import Popup from "src/components/Popup";
import { FormButton } from "src/components/formControls";
import {
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stack,
} from "@mui/material";

import { useEffect, useState } from "react";
import { useLog } from "src/hooks";
import libraryDocumentService from "src/services/libraryDocumentService";
import { IAditionalProposalDocuments } from "src/ts/interfaces";
import moment from "moment";
import { FileDownloadOutlined } from "@mui/icons-material";
import FileUtils from "src/utils/file";

interface ViewDocumentsProps {
  viewDocumentsShow: boolean;
  setViewDocumentsShow: (val: boolean) => void;
  projectId: string;
  companyId: string;
}

export default function ViewDocumentsPopUp({
  viewDocumentsShow,
  setViewDocumentsShow,
  projectId,
  companyId,
}: ViewDocumentsProps) {
  const { log } = useLog();
  const [dataDocument, setDataDocument] = useState<
    IAditionalProposalDocuments[]
  >([]);
  useEffect(() => {
    const getData = async () => {
      try {
        const response = await libraryDocumentService.getAdditionalPropsoal(
          projectId,
          companyId
        );
        setDataDocument(response.data);
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      }
    };
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleItemClick = async (item: IAditionalProposalDocuments) => {
    try {
      const response = await libraryDocumentService.getDocumentFile(item.id);

      let fileName = item.name;

      FileUtils.downloadFile2(response.data, fileName, "application/pdf");
    } catch (error: any) {
      log.error(error?.message?.exceptionMessage ?? "Something went wrong");
    }
  };
  return (
    <>
      <Popup
        title={"Document List"}
        openPopup={viewDocumentsShow}
        setOpenPopup={setViewDocumentsShow}
        onClose={() => {
          setViewDocumentsShow(false);
        }}
        size="sm"
      >
        <>
          <Stack direction={"row"}>
            {dataDocument.length > 0 ? (
              <b>Select a file that you want to download</b>
            ) : (
              <b>No Additional proposal documents were found</b>
            )}
          </Stack>
          <List sx={{ width: "100%", maxWidth: 360 }}>
            {dataDocument.map((item) => (
              <ListItem
                key={item.id}
                onClick={() => handleItemClick(item)}
                sx={{ cursor: "pointer", "&:hover": { background: "#f5f5f5" } }}
              >
                <ListItemAvatar>
                  <FileDownloadOutlined />
                </ListItemAvatar>
                <ListItemText
                  primary={item.name}
                  secondary={moment(item.createdDate).format("MM/DD/YYYY")}
                />
              </ListItem>
            ))}
          </List>
        </>
        <Stack direction="row" spacing={3}>
          <FormButton
            size="small"
            text="Close"
            variant="outlined"
            onClick={() => {
              setViewDocumentsShow(false);
            }}
          />
        </Stack>
      </Popup>
    </>
  );
}
