import { Grid, Typography } from "@mui/material";
import LocalEnhancedTable from "src/components/localTable/LocalTable";
import { ColumnType } from "src/types/enhancedTable";
import { useForm, useLog } from "src/hooks";
import { useEffect, useState } from "react";
import { FormText } from "src/components/formControls";
import { IEquipmentSummary } from "src/ts/interfaces/equipmentDocument";

const getColumns = (): ColumnType[] => {
  return [
    {
      id: "name",
      label: "Equipment Name",
      type: "string",
      sort: true,
    },
    {
      id: "type",
      label: "File Name",
      type: "string",
      sort: true,
    },
    {
      id: "totalFileSize",
      label: "Size",
      type: "custom",
      sort: true,
      callback: (row: IEquipmentSummary) => {
        return (
          <>
            <Typography>
              {Intl.NumberFormat("en-US", {
                maximumFractionDigits: 2,
              }).format(row.size)}{" "}
              KB
            </Typography>
          </>
        );
      },
    },
  ];
};

const initialValues = {
  id: 0,
  name: "",
  type: "",
};

const TableEquipmentSummary = () => {
  const columns = getColumns();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [equipmentSummary, setEquipmentSummary] = useState<IEquipmentSummary[]>(
    []
  );
  const { log } = useLog();

  const { values: filterValues, handleInputChange: handleFilterChange } =
    useForm(initialValues, true, []);

  useEffect(() => {
    const getData = async () => {
      try {
        setIsSubmitting(true);
        //const request = await equipmentDocumentService.getEquipmentSummary(); The development is not in Angular
        setEquipmentSummary([]);
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      } finally {
        setIsSubmitting(false);
      }
    };

    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Grid item xs={12}>
        <Grid container padding={"20px"}>
          <Grid item xs={12}>
            <FormText
              name="name"
              label="Search"
              value={filterValues.name}
              onChange={handleFilterChange}
            />
          </Grid>
        </Grid>

        <LocalEnhancedTable<IEquipmentSummary>
          refreshGrid={true}
          columns={columns}
          data={equipmentSummary}
          showSkeleton={isSubmitting}
          query={filterValues.name}
          defaultRowPerPage={10}
        />
      </Grid>
    </>
  );
};

export default TableEquipmentSummary;
