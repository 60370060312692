import { useEffect, useState } from "react";
import Popup from "src/components/Popup";
import {
  FormAcceptButton,
  FormCancelButton,
} from "src/components/formControls";
import { Stack, CardMedia } from "@mui/material";
import TicketService from "src/services/ticketService";
import { useLog } from "src/hooks";

interface CommentsTicketsProps {
  showImageTicketPopup: boolean;
  setShowImageTicketPopup: (val: boolean) => void;
  informationId: any;
}

export default function ImageTicketPopUp({
  showImageTicketPopup,
  setShowImageTicketPopup,
  informationId,
}: CommentsTicketsProps) {
  const { log } = useLog();
  const [isSubmitting] = useState(false);
  const [enCodeImg, setEnCodeImg] = useState({});
  const handleClosePopUp = () => {
    setShowImageTicketPopup(false);
  };

  useEffect(() => {
    const getImg = async () => {
      try {
        const request = await TicketService.getImage(informationId.id);
        setEnCodeImg(request.data);
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      }
    };
    getImg();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Popup
        title="Ticket's document"
        openPopup={showImageTicketPopup}
        setOpenPopup={setShowImageTicketPopup}
        onClose={handleClosePopUp}
      >
        <>
          <Stack direction="row" spacing={15}>
            <CardMedia
              component="img"
              src={`data:image/png;base64, ${enCodeImg}`}
            />
          </Stack>
        </>
        <Stack
          direction="row"
          spacing={2}
          justifyContent="flex-end"
          alignItems="flex-start"
        >
          <FormCancelButton
            onClick={() => {
              setShowImageTicketPopup(false);
            }}
            isSubmitting={isSubmitting}
            text="Close"
          />
        </Stack>
      </Popup>
    </>
  );
}
