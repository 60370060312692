import React, { useEffect, useState } from "react";
import { Stack } from "@mui/material";
import { ColumnType } from "src/types/enhancedTable";
import LocalEnhancedTable from "src/components/localTable/LocalTable";
import { GridActionButton } from "src/components/gridControls";
import yotubeVideos from "src/services/catalogs/youtubeVideos";
import { getAllDistinct } from "src/ts/interfaces/catalogs/youtubeTrainingVideos";
import EditTrainingVideosModal from "./Component/EditTrainingVideosModal";
import { useForm, useLog } from "src/hooks";

const initialValues = {
  pageName: "",
};

const YoutubeVideosReports = () => {
  const { log } = useLog();
  const [data, setData] = useState<getAllDistinct[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showEditTraningVideo, setShowEditTraningVideo] = useState(false);
  const [dataRow, setDataRow] = useState<getAllDistinct>();
  useEffect(() => {
    const getData = async () => {
      try {
        setIsLoading(true);
        const response = await yotubeVideos.getAllReports();
        setData(response.data);
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      } finally {
        setIsLoading(false);
      }
    };

    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleEdit = (row: getAllDistinct) => {
    setShowEditTraningVideo(true);
    setDataRow(row);
  };

  const columns: ColumnType[] = [
    {
      id: "pageName",
      label: "Reports",
      type: "custom",
      sort: true,
      callback: (row: getAllDistinct) => {
        return <>{row.reportType?.name}</>;
      },
    },
    {
      id: "x",
      label: "Actions",
      type: "custom",
      sort: false,
      callback: (row: getAllDistinct) => (
        <div>
          <Stack direction="row">
            <GridActionButton
              type="edit"
              onClick={() => handleEdit(row)}
              tooltip="Edit"
            />
          </Stack>
        </div>
      ),
    },
  ];

  const { values: filterValues } = useForm(initialValues, true, []);

  return (
    <>
      <LocalEnhancedTable<getAllDistinct>
        refreshGrid={true}
        columns={columns}
        data={data ?? []}
        showSkeleton={isLoading}
        defaultRowPerPage={25}
        defaultSortColumn={"reportType"}
        query={filterValues.pageName}
        secondLevelSort="name"
      />

      {showEditTraningVideo ? (
        <EditTrainingVideosModal
          showEditTraningVideo={showEditTraningVideo}
          setShowEditTraningVideo={setShowEditTraningVideo}
          dataRow={dataRow!}
          tag="reports"
        />
      ) : null}
    </>
  );
};

export default YoutubeVideosReports;
