import axios from "src/utils/axios";
import { IBearingDTO } from "src/ts/interfaces";

const getByReportAndFan = (id: number, fanId: number) => {
  return axios.get<IBearingDTO>(`bearing?reportId=${id}&fanId=${fanId}`);
};

const save = (bearing: IBearingDTO) => {
  return axios.post<IBearingDTO>(`bearing`, bearing);
};

const update = (id: number, bearing: IBearingDTO) => {
  return axios.put<IBearingDTO>(`bearing/${id}`, bearing);
};

const remove = (id: number) => {
  return axios.delete<IBearingDTO>(`Bearing/${id}`);
};

const bearingService = {
  getByReportAndFan,
  save,
  remove,
  update,
};

export default bearingService;
