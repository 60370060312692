import {
  Alert,
  CardContent,
  Grid,
  Card as MuiCard,
  Divider as MuiDivider,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";
import styled from "@emotion/styled";
import { useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  useAsyncQuery,
  useAuth,
  useEffectOnce,
  useLog,
  useSystemOfMeasurement,
} from "src/hooks";
import chillerService from "src/services/study/chillerService";
import {
  IChillerCompressor,
  IChillerEvaporatorCondenser,
  IReportType,
} from "src/ts/interfaces";
import { Mode } from "src/ts/types";
import StudyWizard from "../../components/StudyWizard";
import { FooterButtons } from "../../components/FooterButtons";
import { steps, studyTypeCode } from "../const";
import projectReportService from "src/services/study/projectReportService";
import { IProjectReport } from "src/ts/interfaces/project/projectDto";
import reportTypeService from "src/services/study/reportTypeService";
import studiesHistoryService from "src/services/study/studiesHistoryService";
import useChillerStudy from "../hooks/useChillerStudy";
import EvaporatorList from "../components/CondensersList";
import EvaporatorForm from "../components/EvaporatorForm";
import EvaporatorTable from "../components/EvaporatorTable";
import CompressorsList from "../components/CompressorsList";
import CompressorActualForm from "../components/CompressorActualForm";
import ChillerResults from "../components/ChillerResults";
import HeaderStudyPage from "src/components/page/HeaderStudyPage";
import DialogMessagePopup from "src/components/DialogMessagePopup";

const Divider = styled(MuiDivider)(spacing);
const Card = styled(MuiCard)(spacing);
const activeStep = 3;

const ChillerStep41 = () => {
  const { user } = useAuth();
  const params = useParams<{ id: string }>();
  const id = parseInt(params?.id === undefined ? "0" : params?.id);
  let navigate = useNavigate();
  const { log } = useLog();
  const { systemOfMeasurement } = useSystemOfMeasurement(true);

  const {
    chillerValues,
    setChillerValues,
    setEvaporatorValues,
    setCompressorValues,
    compressorValues,
    evaporatorValues,
    validateCompressorValues,
  } = useChillerStudy();

  const [reportType, setReportType] = useState<IReportType | null>();

  const { execute: executeReport, data: dataReport } =
    useAsyncQuery<IProjectReport>(projectReportService.report);

  const [isLoading, setIsLoading] = useState(false);

  const [evaporators, setEvaporators] = useState<IChillerEvaporatorCondenser[]>(
    []
  );
  const [compressors, setCompressors] = useState<IChillerCompressor[]>([]);
  useEffectOnce(() => {
    const getData = async () => {
      try {
        setIsLoading(true);
        const chillerRes = await chillerService.getById(id);
        setChillerValues(chillerRes.data);

        const evaporatorsRes = await chillerService.evaporators(id);
        setEvaporators(evaporatorsRes.data);

        if (evaporatorsRes.data.length > 0) {
          setEvaporatorValues(evaporatorsRes.data[0]);

          await getCompressors(evaporatorsRes.data[0]);

          if (
            evaporatorsRes.data[0].chillerCOP === 0 ||
            evaporatorsRes.data[0].chillerCOP == null
          ) {
            setCopStatus(0);
          } else if (evaporatorsRes.data[0].chillerCOP <= 3.5) {
            setCopStatus(1);
          } else if (
            evaporatorsRes.data[0].chillerCOP > 3.5 &&
            evaporatorsRes.data[0].chillerCOP <= 4
          ) {
            setCopStatus(2);
          } else if (
            evaporatorsRes.data[0].chillerCOP > 4 &&
            evaporatorsRes.data[0].chillerCOP <= 5
          ) {
            setCopStatus(3);
          } else {
            setCopStatus(4);
          }
        }

        const reportTypeRes = await reportTypeService.getReportTypeByCode(
          studyTypeCode
        );
        setReportType(reportTypeRes.data);

        await executeReport(id, reportTypeRes.data.code);
        await systemOfMeasurement.getByProject(chillerRes.data.projectId);
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      } finally {
        setIsLoading(false);
      }
    };
    if (id > 0) getData();
  });

  const getCompressors = async (evaporator: IChillerEvaporatorCondenser) => {
    const compressorsRes = await chillerService.getCompressorsByEvaporatorId(
      evaporator.id
    );

    if (compressorsRes.data.length > 0) {
      setCompressors(compressorsRes.data);
      setCompressorValues(compressorsRes.data[0]);
      //Training Motor
    } else {
      const compressorNew: any = {
        chillerEvaporatorCondenserId: evaporator.id,
      };

      const compressorRes = await chillerService.addCompressor(compressorNew);
      setCompressors([compressorRes.data]);
      setCompressorValues(compressorRes.data);
    }
  };

  const mode: Mode = chillerValues.isComplete ? "read" : "read&Write";

  const disableStudy = useMemo(() => {
    return (
      chillerValues?.isComplete ||
      (dataReport?.isInUse && dataReport?.isInUseById !== user?.userId) ||
      dataReport?.isPartOfProject === false
    );
  }, [
    dataReport?.isInUse,
    dataReport?.isInUseById,
    dataReport?.isPartOfProject,
    user?.userId,
    chillerValues?.isComplete,
  ]);

  const save = async () => {
    if (!disableStudy) {
      await chillerService.updateEvaporator(
        evaporatorValues.id,
        evaporatorValues
      );
      await saveCompressor();
    }
    await studiesHistoryService.save(
      chillerValues.projectId,
      reportType?.id ?? 0,
      chillerValues.id,
      "saved"
    );
    log.info("Report Was saved");
  };

  const next = async () => {
    if (!disableStudy) {
      await chillerService.updateEvaporator(
        evaporatorValues.id,
        evaporatorValues
      );
      await saveCompressor();
    }

    const route = `/studies/Chiller/step42/${id}`;
    navigate(route);
  };

  const back = () => {
    const route = `/studies/Chiller/step3/${id}`;
    navigate(route);
  };

  const onEvaporatorChangeHandler = async (
    evaporator: IChillerEvaporatorCondenser
  ) => {
    if (evaporator.id === evaporatorValues.id) return;
    // if (!validateCompressorValues()) return;
    if (!disableStudy) {
      await chillerService.updateEvaporator(
        evaporatorValues.id,
        evaporatorValues
      );
      await saveCompressor();
    }
    const evaporatorRes = await chillerService.getEvaporatorById(evaporator.id);
    setEvaporatorValues(evaporatorRes.data);
    await getCompressors(evaporator);
  };

  const selectCompressorHandler = async (
    selectedCompressor: IChillerCompressor
  ) => {
    if (!disableStudy) {
      await saveCompressor();
    }

    const compressorRes = await chillerService.getCompressorById(
      selectedCompressor.id
    );
    setCompressorValues(compressorRes.data);
  };

  const saveCompressor = async () => {
    if (!validateCompressorValues()) return;
    await chillerService.updateCompressor(
      compressorValues.id,
      compressorValues
    );
  };

  const [copStatus, setCopStatus] = useState(0);
  const [effPfMessage, setEffPfMessage] = useState("");
  const [showEffPfLimitModal, setShowEffPfLimitModal] = useState(false);

  const updateCompressor = async () => {
    await chillerService.updateCompressor(
      compressorValues.id,
      compressorValues
    );

    const calculateEvaporatorFormulaRes =
      await chillerService.evaporatorFormula(evaporatorValues.id);
    const newEvaporatorCondenser = calculateEvaporatorFormulaRes.data;
    setEvaporatorValues(newEvaporatorCondenser);

    if (
      newEvaporatorCondenser.chillerCOP === 0 ||
      newEvaporatorCondenser.chillerCOP == null
    ) {
      setCopStatus(0);
    } else if (newEvaporatorCondenser.chillerCOP <= 3.5) {
      setCopStatus(1);
    } else if (
      newEvaporatorCondenser.chillerCOP > 3.5 &&
      newEvaporatorCondenser.chillerCOP <= 4
    ) {
      setCopStatus(2);
    } else if (
      newEvaporatorCondenser.chillerCOP > 4 &&
      newEvaporatorCondenser.chillerCOP <= 5
    ) {
      setCopStatus(3);
    } else {
      setCopStatus(4);
    }

    const compressorRes = await chillerService.getCompressorById(
      compressorValues.id
    );

    const newEff = compressorRes?.data?.chillerFormula.eff
      ? compressorRes?.data?.chillerFormula.eff / 100
      : 0;
    const powerFactor = compressorRes.data.chillerFormula.powerFactor;
    setCompressorValues({
      ...compressorValues,
      chillerFormula: {
        ...compressorRes.data.chillerFormula,
        eff: newEff,
      },
    });

    if (newEff > 1.0) {
      setEffPfMessage("Improperly inputed data EFF can’t exceed 1.0");
      setShowEffPfLimitModal(true);
    } else if (powerFactor > 1.0) {
      setEffPfMessage("Improperly inputed data PF can’t exceed 1.0");
      setShowEffPfLimitModal(true);
    }
  };
  return (
    <>
      <HeaderStudyPage
        headerStudiesPage={{
          code: studyTypeCode,
          system: chillerValues.system,
          id: id,
        }}
        parentText="Project"
        parentLink="/"
      />
      <Divider my={6} />

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <StudyWizard
            steps={steps}
            activeStep={activeStep}
            projectId={dataReport?.projectId}
            reportId={dataReport?.reportId}
            reportTypeId={dataReport?.reportTypeId}
          />
        </Grid>
        <Grid item xs={12}>
          <Card mb={1}>
            <CardContent>
              <Grid container spacing={5}>
                <Grid item xs={12}>
                  <Typography variant="h6">
                    <strong>Chiller Actuals</strong>
                  </Typography>
                  <Typography variant="body1">
                    Record Chilled water supply and return temperature readings.
                    Compare data to digital readout on chiller control panel.
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <EvaporatorForm
                    mode={mode}
                    disableStudy={disableStudy}
                    isLoading={false}
                    systemOfMeasurement={systemOfMeasurement}
                    parentReportId={dataReport?.parentReportId}
                  />
                </Grid>
                <Grid item xs={12}>
                  <EvaporatorList
                    evaporators={evaporators}
                    isStudyComplete={disableStudy}
                    onChange={onEvaporatorChangeHandler}
                  />
                </Grid>
                <Grid item xs={12}>
                  <EvaporatorTable
                    mode={mode}
                    disableStudy={disableStudy}
                    isLoading={false}
                    systemOfMeasurement={systemOfMeasurement}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h6">Compressors/Circuits.</Typography>
                </Grid>
                <Grid item xs={12}>
                  <CompressorsList
                    compressors={compressors}
                    disableStudy={disableStudy}
                    onSelect={selectCompressorHandler}
                    showDelete={false}
                    showDuplicate={false}
                  />
                </Grid>
                <Grid item xs={12}>
                  <CompressorActualForm
                    mode={mode}
                    disableStudy={false}
                    isLoading={false}
                    updateCompressor={updateCompressor}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h6">Chiller Results</Typography>
                  <Typography variant="body2">Based on Actual BHP</Typography>
                </Grid>
                <Grid item xs={12}>
                  <ChillerResults
                    mode={mode}
                    disableStudy={false}
                    isLoading={false}
                    systemOfMeasurement={systemOfMeasurement}
                  />
                </Grid>
                <Grid item xs={12}>
                  {copStatus === 1 && (
                    <Alert severity="error">
                      {"C.O.P. EVALUATION RESULT"}{" "}
                      <strong>NEEDS IMPROVEMENT</strong>
                    </Alert>
                  )}
                  {copStatus === 2 && (
                    <Alert severity="warning">
                      {"C.O.P. EVALUATION RESULT"} <strong>FAIR</strong>
                    </Alert>
                  )}
                  {copStatus === 3 && (
                    <Alert severity="success">
                      {"C.O.P. EVALUATION RESULT"} <strong>GOOD</strong>
                    </Alert>
                  )}
                  {copStatus === 4 && (
                    <Alert severity="info">
                      {"C.O.P. EVALUATION RESULT"} <strong>EXCELLENT</strong>
                    </Alert>
                  )}
                  <br />
                  <Divider />
                  <br />
                  {copStatus > 0 && (
                    <div style={{ textAlign: "center" }}>
                      <img
                        style={{ width: "60%" }}
                        alt="Chiller Plant Energy Use"
                        src="/static/img/chiller-plant-energy-use.jpg"
                      ></img>
                    </div>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <FooterButtons
                    activeStep={activeStep}
                    stepsCount={steps.length}
                    projectId={dataReport?.projectId}
                    companyId={user?.companyId as number}
                    isSaving={false}
                    disabled={false}
                    reportName={reportType?.name ?? ""}
                    saveHandler={save}
                    nextStepHandler={() => next()}
                    previousStepHandler={() => back()}
                    hideSaveButton={disableStudy}
                    isComplete={chillerValues.isComplete}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <DialogMessagePopup
        title={"Limit reached."}
        text={effPfMessage}
        showPopup={showEffPfLimitModal}
        setShowPopup={setShowEffPfLimitModal}
        onSave={() => {
          setShowEffPfLimitModal(false);
        }}
        isSubmitting={false}
        hideCancel={true}
      />
    </>
  );
};

export default ChillerStep41;
