import axios from "src/utils/axios";
import { IBeltType } from "src/ts/interfaces/catalogs";

const getBeltTypes = () => {
  return axios.get<IBeltType[]>("BeltType");
};

const beltTypeService = {
  getBeltTypes,
};

export default beltTypeService;
