import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Grid,
  CardContent,
  Typography,
  styled,
  Card as MuiCard,
  Stack,
} from "@mui/material";
import Popup from "src/components/Popup";
import { ColumnType } from "src/types/enhancedTable";
import LocalEnhancedTable from "src/components/localTable/LocalTable";
import { IAssets, ITypeAsset } from "src/ts/interfaces/companyAssets";
import { spacing } from "@mui/system";
import EditAssetPopUp from "./EditAssetPopUp";
import { useAuth, usePermissions } from "src/hooks";
import SatellitePopUp from "./SatellitePopUp";
import AssignTransferPopUp from "./AssignTransferPopUp";
import { FormButton } from "src/components/formControls";
import AssesmentReport from "./AssesmentReport";
import { AssetAssignedToYouEquipmentColumns } from "./columnsTable/EquipmentColumns";
import { AssetAssignedToYouVehicleColumns } from "./columnsTable/VehicleColumns";
import { ASSET_TILE } from "src/constants";
import { Permission as PermissionTypes } from "src/ts/enums";
const Card = styled(MuiCard)(spacing);

interface Props {
  data: IAssets[];
  isLoading: boolean;
  asset: string;
  companyId: string;
  setRefreshGrid: (value: boolean) => void;
  refreshGrid: boolean;
  filterValues: string;
  information: ITypeAsset;
  permissionRole: boolean;
}

const AssetAssignedToYou = (props: Props) => {
  const {
    data,
    isLoading,
    asset,
    companyId,
    setRefreshGrid,
    refreshGrid,
    filterValues,
    information,
    permissionRole,
  } = props;
  const [dataTable, setDataTable] = useState<IAssets[]>([]);
  const [columns, setColumns] = useState<ColumnType[]>([]);
  const [currentRow, setCurrentRow] = useState<IAssets>();

  const [showEditModal, setShowEditModal] = useState(false);
  const [showSatelliteModal, setShowSatelliteModal] = useState(false);
  const [showAssigment, setShowAssigment] = useState(false);
  const [showAssignTransfer, setShowAssignTransfer] = useState(false);
  const [showAssessmentHistory, setShowAssessmentHistory] = useState(false);
  const { fullAccess: fullAccessEQIP } = usePermissions(
    PermissionTypes.Equipment
  );
  const { fullAccess: fullAccessVEHI } = usePermissions(
    PermissionTypes.Vehicles
  );
  const { user } = useAuth();
  let navigate = useNavigate();

  const permission =
    user?.role === "ProjectManager" ||
    user?.role === "CompanyOwner" ||
    user?.role === "SuperAdministrator" ||
    user?.role === "Administrator" ||
    user?.role === "Training";

  const titleName =
    asset === "VEHI" ? ASSET_TILE.VEHICLE : ASSET_TILE.EQUIPMENT;

  useEffect(() => {
    const getData = () => {
      if (asset === "VEHI") {
        if (data !== null) {
          const filter = data.filter((item: IAssets) => {
            return item.assignedToId === user?.userId;
          });
          setDataTable(filter);
        }
      } else {
        const filter = data.filter((item: IAssets) => {
          return item.assignedToId === user?.userId;
        });
        setDataTable(filter);
      }
    };
    if (data !== null) getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    setColumns(
      asset === "VEHI"
        ? AssetAssignedToYouVehicleColumns(
            setCurrentRow,
            setShowEditModal,
            setShowSatelliteModal,
            setShowAssigment,
            setShowAssignTransfer,
            setShowAssessmentHistory,
            permission,
            navigate,
            companyId,
            eval(`fullAccess${asset}`)
          )
        : AssetAssignedToYouEquipmentColumns(
            setCurrentRow,
            setShowEditModal,
            setShowSatelliteModal,
            setShowAssigment,
            setShowAssignTransfer,
            setShowAssessmentHistory,
            permission,
            navigate,
            companyId,
            user?.canAssignTestEquipment,
            eval(`fullAccess${asset}`)
          )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, asset]);

  const AssigmentProcessDecision = () => (
    <>
      <Popup
        title={"Information"}
        openPopup={showAssigment}
        setOpenPopup={setShowAssigment}
        onClose={() => {
          setShowAssigment(false);
        }}
      >
        <Stack>
          <Typography>
            The {titleName} has an open assignment process and is awaiting a
            decision from{" "}
            {currentRow?.requestTransfer?.transferTo?.name === undefined
              ? ""
              : currentRow?.requestTransfer?.transferTo?.name +
                " " +
                currentRow?.requestTransfer?.transferTo?.lastName}{" "}
            {currentRow?.requestTransfer !== null &&
            currentRow?.requestTransfer?.transferTo === null
              ? "Office Shop"
              : ""}
            . If you confirm this transfer request, the current one will be
            canceled.
          </Typography>
        </Stack>

        <Stack
          direction="row"
          spacing={2}
          justifyContent="flex-end"
          alignItems="flex-start"
        >
          <FormButton
            text="Yes, proceed "
            size="medium"
            color="success"
            onClick={() => {
              setShowAssignTransfer(true);
              setShowAssigment(false);
            }}
          />
          <FormButton
            text="No, cancel request "
            size="medium"
            color="error"
            onClick={() => {
              setShowAssigment(false);
            }}
          />
        </Stack>
      </Popup>
    </>
  );

  const filterType =
    information.name === "Vehicle"
      ? ["vehicle.make", "vehicle.year", "vehicle.model"]
      : [
          "equipment.name",
          "equipment.description",
          "equipment.serialNumber",
          "equipment.model",
        ];

  return (
    <>
      <Card mb={6}>
        <CardContent>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Typography variant="h6">
                {asset === "VEHI"
                  ? "Vehicle assigned to you"
                  : "Recommended Test Equipment assigned to you"}{" "}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <LocalEnhancedTable<IAssets>
                refreshGrid={true}
                columns={columns}
                data={dataTable}
                showSkeleton={isLoading}
                skeletonRows={1}
                hidePagination={false}
                defaultRowPerPage={10}
                noDataTitle={
                  !permission
                    ? `The company administrator has not assigned any ${information.name} to you`
                    : ` Click "Add ${information.name}" button to add a new ${information.name} to the current company.`
                }
                query={filterValues}
                filterCustom={filterType}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      {showEditModal && (
        <EditAssetPopUp
          setShowEditModal={setShowEditModal}
          showEditModal={showEditModal}
          currentRow={currentRow ?? {}}
          companyId={companyId}
          asset={asset}
          setRefreshGrid={setRefreshGrid}
          refreshGrid={refreshGrid}
          information={information ?? []}
        />
      )}
      {showSatelliteModal && (
        <SatellitePopUp
          showSatelliteModal={showSatelliteModal}
          setShowSatelliteModal={setShowSatelliteModal}
          currentRow={currentRow ?? {}}
          transactionType="transferTo"
        />
      )}
      {showAssigment && <AssigmentProcessDecision />}
      {showAssignTransfer && (
        <AssignTransferPopUp
          showAssignTransfer={showAssignTransfer}
          setShowAssignTransfer={setShowAssignTransfer}
          currentRow={currentRow ?? {}}
          asset={asset}
          companyId={companyId}
          setRefreshGrid={setRefreshGrid}
          refreshGrid={refreshGrid}
          information={information ?? []}
          permissionRole={permissionRole}
        />
      )}
      {showAssessmentHistory && (
        <AssesmentReport
          showAssessmentHistory={showAssessmentHistory}
          setShowAssessmentHistory={setShowAssessmentHistory}
          currentRow={currentRow ?? {}}
          asset={asset}
          permissionRole={false}
          assetStatus={""}
        />
      )}
    </>
  );
};

export default AssetAssignedToYou;
