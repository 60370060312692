export enum MeasurementSystemEnum {
  Imperial = 1,
  MetricSystem = 2,
}

export enum ProjectTimeDurationUnit {
  None = 0,
  Hours = 1,
  Days = 2,
}

export enum ProjectAddendum {
  None = 0,
  Yes = 1,
}

export enum ProjectRoofAccessDTO {
  Unknown = 0,
  Yes = 1,
  No = 2,
}

export enum TestingEquipmentMinimumDateEnum {
  OneYearRequired = 1,
  SixMonthRequired = 2,
}

export enum BeltsAndPulleysEnum {
  //Cost for any Belts and Pulleys requiring purchased will be carried by the HVAC Installing Mechanical Contractor (Recommended)
  HVACInstalling = 1,
  //Cost for any Belts and Pulleys requiring purchased will be carried by this project AMP Energy, T&B, & Infectious Disease Control Project Contractor
  AMPEnergy = 2,
  NotSelected = 3,
}

export enum CostForElectricMotorsEnum {
  //Cost for any Electric Motors requiring purchase will be carried by the HVAC Installing Mechanical Contractor (Recommended)
  HVACInstalling = 1,
  //Cost for any Electric Motors requiring purchase will be carried by this projects AMP Energy, T&B, & Infectous Disease Control Project Control
  AMPEnergy = 2,
  NotSelected = 3,
}

export enum AdditionalTestingReqEnum {
  //Duct Leakage Testing, see additional Specifications. Required for any duct equal to or greater than Medium Pressure Classification and witnessed by this projects Architect and/or Engineer (Recommended)
  DuctLeakageTesting = 1,
  //Vibration Testing for motors equal to or greater than 1.0 HP or 745.69 Watts. See additional Specifications
  VibrationTestingForMotors = 2,
  //Sound Testing. Locations chosen by this projects Architect and/or Engineer. See additional Specifications
  SoundTestingLocations = 3,
  //Hydronic pipe leakage testing. See additional Specifications
  HydronicPipeleakage = 4,
  //UFAD Plenum leakage testing. See additional Specifications
  UFADPlenumLeakage = 5,
  NotSelected = 6,
}

export enum StudyStatusEnum {
  New = 1,
  Working = 2,
  Complete = 3,
  Removed = 4,
  Incomplete = 5,
  InReview = 6,
  Approved = 7,
  ApprovedButNotCompliant = 8,
  Rejected = 9,
  Appeal = 10,
  FinalReject = 11,
  History = 12,
}
