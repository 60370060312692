import { useState } from "react";
import { Stack, Typography } from "@mui/material";
import { ColumnType } from "src/types/enhancedTable";
import { FilterType } from "../types/FilterTypes";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { GridActionButton } from "src/components/gridControls";
import { useAsyncQuery, useAuth, usePermissions } from "src/hooks";

import { IArchitectProjects } from "src/ts/interfaces/projectArchitect";
import LocalEnhancedTable from "src/components/localTable/LocalTable";
import MatchWordConfirmModal from "src/components/modals/MatchWordConfirmModal";
import projectService from "src/services/project/projectService";
import ArchiveProjectPopUp from "./components/ArchiveProjectPopUp";
import { Permission as PermissionTypes } from "src/ts/enums";
const getColumns = (
  navigate: NavigateFunction,
  role: string,
  setCurrentRow: (val: IArchitectProjects) => void,
  setDeleteProjectModal: (val: boolean) => void,
  setArchiveProjectModal: (val: boolean) => void,
  companyId: number,
  fullAccess: boolean
): ColumnType[] => [
  {
    id: "code",
    label: "Code",
    type: "string",
    sort: true,
  },
  {
    id: "name",
    label: "Project",
    type: "string",
    sort: true,
  },
  {
    id: "createdDate",
    label: "Created date",
    type: "date",
    format: "MM/DD/YYYY",
    sort: true,
  },
  {
    id: "closedDate",
    label: "Closed date",
    type: "date",
    format: "MM/DD/YYYY",
    sort: true,
  },
  {
    id: "buildingName",
    label: "Building",
    type: "custom",
    sort: true,
    callback: (row: IArchitectProjects) => {
      return (
        <Typography>
          {" "}
          <b>{row.buildingName}</b> <br />
          {row.buildingAddress}{" "}
        </Typography>
      );
    },
  },

  {
    id: "action",
    label: "",
    type: "custom",
    sort: true,
    width: "150p",
    callback: (row: IArchitectProjects) => (
      <>
        <Stack direction="row">
          <GridActionButton
            type="forward"
            onClick={() => {
              navigate(`/app/Project/Edit/${row.id}/${companyId}`);
            }}
            tooltip="Review Project"
          />
          {fullAccess && (
            <GridActionButton
              type="remove"
              onClick={() => {
                setCurrentRow(row);
                setArchiveProjectModal(true);
              }}
              tooltip="Archive Project"
            />
          )}
          {fullAccess && (
            <GridActionButton
              type="delete"
              onClick={() => {
                setCurrentRow(row);
                setDeleteProjectModal(true);
              }}
              tooltip="Delete Project"
            />
          )}
          <GridActionButton
            type="folder"
            onClick={() => {
              navigate(`/app/DocumentLibrary/${row.id}/${companyId}/Project`);
            }}
            tooltip="Project's Document Library"
          />
          <GridActionButton
            type="picture"
            onClick={() => {
              navigate(`/app/PhotoLibrary/${row.id}/${companyId}/Project`);
            }}
            tooltip="Project's Picture Library"
          />
        </Stack>
      </>
    ),
  },
];

interface OutForBidProjectProps {
  filters: FilterType;
  setRefreshGrid: (val: boolean) => void;
  dataTable: IArchitectProjects[];
  isLoading: boolean;
  refreshGrid: boolean;
  getDataSummary: () => void;
  setDataTable: (val: IArchitectProjects[]) => void;
}

export default function ClosedProject({
  filters,
  setRefreshGrid,
  dataTable,
  isLoading,
  refreshGrid,
  getDataSummary,
  setDataTable,
}: OutForBidProjectProps) {
  const [currentRow, setCurrentRow] = useState<IArchitectProjects>();
  const [deleteProjectModal, setDeleteProjectModal] = useState(false);
  const [archiveProjectModal, setArchiveProjectModal] = useState(false);
  const { fullAccess } = usePermissions(PermissionTypes.Project);
  let navigate = useNavigate();
  const { user } = useAuth();

  const { execute: executeDelete } = useAsyncQuery(
    projectService.getDeleteProject
  );

  let columns = getColumns(
    navigate,
    user?.role,
    setCurrentRow,
    setDeleteProjectModal,
    setArchiveProjectModal,
    user?.companyId,
    fullAccess
  );

  const deleteProject = async () => {
    await executeDelete(currentRow?.id);

    if (dataTable.length >= 0) {
      const dataSplice = dataTable.filter((item: IArchitectProjects) => {
        return item.id !== currentRow?.id;
      });
      setDataTable(dataSplice);
    }
    getDataSummary();
  };

  return (
    <>
      <LocalEnhancedTable<IArchitectProjects>
        refreshGrid={false}
        columns={columns}
        data={dataTable ?? []}
        showSkeleton={isLoading}
        skeletonRows={1}
        hidePagination={false}
        defaultRowPerPage={10}
        orderColumn="desc"
        defaultSortColumn="id"
      />
      <MatchWordConfirmModal
        onConfirm={deleteProject}
        text={`Are you certain you want to delete permanently this project?`}
        matchWord={"DELETE"}
        isDialogOpen={deleteProjectModal}
        setIsDialogOpen={setDeleteProjectModal}
      />
      {archiveProjectModal && (
        <ArchiveProjectPopUp
          setArchiveProjectModal={setArchiveProjectModal}
          archiveProjectModal={archiveProjectModal}
          currentRow={currentRow}
          setRefreshGrid={setRefreshGrid}
          refreshGrid={refreshGrid}
        />
      )}
    </>
  );
}
