import { Grid, Stack, Typography } from "@mui/material";
import {
  FormAcceptButton,
  FormButton,
  FormCancelButton,
  FormText,
} from "src/components/formControls";
import Popup from "src/components/Popup";
import { useAsyncMutation } from "src/hooks";
import { IUserPerson } from "src/ts/interfaces";
import ReCAPTCHA from "react-google-recaptcha";
import { useEffect, useState } from "react";
import signUpService from "src/services/signUpService";
import { IPostCellPhoneNumberVerificationRequest } from "src/ts/interfaces/signup";
import FormTitleBox from "src/components/formControls/FormTitleBox";
import ReactInputVerificationCode from "react-input-verification-code";
import styled from "styled-components";
interface GPSAppModalProps {
  showVerificationModal: boolean;
  setSowVerificationModal: (val: boolean) => void;
  userPerson: IUserPerson;
  confirmSave: () => Promise<void>;
}

const CustomStylesContainer = styled.div`
  --ReactInputVerificationCode-itemWidth: 3rem;
  --ReactInputVerificationCode-itemHeight: 3rem;
  --ReactInputVerificationCode-itemSpacing: 2rem;
  .ReactInputVerificationCode__container {
    padding-left: 5vw;
  }
`;

const IdentityVerificationPopUp = (props: GPSAppModalProps) => {
  const {
    showVerificationModal,
    setSowVerificationModal,
    userPerson,
    confirmSave,
  } = props;
  const [code, setCode] = useState<string>("");
  const [step, setStep] = useState(0);
  const { execute, isSubmitting } = useAsyncMutation(signUpService.sendcode, {
    successfulMessage: `A verification code was sent to your phone ending in ******${
      userPerson?.phone ? userPerson?.phone.slice(-4) : ""
    }`,
    onSuccess: async () => {
      setCode("");
      setStep(1);
    },
  });

  const { execute: executeReview, isSubmitting: isSubmittingReview } =
    useAsyncMutation(signUpService.verifyCode, {
      hideSuccessfulMessage: true,
      onSuccess: async () => {
        setSowVerificationModal(false);
        await confirmSave();
      },
      errorMessage:
        "The verification code is wrong, please validate it and try again",
    });

  useEffect(() => {
    setCode("");
    setStep(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showVerificationModal]);

  const cancelHandler = () => {
    setStep(0);
    setSowVerificationModal(false);
  };

  const handleChange = async (recaptcha: any) => {
    const valueCode: IPostCellPhoneNumberVerificationRequest = {
      countryCode: userPerson?.country?.countryCode ?? 0,
      cellPhoneNumber: userPerson?.phone ?? "",
      type: "sms",
      code: 0,
      recaptchaResponse: recaptcha,
    };

    await execute(valueCode);
  };

  const handleResendCode = () => {
    setStep(0);
  };

  const handleVerify = async () => {
    const valueCode: IPostCellPhoneNumberVerificationRequest = {
      countryCode: userPerson?.country?.countryCode ?? 0,
      cellPhoneNumber: userPerson?.phone ?? "",
      type: "sms",
      code: Number(code),
    };

    await executeReview(valueCode);
  };

  return (
    <Popup
      openPopup={showVerificationModal}
      setOpenPopup={setSowVerificationModal}
      title={"Information"}
      onClose={cancelHandler}
      size="sm"
    >
      <Stack>
        {step === 0 && (
          <FormTitleBox title={"Identity Verification "}>
            <Grid container spacing={3}>
              <Grid item xs={2}></Grid>
              <Grid item xs={4}>
                <FormText
                  name={"countryCode"}
                  label={"Country or region code"}
                  value={`+ ${
                    userPerson?.country?.countryCode?.toString() ?? ""
                  }`}
                  mode="read"
                ></FormText>
              </Grid>
              <Grid item xs={4}>
                <FormText
                  name={"phone"}
                  label={"Cell phone"}
                  value={userPerson?.phone ?? ""}
                  mode="read"
                ></FormText>
              </Grid>
              <Grid item xs={2.5}></Grid>
              <Grid item xs={7} textAlign={"center"}>
                <ReCAPTCHA
                  sitekey="6Ld4ht0UAAAAAIurUS8AVwmhNlluE1wReG6_sGgf"
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
          </FormTitleBox>
        )}
        {step === 1 && (
          <FormTitleBox title={"Identity Verification "}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography textAlign={"center"}>
                  Enter the 6-digit verification code that you received on your
                  phone
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <CustomStylesContainer>
                  <ReactInputVerificationCode
                    onChange={(values) => setCode(values)}
                    value={code}
                    length={6}
                    placeholder={""}
                  />
                </CustomStylesContainer>
              </Grid>
            </Grid>
          </FormTitleBox>
        )}
      </Stack>
      <>
        <Stack
          direction="row"
          spacing={2}
          justifyContent="center"
          alignItems="center"
        >
          <FormCancelButton
            onClick={cancelHandler}
            isSubmitting={isSubmitting || isSubmittingReview}
          ></FormCancelButton>
          {step === 1 && (
            <FormButton
              text={"Resend Code"}
              onClick={handleResendCode}
              variant="outlined"
              size="small"
              isSubmitting={isSubmittingReview}
            ></FormButton>
          )}
          {step === 1 && (
            <FormAcceptButton
              isSubmitting={isSubmittingReview}
              text="Verify"
              onClick={handleVerify}
            ></FormAcceptButton>
          )}
        </Stack>
      </>
    </Popup>
  );
};

export default IdentityVerificationPopUp;
