import axios from "../utils/axios";
import {
  IProjectContestOwner,
  IProposalBidContractor,
  TableData,
} from "../ts/interfaces/projectOutToBid";
import {
  ICheckAssign,
  IProjectNewContractor,
  IUpdateEstimatedTime,
} from "src/ts/interfaces/projectContest";

const getProjectContest = async (
  projectId: number,
  checkPermission: number
) => {
  return axios.get(
    `ProjectContest/owner/${checkPermission}/project/${projectId}`
  );
};

const getPrjojectTestProposalBid = async (id: number) => {
  return axios.get(`ProjectContest/${id}`);
};

const getDownloadFile = async (id: number) => {
  return axios.get(`ProjectContest/file?id=${id}`);
};

const setProjectByCode = async (code: string) => {
  return axios.post(`ProjectContest/SetProjectByCode?code=${code}`);
};

const sendBidDocument = async (
  projectId: number,
  id: number,
  data: TableData[]
) => {
  return axios.post(
    `ProjectContest/sendBidDocument/${projectId}/${id}/forwardProjectEmailsTeam`,
    data
  );
};

const getCurrentContestByProject = async (id: number) => {
  return axios.get(`ProjectContest/GetCurrentContestByProject/${id}`);
};

const postUpdateEstimatedTime = async (
  projectId: number,
  estimatedTime: number,
  isDayOrHours: string
) => {
  return axios.post(
    `ProjectContest/UpdateEstimatedTime/${projectId}/${estimatedTime}/${isDayOrHours}`
  );
};

const postCurrentContest = async (projectID: number) => {
  return axios.post(`ProjectContest/CurrentContest?projectId=${projectID}`);
};

const putProjectContest = async (
  projectId: number,
  data: IProposalBidContractor
) => {
  return axios.put(`ProjectContest/${projectId}`, data);
};

const putEstimatedTime = async (data: IUpdateEstimatedTime) => {
  return axios.put(`ProjectContest/updateEstimatedTime`, data);
};

const putDeclineProject = async (projectid: number, comments: string) => {
  return axios.put(
    `ProjectContest/decline?id=${projectid}&comments=${comments}`
  );
};
const putReverseDeclineProject = async (projectid: number) => {
  return axios.put(`ProjectContest/reverseDecline?id=${projectid}`);
};

const postNotifyProjectContest = async (id: number) => {
  return axios.post(`ProjectContest/notify/${id}`);
};

const postProjectNewContractor = async (data: IProjectNewContractor) => {
  return axios.post(`ProjectContest`, data);
};

const deleteProjectContest = async (id: number) => {
  return axios.delete(`ProjectContest/${id}`);
};

const postProjectContestList = async (data: IProjectNewContractor[]) => {
  return axios.post(`ProjectContest/list`, data);
};

const postCheckToAssignProject = async (ids: number[]) => {
  return axios.post<ICheckAssign[]>(
    `ProjectContest/CheckToAssignProjects`,
    ids
  );
};

const postContestRangeContractors = async (
  projectid: number,
  data: IProjectContestOwner[]
) => {
  return axios.post(
    `ProjectContest/ContestRangeContractors?projectId=${projectid}`,
    data
  );
};

const postSendMembers = async (projectId: number, data: number[]) => {
  return axios.post(`ProjectContest/SentToTeamMembers/${projectId}`, data);
};

const getInternalApprovers = async (ownerId: number, projectId: number) => {
  return axios.get(`ProjectContest/owner/${ownerId}/project/${projectId}`);
};

const getWinnerContestByProject = async (id: number) => {
  return axios.get(`ProjectContest/GetWinnerContestByProject/${id}`);
};

const resendInvitation = async (projectId: number, contestId: number) => {
  return axios.post(
    `ProjectContest/ResendInvitation?projectId=${projectId}&contestId=${contestId}`
  );
};

const projectContestService = {
  getProjectContest,
  getPrjojectTestProposalBid,
  getDownloadFile,
  sendBidDocument,
  setProjectByCode,
  getCurrentContestByProject,
  postUpdateEstimatedTime,
  postCurrentContest,
  putProjectContest,
  putEstimatedTime,
  putDeclineProject,
  putReverseDeclineProject,
  postNotifyProjectContest,
  postProjectNewContractor,
  deleteProjectContest,
  postProjectContestList,
  postCheckToAssignProject,
  postContestRangeContractors,
  postSendMembers,
  getInternalApprovers,
  getWinnerContestByProject,
  resendInvitation,
};

export default projectContestService;
