import {
  CardContent,
  Grid,
  Divider as MuiDivider,
  Card as MuiCard,
  Tooltip,
  Zoom,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";
import styled from "@emotion/styled";
import {
  useAsyncQuery,
  useAuth,
  useEffectOnce,
  useLog,
  useSystemOfMeasurement,
} from "src/hooks";
import { useParams } from "react-router";
import { useEffect, useMemo, useState } from "react";
import { IReportType } from "src/ts/interfaces";
import { IProjectReport } from "src/ts/interfaces/project/projectDto";
import projectReportService from "src/services/study/projectReportService";
import reportTypeService from "src/services/study/reportTypeService";
import StudyWizard from "../components/StudyWizard";
import { FooterButtons } from "../components/FooterButtons";
import { reportTypeId, steps, studyTypeCode } from "./const/const";
import { useNavigate } from "react-router-dom";
import { ITower } from "src/ts/interfaces/study/tower";
import useFormTyped from "src/hooks/useFormTyped";
import { Mode, Validator } from "src/ts/types";
import {
  FormDatePicker,
  FormNumeric2,
  FormSelect,
  FormText,
} from "src/components/formControls";
import { InfoIcon } from "src/components/icons";
import DialogMessagePopup from "src/components/DialogMessagePopup";
import moment from "moment";
import towerService from "src/services/study/towerService";
import studiesHistoryService from "src/services/study/studiesHistoryService";
import getProjectSingleService from "src/services/getProjectSingleService";
import pumpReportService from "src/services/study/pumpReportService";
import HeaderStudyPage from "src/components/page/HeaderStudyPage";

const Divider = styled(MuiDivider)(spacing);
const Card = styled(MuiCard)(spacing);
const activeStep = 0;
const initialValues: ITower = {
  id: 0,
  projectId: 0,
  projectName: "",
  reportTypeId: 0,
  system: "",
  ctSystem: "",
  numberOfTowers: 0,
  energySourceSubtractedIsolated: false,
  energyCombinedAverage: false,
  operateFromApproximately: null,
  operateToApproximately: null,
  estimatedAnnualHoursMonFri: 0,
  estimatedAnnualHoursSaturday: 0,
  estimatedAnnualHoursSunday: 0,
  estimatedAnnualHoursOperated: 0,
  estimatedAnnualOperatingDays: 0,
  numberOfCells: 0,
  designRatedGPMTotal: 0,
  desginRatedWarmEwt: 0,
  designRatedColdLwt: 0,
  designRatedAbientAirTempWb: 0,
  condenserDesignBTUH: null,
  evaporatorDesignATFCondenser: 0,
  numberOfCellsOnLineToBeTested: 0,
  numberOfTowerCondenserFanMotors: 0,
  numberOfTowerCondenserFansToBeTestedCreated: false,
  numberOfTowerCondenserFansToBeTested: 0,
  numberOfCondenserPumpsProvidingFlowToThisTower: 0,
  numberOfCondenserPumpsToBeTested: 0,
  numberOfCondenserPumpsToBeTestedCreated: false,
  coolingTowerSoundPressureTesting: false,
  isComplete: false,
  testEndTime: null,
  statusId: 0,
  createdDate: "",
  createdBy: "",
  updatedDate: "",
  updatedBy: "",
  userAuth: "",
  userName: "",
};

const numberOfCondenserPumps = [
  { key: 1, value: 1 },
  { key: 2, value: 2 },
  { key: 3, value: 3 },
  { key: 4, value: 4 },
  { key: 5, value: 5 },
  { key: 6, value: 6 },
  { key: 7, value: 7 },
  { key: 8, value: 8 },
  { key: 9, value: 9 },
  { key: 10, value: 10 },
  { key: 11, value: 11 },
  { key: 12, value: 12 },
  { key: 13, value: 13 },
  { key: 14, value: 14 },
  { key: 15, value: 15 },
  { key: 16, value: 16 },
  { key: 17, value: 17 },
  { key: 18, value: 18 },
  { key: 19, value: 19 },
  { key: 20, value: 20 },
];

let lastTowerNumber = 0;
let type: string = "";
const TowerStep1 = () => {
  const { user } = useAuth();
  const params = useParams<{ id: string }>();
  const id = parseInt(params?.id === undefined ? "0" : params?.id);
  let navigate = useNavigate();
  const { log } = useLog();
  const { systemOfMeasurement } = useSystemOfMeasurement(true);

  const [reportType, setReportType] = useState<IReportType | null>();
  const { execute: executeReport, data: dataReport } =
    useAsyncQuery<IProjectReport>(projectReportService.report);

  const [numberOfTowers, setMaxNumberOfTowers] = useState(100);
  const [days, setDays] = useState({
    weekDays: 0,
    saturdays: 0,
    sundays: 0,
  });

  const [showDateErrorPopup, setShowErrorPopup] = useState(false);
  const [showDecreaseTowersModal, setShowDecreaseTowersModal] = useState(false);

  const validate: any = (fieldValues = values) => {
    let temp: Record<string, string> = { ...errors };

    temp.numberOfTowers = new Validator(fieldValues, "numberOfTowers")
      .lessThan(numberOfTowers, `Please enter maximum ${numberOfTowers} Tower`)
      .greatThan(0, "Please enter minimum 1 Tower")
      .toString();

    temp.estimatedAnnualHoursMonFri = new Validator(
      fieldValues,
      "estimatedAnnualHoursMonFri"
    )
      .greatThanOrEquals(0, "Minimum value 0")
      .toString();

    temp.estimatedAnnualHoursSaturday = new Validator(
      fieldValues,
      "estimatedAnnualHoursSaturday"
    )
      .greatThanOrEquals(0, "Minimum value 0")
      .toString();

    temp.estimatedAnnualHoursSunday = new Validator(
      fieldValues,
      "estimatedAnnualHoursSunday"
    )
      .greatThanOrEquals(0, "Minimum value 0")
      .toString();

    temp.numberOfCellsOnLineToBeTested = new Validator(
      fieldValues,
      "numberOfCellsOnLineToBeTested"
    )
      .isRequired("Required")
      .toString();

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange } =
    useFormTyped<ITower>(initialValues, false, validate);

  const [isLoading, setIsLoading] = useState(false);
  useEffectOnce(() => {
    const getData = async () => {
      try {
        setIsLoading(true);
        const towerReponse = await towerService.getBydId(id);
        setValues(towerReponse.data);
        lastTowerNumber = towerReponse.data.numberOfTowers;

        if (values?.projectId > 0)
          systemOfMeasurement.getByProject(values?.projectId);

        const reportTypeRes = await reportTypeService.getReportTypeByCode(
          studyTypeCode
        );
        setReportType(reportTypeRes.data);
        await executeReport(id, reportTypeRes.data.code);

        if (user?.role === "SuperAdministrator" && user?.subscriptionId === 3)
          setMaxNumberOfTowers(2);
        if (user?.role === "Training") setMaxNumberOfTowers(2);
        if (
          values.operateFromApproximately !== null &&
          values.operateToApproximately !== null
        ) {
          getWorkingDaysDetail(
            values.operateFromApproximately,
            values.operateToApproximately
          );
        }
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      } finally {
        setIsLoading(false);
      }
    };

    if (id > 0) getData();
  });

  const getWorkingDaysDetail = (date1: string, date2: string) => {
    const d1 = moment(date1, "YYYY-MM-DD").toDate();
    const d2 = moment(date2, "YYYY-MM-DD").toDate();

    let weekDays = 0;
    let saturdays = 0;
    let sundays = 0;

    while (d1 <= d2) {
      var day = d1.getDay();
      switch (day) {
        case 0:
          sundays++;
          break;
        case 6:
          saturdays++;
          break;
        default:
          weekDays++;
          break;
      }
      d1.setDate(d1.getDate() + 1);
    }

    setDays({
      saturdays,
      sundays,
      weekDays,
    });

    return false;
  };

  useEffect(() => {
    calculateEstimatedAnualHoursOperated();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [days]);

  const mode: Mode = values.isComplete ? "read" : "read&Write";

  const disableStudy = useMemo(() => {
    return (
      values?.isComplete ||
      (dataReport?.isInUse && dataReport?.isInUseById !== user?.userId) ||
      dataReport?.isPartOfProject === false
    );
  }, [
    dataReport?.isInUse,
    dataReport?.isInUseById,
    dataReport?.isPartOfProject,
    user?.userId,
    values?.isComplete,
  ]);

  const calculateEstimatedAnualHoursOperated = () => {
    const hoursMonFri = values.estimatedAnnualHoursMonFri * days.weekDays;
    const hoursSat = values.estimatedAnnualHoursSaturday * days.saturdays;
    const hoursSun = values.estimatedAnnualHoursSunday * days.sundays;

    const estimatedAnnualHoursOperated = hoursMonFri + hoursSat + hoursSun;

    const estimatedAnnualOperatingDays =
      (values.estimatedAnnualHoursMonFri > 0 ? days.weekDays : 0) +
      (values.estimatedAnnualHoursSaturday > 0 ? days.saturdays : 0) +
      (values.estimatedAnnualHoursSunday > 0 ? days.sundays : 0);

    setValues({
      ...values,
      estimatedAnnualHoursOperated,
      estimatedAnnualOperatingDays,
    });
  };

  const compareDateFromAndTo = () => {
    if (
      values.operateToApproximately != null &&
      values.operateFromApproximately != null
    ) {
      if (
        Date.parse(values.operateToApproximately) <
        Date.parse(values.operateFromApproximately)
      ) {
        setShowErrorPopup(true);
        setValues({ ...values, operateToApproximately: null });
        return;
      }

      if (
        values.operateFromApproximately !== null &&
        values.operateToApproximately !== null
      )
        getWorkingDaysDetail(
          values.operateFromApproximately,
          values.operateToApproximately
        );
    }
  };

  useEffect(() => {
    compareDateFromAndTo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.operateToApproximately, values.operateFromApproximately]);

  const validateTowersDecrement = (typeCode: number) => {
    if (!validate()) return;

    switch (typeCode) {
      case 0:
        type = "save";
        break;
      case 1:
        type = "next";
        break;
      case 2:
        type = "back";
        break;
      case 3:
        type = "partial";
        break;
    }
    //Validating the number of Towers great than 0
    if (values.numberOfTowers === 0) {
      log.error("Please enter minimum 1 Tower");
      return;
    }

    const actualTotalTowers = values.numberOfTowers;
    if (lastTowerNumber > actualTotalTowers) {
      setShowDecreaseTowersModal(true);
    } else {
      _continue();
    }
  };

  const _continue = () => {
    setShowDecreaseTowersModal(false);
    switch (type) {
      case "save":
        save();
        break;
      case "next":
        next();
        break;
      case "partial":
        savePartial();
        break;
      default:
        break;
    }
  };

  const save = async () => {
    if (!validate()) return;

    const updateRes = await towerService.update(id, values);
    await studiesHistoryService.save(
      values.projectId,
      reportType?.id ?? 0,
      values.id,
      "saved"
    );

    log.info("Report Was saved");
    setValues(updateRes.data);
    lastTowerNumber = updateRes.data.numberOfTowers;
  };

  const next = async () => {
    const route = `/studies/Tower/step2/${id}`;
    if (values.isComplete) {
      navigate(route);
    } else {
      if (
        values.numberOfCondenserPumpsToBeTested > 0 &&
        values.numberOfCondenserPumpsToBeTestedCreated === false &&
        totalpump === 0
      ) {
        createprojectPump();
      } else {
        await towerService.update(id, values);
        await studiesHistoryService.save(
          values.projectId,
          reportType?.id ?? 0,
          values.id,
          "saved"
        );
        navigate(route);
      }
    }
  };

  let countotalpimp = 0;
  let totalpump = 0;
  const createprojectPump = () => {
    if (values.numberOfCondenserPumpsToBeTested > 0) {
      totalpump = values.numberOfCondenserPumpsToBeTested;
    }
    let i = 0;
    if (totalpump > 0) {
      for (i = 0; i < totalpump; i++) {
        createPump();
      }
    }
  };

  const getPtoject = async () => {
    const res = await getProjectSingleService.single(values?.projectId);
    return res.data;
  };

  const createPump = async () => {
    const project = await getPtoject();

    const projectObject: any = {
      projectId: project.id,
      reportTypeId: 1,
      buildingId: project.buildingId,
    };

    const pumpReportRes = await pumpReportService.save(projectObject);

    const projectReport: any = {
      reportId: pumpReportRes.data.id,
      projectId: project.id,
      reportTypeId: 1,
      parentReportId: id,
      parentReportTypeId: reportTypeId,
    };

    await projectReportService.save(projectReport);
    countotalpimp++;
    if (countotalpimp === totalpump) {
      setValues({ ...values, numberOfCondenserPumpsToBeTestedCreated: true });
    }
  };

  const savePartial = async () => {
    const updateRes = await towerService.update(id, values);
    await studiesHistoryService.save(
      values.projectId,
      reportType?.id ?? 0,
      values.id,
      "saved"
    );

    log.info("Report Was saved");
    setValues(updateRes.data);
    lastTowerNumber = updateRes.data.numberOfTowers;
  };

  return (
    <>
      <HeaderStudyPage
        headerStudiesPage={{
          code: "TOWE",
          system: values?.system,
          id: id,
        }}
        parentText="Project"
        parentLink="/"
      />
      <Divider my={6} />

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <StudyWizard
            steps={steps}
            activeStep={activeStep}
            projectId={dataReport?.projectId}
            reportId={dataReport?.reportId}
            reportTypeId={dataReport?.reportTypeId}
          />
        </Grid>
        <Grid item xs={12}>
          <Card mb={1}>
            <CardContent>
              <Grid container spacing={5}>
                <Grid item xs={12}>
                  <Typography variant="h6">System Information</Typography>
                </Grid>
                <Grid item xs={12}>
                  <FormText
                    name="projectName"
                    label="Project Name"
                    value={values.projectName}
                    onChange={handleInputChange}
                    error={errors.projectName}
                    showSkeleton={isLoading}
                    mode={mode}
                    disabled={disableStudy}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormText
                    name="system"
                    label="System(s) Served"
                    value={values.system}
                    onChange={handleInputChange}
                    error={errors.system}
                    showSkeleton={isLoading}
                    maxLength={50}
                    mode={mode}
                    disabled={disableStudy}
                    type="text"
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormText
                    name="ctSystem"
                    label="CT System"
                    value={values.ctSystem}
                    onChange={handleInputChange}
                    error={errors.ctSystem}
                    showSkeleton={isLoading}
                    maxLength={100}
                    mode={mode}
                    disabled={disableStudy}
                    type="text"
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormNumeric2
                    name="numberOfTowers"
                    label="Number of Towers"
                    value={values.numberOfTowers.toString()}
                    onChange={handleInputChange}
                    error={errors.numberOfTowers}
                    showSkeleton={isLoading}
                    maxValue={100}
                    mode={mode}
                    disabled={disableStudy}
                    decimalScale={0}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h6">System Design Data</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Stack direction="row" spacing={5}>
                    <FormDatePicker
                      name="operateFromApproximately"
                      label="Operated from approximately (Date)"
                      value={values.operateFromApproximately}
                      onChange={handleInputChange}
                      error={errors.operateFromApproximately}
                      mode={mode}
                      disable={disableStudy}
                      showSkeleton={isLoading}
                      outputFormat="YYYY-MM-DD"
                    />
                    <FormDatePicker
                      name="operateToApproximately"
                      label="Operated to (Date)"
                      value={values.operateToApproximately}
                      onChange={handleInputChange}
                      error={errors.operateToApproximately}
                      minDate={values.operateFromApproximately}
                      mode={mode}
                      disable={disableStudy}
                      showSkeleton={isLoading}
                      outputFormat="YYYY-MM-DD"
                    />
                  </Stack>
                </Grid>
                <Grid item xs={12}>
                  <FormNumeric2
                    name="estimatedAnnualHoursMonFri"
                    label='"Estimated" Daily Operating Hours Mon - Fri (Hrs)'
                    value={values?.estimatedAnnualHoursMonFri ?? 0}
                    onChange={handleInputChange}
                    onBlur={calculateEstimatedAnualHoursOperated}
                    error={errors.estimatedAnnualHoursMonFri}
                    showSkeleton={isLoading}
                    mode={mode}
                    disabled={disableStudy}
                    decimalScale={0}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormNumeric2
                    name="estimatedAnnualHoursSaturday"
                    label='"Estimated" Daily Operating Hours Saturday (Hrs)'
                    value={values?.estimatedAnnualHoursSaturday ?? 0}
                    onChange={handleInputChange}
                    onBlur={calculateEstimatedAnualHoursOperated}
                    error={errors.estimatedAnnualHoursSaturday}
                    showSkeleton={isLoading}
                    mode={mode}
                    disabled={disableStudy}
                    decimalScale={0}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormNumeric2
                    name="estimatedAnnualHoursSunday"
                    label='"Estimated" Daily Operating Hours Sunday (Hrs)'
                    value={values?.estimatedAnnualHoursSunday ?? 0}
                    onChange={handleInputChange}
                    onBlur={calculateEstimatedAnualHoursOperated}
                    error={errors.estimatedAnnualHoursSunday}
                    showSkeleton={isLoading}
                    mode={mode}
                    disabled={disableStudy}
                    decimalScale={0}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormNumeric2
                    name="estimatedAnnualHoursOperated"
                    label='"Estimated" Annual Operating Hours (Hrs)'
                    value={values?.estimatedAnnualHoursOperated ?? 0}
                    onChange={handleInputChange}
                    error={errors.estimatedAnnualHoursOperated}
                    showSkeleton={isLoading}
                    mode={mode}
                    disabled={disableStudy}
                    decimalScale={0}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormNumeric2
                    name="estimatedAnnualOperatingDays"
                    label='"Estimated" Annual Operating Days (Days)'
                    value={values?.estimatedAnnualOperatingDays ?? 0}
                    onChange={handleInputChange}
                    error={errors.estimatedAnnualOperatingDays}
                    showSkeleton={isLoading}
                    mode={mode}
                    disabled={disableStudy}
                    decimalScale={0}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Stack direction={"row"}>
                    <FormNumeric2
                      name="numberOfCells"
                      label="Number of Cells"
                      value={values?.numberOfCells ?? 0}
                      onChange={handleInputChange}
                      error={errors.numberOfCells}
                      showSkeleton={isLoading}
                      mode={mode}
                      disabled={disableStudy}
                      decimalScale={0}
                    />
                    <Tooltip
                      TransitionComponent={Zoom}
                      title={
                        "If one tower test is being done as a whole, enter 1."
                      }
                    >
                      <span>
                        <IconButton aria-label="delete" size="large">
                          <InfoIcon />
                        </IconButton>
                      </span>
                    </Tooltip>
                  </Stack>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h6">Cooling Tower Design</Typography>
                </Grid>
                <Grid item xs={12}>
                  <FormNumeric2
                    name="designRatedAbientAirTempWb"
                    label="Design/Rated Ambient Air Temp (Wb)"
                    value={values.designRatedAbientAirTempWb}
                    onChange={handleInputChange}
                    onBlur={() => validateTowersDecrement(3)}
                    error={errors.designRatedAbientAirTempWb}
                    maxValue={1000000000}
                    thousandSeparator={true}
                    decimalScale={3}
                    fixedDecimalScale={true}
                    mode={mode}
                    disabled={disableStudy}
                    showSkeleton={isLoading}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormNumeric2
                    name="designRatedGPMTotal"
                    label={`Design/Rated ${systemOfMeasurement.get(
                      "gpm"
                    )} Total`}
                    value={values.designRatedGPMTotal}
                    onChange={handleInputChange}
                    onBlur={() => validateTowersDecrement(3)}
                    error={errors.designRatedGPMTotal}
                    maxValue={1000000000}
                    thousandSeparator={true}
                    decimalScale={3}
                    fixedDecimalScale={true}
                    mode={mode}
                    disabled={disableStudy}
                    showSkeleton={isLoading}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormNumeric2
                    name="desginRatedWarmEwt"
                    label={`Design/Rated (Warm) EWT (${systemOfMeasurement.get(
                      "temperature"
                    )})`}
                    value={values.desginRatedWarmEwt}
                    onChange={handleInputChange}
                    onBlur={() => validateTowersDecrement(3)}
                    error={errors.desginRatedWarmEwt}
                    maxValue={1000000000}
                    thousandSeparator={true}
                    decimalScale={3}
                    fixedDecimalScale={true}
                    mode={mode}
                    disabled={disableStudy}
                    showSkeleton={isLoading}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormNumeric2
                    name="designRatedColdLwt"
                    label={`Design/Rated (Cold) LWT (${systemOfMeasurement.get(
                      "temperature"
                    )})`}
                    value={values.designRatedColdLwt}
                    onChange={handleInputChange}
                    onBlur={() => validateTowersDecrement(3)}
                    error={errors.designRatedColdLwt}
                    maxValue={1000000000}
                    thousandSeparator={true}
                    decimalScale={3}
                    fixedDecimalScale={true}
                    mode={mode}
                    disabled={disableStudy}
                    showSkeleton={isLoading}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormNumeric2
                    name="evaporatorDesignATFCcondenserDesignBTUHondenser"
                    label={`Condenser Design ΔT (${systemOfMeasurement.get(
                      "temperature"
                    )})`}
                    value={values.evaporatorDesignATFCondenser}
                    onChange={handleInputChange}
                    onBlur={() => validateTowersDecrement(3)}
                    error={errors.evaporatorDesignATFCondenser}
                    maxValue={1000000000}
                    thousandSeparator={true}
                    decimalScale={3}
                    fixedDecimalScale={true}
                    mode="read"
                    showSkeleton={isLoading}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormNumeric2
                    name="condenserDesignBTUH"
                    label={`Condenser Design ${systemOfMeasurement.get(
                      "btuh"
                    )}`}
                    value={values.condenserDesignBTUH}
                    onChange={handleInputChange}
                    onBlur={() => validateTowersDecrement(3)}
                    error={errors.condenserDesignBTUH}
                    maxValue={1000000000}
                    thousandSeparator={true}
                    decimalScale={3}
                    fixedDecimalScale={true}
                    mode="read"
                    showSkeleton={isLoading}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormNumeric2
                    name="numberOfCellsOnLineToBeTested"
                    label='Number of Cells "on Line" to be Tested'
                    value={values.numberOfCellsOnLineToBeTested}
                    onChange={handleInputChange} //TODO: Extra
                    error={errors.numberOfCellsOnLineToBeTested}
                    maxValue={1000000}
                    decimalScale={0}
                    mode={mode}
                    disabled={disableStudy}
                    showSkeleton={isLoading}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormSelect
                    name="numberOfCondenserPumpsProvidingFlowToThisTower"
                    label="Number of Condenser pumps providing flow to this tower"
                    size="small"
                    value={
                      values.numberOfCondenserPumpsProvidingFlowToThisTower
                    }
                    onChange={handleInputChange}
                    options={numberOfCondenserPumps}
                    error={
                      errors.numberOfCondenserPumpsProvidingFlowToThisTower
                    }
                    fullWidth={true}
                    mode={mode}
                    disabled={disableStudy}
                    showSkeleton={isLoading}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormSelect
                    name="numberOfCondenserPumpsToBeTested"
                    label="Number of condenser pumps to be tested"
                    size="small"
                    value={values?.numberOfCondenserPumpsToBeTested ?? 0}
                    onChange={handleInputChange}
                    options={numberOfCondenserPumps.filter(
                      (item) =>
                        item.key <=
                        values.numberOfCondenserPumpsProvidingFlowToThisTower
                    )}
                    defaultValue={{ key: 0, value: 0 }}
                    error={errors.numberOfCondenserPumpsToBeTested}
                    fullWidth={true}
                    mode={mode}
                    disabled={disableStudy}
                    showSkeleton={isLoading}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FooterButtons
                    activeStep={activeStep}
                    stepsCount={steps.length}
                    projectId={dataReport?.projectId}
                    companyId={user?.companyId as number}
                    isSaving={false}
                    disabled={false}
                    reportName={reportType?.name ?? ""}
                    saveHandler={save}
                    nextStepHandler={() => validateTowersDecrement(1)}
                    hideSaveButton={disableStudy}
                    isComplete={values?.isComplete ?? true}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <DialogMessagePopup
        title={"Error"}
        text={
          <div>
            "Operation Start Date must not be greater or later then its End
            Date. Please re-enter an earlier Start Date or later End Date.
          </div>
        }
        isSubmitting={false}
        showPopup={showDateErrorPopup}
        setShowPopup={setShowErrorPopup}
        onSave={() => {
          setShowErrorPopup(false);
        }}
        hideAccept={true}
        cancelTextButton="Close"
      />
      <DialogMessagePopup
        title={"Error"}
        text={
          <div>
            You are about to decrease the number of Towers in this Report wich
            will be removed permanently. Are you certain you want to continue?
          </div>
        }
        isSubmitting={false}
        showPopup={showDecreaseTowersModal}
        setShowPopup={setShowDecreaseTowersModal}
        onSave={() => {
          _continue();
        }}
      />
    </>
  );
};

export default TowerStep1;
