import { INewRecommendations } from "src/ts/interfaces/recommendations";
import axios from "../utils/axios";

const create = (recommentations: INewRecommendations) => {
  return axios.post("Recommendations", recommentations);
};

const getByUser = (startDate: string, endDate: string, search: string) => {
  return axios.get(
    `Recommendations/ByUser?start=${startDate}&end=${endDate}&search=${search}`
  );
};

const getAll = (startDate: string, endDate: string, search: string) => {
  return axios.get(
    `Recommendations/GetAll?start=${startDate}&end=${endDate}&search=${search}`
  );
};

const getByIdUser = (id: number, statusId: number) => {
  return axios.get(`Recommendations/${id}?statusId=${statusId}`);
};

const getByStatus = (statusId: number, startDate: string, endDate: string) => {
  return axios.get(
    `Recommendations/GetSearch?statusId=${statusId}&start=${startDate}&end=${endDate}`
  );
};

const updateType = (id: number, type: number) => {
  return axios.post(
    `Recommendations/UpdateType?recommendationId=${id}&typeId=${type}`
  );
};

const reward = (id: number) => {
  return axios.post(
    `Recommendations/UpdateStatusToReawrded?recommendationId=${id}`
  );
};

const recommendationsService = {
  create,
  getByUser,
  getAll,
  getByIdUser,
  getByStatus,
  updateType,
  reward,
};

export default recommendationsService;
