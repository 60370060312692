import { ChillerStudyProvider } from "../context/ChillerStudyContext";
import ChillerStep7 from "./ChillerStep7";
const Index = () => {
  return (
    <ChillerStudyProvider>
      <ChillerStep7 />
    </ChillerStudyProvider>
  );
};

export default Index;
