import axios from "../utils/axios";

const getCheckUserPermission = async (projectId: number) => {
  return axios.get(
    `Permissions/checkUserPermissionRouteProject?projectId=${projectId}`
  );
};

const permissionService = {
  getCheckUserPermission,
};

export default permissionService;
