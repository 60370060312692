import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import {
  CardContent,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";
import { ITechnicianQuizCategory } from "src/ts/interfaces";
import { technicianQuizCategoryService } from "src/services";
import { useLoading, useLog } from "src/hooks";
import QuestionsCatalog from "./QuestionsCatalog";
import FormAccordion from "src/components/formControls/FormAccordion";

const Card = styled(MuiCard)(spacing);
const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Questions = () => {
  const { log } = useLog();
  const [category, setCategory] = useState<ITechnicianQuizCategory[]>([]);
  const { startRequest, endRequest } = useLoading();
  const [refreshPage] = useState(false);
  const [expanded, setExpanded] = React.useState<string | false>(false);
  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };
  useEffect(() => {
    const getData = async () => {
      try {
        startRequest("Category");
        const request = await technicianQuizCategoryService.getAll();
        setCategory(request.data);
        endRequest("Category");
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
        endRequest("Category");
        throw error;
      }
    };

    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshPage]);

  const listItemsSelected = category.map(function (item) {
    return (
      <div>
        <FormAccordion
          title={item.name}
          expanded={expanded === `selected${item.id}`}
          onChange={handleChange(`selected${item.id}`)}
        >
          <Typography variant="subtitle1" color="textSecondary">
            <QuestionsCatalog
              data={item.technicianQuizBacklog}
              categoryId={item.id}
            ></QuestionsCatalog>
          </Typography>
        </FormAccordion>
      </div>
    );
  });

  return (
    <>
      <Helmet title="Blank" />
      <Typography variant="h3" gutterBottom display="inline">
        Questions
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/">
          Quiz
        </Link>
        <Typography>Questions</Typography>
      </Breadcrumbs>

      <Divider my={6} />
      <Card mb={6}>
        <CardContent>{listItemsSelected}</CardContent>
      </Card>
    </>
  );
};

export default Questions;
