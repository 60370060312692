import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { useForm, useLog } from "src/hooks";
import { Form } from "src/hooks/useForm";
import {
  FormCancelButton,
  FormSaveButton,
  FormSelect,
  FormText,
} from "src/components/formControls";
import Popup from "src/components/Popup";
import { Validator } from "src/ts/types/Validator";
import { IKeyValue } from "src/ts/interfaces";
import { Permission as PermissionTypes } from "src/ts/enums";
import { usePermissions } from "src/hooks";
import { ILocalUnions } from "src/ts/interfaces/catalogs/localUnions";
import laborUnions from "src/services/catalogs/laborUnionsService";
import localUnionsService from "src/services/catalogs/localUnionsService";

interface LocalUnionsFormProps {
  selectedValue?: ILocalUnions;
  isDialogOpen: boolean;
  setIsDialogOpen: (val: boolean) => void;
  onUpdate: () => void;
}

const initialValues: ILocalUnions = {
  id: 0,
  name: "",
  laborUnionsId: 0,
  orderPosition: 0,
  laborUnionsName: "",
  createdDate: new Date(),
  createdById: 0,
  updatedDate: new Date(),
  updatedId: 0,
  statusId: 1,
};

const LocalUnionsForm = (props: LocalUnionsFormProps) => {
  const { selectedValue, isDialogOpen, setIsDialogOpen, onUpdate } = props;
  const { log } = useLog();
  const [laborUnionsKeyValue, setLaborUnionsKeyValue] = useState<
    IKeyValue<number, string>[]
  >([]);
  const [isSubmiting, setIsSubmiting] = useState(false);

  const validate = (fieldValues = values) => {
    let temp: Record<string, string> = { ...errors };

    temp.name = new Validator(fieldValues, "name")
      .isRequired("The SystemCostsCatalog's name is required")
      .maxLength(200, "Only 200 character are allowed")
      .toString();

    temp.laborUnionsId = new Validator(fieldValues, "laborUnionsId")
      .selectedOption("", "The Union Organizations's is required.")
      .toString();

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues, false, validate);

  useEffect(() => {
    if (isDialogOpen) setValues(selectedValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDialogOpen]);

  useEffect(() => {
    const getLaborUnions = async () => {
      const response = await laborUnions.getKeyValues();
      setLaborUnionsKeyValue(response);
    };

    getLaborUnions();
  }, []);

  const handleClose = () => {
    resetForm();
    setIsDialogOpen(false);
  };

  const handleSave = async () => {
    if (!validate()) return;
    try {
      setIsSubmiting(true);
      if (values.id === 0) {
        await localUnionsService.add(values);
        log.success("The Local Unions was added succesfully");
      } else {
        await localUnionsService.update(values);
        log.success("The Local Unions was updated succesfully");
      }
    } catch (error: any) {
      log.error(error?.message?.exceptionMessage ?? "Something went wrong");
    }
    setIsSubmiting(false);
    setIsDialogOpen(false);
    onUpdate();
  };

  const { fullAccess, readOnly } = usePermissions(PermissionTypes.Catalogs);

  const row = 12;
  return (
    <>
      <Popup
        openPopup={isDialogOpen}
        setOpenPopup={setIsDialogOpen}
        title={fullAccess ? (values.id === 0 ? "New" : "Edit") : "view"}
        onClose={() => setIsDialogOpen(false)}
      >
        <Form>
          <Grid container spacing={5}>
            <Grid item xs={row}>
              <FormText
                name="name"
                label="Name"
                value={values.name}
                onChange={handleInputChange}
                error={errors.name}
                showSkeleton={false}
                disabled={readOnly}
              />
            </Grid>
            <Grid item xs={row}>
              <FormSelect
                name="laborUnionsId"
                label="Labor Unions"
                value={values.laborUnionsId}
                onChange={handleInputChange}
                options={laborUnionsKeyValue}
                error={errors.laborUnionsId}
                disable={readOnly}
                showSkeleton={false}
                defaultValue={{ key: -1, value: "Select a value" }}
              />
            </Grid>
          </Grid>
        </Form>
        <>
          <FormCancelButton onClick={handleClose} isSubmitting={isSubmiting} />
          {fullAccess && (
            <FormSaveButton onClick={handleSave} isSubmitting={isSubmiting} />
          )}
        </>
      </Popup>
    </>
  );
};

export default LocalUnionsForm;
