import React, { useEffect } from "react";
import { Box, Stack, Typography } from "@mui/material";
import Popup from "../Popup";
import { FormButton } from "../formControls";
import { useAsyncQuery } from "src/hooks";
import { projectIsCompleteService } from "src/services";
import { HistoryProjectCostDTO } from "src/ts/interfaces";
import FormAccordion from "../formControls/FormAccordion";
interface Props {
  showPopup: boolean;
  setShowPopup: (val: boolean) => void;
}

const TestingUnitCostModal = ({ showPopup, setShowPopup }: Props) => {
  const [expanded, setExpanded] = React.useState<string | false>(false);

  const { execute, data } = useAsyncQuery<HistoryProjectCostDTO>(
    projectIsCompleteService.getCheckPricing
  );

  useEffect(() => {
    const getData = async () => {
      await execute(0);
    };

    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };
  return (
    <Popup
      title={"Information"}
      openPopup={showPopup}
      setOpenPopup={setShowPopup}
      onClose={() => {
        setShowPopup(false);
      }}
      size={"lg"}
    >
      <Stack>
        <div>
          <FormAccordion
            title="General Testing, Balancing & Commissioning"
            expanded={expanded === "panel1"}
            onChange={handleChange("panel1")}
          >
            <Typography component="div">
              General Testing, Balancing & Commissioning Unit Cost Per Completed
              Report. Unit cost is not project size, project time or quantity of
              studies dependent. Unit cost provides full systems testing,
              balancing & HVAC Unit Commissioning:{" "}
              <Box
                fontWeight={"fontWeightBold"}
                color={"green"}
                display="inline"
              >
                ${data?.generalTestingBalancingCommissioning ?? "NA"} USD
              </Box>
            </Typography>
          </FormAccordion>
          <FormAccordion
            title="Single HVAC System Heat Transfer Test – Individual Energy Unit
            Testing"
            expanded={expanded === "panel2"}
            onChange={handleChange("panel2")}
          >
            <Typography>
              Single “As Found” HVAC System Heat Transfer Test – Individual
              Energy Unit Cost Per Completed Report. Unit cost is based on each
              individual HVAC system tested, using AMP’s Patented testing
              procedures which optimizes system Heat Transfer Performance with
              less than 1.0% testing error results:{" "}
              <Box
                fontWeight={"fontWeightBold"}
                color={"green"}
                display="inline"
              >
                ${data?.singleHvacSystemHeatTransferTest ?? "NA"} USD
              </Box>{" "}
              per HVAC system.
            </Typography>
          </FormAccordion>
          <FormAccordion
            title="Building Energy Efficiency, Infectious Disease Compliancy
            Testing (Plaque)"
            expanded={expanded === "panel3"}
            onChange={handleChange("panel3")}
          >
            <Typography>
              Building Energy Efficiency, Infectious Disease Compliancy Testing
              (Plaque) Unit Cost. Unit cost is based on each individual HVAC
              system tested, using Patented testing procedures which optimizes
              system Heat Transfer Performance with less than 1.0% testing error
              results. Optimal setting of the design/required Outside Air (O/A)
              qualifies for a building plaque identifying a building as Energy
              Efficient and Infectious Disease Compliant.
              <br />
              <br />
              AMP Engineers work real-time/live through the software processes
              with the contractors or energy professionals performing the
              testing. AMP Engineers review the testing results, respond to any
              issues found and make recommendations to ensure system
              optimization occurs, energy savings are maximized, and prescribed
              building O/A is reached for elevated IAQ.
              <br />
              <br />
              Each system test provides 2 full patented system tests for “As
              Found system operation”, which then allows for prescriptive
              measures to occur for total system optimization, and final
              reporting showing “As Left Results”. AMP’s Building Energy
              Efficiency, Infectious Disease Compliancy Testing fee includes
              real-time AMP Engineer guidance regardless of project size,
              project time or quantity of HVAC systems tested:{" "}
              <Box
                fontWeight={"fontWeightBold"}
                color={"green"}
                display="inline"
              >
                ${data?.covidProject ?? "NA"} USD
              </Box>{" "}
              Per building tested
              <br />
              <br />
              Two Tests described as: As Found and As Left, Single HVAC System
              Testing and Optimization. Unit cost is based on each individual
              HVAC system tested, using Patented testing procedures with
              optimized system Heat Transfer Performance, including O/A delivery
              with less than 1.0% testing error results:{" "}
              <Box
                fontWeight={"fontWeightBold"}
                color={"green"}
                display="inline"
              >
                ${data?.buildingEnergyEfficiency ?? "NA"} USD
              </Box>{" "}
              Per building tested
            </Typography>
          </FormAccordion>
        </div>
      </Stack>

      <Stack
        direction="row"
        spacing={2}
        justifyContent="flex-end"
        alignItems="flex-start"
      >
        <FormButton
          text="Close"
          size="medium"
          onClick={() => {
            setShowPopup(false);
          }}
          variant="outlined"
        />
      </Stack>
    </Popup>
  );
};

export default TestingUnitCostModal;
