import axios from "../utils/axios";
import {
  IPostTicketsRequest,
  IUploadTicketImage,
  IPostTicketChangePriority,
  IPostCommentTicket,
  IPostAssignTicket,
  ITicketFilter,
  ITicketDataPage,
} from "../ts/interfaces/tickets";
import { getFilter } from "../utils/utils";

const postTicket = async (values: IPostTicketsRequest) => {
  return await axios.post(`Tickets/Create`, values);
};

const getAllTicketClassification = async () => {
  return axios.get("TicketClassification");
};

const uploadFile = async (file: IUploadTicketImage) => {
  let bodyFormData = new FormData();

  bodyFormData.append("fileForUpload", file.content);
  bodyFormData.append("key", JSON.stringify(file.key));

  return await axios({
    method: "post",
    url: "/Tickets/CreateAttachment",
    data: bodyFormData,
    headers: { "Content-Type": "multipart/form-data" },
  });
};

const getAll = async (
  filter: ITicketFilter,
  getQuery?: (filters: Record<string, any>) => string
) => {
  const queryString = getQuery ? getQuery(filter) : getFilter(filter);

  return await axios.get<ITicketDataPage>(`Tickets/GetAll?${queryString}`);
};

const postChangePriority = async (values: IPostTicketChangePriority) => {
  return await axios.post(
    `Tickets/Priority?ticketId=${values.ticketId}&priority=${values.priority}`
  );
};

const getImage = async (ticketId: number) => {
  return axios.get(`Tickets/file?id=${ticketId}`);
};

const postCommentTicket = async (values: IPostCommentTicket) => {
  return await axios.post(`Tickets/Close`, values);
};

const postAssign = async (values: IPostAssignTicket) => {
  return axios.post(
    `Tickets/Assign?userId=${values.userId}&ticketId=${values.ticketId}`
  );
};

const TicketService = {
  postTicket,
  getAllTicketClassification,
  uploadFile,
  getAll,
  postChangePriority,
  getImage,
  postCommentTicket,
  postAssign,
};

export default TicketService;
