import Popup from "src/components/Popup";
import { FormButton, FormText } from "src/components/formControls";
import { Stack } from "@mui/material";
import { useAsyncMutation, useForm } from "src/hooks";
import projectBidOtherService from "src/services/project/projectBidOtherService";
import { IProjectBidOtherConfiguration } from "src/ts/interfaces/project/projectDto";

interface EditOtherBidProps {
  editOtherBidPropsShowPopUp: boolean;
  setEditOtherBidPropsShowPopUp: (val: boolean) => void;
  id: number;
  descriptionBid: string;

  setProjectBid: (val: IProjectBidOtherConfiguration[]) => void;
  projectBid: IProjectBidOtherConfiguration[];
}

export default function EditOtherBid({
  editOtherBidPropsShowPopUp,
  setEditOtherBidPropsShowPopUp,
  id,
  descriptionBid,

  setProjectBid,
  projectBid,
}: EditOtherBidProps) {
  const initialValue = {
    description: descriptionBid,
  };
  const { values, handleInputChange } = useForm(initialValue, false, []);

  const { execute: updateDescription, isSubmitting: isSubmittingDescription } =
    useAsyncMutation(projectBidOtherService.putOtherBid, {
      successfulMessage: "The permission was updated",
    });

  const handlerUpdate = async () => {
    updateDescription(id, values.description);
    const updatedProjectBid = projectBid.map(
      (item: IProjectBidOtherConfiguration) => {
        if (item.id === id) {
          return {
            ...item,
            description: values.description,
            [`description${item.id}`]: values.description,
          };
        }
        return item;
      }
    );
    setProjectBid(updatedProjectBid);

    setEditOtherBidPropsShowPopUp(false);
  };
  return (
    <>
      <Popup
        title={"Other Bid"}
        openPopup={editOtherBidPropsShowPopUp}
        setOpenPopup={setEditOtherBidPropsShowPopUp}
        onClose={() => {
          setEditOtherBidPropsShowPopUp(false);
        }}
        size="sm"
      >
        <>
          <FormText
            name={"description"}
            label={"Description"}
            value={values.description}
            onChange={handleInputChange}
          />
        </>
        <Stack direction="row" spacing={3}>
          <FormButton
            size="small"
            text="Close"
            variant="outlined"
            onClick={() => {
              setEditOtherBidPropsShowPopUp(false);
            }}
            isSubmitting={isSubmittingDescription}
          />
          <FormButton
            size="small"
            text="Update"
            color="primary"
            onClick={() => {
              handlerUpdate();
            }}
            isSubmitting={isSubmittingDescription}
          />
        </Stack>
      </Popup>
    </>
  );
}
