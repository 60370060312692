import { Alert, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { buildingService } from "src/services";

interface Props {
  updateControlStep: (step: number, value: boolean) => void;
  value: boolean;
  selectedBuilding: number;
}
const step = 3;

const Validations = ({ updateControlStep, value, selectedBuilding }: Props) => {
  const [buildingSpecificsCheck, setBuildingSpecificsCheck] =
    useState<number>(0);
  const [buildingActiveCovidProjectCheck, setBuildingActiveCovidProjectCheck] =
    useState<number>(0);
  const [, setBuildingActiveCovidProjectId] = useState<number>(0);

  useEffect(() => {
    const getData = async () => {
      const res = await buildingService.checkCovidAvailability(
        selectedBuilding
      );
      setBuildingSpecificsCheck(res.data & 0x01);
      setBuildingActiveCovidProjectCheck((res.data & 0x02) >> 1);
      setBuildingActiveCovidProjectId((res.data & 0xfffffffc) >> 2);
    };

    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (buildingSpecificsCheck || buildingActiveCovidProjectCheck) {
      updateControlStep(step, false);
    } else {
      updateControlStep(step, true); //TODO: Check this with Argenis
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buildingSpecificsCheck, buildingActiveCovidProjectCheck]);

  return (
    <>
      <Typography variant="h4" gutterBottom pt={10} pb={4} align="center">
        Checking previous validations
      </Typography>
      {!buildingSpecificsCheck && (
        <>
          <Alert color="success">
            Building Specifics information is complete
          </Alert>
          <br />
        </>
      )}
      {!!buildingSpecificsCheck && (
        <>
          <Alert color="warning">
            This building has incomplete data in the "Specifics" section.
          </Alert>
          <br />
        </>
      )}
      {!!buildingSpecificsCheck && (
        <>
          <Alert color="info">
            Go to Edit and complete this buildings Specifics data first.
          </Alert>
          <br />
        </>
      )}
      {!buildingActiveCovidProjectCheck && (
        <>
          <Alert color="success">
            This specific Building does not have another <b>ACTIVE</b>{" "}
            Infectious Control Compliancy Project currently in progress
          </Alert>
          <br />
        </>
      )}
      {!!buildingActiveCovidProjectCheck && (
        <>
          <Alert color="warning">
            This building already has an ACTIVE Infectious Control Compliancy
            Project in progress.
          </Alert>
          <br />
        </>
      )}
      {!!buildingActiveCovidProjectCheck && (
        <>
          <Alert color="info">Go to Project detailed information.</Alert>
          <br />
        </>
      )}
    </>
  );
};

export default Validations;
