import { useContext } from "react";
import { OutletReportContext } from "src/pages/studies/outlet/contexts/OutletReportContext";

const useOutletReport = () => {
  const context = useContext(OutletReportContext);

  if (!context)
    throw new Error(
      "OutletReportContext must be placed within OutletReportProvider"
    );

  return context;
};

export default useOutletReport;
