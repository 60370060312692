import React, { ChangeEvent } from "react";
import { Stack } from "@mui/material";
import { FormButton, FormUploadButton } from "src/components/formControls";
import useAuth from "src/hooks/useAuth";
import { FolderIcon, ArrowUpwardIcon } from "src/components/icons";
import { ROLES } from "src/constants";

interface Props {
  setShowAddDirectoryPopup: (value: boolean) => void;
  disableButtons: boolean;
  parent: number | null | undefined;
  onGotoUp: () => void;
  onFileUpload: (e: ChangeEvent<HTMLInputElement>) => Promise<void>;
  isUploadingFile: boolean;
  isProjectCompleted: boolean;
  viewMode: boolean;
  viewModeArchived: boolean;
}

function Actions({
  setShowAddDirectoryPopup,
  disableButtons,
  parent,
  onGotoUp,
  onFileUpload,
  isUploadingFile,
  isProjectCompleted,
  viewMode,
  viewModeArchived,
}: Props) {
  const { user } = useAuth();

  return (
    <React.Fragment>
      <Stack direction="row" spacing={2}>
        {user?.canAddBuildingsAndProjects && (
          <>
            {!isProjectCompleted && (
              <FormButton
                text={"Create Folder"}
                onClick={() => setShowAddDirectoryPopup(true)}
                startIcon={<FolderIcon />}
                size="small"
                color="success"
                disabled={disableButtons || viewMode || viewModeArchived}
              />
            )}
            {user?.role !== ROLES.Training &&
              !isProjectCompleted &&
              !viewMode && (
                <FormUploadButton
                  accept=".pdf"
                  onChange={(e: any) => onFileUpload(e)}
                  text={"Upload File"}
                  size="small"
                  disabled={disableButtons || viewModeArchived}
                  isSubmitting={isUploadingFile}
                />
              )}
            <FormButton
              text={"Go To Previous Folder"}
              onClick={() => onGotoUp()}
              startIcon={<ArrowUpwardIcon />}
              size="small"
              disabled={parent === null}
            />
          </>
        )}
      </Stack>
    </React.Fragment>
  );
}

export default Actions;
