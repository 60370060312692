import React, { useEffect } from "react";
import { Grid, Stack } from "@mui/material";
import { useForm, Form } from "src/hooks";
import {
  FormCancelButton,
  FormSaveButton,
  FormText,
} from "src/components/formControls";
import FormNumeric from "src/components/formControls/FormNumeric";
import Popup from "src/components/Popup";
import { IPictureLogDto } from "src/ts/interfaces";
import { Validator } from "src/ts/types/Validator";

interface ConfirmModalProps {
  isDialogOpen: boolean;
  setIsDialogOpen: (val: boolean) => void;
  onSave: (pictureLog: IPictureLogDto) => void;
  picture: IPictureLogDto | null;
}

const initialValues: IPictureLogDto = {
  title: "",
  description: "",
  orderNumber: 0,
  id: 0,
  name: "",
  type: "",
  size: 0,
  projectId: 0,
  path: "",
  thumbPath: "",
  document: "",
  thumbDocument: "",
  createdDate: "",
  createdBy: 0,
  isReadOnly: false,
};

const EditPictureLogModal = (props: ConfirmModalProps) => {
  const { isDialogOpen, setIsDialogOpen, picture, onSave } = props;
  const validate = (fieldValues = values) => {
    let temp: Record<string, string> = { ...errors };

    temp.title = new Validator(fieldValues, "title").toString();

    temp.description = new Validator(fieldValues, "description").toString();

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange } = useForm(
    picture ?? initialValues,
    false,
    validate
  );

  useEffect(() => {
    if (picture !== null) {
      setValues({ ...picture });
    }
  }, [picture, setValues]);

  const saveHandler = async () => {
    if (!validate()) return;
    onSave(values);
  };

  const cancelHandler = () => {
    setIsDialogOpen(false);
  };

  const row = 12;
  return (
    <Popup
      openPopup={isDialogOpen}
      setOpenPopup={setIsDialogOpen}
      title={`Add New Item`}
      onClose={cancelHandler}
    >
      <Form>
        <Grid container spacing={5}>
          <Grid item xs={row}>
            <FormText
              name="title"
              label="Title"
              value={values.title}
              onChange={handleInputChange}
              error={errors.title}
              showSkeleton={false}
              maxLength={50}
            />
          </Grid>
          <Grid item xs={row}>
            <FormText
              name="description"
              label="Description"
              value={values.description}
              onChange={handleInputChange}
              error={errors.description}
              showSkeleton={false}
              maxLength={100}
            />
          </Grid>
          <Grid item xs={row}>
            <FormNumeric
              name="orderNumber"
              label="Order number"
              value={values.orderNumber}
              onChange={handleInputChange}
              error={errors.orderNumber}
              placeholder=""
            />
          </Grid>
        </Grid>
      </Form>
      <>
        <Stack
          direction="row"
          spacing={2}
          justifyContent="center"
          alignItems="center"
        >
          <FormCancelButton onClick={cancelHandler} isSubmitting={false} />
          <FormSaveButton onClick={saveHandler} isSubmitting={false} />
        </Stack>
      </>
    </Popup>
  );
};

export default EditPictureLogModal;
