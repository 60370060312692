import { useEffect, useState } from "react";
import { Grid, Switch, Typography, Stack } from "@mui/material";
import Popup from "src/components/Popup";
import { FormCancelButton } from "src/components/formControls";
import emailUnsubscribeService from "src/services/emailUnsubscribeService";
import {
  IEmailClassification,
  IEmailTemplateNotification,
} from "src/ts/interfaces";
import { useLog } from "src/hooks";
import emailTemplateService from "src/services/emailTemplateService";
import FormAccordion from "src/components/formControls/FormAccordion";
import React from "react";

interface ConfigureNotificationPopupProps {
  showModal: boolean;
  showModalHandler: (show: boolean) => void;
}

const ConfigureNotificationPopup = (props: ConfigureNotificationPopupProps) => {
  const { showModal, showModalHandler } = props;
  const [emailClassifications, setEmailClassifications] =
    useState<IEmailClassification[]>();
  const { log } = useLog();
  const [notifications, setNotifications] =
    useState<IEmailTemplateNotification[]>();
  const [expanded, setExpanded] = React.useState<string | false>(false);

  useEffect(() => {
    const loadData = async () => {
      const responseEmailClassification =
        await emailUnsubscribeService.getEmailClassification();
      setEmailClassifications(responseEmailClassification.data);

      const responseNotifications =
        await emailTemplateService.getUserNotifications();
      setNotifications(responseNotifications.data);
    };

    if (showModal) loadData();
  }, [showModal]);

  const saveNotification = async (id: number) => {
    return await emailUnsubscribeService.saveNotification(id);
  };

  const deleteNotification = async (id: number) => {
    return await emailUnsubscribeService.deleteNotification(id);
  };

  const NotificationCheck = ({
    notification,
  }: {
    notification: IEmailTemplateNotification;
  }) => {
    const [checked, setChecked] = useState(notification.unsubscribeId === 0);
    const [notificationState, setNotificationState] =
      useState<IEmailTemplateNotification>(notification);
    return (
      <Stack direction="row" justifyContent="space-between">
        <Typography pt={2}>{notificationState.label}</Typography>
        <Switch
          checked={checked}
          onChange={async (e) => {
            if (checked) {
              const saveResponse = await saveNotification(notificationState.id);
              setNotificationState({
                ...notificationState,
                unsubscribeId: saveResponse.data.id,
              });
              log.info("The email notification has been deactivated");
            } else {
              await deleteNotification(notificationState.unsubscribeId);
              log.success("The email notification has been activated");
            }
            setChecked(!checked);
          }}
          value={checked}
        />
      </Stack>
    );
  };

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  return (
    <>
      <Popup
        openPopup={showModal}
        setOpenPopup={showModalHandler}
        title="Configure Email Notifications"
        onClose={() => showModalHandler(false)}
      >
        <Grid container spacing={6}>
          <Grid item xs={12}>
            {emailClassifications &&
              emailClassifications.map((classification) => {
                return (
                  <FormAccordion
                    title={classification.name}
                    expanded={expanded === `selected${classification.id}`}
                    onChange={handleChange(`selected${classification.id}`)}
                  >
                    {notifications
                      ?.filter(
                        (notification) =>
                          notification.classification === classification.name
                      )
                      .sort(function (a, b) {
                        if (a.label.toLowerCase() < b.label.toLowerCase())
                          return -1;
                        if (a.label.toLowerCase() > b.label.toLowerCase())
                          return 1;
                        return 0;
                      })
                      .map((notification) => (
                        <Grid pl={7}>
                          <NotificationCheck notification={notification} />
                        </Grid>
                      ))}
                  </FormAccordion>
                );
              })}
          </Grid>
        </Grid>
        <>
          <FormCancelButton
            onClick={() => showModalHandler(false)}
            isSubmitting={false}
          />
        </>
      </Popup>
    </>
  );
};

export default ConfigureNotificationPopup;
