import React, { useEffect, useRef, useState } from "react";
import styled from "@emotion/styled";

import {
  IconButton,
  ListItemText,
  Popover as MuiPopover,
  Tooltip,
  MenuList,
  MenuItem,
  Stack,
  Typography,
  Grid,
} from "@mui/material";
import { fetchNewNotifications } from "src/redux/slices/notificationActions";
import { Form, useAppDispatch, useAsyncMutation } from "src/hooks";
import { LightbulbOutlined } from "@mui/icons-material";
import Popup from "../Popup";
import {
  FormAcceptButton,
  FormCancelButton,
  FormCheckBox,
  FormSelect,
  FormText,
  FormTextArea,
} from "../formControls";
import FormAccordion from "../formControls/FormAccordion";
import { INewRecommendations } from "src/ts/interfaces/recommendations";
import useFormTyped from "src/hooks/useFormTyped";
import { Validator } from "src/ts/types";
import { CatalogService } from "src/services";
import { IKeyValue } from "src/ts/interfaces";
import DialogMessagePopup from "../DialogMessagePopup";
import recommendationsService from "src/services/recommendationsService";
import { useNavigate } from "react-router-dom";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineContent from "@mui/lab/TimelineContent";
import { AdditionalTestingReqEnum } from "../../ts/enums/project";

const Popover = styled(MuiPopover)`
  .MuiPaper-root {
    width: 300px;
    ${(props) => props.theme.shadows[1]};
    border: 1px solid ${(props) => props.theme.palette.divider};
  }
`;

const initialValues: INewRecommendations = {
  title: "",
  recommendationsTypeId: 0,
  description: "",
  other: "",
};

function NavbarSuggestionBank() {
  const validate: any = (fieldValues: INewRecommendations = values) => {
    let temp: Record<string, string> = { ...errors };

    temp.description = new Validator(fieldValues, "description")
      .isRequired("Description is required")
      .toString();

    temp.title = new Validator(fieldValues, "title")
      .isRequired("Title is required")
      .toString();

    temp.recommendationsTypeId = new Validator(
      fieldValues,
      "recommendationsTypeId"
    )
      .selectedOption(0, "Classification is required")
      .toString();

    if (isOther()) {
      temp.other = new Validator(fieldValues, "other")
        .isRequired("Classification is required")
        .toString();
    } else temp.other = "";

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };
  const navigate = useNavigate();
  const ref = useRef(null);
  const [isOpen, setOpen] = useState(false);
  const [showNewPopup, setShowNewPopup] = useState(false);
  const [showInfoPopup, setShowInfoPopup] = useState(false);
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [accetMessage, setAccetMessage] = useState(false);
  const dispatch = useAppDispatch();
  const [expanded, setExpanded] = React.useState<string | false>("panel1");
  const { values, errors, setErrors, handleInputChange, setValues } =
    useFormTyped<INewRecommendations>(initialValues, false, validate);
  const [dataType, setDataType] = useState<IKeyValue<number, string>[]>([]);
  const { execute, isSubmitting, data } = useAsyncMutation<string>(
    recommendationsService.create,
    { successfulMessage: "The Suggestion was created" }
  );

  useEffect(() => {
    const getData = async () => {
      var result = await CatalogService.getAllDropdown("RecommendationsType");
      setDataType(result);
    };
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (data) {
      setShowNewPopup(false);
      setAccetMessage(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    dispatch(fetchNewNotifications());
  }, [dispatch]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  const handleNewSuggestion = () => {
    setShowNewPopup(true);
    setOpen(false);
  };

  const handleCheck = () => {
    setAcceptTerms(!acceptTerms);
    if (!acceptTerms) setExpanded("panel2");
  };

  const handleSave = async () => {
    if (!validate()) return;
    await execute(values);
    clearFields();
  };

  const handleGotoList = () => {
    setOpen(false);
    navigate("/app/suggestionBank");
  };

  const handleInformation = () => {
    setShowInfoPopup(true);
    setOpen(false);
  };

  const isOther = () => {
    const found = dataType.find((element) => element.value === "Other");
    if (!found) return false;
    return values?.recommendationsTypeId === found?.key;
  };

  const clearFields = () => {
    setAcceptTerms(false);
    setValues(initialValues);
    setExpanded("panel1");
  };

  return (
    <React.Fragment>
      <Tooltip title="Suggestion Bank">
        <IconButton color="inherit" ref={ref} onClick={handleOpen} size="large">
          <LightbulbOutlined />
        </IconButton>
      </Tooltip>
      <Popover
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
      >
        <MenuList dense>
          <MenuItem>
            <ListItemText inset onClick={handleNewSuggestion}>
              Create New
            </ListItemText>
          </MenuItem>
          <MenuItem>
            <ListItemText inset onClick={handleGotoList}>
              View Stored
            </ListItemText>
          </MenuItem>
          <MenuItem>
            <ListItemText inset onClick={handleInformation}>
              Information
            </ListItemText>
          </MenuItem>
        </MenuList>
      </Popover>
      <Popup
        title={"Amazon Gift Card Suggestion Bank"}
        openPopup={showNewPopup}
        setOpenPopup={setShowNewPopup}
        onClose={() => {
          setShowNewPopup(false);
        }}
        size={"md"}
      >
        <Stack>
          <FormAccordion
            title="Terms And Conditions"
            expanded={expanded === "panel1"}
            onChange={handleChange("panel1")}
          >
            <Grid p={2}>
              <Typography variant="body2" color="textPrimary" pb={3}>
                Please take the time to precisely describe your
                recommendation(s) as to its association, application, and
                functionality with AMP's software. Describe all Processes and
                parameters where your recommendation(s) may be used. For
                example, identify if your idea would be an “add on software
                tool” or “a time saving field labor application” etc. Please
                provide any math if applicable along with any published articles
                or applications it may be currently used on, with or for.
                Comprehensive descriptions and data provided by you helps AMP's
                Software and Engineering Design Teams understand your
                recommendation(s) and potentially approve your recommendation(s)
                for implementation and award you with a $25.00 Amazon Gift Card
                for appreciation and compensation for your submittal to AMP.
              </Typography>
              <Typography variant="body2" color="textPrimary" pb={3}>
                Acceptance of compensation in the form of a $25.00 Amazon Gift
                Card acknowledges you agree to let AMP along with any
                subscribers the use of your implemented recommendation(s) for
                our (AMP's) and their benefit. Any recommendations placed in the
                stored repository and not dynamically used for the betterment of
                the software, subscribers and engineering processes will not be
                considered “Implemented”.
              </Typography>
              <Typography variant="body2" color="textPrimary" pb={3}>
                Your Recommendation application will be cataloged in 1 of 3
                repositories, 1. Implemented 2. Pending and in Review or 3.
                Stored Any “identical” or “similar” recommendation(s) post
                received “after” an initially submitted application by other(s)
                which were already functionally implemented or in the process of
                implementation into AMP's software won’t qualify for an Amazon
                Gift Card.
              </Typography>
              <Typography variant="body2" color="textPrimary" pb={3}>
                Every applicant’s recommendation/submittal will be available in
                AMP's Recommendation(s) Repository for viewing which will assist
                you to identify if your recommendation(s) or idea(s) is
                identical to one that has already been submitted.
              </Typography>
              <Typography variant="body2" color="textPrimary" pb={3}>
                All participants will receive a submittal reference number for
                immediate reference or retrieval for your and other applicant’s
                review. Submittal Reference #('s) are for your privacy, keeping
                your identity and other like applicants private. Acceptance or
                Non-Implementation of any applicants’ recommendations(s) is the
                sole right of AMP to make the decisions that they feel best
                represents and protects their programs interest and intent.
              </Typography>
              <FormCheckBox
                label="I understand and I Agree to the Terms and the Spirit of Written Words Listed above "
                name="acceptTerms"
                value={acceptTerms}
                onChange={handleCheck}
              />
            </Grid>
          </FormAccordion>
          <FormAccordion
            title="New Suggestion"
            expanded={expanded === "panel2"}
            onChange={handleChange("panel2")}
          >
            <Form>
              <Grid container p={2} spacing={2}>
                <Grid item xs={6}>
                  <FormText
                    name="title"
                    label="Title"
                    value={values.title}
                    onChange={handleInputChange}
                    error={errors.title}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormSelect
                    name="recommendationsTypeId"
                    label="Classification"
                    value={values.recommendationsTypeId?.toString()}
                    onChange={handleInputChange}
                    options={dataType}
                    error={errors.recommendationsTypeId}
                  />
                </Grid>
                {isOther() && (
                  <Grid item xs={12}>
                    <FormText
                      name="other"
                      label="Other"
                      value={values.other}
                      onChange={handleInputChange}
                      error={errors.other}
                    />
                  </Grid>
                )}
                <Grid item xs={12}>
                  <FormTextArea
                    name="description"
                    label="Description"
                    value={values.description}
                    onChange={handleInputChange}
                    error={errors.description}
                    placeholder="We appreciate you're taking the time to share your thoughts and recommendations."
                  />
                </Grid>
              </Grid>
            </Form>
          </FormAccordion>
        </Stack>

        <Stack
          direction="row"
          spacing={2}
          justifyContent="flex-end"
          alignItems="flex-start"
        >
          <FormCancelButton
            onClick={() => {
              clearFields();
              setShowNewPopup(false);
            }}
            isSubmitting={isSubmitting}
          />
          <FormAcceptButton
            onClick={handleSave}
            isSubmitting={isSubmitting}
            text="Send"
            disabled={!acceptTerms}
          />
        </Stack>
      </Popup>
      <DialogMessagePopup
        title={"Suggestion Bank"}
        text={
          <>
            <Typography>
              We appreciate taking the time to share your thoughts and
              recommendations.
            </Typography>
            <Typography>
              We work hard to build the best solution for your company. Your
              idea will be reviewed and you will be kept up to date on the
              process.
            </Typography>
            <Typography>
              Your recommendation has been registered with the following code:{" "}
              {data}
            </Typography>
          </>
        }
        showPopup={accetMessage}
        setShowPopup={setAccetMessage}
        isSubmitting={false}
        onSave={() => {
          setAccetMessage(false);
        }}
        hideCancel={true}
      ></DialogMessagePopup>

      <Popup
        title={"Amazon Gift Card Suggestion Bank"}
        openPopup={showInfoPopup}
        setOpenPopup={setShowInfoPopup}
        onClose={() => {
          setShowInfoPopup(false);
        }}
        size={"md"}
      >
        <Stack>
          <Timeline position="alternate">
            <TimelineItem>
              <TimelineOppositeContent>
                <Typography variant="h6" component="span">
                  I Have A Recommendation
                </Typography>
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineDot variant="outlined" color="error" />
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>
                <Typography>Step 1</Typography>
              </TimelineContent>
            </TimelineItem>
            <TimelineItem>
              <TimelineOppositeContent>
                <Typography variant="h6" component="span">
                  Send My Recommendation
                </Typography>
                <Typography paddingTop={1}>
                  Submit my recommendation to Audit Master Pro
                </Typography>
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineDot variant="outlined" color="info" />
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>
                <Typography>Step 2</Typography>
              </TimelineContent>
            </TimelineItem>
            <TimelineItem>
              <TimelineOppositeContent>
                <Typography variant="h6" component="span">
                  Review Process
                </Typography>
                <Typography paddingTop={1}>
                  Our AMP commitee will review your proposal.
                </Typography>
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineDot variant="outlined" color="primary" />
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>
                <Typography>Step 3</Typography>
              </TimelineContent>
            </TimelineItem>
            <TimelineItem>
              <TimelineOppositeContent>
                <Typography variant="h6" component="span">
                  Amazon Gift Card
                </Typography>
                <Typography paddingTop={1}>
                  If your recommendation is deemed viable and functionally
                  implemented into AMP's software application processes, you
                  will receive a{" "}
                  <Typography component={"span"} color={"primary"}>
                    $25.00 Amazon Gift Card
                  </Typography>{" "}
                  Your amazon Gift Card will be then electronically sent to you
                  registered AMP e-mail address on record.
                </Typography>
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineDot variant="outlined" color="success" />
              </TimelineSeparator>
              <TimelineContent>
                <Typography>Step 4</Typography>
              </TimelineContent>
            </TimelineItem>
          </Timeline>
        </Stack>

        <Stack
          direction="row"
          spacing={2}
          justifyContent="flex-end"
          alignItems="flex-start"
        >
          <FormCancelButton
            onClick={() => {
              setShowInfoPopup(false);
            }}
            isSubmitting={isSubmitting}
            text="Close"
          />
        </Stack>
      </Popup>
    </React.Fragment>
  );
}

export default NavbarSuggestionBank;
