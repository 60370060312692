import { Grid, Typography, Stack } from "@mui/material";
import { useState } from "react";
import { FormButton } from "src/components/formControls";
import { MetaData } from "src/ts/interfaces/subscription";
import EditCost from "./Components/EditCost";
import { Permission as PermissionTypes } from "src/ts/enums";
import { usePermissions } from "src/hooks";

interface OnlineSoftwareProps {
  metaData: MetaData[];
  setRefresh: (val: boolean) => void;
  keyValueData: any;
  keyValue: any;
}

export default function OnlineSoftware(props: OnlineSoftwareProps) {
  const { metaData, setRefresh, keyValueData, keyValue } = props;
  const [showEditCost, setShowEditCost] = useState(false);
  const { fullAccess } = usePermissions(PermissionTypes.Subscriptions);
  return (
    <>
      <Typography variant="h3" mb={10}>
        Free subscription allows free access to AMP’s software which allows you
        to recognize the many options offered. Functionality is limited with
        this type of subscription and includes the following features:
      </Typography>
      <Grid container>
        <Grid xs={6} item>
          <Typography variant="h4">
            Options <b>without</b> cost
          </Typography>
          <ul>
            {metaData[2].withoutCostList.length !== 0
              ? metaData[2].withoutCostList.map((list: any, index: number) => (
                  <Stack key={index.toString()} mb={2}>
                    <li key={index.toString()}>{list.text}</li>
                  </Stack>
                ))
              : null}
          </ul>
        </Grid>
        <Grid xs={6} item>
          <Typography variant="h4">
            Options <b>with</b> cost
          </Typography>
          <ul>
            {metaData[2].withCostList.length !== 0
              ? metaData[2].withCostList.map((list: any, index: number) => (
                  <Stack key={index.toString()} mb={2}>
                    <li key={index}>
                      {list.text}
                      {". "} <b>{list.cost}</b>
                    </li>
                  </Stack>
                ))
              : null}
          </ul>
        </Grid>
      </Grid>
      <FormButton
        text={fullAccess ? "Edit" : "View"}
        onClick={() => {
          setShowEditCost(true);
        }}
      />
      {showEditCost ? (
        <EditCost
          setShowEditCost={setShowEditCost}
          showEditCost={showEditCost}
          metaData={metaData[2]}
          setRefresh={setRefresh}
          keyValueData={keyValueData}
          keyValue={keyValue}
        />
      ) : null}
    </>
  );
}
