import React from "react";
import { Grid, Stack, Typography } from "@mui/material";
import FormTitleBox from "src/components/formControls/FormTitleBox";
import { FormAcceptButton } from "src/components/formControls";
import { useAsyncMutation, useAuth } from "src/hooks";
import signUpService from "src/services/signUpService";
import { useLocation, useNavigate } from "react-router-dom";
import { ROLES } from "src/constants";
import templateServices from "src/services/templateServices";
interface Props {
  wizardId: number;
}
function ConfirmFree({ wizardId }: Props) {
  const { signOut } = useAuth();
  const { user } = useAuth();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const isAdmin = pathname.indexOf("ResetPasswordNewUserCompany") > 0;
  const { execute, isSubmitting } = useAsyncMutation(signUpService.done, {
    hideSuccessfulMessage: true,
    onSuccess: async () => {
      handlelogout();
    },
  });
  const { execute: executeTemplate } = useAsyncMutation(
    templateServices.createDefaults,
    {
      hideSuccessfulMessage: true,
    }
  );
  const handlelogout = async () => {
    await signOut();
    navigate("/auth/sign-in");
  };
  const handleConfirm = async () => {
    if (user?.role === ROLES.TemporalArchitect) executeTemplate();
    if (wizardId === 0) {
      handlelogout();
    } else await execute(wizardId);
  };
  return (
    <>
      <FormTitleBox title={"Confirmation"}>
        <Grid container spacing={5} pt={2}>
          <Grid item xs={12} textAlign={"center"}>
            {user?.role === ROLES.TemporalArchitect && !isAdmin && (
              <Typography variant="subtitle2" pt={2}>
                You are all set. Your new license for Building Owners,
                Architects, Professional Engineers and Building Engineers is now
                fully configured. You will be redirected to the login screen
                allowing you to start using AMP's Software. Thank you!
              </Typography>
            )}
            {user?.role === ROLES.TemporalTraining && !isAdmin && (
              <Typography variant="subtitle2" pt={2}>
                You are all set. Your new license for your trial & demonstration
                account is now fully configured. You will be redirected to the
                login screen allowing you to start using AMP's Software. Thank
                you!
              </Typography>
            )}
            {user?.role !== ROLES.TemporalTraining &&
              !isAdmin &&
              user?.role !== ROLES.TemporalArchitect && (
                <Typography variant="subtitle2" pt={2}>
                  You are all set. Your new license for your account is now
                  fully configured. You will be redirected to the login screen
                  allowing you to start using AMP's Software. Thank you!
                </Typography>
              )}
            {isAdmin && (
              <Typography variant="subtitle2" pt={2}>
                Thank you for confirming your new AMP account. You will be
                redirected to the login screen to continue with the signup
                process. Thank you!
              </Typography>
            )}
          </Grid>

          <Grid item xs={12}>
            <Stack
              direction="row"
              spacing={2}
              justifyContent="center"
              alignItems="center"
            >
              <FormAcceptButton
                text="Confirm"
                onClick={handleConfirm}
                isSubmitting={isSubmitting}
              ></FormAcceptButton>
            </Stack>
          </Grid>
        </Grid>
      </FormTitleBox>
    </>
  );
}

export default ConfirmFree;
