import Popup from "src/components/Popup";
import { FormButton } from "src/components/formControls";
import {
  FormControlLabel,
  FormGroup,
  Stack,
  Switch,
  Divider as MuiDivider,
  Typography,
  Skeleton,
} from "@mui/material";
import { IProjectReporDisplayt } from "src/ts/interfaces";
import { useEffect, useState } from "react";
import mandatoryMinimumFieldService from "src/services/mandatoryMinimumFieldService";
import { IMandatoryMinimumField } from "src/ts/interfaces/catalogs/mandatoryMinimumField";
import { spacing } from "@mui/system";
import styled from "@emotion/styled";
import { useAsyncQuery } from "src/hooks";

const Divider = styled(MuiDivider)(spacing);

interface InformationStudiesProps {
  informationStuidesShowPopUp: boolean;
  setInformationStuidesShowPopUp: (val: boolean) => void;
  study: IProjectReporDisplayt;
}

export default function InformationStudiesPopUp({
  informationStuidesShowPopUp,
  setInformationStuidesShowPopUp,
  study,
}: InformationStudiesProps) {
  const {
    execute,
    data: dataStudies,
    isLoading,
  } = useAsyncQuery<IMandatoryMinimumField[]>(
    mandatoryMinimumFieldService.getByType
  );

  const [studiesSort, setStudiesSort] = useState<IMandatoryMinimumField[]>([]);

  useEffect(() => {
    const getMiniumField = async () => {
      execute(study?.reportTypeId);
    };

    getMiniumField();
  }, []);

  useEffect(() => {
    const sortInfo = async () => {
      const orderBySubItems = dataStudies?.sort(
        (a, b) => b.subItems.length - a.subItems.length
      );

      setStudiesSort(orderBySubItems);
    };

    sortInfo();
  }, [dataStudies]);

  const skeletonCount = 10;

  const SkeletonsShow = Array.from({ length: skeletonCount }, (_, index) => (
    <Skeleton
      key={index}
      width={600}
      height={25}
      style={{ marginTop: "5px" }}
    />
  ));

  return (
    <>
      <Popup
        title={`Configure report - ${study?.reportTypeName}`}
        openPopup={informationStuidesShowPopUp}
        setOpenPopup={setInformationStuidesShowPopUp}
        onClose={() => {
          setInformationStuidesShowPopUp(false);
        }}
        size="sm"
      >
        <>
          <FormGroup>
            {isLoading && (
              <>
                <Stack direction="column">{SkeletonsShow}</Stack>
              </>
            )}
            {studiesSort?.map((item) => {
              if (item.subItems.length > 0) {
                return (
                  <>
                    <Divider my={5} />
                    <FormControlLabel
                      control={<Switch defaultChecked />}
                      label={item.description}
                      checked={item.switchOn}
                    />
                    {item.subItems.map((subitem) => (
                      <FormControlLabel
                        key={subitem.id}
                        control={<Switch defaultChecked />}
                        label={subitem.description}
                        checked={subitem.switchOn}
                      />
                    ))}
                  </>
                );
              } else if (item.subTitle !== null) {
                const lines = item.subTitle.split("\n");
                return (
                  <>
                    <Divider my={5} />
                    <Typography variant="h6">{item.description}</Typography>
                    {lines.map((line, index) => (
                      <>
                        <Typography key={index} ml={8}>
                          {line}
                        </Typography>
                      </>
                    ))}
                  </>
                );
              }
              return null;
            })}
          </FormGroup>
        </>
        <Stack direction="row" spacing={3}>
          <FormButton
            size="small"
            text="Close"
            variant="outlined"
            onClick={() => {
              setInformationStuidesShowPopUp(false);
            }}
          />
        </Stack>
      </Popup>
    </>
  );
}
