import axios from "../utils/axios";
import { ISubscription, IKeyValue, IAuthority } from "../ts/interfaces";
import { MetaData } from "../ts/interfaces/subscription";

const getAll = () => {
  return axios.get<Array<ISubscription>>("/subscription");
};

const getById = (subId: number) => {
  return axios.get<ISubscription>(`/subscription/${subId}`);
};

const getKeyValues = () => {
  return new Promise<IKeyValue<number, string>[]>((resolve, reject) => {
    axios
      .get<Array<ISubscription>>("/subscription")
      .then((response) => {
        const keyValuePair: IKeyValue<number, string>[] = response.data.map(
          (subscription) => {
            return {
              key: subscription.id,
              value: subscription.name,
            };
          }
        );

        resolve(keyValuePair);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const getMetaData = () => {
  return axios.get("/subscription/metadata");
};
const postMetaData = async (data: MetaData[]) => {
  return axios.post(`subscription/metadata`, data);
};

const getKeyValuesAuthority = () => {
  return new Promise<IKeyValue<number, string>[]>((resolve, reject) => {
    axios
      .get<Array<IAuthority>>("/Authority")
      .then((response) => {
        const keyValuePair: IKeyValue<number, string>[] = response.data.map(
          (subscription) => {
            return {
              key: subscription.id,
              value: subscription.name,
            };
          }
        );

        resolve(keyValuePair);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const SubscriptionService = {
  getAll,
  getKeyValues,
  getMetaData,
  postMetaData,
  getKeyValuesAuthority,
  getById,
};

export default SubscriptionService;
