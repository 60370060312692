import {
  IProjectOutForBidDataPage,
  IProjectOutForBidFilter,
} from "src/ts/interfaces/projectOutToBid";
import axios from "../utils/axios";
import { getFilter } from "src/utils/utils";
import { ProjectDTO } from "src/ts/interfaces/project/projectDto";

const getAll = async (
  filter: IProjectOutForBidFilter,
  getQuery?: (filters: Record<string, any>) => string
) => {
  const queryString = getQuery ? getQuery(filter) : getFilter(filter);
  return await axios.get<IProjectOutForBidDataPage>(
    `GetProjectSingle/GetAllContestProjectSysAdmin?${queryString}`
  );
};

const getAllProjects = async (
  filter: IProjectOutForBidFilter,
  getQuery?: (filters: Record<string, any>) => string
) => {
  const queryString = getQuery ? getQuery(filter) : getFilter(filter);
  return await axios.get<IProjectOutForBidDataPage>(
    `GetProjectSingle/GetAllContestProject?${queryString}`
  );
};

const getProjectSingle = async (id: number) => {
  return axios.get(`GetProjectSingle/${id}`);
};

const single = async (id: number) => {
  return axios.get<ProjectDTO>(`GetProjectSingle/single/${id}`);
};

const getAllDeclined = async (
  filter: IProjectOutForBidFilter,
  getQuery?: (filters: Record<string, any>) => string
) => {
  const queryString = getQuery ? getQuery(filter) : getFilter(filter);
  return await axios.get<IProjectOutForBidDataPage>(
    `GetProjectSingle/GetAllDeclinedProject?${queryString}`
  );
};

const getProjectSingleService = {
  getAll,
  getProjectSingle,
  getAllProjects,
  single,
  getAllDeclined,
};

export default getProjectSingleService;
