import React, { useEffect, useMemo, useState } from "react";
import {
  Grid,
  Stack,
  Tooltip,
  IconButton,
  Zoom,
  List,
  ListItemButton,
  Typography,
  FormLabel,
} from "@mui/material";
import {
  Form,
  useAsyncQuery,
  useAuth,
  useLog,
  usePermissions,
} from "src/hooks";
import {
  FormSelect,
  FormText,
  FormMaskText,
  FormTextArea,
  FormDatePicker,
  FormTimePicker,
  FormRadioGroup,
  FormCheckSwitch,
  FormButton,
  FormCheckBox,
  FormNumeric2,
} from "src/components/formControls";
import { Validator } from "src/ts/types";
import FormNumeric from "src/components/formControls/FormNumeric";
import {
  MeasurementSystemEnum,
  ProjectAddendum,
  ProjectRoofAccessDTO,
  ProjectTimeDurationUnit,
} from "src/ts/enums/project";
import useFormTyped from "src/hooks/useFormTyped";
import { useNavigate, useParams } from "react-router-dom";
import { ProjectWithBogus } from "src/ts/interfaces/project/projectDto";
import projectService from "src/services/project/projectService";
import TransferList from "src/components/lists/TransferList";
import projectCatalogs from "src/services/project/projectCatalogs";
import {
  ILadderAFrame,
  ISafetyEquipment,
} from "src/ts/interfaces/project/porjectCatalog";
import {
  IBuilding,
  IBuildingSelected,
  ICountry,
  IProjectBomaType,
  IUserPerson,
  KeyValueList,
  KeyValueListColor,
} from "src/ts/interfaces";
import {
  CountryService,
  buildingService,
  projectActiveService,
  projectBomaTypeService,
  projectPutService,
  userService,
} from "src/services";
import WithRole from "src/components/guards/WithRole";
import { ARCHITECTDATA, ROLES, projectStatus } from "src/constants";
import FormReadOnlyField from "src/components/formControls/FormReadOnlyField";
import { convertUTCDateToLocalDate } from "src/utils/utils";
import { Permission as PermissionTypes } from "src/ts/enums";
import InfoIcon from "@mui/icons-material/Info";
import { IValidateAuditors } from "src/ts/interfaces/technician";
import ValidateAuditorsPopUp from "./ValidateAuditorsPopUp";
import DialogMessagePopup from "src/components/DialogMessagePopup";
import projectAuditorService from "src/services/project/projectAuditorService";
import projectHistoryService from "src/services/project/projectHistoryService";
import BuildingSelect from "./BuildingSelect";
import FormNextButton from "src/components/formControls/FormNextButton";

export type ProjectParams = {
  projectId: string;
  companyId: string;
  navRoute: string;
};

interface ProjectFormProps {
  project: ProjectWithBogus;
  isLoading: boolean;
}

// 1	Test & Balance Certification
// 2	Energy Certification
// 3	Both
const BOTH = 3;

const controlTypeOptions = [
  { key: "DDC", value: "DDC" },
  { key: "Electronic", value: "Electronic" },
  { key: "Electric", value: "Electric" },
  { key: "Pneumatic", value: "Pneumatic" },
  { key: "Other", value: "Other" },
];

const measurementSystem = [
  { key: MeasurementSystemEnum.Imperial, value: "Imperial" },
  {
    key: MeasurementSystemEnum.MetricSystem,
    value: "Metric System",
  },
];

const ProjectForm = ({ project, isLoading }: ProjectFormProps) => {
  //hooks
  let params = useParams<ProjectParams>();
  const projectId = parseInt(
    params?.projectId === undefined ? "0" : params?.projectId
  );

  const companyId = parseInt(
    params?.companyId === undefined ? "0" : params?.companyId
  );

  const createdProject = projectId > 0;
  const newProject = projectId === 0;

  const navigate = useNavigate();
  const { log } = useLog();
  const { user } = useAuth();
  const { fullAccess } = usePermissions(PermissionTypes.Project);
  const { fullAccess: fullAccessTechAuditor } = usePermissions(
    PermissionTypes.Project_Auditors_Technician
  );

  //state
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [validateAuditor, setValidateAuditor] = useState<IValidateAuditors>();
  const [auditorsDataHasSheduler, setAuditorsDataHasSheduler] =
    useState<IUserPerson[]>();
  const [auditorsDataList, setAuditorsDataList] = useState<IUserPerson[]>();
  const [showValidateAuditorsPopUp, setShowValidateAuditorsPopUp] =
    useState(false);
  const [loadAuditorsSheduled, setLoadAuditorsSheduled] = useState(0);
  const [gotoNext, setGotoNext] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [finshLoadCatalogs, setFinshLoadCatalogs] = useState(false);
  const [selectedProjectBomaType, setSelectedProjectBomaType] =
    useState<IProjectBomaType | null>(null);
  const [pressurization, setPressurization] = useState("");
  const [validateAuditorModalShow, setValidateAuditorModalShow] =
    useState(false);

  const validate: any = (fieldValues = values) => {
    let temp: Record<string, string> = { ...errors };

    temp.name = new Validator(fieldValues, "name")
      .isRequired("Project Name is required")
      .toString();

    temp.estimatedTimeDuration = new Validator(
      fieldValues,
      "estimatedTimeDuration"
    )
      .isRequired("The time duration is required.")
      .greatThan(0, "Time duration must be greater than 0")
      .toString();

    temp.buildingId = new Validator(fieldValues, "buildingId")
      .selectedOption(-1, "The project's building is required.")
      .toString();

    temp.measurementSystem = new Validator(fieldValues, "measurementSystem")
      .selectedOption("", "Measurement system is required.")
      .toString();

    temp.suppliedPowerGridFrequency = new Validator(
      fieldValues,
      "suppliedPowerGridFrequency"
    )
      .selectedOption(null, "Supplied power grid frequency is required.")
      .toString();

    temp.auditAreaSqFt = new Validator(fieldValues, "auditAreaSqFt")
      .validateIf(fieldValues.buildingId > 0)
      .isRequired("This field is required.")
      .toString();

    temp.auditAreaCuFt = new Validator(fieldValues, "auditAreaCuFt")
      .validateIf(fieldValues.buildingId > 0)
      .isRequired("This field is required.")
      .toString();

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  //query
  const { values, setValues, errors, setErrors, handleInputChange } =
    useFormTyped<ProjectWithBogus>(project, false, validate);

  const { execute: getSafetyEquipment, data: safetyEquipmentsData } =
    useAsyncQuery<ISafetyEquipment[]>(projectCatalogs.safetyEquipments);

  const { execute: getAuditors, data: auditorsData } = useAsyncQuery<
    IUserPerson[]
  >(userService.getAuditors);

  const { execute: getLadderAFrame, data: ladderAFrame } = useAsyncQuery<
    ILadderAFrame[]
  >(projectCatalogs.ladderAFrame);

  const { execute: getLadderExtension, data: ladderExtension } = useAsyncQuery<
    ILadderAFrame[]
  >(projectCatalogs.ladderExtension);

  const { execute: getProjectBomaType, data: projectBomaType } = useAsyncQuery<
    IProjectBomaType[]
  >(projectBomaTypeService.getAll);

  const { execute: getBuilding, data: building } = useAsyncQuery<IBuilding>(
    buildingService.getById
  );

  const { execute: getCountry, data: country } = useAsyncQuery<ICountry>(
    CountryService.getById
  );

  //effects

  useEffect(() => {
    if (project.id > 0) {
      setValues({ ...project });
    } else {
      if (values.estimatedTimeDurationUnit === 0) {
        values.estimatedTimeDurationUnit = 2;
        setValues(values);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project]);

  useEffect(() => {
    const getData = async () => {
      await getLadderAFrame();
      await getLadderExtension();
      await getProjectBomaType();
      await getSafetyEquipment();
      await getAuditors(companyId, projectId);

      setFinshLoadCatalogs(true);
    };

    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (newProject && safetyEquipmentsData && safetyEquipmentsData.length > 0) {
      //"Safety glasses", "Hard Hat", "Steel toe boots"
      const initialSafeEquipments = safetyEquipmentsData.filter(
        (safeEquipment) =>
          safeEquipment.name === "Safety glasses" ||
          safeEquipment.name === "Hard Hat" ||
          safeEquipment.name === "Steel toe boots"
      );

      setValues({
        ...values,
        safetyEquipments: [safetyEquipmentsData[1]],
        bogusSafetyEquipments: [...initialSafeEquipments],
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [safetyEquipmentsData]);

  useEffect(() => {
    if (newProject && ladderAFrame && ladderAFrame.length > 0) {
      //6'
      setValues({ ...values, ladderAFrames: [ladderAFrame[1]] });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ladderAFrame]);

  useEffect(() => {
    const getDataDetail = async () => {
      await getCountry(building.countryId);
    };

    const pressurization = (pressurizationId: number) => {
      if (pressurizationId === 1) setPressurization("Positive +");
      else if (pressurizationId === 2) setPressurization("Negative -");
      else setPressurization("Combination of Both +/-");
    };

    if (building) {
      getDataDetail();
      pressurization(building?.pressurizationId ?? 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [building]);

  useEffect(() => {
    const getBuildingData = async () => {
      await getBuilding(project.buildingId);
    };
    if (project.buildingId > 0) {
      getBuildingData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project]);

  useEffect(() => {
    if (newProject && building && building.id > 0) {
      setValues({ ...values, measurementSystem: building.measurementSystem });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [building]);

  useEffect(() => {
    if (newProject && country && (country.id as number) > 0) {
      setValues({ ...values, suppliedPowerGridFrequency: country.frequency });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [country]);

  useEffect(() => {
    if (auditorsData != null && auditorsData.length > 0) {
      const hasSheduler = auditorsData.filter((item) => item.hasSheduler);
      setAuditorsDataHasSheduler(hasSheduler);
      const nothasSheduler = auditorsData.filter((item) => !item.hasSheduler);
      setAuditorsDataList(nothasSheduler);
    }
  }, [auditorsData]);

  useEffect(() => {
    if (loadAuditorsSheduled > 0) {
      showCreateProjectButton
        ? saveHandler()
        : gotoNext
        ? nextStep()
        : updateHandler();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadAuditorsSheduled]);

  useEffect(() => {
    if (
      values?.auditAreaSqFt !== undefined &&
      values?.auditAreaSqFt !== null &&
      values?.auditAreaSqFt >= 0
    ) {
      const multiplier =
        values.measurementSystem === MeasurementSystemEnum.Imperial ? 10 : 3;

      setValues({
        ...values,
        auditAreaCuFt: values.auditAreaSqFt * multiplier,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.auditAreaSqFt]);

  useEffect(() => {
    if (values.projectBomaTypeId && values.projectBomaTypeId > 0) {
      const type = projectBomaType?.find(
        (type) => type.id === values.projectBomaTypeId
      );
      setSelectedProjectBomaType(type ?? null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.projectBomaTypeId]);

  const validateAuditors = async (bogusAuditors: IUserPerson[]) => {
    const result = await projectPutService.validateAuditors(
      projectId,
      bogusAuditors
    );
    const validateAuditor = result?.data;

    if (result?.data?.message === "Fail") {
      if (
        (user?.role === "Training" ||
          user?.role === "TemporalAuditor" ||
          user?.role === "Auditor" ||
          user?.role === "EnergyAuditor" ||
          user?.role === "TemporalEnergyAuditor") &&
        validateAuditor.userId === user?.userId
      ) {
        await removeAuditor(validateAuditor);
      } else {
        setValidateAuditor(result.data);
        setShowValidateAuditorsPopUp(true);
        return false;
      }
    }

    return true;
  };

  const removeAuditor = async (validateAuditor: IValidateAuditors) => {
    try {
      await projectAuditorService.deleteAuditor(
        validateAuditor.projectId,
        validateAuditor.userId
      );

      if (user?.role !== "Training") {
        log.info(
          "Auditor/Technician " +
            validateAuditor?.userName +
            " has been succesfully remove from the project assigned."
        );
      }
    } catch (error: any) {
      log.info(error.message || "Something went wrong");
    }
  };

  //handlers
  const saveHandler = async (validTech: boolean = false) => {
    if (!validate()) {
      log.warning("The form is invalid, please correct.");
      return;
    }

    let frequencySelected: boolean | null = null;

    if (
      values.suppliedPowerGridFrequency === null &&
      user?.role !== "Architect" &&
      user?.role !== "ArchitectAux"
    ) {
      frequencySelected = false;
    }

    if (frequencySelected && !frequencySelected) {
      log.error("The form is invalid, please correct.");
      return;
    }

    if (
      user?.role !== "Training" &&
      user?.role !== "TemporalAuditor" &&
      user?.role !== "Auditor" &&
      user?.role !== "EnergyAuditor" &&
      user?.role !== "TemporalEnergyAuditor" &&
      validTech &&
      (values.bogusAuditors === undefined ||
        values.bogusAuditors == null ||
        values.bogusAuditors.length === 0)
    ) {
      setValidateAuditorModalShow(true);
      return;
    }

    let auditorResult = false;

    if (
      (values.bogusAuditors !== undefined && values.bogusAuditors.length > 0) ||
      user?.role === "Auditor" ||
      user?.role === "EnergyAuditor" ||
      user?.role === "TemporalAuditor" ||
      user?.role === "TemporalEnergyAuditor" ||
      (user?.role === "Training" && user?.role !== "SuperAdministrator") //myAuthService.authentication.role != 'SuperAdministrator'
    ) {
      if (
        user?.role === "Auditor" ||
        user?.role === "EnergyAuditor" ||
        user?.role === "TemporalAuditor" ||
        user?.role === "TemporalEnergyAuditor" ||
        user?.role === "Training"
      ) {
        const currentAuditor =
          auditorsData.find(
            (item) =>
              item.userName?.toLowerCase() === user?.userName.toLowerCase() &&
              (item?.roleName === user?.role ||
                item?.textColor === "text-warning")
          ) ?? null;

        values.bogusAuditors = [];
        if (currentAuditor) values.bogusAuditors.push(currentAuditor);
      }
      auditorResult = await validateAuditors(values.bogusAuditors ?? []);
    }

    if (!auditorResult && validTech) return;

    try {
      setIsSubmitting(true);

      let publishInAuditMasterPro = false;
      let safetyEquipments: ISafetyEquipment[] =
        values?.bogusSafetyEquipments ?? [];

      if (user?.role === "Architect" || user?.role === "ArchitectAux") {
        //boma role doesn't have option for add SafetyEquipment for it self
        var defaultSafetyEquipment = [
          "Hard hat",
          "Steel toe boots",
          "Safety glasses",
        ];
        publishInAuditMasterPro = true;
        safetyEquipments = safetyEquipmentsData.filter((x) =>
          defaultSafetyEquipment.includes(x.name)
        );
      }

      const project = {
        ...values,
        startDate: values.bogusStartDate
          ? `${values.bogusStartDate} ${values.bogusStartDateTime}`
          : null,
        actualStartDate: values.bogusActualStartDate
          ? `${values.bogusActualStartDate} ${values.bogusActualStartDateTime}`
          : null,
        visibleToAllContractors: true,
        publishInAuditMasterPro: publishInAuditMasterPro,
        sendProposalThroughAMP: true,
        bogusSafetyEquipments: safetyEquipments ? [...safetyEquipments] : [],
        projectTypeId: BOTH,
      };

      const createProjectResponse = await projectService.create(
        companyId!,
        project
      );

      const createdProject = createProjectResponse.data;

      //saveZeroEmailProject(); //TODO: check with boma role
      await projectHistoryService.log(createdProject.id, "Project was updated");

      await modifyActiveProject(createdProject.id);
      //await technicianAssigment(createdProject.id); //TODO: check with boma role

      log.success("The project was successfully created");
      setIsSubmitting(false);

      if (user?.role === "Architect" || user?.role === "ArchitectAux") {
        navigate(`/Tolerances/${createdProject.id}/${companyId}`);
      } else {
        if (
          user?.role === "Auditor" ||
          user?.role === "EnergyAuditor" ||
          user?.role === "TemporalEnergyAuditor" ||
          user?.role === "TemporalAuditor" ||
          user?.role === "Training"
        )
          navigate("/app/Working");
        else navigate(`/app/ProjectList/${user?.companyId}`);
      }
    } catch (error: any) {
      log.error(error?.message?.exceptionMessage ?? "Something went wrong");
    } finally {
      setIsSubmitting(false);
    }
  };

  const modifyActiveProject = async (projectId: number) => {
    await projectActiveService.modifyActiveProject(projectId);
  };

  const updateHandler = async () => {
    setGotoNext(false);
    if (!validate()) {
      log.warning("The form is invalid, please correct.");
      return;
    }

    const auditorResult = await validateAuditors(values.bogusAuditors ?? []);
    if (!auditorResult) return;

    const project = {
      ...values,
      startDate: values.bogusStartDate
        ? `${values.bogusStartDate} ${values.bogusStartDateTime}`
        : null,
      actualStartDate: values.bogusActualStartDate
        ? `${values.bogusActualStartDate} ${values.bogusActualStartDateTime}`
        : null,
    };

    try {
      await projectPutService.update(projectId, project);
      log.success("The project has been succesfully updated.");
      setIsSubmitting(false);
    } catch (error: any) {
      log.info(error.message || "Something went wrong");
    } finally {
      setIsSubmitting(false);
    }
  };

  const filterByTab = useMemo(() => {
    for (const key in ARCHITECTDATA) {
      if (ARCHITECTDATA[key].queryData === project.projectStatusId) {
        return ARCHITECTDATA[key].name;
      }
    }
    return "all";
  }, [project.projectStatusId]);

  const cancelHandler = () => {
    if (user?.role === ROLES.Auditor || user?.role === ROLES.EnergyAuditor) {
      navigate("/app/Working");
    } else if (
      user?.role === ROLES.Architect ||
      user?.role === ROLES.ArchitectAux ||
      user?.subscriptionId === 1
    )
      navigate(`/app/ProjectArchitect/${user?.companyId}/${filterByTab}`);
    else {
      navigate(`/app/ProjectList/${user?.companyId}`);
    }
  };

  const nextStep = async () => {
    setGotoNext(true);
    if (mode === "read") {
      navigate(`/app/ProjectStudies/${projectId}/${companyId}`);
    } else {
      if (!validate()) {
        log.warning("The form is invalid, please correct.");
        return;
      }

      //const nextStep = true;
      const viewMode = false;

      if (user?.role === "Architect" || user?.role === "ArchitectAux") {
        if (
          values?.projectStatus?.name !== "Complete" &&
          values?.projectStatus?.name !== "Rejected" &&
          values?.projectStatus?.name !== "Approved" &&
          !viewMode
        ) {
          const project = {
            ...values,
            startDate: values.bogusStartDate
              ? `${values.bogusStartDate} ${values.bogusStartDateTime}`
              : null,
            actualStartDate: values.bogusActualStartDate
              ? `${values.bogusActualStartDate} ${values.bogusActualStartDateTime}`
              : null,
          };

          // if ($scope.crud.projectBomaType != null)
          //     currentCRUD.projectBomaTypeId = $scope.crud.projectBomaType.id

          try {
            await projectPutService.update(projectId, project);
            setIsSubmitting(false);
            log.success("Data was updated succesfully");
            await projectHistoryService.log(projectId, "Project was updated");

            if (
              values?.projectStatus?.name === "Contest" ||
              values?.projectStatus?.name === "New contest"
            )
              navigate("Tolerances/" + projectId + "/" + user?.companyId);
            else navigate(`/app/ProjectStudies/${projectId}/${companyId}`);
          } catch (error: any) {
            log.error("Error at edit");
          } finally {
            setIsSubmitting(false);
          }
        } else {
          if (
            values?.projectStatus?.name === "Contest" ||
            values?.projectStatus?.name === "New contest"
          )
            navigate("Reports/" + projectId + "/" + user?.companyId);
          else navigate(`/app/ProjectStudies/${projectId}/${companyId}`);
        }
      } else {
        if (
          values?.projectStatus?.name !==
            "Complete" /*|| //TODO: what if status is Rejected
          values?.projectStatus?.name !== "Rejected" ||
          values?.projectStatus?.name !== "Approved")*/ &&
          !(
            projectId > 0 &&
            (user?.role === "Architect" || user?.role === "ArchitectAux")
          ) &&
          fullAccess
        ) {
          const uniqueCode = true;
          if (!validate() || !uniqueCode) {
            log.warning("The form is invalid, please correct.");
          }

          const auditorResult = await validateAuditors(
            values.bogusAuditors ?? []
          );
          if (!auditorResult) return;

          const project = {
            ...values,
            startDate: values.bogusStartDate
              ? `${values.bogusStartDate} ${values.bogusStartDateTime}`
              : null,
            actualStartDate: values.bogusActualStartDate
              ? `${values.bogusActualStartDate} ${values.bogusActualStartDateTime}`
              : null,
          };

          if (
            user?.canAddBuildingsAndProjects === "true" ||
            user?.canAddBuildingsAndProjects === true ||
            user?.canAddTechniciansToProject === "true" ||
            user?.canAddTechniciansToProject === true
          ) {
            try {
              await projectPutService.update(projectId, project);
              setIsSubmitting(false);
              log.success("Data was updated succesfully");
              projectHistoryService.log(projectId, "Project was updated");
              navigate(`/app/ProjectStudies/${projectId}/${companyId}`);
            } catch (error: any) {
              log.info(error.message || "Something went wrong");
            } finally {
              setIsSubmitting(false);
            }
          } else {
            navigate(`/app/ProjectStudies/${projectId}/${companyId}`);
          }
        } else {
          navigate(`/app/ProjectStudies/${projectId}/${companyId}`);
        }
      }
    }
  };

  const ladderAFrameHandler = (selectedItems: KeyValueList[]) => {
    const selected = ladderAFrame.filter((val1) => {
      return selectedItems.find((val2) => val1.id === val2.key);
    });

    setValues({ ...values, bogusLadderAFrames: selected });
  };

  const ladderExtensionHandler = (selectedItems: KeyValueList[]) => {
    const selected = ladderExtension.filter((val1) => {
      return selectedItems.find((val2) => val1.id === val2.key);
    });

    setValues({ ...values, bogusLadderExtensions: selected });
  };

  const safetyEquipmentsHandler = (selectedItems: KeyValueList[]) => {
    const selected = safetyEquipmentsData.filter((val1) => {
      return selectedItems.find((val2) => val1.id === val2.key);
    });
    setValues({ ...values, bogusSafetyEquipments: selected });
  };

  const auditorsHandler = (selectedItems: KeyValueList[]) => {
    const selected = auditorsDataList?.filter((val1) => {
      return selectedItems.find((val2) => val1.id === val2.key);
    });
    setValues({ ...values, bogusAuditors: selected });
  };

  const sheduleAuditorsHandler = (userId: number) => {
    const user = auditorsData?.filter((item) => item.id === userId)[0];
    auditorsDataHasSheduler?.push(user);
    setAuditorsDataHasSheduler(auditorsDataHasSheduler);
    setLoadAuditorsSheduled(loadAuditorsSheduled + 1);
    const selected = values?.bogusAuditors?.filter((val1) => {
      return val1.id !== userId;
    });
    setValues({ ...values, bogusAuditors: selected });
  };

  const selectBuidingHandler = async (selectedBuilding: IBuildingSelected) => {
    await getBuilding(selectedBuilding.id);
    setValues({
      ...values,
      buildingId: selectedBuilding.id,
      measurementSystem: selectedBuilding.measurementSystem,
    });
  };

  const row = 12;

  let viewMode =
    values.disabled ||
    values?.projectStatus?.name === "In Review" ||
    values?.projectStatus?.name === "Complete" ||
    values?.projectStatus?.name === "Canceled" ||
    values?.projectStatus?.name === "Rejected" ||
    values?.projectStatus?.name === "Approved" ||
    values?.projectStatus?.name === "Incomplete" ||
    user?.role === "SysAdmin" ||
    ((user?.role === "Architect" || user?.role === "ArchitectAux") &&
      values.projectStatusId !== 6 &&
      values.projectStatusId !== 5);

  if (!fullAccess) {
    viewMode = true;
  }

  //const
  const mode = viewMode ? "read" : "read&Write";

  const canSeeEstimatedTime =
    createdProject &&
    (user?.canTechnicianEstimatedTime === "true" ||
      user?.canTechnicianEstimatedTime === true) &&
    values.projectStatusId !== projectStatus.Contest.id &&
    values.projectStatusId !== projectStatus.NewContest.id;

  const canEditEstimatedTime =
    ((((user?.canTechnicianEstimatedTime === "false" ||
      user?.canTechnicianEstimatedTime === false) &&
      (values.projectStatusId === null ||
        values.projectStatusId === undefined ||
        values.projectStatusId === 0)) ||
      user?.canTechnicianEstimatedTime === "true" ||
      user?.canTechnicianEstimatedTime === true) &&
      values.projectStatusId !== 6 &&
      values.projectStatusId !== 5 &&
      values.projectStatusId !== 7 &&
      values.projectStatusId !== 9 &&
      values.companyId == null &&
      user?.role !== ROLES.Architect &&
      user?.role !== ROLES.ArchitectAux) ||
    (values.projectStatusId !== projectStatus.Contest.id &&
      (user?.role === ROLES.Architect || user?.role === ROLES.ArchitectAux) &&
      values.projectStatusId !== projectStatus.NewContest.id &&
      values.projectStatusId !== undefined) ||
    user?.role === ROLES.SysAdmin ||
    user?.role === ROLES.Administrator ||
    user?.role === ROLES.CompanyOwner ||
    user?.role === ROLES.ProjectManager ||
    user?.role === ROLES.SuperAdministrator;

  const canEditMeasureSystem =
    !(
      newProject &&
      (user?.role === "Architect" ||
        user?.role === "SysAdmin" ||
        user?.role === "ArchitectAux")
    ) &&
    ((values.projectStatusId === undefined &&
      user?.role !== "Architect" &&
      user?.role !== "ArchitectAux") ||
      values.projectStatusId !== undefined) &&
    values.projectStatusId !== 5 &&
    values.projectStatusId !== 6 &&
    values.projectStatusId !== 7 &&
    values.projectStatusId !== 9;

  const measureSystemMode =
    (viewMode || createdProject) &&
    (values.updatedBy != null ||
      user?.role === "Architect" ||
      user?.role === "ArchitectAux" ||
      user?.role === "SysAdmin");

  const isVisibleActualStartDate =
    user?.role === "ProjectManager" ||
    user?.role === "SuperAdministrator" ||
    user?.role === "Administrator" ||
    user?.role === "CompanyOwner" ||
    user?.role === "Auditor" ||
    user?.role === "EnergyAuditor" ||
    user?.role === "TemporalAuditor" ||
    user?.role === "TemporalEnergyAuditor" ||
    user?.role === "Training";

  const showGotoBuildingButton =
    (viewMode || createdProject) &&
    user?.role !== "SysAdmin" &&
    (user?.canAddBuildingsAndProjects === "true" ||
      user?.canAddBuildingsAndProjects === true ||
      !(
        user?.role === "Auditor" ||
        user?.role === "EnergyAuditor" ||
        user?.role === "TemporalAuditor" ||
        user?.role === "TemporalEnergyAuditor"
      ));

  const showContractedBy =
    ((user?.role === "Architect" ||
      user?.role === "SysAdmin" ||
      user?.role === "ArchitectAux") &&
      values?.projectStatus?.name !== "Contest" &&
      values?.projectStatus?.name !== "New contest" &&
      values?.projectStatus?.name !== undefined) ||
    user?.role === "ProjectManager" ||
    user?.role === "SuperAdministrator" ||
    user?.role === "Administrator" ||
    user?.role === "CompanyOwner" ||
    user?.role === "Auditor" ||
    user?.role === "EnergyAuditor" ||
    user?.role === "TemporalAuditor" ||
    user?.role === "TemporalEnergyAuditor" ||
    user?.role === "Training";

  const otherBomaTypeIsSet =
    values?.projectBomaType != null
      ? values?.projectBomaType.name === "Other"
        ? true
        : false
      : null;

  const showProjectType =
    (user?.role === "Architect" ||
      user?.role === "SysAdmin" ||
      user?.role === "ArchitectAux") &&
    (otherBomaTypeIsSet == null || !otherBomaTypeIsSet);

  const showCreateProjectButton =
    newProject &&
    (user?.role !== "Architect" ||
      user?.role !== "ArchitectAux" ||
      user?.role !== "SysAdmin");

  const showUpdateButton =
    createdProject &&
    (user?.role !== "Architect" ||
      user?.role !== "ArchitectAux" ||
      user?.role !== "SysAdmin") &&
    mode === "read&Write";

  const nextStepButtonLabel =
    user?.role === "Architect" || user?.role === "ArchitectAux"
      ? values?.projectStatusId === 9 ||
        values?.projectStatusId === 1 ||
        values?.projectStatusId === 3 ||
        values?.projectStatusId === 11 ||
        values?.projectStatusId === 10 ||
        values?.projectStatusId === 7
        ? "Continue"
        : "Save & Continue"
      : values?.statusBeforeClosed === 1
      ? "Continue"
      : "Next Step";

  const isBomaAwarded = useMemo(() => {
    return (
      (user?.role === ROLES.Architect || user?.role === ROLES.ArchitectAux) &&
      values?.projectStatusId !== 5 &&
      values?.projectStatusId !== 6
    );
  }, [user?.role, values?.projectStatusId]);

  return (
    <>
      <Form>
        <Grid container spacing={3}>
          <WithRole
            value={[ROLES.Architect, ROLES.SysAdmin, ROLES.ArchitectAux]}
            action="hide"
          >
            <>
              {values.code && !isBomaAwarded && (
                <Grid item xs={row}>
                  <FormReadOnlyField
                    label="Automatically Generated Code"
                    value={values.code}
                    showSkeleton={isLoading}
                  />
                </Grid>
              )}
              {values.buildingOwnerName && !isBomaAwarded && (
                <Grid item xs={row}>
                  <FormReadOnlyField
                    label="Building's Owner Name"
                    value={`${values.buildingOwnerName} ${values.buildingOwnerLastName}`}
                    showSkeleton={isLoading}
                  />
                </Grid>
              )}
              {values.buildingOwnerEmail && !isBomaAwarded && (
                <Grid item xs={row}>
                  <FormReadOnlyField
                    label="Building's Owner Email"
                    value={values.buildingOwnerEmail}
                    showSkeleton={isLoading}
                  />
                </Grid>
              )}
            </>
          </WithRole>
          <Grid item xs={row}>
            <FormText
              name="name"
              label="Project Name"
              value={values.name}
              onChange={handleInputChange}
              error={errors.name}
              showSkeleton={isLoading}
              mode={createdProject ? "read" : "read&Write"}
            />
          </Grid>
          {createdProject && (
            <Grid item xs={row}>
              <FormReadOnlyField
                label="Created Date"
                value={convertUTCDateToLocalDate(
                  values?.createdDate ?? ""
                )?.format("MM/DD/yyyy")}
                showSkeleton={isLoading}
              />
            </Grid>
          )}
          <Grid item xs={row}>
            <Grid container>
              <Grid item xs={4}>
                <FormDatePicker
                  name="bogusStartDate"
                  label="Proposed Start Date"
                  value={values.bogusStartDate}
                  onChange={handleInputChange}
                  error={errors.bogusStartDate}
                  disablePast={true}
                  mode={
                    createdProject && values.startDate !== null
                      ? "read"
                      : "read&Write"
                  }
                  showSkeleton={isLoading}
                />
              </Grid>
              <Grid item xs={8}>
                <FormTimePicker
                  label="Proposed Start Time"
                  value={values.bogusStartDateTime}
                  onChange={handleInputChange}
                  name={"bogusStartDateTime"}
                  error={errors.bogusStartDateTime}
                  mode={
                    createdProject && values.startDate !== null
                      ? "read"
                      : "read&Write"
                  }
                  showSkeleton={isLoading}
                />
              </Grid>
            </Grid>
          </Grid>
          {isVisibleActualStartDate && (
            <Grid item xs={row}>
              <Grid container>
                <Grid item xs={4}>
                  <FormDatePicker
                    name="bogusActualStartDate"
                    label="Actual Start Date"
                    value={values.bogusActualStartDate}
                    onChange={handleInputChange}
                    error={errors.bogusActualStartDate}
                    disablePast={true}
                    mode={mode}
                    showSkeleton={isLoading}
                  />
                </Grid>
                <Grid item xs={8}>
                  <FormTimePicker
                    label="Actual Start Time"
                    value={values.bogusActualStartDateTime}
                    onChange={handleInputChange}
                    name={"bogusActualStartDateTime"}
                    error={errors.bogusActualStartDateTime}
                    mode={mode}
                    showSkeleton={isLoading}
                  />
                </Grid>
              </Grid>
            </Grid>
          )}
          {canEditEstimatedTime && (
            <>
              <Grid item xs={6}>
                <FormNumeric
                  name="estimatedTimeDuration"
                  label="Proposal Project Time Duration / Bid"
                  value={values.estimatedTimeDuration?.toString() ?? ""}
                  onChange={handleInputChange}
                  maxLength={6}
                  error={errors.estimatedTimeDuration}
                  mode={mode}
                  showSkeleton={isLoading}
                  suffix={
                    mode === "read"
                      ? values.estimatedTimeDurationUnit ===
                        ProjectTimeDurationUnit.Hours
                        ? "Hours"
                        : "Days"
                      : ""
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <FormRadioGroup
                  name="estimatedTimeDurationUnit"
                  label={""}
                  items={[
                    { key: ProjectTimeDurationUnit.Hours, value: "Hours" },
                    { key: ProjectTimeDurationUnit.Days, value: "Days" },
                  ]}
                  onChange={handleInputChange}
                  value={values.estimatedTimeDurationUnit}
                  row={true}
                  mode={mode}
                  showSkeleton={isLoading}
                />
              </Grid>
            </>
          )}
          {canSeeEstimatedTime && (
            <Grid item xs={row}>
              <FormReadOnlyField
                label="Computer Estimated Time Remaining"
                value={`${values.computerEstimatedTimeRemaining} ${
                  values.estimatedTimeDurationUnit ===
                  ProjectTimeDurationUnit.Hours
                    ? "Hours"
                    : values.estimatedTimeDurationUnit ===
                      ProjectTimeDurationUnit.Days
                    ? "Days"
                    : ""
                }`}
                showSkeleton={isLoading}
              />
            </Grid>
          )}
          {canSeeEstimatedTime && (
            <Grid item xs={row}>
              <FormReadOnlyField
                label="Technicians Estimated Time Remaining"
                value={`${
                  values?.techniciansEstimatedTimeRemaining === "Empty"
                    ? "Not yet defined"
                    : values?.techniciansEstimatedTimeRemaining
                } ${
                  values?.techniciansEstimatedTimeRemaining === "Empty"
                    ? ""
                    : values?.techniciansEstimatedTimeRemainingIsDays
                    ? "Days"
                    : "Hours"
                }`}
                showSkeleton={isLoading}
              />
            </Grid>
          )}
          <BuildingSelect
            buildingId={project.buildingId}
            isRead={viewMode || createdProject}
            companyId={companyId}
            values={values}
            setValues={setValues}
            errors={errors}
            showGotoBuildingButton={showGotoBuildingButton}
            onSelect={selectBuidingHandler}
          ></BuildingSelect>
          {canEditMeasureSystem && (
            <Grid item xs={row}>
              <FormSelect
                name="measurementSystem"
                label="Measurement System"
                value={values.measurementSystem.toString()}
                onChange={handleInputChange}
                options={measurementSystem}
                error={errors.measurementSystem}
                disable={isSubmitting}
                showSkeleton={isLoading}
                mode={measureSystemMode ? "read" : "read&Write"}
              />
            </Grid>
          )}
          {canEditMeasureSystem && (
            <Grid item xs={row}>
              <FormRadioGroup
                name="suppliedPowerGridFrequency"
                label={"Supplied Power Grid Frequency"}
                items={[
                  { key: 60, value: "60 Hz" },
                  { key: 50, value: "50 Hz" },
                ]}
                onChange={handleInputChange}
                value={values?.suppliedPowerGridFrequency}
                row={true}
                error={errors.suppliedPowerGridFrequency}
                mode={mode}
                showSkeleton={isLoading}
              />
            </Grid>
          )}
          {values?.buildingId && (
            <>
              <Grid item xs={row}>
                <Stack direction={"row"}>
                  <FormNumeric2
                    name="auditAreaSqFt"
                    suffix={
                      values.measurementSystem ===
                      MeasurementSystemEnum.Imperial
                        ? " ft\u00b2"
                        : " mt\u00b2"
                    }
                    label={
                      values.measurementSystem ===
                      MeasurementSystemEnum.Imperial
                        ? "Total square feet (Sq/Ft) of audit area being tested"
                        : "Total square meters (Sq/Mt) of audit area being tested"
                    }
                    value={values.auditAreaSqFt?.toString() ?? ""}
                    onChange={handleInputChange}
                    error={errors.auditAreaSqFt}
                    mode={mode}
                    showSkeleton={isLoading}
                    thousandSeparator={true}
                  />
                  <Tooltip
                    TransitionComponent={Zoom}
                    title={
                      values.measurementSystem ===
                      MeasurementSystemEnum.Imperial
                        ? "Building Total Cube Feet defaults using a cellining height value of 10'"
                        : "Building Total Cube Meters defaults using a cellining height value of 3m"
                    }
                  >
                    <span>
                      <IconButton aria-label="delete" size="large">
                        <InfoIcon />
                      </IconButton>
                    </span>
                  </Tooltip>
                </Stack>
              </Grid>
              <Grid item xs={row}>
                <FormNumeric2
                  name="auditAreaCuFt"
                  suffix={
                    values.measurementSystem === MeasurementSystemEnum.Imperial
                      ? " ft\u00b3"
                      : " mt\u00b3"
                  }
                  label={
                    values.measurementSystem === MeasurementSystemEnum.Imperial
                      ? "Total cubic feet (Cu/Ft) of audit area being tested"
                      : "Total cubic meters (Cu/Mt) of audit area being tested"
                  }
                  value={values.auditAreaCuFt?.toString() ?? ""}
                  onChange={handleInputChange}
                  error={errors.auditAreaCuFt}
                  mode={mode}
                  showSkeleton={isLoading}
                  thousandSeparator={true}
                />
              </Grid>
            </>
          )}
          {values?.buildingId && (
            <Grid item xs={row}>
              <FormCheckBox
                label="Parking area is part of this test"
                name="isParkingAreaPartOfThisTest"
                value={values.isParkingAreaPartOfThisTest}
                onChange={handleInputChange}
                mode={mode}
                showSkeleton={isLoading}
              />
            </Grid>
          )}
          {showProjectType && (
            <Grid item xs={row}>
              <FormSelect
                name="projectBomaTypeId"
                label="Project Type"
                value={values?.projectBomaTypeId?.toString()}
                onChange={handleInputChange}
                options={projectBomaType?.map((type) => {
                  return { key: type.id, value: type.name };
                })}
                error={errors.projectBomaType}
                disable={isSubmitting}
                showSkeleton={isLoading}
                mode={mode}
              />
            </Grid>
          )}
          {selectedProjectBomaType && selectedProjectBomaType.name === "Other" && (
            <Grid item xs={row}>
              <FormText
                name="otherBomaType"
                label="Other Project Type"
                value={values.otherBomaType}
                onChange={handleInputChange}
                error={errors.otherBomaType}
                showSkeleton={isLoading}
                maxLength={200}
                mode={mode}
              />
            </Grid>
          )}
          <Grid item xs={row}>
            <FormTextArea
              name="projectDescription"
              label="Project Description"
              //TODO: Check null value for text area
              value={values.projectDescription ?? ""}
              onChange={handleInputChange}
              error={errors.projectDescription}
              placeholder=""
              mode={mode}
              showSkeleton={isLoading}
            />
          </Grid>
          <Grid item xs={row}>
            <FormCheckSwitch
              label="Required Comprehensive Energy Test"
              name="requiredComprehensiveEnergyTest"
              value={values.requiredComprehensiveEnergyTest}
              onChange={handleInputChange}
              mode={mode}
              showSkeleton={isLoading}
            />
          </Grid>
          {showContractedBy && (
            <>
              <Grid item xs={row}>
                <FormText
                  name="contractedBy"
                  label="Contracted By"
                  value={values.contractedBy}
                  onChange={handleInputChange}
                  error={errors.contractedBy}
                  showSkeleton={isLoading}
                  maxLength={200}
                  mode={mode}
                />
              </Grid>
              <Grid item xs={row}>
                <FormText
                  name="purchaseOrder"
                  label="Purchase Order #"
                  value={values.purchaseOrder}
                  onChange={handleInputChange}
                  error={errors.purchaseOrder}
                  showSkeleton={isLoading}
                  maxLength={200}
                  mode={mode}
                />
              </Grid>
              <Grid item xs={row}>
                <FormText
                  name="contactPerson"
                  label="Contact Person"
                  value={values.contactPerson}
                  onChange={handleInputChange}
                  error={errors.contactPerson}
                  showSkeleton={isLoading}
                  maxLength={200}
                  mode={mode}
                />
              </Grid>
              <Grid item xs={row}>
                <FormMaskText
                  name="contactPhoneNumber"
                  mask="(999) 999 - 9999"
                  label="Contact Phone Number"
                  onChange={handleInputChange}
                  value={values.contactPhoneNumber}
                  error={errors.contactPhoneNumber}
                  mode={mode}
                  showSkeleton={isLoading}
                />
              </Grid>
              <Grid item xs={row}>
                <FormCheckSwitch
                  label="Print Drawing(s) Available On-Site"
                  name="printDrawingsAvailableOnSite"
                  value={values.printDrawingsAvailableOnSite}
                  onChange={handleInputChange}
                  mode={mode}
                  showSkeleton={isLoading}
                />
              </Grid>
              <Grid item xs={row}>
                <FormTextArea
                  name="printDrawings"
                  label="Print Drawing(s) Bid for this Project"
                  value={values.printDrawings ?? ""}
                  onChange={handleInputChange}
                  error={errors.printDrawings}
                  placeholder=""
                  mode={mode}
                  showSkeleton={isLoading}
                />
              </Grid>
              <Grid item xs={row}>
                <FormDatePicker
                  name="printAddendumDateUsedForProposal"
                  label="Print Addendum Date Used for Proposal"
                  value={values.printAddendumDateUsedForProposal}
                  onChange={handleInputChange}
                  error={errors.printAddendumDateUsedForProposal}
                  mode={mode}
                  showSkeleton={isLoading}
                />
              </Grid>
              <Grid item xs={row}>
                <FormText
                  name="printProjectNumber"
                  label="Print Project Number"
                  value={values.printProjectNumber}
                  onChange={handleInputChange}
                  error={errors.printProjectNumber}
                  placeholder=""
                  mode={mode}
                  showSkeleton={isLoading}
                />
              </Grid>
              <Grid item xs={row}>
                <FormCheckSwitch
                  label="Specifications Provided"
                  name="specificationsProvided"
                  value={values.specificationsProvided}
                  onChange={handleInputChange}
                  mode={mode}
                  showSkeleton={isLoading}
                />
              </Grid>
              <Grid item xs={row}>
                <FormRadioGroup
                  name="addendumBidForThisProject"
                  label={"Addendums Bid for this Project"}
                  items={[
                    { key: ProjectAddendum.None, value: "None" },
                    { key: ProjectAddendum.Yes, value: "Yes" },
                  ]}
                  onChange={handleInputChange}
                  value={values?.addendumBidForThisProject}
                  row={true}
                  error={errors.addendumBidForThisProject}
                  mode={mode}
                  showSkeleton={isLoading}
                />
                {
                  // eslint-disable-next-line eqeqeq
                  values?.addendumBidForThisProject == ProjectAddendum.Yes && (
                    <FormTextArea
                      name="addendumBidForThisProjectValue"
                      label=""
                      value={values.addendumBidForThisProjectValue ?? ""}
                      onChange={handleInputChange}
                      error={errors.addendumBidForThisProjectValue}
                      placeholder={"Enter Addendum Information"}
                      mode={mode}
                      showSkeleton={isLoading}
                    />
                  )
                }
              </Grid>
              <Grid item xs={row}>
                <FormTextArea
                  name="equipmentDescriptionAndQuantity"
                  label="Equipment Description and Quantity"
                  value={values.equipmentDescriptionAndQuantity ?? ""}
                  onChange={handleInputChange}
                  error={errors.equipmentDescriptionAndQuantity}
                  placeholder=""
                  mode={mode}
                  showSkeleton={isLoading}
                />
              </Grid>
              <Grid item xs={row}>
                <FormCheckSwitch
                  label="Lift Work Required"
                  name="liftWorkRequired"
                  value={values.liftWorkRequired}
                  onChange={handleInputChange}
                  mode={mode}
                  showSkeleton={isLoading}
                />
              </Grid>
              <Grid item xs={row}>
                <FormCheckSwitch
                  label="Lift On-Site"
                  name="liftWorkOnSite"
                  value={values.liftWorkOnSite}
                  onChange={handleInputChange}
                  mode={mode}
                  showSkeleton={isLoading}
                />
              </Grid>
              <Grid item xs={row}>
                <FormCheckSwitch
                  label="Lift Provided By Others"
                  name="liftProvidedByOthers"
                  value={values.liftProvidedByOthers}
                  onChange={handleInputChange}
                  mode={mode}
                  showSkeleton={isLoading}
                />
              </Grid>
              <Grid item xs={row}>
                <FormTextArea
                  name="additionalLiftInstructions"
                  label="Additional Lift Instructions"
                  value={values.additionalLiftInstructions ?? ""}
                  onChange={handleInputChange}
                  error={errors.additionalLiftInstructions}
                  placeholder=""
                  mode={mode}
                  showSkeleton={isLoading}
                />
              </Grid>
              <Grid item xs={row}>
                <FormRadioGroup
                  name="roofAccessHatchAvailable"
                  label={"Roof Access / Hatch Available"}
                  items={[
                    { key: ProjectRoofAccessDTO.Yes, value: "Yes" },
                    { key: ProjectRoofAccessDTO.No, value: "No" },
                    { key: ProjectRoofAccessDTO.Unknown, value: "Unknown" },
                  ]}
                  onChange={handleInputChange}
                  value={values?.roofAccessHatchAvailable}
                  row={true}
                  error={errors.roofAccessHatchAvailable}
                  mode={mode}
                  showSkeleton={isLoading}
                />
              </Grid>
              <Grid item xs={row}>
                <FormCheckSwitch
                  label="Ladders Required"
                  name="laddersRequired"
                  value={values.laddersRequired}
                  onChange={handleInputChange}
                  mode={mode}
                  showSkeleton={isLoading}
                />
              </Grid>
              {values.laddersRequired && (
                <>
                  {ladderAFrame && !viewMode && (
                    <Grid item xs={row}>
                      <TransferList
                        label="A-Frame"
                        options={ladderAFrame?.map((item) => ({
                          key: item.id,
                          value: item.name,
                        }))}
                        rightInitialKeys={
                          values.ladderAFrames?.map((value) => value.id) ?? []
                        }
                        onChange={ladderAFrameHandler}
                        mode={mode}
                        sortById={true}
                      />
                    </Grid>
                  )}

                  {ladderExtension && (
                    <Grid item xs={row}>
                      <TransferList
                        label="Extension"
                        options={ladderExtension?.map((item) => ({
                          key: item.id,
                          value: item.name,
                        }))}
                        rightInitialKeys={
                          values.ladderExtensions?.map((value) => value.id) ??
                          []
                        }
                        onChange={ladderExtensionHandler}
                        mode={mode}
                      />
                    </Grid>
                  )}
                  <Grid item xs={row}>
                    <FormText
                      name="ladderOther"
                      label="Other"
                      value={values.ladderOther}
                      onChange={handleInputChange}
                      error={errors.ladderOther}
                      showSkeleton={isLoading}
                      maxLength={200}
                      mode={mode}
                    />
                  </Grid>
                </>
              )}
              <Grid item xs={row}>
                <FormText
                  name="controlContractorName"
                  label="Control Contractor Name"
                  value={values.controlContractorName}
                  onChange={handleInputChange}
                  error={errors.controlContractorName}
                  showSkeleton={isLoading}
                  maxLength={200}
                  mode={mode}
                />
              </Grid>
              <Grid item xs={row}>
                <FormText
                  name="contactPersonTechnician"
                  label="Contact Person/Technician"
                  value={values.contactPersonTechnician}
                  onChange={handleInputChange}
                  error={errors.contactPersonTechnician}
                  showSkeleton={isLoading}
                  maxLength={200}
                  mode={mode}
                />
              </Grid>
              <Grid item xs={row}>
                <FormMaskText
                  name="controlContractorPhoneNumber"
                  mask="(999) 999 - 9999"
                  label="Control Contractors Contact Phone Number"
                  onChange={handleInputChange}
                  value={values.controlContractorPhoneNumber}
                  error={errors.controlContractorPhoneNumber}
                  mode={mode}
                  showSkeleton={isLoading}
                />
              </Grid>
              <Grid item xs={row}>
                <FormText
                  name="controlManufacturer"
                  label="Installed Controls Manufacturer"
                  value={values.controlManufacturer}
                  onChange={handleInputChange}
                  error={errors.controlManufacturer}
                  showSkeleton={isLoading}
                  maxLength={200}
                  mode={mode}
                />
              </Grid>
              <Grid item xs={row}>
                <FormSelect
                  name="controlType"
                  label="Installed Controls Type"
                  value={values.controlType ?? ""}
                  onChange={handleInputChange}
                  options={controlTypeOptions}
                  error={errors.controlType}
                  disable={isSubmitting}
                  showSkeleton={isLoading}
                  mode={mode}
                />
              </Grid>
              {values.controlType === "Other" && (
                <Grid item xs={row}>
                  <FormText
                    name="controlTypeOther"
                    label=""
                    value={values.controlTypeOther}
                    onChange={handleInputChange}
                    error={errors.controlTypeOther}
                    showSkeleton={isLoading}
                    maxLength={200}
                    placeholder="Indicate other installed controls type"
                    mode={mode}
                  />
                </Grid>
              )}
              <Grid item xs={row}>
                <FormText
                  name="controlVersion"
                  label="Installed Controls Version"
                  value={values.controlVersion}
                  onChange={handleInputChange}
                  error={errors.controlVersion}
                  showSkeleton={isLoading}
                  maxLength={200}
                  mode={mode}
                />
              </Grid>
              {auditorsDataHasSheduler && auditorsDataHasSheduler?.length > 0 && (
                <>
                  <Grid item xs={row}>
                    <FormLabel>
                      Technician(s) scheduled to be assigned to this project
                    </FormLabel>
                  </Grid>
                  <Grid item xs={row} p={0}>
                    <List>
                      <Stack direction="row" spacing={2}>
                        {auditorsDataHasSheduler.map((row) => {
                          return (
                            <ListItemButton>
                              <Typography
                                color={
                                  row.textColor === "text-primary"
                                    ? `primary.main`
                                    : row.textColor === "text-warning"
                                    ? `warning.main`
                                    : `success.main`
                                }
                              >
                                {row.name} {row.lastName}
                              </Typography>
                            </ListItemButton>
                          );
                        })}
                      </Stack>
                    </List>
                  </Grid>
                </>
              )}

              <Grid item xs={row}>
                {auditorsDataList && fullAccessTechAuditor && (
                  <TransferList
                    label="Auditors/Technician"
                    options={auditorsDataList?.map(
                      (item) =>
                        ({
                          key: item.id,
                          value: item.name,
                          color:
                            item.textColor === "text-primary"
                              ? `primary.main`
                              : item.textColor === "text-warning"
                              ? `warning.main`
                              : `success.main`,
                        } as KeyValueListColor)
                    )}
                    rightInitialKeys={
                      values.auditors?.map((value) => value.id ?? 0) ?? []
                    }
                    onChange={auditorsHandler}
                    mode={
                      (user?.role === "Auditor" ||
                        user?.role === "EnergyAuditor") &&
                      user.canAddTechniciansToProject === "false"
                        ? "read"
                        : mode
                    }
                    disabled={
                      (user?.role === "Auditor" ||
                        user?.role === "EnergyAuditor") &&
                      user.canAddTechniciansToProject === "false"
                    }
                  />
                )}
              </Grid>
              <Grid item xs={row}>
                {safetyEquipmentsData && (
                  <>
                    <TransferList
                      label="Safety Equipment"
                      options={safetyEquipmentsData?.map((item) => ({
                        key: item.id,
                        value: item.name,
                      }))}
                      rightInitialKeys={
                        newProject
                          ? [1, 2, 4]
                          : values.safetyEquipments?.map((value) => value.id) ??
                            []
                      }
                      onChange={safetyEquipmentsHandler}
                      mode={mode}
                    />
                  </>
                )}
              </Grid>
              <Grid item xs={row}>
                <Stack direction={"row"}>
                  <FormReadOnlyField
                    label="Building Pressurization"
                    value={pressurization}
                    showSkeleton={isLoading}
                  />
                  <Tooltip
                    TransitionComponent={Zoom}
                    title={
                      'Go to "Building" then "Specifics" to change the required building pressurization status.'
                    }
                  >
                    <span>
                      <IconButton aria-label="delete" size="large">
                        <InfoIcon />
                      </IconButton>
                    </span>
                  </Tooltip>
                </Stack>
              </Grid>
              <Grid item xs={row}>
                <FormTextArea
                  name="additionalProjectInstructions"
                  label="Additional Project Instructions/Directions"
                  value={values.additionalProjectInstructions ?? ""}
                  onChange={handleInputChange}
                  error={errors.additionalProjectInstructions}
                  placeholder=""
                  mode={mode}
                  showSkeleton={isLoading}
                />
              </Grid>
            </>
          )}
          <Grid item xs={12}>
            <Stack
              direction="row"
              spacing={2}
              justifyContent="center"
              alignItems="center"
            >
              <FormButton
                onClick={cancelHandler}
                isSubmitting={isSubmitting}
                variant="outlined"
                size="small"
                text="Close"
              />
              {showCreateProjectButton && (
                <FormButton
                  onClick={() => saveHandler(true)}
                  isSubmitting={isSubmitting}
                  text="Create Project"
                  size="small"
                />
              )}
              {showUpdateButton && (
                <FormButton
                  onClick={updateHandler}
                  isSubmitting={isSubmitting}
                  text="Update"
                  size="small"
                />
              )}
              {createdProject && (
                <FormNextButton
                  onClick={nextStep}
                  isSubmitting={isSubmitting}
                  text={nextStepButtonLabel}
                  size="small"
                />
              )}
            </Stack>
          </Grid>
        </Grid>
      </Form>
      <ValidateAuditorsPopUp
        showValidateAuditorsPopUp={showValidateAuditorsPopUp}
        setShowValidateAuditorsPopUp={setShowValidateAuditorsPopUp}
        projectId={validateAuditor?.projectId ?? 0}
        name={validateAuditor?.userName ?? ""}
        userId={validateAuditor?.userId ?? 0}
        toProjectId={projectId}
        save={
          showCreateProjectButton
            ? saveHandler
            : gotoNext
            ? nextStep
            : updateHandler
        }
        scheduled={sheduleAuditorsHandler}
      ></ValidateAuditorsPopUp>
      <DialogMessagePopup
        title={"Information"}
        text={
          "Are you certain you want to Create your project without assigning an Auditor or Technician?"
        }
        showPopup={validateAuditorModalShow}
        setShowPopup={setValidateAuditorModalShow}
        onSave={() => {
          saveHandler(false);
        }}
        onCancel={() => {}}
        isSubmitting={false}
      />
    </>
  );
};

export default ProjectForm;
