import React, { useState, useEffect, useMemo } from "react";
import styled from "@emotion/styled";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import useLog from "src/hooks/useLog";
import {
  CardContent,
  Grid,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  Typography,
  Stack,
  Button,
  Tooltip,
  IconButton,
  Zoom,
} from "@mui/material";
import { spacing } from "@mui/system";
import {
  useForm,
  Form,
  useCountry,
  useStateCatalog,
  useAuth,
  usePermissions,
} from "src/hooks";
import {
  FormCancelButton,
  FormSaveButton,
  FormSelect,
  FormText,
  FormMaskText,
} from "src/components/formControls";
import { IUserPerson, IRoleAlias, IRoleUpdate } from "src/ts/interfaces";
import { UserParams, Validator } from "src/ts/types";
import { COUNTRIES, ROLES, STATUSES } from "src/constants";
import { userService } from "src/services";
import EditUserRoles from "./components/EditUserRoles";
import EnableDisableUpdatePopUp from "./components/EnableDisableUpdatePopUp";
import Popup from "src/components/Popup";
import UnionSection from "../profile/UnionSection";
import { IUnionAffiliationNumbers } from "src/ts/interfaces/catalogs";
import { Permission as PermissionTypes } from "src/ts/enums";
import { InfoIcon } from "src/components/icons";
import DialogMessagePopup from "src/components/DialogMessagePopup";
import UserStatusHistory from "./components/UserStatusHistory";
const Card = styled(MuiCard)(spacing);
const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const initialValues: IUserPerson = {
  id: 0,
  name: "",
  certification: "",
  lastName: "",
  email: "",
  address: "",
  address2: "",
  stateId: 0,
  statusId: 0,
  countryId: 0,
  zip: "",
  city: "",
  countryCode: "",
  phone: null,
  extension: "",
  userName: "",
  userAuth: "",
  stateName: "",
  isEmailConfirmed: false,
  unibesEnabled: true,
  unionAffiliated: {
    unionAffiliatedAnswer: false,
    apprentice: true,
    isAffiliated: false,
    isCanceled: false,
    smwiaLocalUnion: "",
    smwiaMember: "",
    code: "",
    unionAffiliationNumbers: {
      localUnion: "",
    },
    laborUnionsId: 0,
    localUnionsId: 0,
  },
  hasSheduler: false,
  roleName: "",
  textColor: "",
};

const initialValuesRoles: IRoleUpdate = {
  emailConfirmed: false,
  id: "",
  role: null,
  roles: [],
  userName: "",
};

const User = () => {
  let { id, companyId: companyParameter } = useParams<UserParams>();
  const userId = parseInt(id === undefined ? "0" : id);
  const companyId = parseInt(
    companyParameter === undefined ? "0" : companyParameter
  );
  const { log } = useLog();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showStatusHistory, setShowStatusHistory] = useState(false);
  const [showPopUp, setShowPopUp] = useState(false);
  const [informationShowPopUp, setInofrmationShowPopUp] = useState(false);
  const [roleCatalog, setRoleCatalog] = useState<IRoleAlias[]>([]);
  const [currentRoles, setCurrentRoles] = useState<string[]>([]);
  const [filterroles, setFilterroles] = useState<IRoleAlias[]>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [unionNumberData, setUnionNumberData] = useState<
    IUnionAffiliationNumbers[]
  >([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [unionNumberDataAll, setUnionNumberDataAll] = useState<
    IUnionAffiliationNumbers[]
  >([]);

  const [registerAccount, setRegisterAccount] =
    useState<IRoleUpdate>(initialValuesRoles);

  const [stateUser, setStateUser] = useState(0);
  const { user } = useAuth();
  let navigate = useNavigate();

  const { read: readUnion } = usePermissions(
    PermissionTypes.User_Union_Affiliation_Section
  );
  const { fullAccess: fullAccessActivateDes } = usePermissions(
    PermissionTypes.Users_SuperAdmin
  );

  const { readOnly, fullAccess } = usePermissions(PermissionTypes.Users);

  const { countriesKeyValue, countries } = useCountry();
  const { states, setCountryId, isLoading } = useStateCatalog();

  const disableUser = useMemo(() => {
    return readOnly || user?.role === "UnionAdministrator";
  }, [readOnly, user?.role]);

  const validate = (fieldValues = values) => {
    let temp: Record<string, string> = { ...errors };

    temp.name = new Validator(fieldValues, "name")
      .isRequired("The User's first name is required")
      .maxLength(200, "Only 200 character are allowed")
      .toString();

    temp.lastName = new Validator(fieldValues, "lastName")
      .isRequired("The User's last name is required.")
      .maxLength(200, "Only 200 character are allowed")
      .toString();

    temp.address = new Validator(fieldValues, "address")
      .isRequired("The User's address is required.")
      .maxLength(500, "Only 500 character are allowed")
      .toString();

    temp.address2 = new Validator(fieldValues, "address2")
      .maxLength(500, "Only 500 character are allowed")
      .toString();

    temp.countryId = new Validator(fieldValues, "countryId")
      .selectedOption("", "The User's country is required.")
      .toString();

    temp.zip = new Validator(fieldValues, "zip")
      .isRequired("The User's zipcode is required.")
      .maxLength(5, "Only 5 character are allowed")
      .toString();

    temp.city = new Validator(fieldValues, "city")
      .isRequired("The User's city is required.")
      .maxLength(50, "Only 50 character are allowed")
      .toString();

    temp.stateId = new Validator(fieldValues, "stateId")
      .selectedOption("", "The User's state is required.")
      .toString();

    temp.stateName = new Validator(fieldValues, "stateName")
      .validateIf(fieldValues.countryId !== COUNTRIES.USA)
      .isRequired("The User's state is required.")
      .maxLength(50, "Only 50 character are allowed")
      .toString();

    temp.phone = new Validator(fieldValues, "phone")
      .isRequired("The User's cell phone is required.")
      .phoneFormat("Incorrect phone format.")
      .toString();

    temp.email = new Validator(fieldValues, "email")
      .isRequired("The User's email is required.")
      .maxLength(50, "Only 50 character are allowed")
      .emailFormat("Insert a correct email. demo@bootlab.io")
      .toString();

    if (fieldValues?.unionAffiliated?.unionAffiliatedAnswer) {
      temp.laborUnionsId = new Validator(
        fieldValues.unionAffiliated,
        "laborUnionsId"
      )
        .selectedOption(0, "The Labor Union is required.")
        .toString();
      temp.smwiaMember = new Validator(
        fieldValues.unionAffiliated,
        "smwiaMember"
      )
        .isRequired("The Member # is required.")
        .toString();

      temp.localUnionsId = new Validator(
        fieldValues.unionAffiliated,
        "localUnionsId"
      )
        .selectedOption(0, "The Local Union is required.")
        .toString();
    }

    if (
      fieldValues?.unionAffiliated?.laborUnionsId === 2 ||
      fieldValues?.unionAffiliated?.laborUnionsId === 3
    ) {
      temp.localUnionsOther = new Validator(
        fieldValues.unionAffiliated,
        "localUnionsOther"
      )
        .isRequired("The Local Union is required.")
        .toString();
    }

    if (currentRoles.length === 0 && user?.role !== ROLES.Architect) {
      temp.currentRoles = "User must have at least one role";
      log.warning("User must have at least one role");
    } else {
      temp.currentRoles = "";
    }

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange } = useForm(
    initialValues,
    false,
    validate
  );

  useEffect(() => {
    const getUser = async (userId: number) => {
      const response = await userService.getById(userId);
      setValues(response.data);
      setStateUser(response.data.statusId);
    };
    const getUnionNUmbers = async () => {
      try {
        const response = await userService.getUnionAffiliationNumbers();
        const sortedData = response.data.sort((a, b) => {
          return b.localUnion?.localeCompare(a.localUnion ?? "") || 0;
        });
        setUnionNumberDataAll(sortedData);

        const newData = response.data.map((option: any) => ({
          localUnion: option.localUnion,
          city: option.city,
          state: {
            name: option.state === null ? option.stateName : option.state.name,
          },
        }));

        setUnionNumberData((prevData) => [
          ...prevData,
          ...(newData as unknown as IUnionAffiliationNumbers[]),
        ]);
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      }
    };

    if (userId > 0) {
      getUser(userId);
      getUnionNUmbers();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setValues, userId]);

  useEffect(() => {
    if (countries.length > 0) {
      const countryCodevalue = countries.filter((item: any) => {
        return item.id === values.countryId;
      });

      setValues({
        ...values,
        countryCode: countryCodevalue[0].countryCode,
      });
    }
    setCountryId(values.countryId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setCountryId, values.countryId]);

  useEffect(() => {
    setFilterroles(
      currentRoles?.flatMap((item: any) => {
        return roleCatalog.filter((value: any) => {
          return item === value.alias;
        });
      })
    );
    setRegisterAccount({
      emailConfirmed: values.isEmailConfirmed,
      id: values.userAuth,
      role: null,
      roles: filterroles,
      userName: values.email,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentRoles]);

  const saveHandler = async () => {
    if (!validate()) return;
    if (userId > 0) {
      try {
        setIsSubmitting(true);

        if (user?.role !== ROLES.Architect) {
          await userService.userAccountUpdateRole({
            ...registerAccount,
            roles: filterroles,
          });
        }

        if (
          values.unionAffiliated !== null &&
          values.unionAffiliated.unionAffiliatedAnswer
        ) {
          await userService.unionAffiliatedUser({
            laborUnions: values?.unionAffiliated?.laborUnions,
            laborUnionsId: values?.unionAffiliated?.laborUnionsId,
            localUnions: values?.unionAffiliated?.localUnions,
            localUnionsId: values?.unionAffiliated?.localUnionsId,
            smwiaMember: values?.unionAffiliated?.smwiaMember,
            unionAffiliatedAnswer:
              values?.unionAffiliated?.unionAffiliatedAnswer,
            userPersonId: userId,
            localUnionsOther: values?.unionAffiliated?.localUnionsOther,
            laborUnionsOther: values?.unionAffiliated?.laborUnionsOther,
          });
        }
        if (stateUser !== values.statusId) {
          setShowPopUp(true);
        } else {
          await userService.update(userId, true, values);
          log.success("User was updated");
          setIsSubmitting(false);
          navigate(`/app/users/${companyId}`);
        }
        setIsSubmitting(false);
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      }
    }
  };

  const handleOnChangeMask = (e: any) => {
    const { name, value } = e.target;

    setValues({ ...values, [name]: value });
  };
  const cancelHandler = () => {
    navigate(`/app/users/${companyId}`);
  };

  const row = 6;

  const handleClosePopUp = () => {
    setInofrmationShowPopUp(false);
  };

  const InformationUnion = () => (
    <>
      <Popup
        title="Information"
        openPopup={informationShowPopUp}
        setOpenPopup={setInofrmationShowPopUp}
        onClose={handleClosePopUp}
        size="sm"
      >
        <>
          <Typography>
            <b>
              The acronym SMWIA specifically represents the Sheet Metal Workers
              International Association, which pertains to union affiliation.
            </b>
          </Typography>
          <br />
          <Typography>
            <b>
              Additionally, the acronym SMART represents Sheet Metal, Air, Rail,
              and Transportation workers.
            </b>
          </Typography>
        </>
        <Stack direction="row" spacing={3}>
          <Button
            type="submit"
            variant="contained"
            color="error"
            onClick={handleClosePopUp}
          >
            Close
          </Button>
        </Stack>
      </Popup>
    </>
  );

  return (
    <>
      <Helmet title="Company" />
      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            User
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} to="/">
              Dashboard
            </Link>
            <Link component={NavLink} to="/app/users">
              Users
            </Link>
            <Typography>User</Typography>
          </Breadcrumbs>
        </Grid>
      </Grid>
      <Divider my={6} />
      <Card mb={6}>
        <CardContent>
          <Form>
            <Grid container spacing={5}>
              <Grid item xs={row}>
                <FormText
                  name="name"
                  label="First Name"
                  value={values.name}
                  onChange={handleInputChange}
                  error={errors.name}
                  showSkeleton={isLoading}
                  disabled={disableUser}
                />
              </Grid>
              <Grid item xs={row}>
                <FormText
                  name="lastName"
                  label="Last Name"
                  value={values.lastName}
                  onChange={handleInputChange}
                  error={errors.lastName}
                  showSkeleton={isLoading}
                  disabled={disableUser}
                />
              </Grid>
              <Grid item xs={row}>
                <FormText
                  name="address"
                  label="Address"
                  value={values.address}
                  onChange={handleInputChange}
                  error={errors.address}
                  showSkeleton={isLoading}
                  disabled={disableUser}
                />
              </Grid>
              <Grid item xs={row}>
                <FormText
                  name="address2"
                  label="Address 2"
                  value={values.address2}
                  onChange={handleInputChange}
                  error={errors.address2}
                  showSkeleton={isLoading}
                  disabled={disableUser}
                />
              </Grid>
              <Grid item xs={row}>
                <FormSelect
                  name="countryId"
                  label="Country"
                  value={values.countryId}
                  onChange={handleInputChange}
                  options={countriesKeyValue}
                  error={errors.countryId}
                  disable={isSubmitting || disableUser}
                  showSkeleton={isLoading}
                />
              </Grid>
              <Grid item xs={row}>
                <FormText
                  name="zip"
                  label="Zip Code"
                  value={values.zip}
                  onChange={handleInputChange}
                  error={errors.zip}
                  showSkeleton={isLoading}
                  disabled={disableUser}
                />
              </Grid>
              <Grid item xs={row}>
                <FormText
                  name="city"
                  label="City"
                  value={values.city}
                  onChange={handleInputChange}
                  error={errors.city}
                  showSkeleton={isLoading}
                  disabled={disableUser}
                />
              </Grid>
              {values.countryId !== Number.MIN_SAFE_INTEGER &&
              values.countryId === COUNTRIES.USA ? (
                <Grid item xs={row}>
                  <FormSelect
                    name="stateId"
                    label="State"
                    value={values.stateId}
                    onChange={handleInputChange}
                    options={states}
                    error={errors.stateId}
                    disable={isSubmitting || disableUser}
                    showSkeleton={isLoading}
                  />
                </Grid>
              ) : (
                <Grid item xs={row}>
                  <FormText
                    name="stateName"
                    label="State"
                    value={values.stateName}
                    onChange={handleInputChange}
                    error={errors.stateName}
                    showSkeleton={isLoading}
                    disabled={disableUser}
                  />
                </Grid>
              )}
              <Grid item xs={row}>
                <Stack direction="row">
                  <Grid container>
                    <Grid item xs={1.5}>
                      <FormText
                        name="countryCode"
                        label="Code"
                        value={values.countryCode}
                        disabled={disableUser}
                      />
                    </Grid>
                    <Grid item xs={10.5}>
                      <FormMaskText
                        name="phone"
                        mask="(999) 999 - 9999"
                        label="Cell Phone"
                        onChange={handleOnChangeMask}
                        value={values.phone}
                        error={errors.phone}
                        disabled={disableUser}
                      />
                    </Grid>
                  </Grid>
                </Stack>
              </Grid>
              <Grid item xs={row}>
                <Stack direction="row">
                  <Grid container>
                    <Grid item xs={1.5}>
                      <FormText
                        name="countryCode"
                        label="Code"
                        value={values.countryCode}
                        disabled={disableUser}
                      />
                    </Grid>
                    <Grid item xs={10.5}>
                      <FormMaskText
                        name="fixedPhone"
                        mask="(999) 999 - 9999"
                        label="Phone"
                        onChange={handleOnChangeMask}
                        value={values.fixedPhone}
                        error={errors.fixedPhone}
                        disabled={disableUser}
                      />
                    </Grid>
                  </Grid>
                </Stack>
              </Grid>
              <Grid item xs={row}>
                <FormText
                  name="email"
                  label="Email"
                  value={values.email}
                  onChange={handleInputChange}
                  error={errors.email}
                  showSkeleton={isLoading}
                  disabled={true}
                />
              </Grid>
              <Grid item xs={row}>
                {values.unibesEnabled ? (
                  <>
                    <Grid container>
                      <Grid item xs={11}>
                        <FormSelect
                          name="statusId"
                          label="Status"
                          value={values.statusId ?? 2}
                          onChange={handleInputChange}
                          options={STATUSES}
                          error={errors.statusId}
                          disable={
                            isSubmitting ||
                            disableUser ||
                            readOnly ||
                            (user?.role === ROLES.SuperAdministrator
                              ? !fullAccessActivateDes
                              : false)
                          }
                          showSkeleton={isLoading}
                        />
                      </Grid>
                      <Grid item xs={1} textAlign={"center"}>
                        <Tooltip
                          TransitionComponent={Zoom}
                          title={"Status History."}
                        >
                          <span>
                            <IconButton
                              aria-label="delete"
                              size="large"
                              onClick={() => {
                                setShowStatusHistory(true);
                              }}
                            >
                              <InfoIcon />
                            </IconButton>
                          </span>
                        </Tooltip>
                      </Grid>
                    </Grid>
                  </>
                ) : (
                  <>
                    <Grid item xs={12}>
                      <span style={{ color: "red" }}>
                        <b>User was disabled by AMP's administrator. </b>
                      </span>
                    </Grid>
                    <Grid container>
                      <Grid item xs={1.3}>
                        <b>Reason:</b>
                      </Grid>
                      <Grid item xs={10.7}>
                        {values.unibesComments}
                      </Grid>
                    </Grid>
                  </>
                )}
              </Grid>
              {user &&
                user.role !== ROLES.Architect &&
                values.companyId !== 1 &&
                values?.company?.subscriptionId !== 1 && (
                  <>
                    <Grid item xs={row}>
                      <EditUserRoles
                        setRolesData={setRoleCatalog}
                        userId={userId}
                        userRole={user?.role}
                        userAuth={values.userAuth}
                        setSelectedRoles={setCurrentRoles}
                      />
                    </Grid>
                    <Grid item xs={row}></Grid>

                    {process.env.REACT_APP_ENVIRONMENT !== "SCHOOL" && (
                      <Grid item xs={12}>
                        {readUnion && (
                          <UnionSection
                            values={values}
                            setValues={setValues}
                            errors={errors}
                          />
                        )}
                      </Grid>
                    )}
                  </>
                )}
              <Grid item xs={12}>
                <Stack
                  direction="row"
                  spacing={2}
                  justifyContent="center"
                  alignItems="center"
                >
                  <FormCancelButton
                    onClick={cancelHandler}
                    isSubmitting={isSubmitting}
                    text="Close"
                  />
                  {!readOnly && (
                    <FormSaveButton
                      onClick={saveHandler}
                      isSubmitting={isSubmitting}
                    />
                  )}
                </Stack>
              </Grid>
            </Grid>
          </Form>
        </CardContent>
      </Card>
      <EnableDisableUpdatePopUp
        showPopUp={showPopUp}
        setShowPopUp={setShowPopUp}
        name={`${values.name} ${values.lastName}`}
        enableUser={values.statusId === 1 ? false : true}
        userID={userId}
        valuesOther={values}
        redirect={true}
      />

      {values.id !== 0 && showStatusHistory && (
        <UserStatusHistory
          showPopUp={showStatusHistory}
          setShowPopUp={setShowStatusHistory}
          userId={values.id}
        />
      )}

      <InformationUnion />
    </>
  );
};

export default User;
