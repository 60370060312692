import { useEffect, useState } from "react";
import { Grid, Stack } from "@mui/material";
import { Form, useAuth, useLog, usePermissions } from "src/hooks";
import {
  FormButton,
  FormCheckBox,
  FormSaveButton,
  FormTextArea,
} from "src/components/formControls";
import Popup from "src/components/Popup";
import TimeCardGrid from "./TimeCardGrid";
import { ITimeCard } from "src/ts/interfaces";
import timeCardService from "src/services/project/timeCardService";
import useFormTyped from "src/hooks/useFormTyped";
import { Validator } from "src/ts/types/Validator";
import FormNumeric from "../formControls/FormNumeric";
import { Permission as PermissionTypes } from "src/ts/enums";
import { ProjectDTO } from "src/ts/interfaces/project/projectDto";

const ValidateDate = (a: Date, b: Date) => {
  b = new Date(
    Date.UTC(
      b.getFullYear(),
      b.getMonth(),
      b.getDate(),
      b.getHours(),
      b.getMinutes(),
      b.getSeconds()
    )
  );

  return (
    a.getFullYear() === b.getFullYear() &&
    a.getMonth() === b.getMonth() &&
    a.getDate() === b.getDate()
  );
};

interface ConfirmModalProps {
  project: ProjectDTO;
  isDialogOpen: boolean;
  setIsDialogOpen: (val: boolean) => void;
  isCurrentProject: boolean;
  validateEstimatedTime: () => void;
  viewMode?: boolean;
}

const initialValues: ITimeCard = {
  hours: 0,
  confirm: false,
  comments: "",
  id: 0,
  projectId: "",
  createdBy: null,
  createdDate: "",
  updatedBy: null,
  updatedDate: "",
  userName: "",
};

const TimeCardPopup = (props: ConfirmModalProps) => {
  const { project, isDialogOpen, setIsDialogOpen, isCurrentProject } = props;
  const { user } = useAuth();
  const { log } = useLog();
  const { fullAccess: fullAccessToProjects } = usePermissions(
    PermissionTypes.Project
  );

  const { readOnly: ProjectAdminReadOnly } = usePermissions(
    PermissionTypes.Project_Admin
  );
  let editTime: boolean = true;
  if (!fullAccessToProjects) {
    editTime = !fullAccessToProjects;
  }

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [refreshGrid, setRefreshGrid] = useState(false);
  const [timeCard, setTimeCard] = useState<ITimeCard[]>([]);
  const [todayTimeCard, setTodayTimeCard] = useState(false);
  const [canEditTime, setCanEditTime] = useState(false);
  const [timeCardFirstUpdate, setTimeCardFirstUpdate] = useState(false);
  const validate: any = (fieldValues: ITimeCard = values) => {
    let temp: Record<string, string> = { ...errors };

    temp.hours = new Validator(fieldValues, "hours")
      .isRequired("The time duration is required.")
      .greatThan(0, "Must be great than 0")
      .lessThan(25, "Must be less than 24")
      .toString();

    temp.comments = new Validator(fieldValues, "comments")
      .maxLength(500, "Only 500 characters are allowed")
      .toString();

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useFormTyped<ITimeCard>(initialValues, false, validate);

  useEffect(() => {
    const getData = async () => {
      const req = await timeCardService.getByProject(project.id);
      const sortedTimeCard = req.data.sort((a, b) => (a.id > b.id ? -1 : 1));
      setTimeCard(sortedTimeCard);
      setTimeCardFirstUpdate(true);
    };

    if (project.id > 0 && project.projectStatusId > 0) {
      getData();

      if (fullAccessToProjects) {
        setCanEditTime(true);
      }

      if (
        user?.role === "Auditor" ||
        user?.role === "EnergyAuditor" ||
        user?.role === "TemporalAuditor" ||
        user?.role === "TemporalEnergyAuditor"
      ) {
        setCanEditTime(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project.id, refreshGrid, project.projectStatusId]);

  useEffect(() => {
    if (timeCardFirstUpdate) {
      estimatedTime();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeCardFirstUpdate]);

  useEffect(() => {
    if (isDialogOpen) {
      estimatedTime();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDialogOpen]);

  function estimatedTime() {
    let viewMode = false; //$routeParams.viewMode == 0
    let todayTimeCardLocal = false;
    if (
      project.projectStatusId < 3 &&
      (user?.role === "Auditor" ||
        user?.role === "EnergyAuditor" ||
        user?.role === "TemporalAuditor" ||
        user?.role === "TemporalEnergyAuditor")
    ) {
      const viewModeEnergy = false;
      //$routeParams.viewMode == 0 && !viewMode ? false : true;
      if (!viewMode) {
        viewMode =
          user?.canAddBuildingsAndProjects === "true" ||
          user?.canAddBuildingsAndProjects === true
            ? false
            : true;
      }
      var todayDate = new Date();

      if (timeCard.length > 0) {
        var firstItem = new Date(timeCard[0].createdDate);
        if (ValidateDate(todayDate, firstItem)) {
          todayTimeCardLocal = true;
          setValues({ ...timeCard[0] });
          props.validateEstimatedTime();
        }
        if (
          !viewModeEnergy &&
          user?.role !== "Training" &&
          !todayTimeCardLocal
        ) {
          openTimeCard(false);
        }
      } else {
        if (!viewModeEnergy && user?.role !== "Training") {
          openTimeCard(false);
        }
      }
    } else if (
      !(
        user?.role === "Auditor" ||
        user?.role === "EnergyAuditor" ||
        user?.role === "TemporalAuditor" ||
        user?.role === "TemporalEnergyAuditor"
      )
    ) {
      todayTimeCardLocal = true;
    }

    setTodayTimeCard(todayTimeCardLocal);
  }

  const openTimeCard = (openTime = true) => {
    if (
      openTime ||
      (project.projectStatusId < 3 &&
        (!(
          project.isInfectiousControlProject &&
          (user?.role === "TemporalAuditor" || user?.role === "Auditor")
        ) ||
          openTime) &&
        isCurrentProject)
    ) {
      setIsDialogOpen(true);
    }
  };

  const saveHandler = async () => {
    if (!validate()) return;

    try {
      setIsSubmitting(true);
      if (todayTimeCard) {
        await timeCardService.update(values);
      } else {
        await timeCardService.save({
          comments: values.comments,
          hours: values.hours,
          projectId: project.id,
        });
      }

      log.success("Time card was save");
      setIsSubmitting(false);
      setRefreshGrid(!refreshGrid);

      setIsDialogOpen(false);
      props.validateEstimatedTime();
    } catch (error: any) {
      log.error(error?.message?.exceptionMessage ?? "Something went wrong");
    } finally {
      setIsSubmitting(false);
    }
  };

  const row = 6;
  const cancelHandler = () => {
    resetForm();
    setIsDialogOpen(false);
    props.validateEstimatedTime();
  };

  const showBypassTimeCard =
    editTime &&
    user?.canByPassTimeCard === "true" &&
    !todayTimeCard &&
    (user?.role === "Auditor" ||
      user?.role === "EnergyAuditor" ||
      user?.role === "TemporalAuditor" ||
      user?.role === "TemporalEnergyAuditor" ||
      user?.role === "Training");

  const closeWithoutSaving = !editTime || todayTimeCard || ProjectAdminReadOnly;

  const closeTimeCard = () => {
    resetForm();
    setIsDialogOpen(false);
    props.validateEstimatedTime();
  };

  return (
    <Popup
      openPopup={isDialogOpen}
      setOpenPopup={setIsDialogOpen}
      title={`Project Time Card`}
      onClose={cancelHandler}
      hideClose={true}
      disableClickOutside={true}
      disableEscKeyDown={true}
    >
      <Grid container spacing={6}>
        <Grid item xs={12}>
          {canEditTime && !props.viewMode && (
            <Form>
              <Grid container>
                <Grid item xs={12}>
                  <Form>
                    <Grid container spacing={5}>
                      <Grid item xs={row}>
                        Enter the hours you invested / planning to invest today
                        for this project:
                        <br />
                        <span>
                          <small>
                            If just starting project and unknown, enter 1 hour
                            and modify your total hours at the end of the day
                          </small>
                        </span>
                      </Grid>
                      <Grid item xs={row}>
                        <FormNumeric
                          name="hours"
                          label="Hours"
                          value={values.hours.toString()}
                          onChange={handleInputChange}
                          maxLength={5}
                          error={errors.hours}
                          disabled={!isCurrentProject}
                        />
                      </Grid>
                      <Grid item xs={row}>
                        Please confirm that you are entering {values.hours}{" "}
                        hours for today
                      </Grid>
                      <Grid item xs={row}>
                        <FormCheckBox
                          label=""
                          name="confirm"
                          value={values.confirm}
                          onChange={handleInputChange}
                          disabled={!isCurrentProject}
                        />
                      </Grid>
                      <Grid item xs={row}>
                        Comments
                      </Grid>
                      <Grid item xs={row}>
                        <FormTextArea
                          name="comments"
                          label=""
                          value={values.comments}
                          onChange={handleInputChange}
                          error={errors.comments}
                          placeholder=""
                          disabled={!isCurrentProject}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <Stack
                          direction="row"
                          spacing={2}
                          justifyContent="center"
                          alignItems="center"
                        >
                          <FormSaveButton
                            disabled={!values.confirm || !isCurrentProject}
                            onClick={saveHandler}
                            isSubmitting={isSubmitting}
                          />
                        </Stack>
                      </Grid>
                    </Grid>
                  </Form>
                </Grid>
              </Grid>
            </Form>
          )}
        </Grid>
        <Grid item xs={12}>
          <TimeCardGrid data={timeCard} />
        </Grid>
      </Grid>
      <>
        <Stack
          direction="row"
          spacing={2}
          justifyContent="center"
          alignItems="center"
        >
          {showBypassTimeCard && (
            <FormButton
              onClick={closeTimeCard}
              isSubmitting={isSubmitting}
              text="Bypass Time Card"
              color="primary"
              size="small"
            />
          )}

          {closeWithoutSaving && (
            <FormButton
              text="Close without saving"
              onClick={cancelHandler}
              isSubmitting={isSubmitting}
              color="error"
              size="small"
            />
          )}
        </Stack>
      </>
    </Popup>
  );
};

export default TimeCardPopup;
