import { IFormulaSystemService } from "src/ts/interfaces";

const rectangularArea = (height: number, width: number) => {
  return height * width;
};

const circularArea = (diameter: number) => {
  return Math.PI * Math.pow(diameter / 2, 2);
};

const averageVelocity = (systemCFM: number, Sqft: number) => {
  return systemCFM / (Sqft * 1000);
};

const vp = (AvgVel: number) => {
  return Math.pow(AvgVel, 2) * 0.602;
};

const sqft = (area: number) => {
  return area / 1000000;
};

const totalSPDrop = (upPressureInwg: number, downPressureInwg: number) => {
  var totalSPDrop = upPressureInwg - downPressureInwg;
  return Math.abs(totalSPDrop);
};

const calculateBtuH = (cfm: number, h: number, d: number) => {
  //var enalphy = floorFigure(h * 2.326, 3);
  //var density = floorFigure(d * 16.018, 3);
  return cfm * 1.2 * h; // * (d / 1.2);
};

const calculateSensibleHeat = (cfm: number, dryBulbF: number) => {
  return cfm * dryBulbF * 1.23;
};

const temperature = (celsious: number) => {
  return (celsious * 9) / 5 + 32;
};

// const floorFigure = (figure: number, decimals: number) => {
//   if (!decimals) decimals = 2;
//   var d = Math.pow(10, decimals);
//   return ((figure * d) / d).toFixed(decimals);
// };

const getSheaveDimensionBetween = () => 6.35;

const getSheaveDimensionBetweenFirst = () => 31.75;

const getAirflowTemperature = () => 20;

const InHgToKPa = (InHg: number) => {
  return InHg * 3.3863886666667;
};

const getPneumaticPressureBottom = () => 124106.0;

const getPneumaticPressureTop = () => 137895.0;

const sqftToCuft = (sqft: number) => sqft * 3.048;

const cuftToSqft = (cuft: number) => cuft / 10;

var formulasMetricService: IFormulaSystemService = {
  rectangularArea: rectangularArea,
  circularArea: circularArea,
  averageVelocity: averageVelocity,
  vp: vp,
  sqft: sqft,
  totalSPDrop: totalSPDrop,
  calculateBtuH: calculateBtuH,
  calculateSensibleHeat: calculateSensibleHeat,
  temperature: temperature,
  getSheaveDimensionBetween: getSheaveDimensionBetween,
  getSheaveDimensionBetweenFirst: getSheaveDimensionBetweenFirst,
  getAirflowTemperature: getAirflowTemperature,
  InHgToKPa: InHgToKPa,
  getPneumaticPressureBottom: getPneumaticPressureBottom,
  getPneumaticPressureTop: getPneumaticPressureTop,
  sqftToCuft: sqftToCuft,
  cuftToSqft: cuftToSqft,
};

export default formulasMetricService;
