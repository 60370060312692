import Popup from "src/components/Popup";
import { FormButton, FormTextArea } from "src/components/formControls";
import { Grid, Stack, Typography } from "@mui/material";
import {
  IBuilding,
  IProjectContestOwner,
} from "src/ts/interfaces/projectOutToBid";

import moment from "moment";
import { ProjectDTO } from "src/ts/interfaces/project/projectDto";
import { convertUTCDateToLocalDate } from "src/utils/utils";

interface InformationProps {
  showDeclinedPopUp: boolean;
  setShowDeclinedPopUp: (val: boolean) => void;
  buildingInformation: IBuilding | undefined;
  projectSingle: ProjectDTO | undefined;
  projectContestSelected: IProjectContestOwner | undefined;
}

export default function DeclinedPopUp({
  showDeclinedPopUp,
  setShowDeclinedPopUp,
  buildingInformation,
  projectSingle,
  projectContestSelected,
}: InformationProps) {
  const handleClosePopUp = () => {
    setShowDeclinedPopUp(false);
  };

  return (
    <>
      <Popup
        title="Project Declined"
        openPopup={showDeclinedPopUp}
        setOpenPopup={setShowDeclinedPopUp}
        onClose={handleClosePopUp}
        size="sm"
      >
        <>
          <Grid container pb={2}>
            <Grid item xs={2} textAlign={"right"}>
              <Typography>
                <b>Project Name:</b>
              </Typography>
            </Grid>
            <Grid item xs={10} pl={2}>
              <Typography>{projectSingle?.name}</Typography>
            </Grid>
          </Grid>

          <Grid container pb={2}>
            <Grid item xs={2} textAlign={"right"}>
              <Typography>
                <b>Project Code:</b>
              </Typography>
            </Grid>
            <Grid item xs={10} pl={2}>
              <Typography>{projectSingle?.code}</Typography>
            </Grid>
          </Grid>

          <Grid container pb={2}>
            <Grid item xs={2} textAlign={"right"}>
              <Typography>
                <b>Building:</b>
              </Typography>
            </Grid>
            <Grid item xs={10} pl={2}>
              <Typography>
                <span style={{ fontWeight: "bold" }}>
                  {buildingInformation?.name}
                </span>{" "}
                {buildingInformation?.getFullAddress}
              </Typography>
            </Grid>
          </Grid>

          <Grid container pb={2}>
            <Grid item xs={2} textAlign={"right"}>
              <Typography>
                <b>Contractor:</b>
              </Typography>
            </Grid>
            <Grid item xs={10} pl={2}>
              <Typography>{projectContestSelected?.name}</Typography>
            </Grid>
          </Grid>

          <Grid container pb={2}>
            <Grid item xs={2} textAlign={"right"}>
              <Typography>
                <b>Declined Date:</b>
              </Typography>
            </Grid>
            <Grid item xs={10} pl={2}>
              <Typography>
                {convertUTCDateToLocalDate(
                  projectContestSelected?.declinedDate?.toString() || ""
                )?.format("MM/DD/YYYY hh:mm A")}
              </Typography>
            </Grid>
          </Grid>

          <Stack mt={5} direction="column">
            <Typography>
              <b>Reason: </b>
            </Typography>
            <FormTextArea
              name="declineComments"
              value={projectContestSelected?.declineComments ?? ""}
              placeholder="Reason"
              onChange={() => {}}
              label=""
            />
          </Stack>
        </>

        <Stack>
          <FormButton
            text="Close"
            variant="outlined"
            onClick={() => {
              setShowDeclinedPopUp(false);
            }}
          />
        </Stack>
      </Popup>
    </>
  );
}
