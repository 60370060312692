/* eslint-disable react-hooks/exhaustive-deps */
import { Alert, Button, Grid, Stack } from "@mui/material";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import {
  FormNumeric2,
  FormSelect,
  FormText,
  FormCheckBox,
  FormRadioGroup,
  FormButton,
  FormLabel,
} from "src/components/formControls";
import {
  useAsyncQuery,
  useAuth,
  useLog,
  useSystemOfMeasurement,
} from "src/hooks";
import {
  IFanMotorDTO,
  IFormulaSystemService,
  IMotorDTO,
} from "src/ts/interfaces";
import fanMotorService from "src/services/study/fanMotorService";
import motorService from "src/services/study/motorService";
import tooltipService from "src/services/tooltipService";
import { AddIcon, RemoveIcon } from "src/components/icons";
import Popup from "src/components/Popup";
import { ITooltip } from "src/ts/interfaces/tooltip";
import FormTooltip from "src/components/formControls/FormTooltip";
import {
  IBeltType,
  IDefaultHorsePower,
  IFanMotorManufacturer,
  IMotorFrameCategory,
  IMotorManufacturer,
  IMotorSheaveMfg,
} from "src/ts/interfaces/catalogs";
import { IDefaultComboBoxCatalog } from "src/ts/interfaces";
import defaultHorsePowerService from "src/services/study/defaultHorsePowerService";
import frameCategoryService from "src/services/study/frameCategoryService";
import motorPowerService from "src/services/study/motorPowerService";
import useFormTyped from "src/hooks/useFormTyped";
import { Validator } from "src/ts/types/Validator";
import { useFormulas } from "src/hooks";
import beltTensionerDown from "../headsheet/step3/assets/belt-tensioner-down.png";
import beltTensionerUp from "../headsheet/step3/assets/belt-tensioner-up.png";
import IncompleteDataDialog from "./IncompleteDataDialog";
import fanMotorManufacturerService from "src/services/study/fanMotorManufacturerService";
import motorSheaveMfgService from "src/services/study/motorSheaveMfgService";
import beltTypeService from "src/services/study/beltTypeService";
import motorManufacturerService from "src/services/catalogs/motorManufacturerService";
import headSheetService from "src/services/study/headSheetService";

type InferMotor<T> = T extends true ? IFanMotorDTO : IMotorDTO;

export const motorPhaseOptions = [
  { key: "Three phase", value: "Three phase" },
  { key: "2 wire single phase", value: "2 wire single phase" },
  { key: "Single phase", value: "Single phase" },
];

export const motorSheaveOptions = [
  { key: "Fixed", value: "Fixed" },
  { key: "Adjustable", value: "Adjustable" },
];

const initialValues: any = {};

const Motor = <IsFanMotor extends boolean>({
  id,
  triggerSave,
  triggerMoveNext,
  triggerMoveBack,
  setTriggerSave,
  setTriggerMoveNext,
  setTriggerMoveBack,
  setIsUpdating,
  moveNextHandler,
  moveBackHandler,
  isFanMotor,
  reportId,
  projectId,
  showSkeleton,
  isArray,
  index,
  isDisabled,
}: {
  id: number;
  triggerSave: boolean;
  triggerMoveNext: boolean;
  triggerMoveBack: boolean;
  setTriggerSave: Dispatch<SetStateAction<boolean>>;
  setTriggerMoveNext: Dispatch<SetStateAction<boolean>>;
  setTriggerMoveBack: Dispatch<SetStateAction<boolean>>;
  setIsUpdating: Dispatch<SetStateAction<boolean>>;
  moveNextHandler: () => void;
  moveBackHandler: () => void;
  isFanMotor: IsFanMotor;
  reportId: number;
  projectId: number;
  showSkeleton?: boolean;
  isArray?: boolean;
  index?: number;
  isDisabled?: boolean;
}) => {
  const { log } = useLog();
  const { user } = useAuth();
  const { systemOfMeasurement } = useSystemOfMeasurement(true);
  const { formulaSystem } = useFormulas();
  const [formulas, setFormulas] = useState<IFormulaSystemService>();

  const [openSvcFactorPopup, setOpenSvcFactorPopup] = useState(false);
  const [openBeltTensionerPopup, setBelTensionerPopup] = useState(false);
  const [motorManufacturers, setMotorManufacturers] =
    useState<IDefaultComboBoxCatalog[]>();
  const [defaultHPs, setDefaultHPs] = useState<IDefaultComboBoxCatalog[]>();
  const [betlTypes, setBeltTypes] = useState<IDefaultComboBoxCatalog[]>();
  const [defaultHpsExtended, setDefaultHpsExtended] =
    useState<IDefaultHorsePower[]>();
  const [frameCategories, setFrameCategories] =
    useState<IDefaultComboBoxCatalog[]>();
  const [motorSheaveManufacturers, setMotorSheaveManufacturers] =
    useState<IDefaultComboBoxCatalog[]>();
  const [showMotorSheaveAlert, setShowMotorSheaveAlert] = useState(false);
  const [sheaveDimensionBetween, setSheaveDimensionBetween] = useState(0);
  useState(0);
  const [sheaveDimensionBetweenFirst, setSheaveDimensionBetweenFirst] =
    useState(0);
  const [motorSheaveDimensionLow, setMotorSheaveDimensionLow] = useState(0);
  const [motorSheaveDimensionHigh, setMotorSheaveDimensionHigh] = useState(0);
  const [openIncompleteDataDialog, setOpenIncompleteDataDialog] =
    useState(false);
  const ecmTooltip = `<p>ECM (Electronically Commutated Motor) Replacement requires reprogramming of this specific motor. Motor swap can't be performed without reprogramming.</p><p>See Manufacturer Literature.</p><p>Limited Horsepower - [1/60 HP - 12.42 Watts] to [10.0 HP - 7,456.9 Watts] is the typical range.</p><p>[1.0 HP - 745.69 Watts] is by far the most common size.</p>`;

  const catalogValueOtherEval = (
    id: number,
    catalog: IDefaultComboBoxCatalog[]
  ) => {
    if (catalog && id) {
      const [{ value }] = catalog.filter((x) => x.key === id);
      return value === "Other";
    }
    return false;
  };

  const validate: any = (
    motorFieldValues: InferMotor<IsFanMotor> = motorValues
  ) => {
    let temp: Record<string, string> = { ...errors };

    if (isDisabled) return true;

    temp.fanMotorManufacturerId = new Validator(
      motorFieldValues,
      "fanMotorManufacturerId"
    )
      .isRequired("Motor Manufacturer is required.")
      .greatThan(0, "Motor Manufacturer is required.")
      .toString();
    temp.otherManufaturer = new Validator(motorFieldValues, "otherManufaturer")
      .validateIf(
        catalogValueOtherEval(
          (motorValues as IFanMotorDTO).fanMotorManufacturerId as number,
          motorManufacturers as IDefaultComboBoxCatalog[]
        )
      )
      .isRequired("Other Motor manufacturer is required.")
      .toString();
    temp.motorManufacturerId = new Validator(
      motorFieldValues,
      "motorManufacturerId"
    )
      .isRequired("Motor Manufacturer is required.")
      .greatThan(0, "Motor Manufacturer is required.")
      .toString();
    temp.otherMotorManufacturer = new Validator(
      motorFieldValues,
      "otherMotorManufacturer"
    )
      .validateIf(
        catalogValueOtherEval(
          (motorValues as IMotorDTO).motorManufacturerId as number,
          motorManufacturers as IDefaultComboBoxCatalog[]
        )
      )
      .isRequired("Other Motor manufacturer is required.")
      .toString();
    temp.nameplateHorsePowerId = new Validator(
      motorFieldValues,
      "nameplateHorsePowerId"
    )
      .isRequired("Nameplate HP is required.")
      .greatThan(0, "Nameplate HP is required.")
      .toString();
    temp.nameplateRpm = new Validator(motorFieldValues, "nameplateRpm")
      .isRequired("RPM is required.")
      .greatThan(0, "RPM must be great than 0")
      .toString();
    temp.rpm = new Validator(motorFieldValues, "rpm")
      .isRequired("RPM is required.")
      .greatThan(0, "RPM must be great than 0")
      .toString();
    temp.nameplateVoltsPhase1 = new Validator(
      motorFieldValues,
      "nameplateVoltsPhase1"
    )
      .isRequired("Nameplate volts phase 1 is required.")
      .greatThan(0, "Nameplate volts phase 1 must be great than 0")
      .toString();
    temp.nameplateAmpsPhase1 = new Validator(
      motorFieldValues,
      "nameplateAmpsPhase1"
    )
      .isRequired("Nameplate amps phase 1 is required.")
      .greatThan(0, "Nameplate amps phase 1 must be great than 0")
      .toString();
    temp.nameplateEfficiency = new Validator(
      motorFieldValues,
      "nameplateEfficiency"
    )
      .isRequired("Nameplate efficiency is required.")
      .lessThan(
        1,
        "It seems that you have entered improper data. Your resultant EFF cannot exceed 1.0"
      )
      .toString();
    temp.nameplatePowerFactor = new Validator(
      motorFieldValues,
      "nameplatePowerFactor"
    )
      .isRequired("Nameplate power factor is required.")
      .lessThan(
        1,
        "It seems that you have entered improper data. Your resultant PF cannot exceed 1.0"
      )
      .toString();

    temp.motorSheaveMfgId = new Validator(motorFieldValues, "motorSheaveMfgId")
      .isRequired("Motor sheave manufacturer is required.")
      .greatThan(0, "Motor sheave manufacturer is required.")
      .toString();
    temp.otherMotorSheaveMfg = new Validator(
      motorFieldValues,
      "otherMotorSheaveMfg"
    )
      .validateIf(
        catalogValueOtherEval(
          (motorValues as IFanMotorDTO).motorSheaveMfgId as number,
          motorSheaveManufacturers as IDefaultComboBoxCatalog[]
        )
      )
      .isRequired("Other Motor seahve manufacturer is required.")
      .toString();
    temp.halfOfTheMotorSheaveDimension = new Validator(
      motorFieldValues,
      "halfOfTheMotorSheaveDimension"
    )
      .isRequired(
        "Half of the motor sheave dimension from outer edge of pulley to the face of the motorshaft is required."
      )
      .greatThan(
        0,
        "Half of the motor sheave dimension from outer edge of pulley to the face of the motorshaft must be great than 0"
      )
      .toString();
    temp.otherMotorShaftDiameter = new Validator(
      motorFieldValues,
      "otherMotorShaftDiameter"
    )
      .isRequired("Motor Shaft Diameter is required.")
      .greatThan(0, "Motor Shaft Diameter must be great than 0")
      .toString();
    temp.beltTypeId = new Validator(motorFieldValues, "beltTypeId")
      .isRequired("Belt Type is required.")
      .greatThan(0, "Belt Type is required.")
      .toString();
    temp.otherBeltType = new Validator(motorFieldValues, "otherBeltType")
      .validateIf(
        catalogValueOtherEval(
          (motorValues as IFanMotorDTO).beltTypeId as number,
          betlTypes as IDefaultComboBoxCatalog[]
        )
      )
      .isRequired("Other Motor seahve manufacturer is required.")
      .toString();
    temp.beltSize = new Validator(motorFieldValues, "beltSize")
      .isRequired("Belt Size is required.")
      .greatThan(0, "Belt Size must be great than 0")
      .toString();
    temp.dimension = new Validator(motorFieldValues, "dimension")
      .isRequired(
        "Center of motor shaft to center of fan shaft distance is required."
      )
      .greatThan(
        0,
        "Center of motor shaft to center of fan shaft distance must be great than 0"
      )
      .toString();
    temp.baseFrameAdjIn = new Validator(motorFieldValues, "baseFrameAdjIn")
      .isRequired(
        `${
          (motorValues as IFanMotorDTO)?.betlTensioningIdler
            ? "Belt Tension Idler"
            : "Motor base/frame"
        } is required.`
      )
      .greatThan(
        0,
        `${
          (motorValues as IFanMotorDTO)?.betlTensioningIdler
            ? "Belt Tension Idler"
            : "Motor base/frame"
        } must be great than 0`
      )
      .toString();
    temp.baseFrameAdjOut = new Validator(motorFieldValues, "baseFrameAdjOut")
      .isRequired(
        `${
          (motorValues as IFanMotorDTO)?.betlTensioningIdler
            ? "Belt Tension Idler"
            : "Motor base/frame"
        } is required.`
      )
      .greatThan(
        0,
        `${
          (motorValues as IFanMotorDTO)?.betlTensioningIdler
            ? "Belt Tension Idler"
            : "Motor base/frame"
        } must be great than 0`
      )
      .toString();

    setErrors({
      ...temp,
    });

    if (motorFieldValues === motorValues) {
      return Object.values(temp).every((x) => x === "");
    }
  };

  const {
    values: motorValues,
    setValues: setMotorValues,
    errors,
    setErrors,
    handleInputChange,
  } = useFormTyped<InferMotor<IsFanMotor>>(initialValues, false, validate);

  const { execute: executeMotor, isLoading: isLoadingMotor } = useAsyncQuery<
    InferMotor<IsFanMotor>
  >(isFanMotor ? fanMotorService.getById : motorService.getByPumpId, {
    onSuccess: (dataResult) => {
      const getData = async () => {
        setMotorValues({
          ...dataResult,
          motorFrameCategoryId: dataResult.motorFrameCategoryId ?? 1,
        });
        window.scrollTo(0, 0);
      };
      getData();
    },
  });

  const { execute: executeSvcFactorTooltip, data: svcFactorTooltip } =
    useAsyncQuery<ITooltip>(tooltipService.getByCode);

  const {
    execute: executeFrameCategories,
    isLoading: isLoadingFrameCategories,
  } = useAsyncQuery<IMotorFrameCategory[]>(frameCategoryService.getAll, {
    onSuccess: (dataResult) => {
      const getData = async () => {
        const mfgs = (dataResult as IMotorFrameCategory[]).map((mfg) => ({
          key: mfg.id as number,
          value: mfg.name as any,
        }));
        setFrameCategories([...mfgs]);
      };
      getData();
    },
  });

  const { execute: executeDefHP, isLoading: isLoadingDefaultHPs } =
    useAsyncQuery<IDefaultHorsePower[]>(defaultHorsePowerService.getAll, {
      onSuccess: (dataResult) => {
        const getData = async () => {
          setDefaultHpsExtended(dataResult);
          const hps = (dataResult as IDefaultHorsePower[]).map((mfg) => ({
            key: mfg.id as number,
            value: mfg.metricName as any,
          }));
          setDefaultHPs([{ key: 0, value: "Select a Horse Power" }, ...hps]);
        };
        getData();
      },
    });

  const {
    execute: executeDefHPTraining,
    isLoading: isLoadingDefaultHPTraining,
  } = useAsyncQuery<IDefaultHorsePower[]>(defaultHorsePowerService.getAll, {
    onSuccess: (dataResult) => {
      const getData = async () => {
        const mfgs = (dataResult as IDefaultHorsePower[]).map((mfg) => ({
          key: mfg.id as number,
          value: mfg.metricName as any,
        }));
        setDefaultHPs([{ key: 0, value: "Select a Horse Power" }, ...mfgs]);
      };
      getData();
    },
  });

  const { execute: executeMotorMfg, isLoading: isLoadingMotorMfgs } =
    useAsyncQuery<IFanMotorManufacturer[] | IMotorManufacturer[]>(
      isFanMotor
        ? fanMotorManufacturerService.getManufacturers
        : motorManufacturerService.getManufacturers,
      {
        onSuccess: (dataResult) => {
          const getData = async () => {
            const mfgs = (
              dataResult as IFanMotorManufacturer[] | IMotorManufacturer[]
            ).map((mfg) => ({
              key: mfg.id as number,
              value: mfg.name as any,
            }));
            setMotorManufacturers([
              { key: 0, value: "Select a motor manufacturer" },
              ...mfgs,
            ]);
          };
          getData();
        },
      }
    );

  const { execute: executePF, isLoading: isLoadingPF } = useAsyncQuery<number>(
    motorPowerService.getPowerFactor,
    {
      onSuccess: (result) => {
        const getData = async () => {
          const powerFactorResult =
            result === 0 ? null : (result as number).toFixed(3);
          setMotorValues({
            ...motorValues,
            nameplatePowerFactor: powerFactorResult,
          });
        };
        getData();
      },
    }
  );

  const { execute: executeEff, isLoading: isLoadingEff } =
    useAsyncQuery<number>(motorPowerService.getEffByPowerFactor, {
      onSuccess: (result) => {
        const getData = async () => {
          const effResult = result === 0 ? null : (result as number).toFixed(3);
          setMotorValues({
            ...motorValues,
            nameplateEfficiency: effResult,
          });
        };
        getData();
      },
    });

  const {
    execute: executeMotorSheaveMfg,
    isLoading: isLoadingMotorSheaveMfgs,
  } = useAsyncQuery<IMotorSheaveMfg[]>(
    motorSheaveMfgService.getMotorSheaveManufacturers,
    {
      onSuccess: (dataResult) => {
        const getData = async () => {
          const mfgs = (dataResult as IMotorSheaveMfg[]).map((mfg) => ({
            key: mfg.id as number,
            value: mfg.name as any,
          }));
          setMotorSheaveManufacturers([
            { key: 0, value: "Select a fan sheave manufacturer" },
            ...mfgs,
          ]);
        };
        getData();
      },
    }
  );

  const { execute: executeBeltType, isLoading: isLoadingBeltType } =
    useAsyncQuery<IBeltType[]>(beltTypeService.getBeltTypes, {
      onSuccess: (dataResult) => {
        const getData = async () => {
          const types = (dataResult as IBeltType[]).map((mfg) => ({
            key: mfg.id as number,
            value: mfg.name as any,
          }));
          setBeltTypes([{ key: 0, value: "Select a belt type" }, ...types]);
        };
        getData();
      },
    });

  const loadMiscellaneousHandler = async () => {
    await executeMotorMfg();
    await executeMotorSheaveMfg();
    await executeSvcFactorTooltip("Service Factor Warning");
    await executeFrameCategories();
    await executeBeltType();
    await systemOfMeasurement.getByProject(projectId);
    if (user?.role !== "Training") {
      await executeDefHP();
    }
  };

  const handleCloseSvcFactorPopup = () => {
    setOpenSvcFactorPopup(false);
  };

  const handleOpenSvcFactorTooltip = () => {
    setOpenSvcFactorPopup(true);
  };

  const handleCloseBeltTensionerPopup = () => {
    setBelTensionerPopup(false);
  };

  const handleOpenBeltTensionerTooltip = () => {
    setBelTensionerPopup(true);
  };

  const saveMotorHandler = async () => {
    if (!validate() && triggerMoveNext) setOpenIncompleteDataDialog(true);
    else movingForwardHandler();
  };

  const incompleteDataDialogNoButtonClickHandler = () =>
    setTriggerMoveNext(false);

  const movingForwardHandler = async () => {
    setIsUpdating(true);
    if (!isDisabled) {
      if (isFanMotor) {
        await headSheetService.updateFanMotor(
          reportId,
          motorValues as IFanMotorDTO,
          isArray as boolean,
          index as number
        );
      } else {
        await motorService.update(reportId, motorValues as IMotorDTO);
      }
      log.success("Motor was updated successfully");
    }
    if (triggerMoveNext) {
      moveNextHandler();
    }
    if (triggerMoveBack) {
      moveBackHandler();
    }
    setTriggerSave(false);
    setTriggerMoveNext(false);
    setTriggerMoveBack(false);
    setIsUpdating(false);
  };

  const updateAdditionalVoltAmpsHandle = (upDown: "up" | "down") => {
    let currentValue = motorValues?.additionalVolAmpValue as number;
    if (upDown === "down") {
      currentValue -= 1;
      setMotorValues({
        ...motorValues,
        additionalVolAmpValue: currentValue,
      });
    } else {
      currentValue += 1;
      setMotorValues({
        ...motorValues,
        additionalVolAmpValue: currentValue,
      });
    }
  };

  useEffect(() => {
    if (id) {
      executeMotor(id);
      loadMiscellaneousHandler();
    }
  }, [id]);

  const setFormulasHandler = async () => {
    const formulas = await formulaSystem.setSystem(projectId);
    setFormulas(formulas);
  };

  useEffect(() => {
    if (projectId) setFormulasHandler();
  }, [projectId]);

  useEffect(() => {
    if (triggerSave || triggerMoveBack || triggerMoveNext) saveMotorHandler();
  }, [triggerSave, triggerMoveNext, triggerMoveBack]);

  useEffect(() => {
    if (motorValues?.horsepowerUnknown) {
      setMotorValues({
        ...motorValues,
        efficiencyUnknown: false,
        powerFactorUnknown: false,
        nameplateHorsePowerId: null,
      });
    }
  }, [motorValues?.horsepowerUnknown]);

  useEffect(() => {
    if (motorValues?.efficiencyUnknown) {
      setMotorValues({
        ...motorValues,
        horsepowerUnknown: false,
      });
    }
  }, [motorValues?.efficiencyUnknown]);

  useEffect(() => {
    if (motorValues?.serviceFactorNotListed) {
      setMotorValues({
        ...motorValues,
        serviceFactor: 1,
      });
    }
  }, [motorValues?.serviceFactorNotListed]);

  useEffect(() => {
    if (motorValues) {
      if (user?.role === "Training") {
        const manufacturerId = isFanMotor
          ? (motorValues as IFanMotorDTO).fanMotorManufacturerId
          : (motorValues as IMotorDTO).motorManufacturerId;
        const motorType = isFanMotor ? "fanMotor" : "motor";
        executeDefHPTraining(manufacturerId, motorType);
      }
    }
  }, [
    (motorValues as IFanMotorDTO)?.fanMotorManufacturerId,
    (motorValues as IMotorDTO)?.motorManufacturerId,
  ]);

  useEffect(() => {
    if (motorValues?.nameplateHorsePowerId && defaultHpsExtended) {
      if (
        motorValues?.efficiencyUnknown &&
        motorValues?.nameplateHorsePowerId > 0
      ) {
        const [hp] = (defaultHpsExtended as IDefaultHorsePower[]).filter(
          (x) => x.id === motorValues?.nameplateHorsePowerId
        );
        setMotorValues({
          ...motorValues,
          nameplateEfficiency: hp.efficiency,
        });
      }
    }
  }, [motorValues?.nameplateHorsePowerId, defaultHpsExtended]);

  useEffect(() => {
    if (motorValues?.nameplateEfficiency !== null) {
      validate();
    }
  }, [motorValues?.nameplateEfficiency]);

  const handleEffBlur = (e: any) => {
    if (!isLoadingPF && !isLoadingEff) {
      getPowerFactor();
    }
  };

  useEffect(() => {
    if (motorValues?.nameplatePowerFactor !== null) {
      validate();
    }
  }, [motorValues?.nameplatePowerFactor]);

  const handlePFBlur = (e: any) => {
    if (!isLoadingPF && !isLoadingEff) {
      getEffByPowerFactor();
    }
  };

  useEffect(() => {
    if (formulas) {
      setSheaveDimensionBetween(formulas?.getSheaveDimensionBetween());
      setSheaveDimensionBetweenFirst(
        formulas?.getSheaveDimensionBetweenFirst()
      );
    }
  }, [formulas]);

  const handleVoltsAmpsBlur = (e: any) => {
    if (!isLoadingEff && !isLoadingPF) {
      getPowerFactor();
    }
  };

  useEffect(() => {
    if (motorValues) {
      const motorValuesTemp = motorValues as IFanMotorDTO;
      const pitchDiameter =
        typeof motorValuesTemp?.motorSheaveDimension === "string"
          ? parseFloat(motorValuesTemp?.motorSheaveDimension)
          : (motorValuesTemp?.motorSheaveDimension as number);
      const sheaveDimension =
        typeof motorValuesTemp?.motorSheaveStampingDimension === "string"
          ? parseFloat(motorValuesTemp?.motorSheaveStampingDimension)
          : (motorValuesTemp?.motorSheaveStampingDimension as number);
      setMotorSheaveDimensionLow(sheaveDimension - sheaveDimensionBetweenFirst);
      setMotorSheaveDimensionHigh(sheaveDimension + sheaveDimensionBetween);

      const halfFanSheaveDimension =
        typeof motorValuesTemp?.halfOfTheMotorSheaveDimension === "string"
          ? parseFloat(motorValuesTemp?.halfOfTheMotorSheaveDimension)
          : (motorValuesTemp?.halfOfTheMotorSheaveDimension as number);
      const fanShaftDiameter =
        typeof motorValuesTemp?.otherMotorShaftDiameter === "string"
          ? parseFloat(motorValuesTemp?.otherMotorShaftDiameter)
          : (motorValuesTemp?.otherMotorShaftDiameter as number);
      const result = halfFanSheaveDimension * 2 + fanShaftDiameter * 1;
      setMotorValues({
        ...motorValues,
        motorSheaveDimension: result,
      });

      if (
        (pitchDiameter > sheaveDimension + sheaveDimensionBetween ||
          pitchDiameter < sheaveDimension - sheaveDimensionBetweenFirst) &&
        motorValuesTemp?.otherMotorShaftDiameter != null &&
        motorValuesTemp?.halfOfTheMotorSheaveDimension != null
      ) {
        setShowMotorSheaveAlert(true);
      } else {
        setShowMotorSheaveAlert(false);
      }
    }
  }, [
    (motorValues as IFanMotorDTO)?.motorSheaveStampingDimension,
    (motorValues as IFanMotorDTO)?.motorSheaveDimension,
    (motorValues as IFanMotorDTO)?.otherMotorShaftDiameter,
    (motorValues as IFanMotorDTO)?.halfOfTheMotorSheaveDimension,
    sheaveDimensionBetween,
    sheaveDimensionBetweenFirst,
  ]);

  const validateNameplateVolts = () =>
    motorValues?.nameplateVoltsPhase1 != null ||
    motorValues?.nameplateVoltsPhase2 != null ||
    motorValues?.nameplateVoltsPhase3 != null;

  const validateNameplateAmps = () =>
    motorValues?.nameplateAmpsPhase1 != null ||
    motorValues?.nameplateAmpsPhase2 != null ||
    motorValues?.nameplateAmpsPhase3 != null;

  const getPowerFactor = () => {
    setMotorValues({ ...motorValues, nameplatePowerFactor: null });
    if (motorValues?.nameplateHorsePowerId == null) {
      return false;
    }
    if (!validateNameplateVolts()) {
      return false;
    }
    if (!validateNameplateAmps()) {
      return false;
    }
    if (!motorValues?.nameplateEfficiency) {
      return false;
    }
    executePF(motorValues);
  };

  const getEffByPowerFactor = () => {
    setMotorValues({ ...motorValues, nameplateEfficiency: null });
    if (motorValues?.nameplateHorsePowerId == null) {
      return false;
    }
    if (!validateNameplateVolts()) {
      return false;
    }
    if (!validateNameplateAmps()) {
      return false;
    }
    if (!motorValues?.nameplatePowerFactor) {
      return false;
    }
    executeEff(motorValues);
  };

  const handleEffChange = (e: any) => {
    setMotorValues({
      ...motorValues,
      nameplateEfficiency: e.target.value,
    });
  };

  const handlePFChange = (e: any) => {
    setMotorValues({
      ...motorValues,
      nameplatePowerFactor: e.target.value,
    });
  };

  return (
    <>
      <Popup
        openPopup={openSvcFactorPopup}
        setOpenPopup={setOpenSvcFactorPopup}
        title="Important"
        onClose={handleCloseSvcFactorPopup}
        size="lg"
      >
        <div dangerouslySetInnerHTML={{ __html: svcFactorTooltip?.content }} />
        <Stack direction="row" spacing={3}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleCloseSvcFactorPopup}
          >
            Close
          </Button>
        </Stack>
      </Popup>
      <Popup
        openPopup={openBeltTensionerPopup}
        setOpenPopup={setBelTensionerPopup}
        title="Belt Tensioning Idler Pulley"
        onClose={handleCloseBeltTensionerPopup}
        size="lg"
      >
        <>
          <img
            src={beltTensionerUp}
            style={{ width: 600 }}
            alt="Belt Tensioning Idler Pulley Up"
          />
          <img
            src={beltTensionerDown}
            style={{ width: 600 }}
            alt="Belt Tensioning Idler Pulley Down"
          />
        </>
        <Stack direction="row" spacing={3}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleCloseBeltTensionerPopup}
          >
            Close
          </Button>
        </Stack>
      </Popup>
      <IncompleteDataDialog
        setOpenIncompleteDataDialog={setOpenIncompleteDataDialog}
        openIncompleteDataDialog={openIncompleteDataDialog}
        yesButtonClickHandler={movingForwardHandler}
        noButtonClickHandler={incompleteDataDialogNoButtonClickHandler}
      />
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <h2>Motor Information</h2>
        </Grid>
        {motorValues && (
          <>
            <Grid item xs={12}>
              <FormSelect
                name={
                  isFanMotor ? "fanMotorManufacturerId" : "motorManufacturerId"
                }
                label="Nameplate motor manufacturer"
                onChange={handleInputChange}
                options={motorManufacturers}
                value={
                  (isFanMotor
                    ? (motorValues as IFanMotorDTO).fanMotorManufacturerId
                    : (motorValues as IMotorDTO).motorManufacturerId) || 0
                }
                showSkeleton={
                  isLoadingMotor || isLoadingMotorMfgs || showSkeleton
                }
                error={
                  errors.fanMotorManufacturerId || errors.motorManufacturerId
                }
                disabled={isDisabled}
              />
            </Grid>
            {catalogValueOtherEval(
              (isFanMotor
                ? (motorValues as IFanMotorDTO).fanMotorManufacturerId
                : (motorValues as IMotorDTO).motorManufacturerId) || 0,
              motorManufacturers as IDefaultComboBoxCatalog[]
            ) && (
              <Grid item xs={12}>
                <FormText
                  name={
                    isFanMotor ? "otherManufaturer" : "otherMotorManufacturer"
                  }
                  label="Other motor manufacturer"
                  onChange={handleInputChange}
                  placeholder="Type the motor manufacturer here"
                  value={
                    (isFanMotor
                      ? (motorValues as IFanMotorDTO).otherManufaturer
                      : (motorValues as IMotorDTO).otherMotorManufacturer) || ""
                  }
                  showSkeleton={isLoadingMotor || showSkeleton}
                  error={
                    errors.otherManufaturer || errors.otherMotorManufacturer
                  }
                  disabled={isDisabled}
                />
              </Grid>
            )}
          </>
        )}
        {isFanMotor && motorValues && (
          <Grid item xs={12} md={4}>
            <FormText
              name="description"
              label="Description"
              onChange={handleInputChange}
              placeholder="Place a description here"
              value={(motorValues as IFanMotorDTO).description ?? ""}
              showSkeleton={isLoadingMotor || showSkeleton}
              disabled={isDisabled}
            />
          </Grid>
        )}
        <Grid item xs={12} md={4}>
          <FormText
            name="model"
            label="Model"
            onChange={handleInputChange}
            placeholder="Type the nameplate model here"
            value={motorValues?.model ?? ""}
            showSkeleton={isLoadingMotor || showSkeleton}
            disabled={isDisabled}
          />
        </Grid>
        {isFanMotor && motorValues && (
          <Grid item xs={12} md={4}>
            <FormText
              name="serialNumber"
              label="Serial number"
              onChange={handleInputChange}
              placeholder="Type the nameplate serial number here"
              value={(motorValues as IFanMotorDTO).serialNumber ?? ""}
              showSkeleton={isLoadingMotor || showSkeleton}
              disabled={isDisabled}
            />
          </Grid>
        )}
        <Grid item xs={12} md={4}>
          <FormSelect
            disabled={motorValues?.horsepowerUnknown || isDisabled}
            name="nameplateHorsePowerId"
            label="Nameplate HP"
            options={defaultHPs}
            value={motorValues?.nameplateHorsePowerId ?? 0}
            onChange={handleInputChange}
            showSkeleton={
              isLoadingMotor ||
              isLoadingDefaultHPs ||
              showSkeleton ||
              isLoadingDefaultHPTraining
            }
            error={errors.nameplateHorsePowerId}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <FormCheckBox
            name="horsepowerUnknown"
            label="Nameplate HP unknown"
            onChange={handleInputChange}
            value={motorValues?.horsepowerUnknown}
            showSkeleton={isLoadingMotor || showSkeleton}
            disabled={isDisabled}
          />
        </Grid>
        {motorValues && (
          <Grid item xs={12} md={4}>
            <FormNumeric2
              name={isFanMotor ? "nameplateRpm" : "rpm"}
              value={
                isFanMotor
                  ? (motorValues as IFanMotorDTO).nameplateRpm ?? 0
                  : (motorValues as IMotorDTO).rpm ?? 0
              }
              label="Nameplate RPM"
              onChange={handleInputChange}
              fixedDecimalScale
              decimalScale={2}
              thousandSeparator
              showSkeleton={isLoadingMotor || showSkeleton}
              error={errors.nameplateRpm || errors.rpm}
              disabled={isDisabled}
            />
          </Grid>
        )}
        <Grid item xs={12} md={4}>
          <FormSelect
            name="motorFrameCategoryId"
            label="Nameplate frame category"
            onChange={handleInputChange}
            options={frameCategories}
            value={motorValues?.motorFrameCategoryId}
            showSkeleton={
              isLoadingMotor || showSkeleton || isLoadingFrameCategories
            }
            disabled={isDisabled}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <FormText
            name="otherNameplateFrame"
            label={`Nameplate frame ${
              catalogValueOtherEval(
                motorValues?.motorFrameCategoryId || 0,
                frameCategories as IDefaultComboBoxCatalog[]
              )
                ? "(Other)"
                : ""
            }`}
            onChange={handleInputChange}
            placeholder="Type the nameplate serial number here"
            value={motorValues?.otherNameplateFrame ?? ""}
            showSkeleton={isLoadingMotor || showSkeleton}
            disabled={isDisabled}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Grid container spacing={0}>
            <Grid item xs={7}>
              <FormNumeric2
                disabled={motorValues?.serviceFactorNotListed || isDisabled}
                name="serviceFactor"
                value={motorValues?.serviceFactor ?? 1}
                label="Nameplate service factor"
                onChange={handleInputChange}
                fixedDecimalScale
                decimalScale={2}
                thousandSeparator
                maxValue={2}
                showSkeleton={isLoadingMotor || showSkeleton}
              />
            </Grid>
            <Grid item xs={3} marginLeft={5}>
              <FormCheckBox
                name="serviceFactorNotListed"
                label="Not listed"
                onChange={handleInputChange}
                value={motorValues?.serviceFactorNotListed || false}
                showSkeleton={isLoadingMotor || showSkeleton}
                disabled={isDisabled}
              />
            </Grid>
            <Grid item xs={1}>
              <FormTooltip
                text="Click to open information"
                onClick={handleOpenSvcFactorTooltip}
              />
            </Grid>
          </Grid>
        </Grid>
        {motorValues && isFanMotor && (
          <Grid item xs={12}>
            <FormCheckBox
              name="isMotorInAirStream"
              label="Motor is in the airstream"
              onChange={handleInputChange}
              value={(motorValues as IFanMotorDTO).isMotorInAirStream || false}
              showSkeleton={isLoadingMotor || showSkeleton}
              size="medium"
              disabled={isDisabled}
            />
            {!(motorValues as IFanMotorDTO)?.isMotorInAirStream && (
              <Alert severity="warning">
                Please ensure that the motor is not in the airstream; if it is,
                please check the "Motor is in the airstream" option.
              </Alert>
            )}
          </Grid>
        )}
        <Grid item xs={12}>
          <FormRadioGroup
            label="Motor phase"
            name="motorPhase"
            onChange={handleInputChange}
            items={motorPhaseOptions}
            row
            value={motorValues?.motorPhase}
            showSkeleton={isLoadingMotor || showSkeleton}
            disabled={isDisabled}
          />
        </Grid>
        <Grid item xs={12}>
          <FormCheckBox
            name="ecmMotor"
            label="ECM (Motor)"
            onChange={handleInputChange}
            value={motorValues?.ecmMotor || false}
            showSkeleton={isLoadingMotor || showSkeleton}
            size="medium"
            disabled={isDisabled}
          />
          <FormTooltip text={ecmTooltip} />
        </Grid>
        <Grid item xs={12}>
          <FormCheckBox
            name="systemMotorOperatesFromAVfDPwmDevice"
            label="System motor operates from a VFD/PWM device"
            onChange={handleInputChange}
            value={motorValues?.systemMotorOperatesFromAVfDPwmDevice || false}
            showSkeleton={isLoadingMotor || showSkeleton}
            size="medium"
            disabled={isDisabled}
          />
        </Grid>
        {motorValues?.systemMotorOperatesFromAVfDPwmDevice && isFanMotor && (
          <>
            <Grid item xs={12}>
              <FormCheckBox
                name="vortexDamperPresent"
                label="Fan inlet vortex damper present"
                onChange={handleInputChange}
                value={
                  (motorValues as IFanMotorDTO).vortexDamperPresent || false
                }
                showSkeleton={isLoadingMotor || showSkeleton}
                size="medium"
                disabled={isDisabled}
              />
              {(motorValues as IFanMotorDTO).vortexDamperPresent && (
                <Alert severity="warning" style={{ marginTop: 5 }}>
                  Recommend removing the fan inlet vortex damper and operate the
                  Static Pressure (SP) and airflow delivery by the VFD only.
                </Alert>
              )}
            </Grid>
            <Grid item xs={12}>
              <FormCheckBox
                name="systemOperatesVAVs"
                label="System operates (either/and) VAV's, FPB's, CAV's"
                onChange={handleInputChange}
                value={
                  (motorValues as IFanMotorDTO).systemOperatesVAVs || false
                }
                showSkeleton={isLoadingMotor || showSkeleton}
                size="medium"
                disabled={isDisabled}
              />
            </Grid>
          </>
        )}
        <Grid item xs={12}>
          <FormCheckBox
            name="motorHaveEmdBearingProtectionInstalled"
            label="Motor has Electric Discharge Machining (EDM) Bearing protection installed"
            onChange={handleInputChange}
            value={motorValues?.motorHaveEmdBearingProtectionInstalled || false}
            showSkeleton={isLoadingMotor || showSkeleton}
            size="medium"
            disabled={isDisabled}
          />
          <FormTooltip titleName="EDM Bearing Protection" />
          {!motorValues?.motorHaveEmdBearingProtectionInstalled && (
            <Alert severity="warning">
              Motor Electric Discharge Machining (EDM) Bearing Protection not
              installed, EDM Protection required, or Bearing Raceway will be
              machined out making the Bearing Grease conductive and Metal
              particles will be present.
            </Alert>
          )}
        </Grid>
        <Grid item xs={12}>
          <h2>Nameplate rated Volts & Amps</h2>
          <Alert severity="warning">
            Collect only 60 Hz nameplate rated voltage and amps
          </Alert>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={3}>
            <Grid
              item
              xs={12}
              md={(motorValues?.additionalVolAmpValue as number) >= 1 ? 2 : 4}
            >
              <FormNumeric2
                name="nameplateVoltsPhase1"
                value={motorValues?.nameplateVoltsPhase1}
                label="Nameplate rated V"
                onChange={handleInputChange}
                fixedDecimalScale
                decimalScale={2}
                thousandSeparator
                showSkeleton={isLoadingMotor || showSkeleton}
                placeholder="000.0 V"
                error={errors.nameplateVoltsPhase1}
                onBlur={handleVoltsAmpsBlur}
                disabled={isDisabled}
              />
            </Grid>
            {(motorValues?.motorPhase === "Three phase" ||
              (motorValues?.motorPhase === "2 wire single phase" &&
                motorValues?.additionalVolAmpValue >= 1)) && (
              <Grid
                item
                xs={12}
                md={(motorValues?.additionalVolAmpValue as number) >= 1 ? 2 : 4}
              >
                <FormNumeric2
                  name="nameplateVoltsPhase2"
                  value={motorValues?.nameplateVoltsPhase2}
                  label="Nameplate rated V"
                  onChange={handleInputChange}
                  fixedDecimalScale
                  decimalScale={2}
                  thousandSeparator
                  showSkeleton={isLoadingMotor || showSkeleton}
                  placeholder="000.0 V"
                  onBlur={handleVoltsAmpsBlur}
                  disabled={isDisabled}
                />
              </Grid>
            )}
            {(motorValues?.motorPhase === "Three phase" ||
              (motorValues?.motorPhase === "2 wire single phase" &&
                motorValues?.additionalVolAmpValue === 2)) && (
              <Grid
                item
                xs={12}
                md={(motorValues?.additionalVolAmpValue as number) >= 1 ? 2 : 4}
              >
                <FormNumeric2
                  name="nameplateVoltsPhase3"
                  value={motorValues?.nameplateVoltsPhase3}
                  label="Nameplate rated V"
                  onChange={handleInputChange}
                  fixedDecimalScale
                  decimalScale={2}
                  thousandSeparator
                  showSkeleton={isLoadingMotor || showSkeleton}
                  placeholder="000.0 V"
                  onBlur={handleVoltsAmpsBlur}
                  disabled={isDisabled}
                />
              </Grid>
            )}
            {motorValues?.motorPhase === "Three phase" &&
              motorValues?.additionalVolAmpValue >= 1 && (
                <Grid
                  item
                  xs={12}
                  md={
                    (motorValues?.additionalVolAmpValue as number) >= 1 ? 2 : 4
                  }
                >
                  <FormNumeric2
                    name="nameplateVoltsPhase4"
                    value={motorValues?.nameplateVoltsPhase4}
                    label="Nameplate rated V"
                    onChange={handleInputChange}
                    fixedDecimalScale
                    decimalScale={2}
                    thousandSeparator
                    showSkeleton={isLoadingMotor || showSkeleton}
                    placeholder="000.0 V"
                    disabled={isDisabled}
                  />
                </Grid>
              )}
            {motorValues?.motorPhase === "Three phase" &&
              motorValues?.additionalVolAmpValue >= 2 && (
                <Grid
                  item
                  xs={12}
                  md={
                    (motorValues?.additionalVolAmpValue as number) >= 1 ? 2 : 4
                  }
                >
                  <FormNumeric2
                    name="nameplateVoltsPhase5"
                    value={motorValues?.nameplateVoltsPhase5}
                    label="Nameplate rated V"
                    onChange={handleInputChange}
                    fixedDecimalScale
                    decimalScale={2}
                    thousandSeparator
                    showSkeleton={isLoadingMotor || showSkeleton}
                    placeholder="000.0 V"
                    disabled={isDisabled}
                  />
                </Grid>
              )}
            {motorValues?.motorPhase === "Three phase" &&
              motorValues?.additionalVolAmpValue === 3 && (
                <Grid
                  item
                  xs={12}
                  md={
                    (motorValues?.additionalVolAmpValue as number) >= 1 ? 2 : 4
                  }
                >
                  <FormNumeric2
                    name="nameplateVoltsPhase6"
                    value={motorValues?.nameplateVoltsPhase6}
                    label="Nameplate rated V"
                    onChange={handleInputChange}
                    fixedDecimalScale
                    decimalScale={2}
                    thousandSeparator
                    showSkeleton={isLoadingMotor || showSkeleton}
                    placeholder="000.0 V"
                    disabled={isDisabled}
                  />
                </Grid>
              )}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={3}>
            <Grid
              item
              xs={12}
              md={(motorValues?.additionalVolAmpValue as number) >= 1 ? 2 : 4}
            >
              <FormNumeric2
                name="nameplateAmpsPhase1"
                value={motorValues?.nameplateAmpsPhase1}
                label="Nameplate rated A"
                onChange={handleInputChange}
                fixedDecimalScale
                decimalScale={2}
                thousandSeparator
                showSkeleton={isLoadingMotor || showSkeleton}
                placeholder="000.0 A"
                error={errors.nameplateAmpsPhase1}
                onBlur={handleVoltsAmpsBlur}
                disabled={isDisabled}
              />
            </Grid>
            {(motorValues?.motorPhase === "Three phase" ||
              (motorValues?.motorPhase === "2 wire single phase" &&
                motorValues?.additionalVolAmpValue >= 1)) && (
              <Grid
                item
                xs={12}
                md={(motorValues?.additionalVolAmpValue as number) >= 1 ? 2 : 4}
              >
                <FormNumeric2
                  name="nameplateAmpsPhase2"
                  value={motorValues?.nameplateAmpsPhase2}
                  label="Nameplate rated A"
                  onChange={handleInputChange}
                  fixedDecimalScale
                  decimalScale={2}
                  thousandSeparator
                  showSkeleton={isLoadingMotor || showSkeleton}
                  placeholder="000.0 A"
                  onBlur={handleVoltsAmpsBlur}
                  disabled={isDisabled}
                />
              </Grid>
            )}
            {(motorValues?.motorPhase === "Three phase" ||
              (motorValues?.motorPhase === "2 wire single phase" &&
                motorValues?.additionalVolAmpValue === 2)) && (
              <Grid
                item
                xs={12}
                md={(motorValues?.additionalVolAmpValue as number) >= 1 ? 2 : 4}
              >
                <FormNumeric2
                  name="nameplateAmpsPhase3"
                  value={motorValues?.nameplateAmpsPhase3}
                  label="Nameplate rated A"
                  onChange={handleInputChange}
                  fixedDecimalScale
                  decimalScale={2}
                  thousandSeparator
                  showSkeleton={isLoadingMotor || showSkeleton}
                  placeholder="000.0 A"
                  onBlur={handleVoltsAmpsBlur}
                  disabled={isDisabled}
                />
              </Grid>
            )}
            {motorValues?.motorPhase === "Three phase" &&
              motorValues?.additionalVolAmpValue >= 1 && (
                <Grid
                  item
                  xs={12}
                  md={
                    (motorValues?.additionalVolAmpValue as number) >= 1 ? 2 : 4
                  }
                >
                  <FormNumeric2
                    name="nameplateAmpsPhase4"
                    value={motorValues?.nameplateAmpsPhase4}
                    label="Nameplate rated A"
                    onChange={handleInputChange}
                    fixedDecimalScale
                    decimalScale={2}
                    thousandSeparator
                    showSkeleton={isLoadingMotor || showSkeleton}
                    placeholder="000.0 A"
                    disabled={isDisabled}
                  />
                </Grid>
              )}
            {motorValues?.motorPhase === "Three phase" &&
              motorValues?.additionalVolAmpValue >= 2 && (
                <Grid
                  item
                  xs={12}
                  md={
                    (motorValues?.additionalVolAmpValue as number) >= 1 ? 2 : 4
                  }
                >
                  <FormNumeric2
                    name="nameplateAmpsPhase5"
                    value={motorValues?.nameplateAmpsPhase5}
                    label="Nameplate rated A"
                    onChange={handleInputChange}
                    fixedDecimalScale
                    decimalScale={2}
                    thousandSeparator
                    showSkeleton={isLoadingMotor || showSkeleton}
                    placeholder="000.0 A"
                    disabled={isDisabled}
                  />
                </Grid>
              )}
            {motorValues?.motorPhase === "Three phase" &&
              motorValues?.additionalVolAmpValue === 3 && (
                <Grid
                  item
                  xs={12}
                  md={
                    (motorValues?.additionalVolAmpValue as number) >= 1 ? 2 : 4
                  }
                >
                  <FormNumeric2
                    name="nameplateAmpsPhase6"
                    value={motorValues?.nameplateAmpsPhase6}
                    label="Nameplate rated A"
                    onChange={handleInputChange}
                    fixedDecimalScale
                    decimalScale={2}
                    thousandSeparator
                    showSkeleton={isLoadingMotor || showSkeleton}
                    placeholder="000.0 A"
                    disabled={isDisabled}
                  />
                </Grid>
              )}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          {(((motorValues?.additionalVolAmpValue as number) < 3 &&
            motorValues?.motorPhase === "Three phase") ||
            ((motorValues?.additionalVolAmpValue as number) < 2 &&
              motorValues?.motorPhase === "2 wire single phase")) && (
            <FormButton
              text="Add"
              startIcon={<AddIcon />}
              variant="outlined"
              color="secondary"
              size="small"
              onClick={() => updateAdditionalVoltAmpsHandle("up")}
              disabled={isDisabled}
            />
          )}
          {(motorValues?.additionalVolAmpValue as number) > 0 && (
            <FormButton
              text="Remove"
              startIcon={<RemoveIcon />}
              variant="outlined"
              color="secondary"
              size="small"
              onClick={() => updateAdditionalVoltAmpsHandle("down")}
              disabled={isDisabled}
            />
          )}
        </Grid>
        <Grid item xs={12}>
          <FormCheckBox
            name="efficiencyUnknown"
            label="Power Factor and Efficiency are unknown"
            onChange={handleInputChange}
            value={motorValues?.efficiencyUnknown || false}
            showSkeleton={isLoadingMotor || showSkeleton}
            size="medium"
            disabled={isDisabled}
          />
        </Grid>
        <Grid item xs={4}>
          <Grid container spacing={0}>
            <Grid item xs={11}>
              <FormNumeric2
                disabled={motorValues?.efficiencyUnknown || isDisabled}
                name="nameplateEfficiency"
                value={motorValues?.nameplateEfficiency}
                label="Nameplate efficiency (Eff)"
                onChange={handleEffChange}
                fixedDecimalScale
                decimalScale={3}
                thousandSeparator
                maxValue={1}
                showSkeleton={isLoadingMotor || showSkeleton}
                placeholder="0.000"
                error={errors.nameplateEfficiency}
                onBlur={handleEffBlur}
              />
            </Grid>
            <Grid item xs={1}>
              <FormTooltip tooltipId={1} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4}>
          <Grid container spacing={0}>
            <Grid item xs={11}>
              <FormNumeric2
                disabled={motorValues?.efficiencyUnknown || isDisabled}
                name="nameplatePowerFactor"
                value={motorValues?.nameplatePowerFactor}
                label="Nameplate power factor (PF)"
                onChange={handlePFChange}
                fixedDecimalScale
                decimalScale={3}
                thousandSeparator
                maxValue={1}
                showSkeleton={isLoadingMotor || showSkeleton}
                placeholder="0.000"
                error={errors.nameplatePowerFactor}
                onBlur={handlePFBlur}
              />
            </Grid>
            <Grid item xs={1}>
              <FormTooltip tooltipId={2} />
            </Grid>
          </Grid>
        </Grid>
        {isFanMotor && (
          <>
            <Grid item xs={12}>
              <h2>Sheave</h2>
            </Grid>
            <Grid item xs={12}>
              <FormSelect
                name="motorSheaveMfgId"
                label="Motor sheave manufacturer"
                error={errors.motorSheaveMfgId}
                onChange={handleInputChange}
                showSkeleton={
                  isLoadingMotor || showSkeleton || isLoadingMotorSheaveMfgs
                }
                options={motorSheaveManufacturers}
                value={(motorValues as IFanMotorDTO)?.motorSheaveMfgId || 0}
                disabled={isDisabled}
              />
            </Grid>
            {catalogValueOtherEval(
              (motorValues as IFanMotorDTO)?.motorSheaveMfgId || 0,
              motorSheaveManufacturers as IDefaultComboBoxCatalog[]
            ) && (
              <Grid item xs={12}>
                <FormText
                  name="otherMotorSheaveMfg"
                  label="Other motor sheave manufacturer"
                  onChange={handleInputChange}
                  placeholder="Type the fan type here"
                  value={
                    (motorValues as IFanMotorDTO)?.otherMotorSheaveMfg || ""
                  }
                  showSkeleton={isLoadingMotor || showSkeleton}
                  error={errors.otherMotorSheaveMfg}
                  disabled={isDisabled}
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <FormText
                name="motorSheaveStamping"
                label="Motor sheave stamping (Full stamping)"
                onChange={handleInputChange}
                placeholder="Motor sheave stamping"
                value={(motorValues as IFanMotorDTO)?.motorSheaveStamping || ""}
                showSkeleton={isLoadingMotor || showSkeleton}
                disabled={isDisabled}
              />
            </Grid>
            <Grid item xs={12} md={4} flexDirection="column">
              <Grid container spacing={0}>
                <Grid item xs={11}>
                  <FormNumeric2
                    name="motorSheaveStampingDimension"
                    value={
                      (motorValues as IFanMotorDTO)
                        ?.motorSheaveStampingDimension || 0
                    }
                    label={`Motor sheave stamping dimension ${systemOfMeasurement.get(
                      "in"
                    )}`}
                    error={errors.motorSheaveStampingDimension}
                    onChange={handleInputChange}
                    fixedDecimalScale
                    decimalScale={4}
                    thousandSeparator
                    showSkeleton={isLoadingMotor || showSkeleton}
                    disabled={isDisabled}
                  />
                </Grid>
                <Grid item xs={1}>
                  <FormTooltip
                    tooltipId={
                      systemOfMeasurement.measurementSystem === "metricSystem"
                        ? 33
                        : 24
                    }
                    showSkeleton={isLoadingMotor || showSkeleton}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={4}>
              <FormNumeric2
                name="halfOfTheMotorSheaveDimension"
                value={
                  (motorValues as IFanMotorDTO)
                    ?.halfOfTheMotorSheaveDimension || 0
                }
                label={`Half of the motor sheave dimension from outer edge of pulley to the face of the motorshaft ${systemOfMeasurement.get(
                  "in"
                )}`}
                error={errors.halfOfTheMotorSheaveDimension}
                onChange={handleInputChange}
                fixedDecimalScale
                decimalScale={4}
                thousandSeparator
                showSkeleton={isLoadingMotor || showSkeleton}
                disabled={isDisabled}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Grid container spacing={0}>
                <Grid item xs={11}>
                  <FormNumeric2
                    name="otherMotorShaftDiameter"
                    value={
                      (motorValues as IFanMotorDTO)?.otherMotorShaftDiameter ||
                      0
                    }
                    label={`Motor Shaft Diameter ${systemOfMeasurement.get(
                      "in"
                    )}`}
                    error={errors.otherMotorShaftDiameter}
                    onChange={handleInputChange}
                    fixedDecimalScale
                    decimalScale={4}
                    thousandSeparator
                    showSkeleton={isLoadingMotor || showSkeleton}
                    disabled={isDisabled}
                  />
                </Grid>
                <Grid item xs={1}>
                  <FormTooltip
                    tooltipId={
                      systemOfMeasurement.measurementSystem === "metricSystem"
                        ? 33
                        : 24
                    }
                    showSkeleton={isLoadingMotor || showSkeleton}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <FormLabel
                text={`Motor sheave dimension measured ${systemOfMeasurement.get(
                  "in"
                )}: ${
                  (motorValues as IFanMotorDTO)?.motorSheaveDimension?.toFixed(
                    2
                  ) || ""
                }`}
                showSkeleton={isLoadingMotor || showSkeleton}
              ></FormLabel>
            </Grid>
            {showMotorSheaveAlert && (
              <Grid item xs={12}>
                <Alert severity="error">
                  <p>
                    Please review dimension entered.
                    <br />
                    Difference in dimensions exceeds built-in tolerance.
                    <br />
                    Motor Sheave Dimension Measured value should be between{" "}
                    {`${motorSheaveDimensionLow.toFixed(2)}`} and{" "}
                    {`${motorSheaveDimensionHigh.toFixed(
                      2
                    )} ${systemOfMeasurement.get("in")}`}{" "}
                    regarding the actual Motor Sheave Stamping Dimension value.
                  </p>
                </Alert>
              </Grid>
            )}
            <Grid item xs={12}>
              <h2>Motor Sheave Type and Bore</h2>
            </Grid>
            <Grid item xs={12}>
              <FormRadioGroup
                name="motorSheave"
                label="Motor sheave"
                onChange={handleInputChange}
                items={motorSheaveOptions}
                value={(motorValues as IFanMotorDTO)?.motorSheave || null}
                showSkeleton={isLoadingMotor || showSkeleton}
                row
                disabled={isDisabled}
              />
            </Grid>
            {(motorValues as IFanMotorDTO)?.motorSheave === "Fixed" && (
              <>
                <Grid item xs={12} md={3}>
                  <FormCheckBox
                    size="medium"
                    name="motorSheaveBushingInstalled"
                    label="Motor sheave bushing installed"
                    onChange={handleInputChange}
                    value={
                      (motorValues as IFanMotorDTO)
                        ?.motorSheaveBushingInstalled || false
                    }
                    showSkeleton={isLoadingMotor || showSkeleton}
                    disabled={isDisabled}
                  />
                </Grid>
                {(motorValues as IFanMotorDTO)?.motorSheaveBushingInstalled && (
                  <Grid item xs={12} md={9}>
                    <FormText
                      name="motorSheaveBushingStamping"
                      label="Motor sheave bushing stamping"
                      onChange={handleInputChange}
                      placeholder="Motor sheave bushing stamping"
                      value={
                        (motorValues as IFanMotorDTO)
                          ?.motorSheaveBushingStamping || ""
                      }
                      showSkeleton={isLoadingMotor || showSkeleton}
                      disabled={isDisabled}
                    />
                  </Grid>
                )}
              </>
            )}
            {(motorValues as IFanMotorDTO)?.motorSheave === "Adjustable" && (
              <Grid item xs={12}>
                <FormNumeric2
                  name="turnsOpen"
                  value={(motorValues as IFanMotorDTO)?.turnsOpen}
                  label="Turns open"
                  onChange={handleInputChange}
                  fixedDecimalScale
                  decimalScale={2}
                  thousandSeparator
                  showSkeleton={isLoadingMotor || showSkeleton}
                  placeholder="0.00"
                  disabled={isDisabled}
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <h2>Belt</h2>
            </Grid>
            <Grid item xs={12}>
              <FormNumeric2
                name="quantityOfBelts"
                value={(motorValues as IFanMotorDTO)?.quantityOfBelts}
                label="Quantity of fan/motor belt(s) required"
                onChange={handleInputChange}
                fixedDecimalScale
                decimalScale={0}
                thousandSeparator
                showSkeleton={isLoadingMotor || showSkeleton}
                placeholder="0"
                disabled={isDisabled}
              />
            </Grid>
            <Grid item xs={12}>
              <FormSelect
                name="beltTypeId"
                label="Belt type"
                onChange={handleInputChange}
                options={betlTypes}
                value={(motorValues as IFanMotorDTO).beltTypeId || 0}
                showSkeleton={
                  isLoadingMotor || isLoadingBeltType || showSkeleton
                }
                error={errors.beltTypeId}
                disabled={isDisabled}
              />
            </Grid>
            {catalogValueOtherEval(
              (motorValues as IFanMotorDTO).beltTypeId || 0,
              betlTypes as IDefaultComboBoxCatalog[]
            ) && (
              <Grid item xs={12}>
                <FormText
                  name="otherBeltType"
                  label="Other betl type"
                  onChange={handleInputChange}
                  placeholder="Type the motor manufacturer here"
                  value={(motorValues as IFanMotorDTO).otherBeltType || ""}
                  showSkeleton={isLoadingMotor || showSkeleton}
                  error={errors.otherBeltType}
                  disabled={isDisabled}
                />
              </Grid>
            )}
            <Grid item xs={12} md={6}>
              <FormNumeric2
                name="beltSize"
                value={(motorValues as IFanMotorDTO)?.beltSize}
                label={`Belt size (${systemOfMeasurement.get("in")})`}
                onChange={handleInputChange}
                fixedDecimalScale
                decimalScale={3}
                thousandSeparator
                showSkeleton={isLoadingMotor || showSkeleton}
                placeholder="0.00"
                error={errors.beltSize}
                disabled={isDisabled}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormNumeric2
                name="dimension"
                value={(motorValues as IFanMotorDTO)?.dimension}
                label={`Center of motor shaft to center of fan shaft distance (${systemOfMeasurement.get(
                  "in"
                )})`}
                onChange={handleInputChange}
                fixedDecimalScale
                decimalScale={3}
                thousandSeparator
                showSkeleton={isLoadingMotor || showSkeleton}
                placeholder="0.00"
                error={errors.dimension}
                disabled={isDisabled}
              />
            </Grid>
            <Grid item xs={12}>
              <FormCheckBox
                size="medium"
                name="betlTensioningIdler"
                label="Belt Tensioning Idler Pulley Installed"
                onChange={handleInputChange}
                value={
                  (motorValues as IFanMotorDTO)?.betlTensioningIdler || false
                }
                showSkeleton={isLoadingMotor || showSkeleton}
                disabled={isDisabled}
              />
              <FormTooltip
                text="Click to open information"
                onClick={handleOpenBeltTensionerTooltip}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormNumeric2
                name="baseFrameAdjIn"
                value={(motorValues as IFanMotorDTO)?.baseFrameAdjIn}
                label={`${
                  (motorValues as IFanMotorDTO)?.betlTensioningIdler
                    ? "Belt Tension Idler"
                    : "Motor base/frame"
                } adj in (${systemOfMeasurement.get("in")})`}
                onChange={handleInputChange}
                fixedDecimalScale
                decimalScale={3}
                thousandSeparator
                showSkeleton={isLoadingMotor || showSkeleton}
                placeholder="0.00"
                error={errors.baseFrameAdjIn}
                disabled={isDisabled}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormNumeric2
                name="baseFrameAdjOut"
                value={(motorValues as IFanMotorDTO)?.baseFrameAdjOut}
                label={`${
                  (motorValues as IFanMotorDTO)?.betlTensioningIdler
                    ? "Belt Tension Idler"
                    : "Motor base/frame"
                } adj out (${systemOfMeasurement.get("in")})`}
                onChange={handleInputChange}
                fixedDecimalScale
                decimalScale={3}
                thousandSeparator
                showSkeleton={isLoadingMotor || showSkeleton}
                placeholder="0.00"
                error={errors.baseFrameAdjOut}
                disabled={isDisabled}
              />
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
};

export default Motor;
