import { IRecommendationsComments } from "src/ts/interfaces/recommendationsComments";
import axios from "../utils/axios";

const create = (comments: IRecommendationsComments) => {
  return axios.post(`RecommendationsComments`, comments);
};

const recommendationsCommentsService = {
  create,
};

export default recommendationsCommentsService;
