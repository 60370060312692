import React, { useEffect, useState } from "react";
import Popup from "src/components/Popup";
import { FormButton, FormCheckSwitch } from "src/components/formControls";
import {
  Stack,
  Grid,
  Divider as MuiDivider,
  styled,
  Typography,
} from "@mui/material";
import { useAsyncMutation, useAsyncQuery, useLog } from "src/hooks";
import mandatoryMinimumStudyService from "src/services/mandatoryMinimumStudyService";
import { IMandatoryMinimumStudy } from "src/ts/interfaces/mandatoryMinimumStudy";
import { spacing } from "@mui/system";
import { ConfigurableStudiesGroup } from "src/constants";

interface Props {
  showTemplatePopUp: boolean;
  setShowTemplatePopUp: (val: boolean) => void;
  projectId: number;
  projectReportId: number;
  reportName: string;
  groupType: number;
  disable?: boolean;
}

export default function ConfigureStudyPopup({
  showTemplatePopUp,
  setShowTemplatePopUp,
  projectId,
  projectReportId,
  reportName,
  groupType,
  disable,
}: Props) {
  const { log } = useLog();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [checkStudy, setCheckStudy] = useState<IMandatoryMinimumStudy>();
  const [selectAll, setSelectAll] = useState<boolean>(false);
  const Divider = styled(MuiDivider)(spacing);
  const handleClosePopUp = () => {
    setShowTemplatePopUp(false);
  };

  const {
    execute: executeStudies,
    data: dataStudies,
    setData: setDataStudies,
  } = useAsyncQuery<IMandatoryMinimumStudy[]>(
    mandatoryMinimumStudyService.getByStudy
  );

  const { execute: executeUpdate } = useAsyncMutation(
    mandatoryMinimumStudyService.update,
    { successfulMessage: "The status was changed" }
  );

  const { execute: executeStudiesUpdate } =
    useAsyncQuery<IMandatoryMinimumStudy>(mandatoryMinimumStudyService.update);

  useEffect(() => {
    const getData = async () => {
      await executeStudies(projectId, projectReportId);
    };

    getData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (dataStudies) {
      dataStudies.forEach(async (item) => {
        if (item.mandatoryMinimumField.parentId === null) {
          let isChecked = true;
          dataStudies.forEach(async (subItem) => {
            if (
              subItem.mandatoryMinimumField.parentId !== null &&
              item.mandatoryMinimumFieldId ===
                subItem.mandatoryMinimumField.parentId &&
              subItem.disabled === false
            ) {
              isChecked = false;
            }
          });
          if (item.disabled !== isChecked) {
            item.disabled = isChecked;
            setCheckStudy(item);
          }
        }
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataStudies, checkStudy]);

  const handleSelectAll = () => {
    dataStudies.forEach(async (element) => {
      element.disabled = !selectAll;
      setCheckStudy(element);
      await executeStudiesUpdate(element);
    });
    setSelectAll(!selectAll);
    log.success("The status was changed");
  };

  const handleUpdate = async (study: IMandatoryMinimumStudy) => {
    study.disabled = !study.disabled;
    if (!study.disabled) {
      setSelectAll(false);
    }
    setCheckStudy(study);
    await executeUpdate(study);
  };

  const handleUpdateAll = async (study: IMandatoryMinimumStudy) => {
    study.disabled = !study.disabled;

    dataStudies.forEach(async (item) => {
      if (
        item.mandatoryMinimumField.parentId === study.mandatoryMinimumFieldId ||
        (item.id === study.id && item.disabled !== study.disabled)
      ) {
        item.disabled = study.disabled;
        await executeUpdate(item);
      }
    });
    setDataStudies(dataStudies);
  };

  return (
    <>
      <Popup
        title={`Configure report - ${reportName}`}
        openPopup={showTemplatePopUp}
        setOpenPopup={setShowTemplatePopUp}
        onClose={handleClosePopUp}
        size="sm"
      >
        <Stack direction="row">
          <Grid container spacing={5}>
            <Grid item xs={12}>
              {groupType !== ConfigurableStudiesGroup.nonConfigurable && (
                <FormCheckSwitch
                  name={"selectAll"}
                  label={"Select All"}
                  onChange={handleSelectAll}
                  value={selectAll}
                  disabled={disable}
                ></FormCheckSwitch>
              )}
              <Divider></Divider>
              {dataStudies
                ?.filter(
                  (item) =>
                    (item.mandatoryMinimumField.type === 1 ||
                      item.mandatoryMinimumField.type === 2) &&
                    item.mandatoryMinimumField.parentId === null
                )
                .map((study) => {
                  return (
                    <>
                      <Grid item xs={12}>
                        <FormCheckSwitch
                          name={study.id.toString()}
                          label={
                            <Typography variant="h6">
                              {study.mandatoryMinimumField.description}
                            </Typography>
                          }
                          onChange={() => {
                            handleUpdateAll(study);
                          }}
                          value={study.disabled}
                          disabled={disable}
                        ></FormCheckSwitch>
                      </Grid>
                      {dataStudies
                        ?.filter(
                          (item) =>
                            (item.mandatoryMinimumField.type === 1 ||
                              item.mandatoryMinimumField.type === 2) &&
                            item.mandatoryMinimumField.parentId ===
                              study.mandatoryMinimumField.id
                        )
                        .map((sub) => {
                          return (
                            <>
                              <Grid item xs={12} paddingLeft={5}>
                                <FormCheckSwitch
                                  name={sub.id.toString()}
                                  label={sub.mandatoryMinimumField.description}
                                  onChange={() => {
                                    handleUpdate(sub);
                                  }}
                                  value={sub.disabled}
                                  disabled={disable}
                                ></FormCheckSwitch>
                              </Grid>
                            </>
                          );
                        })}
                      <Divider my={3}></Divider>
                    </>
                  );
                })}
              <Grid item xs={12}>
                <Typography pt={3} pb={3} variant="h5" color={"error"}>
                  Mandatory Minimum List
                </Typography>
                <Divider></Divider>
              </Grid>
              <Grid item xs={12} pt={3}>
                {dataStudies
                  ?.filter((item) => item.mandatoryMinimumField.type === 3)
                  .map((item) => {
                    return (
                      <Grid container>
                        {item.mandatoryMinimumField.type === 3 && (
                          <Grid item xs={12}>
                            <Typography variant="h4">
                              {item.mandatoryMinimumField.description}
                            </Typography>
                          </Grid>
                        )}
                        <Grid item xs={12}>
                          <Typography
                            pl={10}
                            display={"block"}
                            whiteSpace={"pre-wrap"}
                          >
                            {item.mandatoryMinimumField.subTitle}
                          </Typography>
                          <br />
                        </Grid>
                      </Grid>
                    );
                  })}
              </Grid>
            </Grid>
            <></>
          </Grid>
        </Stack>
        <Stack direction="row" spacing={2}>
          <FormButton
            text="Close"
            size="small"
            variant="outlined"
            onClick={() => {
              setShowTemplatePopUp(false);
            }}
          />
        </Stack>
      </Popup>
    </>
  );
}
