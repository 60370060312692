import Popup from "src/components/Popup";
import {
  FormButton,
  FormCheckBox,
  FormTextArea,
} from "src/components/formControls";
import { Stack, Typography, Grid } from "@mui/material";
import { useAsyncQuery, useForm, useLog } from "src/hooks";
import documentNotificationService from "src/services/documentNotificationService";
import { useEffect, useState } from "react";
import { IGetNotificationData } from "src/ts/interfaces/documentNotification";
import { NOTIFICATIONTYPE } from "src/constants";
import technicianService from "src/services/technicianService";

interface DocumentIssuePopUpProps {
  documentIssueShowPopUp: boolean;
  setDocumentIssueShowPopUp: (val: boolean) => void;
  technicianId: number;
}
const initialValues = {
  idNotes: "",
  photoNotes: "",
  1: false,
  2: false,
  3: false,
  4: false,
  5: false,
  6: false,
};

export default function DocumentIssuePopUp({
  documentIssueShowPopUp,
  setDocumentIssueShowPopUp,
  technicianId,
}: DocumentIssuePopUpProps) {
  const { log } = useLog();
  const [reasonsIds, setReasonsIds] = useState("");

  const {
    execute: executeNotificaiton,
    data: dataNotification,
    isLoading: isLoadingNotification,
  } = useAsyncQuery<IGetNotificationData[]>(
    documentNotificationService.getNotifications
  );

  const { execute: executeDocumentIssue, isLoading: isLoadingDocumentIssue } =
    useAsyncQuery(technicianService.postDocumentIssue);

  useEffect(() => {
    executeNotificaiton();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const { values, setValues, handleInputChange } = useForm(
    initialValues,
    false,
    []
  );

  const handleCheckBoxChange = (e: any) => {
    const { name, value } = e.target;

    setValues({ ...values, [name]: value });

    if (value) {
      if (!reasonsIds.split(",").includes(name)) {
        setReasonsIds(reasonsIds ? `${reasonsIds},${name}` : name);
      }
    } else {
      const updatedReasonsIds = reasonsIds
        .split(",")
        .filter((reasonId) => reasonId !== name)
        .join(",");
      setReasonsIds(updatedReasonsIds);
    }
  };

  const handlerAccept = async () => {
    await executeDocumentIssue({
      idNotes: values.idNotes,
      photoNotes: values.photoNotes,
      reasonsIds: reasonsIds,
      techId: technicianId.toString(),
    });
    setDocumentIssueShowPopUp(false);
    log.success("The email has been succesfully sent.");
  };

  return (
    <>
      <Popup
        title={"Send notification"}
        openPopup={documentIssueShowPopUp}
        setOpenPopup={setDocumentIssueShowPopUp}
        onClose={() => {
          setDocumentIssueShowPopUp(false);
        }}
        size="lg"
      >
        <>
          <Grid container>
            <Grid item xs={12}>
              <Typography mb={3} textAlign={"center"}>
                Please select the reason from the list below why of the Test and
                Balance / Enery Technician training date can't be schedule. A
                notification will be sent inmediately to the applicant:{" "}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography>
                {" "}
                <b>Issues related with the ID:</b>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Stack direction={"column"}>
                {dataNotification &&
                  dataNotification
                    .filter((item) => {
                      return item.name === NOTIFICATIONTYPE.ID;
                    })
                    .map((item2) => {
                      return (
                        <FormCheckBox
                          label={item2.description}
                          name={item2.id.toString()}
                          value={values.id}
                          onChange={handleCheckBoxChange}
                          showSkeleton={isLoadingNotification}
                          size={"small"}
                        />
                      );
                    })}
              </Stack>
            </Grid>
            <Grid item xs={12} mt={1}>
              <FormTextArea
                label=""
                name="idNotes"
                placeholder="Enter an explanation if the button selection doesn't represent the issue at hand (ID)"
                onChange={handleInputChange}
                value={values.idNotes}
                showSkeleton={isLoadingNotification}
              />
            </Grid>
          </Grid>
          <Grid container mt={4}>
            <Grid item xs={12}>
              <Typography>
                {" "}
                <b>Issues related with the Photo:</b>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Stack direction={"column"}>
                {dataNotification &&
                  dataNotification
                    .filter((item) => {
                      return item.name === NOTIFICATIONTYPE.PHOTO;
                    })
                    .map((item2) => {
                      return (
                        <FormCheckBox
                          label={item2.description}
                          name={item2.id.toString()}
                          value={values.id}
                          onChange={handleCheckBoxChange}
                          showSkeleton={isLoadingNotification}
                        />
                      );
                    })}
              </Stack>
            </Grid>
            <Grid item xs={12} mt={1}>
              <FormTextArea
                label=""
                name="photoNotes"
                placeholder="Enter an explanation if the button selection doesn't represent the issue at hand (Photo)"
                onChange={handleInputChange}
                value={values.photoNotes}
                showSkeleton={isLoadingNotification}
              />
            </Grid>
          </Grid>
        </>
        <Stack direction="row" spacing={3}>
          <FormButton
            size="small"
            text="Cancel"
            variant="outlined"
            onClick={() => {
              setDocumentIssueShowPopUp(false);
            }}
            isSubmitting={isLoadingDocumentIssue}
          />
          <FormButton
            size="small"
            text="Accept"
            color="primary"
            onClick={handlerAccept}
            isSubmitting={isLoadingDocumentIssue}
          />
        </Stack>
      </Popup>
    </>
  );
}
