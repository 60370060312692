import { TowerStudyProvider } from "../context/TowerStudyContext";
import TowerStep4 from "./TowerStep4";

const Index = () => {
  return (
    <TowerStudyProvider>
      <TowerStep4 />
    </TowerStudyProvider>
  );
};

export default Index;
