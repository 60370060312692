import { unitLabelService } from "src/services";
import { useEffect, useState } from "react";
import getProjectService from "src/services/project/getProjectService";
import { ISystemOfMeasurement } from "src/ts/interfaces";

function useSystemOfMeasurement(loadLabels: boolean) {
  const [labels, setLabels] = useState<any | null>(null);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [load, setLoad] = useState<boolean>(loadLabels);
  const [measurementSystem, setMeasurementSystem] = useState<string | null>(
    null
  );
  let currentSystem = "imperialSystem";

  useEffect(() => {
    const loadData = async () => {
      const res = await unitLabelService.dictionary();
      localStorage.setItem("systemOfMeasurement", JSON.stringify(res.data));
    };

    if (load) loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const get = (key: string) => {
    if (labels === null) {
      const localStorageValue = localStorage.getItem("systemOfMeasurement");
      if (localStorageValue === null) return;

      const parsedValue = JSON.parse(localStorageValue);
      setLabels(parsedValue);
      const value = parsedValue[key][measurementSystem ?? "imperialSystem"];
      return value;
    } else {
      const value = labels[key][measurementSystem ?? "imperialSystem"];
      return value;
    }
  };

  const getByProject = async (projectId: number) => {
    try {
      const measurementSystemResponse =
        await getProjectService.getMeasurementSystem(projectId);
      setMeasurementSystem(measurementSystemResponse.data);
      setSystem(measurementSystemResponse.data);
      return measurementSystemResponse.data;
    } catch (error) {
      return null;
    }
  };

  const setSystem = (system: string) => {
    currentSystem = system;
    setMeasurementSystem(system);
  };

  const systemOfMeasurement: ISystemOfMeasurement = {
    get,
    setSystem,
    getByProject,
    measurementSystem,
  };

  return { systemOfMeasurement };
}

export default useSystemOfMeasurement;
