import React, { useEffect, useState } from "react";
import { Stack } from "@mui/material";
import Popup from "src/components/Popup";
import { FormButton } from "src/components/formControls";
import { ITypeAsset } from "src/ts/interfaces/companyAssets";
import { useTheme } from "src/hooks";
import { THEMES } from "src/constants";

interface Props {
  showRecommendedModal: boolean;
  setShowRecommendedModal: (val: boolean) => void;
  information: ITypeAsset;
}

const RecommendedPopUp = ({
  showRecommendedModal,
  setShowRecommendedModal,
  information,
}: Props) => {
  const { theme } = useTheme();
  const [finallyStyel, setFinallyStyel] = useState("");

  useEffect(() => {
    const themeSelected = () => {
      if (THEMES.DARK === theme) {
        const backgroundColorChanged = information.recommendedHTML?.replaceAll(
          "background-color: rgb(255,255,255)",
          ""
        );
        const texColor = backgroundColorChanged?.replaceAll(
          "color: rgb(62,72,85)",
          "color: white"
        );
        setFinallyStyel(
          texColor?.replaceAll("color: black", "color: white") ?? ""
        );
      } else {
        setFinallyStyel(information.recommendedHTML ?? "");
      }
    };
    themeSelected();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Popup
      title={
        ` ${
          information.title === "Vehicle"
            ? "Recommended Testing Vehicle"
            : "Recommended Testing Equipment"
        } ` ?? ""
      }
      openPopup={showRecommendedModal}
      setOpenPopup={setShowRecommendedModal}
      onClose={() => {
        setShowRecommendedModal(false);
      }}
      size="md"
    >
      <div dangerouslySetInnerHTML={{ __html: finallyStyel ?? "" }} />
      <Stack
        direction="row"
        spacing={2}
        justifyContent="flex-end"
        alignItems="flex-start"
      >
        <FormButton
          text="Close"
          size="medium"
          onClick={() => {
            setShowRecommendedModal(false);
          }}
          variant="outlined"
        />
      </Stack>
    </Popup>
  );
};

export default RecommendedPopUp;
