import { IUploadAssetFile, SendDocumentAssets } from "src/ts/interfaces";
import { IAssetDocument } from "src/ts/interfaces/companyAssets";
import axios from "src/utils/axios";

const getAllAssetDocuments = (assetId: number) => {
  return axios.get<Array<IAssetDocument>>(
    `AssetDocument/documentsList?assetId=${assetId}`
  );
};

const uploadUserFile = async (file: IUploadAssetFile) => {
  let bodyFormData = new FormData();
  bodyFormData.append("fileForUpload[0]", file.content);
  bodyFormData.append("key[type]", file.documentType);
  bodyFormData.append("key[companyAssetId]", file.assetId);
  bodyFormData.append("key[companyId]", file.companyId);

  if (file.assetsAssessmentId !== undefined) {
    bodyFormData.append("key[assetAssessmentId]", file.assetsAssessmentId);
  }

  return await axios({
    method: "post",
    url: "/AssetDocument/document",
    data: bodyFormData,
    headers: { "Content-Type": "multipart/form-data" },
  });
};

const deleteDocumentAssets = (id: number) => {
  return axios.delete(`AssetDocument?id=${id}`);
};
const downloadDocumentAssets = (id: number) => {
  return axios.get(`AssetDocument/file?id=${id}`);
};

const sendEmailDocuments = (data: SendDocumentAssets) => {
  return axios.post(`AssetDocument/SendDocuments`, data);
};

const assetDocumentService = {
  getAllAssetDocuments,
  uploadUserFile,
  deleteDocumentAssets,
  downloadDocumentAssets,
  sendEmailDocuments,
};

export default assetDocumentService;
