import React, { useEffect, useState } from "react";
import Popup from "src/components/Popup";
import {
  FormCancelButton,
  FormText,
  FormCheckBox,
  FormSaveButton,
} from "src/components/formControls";
import { Stack, IconButton, Grid } from "@mui/material";
import { ClearOutIcon, AddIcon } from "src/components/icons";
import useForm from "src/hooks/useForm";
import { MetaData } from "src/ts/interfaces/subscription";
import SubscriptionService from "src/services/subscriptionService";
import useLog from "src/hooks/useLog";
import { Permission as PermissionTypes } from "src/ts/enums";
import { usePermissions } from "src/hooks";

interface InformationProps {
  showEditSubscription: boolean;
  setShowEditSubscription: (val: boolean) => void;
  susbcriptionItem: any;
  setRefresh: (val: boolean) => void;
}

export default function EditSubscritionPopUp({
  showEditSubscription,
  setShowEditSubscription,
  susbcriptionItem,
  setRefresh,
}: InformationProps) {
  const [textLines, setTextLines] = useState<any>(susbcriptionItem.textLines);
  const [subsMetaData, setSubsMetaData] = useState<any>(susbcriptionItem);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { fullAccess, readOnly } = usePermissions(
    PermissionTypes.Subscriptions
  );
  const handleClosePopUp = () => {
    setShowEditSubscription(false);
  };
  const initialValues = {
    title: susbcriptionItem.title,
    subTitle: susbcriptionItem.subTitle,
    price: susbcriptionItem.price,
    getPriceFromDb: susbcriptionItem.getPriceFromDb,
    isActive: susbcriptionItem.isActive,
    id: susbcriptionItem.id,
    costCatalogId: susbcriptionItem.costCatalogId,
    subscriptionId: susbcriptionItem.subscriptionId,
    textPage: susbcriptionItem.textPage,
  };

  const { log } = useLog();
  const { values, handleInputChange } = useForm(initialValues, false, []);

  useEffect(() => {
    setSubsMetaData((current: any) => {
      return {
        ...current,
        textLines: textLines,
      };
    });
  }, [textLines]);

  const handleAddNewTextLine = () => {
    setTextLines([
      ...textLines,
      {
        breakLine: false,
        cost: null,
        text: "",
        style: null,
        costCatalogId: null,
      },
    ]);
  };

  const handleDeleteTextLine = (textData: any) => {
    var newArrayTextLine = textLines.filter((textLine: any) => {
      return textLine.text !== textData.text;
    });

    setTextLines(newArrayTextLine);
  };

  const handleOnChangeTextLines = (e: any, index: number, field: string) => {
    e.preventDefault();
    const { value } = e.target;

    const newTextLines = [...textLines];
    newTextLines[index] = { ...newTextLines[index], [field]: value };
    setTextLines(newTextLines);
  };

  const handleAcceptedButton = () => {
    var arrayMetaData: MetaData[] = [];
    arrayMetaData.push({
      ...values,
      textLines: subsMetaData.textLines,
      withCostList: subsMetaData.withCostList,
      withoutCostList: subsMetaData.withoutCostList,
    });

    const postMetaDataSubs = async (data: MetaData[]) => {
      setIsSubmitting(true);
      try {
        await SubscriptionService.postMetaData(data);
        log.success(`Metadata was saved successfully`);
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      } finally {
        setIsSubmitting(false);
        setRefresh(true);
        setShowEditSubscription(false);
      }
    };
    postMetaDataSubs(arrayMetaData);
  };

  return (
    <>
      <Popup
        title="Add new team member"
        openPopup={showEditSubscription}
        setOpenPopup={setShowEditSubscription}
        onClose={handleClosePopUp}
        size="md"
      >
        <>
          <Grid container>
            <Grid item container xs={12} spacing={5} mb={5}>
              <Grid item xs={12}>
                <Stack
                  direction="row"
                  justifyContent="start"
                  alignItems="center"
                >
                  <FormText
                    name="title"
                    label="Title"
                    value={values.title}
                    onChange={handleInputChange}
                    disabled={readOnly}
                  />
                </Stack>
              </Grid>
              <Grid item xs={12}>
                <Stack
                  direction="row"
                  justifyContent="start"
                  alignItems="center"
                >
                  <FormText
                    name="subTitle"
                    label="SubTitle"
                    value={values.subTitle}
                    onChange={handleInputChange}
                    disabled={readOnly}
                  />
                </Stack>
              </Grid>
              <Grid item xs={12}>
                <Stack
                  direction="row"
                  justifyContent="start"
                  alignItems="center"
                >
                  <FormText
                    name="price"
                    label="Price"
                    value={values.price}
                    onChange={handleInputChange}
                    disabled={readOnly}
                  />
                </Stack>
              </Grid>
              <Grid item xs={12}>
                <Stack
                  direction="row"
                  justifyContent="start"
                  alignItems="center"
                >
                  <FormCheckBox
                    name="getPriceFromDb"
                    label="Get price from database"
                    value={values.getPriceFromDb}
                    onChange={handleInputChange}
                    disabled={readOnly}
                  />
                </Stack>
                <Stack
                  direction="row"
                  justifyContent="start"
                  alignItems="center"
                >
                  <FormCheckBox
                    name="isActive"
                    label="Active"
                    value={values.isActive}
                    onChange={handleInputChange}
                    disabled={readOnly}
                  />
                </Stack>
                <Stack
                  direction="row"
                  justifyContent="start"
                  alignItems="center"
                  ml={-2}
                >
                  {fullAccess && (
                    <IconButton
                      aria-label="add"
                      size="small"
                      onClick={handleAddNewTextLine}
                    >
                      <AddIcon />
                    </IconButton>
                  )}
                </Stack>
              </Grid>

              <Grid container item xs={12} spacing={3}>
                {textLines.map((item: any, index: number) => (
                  <React.Fragment key={index}>
                    <Grid item xs={6}>
                      <FormText
                        name=""
                        label="Text"
                        value={item.text}
                        disabled={readOnly}
                        onChange={(e) => {
                          handleOnChangeTextLines(e, index, "text");
                        }}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <FormText
                        name=""
                        label="Style"
                        value={item.style}
                        disabled={readOnly}
                        onChange={(e) => {
                          handleOnChangeTextLines(e, index, "style");
                        }}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      {fullAccess && (
                        <IconButton
                          aria-label="delete"
                          size="small"
                          onClick={() => handleDeleteTextLine(item)}
                        >
                          <ClearOutIcon />
                        </IconButton>
                      )}
                    </Grid>
                  </React.Fragment>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </>

        <Stack direction="row" spacing={5}>
          <FormCancelButton
            isSubmitting={isSubmitting}
            onClick={() => {
              setShowEditSubscription(false);
            }}
          />
          {fullAccess && (
            <FormSaveButton
              isSubmitting={isSubmitting}
              onClick={handleAcceptedButton}
            />
          )}
        </Stack>
      </Popup>
    </>
  );
}
