import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { Badge, Grid, Avatar, Typography } from "@mui/material";
import { useAuth, useAppSelector } from "src/hooks";
import userService from "src/services/userService";
import { IUser } from "src/ts/interfaces/user";
import { ToolTip } from "../others/ToolTip";
import { useNavigate } from "react-router-dom";

const Footer = styled.div`
  background-color: ${(props) =>
    props.theme.sidebar.footer.background} !important;
  padding: ${(props) => props.theme.spacing(2.75)}
    ${(props) => props.theme.spacing(4)};
  border-right: 1px solid rgba(0, 0, 0, 0.12);
`;

const FooterText = styled(Typography)`
  color: ${(props) => props.theme.sidebar.footer.color};
`;

const FooterSubText = styled(Typography)`
  color: ${(props) => props.theme.sidebar.footer.color};
  font-size: 0.7rem;
  display: block;
  padding: 1px;
`;

const FooterBadge = styled(Badge)`
  margin-right: ${(props) => props.theme.spacing(1)};
  span {
    border: 1.5px solid ${(props) => props.theme.palette.common.white};
    height: 12px;
    width: 12px;
    border-radius: 50%;
  }
`;

const SidebarFooter: React.FC = ({ ...rest }) => {
  const [userData, setUserData] = useState<IUser>();
  const { user } = useAuth();
  const signalRStatus = useAppSelector((state) => state.signalR);
  const navigate = useNavigate();
  useEffect(() => {
    const getComapanyData = async () => {
      try {
        const responesUser = await userService.getUserInformation();
        setUserData(responesUser.data);
      } catch (error) {
        navigate("/auth/sign-in");
      }
    };
    getComapanyData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Footer {...rest}>
      <Grid container spacing={2}>
        <Grid item>
          <FooterBadge
            color={signalRStatus.isConnected ? "success" : "error"}
            overlap="circular"
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            variant="dot"
          >
            {!!user && (
              <ToolTip title="Edit Profile">
                <Avatar
                  sx={{ cursor: "pointer" }}
                  onClick={() => {
                    navigate("/app/profile");
                  }}
                  alt={user.displayName}
                  src={
                    userData?.photo
                      ? `data:image/png;base64, ${userData.photo}`
                      : user.Avatar
                  }
                />
              </ToolTip>
            )}
          </FooterBadge>
        </Grid>
        <Grid item>
          {
            <FooterText variant="body2">
              {`${userData?.name} ${userData?.lastName}` ?? ""}
            </FooterText>
          }
          {/* Demo data */}
          {!user && <FooterText variant="body2">Lucy Lavender</FooterText>}
          <FooterSubText variant="caption">
            {signalRStatus.isConnected ? "Connected" : "Disconnected"}
          </FooterSubText>
        </Grid>
      </Grid>
    </Footer>
  );
};

export default SidebarFooter;
