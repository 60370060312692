import { Stack } from "@mui/material";
import { Dispatch, SetStateAction, useState } from "react";
import SpreadSheetActionButton from "src/components/spreadsheet/SpreadSheetActionButton";
import SpreadSheetCell from "src/components/spreadsheet/SpreadSheetCell";
import Spreadsheet, {
  ISpreadsheetColumn,
} from "src/components/spreadsheet/Spreadsheet";
import energySourceSubtractedService from "src/services/study/energySourceSubtractedService";
import sourceSoundTestService from "src/services/study/sourceSoundTestService";
import {
  IEnergySourceSubtracted,
  ISourceSoundTest,
} from "src/ts/interfaces/catalogs";
import { Mode } from "src/ts/types";
import DialogMessagePopup from "src/components/DialogMessagePopup";

interface Props {
  sourceSoundTestItems: ISourceSoundTest[];
  setSourceSoundTestItems: Dispatch<SetStateAction<ISourceSoundTest[]>>;
  energySourceSubtractedSelected: IEnergySourceSubtracted;
  energySourceSubtractedItems: IEnergySourceSubtracted[];
  setEnergySourceSubtractedItems: (val: IEnergySourceSubtracted[]) => void;
  mode: Mode;
  studyDisabled: boolean;
  isLoading: boolean;
  reportId: number;
  reportTypeId: number;
}

const SourceSoundTestGrid = ({
  sourceSoundTestItems,
  setSourceSoundTestItems,
  energySourceSubtractedSelected,
  energySourceSubtractedItems,
  setEnergySourceSubtractedItems,
  mode,
  studyDisabled,
  isLoading,
  reportId,
  reportTypeId,
}: Props) => {
  const [showConfirmDeletePopup, setShowConfirmDeletePopup] = useState(false);

  const savePartial = async (row: ISourceSoundTest, index: number) => {
    const updatedItems = sourceSoundTestItems.map((item, i) => {
      return i === index ? row : item;
    });

    await sourceSoundTestService.updateAll(updatedItems);
    const energySourceItemsRes =
      await sourceSoundTestService.getByEnergySourceSubtractedId(
        energySourceSubtractedSelected?.id
      );

    const [responseItem] = energySourceItemsRes.data.filter(
      (item) => item.id === row.id
    );

    const newSourceSoundTestItems = [...updatedItems];
    newSourceSoundTestItems[index].db = responseItem.db;
    setSourceSoundTestItems(newSourceSoundTestItems);

    await energySourceSubtractedService.updateAll(energySourceSubtractedItems);
    const energySourceRes =
      await energySourceSubtractedService.getByReportIdAndType(
        reportId,
        reportTypeId
      );
    setEnergySourceSubtractedItems(energySourceRes.data);
  };

  const [selectedSoundTestItem, setSelectedSoundTestItem] = useState<{
    item: ISourceSoundTest;
    index: number;
  }>();
  const deleteSoundTestItem = (row: ISourceSoundTest, index: number) => {
    setSelectedSoundTestItem({
      item: row,
      index: index,
    });
    setShowConfirmDeletePopup(true);
  };

  const deleteSourceSound = async () => {
    await sourceSoundTestService.remove(selectedSoundTestItem?.item.id!);

    const newSourceSoundTestItems = [...sourceSoundTestItems];
    newSourceSoundTestItems.splice(selectedSoundTestItem?.index!, 1);

    setSourceSoundTestItems(newSourceSoundTestItems);
  };

  const addSourceSound = async (row: ISourceSoundTest, index: number) => {
    const tempSourceSoundTest: any = {
      energySourceSubtractedId: energySourceSubtractedSelected.id,
    };

    const res = await sourceSoundTestService.add(tempSourceSoundTest);

    const newSourceSoundTestItems = [...sourceSoundTestItems];
    newSourceSoundTestItems.push(res.data);
    setSourceSoundTestItems(newSourceSoundTestItems);
  };

  const columns: ISpreadsheetColumn[] = [
    {
      name: "type",
      type: "index",
      label: "#",
      align: "center",
    },
    {
      name: "description",
      type: "text",
      label: "Source",
      mode: mode,
      disabled: studyDisabled,
    },
    {
      name: "testedSourceOn",
      label: "Tested Source On",
      type: "numericInput",
      maxValue: 10000,
      decimalScale: 3,
      mode: mode,
      disabled: studyDisabled,
      onChange: async (
        row: ISourceSoundTest,
        e: any,
        index: number
      ): Promise<void> => {
        const modifiedRow: ISourceSoundTest = {
          ...row,
          testedSourceOn: parseFloat(e.target.value),
        };
        await savePartial(modifiedRow, index);
      },
    },

    {
      name: "testedSourceOff",
      label: "Tested Source Off",
      type: "numericInput",
      maxValue: 10000,
      disabled: studyDisabled,
      decimalScale: 3,
      mode: mode,
      onChange: async (
        row: ISourceSoundTest,
        e: any,
        index: number
      ): Promise<void> => {
        const modifiedRow: ISourceSoundTest = {
          ...row,
          testedSourceOff: parseFloat(e.target.value),
        };
        await savePartial(modifiedRow, index);
      },
    },
    {
      name: "db",
      label: "Tested Source dB",
      type: "numeric",
      decimalScale: 2,
    },
    {
      name: "actio1",
      type: "custom",
      render: (
        row: ISourceSoundTest,
        col: ISpreadsheetColumn,
        index: number
      ) => {
        return (
          <SpreadSheetCell>
            {mode === "read&Write" ? (
              <Stack direction="row" justifyContent="center">
                <SpreadSheetActionButton
                  type="add"
                  tooltip="Add new item"
                  onMouseUp={(e: any) => addSourceSound(row, index)}
                  disabled={studyDisabled}
                />

                {index !== 0 && (
                  <SpreadSheetActionButton
                    type="delete"
                    tooltip="Delete this item"
                    onMouseUp={() => deleteSoundTestItem(row, index)}
                    disabled={studyDisabled}
                  />
                )}
              </Stack>
            ) : (
              <></>
            )}
          </SpreadSheetCell>
        );
      },
    },
  ];

  return (
    <div>
      <Spreadsheet
        items={sourceSoundTestItems}
        setItems={setSourceSoundTestItems}
        cols={columns}
        defaultRowPerPage={25}
        showSkeleton={isLoading}
      />
      <DialogMessagePopup
        title={"Delete Confirmation"}
        text="Are you certain you want to delete the selected Source Item?"
        showPopup={showConfirmDeletePopup}
        setShowPopup={setShowConfirmDeletePopup}
        onSave={deleteSourceSound}
        onCancel={() => {
          setShowConfirmDeletePopup(false);
        }}
        isSubmitting={false}
        disableClickOutside={true}
      />
    </div>
  );
};

export default SourceSoundTestGrid;
