import {
  Box,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemSecondaryAction,
  ListItemText,
  Stack,
  useTheme,
} from "@mui/material";
import { AddIcon, DeleteIcon } from "src/components/icons";
import { IEnergyCombinedAverage } from "src/ts/interfaces/catalogs";

interface Props {
  energyCombinedAverageItems: IEnergyCombinedAverage[];
  energyCombinedAverageSelected: IEnergyCombinedAverage | null;
  setEnergyCombinedAverageSelected: (
    val: IEnergyCombinedAverage | null
  ) => void;
  selectedEnergy: (
    energySourceSelected: IEnergyCombinedAverage
  ) => Promise<void>;
  onAddEnergySource: () => Promise<void>;
  onDeleteEnergyConfirmation: () => void;
  disabledStudy: boolean;
}

const EnergyCombinedAverageTabs = ({
  energyCombinedAverageItems,
  energyCombinedAverageSelected,
  setEnergyCombinedAverageSelected,
  selectedEnergy,
  onAddEnergySource: addEnergySourceHandler,
  onDeleteEnergyConfirmation,
  disabledStudy,
}: Props) => {
  const theme = useTheme();

  const addEnergySource = async () => {
    await addEnergySourceHandler();
  };

  const selectedItemHandler = async (item: IEnergyCombinedAverage) => {
    await selectedEnergy(item);
  };

  const deleteEnergySource = () => {
    onDeleteEnergyConfirmation();
  };

  return (
    <>
      <Stack direction="row" justifyContent="space-between">
        <Box display="flex" justifyContent="center" alignItems="center">
          <List component={Stack} direction="row" flexWrap={"wrap"}>
            {energyCombinedAverageItems.map((item, index) => {
              return (
                <ListItem
                  key={item.id}
                  disablePadding
                  sx={{
                    borderColor: theme.palette.primary.main,
                    borderWidth: "1px",
                    borderStyle: "solid",
                  }}
                >
                  <ListItemButton
                    selected={energyCombinedAverageSelected?.id === item.id}
                    onMouseDown={async () => await selectedItemHandler(item)}
                  >
                    <ListItemText primary={`${index + 1}      `} />
                  </ListItemButton>

                  <ListItemSecondaryAction></ListItemSecondaryAction>
                </ListItem>
              );
            })}
          </List>
          {/* {!disabledStudy && (
            <Box display="flex" justifyContent="center" alignItems="center">
              <IconButton onClick={addEnergySource}>
                <AddIcon />
              </IconButton>
            </Box>
          )} */}
          {!disabledStudy && (
            <Box display="flex" justifyContent="center" alignItems="center">
              <IconButton onClick={addEnergySource}>
                <AddIcon />
              </IconButton>
            </Box>
          )}
        </Box>
        {!disabledStudy && (
          <Box display="flex" justifyContent="center" alignItems="center">
            <IconButton edge="end" onClick={() => deleteEnergySource()}>
              <DeleteIcon />
            </IconButton>
          </Box>
        )}
      </Stack>
    </>
  );
};

export default EnergyCombinedAverageTabs;
