import { Dispatch, SetStateAction, useCallback, useMemo } from "react";
import { Stack, Toolbar } from "@mui/material";
import outletMasterItemsService from "src/services/study/outletMasterItemsService";
import {
  IKeyValue,
  IOutletMaster,
  IOutletMasterItem,
  ISystemOfMeasurement,
} from "src/ts/interfaces";
import { useLog } from "src/hooks";
import Spreadsheet, {
  ISpreadsheetColumn,
} from "src/components/spreadsheet/Spreadsheet";
import SpreadSheetActionButton from "src/components/spreadsheet/SpreadSheetActionButton";
import SpreadSheetCell from "src/components/spreadsheet/SpreadSheetCell";
import SpreadsheetNumericInput2 from "src/components/spreadsheet/SpreadsheetNumericInput2";
import { Mode } from "src/ts/types/common";
import { FormActionButton } from "src/components/formControls";

interface Props {
  id: number;
  diffuserGrillerNeckSize: boolean;
  diffuserGrilleModel: boolean;
  items: IOutletMasterItem[];
  setItems: Dispatch<SetStateAction<IOutletMasterItem[]>>;
  meterDeviceKeyValue: IKeyValue<number, string>[];
  onSaveMaxAndMin: (row: IOutletMasterItem, index: number) => IOutletMasterItem;
  outletMaster: IOutletMaster;
  updateOutletMaster: () => Promise<void>;
  isLoading: boolean;
  mode: Mode;
  disableStudy: boolean;
  systemOfMeasurement: ISystemOfMeasurement;
}

const OutletMasterGrid = ({
  id,
  diffuserGrillerNeckSize = false,
  diffuserGrilleModel = false,
  items,
  setItems,
  meterDeviceKeyValue,
  onSaveMaxAndMin,
  outletMaster,
  updateOutletMaster,
  isLoading,
  mode,
  disableStudy,
  systemOfMeasurement,
}: Props) => {
  const { log } = useLog();

  const updateActualCFM = useCallback(
    async (e: any, row: IOutletMasterItem, index: number) => {
      const modifiedRow: IOutletMasterItem = {
        ...row,
        akColumn: parseFloat(e.target.value),
      };

      try {
        const res = await outletMasterItemsService.updateItem(modifiedRow);
        const { percentaje, actualCFM, sensibleHeat } = res.data;

        const modifiedRowItem: IOutletMasterItem = {
          ...modifiedRow,
          percentaje,
          actualCFM,
          sensibleHeat,
        };

        const modifiedItems = items.map((item, i) =>
          i === index ? modifiedRowItem : item
        );

        setItems(modifiedItems);

        await updateOutletMaster();
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      }
    },
    [items, log, setItems, updateOutletMaster]
  );

  const updateTotal = useCallback(
    async (e: any, row: IOutletMasterItem, index: number) => {
      const { value } = e.target;
      const nl = !!value;
      const requiredCFM = 0.01;
      const modifiedRow = {
        ...row,
        nl: nl,
        requiredCFM: requiredCFM,
      };

      const newSet = items.map((item: IOutletMasterItem, i: number) =>
        i === index ? modifiedRow : item
      );

      setItems(newSet);

      try {
        await outletMasterItemsService.updateItem(modifiedRow);
        await updateOutletMaster();
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      }
    },
    [items, log, setItems, updateOutletMaster]
  );

  const updateNumbers = useCallback(
    async (e: any, row: IOutletMasterItem, index: number) => {
      const val = parseFloat(e.target.value.toString().replace(/,/g, ""));

      if (row?.requiredCFM === val) return;
      const modifiedRow = { ...row, requiredCFM: val };
      const modifiedItem = onSaveMaxAndMin(modifiedRow, index);

      try {
        await outletMasterItemsService.updateItem(modifiedItem);

        await updateOutletMaster();
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      }
    },
    [log, onSaveMaxAndMin, updateOutletMaster]
  );

  const columns = useMemo(() => {
    const updateRow = (row: IOutletMasterItem, index: number) => {
      const newSet = items.map((item: IOutletMasterItem, i: number) =>
        i === index ? row : item
      );

      setItems(newSet);
    };

    const deleteItem = async (row: IOutletMasterItem) => {
      try {
        await outletMasterItemsService.deleteItem(row.id);
        const unmodified = items.filter((item) => item.id !== row.id);
        setItems(unmodified);
        await updateOutletMaster();
        log.info("Item was deleted");
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      }
    };

    const duplicateItem = async (row: IOutletMasterItem, index: number) => {
      try {
        const res = await outletMasterItemsService.addItem(row);
        const copyOutletMasterItems: IOutletMasterItem[] = [...items];
        copyOutletMasterItems.splice(index + 1, 0, res.data);
        const sortedItems = copyOutletMasterItems.sort((a, b) =>
          !a.order > !b.order ? -1 : 1
        );

        setItems(sortedItems);
        await updateOutletMaster();
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      }
    };

    const onChangeText = (e: any, row: IOutletMasterItem, indexRow: number) => {
      const { name, value } = e.target;

      const modifiedRow = { ...row, [name]: value };

      const unmodified = items.map((item, index) =>
        index === indexRow ? modifiedRow : item
      );

      setItems(unmodified);
    };

    const bb: ISpreadsheetColumn[] = [
      {
        name: "index",
        type: "index",
        label: "#",
        align: "center",
      },
      {
        name: "roomAreaServed",
        type: "text",
        label: "Room or Area Served",
        disabled: disableStudy,
        mode: mode,
        placeholder: "Area served",
      },
      {
        name: "diffuserGrilleModel",
        type: "text",
        label: "Diffuser / Grille Model",
        hide: !diffuserGrilleModel,
        disabled: disableStudy,
        mode: mode,
      },
      {
        name: "rectangle",
        type: "radioButton",
        align: "center",
        label: "Diff / Grille Rect.",
        disabled: disableStudy,
        mode: mode,
        onChange: (row: IOutletMasterItem, e: any, index: number): void => {
          const { checked } = e.target;
          const rectangleValue = !!checked;
          const modifiedRow = {
            ...row,
            circle: !rectangleValue,
            rectangle: rectangleValue,
          };
          updateRow(modifiedRow, index);
        },
      },
      {
        name: "circle",
        type: "radioButton",
        label: "Diff / Grille Round",
        align: "center",
        disabled: disableStudy,
        mode: mode,
        onChange: (row: IOutletMasterItem, e: any, index: number): void => {
          const { checked } = e.target;
          const circleValue = !!checked;
          const modifiedRow = {
            ...row,
            circle: circleValue,
            rectangle: !circleValue,
          };
          updateRow(modifiedRow, index);
        },
      },
      {
        name: "circle",
        type: "custom",
        label: "Grille Length / Diameter",
        render: (
          row: IOutletMasterItem,
          col: ISpreadsheetColumn,
          index: number
        ) => {
          return (
            <SpreadSheetCell>
              <>
                {row.circle ? (
                  "Diameter"
                ) : (
                  <SpreadsheetNumericInput2
                    name="heigth"
                    value={row.heigth}
                    onChange={(e: any) => {
                      onChangeText(e, row, index);
                    }}
                    decimalScale={3}
                    maxValue={1000000}
                    disabled={disableStudy}
                    mode={mode}
                    placeholder={`${systemOfMeasurement.get("in")}`}
                  />
                )}
              </>
            </SpreadSheetCell>
          );
        },
      },
      {
        name: "width",
        type: "numericInput",
        label: "Diff / Grille Width",
        disabled: disableStudy,
        decimalScale: 3,
        maxValue: 1000000,
        mode: mode,
        thousandSeparator: false,
      },
      {
        name: "neckCircle",
        type: "radioButton",
        align: "center",
        label: "Neck Round",
        hide: !diffuserGrillerNeckSize,
        disabled: disableStudy,
        mode: mode,
        onChange: (row: IOutletMasterItem, e: any, index: number) => {
          const { checked } = e.target;
          const rectangleValue = !!checked;
          const modifiedRow = {
            ...row,
            neckRectangle: !rectangleValue,
            neckCircle: rectangleValue,
          };
          updateRow(modifiedRow, index);
        },
      },
      {
        name: "neckRectangle",
        type: "radioButton",
        align: "center",
        label: "Neck Rect.",
        disabled: disableStudy,
        mode: mode,
        hide: !diffuserGrillerNeckSize,
        onChange: (row: IOutletMasterItem, e: any, index: number) => {
          const { checked } = e.target;
          const rectangleValue = !!checked;
          const modifiedRow = {
            ...row,
            neckCircle: !rectangleValue,
            neckRectangle: rectangleValue,
          };
          updateRow(modifiedRow, index);
        },
      },
      {
        name: "neckHeigth",
        type: "custom",
        label: "Neck Length",
        hide: !diffuserGrillerNeckSize,
        render: (
          row: IOutletMasterItem,
          col: ISpreadsheetColumn,
          index: number
        ) => {
          return (
            <SpreadSheetCell>
              <>
                {row.neckCircle ? (
                  "Diameter"
                ) : (
                  <SpreadsheetNumericInput2
                    name="neckHeigth"
                    value={row?.neckHeigth}
                    onChange={(e: any) => onChangeText(e, row, index)}
                    disabled={disableStudy}
                    decimalScale={3}
                    maxValue={1000000}
                    mode={mode}
                  />
                )}
              </>
            </SpreadSheetCell>
          );
        },
      },
      {
        name: "neckWidth",
        type: "numericInput",
        label: "Neck Width",
        hide: !diffuserGrillerNeckSize,
        decimalScale: 3,
        maxValue: 1000000,
        disabled: disableStudy,
        mode: mode,
        thousandSeparator: false,
      },
      {
        name: "balanceDamperMissing",
        type: "checkBox",
        align: "center",
        label: "Balance Damper Missing",
        disabled: disableStudy,
        mode: mode,
      },
      {
        name: "akColumn",
        label: `AK Factor/ ${systemOfMeasurement.get("sqft")}`,
        type: "custom",
        render: (
          row: IOutletMasterItem,
          col: ISpreadsheetColumn,
          index: number
        ) => {
          return (
            <SpreadSheetCell>
              <>
                <SpreadsheetNumericInput2
                  value={row.akColumn}
                  onChange={(e: any) => updateActualCFM(e, row, index)}
                  name="akColumn"
                  decimalScale={3}
                  maxValue={10}
                  disabled={disableStudy}
                  mode={mode}
                />
              </>
            </SpreadSheetCell>
          );
        },
      },
      {
        name: "meterDeviceId",
        label: "Meter / Device",
        type: "select",
        items: meterDeviceKeyValue,
        disabled: disableStudy,
        mode: mode,
      },
      {
        name: "requiredCFM",
        type: "custom",
        label: `Required ${systemOfMeasurement.get("cfm")}`,
        showTotals: true,
        render: (
          row: IOutletMasterItem,
          col: ISpreadsheetColumn,
          index: number
        ) => {
          return (
            <SpreadSheetCell>
              <SpreadsheetNumericInput2
                value={row.requiredCFM}
                onChange={(e: any) => updateNumbers(e, row, index)}
                name="requiredCFM"
                decimalScale={3}
                maxValue={1000000000}
                disabled={row.nl || disableStudy}
                mode={mode}
                thousandSeparator={true}
              />
            </SpreadSheetCell>
          );
        },
      },
      {
        name: "nl",
        type: "checkBox",
        align: "center",
        label: "Not Listed",
        disabled: disableStudy,
        mode: mode,
        onChange: async (
          row: IOutletMasterItem,
          e: any,
          index: number
        ): Promise<void> => {
          await updateTotal(e, row, index);
        },
      },
      {
        name: "actio1",
        type: "custom",
        render: (
          row: IOutletMasterItem,
          col: ISpreadsheetColumn,
          index: number
        ) => {
          return (
            <SpreadSheetCell>
              {mode === "read&Write" ? (
                <Stack direction="row">
                  <SpreadSheetActionButton
                    type="delete"
                    tooltip="Delete this item"
                    onClick={() => deleteItem(row)}
                    disabled={disableStudy}
                  />
                  <SpreadSheetActionButton
                    type="copy"
                    tooltip="Copy this item"
                    onMouseUp={(e: any) => duplicateItem(row, index)}
                    disabled={disableStudy}
                  />
                </Stack>
              ) : (
                <></>
              )}
            </SpreadSheetCell>
          );
        },
      },
    ];
    return bb;
  }, [
    diffuserGrilleModel,
    diffuserGrillerNeckSize,
    items,
    log,
    meterDeviceKeyValue,
    mode,
    disableStudy,
    setItems,
    updateActualCFM,
    updateNumbers,
    updateOutletMaster,
    updateTotal,
  ]);

  const addOutletMasterItems = async () => {
    if (items.length !== 0) {
      const lastItem = items[items.length - 1];

      const newItem: IOutletMasterItem = {
        ...lastItem,
        order: lastItem?.order ?? 0 + 1,
        outletMasterId: outletMaster.id,
        meterDevice: lastItem.meterDevice,
      };

      try {
        const res = await outletMasterItemsService.addItem(newItem);
        const newItems = [...items];
        newItems.push(res.data);
        setItems(newItems);

        await updateOutletMaster();
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      }
    } else {
      const newItem: any = {
        order: 1,
        device: "Hood",
        outletMasterId: outletMaster.id,
      };

      try {
        const res = await outletMasterItemsService.addItem(newItem);
        setItems([res.data]);
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      }
    }
  };

  return (
    <>
      <Toolbar
        sx={{
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 },
        }}
      >
        {!disableStudy && (
          <FormActionButton
            onClick={addOutletMasterItems}
            size="small"
            text="Add"
            type={"add"}
          />
        )}
      </Toolbar>

      <Spreadsheet
        items={items}
        setItems={setItems}
        cols={columns}
        defaultRowPerPage={25}
        totals={{
          requiredCFM: outletMaster.totalRequiredCFM,
        }}
        showRowTotals={true}
        showSkeleton={isLoading}
      />
    </>
  );
};

export default OutletMasterGrid;
