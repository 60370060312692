import { Stack, Typography, Button } from "@mui/material";
import React, { useState } from "react";
import { FormCancelButton, FormCheckBox } from "src/components/formControls";
import Popup from "src/components/Popup";
import useForm from "src/hooks/useForm";
import { useLog } from "src/hooks";
import { IUploadDocumentTech } from "src/ts/interfaces";
import userDocumentService from "src/services/userDocumentService";

interface IDisabledUserPopupProps {
  showAddPhotoModal: boolean;
  setShowAddPhotoModal: (val: boolean) => void;
  row: IUploadDocumentTech | undefined;
  setRefreshPage: (val: boolean) => void;
  refreshPage: boolean;
}
const initialValue = {
  checkInfo: false,
};

const AddNewPhotoPopUp = ({
  showAddPhotoModal,
  setShowAddPhotoModal,
  row,
  setRefreshPage,
  refreshPage,
}: IDisabledUserPopupProps) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { values, handleInputChange, resetForm } = useForm(
    initialValue,
    false,
    []
  );

  const { log } = useLog();
  const handleClose = () => {
    setShowAddPhotoModal(false);
    resetForm();
  };

  const saveFile = async (
    file: File,
    fileType: string,
    userId: string,
    technicianId: string
  ) => {
    try {
      setIsSubmitting(true);

      await userDocumentService.uploadUserFile({
        documentType: fileType,
        content: file,
        technicianId: technicianId,
        userId: userId,
      });
      setRefreshPage(!refreshPage);
    } catch (error: any) {
      log.error(error?.message?.exceptionMessage ?? "Something went wrong");
    } finally {
      setIsSubmitting(false);
    }
  };

  const handlePhotoFileAccepted = async (file: File) => {
    await saveFile(file, "photo", row?.userId ?? "", row?.technicianId ?? "");
  };

  const onChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0] || null;
    if (file !== null) {
      handlePhotoFileAccepted(file);
      setShowAddPhotoModal(false);
      resetForm();
    }
  };

  return (
    <Popup
      title="Recommendations"
      openPopup={showAddPhotoModal}
      setOpenPopup={setShowAddPhotoModal}
      onClose={handleClose}
      size="sm"
    >
      <>
        <>
          <Typography>
            The picture you upload should be a presentable picture that
            represents you and your company with class. Pictures uploaded that
            encompass T-shirts with advertisement other than shirts carrying
            your companies logo and name or damaged and ripped shirts will not
            be allowed. Once this picture is uploaded you will not be able to
            remove it and it will cause a delay in receiving your certification.
          </Typography>
          <br />
          <Typography>
            The picture you submit will appear on your certification and become
            part of every report you submit. AMP's is strict with requiring a
            business like professional image for you and the company you own or
            work for.
          </Typography>
          <br />
          <Typography mb={5}>
            As a bare minimum we recommend every technician upload their image
            while they are well groomed, wearing a button down or polo shirt and
            it is highly recommended that you smile for the picture you intend
            on submitting.
          </Typography>
        </>
      </>
      <Stack direction="row" spacing={3} justifyContent="flex-end">
        <FormCheckBox
          name="checkInfo"
          label="Check here to indicate you have read and agree to the recomendations for the technician's photo."
          value={values.checkInfo}
          onChange={handleInputChange}
        />

        <Button
          variant="contained"
          aria-label="upload picture"
          component="label"
          disabled={!values.checkInfo}
          sx={{ textAlign: "center" }}
        >
          <input hidden accept="image/*" type="file" onChange={onChangeInput} />
          <Typography>Add Photo</Typography>
        </Button>

        <FormCancelButton onClick={handleClose} isSubmitting={isSubmitting} />
      </Stack>
    </Popup>
  );
};

export default AddNewPhotoPopUp;
