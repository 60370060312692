import {
  CardContent,
  Grid,
  Divider as MuiDivider,
  Card as MuiCard,
  Stack,
} from "@mui/material";
import { spacing } from "@mui/system";
import styled from "@emotion/styled";
import { useState } from "react";
import { useAuth, useEffectOnce } from "src/hooks";
import { IProjectReportInfectiousComplianceControl } from "src/ts/interfaces";
import { useNavigate, useParams } from "react-router-dom";
import projectReportService from "src/services/study/projectReportService";
import {
  IProjectReport,
  ProjectDTO,
} from "src/ts/interfaces/project/projectDto";
import getProjectSingleService from "src/services/getProjectSingleService";
import CompleteStudyPopup from "../components/CompleteStudyPopup";
import { steps } from "./const/const";
import coilsService from "src/services/study/coilsService";
import { ICoils } from "src/ts/interfaces/study/coils";
import { FooterButtons } from "../components/FooterButtons";
import StudyWizard from "../components/StudyWizard";
import HeaderStudyPage from "src/components/page/HeaderStudyPage";

const Divider = styled(MuiDivider)(spacing);
const Card = styled(MuiCard)(spacing);

const CoilsStep4 = () => {
  const params = useParams<{ id: string }>();
  const id = parseInt(params?.id === undefined ? "0" : params?.id);
  const navigate = useNavigate();
  const { user } = useAuth();

  const [coils, setCoils] = useState<ICoils | null>();
  const [parentStudy, setParentStudy] = useState<IProjectReport | null>();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [project, setProject] = useState<ProjectDTO | null>();
  const [projectReport, setProjectReport] = useState<IProjectReport | null>();
  const [infectiousComplianceControl, setInfectiousComplianceControl] =
    useState<IProjectReportInfectiousComplianceControl | null>(null);
  const getParentReportIsComplete = async (
    reportId: number,
    reportType: string
  ) => {
    try {
      const res = await projectReportService.report(reportId, reportType);
      setParentStudy(res.data);
    } catch (error) {}
  };

  useEffectOnce(() => {
    const getData = async () => {
      const coilsResponse = await coilsService.getById(id);
      setCoils(coilsResponse.data);
      await getParentReportIsComplete(
        coilsResponse.data.id,
        coilsResponse.data.reportTypeId === 4 ? "COTD" : "COAH"
      );
      await getProject(coilsResponse.data.projectId);
      await getProjectReport(
        coilsResponse.data.id,
        coilsResponse.data.reportTypeId,
        coilsResponse.data.projectId
      );
      await getInfectiousControlData(coilsResponse.data.id);
    };
    if (id > 0) getData();
  });

  const getInfectiousControlData = async (reportId: number) => {
    try {
      const res = await projectReportService.getInfectiousControl(reportId);
      setInfectiousComplianceControl(res.data);
    } catch (error: any) {
      //log.error(error?.message?.exceptionMessage ?? "Something went wrong");
    }
  };

  const getProjectReport = async (
    reportId: number,
    reportTypeId: number,
    projectId: number
  ) => {
    const projectReportResposne = await projectReportService.projectReport(
      reportId,
      reportTypeId,
      projectId
    );
    setProjectReport(projectReportResposne.data);
  };

  const getProject = async (projectId: number) => {
    const projectResponse = await getProjectSingleService.single(projectId);
    setProject(projectResponse.data);
  };

  const previousStepHandler = async () => {
    navigate(`/studies/Coils/step3/${id}/`);
  };

  return (
    <>
      <HeaderStudyPage
        headerStudiesPage={{
          code: coils?.reportTypeId === 4 ? "COTD" : "COAH",
          system: coils?.systemField,
          id: id,
        }}
        parentText="Project"
        parentLink="/"
      />
      <Divider my={6} />

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card mb={1}>
            <CardContent>
              <StudyWizard
                steps={steps}
                activeStep={3}
                projectId={coils?.projectId}
                reportId={id}
                reportTypeId={coils?.reportTypeId}
              ></StudyWizard>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card mb={1}>
            <CardContent>
              <Grid container spacing={5}>
                <Grid item xs={12}>
                  {project && projectReport && coils && (
                    <CompleteStudyPopup
                      isDialogOpen={isDialogOpen}
                      setIsDialogOpen={setIsDialogOpen}
                      project={project}
                      projectReport={projectReport}
                      completed={coils?.isComplete}
                      infectiousComplianceControl={infectiousComplianceControl}
                      parentStudy={parentStudy ?? null}
                    />
                  )}
                </Grid>
                <Grid item xs={12}>
                  <Stack
                    direction="row"
                    spacing={2}
                    justifyContent="center"
                    alignItems="center"
                  >
                    <FooterButtons
                      activeStep={3}
                      stepsCount={steps.length}
                      projectId={coils?.projectId ?? 0}
                      companyId={user?.companyId}
                      isSaving={false}
                      disabled={false}
                      reportName="Coil AHU Mixed Air Report"
                      previousStepHandler={previousStepHandler}
                      isComplete={coils?.isComplete ?? true}
                    />
                  </Stack>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default CoilsStep4;
