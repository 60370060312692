import axios from "src/utils/axios";
import { IEnergyCombinedAverage } from "src/ts/interfaces/catalogs";

const getByEnergyCombinedAverageId = (energyCombinedAverageId: number) => {
  return axios.get<IEnergyCombinedAverage>(
    `EnergyCombinedAverage/Average?EnergyCombinedAverageId=${energyCombinedAverageId}`
  );
};

const getByEnergyCombinedAverageById = (energyCombinedAverageId: number) => {
  return axios.get<IEnergyCombinedAverage>(
    `EnergyCombinedAverage?EnergyCombinedAverageId=${energyCombinedAverageId}`
  );
};

const getByReportIdAndType = (reportId: number, typeId: number) => {
  return axios.get<IEnergyCombinedAverage[]>(
    `EnergyCombinedAverage/Report?reportId=${reportId}&reportType=${typeId}`
  );
};

const add = (energyCombinedAverage: IEnergyCombinedAverage) => {
  return axios.post<IEnergyCombinedAverage>(
    `EnergyCombinedAverage`,
    energyCombinedAverage
  );
};

const updateAll = (energyCombinedAverageItems: IEnergyCombinedAverage[]) => {
  return axios.put(`EnergyCombinedAverage`, energyCombinedAverageItems);
};

const update = (id: number, energyCombinedAverage: IEnergyCombinedAverage) => {
  return axios.put<IEnergyCombinedAverage>(
    `EnergyCombinedAverage/${id}`,
    energyCombinedAverage
  );
};

const remove = (id: number) => {
  return axios.delete(`EnergyCombinedAverage/${id}`);
};

const energyCombinedAverageService = {
  getByEnergyCombinedAverageId,
  getByReportIdAndType,
  add,
  updateAll,
  remove,
  update,
  getByEnergyCombinedAverageById,
};

export default energyCombinedAverageService;
