import { IRoleAlias } from "../ts/interfaces";
import axios from "../utils/axios";

const getRolesCanView = () => {
  return axios.get<IRoleAlias[]>("role/rolesCanView");
};

const hasTechnicianRole = (userId: string) => {
  return axios.get<boolean>(`Role/HasTechnicianRoleByUser?userId=${userId}`);
};

const roleService = {
  getRolesCanView,
  hasTechnicianRole,
};

export default roleService;
