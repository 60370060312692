import axios from "../utils/axios";
import { IUploadCommentFile } from "../ts/interfaces";

const uploadCommentFile = async (file: IUploadCommentFile) => {
  let bodyFormData = new FormData();
  bodyFormData.append("fileForUpload[0]", file.content);
  bodyFormData.append("key[id]", file.id.toString());

  return await axios({
    method: "post",
    url: "/CommentDocument/UploadDeficiencies",
    data: bodyFormData,
    headers: { "Content-Type": "multipart/form-data" },
  });
};

const deleteCommentFile = async (id: number) => {
  return axios.delete(`CommentDocument/${id}`);
};

const downloadCommentFile = async (id: number) => {
  return axios.get(`CommentDocument/file?id=${id}`);
};

const commentDocumentService = {
  uploadCommentFile,
  downloadCommentFile,
  deleteCommentFile,
};

export default commentDocumentService;
