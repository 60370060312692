import {
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  Chip as MuiChip,
} from "@mui/material";
import { SpacingProps, spacing, styled } from "@mui/system";
import React, { useState } from "react";
import { FormButton, FormLabel, FormText } from "src/components/formControls";
import { VisibilityIcon, VisibilityOffIcon } from "src/components/icons";
import { ICoupon } from "src/ts/interfaces";

interface Props {
  validateCoupon: (coupon: string) => Promise<void>;
  isLoadingPay: boolean;
  dataCoupon?: ICoupon;
}

interface ChipProps extends SpacingProps {
  component?: React.ElementType;
  href?: string;
  icon?: JSX.Element | null;
}
const Chip = styled(MuiChip)<ChipProps>(spacing);

function ApplyCoupon({ validateCoupon, isLoadingPay, dataCoupon }: Props) {
  const [coupon, setCoupon] = useState("");
  const handleChangeCoupon = (e: any) => {
    setCoupon(e.target.value);
  };
  const handleClickShowPassword = () => setShowCouponText((show) => !show);
  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const handleValidateCoupon = async () => {
    await validateCoupon(coupon);
  };
  const [showCouponText, setShowCouponText] = useState(false);
  return (
    <React.Fragment>
      <Grid container sx={{ marginTop: "10px", marginBottom: "10px" }}>
        <Grid
          item
          xs={4}
          textAlign={"right"}
          marginRight={"5px"}
          marginTop={"7px"}
        >
          <FormLabel text="Promotional code:" />
        </Grid>
        <Grid item xs={3} sx={{ marginRight: "5px" }}>
          <Stack direction="row">
            <FormText
              name="codeCoupon"
              label="Enter your code:"
              value={coupon}
              onChange={handleChangeCoupon}
              type={showCouponText ? "text" : "password"}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showCouponText ? (
                      <VisibilityIcon />
                    ) : (
                      <VisibilityOffIcon />
                    )}
                  </IconButton>
                </InputAdornment>
              }
            />
          </Stack>
        </Grid>
        {dataCoupon && (
          <Grid item xs={1} paddingTop={"5px"}>
            <Chip
              label={`${dataCoupon.value}%`}
              color="success"
              m={1}
              size="small"
            />
          </Grid>
        )}
        <Grid item xs={3} paddingTop={"5px"} textAlign={"left"}>
          <FormButton
            text="Validate Coupon"
            onClick={handleValidateCoupon}
            size="small"
            variant="outlined"
            isSubmitting={isLoadingPay}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default ApplyCoupon;
