import axios from "src/utils/axios";
import { IPumpSystemStrainerInstalledDTO } from "src/ts/interfaces";

const get = () => {
  return axios.get<IPumpSystemStrainerInstalledDTO[]>(
    "PumpSystemStrainerInstalled"
  );
};

const pumpSystemStrainerInstalledService = { get };
export default pumpSystemStrainerInstalledService;
