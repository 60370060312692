import { ChillerStudyProvider } from "../context/ChillerStudyContext";
import ChillerStep2 from "./ChillerStep2";
const Index = () => {
  return (
    <ChillerStudyProvider>
      <ChillerStep2 />
    </ChillerStudyProvider>
  );
};

export default Index;
