import React, { useEffect, useState } from "react";
import { useLoading, useLog, usePermissions } from "src/hooks";
import { IKeyValue, IPartnerHistory } from "src/ts/interfaces";
import { ColumnType } from "src/types/enhancedTable";
import LocalEnhancedTable from "src/components/localTable/LocalTable";
import { WalletService } from "src/services";
import { currencyformatter } from "src/utils/format";
import { LocalAtmIcon } from "src/components/icons";
import {
  Button,
  Card,
  CardContent,
  Grid,
  IconButton,
  Chip as MuiChip,
} from "@mui/material";
import { FormSelect } from "src/components/formControls";
import { SpacingProps, spacing, styled } from "@mui/system";
import DialogMessagePopup from "src/components/DialogMessagePopup";
import PasswordConfirmModal from "src/components/modals/PasswordConfirmModal";
import FileUtils from "src/utils/file";
import SettlementBalance from "./SettlementBalance";
import { Permission as PermissionTypes } from "src/ts/enums";

const PartnerBalance = () => {
  const { isLoading, startRequest, endRequest } = useLoading();
  const [refreshGrid, setRefreshGrid] = useState(false);
  const [showSettleModal, setShowSettleModal] = useState(false);
  const [year, setYear] = useState<IKeyValue<string, string>>();
  const [years, setYears] = useState<IKeyValue<string, string>[]>([]);
  const [partnetHistory, setPartnetHistory] = useState<IPartnerHistory[]>([]);
  const [currentDate, setCurrentDate] = useState<IKeyValue<string, string>>();
  const [month, setMonth] = useState<string>("");
  const [isPasswordDialogOpen, setIsPasswordDialogOpen] = useState(false);
  const [settleHistory, setSettleHistory] = useState(false);
  const { log } = useLog();
  const { fullAccess } = usePermissions(PermissionTypes.Wallet_Admin_Center);

  interface ChipProps extends SpacingProps {
    component?: React.ElementType;
    href?: string;
    icon?: JSX.Element | null;
  }

  const Chip = styled(MuiChip)<ChipProps>(spacing);

  useEffect(() => {
    const max = new Date().getFullYear();
    const min = 2020;
    const years = [];

    for (var i = max; i >= min; i--) {
      const item: IKeyValue<string, string> = {
        value: i.toString(),
        key: i.toString(),
      };
      years.push(item);
    }
    setYears(years);
    setYear(years[0]);
    const itemCuttent: IKeyValue<string, string> = {
      value: new Date().getMonth().toString(),
      key: years[0].key,
    };
    setCurrentDate(itemCuttent);
  }, []);

  useEffect(() => {
    const getData = async () => {
      try {
        startRequest("PartnerMetrics");
        const request = await WalletService.getPartnerMetrics(
          year?.value ?? ""
        );
        setPartnetHistory(request.data);
        endRequest("PartnerMetrics");
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      }
    };

    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [year, refreshGrid]);

  const handleSettle = (item: any) => {
    setShowSettleModal(true);
    setMonth(item);
  };

  const handleSettleHistory = () => {
    setSettleHistory(!settleHistory);
  };

  const handleSaveSettle = async () => {
    try {
      const request = await WalletService.settlePartners(
        year?.value ?? "",
        month
      );
      setRefreshGrid(!refreshGrid);
      log.success("Settle was successfully");
      await WalletService.generatePdfSettle(request.data.id);
      const pdf = await WalletService.getPdfSettle(request.data.id);
      FileUtils.downloadFile(pdf.data, "Settle" + request.data.id + ".pdf");
    } catch (error: any) {
      log.error(error?.message?.exceptionMessage ?? "Something went wrong");
    }
  };
  const handleInputChange = (item: any) => {
    setYear(item.target);
  };

  const getcolumns = (
    itemCurrent: IKeyValue<string, string> | undefined,
    year: IKeyValue<string, string> | undefined
  ): ColumnType[] => [
    {
      id: "name",
      label: "Team Member",
      type: "string",
      sort: false,
      callback: (row: IPartnerHistory) => {
        return row.name;
      },
    },
    {
      id: "january",
      label: "January",
      type: "custom",
      sort: false,
      callback: (row: IPartnerHistory) => {
        return (
          <>
            {row.name === "Funds Status" ? (
              row.january === 1 ? (
                "Settled"
              ) : (
                fullAccess && (
                  <IconButton
                    aria-label="details"
                    onClick={() => handleSettle("1")}
                  >
                    <LocalAtmIcon />
                  </IconButton>
                )
              )
            ) : itemCurrent?.key === year?.key && itemCurrent?.value === "0" ? (
              <Chip
                label={currencyformatter.format(row.january)}
                color="primary"
                m={1}
                size="small"
              />
            ) : (
              currencyformatter.format(row.january)
            )}
          </>
        );
      },
    },
    {
      id: "february",
      label: "February",
      type: "custom",
      sort: false,
      callback: (row: IPartnerHistory) => {
        return (
          <>
            {row.name === "Funds Status" ? (
              row.february === 1 ? (
                "Settled"
              ) : (
                fullAccess && (
                  <IconButton
                    aria-label="details"
                    onClick={() => handleSettle("2")}
                  >
                    <LocalAtmIcon />
                  </IconButton>
                )
              )
            ) : itemCurrent?.key === year?.value &&
              itemCurrent?.value === "1" ? (
              <Chip
                label={currencyformatter.format(row.february)}
                color="primary"
                m={1}
                size="small"
              />
            ) : (
              currencyformatter.format(row.february)
            )}
          </>
        );
      },
    },
    {
      id: "march",
      label: "March",
      type: "custom",
      sort: false,
      callback: (row: IPartnerHistory) => {
        return (
          <>
            {row.name === "Funds Status" ? (
              row.march === 1 ? (
                "Settled"
              ) : (
                fullAccess && (
                  <IconButton
                    aria-label="details"
                    onClick={() => handleSettle("3")}
                  >
                    <LocalAtmIcon />
                  </IconButton>
                )
              )
            ) : itemCurrent?.key === year?.value &&
              itemCurrent?.value === "2" ? (
              <Chip
                label={currencyformatter.format(row.march)}
                color="primary"
                m={1}
                size="small"
              />
            ) : (
              currencyformatter.format(row.march)
            )}
          </>
        );
      },
    },
    {
      id: "april",
      label: "April",
      type: "custom",
      sort: false,
      callback: (row: IPartnerHistory) => {
        return (
          <>
            {row.name === "Funds Status" ? (
              row.april === 1 ? (
                "Settled"
              ) : (
                fullAccess && (
                  <IconButton
                    aria-label="details"
                    onClick={() => handleSettle("4")}
                  >
                    <LocalAtmIcon />
                  </IconButton>
                )
              )
            ) : itemCurrent?.key === year?.value &&
              itemCurrent?.value === "3" ? (
              <Chip
                label={currencyformatter.format(row.april)}
                color="primary"
                m={1}
                size="small"
              />
            ) : (
              currencyformatter.format(row.april)
            )}
          </>
        );
      },
    },
    {
      id: "may",
      label: "May",
      type: "custom",
      sort: false,
      callback: (row: IPartnerHistory) => {
        return (
          <>
            {row.name === "Funds Status" ? (
              row.may === 1 ? (
                "Settled"
              ) : (
                fullAccess && (
                  <IconButton
                    aria-label="details"
                    onClick={() => handleSettle("5")}
                  >
                    <LocalAtmIcon />
                  </IconButton>
                )
              )
            ) : itemCurrent?.key === year?.value &&
              itemCurrent?.value === "4" ? (
              <Chip
                label={currencyformatter.format(row.may)}
                color="primary"
                m={1}
                size="small"
              />
            ) : (
              currencyformatter.format(row.may)
            )}
          </>
        );
      },
    },
    {
      id: "june",
      label: "June",
      type: "custom",
      sort: false,
      callback: (row: IPartnerHistory) => {
        return (
          <>
            {row.name === "Funds Status" ? (
              row.june === 1 ? (
                "Settled"
              ) : (
                fullAccess && (
                  <IconButton
                    aria-label="details"
                    onClick={() => handleSettle("6")}
                  >
                    <LocalAtmIcon />
                  </IconButton>
                )
              )
            ) : itemCurrent?.key === year?.value &&
              itemCurrent?.value === "5" ? (
              <Chip
                label={currencyformatter.format(row.june)}
                color="primary"
                m={1}
                size="small"
              />
            ) : (
              currencyformatter.format(row.june)
            )}
          </>
        );
      },
    },
    {
      id: "july",
      label: "July",
      type: "custom",
      sort: false,
      callback: (row: IPartnerHistory) => {
        return (
          <>
            {row.name === "Funds Status" ? (
              row.july === 1 ? (
                "Settled"
              ) : (
                fullAccess && (
                  <IconButton
                    aria-label="details"
                    onClick={() => handleSettle("7")}
                  >
                    <LocalAtmIcon />
                  </IconButton>
                )
              )
            ) : itemCurrent?.key === year?.value &&
              itemCurrent?.value === "6" ? (
              <Chip
                label={currencyformatter.format(row.july)}
                color="primary"
                m={1}
                size="small"
              />
            ) : (
              currencyformatter.format(row.july)
            )}
          </>
        );
      },
    },
    {
      id: "august",
      label: "August",
      type: "custom",
      sort: false,
      callback: (row: IPartnerHistory) => {
        return (
          <>
            {row.name === "Funds Status" ? (
              row.august === 1 ? (
                "Settled"
              ) : (
                fullAccess && (
                  <IconButton
                    aria-label="details"
                    onClick={() => handleSettle("8")}
                  >
                    <LocalAtmIcon />
                  </IconButton>
                )
              )
            ) : itemCurrent?.key === year?.value &&
              itemCurrent?.value === "7" ? (
              <Chip
                label={currencyformatter.format(row.august)}
                color="primary"
                m={1}
                size="small"
              />
            ) : (
              currencyformatter.format(row.august)
            )}
          </>
        );
      },
    },
    {
      id: "september",
      label: "September",
      type: "custom",
      sort: false,
      callback: (row: IPartnerHistory) => {
        return (
          <>
            {row.name === "Funds Status" ? (
              row.september === 1 ? (
                "Settled"
              ) : (
                fullAccess && (
                  <IconButton
                    aria-label="details"
                    onClick={() => handleSettle("9")}
                  >
                    <LocalAtmIcon />
                  </IconButton>
                )
              )
            ) : itemCurrent?.key === year?.value &&
              itemCurrent?.value === "8" ? (
              <Chip
                label={currencyformatter.format(row.september)}
                color="primary"
                m={1}
                size="small"
              />
            ) : (
              currencyformatter.format(row.september)
            )}
          </>
        );
      },
    },
    {
      id: "october",
      label: "October",
      type: "custom",
      sort: false,
      callback: (row: IPartnerHistory) => {
        return (
          <>
            {row.name === "Funds Status" ? (
              row.october === 1 ? (
                "Settled"
              ) : (
                fullAccess && (
                  <IconButton
                    aria-label="details"
                    onClick={() => handleSettle("10")}
                  >
                    <LocalAtmIcon />
                  </IconButton>
                )
              )
            ) : itemCurrent?.key === year?.value &&
              itemCurrent?.value === "9" ? (
              <Chip
                label={currencyformatter.format(row.october)}
                color="primary"
                m={1}
                size="small"
              />
            ) : (
              currencyformatter.format(row.october)
            )}
          </>
        );
      },
    },
    {
      id: "november",
      label: "November",
      type: "custom",
      sort: false,
      callback: (row: IPartnerHistory) => {
        return (
          <>
            {row.name === "Funds Status" ? (
              row.november === 1 ? (
                "Settled"
              ) : (
                fullAccess && (
                  <IconButton
                    aria-label="details"
                    onClick={() => handleSettle("11")}
                  >
                    <LocalAtmIcon />
                  </IconButton>
                )
              )
            ) : itemCurrent?.key === year?.value &&
              itemCurrent?.value === "10" ? (
              <Chip
                label={currencyformatter.format(row.november)}
                color="primary"
                m={1}
                size="small"
              />
            ) : (
              currencyformatter.format(row.november)
            )}
          </>
        );
      },
    },
    {
      id: "december",
      label: "December",
      type: "custom",
      sort: false,
      callback: (row: IPartnerHistory) => {
        return (
          <>
            {row.name === "Funds Status" ? (
              row.december === 1 ? (
                "Settled"
              ) : (
                fullAccess && (
                  <IconButton
                    aria-label="details"
                    onClick={() => handleSettle("12")}
                  >
                    <LocalAtmIcon />
                  </IconButton>
                )
              )
            ) : itemCurrent?.key === year?.value &&
              itemCurrent?.value === "11" ? (
              <Chip
                label={currencyformatter.format(row.december)}
                color="primary"
                m={1}
                size="small"
              />
            ) : (
              currencyformatter.format(row.december)
            )}
          </>
        );
      },
    },
  ];

  let columns = getcolumns(currentDate, year);

  return (
    <>
      {!settleHistory && (
        <Grid>
          <CardContent>
            <Grid container spacing={12}>
              <Grid item xs={9}></Grid>
              <Grid item xs={2}>
                <Button
                  fullWidth
                  variant="outlined"
                  color="primary"
                  sx={{
                    color: "black",
                    borderColor: "black",
                  }}
                  onClick={handleSettleHistory}
                >
                  <span style={{ fontWeight: "bold" }}>H&nbsp;</span> Settle
                  History
                </Button>
              </Grid>
            </Grid>
          </CardContent>
        </Grid>
      )}
      {!settleHistory && (
        <Card>
          <CardContent>
            <Grid>
              <Grid container spacing={12}>
                <Grid item xs={2}>
                  <FormSelect
                    name="year"
                    label="Year:"
                    value={year?.value ?? ""}
                    onChange={handleInputChange}
                    options={years}
                    showSkeleton={isLoading}
                  />
                </Grid>
              </Grid>
              <LocalEnhancedTable<IPartnerHistory>
                refreshGrid={refreshGrid}
                columns={columns}
                data={partnetHistory}
                showSkeleton={false}
                defaultRowPerPage={10}
              />
            </Grid>
          </CardContent>
        </Card>
      )}
      {settleHistory && (
        <Grid>
          <CardContent>
            <Grid container spacing={12}>
              <Grid item xs={9}></Grid>
              <Grid item xs={3}>
                <Button
                  fullWidth
                  variant="outlined"
                  color="primary"
                  sx={{
                    color: "black",
                    borderColor: "black",
                  }}
                  onClick={handleSettleHistory}
                >
                  <span style={{ fontWeight: "bold" }}>-&nbsp;</span> Current
                  Settlement Balance
                </Button>
              </Grid>
            </Grid>
          </CardContent>
          <Grid item xs={12}>
            <SettlementBalance></SettlementBalance>
          </Grid>
        </Grid>
      )}
      <DialogMessagePopup
        title={"Settle pending funds"}
        text={`
        This action will Settle the Team's pending funds and will reset the Unsettled 
        Balance of funds for the Team Members. 

        Are you certain you want to continue?
        `}
        showPopup={showSettleModal}
        setShowPopup={setShowSettleModal}
        onSave={() => {
          setShowSettleModal(false);
          setIsPasswordDialogOpen(true);
        }}
        isSubmitting={false}
      />
      <PasswordConfirmModal
        isDialogOpen={isPasswordDialogOpen}
        setIsDialogOpen={setIsPasswordDialogOpen}
        setIsValidPassword={handleSaveSettle}
      />
    </>
  );
};

export default PartnerBalance;
