import Popup from "src/components/Popup";
import {
  FormButton,
  FormRadioGroup,
  FormText,
  FormTextArea,
} from "src/components/formControls";
import { Stack } from "@mui/material";
import { DISPUTEOPTION, complaintDisputeOption } from "src/constants";

import { IDisputeCreate } from "src/ts/interfaces/dispute";
import { useAsyncQuery, useForm, useLog } from "src/hooks";
import disputeService from "src/services/disputeService";
import { Validator } from "src/ts/types";

interface AddDisputeInformationPopUpProps {
  addDisputeEntryShowPopUp: boolean;
  setAddDisputeEntryShowPopUp: (val: boolean) => void;
  setAddDisputeInformationShowPopUp: (val: boolean) => void;
  projectId: number;
  setRefresh: (val: boolean) => void;
  refresh: boolean;
}

const initialValues: IDisputeCreate = {
  details: "",
  projectId: "",
  reason: "",
  type: -1,
  other: null,
};

export default function AddDisputeEntryPopUp({
  addDisputeEntryShowPopUp,
  setAddDisputeEntryShowPopUp,
  setAddDisputeInformationShowPopUp,
  projectId,
  setRefresh,
  refresh,
}: AddDisputeInformationPopUpProps) {
  const { log } = useLog();
  const validate = (fieldValues = values) => {
    let temp: Record<string, string> = { ...errors };

    temp.reason = new Validator(fieldValues, "reason")
      .isRequired("Reason is required.")
      .toString();

    temp.details = new Validator(fieldValues, "details")
      .isRequired("Details are required.")
      .maxLength(
        800,
        `Only 800 character are allowed (${values.details.length}).`
      )
      .toString();

    if (values.details.length > 800) {
      log.warning("Only 800 characters are allowed for details section.");
    }

    temp.type = new Validator(fieldValues, "type")
      .greatThan(0, "Select a Complaint.")
      .toString();

    temp.other = new Validator(fieldValues, "other")
      .validateIf(
        fieldValues.type === DISPUTEOPTION.OTHER && fieldValues.other === ""
      )
      .isRequired("Comments are required.")
      .toString();

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };
  const { values, handleInputChange, setErrors, errors } = useForm(
    initialValues,
    false,
    validate
  );

  const { execute: executeCreate, isLoading } = useAsyncQuery(
    disputeService.postCreateDispute,
    {
      onSuccess: () => {
        setRefresh(!refresh);
        setAddDisputeEntryShowPopUp(false);
        setAddDisputeInformationShowPopUp(false);
      },
    }
  );

  const handlerAddDispute = async () => {
    if (!validate()) return;
    executeCreate({
      reason: values.reason,
      details: values.details,
      type: Number(values.type),
      projectId: projectId.toString(),
      other: values.other,
    });
  };

  return (
    <>
      <Popup
        title={"Add new dispute"}
        openPopup={addDisputeEntryShowPopUp}
        setOpenPopup={setAddDisputeEntryShowPopUp}
        onClose={() => {
          setAddDisputeEntryShowPopUp(false);
        }}
        size="md"
      >
        <>
          <Stack direction={"column"} spacing={3}>
            <FormText
              label="Reason(s)"
              name="reason"
              value={values.reason}
              onChange={handleInputChange}
              error={errors.reason}
            />
            <FormTextArea
              label="Details"
              name="details"
              value={values.details}
              onChange={handleInputChange}
              error={errors.details}
            />
          </Stack>
          <Stack mt={2}>
            <FormRadioGroup
              name="type"
              label="Complaint"
              value={values.type}
              onChange={handleInputChange}
              items={complaintDisputeOption}
              error={errors.type}
            />
          </Stack>
          {values.type === DISPUTEOPTION.OTHER && (
            <Stack mt={2}>
              <FormText
                name="other"
                value={values.other}
                onChange={handleInputChange}
                label="Comments"
                error={errors.other}
              />
            </Stack>
          )}
        </>
        <Stack direction="row" spacing={3}>
          <FormButton
            size="small"
            text="Cancel"
            variant="outlined"
            onClick={() => {
              setAddDisputeEntryShowPopUp(false);
              setAddDisputeInformationShowPopUp(false);
            }}
          />
          <FormButton
            size="small"
            text="Add Dispute"
            color="primary"
            onClick={() => {
              handlerAddDispute();
            }}
            isSubmitting={isLoading}
          />
        </Stack>
      </Popup>
    </>
  );
}
