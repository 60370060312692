import React, { useState, useEffect } from "react";
import {
  Card as MuiCard,
  CardContent,
  Chip,
  Divider as MuiDivider,
  Grid,
  Tab,
  Tabs,
  Typography,
  styled,
} from "@mui/material";
import { FormSelect } from "../../components/formControls";
import HeaderPage from "src/components/page/HeaderPage";
import { storageUnitType } from "src/constants";
import { IStorageMetrics } from "src/ts/interfaces/storage";
import { useAsyncQuery, useAuth, useLog } from "src/hooks";
import storageService from "src/services/storageService";

import MemoryDashboardItems from "./MemoryDashboardItems";
import TableStorageCurrentCompany from "./TableStorageCurrentCompany";
import { ICompany } from "src/ts/interfaces";
import { CompanyService } from "src/services";
import { spacing } from "@mui/system";
import TableCompletedProjects from "./TableCompletedProjects";
import TableCompanyDocumentSummary from "./TableCompanyDocumentSummary";
import TableUserDocumentSummary from "./TableUserDocumentSummary";
import TableLibraryDocumentSummary from "./TableLibraryDocumentSummary";
import TablePictureLogSummary from "./TablePictureLogSummary";
import TableEquipmentSummary from "./TableEquipmentSummary";

const Card = styled(MuiCard)(spacing);
const Divider = styled(MuiDivider)(spacing);

const MemorySummary = () => {
  const { user } = useAuth();
  const [unitType, setUnitType] = useState("");
  const [selectdTab, setSelectedTab] = useState(1);
  const { log } = useLog();

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  const { execute: getStorageMetric, data: storageMetric } =
    useAsyncQuery<IStorageMetrics>(storageService.storageMetrics);

  const handleInputChange = (e: any) => {
    setUnitType(e.target.value.toString());
    localStorage.setItem("showStorageIn", JSON.stringify(e.target.value));
  };

  const { execute: getCompanyById, data: company } = useAsyncQuery<ICompany>(
    CompanyService.getById
  );

  useEffect(() => {
    const getData = async () => {
      try {
        const storedUnitType = localStorage.getItem("showStorageIn");
        if (storedUnitType) setUnitType(storedUnitType.toString());
        else setUnitType("1");
        getStorageMetric(user?.companyId);
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
        setUnitType("1");
      }
    };

    const getCompanyData = async () => {
      try {
        await getCompanyById(user?.companyId);
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      }
    };

    getData();
    if (user?.companyId) getCompanyData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <HeaderPage
        title={"Storage Summary"}
        parentText={"Dashboard"}
        parentLink={`/`}
        actionSection={
          <Grid container>
            <Grid item p={2} sx={{ alignItems: "right", width: "120px" }}>
              <FormSelect
                name="unitType"
                label="Show storage in:"
                value={unitType}
                onChange={handleInputChange}
                options={storageUnitType}
                disabled={false}
              />
            </Grid>
          </Grid>
        }
      ></HeaderPage>
      <MemoryDashboardItems unitType={unitType} storageMetric={storageMetric} />
      <Grid item md={4} p={2} pl={2}>
        <Card>
          <CardContent>
            <Grid container>
              <Grid item xs={12}>
                <Typography variant="h6">
                  {"Storage current company"}
                </Typography>
              </Grid>
            </Grid>
            <TableStorageCurrentCompany
              unitType={unitType}
            ></TableStorageCurrentCompany>
          </CardContent>
        </Card>
      </Grid>
      <Grid item md={4} p={2} pl={2}>
        <Card>
          <CardContent>
            <Grid container>
              <Grid item xs={12}>
                <Typography variant="h6">
                  {"Storage current company  "}
                  {company?.headquarters ? (
                    <Chip label={"Headquarters"} color="warning" size="small" />
                  ) : (
                    <Chip label={"Satellite"} color="info" size="small" />
                  )}
                  {`${"  Address: "} ${company?.address}${
                    company?.address2 !== null ? company?.address2 : ""
                  }${","} ${company?.city} ${
                    company?.stateName !== null
                      ? company?.stateName
                      : company?.state?.name
                  }${", "} ${company?.country?.name}`}
                </Typography>
                <Tabs
                  value={selectdTab}
                  onChange={handleChangeTab}
                  aria-label="Buildings"
                >
                  <Tab label="Company Documents" id="0" value={0} />
                  <Tab label="Project Documents" id="1" value={1} />
                  <Tab label="User Documents" id="2" value={2} />
                  <Tab label="Project's Document Library" id="3" value={3} />
                  <Tab label="Project's Picture Log" id="4" value={4} />
                  <Tab label="Equipment Documents" id="5" value={5} />
                </Tabs>
              </Grid>
              <Divider my={3}></Divider>
              {selectdTab === 0 && <TableCompanyDocumentSummary />}
              {selectdTab === 1 && <TableCompletedProjects />}
              {selectdTab === 2 && <TableUserDocumentSummary />}
              {selectdTab === 3 && <TableLibraryDocumentSummary />}
              {selectdTab === 4 && <TablePictureLogSummary />}
              {selectdTab === 5 && <TableEquipmentSummary />}
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </>
  );
};

export default MemorySummary;
