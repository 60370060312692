import axios from "src/utils/axios";
import { IHeadSheetActualSpacePressurizationDTO } from "src/ts/interfaces";

const getByHeadSheetActualId = (id: number) => {
  return axios.get<IHeadSheetActualSpacePressurizationDTO[]>(
    `headSheetActualSpacePressurization?headSheetActualId=${id}`
  );
};

const save = (
  headSheetActualSpacePressurization: IHeadSheetActualSpacePressurizationDTO
) => {
  return axios.post<IHeadSheetActualSpacePressurizationDTO>(
    `headSheetActualSpacePressurization`,
    headSheetActualSpacePressurization
  );
};

const update = (
  id: number,
  headSheetActualSpacePressurization: IHeadSheetActualSpacePressurizationDTO
) => {
  return axios.put<IHeadSheetActualSpacePressurizationDTO>(
    `headSheetActualSpacePressurization/${id}`,
    headSheetActualSpacePressurization
  );
};

const remove = (id: number) => {
  return axios.delete<IHeadSheetActualSpacePressurizationDTO>(
    `headSheetActualSpacePressurization/${id}`
  );
};

const headSheetActualSpacePressurizationService = {
  getByHeadSheetActualId,
  save,
  remove,
  update,
};

export default headSheetActualSpacePressurizationService;
