import axios from "src/utils/axios";
import { IMotorFrameCategory } from "src/ts/interfaces/catalogs";

const getAll = (id: number) => {
  return axios.get<IMotorFrameCategory>("MotorFrameCategory");
};

const frameCategoryService = {
  getAll,
};

export default frameCategoryService;
