import { Stack, Typography, Box } from "@mui/material";
import { FormButton } from "src/components/formControls";
import Popup from "src/components/Popup";
import { FileDownloadIcon } from "../icons";
import { SysAdminDocumentService } from "src/services";
import FileUtils from "src/utils/file";
import { useLog } from "src/hooks";

interface GPSAppModalProps {
  showPunchList: boolean;
  setShowPunchList: (val: boolean) => void;
}

const PunchListPopUp = (props: GPSAppModalProps) => {
  const { showPunchList, setShowPunchList } = props;
  const { log } = useLog();

  const cancelHandler = () => {
    setShowPunchList(false);
  };

  const downloadFile = async () => {
    try {
      const response = await SysAdminDocumentService.getPunchList();
      let fileName = "Punchlist.pdf";

      FileUtils.downloadFile3(response.data, fileName);
      log.success("File downloaded successfully");
    } catch (error: any) {
      log.error(error?.message?.exceptionMessage ?? "Something went wrong");
    }
  };

  return (
    <Popup
      openPopup={showPunchList}
      setOpenPopup={setShowPunchList}
      title={"Information"}
      onClose={cancelHandler}
      size="sm"
    >
      <Stack>
        <Typography align="center">
          <b>Pre-testing AHU Energy Study Site Inspection Walkdown Punchlist</b>
        </Typography>
        <Box mt={2} display="flex" justifyContent="center">
          <FormButton
            startIcon={<FileDownloadIcon />}
            text="Download"
            onClick={downloadFile}
            size="small"
          />
        </Box>
      </Stack>
      <>
        <Stack
          direction="row"
          spacing={2}
          justifyContent="center"
          alignItems="center"
        >
          <FormButton
            onClick={cancelHandler}
            text="Close"
            color="error"
            size="medium"
          />
        </Stack>
      </>
    </Popup>
  );
};

export default PunchListPopUp;
