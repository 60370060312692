import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import {
  CardContent,
  CssBaseline,
  Grid,
  Link,
  Card as MuiCard,
  Typography,
  Divider as MuiDivider,
  Stack,
} from "@mui/material";
import { useAsyncMutation, useAsyncQuery, useLog, useTheme } from "src/hooks";
import { useNavigate, useParams } from "react-router-dom";
import GlobalStyle from "src/components/GlobalStyle";
import { Box, spacing } from "@mui/system";
import { Helmet } from "react-helmet-async";
import {
  FormButton,
  FormNumeric2,
  FormRadioGroup,
  FormTextArea,
} from "src/components/formControls";
import { THEMES } from "src/constants";
import { Check, Close } from "@mui/icons-material";
import { convertUTCDateToLocalDate } from "src/utils/utils";
import DialogMessagePopup from "src/components/DialogMessagePopup";
import RequestTransferTechnicianService from "src/services/RequestTransferTechnicianService";
import requestTechnicianPermissionService from "src/services/requestTechnicianPermissionService";
import { IRequestTechnicianPermission } from "src/ts/interfaces/requestTechnicianPermission";
const Card = styled(MuiCard)(spacing);
const Root = styled.div`
  max-width: 90%;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  display: flex;
  min-height: 100%;
  flex-direction: column;
`;

const initialValues = {
  radioButtonSelect: "1",
};

export const radioInformation = [
  {
    key: "1",
    value: "Hours",
  },
  {
    key: "2",
    value: "Days",
  },
  {
    key: "5",
    value: "Provide permanent permission for this project",
  },
];

function AuthorizeTechnicianPermission() {
  const { theme } = useTheme();
  const { log } = useLog();
  const Divider = styled(MuiDivider)(spacing);
  const navigate = useNavigate();
  let { code } = useParams<{ code: string }>();
  const [confirmModal, setConfirmModal] = useState(false);
  const [rejectModal, setRejectModal] = useState(false);
  const [comments, setComments] = useState("");
  const [statusButton, setStatusButton] = useState("");
  const [permissionUnit, setPermissionUnit] = useState(1);
  const [permissionValue, setPermissionValue] = useState(24);
  const [selectRadioButton, setSelectRadioButton] = useState(
    initialValues.radioButtonSelect
  );

  const { execute: executeLoad, data } =
    useAsyncQuery<IRequestTechnicianPermission>(
      requestTechnicianPermissionService.getCurrentStatus
    );

  const { execute, isSubmitting } = useAsyncMutation(
    requestTechnicianPermissionService.update,
    {
      hideSuccessfulMessage: true,
      onSuccess: (result, request) => {
        setConfirmModal(false);
        setRejectModal(false);
        navigate("/auth/sign-in");
        log.success(`The permission was ${request[1]}ed`);
      },
    }
  );

  useEffect(() => {
    const getData = async () => {
      await executeLoad(code);
    };
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleConfirm = async (status: string) => {
    await execute(code, status, comments, permissionValue, permissionUnit);
  };

  return (
    <Root>
      <CssBaseline />
      <GlobalStyle />
      <Grid container spacing={2} pt={10}>
        <Grid item xs={2}></Grid>
        <Grid item xs={8}>
          <Card mb={1}>
            <CardContent>
              <Helmet title="Confirm" />
              <Link href="https://www.auditmaster.pro/" target="_blank">
                <Box
                  component="img"
                  alt="Logo AMP"
                  src={
                    THEMES.DARK === theme
                      ? "/static/img/AMPWebLogoVectors.svg"
                      : "/static/img/AMPWebLogoVectorsLight.svg"
                  }
                  sx={{
                    marginLeft: "35%",
                    marginRight: "35%",
                    width: "30%",
                    marginBottom: "20px",
                  }}
                />
              </Link>
              <Divider mb={4} />
              {data?.requestStatus !== 1 && (
                <Grid container>
                  <Grid item xs={12}>
                    <Typography variant="h3" pb={2} textAlign={"center"}>
                      This request code is not longer valid
                    </Typography>
                  </Grid>
                </Grid>
              )}
              {data?.requestStatus === 1 && (
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Typography variant="h3" pb={2} textAlign={"center"}>
                      Authorization request for temporarily disabling this AMP
                      Technicians App
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container>
                      <Grid item xs={2} textAlign={"right"}>
                        <Typography variant="subtitle2">
                          Technician/Auditor:
                        </Typography>
                      </Grid>
                      <Grid item xs={10} pl={2}>
                        <Typography>{data?.technicianName}</Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container>
                      <Grid item xs={2} textAlign={"right"}>
                        <Typography display={"inline"} variant="subtitle2">
                          Certification Type:
                        </Typography>
                      </Grid>
                      <Grid item xs={10} pl={2}>
                        {data?.certificationTech &&
                          data?.certificationTech.map((row, indexRow) => {
                            return (
                              <Typography display={"inline"}>
                                {row.certificationId === 3 && (
                                  <Typography color={"primary"}>
                                    Testing & Balancing Technician{" "}
                                    {row.status === false
                                      ? "(In progress)"
                                      : ""}
                                  </Typography>
                                )}
                                {row.certificationId === 6 && (
                                  <Typography color={"green"}>
                                    Energy Auditor / Infectious Building Control{" "}
                                    {row.status === false
                                      ? "(In progress)"
                                      : ""}
                                  </Typography>
                                )}
                              </Typography>
                            );
                          })}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container>
                      <Grid item xs={2} textAlign={"right"}>
                        <Typography display={"inline"} variant="subtitle2">
                          Project Assigned:
                        </Typography>
                      </Grid>
                      <Grid item xs={10} pl={2}>
                        <Typography display={"inline"}>
                          {data?.projectName}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container>
                      <Grid item xs={2} textAlign={"right"}>
                        <Typography display={"inline"} variant="subtitle2">
                          Building:
                        </Typography>
                      </Grid>
                      <Grid item xs={10} pl={2}>
                        <Typography display={"inline"}>
                          {data?.buildingName}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container>
                      <Grid item xs={2} textAlign={"right"}>
                        <Typography display={"inline"} variant="subtitle2">
                          Requested on:
                        </Typography>
                      </Grid>
                      <Grid item xs={10} pl={2}>
                        <Typography display={"inline"}>
                          {convertUTCDateToLocalDate(
                            data?.createdDate.toString()
                          )?.format("MM/DD/YYYY h:mma")}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container>
                      <Grid item xs={2} textAlign={"right"}>
                        <Typography display={"inline"} variant="subtitle2">
                          Comments:
                        </Typography>
                      </Grid>
                      <Grid item xs={10} pl={2}>
                        <Typography display={"inline"}>
                          {data?.technicianComments}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12} textAlign={"center"} spacing={2}>
                    <Divider mb={4} mt={3} />
                    <Stack
                      direction="row"
                      spacing={2}
                      justifyContent="center"
                      alignItems="center"
                    >
                      <FormButton
                        onClick={() => {
                          setStatusButton("reject");
                          setRejectModal(true);
                        }}
                        isSubmitting={isSubmitting}
                        variant="outlined"
                        text="Reject"
                        size="small"
                        startIcon={<Close />}
                      ></FormButton>
                      <FormButton
                        onClick={() => {
                          setStatusButton("accept");
                          setConfirmModal(true);
                        }}
                        isSubmitting={isSubmitting}
                        text="Accept"
                        size="small"
                        startIcon={<Check />}
                      ></FormButton>
                    </Stack>
                  </Grid>
                </Grid>
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <DialogMessagePopup
        title={
          "Authorization request for temporary disable AMP Technicians App"
        }
        text={
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Typography>
                {`Are you certain you want to ${statusButton} this request?`}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={2} pt={2}>
                  <Typography>Yes Provide</Typography>
                </Grid>
                <Grid item xs={2}>
                  <FormNumeric2
                    name="provide"
                    label=""
                    value={permissionValue}
                    onChange={(e) => {
                      setPermissionValue(Number(e.target.value));
                    }}
                    disabled={isSubmitting}
                    decimalScale={0}
                  ></FormNumeric2>
                </Grid>
                <Grid item xs={8} pl={3}>
                  <FormRadioGroup
                    name="radioButtonSelect"
                    label=""
                    value={permissionUnit}
                    items={radioInformation}
                    onChange={(e) => {
                      setPermissionUnit(Number(e.target.value));
                    }}
                    defaultValue={selectRadioButton}
                    row={true}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        }
        showPopup={confirmModal}
        setShowPopup={setConfirmModal}
        isSubmitting={isSubmitting}
        onSave={() => {
          handleConfirm("accept");
        }}
      ></DialogMessagePopup>
      <DialogMessagePopup
        title={
          "Authorization request for temporary disable AMP Technicians App"
        }
        text={
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Typography>
                Are you certain you want to reject this request?
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <FormTextArea
                name={"comments"}
                label={"Comments"}
                value={comments}
                onChange={(e: any) => {
                  setComments(e.target.value);
                }}
              ></FormTextArea>
            </Grid>
          </Grid>
        }
        showPopup={rejectModal}
        setShowPopup={setRejectModal}
        isSubmitting={isSubmitting}
        onSave={() => {
          handleConfirm("reject");
        }}
        disabled={!comments}
      ></DialogMessagePopup>
    </Root>
  );
}
export default AuthorizeTechnicianPermission;
