import { useEffect, useState } from "react";
import { Stack, Typography } from "@mui/material";
import { ColumnType } from "src/types/enhancedTable";
import { IProjectOutForBidData } from "src/ts/interfaces/projectOutToBid";
import { useTable } from "src/hooks/useTable";
import { FilterType } from "../project/types/FilterTypes";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { getQueryString } from "../project/utils/common";
import { GridActionButton } from "src/components/gridControls";
import getProjectSingleService from "src/services/getProjectSingleService";
import { useAuth, useLog, usePermissions } from "src/hooks";
import TimeDurationEnteredPopUp from "./Components/TimeDurationEnteredPopUp";
import DeclineProject from "./Components/DeclineProject";
import { Permission as PermissionTypes } from "src/ts/enums";

const getColumns = (
  navigate: NavigateFunction,
  companyId: number,
  setCurrentRow: (val: IProjectOutForBidData) => void,
  setTimeDurationEnteredShowPopUp: (val: boolean) => void,
  setDeclineProjectShow: (val: boolean) => void,
  readBids: boolean,
  fulloutForBid: boolean
): ColumnType[] => [
  {
    id: "code",
    label: "Code",
    type: "string",
    sort: true,
  },
  {
    id: "name",
    label: "Project",
    type: "string",
    sort: true,
  },
  {
    id: "ownerName",
    label: "Proposed By",
    type: "string",
    sort: true,
  },
  {
    id: "building",
    label: "Building",
    type: "custom",
    sort: true,
    callback: (row: IProjectOutForBidData) => {
      return (
        <Typography>
          {" "}
          <b>{row.buildingName}</b> <br />
          {row.buildingAddress}{" "}
        </Typography>
      );
    },
  },
  {
    id: "projectDescription",
    label: "Description",
    type: "string",
    sort: true,
  },
  {
    id: "timeDuration",
    label: "Project Time Duration Entered",
    type: "custom",
    sort: false,
    align: "center",
    hide: !readBids,
    callback: (row: IProjectOutForBidData) => (
      <>
        <Stack direction="row" justifyContent="center">
          {row.timeDurationEntered ? (
            <GridActionButton
              type="calendar"
              onClick={() => {
                setCurrentRow(row);
                setTimeDurationEnteredShowPopUp(true);
              }}
              tooltip="Time Duration"
            />
          ) : (
            <GridActionButton
              type="calendarOff"
              onClick={() => {
                setCurrentRow(row);
                setTimeDurationEnteredShowPopUp(true);
              }}
              tooltip="Without Time Duration"
            />
          )}
        </Stack>
      </>
    ),
  },
  {
    id: "contestDueDate",
    label: "Proposal Due Date",
    type: "date",
    format: "MM/DD/YYYY hh:mm A",
    sort: true,
    width: "125px",
  },
  {
    id: "action",
    label: "",
    type: "custom",
    sort: true,
    callback: (row: IProjectOutForBidData) => (
      <>
        <Stack direction="row">
          <GridActionButton
            type="forward"
            onClick={() => {
              navigate(`/app/TemporalProjectContest/${row.id}`);
            }}
            tooltip="Review Project"
          />
          <GridActionButton
            type="folder"
            onClick={() => {
              navigate(
                `/app/DocumentLibrary/${row.id}/${companyId}/ProjectOutToBid`
              );
            }}
            tooltip="Project's Document Library"
          />
          <GridActionButton
            type="picture"
            onClick={() => {
              navigate(
                `/app/PhotoLibrary/${row.id}/${companyId}/ProjectOutToBid`
              );
            }}
            tooltip="Project's Picture Library"
          />
          {fulloutForBid && (
            <GridActionButton
              type="close"
              onClick={() => {
                setCurrentRow(row);
                setDeclineProjectShow(true);
              }}
              tooltip="Decline"
            />
          )}
        </Stack>
      </>
    ),
  },
];

interface projectsAvailableProps {
  filters: FilterType;
  refresh: boolean;
}

export default function ProjectsApplied({
  filters,
  refresh,
}: projectsAvailableProps) {
  const { log } = useLog();
  const { user } = useAuth();
  const [currentRow, setCurrentRow] = useState<IProjectOutForBidData>();
  const [timeDurationEnteredShowPopUp, setTimeDurationEnteredShowPopUp] =
    useState(false);
  const [declineProjectShow, setDeclineProjectShow] = useState(false);
  const { read: readBids } = usePermissions(PermissionTypes.Project_Bids);
  const { fullAccess: fulloutForBid } = usePermissions(
    PermissionTypes.Projects_out_for_bid
  );

  let navigate = useNavigate();
  let columns = getColumns(
    navigate,
    user?.companyId ?? 0,
    setCurrentRow,
    setTimeDurationEnteredShowPopUp,
    setDeclineProjectShow,
    readBids ?? false,
    fulloutForBid
  );
  const {
    Table,
    setRowsPerPage,
    page,
    setDataSet,
    setCount,
    orderBy,
    order,
    startRequest,
    endRequest,
    refreshGrid,
    setRefreshGrid,
  } = useTable(columns, {
    defaultOrderBy: "createdDate",
    defaultOrder: "desc",
  });

  useEffect(() => {
    const getData = async () => {
      try {
        startRequest("dataLoading");
        const request = await getProjectSingleService.getAllProjects(
          {
            page: page + 1,
            sortColumn: orderBy,
            sortDec: order === "desc" ? true : false,
            search: filters.search,
            countryId: filters.countryId,
            stateId: filters.stateId | 0,
            stateName: filters.stateName,
            zipCode: filters.zipCode,
            projectCode: filters.projectCode,
            hasAaacepted: true,
            buildingName: filters.buildingName,
          },
          getQueryString
        );

        setCount(request.data.count);
        setDataSet(request.data.getAll);
        setRowsPerPage(10);
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      } finally {
        endRequest("dataLoading");
      }
    };

    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    page,
    orderBy,
    order,
    setCount,
    setDataSet,
    setRowsPerPage,
    refreshGrid,
    refresh,
  ]);

  return (
    <>
      <Table />

      {timeDurationEnteredShowPopUp && (
        <TimeDurationEnteredPopUp
          timeDurationEnteredShowPopUp={timeDurationEnteredShowPopUp}
          setTimeDurationEntered={setTimeDurationEnteredShowPopUp}
          currentRow={currentRow}
          refreshPage={refreshGrid}
          setRefreshPage={setRefreshGrid}
        />
      )}
      {declineProjectShow && (
        <DeclineProject
          declineProjectShow={declineProjectShow}
          setDeclineProject={setDeclineProjectShow}
          dataRow={currentRow}
          refreshPage={refreshGrid}
          setRefreshPage={setRefreshGrid}
        />
      )}
    </>
  );
}
