import { ITimeCard, ITimeCardNew } from "src/ts/interfaces";
import axios from "src/utils/axios";

const getByProject = (projectId: number) => {
  return axios.get<ITimeCard[]>(`TimeCard/GetByProject?projectId=${projectId}`);
};

const save = (timeCard: ITimeCardNew) => {
  return axios.post<ITimeCard>("TimeCard", timeCard);
};

const update = (timeCard: ITimeCard) => {
  return axios.put<ITimeCard>("TimeCard", timeCard);
};

const timeCardService = {
  getByProject,
  save,
  update,
};

export default timeCardService;
