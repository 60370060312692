import { useEffect, useState } from "react";
import { Stack, Typography, Chip as MuiChip } from "@mui/material";
import { ColumnType } from "src/types/enhancedTable";
import { IProjectOutForBidData } from "src/ts/interfaces/projectOutToBid";
import { useTable } from "src/hooks/useTable";
import { FilterType } from "../project/types/FilterTypes";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { getQueryString } from "../project/utils/common";
import { GridActionButton } from "src/components/gridControls";
import getProjectSingleService from "src/services/getProjectSingleService";
import { useAuth, useLog, usePermissions } from "src/hooks";
import { SpacingProps, spacing } from "@mui/system";
import styled from "@emotion/styled";
import ReverseDeclinationPopUp from "./Components/ReverseDeclinationPopUp";
import moment from "moment";
import { Permission as PermissionTypes } from "src/ts/enums";
interface ChipProps extends SpacingProps {
  component?: React.ElementType;
  href?: string;
  icon?: JSX.Element | null;
}

const Chip = styled(MuiChip)<ChipProps>(spacing);

const getColumns = (
  navigate: NavigateFunction,
  companyId: number,
  setCurrentRow: (val: IProjectOutForBidData) => void,
  setReverseDeclineProjectShow: (val: boolean) => void,
  fulloutForBid: boolean
): ColumnType[] => [
  {
    id: "code",
    label: "Code",
    type: "string",
    sort: true,
  },
  {
    id: "name",
    label: "Project",
    type: "string",
    sort: true,
  },
  {
    id: "ownerName",
    label: "Proposed By",
    type: "string",
    sort: true,
  },
  {
    id: "building",
    label: "Building",
    type: "custom",
    sort: true,
    callback: (row: IProjectOutForBidData) => {
      return (
        <Typography>
          {" "}
          <b>{row.buildingName}</b> <br />
          {row.buildingAddress}{" "}
        </Typography>
      );
    },
  },

  {
    id: "winning",
    label: "Status",
    type: "custom",
    sort: false,
    align: "left",
    callback: (row: IProjectOutForBidData) => (
      <>
        <Stack direction="row">
          {row.winingDate === null && (
            <Chip label={"Applied"} color="primary" m={1} size="small" />
          )}
          {row.winingDate !== null && (
            <Chip label={"Awarded"} color="success" m={1} size="small" />
          )}
        </Stack>
      </>
    ),
  },
  {
    id: "contestDueDate",
    label: "Proposal Due Date",
    type: "date",
    format: "MM/DD/YYYY hh:mm A",
    sort: true,
    width: "125px",
  },
  {
    id: "winingDate",
    label: "Date Awarded",
    type: "custom",
    sort: false,
    callback: (row: IProjectOutForBidData) => (
      <>
        {row.winingDate === null
          ? ""
          : moment(row.winingDate).format("MM/DD/YYYY")}
      </>
    ),
  },
  {
    id: "action",
    label: "",
    type: "custom",
    sort: true,
    callback: (row: IProjectOutForBidData) => (
      <>
        <Stack direction="row">
          <GridActionButton
            type="forward"
            onClick={() => {
              navigate(`/app/TemporalProjectContest/${row.id}`);
            }}
            tooltip="Review Project"
          />
          <GridActionButton
            type="folder"
            onClick={() => {
              navigate(
                `/app/DocumentLibrary/${row.id}/${companyId}/ProjectOutToBid`
              );
            }}
            tooltip="Project's Document Library"
          />
          <GridActionButton
            type="picture"
            onClick={() => {
              navigate(
                `/app/PhotoLibrary/${row.id}/${companyId}/ProjectOutToBid`
              );
            }}
            tooltip="Project's Picture Library"
          />

          {row.winingDate === null && fulloutForBid && (
            <GridActionButton
              type="check"
              onClick={() => {
                setCurrentRow(row);
                setReverseDeclineProjectShow(true);
              }}
              tooltip="Accept"
            />
          )}
        </Stack>
      </>
    ),
  },
];

interface projectsDecliinedbleProps {
  filters: FilterType;
  refresh: boolean;
}

export default function ProjectsDeclined({
  filters,
  refresh,
}: projectsDecliinedbleProps) {
  const { log } = useLog();
  const { user } = useAuth();
  const [currentRow, setCurrentRow] = useState<IProjectOutForBidData>();
  const { fullAccess: fulloutForBid } = usePermissions(
    PermissionTypes.Projects_out_for_bid
  );
  const [reverseDeclineProjectShow, setReverseDeclineProjectShow] =
    useState(false);

  let navigate = useNavigate();
  let columns = getColumns(
    navigate,
    user?.companyId ?? 0,
    setCurrentRow,
    setReverseDeclineProjectShow,
    fulloutForBid
  );
  const {
    Table,
    setRowsPerPage,
    page,
    setDataSet,
    setCount,
    orderBy,
    order,
    startRequest,
    endRequest,
    refreshGrid,
    setRefreshGrid,
  } = useTable(columns, {
    defaultOrderBy: "createdDate",
    defaultOrder: "desc",
  });

  useEffect(() => {
    const getData = async () => {
      try {
        startRequest("dataLoading");
        const request = await getProjectSingleService.getAllDeclined(
          {
            page: page + 1,
            sortColumn: orderBy,
            sortDec: order === "desc" ? true : false,
            search: filters.search,
            countryId: filters.countryId,
            stateId: filters.stateId | 0,
            stateName: filters.stateName,
            zipCode: filters.zipCode,
            projectCode: filters.projectCode,
            buildingName: filters.buildingName,
          },
          getQueryString
        );

        setCount(request.data.count);
        setDataSet(request.data.getAll);
        setRowsPerPage(10);
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      } finally {
        endRequest("dataLoading");
      }
    };

    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    page,
    orderBy,
    order,
    setCount,
    setDataSet,
    setRowsPerPage,
    refreshGrid,
    refresh,
  ]);

  return (
    <>
      <Table />

      {reverseDeclineProjectShow && (
        <ReverseDeclinationPopUp
          declineProjectShow={reverseDeclineProjectShow}
          setDeclineProject={setReverseDeclineProjectShow}
          dataRow={currentRow}
          refreshPage={refreshGrid}
          setRefreshPage={setRefreshGrid}
        />
      )}
    </>
  );
}
