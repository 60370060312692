import { useEffect, useState } from "react";
import { Stack, Typography } from "@mui/material";
import { ColumnType } from "src/types/enhancedTable";
import { FilterType } from "../types/FilterTypes";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { GridActionButton } from "src/components/gridControls";
import { useAsyncQuery, useAuth } from "src/hooks";
import LocalEnhancedTable from "src/components/localTable/LocalTable";
import MatchWordConfirmModal from "src/components/modals/MatchWordConfirmModal";
import projectService from "src/services/project/projectService";
import ArchiveProjectPopUp from "../componentsArchitectProject/components/ArchiveProjectPopUp";
import { IGetProjectInfectious } from "src/ts/interfaces/project/projectinfectiousCompliance";
import projectPutService from "src/services/project/projectPutService";
import { ProjectDTO } from "src/ts/interfaces/project/projectDto";
import getProjectSingleService from "src/services/project/getProjectSingleService";

const getColumns = (
  navigate: NavigateFunction,
  role: string,
  setCurrentRow: (val: IGetProjectInfectious) => void,
  setDeleteProjectModal: (val: boolean) => void,
  companyId: number
): ColumnType[] => [
  {
    id: "code",
    label: "Code",
    type: "string",
    sort: true,
  },
  {
    id: "name",
    label: "Project",
    type: "string",
    sort: true,
  },
  {
    id: "building",
    label: "Building Address",
    type: "custom",
    sort: true,
    callback: (row: IGetProjectInfectious) => {
      return <Typography>{row.buildingAddress} </Typography>;
    },
  },
  {
    id: "createdDate",
    label: "Created Date",
    type: "utcDate",
    format: "MM/DD/YYYY",
    sort: true,
  },
  {
    id: "closedDate",
    label: "Archived date",
    type: "date",
    format: "MM/DD/YYYY",
    sort: true,
  },
  {
    id: "closedComments",
    label: "Comments",
    type: "string",
    sort: true,
  },
  {
    id: "action",
    label: "",
    type: "custom",
    sort: true,
    width: "150p",
    callback: (row: IGetProjectInfectious) => (
      <>
        <Stack direction="row">
          <GridActionButton
            type="forward"
            onClick={() => {
              navigate(`/app/Project/Edit/${row.id}/${companyId}`);
            }}
            tooltip="Review Project"
          />
          <GridActionButton
            type="delete"
            onClick={() => {
              setCurrentRow(row);
              setDeleteProjectModal(true);
            }}
            tooltip="Delete Project"
          />
          <GridActionButton
            type="folder"
            onClick={() => {
              navigate(`/app/DocumentLibrary/${row.id}/${companyId}/Project`);
            }}
            tooltip="Project's Document Library"
          />
          <GridActionButton
            type="picture"
            onClick={() => {
              navigate(`/app/PhotoLibrary/${row.id}/${companyId}/Project`);
            }}
            tooltip="Project's Picture Library"
          />
        </Stack>
      </>
    ),
  },
];

interface ArchivedInfectiousProps {
  filters: FilterType;
  setRefreshGrid: (val: boolean) => void;
  dataTable: IGetProjectInfectious[];
  isLoading: boolean;
  refreshGrid: boolean;
  setDataTable: (val: IGetProjectInfectious[]) => void;
}

export default function ArchivedInfectious({
  filters,
  setRefreshGrid,
  dataTable,
  isLoading,
  refreshGrid,
  setDataTable,
}: ArchivedInfectiousProps) {
  const [currentRow, setCurrentRow] = useState<IGetProjectInfectious>();
  const [deleteProjectModal, setDeleteProjectModal] = useState(false);
  let navigate = useNavigate();
  const { user } = useAuth();

  const { execute: executeDelete } = useAsyncQuery(
    projectService.getDeleteProject
  );

  const { execute: executeProjectSingle, data: dataProjectSingle } =
    useAsyncQuery<ProjectDTO>(getProjectSingleService.getById);

  const { execute: executeUpdateProjectSingle } = useAsyncQuery<ProjectDTO>(
    projectPutService.update
  );

  useEffect(() => {
    const updateProject = async () => {
      await executeUpdateProjectSingle(currentRow?.id ?? 0, {
        ...dataProjectSingle,
        projectStatus: {
          id: 4,
          name: "Removed",
        },
      });
    };

    if (dataProjectSingle) updateProject();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataProjectSingle]);

  let columns = getColumns(
    navigate,
    user?.role,
    setCurrentRow,
    setDeleteProjectModal,
    user?.companyId
  );

  const deleteProject = async () => {
    await executeDelete(currentRow?.id);

    if (dataTable.length >= 0) {
      const dataSplice = dataTable.filter((item: IGetProjectInfectious) => {
        return item.id !== currentRow?.id;
      });
      setDataTable(dataSplice);
      executeProjectSingle(currentRow?.id);
      setRefreshGrid(!refreshGrid);
    }
  };

  return (
    <>
      <LocalEnhancedTable<IGetProjectInfectious>
        refreshGrid={refreshGrid}
        columns={columns}
        data={dataTable ?? []}
        showSkeleton={isLoading}
        skeletonRows={1}
        hidePagination={false}
        defaultRowPerPage={10}
        orderColumn="desc"
        defaultSortColumn="id"
      />
      <MatchWordConfirmModal
        onConfirm={deleteProject}
        text={`Are you certain you want to delete permanently this project?`}
        matchWord={"DELETE"}
        isDialogOpen={deleteProjectModal}
        setIsDialogOpen={setDeleteProjectModal}
      />
    </>
  );
}
