import axios from "../utils/axios";
import { IPlaqueProviders } from "../ts/interfaces";

const getAll = () => {
  return axios.get<IPlaqueProviders[]>("PlaqueProviders");
};

const getById = (id: Number) => {
  return axios.get<IPlaqueProviders>(`PlaqueProviders/${id}`);
};

const add = (unitLabel: IPlaqueProviders) => {
  return axios.post("PlaqueProviders", unitLabel);
};

const update = (unitLabel: IPlaqueProviders) => {
  return axios.put(`PlaqueProviders/${unitLabel.id}`, unitLabel);
};

const remove = (id: Number) => {
  return axios.delete(`PlaqueProviders/${id}`);
};

const plaqueProviderService = { getAll, getById, add, update, remove };
export default plaqueProviderService;
