import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import {
  Grid,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
  Typography,
  Button,
  Card as MuiCard,
  CardContent,
  Stack,
} from "@mui/material";
import { spacing } from "@mui/system";
import { Add as AddIcon } from "@mui/icons-material";
import { ColumnType } from "src/types/enhancedTable";
import { useForm, Form, useAsyncQuery, useLog } from "src/hooks";
import FormText from "src/components/formControls/FormText";
import LocalEnhancedTable from "src/components/localTable/LocalTable";
import { GridActionButton, GridChipStatus } from "src/components/gridControls";
import convertionService from "src/services/catalogs/convertionService";
import { IConversions } from "src/ts/interfaces/catalogs";
import ConversionForm from "./ConversionForm";
import DialogMessagePopup from "src/components/DialogMessagePopup";
import { Permission as PermissionTypes } from "src/ts/enums";
import { usePermissions } from "src/hooks";

const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Card = styled(MuiCard)(spacing);

const filterInitialValues = {
  id: 0,
  name: "",
  statusId: "",
};

const initialValues: IConversions = {
  statusId: -1,
  fromName: "",
  formulaFrom: "",
  toName: "",
  formulaTo: "",
  id: 0,
};

const getColumns = (
  editHandler: (row: IConversions) => void,
  showDeleteModalHandler: (row: IConversions) => void,
  fullAccess: boolean | undefined
): ColumnType[] => [
  { id: "id", label: "#", type: "string", sort: true },
  { id: "fromName", label: "From", type: "string", sort: true },
  { id: "toName", label: "To", type: "string", sort: true },
  {
    id: "statusId",
    label: "Status",
    type: "custom",
    sort: true,
    callback: (row: IConversions) => {
      return <GridChipStatus value={row.statusId} />;
    },
  },
  {
    id: "actions",
    label: "Actions",
    type: "custom",
    sort: false,
    callback: (row: IConversions) => {
      return (
        <Stack direction="row">
          <GridActionButton
            type={fullAccess ? "edit" : "view"}
            onClick={() => editHandler(row)}
            tooltip={fullAccess ? "Edit" : "View"}
          />
          {fullAccess && (
            <GridActionButton
              type="delete"
              onClick={() => showDeleteModalHandler(row)}
              tooltip="Delete"
            />
          )}
        </Stack>
      );
    },
  },
];

const ConversionGrid = () => {
  const [refreshGrid, setRefreshGrid] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [currentRow, setCurrentRow] = useState<IConversions>(initialValues);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const { execute, data, isLoading } = useAsyncQuery<IConversions[]>(
    convertionService.getAll
  );

  const { values: filterValues, handleInputChange: handleFilterChange } =
    useForm(filterInitialValues, true, []);
  const { fullAccess, readOnly } = usePermissions(PermissionTypes.Conversions);
  const { log } = useLog();

  useEffect(() => {
    const getData = async () => {
      execute();
    };

    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshGrid]);

  const handleOpen = () => {
    editHandler(initialValues);
  };

  const editHandler = (row: IConversions) => {
    setCurrentRow(row);
    setIsDialogOpen(true);
  };

  const refreshGridHandler = () => {
    setRefreshGrid(!refreshGrid);
  };

  const showDeleteModalHandler = (row: IConversions) => {
    setCurrentRow(row);
    setShowDeleteModal(true);
  };

  const deleteHandler = async () => {
    if (currentRow?.id !== undefined) {
      try {
        await convertionService.remove(currentRow?.id);
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      }

      refreshGridHandler();
    }
  };

  const columns = getColumns(editHandler, showDeleteModalHandler, fullAccess);

  return (
    <>
      <Helmet title="Convertions" />
      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Conversions
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} to="/">
              Dashboard
            </Link>
            <Typography>Conversions</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item>
          <div>
            {fullAccess && (
              <Button onClick={handleOpen} variant="contained" color="primary">
                <AddIcon />
                New Convertion
              </Button>
            )}
          </div>
        </Grid>
      </Grid>

      <Divider my={6} />

      <Card mb={6}>
        <CardContent>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Form>
                <Grid container>
                  <Grid item xs={12}>
                    <FormText
                      name="name"
                      label="Name"
                      value={filterValues.name}
                      disabled={readOnly}
                      onChange={handleFilterChange}
                    />
                  </Grid>
                </Grid>
              </Form>
            </Grid>
            <Grid item xs={12}>
              <LocalEnhancedTable<IConversions>
                refreshGrid={refreshGrid}
                columns={columns}
                data={data}
                query={filterValues.name}
                showSkeleton={isLoading}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <ConversionForm
        isDialogOpen={isDialogOpen}
        setIsDialogOpen={setIsDialogOpen}
        onSave={refreshGridHandler}
        currentValue={currentRow}
      />
      <DialogMessagePopup
        title={"Information"}
        text={"Are you certain you want to delete this item?"}
        showPopup={showDeleteModal}
        setShowPopup={setShowDeleteModal}
        onSave={deleteHandler}
        isSubmitting={false}
      />
    </>
  );
};

export default ConversionGrid;
