import { IConfiguration, IUserPerson } from "src/ts/interfaces";
import { ProjectDTO } from "src/ts/interfaces/project/projectDto";
import { IValidateAuditors } from "src/ts/interfaces/technician";
import axios from "src/utils/axios";

const update = (projectId: number, project: ProjectDTO) => {
  return axios.put(`ProjectPut/${projectId}`, project);
};

const validateAuditors = (projectId: number, auditors: IUserPerson[]) => {
  return axios.post<IValidateAuditors>(
    `ProjectPut/ValidateAuditors?id=${projectId}`,
    auditors
  );
};

const putBeltsAndPulleys = (value: number, projectId: number) => {
  return axios.put(
    `ProjectPut/${projectId}/BeltsAndPulleys?beltsAndPulleys=${value}`
  );
};

const putCostForElectricMotros = (value: number, projectId: number) => {
  return axios.put(
    `ProjectPut/${projectId}/CostForElectricMotors?costForElectricMotors=${value}`
  );
};

const putAdditionalTestingReq = (projectId: number, data: IConfiguration) => {
  return axios.put(`ProjectPut/${projectId}/AdditionalTestingReq`, data);
};

const transferProject = (projectId: number, satelliteId: number) => {
  return axios.post<any>(
    `ProjectPut/Transfer?projectId=${projectId}&copanyId=${satelliteId}`
  );
};

const projectPutService = {
  update,
  validateAuditors,
  putBeltsAndPulleys,
  putCostForElectricMotros,
  putAdditionalTestingReq,
  transferProject,
};

export default projectPutService;
