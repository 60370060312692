import React, { useEffect } from "react";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import {
  Grid,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
  Typography,
  Card as MuiCard,
  CardContent,
  Stack,
  Tooltip,
} from "@mui/material";
import { spacing } from "@mui/system";
import { IUserNotification } from "src/ts/interfaces";
import { ColumnType } from "src/types/enhancedTable";
import LocalEnhancedTable from "src/components/localTable/LocalTable";
import useAsyncQuery from "src/hooks/useAsyncQuery";
import { NotificationService } from "src/services";
import MarkunreadIcon from "@mui/icons-material/Markunread";
import MarkAsUnreadIcon from "@mui/icons-material/MarkAsUnread";
import { FormButton } from "src/components/formControls";
import useAsyncMutation from "src/hooks/useAsyncMutation";
import { useAppDispatch } from "src/hooks";
import { notificationActions } from "src/redux/slices/notificationActions";
import { GridActionButton } from "src/components/gridControls";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const NotificationsPage = () => {
  const dispatch = useAppDispatch();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { execute, data, setData } = useAsyncQuery<IUserNotification[]>(
    NotificationService.getAllByUser,
    {
      immediate: true,
    }
  );

  const { execute: readAll, status } = useAsyncMutation(
    NotificationService.readAll,
    {
      successfulMessage: "All notification were archived.",
    }
  );

  const { execute: deleteAll } = useAsyncMutation(
    NotificationService.deleteAll,
    {
      successfulMessage: "All notification were deleted.",
    }
  );

  const handleDelete = async (row: IUserNotification) => {
    await NotificationService.disable(row.id);
    setData([...data.filter((notification) => notification.id !== row.id)]);
    if (!row.isRead) {
      dispatch(notificationActions.archiveNotification(row.id));
    }
  };

  const handleReadNotification = async (row: IUserNotification) => {
    await NotificationService.read(row.id);

    const newArr = data.map((notification) => {
      if (notification.id === row.id) {
        return { ...notification, isRead: true };
      } else return notification;
    });

    setData(newArr);
    dispatch(notificationActions.archiveNotification(row.id));
  };

  const columns: ColumnType[] = [
    {
      id: "read",
      label: "",
      type: "custom",
      sort: false,
      callback: (row: IUserNotification) => {
        return (
          <div>
            {row.isRead ? (
              <Tooltip title="Read">
                <MarkunreadIcon />
              </Tooltip>
            ) : (
              <Tooltip title="Unread">
                <MarkAsUnreadIcon />
              </Tooltip>
            )}
          </div>
        );
      },
    },
    {
      id: "notificationTitle",
      label: "Title",
      type: "string",
      sort: true,
    },
    {
      id: "notificationCreated",
      label: "Created",
      type: "utcDate",
      sort: true,
      format: "MM/DD/yyyy h:mma",
    },
    {
      id: "delete",
      label: "Actions",
      type: "custom",
      sort: false,
      callback: (row: IUserNotification) => {
        return (
          <Stack direction="row">
            <GridActionButton
              type="delete"
              onClick={() => {
                handleDelete(row);
              }}
              tooltip="Remove"
            />

            {!row.isRead ? (
              <GridActionButton
                type="view"
                onClick={() => {
                  handleReadNotification(row);
                }}
                tooltip="View"
              />
            ) : null}
          </Stack>
        );
      },
    },
  ];

  useEffect(() => {
    if (status === "success") {
      let readNotifications = data.map((notification) => {
        if (!notification.isRead) {
          notification.isRead = true;
          return notification;
        } else {
          return notification;
        }
      });

      setData([...readNotifications]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  const handleReadAll = async () => {
    await readAll();
    dispatch(notificationActions.archiveAllNotification());
  };

  const handleDeleteAll = async () => {
    await deleteAll();
    dispatch(notificationActions.archiveAllNotification());
    setData([]);
  };

  return (
    <>
      <Helmet title="System Administrators Permissions" />
      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            System Administrators Permissions
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} to="/">
              Dashboard
            </Link>
            <Typography>System Administrators Permissions</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item></Grid>
      </Grid>

      <Divider my={6} />
      <Card mb={6}>
        <CardContent>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Stack direction="row" spacing={2}>
                <FormButton
                  mr={2}
                  text={false ? "submitting..." : "Read all"}
                  size="medium"
                  onClick={handleReadAll}
                />
                <FormButton
                  mr={2}
                  text="Delete all"
                  size="medium"
                  onClick={handleDeleteAll}
                />
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <LocalEnhancedTable<IUserNotification>
                refreshGrid={true}
                columns={columns}
                data={data ?? []}
                defaultSortColumn="notificationCreated"
                orderColumn="desc"
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
};

export default NotificationsPage;
