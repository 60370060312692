import axios from "src/utils/axios";
import { IOutletReportCustomParameters } from "src/ts/interfaces";

const updateAllCustomParameterss = (
  outletMasterCustomParameterss: IOutletReportCustomParameters[]
) => {
  return axios.put(
    "OutletReportCustomParameters/AllCustomParameterss",
    outletMasterCustomParameterss
  );
};

const getByComponent = (componentId: number) => {
  return axios.get<IOutletReportCustomParameters[]>(
    `OutletReportCustomParameters/component/${componentId}`
  );
};

const add = (
  componentId: number,
  customParameter: IOutletReportCustomParameters
) => {
  return axios.post<IOutletReportCustomParameters>(
    `OutletReportCustomParameters/${componentId}`,
    customParameter
  );
};

const remove = (parameterId: number) => {
  return axios.delete<IOutletReportCustomParameters[]>(
    `OutletReportCustomParameters/${parameterId}`
  );
};

const outletReportCustomParametersService = {
  updateAllCustomParameterss,
  getByComponent,
  add,
  remove,
};

export default outletReportCustomParametersService;
