/* eslint-disable react-hooks/exhaustive-deps */
import {
  CardContent,
  Grid,
  Divider as MuiDivider,
  Card as MuiCard,
  ButtonGroup,
  Button,
  Alert,
} from "@mui/material";
import { Stack, spacing } from "@mui/system";

import styled from "@emotion/styled";
import HeaderStudyPage from "src/components/page/HeaderStudyPage";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  useAsyncQuery,
  useLog,
  useAuth,
  useSystemOfMeasurement,
} from "src/hooks";
import {
  FormCheckBox,
  FormNumeric2,
  FormSelect,
  FormText,
} from "src/components/formControls";
import useFormTyped from "src/hooks/useFormTyped";
import {
  IPumpReportViewModelDTO,
  IReportType,
  IActualFormulaPumpMotorDTO,
  IPumpStarterDTO,
} from "src/ts/interfaces";
import { IProjectReport } from "src/ts/interfaces/project/projectDto";
import projectReportService from "src/services/study/projectReportService";
import { steps } from "../const/consts";
import { FooterButtons } from "../../components/FooterButtons";
import CycloneIcon from "@mui/icons-material/Cyclone";
import { IBreakerMfgDTO, IStarterMfgDTO } from "src/ts/interfaces/catalogs";
import { IDefaultComboBoxCatalog } from "src/ts/interfaces";
import Popup from "src/components/Popup";
import { Validator } from "src/ts/types/Validator";
import pumpReportService from "src/services/study/pumpReportService";
import reportTypeService from "src/services/study/reportTypeService";
import pumpStarterService from "src/services/study/pumpStarterService";
import FormTooltip from "src/components/formControls/FormTooltip";
import starterMfgService from "src/services/study/starterMfgService";
import breakerMfgService from "src/services/study/breakerMfgService";
import starterSizesImg from "./assets/StarterSizes.png";
import StudyWizard from "../../components/StudyWizard";

const quantitiesOfHeaters = [
  { key: 0, value: "Select a quantity" },
  { key: 1, value: 1 },
  { key: 2, value: 2 },
  { key: 3, value: 3 },
];

const starterSizes = [
  { key: "", value: "Select a size" },
  { key: "00", value: "00" },
  { key: "0", value: "0" },
  { key: "1", value: "1" },
  { key: "2", value: "2" },
  { key: "3", value: "3" },
  { key: "4", value: "4" },
  { key: "5", value: "5" },
  { key: "6", value: "6" },
  { key: "7", value: "7" },
  { key: "8", value: "8" },
  { key: "9", value: "9 " },
];

const initialValues: any = {};

const Divider = styled(MuiDivider)(spacing);
const Card = styled(MuiCard)(spacing);

const PumpStep4 = () => {
  const { log } = useLog();
  const { user } = useAuth();
  const { systemOfMeasurement } = useSystemOfMeasurement(true);
  const params = useParams<{ id: string; step: string }>();
  const id = parseInt(params?.id === undefined ? "0" : params?.id);
  const [isUpdating, setIsUpdating] = useState(false);
  const [title, setTitle] = useState<string>();
  const [code, setCode] = useState<string>();
  const [selectedPump, setSelectedPump] = useState(0);
  const [selectedPumpIndex, setSelectedPumpIndex] = useState(0);
  const [pumpValues, setPumpValues] = useState<IActualFormulaPumpMotorDTO>();
  const [pumps, setPumps] = useState<IActualFormulaPumpMotorDTO[]>();
  const [pumpReportValues, setPumpReportValues] =
    useState<IPumpReportViewModelDTO>();
  const [starterMfgs, setStarterMfgs] = useState<IDefaultComboBoxCatalog[]>();
  const [breakerMfgs, setBreakerMfgs] = useState<IDefaultComboBoxCatalog[]>();
  const [openStarterSizesPopup, setoPenStarterSizesPopup] = useState(false);
  const starterSizesBtnTooltip =
    "<p>Click to show NEMA motor Starters Chart</p>";

  let navigate = useNavigate();

  const validate: any = (fieldValues = values) => {
    let temp: Record<string, string> = { ...errors };

    temp.starterMfgId = new Validator(fieldValues, "starterMfgId")
      .validateIf(values?.isStarter)
      .isRequired("Motor Manufacturer is required.")
      .greatThan(0, "Motor Manufacturer is required.")
      .toString();
    temp.otherStarterMfg = new Validator(fieldValues, "otherStarterMfg")
      .validateIf(
        values?.isStarter &&
          catalogValueOtherEval(
            values?.starterMfgId as number,
            starterMfgs as IDefaultComboBoxCatalog[]
          )
      )
      .isRequired("Other Motor manufacturer is required.")
      .toString();

    temp.breakerMfgId = new Validator(fieldValues, "breakerMfgId")
      .validateIf(values?.isBreaker)
      .isRequired("Motor Manufacturer is required.")
      .greatThan(0, "Motor Manufacturer is required.")
      .toString();
    temp.otherBreakerMfg = new Validator(fieldValues, "otherBreakerMfg")
      .validateIf(
        values?.isBreaker &&
          catalogValueOtherEval(
            values?.breakerMfgId as number,
            breakerMfgs as IDefaultComboBoxCatalog[]
          )
      )
      .isRequired("Other Motor manufacturer is required.")
      .toString();

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, handleInputChange, errors, setErrors } =
    useFormTyped<IPumpStarterDTO>(initialValues, false, validate);

  const activeStep = 3;

  const { execute: executeReport, data: dataReport } =
    useAsyncQuery<IProjectReport>(projectReportService.report);

  const { execute: executePumpStarter, isLoading: isLoadingPumpStarter } =
    useAsyncQuery<IPumpStarterDTO>(pumpStarterService.getById, {
      onSuccess: (dataResult) => {
        const getData = async () => {
          setValues(dataResult);
        };
        getData();
      },
    });

  const { execute: executeFormula, isLoading: isLoadingFormula } =
    useAsyncQuery<IActualFormulaPumpMotorDTO[]>(
      pumpReportService.getActualFormulaMotorById,
      {
        onSuccess: (dataResult) => {
          const getData = async () => {
            setPumps(dataResult);
          };
          getData();
          executeStarterMfg();
          executeBreakerMfg();
        },
      }
    );

  const { execute, isLoading } = useAsyncQuery<IPumpReportViewModelDTO>(
    pumpReportService.getById,
    {
      onSuccess: (dataResult) => {
        const getData = async () => {
          setPumpReportValues({ ...dataResult });
          await systemOfMeasurement.getByProject(
            dataResult.projectId ?? pumpReportValues?.projectId
          );
          window.scrollTo(0, 0);
        };
        getData();
      },
    }
  );

  const { execute: executeReportType } = useAsyncQuery<IReportType>(
    reportTypeService.getReportTypeById,
    {
      onSuccess: (dataResult) => {
        const getData = async () => {
          setCode(dataResult.code);
          setTitle(dataResult.name);
        };
        getData();
      },
    }
  );

  const { execute: executeStarterMfg, isLoading: isLoadingStarterMfg } =
    useAsyncQuery<IStarterMfgDTO[]>(starterMfgService.getStarterMfgs, {
      onSuccess: (dataResult) => {
        const getData = async () => {
          const types = (dataResult as IStarterMfgDTO[]).map((type) => ({
            key: type.id as number,
            value: type.name as any,
          }));
          setStarterMfgs([
            { key: 0, value: "Select a starter Manufacturer" },
            ...types,
          ]);
        };
        getData();
      },
    });

  const { execute: executeBreakerMfg, isLoading: isLoadingBreakerMfg } =
    useAsyncQuery<IBreakerMfgDTO[]>(breakerMfgService.getBreakerMfgs, {
      onSuccess: (dataResult) => {
        const getData = async () => {
          const types = (dataResult as IBreakerMfgDTO[]).map((type) => ({
            key: type.id as number,
            value: type.name as any,
          }));
          setBreakerMfgs([
            { key: 0, value: "Select a breaker Manufacturer" },
            ...types,
          ]);
        };
        getData();
      },
    });

  const disableStudy = () => {
    return (
      pumpReportValues?.isComplete ||
      (dataReport?.isInUse && dataReport?.isInUseById !== user?.userId) ||
      dataReport?.isPartOfProject === false
    );
  };

  useEffect(() => {
    if (pumpReportValues?.reportTypeId)
      executeReportType(pumpReportValues?.reportTypeId);
  }, [pumpReportValues?.reportTypeId]);

  useEffect(() => {
    const getData = async () => {
      try {
        await execute(id);
        await executeFormula(id);
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      }
    };

    if (id !== 0) getData();
  }, [id]);

  useEffect(() => {
    if (pumps) {
      setErrors({});
      setSelectedPump(
        (pumps as IActualFormulaPumpMotorDTO[])[selectedPumpIndex].id
      );
      setPumpValues((pumps as IActualFormulaPumpMotorDTO[])[selectedPumpIndex]);
      executePumpStarter(
        (pumps as IActualFormulaPumpMotorDTO[])[selectedPumpIndex].pumpStarterId
      );
    }
  }, [pumps, selectedPumpIndex]);

  useEffect(() => {
    const getData = async () => {
      await executeReport(id, code);
    };

    if (id !== 0 && code !== undefined) getData();
  }, [id, code]);

  const saveStarterBreakerHandle = async () => {
    try {
      if (!disableStudy()) {
        if (!validate()) return;
        await pumpStarterService.update(id, {
          ...values,
          starterMfgId: !values?.isStarter ? null : values?.starterMfgId,
          quantityOfHeaters: !values?.isStarter ? 0 : values?.quantityOfHeaters,
          starterSize: !values?.isStarter ? null : values?.starterSize,
          heaterStamping: !values?.isStarter ? null : values?.heaterStamping,
          heaterRangeStampingRated: !values?.isStarter
            ? null
            : values?.heaterRangeStampingRated,
          incorrectHeaters: !values?.isStarter
            ? false
            : values?.incorrectHeaters,
          heatersRequired: !values?.isStarter ? null : values?.heatersRequired,
          heaterRangeRequiredRate: !values?.isStarter
            ? null
            : values?.heaterRangeRequiredRate,
          incomingAWGWireSize: !values?.isStarter
            ? null
            : values?.incomingAWGWireSize,
          breakerMfgId: !values?.isBreaker ? null : values?.breakerMfgId,
          breakerModel: !values?.isBreaker ? null : values?.breakerModel,
          breakerRated: !values?.isBreaker ? null : values?.breakerRated,
          breakerIsTooLarge: !values?.isBreaker
            ? false
            : values?.breakerIsTooLarge,
          breakerIsTooSmall: !values?.isBreaker
            ? false
            : values?.breakerIsTooSmall,
        });
        log.success("Starter/Breaker was updated successfully");
        return true;
      }
    } catch (error) {
      return false;
    }
  };

  const saveHandler = async () => {
    try {
      setIsUpdating(true);
      await saveStarterBreakerHandle();
      setIsUpdating(false);
    } catch (error) {}
  };

  const nextStepHandler = async () => {
    try {
      if (!(await saveStarterBreakerHandle())) return false;
      if (
        selectedPumpIndex <
        (pumps as IActualFormulaPumpMotorDTO[])?.length - 1
      ) {
        setSelectedPumpIndex(selectedPumpIndex + 1);
        window.scrollTo(0, 0);
      } else navigate(`/studies/pumpReport/step5/${id}`);
    } catch (error) {}
  };

  const previousStepHandler = async () => {
    try {
      if (!(await saveStarterBreakerHandle())) return false;
      if (selectedPumpIndex === 0) {
        navigate(`/studies/pumpReport/step3/${id}`);
      } else {
        setSelectedPumpIndex(selectedPumpIndex - 1);
        window.scrollTo(0, 0);
      }
    } catch (error) {}
  };

  const catalogValueOtherEval = (
    id: number,
    catalog: IDefaultComboBoxCatalog[]
  ) => {
    if (catalog && id) {
      const [{ value }] = catalog.filter((x) => x.key === id);
      return value === "Other";
    }
    return false;
  };

  const loadPumpMotor = (index: number) => {
    setSelectedPumpIndex(index);
  };

  const handleBreakerIsTooLargeChange = (e: any) => {
    setValues({
      ...values,
      [e.target.name]: e.target.value,
      breakerIsTooSmall: e.target.value ? false : values.breakerIsTooSmall,
    });
  };

  const handleBreakerIsTooSmallChange = (e: any) => {
    setValues({
      ...values,
      [e.target.name]: e.target.value,
      breakerIsTooLarge: e.target.value ? false : values.breakerIsTooLarge,
    });
  };

  const handleOpenStarterSizesPopup = () => {
    setoPenStarterSizesPopup(true);
  };

  const handleCloseStarterSizesPopup = () => {
    setoPenStarterSizesPopup(false);
  };

  return (
    <>
      {/**Warnings section*/}
      <Popup
        openPopup={openStarterSizesPopup}
        setOpenPopup={setoPenStarterSizesPopup}
        title="NEMA Motor Starters Chart"
        onClose={handleCloseStarterSizesPopup}
        size="lg"
      >
        <>
          <div style={{ textAlign: "center" }}>
            <img
              src={starterSizesImg}
              style={{ width: 900 }}
              alt="NEMA Motor Starters Chart"
            />
          </div>
        </>
        <Stack direction="row" spacing={3}>
          <Button
            variant="outlined"
            color="secondary"
            onClick={handleCloseStarterSizesPopup}
          >
            Close
          </Button>
        </Stack>
      </Popup>
      {/**Warnings section end */}
      <HeaderStudyPage
        headerStudiesPage={{
          code: code,
          system: pumpReportValues?.system,
          id: id,
        }}
        parentText="Project"
        parentLink={`/app/ProjectStudies/${pumpReportValues?.projectId}/${user?.companyId}`}
      />
      <Divider my={6} />

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card mb={6}>
            <CardContent>
              <StudyWizard
                steps={steps}
                activeStep={activeStep}
                projectId={dataReport?.projectId}
                reportId={dataReport?.reportId}
                reportTypeId={dataReport?.reportTypeId}
              />
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <ButtonGroup
                    variant="outlined"
                    aria-label="Loading button group"
                  >
                    {pumps?.map((pump, index) => (
                      <Button
                        key={pump.id}
                        disabled={selectedPump === pump.id}
                        onClick={() => loadPumpMotor(index)}
                      >
                        <CycloneIcon />
                        {`Pmp ${index + 1}`}
                      </Button>
                    ))}
                  </ButtonGroup>
                </Grid>
              </Grid>
              {pumpValues?.systemMotorOperatesFromAVfDPwmDevice ? (
                <>
                  <br />
                  <Alert severity="info">
                    INFORMATION
                    <br />
                    The Motor for this Pump operates from a VFD/PWM Device.
                    <br />
                    Starter/Breaker selection not required.
                  </Alert>
                </>
              ) : (
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <h3>Starter</h3>
                    <FormCheckBox
                      size="medium"
                      name="isStarter"
                      label="Starter"
                      onChange={handleInputChange}
                      value={values?.isStarter || false}
                      showSkeleton={
                        isLoadingPumpStarter || isLoading || isLoadingFormula
                      }
                      disabled={disableStudy()}
                    />
                  </Grid>
                  {values?.isStarter && (
                    <>
                      <Grid item xs={12}>
                        <FormSelect
                          name="starterMfgId"
                          label="Starter manufacturer"
                          error={errors.starterMfgId}
                          onChange={handleInputChange}
                          options={starterMfgs}
                          value={values?.starterMfgId || 0}
                          showSkeleton={
                            isLoadingPumpStarter ||
                            isLoading ||
                            isLoadingStarterMfg ||
                            isLoadingFormula
                          }
                          disabled={disableStudy()}
                        />
                      </Grid>
                      {catalogValueOtherEval(
                        values?.starterMfgId || 0,
                        starterMfgs as IDefaultComboBoxCatalog[]
                      ) && (
                        <Grid item xs={12}>
                          <FormText
                            name="otherStarterMfg"
                            error={errors.otherStarterMfg}
                            label="Other starter manufacturer"
                            onChange={handleInputChange}
                            placeholder="Type the starter manufacturer here"
                            value={values?.otherStarterMfg || ""}
                            showSkeleton={
                              isLoadingPumpStarter ||
                              isLoading ||
                              isLoadingStarterMfg ||
                              isLoadingFormula
                            }
                            disabled={disableStudy()}
                          />
                        </Grid>
                      )}
                      <Grid item xs={12} md={6}>
                        <FormSelect
                          name="starterSize"
                          label="Starter size"
                          onChange={handleInputChange}
                          options={starterSizes}
                          value={values?.starterSize || ""}
                          showSkeleton={
                            isLoadingPumpStarter ||
                            isLoading ||
                            isLoadingFormula
                          }
                          fullWidth={false}
                          width="90%"
                          disabled={disableStudy()}
                        />
                        <FormTooltip
                          text={starterSizesBtnTooltip}
                          onClick={handleOpenStarterSizesPopup}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <FormSelect
                          name="quantityOfHeaters"
                          label="Quantity of heaters"
                          onChange={handleInputChange}
                          options={quantitiesOfHeaters}
                          value={values?.quantityOfHeaters || 0}
                          showSkeleton={
                            isLoadingPumpStarter ||
                            isLoading ||
                            isLoadingFormula
                          }
                          disabled={disableStudy()}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <FormText
                          name="heaterStamping"
                          label="Heater stamping"
                          onChange={handleInputChange}
                          placeholder="Heater stamping"
                          value={values?.heaterStamping || ""}
                          showSkeleton={
                            isLoadingPumpStarter ||
                            isLoading ||
                            isLoadingFormula
                          }
                          disabled={disableStudy()}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <FormText
                          name="heaterRangeStampingRated"
                          label="Heater range rated @"
                          onChange={handleInputChange}
                          placeholder="Heater range rated @"
                          value={values?.heaterRangeStampingRated || ""}
                          showSkeleton={
                            isLoadingPumpStarter ||
                            isLoading ||
                            isLoadingFormula
                          }
                          disabled={disableStudy()}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FormCheckBox
                          size="medium"
                          name="incorrectHeaters"
                          label="Incorrect Heaters"
                          onChange={handleInputChange}
                          value={values?.incorrectHeaters || false}
                          showSkeleton={
                            isLoadingPumpStarter ||
                            isLoading ||
                            isLoadingFormula
                          }
                          disabled={disableStudy()}
                        />
                      </Grid>
                      {values?.incorrectHeaters && (
                        <>
                          <Grid item xs={12} md={6}>
                            <FormText
                              name="heatersRequired"
                              label="Heaters required"
                              onChange={handleInputChange}
                              placeholder="Heaters required"
                              value={values?.heatersRequired || ""}
                              showSkeleton={
                                isLoadingPumpStarter ||
                                isLoading ||
                                isLoadingFormula
                              }
                              disabled={disableStudy()}
                            />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <FormText
                              name="heaterRangeRequiredRate"
                              label="Required Heater range rated @"
                              onChange={handleInputChange}
                              placeholder="Required Heater range rated @"
                              value={values?.heaterRangeRequiredRate || ""}
                              showSkeleton={
                                isLoadingPumpStarter ||
                                isLoading ||
                                isLoadingFormula
                              }
                              disabled={disableStudy()}
                            />
                          </Grid>
                        </>
                      )}
                      <Grid item xs={12}>
                        <FormText
                          name="incomingAWGWireSize"
                          label="Incoming AWG Wire Size supplying power to the starter/panel"
                          onChange={handleInputChange}
                          placeholder="Incoming AWG Wire Size supplying power to the starter/panel"
                          value={values?.incomingAWGWireSize || ""}
                          showSkeleton={
                            isLoadingPumpStarter ||
                            isLoading ||
                            isLoadingFormula
                          }
                          disabled={disableStudy()}
                        />
                      </Grid>
                    </>
                  )}
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                  <Grid item xs={12}>
                    <h3>Breaker</h3>
                    <FormCheckBox
                      size="medium"
                      name="isBreaker"
                      label="Breaker"
                      onChange={handleInputChange}
                      value={values?.isBreaker || false}
                      showSkeleton={
                        isLoadingPumpStarter || isLoading || isLoadingFormula
                      }
                      disabled={disableStudy()}
                    />
                  </Grid>
                  {values?.isBreaker && (
                    <>
                      <Grid item xs={12}>
                        <FormSelect
                          name="breakerMfgId"
                          label="Breaker manufacturer"
                          error={errors.breakerMfgId}
                          onChange={handleInputChange}
                          options={breakerMfgs}
                          value={values?.breakerMfgId || 0}
                          showSkeleton={
                            isLoadingPumpStarter ||
                            isLoading ||
                            isLoadingBreakerMfg ||
                            isLoadingFormula
                          }
                          disabled={disableStudy()}
                        />
                      </Grid>
                      {catalogValueOtherEval(
                        values?.breakerMfgId || 0,
                        breakerMfgs as IDefaultComboBoxCatalog[]
                      ) && (
                        <Grid item xs={12}>
                          <FormText
                            name="otherBreakerMfg"
                            error={errors.otherBreakerMfg}
                            label="Other breaker manufacturer"
                            onChange={handleInputChange}
                            placeholder="Type the breaker manufacturer here"
                            value={values?.otherBreakerMfg || ""}
                            showSkeleton={
                              isLoadingPumpStarter ||
                              isLoading ||
                              isLoadingBreakerMfg ||
                              isLoadingFormula
                            }
                            disabled={disableStudy()}
                          />
                        </Grid>
                      )}
                      <Grid item xs={12} md={6}>
                        <FormText
                          name="breakerModel"
                          label="Breaker model #"
                          onChange={handleInputChange}
                          placeholder="Breaker model #"
                          value={values?.breakerModel || ""}
                          showSkeleton={
                            isLoadingPumpStarter ||
                            isLoading ||
                            isLoadingFormula
                          }
                          disabled={disableStudy()}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <FormNumeric2
                          label="Breaker rated @"
                          placeholder="0.0"
                          name="breakerRated"
                          value={values?.breakerRated}
                          decimalScale={1}
                          fixedDecimalScale
                          maxValue={1000000}
                          onChange={handleInputChange}
                          showSkeleton={
                            isLoadingPumpStarter ||
                            isLoading ||
                            isLoadingFormula
                          }
                          disabled={disableStudy()}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <p>Wrong Breaker</p>
                        <FormCheckBox
                          size="medium"
                          name="breakerIsTooLarge"
                          label="Breaker installed was too large"
                          onChange={handleBreakerIsTooLargeChange}
                          value={values?.breakerIsTooLarge || false}
                          showSkeleton={
                            isLoadingPumpStarter ||
                            isLoading ||
                            isLoadingFormula
                          }
                          disabled={disableStudy()}
                        />
                        <FormCheckBox
                          size="medium"
                          name="breakerIsTooSmall"
                          label="Breaker installed was too small"
                          onChange={handleBreakerIsTooSmallChange}
                          value={values?.breakerIsTooSmall || false}
                          showSkeleton={
                            isLoadingPumpStarter ||
                            isLoading ||
                            isLoadingFormula
                          }
                          disabled={disableStudy()}
                        />
                      </Grid>
                    </>
                  )}
                </Grid>
              )}
              <FooterButtons
                activeStep={activeStep}
                stepsCount={steps.length}
                projectId={pumpReportValues?.projectId as number}
                companyId={user?.companyId as number}
                isSaving={isUpdating}
                disabled={isUpdating || isLoading || disableStudy()}
                reportName={title as string}
                saveHandler={saveHandler}
                nextStepHandler={nextStepHandler}
                previousStepHandler={previousStepHandler}
                isComplete={pumpReportValues?.isComplete ?? true}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default PumpStep4;
