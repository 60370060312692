import axios from "../utils/axios";
import {
  IMemorandumForward,
  IMemorandumForwardRequest,
} from "src/ts/interfaces/memorandumForward";

const create = async (item: IMemorandumForwardRequest) => {
  return axios.post("memorandumForward", item);
};

const getAll = async (id: number) => {
  return axios.get<IMemorandumForward[]>(`memorandumForward/${id}`);
};

const remove = async (id: number) => {
  return axios.delete(`memorandumForward?id=${id}`);
};

const send = async (id: number) => {
  return axios.get(`memorandumForward/sendEmails/${id}`);
};

const memorandumForwardService = {
  create,
  getAll,
  remove,
  send,
};

export default memorandumForwardService;
