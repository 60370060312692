import { useEffect, useState } from "react";
import {
  IconButton,
  Stack,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import { ColumnType } from "src/types/enhancedTable";
import { ITicketData } from "src/ts/interfaces/tickets";
import { useTable } from "src/hooks/useTable";
import { FilterType } from "./types/FilterTypes";
import { getQueryString } from "./utils/common";
import TicketService from "src/services/ticketService";
import { PriorityEnum } from "src/ts/enums";
import FormSelect from "src/components/formControls/FormSelect";
import {
  ImageIcon,
  HideImageIcon,
  PersonIcon,
  InfoIcon,
} from "src/components/icons";
import InformationTicketPopUp from "./components/InformationTicketPopUp";
import AssignTicketPopUp from "./components/AssignTicketPopUp";
import ImageTicketPopUp from "./components/ImageTicketPopUp";
import useLog from "src/hooks/useLog";
import useLoading from "src/hooks/useLoading";
import { ToolTip } from "src/components/others/ToolTip";
import userService from "src/services/userService";
import { usePermissions } from "src/hooks";
import { Permission as PermissionTypes } from "src/ts/enums";

const priorities = [
  { key: PriorityEnum.High, value: PriorityEnum.High },
  { key: PriorityEnum.Medium, value: PriorityEnum.Medium },
  { key: PriorityEnum.Low, value: PriorityEnum.Low },
];

interface NewTicketProps {
  filters: FilterType;
  refresh: boolean;
  onRefreshGrid: () => void;
}

function NewTicket(props: NewTicketProps) {
  const [showInformationTicketPopup, setShowInformationTicketPopup] =
    useState(false);
  const [showImageTicketPopup, setShowImageTicketPopup] = useState(false);
  const [showAssignTicketPopup, setShowAssignTicketPopup] = useState(false);
  const [informationId, setInformationId] = useState([]);
  const [administratorList, setAdministratorList] = useState({});
  const [refresh, setRefresh] = useState(false);

  const { log } = useLog();
  const { isLoading, startRequest, endRequest } = useLoading();

  const { readOnly } = usePermissions(PermissionTypes.Assign_Support_Tickets);

  interface EditValueProps {
    informationRow: any;
    initialValue: string;
  }

  const EditValue = (props: EditValueProps) => {
    const { initialValue, informationRow } = props;
    const [value, setValue] = useState(initialValue);

    const handleSetValue = async (event: SelectChangeEvent<any>) => {
      setValue(event.target.value);
      await TicketService.postChangePriority({
        ticketId: informationRow.id,
        priority: event.target.value,
      });
      log.info("New priority was set for the ticket.");
    };

    return readOnly ? (
      <Typography>{value}</Typography>
    ) : (
      <FormSelect
        name="value"
        label="Value"
        value={value}
        onChange={handleSetValue}
        options={priorities}
        readOnly={readOnly}
      />
    );
  };

  const getColumns = (
    setShowInformationTickePopup: (val: boolean) => void,
    setInformationId: any,
    setShowAssignTicketPopup: (val: boolean) => void,
    setShowImageTicketPopup: (val: boolean) => void
  ): ColumnType[] => [
    {
      id: "id",
      label: "ID",
      type: "string",
      sort: true,
      callback: (row: ITicketData) => {
        return row.id;
      },
    },
    {
      id: "classification",
      label: "Classification",
      type: "custom",
      sort: true,
      callback: (row: ITicketData) => {
        return row.classification.name;
      },
    },
    {
      id: "title",
      label: "Title",
      type: "string",
      sort: true,
      callback: (row: ITicketData) => {
        return row.title;
      },
    },
    {
      id: "createdBy",
      label: "Created By",
      type: "custom",
      sort: true,
      callback: (row: ITicketData) => {
        return `${row.anonymousName ?? ""}${
          row.created !== null ? row.created.name : ""
        } ${row.created !== null ? row.created.lastName : ""}`;
      },
    },
    {
      id: "createdDate",
      label: "Created Date",
      type: "date",
      format: "MM/DD/YYYY",
      sort: true,
    },
    {
      id: "priority",
      label: "Priority",
      type: "custom",
      sort: true,
      callback: (row: ITicketData) => (
        <>
          <EditValue
            initialValue={row.priority.toString()}
            informationRow={row}
          />
        </>
      ),
    },
    {
      id: "action",
      label: "Action",
      type: "custom",
      sort: false,
      callback: (row: ITicketData) => (
        <>
          <Stack direction="row">
            <ToolTip title="Review Ticket">
              <IconButton
                aria-label="details"
                size="large"
                onClick={() => {
                  setShowInformationTickePopup(true);
                  setInformationId(row);
                }}
              >
                <InfoIcon />
              </IconButton>
            </ToolTip>
            {!readOnly && (
              <ToolTip title="Assig to User">
                <IconButton
                  aria-label="assigned"
                  size="large"
                  onClick={() => {
                    setShowAssignTicketPopup(true);
                    setInformationId(row);
                  }}
                >
                  <PersonIcon />
                </IconButton>
              </ToolTip>
            )}
            <ToolTip title="Review Attachement">
              <IconButton aria-label="image" size="large">
                {row.document === null ? (
                  <HideImageIcon onClick={() => {}} />
                ) : (
                  <ImageIcon
                    onClick={() => {
                      setShowImageTicketPopup(true);
                      setInformationId(row);
                    }}
                  />
                )}
              </IconButton>
            </ToolTip>
          </Stack>
        </>
      ),
    },
  ];

  let columns = getColumns(
    setShowInformationTicketPopup,
    setInformationId,
    setShowAssignTicketPopup,
    setShowImageTicketPopup
  );

  const { Table, setRowsPerPage, page, setDataSet, setCount, orderBy, order } =
    useTable(columns, { cellCheckBox: false });

  const handleRefresh = () => {
    setRefresh(!refresh);
  };

  useEffect(() => {
    const getData = async () => {
      try {
        startRequest("getDataNewTicket");
        const request = await TicketService.getAll(
          {
            page: page + 1,
            sortColumn: orderBy,
            sortDec: order === "desc" ? true : false,
            filter: props.filters.filter,
            dateFrom: props.filters.from,
            dateTo: props.filters.to,
            status: "New",
          },
          getQueryString
        );
        endRequest("getDataNewTicket");
        setCount(request.data.count);
        setDataSet(request.data.data);
        setRowsPerPage(10);
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      }
    };

    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, orderBy, order, setCount, setDataSet, setRowsPerPage]);

  useEffect(() => {
    const getData = async () => {
      try {
        startRequest("getDataNewTicket");
        const request = await TicketService.getAll(
          {
            page: page + 1,
            sortColumn: "createdDate",
            sortDec: order === "desc" ? true : false,
            filter: props.filters.filter,
            dateFrom: props.filters.from,
            dateTo: props.filters.to,
            status: "New",
          },
          getQueryString
        );
        endRequest("getDataNewTicket");
        setCount(request.data.count);
        setDataSet(request.data.data);
        setRowsPerPage(10);
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      }
    };

    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.refresh]);

  useEffect(() => {
    const getAdmin = async () => {
      const response = await userService.getTicketAdministrators();

      setAdministratorList(
        response.data.map((item: any) => {
          return {
            ...administratorList,
            key: item.id,
            value: `${item.name} ${item.lastName}`,
          };
        })
      );
    };
    getAdmin();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isLoading) handleRefresh();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  return (
    <>
      <Table />
      {showInformationTicketPopup ? (
        <InformationTicketPopUp
          showInformationTicketPopup={showInformationTicketPopup}
          setShowInformationTicketPopup={setShowInformationTicketPopup}
          informationId={informationId}
        />
      ) : null}
      {showAssignTicketPopup ? (
        <AssignTicketPopUp
          showAssignTicketPopup={showAssignTicketPopup}
          setShowAssignTicketPopup={setShowAssignTicketPopup}
          informationId={informationId}
          administratorList={administratorList}
          onRefreshGrid={props.onRefreshGrid}
        />
      ) : null}
      {showImageTicketPopup ? (
        <ImageTicketPopUp
          showImageTicketPopup={showImageTicketPopup}
          setShowImageTicketPopup={setShowImageTicketPopup}
          informationId={informationId}
        />
      ) : (
        ""
      )}
    </>
  );
}

export default NewTicket;
