import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import {
  CardContent,
  CssBaseline,
  Grid,
  Link,
  Card as MuiCard,
  Typography,
  Divider as MuiDivider,
  Stack,
} from "@mui/material";
import { useAsyncMutation, useAsyncQuery, useLog, useTheme } from "src/hooks";
import { useNavigate, useParams } from "react-router-dom";
import GlobalStyle from "src/components/GlobalStyle";
import { Box, spacing } from "@mui/system";
import { Helmet } from "react-helmet-async";
import { FormButton } from "src/components/formControls";
import { THEMES } from "src/constants";
import { Check } from "@mui/icons-material";
import DialogMessagePopup from "src/components/DialogMessagePopup";
import unsubscribeService from "src/services/unsubscribeService";
import {
  IUnsubscribeData,
  IUnsubscribeRequest,
} from "src/ts/interfaces/unsubscribeData";
const Card = styled(MuiCard)(spacing);
const Root = styled.div`
  max-width: 90%;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  display: flex;
  min-height: 100%;
  flex-direction: column;
`;

function Unsubscribe() {
  const { theme } = useTheme();
  const { log } = useLog();
  const Divider = styled(MuiDivider)(spacing);
  const navigate = useNavigate();
  let { guid } = useParams<{ guid: string }>();
  let { key } = useParams<{ key: string }>();
  const [confirmModal, setConfirmModal] = useState(false);

  const { execute: executeLoad, data } = useAsyncQuery<IUnsubscribeData>(
    unsubscribeService.getByCode
  );

  const { execute, isSubmitting } = useAsyncMutation(
    unsubscribeService.updateByCode,
    {
      hideSuccessfulMessage: true,
      onSuccess: async () => {
        await executeLoad(guid, key);
        setConfirmModal(false);
        log.success(`The change was made successfully`);
      },
    }
  );

  useEffect(() => {
    const getData = async () => {
      await executeLoad(guid, key);
    };
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleConfirm = async () => {
    const confirmData: IUnsubscribeRequest = {
      code: guid ?? "",
      key: key ?? "",
    };
    await execute(confirmData);
  };

  const handlGotoLogin = () => {
    navigate("/auth/sign-in");
  };

  return (
    <Root>
      <CssBaseline />
      <GlobalStyle />
      <Grid container spacing={2} pt={10}>
        <Grid item xs={3}></Grid>
        <Grid item xs={6}>
          <Card mb={1}>
            <CardContent>
              <Helmet title="Confirm" />
              <Link href="https://www.auditmaster.pro/" target="_blank">
                <Box
                  component="img"
                  alt="Logo AMP"
                  src={
                    THEMES.DARK === theme
                      ? "/static/img/AMPWebLogoVectors.svg"
                      : "/static/img/AMPWebLogoVectorsLight.svg"
                  }
                  sx={{
                    marginLeft: "35%",
                    marginRight: "35%",
                    width: "30%",
                    marginBottom: "20px",
                  }}
                />
              </Link>
              <Divider mb={4} />
              {data && !data?.isValid && (
                <Grid container spacing={5}>
                  <Grid item xs={12}>
                    <Typography variant="h3" pb={2} textAlign={"center"}>
                      You're Unsubscribed.
                    </Typography>
                    <Typography variant="h6" textAlign={"center"}>
                      You were successfully unsubscribed from{" "}
                      {data?.emailTemplateName} email.
                    </Typography>
                    <Typography variant="h6" textAlign={"center"}>
                      You can reactivate the emails by selecting the option on
                      your AMP user profile page.
                    </Typography>
                  </Grid>
                  <Grid item xs={12} textAlign={"center"}>
                    <FormButton
                      onClick={handlGotoLogin}
                      isSubmitting={isSubmitting}
                      text="Go to AMP"
                      size="small"
                      variant="outlined"
                    ></FormButton>
                  </Grid>
                </Grid>
              )}
              {data && data?.isValid && (
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Typography variant="h3" pb={2} textAlign={"center"}>
                      Unsubscribe
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="subtitle2" pl={5}>
                      Your email address: {data?.email}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="subtitle2" pl={5}>
                      Will not receive any more emails from:{" "}
                      {data?.emailTemplateName}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} textAlign={"center"} spacing={2}>
                    <Divider mb={4} mt={3} />
                    <Stack
                      direction="row"
                      spacing={2}
                      justifyContent="center"
                      alignItems="center"
                    >
                      <FormButton
                        onClick={() => {
                          setConfirmModal(true);
                        }}
                        isSubmitting={isSubmitting}
                        text="Yes, Unsubscribe"
                        size="small"
                        startIcon={<Check />}
                      ></FormButton>
                    </Stack>
                  </Grid>
                </Grid>
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <DialogMessagePopup
        title={"Unsubscribe "}
        text={
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Typography>{`Are you certain you want to unsubscribe?`}</Typography>
            </Grid>
          </Grid>
        }
        showPopup={confirmModal}
        setShowPopup={setConfirmModal}
        isSubmitting={isSubmitting}
        onSave={handleConfirm}
      ></DialogMessagePopup>
    </Root>
  );
}
export default Unsubscribe;
