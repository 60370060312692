/* eslint-disable react-hooks/exhaustive-deps */
import {
  CardContent,
  Grid,
  Divider as MuiDivider,
  Card as MuiCard,
} from "@mui/material";
import CompleteStudyPopup from "../../components/CompleteStudyPopup";
import projectReportService from "src/services/study/projectReportService";
import getProjectSingleService from "src/services/getProjectSingleService";
import { spacing } from "@mui/system";
import styled from "@emotion/styled";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import headSheetService from "src/services/study/headSheetService";
import { useAuth, useLog } from "src/hooks";
import useFormTyped from "src/hooks/useFormTyped";
import { IHeadSheetDTO } from "src/ts/interfaces";
import {
  IProjectReport,
  ProjectDTO,
} from "src/ts/interfaces/project/projectDto";
import { headSheetType, steps } from "../const/consts";
import { FooterButtons } from "../../components/FooterButtons";
import HeaderStudyPage from "src/components/page/HeaderStudyPage";
import StudyWizard from "../../components/StudyWizard";

const initialValues: any = {
  projectName: "",
  system: "",
  equipmentLocation: "",
  conditionOfTest: "",
};

const Divider = styled(MuiDivider)(spacing);
const Card = styled(MuiCard)(spacing);

const HeadSheetStep6 = () => {
  const { log } = useLog();
  const { user } = useAuth();
  const params = useParams<{ id: string }>();
  const id = parseInt(params?.id === undefined ? "0" : params?.id);
  const [project, setProject] = useState<ProjectDTO | null>();
  const [projectReport, setProjectReport] = useState<IProjectReport | null>();
  const [parentStudy, setParentStudy] = useState<IProjectReport | null>();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [title, setTitle] = useState<string>();
  const [code, setCode] = useState<string>();
  const [route, setRoute] = useState<string>();

  let navigate = useNavigate();

  const validate: any = (fieldValues = values) => {
    let temp: Record<string, string> = { ...errors };

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors } = useFormTyped<IHeadSheetDTO>(
    initialValues,
    false,
    validate
  );

  const activeStep = 5;

  const getProject = async (projectId: number) => {
    const projectResponse = await getProjectSingleService.single(projectId);
    setProject(projectResponse.data);
  };

  const getData = async () => {
    try {
      const res = await headSheetService.getById(id);
      setValues(res.data);
    } catch (error: any) {
      log.error(error?.message?.exceptionMessage ?? "Something went wrong");
    }
  };

  useEffect(() => {
    if (values.reportTypeId) {
      const [{ title, code, route }] = headSheetType.filter(
        (x) => x.key === values?.reportTypeId
      );
      setTitle(title);
      setCode(code);
      setRoute(route);
    }
  }, [values.reportTypeId]);

  useEffect(() => {
    if (id !== 0) {
      getData();
    }
  }, [id]);

  useEffect(() => {
    if (values.id && values.reportTypeId && values.projectId && code) {
      getProject(values.projectId as number);
      getProjectReport(
        values.id as number,
        values.reportTypeId as number,
        values.projectId as number
      );
      getParentReportIsComplete(values.id as number, code as string);
    }
  }, [values.id, values.reportTypeId, values.projectId, code]);

  const getParentReportIsComplete = async (
    reportId: number,
    reportType: string
  ) => {
    try {
      const res = await projectReportService.report(reportId, reportType);
      setParentStudy(res.data);
    } catch (error) {}
  };

  const getProjectReport = async (
    reportId: number,
    reportTypeId: number,
    projectId: number
  ) => {
    const projectReportResposne = await projectReportService.projectReport(
      reportId,
      reportTypeId,
      projectId
    );
    setProjectReport(projectReportResposne.data);
  };

  const saveHandler = async () => {};

  const previousStepHandler = () => {
    if (!validate()) return;
    try {
      navigate(`/studies/${route}/step5/${id}`);
    } catch (error) {}
  };

  return (
    <>
      <HeaderStudyPage
        headerStudiesPage={{ code: code, system: values?.system, id: id }}
        parentText="Project"
        parentLink={`/app/ProjectStudies/${values?.projectId}/${user?.companyId}`}
      />
      <Divider my={6} />

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card mb={6}>
            <CardContent>
              <StudyWizard
                steps={steps}
                activeStep={activeStep}
                projectId={projectReport?.projectId}
                reportId={projectReport?.reportId}
                reportTypeId={projectReport?.reportTypeId}
              />
              <Grid container spacing={5}>
                <Grid item xs={12}>
                  {project && projectReport && values && (
                    <CompleteStudyPopup
                      isDialogOpen={isDialogOpen}
                      setIsDialogOpen={setIsDialogOpen}
                      project={project}
                      projectReport={projectReport}
                      completed={values?.isComplete}
                      infectiousComplianceControl={null}
                      parentStudy={parentStudy ?? null}
                    />
                  )}
                </Grid>
              </Grid>
              <FooterButtons
                activeStep={activeStep}
                stepsCount={steps.length}
                projectId={values?.projectId as number}
                companyId={values.user?.companyId as number}
                isSaving={false}
                disabled={false}
                reportName={title as string}
                previousStepHandler={previousStepHandler}
                saveHandler={saveHandler}
                isComplete={values?.isComplete ?? true}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default HeadSheetStep6;
