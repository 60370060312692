import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import {
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  CardContent,
  Typography,
  Grid,
  Stack,
} from "@mui/material";
import { spacing } from "@mui/system";
import useForm, { Form } from "src/hooks/useForm";
import FormText from "src/components/formControls/FormText";
import { FormActionButton, FormDatePicker } from "src/components/formControls";
import { ICouponsHistoryData } from "src/ts/interfaces/history";
import { FilterType } from "./types/FilterTypes";
import HistoryService from "src/services/historyService";
import moment from "moment/moment.js";
import { useTable } from "src/hooks/useTable";
import { ColumnType } from "src/types/enhancedTable";
import { getQueryString } from "./utils/common";
import { useLog } from "src/hooks";
import { Validator } from "src/ts/types/Validator";

const Divider = styled(MuiDivider)(spacing);
const Card = styled(MuiCard)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const initialValues: FilterType = {
  filter: "",
  from: moment().startOf("month").format("MM/DD/YYYY"),
  to: moment().format("MM/DD/YYYY"),
};

function CouponsHistroy() {
  const [refreshGrid, setRefreshGrid] = useState(false);
  const { log } = useLog();

  const validate = (fieldValues = values) => {
    let temp: Record<string, string> = { ...errors };

    temp.from = new Validator(fieldValues, "from")
      .isRequired("The date is required.")
      .toString();

    temp.to = new Validator(fieldValues, "to")
      .isRequired("The date is required.")
      .toString();

    const end = new Date(fieldValues.to);
    const start = new Date(fieldValues.from);
    if (fieldValues.to && fieldValues.from) {
      if (end < start) {
        temp.from = `Start date can not be greater than  ${moment(end).format(
          "MM/DD/YYYY"
        )}`;
        temp.to = `End date can not be less than  ${moment(start).format(
          "MM/DD/YYYY"
        )}`;
      } else {
        temp.from = "";
        temp.to = "";
      }
    }

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, errors, setErrors, handleInputChange, setValues } = useForm(
    initialValues,
    false,
    validate
  );
  const [isSubmitting, setIsSubmitting] = useState(false);

  const getColumns = (): ColumnType[] => [
    {
      id: "id",
      label: "Id",
      type: "string",
      sort: true,
      align: "center",
      hide: true,
    },
    {
      id: "code",
      label: "Coupon Code",
      type: "custom",
      sort: true,
      width: "150px",
      align: "center",
      callback: (row: ICouponsHistoryData) => (
        <>
          <Typography>{row.coupon.code}</Typography>
        </>
      ),
    },
    {
      id: "couponType",
      label: "Coupon Type",
      type: "custom",
      sort: true,
      callback: (row: ICouponsHistoryData) => (
        <>
          <Typography>{row.coupon.type.name}</Typography>
        </>
      ),
    },
    {
      id: "createdDate",
      label: "Date",
      type: "utcDate",
      format: "MM/DD/YYYY h:mma",
      sort: true,
      align: "left",
    },
    {
      id: "createdBy",
      label: "Applied By",
      type: "custom",
      sort: true,
      align: "left",
      callback: (row: ICouponsHistoryData) => (
        <>
          <Typography>
            {row.createdBy.name} {row.createdBy.lastName}
          </Typography>
        </>
      ),
    },
    {
      id: "projectName",
      label: "Name",
      type: "custom",
      sort: true,
      align: "left",
      width: "200px",
      callback: (row: ICouponsHistoryData) => {
        var names = row.projectName ?? "";
        var fields = names.split("~");
        return (
          <>
            {fields.length > 0 ? (
              <>
                {fields.map((item: any) => (
                  <>{item.length > 0 && <Typography>{item}</Typography>}</>
                ))}
              </>
            ) : (
              <Typography>{row.projectName ?? ""}</Typography>
            )}
          </>
        );
      },
    },

    {
      id: "discount",
      label: "Discount",
      type: "custom",
      sort: true,
      width: "120px",
      align: "right",
      callback: (row: ICouponsHistoryData) => {
        const value =
          row.coupon.value.toString().length === 6
            ? row.coupon.value.toString().substring(0, 5)
            : row.coupon.value.toString();
        return (
          <>
            <Stack style={{ textAlign: "right" }}>
              <Typography>{value}%</Typography>
            </Stack>
          </>
        );
      },
    },
    {
      id: "originalCost",
      label: "Original Cost",
      type: "custom",
      sort: true,
      width: "150px",
      align: "right",
      callback: (row: ICouponsHistoryData) => {
        const originalCostValue = row.originalCost.toFixed(2);
        return (
          <>
            <Stack style={{ textAlign: "right" }}>
              <Typography>${originalCostValue}</Typography>
            </Stack>
          </>
        );
      },
    },
    {
      id: "costWithDiscount",
      label: "Cost With Discount",
      type: "custom",
      sort: true,
      width: "200px",
      align: "right",
      callback: (row: ICouponsHistoryData) => {
        const totalCostValue = (row.originalCost - row.discount).toFixed(2);

        return (
          <>
            <Stack style={{ textAlign: "right" }}>
              <Typography>${totalCostValue}</Typography>
            </Stack>
          </>
        );
      },
    },
  ];

  let columns = getColumns();

  const {
    Table,
    setRowsPerPage,
    page,
    setPage,
    setDataSet,
    setCount,
    orderBy,
    order,
  } = useTable(columns, { cellCheckBox: false });

  const handleRefreshGrid = () => {
    setPage(0);
    setRefreshGrid(!refreshGrid);
  };

  useEffect(() => {
    const getData = async () => {
      try {
        if (!validate()) return;

        setIsSubmitting(true);
        const request = await HistoryService.getAllCouponsHistory(
          {
            page: page + 1,
            sortColumn: orderBy,
            sortDec: order === "desc" ? true : false,
            filter: values.filter,
            dateFrom: new Date(`${values.from} 00:00`).toISOString(),
            dateTo: new Date(`${values.to} 23:59`).toISOString(),
          },
          getQueryString
        );

        setCount(request.data.count);
        setDataSet(request.data.data);
        //setRowsPerPage(10);
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      } finally {
        setIsSubmitting(false);
      }
    };

    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, orderBy, order, setCount, setDataSet, setRowsPerPage, refreshGrid]);

  const validateMinDate = (e: any) => {
    if (e.target.value !== "Invalid date") {
      let newValues = { ...values };
      newValues.from = e.target.value;

      if (Date.parse(newValues.to) < Date.parse(newValues.from))
        setValues({ ...newValues, to: newValues.from });
      else setValues({ ...newValues });
    }
  };

  return (
    <>
      <Helmet title="Coupons History" />
      <Typography variant="h3" gutterBottom display="inline">
        Coupons History
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/">
          Dashboard
        </Link>
        <Typography>Coupons History</Typography>
      </Breadcrumbs>

      <Divider my={6} />
      <Card mb={6}>
        <CardContent>
          <Grid container spacing={10}>
            <Grid item xs={12}>
              <Form>
                <Grid container spacing={5}>
                  <Grid item xs={4}>
                    <FormDatePicker
                      label={"Date From"}
                      name="from"
                      disablePast={false}
                      value={values.from}
                      onChange={validateMinDate}
                      error={errors.from}
                    />
                  </Grid>

                  <Grid item xs={4} textAlign={"right"}>
                    <FormDatePicker
                      label={"Date To"}
                      name="to"
                      disablePast={false}
                      value={values.to}
                      onChange={handleInputChange}
                      error={errors.to}
                      minDate={values.from}
                    />
                  </Grid>
                  <Grid item xs={8}>
                    <FormText
                      name="filter"
                      label="Type text to search"
                      value={values.filter}
                      onChange={handleInputChange}
                      error={errors.filter}
                    />
                  </Grid>

                  <Grid item xs={4}>
                    <FormActionButton
                      mr={2}
                      text="Search"
                      size="medium"
                      onClick={handleRefreshGrid}
                      type="search"
                      isSubmitting={isSubmitting}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Table />
                  </Grid>
                </Grid>
              </Form>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
}

export default CouponsHistroy;
