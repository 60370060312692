import React, { useCallback, useEffect, useLayoutEffect } from "react";
import { useRoutes } from "react-router-dom";
import { Provider } from "react-redux";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { CacheProvider } from "@emotion/react";
import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "./i18n";
import createTheme from "./theme";
import routes from "./routes";
import useTheme from "./hooks/useTheme";
import { store } from "./redux/store";
import createEmotionCache from "./utils/createEmotionCache";
import { useLocation } from "react-router-dom";
import AppInit from "./components/AppInit";
import { LoadingProvider } from "./contexts/LoadingContext";
import { useAppDispatch, useAppSelector, useAuth } from "./hooks";
import SignalRComponent from "./components/SignalRComponent";
import { floatingBarActions } from "./redux/slices/floatingBarActions";
import studiesHistoryService from "./services/study/studiesHistoryService";
import FormIdle from "./components/idle/Idle";
import { NotificationService } from "./services";
import { signalrActions } from "./redux/slices/signalrActions";
import { useInterval } from "./hooks";
import versionService from "./services/versionService";
import appGlobalActions from "./redux/slices/appSlice";

const clientSideEmotionCache = createEmotionCache();

const AppStore = () => {
  const content = useRoutes(routes);
  const { isInitialized, initialize } = useAuth();
  const { theme } = useTheme();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const report = useAppSelector((state) => state.floatingBar.report);

  useEffect(() => {
    if (report?.reportId !== 0 && report?.reportTypeId !== 0) {
      studiesHistoryService.save(
        report?.projectId ?? 0,
        report?.reportTypeId ?? 0,
        report?.reportId ?? 0,
        "leave",
        location.pathname
      );
    }

    return () => {
      dispatch(floatingBarActions.setChangeProjectId(0));
      dispatch(
        floatingBarActions.setReport({
          projectId: 0,
          reportId: 0,
          reportTypeId: 0,
        })
      );
      dispatch(floatingBarActions.setOptionMenu([]));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  useEffect(() => {
    initialize();
    const getNotifications = async () => {
      var countTickets = await NotificationService.getByType(2);
      dispatch(signalrActions.coutNewTicket(Number(countTickets.data ?? 0)));
      var newTechnician = await NotificationService.getByType(1);
      dispatch(signalrActions.newTechnician(Number(newTechnician.data ?? 0)));
      var newOutToBid = await NotificationService.getByType(13);
      dispatch(signalrActions.newOutToBid(Number(newOutToBid.data ?? 0)));
    };
    getNotifications();
    checkVersion();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkVersion = useCallback(async () => {
    if (process.env.REACT_APP_ENVIRONMENT === "DEV") return;

    const version = process.env.REACT_APP_VERSION ?? "0.0.0";
    const remoteVersion = await versionService.getVersion();
    console.log(version);
    console.log(remoteVersion);
    dispatch(
      appGlobalActions.actions.setVersions({
        newVersion: remoteVersion,
        currentVersion: version,
      })
    );
    dispatch(
      appGlobalActions.actions.setShowOutdatedVersionPopup(
        version !== remoteVersion
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useInterval(checkVersion, 10 * 60 * 1000); //10 min
  return (
    <>
      <FormIdle></FormIdle>
      <MuiThemeProvider theme={createTheme(theme)}>
        <SignalRComponent />
      </MuiThemeProvider>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <MuiThemeProvider theme={createTheme(theme)}>
          {isInitialized ? content : <AppInit />}
        </MuiThemeProvider>
      </LocalizationProvider>
    </>
  );
};

function App({ emotionCache = clientSideEmotionCache }) {
  return (
    <CacheProvider value={emotionCache}>
      <LoadingProvider>
        <HelmetProvider>
          <Helmet
            titleTemplate="%s | AuditMasterPro"
            defaultTitle="AuditMasterPro"
          />
          <Provider store={store}>
            <AppStore />
          </Provider>
        </HelmetProvider>
      </LoadingProvider>
    </CacheProvider>
  );
}

export default App;
