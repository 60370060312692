import React, { useState } from "react";
import { Stack, Typography } from "@mui/material";
import { ColumnType } from "src/types/enhancedTable";
import { useAsyncMutation, useAuth } from "src/hooks";
import LocalEnhancedTable from "src/components/localTable/LocalTable";
import { GridActionButton } from "src/components/gridControls";
import { ProjectAuditorListDTO } from "src/ts/interfaces";
import BuildIcon from "@mui/icons-material/Build";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import { projectCurrentService } from "src/services";
import styled from "@emotion/styled";
import { Grid, Card as MuiCard, CardContent } from "@mui/material";
import { spacing } from "@mui/system";
import { useForm, Form } from "src/hooks";
import FormText from "src/components/formControls/FormText";
import { ROLES } from "src/constants";
import DialogMessagePopup from "src/components/DialogMessagePopup";
import projectHistoryService from "src/services/project/projectHistoryService";
import { useNavigate } from "react-router-dom";

const Card = styled(MuiCard)(spacing);

const getColumns = (
  user: any,
  setCurrentRow: (row: ProjectAuditorListDTO) => void,
  setShowMakeProjectDlg: (value: boolean) => void,
  documentLibraryNavigate: (projectId: number) => void,
  pictureLogNavigate: (projectId: number) => void,
  projectNavigate: (projectId: number) => void
): ColumnType[] => {
  const showSetCurrentProject =
    user?.canAddBuildingsAndProjects === "true" ||
    user?.role === ROLES.SuperAdministrator;

  return [
    {
      id: "isInfectiousControlProject",
      label: "Type",
      type: "custom",
      sort: true,
      callback: (row: ProjectAuditorListDTO) => {
        return (
          <div>
            {row.isInfectiousControlProject ? (
              <VerifiedUserIcon />
            ) : (
              <BuildIcon />
            )}
          </div>
        );
      },
    },
    { id: "id", label: "Project Id", type: "string", sort: true },
    {
      id: "code",
      label: "Code",
      type: "custom",
      sort: true,
      callback: (row: ProjectAuditorListDTO) => {
        return (
          <>
            {row.code}
            {row.companyOwnerId != null && row.companyOwnerId !== 0 && (
              <Typography variant="subtitle2">
                Assigned and Awarded Building Professional Project
              </Typography>
            )}
          </>
        );
      },
    },
    { id: "name", label: "Name", type: "string", sort: true },
    { id: "buildingName", label: "Building", type: "string", sort: true },
    {
      id: "createdDate",
      label: "Created Date",
      type: "utcDate",
      sort: true,
      format: "MM/DD/YYYY",
    },
    {
      id: "na",
      label: "Set Current Project",
      type: "custom",
      sort: false,
      hide: !showSetCurrentProject,
      callback: (row: ProjectAuditorListDTO) => (
        <>
          {!(
            (user?.role === "Auditor" || user?.role === "TemporalAuditor") &&
            row.isInfectiousControlProject
          ) && (
            <GridActionButton
              type="enable"
              onClick={() => {
                setCurrentRow(row);
                setShowMakeProjectDlg(true);
              }}
              tooltip="Make this project the current project"
            />
          )}
        </>
      ),
    },
    {
      id: "actions",
      label: "Actions",
      type: "custom",
      sort: false,
      callback: (row: ProjectAuditorListDTO) => {
        return (
          <div>
            <Stack direction="row">
              <GridActionButton
                type="forward"
                onClick={() => projectNavigate(row.id)}
                tooltip="Go to project"
              />
              <GridActionButton
                type="folder"
                onClick={() => documentLibraryNavigate(row.id)}
                tooltip="Project's Document Library"
              />
              <GridActionButton
                type="picture"
                onClick={() => pictureLogNavigate(row.id)}
                tooltip="Project's picture library"
              />
            </Stack>
          </div>
        );
      },
    },
  ];
};

const initialValues = {
  id: 0,
  name: "",
  statusId: "",
};

interface Props {
  data: ProjectAuditorListDTO[];
  isLoading: boolean;
  refreshGrid: boolean;
  setRefreshGrid: (value: boolean) => void;
}

const ActiveProjectGrid = (props: Props) => {
  const { data, isLoading, refreshGrid, setRefreshGrid } = props;
  const navigate = useNavigate();
  const [showMakeProjectDlg, setShowMakeProjectDlg] = useState(false);
  const [currentRow, setCurrentRow] = useState<ProjectAuditorListDTO>();
  const { user } = useAuth();

  const { values: filterValues, handleInputChange: handleFilterChange } =
    useForm(initialValues, true, []);

  const {
    execute: makeCurrentProject,
    isSubmitting: makeCurrentProjectIsSubmtting,
  } = useAsyncMutation(projectCurrentService.updateProjectCurrentTechnician, {
    successfulMessage: "The current project was updated",
  });

  const handleMakeCurrentProject = async () => {
    await makeCurrentProject(currentRow?.id);
    setRefreshGrid(!refreshGrid);
    await projectHistoryService.log(
      currentRow?.id ?? 0,
      "Project was set as current"
    );
  };

  const documentLibraryNavigate = (projectId: number) => {
    navigate(`/app/DocumentLibrary/${projectId}/${user?.companyId}/Working`);
  };

  const pictureLogNavigate = (projectId: number) => {
    navigate(`/app/PhotoLibrary/${projectId}/${user?.companyId}/Working`);
  };

  const projectNavigate = (projectId: number) => {
    navigate(`/app/Project/Edit/${projectId}/${user?.companyId}`);
  };

  const columns = getColumns(
    user,
    setCurrentRow,
    setShowMakeProjectDlg,
    documentLibraryNavigate,
    pictureLogNavigate,
    projectNavigate
  );

  return (
    <>
      <Card mb={6}>
        <CardContent>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Typography variant="h6">
                Active project(s) which you have been part of before
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Form>
                <Grid container>
                  <Grid item xs={12}>
                    <FormText
                      name="name"
                      label="Search"
                      value={filterValues.name}
                      onChange={handleFilterChange}
                    />
                  </Grid>
                </Grid>
              </Form>
            </Grid>
            <Grid item xs={12}>
              <LocalEnhancedTable<ProjectAuditorListDTO>
                refreshGrid={refreshGrid}
                columns={columns}
                data={data}
                query={filterValues.name}
                showSkeleton={isLoading}
                defaultRowPerPage={10}
                defaultSortColumn="createdDate"
                orderColumn="desc"
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <DialogMessagePopup
        title={"Warning"} //
        text={`Are you certain you want make the project: ${currentRow?.name} your current project?`}
        showPopup={showMakeProjectDlg}
        setShowPopup={setShowMakeProjectDlg}
        onSave={handleMakeCurrentProject}
        isSubmitting={makeCurrentProjectIsSubmtting}
      />
    </>
  );
};

export default ActiveProjectGrid;
