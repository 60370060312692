import axios from "src/utils/axios";
import { IReportType } from "src/ts/interfaces";

const getValue = (action: string) => {
  switch (action) {
    case "saved":
      action = "Report was saved";
      break;
    case "generated":
      action = "Report was generated";
      break;
    case "completed":
      action = "Report was completed";
      break;
    case "created":
      action = "Report was created";
      break;
    case "opened":
      action = "Report was opened";
      break;
    case "reopened":
      action = "Report was reactivated";
      break;
    case "duplicated":
      action = "Report was duplicated";
      break;
    case "deleted":
      action = "Report was deleted";
      break;
    case "leave":
      action = "User left the report";
      break;
    case "idle":
      action = "User is idle";
      break;
    case "finishidle":
      action = "User idle end";
      break;
    case "approved":
      action = "Report was approved";
      break;
    case "notCompliant":
      action = "Report was approved but not compliant";
      break;
    case "rejected":
      action = "Report was rejected";
      break;
    case "appeal":
      action = "Report was appeal";
      break;
    case "finalreject":
      action = "Report final reject";
      break;
    case "retest":
      action = "Report was sent to retest";
      break;
    case "sendToAmp":
      action = "Report was sent to AMP for evaluation";
      break;
    default:
      break;
  }
  return action;
};

const save = (
  projectId: number,
  reportTypeId: number,
  reportId: number,
  action: string,
  url?: string
) => {
  const historyItem = {
    projectId: projectId,
    reportTypeId: reportTypeId,
    reportId: reportId,
    action: getValue(action),
    url: url
      ? url.replace("/studies", "")
      : window.location.pathname.replace("/studies", ""),
  };
  return axios.post<IReportType[]>("StudiesHistory", historyItem);
};

const studiesHistoryService = {
  save,
};

export default studiesHistoryService;
