import axios from "../utils/axios";

const getByUserId = async (id: number) => {
  return axios.get(`HistoricalRecord/user/${id}`);
};

//
const historicalRecordService = {
  getByUserId,
};

export default historicalRecordService;
