import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "@emotion/styled";
import useLog from "src/hooks/useLog";
import {
  CardContent,
  Grid,
  Card as MuiCard,
  Divider as MuiDivider,
  Stack,
  Stepper,
  Step,
  StepLabel,
  Button,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";
import { useForm, Form } from "src/hooks";
import { FormCancelButton, FormSelect } from "src/components/formControls";
import useLoading from "src/hooks/useLoading";
import { IKeyValue } from "src/ts/interfaces";
import FormBackButton from "src/components/formControls/FormBackButton";
import { GridActionButton } from "src/components/gridControls";
import { ColumnType } from "src/types/enhancedTable";
import LocalEnhancedTable from "src/components/localTable/LocalTable";
import gasFuelTypeEnergyCostService from "src/services/gasFuelTypeEnergyCostService";
import { IGasFuelTypeEnergyCost } from "src/ts/interfaces/gasFuelTypeEnergyCost";
import HeaderPage from "src/components/page/HeaderPage";
import FormNextButton from "src/components/formControls/FormNextButton";
import DialogMessagePopup from "src/components/DialogMessagePopup";
import buildingService from "src/services/buildingService";
import CatalogService from "src/services/catalogService";

const Card = styled(MuiCard)(spacing);
const Divider = styled(MuiDivider)(spacing);
const initialValues: IGasFuelTypeEnergyCost = {
  id: 0,
  energyCostProcessId: 0,
  fuelTypeId: "0",
  gasUnitsId: "0",
};

const columns = (
  canEdit: boolean,
  handleDelete: (id: number) => void
): ColumnType[] => [
  {
    id: "fuelType.name",
    label: "Fuel Type",
    type: "custom",
    sort: false,
    callback: (row: IGasFuelTypeEnergyCost) => {
      return <>{row?.fuelType?.name}</>;
    },
  },
  {
    id: "gasUnits.name",
    label: "Unit",
    type: "custom",
    sort: false,
    callback: (row: IGasFuelTypeEnergyCost) => {
      return <>{row?.gasUnits?.name}</>;
    },
  },
  {
    id: "actions",
    label: "Actions",
    type: "custom",
    sort: false,
    callback: (row: IGasFuelTypeEnergyCost) => {
      return (
        <GridActionButton
          type="delete"
          onClick={() => {
            handleDelete(row.id);
          }}
          tooltip="Delete"
          disabled={!canEdit}
        />
      );
    },
  },
];

const AnnualDetailed = () => {
  let navigate = useNavigate();
  const { log } = useLog();
  let { energyCostProcessId } = useParams<{ energyCostProcessId: string }>();
  let { type } = useParams<{ type: string }>();
  let { id } = useParams<{ id: string }>();
  const [canEdit, setCanEdit] = useState(false);

  const validate = (fieldValues = values) => {
    let temp: Record<string, string> = { ...errors };

    temp.fuelTypeId =
      fieldValues.fuelTypeId !== "0" ? "" : "This field is required.";

    temp.gasUnitsId =
      fieldValues.gasUnitsId !== "0" ? "" : "This field is required.";

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [requieredValues, setRequieredValues] = useState("");
  const [requieredValuesPopup, setRequieredValuesPopup] = useState(false);
  const [refreshTable, setRefreshTable] = useState(false);
  const [gasTotalCost, setGasTotalCost] = useState<IGasFuelTypeEnergyCost[]>(
    []
  );
  const [electricityUnitsKeyValue, setElectricityUnitsKeyValue] = useState<
    IKeyValue<number, string>[]
  >([]);
  const [fuelTypeKeyValue, setFuelTypeKeyValue] = useState<
    IKeyValue<number, string>[]
  >([]);
  const { values, setValues, errors, setErrors, handleInputChange } = useForm(
    initialValues,
    false,
    validate
  );
  const { isLoading, startRequest, endRequest } = useLoading();

  useEffect(() => {
    const getFuelType = async () => {
      const response = await CatalogService.getAllDropdown("FuelType");
      setFuelTypeKeyValue(response);
    };

    getFuelType();
    values.energyCostProcessId = energyCostProcessId;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [energyCostProcessId]);

  useEffect(() => {
    const getUnit = async () => {
      const response = await CatalogService.getAllDropdown(
        "Units/" + values.fuelTypeId
      );
      setElectricityUnitsKeyValue(response);
    };
    if (values.fuelTypeId !== 0) getUnit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.fuelTypeId]);

  useEffect(() => {
    const getBuilding = async (buildingId: number) => {
      const response = await buildingService.getById(buildingId);
      setCanEdit(response.data.canEdit);
    };

    if (Number(id) > 0) {
      getBuilding(Number(id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    const getGas = async () => {
      startRequest("user");
      const response = await gasFuelTypeEnergyCostService.getGasCost(
        Number(energyCostProcessId)
      );
      setGasTotalCost(response.data);
      endRequest("user");
    };
    if (Number(energyCostProcessId) !== 0) getGas();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [energyCostProcessId, refreshTable]);

  const handleCancel = () => {
    navigate("/app/BuildingEnergyCost/" + id);
  };

  const handleBack = async () => {
    navigate("/app/BuildingEnergyCost/" + id);
  };

  const confirmHandler = async () => {
    if (!validate()) return;
    try {
      setIsSubmitting(true);
      await gasFuelTypeEnergyCostService.createGasCost(values);
      log.success("Item was added");
      setRefreshTable(!refreshTable);
      initialValues.energyCostProcessId = Number(energyCostProcessId);
      setValues(initialValues);
    } catch (error: any) {
      log.error(error?.message?.exceptionMessage ?? "Something went wrong");
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleDelete = async (id: number) => {
    await gasFuelTypeEnergyCostService.deleteGasCost(id);
    log.success("Item was delete");
    setRefreshTable(!refreshTable);
  };

  const handleNext = async () => {
    const fuelType = gasTotalCost.find((item) => Number(item.fuelTypeId) === 1);

    if (!fuelType) {
      setRequieredValues("At least there must be an electricity fuel type");
      setRequieredValuesPopup(true);
      return;
    }

    navigate(
      `/app/BuildingAnnualDetailedData/${id}/${energyCostProcessId}/${type}`
    );
  };

  const steps = ["Energy configuration", "Annual Combined", "Annual Detailed"];

  return (
    <>
      <HeaderPage
        title={"Energy Cost"}
        parentText={"Buildings"}
        parentLink={`/app/Buildings/${id}`}
        actionSection={undefined}
      ></HeaderPage>
      <Divider my={6} />
      <Card mb={6}>
        <CardContent>
          <Form>
            <Grid item xs={12} md={12}>
              <Stepper alternativeLabel activeStep={1}>
                {steps.map((label) => (
                  <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
            </Grid>
            <Divider my={3}></Divider>
            <Typography variant="h2">{type}</Typography>
            <Divider my={6} />
            <Grid container spacing={5}>
              <Grid item xs={6}>
                <FormSelect
                  name="fuelTypeId"
                  label="Fuel Type"
                  value={values.fuelTypeId}
                  onChange={handleInputChange}
                  options={fuelTypeKeyValue}
                  error={errors.fuelTypeId}
                  disable={isSubmitting || !canEdit}
                  showSkeleton={isLoading}
                  defaultValue={{ key: 0, value: "Select a value" }}
                />
              </Grid>
              <Grid item xs={6}>
                <FormSelect
                  name="gasUnitsId"
                  label="Unit"
                  value={values.gasUnitsId}
                  onChange={handleInputChange}
                  options={electricityUnitsKeyValue}
                  error={errors.gasUnitsId}
                  disable={isSubmitting || !canEdit}
                  showSkeleton={isLoading}
                  defaultValue={{ key: 0, value: "Select a value" }}
                />
              </Grid>
              <Grid item xs={12} sx={{ textAlign: "center" }}>
                <Button
                  onClick={confirmHandler}
                  variant="contained"
                  color="primary"
                  disabled={!canEdit}
                >
                  Add
                </Button>
              </Grid>
            </Grid>
            <Divider my={3}></Divider>
            <Grid container>
              <Grid item xs={2}></Grid>
              <Grid item xs={8}>
                <LocalEnhancedTable<IGasFuelTypeEnergyCost>
                  refreshGrid={true}
                  columns={columns(canEdit, handleDelete)}
                  data={gasTotalCost}
                  showSkeleton={isLoading}
                />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Stack
                direction="row"
                spacing={2}
                justifyContent="center"
                alignItems="center"
              >
                <FormBackButton
                  onClick={handleBack}
                  isSubmitting={isSubmitting}
                  disabled={isLoading}
                  showSkeleton={isLoading}
                />
                <FormCancelButton
                  onClick={handleCancel}
                  isSubmitting={isSubmitting}
                  disabled={isLoading}
                />
                <FormNextButton
                  onClick={handleNext}
                  isSubmitting={isSubmitting}
                  disabled={isLoading}
                  showSkeleton={isLoading}
                />
              </Stack>
            </Grid>
          </Form>
        </CardContent>
      </Card>
      <DialogMessagePopup
        title={"Information"}
        text={requieredValues}
        showPopup={requieredValuesPopup}
        setShowPopup={setRequieredValuesPopup}
        onSave={function (): void {
          setRequieredValuesPopup(false);
        }}
        isSubmitting={false}
      ></DialogMessagePopup>
    </>
  );
};

export default AnnualDetailed;
