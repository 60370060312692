import Popup from "src/components/Popup";
import { FormButton } from "src/components/formControls";
import { Stack, Typography } from "@mui/material";

interface NotDefaultCardProps {
  notDefaultCardShowPopUp: boolean;
  setNotDefaultCardShowPopUp: (val: boolean) => void;
}

export default function NotDefaultCardPopUp({
  notDefaultCardShowPopUp,
  setNotDefaultCardShowPopUp,
}: NotDefaultCardProps) {
  return (
    <>
      <Popup
        title={"Information"}
        openPopup={notDefaultCardShowPopUp}
        setOpenPopup={setNotDefaultCardShowPopUp}
        onClose={() => {
          setNotDefaultCardShowPopUp(false);
        }}
        size="sm"
      >
        <>
          <Typography>
            You don't have a default card selected on your AMP Wallet, please
            select one before save Auto-Reload and Auto-Withdraw settings.
          </Typography>
        </>
        <Stack direction="row" spacing={3}>
          <FormButton
            size="small"
            text="Close"
            color="error"
            onClick={() => {
              setNotDefaultCardShowPopUp(false);
            }}
          />
        </Stack>
      </Popup>
    </>
  );
}
