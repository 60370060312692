import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { Helmet } from "react-helmet-async";
import { Button, Grid, Divider as MuiDivider, Typography } from "@mui/material";
import { spacing } from "@mui/system";
import DialogMessagePopup from "src/components/DialogMessagePopup";
import { useAsyncQuery, useAuth } from "src/hooks";
import getProjectService from "src/services/project/getProjectService";
import { useNavigate, useParams } from "react-router-dom";
import HeaderPage from "src/components/page/HeaderPage";
import { IProjectDashboard } from "src/ts/interfaces/project/projectDashboard";
import DashboarItem from "../dashboards/Auditor/DashboarItem";
import moment from "moment";
import DailyLogPopUp from "./Components/DailyLogPopUp";
import FormChartDoughnut from "src/components/formControls/FormChartDoughnut";
import {
  IDataBarChart,
  IDataDoughnutChart,
  IDataSetsBarChart,
} from "src/ts/interfaces/dataDoughnutChart";
import { grey, common, blue, green } from "@mui/material/colors";
import FormChartBar from "src/components/formControls/FormChartBar";
import TechnicianHistoryPopup from "./Components/TechnicianHistoryPopup";
import TimeCardPopup from "src/components/timeCardPopup/TimeCardPopup";
import getProjectSingleService from "src/services/project/getProjectSingleService";
import { ProjectDTO } from "src/ts/interfaces/project/projectDto";
import ChangeWorkOrdersPopUp from "src/components/floatingModalBar/ChangeWorkOrderPopUp";
import MemorandumPopUp from "src/components/floatingModalBar/MemorandumPopUp";
import { COMMENTSTYPE } from "src/constants";
import CommentsModal from "src/components/floatingModalBar/CommentsModal";
import {
  PlaceIcon,
  NotificationsActiveIcon,
  NotificationsOffIcon,
  HourglassBottomIcon,
  CalendarTodayIcon,
  DescriptionIcon,
  InsertPhotoIcon,
  ListAltIcon,
  AutoAwesomeMosaicIcon,
  ErrorIcon,
  CommentIcon,
} from "src/components/icons";

const Divider = styled(MuiDivider)(spacing);
const initialValue: IDataDoughnutChart = {
  labels: ["Completed", "Pending"],
  datasets: [
    {
      data: [0, 100],
      backgroundColor: [blue[700], grey[200]],
      borderWidth: 5,
      borderColor: common.white,
    },
  ],
};
const initialValueEstimated: IDataDoughnutChart = {
  labels: ["Completed", "Pending"],
  datasets: [
    {
      data: [0, 100],
      backgroundColor: [blue[700], green[100]],
      borderWidth: 5,
      borderColor: common.white,
    },
  ],
};

const initialValueReport: IDataBarChart = {
  labels: [
    "Air Report Template",
    "Water Report Template",
    "Energy Commissioning Report Template",
    "Comparative Test Report Template",
  ],
  datasets: [],
};

const ProjectDashboard = () => {
  let { projectId } = useParams<{ projectId: string }>();
  const { user } = useAuth();
  const navigate = useNavigate();
  const [typeComment, setTypeComment] = useState(0);
  const [showComments, setShowComments] = useState(false);
  const [showDailyLogPopUp, setShowDailyLogPopUp] = useState(false);
  const [showTechHistory, setShowTechHistory] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showTimeCardModal, setShowTimeCardModal] = useState(false);
  const [showMemorandumPopUp, setShowMemorandumPopUp] = useState(false);
  const [showChangeOrdersPopUp, setShowChangeOrdersPopUp] = useState(false);
  const [dataChartComputer, setDataChartComputer] =
    useState<IDataDoughnutChart>();
  const [dataChartEstimated, setDataChartEstimated] =
    useState<IDataDoughnutChart>();
  const [dataChartReport, setDataChartReport] = useState<IDataBarChart>();
  const [project, setProject] = useState<ProjectDTO>();
  const { execute, data, isLoading } = useAsyncQuery<IProjectDashboard>(
    getProjectService.getProjectDashboard
  );

  const generateDataComputerChart = () => {
    initialValue.datasets[0].data[0] =
      data?.computerEstimatedTimeRemainingPercentage ?? 0;
    initialValue.datasets[0].data[1] =
      100 - (data?.computerEstimatedTimeRemainingPercentage ?? 0);
    setDataChartComputer(initialValue);
    initialValueEstimated.datasets[0].data[0] =
      data?.techniciansEstimatedTimeRemaningPercentage ?? 0;
    initialValueEstimated.datasets[0].data[1] =
      100 - (data?.techniciansEstimatedTimeRemaningPercentage ?? 0);
    setDataChartEstimated(initialValueEstimated);
    if (data?.report && data?.report.length > 0) {
      initialValueReport.datasets = [];
      data?.report.forEach((item) => {
        const newItem: IDataSetsBarChart = {
          label: item.label,
          backgroundColor: item.backgroundColor,
          borderColor: item.backgroundColor,
          hoverBackgroundColor: item.backgroundColor,
          hoverBorderColor: item.backgroundColor,
          data: item.data,
          barPercentage: 0.75,
          categoryPercentage: 0.75,
        };
        initialValueReport.datasets.push(newItem);
      });
    } else {
      initialValueReport.datasets = [];
    }
    setDataChartReport(initialValueReport);
  };

  useEffect(() => {
    if (data) generateDataComputerChart();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    const getData = async () => {
      execute(projectId);
      const response = await getProjectSingleService.getById(Number(projectId));
      setProject(response.data);
    };
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const openModalComments = (types: number) => {
    setTypeComment(types);
    setShowComments(true);
  };

  return (
    <React.Fragment>
      <Helmet title="Dashboard" />
      <HeaderPage
        title={"Dashboard"}
        parentText={"Dashboard"}
        parentLink={`/`}
      ></HeaderPage>
      <Divider my={6} />
      <Typography variant="h5" style={{ paddingBottom: 15 }}>
        Project General Information{" "}
      </Typography>
      <Grid container spacing={5}>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <DashboarItem
            title="Building Information"
            label2={
              <ul>
                <li>
                  <Typography display={"inline"} variant="subtitle2">
                    Building Name:{" "}
                  </Typography>{" "}
                  <Typography display={"inline"} variant="body2">
                    {data?.buildingName}
                  </Typography>
                </li>
                <li>
                  <Typography display={"inline"} variant="subtitle2">
                    Building Address:{" "}
                  </Typography>{" "}
                  <Typography display={"inline"} variant="body2">
                    {data?.buildingAddress}
                  </Typography>
                </li>
              </ul>
            }
            showSkeleton={isLoading}
            action={() => {
              navigate(`/app/Project/Edit/${projectId}/${user?.companyId}`);
            }}
            label1={""}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <DashboarItem
            title="Project Time & Daily Log"
            label2={
              <Grid container xs={12} sm={12}>
                <Grid item md={9}>
                  <ul style={{ margin: 0 }}>
                    <li>
                      <Typography display={"inline"} variant="subtitle2">
                        Created Date:{" "}
                      </Typography>{" "}
                      <Typography display={"inline"} variant="body2">
                        {moment(data?.createdDate).format("MM/DD/YYYY")}
                      </Typography>
                    </li>
                    <li>
                      <Typography display={"inline"} variant="subtitle2">
                        Estimated Proposed Start Date:{" "}
                      </Typography>{" "}
                      <Typography display={"inline"} variant="body2">
                        {moment(data?.estimatedStartDate).format("MM/DD/YYYY")}
                      </Typography>
                    </li>
                    <li>
                      <Typography display={"inline"} variant="subtitle2">
                        Proposal Project Time Duration/Bid:{" "}
                      </Typography>{" "}
                      <Typography display={"inline"} variant="body2">
                        {data?.projectTimeDuration}
                      </Typography>
                    </li>
                  </ul>
                </Grid>
                <Grid item md={3}>
                  <Button
                    size="small"
                    variant="outlined"
                    onClick={() => {
                      setShowDailyLogPopUp(true);
                    }}
                  >
                    <Grid>
                      <Grid>
                        <PlaceIcon />
                      </Grid>
                      <Grid>
                        <Typography>Daily Log</Typography>
                      </Grid>
                    </Grid>
                  </Button>
                </Grid>
              </Grid>
            }
            showSkeleton={isLoading}
            action={() => {
              navigate(`/app/Project/Edit/${projectId}/${user?.companyId}`);
            }}
            label1={""}
          />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item md={6}>
          <Typography
            variant="h5"
            style={{ paddingBottom: 15, paddingTop: 15 }}
          >
            Technician & Time Card{" "}
          </Typography>
          <Grid container pr={1}>
            <Grid item md={6} p={1} pl={0}>
              <DashboarItem
                title="Active"
                label2={
                  <NotificationsActiveIcon
                    style={{ float: "right", fontSize: 40 }}
                  />
                }
                showSkeleton={isLoading}
                action={() => {
                  setShowTechHistory(true);
                }}
                label1={""}
                chip={data?.activeTech.toString()}
              />
            </Grid>
            <Grid item md={6} p={1}>
              <DashboarItem
                title={data?.isCompleted ? "Involved" : "Not Active"}
                label2={
                  <NotificationsOffIcon
                    style={{ float: "right", fontSize: 40 }}
                  />
                }
                showSkeleton={isLoading}
                action={() => {
                  setShowTechHistory(true);
                }}
                label1={""}
                chip={data?.notActiveTech.toString()}
              />
            </Grid>
            <Grid item md={6} p={1} pl={0}>
              <DashboarItem
                title={"Total Hours Invested"}
                label2={
                  <HourglassBottomIcon
                    style={{ float: "right", fontSize: 40 }}
                  />
                }
                showSkeleton={isLoading}
                action={() => {
                  setShowTimeCardModal(true);
                }}
                label1={""}
                chip={data?.totalHoursInvested.toString()}
              />
            </Grid>
            <Grid item md={6} p={1}>
              <DashboarItem
                title={"Total Days Invested"}
                label2={
                  <CalendarTodayIcon style={{ float: "right", fontSize: 40 }} />
                }
                showSkeleton={isLoading}
                action={() => {
                  setShowTimeCardModal(true);
                }}
                label1={""}
                chip={(data?.totalHoursInvested / 8).toString()}
              />
            </Grid>
            <Grid item md={12} pr={1}>
              <Typography
                variant="h5"
                style={{ paddingBottom: 15, paddingTop: 15 }}
              >
                Reports{" "}
              </Typography>
              <DashboarItem
                title=""
                label2={
                  <>
                    <Grid pb={2}>
                      {data?.isCompleted && data?.totalCost !== 0 && (
                        <Typography variant="subtitle2">
                          Total cost for project Completion $
                          {data?.totalCostPaid}
                        </Typography>
                      )}
                      {!data?.isCompleted && data?.totalCost !== 0 && (
                        <Typography variant="subtitle2">
                          Estimated cost to close the project including all
                          reports ${data?.totalCost}
                        </Typography>
                      )}
                      {!data?.isCompleted &&
                        data?.totalCost !== 0 &&
                        data?.totalCostCompleted !== 0 && (
                          <Typography variant="subtitle2">
                            Estimated cost to close the project including only
                            completed reports ${data?.totalCostCompleted}
                          </Typography>
                        )}
                      {!data?.isCompleted &&
                        data?.totalCost !== 0 &&
                        data?.totalCostCompleted === 0 && (
                          <Typography variant="subtitle2">
                            No studies have been completed.
                          </Typography>
                        )}
                      {data?.totalCost === 0 && (
                        <Typography variant="subtitle2">
                          No report(s) have been added
                        </Typography>
                      )}
                    </Grid>

                    {dataChartReport && (
                      <FormChartBar data={dataChartReport}></FormChartBar>
                    )}
                  </>
                }
                showSkeleton={isLoading}
                action={() => {}}
                label1={""}
                hideViewDetails={true}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={6} pl={3}>
          <Typography
            variant="h5"
            style={{ paddingBottom: 15, paddingTop: 15 }}
          >
            Project Time Clock{" "}
          </Typography>
          <Grid container pr={1}>
            <Grid item md={6} p={1} pl={0}>
              <DashboarItem
                title="Computer Estimated Time Remaning"
                label2={
                  <Grid container>
                    <Grid item md={12}>
                      {" "}
                      {dataChartComputer && (
                        <FormChartDoughnut
                          data={dataChartComputer}
                          title={`${data?.computerEstimatedTimeRemaining}`}
                          subtitle={""}
                        />
                      )}
                    </Grid>
                    <Grid item md={12}>
                      <Typography style={{ float: "right" }}>
                        {data?.techniciansEstimatedTimeRemaningIsDays}
                      </Typography>
                    </Grid>
                  </Grid>
                }
                showSkeleton={isLoading}
                action={() => {}}
                label1={""}
                hideViewDetails={true}
              />
            </Grid>
            <Grid item md={6} p={1}>
              <DashboarItem
                title={"Technicians Estimated Time Remaining"}
                label2={
                  <Grid container>
                    <Grid item md={12}>
                      {" "}
                      {dataChartEstimated && (
                        <FormChartDoughnut
                          data={dataChartEstimated}
                          title={`${data?.techniciansEstimatedTimeRemaning}`}
                          subtitle={""}
                        />
                      )}
                    </Grid>
                    <Grid item md={6}>
                      <Typography style={{ float: "left" }}>
                        {data?.techniciansEstimatedTimeRemaningName}
                      </Typography>
                    </Grid>
                    <Grid item md={6}>
                      <Typography style={{ float: "right" }}>
                        {data?.techniciansEstimatedTimeRemaningIsDays}
                      </Typography>
                    </Grid>
                  </Grid>
                }
                showSkeleton={isLoading}
                action={() => {}}
                label1={""}
                hideViewDetails={true}
              />
            </Grid>
          </Grid>
          <Typography
            variant="h5"
            style={{ paddingBottom: 15, paddingTop: 15 }}
          >
            Project Database{" "}
          </Typography>
          <Grid container pr={1}>
            <Grid item md={6} p={1} pl={0}>
              <DashboarItem
                title="Documents in Project Library"
                label2={
                  <DescriptionIcon style={{ float: "right", fontSize: 40 }} />
                }
                showSkeleton={isLoading}
                action={() => {
                  navigate(
                    `/app/DocumentLibrary/${projectId}/${user?.companyId}/Project`
                  );
                }}
                label1={""}
                chip={data?.documentsProjectLibrary.toString()}
              />
            </Grid>
            <Grid item md={6} p={1}>
              <DashboarItem
                title={"Files in Picture Log"}
                label2={
                  <InsertPhotoIcon style={{ float: "right", fontSize: 40 }} />
                }
                showSkeleton={isLoading}
                action={() => {
                  navigate(
                    `/app/PhotoLibrary/${projectId}/${user?.companyId}/Project`
                  );
                }}
                label1={""}
                chip={data?.filesInPictureLog.toString()}
              />
            </Grid>
          </Grid>
          <Typography
            variant="h5"
            style={{ paddingBottom: 15, paddingTop: 15 }}
          >
            Change/Work Orders and Memorandums{" "}
          </Typography>
          <Grid container pr={1}>
            <Grid item md={6} p={1} pl={0}>
              <DashboarItem
                title="Change/Work Orders"
                label2={
                  <ListAltIcon style={{ float: "right", fontSize: 40 }} />
                }
                showSkeleton={isLoading}
                action={() => {
                  setShowChangeOrdersPopUp(true);
                }}
                label1={""}
                chip={data?.changeOrders.toString()}
              />
            </Grid>
            <Grid item md={6} p={1}>
              <DashboarItem
                title={"Memorandums"}
                label2={
                  <AutoAwesomeMosaicIcon
                    style={{ float: "right", fontSize: 40 }}
                  />
                }
                showSkeleton={isLoading}
                action={() => {
                  setShowMemorandumPopUp(true);
                }}
                label1={""}
                chip={data?.listMemorandums?.length.toString()}
              />
            </Grid>
          </Grid>
          <Typography
            variant="h5"
            style={{ paddingBottom: 15, paddingTop: 15 }}
          >
            Deficiencies and Comments{" "}
          </Typography>
          <Grid container pr={1}>
            <Grid item md={6} p={1} pl={0}>
              <DashboarItem
                title="Deficiencies"
                label2={<ErrorIcon style={{ float: "right", fontSize: 40 }} />}
                showSkeleton={isLoading}
                action={() => {
                  openModalComments(COMMENTSTYPE.DEFICIENCIES);
                }}
                label1={""}
                chip={data?.deficiences.toString()}
              />
            </Grid>
            <Grid item md={6} p={1}>
              <DashboarItem
                title={"Comments"}
                label2={
                  <CommentIcon style={{ float: "right", fontSize: 40 }} />
                }
                showSkeleton={isLoading}
                action={() => {
                  openModalComments(COMMENTSTYPE.COMMENTS);
                }}
                label1={""}
                chip={data?.listComments?.length.toString()}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <DialogMessagePopup
        title={"Information"}
        text={"under construction"}
        showPopup={showDeleteModal}
        setShowPopup={setShowDeleteModal}
        onSave={() => setShowDeleteModal(false)}
        isSubmitting={false}
      />
      {showDailyLogPopUp && (
        <DailyLogPopUp
          showDailyLogPopUp={showDailyLogPopUp}
          setShowDailyLogPopUp={setShowDailyLogPopUp}
          projectId={Number(projectId ?? 0)}
          dashboardView={false}
        />
      )}
      <TechnicianHistoryPopup
        showTechHistory={showTechHistory}
        setShowTechHistory={setShowTechHistory}
        data={data?.techniciansHistory ?? []}
      ></TechnicianHistoryPopup>
      {project && (
        <TimeCardPopup
          project={project}
          isDialogOpen={showTimeCardModal}
          setIsDialogOpen={setShowTimeCardModal}
          isCurrentProject={false}
          validateEstimatedTime={() => {}}
          viewMode={true}
        ></TimeCardPopup>
      )}
      <ChangeWorkOrdersPopUp
        showChangeOrdersPopUp={showChangeOrdersPopUp}
        setShowChangeOrdersPopUp={setShowChangeOrdersPopUp}
        projectId={Number(projectId)}
        defaultTab="all"
        projectCompleted={false}
      ></ChangeWorkOrdersPopUp>
      <MemorandumPopUp
        showMemorandumPopUp={showMemorandumPopUp}
        setShowMemorandumPopUp={setShowMemorandumPopUp}
        projectId={Number(projectId)}
        defaultTab="all"
        projectCompleted={false}
      ></MemorandumPopUp>
      {showComments && (
        <CommentsModal
          isDialogOpen={showComments}
          setIsDialogOpen={setShowComments}
          type={typeComment}
          projectId={Number(projectId)}
          viewMode={true}
          projectCompleted={false}
        />
      )}
    </React.Fragment>
  );
};

export default ProjectDashboard;
