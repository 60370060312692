import React from "react";
import { Stack } from "@mui/material";
import { FormButton } from "src/components/formControls";
import BusinessIcon from "@mui/icons-material/Business";
import useAuth from "src/hooks/useAuth";
import { AddIcon } from "src/components/icons";
import { useNavigate } from "react-router-dom";

function Actions() {
  const { user } = useAuth();
  const navigate = useNavigate();

  const handleViewCompleteProjects = () => {
    navigate("/app/Working");
  };

  return (
    <React.Fragment>
      <Stack direction="row" spacing={2}>
        {user?.canAddBuildingsAndProjects === "true" && (
          <>
            <FormButton
              text={"Create New Project"}
              onClick={() => handleViewCompleteProjects()}
              startIcon={<AddIcon />}
              size="small"
              color="primary"
            />
          </>
        )}
        <FormButton
          text={"View Active Projects"}
          onClick={() => handleViewCompleteProjects()}
          startIcon={<BusinessIcon />}
          size="small"
          variant="outlined"
        />
      </Stack>
    </React.Fragment>
  );
}

export default Actions;
