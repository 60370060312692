import React, { useEffect } from "react";
import { Grid, Stack } from "@mui/material";
import { useForm, useLog } from "src/hooks";
import { IUnitLabel } from "src/ts/interfaces/catalogs";
import { Form } from "src/hooks/useForm";
import {
  FormCancelButton,
  FormSaveButton,
  FormText,
} from "src/components/formControls";
import Popup from "src/components/Popup";
import { Validator } from "src/ts/types/Validator";
import unitLabelService from "src/services/catalogs/unitLabelService";
import { Permission as PermissionTypes } from "src/ts/enums";
import { usePermissions } from "src/hooks";

interface UnitLabelFormProps {
  selectedValue?: IUnitLabel;
  isDialogOpen: boolean;
  setIsDialogOpen: (val: boolean) => void;
  onUpdate: () => void;
}

const initialValues: IUnitLabel = {
  id: 0,
  key: "",
  metricSystem: "",
  imperialSystem: "",
};

const UnitLabelForm = (props: UnitLabelFormProps) => {
  const { selectedValue, isDialogOpen, setIsDialogOpen, onUpdate } = props;
  const { log } = useLog();
  const validate = (fieldValues = values) => {
    let temp: Record<string, string> = { ...errors };

    temp.key = new Validator(fieldValues, "key")
      .isRequired("The key is required")
      .maxLength(50, "Only 50 character are allowed")
      .toString();

    temp.imperialSystem = new Validator(fieldValues, "imperialSystem")
      .isRequired("The label is required")
      .maxLength(50, "Only 50 character are allowed")
      .toString();

    temp.metricSystem = new Validator(fieldValues, "metricSystem")
      .isRequired("The label is required")
      .maxLength(50, "Only 50 character are allowed")
      .toString();

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues, false, validate);

  useEffect(() => {
    if (isDialogOpen) setValues(selectedValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDialogOpen]);

  const handleClose = () => {
    resetForm();
    setIsDialogOpen(false);
  };

  const { fullAccess, readOnly } = usePermissions(PermissionTypes.Catalogs);

  const handleSave = async () => {
    if (!validate()) return;

    try {
      if (values.id === 0) {
        await unitLabelService.add(values);
        log.success("The unit label was added succesfully");
      } else {
        await unitLabelService.update(values);
        log.success("The unit label was updated succesfully");
      }
    } catch (error: any) {
      log.error(error?.message?.exceptionMessage ?? "Something went wrong");
    }

    setIsDialogOpen(false);
    onUpdate();
  };

  const row = 12;
  return (
    <>
      <Popup
        openPopup={isDialogOpen}
        setOpenPopup={setIsDialogOpen}
        title={values.id === 0 ? "New" : "Edit"}
        onClose={() => setIsDialogOpen(false)}
      >
        <Form>
          <Grid container spacing={5}>
            <Grid item xs={row}>
              <FormText
                name="key"
                label="Key"
                value={values.key}
                onChange={handleInputChange}
                error={errors.key}
                showSkeleton={false}
                disabled={readOnly}
              />
            </Grid>
            <Grid item xs={row}>
              <FormText
                name="imperialSystem"
                label="Imperial System Label"
                value={values.imperialSystem}
                onChange={handleInputChange}
                error={errors.imperialSystem}
                showSkeleton={false}
                disabled={readOnly}
              />
            </Grid>
            <Grid item xs={row}>
              <FormText
                name="metricSystem"
                label="Metric System Label"
                value={values.metricSystem}
                onChange={handleInputChange}
                error={errors.metricSystem}
                showSkeleton={false}
                disabled={readOnly}
              />
            </Grid>
          </Grid>
        </Form>
        <>
          <Stack direction="row" spacing={2}>
            <FormCancelButton onClick={handleClose} isSubmitting={false} />
            {fullAccess && (
              <FormSaveButton onClick={handleSave} isSubmitting={false} />
            )}
          </Stack>
        </>
      </Popup>
    </>
  );
};

export default UnitLabelForm;
