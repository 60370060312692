import axios from "src/utils/axios";
import { IEnergySourceSubtracted } from "src/ts/interfaces/catalogs";

const getByReportIdAndType = (reportId: number, typeId: number) => {
  return axios.get<IEnergySourceSubtracted[]>(
    `EnergySourceSubtracted/Report?reportId=${reportId}&reportType=${typeId}`
  );
};

const add = (energySubstracteditem: IEnergySourceSubtracted) => {
  return axios.post<IEnergySourceSubtracted>(
    `EnergySourceSubtracted`,
    energySubstracteditem
  );
};

const updateAll = (energySubstracteditems: IEnergySourceSubtracted[]) => {
  return axios.put<IEnergySourceSubtracted>(
    `EnergySourceSubtracted`,
    energySubstracteditems
  );
};

const remove = (id: number) => {
  return axios.delete(`EnergySourceSubtracted/${id}`);
};

const energySourceSubtractedService = {
  getByReportIdAndType,
  add,
  updateAll,
  remove,
};

export default energySourceSubtractedService;
