import axios from "../utils/axios";
import { IEmailUnsubscribe } from "../ts/interfaces";
import {
  IUnsubscribeData,
  IUnsubscribeRequest,
} from "src/ts/interfaces/unsubscribeData";

const getByCode = (code: string, key: string) => {
  return axios.get<IUnsubscribeData>(`unsubscribe/email/${code}/${key}`);
};

const updateByCode = (data: IUnsubscribeRequest) => {
  return axios.post<IEmailUnsubscribe>(`unsubscribe`, data);
};

const unsubscribeService = {
  getByCode,
  updateByCode,
};

export default unsubscribeService;
