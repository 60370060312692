import { useEffect, useState } from "react";
import Popup from "../Popup";
import { Badge, Box, IconButton, Tooltip, Typography } from "@mui/material";
import { Info } from "react-feather";
import { FormButton } from "../formControls";
import releaseVersion from "src/services/releaseVersionService";
import { IRelease } from "src/ts/interfaces/releaseVersion";
import { useAppSelector } from "src/hooks";
import styled from "@emotion/styled";

const Indicator = styled(Badge)`
  .MuiBadge-badge {
    background: ${(props) => props.theme.palette.error};
    color: ${(props) => props.theme.palette.common.white};
  }
`;

function NavBarVersion() {
  const [isOpenModal, setOpenModal] = useState(false);
  const [dataVersion, setDataVersion] = useState<IRelease>();
  const [body, setBody] = useState("");
  const appGlobal = useAppSelector((state) => state.appGlobal);

  const handleClose = () => {
    setOpenModal(false);
  };

  useEffect(() => {
    const getReleaseVersions = async () => {
      const response = await releaseVersion.getReleaseVersion();
      setDataVersion(response.data);
      setBody(response.data.description);
    };

    getReleaseVersions();
  }, [isOpenModal]);

  const openVersionPopup = () => {
    setOpenModal(true);
  };

  useEffect(() => {
    if (appGlobal.showOutdatedVersionPopup) {
      setOpenModal(true);
    }
  }, [appGlobal.showOutdatedVersionPopup]);

  return (
    <>
      <Tooltip title="Version">
        <IconButton color="inherit" onClick={openVersionPopup} size="large">
          <Indicator
            invisible={!appGlobal.showOutdatedVersionPopup}
            badgeContent="!"
            color="error"
          >
            <Info />
          </Indicator>
        </IconButton>
      </Tooltip>
      <Popup
        openPopup={isOpenModal}
        setOpenPopup={setOpenModal}
        title="AMP Software"
        onClose={handleClose}
      >
        {!appGlobal.showOutdatedVersionPopup ? (
          <>
            <Typography>
              <b>AMP software version is up to date.</b>
            </Typography>
            <Typography mt={1}>
              <b>{`Release Notes: ${dataVersion?.name}`} </b>
            </Typography>
            <Typography mt={1} mb={5}>
              <b>{`Version: ${dataVersion?.version}`} </b>
            </Typography>
            <div dangerouslySetInnerHTML={{ __html: body ?? "" }} />
          </>
        ) : (
          <>
            <Typography>
              <b>
                AMP software version is outdated. <br /> Current version:
                {appGlobal.currentVersion} <br /> New version:{" "}
                {appGlobal.newVersion}.
              </b>
            </Typography>
            <Typography mt={1}>
              <b>
                Please perform the following instructions to execute a hard
                reset of your internet browser's page.
              </b>
            </Typography>
            <Typography sx={{ mt: 2, textAlign: "justify" }}>
              It's <b style={{ color: "orangered" }}>IMPORTANT</b> that you
              perform the following instructions while you are into the
              browser's page where AMP software is running.
            </Typography>
            <Box
              component="img"
              src="/static/img/hard-refresh-browser.jpg"
              sx={{
                width: "550px",
              }}
            />
          </>
        )}
        <>
          <FormButton
            text="Close"
            size="medium"
            onClick={handleClose}
            isSubmitting={false}
          />
        </>
      </Popup>
    </>
  );
}

export default NavBarVersion;
