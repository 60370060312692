import { Stack } from "@mui/material";
import { useEffect, useState } from "react";
import DialogMessagePopup from "src/components/DialogMessagePopup";
import { GridActionButton } from "src/components/gridControls";
import LocalEnhancedTable from "src/components/localTable/LocalTable";
import { useLog } from "src/hooks";
import { useTable } from "src/hooks/useTable";
import { CompanyService } from "src/services";
import { ICompany, ICompanyData } from "src/ts/interfaces";
import { ColumnType } from "src/types/enhancedTable";

interface SatelliteActiveGridProps {
  companies: ICompany[] | null;
  refreshGrid: () => void;
}
const SatelliteInactiveGrid = (props: SatelliteActiveGridProps) => {
  const { companies, refreshGrid } = props;
  const [selectedCompany, setSelectedCompany] = useState<ICompanyData>();
  const [showEnableCompanyPopup, setShowEnableCompanyPopup] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { log } = useLog();
  const columns: ColumnType[] = [
    {
      id: "code",
      label: "Code",
      type: "string",
      sort: true,
      callback: (row: ICompanyData) => {
        return row.code;
      },
    },
    {
      id: "address",
      label: "Address",
      type: "custom",
      sort: true,
      callback: (row: ICompanyData) => {
        return (
          <>
            {row.address}, {row.city}{" "}
            {`${row.stateName !== null ? row.stateName : row.state?.name}`}{" "}
            {row.country?.name}
          </>
        );
      },
    },
    {
      id: "deactivatedBy",
      label: "Deactivated By",
      type: "string",
      sort: true,
    },
    {
      id: "deactivatedDate",
      label: "Deactivation Date",
      type: "date",
      format: "MM/DD/YYYY",
      sort: true,
    },
    {
      id: "deactivationReason",
      label: "Deactivation Reason",
      type: "string",
      sort: true,
    },
    {
      id: "x",
      label: "Actions",
      type: "custom",
      sort: false,
      callback: (row: ICompanyData) => (
        <div>
          <Stack direction="row">
            <GridActionButton
              type="enable"
              disabled={row.deactivatedBy === "System"}
              tooltip="Activate"
              onClick={() => {
                setSelectedCompany(row);
                setShowEnableCompanyPopup(true);
              }}
            />
          </Stack>
        </div>
      ),
    },
  ];

  const { setDataSet } = useTable(columns);

  useEffect(() => {
    setDataSet(companies ?? []);
  });

  const handleSaveEnableCompany = async () => {
    if (selectedCompany && selectedCompany.id) {
      try {
        setIsSubmitting(true);
        await CompanyService.changeStatusSatellite({
          id: selectedCompany.id,
          isActive: true,
          reason: selectedCompany?.deactivationReason,
        });
        log.success(
          `Satellite company with address 
            ${selectedCompany?.address}, ${selectedCompany?.city} ${
            selectedCompany?.stateName !== null
              ? selectedCompany?.stateName
              : selectedCompany?.state?.name
          } ${selectedCompany?.country?.name} was activated`
        );
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      } finally {
        setIsSubmitting(false);
        refreshGrid();
      }
    }
  };

  return (
    <>
      <DialogMessagePopup
        title="Information"
        text="Are you certain you want to enable this company? Enabling will renew
        access to the system"
        showPopup={showEnableCompanyPopup}
        setShowPopup={setShowEnableCompanyPopup}
        onSave={handleSaveEnableCompany}
        isSubmitting={isSubmitting}
      />
      <LocalEnhancedTable<ICompany>
        refreshGrid={true}
        columns={columns}
        data={companies ?? []}
      />
    </>
  );
};

export default SatelliteInactiveGrid;
