import { Typography } from "@mui/material";
import DialogMessagePopup from "src/components/DialogMessagePopup";

interface InformationProps {
  showPopUp: boolean;
  setShowPopUp: (val: boolean) => void;
}

export default function TandbalanceEnergyPopup({
  showPopUp,
  setShowPopUp,
}: InformationProps) {
  return (
    <>
      <DialogMessagePopup
        title={"Information"}
        text={
          <Typography>
            You are currently logged in to AMP software with Testing & Balancing
            Certification. To have access to this project's studies please
            select the role "Energy Auditor / Infectious Building Control" when
            login to the software.{" "}
          </Typography>
        }
        showPopup={showPopUp}
        setShowPopup={setShowPopUp}
        hideAccept={true}
        cancelTextButton="Close"
        isSubmitting={false}
      ></DialogMessagePopup>
    </>
  );
}
