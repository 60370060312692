import axios from "../utils/axios";
import { IPlaques } from "../ts/interfaces";
import { IProjectEstimatedTime } from "src/ts/interfaces/projectEstimatedTime";

const getById = (id: Number) => {
  return axios.get<IPlaques>(`Plaques?id=${id}`);
};

const update = (item: any) => {
  return axios.put(`Plaques`, item);
};

const insert = (plaque: IPlaques) => {
  return axios.post(`Plaques`, plaque);
};

const deleteById = (id: number) => {
  return axios.delete(`Plaques?id=${id}`);
};

const getall = (id: Number) => {
  return axios.get<IProjectEstimatedTime>(`Plaques?id=${id}`);
};

const getallActive = (id: Number) => {
  return axios.get<IProjectEstimatedTime>(`Plaques/AllActive/projectId/${id}`);
};

const plaqueService = {
  getById,
  update,
  insert,
  deleteById,
  getall,
  getallActive,
};
export default plaqueService;
