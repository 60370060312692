import React, { useEffect, useState } from "react";
import {
  Grid,
  Button,
  Tabs,
  Tab,
  Card,
  CardContent,
  Switch,
  FormControlLabel,
} from "@mui/material";
import { Add as AddIcon } from "@mui/icons-material";
import {
  ITechnicianQuizAnswers,
  ITechnicianQuizBacklog,
} from "src/ts/interfaces";
import { useForm, Form, useLog, usePermissions } from "src/hooks";
import { FormTextArea } from "src/components/formControls";
import FormSaveButton from "src/components/formControls/FormSaveButton";
import FormCancelButton from "src/components/formControls/FormCancelButton";
import Popup from "src/components/Popup";
import DialogMessagePopup from "src/components/DialogMessagePopup";
import { draftToHtmlService, technicianQuizBacklogService } from "src/services";
import QuestionsCatalogGrid from "./QuestionsCatalogGrid";
import QuestionsCatalogAnswers from "./QuestionsCatalogAnswers";
import FormTextEditor from "src/components/formControls/FormTextEditor";
import { Permission as PermissionTypes } from "src/ts/enums";

interface QuestionsProps {
  data: ITechnicianQuizBacklog[];
  categoryId: number;
}

const QuestionsCatalog = (prop: QuestionsProps) => {
  const initialValues = {
    id: 0,
    description: "",
    technicianQuizCategoryId: prop.categoryId,
    solution: "",
    isRequired: false,
    isDeleted: false,
    isDisabled: false,
    technicianQuizAnswers: [],
    descriptionNew: "",
  };
  const [selectdTab, setSelectedTab] = useState(0);
  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [refreshGrid, setRefreshGrid] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [currentRow, setCurrentRow] = useState<ITechnicianQuizBacklog>();
  const [questions, setQuestions] = useState<ITechnicianQuizBacklog[]>([]);
  const { fullAccess, readOnly } = usePermissions(PermissionTypes.Quiz);
  const { log } = useLog();

  useEffect(() => {
    setQuestions(prop.data);
  }, [prop.data]);

  const validate = (fieldValues = values) => {
    let temp: Record<string, string> = { ...errors };
    if (fieldValues.hasOwnProperty("description")) {
      temp.description = fieldValues.description
        ? ""
        : "This field is required.";
    }

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues, false, validate);
  const handleOpen = () => {
    resetForm();
    setIsDialogOpen(true);
  };

  const handleDelete = (row: ITechnicianQuizBacklog) => {
    setCurrentRow(row);
    setShowDeleteModal(true);
  };

  const handleChangeAnswers = (row: ITechnicianQuizAnswers[]) => {
    values.technicianQuizAnswers = row;
    setValues(values);
  };

  const handleEdit = (row: ITechnicianQuizBacklog) => {
    setValues(row);
    setIsDialogOpen(true);
  };

  const handleDisable = async (row: ITechnicianQuizBacklog) => {
    row.isDisabled = !row.isDisabled;
    setValues(row);
    setIsSubmitting(true);
    try {
      await technicianQuizBacklogService.changeStatus(row.id);
      if (row.isDisabled) {
        log.success("The question was successfully deactivated");
      } else {
        log.success("The question was successfully activated");
      }
    } catch (error: any) {
      log.error(error?.message?.exceptionMessage ?? "Something went wrong");
    }
    setIsSubmitting(false);
  };
  const handleClose = () => {
    resetForm();
    setIsDialogOpen(false);
  };

  const deleteDocument = async () => {
    if (currentRow && currentRow.id) {
      try {
        setIsSubmitting(true);
        await technicianQuizBacklogService.deleteById(currentRow.id);
        var deletesAnswers = questions.filter((x) => {
          return x !== currentRow;
        });
        setQuestions(deletesAnswers);
        setRefreshGrid(!refreshGrid);
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      } finally {
        setIsSubmitting(false);
      }
    } else {
    }
  };

  const handleSave = async () => {
    if (!validate()) return;

    setIsSubmitting(true);
    values.description = values.descriptionNew;
    values.descriptionHTML = draftToHtmlService.convertToHTML(
      values.description
    );

    if (values.id === 0) {
      await technicianQuizBacklogService
        .add(values)
        .then((response) => {
          setIsDialogOpen(false);
          var result = questions.concat(response.data);
          setQuestions(result);
          setRefreshGrid(!refreshGrid);
          setIsSubmitting(false);
          resetForm();
          log.success("The question was successfully created");
        })
        .catch((error) => {
          log.error(error?.message?.exceptionMessage ?? "Something went wrong");
        });
    } else {
      await technicianQuizBacklogService
        .update(values.id, values)
        .then((response) => {
          setIsDialogOpen(false);
          setCurrentRow({ ...values });
          const newQuestions = questions.map((c, i) => {
            if (c.id === values.id) {
              return values;
            } else {
              return c;
            }
          });
          setQuestions(newQuestions);
          setRefreshGrid(!refreshGrid);
          setIsSubmitting(false);
          resetForm();
          log.success("The question was successfully updated");
        })
        .catch((error) => {
          log.error(error?.message?.exceptionMessage ?? "Something went wrong");
        });
    }
  };

  const handleUpdateTextEditor = (e: any) => {
    if (values.id !== 0) {
      values.descriptionNew = e.target.value;
      setValues(values);
    }
  };

  const [isSubmitting, setIsSubmitting] = useState(false);

  return (
    <React.Fragment>
      <Grid
        container
        spacing={10}
        sx={{ borderBottom: 1, borderColor: "divider", marginBottom: 5 }}
      >
        <Grid item xs={9}>
          <Tabs
            value={selectdTab}
            onChange={handleChangeTab}
            aria-label="basic tabs example"
          >
            <Tab label="Active" id="0" />
            <Tab label="Inactive" id="1" />
          </Tabs>
        </Grid>
        <Grid item xs={3}>
          <div>
            {fullAccess && (
              <Button onClick={handleOpen} variant="contained" color="primary">
                <AddIcon />
                {`New Question`}
              </Button>
            )}
          </div>
        </Grid>
      </Grid>

      <Card>
        <CardContent>
          {selectdTab === 0 ? (
            <QuestionsCatalogGrid
              data={Object.values(questions).filter((option) => {
                return option.isDisabled === false;
              })}
              disableFunction={handleDisable}
              deleteFunction={handleDelete}
              editFunction={handleEdit}
              refreshGrid={refreshGrid}
            ></QuestionsCatalogGrid>
          ) : null}
          {selectdTab === 1 ? (
            <QuestionsCatalogGrid
              data={Object.values(questions).filter((option) => {
                return option.isDisabled === true;
              })}
              disableFunction={handleDisable}
              deleteFunction={handleDelete}
              editFunction={handleEdit}
              refreshGrid={refreshGrid}
            ></QuestionsCatalogGrid>
          ) : null}
        </CardContent>
      </Card>
      <Popup
        openPopup={isDialogOpen}
        setOpenPopup={setIsDialogOpen}
        title={
          readOnly
            ? "View Qestion"
            : (values.id === 0 ? "Add New" : "Update") + ` Question`
        }
        onClose={() => setIsDialogOpen(false)}
      >
        <Form>
          <Grid container spacing={5}>
            <Grid item xs={12}>
              <FormTextEditor
                value={values.description}
                title={"Question"}
                onChange={handleUpdateTextEditor}
                name={"description"}
                disable={readOnly}
              ></FormTextEditor>
            </Grid>
            <Grid item xs={12}>
              <FormTextArea
                name="solution"
                label="Solution"
                value={values.solution}
                onChange={handleInputChange}
                error={errors.solution}
                placeholder={""}
                disabled={readOnly}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={<Switch />}
                label="Required"
                value={values.isRequired}
                onChange={handleInputChange}
                labelPlacement="start"
                disabled={readOnly}
              />
            </Grid>
            <Grid item xs={12}>
              <QuestionsCatalogAnswers
                questionId={values.id}
                onChange={handleChangeAnswers}
              ></QuestionsCatalogAnswers>
            </Grid>
          </Grid>
        </Form>
        <>
          <FormCancelButton onClick={handleClose} isSubmitting={isSubmitting} />
          {fullAccess && (
            <FormSaveButton onClick={handleSave} isSubmitting={isSubmitting} />
          )}
        </>
      </Popup>
      <DialogMessagePopup
        title={"Information"}
        text={"Are you certain you want to delete this item?"}
        showPopup={showDeleteModal}
        setShowPopup={setShowDeleteModal}
        onSave={deleteDocument}
        isSubmitting={false}
      />
    </React.Fragment>
  );
};

export default QuestionsCatalog;
