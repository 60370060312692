import {
  Skeleton,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { useState } from "react";
import { Visibility, VisibilityOff } from "@mui/icons-material";

interface FormTextHideTextProps {
  name: string;
  label: string;
  value?: string;
  onChange?: (e: any) => void;
  disabled?: boolean;
  showSkeleton?: boolean;
  autocomplete?: boolean | true;
}

export default function FormTextHideText(props: FormTextHideTextProps) {
  const {
    name,
    label,
    value,
    disabled = undefined,
    onChange,
    showSkeleton,
    autocomplete,
  } = props;
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  return (
    <>
      {showSkeleton ? (
        <Skeleton height={50} />
      ) : (
        <FormControl variant="outlined" size="small">
          <InputLabel htmlFor="outlined-adornment-password">{label}</InputLabel>
          <OutlinedInput
            id="outlined-adornment-password"
            type={showPassword ? "text" : "password"}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            label={label}
            name={name}
            value={value}
            onChange={onChange}
            disabled={disabled}
            autoComplete={autocomplete ? "" : "new-password"}
          />
        </FormControl>
      )}
    </>
  );
}
