import { useState, useCallback } from "react";

const useApi = <T>(apiFunc: any) => {
  const [data, setData] = useState<T>(null as any);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [argumets, setArguments] = useState("");

  const request = useCallback(
    async (...args: any[]) => {
      setArguments(JSON.stringify({ ...args }));
      setLoading(true);
      try {
        const result = await apiFunc(...args);
        setData(result.data);
      } catch (err: any) {
        setError(err.message || "Unexpected Error!");
      } finally {
        setLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [apiFunc, argumets]
  );

  //   useEffect(() => {
  //     request();
  //   }, []);

  return {
    data,
    error,
    loading,
    request,
    argumets,
  };
};

export default useApi;
