import axios from "src/utils/axios";
import { ICustomData, IKeyValue, ISimpleCatalogType } from "src/ts/interfaces";
import { IMotorManufacturer } from "src/ts/interfaces/catalogs";
const getKeyValues = () => {
  return new Promise<ICustomData>((resolve, reject) => {
    axios
      .get<Array<ISimpleCatalogType>>(`MotorManufacturer`)
      .then((response) => {
        const keyValuePair: IKeyValue<number, string>[] = response.data.map(
          (item) => {
            return {
              key: item.id!,
              value: item.name,
            };
          }
        );

        resolve({ data: keyValuePair });
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const getManufacturers = () => {
  return axios.get<IMotorManufacturer[]>("MotorManufacturer");
};

const motorManufacturerService = { getKeyValues, getManufacturers };
export default motorManufacturerService;
