import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import SubscriptionService from "src/services/subscriptionService";
import { MetaData } from "src/ts/interfaces/subscription";

import {
  Button,
  CardActions,
  CardContent,
  Grid,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardHeader as MuiCardHeader,
  Divider as MuiDivider,
  Typography,
  Stack,
  IconButton,
} from "@mui/material";
import { Edit, Forward } from "@mui/icons-material";
import { spacing } from "@mui/system";
import EditSubscritionPopUp from "./Components/EditSubscritionPopUp";
import { Permission as PermissionTypes } from "src/ts/enums";
import { useLog, usePermissions } from "src/hooks";

const Card = styled(MuiCard)(spacing);

const CardHeader = styled(MuiCardHeader)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Price = styled.div`
  text-align: center;
  padding-bottom: ${(props) => props.theme.spacing(3)};
`;

const SubscriptionPricing = () => {
  const { log } = useLog();
  const [metaData, setMetaData] = useState<MetaData[]>([]);
  const [showEditSubscription, setShowEditSubscription] = useState(false);
  const [susbcriptionItem, setSusbcriptionItem] = useState<MetaData[]>([]);
  const [refresh, setRefresh] = useState(false);
  const { fullAccess } = usePermissions(PermissionTypes.Subscriptions);
  useEffect(() => {
    const getMetaData = async () => {
      try {
        const response = await SubscriptionService.getMetaData();

        setMetaData(response.data);
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      }
    };
    getMetaData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!refresh) return;
    const getMetaData = async () => {
      try {
        const response = await SubscriptionService.getMetaData();
        setRefresh(false);
        setMetaData(response.data);
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      }
    };
    getMetaData();

    //window.location.reload();
    setRefresh(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh]);

  return (
    <>
      <Helmet title="Pricing" />
      <Typography variant="h3" gutterBottom display="inline">
        Subscription Pricing Preview
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/">
          Dashboard
        </Link>
        <Link component={NavLink} to="/">
          Pages
        </Link>
        <Typography>Pricing</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Grid container justifyContent="center">
        <Grid item xs={12} lg={12}>
          <Grid container spacing={6}>
            {metaData.map((item: any, index: any) => (
              <Grid item xs={12} md={4} key={index}>
                <Card
                  p={5}
                  sx={{
                    boxShadow:
                      "rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;",
                    backgroundColor: item.isActive ? "#233044" : "white",
                    color: item.isActive ? "white" : "black",
                    height: "100%",
                  }}
                  key={index}
                >
                  <Stack direction="row" justifyContent="center">
                    <IconButton
                      aria-label="delete"
                      size="large"
                      onClick={() => {
                        setSusbcriptionItem(item);
                        setShowEditSubscription(true);
                      }}
                    >
                      {fullAccess ? (
                        <Edit
                          sx={{ color: item.isActive ? "white" : "black" }}
                        />
                      ) : (
                        <Forward
                          sx={{ color: item.isActive ? "white" : "black" }}
                        />
                      )}
                    </IconButton>
                  </Stack>

                  <CardHeader
                    title={item.title}
                    titleTypographyProps={{ align: "center" }}
                    pb={0}
                    pt={2}
                    key={index}
                  />
                  <Typography component="h6" variant="h6" align="center">
                    {item.subTitle}
                  </Typography>
                  <Divider
                    my={3}
                    sx={{ background: item.isActive ? "white" : "black" }}
                  />
                  <CardContent>
                    <Price>
                      <Typography
                        component="h3"
                        variant="h4"
                        color="textPrimary"
                        align="center"
                        display="inline"
                        sx={{ color: item.isActive ? "white" : "black" }}
                        key={index}
                      >
                        {item.price}
                      </Typography>
                    </Price>
                    <Divider
                      my={3}
                      sx={{ background: item.isActive ? "white" : "black" }}
                    />
                    {item.textLines.map((textLines: any, index: any) => (
                      <Typography
                        variant="subtitle2"
                        align="center"
                        key={index}
                        sx={{
                          fontWeight: textLines.style === "bolt" ? "bold" : "",
                        }}
                      >
                        {textLines.text} <br />
                      </Typography>
                    ))}
                    <Divider
                      my={2}
                      sx={{ background: item.isActive ? "white" : "black" }}
                    />
                    <Divider
                      my={2}
                      sx={{ background: item.isActive ? "white" : "black" }}
                    />
                  </CardContent>
                  <CardActions>
                    <Button
                      fullWidth
                      variant="outlined"
                      color="primary"
                      sx={{
                        color: item.isActive ? "white" : "black",
                        borderColor: item.isActive ? "white" : "black",
                      }}
                    >
                      More Info Select
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
      {showEditSubscription ? (
        <EditSubscritionPopUp
          setShowEditSubscription={setShowEditSubscription}
          showEditSubscription={showEditSubscription}
          susbcriptionItem={susbcriptionItem}
          setRefresh={setRefresh}
        />
      ) : null}
    </>
  );
};

export default SubscriptionPricing;
