import axios from "src/utils/axios";
import { IEnergyStudyAirHydronicsDTO } from "src/ts/interfaces";

const getById = (id: number) => {
  return axios.get<IEnergyStudyAirHydronicsDTO>(
    `SingleEnergyStudyAirHydronics/${id}`
  );
};

const singleEnergyStudyAirHydronicsService = {
  getById,
};

export default singleEnergyStudyAirHydronicsService;
