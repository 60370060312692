import { IconButton } from "@mui/material";
import { CloudUploadIcon } from "../icons";
import { ToolTip } from "../../components/others/ToolTip";

interface GridUploadButtonProps {
  onChange?: (e: any) => void;
  accept: string;
  index?: number;
  disabled?: boolean;
}

const GridUploadButton = (props: GridUploadButtonProps) => {
  const { onChange, accept, index, disabled } = props;
  return (
    <>
      <input
        accept={accept}
        id={`icon-button-file-${index}`}
        type="file"
        hidden
        onChange={onChange}
        disabled={disabled}
      />
      <label htmlFor={`icon-button-file-${index}`} style={{ marginTop: "3px" }}>
        <ToolTip title={"Upload"}>
          <IconButton component="span" disabled={disabled}>
            <CloudUploadIcon />
          </IconButton>
        </ToolTip>
      </label>
    </>
  );
};

export default GridUploadButton;
