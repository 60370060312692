import axios from "src/utils/axios";
import {
  ICoilsItems,
  ICoilsItemsCircuits,
} from "src/ts/interfaces/study/coils";

const getByCoilId = (id: number) => {
  return axios.get<ICoilsItems[]>(`CoilsItems/ByCoils/${id}`);
};

const getBanksByItemId = (id: number) => {
  return axios.get<ICoilsItemsCircuits[]>(`CoilsItems/Circuits/${id}`);
};

const saveItems = (items: ICoilsItems[]) => {
  return axios.put(`CoilsItems/AllItems`, items);
};

const save = (item: ICoilsItems) => {
  return axios.put(`CoilsItems/Item`, item);
};

const saveBank = (item: ICoilsItemsCircuits) => {
  return axios.put(`CoilsItems/Circuit/${item.id}`, item);
};

const create = (items: ICoilsItems[]) => {
  return axios.post(`CoilsItems`, items);
};

const remove = (id: number) => {
  return axios.delete(`CoilsItems/${id}`);
};

const devActualPDWPSI = (item: ICoilsItems) => {
  return axios.put(`CoilsItems/DevActPDWPSI/${item.id}`, item);
};

const devActPDWFeet = (item: ICoilsItems) => {
  return axios.put(`CoilsItems/DevActPDWFeet/${item.id}`, item);
};

const devDesignPDWPSI = (item: ICoilsItems) => {
  return axios.put(`CoilsItems/DevDesignPDWPSI/${item.id}`, item);
};

const coilsItemService = {
  getByCoilId,
  saveItems,
  create,
  remove,
  save,
  getBanksByItemId,
  saveBank,
  devActualPDWPSI,
  devActPDWFeet,
  devDesignPDWPSI,
};

export default coilsItemService;
