import {
  IProjectHistoryDataPage,
  IProjectHistoryFilter,
} from "src/ts/interfaces/history";
import axios from "src/utils/axios";
import { getFilter } from "src/utils/utils";

const log = (projectId: number, action: string) => {
  return axios.post("ProjectHistory", {
    projectId,
    action,
  });
};

const getProjectBidsHistory = async (
  filter: IProjectHistoryFilter,
  getQuery?: (filters: Record<string, any>) => string
) => {
  const queryString = getQuery ? getQuery(filter) : getFilter(filter);

  return await axios.get<IProjectHistoryDataPage>(
    `ProjectHistory/Bids?${queryString}`
  );
};

const projectHistoryService = {
  log,
  getProjectBidsHistory,
};

export default projectHistoryService;
