import {
  CardContent,
  Grid,
  Card as MuiCard,
  Divider as MuiDivider,
} from "@mui/material";
import { spacing } from "@mui/system";
import styled from "@emotion/styled";
import { useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import HeaderPage from "src/components/page/HeaderPage";
import { useAsyncQuery, useAuth, useEffectOnce, useLog } from "src/hooks";
import chillerService from "src/services/study/chillerService";
import { IReportType } from "src/ts/interfaces";
import { Mode } from "src/ts/types";
import StudyWizard from "../../components/StudyWizard";
import { FormNumeric2, FormText } from "src/components/formControls";
import { FooterButtons } from "../../components/FooterButtons";
import DialogMessagePopup from "src/components/DialogMessagePopup";
import { steps, studyTypeCode } from "../const";
import projectReportService from "src/services/study/projectReportService";
import { IProjectReport } from "src/ts/interfaces/project/projectDto";
import reportTypeService from "src/services/study/reportTypeService";
import studiesHistoryService from "src/services/study/studiesHistoryService";
import useChillerStudy from "../hooks/useChillerStudy";
import HeaderStudyPage from "src/components/page/HeaderStudyPage";

const Divider = styled(MuiDivider)(spacing);
const Card = styled(MuiCard)(spacing);
const activeStep = 0;

interface IChillerCongiguration {
  numberOfChillers: number;
  numberOfTowers: number;
  lastChillerNumber: number;
  lastTowerNumber: number;
}

const ChillerStep1 = () => {
  const { user } = useAuth();
  const params = useParams<{ id: string }>();
  const id = parseInt(params?.id === undefined ? "0" : params?.id);
  let navigate = useNavigate();
  const { log } = useLog();
  const {
    chillerValues,
    setChillerValues,
    chillerErros,
    handleChillerInputChange,
    validateChillerValues,
  } = useChillerStudy();

  const [reportType, setReportType] = useState<IReportType | null>();

  const { execute: executeReport, data: dataReport } =
    useAsyncQuery<IProjectReport>(projectReportService.report);

  const [isLoading, setIsLoading] = useState(false);

  const [chillerConfig, setChillerConfig] = useState<IChillerCongiguration>({
    numberOfChillers: 101,
    numberOfTowers: 101,
    lastChillerNumber: 0,
    lastTowerNumber: 0,
  });

  useEffectOnce(() => {
    const getData = async () => {
      try {
        setIsLoading(true);

        const chillerRes = await chillerService.getById(id);
        setChillerValues(chillerRes.data);

        let chillerConfigTmp: IChillerCongiguration = {
          ...chillerConfig,
          lastChillerNumber: chillerRes.data.chillerNumber,
          lastTowerNumber: chillerRes.data.numberOfTowers,
        };

        if (user?.role === "Training") {
          chillerConfigTmp = {
            ...chillerConfigTmp,
            numberOfChillers: 3, //should be 2 for the validations
            numberOfTowers: 3, //should be 2 for the validations
          };
        }

        setChillerConfig(chillerConfigTmp);

        const reportTypeRes = await reportTypeService.getReportTypeByCode(
          studyTypeCode
        );
        setReportType(reportTypeRes.data);

        await executeReport(id, reportTypeRes.data.code);
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      } finally {
        setIsLoading(false);
      }
    };
    if (id > 0) getData();
  });

  const mode: Mode = chillerValues.isComplete ? "read" : "read&Write";

  const disableStudy = useMemo(() => {
    return (
      chillerValues?.isComplete ||
      (dataReport?.isInUse && dataReport?.isInUseById !== user?.userId) ||
      dataReport?.isPartOfProject === false
    );
  }, [
    dataReport?.isInUse,
    dataReport?.isInUseById,
    dataReport?.isPartOfProject,
    user?.userId,
    chillerValues?.isComplete,
  ]);

  const saveHandler = async () => {
    if (!validateChillerValues()) return;
    validateChillersDecrement("save");
  };

  const nextHandler = () => {
    if (!validateChillerValues()) return;
    validateChillersDecrement("next");
  };

  const [temporalAction, setTemporalAction] = useState("");
  const [showDecreaseChillerModal, setShowDecreaseChillerModal] =
    useState(false);
  const [showDecreaseTowerModal, setShowDecreaseTowerModal] = useState(false);

  const validateChillersDecrement = async (action: string) => {
    setTemporalAction(action);
    if (chillerValues.chillerNumber === 0) {
      log.error("Please enter minimum 1 Chiller");
      return;
    }

    if (chillerValues.numberOfTowers === 0) {
      log.error("Please enter minimum 1 Tower");
      return;
    }

    if (chillerConfig.lastChillerNumber > chillerValues.chillerNumber) {
      setShowDecreaseChillerModal(true);
    } else if (chillerConfig.lastTowerNumber > chillerValues.numberOfTowers) {
      setShowDecreaseTowerModal(true);
    } else {
      await continueAction(action);
    }
  };

  const continueAction = async (action: string) => {
    switch (action) {
      case "save":
        await save();
        break;
      case "next":
        await next();
    }
  };

  const save = async () => {
    await chillerService.update(chillerValues.id, chillerValues);
    await studiesHistoryService.save(
      chillerValues.projectId,
      reportType?.id ?? 0,
      chillerValues.id,
      "saved"
    );
    log.info("Report was saved");
    setChillerConfig({
      ...chillerConfig,
      lastChillerNumber: chillerValues.chillerNumber,
      lastTowerNumber: chillerValues.numberOfTowers,
    });
  };

  const next = async () => {
    if (!disableStudy) await save();
    const route = `/studies/Chiller/step2/${id}`;
    navigate(route);
  };

  return (
    <>
      <HeaderStudyPage
        headerStudiesPage={{
          code: studyTypeCode,
          system: chillerValues.system,
          id: id,
        }}
        parentText="Project"
        parentLink="/"
      />

      <Divider my={6} />

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <StudyWizard
            steps={steps}
            activeStep={activeStep}
            projectId={dataReport?.projectId}
            reportId={dataReport?.reportId}
            reportTypeId={dataReport?.reportTypeId}
          />
        </Grid>
        <Grid item xs={12}>
          <Card mb={1}>
            <CardContent>
              <Grid container spacing={5}>
                <Grid item xs={12}>
                  <FormText
                    name="projectName"
                    label="Project Name"
                    value={chillerValues.projectName}
                    onChange={handleChillerInputChange}
                    error={chillerErros.projectName}
                    showSkeleton={isLoading}
                    mode={mode}
                    disabled={disableStudy}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormText
                    name="system"
                    label="System(s) Served"
                    value={chillerValues.system}
                    onChange={handleChillerInputChange}
                    error={chillerErros.system}
                    showSkeleton={isLoading}
                    maxLength={50}
                    mode={mode}
                    disabled={disableStudy}
                    type="text"
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormNumeric2
                    name="chillerNumber"
                    label="Quantity of Chillers"
                    value={chillerValues.chillerNumber}
                    onChange={handleChillerInputChange}
                    error={chillerErros.chillerNumber}
                    maxValue={chillerConfig.numberOfChillers}
                    mode={mode}
                    disabled={disableStudy}
                    showSkeleton={isLoading}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormNumeric2
                    name="numberOfTowers"
                    label="Quantity of Towers"
                    value={chillerValues.numberOfTowers}
                    onChange={handleChillerInputChange}
                    error={chillerErros.numberOfTowers}
                    maxValue={chillerConfig.numberOfTowers}
                    mode={mode}
                    disabled={disableStudy}
                    showSkeleton={isLoading}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormText
                    name="location"
                    label="Location"
                    value={chillerValues.location}
                    onChange={handleChillerInputChange}
                    showSkeleton={isLoading}
                    mode={mode}
                    disabled={disableStudy}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FooterButtons
                    activeStep={activeStep}
                    stepsCount={steps.length}
                    projectId={dataReport?.projectId}
                    companyId={user?.companyId as number}
                    isSaving={false}
                    disabled={false}
                    reportName={reportType?.name ?? ""}
                    saveHandler={saveHandler}
                    nextStepHandler={nextHandler}
                    hideSaveButton={disableStudy}
                    isComplete={chillerValues.isComplete}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <DialogMessagePopup
        title={"Warning"}
        text={
          <div>
            You are about to decrease the number of Chillers in this Report wich
            will be removed permanently. Are you certain you want to continue?
          </div>
        }
        isSubmitting={false}
        showPopup={showDecreaseChillerModal}
        setShowPopup={setShowDecreaseChillerModal}
        onSave={() => {
          continueAction(temporalAction);
        }}
      />
      <DialogMessagePopup
        title={"Warning"}
        text={
          <div>
            You are about to decrease the number of Towers in this Report wich
            will be removed permanently. Are you certain you want to continue?
          </div>
        }
        isSubmitting={false}
        showPopup={showDecreaseTowerModal}
        setShowPopup={setShowDecreaseTowerModal}
        onSave={() => {
          continueAction(temporalAction);
        }}
      />
    </>
  );
};

export default ChillerStep1;
