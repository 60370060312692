import React from "react";
import { CircularProgress } from "@mui/material";
import { KeyboardBackspace as KeyboardBackspaceIcon } from "@mui/icons-material";
import FormButton from "./FormButton";

interface ButtonProps {
  onClick: (e: React.MouseEvent<HTMLElement>) => void;
  [key: string]: any;
  isSubmitting: boolean;
  disabled?: boolean;
  color?:
    | "inherit"
    | "primary"
    | "secondary"
    | "success"
    | "error"
    | "info"
    | "warning"
    | undefined;
  variant?: "text" | "contained" | "outlined" | undefined;
}

const FormBackButton = (props: ButtonProps) => {
  const {
    text,
    size,
    variant,
    onClick,
    isSubmitting,
    disabled,
    color,
    ...other
  } = props;

  return (
    <FormButton
      color={color || "primary"}
      text="Prev Step"
      variant={variant || "outlined"}
      size="small"
      onClick={onClick}
      startIcon={
        isSubmitting ? (
          <CircularProgress size="1rem" />
        ) : (
          <KeyboardBackspaceIcon />
        )
      }
      disabled={disabled || isSubmitting}
      {...other}
    />
  );
};

export default FormBackButton;
