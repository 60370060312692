import {
  ITechnicianQuizAnswersTech,
  ITechnicianQuizExam,
  ITechnicianQuizExamAnswers,
  ITechnicianQuizExamList,
  ITechnicianQuizExamResults,
} from "../ts/interfaces";
import { ITechnicianQuizQuestions } from "../ts/interfaces/technicianQuizQuestions";
import axios from "../utils/axios";

const getChartById = async (id: number) => {
  return axios.get<ITechnicianQuizExamResults>(
    `TechnicianQuizExam/Results?quizId=` + id
  );
};

const getByQuizExamId = async (id: number) => {
  return axios.get<ITechnicianQuizExamAnswers[]>(`TechnicianQuiz`);
};

const getByQuizExamQuizId = async (id: number) => {
  return axios.get<ITechnicianQuizExamList[]>(
    `TechnicianQuizExam/getById/` + id
  );
};

const getViewQuizExamId = async (id: number) => {
  return axios.get<ITechnicianQuizExamAnswers[]>(
    `TechnicianQuizExam/GetByQuizExamId?quizExamId=` + id
  );
};

const GetQuizExamByUser = async () => {
  return axios.get<ITechnicianQuizExam>(`TechnicianQuizExam/GetQuizExamByUser`);
};

const getQuiz = async () => {
  return axios.get<ITechnicianQuizQuestions[]>(`TechnicianQuizExam/getQuiz`);
};

const updateAnswer = (answer: ITechnicianQuizAnswersTech) => {
  return axios.post(`TechnicianQuizExam/UpdateAnswer`, answer);
};

const finishExam = () => {
  return axios.put(`TechnicianQuizExam/finishExam`, null);
};

const reschedule = (date: string) => {
  return axios.post(
    `TechnicianQuizExam/reschedule?rescheduleDate=` + date,
    null
  );
};

const getByTechId = async (techId: number) => {
  return axios.get(`TechnicianQuizExam/getByTechId/${techId}`);
};

const getQuizExam = async (techId: number) => {
  return axios.get<ITechnicianQuizExamAnswers[]>(
    `TechnicianQuizExam/GetByQuizExamByTechId?techId=${techId}`
  );
};

const technicianQuizExamService = {
  getByQuizExamId,
  getByQuizExamQuizId,
  getViewQuizExamId,
  getChartById,
  GetQuizExamByUser,
  getQuiz,
  updateAnswer,
  finishExam,
  reschedule,
  getByTechId,
  getQuizExam,
};

export default technicianQuizExamService;
