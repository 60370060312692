import Popup from "src/components/Popup";
import {
  FormButton,
  FormCancelButton,
  FormLabel,
  FormText,
} from "src/components/formControls";
import {
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  Typography,
  styled,
  Chip as MuiChip,
} from "@mui/material";
import {
  ICartPursasheResult,
  IPreviewPursacheProject,
  IPurchaseResultMessage,
  IpursacheCartProps,
} from "src/ts/interfaces/cart";
import { ColumnType } from "src/types/enhancedTable";
import LocalEnhancedTable from "src/components/localTable/LocalTable";
import { useAsyncQuery, useAuth, useLog, usePermissions } from "src/hooks";
import cartService from "src/services/cartService";
import { useEffect, useState } from "react";
import PurchaseSummary from "./PurchaseSummary";
import { VisibilityIcon, VisibilityOffIcon } from "src/components/icons";
import { Permission as PermissionTypes } from "src/ts/enums";
import { currencyformatter } from "src/utils/format";
import { PayCartItemsResultMessage } from "src/constants";
import { SpacingProps, spacing } from "@mui/system";
interface ChipProps extends SpacingProps {
  component?: React.ElementType;
  href?: string;
  icon?: JSX.Element | null;
}
const Chip = styled(MuiChip)<ChipProps>(spacing);
interface Props {
  pursacheCarProps: {
    carts: number[];
    showPopup: boolean;
  };
  setPursacheCarProps: React.Dispatch<React.SetStateAction<IpursacheCartProps>>;
  refreshTable: () => void;
}

const columns = (): ColumnType[] => [
  {
    id: "code",
    label: "Code",
    type: "string",
    sort: true,
  },
  {
    id: "description",
    label: "Description",
    type: "string",
    sort: true,
  },
  {
    id: "quantity",
    label: "Qty",
    type: "string",
    sort: false,
  },
  {
    id: "price",
    label: "Price",
    type: "custom",
    sort: true,
    align: "right",
    callback: (row: IPreviewPursacheProject) => {
      return (
        <>
          <Typography textAlign={"right"}>
            {currencyformatter.format(row.price ?? 0)}
          </Typography>
        </>
      );
    },
  },
  {
    id: "discountsCode",
    label: "Discount Code",
    type: "custom",
    sort: false,
    align: "center",
    callback: (row: IPreviewPursacheProject) => {
      return (
        <>
          <Typography textAlign={"center"}>{row.coupon?.code}</Typography>
        </>
      );
    },
  },
  {
    id: "discountsValue",
    label: "Discount %",
    type: "custom",
    sort: false,
    align: "center",
    callback: (row: IPreviewPursacheProject) => {
      return (
        <Typography textAlign={"center"}>
          {row.coupon?.value && (
            <Chip
              label={`${row.coupon?.value}%`}
              color="success"
              m={1}
              size="small"
            />
          )}
        </Typography>
      );
    },
  },
  {
    id: "discounts",
    label: "Discount",
    type: "custom",
    sort: true,
    align: "right",
    totalLabel: "Total:",
    callback: (row: IPreviewPursacheProject) => {
      return (
        <>
          <Typography textAlign={"right"}>
            {currencyformatter.format(row.discounts ?? 0)}
          </Typography>
        </>
      );
    },
  },
  {
    id: "total",
    label: "Total",
    type: "custom",
    sort: true,
    align: "right",
    total: "total",
    totalLabelAlign: "right",
    callback: (row: IPreviewPursacheProject) => {
      return (
        <>
          <Typography textAlign={"right"}>
            {currencyformatter.format(row.total ?? 0)}
          </Typography>
        </>
      );
    },
  },
];

export default function PursacheCartPopup({
  pursacheCarProps,
  setPursacheCarProps,
  refreshTable,
}: Props) {
  const { user } = useAuth();
  const { log } = useLog();
  const { execute, data, isLoading } = useAsyncQuery<ICartPursasheResult>(
    cartService.getPreviewPursache,
    {
      onSuccess: () => {
        setLoadedInfo(loadedInfo + 1);
      },
    }
  );
  const {
    execute: executePay,
    isLoading: isLoadingPay,
    data: dataPay,
  } = useAsyncQuery<IPurchaseResultMessage>(cartService.payCartItems);
  const [coupons, setCoupons] = useState<string[]>([]);
  const [showCouponText, setShowCouponText] = useState(false);
  const [coupon, setCoupon] = useState("");
  const [loadedInfo, setLoadedInfo] = useState(0);
  const { read: readWallet } = usePermissions(PermissionTypes.Wallet);
  const handleClickShowPassword = () => setShowCouponText((show) => !show);
  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  useEffect(() => {
    const getData = async () => {
      const listcoupons = Object.keys(coupons).map((key, item) => {
        return coupons[item];
      });
      const payload = {
        selected: pursacheCarProps.carts,
        coupons: listcoupons,
      };
      try {
        await execute(user?.companyId, payload);
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      }
    };

    if (pursacheCarProps.carts.length > 0 && pursacheCarProps.showPopup)
      getData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pursacheCarProps.showPopup]);

  useEffect(() => {
    if (data?.result?.message) {
      log.error(data?.result?.message);
      setCoupons(data?.result?.coupons);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const handleClosePopUp = () => {
    setPursacheCarProps({
      carts: [],
      showPopup: false,
    });
    setCoupon("");
    setCoupons([]);
  };

  const handleOpenPopUp = () => {
    setPursacheCarProps({
      ...pursacheCarProps,
      showPopup: true,
    });
  };

  const validateCouponHandler = async () => {
    let couponsNew = [...coupons];
    couponsNew.push(coupon);
    setCoupons(couponsNew);
    setCoupon("");

    const listcoupons = Object.keys(couponsNew).map((key, item) => {
      return couponsNew[item];
    });
    const payload = {
      selected: pursacheCarProps.carts,
      coupons: listcoupons,
    };
    await execute(user?.companyId, payload);
  };

  const handleChangeCoupon = (e: any) => {
    setCoupon(e.target.value);
  };

  const handlePayItems = async () => {
    const listcoupons = Object.keys(coupons).map((key, item) => {
      return coupons[item];
    });
    const payload = {
      selected: pursacheCarProps.carts,
      coupons: listcoupons,
    };
    try {
      await executePay(user?.companyId, payload);
    } catch (error: any) {
      log.error(error?.message?.exceptionMessage ?? "Something went wrong");
    }
  };

  useEffect(() => {
    if (dataPay) {
      if (dataPay.statusCode === PayCartItemsResultMessage.insufficientFunds)
        log.error(dataPay.resultMessage);
      else log.success(dataPay.resultMessage);
      handleClosePopUp();
      refreshTable();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataPay]);

  return (
    <>
      <Popup
        title="Payment Confirmation"
        openPopup={pursacheCarProps.showPopup}
        setOpenPopup={handleOpenPopUp}
        onClose={handleClosePopUp}
        size="md"
        disableClickOutside
        isSubmitting={isLoadingPay}
      >
        <>
          <Stack mt={5} direction="column">
            <Grid item xs={12}>
              {readWallet && (
                <PurchaseSummary
                  companyId={user?.companyId}
                  total={data?.result.total}
                ></PurchaseSummary>
              )}
              <Grid container sx={{ marginTop: "10px", marginBottom: "10px" }}>
                <Grid
                  item
                  xs={4}
                  textAlign={"right"}
                  marginRight={"5px"}
                  marginTop={"7px"}
                >
                  <FormLabel text="Promotional code:" />
                </Grid>
                <Grid item xs={3} sx={{ marginRight: "5px" }}>
                  <Stack direction="row">
                    <FormText
                      name="codeCoupon"
                      label="Enter your code:"
                      value={coupon}
                      onChange={handleChangeCoupon}
                      type={showCouponText ? "text" : "password"}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showCouponText ? (
                              <VisibilityIcon />
                            ) : (
                              <VisibilityOffIcon />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                    {/* Coupon was successfully applied */}
                  </Stack>
                </Grid>
                <Grid item xs={3} paddingTop={"5px"}>
                  <FormButton
                    text="Validate Coupon"
                    onClick={validateCouponHandler}
                    size="small"
                    variant="outlined"
                    isSubmitting={isLoading || isLoadingPay}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <LocalEnhancedTable<IPreviewPursacheProject>
                  refreshGrid={true}
                  columns={columns()}
                  data={Object.values(data?.result?.items ?? [])}
                  showSkeleton={isLoading && loadedInfo === 0}
                  defaultSortColumn={"code"}
                  totalData={data?.result ?? []}
                />
              </Grid>
            </Grid>
          </Stack>
        </>

        <Stack direction="row" spacing={2}>
          <FormCancelButton
            onClick={() => {
              handleClosePopUp();
            }}
            isSubmitting={isLoadingPay}
          />
          <FormButton
            onClick={handlePayItems}
            text="Accept"
            size="medium"
            isSubmitting={isLoadingPay}
          />
        </Stack>
      </Popup>
    </>
  );
}
