import React, { useState, useEffect } from "react";
import Popup from "src/components/Popup";
import {
  FormButton,
  FormRaitingStarts,
  FormRadioGroup,
  FormTextArea,
} from "src/components/formControls";
import {
  Stack,
  Typography,
  Divider as MuiDivider,
  Grid,
  Tabs,
  Tab,
} from "@mui/material";
import {
  IRatingContractors,
  IRatingProject,
} from "src/ts/interfaces/projectOutToBid";
import styled from "@emotion/styled";
import { minHeight, spacing } from "@mui/system";
import contractorRatingService from "src/services/contractorRatingService";
import {
  lastQuestionRating,
  ratingOptionReversed,
  ratingOptionsSurvey,
} from "src/constants";
import { useAsyncQuery, useForm, useLog, usePermissions } from "src/hooks";
import { ITestimonialSurvey } from "src/ts/interfaces/testimonial";
import testimonialSurveyService from "src/services/testimonialSurveyService";
import { Permission as PermissionTypes } from "src/ts/enums";
import { ColumnType } from "src/types/enhancedTable";
import LocalEnhancedTable from "src/components/localTable/LocalTable";
import { SxProps } from "@mui/material";

const Divider = styled(MuiDivider)(spacing);
interface InformationProps {
  showRatingContractorPopUp: boolean;
  setShowRatingContractorPopUp: (val: boolean) => void;
  informationContractor?: number;
  isProject?: boolean;
  setRefresh?: (val: boolean) => void;
  setInfo?: React.Dispatch<any>;
  testimonialId?: number;
  isNotAuthorized?: boolean;
  hideComments?: boolean;
}

const initialValues: IRatingContractors = {
  question1Rating: 0,
  question2Rating: 0,
  question3Rating: 0,
  question4Rating: 0,
  question5Rating: 0,
  comments: "",
  address: "",
  buildingName: "",
  buildingAddress: "",
  projectName: "",
  companyName: "",
  projectId: 0,
  rating: 0,
};

const initialValuesTestimonials: IRatingProject = {
  question1Rating: 0,
  question2Rating: 0,
  question3Rating: 0,
  question4Rating: 0,
  question5Rating: 0,
  question6Rating: 0,
  question7Rating: 0,
  comments: "",
  address: "",
  buildingName: "",
  buildingAddress: "",
  projectName: "",
  companyName: "",
  projectId: 0,
  rating: 0,
  createdDate: "",
  saved: false,
  saveRejected: false,
  saveAgain: false,
  authorizeAppearWebPage: false,
  updatedDate: new Date(),
};

export default function RatingInformation({
  showRatingContractorPopUp,
  setShowRatingContractorPopUp,
  informationContractor,
  isProject,
  setRefresh,
  setInfo,
  testimonialId,
  isNotAuthorized,
  hideComments,
}: InformationProps) {
  const [ratingContractor, setRatingContractor] =
    useState<IRatingContractors>();

  const [ratingTestimonial, setRatingTestimonial] =
    useState<ITestimonialSurvey>();

  const { log } = useLog();
  const { values, setValues } = useForm(
    testimonialId === null || testimonialId === undefined
      ? initialValues
      : initialValuesTestimonials,
    false,
    []
  );

  const { fullAccess: fullAccessTestimonials } = usePermissions(
    PermissionTypes.Testimonials
  );

  const [tab, setTab] = useState(0);
  const Spacer = styled.div(spacing);

  interface IComments {
    comment: string;
    createdDate: string;
  }

  const getColumns = (): ColumnType[] => [
    {
      id: "createdDate",
      label: "Created Date",
      type: "utcDate",
      format: "MM/DD/YYYY h:mma",
      sort: true,
      width: "150px",
    },
    {
      id: "comment",
      label: "Comments",
      type: "string",
      sort: true,
    },
  ];

  let columns = getColumns();

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  const { execute: executePostRating } = useAsyncQuery(
    contractorRatingService.postContractorRating,
    {
      onSuccess: async () => {
        log.success("The contractor rating was successfully created.");
        setShowRatingContractorPopUp(false);
        if (!isProject) {
          await getRating();
        } else {
          await getRatingProject();
        }
        if (setRefresh) {
          setRefresh(true);
        }
      },
    }
  );

  const handleClosePopUp = () => {
    setShowRatingContractorPopUp(false);
  };

  const getRating = async () => {
    const response = await contractorRatingService.getContractorRating(
      informationContractor ?? 0
    );
    setRatingContractor(response.data);
    setValues(response.data);
    if (setInfo) setInfo(response.data);
  };

  const getRatingProject = async () => {
    const response = await contractorRatingService.getContractorRatingProject(
      informationContractor ?? 0
    );
    setRatingContractor(response.data);
    setValues(response.data);
    if (setInfo) setInfo(response.data);
  };

  const getTestimonial = async () => {
    if (testimonialId)
      try {
        const response = await testimonialSurveyService.getCurrent(
          testimonialId ?? 0
        );
        setRatingTestimonial(response.data);
        setValues(response.data);
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      }
  };

  useEffect(() => {
    if (testimonialId === null || testimonialId === undefined) {
      if (!isProject) {
        getRating();
      } else {
        getRatingProject();
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [informationContractor]);

  useEffect(() => {
    if (testimonialId !== null && testimonialId !== undefined) getTestimonial();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [testimonialId]);

  const InformationProject = () => {
    return (
      <>
        <Grid container>
          <Grid item xs={2}>
            <Stack textAlign={"center"}>
              <Typography ml={3}>
                {" "}
                <b>Contractor's Name: </b>
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={6}>
            <Typography>{ratingContractor?.companyName}</Typography>
          </Grid>
          <Grid item xs={4}>
            {values?.saved && (
              <Stack direction="row" spacing={10} justifyContent="end">
                <Typography>Rating</Typography>
                {ratingContractor && (
                  <FormRaitingStarts
                    value={values?.rating}
                    readOnly={values?.saved}
                    onChange={(rating) => {
                      setValues({
                        ...values,
                        rating: rating,
                      });
                    }}
                    precision={values?.saved ? 0.5 : 1}
                  />
                )}
              </Stack>
            )}
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={2}>
            <Stack textAlign={"center"}>
              <Typography>
                {" "}
                <b>Contractor's Address:</b>{" "}
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={10}>
            <Typography> {ratingContractor?.address}</Typography>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={2}>
            <Stack textAlign={"center"}>
              <Typography ml={11}>
                {" "}
                <b>Project Name:</b>{" "}
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={10}>
            <Typography> {ratingContractor?.projectName}</Typography>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={2}>
            <Stack textAlign={"center"}>
              <Typography ml={20}>
                {" "}
                <b>Building:</b>{" "}
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={10}>
            <Typography>
              {`${ratingContractor?.buildingName} - ${ratingContractor?.buildingAddress}`}
            </Typography>
          </Grid>
        </Grid>
      </>
    );
  };

  const InformationOthers = () => {
    return (
      <>
        <Grid container>
          <Grid item xs={6}>
            <Grid container>
              <Grid item xs={3}>
                <Typography sx={{ fontWeight: "bold" }} textAlign={"right"}>
                  Company Name:
                </Typography>
              </Grid>
              <Grid item xs={7} pl={1.5}>
                <Typography>{ratingContractor?.companyName}</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={2}></Grid>
          <Grid item xs={4}>
            <Stack direction="row" spacing={10} justifyContent="end">
              <Typography>Rating</Typography>
              {ratingContractor && (
                <FormRaitingStarts value={ratingContractor?.rating} />
              )}
            </Stack>
          </Grid>

          <Grid item xs={1.5} textAlign={"right"}>
            <Typography sx={{ fontWeight: "bold" }}> Address: </Typography>
          </Grid>
          <Grid item xs={9} textAlign={"left"} pl={1.5}>
            <Typography>{`${ratingContractor?.address}`}</Typography>
          </Grid>
        </Grid>
      </>
    );
  };

  const InformationTestimonial = () => {
    return (
      <>
        <Grid container>
          <Grid item xs={6}>
            <Grid container>
              <Grid item xs={2.4}>
                <Typography sx={{ fontWeight: "bold" }} textAlign={"right"}>
                  Name:
                </Typography>
              </Grid>
              <Grid item xs={7} pl={1.5}>
                <Typography>{`${ratingTestimonial?.userPerson.name} ${ratingTestimonial?.userPerson.lastName}`}</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={2}></Grid>
          <Grid item xs={4}>
            <Stack direction="row" spacing={10} justifyContent="end">
              <Typography>Rating</Typography>
              <FormRaitingStarts value={ratingTestimonial?.rating} />
            </Stack>
          </Grid>

          <Grid item xs={1.2} textAlign={"right"}>
            <Typography sx={{ fontWeight: "bold" }}> Project Name: </Typography>
          </Grid>
          <Grid item xs={10} textAlign={"left"} pl={1.5}>
            <Typography>{`${ratingTestimonial?.project.name}`}</Typography>
          </Grid>
        </Grid>
      </>
    );
  };

  const disableAdd =
    !values.question1Rating ||
    !values.question2Rating ||
    !values.question3Rating ||
    !values.question4Rating ||
    !values.question5Rating;

  const handlerAccept = async () => {
    await executePostRating(values);
  };

  const handleUpdateStatus = async (id: number, status: number) => {
    try {
      await testimonialSurveyService.updatestatus(id, status);
      log.success("The status was updated");
      setShowRatingContractorPopUp(false);
    } catch (error: any) {
      log.error(error?.message?.exceptionMessage ?? "Something went wrong");
    }
  };

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setTab(newValue);
  };

  return (
    <>
      <Popup
        title={
          testimonialId !== null && testimonialId !== undefined
            ? "Testimonial Survey"
            : "Contractor's Rating"
        }
        openPopup={showRatingContractorPopUp}
        setOpenPopup={setShowRatingContractorPopUp}
        onClose={handleClosePopUp}
        size="md"
        paperProps={{
          style: {
            height: "600px",
          },
        }}
      >
        {ratingContractor === undefined && testimonialId === null ? (
          <></>
        ) : (
          <>
            {!isProject &&
              (testimonialId === null || testimonialId === undefined) && (
                <InformationOthers />
              )}
            {isProject &&
              (testimonialId === null || testimonialId === undefined) && (
                <InformationProject />
              )}
            {testimonialId !== null && testimonialId !== undefined && (
              <InformationTestimonial />
            )}

            <Divider my={2} />

            <Tabs value={tab} onChange={handleChangeTab} aria-label="Rating">
              <Tab label="Answers" id="0" value={0} />
              {!(!isProject || values?.saved) ? (
                <></>
              ) : (
                <Tab label="Comments" id="1" value={1} />
              )}
            </Tabs>
            {tab === 0 && (
              <>
                <Spacer mb={4}></Spacer>
                {!testimonialId && (
                  <>
                    <Stack direction="row" spacing={10}>
                      <Typography>
                        1. How satisfied are you with the knowledge of the
                        project's contractor and field technician(s)?
                      </Typography>
                    </Stack>
                    <Stack
                      direction="row"
                      justifyContent="start"
                      alignItems="center"
                    >
                      <FormRadioGroup
                        name="question1Rating"
                        label=""
                        value={values.question1Rating}
                        onChange={handleInputChange}
                        items={ratingOptionReversed}
                        disabled={!isProject || values?.saved}
                      />
                    </Stack>
                    <Divider my={2} />
                    <Stack direction="row" spacing={10}>
                      <Typography>
                        2. How satisfied are you with the communication and
                        professionalism of the project's contractor and
                        technician(s)?
                      </Typography>
                    </Stack>
                    <Stack
                      direction="row"
                      justifyContent="start"
                      alignItems="center"
                    >
                      <FormRadioGroup
                        name="question2Rating"
                        label=""
                        value={values.question2Rating}
                        onChange={handleInputChange}
                        items={ratingOptionReversed}
                        disabled={!isProject || values?.saved}
                      />
                    </Stack>
                    <Divider my={2} />
                    <Stack direction="row" spacing={10}>
                      <Typography>
                        3. How satisfied are you with the contractor's and
                        technician(s)' ability to address and resolve problems
                        on this project?
                      </Typography>
                    </Stack>
                    <Stack
                      direction="row"
                      justifyContent="start"
                      alignItems="center"
                    >
                      <FormRadioGroup
                        name="question3Rating"
                        label=""
                        value={values.question3Rating}
                        onChange={handleInputChange}
                        items={ratingOptionReversed}
                        disabled={!isProject || values?.saved}
                      />
                    </Stack>
                    <Divider my={2} />
                    <Stack direction="row" spacing={10}>
                      <Typography>
                        4. How satisfied are you with the contractor’s
                        thoroughness in fulfilling and meeting the
                        project/contract requirements for measurement and
                        verification (M&V), along with testing and data
                        collection?
                      </Typography>
                    </Stack>
                    <Stack
                      direction="row"
                      justifyContent="start"
                      alignItems="center"
                    >
                      <FormRadioGroup
                        name="question4Rating"
                        label=""
                        value={values.question4Rating}
                        onChange={handleInputChange}
                        items={ratingOptionReversed}
                        disabled={!isProject || values?.saved}
                      />
                    </Stack>
                    <Divider my={2} />
                    <Stack direction="row" spacing={10}>
                      <Typography>
                        5. Overall, would you use or recommend this contractor
                        to someone else?
                      </Typography>
                    </Stack>
                    <Stack
                      direction="row"
                      justifyContent="start"
                      alignItems="center"
                    >
                      <FormRadioGroup
                        name="question5Rating"
                        label=""
                        value={values.question5Rating}
                        onChange={handleInputChange}
                        items={lastQuestionRating}
                        disabled={!isProject || values?.saved}
                      />
                    </Stack>
                  </>
                )}

                {testimonialId && (
                  <>
                    <Stack direction="row" spacing={10}>
                      <Typography>
                        1. How likely is it that you recommend this software to
                        a friend or contractor?
                      </Typography>
                    </Stack>
                    <Stack
                      direction="row"
                      justifyContent="start"
                      alignItems="center"
                    >
                      <FormRadioGroup
                        name="question1Rating"
                        label=""
                        value={values?.question1Rating}
                        onChange={handleInputChange}
                        items={ratingOptionsSurvey}
                        disabled={!isProject || values?.saved}
                      />
                    </Stack>
                    <Divider my={2} />
                    <Stack direction="row" spacing={10}>
                      <Typography>
                        2. How satisfied are you with the reliability of this
                        software?
                      </Typography>
                    </Stack>
                    <Stack
                      direction="row"
                      justifyContent="start"
                      alignItems="center"
                    >
                      <FormRadioGroup
                        name="question2Rating"
                        label=""
                        value={values?.question2Rating}
                        onChange={handleInputChange}
                        items={ratingOptionReversed}
                        disabled={!isProject || values?.saved}
                      />
                    </Stack>
                    <Divider my={2} />
                    <Stack direction="row" spacing={10}>
                      <Typography>
                        3. How satisfied are you with the security of AMP
                        software?
                      </Typography>
                    </Stack>
                    <Stack
                      direction="row"
                      justifyContent="start"
                      alignItems="center"
                    >
                      <FormRadioGroup
                        name="question3Rating"
                        label=""
                        value={values?.question3Rating}
                        onChange={handleInputChange}
                        items={ratingOptionReversed}
                        disabled={!isProject || values?.saved}
                      />
                    </Stack>
                    <Divider my={2} />
                    <Stack direction="row" spacing={10}>
                      <Typography>
                        4. How satisfied are you with the ease of use of AMP
                        software?
                      </Typography>
                    </Stack>
                    <Stack
                      direction="row"
                      justifyContent="start"
                      alignItems="center"
                    >
                      <FormRadioGroup
                        name="question4Rating"
                        label=""
                        value={values?.question4Rating}
                        onChange={handleInputChange}
                        items={ratingOptionReversed}
                        disabled={!isProject || values?.saved}
                      />
                    </Stack>
                    <Divider my={2} />
                    <Stack direction="row" spacing={10}>
                      <Typography>
                        5. How satisfied are you with the look and feel of AMP
                        software?
                      </Typography>
                    </Stack>
                    <Stack
                      direction="row"
                      justifyContent="start"
                      alignItems="center"
                    >
                      <FormRadioGroup
                        name="question5Rating"
                        label=""
                        value={values?.question5Rating}
                        onChange={handleInputChange}
                        items={ratingOptionReversed}
                        disabled={!isProject || values?.saved}
                      />
                    </Stack>
                    <Divider my={2} />
                    <Stack direction="row" spacing={10}>
                      <Typography>
                        6. How satisfied are you with the value of AMP software?
                      </Typography>
                    </Stack>
                    <Stack
                      direction="row"
                      justifyContent="start"
                      alignItems="center"
                    >
                      <FormRadioGroup
                        name="question6Rating"
                        label=""
                        value={values?.question6Rating}
                        onChange={handleInputChange}
                        items={ratingOptionReversed}
                        disabled={!isProject || values?.saved}
                      />
                    </Stack>
                    <Divider my={2} />
                    <Stack direction="row" spacing={10}>
                      <Typography>
                        7. How satisfied are you with the ability to collaborate
                        with other users on this software?
                      </Typography>
                    </Stack>
                    <Stack
                      direction="row"
                      justifyContent="start"
                      alignItems="center"
                    >
                      <FormRadioGroup
                        name="question7Rating"
                        label=""
                        value={values?.question7Rating}
                        onChange={handleInputChange}
                        items={ratingOptionReversed}
                        disabled={!isProject || values?.saved}
                      />
                    </Stack>
                  </>
                )}
                {!(!isProject || values?.saved) && (
                  <>
                    <Divider my={2} />
                    <Stack>
                      <FormTextArea
                        name="comments"
                        label="Comments"
                        value={values.comments}
                        placeholder="Comments"
                        onChange={handleInputChange}
                        disabled={!isProject || values?.saved}
                      />
                    </Stack>
                  </>
                )}
              </>
            )}
            {tab === 1 && (
              <>
                <Divider my={2} />
                <Spacer mb={4}></Spacer>
                {!testimonialId && (
                  <>
                    <Grid container>
                      <Grid item xs={12}>
                        <LocalEnhancedTable<IComments>
                          refreshGrid={true}
                          columns={columns}
                          data={values.commentsDTO}
                          showSkeleton={false}
                        />
                      </Grid>
                    </Grid>
                  </>
                )}
                {testimonialId && (
                  <Stack>
                    <FormTextArea
                      name="comments"
                      label="Comments"
                      value={values.comments}
                      placeholder="Comments"
                      onChange={handleInputChange}
                      disabled={!isProject || values?.saved}
                    />
                  </Stack>
                )}
              </>
            )}
          </>
        )}
        <Stack direction={"row"} spacing={2}>
          <FormButton
            text="Close"
            variant="outlined"
            onClick={() => {
              setShowRatingContractorPopUp(false);
            }}
            size="small"
          />
          {testimonialId !== null &&
            testimonialId !== undefined &&
            fullAccessTestimonials && (
              <>
                {(!isNotAuthorized || isNotAuthorized === undefined) && (
                  <>
                    {(ratingTestimonial?.sendToWebPage === 2 ||
                      ratingTestimonial?.sendToWebPage === 4) && (
                      <FormButton
                        text={
                          testimonialId !== null && testimonialId !== undefined
                            ? "Keep Private"
                            : "Reject"
                        }
                        variant={
                          ratingTestimonial?.sendToWebPage === 2
                            ? "contained"
                            : "outlined"
                        }
                        onClick={() => {
                          handleUpdateStatus(ratingTestimonial.id, 3);
                        }}
                        size="small"
                      />
                    )}
                    {(ratingTestimonial?.sendToWebPage === 3 ||
                      ratingTestimonial?.sendToWebPage === 4) && (
                      <FormButton
                        text="Publish"
                        variant="contained"
                        onClick={() => {
                          handleUpdateStatus(ratingTestimonial.id, 2);
                        }}
                        size="small"
                      />
                    )}
                  </>
                )}
              </>
            )}
          {!values.saved &&
            (testimonialId === null || testimonialId === undefined) && (
              <FormButton
                text="Accept"
                color="primary"
                onClick={handlerAccept}
                size="small"
                disabled={disableAdd}
              />
            )}
        </Stack>
      </Popup>
    </>
  );
}
