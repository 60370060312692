import axios from "../utils/axios";
import {
  IPlaqueCompanyOrders,
  IPlaqueOrder,
  IPlaqueOrderModify,
  IPlaqueProviders,
  IUpdatePlaqueOrder,
} from "../ts/interfaces";
import { OrderStatusEnum } from "../ts/enums/plaquesAndDecals";

const getByStatus = (orderStatus: OrderStatusEnum) => {
  return axios.get<IPlaqueProviders[]>(
    `PlaqueOrder?OrderStatus=${orderStatus}`
  );
};

const getById = (id: Number) => {
  return axios.get<IPlaqueOrderModify>(`PlaqueOrder/Modify?id=${id}`);
};

const getByProjectId = (projectId: Number) => {
  return axios.get<IPlaqueOrderModify>(`PlaqueOrder?projectId=${projectId}`);
};

const previewOrder = (projectId: Number, coupon: string) => {
  return axios.get<IPlaqueOrderModify>(
    `PlaqueOrder/PreviewOrder?projectId=${projectId}&coupon=${coupon ?? ""}`
  );
};

const getProjectPurchase = (id: Number) => {
  return axios.get<IPlaqueOrder>(
    `PlaqueOrder/ProjectPurchase?projectPurchaseId=${id}`
  );
};

const update = (order: IUpdatePlaqueOrder) => {
  return axios.put("PlaqueOrder", order);
};

const cancelOrder = (projectId: Number) => {
  return axios.put(`PlaqueOrder/CancelOrder?projectId=${projectId}`);
};

const purchase = (projectId: Number, sendToCart: boolean) => {
  return axios.post(
    `PlaqueOrder/Purchase?projectId=${projectId}&cart=${sendToCart}`
  );
};

const getPlaqueCompanyOrders = async (companyId: Number) => {
  return axios.get<IPlaqueCompanyOrders[]>(
    `PlaqueOrder?companyId=${companyId}`
  );
};

const plaqueOrderService = {
  getProjectPurchase,
  getByStatus,
  getById,
  update,
  getByProjectId,
  cancelOrder,
  purchase,
  getPlaqueCompanyOrders,
  previewOrder,
};
export default plaqueOrderService;
