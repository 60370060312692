import axios from "../utils/axios";
import { IAssets, IPostAsset } from "../ts/interfaces/companyAssets";

const getAssets = (companyId: number, active: boolean, type: string) => {
  return axios.get<IAssets[]>(
    `CompanyAssets/ByCompany?companyId=${companyId}&active=${active}&type=${type}`
  );
};

const requestTrasnferTech = async (
  id: number,
  officeShop: boolean,
  companyId: string,
  comments: string
) => {
  return axios.put(
    `CompanyAssets/requestTransferTech?id=${id}&officeShop=${officeShop}&companyId=${companyId}&comments=${comments}&newRoute=true`
  );
};

const transferToAnotherTechnician = async (
  id: number,
  officeShop: boolean,
  companyId: string,
  userId: string,
  comments: string,
  data: IAssets
) => {
  return axios.put(
    `CompanyAssets/requestTransfer?id=${id}&officeShop=${officeShop}&userId=${userId}&companyId=${companyId}&comments=${comments}&newRoute=true`,
    data
  );
};

const getAssetsEditUpdate = async (asseId: string) => {
  return axios.get<IAssets>(`CompanyAssets/${asseId}`);
};

const putAssetsUpdateEdit = async (assetId: string, data: IAssets) => {
  return axios.put(`CompanyAssets/${assetId}`, data);
};

const postAssetsEditUpdate = async (data: IPostAsset) => {
  return axios.post(`CompanyAssets/CompanyAssetAdd`, data);
};

const deleteAssets = async (assetId: number) => {
  return axios.delete(`CompanyAssets?id=${assetId}`);
};

const putSenToOffice = async (id: number, data: IAssets) => {
  return axios.put(`CompanyAssets/SendToOfficeShop?id=${id}`, data);
};

const putTransferSatellite = async (
  id: number,
  officeShop: boolean,
  companyId: string,
  comments: string,
  data: IAssets
) => {
  return axios.put(
    `CompanyAssets/requestTransfer?id=${id}&officeShop=${officeShop}&companyId=${companyId}&comments=${comments}&newRoute=true
    `,
    data
  );
};

const getPendingAsset = async (userId: number, type: string) => {
  return axios.get<IAssets[]>(
    `CompanyAssets/GetMyPending?userId=${userId}&type=${type}`
  );
};

const companyAssetsService = {
  getAssets,
  requestTrasnferTech,
  transferToAnotherTechnician,
  getAssetsEditUpdate,
  putAssetsUpdateEdit,
  postAssetsEditUpdate,
  deleteAssets,
  putSenToOffice,
  putTransferSatellite,
  getPendingAsset,
};

export default companyAssetsService;
