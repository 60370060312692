import { Grid, InputAdornment, Typography } from "@mui/material";
import { FormText } from "src/components/formControls";
import { AddIcon, RemoveIcon } from "src/components/icons";
import FormReadOnlyField from "src/components/formControls/FormReadOnlyField";

interface Props {
  label: string;
  topName: string;
  bottonName: string;
  onChange: ((e: any) => void) | undefined;
  error?: any;
  topValue?: number | null;
  bottomValue?: number | null;
  disable?: boolean;
  showskeleton?: boolean;
}

const ToleranceInput = ({
  label,
  topName,
  bottonName,
  topValue,
  bottomValue,
  disable,
  onChange,
  error,
  showskeleton,
}: Props) => {
  return (
    <Grid container spacing={3} /*sx={{ paddingBottom: "5px" }}*/>
      <Grid
        container
        xs={8}
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
      >
        <Typography gutterBottom variant="body1" align="right" component="div">
          {label}
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <Grid container direction="column" spacing={1}>
          <Grid item>
            {disable ? (
              <>
                <Grid container>
                  <Grid item xs={1}>
                    <Typography pt={1.7}>+</Typography>
                  </Grid>
                  <Grid item xs={4} textAlign={"right"}>
                    <FormReadOnlyField
                      label=""
                      value={`${topValue?.toString()}`}
                      showSkeleton={showskeleton}
                    />
                  </Grid>
                </Grid>
              </>
            ) : (
              <FormText
                name={topName}
                type="number"
                label=""
                value={topValue?.toString() ?? ""}
                onChange={onChange}
                disable={disable ?? false}
                readOnlyText={disable ?? false}
                startAdornment={
                  <InputAdornment position="start">
                    <AddIcon />
                  </InputAdornment>
                }
                error={error?.[topName]}
                showSkeleton={showskeleton}
                rightAlign={true}
              />
            )}
          </Grid>
          <Grid item>
            {disable ? (
              <>
                <Grid container>
                  <Grid item xs={1}>
                    <Typography pt={1.5}>-</Typography>
                  </Grid>
                  <Grid item xs={4} textAlign={"right"}>
                    <FormReadOnlyField
                      label=""
                      value={`${topValue?.toString()}`}
                    />
                  </Grid>
                </Grid>
              </>
            ) : (
              <FormText
                name={bottonName}
                type="number"
                label=""
                value={bottomValue?.toString() ?? ""}
                onChange={onChange}
                disable={disable ?? false}
                readOnlyText={disable ?? false}
                startAdornment={
                  <InputAdornment position="start">
                    <RemoveIcon />
                  </InputAdornment>
                }
                error={error?.[bottonName]}
                showSkeleton={showskeleton}
                rightAlign={true}
              />
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ToleranceInput;
