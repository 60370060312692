import axios from "src/utils/axios";
import {
  IElectricCoilBank,
  IElectricCoilItem,
} from "src/ts/interfaces/study/electricCoil";

const getByCoilId = (id: number) => {
  return axios.get<IElectricCoilItem[]>(`ElectricCoilItem/ByCoils/${id}`);
};

const getBanksByItemId = (id: number) => {
  return axios.get<IElectricCoilBank[]>(`ElectricCoilItem/Banks/${id}`);
};

const saveItems = (items: IElectricCoilItem[]) => {
  return axios.put(`ElectricCoilItem/AllItems`, items);
};

const save = (item: IElectricCoilItem) => {
  return axios.put(`ElectricCoilItem/Item`, item);
};

const saveBank = (item: IElectricCoilBank) => {
  return axios.put(`ElectricCoilItem/Bank/${item.id}`, item);
};

const create = (items: IElectricCoilItem[]) => {
  return axios.post(`ElectricCoilItem`, items);
};

const remove = (id: number) => {
  return axios.delete(`ElectricCoilItem/${id}`);
};

const electricCoilItemService = {
  getByCoilId,
  saveItems,
  create,
  remove,
  save,
  getBanksByItemId,
  saveBank,
};

export default electricCoilItemService;
