import React, { useState, useEffect } from "react";
import Popup from "src/components/Popup";
import {
  FormButton,
  FormText,
  FormAcceptButton,
  FormTextArea,
} from "src/components/formControls";
import { Stack, Grid } from "@mui/material";
import { useAsyncMutation, useAsyncQuery, useForm } from "src/hooks";
import { ITemplate } from "src/ts/interfaces/template";
import { Validator } from "src/ts/types";
import templateServices from "src/services/templateServices";

interface Props {
  showTemplatePopUp: boolean;
  setShowTemplatePopUp: (val: boolean) => void;
  templateId: number;
  setRefresh: () => void;
}

const initialValues: ITemplate = {
  id: 0,
  name: "",
  description: "",
  companyId: 0,
  projectId: 0,
  enabled: false,
  projectTypeId: 0,
  additionalNotes: "",
  isDefault: false,
  preferredTemplate: false,
};

export default function AddTemplatePopUp({
  showTemplatePopUp,
  setShowTemplatePopUp,
  templateId,
  setRefresh,
}: Props) {
  const validate = (fieldValues = values) => {
    let temp: Record<string, string> = { ...errors };

    temp.name = new Validator(fieldValues, "name")
      .isRequired("The name is required")
      ?.toString();

    temp.description = new Validator(fieldValues, "description")
      .isRequired("The description is required")
      ?.toString();

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const handleSuccessSave = () => {
    setShowTemplatePopUp(false);
    setRefresh();
  };

  const handleSuccessSaveCreate = (response: any) => {
    handleSuccessSave();
    executeStudies(response?.projectId);
  };

  const [isLoadingPage, setisLoadingPage] = useState(true);
  const { execute: executeStudies } = useAsyncQuery(
    templateServices.createStudies
  );
  const { execute, isSubmitting } = useAsyncMutation(
    templateServices.updateTemplate,
    {
      successfulMessage: "Template was updated",
      onSuccess: handleSuccessSave,
    }
  );

  const { execute: executeCreate, isSubmitting: isLoadingCreate } =
    useAsyncMutation<any>(templateServices.createTemplate, {
      successfulMessage: "Template was created",
      onSuccess: (result) => {
        handleSuccessSaveCreate(result);
      },
    });

  const { values, setValues, errors, setErrors, handleInputChange } = useForm(
    initialValues,
    false,
    validate
  );

  const handleClosePopUp = () => {
    setShowTemplatePopUp(false);
  };

  useEffect(() => {
    const getData = async () => {
      const result = await templateServices.getById(templateId);
      setisLoadingPage(false);
      setValues(result.data);
    };
    if (templateId !== 0) {
      getData();
    } else setisLoadingPage(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [templateId]);

  const handleSave = async () => {
    if (!validate()) return;
    await execute(values);
  };

  const handleAdd = async () => {
    if (!validate()) return;
    await executeCreate(values);
    setValues({ ...initialValues });
  };

  return (
    <>
      <Popup
        title="Add Template"
        openPopup={showTemplatePopUp}
        setOpenPopup={setShowTemplatePopUp}
        onClose={handleClosePopUp}
        size="sm"
      >
        <Stack direction="row">
          <Grid container spacing={5}>
            <Grid item xs={12}>
              <FormText
                name="name"
                label="Name"
                value={values.name}
                onChange={handleInputChange}
                error={errors.name}
                showSkeleton={isLoadingPage}
              />
            </Grid>
            <Grid item xs={12}>
              <FormTextArea
                name={"description"}
                label={"Description"}
                value={values.description}
                placeholder={"Description"}
                onChange={handleInputChange}
                error={errors.description}
              ></FormTextArea>
            </Grid>
          </Grid>
        </Stack>
        <Stack direction="row" spacing={2}>
          <FormButton
            text="Close"
            size="small"
            variant="outlined"
            onClick={() => {
              setShowTemplatePopUp(false);
            }}
          />
          {templateId > 0 && (
            <FormAcceptButton
              onClick={handleSave}
              isSubmitting={isSubmitting}
              text="Update"
            ></FormAcceptButton>
          )}
          {templateId === 0 && (
            <FormAcceptButton
              onClick={handleAdd}
              isSubmitting={isLoadingCreate}
              text="Create"
            ></FormAcceptButton>
          )}
        </Stack>
      </Popup>
    </>
  );
}
