import {
  Box,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemSecondaryAction,
  ListItemText,
  Stack,
  useTheme,
} from "@mui/material";
import { AddIcon, DeleteIcon } from "src/components/icons";
import { IEnergySourceSubtracted } from "src/ts/interfaces/catalogs";

interface Props {
  energySourceSubtractedItems: IEnergySourceSubtracted[];
  energySourceSubtractedSelected: IEnergySourceSubtracted | null;
  setEnergySourceSubtractedSelected: (val: IEnergySourceSubtracted) => void;
  selectedEnergy: (
    energySourceSelected: IEnergySourceSubtracted
  ) => Promise<void>;
  onAddEnergySourceHandler: () => Promise<void>;
  onDeleteSoundTestConfirmation: () => void;
  studyIsCompleted: boolean;
  disabledStudy: boolean;
}

const EnergySourceSubtractedTabs = ({
  energySourceSubtractedItems,
  energySourceSubtractedSelected,
  setEnergySourceSubtractedSelected,
  selectedEnergy,
  onAddEnergySourceHandler: addEnergySourceHandler,
  onDeleteSoundTestConfirmation,
  studyIsCompleted,
  disabledStudy,
}: Props) => {
  const theme = useTheme();

  const addEnergySource = async () => {
    await addEnergySourceHandler();
  };

  const selectedItemHandler = async (item: IEnergySourceSubtracted) => {
    await selectedEnergy(item);
  };

  const deleteEnergySource = () => {
    onDeleteSoundTestConfirmation();
  };

  return (
    <>
      <Stack direction="row" justifyContent="space-between">
        <Box display="flex" justifyContent="center" alignItems="center">
          <List component={Stack} direction="row" flexWrap={"wrap"}>
            {energySourceSubtractedItems.map((item, index) => {
              return (
                <ListItem
                  key={item.id}
                  disablePadding
                  sx={{
                    borderColor: theme.palette.primary.main,
                    borderWidth: "1px",
                    borderStyle: "solid",
                  }}
                >
                  <ListItemButton
                    selected={energySourceSubtractedSelected?.id === item.id}
                    onClick={async () => await selectedItemHandler(item)}
                  >
                    <ListItemText primary={`${index + 1}      `} />
                  </ListItemButton>

                  <ListItemSecondaryAction></ListItemSecondaryAction>
                </ListItem>
              );
            })}
          </List>
          {!studyIsCompleted && !disabledStudy && (
            <Box display="flex" justifyContent="center" alignItems="center">
              <IconButton onClick={addEnergySource}>
                <AddIcon />
              </IconButton>
            </Box>
          )}
        </Box>
        {!studyIsCompleted &&
          !disabledStudy &&
          energySourceSubtractedItems.length > 1 && (
            <Box display="flex" justifyContent="center" alignItems="center">
              <IconButton edge="end" onClick={() => deleteEnergySource()}>
                <DeleteIcon />
              </IconButton>
            </Box>
          )}
      </Stack>
    </>
  );
};

export default EnergySourceSubtractedTabs;
