import React, { ChangeEvent, useEffect, useState } from "react";
import styled from "@emotion/styled";
import { useParams } from "react-router-dom";
import {
  Grid,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
  Card as MuiCard,
  CardContent,
  Alert,
} from "@mui/material";
import { spacing } from "@mui/system";
import DocumentLibraryGrid from "./components/DocumentLibraryGrid";
import Actions from "./components/Actions";
import AddDirectoryPopup from "./components/AddDirectoryPopup";
import documentLibraryService from "src/services/documentLibraryService";
import { useAsyncMutation, useAsyncQuery, useAuth } from "src/hooks";
import { DocumentLibraryDto } from "src/ts/interfaces";
import HeaderPage from "src/components/page/HeaderPage";
import { ProjectDTO } from "src/ts/interfaces/project/projectDto";
import getProjectSingleService from "src/services/project/getProjectSingleService";
import { ROLES, projectStatus } from "src/constants";

const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Card = styled(MuiCard)(spacing);

export type DocumentLibraryParams = {
  projectId: string;
  companyId: string;
  returnPage: string;
};

const DocumentLibrary = () => {
  const { user } = useAuth();
  const { projectId } = useParams<DocumentLibraryParams>();
  const { companyId } = useParams<DocumentLibraryParams>();
  const projectIdNumber = Number(projectId);

  const [parent, setParent] = useState<number | null | undefined>(null);
  const [refreshGrid, setRefreshGrid] = useState(false);
  const [currentItem, setCurrentItem] = useState<DocumentLibraryDto | null>(
    null
  );
  const { execute } = useAsyncMutation(documentLibraryService.createFolder);

  const [showAddDirectoryPopup, setShowAddDirectoryPopup] = useState(false);

  const {
    execute: getPath,
    data: path,
    resetData,
  } = useAsyncQuery<DocumentLibraryDto[]>(documentLibraryService.getPath);

  const { execute: getProject, data: project } = useAsyncQuery<ProjectDTO>(
    getProjectSingleService.getById
  );

  useEffect(() => {
    const getData = async (parent: number) => {
      const req = await documentLibraryService.getById(parent);
      setCurrentItem(req.data);

      getPath(parent);
    };

    getProject(projectId);

    if (parent !== null && parent !== undefined) {
      getData(parent);
    } else {
      setCurrentItem(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parent]);

  const saveFolderHandler = async (folderName: string) => {
    const documentLibraryItem: {
      name: string;
      parentId: number | null | undefined;
      projectId: number;
    } = {
      name: folderName,
      parentId: parent,
      projectId: projectIdNumber,
    };
    await execute(documentLibraryItem);
    setRefreshGrid(!refreshGrid);
  };

  const gotoUpLevelHandler = () => {
    if (currentItem?.parentId === null || currentItem?.parentId === undefined)
      resetData();
    setParent(currentItem?.parentId);
  };

  const { execute: uploadFile, isSubmitting: isUploadingFile } =
    useAsyncMutation(documentLibraryService.uploadFile);

  const handleFileUpload = async (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      return;
    }
    const file = e.target.files[0];

    await uploadFile({
      type: "",
      content: file,
      projectId: projectIdNumber.toString(),
      parentId: parent?.toString() ?? "",
    });
    setRefreshGrid(!refreshGrid);
  };

  const isProjectCompleted =
    project?.projectStatus?.name === projectStatus.Complete.name ||
    project?.projectStatus?.name === projectStatus.Paid.name ||
    project?.projectStatus?.name === projectStatus.Approved.name ||
    project?.projectStatus?.name === projectStatus.InReview.name ||
    project?.projectStatus?.name === projectStatus.Rejected.name;

  const viewMode =
    (path &&
      path[0].type === "Folder" &&
      path[0].name === "Legal" &&
      project?.companyOwnerId !== null &&
      project?.companyOwnerId !== companyId) ||
    (project?.projectStatusId === 6 &&
      user?.role !== ROLES.Architect &&
      user?.role !== ROLES.ArchitectAux)
      ? true
      : false;

  const viewModeArchived =
    project?.projectStatusId === 7 || project?.projectStatusId === 9
      ? true
      : false;

  return (
    <>
      <HeaderPage
        title="Document Library"
        parentText="Dashboard"
        parentLink="/"
        actionSection={
          <Actions
            setShowAddDirectoryPopup={setShowAddDirectoryPopup}
            disableButtons={currentItem === null || isProjectCompleted}
            parent={parent}
            onGotoUp={gotoUpLevelHandler}
            onFileUpload={handleFileUpload}
            isUploadingFile={isUploadingFile}
            isProjectCompleted={isProjectCompleted}
            viewMode={viewMode}
            viewModeArchived={viewModeArchived}
          />
        }
      />
      <Divider my={6} />
      <Card mb={6}>
        <CardContent>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={12}>
                  <Breadcrumbs aria-label="Breadcrumb" mt={2}>
                    <Link
                      component="button"
                      onClick={() => {
                        resetData();
                        setParent(null);
                      }}
                    >
                      Document Library
                    </Link>
                    {path?.map((items) => {
                      return (
                        <Link
                          component="button"
                          onClick={() => {
                            setParent(items.id);
                          }}
                        >
                          {items.name}
                        </Link>
                      );
                    })}
                  </Breadcrumbs>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <DocumentLibraryGrid
                projectId={projectIdNumber}
                setParent={setParent}
                parent={parent}
                refreshGrid={refreshGrid}
                setRefreshGrid={setRefreshGrid}
                disable={isProjectCompleted}
                isProjectCompleted={isProjectCompleted}
                viewMode={viewMode}
                projectStatus={project?.projectStatus?.name ?? ""}
                contestDueDate={project?.contestDueDate ?? null}
              />
              <Alert severity="warning">PDF Upload Only</Alert>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <AddDirectoryPopup
        isDialogOpen={showAddDirectoryPopup}
        setIsDialogOpen={setShowAddDirectoryPopup}
        onSave={saveFolderHandler}
      />
    </>
  );
};

export default DocumentLibrary;
