import { useEffect } from "react";
import { Grid, Stack } from "@mui/material";
import { Form, useAsyncMutation } from "src/hooks";
import {
  FormCancelButton,
  FormSaveButton,
  FormText,
} from "src/components/formControls";
import { Validator } from "src/ts/types";

import Popup from "src/components/Popup";
import projectContestService from "src/services/projectContestService";
import useFormTyped from "src/hooks/useFormTyped";

interface ICode {
  code: string;
}

const initialValues: ICode = {
  code: "",
};

interface ConfirmModalProps {
  isDialogOpen: boolean;
  setIsDialogOpen: (val: boolean) => void;
  onSave: () => void;
}

const AddProjectByCode = (props: ConfirmModalProps) => {
  const { isDialogOpen, setIsDialogOpen, onSave } = props;
  const validate: any = (fieldValues = values) => {
    let temp: Record<string, string> = { ...errors };

    temp.code = new Validator(fieldValues, "code")
      .maxLength(50, "Only 50 characters are allowed")
      .toString();

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, errors, setErrors, handleInputChange, resetForm } =
    useFormTyped<ICode>(initialValues, false, validate);

  const { execute, isSubmitting } = useAsyncMutation(
    projectContestService.setProjectByCode,
    {
      successfulMessage: "Project was created successfully",
      onSuccess: () => {
        setIsDialogOpen(false);
        onSave();
      },
    }
  );

  useEffect(() => {
    resetForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDialogOpen]);

  const saveHandler = async () => {
    if (!validate()) return;

    await execute(values.code);
  };

  const cancelHandler = () => {
    setIsDialogOpen(false);
  };

  const row = 12;
  return (
    <Popup
      openPopup={isDialogOpen}
      setOpenPopup={setIsDialogOpen}
      title={`Add Project By Code`}
      onClose={cancelHandler}
      disableClickOutside={true}
      isSubmitting={isSubmitting}
    >
      <Form>
        <Grid container spacing={5}>
          <Grid item xs={row}>
            <FormText
              name="code"
              label="Code"
              value={values.code}
              onChange={handleInputChange}
              error={errors.code}
              showSkeleton={isSubmitting}
              maxLength={50}
            />
          </Grid>
        </Grid>
      </Form>
      <>
        <Stack
          direction="row"
          spacing={2}
          justifyContent="center"
          alignItems="center"
        >
          <FormCancelButton
            onClick={cancelHandler}
            isSubmitting={isSubmitting}
          />
          <FormSaveButton onClick={saveHandler} isSubmitting={isSubmitting} />
        </Stack>
      </>
    </Popup>
  );
};

export default AddProjectByCode;
