import { Grid, Stack } from "@mui/material";
import { useState } from "react";
import {
  FormAcceptButton,
  FormCancelButton,
  FormDatePicker,
  FormTextArea,
} from "src/components/formControls";
import Popup from "src/components/Popup";
import { orderStatus } from "src/constants";
import { useForm, Form, useLog } from "src/hooks";
import plaqueOrderService from "src/services/plaqueOrderService";
import { OrderStatusEnum } from "src/ts/enums";
import {
  ICompanyData,
  IPlaqueOrderModify,
  IUpdatePlaqueOrder,
} from "src/ts/interfaces";

interface IChangeStatusPopupProps {
  showPopup: boolean;
  setShowPopup: (val: boolean) => void;
  currentValues?: ICompanyData;
  onSave: () => void;
  order: IPlaqueOrderModify | null;
}

const initialValues: IUpdatePlaqueOrder = {
  id: 0,
  orderStatus: OrderStatusEnum.Other,
  info: "",
  shippedDateInput: null,
};

const ChangeStatusOrderPopup = ({
  showPopup: disablePopup,
  setShowPopup: setDisablePopup,
  currentValues,
  onSave,
  order,
}: IChangeStatusPopupProps) => {
  const validate = (fieldValues = values) => {};
  const { log } = useLog();
  const { values, errors, handleInputChange, resetForm } = useForm(
    initialValues,
    true,
    validate
  );

  const [isSubmitting, setIsSubmitting] = useState(false);
  const handleSave = async () => {
    try {
      setIsSubmitting(true);

      await plaqueOrderService.update({
        id: order?.id ?? 0,
        info: values.info,
        orderStatus: (order?.orderStatus ?? 0) + 1,
        shippedDateInput: values.shippedDateInput,
      });

      log.success(`The order was updated`);
    } catch (error: any) {
      log.error(error?.message?.exceptionMessage ?? "Something went wrong");
    } finally {
      setIsSubmitting(false);
      setDisablePopup(false);
      resetForm();
      onSave();
    }
  };

  const handleClose = () => {
    resetForm();
    setDisablePopup(false);
  };

  return (
    <Popup
      title="Order Information"
      openPopup={disablePopup}
      setOpenPopup={setDisablePopup}
      onClose={handleClose}
    >
      <>
        <Form>
          <Grid container spacing={5}>
            {order?.orderStatus === OrderStatusEnum.Preparing && (
              <Grid item xs={12}>
                <FormDatePicker
                  label="Shipped Date"
                  name="shippedDateInput"
                  disablePast={true}
                  value={values.shippedDateInput}
                  onChange={handleInputChange}
                  error={errors.shippedDateInput}
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <FormTextArea
                name="info"
                label="Additional Information:"
                value={values.info}
                onChange={handleInputChange}
                error={errors.info}
                placeholder={""}
              />
            </Grid>
          </Grid>
        </Form>
      </>
      <Stack
        direction="row"
        spacing={2}
        justifyContent="flex-end"
        alignItems="flex-start"
      >
        <FormAcceptButton
          onClick={handleSave}
          isSubmitting={isSubmitting}
          disabled={
            order?.orderStatus === OrderStatusEnum.Preparing
              ? !values.info ||
                values.info.length === 0 ||
                values.shippedDateInput === "" ||
                values.shippedDateInput === null
              : !values.info || values.info.length === 0
          }
          text={`Change Status to ${
            orderStatus[order?.orderStatus ?? 0]?.name ?? ""
          }`}
        />

        <FormCancelButton onClick={handleClose} isSubmitting={isSubmitting} />
      </Stack>
    </Popup>
  );
};

export default ChangeStatusOrderPopup;
