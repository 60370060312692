import { IGetProjectInfectious } from "src/ts/interfaces/project/projectinfectiousCompliance";

import axios from "src/utils/axios";

const getProjectsInfecteous = (projectId: number) => {
  return axios.get<IGetProjectInfectious[]>(
    `projectInfectiousCompliance/byArchitect`
  );
};

const putChangeStatusInfect = async (
  id: number,
  statusId: number,
  comments: string
) => {
  await axios.put(
    `projectInfectiousCompliance/${id}/ChangeStatusInfect/${statusId}/comments/${comments}`
  );
};

const projectInfectiousComplianceService = {
  getProjectsInfecteous,
  putChangeStatusInfect,
};

export default projectInfectiousComplianceService;
