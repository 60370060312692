import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { Stack, Chip as MuiChip } from "@mui/material";
import { ColumnType } from "src/types/enhancedTable";
import { GridActionButton } from "src/components/gridControls";
import { useTable } from "src/hooks/useTable";
import { WalletService } from "src/services";
import { ElavonPaymentFilter } from "../types";
import moment from "moment";
import CreditCardIconSelector from "src/components/icons/CreditCardIcon";
import { spacing, SpacingProps } from "@mui/system";
import ColorText from "src/components/text/ColorText";
import { green, red } from "@mui/material/colors";
import { currencyformatter } from "src/utils/format";
import DialogMessagePopup from "src/components/DialogMessagePopup";
import { IWalletHistory } from "src/ts/interfaces";
import PasswordConfirmModal from "src/components/modals/PasswordConfirmModal";
import { useLog, usePermissions } from "src/hooks";
import { Permission as PermissionTypes } from "src/ts/enums";

interface ChipProps extends SpacingProps {
  component?: React.ElementType;
  href?: string;
  icon?: JSX.Element | null;
}

const Chip = styled(MuiChip)<ChipProps>(spacing);

const getColumns = (
  returnFundHandler: (selectedRow: IWalletHistory) => void,
  fullAccess: boolean
): ColumnType[] => [
  { id: "companyName", label: "Company", type: "string", sort: true },
  { id: "cardNumber", label: "Card Number", type: "string", sort: true },
  {
    id: "cardType",
    label: "Type",
    type: "custom",
    sort: true,
    callback: (row: any) => (
      <CreditCardIconSelector typeCreditCard={row.cardType} />
    ),
  },
  {
    id: "transactionStatus",
    label: "Transaction status",
    type: "custom",
    sort: true,
    callback: (row: any) => {
      return (
        <>
          {row.transactionStatus === "APPROVAL" ? (
            <Chip
              label={row.transactionStatus}
              color="success"
              m={1}
              size="small"
            />
          ) : (
            <Chip
              label={row.transactionStatus}
              color="error"
              variant="filled"
              m={1}
              size="small"
            />
          )}
        </>
      );
    },
  },
  {
    id: "createdDate",
    label: "DateTime",
    type: "utcDate",
    format: "MM/DD/yyyy h:mm A",
    sort: true,
  },
  {
    id: "movementDescription",
    label: "Movement Description",
    type: "string",
    sort: true,
  },
  {
    id: "amount",
    label: "Amount",
    type: "custom",
    sort: true,
    callback: (row: IWalletHistory) => (
      <>
        <div style={{ textAlign: "right" }}>
          <ColorText
            text={currencyformatter.format(row.amount)}
            percentagecolor={row.amount > 0 ? green[500] : red[500]}
          />
        </div>
      </>
    ),
  },
  {
    id: "action",
    label: "Actions",
    type: "custom",
    sort: false,
    hide: !fullAccess,
    callback: (row: IWalletHistory) => (
      <Stack direction="row">
        <GridActionButton
          type="backward"
          tooltip="Return Transaction"
          onClick={() => returnFundHandler(row)}
        />
      </Stack>
    ),
  },
];

interface Props {
  filter: ElavonPaymentFilter | null;
  refresh: boolean;
}

const ElavonPaymentLogGrid = (props: Props) => {
  const { filter, refresh } = props;
  const { log } = useLog();
  const [showReturnModal, setShowReturnModal] = useState(false);
  const [currentRow, setCurrentRow] = useState<IWalletHistory | null>(null);
  const [isPasswordDialogOpen, setIsPasswordDialogOpen] = useState(false);
  const { fullAccess } = usePermissions(PermissionTypes.Wallet_Admin_Center);
  const returnFundHandler = (selectedRow: IWalletHistory) => {
    setCurrentRow(selectedRow);
    setShowReturnModal(true);
  };

  const { Table, setRowsPerPage, page, setDataSet, setCount, orderBy, order } =
    useTable(getColumns(returnFundHandler, fullAccess || false), {
      rowsPerPageOptions: [5],
    });

  useEffect(() => {
    const getData = async () => {
      try {
        const request = await WalletService.getElavonPaymentLog({
          page: page + 1,
          sortColumn: orderBy,
          sortDec: order === "desc" ? true : false,
          filter: filter?.search ?? "",
          dateFrom:
            filter?.startDate ?? moment().startOf("month").format("YYYY-MM-DD"),
          dateTo: filter?.endDate ?? moment().format("YYYY-MM-DD"),
          amount: filter?.amountSearch ?? null,
        });

        setCount(request.data.count);
        setDataSet(request.data.data);
        setRowsPerPage(5);
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      }
    };

    getData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, orderBy, order, refresh]);

  const saveOnConfirmPasswordHandler = async () => {
    if (currentRow === null) {
      log.error("Select a row");
      return;
    }
    try {
      await WalletService.returnTxn(currentRow.id);
      log.success("Transaction returned successfuly");
    } catch (error: any) {
      log.error(error?.message?.exceptionMessage ?? "Something went wrong");
    }
  };

  return (
    <>
      <Table />
      <DialogMessagePopup
        title={"Return Transaction Confirmation"}
        text={`This action will request a payment return to Elavon Services.\n
The funds will be returned directly to the credit card where the payment came from.
The funds also will be discounted from the Company's Wallet Balance related to the payment.
Are you certain you want to proceed to return the transaction?`}
        showPopup={showReturnModal}
        setShowPopup={setShowReturnModal}
        onSave={() => {
          setShowReturnModal(false);
          setIsPasswordDialogOpen(true);
        }}
        isSubmitting={false}
      />
      <PasswordConfirmModal
        isDialogOpen={isPasswordDialogOpen}
        setIsDialogOpen={setIsPasswordDialogOpen}
        setIsValidPassword={saveOnConfirmPasswordHandler}
      />
    </>
  );
};

export default ElavonPaymentLogGrid;
