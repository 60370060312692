import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import {
  Button,
  CardContent,
  Grid,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";
import { technicianQuizExamService } from "src/services";
import { ITechnicianQuizExam } from "src/ts/interfaces";
import moment from "moment";
import ExamStart from "./ExamStart";
import ViewExamPopup from "./component/ViewExamPopup";
import { FormDatePicker } from "src/components/formControls";
import { statusExam, viewStatusExam } from "src/constants";
import FormCountdown from "src/components/formControls/FormCountdown";
import { useLog } from "src/hooks";

const Card = styled(MuiCard)(spacing);
const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

function Exam() {
  const { log } = useLog();
  const [exam, setExam] = useState<ITechnicianQuizExam>();
  const [status, setStatus] = useState(-1);
  const [refreshPage, setRefreshPage] = useState(false);
  const [endDate, setEndDate] = useState(new Date());
  const [openView, setOpenView] = useState(false);
  const [rescheduleDate, setRescheduleDate] = useState(
    moment().format("MM/DD/yyyy")
  );
  useEffect(() => {
    const getData = async () => {
      try {
        const request = await technicianQuizExamService.GetQuizExamByUser();
        setExam(request.data);
        if (request.data === null) {
          setStatus(7);
          return;
        }
        if (request.data.statusExam === statusExam.FAIL) {
          setStatus(3);
          return;
        }

        if (request.data.statusExam === statusExam.PASS) {
          setStatus(2);
          return;
        }

        if (request.data.statusExam === statusExam.STARTED) {
          setStatus(4);
        }
        if (request.data.statusExam === null) {
          let start = new Date(request.data.startDate ?? new Date());
          setEndDate(new Date(request.data.startDate ?? new Date()));
          if (request.data.isReschedule) {
            endDate.setDate(endDate.getDate() + 1);
            setEndDate(endDate);
          } else {
            endDate.setDate(endDate.getDate() + 7);
            setEndDate(endDate);
          }
          var current = new Date();
          if (start <= current) {
            if (endDate <= current) {
              setStatus(6);
              return;
            } else {
              setStatus(1);
              return;
            }
          } else {
            setStatus(0);
            return;
          }
        }
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      }
    };
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshPage]);
  const handleViewQuiz = () => {
    setOpenView(true);
  };
  const handleReschedule = async () => {
    await technicianQuizExamService.reschedule(rescheduleDate);
    setRefreshPage(!refreshPage);
  };
  const handleStartTest = async () => {
    setStatus(4);
  };

  return (
    <React.Fragment>
      <Grid container>
        <Grid item xs={9}>
          <Helmet title="Blank" />
          <Typography variant="h3" gutterBottom display="inline">
            Exam
          </Typography>
          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} to="/">
              Dashboard
            </Link>
            <Typography>Exam</Typography>
          </Breadcrumbs>
        </Grid>
      </Grid>

      <Divider my={6} />
      <Card mb={12}>
        <CardContent>
          {status === viewStatusExam.NOTEST && (
            <>
              <Typography variant="h3" align="center">
                No test(s) are currently available
              </Typography>
            </>
          )}
          {status === viewStatusExam.PENDING && (
            <>
              <Typography variant="h3" align="center">
                Your test will be available to you in
              </Typography>
              <Grid spacing={8}>
                <FormCountdown
                  date={exam?.startDate ?? new Date()}
                ></FormCountdown>
              </Grid>
            </>
          )}
          {status === viewStatusExam.START && (
            <>
              <Typography variant="h3" align="center">
                Your test is available
              </Typography>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                onClick={handleStartTest}
              >
                Start Test
              </Button>
              <Typography variant="subtitle1">
                It's important to ensure that you complete your test within the
                allotted time frame. If you do not finish your test during the
                current session, you will be required to schedule another test
                date. Additionally, the test will be recorded as "failed" if it
                is not completed within the specified time limit. <br />
                <br />
                Therefore, it is important to manage your time wisely during the
                test to ensure that you are able to complete all the required
                questions before the time runs out. If you are unable to
                complete your test within the allocated time frame, you risk
                having to reschedule the test and potentially delaying your
                progress.
                <br />
                <br />
                To avoid any negative consequences, it's best to plan your time
                accordingly and ensure that you are fully prepared before
                starting the test. This will help you to stay calm and focused
                throughout the test, giving you the best chance of completing it
                successfully within the specified time limit.
              </Typography>
              <Typography variant="h5" align="center">
                Your test will expire on {moment(endDate).format("MM-DD-YYYY")}
              </Typography>
            </>
          )}
          {status === viewStatusExam.PASSED && (
            <>
              <Typography variant="h3" align="center">
                Congratulations! You passed your test
              </Typography>
              <Typography variant="h6" gutterBottom>
                Date: {moment.utc(exam?.endDate).local().format("MM-DD-YYYY")}{" "}
                <br />
                Time at completion:{" "}
                {moment.utc(exam?.endDate).local().format("h:mma")} <br />
                Total questions: {exam?.technicianQuiz?.totalQuestions} <br />
                Score: {exam?.score} <br />
              </Typography>
            </>
          )}
          {status === viewStatusExam.RETEST && (
            <>
              <Typography variant="h3" align="center">
                We are sorry to inform you that your test results will not
                currently allow you to become certified for the moment.
              </Typography>
              <Typography variant="h4" align="center" style={{ padding: 10 }}>
                Please reschedule to take this test again.
              </Typography>
              <Typography variant="h4" align="center" style={{ padding: 10 }}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  onClick={handleViewQuiz}
                >
                  View Quiz
                </Button>
              </Typography>
              <Typography variant="h4" align="center" style={{ padding: 10 }}>
                Select a new test date
              </Typography>
              <Grid container alignItems="center" style={{ padding: 10 }}>
                <Grid item xs={5}></Grid>
                <Grid item xs={2} alignItems="center">
                  <FormDatePicker
                    label="Reschedule"
                    name="reschedule"
                    disablePast={true}
                    value={rescheduleDate}
                    onChange={(e) => {
                      setRescheduleDate(e.target.value);
                    }}
                  />
                </Grid>
              </Grid>
              <Typography variant="h4" align="center" style={{ padding: 10 }}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  onClick={handleReschedule}
                >
                  Reschedule
                </Button>
              </Typography>
            </>
          )}
          {status === viewStatusExam.EXAM && (
            <ExamStart
              title={exam?.technicianQuiz.title ?? ""}
              description={exam?.technicianQuiz.description ?? ""}
              examId={exam?.id ?? 0}
              onFinish={(value) => {
                setStatus(value);
                setRefreshPage(!refreshPage);
              }}
            ></ExamStart>
          )}
        </CardContent>
      </Card>
      <ViewExamPopup
        showPopup={openView}
        setShowPopup={setOpenView}
        quizExamId={exam?.id ?? 0}
      />
    </React.Fragment>
  );
}

export default Exam;
