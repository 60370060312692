import {
  useAsyncMutation,
  useAsyncQuery,
  useLoading,
  useLog,
  useTheme,
} from "src/hooks";
import SpreadSheetRow from "src/components/spreadsheet/SpreadSheetRow";
import SpreadSheetCell from "src/components/spreadsheet/SpreadSheetCell";
import { useEffect, useMemo, useState } from "react";
import { Grid, Skeleton, Table, TableBody, Typography } from "@mui/material";
import SpreadsheetNumericInput2 from "src/components/spreadsheet/SpreadsheetNumericInput2";
import {
  IPitotMatrixItems,
  IPitotMatrixTitle,
  IPitotTraverseTest,
} from "src/ts/interfaces/study/pitot";
import pitotMatrixTitleService from "src/services/study/pitotMatrixTitleService";
import pitotMatrixItemsService from "src/services/study/pitotMatrixItemsService";
import ChangeReadingsPopUp from "../../components/ChangeReadingsPopUp";
import pitotTraverseTestService from "src/services/study/pitotTraverseTestService";

interface IOvalaAction {
  ovalAction: boolean;
  yesOvalAction: boolean;
  noOvalAction: boolean;
}

interface Props {
  id: number;
  saveItems: boolean;
  disabled: boolean;
  showSkeleton: boolean;
  onBlur: (total: number, totalItems: number) => void;
  systemOfMeasurement: {
    get: (key: string) => any;
    setSystem: (system: string) => void;
  };
  values: IPitotTraverseTest;
  setValues: (val: IPitotTraverseTest) => void;
  dataBase: number;
  dataHeight: number;
  setDataBase: (val: number) => void;
  dataInsulation: number;
  setDataInsulation: (val: number) => void;
  setDataHeight: (val: number) => void;
  stepContinuos: string;
  ductSizeBase: number;
  ductSizeHeight: number;
  setShowSteady: (total: boolean) => void;
  showSteady: boolean;
  internalInsulation: number;
  setPitotTraverseGrid: (val: boolean) => void;
  setChangeMatrixButton: (val: boolean) => void;
  setGridLoading: (val: boolean) => void;
  pitotTraverseGrid: boolean;
  executeRefresh: (id: number, values: IPitotTraverseTest) => void;
  ovalAction: IOvalaAction;
  setOvalAction: (val: IOvalaAction) => void;
}

const PitotFanTraverseGrid = ({
  id,
  saveItems,
  disabled,
  showSkeleton,
  onBlur,
  systemOfMeasurement,
  values,
  setValues,
  dataBase,
  setDataBase,
  dataHeight,
  setDataHeight,
  stepContinuos,
  ductSizeBase,
  ductSizeHeight,
  setShowSteady,
  showSteady,
  dataInsulation,
  setDataInsulation,
  internalInsulation,
  setPitotTraverseGrid,
  pitotTraverseGrid,
  setChangeMatrixButton,
  setGridLoading,
  executeRefresh,
  ovalAction,
  setOvalAction,
}: Props) => {
  const [items, setItems] = useState<IPitotMatrixItems[][]>();
  const [item, setItem] = useState<IPitotMatrixItems>();
  const [showChangeXYModal, setShowChangeXYModal] = useState(false);
  const { theme } = useTheme();
  const { startRequest, endRequest, isLoading } = useLoading();
  const { log } = useLog();
  const { execute, data } = useAsyncQuery<IPitotMatrixItems[]>(
    pitotMatrixItemsService.getByPitotId
  );

  const { execute: executeX, data: dataX } = useAsyncQuery<IPitotMatrixTitle[]>(
    pitotMatrixTitleService.getByPitotId
  );

  const { execute: executeY, data: dataY } = useAsyncQuery<IPitotMatrixTitle[]>(
    pitotMatrixTitleService.getByPitotId
  );

  const { execute: executeSave } = useAsyncQuery(
    pitotMatrixItemsService.saveItems
  );

  const { execute: executeUpdate } = useAsyncMutation<number>(
    pitotTraverseTestService.update
  );

  useEffect(() => {
    const runProcess = () => {
      const matrix = Array(Math.ceil(data.length / (dataX?.length ?? 0)))
        .fill("")
        .reduce((acc, cur, index) => {
          return [
            ...acc,
            [...data].splice(index * dataX?.length, dataX?.length),
          ];
        }, []);
      setItems(matrix);
    };

    if (
      data &&
      data.length > 0 &&
      dataX &&
      dataX.length > 0 &&
      dataY &&
      dataX.length > 0
    ) {
      runProcess();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (values?.ductTypeId !== 0) setItems([]);
  }, [values?.ductTypeId]);

  useEffect(() => {
    const matrixChange = async () => {
      setChangeMatrixButton(true);
    };

    const matrixNotChange = async () => {
      setChangeMatrixButton(false);
    };

    const getMatrix = async () => {
      await executeY(id, 2);
      await executeX(id, 1);
      await execute(id);

      setDataBase(values?.ductSizeBase ?? 0);
      setDataHeight(values?.ductSizeHeight ?? 0);
      setDataInsulation(values?.internalInsulation ?? 0);
    };

    if (
      items &&
      items.length > 0 &&
      values &&
      stepContinuos === "step2" &&
      ((ductSizeBase > 0 && values?.ductSizeBase !== dataBase) ||
        (ductSizeHeight > 0 && values?.ductSizeHeight !== dataHeight) ||
        (internalInsulation > 0 &&
          values?.internalInsulation !== dataInsulation))
    ) {
      matrixChange();
    } else if (
      items &&
      items.length > 0 &&
      values &&
      stepContinuos === "step2" &&
      ((ductSizeBase > 0 && values?.ductSizeBase === dataBase) ||
        (ductSizeHeight > 0 && values?.ductSizeHeight === dataHeight) ||
        (internalInsulation > 0 &&
          values?.internalInsulation === dataInsulation))
    ) {
      matrixNotChange();
    } else if (
      ((data && data.length === 0) || data === null) &&
      ductSizeBase > 0 &&
      ductSizeHeight > 0 &&
      internalInsulation > 0 &&
      stepContinuos === "step2"
    ) {
      getMatrix();
    } else if (stepContinuos === "step2") {
      getMatrix();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    values?.ductSizeBase,
    values?.ductSizeHeight,
    values?.internalInsulation,
    values?.conditionId,
  ]);

  useEffect(() => {
    let total = 0;
    total = sumItems();
    if (item) {
      onBlur(total, data?.length);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item, data]);

  useEffect(() => {
    const getData = async () => {
      await executeSave(data);
    };
    if (data && data.length > 0) getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveItems]);

  useEffect(() => {
    if (pitotTraverseGrid) setShowChangeXYModal(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pitotTraverseGrid]);

  const setItemsCalc = async (element: IPitotMatrixItems, e: any) => {
    element.item = Number(e.target.value);
    await setItem({ ...element });
  };

  const sumItems = () => {
    const sum = data
      ? data.reduce((sum, current) => sum + (current?.item ?? 0), 0)
      : 0;
    return sum;
  };

  const width = useMemo(() => `${89 / dataX?.length}%`, [dataX?.length]);

  const changeMatrixXY = async () => {
    startRequest("changeMatrix");
    setGridLoading(true);
    const newValues = {
      ...values,
      totalVelocity: 0,
      avgVelocity: 0,
      trueCorectedAirVelocity: 0,
      totalCFM: 0,
      required: 0,
      akFactor: 0,
      percentajeOfDesign: 0,
      dryBulbF: 0,
      relativeHumidity: 0,
      wetBulbF: 0,
      dewPoint: 0,
      btuh: 0,
      sensibleHeat: 0,
    };

    try {
      await executeUpdate(id, newValues);
      await executeY(id, 2);
      await executeX(id, 1);
      await execute(id);
      setDataBase(values?.ductSizeBase ?? 0);
      setDataHeight(values?.ductSizeHeight ?? 0);
      setDataInsulation(values?.internalInsulation ?? 0);
      setOvalAction({
        noOvalAction: ovalAction.ovalAction,
        yesOvalAction: true,
        ovalAction: false,
      });
    } catch (error: any) {
      log.error(error?.message?.exceptionMessage ?? "Something went wrong");
    }
    if (!ovalAction.ovalAction) setShowSteady(true);
    setChangeMatrixButton(false);
    setPitotTraverseGrid(false);
    setShowChangeXYModal(false);
    endRequest("changeMatrix");
    setGridLoading(false);
  };

  const closeButton = async () => {
    const newValues = {
      ...values,
      ductSizeBase: dataBase,
      ductSizeHeight: dataHeight,
      internalInsulation: dataInsulation,
    };

    await executeRefresh(id, newValues);
    setShowChangeXYModal(false);
    setChangeMatrixButton(false);
    setPitotTraverseGrid(false);
    setOvalAction({
      noOvalAction: ovalAction.ovalAction,
      yesOvalAction: false,
      ovalAction: true,
    });
  };

  return (
    <>
      <Table>
        <TableBody>
          <SpreadSheetRow>
            <SpreadSheetCell key={`number`} width={"11%"}>
              <Typography
                sx={{
                  background: `linear-gradient(to right top, ${
                    theme === "DARK" ? "#233044" : "#FFFFFF"
                  } 0%,${theme === "DARK" ? "#233044" : "#FFFFFF"} 48%,${
                    theme === "DARK" ? "#FFFFFF50" : "#0000001f"
                  } 50%,${theme === "DARK" ? "#FFFFFF50" : "#0000001f"} 50%,${
                    theme === "DARK" ? "#233044" : "#FFFFFF"
                  } 52%,${theme === "DARK" ? "#233044" : "#FFFFFF"} 100%)`,
                  padding: "2px",
                }}
              >
                <Grid container>
                  <Grid item xs={6} sx={{ padding: "0px", marginTop: "15px" }}>
                    Point Side
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    sx={{
                      textAlign: "right",
                      padding: "0px",
                      paddingBottom: "15px",
                    }}
                  >
                    Hole Side
                  </Grid>
                </Grid>
              </Typography>
            </SpreadSheetCell>
            {dataX && (
              <>
                {Array.from({ length: dataX?.length }, (_, i) => (
                  <SpreadSheetCell key={`${i + 1}`} width={width} minWidth={95}>
                    <Typography mt={0.8} padding={1.8} fontWeight="bold">
                      {dataX[i].title.toFixed(2)}
                    </Typography>
                  </SpreadSheetCell>
                ))}
              </>
            )}
          </SpreadSheetRow>
          {items &&
            items?.length > 0 &&
            Object.values(items ?? []).map(
              (item: IPitotMatrixItems[], index: number) => (
                <SpreadSheetRow>
                  <SpreadSheetCell key={`${index + 1}`} width={"5%"}>
                    <Typography
                      m={2}
                      mt={0.8}
                      paddingLeft={1.8}
                      fontWeight="bold"
                    >
                      {dataY[index]?.title.toFixed(2)}
                    </Typography>
                  </SpreadSheetCell>
                  <>
                    {item.map((element: IPitotMatrixItems, indexy) => (
                      <SpreadSheetCell key={`${element.id}`} width={width}>
                        {showSkeleton || isLoading ? (
                          <Skeleton height={50} />
                        ) : (
                          <>
                            <SpreadsheetNumericInput2
                              name={element.id.toString()}
                              value={element.item}
                              onBlur={(e: any) => {
                                setItemsCalc(element, e);
                              }}
                              decimalScale={3}
                              maxValue={1000000}
                              disabled={disabled}
                              mode={disabled ? "read" : undefined}
                              placeholder={systemOfMeasurement.get("phfpm")}
                            ></SpreadsheetNumericInput2>
                          </>
                        )}
                      </SpreadSheetCell>
                    ))}
                  </>
                </SpreadSheetRow>
              )
            )}
        </TableBody>
      </Table>
      <ChangeReadingsPopUp
        onConfirm={changeMatrixXY}
        text={`Are you certain you want to change the values of the dimensions?`}
        matchWord={"CHANGE"}
        isDialogOpen={showChangeXYModal}
        setIsDialogOpen={setShowChangeXYModal}
        onCancel={closeButton}
        setShowSteady={setShowSteady}
        showSteady={showSteady}
      />
    </>
  );
};

export default PitotFanTraverseGrid;
