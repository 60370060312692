import React, { useEffect, useState } from "react";
import { Stack, Typography } from "@mui/material";
import Popup from "src/components/Popup";
import {
  FormButton,
  FormSelect,
  FormTextArea,
  FormCancelButton,
  FormCheckBox,
  FormRadioGroup,
} from "src/components/formControls";
import { IAssets, ITypeAsset } from "src/ts/interfaces/companyAssets";
import { useForm, useLog } from "src/hooks";
import companyAssetsService from "src/services/companyAssetsService";
import { IKeyValue } from "src/ts/interfaces";
import { Validator } from "src/ts/types";
import { CompanyService, userService } from "src/services";
import { ASSET_TILE, AssetsConstant, TRANSFERTYPE } from "src/constants";

interface Props {
  showAssignTransfer: boolean;
  setShowAssignTransfer: (val: boolean) => void;
  currentRow: IAssets;
  asset: string;
  companyId: string;
  setRefreshGrid: (val: boolean) => void;
  refreshGrid: boolean;
  information: ITypeAsset;
  permissionRole: boolean;
}

const initialValues = {
  comments: "",
  selectedTransfer: "-1",
  returnOffice: false,
  radioButtonSelect: "0",
  selectedSatellite: "-1",
};

const radioInformation = [
  {
    key: "0",
    value: "Headquarters",
  },
  {
    key: "1",
    value: "Satellite",
  },
];

const AssignTransferPopUp = ({
  showAssignTransfer,
  setShowAssignTransfer,
  currentRow,
  asset,
  companyId,
  setRefreshGrid,
  refreshGrid,
  information,
  permissionRole,
}: Props) => {
  const [dataUser, setDataUser] = useState<IKeyValue<number, string>[]>([]);
  const [dataSatellite, setDataSatellite] = useState<
    IKeyValue<number, string>[]
  >([]);
  const [selectRadioButton, setSelectRadioButton] = useState(
    initialValues.radioButtonSelect
  );
  const { log } = useLog();

  const assetTitle =
    asset === AssetsConstant.VEHICLE
      ? ASSET_TILE.VEHICLE
      : ` Test ${ASSET_TILE.EQUIPMENT}`;

  const validate = (fieldValues = values) => {
    let temp: Record<string, string> = { ...errors };

    temp.comments = new Validator(fieldValues, "comments")
      .isRequired("The comments are required.")
      .maxLength(200, "Only 200 character are allowed")
      .toString();

    temp.selectedTransfer = new Validator(fieldValues, "selectedTransfer")
      .validateIf(fieldValues.officeShop)
      .selectedOption("-1", "Selected a technician.")
      .toString();

    if (fieldValues.radioButtonSelect === "1") {
      temp.selectedSatellite = new Validator(fieldValues, "selectedSatellite")
        .selectedOption("-1", "Selected a satellite.")
        .toString();
    }

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, handleInputChange, setErrors, errors } = useForm(
    initialValues,
    false,
    validate
  );

  useEffect(() => {
    const getInfo = async () => {
      try {
        const responseTransfer = await userService.getKeyUsersTransferTo(
          Number(companyId)
        );
        const responseSatellite = await CompanyService.getKeyValues(
          Number(companyId)
        );
        setDataUser(responseTransfer);
        setDataSatellite(responseSatellite);
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      }
    };
    getInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setValues({ ...values, selectedTransfer: "-1" });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.returnOffice]);

  const handleSave = async () => {
    if (!validate()) return;
    try {
      const id = currentRow?.id ?? 0;

      if (currentRow.assignedToId === values.selectedTransfer) {
        log.warning(
          `The ${assetTitle} is already assigned to the Auditor/Technician selected.`
        );
      } else {
        if (!values.returnOffice) {
          await companyAssetsService.transferToAnotherTechnician(
            id,
            permissionRole ? values.returnOffice : true,
            companyId,
            values.selectedTransfer,
            values.comments,
            currentRow
          );
        } else if (values.radioButtonSelect === TRANSFERTYPE.SATELLITE) {
          await companyAssetsService.putTransferSatellite(
            id,
            values.returnOffice,
            values.selectedSatellite,
            values.comments,
            currentRow
          );
        } else {
          await companyAssetsService.putSenToOffice(id, {
            ...currentRow,
            officeShop: true,
            assignedTo: null,
            assignedToId: null,
            requestTransfer: {
              comments: values.comments,
            },
          });
        }

        setShowAssignTransfer(false);
        setRefreshGrid(!refreshGrid);
      }
    } catch (error: any) {
      log.error(error?.message?.exceptionMessage ?? "Something went wrong");
    }
  };

  const VehicleTransfer = () => (
    <>
      <Stack direction="row" spacing={5} mb={3}>
        <Typography>
          <b>Make</b>
        </Typography>
        <Typography>{currentRow?.vehicle?.make}</Typography>
      </Stack>
      <Stack direction="row" spacing={3.5} mb={3}>
        <Typography>
          <b>Model</b>
        </Typography>
        <Typography>{currentRow?.vehicle?.model}</Typography>
      </Stack>
      <Stack direction="row" spacing={6.5} mb={1}>
        <Typography>
          <b>Year</b>
        </Typography>
        <Typography>{currentRow?.vehicle?.year}</Typography>
      </Stack>
    </>
  );

  const EquipmentTransfer = () => (
    <>
      <Stack direction="row" spacing={3.7} mb={3} ml={13}>
        <Typography>
          <b>Name</b>
        </Typography>
        <Typography>{currentRow?.equipment?.name}</Typography>
      </Stack>
      <Stack direction="row" spacing={4} mb={3}>
        <Typography>
          <b>Serial Number</b>
        </Typography>
        <Typography>{currentRow?.equipment?.serialNumber}</Typography>
      </Stack>
      <Stack direction="row" spacing={3} mb={3}>
        <Typography>
          <b>Model Number</b>
        </Typography>
        <Typography>{currentRow?.equipment?.model}</Typography>
      </Stack>
    </>
  );

  const handleOnChange = (e: any) => {
    setValues({ ...values, [e.target.name]: e.target.value });
    setSelectRadioButton(e.target.value);
  };

  const commentsPlaceholder = `Place explain why the ${
    information.title === "Recommended Test Equipment"
      ? "test equipment"
      : information.title?.toLowerCase()
  } needs to be transfred to the auditor/technician selected`;

  return (
    <Popup
      title={`Transfer ${assetTitle}`}
      openPopup={showAssignTransfer}
      setOpenPopup={setShowAssignTransfer}
      onClose={() => {
        setShowAssignTransfer(false);
      }}
    >
      <Stack>
        <Stack>
          <div>
            {asset === AssetsConstant.VEHICLE && <VehicleTransfer />}
            {asset === AssetsConstant.EQUIPMENT && <EquipmentTransfer />}
            {!values.returnOffice && (
              <Stack direction="row" spacing={6.5} mb={2} ml={12} mt={3}>
                <FormSelect
                  name="selectedTransfer"
                  label="Transfer to"
                  value={values.selectedTransfer}
                  options={dataUser}
                  onChange={handleInputChange}
                  defaultValue={{
                    key: "-1",
                    value: " Please Select",
                  }}
                  error={errors.selectedTransfer}
                />
              </Stack>
            )}
            {values.returnOffice && dataSatellite && permissionRole && (
              <Stack ml={12}>
                <FormRadioGroup
                  name="radioButtonSelect"
                  label=""
                  value={values.radioButtonSelect}
                  items={radioInformation}
                  onChange={handleOnChange}
                  defaultValue={selectRadioButton}
                />
              </Stack>
            )}
            {values.radioButtonSelect === TRANSFERTYPE.SATELLITE && (
              <Stack direction="row" spacing={6.5} mb={3} ml={12}>
                <FormSelect
                  name="selectedSatellite"
                  label=""
                  value={values.selectedSatellite}
                  options={dataSatellite}
                  onChange={handleInputChange}
                  defaultValue={{
                    key: "-1",
                    value: " Please Select",
                  }}
                  error={errors.selectedSatellite}
                />
              </Stack>
            )}
            {permissionRole && (
              <Stack ml={12} mb={2}>
                <FormCheckBox
                  name="returnOffice"
                  value={values.returnOffice}
                  onChange={handleInputChange}
                  label={"Return to office"}
                  disabled={currentRow?.officeShop}
                />
              </Stack>
            )}

            <Stack direction="row" spacing={6.5} mb={3} ml={12}>
              <FormTextArea
                name="comments"
                label="Comments"
                value={values.comments}
                onChange={handleInputChange}
                placeholder={commentsPlaceholder}
                error={errors.comments}
              />
            </Stack>
          </div>
        </Stack>
      </Stack>

      <Stack
        direction="row"
        spacing={2}
        justifyContent="flex-end"
        alignItems="flex-start"
      >
        <FormCancelButton
          isSubmitting={false}
          onClick={() => {
            setShowAssignTransfer(false);
          }}
        />
        <FormButton
          text="Accept"
          size="small"
          onClick={() => {
            handleSave();
          }}
        />
      </Stack>
    </Popup>
  );
};

export default AssignTransferPopUp;
