import { Grid } from "@mui/material";
import { FormDatePicker, FormNumeric2 } from "src/components/formControls";
import { Mode } from "src/ts/types";
import useTowerStudy from "../../hooks/useTowerStudy";
import { ISystemOfMeasurement } from "src/ts/interfaces";
import moment from "moment";
import { useEffect } from "react";

interface Props {
  mode: Mode;
  disableStudy: boolean;
  isLoading: boolean;
  systemOfMeasurement: ISystemOfMeasurement;
  parentReportId: number | null;
}

const TowerForm = ({
  mode,
  disableStudy,
  isLoading,
  systemOfMeasurement,
  parentReportId,
}: Props) => {
  const {
    towerCondenserValues: values,
    handleInputTowerCondenserChange: handleInputChange,
    setTowerCondenserValues: setValues,
  } = useTowerStudy();

  useEffect(() => {
    if (
      values.actualDateTestPerformed === null ||
      values.actualDateTestPerformed === undefined
    ) {
      let newvalues = { ...values };
      newvalues.actualDateTestPerformed = moment().format("MM/DD/YYYY");
      setValues(newvalues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  return (
    <Grid container spacing={5}>
      {parentReportId === null && (
        <Grid item xs={6}>
          <FormDatePicker
            name="actualDateTestPerformed"
            label={`Actual Date Of When The Test Was Performed`}
            value={values.actualDateTestPerformed}
            onChange={handleInputChange}
            disable={disableStudy}
            showSkeleton={isLoading}
            fullWidth={true}
            disableFuture={true}
          />
        </Grid>
      )}
      <Grid item xs={6}>
        <FormNumeric2
          name="actualWaterDeliveryGPM"
          label={`Actual Condenser Water Delivery ${systemOfMeasurement.get(
            "gpm"
          )}`}
          value={values.actualWaterDeliveryGPM}
          onChange={handleInputChange}
          showSkeleton={isLoading}
          mode={mode}
          disabled={disableStudy}
          decimalScale={2}
          thousandSeparator={true}
          maxValue={1000000000}
          fixedDecimalScale={true}
        />
      </Grid>
      <Grid item xs={6}>
        <FormNumeric2
          name="actualEnteringAirWBTemp"
          label={`Actual Entering Air WB Temp ${systemOfMeasurement.get(
            "temperature"
          )}`}
          value={values.actualEnteringAirWBTemp}
          onChange={handleInputChange}
          showSkeleton={isLoading}
          mode={mode}
          disabled={disableStudy}
          decimalScale={2}
          thousandSeparator={true}
          maxValue={1000000000}
          fixedDecimalScale={true}
        />
      </Grid>
      <Grid item xs={6}>
        <FormNumeric2
          name="actualAmbientAirDBTemp"
          label={`Actual Ambient Air DB Temp ${systemOfMeasurement.get(
            "temperature"
          )}`}
          value={values.actualAmbientAirDBTemp}
          onChange={handleInputChange}
          showSkeleton={isLoading}
          mode={mode}
          disabled={disableStudy}
          decimalScale={2}
          thousandSeparator={true}
          maxValue={1000000000}
          fixedDecimalScale={true}
        />
      </Grid>
    </Grid>
  );
};

export default TowerForm;
