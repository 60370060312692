import React, { useState } from "react";
import { Alert as MuiAlert, Grid, Stack, Typography } from "@mui/material";
import { spacing } from "@mui/system";
import styled from "@emotion/styled";
import EmailIcon from "@mui/icons-material/Email";
import SmsIcon from "@mui/icons-material/Sms";
import { FormButton } from "src/components/formControls";
import { useForm, Form, useLog } from "src/hooks";
import useAuth from "src/hooks/useAuth";
import AuthenticationService from "src/services/authenticationService";
import { NotificationType } from "src/ts/types";
import ReactInputVerificationCode from "react-input-verification-code";
import "./ReactInputVerificationCodeCSS.css";

const Alert = styled(MuiAlert)(spacing);

const initialValues = {
  id: 0,
  name: "",
  statusId: 0,
  code: "",
};

const CodeVerification = () => {
  const { log } = useLog();
  const {
    userName,
    password,
    verifyCode,
    signOut,
    user,
    confirmIdentityBySms,
  } = useAuth();
  const validate = (fieldValues = values) => {
    let temp: Record<string, string> = { ...errors };
    if (fieldValues.hasOwnProperty("name")) {
      temp.name = fieldValues.name ? "" : "This field is required.";
      temp.name =
        fieldValues.name.length < 9 ? "put more than 9 charac..." : "";
    }

    if (fieldValues.hasOwnProperty("statusId"))
      temp.statusId =
        fieldValues.statusId !== 0 ? "" : "This field is required.";

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, errors, setErrors, handleInputChange } = useForm(
    initialValues,
    true,
    validate
  );

  const [message, setMessage] = useState("");
  const [methodAuth, setMethodAuth] = useState(false);
  const [emailButtonText, setEmailButtonText] = useState("Send");
  const [smsButtonText, setSmsButtonText] = useState("Send");
  const [codeSent, setCodeSent] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSendNotification = async (notificationType: NotificationType) => {
    if (userName !== null && password != null) {
      const request = await AuthenticationService.verificationCode({
        userName: userName,
        password: password,
        role: "",
        notificationType,
      });
      setMethodAuth(true);

      setMessage(request?.data?.message ?? "");
      if (notificationType === "Email") setEmailButtonText("Resend");
      else setSmsButtonText("Resend");
      setCodeSent(true);
      setErrors({});
    }
  };

  const handleVerifyCode = async () => {
    if (userName !== null && password != null) {
      setIsSubmitting(true);
      const isAuthenticated = await verifyCode({
        userName: userName!,
        password: password!,
        code: values.code,
      });
      setIsSubmitting(false);
      if (!isAuthenticated) {
        errors.code =
          "The verification code you entered is incorrect, please  validate it and try again.";
        setErrors({ ...errors });
        if (errors.code) log.error(errors.code);
      } else {
        setErrors({});
      }
    }
  };

  const handleCancelSignIn = async () => {
    await signOut();
  };

  return (
    <div>
      <Form>
        <Grid container spacing={5}>
          <Grid item xs={12}>
            <Alert mt={2} mb={3} severity="warning">
              You are required to use Two-Factor Authentication. Please choose
              whether to receive your code by email or cell phone. If you do not
              receive a code after your first choice, you can switch between SMS
              and email.
            </Alert>
            {message && (
              <Alert mt={2} mb={3} severity="info">
                {message}
              </Alert>
            )}

            <Typography component="h2" variant="body1" align="center">
              Authentication code
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <div className="custom-styles">
              <ReactInputVerificationCode
                onChange={(values) =>
                  handleInputChange({ target: { value: values, name: "code" } })
                }
                value={values.code}
                length={6}
                placeholder={""}
              />
            </div>
          </Grid>
          {codeSent && (
            <Grid item xs={12}>
              <Typography component="h2" variant="body1" align="center">
                Didn't receive the verification code?
              </Typography>
            </Grid>
          )}
          <Grid item xs={12}>
            <Stack
              direction="row"
              spacing={2}
              justifyContent="space-around"
              alignItems="flex-start"
            >
              <FormButton
                text={`${emailButtonText} to email`}
                fullWidth={true}
                onClick={() => handleSendNotification("Email")}
                startIcon={<EmailIcon />}
              />
              {(user?.confirmIdentityBySms || confirmIdentityBySms) && (
                <FormButton
                  text={`${smsButtonText} to Cell phone`}
                  fullWidth={true}
                  onClick={() => handleSendNotification("SMS")}
                  startIcon={<SmsIcon />}
                />
              )}
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Stack
              direction="row"
              spacing={2}
              justifyContent="space-around"
              alignItems="flex-start"
            >
              <FormButton
                text="Verify"
                color="primary"
                variant="outlined"
                fullWidth={true}
                onClick={handleVerifyCode}
                disabled={!methodAuth}
                isSubmitting={isSubmitting}
              />
              <FormButton
                text="Cancel"
                color="primary"
                variant="outlined"
                fullWidth={true}
                onClick={handleCancelSignIn}
              />
            </Stack>
          </Grid>
        </Grid>
      </Form>
    </div>
  );
};

export default CodeVerification;
