import {
  Grid,
  Typography,
  Divider as MuiDivider,
  Skeleton,
} from "@mui/material";
import styled from "@emotion/styled";
import { useParams, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { IReportGroup } from "src/ts/interfaces";
import { useAsyncQuery, useAuth } from "src/hooks";
import HeaderPage from "src/components/page/HeaderPage";
import { spacing } from "@mui/system";
import templateServices from "src/services/templateServices";
import { ITemplate } from "src/ts/interfaces/template";
import projectReportGroupService from "src/services/ProjectReportGroupService";
import { FormButton } from "src/components/formControls";
import ToleranceInput from "./projectStudies/components/ToleranceInput";
import Study from "./projectStudies/components/Study";

const Divider = styled(MuiDivider)(spacing);

interface Toleraces {
  diffuserRegisterGrilleToleranceTop?: number | null;
  diffuserRegisterGrilleToleranceBottom?: number | null;
  requiredCFMSystemToleranceTop?: number | null;
  requiredCFMSystemToleranceBottom?: number | null;
  hydronicTerminalDevicesToleranceTop?: number | null;
  hydronicTerminalDevicesToleranceBottom?: number | null;
  requiredGPMSystemToleranceTop?: number | null;
  requiredGPMSystemToleranceBottom?: number | null;
  outsideAirVentilationRateTop?: number | null;
  outsideAirVentilationRateBottom?: number | null;

  energyDiffuserRegisterGrilleToleranceTop?: number | null;
  energyDiffuserRegisterGrilleToleranceBottom?: number | null;
  energyRequiredCFMSystemToleranceTop?: number | null;
  energyRequiredCFMSystemToleranceBottom?: number | null;
  energyHydronicTerminalDevicesToleranceTop?: number | null;
  energyHydronicTerminalDevicesToleranceBottom?: number | null;
  energyRequiredGPMSystemToleranceTop?: number | null;
  energyRequiredGPMSystemToleranceBottom?: number | null;
  energyOutsideAirVentilationRateTop?: number | null;
  energyOutsideAirVentilationRateBottom?: number | null;
}

export default function ViewTemplateFromTolerances() {
  const params = useParams<{ projectId: string; templateId: string }>();
  const project = parseInt(
    params?.projectId === undefined ? "0" : params?.projectId
  );
  const projectId = Number(project);

  const template = parseInt(
    params?.templateId === undefined ? "0" : params?.templateId
  );

  const templateId = Number(template);

  const navigate = useNavigate();
  const { user } = useAuth();

  const { data, execute } = useAsyncQuery<Toleraces>(
    templateServices.getProject
  );

  const { data: getTemplate, execute: executeTemplate } =
    useAsyncQuery<ITemplate>(templateServices.getById);

  const { execute: executeReportGroup, data: dataReportGroup } =
    useAsyncQuery<IReportGroup>(
      projectReportGroupService.getProjectReportGrupo
    );

  useEffect(() => {
    execute(templateId);
    executeTemplate(templateId);
    executeReportGroup(projectId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const skeletonCount = 10;
  const skeletons = Array.from({ length: skeletonCount }, (_, index) => (
    <Skeleton
      key={index}
      width={1500}
      height={25}
      style={{ marginTop: "5px" }}
    />
  ));

  return (
    <>
      <HeaderPage
        title={`Template: ${getTemplate?.name}`}
        parentText={"Dashboard"}
        parentLink={`/`}
        actionSection={undefined}
        pageYoutube=""
      ></HeaderPage>

      <Divider my={6} />
      <Grid container justifyContent={"center"}>
        <Grid item xs={12} sm={12} lg={6}>
          <Grid container>
            <Grid item xs={8}>
              <Typography
                gutterBottom
                variant="h6"
                component="div"
                align="center"
                color="info.main"
                textAlign={"right"}
              >
                Tolerances
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <ToleranceInput
                label="Diffuser, Register & Grille CFM Tolerance"
                topName="diffuserRegisterGrilleToleranceTop"
                bottonName="diffuserRegisterGrilleToleranceBottom"
                topValue={data?.diffuserRegisterGrilleToleranceTop}
                bottomValue={data?.diffuserRegisterGrilleToleranceBottom}
                onChange={() => {}}
                disable={true}
              />
            </Grid>
            <Grid item xs={12}>
              <ToleranceInput
                label="Total System CFM Tolerance"
                topName="requiredCFMSystemToleranceTop"
                bottonName="requiredCFMSystemToleranceBottom"
                topValue={data?.requiredCFMSystemToleranceTop}
                bottomValue={data?.requiredCFMSystemToleranceBottom}
                onChange={() => {}}
                disable={true}
              />
            </Grid>
            <Grid item xs={12}>
              <ToleranceInput
                label="Outside Air Ventilation Rate Tolerance"
                topName="outsideAirVentilationRateTop"
                bottonName="outsideAirVentilationRateBottom"
                topValue={data?.outsideAirVentilationRateTop}
                bottomValue={data?.outsideAirVentilationRateBottom}
                onChange={() => {}}
                disable={true}
              />
            </Grid>
            <Grid item xs={12}>
              <ToleranceInput
                label="Hydronic Terminal Devices GPM Tolerance"
                topName="hydronicTerminalDevicesToleranceTop"
                bottonName="hydronicTerminalDevicesToleranceBottom"
                topValue={data?.hydronicTerminalDevicesToleranceTop}
                bottomValue={data?.hydronicTerminalDevicesToleranceBottom}
                onChange={() => {}}
                disable={true}
              />
            </Grid>
            <Grid item xs={12}>
              <ToleranceInput
                label="Total System GPM Tolerance"
                topName="requiredGPMSystemToleranceTop"
                bottonName="requiredGPMSystemToleranceBottom"
                topValue={data?.requiredGPMSystemToleranceTop}
                bottomValue={data?.requiredGPMSystemToleranceBottom}
                onChange={() => {}}
                disable={true}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid container>
        {dataReportGroup ? (
          <>
            <Typography variant="h4">Configurable Studies</Typography>
            <Grid container xs={12} spacing={5} item>
              {dataReportGroup?.groupType1 !== null &&
                dataReportGroup?.groupType1.map((item) => (
                  <Grid item xs={12} md={4} key={item.id}>
                    <Study
                      study={item}
                      isSelectable={true}
                      readOnly={true}
                      groupId={1}
                      projectId={projectId}
                      disable={false}
                    />
                  </Grid>
                ))}
            </Grid>
            <Typography variant="h4" mt={5}>
              Non-Configurable Studies
            </Typography>
            <Grid container xs={12} spacing={5} item>
              {dataReportGroup?.groupType1 !== null &&
                dataReportGroup?.groupType3.map((item, index) => (
                  <Grid item xs={12} md={4} key={item.id}>
                    <Study
                      study={item}
                      isSelectable={true}
                      readOnly={true}
                      groupId={3}
                      projectId={projectId}
                    />
                  </Grid>
                ))}
            </Grid>
          </>
        ) : (
          skeletons
        )}
      </Grid>
      <Grid container justifyContent={"center"} mt={5}>
        <FormButton
          onClick={() => {
            navigate(
              `/app/TolerancesWizard/Edit/${projectId}/${user?.companyId}`
            );
          }}
          text="Close"
          size="medium"
          variant="outlined"
        />
      </Grid>
    </>
  );
}
