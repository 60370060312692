import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import dayjs from "dayjs";
import FormReadOnlyField from "./FormReadOnlyField";
import { Skeleton } from "@mui/material";

interface FormTimePickerProps {
  name: string;
  label: string;
  value: string | null | undefined;
  onChange: (e: any) => void;
  size?: "small" | "medium" | null;
  fullWidth?: boolean | undefined;
  disablePast?: boolean;
  disable?: boolean;
  mode?: "read" | "read&Write";
  error?: any;
  showSkeleton?: boolean;
}

const FormTimePicker = (props: FormTimePickerProps) => {
  const { name, label, value, disable, onChange, mode, error, showSkeleton } =
    props;

  const convertToDefEventPara = (name: string, value: any | null) => {
    return {
      target: {
        name,
        //if you need different time format check https://day.js.org/docs/en/display/format
        value: dayjs(value).format("LT"),
      },
    };
  };

  if (mode && mode === "read")
    return (
      <FormReadOnlyField
        label={label}
        value={dayjs("2022-04-07 " + value).format("LT") ?? ""}
      />
    );

  return (
    <>
      {showSkeleton ? (
        <Skeleton height={40}></Skeleton>
      ) : (
        <TimePicker
          label={label}
          value={value !== null ? dayjs("2022-04-07 " + value) : null}
          disabled={disable ?? false}
          onChange={(time) => onChange(convertToDefEventPara(name, time))}
          slotProps={{
            textField: {
              size: "small",
              helperText: error ?? "",
              error: error !== undefined && error !== null && error !== "",
            },
          }}
        />
      )}
    </>
  );
};

export default FormTimePicker;
