import React from "react";
import { CircularProgress } from "@mui/material";
import { Save as SaveIcon } from "@mui/icons-material";
import FormButton from "./FormButton";
import AddIcon from "@mui/icons-material/Add";
import { FilterAltIcon, SearchIcon } from "src/components/icons";
import { CloudUploadIcon, SettingsIcon } from "../icons";

interface ButtonProps {
  text?: string;
  onClick: (e: React.MouseEvent<HTMLElement>) => void;
  [key: string]: any;
  isSubmitting?: boolean;
  disabled?: boolean;
  type: "save" | "add" | "filter" | "search" | "upload" | "settings";
}

const FormActionButton = (props: ButtonProps) => {
  const {
    text,
    size,
    variant,
    onClick,
    isSubmitting,
    disabled,
    type,
    ...other
  } = props;

  const IconType = () => {
    switch (type) {
      case "save":
        return <SaveIcon />;
      case "add":
        return <AddIcon />;
      case "filter":
        return <FilterAltIcon />;
      case "search":
        return <SearchIcon />;
      case "upload":
        return <CloudUploadIcon />;
      case "settings":
        return <SettingsIcon />;
    }
  };

  return (
    <FormButton
      color="primary"
      text={text ?? type}
      variant="contained"
      size="small"
      onClick={onClick}
      startIcon={isSubmitting ? <CircularProgress size="1rem" /> : <IconType />}
      disabled={disabled || isSubmitting}
      {...other}
    />
  );
};

export default FormActionButton;
