import { CardContent, Grid, Card as MuiCard } from "@mui/material";
import { spacing } from "@mui/system";
import styled from "@emotion/styled";
import { useMemo } from "react";
import {
  IKeyValue,
  IOutletReport,
  IOutletReportComponent,
  ISystemOfMeasurement,
} from "src/ts/interfaces";
import {
  FormCheckBox,
  FormNumeric2,
  FormRadioGroup,
  FormSelect,
  FormText,
} from "src/components/formControls";
import useOutletReport from "src/pages/studies/outlet/hooks/useOutletReport";
import { Mode } from "src/ts/types";

const Card = styled(MuiCard)(spacing);

interface Props {
  componentId: number | null;
  outletReport: IOutletReport;
  meterDeviceKeyValue: IKeyValue<number, string>[];
  mode: Mode;
  systemOfMeasurement: ISystemOfMeasurement;
  disableStudy: boolean;
}

const coilTypes: IKeyValue<string, string>[] = [
  { key: "None", value: "None" },
  { key: "Hydronic", value: "Hydronic" },
  { key: "Electric", value: "Electric" },
  { key: "DX", value: "DX" },
];

const hydronicCoilTypes: IKeyValue<string, string>[] = [
  { key: "Hydronic Hot Water", value: "Hydronic Hot Water" },
  { key: "Hydronic Chilled Water", value: "Hydronic Chilled Water" },
  { key: "Hydronic Both", value: "Hydronic Both" },
];

export const radioInformation = [
  {
    key: "Round",
    value: "Round",
  },
  {
    key: "Rectangular",
    value: "Rectangular",
  },
];

const HYDRONIC = "Hydronic";

const ComponentForm = ({
  componentId,
  outletReport,
  meterDeviceKeyValue,
  mode,
  systemOfMeasurement,
  disableStudy,
}: Props) => {
  const row = 12;
  const {
    componentValues: values,
    setComponentValues: setValues,
    handleInputComponentChange: handleInputChange,
    errorComponents: errors,
    isLoading,
  } = useOutletReport();

  const showHydronic = useMemo(() => {
    if (values?.coilTypeLinked) {
      const type = coilTypes.find(
        (item) => item.key === values?.coilTypeLinked
      );
      return type?.value === HYDRONIC;
    }
  }, [values?.coilTypeLinked]);

  const validSelectedCoilLinked = useMemo(() => {
    const coilType = values?.coilTypeLinked;
    const hydronicType = values?.hydronicTypeCoilLinked;
    let result = false;
    if (coilType != null) {
      if (coilType === HYDRONIC) result = hydronicType != null;
      else result = true;
    }

    return result;
  }, [values?.coilTypeLinked, values?.hydronicTypeCoilLinked]);

  const manufacturerNoAvailableHandler = (e: any) => {
    setValues({
      ...values,
      manufacturerNotAvailable: e.target.value,
      manufacturer: null,
    });
  };

  const modelNumerNoAvailableHandler = (e: any) => {
    setValues({
      ...values,
      modelNumberNotAvailable: e.target.value,
      modelNumber: null,
    });
  };

  const serialNumberNoAvailableHandler = (e: any) => {
    setValues({
      ...values,
      serialNumberNotAvailable: e.target.value,
      serialNumber: null,
    });
  };

  function getSqFt(
    outletComponent: IOutletReportComponent,
    measurementSystem: string
  ) {
    const sqFoot = 144;
    const sqMt = 1000000;
    const maxCfmIP = 2250;
    const minCfmIP = 1350;
    const maxCfmMet = 11.43;
    const minCfmMet = 6.858;

    var ductArea = 0;

    if (
      outletComponent.inletTypeOfDuct === "Rectangular" &&
      outletComponent.size != null &&
      outletComponent.actualBoxInletSizeH
    ) {
      ductArea = outletComponent.size * outletComponent.actualBoxInletSizeH;
    } else if (
      outletComponent.inletTypeOfDuct === "Round" &&
      outletComponent.size != null
    ) {
      ductArea = Math.PI * Math.pow(outletComponent.size / 2, 2);
    }

    outletComponent.actualInletSqtFt =
      measurementSystem === "metricSystem"
        ? ductArea / sqMt
        : ductArea / sqFoot;
    outletComponent.designMaximumCfm =
      measurementSystem === "metricSystem"
        ? outletComponent.actualInletSqtFt * maxCfmMet
        : outletComponent.actualInletSqtFt * maxCfmIP;
    outletComponent.designMinimumCfm =
      measurementSystem === "metricSystem"
        ? outletComponent.actualInletSqtFt * minCfmMet
        : outletComponent.actualInletSqtFt * minCfmIP;

    return outletComponent;
  }

  const getActualSqtFtHandler = (e: any) => {
    if (systemOfMeasurement.measurementSystem === null) return;
    const sqFt = getSqFt(values, systemOfMeasurement.measurementSystem);
    setValues({ ...values, ...sqFt });
  };

  return (
    <Card mb={1}>
      <CardContent>
        <Grid container spacing={5}>
          <Grid item xs={row}></Grid>
          <Grid item xs={row}>
            <FormSelect
              name="coilTypeLinked"
              label="Coil Type Linked to this Device"
              value={values?.coilTypeLinked}
              onChange={handleInputChange}
              options={coilTypes}
              error={errors.coilTypeLinked}
              disabled={disableStudy}
              mode={mode}
              // defaultValue={{ key: "None", value: "None" }}
            />
          </Grid>
          {showHydronic && (
            <Grid item xs={row}>
              <FormSelect
                name="hydronicTypeCoilLinked"
                label="Hydronic Coil Type Linked to this Device"
                value={values?.hydronicTypeCoilLinked}
                onChange={handleInputChange}
                options={hydronicCoilTypes}
                error={errors.hydronicTypeCoilLinked}
                disabled={disableStudy}
                mode={mode}
              />
            </Grid>
          )}
          <Grid item xs={row}>
            <FormText
              name="name"
              label="Box Identifier"
              value={values?.name}
              onChange={handleInputChange}
              error={errors.name}
              showSkeleton={isLoading}
              mode={mode}
              disabled={disableStudy}
            />
          </Grid>
          <Grid item xs={row}>
            <FormText
              name="boxAddress"
              label="Box Address"
              value={values?.boxAddress}
              onChange={handleInputChange}
              error={errors.boxAddress}
              showSkeleton={isLoading}
              mode={mode}
              disabled={disableStudy}
            />
          </Grid>
          <Grid item xs={6}>
            <FormText
              name="manufacturer"
              label="Manufacturer"
              value={values?.manufacturer ?? ""}
              onChange={handleInputChange}
              error={errors.manufacturer}
              showSkeleton={isLoading}
              mode={mode}
              disabled={disableStudy || values.manufacturerNotAvailable}
            />
          </Grid>
          <Grid item xs={6}>
            <FormCheckBox
              label="Manufacturer Not Available"
              name="manufacturerNotAvailable"
              value={values.manufacturerNotAvailable}
              onChange={(e) => manufacturerNoAvailableHandler(e)}
              showSkeleton={isLoading}
              mode={mode}
              disabled={disableStudy}
            />
          </Grid>
          <Grid item xs={6}>
            <FormText
              name="modelNumber"
              label="Model Number"
              value={values?.modelNumber ?? ""}
              onChange={handleInputChange}
              error={errors.modelNumber}
              showSkeleton={isLoading}
              mode={mode}
              disabled={disableStudy || values.modelNumberNotAvailable}
            />
          </Grid>
          <Grid item xs={6}>
            <FormCheckBox
              label="Model Number Not Available"
              name="modelNumberNotAvailable"
              value={values.modelNumberNotAvailable}
              showSkeleton={isLoading}
              onChange={(e) => modelNumerNoAvailableHandler(e)}
              mode={mode}
              disabled={disableStudy}
            />
          </Grid>
          <Grid item xs={6}>
            <FormText
              name="serialNumber"
              label="Serial Number"
              value={values?.serialNumber ?? ""}
              onChange={handleInputChange}
              error={errors.serialNumber}
              showSkeleton={isLoading}
              mode={mode}
              disabled={disableStudy || values.serialNumberNotAvailable}
            />
          </Grid>
          <Grid item xs={6}>
            <FormCheckBox
              label="Serial Number Not Available"
              name="serialNumberNotAvailable"
              showSkeleton={isLoading}
              value={values.serialNumberNotAvailable}
              onChange={(e) => serialNumberNoAvailableHandler(e)}
              mode={mode}
              disabled={disableStudy}
            />
          </Grid>
          <Grid item xs={12}>
            <FormRadioGroup
              name="inletTypeOfDuct"
              label="Type of Inlet Duct"
              value={values.inletTypeOfDuct}
              items={radioInformation}
              onChange={handleInputChange}
              row={true}
              disabled={disableStudy}
              showSkeleton={isLoading}
              mode={mode}
            />
          </Grid>
          {systemOfMeasurement.measurementSystem && (
            <>
              <Grid item xs={values.inletTypeOfDuct === "Rectangular" ? 6 : 12}>
                <FormNumeric2
                  name="designBoxInletSizeW"
                  label={`Design Box Inlet ${
                    values.inletTypeOfDuct === "Round" ? "Diameter" : "Width"
                  } (${systemOfMeasurement.get("inches")})`}
                  value={values?.designBoxInletSizeW?.toString()}
                  onChange={handleInputChange}
                  error={errors.designBoxInletSizeW}
                  showSkeleton={isLoading}
                  mode={mode}
                  disabled={disableStudy}
                  maxValue={100000}
                  onBlur={getActualSqtFtHandler}
                  decimalScale={3}
                />
              </Grid>

              {values.inletTypeOfDuct === "Rectangular" && (
                <Grid
                  item
                  xs={values.inletTypeOfDuct === "Rectangular" ? 6 : 12}
                >
                  <FormNumeric2
                    name="designBoxInletSizeH"
                    label={`Design Box Inlet Height (${systemOfMeasurement.get(
                      "inches"
                    )})`}
                    value={values?.designBoxInletSizeH?.toString()}
                    onChange={handleInputChange}
                    error={errors.designBoxInletSizeH}
                    showSkeleton={isLoading}
                    mode={mode}
                    disabled={disableStudy}
                    maxValue={100000}
                    onBlur={getActualSqtFtHandler}
                    decimalScale={3}
                  />
                </Grid>
              )}
            </>
          )}
          {systemOfMeasurement.measurementSystem && (
            <>
              <Grid item xs={values.inletTypeOfDuct === "Rectangular" ? 6 : 12}>
                <FormNumeric2
                  name="size"
                  label={`Actual Box Inlet ${
                    values.inletTypeOfDuct === "Round" ? "Diameter" : "Width"
                  } (${systemOfMeasurement.get("inches")})`}
                  value={values?.size?.toString()}
                  onChange={handleInputChange}
                  error={errors.size}
                  showSkeleton={isLoading}
                  mode={mode}
                  disabled={disableStudy}
                  maxValue={100000}
                  onBlur={getActualSqtFtHandler}
                />
              </Grid>
              {values.inletTypeOfDuct === "Rectangular" && (
                <Grid
                  item
                  xs={values.inletTypeOfDuct === "Rectangular" ? 6 : 12}
                >
                  <FormNumeric2
                    name="actualBoxInletSizeH"
                    label={`Actual Box Inlet Height (${systemOfMeasurement.get(
                      "inches"
                    )})`}
                    value={values?.actualBoxInletSizeH?.toString()}
                    onChange={handleInputChange}
                    error={errors.actualBoxInletSizeH}
                    showSkeleton={isLoading}
                    mode={mode}
                    disabled={disableStudy}
                    maxValue={100000}
                    onBlur={getActualSqtFtHandler}
                    decimalScale={3}
                  />
                </Grid>
              )}
            </>
          )}
          <Grid item xs={12}>
            <FormNumeric2
              name="actualInletSqtFt"
              label={`${systemOfMeasurement.get("sqft")}`}
              value={values?.actualInletSqtFt?.toString()}
              onChange={handleInputChange}
              error={errors.actualInletSqtFt}
              showSkeleton={isLoading}
              mode={mode}
              disabled={true}
              maxValue={100000}
              onBlur={getActualSqtFtHandler}
              decimalScale={3}
              fixedDecimalScale
            />
          </Grid>
          {outletReport?.controlType?.name !== "Pneumatic" && (
            <Grid item xs={12}>
              <FormText
                name="boxSizeRange"
                label={`Box Mfg. Listed/Label ${systemOfMeasurement.get(
                  "cfm"
                )}`}
                value={values?.boxSizeRange}
                onChange={handleInputChange}
                error={errors.boxSizeRange}
                showSkeleton={isLoading}
                mode={mode}
                disabled={disableStudy}
              />
            </Grid>
          )}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default ComponentForm;
