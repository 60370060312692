import axios from "src/utils/axios";
import {
  IStaticIdentDTO,
  IStaticPressureComponentCategoryDTO,
  IStaticPressureComponentDTO,
  IStaticPressureComponentElementDTO,
  IStaticReportDTO,
} from "src/ts/interfaces";

const save = (staticReport: IStaticReportDTO) => {
  return axios.post<IStaticReportDTO>("StaticReport", staticReport);
};

const getById = (id: number) => {
  return axios.get<IStaticReportDTO>(`StaticReport/${id}`);
};

const update = (id: number, staticReport: IStaticReportDTO) => {
  return axios.put<IStaticReportDTO>(`StaticReport/${id}`, staticReport);
};

const updateIdent = (id: number, staticReport: IStaticIdentDTO[]) => {
  return axios.put<IStaticIdentDTO[]>(
    `StaticReport/Idents/${id}`,
    staticReport
  );
};

const removeIdent = (id: string) => {
  return axios.delete(`StaticReport/Ident/${id}`);
};

const getSubcategories = (id: string) => {
  return axios.get<IStaticPressureComponentCategoryDTO[]>(
    `StaticPressureComponentCategory/GetByComponent/${id}`
  );
};

const getElements = (id: string) => {
  return axios.get<IStaticPressureComponentElementDTO[]>(
    `StaticPressureComponentElement/GetByCategory/${id}`
  );
};

const getComponents = () => {
  return axios.get<IStaticPressureComponentDTO[]>("StaticPressureComponent");
};

const staticReportService = {
  save,
  getById,
  update,
  updateIdent,
  removeIdent,
  getSubcategories,
  getElements,
  getComponents,
};

export default staticReportService;
