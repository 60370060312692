import React, { useState, useEffect, Dispatch, SetStateAction } from "react";
import {
  ITechnicianQuizBacklog,
  ITechnicianQuizCategory,
} from "src/ts/interfaces";
import {
  Grid,
  Typography,
  ListItem,
  IconButton,
  Divider,
  CardContent,
  Chip as MuiChip,
} from "@mui/material";
import { spacing, SpacingProps } from "@mui/system";
import { technicianQuizCategoryService } from "src/services/index";
import { AddCircle, RemoveCircle } from "@mui/icons-material";
import { useLoading, useLog } from "src/hooks";
import styled from "@emotion/styled";
import FormTextEditor from "src/components/formControls/FormTextEditor";
import FormAccordion from "src/components/formControls/FormAccordion";

interface IQuizQuestionProps {
  quizId: number;
  setNumberOfQuestions: Dispatch<SetStateAction<number>>;
  setSelectedCategory: Dispatch<SetStateAction<ITechnicianQuizCategory[]>>;
  selectedCategory: ITechnicianQuizCategory[];
}

interface ChipProps extends SpacingProps {
  component?: React.ElementType;
  href?: string;
  icon?: JSX.Element | null;
}

const Chip = styled(MuiChip)<ChipProps>(spacing);

const SelectQuizQuestions = ({
  quizId,
  setNumberOfQuestions,
  setSelectedCategory,
  selectedCategory,
}: IQuizQuestionProps) => {
  const { log } = useLog();
  const [category, setCategory] = useState<ITechnicianQuizCategory[]>([]);
  const { isLoading, startRequest, endRequest } = useLoading();
  const [expanded, setExpanded] = React.useState<string | false>(false);
  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };
  useEffect(() => {
    const getData = async () => {
      try {
        startRequest("Category");
        const request = await technicianQuizCategoryService.getAll();
        setCategory(request.data);
        endRequest("Category");
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
        endRequest("Category");
      }
    };

    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quizId]);

  async function selectAll() {
    category.forEach((value) => {
      setSelectedCategory((existing) => [...existing, value]);
    });
    setCategory((old) => []);
  }

  async function unselectAll() {
    selectedCategory.forEach((value) => {
      setCategory((existing) => [...existing, value]);
    });
    setSelectedCategory((old) => []);
  }

  useEffect(() => {
    let count = 0;
    selectedCategory.forEach((value) => {
      count = count + value.technicianQuizBacklog.length;
    });
    setNumberOfQuestions(count);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCategory]);

  const selectCategory = (value: ITechnicianQuizCategory) => {
    const result = selectedCategory.find((element) => element.id === value.id);
    if (result === undefined) {
      if (value !== undefined)
        setSelectedCategory((existing) => [...existing, value]);
    } else {
      selectedCategory.forEach((questions) => {
        if (questions !== undefined && questions.id === value.id) {
          value.technicianQuizBacklog.forEach((item) => {
            if (item !== undefined) {
              questions.technicianQuizBacklog.push(item);
            }
          });
        }
      });
    }
    setCategory(category.filter((item) => item.id !== value?.id));
  };

  const unselectCategory = (value: ITechnicianQuizCategory) => {
    const result = category.find((element) => element.id === value.id);
    if (result === undefined) {
      if (value !== undefined) setCategory((existing) => [...existing, value]);
    } else {
      category.forEach((questions) => {
        if (questions !== undefined && questions.id === value.id) {
          value.technicianQuizBacklog.forEach((item) => {
            if (item !== undefined) {
              questions.technicianQuizBacklog.push(item);
            }
          });
        }
      });
    }
    setSelectedCategory(
      selectedCategory.filter((item) => item.id !== value?.id)
    );
  };

  const selectQuestion = (
    categoryId: number,
    question: ITechnicianQuizBacklog,
    selected: boolean
  ) => {
    if (selected) {
      const result = selectedCategory.find(
        (element) => element.id === categoryId
      );
      const currentCategory = category.find(
        (element) => element.id === categoryId
      );
      if (
        result === undefined &&
        question !== undefined &&
        currentCategory !== undefined &&
        selectedCategory !== undefined
      ) {
        setSelectedCategory((current) => [
          ...current,
          {
            id: currentCategory.id,
            technicianQuizBacklog: currentCategory.technicianQuizBacklog.filter(
              (item) => item.id === question.id
            ),
            name: currentCategory.name,
          },
        ]);
      } else if (result !== undefined) {
        result.technicianQuizBacklog.push(question);
      }

      if (currentCategory !== undefined) {
        if (currentCategory.technicianQuizBacklog.length === 1) {
          setCategory(category.filter((item) => item.id !== categoryId));
        } else {
          const update = category.map((item) => {
            if (item.id === categoryId) {
              return {
                ...item,
                technicianQuizBacklog: item.technicianQuizBacklog.filter(
                  (item) => item.id !== question.id
                ),
              };
            } else {
              return item;
            }
          });

          setCategory(update);
        }
      }
    } else {
      const result = category.find((element) => element.id === categoryId);
      const currentCategory = selectedCategory.find(
        (element) => element.id === categoryId
      );
      if (
        result === undefined &&
        question !== undefined &&
        currentCategory !== undefined
      ) {
        setCategory((current) => [
          ...current,
          {
            id: currentCategory.id,
            technicianQuizBacklog: currentCategory.technicianQuizBacklog.filter(
              (item) => item.id === question.id
            ),
            name: currentCategory.name,
          },
        ]);
      } else if (result !== undefined) {
        result.technicianQuizBacklog.push(question);
      }

      if (currentCategory !== undefined) {
        if (currentCategory.technicianQuizBacklog.length === 1) {
          setSelectedCategory(
            selectedCategory.filter((item) => item.id !== categoryId)
          );
        } else {
          const update = selectedCategory.map((item) => {
            if (item.id === categoryId) {
              return {
                ...item,
                technicianQuizBacklog: item.technicianQuizBacklog.filter(
                  (item) => item.id !== question.id
                ),
              };
            } else {
              return item;
            }
          });

          setSelectedCategory(update);
        }
      }
    }
  };

  const iconCircle = (selected: boolean) => {
    return selected ? <AddCircle /> : <RemoveCircle />;
  };

  const isRequired = (required: boolean) => {
    return required ? (
      <Chip label="Required" color="primary" m={1} size="small" />
    ) : (
      <></>
    );
  };

  const renderBacklog = (
    backlog: ITechnicianQuizBacklog,
    questionId: number,
    selected: boolean
  ) => {
    return (
      <ListItem
        key={
          (selected ? "sitem" : "uitem") + questionId.toString() + backlog.id
        }
        disableGutters
        secondaryAction={
          <IconButton
            onClick={() => selectQuestion(questionId, backlog, selected)}
            disabled={isLoading}
            aria-label="Add"
          >
            {iconCircle(selected)}
          </IconButton>
        }
      >
        <FormTextEditor
          value={backlog.description}
          onChange={function (e: any): void {
            throw new Error("Function not implemented.");
          }}
          name={"description" + questionId}
          readonly={true}
        ></FormTextEditor>
        {isRequired(backlog.isRequired)}
      </ListItem>
    );
  };

  function subItems(
    parent: ITechnicianQuizBacklog[],
    id: number,
    selected: boolean
  ) {
    return parent.map((item) => renderBacklog(item, id, selected));
  }

  const listItems = category.map(function (item) {
    return (
      <div>
        <FormAccordion
          title={
            <>
              <Typography variant="subtitle1" style={{ paddingTop: "8px" }}>
                {item.name}
              </Typography>
              <IconButton
                onClick={() => selectCategory(item)}
                disabled={isLoading}
                aria-label="Add"
              >
                <AddCircle />
              </IconButton>
            </>
          }
          expanded={expanded === `selected${item.id}`}
          onChange={handleChange(`selected${item.id}`)}
        >
          <Typography variant="subtitle1" color="textSecondary">
            {subItems(item.technicianQuizBacklog, item.id, true)}
          </Typography>
        </FormAccordion>
      </div>
    );
  });

  const listItemsSelected = selectedCategory.map(function (item) {
    return (
      <div>
        <FormAccordion
          title={
            <>
              <Typography variant="subtitle1" style={{ paddingTop: "8px" }}>
                {item.name}
              </Typography>
              <IconButton
                onClick={() => unselectCategory(item)}
                disabled={isLoading}
                aria-label="Add"
              >
                <RemoveCircle />
              </IconButton>
            </>
          }
          expanded={expanded === `selected${item.id}`}
          onChange={handleChange(`selected${item.id}`)}
        >
          <Typography variant="subtitle1" color="textSecondary">
            {subItems(item.technicianQuizBacklog, item.id, false)}
          </Typography>
        </FormAccordion>
      </div>
    );
  });

  return (
    <React.Fragment>
      <Grid container spacing={5}>
        <Grid item xs={6} sx={{ paddingBottom: 5 }}>
          <CardContent>
            <div>
              <h3 style={{ paddingLeft: "8px" }}>
                Select All{" "}
                <IconButton
                  disabled={isLoading}
                  onClick={async () => {
                    await selectAll();
                  }}
                  aria-label="Add"
                >
                  <AddCircle />
                </IconButton>
              </h3>
              <Divider />
            </div>
            {listItems}
          </CardContent>
        </Grid>
        <Grid item xs={6} sx={{ paddingBottom: 5 }}>
          <CardContent>
            <div>
              <h3 style={{ paddingLeft: "8px" }}>
                Unselect All{" "}
                <IconButton
                  disabled={isLoading}
                  onClick={async () => {
                    await unselectAll();
                  }}
                  aria-label="Remove"
                >
                  <RemoveCircle />
                </IconButton>
              </h3>
              <Divider />
            </div>
            {listItemsSelected}
          </CardContent>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default SelectQuizQuestions;
