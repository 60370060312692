import axios from "src/utils/axios";
import { IMotorSheaveMfg } from "src/ts/interfaces/catalogs";

const getMotorSheaveManufacturers = () => {
  return axios.get<IMotorSheaveMfg[]>("MotorSheaveMfg");
};

const motorSheaveMfgService = {
  getMotorSheaveManufacturers,
};

export default motorSheaveMfgService;
