import React, { useState } from "react";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import {
  CardContent,
  Grid,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  Typography,
  Stack,
  Tabs,
  Tab,
} from "@mui/material";
import { spacing } from "@mui/system";
import { FormActionButton, FormText } from "src/components/formControls";
import QuizData from "./QuizData";
import { Permission as PermissionTypes } from "src/ts/enums";
import { useForm, usePermissions } from "src/hooks";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const filterInitialValues = {
  name: "",
};

function Quiz() {
  let navigate = useNavigate();

  const { values: filterValues, handleInputChange: handleFilterChange } =
    useForm(filterInitialValues, true, []);

  const handleNewAdministrator = () => {
    navigate("/app/quiz/0");
  };
  const [selectdTab, setSelectedTab] = useState(0);
  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };
  const { fullAccess } = usePermissions(PermissionTypes.Quiz);

  return (
    <React.Fragment>
      <Grid container>
        <Grid item xs={9}>
          <Helmet title="Blank" />
          <Typography variant="h3" gutterBottom display="inline">
            Quiz
          </Typography>
          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} to="/">
              Dashboard
            </Link>
            <Typography>Quiz</Typography>
          </Breadcrumbs>
        </Grid>

        <Grid item xs={3} spacing={10} sx={{ paddingBottom: 5 }}>
          <Grid item xs={12}>
            <Stack justifyContent="center" alignItems="flex-end">
              {fullAccess && (
                <FormActionButton
                  mr={2}
                  text="Add Quiz"
                  size="medium"
                  onClick={handleNewAdministrator}
                  isSubmitting={false}
                  type={"add"}
                />
              )}
            </Stack>
          </Grid>
        </Grid>
      </Grid>

      <Divider my={6} />
      <Grid item xs={12}>
        <FormText
          name="name"
          label="Search: Title/Description"
          value={filterValues.name}
          onChange={handleFilterChange}
        />
      </Grid>
      <Divider my={6} />
      <Grid item xs={6}>
        <Tabs
          value={selectdTab}
          onChange={handleChangeTab}
          aria-label="basic tabs example"
        >
          <Tab label="Active" id="true" />
          <Tab label="Inactive" id="false" />
        </Tabs>
      </Grid>

      <Card mb={12}>
        <CardContent>
          {selectdTab === 0 && (
            <QuizData
              refresh={false}
              isActive={true}
              filter={filterValues.name}
            ></QuizData>
          )}
          {selectdTab === 1 && (
            <QuizData
              refresh={false}
              isActive={false}
              filter={filterValues.name}
            ></QuizData>
          )}
        </CardContent>
      </Card>
    </React.Fragment>
  );
}

export default Quiz;
