import React, { useState } from "react";
import Popup from "src/components/Popup";
import { FormCancelButton, FormText } from "src/components/formControls";
import { Button, Stack, Typography } from "@mui/material";
import useAuth from "src/hooks/useAuth";
import { useForm } from "src/hooks";
import { Validator } from "src/ts/types";

interface InformationProps {
  showForgotPasswordPopUp: boolean;
  setShowForgotPasswordPopUp: (val: boolean) => void;
}

const initialValues = {
  email: "",
};

export default function ResetPasswordModal({
  showForgotPasswordPopUp,
  setShowForgotPasswordPopUp,
}: InformationProps) {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { resetPassword } = useAuth();

  const validate = (fieldValues = values) => {
    let temp: Record<string, string> = { ...errors };

    temp.email = new Validator(fieldValues, "email")
      .emailFormat("Insert a correct email: demo@gmail.com")
      ?.toString();

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };
  const { values, errors, setErrors, handleInputChange, resetForm } = useForm(
    initialValues,
    false,
    validate
  );

  const handleSave = () => {
    if (!validate()) return;

    try {
      setIsSubmitting(true);
      resetPassword(values.email);
      resetForm();
      setShowForgotPasswordPopUp(false);
    } catch (error: any) {
      const message = error.message || "Something went wrong";
      setErrors({ submit: message });
      setIsSubmitting(false);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleClosePopUp = () => {
    resetForm();
    setShowForgotPasswordPopUp(false);
  };

  return (
    <>
      <Popup
        title="Forgot Password"
        openPopup={showForgotPasswordPopUp}
        setOpenPopup={setShowForgotPasswordPopUp}
        onClose={handleClosePopUp}
        size="sm"
      >
        <>
          <Stack mb={4}>
            <Typography>
              To reset your password please enter the email associated with the
              account you use to login to Audit Master Pro. A verification code
              will be sent to you. Once you received the link, you will be able
              to choose a new password for your account.
            </Typography>
          </Stack>{" "}
          <FormText
            type="email"
            name="email"
            label="Email Address"
            value={values.email}
            error={errors.email}
            onChange={handleInputChange}
          />
        </>
        <Stack direction="row" spacing={3}>
          <FormCancelButton
            onClick={handleClosePopUp}
            isSubmitting={isSubmitting}
          />
          <Button
            type="submit"
            variant="contained"
            color="primary"
            onClick={handleSave}
          >
            Reset password
          </Button>
        </Stack>
      </Popup>
    </>
  );
}
