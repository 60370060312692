import {
  IAvailable,
  ITechnicianQuizAdd,
  ITechnicianQuizList,
  ITechnicianQuizQuestionsAnswers,
} from "../ts/interfaces/technicianQuiz";
import axios from "../utils/axios";

const getAll = async (isActive: boolean) => {
  return axios.get<ITechnicianQuizList[]>(
    `TechnicianQuiz/ByStatus?isActive=` + isActive
  );
};

const getById = async (id: number) => {
  return axios.get<ITechnicianQuizQuestionsAnswers>(`TechnicianQuiz/` + id);
};

const getByIdNoExam = async (id: number) => {
  return axios.get<ITechnicianQuizList>(`TechnicianQuiz/NoExam?id=` + id);
};

const deleteById = async (id: number) => {
  return axios.delete(`TechnicianQuiz/` + id);
};

const createQuiz = async (item: ITechnicianQuizAdd) => {
  return await axios.post("TechnicianQuiz", item);
};

const changeStatusQuiz = async (id: number, comments: string) => {
  return await axios.put(
    "TechnicianQuiz/EnableDisable?id=" + id + "&comments=" + comments,
    null
  );
};

const updateQuiz = async (id: number, item: ITechnicianQuizAdd) => {
  return await axios.put("TechnicianQuiz/" + id, item);
};

const getAvailable = async () => {
  return await axios.get<IAvailable[]>(`TechnicianQuiz/Available`);
};

const technicianQuizService = {
  getAll,
  deleteById,
  createQuiz,
  getById,
  getByIdNoExam,
  changeStatusQuiz,
  updateQuiz,
  getAvailable,
};

export default technicianQuizService;
