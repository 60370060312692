import { Chip, Stack, Typography } from "@mui/material";
import { StudyStatusEnum } from "src/ts/enums/project";
import { IProjectReporDisplayt } from "src/ts/interfaces";

interface Props {
  row: IProjectReporDisplayt;
}

const StudyStatusComponent = ({ row }: Props) => {
  const statusLabel =
    row?.infectiousControlData !== null &&
    row?.infectiousControlData?.studyStatusId >= 6
      ? "Compliance Status:"
      : "Status: ";
  return (
    <>
      <Stack direction="row">
        <Typography pr="2px">{statusLabel}</Typography>
        {row.studyStatus !== "Complete" &&
          ((row?.infectiousControlData?.studyStatusId ?? 0) < 6 ||
            row?.infectiousControlData?.studyStatusId == null) && (
            <Chip
              label={row.studyStatus}
              size="small"
              color={row.studyStatus === "New" ? "primary" : "default"}
            />
          )}
        {row.studyStatus === "Complete" &&
          (row?.infectiousControlData?.studyStatusId ?? 0) < 6 && (
            <Chip label={row.studyStatus} size="small" color="success" />
          )}
        {/* {row?.infectiousControlData?.studyStatusId ===
          StudyStatusEnum.Working && (
          <Chip label="Complete" size="small" color="info" />
        )} */}
        {row?.infectiousControlData?.studyStatusId ===
          StudyStatusEnum.InReview && (
          <Chip label="In review" size="small" color="info" />
        )}
        {row?.infectiousControlData?.studyStatusId ===
          StudyStatusEnum.Approved && (
          <Chip label="Approved" size="small" color="success" />
        )}
        {row?.infectiousControlData?.studyStatusId ===
          StudyStatusEnum.ApprovedButNotCompliant && (
          <Chip label="Not Compliant" size="small" color="success" />
        )}
        {row?.infectiousControlData?.studyStatusId ===
          StudyStatusEnum.Rejected && (
          <Chip label="Rejected" size="small" color="warning" />
        )}
        {row?.infectiousControlData?.studyStatusId ===
          StudyStatusEnum.Appeal && (
          <Chip label="Appeal" size="small" color="primary" />
        )}
        {row?.infectiousControlData?.studyStatusId ===
          StudyStatusEnum.FinalReject && (
          <Chip label="Final Reject" size="small" color="error" />
        )}
      </Stack>
    </>
  );
};

export default StudyStatusComponent;
