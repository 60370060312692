import axios from "src/utils/axios";
import { IStarterMfgDTO } from "src/ts/interfaces/catalogs";

const getStarterMfgs = () => {
  return axios.get<IStarterMfgDTO[]>("StarterMfg");
};

const starterMfgService = {
  getStarterMfgs,
};

export default starterMfgService;
