import { IEquipment } from "src/ts/interfaces";
import axios from "../utils/axios";

const post = async (projectId: number, allEquipments: IEquipment[]) => {
  return axios.post(`EquipmentProject?ProjectId=${projectId}`, allEquipments);
};

const equipmentProjectService = {
  post,
};

export default equipmentProjectService;
