import axios from "../utils/axios";

const remove = (projectReportId: number) => {
  return axios.delete(`ReportFile/remove?projectReportId=${projectReportId}`);
};

const reportFileService = {
  remove,
};

export default reportFileService;
