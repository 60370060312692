import { useEffect, useState } from "react";
import { useAsyncQuery, useAuth, useLog, usePermissions } from "src/hooks";
import { Grid, Skeleton, Stack, Typography } from "@mui/material";
import { IReportGroup } from "src/ts/interfaces";
import projectReportGroupService from "src/services/ProjectReportGroupService";
import Study from "../../projectStudies/components/Study";
import { ProjectDTO } from "src/ts/interfaces/project/projectDto";
import getProjectSingleService from "src/services/project/getProjectSingleService";
import { FormButton } from "src/components/formControls";
import templateServices from "src/services/templateServices";
import { useNavigate } from "react-router-dom";
import UseMyPreferredTemplatePopUp from "../../projectStudies/components/UseMyPreferredTemplatePopUp";
import WithoutPreferredTemplatePopUp from "../../projectStudies/components/WithoutPreferredTemplatePopUp";
import SelectTemplatePopUp from "../../projectStudies/components/SelectTemplatePopUp";
import FormBackButton from "src/components/formControls/FormBackButton";
import FormNextButton from "src/components/formControls/FormNextButton";
import { Permission as PermissionTypes } from "src/ts/enums";
interface StudiesListProjectProps {
  projectResponse: number;
}

export default function StudiesListProject({
  projectResponse,
}: StudiesListProjectProps) {
  const navigate = useNavigate();
  const { user } = useAuth();
  const { log } = useLog();
  const [useMyPreferredTemplateShowPopUp, setUseMyPreferredTemplateShowPopUp] =
    useState(false);
  const [selectTemplateShowPopUp, setSelectTemplateShowPopUp] = useState(false);
  const [withoutPreferredTemplate, setWithoutPreferredTemplate] =
    useState(false);

  const { execute, data } = useAsyncQuery<IReportGroup>(
    projectReportGroupService.getProjectReportGrupo
  );

  useEffect(() => {
    execute(projectResponse);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    execute: executaProject,
    data: dataProject,
    setData: setDataProject,
    isLoading: isLoadingProject,
  } = useAsyncQuery<ProjectDTO>(getProjectSingleService.getById);

  const { execute: preferredName, data: preferredNameData } =
    useAsyncQuery<string>(templateServices.getPreferredName);
  const { fullAccess } = usePermissions(PermissionTypes.Project);

  useEffect(() => {
    const data = async () => {
      await execute(projectResponse);
      await executaProject(projectResponse);
      await preferredName();
    };
    data();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const skeletonCount = 10;
  const skeletons = Array.from({ length: skeletonCount }, (_, index) => (
    <Skeleton
      key={index}
      width={1500}
      height={25}
      style={{ marginTop: "5px" }}
    />
  ));

  const modalPreferredTemplateHandler = () => {
    if (preferredNameData !== "") {
      setUseMyPreferredTemplateShowPopUp(true);
    } else {
      setWithoutPreferredTemplate(true);
    }
  };

  const disableTemplate = dataProject && dataProject.templateWasUsed;

  return (
    <>
      {dataProject && dataProject.templateName !== null && (
        <Stack textAlign={"center"} mb={5}>
          <Typography variant="h3">
            Template applied: {`${dataProject.templateName}`}
          </Typography>
        </Stack>
      )}
      {data && !isLoadingProject ? (
        <>
          {data.groupType1 !== null && (
            <Typography variant="h4">Configurable Studies</Typography>
          )}
          <Grid container xs={12} spacing={5} item>
            {data.groupType1 !== null &&
              data.groupType1.map((item) => (
                <Grid item xs={12} md={4} key={item.id}>
                  <Study
                    study={item}
                    isSelectable={true}
                    readOnly={true}
                    groupId={1}
                    projectId={projectResponse}
                    disable={
                      disableTemplate ||
                      dataProject?.templateWasUsed ||
                      !fullAccess
                    }
                  />
                </Grid>
              ))}
          </Grid>
          {data.groupType3 !== null && (
            <Typography variant="h4" mt={5}>
              Non-Configurable Studies
            </Typography>
          )}
          <Grid container xs={12} spacing={5} item>
            {data.groupType3 !== null &&
              data.groupType3.map((item, index) => (
                <Grid item xs={12} md={4} key={item.id}>
                  <Study
                    study={item}
                    isSelectable={true}
                    readOnly={true}
                    groupId={3}
                    projectId={projectResponse}
                  />
                </Grid>
              ))}
          </Grid>
          {dataProject && !dataProject.isInfectiousControlProject && (
            <Grid container justifyContent={"center"} mt={4}>
              <Stack direction={"row"} spacing={2} mt={2}>
                <FormButton
                  text="Use My Preferred Template"
                  size="small"
                  onClick={modalPreferredTemplateHandler}
                  disabled={disableTemplate || !fullAccess}
                />
                <FormButton
                  text="Use Template"
                  size="small"
                  onClick={() => {
                    setSelectTemplateShowPopUp(true);
                  }}
                  disabled={disableTemplate || !fullAccess}
                />
              </Stack>
            </Grid>
          )}

          <Grid container justifyContent={"center"} mt={6}>
            <Stack
              direction={"row"}
              spacing={4}
              mt={5}
              justifyContent={"center"}
            >
              <FormBackButton
                size="small"
                text="Previous Step"
                color="primary"
                onClick={() => {
                  navigate(
                    `/app/TolerancesWizard/Edit/${dataProject?.id}/${user?.companyId}`
                  );
                }}
                isSubmitting={false}
              />
              <FormButton
                size="small"
                text="Close"
                variant="outlined"
                onClick={() => {
                  navigate(
                    `/app/ProjectArchitect/${dataProject?.companyId}/all`
                  );
                }}
              />
              <FormNextButton
                size="small"
                text="Next Step"
                color="primary"
                onClick={() => {
                  navigate(
                    `/app/ConfigurationWizard/Edit/${dataProject?.id}/${user?.companyId}`
                  );
                  log.success("The information was saved succesfully");
                }}
                isSubmitting={false}
              />
            </Stack>
          </Grid>
        </>
      ) : (
        skeletons
      )}
      {useMyPreferredTemplateShowPopUp && (
        <UseMyPreferredTemplatePopUp
          setUseMyPreferredTemplateShowPopUp={
            setUseMyPreferredTemplateShowPopUp
          }
          useMyPreferredTemplateShowPopUp={useMyPreferredTemplateShowPopUp}
          projectId={dataProject?.id}
          setSelectTemplateShowPopUp={setSelectTemplateShowPopUp}
          project={dataProject}
          setProject={setDataProject}
        />
      )}
      {withoutPreferredTemplate && (
        <WithoutPreferredTemplatePopUp
          setWithoutPreferredTemplate={setWithoutPreferredTemplate}
          withoutPreferredTemplate={withoutPreferredTemplate}
        />
      )}
      {selectTemplateShowPopUp && (
        <SelectTemplatePopUp
          setSelectTemplateShowPopUp={setSelectTemplateShowPopUp}
          selectTemplateShowPopUp={selectTemplateShowPopUp}
          projectId={dataProject?.id}
          project={dataProject}
          setProject={setDataProject}
        />
      )}
    </>
  );
}
