import axios from "src/utils/axios";

import {
  IChiller,
  IChillerCompressor,
  IChillerEvaporatorCondenser,
  IChillerTowerCondenser,
} from "src/ts/interfaces";
import { IChillerTowerFanMotor } from "src/ts/interfaces/study/tower";

const getById = (id: number) => {
  return axios.get<IChiller>(`Chiller/${id}`);
};

const update = (id: number, chiller: IChiller) => {
  return axios.put<IChiller>(`Chiller/${id}`, chiller);
};

const evaporators = (chillerId: number) => {
  return axios.get<IChillerEvaporatorCondenser[]>(
    `Chiller/evaporators?reportId=${chillerId}`
  );
};

const towerCondensers = (chillerId: number) => {
  return axios.get<IChillerTowerCondenser[]>(
    `Chiller/towerCondensers?reportId=${chillerId}`
  );
};

const updateEvaporator = (
  evaporatoreId: number,
  evaporator: IChillerEvaporatorCondenser
) => {
  return axios.put<IChillerEvaporatorCondenser>(
    `Chiller/evaporator/${evaporatoreId}`,
    evaporator
  );
};

const getEvaporatorById = (evaporatorId: number) => {
  return axios.get<IChillerEvaporatorCondenser>(
    `Chiller/evaporator/${evaporatorId}`
  );
};

const getCompressorsByEvaporatorId = (evaporatorId: number) => {
  return axios.get<IChillerCompressor[]>(
    `Chiller/compressors?reportId=${evaporatorId}`
  );
};

const getFanMotorByTowerCondenserId = (towerCondenserId: number) => {
  return axios.get<IChillerTowerFanMotor[]>(
    `Chiller/fanMotors?reportId=${towerCondenserId}`
  );
};

const getFanMotorById = (fanMotorId: number) => {
  return axios.get<IChillerTowerFanMotor>(`Chiller/fanMotor?id=${fanMotorId}`);
};

const getTowerCondenserById = (towerCondenserId: number) => {
  return axios.get<IChillerTowerCondenser>(
    `Chiller/towerCondenser/${towerCondenserId}`
  );
};

const getCompressorById = (compressorId: number) => {
  return axios.get<IChillerCompressor>(`Chiller/compressor?id=${compressorId}`);
};

const updateCompressor = (
  compressorId: number,
  compressor: IChillerCompressor
) => {
  return axios.put<IChillerCompressor>(
    `Chiller/compressor/${compressorId}`,
    compressor
  );
};

const deleteCompressor = (compressorId: number) => {
  return axios.delete(`Chiller/compressor/${compressorId}`);
};

const deleteFanmotor = (compressorId: number) => {
  return axios.delete(`Chiller/fanMotor/${compressorId}`);
};

const addCompressor = (compressor: IChillerCompressor) => {
  return axios.post<IChillerCompressor>("Chiller/compressor", compressor);
};

const addFanMotor = (fanMotor: IChillerTowerFanMotor) => {
  return axios.post<IChillerTowerFanMotor>("Chiller/fanMotor", fanMotor);
};

const updateTowerCondenser = (
  towerCondenserId: number,
  towerCondenser: IChillerTowerCondenser
) => {
  return axios.put<IChillerTowerCondenser>(
    `Chiller/towerCondenser/${towerCondenserId}`,
    towerCondenser
  );
};

const updateFanMotor = (
  fanMotorId: number,
  fanMotor: IChillerTowerFanMotor
) => {
  return axios.put<IChillerTowerFanMotor>(
    `Chiller/fanMotor/${fanMotorId}`,
    fanMotor
  );
};

const updateTowerFormula = (
  towerCondenserId: number,
  towerCondenser: IChillerTowerCondenser
) => {
  return axios.put<IChillerTowerCondenser>(
    `Chiller/towerFormula/${towerCondenserId}`,
    towerCondenser
  );
};

const evaporatorFormula = (towerCondenserId: number) => {
  return axios.put<IChillerEvaporatorCondenser>(
    `Chiller/evaporatorFormula/${towerCondenserId}`
  );
};

const chillerService = {
  getById,
  update,
  evaporators,
  towerCondensers,
  //
  getEvaporatorById,
  getCompressorsByEvaporatorId,
  getCompressorById,
  getFanMotorByTowerCondenserId,
  getTowerCondenserById,
  getFanMotorById,
  //
  addCompressor,
  addFanMotor,
  //
  updateEvaporator,
  updateCompressor,
  updateTowerCondenser,
  updateFanMotor,
  updateTowerFormula,
  evaporatorFormula,
  //
  deleteCompressor,
  deleteFanmotor,
};

export default chillerService;
