import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { spacing, SpacingProps } from "@mui/system";
import { Chip as MuiChip } from "@mui/material";
import { ITechnicianQuizAnswers } from "src/ts/interfaces";
import useLoading from "src/hooks/useLoading";
import { technicianQuizService } from "src/services";
import { ITechnicianQuizQuestions } from "src/ts/interfaces/technicianQuizQuestions";
import { ColumnType } from "src/types/enhancedTable";
import LocalEnhancedTable from "src/components/localTable/LocalTable";
import FormTextEditor from "src/components/formControls/FormTextEditor";
import { useLog } from "src/hooks";

interface ChipProps extends SpacingProps {
  component?: React.ElementType;
  href?: string;
  icon?: JSX.Element | null;
}

const Chip = styled(MuiChip)<ChipProps>(spacing);

interface questionsExamProps {
  quizId: number;
}

function answers(answers: ITechnicianQuizAnswers[]) {
  return answers.map((item, index) => {
    return (
      <>
        <b>{index + 1 + ") "}</b>
        <Chip
          label={item.description}
          color={item.isCorrect ? "success" : "error"}
          variant="outlined"
          m={1}
          size="small"
        />
        <br></br>
      </>
    );
  });
}

const getColumns = (): ColumnType[] => [
  {
    id: "questiob",
    label: "Question",
    type: "custom",
    sort: true,
    callback: (row: ITechnicianQuizQuestions) => {
      return (
        <>
          <FormTextEditor
            value={row.technicianQuizBacklog.description}
            onChange={function (e: any): void {
              throw new Error("Function not implemented.");
            }}
            name={"description" + row.id}
            readonly={true}
          ></FormTextEditor>
          {answers(row.technicianQuizBacklog.technicianQuizAnswers)}
        </>
      );
    },
  },
];

function QuestionsExam(props: questionsExamProps) {
  const { log } = useLog();
  const { startRequest, endRequest } = useLoading();
  let columns = getColumns();
  const [dataSet, setDataSet] = useState<ITechnicianQuizQuestions[]>([]);

  useEffect(() => {
    const getData = async () => {
      try {
        startRequest("ExamenQuestions");
        const request = await technicianQuizService.getById(props.quizId);
        setDataSet(request.data.technicianQuizQuestions);
        endRequest("ExamenQuestions");
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
        endRequest("ExamenQuestions");
      }
    };

    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <LocalEnhancedTable<ITechnicianQuizQuestions>
      columns={columns}
      data={dataSet}
      defaultRowPerPage={10}
    />
  );
}

export default QuestionsExam;
