import axios from "src/utils/axios";

const createStudies = (url: string, projectData: any) => {
  return axios.post(url, projectData);
};

const commonStudyService = {
  createStudies,
};

export default commonStudyService;
