import { Stack } from "@mui/material";
import { useEffect, useState } from "react";
import { FormButton } from "src/components/formControls";
import LocalEnhancedTable from "src/components/localTable/LocalTable";
import Popup from "src/components/Popup";
import { useLog } from "src/hooks";
import permissionUsersService from "src/services/permissionUsersService";
import { IAdministratorsData } from "src/ts/interfaces";
import { ColumnType } from "src/types/enhancedTable";
import { formatPhoneNumber } from "src/utils/format";

interface SuggestionbankProps {
  showList: boolean;
  setShowList: (val: boolean) => void;
}

const AdministratorsSuggestionBankPopUp = (props: SuggestionbankProps) => {
  const { showList, setShowList } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [adminsData, setAdminsData] = useState<IAdministratorsData[]>([]);

  const { log } = useLog();

  const getColumns = (): ColumnType[] => [
    {
      id: "id",
      label: "Admin ID",
      type: "string",
      sort: true,
    },
    {
      id: "name",
      label: "Name",
      type: "custom",
      sort: true,
      callback: (row: IAdministratorsData) => {
        return row.name + " " + row.lastName;
      },
    },
    {
      id: "phone",
      label: "Phone",
      type: "custom",
      sort: true,
      callback: (row: IAdministratorsData) => formatPhoneNumber(row.phone),
    },
    {
      id: "email",
      label: "Email",
      type: "custom",
      sort: true,
      callback: (row: IAdministratorsData) => {
        var input = row.email;
        var fields = input.split("@");
        return (
          <>
            <Stack>{fields[0]}@</Stack>
            <Stack>{fields[1]}</Stack>
          </>
        );
      },
    },
  ];

  let columns = getColumns();

  useEffect(() => {
    const getData = async () => {
      try {
        setIsLoading(true);
        const request =
          await permissionUsersService.getAdministratorsSuggestionBank();
        setAdminsData(request.data);
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      } finally {
        setIsLoading(false);
      }
    };

    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const cancelHandler = () => {
    setShowList(false);
  };

  return (
    <Popup
      openPopup={showList}
      setOpenPopup={setShowList}
      title={"Suggestion Bank Committee"}
      onClose={cancelHandler}
      size="md"
    >
      <Stack>
        <LocalEnhancedTable<IAdministratorsData>
          refreshGrid={true}
          columns={columns}
          data={adminsData}
          showSkeleton={isLoading}
        />
      </Stack>
      <>
        <Stack
          direction="row"
          spacing={2}
          justifyContent="center"
          alignItems="center"
        >
          <FormButton
            onClick={cancelHandler}
            text="Close"
            variant="outlined"
            size="medium"
          />
        </Stack>
      </>
    </Popup>
  );
};

export default AdministratorsSuggestionBankPopUp;
