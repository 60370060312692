import {
  IKeyValue,
  ISystemCostsCatalog,
  IUploadFile,
  keyValuePrice,
} from "../ts/interfaces";
import axios from "../utils/axios";

const getByClassification = (classification: string) => {
  return axios.get<ISystemCostsCatalog[]>(
    `SystemCostsCatalog?classification=${classification}`
  );
};

const getAll = () => {
  return axios.get<ISystemCostsCatalog[]>("SystemCostsCatalog/Status");
};

const update = (cost: ISystemCostsCatalog) => {
  return axios.put(`SystemCostsCatalog/${cost.id}`, cost);
};

const downloadFile = async (systemCostId: number) => {
  return axios.get(`SystemCostsCatalog/file?id=${systemCostId}`);
};

const uploadFile = async (file: IUploadFile, id: number) => {
  let bodyFormData = new FormData();

  bodyFormData.append("fileForUpload[0]", file.content);
  bodyFormData.append("key[itemId]", id.toString());
  bodyFormData.append("key[catalogName]", "SystemCostCatalog");

  return await axios({
    method: "post",
    url: "/SystemCostsCatalog/document",
    data: bodyFormData,
    headers: { "Content-Type": "multipart/form-data" },
  });
};

const getKeyValuePrice = () => {
  //return axios.get("/SystemCostsCatalog/keyValuePrice");
  return new Promise<IKeyValue<number, string>[]>((resolve, reject) => {
    axios
      .get<Array<keyValuePrice>>("/SystemCostsCatalog/keyValuePrice")
      .then((response) => {
        const keyValuePair: IKeyValue<number, string>[] = response.data.map(
          (item) => {
            return {
              key: item.id ?? 0,
              value: item.name,
            };
          }
        );

        resolve(keyValuePair);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const getKeyValuesData = () => {
  return axios.get("/SystemCostsCatalog/keyValuePrice");
};

const GetCovidProjectCosts = () => {
  return axios.get<ISystemCostsCatalog[]>(
    "/SystemCostsCatalog/GetCovidProjectCosts"
  );
};

const getStorageValues = async (classification: string) => {
  return axios.get(`SystemCostsCatalog?classification=${classification}`);
};

const GetTechniciansCosts = () => {
  return axios.get("SystemCostsCatalog/GetTechniciansCosts");
};

const GetCostToUpgrade = () => {
  return axios.get("SystemCostsCatalog/GetCostToUpgrade");
};

const systemCostCatalogService = {
  getKeyValuesData,
  getKeyValuePrice,
  getAll,
  getByClassification,
  update,
  downloadFile,
  uploadFile,
  GetCovidProjectCosts,
  getStorageValues,
  GetTechniciansCosts,
  GetCostToUpgrade,
};

export default systemCostCatalogService;
