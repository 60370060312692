import {
  IPostHistoryStatusComments,
  IProjectHistoryStatusComments,
} from "src/ts/interfaces";
import axios from "src/utils/axios";

const getProjectHistoryStatusComments = (projectId: number) => {
  return axios.get<IProjectHistoryStatusComments[]>(
    `ProjectHistoryStatusComments?projectId=${projectId}`
  );
};

const getRejectedProjectHistoryStatusComments = (projectId: number) => {
  return axios.get<boolean>(
    `ProjectHistoryStatusComments/ProjectIsRejected?Id=${projectId}`
  );
};

const postProjectHistoryStatusComments = async (
  data: IPostHistoryStatusComments
) => {
  return axios.post(`ProjectHistoryStatusComments`, data);
};

const projectHistoryStatusCommentsService = {
  getProjectHistoryStatusComments,
  postProjectHistoryStatusComments,
  getRejectedProjectHistoryStatusComments,
};

export default projectHistoryStatusCommentsService;
