import {
  CardContent,
  Grid,
  Divider as MuiDivider,
  Card as MuiCard,
} from "@mui/material";
import { spacing } from "@mui/system";
import styled from "@emotion/styled";
import { useAsyncQuery, useAuth, useEffectOnce, useLog } from "src/hooks";
import { useParams } from "react-router";
import { useMemo, useState } from "react";
import { IReportType } from "src/ts/interfaces";
import { IProjectReport } from "src/ts/interfaces/project/projectDto";
import projectReportService from "src/services/study/projectReportService";
import reportTypeService from "src/services/study/reportTypeService";
import StudyWizard from "../../components/StudyWizard";
import { FooterButtons } from "../../components/FooterButtons";
import { reportTypeId, steps, studyTypeCode } from "../const/const";
import { useNavigate } from "react-router-dom";
import { FormCheckSwitch } from "src/components/formControls";
import useTowerStudy from "../hooks/useTowerStudy";
import { Mode } from "src/ts/types";
import {
  IEnergySourceSubtracted,
  ISourceSoundTest,
} from "src/ts/interfaces/catalogs";
import energySourceSubtractedService from "src/services/study/energySourceSubtractedService";
import sourceSoundTestService from "src/services/study/sourceSoundTestService";
import EnergySourceSubtractedTabs from "./components/EnergySourceSubtractedTabs";
import SourceSoundTestGrid from "./components/SourceSoundTestGrid";
import DialogMessagePopup from "src/components/DialogMessagePopup";
import studiesHistoryService from "src/services/study/studiesHistoryService";
import HeaderStudyPage from "src/components/page/HeaderStudyPage";

const Divider = styled(MuiDivider)(spacing);
const Card = styled(MuiCard)(spacing);
const activeStep = 4;

const TowerStep5 = () => {
  const {
    getTowerStudy,
    towerValues,
    handleInputTowerChange,
    updateTowerStudy,
  } = useTowerStudy();

  const { user } = useAuth();
  const { log } = useLog();
  const params = useParams<{ id: string }>();
  const id = parseInt(params?.id === undefined ? "0" : params?.id);
  let navigate = useNavigate();

  const [showConfirmDeletePopup, setShowConfirmDeletePopup] = useState(false);
  const [showInformationModal, setShowInformationModal] = useState(false);

  const [reportType, setReportType] = useState<IReportType | null>();
  const { execute: executeReport, data: dataReport } =
    useAsyncQuery<IProjectReport>(projectReportService.report);

  const [energySourceSubtractedItems, setEnergySourceSubtractedItems] =
    useState<IEnergySourceSubtracted[]>([]);

  const [energySourceSubtractedSelected, setEnergySourceSubtractedSelected] =
    useState<IEnergySourceSubtracted | null>(null);

  const [sourceSoundTestItems, setSourceSoundTestItems] = useState<
    ISourceSoundTest[]
  >([]);

  const getEnergySourceSubtracted = async () => {
    const energySourceSubtractedItemsRes =
      await energySourceSubtractedService.getByReportIdAndType(
        id,
        reportTypeId
      );

    if (energySourceSubtractedItemsRes.data.length === 0) {
      const tempEnergySourceSubtracted: any = {
        reportId: id,
        reportTypeId: reportTypeId,
      };

      const addeEnergySourceRes = await energySourceSubtractedService.add(
        tempEnergySourceSubtracted
      );

      const tempSourceSoundTest: any = {
        energySourceSubtractedId: addeEnergySourceRes.data.id,
      };

      const addedSourceSoundRes = await sourceSoundTestService.add(
        tempSourceSoundTest
      );

      setEnergySourceSubtractedItems([addeEnergySourceRes.data]);
      setEnergySourceSubtractedSelected(addeEnergySourceRes.data);
      setSourceSoundTestItems([addedSourceSoundRes.data]);
    } else {
      setEnergySourceSubtractedItems(energySourceSubtractedItemsRes.data);
      const sourceSoundTestItemsRes =
        await sourceSoundTestService.getByEnergySourceSubtractedId(
          energySourceSubtractedItemsRes.data[0].id
        );

      if (sourceSoundTestItemsRes.data.length === 0) {
        const tempSourceSoundTest: any = {
          energySourceSubtractedId: energySourceSubtractedItemsRes.data[0].id,
        };

        const addedSourceSoundRes = await sourceSoundTestService.add(
          tempSourceSoundTest
        );
        setSourceSoundTestItems([addedSourceSoundRes.data]);
      } else {
        setSourceSoundTestItems(sourceSoundTestItemsRes.data);
      }
      setEnergySourceSubtractedSelected(energySourceSubtractedItemsRes.data[0]);
    }
  };
  const [isLoading, setIsLoading] = useState(false);
  useEffectOnce(() => {
    const getData = async () => {
      try {
        setIsLoading(true);
        await getTowerStudy(id);

        const reportTypeRes = await reportTypeService.getReportTypeByCode(
          studyTypeCode
        );
        setReportType(reportTypeRes.data);
        await executeReport(id, reportTypeRes.data.code);
        await getEnergySourceSubtracted();
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      } finally {
        setIsLoading(false);
      }
    };

    if (id > 0) getData();
  });

  const mode: Mode = towerValues?.isComplete ? "read" : "read&Write";

  const disableStudy = useMemo(() => {
    return (
      towerValues?.isComplete ||
      (dataReport?.isInUse && dataReport?.isInUseById !== user?.userId) ||
      dataReport?.isPartOfProject === false
    );
  }, [
    dataReport?.isInUse,
    dataReport?.isInUseById,
    dataReport?.isPartOfProject,
    user?.userId,
    towerValues?.isComplete,
  ]);

  const nextStepHandler = async () => {
    const route = `/studies/Tower/step6/${id}`;
    if (disableStudy) {
      navigate(route);
      return;
    }
    await save();

    navigate(route);
  };

  const previousStepHandler = async () => {
    const route = `/studies/Tower/step4/${id}`;
    if (disableStudy) {
      navigate(route);
      return;
    }
    await save();
    navigate(route);
  };

  const selectedEnergy = async (
    energySourceSelected: IEnergySourceSubtracted
  ) => {
    if (!towerValues.isComplete && !disableStudy) {
      await energySourceSubtractedService.updateAll(
        energySourceSubtractedItems
      );
      log.info("Report was saved");
    }

    const sourceSoundItemsRes =
      await sourceSoundTestService.getByEnergySourceSubtractedId(
        energySourceSelected?.id!
      );

    if (sourceSoundItemsRes.data.length === 0) {
      const tempSourceSoundTest: any = {
        energySourceSubtractedId: energySourceSelected.id,
      };
      const addedSourceSoundTestRes = await sourceSoundTestService.add(
        tempSourceSoundTest
      );
      setSourceSoundTestItems([addedSourceSoundTestRes.data]);
    } else {
      setSourceSoundTestItems(sourceSoundItemsRes.data);
      setEnergySourceSubtractedSelected(energySourceSelected);
    }
  };

  const addEnergySourceHandler = async () => {
    const tempEnergySourceSubtracted: any = {
      reportId: towerValues.id,
      reportTypeId: reportTypeId,
    };

    const addedEnergySourceRes = await energySourceSubtractedService.add(
      tempEnergySourceSubtracted
    );

    const tempSourceSoundTest: any = {
      energySourceSubtractedId: addedEnergySourceRes.data.id,
    };

    const addedSourceSoundRes = await sourceSoundTestService.add(
      tempSourceSoundTest
    );

    const newEnergySourceItems = [...energySourceSubtractedItems];
    newEnergySourceItems.push(addedEnergySourceRes.data);
    setEnergySourceSubtractedItems(newEnergySourceItems);
    setSourceSoundTestItems([addedSourceSoundRes.data]);
    selectedEnergy(newEnergySourceItems[newEnergySourceItems.length - 1]);
  };

  const deleteSoundTestConfirmationHandler = () => {
    setShowConfirmDeletePopup(true);
  };

  const deleteEnergySourceHandler = async () => {
    if (
      energySourceSubtractedSelected == null ||
      energySourceSubtractedItems.length <= 1
    )
      return;

    const index = energySourceSubtractedItems.findIndex(
      (item) => item.id === energySourceSubtractedSelected.id
    );

    if (index < 0) return;

    await energySourceSubtractedService.remove(
      energySourceSubtractedSelected?.id
    );

    const newEnergySourceItems = [...energySourceSubtractedItems];

    newEnergySourceItems.splice(index, 1);
    setEnergySourceSubtractedItems(newEnergySourceItems);

    const sourceSoundItemsRes =
      await sourceSoundTestService.getByEnergySourceSubtractedId(
        newEnergySourceItems[0]?.id!
      );

    setSourceSoundTestItems(sourceSoundItemsRes.data);
    setEnergySourceSubtractedSelected(newEnergySourceItems[0]);

    setShowConfirmDeletePopup(false);
  };

  const changeEnergySourceSubtractedIsolatedHandler = (e: any) => {
    const energySourceSubtractedIsolated = e.target.value;
    if (energySourceSubtractedIsolated) {
      setShowInformationModal(true);
    }
    handleInputTowerChange(e);
  };

  const saveHandler = async () => {
    await save();
  };

  const save = async () => {
    await updateTowerStudy();
    await studiesHistoryService.save(
      towerValues.projectId,
      dataReport.reportTypeId,
      towerValues.id,
      "saved"
    );
    await sourceSoundTestService.updateAll(sourceSoundTestItems);
    await energySourceSubtractedService.updateAll(energySourceSubtractedItems);
    await getEnergySourceSubtracted();
    log.info("Report was saved");
  };

  return (
    <>
      <HeaderStudyPage
        headerStudiesPage={{
          code: "TOWE",
          system: towerValues?.system,
          id: id,
        }}
        parentText="Project"
        parentLink="/"
      />
      <Divider my={6} />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <StudyWizard
            steps={steps}
            activeStep={activeStep}
            projectId={dataReport?.projectId}
            reportId={dataReport?.reportId}
            reportTypeId={dataReport?.reportTypeId}
          />
        </Grid>
        <Grid item xs={12}>
          <Card mb={1}>
            <CardContent>
              <Grid container spacing={5}>
                <Grid item xs={12}>
                  <FormCheckSwitch
                    label="Energy Source Subtracted/Isolated"
                    name="energySourceSubtractedIsolated"
                    value={towerValues.energySourceSubtractedIsolated}
                    onChange={changeEnergySourceSubtractedIsolatedHandler}
                    mode={mode}
                    disabled={disableStudy}
                    showSkeleton={isLoading}
                  />{" "}
                  {towerValues.energySourceSubtractedIsolated && (
                    <>← Isolating background noise from subject target.</>
                  )}
                </Grid>
                {towerValues.energySourceSubtractedIsolated && (
                  <>
                    <Grid item xs={12}>
                      <EnergySourceSubtractedTabs
                        energySourceSubtractedItems={
                          energySourceSubtractedItems
                        }
                        energySourceSubtractedSelected={
                          energySourceSubtractedSelected
                        }
                        setEnergySourceSubtractedSelected={
                          setEnergySourceSubtractedSelected
                        }
                        selectedEnergy={selectedEnergy}
                        onAddEnergySourceHandler={addEnergySourceHandler}
                        onDeleteSoundTestConfirmation={
                          deleteSoundTestConfirmationHandler
                        }
                        studyIsCompleted={towerValues?.isComplete}
                        disabledStudy={disableStudy}
                      />
                    </Grid>

                    {energySourceSubtractedSelected && (
                      <>
                        <Grid item xs={12}>
                          <SourceSoundTestGrid
                            sourceSoundTestItems={sourceSoundTestItems}
                            setSourceSoundTestItems={setSourceSoundTestItems}
                            energySourceSubtractedSelected={
                              energySourceSubtractedSelected
                            }
                            energySourceSubtractedItems={
                              energySourceSubtractedItems
                            }
                            setEnergySourceSubtractedItems={
                              setEnergySourceSubtractedItems
                            }
                            mode={mode}
                            studyDisabled={disableStudy}
                            isLoading={isLoading}
                            reportId={towerValues.id}
                            reportTypeId={reportTypeId}
                          />
                        </Grid>
                      </>
                    )}
                  </>
                )}

                <Grid item xs={12}>
                  <FooterButtons
                    activeStep={activeStep}
                    stepsCount={steps.length}
                    projectId={dataReport?.projectId}
                    companyId={user?.companyId as number}
                    isSaving={false}
                    disabled={false}
                    reportName={reportType?.name ?? ""}
                    previousStepHandler={previousStepHandler}
                    saveHandler={saveHandler}
                    nextStepHandler={nextStepHandler}
                    hideSaveButton={disableStudy}
                    isComplete={towerValues?.isComplete ?? true}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <DialogMessagePopup
        title={"Delete Confirmation"}
        text="Are you certain you want to delete the selected Energy Source Sound Test?"
        showPopup={showConfirmDeletePopup}
        setShowPopup={setShowConfirmDeletePopup}
        onSave={deleteEnergySourceHandler}
        onCancel={() => {
          setShowConfirmDeletePopup(false);
        }}
        isSubmitting={false}
        disableClickOutside={true}
      />
      ,
      <DialogMessagePopup
        title={"Warning"}
        text="For SUBTRACTING background noise-testing purposes, this procedure should be used only when the total noise exceeds the background noise by 3 dB or more."
        showPopup={showInformationModal}
        setShowPopup={setShowInformationModal}
        onSave={() => setShowInformationModal(false)}
        hideCancel={true}
        isSubmitting={false}
        disableClickOutside={true}
      />
    </>
  );
};

export default TowerStep5;
