import React, { useEffect, useState } from "react";
import { Grid, Link, Stack, Typography } from "@mui/material";
import { useAsyncMutation, useAsyncQuery, useForm } from "src/hooks";
import FormTitleBox from "src/components/formControls/FormTitleBox";
import {
  FormAcceptButton,
  FormCancelButton,
  FormCheckBox,
  FormMaskText,
  FormNumeric2,
  FormSelect,
} from "src/components/formControls";
import FormNextButton from "src/components/formControls/FormNextButton";
import FormBackButton from "src/components/formControls/FormBackButton";
import signUpService from "src/services/signUpService";
import { IPostCellPhoneNumberVerificationRequest } from "src/ts/interfaces/signup";
import { userService } from "src/services";
import { Validator } from "src/ts/types";

const initialValues: IPostCellPhoneNumberVerificationRequest = {
  countryCode: 0,
  cellPhoneNumber: "",
  type: "sms",
  code: null,
};

interface Props {
  setModalCancel?: React.Dispatch<React.SetStateAction<boolean>>;
  handleNext: (cardConfirm: boolean) => Promise<void>;
  handleBack?: () => Promise<void>;
}
function IdentityConfirmation({
  setModalCancel,
  handleNext,
  handleBack,
}: Props) {
  const [codeSend, setCodeSend] = useState<boolean>(false);
  const [acceptTerms, setAcceptTerms] = useState<boolean>(false);
  const validate = (fieldValues = values) => {
    let temp: Record<string, string> = { ...errors };
    temp.cellPhoneNumber = new Validator(values, "cellPhoneNumber")
      .isRequired("The User's cell phone is required.")
      .phoneFormat("Incorrect phone format.")
      .toString();
    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };
  const { values, setValues, errors, setErrors, handleInputChange } = useForm(
    initialValues,
    true,
    validate
  );

  const { execute: executeLoadData } = useAsyncQuery(
    userService.getForContactInfo,
    {
      hideSuccessfulMessage: true,
      hideErrorMessage: true,
      onSuccess: (result) => {
        setValues({
          ...values,
          countryCode: result.country.countryCode,
          type: "sms",
        });
      },
    }
  );

  const { execute, isSubmitting } = useAsyncMutation(signUpService.sendcode, {
    successfulMessage: `A verification code was sent to your phone ending in ******${
      values?.cellPhoneNumber ? values?.cellPhoneNumber.slice(-4) : ""
    }`,
    errorMessage: "An error was ocurred",
    onSuccess: () => {
      setCodeSend(true);
    },
  });

  const { execute: executeVerify, isSubmitting: isSubmittingVerify } =
    useAsyncMutation(signUpService.verifyCode, {
      hideSuccessfulMessage: true,
      errorMessage:
        "The verification code you entered is incorrect, please validate it and try again",
      onSuccess: async () => {
        await handleNext(false);
      },
    });

  const countryCode = [
    { key: 1, value: "USA (+1)" },
    { key: 52, value: "MX (+52)" },
  ];

  useEffect(() => {
    const getData = async () => {
      await executeLoadData();
    };
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const cancelHandler = () => {
    if (setModalCancel) setModalCancel(true);
  };

  const handleNextStep = async () => {
    await handleNext(false);
  };

  const handleSendCode = async () => {
    await execute(values);
  };

  const handleVerify = async () => {
    await executeVerify(values);
  };

  const handleResend = async () => {};

  return (
    <>
      <FormTitleBox title={"Identity Verification"}>
        <Grid container spacing={5} pt={2}>
          {!codeSend && (
            <Grid item xs={3} textAlign={"right"}>
              <Typography variant="subtitle2" pt={2}>
                Confirm identity by SMS:
              </Typography>
            </Grid>
          )}
          {codeSend && (
            <Grid item xs={6} textAlign={"right"}>
              <Typography variant="subtitle2" pt={2}>
                Enter the 6-digit verification code that you received on your
                phone:{" "}
              </Typography>
            </Grid>
          )}
          {codeSend && (
            <Grid item xs={4}>
              <Typography display={"inline"}></Typography>
              <FormNumeric2
                name={"code"}
                label={"Code"}
                value={values.code}
                onChange={handleInputChange}
                maxValue={999999}
              ></FormNumeric2>
            </Grid>
          )}
          {!codeSend && (
            <Grid item xs={9}>
              <FormCheckBox
                name="acceptTerms"
                label={
                  <Typography display={"inline"}>
                    <Typography display={"inline"}>
                      I agree to receive the authentication code by SMS in
                      accordance with{" "}
                    </Typography>
                    <Link
                      display={"inline"}
                      href="https://auditmaster.pro/legal?id=sms"
                      target="_blank"
                    >
                      SMS Terms of Service
                    </Link>
                  </Typography>
                }
                value={acceptTerms}
                onChange={() => {
                  setAcceptTerms(!acceptTerms);
                }}
                showSkeleton={false}
              />
            </Grid>
          )}
          {acceptTerms && !codeSend && (
            <Grid item xs={12}>
              <Grid container spacing={4}>
                <Grid item xs={4}></Grid>
                <Grid item xs={4}>
                  <FormSelect
                    name={"countryCode"}
                    value={values.countryCode}
                    label={"Country or region code"}
                    onChange={function (e: any): void {
                      throw new Error("Function not implemented.");
                    }}
                    disable={true}
                    options={countryCode}
                  ></FormSelect>
                </Grid>
                <Grid item xs={4}></Grid>
                <Grid item xs={4}></Grid>
                <Grid item xs={4}>
                  <FormMaskText
                    name="cellPhoneNumber"
                    label="Phone"
                    value={values?.cellPhoneNumber}
                    onChange={(e: any) => {
                      handleInputChange(e);
                      validate();
                    }}
                    error={errors.cellPhoneNumber}
                    mask="(999) 999 - 9999"
                    showSkeleton={false}
                    onBlur={validate}
                  />
                </Grid>
              </Grid>
            </Grid>
          )}
          {codeSend && (
            <Grid item xs={12}>
              <Stack
                direction="row"
                spacing={2}
                justifyContent="center"
                alignItems="center"
              ></Stack>
            </Grid>
          )}
          <Grid item xs={12}>
            <Stack
              direction="row"
              spacing={2}
              justifyContent="center"
              alignItems="center"
            >
              {handleBack && (
                <FormBackButton
                  onClick={() => {
                    if (handleBack) handleBack();
                  }}
                  isSubmitting={false}
                ></FormBackButton>
              )}
              {setModalCancel && (
                <FormCancelButton
                  onClick={cancelHandler}
                  isSubmitting={false}
                />
              )}
              {acceptTerms && !codeSend && (
                <FormAcceptButton
                  onClick={handleSendCode}
                  isSubmitting={isSubmitting}
                  text={"Send Code"}
                  disabled={
                    !values?.cellPhoneNumber || errors.cellPhoneNumber !== ""
                  }
                ></FormAcceptButton>
              )}
              {!acceptTerms && (
                <FormNextButton
                  onClick={handleNextStep}
                  isSubmitting={false}
                ></FormNextButton>
              )}
              {codeSend && (
                <FormCancelButton
                  text="Resend"
                  onClick={handleResend}
                  isSubmitting={false}
                ></FormCancelButton>
              )}
              {codeSend && (
                <FormAcceptButton
                  text="Verify"
                  onClick={handleVerify}
                  isSubmitting={isSubmittingVerify}
                ></FormAcceptButton>
              )}
            </Stack>
          </Grid>
        </Grid>
      </FormTitleBox>
    </>
  );
}

export default IdentityConfirmation;
