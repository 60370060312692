import React, { useState, useEffect } from "react";
import Popup from "src/components/Popup";
import {
  FormButton,
  FormText,
  FormSelectState,
  FormMaskText,
  FormSelect,
  FormAcceptButton,
} from "src/components/formControls";
import { Stack, Typography, Grid } from "@mui/material";
import { IContractor } from "src/ts/interfaces/contractor";
import { useAsyncQuery, useAuth, useCountry, useForm } from "src/hooks";
import { COUNTRIES } from "src/constants";
import contractorService from "src/services/contractorService";
import useLog from "src/hooks/useLog";
import { Validator } from "src/ts/types";
import useZipCode from "src/hooks/useZipCode";

interface Props {
  showContractorPopUp: boolean;
  setShowContractorPopUp: (val: boolean) => void;
  contractorId: number;
  setRefresh: () => void;
}

const initialValues: IContractor = {
  id: 0,
  code: "",
  name: "",
  address: "",
  email: "",
  phoneNumber: "",
  isKnownContractor: false,
  isPreferredContractor: false,
  isTermsAndConditionsAccepted: false,
  winning: false,
  estimatedTimeDuration: "",
  invited: false,
  hasDeclined: false,
  declineComments: "",
  codeUsed: "",
  isHeadquarters: false,
  totalCurrentProjects: 0,
  totalCompletedProjects: 0,
  countryId: COUNTRIES.USA,
  enable: true,
  unknowContractor: true,
  unknowContractorTitle: "",
  unknowContractorName: "",
  unknowContractorEmail: "",
};

export default function ContractorPopUp({
  showContractorPopUp,
  setShowContractorPopUp,
  contractorId,
  setRefresh,
}: Props) {
  const validate = (fieldValues = values) => {
    let temp: Record<string, string> = { ...errors };

    temp.unknowContractorTitle = new Validator(
      fieldValues,
      "unknowContractorTitle"
    )
      .isRequired("This field is required")
      ?.toString();

    temp.unknowContractorName = new Validator(
      fieldValues,
      "unknowContractorName"
    )
      .isRequired("This field is required")
      ?.toString();

    temp.unknowContractorName = new Validator(
      fieldValues,
      "unknowContractorName"
    )
      .isRequired("This field is required")
      ?.toString();

    temp.unknowContractorEmail = new Validator(
      fieldValues,
      "unknowContractorEmail"
    )
      .emailFormat("Insert a correct email: demo@gmail.com")
      ?.toString();

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };
  const { log } = useLog();
  const { user } = useAuth();
  const [isStateLoading, setIsStateLoading] = useState(false);
  const [isReadOnly, setIsReadOnly] = useState(false);
  const [isLoadingPage, setisLoadingPage] = useState(true);
  const { countriesKeyValue, countries } = useCountry();
  const { execute, isLoading, data } = useAsyncQuery(
    contractorService.updateContractor
  );
  const {
    execute: executeCreate,
    isLoading: isLoadingCreate,
    data: dataCreate,
  } = useAsyncQuery(contractorService.createContractor);

  const { values, setValues, errors, setErrors, handleInputChange } = useForm(
    initialValues,
    false,
    validate
  );

  const { zipValue } = useZipCode(values.zipCode);

  useEffect(() => {
    if (
      zipValue?.countryId !== undefined &&
      values.countryId === COUNTRIES.USA &&
      !isLoadingPage
    ) {
      if (values.stateId !== zipValue.stateId) setIsStateLoading(true);
      setValues({ ...values, city: zipValue.city, stateId: zipValue.stateId });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [zipValue]);

  useEffect(() => {
    setisLoadingPage(false);
    if (!values.stateId) {
      setIsStateLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  useEffect(() => {
    if (dataCreate !== null) {
      setShowContractorPopUp(false);
      log.success("The contractor was added");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataCreate]);

  useEffect(() => {
    if (data !== null) {
      setShowContractorPopUp(false);
      log.success("The contractor was updated");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    const getData = async () => {
      const result = await contractorService.getContractor(contractorId);
      setValues(result.data);
      if (result.data.companyContractorId) setIsReadOnly(true);
    };
    if (contractorId > 0 && countriesKeyValue.length > 0) getData();
    else {
      setValues({ ...values, companyOwnerId: user?.companyId });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contractorId, countriesKeyValue]);

  useEffect(() => {
    const code = countries.filter((item: any) => {
      return item.id === values.countryId;
    });

    if (code.length >= 1) {
      setIsStateLoading(true);
      setValues({
        ...values,
        countryCode: code[0].countryCode,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.countryId, countries, values.country]);

  useEffect(() => {
    setIsStateLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.stateId]);

  const handleClosePopUp = () => {
    setShowContractorPopUp(false);
  };

  const handleSave = async () => {
    await execute(contractorId, values);
  };

  const handleSendInvitation = async () => {
    if (!validate()) return;
    await executeCreate(values);
    setRefresh();
  };

  return (
    <>
      <Popup
        title="Contractor's Information"
        openPopup={showContractorPopUp}
        setOpenPopup={setShowContractorPopUp}
        onClose={handleClosePopUp}
        size="md"
      >
        <Stack direction="row" spacing={10}>
          <Grid container spacing={5}>
            <Grid item xs={12}>
              <Typography>
                This contractor will receive an emailed invitation to create an
                account through AMP's software who holds either a contractors
                license or a free training/demo license. If this contractor
                chooses not to sign up, they won't be able to develop a proposal
                for this project.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <FormText
                name="unknowContractorTitle"
                label="Contractor"
                value={values.unknowContractorTitle}
                onChange={handleInputChange}
                error={errors.unknowContractorTitle}
                mode={isReadOnly ? "read" : "read&Write"}
                showSkeleton={isLoadingPage}
              />
            </Grid>
            <Grid item xs={12}>
              <FormText
                name="unknowContractorName"
                label="Name"
                value={values.unknowContractorName}
                onChange={handleInputChange}
                error={errors.unknowContractorName}
                mode={isReadOnly ? "read" : "read&Write"}
                showSkeleton={isLoadingPage}
              />
            </Grid>
            <Grid item xs={12}>
              <FormText
                name="unknowContractorEmail"
                label="Email"
                value={values.unknowContractorEmail}
                onChange={handleInputChange}
                error={errors.unknowContractorEmail}
                mode={isReadOnly ? "read" : "read&Write"}
                showSkeleton={isLoadingPage}
              />
            </Grid>
            <Grid item xs={12}>
              <FormText
                name="address"
                label="Address"
                value={values.address}
                onChange={handleInputChange}
                error={errors.address}
                mode={isReadOnly ? "read" : "read&Write"}
                showSkeleton={isLoadingPage}
              />
            </Grid>
            <Grid item xs={12}>
              <FormText
                name="address2"
                label="Address 2"
                value={values.address2}
                onChange={handleInputChange}
                error={errors.address2}
                mode={isReadOnly ? "read" : "read&Write"}
                showSkeleton={isLoadingPage}
              />
            </Grid>
            <Grid item xs={6}>
              <FormSelect
                name="countryId"
                label="Country"
                value={values.countryId}
                onChange={handleInputChange}
                options={countriesKeyValue}
                error={errors.countryId}
                mode={isReadOnly ? "read" : "read&Write"}
                defaultValue={{ key: -1, value: "Select a country" }}
                showSkeleton={isLoadingPage}
              />
            </Grid>
            <Grid item xs={6}>
              <FormText
                name="zipCode"
                label="Zip Code"
                value={values.zipCode}
                onChange={handleInputChange}
                error={errors.zipCode}
                maxLength={6}
                mode={isReadOnly ? "read" : "read&Write"}
                showSkeleton={isLoadingPage}
              />
            </Grid>
            <Grid item xs={6}>
              <FormText
                name="city"
                label="City"
                value={values.city}
                onChange={handleInputChange}
                error={errors.city}
                mode={isReadOnly ? "read" : "read&Write"}
                showSkeleton={isLoadingPage}
              />
            </Grid>
            <Grid item xs={6}>
              {values.countryId === COUNTRIES.USA ? (
                <FormSelectState
                  stateId={values.stateId}
                  stateName={values.stateName}
                  countryId={values.countryId}
                  errorsStateId={errors.stateId}
                  errorsStateName={errors.stateName}
                  onChange={handleInputChange}
                  isLoading={(value: boolean) => setIsStateLoading(value)}
                  mode={isReadOnly ? "read" : "read&Write"}
                  showSkeleton={isLoadingPage || isStateLoading}
                />
              ) : (
                <FormText
                  name="stateName"
                  label="State"
                  value={values.stateName}
                  onChange={handleInputChange}
                  error={errors.stateName}
                  mode={isReadOnly ? "read" : "read&Write"}
                  showSkeleton={isLoadingPage}
                />
              )}
            </Grid>
            <Grid item xs={6}>
              <Stack direction="row">
                <Grid container>
                  <Grid item xs={2}>
                    <FormText
                      name="countryCode"
                      label="Code"
                      value={values.countryCode}
                      disabled={true}
                      mode={isReadOnly ? "read" : "read&Write"}
                      showSkeleton={isLoadingPage}
                    />
                  </Grid>
                  <Grid item xs={10}>
                    <FormMaskText
                      name="workPhoneNumber"
                      mask="(999) 999 - 9999"
                      label="Work Phone Number"
                      onChange={handleInputChange}
                      value={values.workPhoneNumber}
                      error={errors.workPhoneNumber}
                      mode={isReadOnly ? "read" : "read&Write"}
                      showSkeleton={isLoadingPage}
                    />
                  </Grid>
                </Grid>
              </Stack>
            </Grid>
            <Grid item xs={6}>
              <FormText
                name="extension"
                label="Extension Number"
                value={values.extension}
                onChange={handleInputChange}
                error={errors.extension}
                mode={isReadOnly ? "read" : "read&Write"}
                showSkeleton={isLoadingPage}
              />
            </Grid>
            <Grid item xs={6}>
              <Stack direction="row">
                <Grid container>
                  <Grid item xs={2}>
                    <FormText
                      name="countryCode"
                      label="Code"
                      value={values.countryCode}
                      disabled={true}
                      mode={isReadOnly ? "read" : "read&Write"}
                      showSkeleton={isLoadingPage}
                    />
                  </Grid>
                  <Grid item xs={10}>
                    <FormMaskText
                      name="cellPhoneNumber"
                      mask="(999) 999 - 9999"
                      label="Cell Phone Number"
                      onChange={handleInputChange}
                      value={values.cellPhoneNumber}
                      error={errors.cellPhoneNumber}
                      mode={isReadOnly ? "read" : "read&Write"}
                      showSkeleton={isLoadingPage}
                    />
                  </Grid>
                </Grid>
              </Stack>
            </Grid>
          </Grid>
        </Stack>
        <Stack direction="row" spacing={2}>
          <FormButton
            text="Close"
            size="small"
            variant="outlined"
            onClick={() => {
              setShowContractorPopUp(false);
            }}
          />
          {contractorId > 0 && !isReadOnly && (
            <FormAcceptButton
              onClick={handleSave}
              isSubmitting={isLoading}
              text="Update"
            ></FormAcceptButton>
          )}
          {contractorId === 0 && (
            <FormAcceptButton
              onClick={handleSendInvitation}
              isSubmitting={isLoadingCreate}
              text="Send Invitation"
            ></FormAcceptButton>
          )}
        </Stack>
      </Popup>
    </>
  );
}
