import axios from "src/utils/axios";
import { IBoilerTest } from "src/ts/interfaces/study/boiler";

const getById = (id: number) => {
  return axios.get<IBoilerTest>(`BoilerTest/${id}`);
};

const totalBTUHasTested = (boiler: IBoilerTest) => {
  return axios.post<IBoilerTest>(`BoilerTest/TotalBTUHasTested`, boiler);
};

const actualWaterHydronickW = (boiler: IBoilerTest) => {
  return axios.post<IBoilerTest>(`BoilerTest/ActualWaterHydronickW`, boiler);
};

const totalMBHasTested = (boiler: IBoilerTest) => {
  return axios.post<IBoilerTest>(`BoilerTest/TotalMBHasTested`, boiler);
};

const totalTubeBundleasTested = (boiler: IBoilerTest) => {
  return axios.post<IBoilerTest>(`BoilerTest/TotalTubeBundleasTested`, boiler);
};

const actualOperatingKw = (boiler: IBoilerTest) => {
  return axios.post<IBoilerTest>(`BoilerTest/ActualOperatingKw`, boiler);
};

const actualTubeBundlePSIasTested = (boiler: IBoilerTest) => {
  return axios.post<IBoilerTest>(
    `BoilerTest/ActualTubeBundlePSIasTested`,
    boiler
  );
};

const calculation = (boiler: IBoilerTest) => {
  return axios.post<IBoilerTest>(`BoilerTest/Calculation`, boiler);
};

const update = (id: number, boiler: IBoilerTest) => {
  return axios.put<IBoilerTest>(`BoilerTest/${id}?validate=true`, boiler);
};

const boilerTestService = {
  getById,
  update,
  totalBTUHasTested,
  actualWaterHydronickW,
  totalMBHasTested,
  totalTubeBundleasTested,
  actualTubeBundlePSIasTested,
  actualOperatingKw,
  calculation,
};

export default boilerTestService;
