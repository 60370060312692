import { CardContent, Grid, Typography, Stack, Card } from "@mui/material";
import { useForm, Form, useAsyncMutation } from "src/hooks";
import { FormButton, FormText } from "src/components/formControls";
import { Validator } from "src/ts/types";
import KeyIcon from "@mui/icons-material/Key";
import accountService from "src/services/accountService";

interface AccountSettingsFormProps {
  email: string;
}

const initialValues: any = {
  oldPassword: "",
  newPassword: "",
  confirmPassword: "",
};

const AccountSettingsForm = (props: AccountSettingsFormProps) => {
  const { email } = props;

  const validate = (fieldValues = values) => {
    let temp: Record<string, string> = { ...errors };

    temp.oldPassword = new Validator(fieldValues, "oldPassword")
      .isRequired("Previous Password is required")
      .toString();

    temp.newPassword = new Validator(fieldValues, "newPassword")
      .isRequired("New Password is required")
      .minLength(6, "Please enter at least 6 characters")
      .toString();

    temp.confirmPassword = new Validator(fieldValues, "confirmPassword")
      .isRequired("New Password is required")
      .minLength(6, "Please enter at least 6 characters")
      .toString();

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { execute, isSubmitting } = useAsyncMutation(
    accountService.chagePassword,
    {
      successfulMessage: "Password was updated",
      hideErrorMessage: true,
      onSuccess: async () => {
        setValues(initialValues);
      },
      onError: (result) => {
        let temp: Record<string, string> = { ...errors };
        temp.oldPassword = "";
        temp.confirmPassword = "";
        temp.newPassword = "";

        if (result.message.PasswordMismatch) {
          temp.oldPassword = "Password is incorrect";
        }
        if (result.message.errors && result.message.errors.ConfirmPassword) {
          temp.confirmPassword =
            "The new password and confirmation password do not match.";
        }
        if (result.message.PasswordRequiresDigit) {
          temp.newPassword =
            "Passwords must have at least one digit ('0'-'9').";
        }
        if (result.message.PasswordRequiresLower) {
          temp.newPassword =
            "Passwords must have at least one lowercase ('a'-'z').";
        }
        if (result.message.PasswordRequiresUpper) {
          temp.newPassword =
            "Passwords must have at least one uppercase ('A'-'Z').";
        }
        if (result.message.PasswordRequiresNonAlphanumeric) {
          temp.newPassword =
            "Passwords must have at least one non alphanumeric character.";
        }

        setErrors({
          ...temp,
        });
      },
    }
  );

  const { values, errors, setErrors, handleInputChange, setValues } = useForm(
    initialValues,
    false,
    validate
  );

  const saveHandler = async () => {
    if (!validate()) return;

    await execute({
      oldPassword: values.oldPassword,
      confirmPassword: values.confirmPassword,
      newPassword: values.newPassword,
    });
  };

  const row = 12;
  return (
    <Card>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Account settings
        </Typography>
        <Form>
          <Grid container spacing={5}>
            <Grid item xs={row}>
              <Stack direction="row">
                <Typography variant="subtitle2" gutterBottom>
                  Email:
                </Typography>
                <Typography variant="body2" gutterBottom>
                  &nbsp;
                  {`${email}`}
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={row}>
              <FormText
                name="oldPassword"
                label="Previous Password"
                type="password"
                value={values.oldPassword}
                onChange={handleInputChange}
                error={errors.oldPassword}
                maxLength={20}
              />
            </Grid>
            <Grid item xs={row}>
              <FormText
                name="newPassword"
                label="New Password"
                type="password"
                value={values.newPassword}
                onChange={handleInputChange}
                error={errors.newPassword}
                maxLength={20}
              />
            </Grid>
            <Grid item xs={row}>
              <FormText
                name="confirmPassword"
                label="Re-Enter New Password"
                type="password"
                value={values.confirmPassword}
                onChange={handleInputChange}
                error={errors.confirmPassword}
                maxLength={20}
              />
            </Grid>

            <Grid item xs={12}>
              <Stack
                direction="row"
                spacing={2}
                justifyContent="center"
                alignItems="center"
              >
                <FormButton
                  onClick={saveHandler}
                  isSubmitting={isSubmitting}
                  text="Change Password"
                  size="medium"
                  startIcon={<KeyIcon />}
                />
              </Stack>
            </Grid>
          </Grid>
        </Form>
      </CardContent>
    </Card>
  );
};

export default AccountSettingsForm;
