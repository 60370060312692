import React from "react";
import { Grid, Stack, Typography } from "@mui/material";
import FormTitleBox from "src/components/formControls/FormTitleBox";
import { FormAcceptButton } from "src/components/formControls";
import { useAsyncMutation, useAuth } from "src/hooks";
import signUpService from "src/services/signUpService";
import { useNavigate } from "react-router-dom";
interface Props {
  wizardId: number;
}
function Redirect({ wizardId }: Props) {
  const { signOut } = useAuth();
  const navigate = useNavigate();
  const { execute, isSubmitting } = useAsyncMutation(signUpService.done, {
    hideSuccessfulMessage: true,
    onSuccess: async () => {
      await signOut();
      navigate("/auth/sign-in");
    },
  });
  const handleConfirm = async () => {
    await execute(wizardId);
  };
  return (
    <>
      <FormTitleBox title={"Confirmation"}>
        <Grid container spacing={5} pt={2}>
          <Grid item xs={12} textAlign={"center"}>
            <Typography variant="subtitle2" pt={2}>
              Your account was created successfully. Click Ok to log into your
              account
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Stack
              direction="row"
              spacing={2}
              justifyContent="center"
              alignItems="center"
            >
              <FormAcceptButton
                text="Ok"
                onClick={handleConfirm}
                isSubmitting={isSubmitting}
              ></FormAcceptButton>
            </Stack>
          </Grid>
        </Grid>
      </FormTitleBox>
    </>
  );
}

export default Redirect;
