import axios from "../utils/axios";

const upgrade = async (password: string, username: string) => {
  return axios.post(
    `token/checkPasswordToUpgradeSubscriptionLogin`,
    `password=${password}&userName=${username}`
  );
};

const tokenService = {
  upgrade,
};

export default tokenService;
