import axios from "src/utils/axios";

const sendToAmp = (
  reportId: number,
  projectId: number,
  reportTypeId: number
) => {
  return axios.put(
    `ProjectReportSendAmp/SendToAmp?id=${reportId}&projectId=${projectId}&typeId=${reportTypeId}`,
    ""
  );
};

const projectReportSendAmpService = {
  sendToAmp,
};

export default projectReportSendAmpService;
