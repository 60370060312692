import { useEffect, useState } from "react";
import {
  Button,
  Grid,
  Stack,
  Typography,
  Divider as MuiDivider,
} from "@mui/material";
import Popup from "src/components/Popup";
import { DailyLogType, projectStatus } from "src/constants";
import { BuildingDTO, IKeyValue, ITechDailyLogItem } from "src/ts/interfaces";
import { useAsyncQuery, useAuth } from "src/hooks";
import {
  FormAcceptButton,
  FormButton,
  FormCancelButton,
  FormSelect,
  FormTextArea,
} from "src/components/formControls";
import {
  Pause,
  PlayArrow,
  Stop,
  History as HistoryIcon,
} from "@mui/icons-material";
import techDailyLogService from "src/services/techDailyLogService";
import { ColumnType } from "src/types/enhancedTable";
import LocalEnhancedTable from "src/components/localTable/LocalTable";
import { spacing } from "@mui/system";
import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";
import { convertUTCDateToLocalDate } from "src/utils/utils";
import moment from "moment";
import {
  buildingService,
  gpsPermissionService,
  projectCurrentService,
} from "src/services";
import techniciansHistoryService from "src/services/techniciansHistoryService";
import getProjectSingleService from "src/services/project/getProjectSingleService";
import { ProjectDTO } from "src/ts/interfaces/project/projectDto";
import WaitingMobileAppPopup from "./WaitingMobileAppPopup";
import techniciansAppService from "src/services/techniciansAppService";
const Divider = styled(MuiDivider)(spacing);
interface DailyLogPopUpProps {
  showDailyLogPopUp: boolean;
  setShowDailyLogPopUp: (val: boolean) => void;
  projectId: number;
  dashboardView?: boolean;
}
const newItem: ITechDailyLogItem = {
  id: 0,
  userId: 0,
  typeTechDailyLogId: 0,
  comment: "",
  time: "",
  createDate: new Date(),
  saveDate: new Date(),
  total: 0,
  projectId: 0,
  breadcrumbsJson: "",
  technicianName: "",
};
const columns = (): ColumnType[] => [
  {
    id: "typeTechDailyLog.name",
    label: "Log Type",
    type: "custom",
    sort: false,
    callback: (row: ITechDailyLogItem) => {
      return <>{row?.typeTechDailyLog?.name}</>;
    },
  },
  {
    id: "time",
    label: "Time",
    type: "string",
    sort: false,
  },
  {
    id: "comment",
    label: "Comment",
    type: "string",
    sort: false,
  },
  {
    id: "createdDate",
    label: "Date",
    type: "utcDate",
    sort: false,
    format: "yyyy-MM-DD  hh:mm:ss a",
  },
];

export default function DailyLogPopUp({
  showDailyLogPopUp,
  setShowDailyLogPopUp,
  projectId,
  dashboardView,
}: DailyLogPopUpProps) {
  const { user } = useAuth();
  const [auditorList, setAuditorList] = useState<ITechDailyLogItem[]>([]);
  const [auditorLast, setAuditorLast] = useState<ITechDailyLogItem>();
  const [project, setProject] = useState<ProjectDTO>();
  const [isLoading, setIsLoading] = useState(false);
  const [refeshTable, setRefeshTable] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [dailyLogEntered, setDailyLogEntered] = useState(false);
  const [isCurrentProject, setIsCurrentProject] = useState(false);
  const [loadingOpen, setLoadingOpen] = useState(false);
  const [building, setBuilding] = useState<BuildingDTO>();
  const [action, setAction] = useState("");
  const [dateAddedAction, setDateAddedAction] = useState(new Date());
  const [dateDeletedAction, setDateDeletedAction] = useState(new Date());
  const [selectedAuditor, setSelectedAuditor] = useState(0);
  const [showWaitingMobileAppPopup, setShowWaitingMobileAppPopup] =
    useState(false);
  const [auditorsList, setAuditorsList] = useState<IKeyValue<number, string>[]>(
    []
  );
  const { execute: getPermissionStatus, data: permissionStatus } =
    useAsyncQuery<string>(gpsPermissionService.getPermissionStatus);
  const [isComplete] = useState(
    project?.projectStatusId === projectStatus.Complete.id ||
      project?.projectStatusId === projectStatus.InReview.id ||
      project?.projectStatusId === projectStatus.Approved.id ||
      project?.projectStatusId === projectStatus.Rejected.id ||
      project?.projectStatusId === projectStatus.Canceled.id
      ? true
      : false
  );
  const [popupDialog, setPopupDialog] = useState("");
  const [comments, setComments] = useState("");
  const [type, setType] = useState(0);
  const navigate = useNavigate();
  const isTechnician =
    user?.role === "TemporalAuditor" ||
    user?.role === "Auditor" ||
    user?.role === "EnergyAuditor" ||
    user?.role === "TemporalEnergyAuditor";

  useEffect(() => {
    const getData = async () => {
      let result = await techniciansHistoryService.getAuditorList(projectId);
      const keyValuePair: IKeyValue<number, string>[] = result.data.map(
        (item) => {
          return {
            key: item.userId!,
            value: `${item.user.name} ${item.user.lastName}`,
          };
        }
      );
      setAuditorsList(keyValuePair);

      let buildingResult = await buildingService.getBuildingByProject(
        projectId
      );
      setBuilding(buildingResult.data);
    };
    if (!isTechnician && projectId > 0) {
      getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId]);

  useEffect(() => {
    const getData = async () => {
      setIsLoading(true);
      let result = await techDailyLogService.getAuditorList(
        projectId,
        selectedAuditor
      );
      setAuditorList(result.data.sort((a, b) => b.id - a.id));
      setIsLoading(false);

      let resultHistory = await techniciansHistoryService.getByAuditor(
        projectId,
        selectedAuditor
      );

      let lastElement = resultHistory.data[0];
      setAction(lastElement.action);
      if (lastElement.action === "Added") {
        setDateAddedAction(lastElement.createdDate);
      } else {
        setDateDeletedAction(lastElement.createdDate);
        let addeddItems = resultHistory.data.filter(
          (x) => x.action === "Added"
        );
        setDateAddedAction(addeddItems[addeddItems.length - 1].createdDate);
      }
    };
    if (!isTechnician && selectedAuditor !== 0) {
      getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAuditor]);

  useEffect(() => {
    const getProject = async () => {
      let result = await getProjectSingleService.getById(projectId);
      setProject(result.data);
      if (projectId !== 0 && isTechnician) {
        getData();
      }
    };

    const getData = async () => {
      setIsLoading(true);
      let result = await techDailyLogService.getAuditorList(
        projectId,
        user?.userId
      );
      setAuditorList(result.data.sort((a, b) => b.id - a.id));
      let resultLast = await techDailyLogService.getAuditorLast(
        projectId,
        user?.userId
      );
      setAuditorLast(resultLast.data);
      let dailyLodResult = false;
      if (resultLast.data) {
        let utcDate = convertUTCDateToLocalDate(
          resultLast.data?.createDate.toString()
        )?.format("L");
        let currentDate = moment(new Date()).format("L");
        if (
          utcDate === currentDate &&
          resultLast.data?.typeTechDailyLogId !== 6
        ) {
          dailyLodResult = true;
          setDailyLogEntered(true);
        } else setDailyLogEntered(false);
      }

      const getCurrent = async () => {
        let result = await projectCurrentService.getByUser();
        if (result.data.projectId === projectId) {
          setIsCurrentProject(true);
          if (!isComplete && !dailyLodResult) {
            await getPermissionStatus();
          }
        } else setIsCurrentProject(false);
        setLoadingOpen(true);
      };
      if (isTechnician && !loadingOpen) getCurrent();

      setIsLoading(false);
    };
    if (!loadingOpen && projectId > 0) getProject();
    else {
      if (projectId > 0 && isTechnician) {
        getData();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId, refeshTable]);

  useEffect(() => {
    if (permissionStatus === null) return;
    if (permissionStatus !== "Active" && permissionStatus !== "Permanent") {
      setShowWaitingMobileAppPopup(true);
      techniciansAppService.sendStartWorkingNotification(projectId);
      return;
    }
    setShowDailyLogPopUp(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [permissionStatus]);

  const handleClosePopUp = () => {
    setShowDailyLogPopUp(false);
  };

  const handleClose = () => {
    setShowPopup(false);
  };

  const handleSave = async () => {
    newItem.projectId = projectId;
    newItem.typeTechDailyLogId = type;
    newItem.comment = comments;
    newItem.step = 2;
    await techDailyLogService.add(newItem);
    setShowPopup(false);
    setRefeshTable(!refeshTable);
  };

  const addDailyLogHandler = async (type: number, name: string) => {
    if (
      type === DailyLogType.StartTime ||
      type === DailyLogType.BeginBreak ||
      type === DailyLogType.EndBreak ||
      type === DailyLogType.BeginLunch ||
      type === DailyLogType.EndLunch ||
      type === DailyLogType.EndOfWorkDay ||
      type === DailyLogType.TravelTimeLeave ||
      type === DailyLogType.TravelTimeArrive
    ) {
      newItem.projectId = projectId;
      newItem.typeTechDailyLogId = type;
      newItem.step = 1;
      if (!newItem.comment) newItem.comment = undefined;
      await techDailyLogService.add(newItem);
      setRefeshTable(!refeshTable);
      if (DailyLogType.EndOfWorkDay === type) navigate("/app/working");
    } else {
      setType(type);
      setPopupDialog(name);
      setComments("");
      setShowPopup(true);
    }
  };

  const validateStopPouse = () => {
    return (
      !auditorLast ||
      (auditorLast &&
        (auditorLast?.typeTechDailyLogId === DailyLogType.BeginBreak ||
          auditorLast?.typeTechDailyLogId === DailyLogType.BeginLunch ||
          auditorLast?.typeTechDailyLogId === DailyLogType.EndOfWorkDay ||
          auditorLast?.typeTechDailyLogId === DailyLogType.TravelTimeLeave)) ||
      project?.projectStatusId === projectStatus.Complete.id ||
      project?.projectStatusId === projectStatus.Paid.id
    );
  };
  const endWorkDay = () => {
    return (
      !auditorLast ||
      (auditorLast &&
        auditorLast?.typeTechDailyLogId !== DailyLogType.StartTime &&
        auditorLast?.typeTechDailyLogId !== DailyLogType.EndBreak &&
        auditorLast?.typeTechDailyLogId !== DailyLogType.EndLunch &&
        auditorLast?.typeTechDailyLogId !== DailyLogType.TravelTimeArrive) ||
      project?.projectStatusId === projectStatus.Complete.id ||
      project?.projectStatusId === projectStatus.Paid.id
    );
  };

  const disableStart = (type: number) => {
    return (
      !auditorLast ||
      (auditorLast && auditorLast?.typeTechDailyLogId !== type) ||
      project?.projectStatusId === projectStatus.Complete.id ||
      project?.projectStatusId === projectStatus.Paid.id
    );
  };
  return (
    <>
      <WaitingMobileAppPopup
        showWaitingMobileAppPopup={showWaitingMobileAppPopup}
        setShowWaitingMobileAppPopup={setShowWaitingMobileAppPopup}
      ></WaitingMobileAppPopup>
      <Popup
        title={`Daily Log - ${project?.name}`}
        openPopup={showDailyLogPopUp}
        setOpenPopup={setShowDailyLogPopUp}
        onClose={handleClosePopUp}
        size={isCurrentProject && isTechnician ? "lg" : "md"}
        hideClose={!dailyLogEntered && isCurrentProject}
        disableClickOutside={true}
        disableEscKeyDown={true}
      >
        <>
          {isCurrentProject && isTechnician && (
            <>
              <Grid container>
                <Grid item xs={2} p={1}>
                  <Button
                    type="submit"
                    fullWidth
                    variant="outlined"
                    color="success"
                    size="large"
                    disabled={
                      disableStart(DailyLogType.EndOfWorkDay) &&
                      auditorLast !== null
                    }
                    onClick={() =>
                      addDailyLogHandler(DailyLogType.StartTime, "")
                    }
                  >
                    <Grid>
                      <Grid>
                        <PlayArrow />
                        <Typography variant="h5" gutterBottom>
                          Start Time
                        </Typography>
                      </Grid>
                    </Grid>
                  </Button>
                </Grid>
                <Grid item xs={2} p={1}>
                  <Button
                    type="submit"
                    fullWidth
                    variant="outlined"
                    color="warning"
                    size="large"
                    disabled={validateStopPouse()}
                    onClick={() =>
                      addDailyLogHandler(DailyLogType.BeginBreak, "")
                    }
                  >
                    <Grid>
                      <Grid>
                        <Pause />
                        <Typography variant="h5" gutterBottom>
                          Begin Break
                        </Typography>
                      </Grid>
                    </Grid>
                  </Button>
                </Grid>
                <Grid item xs={2} p={1}>
                  <Button
                    type="submit"
                    fullWidth
                    variant="outlined"
                    color="error"
                    size="large"
                    disabled={disableStart(DailyLogType.BeginBreak)}
                    onClick={() =>
                      addDailyLogHandler(DailyLogType.EndBreak, "")
                    }
                  >
                    <Grid>
                      <Grid>
                        <Stop />
                        <Typography variant="h5" gutterBottom>
                          End Break
                        </Typography>
                      </Grid>
                    </Grid>
                  </Button>
                </Grid>
                <Grid item xs={2} p={1}>
                  <Button
                    type="submit"
                    fullWidth
                    variant="outlined"
                    color="warning"
                    size="large"
                    disabled={validateStopPouse()}
                    onClick={() =>
                      addDailyLogHandler(DailyLogType.BeginLunch, "")
                    }
                  >
                    <Grid>
                      <Grid>
                        <Pause />
                        <Typography variant="h5" gutterBottom>
                          Begin Lunch
                        </Typography>
                      </Grid>
                    </Grid>
                  </Button>
                </Grid>
                <Grid item xs={2} p={1}>
                  <Button
                    type="submit"
                    fullWidth
                    variant="outlined"
                    color="error"
                    size="large"
                    disabled={disableStart(DailyLogType.BeginLunch)}
                    onClick={() =>
                      addDailyLogHandler(DailyLogType.EndLunch, "")
                    }
                  >
                    <Grid>
                      <Grid>
                        <Stop />
                        <Typography variant="h5" gutterBottom>
                          End Lunch
                        </Typography>
                      </Grid>
                    </Grid>
                  </Button>
                </Grid>
                <Grid item xs={2} p={1}>
                  <Button
                    type="submit"
                    fullWidth
                    variant="outlined"
                    color="error"
                    size="large"
                    disabled={validateStopPouse()}
                    onClick={() =>
                      addDailyLogHandler(DailyLogType.EndOfWorkDay, "")
                    }
                  >
                    <Grid>
                      <Grid>
                        <Stop />
                        <Typography variant="h5" gutterBottom>
                          End of Work Day
                        </Typography>
                      </Grid>
                    </Grid>
                  </Button>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={2} p={1}>
                  <Button
                    type="submit"
                    fullWidth
                    variant="outlined"
                    color="primary"
                    size="large"
                    disabled={endWorkDay()}
                    onClick={() =>
                      addDailyLogHandler(DailyLogType.DownTime, "Down Time")
                    }
                  >
                    <Grid>
                      <Grid>
                        <HistoryIcon />
                        <Typography variant="h5" gutterBottom>
                          Down Time
                        </Typography>
                      </Grid>
                    </Grid>
                  </Button>
                </Grid>
                <Grid item xs={2} p={1}>
                  <Button
                    type="submit"
                    fullWidth
                    variant="outlined"
                    color="primary"
                    size="large"
                    disabled={endWorkDay()}
                    onClick={() =>
                      addDailyLogHandler(
                        DailyLogType.ProjectMeeting,
                        "Project Meeting"
                      )
                    }
                  >
                    <Grid>
                      <Grid>
                        <HistoryIcon />
                        <Typography variant="h5" gutterBottom>
                          Project Meeting
                        </Typography>
                      </Grid>
                    </Grid>
                  </Button>
                </Grid>
                <Grid item xs={2} p={1}>
                  <Button
                    type="submit"
                    fullWidth
                    variant="outlined"
                    color="primary"
                    size="large"
                    disabled={endWorkDay()}
                    onClick={() =>
                      addDailyLogHandler(
                        DailyLogType.SafetyMeeting,
                        "Safety Meeting"
                      )
                    }
                  >
                    <Grid>
                      <Grid>
                        <HistoryIcon />
                        <Typography variant="h5" gutterBottom>
                          Safety Meeting
                        </Typography>
                      </Grid>
                    </Grid>
                  </Button>
                </Grid>
                <Grid item xs={2} p={1}>
                  <Button
                    type="submit"
                    fullWidth
                    variant="outlined"
                    color="primary"
                    size="large"
                    disabled={endWorkDay()}
                    onClick={() =>
                      addDailyLogHandler(DailyLogType.TravelTimeLeave, "")
                    }
                  >
                    <Grid>
                      <Grid>
                        <HistoryIcon />
                        <Typography variant="h5" gutterBottom>
                          Travel Time Leave
                        </Typography>
                      </Grid>
                    </Grid>
                  </Button>
                </Grid>
                <Grid item xs={2} p={1}>
                  <Button
                    type="submit"
                    fullWidth
                    variant="outlined"
                    color="primary"
                    size="large"
                    disabled={disableStart(DailyLogType.TravelTimeLeave)}
                    onClick={() =>
                      addDailyLogHandler(DailyLogType.TravelTimeArrive, "")
                    }
                  >
                    <Grid>
                      <Grid>
                        <HistoryIcon />
                        <Typography variant="h5" gutterBottom>
                          Travel Time Arrive
                        </Typography>
                      </Grid>
                    </Grid>
                  </Button>
                </Grid>
                <Grid item xs={2} p={1}>
                  <Button
                    type="submit"
                    fullWidth
                    variant="outlined"
                    color="primary"
                    size="large"
                    disabled={endWorkDay()}
                    onClick={() =>
                      addDailyLogHandler(DailyLogType.Other, "Other")
                    }
                  >
                    <Grid>
                      <Grid>
                        <HistoryIcon />
                        <Typography variant="h5" gutterBottom>
                          Other
                        </Typography>
                      </Grid>
                    </Grid>
                  </Button>
                </Grid>
              </Grid>
              <Divider></Divider>
            </>
          )}
          {!isTechnician && (
            <Grid container>
              <Grid item xs={6} p={3}>
                <FormSelect
                  name="selectedAuditor"
                  label="Technician(s) assigned to above project"
                  value={selectedAuditor?.toString()}
                  options={auditorsList}
                  showSkeleton={isLoading}
                  onChange={(e) => {
                    setSelectedAuditor(e.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={6} p={3}>
                <Typography variant="subtitle2" style={{ display: "inline" }}>
                  Date assigned to the project:{" "}
                </Typography>
                <Typography style={{ display: "inline" }}>
                  {selectedAuditor !== 0 &&
                    convertUTCDateToLocalDate(
                      dateAddedAction.toString()
                    )?.format("L")}
                </Typography>
              </Grid>
              <Grid item xs={6} p={3}>
                <Typography variant="subtitle2" style={{ display: "inline" }}>
                  Building Name:{" "}
                </Typography>
                <Typography style={{ display: "inline" }}>
                  {building?.name}
                </Typography>
              </Grid>
              <Grid item xs={6} p={3}>
                <Typography variant="subtitle2" style={{ display: "inline" }}>
                  Date removed from the project:{" "}
                </Typography>
                <Typography style={{ display: "inline" }}>
                  {action === "Deleted" &&
                    convertUTCDateToLocalDate(
                      dateDeletedAction.toString()
                    )?.format("L")}
                </Typography>
              </Grid>
            </Grid>
          )}
          <Grid container>
            <Grid item xs={12}>
              <LocalEnhancedTable<ITechDailyLogItem>
                refreshGrid={true}
                columns={columns()}
                data={auditorList}
                showSkeleton={isLoading}
                defaultRowPerPage={isCurrentProject ? 5 : 10}
                orderColumn="desc"
              />
            </Grid>
          </Grid>
        </>

        <Stack
          direction="row"
          spacing={2}
          justifyContent="flex-end"
          alignItems="flex-start"
        >
          {(dailyLogEntered || !isCurrentProject || dashboardView) && (
            <FormButton
              text="Close"
              size="medium"
              variant="outlined"
              onClick={() => {
                setShowDailyLogPopUp(false);
              }}
            />
          )}
        </Stack>
      </Popup>

      <Popup
        title={popupDialog}
        openPopup={showPopup}
        setOpenPopup={setShowPopup}
        onClose={handleClose}
        size={"sm"}
      >
        <Stack>
          <FormTextArea
            name="comments"
            label={popupDialog}
            value={comments}
            placeholder=""
            onChange={function (e: any): void {
              setComments(e.target.value);
            }}
          />
        </Stack>

        <Stack
          direction="row"
          spacing={2}
          justifyContent="flex-end"
          alignItems="flex-start"
        >
          <FormCancelButton onClick={handleClose} isSubmitting={false} />
          <FormAcceptButton onClick={handleSave} isSubmitting={false} />
        </Stack>
      </Popup>
    </>
  );
}
