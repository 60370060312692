import axios from "src/utils/axios";
import { IQuizQuestionDTO, IQuizResultDTO } from "src/ts/interfaces";

const getQuizQuestions = (id: number, target: string, fanId: number) => {
  return axios.get<IQuizQuestionDTO[]>(
    `QuizQuestion?reportId=${id}&target=${target}&fanId=${fanId}`
  );
};

const save = (id: number, results: IQuizResultDTO[]) => {
  return axios.post<IQuizResultDTO[]>(`QuizQuestion/results/${id}`, results);
};

const quizQuestionService = {
  getQuizQuestions,
  save,
};

export default quizQuestionService;
