import { IProjectBomaType } from "src/ts/interfaces";
import axios from "src/utils/axios";

const getAll = () => {
  return axios.get<IProjectBomaType>("ProjectBomaType");
};

const projectBomaTypeService = {
  getAll,
};

export default projectBomaTypeService;
