import {
  IConfiguration,
  IProjectTypeCount,
  ProjectAuditorListDTO,
  ProjectCompletedDTO,
} from "src/ts/interfaces";
import { ProjectDTO } from "src/ts/interfaces/project/projectDto";
import axios from "src/utils/axios";

const getAuditorCount = () => {
  return axios.get<number>(`GetProjectSingle/AuditorCount`);
};

const getCountCompleteAuditor = () => {
  return axios.get<number>(`GetProjectSingle/CountCompleteAuditor`);
};

const getProjectsByAuditor = () => {
  return axios.get<ProjectAuditorListDTO[]>(`GetProjectSingle/Auditor`);
};

const getCompletedProjectsByAuditor = () => {
  return axios.get<ProjectCompletedDTO[]>(`GetProjectSingle/Auditor/Complete`);
};

const getById = (projectId: number) => {
  return axios.get<ProjectDTO>(`GetProjectSingle/${projectId}`);
};

const getCountCompleteByType = () => {
  return axios.get<IProjectTypeCount[]>(`GetProjectSingle/CountCompleteByType`);
};
const getConfiguration = (projectId: number) => {
  return axios.get<IConfiguration[]>(
    `GetProjectSingle/Configuration?id=${projectId}`
  );
};

const getCountOutToBid = () => {
  return axios.get<number>(`GetProjectSingle/GetCountOutToBid`);
};

const getProjectSingleService = {
  getCountCompleteByType,
  getAuditorCount,
  getCountCompleteAuditor,
  getProjectsByAuditor,
  getCompletedProjectsByAuditor,
  getById,
  getConfiguration,
  getCountOutToBid,
};

export default getProjectSingleService;
