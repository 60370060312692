import { TowerStudyProvider } from "../context/TowerStudyContext";
import TowerStep31 from "./TowerStep31";

const Index = () => {
  return (
    <TowerStudyProvider>
      <TowerStep31 />
    </TowerStudyProvider>
  );
};

export default Index;
