import Popup from "src/components/Popup";
import { FormButton, FormTextArea } from "src/components/formControls";
import { Grid, Stack, Typography } from "@mui/material";
import { IArchitectProjects } from "src/ts/interfaces/projectArchitect";
import { convertUTCDateToLocalDate } from "src/utils/utils";
import { useAsyncQuery, useForm, useLog } from "src/hooks";
import projectService from "src/services/project/projectService";
import projectHistoryStatusCommentsService from "src/services/project/projectHistoryStatusCommentsService";
import { IGetProjectInfectious } from "src/ts/interfaces/project/projectinfectiousCompliance";
import projectInfectiousComplianceService from "src/services/project/projectInfectiousComplianceService ";

interface CloseProjectPopUpProps {
  closeProjectShowPopUp: boolean;
  setCloseProjectShowPopUp: (val: boolean) => void;
  setRefreshGrid: (val: boolean) => void;
  currentRow: IArchitectProjects | IGetProjectInfectious | undefined;
  refreshGrid: boolean;
  isInfectious?: boolean;
}

const initialValues = {
  comments: "",
};

export default function CloseProjectPopUp({
  closeProjectShowPopUp,
  setCloseProjectShowPopUp,
  currentRow,
  setRefreshGrid,
  refreshGrid,
  isInfectious,
}: CloseProjectPopUpProps) {
  const { values, handleInputChange } = useForm(initialValues, false, []);

  const { log } = useLog();
  const { execute: executePutChangeStatus } = useAsyncQuery(
    projectService.putChangeStatus
  );

  const { execute: executeHistory } = useAsyncQuery(
    projectHistoryStatusCommentsService.postProjectHistoryStatusComments
  );

  const handlerAccept = async () => {
    if (values.comments === "") return log.warning("Comments are required.");

    if (!isInfectious) {
      await executePutChangeStatus(currentRow?.id, 9, values.comments);
    } else {
      try {
        await projectInfectiousComplianceService.putChangeStatusInfect(
          currentRow?.id ?? 0,
          9,
          values.comments
        );
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      }
    }

    await executeHistory({
      comments: values.comments,
      projectId: currentRow?.id,
      statusId: 9,
    });
    log.success("Project was Closed.");
    setRefreshGrid(!refreshGrid);
    setCloseProjectShowPopUp(false);
  };
  return (
    <>
      <Popup
        title={`Close Project: ${currentRow?.code}`}
        openPopup={closeProjectShowPopUp}
        setOpenPopup={setCloseProjectShowPopUp}
        onClose={() => {
          setCloseProjectShowPopUp(false);
        }}
        size="sm"
      >
        <>
          <Typography ml={4}>
            <b>Project details</b>
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={2.5}>
              <Stack direction="row" justifyContent={"end"}>
                <Typography> Name: </Typography>
              </Stack>
            </Grid>
            <Grid item xs={9}>
              <Typography>{currentRow?.name} </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={2.5}>
              <Stack direction="row" justifyContent={"end"}>
                <Typography> Description: </Typography>
              </Stack>
            </Grid>
            <Grid item xs={9}>
              <Typography>{currentRow?.description ?? ""} </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={2.5}>
              <Stack direction="row" justifyContent={"end"}>
                <Typography> Created on: </Typography>
              </Stack>
            </Grid>
            <Grid item xs={9}>
              {currentRow?.createdDate !== null &&
                convertUTCDateToLocalDate(
                  currentRow?.createdDate.toString() || ""
                )?.format("MM/DD/YYYY hh:mm A")}
            </Grid>
          </Grid>
          <Grid container spacing={3} mb={4}>
            <Grid item xs={2.5}>
              <Stack direction="row" justifyContent={"end"}>
                <Typography> Completed on: </Typography>
              </Stack>
            </Grid>
            <Grid item xs={9}>
              {currentRow?.completedDate !== null &&
                convertUTCDateToLocalDate(
                  currentRow?.completedDate.toString() || ""
                )?.format("MM/DD/YYYY hh:mm A")}
            </Grid>
          </Grid>

          <FormTextArea
            label="Comments"
            name="comments"
            value={values.comments}
            onChange={handleInputChange}
          />
        </>
        <Stack direction="row" spacing={3}>
          <Stack>
            <Typography mt={1}>
              <b>Are you certain you want to close this project?</b>
            </Typography>
          </Stack>
          <FormButton
            size="small"
            text="Cancel"
            variant="outlined"
            onClick={() => {
              setCloseProjectShowPopUp(false);
            }}
          />
          <FormButton
            size="small"
            text="Yes, Close"
            color="primary"
            onClick={() => {
              handlerAccept();
            }}
          />
        </Stack>
      </Popup>
    </>
  );
}
