import React, { useEffect, useState } from "react";
import {
  Grid,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  Typography,
  CardContent,
} from "@mui/material";
import styled from "@emotion/styled";
import { Helmet } from "react-helmet-async";
import { NavLink } from "react-router-dom";
import { spacing } from "@mui/system";
import { useAsyncQuery } from "src/hooks";
import getProjectSingleService from "src/services/project/getProjectSingleService";
import { ProjectCompletedDTO } from "src/ts/interfaces";
import Actions from "./components/Actions";
import CompleteProjectsGrid from "./components/CompleteProjectsGrid";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const TechnicianCompleteProjects = () => {
  const [refreshGrid, setRefreshGrid] = useState(false);
  const { execute, data, isLoading } = useAsyncQuery<ProjectCompletedDTO[]>(
    getProjectSingleService.getCompletedProjectsByAuditor
  );

  useEffect(() => {
    const getData = async () => {
      execute();
    };

    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshGrid]);

  return (
    <React.Fragment>
      <Helmet title="Complete Projects" />
      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Complete Projects
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} to="/">
              Dashboard
            </Link>
            <Typography>Complete Projects</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item>
          <Actions />
        </Grid>
      </Grid>

      <Divider my={6} />
      <Card>
        <CardContent>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <CompleteProjectsGrid
                setRefreshGrid={setRefreshGrid}
                refreshGrid={refreshGrid}
                data={data}
                isLoading={isLoading}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </React.Fragment>
  );
};

export default TechnicianCompleteProjects;
