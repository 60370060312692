import { Chip, Grid } from "@mui/material";
import { IStorageMetrics } from "src/ts/interfaces/storage";
import LocalEnhancedTable from "src/components/localTable/LocalTable";
import { ColumnType } from "src/types/enhancedTable";
import { useLog } from "src/hooks";
import storageService from "src/services/storageService";
import { useEffect, useState } from "react";

interface MemoryDashboardProp {
  unitType: string;
}

const getColumns = (unitType: string): ColumnType[] => {
  return [
    {
      id: "companyAddress",
      label: "Company address",
      type: "custom",
      sort: false,
      callback: (row: IStorageMetrics) => {
        return (
          <>
            {row.companyAddress}{" "}
            {row.parentCompanyId === 0 && (
              <Chip
                label="Headquarters"
                color="error"
                variant="outlined"
              ></Chip>
            )}
            {row.parentCompanyId !== 0 && (
              <Chip label="Satellite" color="info" variant="outlined"></Chip>
            )}{" "}
          </>
        );
      },
    },
    {
      id: "companyDocumentSize",
      label: "Company Documents",
      type: "custom",
      sort: false,
      callback: (row: IStorageMetrics) => {
        return (
          <>
            {unitType === "1" ? (
              <>
                {Intl.NumberFormat("en-US", {
                  maximumFractionDigits: 2,
                }).format(row.companyDocumentSizeGb)}
                {`${" GB"}`}
              </>
            ) : (
              <>
                {Intl.NumberFormat("en-US", {
                  maximumFractionDigits: 2,
                }).format(row.companyDocumentSize)}
                {`${" MB"}`}
              </>
            )}
          </>
        );
      },
    },
    {
      id: "projectDocumentSize",
      label: "Project Documents",
      type: "custom",
      sort: false,
      callback: (row: IStorageMetrics) => {
        return (
          <>
            {unitType === "1" ? (
              <>
                {Intl.NumberFormat("en-US", {
                  maximumFractionDigits: 2,
                }).format(row.projectDocumentSizeGb)}
                {`${" GB"}`}
              </>
            ) : (
              <>
                {Intl.NumberFormat("en-US", {
                  maximumFractionDigits: 2,
                }).format(row.projectDocumentSize)}
                {`${" MB"}`}
              </>
            )}
          </>
        );
      },
    },
    {
      id: "userDocumentSize",
      label: "User Documents",
      type: "custom",
      sort: false,
      callback: (row: IStorageMetrics) => {
        return (
          <>
            {unitType === "1" ? (
              <>
                {Intl.NumberFormat("en-US", {
                  maximumFractionDigits: 2,
                }).format(row.userDocumentSizeGb)}
                {`${" GB"}`}
              </>
            ) : (
              <>
                {Intl.NumberFormat("en-US", {
                  maximumFractionDigits: 2,
                }).format(row.userDocumentSize)}
                {`${" MB"}`}
              </>
            )}
          </>
        );
      },
    },
    {
      id: "libraryDocumentSize",
      label: "Project's Documents Library",
      type: "custom",
      sort: false,
      callback: (row: IStorageMetrics) => {
        return (
          <>
            {unitType === "1" ? (
              <>
                {Intl.NumberFormat("en-US", {
                  maximumFractionDigits: 2,
                }).format(row.libraryDocumentSizeGb)}
                {`${" GB"}`}
              </>
            ) : (
              <>
                {Intl.NumberFormat("en-US", {
                  maximumFractionDigits: 2,
                }).format(row.libraryDocumentSize)}
                {`${" MB"}`}
              </>
            )}
          </>
        );
      },
    },
    {
      id: "pictureLogSize",
      label: "Project's Picture Log",
      type: "custom",
      sort: false,
      callback: (row: IStorageMetrics) => {
        return (
          <>
            {unitType === "1" ? (
              <>
                {Intl.NumberFormat("en-US", {
                  maximumFractionDigits: 2,
                }).format(row.pictureLogSizeGb)}
                {`${" GB"}`}{" "}
              </>
            ) : (
              <>
                {Intl.NumberFormat("en-US", {
                  maximumFractionDigits: 2,
                }).format(row.pictureLogSize)}
                {`${" MB"}`}
              </>
            )}
          </>
        );
      },
    },
    {
      id: "equipmentDocuments",
      label: "Equipment Documents",
      type: "custom",
      sort: false,
      callback: () => {
        return <>{unitType === "1" ? "0 GB" : "0 MB"}</>;
      },
    },
  ];
};

const TableStorageCurrentCompany = (props: MemoryDashboardProp) => {
  const { unitType } = props;
  const columns = getColumns(unitType);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [storageMetric, setStorageMetric] = useState<IStorageMetrics[]>([]);
  const { log } = useLog();

  useEffect(() => {
    const getData = async () => {
      try {
        setIsSubmitting(true);
        const request = await storageService.summaryByCompanies();
        setStorageMetric(request.data);
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      } finally {
        setIsSubmitting(false);
      }
    };

    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Grid item xs={12}>
        <LocalEnhancedTable<IStorageMetrics>
          refreshGrid={true}
          columns={columns}
          data={storageMetric}
          showSkeleton={isSubmitting}
        />
      </Grid>
    </>
  );
};

export default TableStorageCurrentCompany;
