import {
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import DialogMessagePopup from "src/components/DialogMessagePopup";
import { FormButton, FormLabel, FormText } from "src/components/formControls";
import { useAuth, useLog } from "src/hooks";
import useFormTyped from "src/hooks/useFormTyped";
import { CouponService, projectActiveService, userService } from "src/services";
import purchaseService from "src/services/purchaseService";
import systemCostCatalogService from "src/services/systemCostCatalogService";
import { ICompany, IUserPerson, IUserPersonAuditor } from "src/ts/interfaces";
import { IPurchaseRequest } from "src/ts/interfaces/purchase";
import { currencyformatter } from "src/utils/format";
import {
  ProjectDTO,
  ProjectWithBogus,
} from "src/ts/interfaces/project/projectDto";
import projectCatalogs from "src/services/project/projectCatalogs";
import technicianService from "src/services/technicianService";
import projectService from "src/services/project/projectService";
import projectHistoryService from "src/services/project/projectHistoryService";
import { useNavigate } from "react-router-dom";
import { PROJECT_TYPES } from "src/constants";
import { VisibilityIcon, VisibilityOffIcon } from "src/components/icons";

interface ICompletePayment {
  codeCoupon: string;
}

interface IPayment {
  systemCostCatalogId: number;
  itemName: string;
  cost: number;
  couponValue: number;
  total: number;
}

const initialValues: ICompletePayment = {
  codeCoupon: "",
};

interface Props {
  company: ICompany;
  project: ProjectWithBogus;
}

const Payment = ({ company, project }: Props) => {
  const navigate = useNavigate();
  const { log } = useLog();
  const { user } = useAuth();
  const [payment, setPayment] = useState<IPayment | null>(null);
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const [showInsufficientFundsModal, setShowInsufficientFundsModal] =
    useState(false);
  const [showNoDefaultCardBodyModal, setShowNoDefaultCardBodyModal] =
    useState(false);

  const { values, handleInputChange, setValues } =
    useFormTyped<ICompletePayment>(initialValues, true, []);

  const validateCouponHandler = async () => {
    if (values.codeCoupon === "" || values.codeCoupon == null) {
      log.error("Please enter a value for the coupon code");
      return;
    }

    try {
      const req = await CouponService.getByCode(values.codeCoupon);
      if (req.data.typeId !== 4) {
        log.error(
          "The coupon does not apply to Infectious Control Compliancy Project"
        );
        setValues({
          codeCoupon: "",
        });

        if (payment !== null)
          setPayment({
            ...payment,
            couponValue: 0,
          });
      }

      if (payment !== null) {
        const total = payment.cost - (payment.cost / 100) * req.data.value;
        setPayment({
          ...payment,
          couponValue: req.data.value,
          total: total,
        });
      }
    } catch {
      if (payment !== null)
        setPayment({
          ...payment,
          couponValue: 0,
          total: payment.cost,
        });
      log.error("The coupon code is invalid or was expired");
    }
  };

  const [auditorsList, setAuditorList] = useState<IUserPersonAuditor[]>();

  //1 Auditor
  //2 EnergyAuditor
  //3 all ??
  const getUserCertifications = async () => {
    const res = await technicianService.getCertificationsByUser(user?.userName);
    const certifications = res.data;
    let projectType = PROJECT_TYPES.EnergyCertification;
    if (certifications.length === 2) projectType = PROJECT_TYPES.Both;
    else {
      if (certifications[0].certificationType === "Auditor")
        projectType = PROJECT_TYPES.TestAndBalanceCertification;
      else projectType = PROJECT_TYPES.EnergyCertification;
    }
    return projectType;
  };

  const getAuditors = async (cert: number) => {
    const res = await userService.getAuditorByCert(user?.companyId, cert);
    return res.data;
  };

  useEffect(() => {
    const getData = async () => {
      const req = await systemCostCatalogService.GetCovidProjectCosts();

      const cost = req.data[0].pricing;
      const couponValue = 0;
      const total = cost - (cost / 100) * couponValue;

      setPayment({
        systemCostCatalogId: req.data[0].id,
        itemName: req.data[0].name,
        cost: cost,
        couponValue: 0,
        total: total,
      });

      if (
        user?.role === "TemporalEnergyAuditor" ||
        user?.role === "EnergyAuditor" ||
        user?.role === "Training"
      ) {
        const certification = await getUserCertifications();
        const auditors = await getAuditors(certification);
        setAuditorList(auditors);
      }
    };

    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const createAndPayNowHandler = () => {
    setShowConfirmModal(true);
  };

  const confirmMessage = () => {
    if (
      user?.role !== "TemporalAuditor" &&
      user?.role !== "TemporalEnergyAuditor" &&
      user?.role !== "Auditor" &&
      user?.role !== "EnergyAuditor"
    ) {
      return `We will charge ${currencyformatter.format(
        payment?.total ?? 0
      )} to your AMP Wallet. Are you certain you want to continue?`;
    }

    return "Are you certain you want to continue?";
  };

  const createProjectAndPay = async () => {
    let walletCompany = 0;
    if (user?.parentCompanyId === "0") walletCompany = user?.companyId;
    else walletCompany = user?.parentCompanyId;

    const itemToPurchase: IPurchaseRequest = {
      systemCostCatalogId: payment?.systemCostCatalogId ?? 0,
      cuponCode: values.codeCoupon,
      companyId: walletCompany,
      projectName: project.name,
    };

    const walletMessage = await walletPurchase(itemToPurchase);
    if (walletMessage.statusCode === 0) {
      log.info(walletMessage.resultMessage);
      addCovidProject(project);
    } else {
      switch (walletMessage.statusCode) {
        case 1:
          setShowInsufficientFundsModal(true);
          break;
        case 3:
          setShowNoDefaultCardBodyModal(true);
          break;
        default:
          log.error(walletMessage.resultMessage);
          break;
      }
    }
  };

  const walletPurchase = async (itemToPurchase: IPurchaseRequest) => {
    try {
      const response = await purchaseService.purchase(itemToPurchase);
      if (response.data.autoReloadApplied) {
        log.info("");
      }
      return {
        statusCode: response.data.statusCode,
        resultMessage: response.data.resultMessage,
      };
    } catch (error: any) {
      var errorMessage = "An error has occurred";
      if (error && error?.message?.message)
        errorMessage = error.message.message;
      if (error?.data && error?.data?.message)
        errorMessage = error?.data?.message;
      if (error?.data && error?.data?.exceptionMessage)
        errorMessage = error.data.exceptionMessage;
      return { statusCode: 2, resultMessage: errorMessage };
    }
  };

  const getSafetyEquipments = async () => {
    const safetyEquipmentResponse = await projectCatalogs.safetyEquipments();
    const selectedEquipments = safetyEquipmentResponse.data.filter(
      (equipment) =>
        equipment.name === "Hard hat" ||
        equipment.name === "Steel toe boots" ||
        equipment.name === "Safety glasses"
    );
    return selectedEquipments;
  };

  const addCovidProject = async (values: ProjectWithBogus) => {
    try {
      const safetyEquipments = await getSafetyEquipments();

      let bogusAuditors: IUserPerson[] = [];
      if (
        user?.role === "TemporalEnergyAuditor" ||
        user?.role === "EnergyAuditor" ||
        user?.role === "Training"
      ) {
        const currentAuditor = auditorsList?.find(
          (auditor) => auditor.userName === user?.userName
        );

        if (currentAuditor) {
          const cc: IUserPerson = {
            ...currentAuditor,
            stateId: 0,
            countryId: "",
            zip: "",
            phone: null,
          };

          bogusAuditors.push(cc);
        }
      }
      const project: ProjectDTO = {
        ...values,
        startDate: values.bogusStartDate
          ? `${values.bogusStartDate} ${values.bogusStartDateTime}`
          : null,
        buildingEnergyEfficiency: true,
        bogusSafetyEquipments: safetyEquipments,
        bogusAuditors: bogusAuditors,
      };

      const responseCreateProject = await projectService.create(
        user?.companyId,
        project
      );

      await projectHistoryService.log(
        responseCreateProject.data.id,
        "Project was created"
      );

      await projectActiveService.modifyActiveProject(
        responseCreateProject.data.id
      );

      if (user?.role === "Architect" || user?.role === "ArchitectAux") {
        navigate(
          `/app/ProjectWizard/Edit/${responseCreateProject.data.id}/${user?.companyId}`
        );
      } else {
        if (
          user?.role === "TemporalEnergyAuditor" ||
          user?.role === "EnergyAuditor" ||
          user?.role === "Auditor" ||
          user?.role === "Training"
        )
          navigate("/app/Working");
        else navigate(`/app/ProjectList/${user?.companyId}`);
      }
    } catch (error: any) {
      const message = error.message || "Something went wrong";
      log.error(message);
    }
  };
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };
  return (
    <>
      <Typography variant="h4" gutterBottom pt={10} pb={4} align="center">
        Confirmation and Payment
      </Typography>

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>#</TableCell>
                <TableCell>Description</TableCell>
                <TableCell>Price</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>1</TableCell>
                <TableCell>{payment?.itemName}</TableCell>
                <TableCell align="right">
                  {currencyformatter.format(payment?.cost ?? 0)}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={2} align="right">
                  Subtotal:
                </TableCell>
                <TableCell colSpan={1} align="right">
                  {currencyformatter.format(payment?.cost ?? 0)}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={2} align="right">
                  Taxes:
                </TableCell>
                <TableCell colSpan={1} align="right">
                  $0.00
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={2} align="right">
                  Discount:
                </TableCell>
                <TableCell colSpan={1} align="right">
                  {currencyformatter.format(
                    ((payment?.cost ?? 0) / 100) * (payment?.couponValue ?? 0)
                  )}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={2} align="right">
                  TOTAL:
                </TableCell>
                <TableCell colSpan={1} align="right">
                  {currencyformatter.format(payment?.total ?? 0)}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Grid>
        <Grid item xs={2}>
          <FormLabel text="Promotional code:" />
        </Grid>
        <Grid item xs={8}>
          <Stack direction="row">
            <FormText
              name="codeCoupon"
              label="Enter your code:"
              value={values.codeCoupon}
              onChange={handleInputChange}
              type={showPassword ? "text" : "password"}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                  </IconButton>
                </InputAdornment>
              }
            />
            {/* Coupon was successfully applied */}
          </Stack>
        </Grid>
        <Grid item xs={2}>
          <FormButton
            text="Validate Coupon"
            onClick={validateCouponHandler}
            size="small"
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12}>
          <Stack direction="row" spacing={2} justifyContent="center">
            <FormButton
              text="Create and Pay Now"
              onClick={createAndPayNowHandler}
              size="small"
            />
          </Stack>
        </Grid>
      </Grid>
      <DialogMessagePopup
        title={"Information"}
        text={confirmMessage()}
        showPopup={showConfirmModal}
        setShowPopup={setShowConfirmModal}
        onSave={() => createProjectAndPay()}
        onCancel={() => {
          setShowConfirmModal(false);
        }}
        isSubmitting={false}
      />
      <DialogMessagePopup
        title={"Insufficient funds in your AMP Wallet"}
        text={
          'You have insufficient funds to complete this purchase, please go to your "Wallet" module (Button Below) to complete this purchase.'
        }
        acceptTextButton="Wallet"
        showPopup={showInsufficientFundsModal}
        setShowPopup={setShowInsufficientFundsModal}
        onSave={() => {
          navigate(`/app/Wallet/${user?.companyId}`);
          setShowInsufficientFundsModal(false);
        }}
        onCancel={() => {
          setShowConfirmModal(false);
        }}
        isSubmitting={false}
      />
      <DialogMessagePopup
        title={"Information"}
        text={confirmMessage()}
        showPopup={showNoDefaultCardBodyModal}
        setShowPopup={setShowNoDefaultCardBodyModal}
        onSave={() => {
          navigate(`/app/Wallet/${user?.companyId}`);
          setShowNoDefaultCardBodyModal(false);
        }}
        onCancel={() => {
          setShowConfirmModal(false);
        }}
        isSubmitting={false}
      />
    </>
  );
};

export default Payment;
