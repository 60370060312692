import React, { useEffect } from "react";
import { Grid, Stack, Typography } from "@mui/material";
import { useForm, useLog } from "src/hooks";
import { Form } from "src/hooks/useForm";
import { FormCancelButton } from "src/components/formControls";
import Popup from "src/components/Popup";
import { ISettleDetail, ISettlementBalance } from "src/ts/interfaces";
import { ColumnType } from "src/types/enhancedTable";
import { useTable } from "src/hooks/useTable";
import { WalletService } from "src/services";

interface SettleDetailFormProps {
  selectedValue: ISettlementBalance;
  isDialogOpen: boolean;
  setIsDialogOpen: (val: boolean) => void;
}

const initialValues: ISettleDetail = {
  id: 0,
  movementId: 0,
  orderNumber: "",
  teamMember: "",
  amount: 0,
};

const SettleDetailForm = (props: SettleDetailFormProps) => {
  const { selectedValue, isDialogOpen, setIsDialogOpen } = props;
  const { log } = useLog();

  const { setValues } = useForm(initialValues, false, []);

  const getColumns = (): ColumnType[] => [
    {
      id: "movementId",
      label: "Movement Id",
      type: "string",
      sort: true,
    },
    {
      id: "orderNumber",
      label: "Order Number",
      type: "string",
      sort: true,
    },
    {
      id: "teamMember",
      label: "Team Member",
      type: "string",
      sort: true,
    },
    {
      id: "amount",
      label: "Amount",
      type: "custom",
      sort: true,
      callback: (row: ISettleDetail) => {
        const originalAmountValue = row.amount.toLocaleString("en-US");
        return (
          <>
            <Stack style={{ textAlign: "left" }}>
              <Typography>${originalAmountValue}</Typography>
            </Stack>
          </>
        );
      },
    },
  ];
  let columns = getColumns();
  const { Table, setRowsPerPage, setDataSet, setCount } = useTable(columns, {
    cellCheckBox: false,
  });

  useEffect(() => {
    if (isDialogOpen) setValues(selectedValue);
    const getData = async () => {
      try {
        const request = await WalletService.getSettleDetail(
          props.selectedValue.id
        );
        setDataSet(request.data);
        setCount(request.data.length);
        setRowsPerPage(10);
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      }
    };
    if (props.selectedValue.id !== 0) getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.selectedValue]);

  const handleClose = () => {
    setIsDialogOpen(false);
  };

  return (
    <>
      <Popup
        openPopup={isDialogOpen}
        setOpenPopup={setIsDialogOpen}
        title={"Settle Detail"}
        onClose={() => setIsDialogOpen(false)}
      >
        <Form>
          <Grid item xs={12}>
            <Table />
          </Grid>
        </Form>
        <>
          <Stack direction="row" spacing={2}>
            <FormCancelButton onClick={handleClose} isSubmitting={false} />
          </Stack>
        </>
      </Popup>
    </>
  );
};

export default SettleDetailForm;
