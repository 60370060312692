import axios from "../utils/axios";
import { IEmailClassification, IEmailUnsubscribe } from "../ts/interfaces";

const getEmailClassification = () => {
  return axios.get<IEmailClassification[]>(
    "EmailUnsubscribe/GetClassificationByRole"
  );
};

const saveNotification = (id: number) => {
  return axios.post<IEmailUnsubscribe>(`EmailUnsubscribe/Add?emailId=${id}`);
};

const deleteNotification = (id: number) => {
  return axios.delete<IEmailUnsubscribe>(`EmailUnsubscribe/Delete?id=${id}`);
};

const emailUnsubscribeService = {
  getEmailClassification,
  saveNotification,
  deleteNotification,
};

export default emailUnsubscribeService;
