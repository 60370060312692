import {
  CardContent,
  Grid,
  Divider as MuiDivider,
  Card as MuiCard,
} from "@mui/material";
import { spacing } from "@mui/system";
import styled from "@emotion/styled";
import { steps } from "./const/const";
import CompleteStudyPopup from "../components/CompleteStudyPopup";
import { useState } from "react";
import getProjectSingleService from "src/services/getProjectSingleService";
import {
  IProjectReport,
  ProjectDTO,
} from "src/ts/interfaces/project/projectDto";
import { useAsyncQuery, useAuth, useEffectOnce } from "src/hooks";
import projectReportService from "src/services/study/projectReportService";
import outletReportService from "src/services/study/outletReportService";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { IOutletReport, IReportType } from "src/ts/interfaces";
import { getCode, getStudyCode, initialValues } from "./util/util";
import StudyWizard from "../components/StudyWizard";
import reportTypeService from "src/services/study/reportTypeService";
import { FooterButtons } from "../components/FooterButtons";
import HeaderStudyPage from "src/components/page/HeaderStudyPage";

const Divider = styled(MuiDivider)(spacing);
const Card = styled(MuiCard)(spacing);
const row = 12;

const OutletStep4 = () => {
  const params = useParams<{ id: string }>();
  const id = parseInt(params?.id === undefined ? "0" : params?.id);
  const navigate = useNavigate();
  const { user } = useAuth();
  const location = useLocation();

  const [project, setProject] = useState<ProjectDTO | null>();
  const [reportType, setReportType] = useState<IReportType | null>();

  const { execute: executeReport, data: dataReport } =
    useAsyncQuery<IProjectReport>(projectReportService.report);

  const [outletMaster, setOutletReport] =
    useState<IOutletReport>(initialValues);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [parentStudy, setParentStudy] = useState<IProjectReport | null>();

  const getProject = async (projectId: number) => {
    const projectResponse = await getProjectSingleService.single(projectId);
    setProject(projectResponse.data);
  };

  const getParentReportIsComplete = async (
    reportId: number,
    reportType: string
  ) => {
    try {
      const res = await projectReportService.report(reportId, reportType);
      setParentStudy(res.data);
    } catch (error) {}
  };

  useEffectOnce(() => {
    const getData = async () => {
      const outletReportResponse = await outletReportService.getById(id);
      setOutletReport(outletReportResponse.data);

      await getParentReportIsComplete(
        outletReportResponse.data.id,
        getStudyCode(location.pathname)
      );
      await getProject(outletReportResponse.data.projectId);

      const studyType = getStudyCode(location.pathname);
      const reportTypeRes = await reportTypeService.getReportTypeByCode(
        studyType
      );
      setReportType(reportTypeRes.data);
      await executeReport(id, reportTypeRes.data.code);
    };
    if (id > 0) getData();
  });

  const previousStepHandler = async () => {
    const route = `/studies/${getCode(location.pathname)}/step3/${id}`;
    navigate(route);
  };

  const activeStep = 4;
  return (
    <>
      <HeaderStudyPage
        headerStudiesPage={{
          code: reportType?.code,
          system: outletMaster?.systemField,
          id: id,
        }}
        parentText="Project"
        parentLink="/"
      />
      <Divider my={6} />

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <StudyWizard
            steps={steps}
            activeStep={activeStep}
            projectId={outletMaster?.projectId}
            reportId={dataReport?.reportId}
            reportTypeId={dataReport?.reportTypeId}
            code={getCode(location.pathname)}
          />
        </Grid>
        <Grid item xs={12}>
          <Card mb={1}>
            <CardContent>
              <Grid container spacing={5}>
                <Grid item xs={row}>
                  {project && dataReport && outletMaster && (
                    <CompleteStudyPopup
                      isDialogOpen={isDialogOpen}
                      setIsDialogOpen={setIsDialogOpen}
                      project={project}
                      projectReport={dataReport}
                      completed={outletMaster?.isComplete}
                      infectiousComplianceControl={null}
                      parentStudy={parentStudy ?? null}
                    />
                  )}
                </Grid>
                <Grid item xs={12}>
                  <FooterButtons
                    activeStep={activeStep}
                    stepsCount={steps.length}
                    projectId={outletMaster?.projectId}
                    companyId={user?.companyId as number}
                    isSaving={false}
                    disabled={false}
                    reportName={reportType?.name ?? ""}
                    previousStepHandler={previousStepHandler}
                    saveHandler={() => {}}
                    isComplete={outletMaster?.isComplete ?? true}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default OutletStep4;
