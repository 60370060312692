import {
  IProjectReport,
  IProjectReportComments,
  IProjectReportsValidation,
} from "src/ts/interfaces/project/projectDto";
import {
  IProjectPurchase,
  IProjectPurchaseDetail,
  IProjectReporDisplayPursache,
  IProjectReporDisplayt,
  IProjectReportInfectiousComplianceControl,
  IReportType,
} from "src/ts/interfaces";
import axios from "src/utils/axios";

const getStudiesByProject = (projectId: number) => {
  return axios.get<IProjectReporDisplayt[]>(
    `ProjectReport/Reports/${projectId}`
  );
};

const getStudiesByContest = (projectId: number) => {
  return axios.get<IProjectReporDisplayt[]>(
    `ProjectReport/Reports/Contest?projectId=${projectId}`
  );
};

const getStudiesByProjectCart = (projectId: number) => {
  return axios.get<IProjectReporDisplayPursache[]>(
    `ProjectReport/ReportsCart/${projectId}`
  );
};

const removeSubStudy = (
  projectId: number,
  reportId: number,
  reportTypeId: number
) => {
  return axios.delete(
    `ProjectReport/Report/Remove/${projectId}/${reportId}/${reportTypeId}`
  );
};

const updateOrder = (studies: IProjectReporDisplayt[]) => {
  return axios.put("ProjectReport/UpdateOrder", studies);
};

const validateOpenDispute = (projectId: number) => {
  return axios.get<boolean>(`ProjectReport/ValidateOpenDispute/${projectId}`);
};

const validateReports = (projectId: number) => {
  return axios.get<IProjectReportsValidation>(
    `ProjectReport/ValidateReports/${projectId}`
  );
};

const canDeleteProject = (projectId: number) => {
  return axios.get<boolean>(`ProjectReport/CanDeleteProject/${projectId}`);
};

//Report
const projectReport = (
  reportId: number,
  reportTypeId: number,
  projectId: number
) => {
  return axios.get<IProjectReport>(
    `ProjectReport/ProjectReport/${reportId}/${reportTypeId}/${projectId}`
  );
};

const getInfectiousControl = (reportId: number) => {
  return axios.get<IProjectReportInfectiousComplianceControl>(
    `ProjectReport/InfectiousControl/${reportId}`
  );
};

const getProjectReportPurchase = (reportId: number) => {
  return axios.get<IProjectPurchase>(
    `ProjectReport/ProjectReportPurchase/${reportId}`
  );
};

const report = (reportId: number, reportType: string) => {
  return axios.get<IProjectReport>(
    `ProjectReport/Report/${reportId}?code=${reportType}`
  );
};

const systemSteady = (id: number, projectReport: IProjectReport) => {
  return axios.put(`ProjectReport/SystemSteady/${id}`, projectReport);
};

const getProjectReportComments = (id: number) => {
  return axios.get<IProjectReportComments[]>(
    `ProjectReport/ProjectComments/${id}`
  );
};

const save = (projectReport: IProjectReport) => {
  return axios.post<IProjectReport>("ProjectReport", projectReport);
};

const GetParentReport = (reportId: number, typeId: number) => {
  return axios.get<IReportType>(
    `ProjectReport/GetParentReport?id=${reportId}&reportTypeId=${typeId}`
  );
};

const GetPurchaseDetail = (typeId: number, projectId: number, code: string) => {
  return axios.get<IProjectPurchaseDetail>(
    `ProjectReport/InfectiousControl/PurchaseDetail/${typeId}/${projectId}/${code}`
  );
};

const projectReportService = {
  GetParentReport,
  getStudiesByProjectCart,
  getStudiesByProject,
  removeSubStudy,
  updateOrder,
  validateOpenDispute,
  validateReports,
  canDeleteProject,
  getStudiesByContest,
  projectReport,
  getInfectiousControl,
  report,
  systemSteady,
  getProjectReportComments,
  save,
  GetPurchaseDetail,
  getProjectReportPurchase,
};

export default projectReportService;
