import Popup from "src/components/Popup";
import { FormButton } from "src/components/formControls";
import { Stack, Typography } from "@mui/material";
import { IProjectOutForBidData } from "src/ts/interfaces/projectOutToBid";

interface AwardedProjectProps {
  awarededShowPopUp: boolean;
  setAwarededShowPopUp: (val: boolean) => void;
  currentRow: IProjectOutForBidData | undefined;
}

export default function AwardedProjectPopUp({
  awarededShowPopUp,
  setAwarededShowPopUp,
  currentRow,
}: AwardedProjectProps) {
  const dataInfo = `Copy the code that you see here: <b>${currentRow?.winningCode}</b>\nGo to the left side navigation menu and select 'Projects'.\nFind the 'Add Project By Code' button located at the upper right corner under 'Projects'.\nClick on the 'Add Project By Code' button.\nPaste the code that you copied earlier into the provided field.\nClick on the 'Accept' button.`;
  const arrayString = dataInfo.split("\n");
  return (
    <>
      <Popup
        title={"Information"}
        openPopup={awarededShowPopUp}
        setOpenPopup={setAwarededShowPopUp}
        onClose={() => {
          setAwarededShowPopUp(false);
        }}
        size="sm"
      >
        <>
          <Typography>
            To accept this project and begin working on it, you will need to
            follow these steps:
          </Typography>
          <br />
          {arrayString?.map((item, index) => {
            return (
              <Typography
                key={index}
                ml={3}
                dangerouslySetInnerHTML={{ __html: `${index + 1}. ${item}` }}
              />
            );
          })}
          <br />
          <Typography>
            By completing these steps, you will have confirmed your acceptance
            of the project and can start working on it. It's important to ensure
            that you enter the correct code and follow all the necessary steps
            to avoid any delays or issues with the project.
          </Typography>
        </>
        <Stack direction="row" spacing={3}>
          <FormButton
            variant="outlined"
            size="small"
            text="Close"
            onClick={() => {
              setAwarededShowPopUp(false);
            }}
          />
        </Stack>
      </Popup>
    </>
  );
}
