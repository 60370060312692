import styled from "@emotion/styled";
import { Bar } from "react-chartjs-2";
import { IDataBarChart } from "src/ts/interfaces/dataDoughnutChart";

interface ButtonProps {
  data: IDataBarChart;
}

const FormChartBar = (props: ButtonProps) => {
  const { data } = props;
  const ChartWrapper = styled.div`
    height: 400px;
    position: relative;
  `;
  const options = {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      y: {
        grid: {
          display: false,
        },
        stacked: false,
        ticks: {
          stepSize: 20,
        },
      },

      x: {
        stacked: false,
        grid: {
          color: "transparent",
        },
      },
    },
  };

  return (
    <ChartWrapper>
      <Bar data={data} options={options} />
    </ChartWrapper>
  );
};

export default FormChartBar;
