import { IStudyStep } from "src/ts/interfaces";

interface IHeadSheetType {
  title: string;
  key: number;
  code: string;
  route: string;
}

export const steps = [
  {
    label: "Step 1",
    key: 0,
    description: "General information",
    url: "/studies/headSheetSystem/step1/:id",
  },
  {
    label: "Step 2",
    key: 1,
    description: "Commissioning",
    url: "/studies/headSheetSystem/step2/:id",
  },
  {
    label: "Step 3",
    key: 2,
    description: "Nameplates Fan - Motor",
    url: "/studies/headSheetSystem/step3/:id",
  },
  {
    label: "Step 4",
    key: 3,
    description: "Additional components",
    url: "/studies/headSheetSystem/step4/:id",
  },
  {
    label: "Step 5",
    key: 4,
    description: "Testing and report",
    url: "/studies/headSheetSystem/step5/:id",
  },
  {
    label: "Step 6",
    key: 5,
    description: "Report",
  },
] as IStudyStep[];

export const headSheetType = [
  {
    title: "Engineered Fan Report",
    key: 3,
    code: "HSFS",
    route: "headSheetSystem",
  },
  {
    title: "Engineered Fan Array Report",
    key: 2,
    code: "HSFA",
    route: "headSheetArray",
  },
] as IHeadSheetType[];

export const initialValuesComm: any = {
  isProjectDisabled: false,
  //Visual
  visualOADamperPositionPercentage: true,
  visualOADamperpositionNotApplicable: false,
  visualOADamperpositionNotAccessible: false,
  visualRADamperPositionPercentage: true,
  visualRADamperpositionNotApplicable: false,
  visualRADamperpositionNotAccessible: false,
  visualMADamperPositionPercentage: true,
  visualMADamperpositionNotApplicable: false,
  visualMADamperpositionNotAccessible: false,
  visualAHUExhaustDamperPercentage: true,
  visualAHUExhaustDamperNotApplicable: false,
  visualAHUExhaustDamperNotAccessible: false,
  //BAS
  basOADamperPositionPercentage: true,
  basOADamperpositionNotApplicable: false,
  basOADamperpositionNotAccessible: false,
  basRADamperPositionPercentage: true,
  basRADamperpositionNotApplicable: false,
  basRADamperpositionNotAccessible: false,
  basMADamperPositionPercentage: true,
  basMADamperpositionNotApplicable: false,
  basMADamperpositionNotAccessible: false,
  basAHUExhaustDamperPercentage: true,
  basAHUExhaustDamperNotApplicable: false,
  basAHUExhaustDamperNotAccessible: false,
  firstUpdated: false,
  id: 0,
  dirtyFanBlades: false,
  fanBladesLoose: false,
  fanBladesMissing: false,
  waterCoilCarryover: false,
  drainPanClogged: false,
  microbialGrowthSuspected: false,
  dirtyCoils: false,
  dirtyFilters: false,
  wetInsulation: false,
  damagedInsulation: false,
  obviousDuctLeakage: false,
  canvasConnectionNeedsRepair: false,
  doorSealsLeak: false,
  louverDirty: false,
  restrictedAirflow: false,
  fanMotorRotation: false,
  fanMotorAirstream: false,
  beltSlippageStartup: false,
  beltSlippage: false,
  PulleyAlignment: false,
  damagedBelts: false,
  excessivevibration: false,
  excessiveBearingNoise: false,
  excessiveBearingHeat: false,
  visualOADamperposition: 0,
  visualRADamperposition: 0,
  visualMADamperposition: 0,
  visualAHUExhaustDamper: 0,
  endCoilBlankOff: false,
  coilFinDamage: false,
  basOADamperposition: 0,
  basRADamperposition: 0,
  basMADamperposition: 0,
  basAHUExhaustDamper: 0,
  headSheetId: 0,
};
