import React, { useState } from "react";
import styled from "@emotion/styled";
import { Helmet } from "react-helmet-async";
import {
  Grid,
  Divider as MuiDivider,
  Typography as MuiTypography,
} from "@mui/material";
import { spacing } from "@mui/system";
import DialogMessagePopup from "src/components/DialogMessagePopup";

const Divider = styled(MuiDivider)(spacing);

const Typography = styled(MuiTypography)(spacing);

const ProjectManagerDashboard = () => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  return (
    <React.Fragment>
      <Helmet title="Analytics Dashboard" />
      <Grid justifyContent="space-between" container spacing={6}>
        <Grid item>
          <Typography variant="h3" gutterBottom>
            Dashboard
          </Typography>
          <Typography variant="subtitle1">ProjectManager Dashboard</Typography>
        </Grid>
        <Grid item></Grid>
      </Grid>
      <Divider my={6} />
      <Grid container spacing={5}></Grid>
      <DialogMessagePopup
        title={"Information"}
        text={"under construction"}
        showPopup={showDeleteModal}
        setShowPopup={setShowDeleteModal}
        onSave={() => setShowDeleteModal(false)}
        isSubmitting={false}
      />
    </React.Fragment>
  );
};

export default ProjectManagerDashboard;
