import styled from "@emotion/styled";
import { Grid, Stack, Typography } from "@mui/material";
import { spacing } from "@mui/system";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FormButton } from "src/components/formControls";
import { GridActionButton } from "src/components/gridControls";
import LocalEnhancedTable from "src/components/localTable/LocalTable";
import Popup from "src/components/Popup";
import { useAuth, useLog } from "src/hooks";
import couponsWalletMovementService from "src/services/couponsWalletMovement";
import { ICouponsPrimaryEnergyAirtMovement } from "src/ts/interfaces";
import { ColumnType } from "src/types/enhancedTable";

interface PrimaryEnergyAirProps {
  showList: boolean;
  setShowList: (val: boolean) => void;
  userId: number;
  technicianName: string;
}

const PrimaryEnergyAirCouponsPopUp = (props: PrimaryEnergyAirProps) => {
  const { showList, setShowList } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [couponsData, setCouponsData] = useState<
    ICouponsPrimaryEnergyAirtMovement[]
  >([]);

  const { log } = useLog();
  const { user } = useAuth();
  const navigate = useNavigate();
  const Spacer = styled.div(spacing);

  const getColumns = (): ColumnType[] => [
    {
      id: "id",
      label: "Admin ID",
      type: "string",
      sort: false,
      hide: true,
    },
    {
      id: "projectName",
      label: "Project Name",
      type: "string",
      sort: true,
    },
    {
      id: "buildingName",
      label: "Building",
      type: "custom",
      sort: true,
      callback: (row: ICouponsPrimaryEnergyAirtMovement) => {
        return (
          <>
            <Typography>{row.buildingName}</Typography>
            <Typography>{row.buildingAddress}</Typography>
          </>
        );
      },
    },
    {
      id: "system",
      label: "System",
      type: "string",
      sort: true,
    },
    {
      id: "createdDate",
      label: "Date",
      type: "utcDate",
      format: "MM/DD/YYYY h:mma",
      sort: true,
    },
    {
      id: "actions",
      label: "Go To Project",
      type: "custom",
      sort: false,
      callback: (row: ICouponsPrimaryEnergyAirtMovement) => {
        return (
          <>
            <GridActionButton
              type="forward"
              onClick={() => {
                navigate(
                  `/app/Project/Edit/${row.projectId}/${user?.companyId}`
                );
              }}
            />
          </>
        );
      },
    },
  ];

  let columns = getColumns();

  useEffect(() => {
    const getData = async () => {
      try {
        setIsLoading(true);
        const request =
          await couponsWalletMovementService.getPrimaryEnergyAirCoupons(
            props.userId
          );

        setCouponsData(request.data);
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      } finally {
        setIsLoading(false);
      }
    };

    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const cancelHandler = () => {
    setShowList(false);
  };

  return (
    <Popup
      openPopup={showList}
      setOpenPopup={setShowList}
      title={"Primary Energy Air Reports Coupons Usage"}
      onClose={cancelHandler}
      size="md"
      isSubmitting={isLoading}
    >
      <Grid container>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={2}>
              <Typography sx={{ fontWeight: "bold" }}>
                Auditor / Technician:
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography>{props.technicianName}</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Spacer mb={4}></Spacer>
        <Grid item xs={12}>
          <LocalEnhancedTable<ICouponsPrimaryEnergyAirtMovement>
            refreshGrid={true}
            columns={columns}
            data={couponsData}
            showSkeleton={isLoading}
            hidePagination
          />
        </Grid>
      </Grid>

      <>
        <Stack
          direction="row"
          spacing={2}
          justifyContent="center"
          alignItems="center"
        >
          <FormButton
            onClick={cancelHandler}
            text="Close"
            variant="outlined"
            size="medium"
          />
        </Stack>
      </>
    </Popup>
  );
};

export default PrimaryEnergyAirCouponsPopUp;
