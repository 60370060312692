import { useEffect, useState } from "react";
import { IconButton, Stack } from "@mui/material";
import { ColumnType } from "src/types/enhancedTable";
import { ITicketData } from "src/ts/interfaces/tickets";
import { useTable } from "src/hooks/useTable";
import { FilterType } from "./types/FilterTypes";
import { getQueryString } from "./utils/common";
import TicketService from "src/services/ticketService";
import { InfoIcon, ImageIcon, HideImageIcon } from "src/components/icons";
import InformationTicketPopUp from "./components/InformationTicketPopUp";
import ImageTicketPopUp from "./components/ImageTicketPopUp";
import useLoading from "src/hooks/useLoading";
import { useLog } from "src/hooks";

const getColumns = (
  setShowInformationTickePopup: (val: boolean) => void,
  setInformationId: any,
  setShowImageTicketPopup: (val: boolean) => void
): ColumnType[] => [
  {
    id: "id",
    label: "ID",
    type: "string",
    sort: true,
    callback: (row: ITicketData) => {
      return row.id;
    },
  },
  {
    id: "classification",
    label: "Classification",
    type: "custom",
    sort: true,
    callback: (row: ITicketData) => {
      return row.classification.name;
    },
  },
  {
    id: "title",
    label: "Title",
    type: "string",
    sort: true,
    callback: (row: ITicketData) => {
      return row.title;
    },
  },
  {
    id: "createdBy",
    label: "Created By",
    type: "custom",
    sort: true,
    callback: (row: ITicketData) => {
      return `${row.anonymousName ?? ""}${
        row.created !== null ? row.created.name : ""
      } ${row.created !== null ? row.created.lastName : ""}`;
    },
  },
  {
    id: "createdDate",
    label: "Created Date",
    type: "date",
    format: "MM/DD/YYYY",
    sort: true,
  },
  {
    id: "priority",
    label: "Priority",
    type: "string",
    sort: true,
  },
  {
    id: "assignedTo",
    label: "Assigned To ",
    type: "custom",
    sort: true,
    callback: (row: ITicketData) => {
      return `${row.assignedTo.name} ${row.assignedTo.lastName} `;
    },
  },
  {
    id: "closeDate",
    label: "Closed Date",
    type: "date",
    format: "MM/DD/YYYY",
    sort: true,
  },
  {
    id: "action",
    label: "Action",
    type: "custom",
    sort: false,
    callback: (row: ITicketData) => (
      <>
        <Stack direction="row">
          <IconButton
            aria-label="delete"
            size="large"
            onClick={() => {
              setShowInformationTickePopup(true);
              setInformationId(row);
            }}
          >
            <InfoIcon />
          </IconButton>
          <IconButton aria-label="delete" size="large">
            {row.document === null ? (
              <HideImageIcon onClick={() => {}} />
            ) : (
              <ImageIcon
                onClick={() => {
                  setShowImageTicketPopup(true);
                  setInformationId(row);
                }}
              />
            )}
          </IconButton>
        </Stack>
      </>
    ),
  },
];

interface companyTableProps {
  filters: FilterType;
  refresh: boolean;
}

function ClosedTicket(props: companyTableProps) {
  const { log } = useLog();
  const [showInformationTicketPopup, setShowInformationTicketPopup] =
    useState(false);
  const [informationId, setInformationId] = useState([]);
  const [showImageTicketPopup, setShowImageTicketPopup] = useState(false);
  const [refresh, setRefresh] = useState(false);
  let columns = getColumns(
    setShowInformationTicketPopup,
    setInformationId,
    setShowImageTicketPopup
  );

  const { Table, setRowsPerPage, page, setDataSet, setCount, orderBy, order } =
    useTable(columns, { cellCheckBox: false });

  const { isLoading, startRequest, endRequest } = useLoading();

  const handleRefresh = () => {
    setRefresh(!refresh);
  };

  useEffect(() => {
    const getData = async () => {
      try {
        startRequest("getDataClosedTicket");
        const request = await TicketService.getAll(
          {
            page: page + 1,
            sortColumn: "createdDate",
            sortDec: order === "desc" ? true : false,
            filter: props.filters.filter,
            dateFrom: props.filters.from,
            dateTo: props.filters.to,
            status: "Closed",
          },
          getQueryString
        );
        endRequest("getDataClosedTicket");
        setCount(request.data.count);
        setDataSet(request.data.data);
        setRowsPerPage(10);
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      }
    };

    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    page,
    orderBy,
    order,
    setCount,
    setDataSet,
    setRowsPerPage,
    props.filters,
  ]);

  useEffect(() => {
    if (!isLoading) handleRefresh();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  return (
    <>
      <Table />
      {showInformationTicketPopup ? (
        <InformationTicketPopUp
          showInformationTicketPopup={showInformationTicketPopup}
          setShowInformationTicketPopup={setShowInformationTicketPopup}
          informationId={informationId}
        />
      ) : null}
      {showImageTicketPopup ? (
        <ImageTicketPopUp
          showImageTicketPopup={showImageTicketPopup}
          setShowImageTicketPopup={setShowImageTicketPopup}
          informationId={informationId}
        />
      ) : (
        ""
      )}
    </>
  );
}

export default ClosedTicket;
