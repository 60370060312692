import axios from "../utils/axios";
import {
  IForwardDocumentLibraryFilter,
  IForwardDocumentLibraryDataPage,
} from "../ts/interfaces/history";
import { getFilter } from "../utils/utils";

const getForwardDocumentHistory = async (
  filter: IForwardDocumentLibraryFilter,
  getQuery?: (filters: Record<string, any>) => string
) => {
  const queryString = getQuery ? getQuery(filter) : getFilter(filter);
  return await axios.get<IForwardDocumentLibraryDataPage>(
    `forwardDocumentHistory?${queryString}`
  );
};

const forwardDocumentHistoryService = {
  getForwardDocumentHistory,
};

export default forwardDocumentHistoryService;
