import axios from "src/utils/axios";
import { IOutletMasterItem } from "src/ts/interfaces";

const getOutletMasterId = (outletMasterId: number) => {
  return axios.get<IOutletMasterItem[]>(
    `OutletMasterItems?outletMasterId=${outletMasterId}`
  );
};

const updateItem = (item: IOutletMasterItem) => {
  return axios.put<IOutletMasterItem>(`OutletMasterItems/Item`, item);
};

const deleteItem = (id: number) => {
  return axios.delete(`OutletMasterItems/${id}`);
};

const addItem = (item: IOutletMasterItem) => {
  return axios.post<IOutletMasterItem>(`OutletMasterItems`, item);
};

const updateAll = (items: IOutletMasterItem[]) => {
  return axios.put<IOutletMasterItem[]>(`OutletMasterItems/AllItems`, items);
};

const outletMasterItemsService = {
  getOutletMasterId,
  updateItem,
  deleteItem,
  addItem,
  updateAll,
};

export default outletMasterItemsService;
