import React from "react";
import { Typography, styled } from "@mui/material";
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionDetails from "@mui/material/AccordionDetails";

interface FormCheckBoxProps {
  title: React.ReactNode;
  children: React.ReactNode;
}

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion
    sx={{ cursor: "auto" }}
    disableGutters
    elevation={0}
    square
    {...props}
  />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  cursor: "auto",
  "&:not(:last-child)": {
    borderBottom: 0,
    cursor: "auto",
  },
  "&:before": {
    display: "none",
    cursor: "auto",
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary sx={{ cursor: "auto" }} {...props} />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? "rgba(255, 255, 255, .05)" : "#F7F9FC",
  cursor: "auto",

  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
    cursor: "auto",
    "& .MuiTypography-root": {
      fontWeight: "500",
      cursor: "auto",
    },
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
  cursor: "auto",
}));

export default function FormAccordion(props: FormCheckBoxProps) {
  const { title, children } = props;

  return (
    <Accordion expanded={true} sx={{ cursor: "auto" }}>
      <AccordionSummary
        aria-controls="panel1a-content"
        id="panel1a-header"
        sx={{ cursor: "auto" }}
      >
        <Typography>{title}</Typography>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );
}
