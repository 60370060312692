import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import {
  Card as MuiCard,
  CardContent,
  Divider as MuiDivider,
  Grid,
  Typography,
  Box,
  Skeleton,
} from "@mui/material";
import { spacing } from "@mui/system";
import { useAsyncMutation, useAsyncQuery } from "src/hooks";
import HeaderPage from "src/components/page/HeaderPage";
import templateServices from "src/services/templateServices";
import { useNavigate, useParams } from "react-router-dom";
import { FormCancelButton, FormCheckBox } from "src/components/formControls";
import {
  ITemplate,
  ITemplateDisplay,
  ITemplateDisplayGroup,
} from "src/ts/interfaces/template";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAdjust,
  faArchive,
  faAtom,
  faBolt,
  faBookmark,
  faCertificate,
  faGears,
  faQrcode,
  faReorder,
  faShekel,
  faShield,
  faTable,
} from "@fortawesome/free-solid-svg-icons";
import { faEmpire, faTrello, faYelp } from "@fortawesome/free-brands-svg-icons";
import ConfigureStudyPopup from "./ConfigureStudyPopup";
import FormBackButton from "src/components/formControls/FormBackButton";
import { ConfigurableStudiesGroup } from "src/constants";
const style = {
  bgcolor: "background.default",
  paddingRight: "10px",
};
const Divider = styled(MuiDivider)(spacing);
const Card = styled(MuiCard)(spacing);

const ProjectTemplateStudies = () => {
  let navigate = useNavigate();
  let { template } = useParams<{ template: string }>();
  const { execute, data, isLoading } = useAsyncQuery<ITemplateDisplayGroup>(
    templateServices.getStudiesByTemplate
  );
  const {
    execute: executeTemplate,
    data: dataTemplate,
    setData: setDataTemplate,
  } = useAsyncQuery<ITemplate>(templateServices.getById);

  const { execute: executeStatus } = useAsyncMutation(
    templateServices.changeStatus,
    { successfulMessage: "The status was changed" }
  );

  const [configureStudyPopup, setConfigureStudyPopup] = useState({
    show: false,
    projectId: 0,
    projectReportId: 0,
    reportName: "",
    groupType: 0,
  });

  useEffect(() => {
    const getData = async () => {
      await execute(template);
      await executeTemplate(template);
    };

    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeStatus = async (e: any) => {
    setDataTemplate({ ...dataTemplate, enabled: e.target.value });
    await executeStatus(dataTemplate.projectId, e.target.value);
  };

  const handleOpenConfigurationPopup = (
    item: ITemplateDisplay,
    groupType: number
  ) => {
    setConfigureStudyPopup({
      show: true,
      projectId: dataTemplate.projectId,
      projectReportId: item.reportTypeId,
      reportName: item.reportTypeName,
      groupType: groupType,
    });
  };

  const handleBack = async () => {
    navigate(`/app/tolerances/${dataTemplate?.id}`);
  };

  const handleClose = async () => {
    navigate(`/app/templates`);
  };

  const icons: Record<string, any> = {
    "icon-shield": <FontAwesomeIcon icon={faShield} size="2x" color="orange" />,
    "icon-bolt": <FontAwesomeIcon icon={faBolt} size="2x" />,
    "icon-certificate": <FontAwesomeIcon icon={faCertificate} size="2x" />,
    "icon-adjust": <FontAwesomeIcon icon={faAdjust} size="2x" />,
    "icon-empire": <FontAwesomeIcon icon={faEmpire} size="2x" />,
    "icon-ge": <FontAwesomeIcon icon={faAtom} size="2x" />,
    "icon-yelp": <FontAwesomeIcon icon={faYelp} size="2x" />,
    "icon-reorder": <FontAwesomeIcon icon={faReorder} size="2x" />,
    "icon-table": <FontAwesomeIcon icon={faTable} size="2x" />,
    "icon-archive": <FontAwesomeIcon icon={faArchive} size="2x" />,
    "icon-bookmark": <FontAwesomeIcon icon={faBookmark} size="2x" />,
    "icon-trello": <FontAwesomeIcon icon={faTrello} size="2x" />,
    "icon-gears": <FontAwesomeIcon icon={faGears} size="2x" />,
    "icon-shekel": <FontAwesomeIcon icon={faShekel} size="2x" />,
    "icon-qrcode": <FontAwesomeIcon icon={faQrcode} size="2x" />,
  };

  const renderStudies = (
    items: ITemplateDisplay[],
    groupType: number,
    isLoading: boolean
  ) => {
    return (
      <>
        {isLoading && (
          <Grid container sx={style} spacing={2}>
            {Array.from({ length: 6 }, (_, i) => (
              <Grid item xs={4}>
                <Skeleton height={130}></Skeleton>
              </Grid>
            ))}
          </Grid>
        )}
        {!isLoading && (
          <Grid container sx={style} spacing={2}>
            {items?.map((studyTypes) => {
              return (
                <Grid item xs={4} key={studyTypes.id}>
                  <Card mb={1}>
                    <CardContent
                      onClick={() => {
                        handleOpenConfigurationPopup(studyTypes, groupType);
                      }}
                      sx={
                        studyTypes.reportTypeColor === "bg-grayDark"
                          ? {
                              backgroundColor: "text.disabled",

                              "&:hover": {
                                cursor: "not-allowed",
                              },
                            }
                          : {
                              "&:hover": {
                                backgroundColor: "primary.main",
                                color: "white",
                                cursor: "hand",
                              },
                            }
                      }
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Box sx={{ paddingRight: "5px" }}>
                          {icons[studyTypes.reportTypeIcon]}
                        </Box>
                        <Box>
                          <Typography>
                            <b>{studyTypes.reportLabel}</b>
                          </Typography>
                          <Typography>{studyTypes.reportTypeName}</Typography>
                        </Box>
                      </Box>
                    </CardContent>
                  </Card>
                </Grid>
              );
            })}
          </Grid>
        )}
      </>
    );
  };

  return (
    <>
      <HeaderPage
        title={`Template: ${dataTemplate ? dataTemplate.name : ""}`}
        parentText="Tolerances"
        parentLink={`/app/tolerances/${dataTemplate?.id}`}
      />
      <Divider my={6} />
      <Card mb={3} mr={5}>
        {dataTemplate && !dataTemplate.isDefault && (
          <CardContent>
            <Grid container xs={12}>
              <Grid item xs={12} textAlign={"center"}>
                <FormCheckBox
                  name={"enabled"}
                  label={`${
                    dataTemplate?.enabled ? "Deactivate" : "Activate"
                  } This Template`}
                  value={dataTemplate?.enabled}
                  onChange={handleChangeStatus}
                ></FormCheckBox>
              </Grid>
            </Grid>
          </CardContent>
        )}
      </Card>
      <Grid container spacing={2}>
        <Grid item xs={12} mb={3} mr={5}>
          <Card>
            <CardContent>
              <Typography variant="h6">Configurable Studies</Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} pt={30} sx={{ marginLeft: "8px" }}>
          <Grid container>
            <Grid item xs={12}>
              <Grid container sx={style} spacing={2}>
                {renderStudies(
                  data?.groupType1,
                  ConfigurableStudiesGroup.nonConfigurable,
                  isLoading
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} mb={3} mr={5}>
          <Card>
            <CardContent>
              <Typography variant="h6">Non-Configurable Studies</Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} pt={30} pl={16} sx={{ marginLeft: "8px" }}>
          <Grid container>
            <Grid item xs={12}>
              <Grid container sx={style} spacing={2}>
                {renderStudies(
                  data?.groupType3,
                  ConfigurableStudiesGroup.nonConfigurable,
                  isLoading
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {configureStudyPopup.show && (
        <ConfigureStudyPopup
          showTemplatePopUp={configureStudyPopup.show}
          setShowTemplatePopUp={() => {
            setConfigureStudyPopup({
              ...configureStudyPopup,
              show: !configureStudyPopup.show,
            });
          }}
          projectId={configureStudyPopup.projectId}
          projectReportId={configureStudyPopup.projectReportId}
          reportName={configureStudyPopup.reportName}
          groupType={configureStudyPopup.groupType}
          disable={dataTemplate.isDefault}
        ></ConfigureStudyPopup>
      )}
      {dataTemplate && (
        <Grid container p={3}>
          <Grid item xs={6} textAlign={"right"} p={1}>
            <FormBackButton
              onClick={handleBack}
              isSubmitting={false}
              disabled={false}
              showSkeleton={false}
            />
          </Grid>
          <Grid item xs={6} textAlign={"left"} p={1}>
            <FormCancelButton
              onClick={handleClose}
              isSubmitting={false}
              text={"Close"}
            />
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default ProjectTemplateStudies;
