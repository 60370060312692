import { useState } from "react";

function useFetch(apiFunc: any) {
  const [data, setData] = useState(null as any);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  //   useEffect(() => {
  //     setLoading(true);
  //     apiFunc
  //       .then((response: any) => {
  //         setData(response.data);
  //       })
  //       .catch((err: any) => {
  //         setError(err);
  //       })
  //       .finally(() => {
  //         setLoading(false);
  //       });
  //   }, [apiFunc]);

  const refetch = (...args: any) => {
    setLoading(true);
    apiFunc(...args)
      .then((response: any) => {
        setData(response.data);
      })
      .catch((err: any) => {
        setError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return { data, loading, error, refetch };
}

export default useFetch;
