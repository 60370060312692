import { Grid, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import {
  FormAcceptButton,
  FormCancelButton,
  FormTextArea,
} from "src/components/formControls";
import Popup from "src/components/Popup";
import { useForm, Form } from "src/hooks";
import WalletService from "src/services/walletService";
import { ICompanyData } from "src/ts/interfaces";
import useLog from "src/hooks/useLog";

interface BlockWalletForm {
  reason: string;
}

const initialValues: BlockWalletForm = {
  reason: "",
};

interface IBlockingWalletPopupProps {
  disablePopup: boolean;
  setDisablePopup: (val: boolean) => void;
  currentValues?: ICompanyData;
  onSave: () => void;
}

const BlockingWalletPopup = ({
  disablePopup,
  setDisablePopup,
  currentValues,
  onSave,
}: IBlockingWalletPopupProps) => {
  const { log } = useLog();
  const validate = (fieldValues = values) => {
    let temp: Record<string, string> = { ...errors };
    if (fieldValues.hasOwnProperty("reason")) {
      temp.reason = fieldValues.name ? "" : "This field is required.";
      temp.reason =
        fieldValues.reason.length > 500
          ? "only  500 characteres are allowed"
          : "";
    }

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, errors, setErrors, handleInputChange, resetForm } = useForm(
    initialValues,
    true,
    validate
  );

  const [isSubmitting, setIsSubmitting] = useState(false);
  const handleSave = async () => {
    try {
      setIsSubmitting(true);
      if (values.reason === "") {
        log.error("You must specify a reason for blocking the wallet.");
        setIsSubmitting(false);
      } else {
        await WalletService.blockWallet({
          id: currentValues!.id,
          reason: values.reason,
        });
        log.success("The AMP Wallet was blocked.");
        setIsSubmitting(false);
        setDisablePopup(false);
        resetForm();
        onSave();
      }
    } catch (error: any) {
      log.error(error?.message?.exceptionMessage ?? "Something went wrong");
    }
  };

  const handleClose = () => {
    resetForm();
    setDisablePopup(false);
  };

  return (
    <Popup
      title="Information"
      openPopup={disablePopup}
      setOpenPopup={setDisablePopup}
      onClose={handleClose}
    >
      <>
        <Typography variant="body2" gutterBottom>
          Reason for blocking the wallet for this Company:
        </Typography>
        <Form>
          <Grid container>
            <Grid item xs={12} mb={2}></Grid>
            <FormTextArea
              name="reason"
              label="Reason for Blocking:"
              value={values.reason}
              onChange={handleInputChange}
              error={errors.reason}
              placeholder="Be descriptive when entering the reason for Blocking"
            />
          </Grid>
        </Form>
      </>
      <Stack
        direction="row"
        spacing={2}
        justifyContent="flex-end"
        alignItems="flex-start"
      >
        <FormAcceptButton onClick={handleSave} isSubmitting={isSubmitting} />
        <FormCancelButton onClick={handleClose} isSubmitting={isSubmitting} />
      </Stack>
    </Popup>
  );
};

export default BlockingWalletPopup;
