import { IEnergyCost } from "../ts/interfaces/energyCost";
import axios from "../utils/axios";

const getById = async (id: number) => {
  return axios.get<IEnergyCost>("EnergyCost/building/" + id);
};

const update = async (id: number, data: IEnergyCost) => {
  return axios.put<any>("EnergyCost/" + id, data);
};

const create = async (data: IEnergyCost) => {
  return axios.post<any>("EnergyCost", data);
};

const energyCostService = {
  update,
  create,
  getById,
};

export default energyCostService;
