import React, { useEffect, useState } from "react";
import { ColumnType } from "src/types/enhancedTable";
import { IRecommendations } from "src/ts/interfaces/recommendations";
import {
  Chip as MuiChip,
  Card as MuiCard,
  CardContent,
  Grid,
  Tabs,
  Tab,
  Typography,
  Divider as MuiDivider,
} from "@mui/material";
import { SpacingProps, spacing, styled } from "@mui/system";
import { GridActionButton } from "src/components/gridControls";
import HeaderPage from "src/components/page/HeaderPage";
import LocalEnhancedTable from "src/components/localTable/LocalTable";
import {
  useAsyncMutation,
  useAsyncQuery,
  useForm,
  usePermissions,
} from "src/hooks";
import recommendationsService from "src/services/recommendationsService";
import DialogMessagePopup from "src/components/DialogMessagePopup";
import { useNavigate } from "react-router-dom";
import { RecommendationStatusTab } from "src/constants";
import {
  FormActionButton,
  FormDatePicker,
  FormRaitingStarts,
  FormText,
} from "src/components/formControls";
import moment from "moment";
import { Validator } from "src/ts/types/Validator";
import { Permission as PermissionTypes } from "src/ts/enums";

const Divider = styled(MuiDivider)(spacing);
const Card = styled(MuiCard)(spacing);
interface ChipProps extends SpacingProps {
  component?: React.ElementType;
  href?: string;
  icon?: JSX.Element | null;
}
const Chip = styled(MuiChip)<ChipProps>(spacing);
const initialValues: IRecommendations = {
  id: 0,
  title: "",
  description: "",
  createdDate: new Date(),
  updated: new Date(),
  recommendationsStatusId: 0,
  score: 0,
  recommendationsTypeId: 0,
  code: "",
  other: "",
};

const initialValuesFilter = {
  startDate: moment().startOf("month").format("MM/DD/YYYY"),
  endDate: moment().format("MM/DD/YYYY"),
  search: "",
};

const columns = (
  handleEdit: (id: number) => void,
  setReward: React.Dispatch<
    React.SetStateAction<{
      recommendation: IRecommendations;
      showPopup: boolean;
    }>
  >,
  isAll: boolean,
  tab: number,
  fullAccess: boolean
): ColumnType[] => [
  {
    id: "title",
    label: "Title",
    type: "custom",
    sort: false,
    callback: (row: IRecommendations) => {
      return (
        <>
          <Typography variant="subtitle2">
            {`(${row.code}) ${row.title}`}
          </Typography>
        </>
      );
    },
  },
  {
    id: "classification",
    label: "Classification",
    type: "custom",
    sort: false,
    callback: (row: IRecommendations) => {
      return <>{row.recommendationsType?.name}</>;
    },
  },
  {
    id: "description",
    label: "Description",
    type: "string",
    sort: true,
  },
  {
    id: "createdDate",
    label: "Created Date",
    type: "utcDate",
    sort: true,
    format: "MM/DD/yyyy",
  },
  {
    id: "status",
    label: "Status",
    type: "custom",
    sort: false,
    hide: isAll,
    callback: (row: IRecommendations) => {
      return (
        <>
          <Chip
            label={row.recommendationsStatus?.name}
            color={row.recommendationsStatus?.colorReact ?? "default"}
            m={1}
            size="small"
          />
        </>
      );
    },
  },
  {
    id: "score",
    label: "Score",
    type: "custom",
    sort: false,
    hide: tab < 3,
    callback: (row: IRecommendations) => {
      return <FormRaitingStarts value={row?.score ?? 0} />;
    },
  },
  {
    id: "actions",
    label: "Actions",
    type: "custom",
    sort: false,
    callback: (row: IRecommendations) => {
      return (
        <>
          {tab === RecommendationStatusTab.Accepted && fullAccess && (
            <GridActionButton
              type="money"
              onClick={() => {
                setReward({ recommendation: row, showPopup: true });
              }}
              tooltip="Change Status To Rewarded"
            />
          )}
          <GridActionButton
            type="edit"
            onClick={() => {
              handleEdit(row.id);
            }}
            tooltip="Edit"
          />
        </>
      );
    },
  },
];

function SuggestionBankListAdmin() {
  const navigate = useNavigate();
  const { execute, data, isLoading } = useAsyncQuery<IRecommendations[]>(
    recommendationsService.getByStatus
  );
  const [tab, setTab] = useState(1);
  const [refreshTable, setRefreshTable] = useState(true);
  const [reward, setReward] = useState<{
    recommendation: IRecommendations;
    showPopup: boolean;
  }>({
    recommendation: initialValues,
    showPopup: false,
  });
  const { fullAccess } = usePermissions(
    PermissionTypes.Commitee_Suggestion_Box
  );
  const validate = (fieldValues = filterValues) => {
    let temp: Record<string, string> = { ...errors };

    temp.startDate = new Validator(fieldValues, "startDate")
      .isRequired("The date is required.")
      .toString();

    temp.endDate = new Validator(fieldValues, "endDate")
      .isRequired("The date is required.")
      .toString();

    const end = new Date(fieldValues.endDate);
    const start = new Date(fieldValues.startDate);
    if (fieldValues.endDate && fieldValues.startDate) {
      if (end < start) {
        temp.startDate = `Start date can not be greater than  ${moment(
          end
        ).format("MM/DD/YYYY")}`;
        temp.endDate = `End date can not be less than  ${moment(start).format(
          "MM/DD/YYYY"
        )}`;
      } else {
        temp.startDate = "";
        temp.endDate = "";
      }
    }

    setErrors({
      ...temp,
    });

    if (fieldValues === filterValues)
      return Object.values(temp).every((x) => x === "");
  };

  const {
    values: filterValues,
    errors,
    setErrors,
    handleInputChange: handleFilterChange,
    setValues: setFilterValues,
  } = useForm(initialValuesFilter, true, validate);

  const {
    execute: executeReward,
    isSubmitting: isSubmittingReward,
    data: dataReward,
  } = useAsyncMutation(recommendationsService.reward, {
    successfulMessage: "The status was changed",
  });

  useEffect(() => {
    setFilterValues({
      ...filterValues,
      startDate: moment().startOf("month").format("MM-DD-YYYY"),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const getData = async () => {
      if (!validate()) return;

      const startDate = new Date(
        moment(filterValues.startDate)
          .set({
            hour: 0,
            minute: 0,
            second: 0,
            millisecond: 0,
          })
          .format("MM/DD/yyyy hh:mm:ss a")
      ).toUTCString();

      const endDate = new Date(
        moment(filterValues.endDate)
          .set({
            hour: 23,
            minute: 59,
            second: 59,
            millisecond: 999,
          })
          .format("MM/DD/yyyy hh:mm:ss a")
      ).toUTCString();
      await execute(tab, startDate, endDate);
    };

    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab, refreshTable]);

  useEffect(() => {
    if (dataReward) {
      if (!validate()) return;
      setRefreshTable(!refreshTable);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataReward]);

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setTab(newValue);
  };

  const handleEdit = (id: number) => {
    navigate(`/app/suggestionBankInfo/${id}/${tab}`);
  };

  const refreshHandler = () => {
    setRefreshTable(!refreshTable);
  };

  const handleConfirmReward = async () => {
    await executeReward(reward.recommendation.id);
    setReward({ ...reward, showPopup: false });
  };

  const handleToDate = (e: any) => {
    const date = new Date(e.target.value);
    if (
      !isNaN(date.getTime()) &&
      date.getFullYear() > 1000 &&
      new Date(filterValues?.startDate) > date
    ) {
      filterValues.startDate = e.target.value;
    }
    filterValues.endDate = e.target.value;
    setFilterValues({ ...filterValues });
  };

  const validateMinDate = (e: any) => {
    if (e.target.value !== "Invalid date") {
      let newValues = { ...filterValues };
      newValues.startDate = e.target.value;

      if (Date.parse(newValues.endDate) < Date.parse(newValues.startDate))
        setFilterValues({ ...newValues, endDate: newValues.startDate });
      else setFilterValues({ ...newValues });
    }
  };

  return (
    <>
      <HeaderPage
        title={"Suggestion Bank"}
        parentText={"Dashboard"}
        parentLink={`/`}
        pageYoutube="Suggestion Bank"
      ></HeaderPage>
      <Card mb={6}>
        <CardContent>
          <Grid container spacing={6}>
            <Grid item xs={2}>
              <FormDatePicker
                name="startDate"
                label="Date From"
                value={filterValues.startDate}
                onChange={validateMinDate}
                error={errors.startDate}
              />
            </Grid>
            <Grid item xs={2}>
              <FormDatePicker
                name="endDate"
                label="Date To"
                value={filterValues.endDate}
                onChange={handleToDate}
                minDate={filterValues.startDate}
                error={errors.endDate}
              />
            </Grid>
            <Grid item xs={6}>
              <FormText
                name="search"
                label="Search"
                value={filterValues.search}
                onChange={handleFilterChange}
                showSkeleton={false}
              />
            </Grid>
            <Grid item xs={2}>
              <FormActionButton
                mr={2}
                text="Search"
                size="medium"
                onClick={refreshHandler}
                isSubmitting={false}
                type="search"
              />
            </Grid>
          </Grid>
          <Divider my={3}></Divider>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Tabs
                value={tab}
                onChange={handleChangeTab}
                aria-label="Buildings"
              >
                <Tab
                  label="New"
                  id={RecommendationStatusTab.New.toString()}
                  value={RecommendationStatusTab.New}
                />
                <Tab
                  label="Pending"
                  id={RecommendationStatusTab.Pending.toString()}
                  value={RecommendationStatusTab.Pending}
                />
                <Tab
                  label="Accepted"
                  id={RecommendationStatusTab.Accepted.toString()}
                  value={RecommendationStatusTab.Accepted}
                />
                <Tab
                  label="Rewarded"
                  id={RecommendationStatusTab.Rewarded.toString()}
                  value={RecommendationStatusTab.Rewarded}
                />
                <Tab
                  label="Stored"
                  id={RecommendationStatusTab.Stored.toString()}
                  value={RecommendationStatusTab.Stored}
                />
              </Tabs>
            </Grid>
            <Grid item xs={12}>
              <LocalEnhancedTable<IRecommendations>
                refreshGrid={true}
                columns={columns(handleEdit, setReward, true, tab, fullAccess)}
                data={Object.values(data ?? [])}
                showSkeleton={isLoading}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <DialogMessagePopup
        title={"Information"}
        text={`Are you certain you want to change the status to "Rewarded"? This will sent a notification to the appropriate person in AMP to order an amazon gift card for the user: ${reward.recommendation.created?.name} ${reward.recommendation.created?.lastName}, email:${reward.recommendation.created?.email}`}
        showPopup={reward.showPopup}
        setShowPopup={() => {
          setReward({ ...reward, showPopup: !reward.showPopup });
        }}
        onSave={handleConfirmReward}
        isSubmitting={isSubmittingReward}
      ></DialogMessagePopup>
    </>
  );
}

export default SuggestionBankListAdmin;
