import {
  ITowerWeigtedTest,
  ITowerWeigtedTestItem,
} from "src/ts/interfaces/study/tower";
import axios from "src/utils/axios";

const getByTowerId = (id: number) => {
  return axios.get<ITowerWeigtedTest[]>(`TowerWeigtedTest/Tower/${id}`);
};

const updateTowerWeigtedTest = (test: ITowerWeigtedTest) => {
  return axios.put(`TowerWeigtedTest/${test.id}`, test);
};

const addTowerWeigtedTest = (test: ITowerWeigtedTest) => {
  return axios.post(`TowerWeigtedTest`, test);
};

const deleteTowerWeigtedTest = (itemId: number) => {
  return axios.delete(`TowerWeigtedTest/${itemId}`);
};

const updateTowerWeigtedTests = (tests: ITowerWeigtedTest[]) => {
  return axios.put(`TowerWeigtedTest`, tests);
};

const getTowerWeigtedTestItem = (weigtedTestId: number) => {
  return axios.get<ITowerWeigtedTestItem[]>(
    `TowerWeigtedTest/items?testId=${weigtedTestId}`
  );
};

const updateTowerWeigtedTestItem = (item: ITowerWeigtedTestItem) => {
  return axios.put(`TowerWeigtedTest/item/${item.id}`, item);
};

const addTowerWeigtedTestItem = (item: ITowerWeigtedTestItem) => {
  return axios.post<ITowerWeigtedTestItem>(`TowerWeigtedTest/item`, item);
};

const deleteTowerWeigtedTestItem = (itemId: number) => {
  return axios.delete(`TowerWeigtedTest/item/${itemId}`);
};

const towerWeigtedTestService = {
  getByTowerId,
  getTowerWeigtedTestItem,
  deleteTowerWeigtedTest,
  updateTowerWeigtedTestItem,
  updateTowerWeigtedTests,
  updateTowerWeigtedTest,
  addTowerWeigtedTest,
  deleteTowerWeigtedTestItem,
  addTowerWeigtedTestItem,
};

export default towerWeigtedTestService;
