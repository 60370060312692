import axios from "../utils/axios";
import { IRelease } from "../ts/interfaces/releaseVersion";

const getReleaseVersion = () => {
  return axios.get<IRelease>("Version/releaseNotes");
};

const releaseVersion = {
  getReleaseVersion,
};

export default releaseVersion;
