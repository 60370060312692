import { IMandatoryMinimumList } from "../ts/interfaces/catalogs/mandatoryMinimum";
import axios from "../utils/axios";

const getAll = async () => {
  return axios.get<IMandatoryMinimumList[]>("mandatoryMinimum");
};

const getByTypeId = async (id: number) => {
  return axios.get<IMandatoryMinimumList>(`mandatoryMinimum/${id}`);
};

const mandatoryMinimumService = {
  getAll,
  getByTypeId,
};

export default mandatoryMinimumService;
