import { Typography, Stack } from "@mui/material";
import moment from "moment";
import { NavigateFunction } from "react-router-dom";
import { GridActionButton } from "src/components/gridControls";
import { IAssets } from "src/ts/interfaces/companyAssets";
import { ColumnType } from "src/types/enhancedTable";

const AssetAvailableVehicleColumns = (
  setCurrentRow: (row: IAssets) => void,
  setShowSatelliteModal: (row: boolean) => void,
  setShowAssessmentHistory: (row: boolean) => void,
  setTransferAssigType: (row: string) => void,
  navigate: NavigateFunction,
  companyId: string,
  setShowEditModal: (row: boolean) => void,
  setShowAssigment: (row: boolean) => void,
  setShowAssignTransfer: (row: boolean) => void,
  setDeleteAssetModal: (row: boolean) => void,
  setOutOfServiceShowPopUp: (row: boolean) => void,
  fullAccess: boolean
): ColumnType[] => {
  return [
    {
      id: "vehicle.make",
      label: "Make",
      type: "custom",
      sort: true,
      callback: (row: IAssets) => <Typography>{row?.vehicle?.make}</Typography>,
    },
    {
      id: "vehicle.model",
      label: "Model",
      type: "custom",
      sort: true,
      callback: (row: IAssets) => (
        <Typography>{row?.vehicle?.model}</Typography>
      ),
    },
    {
      id: "vehicle.year",
      label: "Year",
      type: "custom",
      sort: true,
      callback: (row: IAssets) => <Typography>{row?.vehicle?.year}</Typography>,
    },
    {
      id: "assignedTo.name",
      label: "Assigned to",
      type: "custom",
      sort: true,
      callback: (row: IAssets) => {
        return (
          <>
            {!row.officeShop && row.assignedTo !== null && (
              <Typography>{`${row?.assignedTo?.name} ${row.assignedTo?.lastName}`}</Typography>
            )}
            {row.officeShop && !row.hasOrIsSatellite && (
              <Typography color="primary.main">Office/Shop</Typography>
            )}
            {row.officeShop && row.hasOrIsSatellite && (
              <Typography
                sx={{
                  cursor: "pointer",
                  textDecoration: "underline",
                }}
                color={"primary.main"}
                onClick={() => {
                  setTransferAssigType("assignedTo");
                  setCurrentRow(row);
                  setShowSatelliteModal(true);
                }}
              >
                Office/Shop
              </Typography>
            )}
          </>
        );
      },
    },
    {
      id: "requestTransfer.transferTo.name",
      label: "Transfer to",
      type: "custom",
      sort: true,
      callback: (row: IAssets) => {
        return (
          <>
            <Typography>
              {row?.requestTransfer?.transferTo?.name === undefined ? (
                ""
              ) : (
                <Typography>{`${row?.requestTransfer?.transferTo?.name} ${row?.requestTransfer?.transferTo?.lastName}`}</Typography>
              )}
              {row.requestTransfer !== null &&
                row.requestTransfer?.transferTo === null &&
                !row.hasOrIsSatellite && (
                  <Typography color="primary.main"> Office/Shop</Typography>
                )}
              {row.requestTransfer !== null &&
                row.requestTransfer?.transferTo === null &&
                row.hasOrIsSatellite && (
                  <Typography
                    onClick={() => {
                      setTransferAssigType("transferTo");
                      setCurrentRow(row);
                      setShowSatelliteModal(true);
                    }}
                    sx={{
                      cursor: "pointer",
                      textDecoration: "underline",
                    }}
                    color={"primary.main"}
                  >
                    {" "}
                    Office/Shop
                  </Typography>
                )}
            </Typography>
          </>
        );
      },
    },

    {
      id: "actions",
      label: "",
      type: "custom",
      sort: false,
      callback: (row: IAssets) => {
        return (
          <Stack direction="row">
            {fullAccess && (
              <GridActionButton
                type="compare"
                onClick={() => {
                  if (row.requestTransfer != null) {
                    setCurrentRow(row);
                    setShowAssigment(true);
                  } else {
                    setCurrentRow(row);
                    setShowAssignTransfer(true);
                  }
                }}
                tooltip="Assign/Transfer Vehicle"
              />
            )}
            <GridActionButton
              type={fullAccess ? "edit" : "view"}
              onClick={() => {
                navigate(
                  `/app/companyAssets/Edit/${row.id}/${companyId}/VEHI/0`
                );
              }}
              tooltip="Edit"
            />
            <GridActionButton
              type="folder"
              onClick={() => {
                navigate(
                  `/app/companyAssetsDocument/${row.id}/${companyId}/VEHI`
                );
              }}
              tooltip="Project's Document Library"
            />
            <GridActionButton
              type="report"
              onClick={() => {
                setCurrentRow(row);
                setShowAssessmentHistory(true);
              }}
              tooltip="Assessment Report"
            />
            {fullAccess && (
              <GridActionButton
                type="disable"
                onClick={() => {
                  setCurrentRow(row);
                  setOutOfServiceShowPopUp(true);
                }}
                tooltip="Out of Service"
              />
            )}
            {row.canDelete && fullAccess && (
              <GridActionButton
                type="delete"
                onClick={() => {
                  setCurrentRow(row);
                  setDeleteAssetModal(true);
                }}
                tooltip="Delete"
              />
            )}
          </Stack>
        );
      },
    },
  ];
};

const AssetOutOfServiceVehicleColumns = (
  setCurrentRow: (row: IAssets) => void,
  setShowSatelliteModal: (row: boolean) => void,
  setShowAssessmentHistory: (row: boolean) => void,
  setTransferAssigType: (row: string) => void,
  navigate: NavigateFunction,
  companyId: string,
  setOutOfServiceShowPopUp: (row: boolean) => void,
  setReactiveAssetShowPopUp: (row: boolean) => void,
  fullAccess: boolean
): ColumnType[] => {
  return [
    {
      id: "vehicle.make",
      label: "Make",
      type: "custom",
      sort: true,
      callback: (row: IAssets) => <Typography>{row?.vehicle?.make}</Typography>,
    },
    {
      id: "vehicle.model",
      label: "Model",
      type: "custom",
      sort: true,
      callback: (row: IAssets) => (
        <Typography>{row?.vehicle?.model}</Typography>
      ),
    },
    {
      id: "vehicle.year",
      label: "Year",
      type: "custom",
      sort: true,
      callback: (row: IAssets) => <Typography>{row?.vehicle?.year}</Typography>,
    },
    {
      id: "assetOutOfService.createDate",
      label: "Date Removed",
      type: "custom",
      sort: true,
      callback: (row: IAssets) => (
        <Typography>
          {" "}
          {moment(row?.assetOutOfService?.createDate).format("MM/DD/yyyy")}
        </Typography>
      ),
    },
    {
      id: "assetOutOfService.createdBy.name",
      label: "Removed By",
      type: "custom",
      sort: true,
      callback: (row: IAssets) => (
        <Typography>
          {`${row?.assetOutOfService?.createdBy?.name} ${row?.assetOutOfService?.createdBy?.lastName}`}
        </Typography>
      ),
    },
    {
      id: "reason",
      label: "Reason",
      type: "custom",
      sort: false,
      callback: (row: IAssets) => (
        <Stack direction="column">
          <Typography>
            {row?.assetOutOfService?.removedFromService && "By Office/shop"}
            {row?.assetOutOfService?.lost && "Lost"}
            {row?.assetOutOfService?.stolen && "Stolen"}
          </Typography>

          <Typography
            sx={{ cursor: "pointer", textDecoration: "underline" }}
            variant="overline"
            onClick={() => {
              setCurrentRow(row);
              setOutOfServiceShowPopUp(true);
            }}
          >
            View Detail
          </Typography>
        </Stack>
      ),
    },
    {
      id: "actions",
      label: "Actions",
      type: "custom",
      sort: false,
      callback: (row: IAssets) => {
        return (
          <Stack direction="row">
            <GridActionButton
              type="forward"
              onClick={() => {
                navigate(
                  `/app/companyAssets/Edit/${row.id}/${companyId}/VEHI/1`
                );
              }}
              tooltip="View"
            />
            <GridActionButton
              type="folder"
              onClick={() => {
                navigate(
                  `/app/companyAssetsDocument/${row.id}/${companyId}/VEHI`
                );
              }}
              tooltip="Project's Document Library"
            />
            <GridActionButton
              type="report"
              onClick={() => {
                setCurrentRow(row);
                setShowAssessmentHistory(true);
              }}
              tooltip="Assessment Report"
            />
            {fullAccess && (
              <GridActionButton
                type="check"
                onClick={() => {
                  setCurrentRow(row);
                  setReactiveAssetShowPopUp(true);
                }}
                tooltip="Assign/Transfer Vehicle"
              />
            )}
          </Stack>
        );
      },
    },
  ];
};

export { AssetAvailableVehicleColumns, AssetOutOfServiceVehicleColumns };
