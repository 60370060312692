import { Grid } from "@mui/material";
import { Form } from "src/hooks";
import { FormTextArea } from "src/components/formControls";

interface Props {
  handleCommentInputChange: (e: any) => void;
  values: any;
  commentErrors: Record<string, string>;
}

const CompleteComments = ({
  handleCommentInputChange,
  values,
  commentErrors,
}: Props) => {
  return (
    <Form>
      <Grid container spacing={5}>
        <Grid item xs={12}>
          <FormTextArea
            name="comment"
            label="Comment"
            value={values.comment}
            onChange={handleCommentInputChange}
            error={commentErrors.comment}
            placeholder=""
          />
        </Grid>
      </Grid>
    </Form>
  );
};

export default CompleteComments;
