import { useState } from "react";
import { IMutationOptions } from "../ts/interfaces";
import useLog from "./useLog";

type status = "idle" | "pending" | "success" | "error";

const useAsyncMutation = <T>(func: any, options?: IMutationOptions) => {
  const { log } = useLog();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [status, setStatus] = useState<status>("idle");
  const [data, setData] = useState<T>(null as any);

  const execute = async (...params: any[]) => {
    try {
      setIsSubmitting(true);
      setStatus("pending");
      const response = await func(...params);
      setData(response.data);

      if (
        !(
          options?.hideSuccessfulMessage &&
          options?.hideSuccessfulMessage === true
        )
      ) {
        log.success(options?.successfulMessage ?? "Action was sucessful");
      }

      setIsSubmitting(false);
      setStatus("success");
      if (options?.onSuccess) options?.onSuccess(response.data, params);
    } catch (error: any) {
      if (!options?.hideErrorMessage) {
        log.error(
          options?.errorMessage ??
            error?.message?.message ??
            error?.message?.exceptionMessage ??
            "Something went wrong"
        );
      }
      if (options?.onError) options?.onError(error);
      setStatus("error");
    } finally {
      setIsSubmitting(false);
    }
  };

  const resetData = () => {
    setData(null as any);
  };

  const mutation = { execute, data, isSubmitting, status, resetData };

  return mutation;
};

export default useAsyncMutation;
