import React, { useState } from "react";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import {
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  CardContent,
  Typography,
  Tab,
  Tabs,
  Grid,
} from "@mui/material";
import { spacing } from "@mui/system";
import useForm, { Form } from "src/hooks/useForm";
import FormText from "src/components/formControls/FormText";
import { FormActionButton, FormDatePicker } from "src/components/formControls";
import { FilterType } from "./types/FilterTypes";
import moment from "moment";
import { SearchIcon } from "src/components/icons";

import Published from "./Published";
import Rejected from "./Rejected";
import { Validator } from "src/ts/types";
import Authorized from "./Authorized";
import NotAuthorized from "./NotAuthorized";

const Divider = styled(MuiDivider)(spacing);
const Card = styled(MuiCard)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const initialValues: FilterType = {
  filter: "",
  start: moment().startOf("month").format("MM/DD/yyyy"),
  end: moment().format("MM/DD/yyyy"),
};

function Testimonials() {
  const [value, setValue] = React.useState(0);
  const [refreshGrid, setRefreshGrid] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const { values, errors, setErrors, setValues, handleInputChange } = useForm(
    initialValues,
    false,
    []
  );

  const validate = (fieldValues = values) => {
    let temp: Record<string, string> = { ...errors };

    temp.start = new Validator(fieldValues, "start")
      .isRequired("The date is required.")
      .toString();

    temp.end = new Validator(fieldValues, "end")
      .isRequired("The date is required.")
      .toString();

    const end = new Date(fieldValues.end);
    const start = new Date(fieldValues.start);
    if (fieldValues.end && fieldValues.start) {
      if (end < start) {
        temp.start = `Start date can not be greater than  ${moment(end).format(
          "MM/DD/YYYY"
        )}`;
        temp.end = `End date can not be less than  ${moment(start).format(
          "MM/DD/YYYY"
        )}`;
      } else {
        temp.start = "";
        temp.end = "";
      }
    }

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const validateMinDate = (e: any) => {
    if (e.target.value !== "Invalid date") {
      let newValues = { ...values };
      newValues.start = e.target.value;
      if (Date.parse(newValues.end) < Date.parse(newValues.start))
        setValues({ ...newValues, end: newValues.start });
      else setValues({ ...newValues });
    }
  };

  const handleRefreshGrid = () => {
    if (!validate) return;

    setRefreshGrid(!refreshGrid);
  };

  return (
    <React.Fragment>
      <Helmet title="Testimonials" />
      <Typography variant="h3" gutterBottom display="inline">
        Testimonials
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/">
          Dashboard
        </Link>
        <Typography>Tickets</Typography>
      </Breadcrumbs>

      <Divider my={6} />
      <Card mb={6}>
        <CardContent>
          <Grid
            container
            spacing={5}
            sx={{ borderBottom: 1, borderColor: "divider", marginBottom: 5 }}
          >
            <Grid item xs={4}>
              <FormDatePicker
                label={"Date From:"}
                name="start"
                disablePast={false}
                value={values.start}
                onChange={validateMinDate}
                error={errors.start}
                fullWidth={true}
              />
            </Grid>

            <Grid item xs={4}>
              <FormDatePicker
                label={"Date To:"}
                name="end"
                disablePast={false}
                value={values.end}
                onChange={handleInputChange}
                error={errors.end}
                fullWidth={true}
                minDate={values.start}
              />
            </Grid>
            <Grid item xs={4}>
              <FormActionButton
                mr={2}
                text="Search"
                size="medium"
                onClick={handleRefreshGrid}
                isSubmitting={isLoading}
                type="search"
                startIcon={<SearchIcon />}
              />
            </Grid>
            <Grid item xs={8}>
              <FormText
                name="filter"
                label="Type text to search"
                value={values.filter}
                onChange={handleInputChange}
                error={errors.filter}
              />
            </Grid>

            <Grid item xs={6}>
              <Tabs value={value} onChange={handleChange} aria-label="">
                <Tab label="Not Authorized" id="0" />
                <Tab label="Authorized" id="1" />
                <Tab label="Published" id="2" />
                <Tab label="Kept Private" id="3" />
              </Tabs>
            </Grid>
          </Grid>

          <Grid container spacing={10}>
            <Grid item xs={12}>
              <Form>
                <Grid container spacing={5}>
                  <Grid item xs={12}>
                    {value === 0 && (
                      <NotAuthorized
                        filters={values}
                        refresh={refreshGrid}
                        setIsRefresh={setIsLoading}
                      />
                    )}
                    {value === 1 && (
                      <Authorized
                        filters={values}
                        refresh={refreshGrid}
                        setIsRefresh={setIsLoading}
                      />
                    )}
                    {value === 2 && (
                      <Published
                        filters={values}
                        refresh={refreshGrid}
                        setIsRefresh={setIsLoading}
                      />
                    )}
                    {value === 3 && (
                      <Rejected
                        filters={values}
                        refresh={refreshGrid}
                        setIsRefresh={setIsLoading}
                      />
                    )}
                  </Grid>
                </Grid>
              </Form>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </React.Fragment>
  );
}

export default Testimonials;
