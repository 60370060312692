import { Grid } from "@mui/material";
import { ColumnType } from "src/types/enhancedTable";
import { Form } from "src/hooks";
import { FormCancelButton } from "src/components/formControls";
import LocalEnhancedTable from "src/components/localTable/LocalTable";
import { GridActionButton } from "src/components/gridControls";
import { BuildingDTO, IProjectBuilding } from "src/ts/interfaces";
import Popup from "src/components/Popup";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import BuildIcon from "@mui/icons-material/Build";

interface IBuildingProjectPopupProps {
  openPopup: boolean;
  setOpenPopup: (val: boolean) => void;
  data: IProjectBuilding[];
}

const BuildingProjectPopup = (props: IBuildingProjectPopupProps) => {
  const handleClose = () => {
    props.setOpenPopup(false);
  };

  const columns: ColumnType[] = [
    {
      id: "type",
      label: "Type",
      type: "custom",
      sort: false,
      callback: (row: IProjectBuilding) => {
        return (
          <div>
            {row.isInfectiousControlProject ? (
              <VerifiedUserIcon />
            ) : (
              <BuildIcon />
            )}
          </div>
        );
      },
    },
    {
      id: "name",
      label: "Project",
      type: "string",
      sort: true,
    },
    {
      id: "description",
      label: "Description",
      type: "string",
      sort: true,
    },
    {
      id: "createdDate",
      label: "Date Created",
      type: "utcDate",
      sort: true,
      format: "MM/DD/yyyy h:mma",
    },
    {
      id: "projectStatus",
      label: "Status",
      type: "string",
      sort: true,
    },
    {
      id: "actions",
      label: "Actions",
      type: "custom",
      sort: false,
      callback: (row: BuildingDTO) => {
        return (
          <div>
            <GridActionButton type="edit" onClick={() => {}} tooltip="Edit" />
          </div>
        );
      },
    },
  ];

  return (
    <>
      <Popup
        title="Deletion error: You are trying to delete a building that is part of the following project(s)"
        openPopup={props.openPopup}
        setOpenPopup={props.setOpenPopup}
        onClose={handleClose}
        size={"md"}
      >
        <Form>
          <Grid item xs={12}>
            <LocalEnhancedTable<IProjectBuilding>
              refreshGrid={true}
              columns={columns}
              data={props.data}
              showSkeleton={false}
            />
          </Grid>
        </Form>
        <>
          <FormCancelButton onClick={handleClose} isSubmitting={false} />
        </>
      </Popup>
    </>
  );
};

export default BuildingProjectPopup;
