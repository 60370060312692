import { useContext } from "react";
import { LoadingContext } from "../contexts/LoadingContext";

const useLoading = () => {
  const context = useContext(LoadingContext);

  if (!context)
    throw new Error("LoadingContext must be placed within Loading Provider");

  return context;
};

export default useLoading;
