import {
  IEnergyCostSheduledAndBilling,
  IGasTotalCost,
} from "../ts/interfaces/energyCostSheduledAndBilling";
import axios from "../utils/axios";

const getById = async (id: number) => {
  return axios.get<IEnergyCostSheduledAndBilling>(
    "EnergyCostSheduledAndBilling/LimitedData/" + id
  );
};

const update = async (id: number, data: IEnergyCostSheduledAndBilling) => {
  return axios.put<any>("EnergyCostSheduledAndBilling/LimitedData/" + id, data);
};

const updateAll = async (data: IEnergyCostSheduledAndBilling[]) => {
  return axios.put<any>("EnergyCostSheduledAndBilling/SaveAll/", data);
};

const create = async (data: IEnergyCostSheduledAndBilling) => {
  return axios.post<any>("EnergyCostSheduledAndBilling", data);
};

const deleteGasCost = async (id: number) => {
  return axios.delete<any>(`EnergyCostSheduledAndBilling/gasTotalCost/${id}`);
};

const getGasCost = async (id: number) => {
  return axios.get<IGasTotalCost[]>(
    "EnergyCostSheduledAndBilling/gasTotalCost/" + id
  );
};

const getGasCostAnnualDetailed = async (id: number) => {
  return axios.get<IEnergyCostSheduledAndBilling[]>(
    "EnergyCostSheduledAndBilling/energyCost/AnnualDetailed/" + id
  );
};

const createGasCost = async (id: number, data: IGasTotalCost) => {
  return axios.post<any>(
    "EnergyCostSheduledAndBilling/gasTotalCost/" + id,
    data
  );
};

const energyCostSheduledAndBillingService = {
  update,
  create,
  getById,
  deleteGasCost,
  createGasCost,
  getGasCost,
  getGasCostAnnualDetailed,
  updateAll,
};

export default energyCostSheduledAndBillingService;
