import {
  CardContent,
  Grid,
  Divider as MuiDivider,
  Card as MuiCard,
  Stepper,
  Step,
  StepLabel,
  Stack,
} from "@mui/material";
import { spacing } from "@mui/system";
import styled from "@emotion/styled";
import { useEffect, useState } from "react";
import { useAppDispatch, useAuth, useEffectOnce } from "src/hooks";
import { IProjectReportInfectiousComplianceControl } from "src/ts/interfaces";
import { useNavigate, useParams } from "react-router-dom";
import projectReportService from "src/services/study/projectReportService";
import {
  IProjectReport,
  ProjectDTO,
} from "src/ts/interfaces/project/projectDto";
import FormBackButton from "src/components/formControls/FormBackButton";
import { FormCancelButton } from "src/components/formControls";
import getProjectSingleService from "src/services/getProjectSingleService";
import CompleteStudyPopup from "../components/CompleteStudyPopup";
import { floatingBarActions } from "src/redux/slices/floatingBarActions";
import { PROJECTMENU_ALL } from "src/constants";
import { IBoilerTest } from "src/ts/interfaces/study/boiler";
import boilerTestService from "src/services/study/boilerTestService";
import { steps } from "./components/const";
import HeaderStudyPage from "src/components/page/HeaderStudyPage";
import StudyWizard from "../components/StudyWizard";

const Divider = styled(MuiDivider)(spacing);
const Card = styled(MuiCard)(spacing);

const BoilerStep5 = () => {
  const params = useParams<{ id: string; code: string }>();
  const dispatch = useAppDispatch();
  const id = parseInt(params?.id === undefined ? "0" : params?.id);
  const navigate = useNavigate();
  const { user } = useAuth();

  const [boiler, setBoiler] = useState<IBoilerTest | null>();
  const [parentStudy, setParentStudy] = useState<IProjectReport | null>();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [project, setProject] = useState<ProjectDTO | null>();
  const [projectReport, setProjectReport] = useState<IProjectReport | null>();
  const [infectiousComplianceControl, setInfectiousComplianceControl] =
    useState<IProjectReportInfectiousComplianceControl | null>(null);

  const getParentReportIsComplete = async (
    reportId: number,
    reportType: string
  ) => {
    try {
      const res = await projectReportService.report(reportId, reportType);
      setParentStudy(res.data);
    } catch (error) {}
  };

  useEffectOnce(() => {
    const getData = async () => {
      const boilerResponse = await boilerTestService.getById(id);
      setBoiler(boilerResponse.data);
      await getParentReportIsComplete(boilerResponse.data.id, "BOTS");
      await getProject(boilerResponse.data.projectId ?? 0);
      await getProjectReport(
        boilerResponse.data.id,
        19,
        boilerResponse.data.projectId ?? 0
      );
      await getInfectiousControlData(boilerResponse.data.id);
    };
    if (id > 0) getData();
  });

  useEffect(() => {
    const getProjectID = () => {
      dispatch(
        floatingBarActions.setReport({
          projectId: boiler?.projectId ?? 0,
          reportId: id,
          reportTypeId: 4,
        })
      );
      dispatch(floatingBarActions.setChangeProjectId(boiler?.projectId ?? 0));
      dispatch(floatingBarActions.setOptionMenu(PROJECTMENU_ALL));
    };
    if (boiler) getProjectID();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [boiler]);

  const getInfectiousControlData = async (reportId: number) => {
    try {
      const res = await projectReportService.getInfectiousControl(reportId);
      setInfectiousComplianceControl(res.data);
    } catch (error: any) {
      //log.error(error?.message?.exceptionMessage ?? "Something went wrong");
    }
  };

  const getProjectReport = async (
    reportId: number,
    reportTypeId: number,
    projectId: number
  ) => {
    const projectReportResposne = await projectReportService.projectReport(
      reportId,
      reportTypeId,
      projectId
    );
    setProjectReport(projectReportResposne.data);
  };

  const getProject = async (projectId: number) => {
    const projectResponse = await getProjectSingleService.single(projectId);
    setProject(projectResponse.data);
  };

  const previousStepHandler = async () => {
    navigate(`/studies/BoilerTest/Step4/${id}`);
  };
  //ProjectStudies/713/58
  const cancelHandler = async () => {
    navigate(`/app/ProjectStudies/${project?.id}/${user?.companyId}`);
  };

  return (
    <>
      <HeaderStudyPage
        parentText="Project"
        parentLink="/"
        headerStudiesPage={{ code: "BOTS", system: boiler?.system, id: id }}
      />
      <Divider my={6} />

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <StudyWizard
            steps={steps}
            activeStep={4}
            projectId={boiler?.projectId}
            reportId={id}
            reportTypeId={boiler?.reportTypeId}
          ></StudyWizard>
        </Grid>
        <Grid item xs={12}>
          <Card mb={1}>
            <CardContent>
              <Grid container spacing={5}>
                <Grid item xs={12}>
                  {project && projectReport && boiler && (
                    <CompleteStudyPopup
                      isDialogOpen={isDialogOpen}
                      setIsDialogOpen={setIsDialogOpen}
                      project={project}
                      projectReport={projectReport}
                      completed={boiler?.isComplete}
                      infectiousComplianceControl={infectiousComplianceControl}
                      parentStudy={parentStudy ?? null}
                    />
                  )}
                </Grid>
                <Grid item xs={12}>
                  <Stack
                    direction="row"
                    spacing={2}
                    justifyContent="center"
                    alignItems="center"
                  >
                    <FormBackButton
                      onClick={previousStepHandler}
                      isSubmitting={false}
                    />
                    <FormCancelButton
                      onClick={cancelHandler}
                      isSubmitting={false}
                    />
                  </Stack>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default BoilerStep5;
