import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import {
  Grid,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  CardContent,
  Typography,
  Tabs,
  Tab,
} from "@mui/material";
import { spacing } from "@mui/system";
import useForm, { Form } from "src/hooks/useForm";
import { IStates } from "src/ts/interfaces/projectOutToBid";
import { ICountry } from "src/ts/interfaces/country";
import { FormText, FormSelect, FormButton } from "src/components/formControls";
import ProjectsAvailable from "./ProjectsAvailable";
import { COUNTRIES, ROLES } from "src/constants";
import stateService from "src/services/stateService";
import {
  CountryService,
  NotificationService,
  userCountryAccessService,
} from "src/services";
import { useAppDispatch, useAuth, useLog } from "src/hooks";
import ProjectsApplied from "./ProjectsApplied";
import ProjectsDeclined from "./ProjectsDeclined";
import ProjectAwarded from "./ProjectAwarded";
import { signalrActions } from "src/redux/slices/signalrActions";
import { IKeyValue } from "src/ts/interfaces";

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Divider = styled(MuiDivider)(spacing);
const Card = styled(MuiCard)(spacing);

const initialValues = {
  search: "",
  buildingName: "",
  zipCode: "",
  countryId: null,
  stateId: "",
  projectCode: "",
};

const ProjectsOutForBid = () => {
  const { log } = useLog();
  const [value, setValue] = useState(0);
  const { user } = useAuth();
  const [countries, setCountries] = useState<
    IKeyValue<number | null | undefined, string | undefined>[]
  >([]);
  const [states, setStates] = useState<IStates[]>([]);
  const [refreshGrid, setRefreshGrid] = useState(false);
  const dispatch = useAppDispatch();
  const { values, handleInputChange, setValues } = useForm(
    initialValues,
    false,
    []
  );

  useEffect(() => {
    const getCountries = async () => {
      try {
        const response = await CountryService.getCountryList();
        setCountries(
          response.data.map((values: any) => {
            return { ...countries, key: values.id, value: values.name };
          })
        );
        setValues({ ...values, countryId: COUNTRIES.USA });
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      }
    };

    const getCountriesSuperAdmin = async () => {
      const request = await userCountryAccessService.getKeyValues();
      setCountries(request);
      let foundItem = request.find((count) => count.key === COUNTRIES.USA);
      if (!foundItem) setValues({ ...values, countryId: request[0].key });
      else setValues({ ...values, countryId: COUNTRIES.USA });
    };
    if (user?.role !== ROLES.SuperAdministrator) getCountries();
    else getCountriesSuperAdmin();
    NotificationService.readByType(13);
    dispatch(signalrActions.newOutToBid(0));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const getStates = async () => {
      try {
        const response = await stateService.getAll(values.countryId);

        setStates(
          response.data.map((state: any) => {
            return { ...states, key: state.id, value: state.name };
          })
        );
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      }
    };
    if (values.countryId === COUNTRIES.USA) getStates();
    else {
      setStates([]);
    }
    handleRefreshGrid();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.countryId]);

  const handleRefreshGrid = () => {
    setRefreshGrid(!refreshGrid);
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <>
      <Helmet title="Project Out For Bid" />
      <Typography variant="h3" gutterBottom display="inline">
        Projects Out For Bid
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/">
          Dashboard
        </Link>

        <Typography>Project Out For Bid</Typography>
      </Breadcrumbs>
      <Divider my={6} />
      <Card mb={6}>
        <CardContent>
          <Grid
            container
            spacing={10}
            sx={{ borderBottom: 1, borderColor: "divider", marginBottom: 5 }}
          >
            <Grid item xs={12}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                <Tab label="Projects Available" id="0" />
                <Tab label="Projects Applied" id="1" />
                <Tab label="Projects Awarded" id="2" />
                <Tab label="Projects Declined" id="3" />
              </Tabs>
            </Grid>
          </Grid>

          <Grid container spacing={10}>
            <Grid item xs={12}>
              <Form>
                <Grid container spacing={5}>
                  <Grid item xs={4}>
                    <FormText
                      name="search"
                      value={values.search}
                      label="Search"
                      onChange={handleInputChange}
                    />
                  </Grid>

                  <Grid item xs={4}>
                    <FormText
                      name="buildingName"
                      value={values.buildingName}
                      label="Building Name"
                      onChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <FormText
                      name="zipCode"
                      value={values.zipCode}
                      label="Zip Code"
                      onChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <FormSelect
                      name="countryId"
                      value={values.countryId}
                      label="Country"
                      onChange={handleInputChange}
                      options={countries}
                      defaultValue={{ key: COUNTRIES.USA, value: "" }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    {states.length > 0 ? (
                      <FormSelect
                        name="stateId"
                        value={values.stateId}
                        label="State"
                        onChange={handleInputChange}
                        options={states}
                        defaultValue={{ key: -1, value: "(All)" }}
                      />
                    ) : (
                      <FormText
                        name="state"
                        value={values.state}
                        label="State"
                        onChange={handleInputChange}
                      />
                    )}
                  </Grid>
                  <Grid item xs={4}>
                    <FormText
                      name="projectCode"
                      value={values.projectCode}
                      label="Project Code"
                      onChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <FormButton
                      color="primary"
                      text="Find Projects"
                      variant="contained"
                      size="medium"
                      onClick={handleRefreshGrid}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    {value === 0 && (
                      <ProjectsAvailable
                        filters={values}
                        refresh={refreshGrid}
                      />
                    )}
                    {value === 1 && (
                      <ProjectsApplied filters={values} refresh={refreshGrid} />
                    )}
                    {value === 2 && (
                      <ProjectAwarded filters={values} refresh={refreshGrid} />
                    )}
                    {value === 3 && (
                      <ProjectsDeclined
                        filters={values}
                        refresh={refreshGrid}
                      />
                    )}
                  </Grid>
                </Grid>
              </Form>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
};

export default ProjectsOutForBid;
