import {
  CardContent,
  Grid,
  Divider as MuiDivider,
  Card as MuiCard,
  Alert,
} from "@mui/material";

import { spacing } from "@mui/system";
import styled from "@emotion/styled";
import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import outletMasterService from "src/services/study/outletMasterService";
import {
  useAsyncQuery,
  useAuth,
  useLog,
  useSystemOfMeasurement,
} from "src/hooks";
import {
  FormCheckBox,
  FormCheckSwitch,
  FormRadioGroup,
  FormText,
} from "src/components/formControls";
import OutletMasterGrid from "../../components/OutletMasterGrid";
import useFormTyped from "src/hooks/useFormTyped";
import { IKeyValue, IOutletMaster, IOutletMasterItem } from "src/ts/interfaces";
import OuletTypeSelect from "src/components/studies/OuletTypeSelect";
import outletMasterItemsService from "src/services/study/outletMasterItemsService";
import meterDeviceService from "src/services/catalogs/meterDeviceService";
import { Mode } from "src/ts/types/common";
import projectReportService from "src/services/study/projectReportService";
import { IProjectReport } from "src/ts/interfaces/project/projectDto";
import { steps } from "../const/const";
import { psycometrics } from "src/constants";
import { FooterButtons } from "../../components/FooterButtons";
import StudyWizard from "../../components/StudyWizard";
import HeaderStudyPage from "src/components/page/HeaderStudyPage";

const initialValues: any = {
  projectName: "",
  systemField: "",
  description: "",
  supply: false,
  return: false,
  exhaust: false,
  outsideAir: false,
  systemTotal: false,
  outletTotal: false,
  fillNextToogle: false,
  temperatureSensibleDryBulb: false,
  heat: false,
  diffuserGrillerNeckSize: false,
  minimumOutletCFMRecorded: false,
  diffuserGrilleModel: false,
  // diffuserGrilleModel: false,
  // diffuserGrilleModel: false,
  // diffuserGrilleModel: false,
};

const Divider = styled(MuiDivider)(spacing);
const Card = styled(MuiCard)(spacing);

const OutletMaster = () => {
  const params = useParams<{ id: string }>();
  const id = parseInt(params?.id === undefined ? "0" : params?.id);
  const { user } = useAuth();
  const { log } = useLog();
  const { systemOfMeasurement } = useSystemOfMeasurement(true);

  let navigate = useNavigate();

  const [items, setItems] = useState<IOutletMasterItem[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const validate: any = (fieldValues = values) => {
    let temp: Record<string, string> = { ...errors };

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange } =
    useFormTyped<IOutletMaster>(initialValues, false, validate);

  const { execute: getOutletMaster } = useAsyncQuery<IOutletMaster>(
    outletMasterService.getById,
    {
      successfulMessage: "The Study was updating",
      onSuccess: (result: IOutletMaster) => {
        setValues(result);
      },
    }
  );

  const { execute: executeReport, data: dataReport } =
    useAsyncQuery<IProjectReport>(projectReportService.report);

  const { execute: getMeterDeviceKeyValues, data: meterDeviceKeyValue } =
    useAsyncQuery<IKeyValue<number, string>[]>(meterDeviceService.getKeyValues);

  const activeStep = 0;

  const disableStudy = useMemo(() => {
    return (
      values?.isComplete ||
      (dataReport?.isInUse && dataReport?.isInUseById !== user?.userId) ||
      dataReport?.isPartOfProject === false
    );
  }, [
    dataReport?.isInUse,
    dataReport?.isInUseById,
    dataReport?.isPartOfProject,
    user?.userId,
    values?.isComplete,
  ]);

  useEffect(() => {
    if (values?.projectId > 0)
      systemOfMeasurement.getByProject(values?.projectId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values?.projectId]);

  const getOutletMasterItem = async (outletMasterId: number) => {
    try {
      const itemsRes = await outletMasterItemsService.getOutletMasterId(
        outletMasterId
      );
      if (itemsRes.data.length === 0) {
        const newItem: any = {
          order: 1,
          device: "Hood",
          outletMasterId: outletMasterId,
        };
        const res = await outletMasterItemsService.addItem(newItem);
        setItems([res.data]);
      } else {
        setItems(itemsRes.data);
      }
    } catch (error: any) {
      log.error(error?.message?.exceptionMessage ?? "Something went wrong");
    }
  };

  useEffect(() => {
    const getData = async () => {
      setIsLoading(true);
      await getMeterDeviceKeyValues();
      await getOutletMaster(id);
      await executeReport(id, "OUMA");
      await getOutletMasterItem(id);

      setIsLoading(false);
    };

    if (id !== 0) getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    //
    if (values.heat) {
      setValues({
        ...values,
        dryBulbFId: values.dryBulbFId === 0 ? 1 : values?.dryBulbFId,
      });
    } else {
      setValues({
        ...values,
        dryBulbFId: 0,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.heat]);

  const updateOutletMasterHandler = async () => {
    const res = await outletMasterService.update(values.id, values);
    setValues(res.data);
  };

  const row = 12;

  const changeOutletTypeHandler = (
    supply: boolean,
    return_: boolean,
    exhaust: boolean,
    outsideAir: boolean
  ) => {
    const outletTotal = outsideAir ? false : values?.outletTotal;
    setValues({
      ...values,
      supply: supply,
      return: return_,
      exhaust: exhaust,
      outsideAir: outsideAir,
      outletTotal: outletTotal,
    });
  };

  const chageOuletTotal = (e: any) => {
    if (values?.outsideAir || values?.isComplete) return;
    handleInputChange(e);
  };

  const changeInputWithVerificationHandler = (e: any) => {
    if (values?.isComplete || values?.isProjectDisabled) return;
    handleInputChange(e);
  };

  const saveMaxAndMinHandler = (
    row: IOutletMasterItem,
    index: number
  ): IOutletMasterItem => {
    const toleranceMin = values.toleranceMin ?? 5;
    const toleranceMax = values.toleranceMax ?? 10;

    setValues({ ...values, toleranceMax, toleranceMin });

    const requiredCFM = row?.requiredCFM ?? 0;

    const min = requiredCFM - (requiredCFM / 100) * toleranceMin;
    const max = requiredCFM + (requiredCFM / 100) * toleranceMax;
    const maxMin = min + "/" + max;
    const itemModified = { ...row, min, max, maxMin };

    const newItems = items.map((item, i) =>
      i === index ? itemModified : item
    );

    setItems(newItems);

    return itemModified;
  };

  const nextStepHandler = async () => {
    await saveHandler();

    navigate(`/studies/OutletMasterStep2/${id}`);
  };

  const saveHandler = async () => {
    if (mode === "read&Write" && !disableStudy) {
      try {
        await updateOutletMasterHandler();
        await outletMasterItemsService.updateAll(items);
        log.info("Report was updated successfully");
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      }
    }
  };

  const mode: Mode = values.isComplete ? "read" : "read&Write";
  return (
    <>
      <HeaderStudyPage
        headerStudiesPage={{
          code: "OUMA",
          system: values?.systemField,
          id: id,
        }}
        parentText="Project"
        parentLink="/"
      />
      <Divider my={6} />

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <StudyWizard
            steps={steps}
            activeStep={activeStep}
            projectId={values?.projectId}
            reportId={dataReport?.reportId}
            reportTypeId={dataReport?.reportTypeId}
          />
        </Grid>
        <Grid item xs={12}>
          <Card mb={1}>
            <CardContent>
              <Grid container spacing={5}>
                <Grid item xs={row}>
                  <FormText
                    name="projectName"
                    label="Project Name"
                    value={values.projectName}
                    onChange={handleInputChange}
                    error={errors.projectName}
                    showSkeleton={isLoading}
                    mode={mode}
                    disabled={disableStudy}
                  />
                </Grid>
                <Grid item xs={row}>
                  <FormText
                    name="systemField"
                    label="System"
                    value={values.systemField}
                    onChange={handleInputChange}
                    error={errors.systemField}
                    showSkeleton={isLoading}
                    mode={mode}
                    disabled={disableStudy}
                    maxLength={50}
                  />
                </Grid>
                <Grid item xs={row}>
                  <FormText
                    name="description"
                    label="Description"
                    value={values.description}
                    onChange={handleInputChange}
                    error={errors.description}
                    showSkeleton={isLoading}
                    mode={mode}
                    disabled={disableStudy}
                  />
                </Grid>
                {dataReport?.parentReportId !== null && (
                  <>
                    <Grid item xs={12}>
                      <OuletTypeSelect
                        onChange={changeOutletTypeHandler}
                        supply={values.supply}
                        return_={values.return}
                        exhaust={values.exhaust}
                        outsideAir={values.outsideAir}
                        showSkeleton={isLoading}
                        mode={mode}
                        disabled={disableStudy}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <FormCheckBox
                        label="Part of System total"
                        name="systemTotal"
                        value={values.systemTotal}
                        onChange={handleInputChange}
                        showSkeleton={isLoading}
                        mode={mode}
                        disabled={disableStudy}
                      />
                    </Grid>
                    <Grid item xs={9}>
                      <FormCheckBox
                        label="Part of Outlet total"
                        name="outletTotal"
                        value={values.outletTotal}
                        onChange={chageOuletTotal}
                        showSkeleton={isLoading}
                        mode={mode}
                        disabled={disableStudy}
                      />
                    </Grid>
                  </>
                )}

                <Grid item xs={3}>
                  <FormCheckSwitch
                    label="Fill Next Item"
                    name="fillNextToogle"
                    value={values.fillNextToogle}
                    onChange={changeInputWithVerificationHandler}
                    showSkeleton={isLoading}
                    mode={mode}
                    disabled={disableStudy}
                  />
                </Grid>
                <Grid item xs={3}>
                  <FormCheckSwitch
                    label="Temperature Sensible Dry Bulb"
                    name="temperatureSensibleDryBulb"
                    value={values.temperatureSensibleDryBulb}
                    onChange={changeInputWithVerificationHandler}
                    showSkeleton={isLoading}
                    mode={mode}
                    disabled={disableStudy}
                  />
                </Grid>
                <Grid item xs={3}>
                  <FormCheckSwitch
                    label="Total Heat"
                    name="heat"
                    value={values.heat}
                    onChange={changeInputWithVerificationHandler}
                    showSkeleton={isLoading}
                    mode={mode}
                    disabled={disableStudy}
                  />
                </Grid>
                <Grid item xs={3}>
                  <FormCheckSwitch
                    label="Diffuser/Grille Neck Size"
                    name="diffuserGrillerNeckSize"
                    value={values.diffuserGrillerNeckSize}
                    onChange={changeInputWithVerificationHandler}
                    showSkeleton={isLoading}
                    mode={mode}
                    disabled={disableStudy}
                  />
                </Grid>
                <Grid item xs={3}>
                  <FormCheckSwitch
                    label={`Minimum Outlet ${systemOfMeasurement.get(
                      "cfm"
                    )} Recorded`}
                    name="minimumOutletCFMRecorded"
                    value={values.minimumOutletCFMRecorded}
                    onChange={changeInputWithVerificationHandler}
                    showSkeleton={isLoading}
                    mode={mode}
                    disabled={disableStudy}
                  />
                </Grid>
                <Grid item xs={3}>
                  <FormCheckSwitch
                    label="Diffuser / Grille Model"
                    name="diffuserGrilleModel"
                    value={values.diffuserGrilleModel}
                    onChange={changeInputWithVerificationHandler}
                    showSkeleton={isLoading}
                    mode={mode}
                    disabled={disableStudy}
                  />
                </Grid>
                {values?.heat && (
                  <Grid item xs={6}>
                    <FormRadioGroup
                      name="dryBulbFId"
                      label=""
                      row={true}
                      value={values?.dryBulbFId === 0 ? 1 : values?.dryBulbFId}
                      onChange={handleInputChange}
                      items={psycometrics}
                      showSkeleton={isLoading}
                      mode={mode}
                      disabled={disableStudy}
                    />
                  </Grid>
                )}
                <Grid item xs={12}>
                  <OutletMasterGrid
                    id={id}
                    diffuserGrillerNeckSize={values.diffuserGrillerNeckSize}
                    diffuserGrilleModel={values.diffuserGrilleModel}
                    items={items}
                    setItems={setItems}
                    meterDeviceKeyValue={meterDeviceKeyValue}
                    onSaveMaxAndMin={saveMaxAndMinHandler}
                    outletMaster={values}
                    updateOutletMaster={updateOutletMasterHandler}
                    isLoading={isLoading}
                    mode={mode}
                    disableStudy={disableStudy}
                    systemOfMeasurement={systemOfMeasurement}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Alert severity="error">
                    Warning: Make sure the AK Factor is correct for all
                    Meter/Devices being used
                  </Alert>
                </Grid>
                <Grid item xs={12}>
                  <FooterButtons
                    activeStep={activeStep}
                    stepsCount={steps.length}
                    projectId={values?.projectId}
                    companyId={user?.companyId as number}
                    isSaving={false}
                    disabled={false}
                    reportName={"Diffuser, Register & Grille Test Report"}
                    saveHandler={saveHandler}
                    nextStepHandler={nextStepHandler}
                    isComplete={values?.isComplete ?? true}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default OutletMaster;
