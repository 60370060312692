import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import {
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  CardContent,
  Typography,
  Grid,
  Stack,
} from "@mui/material";
import { spacing } from "@mui/system";
import useForm, { Form } from "src/hooks/useForm";
import FormText from "src/components/formControls/FormText";
import { FormActionButton, FormDatePicker } from "src/components/formControls";
import { FilterType } from "./types/FilterTypes";
import HistoryService from "src/services/historyService";
import moment from "moment/moment.js";
import { useTable } from "src/hooks/useTable";
import { ColumnType } from "src/types/enhancedTable";
import { getQueryString } from "./utils/common";
import { IHistoryData } from "src/ts/interfaces/history";
import { useLog } from "src/hooks";
import { Validator } from "src/ts/types/Validator";

const Divider = styled(MuiDivider)(spacing);
const Card = styled(MuiCard)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const initialValues: FilterType = {
  filter: "",
  from: moment().startOf("month").format("MM/DD/YYYY"),
  to: moment().format("MM/DD/YYYY"),
};

function History() {
  const [refreshGrid, setRefreshGrid] = useState(false);
  const { log } = useLog();

  const validate = (fieldValues = values) => {
    let temp: Record<string, string> = { ...errors };

    temp.from = new Validator(fieldValues, "from")
      .isRequired("The date is required.")
      .toString();

    temp.to = new Validator(fieldValues, "to")
      .isRequired("The date is required.")
      .toString();

    const end = new Date(fieldValues.to);
    const start = new Date(fieldValues.from);
    if (fieldValues.to && fieldValues.from) {
      if (end < start) {
        temp.from = `Start date can not be greater than  ${moment(end).format(
          "MM/DD/YYYY"
        )}`;
        temp.to = `End date can not be less than  ${moment(start).format(
          "MM/DD/YYYY"
        )}`;
      } else {
        temp.from = "";
        temp.to = "";
      }
    }

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, errors, setErrors, handleInputChange, setValues } = useForm(
    initialValues,
    false,
    validate
  );

  const getColumns = (): ColumnType[] => [
    {
      id: "code",
      label: "Code",
      type: "string",
      sort: true,
    },
    {
      id: "name",
      label: "Name",
      type: "string",
      sort: true,
      width: "50%",
    },
    {
      id: "pricing",
      label: "Pricing",
      type: "custom",
      sort: true,
      callback: (row: IHistoryData) => {
        return (
          <Stack style={{ textAlign: "right" }}>
            ${row.pricing.toLocaleString("en-US")}
          </Stack>
        );
      },
    },
    {
      id: "date",
      label: "Date",
      type: "utcDate",
      sort: true,
      format: "MM/DD/YYYY hh:mm A",
    },
    {
      id: "userName",
      label: "Updated By",
      type: "string",
      sort: true,
    },
  ];

  let columns = getColumns();

  const {
    Table,
    setRowsPerPage,
    page,
    setDataSet,
    setCount,
    orderBy,
    order,
    startRequest,
    endRequest,
  } = useTable(columns, { cellCheckBox: false });

  const handleRefreshGrid = () => {
    setRefreshGrid(!refreshGrid);
  };
  useEffect(() => {
    const getData = async () => {
      try {
        if (!validate()) return;
        startRequest("dataLoading");
        const request = await HistoryService.getAll(
          {
            page: page + 1,
            sortColumn: "createdDate",
            sortDec: order === "desc" ? true : false,
            filter: values.filter,
            dateFrom: values.from,
            dateTo: values.to,
          },
          getQueryString
        );

        setCount(request.data.count);
        setDataSet(request.data.data);
        setRowsPerPage(10);
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      } finally {
        endRequest("dataLoading");
      }
    };

    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, orderBy, order, setCount, setDataSet, setRowsPerPage, refreshGrid]);

  const validateMinDate = (e: any) => {
    if (e.target.value !== "Invalid date") {
      let newValues = { ...values };
      newValues.from = e.target.value;

      if (Date.parse(newValues.to) < Date.parse(newValues.from))
        setValues({ ...newValues, to: newValues.from });
      else setValues({ ...newValues });
    }
  };

  return (
    <>
      <Helmet title="System Costs History" />
      <Typography variant="h3" gutterBottom display="inline">
        System Costs History
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/">
          Dashboard
        </Link>
        <Typography>System Costs History</Typography>
      </Breadcrumbs>

      <Divider my={6} />
      <Card mb={6}>
        <CardContent>
          <Grid container spacing={10}>
            <Grid item xs={12}>
              <Form>
                <Grid container spacing={5}>
                  <Grid item xs={4}>
                    <FormDatePicker
                      label={"Date From"}
                      name="from"
                      disablePast={false}
                      value={values.from}
                      onChange={validateMinDate}
                      error={errors.from}
                    />
                  </Grid>

                  <Grid item xs={4} textAlign={"right"}>
                    <FormDatePicker
                      label={"Date To"}
                      name="to"
                      disablePast={false}
                      value={values.to}
                      onChange={handleInputChange}
                      error={errors.to}
                      minDate={values.from}
                    />
                  </Grid>
                  <Grid item xs={8}>
                    <FormText
                      name="filter"
                      label="Type text to search"
                      value={values.filter}
                      onChange={handleInputChange}
                      error={errors.filter}
                    />
                  </Grid>

                  <Grid item xs={4}>
                    <FormActionButton
                      mr={2}
                      text="Search"
                      size="medium"
                      onClick={handleRefreshGrid}
                      type="search"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Table />
                  </Grid>
                </Grid>
              </Form>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
}

export default History;
