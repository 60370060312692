interface SvgImageProps {
  color: string;
}

const SvgBtu = (props: SvgImageProps) => {
  const { color } = props;
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="623.000000pt"
      height="634.000000pt"
      viewBox="0 0 623.000000 634.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,634.000000) scale(0.100000,-0.100000)"
        fill={color}
        stroke="none"
      >
        <path
          d="M1625 5860 c-331 -3 -536 -9 -547 -15 -15 -9 14 -42 200 -232 119
-121 327 -334 462 -473 283 -292 396 -395 451 -412 59 -17 61 -25 29 -106 -16
-39 -37 -98 -45 -129 -61 -215 -148 -415 -300 -687 -30 -54 -55 -103 -55 -107
0 -19 -112 -23 -751 -23 l-676 -1 1 -475 1 -476 585 1 c322 1 602 5 623 9 34
8 37 7 37 -13 0 -50 34 -179 75 -286 107 -277 205 -423 406 -604 84 -76 137
-113 246 -174 76 -42 174 -89 216 -103 l77 -26 0 -554 0 -554 456 0 456 0 -3
547 -3 547 51 17 c61 20 83 31 148 74 269 173 314 209 462 373 108 120 172
212 231 329 47 93 115 284 132 370 l12 58 176 -6 c97 -4 373 -7 612 -8 l435
-1 0 481 0 481 -190 -2 c-104 -2 -418 -2 -697 -1 l-507 1 -34 68 c-18 37 -85
166 -149 287 -63 121 -119 232 -123 245 -4 14 -18 45 -32 70 -13 25 -36 72
-50 105 -47 112 -55 125 -105 162 -46 34 -51 35 -60 18 -6 -10 -35 -45 -64
-79 -106 -119 -139 -180 -169 -311 -6 -26 -11 -17 -37 75 -29 101 -30 117 -38
390 -5 157 -7 481 -5 720 l3 435 -452 -3 c-248 -2 -452 -5 -454 -6 -1 -2 -2
-356 -2 -787 0 -780 0 -784 -21 -846 l-22 -62 -24 72 c-35 104 -78 189 -122
244 -22 26 -65 79 -95 116 l-56 68 0 605 0 604 -82 -2 c-46 -1 -321 -4 -613
-8z m1381 -879 c220 -264 276 -350 352 -541 61 -153 176 -503 192 -585 26
-134 33 -151 79 -215 107 -146 177 -287 217 -435 24 -88 28 -119 28 -255 1
-180 -17 -261 -85 -389 -113 -211 -273 -341 -487 -393 -177 -43 -390 -17 -554
69 -144 75 -307 270 -362 433 -24 72 -30 106 -34 220 -8 244 30 366 246 773
229 435 281 572 311 817 12 98 16 455 6 550 -5 43 -4 62 3 55 5 -5 45 -52 88
-104z m-605 -876 c7 -33 12 -118 13 -190 1 -151 -4 -166 -93 -330 -72 -130
-132 -280 -163 -402 -19 -75 -23 -115 -23 -268 0 -177 1 -182 32 -272 79 -226
177 -365 363 -515 99 -80 256 -155 386 -184 105 -23 338 -15 440 15 181 52
321 140 471 294 174 179 260 368 278 612 9 112 -15 293 -55 416 -26 79 -106
247 -196 409 l-36 65 5 170 c3 130 10 193 27 268 13 54 27 94 31 90 3 -5 12
-26 19 -48 17 -54 67 -153 157 -308 86 -150 208 -391 248 -491 15 -38 39 -113
53 -165 24 -89 26 -111 26 -311 0 -200 -2 -222 -26 -311 -36 -134 -66 -203
-139 -324 -78 -129 -195 -265 -296 -346 -100 -79 -306 -201 -407 -240 -123
-48 -320 -82 -431 -75 -237 14 -472 96 -673 233 -252 172 -463 466 -539 750
-24 89 -27 116 -27 283 -1 158 3 200 22 288 38 169 117 356 242 572 104 181
178 334 207 430 l27 92 23 -73 c12 -41 28 -101 34 -134z"
        />
        <path
          d="M3017 3822 c-27 -53 -98 -186 -159 -294 -113 -203 -165 -327 -192
-461 -57 -273 43 -507 261 -614 74 -36 83 -38 173 -37 78 1 107 6 164 28 95
36 166 93 226 178 60 86 79 143 87 264 10 154 -24 288 -123 487 -73 145 -173
312 -221 367 -21 25 -54 65 -72 90 -32 43 -80 90 -91 90 -3 0 -27 -44 -53 -98z
m157 -401 c122 -203 169 -318 182 -445 15 -143 -51 -277 -161 -327 -50 -23
-79 -23 -144 -2 -108 34 -170 124 -179 259 -7 104 22 200 118 398 45 93 85
187 87 208 7 51 17 42 97 -91z"
        />
        <path
          d="M3902 5323 l3 -542 45 -46 c24 -25 49 -45 56 -45 23 0 282 248 709
675 237 238 443 441 460 453 16 11 26 24 22 29 -5 4 -299 10 -653 13 l-645 5
3 -542z"
        />
        <path
          d="M5243 5461 c-120 -114 -247 -239 -283 -277 -36 -38 -160 -159 -275
-269 -234 -223 -299 -288 -389 -390 -97 -110 -93 -96 -48 -157 21 -29 52 -86
68 -125 l29 -73 165 163 c91 90 212 206 271 259 58 53 265 255 460 448 194
193 375 369 401 390 l48 38 -39 49 c-57 72 -147 153 -170 153 -13 0 -100 -76
-238 -209z"
        />
        <path
          d="M588 5517 c-31 -39 -67 -93 -82 -119 l-27 -47 228 -223 c255 -250
227 -222 643 -642 322 -325 388 -386 423 -386 27 0 56 20 129 84 l56 50 -80
95 c-44 53 -178 195 -297 316 -119 121 -336 346 -484 500 -238 249 -386 396
-438 432 -14 11 -24 3 -71 -60z"
        />
        <path
          d="M393 4535 l2 -515 518 0 519 0 -43 53 c-24 28 -113 122 -199 208 -85
86 -247 250 -360 365 -201 205 -413 404 -430 404 -4 0 -8 -232 -7 -515z"
        />
        <path
          d="M5314 4536 c-274 -272 -497 -497 -494 -500 12 -12 394 -18 693 -11
l317 7 0 499 c0 275 -4 499 -8 499 -5 0 -233 -222 -508 -494z"
        />
        <path
          d="M4800 2391 c0 -18 130 -160 385 -421 132 -136 323 -333 425 -438 102
-106 190 -192 196 -192 20 0 24 88 24 571 l0 479 -499 0 c-275 0 -506 3 -515
6 -9 3 -16 1 -16 -5z"
        />
        <path
          d="M394 1852 l-1 -533 474 473 c261 260 498 500 528 534 l54 61 -527 -1
-527 -1 -1 -533z"
        />
        <path
          d="M4551 2074 c-14 -19 -58 -66 -99 -105 l-74 -69 45 -50 c25 -27 64
-66 88 -86 24 -19 123 -113 219 -207 199 -196 450 -439 559 -542 41 -38 105
-107 142 -152 60 -71 146 -153 161 -153 5 0 86 135 110 185 39 78 44 69 -143
265 -94 98 -175 180 -179 182 -5 2 -173 165 -376 363 -297 291 -418 406 -428
405 -1 0 -12 -16 -25 -36z"
        />
        <path
          d="M1676 1990 c-53 -40 -313 -287 -601 -571 -277 -272 -464 -453 -517
-499 -21 -18 -38 -39 -38 -47 0 -17 74 -132 118 -183 27 -32 32 -34 53 -22 41
22 182 153 544 506 489 477 593 578 646 623 l46 39 -101 90 c-55 49 -103 90
-106 91 -3 1 -22 -11 -44 -27z"
        />
        <path
          d="M3952 1556 l-52 -31 2 -552 3 -553 205 1 c489 2 995 17 1038 30 23 7
42 16 42 19 0 3 -19 16 -42 30 -23 14 -206 189 -408 390 -385 384 -714 701
-727 699 -5 -1 -32 -15 -61 -33z"
        />
        <path
          d="M2085 1471 c-109 -97 -1029 -1011 -1023 -1016 2 -1 35 -8 73 -16 51
-9 219 -13 628 -14 l557 -2 0 543 0 544 -60 30 c-33 16 -61 30 -62 30 -2 0
-52 -45 -113 -99z"
        />
      </g>
    </svg>
  );
};

export default SvgBtu;
