import React, { useState, useEffect, ChangeEvent } from "react";
import { Grid, Stack, Chip } from "@mui/material";
import { useForm, useLog, useAsyncQuery, useLoading, useAuth } from "src/hooks";
import {
  FormButton,
  FormCancelButton,
  FormTextArea,
} from "src/components/formControls";
import Popup from "src/components/Popup";
import { ColumnType } from "src/types/enhancedTable";
import LocalEnhancedTable from "src/components/localTable/LocalTable";
import {
  GridActionButton,
  GridUploadButton,
} from "src/components/gridControls";
import { Validator } from "src/ts/types";
import commentService from "src/services/commentService";
import { IComment, CommentDocument } from "src/ts/interfaces";
import MatchWordConfirmModal from "src/components/modals/MatchWordConfirmModal";
import { convertUTCDateToLocalDate } from "src/utils/utils";
import FileUtils from "src/utils/file";
import { COMMENTSTYPE } from "src/constants";
import commentDocumentService from "src/services/commentDocumentService";

const initialValues = {
  note: "",
};

const getColumns = (
  editHandler: (note: string) => void,
  editMode: boolean,
  setCurrentRow: (val: IComment) => void,
  handleFileUpload: (
    e: ChangeEvent<HTMLInputElement>,
    report: IComment
  ) => void,
  setDeleteModal: (val: boolean) => void,
  downloadDocument: (row: IComment) => void,
  setTypeDelete: (val: string) => void,
  userId: number,
  projectCompleted: boolean
): ColumnType[] => [
  { id: "id", label: "#", type: "string", sort: true },
  { id: "note", label: "Note", type: "string", sort: false },
  {
    id: "createdByName",
    label: "Created By",
    type: "string",
    sort: false,
  },
  {
    id: "createdDate",
    label: "Date",
    type: "utcDate",
    sort: true,
    format: "MM/DD/yyyy h:mma",
  },
  {
    id: "action",
    label: "Actions",
    type: "custom",
    sort: false,
    callback: (row: IComment, index: number) => (
      <Stack direction="row">
        {row.commentDocument && (
          <GridActionButton
            type="edit"
            tooltip="edit"
            onClick={() => {
              editHandler(row.note);
              setCurrentRow(row);
            }}
            disabled={userId !== row.userId || projectCompleted}
          />
        )}
        {!editMode && row.commentDocument && (
          <GridUploadButton
            accept="image/*"
            onChange={(e: any) => {
              handleFileUpload(e, row);
            }}
            index={index}
            disabled={userId !== row.userId}
          />
        )}
        {!editMode && row.commentDocument && (
          <GridActionButton
            type="delete"
            tooltip="Delete"
            onClick={() => {
              setTypeDelete("deleteRow");
              setCurrentRow(row);
              setDeleteModal(true);
            }}
            disabled={userId !== row.userId || projectCompleted}
          />
        )}
        {!row.commentDocument && (
          <GridActionButton
            type="picture"
            onClick={() => {
              setCurrentRow(row);
              downloadDocument(row);
            }}
            tooltip="Picture "
          />
        )}
        {!row.commentDocument && (
          <GridActionButton
            type="delete"
            tooltip="Delete"
            onClick={() => {
              setTypeDelete("deleteFile");
              setCurrentRow(row);
              setDeleteModal(true);
            }}
            disabled={userId !== row.userId || projectCompleted}
          />
        )}
      </Stack>
    ),
  },
];

const getColumnsDeficiencies = (
  editHandler: (note: string) => void,
  editMode: boolean,
  setCurrentRow: (val: IComment) => void,
  handleFileUpload: (
    e: ChangeEvent<HTMLInputElement>,
    report: IComment
  ) => void,
  setDeleteModal: (val: boolean) => void,
  downloadDocument: (row: IComment) => void,
  setTypeDelete: (val: string) => void,
  userId: number,
  projectCompleted: boolean
): ColumnType[] => [
  { id: "id", label: "#", type: "string", sort: true },
  { id: "note", label: "Note", type: "string", sort: false },
  {
    id: "createdByName",
    label: "Created By",
    type: "string",
    sort: false,
  },
  {
    id: "createdDate",
    label: "Date",
    type: "utcDate",
    sort: true,
    format: "MM/DD/yyyy h:mma",
  },
  {
    id: "status",
    label: "Status",
    type: "custom",
    sort: false,
    callback: (row: IComment) => {
      return (
        <>
          {row?.status !== null && row?.status === "Solved" && (
            <Chip label={row.status} color="success" variant="outlined" />
          )}
          {row?.status !== null && row?.status === "New" && (
            <Chip label={row.status} color="error" variant="outlined" />
          )}
        </>
      );
    },
  },
  {
    id: "updatedDate",
    label: "Date Solved",
    type: "custom",
    sort: true,
    callback: (row: IComment) => {
      return (
        <>
          {row?.status !== null && row?.status === "Solved" ? (
            convertUTCDateToLocalDate(row?.updatedDate ?? "")?.format(
              "MM/DD/yyyy h:mma"
            )
          ) : (
            <></>
          )}
        </>
      );
    },
  },
  {
    id: "action",
    label: "Actions",
    type: "custom",
    sort: false,
    callback: (row: IComment, index: number) => (
      <Stack direction="row">
        {row.commentDocument &&
          row?.status !== null &&
          row?.status !== "Solved" && (
            <GridActionButton
              type="edit"
              tooltip="edit"
              onClick={() => {
                editHandler(row.note);
                setCurrentRow(row);
              }}
              disabled={userId !== row.userId || projectCompleted}
            />
          )}
        {!editMode &&
          row.commentDocument &&
          row?.status !== null &&
          row?.status !== "Solved" && (
            <GridUploadButton
              accept="image/*"
              onChange={(e: any) => {
                handleFileUpload(e, row);
              }}
              index={index}
              disabled={userId !== row.userId}
            />
          )}
        {!editMode &&
          row.commentDocument &&
          row?.status !== null &&
          row?.status !== "Solved" && (
            <GridActionButton
              type="delete"
              tooltip="Delete"
              onClick={() => {
                setTypeDelete("deleteRow");
                setCurrentRow(row);
                setDeleteModal(true);
              }}
              disabled={userId !== row.userId || projectCompleted}
            />
          )}
        {!row.commentDocument && (
          <GridActionButton
            type="picture"
            onClick={() => {
              setCurrentRow(row);
              downloadDocument(row);
            }}
            tooltip="Picture "
          />
        )}
        {!row.commentDocument &&
          row?.status !== null &&
          row?.status !== "Solved" && (
            <GridActionButton
              type="delete"
              tooltip="Delete"
              onClick={() => {
                setTypeDelete("deleteFile");
                setCurrentRow(row);
                setDeleteModal(true);
              }}
              disabled={userId !== row.userId || projectCompleted}
            />
          )}
      </Stack>
    ),
  },
];

interface ConfirmModalProps {
  isDialogOpen: boolean;
  setIsDialogOpen: (val: boolean) => void;
  type: number;
  projectId: number;
  viewMode?: boolean;
  projectCompleted: boolean;
}

const CommentsModal = (props: ConfirmModalProps) => {
  const { isDialogOpen, setIsDialogOpen, type, projectId, projectCompleted } =
    props;
  const [refreshGrid, setRefreshGrid] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [currentRow, setCurrentRow] = useState<IComment>();
  const [deleteModal, setDeleteModal] = useState(false);
  const [typeDelete, setTypeDelete] = useState("");
  const [userId, setUserId] = useState(0);
  const { log } = useLog();
  const { user } = useAuth();

  const { startRequest, endRequest } = useLoading();
  const { execute, data, isLoading, setData } = useAsyncQuery<IComment[]>(
    commentService.getComment
  );

  const { values: filterValues } = useForm(initialValues, true, []);

  const title =
    type === COMMENTSTYPE.COMMENTS
      ? "Comment"
      : type === COMMENTSTYPE.PRIVATE_NOTES
      ? "Note"
      : "Deficiency";

  useEffect(() => {
    resetForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDialogOpen]);

  useEffect(() => {
    const getData = async () => {
      try {
        await execute(projectId, type);
        setUserId(user?.userId);
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      }
    };

    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshGrid]);

  useEffect(() => {
    const subItems = () => {
      if (data !== null) {
        const updatedData = data.map((item: IComment) => {
          if (
            item.commentDocument !== undefined &&
            item.commentDocument.length > 0
          ) {
            const newSubItems = item.commentDocument.map(
              (itemDoc: CommentDocument) => {
                return {
                  note: itemDoc.name,
                  createdByName: itemDoc.createdBy
                    ? itemDoc.createdBy.getFullName
                    : "",
                  createdDate: itemDoc.createdDate,
                  id: itemDoc.id || 0,
                  status: item.status || "",
                  userId: item.userId,
                };
              }
            );

            return {
              ...item,
              subItems: [...(item.subItems || []), ...newSubItems],
            };
          } else {
            return item;
          }
        });
        setData(updatedData);
      }
    };

    subItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  const cancelHandler = () => {
    setIsDialogOpen(false);
  };

  const validate = (fieldValues = values) => {
    let temp: Record<string, string> = { ...errors };

    temp.note = new Validator(fieldValues, "note")
      .maxLength(500, "Only 500 character are allowed")
      .toString();
    temp.note = new Validator(fieldValues, "note")
      .isRequired(`The ${title} is required.`)
      .toString();

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues, false, validate);

  const addHandler = async () => {
    if (!validate()) return;

    try {
      await commentService.postComment({
        commentTypeId: type,
        note: values.note,
        projectId: projectId,
      });
      log.success(`${title} was saved`);
    } catch (error: any) {
      log.error(error?.message?.exceptionMessage ?? "Something went wrong");
    } finally {
      setRefreshGrid(!refreshGrid);
      resetForm();
    }
  };

  const resolvedHandler = async () => {
    try {
      await commentService.resolvedComment(currentRow?.id ?? 0);
      log.success("Deficiency changed it status to resolved");
    } catch (error: any) {
      log.error(error?.message?.exceptionMessage ?? "Something went wrong");
    } finally {
      setRefreshGrid(!refreshGrid);
    }
  };

  const updateHandler = async () => {
    try {
      await commentService.putComment(
        currentRow?.id ?? 0,
        { ...currentRow, note: values.note } ?? { note: "" }
      );
      log.success(`${title} was updated`);
      setRefreshGrid(!refreshGrid);
    } catch (error: any) {
      log.error(error?.message?.exceptionMessage ?? "Something went wrong");
    } finally {
      setEditMode(false);
      resetForm();
    }
  };

  const editHandler = (notes: string) => {
    setEditMode(true);
    setValues({ ...values, note: notes });
  };

  const cancelHandled = () => {
    setEditMode(false);
  };

  const handleFileUpload = async (
    e: ChangeEvent<HTMLInputElement>,
    rows: IComment
  ) => {
    if (!e.target.files) {
      return;
    }
    const file = e.target.files[0];
    startRequest("uploadfile");
    await commentDocumentService.uploadCommentFile({
      content: file,
      id: rows.id ?? 0,
    });
    setRefreshGrid(!refreshGrid);
    endRequest("uploadfile");
    log.success("File was uploaded succesfully");
  };

  const downloadDocument = async (row: IComment) => {
    if (row) {
      try {
        const response = await commentDocumentService.downloadCommentFile(
          Number(row?.id ?? 0)
        );

        let fileName = "";
        fileName = row?.note ?? "";
        FileUtils.downloadFile(response.data, fileName);
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      }
    } else {
    }
  };

  const deleteModalHandler = async () => {
    try {
      if (typeDelete !== "deleteFile") {
        await commentService.deleteCommentRow(currentRow?.id ?? 0);
      } else {
        await commentDocumentService.deleteCommentFile(currentRow?.id ?? 0);
      }
      log.success("The item was deleted");
      setRefreshGrid(!refreshGrid);
      setDeleteModal(false);
    } catch (error: any) {
      log.error(error?.message?.exceptionMessage ?? "Something went wrong");
    }
  };

  const columns =
    type !== COMMENTSTYPE.DEFICIENCIES
      ? getColumns(
          editHandler,
          editMode,
          setCurrentRow,
          handleFileUpload,
          setDeleteModal,
          downloadDocument,
          setTypeDelete,
          userId,
          projectCompleted
        )
      : getColumnsDeficiencies(
          editHandler,
          editMode,
          setCurrentRow,
          handleFileUpload,
          setDeleteModal,
          downloadDocument,
          setTypeDelete,
          userId,
          projectCompleted
        );

  return (
    <Popup
      openPopup={isDialogOpen}
      setOpenPopup={setIsDialogOpen}
      title={title}
      onClose={cancelHandler}
      size="md"
    >
      <Grid container spacing={6}>
        {!props.viewMode && (
          <Grid item xs={12}>
            <Stack direction="row" mb={2}>
              <FormTextArea
                name="note"
                label={title}
                value={values.note}
                onChange={handleInputChange}
                error={errors.note}
                placeholder=""
                disabled={projectCompleted}
              />
            </Stack>
            <Stack direction="row" spacing={2}>
              {!editMode && (
                <FormButton
                  text={"Add"}
                  onClick={() => {
                    addHandler();
                  }}
                  size="medium"
                  disabled={projectCompleted}
                />
              )}

              {editMode && (
                <FormButton
                  text={"Update"}
                  onClick={() => {
                    updateHandler();
                  }}
                  size="small"
                />
              )}
              {editMode && type === COMMENTSTYPE.DEFICIENCIES && (
                <FormButton
                  text={"Resolved"}
                  onClick={() => {
                    resolvedHandler();
                    cancelHandled();
                    resetForm();
                  }}
                  color="success"
                  size="small"
                />
              )}
              {editMode && (
                <FormCancelButton
                  onClick={() => {
                    cancelHandled();
                    resetForm();
                  }}
                  isSubmitting={false}
                />
              )}
            </Stack>
          </Grid>
        )}

        <Grid item xs={12}>
          <LocalEnhancedTable<any>
            refreshGrid={refreshGrid}
            columns={columns}
            data={data}
            query={filterValues.name}
            showSkeleton={isLoading}
            subItems="subItems"
            orderColumn="desc"
          />
        </Grid>
      </Grid>
      <>
        <Stack
          direction="row"
          spacing={2}
          justifyContent="center"
          alignItems="center"
        >
          <FormButton
            onClick={cancelHandler}
            text="Close"
            color="primary"
            size="medium"
            variant="outlined"
          />
        </Stack>
        <MatchWordConfirmModal
          onConfirm={deleteModalHandler}
          text={`Are you certain you want to delete this item?`}
          matchWord={"DELETE"}
          isDialogOpen={deleteModal}
          setIsDialogOpen={setDeleteModal}
          isSubmitting={false}
        />
      </>
    </Popup>
  );
};

export default CommentsModal;
