import React from "react";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import {
  Grid,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
  Typography,
  Box,
  Stack,
} from "@mui/material";
import { spacing } from "@mui/system";

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

function Default() {
  return (
    <React.Fragment>
      <Helmet title="Dashboard" />
      <Grid container>
        <Grid item xs={6} direction="row">
          <Typography variant="h3" gutterBottom display="inline">
            Dashboard
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} to="/">
              AMP Dashboard.
            </Link>
          </Breadcrumbs>
        </Grid>
        <Grid item xs={6}>
          <Stack direction="row" justifyContent="flex-end">
            <Box
              component="img"
              src="/static/img/COVID1.png"
              sx={{
                width: "5em",
              }}
            />
          </Stack>
        </Grid>
      </Grid>

      <Divider my={4} />

      <Grid container spacing={6}>
        <Grid item xs={12}></Grid>
      </Grid>
    </React.Fragment>
  );
}

export default Default;
