import { IStudyStep } from "src/ts/interfaces";

export const STEPS: IStudyStep[] = [
  {
    label: "Step 1",
    key: 0,
    description: "System configuration",
    url: "/studies/PitotTraverseStartTT/Step1/:id",
  },
  {
    label: "Step 2",
    key: 1,
    description: "Readings",
    url: "/studies/PitotTraverseStartTT/Step2/:id",
  },
  {
    label: "Step 3",
    key: 2,
    description: "Report",
    url: "/studies/PitotTraverseStartTT/Step3/:id",
  },
];
