import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { CssBaseline } from "@mui/material";
import { useAsyncQuery, useAuth } from "src/hooks";
import { IWizard } from "src/ts/interfaces/wizard";
import { useNavigate, useParams } from "react-router-dom";
import GlobalStyle from "src/components/GlobalStyle";
import DialogMessagePopup from "src/components/DialogMessagePopup";
import accountService from "src/services/accountService";
const Root = styled.div`
  max-width: 70%;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  display: flex;
  min-height: 100%;
  flex-direction: column;
`;

function ContactConfirmation() {
  const navigate = useNavigate();
  const { isAuthenticated } = useAuth();
  const [modalConfirm, setModalConfirm] = useState(false);
  let { code } = useParams<{ code: string }>();
  const { execute } = useAsyncQuery<IWizard>(accountService.confirm, {
    hideSuccessfulMessage: true,
    onSuccess: async () => {
      setModalConfirm(true);
    },
  });
  useEffect(() => {
    const data = async () => {
      await execute(code);
    };

    data();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAccept = async () => {
    if (isAuthenticated) navigate("/contactInformation");
    else navigate("/auth/sign-in");
  };

  return (
    <Root>
      <CssBaseline />
      <GlobalStyle />
      <DialogMessagePopup
        title={"Information"}
        text={"Email was confirmed "}
        showPopup={modalConfirm}
        setShowPopup={setModalConfirm}
        onSave={handleAccept}
        isSubmitting={false}
        disableClickOutside={true}
        hideCancel={true}
      ></DialogMessagePopup>
    </Root>
  );
}

export default ContactConfirmation;
