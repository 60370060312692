import React, { useEffect, useState } from "react";
import {
  Card as MuiCard,
  Divider as MuiDivider,
  Grid,
  Switch,
  Typography,
  Chip as MuiChip,
  CardContent,
} from "@mui/material";
import { SpacingProps, Stack, spacing } from "@mui/system";
import { Form, useAsyncQuery, useLog } from "src/hooks";
import { Validator } from "src/ts/types/Validator";
import { Permission as PermissionTypes } from "src/ts/enums";
import { usePermissions } from "src/hooks";
import HeaderPage from "src/components/page/HeaderPage";
import styled from "@emotion/styled";
import { useNavigate, useParams } from "react-router-dom";
import { ITermsAndConditions } from "src/ts/interfaces/catalogs/termsAndConditions";
import termsAndConditionsService from "src/services/termsAndConditionsService";
import {
  FormAcceptButton,
  FormButton,
  FormCancelButton,
  FormSaveButton,
  FormText,
} from "src/components/formControls";
import { AddIcon } from "src/components/icons";
import Popup from "src/components/Popup";
import { ITermsAndConditionsTabs } from "src/ts/interfaces/catalogs/termsAndConditionsTabs";
import { GridActionButton } from "src/components/gridControls";
import TermsAndConditionsTab from "./termsAndConditionsTab";
import MatchWordConfirmModal from "src/components/modals/MatchWordConfirmModal";
import { ITermsAndConditionsOptions } from "src/ts/interfaces/catalogs/termsAndConditionsOptions";
import useFormTyped from "src/hooks/useFormTyped";
import FormAccordion from "src/components/formControls/FormAccordion";

const Card = styled(MuiCard)(spacing);
const Divider = styled(MuiDivider)(spacing);
interface ChipProps extends SpacingProps {
  component?: React.ElementType;
  href?: string;
  icon?: JSX.Element | null;
}

const Chip = styled(MuiChip)<ChipProps>(spacing);
const initialValues: ITermsAndConditions = {
  id: 0,
  title: "",
  description: "",
  version: "",
  updatedDate: new Date(),
  updatedBy: "",
  termsAndConditionsTabs: [],
  isCurrent: false,
};

const tabs: ITermsAndConditionsTabs = {
  id: 0,
  termsAndConditionsId: 0,
  description: "",
  isRequired: true,
  isNew: true,
};

export type termsParams = {
  id: string;
  type: string;
};

const TermsAndConditionsForm = () => {
  let navigate = useNavigate();
  let params = useParams<termsParams>();
  const id = parseInt(params?.id === undefined ? "0" : params?.id);
  const type = params?.type;
  const { log } = useLog();
  const validate: any = (fieldValues: ITermsAndConditions = values) => {
    let temp: Record<string, string> = { ...errors };

    temp.title = new Validator(fieldValues, "title")
      .isRequired("The title is required")
      .toString();

    temp.description = new Validator(fieldValues, "description")
      .isRequired("The description is required")
      .toString();

    temp.version = new Validator(fieldValues, "version")
      .isRequired("The version is required")
      .toString();

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { execute, data, isLoading } = useAsyncQuery<ITermsAndConditions>(
    termsAndConditionsService.getCurrentById
  );

  const { values, setValues, errors, setErrors, handleInputChange } =
    useFormTyped<ITermsAndConditions>(initialValues, false, validate);

  const { fullAccess, readOnly } = usePermissions(PermissionTypes.Catalogs);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [popUpTab, setPopUpTab] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [newTab, setNewTab] = useState<ITermsAndConditionsTabs>(tabs);
  const [deleteModal, setDeleteModal] = useState(false);
  const [expanded, setExpanded] = React.useState<string | false>(false);
  useEffect(() => {
    const getData = async () => {
      await execute(id);
    };

    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (id === 0) {
      initialValues.termsAndConditionsTabs = [];
      setValues(initialValues);
    } else setValues(data);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const viewMode = () => {
    return type === "View";
  };

  const handleSwicthChange = (e: any) => {
    newTab.isRequired = !newTab.isRequired;
    setNewTab({ ...newTab });
  };

  const handleDescriptionChange = (e: any) => {
    newTab.description = e.target.value;
    setNewTab({ ...newTab });
  };

  const handleAddTab = () => {
    newTab.id =
      values.termsAndConditionsTabs.length === 0
        ? 0
        : values.termsAndConditionsTabs[
            values.termsAndConditionsTabs.length - 1
          ].id + 1;
    newTab.isNew = true;
    values.termsAndConditionsTabs.push(newTab);
    setValues({ ...values });
    setPopUpTab(false);
  };

  const handleEditTab = () => {
    setPopUpTab(false);
  };

  const handleEdit = (row: any, edit: boolean) => {
    setIsEdit(edit);
    setNewTab(row);
    setPopUpTab(true);
  };

  const deleteHandler = (row: any) => {
    setDeleteModal(true);
    setNewTab(row);
  };

  const handledeleteTab = () => {
    let filter = values.termsAndConditionsTabs.filter(
      (item: any) => item.id !== newTab.id
    );
    values.termsAndConditionsTabs = filter;
    setValues(values);
    log.success("Tab was deleted");
    setDeleteModal(false);
  };

  const handleCancel = () => {
    navigate(`/app/TermsAndConditions`);
  };

  const handleSaveOptions = (
    e: ITermsAndConditionsOptions[],
    tabId: number
  ) => {
    const findTab = values.termsAndConditionsTabs.find((element: any) => {
      return element.id === tabId;
    });
    if (findTab) {
      setNewTab(findTab);
      findTab.termsAndConditionsOptions = e;
      setNewTab(findTab);
    }
  };

  const handleSave = async () => {
    try {
      setIsSubmitting(true);
      await termsAndConditionsService.add(values);
      log.success("A new terms and conditions was created successfully");
      navigate("/app/TermsAndConditions");
    } catch (error: any) {
      log.error(error?.message?.exceptionMessage ?? "Something went wrong");
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  const listTabs = (values?.termsAndConditionsTabs ?? []).map(function (
    item: any
  ) {
    return (
      <div>
        <FormAccordion
          title={
            <Typography variant="subtitle1">
              {item.description}
              {"  "}
              {item.isRequired && (
                <Chip label="Required" color="primary" size="small" />
              )}
              {!viewMode() && (
                <>
                  <GridActionButton
                    type="edit"
                    onClick={() => {
                      handleEdit(item, true);
                    }}
                    tooltip="Edit"
                  />
                  <GridActionButton
                    type="delete"
                    onClick={() => {
                      deleteHandler(item);
                    }}
                    tooltip="Delete"
                  />
                </>
              )}
            </Typography>
          }
          expanded={expanded === `selected${item.id}`}
          onChange={handleChange(`selected${item.id}`)}
        >
          <TermsAndConditionsTab
            tabId={item.id}
            setOptions={handleSaveOptions}
            isNew={item.isNew}
          ></TermsAndConditionsTab>
        </FormAccordion>
      </div>
    );
  });

  return (
    <>
      <HeaderPage
        title={`Terms And Conditions`}
        parentText={"Terms And Conditions List"}
        parentLink={`/app/TermsAndConditions`}
        actionSection={
          <FormButton
            text={"Create New Tab"}
            onClick={() => {
              tabs.id = 0;
              tabs.termsAndConditionsId = 0;
              tabs.description = "";
              tabs.isRequired = true;
              handleEdit(tabs, false);
            }}
            startIcon={<AddIcon />}
            size="small"
            color="success"
            disabled={viewMode()}
          />
        }
      ></HeaderPage>
      <Divider my={6}></Divider>
      <Card mb={6}>
        <CardContent>
          <Form>
            <Grid container>
              <Grid item xs={8} pr={3} pb={3}>
                <FormText
                  name="title"
                  label="Title"
                  value={values?.title || ""}
                  onChange={handleInputChange}
                  error={errors.title}
                  disabled={isSubmitting || readOnly}
                  showSkeleton={isLoading}
                  mode={viewMode() ? "read" : undefined}
                />
              </Grid>
              <Grid item xs={4} pb={3}>
                <FormText
                  name="version"
                  label="Version"
                  value={values?.version || ""}
                  onChange={handleInputChange}
                  error={errors.version}
                  disabled={isSubmitting || readOnly}
                  showSkeleton={isLoading}
                  mode={viewMode() ? "read" : undefined}
                />
              </Grid>
              <Grid item xs={8} pr={3} pb={3}>
                <FormText
                  name="description"
                  label="Description"
                  value={values?.description || ""}
                  onChange={handleInputChange}
                  error={errors.description}
                  disabled={isSubmitting || readOnly}
                  showSkeleton={isLoading}
                  mode={viewMode() ? "read" : undefined}
                />
              </Grid>
              {viewMode() && (
                <Grid item xs={4} pb={3}>
                  <Grid container>
                    <Grid item xs={4}>
                      <Typography p={2}>Is Current Version</Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <Switch
                        name="isCurrent"
                        checked={values?.isCurrent}
                        value={true}
                        disabled={true}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              )}

              <Grid item xs={6} pr={3} pb={3}></Grid>
              <Grid item xs={12}>
                {listTabs}
              </Grid>
              <Grid item xs={12}>
                <Stack
                  direction="row"
                  spacing={2}
                  justifyContent="center"
                  alignItems="center"
                >
                  <FormCancelButton
                    onClick={handleCancel}
                    isSubmitting={isSubmitting}
                    disabled={isLoading}
                  />
                  {fullAccess && !viewMode() && (
                    <FormSaveButton
                      onClick={handleSave}
                      isSubmitting={isSubmitting}
                      disabled={isLoading}
                      showSkeleton={isLoading}
                    />
                  )}
                </Stack>
              </Grid>
            </Grid>
          </Form>
        </CardContent>
      </Card>

      <Popup
        title={"Tabs"}
        openPopup={popUpTab}
        setOpenPopup={setPopUpTab}
        onClose={() => {
          setPopUpTab(false);
        }}
        size={"sm"}
      >
        <Stack>
          <Grid container>
            <Grid item xs={12} pb={1}>
              <FormText
                name="description"
                label="Name"
                value={newTab?.description}
                onChange={handleDescriptionChange}
                disabled={isSubmitting || readOnly}
                showSkeleton={isLoading}
              />
            </Grid>
            <Grid item pt={2} xs={2}>
              <Typography>Is Required</Typography>
            </Grid>
            <Grid item xs={10}>
              <Switch
                name="isRequired"
                checked={newTab.isRequired}
                onChange={handleSwicthChange}
                value={true}
              />
            </Grid>
          </Grid>
        </Stack>

        <Stack
          direction="row"
          spacing={4}
          justifyContent="flex-end"
          alignItems="flex-start"
          pt={4}
        >
          <FormCancelButton
            onClick={() => {
              setPopUpTab(false);
            }}
            isSubmitting={isSubmitting}
          />
          <FormAcceptButton
            onClick={isEdit ? handleEditTab : handleAddTab}
            isSubmitting={isSubmitting}
          />
        </Stack>
      </Popup>
      <MatchWordConfirmModal
        onConfirm={handledeleteTab}
        text={`Are you certain you want to delete this tab`}
        matchWord={"DELETE"}
        isDialogOpen={deleteModal}
        setIsDialogOpen={setDeleteModal}
      />
    </>
  );
};

export default TermsAndConditionsForm;
