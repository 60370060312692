import { NotificationService } from "src/services";
import { AppThunk } from "../store";
import notificationsSlice from "./notificationsSlice";

export const notificationActions = notificationsSlice.actions;

export const fetchNewNotifications =
  (): AppThunk => async (dispatch, getState) => {
    const response = await NotificationService.getByUser();
    dispatch(notificationActions.setNotifications(response.data));
  };

export const archiveNotificationById =
  (id: number): AppThunk =>
  async (dispatch, getState) => {
    await NotificationService.read(id);
    dispatch(notificationActions.archiveNotification(id));
  };

export const archiveAllNotification =
  (): AppThunk => async (dispatch, getState) => {
    await NotificationService.readAll();
    dispatch(notificationActions.archiveAllNotification());
  };
