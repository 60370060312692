import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "@emotion/styled";
import useLog from "src/hooks/useLog";
import {
  CardContent,
  Grid,
  Card as MuiCard,
  Divider as MuiDivider,
  Stack,
  Stepper,
  Step,
  StepLabel,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";
import { useForm, Form } from "src/hooks";
import {
  FormCancelButton,
  FormSaveButton,
  FormText,
} from "src/components/formControls";
import useLoading from "src/hooks/useLoading";
import FormBackButton from "src/components/formControls/FormBackButton";
import { ColumnType } from "src/types/enhancedTable";
import LocalEnhancedTable from "src/components/localTable/LocalTable";
import { IGasFuelTypeEnergyCost } from "src/ts/interfaces/gasFuelTypeEnergyCost";
import HeaderPage from "src/components/page/HeaderPage";
import energyCostSheduledAndBillingService from "src/services/energyCostSheduledAndBillingService";
import { IEnergyCostSheduledAndBilling } from "src/ts/interfaces/energyCostSheduledAndBilling";
import energyCostSheduledAndBillingTotalService from "src/services/energyCostSheduledAndBillingTotalService";
import { IEnergyCostSheduledAndBillingTotal } from "src/ts/interfaces/energyCostSheduledAndBillingTotal";
import { ContFuelType } from "src/constants";
import buildingService from "src/services/buildingService";
import FormNumeric from "src/components/formControls/FormNumeric";

const Card = styled(MuiCard)(spacing);
const Divider = styled(MuiDivider)(spacing);
const initialValues: IGasFuelTypeEnergyCost = {
  id: 0,
  energyCostProcessId: 0,
  fuelTypeId: "0",
  gasUnitsId: "0",
};

const columns = (
  canEdit: boolean,
  showMonths: boolean,
  setGasTotalCost: (e: IEnergyCostSheduledAndBilling) => void,
  handleSumTotalCost: (e: number) => void,
  handleTotal: (e: number) => void,
  totalText: string,
  dataLenght: number
): ColumnType[] => [
  {
    id: "year",
    label:
      dataLenght === 1
        ? "Year / Electricity Unit"
        : "Year / Month / Electricity Unit",
    type: "custom",
    sort: false,
    totalLabel: totalText,
    width: "158px",
    callback: (row: IEnergyCostSheduledAndBilling) => {
      return (
        <>
          {row.year}
          {row.month === null ? "" : " / "}
          {row.month}
          {" / "}
          {
            row?.gasTotalCost.find(
              (x) => x.gasFuelTypeEnergyCost?.fuelType?.id === ContFuelType
            )?.gasFuelTypeEnergyCost?.gasUnits?.name
          }
        </>
      );
    },
  },
  {
    id: "totalCost",
    label: " Total Cost",
    type: "custom",
    sort: false,
    total: "totalCost",
    minWidth: "114px",
    callback: (row: IEnergyCostSheduledAndBilling) => {
      return (
        <>
          <FormNumeric
            name="totalCost"
            label=""
            value={
              row?.gasTotalCost
                .find(
                  (x) => x.gasFuelTypeEnergyCost?.fuelType?.id === ContFuelType
                )
                ?.totalCost?.toString() ?? ""
            }
            onChange={function (e: any): void {
              const foundRow = row?.gasTotalCost.find(
                (x) => x.gasFuelTypeEnergyCost?.fuelType?.id === ContFuelType
              );
              foundRow!.totalCost = e.target.value.toString();
              setGasTotalCost({ ...row });
              handleSumTotalCost(row.id);
            }}
            prefix={"$"}
            disabled={!canEdit}
          />
        </>
      );
    },
  },
  {
    id: "actualUtility",
    label: "Actual Utility Reported Power Factor",
    type: "custom",
    sort: false,
    total: "actualUtilityReportedPowerFactor",
    minWidth: "114px",
    callback: (row: IEnergyCostSheduledAndBilling) => {
      return (
        <>
          <FormNumeric
            name="actualUtilityReportedPowerFactor"
            label=""
            value={row?.actualUtilityReportedPowerFactor?.toString() ?? ""}
            onChange={function (e: any): void {
              row.actualUtilityReportedPowerFactor = e.target.value;
              setGasTotalCost({
                ...row,
              });
            }}
            disabled={!canEdit}
          />
        </>
      );
    },
  },
  {
    id: "powerFactor",
    label: "Power Factor Penalties $ (USD)",
    type: "custom",
    sort: false,
    total: "powerFactorPenalties",
    minWidth: "114px",
    callback: (row: IEnergyCostSheduledAndBilling) => {
      return (
        <>
          <FormNumeric
            name="powerFactorPenalties"
            label=""
            value={row?.powerFactorPenalties?.toString() ?? ""}
            onChange={function (e: any): void {
              row.powerFactorPenalties = e.target.value;
              setGasTotalCost({
                ...row,
              });
            }}
            prefix={"$"}
            disabled={!canEdit}
          />
        </>
      );
    },
  },
  {
    id: "allOther",
    label: "All Other Penalties Combined $ (USD)",
    type: "custom",
    sort: false,
    total: "allOtherPenaltiesCombined",
    minWidth: "114px",
    callback: (row: IEnergyCostSheduledAndBilling) => {
      return (
        <>
          <FormNumeric
            name="allOtherPenaltiesCombined"
            label=""
            value={row?.allOtherPenaltiesCombined?.toString() ?? ""}
            onChange={function (e: any): void {
              row.allOtherPenaltiesCombined = e.target.value;
              setGasTotalCost({
                ...row,
              });
            }}
            prefix={"$"}
            disabled={!canEdit}
          />
        </>
      );
    },
  },
  {
    id: "fuelType",
    label: "Fuel Type",
    type: "custom",
    sort: false,
    width: "127px;",
    callback: (row: IEnergyCostSheduledAndBilling) => {
      return (
        <>
          <Grid container>
            {row?.gasTotalCost
              .filter(
                (x) => x.gasFuelTypeEnergyCost?.fuelType?.id !== ContFuelType
              )
              .map((item) => (
                <Grid item sx={{ height: "45px", verticalAlign: "middle" }}>
                  <FormText
                    name="totalCost"
                    label=""
                    value={item?.gasFuelTypeEnergyCost?.fuelType?.name}
                    readOnlyText={true}
                    mode={"read"}
                  />
                </Grid>
              ))}
          </Grid>
        </>
      );
    },
  },
  {
    id: "unit",
    label: "Unit",
    type: "custom",
    sort: false,
    width: "80px;",
    callback: (row: IEnergyCostSheduledAndBilling) => {
      return (
        <>
          <Grid container>
            {row?.gasTotalCost
              .filter(
                (x) => x.gasFuelTypeEnergyCost?.fuelType?.id !== ContFuelType
              )
              .map((item) => (
                <Grid item sx={{ height: "45px", verticalAlign: "middle" }}>
                  <FormText
                    name="totalCost"
                    label=""
                    value={item?.gasFuelTypeEnergyCost?.gasUnits?.name}
                    readOnlyText={true}
                    mode={"read"}
                  />
                </Grid>
              ))}
          </Grid>
        </>
      );
    },
  },
  {
    id: "totalCost",
    label: "Total Cost $ (USD)",
    type: "custom",
    sort: false,
    width: "135px",
    callback: (row: IEnergyCostSheduledAndBilling) => {
      return (
        <>
          <Grid container>
            {row?.gasTotalCost
              .filter(
                (x) => x.gasFuelTypeEnergyCost?.fuelType?.id !== ContFuelType
              )
              .map((x) => (
                <Grid item sx={{ height: "45px", verticalAlign: "middle" }}>
                  <FormNumeric
                    name="totalCost"
                    label=""
                    value={x.totalCost?.toString()}
                    onChange={function (e: any): void {
                      const foundRow = row?.gasTotalCost.find(
                        (y) =>
                          y.gasFuelTypeEnergyCost?.gasUnits?.id ===
                          x.gasFuelTypeEnergyCost?.gasUnits?.id
                      );
                      foundRow!.totalCost = e.target.value.toString();
                      setGasTotalCost({ ...row });
                      handleSumTotalCost(row.id);
                    }}
                    prefix={"$"}
                    disabled={!canEdit}
                  />
                </Grid>
              ))}
          </Grid>
        </>
      );
    },
  },
  {
    id: "totalCost",
    label: "Total Cost",
    type: "custom",
    sort: false,
    callback: (row: IEnergyCostSheduledAndBilling) => {
      return (
        <>
          {"$"}
          {row.totalCost}
        </>
      );
    },
  },
];

const AnnualDetailedData = () => {
  let navigate = useNavigate();
  const { log } = useLog();
  let { energyCostProcessId } = useParams<{ energyCostProcessId: string }>();
  let { type } = useParams<{ type: string }>();
  let { id } = useParams<{ id: string }>();
  const [canEdit, setCanEdit] = useState(false);

  const validate = (fieldValues = values) => {
    let temp: Record<string, string> = { ...errors };

    temp.fuelTypeId =
      fieldValues.fuelTypeId !== "0" ? "" : "This field is required.";

    temp.gasUnitsId =
      fieldValues.gasUnitsId !== "0" ? "" : "This field is required.";

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const [gasTotalCost, setGasTotalCost] = useState<
    IEnergyCostSheduledAndBilling[]
  >([]);
  const [gasTotalCostTotal, setGasTotalCostTotal] = useState<
    IEnergyCostSheduledAndBillingTotal[]
  >([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [gasTotalCostTotalItem, setGasTotalCostTotalItem] =
    useState<IEnergyCostSheduledAndBillingTotal>();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [gasTotalCostItem, setGasTotalCostItem] =
    useState<IEnergyCostSheduledAndBilling>();

  const [totalProcess, setTotalProcess] = useState(false);

  const { values, errors, setErrors } = useForm(initialValues, false, validate);
  const { isLoading, startRequest, endRequest } = useLoading();

  useEffect(() => {
    const getBuilding = async (buildingId: number) => {
      const response = await buildingService.getById(buildingId);
      setCanEdit(response.data.canEdit);
    };

    if (Number(id) > 0) {
      getBuilding(Number(id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    const getGas = async () => {
      startRequest("AnnualDetailed");
      const response =
        await energyCostSheduledAndBillingService.getGasCostAnnualDetailed(
          Number(energyCostProcessId)
        );
      setGasTotalCost(response.data);

      const responseTotal =
        await energyCostSheduledAndBillingTotalService.getById(
          Number(energyCostProcessId)
        );
      setGasTotalCostTotal(responseTotal.data);

      setTotalProcess(true);
      endRequest("AnnualDetailed");
    };
    if (Number(energyCostProcessId) !== 0) getGas();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [energyCostProcessId]);

  useEffect(() => {
    const dataTotal = () => {
      const updatedGasTotalCostTotal = gasTotalCostTotal.map(
        (itemCost: IEnergyCostSheduledAndBillingTotal, index: number) => {
          const totalCost = gasTotalCost
            .filter(
              (item: IEnergyCostSheduledAndBilling) =>
                Number(item.year) === index + 1
            )
            .map((datum) => Number(datum.totalCost))
            .reduce((a, b) => a + b, 0);

          const totalPowerFactor = gasTotalCost
            .filter(
              (item: IEnergyCostSheduledAndBilling) =>
                Number(item.year) === index + 1
            )
            .map((datum) =>
              Number(
                datum.actualUtilityReportedPowerFactor === null
                  ? 0
                  : datum.actualUtilityReportedPowerFactor
              )
            )
            .reduce((a, b) => a + b, 0);

          const totalPowerPenalties = gasTotalCost
            .filter(
              (item: IEnergyCostSheduledAndBilling) =>
                Number(item.year) === index + 1
            )
            .map((datum) =>
              Number(
                datum.powerFactorPenalties === null
                  ? 0
                  : datum.powerFactorPenalties
              )
            )
            .reduce((a, b) => a + b, 0);
          const totalOtherPenalties = gasTotalCost
            .filter(
              (item: IEnergyCostSheduledAndBilling) =>
                Number(item.year) === index + 1
            )
            .map((datum) =>
              Number(
                datum.allOtherPenaltiesCombined === null
                  ? 0
                  : datum.allOtherPenaltiesCombined
              )
            )
            .reduce((a, b) => a + b, 0);
          return {
            ...itemCost,
            totalCost: totalCost,
            actualUtilityReportedPowerFactor: totalPowerFactor,
            powerFactorPenalties: totalPowerPenalties,
            allOtherPenaltiesCombined: totalOtherPenalties,
          };
        }
      );

      setGasTotalCostTotal(updatedGasTotalCostTotal);
    };

    if (gasTotalCostTotal.length > 0) dataTotal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalProcess, gasTotalCostItem]);

  const handleCancel = () => {
    navigate("/app/BuildingEnergyCost/" + id);
  };

  const handleSumTotalCost = (parentId: number) => {
    const foundRow = gasTotalCost.find((y) => y.id === parentId);
    if (foundRow !== undefined) {
      foundRow.totalCost =
        Number(
          foundRow.gasTotalCost
            .map((datum) => Number(datum.totalCost))
            .reduce((a, b) => Number(a) + Number(b))
        ) ?? 0;
      setGasTotalCostItem({ ...foundRow });
    }
  };

  const handleTotal = (energyCostId: number) => {
    const foundRow = gasTotalCost.filter(
      (y) => y.energyCostProcessId === energyCostId
    );
    const foundRowTotal = gasTotalCostTotal.find(
      (y) => y.energyCostProcessId === energyCostId
    );

    if (foundRowTotal !== undefined) {
      foundRowTotal.totalCost = Number(
        foundRow
          .map((datum) => Number(datum.totalCost))
          .reduce((a, b) => Number(a) + Number(b))
      );

      foundRowTotal.actualUtilityReportedPowerFactor = Number(
        foundRow
          .map((datum) => Number(datum.actualUtilityReportedPowerFactor))
          .reduce((a, b) => Number(a) + Number(b))
      );

      foundRowTotal.powerFactorPenalties = Number(
        foundRow
          .map((datum) => Number(datum.powerFactorPenalties))
          .reduce((a, b) => Number(a) + Number(b))
      );

      foundRowTotal.allOtherPenaltiesCombined = Number(
        foundRow
          .map((datum) => Number(datum.allOtherPenaltiesCombined))
          .reduce((a, b) => Number(a) + Number(b))
      );

      setGasTotalCostTotalItem({ ...foundRowTotal });
    }
  };

  const handleSave = async () => {
    await energyCostSheduledAndBillingService.updateAll(gasTotalCost);
    log.success("Detailed data was saved");
  };

  const handleBack = async () => {
    navigate(
      `/app/BuildingAnnualDetailed/${id}/${energyCostProcessId}/${type}`
    );
  };

  const steps = ["Energy configuration", "Annual Combined", "Annual Detailed"];

  return (
    <>
      <HeaderPage
        title={"Energy Cost"}
        parentText={"Buildings"}
        parentLink={`/app/Buildings/${id}`}
        actionSection={undefined}
      ></HeaderPage>
      <Divider my={6} />
      <Card mb={6}>
        <CardContent>
          <Form>
            <Grid item xs={12} md={12}>
              <Stepper alternativeLabel activeStep={2}>
                {steps.map((label) => (
                  <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
            </Grid>
            <Divider my={3}></Divider>
            <Typography variant="h2">{type}</Typography>
            <Divider my={6} />
            <Grid container>
              {gasTotalCost.find((x) => x.year === "1") && (
                <Grid item xs={12} pb={5}>
                  <LocalEnhancedTable<IEnergyCostSheduledAndBilling>
                    refreshGrid={true}
                    columns={columns(
                      canEdit,
                      true,
                      setGasTotalCostItem,
                      handleSumTotalCost,
                      handleTotal,
                      "Year 1",
                      gasTotalCost.length
                    )}
                    data={gasTotalCost.filter((x) => x.year === "1")}
                    showSkeleton={isLoading}
                    defaultRowPerPage={48}
                    totalData={gasTotalCostTotal[0]}
                    hidePagination={true}
                  />
                </Grid>
              )}

              {gasTotalCost.find((x) => x.year === "2") && (
                <Grid item xs={12} pb={5}>
                  <LocalEnhancedTable<IEnergyCostSheduledAndBilling>
                    refreshGrid={true}
                    columns={columns(
                      canEdit,
                      true,
                      setGasTotalCostItem,
                      handleSumTotalCost,
                      handleTotal,
                      "Year 2",
                      gasTotalCost.length
                    )}
                    data={gasTotalCost.filter((x) => x.year === "2")}
                    showSkeleton={isLoading}
                    defaultRowPerPage={48}
                    totalData={gasTotalCostTotal[1]}
                    hidePagination={true}
                  />
                </Grid>
              )}

              {gasTotalCost.find((x) => x.year === "3") && (
                <Grid item xs={12} pb={5}>
                  <LocalEnhancedTable<IEnergyCostSheduledAndBilling>
                    refreshGrid={true}
                    columns={columns(
                      canEdit,
                      true,
                      setGasTotalCostItem,
                      handleSumTotalCost,
                      handleTotal,
                      "Year 3",
                      gasTotalCost.length
                    )}
                    data={gasTotalCost.filter((x) => x.year === "3")}
                    showSkeleton={isLoading}
                    defaultRowPerPage={48}
                    totalData={gasTotalCostTotal[2]}
                    hidePagination={true}
                  />
                </Grid>
              )}
            </Grid>
            <Grid item xs={12}>
              <Stack
                direction="row"
                spacing={2}
                justifyContent="center"
                alignItems="center"
              >
                <FormBackButton
                  onClick={handleBack}
                  isSubmitting={false}
                  disabled={isLoading}
                  showSkeleton={isLoading}
                />
                <FormCancelButton
                  onClick={handleCancel}
                  isSubmitting={false}
                  disabled={isLoading}
                />
                <FormSaveButton
                  isSubmitting={false}
                  onClick={handleSave}
                  disabled={isLoading || !canEdit}
                />
              </Stack>
            </Grid>
          </Form>
        </CardContent>
      </Card>
    </>
  );
};

export default AnnualDetailedData;
