import {
  CardContent,
  Grid,
  Divider as MuiDivider,
  Card as MuiCard,
} from "@mui/material";
import { spacing } from "@mui/system";
import styled from "@emotion/styled";
import { useState } from "react";
import { useAuth, useEffectOnce } from "src/hooks";
import { IOutletMaster } from "src/ts/interfaces";
import { useNavigate, useParams } from "react-router-dom";
import outletMasterService from "src/services/study/outletMasterService";
import projectReportService from "src/services/study/projectReportService";
import {
  IProjectReport,
  ProjectDTO,
} from "src/ts/interfaces/project/projectDto";
import CompleteStudyPopup from "../../components/CompleteStudyPopup";
import getProjectSingleService from "src/services/getProjectSingleService";
import { steps } from "../const/const";
import { FooterButtons } from "../../components/FooterButtons";
import StudyWizard from "../../components/StudyWizard";
import HeaderStudyPage from "src/components/page/HeaderStudyPage";

const Divider = styled(MuiDivider)(spacing);
const Card = styled(MuiCard)(spacing);

const OuletMasterStep3 = () => {
  const params = useParams<{ id: string }>();
  const id = parseInt(params?.id === undefined ? "0" : params?.id);
  const navigate = useNavigate();
  const { user } = useAuth();

  const activeStep = 2;

  const [outletMaster, setOutletMaster] = useState<IOutletMaster | null>();
  const [parentStudy, setParentStudy] = useState<IProjectReport | null>();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [project, setProject] = useState<ProjectDTO | null>();
  const [projectReport, setProjectReport] = useState<IProjectReport | null>();

  const getParentReportIsComplete = async (
    reportId: number,
    reportType: string
  ) => {
    try {
      const res = await projectReportService.report(reportId, reportType);
      setParentStudy(res.data);
    } catch (error) {}
  };

  useEffectOnce(() => {
    const getData = async () => {
      const outletMasterResponse = await outletMasterService.getById(id);
      setOutletMaster(outletMasterResponse.data);

      await getParentReportIsComplete(outletMasterResponse.data.id, "OUMA");
      await getProject(outletMasterResponse.data.projectId);
      await getProjectReport(
        outletMasterResponse.data.id,
        outletMasterResponse.data.reportTypeId,
        outletMasterResponse.data.projectId
      );
    };
    if (id > 0) getData();
  });

  const getProjectReport = async (
    reportId: number,
    reportTypeId: number,
    projectId: number
  ) => {
    const projectReportResposne = await projectReportService.projectReport(
      reportId,
      reportTypeId,
      projectId
    );
    setProjectReport(projectReportResposne.data);
  };

  const getProject = async (projectId: number) => {
    const projectResponse = await getProjectSingleService.single(projectId);
    setProject(projectResponse.data);
  };

  const previousStepHandler = async () => {
    navigate(`/studies/OutletMasterStep2/${id}/`);
  };

  return (
    <>
      <HeaderStudyPage
        headerStudiesPage={{
          code: "OUMA",
          system: outletMaster?.systemField,
          id: id,
        }}
        parentText="Project"
        parentLink="/"
      />
      <Divider my={6} />

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <StudyWizard
            steps={steps}
            activeStep={activeStep}
            projectId={outletMaster?.projectId}
            reportId={outletMaster?.id}
            reportTypeId={9}
          />
        </Grid>
        <Grid item xs={12}>
          <Card mb={1}>
            <CardContent>
              <Grid container spacing={5}>
                <Grid item xs={12}>
                  {project && projectReport && outletMaster && (
                    <CompleteStudyPopup
                      isDialogOpen={isDialogOpen}
                      setIsDialogOpen={setIsDialogOpen}
                      project={project}
                      projectReport={projectReport}
                      completed={outletMaster?.isComplete}
                      infectiousComplianceControl={null}
                      parentStudy={parentStudy ?? null}
                    />
                  )}
                </Grid>
                <Grid item xs={12}>
                  <FooterButtons
                    activeStep={activeStep}
                    stepsCount={steps.length}
                    projectId={outletMaster?.projectId ?? 0}
                    companyId={user?.companyId as number}
                    isSaving={false}
                    disabled={false}
                    reportName={"Diffuser, Register & Grille Test Report"}
                    previousStepHandler={previousStepHandler}
                    saveHandler={() => {}}
                    isComplete={outletMaster?.isComplete ?? true}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default OuletMasterStep3;
