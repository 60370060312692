import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import useFormTyped from "src/hooks/useFormTyped";
import outletReportComponentService from "src/services/study/outletReportComponentService";
import outletReportItemService from "src/services/study/outletReportItemService";
import {
  IOutletReportComponent,
  IOutletReportItem,
  IOutletReportItems,
} from "src/ts/interfaces";
import { Validator } from "src/ts/types";

interface IOutletReportContext {
  outletReportComponentId: number;
  setOutletReportComponentId: (outletReportComponentId: number) => void;
  items: IOutletReportItem[];
  setItems: React.Dispatch<React.SetStateAction<IOutletReportItem[]>>;
  componentValues: IOutletReportComponent;
  setComponentValues: React.Dispatch<
    React.SetStateAction<IOutletReportComponent>
  >;
  errorComponents: Record<string, string>;
  handleInputComponentChange: (e: any) => void;
  isLoading: boolean;
  updateAllItems: () => Promise<void>;
  // setOutletReportId: (outletReportId: number) => void;
  getAllOutletReportItems: (outletReportId: number) => Promise<void>;
  allOutletReportItems: IOutletReportItems[];
}

const OutletReportContext = React.createContext<IOutletReportContext | null>(
  null
);

type OutletReportProviderProps = {
  children: React.ReactNode;
};

const initialComponent: IOutletReportComponent = {
  id: 0,
  ouletReportId: 0,
  name: "",
  manufacturer: "",
  size: null,
  order: null,
  lowestGriller: null,
  lowestGrillerId: 0,
  percentage: null,
  boxAddress: "",
  boxSizeRange: "",
  totalRequiredCFM: null,
  totalActualCFM: null,
  totalSensibleHeat: null,
  totalMax: null,
  totalMin: null,
  vpMinimumCold: null,
  vpMaximumCold: null,
  inletStaticPressureCold: null,
  dischargeStaticPressureCold: null,
  fanCheckBoxes: 0,
  finalCorrectedArflowACFM: null,
  inletTypeOfDuct: "",
  designBoxInletSizeW: null,
  designBoxInletSizeH: null,
  actualBoxInletSizeH: null,
  actualInletSqtFt: null,
  actualInletVelocity: null,
  modelNumber: "",
  serialNumber: "",
  serialNumberNotAvailable: false,
  actualMinimumCfm: null,
  designMinimumCfm: null,
  designMaximumCfm: null,
  requiredMinimumCfm: null,
  minimumCfmPod: null,
  coilTypeLinked: "",
  hydronicTypeCoilLinked: "",
  modelNumberNotAvailable: false,
  manufacturerNotAvailable: false,
  minimumMax: null,
  minimumMin: null,
};

function OutletReportProvider({ children }: OutletReportProviderProps) {
  const [outletReportComponentId, _setOutletReportComponentId] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [items, setItems] = useState<IOutletReportItem[]>([]);
  const [allOutletReportItems, setAllOutletReportItems] = useState<
    IOutletReportItems[]
  >([]);

  const validateComponent: any = (fieldValues = componentValues) => {
    let temp: Record<string, string> = { ...errorComponents };

    temp.coilTypeLinked = new Validator(fieldValues, "coilTypeLinked")
      .isRequired("Required")
      .toString();

    temp.hydronicTypeCoilLinked = new Validator(
      fieldValues,
      "hydronicTypeCoilLinked"
    )
      .isRequired("Required")
      .toString();

    temp.name = new Validator(fieldValues, "name")
      .maxLength(50, "Only 50 characters are allowed")
      .toString();

    temp.boxAddress = new Validator(fieldValues, "boxAddress")
      .maxLength(50, "Only 50 characters are allowed")
      .toString();

    temp.manufacturer = new Validator(fieldValues, "manufacturer")
      .maxLength(50, "Only 50 characters are allowed")
      .toString();

    temp.modelNumber = new Validator(fieldValues, "modelNumber")
      .maxLength(50, "Only 50 characters are allowed")
      .toString();

    temp.serialNumber = new Validator(fieldValues, "serialNumber")
      .validateIf(!fieldValues.serialNumberNotAvailable)
      .isRequired("Required")
      .toString();

    // temp.outletDuctTypeId = new Validator(fieldValues, "outletDuctTypeId")
    //   .validateIf(fieldValues.controlTypeId === PNEUMATIC_ID)
    //   .isRequired("Select Duct Type")
    //   .toString();

    setComponentErrors({
      ...temp,
    });

    if (fieldValues === componentValues)
      return Object.values(temp).every((x) => x === "");
  };

  const {
    values: componentValues,
    setValues: setComponentValues,
    errors: errorComponents,
    setErrors: setComponentErrors,
    handleInputChange: handleInputComponentChange,
  } = useFormTyped<IOutletReportComponent>(
    initialComponent,
    false,
    validateComponent
  );

  const setOutletReportComponentId = (outletReportComponentId: number) => {
    _setOutletReportComponentId(outletReportComponentId);
  };

  useEffect(() => {
    const getData = async () => {
      try {
        setIsLoading(true);
        const itemsRes = await outletReportItemService.component(
          outletReportComponentId!
        );
        setItems(itemsRes.data);

        const res = await outletReportComponentService.getById(
          outletReportComponentId
        );
        setComponentValues(res.data);
      } catch (error) {
      } finally {
        setIsLoading(false);
      }
    };

    if (outletReportComponentId !== null && outletReportComponentId > 0)
      getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [outletReportComponentId]);

  const updateAllItems = async () => {
    if (items === null || items.length === 0) return;
    await outletReportItemService.updateAllItems(items);
  };

  const getAllOutletReportItems = async (outletReportId: number) => {
    const itemsByReportRes = await outletReportItemService.getByReportId(
      outletReportId
    );
    setAllOutletReportItems(itemsByReportRes.data);
  };

  return (
    <OutletReportContext.Provider
      value={{
        outletReportComponentId,
        setOutletReportComponentId,
        items,
        setItems,
        componentValues,
        setComponentValues,
        errorComponents,
        handleInputComponentChange,
        isLoading,
        updateAllItems,
        getAllOutletReportItems,
        allOutletReportItems,
      }}
    >
      {children}
    </OutletReportContext.Provider>
  );
}

export { OutletReportContext, OutletReportProvider };
