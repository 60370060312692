import AmericanExpressIcon from "./AmericanExpressIcon";
import DinersClubIcon from "./DinersClubIcon";
import DiscoveryIcon from "./DiscoveryIcon";
import GenericIcon from "./GenericIcon";
import JCBIcon from "./JCBIcon";
import MasterCardIcon from "./MasterCardIcon";
import VisaIcon from "./VisaIcon";

interface Props {
  typeCreditCard: string;
}

const CreditCardIconSelector = (prop: Props) => {
  return (
    <>
      {prop.typeCreditCard === "VISA" && <VisaIcon />}
      {prop.typeCreditCard === "AMEX" && <AmericanExpressIcon />}
      {prop.typeCreditCard === "DC" && <DinersClubIcon />}
      {prop.typeCreditCard === "DISC" && <DiscoveryIcon />}
      {prop.typeCreditCard === "generic" && <GenericIcon />}
      {prop.typeCreditCard === "JCB" && <JCBIcon />}
      {prop.typeCreditCard === "MC" && <MasterCardIcon />}
    </>
  );
};

export default CreditCardIconSelector;
