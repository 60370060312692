import {
  CardContent,
  Grid,
  Card as MuiCard,
  Divider as MuiDivider,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";
import styled from "@emotion/styled";
import { useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  useAsyncQuery,
  useAuth,
  useEffectOnce,
  useLog,
  useSystemOfMeasurement,
} from "src/hooks";
import chillerService from "src/services/study/chillerService";
import { IChillerTowerCondenser, IReportType } from "src/ts/interfaces";
import { Mode } from "src/ts/types";
import StudyWizard from "../../components/StudyWizard";
import { FooterButtons } from "../../components/FooterButtons";
import DialogMessagePopup from "src/components/DialogMessagePopup";
import { steps, studyTypeCode } from "../const";
import projectReportService from "src/services/study/projectReportService";
import { IProjectReport } from "src/ts/interfaces/project/projectDto";
import reportTypeService from "src/services/study/reportTypeService";
import studiesHistoryService from "src/services/study/studiesHistoryService";
import useChillerStudy from "../hooks/useChillerStudy";
import TowerCondenserList from "../components/TowerCondenserList";
import TowerCondenserForm from "../components/TowerCondenserForm";
import FanMotorList from "../components/FanMotorList";
import { IChillerTowerFanMotor } from "src/ts/interfaces/study/tower";
import FanMotorForm from "../components/FanMotorForm";
import HeaderStudyPage from "src/components/page/HeaderStudyPage";

const Divider = styled(MuiDivider)(spacing);
const Card = styled(MuiCard)(spacing);
const activeStep = 2;

const ChillerStep31 = () => {
  const { user } = useAuth();
  const params = useParams<{ id: string }>();
  const id = parseInt(params?.id === undefined ? "0" : params?.id);
  let navigate = useNavigate();
  const { log } = useLog();

  const {
    chillerValues,
    setChillerValues,
    towerCondenserValues,
    setTowerCondenserValues,
    setFanMotorValues,
    fanMotorValues,
    validateFanMotorValues,
  } = useChillerStudy();

  const { systemOfMeasurement } = useSystemOfMeasurement(true);

  const [reportType, setReportType] = useState<IReportType | null>();

  const { execute: executeReport, data: dataReport } =
    useAsyncQuery<IProjectReport>(projectReportService.report);

  const [isLoading, setIsLoading] = useState(false);

  const [towerCondensers, setTowerCondensers] = useState<
    IChillerTowerCondenser[]
  >([]);

  const [fanMotors, setFanMotors] = useState<IChillerTowerFanMotor[]>([]);

  useEffectOnce(() => {
    const getData = async () => {
      try {
        setIsLoading(true);
        const chillerRes = await chillerService.getById(id);
        setChillerValues(chillerRes.data);

        const towerCondensersRes = await chillerService.towerCondensers(id);
        setTowerCondensers(towerCondensersRes.data);
        if (towerCondensersRes.data.length > 0) {
          setTowerCondenserValues(towerCondensersRes.data[0]);

          await getFanMotor(towerCondensersRes.data[0]);
        }

        const reportTypeRes = await reportTypeService.getReportTypeByCode(
          studyTypeCode
        );
        setReportType(reportTypeRes.data);

        await executeReport(id, reportTypeRes.data.code);
        await systemOfMeasurement.getByProject(chillerRes.data.projectId);
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      } finally {
        setIsLoading(false);
      }
    };
    if (id > 0) getData();
  });

  const getFanMotor = async (towerCondenser: IChillerTowerCondenser) => {
    const fanMotorsRes = await chillerService.getFanMotorByTowerCondenserId(
      towerCondenser.id
    );

    if (fanMotorsRes.data.length > 0) {
      setFanMotors(fanMotorsRes.data);
      setFanMotorValues(fanMotorsRes.data[0]);
      //Training Motor
    } else {
      const fanMotorNew: any = {
        chillerTowerCondenserId: towerCondenser.id,
      };

      const fanMotorRes = await chillerService.addFanMotor(fanMotorNew);
      setFanMotors([fanMotorRes.data]);
      setFanMotorValues(fanMotorRes.data);
    }
  };

  const mode: Mode = chillerValues.isComplete ? "read" : "read&Write";

  const disableStudy = useMemo(() => {
    return (
      chillerValues?.isComplete ||
      (dataReport?.isInUse && dataReport?.isInUseById !== user?.userId) ||
      dataReport?.isPartOfProject === false
    );
  }, [
    dataReport?.isInUse,
    dataReport?.isInUseById,
    dataReport?.isPartOfProject,
    user?.userId,
    chillerValues?.isComplete,
  ]);

  const saveHandler = async () => {
    await save();
  };

  const nextHandler = async () => {
    if (!disableStudy) {
      if (
        (fanMotorValues.nameplateHorsePowerId == null ||
          fanMotorValues.nameplateHorsePowerId === 0) &&
        !fanMotorValues.horsepowerUnknown
      ) {
        log.error(
          'To move forward, Nameplate HP is required. If you don\'t know the HP, select the "Nameplate HP unknown" checkbox.'
        );
        return;
      }
      await save();
    }
    const route = `/studies/Chiller/step4/${id}`;
    navigate(route);
  };

  const backHandler = async () => {
    await save();
    const route = `/studies/Chiller/step3/${id}`;
    navigate(route);
  };

  const save = async () => {
    if (disableStudy) return;

    try {
      setIsLoading(true);
      const updateChillerRes = await chillerService.update(
        chillerValues.id,
        chillerValues
      );
      setChillerValues(updateChillerRes.data);

      await studiesHistoryService.save(
        chillerValues.projectId,
        reportType?.id ?? 0,
        chillerValues.id,
        "saved"
      );
      await chillerService.updateTowerCondenser(towerCondenserValues.id, {
        ...towerCondenserValues,
        quantityOfFanMotors: fanMotors.length,
      });

      await saveFanMotor();

      log.info("Updated successfully");
    } catch (error: any) {
      log.error(error?.message?.exceptionMessage ?? "Something went wrong");
    } finally {
      setIsLoading(false);
    }
  };

  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [fanMotorForDelete, setFanMotorForDelete] =
    useState<IChillerTowerFanMotor | null>(null);

  const showModalDeleteFanMotor = (fanMotor: IChillerTowerFanMotor) => {
    setFanMotorForDelete(fanMotor);
    setShowDeleteModal(true);
  };

  const selectFanMotorHandler = async (
    selectedFanMotor: IChillerTowerFanMotor
  ) => {
    if (!disableStudy) {
      await saveFanMotor();
    }

    const fanMotorRes = await chillerService.getFanMotorById(
      selectedFanMotor.id
    );
    setFanMotorValues(fanMotorRes.data);
  };

  const saveFanMotor = async () => {
    if (!validateFanMotorValues()) return;
    await chillerService.updateFanMotor(fanMotorValues.id, fanMotorValues);
  };

  const onTowerCondenserChangeHandler = async (
    towerCondenser: IChillerTowerCondenser
  ) => {
    if (towerCondenser.id === towerCondenserValues.id) return;
    //if (!validateFanMotorValues()) return;
    if (!disableStudy) {
      await chillerService.updateTowerCondenser(towerCondenserValues.id, {
        ...towerCondenserValues,
        quantityOfFanMotors: fanMotors.length,
      });
      await saveFanMotor();
    }

    const evaporatorRes = await chillerService.getTowerCondenserById(
      towerCondenser.id
    );

    setTowerCondenserValues(evaporatorRes.data);
    await getFanMotor(towerCondenser);
  };

  const deleteFanMotor = async () => {
    if (fanMotorForDelete === null) return;

    await chillerService.deleteFanmotor(fanMotorForDelete.id);
    setShowDeleteModal(false);

    if (fanMotors === null) return;

    const index = fanMotors.findIndex((x) => x.id === fanMotorForDelete.id);
    const newFanMotors = [...fanMotors];
    newFanMotors.splice(index, 1);
    setFanMotors(newFanMotors);
    if (newFanMotors.length > 0) setFanMotorValues(newFanMotors[0]);
  };

  const addFanMotor = async (
    fanMotor: IChillerTowerFanMotor,
    index: number
  ) => {
    await saveFanMotor();

    const fanMotorNew: any = {
      chillerTowerCondenserId: fanMotor.chillerTowerCondenserId,
    };

    const fanMotorRes = await chillerService.addFanMotor(fanMotorNew);

    if (fanMotors === null) return;
    const newFanMotor = [...fanMotors];

    newFanMotor.splice(index + 1, 0, fanMotorRes.data);
    setFanMotors(newFanMotor);
    setFanMotorValues(fanMotorRes.data);
  };

  return (
    <>
      <HeaderStudyPage
        headerStudiesPage={{
          code: studyTypeCode,
          system: chillerValues.system,
          id: id,
        }}
        parentText="Project"
        parentLink="/"
      />
      <Divider my={6} />

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <StudyWizard
            steps={steps}
            activeStep={activeStep}
            projectId={dataReport?.projectId}
            reportId={dataReport?.reportId}
            reportTypeId={dataReport?.reportTypeId}
          />
        </Grid>
        <Grid item xs={12}>
          <Card mb={1}>
            <CardContent>
              <Grid container spacing={5}>
                <Grid item xs={12}>
                  <Typography variant="h6">
                    Tower/Condenser Nameplate Data
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <TowerCondenserList
                    towerCondensers={towerCondensers}
                    isStudyComplete={disableStudy}
                    onChange={onTowerCondenserChangeHandler}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TowerCondenserForm
                    mode={mode}
                    disableStudy={disableStudy}
                    isLoading={isLoading}
                    systemOfMeasurement={systemOfMeasurement}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h6">Fan/Motors</Typography>
                </Grid>
                <Grid item xs={12}>
                  <FanMotorList
                    fanMotors={fanMotors}
                    disableStudy={disableStudy}
                    onSelect={selectFanMotorHandler}
                    onDelete={showModalDeleteFanMotor}
                    onAdd={addFanMotor}
                    showDelete={fanMotors.length > 1}
                    showDuplicate={true}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FanMotorForm
                    mode={mode}
                    disableStudy={disableStudy}
                    isLoading={isLoading}
                    systemOfMeasurement={systemOfMeasurement}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FooterButtons
                    activeStep={activeStep}
                    stepsCount={steps.length}
                    projectId={dataReport?.projectId}
                    companyId={user?.companyId as number}
                    isSaving={false}
                    disabled={false}
                    reportName={reportType?.name ?? ""}
                    saveHandler={saveHandler}
                    nextStepHandler={() => nextHandler()}
                    previousStepHandler={() => backHandler()}
                    hideSaveButton={disableStudy}
                    isComplete={chillerValues.isComplete}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <DialogMessagePopup
        title={"Delete Fan/Motor."}
        text={"Are certain you want to remove this Fan/Motor?"}
        showPopup={showDeleteModal}
        setShowPopup={setShowDeleteModal}
        onSave={() => {
          deleteFanMotor();
        }}
        isSubmitting={false}
      />
    </>
  );
};

export default ChillerStep31;
