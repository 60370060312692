import axios from "src/utils/axios";
import { IHeadSheetStarterDTO } from "src/ts/interfaces";

const getById = (id: number) => {
  return axios.get<IHeadSheetStarterDTO[]>(`HeadSheetStarter/${id}`);
};

const headSheetStarterService = {
  getById,
};

export default headSheetStarterService;
