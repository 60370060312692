import axios from "src/utils/axios";
import { ICoils } from "src/ts/interfaces/study/coils";

const getById = (id: number) => {
  return axios.get<ICoils>(`Coils/${id}`);
};

const update = (id: number, coil: ICoils) => {
  return axios.put<ICoils>(`Coils/${id}`, coil);
};

const coilsService = {
  getById,
  update,
};

export default coilsService;
