import { TableRow } from "@mui/material";

interface SpreadSheetRowProp {
  children: JSX.Element[] | JSX.Element;
  transposed?: boolean;
}

const styles = {
  transposed: {
    display: "table-cell",
  },
};

const SpreadSheetRow = ({ children, transposed }: SpreadSheetRowProp) => {
  return (
    <TableRow style={transposed ? styles.transposed : {}}>{children}</TableRow>
  );
};

export default SpreadSheetRow;
