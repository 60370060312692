import { ProjectWithBogus } from "src/ts/interfaces/project/projectDto";
import { DuplicateDeleteStudy } from "src/ts/interfaces";
import axios from "src/utils/axios";
import { IStudiesTemplate } from "src/ts/interfaces/template";

const duplicateStudies = (
  quantity: number,
  copyActual: boolean,
  studies: DuplicateDeleteStudy[]
) => {
  return axios.put(
    `ProjectDuplicate/duplicateStudies?quantity=${quantity}&copyActuals=${copyActual}`,
    studies
  );
};

const duplicateProject = (
  projectId: number,
  project: ProjectWithBogus,
  copyActuals: boolean
) => {
  return axios.post(
    `ProjectDuplicate/Duplicate?id=${projectId}&copyActuals=${copyActuals}`,
    project
  );
};

const duplicateStudiesNewProject = (
  projectId: number,
  studies: IStudiesTemplate[]
) => {
  return axios.post(
    `ProjectDuplicate/duplicateStudiesNewProject?projectId=${projectId}`,
    studies
  );
};

const projectDuplicateService = {
  duplicateStudies,
  duplicateProject,
  duplicateStudiesNewProject,
};

export default projectDuplicateService;
