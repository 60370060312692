import React, { useEffect, useState } from "react";
import { Stack, Typography } from "@mui/material";
import Popup from "src/components/Popup";
import {
  FormButton,
  FormCancelButton,
  FormRadioGroup,
  FormSelect,
  FormTextArea,
} from "src/components/formControls";
import { IAssets, ITypeAsset } from "src/ts/interfaces/companyAssets";
import { useForm, useLog } from "src/hooks";
import companyAssetsService from "src/services/companyAssetsService";
import { IKeyValue } from "src/ts/interfaces";
import { Validator } from "src/ts/types";
import companyService from "src/services/companyService";

interface Props {
  showEditModal: boolean;
  setShowEditModal: (val: boolean) => void;
  currentRow: IAssets;
  companyId: string;
  asset: string;
  setRefreshGrid: (value: boolean) => void;
  refreshGrid: boolean;
  information: ITypeAsset;
}
const initialValues = {
  radioButtonSelect: "0",
  comments: "",
  selectedSatellite: "-1",
};

const EditAssetPopUp = ({
  showEditModal,
  setShowEditModal,
  currentRow,
  companyId,
  asset,
  setRefreshGrid,
  refreshGrid,
  information,
}: Props) => {
  const [selectRadioButton, setSelectRadioButton] = useState(
    initialValues.radioButtonSelect
  );
  const { log } = useLog();
  const [dataUser, setDataUser] = useState<IKeyValue<number, string>[]>([]);

  useEffect(() => {
    const getData = async () => {
      const response = await companyService.getKeyValues(Number(companyId));

      setDataUser(response);
    };

    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const validate = (fieldValues = values) => {
    let temp: Record<string, string> = { ...errors };

    temp.comments = new Validator(fieldValues, "comments")
      .isRequired("The comments are required.")
      .maxLength(200, "Only 200 character are allowed")
      .toString();
    if (fieldValues.radioButtonSelect === "1") {
      temp.selectedSatellite = new Validator(fieldValues, "selectedSatellite")
        .selectedOption("-1", "Selected a satellite.")
        .toString();
    }

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, handleInputChange, setErrors, errors } = useForm(
    initialValues,
    false,
    validate
  );

  const radioInformation = [
    {
      key: "0",
      value: "Headquarters",
    },
    {
      key: "1",
      value: "Satellite",
    },
  ];

  const handleOnChange = (e: any) => {
    setValues({ ...values, [e.target.name]: e.target.value });
    setSelectRadioButton(e.target.value);
  };

  const handleSave = async () => {
    if (!validate()) return;
    try {
      const id = currentRow?.id ?? 0;

      await companyAssetsService.requestTrasnferTech(
        id,
        true,
        values.radioButtonSelect === "0" ? companyId : values.selectedSatellite,
        values.comments
      );
    } catch (error: any) {
      log.error(error?.message?.exceptionMessage ?? "Something went wrong");
    } finally {
      setShowEditModal(false);
      setRefreshGrid(!refreshGrid);
    }
  };

  const VehicleTransfer = () => (
    <>
      <Stack direction="row" spacing={3.5} ml={1.5} mb={3}>
        <Typography>
          <b>Make</b>
        </Typography>
        <Typography>{currentRow?.vehicle?.make}</Typography>
      </Stack>
      <Stack direction="row" spacing={3.5} mb={3}>
        <Typography>
          <b>Model</b>
        </Typography>
        <Typography>{currentRow?.vehicle?.model}</Typography>
      </Stack>
      <Stack direction="row" spacing={3} mb={1} ml={3}>
        <Typography>
          <b>Year</b>
        </Typography>
        <Typography>{currentRow?.vehicle?.year}</Typography>
      </Stack>
    </>
  );

  const EquipmentTransfer = () => (
    <>
      <Stack direction="row" spacing={4.9} mb={3} ml={14}>
        <Typography>
          <b>Name</b>
        </Typography>
        <Typography>{currentRow?.equipment?.name}</Typography>
      </Stack>
      <Stack direction="row" spacing={5} mb={3} ml={1}>
        <Typography>
          <b>Serial Number</b>
        </Typography>
        <Typography>{currentRow?.equipment?.serialNumber}</Typography>
      </Stack>
      <Stack direction="row" spacing={5} mb={1}>
        <Typography>
          <b>Model Number</b>
        </Typography>
        <Typography>{currentRow?.equipment?.model}</Typography>
      </Stack>
    </>
  );

  return (
    <Popup
      title={`Transfer ${
        information.title === "Recommended Test Equipment"
          ? "Test Equipment"
          : information.title
      } to Office/Shop`}
      openPopup={showEditModal}
      setOpenPopup={setShowEditModal}
      onClose={() => {
        setShowEditModal(false);
      }}
    >
      <Stack>
        <div>
          {asset === "VEHI" && <VehicleTransfer />}
          {asset === "EQIP" && <EquipmentTransfer />}
          <Stack direction="row" mb={1} ml={12}>
            <FormRadioGroup
              name="radioButtonSelect"
              label=""
              value={values.radioButtonSelect}
              items={radioInformation}
              onChange={handleOnChange}
              defaultValue={selectRadioButton}
            />
          </Stack>
          {values.radioButtonSelect === "1" && (
            <Stack direction="row" spacing={6.5} mb={3} ml={12}>
              <FormSelect
                name="selectedSatellite"
                label=""
                value={values.selectedSatellite}
                options={dataUser}
                onChange={handleInputChange}
                defaultValue={{
                  key: "-1",
                  value: " Please Select",
                }}
                error={errors.selectedSatellite}
              />
            </Stack>
          )}
          <Stack direction="row" spacing={6.5} mb={3} ml={12}>
            <FormTextArea
              name="comments"
              label="Comments"
              value={values.comments}
              onChange={handleInputChange}
              placeholder={`Place explain why the ${
                information.title === "Recommended Test Equipment"
                  ? "test equipment"
                  : information.title?.toLowerCase()
              } needs to be transferred to the office/shop`}
              error={errors.comments}
            />
          </Stack>
        </div>
      </Stack>

      <Stack
        direction="row"
        spacing={2}
        justifyContent="flex-end"
        alignItems="flex-start"
      >
        <FormCancelButton
          onClick={() => {
            setShowEditModal(false);
          }}
          isSubmitting={false}
        />
        <FormButton
          text="Accept"
          size="medium"
          onClick={() => {
            handleSave();
          }}
        />
      </Stack>
    </Popup>
  );
};

export default EditAssetPopUp;
