import Popup from "src/components/Popup";
import { FormButton } from "src/components/formControls";
import { Stack, Typography } from "@mui/material";

interface NewContractorAddedPopUpProps {
  newContractorAddedShowPopUp: boolean;
  setNewContractorAddedShowPopUp: (val: boolean) => void;
  setShowContractorPopUp: (val: boolean) => void;
  preferred: boolean;
}

export default function NewContractorAddedPopUp({
  newContractorAddedShowPopUp,
  setNewContractorAddedShowPopUp,
  setShowContractorPopUp,
  preferred,
}: NewContractorAddedPopUpProps) {
  return (
    <>
      <Popup
        title={"Information"}
        openPopup={newContractorAddedShowPopUp}
        setOpenPopup={setNewContractorAddedShowPopUp}
        onClose={() => {
          setNewContractorAddedShowPopUp(false);
        }}
        size="sm"
      >
        <>
          {!preferred && (
            <Typography>
              The contractor Amp Union Contractor 01 was successfully added to
              the project out for bid. An invitation to apply to it was sent to
              the registered email with the project details.
            </Typography>
          )}
          {preferred && (
            <Typography>
              The selected contractors were added successfully added to the
              project out for bid. An invitation to apply to it was sent to the
              registered email with the project details.
            </Typography>
          )}
        </>
        <Stack direction="row" spacing={3}>
          <FormButton
            size="small"
            text="Close"
            variant="outlined"
            onClick={() => {
              setNewContractorAddedShowPopUp(false);
              setShowContractorPopUp(false);
            }}
          />
        </Stack>
      </Popup>
    </>
  );
}
