import { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { spacing } from "@mui/system";

import { Grid, Divider as MuiDivider, Typography, Stack } from "@mui/material";
import { useForm, Form, useLog, usePermissions } from "src/hooks";
import {
  FormAcceptButton,
  FormCancelButton,
  FormNumeric2,
  FormSelect,
  FormText,
  FormTextArea,
} from "src/components/formControls";
import { IPlaques } from "src/ts/interfaces";
import { PlaquesTypeEnum } from "src/ts/enums";
import plaqueService from "src/services/plaqueService";
import Popup from "src/components/Popup";
import { Permission as PermissionTypes } from "src/ts/enums";
import { Validator } from "src/ts/types";

const Divider = styled(MuiDivider)(spacing);

const initialValues: IPlaques = {
  id: 0,
  name: "",
  price: 0,
  percentage: 0,
  unitActualCost: 0,
  additionalCostsPercentage: 0,
  type: PlaquesTypeEnum.Bronze,
  description: "",
  size: "",
  plaqueProvidersId: 0,
  lengthPackage: 0,
  widthPackage: 0,
  heightPackage: 0,
  weightPackage: 0,
  insuredValuePackage: 0,
  isFirstOrderNeeded: false,
  plaquesPicture: [],
};

interface IPopupProps {
  plaqueId: number;
  providerType: number;
  providerId: number;
  showPopup: boolean;
  setShowPopup: (e: boolean) => void;
}

const PlaqueDecalForm = (props: IPopupProps) => {
  const plaqueTypes = [
    { key: 1, value: "Bonze" },
    { key: 2, value: "Crystal" },
    { key: 3, value: "Aluminium" },
  ];

  const afterSecodnOrder = [
    { key: false, value: "No" },
    { key: true, value: "Yes" },
  ];

  const { log } = useLog();
  const [isLoading, setIsLoading] = useState(false);
  const { fullAccess: fullAccessaSysadmin } = usePermissions(
    PermissionTypes.SysAdmin
  );

  const { readOnly } = usePermissions(PermissionTypes.Plaque_Decals);

  const validate = (fieldValues = values) => {
    let temp: Record<string, string> = { ...errors };

    temp.name = new Validator(fieldValues, "name")
      .isRequired("Name is required")
      ?.toString();

    temp.size = new Validator(fieldValues, "size")
      .isRequired("Size is required")
      ?.toString();

    temp.unitActualCost = new Validator(fieldValues, "unitActualCost")
      .isRequired("Unit Actual Cost is required")
      ?.toString();

    temp.percentage = new Validator(fieldValues, "percentage")
      .isRequired("Percentage is required")
      ?.toString();

    temp.additionalCostsPercentage = new Validator(
      fieldValues,
      "additionalCostsPercentage"
    )
      .isRequired("Additional Cost Percentage is required")
      ?.toString();

    temp.description = new Validator(fieldValues, "description")
      .isRequired("Description is required")
      ?.toString();

    temp.lengthPackage = new Validator(fieldValues, "lengthPackage")
      .isRequired("Length is required")
      ?.toString();

    temp.widthPackage = new Validator(fieldValues, "widthPackage")
      .isRequired("Width is required")
      ?.toString();

    temp.heightPackage = new Validator(fieldValues, "heightPackage")
      .isRequired("Height is required")
      ?.toString();

    temp.weightPackage = new Validator(fieldValues, "weightPackage")
      .isRequired("Weight is required")
      ?.toString();

    temp.insuredValuePackage = new Validator(fieldValues, "insuredValuePackage")
      .isRequired("Insure to the value of is required")
      ?.toString();

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange } = useForm(
    initialValues,
    false,
    validate
  );

  useEffect(() => {
    const getPlaques = async () => {
      try {
        setIsLoading(true);
        const response = await plaqueService.getById(props.plaqueId);
        setValues(response.data);
        setIsLoading(false);
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      } finally {
        setIsLoading(false);
      }
    };

    const resetForm = async () => {
      let newValues = { ...initialValues };
      newValues.plaqueProvidersId = props.providerId;
      if (props.providerType !== 1) newValues.type = 0;
      setValues({ ...newValues });
    };

    if (props.plaqueId > 0) {
      getPlaques();
    }
    if (props.plaqueId === 0) resetForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.showPopup]);

  const handleSave = async () => {
    try {
      if (!validate()) return;

      setIsLoading(true);
      if (props.plaqueId === 0) await plaqueService.insert(values);
      else await plaqueService.update(values);

      setIsLoading(false);
      props.setShowPopup(false);
      log.success(
        `${props.providerType === 1 ? "Plaque" : "Decal"} was successfully ${
          props.plaqueId === 0 ? "added" : "updated"
        }`
      );
    } catch (error: any) {
      log.error(error?.message?.exceptionMessage ?? "Something went wrong");
    } finally {
      setIsLoading(false);
    }
  };

  const row = 6;

  const currencyformatterThreeDecimal = new Intl.NumberFormat("en-US", {
    style: "currency",
    minimumFractionDigits: 3,
    currency: "USD",
  });

  const handleInputChangeType = (e: any) => {
    let newValues = { ...values };
    newValues.type = e.target.value;
    setValues({ ...newValues });
  };

  return (
    <Popup
      title={`${props.providerType === 1 ? "Plaques" : "Decals"}`}
      openPopup={props.showPopup}
      setOpenPopup={props.setShowPopup}
      onClose={() => {
        props.setShowPopup(false);
      }}
      size={"md"}
    >
      <>
        <Form>
          <Grid container spacing={5}>
            <Grid item xs={row}>
              <FormText
                name="name"
                label="Name"
                value={values.name}
                onChange={handleInputChange}
                error={errors.name}
                showSkeleton={isLoading}
                disabled={readOnly}
              />
            </Grid>
            {props.providerType === 1 && (
              <Grid item xs={row}>
                <FormSelect
                  name="plaqueType"
                  label="Type"
                  value={values.type}
                  onChange={handleInputChangeType}
                  options={plaqueTypes}
                  error={errors.type}
                  disable={false}
                  showSkeleton={isLoading}
                  disabled={readOnly}
                />
              </Grid>
            )}
            <Grid item xs={row}>
              <FormText
                name="size"
                label="Size"
                value={values.size}
                onChange={handleInputChange}
                error={errors.size}
                showSkeleton={isLoading}
                disabled={readOnly}
              />
            </Grid>
            <Grid item xs={row}>
              <FormNumeric2
                name="unitActualCost"
                label="Unit Actual Cost"
                value={values.unitActualCost}
                onChange={handleInputChange}
                error={errors.unitActualCost}
                showSkeleton={isLoading}
                maxValue={999999}
                disabled={readOnly}
              />
            </Grid>
            <Grid item xs={row}>
              <FormNumeric2
                name="percentage"
                label="Percentage"
                value={values.percentage}
                onChange={handleInputChange}
                maxValue={999}
                suffix={"%"}
                error={errors.percentage}
                placeholder=""
                showSkeleton={isLoading}
                disabled={readOnly}
              />
            </Grid>
            <Grid item xs={row} pb={4}>
              <FormNumeric2
                name="additionalCostsPercentage"
                label="Additional Cost Percentage"
                value={values.additionalCostsPercentage}
                onChange={handleInputChange}
                maxValue={999}
                suffix={"%"}
                error={errors.additionalCostsPercentage}
                placeholder=""
                showSkeleton={isLoading}
                disabled={readOnly}
              />
            </Grid>

            {!fullAccessaSysadmin && (
              <Grid container direction={"row"}>
                <Grid item xs={4}></Grid>
                <Grid item xs={2} textAlign={"right"} pr={2}>
                  <Typography variant="subtitle2" gutterBottom>
                    Actual AMP Billing
                  </Typography>
                </Grid>
                <Grid item xs={2} pt={0.5}>
                  <Typography variant="body2" gutterBottom>
                    {currencyformatterThreeDecimal.format(values.price)}
                  </Typography>
                </Grid>
                <Grid item xs={4}></Grid>
              </Grid>
            )}

            {fullAccessaSysadmin && (
              <Grid container direction={"row"}>
                <Grid item xs={4}></Grid>
                <Grid item xs={2} textAlign={"right"} pr={2}>
                  <Typography variant="subtitle2">
                    Actual AMP Billing
                  </Typography>{" "}
                </Grid>
                <Grid item xs={2}>
                  <Typography variant="body2" pt={0.5}>
                    {currencyformatterThreeDecimal.format(
                      values.unitActualCost *
                        (1 + values.additionalCostsPercentage / 100) *
                        (values.percentage / 100 + 1)
                    )}
                  </Typography>
                </Grid>
                <Grid item xs={4}></Grid>
              </Grid>
            )}

            <Grid item xs={12}>
              <FormTextArea
                name="description"
                label="Description"
                value={values.description}
                onChange={handleInputChange}
                error={errors.description}
                placeholder=""
                showSkeleton={isLoading}
                disabled={readOnly}
              />
            </Grid>
            <Grid item xs={12}>
              <Divider orientation="horizontal" flexItem></Divider>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle2" gutterBottom>
                Dimensions
              </Typography>
            </Grid>
            <Grid item xs={row}>
              <FormNumeric2
                name="lengthPackage"
                label="Length"
                value={values.lengthPackage}
                onChange={handleInputChange}
                maxValue={999999}
                suffix={'"'}
                error={errors.lengthPackage}
                placeholder=""
                showSkeleton={isLoading}
                decimalScale={2}
                disabled={readOnly}
              />
            </Grid>
            <Grid item xs={row}>
              <FormNumeric2
                name="widthPackage"
                label="Width"
                value={values.widthPackage}
                onChange={handleInputChange}
                maxValue={999999}
                suffix={'"'}
                error={errors.widthPackage}
                placeholder=""
                showSkeleton={isLoading}
                disabled={readOnly}
                decimalScale={2}
              />
            </Grid>
            <Grid item xs={row}>
              <FormNumeric2
                name="heightPackage"
                label="Height"
                value={values.heightPackage}
                onChange={handleInputChange}
                maxValue={999999}
                suffix={'"'}
                error={errors.heightPackage}
                placeholder=""
                showSkeleton={isLoading}
                disabled={readOnly}
                decimalScale={2}
              />
            </Grid>
            <Grid item xs={row}>
              <FormNumeric2
                name="weightPackage"
                label="Weight"
                value={values.weightPackage}
                onChange={handleInputChange}
                maxValue={999999}
                suffix={" Pounds"}
                error={errors.weightPackage}
                placeholder=""
                showSkeleton={isLoading}
                disabled={readOnly}
                decimalScale={2}
              />
            </Grid>
            <Grid item xs={row}>
              <FormNumeric2
                name="insuredValuePackage"
                label="Insure to the value of"
                value={values.insuredValuePackage}
                onChange={handleInputChange}
                maxValue={999999}
                prefix={"$"}
                decimalScale={2}
                error={errors.insuredValuePackage}
                placeholder=""
                showSkeleton={isLoading}
                disabled={readOnly}
              />
            </Grid>
            <Grid item xs={row}>
              <FormSelect
                name="isFirstOrderNeeded"
                label="Show after second order"
                value={values.isFirstOrderNeeded}
                onChange={handleInputChange}
                options={afterSecodnOrder}
                error={errors.isFirstOrderNeeded}
                disable={false}
                showSkeleton={isLoading}
                disabled={readOnly}
              />
            </Grid>
          </Grid>
        </Form>
      </>
      <Stack direction="row" spacing={3}>
        <FormCancelButton
          onClick={() => {
            props.setShowPopup(false);
          }}
          isSubmitting={false}
        />
        {!readOnly && (
          <FormAcceptButton
            onClick={handleSave}
            isSubmitting={false}
          ></FormAcceptButton>
        )}
      </Stack>
    </Popup>
  );
};

export default PlaqueDecalForm;
