import {
  Button,
  Grid,
  Typography,
  TypographyPropsVariantOverrides,
} from "@mui/material";
import { useCountdown } from "../../hooks/useCountdown";
import { useEffect, useState } from "react";
import { OverridableStringUnion } from "@mui/types";
import { Variant } from "@mui/material/styles/createTypography";

interface FormCountdownPros {
  date: Date;
  hideDays?: boolean;
  hideHours?: boolean;
  hideMinutes?: boolean;
  hideSeconds?: boolean;
  size?: "small" | "medium" | "large";
}

const FormCountdown = (props: FormCountdownPros) => {
  const { date, hideDays, hideHours, hideMinutes, hideSeconds, size } = props;
  const countDownDate = new Date(date).getTime();
  const [days, hours, minutes, seconds] = useCountdown(countDownDate);
  const [sizeTitle, setSizeTitle] =
    useState<
      OverridableStringUnion<
        Variant | "inherit",
        TypographyPropsVariantOverrides
      >
    >("h1");

  const [sizeName, setSizeName] =
    useState<
      OverridableStringUnion<
        Variant | "inherit",
        TypographyPropsVariantOverrides
      >
    >("h5");

  useEffect(() => {
    switch (size) {
      case "small":
        setSizeTitle("subtitle1");
        break;
      case "medium":
        setSizeTitle("h5");
        break;
      default:
        setSizeTitle("h1");
        break;
    }

    switch (size) {
      case "small":
        setSizeName("caption");
        break;
      case "medium":
        setSizeName("body2");
        break;
      default:
        setSizeName("h1");
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [size]);

  return (
    <>
      <Grid container spacing={12} textAlign={"center"}>
        {(!hideDays || days > 0) && (
          <Grid item xs={3}>
            <Button
              type="submit"
              fullWidth
              variant="outlined"
              color="primary"
              size={size ? size : "large"}
            >
              <Grid>
                <Grid item>
                  <Typography variant={sizeTitle} gutterBottom>
                    {days}
                  </Typography>
                </Grid>
                <Grid>
                  <Typography variant={sizeName} gutterBottom>
                    Days
                  </Typography>
                </Grid>
              </Grid>
            </Button>
          </Grid>
        )}
        {(!hideHours || hours > 0) && (
          <Grid item xs={3}>
            <Button
              type="submit"
              fullWidth
              variant="outlined"
              color="primary"
              size={size ? size : "large"}
            >
              <Grid>
                <Grid item>
                  <Typography variant={sizeTitle} gutterBottom>
                    {hours}
                  </Typography>
                </Grid>
                <Grid>
                  <Typography variant={sizeName} gutterBottom>
                    Hours
                  </Typography>
                </Grid>
              </Grid>
            </Button>
          </Grid>
        )}
        {(!hideMinutes || minutes > 0) && (
          <Grid item xs={3}>
            <Button
              type="submit"
              fullWidth
              variant="outlined"
              color="primary"
              size={size ? size : "large"}
            >
              <Grid>
                <Grid item>
                  <Typography variant={sizeTitle} gutterBottom>
                    {minutes}
                  </Typography>
                </Grid>
                <Grid>
                  <Typography variant={sizeName} gutterBottom>
                    Minutes
                  </Typography>
                </Grid>
              </Grid>
            </Button>
          </Grid>
        )}
        {(!hideSeconds || seconds > 0) && (
          <Grid item xs={3}>
            <Button
              type="submit"
              fullWidth
              variant="outlined"
              color="primary"
              size={size ? size : "large"}
            >
              <Grid>
                <Grid item>
                  <Typography variant={sizeTitle} gutterBottom>
                    {seconds}
                  </Typography>
                </Grid>
                <Grid>
                  <Typography variant={sizeName} gutterBottom>
                    Seconds
                  </Typography>
                </Grid>
              </Grid>
            </Button>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default FormCountdown;
