import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import useLog from "src/hooks/useLog";
import {
  CardContent,
  Grid,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  Typography,
  Stack,
  Button,
} from "@mui/material";
import { spacing } from "@mui/system";
import { useForm, Form, useCountry, useStateCatalog, useAuth } from "src/hooks";
import {
  FormCancelButton,
  FormSaveButton,
  FormSelect,
  FormText,
  FormMaskText,
} from "src/components/formControls";
import { IUserNew, IRoleAlias, IRolePost } from "src/ts/interfaces";
import { Validator } from "src/ts/types";
import { COUNTRIES, STATUSES, SubscriptionType } from "src/constants";
import EditUserRoles from "./components/EditUserRoles";
import Popup from "src/components/Popup";
import { CompanyParams } from "src/ts/types";
import { userService } from "src/services";
import useZipCode from "src/hooks/useZipCode";
import UnionSection from "../profile/UnionSection";
import DialogMessagePopup from "src/components/DialogMessagePopup";
import teamMemberServices from "src/services/teamMemberServices";

const Card = styled(MuiCard)(spacing);
const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const initialValues: IUserNew = {
  unibesEnabled: true,
  name: "",
  lastName: "",
  address: "",
  address2: "",
  zip: "",
  city: "",
  stateName: "",
  phone: null,
  email: "",
  fixedPhone: null,
  unionAffiliated: {
    unionAffiliatedAnswer: false,
    smwiaLocalUnion: "",
    smwiaMember: "",
    unionAffiliationNumbers: {
      localUnion: "",
    },
    localUnionsId: 0,
    laborUnionsId: 0,
  },
  StatusId: 1,
  countryId: 235,
  country: {
    id: 0,
    name: "",
  },
  countryCode: "",
  state: null,
  stateId: null,
  CompanyId: "",
  UserName: "",
};

const initialValuesRoles: IRolePost = {
  UserName: "",
  id: 0,
  role: [],
  type: "",
};

const AddNewUser = () => {
  const { log } = useLog();
  let { id } = useParams<CompanyParams>();
  const { user } = useAuth();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [informationShowPopUp, setInofrmationShowPopUp] = useState(false);
  const [roleCatalog, setRoleCatalog] = useState<IRoleAlias[]>([]);
  const [currentRoles, setCurrentRoles] = useState<string[]>([]);
  const [filterroles, setFilterroles] = useState<IRoleAlias[]>([]);

  const [registerAccount, setRegisterAccount] =
    useState<IRolePost>(initialValuesRoles);

  let navigate = useNavigate();

  const { countriesKeyValue, countries } = useCountry();
  const { states, setCountryId, isLoading } = useStateCatalog();

  const validate = (fieldValues = values) => {
    let temp: Record<string, string> = { ...errors };

    temp.name = new Validator(fieldValues, "name")
      .isRequired("The User's first name is required")
      .maxLength(200, "Only 200 character are allowed")
      .toString();

    temp.lastName = new Validator(fieldValues, "lastName")
      .isRequired("The User's last name is required.")
      .maxLength(200, "Only 200 character are allowed")
      .toString();

    temp.address = new Validator(fieldValues, "address")
      .isRequired("The User's address is required.")
      .maxLength(500, "Only 500 character are allowed")
      .toString();

    temp.address2 = new Validator(fieldValues, "address2")
      .maxLength(500, "Only 500 character are allowed")
      .toString();

    temp.countryId = new Validator(fieldValues, "countryId")
      .selectedOption("", "The User's country is required.")
      .toString();

    temp.zip = new Validator(fieldValues, "zip")
      .isRequired("The User's zipcode is required.")
      .maxLength(5, "Only 5 character are allowed")
      .toString();

    temp.city = new Validator(fieldValues, "city")
      .isRequired("The User's city is required.")
      .maxLength(50, "Only 50 character are allowed")
      .toString();

    temp.stateId = new Validator(fieldValues, "stateId")
      .validateIf(fieldValues.countryId === COUNTRIES.USA)
      .selectedOption(null, "The User's state is required.")
      .toString();

    temp.stateName = new Validator(fieldValues, "stateName")
      .validateIf(fieldValues.countryId !== COUNTRIES.USA)
      .isRequired("The User's state is required.")
      .maxLength(50, "Only 50 character are allowed")
      .toString();

    temp.phone = new Validator(fieldValues, "phone")
      .isRequired("The User's cell phone is required.")
      .phoneFormat("Incorrect phone format.")
      .toString();

    if (fieldValues?.unionAffiliated?.unionAffiliatedAnswer) {
      temp.laborUnionsId = new Validator(
        fieldValues.unionAffiliated,
        "laborUnionsId"
      )
        .selectedOption(0, "The Labor Union is required.")
        .toString();
      temp.smwiaMember = new Validator(
        fieldValues.unionAffiliated,
        "smwiaMember"
      )
        .isRequired("The Member # is required.")
        .toString();

      temp.localUnionsId = new Validator(
        fieldValues.unionAffiliated,
        "localUnionsId"
      )
        .selectedOption(0, "The Local Union is required.")
        .toString();
    }

    if (
      fieldValues?.unionAffiliated?.laborUnionsId === 2 ||
      fieldValues?.unionAffiliated?.laborUnionsId === 3
    ) {
      temp.localUnionsOther = new Validator(
        fieldValues.unionAffiliated,
        "localUnionsOther"
      )
        .isRequired("The Local Union is required.")
        .toString();
    }

    temp.email = new Validator(fieldValues, "email")
      .isRequired("The User's email is required.")
      .maxLength(50, "Only 50 character are allowed")
      .emailFormat("Insert a correct email. demo@bootlab.io")
      .toString();

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange } = useForm(
    initialValues,
    false,
    validate
  );
  const { zipValue } = useZipCode(values.zip);

  useEffect(() => {
    try {
      setIsSubmitting(true);
      if (countries.length > 0) {
        if (values.countryId === COUNTRIES.USA) {
          const stateCodevalue = states.filter((item: any) => {
            return item.key === values.stateId;
          });

          if (values.stateId != null && states.length > 0) {
            setValues({
              ...values,
              state: {
                id: stateCodevalue[0].key,
                name: stateCodevalue[0].value,
              },
            });
          }
        } else {
          setValues({
            ...values,
            state: null,
            stateId: null,
          });
        }

        if (SubscriptionType.Free !== user?.subscriptionId) {
          setFilterroles(
            currentRoles?.flatMap((item: any) => {
              return roleCatalog.filter((value: any) => {
                return item === value.alias;
              });
            })
          );
        }
      }
      if (SubscriptionType.Free === user?.subscriptionId) {
        const filterrole: IRoleAlias[] = [
          {
            id: "",
            alias: "Architect Assistant",
            name: "ArchitectAux",
            roleCanView: [],
            roleCanAdd: [],
            roleCanEdit: [],
            order: 0,
          },
        ];

        setRegisterAccount({
          UserName: values.email,
          id: 0,
          role: filterrole,
          type: "Select Role",
        });
        setFilterroles(filterrole);
      }
    } catch (error: any) {
    } finally {
      setIsSubmitting(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countries, values.email, values.stateId, values.countryId, currentRoles]);

  useEffect(() => {
    setValues({
      ...values,
      unionAffiliated: null,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (zipValue?.countryId !== undefined) {
      if (values.countryId === COUNTRIES.USA) {
        setValues({
          ...values,
          city: zipValue.city,
          stateId: zipValue.stateId,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [zipValue]);

  useEffect(() => {
    if (SubscriptionType.Free !== user?.subscriptionId) {
      setFilterroles(
        currentRoles?.flatMap((item: any) => {
          return roleCatalog.filter((value: any) => {
            return item === value.alias;
          });
        })
      );
      setRegisterAccount({
        UserName: values.email,
        id: 0,
        role: filterroles,
        type: "Select Role",
      });
    } else {
      const filterrole: IRoleAlias[] = [
        {
          id: "",
          alias: "Architect Assistant",
          name: "ArchitectAux",
          roleCanView: [],
          roleCanAdd: [],
          roleCanEdit: [],
          order: 0,
        },
      ];

      setRegisterAccount({
        UserName: values.email,
        id: 0,
        role: filterrole,
        type: "Select Role",
      });
      setFilterroles(filterrole);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentRoles, values.email]);

  useEffect(() => {
    setCountryId(values.countryId);
    if (countries.length > 0) {
      const countryCodevalue = countries.filter((item: any) => {
        return item.id === values.countryId;
      });

      setValues({
        ...values,
        countryId: countryCodevalue[0].id,
        countryCode: countryCodevalue[0].countryCode,
        CompanyId: id,
        UserName: values.email,
        country: {
          id: countryCodevalue[0].id,
          name: countryCodevalue[0].name,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setCountryId, values.countryId, countries]);

  const [teamMemberId, setTeamMemberId] = useState(0);

  const validateSaveHandler = async () => {
    if (!validate()) return;
    const emailOnTeamMembers = await userService.existEmailInTeamMember(
      values.email
    );

    if (emailOnTeamMembers.data !== null) {
      if (user?.CompanyId !== emailOnTeamMembers.data.companyId)
        log.error("The email is already registered to another user");
      else {
        setTeamMemberId(emailOnTeamMembers.data.id);
        setDeleteTeamMemberModal(!deleteTeamMemberModal);
      }
    } else {
      try {
        setIsSubmitting(true);
        const companyId = Number(id);

        const response = await userService.userPost(values);
        await userService.userAccountPost({
          ...registerAccount,
          id: Number(response.data.id),
          role: filterroles,
        });
        if (
          values.unionAffiliated &&
          values.unionAffiliated.unionAffiliatedAnswer
        ) {
          await userService.unionAffiliatedUser({
            laborUnions: values?.unionAffiliated?.laborUnions,
            laborUnionsId: values?.unionAffiliated?.laborUnionsId,
            localUnions: values?.unionAffiliated?.localUnions,
            localUnionsId: values?.unionAffiliated?.localUnionsId,
            smwiaMember: values?.unionAffiliated?.smwiaMember,
            unionAffiliatedAnswer:
              values?.unionAffiliated?.unionAffiliatedAnswer,
            userPersonId: response.data.id,
            localUnionsOther: values?.unionAffiliated?.localUnionsOther,
            laborUnionsOther: values?.unionAffiliated?.laborUnionsOther,
          });
        }

        await userService.getCheckUserPermissionRoute(companyId);
        setIsSubmitting(false);
        log.success("User was added");
        navigate(`/app/users/${id}`);
      } catch (error: any) {
        log.error(error?.message ?? "Something went wrong");
        setIsSubmitting(false);
      }
    }
  };

  const cancelHandler = () => {
    navigate(`/app/users/${id}`);
  };

  const handleOnChangeMask = (e: any) => {
    const { name, value } = e.target;

    setValues({ ...values, [name]: value });
  };

  const row = 6;

  const handleClosePopUp = () => {
    setInofrmationShowPopUp(false);
  };

  const [deleteTeamMemberModal, setDeleteTeamMemberModal] = useState(false);

  const handleDeleteTeamMember = async () => {
    try {
      setIsSubmitting(true);
      await teamMemberServices.deleteTeamMember(teamMemberId);
      log.success("The team member was successfully removed.");
    } catch (error: any) {
      log.error(error?.message?.exceptionMessage ?? "Something went wrong");
    } finally {
      setTeamMemberId(0);
      setDeleteTeamMemberModal(false);
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <Helmet title="Company" />
      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            User
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} to="/">
              Dashboard
            </Link>
            <Link component={NavLink} to="/app/users">
              Users
            </Link>
            <Typography>User</Typography>
          </Breadcrumbs>
        </Grid>
      </Grid>
      <Divider my={6} />
      <Card mb={6}>
        <CardContent>
          <Form>
            <Grid container spacing={5}>
              <Grid item xs={row}>
                <FormText
                  name="name"
                  label="First Name"
                  value={values.name}
                  onChange={handleInputChange}
                  error={errors.name}
                  showSkeleton={isLoading || isSubmitting}
                />
              </Grid>
              <Grid item xs={row}>
                <FormText
                  name="lastName"
                  label="Last Name"
                  value={values.lastName}
                  onChange={handleInputChange}
                  error={errors.lastName}
                  showSkeleton={isLoading || isSubmitting}
                />
              </Grid>
              <Grid item xs={row}>
                <FormText
                  name="address"
                  label="Address"
                  value={values.address}
                  onChange={handleInputChange}
                  error={errors.address}
                  showSkeleton={isLoading || isSubmitting}
                />
              </Grid>
              <Grid item xs={row}>
                <FormText
                  name="address2"
                  label="Address 2"
                  value={values.address2}
                  onChange={handleInputChange}
                  error={errors.address2}
                  showSkeleton={isLoading || isSubmitting}
                />
              </Grid>
              <Grid item xs={row}>
                <FormSelect
                  name="countryId"
                  label="Country"
                  value={values.countryId}
                  onChange={handleInputChange}
                  options={countriesKeyValue}
                  error={errors.countryId}
                  disable={isSubmitting}
                  showSkeleton={isLoading || isSubmitting}
                />
              </Grid>
              <Grid item xs={row}>
                <FormText
                  name="zip"
                  label="Zip Code"
                  value={values.zip}
                  onChange={handleInputChange}
                  error={errors.zip}
                  showSkeleton={isLoading || isSubmitting}
                />
              </Grid>
              <Grid item xs={row}>
                <FormText
                  name="city"
                  label="City"
                  value={values.city}
                  onChange={handleInputChange}
                  error={errors.city}
                  showSkeleton={isLoading || isSubmitting}
                />
              </Grid>
              {values.countryId !== Number.MIN_SAFE_INTEGER &&
              values.countryId === COUNTRIES.USA ? (
                <Grid item xs={row}>
                  <FormSelect
                    name="stateId"
                    label="State"
                    value={values.stateId}
                    onChange={handleInputChange}
                    options={states}
                    error={errors.stateId}
                    disable={isSubmitting}
                    showSkeleton={isLoading || isSubmitting}
                  />
                </Grid>
              ) : (
                <Grid item xs={row}>
                  <FormText
                    name="stateName"
                    label="State"
                    value={values.stateName}
                    onChange={handleInputChange}
                    error={errors.stateName}
                    showSkeleton={isLoading || isSubmitting}
                  />
                </Grid>
              )}
              <Grid item xs={row}>
                <Stack direction="row">
                  <Grid container>
                    <Grid item xs={1.5}>
                      <FormText
                        name="countryCode"
                        label="Code"
                        value={values.countryCode}
                        showSkeleton={isLoading || isSubmitting}
                      />
                    </Grid>
                    <Grid item xs={10.5}>
                      <FormMaskText
                        name="phone"
                        mask="(999) 999 - 9999"
                        label="Cell Phone"
                        onChange={handleOnChangeMask}
                        value={values.phone}
                        error={errors.phone}
                        showSkeleton={isLoading || isSubmitting}
                      />
                    </Grid>
                  </Grid>
                </Stack>
              </Grid>
              <Grid item xs={row}>
                <Stack direction="row">
                  <Grid container>
                    <Grid item xs={1.5}>
                      <FormText
                        name="countryCode"
                        label="Code"
                        value={values.countryCode}
                        showSkeleton={isLoading || isSubmitting}
                      />
                    </Grid>
                    <Grid item xs={10.5}>
                      <FormMaskText
                        name="fixedPhone"
                        mask="(999) 999 - 9999"
                        label="Phone"
                        onChange={handleOnChangeMask}
                        value={values.fixedPhone}
                        error={errors.fixedPhone}
                        showSkeleton={isLoading || isSubmitting}
                      />
                    </Grid>
                  </Grid>
                </Stack>
              </Grid>
              <Grid item xs={row}>
                <FormText
                  name="email"
                  label="Email  "
                  value={values.email}
                  onChange={handleInputChange}
                  error={errors.email}
                  showSkeleton={isLoading || isSubmitting}
                />
              </Grid>
              <Grid item xs={row}>
                <FormSelect
                  name="StatusId"
                  label="Status"
                  value={values.StatusId}
                  onChange={handleInputChange}
                  options={STATUSES}
                  error={errors.StatusId}
                  disable={isSubmitting}
                  showSkeleton={isLoading || isSubmitting}
                />
              </Grid>
              {user && SubscriptionType.Free !== user?.subscriptionId && (
                <>
                  <Grid item xs={row}>
                    <EditUserRoles
                      setRolesData={setRoleCatalog}
                      userId={0}
                      userRole={user?.role}
                      userAuth={values?.userAuth}
                      setSelectedRoles={setCurrentRoles}
                      showSkeleton={isLoading || isSubmitting}
                    />
                  </Grid>

                  {process.env.REACT_APP_ENVIRONMENT !== "SCHOOL" && (
                    <Grid item xs={12}>
                      <UnionSection
                        values={values}
                        setValues={setValues}
                        errors={errors}
                      />
                    </Grid>
                  )}
                </>
              )}
              <Grid item xs={12}>
                <Stack
                  direction="row"
                  spacing={2}
                  justifyContent="center"
                  alignItems="center"
                >
                  <FormCancelButton
                    onClick={cancelHandler}
                    isSubmitting={isSubmitting}
                  />
                  <FormSaveButton
                    onClick={validateSaveHandler}
                    isSubmitting={isSubmitting}
                    disabled={
                      user &&
                      (SubscriptionType.Free === user?.subscriptionId ||
                        currentRoles.length > 0)
                        ? false
                        : true
                    }
                  />
                </Stack>
              </Grid>
            </Grid>
          </Form>
        </CardContent>
      </Card>

      <Popup
        title="Information"
        openPopup={informationShowPopUp}
        setOpenPopup={setInofrmationShowPopUp}
        onClose={handleClosePopUp}
        size="sm"
      >
        <>
          <Typography>
            <b>
              The acronym SMWIA specifically represents the Sheet Metal Workers
              International Association, which pertains to union affiliation.
            </b>
          </Typography>
          <br />
          <Typography>
            <b>
              Additionally, the acronym SMART represents Sheet Metal, Air, Rail,
              and Transportation workers.
            </b>
          </Typography>
        </>
        <Stack direction="row" spacing={3}>
          <Button
            type="submit"
            variant="contained"
            color="error"
            onClick={handleClosePopUp}
          >
            Close
          </Button>
        </Stack>
      </Popup>

      <DialogMessagePopup
        title={"Warning"}
        text={
          <>
            <Typography
              paddingBottom={3}
            >{`The ${values.email} is currently used by a team member.`}</Typography>
            <Typography>Do you want to remove this team member?</Typography>
          </>
        }
        showPopup={deleteTeamMemberModal}
        setShowPopup={setDeleteTeamMemberModal}
        onSave={handleDeleteTeamMember}
        onCancel={() => {
          setDeleteTeamMemberModal(false);
        }}
        isSubmitting={isSubmitting}
      />
    </>
  );
};

export default AddNewUser;
