import React, { useEffect, useState } from "react";
import { Stack } from "@mui/material";
import { FormButton } from "src/components/formControls";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import PlaceIcon from "@mui/icons-material/Place";
import BusinessIcon from "@mui/icons-material/Business";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import PublicIcon from "@mui/icons-material/Public";
import useAuth from "src/hooks/useAuth";
import TestingUnitCostModal from "src/components/modals/TestingUnitCostModal";
import TimeCardPopup from "src/components/timeCardPopup/TimeCardPopup";
import { ProjectSelectedDashboardDTO } from "src/ts/interfaces";
import { useNavigate } from "react-router-dom";
import DailyLogPopUp from "src/pages/project/Components/DailyLogPopUp";
import getProjectSingleService from "src/services/project/getProjectSingleService";
import { ProjectWithBogus } from "src/ts/interfaces/project/projectDto";
import EstimatedTimePopup from "src/components/estimatedTimePopup/EstimatedTimePopup";
import { useLog } from "src/hooks";

interface Props {
  showUnderConstruction: () => void;
  projectCurrent: ProjectSelectedDashboardDTO;
  companyId: number;
}

function Actions({ showUnderConstruction, projectCurrent, companyId }: Props) {
  const { log } = useLog();
  const { user } = useAuth();
  const navigate = useNavigate();
  const [testingUnitCostModalShow, setTestingUnitCostModalShow] =
    useState(false);
  const [showDailyLogPopUp, setShowDailyLogPopUp] = useState(false);
  const [project, setProject] = useState<ProjectWithBogus>();
  const [validateEstimatedTime, setValidateEstimatedTime] = useState(0);
  const [showTimeCardPopup, setShowTimeCardPopup] = useState(false);
  const [showEstimatedTimePopup, setShowEstimatedTimePopup] = useState(false);

  useEffect(() => {
    const getProjectInfo = async () => {
      try {
        const response = await getProjectSingleService.getById(
          projectCurrent.project.id
        );
        setProject(response.data);
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      }
    };
    if (projectCurrent?.project?.id > 0) getProjectInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectCurrent]);

  return (
    <React.Fragment>
      <Stack direction="row" spacing={2}>
        {user?.canAddBuildingsAndProjects === "true" && (
          <>
            <FormButton
              text={"Create Building"}
              onClick={() => navigate(`/app/Building/0/${companyId}`)}
              startIcon={<BusinessIcon />}
              size="small"
            />
            <FormButton
              text={"Create Project"}
              onClick={() => navigate(`/app/Project/Edit/0/${companyId}`)}
              startIcon={<AccountTreeIcon />}
              size="small"
            />
          </>
        )}

        <FormButton
          color="success"
          text={"Testing Unit Cost"}
          onClick={() => {
            setTestingUnitCostModalShow(true);
          }}
          startIcon={<AttachMoneyIcon />}
          size="small"
        />

        <FormButton
          color="success"
          text={"Global Energy Prices"}
          onClick={() => {
            window.open("https://www.globalpetrolprices.com/", "_blank");
          }}
          startIcon={<PublicIcon />}
          size="small"
        />

        {projectCurrent?.project !== null && (
          <>
            <FormButton
              variant="outlined"
              text={"Time Card"}
              onClick={() => {
                setShowTimeCardPopup(true);
              }}
              startIcon={<AccessTimeIcon />}
              size="small"
            />
            <FormButton
              variant="outlined"
              text={"Estimated Time"}
              onClick={() => {
                setShowEstimatedTimePopup(true);
              }}
              startIcon={<CalendarMonthIcon />}
              size="small"
            />
            <FormButton
              variant="outlined"
              text={"Daily Log"}
              onClick={() => {
                setShowDailyLogPopUp(true);
              }}
              startIcon={<PlaceIcon />}
              size="small"
            />
          </>
        )}
      </Stack>
      <TestingUnitCostModal
        showPopup={testingUnitCostModalShow}
        setShowPopup={setTestingUnitCostModalShow}
      />
      {showDailyLogPopUp &&
        projectCurrent !== null &&
        projectCurrent?.project?.id !== 0 && (
          <DailyLogPopUp
            showDailyLogPopUp={showDailyLogPopUp}
            setShowDailyLogPopUp={setShowDailyLogPopUp}
            projectId={projectCurrent?.project?.id}
            dashboardView={true}
          />
        )}

      {showTimeCardPopup && project !== undefined && (
        <TimeCardPopup
          project={project}
          isDialogOpen={showTimeCardPopup}
          setIsDialogOpen={setShowTimeCardPopup}
          isCurrentProject={true}
          validateEstimatedTime={() => {
            setValidateEstimatedTime(validateEstimatedTime + 1);
          }}
        />
      )}

      {showEstimatedTimePopup && project !== undefined && (
        <EstimatedTimePopup
          project={project}
          isDialogOpen={showEstimatedTimePopup}
          setIsDialogOpen={setShowEstimatedTimePopup}
          isCurrentProject={true}
          validateEstimatedTime={validateEstimatedTime}
        />
      )}
    </React.Fragment>
  );
}

export default Actions;
