import { IStudyStep } from "src/ts/interfaces";

export const steps = [
  {
    label: "Step 1",
    key: 0,
    description: "System",
    url: "/studies/pumpReport/step1/:id",
  },
  {
    label: "Step 2",
    key: 1,
    description: "System Design",
    url: "/studies/pumpReport/step2/:id",
  },
  {
    label: "Step 3",
    key: 2,
    description: "Nameplates Pump - Motor",
    url: "/studies/pumpReport/step3/:id",
  },
  {
    label: "Step 4",
    key: 3,
    description: "Additional components",
    url: "/studies/pumpReport/step4/:id",
  },
  {
    label: "Step 5",
    key: 4,
    description: "Actuals",
    url: "/studies/pumpReport/step5/:id",
  },
  {
    label: "Step 6",
    key: 5,
    description: "Report",
    url: "/studies/pumpReport/step6/:id",
  },
] as IStudyStep[];
