import { ITechnicianAssigmentProject } from "src/ts/interfaces/technicianAssigmentProject";
import axios from "src/utils/axios";

const createAssigment = (assigmentProject: ITechnicianAssigmentProject) => {
  return axios.post(`TechnicianAssigmentProject`, assigmentProject);
};

const assignTechnician = (projectId: number) => {
  return axios.post(`TechnicianAssigmentProject?projectId=${projectId}`);
};

const TechnicianAssigmentProjectService = {
  createAssigment,
  assignTechnician,
};

export default TechnicianAssigmentProjectService;
