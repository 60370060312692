import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface IAppGlobalState {
  showOutdatedVersionPopup: boolean;
  newVersion: string;
  currentVersion: string;
}

const initialState: IAppGlobalState = {
  showOutdatedVersionPopup: false,
  newVersion: "",
  currentVersion: "",
};

export const appGlobal = createSlice({
  name: "appGlobal",
  initialState,
  reducers: {
    setShowOutdatedVersionPopup(
      state: IAppGlobalState,
      action: PayloadAction<boolean>
    ) {
      state.showOutdatedVersionPopup = action.payload;
    },
    setVersions(
      state: IAppGlobalState,
      action: PayloadAction<{ newVersion: string; currentVersion: string }>
    ) {
      state.newVersion = action.payload.newVersion;
      state.currentVersion = action.payload.currentVersion;
    },
  },
});

export default appGlobal;
