import React, { useState, useEffect } from "react";
import {
  Grid,
  Stack,
  Stepper,
  Step,
  StepLabel,
  Card,
  CardContent,
} from "@mui/material";
import { useLog, useAuth, useAsyncMutation } from "src/hooks";
import { FormButton } from "src/components/formControls";
import Popup from "src/components/Popup";
import {
  equipmentProjectService,
  projectIsCompleteService,
} from "src/services";
import CompleteValidateBuildingPicture from "./CompleteValidateBuildingPicture";
import CompleteValidateStudies from "./CompleteValidateStudies";
import CompleteEquipmentValidation from "./CompleteEquipmentValidation";
import CompleteAcceptance from "./CompleteAcceptance";
import CompleteComments from "./CompleteComments";
import CompletePayment from "./CompletePayment";
import { ICompany, IEquipment, IProjectReporDisplayt } from "src/ts/interfaces";
import { ProjectDTO } from "src/ts/interfaces/project/projectDto";
import techniciansHistoryService from "src/services/techniciansHistoryService";
import equipmentService from "src/services/equipmentService";
import buildingPhotoService from "src/services/buildingPhotoService";
import useFormTyped from "src/hooks/useFormTyped";
import { Aceptance, CommentForm } from "../interfaces/types";
import moment from "moment";
import { Validator } from "src/ts/types/Validator";
import Complete from "./Complete";
import { ROLES } from "src/constants";
import FormNextButton from "src/components/formControls/FormNextButton";
import FormBackButton from "src/components/formControls/FormBackButton";

const initialValues: Aceptance = {
  acceptance1: false,
  acceptance2: false,
  acceptance3: false,
};

const initialCommentValues: CommentForm = {
  comment: "",
};

enum WizardStepsEnum {
  ValidateBuildingPicture,
  ValidateIncompleteStudies,
  EquipmentValidation,
  Acceptance,
  Comments,
  Complete,
  PaymentConfirmation,
}

interface CompleteProjectStep {
  label: string;
  key: number;
  type: WizardStepsEnum;
}

interface ConfirmModalProps {
  isDialogOpen: boolean;
  setIsDialogOpen: (val: boolean) => void;
  companyUser: ICompany | null;
  projectId: number;
  project: ProjectDTO;
  studies: IProjectReporDisplayt[];
  refreshProject: () => void;
  showIncompleteStudiesPopup: () => void;
}

const CompleteProjectPopup = (props: ConfirmModalProps) => {
  const {
    isDialogOpen,
    setIsDialogOpen,
    companyUser,
    projectId,
    project,
    studies,
    refreshProject,
    showIncompleteStudiesPopup,
  } = props;
  const { log } = useLog();
  const { user } = useAuth();
  const [steps, setSteps] = useState<CompleteProjectStep[]>([]);
  const [activeStep, setActiveStep] = useState<number>(0);
  const [allEquipmentsState, setAllEquipmentState] = useState<IEquipment[]>([]);
  const [selectedEquipmentIds, setSelectedEquipmentIds] = useState<number[]>(
    []
  );
  const [isSubmitting, setIsSubmitting] = useState(false);
  const validate: any = (fieldValues = AceptanceValues) => {
    let temp: Record<string, string> = { ...errors };

    setErrors({
      ...temp,
    });

    if (fieldValues === AceptanceValues)
      return Object.values(temp).every((x) => x === "");
  };

  const {
    values: AceptanceValues,
    errors,
    setErrors,
    handleInputChange: handleAceptanceInputChange,
    resetForm: resetAceptanceForm,
  } = useFormTyped<Aceptance>(initialValues, false, validate);

  const validateCommentForm: any = (fieldValues = commentValues) => {
    let temp: Record<string, string> = { ...errors };

    temp.comment = new Validator(fieldValues, "comment")
      .isRequired("Comments are required")
      .maxLength(500, "Only 500 character are allowed")
      .toString();

    setCommentErrors({
      ...temp,
    });

    if (fieldValues === commentValues)
      return Object.values(temp).every((x) => x === "");
  };

  const {
    values: commentValues,
    errors: commentErrors,
    setErrors: setCommentErrors,
    handleInputChange: handleCommentInputChange,
  } = useFormTyped<CommentForm>(
    initialCommentValues,
    false,
    validateCommentForm
  );

  const setupWizard = async () => {
    const responseEquipment = await equipmentService.equipmentAssigned();
    const equipments = responseEquipment.data;

    const responseTechician = await techniciansHistoryService.getAuditorList(
      project.id
    );

    const auditorsHistory = responseTechician.data;

    const equipmentOfficeShop = equipments.filter(function (item1) {
      if (item1.companyAssets.officeShop) {
        return true;
      }
      return false;
    });

    const auditorsHistoryAssignedToMe = equipments.filter(function (item2) {
      for (var i in auditorsHistory) {
        if (item2.companyAssets.assignedToId === auditorsHistory[i].userId) {
          return true;
        }
      }
      return false;
    });

    const allEquipments = [
      ...auditorsHistoryAssignedToMe,
      ...equipmentOfficeShop,
    ];

    setAllEquipmentState([...allEquipments]);
    // let countEquipment = false;
    // if (project.equipmentCalibrationReportRequired) {
    //   allEquipments.forEach(function (valuesRepor) {
    //     if (true == valuesRepor.equipmentCalibrationReportRequired) {
    //       countEquipment = true;
    //     }
    //   });
    // }

    await equipmentProjectService.post(project.id, allEquipments);
    const hasBuildingResponse = await buildingPhotoService.hasBuildingProject(
      project.id
    );

    const hasBuildingPhoto = hasBuildingResponse.data;

    let wizardSteps: CompleteProjectStep[] = [];
    let xitems = -1;
    if (project.isInfectiousControlProject) {
      if (!hasBuildingPhoto) {
        wizardSteps.push({
          key: xitems++,
          label: "Validate Building Picture",
          type: WizardStepsEnum.ValidateBuildingPicture,
        });
      }

      var incompleteReports = studies.filter(function (item) {
        return item.studyStatus !== "Complete";
      });

      if (incompleteReports.length > 0) {
        wizardSteps.push({
          key: xitems++,
          label: "Validate Incomplete Studies",
          type: WizardStepsEnum.ValidateIncompleteStudies,
        });
      }

      if (allEquipments.length > 0) {
        wizardSteps.push({
          key: xitems++,
          label: "Equipment Validation / Assignment",
          type: WizardStepsEnum.EquipmentValidation,
        });
      }
      wizardSteps.push({
        key: xitems++,
        label: "Acceptance",
        type: WizardStepsEnum.Acceptance,
      });

      if (user?.role === ROLES.SuperAdministrator) {
        wizardSteps.push({
          key: xitems++,
          label: "Comments",
          type: WizardStepsEnum.Comments,
        });
      }

      wizardSteps.push({
        key: xitems++,
        label: "Complete",
        type: WizardStepsEnum.Complete,
      });

      setSteps([...wizardSteps]);
      setActiveStep(0);
    } else {
      if (project.completionPaymentByOwner) {
        await completeProjectHandler(true, true, []);
      } else {
        if (!hasBuildingPhoto) {
          wizardSteps.push({
            key: xitems++,
            label: "Validate Building Picture",
            type: WizardStepsEnum.ValidateBuildingPicture,
          });
        }

        let incompleteReports = studies.filter(function (item) {
          return item.studyStatus !== "Complete";
        });

        if (incompleteReports.length > 0) {
          wizardSteps.push({
            key: xitems++,
            label: "Validate Incomplete Studies",
            type: WizardStepsEnum.ValidateIncompleteStudies,
          });
        }

        if (allEquipments.length > 0) {
          wizardSteps.push({
            key: xitems++,
            label: "Equipment Validation / Assignment",
            type: WizardStepsEnum.EquipmentValidation,
          });
        }

        wizardSteps.push({
          key: xitems++,
          label: "Acceptance",
          type: WizardStepsEnum.Acceptance,
        });

        if (user?.role === "SuperAdministrator") {
          wizardSteps.push({
            key: xitems++,
            label: "Comments",
            type: WizardStepsEnum.Comments,
          });
        }

        var airWaterReports = studies.filter(function (item) {
          return (
            item.studyStatus === "Complete" &&
            item.reportTypeId !== 18 &&
            item.reportTypeId !== 24
          );
        });

        if (airWaterReports.length > 0) {
          wizardSteps.push({
            key: xitems++,
            label: "Payment Confirmation",
            type: WizardStepsEnum.PaymentConfirmation,
          });
        } else {
          wizardSteps.push({
            key: xitems++,
            label: "Complete",
            type: WizardStepsEnum.Complete,
          });
        }

        setSteps([...wizardSteps]);
        setActiveStep(0);
      }
    }
  };

  useEffect(() => {
    if (isDialogOpen) {
      setupWizard();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDialogOpen]);

  const previousStepHandler = () => {
    setActiveStep(activeStep - 1);
  };

  const { execute: saveEquipments } = useAsyncMutation(
    equipmentProjectService.post
  );

  const nexStepHandler = async () => {
    if (steps[activeStep]?.type === WizardStepsEnum.Comments)
      if (!validateCommentForm()) return;

    setActiveStep(activeStep + 1);
    if (steps[activeStep]?.type === WizardStepsEnum.EquipmentValidation) {
      const equipmentRequired = allEquipmentsState.map((equipment) => ({
        ...equipment,
        equipmentCalibrationReportRequired: selectedEquipmentIds.includes(
          equipment.id
        ),
      }));

      await saveEquipments(projectId, equipmentRequired);
    }
  };

  const selectedEquipmentHandler = (selectedIds: number[]) => {
    setSelectedEquipmentIds([...selectedIds]);
  };

  const completeWaitGenerate = () => {
    setIsDialogOpen(false);
    refreshProject();
  };

  const completeProjectHandler = async (
    sendToCart: boolean,
    isBomaProject: boolean,
    coupons: string[]
  ) => {
    const localDate = moment().toDate();
    let endDate = moment(localDate).format("MM/DD/YYYY HH:mm").toLocaleString();

    try {
      setIsSubmitting(true);
      var result = await projectIsCompleteService.completePayment(
        projectId,
        endDate,
        sendToCart,
        commentValues.comment,
        isBomaProject,
        coupons
      );
      log.success("Project completed successfully");
      if (result.data.statusCode !== 0) {
        if (result.data.sentToCart) log.success("Project was send to cart");
        log.error(result.data.resultMessage);
      }
    } catch (error: any) {
      const message =
        error?.message?.exceptionMessage || "Something went wrong";
      log.error(message);
    } finally {
      setIsSubmitting(false);
      setIsDialogOpen(false);
      refreshProject();
    }
  };

  const completeHandler = async () => {
    if (project.isInfectiousControlProject) {
      try {
        setIsSubmitting(true);
        await projectIsCompleteService.completeInfect(
          projectId,
          commentValues.comment
        );
      } catch (error: any) {
        const message = error.message || "Something went wrong";
        log.error(message);
      } finally {
        setIsSubmitting(true);
        setIsDialogOpen(false);
        refreshProject();
      }
    } else {
      await completeProjectHandler(false, false, []);
    }
  };

  const cancelHandler = () => {
    setActiveStep(0);
    resetAceptanceForm();
    setIsDialogOpen(false);
  };

  return (
    <Popup
      openPopup={isDialogOpen}
      setOpenPopup={setIsDialogOpen}
      title={`Complete/Finalize Project & Report(s) - ${project.name}`}
      onClose={cancelHandler}
      size="md"
      disableClickOutside
      isSubmitting={isSubmitting}
    >
      <Grid container>
        <Grid item xs={12} spacing={6}>
          <Stepper alternativeLabel activeStep={activeStep}>
            {steps.map((step) => (
              <Step key={step.key}>
                <StepLabel>{step.label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </Grid>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              {steps[activeStep]?.type ===
                WizardStepsEnum.ValidateBuildingPicture && (
                <CompleteValidateBuildingPicture
                  buildingId={project.buildingId}
                  companyId={user?.companyId}
                />
              )}
              {steps[activeStep]?.type ===
                WizardStepsEnum.ValidateIncompleteStudies && (
                <CompleteValidateStudies
                  showIncompleteStudiesPopup={showIncompleteStudiesPopup}
                  cancelHandler={cancelHandler}
                />
              )}
              {steps[activeStep]?.type ===
                WizardStepsEnum.EquipmentValidation && (
                <CompleteEquipmentValidation
                  allEquipments={allEquipmentsState}
                  onSelect={selectedEquipmentHandler}
                  selectedItemsIds={selectedEquipmentIds}
                />
              )}
              {steps[activeStep]?.type === WizardStepsEnum.Acceptance && (
                <CompleteAcceptance
                  handleAceptanceInputChange={handleAceptanceInputChange}
                  AceptanceValues={AceptanceValues}
                />
              )}
              {steps[activeStep]?.type === WizardStepsEnum.Comments && (
                <CompleteComments
                  handleCommentInputChange={handleCommentInputChange}
                  values={commentValues}
                  commentErrors={commentErrors}
                />
              )}
              {steps[activeStep]?.type ===
                WizardStepsEnum.PaymentConfirmation && (
                <CompletePayment
                  onCompleteProject={completeProjectHandler}
                  companyUser={companyUser}
                  projectId={projectId}
                  completeWaitGenerate={completeWaitGenerate}
                />
              )}
              {steps[activeStep]?.type === WizardStepsEnum.Complete && (
                <Complete
                  onCompleteProject={completeHandler}
                  companyUser={companyUser}
                  projectId={projectId}
                />
              )}
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}></Grid>
      </Grid>
      <>
        <Stack
          direction="row"
          spacing={2}
          justifyContent="center"
          alignItems="center"
        >
          {activeStep > 0 && (
            <FormBackButton
              onClick={previousStepHandler}
              size="small"
              variant="outlined"
              isSubmitting={false}
            />
          )}
          <FormButton
            text="Cancel"
            onClick={cancelHandler}
            size="small"
            variant="outlined"
            color="primary"
          />
          {activeStep < steps.length - 1 && (
            <FormNextButton
              onClick={nexStepHandler}
              size="small"
              isSubmitting={false}
              disabled={
                steps[activeStep]?.type === WizardStepsEnum.Acceptance &&
                !(
                  AceptanceValues.acceptance1 &&
                  AceptanceValues.acceptance2 &&
                  AceptanceValues.acceptance3
                )
              }
            />
          )}
        </Stack>
      </>
    </Popup>
  );
};

export default CompleteProjectPopup;
