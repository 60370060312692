import Popup from "src/components/Popup";
import { FormButton } from "src/components/formControls";
import { Stack, Grid, Chip as MuiChip, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { AccessTime, HistoryRounded, Person } from "@mui/icons-material";
import { useAsyncQuery } from "src/hooks";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import projectHistoryStatusCommentsService from "src/services/project/projectHistoryStatusCommentsService";
import { IProjectHistoryStatusComments } from "src/ts/interfaces";
import { SpacingProps, spacing, styled } from "@mui/system";
import { convertUTCDateToLocalDate } from "src/utils/utils";
import { projectStatus } from "src/constants";

interface props {
  projectId: number;
  projectStatusId: number;
}
interface ChipProps extends SpacingProps {
  component?: React.ElementType;
  href?: string;
  icon?: JSX.Element | null;
}
const Chip = styled(MuiChip)<ChipProps>(spacing);

export default function HistoryBomaEvaluation({
  projectId,
  projectStatusId,
}: props) {
  const [modal, setModal] = useState(false);
  const { execute, data } = useAsyncQuery<IProjectHistoryStatusComments[]>(
    projectHistoryStatusCommentsService.getProjectHistoryStatusComments
  );

  const { execute: executeRejected, data: dataRejected } =
    useAsyncQuery<boolean>(
      projectHistoryStatusCommentsService.getRejectedProjectHistoryStatusComments
    );

  useEffect(() => {
    const getData = async () => {
      await executeRejected(projectId);
    };
    if (modal) getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modal]);

  useEffect(() => {
    const getData = async () => {
      await execute(projectId);
    };
    if (projectId || modal) getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId, modal]);

  return (
    <>
      {(projectStatusId === projectStatus.Rejected.id ||
        projectStatusId === projectStatus.Approved.id ||
        dataRejected) && (
        <FormButton
          text={"History"}
          onClick={() => {
            setModal(true);
          }}
          startIcon={<HistoryRounded />}
          size="small"
          variant="outlined"
        ></FormButton>
      )}

      <Popup
        title={`Project History Status & Comments`}
        openPopup={modal}
        setOpenPopup={setModal}
        onClose={() => {
          setModal(false);
        }}
        size="sm"
      >
        <>
          <Grid container spacing={4}>
            <Grid item xs={12} textAlign={"center"}>
              <Timeline position="alternate">
                {data?.map((item: any, index: number) => (
                  <TimelineItem>
                    <TimelineSeparator>
                      <TimelineDot variant="outlined" />
                      <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>
                      {item.statusId === 8 && (
                        <Chip
                          label={item.projectStatus.name}
                          color="warning"
                          m={1}
                          size="small"
                        />
                      )}
                      {item.statusId === 11 && (
                        <Chip
                          label={item.projectStatus.name}
                          color="success"
                          m={1}
                          size="small"
                        />
                      )}
                      {item.statusId === 10 && (
                        <Chip
                          label={item.projectStatus.name}
                          color="error"
                          m={1}
                          size="small"
                        />
                      )}
                      {item.statusId === 13 && (
                        <Chip
                          label={item.projectStatus.name}
                          color="primary"
                          m={1}
                          size="small"
                        />
                      )}
                      {item.statusId === 9 && (
                        <Chip
                          label={"Closed"}
                          color="warning"
                          m={1}
                          size="small"
                        />
                      )}
                      {item.statusId === 7 && (
                        <Chip
                          label={"Archived"}
                          color="secondary"
                          m={1}
                          size="small"
                        />
                      )}
                      {item.statusId === 1 && (
                        <Chip
                          label={"Reopen"}
                          color="info"
                          m={1}
                          size="small"
                        />
                      )}
                      <Typography variant="h6" p={1} pb={2} pt={2}>
                        {item.comments}
                      </Typography>
                      <Grid container>
                        <Grid item xs={12}>
                          <AccessTime fontSize="inherit" />
                          <Typography variant="caption">
                            {convertUTCDateToLocalDate(
                              item.createdDate ?? ""
                            )?.format("MM/DD/yyyy h:mma")}
                            {}
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Person fontSize="inherit" />
                          <Typography variant="caption">
                            {item.created.name} {item.created.lastName}
                          </Typography>
                        </Grid>
                      </Grid>
                    </TimelineContent>
                  </TimelineItem>
                ))}
              </Timeline>
            </Grid>
          </Grid>
        </>
        <Stack direction="row" spacing={3}>
          <FormButton
            size="small"
            text="Close"
            color="primary"
            variant="outlined"
            onClick={() => {
              setModal(false);
            }}
          />
        </Stack>
      </Popup>
    </>
  );
}
