import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import {
  CardContent,
  CssBaseline,
  Grid,
  Link,
  Card as MuiCard,
  Typography,
  Divider as MuiDivider,
  Stack,
} from "@mui/material";
import { useAsyncMutation, useAsyncQuery, useLog, useTheme } from "src/hooks";
import { useNavigate, useParams } from "react-router-dom";
import GlobalStyle from "src/components/GlobalStyle";
import { Box, spacing } from "@mui/system";
import { Helmet } from "react-helmet-async";
import { FormButton, FormTextArea } from "src/components/formControls";
import { THEMES } from "src/constants";
import { Check, Close } from "@mui/icons-material";
import { convertUTCDateToLocalDate } from "src/utils/utils";
import DialogMessagePopup from "src/components/DialogMessagePopup";
import RequestTransferTechnicianService from "src/services/RequestTransferTechnicianService";
import { IRequestTransfer } from "src/ts/interfaces/requestTransfer";
const Card = styled(MuiCard)(spacing);
const Root = styled.div`
  max-width: 70%;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  display: flex;
  min-height: 100%;
  flex-direction: column;
`;

function TechnicianTransferRequest() {
  const { theme } = useTheme();
  const { log } = useLog();
  const Divider = styled(MuiDivider)(spacing);
  const navigate = useNavigate();
  let { code } = useParams<{ code: string }>();
  const [confirmModal, setConfirmModal] = useState(false);
  const [rejectModal, setRejectModal] = useState(false);
  const [comments, setComments] = useState("");

  const { execute: executeLoad, data } = useAsyncQuery<IRequestTransfer>(
    RequestTransferTechnicianService.getTransferInfo
  );

  const { execute, isSubmitting } = useAsyncMutation(
    RequestTransferTechnicianService.putDecisionTransfer,
    {
      hideSuccessfulMessage: true,
      onSuccess: (result, request) => {
        setConfirmModal(false);
        setRejectModal(false);
        navigate("/auth/sign-in");
        log.success(`The transfer was ${request[1]}ed`);
      },
    }
  );

  useEffect(() => {
    const getData = async () => {
      await executeLoad(code);
    };

    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleConfirm = async (status: string) => {
    await execute(code, status, comments);
  };

  return (
    <Root>
      <CssBaseline />
      <GlobalStyle />
      <Grid container spacing={2} pt={10}>
        <Grid item xs={2}></Grid>
        <Grid item xs={8}>
          <Card mb={1}>
            <CardContent>
              <Helmet title="Confirm" />
              <Link href="https://www.auditmaster.pro/" target="_blank">
                <Box
                  component="img"
                  alt="Logo AMP"
                  src={
                    THEMES.DARK === theme
                      ? "/static/img/AMPWebLogoVectors.svg"
                      : "/static/img/AMPWebLogoVectorsLight.svg"
                  }
                  sx={{
                    marginLeft: "25%",
                    marginRight: "25%",
                    width: "50%",
                    marginBottom: "20px",
                  }}
                />
              </Link>
              <Divider mb={4} />
              {data?.requestStatus !== 1 && (
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Typography variant="h3" pb={2} textAlign={"center"}>
                      This request code is not longer valid
                    </Typography>
                  </Grid>
                </Grid>
              )}
              {data?.requestStatus === 1 && (
                <Grid container>
                  <Grid item xs={12}>
                    <Typography variant="h3" pb={4} textAlign={"center"}>
                      Technician Transfer Request
                    </Typography>
                  </Grid>
                  <Grid container pb={2}>
                    <Grid item xs={3}>
                      <Typography
                        sx={{ fontWeight: "bold" }}
                        textAlign={"right"}
                      >
                        Technician/Auditor:
                      </Typography>
                    </Grid>
                    <Grid item xs={9} pl={2}>
                      <Typography>
                        {data?.technicianUser?.name}{" "}
                        {data?.technicianUser?.lastName}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container pb={2}>
                    <Grid item xs={3}>
                      <Typography
                        sx={{ fontWeight: "bold" }}
                        textAlign={"right"}
                      >
                        Certification Type:
                      </Typography>
                    </Grid>
                    <Grid item xs={9} pl={2}>
                      {data?.techniciansAssignementsRole &&
                        data?.techniciansAssignementsRole.map(
                          (row, indexRow) => {
                            return (
                              <Typography>
                                {row.certificationId === 3 && (
                                  <Typography color={"primary"}>
                                    Testing & Balancing Technician{" "}
                                    {row.status === false
                                      ? "(In progress)"
                                      : ""}
                                  </Typography>
                                )}
                                {row.certificationId === 6 && (
                                  <Typography color={"green"}>
                                    Energy Auditor / Infectious Building Control{" "}
                                    {row.status === false
                                      ? "(In progress)"
                                      : ""}
                                  </Typography>
                                )}
                              </Typography>
                            );
                          }
                        )}
                    </Grid>
                  </Grid>
                  <Grid container pb={2}>
                    <Grid item xs={3}>
                      <Typography
                        sx={{ fontWeight: "bold" }}
                        textAlign={"right"}
                      >
                        Requested by:
                      </Typography>
                    </Grid>
                    <Grid item xs={9}>
                      <Typography pl={2}>
                        {data?.createdBy?.name} {data?.createdBy?.lastName} (
                        {data?.createdBy?.company?.getFullAddress})
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container pb={2}>
                    <Grid item xs={3}>
                      <Typography
                        sx={{ fontWeight: "bold" }}
                        textAlign={"right"}
                      >
                        Requested on:
                      </Typography>
                    </Grid>
                    <Grid item xs={9} pl={2}>
                      <Typography>
                        {convertUTCDateToLocalDate(
                          data?.createdDate.toString()
                        )?.format("MM/DD/YYYY h:mma")}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container pb={2}>
                    <Grid item xs={3}>
                      <Typography
                        sx={{ fontWeight: "bold" }}
                        textAlign={"right"}
                      >
                        Comments:
                      </Typography>
                    </Grid>
                    <Grid item xs={9} pl={2}>
                      <Typography display={"inline"}>
                        {data?.comments}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} textAlign={"center"} spacing={2}>
                    <Divider mb={4} mt={3} />
                    <Stack
                      direction="row"
                      spacing={2}
                      justifyContent="center"
                      alignItems="center"
                    >
                      <FormButton
                        onClick={() => {
                          setComments("");
                          setRejectModal(true);
                        }}
                        isSubmitting={isSubmitting}
                        variant="outlined"
                        text="Reject"
                        size="small"
                        startIcon={<Close />}
                      ></FormButton>
                      <FormButton
                        onClick={() => {
                          setComments("");
                          setConfirmModal(true);
                        }}
                        isSubmitting={isSubmitting}
                        text="Accept"
                        size="small"
                        startIcon={<Check />}
                      ></FormButton>
                    </Stack>
                  </Grid>
                </Grid>
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <DialogMessagePopup
        title={"Technician Transfer Request"}
        text={
          <Typography>
            <Typography>
              Are you certain you want to accept this request?
            </Typography>
          </Typography>
        }
        showPopup={confirmModal}
        setShowPopup={setConfirmModal}
        isSubmitting={isSubmitting}
        onSave={() => {
          handleConfirm("accept");
        }}
        disableClickOutside={true}
      ></DialogMessagePopup>

      <DialogMessagePopup
        title={"Technician Transfer Request"}
        text={
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Typography>
                Are you certain you want to reject this request?
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <FormTextArea
                name={"comments"}
                label={"Comments"}
                value={comments}
                onChange={(e: any) => {
                  setComments(e.target.value);
                }}
              ></FormTextArea>
            </Grid>
          </Grid>
        }
        showPopup={rejectModal}
        setShowPopup={setRejectModal}
        isSubmitting={isSubmitting}
        onSave={() => {
          handleConfirm("reject");
        }}
        disabled={!comments}
      ></DialogMessagePopup>
    </Root>
  );
}
export default TechnicianTransferRequest;
