import React, { useState } from "react";
import EnhancedTable from "../components/enhancedTable/EnhancedTable";
import { ColumnType } from "../types/enhancedTable";
import useLoading from "./useLoading";

interface TableOptions {
  cellCheckBox?: boolean;
  rowsPerPageOptions?: number[];
  defaultOrderBy?: string;
  defaultOrder?: "desc" | "asc";
  hideOverFlowX?: boolean;
}

export function useTable<T extends { id: number }>(
  columns: ColumnType[],
  options?: TableOptions
) {
  const { isLoading, startRequest, endRequest } = useLoading();
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page, setPage] = React.useState(0);
  const [orderBy, setOrderBy] = React.useState(
    options?.defaultOrderBy !== undefined ? options?.defaultOrderBy : "id"
  );
  const [order, setOrder] = React.useState<"desc" | "asc">(
    options?.defaultOrder !== undefined ? options?.defaultOrder : "asc"
  );
  const [dataSet, setDataSet] = useState<T[]>([]);
  const [refreshGrid, setRefreshGrid] = useState(false);
  const [count, setCount] = useState(0);

  const cellCheckBoxTable =
    options?.cellCheckBox === undefined ? false : options?.cellCheckBox;

  const Table = () => (
    <EnhancedTable<T>
      refreshGrid={refreshGrid}
      tableCellCheckBox={cellCheckBoxTable}
      columns={columns.filter((x) => x.hide !== true)}
      data={dataSet}
      rowsPerPage={rowsPerPage}
      setRowsPerPage={setRowsPerPage}
      page={page}
      setPage={setPage}
      orderBy={orderBy}
      setOrderBy={setOrderBy}
      order={order}
      setOrder={setOrder}
      count={count}
      setCount={setCount}
      showSkeleton={isLoading}
      rowsPerPageOptions={options?.rowsPerPageOptions}
      hideOverFlowX={options?.hideOverFlowX}
    />
  );

  return {
    Table,
    rowsPerPage,
    setRowsPerPage,
    page,
    setPage,
    orderBy,
    setOrderBy,
    order,
    setOrder,
    dataSet,
    setDataSet,
    refreshGrid,
    setRefreshGrid,
    count,
    setCount,
    startRequest,
    endRequest,
  };
}
