/* eslint-disable react-hooks/exhaustive-deps */
import {
  CardContent,
  Grid,
  Divider as MuiDivider,
  Card as MuiCard,
} from "@mui/material";

import { spacing } from "@mui/system";
import styled from "@emotion/styled";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAsyncQuery, useLog, useAuth } from "src/hooks";
import { FormText } from "src/components/formControls";
import useFormTyped from "src/hooks/useFormTyped";
import { IStaticReportDTO } from "src/ts/interfaces";
import { IProjectReport } from "src/ts/interfaces/project/projectDto";
import projectReportService from "src/services/study/projectReportService";
import staticReportService from "src/services/study/staticReportService";
import { steps } from "../const/consts";
import { FooterButtons } from "../../components/FooterButtons";
import HeaderStudyPage from "src/components/page/HeaderStudyPage";
import StudyWizard from "../../components/StudyWizard";

const initialValues: any = {
  projectName: "",
  system: "",
  equipmentLocation: "",
  conditionOfTest: "",
};

const Divider = styled(MuiDivider)(spacing);
const Card = styled(MuiCard)(spacing);

const StaticStep1 = () => {
  const { log } = useLog();
  const { user } = useAuth();
  const params = useParams<{ id: string; step: string }>();
  const id = parseInt(params?.id === undefined ? "0" : params?.id);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  let navigate = useNavigate();

  const validate: any = (fieldValues = values) => {
    let temp: Record<string, string> = { ...errors };

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange } =
    useFormTyped<IStaticReportDTO>(initialValues, false, validate);

  const activeStep = 0;

  const { execute: executeReport, data: dataReport } =
    useAsyncQuery<IProjectReport>(projectReportService.report);

  const { execute, isLoading } = useAsyncQuery<IStaticReportDTO>(
    staticReportService.getById,
    {
      onSuccess: (dataResult) => {
        const getData = async () => {
          setValues({ ...dataResult });
        };
        getData();
      },
    }
  );

  const disableStudy = () => {
    return (
      values?.isComplete ||
      (dataReport?.isInUse && dataReport?.isInUseById !== user?.userId) ||
      dataReport?.isPartOfProject === false
    );
  };

  useEffect(() => {
    const getData = async () => {
      try {
        await execute(id);
        await executeReport(id, "STCT");
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      }
    };

    if (id !== 0) getData();
  }, [id]);

  const saveHandler = async () => {
    if (!validate()) return;
    try {
      setIsUpdating(true);
      await staticReportService.update(id, values);
      log.success("Report was updated successfully");
      setIsUpdating(false);
    } catch (error) {}
  };

  const nexStepHandler = async () => {
    if (!validate()) return;
    try {
      if (!values?.isComplete) {
        setIsSubmitting(true);
        await staticReportService.update(id, values);
        log.success("Report was updated successfully");
        setIsSubmitting(false);
      }
      navigate(`/studies/static/step2/${id}`);
    } catch (error) {}
  };

  const row = 12;

  return (
    <>
      <HeaderStudyPage
        headerStudiesPage={{
          code: "STCT",
          system: values?.system,
          id: id,
        }}
        parentText="Project"
        parentLink={`/app/ProjectStudies/${values?.projectId}/${user?.companyId}`}
      />
      <Divider my={6} />

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card mb={6}>
            <CardContent>
              <StudyWizard
                steps={steps}
                activeStep={activeStep}
                projectId={dataReport?.projectId}
                reportId={dataReport?.reportId}
                reportTypeId={dataReport?.reportTypeId}
              />
              <Grid container spacing={5}>
                <Grid item xs={row}>
                  <FormText
                    name="projectName"
                    label="Project Name"
                    value={values.projectName}
                    onChange={handleInputChange}
                    error={errors.projectName}
                    showSkeleton={isLoading}
                    readOnlyText={disableStudy()}
                    disabled
                  />
                </Grid>
                <Grid item xs={row}>
                  <FormText
                    name="system"
                    label="System"
                    value={values.system}
                    onChange={handleInputChange}
                    error={errors.system}
                    showSkeleton={isLoading}
                    disabled={disableStudy()}
                    maxLength={50}
                  />
                </Grid>
                <Grid item xs={row}>
                  <FormText
                    name="equipmentLocation"
                    label="Equipment Location"
                    value={values.equipmentLocation}
                    onChange={handleInputChange}
                    error={errors.equipmentLocation}
                    showSkeleton={isLoading}
                    disabled={disableStudy()}
                  />
                </Grid>
                <Grid item xs={row}>
                  <FormText
                    name="conditionOfTest"
                    label="Area Served"
                    value={values.conditionOfTest}
                    onChange={handleInputChange}
                    error={errors.conditionOfTest}
                    showSkeleton={isLoading}
                    disabled={disableStudy()}
                  />
                </Grid>
              </Grid>
              <FooterButtons
                activeStep={activeStep}
                stepsCount={steps.length}
                projectId={values?.projectId}
                companyId={values.user?.companyId as number}
                isSaving={isUpdating}
                disabled={
                  isUpdating || isLoading || isSubmitting || values?.isComplete
                }
                reportName="Static Report"
                saveHandler={saveHandler}
                nextStepHandler={nexStepHandler}
                isComplete={values?.isComplete ?? true}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default StaticStep1;
