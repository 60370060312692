import { Tooltip, IconButton, Zoom, Skeleton, Grid } from "@mui/material";
import { InfoIcon } from "../icons";
import { useEffect, useState } from "react";
import { useAsyncQuery } from "src/hooks";
import tooltipService from "src/services/tooltipService";
import { ITooltip } from "src/ts/interfaces/tooltip";

interface Props {
  text?: React.ReactNode;
  titleName?: string;
  showSkeleton?: boolean;
  tooltipId?: number;
  padding?: string;
  onClick?: () => void;
}

export default function FormTooltip(props: Props) {
  const { execute, data, isLoading } = useAsyncQuery<ITooltip>(
    tooltipService.getByCode
  );
  const [contentIsHTML, setContentIsHTML] = useState(false);
  const [content, setContent] = useState("");

  const {
    execute: executeId,
    data: dataId,
    isLoading: isLoadingId,
  } = useAsyncQuery<ITooltip>(tooltipService.getById);

  useEffect(() => {
    const getData = async () => {
      execute(props.titleName);
    };
    if (props.titleName) getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.titleName]);

  useEffect(() => {
    const getData = async () => {
      executeId(props.tooltipId);
    };
    if (props.tooltipId) getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.tooltipId]);

  useEffect(() => {
    const text = (props.text ?? "") as string;
    const content = data?.content || dataId?.content || text;
    if (content.constructor === String) {
      setContentIsHTML(
        content.startsWith("<") ||
          content.includes("</") ||
          content.includes("/>")
      );
    }
    setContent(content);
  }, [data?.content, dataId?.content, props.text]);

  return (
    <>
      {props.showSkeleton || isLoading || isLoadingId ? (
        <Grid display={"inline-block"}>
          <Skeleton height={50} width={40} />
        </Grid>
      ) : (
        <Tooltip
          tabIndex={-1}
          TransitionComponent={Zoom}
          title={
            contentIsHTML ? (
              <div dangerouslySetInnerHTML={{ __html: content }}></div>
            ) : (
              content
            )
          }
          onClick={props.onClick}
        >
          <span>
            <IconButton
              tabIndex={-1}
              sx={{ padding: props.padding }}
              aria-label=""
              size="large"
            >
              <InfoIcon />
            </IconButton>
          </span>
        </Tooltip>
      )}
    </>
  );
}
