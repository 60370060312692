import React, { useEffect } from "react";
import { Grid, Stack } from "@mui/material";
import { useForm, useLog } from "src/hooks";
import { Form } from "src/hooks/useForm";
import {
  FormCancelButton,
  FormSaveButton,
  FormText,
} from "src/components/formControls";
import Popup from "src/components/Popup";
import { Validator } from "src/ts/types/Validator";
import { Permission as PermissionTypes } from "src/ts/enums";
import { usePermissions } from "src/hooks";
import companyAssetsTypeService from "src/services/catalogs/companyAssetTypeService";
import { ICompanyAssetsType } from "src/ts/interfaces/catalogs/companyAssetsType";
import FormTextEditor from "src/components/formControls/FormTextEditor";
import { draftToHtmlService } from "src/services";

interface CompanyAssetsTypeFormProps {
  selectedValue: ICompanyAssetsType;
  isDialogOpen: boolean;
  setIsDialogOpen: (val: boolean) => void;
  onUpdate: () => void;
}

const initialValues: ICompanyAssetsType = {
  id: 0,
  name: "",
  code: "",
  title: "",
  recommended: "",
  recommendedHTML: "",
  orderPosition: 0,
  statusId: 0,
  recommendedNvo: "",
};

const CompanyAssetsTypeForm = (props: CompanyAssetsTypeFormProps) => {
  const { selectedValue, isDialogOpen, setIsDialogOpen, onUpdate } = props;
  const { log } = useLog();
  const validate = (fieldValues = values) => {
    let temp: Record<string, string> = { ...errors };

    temp.title = new Validator(fieldValues, "title")
      .isRequired("The title is required")
      .toString();

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues, false, validate);

  useEffect(() => {
    if (isDialogOpen) {
      setValues(selectedValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDialogOpen]);

  const handleClose = () => {
    resetForm();
    setIsDialogOpen(false);
  };

  const handleUpdateTextEditor = (e: any) => {
    if (values.id !== 0) {
      values.recommendedNvo = e.target.value;
      setValues(values);
    }
  };

  const { fullAccess, readOnly } = usePermissions(PermissionTypes.Catalogs);

  const handleSave = async () => {
    if (!validate()) return;

    try {
      values.recommended = values.recommendedNvo;
      values.recommendedHTML = draftToHtmlService.convertToHTML(
        values.recommended
      );
      await companyAssetsTypeService.update(values);
      log.success("The company asset type was updated succesfully");
    } catch (error: any) {
      log.error(error?.message?.exceptionMessage ?? "Something went wrong");
    }
    resetForm();
    setIsDialogOpen(false);
    onUpdate();
  };

  const row = 12;
  return (
    <>
      <Popup
        openPopup={isDialogOpen}
        setOpenPopup={setIsDialogOpen}
        title={values.id === 0 ? "New" : "Edit"}
        onClose={() => setIsDialogOpen(false)}
      >
        <Form>
          <Grid container spacing={5}>
            <Grid item xs={row}>
              <FormText
                name="name"
                label="Name"
                value={values.name}
                showSkeleton={false}
                disabled={true}
              />
            </Grid>
            <Grid item xs={row}>
              <FormText
                name="code"
                label="Code"
                value={values.code}
                onChange={handleInputChange}
                showSkeleton={false}
                disabled={true}
              />
            </Grid>
            <Grid item xs={row}>
              <FormText
                name="title"
                label="Title"
                value={values.title}
                onChange={handleInputChange}
                error={errors.title}
                showSkeleton={false}
                disabled={readOnly}
              />
            </Grid>
            <Grid item xs={row}>
              <FormTextEditor
                value={values.recommended ?? ""}
                onChange={handleUpdateTextEditor}
                name={"recommended"}
                title={"Recommended"}
                placeholder={"Recommended text"}
              ></FormTextEditor>
            </Grid>
          </Grid>
        </Form>
        <>
          <Stack direction="row" spacing={2}>
            <FormCancelButton onClick={handleClose} isSubmitting={false} />
            {fullAccess && (
              <FormSaveButton onClick={handleSave} isSubmitting={false} />
            )}
          </Stack>
        </>
      </Popup>
    </>
  );
};

export default CompanyAssetsTypeForm;
