import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import {
  CardContent,
  CssBaseline,
  Grid,
  Link,
  Card as MuiCard,
  Typography,
  Divider as MuiDivider,
  Stack,
} from "@mui/material";
import { useAsyncMutation, useAsyncQuery, useLog, useTheme } from "src/hooks";
import { useNavigate, useParams } from "react-router-dom";
import GlobalStyle from "src/components/GlobalStyle";
import { Box, spacing } from "@mui/system";
import { Helmet } from "react-helmet-async";
import {
  FormButton,
  FormCancelButton,
  FormTextArea,
} from "src/components/formControls";
import {
  ASSET_TILE,
  ASSETS_STATUS,
  AssetsConstant,
  THEMES,
} from "src/constants";
import { IAssetTransfer } from "src/ts/interfaces/companyAssets";
import assetTransferService from "src/services/assetTransferService";
import { Check, Close } from "@mui/icons-material";
import Popup from "src/components/Popup";
const Card = styled(MuiCard)(spacing);
const Root = styled.div`
  max-width: 70%;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  display: flex;
  min-height: 100%;
  flex-direction: column;
`;

function RequestTransferCompanyAssets() {
  const { theme } = useTheme();
  const Divider = styled(MuiDivider)(spacing);
  const navigate = useNavigate();
  let { code } = useParams<{ code: string }>();
  const [confirmModal, setConfirmModal] = useState(false);
  const [isAccepted, setIsAccepted] = useState(false);
  const [comments, setComments] = useState("");

  const { execute: executeLoad, data } = useAsyncQuery<IAssetTransfer>(
    assetTransferService.getByCode
  );

  const { execute, isSubmitting } = useAsyncMutation(
    assetTransferService.updateByCode,
    {
      successfulMessage: `The transfer was ${
        isAccepted ? "accepted" : "rejected"
      }`,
      onSuccess: () => {
        setConfirmModal(false);
        navigate("/auth/sign-in");
      },
    }
  );

  useEffect(() => {
    const getData = async () => {
      await executeLoad(code);
    };

    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleConfirmModal = async (accepted: boolean) => {
    setComments("");
    setIsAccepted(accepted);
    setConfirmModal(true);
  };

  const handleConfirm = async () => {
    await execute(code, isAccepted ? "accept" : "reject", comments);
  };

  const acceptedOrRejected =
    data?.transferStatus === ASSETS_STATUS.Approved.id ||
    data?.transferStatus === ASSETS_STATUS.Rejected.id;

  const acceptedOrRejectedText =
    data?.transferStatus === ASSETS_STATUS.Approved.id
      ? ASSETS_STATUS.Approved.name
      : ASSETS_STATUS.Rejected.name;

  const acceptedOrRejectedAssetName =
    data?.type?.name === ASSET_TILE.EQUIPMENT
      ? `Test ${ASSET_TILE.EQUIPMENT}`
      : ASSET_TILE.VEHICLE;

  return (
    <Root>
      <CssBaseline />
      <GlobalStyle />
      <Grid container spacing={2} pt={10}>
        <Grid item xs={3}></Grid>
        <Grid item xs={6}>
          <Card mb={1}>
            <CardContent>
              <Helmet title="Confirm" />
              <Link href="https://www.auditmaster.pro/" target="_blank">
                <Box
                  component="img"
                  alt="Logo AMP"
                  src={
                    THEMES.DARK === theme
                      ? "/static/img/AMPWebLogoVectors.svg"
                      : "/static/img/AMPWebLogoVectorsLight.svg"
                  }
                  sx={{
                    marginLeft: "25%",
                    marginRight: "25%",
                    width: "50%",
                    marginBottom: "20px",
                  }}
                />
              </Link>
              <Divider mb={4} />
              {acceptedOrRejected && (
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Typography variant="h4" pb={2} textAlign={"center"}>
                      {` This ${acceptedOrRejectedAssetName} was already ${acceptedOrRejectedText}.`}
                    </Typography>
                  </Grid>
                </Grid>
              )}
              {!acceptedOrRejected && (
                <>
                  {" "}
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Typography variant="h3" pb={2} textAlign={"center"}>
                        {` ${data?.type?.name} Transfer Request`}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography>
                        {data?.type?.code === AssetsConstant.EQUIPMENT && (
                          <>
                            {" "}
                            <Typography display={"inline"} variant="subtitle2">
                              Name:{" "}
                            </Typography>
                            <Typography display={"inline"}>
                              {data?.name}
                            </Typography>{" "}
                          </>
                        )}
                        {data?.type?.code === AssetsConstant.VEHICLE && (
                          <>
                            {" "}
                            <Typography display={"inline"} variant="subtitle2">
                              Make:{" "}
                            </Typography>
                            <Typography display={"inline"}>
                              {data?.make}
                            </Typography>{" "}
                          </>
                        )}
                      </Typography>
                    </Grid>
                    {data?.type?.code === AssetsConstant.EQUIPMENT && (
                      <>
                        {" "}
                        <Grid item xs={12}>
                          <Typography>
                            <Typography display={"inline"} variant="subtitle2">
                              Serial Number:{" "}
                            </Typography>
                            <Typography display={"inline"}>
                              {data?.serialNumber}
                            </Typography>
                          </Typography>
                        </Grid>{" "}
                      </>
                    )}
                    <Grid item xs={12}>
                      <Typography>
                        <>
                          {" "}
                          <Typography display={"inline"} variant="subtitle2">
                            Model:{" "}
                          </Typography>
                          <Typography display={"inline"}>
                            {data?.model}
                          </Typography>{" "}
                        </>
                      </Typography>
                    </Grid>
                    {data?.type?.code === AssetsConstant.VEHICLE && (
                      <Grid item xs={12}>
                        <Typography>
                          <Typography display={"inline"} variant="subtitle2">
                            Year:{" "}
                          </Typography>
                          <Typography display={"inline"}>
                            {data?.year}
                          </Typography>
                        </Typography>
                      </Grid>
                    )}
                    {data?.type?.code === AssetsConstant.VEHICLE && (
                      <Grid item xs={12}>
                        <Typography>
                          <Typography display={"inline"} variant="subtitle2">
                            License Plate:{" "}
                          </Typography>
                          <Typography display={"inline"}>
                            {data?.licensePlate}
                          </Typography>
                        </Typography>
                      </Grid>
                    )}
                    {data?.type?.code === AssetsConstant.VEHICLE && (
                      <Grid item xs={12}>
                        <Typography>
                          <Typography display={"inline"} variant="subtitle2">
                            Vehicle ID Number:{" "}
                          </Typography>
                          <Typography display={"inline"}>
                            {data?.vehicleIdNumber}
                          </Typography>
                        </Typography>
                      </Grid>
                    )}
                    {data?.comments && (
                      <Grid item xs={12}>
                        <Typography>
                          <Typography display={"inline"} variant="subtitle2">
                            Comments:{" "}
                          </Typography>
                          <Typography display={"inline"}>
                            {data?.comments}
                          </Typography>
                        </Typography>
                      </Grid>
                    )}

                    <Grid item xs={12} textAlign={"center"} spacing={2}>
                      <Divider mb={4} mt={3} />
                      <Stack
                        direction="row"
                        spacing={2}
                        justifyContent="center"
                        alignItems="center"
                      >
                        <FormButton
                          onClick={() => {
                            handleConfirmModal(false);
                          }}
                          isSubmitting={isSubmitting}
                          variant="outlined"
                          text="Reject"
                          size="small"
                          startIcon={<Close />}
                        ></FormButton>
                        <FormButton
                          onClick={() => {
                            handleConfirmModal(true);
                          }}
                          isSubmitting={isSubmitting}
                          text="Accept"
                          size="small"
                          startIcon={<Check />}
                        ></FormButton>
                      </Stack>
                    </Grid>
                  </Grid>{" "}
                </>
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Popup
        title={`${data?.type?.name} Transfer Request`}
        openPopup={confirmModal}
        setOpenPopup={setConfirmModal}
        onClose={() => {
          setConfirmModal(false);
        }}
      >
        <>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography>
                Are you certain you want to {isAccepted ? "accept" : "reject"}{" "}
                this request?
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <FormTextArea
                name={"comments"}
                label={"Comments"}
                value={comments}
                onChange={(e: any) => {
                  setComments(e.target.value);
                }}
              ></FormTextArea>
            </Grid>
          </Grid>
        </>
        <Stack
          direction="row"
          spacing={2}
          justifyContent="center"
          alignItems="center"
        >
          <FormCancelButton
            onClick={() => {
              setConfirmModal(false);
            }}
            isSubmitting={isSubmitting}
          ></FormCancelButton>
          <FormButton
            onClick={handleConfirm}
            isSubmitting={isSubmitting}
            text={isAccepted ? "Accept" : "Reject"}
            size="small"
            startIcon={<Check />}
            disabled={!comments}
          ></FormButton>
        </Stack>
      </Popup>
    </Root>
  );
}
export default RequestTransferCompanyAssets;
