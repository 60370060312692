import { Button, Stack, Typography } from "@mui/material";
import Popup from "../Popup";

interface IEnableDisableUpdatePopUpProps {
  showPopUp: boolean;
  setShowPopUp: (val: boolean) => void;
}

const UnionPopUp = ({
  showPopUp,
  setShowPopUp,
}: IEnableDisableUpdatePopUpProps) => {
  const handleClosePopUp = () => {
    setShowPopUp(false);
  };

  return (
    <Popup
      title="Information"
      openPopup={showPopUp}
      setOpenPopup={setShowPopUp}
      onClose={handleClosePopUp}
      size="sm"
    >
      <>
        <Typography>
          <b>
            The acronym SMWIA specifically represents the Sheet Metal Workers
            International Association, which pertains to union affiliation.
          </b>
        </Typography>
        <br />
        <Typography>
          <b>
            Additionally, the acronym SMART represents Sheet Metal, Air, Rail,
            and Transportation workers.
          </b>
        </Typography>
      </>
      <Stack direction="row" spacing={3}>
        <Button
          type="submit"
          variant="contained"
          color="error"
          onClick={handleClosePopUp}
        >
          Close
        </Button>
      </Stack>
    </Popup>
  );
};

export default UnionPopUp;
