import * as React from "react";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import { FormCancelButton } from "src/components/formControls";
import Popup from "src/components/Popup";
import { Stack } from "@mui/material";

interface ButtonProps {
  isSubmitting: boolean;
  projectId: number;
  companyId: number;
  reportName: string;
  fullWidth?: boolean | undefined;
  disabled?: boolean;
  isComplete: boolean;
}

const StudiesFormCancelButton = (props: ButtonProps) => {
  const {
    isSubmitting,
    projectId,
    companyId,
    reportName,
    disabled,
    isComplete,
  } = props;
  const [open, setOpen] = React.useState(false);

  let navigate = useNavigate();

  const handleClickOpen = () => {
    if (!isComplete) setOpen(true);
    else handleCancel();
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCancel = () => {
    navigate(`/app/ProjectStudies/${projectId}/${companyId}`);
  };

  return (
    <>
      <Popup
        openPopup={open}
        onClose={handleClose}
        setOpenPopup={setOpen}
        title={reportName}
      >
        <>
          <p>
            Unsaved changes will be lost. Are you sure you want to continue?
          </p>
        </>
        <Stack direction="row" spacing={3}>
          <Button color="secondary" variant="outlined" onClick={handleClose}>
            No
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={handleCancel}
            autoFocus
          >
            Yes
          </Button>
        </Stack>
      </Popup>
      <FormCancelButton
        onClick={handleClickOpen}
        isSubmitting={isSubmitting}
        disabled={isSubmitting || disabled}
      />
    </>
  );
};

export default StudiesFormCancelButton;
