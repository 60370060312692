import React, { useEffect, useMemo, useState } from "react";
import styled from "@emotion/styled";
import {
  Grid,
  Card as MuiCard,
  Divider as MuiDivider,
  CardContent,
  Stack,
  Typography,
  Box,
  Avatar,
  TextField as MuiTextField,
  Fab,
  IconButton,
} from "@mui/material";
import { spacing } from "@mui/system";
import {
  FormText,
  FormButton,
  FormRadioGroup,
  FormTextArea,
} from "src/components/formControls";

import HeaderPage from "src/components/page/HeaderPage";
import { AddIcon, AttachFileIcon, SendIcon } from "src/components/icons";
import { useParams } from "react-router-dom";
import {
  useAsyncMutation,
  useAsyncQuery,
  useAuth,
  useForm,
  useLog,
} from "src/hooks";
import disputeService from "src/services/disputeService";
import { IDisputeData, IDisputeMessage } from "src/ts/interfaces/dispute";
import {
  DISPUTECONSTANT,
  DISPUTEOPTION,
  ROLES,
  complaintDisputeOption,
} from "src/constants";
import AddDisputeInformationPopUp from "./components/AddDisputeInformationPopUp";
import { convertUTCDateToLocalDate } from "src/utils/utils";
import FileUtils from "src/utils/file";
import { deepOrange, deepPurple } from "@mui/material/colors";
import { GridActionButton } from "src/components/gridControls";
import { Check } from "@mui/icons-material";
import DialogMessagePopup from "src/components/DialogMessagePopup";
import { Validator } from "src/ts/types/Validator";

const ChatMain = styled(Grid)``;
const ChatMessages = styled.div`
  overflow-y: scroll;
  height: calc(100vh - 160px);
`;
const ChatMessage = styled.div<{ position: "left" | "right" }>`
  margin: 30px;
  text-align: ${(props) => props.position};
`;

const ChatMessageInner = styled.div`
  display: inline-block;
`;

const ChatMessageBubble = styled.div<{ highlighted: boolean }>`
  display: inline-block;
  margin-right: auto;
  text-align: center;
  min-width: 150px;
  max-width: 70%;
  max-height: 300px;
  min-height: 55px;
  background: ${(props) =>
    props.highlighted
      ? props.theme.palette.secondary.main
      : props.theme.palette.action.hover};
  color: ${(props) =>
    props.highlighted
      ? props.theme.palette.common.white
      : props.theme.palette.text.primary};
  border-radius: ${(props) =>
    props.highlighted ? "10px 10px 0 10px" : "10px 10px 10px 0"};
  padding: ${(props) => props.theme.spacing(2)};
  margin-bottom: ${(props) => props.theme.spacing(1)};
  ${(props) => props.theme.shadows[1]};
`;

const ChatMessageTime = styled(Typography)`
  opacity: 0.8;
`;

const ChatInput = styled(Grid)`
  min-height: 94px;
  padding: ${(props) => props.theme.spacing(5)};
`;

const TextField = styled(MuiTextField)(spacing);

const Card = styled(MuiCard)(spacing);
const Divider = styled(MuiDivider)(spacing);

export type DisputeParams = {
  projectId: string;
};

const initialValues = {
  message: "",
};

function ChatMessageComponent({
  message,
  creationDate,
  user,
  fileName,
  path,
  id,
  isBoma,
}: IDisputeMessage) {
  const { log } = useLog();

  const downloadDocument = async (id: number) => {
    try {
      const response = await disputeService.getShowImage(id ?? 0);

      FileUtils.downloadFile(response.data, fileName);
    } catch (error: any) {
      log.error(error?.message?.exceptionMessage ?? "Something went wrong");
    }
  };
  return (
    <>
      <ChatMessage position={user === DISPUTECONSTANT.ME ? "right" : "left"}>
        <ChatMessageInner>
          <Stack
            direction="row"
            spacing={2}
            justifyContent={
              user === DISPUTECONSTANT.ME ? "flex-end" : "flex-start"
            }
          >
            <Avatar
              sx={{
                bgcolor: isBoma ? deepOrange[500] : deepPurple[500],
              }}
            >
              {isBoma ? DISPUTECONSTANT.B : DISPUTECONSTANT.C}
            </Avatar>

            <ChatMessageBubble highlighted={isBoma}>
              {message !== "" && (
                <Typography variant="body2" textAlign={"left"}>
                  {message}
                </Typography>
              )}
              {message === null && (
                <>
                  <Stack>
                    <GridActionButton
                      type="picture"
                      onClick={() => {
                        downloadDocument(id ?? 0);
                      }}
                      tooltip="Click & Download"
                    />

                    {fileName}
                  </Stack>
                </>
              )}
            </ChatMessageBubble>
          </Stack>

          <ChatMessageTime
            variant="body2"
            textAlign={user === DISPUTECONSTANT.ME ? "right" : "left"}
          >
            <Typography variant="subtitle2">{user}</Typography>
            <Typography>
              {convertUTCDateToLocalDate(creationDate.toString() ?? "")?.format(
                "MM/DD/yyyy h:mma"
              )}
            </Typography>
          </ChatMessageTime>
        </ChatMessageInner>
      </ChatMessage>
    </>
  );
}

const Dispute = () => {
  let params = useParams<DisputeParams>();
  const projectId = parseInt(
    params?.projectId === undefined ? "0" : params?.projectId
  );
  const { log } = useLog();
  const [addDisputeInformationShowPopUp, setAddDisputeInformationShowPopUp] =
    useState(false);
  const [messeageOrder, setMesseageOrder] = useState<IDisputeMessage[]>();
  const [refresh, setRefresh] = useState(false);
  const [comments, setComments] = useState("");
  const [resolveModal, setResolveModal] = useState(false);

  const validate = (fieldValues = values) => {
    let temp: Record<string, string> = { ...errors };

    temp.message = new Validator(fieldValues, "message")
      .isRequired("The message is required")
      .maxLength(
        800,
        `Only 800 character are allowed (${values.message.length}).`
      )
      .toString();

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };
  const { values, handleInputChange, resetForm, errors, setErrors } = useForm(
    initialValues,
    false,
    []
  );

  const { user } = useAuth();
  const { execute: executeDispute, data: dataDispute } =
    useAsyncQuery<IDisputeData>(disputeService.getDispute);

  const { execute: executeMessage, data: dataMesseage } = useAsyncQuery<
    IDisputeMessage[]
  >(disputeService.getDisputeMessage);

  const { execute: executePostMessage } = useAsyncQuery(
    disputeService.postMesseage
  );

  const { execute: executePostImage } = useAsyncQuery(disputeService.postImage);
  const { execute: executeResolve, isSubmitting } = useAsyncMutation(
    disputeService.resolve,
    {
      onSuccess: async () => {
        setResolveModal(false);
        await executeDispute(projectId);
      },
      successfulMessage: "Dispute was resolved",
    }
  );

  useEffect(() => {
    executeDispute(projectId);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (dataDispute && dataDispute.id > 0) executeMessage(dataDispute.id);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataDispute]);

  useEffect(() => {
    if (dataMesseage && dataMesseage.length > 0) {
      const sortedData = dataMesseage.sort((a, b) => {
        return (
          new Date(b.creationDate).getTime() -
          new Date(a.creationDate).getTime()
        );
      });
      setMesseageOrder(sortedData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataMesseage]);

  useEffect(() => {
    executeDispute(projectId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh]);

  const handlerAddDispute = () => {
    setAddDisputeInformationShowPopUp(true);
  };

  const postMesseageHandler = async () => {
    if (!validate())
      return log.warning(
        "Only 800 characters are allowed for comments section."
      );
    executePostMessage({
      disputeId: dataDispute.id,
      message: values.message,
    });
    log.success("Message was added.");
    resetForm();
    setRefresh(!refresh);
  };

  const handleImageAccepted = async (file: File) => {
    executePostImage({
      content: file,
      documentType: "photo",
      disputeId: dataDispute.id,
    });
    log.success("Image was attached.");
    setRefresh(!refresh);
  };

  const onChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0] || null;
    if (file !== null) {
      handleImageAccepted(file);
    }
  };

  const handleResolve = () => {
    setComments("");
    setResolveModal(true);
  };

  const handleSaveResolve = async () => {
    await executeResolve(dataDispute.id, comments);
  };

  return (
    <>
      <HeaderPage
        title={`Dispute`}
        parentText={"Dashboard"}
        parentLink={`/`}
        actionSection={
          <>
            {dataDispute != null &&
              dataDispute.isOpen &&
              (user?.role === ROLES.Architect ||
                user?.role === ROLES.ArchitectAux) && (
                <FormButton
                  text={"Resolve Dispute"}
                  onClick={handleResolve}
                  startIcon={<Check></Check>}
                  size="medium"
                ></FormButton>
              )}
          </>
        }
        pageYoutube=""
      ></HeaderPage>
      <Divider my={6} />
      <Card mb={6}>
        <CardContent>
          {dataDispute && dataDispute.id === 0 && (
            <Grid container direction={"row"}>
              <Grid item xs={10} textAlign={"center"}>
                <Stack mt={2}>
                  No complaint has been initiated for this project.
                </Stack>
              </Grid>
              <Grid item xs={2}>
                {dataDispute.id === 0 && (
                  <FormButton
                    text="Add Dispute"
                    color="secondary"
                    endIcon={<AddIcon />}
                    onClick={() => {
                      handlerAddDispute();
                    }}
                    size="medium"
                  />
                )}
              </Grid>
            </Grid>
          )}
          {dataDispute && dataDispute.id > 0 && (
            <>
              <Grid container>
                <Grid item xs={1.1}>
                  <Typography>Project Name: </Typography>
                </Grid>
                <Grid item xs={10}>
                  <Typography> {dataDispute?.project?.name}</Typography>
                </Grid>
              </Grid>
              <Grid container mt={2}>
                <Grid item xs={1.1}>
                  <Typography>Project Code:</Typography>
                </Grid>
                <Grid item xs={10}>
                  <Typography>{dataDispute?.project?.code}</Typography>
                </Grid>
              </Grid>
              <Grid container mt={2}>
                <Grid item xs={1.1}>
                  <Typography ml={8.5}>Building:</Typography>
                </Grid>
                <Grid item xs={10}>
                  <Typography>{`${dataDispute?.project?.buildingName} - ${dataDispute?.project?.buildingAddress} `}</Typography>
                </Grid>
              </Grid>
              <Grid container>
                <Stack mt={3} ml={5.5}>
                  <FormRadioGroup
                    name="type"
                    label="Complaint"
                    value={dataDispute.type}
                    items={complaintDisputeOption}
                    disabled={!dataDispute?.isOpen}
                  />
                </Stack>
              </Grid>
              {dataDispute.type === Number(DISPUTEOPTION.OTHER) &&
                dataDispute.other !== null && (
                  <Grid container>
                    <Stack mt={3} ml={5.5}>
                      <FormText
                        name="other"
                        value={dataDispute.other}
                        label="Comments"
                        disabled={!dataDispute?.isOpen}
                      />
                    </Stack>
                  </Grid>
                )}
              <Grid container>
                <Grid item xs={12}>
                  <Box
                    my={4}
                    display="flex"
                    flexDirection="column"
                    p={2}
                    sx={{ border: "2px solid grey" }}
                  >
                    <Box mb={2}>
                      <Typography variant="h6" textAlign={"center"}>
                        Details
                      </Typography>
                    </Box>

                    <Box>
                      <Grid container>
                        <Grid item xs={2} textAlign={"right"} pr={1}>
                          <Typography variant="subtitle2">Reason:</Typography>
                        </Grid>
                        <Grid item xs={10}>
                          {dataDispute?.reason}
                        </Grid>
                        <Grid item xs={2} textAlign={"right"} pr={1}>
                          <Typography variant="subtitle2">Details:</Typography>
                        </Grid>
                        <Grid item xs={10}>
                          {dataDispute?.details}
                        </Grid>
                        <Grid item xs={2} textAlign={"right"} pr={1}>
                          <Typography variant="subtitle2">
                            Open Date:
                          </Typography>
                        </Grid>
                        <Grid item xs={10}>
                          {convertUTCDateToLocalDate(
                            dataDispute?.creationDate.toString()
                          )?.format("MM/DD/YYYY hh:mm A")}
                        </Grid>
                        <Grid item xs={2} textAlign={"right"} pr={1}>
                          <Typography variant="subtitle2">Status:</Typography>
                        </Grid>
                        <Grid item xs={10}>
                          {dataDispute?.isOpen ? "Open" : "Close"}
                        </Grid>
                        {!dataDispute?.isOpen && (
                          <>
                            <Grid item xs={2} textAlign={"right"} pr={1}>
                              <Typography variant="subtitle2">
                                Resolve Date:
                              </Typography>
                            </Grid>
                            <Grid item xs={10}>
                              {`${convertUTCDateToLocalDate(
                                dataDispute?.resolveDate.toString()
                              )?.format("MM/DD/YYYY hh:mm A")}`}
                            </Grid>
                          </>
                        )}
                        {!dataDispute?.isOpen && <Typography></Typography>}
                        {!dataDispute?.isOpen && (
                          <>
                            <Grid item xs={2} textAlign={"right"} pr={1}>
                              <Typography variant="subtitle2">
                                Resolve Comments:
                              </Typography>
                            </Grid>
                            <Grid item xs={10}>
                              {dataDispute?.resolveComments}
                            </Grid>
                          </>
                        )}
                      </Grid>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
              <Grid container mt={2}>
                <Grid item xs={12}>
                  <Typography variant="h6">Comments</Typography>
                </Grid>
                <Grid item xs={12}>
                  <ChatMain item xs={12}>
                    {dataDispute?.isOpen && (
                      <ChatInput container>
                        <Grid item style={{ flexGrow: 1, marginTop: "0" }}>
                          <FormTextArea
                            variant="outlined"
                            label="Type your message"
                            fullWidth={true}
                            value={values.message}
                            name="message"
                            onChange={handleInputChange}
                            error={errors.message}
                          />
                        </Grid>
                        <Grid item>
                          <Box ml={2}>
                            <Fab
                              onClick={postMesseageHandler}
                              color="primary"
                              aria-label="add"
                              size="medium"
                            >
                              <SendIcon />
                            </Fab>
                          </Box>
                        </Grid>
                        <Grid item>
                          <Box ml={2} mt={1.5}>
                            <IconButton
                              aria-label="upload picture"
                              component="label"
                            >
                              <input
                                hidden
                                accept="image/*"
                                type="file"
                                onChange={(e) => {
                                  onChangeInput(e);
                                }}
                              />
                              <AttachFileIcon />
                            </IconButton>
                          </Box>
                        </Grid>
                      </ChatInput>
                    )}
                    <Grid item xs={12} paddingBottom={2}>
                      <Stack
                        direction="row"
                        spacing={2}
                        justifyContent="center"
                        alignItems="center"
                      ></Stack>
                    </Grid>
                    <Divider />
                    <ChatMessages>
                      {(messeageOrder ?? []).map(
                        (item: IDisputeMessage, index: number) => (
                          <ChatMessageComponent
                            creationDate={item.creationDate}
                            message={item.message}
                            user={item.user}
                            fileName={item.fileName}
                            path={item.path}
                            id={item.id}
                            key={index}
                            isBoma={item.isBoma}
                          />
                        )
                      )}
                    </ChatMessages>
                  </ChatMain>
                </Grid>
              </Grid>
            </>
          )}
        </CardContent>
      </Card>
      {addDisputeInformationShowPopUp && (
        <AddDisputeInformationPopUp
          addDisputeInformationShowPopUp={addDisputeInformationShowPopUp}
          setAddDisputeInformationShowPopUp={setAddDisputeInformationShowPopUp}
          projectId={projectId}
          setRefresh={setRefresh}
          refresh={refresh}
        />
      )}
      <DialogMessagePopup
        title={"Resolve dispute"}
        text={
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Typography>
                Are you certain you want to resolve your dispute for this
                project?
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <FormTextArea
                name={"comments"}
                label={"Comments"}
                value={comments}
                onChange={(e: any) => {
                  setComments(e.target.value);
                }}
              ></FormTextArea>
            </Grid>
          </Grid>
        }
        showPopup={resolveModal}
        setShowPopup={setResolveModal}
        isSubmitting={isSubmitting}
        acceptTextButton="Yes, Resolve"
        onSave={handleSaveResolve}
      ></DialogMessagePopup>
    </>
  );
};

export default Dispute;
