import { createContext, ReactNode, useEffect, useReducer } from "react";
import { v4 as uuidv4 } from "uuid";

enum LoadingActionEnum {
  ApiRequest,
  ApiSuccess,
  ApiError,
  Clear,
}

interface LoadingAction {
  type: LoadingActionEnum;
  payload: string;
}

interface LoadingState {
  isLoading: boolean;
  count: number;
  requests: string[];
}

const initialState: LoadingState = {
  isLoading: false,
  count: 0,
  requests: [] as string[],
};

const loadingReducer = (
  state: LoadingState,
  action: LoadingAction
): LoadingState => {
  switch (action.type) {
    case LoadingActionEnum.ApiRequest:
      return {
        isLoading: true,
        count: state.requests.length === 0 ? 1 : state.requests.length,
        requests: [...state.requests, action.payload],
      };
    case LoadingActionEnum.ApiSuccess:
    case LoadingActionEnum.ApiError:
      const newArray = state.requests.filter((e) => e !== action.payload);

      return {
        isLoading: newArray.length > 0,
        count: newArray.length,
        requests: newArray,
      };
    case LoadingActionEnum.Clear:
      return {
        ...state,
        isLoading: false,
        count: 0,
        requests: [] as string[],
      };
    default:
      return state;
  }
};

interface ILoadingContextType {
  isLoading: boolean;
  count: number;
  requests: string[];
  startRequest: (id?: string) => string;
  endRequest: (id: string) => void;
}

const LoadingContext = createContext<ILoadingContextType | null>(null);

const LoadingProvider = ({ children }: { children: ReactNode }) => {
  const [state, dispatch] = useReducer(loadingReducer, initialState);

  useEffect(() => {
    initialize();
  }, []);

  const startRequest = (id?: string) => {
    const newId = id ?? uuidv4();
    dispatch({ type: LoadingActionEnum.ApiRequest, payload: newId });
    return newId;
  };

  const endRequest = (id: string) => {
    if (state.requests.length > 0) {
      setTimeout(
        () => dispatch({ type: LoadingActionEnum.ApiSuccess, payload: id }),
        100
      );
    } else {
      dispatch({ type: LoadingActionEnum.ApiSuccess, payload: id });
    }
  };

  const initialize = () => {
    dispatch({ type: LoadingActionEnum.Clear, payload: "" });
  };

  return (
    <LoadingContext.Provider value={{ ...state, startRequest, endRequest }}>
      {/* <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={state.isLoading}
        onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop> */}
      {children}
    </LoadingContext.Provider>
  );
};

export { LoadingContext, LoadingProvider };
