import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import {
  Grid,
  Divider as MuiDivider,
  Card as MuiCard,
  CardContent,
} from "@mui/material";
import { spacing } from "@mui/system";
import HeaderPage from "src/components/page/HeaderPage";
import ProjectForm, { ProjectParams } from "./components/ProjectForm";
import ProjectActions from "./components/ProjectActions";
import TimeCardPopup from "src/components/timeCardPopup/TimeCardPopup";
import { useParams } from "react-router-dom";
import moment from "moment";
import getProjectSingleService from "src/services/project/getProjectSingleService";
import { ProjectWithBogus } from "src/ts/interfaces/project/projectDto";
import { CompanyService } from "src/services";
import { useAsyncQuery, useAuth } from "src/hooks";
import {
  ICompany,
  ICompanyContractors,
  IProjectHistoryStatusComments,
} from "src/ts/interfaces";
import CompanyInformation from "./components/CompanyInformation";
import ContractorDetail from "./components/ContractorDetail";
import WithRole from "src/components/guards/WithRole";
import ProjectHistoryStatusComments from "./components/ProjectHistoryStatusComments";
import projectCurrentService from "src/services/project/projectCurrentService";
import EstimatedTimePopup from "src/components/estimatedTimePopup/EstimatedTimePopup";
import { ROLES } from "src/constants";
import TandbalanceEnergyPopup from "../Components/TandbalanceEnergyPopup";
import projectContestService from "src/services/projectContestService";
import projectHistoryStatusCommentsService from "src/services/project/projectHistoryStatusCommentsService";

const Divider = styled(MuiDivider)(spacing);
const Card = styled(MuiCard)(spacing);

const initialValues: ProjectWithBogus = {
  bogusStartDate: null,
  bogusStartDateTime: "8:00 AM",
  bogusActualStartDate: null,
  bogusActualStartDateTime: "8:00 AM",
  name: "",
  estimatedTimeDurationUnit: 0,
  startDate: null,
  actualStartDate: null,
  estimatedTimeDuration: 0,
  buildingId: -1,
  measurementSystem: 1,
  suppliedPowerGridFrequency: null,
  auditAreaSqFt: null,
  auditAreaCuFt: null,
  isParkingAreaPartOfThisTest: false,
  requiredComprehensiveEnergyTest: false,
  printDrawingsAvailableOnSite: false,
  printAddendumDateUsedForProposal: null,
  specificationsProvided: false,
  addendumBidForThisProject: 0,
  liftWorkRequired: false,
  liftWorkOnSite: false,
  liftProvidedByOthers: false,
  roofAccessHatchAvailable: 0,
  laddersRequired: false,
  id: 0,
  equipmentCalibrationReportRequired: false,
  visibleToAllContractors: false,
  publishInAuditMasterPro: false,
  tolerancesWereSaved: false,
  sendProposalThroughAMP: false,
  projectStatusId: 0,
  isTemplate: false,
  templateWasUsed: false,
  currentProject: false,
  activeProject: false,
  disabled: false,
  size: 0,
  completedBySuperAdmin: false,
  wasOpenedByContractor: false,
  projectAgreementsAccepted: false,
  isInfectiousControlProject: false,
  computerEstimatedTimeRemaining: 0,
  techniciansEstimatedTimeRemainingIsDays: false,
  completionPaymentByOwner: false,
  elevation: 0,
  gridView: false,
  isOpenDispute: false,
  ductLeakageTesting: false,
  vibrationTestingForMotors: false,
  soundTestingLocations: false,
  hydronicPipeleakage: false,
  ufadPlenumLeakage: false,
  otherTesting: false,
  buildingEnergyEfficiency: false,
  airTestingBalancing: false,
  hydronicWaterBalancing: false,
  hvacEnergyPerformance: false,
  commissioning: false,
  proposedStartDateTimeUnknown: false,
  auditAreaUnknown: false,
  auditAreaUnknownCuFt: false,
  projectBomaTypeId: null,
  projectBomaType: null,
  projectDescription: "",
  projectEstimatedTime: [],
};

const Project = () => {
  let params = useParams<ProjectParams>();

  const projectId = parseInt(
    params?.projectId === undefined ? "0" : params?.projectId
  );

  const companyId = parseInt(
    params?.companyId === undefined ? "0" : params?.companyId
  );
  const { user } = useAuth();
  const [project, setProject] = useState<ProjectWithBogus>(initialValues);
  const [winnerContestProject, setWinnerContestProject] =
    useState<ICompanyContractors>();
  const [showTimeCardPopup, setShowTimeCardPopup] = useState(false);
  const [validateEstimatedTime, setValidateEstimatedTime] = useState(0);
  const [showEstimatedTimePopup, setShowEstimatedTimePopup] = useState(false);
  const [isCurrentProject, setIsCurrentProject] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [tandbalanceEnergyModal, setTandbalanceEnergyModal] = useState(false);

  const { execute: getCommentStatus, data: commentStatus } = useAsyncQuery<
    IProjectHistoryStatusComments[]
  >(projectHistoryStatusCommentsService.getProjectHistoryStatusComments);
  useEffect(() => {
    const getData = async () => {
      setIsLoading(true);
      const currentProjectResponse =
        await projectCurrentService.GetCurrentProjectByUser();
      if (
        currentProjectResponse.data &&
        currentProjectResponse.data.projectId === projectId
      ) {
        setIsCurrentProject(true);
      }

      const response = await getProjectSingleService.getById(projectId);
      if (response.data === null) return;

      const actualDateTime = moment(response.data.actualStartDate);
      const bogusActualStartDate =
        response.data.actualStartDate !== null
          ? actualDateTime.format("YYYY/MM/DD")
          : null;
      const bogusActualStartDateTime =
        response.data.actualStartDate !== null
          ? actualDateTime.format("HH:mm")
          : "8:00 AM";

      const startDateTime = response.data.startDate
        ? moment(response.data.startDate)
        : null;
      const bogusStartDate = response.data.startDate
        ? startDateTime?.format("YYYY/MM/DD")
        : null;
      const bogusStartDateTime = response.data.startDate
        ? startDateTime?.format("HH:mm")
        : "8:00 AM";

      //Safety glasses, Hard Hat, Steel toe boots  values.safetyEquipments?.map((value) => value.id)

      setProject({
        ...response.data,
        bogusStartDate,
        bogusStartDateTime,
        bogusActualStartDate,
        bogusActualStartDateTime,
        bogusLadderAFrames: response?.data?.ladderAFrames ?? [],
        bogusLadderExtensions: response?.data?.ladderExtensions ?? [],
        bogusSafetyEquipments: response?.data?.safetyEquipments ?? [],
      });

      if (user?.role === ROLES.Architect) {
        const responseContest =
          await projectContestService.getWinnerContestByProject(projectId);
        setWinnerContestProject(responseContest.data);
      }

      setIsLoading(false);
      if (
        response.data.isInfectiousControlProject &&
        (user?.role === ROLES.Auditor || user?.role === ROLES.TemporalAuditor)
      )
        setTandbalanceEnergyModal(true);
    };
    const commentStatusData = async () => {
      await getCommentStatus(projectId);
    };

    if (projectId > 0) {
      getData();
      commentStatusData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId]);

  const timeCardButtonClickHandler = () => {
    setShowTimeCardPopup(true);
  };

  const estimatedTimeButtonClickHandler = () => {
    setShowEstimatedTimePopup(true);
  };

  const { execute: getCompanyById, data: company } = useAsyncQuery<ICompany>(
    CompanyService.getById
  );

  useEffect(() => {
    const getCompany = async () => {
      await getCompanyById(companyId);
    };

    if (companyId > 0) getCompany();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyId]);

  return (
    <>
      <HeaderPage
        title={projectId === 0 ? "Create Project" : "Project"}
        parentText="Dashboard"
        parentLink="/"
        infoSection={<CompanyInformation company={company} />}
        actionSection={
          <ProjectActions
            timeCardButtonClick={timeCardButtonClickHandler}
            showTimeCardButton={project.projectStatusId > 0}
            estimatedTimeButtonClick={estimatedTimeButtonClickHandler}
          />
        }
      />
      <Divider my={6} />

      <Grid container spacing={2}>
        <Grid item xs={commentStatus && commentStatus.length > 0 ? 9 : 12}>
          <Card mb={6}>
            <CardContent>
              {/* {projectId === 0 && (
                <Typography gutterBottom variant="h5" component="div">
                  Create Project
                </Typography>
              )} */}

              <ProjectForm project={project} isLoading={isLoading} />
            </CardContent>
          </Card>
        </Grid>
        {commentStatus && commentStatus.length > 0 && (
          <Grid item xs={3}>
            <Card mb={6}>
              <CardContent>
                <ProjectHistoryStatusComments
                  projectId={projectId}
                  project={project}
                />
              </CardContent>
            </Card>
          </Grid>
        )}
        {(project?.companyId !== 0 || winnerContestProject) && (
          <WithRole
            value={["Architect", "SysAdmin", "ArchitectAux"]}
            action="show"
          >
            <Grid item xs={12}>
              <Card mb={6}>
                <CardContent>
                  <ContractorDetail
                    companyId={project?.companyId ?? 0}
                    contractorInfo={winnerContestProject}
                  />
                </CardContent>
              </Card>
            </Grid>
          </WithRole>
        )}
      </Grid>
      {project && project.id > 0 && (
        <>
          <TimeCardPopup
            project={project}
            isDialogOpen={showTimeCardPopup}
            setIsDialogOpen={setShowTimeCardPopup}
            isCurrentProject={isCurrentProject}
            validateEstimatedTime={() => {
              setValidateEstimatedTime(validateEstimatedTime + 1);
            }}
          />
          <EstimatedTimePopup
            project={project}
            isDialogOpen={showEstimatedTimePopup}
            setIsDialogOpen={setShowEstimatedTimePopup}
            isCurrentProject={isCurrentProject}
            validateEstimatedTime={validateEstimatedTime}
          ></EstimatedTimePopup>
        </>
      )}
      <TandbalanceEnergyPopup
        showPopUp={tandbalanceEnergyModal}
        setShowPopUp={setTandbalanceEnergyModal}
      ></TandbalanceEnergyPopup>
    </>
  );
};

export default Project;
