import React, { useEffect, useState } from "react";
import { Stack, Typography, Grid, Tabs, Tab, Divider } from "@mui/material";
import Popup from "src/components/Popup";
import { FormButton, FormTextArea } from "src/components/formControls";
import { IAssementHistory, IAssets } from "src/ts/interfaces/companyAssets";
import { useForm, useLog, usePermissions } from "src/hooks";
import AssesmentHistoryGrid from "../components/AssesmentHistoryGrid";
import useAsyncMutation from "src/hooks/useAsyncMutation";
import assetAssessmentService from "src/services/assetAssessmentService";
import MatchWordConfirmModal from "src/components/modals/MatchWordConfirmModal";
import { Validator } from "src/ts/types/Validator";
import AssignmentsGrid from "./AssignmentsGrid";
import AssetProjects from "./AssetPRojects";
import { ASSETSTATUS, ASSET_TILE, AssetsConstant } from "src/constants";
import { Permission as PermissionTypes } from "src/ts/enums";
interface Props {
  showAssessmentHistory: boolean;
  setShowAssessmentHistory: (val: boolean) => void;
  currentRow: IAssets;
  asset: string;
  permissionRole: boolean;
  assetStatus: string;
}
const initialValues = {
  description: "",
};

const AssesmentReport = ({
  showAssessmentHistory,
  setShowAssessmentHistory,
  currentRow,
  asset,
  permissionRole,
  assetStatus,
}: Props) => {
  const [selectedTab, setSelectedTab] = React.useState(0);
  const [refresh, setRefresh] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [rowEdit, setRowEdit] = useState<IAssementHistory>();
  const [deleteModal, setDeleteModal] = useState(false);
  const { fullAccess: fullAccessEQIP } = usePermissions(
    PermissionTypes.Equipment
  );
  const { fullAccess: fullAccessVEHI } = usePermissions(
    PermissionTypes.Vehicles
  );
  const { log } = useLog();

  const validate = (fieldValues = values) => {
    let temp: Record<string, string> = { ...errors };

    temp.description = new Validator(fieldValues, "note")
      .maxLength(500, "Only 500 character are allowed")
      .toString();
    temp.description = new Validator(fieldValues, "description")
      .isRequired(`The description is required.`)
      .toString();

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, handleInputChange, resetForm, errors, setErrors } =
    useForm(initialValues, false, validate);

  useEffect(() => {
    const getData = async () => {};

    getData();
  }, []);

  useEffect(() => {
    const getDetail = () => {
      setValues({ ...values, description: rowEdit?.description });
    };

    if (editMode) getDetail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editMode]);

  const { execute: addDescription } = useAsyncMutation(
    assetAssessmentService.postDescription,
    { successfulMessage: " The Assessment has been added." }
  );

  const { execute: updateDescription } = useAsyncMutation(
    assetAssessmentService.updateDescription,
    { successfulMessage: "The Assessment has been Updated." }
  );

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  const VehicleTransfer = () => (
    <>
      <Stack direction="row" spacing={5} mb={3}>
        <Grid container direction="row">
          <Grid item xs={4} textAlign="right">
            <Typography>
              <b>Make</b>
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography ml={5}>{currentRow?.vehicle?.make}</Typography>
          </Grid>
        </Grid>
      </Stack>
      <Stack direction="row" spacing={5} mb={3}>
        <Grid container direction="row">
          <Grid item xs={4} textAlign="right">
            <Typography>
              <b>Model</b>
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography ml={5}>{currentRow?.vehicle?.make}</Typography>
          </Grid>
        </Grid>
      </Stack>
      <Stack direction="row" spacing={5} mb={3}>
        <Grid container direction="row">
          <Grid item xs={4} textAlign="right">
            <Typography>
              <b>Year</b>
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography ml={5}>{currentRow?.vehicle?.year}</Typography>
          </Grid>
        </Grid>
      </Stack>
      <Stack direction="row" spacing={5} mb={3}>
        <Grid container direction="row">
          <Grid item xs={4} textAlign="right">
            <Typography>
              <b>Vehicle Identification Number</b>
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography ml={5}>
              {currentRow?.vehicle?.vehicleIdNumber}
            </Typography>
          </Grid>
        </Grid>
      </Stack>
      <Stack direction="row" spacing={5} mb={3}>
        <Grid container direction="row">
          <Grid item xs={4} textAlign="right">
            <Typography>
              <b>License Plate</b>
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography ml={5}>{currentRow?.vehicle?.licensePlate}</Typography>
          </Grid>
        </Grid>
      </Stack>
    </>
  );

  const EquipmentTransfer = () => (
    <>
      <Stack direction="row" spacing={5} mb={3}>
        <Grid container direction="row">
          <Grid item xs={4} textAlign="right">
            <Typography>
              <b>Equipment Name</b>
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography ml={5}>{currentRow?.equipment?.name}</Typography>
          </Grid>
        </Grid>
      </Stack>
      <Stack direction="row" spacing={5} mb={3}>
        <Grid container direction="row">
          <Grid item xs={4} textAlign="right">
            <Typography>
              <b>Serial Number</b>
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography ml={5}>
              {currentRow?.equipment?.serialNumber}
            </Typography>
          </Grid>
        </Grid>
      </Stack>
      <Stack direction="row" spacing={5} mb={3}>
        <Grid container direction="row">
          <Grid item xs={4} textAlign="right">
            <Typography>
              <b>Model Number</b>
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography ml={5}>{currentRow?.equipment?.model}</Typography>
          </Grid>
        </Grid>
      </Stack>
    </>
  );

  const AddDescription = () => {
    if (!validate()) return;
    addDescription({
      companyAssetsId: currentRow.id ?? 0,
      description: values.description,
    });
    setRefresh(!refresh);
    resetForm();
  };

  const updateDetail = () => {
    updateDescription({
      id: rowEdit?.id ?? 0,
      description: values.description,
    });
    setRefresh(!refresh);
    setEditMode(false);
    resetForm();
  };

  const deleteModalHandler = async () => {
    try {
      await assetAssessmentService.deleteDescription(
        rowEdit?.id?.toString() ?? "0"
      );
      log.success("The item was deleted");
      setRefresh(!refresh);
      setDeleteModal(false);
    } catch (error: any) {
      log.error(error?.message?.exceptionMessage ?? "Something went wrong");
    }
  };
  const assetTitle =
    asset === AssetsConstant.VEHICLE
      ? ASSET_TILE.VEHICLE
      : ASSET_TILE.EQUIPMENT;

  const placeholderText = `Please describe in details the results of the ${assetTitle} assessment, including any detected failures or issues`;

  return (
    <Popup
      title={`History by ${assetTitle}`}
      openPopup={showAssessmentHistory}
      setOpenPopup={setShowAssessmentHistory}
      onClose={() => {
        setShowAssessmentHistory(false);
      }}
      size="lg"
    >
      <Grid container>
        <Grid item xs={12}>
          {asset === AssetsConstant.VEHICLE && <VehicleTransfer />}
          {asset === AssetsConstant.EQUIPMENT && <EquipmentTransfer />}
        </Grid>
        <Grid item xs={6}>
          <Tabs
            value={selectedTab}
            onChange={handleChangeTab}
            aria-label="basic tabs example"
          >
            {permissionRole && <Tab label="Asignments" id="0" />}
            <Tab label="Assessment" id="1" />
            {permissionRole && asset === AssetsConstant.EQUIPMENT && (
              <Tab label="Projects" id="2" />
            )}
          </Tabs>
        </Grid>
        <Divider />
        <Grid item xs={12} mt={2}>
          <Stack mr={20} mb={2}>
            {selectedTab === 1 &&
              assetStatus !== ASSETSTATUS.OUTOFSERVICE &&
              eval(`fullAccess${asset}`) && (
                <FormTextArea
                  label=""
                  name="description"
                  value={values.description}
                  placeholder={placeholderText}
                  onChange={handleInputChange}
                  error={errors.description}
                />
              )}
          </Stack>
          {!editMode &&
            selectedTab === 1 &&
            assetStatus !== ASSETSTATUS.OUTOFSERVICE &&
            eval(`fullAccess${asset}`) && (
              <FormButton text="Add" onClick={AddDescription} size="small" />
            )}
          {editMode && eval(`fullAccess${asset}`) && (
            <FormButton
              text="Update"
              color="primary"
              onClick={updateDetail}
              size="small"
            />
          )}
        </Grid>
        <Grid item xs={12} mt={5}>
          {selectedTab === 0 && (
            <AssignmentsGrid assetId={currentRow?.id ?? 0} refresh={refresh} />
          )}
          {selectedTab === 1 && (
            <AssesmentHistoryGrid
              assetId={currentRow?.id ?? 0}
              refresh={refresh}
              setEditMode={setEditMode}
              setRowEdit={setRowEdit}
              setDeleteModal={setDeleteModal}
            />
          )}
          {selectedTab === 2 && (
            <AssetProjects currentrow={currentRow} refresh={refresh} />
          )}
        </Grid>
      </Grid>

      <Stack
        direction="row"
        spacing={2}
        justifyContent="flex-end"
        alignItems="flex-start"
      >
        <FormButton
          text="Close"
          size="medium"
          variant="outlined"
          onClick={() => {
            setShowAssessmentHistory(false);
          }}
        />
        <MatchWordConfirmModal
          onConfirm={deleteModalHandler}
          text={`Are you certain you want to delete this item?`}
          matchWord={"delete"}
          isDialogOpen={deleteModal}
          setIsDialogOpen={setDeleteModal}
        />
      </Stack>
    </Popup>
  );
};

export default AssesmentReport;
