import React from "react";
import { CircularProgress } from "@mui/material";
import { Save as SaveIcon } from "@mui/icons-material";
import FormButton from "./FormButton";

interface ButtonProps {
  onClick: (e: React.MouseEvent<HTMLElement>) => void;
  [key: string]: any;
  isSubmitting: boolean;
  disabled?: boolean;
}

const FormSaveButton = (props: ButtonProps) => {
  const { text, size, variant, onClick, isSubmitting, disabled, ...other } =
    props;

  return (
    <FormButton
      color="primary"
      text={text ? text : "Save"}
      variant="contained"
      size="small"
      onClick={onClick}
      startIcon={isSubmitting ? <CircularProgress size="1rem" /> : <SaveIcon />}
      disabled={disabled || isSubmitting}
      {...other}
    />
  );
};

export default FormSaveButton;
