import Popup from "src/components/Popup";
import { FormButton } from "src/components/formControls";
import { Stack, Typography } from "@mui/material";
import { useState } from "react";
import AddDisputeEntryPopUp from "./AddDisputeEntryPopUp";

interface AddDisputeInformationPopUpProps {
  addDisputeInformationShowPopUp: boolean;
  setAddDisputeInformationShowPopUp: (val: boolean) => void;
  projectId: number;
  setRefresh: (val: boolean) => void;
  refresh: boolean;
}

export default function AddDisputeInformationPopUp({
  addDisputeInformationShowPopUp,
  setAddDisputeInformationShowPopUp,
  projectId,
  setRefresh,
  refresh,
}: AddDisputeInformationPopUpProps) {
  const [addDisputeEntryShowPopUp, setAddDisputeEntryShowPopUp] =
    useState(false);
  const moveFowardHandler = () => {
    setAddDisputeEntryShowPopUp(true);
  };
  return (
    <>
      <Popup
        title={"Information"}
        openPopup={addDisputeInformationShowPopUp}
        setOpenPopup={setAddDisputeInformationShowPopUp}
        onClose={() => {
          setAddDisputeInformationShowPopUp(false);
        }}
        size="sm"
      >
        <>
          <Typography>
            Any complaints involving contractual disputes, charges for services
            provided, disputes involving Accounts Receivable, Accounts Payable
            or the length of time Technician(s) spend on a project is not part
            of AMP’s complaint or dispute scope.
          </Typography>
          <br />
          <Typography>
            If any project plans, specifications, codes or standards weren’t
            followed, this would fall under contractual disputes that may
            require non payment by the project owner to the contractor who
            provided their services. Filing with the courts to recover damages.
          </Typography>
          <br />
          <Typography>
            AMP’s scope does involve complaints of Fraud, specifically the
            submitting of data by the contractor providing their services that
            is false, or known to be false.
          </Typography>
        </>
        <Stack direction="row" spacing={3}>
          <FormButton
            size="small"
            text="Cancel"
            variant="outlined"
            onClick={() => {
              setAddDisputeInformationShowPopUp(false);
            }}
          />
          <FormButton
            size="small"
            text="Move forward with complaint"
            color="primary"
            onClick={moveFowardHandler}
          />
        </Stack>
      </Popup>
      {addDisputeEntryShowPopUp && (
        <AddDisputeEntryPopUp
          addDisputeEntryShowPopUp={addDisputeEntryShowPopUp}
          setAddDisputeEntryShowPopUp={setAddDisputeEntryShowPopUp}
          setAddDisputeInformationShowPopUp={setAddDisputeInformationShowPopUp}
          projectId={projectId}
          setRefresh={setRefresh}
          refresh={refresh}
        />
      )}
    </>
  );
}
