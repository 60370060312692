import {
  IDefaultTemplate,
  IStudiesTemplate,
  ITemplate,
} from "src/ts/interfaces/template";
import axios from "../utils/axios";

const getById = async (id: number) => {
  return axios.get(`Template/${id}`);
};

const getDefaults = async () => {
  return axios.get(`Template/WithDefaults`);
};

const getProject = async (id: number) => {
  return axios.get(`Template/${id}/project`);
};

const setPrefered = async (id: number) => {
  return axios.post(`Template/SetPreferred?templateId=${id}`);
};

const createTemplate = async (template: ITemplate) => {
  return axios.post(`Template`, template);
};

const updateTemplate = async (template: ITemplate) => {
  return axios.put(`Template/${template.id}`, template);
};

const deleteTemplate = async (id: number) => {
  return axios.delete(`Template/${id}`);
};

const createStudies = async (id: number) => {
  return axios.post(`Template/CreateStudyByType?projectId=${id}`);
};

const createDefaults = async () => {
  return axios.post(`Template/CreateDefaults`);
};

const changeStatus = async (id: number, isEnabled: boolean) => {
  return axios.put(`Template/UpdateActiveTemplate/${id}/${isEnabled}`);
};

const getStudiesByTemplate = async (id: number) => {
  return axios.get(`Template/${id}/studiesGroup`);
};

const getPreferredName = async () => {
  return axios.get<string>(`template/GetPreferredName`);
};
const getPreferred = async () => {
  return axios.get<string>(`template/GetPreferred`);
};

const getStudies = async (id: number) => {
  return axios.get<IStudiesTemplate[]>(`Template/${id}/studies`);
};

const getDefaultTemplate = async () => {
  return axios.get<IDefaultTemplate>(`template/WithDefaultsByTypeId?typeId=3`);
};

const templateServices = {
  getStudiesByTemplate,
  getById,
  getDefaults,
  setPrefered,
  createTemplate,
  deleteTemplate,
  updateTemplate,
  getProject,
  createStudies,
  changeStatus,
  getPreferredName,
  getPreferred,
  getStudies,
  getDefaultTemplate,
  createDefaults,
};

export default templateServices;
