import axios from "src/utils/axios";
import { IPumpSystemStrainerRequiredDTO } from "src/ts/interfaces";

const get = () => {
  return axios.get<IPumpSystemStrainerRequiredDTO[]>(
    "PumpSystemStrainerRequired"
  );
};

const pumpSystemStrainerRequiredService = { get };
export default pumpSystemStrainerRequiredService;
