import Popup from "src/components/Popup";
import { FormButton, FormSelect } from "src/components/formControls";
import { Stack, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { IAudiTechnDataPage } from "src/ts/interfaces/technician";
import techniciansHistoryService from "src/services/techniciansHistoryService";
import { IHistoryDailyLogTech } from "src/ts/interfaces/techniciansHistory";
import { IKeyValue, ITechDailyLogItem } from "src/ts/interfaces";
import { useAsyncQuery, useForm, useLog } from "src/hooks";
import { ColumnType } from "src/types/enhancedTable";
import techDailyLogService from "src/services/techDailyLogService";
import LocalEnhancedTable from "src/components/localTable/LocalTable";
import { convertUTCDateToLocalDate } from "src/utils/utils";

interface DailyLogsProps {
  dailyLogShowPopUp: boolean;
  setDailyLogShowPopUp: (val: boolean) => void;
  dataTechn: IAudiTechnDataPage;
}

const initialValues = {
  projectSelected: -1,
};

export default function DailyLogTechnicians({
  dailyLogShowPopUp,
  setDailyLogShowPopUp,
  dataTechn,
}: DailyLogsProps) {
  const { log } = useLog();
  const [dataDailyLog, setDataDailyLog] = useState<IHistoryDailyLogTech[]>([]);
  const [dataSelected, setDataSelected] = useState<IHistoryDailyLogTech>();
  const [options, setOptions] = useState<IKeyValue<number, string>[]>([]);

  const { values, handleInputChange } = useForm(initialValues, true, []);

  const columns = (): ColumnType[] => [
    {
      id: "logType",
      label: "Log Type",
      type: "custom",
      sort: true,
      callback: (row: ITechDailyLogItem) => {
        return <>{row.typeTechDailyLog?.name}</>;
      },
    },
    {
      id: "time",
      label: "Time",
      type: "custom",
      sort: false,
      align: "center",
      callback: (row: ITechDailyLogItem) => {
        return (
          <>
            <Typography>{row.time}</Typography>
          </>
        );
      },
    },
    {
      id: "comment",
      label: "Comment",
      type: "string",
      sort: true,
      align: "left",
    },
    {
      id: "createdDate",
      label: "Created Date",
      type: "utcDate",
      format: "YYYY-MM-DD hh:mm:ss A",
      sort: true,
      align: "center",
    },
  ];

  useEffect(() => {
    const getData = async () => {
      const response = await techniciansHistoryService.getTechnicianDailyLog(
        dataTechn.userId ?? 0,
        dataTechn.companyId ?? 0
      );
      if (response.data.length === 0) {
        log.error("The technician has not been assigned yet to any project");
        setDailyLogShowPopUp(false);
      }
      setDataDailyLog(response.data);
    };

    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const optionList = () => {
      const newOptions = dataDailyLog.map((item: IHistoryDailyLogTech) => ({
        key: item.id,
        value: item.projectName,
      }));
      setOptions([...options, ...newOptions]);
    };
    if (dataDailyLog.length > 0) optionList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataDailyLog]);

  useEffect(() => {
    const getData = () => {
      const filter = dataDailyLog.filter((item) => {
        return item.id === values.projectSelected;
      });
      setDataSelected(filter[0] ?? {});

      execute(filter[0].projectId, filter[0].userId);
    };

    if (dataDailyLog.length > 0) getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  const { execute, data, isLoading } = useAsyncQuery<ITechDailyLogItem[]>(
    techDailyLogService.getAuditorList
  );

  const getColumns = columns();

  return (
    <>
      {dataDailyLog.length > 0 && (
        <Popup
          title={`Daily Log - Technician: ${dataTechn.name} ${dataTechn.lastName}`}
          openPopup={dailyLogShowPopUp}
          setOpenPopup={setDailyLogShowPopUp}
          onClose={() => {
            setDailyLogShowPopUp(false);
          }}
          size="md"
        >
          <>
            <Grid container spacing={5}>
              <Grid item xs={2}>
                <Typography>Project(s) assigned to the technician</Typography>
              </Grid>
              <Grid item xs={3}>
                <Stack direction="row" justifyContent="center">
                  <FormSelect
                    name="projectSelected"
                    value={values.projectSelected}
                    label=""
                    onChange={handleInputChange}
                    options={options}
                    size="small"
                    defaultValue={{
                      key: -1,
                      value: "Please Select a Project",
                    }}
                  />
                </Stack>
              </Grid>
              <Grid item xs={3} mt={2} container justifyContent="flex-end">
                <Typography> Date assigned to the project</Typography>
              </Grid>
              <Grid item xs={3}>
                {dataSelected !== undefined && (
                  <Typography mt={2}>
                    <b>
                      {convertUTCDateToLocalDate(
                        dataSelected?.dateAdded.toString()
                      )?.format("MM/DD/yyyy h:mm a") ?? ""}
                    </b>
                  </Typography>
                )}
              </Grid>
            </Grid>
            <Grid container spacing={3} mt={2}>
              <Grid item xs={2} container justifyContent="flex-end">
                <Typography mt={2}>Building Name</Typography>
              </Grid>
              <Grid item xs={3.3} container justifyContent="flex-start">
                {dataSelected !== undefined && (
                  <Typography mt={2}>
                    {dataSelected !== undefined && (
                      <Typography>
                        <b>{dataSelected?.buildingName}</b>
                      </Typography>
                    )}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={2.7} container justifyContent="flex-end">
                <Typography mt={2}>Date removed from the project</Typography>
              </Grid>
              <Grid item xs={4} container justifyContent="flex-start">
                {dataSelected !== undefined && (
                  <Typography mt={2} ml={2} mb={5}>
                    <b>
                      {convertUTCDateToLocalDate(
                        dataSelected?.dateRemoved.toString()
                      )?.format("MM/DD/yyyy h:mm a") ?? ""}
                    </b>
                  </Typography>
                )}
              </Grid>
            </Grid>
            <LocalEnhancedTable<ITechDailyLogItem>
              refreshGrid={false}
              columns={getColumns}
              data={data}
              showSkeleton={isLoading}
              orderColumn="desc"
            />
          </>
          <Stack direction="row" spacing={3}>
            <FormButton
              size="small"
              text="Close Daily Log"
              variant="outlined"
              onClick={() => {
                setDailyLogShowPopUp(false);
              }}
            />
          </Stack>
        </Popup>
      )}
    </>
  );
}
