import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import ReactPerfectScrollbar from "react-perfect-scrollbar";
import { List, Box, Link } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Stack } from "@mui/material";
import { SidebarItemsType } from "src/types/sidebar";
import SidebarNavSection from "./SidebarNavSection";
import "src/vendor/perfect-scrollbar.css";
import { ToolTip } from "../others/ToolTip";
import useAuth from "src/hooks/useAuth";
import { Permissions } from "src/types/authorizationData";

const baseScrollbar = (props: any) => css`
  background-color: ${props.theme.sidebar.background};
  border-right: 1px solid rgba(0, 0, 0, 0.12);
  flex-grow: 1;
`;

const Scrollbar = styled.div`
  ${baseScrollbar}
`;

const PerfectScrollbar = styled(ReactPerfectScrollbar)`
  ${baseScrollbar}
`;

const Items = styled.div`
  padding-top: ${(props) => props.theme.spacing(2.5)};
  padding-bottom: ${(props) => props.theme.spacing(2.5)};
`;

type SidebarNavProps = {
  items: {
    title: string;
    pages: SidebarItemsType[];
  }[];
};

const SidebarNav: React.FC<SidebarNavProps> = ({ items }) => {
  const theme1 = useTheme();
  const matches = useMediaQuery(theme1.breakpoints.up("md"));
  const { user } = useAuth();
  const ScrollbarComponent = (
    matches ? PerfectScrollbar : Scrollbar
  ) as React.ElementType;
  const hostname = window.location.hostname;
  return (
    <ScrollbarComponent>
      <List disablePadding>
        <Items>
          {items &&
            items.map((item) => (
              <SidebarNavSection
                component="div"
                key={item.title}
                pages={item.pages.filter((x) => {
                  return (
                    (((user?.permissions !== undefined &&
                      user?.permissions.filter(
                        (y: Permissions) =>
                          Object.values(x.permissionMatrix).filter(
                            (v) => v === y.code
                          ).length > 0 &&
                          (y.readOnly || y.fullAccess) &&
                          (!x.permissionMatrixChild ||
                            (x.permissionMatrixChild &&
                              user?.permissions.filter(
                                (y: Permissions) =>
                                  x.permissionMatrixChild?.filter(
                                    (v) => v === y.code
                                  ) &&
                                  (y.readOnly || y.fullAccess)
                              ).length > 0))
                      ).length > 0) ||
                      x.permissionMatrix === undefined) &&
                      x.permissionTechician === undefined) ||
                    user?.[x.permissionTechician ?? ""] === "true"
                  );
                })}
                title={item.title}
              />
            ))}
        </Items>
        <Stack direction="row" ml={10} mb={5}>
          <ToolTip title="Click to Verify Certificate">
            <Link
              target="_blank"
              href={`https://secure.trust-provider.com/ttb_searcher/trustlogo?v_querytype=W&v_shortname=SC5&v_search=https://${hostname}/&x=6&y=5`}
            >
              <Box
                component="img"
                alt="InstantSSL"
                src={"/static/img/instantSSL.png"}
                sx={{}}
              />
            </Link>
          </ToolTip>
        </Stack>
      </List>
    </ScrollbarComponent>
  );
};

export default SidebarNav;
