import {
  CardContent,
  Card as MuiCard,
  Stepper,
  Step,
  StepLabel,
  StepButton,
} from "@mui/material";
import { spacing } from "@mui/system";
import styled from "@emotion/styled";
import { IStudyStep } from "src/ts/interfaces";
import { useEffect } from "react";
import { floatingBarActions } from "src/redux/slices/floatingBarActions";
import { useAppDispatch } from "src/hooks";
import { PROJECTMENU_ALL } from "src/constants";
import { useNavigate } from "react-router-dom";

const Card = styled(MuiCard)(spacing);

interface Props {
  steps: IStudyStep[];
  activeStep?: number;
  projectId?: number;
  reportId?: number;
  reportTypeId?: number;
  code?: string;
}

const StudyWizard = ({
  steps,
  activeStep,
  projectId,
  reportId,
  reportTypeId,
  code,
}: Props) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    const getData = async () => {
      dispatch(
        floatingBarActions.setReport({
          projectId: projectId!,
          reportId: reportId!,
          reportTypeId: reportTypeId!,
        })
      );
      dispatch(floatingBarActions.setChangeProjectId(projectId!));
      dispatch(floatingBarActions.setOptionMenu(PROJECTMENU_ALL));
    };
    if (projectId && reportId && reportTypeId) getData();
  }, [dispatch, projectId, reportId, reportTypeId, activeStep]);

  const handleGoto = (url: string) => {
    let codeurl = url.replace(":code", code ?? "");
    navigate(codeurl.replace(":id", (reportId ?? 0)?.toString()));
  };

  return (
    <Card mb={1}>
      <CardContent>
        <Stepper alternativeLabel activeStep={activeStep}>
          {steps.map((step) => (
            <Step key={step.key}>
              <StepButton
                color="inherit"
                onClick={() => {
                  handleGoto(step.url ?? "/");
                }}
              >
                <StepLabel>
                  {step.label}
                  <br /> {step.description}
                </StepLabel>
              </StepButton>
            </Step>
          ))}
        </Stepper>
      </CardContent>
    </Card>
  );
};

export default StudyWizard;
