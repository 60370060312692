import React, { useEffect, useState } from "react";
import { NavigateFunction, useNavigate } from "react-router-dom";
import styled from "@emotion/styled";
import { ColumnType } from "src/types/enhancedTable";
import { spacing, SpacingProps } from "@mui/system";
import { Chip as MuiChip, Stack } from "@mui/material";
import LocalEnhancedTable from "src/components/localTable/LocalTable";
import useLoading from "src/hooks/useLoading";
import technicianQuizService from "src/services/technicianQuizService";
import { ITechnicianQuizList } from "src/ts/interfaces/technicianQuiz";
import DialogMessagePopup from "src/components/DialogMessagePopup";
import { GridActionButton } from "src/components/gridControls";
import { Permission as PermissionTypes } from "src/ts/enums";
import { useLog, usePermissions } from "src/hooks";
import DisableQuizPopup from "./component/DisableQuizPopup";

interface ChipProps extends SpacingProps {
  component?: React.ElementType;
  href?: string;
  icon?: JSX.Element | null;
}

const Chip = styled(MuiChip)<ChipProps>(spacing);

const getColumns = (
  navigate: NavigateFunction,
  handleDelete: (row: ITechnicianQuizList) => void,
  handleDisable: (row: ITechnicianQuizList) => void,
  fullAccess: boolean | undefined
): ColumnType[] => [
  {
    id: "title",
    label: "Title",
    type: "string",
    sort: true,
    callback: (row: ITechnicianQuizList) => {
      return row.title;
    },
  },
  {
    id: "certificationType",
    label: "Certification Type",
    type: "custom",
    sort: true,
    callback: (row: ITechnicianQuizList) => {
      return (
        <>
          {row.type === 2 ? (
            <Chip label="Test & Balance" color="primary" m={1} size="small" />
          ) : (
            <Chip label="Energy/Auditor" color="success" m={1} size="small" />
          )}
        </>
      );
    },
  },
  {
    id: "createDate",
    label: "Date Created",
    type: "utcDate",
    format: "MM/DD/YYYY",
    sort: true,
    callback: (row: ITechnicianQuizList) => {
      return row.createDate;
    },
  },
  {
    id: "description",
    label: "Description",
    type: "string",
    sort: true,
    callback: (row: ITechnicianQuizList) => {
      return row.description;
    },
  },

  {
    id: "totalQuestions",
    label: "Total Questions",
    type: "custom",
    sort: true,
    callback: (row: ITechnicianQuizList) => {
      return row.totalQuestions;
    },
  },
  {
    id: "x",
    label: "Actions",
    type: "custom",
    sort: false,
    callback: (row: ITechnicianQuizList) => (
      <div>
        <Stack direction="row">
          <GridActionButton
            type={fullAccess ? "edit" : "view"}
            onClick={() => {
              navigate(`/app/quizView/${row.id}`);
            }}
            tooltip={fullAccess ? "Edit" : "View"}
          />
          {fullAccess && (
            <>
              <GridActionButton
                type="disable"
                onClick={() => handleDisable(row)}
                tooltip="Disable"
              />
              <GridActionButton
                type="delete"
                onClick={() => handleDelete(row)}
                tooltip="Delete"
              />
            </>
          )}
        </Stack>
      </div>
    ),
  },
];

interface companyTableProps {
  refresh: boolean;
  isActive: boolean;
  filter: string;
}

function QuizData(props: companyTableProps) {
  let navigate = useNavigate();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showDisableModal, setShowDisableModal] = useState(false);
  const [currentRow, setCurrentRow] = useState<ITechnicianQuizList>();
  const [dataSet, setDataSet] = useState<ITechnicianQuizList[]>([]);
  const handleDelete = (row: ITechnicianQuizList) => {
    setCurrentRow(row);
    setShowDeleteModal(true);
  };
  const handleDisable = (row: ITechnicianQuizList) => {
    setCurrentRow(row);
    setShowDisableModal(true);
  };
  const { fullAccess } = usePermissions(PermissionTypes.Quiz);
  let columns = getColumns(navigate, handleDelete, handleDisable, fullAccess);
  const { startRequest, endRequest } = useLoading();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showSkeleton, setShowSkeleton] = useState(false);
  const { log } = useLog();
  useEffect(() => {
    const getData = async () => {
      try {
        setShowSkeleton(true);
        startRequest("quiz");
        const request = await technicianQuizService.getAll(props.isActive);
        setDataSet(request.data);
        endRequest("quiz");
        setShowSkeleton(false);
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
        navigate("/app/invalidPermissions");
      }
    };

    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setDataSet]);

  const deleteDocument = async () => {
    if (currentRow && currentRow.id) {
      try {
        setIsSubmitting(true);
        await technicianQuizService.deleteById(currentRow.id);
        setDataSet((current) =>
          current.filter(function (item) {
            return item !== currentRow;
          })
        );
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      } finally {
        setIsSubmitting(false);
      }
    } else {
    }
  };

  const disableQuiz = () => {
    setDataSet((current) =>
      current.filter(function (item) {
        return item !== currentRow;
      })
    );
  };

  return (
    <>
      <LocalEnhancedTable<ITechnicianQuizList>
        columns={columns}
        data={dataSet}
        defaultRowPerPage={10}
        showSkeleton={showSkeleton}
        query={props.filter}
        filterCustom={["title", "description"]}
      />
      <DialogMessagePopup
        title={"Information"}
        text={"Are you certain you want to delete this item?"}
        showPopup={showDeleteModal}
        setShowPopup={setShowDeleteModal}
        onSave={deleteDocument}
        isSubmitting={isSubmitting}
      />
      <DisableQuizPopup
        showDisableModal={showDisableModal}
        setShowDisableModal={setShowDisableModal}
        id={currentRow?.id ?? 0}
        status={props.isActive}
        onSave={disableQuiz}
      ></DisableQuizPopup>
    </>
  );
}

export default QuizData;
