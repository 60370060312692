import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "@emotion/styled";
import {
  Avatar,
  CardContent,
  Grid,
  Card as MuiCard,
  Divider as MuiDivider,
  Typography,
  TextField as MuiTextField,
  Fab,
} from "@mui/material";
import { Box, Stack, spacing } from "@mui/system";
import {
  Form,
  useAsyncMutation,
  useAsyncQuery,
  useLog,
  usePermissions,
} from "src/hooks";
import {
  FormButton,
  FormCancelButton,
  FormDatePicker,
  FormMaskText,
  FormRaitingStarts,
  FormSelect,
  FormText,
  FormTextArea,
} from "src/components/formControls";
import HeaderPage from "src/components/page/HeaderPage";
import {
  IRecommendations,
  IRecommendationsScore,
} from "src/ts/interfaces/recommendations";
import recommendationsService from "src/services/recommendationsService";
import { CatalogService } from "src/services";
import { IKeyValue } from "src/ts/interfaces";
import { Send } from "@mui/icons-material";
import { IRecommendationsComments } from "src/ts/interfaces/recommendationsComments";
import { convertUTCDateToLocalDate } from "src/utils/utils";
import { Permission as PermissionTypes } from "src/ts/enums";
import Popup from "src/components/Popup";
import recommendationsScoreService from "src/services/recommendationsScoreService";
import { ColumnType } from "src/types/enhancedTable";
import LocalEnhancedTable from "src/components/localTable/LocalTable";
import recommendationsCommentsService from "src/services/recommendationsCommentsService";
const ChatMain = styled(Grid)``;
const ChatMessages = styled.div`
  overflow-y: scroll;
  height: calc(65vh - 94px);
`;
const ChatMessage = styled.div<{ position: "left" | "right" }>`
  margin: 30px;
  text-align: ${(props) => props.position};
`;

const ChatMessageInner = styled.div`
  display: inline-block;
`;

const ChatMessageAvatar = styled(Avatar)`
  position: relative;
  display: inline-block;
  width: 34px;
  height: 34px;
  margin-right: ${(props) => props.theme.spacing(2)};
`;

const ChatMessageBubble = styled.div<{ highlighted: boolean }>`
  display: inline-block;
  margin-right: auto;
  background: ${(props) =>
    props.highlighted
      ? props.theme.palette.secondary.main
      : props.theme.palette.action.hover};
  color: ${(props) =>
    props.highlighted
      ? props.theme.palette.common.white
      : props.theme.palette.text.primary};
  border-radius: 3px;
  padding: ${(props) => props.theme.spacing(2)};
  margin-bottom: ${(props) => props.theme.spacing(1)};
  ${(props) => props.theme.shadows[1]};
`;

const ChatMessageBubbleName = styled(Typography)`
  font-weight: ${(props) => props.theme.typography.fontWeightBold};
`;

const ChatMessageTime = styled(Typography)`
  text-align: right;
  opacity: 0.8;
`;

const ChatInput = styled(Grid)`
  min-height: 94px;
  padding: ${(props) => props.theme.spacing(5)};
`;

const TextField = styled(MuiTextField)(spacing);

const Card = styled(MuiCard)(spacing);
const Divider = styled(MuiDivider)(spacing);
const initialValues: IRecommendations = {
  id: 0,
  title: "",
  description: "",
  createdDate: new Date(),
  updated: new Date(),
  recommendationsStatusId: 0,
  score: 0,
  recommendationsTypeId: 0,
  code: "",
  other: "",
};

const columns = (): ColumnType[] => [
  {
    id: "name",
    label: "Name",
    type: "custom",
    sort: false,
    callback: (row: IRecommendationsScore) => {
      return <>{`(${row.created?.name}) ${row.created?.lastName}`}</>;
    },
  },
  {
    id: "comments",
    label: "Comments",
    type: "string",
    sort: true,
  },
  {
    id: "score",
    label: "Score",
    type: "custom",
    sort: false,
    callback: (row: IRecommendations) => {
      return <FormRaitingStarts value={row?.score ?? 0} />;
    },
  },
];

function ChatMessageComponent({
  comments,
  createdDate,
  isOwner,
  created,
}: IRecommendationsComments) {
  return (
    <ChatMessage position={isOwner ? "right" : "left"}>
      <ChatMessageInner>
        <ChatMessageAvatar
          alt={`${created?.name} ${created?.lastName}`}
          src={"/static/img/AMPWebLogoVectors.svg"}
        />
        <ChatMessageBubble highlighted={isOwner}>
          <Box>
            <ChatMessageBubbleName variant="body1">
              {created?.name} {created?.lastName}
            </ChatMessageBubbleName>
          </Box>
          <Typography variant="body2">{comments}</Typography>
        </ChatMessageBubble>
        <ChatMessageTime variant="body2">
          {convertUTCDateToLocalDate(createdDate.toString() ?? "")?.format(
            "MM/DD/yyyy h:mma"
          )}
        </ChatMessageTime>
      </ChatMessageInner>
    </ChatMessage>
  );
}

const SuggestionBankInfo = () => {
  let navigate = useNavigate();
  let params = useParams<{
    id: string;
    statusId: string;
  }>();
  const { log } = useLog();
  const id = parseInt(params?.id === undefined ? "0" : params?.id);
  const { execute, data, isLoading } = useAsyncQuery<IRecommendations>(
    recommendationsService.getByIdUser
  );
  const [dataType, setDataType] = useState<IKeyValue<number, string>[]>([]);
  const [ratingPopup, setRatingPopup] = useState(false);
  const [ratingComments, setRatingComments] = useState("");
  const [message, setMessage] = useState("");
  const [statusId, setStatusId] = useState(
    parseInt(params?.statusId === undefined ? "0" : params?.statusId)
  );
  const [values, setValues] = useState<IRecommendations>(initialValues);
  const { fullAccess, read } = usePermissions(
    PermissionTypes.Commitee_Suggestion_Box
  );
  const { execute: executeRating, isSubmitting: isSubmittingRating } =
    useAsyncMutation(recommendationsScoreService.create, {
      successfulMessage: "Score was saved",
    });

  const { execute: executeSendMessages, isSubmitting: isSubmittingMessages } =
    useAsyncMutation(recommendationsCommentsService.create, {
      successfulMessage: "The message was sent",
      onSuccess: async () => {
        await execute(id, statusId);
        setMessage("");
      },
    });

  useEffect(() => {
    const getData = async () => {
      var result = await CatalogService.getAllDropdown("RecommendationsType");
      setDataType(result);
    };
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const getData = async () => {
      await execute(id, statusId);
    };
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (data) {
      if (data.recommendationsStatusId > 1) {
        setStatusId(data.recommendationsStatusId);
      }
      setValues(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const handleCancel = () => {
    if (read) navigate(`/app/suggestionBankAdmin`);
    else navigate(`/app/suggestionBank`);
  };

  const handleUpdateType = (e: any) => {
    setValues({
      ...values,
      recommendationsTypeId: e.target.value,
    });
    recommendationsService.updateType(id, e.target.value);
    log.success("Classification was updated");
  };

  const saveRatingHandler = async () => {
    await executeRating(values.score, id, ratingComments);
    handleCancel();
  };

  const handleConfirmModalRating = (rating: number) => {
    setValues({
      ...values,
      score: rating,
    });
    setRatingPopup(true);
  };

  const handleSendMessage = async () => {
    const messageComments: IRecommendationsComments = {
      id: 0,
      comments: message,
      createdDate: new Date(),
      isOwner: read ? false : true,
      recommendationsId: values?.id,
    };

    await executeSendMessages(messageComments);
  };

  const isOther = () => {
    const found = dataType.find((element) => element.value === "Other");
    if (!found) return false;
    return values?.recommendationsTypeId === found?.key;
  };

  return (
    <>
      <HeaderPage
        title={"Amazon Gift Card Suggestion Bank"}
        parentText={"Suggestion Bank"}
        parentLink={`/app/suggestionBank`}
        actionSection={undefined}
      ></HeaderPage>
      <Divider my={6} />
      <Card mb={6}>
        <CardContent>
          <Form>
            <Grid container>
              <Grid item xs={6}>
                <Typography variant="h6">
                  {" "}
                  {`(${values?.code}) ${values?.title}`}{" "}
                </Typography>
              </Grid>
              <Grid item xs={6} textAlign={"right"}>
                {read && values && (
                  <>
                    <Grid container>
                      <Grid item xs={10}>
                        {fullAccess && (
                          <Typography variant="h6" p={1}>
                            Please rate this suggestion
                          </Typography>
                        )}
                      </Grid>
                      <Grid item xs={2}>
                        <FormRaitingStarts
                          value={values?.score ?? 0}
                          readOnly={!fullAccess || statusId !== 1}
                          onChange={handleConfirmModalRating}
                        />
                      </Grid>
                    </Grid>
                  </>
                )}
              </Grid>
            </Grid>

            <Divider my={3}></Divider>
            <Grid container spacing={5}>
              <Grid item xs={4}>
                <FormText
                  name="name"
                  label="Name"
                  value={`${values?.created?.name} ${values?.created?.lastName}`}
                  showSkeleton={isLoading}
                  placeholder="Name"
                  mode={"read"}
                />
              </Grid>
              <Grid item xs={4}>
                <FormText
                  name="email"
                  label="Email"
                  value={values?.created?.email}
                  showSkeleton={isLoading}
                  placeholder="Email"
                  mode={"read"}
                />
              </Grid>
              <Grid item xs={4}>
                <FormMaskText
                  name="phone"
                  mask="(999) 999 - 9999"
                  label="Phone"
                  value={values?.created?.phone}
                  mode={"read"}
                  showSkeleton={isLoading}
                  onChange={function (e: any): void {
                    throw new Error("Function not implemented.");
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <FormDatePicker
                  label="Created Date"
                  value={values?.createdDate.toString()}
                  name={"createdDate"}
                  mode={"read"}
                  onChange={function (e: any): void {
                    throw new Error("Function not implemented.");
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <FormTextArea
                  name="description"
                  label="Description"
                  value={values.description}
                  placeholder="Description"
                  onChange={function (e: any): void {
                    throw new Error("Function not implemented.");
                  }}
                  mode={"read"}
                />
              </Grid>
              <Grid item xs={4}>
                <FormSelect
                  name="recommendationsTypeId"
                  label="Classification"
                  value={values?.recommendationsTypeId.toString()}
                  onChange={handleUpdateType}
                  options={dataType}
                  showSkeleton={isLoading}
                  mode={fullAccess ? "read&Write" : "read"}
                />
              </Grid>
              {isOther() && (
                <Grid item xs={6}>
                  <FormTextArea
                    name="other"
                    label="Other Classification"
                    value={values.other}
                    placeholder="Other"
                    onChange={function (e: any): void {
                      throw new Error("Function not implemented.");
                    }}
                    mode={"read"}
                  />
                </Grid>
              )}
              {(statusId === 0 ||
                statusId === 3 ||
                statusId === 5 ||
                statusId === 4) &&
                read && (
                  <>
                    <Grid item xs={12}>
                      <Typography variant="h6">
                        Scores by Administrators
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Divider></Divider>
                    </Grid>
                    <Grid item xs={12}>
                      <LocalEnhancedTable<IRecommendationsScore>
                        refreshGrid={true}
                        columns={columns()}
                        data={Object.values(values.recommendationsScore ?? [])}
                        showSkeleton={isLoading}
                      />
                    </Grid>
                  </>
                )}
            </Grid>
            <Grid container paddingTop={3}>
              <Grid item xs={12}>
                <Typography variant="h6">Comments</Typography>
              </Grid>

              <Grid item xs={12}>
                <ChatMain item xs={12}>
                  {fullAccess && (
                    <ChatInput container>
                      <Grid item style={{ flexGrow: 1, marginTop: "0" }}>
                        <TextField
                          variant="outlined"
                          label="Type your message"
                          fullWidth
                          value={message}
                          onChange={(e: any) => {
                            setMessage(e.target.value);
                          }}
                        />
                      </Grid>
                      <Grid item>
                        <Box ml={2}>
                          <Fab
                            onClick={handleSendMessage}
                            color="primary"
                            aria-label="add"
                            size="medium"
                            disabled={isSubmittingMessages}
                          >
                            <Send />
                          </Fab>
                        </Box>
                      </Grid>
                    </ChatInput>
                  )}

                  <Grid item xs={12} paddingBottom={2}>
                    <Stack
                      direction="row"
                      spacing={2}
                      justifyContent="center"
                      alignItems="center"
                    >
                      <FormCancelButton
                        onClick={handleCancel}
                        isSubmitting={false}
                      />
                    </Stack>
                  </Grid>
                  <Divider />
                  <ChatMessages>
                    {(values?.recommendationsComments ?? []).map(
                      (item: any, index: number) => (
                        <ChatMessageComponent
                          comments={item.comments}
                          id={item.id}
                          createdDate={item.createdDate}
                          isOwner={item.isOwner}
                          recommendationsId={item.recommendationsId}
                          created={item.created}
                        />
                      )
                    )}
                  </ChatMessages>
                </ChatMain>
              </Grid>
            </Grid>
          </Form>
        </CardContent>
      </Card>
      <Popup
        openPopup={ratingPopup}
        setOpenPopup={setRatingPopup}
        title={`Comments`}
        onClose={() => setRatingPopup(false)}
      >
        <Grid container>
          <Grid item xs={12} pb={5}>
            <Typography>Are you certain you want to add this score:</Typography>
          </Grid>
          <Grid item xs={12} pb={5}>
            <FormRaitingStarts value={values?.score ?? 0} readOnly={true} />
          </Grid>
          <Grid item xs={12}>
            <FormTextArea
              name="comment"
              label="Comments"
              value={ratingComments}
              onChange={(e: any) => {
                setRatingComments(e.target.value);
              }}
              placeholder="Comments"
            />
          </Grid>
        </Grid>
        <>
          <Stack
            direction="row"
            spacing={2}
            justifyContent="center"
            alignItems="center"
          >
            <FormButton
              text="Save"
              onClick={saveRatingHandler}
              isSubmitting={isSubmittingRating}
              size="small"
            />
          </Stack>
        </>
      </Popup>
    </>
  );
};

export default SuggestionBankInfo;
