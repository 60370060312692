import Popup from "src/components/Popup";
import { FormButton } from "src/components/formControls";
import { Stack, Typography, Divider as MuiDivider } from "@mui/material";
import { useAsyncQuery } from "src/hooks";
import { technicianQuizExamService } from "src/services";
import { useEffect } from "react";
import { ITechnicianQuizExamAnswers } from "src/ts/interfaces";
import FormTextEditor from "src/components/formControls/FormTextEditor";
import styled from "@emotion/styled";
import { spacing } from "@mui/system";

const Divider = styled(MuiDivider)(spacing);

interface ViewTestPopUpProps {
  viewTestShowPopUp: boolean;
  setViewTestShowPopUp: (val: boolean) => void;
  technicianId: number;
}

export default function ViewTestPopUp({
  viewTestShowPopUp,
  setViewTestShowPopUp,
  technicianId,
}: ViewTestPopUpProps) {
  const { execute: executeQuiz, data: dataQuiz } = useAsyncQuery<
    ITechnicianQuizExamAnswers[]
  >(technicianQuizExamService.getQuizExam);

  useEffect(() => {
    executeQuiz(technicianId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Popup
        title={"Exam"}
        openPopup={viewTestShowPopUp}
        setOpenPopup={setViewTestShowPopUp}
        onClose={() => {
          setViewTestShowPopUp(false);
        }}
        size="sm"
      >
        <>
          {dataQuiz &&
            dataQuiz.map((item, index) => {
              return (
                <>
                  <Stack>
                    <Typography>{`${index + 1}. Question`}</Typography>
                    <FormTextEditor
                      value={item?.technicianQuizBacklog?.description ?? ""}
                      onChange={() => {}}
                      name={"description"}
                      title={""}
                      readonly={true}
                    />
                  </Stack>
                  <Typography>{`Answer: ${item.technicianQuizAnswers.description}`}</Typography>
                  <Divider my={6} />
                </>
              );
            })}
        </>
        <Stack direction="row" spacing={3}>
          <FormButton
            size="small"
            text="Close"
            variant="outlined"
            onClick={() => {
              setViewTestShowPopUp(false);
            }}
          />
        </Stack>
      </Popup>
    </>
  );
}
