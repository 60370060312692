import axios from "src/utils/axios";
import { IElectricCoil } from "src/ts/interfaces/study/electricCoil";

const getById = (id: number) => {
  return axios.get<IElectricCoil>(`ElectricCoil/${id}`);
};

const update = (id: number, outletMaster: IElectricCoil) => {
  return axios.put<IElectricCoil>(`ElectricCoil/${id}`, outletMaster);
};

const electricCoilService = {
  getById,
  update,
};

export default electricCoilService;
