import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import {
  CardContent,
  CircularProgress,
  CircularProgressProps,
  CssBaseline,
  Grid,
  Link,
  Card as MuiCard,
  Divider as MuiDivider,
  Typography,
} from "@mui/material";
import { useAsyncQuery, useLog, useTheme } from "src/hooks";

import GlobalStyle from "src/components/GlobalStyle";
import { Box, spacing } from "@mui/system";
import { Helmet } from "react-helmet-async";

import { THEMES } from "src/constants";
import { useParams } from "react-router-dom";
import { FormButton } from "src/components/formControls";
import { FileDownloadIcon } from "src/components/icons";

import FileUtils from "src/utils/file";
import sharedDocumentServices from "src/services/sharedDocumentServices";

const Card = styled(MuiCard)(spacing);
const Root = styled.div`
  max-width: 70%;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  display: flex;
  min-height: 100%;
  flex-direction: column;
`;

interface IGetDocument {
  isValidCode: boolean;
  typeName: string;
}

function GetDocument() {
  let { code } = useParams<{ code: string }>();
  const { log } = useLog();
  const { theme } = useTheme();
  const Divider = styled(MuiDivider)(spacing);

  const [progress, setProgress] = useState(0);
  const [isProgressRunning, setIsProgressRunning] = useState(false);
  const { execute, data } = useAsyncQuery<IGetDocument>(
    sharedDocumentServices.getCheckCode
  );

  useEffect(() => {
    execute(code ?? "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (isProgressRunning) {
      timer = setInterval(() => {
        setProgress((prevProgress) =>
          prevProgress >= 100 ? 100 : prevProgress + 10
        );
      }, 200);
    } else if (!isProgressRunning && progress !== 100) {
      setProgress(0);
    }

    return () => {
      if (timer) {
        clearInterval(timer);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isProgressRunning]);

  const downloadFile = async () => {
    try {
      setIsProgressRunning(true);
      setProgress(0);
      const response = await sharedDocumentServices.downloadFile(code ?? "");
      setProgress(100);
      let fileName = data.typeName;

      FileUtils.downloadFile2(
        response.data,
        fileName,
        "application/zip",
        "zip"
      );
      setIsProgressRunning(false);
    } catch (error: any) {
      log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      setIsProgressRunning(false);
      setProgress(0);
    }
  };

  function CircularProgressWithLabel(
    props: CircularProgressProps & { value: number }
  ) {
    return (
      <Box sx={{ position: "relative", display: "inline-flex" }}>
        <CircularProgress variant="determinate" {...props} />
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: "absolute",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography
            variant="caption"
            component="div"
            color="text.secondary"
          >{`${Math.round(props.value)}%`}</Typography>
        </Box>
      </Box>
    );
  }

  return (
    <Root>
      <CssBaseline />
      <GlobalStyle />
      <Grid container spacing={2} pt={10}>
        <Grid item xs={3}></Grid>
        <Grid item xs={6}>
          <Card mb={1}>
            <CardContent>
              <Helmet title="Get Zip File" />
              <Link href="https://www.auditmaster.pro/" target="_blank">
                <Box
                  component="img"
                  alt="Logo AMP"
                  src={
                    THEMES.DARK === theme
                      ? "/static/img/AMPWebLogoVectors.svg"
                      : "/static/img/AMPWebLogoVectorsLight.svg"
                  }
                  sx={{
                    marginLeft: "25%",
                    marginRight: "25%",
                    width: "50%",
                    marginBottom: "20px",
                  }}
                />
              </Link>
              <Divider mb={4} />

              {data?.isValidCode && (
                <Grid container justifyContent={"center"}>
                  <FormButton
                    onClick={downloadFile}
                    text="Get Zip File"
                    color="primary"
                    startIcon={<FileDownloadIcon />}
                  />
                </Grid>
              )}
              {data && !data?.isValidCode && (
                <Grid container justifyContent={"center"}>
                  <Typography variant="h3" pb={2} textAlign={"center"}>
                    This request code is not longer valid
                  </Typography>
                </Grid>
              )}

              <Grid container justifyContent={"center"} mt={5}>
                {isProgressRunning && (
                  <CircularProgressWithLabel value={progress} />
                )}
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Root>
  );
}
export default GetDocument;
