import { ICouponsPrimaryEnergyAirtMovement } from "src/ts/interfaces";
import axios from "../utils/axios";

const CountAvailableCoupons = async () => {
  return axios.get(`CouponsWalletMovement/countAvailable`);
};

const getPrimaryEnergyAirCoupons = (userId: number) => {
  return axios.get<ICouponsPrimaryEnergyAirtMovement[]>(
    `CouponsWalletMovement/energyAirCoupons?userId=${userId}`
  );
};

const couponsWalletMovementService = {
  CountAvailableCoupons,
  getPrimaryEnergyAirCoupons,
};

export default couponsWalletMovementService;
