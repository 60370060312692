import React, { useState } from "react";
import { Grid, Stack } from "@mui/material";
import { ICompany } from "src/ts/interfaces";
import { FormButton } from "src/components/formControls";
import { LibraryAddCheck } from "@mui/icons-material";

interface Props {
  companyUser: ICompany | null;
  projectId: number;
  onCompleteProject: () => Promise<void>;
}

const Complete = ({ companyUser, projectId, onCompleteProject }: Props) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const complete = async () => {
    setIsSubmitting(true);
    await onCompleteProject();
    setIsSubmitting(false);
  };

  return (
    <Grid container spacing={6}>
      <Grid item xs={12}>
        <Stack direction="row" justifyContent="center">
          <FormButton
            text="Complete Project"
            onClick={() => complete()}
            size="small"
            startIcon={<LibraryAddCheck />}
            isSubmitting={isSubmitting}
          />
        </Stack>
      </Grid>
    </Grid>
  );
};

export default Complete;
