import { Typography } from "@mui/material";
import { useEffect, useState } from "react";
import FormReadOnlyField from "src/components/formControls/FormReadOnlyField";
import { CompanyService } from "src/services";
import { CompanyContractorData, ICompanyContractors } from "src/ts/interfaces";

interface Props {
  companyId: number;
  contractorInfo: ICompanyContractors | undefined;
}

const ContractorDetail = ({ companyId, contractorInfo }: Props) => {
  const [contractorData, setContractorData] =
    useState<CompanyContractorData | null>(null);

  useEffect(() => {
    const getContractorData = async () => {
      const req = await CompanyService.getContractorData(companyId);
      setContractorData(req.data);
    };
    if (companyId > 0) getContractorData();
  }, [companyId]);

  return (
    <>
      <Typography variant="h4" pb={4}>
        Contractor
      </Typography>
      <FormReadOnlyField
        label="Name"
        value={companyId !== 0 ? contractorData?.name : contractorInfo?.name}
      />
      <FormReadOnlyField
        label="Address"
        value={
          companyId !== 0
            ? contractorData?.companyAddress
            : contractorInfo?.address
        }
      />
      <FormReadOnlyField
        label="Email"
        value={companyId !== 0 ? contractorData?.email : contractorInfo?.email}
      />
      <FormReadOnlyField
        label="Phone"
        value={
          companyId !== 0 ? contractorData?.phone : contractorInfo?.phoneNumber
        }
      />
    </>
  );
};

export default ContractorDetail;
