import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface ISignalrState {
  isConnected: boolean;
  messageCode: string;
  building: number;
  paidId: number;
  projectList: number;
  ticketList: number;
  technicianList: number;
  walletList: number;
  coutNewTicket: number;
  newTechnician: number;
  newOutToBid: number;
}

const initialState: ISignalrState = {
  isConnected: false,
  messageCode: "",
  building: 0,
  paidId: 0,
  projectList: 0,
  ticketList: 0,
  technicianList: 0,
  walletList: 0,
  coutNewTicket: 0,
  newTechnician: 0,
  newOutToBid: 0,
};

const signalrSlice = createSlice({
  name: "signalR",
  initialState: initialState,
  reducers: {
    setIsConnected(state: ISignalrState, action: PayloadAction<boolean>) {
      state.isConnected = action.payload;
    },
    setMessageCode(state: ISignalrState, action: PayloadAction<string>) {
      state.messageCode = action.payload;
    },
    newBuilding(state: ISignalrState) {
      state.building = state.building + 1;
    },
    paidId(state: ISignalrState, action: PayloadAction<number>) {
      state.paidId = action.payload;
    },
    projectList(state: ISignalrState) {
      state.projectList = state.projectList + 1;
    },
    ticketList(state: ISignalrState) {
      state.ticketList = state.ticketList + 1;
    },
    technicianList(state: ISignalrState) {
      state.technicianList = state.technicianList + 1;
    },
    walletList(state: ISignalrState) {
      state.walletList = state.walletList + 1;
    },
    coutNewTicket(state: ISignalrState, action: PayloadAction<number>) {
      state.coutNewTicket = action.payload;
    },
    newTechnician(state: ISignalrState, action: PayloadAction<number>) {
      state.newTechnician = action.payload;
    },
    newOutToBid(state: ISignalrState, action: PayloadAction<number>) {
      state.newOutToBid = action.payload;
    },
  },
});

export default signalrSlice;
