import { Grid, IconButton, Tooltip, Zoom, useTheme } from "@mui/material";
import { FormNumeric2 } from "src/components/formControls";
import useSystemOfMeasurement from "src/hooks/useSystemOfMeasurement";
import useOutletReport from "../../hooks/useOutletReport";
import { InfoIcon } from "src/components/icons";
import DialogMessagePopup from "src/components/DialogMessagePopup";
import { useEffect, useState } from "react";
import tooltipService from "src/services/tooltipService";
import { ITooltip } from "src/ts/interfaces/tooltip";
import { useAsyncQuery } from "src/hooks";
import { Mode } from "src/ts/types";
import { IOutletReportComponent } from "src/ts/interfaces";

interface Props {
  updateStudyHandler: () => Promise<void>;
  mode: Mode;
  setComponentHandler: (components: IOutletReportComponent[]) => void;
  components: IOutletReportComponent[];
  disableStudy: boolean;
}

const ComponentForm = ({
  updateStudyHandler,
  mode,
  setComponentHandler,
  components,
  disableStudy,
}: Props) => {
  const theme = useTheme();
  const { systemOfMeasurement } = useSystemOfMeasurement(true);
  const { componentValues, handleInputComponentChange } = useOutletReport();

  const [showBalancingVAVsDiversityModal, setShowBalancingVAVsDiversityModal] =
    useState(false);

  const [showOptimumSpBalancingVAVsModal, setShowOptimumSpBalancingVAVsModal] =
    useState(false);

  const { execute: getBalancingTooltip, data: balancingTooltip } =
    useAsyncQuery<ITooltip>(tooltipService.getById);

  const { execute: getOptimumTooltip, data: optimumTooltip } =
    useAsyncQuery<ITooltip>(tooltipService.getById);

  useEffect(() => {
    const getData = async () => {
      await getBalancingTooltip(34);
      await getOptimumTooltip(35);
    };

    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const row = 12;

  const totalFinalCorrectedColor =
    (componentValues?.totalMax ?? 0) <
      (componentValues?.finalCorrectedArflowACFM ?? 0) ||
    (componentValues?.totalMin ?? 0) >
      (componentValues?.finalCorrectedArflowACFM ?? 0)
      ? theme.palette.error.main
      : undefined;

  const totalFinalCorrectedActualAirflowColor =
    (componentValues.minimumMax ?? 0) <
      (componentValues.actualMinimumCfm ?? 0) ||
    (componentValues.minimumMin ?? 0) > (componentValues.actualMinimumCfm ?? 0)
      ? theme.palette.error.main
      : undefined;

  const totalPercentageColor =
    (componentValues.totalMax ?? 0) <
      (componentValues.finalCorrectedArflowACFM ?? 0) ||
    (componentValues.totalMin ?? 0) >
      (componentValues.finalCorrectedArflowACFM ?? 0)
      ? theme.palette.error.main
      : undefined;

  const totalMinimumColor =
    (componentValues.minimumMax ?? 0) <
      (componentValues.actualMinimumCfm ?? 0) ||
    (componentValues.minimumMin ?? 0) > (componentValues.actualMinimumCfm ?? 0)
      ? theme.palette.error.main
      : undefined;

  const onBlurHandler = async () => {
    await updateStudyHandler();
  };

  const onChangeTotalRequired = (e: any) => {
    const updatedComponent: IOutletReportComponent = {
      ...componentValues,
      requiredMinimumCfm: parseFloat(e.target.value),
    };

    const updatedComponents = components.map((comp) =>
      comp.id === updatedComponent.id ? updatedComponent : comp
    );

    setComponentHandler(updatedComponents);
    handleInputComponentChange(e);
  };

  return (
    <>
      <Grid container>
        <Grid item xs={6}>
          <Grid container spacing={5}>
            <Grid item xs={row}>
              <FormNumeric2
                name="3"
                label={`Total Final Corrected Actual Airflow ${systemOfMeasurement.get(
                  "acfm"
                )}`}
                value={componentValues?.finalCorrectedArflowACFM?.toString()}
                showSkeleton={false}
                fixedDecimalScale={true}
                mode={"read"}
                decimalScale={2}
                color={totalFinalCorrectedColor}
                thousandSeparator={true}
              />
            </Grid>
            <Grid item xs={row}>
              <FormNumeric2
                name="2"
                label={`Total Final Corrected Actual Airflow ${systemOfMeasurement.get(
                  "acfm"
                )} Recorded`}
                value={componentValues?.actualMinimumCfm?.toString()}
                showSkeleton={false}
                fixedDecimalScale={true}
                mode={"read"}
                decimalScale={2}
                color={totalFinalCorrectedActualAirflowColor}
              />
            </Grid>
            <Grid item xs={row}>
              <FormNumeric2
                name="1"
                label={`Total Required ${systemOfMeasurement.get("cfm")}`}
                value={componentValues?.totalRequiredCFM?.toString()}
                showSkeleton={false}
                fixedDecimalScale={true}
                mode={"read"}
                decimalScale={2}
              />
            </Grid>
            <Grid item xs={12} pr={3}>
              <FormNumeric2
                name="requiredMinimumCfm"
                onChange={onChangeTotalRequired}
                onBlur={onBlurHandler}
                label={`Total Required Minimum ${systemOfMeasurement.get(
                  "cfm"
                )}`}
                value={componentValues?.requiredMinimumCfm?.toString()}
                showSkeleton={false}
                fixedDecimalScale={true}
                thousandSeparator={true}
                mode={mode}
                disabled={disableStudy}
                decimalScale={2}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Grid container spacing={5}>
            <Grid item xs={row}>
              <FormNumeric2
                name="12"
                label={`Total ${systemOfMeasurement.get("acfm")} Percentage`}
                value={componentValues?.percentage?.toString()}
                showSkeleton={false}
                suffix="%"
                fixedDecimalScale={true}
                thousandSeparator={true}
                mode={"read"}
                decimalScale={0}
                color={totalPercentageColor}
              />
            </Grid>
            <Grid item xs={row}>
              <FormNumeric2
                name="33"
                label={`Total Minimum ${systemOfMeasurement.get(
                  "acfm"
                )} Percentage`}
                value={componentValues?.minimumCfmPod?.toString()}
                showSkeleton={false}
                suffix="%"
                fixedDecimalScale={true}
                thousandSeparator={true}
                mode={"read"}
                decimalScale={0}
                color={totalMinimumColor}
              />
            </Grid>
            <Grid item xs={8}>
              Balancing a VAV, CAV or FPB System with a Designed Diversity
            </Grid>
            <Grid item xs={4}>
              <Tooltip
                TransitionComponent={Zoom}
                title="Click to open information"
              >
                <span>
                  <IconButton
                    aria-label="delete"
                    size="large"
                    onClick={() => setShowBalancingVAVsDiversityModal(true)}
                  >
                    <InfoIcon />
                  </IconButton>
                </span>
              </Tooltip>
            </Grid>
            <Grid item xs={8}>
              Identifying Optimum Fan System Operating Static Pressure (SP)
              Set-Point when finished balancing any VAV, CAV or FPB System
            </Grid>
            <Grid item xs={4}>
              <Tooltip
                TransitionComponent={Zoom}
                title="Click to open information"
              >
                <span>
                  <IconButton
                    aria-label="delete"
                    size="large"
                    onClick={() => setShowOptimumSpBalancingVAVsModal(true)}
                  >
                    <InfoIcon />
                  </IconButton>
                </span>
              </Tooltip>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <DialogMessagePopup
        title="Balancing VAV/CAV/FPB Diversity"
        // TODO: convert to html, note can be dangerous

        text={
          <div
            dangerouslySetInnerHTML={{ __html: balancingTooltip?.content }}
          />
        }
        showPopup={showBalancingVAVsDiversityModal}
        setShowPopup={setShowBalancingVAVsDiversityModal}
        onSave={() => setShowBalancingVAVsDiversityModal(false)}
        acceptTextButton="Ok"
        hideCancel={true}
        isSubmitting={false}
      />

      <DialogMessagePopup
        title="Optimum Fan System SP Set-Point"
        text={
          <div dangerouslySetInnerHTML={{ __html: optimumTooltip?.content }} />
        }
        showPopup={showOptimumSpBalancingVAVsModal}
        setShowPopup={setShowOptimumSpBalancingVAVsModal}
        onSave={() => setShowOptimumSpBalancingVAVsModal(false)}
        acceptTextButton="Ok"
        hideCancel={true}
        isSubmitting={false}
      />
    </>
  );
};

export default ComponentForm;
