import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { NavLink, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import {
  Grid,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
  Typography,
  Card as MuiCard,
  CardContent,
  Chip as MuiChip,
  Stack,
  Button,
} from "@mui/material";
import { Add as AddIcon } from "@mui/icons-material";
import { spacing, SpacingProps } from "@mui/system";
import { ColumnType } from "src/types/enhancedTable";
import LocalEnhancedTable from "src/components/localTable/LocalTable";
import useAsyncQuery from "src/hooks/useAsyncQuery";
import { GridActionButton } from "src/components/gridControls";
import { plaqueProviderService } from "src/services";
import { IPlaqueProviders } from "src/ts/interfaces";
import { ProviderTypeEnum } from "src/ts/enums/plaquesAndDecals";
import { Permission as PermissionTypes } from "src/ts/enums";
import { useLog, usePermissions } from "src/hooks";
import MatchWordConfirmModal from "src/components/modals/MatchWordConfirmModal";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

let providerTypes: Record<string, string> = {};
providerTypes[ProviderTypeEnum.Plaque] = "Plaque";
providerTypes[ProviderTypeEnum.Decals] = "Decals";

interface ChipProps extends SpacingProps {
  component?: React.ElementType;
  href?: string;
  icon?: JSX.Element | null;
}

const Chip = styled(MuiChip)<ChipProps>(spacing);

const Providers = () => {
  let navigate = useNavigate();
  const { execute, data, isLoading } = useAsyncQuery<IPlaqueProviders[]>(
    plaqueProviderService.getAll
  );
  const [refreshPage, setRefreshPage] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [currentRow, setCurrentRow] = useState<IPlaqueProviders>();
  const { log } = useLog();
  const [deleteModal, setDeleteModal] = useState(false);

  useEffect(() => {
    const getData = async () => {
      await execute();
    };

    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshPage]);

  const selectDeleteHandler = (row: IPlaqueProviders) => {
    setCurrentRow(row);
    setDeleteModal(true);
  };

  const deleteProvider = async () => {
    if (currentRow?.id) {
      try {
        await plaqueProviderService.remove(currentRow?.id ?? 0);
        log.info("The provider was succesfully deleted");
        setRefreshPage(!refreshPage);
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      }
    }
  };

  const newHandler = () => {
    navigate("/app/provider/0");
  };

  const { fullAccess } = usePermissions(PermissionTypes.Plaque_Decals);

  const columns: ColumnType[] = [
    {
      id: "id",
      label: "#",
      type: "string",
      sort: true,
    },
    {
      id: "name",
      label: "Name",
      type: "string",
      sort: true,
    },
    {
      id: "providerType",
      label: "Provider Type",
      type: "custom",
      sort: true,
      callback: (row: IPlaqueProviders) => {
        return (
          <Chip
            label={providerTypes[row.providerType]}
            color={
              row.providerType === ProviderTypeEnum.Plaque
                ? "primary"
                : "warning"
            }
            size="small"
          />
        );
      },
    },
    {
      id: "getFullAddress",
      label: "Address",
      type: "string",
      sort: true,
    },
    {
      id: "x",
      label: "Actions",
      type: "custom",
      sort: false,
      callback: (row: IPlaqueProviders) => (
        <div>
          <Stack direction="row">
            <GridActionButton
              type={fullAccess ? "edit" : "view"}
              onClick={() => {
                navigate(`/app/provider/${row.id}`);
              }}
              tooltip={fullAccess ? "Edit Provider" : "View Provider"}
            />
            <GridActionButton
              type={fullAccess ? "items" : "view"}
              onClick={() => {
                navigate(`/app/plaques/${row.id}`);
              }}
              tooltip={fullAccess ? "Edit Plaque/Decals" : "View Plaque/Decals"}
            />
            {fullAccess && (
              <GridActionButton
                type="delete"
                onClick={() => selectDeleteHandler(row)}
                tooltip="Delete"
              />
            )}
          </Stack>
        </div>
      ),
    },
  ];

  return (
    <>
      <Helmet title="Building Subtype" />
      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Providers
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} to="/">
              Dashboard
            </Link>
            <Typography>Providers</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item>
          <div>
            {fullAccess && (
              <Button onClick={newHandler} variant="contained" color="primary">
                <AddIcon />
                {`New Provider`}
              </Button>
            )}
          </div>
        </Grid>
      </Grid>

      <Divider my={6} />
      <Card mb={6}>
        <CardContent>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <LocalEnhancedTable<IPlaqueProviders>
                refreshGrid={true}
                columns={columns}
                data={data ?? []}
                showSkeleton={isLoading}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      <MatchWordConfirmModal
        onConfirm={deleteProvider}
        text={`Are you certain you want to delete this item?`}
        matchWord={"DELETE"}
        isDialogOpen={deleteModal}
        setIsDialogOpen={setDeleteModal}
        isSubmitting={false}
      />
    </>
  );
};

export default Providers;
