import React from "react";
import { matchPath } from "react-router-dom";

import { SidebarItemsType } from "src/types/sidebar";
import SidebarNavListItem from "./SidebarNavListItem";
import SidebarNavList from "./SidebarNavList";
import useAuth from "src/hooks/useAuth";
import { Permissions } from "src/types/authorizationData";
import { DemoOptions } from "src/constants";

type ReduceChildRoutesProps = {
  depth: number;
  page: SidebarItemsType;
  items: JSX.Element[];
  currentRoute: string;
};

const reduceChildRoutes = (props: ReduceChildRoutesProps) => {
  const { items, page, depth, currentRoute } = props;
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { user } = useAuth();
  const children =
    page.children === undefined
      ? page.children
      : page.children.filter(
          (x) =>
            (((user?.permissions !== undefined &&
              user?.permissions.filter(
                (y: Permissions) =>
                  x.permissionMatrix &&
                  Object.values(x.permissionMatrix).some(
                    (v) =>
                      v.toString().toLowerCase() ===
                      y.code.toString().toLowerCase()
                  ) &&
                  (y.readOnly || y.fullAccess)
              ).length > 0) ||
              x.permissionMatrix === undefined) &&
              x.permissionTechician === undefined) ||
            user?.[x.permissionTechician ?? ""] === "true"
        );
  if (children) {
    const open = page.href
      ? !!matchPath(
          {
            path: page.href,
            end: false,
          },
          currentRoute
        )
      : false;

    items.push(
      <SidebarNavListItem
        depth={depth}
        icon={page.icon}
        key={page.title}
        badge={page.badge}
        open={!!open}
        title={page.title}
        href={page.href}
        disabled={
          user?.permissions !== undefined &&
          user?.permissions.filter(
            (y: Permissions) =>
              page.permissionMatrix &&
              Object.values(page.permissionMatrix).some((v) => v === y.code) &&
              y.demo === DemoOptions.Disabled &&
              (y.readOnly || y.fullAccess)
          ).length > 0
            ? true
            : false
        }
      >
        <SidebarNavList depth={depth + 1} pages={children} />
      </SidebarNavListItem>
    );
  } else {
    items.push(
      <SidebarNavListItem
        depth={depth}
        href={page.href}
        icon={page.icon}
        key={page.title}
        badge={page.badge}
        title={page.title}
        disabled={
          user?.permissions !== undefined &&
          user?.permissions.filter(
            (y: Permissions) =>
              page.permissionMatrix &&
              Object.values(page.permissionMatrix).some((v) => v === y.code) &&
              y.demo === DemoOptions.Disabled &&
              (y.readOnly || y.fullAccess)
          ).length > 0
            ? true
            : false
        }
      />
    );
  }

  return items;
};

export default reduceChildRoutes;
