import axios from "src/utils/axios";
import {
  IChillerWeigtedTest,
  IChillerWeigtedTestItem,
} from "src/ts/interfaces";

const getByChillerId = (chillerId: number) => {
  return axios.get<IChillerWeigtedTest[]>(
    `ChillerWeigtedTest/Chiller/${chillerId}`
  );
};

const addChillerWeigtedTest = (test: IChillerWeigtedTest) => {
  return axios.post(`ChillerWeigtedTest`, test);
};

const getChillerWeigtedTestItem = (weigtedTestId: number) => {
  return axios.get<IChillerWeigtedTestItem[]>(
    `ChillerWeigtedTest/items?testId=${weigtedTestId}`
  );
};

const deleteChillerWeigtedTestItem = (itemId: number) => {
  return axios.delete(`ChillerWeigtedTest/item/${itemId}`);
};

const deleteChillerWeigtedTest = (itemId: number) => {
  return axios.delete(`ChillerWeigtedTest/${itemId}`);
};

const updateChillerWeigtedTestItem = (item: IChillerWeigtedTestItem) => {
  return axios.put(`ChillerWeigtedTest/item/${item.id}`, item);
};

const updateChillerWeigtedTests = (tests: IChillerWeigtedTest[]) => {
  return axios.put(`ChillerWeigtedTest`, tests);
};

const addChillerWeigtedTestItem = (item: IChillerWeigtedTestItem) => {
  return axios.post<IChillerWeigtedTestItem>(`ChillerWeigtedTest/item`, item);
};

const updateChillerWeigtedTest = (test: IChillerWeigtedTest) => {
  return axios.put(`ChillerWeigtedTest/${test.id}`, test);
};

const chillerWeigtedTestService = {
  getByChillerId,
  addChillerWeigtedTest,
  getChillerWeigtedTestItem,
  deleteChillerWeigtedTestItem,
  deleteChillerWeigtedTest,
  updateChillerWeigtedTestItem,
  updateChillerWeigtedTests,
  addChillerWeigtedTestItem,
  updateChillerWeigtedTest,
};

export default chillerWeigtedTestService;
