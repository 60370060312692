import { getFilter } from "src/utils/utils";
import axios from "../utils/axios";
import {
  IRecycleBinBuildingDataPage,
  IRecycleBinProjectDataPage,
  IRecycleBinSearch,
  IRecycleBinStudyDataPage,
} from "src/ts/interfaces/recycleBin";

const getProjects = async (
  filter: IRecycleBinSearch,
  getQuery?: (filters: Record<string, any>) => string
) => {
  const queryString = getQuery ? getQuery(filter) : getFilter(filter);

  return await axios.get<IRecycleBinProjectDataPage>(
    `GetProject/GetDeleted?${queryString}`
  );
};

const getStudies = async (
  filter: IRecycleBinSearch,
  getQuery?: (filters: Record<string, any>) => string
) => {
  const queryString = getQuery ? getQuery(filter) : getFilter(filter);

  return await axios.get<IRecycleBinStudyDataPage>(
    `ProjectReport/GetDeleted?${queryString}`
  );
};

const getBuildings = async (
  filter: IRecycleBinSearch,
  getQuery?: (filters: Record<string, any>) => string
) => {
  const queryString = getQuery ? getQuery(filter) : getFilter(filter);

  return await axios.get<IRecycleBinBuildingDataPage>(
    `Building/GetDeleted?${queryString}`
  );
};

const deleteProject = (projectId: Number) => {
  return axios.delete(`RecycleBin/DeletePermanently?projectId=${projectId}`);
};

const recoverProject = (projectId: Number) => {
  return axios.put(`RecycleBin/Reopen?projectId=${projectId}`);
};

const deleteStudy = (projectId: Number) => {
  return axios.delete(
    `RecycleBin/DeletePermanentlyStudy?projectId=${projectId}`
  );
};

const recoverStudy = (projectId: Number) => {
  return axios.put(`RecycleBin/RecoverStudy?projectId=${projectId}`);
};

const deleteBuilding = (projectId: Number) => {
  return axios.delete(
    `RecycleBin/DeletePermanentlyBuilding?projectId=${projectId}`
  );
};

const recoverBuilding = (projectId: Number) => {
  return axios.put(`RecycleBin/RecoverBuilding?projectId=${projectId}`);
};

const recycleBinService = {
  getProjects,
  getStudies,
  getBuildings,
  deleteProject,
  recoverProject,
  deleteStudy,
  recoverStudy,
  deleteBuilding,
  recoverBuilding,
};

export default recycleBinService;
