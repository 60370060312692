import { Button } from "@mui/material";
import { Stack } from "@mui/system";
import { Dispatch, SetStateAction } from "react";
import Popup from "src/components/Popup";

interface IIncompleteDataDialogProps {
  setOpenIncompleteDataDialog: Dispatch<SetStateAction<boolean>>;
  openIncompleteDataDialog: boolean;
  yesButtonClickHandler: () => void;
  noButtonClickHandler?: () => void;
}

const IncompleteDataDialog = (props: IIncompleteDataDialogProps) => {
  const handleIncompleteDataDialogClose = () => {
    props.setOpenIncompleteDataDialog(false);
    if (props.noButtonClickHandler) props.noButtonClickHandler();
  };

  return (
    <Popup
      openPopup={props.openIncompleteDataDialog}
      setOpenPopup={props.setOpenIncompleteDataDialog}
      onClose={handleIncompleteDataDialogClose}
      title="Form is incomplete"
      titleStyle={{ color: "red" }}
    >
      <>
        <p>There are some required fields that need to be reviewed.</p>
        <p>
          It's understood that continuing forward without filling in the
          required data will result in an incomplete final Study.
        </p>
        <p>Do you wish to still continue forward?</p>
      </>
      <Stack direction="row" spacing={3}>
        <Button
          variant="outlined"
          color="secondary"
          onClick={handleIncompleteDataDialogClose}
        >
          No
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={props.yesButtonClickHandler}
        >
          Yes
        </Button>
      </Stack>
    </Popup>
  );
};

export default IncompleteDataDialog;
