import { IReportGroup } from "src/ts/interfaces";
import axios from "../utils/axios";

const getProjectReportGrupo = async (projectId: number) => {
  return axios.get<IReportGroup>(
    `ProjectReportGroup/ReportsGroup/${projectId}`
  );
};

const projectReportGroupService = {
  getProjectReportGrupo,
};

export default projectReportGroupService;
