import { ChillerStudyProvider } from "../context/ChillerStudyContext";
import ChillerStep5 from "./ChillerStep5";
const Index = () => {
  return (
    <ChillerStudyProvider>
      <ChillerStep5 />
    </ChillerStudyProvider>
  );
};

export default Index;
