import { IKeyValue } from "src/ts/interfaces";
import axios from "../utils/axios";
import { IIDType } from "src/ts/interfaces/technician";

const getIdTypes = () => {
  return new Promise<IKeyValue<number, string>[]>((resolve, reject) => {
    axios
      .get<Array<IIDType>>("IdType")
      .then((response) => {
        const keyValuePair: IKeyValue<number, string>[] = response.data.map(
          (types) => {
            return {
              key: types.id,
              value: types.name,
            };
          }
        );

        resolve(keyValuePair);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const IdTypeService = {
  getIdTypes,
};

export default IdTypeService;
