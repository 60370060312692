import { ISysAdminDocument, IUploadFile } from "../ts/interfaces";
import axios from "../utils/axios";

const getAll = () => {
  return axios.get<Array<ISysAdminDocument>>("/sysAdminDocuments/documents");
};

const uploadFile = async (file: IUploadFile) => {
  let bodyFormData = new FormData();
  bodyFormData.append("fileForUpload[0]", file.content);
  bodyFormData.append("key[type]", file.documentType);
  bodyFormData.append("key[name]", file.name ?? "null");

  return await axios({
    method: "post",
    url: "/sysAdminDocuments/document",
    data: bodyFormData,
    headers: { "Content-Type": "multipart/form-data" },
  });
};

const remove = async (documentId: string) => {
  return axios.delete(`/sysAdminDocuments?id=${documentId}`);
};

const downloadFile = async (documentId: number) => {
  return axios.get(`SysAdminDocuments/file?id=${documentId}`);
};

const ashraeFile = () => {
  return axios.get(`SysAdminDocuments/ashraeFile`);
};

const updateTypeDocument = (id: string, data: ISysAdminDocument) => {
  return axios.put(`SysAdminDocuments/${id}`, data);
};

const getTrainingDocuments = () => {
  return axios.get<ISysAdminDocument[]>(`SysAdminDocuments/trainingDoc`);
};

const downloadTrainingDocument = (documentId: number) => {
  return axios.get(`SysAdminDocuments/trainingFile?id=${documentId}`);
};

const getPunchList = async () => {
  return axios.get(`SysAdminDocuments/pretestingAHU`);
};

const downloadSampleReportFile = async (documentName: string) => {
  return axios.get(`SysAdminDocuments/sampleReport?name=${documentName}`);
};

const SysAdminDocumentService = {
  getAll,
  uploadFile,
  remove,
  downloadFile,
  ashraeFile,
  updateTypeDocument,
  getTrainingDocuments,
  downloadTrainingDocument,
  getPunchList,
  downloadSampleReportFile,
};

export default SysAdminDocumentService;
