import { NumericFormat } from "react-number-format";

interface CurrencyFormarProp {
  value?: number;
}

const CurrencyFormat = (props: CurrencyFormarProp) => {
  const { value } = props;

  return (
    <NumericFormat
      displayType="text"
      value={value ?? 0}
      thousandSeparator=","
      prefix="$"
      decimalScale={2}
      fixedDecimalScale
    />
  );
};

export default CurrencyFormat;
