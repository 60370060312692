import { Grid } from "@mui/material";
import {
  FormNumeric2,
  FormRadioGroup,
  FormText,
} from "src/components/formControls";
import { Mode } from "src/ts/types";
import useChillerStudy from "../hooks/useChillerStudy";

const refClassOptions = [
  { key: "HFC", value: "HFC" },
  { key: "CFC", value: "CFC" },
  { key: "HCFC", value: "HCFC" },
  { key: "HC", value: "HC" },
  { key: "NH3", value: "NH3" },
  { key: "CO2", value: "CO2" },
];

interface Props {
  mode: Mode;
  disableStudy: boolean;
  isLoading: boolean;
}

const CondenserForm = ({ mode, disableStudy, isLoading }: Props) => {
  const {
    evaporatorValues: values,
    handleEvaporatorInputChange: handleInputChange,
    evaporatorErros: errors,
  } = useChillerStudy();
  const row = 12;

  return (
    <Grid container spacing={5}>
      <Grid item xs={row}>
        <FormText
          name="manufacturer"
          label="Chiller Manufacturer"
          value={values.manufacturer ?? ""}
          onChange={handleInputChange}
          error={errors.manufacturer}
          showSkeleton={isLoading}
          mode={mode}
          disabled={disableStudy}
        />
      </Grid>
      <Grid item xs={row}>
        <FormText
          name="model"
          label="Model #"
          value={values?.model ?? ""}
          onChange={handleInputChange}
          error={errors.model}
          showSkeleton={isLoading}
          mode={mode}
          disabled={disableStudy}
        />
      </Grid>
      <Grid item xs={row}>
        <FormText
          name="serial"
          label="Serial #"
          value={values.serial ?? ""}
          onChange={handleInputChange}
          error={errors.serial}
          showSkeleton={isLoading}
          mode={mode}
          disabled={disableStudy}
        />
      </Grid>
      <Grid item xs={row}>
        <FormRadioGroup
          label="Refrigerant Class"
          name="refrigerantClass"
          onChange={handleInputChange}
          items={refClassOptions}
          row
          value={values.refrigerantClass ?? "HFC"}
          defaultValue={"HFC"}
          mode={mode}
          disabled={disableStudy}
          showSkeleton={isLoading}
        />
      </Grid>
      <Grid item xs={row}>
        <FormText
          name="refrigerantType"
          label="Refrigerant Type"
          value={values.refrigerantType ?? ""}
          onChange={handleInputChange}
          error={errors.refrigerantType}
          showSkeleton={isLoading}
          mode={mode}
          disabled={disableStudy}
        />
      </Grid>
      <Grid item xs={row}>
        <FormNumeric2
          name="ratedFullLoadCapacityTons"
          label="Nameplate Rated Full-Load Capacity (Tons)"
          value={values.ratedFullLoadCapacityTons}
          onChange={handleInputChange}
          showSkeleton={isLoading}
          mode={mode}
          disabled={disableStudy}
          decimalScale={3}
          thousandSeparator={true}
          maxValue={1000000000}
          fixedDecimalScale={true}
        />
      </Grid>
      <Grid item xs={row}>
        <FormNumeric2
          name="ratedFullLoadEfficiencyKWTon"
          label="Nameplate Rated Full-Load Efficiency (kW/ton)"
          value={values.ratedFullLoadEfficiencyKWTon}
          onChange={handleInputChange}
          showSkeleton={isLoading}
          mode={mode}
          disabled={disableStudy}
          decimalScale={3}
          thousandSeparator={true}
          maxValue={1000000000}
          fixedDecimalScale={true}
        />
      </Grid>
      <Grid item xs={row}>
        <FormText
          name="optionsCode"
          label="Options Code"
          value={values.optionsCode ?? ""}
          onChange={handleInputChange}
          error={errors.optionsCode}
          showSkeleton={isLoading}
          mode={mode}
          disabled={disableStudy}
        />
      </Grid>
      <Grid item xs={row}>
        <FormText
          name="additionalInformation"
          label="Additional Information"
          value={values.additionalInformation ?? ""}
          onChange={handleInputChange}
          error={errors.additionalInformation}
          showSkeleton={isLoading}
          mode={mode}
          disabled={disableStudy}
        />
      </Grid>
    </Grid>
  );
};

export default CondenserForm;
