import { useEffect, useState } from "react";
import { Stack, Typography } from "@mui/material";
import { ColumnType } from "src/types/enhancedTable";
import { useTable } from "src/hooks/useTable";
import { FilterType } from "./types/FilterTypes";
import useLog from "src/hooks/useLog";
import useLoading from "src/hooks/useLoading";

import { TestimonialSurveyAll } from "src/ts/interfaces/testimonial";
import { getQueryString } from "../history/utils/common";
import testimonialSurveyService from "src/services/testimonialSurveyService";
import { FormRaitingStarts } from "src/components/formControls";
import { GridActionButton } from "src/components/gridControls";
import RatingInformation from "../projectOutForBid/Components/RatingInformationPopUp";

interface CreatedtProps {
  filters: FilterType;
  refresh: boolean;
  setIsRefresh: React.Dispatch<React.SetStateAction<boolean>>;
}

function Authorized(props: CreatedtProps) {
  const [refresh, setRefresh] = useState(false);
  const { log } = useLog();
  const { isLoading } = useLoading();
  let index = 0;

  const getColumns = (
    setShowRatingPopUp: (val: boolean) => void,
    setCurrentId: (val: number) => void
  ): ColumnType[] => [
    {
      id: "id",
      label: "#",
      type: "custom",
      sort: false,
      hide: true,
      align: "center",
      callback: (row: TestimonialSurveyAll) => {
        index = index + 1;
        return (
          <>
            <Stack ml={7}>
              <Typography> {index + page * 10}</Typography>
            </Stack>
          </>
        );
      },
    },
    {
      id: "userName",
      label: "User Name",
      type: "string",
      sort: true,
    },
    {
      id: "companyName",
      label: "Company",
      type: "string",
      sort: true,
    },
    {
      id: "projectName",
      label: "Project Name",
      type: "string",
      sort: true,
    },
    {
      id: "updatedDate",
      label: "Updated Date",
      type: "date",
      format: "MM/DD/YYYY",
      sort: true,
    },
    {
      id: "rating",
      label: "Rating",
      type: "custom",
      sort: false,
      callback: (row: TestimonialSurveyAll) => {
        return (
          <>
            <FormRaitingStarts value={Math.round(row.rating ?? 0)} />
          </>
        );
      },
    },
    {
      id: "action",
      label: "Action",
      type: "custom",
      sort: false,
      callback: (row: TestimonialSurveyAll) => {
        return (
          <div>
            <Stack direction="row">
              <GridActionButton
                type="forward"
                onClick={() => {
                  setShowRatingPopUp(true);
                  setCurrentId(row.id);
                }}
                tooltip=""
              />
            </Stack>
          </div>
        );
      },
    },
  ];

  const [showRatingPopUp, setShowRatingPopUp] = useState(false);
  const [currentId, setCurrentId] = useState(0);

  let columns = getColumns(setShowRatingPopUp, setCurrentId);

  const { Table, setRowsPerPage, page, setDataSet, setCount, orderBy, order } =
    useTable(columns, { cellCheckBox: false });

  const handleRefresh = () => {
    setRefresh(!refresh);
  };

  useEffect(() => {
    const getData = async () => {
      try {
        props.setIsRefresh(true);
        const request = await testimonialSurveyService.getAll(
          {
            page: page + 1,
            filter: props.filters.filter,
            sortColumn: orderBy,
            sortDec: order === "desc" ? true : false,
            sendToWebPage: 4,
            start: props.filters.start,
            end: props.filters.end,
            authorizeAppearWebPage: true,
          },
          getQueryString
        );

        setCount(request.data.count);
        setDataSet(request.data.data);
        setRowsPerPage(10);
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      } finally {
        props.setIsRefresh(false);
      }
    };
    if (!showRatingPopUp) getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    page,
    orderBy,
    order,
    setCount,
    setDataSet,
    setRowsPerPage,
    props.refresh,
    showRatingPopUp,
  ]);

  useEffect(() => {
    if (!isLoading) handleRefresh();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  return (
    <>
      <Table />

      {showRatingPopUp && (
        <RatingInformation
          showRatingContractorPopUp={showRatingPopUp}
          setShowRatingContractorPopUp={setShowRatingPopUp}
          testimonialId={currentId}
        />
      )}
    </>
  );
}

export default Authorized;
