import {
  IDisputeCreate,
  IDisputeData,
  IDisputeMessage,
  IPostImage,
  IPostMeesage,
} from "src/ts/interfaces/dispute";
import axios from "src/utils/axios";

const getDispute = async (projectId: number) => {
  return axios.get<IDisputeData>(`dispute/${projectId}`);
};

const postCreateDispute = async (data: IDisputeCreate) => {
  return axios.post(`dispute/create`, data);
};

const resolve = async (disputeId: number, comments: string) => {
  return axios.post(`dispute/${disputeId}/${comments}/resolve`);
};

const getDisputeMessage = async (idDispute: number) => {
  return axios.get<IDisputeMessage[]>(`dispute/message/${idDispute}`);
};

const postMesseage = async (data: IPostMeesage) => {
  return axios.post(`dispute/message`, data);
};

const postImage = async (file: IPostImage) => {
  let bodyFormData = new FormData();
  bodyFormData.append("fileForUpload[0]", file.content);
  bodyFormData.append("key[type]", file.documentType);
  bodyFormData.append("key[disputeId]", file.disputeId);

  return await axios({
    method: "post",
    url: "Dispute/document",
    data: bodyFormData,
    headers: { "Content-Type": "multipart/form-data" },
  });
};

const getShowImage = async (id: number) => {
  return axios.get(`Dispute/file?id=${id}`);
};

const disputeService = {
  getDispute,
  postCreateDispute,
  getDisputeMessage,
  postMesseage,
  postImage,
  getShowImage,
  resolve,
};

export default disputeService;
