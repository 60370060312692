import { getAllDistinct } from "src/ts/interfaces/catalogs/youtubeTrainingVideos";
import axios from "src/utils/axios";

const getAll = () => {
  return axios.get("YoutubeTraining/getAllDistinct");
};

const getAllReports = () => {
  return axios.get("YoutubeTraining/getAllDistinctReport");
};

const getDataRole = (role: string) => {
  return axios.get(`YoutubeTraining?page=${role}`);
};

const putYoutubeVideo = (data: getAllDistinct[]) => {
  return axios.put("YoutubeTraining", data);
};

const youtubeVideos = { getAll, getDataRole, putYoutubeVideo, getAllReports };

export default youtubeVideos;
