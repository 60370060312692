import { IConversionsList } from "src/ts/interfaces/catalogs";
import axios from "../utils/axios";

const getAll = () => {
  return axios.get<IConversionsList[]>("Conversions");
};

const ConversionsService = {
  getAll,
};

export default ConversionsService;
