import React, { useEffect, useState } from "react";
import { Link } from "@mui/material";
import { Box } from "@mui/system";
import { PictureAsPdf } from "@mui/icons-material";
import { ColumnType } from "src/types/enhancedTable";
import { useForm, useAsyncQuery, useLog, useAuth } from "src/hooks";
import LocalEnhancedTable from "src/components/localTable/LocalTable";
import { GridActionButton } from "src/components/gridControls";
import documentLibraryService from "src/services/documentLibraryService";
import {
  DocumentLibraryDto,
  ISelectedUsers,
  TableData,
} from "src/ts/interfaces";
import { FolderIcon } from "src/components/icons";
import moment from "moment";
import { convertUTCDateToLocalDate } from "src/utils/utils";
import FileUtils from "src/utils/file";
import MatchWordConfirmModal from "src/components/modals/MatchWordConfirmModal";
import { ROLES, projectStatus as projectStatusConstant } from "src/constants";
import ShareByEmailPopUpComponent from "src/components/others/ShareByEmailPopUpComponent";

const initialValues = {
  id: 0,
  name: "",
  statusId: "",
};

const getColumns = (
  gotoHandler: (
    projectId: number,
    parent: number | null | undefined
  ) => Promise<void>,
  downloadDocument: (row: DocumentLibraryDto) => void,
  handleDeleteDocument: (row: DocumentLibraryDto) => void,
  isRootLevel: boolean,
  disable: boolean,
  isProjectCompleted: boolean,
  viewMode: boolean,
  userRole: string,
  projectStatus: string,
  shareDocumentsByEmail: (row: DocumentLibraryDto) => void,
  pastDate: boolean
): ColumnType[] => {
  return [
    {
      id: "type",
      label: "",
      type: "custom",
      sort: false,
      callback: (row: DocumentLibraryDto) => {
        return row.type === "Folder" ? <FolderIcon /> : <PictureAsPdf />;
      },
    },
    {
      id: "name",
      label: "Name",
      type: "custom",
      sort: true,
      callback: (row: DocumentLibraryDto) => {
        return (
          <>
            {row.type === "Folder" ? (
              <Link
                component="button"
                variant="body2"
                onClick={async () => {
                  await gotoHandler(row.projectId, row.id);
                }}
              >
                {row.name}
              </Link>
            ) : (
              <>{row.name}</>
            )}
          </>
        );
      },
    },
    {
      id: "size",
      label: "Size",
      type: "custom",
      sort: true,
      callback: (row: DocumentLibraryDto) => {
        return (
          <>
            {row.type === "Folder" ? "-" : `${(row.size / 1024).toFixed(2)} KB`}
          </>
        );
      },
    },
    {
      id: "createdDate",
      label: "Date created",
      type: "custom",
      sort: true,
      callback: (row: DocumentLibraryDto) =>
        `${moment(convertUTCDateToLocalDate(row.createdDate)).fromNow()}`,
    },
    {
      id: "code",
      label: "Created by",
      type: "custom",
      sort: true,
      callback: (row: DocumentLibraryDto) =>
        `${row.userPerson?.name} ${row.userPerson?.lastName}`,
    },
    {
      id: "na",
      label: "Actions",
      type: "custom",
      sort: false,
      callback: (row: DocumentLibraryDto) => {
        return (
          <div>
            {row.type === "File" && (
              <Box>
                <GridActionButton
                  type="download"
                  onClick={() => downloadDocument(row)}
                  tooltip="Download"
                />
                {userRole === ROLES.Architect &&
                  projectStatus === projectStatusConstant.Contest.name &&
                  pastDate && (
                    <GridActionButton
                      type="email"
                      onClick={() => {
                        shareDocumentsByEmail(row);
                      }}
                      tooltip="Forward"
                    />
                  )}
                {!row.isReadOnly && !isProjectCompleted && (
                  <GridActionButton
                    type="delete"
                    onClick={() => handleDeleteDocument(row)}
                    tooltip="Delete"
                  />
                )}
              </Box>
            )}
            {row.type === "Folder" &&
              !isRootLevel &&
              !row.isReadOnly &&
              !isProjectCompleted &&
              !viewMode && (
                <GridActionButton
                  type="delete"
                  onClick={() => handleDeleteDocument(row)}
                  tooltip="Delete"
                />
              )}
          </div>
        );
      },
    },
  ];
};

interface Props {
  projectId: number;
  setParent: (value: number | null | undefined) => void;
  parent: number | null | undefined;
  refreshGrid: boolean;
  disable: boolean;
  setRefreshGrid: (value: boolean) => void;
  isProjectCompleted: boolean;
  viewMode: boolean;
  projectStatus: string;
  contestDueDate: Date | null;
}

const DocumentLibraryGrid = (props: Props) => {
  const {
    projectId,
    setParent,
    parent,
    refreshGrid,
    setRefreshGrid,
    disable,
    isProjectCompleted,
    viewMode,
    projectStatus,
    contestDueDate,
  } = props;

  const { user } = useAuth();
  const [currentRow, setCurrentRow] = useState<DocumentLibraryDto>();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [pastDate, setPastDate] = useState(true);
  const { log } = useLog();
  const { execute, data } = useAsyncQuery<DocumentLibraryDto[]>(
    documentLibraryService.getByProjectIdAndParentId,
    {
      successfulMessage: "xxx",
      errorMessage: "xxx",
    }
  );

  const { values: filterValues } = useForm(initialValues, true, []);
  const [showShareDocumentsByEmailPopup, setShareDocumentsByEmailPopup] =
    useState(false);

  useEffect(() => {
    const getData = async () => {
      await gotoHandler(projectId, parent);

      if (contestDueDate) {
        const contestDueDateFormat = new Date(contestDueDate);
        const currentDate = new Date();
        setPastDate(currentDate > contestDueDateFormat ? false : true);
      }
    };

    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId, refreshGrid, parent]);

  const gotoHandler = async (
    projectId: number,
    parent: number | null | undefined
  ) => {
    await execute(projectId, parent);
    setParent(parent);
  };

  const downloadDocument = async (row: DocumentLibraryDto) => {
    if (row && row.id) {
      const response = await documentLibraryService.downloadUserFile(
        Number(row.id)
      );

      let fileName = row.name;
      FileUtils.downloadFile2(response.data, fileName, "application/pdf");
    }
  };

  const handleDeleteDocument = (row: DocumentLibraryDto) => {
    setCurrentRow(row);
    setShowDeleteModal(true);
  };

  const deleteDocument = async () => {
    if (currentRow && currentRow.id) {
      try {
        if (currentRow.type === "File") {
          await documentLibraryService.deleteFile(currentRow.id);
        } else if (currentRow.type === "Folder") {
          await documentLibraryService.deleteFolder(currentRow.id);
        }

        setRefreshGrid(!refreshGrid);
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      }
    }
  };

  const shareDocumentsByEmail = async (row: DocumentLibraryDto) => {
    setCurrentRow(row);
    setShareDocumentsByEmailPopup(true);
  };

  const columns = getColumns(
    gotoHandler,
    downloadDocument,
    handleDeleteDocument,
    parent === null || parent === undefined,
    disable,
    isProjectCompleted,
    viewMode,
    user?.role,
    projectStatus,
    shareDocumentsByEmail,
    pastDate
  );

  const handleSendEmail = async (dataTable: TableData[]) => {
    const users = dataTable.map((data) => {
      let user: ISelectedUsers = {
        id: data.id ?? 0,
        email: data.email ?? "",
        name: data.name ?? "",
        isTeamMemeber: data.userType === "LicenseedUser" ? false : true,
      };

      return user;
    });

    const response = await documentLibraryService.send(
      currentRow?.id ?? 0,
      users
    );
    var emailResponse = response.data;
    for (let i = 0; i < emailResponse.length; i++) {
      if (emailResponse[i].wasSuccessful)
        log.info(emailResponse[i].email + " - " + emailResponse[i].message);
      else log.error(emailResponse[i].email + " - " + emailResponse[i].message);
    }
  };

  return (
    <>
      <LocalEnhancedTable<DocumentLibraryDto>
        refreshGrid={refreshGrid}
        columns={columns}
        data={data}
        query={filterValues.name}
        defaultRowPerPage={25}
        showSkeleton={false}
        defaultSortColumn="name"
        orderColumn="asc"
      />
      <MatchWordConfirmModal
        onConfirm={deleteDocument}
        text={`Are you certain you want to delete this item?`}
        matchWord={"delete"}
        isDialogOpen={showDeleteModal}
        setIsDialogOpen={setShowDeleteModal}
      />
      <ShareByEmailPopUpComponent
        sendbyEmailShow={showShareDocumentsByEmailPopup}
        setSendbyEmailShow={setShareDocumentsByEmailPopup}
        sendEmailFunction={handleSendEmail}
        hideLogSuccess={true}
        projectId={projectId}
        sendToContractors={true}
      />
    </>
  );
};

export default DocumentLibraryGrid;
