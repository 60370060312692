import React from "react";
import { TableHead, TableRow, TableCell, TableSortLabel } from "@mui/material";
import { ColumnType } from "src/types/enhancedTable";
import { FormCheckBox } from "../formControls";

type EnhancedTableHeadProps = {
  order: "desc" | "asc";
  orderBy: string;
  onRequestSort: (e: any, property: string) => void;
  columns: ColumnType[];
  tableCellCheckBox?: boolean;
  toggleSelectionAll: () => void;
  isAllSelected: boolean;
  subItems?: string;
  onlyOneSelected?: boolean;
};

const EnhancedTableHead: React.FC<EnhancedTableHeadProps> = (props) => {
  const {
    order,
    orderBy,
    onRequestSort,
    columns,
    tableCellCheckBox,
    toggleSelectionAll,
    isAllSelected,
    subItems,
    onlyOneSelected,
  } = props;
  const createSortHandler = (property: string) => (event: any) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <>
          {tableCellCheckBox ? (
            <TableCell padding="checkbox">
              {!onlyOneSelected && (
                <FormCheckBox
                  value={isAllSelected}
                  onChange={() => {
                    toggleSelectionAll();
                  }}
                  name={""}
                  label={""}
                />
              )}
            </TableCell>
          ) : null}
          {columns
            .filter((col) => !col.hideColumn)
            .map((headCell, index) =>
              headCell.sort ? (
                <>
                  {subItems && index === 0 && <TableCell></TableCell>}
                  <TableCell
                    key={index}
                    sortDirection={orderBy === headCell.id ? order : false}
                    sx={[
                      headCell.width !== null && { width: headCell.width },
                      headCell.minWidth !== null && {
                        minWidth: headCell.minWidth,
                      },
                      headCell.maxWidth !== null && {
                        maxWidth: headCell.maxWidth,
                      },
                    ]}
                    align={headCell?.align || "left"}
                  >
                    <TableSortLabel
                      active={orderBy === headCell.id}
                      direction={orderBy === headCell.id ? order : "asc"}
                      onClick={createSortHandler(headCell.id)}
                    >
                      {headCell.label}
                    </TableSortLabel>
                  </TableCell>
                </>
              ) : (
                <>
                  {subItems && index === 0 && <TableCell></TableCell>}
                  <TableCell
                    key={headCell.id}
                    sx={[
                      headCell.width !== null && { width: headCell.width },
                      headCell.minWidth !== null && {
                        minWidth: headCell.minWidth,
                      },
                    ]}
                    align={headCell?.align || "left"}
                  >
                    {headCell.label}
                  </TableCell>
                </>
              )
            )}
        </>
      </TableRow>
    </TableHead>
  );
};

export default EnhancedTableHead;
