import axios from "src/utils/axios";
import { IVelgridMatrixItems } from "src/ts/interfaces/study/velgridMatrix";

const getByVelgridId = (id: number) => {
  return axios.get<IVelgridMatrixItems[]>(
    `VelgridMatrixItems?velGridMatrixId=${id}`
  );
};

const saveItems = (items: IVelgridMatrixItems[]) => {
  return axios.put(`VelgridMatrixItems`, items);
};

const deleteMatrixItem = (id: number) => {
  return axios.delete(`VelgridMatrixItems/${id}`);
};

const velgridMatrixItemsService = {
  getByVelgridId,
  saveItems,
  deleteMatrixItem,
};

export default velgridMatrixItemsService;
