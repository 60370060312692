import { Stack } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { GridActionButton } from "src/components/gridControls";
import LocalEnhancedTable from "src/components/localTable/LocalTable";
import { useTable } from "src/hooks/useTable";
import { ICompany, ICompanyData } from "src/ts/interfaces";
import { ColumnType } from "src/types/enhancedTable";
import ChangeStatusPopup from "./ChangeStatusPopup";

interface SatelliteActiveGridProps {
  companies: ICompany[] | null;
  setShowSatellitePopup: (val: boolean) => void;
  refreshGrid: () => void;
  historyView?: string | null;
  onClick?: (companyId: number) => void;
}
const SatelliteActiveGrid = (props: SatelliteActiveGridProps) => {
  const {
    companies,
    setShowSatellitePopup,
    refreshGrid,
    historyView,
    onClick,
  } = props;
  let navigate = useNavigate();
  const [disablePopup, setDisablePopup] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState<ICompanyData>();

  const columns: ColumnType[] = [
    {
      id: "code",
      label: "Code",
      type: "string",
      sort: true,
      callback: (row: any) => {
        return row.code;
      },
    },
    {
      id: "address",
      label: "Address",
      type: "custom",
      sort: true,
      callback: (row: ICompanyData) => {
        return (
          <>
            {row.address}, {row.city}{" "}
            {`${row.stateName !== null ? row.stateName : row.state?.name}`}{" "}
            {row.country?.name}
          </>
        );
      },
    },
    {
      id: "createdDate",
      label: "Created Date",
      type: "date",
      format: "MM/DD/YYYY",
      sort: true,
    },
    {
      id: "x",
      label: "Actions",
      type: "custom",
      sort: false,
      callback: (row: ICompanyData) => (
        <div>
          <Stack direction="row">
            <>
              {!historyView && (
                <>
                  <GridActionButton
                    type="edit"
                    onClick={() => {
                      setShowSatellitePopup(false);
                      navigate(`/app/company/${row.id}`);
                    }}
                  />
                  <GridActionButton
                    type="disable"
                    onClick={() => {
                      setDisablePopup(true);
                      setSelectedCompany(row);
                    }}
                  />
                  <GridActionButton
                    type="folder"
                    onClick={() => {
                      navigate(`/app/companyDocuments/${row.id}`);
                    }}
                  />
                </>
              )}
            </>
            {historyView && (
              <>
                <GridActionButton
                  type="forward"
                  onClick={() => {
                    if (onClick) onClick(row.id);
                    setShowSatellitePopup(false);
                    switch (historyView) {
                      case "Technicians History":
                        navigate(`/app/techniciansHistory/${row.id}`);
                        break;
                      case "Buildings":
                        navigate(`/app/Buildings/${row.id}`);
                        break;
                      case "Projects":
                        navigate(`/app/ProjectList/${row.id}`);
                        break;
                      case "Project History":
                        navigate(`/app/projectHistory/${row.id}`);
                        break;
                      case "Studies History":
                        navigate(`/app/studiesHistory/${row.id}`);
                        break;
                      case "Techinicians Request":
                        navigate(`/app/Technicians/${row.id}`);
                        break;
                      case "Users Access History":
                        navigate(`/app/userAccessHistory/${row.id}`);
                        break;
                      case "Wallet":
                        navigate(`/app/Wallet/${row.id}`);
                        break;
                      case "Go To":
                        navigate(`/app/users/${row.id}`);
                        break;
                    }
                  }}
                  tooltip={historyView!}
                />
              </>
            )}
          </Stack>
        </div>
      ),
    },
  ];

  const { setDataSet } = useTable(columns);

  useEffect(() => {
    setDataSet(companies ?? []);
  });

  return (
    <>
      <ChangeStatusPopup
        currentValues={selectedCompany}
        disablePopup={disablePopup}
        setDisablePopup={setDisablePopup}
        onSave={refreshGrid}
        onClick={() => null}
        status={true}
        isSatelite={true}
      />
      <LocalEnhancedTable<ICompany>
        refreshGrid={true}
        columns={columns}
        data={companies ?? []}
      />
    </>
  );
};

export default SatelliteActiveGrid;
