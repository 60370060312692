import { Grid } from "@mui/material";
import { FormNumeric2 } from "src/components/formControls";
import { Mode } from "src/ts/types";
import useChillerStudy from "../hooks/useChillerStudy";
import { ISystemOfMeasurement } from "src/ts/interfaces";

interface Props {
  mode: Mode;
  disableStudy: boolean;
  isLoading: boolean;
  systemOfMeasurement: ISystemOfMeasurement;
}

const TowerCondenserActualForm = ({
  mode,
  disableStudy,
  isLoading,
  systemOfMeasurement,
}: Props) => {
  const {
    towerCondenserValues: values,
    handleTowerCondenserInputChange: handleInputChange,
  } = useChillerStudy();
  const row = 4;

  return (
    <Grid container spacing={5}>
      <Grid item xs={row}>
        <FormNumeric2
          name="actualWaterDeliveryGPM"
          label={`Condenser Water Delivery ${systemOfMeasurement.get("gpm")}`}
          value={values.actualWaterDeliveryGPM}
          onChange={handleInputChange}
          showSkeleton={isLoading}
          mode={mode}
          disabled={disableStudy}
          decimalScale={2}
          thousandSeparator={true}
          maxValue={1000000000}
          fixedDecimalScale={true}
        />
      </Grid>
      <Grid item xs={row}>
        <FormNumeric2
          name="actualEnteringAirWBTemp"
          label={`Actual Entering Air WB Temp ${systemOfMeasurement.get(
            "temperature"
          )}`}
          value={values.actualEnteringAirWBTemp}
          onChange={handleInputChange}
          showSkeleton={isLoading}
          mode={mode}
          disabled={disableStudy}
          decimalScale={2}
          thousandSeparator={true}
          maxValue={1000000000}
          fixedDecimalScale={true}
        />
      </Grid>
      <Grid item xs={row}>
        <FormNumeric2
          name="actualAmbientAirDBTemp"
          label={`Actual Ambient Air DB Temp ${systemOfMeasurement.get(
            "temperature"
          )}`}
          value={values.actualAmbientAirDBTemp}
          onChange={handleInputChange}
          showSkeleton={isLoading}
          mode={mode}
          disabled={disableStudy}
          decimalScale={2}
          thousandSeparator={true}
          maxValue={1000000000}
          fixedDecimalScale={true}
        />
      </Grid>
    </Grid>
  );
};

export default TowerCondenserActualForm;
