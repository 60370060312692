import { IProjectCovid, ProjectSelectedDTO } from "src/ts/interfaces";
import {
  IProjectCovidItems,
  IProjectTypeCount,
  ProjectSelectedItemsDTO,
} from "src/ts/interfaces/project/project";
import {
  IProjectOutForBidDataPage,
  IProjectOutForBidFilter,
} from "src/ts/interfaces/projectOutToBid";
import axios from "src/utils/axios";
import { getFilter } from "src/utils/utils";

const getCompleteSelected = (companyId: number, isControlProject: boolean) => {
  return axios.get<ProjectSelectedDTO[]>(
    `GetProject/CompleteSelected?companyId=${companyId}&isControlProject=${isControlProject}`
  );
};

const getCompleteSelectedItems = (
  companyId: number,
  isControlProject: boolean,
  page: number,
  filter: string,
  sortColumn: string,
  sortDec: string,
  rows: number
) => {
  return axios.get<ProjectSelectedItemsDTO>(
    `GetProject/CompleteSelectedItems?companyId=${companyId}&isControlProject=${isControlProject}&page=${page}&filter=${filter}&sortColumn=${sortColumn}&sortDec=${sortDec}&rows=${rows}`
  );
};

const getProjectSelected = (companyId: number, isControlProject: boolean) => {
  return axios.get<ProjectSelectedDTO[]>(
    `GetProject/ProjectSelected?companyId=${companyId}&isControlProject=${isControlProject}`
  );
};

const getProjectSelectedItem = (
  companyId: number,
  isControlProject: boolean,
  page: number,
  filter: string,
  sortColumn: string,
  sortDec: string,
  rows: number,
  disabled: boolean
) => {
  return axios.get<ProjectSelectedItemsDTO>(
    `GetProject/ProjectSelectedItems?companyId=${companyId}&isControlProject=${isControlProject}&page=${page}&filter=${filter}&sortColumn=${sortColumn}&sortDec=${sortDec}&rows=${rows}&disabled=${disabled}`
  );
};

const getControlEvaluated = (companyId: number, isControlProject: boolean) => {
  return axios.get<IProjectCovid[]>(
    `GetProject/ControlEvaluated?companyId=${companyId}&isControlProject=${isControlProject}`
  );
};

const getControlEvaluatedItems = (
  companyId: number,
  isControlProject: boolean,
  page: number,
  filter: string,
  sortColumn: string,
  sortDec: string,
  rows: number,
  controlStatusId: number
) => {
  return axios.get<IProjectCovidItems>(
    `GetProject/ControlEvaluatedItems?companyId=${companyId}&isControlProject=${isControlProject}&page=${page}&filter=${filter}&sortColumn=${sortColumn}&sortDec=${sortDec}&rows=${rows}&controlStatusId=${controlStatusId}`
  );
};

const getProjectDashboard = (projectId: number) => {
  return axios.get<IProjectCovid[]>(
    `GetProject/GetProjectDashboard?projectId=${projectId}`
  );
};

const countByType = () => {
  return axios.get<IProjectTypeCount[]>(`GetProject/CountByType`);
};

const getMeasurementSystem = (projectId: number) => {
  return axios.get<string>(
    `GetProject/MeasurementSystem?projectId=${projectId}`
  );
};

const isImperialSystem = (projectId: number) => {
  return axios.get<boolean>(
    `GetProject/IsImperialSystem?projectId=${projectId}`
  );
};

const getProjectAwareded = async (
  filter: IProjectOutForBidFilter,
  getQuery?: (filters: Record<string, any>) => string
) => {
  const queryString = getQuery ? getQuery(filter) : getFilter(filter);
  return await axios.get<IProjectOutForBidDataPage>(
    `GetProject/AwardedProject?${queryString}`
  );
};

const getEstimatedTime = (projectId: number) => {
  return axios.get(`GetProject/GetEstimatedTime/${projectId}`);
};

const getProjectService = {
  getCompleteSelected,
  getProjectSelected,
  getControlEvaluated,
  countByType,
  getProjectDashboard,
  getMeasurementSystem,
  isImperialSystem,
  getProjectAwareded,
  getEstimatedTime,
  getProjectSelectedItem,
  getCompleteSelectedItems,
  getControlEvaluatedItems,
};

export default getProjectService;
