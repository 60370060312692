import { Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import {
  FormButton,
  FormCancelButton,
  FormTextArea,
} from "src/components/formControls";
import Popup from "src/components/Popup";
import useForm from "src/hooks/useForm";
import { userService } from "src/services";
import { useLog } from "src/hooks";

interface IDisabledUserPopupProps {
  showPopUp: boolean;
  setShowPopUp: (val: boolean) => void;
  name: string;
  enableUser: boolean;
  userID: number;
  setRefresh: (val: boolean) => void;
  refresh: boolean;
}
const initialValue = {
  deactivationReason: "",
};

const DisabledUserPopUp = ({
  showPopUp,
  setShowPopUp,
  name,
  enableUser,
  userID,
  setRefresh,
  refresh,
}: IDisabledUserPopupProps) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { values, handleInputChange, resetForm } = useForm(
    initialValue,
    false,
    []
  );

  const { log } = useLog();
  const handleClose = () => {
    resetForm();
    setShowPopUp(false);
  };

  const handleDisableClick = () => {
    const disable = async () => {
      try {
        setIsSubmitting(true);
        await userService.userStatus(userID, {
          unibesEnabled: false,
          unibesComments: values.deactivationReason,
        });
        log.success("The user was disable.");
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      } finally {
        setRefresh(!refresh);
        setShowPopUp(false);
        setIsSubmitting(false);
      }
    };

    disable();
  };

  const handleEnableClick = () => {
    const enable = async () => {
      try {
        setIsSubmitting(true);
        await userService.userStatus(userID, {
          unibesEnabled: true,
          unibesComments: "",
        });
        log.success("The user was enable.");
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      } finally {
        setRefresh(!refresh);
        setShowPopUp(false);
        setIsSubmitting(false);
      }
    };

    enable();
  };
  return (
    <Popup
      title="Warning"
      openPopup={showPopUp}
      setOpenPopup={setShowPopUp}
      onClose={handleClose}
    >
      <>
        {enableUser ? (
          <>
            <Typography>
              You are about to disable user: <b>{name}</b>{" "}
            </Typography>
            <Typography>
              Selected user access to the Audit Master Pro software will be
              revoked.{" "}
            </Typography>
            <br />
            <Typography mb={5}>
              Please indicate the reason for disabling (this information will
              appear in the user's page for the organization's administrators).
            </Typography>
            <FormTextArea
              name="deactivationReason"
              value={values.deactivationReason}
              placeholder="Enter reason for disabling the user"
              label=""
              onChange={handleInputChange}
            />
            <br />
          </>
        ) : (
          <>
            <Typography>
              You are about to enabled user: <b>{name}</b>{" "}
            </Typography>
            <Typography>
              Selected user access to the Audit Master Pro software will be
              allowed once company's administrators enable the user too.
            </Typography>
            <br />
            <Typography>
              A notification will be sent to the company's administrators
              indicating that the user can be activated by them.
            </Typography>
          </>
        )}
      </>
      <Stack
        direction="row"
        spacing={2}
        justifyContent="flex-end"
        alignItems="flex-start"
      >
        <FormButton
          text="Accept"
          onClick={() => {
            enableUser ? handleDisableClick() : handleEnableClick();
          }}
          size="medium"
          disabled={!values.deactivationReason && enableUser}
          isSubmitting={isSubmitting}
        />

        <FormCancelButton onClick={handleClose} isSubmitting={isSubmitting} />
      </Stack>
    </Popup>
  );
};

export default DisabledUserPopUp;
