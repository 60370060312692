import { IPsychrometric, IPsychrometricArgs } from "src/ts/interfaces";
import axios from "../utils/axios";

const getByProject = async (psychrometric: IPsychrometricArgs) => {
  var psychrometricRoute = `Psychrometric/Psychrometric/ProjectId/${psychrometric.projectId}/${psychrometric.humidityMeasuredAs}/${psychrometric.humidityValue}/${psychrometric.dryBulbF}`;

  return axios.get<IPsychrometric>(psychrometricRoute);
};

const psychrometricService = {
  getByProject,
};

export default psychrometricService;
