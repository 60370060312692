import axios from "../utils/axios";
import { IKeyValue } from "../ts/interfaces";
import { IUserSchoolsAccess } from "src/ts/interfaces/userSchoolsAccess";
import { ISchools } from "src/ts/interfaces/catalogs/schools";

const getByUser = async (id: number) => {
  return axios.get<IUserSchoolsAccess[]>(`/UserSchoolsAccess/ByUserId/` + id);
};

const deleteItem = async (id: number) => {
  return axios.delete<number>(`UserSchoolsAccess/Delete/` + id);
};

const addItem = async (userId: number, schoolsId: string | null) => {
  if (schoolsId === "0")
    return axios.post<IUserSchoolsAccess>(
      `UserSchoolsAccess/selectAll/` + userId
    );
  else
    return axios.post<IUserSchoolsAccess>(
      `UserSchoolsAccess/Create/` + userId + "/" + schoolsId
    );
};

const selectAllSchools = async (userId: number) => {
  return await axios.post(`UserSchoolsAccess/selectAll/${userId}`);
};

const getAll = () => {
  return axios.get<ISchools[]>("UserSchoolsAccess/SchoolsByCurrent");
};

const getKeyValues = () => {
  return new Promise<
    IKeyValue<number | null | undefined, string | undefined>[]
  >((resolve, reject) => {
    axios
      .get<Array<ISchools>>("/UserSchoolsAccess/SchoolsByCurrent")
      .then((response) => {
        const keyValuePair: IKeyValue<
          number | null | undefined,
          string | undefined
        >[] = response.data.map((schools) => {
          return {
            key: schools.id,
            value: schools.name,
          };
        });

        resolve(keyValuePair);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const userSchoolsAccessService = {
  getAll,
  getByUser,
  deleteItem,
  addItem,
  selectAllSchools,
  getKeyValues,
};

export default userSchoolsAccessService;
