import { IStudyStep } from "src/ts/interfaces";

export const steps: IStudyStep[] = [
  {
    label: "Step 1",
    key: 0,
    description: "Outlet Information",
    url: "/studies/OutletMaster/:id",
  },
  {
    label: "Step 2",
    key: 1,
    description: "Outlet Readings",
    url: "/studies/OutletMasterStep2/:id",
  },
  {
    label: "Step 3",
    key: 2,
    description: "Report",
    url: "/studies/OutletMasterStep3/:id",
  },
];
