import {
  Grid,
  Stack,
  IconButton,
  Chip,
  Typography,
  Tooltip,
  Zoom,
  CardContent,
  styled,
  Card as MuiCard,
} from "@mui/material";
import { useNavigate, useParams, NavigateFunction } from "react-router-dom";
import { useEffect, useMemo, useState } from "react";
import {
  IBuilding,
  IProjectContestOwner,
  ISendOutToBidProject,
} from "src/ts/interfaces/projectOutToBid";
import {
  FormCheckBox,
  FormRadioGroup,
  FormRaitingStarts,
  FormLabel,
  FormButton,
  FormDatePicker,
  FormTimePicker,
} from "src/components/formControls";
import { ColumnType } from "src/types/enhancedTable";
import {
  ForwardIcon,
  CancelIcon,
  AddIcon,
  InfoIcon,
} from "src/components/icons";
import LocalEnhancedTable from "src/components/localTable/LocalTable";
import RatingInformation from "./Components/RatingInformationPopUp";
import TotalCombinedPopUp from "./Components/TotalCombinedPopUp";
import DeclinedPopUp from "../project/Components/DeclinedPopUp";
import { ToolTip } from "src/components/others/ToolTip";
import useLoading from "src/hooks/useLoading";
import { Permission as PermissionTypes } from "src/ts/enums";
import {
  useAsyncMutation,
  useAsyncQuery,
  useAuth,
  useLog,
  usePermissions,
} from "src/hooks";
import buildingService from "src/services/buildingService";
import getProjectSingleService from "src/services/getProjectSingleService";
import sendOutToBidProjectService from "src/services/sendOutToBidProjectService";
import permissionService from "src/services/permissionService";
import projectContestService from "src/services/projectContestService";
import {
  ProjectDTO,
  ProjectWithBogus,
} from "src/ts/interfaces/project/projectDto";
import projectService from "src/services/project/projectService";
import { ARCHITECTDATA, ROLES, projectStatus } from "src/constants";
import { projectPutService } from "src/services";
import useFormTyped from "src/hooks/useFormTyped";
import { GridActionButton } from "src/components/gridControls";
import SendBidPopUp from "./Components/SendBidPopUp";
import MovePhaseBid from "./Components/MovePhaseBid";
import { Validator } from "src/ts/types/Validator";
import AddContractorPopUp from "../contractors/AddContractorPopUp";
import MatchWordConfirmModal from "src/components/modals/MatchWordConfirmModal";
import AddPreferredPopUp from "../contractors/AddPreferredPopUp";
import CheckToAssignPopUp from "./Components/CheckToAssignPopUp";
import FormBackButton from "src/components/formControls/FormBackButton";
import { spacing } from "@mui/system";
const Card = styled(MuiCard)(spacing);
type ProjectOutForBidParams = {
  id: string;
};

const getColumns = (
  userRol: string,
  setDeleteEmailModal: (val: boolean) => void,
  setEmailSelected: (val: ISendOutToBidProject) => void,
  usersSendOutBid: boolean,
  fullAccess: boolean
): ColumnType[] => [
  {
    id: "name",
    label: "Name",
    type: "custom",
    sort: true,
    callback: (row: ISendOutToBidProject) => (
      <>
        <Stack>
          {row.teamMember === null ? row.name : row.teamMember.name}
        </Stack>
      </>
    ),
  },
  {
    id: "lastName",
    label: "Last Name",
    type: "custom",
    sort: true,
    callback: (row: ISendOutToBidProject) => (
      <>
        <Stack>
          {row.teamMember === null ? row.lastName : row.teamMember.lastName}
        </Stack>
      </>
    ),
  },
  {
    id: "email",
    label: "Email",
    type: "custom",
    sort: true,
    callback: (row: ISendOutToBidProject) => (
      <>
        <Stack>
          {row.teamMember === null ? row.email : row.teamMember.email}
        </Stack>
      </>
    ),
  },
  {
    id: "phone",
    label: "Phone",
    type: "phone",
    sort: true,

    callback: (row: ISendOutToBidProject) => {
      const phone =
        row.teamMember === null ? row.phone : row.teamMember.workPhoneNumber;
      let cleaned = ("" + phone).replace(/\D/g, "");
      let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
      var phoneNumber = "";
      if (match) {
        phoneNumber = "(" + match[1] + ") " + match[2] + "-" + match[3];
        return phoneNumber;
      }

      return (
        <>
          <Stack>
            {row.teamMember === null
              ? row.phone
              : row.teamMember.workPhoneNumber}
          </Stack>
        </>
      );
    },
  },
  {
    id: "action",
    label: "",
    type: "custom",
    sort: true,
    callback: (row: ISendOutToBidProject) => {
      return (
        <>
          {(userRol === ROLES.Architect ||
            userRol === ROLES.ArchitectAux ||
            userRol === ROLES.SuperAdministrator) && (
            <Stack>
              {
                <>
                  {fullAccess && !usersSendOutBid && (
                    <GridActionButton
                      type="remove"
                      onClick={() => {
                        setEmailSelected(row);
                        setDeleteEmailModal(true);
                      }}
                    />
                  )}
                </>
              }
            </Stack>
          )}
        </>
      );
    },
  },
];

const getColumnsContest = (
  setShowRatingContractorPopUp: (val: boolean) => void,
  setInformationContractor: (val: number) => void,
  setShowTotalCombinedPopUp: (val: boolean) => void,
  setCodeContractor: (val: string) => void,
  setNameContractor: (val: string) => void,
  navigate: NavigateFunction,
  setShowDeclinedPopUp: (val: boolean) => void,
  setProjectContestSelected: (val: IProjectContestOwner) => void,
  fullAccessBids: boolean,
  readOnlyBids: boolean,
  handlerSelected: (e: any, id: number) => void,
  setDeleteShowPop: (id: boolean) => void,
  setIdSelected: (id: IProjectContestOwner) => void,
  role: string,
  sendProposalThroughAMP: boolean,
  companyId: string,
  projectSingle: ProjectDTO | undefined,
  handleResendEmail: (id: number) => void,
  isOnDate: boolean,
  fullAccess: boolean
): ColumnType[] => [
  {
    id: "winning",
    label: "Select",
    type: "custom",
    sort: true,
    hide: !fullAccess,
    callback: (row: IProjectContestOwner) => {
      const disableSelect =
        (projectSingle?.sendProposalThroughAMP && row.totalCombined === null) ||
        role === ROLES.SysAdmin ||
        !(
          !row.winning &&
          projectSingle?.projectStatus?.name === projectStatus.Contest.name &&
          !(row.estimatedTimeDuration === " ")
        );
      return (
        <>
          {row.declineComments === null ? (
            <FormCheckBox
              name="isSelected"
              label=""
              value={row.isSelected}
              disabled={disableSelect}
              onChange={(e) => handlerSelected(e, row.id)}
            />
          ) : (
            <ToolTip title="Declined">
              <CancelIcon
                sx={{ cursor: "pointer", color: "red" }}
                onClick={() => {
                  setShowDeclinedPopUp(true);
                  setProjectContestSelected(row);
                }}
              />
            </ToolTip>
          )}
        </>
      );
    },
  },
  {
    id: "name",
    label: "Name",
    type: "custom",
    sort: true,
    callback: (row: IProjectContestOwner) => (
      <>
        <Stack>{row.name}</Stack>
        <Stack>{row.invited ? <b>(Invited)</b> : ""}</Stack>
      </>
    ),
  },
  {
    id: "address",
    label: "Address",
    type: "string",
    sort: true,
  },
  {
    id: "email",
    label: "Email",
    type: "custom",
    sort: true,
    callback: (row: IProjectContestOwner) => {
      var input = row.email;
      var fields = input.split("@");
      return (
        <>
          <Stack>{fields[0]}@</Stack>
          <Stack>{fields[1]}</Stack>
        </>
      );
    },
  },
  {
    id: "invited",
    label: "Invited",
    type: "custom",
    sort: true,
    callback: (row: IProjectContestOwner) => (
      <>
        <FormCheckBox name="" value={row.invited} label="" disabled={true} />
      </>
    ),
  },
  {
    id: "preferred",
    label: "Preferred",
    type: "custom",
    sort: true,
    callback: (row: IProjectContestOwner) => (
      <>
        <FormCheckBox
          name=""
          value={row.isPreferredContractor}
          label=""
          disabled={true}
        />
      </>
    ),
  },
  {
    id: "rating",
    label: "Click on Stars to see Service Providers Ratings",
    type: "custom",
    sort: true,
    callback: (row: IProjectContestOwner) => (
      <>
        <Stack
          sx={{ cursor: "pointer" }}
          onClick={() => {
            setInformationContractor(row.companyContractorId);

            row.rating === 0
              ? setShowRatingContractorPopUp(false)
              : setShowRatingContractorPopUp(true);
          }}
        >
          <Stack direction="row" justifyContent="center">
            <FormRaitingStarts value={Math.round(row.rating)} />
          </Stack>
        </Stack>
        <Stack alignContent={"center"} justifyContent="center">
          {row.codeUsed && row.winning ? (
            <Chip label="Accepted " color="primary" size="small" />
          ) : !row.codeUsed && row.winning ? (
            <Chip label="Pending" color="warning" size="small" />
          ) : null}
        </Stack>
      </>
    ),
  },
  {
    id: "estimatedTimeDuration",
    label: "Project Time Duration",
    type: "custom",
    sort: true,
    callback: (row: IProjectContestOwner) => <>{row.estimatedTimeDuration}</>,
  },
  {
    id: "totalCombined",
    label: "Total Combined Bid",
    type: "custom",
    sort: true,
    hide: (!fullAccessBids && !readOnlyBids) || !sendProposalThroughAMP,
    callback: (row: IProjectContestOwner) => (
      <>
        {row.totalCombined === null ? (
          <>
            <Stack direction="column" spacing={1} alignItems="end">
              <FormLabel text="$0" />
            </Stack>
          </>
        ) : (
          <Stack direction="column" spacing={1} alignItems="end">
            <FormLabel
              text={`$${row.totalCombined.toLocaleString("en-US")} `}
            />

            <Typography
              sx={{ cursor: "pointer", textDecoration: "underline" }}
              variant="overline"
              onClick={() => {
                setShowTotalCombinedPopUp(true);
                setInformationContractor(row.id);
                setCodeContractor(row.code);
                setNameContractor(row.name);
              }}
            >
              View Detail
            </Typography>
          </Stack>
        )}
      </>
    ),
  },
  {
    id: "reviewProject",
    label: "Review Project",
    type: "custom",
    sort: true,
    callback: (row: IProjectContestOwner) => (
      <>
        <Stack direction="row">
          {row.winning && (
            <IconButton
              aria-label="delete"
              size="large"
              disabled={row.winning ? false : true}
              onClick={() => {
                if (role === ROLES.SysAdmin) {
                  navigate(
                    `/app/projectsOutForBid/ProjectReport/${row.projectContractorId}`
                  );
                } else {
                  navigate(
                    `/app/Project/Edit/${row.projectContractorId}/${companyId}/`
                  );
                }
              }}
            >
              <ForwardIcon />
            </IconButton>
          )}
          {row.hasDeclined && <Chip label="Declined" color="error" />}
          {!row.winning &&
            !row.isTermsAndConditionsAccepted &&
            role !== ROLES.SysAdmin &&
            fullAccess && (
              <GridActionButton
                type="delete"
                onClick={() => {
                  setIdSelected(row);
                  setDeleteShowPop(true);
                }}
              />
            )}
          {!row.isTermsAndConditionsAccepted && isOnDate && fullAccess && (
            <GridActionButton
              type="email"
              onClick={() => {
                handleResendEmail(row.id);
              }}
              tooltip="Resend Invitation"
            />
          )}
        </Stack>
      </>
    ),
  },
];

const initialValues: ProjectWithBogus = {
  bogusStartDate: null,
  bogusStartDateTime: "8:00 AM",
  bogusActualStartDate: null,
  bogusActualStartDateTime: "8:00 AM",
  name: "",
  estimatedTimeDurationUnit: 0,
  startDate: null,
  actualStartDate: null,
  estimatedTimeDuration: 0,
  buildingId: -1,
  measurementSystem: 1,
  suppliedPowerGridFrequency: null,
  auditAreaSqFt: null,
  auditAreaCuFt: null,
  isParkingAreaPartOfThisTest: false,
  requiredComprehensiveEnergyTest: false,
  printDrawingsAvailableOnSite: false,
  printAddendumDateUsedForProposal: null,
  specificationsProvided: false,
  addendumBidForThisProject: 0,
  liftWorkRequired: false,
  liftWorkOnSite: false,
  liftProvidedByOthers: false,
  roofAccessHatchAvailable: 0,
  laddersRequired: false,
  id: 0,
  equipmentCalibrationReportRequired: false,
  visibleToAllContractors: false,
  publishInAuditMasterPro: false,
  tolerancesWereSaved: false,
  sendProposalThroughAMP: true,
  projectStatusId: 0,
  isTemplate: false,
  templateWasUsed: false,
  currentProject: false,
  activeProject: false,
  disabled: false,
  size: 0,
  completedBySuperAdmin: false,
  wasOpenedByContractor: false,
  projectAgreementsAccepted: false,
  isInfectiousControlProject: false,
  computerEstimatedTimeRemaining: 0,
  techniciansEstimatedTimeRemainingIsDays: false,
  completionPaymentByOwner: false,
  elevation: 0,
  gridView: false,
  isOpenDispute: false,
  ductLeakageTesting: false,
  vibrationTestingForMotors: false,
  soundTestingLocations: false,
  hydronicPipeleakage: false,
  ufadPlenumLeakage: false,
  otherTesting: false,
  buildingEnergyEfficiency: false,
  airTestingBalancing: false,
  hydronicWaterBalancing: false,
  hvacEnergyPerformance: false,
  commissioning: false,
  proposedStartDateTimeUnknown: false,
  auditAreaUnknown: false,
  auditAreaUnknownCuFt: false,
  projectBomaTypeId: null,
  projectBomaType: null,
  projectDescription: "",
  projectEstimatedTime: [],
};

export default function ProjectsAvailableInformation() {
  const { log } = useLog();
  const { user } = useAuth();
  const [projectSingle, setProjectSingle] = useState<ProjectDTO>();
  const [buildingInformation, setBuildingInformation] = useState<IBuilding>();
  const [sendOutToBidProject, setSendOutToBidProject] = useState<
    ISendOutToBidProject[]
  >([]);
  const [checkPermission, setCheckPermission] = useState(1);
  const [projectContest, setProjectContest] = useState<IProjectContestOwner[]>(
    []
  );
  const [projectContestSelected, setProjectContestSelected] =
    useState<IProjectContestOwner>();
  const [showRatingContractorPopUp, setShowRatingContractorPopUp] =
    useState(false);
  const [showTotalCombinedPopUp, setShowTotalCombinedPopUp] = useState(false);
  const [showDeclinedPopUp, setShowDeclinedPopUp] = useState(false);
  const [informationContractor, setInformationContractor] = useState(0);
  const [codeContractor, setCodeContractor] = useState("");
  const [nameContractor, setNameContractor] = useState("");
  const { fullAccess: fullAccessBids, readOnly: readOnlyBids } = usePermissions(
    PermissionTypes.Project_Bids
  );
  const { fullAccess } = usePermissions(PermissionTypes.Project);
  const [getContestProject, setGetContestProject] = useState<ProjectDTO>();
  const [refreshSendOut, setRefreshSendOut] = useState(false);
  const [sendBidShowPopUp, setSendBidShowPopUp] = useState(false);
  const [movePhaseBidShowPopUp, setMovePhaseBidShowPopUp] = useState(false);
  const [isSelected, setIsSelected] = useState(false);
  const [refreshGrid, setRefreshGrid] = useState(false);
  const [showAddContractorPopUp, setShowAddContractorPopUp] = useState(false);
  const [showAddPreferredPopUp, setShowAddPreferredPopUp] = useState(false);
  const [refreshTable, setRefreshTable] = useState(false);
  const [deleteShowPop, setDeleteShowPop] = useState(false);
  const [checkToAssignShow, setCheckToAssignShow] = useState(false);
  const [deleteEmailModal, setDeleteEmailModal] = useState(false);
  const [idSelected, setIdSelected] = useState<IProjectContestOwner>();
  const [isSelectedArray, setIsSelectedArray] = useState<number[]>([]);
  const [emailSelected, setEmailSelected] = useState<ISendOutToBidProject>();

  let { id } = useParams<ProjectOutForBidParams>();
  const projectId = id === undefined ? 0 : Number(id);
  const { isLoading, startRequest, endRequest } = useLoading();

  const navigate = useNavigate();

  const validate: any = (fieldValues = values) => {
    let temp: Record<string, string> = { ...errors };

    temp.bogusStartDate = new Validator(fieldValues, "bogusStartDate")
      .isRequired("Proposed Due Date is required")
      .toString();

    temp.bogusStartDateTime = new Validator(fieldValues, "bogusStartDateTime")
      .isRequired("Proposed Due Time is required")
      .toString();

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, handleInputChange, errors, setErrors } =
    useFormTyped<ProjectWithBogus>(initialValues, false, validate);

  const { execute: executeUpdate } = useAsyncQuery(projectPutService.update);

  const { execute: executeDelete } = useAsyncQuery(
    sendOutToBidProjectService.deleteSendOutToBidProject
  );
  const { execute: executeContesDueDate } = useAsyncQuery(
    projectService.putContestDueDate
  );
  const { execute: executeNotify, isLoading: loadingNotify } = useAsyncQuery(
    projectContestService.postNotifyProjectContest
  );
  const { execute: executeProjectSingle } = useAsyncQuery(
    getProjectSingleService.getProjectSingle
  );

  const { execute: deleteContractro } = useAsyncQuery(
    projectContestService.deleteProjectContest
  );

  const { execute: executeResend } = useAsyncMutation(
    projectContestService.resendInvitation,
    {
      successfulMessage: "The invitation was forwarded",
    }
  );

  useEffect(() => {
    const getProjectInfo = async () => {
      try {
        startRequest("projectInfo");

        if (user?.role === ROLES.SysAdmin) {
          const responsesAdmin = await Promise.all([
            getProjectSingleService.getProjectSingle(projectId),
            sendOutToBidProjectService.getSendOutToBidProject(projectId),
            permissionService.getCheckUserPermission(projectId),
            projectContestService.getProjectContest(projectId, checkPermission),
            projectService.getContestProject(projectId),
          ]);
          setProjectSingle(responsesAdmin[0].data);
          setSendOutToBidProject(responsesAdmin[1].data);
          setCheckPermission(responsesAdmin[2].data);
          setProjectContest(responsesAdmin[3].data);
          setGetContestProject(responsesAdmin[4].data);
          setValues(responsesAdmin[0].data);
        } else if (
          user?.role === ROLES.SuperAdministrator ||
          user?.role === ROLES.CompanyOwner ||
          user?.role === ROLES.Architect ||
          user?.role === ROLES.ArchitectAux
        ) {
          const responses = await Promise.all([
            getProjectSingleService.getProjectSingle(projectId),
            sendOutToBidProjectService.getSendOutToBidProject(projectId),
            permissionService.getCheckUserPermission(projectId),
            projectContestService.getProjectContest(projectId, checkPermission),
          ]);
          setProjectSingle(responses[0].data);
          setSendOutToBidProject(responses[1].data);
          setCheckPermission(responses[2].data);
          setProjectContest(responses[3].data);
          setGetContestProject(responses[0].data);
          setValues(responses[0].data);
        }
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      }
    };

    getProjectInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const tableSendOut = async () => {
      try {
        const response =
          await sendOutToBidProjectService.getSendOutToBidProject(projectId);

        setSendOutToBidProject(response.data);
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      }
    };

    tableSendOut();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshSendOut]);

  useEffect(() => {
    const tableSendOut = async () => {
      try {
        const response = await projectContestService.getProjectContest(
          projectId,
          checkPermission
        );

        const updatedProjectContest = response.data.map(
          (item: IProjectContestOwner) => {
            return {
              ...item,
              [`isSelected`]: item.winning ? item.winning : false,
            };
          }
        );

        setProjectContest(updatedProjectContest);
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      }
    };

    tableSendOut();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshTable]);

  useEffect(() => {
    const getBuildingInfo = async () => {
      try {
        startRequest("projectInfo");
        if (projectSingle === undefined) return;
        const buildingID = Number(projectSingle?.buildingId);
        const response = await buildingService.getBuilding(buildingID);
        setBuildingInformation(response.data);

        if (
          values.contestDueDate !== null &&
          values.contestDueDate !== undefined
        ) {
          const [date, time] = values.contestDueDate.toString().split("T");
          setValues({
            ...values,
            bogusStartDateTime: time,
            bogusStartDate: date,
          });
        } else {
          setValues({
            ...values,
            bogusStartDate: null,
            bogusStartDateTime: "08:00:00 AM",
          });
        }
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      } finally {
        endRequest("projectInfo");
      }
    };

    const isSelectedValue = () => {
      const updatedProjectContest = projectContest.map((item) => {
        return {
          ...item,
          [`isSelected`]: item.winning ? item.winning : false,
        };
      });

      setProjectContest(updatedProjectContest);
    };
    isSelectedValue();
    getBuildingInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectSingle]);

  const usersSendOutBid = useMemo(() => {
    return sendOutToBidProject && sendOutToBidProject.length === 1;
  }, [sendOutToBidProject]);

  const tileRadio =
    projectSingle?.projectStatus?.name === projectStatus.Contest.name;

  const radioInformation = [
    {
      key: "0",
      value:
        "Publish this project in Audit Master PRO's web page, available for all licensed contractors and training/demo users",
    },
    {
      key: "1",
      value: `Make this project private, available only for those contractors you selected. ${
        !tileRadio
          ? "(Contractor selection options will be visible once you send this project out for bid)"
          : ""
      }`,
    },
  ];

  const disableSendProposalThroughAMP =
    projectSingle?.projectStatus?.id === 6 ||
    projectSingle?.projectStatus?.id === 7 ||
    projectSingle?.projectStatus?.id === 9;

  const disableComponents =
    projectSingle?.projectStatus?.id === 7 ||
    projectSingle?.projectStatus?.id === 9 ||
    user?.role === ROLES.SysAdmin ||
    !fullAccess;

  const handlerOnChange = async (e: any) => {
    const { name, checked } = e.target;
    setValues({ ...values, [name]: checked });

    executeUpdate(id, {
      ...values,
      sendProposalThroughAMP: checked,
    });
    log.success("The information was saved successfully.");
  };

  const handlerOnAviability = async (e: any) => {
    const { value } = e.target;

    setValues({
      ...values,
      visibleToAllContractors: value === "0" ? true : false,
      publishInAuditMasterPro: value === "0" ? true : false,
    });
    await executeUpdate(id, {
      ...values,
      visibleToAllContractors: value === "0" ? true : false,
      publishInAuditMasterPro: value === "0" ? true : false,
    });

    log.success("The information was saved successfully.");
  };

  const updateDateTime = async () => {
    await executeUpdate(id, {
      ...values,
      contestDueDate: values.bogusStartDate
        ? `${values.bogusStartDate} ${values.bogusStartDateTime}`
        : null,
    });
    log.success("Proposal Due Date was updated.");
    setRefreshGrid(!refreshGrid);
  };

  const removeSendOutOfBid = async () => {
    await executeDelete(emailSelected?.id);
    const removeValue = sendOutToBidProject.filter((item) => {
      return item.id !== emailSelected?.id;
    });
    setSendOutToBidProject(removeValue);
  };

  const handlerSelected = (e: any, id: number) => {
    const { name, value } = e.target;

    const newProjectContest = projectContest.map((item) => {
      if (item.id === id) {
        return {
          ...item,
          [name]: value,
        };
      } else {
        return { ...item };
      }
    });
    setIsSelectedArray([...isSelectedArray, id]);
    let count = 0;
    newProjectContest.forEach((obj) => {
      if (obj.isSelected && !obj.winning) {
        count++;
      }
    });

    count > 0 ? setIsSelected(true) : setIsSelected(false);

    setProjectContest(newProjectContest);
  };

  const handleDeleteContractor = async (
    row: IProjectContestOwner | undefined
  ) => {
    if (row) {
      deleteContractro(row.id);
      handleRefreshTable();
      log.success("The contractor was deleted.");
    }
  };

  let columns = getColumns(
    user?.role,
    setDeleteEmailModal,
    setEmailSelected,
    usersSendOutBid,
    fullAccess
  );

  const handleResendEmail = async (id: number) => {
    await executeResend(projectId, id);
  };

  const isOnDate = useMemo(() => {
    return (
      new Date(`${values.bogusStartDate} ${values.bogusStartDateTime}`) >=
      new Date()
    );
  }, [values.bogusStartDate, values.bogusStartDateTime]);

  let coluumnsContestOwner = getColumnsContest(
    setShowRatingContractorPopUp,
    setInformationContractor,
    setShowTotalCombinedPopUp,
    setCodeContractor,
    setNameContractor,
    navigate,
    setShowDeclinedPopUp,
    setProjectContestSelected,
    fullAccessBids || false,
    readOnlyBids || false,
    handlerSelected,
    setDeleteShowPop,
    setIdSelected,
    user?.role,
    values.sendProposalThroughAMP,
    user?.companyId,
    projectSingle,
    handleResendEmail,
    isOnDate,
    fullAccess
  );

  const handlerSendForBid = async () => {
    if (!validate()) return setMovePhaseBidShowPopUp(false);

    try {
      await executeContesDueDate(projectId, {
        ...values,
        contestDueDate: values.bogusStartDate
          ? `${values.bogusStartDate} ${values.bogusStartDateTime}`
          : null,
      });
      await executeNotify(projectId);
      executeProjectSingle(projectId);
      setMovePhaseBidShowPopUp(false);
    } catch (error: any) {
      log.error(error?.message?.exceptionMessage ?? "Something went wrong");
    } finally {
      navigate(`/app/ProjectContractors/${projectId}/${user?.companyId}`);
    }
  };

  const handleRefreshTable = () => {
    setRefreshTable(!refreshTable);
  };

  const hideSendOutBid =
    projectSingle?.projectStatus &&
    projectSingle?.projectStatus.id !== 7 &&
    projectSingle?.projectStatus.id !== 9 &&
    !tileRadio;

  useEffect(() => {
    const getBuildingInfo = async () => {
      try {
        startRequest("projectInfo");
        if (projectSingle === undefined) return;
        const buildingID = Number(projectSingle?.buildingId);
        const response = await buildingService.getBuilding(buildingID);
        setBuildingInformation(response.data);
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      } finally {
        endRequest("projectInfo");
      }
    };
    getBuildingInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectSingle]);

  const sendOutForBidModal = () => {
    if (sendOutToBidProject.length === 0)
      return log.warning("At least one user must exists in the list.");

    if (!validate()) return log.warning("A proposal due date is required.");
    setMovePhaseBidShowPopUp(true);
  };

  const showAddButtons =
    projectSingle?.projectStatus?.name === projectStatus.Contest.name &&
    user?.role !== ROLES.SysAdmin &&
    fullAccessBids &&
    isOnDate;

  const filterByTab = useMemo(() => {
    for (const key in ARCHITECTDATA) {
      if (ARCHITECTDATA[key].queryData === projectSingle?.projectStatusId) {
        return ARCHITECTDATA[key].name;
      }
    }
    return "all";
  }, [projectSingle?.projectStatusId]);

  return (
    <Card mb={1}>
      <CardContent>
        <Grid container>
          <Grid container xs={12} spacing={5} mb={5} item>
            <Grid item xs={3}>
              <Stack direction="row" justifyContent="end" alignItems="center">
                <Typography>
                  <b>Code </b>
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={8}>
              <Stack direction="row" justifyContent="start" alignItems="center">
                <FormLabel
                  text={projectSingle?.code}
                  showSkeleton={isLoading}
                />
              </Stack>
            </Grid>
          </Grid>
          <Grid container xs={12} spacing={5} mb={5} item>
            <Grid item xs={3}>
              <Stack direction="row" justifyContent="end" alignItems="center">
                <Typography>
                  <b>Project </b>
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={8}>
              <Stack direction="row" justifyContent="start" alignItems="center">
                <FormLabel
                  text={projectSingle?.name}
                  showSkeleton={isLoading}
                />
              </Stack>
            </Grid>
          </Grid>
          <Grid container xs={12} spacing={5} mb={5} item>
            <Grid item xs={3}>
              <Stack direction="row" justifyContent="end" alignItems="center">
                <Typography>
                  <b>Description </b>
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={8}>
              <Stack direction="row" justifyContent="start" alignItems="center">
                <FormLabel
                  text={projectSingle?.projectDescription}
                  showSkeleton={isLoading}
                />
              </Stack>
            </Grid>
          </Grid>
          <Grid container xs={12} spacing={5} mb={5} item>
            <Grid item xs={3}>
              <Stack direction="row" justifyContent="end" alignItems="center">
                <Typography>
                  <b>Building </b>
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={8}>
              <Stack direction="row" justifyContent="start" alignItems="center">
                <Typography>
                  <b>{buildingInformation?.name ?? ""} </b>
                </Typography>
              </Stack>
              <FormLabel
                text={`${buildingInformation?.address}, ${
                  buildingInformation?.country.name
                }, ${
                  buildingInformation?.stateName !== null
                    ? buildingInformation?.stateName
                    : buildingInformation?.state.name
                }, ${buildingInformation?.city},  ZipCode: ${
                  buildingInformation?.zip ?? ""
                }`}
                showSkeleton={isLoading}
              />
            </Grid>
          </Grid>
          <Grid container xs={12} spacing={5} mb={5} item>
            <Grid item xs={3}>
              <Stack direction="row" justifyContent="end" alignItems="center">
                <Typography mt={1.5} textAlign={"right"}>
                  <b>Current or modified proposal due date </b>
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={8}>
              <>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <FormDatePicker
                      name="bogusStartDate"
                      label=""
                      value={values.bogusStartDate}
                      onChange={handleInputChange}
                      disablePast={true}
                      disable={disableComponents || !fullAccess}
                      error={errors.bogusStartDate}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <FormTimePicker
                      label=""
                      value={values.bogusStartDateTime}
                      onChange={handleInputChange}
                      name={"bogusStartDateTime"}
                      disable={disableComponents || !fullAccess}
                      error={errors.bogusStartDateTime}
                    />
                  </Grid>
                  {!disableComponents && fullAccessBids && (
                    <Grid item xs={2}>
                      <FormButton
                        onClick={updateDateTime}
                        text="Update"
                        color="primary"
                        size="medium"
                      />
                    </Grid>
                  )}
                </Grid>
              </>

              <FormCheckBox
                name="sendProposalThroughAMP"
                label="Send generated proposals using AMP's automated process" // "Send proposal through AMP's Auto Proposal Features"
                value={values.sendProposalThroughAMP}
                disabled={disableSendProposalThroughAMP}
                showSkeleton={isLoading}
                onClick={handlerOnChange}
              />
            </Grid>
          </Grid>
          <Grid container xs={12} spacing={5} mb={5} item>
            <Grid item xs={3}>
              <Stack direction="row" justifyContent="end" alignItems="center">
                <Typography>
                  <b>Send project BID to</b>
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={8}>
              <Stack direction="row" justifyContent="start" mt={-2}>
                <LocalEnhancedTable<ISendOutToBidProject>
                  refreshGrid={refreshGrid}
                  columns={columns}
                  data={sendOutToBidProject}
                  defaultRowPerPage={25}
                  hidePagination={true}
                  defaultSortColumn="name"
                  showSkeleton={isLoading}
                />
              </Stack>
            </Grid>
            <Grid item xs={1}>
              {(user?.role === ROLES.Architect ||
                user?.role === ROLES.ArchitectAux) &&
                fullAccess && (
                  <GridActionButton
                    type="add"
                    onClick={() => {
                      setSendBidShowPopUp(true);
                    }}
                    tooltip="Add"
                  />
                )}
            </Grid>
          </Grid>

          <Grid container xs={12} spacing={5} mb={5} item>
            <Grid item xs={3}>
              <Stack direction="row" justifyContent="end" alignItems="center">
                <Typography mt={4.5}>
                  <b> Availability of the project </b>
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={8}>
              <Stack
                direction="row"
                justifyContent="start"
                alignItems="center"
                mt={-2}
              >
                <FormRadioGroup
                  name="publishInAuditMasterProNumb"
                  label=""
                  value={values?.publishInAuditMasterPro ? 0 : 1}
                  onChange={handlerOnAviability}
                  items={radioInformation}
                  disabled={disableComponents || !fullAccess}
                  showSkeleton={isLoading}
                />
              </Stack>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={6}>
              {showAddButtons && (
                <Stack direction={"row"} spacing={4}>
                  <FormButton
                    onClick={() => {
                      setShowAddContractorPopUp(true);
                    }}
                    text="Add AMP Licensed Contractor"
                    size="medium"
                    color="primary"
                    variant="outlined"
                    startIcon={<AddIcon />}
                  />
                  <FormButton
                    onClick={() => {
                      setShowAddPreferredPopUp(true);
                    }}
                    text="Add Preferred Contractors"
                    size="medium"
                    color="primary"
                    variant="outlined"
                    startIcon={<AddIcon />}
                  />
                </Stack>
              )}
            </Grid>

            <Grid item xs={6}>
              <Stack direction={"row"} justifyContent={"end"} spacing={4}>
                <Tooltip
                  TransitionComponent={Zoom}
                  title={
                    "Before awarding a project, only contractors or service providers who have entered a valid 'Project Time Duration' value will be available for consideration. First, select the contractor or contractors you wish to award the project to."
                  }
                >
                  <span>
                    <IconButton aria-label="delete" size="small">
                      <InfoIcon />
                    </IconButton>
                  </span>
                </Tooltip>
                <FormButton
                  onClick={() => {
                    setCheckToAssignShow(true);
                  }}
                  text="Assign Project"
                  size="medium"
                  color="primary"
                  disabled={!isSelected}
                />
              </Stack>
            </Grid>
          </Grid>

          <Grid container xs={12} spacing={5} mb={5} item>
            <Grid item xs={12}>
              {projectSingle?.projectStatus?.name ===
                projectStatus.Contest.name && (
                <LocalEnhancedTable<IProjectContestOwner>
                  refreshGrid={refreshGrid}
                  columns={coluumnsContestOwner}
                  data={projectContest}
                  defaultRowPerPage={25}
                  hidePagination={true}
                  defaultSortColumn="winning"
                  cellCheckBox={false}
                  orderColumn="desc"
                  showSkeleton={isLoading}
                />
              )}
            </Grid>
          </Grid>
          {(user?.role === ROLES.Architect ||
            user?.role === ROLES.ArchitectAux ||
            user?.role === ROLES.SuperAdministrator) && (
            <Grid container justifyContent={"center"} mt={6}>
              <Stack
                direction={"row"}
                spacing={4}
                mt={5}
                justifyContent={"center"}
              >
                <FormBackButton
                  size="small"
                  text="Prev Step"
                  color="primary"
                  variant="outlined"
                  onClick={() => {
                    navigate(
                      `/app/ConfigurationWizard/Edit/${projectId}/${user?.companyId}`
                    );
                  }}
                  isSubmitting={false}
                />
                <FormButton
                  size="small"
                  text="Go Back to Projects"
                  variant="outlined"
                  onClick={() => {
                    navigate(
                      `/app/ProjectArchitect/${projectId}/${filterByTab}`
                    );
                  }}
                />
                {hideSendOutBid && (
                  <FormButton
                    size="small"
                    text="Send Out For Bid "
                    color="primary"
                    onClick={() => {
                      sendOutForBidModal();
                    }}
                  />
                )}
              </Stack>
            </Grid>
          )}
        </Grid>
        {showRatingContractorPopUp && (
          <RatingInformation
            showRatingContractorPopUp={showRatingContractorPopUp}
            setShowRatingContractorPopUp={setShowRatingContractorPopUp}
            informationContractor={informationContractor}
          />
        )}

        {showTotalCombinedPopUp && (
          <TotalCombinedPopUp
            showTotalCombinedPopUp={showTotalCombinedPopUp}
            setShowTotalCombinedPopUp={setShowTotalCombinedPopUp}
            informationContractor={informationContractor}
            codeContractor={codeContractor}
            nameContractor={nameContractor}
            getprojectContest={getContestProject}
            dataEntry={true}
            buildingInformation={buildingInformation}
          />
        )}
        <DeclinedPopUp
          setShowDeclinedPopUp={setShowDeclinedPopUp}
          showDeclinedPopUp={showDeclinedPopUp}
          buildingInformation={buildingInformation}
          projectSingle={projectSingle}
          projectContestSelected={projectContestSelected}
        />
        {sendBidShowPopUp && (
          <SendBidPopUp
            setSendBidShowPopUp={setSendBidShowPopUp}
            sendBidShowPopUp={sendBidShowPopUp}
            projectId={projectId}
            setRefreshSendOut={setRefreshSendOut}
            refreshSendOut={refreshSendOut}
            sendOutToBidProject={sendOutToBidProject}
          />
        )}
        {movePhaseBidShowPopUp && (
          <MovePhaseBid
            movePhaseBidShowPopUp={movePhaseBidShowPopUp}
            setMovePhaseBidShowPopUp={setMovePhaseBidShowPopUp}
            handlerSendForBid={handlerSendForBid}
            loadingNotify={loadingNotify}
          />
        )}

        {showAddContractorPopUp && (
          <AddContractorPopUp
            showContractorPopUp={showAddContractorPopUp}
            setShowContractorPopUp={setShowAddContractorPopUp}
            setRefresh={handleRefreshTable}
            useProjectBid={true}
            projectId={projectId.toString()}
            projectContest={projectContest}
          />
        )}

        <MatchWordConfirmModal
          onConfirm={() => {
            handleDeleteContractor(idSelected);
          }}
          text={`Are you certain you want to delete contractor ${idSelected?.name} from the list?`}
          matchWord={"delete"}
          isDialogOpen={deleteShowPop}
          setIsDialogOpen={setDeleteShowPop}
          withoutOtherText={true}
        />
        {showAddPreferredPopUp && (
          <AddPreferredPopUp
            showAddPreferredPopUp={showAddPreferredPopUp}
            setShowAddPreferredPopUp={setShowAddPreferredPopUp}
            setRefresh={handleRefreshTable}
            projectId={projectId.toString()}
            projectContest={projectContest}
          />
        )}
        {checkToAssignShow && (
          <CheckToAssignPopUp
            checkToAssignShow={checkToAssignShow}
            setCheckToAssignShow={setCheckToAssignShow}
            isSelected={isSelectedArray}
            setRefresh={handleRefreshTable}
            projectContestData={projectContest}
            projectId={projectId}
            projectSingle={projectSingle}
          />
        )}
        <MatchWordConfirmModal
          onConfirm={removeSendOutOfBid}
          text={`Are you certain you want to delete the user ${emailSelected?.name} ${emailSelected?.lastName} from the list?`}
          matchWord={"DELETE"}
          isDialogOpen={deleteEmailModal}
          setIsDialogOpen={setDeleteEmailModal}
          withoutOtherText={true}
        />
      </CardContent>
    </Card>
  );
}
