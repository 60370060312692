import { Skeleton, TextField } from "@mui/material";
import { ChangeEvent, useEffect, useState } from "react";
import { NumericFormat } from "react-number-format";
import { Mode } from "src/ts/types";
import FormReadOnlyField from "./FormReadOnlyField";

interface FormNumericProps {
  name: string;
  label: string;
  value: string;
  size?: "small" | "medium" | undefined;
  maxLength?: number;
  onChange: (e: any) => void;
  onBlur?: (e: any) => void;
  error?: any;
  placeholder?: string;
  suffix?: string;
  prefix?: string;
  decimalScale?: number;
  fullWidth?: string;
  disabled?: boolean;
  mode?: Mode;
  showSkeleton?: boolean;
  color?: "error" | "info" | "primary" | "secondary" | "success" | "warning";
  fixed?: number;
}

const FormNumeric = (props: FormNumericProps) => {
  const {
    name,
    label,
    size,
    value,
    onChange,
    onBlur,
    error,
    maxLength,
    placeholder,
    suffix,
    prefix,
    decimalScale,
    fullWidth,
    disabled,
    mode,
    showSkeleton,
    color,
    fixed,
  } = props;

  const [textValue, setTextValue] = useState<string>(value);

  const handleOnChangePhone = (event: ChangeEvent<HTMLInputElement>) => {
    setTextValue(event.target.value);
    onChange(convertToDefEventPara(name, event.target.value));
  };

  useEffect(() => {
    if (prefix && value) {
      setTextValue(`${prefix}${value}`);
    }

    if (suffix && value) {
      setTextValue(`${value}${suffix}`);
    }

    if (!prefix && !suffix) setTextValue(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const convertToDefEventPara = (name: string, value: string) => {
    if (prefix) {
      value = value.replaceAll(prefix, "");
    }

    if (suffix) {
      value = value.replaceAll(suffix, "");
    }

    return {
      target: {
        name,
        value: value,
      },
    };
  };

  const getMaxLength = () => {
    let calculatedMaxLength = null;
    if (maxLength === undefined) calculatedMaxLength = null;

    if (prefix) {
      calculatedMaxLength = maxLength! + prefix.length;
    }

    if (suffix && maxLength) {
      calculatedMaxLength = maxLength! + suffix.length;
    }

    if (decimalScale && maxLength) {
      calculatedMaxLength = maxLength! + decimalScale + 1;
    }

    return calculatedMaxLength;
  };

  if (mode && mode === "read")
    return (
      <FormReadOnlyField
        label={label}
        value={`${prefix ?? ""} ${
          !isNaN(Number(value.toString()))
            ? Number(value).toFixed(fixed ?? 2)
            : value
        } ${suffix ?? ""}`}
        valueColor={color}
      />
    );

  return (
    <>
      {showSkeleton ? (
        <Skeleton height={50} />
      ) : (
        <NumericFormat
          value={textValue}
          customInput={TextField}
          size={size ?? "small"}
          onChange={handleOnChangePhone}
          name={name}
          label={label}
          placeholder={placeholder}
          suffix={suffix}
          prefix={prefix}
          {...(error && { error: true, helperText: error })}
          inputProps={{
            maxLength: getMaxLength(),
          }}
          onBlur={onBlur}
          decimalScale={decimalScale}
          fullWidth={fullWidth ?? true}
          disabled={disabled}
          color={color}
        />
      )}
    </>
  );
};

export default FormNumeric;
