import { Alert, Box } from "@mui/material";
import { FormButton } from "src/components/formControls";

interface Props {
  showIncompleteStudiesPopup: () => void;
  cancelHandler: () => void;
}

const CompleteValidateStudies = ({
  showIncompleteStudiesPopup,
  cancelHandler,
}: Props) => {
  const showIncompleteStudiesHandler = () => {
    cancelHandler();
    showIncompleteStudiesPopup();
  };

  return (
    <>
      <Alert severity="error" sx={{ width: "100%" }}>
        This Project has Incomplete Studies.
        <br />
        Completing this Project will close and register as Incomplete
        <br />
        <b>WARNING: NO ADDITIONAL CHANGES WILL BE ALLOWED. </b>
        <br />
        <br />
        Are you certain you want to continue?
      </Alert>
      <br />
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <FormButton
          text="View Incomplete Studies"
          onClick={showIncompleteStudiesHandler}
          color="primary"
        />
      </Box>
    </>
  );
};

export default CompleteValidateStudies;
