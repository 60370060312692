import React, { useState } from "react";
import styled from "@emotion/styled";
import {
  Card as MuiCard,
  CardActions,
  CardContent as MuiCardContent,
  CardMedia,
  Typography,
  Stack,
} from "@mui/material";
import { spacing } from "@mui/system";
import { GridActionButton } from "src/components/gridControls";
import { pictureLogService } from "src/services";
import FileUtils from "src/utils/file";
import { IPictureLogDto } from "src/ts/interfaces";
import EditPictureLogModal from "./EditPictureLogModal";
import { useAsyncMutation } from "src/hooks";
import MatchWordConfirmModal from "src/components/modals/MatchWordConfirmModal";

const Card = styled(MuiCard)(spacing);

const CardContent = styled(MuiCardContent)`
  border-bottom: 1px solid ${(props) => props.theme.palette.grey[300]};
`;

type ProjectProps = {
  chip: JSX.Element;
  pictureLog: IPictureLogDto;
  onRefreshGrid: () => void;
};

const PictureLogItem: React.FC<ProjectProps> = ({
  chip,
  pictureLog,
  onRefreshGrid,
}) => {
  const [showEditPopup, setshowEditPopup] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const downloadDocument = async () => {
    const response = await pictureLogService.downloadFile(pictureLog.id);
    FileUtils.downloadFile3(response.data.document, pictureLog.name);
  };

  const { execute: updatePictureLog } = useAsyncMutation(
    pictureLogService.update,
    {
      successfulMessage: "Data was updated successfully",
      errorMessage: "An error was ocurred",
    }
  );

  const saveHandler = async (pictureLog: IPictureLogDto) => {
    await updatePictureLog(pictureLog);
    setshowEditPopup(false);
    onRefreshGrid();
  };

  const { execute: deletePictureLog } = useAsyncMutation(
    pictureLogService.removePicture,
    {
      successfulMessage: "Data was deleted successfully",
      errorMessage: "An error was ocurred",
    }
  );

  const deleteHandler = async () => {
    await deletePictureLog(pictureLog.id);
    setShowDeleteModal(false);
    onRefreshGrid();
  };

  return (
    <>
      <Card>
        <CardMedia
          style={{ height: 200 }}
          component="img"
          src={`data:image/png;base64, ${pictureLog.thumbDocument}`}
        />
        <CardContent>
          {chip}
          <Typography mb={4} color="textSecondary" component="p">
            {pictureLog.title === "" || pictureLog.title === null
              ? "Untitle"
              : pictureLog.title}
          </Typography>
        </CardContent>
        <CardActions>
          <Stack direction="row">
            <GridActionButton
              type="download"
              onClick={downloadDocument}
              tooltip="Download"
            />
            <GridActionButton
              type="edit"
              onClick={() => {
                setshowEditPopup(true);
              }}
              tooltip="Edit"
            />
            <GridActionButton
              type="delete"
              onClick={() => setShowDeleteModal(true)}
              tooltip="Delete"
            />
          </Stack>
        </CardActions>
      </Card>
      <EditPictureLogModal
        isDialogOpen={showEditPopup}
        setIsDialogOpen={setshowEditPopup}
        picture={pictureLog}
        onSave={saveHandler}
      />
      <MatchWordConfirmModal
        onConfirm={deleteHandler}
        text={`Are you certain you want to delete this item?`}
        matchWord={"delete"}
        isDialogOpen={showDeleteModal}
        setIsDialogOpen={setShowDeleteModal}
      />
    </>
  );
};

export default PictureLogItem;
