import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import {
  Grid,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
  Typography,
  Card as MuiCard,
  CardContent,
  Stack,
  Button,
} from "@mui/material";
import { Add as AddIcon } from "@mui/icons-material";
import { spacing } from "@mui/system";
import { ColumnType } from "src/types/enhancedTable";
import LocalEnhancedTable from "src/components/localTable/LocalTable";
import useAsyncQuery from "src/hooks/useAsyncQuery";
import { GridActionButton } from "src/components/gridControls";
import unitLabelService from "src/services/catalogs/unitLabelService";
import { IUnitLabel } from "src/ts/interfaces/catalogs";
import UnitLabelForm from "./UnitLabelForm";
import MatchWordConfirmModal from "src/components/modals/MatchWordConfirmModal";
import { useLog } from "src/hooks";
import { Permission as PermissionTypes } from "src/ts/enums";
import { usePermissions } from "src/hooks";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const initialValues: IUnitLabel = {
  id: 0,
  key: "",
  metricSystem: "",
  imperialSystem: "",
};

const Units = () => {
  const { log } = useLog();

  const { execute, data, isLoading } = useAsyncQuery<IUnitLabel[]>(
    unitLabelService.getAll
  );
  const [refreshPage, setRefreshPage] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [confirmDialogIsOpen, setConfirmDialogIsOpen] = useState(false);
  const [currentRow, setCurrentRow] = useState<IUnitLabel>();

  useEffect(() => {
    const getData = async () => {
      await execute();
    };

    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshPage]);

  const handleEdit = (row: IUnitLabel) => {
    setCurrentRow(row);
    setIsDialogOpen(true);
  };

  const selectDeleteHandler = (row: IUnitLabel) => {
    setCurrentRow(row);
    setConfirmDialogIsOpen(true);
  };

  const deleteHandler = async () => {
    if (currentRow !== undefined && currentRow?.id > 0) {
      try {
        await unitLabelService.remove(currentRow?.id);
        log.success("The item was deleted");
        refreshGridHandler();
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      }
    }
  };

  const newHandler = () => {
    setCurrentRow(initialValues);
    setIsDialogOpen(true);
  };

  const refreshGridHandler = () => {
    setRefreshPage(!refreshPage);
  };

  const { fullAccess } = usePermissions(PermissionTypes.Catalogs);

  const columns: ColumnType[] = [
    {
      id: "id",
      label: "#",
      type: "string",
      sort: true,
    },
    {
      id: "key",
      label: "Key",
      type: "string",
      sort: true,
    },
    {
      id: "imperialSystem",
      label: "Imperial System",
      type: "string",
      sort: true,
    },
    {
      id: "metricSystem",
      label: "Metric System",
      type: "string",
      sort: true,
    },
    {
      id: "x",
      label: "Actions",
      type: "custom",
      sort: false,
      callback: (row: IUnitLabel) => (
        <div>
          <Stack direction="row">
            <GridActionButton
              type={fullAccess ? "edit" : "view"}
              onClick={() => {
                handleEdit(row);
              }}
              tooltip={fullAccess ? "Edit" : "View"}
            />
            {fullAccess && (
              <GridActionButton
                type="delete"
                onClick={() => selectDeleteHandler(row)}
                tooltip="Delete"
              />
            )}
          </Stack>
        </div>
      ),
    },
  ];

  return (
    <>
      <Helmet title="Subscription Costs" />
      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Units
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} to="/">
              Dashboard
            </Link>
            <Typography>Units</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item>
          <div>
            {fullAccess && (
              <Button onClick={newHandler} variant="contained" color="primary">
                <AddIcon />
                {`New Unit Label`}
              </Button>
            )}
          </div>
        </Grid>
      </Grid>

      <Divider my={6} />
      <Card mb={6}>
        <CardContent>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <LocalEnhancedTable<IUnitLabel>
                refreshGrid={true}
                columns={columns}
                data={data ?? []}
                showSkeleton={isLoading}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <UnitLabelForm
        selectedValue={currentRow}
        isDialogOpen={isDialogOpen}
        setIsDialogOpen={setIsDialogOpen}
        onUpdate={refreshGridHandler}
      />
      <MatchWordConfirmModal
        onConfirm={deleteHandler}
        text={`Are you certain you want to delete key: ${currentRow?.key}?`}
        matchWord={"DELETE"}
        isDialogOpen={confirmDialogIsOpen}
        setIsDialogOpen={setConfirmDialogIsOpen}
      />
    </>
  );
};

export default Units;
