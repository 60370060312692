import React, { useEffect } from "react";
import { Grid } from "@mui/material";
import { useAsyncQuery, useForm, useLog } from "src/hooks";
import { FormText, FormMaskText } from "src/components/formControls";
import useLoading from "src/hooks/useLoading";
import { UserParams } from "src/ts/types";
import { buildingService } from "src/services";
import { GridActionButton } from "src/components/gridControls";
import { IBuildingPhoneNumber } from "src/ts/interfaces";
import { useParams } from "react-router-dom";

interface IBuildingPhoneNumberProps {
  readonly: boolean;
  addPhone: number;
  saveItems: number;
  buildingId: number;
  countryCode: string;
  setBuildingPhones: (val: IBuildingPhoneNumber[]) => void;
}

const BuildingPhoneNumber = (props: IBuildingPhoneNumberProps) => {
  const { log } = useLog();
  let { id } = useParams<UserParams>();
  const validate = (fieldValues = values) => {
    let temp: Record<string, string> = {};

    values.forEach((phones: any, index: number) => {
      if (phones.phone && phones.phone.length !== 13) {
        temp["phoneNumber" + index] = "Incorrect phone format";
      } else {
        temp["phoneNumber" + index] = "";
      }
    });
    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };
  const { values, setValues, errors, setErrors } = useForm([], false, validate);
  const { startRequest, endRequest } = useLoading();

  const { execute } = useAsyncQuery(buildingService.deletePhone);

  useEffect(() => {
    if (props.addPhone > 0) handlerAddContactPhone();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.addPhone]);

  useEffect(() => {
    if (props.saveItems > 0) {
      if (!validate()) return;
      handleSave();
      log.success("Building's data was updated");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.saveItems]);

  useEffect(() => {
    const getUser = async (buildingId: number) => {
      startRequest("user");

      const response = await buildingService.GetPhoneNumbers(buildingId);
      const newValue = response.data.filter((item) => {
        return item.phone !== "";
      });
      setValues(newValue);
      endRequest("user");
    };

    if (Number(id) > 0) {
      getUser(Number(id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const handlerAddContactPhone = () => {
    const item: IBuildingPhoneNumber = {
      id: 0,
      phone: "",
      buildingId: props.buildingId,
      createdDate: new Date(),
      createdBy: "",
      modifiedDate: new Date(),
      modifiedBy: "",
    };
    setValues([...values, item]);
  };

  const handlerDeleteContactPhone = async (index: number) => {
    if (values[index].id !== 0) await execute(values[index].id);
    values.splice(index, 1);
    setValues([...values]);
  };

  const handlerPhoneNumberUpdate = (index: number, phone: string) => {
    values[index].phone = phone;
    setValues([...values]);
    props.setBuildingPhones([...values]);
  };

  const handleSave = async () => {
    try {
      values.forEach((item: any) => {
        item.buildingId = props.buildingId;
      });
      await buildingService.updatePhones(values);
    } catch (error: any) {
      log.error(error?.message?.exceptionMessage ?? "Something went wrong");
    } finally {
    }
  };

  const row = 6;

  return (
    <>
      {values && values.length > 0 && (
        <Grid item xs={row}>
          {values?.map((phone: any, key: number) => (
            <Grid container>
              <Grid item xs={1.5}>
                <FormText
                  name="countryCode"
                  label="Code"
                  value={"+ " + props.countryCode}
                  disabled={true}
                />
              </Grid>
              <Grid item xs={9.5}>
                <FormMaskText
                  name={"phoneNumber" + key}
                  mask="(999) 999 - 9999"
                  label="Contact's Phone Number"
                  onChange={(e) =>
                    handlerPhoneNumberUpdate(key, e.target.value)
                  }
                  value={phone.phone}
                  error={errors["phoneNumber" + key]}
                  disabled={props.readonly}
                />
              </Grid>
              <Grid item xs={1}>
                <GridActionButton
                  type="delete"
                  onClick={() => {
                    handlerDeleteContactPhone(key);
                  }}
                  tooltip="Delete"
                />
              </Grid>
            </Grid>
          ))}
        </Grid>
      )}
    </>
  );
};

export default BuildingPhoneNumber;
