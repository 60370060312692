import { useForm } from "src/hooks";
import { ISystemCostsCatalog } from "src/ts/interfaces";
import React, { useEffect, useState } from "react";
import { Grid, Typography, Stack } from "@mui/material";
import { Form } from "src/hooks/useForm";
import {
  FormCancelButton,
  FormSaveButton,
  FormText,
  FormTextArea,
} from "src/components/formControls";
import Popup from "src/components/Popup";
import { Validator } from "src/ts/types/Validator";
import DialogMessagePopup from "src/components/DialogMessagePopup";
import { Permission as PermissionTypes } from "src/ts/enums";
import { usePermissions } from "src/hooks";

interface SubscriptionCostFormProps {
  selectedValue?: ISystemCostsCatalog;
  isDialogOpen: boolean;
  setIsDialogOpen: (val: boolean) => void;
  setSelectedValue: (val: ISystemCostsCatalog) => void;
  onContinue: () => void;
}

const initialValues: ISystemCostsCatalog = {
  id: 0,
  name: "",
  statusId: 0,
  orderPosition: 0,
  pricing: 0,
  classification: "",
  code: "",
  value: "",
  variableCost: false,
  variablePurchasePricing: false,
  purchasePricing: 0,
  createdBy: "",
  createdDate: "",
  updatedBy: "",
  updatedDate: "",
  comments: "",
  document: false,
};

const SubscriptionCostForm = (props: SubscriptionCostFormProps) => {
  const {
    selectedValue,
    isDialogOpen,
    setIsDialogOpen,
    setSelectedValue,
    onContinue,
  } = props;

  const validate = (fieldValues = values) => {
    let temp: Record<string, string> = { ...errors };

    temp.name = new Validator(fieldValues, "name")
      .isRequired("The SystemCostsCatalog's name is required")
      .maxLength(200, "Only 200 character are allowed")
      .toString();

    temp.pricing = new Validator(fieldValues, "pricing")
      .isRequired("The SystemCostsCatalog's cost is required.")
      .toString();

    temp.comments = new Validator(fieldValues, "comments")
      .maxLength(500, "Only 500 character are allowed")
      .toString();

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues, false, validate);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  useEffect(() => {
    if (isDialogOpen) setValues(selectedValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDialogOpen]);

  const handleClose = () => {
    resetForm();
    setIsDialogOpen(false);
  };

  const handleSave = async () => {
    if (!validate()) return;
    setSelectedValue(values);
    setShowDeleteModal(true);
  };

  const { fullAccess, readOnly } = usePermissions(PermissionTypes.Catalogs);

  const row = 12;
  return (
    <>
      <Popup
        openPopup={isDialogOpen}
        setOpenPopup={setIsDialogOpen}
        title={`Edit`}
        onClose={() => setIsDialogOpen(false)}
      >
        <Form>
          <Grid container spacing={5}>
            <Grid item xs={row}>
              <Typography variant="subtitle2" gutterBottom>
                Code
              </Typography>
              <Typography variant="body2" gutterBottom></Typography>
            </Grid>
            <Grid item xs={row}>
              <FormText
                name="name"
                label="Name"
                value={values.name}
                onChange={handleInputChange}
                error={errors.name}
                showSkeleton={false}
                disabled={readOnly}
              />
            </Grid>
            <Grid item xs={row}>
              <FormText
                name="pricing"
                label="Pricing"
                value={values.pricing}
                onChange={handleInputChange}
                error={errors.pricing}
                showSkeleton={false}
                disabled={readOnly}
              />
            </Grid>
            <Grid item xs={row}>
              <FormTextArea
                name="comments"
                label="Comments"
                value={values.comments}
                onChange={handleInputChange}
                error={errors.comments}
                placeholder=""
                disabled={readOnly}
              />{" "}
            </Grid>
          </Grid>
        </Form>
        <>
          <Stack direction="row" spacing={2}>
            <FormCancelButton onClick={handleClose} isSubmitting={false} />
            {fullAccess && (
              <FormSaveButton onClick={handleSave} isSubmitting={false} />
            )}
          </Stack>
        </>
      </Popup>
      <DialogMessagePopup
        title={"Cost Update Alert"}
        text={
          "You are about to UPDATE an item in System Cost Catalog. \nAre you certain you want to continue?"
        }
        showPopup={showDeleteModal}
        setShowPopup={setShowDeleteModal}
        onSave={() => {
          setShowDeleteModal(false);
          setIsDialogOpen(false);
          onContinue();
        }}
        isSubmitting={false}
      />
    </>
  );
};

export default SubscriptionCostForm;
