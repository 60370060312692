import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Grid,
  CardContent,
  Typography,
  styled,
  Card as MuiCard,
} from "@mui/material";
import { ColumnType } from "src/types/enhancedTable";
import LocalEnhancedTable from "src/components/localTable/LocalTable";

import { useAuth } from "src/hooks";
import { IAssets, ITypeAsset } from "src/ts/interfaces/companyAssets";
import { spacing } from "@mui/system";
import SatellitePopUp from "./SatellitePopUp";
import AssesmentReport from "./AssesmentReport";

import { CompanyAssetEquipmentColumns } from "./columnsTable/EquipmentColumns";
import { CompanyAssetVehicleColumns } from "./columnsTable/VehicleColumns";

const Card = styled(MuiCard)(spacing);

interface Props {
  data: IAssets[];
  isLoading: boolean;
  asset: string;
  companyId: string;
  setRefreshGrid: (value: boolean) => void;
  refreshGrid: boolean;
  filterValues: string;
  information: ITypeAsset;
}

const CompanyAssets = (props: Props) => {
  const { data, isLoading, asset, companyId, filterValues, information } =
    props;
  const [dataTable, setDataTable] = useState<IAssets[]>([]);
  const [showSatelliteModal, setShowSatelliteModal] = useState(false);
  const [showAssessmentHistory, setShowAssessmentHistory] = useState(false);
  const [trasnferAssigType, setTransferAssigType] = useState("");
  const [currentRow, setCurrentRow] = useState<IAssets>();
  const [columns, setColumns] = useState<ColumnType[]>([]);

  const { user } = useAuth();
  let navigate = useNavigate();

  useEffect(() => {
    const getData = () => {
      if (asset === "VEHI") {
        if (data !== null) {
          const filter = data.filter((item: IAssets) => {
            return item.assignedToId !== user?.userId;
          });
          setDataTable(filter);
        }
      } else {
        const filter = data.filter((item: IAssets) => {
          return item.assignedToId !== user?.userId;
        });
        setDataTable(filter);
      }
    };

    if (data !== null) getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    setColumns(
      asset === "VEHI"
        ? CompanyAssetVehicleColumns(
            setCurrentRow,
            setShowSatelliteModal,
            setShowAssessmentHistory,
            setTransferAssigType,
            navigate,
            companyId
          )
        : CompanyAssetEquipmentColumns(
            setCurrentRow,
            setShowSatelliteModal,
            setShowAssessmentHistory,
            setTransferAssigType,
            navigate,
            companyId
          )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  const filterType =
    information.name === "Vehicle"
      ? ["vehicle.make", "vehicle.year", "vehicle.model"]
      : [
          "equipment.name",
          "equipment.description",
          "equipment.serialNumber",
          "equipment.model",
        ];
  return (
    <>
      <Card mb={6}>
        <CardContent>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Typography variant="h6">Company's </Typography>
            </Grid>
            <Grid item xs={12}>
              <LocalEnhancedTable<IAssets>
                refreshGrid={true}
                columns={columns}
                data={dataTable}
                showSkeleton={isLoading}
                skeletonRows={1}
                hidePagination={false}
                defaultRowPerPage={10}
                query={filterValues}
                filterCustom={filterType}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      {showSatelliteModal && (
        <SatellitePopUp
          showSatelliteModal={showSatelliteModal}
          setShowSatelliteModal={setShowSatelliteModal}
          currentRow={currentRow ?? {}}
          transactionType={trasnferAssigType}
        />
      )}
      {showAssessmentHistory && (
        <AssesmentReport
          showAssessmentHistory={showAssessmentHistory}
          setShowAssessmentHistory={setShowAssessmentHistory}
          currentRow={currentRow ?? {}}
          asset={asset}
          permissionRole={false}
          assetStatus={""}
        />
      )}
    </>
  );
};

export default CompanyAssets;
