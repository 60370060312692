import { useState } from "react";
import useLog from "src/hooks/useLog";
import {
  Grid,
  Stepper,
  Step,
  StepLabel,
  Stack,
  Typography,
} from "@mui/material";
import Popup from "src/components/Popup";
import { FormButton } from "src/components/formControls";
import { useForm } from "src/hooks";
import { Validator } from "src/ts/types";
import { useNavigate } from "react-router-dom";
import {
  IPaymentSummary,
  IPostTechnician,
  IPurchaseSummary,
  ITechnician,
} from "src/ts/interfaces/technician";
import PaymentSummary from "./PaymentSummary";
import Document from "./Document";
import technicianService from "src/services/technicianService";
import { ICompany } from "src/ts/interfaces";

interface PayPopupProps {
  showBuyModal: boolean;
  setShowBuyModal: (e: boolean) => void;
  onChangeShowBuyModal: (e: boolean) => void;
  companyId: number;
  companyName: string;
  purchaseSummary: IPurchaseSummary;
  completeName: string;
  userId: string;
  roleTechVal: string;
  data: ITechnician;
  companyInfo: ICompany | undefined;
}

const steps = ["Payment", "Documents"];

const initialValues: IPaymentSummary = {
  code: "",
  paymentOption: 1,
};

const ConfirmationPayment = (props: PayPopupProps) => {
  const {
    showBuyModal,
    setShowBuyModal,
    onChangeShowBuyModal,
    purchaseSummary,
    completeName,
    userId,
    companyId,
    roleTechVal,
    data,
    companyInfo,
  } = props;

  const { log } = useLog();
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState(0);
  const [newUser, setNewUser] = useState("");
  const [discountCode, setDiscountCod] = useState(0);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isSubmitting, setIsSubmitting] = useState(false);

  const validate = (fieldValues = values) => {
    let temp: Record<string, string> = { ...errors };

    temp.code = new Validator(fieldValues, "code")
      .isRequired("Please enter a value for the coupon code.")
      .toString();

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues, false, validate);

  const closeHandler = () => {
    onChangeShowBuyModal(false);
  };

  const handlerAccept = async () => {
    try {
      setIsSubmitting(true);
      const sentToType = values.paymentOption === 1 ? true : false;

      let dataPost: IPostTechnician = {
        name: data.name,
        lastName: data.lastName,
        address: data.address,
        zip: Number(data.zip),
        city: data.city,
        phone: data.phone,
        cellPhone: data.cellPhone,
        idNumber: data.idNumber,
        CountryId: data.countryId,
        countryId: data.countryId,
        country: { id: data.countryId, name: data?.country?.name ?? "" },
        state:
          data.stateName === ""
            ? { id: data?.state?.id ?? 0, name: data?.state?.name ?? "" }
            : null,
        stateName: data.stateName,
        stateId: data.stateName === "" ? data.stateId : null,
        idType: data?.identification?.id,
        identification: {
          id: data?.identification?.id ?? 0,
          name: data?.identification?.name ?? "",
        },
        CompanyId: companyId.toString(),
        email: data.email,
        certificationType: roleTechVal,
        certificationCost:
          discountCode === 0
            ? purchaseSummary.selectionTotal.toString()
            : discountCode.toString(),
        count: 1,
        countryCode: data?.country?.countryCode?.toString(),
        certificationId: data.certificationId,
        idOther: data.idOther ?? "",
        createdDate: new Date(),
      };
      if (data.unionAffiliated?.unionAffiliatedAnswer === true) {
        dataPost = {
          ...dataPost,
          unionAffiliated: {
            unionAffiliatedAnswer: data.unionAffiliated.unionAffiliatedAnswer,
            unionAffiliationNumbersOther:
              data.unionAffiliated.unionAffiliationNumbersOther ?? "",
            smwiaMember: data.unionAffiliated.smwiaMember ?? "",
            apprentice: data.unionAffiliated.apprentice ?? false,
            laborUnionsId: data.unionAffiliated.laborUnionsId,
            localUnionsId: data.unionAffiliated.localUnionsId,
            localUnionsOther: data.unionAffiliated.localUnionsOther,
            laborUnionsOther: data.unionAffiliated.laborUnionsOther,
          },
        };
      }

      const response = await technicianService.postNewTechinician(
        sentToType,
        data.certificationId,
        values.code,
        dataPost
      );
      setNewUser(response.data.userId);
      handleNext();
    } catch (error: any) {
      log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      setShowBuyModal(false);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <Popup
        openPopup={showBuyModal}
        setOpenPopup={setShowBuyModal}
        title={activeStep === 0 ? "Confirmation" : "Information"}
        onClose={() => {
          setShowBuyModal(false);
        }}
        size={activeStep === 0 ? "md" : "sm"}
        disableClickOutside={true}
        isSubmitting={isSubmitting}
      >
        <Grid container>
          <Grid item xs={12} md={12}>
            <Stepper activeStep={activeStep}>
              {steps.map((label, index) => {
                const stepProps: { completed?: boolean } = {};
                const labelProps: {
                  optional?: React.ReactNode;
                } = {};

                return (
                  <Step key={label} {...stepProps}>
                    <StepLabel {...labelProps}>{label}</StepLabel>
                  </Step>
                );
              })}
            </Stepper>
          </Grid>
          {activeStep === 0 && (
            <PaymentSummary
              purchaseSummary={purchaseSummary}
              completeName={completeName}
              values={values}
              setValues={setValues}
              handleInputChange={handleInputChange}
              setDiscountCod={setDiscountCod}
              resetForm={resetForm}
              companyInfo={companyInfo}
              requestedRole={roleTechVal}
              isSubmitting={isSubmitting}
            />
          )}
          {activeStep === 1 && (
            <Document
              values={values}
              userId={userId}
              companyId={companyId}
              newUser={newUser}
            />
          )}
        </Grid>

        <>
          {activeStep === 0 && (
            <Typography mr={10}>Do you want to proceed? </Typography>
          )}
          <Stack direction="row" spacing={2}>
            {activeStep === 0 && (
              <>
                <FormButton
                  text={"Close"}
                  onClick={closeHandler}
                  size="small"
                  variant="outlined"
                  isSubmitting={isSubmitting}
                />

                <FormButton
                  text={"Accept"}
                  onClick={handlerAccept}
                  size="small"
                  color="primary"
                  isSubmitting={isSubmitting}
                />
              </>
            )}
            {activeStep === 1 && (
              <FormButton
                text={"Accept"}
                onClick={() => {
                  setShowBuyModal(false);
                  navigate(`/app/Technicians/${companyId}`);
                }}
                size="small"
                color="primary"
                isSubmitting={isSubmitting}
              />
            )}
          </Stack>
        </>
      </Popup>
    </>
  );
};

export default ConfirmationPayment;
