import { useEffect, useState } from "react";
import { Grid, Typography } from "@mui/material";
import { useAsyncQuery } from "src/hooks";
import {
  ICoilsItems,
  ICoilsItemsCircuits,
} from "src/ts/interfaces/study/coils";
import coilsItemsService from "src/services/study/coilsItemsService";
import Spreadsheet, {
  ISpreadsheetColumn,
} from "src/components/spreadsheet/Spreadsheet";
import FormTooltip from "src/components/formControls/FormTooltip";
import SpreadSheetCell from "src/components/spreadsheet/SpreadSheetCell";
import SpreadsheetNumericInput2 from "src/components/spreadsheet/SpreadsheetNumericInput2";
interface Props {
  data: ICoilsItems;
  refreshCircuits: boolean;
  disableStudy: boolean;
}

const CoilsBank = ({ data, refreshCircuits, disableStudy }: Props) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [bank, setBank] = useState<ICoilsItemsCircuits>();

  const {
    execute: executeBank,
    data: dataBank,
    setData: setDataBank,
    isLoading,
  } = useAsyncQuery<ICoilsItemsCircuits[]>(coilsItemsService.getBanksByItemId);

  const { execute: executeBankBanks } = useAsyncQuery<ICoilsItemsCircuits[]>(
    coilsItemsService.getBanksByItemId,
    {
      onSuccess(result) {
        setDataBank(result);
      },
    }
  );

  const { execute: executeSaveBank } = useAsyncQuery<ICoilsItemsCircuits>(
    coilsItemsService.saveBank,
    {
      onSuccess(result) {
        setBank(result);
        const modifiedItems = dataBank.map((item, i) =>
          item.id === result.id ? result : item
        );
        setDataBank(modifiedItems);
      },
    }
  );

  const { execute: executeSaveBankGet } = useAsyncQuery<ICoilsItemsCircuits>(
    coilsItemsService.saveBank,
    {
      onSuccess: async (result) => {
        await executeSaveItem(data);
      },
    }
  );

  const { execute: executeSaveItem } = useAsyncQuery<ICoilsItems>(
    coilsItemsService.save,
    {
      onSuccess: async (result) => {
        await executeBankBanks(data.id);
      },
    }
  );

  useEffect(() => {
    const getData = async () => {
      await executeBank(data.id);
    };
    if (data && data?.id > 0) getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.id]);

  useEffect(() => {
    const getData = async () => {
      await executeBank(data.id);
    };
    if (data && data.id > 0) getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshCircuits]);

  const handleBlurBank = async (item: any) => {
    await executeSaveBank(item);
  };

  const handleBlurBankGet = async (item: any) => {
    await executeSaveBankGet(item);
  };

  const columns: ISpreadsheetColumn[] = [
    {
      name: "index",
      type: "index",
      label: "Balance Setter Device",
      align: "center",
      disabled: true,
      mode: "read",
    },
    {
      name: "circuitDesignGPM",
      type: "numericInput",
      label: "GPM Design",
      mode: "read",
      decimalScale: 3,
      thousandSeparator: true,
      disabled: disableStudy,
    },
    {
      name: "deviceSize",
      type: "numericInput",
      label: "Device Size In",
      onChange: (row: ICoilsItemsCircuits, e: any, index: number): void => {
        row.deviceSize = e.target.value;
        handleBlurBank(row);
      },
      decimalScale: 3,
      thousandSeparator: true,
      disabled: disableStudy,
    },
    {
      name: "circuitActualGPM",
      type: "numericInput",
      label: "GPM Actual",
      borderColor: "red",
      onChange: (row: ICoilsItemsCircuits, e: any, index: number): void => {
        row.circuitActualGPM = e.target.value;
        handleBlurBankGet(row);
      },
      decimalScale: 3,
      thousandSeparator: true,
      disabled: disableStudy,
    },
    {
      name: "",
      type: "string",
      label: (
        <Typography>
          <Typography display={"inline"} variant={"subtitle2"} fontWeight={600}>
            As Found-Data
          </Typography>
          <Typography display={"inline"}>
            <FormTooltip padding="1px" titleName="As Found-Data"></FormTooltip>
          </Typography>
        </Typography>
      ),
      disabled: true,
    },
    {
      name: "asFoundSPInlet",
      type: "numericInput",
      label: "Entering Water PSI",
      onChange: (row: ICoilsItemsCircuits, e: any, index: number): void => {
        row.asFoundSPInlet = e.target.value;
        handleBlurBank(row);
      },
      decimalScale: 3,
      thousandSeparator: true,
      disabled: disableStudy,
    },
    {
      name: "asFoundSPOutlet",
      type: "numericInput",
      label: "Leaving Water PSI",
      onChange: (row: ICoilsItemsCircuits, e: any, index: number): void => {
        row.asFoundSPOutlet = e.target.value;
        handleBlurBank(row);
      },
      decimalScale: 3,
      thousandSeparator: true,
      disabled: disableStudy,
    },
    {
      name: "asFoundSPDelta",
      type: "numericInput",
      label: "Water Δ PSI",
      mode: "read",
      decimalScale: 3,
      thousandSeparator: true,
      disabled: disableStudy,
    },
    {
      name: "",
      type: "string",
      label: (
        <Typography>
          <Typography display={"inline"} variant={"subtitle2"} fontWeight={600}>
            Wide-Open-Data
          </Typography>
          <Typography display={"inline"}>
            <FormTooltip padding="1px" titleName="Wide-Open-Data"></FormTooltip>
          </Typography>
        </Typography>
      ),
      disabled: true,
    },
    {
      name: "valvsOpenSPInlet",
      type: "numericInput",
      label: "Entering Water PSI",
      onChange: (row: ICoilsItemsCircuits, e: any, index: number): void => {
        row.valvsOpenSPInlet = e.target.value;
        handleBlurBank(row);
      },
      decimalScale: 3,
      thousandSeparator: true,
      disabled: disableStudy,
    },
    {
      name: "valvsOpenSPOutlet",
      type: "numericInput",
      label: "Leaving Water PSI",
      onChange: (row: ICoilsItemsCircuits, e: any, index: number): void => {
        row.valvsOpenSPOutlet = e.target.value;
        handleBlurBank(row);
      },
      decimalScale: 3,
      thousandSeparator: true,
      disabled: disableStudy,
    },
    {
      name: "valvsOpenSPDelta",
      type: "numericInput",
      label: "Water Δ PSI",
      mode: "read",
      decimalScale: 3,
      thousandSeparator: true,
      disabled: disableStudy,
    },
    {
      name: "",
      type: "string",
      label: (
        <Typography>
          <Typography display={"inline"} variant={"subtitle2"} fontWeight={600}>
            As Left/Balanced-Data
          </Typography>
          <Typography display={"inline"}>
            <FormTooltip padding="1px" titleName="As Left-Data"></FormTooltip>
          </Typography>
        </Typography>
      ),
      disabled: true,
    },
    {
      name: "asLeftSPInlet",
      type: "numericInput",
      label: "Entering Water PSI",
      onChange: (row: ICoilsItemsCircuits, e: any, index: number): void => {
        row.asLeftSPInlet = e.target.value;
        handleBlurBank(row);
      },
      decimalScale: 3,
      thousandSeparator: true,
      disabled: disableStudy,
    },
    {
      name: "asLeftSPOutlet",
      type: "numericInput",
      label: "Leaving Water PSI",
      onChange: (row: ICoilsItemsCircuits, e: any, index: number): void => {
        row.asLeftSPOutlet = e.target.value;
        handleBlurBank(row);
      },
      decimalScale: 3,
      thousandSeparator: true,
      disabled: disableStudy,
    },
    {
      name: "asLeftSPDelta",
      type: "numericInput",
      label: "Water Δ PSI",
      mode: "read",
      decimalScale: 3,
      thousandSeparator: true,
      disabled: disableStudy,
    },
    {
      name: "",
      type: "string",
      label: "Water Temperature",
      mode: "read",
      disabled: disableStudy,
    },
    {
      name: "circuitEnteringWaterTemperature",
      type: "custom",
      label: "Entering T °F",
      decimalScale: 3,
      thousandSeparator: true,
      disabled: disableStudy,
      render: (
        row: ICoilsItemsCircuits,
        col: ISpreadsheetColumn,
        index: number
      ) => {
        return (
          <SpreadSheetCell>
            <>
              <SpreadsheetNumericInput2
                value={row.circuitEnteringWaterTemperature}
                onChange={(e: any) => {
                  row.circuitEnteringWaterTemperature = e.target.value;
                  setBank(row);
                }}
                onBlur={(e: any) => {
                  row.circuitEnteringWaterTemperature = e.target.value;
                  handleBlurBankGet(row);
                }}
                name="circuitEnteringWaterTemperature"
                decimalScale={3}
                maxValue={1000}
                disabled={disableStudy || index > 0}
                thousandSeparator={true}
              />
            </>
          </SpreadSheetCell>
        );
      },
    },
    {
      name: "circuitLeavingWaterTemperature",
      type: "numericInput",
      label: "Leaving T °F",
      onChange: (row: ICoilsItemsCircuits, e: any, index: number): void => {
        row.circuitLeavingWaterTemperature = e.target.value;
        handleBlurBankGet(row);
      },
      decimalScale: 3,
      thousandSeparator: true,
      disabled: disableStudy,
    },
    {
      name: "circuitWaterDeltaTemperature",
      type: "numericInput",
      label: "Δ T °F",
      mode: "read",
      decimalScale: 3,
      thousandSeparator: true,
      disabled: disableStudy,
    },
    {
      name: "circuitBTUH",
      type: "numericInput",
      label: "BTU/h",
      mode: "read",
      decimalScale: 3,
      thousandSeparator: true,
      disabled: disableStudy,
    },
    {
      name: "keyCircuit",
      type: "radioButton",
      label: "Key Circuit",
      align: "center",
      onChange: (row: ICoilsItemsCircuits, e: any, index: number): void => {
        const modifiedItems = [...dataBank];
        modifiedItems.forEach((item) => {
          if (item.id !== row.id) item.keyCircuit = false;
          else item.keyCircuit = true;
        });
        setDataBank([...modifiedItems]);
      },
      disabled: disableStudy,
    },
  ];

  return (
    <>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Spreadsheet
            items={dataBank ?? []}
            setItems={setDataBank}
            cols={columns}
            defaultRowPerPage={25}
            showRowTotals={false}
            showSkeleton={isLoading}
            transposed={true}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default CoilsBank;
