import { Box } from "@mui/material";

const AmericanExpressIcon = () => {
  return (
    <Box
      component="img"
      alt="Logo AMP"
      src={"/static/img/creditCard/amex1.svg"}
      sx={{
        width: "50px",
      }}
    />
  );
};

export default AmericanExpressIcon;
