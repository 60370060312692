import { ICoupon, IKeyValue, ISimpleCatalogType } from "../ts/interfaces";
import axios from "../utils/axios";

const getValidCoupons = () => {
  return axios.get<ICoupon[]>("coupon/validCoupons");
};

const getInvalidCoupons = () => {
  return axios.get<ICoupon[]>("coupon/invalidCoupons");
};

const getCouponTypesKeyValues = () => {
  return new Promise<IKeyValue<number, string>[]>((resolve, reject) => {
    axios
      .get<Array<ISimpleCatalogType>>("/coupon/types")
      .then((response) => {
        const keyValuePair: IKeyValue<number, string>[] = response.data.map(
          (item) => {
            return {
              key: item.id ?? 0,
              value: item.name,
            };
          }
        );

        resolve(keyValuePair);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const add = (coupon: ICoupon) => {
  return axios.post("coupon", coupon);
};

const update = (id: number, coupon: ICoupon) => {
  return axios.put(`coupon/${id}`, coupon);
};

const remove = (id: number) => {
  return axios.delete(`coupon/${id}`);
};

const getByCode = (code: string) => {
  return axios.get<ICoupon>(`coupon/code?code=${code}`);
};

const getRandom = () => {
  return axios.get<ICoupon>(`Coupon/GetRandomCode`);
};

const statusCoupon = async (idCoupon: number, status: string) => {
  return axios.put(`coupon/${idCoupon}/${status}`);
};

const CouponService = {
  getValidCoupons,
  getInvalidCoupons,
  getCouponTypesKeyValues,
  add,
  update,
  remove,
  getByCode,
  statusCoupon,
  getRandom,
};

export default CouponService;
