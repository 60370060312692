import React, { useLayoutEffect } from "react";
import Moment from "moment";
import {
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  Checkbox,
  Skeleton,
  Stack,
} from "@mui/material";
import { ColumnType } from "src/types/enhancedTable";
import EnhancedTableHead from "./EnhancedTableHead";
import { currencyformatter } from "src/utils/format";
import { convertUTCDateToLocalDate } from "src/utils/utils";

interface EnhancedTablePropsType<T> {
  refreshGrid?: boolean;
  columns: ColumnType[];
  data: T[];
  rowsPerPage: number;
  setRowsPerPage: (rowsPerPage: number) => void;
  page: number;
  setPage: (rowsPerPage: number) => void;
  orderBy: string;
  setOrderBy: (orderBy: string) => void;
  order: "desc" | "asc";
  setOrder: (orderBy: "desc" | "asc") => void;
  count: number;
  setCount: (count: number) => void;
  showSkeleton?: boolean;
  tableCellCheckBox: boolean;
  rowsPerPageOptions?: number[];
  hideOverFlowX?: boolean;
}

const EnhancedTable = <T extends { id: number | null }>({
  columns,
  refreshGrid,
  data,
  rowsPerPage,
  setRowsPerPage,
  page,
  setPage,
  orderBy,
  setOrderBy,
  order,
  setOrder,
  count,
  setCount,
  showSkeleton,
  tableCellCheckBox,
  rowsPerPageOptions,
  hideOverFlowX,
}: EnhancedTablePropsType<T>) => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [rowsPerPage]);

  const handleRequestSort = (event: any, property: string) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const cellcontent: any = (row: any, col: any) => {
    if (col.type === "string") {
      return row[col.id];
    } else if (col.type === "custom") {
      return col.callback(row);
    } else if (col.type === "date") {
      return Moment(row[col.id]).format(col.format);
    } else if (col.type === "utcDate") {
      return convertUTCDateToLocalDate(row[col.id])?.format(col.format);
    } else if (col.type === "currency") {
      return currencyformatter.format(row[col.id]);
    } else if (col.type === "phone") {
      let cleaned = ("" + row[col.id]).replace(/\D/g, "");
      let match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
      if (match) {
        let intlCode = match[1] ? "+1 " : "";
        return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join(
          ""
        );
      }
      return null;
    } else {
      return row[col.id];
    }
  };

  const skeletonArray = Array(10).fill("");

  return (
    <>
      <TableContainer sx={{ overflowX: hideOverFlowX ? "hidden" : "" }}>
        <Table>
          <EnhancedTableHead
            // numSelected={0}
            order={order}
            orderBy={orderBy}
            // onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            // rowCount={count}
            columns={columns}
            tableCellCheckBox={tableCellCheckBox}
            toggleSelectionAll={function (): void {
              throw new Error("Function not implemented.");
            }}
            isAllSelected={false}
          />
          <TableBody>
            {!showSkeleton && data.length !== 0 ? (
              data.map((row) => {
                return (
                  <TableRow key={row.id}>
                    {tableCellCheckBox && (
                      <TableCell padding="checkbox">
                        <Checkbox />
                      </TableCell>
                    )}
                    {columns
                      .filter((col) => !col.hideColumn)
                      .map((col, index) =>
                        col.hideColumn ? null : (
                          <TableCell
                            key={col.id + "_" + row.id + "_" + index}
                            align={col.contentAlign}
                          >
                            {cellcontent(row, col)}
                          </TableCell>
                        )
                      )}
                  </TableRow>
                );
              })
            ) : (
              <>
                {!showSkeleton && (
                  <TableRow>
                    <TableCell
                      colSpan={columns.filter((x) => x.hide !== true).length}
                    >
                      <Stack sx={{ width: "100%" }} spacing={2}>
                        No results were found
                      </Stack>
                    </TableCell>
                  </TableRow>
                )}
              </>
            )}

            {showSkeleton &&
              skeletonArray.map((row, indexRow) => {
                return (
                  <TableRow key={indexRow}>
                    <TableCell padding="checkbox">
                      <Skeleton />
                    </TableCell>

                    {columns.map((col, index) => {
                      return (
                        <TableCell key={col.id + "_" + indexRow + "_" + index}>
                          <Skeleton />
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
          {/* <TableFooter>
  
            </TableFooter> */}
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={rowsPerPageOptions ?? [5, 10, 25]}
        component="div"
        count={count}
        rowsPerPage={rowsPerPage}
        // page={count <= 0 ? 0 : page}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
};

export default EnhancedTable;
