import getProjectService from "src/services/project/getProjectService";
import formulasImperialService from "src/services/formulas/formulasImperialService";
import formulasMetricService from "src/services/formulas/formulasMetricService";

function useFormulas() {
  const setSystem = async (projectId: number) => {
    const res = await getProjectService.isImperialSystem(projectId);
    if (res.data) {
      return formulasImperialService;
    } else {
      return formulasMetricService;
    }
  };

  const formulaSystem = {
    setSystem,
  };

  return { formulaSystem };
}

export default useFormulas;
