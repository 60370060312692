interface SvgImageProps {
  color: string;
}

const SvgCoil = (props: SvgImageProps) => {
  const { color } = props;
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="600.000000pt"
      height="600.000000pt"
      viewBox="0 0 600.000000 600.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,600.000000) scale(0.100000,-0.100000)"
        fill={color}
        stroke="none"
      >
        <path
          d="M1245 5560 c-239 -29 -412 -105 -573 -255 -104 -96 -200 -249 -236
-376 -49 -173 -48 -124 -47 -1954 0 -1135 4 -1728 11 -1764 47 -246 196 -486
380 -611 73 -49 110 -68 194 -97 160 -54 109 -52 2036 -57 l1785 -5 125 35
c184 51 307 115 413 215 98 91 158 177 210 299 71 169 67 60 67 1969 0 1079
-4 1749 -10 1806 -37 334 -246 612 -551 734 -160 64 -62 61 -1827 61 -884 0
-1674 2 -1757 4 -82 3 -181 1 -220 -4z m3254 -449 c71 -51 85 -122 38 -184
-15 -20 -38 -42 -50 -49 -17 -10 -270 -13 -1202 -13 -1265 0 -1213 2 -1380
-52 -204 -65 -428 -231 -576 -427 -105 -137 -195 -357 -229 -556 -8 -45 -10
-293 -7 -810 3 -732 4 -746 25 -822 66 -232 204 -465 360 -608 170 -157 320
-237 572 -307 60 -17 128 -18 915 -18 l850 0 100 28 c216 62 392 162 528 301
144 146 236 291 308 482 22 60 46 123 52 139 9 22 13 363 17 1222 4 959 8
1195 18 1205 21 21 196 24 227 4 l25 -16 0 -1213 0 -1212 -25 -96 c-78 -306
-211 -528 -445 -743 -146 -134 -305 -220 -580 -315 l-135 -46 -900 0 c-1008 0
-1011 0 -1205 72 -225 83 -378 181 -550 353 -191 191 -321 421 -397 702 l-28
103 -3 799 c-3 889 -6 844 68 1124 64 244 242 493 488 683 124 96 195 139 327
197 200 86 191 85 710 92 253 3 820 4 1260 2 745 -3 802 -4 824 -21z m521 9
c49 -35 73 -85 67 -137 -6 -58 -37 -90 -97 -101 -64 -12 -119 5 -146 45 -28
42 -38 107 -22 141 32 66 139 94 198 52z m-3946 -14 c18 -8 46 -30 61 -50 23
-30 27 -43 22 -78 -7 -53 -35 -89 -80 -107 -100 -38 -213 59 -174 151 18 41
55 76 93 88 40 12 41 12 78 -4z m2371 -476 c224 -8 248 -10 360 -40 66 -18
150 -47 186 -66 137 -69 311 -233 416 -393 105 -159 155 -311 173 -527 12
-150 13 -960 0 -1074 -46 -426 -371 -813 -800 -953 -146 -47 -173 -49 -850
-45 l-635 4 -100 27 c-175 48 -403 177 -528 300 -173 171 -278 373 -327 632
-16 85 -25 865 -12 1040 34 456 217 770 557 958 44 24 112 56 150 70 151 57
175 60 555 67 545 9 598 9 855 0z m-2340 -3462 c34 -26 56 -77 48 -109 -15
-60 -74 -109 -130 -109 -60 0 -123 68 -123 132 0 29 32 78 62 94 38 21 111 17
143 -8z m3914 8 c57 -30 77 -96 46 -156 -20 -39 -63 -60 -120 -60 -124 0 -171
168 -60 216 40 18 99 17 134 0z"
        />
        <path
          d="M2339 4360 c-76 -12 -188 -52 -263 -94 -75 -42 -193 -143 -258 -222
-60 -72 -148 -248 -185 -369 l-28 -90 0 -510 c1 -455 3 -517 18 -573 37 -133
140 -322 229 -418 47 -51 220 -180 286 -213 24 -12 78 -32 120 -43 72 -19 114
-21 612 -28 604 -8 684 -5 784 34 97 37 220 100 276 141 67 49 197 189 222
239 11 22 35 66 54 96 18 30 49 105 70 165 l37 110 0 500 c1 480 0 504 -21
588 -29 117 -72 214 -137 311 -126 189 -285 303 -498 358 -78 20 -109 21 -672
24 -324 1 -615 -1 -646 -6z m1102 -250 c63 -17 137 -42 166 -55 122 -55 230
-151 314 -278 66 -99 90 -159 125 -305 25 -101 25 -112 22 -400 -5 -339 -7
-353 -88 -517 -109 -222 -270 -358 -555 -469 l-80 -31 -375 1 -375 0 -86 27
c-234 71 -404 188 -517 354 -124 183 -152 306 -152 668 0 287 19 446 66 551
70 156 200 298 339 371 92 48 266 101 375 113 41 5 217 7 391 5 316 -4 317 -4
430 -35z"
        />
        <path
          d="M2572 3844 c-75 -16 -183 -71 -243 -122 -106 -91 -188 -256 -215
-436 -22 -139 -13 -373 17 -472 75 -244 239 -406 464 -459 98 -22 618 -22 696
1 195 58 342 188 439 389 49 104 58 147 65 345 9 251 -22 396 -115 528 -82
117 -174 176 -336 218 -93 23 -109 24 -403 23 -220 -1 -324 -5 -369 -15z m88
-292 c43 -21 80 -75 80 -117 0 -22 -9 -40 -30 -60 -66 -63 -88 -242 -42 -337
30 -60 92 -125 145 -149 80 -36 235 -18 314 36 78 52 117 144 116 271 0 97
-23 153 -84 208 -33 31 -38 40 -33 64 11 44 33 69 75 86 84 34 157 16 226 -55
91 -95 134 -242 114 -392 -20 -153 -75 -268 -181 -381 -103 -109 -227 -152
-408 -143 -207 11 -341 65 -445 178 -115 124 -156 240 -158 438 -1 177 25 250
110 314 78 58 139 70 201 39z"
        />
      </g>
    </svg>
  );
};

export default SvgCoil;
