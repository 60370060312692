import { IStudyStep } from "src/ts/interfaces";

export const steps = [
  {
    label: "Step 1",
    key: 0,
    description: "System Setup",
    url: "/studies/EnergyStudyAirHydronics/step1/:id",
  },
  {
    label: "Step 2",
    key: 1,
    description: "System Details",
    url: "/studies/EnergyStudyAirHydronics/step2/:id",
  },
  {
    label: "Step 3",
    key: 2,
    description: "Pre Test Configuration",
    url: "/studies/EnergyStudyAirHydronics/step3/:id",
  },
  {
    label: "Step 4",
    key: 3,
    description: "Pre Test",
    url: "/studies/EnergyStudyAirHydronics/step4/:id",
  },
  {
    label: "Step 5",
    key: 4,
    description: "Perform Test",
    url: "/studies/EnergyStudyAirHydronics/step5/:id",
  },
  {
    label: "Step 6",
    key: 5,
    description: "Report",
    url: "/studies/EnergyStudyAirHydronics/step6/:id",
  },
  {
    label: "Step 7",
    key: 6,
    description: "Summary",
    url: "/studies/EnergyStudyAirHydronics/step7/:id",
  },
] as IStudyStep[];
