/* eslint-disable react-hooks/exhaustive-deps */
import {
  CardContent,
  Grid,
  Divider as MuiDivider,
  Card as MuiCard,
  Tabs,
  Tab,
  TableContainer,
  TableHead,
  Paper,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Alert,
} from "@mui/material";

import { Stack, spacing, useTheme } from "@mui/system";
import styled from "@emotion/styled";
import { SyntheticEvent, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  useAsyncQuery,
  useLog,
  useAuth,
  useSystemOfMeasurement,
} from "src/hooks";
import {
  FormButton,
  FormCheckBox,
  FormNumeric2,
  FormSelect,
  FormText,
} from "src/components/formControls";
import useFormTyped from "src/hooks/useFormTyped";
import {
  IActualFormulaFanMotorDTO,
  ICoilDimensionDTO,
  IDefaultComboBoxCatalog,
  IEETReportServiceModelDTO,
  IEnergyStudyAirHydronicsDTO,
  IFilterDTO,
  IHeadSheetGeneralDataDTO,
  IHeadSheetStarterDTO,
  IStaticPressureComponentCategoryDTO,
  IStaticPressureComponentDTO,
  IStaticPressureComponentElementDTO,
} from "src/ts/interfaces";
import { IProjectReport } from "src/ts/interfaces/project/projectDto";
import projectReportService from "src/services/study/projectReportService";
import filterService from "src/services/study/filterService";
import staticReportService from "src/services/study/staticReportService";
import coilDimensionService from "src/services/study/coilDimensionService";
import headSheetStarterService from "src/services/study/headSheetStarterService";
import { headSheetType, steps } from "../const/consts";
import { FooterButtons } from "../../components/FooterButtons";
import AirIcon from "@mui/icons-material/Air";
import HvacIcon from "@mui/icons-material/Hvac";
import ElectricalServicesIcon from "@mui/icons-material/ElectricalServices";
import { AddIcon } from "src/components/icons";
import { GridActionButton } from "src/components/gridControls";
import Popup from "src/components/Popup";
import {
  IBreakerMfgDTO,
  ICoilType,
  IStarterMfgDTO,
} from "src/ts/interfaces/catalogs";
import { Validator } from "src/ts/types/Validator";
import starterSizesImg from "./assets/StarterSizes.png";
import FormTooltip from "src/components/formControls/FormTooltip";
import headSheetService from "src/services/study/headSheetService";
import starterMfgService from "src/services/study/starterMfgService";
import breakerMfgService from "src/services/study/breakerMfgService";
import HeaderStudyPage from "src/components/page/HeaderStudyPage";
import { ICoilsItems } from "src/ts/interfaces/study/coils";
import coilsItemService from "src/services/study/coilsItemsService";
import singleEnergyStudyAirHydronicsService from "src/services/study/singleEnergyStudyAirHydronicsService";
import headSheetGeneralDataService from "src/services/study/headSheetGeneralDataService";
import StudyWizard from "../../components/StudyWizard";

const initialValues: any = {
  projectName: "",
  system: "",
  equipmentLocation: "",
  conditionOfTest: "",
};

const Divider = styled(MuiDivider)(spacing);
const Card = styled(MuiCard)(spacing);

const _TAB_FT = 0;
const _TAB_CO = 1;
const _TAB_SB = 2;

const quantitiesOfHeaters = [
  { key: 0, value: "Select a quantity" },
  { key: 1, value: 1 },
  { key: 2, value: 2 },
  { key: 3, value: 3 },
];

const starterSizes = [
  { key: "", value: "Select a size" },
  { key: "00", value: "00" },
  { key: "0", value: "0" },
  { key: "1", value: "1" },
  { key: "2", value: "2" },
  { key: "3", value: "3" },
  { key: "4", value: "4" },
  { key: "5", value: "5" },
  { key: "6", value: "6" },
  { key: "7", value: "7" },
  { key: "8", value: "8" },
  { key: "9", value: "9 " },
];

const HeadSheetStep4 = () => {
  const { log } = useLog();
  const { user } = useAuth();
  const { systemOfMeasurement } = useSystemOfMeasurement(true);
  const params = useParams<{ id: string; step: string }>();
  const id = parseInt(params?.id === undefined ? "0" : params?.id);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [title, setTitle] = useState<string>();
  const [code, setCode] = useState<string>();
  const [route, setRoute] = useState<string>();
  const [tabSelected, setTabSelected] = useState(0);
  const [headSheetValues, setHeadSheetValues] =
    useState<IEETReportServiceModelDTO>();
  const [actualFormula, setActualFormula] =
    useState<IActualFormulaFanMotorDTO[]>();
  const [filterValues, setFilterValues] = useState<IFilterDTO[]>();
  const [coilValues, setCoilValues] = useState<ICoilDimensionDTO[]>();
  const [filterElements, setFilterElements] =
    useState<IDefaultComboBoxCatalog[]>();
  const [coilTypes, setCoilTypes] = useState<IDefaultComboBoxCatalog[]>();
  const [starterMfgs, setStarterMfgs] = useState<IDefaultComboBoxCatalog[]>();
  const [breakerMfgs, setBreakerMfgs] = useState<IDefaultComboBoxCatalog[]>();
  const [currentFilterId, setCurrentFilterId] = useState(0);
  const [currentFilterIdx, setCurrentFilterIdx] = useState(0);
  const [openDeleteFilterDialog, setOpenDeleteFilterDialog] = useState(false);
  const [currentCoilId, setCurrentCoilId] = useState(0);
  const [currentCoilIdx, setCurrentCoilIdx] = useState(0);
  const [openDeleteCoilDialog, setOpenDeleteCoilDialog] = useState(false);
  const [openStarterSizesPopup, setoPenStarterSizesPopup] = useState(false);
  const [parentEnergy, setParentEnergy] =
    useState<IEnergyStudyAirHydronicsDTO>();
  const [generalDataValues, setGeneralDataValues] =
    useState<IHeadSheetGeneralDataDTO>();
  const [isEnergyProcess, setIsEnergyProcess] = useState(false);
  const isDarkTheme = useTheme().palette.mode === "dark";
  const starterSizesBtnTooltip =
    "<p>Click to show NEMA motor Starters Chart</p>";

  let navigate = useNavigate();

  const catalogValueOtherEval = (
    id: any,
    catalog: IDefaultComboBoxCatalog[]
  ) => {
    if (catalog) {
      const [{ value }] = catalog.filter((x) => x.key === id);
      return value === "Other";
    }
    return false;
  };

  const validate: any = (fieldValues = values) => {
    let temp: Record<string, string> = { ...errors };

    temp.starterMfgId = new Validator(fieldValues, "starterMfgId")
      .validateIf(values?.isStarter)
      .isRequired("Motor Manufacturer is required.")
      .greatThan(0, "Motor Manufacturer is required.")
      .toString();
    temp.otherStarterMfg = new Validator(fieldValues, "otherStarterMfg")
      .validateIf(
        values?.isStarter &&
          catalogValueOtherEval(
            values?.starterMfgId as number,
            starterMfgs as IDefaultComboBoxCatalog[]
          )
      )
      .isRequired("Other Motor manufacturer is required.")
      .toString();

    temp.breakerMfgId = new Validator(fieldValues, "breakerMfgId")
      .validateIf(values?.isBreaker)
      .isRequired("Motor Manufacturer is required.")
      .greatThan(0, "Motor Manufacturer is required.")
      .toString();
    temp.otherBreakerMfg = new Validator(fieldValues, "otherBreakerMfg")
      .validateIf(
        values?.isBreaker &&
          catalogValueOtherEval(
            values?.breakerMfgId as number,
            breakerMfgs as IDefaultComboBoxCatalog[]
          )
      )
      .isRequired("Other Motor manufacturer is required.")
      .toString();

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange } =
    useFormTyped<IHeadSheetStarterDTO>(initialValues, false, validate);

  const activeStep = 3;

  const { execute: executeReport, data: dataReport } =
    useAsyncQuery<IProjectReport>(projectReportService.report);

  const { execute: executeFilterElement, isLoading: isLoadingFilterElement } =
    useAsyncQuery<IStaticPressureComponentElementDTO[]>(
      staticReportService.getElements,
      {
        onSuccess: (dataResult) => {
          const getData = async () => {
            const elements = (
              dataResult as IStaticPressureComponentElementDTO[]
            ).map((mfg) => ({
              key: mfg.name as any,
              value: mfg.name as any,
            }));
            setFilterElements([
              { key: "Select a filter type", value: "Select a filter type" },
              ...elements,
            ]);
          };
          getData();
        },
      }
    );

  const { execute: executeCoilType, isLoading: isLoadingCoilType } =
    useAsyncQuery<ICoilType[]>(coilDimensionService.getCoilTypes, {
      onSuccess: (dataResult) => {
        const getData = async () => {
          const types = (dataResult as ICoilType[]).map((type) => ({
            key: type.id as number,
            value: type.name as any,
          }));
          setCoilTypes([{ key: 0, value: "Select a coil type" }, ...types]);
        };
        getData();
      },
    });

  const { execute: executeFilterSubCat, isLoading: isLoadingFilterSubCat } =
    useAsyncQuery<IStaticPressureComponentCategoryDTO[]>(
      staticReportService.getSubcategories,
      {
        onSuccess: (dataResult) => {
          const getData = async () => {
            const [singleSubCaterogy] = dataResult.filter(
              (item: IStaticPressureComponentElementDTO) =>
                item.name === "Single"
            );
            executeFilterElement(singleSubCaterogy.id);
          };
          getData();
        },
      }
    );

  const { execute: executeStarterMfg, isLoading: isLoadingStarterMfg } =
    useAsyncQuery<IStarterMfgDTO[]>(starterMfgService.getStarterMfgs, {
      onSuccess: (dataResult) => {
        const getData = async () => {
          const types = (dataResult as IStarterMfgDTO[]).map((type) => ({
            key: type.id as number,
            value: type.name as any,
          }));
          setStarterMfgs([
            { key: 0, value: "Select a starter Manufacturer" },
            ...types,
          ]);
        };
        getData();
      },
    });

  const { execute: executeBreakerMfg, isLoading: isLoadingBreakerMfg } =
    useAsyncQuery<IBreakerMfgDTO[]>(breakerMfgService.getBreakerMfgs, {
      onSuccess: (dataResult) => {
        const getData = async () => {
          const types = (dataResult as IBreakerMfgDTO[]).map((type) => ({
            key: type.id as number,
            value: type.name as any,
          }));
          setBreakerMfgs([
            { key: 0, value: "Select a breaker Manufacturer" },
            ...types,
          ]);
        };
        getData();
      },
    });

  const { execute: executeComponent, isLoading: isLoadingFilterComponents } =
    useAsyncQuery<IStaticPressureComponentDTO[]>(
      staticReportService.getComponents,
      {
        onSuccess: (dataResult) => {
          const getData = async () => {
            const [filterCategory] = dataResult.filter(
              (item: IStaticPressureComponentDTO) => item.name === "Filters"
            );
            executeFilterSubCat(filterCategory.id);
          };
          getData();
        },
      }
    );

  const { execute, isLoading } = useAsyncQuery<IEETReportServiceModelDTO>(
    headSheetService.getStepThree,
    {
      onSuccess: (dataResult) => {
        const getData = async () => {
          setHeadSheetValues({ ...dataResult });
          await systemOfMeasurement.getByProject(
            dataResult.projectId ?? headSheetValues?.projectId
          );
        };
        getData();
      },
    }
  );

  const { execute: executeFilter, isLoading: isLoadingFilter } = useAsyncQuery<
    IFilterDTO[]
  >(filterService.getByReportId, {
    onSuccess: (dataResult) => {
      const getData = async () => {
        setFilterValues(dataResult);
      };
      getData();
    },
  });

  const { execute: executeCoilItems, isLoading: isLoadingCoilItems } =
    useAsyncQuery<ICoilsItems[]>(coilsItemService.getByCoilId, {
      onSuccess: (dataResult) => {
        const getData = async () => {
          const coils = dataResult as ICoilsItems[];
          if (coils.length > 0) {
            let coilValuesTmp = [] as ICoilDimensionDTO[];
            coils.forEach(async (coil, index, base) => {
              const newCoil = {
                coilTypeId: parentEnergy?.testMode === "Cooling" ? 3 : 2,
                length: coil.length,
                width: coil.width,
                finsPerInch: coil.finsPerInch,
                rowsDepthQuantity: (coil.numberOfRows || 0) * 0.1,
              } as ICoilDimensionDTO;

              const newCoilDimension = (
                await coilDimensionService.save(id, newCoil)
              ).data;

              coilValuesTmp.push(newCoilDimension);

              if (index + 1 === base.length) setCoilValues(coilValuesTmp);
            });
          }
        };
        getData();
      },
    });

  const { execute: executeParentEnergy } =
    useAsyncQuery<IEnergyStudyAirHydronicsDTO>(
      singleEnergyStudyAirHydronicsService.getById,
      {
        onSuccess: (dataResult) => {
          const getData = async () => {
            setParentEnergy(dataResult);
          };
          getData();
        },
      }
    );

  const { execute: executeCoil, isLoading: isLoadingCoil } = useAsyncQuery<
    ICoilDimensionDTO[]
  >(coilDimensionService.getByReportId, {
    onSuccess: (dataResult) => {
      const getData = async () => {
        if (!dataResult.length && dataReport?.parentReportTypeId === 18) {
          executeCoilItems(parentEnergy?.coilsReportId);
        } else setCoilValues(dataResult);
      };
      getData();
    },
  });

  const {
    execute: executeHeadSheetStarter,
    isLoading: isLoadingHeadSheetStarter,
  } = useAsyncQuery<IHeadSheetStarterDTO>(headSheetStarterService.getById, {
    onSuccess: (dataResult) => {
      const getData = async () => {
        setValues(dataResult);
      };
      getData();
    },
  });

  const { execute: executeActualFormula, isLoading: isLoadingActualFormula } =
    useAsyncQuery<IActualFormulaFanMotorDTO[]>(
      headSheetService.getActualFormulaById,
      {
        onSuccess: (dataResult) => {
          const getData = async () => {
            setActualFormula(dataResult);
            if (headSheetValues?.fanSystemType === "FanSystem") {
              executeStarterMfg();
              executeBreakerMfg();
              executeHeadSheetStarter(dataResult[0].headSheetStarterId);
            }
          };
          getData();
        },
      }
    );

  const { execute: executeGeneralData } =
    useAsyncQuery<IHeadSheetGeneralDataDTO>(
      headSheetGeneralDataService.getById,
      {
        onSuccess: (dataResult) => {
          const getData = async () => {
            setGeneralDataValues(dataResult);
          };
          getData();
        },
      }
    );

  const disableStudy = () => {
    return (
      generalDataValues?.headSheet?.isComplete ||
      (dataReport?.isInUse && dataReport?.isInUseById !== user?.userId) ||
      dataReport?.isPartOfProject === false
    );
  };

  useEffect(() => {
    if (headSheetValues?.reportTypeId) {
      const [{ title, code, route }] = headSheetType.filter(
        (x) => x.key === headSheetValues?.reportTypeId
      );
      setTitle(title);
      setCode(code);
      setRoute(route);
    }
  }, [headSheetValues?.reportTypeId]);

  useEffect(() => {
    if (id !== 0) {
      execute(id);
      executeGeneralData(id);
    }
  }, [id]);

  useEffect(() => {
    if (id !== 0 && code !== undefined) executeReport(id, code);
  }, [id, code]);

  useEffect(() => {
    if (headSheetValues?.id !== 0) executeActualFormula(id);
  }, [headSheetValues?.id]);

  useEffect(() => {
    if (dataReport?.id) {
      setIsEnergyProcess(dataReport?.parentReportTypeId === 18);
      executeComponent();
      executeCoilType();
      executeFilter(id);
      if (dataReport?.parentReportTypeId === 18)
        executeParentEnergy(dataReport?.parentReportId);
      else executeCoil(id);
      window.scrollTo(0, 0);
    }
  }, [dataReport?.id]);

  useEffect(() => {
    if (parentEnergy?.id && !coilValues) executeCoil(id);
  }, [parentEnergy?.id]);

  const saveFilterHandle = async () => {
    try {
      if (!disableStudy()) {
        setIsSubmitting(true);
        filterValues?.forEach(async (filter) => {
          await filterService.update(filter.id, filter);
        });
        log.success("Filter data was updated successfully");
        setIsSubmitting(false);
      }
    } catch (error) {}
  };

  const saveCoilHandle = async () => {
    try {
      if (!disableStudy()) {
        setIsSubmitting(true);
        coilValues?.forEach(async (coil) => {
          await coilDimensionService.update(coil.id, coil);
        });
        log.success("Coil data was updated successfully");
      }
    } catch (error) {}
  };

  const saveStarterBreakerHandle = async () => {
    try {
      if (!disableStudy()) {
        if (!validate()) return;
        await headSheetService.updateStarter(
          id,
          values,
          headSheetValues?.fanSystemType === "FanArray",
          0
        );
        log.success("Starter/Breaker was updated successfully");
      }
    } catch (error) {}
  };

  const saveHandler = async () => {
    try {
      setIsUpdating(true);
      await saveFilterHandle();
      await saveCoilHandle();
      if (values?.isStarter || values?.isBreaker)
        await saveStarterBreakerHandle();
      setIsUpdating(false);
    } catch (error) {}
  };

  const nextStepHandler = async () => {
    try {
      await saveFilterHandle();
      await saveCoilHandle();
      if (values?.isStarter || values?.isBreaker)
        await saveStarterBreakerHandle();
      navigate(`/studies/${route}/step5/${id}`);
    } catch (error) {}
  };

  const previousStepHandler = async () => {
    try {
      await saveFilterHandle();
      await saveCoilHandle();
      if (values?.isStarter || values?.isBreaker)
        await saveStarterBreakerHandle();
      navigate(`/studies/${route}/step3/${id}`);
    } catch (error) {}
  };

  const handleTabChange = (event: SyntheticEvent, newValue: number) => {
    setTabSelected(newValue);
  };

  //Filters
  const { execute: addFilter, isLoading: isAddingFilter } = useAsyncQuery<
    IFilterDTO[]
  >(filterService.save, {
    onSuccess: (dataResult) => {
      const getData = async () => {
        setFilterValues([...(filterValues as IFilterDTO[]), dataResult]);
      };
      getData();
    },
  });

  const handleAddFilter = async () => {
    const filter = {
      id: 0,
      description: "",
      typeOther: "",
      length: null,
      width: null,
      thickness: null,
      quantity: null,
      headSheetId: id,
    };
    addFilter(id, filter);
  };

  const handleFilterValuesChange = (e: any, row: IFilterDTO, index: number) => {
    let newFilter = { ...row, [e.target.name]: e.target.value };
    let crud = [...(filterValues as IFilterDTO[])];
    crud.splice(index, 1, newFilter);
    setFilterValues(crud);
  };

  const removeFilterHandler = async (id: number, index: number) => {
    await filterService.remove(id);
    let newFilter = filterValues;
    newFilter?.splice(index, 1);
    setFilterValues(newFilter);
    setOpenDeleteFilterDialog(false);
  };

  const handleCloseDeleteFilterDialog = () => {
    setOpenDeleteFilterDialog(false);
  };

  //Coils
  const { execute: addCoil, isLoading: isAddingCoil } = useAsyncQuery<
    ICoilDimensionDTO[]
  >(coilDimensionService.save, {
    onSuccess: (dataResult) => {
      const getData = async () => {
        setCoilValues([...(coilValues as ICoilDimensionDTO[]), dataResult]);
      };
      getData();
    },
  });

  const handleAddCoil = async () => {
    const coil = {
      id: 0,
      coilTypeId: null,
      otherCoilType: "",
      length: null,
      width: null,
      rowsDepthQuantity: null,
      finsPerInch: null,
      headSheetId: id,
    };
    addCoil(id, coil);
  };

  const handleCoilValuesChange = (
    e: any,
    row: ICoilDimensionDTO,
    index: number
  ) => {
    let newCoil = { ...row, [e.target.name]: e.target.value };
    let crud = [...(coilValues as ICoilDimensionDTO[])];
    crud.splice(index, 1, newCoil);
    setCoilValues(crud);
  };

  const removeCoilHandler = async (id: number, index: number) => {
    await coilDimensionService.remove(id);
    let newCoil = coilValues;
    newCoil?.splice(index, 1);
    setCoilValues(newCoil);
    setOpenDeleteCoilDialog(false);
  };

  const handleCloseDeleteCoilDialog = () => {
    setOpenDeleteCoilDialog(false);
  };

  const systemOperatesFromVfd = () =>
    (actualFormula as IActualFormulaFanMotorDTO[])?.filter(
      (x) => x.systemMotorOperatesFromAVfDPwmDevice
    ).length > 0 || headSheetValues?.vfdpwmDevice;

  const handleBreakerIsTooLargeChange = (e: any) => {
    setValues({
      ...values,
      [e.target.name]: e.target.value,
      breakerIsTooSmall: e.target.value ? false : values.breakerIsTooSmall,
    });
  };

  const handleBreakerIsTooSmallChange = (e: any) => {
    setValues({
      ...values,
      [e.target.name]: e.target.value,
      breakerIsTooLarge: e.target.value ? false : values.breakerIsTooLarge,
    });
  };

  const handleOpenStarterSizesPopup = () => {
    setoPenStarterSizesPopup(true);
  };

  const handleCloseStarterSizesPopup = () => {
    setoPenStarterSizesPopup(false);
  };

  return (
    <>
      <Popup
        openPopup={openDeleteFilterDialog}
        setOpenPopup={setOpenDeleteFilterDialog}
        onClose={handleCloseDeleteFilterDialog}
        title="Confirm delete Component"
      >
        <p>Are you certain you want to delete the selected filter item?</p>
        <Stack direction="row" spacing={3}>
          <Button
            variant="outlined"
            color="secondary"
            onClick={handleCloseDeleteFilterDialog}
          >
            No
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() =>
              removeFilterHandler(currentFilterId, currentFilterIdx)
            }
            autoFocus
          >
            Yes
          </Button>
        </Stack>
      </Popup>
      <Popup
        openPopup={openDeleteCoilDialog}
        setOpenPopup={setOpenDeleteCoilDialog}
        onClose={handleCloseDeleteCoilDialog}
        title="Confirm delete Component"
      >
        <p>Are you certain you want to delete the selected coil item?</p>
        <Stack direction="row" spacing={3}>
          <Button
            variant="outlined"
            color="secondary"
            onClick={handleCloseDeleteCoilDialog}
          >
            No
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => removeCoilHandler(currentCoilId, currentCoilIdx)}
            autoFocus
          >
            Yes
          </Button>
        </Stack>
      </Popup>
      <Popup
        openPopup={openStarterSizesPopup}
        setOpenPopup={setoPenStarterSizesPopup}
        title="NEMA Motor Starters Chart"
        onClose={handleCloseStarterSizesPopup}
        size="lg"
      >
        <>
          <div style={{ textAlign: "center" }}>
            <img
              src={starterSizesImg}
              style={{ width: 900 }}
              alt="NEMA Motor Starters Chart"
            />
          </div>
        </>
        <Stack direction="row" spacing={3}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleCloseStarterSizesPopup}
          >
            Close
          </Button>
        </Stack>
      </Popup>
      <HeaderStudyPage
        headerStudiesPage={{
          code: code,
          system: headSheetValues?.system,
          id: id,
        }}
        parentText="Project"
        parentLink={`/app/ProjectStudies/${headSheetValues?.projectId}/${user?.companyId}`}
      />
      <Divider my={6} />

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card mb={6}>
            <CardContent>
              <StudyWizard
                steps={steps}
                activeStep={activeStep}
                projectId={dataReport?.projectId}
                reportId={dataReport?.reportId}
                reportTypeId={dataReport?.reportTypeId}
              />
              <Grid container spacing={5}>
                <Grid item xs={12}>
                  <Tabs
                    value={tabSelected}
                    onChange={handleTabChange}
                    aria-label="headsheet step 2 tabs"
                  >
                    <Tab
                      icon={<AirIcon />}
                      iconPosition="start"
                      label="Filter"
                    />
                    <Tab
                      icon={<HvacIcon />}
                      iconPosition="start"
                      label="Coil"
                    />
                    {!systemOperatesFromVfd() && (
                      <Tab
                        icon={<ElectricalServicesIcon />}
                        iconPosition="start"
                        label="Starter/Breaker"
                      />
                    )}
                  </Tabs>
                </Grid>
              </Grid>
              <br />
              {tabSelected === _TAB_FT && (
                <>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <FormButton
                        variant="contained"
                        color="success"
                        text="Add Filter"
                        endIcon={<AddIcon />}
                        onClick={handleAddFilter}
                        size="small"
                        disabled={disableStudy()}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TableContainer sx={{ maxHeight: 480 }} component={Paper}>
                        <Table stickyHeader aria-label="static components">
                          <TableHead>
                            <TableRow>
                              <TableCell style={{ textAlign: "center" }}>
                                #
                              </TableCell>
                              <TableCell
                                style={{ textAlign: "center", minWidth: 600 }}
                              >
                                Filter Type
                              </TableCell>
                              <TableCell style={{ textAlign: "center" }}>
                                Quantity
                              </TableCell>
                              <TableCell style={{ textAlign: "center" }}>
                                {`Length (${systemOfMeasurement.get("in")})`}
                              </TableCell>
                              <TableCell style={{ textAlign: "center" }}>
                                {`Width (${systemOfMeasurement.get("in")})`}
                              </TableCell>
                              <TableCell style={{ textAlign: "center" }}>
                                {`Thickness (${systemOfMeasurement.get("in")})`}
                              </TableCell>
                              <TableCell style={{ textAlign: "center" }}>
                                Actions
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {filterValues &&
                              filterValues.map(
                                (row: IFilterDTO, index: number) => (
                                  <>
                                    <TableRow
                                      key={`filter-${row.id}-ge-${index}`}
                                      style={{
                                        backgroundColor:
                                          index % 2 > 0
                                            ? isDarkTheme
                                              ? "#243549"
                                              : "#f3f3f3"
                                            : "inherit",
                                      }}
                                    >
                                      <TableCell
                                        style={{
                                          textAlign: "center",
                                        }}
                                      >
                                        {index + 1}
                                      </TableCell>
                                      <TableCell
                                        style={{
                                          textAlign: "center",
                                        }}
                                      >
                                        <FormSelect
                                          label=""
                                          fullWidth={catalogValueOtherEval(
                                            row.description ||
                                              "Select a filter type",
                                            filterElements as IDefaultComboBoxCatalog[]
                                          )}
                                          width={
                                            catalogValueOtherEval(
                                              row.description ||
                                                "Select a filter type",
                                              filterElements as IDefaultComboBoxCatalog[]
                                            )
                                              ? "50%"
                                              : "100%"
                                          }
                                          name="description"
                                          value={
                                            row.description ||
                                            "Select a filter type"
                                          }
                                          options={filterElements}
                                          onChange={(e: any) =>
                                            handleFilterValuesChange(
                                              e,
                                              row,
                                              index
                                            )
                                          }
                                          showSkeleton={
                                            isLoading ||
                                            isLoadingFilterElement ||
                                            isLoadingFilterSubCat ||
                                            isLoadingFilter ||
                                            isLoadingFilterComponents ||
                                            isAddingFilter
                                          }
                                          disabled={disableStudy()}
                                        />
                                        {catalogValueOtherEval(
                                          row.description ||
                                            "Select a filter type",
                                          filterElements as IDefaultComboBoxCatalog[]
                                        ) && (
                                          <FormText
                                            label="Type Other"
                                            name="typeOther"
                                            placeholder="Specify the filter type here..."
                                            fullWidth={false}
                                            style={{ width: "50%" }}
                                            value={row.typeOther || ""}
                                            onChange={(e: any) =>
                                              handleFilterValuesChange(
                                                e,
                                                row,
                                                index
                                              )
                                            }
                                            showSkeleton={
                                              isLoading ||
                                              isLoadingFilterElement ||
                                              isLoadingFilterSubCat ||
                                              isLoadingFilter ||
                                              isLoadingFilterComponents ||
                                              isAddingFilter
                                            }
                                            disabled={disableStudy()}
                                          />
                                        )}
                                      </TableCell>
                                      <TableCell
                                        style={{
                                          textAlign: "center",
                                        }}
                                      >
                                        <FormNumeric2
                                          label=""
                                          placeholder="0.00"
                                          name="quantity"
                                          value={row.quantity}
                                          decimalScale={0}
                                          fixedDecimalScale={false}
                                          onChange={(e: any) =>
                                            handleFilterValuesChange(
                                              e,
                                              row,
                                              index
                                            )
                                          }
                                          showSkeleton={
                                            isLoading ||
                                            isLoadingFilter ||
                                            isAddingFilter
                                          }
                                          disabled={disableStudy()}
                                        />
                                      </TableCell>
                                      <TableCell
                                        style={{
                                          textAlign: "center",
                                        }}
                                      >
                                        <FormNumeric2
                                          label=""
                                          placeholder="0.00"
                                          name="length"
                                          value={row.length}
                                          decimalScale={3}
                                          fixedDecimalScale
                                          maxValue={1000}
                                          onChange={(e: any) =>
                                            handleFilterValuesChange(
                                              e,
                                              row,
                                              index
                                            )
                                          }
                                          showSkeleton={
                                            isLoading ||
                                            isLoadingFilter ||
                                            isAddingFilter
                                          }
                                          disabled={disableStudy()}
                                        />
                                      </TableCell>
                                      <TableCell
                                        style={{
                                          textAlign: "center",
                                        }}
                                      >
                                        <FormNumeric2
                                          label=""
                                          placeholder="0.00"
                                          name="width"
                                          value={row.width}
                                          decimalScale={3}
                                          fixedDecimalScale
                                          maxValue={1000}
                                          onChange={(e: any) =>
                                            handleFilterValuesChange(
                                              e,
                                              row,
                                              index
                                            )
                                          }
                                          showSkeleton={
                                            isLoading ||
                                            isLoadingFilter ||
                                            isAddingFilter
                                          }
                                          disabled={disableStudy()}
                                        />
                                      </TableCell>
                                      <TableCell
                                        style={{
                                          textAlign: "center",
                                        }}
                                      >
                                        <FormNumeric2
                                          label=""
                                          placeholder="0.00"
                                          name="thickness"
                                          value={row.thickness}
                                          decimalScale={3}
                                          fixedDecimalScale
                                          maxValue={1000}
                                          onChange={(e: any) =>
                                            handleFilterValuesChange(
                                              e,
                                              row,
                                              index
                                            )
                                          }
                                          showSkeleton={
                                            isLoading ||
                                            isLoadingFilter ||
                                            isAddingFilter
                                          }
                                          disabled={disableStudy()}
                                        />
                                      </TableCell>
                                      <TableCell
                                        style={{
                                          textAlign: "center",
                                        }}
                                      >
                                        <GridActionButton
                                          type="delete"
                                          disabled={disableStudy()}
                                          onClick={() => {
                                            setCurrentFilterId(row.id);
                                            setCurrentFilterIdx(index);
                                            setOpenDeleteFilterDialog(true);
                                          }}
                                          tooltip="Delete component"
                                        />
                                      </TableCell>
                                    </TableRow>
                                  </>
                                )
                              )}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Grid>
                  </Grid>
                </>
              )}
              {tabSelected === _TAB_CO && (
                <>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <FormButton
                        variant="contained"
                        color="success"
                        text="Add Coil"
                        endIcon={<AddIcon />}
                        onClick={handleAddCoil}
                        size="small"
                        disabled={disableStudy() || isEnergyProcess}
                      />
                      {isEnergyProcess && (
                        <Alert severity="info">
                          This task is disabled during an Energy Process
                        </Alert>
                      )}
                    </Grid>
                    <Grid item xs={12}>
                      <TableContainer sx={{ maxHeight: 480 }} component={Paper}>
                        <Table stickyHeader aria-label="static components">
                          <TableHead>
                            <TableRow>
                              <TableCell style={{ textAlign: "center" }}>
                                #
                              </TableCell>
                              <TableCell
                                style={{ textAlign: "center", minWidth: 600 }}
                              >
                                Coil Type
                              </TableCell>
                              <TableCell style={{ textAlign: "center" }}>
                                {`Length (${systemOfMeasurement.get("in")})`}
                              </TableCell>
                              <TableCell style={{ textAlign: "center" }}>
                                {`Width (${systemOfMeasurement.get("in")})`}
                              </TableCell>
                              <TableCell style={{ textAlign: "center" }}>
                                Depth Quantity
                              </TableCell>
                              <TableCell style={{ textAlign: "center" }}>
                                {`Fins per (${systemOfMeasurement.get("in")})`}
                              </TableCell>
                              <TableCell style={{ textAlign: "center" }}>
                                Actions
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {coilValues?.length &&
                              !isLoadingCoil &&
                              !isLoadingCoilItems &&
                              coilValues.map(
                                (row: ICoilDimensionDTO, index: number) => (
                                  <>
                                    <TableRow
                                      key={`coil-${row.id}-ge-${index}`}
                                      style={{
                                        backgroundColor:
                                          index % 2 > 0
                                            ? isDarkTheme
                                              ? "#243549"
                                              : "#f3f3f3"
                                            : "inherit",
                                      }}
                                    >
                                      <TableCell
                                        style={{
                                          textAlign: "center",
                                        }}
                                      >
                                        {index + 1}
                                      </TableCell>
                                      <TableCell
                                        style={{
                                          textAlign: "center",
                                        }}
                                      >
                                        <FormSelect
                                          label=""
                                          fullWidth={catalogValueOtherEval(
                                            row.coilTypeId || 0,
                                            coilTypes as IDefaultComboBoxCatalog[]
                                          )}
                                          width={
                                            catalogValueOtherEval(
                                              row.coilTypeId || 0,
                                              coilTypes as IDefaultComboBoxCatalog[]
                                            )
                                              ? "50%"
                                              : "100%"
                                          }
                                          name="coilTypeId"
                                          value={row.coilTypeId || 0}
                                          options={coilTypes}
                                          onChange={(e: any) =>
                                            handleCoilValuesChange(
                                              e,
                                              row,
                                              index
                                            )
                                          }
                                          showSkeleton={
                                            isLoading ||
                                            isLoadingCoilType ||
                                            isLoadingCoil ||
                                            isAddingCoil
                                          }
                                          disabled={
                                            disableStudy() || isEnergyProcess
                                          }
                                        />
                                        {catalogValueOtherEval(
                                          row.coilTypeId || 0,
                                          coilTypes as IDefaultComboBoxCatalog[]
                                        ) && (
                                          <FormText
                                            label="Coil Type Other"
                                            name="otherCoilType"
                                            placeholder="Specify the coil type here..."
                                            fullWidth={false}
                                            style={{ width: "50%" }}
                                            value={row.otherCoilType || ""}
                                            onChange={(e: any) =>
                                              handleCoilValuesChange(
                                                e,
                                                row,
                                                index
                                              )
                                            }
                                            showSkeleton={
                                              isLoading ||
                                              isLoadingCoil ||
                                              isAddingCoil
                                            }
                                            disabled={
                                              disableStudy() || isEnergyProcess
                                            }
                                          />
                                        )}
                                      </TableCell>
                                      <TableCell
                                        style={{
                                          textAlign: "center",
                                        }}
                                      >
                                        <FormNumeric2
                                          label=""
                                          placeholder="0.00"
                                          name="length"
                                          value={row.length}
                                          decimalScale={3}
                                          fixedDecimalScale
                                          maxValue={1000}
                                          onChange={(e: any) =>
                                            handleCoilValuesChange(
                                              e,
                                              row,
                                              index
                                            )
                                          }
                                          showSkeleton={
                                            isLoading ||
                                            isLoadingCoil ||
                                            isAddingCoil
                                          }
                                          disabled={
                                            disableStudy() || isEnergyProcess
                                          }
                                        />
                                      </TableCell>
                                      <TableCell
                                        style={{
                                          textAlign: "center",
                                        }}
                                      >
                                        <FormNumeric2
                                          label=""
                                          placeholder="0.00"
                                          name="width"
                                          value={row.width}
                                          decimalScale={3}
                                          fixedDecimalScale
                                          maxValue={1000}
                                          onChange={(e: any) =>
                                            handleCoilValuesChange(
                                              e,
                                              row,
                                              index
                                            )
                                          }
                                          showSkeleton={
                                            isLoading ||
                                            isLoadingCoil ||
                                            isAddingCoil
                                          }
                                          disabled={
                                            disableStudy() || isEnergyProcess
                                          }
                                        />
                                      </TableCell>
                                      <TableCell
                                        style={{
                                          textAlign: "center",
                                        }}
                                      >
                                        <FormNumeric2
                                          label=""
                                          placeholder="0"
                                          name="rowsDepthQuantity"
                                          value={row.rowsDepthQuantity}
                                          decimalScale={3}
                                          fixedDecimalScale={false}
                                          onChange={(e: any) =>
                                            handleCoilValuesChange(
                                              e,
                                              row,
                                              index
                                            )
                                          }
                                          showSkeleton={
                                            isLoading ||
                                            isLoadingCoil ||
                                            isAddingCoil
                                          }
                                          disabled={
                                            disableStudy() || isEnergyProcess
                                          }
                                        />
                                      </TableCell>
                                      <TableCell
                                        style={{
                                          textAlign: "center",
                                        }}
                                      >
                                        <FormNumeric2
                                          label=""
                                          placeholder="0"
                                          name="finsPerInch"
                                          value={row.finsPerInch}
                                          decimalScale={0}
                                          fixedDecimalScale={false}
                                          onChange={(e: any) =>
                                            handleCoilValuesChange(
                                              e,
                                              row,
                                              index
                                            )
                                          }
                                          showSkeleton={
                                            isLoading ||
                                            isLoadingCoil ||
                                            isAddingCoil
                                          }
                                          disabled={
                                            disableStudy() || isEnergyProcess
                                          }
                                        />
                                      </TableCell>
                                      <TableCell
                                        style={{
                                          textAlign: "center",
                                        }}
                                      >
                                        <GridActionButton
                                          type="delete"
                                          disabled={
                                            disableStudy() || isEnergyProcess
                                          }
                                          onClick={() => {
                                            setCurrentCoilId(row.id);
                                            setCurrentCoilIdx(index);
                                            setOpenDeleteCoilDialog(true);
                                          }}
                                          tooltip="Delete coil"
                                        />
                                      </TableCell>
                                    </TableRow>
                                  </>
                                )
                              )}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Grid>
                  </Grid>
                </>
              )}
              {tabSelected === _TAB_SB && !systemOperatesFromVfd() && (
                <>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <h3>Starter</h3>
                      <FormCheckBox
                        size="medium"
                        name="isStarter"
                        label="Starter"
                        onChange={handleInputChange}
                        value={values?.isStarter || false}
                        showSkeleton={
                          isLoadingHeadSheetStarter ||
                          isLoading ||
                          isLoadingActualFormula
                        }
                        disabled={disableStudy()}
                      />
                    </Grid>
                    {values?.isStarter && (
                      <>
                        <Grid item xs={12}>
                          <FormSelect
                            name="starterMfgId"
                            label="Starter manufacturer"
                            error={errors.starterMfgId}
                            onChange={handleInputChange}
                            options={starterMfgs}
                            value={values?.starterMfgId || 0}
                            showSkeleton={
                              isLoadingHeadSheetStarter ||
                              isLoading ||
                              isLoadingStarterMfg ||
                              isLoadingActualFormula
                            }
                            disabled={disableStudy()}
                          />
                        </Grid>
                        {catalogValueOtherEval(
                          values?.starterMfgId || 0,
                          starterMfgs as IDefaultComboBoxCatalog[]
                        ) && (
                          <Grid item xs={12}>
                            <FormText
                              name="otherStarterMfg"
                              error={errors.otherStarterMfg}
                              label="Other starter manufacturer"
                              onChange={handleInputChange}
                              placeholder="Type the starter manufacturer here"
                              value={values?.otherStarterMfg || ""}
                              showSkeleton={
                                isLoadingHeadSheetStarter ||
                                isLoading ||
                                isLoadingStarterMfg ||
                                isLoadingActualFormula
                              }
                              disabled={disableStudy()}
                            />
                          </Grid>
                        )}
                        <Grid item xs={12} md={6}>
                          <FormSelect
                            name="starterSize"
                            label="Starter size"
                            onChange={handleInputChange}
                            options={starterSizes}
                            value={values?.starterSize || ""}
                            showSkeleton={
                              isLoadingHeadSheetStarter ||
                              isLoading ||
                              isLoadingActualFormula
                            }
                            fullWidth={false}
                            width="90%"
                            disabled={disableStudy()}
                          />
                          <FormTooltip
                            text={starterSizesBtnTooltip}
                            onClick={handleOpenStarterSizesPopup}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FormSelect
                            name="quantityOfHeaters"
                            label="Quantity of heaters"
                            onChange={handleInputChange}
                            options={quantitiesOfHeaters}
                            value={values?.quantityOfHeaters || 0}
                            showSkeleton={
                              isLoadingHeadSheetStarter ||
                              isLoading ||
                              isLoadingActualFormula
                            }
                            disabled={disableStudy()}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FormText
                            name="heaterStamping"
                            label="Heater stamping"
                            onChange={handleInputChange}
                            placeholder="Heater stamping"
                            value={values?.heaterStamping || ""}
                            showSkeleton={
                              isLoadingHeadSheetStarter ||
                              isLoading ||
                              isLoadingActualFormula
                            }
                            disabled={disableStudy()}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FormText
                            name="heaterRangeStampingRated"
                            label="Heater range rated @"
                            onChange={handleInputChange}
                            placeholder="Heater range rated @"
                            value={values?.heaterRangeStampingRated || ""}
                            showSkeleton={
                              isLoadingHeadSheetStarter ||
                              isLoading ||
                              isLoadingActualFormula
                            }
                            disabled={disableStudy()}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <FormCheckBox
                            size="medium"
                            name="incorrectHeaters"
                            label="Incorrect Heaters"
                            onChange={handleInputChange}
                            value={values?.incorrectHeaters || false}
                            showSkeleton={
                              isLoadingHeadSheetStarter ||
                              isLoading ||
                              isLoadingActualFormula
                            }
                            disabled={disableStudy()}
                          />
                        </Grid>
                        {values?.incorrectHeaters && (
                          <>
                            <Grid item xs={12} md={6}>
                              <FormText
                                name="heatersRequired"
                                label="Heaters required"
                                onChange={handleInputChange}
                                placeholder="Heaters required"
                                value={values?.heatersRequired || ""}
                                showSkeleton={
                                  isLoadingHeadSheetStarter ||
                                  isLoading ||
                                  isLoadingActualFormula
                                }
                                disabled={disableStudy()}
                              />
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <FormText
                                name="heaterRangeRequiredRate"
                                label="Required Heater range rated @"
                                onChange={handleInputChange}
                                placeholder="Required Heater range rated @"
                                value={values?.heaterRangeRequiredRate || ""}
                                showSkeleton={
                                  isLoadingHeadSheetStarter ||
                                  isLoading ||
                                  isLoadingActualFormula
                                }
                                disabled={disableStudy()}
                              />
                            </Grid>
                          </>
                        )}
                        <Grid item xs={12}>
                          <FormText
                            name="incomingAWGWireSize"
                            label="Incoming AWG Wire Size supplying power to the starter/panel"
                            onChange={handleInputChange}
                            placeholder="Incoming AWG Wire Size supplying power to the starter/panel"
                            value={values?.incomingAWGWireSize || ""}
                            showSkeleton={
                              isLoadingHeadSheetStarter ||
                              isLoading ||
                              isLoadingActualFormula
                            }
                            disabled={disableStudy()}
                          />
                        </Grid>
                      </>
                    )}
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                    <Grid item xs={12}>
                      <h3>Breaker</h3>
                      <FormCheckBox
                        size="medium"
                        name="isBreaker"
                        label="Breaker"
                        onChange={handleInputChange}
                        value={values?.isBreaker || false}
                        showSkeleton={
                          isLoadingHeadSheetStarter ||
                          isLoading ||
                          isLoadingActualFormula
                        }
                        disabled={disableStudy()}
                      />
                    </Grid>
                    {values?.isBreaker && (
                      <>
                        <Grid item xs={12}>
                          <FormSelect
                            name="breakerMfgId"
                            label="Breaker manufacturer"
                            error={errors.breakerMfgId}
                            onChange={handleInputChange}
                            options={breakerMfgs}
                            value={values?.breakerMfgId || 0}
                            showSkeleton={
                              isLoadingHeadSheetStarter ||
                              isLoading ||
                              isLoadingBreakerMfg ||
                              isLoadingActualFormula
                            }
                            disabled={disableStudy()}
                          />
                        </Grid>
                        {catalogValueOtherEval(
                          values?.breakerMfgId || 0,
                          breakerMfgs as IDefaultComboBoxCatalog[]
                        ) && (
                          <Grid item xs={12}>
                            <FormText
                              name="otherBreakerMfg"
                              error={errors.otherBreakerMfg}
                              label="Other breaker manufacturer"
                              onChange={handleInputChange}
                              placeholder="Type the breaker manufacturer here"
                              value={values?.otherBreakerMfg || ""}
                              showSkeleton={
                                isLoadingHeadSheetStarter ||
                                isLoading ||
                                isLoadingBreakerMfg ||
                                isLoadingActualFormula
                              }
                              disabled={disableStudy()}
                            />
                          </Grid>
                        )}
                        <Grid item xs={12} md={6}>
                          <FormText
                            name="breakerModel"
                            label="Breaker model #"
                            onChange={handleInputChange}
                            placeholder="Breaker model #"
                            value={values?.breakerModel || ""}
                            showSkeleton={
                              isLoadingHeadSheetStarter ||
                              isLoading ||
                              isLoadingActualFormula
                            }
                            disabled={disableStudy()}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FormNumeric2
                            label="Breaker rated @"
                            placeholder="0.0"
                            name="breakerRated"
                            value={values?.breakerRated}
                            decimalScale={1}
                            fixedDecimalScale
                            maxValue={1000000}
                            onChange={handleInputChange}
                            showSkeleton={
                              isLoadingHeadSheetStarter ||
                              isLoading ||
                              isLoadingActualFormula
                            }
                            disabled={disableStudy()}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <p>Wrong Breaker</p>
                          <FormCheckBox
                            size="medium"
                            name="breakerIsTooLarge"
                            label="Breaker installed was too large"
                            onChange={handleBreakerIsTooLargeChange}
                            value={values?.breakerIsTooLarge || false}
                            showSkeleton={
                              isLoadingHeadSheetStarter ||
                              isLoading ||
                              isLoadingActualFormula
                            }
                            disabled={disableStudy()}
                          />
                          <FormCheckBox
                            size="medium"
                            name="breakerIsTooSmall"
                            label="Breaker installed was too small"
                            onChange={handleBreakerIsTooSmallChange}
                            value={values?.breakerIsTooSmall || false}
                            showSkeleton={
                              isLoadingHeadSheetStarter ||
                              isLoading ||
                              isLoadingActualFormula
                            }
                            disabled={disableStudy()}
                          />
                        </Grid>
                      </>
                    )}
                  </Grid>
                </>
              )}
              <FooterButtons
                activeStep={activeStep}
                stepsCount={steps.length}
                projectId={headSheetValues?.projectId as number}
                companyId={user?.companyId as number}
                isSaving={isUpdating}
                disabled={
                  isUpdating || isLoading || isSubmitting || disableStudy()
                }
                reportName={title as string}
                saveHandler={saveHandler}
                hideSaveButton={disableStudy()}
                nextStepHandler={nextStepHandler}
                previousStepHandler={previousStepHandler}
                isComplete={headSheetValues?.isComplete ?? true}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default HeadSheetStep4;
