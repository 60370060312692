import { GpsPermissionDTO } from "../ts/interfaces";
import axios from "../utils/axios";

const getPermissionStatus = (projectId: number, action: string) => {
  return axios.get<string>("GpsPermission/GetPermissionStatus");
};

const save = (gpsPermission: GpsPermissionDTO) => {
  return axios.post("GpsPermission?newRoute=true", gpsPermission);
};

const createTrainingPermission = (projectId: number) => {
  return axios.post(
    `GpsPermission/CreateTrainingPermission?projectId=${projectId}`
  );
};

const removeTrainingPermission = (technicianId: number) => {
  return axios.put(`GpsPermission/RemoveGpsPermission?techId=${technicianId}`);
};

const gpsPermissionService = {
  getPermissionStatus,
  save,
  createTrainingPermission,
  removeTrainingPermission,
};

export default gpsPermissionService;
