import {
  ICart,
  ICartPursasheResult,
  IPurchaseResultMessage,
} from "src/ts/interfaces/cart";
import axios from "src/utils/axios";

const getCart = async (companyId: string) => {
  return axios.get<ICart[]>(`/GetCart/ProjectPurchases/${companyId}`);
};

const getSystem = async (cartId: number) => {
  return axios.get<string>(
    `/Cart/SystemByProjectPurchase?projectPurchaseId=${cartId}`
  );
};

const getPreviewPursache = async (companyId: string, payload: any) => {
  return axios.put<ICartPursasheResult>(
    `/Cart/previewPursache?companyId=${companyId}`,
    payload
  );
};

const payCartItems = async (companyId: number, payload: any) => {
  return axios.post<IPurchaseResultMessage>(
    `/Cart/PayCartItems?companyId=${companyId}`,
    payload
  );
};

const cartService = {
  getSystem,
  getCart,
  getPreviewPursache,
  payCartItems,
};

export default cartService;
