import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "@emotion/styled";
import { NavLink, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import useLog from "src/hooks/useLog";
import {
  CardContent,
  Grid,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  Typography,
  Stack,
} from "@mui/material";
import { spacing } from "@mui/system";
import { useForm, Form } from "src/hooks";
import {
  FormCancelButton,
  FormSaveButton,
  FormText,
  FormSelect,
} from "src/components/formControls";
import { ITechnicianQuizAdd, ITechnicianQuizCategory } from "src/ts/interfaces";
import useLoading from "src/hooks/useLoading";
import SelectQuizQuestions from "./component/selectQuizQuestions";
import { technicianQuizService } from "src/services";

const Card = styled(MuiCard)(spacing);
const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const initialValues: ITechnicianQuizAdd = {
  id: 0,
  isActive: 1,
  title: "",
  description: "",
  totalQuestions: 0,
  numberQuestionsToPass: 0,
  technicianQuizCategory: [],
  type: 0,
  filter: "",
};

type QuizParams = {
  id: string;
};

const Quiz = () => {
  let navigate = useNavigate();
  const { log } = useLog();
  let { id } = useParams<QuizParams>();
  const quizId = parseInt(id === undefined ? "0" : id);
  const [numberOfQuestionsValue, setNumberOfQuestionsValue] = useState(0);
  const validate = (fieldValues = values) => {
    let temp: Record<string, string> = { ...errors };

    if (fieldValues.hasOwnProperty("title")) {
      if (fieldValues.title === "") {
        temp.title = "This field is required";
      } else if (fieldValues.title.length > 50) {
        temp.title = "Only 50 character are allowed";
      } else {
        temp.title = "";
      }
    }

    if (fieldValues.hasOwnProperty("type")) {
      if (fieldValues.type === 0) {
        temp.type = "This field is required";
      } else {
        temp.type = "";
      }
    }

    if (fieldValues.hasOwnProperty("description")) {
      if (fieldValues.description === "") {
        temp.description = "This field is required";
      } else if (fieldValues.description.length > 50) {
        temp.description = "Only 50 character are allowed";
      } else {
        temp.description = "";
      }
    }

    if (fieldValues.hasOwnProperty("numberQuestionsToPass")) {
      if (fieldValues.numberQuestionsToPass === "") {
        temp.numberQuestionsToPass = "This field is required";
      } else if (fieldValues.numberQuestionsToPass.length > 50) {
        temp.numberQuestionsToPass = "Only 50 character are allowed";
      } else {
        temp.numberQuestionsToPass = "";
      }
    }

    if (fieldValues.hasOwnProperty("totalQuestions")) {
      if (fieldValues.totalQuestions === "") {
        temp.totalQuestions = "This field is required";
      } else if (fieldValues.totalQuestions.length > 50) {
        temp.totalQuestions = "Only 50 character are allowed";
      } else if (fieldValues.totalQuestions > numberOfQuestionsValue) {
        temp.totalQuestions =
          "Number of questions cannot be greater than total questions";
      } else {
        temp.totalQuestions = "";
      }
    }

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [category, setCategory] = useState<ITechnicianQuizCategory[]>([]);
  const { values, errors, setErrors, handleInputChange } = useForm(
    initialValues,
    false,
    validate
  );
  const { isLoading, startRequest, endRequest } = useLoading();

  const handleCancel = () => {
    navigate("/app/quiz");
  };

  const handleSave = async () => {
    if (!validate()) return;

    try {
      startRequest("CreateQuiz");
      setIsSubmitting(true);
      values.technicianQuizCategory = category;
      await technicianQuizService.createQuiz(values);
      log.success("The test was succesfully created");
      endRequest("CreateQuiz");
      navigate("/app/quiz");
    } catch (error: any) {
      log.error(error?.message?.exceptionMessage ?? "Something went wrong");
    } finally {
      setIsSubmitting(false);
    }
  };

  const statuses = [
    { key: 1, value: "Enable" },
    { key: 0, value: "Disable" },
  ];

  const quizType = [
    { key: 1, value: "Energy/Auditor" },
    { key: 2, value: "Test & Balance" },
  ];

  const row = 6;

  return (
    <>
      <Helmet title="Blank" />
      <Typography variant="h3" gutterBottom display="inline">
        Test
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/">
          Quiz
        </Link>
        <Link component={NavLink} to="/app/quiz">
          Tests
        </Link>
        <Typography>Test</Typography>
      </Breadcrumbs>

      <Divider my={6} />
      <Card mb={6}>
        <CardContent>
          <Form>
            <Grid container spacing={5}>
              <Grid item xs={row}>
                <FormSelect
                  name="type"
                  label="Certification Type:"
                  value={values.type}
                  onChange={handleInputChange}
                  options={quizType}
                  error={errors.type}
                  disable={isSubmitting}
                  showSkeleton={isLoading}
                />
              </Grid>
              <Grid item xs={row}>
                <FormSelect
                  name="isActive"
                  label="Status"
                  value={values.isActive}
                  onChange={handleInputChange}
                  options={statuses}
                  error={errors.isActive}
                  disable={isSubmitting}
                  showSkeleton={isLoading}
                />
              </Grid>
              <Grid item xs={row}>
                <FormText
                  name="title"
                  label="Title"
                  value={values.title}
                  onChange={handleInputChange}
                  error={errors.title}
                  disabled={isSubmitting}
                  showSkeleton={isLoading}
                />
              </Grid>
              <Grid item xs={row}>
                <FormText
                  name="description"
                  label="Description"
                  value={values.description}
                  onChange={handleInputChange}
                  error={errors.description}
                  disabled={isSubmitting}
                  showSkeleton={isLoading}
                />
              </Grid>
              <Grid item xs={row}>
                <FormText
                  name="totalQuestions"
                  label="Number of questions"
                  value={values.totalQuestions}
                  onChange={handleInputChange}
                  error={errors.totalQuestions}
                  disabled={isSubmitting}
                  showSkeleton={isLoading}
                />
              </Grid>
              <Grid item xs={row}>
                <FormText
                  name="numbrTotaQuestions"
                  label="Total questions"
                  value={numberOfQuestionsValue.toString()}
                  onChange={handleInputChange}
                  error={errors.numbrTotaQuestions}
                  disabled={true}
                  showSkeleton={isLoading}
                />
              </Grid>
              <Grid item xs={row}>
                <FormText
                  name="numberQuestionsToPass"
                  label="Percentage to pass"
                  value={values.numberQuestionsToPass}
                  onChange={handleInputChange}
                  error={errors.numberQuestionsToPass}
                  disabled={isSubmitting}
                  showSkeleton={isLoading}
                />
              </Grid>
              <Grid item xs={12}>
                <SelectQuizQuestions
                  quizId={quizId}
                  setNumberOfQuestions={setNumberOfQuestionsValue}
                  setSelectedCategory={setCategory}
                  selectedCategory={category}
                ></SelectQuizQuestions>
              </Grid>
              <Grid item xs={12}>
                <Stack
                  direction="row"
                  spacing={2}
                  justifyContent="center"
                  alignItems="center"
                >
                  <FormCancelButton
                    onClick={handleCancel}
                    isSubmitting={isSubmitting}
                    disabled={isSubmitting}
                  />
                  <FormSaveButton
                    onClick={handleSave}
                    isSubmitting={isSubmitting}
                    disabled={isSubmitting}
                    showSkeleton={isLoading}
                  />
                </Stack>
              </Grid>
            </Grid>
          </Form>
        </CardContent>
      </Card>
    </>
  );
};

export default Quiz;
