/* eslint-disable react-hooks/exhaustive-deps */
import {
  CardContent,
  Grid,
  Divider as MuiDivider,
  Card as MuiCard,
  Button,
  Alert,
} from "@mui/material";

import { Stack, spacing } from "@mui/system";
import styled from "@emotion/styled";
import HeaderStudyPage from "src/components/page/HeaderStudyPage";
import { ChangeEvent, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  useAsyncQuery,
  useLog,
  useAuth,
  useSystemOfMeasurement,
  useFormulas,
} from "src/hooks";
import {
  FormCheckBox,
  FormDatePicker,
  FormLabel,
  FormNumeric2,
  FormRadioGroup,
  FormSelect,
  FormText,
  FormTimePicker,
} from "src/components/formControls";
import useFormTyped from "src/hooks/useFormTyped";
import {
  IEnergyStudyAirHydronicsDTO,
  IFormulaSystemService,
  IReportType,
} from "src/ts/interfaces";
import { IProjectReport } from "src/ts/interfaces/project/projectDto";
import projectReportService from "src/services/study/projectReportService";
import reportTypeService from "src/services/study/reportTypeService";
import { steps } from "../const/consts";
import { FooterButtons } from "../../components/FooterButtons";
import singleEnergyStudyAirHydronicsService from "src/services/study/singleEnergyStudyAirHydronicsService";
import energyStudyAirHydronicsPutService from "src/services/study/energyStudyAirHydronicsPutService";
import energyStudyAirHydronicsTimesService from "src/services/study/energyStudyAirHydronicsTimesService";
import FormTooltip from "src/components/formControls/FormTooltip";
import Popup from "src/components/Popup";
import { Validator } from "src/ts/types";
import StudyWizard from "../../components/StudyWizard";

const initialValues: any = {
  projectName: "",
  system: "",
  equipmentLocation: "",
  conditionOfTest: "",
};

const testModeOptions = [
  { key: "Cooling", value: "Cooling" },
  { key: "Heating", value: "Heating" },
];

const targetOptions = [
  { key: "Air", value: "I know the water, find for air" },
  { key: "Water", value: "I know the air, find for water" },
];

const fanPositionOptions = [
  {
    key: "Fan is Draw Through / Down-Stream Coil Tested",
    value: "Fan is Draw Through / Down-Stream Coil Tested",
  },
  {
    key: "Fan is Push Through / Up-Stream Coil Tested",
    value: "Fan is Push Through / Up-Stream Coil Tested",
  },
];

const numberOfPumpsOptions = [
  { key: 0, value: "Select number of pumps" },
  { key: 1, value: "1 Pump" },
  { key: 2, value: "2 Pumps" },
  { key: 3, value: "3 Pumps" },
  { key: 4, value: "4 Pumps" },
  { key: 5, value: "5 Pumps" },
  { key: 6, value: "6 Pumps" },
  { key: 7, value: "7 Pumps" },
  { key: 8, value: "8 Pumps" },
  { key: 9, value: "9 Pumps" },
];

const acphOptions = [
  { key: "Outside Air CFM", value: "Outside Air CFM" },
  { key: "Exhaust Air CFM", value: "Exhaust Air CFM" },
];

const Divider = styled(MuiDivider)(spacing);
const Card = styled(MuiCard)(spacing);

const EnergyHydronicsStep1 = () => {
  const { log } = useLog();
  const { user } = useAuth();
  const { formulaSystem } = useFormulas();
  const [formulas, setFormulas] = useState<IFormulaSystemService>();
  const { systemOfMeasurement } = useSystemOfMeasurement(true);
  const params = useParams<{ id: string; step: string }>();
  const id = parseInt(params?.id === undefined ? "0" : params?.id);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [title, setTitle] = useState<string>();
  const [code, setCode] = useState<string>();
  const [changeTestModeValue, setChangeTestModeValue] = useState<string>();
  const [openTestModeAlert, setOpenTestModeAlert] = useState(false);
  const [openIncompleteAlert, setOpenIncompleteAlert] = useState(false);

  let navigate = useNavigate();

  const validate: any = (fieldValues = values) => {
    let temp: Record<string, string> = { ...errors };

    //AHU Design data
    temp.sytemTotalBTUH = new Validator(fieldValues, "sytemTotalBTUH")
      .validateIf(!fieldValues.sytemTotalBTUHNotListed)
      .isRequired("This field is required")
      .greatThan(0, "This field must be greater than 0")
      .toString();
    temp.systemSensibleBTUH = new Validator(fieldValues, "systemSensibleBTUH")
      .validateIf(!fieldValues.systemSensibleBTUHNotListed)
      .isRequired("This field is required")
      .greatThan(0, "This field must be greater than 0")
      .toString();
    temp.systemLatentBTUH = new Validator(fieldValues, "systemLatentBTUH")
      .validateIf(!fieldValues.systemLatentBTUHNotListed)
      .isRequired("This field is required")
      .greatThan(0, "This field must be greater than 0")
      .toString();
    temp.heatingSytemTotalBTUH = new Validator(
      fieldValues,
      "heatingSytemTotalBTUH"
    )
      .validateIf(!fieldValues.heatingSytemTotalBTUHNotListed)
      .isRequired("This field is required")
      .greatThan(0, "This field must be greater than 0")
      .toString();
    temp.heatingSystemSensibleBTUH = new Validator(
      fieldValues,
      "heatingSystemSensibleBTUH"
    )
      .validateIf(!fieldValues.heatingSystemSensibleBTUHNotListed)
      .isRequired("This field is required")
      .greatThan(0, "This field must be greater than 0")
      .toString();
    temp.heatingSystemLatentBTUH = new Validator(
      fieldValues,
      "heatingSystemLatentBTUH"
    )
      .validateIf(!fieldValues.heatingSystemLatentBTUHNotListed)
      .isRequired("This field is required")
      .greatThan(0, "This field must be greater than 0")
      .toString();

    //Outside/Interior temp values
    temp.outsideDesignDBCooling = new Validator(
      fieldValues,
      "outsideDesignDBCooling"
    )
      .validateIf(!fieldValues.outsideDesignDBCoolingNotListed)
      .isRequired("This field is required")
      .toString();
    temp.outsideDesignCooling = new Validator(
      fieldValues,
      "outsideDesignCooling"
    )
      .validateIf(!fieldValues.outsideDesignCoolingNotListed)
      .isRequired("This field is required")
      .toString();
    temp.outsideDesignDBHeating = new Validator(
      fieldValues,
      "outsideDesignDBHeating"
    )
      .validateIf(!fieldValues.outsideDesignDBHeatingNotListed)
      .isRequired("This field is required")
      .toString();
    temp.outsideDesignHeating = new Validator(
      fieldValues,
      "outsideDesignHeating"
    )
      .validateIf(!fieldValues.outsideDesignHeatingNotListed)
      .isRequired("This field is required")
      .toString();
    temp.interiorDesignZoneConditionsDBCooling = new Validator(
      fieldValues,
      "interiorDesignZoneConditionsDBCooling"
    )
      .validateIf(!fieldValues.interiorDesignZoneConditionsDBCoolingNotListed)
      .isRequired("This field is required")
      .toString();
    temp.interiorDesignZoneConditionsCooling = new Validator(
      fieldValues,
      "interiorDesignZoneConditionsCooling"
    )
      .validateIf(!fieldValues.interiorDesignZoneConditionsCoolingNotListed)
      .isRequired("This field is required")
      .toString();
    temp.interiorDesignZoneConditionsDBHeating = new Validator(
      fieldValues,
      "interiorDesignZoneConditionsDBHeating"
    )
      .validateIf(!fieldValues.interiorDesignZoneConditionsDBHeatingNotListed)
      .isRequired("This field is required")
      .toString();
    temp.interiorDesignZoneConditionsHeating = new Validator(
      fieldValues,
      "interiorDesignZoneConditionsHeating"
    )
      .validateIf(!fieldValues.interiorDesignZoneConditionsHeatingNotListed)
      .isRequired("This field is required")
      .toString();

    //Area Served
    temp.floorAreaSqFtFed = new Validator(fieldValues, "floorAreaSqFtFed")
      .validateIf(!fieldValues.floorAreaSqFtFedNotListed)
      .isRequired("This field is required")
      .greatThan(0, "This field must be greater than 0")
      .toString();
    temp.floorAreaCuFtFed = new Validator(fieldValues, "floorAreaCuFtFed")
      .validateIf(!fieldValues.floorAreaCuFtFedNotListed)
      .isRequired("This field is required")
      .greatThan(0, "This field must be greater than 0")
      .toString();
    temp.acphConfiguredAs = new Validator(fieldValues, "acphConfiguredAs")
      .validateIf(!fieldValues.acphConfiguredAsNotListed)
      .isRequired("This field is required")
      .toString();

    //Airflow
    temp.systemCFM = new Validator(fieldValues, "systemCFM")
      .validateIf(!fieldValues.systemCFMNotListed)
      .isRequired("This field is required")
      .greatThan(0, "This field must be greater than 0")
      .toString();
    temp.outletCFM = new Validator(fieldValues, "outletCFM")
      .validateIf(!fieldValues.outletCFMNotListed)
      .isRequired("This field is required")
      .greatThan(0, "This field must be greater than 0")
      .toString();
    temp.returnAirflow = new Validator(fieldValues, "returnAirflow")
      .validateIf(!fieldValues.returnAirflowNotListed)
      .isRequired("This field is required")
      .toString();
    temp.outsideAirflow = new Validator(fieldValues, "outsideAirflow")
      .validateIf(!fieldValues.outsideAirflowNotListed)
      .isRequired("This field is required")
      .toString();
    temp.systemExhaustAirflow = new Validator(
      fieldValues,
      "systemExhaustAirflow"
    )
      .validateIf(!fieldValues.systemExhaustAirflowNotListed)
      .isRequired("This field is required")
      .toString();
    temp.numberOfFansX = new Validator(fieldValues, "numberOfFansX")
      .validateIf(!fieldValues.numberOfFansXNotListed)
      .isRequired("This field is required")
      .greatThan(
        fieldValues.supplyFanSystemIsArray ? 1 : 0,
        fieldValues.supplyFanSystemIsArray
          ? "This field must be greater than 1"
          : "This field must be greater than 0"
      )
      .toString();
    temp.numberOfFansY = new Validator(fieldValues, "numberOfFansY")
      .validateIf(
        !fieldValues.numberOfFansYNotListed &&
          fieldValues.supplyFanSystemIsArray
      )
      .isRequired("This field is required")
      .greatThan(1, "This field must be greater than 1")
      .toString();

    //Coil Cooling
    temp.numberOfCircuitsInCoolingCoil = new Validator(
      fieldValues,
      "numberOfCircuitsInCoolingCoil"
    )
      .validateIf(
        !fieldValues.numberOfCircuitsInCoolingCoilNotListed &&
          fieldValues.coolingCoilHasMultipleCircuits
      )
      .isRequired("This field is required")
      .greatThan(1, "This field must be greater than 1")
      .lessThan(16, "This field must be lower than 16")
      .toString();
    temp.enteringAirTemperatureDBCooling = new Validator(
      fieldValues,
      "enteringAirTemperatureDBCooling"
    )
      .validateIf(!fieldValues.enteringAirTemperatureDBCoolingNotListed)
      .isRequired("This field is required")
      .toString();
    temp.enteringAirTemperatureWBCooling = new Validator(
      fieldValues,
      "enteringAirTemperatureWBCooling"
    )
      .validateIf(!fieldValues.enteringAirTemperatureWBCoolingNotListed)
      .isRequired("This field is required")
      .toString();
    temp.leavingAirTemperatureDBCooling = new Validator(
      fieldValues,
      "leavingAirTemperatureDBCooling"
    )
      .validateIf(!fieldValues.leavingAirTemperatureDBCoolingNotListed)
      .isRequired("This field is required")
      .toString();
    temp.leavingAirTemperatureWBCooling = new Validator(
      fieldValues,
      "leavingAirTemperatureWBCooling"
    )
      .validateIf(!fieldValues.leavingAirTemperatureWBCoolingNotListed)
      .isRequired("This field is required")
      .toString();
    temp.maAirPressureDeltaCooling = new Validator(
      fieldValues,
      "maAirPressureDeltaCooling"
    )
      .validateIf(!fieldValues.maAirPressureDeltaCoolingNotListed)
      .isRequired("This field is required")
      .greatThan(0, "This field must be greater than 0")
      .toString();
    temp.designWaterDeltaPressureCooling = new Validator(
      fieldValues,
      "designWaterDeltaPressureCooling"
    )
      .validateIf(!fieldValues.designWaterDeltaPressureCoolingNotListed)
      .isRequired("This field is required")
      .greatThan(0, "This field must be greater than 0")
      .toString();
    temp.coolingCoilGPM = new Validator(fieldValues, "coolingCoilGPM")
      .validateIf(!fieldValues.coolingCoilGPMNotListed)
      .isRequired("This field is required")
      .greatThan(0, "This field must be greater than 0")
      .toString();
    temp.enteringHydronicCoolingCoilTemperature = new Validator(
      fieldValues,
      "enteringHydronicCoolingCoilTemperature"
    )
      .validateIf(!fieldValues.enteringHydronicCoolingCoilTemperatureNotListed)
      .isRequired("This field is required")
      .toString();
    temp.leavingHydronicCoolingCoilTemperature = new Validator(
      fieldValues,
      "leavingHydronicCoolingCoilTemperature"
    )
      .validateIf(!fieldValues.leavingHydronicCoolingCoilTemperatureNotListed)
      .isRequired("This field is required")
      .toString();
    temp.hydronicFluidFlowDeltaTemperatureCooling = new Validator(
      fieldValues,
      "hydronicFluidFlowDeltaTemperatureCooling"
    )
      .validateIf(
        !fieldValues.hydronicFluidFlowDeltaTemperatureCoolingNotListed
      )
      .isRequired("This field is required")
      .toString();
    temp.totalBTUHCooling = new Validator(fieldValues, "totalBTUHCooling")
      .validateIf(!fieldValues.totalBTUHCoolingNotListed)
      .isRequired("This field is required")
      .greatThan(0, "This field must be greater than 0")
      .toString();
    temp.sensibleBTUHCooling = new Validator(fieldValues, "sensibleBTUHCooling")
      .validateIf(!fieldValues.sensibleBTUHCoolingNotListed)
      .isRequired("This field is required")
      .greatThan(0, "This field must be greater than 0")
      .toString();
    temp.latentBTUHCooling = new Validator(fieldValues, "latentBTUHCooling")
      .validateIf(!fieldValues.latentBTUHCoolingNotListed)
      .isRequired("This field is required")
      .greatThan(0, "This field must be greater than 0")
      .toString();
    temp.wpdFeetHeadCooling = new Validator(fieldValues, "wpdFeetHeadCooling")
      .validateIf(!fieldValues.wpdFeetHeadCoolingNotListed)
      .isRequired("This field is required")
      .greatThan(0, "This field must be greater than 0")
      .toString();
    temp.finsPerInchCooling = new Validator(fieldValues, "finsPerInchCooling")
      .validateIf(!fieldValues.finsPerInchCoolingNotListed)
      .isRequired("This field is required")
      .greatThan(0, "This field must be greater than 0")
      .toString();
    temp.coolingCoilRows = new Validator(fieldValues, "coolingCoilRows")
      .validateIf(!fieldValues.coolingCoilRowsNotListed)
      .isRequired("This field is required")
      .greatThan(0, "This field must be greater than 0")
      .toString();

    //Coil Heating
    temp.numberOfCircuitsInHeatingCoil = new Validator(
      fieldValues,
      "numberOfCircuitsInHeatingCoil"
    )
      .validateIf(
        !fieldValues.numberOfCircuitsInHeatingCoilNotListed &&
          fieldValues.heatingCoilHasMultipleCircuits
      )
      .isRequired("This field is required")
      .greatThan(1, "This field must be greater than 1")
      .lessThan(16, "This field must be lower than 16")
      .toString();
    temp.enteringAirTemperatureDBHeating = new Validator(
      fieldValues,
      "enteringAirTemperatureDBHeating"
    )
      .validateIf(!fieldValues.enteringAirTemperatureDBHeatingNotListed)
      .isRequired("This field is required")
      .toString();
    temp.enteringAirTemperatureWBHeating = new Validator(
      fieldValues,
      "enteringAirTemperatureWBHeating"
    )
      .validateIf(!fieldValues.enteringAirTemperatureWBHeatingNotListed)
      .isRequired("This field is required")
      .toString();
    temp.leavingAirTemperatureDBHeating = new Validator(
      fieldValues,
      "leavingAirTemperatureDBHeating"
    )
      .validateIf(!fieldValues.leavingAirTemperatureDBHeatingNotListed)
      .isRequired("This field is required")
      .toString();
    temp.leavingAirTemperatureWBHeating = new Validator(
      fieldValues,
      "leavingAirTemperatureWBHeating"
    )
      .validateIf(!fieldValues.leavingAirTemperatureWBHeatingNotListed)
      .isRequired("This field is required")
      .toString();
    temp.maAirPressureDeltaHeating = new Validator(
      fieldValues,
      "maAirPressureDeltaHeating"
    )
      .validateIf(!fieldValues.maAirPressureDeltaHeatingNotListed)
      .isRequired("This field is required")
      .greatThan(0, "This field must be greater than 0")
      .toString();
    temp.designWaterDeltaPressureHeating = new Validator(
      fieldValues,
      "designWaterDeltaPressureHeating"
    )
      .validateIf(!fieldValues.designWaterDeltaPressureHeatingNotListed)
      .isRequired("This field is required")
      .greatThan(0, "This field must be greater than 0")
      .toString();
    temp.heatingCoilGPM = new Validator(fieldValues, "heatingCoilGPM")
      .validateIf(!fieldValues.heatingCoilGPMNotListed)
      .isRequired("This field is required")
      .greatThan(0, "This field must be greater than 0")
      .toString();
    temp.enteringHydronicHeatingCoilTemperature = new Validator(
      fieldValues,
      "enteringHydronicHeatingCoilTemperature"
    )
      .validateIf(!fieldValues.enteringHydronicHeatingCoilTemperatureNotListed)
      .isRequired("This field is required")
      .toString();
    temp.leavingHydronicHeatingCoilTemperature = new Validator(
      fieldValues,
      "leavingHydronicHeatingCoilTemperature"
    )
      .validateIf(!fieldValues.leavingHydronicHeatingCoilTemperatureNotListed)
      .isRequired("This field is required")
      .toString();
    temp.hydronicFluidFlowDeltaTemperatureHeating = new Validator(
      fieldValues,
      "hydronicFluidFlowDeltaTemperatureHeating"
    )
      .validateIf(
        !fieldValues.hydronicFluidFlowDeltaTemperatureHeatingNotListed
      )
      .isRequired("This field is required")
      .toString();
    temp.totalBTUHHeating = new Validator(fieldValues, "totalBTUHHeating")
      .validateIf(!fieldValues.totalBTUHHeatingNotListed)
      .isRequired("This field is required")
      .greatThan(0, "This field must be greater than 0")
      .toString();
    temp.sensibleBTUHHeating = new Validator(fieldValues, "sensibleBTUHHeating")
      .validateIf(!fieldValues.sensibleBTUHHeatingNotListed)
      .isRequired("This field is required")
      .greatThan(0, "This field must be greater than 0")
      .toString();
    temp.latentBTUHHeating = new Validator(fieldValues, "latentBTUHHeating")
      .validateIf(!fieldValues.latentBTUHHeatingNotListed)
      .isRequired("This field is required")
      .greatThan(0, "This field must be greater than 0")
      .toString();
    temp.wpdFeetHeadHeating = new Validator(fieldValues, "wpdFeetHeadHeating")
      .validateIf(!fieldValues.wpdFeetHeadHeatingNotListed)
      .isRequired("This field is required")
      .greatThan(0, "This field must be greater than 0")
      .toString();
    temp.finsPerInchHeating = new Validator(fieldValues, "finsPerInchHeating")
      .validateIf(!fieldValues.finsPerInchHeatingNotListed)
      .isRequired("This field is required")
      .greatThan(0, "This field must be greater than 0")
      .toString();
    temp.heatingCoilRows = new Validator(fieldValues, "heatingCoilRows")
      .validateIf(!fieldValues.heatingCoilRowsNotListed)
      .isRequired("This field is required")
      .greatThan(0, "This field must be greater than 0")
      .toString();

    if (disableStudy()) return true;

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange } =
    useFormTyped<IEnergyStudyAirHydronicsDTO>(initialValues, false, validate);

  const activeStep = 0;

  const { execute: executeReport, data: dataReport } =
    useAsyncQuery<IProjectReport>(projectReportService.report);

  const { execute, isLoading } = useAsyncQuery<IEnergyStudyAirHydronicsDTO>(
    singleEnergyStudyAirHydronicsService.getById,
    {
      onSuccess: (dataResult) => {
        const getData = async () => {
          const formulas = await formulaSystem.setSystem(dataResult.projectId);
          setFormulas(formulas);
          await systemOfMeasurement.getByProject(dataResult.projectId);
          setValues({
            ...dataResult,
            numberOfCircuitsInCoolingCoil:
              dataResult.numberOfCircuitsInCoolingCoil ?? 1,
            numberOfCircuitsInHeatingCoil:
              dataResult.numberOfCircuitsInHeatingCoil ?? 1,
          });
        };
        getData();
      },
    }
  );

  const { execute: executeReportType, isLoading: isLoadingReportType } =
    useAsyncQuery<IReportType>(reportTypeService.getReportTypeById, {
      onSuccess: (dataResult) => {
        const getData = async () => {
          setCode(dataResult.code);
          setTitle(dataResult.name);
        };
        getData();
      },
    });

  const updateSystemTotalBTUHResponse = async (
    dataResult: IEnergyStudyAirHydronicsDTO
  ) => {
    setValues({
      ...values,
      sytemTotalBTUH:
        dataResult.sytemTotalBTUH != null ? dataResult.sytemTotalBTUH : null,
      systemSensibleBTUH:
        dataResult.systemSensibleBTUH != null
          ? dataResult.systemSensibleBTUH
          : null,
      systemLatentBTUH:
        dataResult.systemLatentBTUH != null
          ? dataResult.systemLatentBTUH
          : null,
      sytemTotalBTUHExpressedIn: dataResult.sytemTotalBTUHExpressedIn,
      systemSensibleBTUHExpressedIn: dataResult.systemSensibleBTUHExpressedIn,
      systemLatentBTUHExpressedIn: dataResult.systemLatentBTUHExpressedIn,
      sytemTotalBTUHNotListed: dataResult.sytemTotalBTUHNotListed,
      systemSensibleBTUHNotListed: dataResult.systemSensibleBTUHNotListed,
      systemLatentBTUHNotListed: dataResult.systemLatentBTUHNotListed,
      shrAHUCooling: dataResult.shrAHUCooling,
    });
  };

  const { execute: executeTimesUpdateSystemTotalBTUH } =
    useAsyncQuery<IEnergyStudyAirHydronicsDTO>(
      energyStudyAirHydronicsTimesService.updateSystemTotalBTUH,
      {
        onSuccess: (dataResult) => {
          updateSystemTotalBTUHResponse(dataResult);
        },
      }
    );

  const { execute: executeUpdateSystemTotalBTUH } =
    useAsyncQuery<IEnergyStudyAirHydronicsDTO>(
      energyStudyAirHydronicsPutService.update,
      {
        onSuccess: (dataResult) => {
          updateSystemTotalBTUHResponse(dataResult);
        },
      }
    );

  const updateHeatingSystemTotalBTUHResponse = async (
    dataResult: IEnergyStudyAirHydronicsDTO
  ) => {
    setValues({
      ...values,
      heatingSytemTotalBTUH:
        dataResult.heatingSytemTotalBTUH != null
          ? dataResult.heatingSytemTotalBTUH
          : null,
      heatingSystemSensibleBTUH:
        dataResult.heatingSystemSensibleBTUH != null
          ? dataResult.heatingSystemSensibleBTUH
          : null,
      heatingSystemLatentBTUH:
        dataResult.heatingSystemLatentBTUH != null
          ? dataResult.heatingSystemLatentBTUH
          : null,
      heatingSytemTotalBTUHExpressedIn:
        dataResult.heatingSytemTotalBTUHExpressedIn,
      heatingSystemSensibleBTUHExpressedIn:
        dataResult.heatingSystemSensibleBTUHExpressedIn,
      heatingSystemLatentBTUHExpressedIn:
        dataResult.heatingSystemLatentBTUHExpressedIn,
      heatingSytemTotalBTUHNotListed: dataResult.heatingSytemTotalBTUHNotListed,
      heatingSystemSensibleBTUHNotListed:
        dataResult.heatingSystemSensibleBTUHNotListed,
      heatingSystemLatentBTUHNotListed:
        dataResult.heatingSystemLatentBTUHNotListed,
      shrAHUHeating: dataResult.shrAHUHeating,
    });
  };

  const { execute: executeTimesUpdateHeatingSystemTotalBTUH } =
    useAsyncQuery<IEnergyStudyAirHydronicsDTO>(
      energyStudyAirHydronicsTimesService.updateHeatingSystemTotalBTUH,
      {
        onSuccess: (dataResult) => {
          updateHeatingSystemTotalBTUHResponse(dataResult);
        },
      }
    );

  const { execute: executeUpdateHeatingSystemTotalBTUH } =
    useAsyncQuery<IEnergyStudyAirHydronicsDTO>(
      energyStudyAirHydronicsPutService.update,
      {
        onSuccess: (dataResult) => {
          updateHeatingSystemTotalBTUHResponse(dataResult);
        },
      }
    );

  //Cooling coil
  const updateCoolingCoilTotalBTUHResponse = async (
    dataResult: IEnergyStudyAirHydronicsDTO
  ) => {
    setValues({
      ...values,
      totalBTUHCooling:
        dataResult.totalBTUHCooling != null
          ? dataResult.totalBTUHCooling
          : null,
      sensibleBTUHCooling:
        dataResult.sensibleBTUHCooling != null
          ? dataResult.sensibleBTUHCooling
          : null,
      latentBTUHCooling:
        dataResult.latentBTUHCooling != null
          ? dataResult.latentBTUHCooling
          : null,
      totalBTUHCoolingExpressedIn: dataResult.totalBTUHCoolingExpressedIn,
      sensibleBTUHCoolingExpressedIn: dataResult.sensibleBTUHCoolingExpressedIn,
      latentBTUHCoolingExpressedIn: dataResult.latentBTUHCoolingExpressedIn,
      totalBTUHCoolingNotListed: dataResult.totalBTUHCoolingNotListed,
      sensibleBTUHCoolingNotListed: dataResult.sensibleBTUHCoolingNotListed,
      latentBTUHCoolingNotListed: dataResult.latentBTUHCoolingNotListed,
      shrCoilCooling: dataResult.shrCoilCooling,
    });
  };

  const { execute: executeTimesUpdateCoolingCoilTotalBTUH } =
    useAsyncQuery<IEnergyStudyAirHydronicsDTO>(
      energyStudyAirHydronicsTimesService.updateCoolingCoilBTUH,
      {
        onSuccess: (dataResult) => {
          updateCoolingCoilTotalBTUHResponse(dataResult);
        },
      }
    );

  const { execute: executeUpdateCoolingCoilTotalBTUH } =
    useAsyncQuery<IEnergyStudyAirHydronicsDTO>(
      energyStudyAirHydronicsPutService.update,
      {
        onSuccess: (dataResult) => {
          updateCoolingCoilTotalBTUHResponse(dataResult);
        },
      }
    );

  const updateCoolingCoilGPMResponse = async (
    dataResult: IEnergyStudyAirHydronicsDTO
  ) => {
    setValues({
      ...values,
      enteringHydronicCoolingCoilTemperature:
        dataResult.enteringHydronicCoolingCoilTemperature != null
          ? dataResult.enteringHydronicCoolingCoilTemperature
          : null,
      leavingHydronicCoolingCoilTemperature:
        dataResult.leavingHydronicCoolingCoilTemperature != null
          ? dataResult.leavingHydronicCoolingCoilTemperature
          : null,
      hydronicFluidFlowDeltaTemperatureCooling:
        dataResult.hydronicFluidFlowDeltaTemperatureCooling != null
          ? dataResult.hydronicFluidFlowDeltaTemperatureCooling
          : null,
      hydronicFluidFlowDeltaTemperatureCoolingNotListed:
        dataResult.hydronicFluidFlowDeltaTemperatureCoolingNotListed,
      enteringHydronicCoolingCoilTemperatureNotListed:
        dataResult.enteringHydronicCoolingCoilTemperatureNotListed,
      leavingHydronicCoolingCoilTemperatureNotListed:
        dataResult.leavingHydronicCoolingCoilTemperatureNotListed,
      totalBTUHCooling: dataResult.totalBTUHCooling,
      totalBTUHCoolingNotListed: dataResult.totalBTUHCoolingNotListed,
    });
  };

  const { execute: executeTimesUpdateCoolingCoilGPM } =
    useAsyncQuery<IEnergyStudyAirHydronicsDTO>(
      energyStudyAirHydronicsTimesService.updateCoolingCoilGPM,
      {
        onSuccess: (dataResult) => {
          updateCoolingCoilGPMResponse(dataResult);
        },
      }
    );

  const updateCoolingCoilEWTResponse = async (
    dataResult: IEnergyStudyAirHydronicsDTO
  ) => {
    setValues({
      ...values,
      hydronicFluidFlowDeltaTemperatureCooling:
        dataResult.hydronicFluidFlowDeltaTemperatureCooling != null
          ? dataResult.hydronicFluidFlowDeltaTemperatureCooling
          : null,
      leavingHydronicCoolingCoilTemperature:
        dataResult.leavingHydronicCoolingCoilTemperature != null
          ? dataResult.leavingHydronicCoolingCoilTemperature
          : null,
      hydronicFluidFlowDeltaTemperatureCoolingNotListed:
        dataResult.hydronicFluidFlowDeltaTemperatureCoolingNotListed,
      leavingHydronicCoolingCoilTemperatureNotListed:
        dataResult.leavingHydronicCoolingCoilTemperatureNotListed,
      totalBTUHCooling: dataResult.totalBTUHCooling,
      totalBTUHCoolingNotListed: dataResult.totalBTUHCoolingNotListed,
    });
  };

  const { execute: executeTimesUpdateCoolingCoilEWT } =
    useAsyncQuery<IEnergyStudyAirHydronicsDTO>(
      energyStudyAirHydronicsTimesService.updateCoolingCoilEWT,
      {
        onSuccess: (dataResult) => {
          updateCoolingCoilEWTResponse(dataResult);
        },
      }
    );

  const updateCoolingCoilLWTResponse = async (
    dataResult: IEnergyStudyAirHydronicsDTO
  ) => {
    setValues({
      ...values,
      hydronicFluidFlowDeltaTemperatureCooling:
        dataResult.hydronicFluidFlowDeltaTemperatureCooling != null
          ? dataResult.hydronicFluidFlowDeltaTemperatureCooling
          : null,
      enteringHydronicCoolingCoilTemperature:
        dataResult.enteringHydronicCoolingCoilTemperature != null
          ? dataResult.enteringHydronicCoolingCoilTemperature
          : null,
      hydronicFluidFlowDeltaTemperatureCoolingNotListed:
        dataResult.hydronicFluidFlowDeltaTemperatureCoolingNotListed,
      enteringHydronicCoolingCoilTemperatureNotListed:
        dataResult.enteringHydronicCoolingCoilTemperatureNotListed,
      totalBTUHCooling: dataResult.totalBTUHCooling,
      totalBTUHCoolingNotListed: dataResult.totalBTUHCoolingNotListed,
    });
  };

  const { execute: executeTimesUpdateCoolingCoilLWT } =
    useAsyncQuery<IEnergyStudyAirHydronicsDTO>(
      energyStudyAirHydronicsTimesService.updateCoolingCoilLWT,
      {
        onSuccess: (dataResult) => {
          updateCoolingCoilLWTResponse(dataResult);
        },
      }
    );

  const updateCoolingCoilAWTResponse = async (
    dataResult: IEnergyStudyAirHydronicsDTO
  ) => {
    setValues({
      ...values,
      enteringHydronicCoolingCoilTemperature:
        dataResult.enteringHydronicCoolingCoilTemperature != null
          ? dataResult.enteringHydronicCoolingCoilTemperature
          : null,
      leavingHydronicCoolingCoilTemperature:
        dataResult.leavingHydronicCoolingCoilTemperature != null
          ? dataResult.leavingHydronicCoolingCoilTemperature
          : null,
      enteringHydronicCoolingCoilTemperatureNotListed:
        dataResult.enteringHydronicCoolingCoilTemperatureNotListed,
      leavingHydronicCoolingCoilTemperatureNotListed:
        dataResult.leavingHydronicCoolingCoilTemperatureNotListed,
      totalBTUHCooling: dataResult.totalBTUHCooling,
      totalBTUHCoolingNotListed: dataResult.totalBTUHCoolingNotListed,
    });
  };

  const { execute: executeTimesUpdateCoolingCoilAWT } =
    useAsyncQuery<IEnergyStudyAirHydronicsDTO>(
      energyStudyAirHydronicsTimesService.updateCoolingCoilAWT,
      {
        onSuccess: (dataResult) => {
          updateCoolingCoilAWTResponse(dataResult);
        },
      }
    );

  //Heating coil
  const updateHeatingCoilTotalBTUHResponse = async (
    dataResult: IEnergyStudyAirHydronicsDTO
  ) => {
    setValues({
      ...values,
      totalBTUHHeating:
        dataResult.totalBTUHHeating != null
          ? dataResult.totalBTUHHeating
          : null,
      sensibleBTUHHeating:
        dataResult.sensibleBTUHHeating != null
          ? dataResult.sensibleBTUHHeating
          : null,
      latentBTUHHeating:
        dataResult.latentBTUHHeating != null
          ? dataResult.latentBTUHHeating
          : null,
      totalBTUHHeatingExpressedIn: dataResult.totalBTUHHeatingExpressedIn,
      sensibleBTUHHeatingExpressedIn: dataResult.sensibleBTUHHeatingExpressedIn,
      latentBTUHHeatingExpressedIn: dataResult.latentBTUHHeatingExpressedIn,
      totalBTUHHeatingNotListed: dataResult.totalBTUHHeatingNotListed,
      sensibleBTUHHeatingNotListed: dataResult.sensibleBTUHHeatingNotListed,
      latentBTUHHeatingNotListed: dataResult.latentBTUHHeatingNotListed,
      shrCoilHeating: dataResult.shrCoilHeating,
    });
  };

  const { execute: executeTimesUpdateHeatingCoilTotalBTUH } =
    useAsyncQuery<IEnergyStudyAirHydronicsDTO>(
      energyStudyAirHydronicsTimesService.updateHeatingCoilBTUH,
      {
        onSuccess: (dataResult) => {
          updateHeatingCoilTotalBTUHResponse(dataResult);
        },
      }
    );

  const { execute: executeUpdateHeatingCoilTotalBTUH } =
    useAsyncQuery<IEnergyStudyAirHydronicsDTO>(
      energyStudyAirHydronicsPutService.update,
      {
        onSuccess: (dataResult) => {
          updateHeatingCoilTotalBTUHResponse(dataResult);
        },
      }
    );

  const updateHeatingCoilGPMResponse = async (
    dataResult: IEnergyStudyAirHydronicsDTO
  ) => {
    setValues({
      ...values,
      enteringHydronicHeatingCoilTemperature:
        dataResult.enteringHydronicHeatingCoilTemperature != null
          ? dataResult.enteringHydronicHeatingCoilTemperature
          : null,
      leavingHydronicHeatingCoilTemperature:
        dataResult.leavingHydronicHeatingCoilTemperature != null
          ? dataResult.leavingHydronicHeatingCoilTemperature
          : null,
      hydronicFluidFlowDeltaTemperatureHeating:
        dataResult.hydronicFluidFlowDeltaTemperatureHeating != null
          ? dataResult.hydronicFluidFlowDeltaTemperatureHeating
          : null,
      hydronicFluidFlowDeltaTemperatureHeatingNotListed:
        dataResult.hydronicFluidFlowDeltaTemperatureHeatingNotListed,
      enteringHydronicHeatingCoilTemperatureNotListed:
        dataResult.enteringHydronicHeatingCoilTemperatureNotListed,
      leavingHydronicHeatingCoilTemperatureNotListed:
        dataResult.leavingHydronicHeatingCoilTemperatureNotListed,
      totalBTUHHeating: dataResult.totalBTUHHeating,
      totalBTUHHeatingNotListed: dataResult.totalBTUHHeatingNotListed,
    });
  };

  const { execute: executeTimesUpdateHeatingCoilGPM } =
    useAsyncQuery<IEnergyStudyAirHydronicsDTO>(
      energyStudyAirHydronicsTimesService.updateHeatingCoilGPM,
      {
        onSuccess: (dataResult) => {
          updateHeatingCoilGPMResponse(dataResult);
        },
      }
    );

  const updateHeatingCoilEWTResponse = async (
    dataResult: IEnergyStudyAirHydronicsDTO
  ) => {
    setValues({
      ...values,
      hydronicFluidFlowDeltaTemperatureHeating:
        dataResult.hydronicFluidFlowDeltaTemperatureHeating != null
          ? dataResult.hydronicFluidFlowDeltaTemperatureHeating
          : null,
      leavingHydronicHeatingCoilTemperature:
        dataResult.leavingHydronicHeatingCoilTemperature != null
          ? dataResult.leavingHydronicHeatingCoilTemperature
          : null,
      hydronicFluidFlowDeltaTemperatureHeatingNotListed:
        dataResult.hydronicFluidFlowDeltaTemperatureHeatingNotListed,
      leavingHydronicHeatingCoilTemperatureNotListed:
        dataResult.leavingHydronicHeatingCoilTemperatureNotListed,
      totalBTUHHeating: dataResult.totalBTUHHeating,
      totalBTUHHeatingNotListed: dataResult.totalBTUHHeatingNotListed,
    });
  };

  const { execute: executeTimesUpdateHeatingCoilEWT } =
    useAsyncQuery<IEnergyStudyAirHydronicsDTO>(
      energyStudyAirHydronicsTimesService.updateHeatingCoilEWT,
      {
        onSuccess: (dataResult) => {
          updateHeatingCoilEWTResponse(dataResult);
        },
      }
    );

  const updateHeatingCoilLWTResponse = async (
    dataResult: IEnergyStudyAirHydronicsDTO
  ) => {
    setValues({
      ...values,
      hydronicFluidFlowDeltaTemperatureHeating:
        dataResult.hydronicFluidFlowDeltaTemperatureHeating != null
          ? dataResult.hydronicFluidFlowDeltaTemperatureHeating
          : null,
      enteringHydronicHeatingCoilTemperature:
        dataResult.enteringHydronicHeatingCoilTemperature != null
          ? dataResult.enteringHydronicHeatingCoilTemperature
          : null,
      hydronicFluidFlowDeltaTemperatureHeatingNotListed:
        dataResult.hydronicFluidFlowDeltaTemperatureHeatingNotListed,
      enteringHydronicHeatingCoilTemperatureNotListed:
        dataResult.enteringHydronicHeatingCoilTemperatureNotListed,
      totalBTUHHeating: dataResult.totalBTUHHeating,
      totalBTUHHeatingNotListed: dataResult.totalBTUHHeatingNotListed,
    });
  };

  const { execute: executeTimesUpdateHeatingCoilLWT } =
    useAsyncQuery<IEnergyStudyAirHydronicsDTO>(
      energyStudyAirHydronicsTimesService.updateHeatingCoilLWT,
      {
        onSuccess: (dataResult) => {
          updateHeatingCoilLWTResponse(dataResult);
        },
      }
    );

  const updateHeatingCoilAWTResponse = async (
    dataResult: IEnergyStudyAirHydronicsDTO
  ) => {
    setValues({
      ...values,
      enteringHydronicHeatingCoilTemperature:
        dataResult.enteringHydronicHeatingCoilTemperature != null
          ? dataResult.enteringHydronicHeatingCoilTemperature
          : null,
      leavingHydronicHeatingCoilTemperature:
        dataResult.leavingHydronicHeatingCoilTemperature != null
          ? dataResult.leavingHydronicHeatingCoilTemperature
          : null,
      enteringHydronicHeatingCoilTemperatureNotListed:
        dataResult.enteringHydronicHeatingCoilTemperatureNotListed,
      leavingHydronicHeatingCoilTemperatureNotListed:
        dataResult.leavingHydronicHeatingCoilTemperatureNotListed,
      totalBTUHHeating: dataResult.totalBTUHHeating,
      totalBTUHHeatingNotListed: dataResult.totalBTUHHeatingNotListed,
    });
  };

  const { execute: executeTimesUpdateHeatingCoilAWT } =
    useAsyncQuery<IEnergyStudyAirHydronicsDTO>(
      energyStudyAirHydronicsTimesService.updateHeatingCoilAWT,
      {
        onSuccess: (dataResult) => {
          updateHeatingCoilAWTResponse(dataResult);
        },
      }
    );

  const disableStudy = () => {
    return (
      values?.isComplete ||
      (dataReport?.isInUse && dataReport?.isInUseById !== user?.userId) ||
      dataReport?.isPartOfProject === false
    );
  };

  useEffect(() => {
    if (values?.reportTypeId) executeReportType(values?.reportTypeId);
  }, [values?.reportTypeId]);

  useEffect(() => {
    const getData = async () => {
      try {
        await execute(id);
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      }
    };

    if (id !== 0) getData();
  }, [id]);

  useEffect(() => {
    const getData = async () => {
      try {
        await executeReport(id, code);
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      }
    };

    if (id !== 0 && code !== undefined) getData();
  }, [id, code]);

  const saveHandler = async () => {
    try {
      setIsUpdating(true);
      await energyStudyAirHydronicsPutService.update(id, values);
      log.success("Report was updated successfully");
      setIsUpdating(false);
    } catch (error) {}
  };

  const nexStepHandler = async () => {
    if (!validate()) {
      setOpenIncompleteAlert(true);
      return;
    }
    try {
      if (!disableStudy()) {
        setIsSubmitting(true);
        await energyStudyAirHydronicsPutService.update(id, values);
        log.success("Report was updated successfully");
        setIsSubmitting(false);
      }
      navigate(`/studies/EnergyStudyAirHydronics/step2/${id}`);
    } catch (error) {}
  };

  const handleSystemTypeChange = (e: any) =>
    setValues({
      ...values,
      systemIsMA: e.target.name === "systemIsMA",
      systemIs100OA: e.target.name === "systemIs100OA",
      systemIs100RA: e.target.name === "systemIs100RA",
    });

  const allAHUDesignDataNA = () =>
    values?.manufacturerNotListed &&
    values?.modelNumberNotListed &&
    values?.numberOfPumpsNotListed &&
    values?.numberOfLeadPumpsDesignNotListed &&
    values?.sytemTotalBTUHNotListed &&
    values?.systemSensibleBTUHNotListed &&
    values?.systemLatentBTUHNotListed &&
    values?.heatingSytemTotalBTUHNotListed &&
    values?.heatingSystemSensibleBTUHNotListed &&
    values?.heatingSystemLatentBTUHNotListed;

  const allAHUDesignDataNAHandleChange = (e: any) => {
    const value = e.target.value || false;
    setValues({
      ...values,
      manufacturerNotListed: value,
      modelNumberNotListed: value,
      numberOfPumpsNotListed: value,
      numberOfLeadPumpsDesignNotListed: value,
      sytemTotalBTUHNotListed: value,
      systemSensibleBTUHNotListed: value,
      systemLatentBTUHNotListed: value,
      heatingSytemTotalBTUHNotListed: value,
      heatingSystemSensibleBTUHNotListed: value,
      heatingSystemLatentBTUHNotListed: value,
      manufacturer: "",
      modelNumber: "",
      sytemTotalBTUH: 0,
      systemSensibleBTUH: 0,
      systemLatentBTUH: 0,
      heatingSytemTotalBTUH: 0,
      heatingSystemSensibleBTUH: 0,
      heatingSystemLatentBTUH: 0,
      numberOfPumps: 1,
      numberOfLeadPumpsDesign: 1,
    });
  };

  const allTempValuesNA = () =>
    values?.outsideDesignDBCoolingNotListed &&
    values?.outsideDesignCoolingNotListed &&
    values?.outsideDesignDBHeatingNotListed &&
    values?.outsideDesignHeatingNotListed &&
    values?.interiorDesignZoneConditionsDBCoolingNotListed &&
    values?.interiorDesignZoneConditionsCoolingNotListed &&
    values?.interiorDesignZoneConditionsDBHeatingNotListed &&
    values?.interiorDesignZoneConditionsHeatingNotListed;

  const allTempValuesNAHandleChange = (e: any) => {
    const value = e.target.value || false;
    setValues({
      ...values,
      outsideDesignDBCoolingNotListed: value,
      outsideDesignCoolingNotListed: value,
      outsideDesignDBHeatingNotListed: value,
      outsideDesignHeatingNotListed: value,
      interiorDesignZoneConditionsDBCoolingNotListed: value,
      interiorDesignZoneConditionsCoolingNotListed: value,
      interiorDesignZoneConditionsDBHeatingNotListed: value,
      interiorDesignZoneConditionsHeatingNotListed: value,
      outsideDesignDBCooling: 0,
      outsideDesignCooling: 0,
      outsideDesignDBCoolingSourceMeterType: "WB",
      outsideDesignDBHeating: 0,
      outsideDesignHeating: 0,
      outsideDesignDBHeatingSourceMeterType: "RH",
      interiorDesignZoneConditionsDBCooling: 0,
      interiorDesignZoneConditionsCooling: 0,
      interiorDesignZoneConditionsDBCoolingSourceMeterType: "WB",
      interiorDesignZoneConditionsDBHeating: 0,
      interiorDesignZoneConditionsHeating: 0,
      interiorDesignZoneConditionsDBHeatingSourceMeterType: "RH",
    });
  };

  const allAreaServedNA = () =>
    values?.floorAreaSqFtFedNotListed &&
    values?.floorAreaCuFtFedNotListed &&
    values?.acphConfiguredAsNotListed;

  const allAreaServedNAHandleChange = (e: any) => {
    const value = e.target.value || false;
    setValues({
      ...values,
      floorAreaSqFtFedNotListed: value,
      floorAreaCuFtFedNotListed: value,
      acphConfiguredAsNotListed: value,
      floorAreaSqFtFed: 0,
      floorAreaCuFtFed: 0,
      acphConfiguredAs: "",
    });
  };

  const allAirflowNA = () =>
    values?.systemCFMNotListed &&
    values?.outletCFMNotListed &&
    values?.returnAirflowNotListed &&
    values?.outsideAirflowNotListed &&
    values?.systemExhaustAirflowNotListed &&
    values?.numberOfFansXNotListed &&
    values?.numberOfFansYNotListed &&
    values?.supplyFanSystemIsArrayNotListed;

  const allAirflowNAHandleChange = (e: any) => {
    const value = e.target.value || false;
    setValues({
      ...values,
      systemCFMNotListed: value,
      outletCFMNotListed: value,
      returnAirflowNotListed: value,
      outsideAirflowNotListed: value,
      systemExhaustAirflowNotListed: value,
      numberOfFansXNotListed: value,
      numberOfFansYNotListed: value,
      supplyFanSystemIsArrayNotListed: value,
      systemCFM: 0,
      outletCFM: 0,
      returnAirflow: 0,
      outsideAirflow: 0,
      systemExhaustAirflow: 0,
      numberOfFansX: 0,
      numberOfFansY: 0,
    });
  };

  const allCoolingCoilDataNA = () =>
    values?.enteringAirTemperatureDBCoolingNotListed &&
    values?.enteringAirTemperatureWBCoolingNotListed &&
    values?.leavingAirTemperatureDBCoolingNotListed &&
    values?.leavingAirTemperatureWBCoolingNotListed &&
    values?.maAirPressureDeltaCoolingNotListed &&
    values?.designWaterDeltaPressureCoolingNotListed &&
    values?.coolingCoilGPMNotListed &&
    values?.enteringHydronicCoolingCoilTemperatureNotListed &&
    values?.leavingHydronicCoolingCoilTemperatureNotListed &&
    values?.hydronicFluidFlowDeltaTemperatureCoolingNotListed &&
    values?.sensibleBTUHCoolingNotListed &&
    values?.latentBTUHCoolingNotListed &&
    values?.totalBTUHCoolingNotListed &&
    values?.wpdFeetHeadCoolingNotListed &&
    values?.numberOfCircuitsInCoolingCoilNotListed &&
    values?.finsPerInchCoolingNotListed &&
    values?.coolingCoilRowsNotListed;

  const updateAllCoolingCoilDataNAHandle = (
    value: boolean,
    additionalValues?: IEnergyStudyAirHydronicsDTO
  ) => {
    setValues({
      ...values,
      ...additionalValues,
      enteringAirTemperatureDBCoolingNotListed: value,
      enteringAirTemperatureWBCoolingNotListed: value,
      leavingAirTemperatureDBCoolingNotListed: value,
      leavingAirTemperatureWBCoolingNotListed: value,
      maAirPressureDeltaCoolingNotListed: value,
      designWaterDeltaPressureCoolingNotListed: value,
      coolingCoilGPMNotListed: value,
      enteringHydronicCoolingCoilTemperatureNotListed: value,
      leavingHydronicCoolingCoilTemperatureNotListed: value,
      hydronicFluidFlowDeltaTemperatureCoolingNotListed: value,
      sensibleBTUHCoolingNotListed: value,
      latentBTUHCoolingNotListed: value,
      totalBTUHCoolingNotListed: value,
      wpdFeetHeadCoolingNotListed: value,
      numberOfCircuitsInCoolingCoilNotListed: value,
      finsPerInchCoolingNotListed: value,
      coolingCoilRowsNotListed: value,
      enteringAirTemperatureDBCooling: 0,
      enteringAirTemperatureWBCooling: 0,
      enteringAirTemperatureCoolingSourceMeterType: "WB",
      leavingAirTemperatureDBCooling: 0,
      leavingAirTemperatureWBCooling: 0,
      leavingAirTemperatureCoolingSourceMeterType: "WB",
      maAirPressureDeltaCooling: 0,
      designWaterDeltaPressureCooling: 0,
      coolingCoilGPM: 0,
      enteringHydronicCoolingCoilTemperature: 0,
      leavingHydronicCoolingCoilTemperature: 0,
      hydronicFluidFlowDeltaTemperatureCooling: 0,
      sensibleBTUHCooling: 0,
      latentBTUHCooling: 0,
      totalBTUHCooling: 0,
      wpdFeetHeadCooling: 0,
      numberOfCircuitsInCoolingCoil: 1,
      finsPerInchCooling: 0,
      coolingCoilRows: 0,
    });
  };

  const allCoolingCoilDataNAHandleChange = (e: any) => {
    const value = e.target.value || false;
    updateAllCoolingCoilDataNAHandle(value);
  };

  const allHeatingCoilDataNA = () =>
    values?.enteringAirTemperatureDBHeatingNotListed &&
    values?.enteringAirTemperatureWBHeatingNotListed &&
    values?.leavingAirTemperatureDBHeatingNotListed &&
    values?.leavingAirTemperatureWBHeatingNotListed &&
    values?.maAirPressureDeltaHeatingNotListed &&
    values?.designWaterDeltaPressureHeatingNotListed &&
    values?.heatingCoilGPMNotListed &&
    values?.enteringHydronicHeatingCoilTemperatureNotListed &&
    values?.leavingHydronicHeatingCoilTemperatureNotListed &&
    values?.hydronicFluidFlowDeltaTemperatureHeatingNotListed &&
    values?.sensibleBTUHHeatingNotListed &&
    values?.latentBTUHHeatingNotListed &&
    values?.totalBTUHHeatingNotListed &&
    values?.wpdFeetHeadHeatingNotListed &&
    values?.numberOfCircuitsInHeatingCoilNotListed &&
    values?.finsPerInchHeatingNotListed &&
    values?.heatingCoilRowsNotListed;

  const updateAllHeatingCoilDataNAHandle = (
    value: boolean,
    additionalValues?: IEnergyStudyAirHydronicsDTO
  ) => {
    setValues({
      ...values,
      ...additionalValues,
      enteringAirTemperatureDBHeatingNotListed: value,
      enteringAirTemperatureWBHeatingNotListed: value,
      leavingAirTemperatureDBHeatingNotListed: value,
      leavingAirTemperatureWBHeatingNotListed: value,
      maAirPressureDeltaHeatingNotListed: value,
      designWaterDeltaPressureHeatingNotListed: value,
      heatingCoilGPMNotListed: value,
      enteringHydronicHeatingCoilTemperatureNotListed: value,
      leavingHydronicHeatingCoilTemperatureNotListed: value,
      hydronicFluidFlowDeltaTemperatureHeatingNotListed: value,
      sensibleBTUHHeatingNotListed: value,
      latentBTUHHeatingNotListed: value,
      totalBTUHHeatingNotListed: value,
      wpdFeetHeadHeatingNotListed: value,
      numberOfCircuitsInHeatingCoilNotListed: value,
      finsPerInchHeatingNotListed: value,
      heatingCoilRowsNotListed: value,
      enteringAirTemperatureDBHeating: 0,
      enteringAirTemperatureWBHeating: 0,
      enteringAirTemperatureHeatingSourceMeterType: "RH",
      leavingAirTemperatureDBHeating: 0,
      leavingAirTemperatureWBHeating: 0,
      leavingAirTemperatureHeatingSourceMeterType: "RH",
      maAirPressureDeltaHeating: 0,
      designWaterDeltaPressureHeating: 0,
      heatingCoilGPM: 0,
      enteringHydronicHeatingCoilTemperature: 0,
      leavingHydronicHeatingCoilTemperature: 0,
      hydronicFluidFlowDeltaTemperatureHeating: 0,
      sensibleBTUHHeating: 0,
      latentBTUHHeating: 0,
      totalBTUHHeating: 0,
      wpdFeetHeadHeating: 0,
      numberOfCircuitsInHeatingCoil: 1,
      finsPerInchHeating: 0,
      heatingCoilRows: 0,
    });
  };

  const allHeatingCoilDataNAHandleChange = (e: any) => {
    const value = e.target.value || false;
    updateAllHeatingCoilDataNAHandle(value);
  };

  const btuhModeOptions = () => [
    { key: "BTUH", value: systemOfMeasurement?.get("btuhw") },
    { key: "MBH", value: systemOfMeasurement?.get("mbh") },
  ];

  const btuhModeValue = (key: string) => {
    const [option] = btuhModeOptions().filter((x) => x.key === key);
    return option?.value;
  };

  const btuhReferenceTooltip = () =>
    `This Total ${systemOfMeasurement?.get("mbh")}/${systemOfMeasurement?.get(
      "btuhw"
    )} will be used for System Total comparison`;

  const handlerReferenceTotalBTUHChange = (target: "AHU" | "Coil") =>
    setValues({ ...values, referenceTotalBTUH: target });

  //Cooling system BTUH
  const triggerSystemTotalBTUHUpdateHandle = (
    sytemTotalBTUH: number | null,
    sytemTotalBTUHExpressedIn: string | null
  ) => {
    if (!!values?.sytemTotalBTUH) {
      executeTimesUpdateSystemTotalBTUH(id, {
        ...values,
        sytemTotalBTUHExpressedIn,
        sytemTotalBTUH,
      });
    } else {
      executeUpdateSystemTotalBTUH(id, {
        ...values,
        sytemTotalBTUH,
        systemLatentBTUHExpressedIn: sytemTotalBTUHExpressedIn,
        systemSensibleBTUHExpressedIn: sytemTotalBTUHExpressedIn,
      });
    }
  };

  const handleSytemTotalBTUHBlur = (e: any) => {
    let value = e.target.value;
    if (typeof e.target.value === "string") {
      const valueSplitted = e.target.value.split(" ");
      value = parseFloat(valueSplitted[0].replace(/,/g, ""));
    }
    triggerSystemTotalBTUHUpdateHandle(
      value,
      values?.sytemTotalBTUHExpressedIn
    );
  };

  const handleSytemTotalBTUHExpressedInChange = (e: any) => {
    triggerSystemTotalBTUHUpdateHandle(values?.sytemTotalBTUH, e.target.value);
  };

  const handleSystemBTUHBlur = (e: any) => {
    let value = e.target.value;
    if (typeof e.target.value === "string") {
      const valueSplitted = e.target.value.split(" ");
      value = parseFloat(valueSplitted[0].replace(/,/g, ""));
    }
    executeTimesUpdateSystemTotalBTUH(id, {
      ...values,
      [e.target.name]: value,
    });
  };

  //Heating System BTUH
  const triggerHeatingSystemTotalBTUHUpdateHandle = (
    heatingSytemTotalBTUH: number | null,
    heatingSytemTotalBTUHExpressedIn: string | null
  ) => {
    if (!!values?.heatingSytemTotalBTUH) {
      executeTimesUpdateHeatingSystemTotalBTUH(id, {
        ...values,
        heatingSytemTotalBTUHExpressedIn,
        heatingSytemTotalBTUH,
      });
    } else {
      executeUpdateHeatingSystemTotalBTUH(id, {
        ...values,
        heatingSytemTotalBTUH,
        heatingSystemLatentBTUHExpressedIn: heatingSytemTotalBTUHExpressedIn,
        heatingSystemSensibleBTUHExpressedIn: heatingSytemTotalBTUHExpressedIn,
      });
    }
  };

  const handleHeatingSytemTotalBTUHBlur = (e: any) => {
    let value = e.target.value;
    if (typeof e.target.value === "string") {
      const valueSplitted = e.target.value.split(" ");
      value = parseFloat(valueSplitted[0].replace(/,/g, ""));
    }
    triggerHeatingSystemTotalBTUHUpdateHandle(
      value,
      values?.heatingSytemTotalBTUHExpressedIn
    );
  };

  const handleHeatingSytemTotalBTUHExpressedInChange = (e: any) => {
    triggerHeatingSystemTotalBTUHUpdateHandle(
      values?.heatingSytemTotalBTUH,
      e.target.value
    );
  };

  const handleHeatingSystemBTUHBlur = (e: any) => {
    let value = e.target.value;
    if (typeof e.target.value === "string") {
      const valueSplitted = e.target.value.split(" ");
      value = parseFloat(valueSplitted[0].replace(/,/g, ""));
    }
    executeTimesUpdateHeatingSystemTotalBTUH(id, {
      ...values,
      [e.target.name]: value,
    });
  };

  //Cooling Coil BTUH
  const triggerCoolingCoilTotalBTUHUpdateHandle = (
    totalBTUHCooling: number | null,
    totalBTUHCoolingExpressedIn: string | null
  ) => {
    if (!!values?.totalBTUHCooling) {
      executeTimesUpdateCoolingCoilTotalBTUH(id, {
        ...values,
        totalBTUHCoolingExpressedIn,
        totalBTUHCooling,
      });
    } else {
      executeUpdateCoolingCoilTotalBTUH(id, {
        ...values,
        totalBTUHCooling,
        systemLatentBTUHExpressedIn: totalBTUHCoolingExpressedIn,
        systemSensibleBTUHExpressedIn: totalBTUHCoolingExpressedIn,
      });
    }
  };

  const handleTotalBTUHCoolingBlur = (e: any) => {
    let value = e.target.value;
    if (typeof e.target.value === "string") {
      const valueSplitted = e.target.value.split(" ");
      value = parseFloat(valueSplitted[0].replace(/,/g, ""));
    }
    triggerCoolingCoilTotalBTUHUpdateHandle(
      value,
      values?.totalBTUHCoolingExpressedIn
    );
  };

  const handleTotalBTUHCoolingExpressedInChange = (e: any) => {
    triggerCoolingCoilTotalBTUHUpdateHandle(
      values?.totalBTUHCooling,
      e.target.value
    );
  };

  const handleCoolingCoilBTUHBlur = (e: any) => {
    let value = e.target.value;
    if (typeof e.target.value === "string") {
      const valueSplitted = e.target.value.split(" ");
      value = parseFloat(valueSplitted[0].replace(/,/g, ""));
    }
    executeTimesUpdateCoolingCoilTotalBTUH(id, {
      ...values,
      [e.target.name]: value,
    });
  };

  const handleCoolingCoilGPMBlur = (e: any) => {
    let value = e.target.value;
    if (typeof e.target.value === "string") {
      const valueSplitted = e.target.value.split(" ");
      value = parseFloat(valueSplitted[0].replace(/,/g, ""));
    }
    executeTimesUpdateCoolingCoilGPM(id, {
      ...values,
      [e.target.name]: value,
    });
  };

  const handleCoolingCoilEWTBlur = (e: any) => {
    let value = e.target.value;
    if (typeof e.target.value === "string") {
      const valueSplitted = e.target.value.split(" ");
      value = parseFloat(valueSplitted[0].replace(/,/g, ""));
    }
    executeTimesUpdateCoolingCoilEWT(id, {
      ...values,
      [e.target.name]: value,
    });
  };

  const handleCoolingCoilLWTBlur = (e: any) => {
    let value = e.target.value;
    if (typeof e.target.value === "string") {
      const valueSplitted = e.target.value.split(" ");
      value = parseFloat(valueSplitted[0].replace(/,/g, ""));
    }
    executeTimesUpdateCoolingCoilLWT(id, {
      ...values,
      [e.target.name]: value,
    });
  };

  const handleCoolingCoilAWTBlur = (e: any) => {
    let value = e.target.value;
    if (typeof e.target.value === "string") {
      const valueSplitted = e.target.value.split(" ");
      value = parseFloat(valueSplitted[0].replace(/,/g, ""));
    }
    executeTimesUpdateCoolingCoilAWT(id, {
      ...values,
      [e.target.name]: value,
    });
  };

  //Heating Coil BTUH
  const triggerHeatingCoilTotalBTUHUpdateHandle = (
    totalBTUHHeating: number | null,
    totalBTUHHeatingExpressedIn: string | null
  ) => {
    if (!!values?.totalBTUHHeating) {
      executeTimesUpdateHeatingCoilTotalBTUH(id, {
        ...values,
        totalBTUHHeatingExpressedIn,
        totalBTUHHeating,
      });
    } else {
      executeUpdateHeatingCoilTotalBTUH(id, {
        ...values,
        totalBTUHHeating,
        systemLatentBTUHExpressedIn: totalBTUHHeatingExpressedIn,
        systemSensibleBTUHExpressedIn: totalBTUHHeatingExpressedIn,
      });
    }
  };

  const handleTotalBTUHHeatingBlur = (e: any) => {
    let value = e.target.value;
    if (typeof e.target.value === "string") {
      const valueSplitted = e.target.value.split(" ");
      value = parseFloat(valueSplitted[0].replace(/,/g, ""));
    }
    triggerHeatingCoilTotalBTUHUpdateHandle(
      value,
      values?.totalBTUHHeatingExpressedIn
    );
  };

  const handleTotalBTUHHeatingExpressedInChange = (e: any) => {
    triggerHeatingCoilTotalBTUHUpdateHandle(
      values?.totalBTUHHeating,
      e.target.value
    );
  };

  const handleHeatingCoilBTUHBlur = (e: any) => {
    let value = e.target.value;
    if (typeof e.target.value === "string") {
      const valueSplitted = e.target.value.split(" ");
      value = parseFloat(valueSplitted[0].replace(/,/g, ""));
    }
    executeTimesUpdateHeatingCoilTotalBTUH(id, {
      ...values,
      [e.target.name]: value,
    });
  };

  const handleHeatingCoilGPMBlur = (e: any) => {
    let value = e.target.value;
    if (typeof e.target.value === "string") {
      const valueSplitted = e.target.value.split(" ");
      value = parseFloat(valueSplitted[0].replace(/,/g, ""));
    }
    executeTimesUpdateHeatingCoilGPM(id, {
      ...values,
      [e.target.name]: value,
    });
  };

  const handleHeatingCoilEWTBlur = (e: any) => {
    let value = e.target.value;
    if (typeof e.target.value === "string") {
      const valueSplitted = e.target.value.split(" ");
      value = parseFloat(valueSplitted[0].replace(/,/g, ""));
    }
    executeTimesUpdateHeatingCoilEWT(id, {
      ...values,
      [e.target.name]: value,
    });
  };

  const handleHeatingCoilLWTBlur = (e: any) => {
    let value = e.target.value;
    if (typeof e.target.value === "string") {
      const valueSplitted = e.target.value.split(" ");
      value = parseFloat(valueSplitted[0].replace(/,/g, ""));
    }
    executeTimesUpdateHeatingCoilLWT(id, {
      ...values,
      [e.target.name]: value,
    });
  };

  const handleHeatingCoilAWTBlur = (e: any) => {
    let value = e.target.value;
    if (typeof e.target.value === "string") {
      const valueSplitted = e.target.value.split(" ");
      value = parseFloat(valueSplitted[0].replace(/,/g, ""));
    }
    executeTimesUpdateHeatingCoilAWT(id, {
      ...values,
      [e.target.name]: value,
    });
  };

  const handleSourceMeterTypeChange = (e: any) => {
    const name = e.target.name.split("-")[0];
    const value = e.target.name.split("-")[1];
    setValues({
      ...values,
      [name]: value,
    });
  };

  const handleOutsideDBCoolingNotListedChange = (e: any) =>
    setValues({
      ...values,
      [e.target.name]: e.target.value,
      outsideDesignCoolingNotListed: e.target.value,
    });

  const handleEnteringAirTemperatureDBCoolingNotListedChange = (e: any) =>
    setValues({
      ...values,
      [e.target.name]: e.target.value,
      enteringAirTemperatureWBCoolingNotListed: e.target.value,
    });

  const handleLeavingAirTemperatureDBCoolingNotListedChange = (e: any) =>
    setValues({
      ...values,
      [e.target.name]: e.target.value,
      leavingAirTemperatureWBCoolingNotListed: e.target.value,
    });

  const handleEnteringAirTemperatureDBHeatingNotListedChange = (e: any) =>
    setValues({
      ...values,
      [e.target.name]: e.target.value,
      enteringAirTemperatureWBHeatingNotListed: e.target.value,
    });

  const handleLeavingAirTemperatureDBHeatingNotListedChange = (e: any) =>
    setValues({
      ...values,
      [e.target.name]: e.target.value,
      leavingAirTemperatureWBHeatingNotListed: e.target.value,
    });

  const handleOutsideDBHeatingNotListedChange = (e: any) =>
    setValues({
      ...values,
      [e.target.name]: e.target.value,
      outsideDesignHeatingNotListed: e.target.value,
    });

  const handleInteriorDBCoolingNotListedChange = (e: any) =>
    setValues({
      ...values,
      [e.target.name]: e.target.value,
      interiorDesignZoneConditionsCoolingNotListed: e.target.value,
    });

  const handleInteriorDBHeatingNotListedChange = (e: any) =>
    setValues({
      ...values,
      [e.target.name]: e.target.value,
      interiorDesignZoneConditionsHeatingNotListed: e.target.value,
    });

  const handleFloorAreaSqFtFedBlur = (e: any) => {
    let value = e.target.value;
    if (typeof e.target.value === "string") {
      const valueSplitted = e.target.value.split(" ");
      value = parseFloat(valueSplitted[0].replace(/,/g, ""));
    }
    const cuFt = formulas?.sqftToCuft(value);
    setValues({ ...values, floorAreaCuFtFed: cuFt || 0 });
  };

  const handlefloorAreaCuFtFedBlur = (e: any) => {
    let value = e.target.value;
    if (typeof e.target.value === "string") {
      const valueSplitted = e.target.value.split(" ");
      value = parseFloat(valueSplitted[0].replace(/,/g, ""));
    }
    const sqft = formulas?.cuftToSqft(value);
    setValues({ ...values, floorAreaSqFtFed: sqft || 0 });
  };

  //Change test mode logic
  const updateTestModeHandle = (testMode: string) => {
    setOpenTestModeAlert(false);
    let newValues = {
      ...values,
      testMode,
      referenceTotalBTUH: "AHU",
    } as IEnergyStudyAirHydronicsDTO;
    if (testMode === "Cooling") {
      //AHU Design Data
      newValues = {
        ...newValues,
        sytemTotalBTUHNotListed: false,
        systemSensibleBTUHNotListed: false,
        systemLatentBTUHNotListed: false,
        heatingSytemTotalBTUHNotListed: true,
        heatingSystemSensibleBTUHNotListed: true,
        heatingSystemLatentBTUHNotListed: true,
        heatingSytemTotalBTUH: 0,
        heatingSystemSensibleBTUH: 0,
        heatingSystemLatentBTUH: 0,
        //Outside - Interior Design Data
        outsideDesignDBCoolingNotListed: false,
        outsideDesignCoolingNotListed: false,
        outsideDesignDBCoolingSourceMeterTypeNotListed: false,
        outsideDesignDBHeatingNotListed: true,
        outsideDesignHeatingNotListed: true,
        outsideDesignDBHeatingSourceMeterTypeNotListed: true,
        interiorDesignZoneConditionsDBCoolingNotListed: false,
        interiorDesignZoneConditionsCoolingNotListed: false,
        interiorDesignZoneConditionsDBCoolingSourceMeterTypeNotListed: false,
        interiorDesignZoneConditionsDBHeatingNotListed: true,
        interiorDesignZoneConditionsHeatingNotListed: true,
        interiorDesignZoneConditionsDBHeatingSourceMeterTypeNotListed: true,
        outsideDesignDBHeating: 0,
        outsideDesignHeating: 0,
        outsideDesignDBHeatingSourceMeterType: "RH",
        interiorDesignZoneConditionsDBHeating: 0,
        interiorDesignZoneConditionsHeating: 0,
        interiorDesignZoneConditionsDBHeatingSourceMeterType: "RH",
        outsideDesignDBCoolingSourceMeterType: "WB",
        interiorDesignZoneConditionsDBCoolingSourceMeterType: "WB",
        //Cooling Coil Data
        enteringAirTemperatureDBCoolingNotListed: false,
        enteringAirTemperatureWBCoolingNotListed: false,
        leavingAirTemperatureDBCoolingNotListed: false,
        leavingAirTemperatureWBCoolingNotListed: false,
        maAirPressureDeltaCoolingNotListed: false,
        designWaterDeltaPressureCoolingNotListed: false,
        coolingCoilGPMNotListed: false,
        enteringHydronicCoolingCoilTemperatureNotListed: false,
        leavingHydronicCoolingCoilTemperatureNotListed: false,
        hydronicFluidFlowDeltaTemperatureCoolingNotListed: false,
        sensibleBTUHCoolingNotListed: false,
        latentBTUHCoolingNotListed: false,
        totalBTUHCoolingNotListed: false,
        wpdFeetHeadCoolingNotListed: false,
        numberOfCircuitsInCoolingCoilNotListed: false,
        finsPerInchCoolingNotListed: false,
        coolingCoilRowsNotListed: false,
      };

      //Heating Coil Data
      updateAllHeatingCoilDataNAHandle(true, newValues);
    } else {
      newValues = {
        ...newValues,
        //AHU Design Data
        sytemTotalBTUHNotListed: true,
        systemSensibleBTUHNotListed: true,
        systemLatentBTUHNotListed: true,
        heatingSytemTotalBTUHNotListed: false,
        heatingSystemSensibleBTUHNotListed: false,
        heatingSystemLatentBTUHNotListed: false,
        sytemTotalBTUH: 0,
        systemSensibleBTUH: 0,
        systemLatentBTUH: 0,
        //Outside - Interior Design Data
        outsideDesignDBCoolingNotListed: true,
        outsideDesignCoolingNotListed: true,
        outsideDesignDBCoolingSourceMeterTypeNotListed: true,
        outsideDesignDBHeatingNotListed: false,
        outsideDesignHeatingNotListed: false,
        outsideDesignDBHeatingSourceMeterTypeNotListed: false,
        interiorDesignZoneConditionsDBCoolingNotListed: true,
        interiorDesignZoneConditionsCoolingNotListed: true,
        interiorDesignZoneConditionsDBCoolingSourceMeterTypeNotListed: true,
        interiorDesignZoneConditionsDBHeatingNotListed: false,
        interiorDesignZoneConditionsHeatingNotListed: false,
        interiorDesignZoneConditionsDBHeatingSourceMeterTypeNotListed: false,
        outsideDesignDBCooling: 0,
        outsideDesignCooling: 0,
        outsideDesignDBCoolingSourceMeterType: "WB",
        interiorDesignZoneConditionsDBCooling: 0,
        interiorDesignZoneConditionsCooling: 0,
        interiorDesignZoneConditionsDBCoolingSourceMeterType: "WB",
        outsideDesignDBHeatingSourceMeterType: "RH",
        interiorDesignZoneConditionsDBHeatingSourceMeterType: "RH",
        //Heating Coil Data
        enteringAirTemperatureDBHeatingNotListed: false,
        enteringAirTemperatureWBHeatingNotListed: false,
        leavingAirTemperatureDBHeatingNotListed: false,
        leavingAirTemperatureWBHeatingNotListed: false,
        maAirPressureDeltaHeatingNotListed: false,
        designWaterDeltaPressureHeatingNotListed: false,
        heatingCoilGPMNotListed: false,
        enteringHydronicHeatingCoilTemperatureNotListed: false,
        leavingHydronicHeatingCoilTemperatureNotListed: false,
        hydronicFluidFlowDeltaTemperatureHeatingNotListed: false,
        sensibleBTUHHeatingNotListed: false,
        latentBTUHHeatingNotListed: false,
        totalBTUHHeatingNotListed: false,
        wpdFeetHeadHeatingNotListed: false,
        numberOfCircuitsInHeatingCoilNotListed: false,
        finsPerInchHeatingNotListed: false,
        heatingCoilRowsNotListed: false,
        heatingElectricResistanceManufacturerNotListed: false,
        heatingElectricResistanceModelNotListed: false,
        heatingElectricResistanceHzNotListed: false,
        heatingElectricResistanceRatedKwsNotListed: false,
        heatingElectricResistanceRatedVoltageNotListed: false,
        heatingElectricResistanceStepsNotListed: false,
        heatingElectricResistanceStagesNotListed: false,
        heatingElectricResistanceHz: 60,
      };

      //Cooling Coil Data
      updateAllCoolingCoilDataNAHandle(true, newValues);
    }
  };

  const handleTestModeChange = (
    event: ChangeEvent<HTMLInputElement>,
    value: string
  ) => {
    if (!!values?.testMode) {
      setChangeTestModeValue(value);
      setOpenTestModeAlert(true);
    } else updateTestModeHandle(value);
  };

  const handleTestModeAlertNo = () => setOpenTestModeAlert(false);

  const handleTestModeAlertYes = () =>
    updateTestModeHandle(changeTestModeValue || "Cooling");

  const handleReturnAirflowBlur = (e: any) => {
    let value = e.target.value;
    if (typeof e.target.value === "string") {
      const valueSplitted = e.target.value.split(" ");
      value = parseFloat(valueSplitted[0].replace(/,/g, ""));
    }

    let outsideAirflow = 0;
    if (values?.systemCFM) {
      outsideAirflow = !!values?.outsideAirflow
        ? values?.outsideAirflow
        : values?.systemCFM - value;
    }
    setValues({
      ...values,
      [e.target.name]: value,
      outsideAirflow,
      cfmDiscrepancyAgreementSignature: "",
      cfmDiscrepancyAuthority: "",
      cfmDiscrepancyDateDiscussed: "",
    });
  };

  const handleOutsideAirflowBlur = (e: any) => {
    let value = e.target.value;
    if (typeof e.target.value === "string") {
      const valueSplitted = e.target.value.split(" ");
      value = parseFloat(valueSplitted[0].replace(/,/g, ""));
    }

    let returnAirflow = 0;
    if (values?.systemCFM) {
      returnAirflow = !!values?.returnAirflow
        ? values?.returnAirflow
        : values?.systemCFM - value;
    }
    setValues({
      ...values,
      [e.target.name]: value,
      returnAirflow,
      cfmDiscrepancyAgreementSignature: "",
      cfmDiscrepancyAuthority: "",
      cfmDiscrepancyDateDiscussed: "",
    });
  };

  const cfmDiscrepancyAlert = () => {
    if (
      !values?.isComplete &&
      !!values?.systemCFM &&
      !values?.systemCFMNotListed &&
      !values?.returnAirflowNotListed &&
      !values?.outsideAirflowNotListed
    ) {
      const combinedCFM =
        (values?.outsideAirflow || 0) + (values?.returnAirflow || 0);
      return combinedCFM > values?.systemCFM && values?.systemCFM > 0;
    }
  };

  const handleCfmDiscrepancyAgreed = (e: any) => {
    const now = new Date(Date.now());
    setValues({
      ...values,
      cfmDiscrepancyAgreementSignature: `${
        user?.userName
      } - ${now.toLocaleString()}`,
    });
  };

  const handleCfmDiscrepancyDateDiscussedTimeChange = (e: any) => {
    setValues({
      ...values,
      [e.target
        .name]: `${values?.cfmDiscrepancyDateDiscussed} ${e.target.value}`,
    });
  };

  const row = 12;

  const dateToLocalTimeString = (date: string) => {
    const dateOb = new Date(date);
    return dateOb.toLocaleTimeString();
  };

  const handleIncompleteAlertYes = () => setOpenIncompleteAlert(false);

  const handleNumberOfFansXNotListedChange = (e: any) =>
    setValues({
      ...values,
      [e.target.name]: e.target.value,
      numberOfFansYNotListed: e.target.value,
      supplyFanSystemIsArrayNotListed: e.target.value,
    });

  return (
    <>
      <Popup
        title="Warning"
        openPopup={openTestModeAlert}
        setOpenPopup={setOpenTestModeAlert}
        onClose={handleTestModeAlertNo}
      >
        <>
          <Alert severity="warning">
            Changing the Mode of Test will reconfigure the entire page and some
            data may be lost.
            <br />
            Are you certain you want to continue changing the Mode of Test?
          </Alert>
        </>
        <Stack direction="row" spacing={3}>
          <Button
            variant="contained"
            color="error"
            onClick={handleTestModeAlertNo}
            size="small"
          >
            No
          </Button>
          <Button
            variant="contained"
            color="success"
            size="small"
            onClick={handleTestModeAlertYes}
            autoFocus
          >
            Yes
          </Button>
        </Stack>
      </Popup>
      <Popup
        title="Warning"
        openPopup={openIncompleteAlert}
        setOpenPopup={setOpenIncompleteAlert}
        onClose={handleIncompleteAlertYes}
      >
        <>
          <Alert severity="warning">
            There are invalid or empty fields on this form.
            <br />
            It is highly recommended that you fill out as much data as possible
            at this step. The design data is used as a reference to evaluate
            system results.
            <br />
            Please review and fix any invalid or empty fields. If you understand
            the risk of leaving the design data empty, you can check each
            required field as NOT LISTED and then click the "Next Step" button.
          </Alert>
        </>
        <Stack direction="row" spacing={3}>
          <Button
            variant="contained"
            color="success"
            size="small"
            onClick={handleIncompleteAlertYes}
            autoFocus
          >
            Ok
          </Button>
        </Stack>
      </Popup>
      <HeaderStudyPage
        headerStudiesPage={{ code: code, system: values?.systemField, id: id }}
        parentText="Project"
        parentLink={`/app/ProjectStudies/${values?.projectId}/${user?.companyId}`}
      />
      <Divider my={6} />

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card mb={6}>
            <CardContent>
              <StudyWizard
                steps={steps}
                activeStep={activeStep}
                projectId={dataReport?.projectId}
                reportId={dataReport?.reportId}
                reportTypeId={dataReport?.reportTypeId}
              />
              <h3>Configuration</h3>
              <Grid container spacing={3}>
                <Grid item xs={row} md={3}>
                  <FormText
                    name="projectName"
                    label="Project Name"
                    value={`${values?.projectName}`}
                    onChange={handleInputChange}
                    error={errors.projectName}
                    showSkeleton={isLoading || isLoadingReportType}
                    readOnlyText={disableStudy()}
                    disabled
                  />
                </Grid>
                <Grid item xs={row} md={3}>
                  <FormText
                    name="systemField"
                    label="System"
                    value={`${values?.systemField ?? ""}`}
                    onChange={handleInputChange}
                    error={errors.systemField}
                    showSkeleton={isLoading || isLoadingReportType}
                    disabled={disableStudy()}
                    maxLength={50}
                  />
                </Grid>
                <Grid item xs={row} md={3}>
                  <FormText
                    name="location"
                    label="Equipment Location"
                    value={`${values?.location ?? ""}`}
                    onChange={handleInputChange}
                    error={errors.location}
                    showSkeleton={isLoading || isLoadingReportType}
                    disabled={disableStudy()}
                  />
                </Grid>
                <Grid item xs={row} md={3}>
                  <FormText
                    name="areaServed"
                    label="Area Served"
                    value={`${values?.areaServed ?? ""}`}
                    onChange={handleInputChange}
                    error={errors.areaServed}
                    showSkeleton={isLoading || isLoadingReportType}
                    disabled={disableStudy()}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormRadioGroup
                    name="testMode"
                    label="Mode of test"
                    items={testModeOptions}
                    value={values?.testMode}
                    onChange={handleTestModeChange}
                    row
                    showSkeleton={isLoading || isLoadingReportType}
                    disabled={disableStudy()}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormLabel
                    text="Type of system"
                    sx={{ color: "rgba(0, 0, 0, 0.6)" }}
                  />
                  <FormCheckBox
                    name="systemIsMA"
                    label="System is OA/RA Mixed Air"
                    onChange={handleSystemTypeChange}
                    value={values?.systemIsMA}
                    size="medium"
                    showSkeleton={isLoading || isLoadingReportType}
                    disabled={disableStudy() || !values?.testMode}
                  />
                  <FormCheckBox
                    name="systemIs100OA"
                    label="System is 100% OA/DOAS"
                    onChange={handleSystemTypeChange}
                    value={values?.systemIs100OA}
                    size="medium"
                    showSkeleton={isLoading || isLoadingReportType}
                    disabled={disableStudy() || !values?.testMode}
                  />
                  <FormCheckBox
                    name="systemIs100RA"
                    label="System is 100% RA"
                    onChange={handleSystemTypeChange}
                    value={values?.systemIs100RA}
                    size="medium"
                    showSkeleton={isLoading || isLoadingReportType}
                    disabled={disableStudy() || !values?.testMode}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormRadioGroup
                    name="target"
                    label="Target"
                    items={targetOptions}
                    value={values?.target}
                    onChange={handleInputChange}
                    row
                    showSkeleton={isLoading || isLoadingReportType}
                    disabled={disableStudy() || !values?.testMode}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormRadioGroup
                    name="fanPosition"
                    label="System's fan location"
                    items={fanPositionOptions}
                    value={values?.fanPosition}
                    onChange={handleInputChange}
                    row
                    showSkeleton={isLoading || isLoadingReportType}
                    disabled={disableStudy() || !values?.testMode}
                  />
                </Grid>
              </Grid>
              <Divider marginTop={2} />
              {!!values?.testMode && !!values?.target && !!values?.fanPosition && (
                <>
                  <Grid
                    container
                    spacing={0}
                    alignItems="flex-start"
                    alignContent="flex-start"
                  >
                    <Grid item xs={12} md={6} marginTop={2} paddingRight={2}>
                      <Grid container spacing={3}>
                        <Grid item xs={12}>
                          <h3>AHU Design Data</h3>
                          <FormCheckBox
                            label={`All not listed`}
                            name="allCFMNotAvailbale"
                            value={allAHUDesignDataNA()}
                            onChange={allAHUDesignDataNAHandleChange}
                            showSkeleton={isLoading}
                            disabled={disableStudy()}
                          />
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <FormCheckBox
                            label="Not Listed"
                            name="manufacturerNotListed"
                            value={values?.manufacturerNotListed}
                            onChange={handleInputChange}
                            showSkeleton={isLoading}
                            disabled={disableStudy()}
                          />
                        </Grid>
                        <Grid item xs={12} md={9}>
                          <FormText
                            disabled={
                              values?.manufacturerNotListed || disableStudy()
                            }
                            label="Manufacturer"
                            name="manufacturer"
                            onChange={handleInputChange}
                            value={values?.manufacturer}
                            showSkeleton={isLoading}
                            error={errors.manufacturer}
                          />
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <FormCheckBox
                            label="Not Listed"
                            name="modelNumberNotListed"
                            value={values?.modelNumberNotListed}
                            onChange={handleInputChange}
                            showSkeleton={isLoading}
                            disabled={disableStudy()}
                          />
                        </Grid>
                        <Grid item xs={12} md={9}>
                          <FormText
                            disabled={
                              values?.modelNumberNotListed || disableStudy()
                            }
                            label="Model Number"
                            name="modelNumber"
                            onChange={handleInputChange}
                            value={values?.modelNumber}
                            showSkeleton={isLoading}
                            error={errors.modelNumber}
                          />
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <FormCheckBox
                            label="Not Listed"
                            name="numberOfPumpsNotListed"
                            value={values?.numberOfPumpsNotListed}
                            onChange={handleInputChange}
                            showSkeleton={isLoading}
                            disabled={disableStudy()}
                          />
                        </Grid>
                        <Grid item xs={12} md={9}>
                          <FormSelect
                            label="Number of pumps serving this Unit Only"
                            name="numberOfPumps"
                            value={values?.numberOfPumps}
                            onChange={handleInputChange}
                            options={numberOfPumpsOptions}
                            showSkeleton={isLoading}
                            disabled={
                              values?.numberOfPumpsNotListed || disableStudy()
                            }
                          />
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <FormCheckBox
                            label="Not Listed"
                            name="numberOfLeadPumpsDesignNotListed"
                            value={values?.numberOfLeadPumpsDesignNotListed}
                            onChange={handleInputChange}
                            showSkeleton={isLoading}
                            disabled={disableStudy()}
                          />
                        </Grid>
                        <Grid item xs={12} md={9}>
                          <FormSelect
                            label="Current operating lead Pumps"
                            name="numberOfLeadPumpsDesign"
                            value={values?.numberOfLeadPumpsDesign}
                            onChange={handleInputChange}
                            options={numberOfPumpsOptions.filter(
                              (x) => x.key <= values?.numberOfPumps
                            )}
                            showSkeleton={isLoading}
                            disabled={
                              values?.numberOfLeadPumpsDesignNotListed ||
                              disableStudy()
                            }
                          />
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <FormCheckBox
                            label="Not Listed"
                            name="sytemTotalBTUHNotListed"
                            value={values?.sytemTotalBTUHNotListed}
                            onChange={handleInputChange}
                            showSkeleton={isLoading}
                            disabled={disableStudy()}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FormNumeric2
                            disabled={
                              values?.sytemTotalBTUHNotListed || disableStudy()
                            }
                            placeholder="0.00"
                            suffix={` ${btuhModeValue(
                              values?.sytemTotalBTUHExpressedIn || "BTUH"
                            )}`}
                            decimalScale={2}
                            thousandSeparator
                            fixedDecimalScale
                            label={`Cooling System TOTAL ${systemOfMeasurement.get(
                              "btuhw"
                            )} via Airflow`}
                            name="sytemTotalBTUH"
                            onChange={handleInputChange}
                            onBlur={handleSytemTotalBTUHBlur}
                            value={values?.sytemTotalBTUH}
                            showSkeleton={isLoading}
                            error={errors.sytemTotalBTUH}
                            inputProps={{ sx: { color: "blue" } }}
                          />
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <FormSelect
                            label="Unit"
                            name="sytemTotalBTUHExpressedIn"
                            value={values?.sytemTotalBTUHExpressedIn}
                            onChange={handleSytemTotalBTUHExpressedInChange}
                            options={btuhModeOptions()}
                            showSkeleton={isLoading}
                            disabled={
                              values?.sytemTotalBTUHNotListed || disableStudy()
                            }
                          />
                        </Grid>
                        {values?.testMode === "Cooling" && (
                          <>
                            <Grid item xs={12} md={11}>
                              <FormCheckBox
                                formControlProps={{ sx: { marginLeft: 42 } }}
                                label={`Use this data as the system's total ${systemOfMeasurement.get(
                                  "btuhw"
                                )} design. Or use the Coil ${systemOfMeasurement.get(
                                  "btuhw"
                                )} design.`}
                                name="referenceTotalBTUH"
                                value={values?.referenceTotalBTUH === "AHU"}
                                onChange={() =>
                                  handlerReferenceTotalBTUHChange("AHU")
                                }
                                showSkeleton={isLoading}
                                disabled={
                                  values?.sytemTotalBTUHNotListed ||
                                  disableStudy()
                                }
                              />
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              md={1}
                              sx={{ textAlign: "center" }}
                            >
                              <FormTooltip text={btuhReferenceTooltip()} />
                            </Grid>
                          </>
                        )}
                        <Grid item xs={12} md={3}>
                          <FormCheckBox
                            label="Not Listed"
                            name="systemSensibleBTUHNotListed"
                            value={values?.systemSensibleBTUHNotListed}
                            onChange={handleInputChange}
                            showSkeleton={isLoading}
                            disabled={disableStudy()}
                          />
                        </Grid>
                        <Grid item xs={12} md={9}>
                          <FormNumeric2
                            disabled={
                              values?.systemSensibleBTUHNotListed ||
                              disableStudy()
                            }
                            placeholder="0.00"
                            suffix={` ${btuhModeValue(
                              values?.systemSensibleBTUHExpressedIn || "BTUH"
                            )}`}
                            decimalScale={2}
                            thousandSeparator
                            fixedDecimalScale
                            label={`Cooling System SENSIBLE ${systemOfMeasurement.get(
                              "btuhw"
                            )} via Airflow`}
                            name="systemSensibleBTUH"
                            onChange={handleInputChange}
                            onBlur={handleSystemBTUHBlur}
                            value={values?.systemSensibleBTUH}
                            showSkeleton={isLoading}
                            error={errors.systemSensibleBTUH}
                            inputProps={{ sx: { color: "blue" } }}
                          />
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <FormCheckBox
                            label="Not Listed"
                            name="systemLatentBTUHNotListed"
                            value={values?.systemLatentBTUHNotListed}
                            onChange={handleInputChange}
                            showSkeleton={isLoading}
                            disabled={disableStudy()}
                          />
                        </Grid>
                        <Grid item xs={12} md={9}>
                          <FormNumeric2
                            disabled={
                              values?.systemLatentBTUHNotListed ||
                              disableStudy()
                            }
                            placeholder="0.00"
                            suffix={` ${btuhModeValue(
                              values?.systemLatentBTUHExpressedIn || "BTUH"
                            )}`}
                            decimalScale={2}
                            thousandSeparator
                            fixedDecimalScale
                            label={`Cooling System LATENT ${systemOfMeasurement.get(
                              "btuhw"
                            )} via Airflow`}
                            name="systemLatentBTUH"
                            onChange={handleInputChange}
                            onBlur={handleSystemBTUHBlur}
                            value={values?.systemLatentBTUH}
                            showSkeleton={isLoading}
                            error={errors.systemLatentBTUH}
                            inputProps={{ sx: { color: "blue" } }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <FormLabel
                            text={`Cooling System Sensible Heat Ratio (SHR) via Airflow: ${(
                              values?.shrAHUCooling || 0
                            ).toFixed(2)}`}
                            sx={{ color: "blue", marginLeft: 42 }}
                          />
                        </Grid>
                        {/** Design AHU Info Heating */}
                        <Grid item xs={12} md={3}>
                          <FormCheckBox
                            label="Not Listed"
                            name="heatingSytemTotalBTUHNotListed"
                            value={values?.heatingSytemTotalBTUHNotListed}
                            onChange={handleInputChange}
                            showSkeleton={isLoading}
                            disabled={disableStudy()}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FormNumeric2
                            disabled={
                              values?.heatingSytemTotalBTUHNotListed ||
                              disableStudy()
                            }
                            placeholder="0.00"
                            suffix={` ${btuhModeValue(
                              values?.heatingSytemTotalBTUHExpressedIn || "BTUH"
                            )}`}
                            decimalScale={2}
                            thousandSeparator
                            fixedDecimalScale
                            label={`Heating System TOTAL ${systemOfMeasurement.get(
                              "btuhw"
                            )} via Airflow`}
                            name="heatingSytemTotalBTUH"
                            onChange={handleInputChange}
                            onBlur={handleHeatingSytemTotalBTUHBlur}
                            value={values?.heatingSytemTotalBTUH}
                            showSkeleton={isLoading}
                            error={errors.heatingSytemTotalBTUH}
                            inputProps={{ sx: { color: "darkred" } }}
                          />
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <FormSelect
                            label="Unit"
                            name="heatingSytemTotalBTUHExpressedIn"
                            value={values?.heatingSytemTotalBTUHExpressedIn}
                            onChange={
                              handleHeatingSytemTotalBTUHExpressedInChange
                            }
                            options={btuhModeOptions()}
                            showSkeleton={isLoading}
                            disabled={
                              values?.heatingSytemTotalBTUHNotListed ||
                              disableStudy()
                            }
                          />
                        </Grid>
                        {values?.testMode === "Heating" && (
                          <>
                            <Grid item xs={12} md={11}>
                              <FormCheckBox
                                formControlProps={{ sx: { marginLeft: 42 } }}
                                label={`Use this data as the system's total ${systemOfMeasurement.get(
                                  "btuhw"
                                )} design. Or use the Coil ${systemOfMeasurement.get(
                                  "btuhw"
                                )} design.`}
                                name="referenceTotalBTUH"
                                value={values?.referenceTotalBTUH === "AHU"}
                                onChange={() =>
                                  handlerReferenceTotalBTUHChange("AHU")
                                }
                                showSkeleton={isLoading}
                                disabled={
                                  values?.sytemTotalBTUHNotListed ||
                                  disableStudy()
                                }
                              />
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              md={1}
                              sx={{ textAlign: "center" }}
                            >
                              <FormTooltip text={btuhReferenceTooltip()} />
                            </Grid>
                          </>
                        )}
                        <Grid item xs={12} md={3}>
                          <FormCheckBox
                            label="Not Listed"
                            name="heatingSystemSensibleBTUHNotListed"
                            value={values?.heatingSystemSensibleBTUHNotListed}
                            onChange={handleInputChange}
                            showSkeleton={isLoading}
                            disabled={disableStudy()}
                          />
                        </Grid>
                        <Grid item xs={12} md={9}>
                          <FormNumeric2
                            disabled={
                              values?.heatingSystemSensibleBTUHNotListed ||
                              disableStudy()
                            }
                            placeholder="0.00"
                            suffix={` ${btuhModeValue(
                              values?.heatingSystemSensibleBTUHExpressedIn ||
                                "BTUH"
                            )}`}
                            decimalScale={2}
                            thousandSeparator
                            fixedDecimalScale
                            label={`Heating System SENSIBLE ${systemOfMeasurement.get(
                              "btuhw"
                            )} via Airflow`}
                            name="heatingSystemSensibleBTUH"
                            onChange={handleInputChange}
                            onBlur={handleHeatingSystemBTUHBlur}
                            value={values?.heatingSystemSensibleBTUH}
                            showSkeleton={isLoading}
                            error={errors.heatingSystemSensibleBTUH}
                            inputProps={{ sx: { color: "darkred" } }}
                          />
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <FormCheckBox
                            label="Not Listed"
                            name="heatingSystemLatentBTUHNotListed"
                            value={values?.heatingSystemLatentBTUHNotListed}
                            onChange={handleInputChange}
                            showSkeleton={isLoading}
                            disabled={disableStudy()}
                          />
                        </Grid>
                        <Grid item xs={12} md={9}>
                          <FormNumeric2
                            disabled={
                              values?.heatingSystemLatentBTUHNotListed ||
                              disableStudy()
                            }
                            placeholder="0.00"
                            suffix={` ${btuhModeValue(
                              values?.heatingSystemLatentBTUHExpressedIn ||
                                "BTUH"
                            )}`}
                            decimalScale={2}
                            thousandSeparator
                            fixedDecimalScale
                            label={`Heating System LATENT ${systemOfMeasurement.get(
                              "btuhw"
                            )} via Airflow`}
                            name="heatingSystemLatentBTUH"
                            onChange={handleInputChange}
                            onBlur={handleHeatingSystemBTUHBlur}
                            value={values?.heatingSystemLatentBTUH}
                            showSkeleton={isLoading}
                            error={errors.heatingSystemLatentBTUH}
                            inputProps={{ sx: { color: "darkred" } }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <FormLabel
                            text={`Heating System Sensible Heat Ratio (SHR) via Airflow: ${(
                              values?.shrAHUHeating || 0
                            ).toFixed(2)}`}
                            sx={{ color: "darkred", marginLeft: 42 }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={6}
                      borderLeft={1}
                      borderColor="lightgray"
                      marginTop={2}
                      paddingLeft={2}
                    >
                      <Grid container spacing={3}>
                        <Grid item xs={12}>
                          <h3>Outside / Interior Design Temperature Values</h3>
                          <FormCheckBox
                            label={`All not listed`}
                            name="allCFMNotAvailbale"
                            value={allTempValuesNA()}
                            onChange={allTempValuesNAHandleChange}
                            showSkeleton={isLoading}
                            disabled={disableStudy()}
                          />
                        </Grid>
                        {/**Outside Cooling */}
                        <Grid item xs={12} md={3}>
                          <FormCheckBox
                            label="Not Listed"
                            name="outsideDesignDBCoolingNotListed"
                            value={values?.outsideDesignDBCoolingNotListed}
                            onChange={handleOutsideDBCoolingNotListedChange}
                            showSkeleton={isLoading}
                            disabled={disableStudy()}
                          />
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <FormNumeric2
                            disabled={
                              values?.outsideDesignDBCoolingNotListed ||
                              disableStudy()
                            }
                            placeholder="0.00"
                            decimalScale={2}
                            thousandSeparator
                            fixedDecimalScale
                            label={`Cooling Outside DB ${systemOfMeasurement.get(
                              "temperature"
                            )}`}
                            name="outsideDesignDBCooling"
                            onChange={handleInputChange}
                            value={values?.outsideDesignDBCooling}
                            showSkeleton={isLoading}
                            error={errors.outsideDesignDBCooling}
                            inputProps={{ sx: { color: "blue" } }}
                          />
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <FormNumeric2
                            disabled={
                              values?.outsideDesignCoolingNotListed ||
                              disableStudy()
                            }
                            placeholder="0.00"
                            decimalScale={2}
                            thousandSeparator
                            fixedDecimalScale
                            label={`Cooling Outside ${values?.outsideDesignDBCoolingSourceMeterType}`}
                            suffix={
                              values?.outsideDesignDBCoolingSourceMeterType ===
                              "RH"
                                ? "%"
                                : systemOfMeasurement.get("temperature")
                            }
                            name="outsideDesignCooling"
                            onChange={handleInputChange}
                            value={values?.outsideDesignCooling}
                            showSkeleton={isLoading}
                            error={errors.outsideDesignCooling}
                            inputProps={{ sx: { color: "blue" } }}
                          />
                        </Grid>
                        <Grid item xs={12} md={1}>
                          <FormCheckBox
                            label="RH"
                            name="outsideDesignDBCoolingSourceMeterType-RH"
                            value={
                              values?.outsideDesignDBCoolingSourceMeterType ===
                              "RH"
                            }
                            onChange={handleSourceMeterTypeChange}
                            showSkeleton={isLoading}
                            disabled={
                              values?.outsideDesignCoolingNotListed ||
                              disableStudy()
                            }
                          />
                        </Grid>
                        <Grid item xs={12} md={1}>
                          <FormCheckBox
                            label="WB"
                            name="outsideDesignDBCoolingSourceMeterType-WB"
                            value={
                              values?.outsideDesignDBCoolingSourceMeterType ===
                              "WB"
                            }
                            onChange={handleSourceMeterTypeChange}
                            showSkeleton={isLoading}
                            disabled={
                              values?.outsideDesignCoolingNotListed ||
                              disableStudy()
                            }
                          />
                        </Grid>
                        <Grid item xs={12} md={1}>
                          <FormCheckBox
                            label="DP"
                            name="outsideDesignDBCoolingSourceMeterType-DP"
                            value={
                              values?.outsideDesignDBCoolingSourceMeterType ===
                              "DP"
                            }
                            onChange={handleSourceMeterTypeChange}
                            showSkeleton={isLoading}
                            disabled={
                              values?.outsideDesignCoolingNotListed ||
                              disableStudy()
                            }
                          />
                        </Grid>
                        {/**Outside Heating */}
                        <Grid item xs={12} md={3}>
                          <FormCheckBox
                            label="Not Listed"
                            name="outsideDesignDBHeatingNotListed"
                            value={values?.outsideDesignDBHeatingNotListed}
                            onChange={handleOutsideDBHeatingNotListedChange}
                            showSkeleton={isLoading}
                            disabled={disableStudy()}
                          />
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <FormNumeric2
                            disabled={
                              values?.outsideDesignDBHeatingNotListed ||
                              disableStudy()
                            }
                            placeholder="0.00"
                            decimalScale={2}
                            thousandSeparator
                            fixedDecimalScale
                            label={`Heating Outside DB ${systemOfMeasurement.get(
                              "temperature"
                            )}`}
                            name="outsideDesignDBHeating"
                            onChange={handleInputChange}
                            value={values?.outsideDesignDBHeating}
                            showSkeleton={isLoading}
                            error={errors.outsideDesignDBHeating}
                            inputProps={{ sx: { color: "darkred" } }}
                          />
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <FormNumeric2
                            disabled={
                              values?.outsideDesignHeatingNotListed ||
                              disableStudy()
                            }
                            placeholder="0.00"
                            decimalScale={2}
                            thousandSeparator
                            fixedDecimalScale
                            label={`Heating Outside ${values?.outsideDesignDBHeatingSourceMeterType}`}
                            suffix={
                              values?.outsideDesignDBHeatingSourceMeterType ===
                              "RH"
                                ? "%"
                                : systemOfMeasurement.get("temperature")
                            }
                            name="outsideDesignHeating"
                            onChange={handleInputChange}
                            value={values?.outsideDesignHeating}
                            showSkeleton={isLoading}
                            error={errors.outsideDesignHeating}
                            inputProps={{ sx: { color: "darkred" } }}
                          />
                        </Grid>
                        <Grid item xs={12} md={1}>
                          <FormCheckBox
                            label="RH"
                            name="outsideDesignDBHeatingSourceMeterType-RH"
                            value={
                              values?.outsideDesignDBHeatingSourceMeterType ===
                              "RH"
                            }
                            onChange={handleSourceMeterTypeChange}
                            showSkeleton={isLoading}
                            disabled={
                              values?.outsideDesignHeatingNotListed ||
                              disableStudy()
                            }
                          />
                        </Grid>
                        <Grid item xs={12} md={1}>
                          <FormCheckBox
                            label="WB"
                            name="outsideDesignDBHeatingSourceMeterType-WB"
                            value={
                              values?.outsideDesignDBHeatingSourceMeterType ===
                              "WB"
                            }
                            onChange={handleSourceMeterTypeChange}
                            showSkeleton={isLoading}
                            disabled={
                              values?.outsideDesignHeatingNotListed ||
                              disableStudy()
                            }
                          />
                        </Grid>
                        <Grid item xs={12} md={1}>
                          <FormCheckBox
                            label="DP"
                            name="outsideDesignDBHeatingSourceMeterType-DP"
                            value={
                              values?.outsideDesignDBHeatingSourceMeterType ===
                              "DP"
                            }
                            onChange={handleSourceMeterTypeChange}
                            showSkeleton={isLoading}
                            disabled={
                              values?.outsideDesignHeatingNotListed ||
                              disableStudy()
                            }
                          />
                        </Grid>
                        {/**Interior Zone Cooling */}
                        <Grid item xs={12} md={3}>
                          <FormCheckBox
                            label="Not Listed"
                            name="interiorDesignZoneConditionsDBCoolingNotListed"
                            value={
                              values?.interiorDesignZoneConditionsDBCoolingNotListed
                            }
                            onChange={handleInteriorDBCoolingNotListedChange}
                            showSkeleton={isLoading}
                            disabled={disableStudy()}
                          />
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <FormNumeric2
                            disabled={
                              values?.interiorDesignZoneConditionsDBCoolingNotListed ||
                              disableStudy()
                            }
                            placeholder="0.00"
                            decimalScale={2}
                            thousandSeparator
                            fixedDecimalScale
                            label={`Cooling Interior DB ${systemOfMeasurement.get(
                              "temperature"
                            )}`}
                            name="interiorDesignZoneConditionsDBCooling"
                            onChange={handleInputChange}
                            value={
                              values?.interiorDesignZoneConditionsDBCooling
                            }
                            showSkeleton={isLoading}
                            error={errors.interiorDesignZoneConditionsDBCooling}
                            inputProps={{ sx: { color: "blue" } }}
                          />
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <FormNumeric2
                            disabled={
                              values?.interiorDesignZoneConditionsCoolingNotListed ||
                              disableStudy()
                            }
                            placeholder="0.00"
                            decimalScale={2}
                            thousandSeparator
                            fixedDecimalScale
                            label={`Cooling Interior ${values?.interiorDesignZoneConditionsDBCoolingSourceMeterType}`}
                            suffix={
                              values?.interiorDesignZoneConditionsDBCoolingSourceMeterType ===
                              "RH"
                                ? "%"
                                : systemOfMeasurement.get("temperature")
                            }
                            name="interiorDesignZoneConditionsCooling"
                            onChange={handleInputChange}
                            value={values?.interiorDesignZoneConditionsCooling}
                            showSkeleton={isLoading}
                            error={errors.interiorDesignZoneConditionsCooling}
                            inputProps={{ sx: { color: "blue" } }}
                          />
                        </Grid>
                        <Grid item xs={12} md={1}>
                          <FormCheckBox
                            label="RH"
                            name="interiorDesignZoneConditionsDBCoolingSourceMeterType-RH"
                            value={
                              values?.interiorDesignZoneConditionsDBCoolingSourceMeterType ===
                              "RH"
                            }
                            onChange={handleSourceMeterTypeChange}
                            showSkeleton={isLoading}
                            disabled={
                              values?.interiorDesignZoneConditionsCoolingNotListed ||
                              disableStudy()
                            }
                          />
                        </Grid>
                        <Grid item xs={12} md={1}>
                          <FormCheckBox
                            label="WB"
                            name="interiorDesignZoneConditionsDBCoolingSourceMeterType-WB"
                            value={
                              values?.interiorDesignZoneConditionsDBCoolingSourceMeterType ===
                              "WB"
                            }
                            onChange={handleSourceMeterTypeChange}
                            showSkeleton={isLoading}
                            disabled={
                              values?.interiorDesignZoneConditionsCoolingNotListed ||
                              disableStudy()
                            }
                          />
                        </Grid>
                        <Grid item xs={12} md={1}>
                          <FormCheckBox
                            label="DP"
                            name="interiorDesignZoneConditionsDBCoolingSourceMeterType-DP"
                            value={
                              values?.interiorDesignZoneConditionsDBCoolingSourceMeterType ===
                              "DP"
                            }
                            onChange={handleSourceMeterTypeChange}
                            showSkeleton={isLoading}
                            disabled={
                              values?.interiorDesignZoneConditionsCoolingNotListed ||
                              disableStudy()
                            }
                          />
                        </Grid>
                        {/**Interior Heating */}
                        <Grid item xs={12} md={3}>
                          <FormCheckBox
                            label="Not Listed"
                            name="interiorDesignZoneConditionsDBHeatingNotListed"
                            value={
                              values?.interiorDesignZoneConditionsDBHeatingNotListed
                            }
                            onChange={handleInteriorDBHeatingNotListedChange}
                            showSkeleton={isLoading}
                            disabled={disableStudy()}
                          />
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <FormNumeric2
                            disabled={
                              values?.interiorDesignZoneConditionsDBHeatingNotListed ||
                              disableStudy()
                            }
                            placeholder="0.00"
                            decimalScale={2}
                            thousandSeparator
                            fixedDecimalScale
                            label={`Heating Interior DB ${systemOfMeasurement.get(
                              "temperature"
                            )}`}
                            name="interiorDesignZoneConditionsDBHeating"
                            onChange={handleInputChange}
                            value={
                              values?.interiorDesignZoneConditionsDBHeating
                            }
                            showSkeleton={isLoading}
                            error={errors.interiorDesignZoneConditionsDBHeating}
                            inputProps={{ sx: { color: "darkred" } }}
                          />
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <FormNumeric2
                            disabled={
                              values?.interiorDesignZoneConditionsHeatingNotListed ||
                              disableStudy()
                            }
                            placeholder="0.00"
                            decimalScale={2}
                            thousandSeparator
                            fixedDecimalScale
                            label={`Heating Interior ${values?.interiorDesignZoneConditionsDBHeatingSourceMeterType}`}
                            suffix={
                              values?.interiorDesignZoneConditionsDBHeatingSourceMeterType ===
                              "RH"
                                ? "%"
                                : systemOfMeasurement.get("temperature")
                            }
                            name="interiorDesignZoneConditionsHeating"
                            onChange={handleInputChange}
                            value={values?.interiorDesignZoneConditionsHeating}
                            showSkeleton={isLoading}
                            error={errors.interiorDesignZoneConditionsHeating}
                            inputProps={{ sx: { color: "darkred" } }}
                          />
                        </Grid>
                        <Grid item xs={12} md={1}>
                          <FormCheckBox
                            label="RH"
                            name="interiorDesignZoneConditionsDBHeatingSourceMeterType-RH"
                            value={
                              values?.interiorDesignZoneConditionsDBHeatingSourceMeterType ===
                              "RH"
                            }
                            onChange={handleSourceMeterTypeChange}
                            showSkeleton={isLoading}
                            disabled={
                              values?.interiorDesignZoneConditionsHeatingNotListed ||
                              disableStudy()
                            }
                          />
                        </Grid>
                        <Grid item xs={12} md={1}>
                          <FormCheckBox
                            label="WB"
                            name="interiorDesignZoneConditionsDBHeatingSourceMeterType-WB"
                            value={
                              values?.interiorDesignZoneConditionsDBHeatingSourceMeterType ===
                              "WB"
                            }
                            onChange={handleSourceMeterTypeChange}
                            showSkeleton={isLoading}
                            disabled={
                              values?.interiorDesignZoneConditionsHeatingNotListed ||
                              disableStudy()
                            }
                          />
                        </Grid>
                        <Grid item xs={12} md={1}>
                          <FormCheckBox
                            label="DP"
                            name="interiorDesignZoneConditionsDBHeatingSourceMeterType-DP"
                            value={
                              values?.interiorDesignZoneConditionsDBHeatingSourceMeterType ===
                              "DP"
                            }
                            onChange={handleSourceMeterTypeChange}
                            showSkeleton={isLoading}
                            disabled={
                              values?.interiorDesignZoneConditionsHeatingNotListed ||
                              disableStudy()
                            }
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={3}>
                        <Grid item xs={12}>
                          <h3>Area Served Dimensions</h3>
                          <FormCheckBox
                            label={`All not listed`}
                            name="allCFMNotAvailbale"
                            value={allAreaServedNA()}
                            onChange={allAreaServedNAHandleChange}
                            showSkeleton={isLoading}
                            disabled={disableStudy()}
                          />
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <FormCheckBox
                            label="Not Listed"
                            name="floorAreaSqFtFedNotListed"
                            value={values?.floorAreaSqFtFedNotListed}
                            onChange={handleInputChange}
                            showSkeleton={isLoading}
                            disabled={disableStudy()}
                          />
                        </Grid>
                        <Grid item xs={12} md={9}>
                          <FormNumeric2
                            disabled={
                              values?.floorAreaSqFtFedNotListed ||
                              disableStudy()
                            }
                            placeholder="0.00"
                            decimalScale={2}
                            thousandSeparator
                            fixedDecimalScale
                            label={`System Floor Area ${systemOfMeasurement.get(
                              "sqft"
                            )} fed by this unit`}
                            name="floorAreaSqFtFed"
                            onChange={handleInputChange}
                            onBlur={handleFloorAreaSqFtFedBlur}
                            value={values?.floorAreaSqFtFed}
                            showSkeleton={isLoading}
                            error={errors.floorAreaSqFtFed}
                          />
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <FormCheckBox
                            label="Not Listed"
                            name="floorAreaCuFtFedNotListed"
                            value={values?.floorAreaCuFtFedNotListed}
                            onChange={handleInputChange}
                            showSkeleton={isLoading}
                            disabled={disableStudy()}
                          />
                        </Grid>
                        <Grid item xs={12} md={8}>
                          <FormNumeric2
                            disabled={
                              values?.floorAreaCuFtFedNotListed ||
                              disableStudy()
                            }
                            placeholder="0.00"
                            decimalScale={2}
                            thousandSeparator
                            fixedDecimalScale
                            label={`System Floor Area ${systemOfMeasurement.get(
                              "cuft"
                            )} fed by this unit`}
                            name="floorAreaCuFtFed"
                            onChange={handleInputChange}
                            onBlur={handlefloorAreaCuFtFedBlur}
                            value={values?.floorAreaCuFtFed}
                            showSkeleton={isLoading}
                            error={errors.floorAreaCuFtFed}
                          />
                        </Grid>
                        <Grid item xs={12} md={1}>
                          <FormTooltip titleName="Total Cubic Feet" />
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <FormCheckBox
                            label="Not Listed"
                            name="acphConfiguredAsNotListed"
                            value={values?.acphConfiguredAsNotListed}
                            onChange={handleInputChange}
                            showSkeleton={isLoading}
                            disabled={disableStudy()}
                          />
                        </Grid>
                        <Grid item xs={12} md={8}>
                          <FormRadioGroup
                            name="acphConfiguredAs"
                            label="Air Changes per Hour Configured using:"
                            items={acphOptions}
                            value={values?.acphConfiguredAs}
                            onChange={handleInputChange}
                            row
                            showSkeleton={isLoading || isLoadingReportType}
                            disabled={
                              values?.acphConfiguredAsNotListed ||
                              disableStudy()
                            }
                            error={errors.acphConfiguredAs}
                          />
                        </Grid>
                        <Grid item xs={12} md={1}>
                          <FormTooltip titleName="ACHP Information" />
                        </Grid>
                      </Grid>
                      <Grid container spacing={3}>
                        <Grid item xs={12}>
                          <h3>Airflow Design Data</h3>
                          <FormCheckBox
                            label={`All not listed`}
                            name="allCFMNotAvailbale"
                            value={allAirflowNA()}
                            onChange={allAirflowNAHandleChange}
                            showSkeleton={isLoading}
                            disabled={disableStudy()}
                          />
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <FormCheckBox
                            label="Not Listed"
                            name="systemCFMNotListed"
                            value={values?.systemCFMNotListed}
                            onChange={handleInputChange}
                            showSkeleton={isLoading}
                            disabled={disableStudy()}
                          />
                        </Grid>
                        <Grid item xs={12} md={9}>
                          <FormNumeric2
                            disabled={
                              values?.systemCFMNotListed || disableStudy()
                            }
                            placeholder="0.00"
                            decimalScale={2}
                            thousandSeparator
                            fixedDecimalScale
                            label={`System ${systemOfMeasurement.get("cfm")}`}
                            name="systemCFM"
                            onChange={handleInputChange}
                            value={values?.systemCFM}
                            showSkeleton={isLoading}
                            error={errors.systemCFM}
                          />
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <FormCheckBox
                            label="Not Listed"
                            name="outletCFMNotListed"
                            value={values?.outletCFMNotListed}
                            onChange={handleInputChange}
                            showSkeleton={isLoading}
                            disabled={disableStudy()}
                          />
                        </Grid>
                        <Grid item xs={12} md={9}>
                          <FormNumeric2
                            disabled={
                              values?.outletCFMNotListed || disableStudy()
                            }
                            placeholder="0.00"
                            decimalScale={2}
                            thousandSeparator
                            fixedDecimalScale
                            label={`Diffusers/Outlets ${systemOfMeasurement.get(
                              "cfm"
                            )}`}
                            name="outletCFM"
                            onChange={handleInputChange}
                            value={values?.outletCFM}
                            showSkeleton={isLoading}
                            error={errors.outletCFM}
                          />
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <FormCheckBox
                            label="Not Listed"
                            name="returnAirflowNotListed"
                            value={values?.returnAirflowNotListed}
                            onChange={handleInputChange}
                            showSkeleton={isLoading}
                            disabled={disableStudy()}
                          />
                        </Grid>
                        <Grid item xs={12} md={9}>
                          <FormNumeric2
                            disabled={
                              values?.returnAirflowNotListed || disableStudy()
                            }
                            placeholder="0.00"
                            decimalScale={2}
                            thousandSeparator
                            fixedDecimalScale
                            label={`Return Airflow ${systemOfMeasurement.get(
                              "cfm"
                            )}`}
                            name="returnAirflow"
                            onChange={handleInputChange}
                            onBlur={handleReturnAirflowBlur}
                            value={values?.returnAirflow}
                            showSkeleton={isLoading}
                            error={errors.returnAirflow}
                          />
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <FormCheckBox
                            label="Not Listed"
                            name="outsideAirflowNotListed"
                            value={values?.outsideAirflowNotListed}
                            onChange={handleInputChange}
                            showSkeleton={isLoading}
                            disabled={disableStudy()}
                          />
                        </Grid>
                        <Grid item xs={12} md={9}>
                          <FormNumeric2
                            disabled={
                              values?.outsideAirflowNotListed || disableStudy()
                            }
                            placeholder="0.00"
                            decimalScale={2}
                            thousandSeparator
                            fixedDecimalScale
                            label={`Outside Airflow ${systemOfMeasurement.get(
                              "cfm"
                            )}`}
                            name="outsideAirflow"
                            onChange={handleInputChange}
                            onBlur={handleOutsideAirflowBlur}
                            value={values?.outsideAirflow}
                            showSkeleton={isLoading}
                            error={errors.outsideAirflow}
                          />
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <FormCheckBox
                            label="Not Listed"
                            name="systemExhaustAirflowNotListed"
                            value={values?.systemExhaustAirflowNotListed}
                            onChange={handleInputChange}
                            showSkeleton={isLoading}
                            disabled={disableStudy()}
                          />
                        </Grid>
                        <Grid item xs={12} md={9}>
                          <FormNumeric2
                            disabled={
                              values?.systemExhaustAirflowNotListed ||
                              disableStudy()
                            }
                            placeholder="0.00"
                            decimalScale={2}
                            thousandSeparator
                            fixedDecimalScale
                            label={`Exhaust Airflow ${systemOfMeasurement.get(
                              "cfm"
                            )}`}
                            name="systemExhaustAirflow"
                            onChange={handleInputChange}
                            value={values?.systemExhaustAirflow}
                            showSkeleton={isLoading}
                            error={errors.systemExhaustAirflow}
                          />
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <FormCheckBox
                            label="Not Listed"
                            name="numberOfFansXNotListed"
                            value={values?.numberOfFansXNotListed}
                            onChange={handleNumberOfFansXNotListedChange}
                            showSkeleton={isLoading}
                            disabled={disableStudy()}
                          />
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <FormCheckBox
                            label="This AHU is a Fan Array System"
                            name="supplyFanSystemIsArray"
                            value={values?.supplyFanSystemIsArray}
                            onChange={handleInputChange}
                            showSkeleton={isLoading}
                            disabled={
                              values?.supplyFanSystemIsArrayNotListed ||
                              disableStudy()
                            }
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          md={values?.supplyFanSystemIsArray ? 3 : 6}
                        >
                          <FormNumeric2
                            disabled={
                              values?.numberOfFansXNotListed || disableStudy()
                            }
                            placeholder="0.00"
                            decimalScale={2}
                            thousandSeparator
                            fixedDecimalScale
                            label={`Number of Fans ${
                              values?.supplyFanSystemIsArray ? "(X)" : ""
                            }`}
                            name="numberOfFansX"
                            onChange={handleInputChange}
                            value={values?.numberOfFansX}
                            showSkeleton={isLoading}
                            error={errors.numberOfFansX}
                          />
                        </Grid>
                        {values?.supplyFanSystemIsArray && (
                          <Grid item xs={12} md={3}>
                            <FormNumeric2
                              disabled={
                                values?.numberOfFansYNotListed || disableStudy()
                              }
                              placeholder="0.00"
                              decimalScale={2}
                              thousandSeparator
                              fixedDecimalScale
                              label="Number of Fans (Y)"
                              name="numberOfFansY"
                              onChange={handleInputChange}
                              value={values?.numberOfFansY}
                              showSkeleton={isLoading}
                              error={errors.numberOfFansY}
                            />
                          </Grid>
                        )}
                        {cfmDiscrepancyAlert() && (
                          <>
                            <Grid item xs={12}>
                              <Alert severity="error">
                                Return air and Outside air, exceeds total
                                airflow.
                                <br />
                                Call the HVAC design team and identify what
                                their new actual airflow intent is and enter
                                this data accordingly.
                              </Alert>
                            </Grid>
                            <Grid item xs={12}>
                              <FormCheckBox
                                name="cfmDiscrepancyAgreementSignature"
                                error={errors.cfmDiscrepancyAgreementSignature}
                                label="I already talked with the building's design team authority about this situation, and the values are correct."
                                value={
                                  !!values?.cfmDiscrepancyAgreementSignature
                                }
                                onClick={handleCfmDiscrepancyAgreed}
                              />
                            </Grid>
                            <Grid item xs={row} md={4}>
                              <FormText
                                name="cfmDiscrepancyAuthority"
                                label="Authority"
                                value={values?.cfmDiscrepancyAuthority}
                                onChange={handleInputChange}
                                error={errors.cfmDiscrepancyAuthority}
                                showSkeleton={isLoading || isLoadingReportType}
                                disabled={
                                  !values?.cfmDiscrepancyAgreementSignature
                                }
                              />
                            </Grid>
                            <Grid item xs={4}>
                              <FormDatePicker
                                name="cfmDiscrepancyDateDiscussed"
                                label="Date of Conversation"
                                value={values?.cfmDiscrepancyDateDiscussed}
                                onChange={handleInputChange}
                                error={errors.cfmDiscrepancyDateDiscussed}
                                disable={
                                  !values?.cfmDiscrepancyAgreementSignature
                                }
                              />
                            </Grid>
                            <Grid item xs={4}>
                              <FormTimePicker
                                name="cfmDiscrepancyDateDiscussed"
                                label="Time of Conversation"
                                value={dateToLocalTimeString(
                                  values?.cfmDiscrepancyDateDiscussed || ""
                                )}
                                onChange={
                                  handleCfmDiscrepancyDateDiscussedTimeChange
                                }
                                error={errors.cfmDiscrepancyDateDiscussed}
                                disable={
                                  !values?.cfmDiscrepancyAgreementSignature
                                }
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <Alert severity="info">
                                Developing a memorandum of understanding is an
                                ideal method for proper documentation at this
                                point.
                              </Alert>
                            </Grid>
                          </>
                        )}
                      </Grid>
                    </Grid>
                    <Grid item xs={12} marginTop={2}>
                      <Divider />
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    spacing={0}
                    alignItems="flex-start"
                    alignContent="flex-start"
                  >
                    <Grid
                      item
                      xs={12}
                      md={6}
                      borderRight={1}
                      borderColor="lightgray"
                      marginTop={2}
                      paddingRight={2}
                    >
                      <Grid container spacing={3}>
                        <Grid item xs={12}>
                          <h3>Cooling Coil Design Data</h3>
                          <FormCheckBox
                            label={`All not listed`}
                            name="allCFMNotAvailbale"
                            value={allCoolingCoilDataNA()}
                            onChange={allCoolingCoilDataNAHandleChange}
                            showSkeleton={isLoading}
                            disabled={disableStudy()}
                          />
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <FormCheckBox
                            label="Not Listed"
                            name="numberOfCircuitsInCoolingCoilNotListed"
                            value={
                              values?.numberOfCircuitsInCoolingCoilNotListed
                            }
                            onChange={handleInputChange}
                            showSkeleton={isLoading}
                            disabled={disableStudy()}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          md={values?.coolingCoilHasMultipleCircuits ? 6 : 9}
                        >
                          <FormCheckBox
                            label="This Unit has multiple stacked Cooling Coils"
                            name="coolingCoilHasMultipleCircuits"
                            value={values?.coolingCoilHasMultipleCircuits}
                            onChange={handleInputChange}
                            showSkeleton={isLoading}
                            disabled={
                              values?.numberOfCircuitsInCoolingCoilNotListed ||
                              disableStudy()
                            }
                          />
                        </Grid>
                        {values?.coolingCoilHasMultipleCircuits && (
                          <Grid item xs={12} md={3}>
                            <FormNumeric2
                              disabled={
                                values?.numberOfCircuitsInCoolingCoilNotListed ||
                                disableStudy()
                              }
                              placeholder="0.00"
                              decimalScale={2}
                              thousandSeparator
                              fixedDecimalScale
                              maxValue={100}
                              label="Number of Cooling Coils"
                              name="numberOfCircuitsInCoolingCoil"
                              onChange={handleInputChange}
                              value={values?.numberOfCircuitsInCoolingCoil}
                              showSkeleton={isLoading}
                              error={errors.numberOfCircuitsInCoolingCoil}
                            />
                          </Grid>
                        )}
                        <Grid item xs={12} md={3}>
                          <FormCheckBox
                            label="Not Listed"
                            name="enteringAirTemperatureDBCoolingNotListed"
                            value={
                              values?.enteringAirTemperatureDBCoolingNotListed
                            }
                            onChange={
                              handleEnteringAirTemperatureDBCoolingNotListedChange
                            }
                            showSkeleton={isLoading}
                            disabled={disableStudy()}
                          />
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <FormNumeric2
                            disabled={
                              values?.enteringAirTemperatureDBCoolingNotListed ||
                              disableStudy()
                            }
                            placeholder="0.00"
                            decimalScale={2}
                            thousandSeparator
                            fixedDecimalScale
                            label={`Entering Air DB ${systemOfMeasurement.get(
                              "temperature"
                            )}`}
                            name="enteringAirTemperatureDBCooling"
                            onChange={handleInputChange}
                            value={values?.enteringAirTemperatureDBCooling}
                            showSkeleton={isLoading}
                            error={errors.enteringAirTemperatureDBCooling}
                            inputProps={{ sx: { color: "blue" } }}
                          />
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <FormNumeric2
                            disabled={
                              values?.enteringAirTemperatureWBCoolingNotListed ||
                              disableStudy()
                            }
                            placeholder="0.00"
                            decimalScale={2}
                            thousandSeparator
                            fixedDecimalScale
                            label={`Entering Air ${values?.enteringAirTemperatureCoolingSourceMeterType}`}
                            suffix={
                              values?.enteringAirTemperatureCoolingSourceMeterType ===
                              "RH"
                                ? "%"
                                : systemOfMeasurement.get("temperature")
                            }
                            name="enteringAirTemperatureWBCooling"
                            onChange={handleInputChange}
                            value={values?.enteringAirTemperatureWBCooling}
                            showSkeleton={isLoading}
                            error={errors.enteringAirTemperatureWBCooling}
                            inputProps={{ sx: { color: "blue" } }}
                          />
                        </Grid>
                        <Grid item xs={12} md={1}>
                          <FormCheckBox
                            label="RH"
                            name="enteringAirTemperatureCoolingSourceMeterType-RH"
                            value={
                              values?.enteringAirTemperatureCoolingSourceMeterType ===
                              "RH"
                            }
                            onChange={handleSourceMeterTypeChange}
                            showSkeleton={isLoading}
                            disabled={
                              values?.enteringAirTemperatureWBCoolingNotListed ||
                              disableStudy()
                            }
                          />
                        </Grid>
                        <Grid item xs={12} md={1}>
                          <FormCheckBox
                            label="WB"
                            name="enteringAirTemperatureCoolingSourceMeterType-WB"
                            value={
                              values?.enteringAirTemperatureCoolingSourceMeterType ===
                              "WB"
                            }
                            onChange={handleSourceMeterTypeChange}
                            showSkeleton={isLoading}
                            disabled={
                              values?.enteringAirTemperatureWBCoolingNotListed ||
                              disableStudy()
                            }
                          />
                        </Grid>
                        <Grid item xs={12} md={1}>
                          <FormCheckBox
                            label="DP"
                            name="enteringAirTemperatureCoolingSourceMeterType-DP"
                            value={
                              values?.enteringAirTemperatureCoolingSourceMeterType ===
                              "DP"
                            }
                            onChange={handleSourceMeterTypeChange}
                            showSkeleton={isLoading}
                            disabled={
                              values?.enteringAirTemperatureWBCoolingNotListed ||
                              disableStudy()
                            }
                          />
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <FormCheckBox
                            label="Not Listed"
                            name="leavingAirTemperatureDBCoolingNotListed"
                            value={
                              values?.leavingAirTemperatureDBCoolingNotListed
                            }
                            onChange={
                              handleLeavingAirTemperatureDBCoolingNotListedChange
                            }
                            showSkeleton={isLoading}
                            disabled={disableStudy()}
                          />
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <FormNumeric2
                            disabled={
                              values?.leavingAirTemperatureDBCoolingNotListed ||
                              disableStudy()
                            }
                            placeholder="0.00"
                            decimalScale={2}
                            thousandSeparator
                            fixedDecimalScale
                            label={`Leaving Air DB ${systemOfMeasurement.get(
                              "temperature"
                            )}`}
                            name="leavingAirTemperatureDBCooling"
                            onChange={handleInputChange}
                            value={values?.leavingAirTemperatureDBCooling}
                            showSkeleton={isLoading}
                            error={errors.leavingAirTemperatureDBCooling}
                            inputProps={{ sx: { color: "blue" } }}
                          />
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <FormNumeric2
                            disabled={
                              values?.leavingAirTemperatureWBCoolingNotListed ||
                              disableStudy()
                            }
                            placeholder="0.00"
                            decimalScale={2}
                            thousandSeparator
                            fixedDecimalScale
                            label={`Leaving Air ${values?.leavingAirTemperatureCoolingSourceMeterType}`}
                            suffix={
                              values?.leavingAirTemperatureCoolingSourceMeterType ===
                              "RH"
                                ? "%"
                                : systemOfMeasurement.get("temperature")
                            }
                            name="leavingAirTemperatureWBCooling"
                            onChange={handleInputChange}
                            value={values?.leavingAirTemperatureWBCooling}
                            showSkeleton={isLoading}
                            error={errors.leavingAirTemperatureWBCooling}
                            inputProps={{ sx: { color: "blue" } }}
                          />
                        </Grid>
                        <Grid item xs={12} md={1}>
                          <FormCheckBox
                            label="RH"
                            name="leavingAirTemperatureCoolingSourceMeterType-RH"
                            value={
                              values?.leavingAirTemperatureCoolingSourceMeterType ===
                              "RH"
                            }
                            onChange={handleSourceMeterTypeChange}
                            showSkeleton={isLoading}
                            disabled={
                              values?.leavingAirTemperatureWBCoolingNotListed ||
                              disableStudy()
                            }
                          />
                        </Grid>
                        <Grid item xs={12} md={1}>
                          <FormCheckBox
                            label="WB"
                            name="leavingAirTemperatureCoolingSourceMeterType-WB"
                            value={
                              values?.leavingAirTemperatureCoolingSourceMeterType ===
                              "WB"
                            }
                            onChange={handleSourceMeterTypeChange}
                            showSkeleton={isLoading}
                            disabled={
                              values?.leavingAirTemperatureWBCoolingNotListed ||
                              disableStudy()
                            }
                          />
                        </Grid>
                        <Grid item xs={12} md={1}>
                          <FormCheckBox
                            label="DP"
                            name="leavingAirTemperatureCoolingSourceMeterType-DP"
                            value={
                              values?.leavingAirTemperatureCoolingSourceMeterType ===
                              "DP"
                            }
                            onChange={handleSourceMeterTypeChange}
                            showSkeleton={isLoading}
                            disabled={
                              values?.leavingAirTemperatureWBCoolingNotListed ||
                              disableStudy()
                            }
                          />
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <FormCheckBox
                            label="Not Listed"
                            name="maAirPressureDeltaCoolingNotListed"
                            value={values?.maAirPressureDeltaCoolingNotListed}
                            onChange={handleInputChange}
                            showSkeleton={isLoading}
                            disabled={disableStudy()}
                          />
                        </Grid>
                        <Grid item xs={12} md={9}>
                          <FormNumeric2
                            disabled={
                              values?.maAirPressureDeltaCoolingNotListed ||
                              disableStudy()
                            }
                            placeholder="0.00"
                            decimalScale={2}
                            thousandSeparator
                            fixedDecimalScale
                            label={`Max "Air" Delta Pressure (ΔP) ${systemOfMeasurement.get(
                              "inwc"
                            )}`}
                            name="maAirPressureDeltaCooling"
                            onChange={handleInputChange}
                            value={values?.maAirPressureDeltaCooling}
                            showSkeleton={isLoading}
                            error={errors.maAirPressureDeltaCooling}
                          />
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <FormCheckBox
                            label="Not Listed"
                            name="designWaterDeltaPressureCoolingNotListed"
                            value={
                              values?.designWaterDeltaPressureCoolingNotListed
                            }
                            onChange={handleInputChange}
                            showSkeleton={isLoading}
                            disabled={disableStudy()}
                          />
                        </Grid>
                        <Grid item xs={12} md={9}>
                          <FormNumeric2
                            disabled={
                              values?.designWaterDeltaPressureCoolingNotListed ||
                              disableStudy()
                            }
                            placeholder="0.00"
                            decimalScale={2}
                            thousandSeparator
                            fixedDecimalScale
                            label={`Design Water Δ ${systemOfMeasurement.get(
                              "psi"
                            )}`}
                            name="designWaterDeltaPressureCooling"
                            onChange={handleInputChange}
                            value={values?.designWaterDeltaPressureCooling}
                            showSkeleton={isLoading}
                            error={errors.designWaterDeltaPressureCooling}
                          />
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <FormCheckBox
                            label="Not Listed"
                            name="coolingCoilGPMNotListed"
                            value={values?.coolingCoilGPMNotListed}
                            onChange={handleInputChange}
                            showSkeleton={isLoading}
                            disabled={disableStudy()}
                          />
                        </Grid>
                        <Grid item xs={12} md={9}>
                          <FormNumeric2
                            disabled={
                              values?.coolingCoilGPMNotListed || disableStudy()
                            }
                            placeholder="0.00"
                            decimalScale={2}
                            thousandSeparator
                            fixedDecimalScale
                            label={`Design ${systemOfMeasurement.get("gpm")}`}
                            name="coolingCoilGPM"
                            onChange={handleInputChange}
                            onBlur={handleCoolingCoilGPMBlur}
                            value={values?.coolingCoilGPM}
                            showSkeleton={isLoading}
                            error={errors.coolingCoilGPM}
                            inputProps={{ sx: { color: "blue" } }}
                          />
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <FormCheckBox
                            label="Not Listed"
                            name="enteringHydronicCoolingCoilTemperatureNotListed"
                            value={
                              values?.enteringHydronicCoolingCoilTemperatureNotListed
                            }
                            onChange={handleInputChange}
                            showSkeleton={isLoading}
                            disabled={disableStudy()}
                          />
                        </Grid>
                        <Grid item xs={12} md={9}>
                          <FormNumeric2
                            disabled={
                              values?.enteringHydronicCoolingCoilTemperatureNotListed ||
                              disableStudy()
                            }
                            placeholder="0.00"
                            decimalScale={2}
                            thousandSeparator
                            fixedDecimalScale
                            label={`Entering Hydronic/Water ${systemOfMeasurement.get(
                              "temperature"
                            )}`}
                            name="enteringHydronicCoolingCoilTemperature"
                            onChange={handleInputChange}
                            onBlur={handleCoolingCoilEWTBlur}
                            value={
                              values?.enteringHydronicCoolingCoilTemperature
                            }
                            showSkeleton={isLoading}
                            error={
                              errors.enteringHydronicCoolingCoilTemperature
                            }
                          />
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <FormCheckBox
                            label="Not Listed"
                            name="leavingHydronicCoolingCoilTemperatureNotListed"
                            value={
                              values?.leavingHydronicCoolingCoilTemperatureNotListed
                            }
                            onChange={handleInputChange}
                            showSkeleton={isLoading}
                            disabled={disableStudy()}
                          />
                        </Grid>
                        <Grid item xs={12} md={9}>
                          <FormNumeric2
                            disabled={
                              values?.leavingHydronicCoolingCoilTemperatureNotListed ||
                              disableStudy()
                            }
                            placeholder="0.00"
                            decimalScale={2}
                            thousandSeparator
                            fixedDecimalScale
                            label={`Leaving Hydronic/Water ${systemOfMeasurement.get(
                              "temperature"
                            )}`}
                            name="leavingHydronicCoolingCoilTemperature"
                            onChange={handleInputChange}
                            onBlur={handleCoolingCoilLWTBlur}
                            value={
                              values?.leavingHydronicCoolingCoilTemperature
                            }
                            showSkeleton={isLoading}
                            error={errors.leavingHydronicCoolingCoilTemperature}
                          />
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <FormCheckBox
                            label="Not Listed"
                            name="hydronicFluidFlowDeltaTemperatureCoolingNotListed"
                            value={
                              values?.hydronicFluidFlowDeltaTemperatureCoolingNotListed
                            }
                            onChange={handleInputChange}
                            showSkeleton={isLoading}
                            disabled={disableStudy()}
                          />
                        </Grid>
                        <Grid item xs={12} md={9}>
                          <FormNumeric2
                            disabled={
                              values?.hydronicFluidFlowDeltaTemperatureCoolingNotListed ||
                              disableStudy()
                            }
                            placeholder="0.00"
                            decimalScale={2}
                            thousandSeparator
                            fixedDecimalScale
                            label={`Hydronic Fluid Flow Δ ${systemOfMeasurement.get(
                              "temperature"
                            )}`}
                            name="hydronicFluidFlowDeltaTemperatureCooling"
                            onChange={handleInputChange}
                            onBlur={handleCoolingCoilAWTBlur}
                            value={
                              values?.hydronicFluidFlowDeltaTemperatureCooling
                            }
                            showSkeleton={isLoading}
                            error={
                              errors.hydronicFluidFlowDeltaTemperatureCooling
                            }
                          />
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <FormCheckBox
                            label="Not Listed"
                            name="totalBTUHCoolingNotListed"
                            value={values?.totalBTUHCoolingNotListed}
                            onChange={handleInputChange}
                            showSkeleton={isLoading}
                            disabled={disableStudy()}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FormNumeric2
                            disabled={
                              values?.totalBTUHCoolingNotListed ||
                              disableStudy()
                            }
                            placeholder="0.00"
                            suffix={` ${btuhModeValue(
                              values?.totalBTUHCoolingExpressedIn || "BTUH"
                            )}`}
                            decimalScale={2}
                            thousandSeparator
                            fixedDecimalScale
                            label={`Total ${systemOfMeasurement.get(
                              "btuhw"
                            )} via Airflow`}
                            name="totalBTUHCooling"
                            onChange={handleInputChange}
                            onBlur={handleTotalBTUHCoolingBlur}
                            value={values?.totalBTUHCooling}
                            showSkeleton={isLoading}
                            error={errors.totalBTUHCooling}
                            inputProps={{ sx: { color: "blue" } }}
                          />
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <FormSelect
                            label="Unit"
                            name="totalBTUHCoolingExpressedIn"
                            value={values?.totalBTUHCoolingExpressedIn}
                            onChange={handleTotalBTUHCoolingExpressedInChange}
                            options={btuhModeOptions()}
                            showSkeleton={isLoading}
                            disabled={
                              values?.totalBTUHCoolingNotListed ||
                              disableStudy()
                            }
                          />
                        </Grid>
                        {values?.testMode === "Cooling" && (
                          <>
                            <Grid item xs={12} md={11}>
                              <FormCheckBox
                                formControlProps={{ sx: { marginLeft: 42 } }}
                                label={`Use this data as the system's total ${systemOfMeasurement.get(
                                  "btuhw"
                                )} design. Or use the AHU ${systemOfMeasurement.get(
                                  "btuhw"
                                )} design.`}
                                name="referenceTotalBTUH"
                                value={values?.referenceTotalBTUH === "Coil"}
                                onChange={() =>
                                  handlerReferenceTotalBTUHChange("Coil")
                                }
                                showSkeleton={isLoading}
                                disabled={
                                  values?.totalBTUHCoolingNotListed ||
                                  disableStudy()
                                }
                              />
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              md={1}
                              sx={{ textAlign: "center" }}
                            >
                              <FormTooltip text={btuhReferenceTooltip()} />
                            </Grid>
                          </>
                        )}
                        <Grid item xs={12} md={3}>
                          <FormCheckBox
                            label="Not Listed"
                            name="sensibleBTUHCoolingNotListed"
                            value={values?.sensibleBTUHCoolingNotListed}
                            onChange={handleInputChange}
                            showSkeleton={isLoading}
                            disabled={disableStudy()}
                          />
                        </Grid>
                        <Grid item xs={12} md={9}>
                          <FormNumeric2
                            disabled={
                              values?.sensibleBTUHCoolingNotListed ||
                              disableStudy()
                            }
                            placeholder="0.00"
                            suffix={` ${btuhModeValue(
                              values?.sensibleBTUHCoolingExpressedIn || "BTUH"
                            )}`}
                            decimalScale={2}
                            thousandSeparator
                            fixedDecimalScale
                            label={`Sensible ${systemOfMeasurement.get(
                              "btuhw"
                            )} via Airflow`}
                            name="sensibleBTUHCooling"
                            onChange={handleInputChange}
                            onBlur={handleCoolingCoilBTUHBlur}
                            value={values?.sensibleBTUHCooling}
                            showSkeleton={isLoading}
                            error={errors.sensibleBTUHCooling}
                            inputProps={{ sx: { color: "blue" } }}
                          />
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <FormCheckBox
                            label="Not Listed"
                            name="latentBTUHCoolingNotListed"
                            value={values?.latentBTUHCoolingNotListed}
                            onChange={handleInputChange}
                            showSkeleton={isLoading}
                            disabled={disableStudy()}
                          />
                        </Grid>
                        <Grid item xs={12} md={9}>
                          <FormNumeric2
                            disabled={
                              values?.latentBTUHCoolingNotListed ||
                              disableStudy()
                            }
                            placeholder="0.00"
                            suffix={` ${btuhModeValue(
                              values?.latentBTUHCoolingExpressedIn || "BTUH"
                            )}`}
                            decimalScale={2}
                            thousandSeparator
                            fixedDecimalScale
                            label={`Latent ${systemOfMeasurement.get(
                              "btuhw"
                            )} via Airflow`}
                            name="latentBTUHCooling"
                            onChange={handleInputChange}
                            onBlur={handleCoolingCoilBTUHBlur}
                            value={values?.latentBTUHCooling}
                            showSkeleton={isLoading}
                            error={errors.latentBTUHCooling}
                            inputProps={{ sx: { color: "blue" } }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <FormLabel
                            text={`Sensible Heat Ratio (SHR) via Airflow: ${(
                              values?.shrCoilCooling || 0
                            ).toFixed(2)}`}
                            sx={{ color: "blue", marginLeft: 42 }}
                          />
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <FormCheckBox
                            label="Not Listed"
                            name="wpdFeetHeadCoolingNotListed"
                            value={values?.wpdFeetHeadCoolingNotListed}
                            onChange={handleInputChange}
                            showSkeleton={isLoading}
                            disabled={disableStudy()}
                          />
                        </Grid>
                        <Grid item xs={12} md={9}>
                          <FormNumeric2
                            disabled={
                              values?.wpdFeetHeadCoolingNotListed ||
                              disableStudy()
                            }
                            placeholder="0.00"
                            decimalScale={2}
                            thousandSeparator
                            fixedDecimalScale
                            label={`Hydronic/Water W.P.D. ${systemOfMeasurement.get(
                              "feet"
                            )} Head`}
                            name="wpdFeetHeadCooling"
                            onChange={handleInputChange}
                            value={values?.wpdFeetHeadCooling}
                            showSkeleton={isLoading}
                            error={errors.wpdFeetHeadCooling}
                          />
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <FormCheckBox
                            label="Not Listed"
                            name="finsPerInchCoolingNotListed"
                            value={values?.finsPerInchCoolingNotListed}
                            onChange={handleInputChange}
                            showSkeleton={isLoading}
                            disabled={disableStudy()}
                          />
                        </Grid>
                        <Grid item xs={12} md={9}>
                          <FormNumeric2
                            disabled={
                              values?.finsPerInchCoolingNotListed ||
                              disableStudy()
                            }
                            placeholder="0.00"
                            decimalScale={2}
                            thousandSeparator
                            fixedDecimalScale
                            label={`Fins per ${systemOfMeasurement.get(
                              "inches"
                            )}`}
                            name="finsPerInchCooling"
                            onChange={handleInputChange}
                            value={values?.finsPerInchCooling}
                            showSkeleton={isLoading}
                            error={errors.finsPerInchCooling}
                          />
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <FormCheckBox
                            label="Not Listed"
                            name="coolingCoilRowsNotListed"
                            value={values?.coolingCoilRowsNotListed}
                            onChange={handleInputChange}
                            showSkeleton={isLoading}
                            disabled={disableStudy()}
                          />
                        </Grid>
                        <Grid item xs={12} md={9}>
                          <FormNumeric2
                            disabled={
                              values?.coolingCoilRowsNotListed || disableStudy()
                            }
                            placeholder="0.00"
                            decimalScale={2}
                            thousandSeparator
                            fixedDecimalScale
                            label="Number of Rows"
                            name="coolingCoilRows"
                            onChange={handleInputChange}
                            value={values?.coolingCoilRows}
                            showSkeleton={isLoading}
                            error={errors.coolingCoilRows}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} md={6} marginTop={2} paddingLeft={2}>
                      <Grid container spacing={3}>
                        <Grid item xs={12}>
                          <h3>Heating Coil Design Data</h3>
                          <FormCheckBox
                            label={`All not listed`}
                            name="allHeatingCoilDataNA"
                            value={allHeatingCoilDataNA()}
                            onChange={allHeatingCoilDataNAHandleChange}
                            showSkeleton={isLoading}
                            disabled={disableStudy()}
                          />
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <FormCheckBox
                            label="Not Listed"
                            name="numberOfCircuitsInHeatingCoilNotListed"
                            value={
                              values?.numberOfCircuitsInHeatingCoilNotListed
                            }
                            onChange={handleInputChange}
                            showSkeleton={isLoading}
                            disabled={disableStudy()}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          md={values?.heatingCoilHasMultipleCircuits ? 6 : 9}
                        >
                          <FormCheckBox
                            label="This Unit has multiple stacked Heating Coils"
                            name="heatingCoilHasMultipleCircuits"
                            value={values?.heatingCoilHasMultipleCircuits}
                            onChange={handleInputChange}
                            showSkeleton={isLoading}
                            disabled={
                              values?.numberOfCircuitsInHeatingCoilNotListed ||
                              disableStudy()
                            }
                          />
                        </Grid>
                        {values?.heatingCoilHasMultipleCircuits && (
                          <Grid item xs={12} md={3}>
                            <FormNumeric2
                              disabled={
                                values?.numberOfCircuitsInHeatingCoilNotListed ||
                                disableStudy()
                              }
                              placeholder="0.00"
                              decimalScale={2}
                              thousandSeparator
                              fixedDecimalScale
                              maxValue={100}
                              label="Number of Heating Coils"
                              name="numberOfCircuitsInHeatingCoil"
                              onChange={handleInputChange}
                              value={values?.numberOfCircuitsInHeatingCoil}
                              showSkeleton={isLoading}
                              error={errors.numberOfCircuitsInHeatingCoil}
                            />
                          </Grid>
                        )}
                        <Grid item xs={12} md={3}>
                          <FormCheckBox
                            label="Not Listed"
                            name="enteringAirTemperatureDBHeatingNotListed"
                            value={
                              values?.enteringAirTemperatureDBHeatingNotListed
                            }
                            onChange={
                              handleEnteringAirTemperatureDBHeatingNotListedChange
                            }
                            showSkeleton={isLoading}
                            disabled={disableStudy()}
                          />
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <FormNumeric2
                            disabled={
                              values?.enteringAirTemperatureDBHeatingNotListed ||
                              disableStudy()
                            }
                            placeholder="0.00"
                            decimalScale={2}
                            thousandSeparator
                            fixedDecimalScale
                            label={`Entering Air DB ${systemOfMeasurement.get(
                              "temperature"
                            )}`}
                            name="enteringAirTemperatureDBHeating"
                            onChange={handleInputChange}
                            value={values?.enteringAirTemperatureDBHeating}
                            showSkeleton={isLoading}
                            error={errors.enteringAirTemperatureDBHeating}
                            inputProps={{ sx: { color: "blue" } }}
                          />
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <FormNumeric2
                            disabled={
                              values?.enteringAirTemperatureWBHeatingNotListed ||
                              disableStudy()
                            }
                            placeholder="0.00"
                            decimalScale={2}
                            thousandSeparator
                            fixedDecimalScale
                            label={`Entering Air ${values?.enteringAirTemperatureHeatingSourceMeterType}`}
                            suffix={
                              values?.enteringAirTemperatureHeatingSourceMeterType ===
                              "RH"
                                ? "%"
                                : systemOfMeasurement.get("temperature")
                            }
                            name="enteringAirTemperatureWBHeating"
                            onChange={handleInputChange}
                            value={values?.enteringAirTemperatureWBHeating}
                            showSkeleton={isLoading}
                            error={errors.enteringAirTemperatureWBHeating}
                            inputProps={{ sx: { color: "blue" } }}
                          />
                        </Grid>
                        <Grid item xs={12} md={1}>
                          <FormCheckBox
                            label="RH"
                            name="enteringAirTemperatureHeatingSourceMeterType-RH"
                            value={
                              values?.enteringAirTemperatureHeatingSourceMeterType ===
                              "RH"
                            }
                            onChange={handleSourceMeterTypeChange}
                            showSkeleton={isLoading}
                            disabled={
                              values?.enteringAirTemperatureWBHeatingNotListed ||
                              disableStudy()
                            }
                          />
                        </Grid>
                        <Grid item xs={12} md={1}>
                          <FormCheckBox
                            label="WB"
                            name="enteringAirTemperatureHeatingSourceMeterType-WB"
                            value={
                              values?.enteringAirTemperatureHeatingSourceMeterType ===
                              "WB"
                            }
                            onChange={handleSourceMeterTypeChange}
                            showSkeleton={isLoading}
                            disabled={
                              values?.enteringAirTemperatureWBHeatingNotListed ||
                              disableStudy()
                            }
                          />
                        </Grid>
                        <Grid item xs={12} md={1}>
                          <FormCheckBox
                            label="DP"
                            name="enteringAirTemperatureHeatingSourceMeterType-DP"
                            value={
                              values?.enteringAirTemperatureHeatingSourceMeterType ===
                              "DP"
                            }
                            onChange={handleSourceMeterTypeChange}
                            showSkeleton={isLoading}
                            disabled={
                              values?.enteringAirTemperatureWBHeatingNotListed ||
                              disableStudy()
                            }
                          />
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <FormCheckBox
                            label="Not Listed"
                            name="leavingAirTemperatureDBHeatingNotListed"
                            value={
                              values?.leavingAirTemperatureDBHeatingNotListed
                            }
                            onChange={
                              handleLeavingAirTemperatureDBHeatingNotListedChange
                            }
                            showSkeleton={isLoading}
                            disabled={disableStudy()}
                          />
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <FormNumeric2
                            disabled={
                              values?.leavingAirTemperatureDBHeatingNotListed ||
                              disableStudy()
                            }
                            placeholder="0.00"
                            decimalScale={2}
                            thousandSeparator
                            fixedDecimalScale
                            label={`Leaving Air DB ${systemOfMeasurement.get(
                              "temperature"
                            )}`}
                            name="leavingAirTemperatureDBHeating"
                            onChange={handleInputChange}
                            value={values?.leavingAirTemperatureDBHeating}
                            showSkeleton={isLoading}
                            error={errors.leavingAirTemperatureDBHeating}
                            inputProps={{ sx: { color: "blue" } }}
                          />
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <FormNumeric2
                            disabled={
                              values?.leavingAirTemperatureWBHeatingNotListed ||
                              disableStudy()
                            }
                            placeholder="0.00"
                            decimalScale={2}
                            thousandSeparator
                            fixedDecimalScale
                            label={`Leaving Air ${values?.leavingAirTemperatureHeatingSourceMeterType}`}
                            suffix={
                              values?.leavingAirTemperatureHeatingSourceMeterType ===
                              "RH"
                                ? "%"
                                : systemOfMeasurement.get("temperature")
                            }
                            name="leavingAirTemperatureWBHeating"
                            onChange={handleInputChange}
                            value={values?.leavingAirTemperatureWBHeating}
                            showSkeleton={isLoading}
                            error={errors.leavingAirTemperatureWBHeating}
                            inputProps={{ sx: { color: "blue" } }}
                          />
                        </Grid>
                        <Grid item xs={12} md={1}>
                          <FormCheckBox
                            label="RH"
                            name="leavingAirTemperatureHeatingSourceMeterType-RH"
                            value={
                              values?.leavingAirTemperatureHeatingSourceMeterType ===
                              "RH"
                            }
                            onChange={handleSourceMeterTypeChange}
                            showSkeleton={isLoading}
                            disabled={
                              values?.leavingAirTemperatureWBHeatingNotListed ||
                              disableStudy()
                            }
                          />
                        </Grid>
                        <Grid item xs={12} md={1}>
                          <FormCheckBox
                            label="WB"
                            name="leavingAirTemperatureHeatingSourceMeterType-WB"
                            value={
                              values?.leavingAirTemperatureHeatingSourceMeterType ===
                              "WB"
                            }
                            onChange={handleSourceMeterTypeChange}
                            showSkeleton={isLoading}
                            disabled={
                              values?.leavingAirTemperatureWBHeatingNotListed ||
                              disableStudy()
                            }
                          />
                        </Grid>
                        <Grid item xs={12} md={1}>
                          <FormCheckBox
                            label="DP"
                            name="leavingAirTemperatureHeatingSourceMeterType-DP"
                            value={
                              values?.leavingAirTemperatureHeatingSourceMeterType ===
                              "DP"
                            }
                            onChange={handleSourceMeterTypeChange}
                            showSkeleton={isLoading}
                            disabled={
                              values?.leavingAirTemperatureWBHeatingNotListed ||
                              disableStudy()
                            }
                          />
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <FormCheckBox
                            label="Not Listed"
                            name="maAirPressureDeltaHeatingNotListed"
                            value={values?.maAirPressureDeltaHeatingNotListed}
                            onChange={handleInputChange}
                            showSkeleton={isLoading}
                            disabled={disableStudy()}
                          />
                        </Grid>
                        <Grid item xs={12} md={9}>
                          <FormNumeric2
                            disabled={
                              values?.maAirPressureDeltaHeatingNotListed ||
                              disableStudy()
                            }
                            placeholder="0.00"
                            decimalScale={2}
                            thousandSeparator
                            fixedDecimalScale
                            label={`Max "Air" Delta Pressure (ΔP) ${systemOfMeasurement.get(
                              "inwc"
                            )}`}
                            name="maAirPressureDeltaHeating"
                            onChange={handleInputChange}
                            value={values?.maAirPressureDeltaHeating}
                            showSkeleton={isLoading}
                            error={errors.maAirPressureDeltaHeating}
                          />
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <FormCheckBox
                            label="Not Listed"
                            name="designWaterDeltaPressureHeatingNotListed"
                            value={
                              values?.designWaterDeltaPressureHeatingNotListed
                            }
                            onChange={handleInputChange}
                            showSkeleton={isLoading}
                            disabled={disableStudy()}
                          />
                        </Grid>
                        <Grid item xs={12} md={9}>
                          <FormNumeric2
                            disabled={
                              values?.designWaterDeltaPressureHeatingNotListed ||
                              disableStudy()
                            }
                            placeholder="0.00"
                            decimalScale={2}
                            thousandSeparator
                            fixedDecimalScale
                            label={`Design Water Δ ${systemOfMeasurement.get(
                              "psi"
                            )}`}
                            name="designWaterDeltaPressureHeating"
                            onChange={handleInputChange}
                            value={values?.designWaterDeltaPressureHeating}
                            showSkeleton={isLoading}
                            error={errors.designWaterDeltaPressureHeating}
                          />
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <FormCheckBox
                            label="Not Listed"
                            name="heatingCoilGPMNotListed"
                            value={values?.heatingCoilGPMNotListed}
                            onChange={handleInputChange}
                            showSkeleton={isLoading}
                            disabled={disableStudy()}
                          />
                        </Grid>
                        <Grid item xs={12} md={9}>
                          <FormNumeric2
                            disabled={
                              values?.heatingCoilGPMNotListed || disableStudy()
                            }
                            placeholder="0.00"
                            decimalScale={2}
                            thousandSeparator
                            fixedDecimalScale
                            label={`Design ${systemOfMeasurement.get("gpm")}`}
                            name="heatingCoilGPM"
                            onChange={handleInputChange}
                            onBlur={handleHeatingCoilGPMBlur}
                            value={values?.heatingCoilGPM}
                            showSkeleton={isLoading}
                            error={errors.heatingCoilGPM}
                            inputProps={{ sx: { color: "darkred" } }}
                          />
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <FormCheckBox
                            label="Not Listed"
                            name="enteringHydronicHeatingCoilTemperatureNotListed"
                            value={
                              values?.enteringHydronicHeatingCoilTemperatureNotListed
                            }
                            onChange={handleInputChange}
                            showSkeleton={isLoading}
                            disabled={disableStudy()}
                          />
                        </Grid>
                        <Grid item xs={12} md={9}>
                          <FormNumeric2
                            disabled={
                              values?.enteringHydronicHeatingCoilTemperatureNotListed ||
                              disableStudy()
                            }
                            placeholder="0.00"
                            decimalScale={2}
                            thousandSeparator
                            fixedDecimalScale
                            label={`Entering Hydronic/Water ${systemOfMeasurement.get(
                              "temperature"
                            )}`}
                            name="enteringHydronicHeatingCoilTemperature"
                            onChange={handleInputChange}
                            onBlur={handleHeatingCoilEWTBlur}
                            value={
                              values?.enteringHydronicHeatingCoilTemperature
                            }
                            showSkeleton={isLoading}
                            error={
                              errors.enteringHydronicHeatingCoilTemperature
                            }
                          />
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <FormCheckBox
                            label="Not Listed"
                            name="leavingHydronicHeatingCoilTemperatureNotListed"
                            value={
                              values?.leavingHydronicHeatingCoilTemperatureNotListed
                            }
                            onChange={handleInputChange}
                            showSkeleton={isLoading}
                            disabled={disableStudy()}
                          />
                        </Grid>
                        <Grid item xs={12} md={9}>
                          <FormNumeric2
                            disabled={
                              values?.leavingHydronicHeatingCoilTemperatureNotListed ||
                              disableStudy()
                            }
                            placeholder="0.00"
                            decimalScale={2}
                            thousandSeparator
                            fixedDecimalScale
                            label={`Leaving Hydronic/Water ${systemOfMeasurement.get(
                              "temperature"
                            )}`}
                            name="leavingHydronicHeatingCoilTemperature"
                            onChange={handleInputChange}
                            onBlur={handleHeatingCoilLWTBlur}
                            value={
                              values?.leavingHydronicHeatingCoilTemperature
                            }
                            showSkeleton={isLoading}
                            error={errors.leavingHydronicHeatingCoilTemperature}
                          />
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <FormCheckBox
                            label="Not Listed"
                            name="hydronicFluidFlowDeltaTemperatureHeatingNotListed"
                            value={
                              values?.hydronicFluidFlowDeltaTemperatureHeatingNotListed
                            }
                            onChange={handleInputChange}
                            showSkeleton={isLoading}
                            disabled={disableStudy()}
                          />
                        </Grid>
                        <Grid item xs={12} md={9}>
                          <FormNumeric2
                            disabled={
                              values?.hydronicFluidFlowDeltaTemperatureHeatingNotListed ||
                              disableStudy()
                            }
                            placeholder="0.00"
                            decimalScale={2}
                            thousandSeparator
                            fixedDecimalScale
                            label={`Hydronic Fluid Flow Δ ${systemOfMeasurement.get(
                              "temperature"
                            )}`}
                            name="hydronicFluidFlowDeltaTemperatureHeating"
                            onChange={handleInputChange}
                            onBlur={handleHeatingCoilAWTBlur}
                            value={
                              values?.hydronicFluidFlowDeltaTemperatureHeating
                            }
                            showSkeleton={isLoading}
                            error={
                              errors.hydronicFluidFlowDeltaTemperatureHeating
                            }
                          />
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <FormCheckBox
                            label="Not Listed"
                            name="totalBTUHHeatingNotListed"
                            value={values?.totalBTUHHeatingNotListed}
                            onChange={handleInputChange}
                            showSkeleton={isLoading}
                            disabled={disableStudy()}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FormNumeric2
                            disabled={
                              values?.totalBTUHHeatingNotListed ||
                              disableStudy()
                            }
                            placeholder="0.00"
                            suffix={` ${btuhModeValue(
                              values?.totalBTUHHeatingExpressedIn || "BTUH"
                            )}`}
                            decimalScale={2}
                            thousandSeparator
                            fixedDecimalScale
                            label={`Total ${systemOfMeasurement.get(
                              "btuhw"
                            )} via Airflow`}
                            name="totalBTUHHeating"
                            onChange={handleInputChange}
                            onBlur={handleTotalBTUHHeatingBlur}
                            value={values?.totalBTUHHeating}
                            showSkeleton={isLoading}
                            error={errors.totalBTUHHeating}
                            inputProps={{ sx: { color: "darkred" } }}
                          />
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <FormSelect
                            label="Unit"
                            name="totalBTUHHeatingExpressedIn"
                            value={values?.totalBTUHHeatingExpressedIn}
                            onChange={handleTotalBTUHHeatingExpressedInChange}
                            options={btuhModeOptions()}
                            showSkeleton={isLoading}
                            disabled={
                              values?.totalBTUHHeatingNotListed ||
                              disableStudy()
                            }
                          />
                        </Grid>
                        {values?.testMode === "Heating" && (
                          <>
                            <Grid item xs={12} md={11}>
                              <FormCheckBox
                                formControlProps={{ sx: { marginLeft: 42 } }}
                                label={`Use this data as the system's total ${systemOfMeasurement.get(
                                  "btuhw"
                                )} design. Or use the AHU ${systemOfMeasurement.get(
                                  "btuhw"
                                )} design.`}
                                name="referenceTotalBTUH"
                                value={values?.referenceTotalBTUH === "Coil"}
                                onChange={() =>
                                  handlerReferenceTotalBTUHChange("Coil")
                                }
                                showSkeleton={isLoading}
                                disabled={
                                  values?.totalBTUHHeatingNotListed ||
                                  disableStudy()
                                }
                              />
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              md={1}
                              sx={{ textAlign: "center" }}
                            >
                              <FormTooltip text={btuhReferenceTooltip()} />
                            </Grid>
                          </>
                        )}
                        <Grid item xs={12} md={3}>
                          <FormCheckBox
                            label="Not Listed"
                            name="sensibleBTUHHeatingNotListed"
                            value={values?.sensibleBTUHHeatingNotListed}
                            onChange={handleInputChange}
                            showSkeleton={isLoading}
                            disabled={disableStudy()}
                          />
                        </Grid>
                        <Grid item xs={12} md={9}>
                          <FormNumeric2
                            disabled={
                              values?.sensibleBTUHHeatingNotListed ||
                              disableStudy()
                            }
                            placeholder="0.00"
                            suffix={` ${btuhModeValue(
                              values?.sensibleBTUHHeatingExpressedIn || "BTUH"
                            )}`}
                            decimalScale={2}
                            thousandSeparator
                            fixedDecimalScale
                            label={`Sensible ${systemOfMeasurement.get(
                              "btuhw"
                            )} via Airflow`}
                            name="sensibleBTUHHeating"
                            onChange={handleInputChange}
                            onBlur={handleHeatingCoilBTUHBlur}
                            value={values?.sensibleBTUHHeating}
                            showSkeleton={isLoading}
                            error={errors.sensibleBTUHHeating}
                            inputProps={{ sx: { color: "darkred" } }}
                          />
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <FormCheckBox
                            label="Not Listed"
                            name="latentBTUHHeatingNotListed"
                            value={values?.latentBTUHHeatingNotListed}
                            onChange={handleInputChange}
                            showSkeleton={isLoading}
                            disabled={disableStudy()}
                          />
                        </Grid>
                        <Grid item xs={12} md={9}>
                          <FormNumeric2
                            disabled={
                              values?.latentBTUHHeatingNotListed ||
                              disableStudy()
                            }
                            placeholder="0.00"
                            suffix={` ${btuhModeValue(
                              values?.latentBTUHHeatingExpressedIn || "BTUH"
                            )}`}
                            decimalScale={2}
                            thousandSeparator
                            fixedDecimalScale
                            label={`Latent ${systemOfMeasurement.get(
                              "btuhw"
                            )} via Airflow`}
                            name="latentBTUHHeating"
                            onChange={handleInputChange}
                            onBlur={handleHeatingCoilBTUHBlur}
                            value={values?.latentBTUHHeating}
                            showSkeleton={isLoading}
                            error={errors.latentBTUHHeating}
                            inputProps={{ sx: { color: "darkred" } }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <FormLabel
                            text={`Sensible Heat Ratio (SHR) via Airflow: ${(
                              values?.shrCoilHeating || 0
                            ).toFixed(2)}`}
                            sx={{ color: "darkred", marginLeft: 42 }}
                          />
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <FormCheckBox
                            label="Not Listed"
                            name="wpdFeetHeadHeatingNotListed"
                            value={values?.wpdFeetHeadHeatingNotListed}
                            onChange={handleInputChange}
                            showSkeleton={isLoading}
                            disabled={disableStudy()}
                          />
                        </Grid>
                        <Grid item xs={12} md={9}>
                          <FormNumeric2
                            disabled={
                              values?.wpdFeetHeadHeatingNotListed ||
                              disableStudy()
                            }
                            placeholder="0.00"
                            decimalScale={2}
                            thousandSeparator
                            fixedDecimalScale
                            label={`Hydronic/Water W.P.D. ${systemOfMeasurement.get(
                              "feet"
                            )} Head`}
                            name="wpdFeetHeadHeating"
                            onChange={handleInputChange}
                            value={values?.wpdFeetHeadHeating}
                            showSkeleton={isLoading}
                            error={errors.wpdFeetHeadHeating}
                          />
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <FormCheckBox
                            label="Not Listed"
                            name="finsPerInchHeatingNotListed"
                            value={values?.finsPerInchHeatingNotListed}
                            onChange={handleInputChange}
                            showSkeleton={isLoading}
                            disabled={disableStudy()}
                          />
                        </Grid>
                        <Grid item xs={12} md={9}>
                          <FormNumeric2
                            disabled={
                              values?.finsPerInchHeatingNotListed ||
                              disableStudy()
                            }
                            placeholder="0.00"
                            decimalScale={2}
                            thousandSeparator
                            fixedDecimalScale
                            label={`Fins per ${systemOfMeasurement.get(
                              "inches"
                            )}`}
                            name="finsPerInchHeating"
                            onChange={handleInputChange}
                            value={values?.finsPerInchHeating}
                            showSkeleton={isLoading}
                            error={errors.finsPerInchHeating}
                          />
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <FormCheckBox
                            label="Not Listed"
                            name="heatingCoilRowsNotListed"
                            value={values?.heatingCoilRowsNotListed}
                            onChange={handleInputChange}
                            showSkeleton={isLoading}
                            disabled={disableStudy()}
                          />
                        </Grid>
                        <Grid item xs={12} md={9}>
                          <FormNumeric2
                            disabled={
                              values?.heatingCoilRowsNotListed || disableStudy()
                            }
                            placeholder="0.00"
                            decimalScale={2}
                            thousandSeparator
                            fixedDecimalScale
                            label="Number of Rows"
                            name="heatingCoilRows"
                            onChange={handleInputChange}
                            value={values?.heatingCoilRows}
                            showSkeleton={isLoading}
                            error={errors.heatingCoilRows}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Divider marginTop={2} />
                    </Grid>
                  </Grid>
                </>
              )}
              <FooterButtons
                activeStep={activeStep}
                stepsCount={steps.length}
                projectId={values?.projectId as number}
                companyId={user?.companyId as number}
                isSaving={isUpdating}
                disabled={
                  isUpdating || isLoading || isSubmitting || disableStudy()
                }
                reportName={title as string}
                saveHandler={saveHandler}
                nextStepHandler={nexStepHandler}
                isComplete={values?.isComplete}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default EnergyHydronicsStep1;
