import React from "react";
import { CircularProgress } from "@mui/material";
import { East as EastIcon } from "@mui/icons-material";
import FormButton from "./FormButton";

interface ButtonProps {
  onClick: (e: React.MouseEvent<HTMLElement>) => void;
  [key: string]: any;
  isSubmitting: boolean;
  disabled?: boolean;
  color?:
    | "inherit"
    | "primary"
    | "secondary"
    | "success"
    | "error"
    | "info"
    | "warning"
    | undefined;
  variant?: "text" | "contained" | "outlined" | undefined;
}

const FormNextButton = (props: ButtonProps) => {
  const {
    text,
    size,
    variant,
    onClick,
    isSubmitting,
    disabled,
    color,
    ...other
  } = props;

  return (
    <FormButton
      color={color || "primary"}
      text="Next Step"
      variant={variant || "outlined"}
      size="small"
      onClick={onClick}
      endIcon={isSubmitting ? <CircularProgress size="1rem" /> : <EastIcon />}
      disabled={disabled || isSubmitting}
      {...other}
    />
  );
};

export default FormNextButton;
