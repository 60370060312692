import { Grid, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import {
  FormAcceptButton,
  FormCancelButton,
  FormCheckBox,
  FormTextArea,
} from "src/components/formControls";
import Popup from "src/components/Popup";
import { useForm, Form, useLog } from "src/hooks";
import CompanyService from "src/services/companyService";
import { ICompanyData } from "src/ts/interfaces";

interface IChangeStatusPopupProps {
  disablePopup: boolean;
  setDisablePopup: (val: boolean) => void;
  currentValues?: ICompanyData;
  onSave: () => void;
  onClick: (e: React.MouseEvent<HTMLElement>) => void;
  status?: boolean;
  isSatelite?: boolean;
}

const ChangeStatusPopup = ({
  disablePopup,
  setDisablePopup,
  currentValues,
  onSave,
  onClick,
  status,
  isSatelite,
}: IChangeStatusPopupProps) => {
  interface IDisableCompanyForm {
    id?: number;
    proceed: number;
    reason: string;
  }

  const initialValues: IDisableCompanyForm = {
    id: currentValues?.id,
    proceed: 0,
    reason: "",
  };

  const validate = (fieldValues = values) => {};
  const { log } = useLog();
  const { values, errors, handleInputChange, resetForm } = useForm(
    initialValues,
    true,
    validate
  );
  const [isSubmitting, setIsSubmitting] = useState(false);
  const handleSave = async () => {
    try {
      setIsSubmitting(true);
      if (isSatelite) {
        await CompanyService.changeStatusSatellite({
          id: currentValues!.id,
          isActive: status ? false : true,
          reason: values.reason,
        });
      } else {
        await CompanyService.changeStatus({
          id: currentValues!.id,
          isActive: status ? false : true,
          reason: values.reason,
        });
      }

      onSave();
      const successMessage =
        currentValues && currentValues?.headquarters
          ? `${currentValues?.name} in ${currentValues?.simpleAddress}, was ${
              status ? "deactivated" : "activated"
            }`
          : `Satellite company with address ${currentValues?.address}, ${
              currentValues?.city
            } ${
              currentValues?.stateName !== null
                ? currentValues?.stateName
                : currentValues?.state?.name
            } ${currentValues?.country?.name}, was ${
              status ? "deactivated" : "activated"
            }`;

      log.success(`${successMessage}`);
    } catch (error: any) {
      log.error(error?.message?.exceptionMessage ?? "Something went wrong");
    } finally {
      setIsSubmitting(false);
      setDisablePopup(false);
      resetForm();
      onSave();
    }
  };

  const handleClose = () => {
    resetForm();
    setDisablePopup(false);
  };

  return (
    <Popup
      title="Information"
      openPopup={disablePopup}
      setOpenPopup={setDisablePopup}
      onClose={handleClose}
    >
      <>
        <Typography variant="body2" gutterBottom>
          {!status
            ? "Are you certain you want to enable this company? Enabling will renew access to the system"
            : "Are you certain you want to disable this company? Disabling the account will also disable access to the system"}
        </Typography>
        <Form>
          <Grid container>
            <Grid item xs={12}>
              <FormCheckBox
                label="Yes, proceed"
                name="proceed"
                value={values.proceed}
                onChange={handleInputChange}
                onClick={onClick}
              />
            </Grid>
            <Grid item xs={12} mb={2}></Grid>
            <FormTextArea
              name="reason"
              label={
                !status ? "Reason for activation:" : "Reason for desactivation:"
              }
              value={values.reason}
              onChange={handleInputChange}
              error={errors.reason}
              disabled={!values.proceed}
              placeholder={
                !status
                  ? "Be descriptive when entering reason for activation"
                  : "Describe the reason"
              }
            />
          </Grid>
          <Grid item xs={12}></Grid>
        </Form>
      </>
      <Stack
        direction="row"
        spacing={2}
        justifyContent="flex-end"
        alignItems="flex-start"
      >
        <FormCancelButton onClick={handleClose} isSubmitting={isSubmitting} />{" "}
        <FormAcceptButton
          onClick={handleSave}
          isSubmitting={isSubmitting}
          disabled={!values.proceed || values.reason.length === 0}
        />
      </Stack>
    </Popup>
  );
};

export default ChangeStatusPopup;
