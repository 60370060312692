import { useCallback, useEffect, useMemo, useState } from "react";

export interface Item {
  id: number;
}

const useListSelection = <T extends Item>(
  data?: T[],
  defaultSelection?: number[],
  onlyOneSelected?: boolean
) => {
  //TODO: test moving to useState
  //let internalData: T[] = data ?? [];
  const [internalData, setInternalData] = useState<T[]>(data ?? []);

  let internalDefaultSelection: number[] = defaultSelection ?? [];

  const [selection, setSelection] = useState<Set<number>>(
    new Set<number>(internalDefaultSelection)
  );

  const isSelected = useCallback(
    (id: number) => selection.has(id),
    [selection]
  );

  const toggleSelection = useCallback((id: number) => {
    setSelection((selection) => {
      let newSelection = new Set(selection);
      if (onlyOneSelected) {
        newSelection = new Set();
        newSelection.add(id);
      } else {
        if (selection.has(id)) {
          newSelection.delete(id);
        } else {
          newSelection.add(id);
        }
      }

      return newSelection;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggleSelectionAll = useCallback(() => {
    setSelection((selection) => {
      const areAllSelected = selection.size === internalData.length;
      let newSelection = new Set<number>();
      if (!areAllSelected) {
        newSelection = new Set(internalData.map((item) => item.id));
      }

      return newSelection;
    });
  }, [internalData]);

  const isAllSelected = useMemo(() => {
    return selection.size !== 0 && selection.size === internalData.length;
  }, [internalData, selection]);

  useEffect(() => {
    const dataIds = internalData.map((item) => item.id);
    setSelection((selection) => {
      const newSelection = new Set(selection);
      selection.forEach((item) => {
        if (!dataIds.includes(item)) {
          newSelection.delete(item);
        }
      });
      return newSelection;
    });
  }, [internalData]);

  const clearSelection = useCallback(() => {
    setSelection(new Set<number>());
  }, []);

  const selectedItem = useMemo(() => {
    const selectedItems: T[] = [];
    selection.forEach((id) => {
      const findItem = internalData.find((d) => d.id === id);
      if (findItem) {
        selectedItems.push(findItem);
      }
    });
    return selectedItems;
  }, [selection, internalData]);

  const selectedIds = useMemo(() => {
    const array = Array.from(selection);
    return array;
  }, [selection]);

  const setSelected = useCallback(
    (selectionOrCallback: number[] | ((selection: number[]) => number[])) => {
      if (Array.isArray(selectionOrCallback)) {
        setSelection(new Set(selectionOrCallback));
      } else {
        setSelection(
          (currentSelection) =>
            new Set(selectionOrCallback(Array.from(currentSelection)))
        );
      }
    },
    []
  );

  const setData = (data: T[], defaultSelection: number[]) => {
    setInternalData([...data]);
  };

  return {
    selection: Array.from(selection),
    isSelected,
    toggleSelection,
    toggleSelectionAll,
    isAllSelected,
    clearSelection,
    selectedItem,
    selectedIds,
    totalSelected: selection.size,
    setSelected,
    data,
    internalData,
    setData,
  };
};

export default useListSelection;
