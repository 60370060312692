import Popup from "src/components/Popup";
import { FormButton } from "src/components/formControls";
import { Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";

import projectContestService from "src/services/projectContestService";
import { useAsyncQuery, useLog } from "src/hooks";
import { ICheckAssign } from "src/ts/interfaces/projectContest";
import { IProjectContestOwner } from "src/ts/interfaces/projectOutToBid";
import { ProjectDTO } from "src/ts/interfaces/project/projectDto";

interface CheckToAssignProps {
  checkToAssignShow: boolean;
  setCheckToAssignShow: (val: boolean) => void;
  isSelected: number[];
  projectContestData: IProjectContestOwner[];
  projectId: number;
  setRefresh: () => void;
  projectSingle: ProjectDTO | undefined;
}

export default function CheckToAssignPopUp({
  checkToAssignShow,
  setCheckToAssignShow,
  isSelected,
  projectContestData,
  projectId,
  setRefresh,
  projectSingle,
}: CheckToAssignProps) {
  const { log } = useLog();

  const [data, setData] = useState<ICheckAssign[]>();

  const { execute, isLoading, status } = useAsyncQuery(
    projectContestService.postContestRangeContractors
  );

  useEffect(() => {
    const process = async () => {
      try {
        const response = await projectContestService.postCheckToAssignProject(
          isSelected
        );
        setData(response.data);
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      }
    };
    process();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (status === "success") {
      setRefresh();
      setCheckToAssignShow(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  const handlerAccept = async () => {
    execute(projectId, projectContestData);
  };
  const showAcceptButton =
    (data && data.length === 0 && projectSingle?.sendProposalThroughAMP) ||
    (data && data.length > 0 && !projectSingle?.sendProposalThroughAMP);

  return (
    <>
      <Popup
        title={"Information"}
        openPopup={checkToAssignShow}
        setOpenPopup={setCheckToAssignShow}
        onClose={() => {
          setCheckToAssignShow(false);
        }}
        size="sm"
        disableClickOutside={true}
        hideClose={isLoading}
      >
        <>
          {data && data.length > 0 && projectSingle?.sendProposalThroughAMP ? (
            <Stack>
              <Typography>
                Before assigning the project, you must select at least one bid
                concept from the following applicant(s).
              </Typography>
              <Stack>
                <ul>
                  {data.map((item: any, index) => (
                    <li key={index}>{item.companyName}</li>
                  ))}
                </ul>
              </Stack>
            </Stack>
          ) : (
            <Stack>
              <Typography>
                Are you certain you want to assign this project to this/these
                contractor(s)?
              </Typography>
            </Stack>
          )}
        </>
        <Stack direction="row" spacing={3}>
          <FormButton
            size="small"
            text="Close"
            variant="outlined"
            onClick={() => {
              setCheckToAssignShow(false);
            }}
            isSubmitting={isLoading}
          />
          {showAcceptButton && (
            <FormButton
              size="small"
              text="Accept"
              color="primary"
              onClick={() => {
                handlerAccept();
              }}
              isSubmitting={isLoading}
            />
          )}
        </Stack>
      </Popup>
    </>
  );
}
