import { Dispatch, SetStateAction, useEffect, useState } from "react";
import {
  Grid,
  IconButton,
  Toolbar,
  styled,
  Typography,
  Fab as MuiFab,
  Divider as MuiDivider,
  Alert as MuiAlert,
  Skeleton,
} from "@mui/material";
import {
  IElectricCoilBank,
  IElectricCoilItem,
} from "src/ts/interfaces/study/electricCoil";
import {
  FormCheckBox,
  FormNumeric2,
  FormText,
} from "src/components/formControls";
import { useAsyncQuery } from "src/hooks";
import electricCoilItemService from "src/services/study/electricCoilItemService";
import { spacing } from "@mui/system";
import { HeatingElectricResistancePhase } from "src/constants";
const Divider = styled(MuiDivider)(spacing);
const Fab = styled(MuiFab)(spacing);
const Alert = styled(MuiAlert)(spacing);
interface Props {
  data: IElectricCoilItem;
  setLoadingTable: Dispatch<SetStateAction<boolean>>;
  saveItem: () => void;
  refreshCircuits: boolean;
  disableStudy: boolean;
  showSkeleton: boolean;
}

const initialValues: IElectricCoilBank = {
  id: 0,
  electricCoilItemtId: 0,
  notOperational: false,
  line1Burned: false,
  line2Burned: false,
  line3Burned: false,
  bankIdentifier: "",
  overAmpningWarning: "",
};

const ElectricCoilBank = ({
  data,
  setLoadingTable,
  saveItem,
  refreshCircuits,
  disableStudy,
  showSkeleton,
}: Props) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [bank, setBank] = useState<IElectricCoilBank>(initialValues);
  const [indexBank, setIndexBank] = useState(0);
  const [refreshBank, setRefreshBank] = useState(false);
  const [isLoadingItem, setIsLoadingItem] = useState(false);

  const {
    execute: executeBank,
    data: dataBank,
    setData: setDataBank,
  } = useAsyncQuery<IElectricCoilBank[]>(
    electricCoilItemService.getBanksByItemId,
    {
      onSuccess: (result) => {
        chekIndex(result);
        setBank(result[0]);
      },
    }
  );

  const { execute: executeSaveBank } = useAsyncQuery<IElectricCoilBank>(
    electricCoilItemService.saveBank,
    {
      onSuccess: () => {
        saveItem();
      },
    }
  );

  useEffect(() => {
    const getData = async () => {
      setIsLoadingItem(false);
      await executeBank(data.id);
    };
    if (data && data?.id > 0) getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.id]);

  useEffect(() => {
    setIsLoadingItem(false);
    if (dataBank) setBank(dataBank[indexBank]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [indexBank]);

  useEffect(() => {
    setRefreshBank(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bank]);

  useEffect(() => {
    setIsLoadingItem(showSkeleton);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showSkeleton]);

  useEffect(() => {
    const getData = async () => {
      await executeBank(data.id);
    };
    if (data && data.id > 0) getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshCircuits]);

  useEffect(() => {
    if (bank) {
      let newBank = bank;
      const actualAmpsPhase1 = bank?.actualOperationalAmpsLine1 ?? 0;
      const actualAmpsPhase2 = bank?.actualOperationalAmpsLine2 ?? 0;
      const actualAmpsPhase3 = bank?.actualOperationalAmpsLine3 ?? 0;
      const line1ActualAmpsValid =
        actualAmpsPhase1 <= (data.heatingElectricResistanceRatedAmperage ?? 0);
      const line2ActualAmpsValid =
        actualAmpsPhase2 <= (data.heatingElectricResistanceRatedAmperage ?? 0);
      const line3ActualAmpsValid =
        actualAmpsPhase3 <= (data.heatingElectricResistanceRatedAmperage ?? 0);
      newBank.overAmpningWarning = "";
      if (!line1ActualAmpsValid && line2ActualAmpsValid && line3ActualAmpsValid)
        newBank.overAmpningWarning =
          "WARNING: Actual Amps exceed referenced nameplate Amp rating in line 1";
      if (line1ActualAmpsValid && !line2ActualAmpsValid && line3ActualAmpsValid)
        newBank.overAmpningWarning =
          "WARNING: Actual Amps exceed referenced nameplate Amp rating in line 2";
      if (line1ActualAmpsValid && line2ActualAmpsValid && !line3ActualAmpsValid)
        newBank.overAmpningWarning =
          "WARNING: Actual Amps exceed referenced nameplate Amp rating in line 3";
      if (
        !line1ActualAmpsValid &&
        !line2ActualAmpsValid &&
        line3ActualAmpsValid
      )
        newBank.overAmpningWarning =
          "WARNING: Actual Amps exceed referenced nameplate Amp rating in lines 1 & 2";
      if (
        !line1ActualAmpsValid &&
        line2ActualAmpsValid &&
        !line3ActualAmpsValid
      )
        newBank.overAmpningWarning =
          "WARNING: Actual Amps exceed referenced nameplate Amp rating in lines 1 & 3";
      if (
        line1ActualAmpsValid &&
        !line2ActualAmpsValid &&
        !line3ActualAmpsValid
      )
        newBank.overAmpningWarning =
          "WARNING: Actual Amps exceed referenced nameplate Amp rating in lines 2 & 3";
      if (
        !line1ActualAmpsValid &&
        !line2ActualAmpsValid &&
        !line3ActualAmpsValid
      )
        newBank.overAmpningWarning =
          "WARNING: Actual Amps exceed referenced nameplate Amp rating in lines 1, 2 & 3";
      setBank({ ...newBank });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    bank?.actualOperationalAmpsLine1,
    bank?.actualOperationalAmpsLine2,
    bank?.actualOperationalAmpsLine3,
    data.heatingElectricResistanceRatedAmperage,
  ]);

  const handleBlurBank = async (e: any, showSkeleton: boolean = false) => {
    const newValues = { ...bank, [e.target.name]: e.target.value };
    if (e.target.name === "line1Burned") {
      newValues.actualOperationalVoltsLine1 = undefined;
      newValues.actualOperationalAmpsLine1 = undefined;
    }
    if (e.target.name === "line2Burned") {
      newValues.actualOperationalVoltsLine2 = undefined;
      newValues.actualOperationalAmpsLine2 = undefined;
    }
    if (e.target.name === "line3Burned") {
      newValues.actualOperationalVoltsLine3 = undefined;
      newValues.actualOperationalAmpsLine3 = undefined;
    }
    if (showSkeleton) {
      setRefreshBank(true);
      setBank({ ...newValues });
    }
    await executeSaveBank(newValues);
  };

  const handleChangeBank = (item: any, e: any) => {
    item[e.target.name] = e.target.value;
    setBank({ ...item });
  };

  const handleChangeBlur = async (item: any, e: any) => {
    item[e.target.name] = e.target.value;
    setBank({ ...item });
    setLoadingTable(true);
    await executeSaveBank(item);
  };

  const chekIndex = (data: IElectricCoilBank[]) => {
    if (data.length < indexBank + 1) setIndexBank(0);
  };

  const applyChanges = (indePrev: number, newbank: IElectricCoilBank) => {
    const unmodified = dataBank.map((item, index) =>
      index === indePrev ? newbank : item
    );
    setDataBank(unmodified);
  };

  return (
    <>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Toolbar
            sx={{
              pl: { sm: 2 },
              pr: { xs: 1, sm: 1 },
              display: "block",
            }}
          >
            <>
              {dataBank &&
                dataBank.map((item, index) => (
                  <>
                    {index !== indexBank && (
                      <>
                        <IconButton
                          size="medium"
                          onClick={() => {
                            setIsLoadingItem(true);
                            applyChanges(indexBank, bank);
                            setIndexBank(index);
                          }}
                          sx={{ border: "1px solid", marginRight: "8px" }}
                        >
                          <Typography
                            variant="h5"
                            width={30}
                            height={30}
                            pt={1}
                          >
                            {index + 1}
                          </Typography>
                        </IconButton>
                      </>
                    )}
                    {index === indexBank && (
                      <>
                        <Fab
                          mx={2}
                          color="primary"
                          size="medium"
                          aria-label="Selected Item"
                        >
                          <Typography
                            variant="h5"
                            width={30}
                            height={30}
                            pt={1}
                          >
                            {index + 1}
                          </Typography>
                        </Fab>
                      </>
                    )}
                  </>
                ))}
            </>
          </Toolbar>
        </Grid>
        <Grid item xs={12}>
          {isLoadingItem || refreshBank ? (
            <Skeleton></Skeleton>
          ) : (
            <Typography variant="h5" textAlign={"center"}>
              Bank {indexBank + 1}
            </Typography>
          )}
        </Grid>
        <Grid item xs={12}>
          <Divider></Divider>
        </Grid>
        <Grid item xs={12} textAlign={"center"}>
          <FormCheckBox
            name={"notOperational"}
            label={"Bank Not Operational/Not Used At Time of testing"}
            value={dataBank && bank?.notOperational}
            onChange={(e: any) => {
              handleChangeBlur(bank, e);
            }}
            disabled={disableStudy}
          ></FormCheckBox>
        </Grid>
        <Grid item xs={4} textAlign={"right"}>
          <Typography variant="subtitle2" pt={2}>
            Bank Identifier
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <FormText
            name={"bankIdentifier"}
            label={"Circuit #, Specific Code for this Bank"}
            onChange={(e: any) => {
              handleChangeBank(bank, e);
            }}
            showSkeleton={isLoadingItem}
            onBlur={(e: any) => {
              handleBlurBank(e);
            }}
            value={bank?.bankIdentifier || ""}
            disabled={disableStudy || !dataBank || bank?.notOperational}
          ></FormText>
        </Grid>
        <Grid item xs={2}></Grid>
        <Grid item xs={2} textAlign={"right"}>
          <Typography variant="subtitle2" pt={2}>
            Line 1
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <FormNumeric2
            name={"actualOperationalVoltsLine1"}
            label={"Volts"}
            value={bank?.actualOperationalVoltsLine1 || ""}
            onChange={(e: any) => {
              handleChangeBank(bank, e);
            }}
            showSkeleton={isLoadingItem || refreshBank}
            onBlur={(e: any) => {
              handleBlurBank(e);
            }}
            disabled={
              disableStudy ||
              !dataBank ||
              bank?.notOperational ||
              bank?.line1Burned
            }
            decimalScale={3}
            thousandSeparator={true}
          ></FormNumeric2>
        </Grid>
        <Grid item xs={3}>
          <FormNumeric2
            name={"actualOperationalAmpsLine1"}
            label={"Amps"}
            value={bank?.actualOperationalAmpsLine1 || ""}
            onChange={(e: any) => {
              handleChangeBank(bank, e);
            }}
            onBlur={(e: any) => {
              handleBlurBank(e);
            }}
            showSkeleton={isLoadingItem || refreshBank}
            disabled={
              disableStudy ||
              !dataBank ||
              bank?.notOperational ||
              bank?.line1Burned
            }
            decimalScale={3}
            thousandSeparator={true}
          ></FormNumeric2>
        </Grid>
        <Grid item xs={3}>
          {(data?.heatingElectricResistancePhase ===
            HeatingElectricResistancePhase.ThreePhase ||
            data?.heatingElectricResistancePhase ===
              HeatingElectricResistancePhase.TwoWireSinglePhase) && (
            <FormCheckBox
              name={"line1Burned"}
              label={"Not Operational"}
              value={bank?.line1Burned}
              onChange={(e: any) => {
                handleBlurBank(e, true);
              }}
              showSkeleton={isLoadingItem}
              disabled={disableStudy || !dataBank || bank?.notOperational}
            ></FormCheckBox>
          )}
        </Grid>
        {(data?.heatingElectricResistancePhase ===
          HeatingElectricResistancePhase.ThreePhase ||
          data?.heatingElectricResistancePhase ===
            HeatingElectricResistancePhase.TwoWireSinglePhase) && (
          <>
            <Grid item xs={2} textAlign={"right"}>
              <Typography variant="subtitle2" pt={2}>
                Line 2
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <FormNumeric2
                name={"actualOperationalVoltsLine2"}
                label={"Volts"}
                value={bank?.actualOperationalVoltsLine2 || ""}
                onChange={(e: any) => {
                  handleChangeBank(bank, e);
                }}
                onBlur={(e: any) => {
                  handleBlurBank(e);
                }}
                showSkeleton={isLoadingItem || refreshBank}
                disabled={
                  disableStudy ||
                  !dataBank ||
                  bank?.notOperational ||
                  bank?.line2Burned
                }
                decimalScale={3}
                thousandSeparator={true}
              ></FormNumeric2>
            </Grid>
            <Grid item xs={3}>
              <FormNumeric2
                name={"actualOperationalAmpsLine2"}
                label={"Amps"}
                value={bank?.actualOperationalAmpsLine2 || ""}
                onChange={(e: any) => {
                  handleChangeBank(bank, e);
                }}
                onBlur={(e: any) => {
                  handleBlurBank(e);
                }}
                showSkeleton={isLoadingItem || refreshBank}
                disabled={
                  disableStudy ||
                  !dataBank ||
                  bank?.notOperational ||
                  bank?.line2Burned
                }
                decimalScale={3}
                thousandSeparator={true}
              ></FormNumeric2>
            </Grid>
            <Grid item xs={3}>
              <FormCheckBox
                name={"line2Burned"}
                label={"Not Operational"}
                value={bank?.line2Burned}
                onChange={(e: any) => {
                  handleBlurBank(e, true);
                }}
                showSkeleton={isLoadingItem}
                disabled={disableStudy || !dataBank || bank?.notOperational}
              ></FormCheckBox>
            </Grid>
          </>
        )}
        {data?.heatingElectricResistancePhase ===
          HeatingElectricResistancePhase.ThreePhase && (
          <>
            <Grid item xs={2} textAlign={"right"}>
              <Typography variant="subtitle2" pt={2}>
                Line 3
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <FormNumeric2
                name={"actualOperationalVoltsLine3"}
                label={"Volts"}
                value={bank?.actualOperationalVoltsLine3 || ""}
                onChange={(e: any) => {
                  handleChangeBank(bank, e);
                }}
                onBlur={(e: any) => {
                  handleBlurBank(e);
                }}
                showSkeleton={isLoadingItem || refreshBank}
                disabled={
                  disableStudy ||
                  !dataBank ||
                  bank?.notOperational ||
                  bank?.line3Burned
                }
                decimalScale={3}
                thousandSeparator={true}
              ></FormNumeric2>
            </Grid>
            <Grid item xs={3}>
              <FormNumeric2
                name={"actualOperationalAmpsLine3"}
                label={"Amps"}
                value={bank?.actualOperationalAmpsLine3 || ""}
                onChange={(e: any) => {
                  handleChangeBank(bank, e);
                }}
                onBlur={(e: any) => {
                  handleBlurBank(e);
                }}
                showSkeleton={isLoadingItem || refreshBank}
                disabled={
                  disableStudy ||
                  !dataBank ||
                  bank?.notOperational ||
                  bank?.line3Burned
                }
                decimalScale={3}
                thousandSeparator={true}
              ></FormNumeric2>
            </Grid>
            <Grid item xs={3}>
              <FormCheckBox
                name={"line3Burned"}
                label={"Not Operational"}
                value={bank?.line3Burned}
                onChange={(e: any) => {
                  handleBlurBank(e, true);
                }}
                showSkeleton={isLoadingItem}
                disabled={disableStudy || !dataBank || bank?.notOperational}
              ></FormCheckBox>
            </Grid>
          </>
        )}
        <Grid item xs={12}>
          {dataBank &&
            bank?.overAmpningWarning &&
            bank?.overAmpningWarning !== "" && (
              <Alert mb={4} severity="error">
                {bank?.overAmpningWarning}
              </Alert>
            )}
        </Grid>
      </Grid>
    </>
  );
};

export default ElectricCoilBank;
