import { useEffect } from "react";

const useInterval = (callback: Function, delay: number) => {
  useEffect(() => {
    const intervalId = setInterval(callback, delay);
    return () => {
      clearInterval(intervalId);
    };
  }, [callback, delay]);
};

export default useInterval;
