import React from "react";
import styled from "@emotion/styled";
import { Typography as MuiTypography } from "@mui/material";

import { rgba } from "polished";
import { css } from "@emotion/react";

const illustrationPercentageStyle = (props: any) => css`
  ${props.illustration &&
  props.theme.palette.mode !== "dark" &&
  `
  color: ${rgba(props.theme.palette.primary.main, 0.85)};
`}
`;

const Percentage = styled(MuiTypography)<{
  percentagecolor: string;
  illustration?: string;
}>`
  span {
    color: ${(props) => props.percentagecolor};
    font-weight: ${(props) => props.theme.typography.fontWeightBold};
    /* background: ${(props) => rgba(props.percentagecolor, 0.1)}; */
    padding: 2px;
    border-radius: 3px;
    margin-right: ${(props) => props.theme.spacing(2)};
  }

  ${illustrationPercentageStyle}
`;

interface Props {
  text: string;
  percentagecolor: string;
}

const ColorText = (props: Props) => {
  const { text, percentagecolor } = props;
  return (
    <Percentage
      variant="subtitle2"
      color="textSecondary"
      percentagecolor={percentagecolor}
    >
      <span>{text}</span>
    </Percentage>
  );
};

export default ColorText;
