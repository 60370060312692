import Spreadsheet, {
  ISpreadsheetColumn,
} from "src/components/spreadsheet/Spreadsheet";
import useOutletReport from "../../hooks/useOutletReport";
import { yellow } from "@mui/material/colors";
import {
  IKeyValue,
  IOutletReport,
  IOutletReportComponent,
  IOutletReportItem,
  IPsychrometricArgs,
  IPsychrometricUI,
} from "src/ts/interfaces";
import SpreadSheetCell from "src/components/spreadsheet/SpreadSheetCell";
import { Checkbox, Typography, useTheme } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import SpreadsheetNumericInput2 from "src/components/spreadsheet/SpreadsheetNumericInput2";
import {
  useAsyncQuery,
  useLog,
  usePsychrometric,
  useSystemOfMeasurement,
} from "src/hooks";
import meterDeviceService from "src/services/catalogs/meterDeviceService";
import { NumericFormat } from "react-number-format";
import useLocalTheme from "src/hooks/useLocalTheme";
import TerminalDevicePopup from "src/pages/studies/components/TerminalDevicePopup";
import outletReportItemService from "src/services/study/outletReportItemService";
import { IProjectReport } from "src/ts/interfaces/project/projectDto";
import outletReportComponentService from "src/services/study/outletReportComponentService";
import outletReportService from "src/services/study/outletReportService";
import DialogMessagePopup from "src/components/DialogMessagePopup";

interface Props {
  outletReport: IOutletReport;
  //reportType: IReportType | null | undefined;
  dataReport: IProjectReport;
  setOutletReport: (outletReport: IOutletReport) => void;
  setComponents: (components: IOutletReportComponent[]) => void;
  components: IOutletReportComponent[];
  disableStudy: boolean;
}

const OutletReportTransposeGrid = ({
  outletReport: values,
  dataReport,
  setOutletReport,
  components,
  setComponents,
  disableStudy,
}: Props) => {
  const { data: meterDeviceKeyValue } = useAsyncQuery<
    IKeyValue<number, string>[]
  >(meterDeviceService.getKeyValues, { immediate: true });

  const { psychrometric } = usePsychrometric();
  const { log } = useLog();
  const {
    outletReportComponentId,
    componentValues,
    setComponentValues,
    items,
    setItems,
    isLoading,
    getAllOutletReportItems,
  } = useOutletReport();
  const { systemOfMeasurement } = useSystemOfMeasurement(true);
  const { theme: localTheme } = useLocalTheme();
  const [psychrometricUi, setPsychrometricUi] =
    useState<IPsychrometricUI | null>(null);
  const [showTerminalDevicePopup, setShowTerminalDevicePopup] = useState(false);
  const theme = useTheme();
  const [selectedItem, setSelectedItem] = useState<{
    e: any;
    row: IOutletReportItem;
    index: number;
  } | null>(null);
  const [
    showUpdateMeterDeviceConfirmation,
    setShowUpdateMeterDeviceConfirmation,
  ] = useState(false);

  useEffect(() => {
    if (values !== null && values?.id === 0) return;

    let psychrometric: IPsychrometricUI = {
      RelativeHumidity: false,
      humidityMeasuredAs: "",
      WetBulbF: false,
      DewPoint: false,
      humidityValue: null,
    };

    if (values.dryBulbFId === 1) {
      psychrometric.RelativeHumidity = true;
      psychrometric.humidityMeasuredAs = "RH";
    }
    if (values.dryBulbFId === 2) {
      psychrometric.WetBulbF = true;
      psychrometric.humidityMeasuredAs = "WB";
    }
    if (values.dryBulbFId === 3) {
      psychrometric.DewPoint = true;
      psychrometric.humidityMeasuredAs = "DP";
    }
    setPsychrometricUi(psychrometric);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.dryBulbFId]);

  const mode = useMemo(
    () => (values.isComplete ? "read" : "read&Write"),
    [values.isComplete]
  );
  const readOnly = useMemo(() => disableStudy, [disableStudy]);
  const transposed = true;

  const [selectedRow, setSelectedRow] = useState<{
    row: IOutletReportItem;
    index: number;
  } | null>(null);

  const changeTerminalDevice = async (
    e: any,
    row: IOutletReportItem,
    index: number,
    outletMaster: IOutletReport
  ) => {
    const modifiedRow: IOutletReportItem = {
      ...row,
      terminalDevice: e.target.checked,
    };
    if (row.terminalDevice === true) modifiedRow.terminalDevice = false;
    else {
      modifiedRow.terminalDevice = true;
      setShowTerminalDevicePopup(true);
    }

    setSelectedRow({ row: modifiedRow, index });

    const newSet = items.map((item: IOutletReportItem, i: number) =>
      i === index ? modifiedRow : item
    );
    setItems(newSet);
    await outletReportItemService.updateItem(modifiedRow);
  };

  const getPsychrometric = async (row: IOutletReportItem) => {
    let humidityMeasuredAs = "";
    let humidityValue = null;

    if (psychrometricUi?.RelativeHumidity) {
      humidityMeasuredAs = "RH";
      humidityValue = row.relHum;
    } else if (psychrometricUi?.DewPoint) {
      humidityMeasuredAs = "DP";
      humidityValue = row.dewPoint;
    } else if (psychrometricUi?.WetBulbF) {
      humidityMeasuredAs = "WB";
      humidityValue = row.wetBulb;
    }

    const psychometricsArgs: IPsychrometricArgs = {
      projectId: values.projectId,
      humidityMeasuredAs: humidityMeasuredAs,
      humidityValue: humidityValue,
      dryBulbF: row.temperatureDB,
      totalCFM: row.finalCorrectedAirflowACFM ?? 0,
    };

    if (
      psychometricsArgs.humidityValue !== null &&
      psychometricsArgs.dryBulbF !== null &&
      psychometricsArgs.totalCFM !== null &&
      values?.heat
    ) {
      const psychrometricRes = await psychrometric.getByProject(
        psychometricsArgs
      );

      return Promise.resolve(psychrometricRes);
    } else {
      return Promise.resolve(null);
    }
  };

  const calcBTUHExtra = async (row: IOutletReportItem) => {
    const psychrometricRes = await getPsychrometric(row);
    if (psychrometricRes === null)
      return {
        atmosphericPress: null,
        outletReportItem: row,
      };

    if (
      psychrometricRes?.psychrometric.message != null &&
      psychrometricRes?.psychrometric.enthalpy === 0
    ) {
      log.warning(psychrometricRes?.psychrometric.message);
    } else if (psychrometricRes?.psychrometric.message != null) {
      log.info(psychrometricRes?.psychrometric.message);
    }

    const updateBtuItem: IOutletReportItem = {
      ...row,
      btuh: psychrometricRes?.btuh ?? null,
    };

    try {
      await outletReportItemService.updateItem(updateBtuItem);
    } catch (error: any) {
      log.error(error?.message?.exceptionMessage ?? "Something went wrong");
    }

    return {
      atmosphericPress: psychrometricRes?.psychrometric?.atmosphericPress,
      outletReportItem: updateBtuItem,
    };
  };

  const updateActualCFM = async (row: IOutletReportItem, index: number) => {
    const res = await outletReportItemService.updateItem(row);
    const updatedItem = {
      ...row,
      ...res.data,
    };

    try {
      const calc = await calcBTUHExtra(updatedItem);

      if (calc?.outletReportItem) {
        const newItems = items.map((item, i) =>
          i === index ? calc?.outletReportItem : item
        );
        setItems(newItems);
      } else {
        const newItems = items.map((item, i) =>
          i === index ? updatedItem : item
        );
        setItems(newItems);
      }

      setOutletReport({
        ...values,
        useSiteSpecificDefault: calc?.atmosphericPress ?? null,
      });

      await outletReportService.update(values.id, values);

      const resLowestGriller =
        await outletReportComponentService.getLowesGriller(
          outletReportComponentId
        );

      setComponentValues(resLowestGriller.data);

      const updatedComponents = components.map((comp) =>
        comp.id === resLowestGriller.data.id ? resLowestGriller.data : comp
      );

      setComponents(updatedComponents);

      await getAllOutletReportItems(values.id);
    } catch (error: any) {
      log.error(error?.message?.exceptionMessage ?? "Something went wrong");
    }
  };

  const changeMeterDevice = (e: any, row: IOutletReportItem, index: number) => {
    setSelectedItem({ e, row, index });
    setShowUpdateMeterDeviceConfirmation(true);
  };

  const correctForBarometrically = async (
    e: any,
    row: IOutletReportItem,
    index: number
  ) => {
    const value = e.target.value as boolean;
    const modifiedRow = { ...row, barometricCorrectionRequired: value };
    await updateActualCFM(modifiedRow, index);
  };

  const calcBTUHHandler = async (
    e: any,
    row: IOutletReportItem,
    indexRow: number
  ) => {
    const modifiedRow = {
      ...row,
      [e.target.name]: parseFloat(e.target.value),
    };

    const psychrometricRes = await getPsychrometric(modifiedRow);
    if (psychrometricRes === null) {
      const modifiedItems = items?.map((item, index) =>
        index === indexRow ? updateRes.data : item
      );

      setItems(modifiedItems);
      return;
    }

    if (
      psychrometricRes?.psychrometric.message != null &&
      psychrometricRes?.psychrometric.enthalpy === 0
    ) {
      log.warning(psychrometricRes?.psychrometric.message);
    } else if (psychrometricRes?.psychrometric.message != null) {
      log.info(psychrometricRes?.psychrometric.message);
    }

    setOutletReport({
      ...values,
      useSiteSpecificDefault:
        psychrometricRes?.psychrometric?.atmosphericPress ?? null,
    });

    const updateBtuItem: IOutletReportItem = {
      ...modifiedRow,
      btuh: psychrometricRes?.btuh ?? null,
    };

    const updateRes = await outletReportItemService.updateItem(updateBtuItem);

    const modifiedItems = items?.map((item, index) =>
      index === indexRow ? updateRes.data : item
    );

    setItems(modifiedItems);

    log.info("Item saved successfully");
  };

  const correctForTemperature = async (
    e: any,
    row: IOutletReportItem,
    index: number
  ) => {
    const value = e.target.value as boolean;
    const modifiedRow = { ...row, temperatureCorrectionRequired: value };
    await updateActualCFM(modifiedRow, index);
  };

  const updateActualCFMComplete = async (
    e: any,
    row: IOutletReportItem,
    index: number
  ) => {
    const modifiedRow: IOutletReportItem = {
      ...row,
      [e.target.name]: parseFloat(e.target.value),
    };
    await updateActualCFM(modifiedRow, index);
  };

  const selectKey = async (row: IOutletReportItem, index: number) => {
    const lowestGrillerId =
      componentValues.lowestGrillerId === row.id ? 0 : row.id;

    const updatedComponent = {
      ...componentValues,
      lowestGrillerId: lowestGrillerId,
    };

    const updatedComponents = components.map((comp) =>
      comp.id === updatedComponent.id ? updatedComponent : comp
    );

    setComponents(updatedComponents);
    setComponentValues(updatedComponent);

    await outletReportComponentService.update(updatedComponent);
  };

  const getDimensionText = (row: IOutletReportItem) => {
    if (row.rectangle) {
      return `${row.width ?? ""}, ${row.heigth ?? ""}`;
    } else {
      return `${row.width ?? ""}`;
    }
  };

  const paintYellow = (row: IOutletReportItem) =>
    row.id === componentValues.lowestGrillerId ||
    (componentValues.lowestGriller === row.finalCorrectedAirflowACFM &&
      componentValues.lowestGrillerId === 0 &&
      row.finalCorrectedAirflowACFM != null &&
      row.terminalDevice !== true);

  const highlight = (row: IOutletReportItem) => {
    return paintYellow(row)
      ? localTheme === "DARK"
        ? theme.palette.primary.dark
        : yellow[200]
      : undefined;
  };

  const columns: ISpreadsheetColumn[] = [
    {
      name: "terminalDevice",
      type: "custom",
      label: "Missing / Extra",
      render: (
        row: IOutletReportItem,
        col: ISpreadsheetColumn,
        index: number
      ) => {
        return (
          <SpreadSheetCell transposed={transposed} align="left">
            <>
              {mode === "read&Write" ? (
                <>
                  <Checkbox
                    checked={row.terminalDevice}
                    onChange={async (e: any) => {
                      await changeTerminalDevice(e, row, index, values);
                    }}
                    disabled={readOnly}
                  />
                  {row.extra && row.terminalDevice ? "Extra" : ""}
                  {row.missing && row.terminalDevice ? "Missing" : ""}
                </>
              ) : true ? (
                "Yes"
              ) : (
                "No"
              )}
            </>
          </SpreadSheetCell>
        );
      },
    },
    {
      name: "index",
      type: "index",
      label: "Number #",
    },
    {
      name: "roomAreaServed",
      type: "string",
      label: "Room or Area Served",
    },
    {
      name: "diffuserGrilleModel",
      type: "string",
      label: "Diffuser / Grille Model",
      color: "success.main",
      hide: !values.diffuserGrilleModel,
    },
    {
      name: "type",
      type: "custom",
      label: "Diffuser / Grille Type",
      color: theme.palette.success.main,
      render: (
        row: IOutletReportItem,
        col: ISpreadsheetColumn,
        index: number
      ) => {
        return (
          <SpreadSheetCell transposed={transposed}>
            <Typography mt={0.8} paddingLeft={1.8} color="success.main">
              {row.circle && <>Round</>}
              {row.rectangle && <>Rectangle</>}
            </Typography>
          </SpreadSheetCell>
        );
      },
    },
    {
      name: "dimension",
      type: "custom",
      label: "Diffuser / Grille Dimension(s)",
      color: "success.main",
      render: (
        row: IOutletReportItem,
        col: ISpreadsheetColumn,
        index: number
      ) => {
        return (
          <SpreadSheetCell transposed={transposed}>
            <Typography mt={0.8} paddingLeft={1.8} color="success.main">
              {getDimensionText(row)}
            </Typography>
          </SpreadSheetCell>
        );
      },
    },
    {
      name: "neckType",
      type: "custom",
      label: "Neck Type",
      color: "success.main",
      hide: !values.diffuserGrillerNeckSize,
      render: (
        row: IOutletReportItem,
        col: ISpreadsheetColumn,
        index: number
      ) => {
        return (
          <SpreadSheetCell transposed={transposed}>
            <Typography mt={0.8} paddingLeft={1.8} color="success.main">
              {row.neckCircle && <>Round</>}
              {row.neckRectangle && <>Rectangle</>}
            </Typography>
          </SpreadSheetCell>
        );
      },
    },
    {
      name: "neckdimension",
      type: "custom",
      label: "Neck Dimension(s)",
      hide: !values.diffuserGrillerNeckSize,
      color: "success.main",
      render: (
        row: IOutletReportItem,
        col: ISpreadsheetColumn,
        index: number
      ) => {
        return (
          <SpreadSheetCell transposed={transposed}>
            <Typography mt={0.8} paddingLeft={1.8} color="success.main">
              {`${row.neckWidth ?? ""}`}
              {`${!!row.neckRectangle ? ", " : " "}`}
              {row.neckHeigth}
            </Typography>
          </SpreadSheetCell>
        );
      },
    },
    {
      name: "akColumn",
      label: "AK Factor/" + systemOfMeasurement.get("sqft"),
      type: "custom",
      render: (
        row: IOutletReportItem,
        col: ISpreadsheetColumn,
        index: number
      ) => {
        return (
          <SpreadSheetCell borderColor="" transposed={transposed}>
            <SpreadsheetNumericInput2
              value={row.akColumn}
              onChange={(e: any) => {
                const modifiedRow: IOutletReportItem = {
                  ...row,
                  [e.target.name]: parseFloat(e.target.value),
                };
                updateActualCFM(modifiedRow, index);
              }}
              name="akColumn"
              decimalScale={2}
              maxValue={1000}
              disabled={values.isComplete || readOnly}
              mode={mode}
            />
          </SpreadSheetCell>
        );
      },
    },
    {
      name: "meterDeviceId",
      label: "Meter/Device",
      type: "select",
      items: meterDeviceKeyValue,
      disabled: readOnly,
      mode: mode,
      onChange: async (
        row: IOutletReportItem,
        e: any,
        index: number
      ): Promise<void> => {
        changeMeterDevice(e, row, index);
      },
    },
    {
      name: "barometricCorrectionRequired",
      type: "checkBox",
      label: "Correct for Barometric",
      align: "left",
      disabled: readOnly,
      mode: mode,
      onChange: async (
        row: IOutletReportItem,
        e: any,
        index: number
      ): Promise<void> => {
        await correctForBarometrically(e, row, index);
      },
    },
    {
      name: "temperatureCorrectionRequired",
      type: "checkBox",
      label: "Correct for Temperature",
      align: "left",
      disabled: readOnly,
      mode: mode,
      hide: !values.temperatureSensibleDryBulb,
      onChange: async (
        row: IOutletReportItem,
        e: any,
        index: number
      ): Promise<void> => {
        await correctForTemperature(e, row, index);
      },
    },
    {
      name: "temperatureDB",
      type: "numericInput",
      label:
        "Diffuser Temperature DB " + systemOfMeasurement.get("temperature"),
      // borderColor: theme.palette.info.dark,
      decimalScale: 3,
      maxValue: 1000000000,
      hide: !values.temperatureSensibleDryBulb,
      disabled: readOnly,
      mode: mode,
      onChange: async (
        row: IOutletReportItem,
        e: any,
        index: number
      ): Promise<void> => {
        const modifiedRow: IOutletReportItem = {
          ...row,
          temperatureDB: parseFloat(e.target.value),
        };
        await updateActualCFM(modifiedRow, index);
      },
    },

    {
      name: "wetBulb",
      type: "numericInput",
      label: "Wet Bulb " + systemOfMeasurement.get("temperature"),
      // borderColor: theme.palette.info.dark,
      decimalScale: 3,
      maxValue: 1000000000,
      hide: !(values.heat && psychrometricUi?.WetBulbF),
      disabled: readOnly,
      mode: mode,
      onChange: async (
        row: IOutletReportItem,
        e: any,
        index: number
      ): Promise<void> => {
        await calcBTUHHandler(e, row, index);
      },
    },
    {
      name: "dewPoint",
      type: "numericInput",
      label: "Dew Point",
      // borderColor: theme.palette.info.dark,
      decimalScale: 3,
      maxValue: 1000000000,
      hide: !(values.heat && psychrometricUi?.DewPoint),
      disabled: values.isComplete,
      mode: mode,
      onChange: async (
        row: IOutletReportItem,
        e: any,
        index: number
      ): Promise<void> => {
        await calcBTUHHandler(e, row, index);
      },
    },

    {
      name: "relHum",
      type: "numericInput",
      label: "Relative Humidity %",
      //borderColor: theme.palette.info.dark,
      decimalScale: 3,
      maxValue: 1000000000,
      hide: !(values.heat && psychrometricUi?.RelativeHumidity),
      disabled: values.isComplete,
      mode: mode,
      onChange: async (
        row: IOutletReportItem,
        e: any,
        index: number
      ): Promise<void> => {
        await calcBTUHHandler(e, row, index);
      },
    },
    {
      name: "sensibleHeat",
      type: "numeric",
      label: "Sensible Heat",
      decimalScale: 2,
      thousandSeparator: true,
      color: "success.main",
      hide: !values.heat,
    },
    {
      name: "btuh",
      type: "numeric",
      label: "Total Heat",
      decimalScale: 2,
      thousandSeparator: true,
      color: "success.main",
      hide: !values.heat,
    },
    {
      name: "requiredVelocity",
      type: "custom",
      label: "Required Velocity " + systemOfMeasurement.get("fpm"),
      color: "success.main",
      render: (
        row: IOutletReportItem,
        col: ISpreadsheetColumn,
        index: number
      ) => {
        return (
          <SpreadSheetCell
            transposed={transposed}
            backgroundColor={highlight(row)}
          >
            <Typography mt={0.8} paddingLeft={1.8}>
              <NumericFormat
                value={row.requiredVelocity}
                displayType="text"
                decimalScale={3}
                thousandSeparator
                valueIsNumericString
                fixedDecimalScale
                style={{ color: theme.palette.success.main }}
              />
            </Typography>
          </SpreadSheetCell>
        );
      },
    },
    {
      name: "actualVelocity",
      type: "custom",
      label: "Actual Velocity " + systemOfMeasurement.get("fpm"),
      //borderColor: theme.palette.info.dark,
      render: (
        row: IOutletReportItem,
        col: ISpreadsheetColumn,
        index: number
      ) => {
        return (
          <SpreadSheetCell
            transposed={transposed}
            backgroundColor={highlight(row)}
            // borderColor={
            //   row.meterDeviceId === 2 ? undefined : theme.palette.info.dark
            // }
          >
            <SpreadsheetNumericInput2
              value={row.actualVelocity}
              onChange={(e: any) => {
                const modifiedRow: IOutletReportItem = {
                  ...row,
                  actualVelocity: parseFloat(e.target.value),
                };
                updateActualCFM(modifiedRow, index);
              }}
              name="actualVelocity"
              decimalScale={3}
              maxValue={100000}
              disabled={row.meterDeviceId === 2 || readOnly}
              mode={mode}
            />
          </SpreadSheetCell>
        );
      },
    },
    {
      name: "requiredCFM",
      type: "custom",
      label: "Required " + systemOfMeasurement.get("cfm"),
      color: theme.palette.info.dark,
      render: (
        row: IOutletReportItem,
        col: ISpreadsheetColumn,
        index: number
      ) => {
        return (
          <SpreadSheetCell
            transposed={transposed}
            backgroundColor={highlight(row)}
          >
            <Typography mt={0.8} paddingLeft={1.8}>
              <NumericFormat
                value={row.requiredCFM}
                displayType="text"
                decimalScale={2}
                thousandSeparator
                valueIsNumericString
                fixedDecimalScale
              />
            </Typography>
          </SpreadSheetCell>
        );
      },
    },
    {
      name: "actualCFM",
      type: "custom",
      label: systemOfMeasurement.get("cfm") + " Read",
      color: theme.palette.info.dark,
      render: (
        row: IOutletReportItem,
        col: ISpreadsheetColumn,
        index: number
      ) => {
        return (
          <SpreadSheetCell
            transposed={transposed}
            backgroundColor={highlight(row)}
            // borderColor={theme.palette.info.dark}
          >
            <SpreadsheetNumericInput2
              value={row.actualCFM}
              name="actualCFM"
              decimalScale={3}
              maxValue={100000}
              disabled={row.meterDeviceId !== 2 || values.isComplete}
              onChange={async (e) => {
                const modifiedRow: IOutletReportItem = {
                  ...row,
                  actualCFM: parseFloat(e.target.value),
                };

                await updateActualCFM(modifiedRow, index);
              }}
              mode={mode}
            />
          </SpreadSheetCell>
        );
      },
    },
    {
      name: "minimumOutletCFMRecorded",
      type: "custom",
      label: "Minimum " + systemOfMeasurement.get("cfm") + " Recorded",
      // borderColor: theme.palette.info.dark,
      color: theme.palette.info.dark,
      render: (
        row: IOutletReportItem,
        col: ISpreadsheetColumn,
        index: number
      ) => {
        return (
          <SpreadSheetCell
            transposed={transposed}
            backgroundColor={highlight(row)}
            //borderColor={theme.palette.info.dark}
          >
            <SpreadsheetNumericInput2
              value={row.minimumOutletCFMRecorded}
              onChange={(e: any) => updateActualCFMComplete(e, row, index)}
              name="minimumOutletCFMRecorded"
              decimalScale={3}
              maxValue={1000000}
              disabled={values.isComplete || row.meterDevice.name !== "Hood"}
              mode={mode}
              placeholder="0.000"
            />
          </SpreadSheetCell>
        );
      },
    },
    {
      name: "actualMinimumVelocity",
      type: "custom",
      label: "Actual Minimum Velocity",
      // borderColor: theme.palette.info.dark,
      color: theme.palette.info.dark,
      render: (
        row: IOutletReportItem,
        col: ISpreadsheetColumn,
        index: number
      ) => {
        return (
          <SpreadSheetCell
            transposed={transposed}
            backgroundColor={highlight(row)}
          >
            <SpreadsheetNumericInput2
              value={row.actualMinimumVelocity}
              onChange={(e: any) => updateActualCFMComplete(e, row, index)}
              name="actualMinimumVelocity"
              decimalScale={3}
              maxValue={1000000}
              disabled={row.meterDeviceId === 2 || readOnly}
              mode={mode}
            />
          </SpreadSheetCell>
        );
      },
    },
    {
      name: "finalCorrectedAirflowACFM",
      type: "custom",
      label: "Final Corrected Airflow " + systemOfMeasurement.get("acfm"),
      render: (
        row: IOutletReportItem,
        col: ISpreadsheetColumn,
        index: number
      ) => {
        return (
          <SpreadSheetCell
            transposed={transposed}
            backgroundColor={highlight(row)}
          >
            <Typography mt={0.8} paddingLeft={1.8}>
              <NumericFormat
                value={row.finalCorrectedAirflowACFM}
                displayType="text"
                decimalScale={2}
                thousandSeparator
                fixedDecimalScale
              />
            </Typography>
          </SpreadSheetCell>
        );
      },
    },
    {
      name: "percentaje",
      type: "custom",
      label: "% of Design",
      render: (
        row: IOutletReportItem,
        col: ISpreadsheetColumn,
        index: number
      ) => {
        return (
          <SpreadSheetCell
            transposed={transposed}
            backgroundColor={highlight(row)}
          >
            <Typography mt={0.8} paddingLeft={1.8}>
              <NumericFormat
                value={row.percentaje}
                displayType="text"
                decimalScale={2}
                fixedDecimalScale
                suffix="%"
              />
            </Typography>
          </SpreadSheetCell>
        );
      },
    },
    {
      name: "neckdimension",
      type: "custom",
      label: "Min/Max " + systemOfMeasurement.get("cfm"),
      color: "success.main",
      render: (
        row: IOutletReportItem,
        col: ISpreadsheetColumn,
        index: number
      ) => {
        return (
          <SpreadSheetCell
            transposed={transposed}
            backgroundColor={highlight(row)}
          >
            <Typography mt={0.8} paddingLeft={1.8}>
              <NumericFormat
                value={row.min}
                displayType="text"
                decimalScale={1}
                fixedDecimalScale
              />
              /
              <NumericFormat
                value={row.max}
                displayType="text"
                decimalScale={1}
                fixedDecimalScale
              />
            </Typography>
          </SpreadSheetCell>
        );
      },
    },
    {
      name: "barometricCorrectionRequired",
      type: "custom",
      label: "Key Outlet",
      color: "success.main",
      render: (
        row: IOutletReportItem,
        col: ISpreadsheetColumn,
        index: number
      ) => {
        return (
          <SpreadSheetCell
            transposed={transposed}
            backgroundColor={highlight(row)}
            align="left"
          >
            <>
              {mode === "read&Write" ? (
                <>
                  <Checkbox
                    checked={paintYellow(row)}
                    onChange={(e: any) => {
                      selectKey(row, index);
                    }}
                    disabled={readOnly}
                  />
                </>
              ) : true ? (
                "Yes"
              ) : (
                "No"
              )}
            </>
          </SpreadSheetCell>
        );
      },
    },
    {
      name: "index2",
      type: "index",
      label: "Number #",
      align: "left",
      color: "success.main",
    },
    {
      name: "roomAreaServed",
      type: "string",
      label: "Room or Area Served",
      color: "success.main",
    },
  ];

  const onSelectMissingExtra = (row: IOutletReportItem, index: number) => {
    const newSet = items.map((item: IOutletReportItem, i: number) =>
      i === index ? row : item
    );

    setItems(newSet);
  };

  const confirmChangeMeterDevice = async () => {
    if (selectedItem === null) return;

    const { e, row, index } = selectedItem;

    const modifiedRow = { ...row, meterDeviceId: parseInt(e.target.value) };
    if (row.meterDeviceId === 2) {
      modifiedRow.actualVelocity = 0;
      modifiedRow.akColumn = 1;
    }

    await updateActualCFM(modifiedRow, index);
    setShowUpdateMeterDeviceConfirmation(false);
  };

  return (
    <>
      <Spreadsheet
        items={items}
        setItems={setItems}
        cols={columns}
        transposed={true}
        defaultRowPerPage={10}
        rowsPerPageOptions={[10]}
        showRowTotals={false}
        totals={false}
        showSkeleton={isLoading}
      />
      <TerminalDevicePopup
        value={""}
        isDialogOpen={showTerminalDevicePopup}
        setIsDialogOpen={setShowTerminalDevicePopup}
        onConfirm={onSelectMissingExtra}
        selectedRow={selectedRow}
        reportTypeId={dataReport?.reportTypeId}
        reportId={dataReport?.reportId}
        projectId={dataReport?.projectId}
      />
      <DialogMessagePopup
        title="Important"
        text={
          "This action will modify your current Item's data. Are you certain you want continue?"
        }
        showPopup={showUpdateMeterDeviceConfirmation}
        setShowPopup={setShowUpdateMeterDeviceConfirmation}
        onSave={confirmChangeMeterDevice}
        onCancel={() => {
          setShowUpdateMeterDeviceConfirmation(false);
        }}
        isSubmitting={false}
      />
    </>
  );
};

export default OutletReportTransposeGrid;
