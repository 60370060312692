import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import Chart from "react-chartjs-2";
import { green, red, blue, grey } from "@mui/material/colors";
import { Card as MuiCard, CardContent, Grid, Typography } from "@mui/material";
import { spacing } from "@mui/system";
import { technicianQuizExamService } from "src/services";
import { ITechnicianQuizExamResults } from "src/ts/interfaces";
import { useLog } from "src/hooks";

interface ITestChartProps {
  quizId: number;
}

const Card = styled(MuiCard)(spacing);

const ChartWrapper = styled.div`
  height: 182px;
  position: relative;
`;

const DoughnutInner = styled.div`
  width: 100%;
  position: absolute;
  top: 50%;
  left: 0;
  margin-top: -22px;
  text-align: center;
  z-index: 0;
`;

const TestChart = ({ quizId }: ITestChartProps) => {
  const { log } = useLog();

  const [results, setResults] = useState<ITechnicianQuizExamResults>({
    totalTechnicians: 0,
    completedTest: 0,
    passTest: 0,
    failTest: 0,
  });
  const [data, setData] = useState<any>({
    labels: ["Completed"],
    datasets: [
      {
        data: [0, 100],
        backgroundColor: [blue[800], grey[200]],
        borderWidth: 5,
      },
    ],
  });

  const [dataTestPassed, setDataTestPassed] = useState<any>({
    labels: ["Completed"],
    datasets: [
      {
        data: [0, 100],
        backgroundColor: [green[500], grey[200]],
        borderWidth: 5,
      },
    ],
  });

  const [dataTestFailed, setDataTestFailed] = useState<any>({
    labels: ["Completed"],
    datasets: [
      {
        data: [0, 100],
        backgroundColor: [red[500], grey[200]],
        borderWidth: 5,
      },
    ],
  });

  const [dataNumberOfTest, setDataNumberOfTest] = useState<any>({
    labels: ["Completed"],
    datasets: [
      {
        data: [0],
        backgroundColor: [grey[200]],
        borderWidth: 5,
      },
    ],
  });

  useEffect(() => {
    const getData = async () => {
      try {
        const request = await technicianQuizExamService.getChartById(quizId);
        if (request.data != null) setResults(request.data);

        const item = {
          labels: ["Completed"],
          datasets: [
            {
              data: [
                request.data?.completedTest ?? 0,
                100 - request.data?.completedTest ?? 0,
              ],
              backgroundColor: [blue[800], grey[200]],
              borderWidth: 5,
            },
          ],
        };

        const pased = {
          labels: ["Completed"],
          datasets: [
            {
              data: [
                request.data?.passTest ?? 0,
                100 - request.data?.passTest ?? 0,
              ],
              backgroundColor: [green[500], grey[200]],
              borderWidth: 5,
            },
          ],
        };

        const failed = {
          labels: ["Completed"],
          datasets: [
            {
              data: [
                request.data?.failTest ?? 0,
                100 - request.data?.failTest ?? 0,
              ],
              backgroundColor: [red[500], grey[200]],
              borderWidth: 5,
            },
          ],
        };

        const number = {
          labels: ["Completed"],
          datasets: [
            {
              data: [
                results === undefined ? 0 : request.data?.totalTechnicians ?? 0,
              ],
              backgroundColor: [grey[200]],
              borderWidth: 5,
            },
          ],
        };

        setData(item);
        setDataTestPassed(pased);
        setDataTestFailed(failed);
        setDataNumberOfTest(number);
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      }
    };

    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quizId]);

  const options = {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
    },
    cutout: "80%",
  };

  return (
    <Card>
      <CardContent>
        <Grid container>
          <Grid item xs={3}>
            <ChartWrapper>
              <DoughnutInner>
                <Typography variant="h4">{results.completedTest}%</Typography>
                <Typography variant="caption">Completed</Typography>
              </DoughnutInner>
              <Chart type="doughnut" data={data} options={options} />
            </ChartWrapper>
          </Grid>
          <Grid item xs={3}>
            <ChartWrapper>
              <DoughnutInner>
                <Typography variant="h4">{results.passTest}%</Typography>
                <Typography variant="caption">Passed</Typography>
              </DoughnutInner>
              <Chart type="doughnut" data={dataTestPassed} options={options} />
            </ChartWrapper>
          </Grid>
          <Grid item xs={3}>
            <ChartWrapper>
              <DoughnutInner>
                <Typography variant="h4">{results.failTest}%</Typography>
                <Typography variant="caption">Failed</Typography>
              </DoughnutInner>
              <Chart type="doughnut" data={dataTestFailed} options={options} />
            </ChartWrapper>
          </Grid>
          <Grid item xs={3}>
            <ChartWrapper>
              <DoughnutInner>
                <Typography variant="h4">{results.totalTechnicians}</Typography>
                <Typography variant="caption">Number of Tests</Typography>
              </DoughnutInner>
              <Chart
                type="doughnut"
                data={dataNumberOfTest}
                options={options}
              />
            </ChartWrapper>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default TestChart;
