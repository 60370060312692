import axios from "src/utils/axios";
import { IVelgridMatrix } from "src/ts/interfaces/study/velgridMatrix";

const getById = (id: number) => {
  return axios.get<IVelgridMatrix>(`VelgridMatrix/${id}`);
};

const update = (id: number, outletMaster: IVelgridMatrix) => {
  return axios.put<IVelgridMatrix>(
    `VelgridMatrix/${id}?recalculate=true`,
    outletMaster
  );
};

const velgridMatrixService = {
  getById,
  update,
};

export default velgridMatrixService;
