import {
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemSecondaryAction,
  ListItemText,
  Stack,
  useTheme,
} from "@mui/material";
import { AddIcon, DeleteIcon } from "src/components/icons";
import useTowerStudy from "../../hooks/useTowerStudy";
import { IChillerTowerFanMotor } from "src/ts/interfaces/study/tower";
import { withStyles } from "@mui/styles";

const ListItemWithWiderSecondaryAction = withStyles({
  secondaryAction: {
    paddingRight: 80,
  },
})(ListItem);

interface Props {
  prefix?: string;
  showDelete?: boolean;
  showDuplicate?: boolean;
  onSelect?: (fanMotor: IChillerTowerFanMotor) => void;
  onDelete?: (fanMotor: IChillerTowerFanMotor) => void;
  onAdd?: (fanMotor: IChillerTowerFanMotor, index: number) => void;
  disableStudy: boolean;
}

const FanMotorList = ({
  prefix,
  showDelete,
  showDuplicate,
  onSelect,
  onDelete,
  onAdd,
  disableStudy,
}: Props) => {
  const { fanMotors, fanMotorValues } = useTowerStudy();
  const theme = useTheme();

  const selectedItemHandler = (fanMotor: IChillerTowerFanMotor) => {
    onSelect && onSelect(fanMotor);
  };

  const deleteItemHandler = (fanMotor: IChillerTowerFanMotor) => {
    onDelete && onDelete(fanMotor);
  };

  const addItemHandler = (fanMotor: IChillerTowerFanMotor, index: number) => {
    onAdd && onAdd(fanMotor, index);
  };

  return (
    <List
      component={Stack}
      direction="row"
      flexWrap={"wrap"}
      sx={{ "--List-item-endActionWidth": "100px" }}
    >
      {fanMotors &&
        fanMotors.map((fanMotor, index) => {
          return (
            <ListItemWithWiderSecondaryAction
              key={fanMotor.id}
              disablePadding
              sx={{
                borderColor: theme.palette.primary.main,
                borderWidth: "1px",
                borderStyle: "solid",
              }}
            >
              <ListItemButton
                selected={fanMotorValues?.id === fanMotor.id}
                onClick={() => selectedItemHandler(fanMotor)}
              >
                <ListItemText primary={`${prefix ? prefix : ""}${index + 1}`} />
              </ListItemButton>

              <ListItemSecondaryAction>
                <>
                  {showDuplicate &&
                    !disableStudy &&
                    index === fanMotors.length - 1 && (
                      <IconButton
                        aria-label="comments"
                        onClick={() => addItemHandler(fanMotor, index)}
                      >
                        <AddIcon />
                      </IconButton>
                    )}

                  {showDelete && !disableStudy && (
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      onClick={() => deleteItemHandler(fanMotor)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  )}
                </>
              </ListItemSecondaryAction>
            </ListItemWithWiderSecondaryAction>
          );
        })}
    </List>
  );
};

export default FanMotorList;
