import {
  Drawer,
  Grid,
  IconButton,
  Divider as MuiDivider,
  Typography,
} from "@mui/material";
import styled from "@emotion/styled";
import { spacing, useTheme } from "@mui/system";
import { ChevronLeft, ChevronRight, ExpandMore } from "@mui/icons-material";
import { TreeView } from "@mui/x-tree-view/TreeView";
import { ICalculator, IInputsCalculator } from "src/ts/interfaces/calculator";
import CalculatorTreeView from "./CalculatorTreeView";
const Divider = styled(MuiDivider)(spacing);

interface CalculatorPopUpProps {
  showCalculator: boolean;
  setShowCalculator: (val: boolean) => void;
}

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: "flex-start",
}));

const drawerWidth = 400;

const formulas: ICalculator[] = [
  {
    id: 0,
    name: "Airflow",
    type: "category",
    color: "#A2CFFF",
    input: [],
    subItems: [
      {
        id: 2,
        name: "Fan / Motor Pulley",
        type: "subcategory",
        color: "#C2DFFF",
        input: [],
        subItems: [
          {
            id: 3,
            name: "Find Motor Pulley Pitch Diameter with known Fan & Motor RPM", //'Find motor pitch diameter',
            description: "",
            input: [
              { name: "Fan RPM", value: "" },
              { name: "Motor RPM", value: "" },
              { name: "Fan Pulley Pitch Diameter", value: "" },
            ],
            calculation: function (inputs: IInputsCalculator[]) {
              return (
                (Number(inputs[0].value) / Number(inputs[1].value)) *
                Number(inputs[2].value)
              )
                .toFixed(2)
                .toString();
            },
            output: "",
            display: false,
            answer: "Motor Pulley Pitch Diameter =",
          },
          {
            id: 4,
            name: "Find Fan Pulley Pitch Diameter with known Fan & Motor RPM", //'Find fan pitch diameter',
            description: "",
            input: [
              { name: "Fan RPM", value: "" },
              { name: "Motor RPM", value: "" },
              { name: "Motor Pulley Pitch Diameter", value: "" },
            ],
            calculation: function (inputs: IInputsCalculator[]) {
              return (
                (Number(inputs[1].value) / Number(inputs[0].value)) *
                Number(inputs[2].value)
              )
                .toFixed(2)
                .toString();
            },
            output: "",
            display: false,
            answer: "Fan Pulley Pitch Diameter =",
          },
        ],
      },

      {
        id: 5,
        name: "Belt Length",
        type: "subcategory",
        color: "#C2DFFF",
        input: [],
        subItems: [
          {
            id: 6,
            name: "Find Pulley Belt Length",
            description: "",
            input: [
              { name: 'Shafts Center to Center Distance"', value: "" },
              { name: 'Large Pulley Diameter"', value: "" },
              { name: 'Small Pulley Diameter"', value: "" },
            ],
            calculation: function (inputs: IInputsCalculator[]) {
              return (
                1.57 *
                  (parseFloat(inputs[1].value) + parseFloat(inputs[2].value)) +
                2 * Number(inputs[0].value) +
                Math.pow(Number(inputs[1].value) - Number(inputs[2].value), 2) /
                  (4 * Number(inputs[0].value))
              )
                .toFixed(2)
                .toString();
            },
            output: "",
            display: false,
            answer: 'Calculated Belt Length" =',
          },
        ],
      },
      {
        id: 7,
        name: "% O/A °F",
        type: "subcategory",
        color: "#C2DFFF",
        input: [],
        subItems: [
          {
            id: 8,
            name: "Find % of Outside Air with known temperatures °F",
            description: "",
            input: [
              { name: "T °F/OA", value: "" },
              { name: "T °F/RA", value: "" },
              { name: "T °F/MA", value: "" },
              { name: "AMP Factor", value: "" },
            ],
            calculation: function (inputs: IInputsCalculator[]) {
              return (
                100 *
                Math.abs(
                  (Number(inputs[1].value) -
                    (Number(inputs[2].value) -
                      Number(inputs[3].value) / 3.14159)) /
                    (Number(inputs[1].value) - Number(inputs[0].value))
                )
              )
                .toFixed(2)
                .toString();
            },
            output: "",
            display: false,
            answer: "% O/A =",
          },
          {
            id: 9,
            name: "Find Mixed Air Temperature °F",
            description: "",
            input: [
              { name: "% O/A", value: "" },
              { name: "T °F/OA", value: "" },
              { name: "% R/A", value: "" },
              { name: "T °F/RA", value: "" },
              { name: "AMP Factor", value: "" },
            ],
            calculation: function (inputs: IInputsCalculator[]) {
              return (
                (Number(inputs[0].value) / 100) * Number(inputs[1].value) +
                (Number(inputs[2].value) / 100) * Number(inputs[3].value) +
                Number(inputs[4].value) / 3.14159
              )
                .toFixed(2)
                .toString();
            },
            output: "",
            display: false,
            answer: "T °F/MA =",
          },
        ],
      },
      {
        id: 10,
        name: "CFM",
        type: "subcategory",
        color: "#C2DFFF",
        input: [],
        subItems: [
          {
            id: 11,
            name: "Find CFM with known VP",
            description: "",
            input: [
              { name: "VP 2 (New)", value: "" },
              { name: "VP 1 (Old)", value: "" },
              { name: "CFM 1 (Old)", value: "" },
            ],
            calculation: function (inputs: IInputsCalculator[]) {
              return (
                Number(inputs[2].value) *
                Math.sqrt(Number(inputs[0].value) / Number(inputs[1].value))
              )
                .toFixed(2)
                .toString();
            },
            output: "",
            display: false,
            answer: "CFM 2 =",
          },
          {
            id: 12,
            name: "Find CFM with known SP",
            description: "",
            input: [
              { name: "SP 2 (New)", value: "" },
              { name: "SP 1 (Old)", value: "" },
              { name: "CFM 1 (Old)", value: "" },
            ],
            calculation: function (inputs: IInputsCalculator[]) {
              return (
                Number(inputs[2].value) *
                Math.sqrt(Number(inputs[0].value) / Number(inputs[1].value))
              )
                .toFixed(2)
                .toString();
            },
            output: "",
            display: false,
            answer: "CFM 2 =",
          },
          {
            id: 13,
            name: "Find CFM with known RPM",
            description: "",
            input: [
              { name: "RPM 2 (New)", value: "" },
              { name: "RPM 1 (Old)", value: "" },
              { name: "CFM 1 (Old)", value: "" },
            ],
            calculation: function (inputs: IInputsCalculator[]) {
              return (
                (Number(inputs[0].value) / Number(inputs[1].value)) *
                Number(inputs[2].value)
              )
                .toFixed(2)
                .toString();
            },
            output: "",
            display: false,
            answer: "CFM 2 =",
          },
          {
            id: 14,
            name: "Find CFM with known Motor BHP",
            description: "",
            input: [
              { name: "BHP 2 (New)", value: "" },
              { name: "BHP 1 (Old)", value: "" },
              { name: "CFM 1 (Old)", value: "" },
            ],
            calculation: function (inputs: IInputsCalculator[]) {
              return (
                Math.pow(
                  Number(inputs[0].value) / Number(inputs[1].value),
                  1 / 3
                ) * Number(inputs[2].value)
              )
                .toFixed(2)
                .toString();
            },
            output: "",
            display: false,
            answer: "CFM 2 =",
          },
        ],
      },
      {
        id: 15,
        name: "RPM",
        type: "subcategory",
        color: "#C2DFFF",
        input: [],
        subItems: [
          {
            id: 16,
            name: "Find RPM with known SP",
            description: "",
            input: [
              { name: "SP 2 (New)", value: "" },
              { name: "SP 1 (Old)", value: "" },
              { name: "RPM 1 (Old)", value: "" },
            ],
            calculation: function (inputs: IInputsCalculator[]) {
              return (
                Math.sqrt(Number(inputs[0].value) / Number(inputs[1].value)) *
                Number(inputs[2].value)
              )
                .toFixed(2)
                .toString();
            },
            output: "",
            display: false,
            answer: "RPM 2 =",
          },
          {
            id: 17,
            name: "Find RPM with known CFM",
            description: "",
            input: [
              { name: "CFM 2 (New)", value: "" },
              { name: "CFM 1 (Old)", value: "" },
              { name: "RPM 1 (Old)", value: "" },
            ],
            calculation: function (inputs: IInputsCalculator[]) {
              return (
                (Number(inputs[0].value) / Number(inputs[1].value)) *
                Number(inputs[2].value)
              )
                .toFixed(2)
                .toString();
            },
            output: "",
            display: false,
            answer: "RPM 2 =",
          },
          {
            id: 18,
            name: "Find RPM with known Motor BHP",
            description: "",
            input: [
              { name: "BHP 2 (New)", value: "" },
              { name: "BHP 1 (Old)  ", value: "" },
              { name: "RPM 1 (Old)", value: "" },
            ],
            calculation: function (inputs: IInputsCalculator[]) {
              return (
                Math.pow(
                  Number(inputs[0].value) / Number(inputs[1].value),
                  1 / 3
                ) * Number(inputs[2].value)
              )
                .toFixed(2)
                .toString();
            },
            output: "",
            display: false,
            answer: "RPM 2 =",
          },
          {
            id: 19,
            name: "Find Fan RPM with known Pulley Pitch Diameters & Motor RPM",
            description:
              "Find Fan RPM with known motor rpm and pulley pitch diameters",
            input: [
              { name: "Pitch Diameter of Fan Pulley", value: "" },
              { name: "Pitch Diameter of Motor Pulley", value: "" },
              { name: "Motor RPM", value: "" },
            ],
            calculation: function (inputs: IInputsCalculator[]) {
              return (
                (Number(inputs[1].value) / Number(inputs[0].value)) *
                Number(inputs[2].value)
              )
                .toFixed(2)
                .toString();
            },
            output: "",
            display: false,
            answer: "Fan RPM =",
          },
          {
            id: 20,
            name: "Find Motor RPM with known Pulley Pitch Diameters & Fan RPM",
            description:
              "Find motor RPM with known fan rpm and pulley pitch diameters",
            input: [
              { name: "Pitch Diameter of Fan Pulley", value: "" },
              { name: "Pitch Diameter of Motor Pulley", value: "" },
              { name: "Fan RPM", value: "" },
            ],
            calculation: function (inputs: IInputsCalculator[]) {
              return (
                (Number(inputs[0].value) / Number(inputs[1].value)) *
                Number(inputs[2].value)
              )
                .toFixed(2)
                .toString();
            },
            output: "",
            display: false,
            answer: "Motor RPM =",
          },
        ],
      },
      {
        id: 21,
        name: "Pressures",
        type: "subcategory",
        color: "#C2DFFF",
        input: [],
        subItems: [
          {
            id: 22,
            name: "Find VP with known CFM",
            description: "",
            input: [
              { name: "CFM 2 (New)", value: "" },
              { name: "CFM 1 (Old)", value: "" },
              { name: "VP 1", value: "" },
            ],
            calculation: function (inputs: IInputsCalculator[]) {
              return (
                Math.pow(Number(inputs[0].value) / Number(inputs[1].value), 2) *
                Number(inputs[2].value)
              )
                .toFixed(2)
                .toString();
            },
            output: "",
            display: false,
            answer: "VP 2 =",
          },
          {
            id: 23,
            name: "Find SP with known CFM",
            description: "",
            input: [
              { name: "CFM 2 (New)", value: "" },
              { name: "CFM 1 (Old)", value: "" },
              { name: "SP 1 (Old)", value: "" },
            ],
            calculation: function (inputs: IInputsCalculator[]) {
              return (
                Math.pow(Number(inputs[0].value) / Number(inputs[1].value), 2) *
                Number(inputs[2].value)
              )
                .toFixed(2)
                .toString();
            },
            output: "",
            display: false,
            answer: "SP 2 =",
          },
          {
            id: 24,
            name: "Find SP with known RPM",
            description: "",
            input: [
              { name: "RPM 2 (New)", value: "" },
              { name: "RPM 1 (Old)", value: "" },
              { name: "SP 1 (Old)", value: "" },
            ],
            calculation: function (inputs: IInputsCalculator[]) {
              return (
                Math.pow(Number(inputs[0].value) / Number(inputs[1].value), 2) *
                Number(inputs[2].value)
              )
                .toFixed(2)
                .toString();
            },
            output: "",
            display: false,
            answer: "SP 2 =",
          },
        ],
      },
      {
        id: 25,
        name: "Motor BHP",
        type: "subcategory",
        color: "#C2DFFF",
        input: [],
        subItems: [
          {
            id: 26,
            name: "Find Motor BHP with known CFM",
            description: "",
            input: [
              { name: "CFM 2 (New)", value: "" },
              { name: "CFM 1 (Old)", value: "" },
              { name: "BHP 1 (Old)", value: "" },
            ],
            calculation: function (inputs: IInputsCalculator[]) {
              return (
                Math.pow(Number(inputs[0].value) / Number(inputs[1].value), 3) *
                Number(inputs[2].value)
              )
                .toFixed(2)
                .toString();
            },
            output: "",
            display: false,
            answer: "BHP 2 =",
          },
          {
            id: 27,
            name: "Find Motor BHP with known RPM",
            description: "",
            input: [
              { name: "RPM 2 (New)", value: "" },
              { name: "RPM 1 (Old)", value: "" },
              { name: "BHP 1 (Old)", value: "" },
            ],
            calculation: function (inputs: IInputsCalculator[]) {
              return (
                Math.pow(Number(inputs[0].value) / Number(inputs[1].value), 3) *
                Number(inputs[2].value)
              )
                .toFixed(2)
                .toString();
            },
            output: "",
            display: false,
            answer: "BHP 2 =",
          },
          {
            id: 28,
            name: "Find Single Phase (1 Ph) Motor BHP",
            description: "",
            input: [
              { name: "Average Amps", value: "" },
              { name: "Average Volts", value: "" },
              {
                name: "Power Factor",
                value: "",
                integerPlaces: 0,
                decimalPlaces: 4,
              },
              {
                name: "Efficiency",
                value: "",
                integerPlaces: 0,
                decimalPlaces: 4,
              },
            ],
            calculation: function (inputs: IInputsCalculator[]) {
              return (
                (Number(inputs[0].value) *
                  Number(inputs[1].value) *
                  Number(inputs[2].value) *
                  Number(inputs[3].value)) /
                746
              )
                .toFixed(2)
                .toString();
            },
            output: "",
            display: false,
            answer: "Motor (1 Ph) BHP =",
          },
          {
            id: 29,
            name: "Find Three Phase (3 Ph) Motor BHP",
            description: "",
            input: [
              { name: "Average Amps", value: "" },
              { name: "Average Volts", value: "" },
              {
                name: "Power Factor",
                value: "",
                integerPlaces: 0,
                decimalPlaces: 4,
              },
              {
                name: "Efficiency",
                value: "",
                integerPlaces: 0,
                decimalPlaces: 4,
              },
            ],
            calculation: function (inputs: IInputsCalculator[]) {
              return (
                (Number(inputs[0].value) *
                  Number(inputs[1].value) *
                  Number(inputs[2].value) *
                  Number(inputs[3].value) *
                  1.732) /
                746
              )
                .toFixed(2)
                .toString();
            },
            output: "",
            display: false,
            answer: "Motor (3 Ph) BHP =",
          },
        ],
      },
      {
        id: 30,
        name: "Fan Tip Speed",
        type: "subcategory",
        color: "#C2DFFF",
        input: [],
        subItems: [
          {
            id: 31,
            name: "Find Fan Tip Speed",
            description: "",
            input: [
              { name: "Fan Diameter", value: "" },
              { name: "Fan RPM", value: "" },
            ],
            calculation: function (inputs: IInputsCalculator[]) {
              return (
                (Math.PI * Number(inputs[0].value) * Number(inputs[1].value)) /
                12
              )
                .toFixed(2)
                .toString();
            },
            output: "",
            display: false,
            answer: "Ft/Min =",
          },
        ],
      },
      {
        id: 32,
        name: "VAV/FPB COEFFICIENTS",
        type: "subcategory",
        color: "#C2DFFF",
        input: [],
        subItems: [
          {
            id: 33,
            name: "Find VAV/FPB Coefficient (Squared)",
            description: "Flow coefficient via division",
            input: [
              { name: "Flow Coefficient Known", value: "" },
              { name: "Flow (CFM) Measured", value: "" },
              { name: "BAS Flow (CFM) Indicated", value: "" },
            ],
            calculation: function (inputs: IInputsCalculator[]) {
              return (
                Number(inputs[0].value) *
                (Number(inputs[1].value) / Number(inputs[2].value))
              )
                .toFixed(2)
                .toString();
            },
            output: "",
            display: false,
            answer: "Flow Coefficient Req =",
          },
          {
            id: 34,
            name: "Find VAV/FPB Coefficient (Division)",
            description: "",
            input: [
              { name: "Flow Coefficient Known", value: "" },
              { name: "Flow (CFM) Measured", value: "" },
              { name: "BAS Flow (CFM) Indicated", value: "" },
            ],
            calculation: function (inputs: IInputsCalculator[]) {
              return (
                Math.pow(Number(inputs[1].value) / Number(inputs[2].value), 2) *
                Number(inputs[0].value)
              )
                .toFixed(2)
                .toString();
            },
            output: "",
            display: false,
            answer: "Flow Coefficient Req =",
          },
        ],
      },
      {
        id: 35,
        name: "Duct Velocity and CFM",
        type: "subcategory",
        color: "#C2DFFF",
        input: [],
        subItems: [
          {
            id: 36,
            name: "Fluid Velocity/FPM to CFM Rectangular Duct", //'Find motor pitch diameter',
            description: "",
            input: [
              { name: "Duct Size Diameter ID Length", value: "" },
              { name: "Duct Size Diamiter ID Width", value: "" },
              { name: "Fluid Velocity/FPM", value: "" },
            ],
            calculation: function (inputs: IInputsCalculator[]) {
              return (
                ((Number(inputs[0].value) * Number(inputs[1].value)) / 144) *
                Number(inputs[2].value)
              )
                .toFixed(2)
                .toString();
            },
            output: "",
            display: false,
            answer: "CFM =",
          },
          {
            id: 37,
            name: "CFM to Fluid Velocity/FPM Rectangular Duct", //'Find motor pitch diameter',
            description: "",
            input: [
              { name: "Duct Size Diameter ID Length", value: "" },
              { name: "Duct Size Diamiter ID Width", value: "" },
              { name: "CFM", value: "" },
            ],
            calculation: function (inputs: IInputsCalculator[]) {
              return (
                Number(inputs[2].value) /
                ((Number(inputs[0].value) * Number(inputs[1].value)) / 144)
              )
                .toFixed(2)
                .toString();
            },
            output: "",
            display: false,
            answer: "Velocity/FPM =",
          },
          {
            id: 38,
            name: "Fluid Velocity/FPM to CFM Round Duct", //'Find motor pitch diameter',
            description: "",
            input: [
              { name: "Round Duct Size ID Diamiter", value: "" },
              { name: "Fluid Velocity/FPM", value: "" },
            ],
            calculation: function (inputs: IInputsCalculator[]) {
              return (
                ((Math.pow(Number(inputs[0].value) / 2, 2) * 3.14159) / 144) *
                Number(inputs[1].value)
              )
                .toFixed(2)
                .toString();
            },
            output: "",
            display: false,
            answer: "CFM =",
          },
          {
            id: 39,
            name: "CFM to Fluid Velocity/FPM Round Duct", //'Find motor pitch diameter',
            description: "",
            input: [
              { name: "Round Duct Size ID Diamiter", value: "" },
              { name: "CFM", value: "" },
            ],
            calculation: function (inputs: IInputsCalculator[]) {
              return (
                Number(inputs[1].value) /
                ((Math.pow(Number(inputs[0].value) / 2, 2) * 3.14159) / 144)
              )
                .toFixed(2)
                .toString();
            },
            output: "",
            display: false,
            answer: "Velocity/FPM =",
          },
        ],
      },
    ],
  },
];

const formulasHydronics: ICalculator[] = [
  {
    id: 0,
    name: "Hydronics",
    type: "category",
    color: "#89C35C",
    input: [],
    subItems: [
      {
        id: 0,
        name: "GPM",
        type: "subcategory",
        color: "#A9DB82",
        input: [],
        subItems: [
          {
            id: 24,
            name: "Find GPM with known RPM",
            description: "",
            input: [
              { name: "gpm1", value: "" },
              { name: "rpm2", value: "" },
              { name: "rpm1", value: "" },
            ],
            calculation: function (inputs: IInputsCalculator[]) {
              return (
                (Number(inputs[1].value) / Number(inputs[2].value)) *
                Number(inputs[0].value)
              )
                .toFixed(2)
                .toString();
            },
            output: "",
            display: false,
          },
          {
            id: 27,
            name: "Find GPM with known Pump Impeller Diameter",
            description: "Find pump diameter/impeller with known GPM",
            input: [
              { name: "GPM 2 (New)", value: "" },
              { name: "GPM 1 (Old)", value: "" },
              { name: "Diameter 1 (Old)", value: "" },
            ],
            calculation: function (inputs: IInputsCalculator[]) {
              return (
                (Number(inputs[0].value) / Number(inputs[1].value)) *
                Number(inputs[2].value)
              )
                .toFixed(2)
                .toString();
            },
            output: "",
            display: false,
            answer: "Diameter 2 =",
          },
          {
            id: 29,
            name: "Find GPM with known (pressure) Feet' of Head W.G.",
            description: "",
            input: [
              { name: "Feet' of Head W.G. 2 (New)", value: "" },
              { name: "Feet' of Head W.G. 1 (Old)", value: "" },
              { name: "GPM 1 (Old)", value: "" },
            ],
            calculation: function (inputs: IInputsCalculator[]) {
              return (
                Math.sqrt(Number(inputs[0].value) / Number(inputs[1].value)) *
                Number(inputs[2].value)
              )
                .toFixed(2)
                .toString();
            },
            output: "",
            display: false,
            answer: "GPM 2 =",
          },
          {
            id: 35,
            name: "Find GPM with known Motor BHP",
            description: "",
            input: [
              { name: "BHP 2 (New)", value: "" },
              { name: "BHP 1 (Old)", value: "" },
              { name: "GPM 1 (Old)", value: "" },
            ],
            calculation: function (inputs: IInputsCalculator[]) {
              return (
                Math.pow(
                  Number(inputs[0].value) / Number(inputs[1].value),
                  1 / 3
                ) * Number(inputs[2].value)
              )
                .toFixed(2)
                .toString();
            },
            output: "",
            display: false,
            answer: "GPM 2 =",
          },
          {
            id: 41,
            name: "Find GPM with known CV & Δ Psi (Δ Pressure)",
            description: "Cv = flow coefficient",
            input: [
              { name: "Δ Psi (Δ Pressure)", value: "" },
              { name: "CV", value: "" },
            ],
            calculation: function (inputs: IInputsCalculator[]) {
              return (
                Number(inputs[1].value) * Math.sqrt(Number(inputs[0].value))
              )
                .toFixed(2)
                .toString();
            },
            output: "",
            display: false,
            answer: "GPM =",
          },
        ],
      },
      {
        id: 0,
        name: "RPM",
        type: "subcategory",
        color: "#A9DB82",
        input: [],
        subItems: [
          {
            id: 25,
            name: "Find Pump RPM with known GPM",
            description: "",
            input: [
              { name: "RPM 2 (New)", value: "" },
              { name: "RPM 1 (Old)", value: "" },
              { name: "BHP 1 (Old)", value: "" },
            ],
            calculation: function (inputs: IInputsCalculator[]) {
              return (
                (Number(inputs[0].value) / Number(inputs[1].value)) *
                Number(inputs[2].value)
              )
                .toFixed(2)
                .toString();
            },
            output: "",
            display: false,
            answer: "BHP 2 =",
          },
          {
            id: 31,
            name: "Find Pump RPM with known (pressure) Feet' of Head W.G.",
            description: "",
            input: [
              { name: "Feet' of Head W.G. 2 (New)", value: "" },
              { name: "Feet' of Head W.G. 1 (Old)", value: "" },
              { name: "RPM 1 (Old)", value: "" },
            ],
            calculation: function (inputs: IInputsCalculator[]) {
              return (
                Math.sqrt(Number(inputs[0].value) / Number(inputs[1].value)) *
                Number(inputs[2].value)
              )
                .toFixed(2)
                .toString();
            },
            output: "",
            display: false,
            answer: "RPM 2 =",
          },
          {
            id: 37,
            name: "Find Pump RPM with known Motor BHP",
            description: "",
            input: [
              { name: "BHP 2 (New)", value: "" },
              { name: "BHP 1 (Old)", value: "" },
              { name: "RPM 1 (Old)", value: "" },
            ],
            calculation: function (inputs: IInputsCalculator[]) {
              return (
                Math.pow(
                  Number(inputs[0].value) / Number(inputs[1].value),
                  1 / 3
                ) * Number(inputs[2].value)
              )
                .toFixed(2)
                .toString();
            },
            output: "",
            display: false,
            answer: "RPM 2 =",
          },
        ],
      },
      {
        id: 0,
        name: "Pump Impeller",
        type: "subcategory",
        color: "#A9DB82",
        input: [],
        subItems: [
          {
            id: 26,
            name: "Find Pump Impeller Diameter with known GPM", //'----Find gpm with known pump diameter...',
            description: "Find Pump Impeller Diameter with known GPM",
            input: [
              { name: "Diameter 2 (New)", value: "" },
              { name: "Diameter 1 (Old)", value: "" },
              { name: "GPM 1 (Old)", value: "" },
            ],
            calculation: function (inputs: IInputsCalculator[]) {
              return (
                (Number(inputs[0].value) / Number(inputs[1].value)) *
                Number(inputs[2].value)
              )
                .toFixed(2)
                .toString();
            },
            output: "",
            display: false,
            answer: "GPM 2 =",
          },
          {
            id: 33,
            name: "Find Pump Impeller Diameter with known (pressure) Feet' of Head W.G.",
            description:
              "Find Pump Impeller Diameter with known (pressure) Feet' of Head W.G.",
            input: [
              { name: "Feet' of Head W.G. 2 (New)", value: "" },
              { name: "Feet' of Head W.G. 1 (Old)", value: "" },
              { name: "Diameter 1 (Old)", value: "" },
            ],
            calculation: function (inputs: IInputsCalculator[]) {
              return (
                Math.sqrt(Number(inputs[0].value) / Number(inputs[1].value)) *
                Number(inputs[2].value)
              )
                .toFixed(2)
                .toString();
            },
            output: "",
            display: false,
            answer: "Diameter 2 =",
          },
          {
            id: 39,
            name: "Find Pump Impeller Diameter with known Motor BHP",
            description: "Find Pump Impeller Diameter with known Motor BHP",
            input: [
              { name: "BHP 2 (New)", value: "" },
              { name: "BHP 1 (Old)", value: "" },
              { name: "Diameter 1 (Old)", value: "" },
            ],
            calculation: function (inputs: IInputsCalculator[]) {
              return (
                Math.pow(
                  Number(inputs[0].value) / Number(inputs[1].value),
                  1 / 3
                ) * Number(inputs[2].value)
              )
                .toFixed(2)
                .toString();
            },
            output: "",
            display: false,
            answer: "Diameter 2 =",
          },
        ],
      },
      {
        id: 0,
        name: "Pressure Feet' of Head W.G",
        type: "subcategory",
        color: "#A9DB82",
        input: [],
        subItems: [
          {
            id: 28,
            name: "Find (pressure) Feet' of Head W.G. with known GPM",
            description: "",
            input: [
              { name: "GPM 2 (New)", value: "" },
              { name: "GPM 1 (Old)", value: "" },
              { name: "Feet' of Head W.G. 1 (Old)", value: "" },
            ],
            calculation: function (inputs: IInputsCalculator[]) {
              return (
                Math.pow(Number(inputs[0].value) / Number(inputs[1].value), 2) *
                Number(inputs[2].value)
              )
                .toFixed(2)
                .toString();
            },
            output: "",
            display: false,
            answer: "Feet' of Head W.G. 2 =",
          },
          {
            id: 30,
            name: "Find (pressure) Feet' of Head W.G. with known Pump RPM",
            description:
              "Find (pressure) Feet' of Head W.G. with known Pump RPM",
            input: [
              { name: "RPM 2 (New)", value: "" },
              { name: "RPM 1 (Old)", value: "" },
              { name: "Feet' of Head W.G. 1 (Old)", value: "" },
            ],
            calculation: function (inputs: IInputsCalculator[]) {
              return (
                Math.pow(Number(inputs[0].value) / Number(inputs[1].value), 2) *
                Number(inputs[2].value)
              )
                .toFixed(2)
                .toString();
            },
            output: "",
            display: false,
            answer: "Feet' of Head W.G. 2 =",
          },
          {
            id: 32,
            name: "Find (pressure) Feet' of Head W.G. with known Pump Impeller Diameter",
            description:
              "Find (pressure) Feet' of Head W.G. with known Pump Impeller Diameter",
            input: [
              { name: "Diameter 2 (New)", value: "" },
              { name: "Diameter 1 (Old)", value: "" },
              { name: "Feet' of Head W.G. 1 (Old)", value: "" },
            ],
            calculation: function (inputs: IInputsCalculator[]) {
              return (
                Math.pow(Number(inputs[0].value) / Number(inputs[1].value), 2) *
                Number(inputs[2].value)
              )
                .toFixed(2)
                .toString();
            },
            output: "",
            display: false,
            answer: "Feet' of Head W.G. 2 =",
          },
        ],
      },
      {
        id: 0,
        name: "Motor BHP",
        type: "subcategory",
        color: "#A9DB82",
        input: [],
        subItems: [
          {
            id: 34,
            name: "Find Motor BHP with known GPM",
            description: "",
            input: [
              { name: "GPM 2 (New)", value: "" },
              { name: "GPM 1 (Old)", value: "" },
              { name: "BHP 1 (Old)", value: "" },
            ],
            calculation: function (inputs: IInputsCalculator[]) {
              return (
                Math.pow(Number(inputs[0].value) / Number(inputs[1].value), 3) *
                Number(inputs[2].value)
              )
                .toFixed(2)
                .toString();
            },
            output: "",
            display: false,
            answer: "BHP 2 =",
          },
          {
            id: 36,
            name: "Find Motor BHP with known RPM",
            description: "",
            input: [
              { name: "RPM 2 (New)", value: "" },
              { name: "RPM 1 (Old)", value: "" },
              { name: "BHP 1 (Old)", value: "" },
            ],
            calculation: function (inputs: IInputsCalculator[]) {
              return (
                Math.pow(Number(inputs[0].value) / Number(inputs[1].value), 3) *
                Number(inputs[2].value)
              )
                .toFixed(2)
                .toString();
            },
            output: "",
            display: false,
            answer: "BHP 2 =",
          },
          {
            id: 38,
            name: "Find Motor BHP with known Pump Impeller Diameter",
            description: "Find Motor BHP with known Pump Impeller Diameter",
            input: [
              { name: "Diameter 2 (New)", value: "" },
              { name: "Diameter 1 (Old)", value: "" },
              { name: "BHP 1 (Old)", value: "" },
            ],
            calculation: function (inputs: IInputsCalculator[]) {
              return (
                Math.pow(Number(inputs[0].value) / Number(inputs[1].value), 3) *
                Number(inputs[2].value)
              )
                .toFixed(2)
                .toString();
            },
            output: "",
            display: false,
            answer: "BHP 2 =",
          },
        ],
      },
      {
        id: 0,
        name: "Δ Pressures & CV's",
        type: "subcategory",
        color: "#A9DB82",
        input: [],
        subItems: [
          {
            id: 40,
            name: "Find Δ Psi  (Δ Pressure) with known CV & GPM",
            description: "CV = flow coefficient",
            input: [
              { name: "GPM", value: "" },
              { name: "CV", value: "" },
            ],
            calculation: function (inputs: IInputsCalculator[]) {
              return Math.pow(
                Number(inputs[0].value) / Number(inputs[1].value),
                2
              )
                .toFixed(2)
                .toString();
            },
            output: "",
            display: false,
            answer: "Δ Psi =",
          },

          {
            id: 42,
            name: "Find CV with known GPM & Δ Psi (Δ Pressure)",
            description: "CV = flow coefficient",
            input: [
              { name: "GPM", value: "" },
              { name: "Δ Psi (Δ Pressure)", value: "" },
            ],
            calculation: function (inputs: IInputsCalculator[]) {
              return (
                Number(inputs[0].value) / Math.sqrt(Number(inputs[1].value))
              )
                .toFixed(2)
                .toString();
            },
            output: "",
            display: false,
            answer: "CV =",
          },
        ],
      },
      {
        id: 0,
        name: "Pipe Velocity and GPM",
        type: "subcategory",
        color: "#A9DB82",
        input: [],
        subItems: [
          {
            id: 1,
            name: "Fluid Velocity/FPS to GPM", //'Find motor pitch diameter',
            description: "",
            input: [
              { name: "Pipe Size Diamiter ID", value: "", decimalPlaces: 3 },
              { name: "Fluid Velocity/FPS", value: "", decimalPlaces: 3 },
            ],
            calculation: function (inputs: IInputsCalculator[]) {
              return (
                (Math.pow(Number(inputs[0].value), 2) / 0.4199) *
                Number(inputs[1].value)
              )
                .toFixed(2)
                .toString();
            },
            output: "",
            display: false,
            answer: "GPM =",
          },
          {
            id: 2,
            name: "Fluid Velocity/FPS to GPM", //'Find motor pitch diameter',
            description: "",
            input: [
              { name: "Pipe OD Size", value: "", decimalPlaces: 3 },
              { name: "Pipe Wall Thickness", value: "", decimalPlaces: 4 },
              { name: "Fluid Velocity/FPS", value: "", decimalPlaces: 3 },
            ],
            calculation: function (inputs: IInputsCalculator[]) {
              return (
                (Math.pow(
                  Number(inputs[0].value) - Number(inputs[1].value) * 2,
                  2
                ) /
                  0.4199) *
                Number(inputs[2].value)
              )
                .toFixed(2)
                .toString();
            },
            output: "",
            display: false,
            answer: "GPM =",
          },
          {
            id: 3,
            name: "GPM to Fluid Velocity/FPS", //'Find motor pitch diameter',
            description: "",
            input: [
              { name: "Pipe Size Diamiter ID", value: "", decimalPlaces: 3 },
              { name: "GPM", value: "" },
            ],
            calculation: function (inputs: IInputsCalculator[]) {
              return (
                (Number(inputs[1].value) * 0.4199) /
                Math.pow(Number(inputs[0].value), 2)
              )
                .toFixed(4)
                .toString();
            },
            output: "",
            display: false,
            answer: "Velocity/FPS =",
            decimalPlaces: 4,
          },
          {
            id: 4,
            name: "GPM to Fluid Velocity/FPS", //'Find motor pitch diameter',
            description: "",
            input: [
              { name: "Pipe OD Size", value: "", decimalPlaces: 3 },
              { name: "Pipe Wall Thickness", value: "", decimalPlaces: 4 },
              { name: "GPM", value: "" },
            ],
            calculation: function (inputs: IInputsCalculator[]) {
              return (
                (Number(inputs[2].value) * 0.4199) /
                Math.pow(
                  Number(inputs[0].value) - Number(inputs[1].value) * 2,
                  2
                )
              )
                .toFixed(4)
                .toString();
            },
            output: "",
            display: false,
            answer: "Velocity/FPS =",
            decimalPlaces: 4,
          },
        ],
      },
    ],
  },
];

export default function CalculatorPopUp({
  showCalculator,
  setShowCalculator,
}: CalculatorPopUpProps) {
  const theme = useTheme();

  const handleCloseCalculator = () => {
    setShowCalculator(false);
  };

  return (
    <>
      <Drawer
        anchor="right"
        open={showCalculator}
        onClose={handleCloseCalculator}
        variant="persistent"
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
          },
        }}
      >
        <>
          <DrawerHeader>
            <IconButton onClick={handleCloseCalculator}>
              {theme.direction === "rtl" ? <ChevronLeft /> : <ChevronRight />}
            </IconButton>
            <Typography variant="h4">Standard Industry Formulas</Typography>
          </DrawerHeader>
          <Divider />
          <Grid container pt={4}>
            <Grid item md={12} textAlign={"center"}>
              <Typography variant="h6">ANSI/AMCA Standard 210</Typography>
            </Grid>
            <Grid item md={12}>
              <Divider p={2} />
            </Grid>
            <Grid item md={6} pt={2} textAlign={"center"}>
              <Typography variant="subtitle2">
                Barometric 29.92 In/Hg
              </Typography>
            </Grid>
            <Grid item md={6} pt={2} textAlign={"center"}>
              <Typography variant="subtitle2">Temp 70.0 ºF @ 50% RH</Typography>
            </Grid>
            <Grid item md={12}>
              <Divider p={1} />
            </Grid>
            <Grid item md={4} pt={2} textAlign={"center"}>
              <Typography variant="subtitle2">1.0824 Sensible</Typography>
            </Grid>
            <Grid item md={4} pt={2} textAlign={"center"}>
              <Typography variant="subtitle2">4.45 h/Total</Typography>
            </Grid>
            <Grid item md={4} pt={2} textAlign={"center"}>
              <Typography variant="subtitle2">500.7 BTUh/Total</Typography>
            </Grid>
            <Grid item md={12}>
              <Divider p={1} />
            </Grid>
            <Grid item md={6} pt={2} textAlign={"center"}>
              <Typography variant="subtitle2">3.412 BTU/Watt</Typography>
            </Grid>
            <Grid item md={6} pt={2} textAlign={"center"}>
              <Typography variant="subtitle2">745.69 Watts/HP</Typography>
            </Grid>
            <Grid item md={12}>
              <Divider p={2} />
            </Grid>
          </Grid>
          <Grid container sx={{ p: "0" }}>
            <Grid item md={12} sx={{ p: "0" }}>
              <TreeView
                aria-label="Standard Industry Formulas"
                defaultCollapseIcon={<ExpandMore />}
                defaultExpandIcon={<ChevronRight />}
                sx={{
                  flexGrow: 1,
                  maxwidth: 380,
                  width: 380,
                  overflowY: "auto",
                  p: "0",
                  color: "primary.main",
                }}
              >
                <CalculatorTreeView formulas={formulas}></CalculatorTreeView>
              </TreeView>
            </Grid>
          </Grid>
          <Divider />
          <Grid container sx={{ p: "0" }}>
            <Grid item md={12} sx={{ p: "0" }}>
              <TreeView
                aria-label="Standard Industry Formulas"
                defaultCollapseIcon={<ExpandMore />}
                defaultExpandIcon={<ChevronRight />}
                sx={{
                  flexGrow: 1,
                  maxwidth: 380,
                  width: 380,
                  overflowY: "auto",
                  p: "0",
                  color: "success.main",
                }}
              >
                <CalculatorTreeView
                  formulas={formulasHydronics}
                ></CalculatorTreeView>
              </TreeView>
            </Grid>
          </Grid>
        </>
      </Drawer>
    </>
  );
}
