import { ChillerStudyProvider } from "../context/ChillerStudyContext";
import ChillerStep3 from "./ChillerStep3";
const Index = () => {
  return (
    <ChillerStudyProvider>
      <ChillerStep3 />
    </ChillerStudyProvider>
  );
};

export default Index;
