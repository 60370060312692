import { Stack } from "@mui/material";
import Popup from "src/components/Popup";
import { FormCheckBox } from "src/components/formControls";
import { IStaticReportDTO, IStaticIdentDTO } from "src/ts/interfaces";

interface IMaDamperWarningProps {
  openPopup: boolean;
  values: IStaticReportDTO;
  selectedIndex: number;
  setOpenPopup: (val: boolean) => void;
  setSaveIdent: (val: boolean) => void;
  updateIdent: (val1: IStaticIdentDTO, val2: number) => void;
}

export const MaDamperWarning = (props: IMaDamperWarningProps) => {
  const handleClose = () => {
    props.setOpenPopup(false);
  };

  const handleMaDamperPositiveSPAnswer = (answer: number) => {
    let [ident] = (props.values.staticIdent as IStaticIdentDTO[]).filter(
      (v, i) => i === props.selectedIndex
    );
    let maDamperPositiveSPAnswer = "";
    switch (answer) {
      case 0:
        maDamperPositiveSPAnswer = "Will correct this M/A Chamber issue";
        break;
      case 1:
        maDamperPositiveSPAnswer =
          "There is no means to correct this M/A Chamber issue";
        break;
      case 2:
        maDamperPositiveSPAnswer = "Can't correct this M/A Chamber issue";
        break;
      case 3:
        maDamperPositiveSPAnswer =
          "This M/A Chamber issue is exclusively a control issue";
        break;
      default:
        break;
    }

    ident = {
      ...ident,
      maDamperPositiveSPAnswer,
    };
    props.setSaveIdent(true);
    props.updateIdent(ident, props.selectedIndex);
    props.setOpenPopup(false);
  };

  return (
    <>
      <Popup
        openPopup={props.openPopup}
        onClose={handleClose}
        disableClickOutside
        setOpenPopup={props.setOpenPopup}
        title="MA Chamber Pressure Warning"
        size="sm"
        hideClose
        disableEscKeyDown
      >
        <>
          <p style={{ textAlign: "justify" }}>
            <strong style={{ color: "red" }}>Warning</strong>:{" "}
            <b>
              You have indicated the M/A Chamber pressure +/positive.
              <br />
              It is recommended you validate the supply air fan motor is
              operating and its rotation is correct.
              <br />
              If the fan motor is operating correctly, investigate if the return
              air fan is over pressurizing the M/A Chamber and adjust the fans
              to deliver their design fluid flows. Validate the M/A Chamber goes
              slightly -/negative in terms of pressure.
              <br />
              If no return fan exists, identify why the building is under an
              extreme +/positive pressure and correct this issue.
              <br />
              <br />
              Correcting this positive M/A chamber condition will allow outside
              air to enter the M/A Chamber and mix with the return air entering
              the chamber.
              <br />
              <br />
              Please select your appropriate response for this M/A Chamber
              issue:
            </b>
          </p>
        </>
        <Stack
          direction="column"
          spacing={0}
          justifyContent="flex-start"
          marginLeft={5}
        >
          <FormCheckBox
            name="cfmWarningUnderstood"
            label="Will correct this M/A Chamber issue (Explain in “Notes”)"
            onClick={() => handleMaDamperPositiveSPAnswer(0)}
          />
          <FormCheckBox
            name="cfmWarningUnderstood"
            label="There is no means to correct this M/A Chamber issue (Explain in “Notes”)"
            onClick={() => handleMaDamperPositiveSPAnswer(1)}
          />
          <FormCheckBox
            name="cfmWarningUnderstood"
            label="Can't correct this M/A Chamber issue (Explain in “Notes”)"
            onClick={() => handleMaDamperPositiveSPAnswer(2)}
          />
          <FormCheckBox
            name="cfmWarningUnderstood"
            label="This M/A Chamber issue is exclusively a control issue"
            onClick={() => handleMaDamperPositiveSPAnswer(3)}
          />
        </Stack>
      </Popup>
      {/**MA Damper Warning Modal */}
    </>
  );
};
