import { IPurchaseRequest } from "src/ts/interfaces/purchase";
import axios from "../utils/axios";

const purchase = async (purchase: IPurchaseRequest) => {
  return axios.post(`purchase`, purchase);
};

const purchaseService = {
  purchase,
};

export default purchaseService;
