import React, { useEffect } from "react";
import { IAssetProject, IAssets } from "src/ts/interfaces/companyAssets";
import { ColumnType } from "src/types/enhancedTable";
import LocalEnhancedTable from "src/components/localTable/LocalTable";
import { useAsyncQuery, useAuth } from "src/hooks";

import { Stack } from "@mui/material";
import equipmentService from "src/services/equipmentService";
import { GridActionButton } from "src/components/gridControls";
import { useNavigate } from "react-router-dom";

interface Props {
  currentrow: IAssets | undefined;
  refresh: boolean;
}

const AssetProjects = ({ currentrow, refresh }: Props) => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const { execute, data, isLoading } = useAsyncQuery<IAssetProject[]>(
    equipmentService.getProjectsAssets
  );

  useEffect(() => {
    const getData = async () => {
      await execute(currentrow?.equipment?.id ?? 0);
    };

    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getColumns = (): ColumnType[] => {
    return [
      {
        id: "name",
        label: "Project",
        type: "string",
        sort: true,
      },
      {
        id: "description",
        label: "Description",
        type: "string",
        sort: true,
      },
      {
        id: "createdDate",
        label: "Date Created",
        type: "utcDate",
        sort: true,
        format: "MM/DD/YYYY h:mma",
      },
      {
        id: "completedDate",
        label: "Date Completed",
        type: "utcDate",
        sort: true,
        format: "MM/DD/YYYY h:mma",
      },
      {
        id: "projectStatus",
        label: "Status",
        type: "string",
        sort: true,
      },

      {
        id: "comments",
        label: "",
        type: "custom",
        sort: true,
        callback: (row: IAssetProject, index: number) => (
          <Stack direction="row">
            <>
              <GridActionButton
                type="forward"
                tooltip="Go to Project"
                onClick={() => {
                  navigate(`/app/ProjectStudies/${row.id}/${user?.companyId}`);
                }}
              />
            </>
          </Stack>
        ),
      },
    ];
  };

  const columns = getColumns();

  return (
    <>
      <LocalEnhancedTable<IAssetProject>
        refreshGrid={true}
        columns={columns}
        data={data ?? []}
        showSkeleton={isLoading}
        defaultRowPerPage={5}
        orderColumn="desc"
        defaultSortColumn="assignedDate"
      />
    </>
  );
};

export default AssetProjects;
