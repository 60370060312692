import axios from "src/utils/axios";
import { IPumpDTO } from "src/ts/interfaces/study/pump";

const getById = (id: number) => {
  return axios.get<IPumpDTO>(`Pump/${id}`);
};

const save = (pump: IPumpDTO) => {
  return axios.post<IPumpDTO>("Pump", pump);
};

const update = (id: number, pump: IPumpDTO) => {
  return axios.put<IPumpDTO>(`Pump/${id}`, pump);
};

const pumpService = {
  getById,
  save,
  update,
};

export default pumpService;
