import { TableCell as TableCellMui, styled } from "@mui/material";

const TableCell = styled(TableCellMui)`
  border-width: 1px;
  padding-left: 1px;
  padding-right: 1px;
  padding-top: 1px;
  padding-bottom: 1px;
`;

interface SpreadSheetCellProps {
  children: JSX.Element | string | number | null;
  transposed?: boolean;
  width?: string | number;
  minWidth?: string | number;
  align?: "left" | "center" | "right" | "justify" | "inherit";
  backgroundColor?: string;
  borderColor?: string;
  paddingTop?: string;
  paddingLeft?: string;
}

const styles = {
  transposed: {
    padding: "0px",
    display: "block",
    height: "35px",
  },
};

const SpreadSheetCell = ({
  children,
  transposed,
  width,
  minWidth,
  align,
  backgroundColor,
  borderColor,
  paddingTop,
  paddingLeft,
}: SpreadSheetCellProps) => {
  return (
    <TableCell
      style={
        transposed
          ? {
              ...styles.transposed,
              backgroundColor: backgroundColor,
              borderColor: borderColor,
              paddingTop: paddingTop ?? "",
              paddingLeft: paddingLeft ?? "",
              minWidth: minWidth ?? "",
              width: width ?? "",
            }
          : {
              backgroundColor: backgroundColor,
              borderColor: borderColor,
              paddingTop: paddingTop ?? "",
              paddingLeft: paddingLeft ?? "",
              minWidth: minWidth ?? "",
              width: width ?? "",
            }
      }
      align={align}
    >
      {children}
    </TableCell>
  );
};

export default SpreadSheetCell;
