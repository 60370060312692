import { Typography } from "@mui/material";
import { FormCheckBox } from "src/components/formControls";

const pb = 4;

interface Props {
  updateControlStep: (step: number, value: boolean) => void;
  value: boolean;
}

const step = 0;

const Agreement = ({ updateControlStep, value }: Props) => {
  const toggleAgreement = (e: any) => {
    updateControlStep(step, e.target.value);
  };

  return (
    <>
      <Typography variant="h4" gutterBottom pt={10} pb={pb} align="center">
        Agreement - Part 1
      </Typography>

      <Typography variant="body1" gutterBottom pb={pb}>
        A building becomes recognized as an “Infectious Building Controlled,
        HVAC Ventilation Certified Building” by having every ventilation system
        delivering design outside airflow and reaching the goal of obtaining,
        proper air changes per hour, ideal pressurization cascading throughout
        the building and proper pressurization from the internal building core
        to the outside ambient environment.
      </Typography>

      <Typography variant="body1" gutterBottom pb={pb}>
        A building under consideration for certification must be a truly
        stand-alone building from any other building being tested or not tested
        by either means of non-connectivity with horizontal distance from
        another building, a demising wall separating one building structure from
        another with no means of airflow or pressurization influencing one
        building to another.
      </Typography>

      <Typography variant="body1" gutterBottom pb={pb}>
        Any satellite/sub building who’s address falls under the primary address
        of the same property may apply for “Infectious Building Controlled, HVAC
        Ventilation Certified Building” designation providing the guidelines are
        recognized and followed as described above.
      </Typography>

      <Typography variant="body1" gutterBottom pb={pb}>
        “Infectious Building Controlled, HVAC Ventilation Certified Building”
        designation must follow the spirit, intent and definition of a true
        stand-alone building.
      </Typography>
      <FormCheckBox
        value={value}
        name={""}
        label={"I agree."}
        onChange={toggleAgreement}
      />
    </>
  );
};

export default Agreement;
