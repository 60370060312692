import Popup from "src/components/Popup";
import { FormButton } from "src/components/formControls";
import { Stack } from "@mui/material";

interface MovePhaseBidProps {
  movePhaseBidShowPopUp: boolean;
  setMovePhaseBidShowPopUp: (val: boolean) => void;
  handlerSendForBid: () => {};
  loadingNotify: boolean;
}

export default function MovePhaseBid({
  movePhaseBidShowPopUp,
  setMovePhaseBidShowPopUp,
  handlerSendForBid,
  loadingNotify,
}: MovePhaseBidProps) {
  return (
    <>
      <Popup
        title={`Information`}
        openPopup={movePhaseBidShowPopUp}
        setOpenPopup={setMovePhaseBidShowPopUp}
        onClose={() => {
          setMovePhaseBidShowPopUp(false);
        }}
        size="sm"
        disableClickOutside={true}
        disableEscKeyDown={true}
        hideClose={loadingNotify}
      >
        <>
          The project will be moved to phase "Out for Bid" and{" "}
          <b>NO OTHER ADDITIONAL CHANGES WILL BE ALLOWED. </b>
          <br />
          The project will be published for all contractors in AMP Software
          and/or Audit master PRO's web page, in accordance with selection made.
          <br />
          <br />
          Ensuring your HVAC project directs service providers, contractors and
          sub-contractors to AMP’s Cloud computing “Projects out for bid”
          software. Add your project, project description, project directives to
          your full packaged project specifications, Copy and paste via Word
          Document directly to a set of prints where mechanical project
          specifications are listed, etc.
          <br />
          <br />A PDF Copy of this project’s details along with a Word Document
          will be automatically sent to your personal email and a second set of
          copies will be stored in Amp project’s folder labeled
          “General/Miscellaneous”
        </>
        <Stack direction="row" spacing={3}>
          <FormButton
            size="small"
            text="Cancel"
            variant="outlined"
            onClick={() => {
              setMovePhaseBidShowPopUp(false);
            }}
            disabled={loadingNotify}
          />
          <FormButton
            size="small"
            text="Accept"
            color="primary"
            onClick={() => {
              handlerSendForBid();
            }}
            isSubmitting={loadingNotify}
          />
        </Stack>
      </Popup>
    </>
  );
}
