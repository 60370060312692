import { IEnergyCostSheduledAndBillingTotal } from "../ts/interfaces/energyCostSheduledAndBillingTotal";
import axios from "../utils/axios";

const updateAll = async (data: IEnergyCostSheduledAndBillingTotal[]) => {
  return axios.put<any>("EnergyCostSheduledAndBillingTotal/SaveAll/", data);
};

const getById = async (id: number) => {
  return axios.get<IEnergyCostSheduledAndBillingTotal[]>(
    "EnergyCostSheduledAndBillingTotal/building/" + id
  );
};

const energyCostSheduledAndBillingTotalService = {
  updateAll,
  getById,
};

export default energyCostSheduledAndBillingTotalService;
