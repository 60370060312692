import {
  CardContent,
  Grid,
  Card as MuiCard,
  Divider as MuiDivider,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";
import styled from "@emotion/styled";
import { useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAsyncQuery, useAuth, useEffectOnce, useLog } from "src/hooks";
import chillerService from "src/services/study/chillerService";
import {
  IChillerCompressor,
  IChillerEvaporatorCondenser,
  IReportType,
} from "src/ts/interfaces";
import { Mode } from "src/ts/types";
import StudyWizard from "../../components/StudyWizard";
import { FooterButtons } from "../../components/FooterButtons";
import DialogMessagePopup from "src/components/DialogMessagePopup";
import { steps, studyTypeCode } from "../const";
import projectReportService from "src/services/study/projectReportService";
import { IProjectReport } from "src/ts/interfaces/project/projectDto";
import reportTypeService from "src/services/study/reportTypeService";
import studiesHistoryService from "src/services/study/studiesHistoryService";
import useChillerStudy from "../hooks/useChillerStudy";
import EvaporatorList from "../components/CondensersList";
import CondenserForm from "../components/CondenserForm";
import CompressorsList from "../components/CompressorsList";
import CompressorForm from "../components/CompressorForm";
import HeaderStudyPage from "src/components/page/HeaderStudyPage";

const Divider = styled(MuiDivider)(spacing);
const Card = styled(MuiCard)(spacing);
const activeStep = 2;

const ChillerStep3 = () => {
  const { user } = useAuth();
  const params = useParams<{ id: string }>();
  const id = parseInt(params?.id === undefined ? "0" : params?.id);
  let navigate = useNavigate();
  const { log } = useLog();

  const {
    chillerValues,
    setChillerValues,
    setEvaporatorValues,
    setCompressorValues,
    compressorValues,
    evaporatorValues,
    validateCompressorValues,
    compressorErros,
  } = useChillerStudy();

  const [reportType, setReportType] = useState<IReportType | null>();

  const { execute: executeReport, data: dataReport } =
    useAsyncQuery<IProjectReport>(projectReportService.report);

  const [isLoading, setIsLoading] = useState(false);

  const [evaporators, setEvaporators] = useState<IChillerEvaporatorCondenser[]>(
    []
  );

  const [compressors, setCompressors] = useState<IChillerCompressor[]>([]);

  useEffectOnce(() => {
    const getData = async () => {
      try {
        setIsLoading(true);
        const chillerRes = await chillerService.getById(id);
        setChillerValues(chillerRes.data);

        const evaporatorsRes = await chillerService.evaporators(id);
        setEvaporators(evaporatorsRes.data);
        if (evaporatorsRes.data.length > 0) {
          setEvaporatorValues(evaporatorsRes.data[0]);

          await getCompressors(evaporatorsRes.data[0]);
        }

        const reportTypeRes = await reportTypeService.getReportTypeByCode(
          studyTypeCode
        );
        setReportType(reportTypeRes.data);

        await executeReport(id, reportTypeRes.data.code);
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      } finally {
        setIsLoading(false);
      }
    };
    if (id > 0) getData();
  });

  const getCompressors = async (evaporator: IChillerEvaporatorCondenser) => {
    const compressorsRes = await chillerService.getCompressorsByEvaporatorId(
      evaporator.id
    );

    if (compressorsRes.data.length > 0) {
      setCompressors(compressorsRes.data);
      setCompressorValues(compressorsRes.data[0]);
      //Training Motor
    } else {
      const compressorNew: any = {
        chillerEvaporatorCondenserId: evaporator.id,
      };

      const compressorRes = await chillerService.addCompressor(compressorNew);
      setCompressors([compressorRes.data]);
      setCompressorValues(compressorRes.data);
    }
  };

  const mode: Mode = chillerValues.isComplete ? "read" : "read&Write";

  const disableStudy = useMemo(() => {
    return (
      chillerValues?.isComplete ||
      (dataReport?.isInUse && dataReport?.isInUseById !== user?.userId) ||
      dataReport?.isPartOfProject === false
    );
  }, [
    dataReport?.isInUse,
    dataReport?.isInUseById,
    dataReport?.isPartOfProject,
    user?.userId,
    chillerValues?.isComplete,
  ]);

  const saveHandler = async () => {
    await save();
  };

  const nextHandler = async () => {
    if (!disableStudy) {
      if (
        (compressorValues.nameplateHorsePowerId == null ||
          compressorValues.nameplateHorsePowerId === 0) &&
        !compressorValues.horsepowerUnknown
      ) {
        log.error(
          'To move forward, Nameplate HP is required. If you don\'t know the HP, select the "Nameplate HP unknown" checkbox.'
        );
        return;
      }
      await save();
    }

    const route = `/studies/Chiller/step31/${id}`;
    navigate(route);
  };

  const backHandler = async () => {
    await save();
    const route = `/studies/Chiller/step2/${id}`;
    navigate(route);
  };

  const save = async () => {
    if (disableStudy) return;

    try {
      setIsLoading(true);
      const updateChillerRes = await chillerService.update(
        chillerValues.id,
        chillerValues
      );
      setChillerValues(updateChillerRes.data);

      await studiesHistoryService.save(
        chillerValues.projectId,
        reportType?.id ?? 0,
        chillerValues.id,
        "saved"
      );
      await chillerService.updateEvaporator(evaporatorValues.id, {
        ...evaporatorValues,
        numberOfCompressorsCircuits: compressors.length,
      });

      await saveCompressor();

      log.info("Updated successfully");
    } catch (error: any) {
      log.error(error?.message?.exceptionMessage ?? "Something went wrong");
    } finally {
      setIsLoading(false);
    }
  };

  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [compressorForDelete, setCompressorForDelete] =
    useState<IChillerCompressor | null>(null);

  const showModalDeleteCompressor = (fanMotor: IChillerCompressor) => {
    setCompressorForDelete(fanMotor);
    setShowDeleteModal(true);
  };

  const selectCompressorHandler = async (
    selectedCompressor: IChillerCompressor
  ) => {
    if (!disableStudy) {
      await saveCompressor();
    }

    const compressorRes = await chillerService.getCompressorById(
      selectedCompressor.id
    );
    setCompressorValues(compressorRes.data);
  };

  const saveCompressor = async () => {
    if (!validateCompressorValues()) return;
    await chillerService.updateCompressor(
      compressorValues.id,
      compressorValues
    );
  };

  const onEvaporatorChangeHandler = async (
    evaporator: IChillerEvaporatorCondenser
  ) => {
    if (evaporator.id === evaporatorValues.id) return;
    if (!validateCompressorValues()) return;
    if (!disableStudy) {
      await chillerService.updateEvaporator(evaporatorValues.id, {
        ...evaporatorValues,
        numberOfCompressorsCircuits: compressors.length,
      });
      await saveCompressor();
    }

    const evaporatorRes = await chillerService.getEvaporatorById(evaporator.id);
    setEvaporatorValues(evaporatorRes.data);
    await getCompressors(evaporator);
  };

  const deleteCompressor = async () => {
    if (compressorForDelete === null) return;

    await chillerService.deleteCompressor(compressorForDelete.id);
    setShowDeleteModal(false);

    if (compressors === null) return;

    const index = compressors.findIndex((x) => x.id === compressorForDelete.id);
    const newCompressors = [...compressors];
    newCompressors.splice(index, 1);
    setCompressors(newCompressors);
    if (newCompressors.length > 0) setCompressorValues(newCompressors[0]);
  };

  const addCompressor = async (
    compressor: IChillerCompressor,
    index: number
  ) => {
    await saveCompressor();

    const compressorNew: any = {
      chillerEvaporatorCondenserId: compressor.chillerEvaporatorCondenserId,
    };

    const compressorRes = await chillerService.addCompressor(compressorNew);

    if (compressors === null) return;
    const newCompressors = [...compressors];

    newCompressors.splice(index + 1, 0, compressorRes.data);
    setCompressors(newCompressors);
    setCompressorValues(compressorRes.data);
  };

  return (
    <>
      <HeaderStudyPage
        headerStudiesPage={{
          code: studyTypeCode,
          system: chillerValues.system,
          id: id,
        }}
        parentText="Project"
        parentLink="/"
      />
      <Divider my={6} />

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <StudyWizard
            steps={steps}
            activeStep={activeStep}
            projectId={dataReport?.projectId}
            reportId={dataReport?.reportId}
            reportTypeId={dataReport?.reportTypeId}
          />
        </Grid>
        <Grid item xs={12}>
          <Card mb={1}>
            <CardContent>
              <Grid container spacing={5}>
                <Grid item xs={12}>
                  <Typography variant="h6">
                    Evaporator/Condenser Nameplate Data
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <EvaporatorList
                    evaporators={evaporators}
                    isStudyComplete={disableStudy}
                    onChange={onEvaporatorChangeHandler}
                  />
                </Grid>
                <Grid item xs={12}>
                  <CondenserForm
                    mode={mode}
                    disableStudy={disableStudy}
                    isLoading={isLoading}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h6">Compressors/Circuits</Typography>
                </Grid>
                <Grid item xs={12}>
                  <CompressorsList
                    compressors={compressors}
                    disableStudy={disableStudy}
                    onSelect={selectCompressorHandler}
                    onDelete={showModalDeleteCompressor}
                    onAdd={addCompressor}
                    showDelete={compressors.length > 1}
                    showDuplicate={true}
                  />
                </Grid>
                <Grid item xs={12}>
                  <CompressorForm
                    mode={mode}
                    disableStudy={disableStudy}
                    isLoading={isLoading}
                    compressorErros={compressorErros}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FooterButtons
                    activeStep={activeStep}
                    stepsCount={steps.length}
                    projectId={dataReport?.projectId}
                    companyId={user?.companyId as number}
                    isSaving={false}
                    disabled={false}
                    reportName={reportType?.name ?? ""}
                    saveHandler={saveHandler}
                    nextStepHandler={() => nextHandler()}
                    previousStepHandler={() => backHandler()}
                    hideSaveButton={disableStudy}
                    isComplete={chillerValues.isComplete}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <DialogMessagePopup
        title={"Delete Compressor/Circuit."}
        text={"Are certain you want to remove this Compressor/Circuit?"}
        showPopup={showDeleteModal}
        setShowPopup={setShowDeleteModal}
        onSave={() => {
          deleteCompressor();
        }}
        isSubmitting={false}
      />
    </>
  );
};

export default ChillerStep3;
