import axios from "../utils/axios";
import { IEmailTemplateNotification } from "../ts/interfaces";

const getUserNotifications = () => {
  return axios.get<IEmailTemplateNotification[]>("EmailTemplate/GetByRole");
};

const emailTemplateService = {
  getUserNotifications,
};

export default emailTemplateService;
