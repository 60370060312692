import { useEffect, useState } from "react";
import { Grid, Stack, Typography } from "@mui/material";

import { ColumnType } from "src/types/enhancedTable";
import { FilterType } from "../types/FilterTypes";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { GridActionButton } from "src/components/gridControls";
import { useAuth } from "src/hooks";
import LocalEnhancedTable from "src/components/localTable/LocalTable";

import { IGetProjectInfectious } from "src/ts/interfaces/project/projectinfectiousCompliance";
import CloseProjectPopUp from "../componentsArchitectProject/components/CloseProjectPopUp";
import { FormRaitingStarts } from "src/components/formControls";
import RatingInformation from "src/pages/projectOutForBid/Components/RatingInformationPopUp";

const getColumns = (
  navigate: NavigateFunction,
  role: string,
  setCurrentRow: (val: IGetProjectInfectious) => void,
  companyId: number,
  setCloseProjectShowPopUp: (val: boolean) => void,
  setShowRatingContractorPopUp: (val: boolean) => void
): ColumnType[] => [
  {
    id: "code",
    label: "Code",
    type: "string",
    sort: true,
  },

  {
    id: "name",
    label: "Project",
    type: "string",
    sort: true,
  },
  {
    id: "buildingAddress",
    label: "Building Address",
    type: "string",
    sort: true,
  },
  {
    id: "projectDescription",
    label: "Description",
    type: "string",
    sort: true,
  },
  {
    id: "createdDate",
    label: "Created Date",
    type: "utcDate",
    format: "MM/DD/YYYY",
    sort: true,
  },
  {
    id: "completedDate",
    label: "Completed Date",
    type: "date",
    format: "MM/DD/YYYY",
    sort: true,
  },
  {
    id: "action",
    label: "Click on Stars to see Service Providers Ratings",
    type: "custom",
    sort: true,
    width: "150p",
    callback: (row: IGetProjectInfectious) => (
      <>
        <Stack direction={"column"}>
          <Stack direction="row">
            <GridActionButton
              type="gauge"
              onClick={() => {
                navigate(`/app/projectDashboard/${row.id}`);
              }}
              tooltip="Dashboard Project"
            />
            <GridActionButton
              type="forward"
              onClick={() => {
                navigate(`/app/Project/Edit/${row.id}/${companyId}`);
              }}
              tooltip="Review Project"
            />

            <GridActionButton
              type="forum"
              onClick={() => {
                navigate(`/app/dispute/${row.id}`);
              }}
              tooltip="Dispute"
            />

            <GridActionButton
              type="folder"
              onClick={() => {
                navigate(`/app/DocumentLibrary/${row.id}/${companyId}/Project`);
              }}
              tooltip="Project's Document Library"
            />
            <GridActionButton
              type="picture"
              onClick={() => {
                navigate(`/app/PhotoLibrary/${row.id}/${companyId}/Project`);
              }}
              tooltip="Project's Picture Library"
            />
          </Stack>
          <Stack>
            <Grid container justifyContent="center">
              <Grid item xs={6}>
                <Stack
                  direction="row"
                  onClick={() => {
                    setCurrentRow(row);
                    setShowRatingContractorPopUp(true);
                  }}
                  sx={{ cursor: "pointer" }}
                >
                  <>
                    <FormRaitingStarts value={row.ratingProject} />
                  </>
                </Stack>
              </Grid>
            </Grid>
          </Stack>
        </Stack>
      </>
    ),
  },
];

interface CompletedInfectiousProps {
  filters: FilterType;
  setRefreshGrid: (val: boolean) => void;
  dataTable: IGetProjectInfectious[];
  isLoading: boolean;
  refreshGrid: boolean;
  setDataTable: (val: IGetProjectInfectious[]) => void;
}

export default function CompletedInfectious({
  filters,
  setRefreshGrid,
  dataTable,
  isLoading,
  refreshGrid,
  setDataTable,
}: CompletedInfectiousProps) {
  const [currentRow, setCurrentRow] = useState<IGetProjectInfectious>();
  const [closeProjectShowPopUp, setCloseProjectShowPopUp] = useState(false);
  const [showRatingContractorPopUp, setShowRatingContractorPopUp] =
    useState(false);
  let navigate = useNavigate();
  const { user } = useAuth();

  let columns = getColumns(
    navigate,
    user?.role,
    setCurrentRow,
    user?.companyId,
    setCloseProjectShowPopUp,
    setShowRatingContractorPopUp
  );

  return (
    <>
      <LocalEnhancedTable<IGetProjectInfectious>
        refreshGrid={true}
        columns={columns}
        data={dataTable ?? []}
        showSkeleton={isLoading}
        skeletonRows={1}
        hidePagination={false}
        defaultRowPerPage={10}
        orderColumn="desc"
        defaultSortColumn="id"
      />
      {closeProjectShowPopUp && (
        <CloseProjectPopUp
          setCloseProjectShowPopUp={setCloseProjectShowPopUp}
          closeProjectShowPopUp={closeProjectShowPopUp}
          currentRow={currentRow}
          setRefreshGrid={setRefreshGrid}
          refreshGrid={refreshGrid}
          isInfectious={true}
        />
      )}
      {showRatingContractorPopUp && (
        <RatingInformation
          showRatingContractorPopUp={showRatingContractorPopUp}
          setShowRatingContractorPopUp={setShowRatingContractorPopUp}
          informationContractor={currentRow?.id ?? 0}
          isProject={true}
          setRefresh={setRefreshGrid}
        />
      )}
    </>
  );
}
