import React, { useState } from "react";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import {
  CardContent,
  Grid,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Button as MuiButton,
  Card as MuiCard,
  Divider as MuiDivider,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import {
  Add as AddIcon,
  Edit as EditIcon,
  CloudUpload as CloudUploadIcon,
  KeyboardVoice as KeyboardVoiceIcon,
  Save as SaveIcon,
} from "@mui/icons-material";
import { spacing } from "@mui/system";
import {
  FormActionButton as AmpFormActionButton,
  FormButton as AmpFormButton,
  FormCheckBox,
} from "src/components/formControls";
import { GridActionButton } from "src/components/gridControls";
import GridUploadButton from "src/components/gridControls/GridUploadButton";
import DropZone from "src/components/DropZone";
import useLog from "src/hooks/useLog";

const Card = styled(MuiCard)(spacing);

const FormActionButton = styled(AmpFormActionButton)(spacing);

const Button = styled(MuiButton)(spacing);

const FormButton = styled(AmpFormButton)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

function StoryBookPage() {
  return (
    <Card mb={6}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Action buttons
        </Typography>
        <Typography variant="body2" gutterBottom>
          Allow actions like Add, Update, Save, Delete
        </Typography>

        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Type</TableCell>
              <TableCell>Normal state</TableCell>
              <TableCell>Submitting</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>Add</TableCell>
              <TableCell>
                <FormActionButton
                  mr={2}
                  text="Add License"
                  size="medium"
                  onClick={() => {}}
                  isSubmitting={false}
                  type={"add"}
                />
              </TableCell>
              <TableCell>
                <FormActionButton
                  mr={2}
                  text="Add License"
                  size="medium"
                  onClick={() => {}}
                  isSubmitting={true}
                  type={"add"}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Save</TableCell>
              <TableCell>
                <FormActionButton
                  mr={2}
                  text="Save"
                  size="medium"
                  onClick={() => {}}
                  isSubmitting={false}
                  type="save"
                />
              </TableCell>
              <TableCell>
                <FormActionButton
                  mr={2}
                  text="Save"
                  size="medium"
                  onClick={() => {}}
                  isSubmitting={true}
                  type="save"
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Filter</TableCell>
              <TableCell>
                <FormActionButton
                  mr={2}
                  text="Filter"
                  size="medium"
                  onClick={() => {}}
                  isSubmitting={false}
                  type="filter"
                />
              </TableCell>
              <TableCell>
                <FormActionButton
                  mr={2}
                  text="Filter"
                  size="medium"
                  onClick={() => {}}
                  isSubmitting={true}
                  type="filter"
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Serch</TableCell>
              <TableCell>
                <FormActionButton
                  mr={2}
                  text="Search"
                  size="medium"
                  onClick={() => {}}
                  isSubmitting={false}
                  type="search"
                />
              </TableCell>
              <TableCell>
                <FormActionButton
                  mr={2}
                  text="Search"
                  size="medium"
                  onClick={() => {}}
                  isSubmitting={true}
                  type="search"
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
}

function TextButtons() {
  return (
    <Card mb={6}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Drag and drop control
        </Typography>
        <Typography variant="body2" gutterBottom>
          Allow Drag and drop files
        </Typography>
        <DropZone
          onFileAccepted={() => {}}
          accept={{ "application/pdf": [".pdf"] }}
          disabled={false}
          disableMessage="Only one profile is allowed"
          submiting={false}
        />
        <br />
        <DropZone
          onFileAccepted={() => {}}
          accept={{ "application/pdf": [".pdf"] }}
          disabled={false}
          disableMessage="Only one profile is allowed"
          submiting={true}
        />
        <br />
        <DropZone
          onFileAccepted={() => {}}
          accept={{ "application/pdf": [".pdf"] }}
          disabled={true}
          disableMessage="Only one profile is allowed"
          submiting={false}
        />
      </CardContent>
    </Card>
  );
}

function OutlinedButtons() {
  return (
    <Card mb={6}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          StoryBook
        </Typography>
        <Typography variant="body2" gutterBottom>
          Outlined buttons are medium-emphasis buttons which contain actions
          that are not that important.
        </Typography>

        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Dessert (100g serving)</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell component="th" scope="row">
                <GridActionButton
                  type="edit"
                  onClick={() => {}}
                  tooltip="Edit"
                />
                <GridActionButton
                  type="disable"
                  onClick={() => {}}
                  tooltip="Disable"
                />
                <GridActionButton
                  type="creditCard"
                  onClick={() => {}}
                  tooltip="Block Wallet"
                />
                <GridActionButton
                  type="creditCardOff"
                  onClick={() => {}}
                  tooltip="Unlock Wallet"
                />
                <GridActionButton
                  type="folder"
                  onClick={() => {}}
                  tooltip="Documents"
                />
                <GridActionButton
                  type="users"
                  onClick={() => {}}
                  tooltip="Manage Certified Technicians"
                />
                <GridActionButton
                  type="forward"
                  onClick={() => {}}
                  tooltip="View"
                />
                <GridActionButton
                  type="delete"
                  onClick={() => {}}
                  tooltip="Delete"
                />
                <GridActionButton
                  type="download"
                  onClick={() => {}}
                  tooltip="Download"
                />
                <GridUploadButton accept=".pdf" />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                <GridActionButton type="edit" onClick={() => {}} />
                <GridActionButton type="disable" onClick={() => {}} />
                <GridActionButton type="creditCard" onClick={() => {}} />
                <GridActionButton type="creditCardOff" onClick={() => {}} />
                <GridActionButton type="folder" onClick={() => {}} />
                <GridActionButton type="users" onClick={() => {}} />
                <GridActionButton type="forward" onClick={() => {}} />
                <GridActionButton type="delete" onClick={() => {}} />
                <GridActionButton type="download" onClick={() => {}} />
                <GridUploadButton accept=".pdf" />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                <GridActionButton type="edit" onClick={() => {}} />
                <GridActionButton type="disable" onClick={() => {}} />
                <GridActionButton type="creditCard" onClick={() => {}} />
                <GridActionButton type="creditCardOff" onClick={() => {}} />
                <GridActionButton type="folder" onClick={() => {}} />
                <GridActionButton type="users" onClick={() => {}} />
                <GridActionButton type="forward" onClick={() => {}} />
                <GridActionButton type="delete" onClick={() => {}} />
                <GridActionButton type="download" onClick={() => {}} />
                <GridUploadButton accept=".pdf" />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                <GridActionButton type="edit" onClick={() => {}} />
                <GridActionButton type="disable" onClick={() => {}} />
                <GridActionButton type="creditCard" onClick={() => {}} />
                <GridActionButton type="creditCardOff" onClick={() => {}} />
                <GridActionButton type="folder" onClick={() => {}} />
                <GridActionButton type="users" onClick={() => {}} />
                <GridActionButton type="forward" onClick={() => {}} />
                <GridActionButton type="delete" onClick={() => {}} />
                <GridActionButton type="download" onClick={() => {}} />
                <GridUploadButton accept=".pdf" />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
}

function FloatingActionButtons() {
  const { log } = useLog();

  return (
    <Card mb={6}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Alerts
        </Typography>
        <Typography variant="body2" gutterBottom>
          Warning, Error, Info, Default alerts
        </Typography>

        <FormButton
          mr={2}
          text={"Info"}
          color="info"
          size="medium"
          onClick={() => {
            log.info("Info alert");
          }}
        />

        <FormButton
          mr={2}
          text={"Warning"}
          color="warning"
          size="medium"
          onClick={() => {
            log.warning("Warning alert");
          }}
        />

        <FormButton
          mr={2}
          text={"Error"}
          color="error"
          size="medium"
          onClick={() => {
            log.error("Warning alert");
          }}
        />

        <FormButton
          mr={2}
          text={"Success"}
          color="success"
          size="medium"
          onClick={() => {
            log.success("Warning alert");
          }}
        />

        <FormButton
          mr={2}
          text={"Default"}
          color="primary"
          size="medium"
          onClick={() => {
            log.message("Warning alert");
          }}
        />
      </CardContent>
    </Card>
  );
}

function ButtonSizes() {
  const [checkList, setCheckList] = useState(false);
  const handleInputChange = () => {
    setCheckList(!checkList);
  };
  return (
    <Card mb={6}>
      <CardContent>
        <React.Fragment>
          <Typography variant="h6" gutterBottom>
            Check list
          </Typography>
          <Typography variant="body2" gutterBottom>
            Check list for build pages.
          </Typography>
        </React.Fragment>

        <Grid container>
          <Grid item xs={12}>
            <FormCheckBox
              label="Show initial loading/skelleton on loading grids with filters or full forms"
              name="isActive"
              value={checkList}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12}>
            <FormCheckBox
              label="Validate each filed if required"
              name="isActive"
              value={checkList}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12}>
            <FormCheckBox
              label="Show notifications after record is save/update/delete"
              name="isActive"
              value={checkList}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12}>
            <FormCheckBox
              label="Show spinner on buton at save/update/delete"
              name="isActive"
              value={checkList}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12}>
            <FormCheckBox
              label="Show notifications on errors"
              name="isActive"
              value={checkList}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12}>
            <FormCheckBox
              label="Verify Page title and breadcrum are right"
              name="isActive"
              value={checkList}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12}>
            <FormCheckBox
              label="Clean code from warnings and errors"
              name="isActive"
              value={checkList}
              onChange={handleInputChange}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

function IconButtons() {
  return (
    <Card mb={6}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Icon Buttons
        </Typography>
        <Typography variant="body2" gutterBottom>
          Sometimes you might want to have icons for certain button to enhance
          the UX.
        </Typography>

        <Button mr={2} variant="contained" color="primary">
          Add
          <AddIcon />
        </Button>
        <Button mr={2} variant="contained" color="secondary">
          Edit
          <EditIcon />
        </Button>
        <Button mr={2} variant="contained">
          Upload&nbsp;
          <CloudUploadIcon />
        </Button>
        <Button mr={2} variant="contained" disabled color="secondary">
          <KeyboardVoiceIcon />
          Talk
        </Button>
        <Button mr={2} variant="contained" size="small">
          <SaveIcon />
          Save
        </Button>
      </CardContent>
    </Card>
  );
}

function Buttons() {
  return (
    <React.Fragment>
      <Helmet title="Buttons" />
      <Typography variant="h3" gutterBottom display="inline">
        Buttons
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/">
          Dashboard
        </Link>
        <Link component={NavLink} to="/">
          Components
        </Link>
        <Typography>Buttons</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12} md={6}>
          <StoryBookPage />
          <OutlinedButtons />
          <TextButtons />
          <IconButtons />
        </Grid>
        <Grid item xs={12} md={6}>
          <ButtonSizes />
          <FloatingActionButtons />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default Buttons;
