import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { NumericFormat } from "react-number-format";
import { Mode } from "src/ts/types";
import useChillerStudy from "../hooks/useChillerStudy";
import { ISystemOfMeasurement } from "src/ts/interfaces";

interface Props {
  mode: Mode;
  disableStudy: boolean;
  isLoading: boolean;
  systemOfMeasurement: ISystemOfMeasurement;
}

const ChillerResults = ({
  mode,
  disableStudy,
  isLoading,
  systemOfMeasurement,
}: Props) => {
  const { evaporatorValues: values } = useChillerStudy();

  return (
    <>
      <TableContainer>
        <Table style={{ marginBottom: "0px" }}>
          <TableHead>
            <TableRow>
              <TableCell style={{ textAlign: "center" }}>
                Actual {systemOfMeasurement.get("btuh")}
              </TableCell>
              <TableCell style={{ textAlign: "center" }}>Actual kW</TableCell>
              <TableCell style={{ textAlign: "center" }}>
                Actual kW/Ton
              </TableCell>
              <TableCell style={{ textAlign: "center" }}>C.O.P.</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell style={{ textAlign: "center" }}>
                <NumericFormat
                  displayType="text"
                  value={values.actualChillerBTUH}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  thousandSeparator={true}
                />
              </TableCell>
              <TableCell style={{ textAlign: "center" }}>
                <NumericFormat
                  displayType="text"
                  value={values.actualChillerKws}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  thousandSeparator={true}
                />
              </TableCell>
              <TableCell style={{ textAlign: "center" }}>
                <NumericFormat
                  displayType="text"
                  value={values.chillerKwTon}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  thousandSeparator={true}
                />
              </TableCell>
              <TableCell style={{ textAlign: "center" }}>
                <NumericFormat
                  displayType="text"
                  value={values.chillerCOP}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  thousandSeparator={true}
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default ChillerResults;
