import Popup from "../Popup";
import {
  FormButton,
  FormSelect,
  FormRadioGroup,
  FormAcceptButton,
  FormCancelButton,
  FormText,
} from "../formControls";
import { Stack, Typography, IconButton, Grid } from "@mui/material";
import userService from "src/services/userService";
import LocalEnhancedTable from "../localTable/LocalTable";
import { ColumnType } from "src/types/enhancedTable";
import { TableData } from "src/ts/interfaces/user";
import { useEffect, useState } from "react";
import { useAuth, useForm } from "src/hooks";
import { Add, Delete } from "@mui/icons-material";
import useLog from "src/hooks/useLog";
import {
  OPTIONSHAREBYEMAIL,
  radioInformation,
  radioInformationContractors,
  radioInformationExternal,
  radioInformationTeamMember,
} from "src/constants";
import { IShareStudiesEmail } from "src/ts/interfaces";
import projectContestService from "src/services/projectContestService";

interface ShareByEmailProps {
  sendbyEmailShow: boolean;
  setSendbyEmailShow: (val: boolean) => void;
  dataTeamMember?: TableData[];
  dataUserCurrent?: TableData[];
  sendEmailFunction: (user: TableData[]) => void;
  children?: React.ReactNode;
  hideLogSuccess?: boolean;
  onlyTeamMember?: boolean;
  sendToExternal?: boolean;
  sendToContractors?: boolean;
  projectId?: number | null;
}

const initialValues = {
  radioButtonSelect: "0",
  formSelectUser: "-1",
};

const initialValuesTeamMember = {
  radioButtonSelect: "1",
  formSelectUser: "-1",
};

interface IComboBoxTeamUser {
  key: number;
  value: string;
}
const getColumns = (
  handleOnClickDelete: (val: number | undefined) => void
): ColumnType[] => [
  {
    id: "name",
    label: "Name",
    type: "custom",
    sort: true,
    callback: (row: TableData) => <>{row.name}</>,
  },
  {
    id: "email",
    label: "Email",
    type: "custom",
    sort: true,
    callback: (row: TableData) => <>{row.email}</>,
  },
  {
    id: "deleteEmail",
    label: "",
    type: "custom",
    sort: false,
    callback: (row: TableData) => (
      <>
        <Stack direction="row">
          <IconButton
            aria-label="delete"
            size="large"
            onClick={() => {
              handleOnClickDelete(row.id);
            }}
          >
            <Delete />
          </IconButton>
        </Stack>
      </>
    ),
  },
];

export default function ShareByEmailPopUpComponent({
  sendbyEmailShow,
  setSendbyEmailShow,
  dataTeamMember,
  dataUserCurrent,
  sendEmailFunction,
  children,
  hideLogSuccess,
  onlyTeamMember,
  sendToExternal,
  sendToContractors,
  projectId,
}: ShareByEmailProps) {
  const [selectRadioButton, setSelectRadioButton] = useState(
    !onlyTeamMember
      ? initialValues.radioButtonSelect
      : initialValuesTeamMember.radioButtonSelect
  );
  const [selectComboBoxUsers, setSelectComboBoxUsers] = useState<TableData[]>(
    []
  );
  const [selectComboBoxTeams, setSelectComboBoxTeams] = useState<TableData[]>(
    []
  );
  const initialValuesExternal: IShareStudiesEmail = {
    id: 0,
    name: "",
    lastName: "",
    email: "",
    type: "",
  };
  const [selectComboBoxContractors, setSelectComboBoxContractors] = useState<
    TableData[]
  >([]);

  const [errors, setErrors] = useState<Record<string, string>>({});

  const validate = () => {
    let temp: Record<string, string> = { ...errors };
    temp.name = !externalData.name ? "Name is required." : "";

    temp.lastName = !externalData.lastName ? "Last name is required." : "";

    temp.email = !externalData.email ? "Email is required." : "";

    if (temp.email === "") {
      const validateEmail = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
      if (!validateEmail.test(externalData.email))
        temp.email = "Insert a correct email: demo@gmail.com";
    }

    setErrors({
      ...temp,
    });

    return Object.values(temp).every((x) => x === "");
  };

  const [externalData, setExternalData] = useState(initialValuesExternal);
  const [selectComboBox, setSelectComboBox] = useState<IComboBoxTeamUser[]>([]);
  const [dataTable, setDataTable] = useState<TableData[]>([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const { user } = useAuth();

  const { log } = useLog();
  const handleClosePopUp = () => {
    setSendbyEmailShow(false);
  };

  const handleOnClickDelete = (id: number | undefined) => {
    var arrayDelete = dataTable.filter((item: TableData) => {
      return item.id !== id;
    });
    setDataTable(arrayDelete);
  };

  let columns = getColumns(handleOnClickDelete);

  const sortByName = (a: TableData, b: TableData) => {
    if (a.name && b.name) {
      if (a.name.toUpperCase() < b.name.toUpperCase()) {
        return -1;
      }
      if (a.name.toUpperCase() > b.name.toUpperCase()) {
        return 1;
      }
      return 0;
    }
    return 0;
  };

  useEffect(() => {
    const getTeamMember = async () => {
      try {
        const response = await userService.teamMemberList();
        const sortTeamMember = response.data;

        sortTeamMember.sort(sortByName);
        setSelectComboBoxTeams(sortTeamMember);
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      }
    };

    const getUserCurrenCompany = async () => {
      try {
        const response = await userService.currentCompanyList();

        const uniqueCurrentUser = response.data.filter(
          (obj: TableData, index: number) => {
            if (obj.name?.includes("In Process")) {
              return (
                index ===
                response.data.findIndex((o: TableData) => {
                  return obj.name1 === o.name1;
                })
              );
            } else {
              return (
                index ===
                response.data.findIndex((o: TableData) => obj.name1 === o.name1)
              );
            }
          }
        );
        uniqueCurrentUser.sort(sortByName);
        setRefresh(true);
        setSelectComboBoxUsers(uniqueCurrentUser);
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      }
    };

    const getTeamMemberNewValue = () => {
      setSelectComboBoxTeams(dataTeamMember ?? []);
    };

    const getUserCurrenCompanyNewValue = () => {
      if (dataUserCurrent !== undefined) {
        const uniqueCurrentUser = dataUserCurrent.filter(
          (obj: TableData, index: number) => {
            if (obj.name?.includes("In Process")) {
              return (
                index ===
                dataUserCurrent.findIndex(
                  (o: TableData) =>
                    obj.name1 === o.name1 && obj.lastName === o.lastName
                )
              );
            } else {
              return (
                index ===
                dataUserCurrent.findIndex((o: TableData) => obj.name === o.name)
              );
            }
          }
        );

        uniqueCurrentUser.sort(sortByName);

        setRefresh(true);
        setSelectComboBoxUsers(uniqueCurrentUser);
      }
    };

    const getContractors = async () => {
      try {
        const response = await projectContestService.getInternalApprovers(
          user?.companyId,
          projectId ?? 0
        );

        const sortContractors = response.data;
        sortContractors.sort(sortByName);
        setSelectComboBoxContractors(sortContractors);
        setRefresh(true);
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      }
    };

    if (sendToContractors) {
      getContractors();
      if (dataTeamMember !== undefined) getTeamMemberNewValue();
      else getTeamMember();
    } else {
      if (dataTeamMember !== undefined || dataUserCurrent !== undefined) {
        getTeamMemberNewValue();
        getUserCurrenCompanyNewValue();
      } else {
        getTeamMember();
        getUserCurrenCompany();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setValues({ ...values, formSelectUser: OPTIONSHAREBYEMAIL.DEFAULT_VALUE });

    if (selectRadioButton === OPTIONSHAREBYEMAIL.TEAM_MEMBER) {
      setSelectComboBox([]);
      setSelectComboBox(
        selectComboBoxTeams.map((item: TableData) => {
          return {
            ...selectComboBoxTeams,
            key: item.id ?? 0,
            value: `${item.name}`,
          };
        })
      );
    } else {
      if (sendToContractors) {
        setSelectComboBox([]);
        setSelectComboBox(
          selectComboBoxContractors.map((item: TableData) => {
            return {
              ...selectComboBoxContractors,
              key: item.id ?? 0,
              value: `${item.name}`,
            };
          })
        );
      } else {
        if (selectComboBoxUsers.length > 0) {
          setSelectComboBox([]);
          setSelectComboBox(
            selectComboBoxUsers.map((item: TableData) => {
              return {
                ...selectComboBoxUsers,
                key: item.id ?? 0,
                value: `${item.name1} ${item.lastName}`,
              };
            })
          );
        } else {
          setSelectComboBox(
            selectComboBoxUsers.map((item: TableData) => {
              return {
                ...selectComboBoxUsers,
                key: item.id ?? 0,
                value: `${item.name1} ${item.lastName}`,
              };
            })
          );
        }
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectRadioButton, refresh]);

  const { values, setValues, handleInputChange } = useForm(
    onlyTeamMember ? initialValuesTeamMember : initialValues,
    false,
    []
  );

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValues({ ...values, [e.target.name]: e.target.value });
    setSelectRadioButton(e.target.value);
  };

  const handleClickAdd = () => {
    if (
      values.formSelectUser === OPTIONSHAREBYEMAIL.DEFAULT_VALUE &&
      selectRadioButton !== OPTIONSHAREBYEMAIL.EXTERNAL
    )
      return log.error("Select a valid Team Member or a Licensed User");

    if (selectRadioButton === OPTIONSHAREBYEMAIL.LICENSEED_USER) {
      var searchArray: any;
      if (sendToContractors) {
        searchArray = selectComboBoxContractors.filter((item: TableData) => {
          return item.id === values.formSelectUser;
        });
      } else {
        searchArray = selectComboBoxUsers.filter((item: TableData) => {
          return item.id === values.formSelectUser;
        });
      }

      searchArray[0] = { ...searchArray[0], userType: "LicenseedUser" };

      var duplicateData = dataTable.filter((item: TableData) => {
        return (
          item.id === values.formSelectUser ||
          item.email === searchArray[0].email
        );
      }).length;

      duplicateData > 0
        ? log.warning("This Email is already in the list")
        : setDataTable([...dataTable, searchArray[0]]);
    } else if (selectRadioButton === OPTIONSHAREBYEMAIL.TEAM_MEMBER) {
      var searchArrayTema = selectComboBoxTeams.filter((item: TableData) => {
        return item.id === values.formSelectUser;
      });
      searchArrayTema[0] = { ...searchArrayTema[0], userType: "TeamMember" };
      var duplicateDataTeam = dataTable.filter((item: TableData) => {
        return (
          item.id === values.formSelectUser ||
          item.email === searchArrayTema[0].email
        );
      }).length;

      duplicateDataTeam > 0
        ? log.warning("This Email is already in the list")
        : setDataTable([...dataTable, searchArrayTema[0]]);
    } else {
      if (!validate()) return;

      const duplicateDataTeam = dataTable.filter((item: TableData) => {
        return item.email === externalData.email;
      }).length;

      if (duplicateDataTeam > 0)
        log.warning("This Email is already in the list");
      else {
        dataTable.push({
          id: 0,
          name: `${externalData.name} ${externalData.lastName}`,
          lastName: externalData.lastName,
          email: externalData.email,
          userType: "email",
        });

        setDataTable([...dataTable]);
        setExternalData(initialValuesExternal);
      }
    }
  };

  const handleinputChangeExternal = (e: any) => {
    const { name, value } = e.target;
    let temp: Record<string, string> = { ...errors };

    if (name === "name") temp.name = "";
    if (name === "lastName") temp.lastName = "";
    if (name === "email") temp.email = "";
    setErrors({
      ...temp,
    });

    setExternalData({ ...externalData, [name]: value });
  };

  const handleClickAccepted = async () => {
    if (dataTable.length === 0) {
      log.error("Please select at least one email");
    } else {
      setIsSubmitting(true);
      try {
        sendEmailFunction(dataTable);

        if (!hideLogSuccess) log.success("The email was successfully sent.");
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      } finally {
        setDataTable([]);
        setSendbyEmailShow(false);
        setIsSubmitting(false);
      }
    }
  };

  return (
    <>
      <Popup
        title="Send document(s) by email"
        openPopup={sendbyEmailShow}
        setOpenPopup={setSendbyEmailShow}
        onClose={handleClosePopUp}
        size="md"
      >
        <>
          {children}
          <Grid container>
            <Grid
              item
              xs={1}
              textAlign={"right"}
              paddingTop={onlyTeamMember ? 3 : 7}
              paddingRight={4}
            >
              <Typography>Sent To:</Typography>
            </Grid>
            <Grid item xs={2}>
              <FormRadioGroup
                name="radioButtonSelect"
                label=""
                value={values.radioButtonSelect}
                items={
                  onlyTeamMember
                    ? radioInformationTeamMember
                    : sendToExternal
                    ? radioInformationExternal
                    : sendToContractors
                    ? radioInformationContractors
                    : radioInformation
                }
                onChange={handleOnChange}
                defaultValue={selectRadioButton}
              />
            </Grid>
            <Grid item xs={7} paddingTop={onlyTeamMember ? 1 : 5}>
              {selectRadioButton === OPTIONSHAREBYEMAIL.TEAM_MEMBER && (
                <FormSelect
                  name="formSelectUser"
                  label=""
                  value={values.formSelectUser}
                  onChange={handleInputChange}
                  defaultValue={{
                    key: "-1",
                    value: "Select Team Member",
                  }}
                  options={selectComboBox}
                  fullWidth={true}
                />
              )}
              {selectRadioButton === OPTIONSHAREBYEMAIL.LICENSEED_USER && (
                <FormSelect
                  name="formSelectUser"
                  label=""
                  value={values.formSelectUser}
                  onChange={handleInputChange}
                  defaultValue={{
                    key: "-1",
                    value: sendToContractors
                      ? "Select Contractor"
                      : "Select Licensed User",
                  }}
                  options={selectComboBox}
                  fullWidth={true}
                />
              )}
              {selectRadioButton === OPTIONSHAREBYEMAIL.EXTERNAL && (
                <>
                  <Grid container spacing={3}>
                    <Grid item xs={6}>
                      <FormText
                        name="name"
                        label="Name"
                        value={externalData.name}
                        onChange={handleinputChangeExternal}
                        disabled={false}
                        showSkeleton={false}
                        error={errors.name}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <FormText
                        name="lastName"
                        label="Last Name"
                        value={externalData.lastName}
                        onChange={handleinputChangeExternal}
                        disabled={false}
                        showSkeleton={false}
                        error={errors.lastName}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormText
                        type="email"
                        name="email"
                        label="Email"
                        value={externalData.email}
                        onChange={handleinputChangeExternal}
                        disabled={false}
                        showSkeleton={false}
                        error={errors.email}
                      />
                    </Grid>
                  </Grid>
                </>
              )}
            </Grid>
            <Grid
              item
              xs={2}
              paddingTop={onlyTeamMember ? 2 : 6}
              paddingLeft={2}
            >
              <FormButton
                text="Add"
                startIcon={<Add />}
                size="small"
                variant="outlined"
                onClick={handleClickAdd}
              />
            </Grid>
          </Grid>
          {sendToExternal === true ? (
            <></>
          ) : (
            <>
              <Stack direction="row" justifyContent="center">
                <Typography>
                  If outside external message is required, create your target
                  person as a team member
                </Typography>
              </Stack>
            </>
          )}

          <Stack mt={10} mb={10}>
            <LocalEnhancedTable<TableData>
              refreshGrid={false}
              columns={columns}
              data={dataTable}
              defaultRowPerPage={25}
              hidePagination={true}
              defaultSortColumn="id"
            />
          </Stack>
        </>

        <Stack direction="row" spacing={2}>
          <FormCancelButton
            onClick={() => {
              setDataTable([]);
              setSendbyEmailShow(false);
            }}
            isSubmitting={isSubmitting}
            text="Cancel"
          />

          <FormAcceptButton
            onClick={handleClickAccepted}
            isSubmitting={isSubmitting}
          />
        </Stack>
      </Popup>
    </>
  );
}
