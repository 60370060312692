import Popup from "src/components/Popup";
import { FormButton, FormSelect } from "src/components/formControls";
import { Stack, Typography, Grid, Chip as MuiChip } from "@mui/material";
import { useEffect, useState } from "react";
import { userService } from "src/services";
import { IKeyValue } from "src/ts/interfaces";
import { useAsyncQuery, useForm, useLog } from "src/hooks";
import assetTransferService from "src/services/assetTransferService";
import { ASSET_TILE, AssetsConstant } from "src/constants";
import { ITransferHistory } from "src/ts/interfaces/companyAssets";
import { ColumnType } from "src/types/enhancedTable";
import { SpacingProps, spacing } from "@mui/system";
import styled from "@emotion/styled";
import LocalEnhancedTable from "src/components/localTable/LocalTable";

interface ChipProps extends SpacingProps {
  component?: React.ElementType;
  href?: string;
  icon?: JSX.Element | null;
}

const Chip = styled(MuiChip)<ChipProps>(spacing);

interface HistoryTechnicianProps {
  historytechnicanShowPopUp: boolean;
  setHistorytechnicanShowPopUp: (val: boolean) => void;
  assetCode: string | undefined;
  companyId: number;
}

const initialValues = {
  selectedTransfer: "-1",
};

const getcolumnsEquipment = (): ColumnType[] => [
  {
    id: "serialNumber",
    label: "Serial Number",
    type: "string",
    sort: false,
  },
  {
    id: "model",
    label: "Model",
    type: "string",
    sort: false,
  },
  {
    id: "name",
    label: "Name",
    type: "string",
    sort: false,
  },
  {
    id: "transferDate",
    label: "Transfer Date",
    type: "utcDate",
    sort: false,
    format: "MM/DD/YYYY",
  },
  {
    id: "trasnferBy",
    label: "Transfer By",
    type: "string",
    sort: false,
  },
  {
    id: "desicion",
    label: "Decision",
    type: "custom",
    sort: false,
    callback: (row: ITransferHistory) => {
      const text = row.desicion === "Created" ? "In Progress" : row.desicion;
      const color =
        row.desicion === "Rejected"
          ? "error"
          : row.desicion === "Accepted"
          ? "success"
          : "primary";
      return (
        <>
          <Chip label={text} color={color} m={1} size="small" />
        </>
      );
    },
  },
  {
    id: "comments",
    label: "Comments",
    type: "string",
    sort: false,
  },
];
const getcolumnsVehicle = (): ColumnType[] => [
  {
    id: "make",
    label: "Make",
    type: "string",
    sort: false,
  },
  {
    id: "modelVehicle",
    label: "Model",
    type: "string",
    sort: false,
  },
  {
    id: "year",
    label: "Year",
    type: "string",
    sort: false,
  },
  {
    id: "licensePlate",
    label: "License Plate",
    type: "string",
    sort: false,
  },
  {
    id: "transferDate",
    label: "Transfer Date",
    type: "utcDate",
    sort: false,
    format: "MM/DD/YYYY",
  },
  {
    id: "trasnferBy",
    label: "Transfer By",
    type: "string",
    sort: false,
  },
  {
    id: "desicion",
    label: "Decision",
    type: "custom",
    sort: false,
    callback: (row: ITransferHistory) => {
      const text = row.desicion === "Created" ? "In Progress" : row.desicion;
      const color =
        row.desicion === "Rejected"
          ? "error"
          : row.desicion === "Accepted"
          ? "success"
          : "primary";
      return (
        <>
          <Chip label={text} color={color} m={1} size="small" />
        </>
      );
    },
  },
  {
    id: "comments",
    label: "Comments",
    type: "string",
    sort: false,
  },
];
export default function HistoryTechnicianPopUp({
  historytechnicanShowPopUp,
  setHistorytechnicanShowPopUp,
  assetCode,
  companyId,
}: HistoryTechnicianProps) {
  const [dataUser, setDataUser] = useState<IKeyValue<number, string>[]>([]);

  const { values, handleInputChange } = useForm(initialValues, false, {});

  const { log } = useLog();

  const { execute, data, isLoading } = useAsyncQuery<ITransferHistory[]>(
    assetTransferService.getAssetTransferGetAll
  );

  useEffect(() => {
    const getList = async () => {
      const response = await userService.getKeyUsersTransferTo(companyId);
      setDataUser(response);
    };
    getList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const dataGrid = async () => {
      if (
        assetCode !== AssetsConstant.VEHICLE &&
        assetCode !== AssetsConstant.EQUIPMENT
      ) {
        log.error("Incorrect asset");
        return;
      }
      if (values.selectedTransfer !== "-1") {
        execute(values.selectedTransfer, assetCode);
      }
    };

    dataGrid();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.selectedTransfer]);

  const columns =
    assetCode === AssetsConstant.EQUIPMENT
      ? getcolumnsEquipment()
      : getcolumnsVehicle();

  const titleAsset =
    assetCode === AssetsConstant.VEHICLE
      ? ASSET_TILE.VEHICLE
      : ASSET_TILE.EQUIPMENT;

  const noDataFound =
    data === null
      ? "No results were found. "
      : "No Recommended Test Equipment has been assigned to the selected technician yet.";

  return (
    <>
      <Popup
        title={`Assignment history of ${titleAsset}`}
        openPopup={historytechnicanShowPopUp}
        setOpenPopup={setHistorytechnicanShowPopUp}
        onClose={() => {
          setHistorytechnicanShowPopUp(false);
        }}
        size="md"
      >
        <>
          <Grid container>
            <Grid xs={6}>
              <Stack direction={"row"}>
                <Typography mt={2} mr={2}>
                  Auditor/Technician
                </Typography>
                <FormSelect
                  name="selectedTransfer"
                  label="Transfer to"
                  value={values.selectedTransfer}
                  options={dataUser}
                  onChange={handleInputChange}
                  defaultValue={{
                    key: "-1",
                    value: " Please Select",
                  }}
                />
              </Stack>
            </Grid>
          </Grid>
          <Stack mt={3}>
            <LocalEnhancedTable<ITransferHistory>
              columns={columns}
              data={data}
              showSkeleton={isLoading}
              orderColumn="desc"
              noDataTitle={noDataFound}
            />
          </Stack>
        </>
        <Stack direction="row" spacing={3}>
          <FormButton
            size="small"
            text="Close"
            variant="outlined"
            onClick={() => {
              setHistorytechnicanShowPopUp(false);
            }}
          />
        </Stack>
      </Popup>
    </>
  );
}
