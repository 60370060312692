import { useEffect, useState } from "react";
import Popup from "src/components/Popup";
import {
  FormButton,
  FormSelect,
  FormTextArea,
} from "src/components/formControls";
import {
  Divider as MuiDivider,
  Grid,
  Stack,
  FormControlLabel,
  Typography,
  Radio,
  RadioGroup,
  Chip as MuiChip,
} from "@mui/material";
import { useAuth, useForm, useLog } from "src/hooks";
import styled from "@emotion/styled";
import { SpacingProps, spacing } from "@mui/system";
import teamMemberServices from "src/services/teamMemberServices";
import { IKeyValue, IUserContactInfo } from "src/ts/interfaces";
import { userService } from "src/services";
import { ColumnType } from "src/types/enhancedTable";
import { changeOrdersStatus } from "src/constants";
import { GridActionButton } from "src/components/gridControls";
import LocalEnhancedTable from "src/components/localTable/LocalTable";
import { convertUTCDateToLocalDate } from "src/utils/utils";
import { IChangeOrders } from "src/ts/interfaces/changeWorkOrder";
import {
  IChangeOrdersForward,
  IChangeOrdersForwardRequest,
} from "src/ts/interfaces/changeOrdersForward";
import changeOrdersForwardService from "src/services/changeOrdersForwardService";
import DialogMessagePopup from "../DialogMessagePopup";
import changeOrdersService from "src/services/changeOrdersService";
import moment from "moment";
const Divider = styled(MuiDivider)(spacing);
interface ChipProps extends SpacingProps {
  component?: React.ElementType;
  href?: string;
  icon?: JSX.Element | null;
}
interface ChangeOrdersForwardPopUpProps {
  showForwardPopUp: boolean;
  setShowForwardPopUp: (val: boolean) => void;
  changeOrders: IChangeOrders;
  isForward: boolean;
  isMyOrder: boolean;
  refreshMainTable: () => void;
  projectId: number;
  projectCompleted: boolean;
}
const Chip = styled(MuiChip)<ChipProps>(spacing);
const initialValues: IChangeOrders = {
  id: 0,
  projectId: 0,
  notes: "",
  createdDate: new Date(),
  updatedDate: new Date(),
  codeVerification: "",
  rejectedComment: "",
  email: "",
  firstName: "",
  lastName: "",
  title: "",
  address: "",
  city: "",
  state: "",
  zipCode: "",
  workPhoneNumber: "",
  extension: "",
  cellPhoneNumber: "",
  isTeamMember: false,
};

const columnsForward = (handleDelete: (id: number) => void): ColumnType[] => [
  {
    id: "name",
    label: "Name",
    type: "custom",
    sort: false,
    callback: (row: IChangeOrdersForward) => {
      return (
        <>
          {row?.name} {row?.lastName}
        </>
      );
    },
  },
  {
    id: "email",
    label: "Email",
    type: "string",
    sort: false,
  },
  {
    id: "actions",
    label: "Actions",
    type: "custom",
    sort: false,
    callback: (row: IChangeOrdersForward) => {
      return (
        <>
          <GridActionButton
            type="delete"
            onClick={() => {
              handleDelete(row.id);
            }}
            tooltip="Remove"
          />
        </>
      );
    },
  },
];

export default function ChangeOrdersForwardPopUp({
  showForwardPopUp,
  setShowForwardPopUp,
  changeOrders,
  isForward,
  isMyOrder,
  refreshMainTable,
  projectId,
  projectCompleted,
}: ChangeOrdersForwardPopUpProps) {
  const validate = (fieldValues = values) => {
    let temp: Record<string, string> = { ...errors };

    if (fieldValues.rejectedComment === "" && isMyOrder) {
      temp.rejectedComment = "This field is required";
    } else {
      temp.rejectedComment = "";
    }

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const [teamMembers, setTeamMembers] = useState<IKeyValue<number, string>[]>(
    []
  );
  const [isLoading, setIsLoading] = useState(false);
  const [finalDesicion, setFinalDesicion] = useState(false);
  const [finalDesicionModal, setFinalDesicionModal] = useState(false);
  const [refreshTable, setRefreshTable] = useState(false);
  const [selectedUser, setSelectedUser] = useState<IUserContactInfo>();
  const [selectedChangeOrders, setSelectedChangeOrders] =
    useState<IChangeOrders>();
  const [changeOrdersForwardList, setChangeOrdersForwardList] = useState<
    IChangeOrdersForward[]
  >([]);
  const [users, setUsers] = useState<IKeyValue<number, string>[]>([]);
  const { values, setValues, errors, setErrors, handleInputChange } = useForm(
    initialValues,
    false,
    validate
  );
  const { log } = useLog();
  const { user } = useAuth();

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await teamMemberServices.keyValue();
        setTeamMembers(response.data);

        const responseInternals = await userService.getDistinctTechnicianList(
          projectId
        );

        const keyValues = responseInternals.data.map(
          (item: { id: any; name: any; lastName: string }) => {
            return {
              key: item.id,
              value: `${item.name} ${item.lastName}`,
            };
          }
        );
        setUsers(keyValues);
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      }
    };
    if (user?.companyId) getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.companyId]);

  useEffect(() => {
    const getData = async () => {
      setSelectedChangeOrders(changeOrders);
      if (changeOrders.isTeamMember) {
        var result = await teamMemberServices.getContactInfo(
          changeOrders?.teamMemberId ?? 0
        );
        setSelectedUser(result.data);
      } else {
        var resultUser = await userService.getUserInfo(
          changeOrders?.userId ?? 0
        );
        setSelectedUser(resultUser.data);
      }
      setShowForwardPopUp(true);
    };
    if (changeOrders.id > 0) getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changeOrders.id]);

  useEffect(() => {
    const getData = async () => {
      await refreshForwardTable(changeOrders.id);
    };
    if (changeOrders.id > 0) getData();
  }, [changeOrders.id, refreshTable]);

  useEffect(() => {
    values.rejectedComment = "";
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showForwardPopUp]);

  const handleTeamMember = async (e: any) => {
    values.userId = null;
    values.teamMemberId = e.target.value;
    setValues({ ...values });
    if (!isForward) {
      var result = await teamMemberServices.getContactInfo(values.teamMemberId);
      setSelectedUser(result.data);
    }
  };

  const handleUser = async (e: any) => {
    values.teamMemberId = null;
    values.userId = e.target.value;
    setValues({ ...values });
    if (!isForward) {
      var result = await userService.getUserInfo(values.userId);
      setSelectedUser(result.data);
    }
  };

  const handleChangeRadio = () => {
    if (!isForward) setSelectedUser(undefined);
  };

  const refreshForwardTable = async (id: number) => {
    const forward = await changeOrdersForwardService.getAll(id);
    setChangeOrdersForwardList(forward.data);
  };

  const handleAddUSerForward = async () => {
    const sendForward: IChangeOrdersForwardRequest = {
      orderId: selectedChangeOrders?.id ?? 0,
    };
    if (values.isTeamMember) sendForward.teamMemberId = values.teamMemberId;
    else sendForward.userId = values.userId;
    await changeOrdersForwardService.create(sendForward);
    setRefreshTable(!refreshTable);
  };

  const handleDelete = async (id: number) => {
    await changeOrdersForwardService.remove(id);
    setRefreshTable(!refreshTable);
  };

  const handleSendForward = async () => {
    if (changeOrdersForwardList.length === 0) {
      log.warning(
        "Is required to select an internal/external user from the list."
      );
      return;
    }
    await changeOrdersForwardService.send(selectedChangeOrders?.id ?? 0);
    log.success(
      `The Change/Work Order was successfully sent to the selected email ${
        changeOrdersForwardList.length > 1 ? "addresses" : "address"
      }`
    );
    setShowForwardPopUp(false);
  };

  const handleAcceptReject = async (desicion: boolean) => {
    if (!desicion && !validate()) return;
    setFinalDesicion(desicion);
    setFinalDesicionModal(true);
  };
  const handleSaveAnswer = async () => {
    if (selectedChangeOrders) {
      selectedChangeOrders.changeOrdersStatusId = finalDesicion ? 2 : 3;
      selectedChangeOrders.rejectedComment = values.rejectedComment;
      selectedChangeOrders.updatedDate = new Date();
      await changeOrdersService.ChangeStatusCurrent(selectedChangeOrders);
      setFinalDesicionModal(false);
      refreshMainTable();
      setShowForwardPopUp(false);
    }
  };

  return (
    <>
      <Popup
        title={`${isForward ? "Forward " : ""}Change Work Order`}
        openPopup={showForwardPopUp}
        setOpenPopup={setShowForwardPopUp}
        onClose={() => {
          setShowForwardPopUp(false);
        }}
        size="md"
      >
        <>
          <Grid container>
            <Grid item xs={12}>
              <Typography>
                <Typography variant="subtitle2" display="inline">
                  Change Work Order:
                </Typography>
                <Typography display="inline">
                  {" "}
                  {selectedChangeOrders?.notes}
                </Typography>
              </Typography>
              <Typography>
                <Typography variant="subtitle2" display="inline">
                  {selectedChangeOrders?.changeOrdersStatusId ===
                    changeOrdersStatus.PendingApproval.id && (
                    <Chip
                      label={selectedChangeOrders?.changeOrdersStatus?.name}
                      color="default"
                      m={1}
                      size="small"
                    />
                  )}
                  {selectedChangeOrders?.changeOrdersStatusId ===
                    changeOrdersStatus.Approved.id && (
                    <Chip
                      label={selectedChangeOrders?.changeOrdersStatus?.name}
                      color="success"
                      m={1}
                      size="small"
                    />
                  )}
                  {selectedChangeOrders?.changeOrdersStatusId ===
                    changeOrdersStatus.Rejected.id && (
                    <Chip
                      label={selectedChangeOrders?.changeOrdersStatus?.name}
                      color="error"
                      m={1}
                      size="small"
                    />
                  )}
                </Typography>
                <Typography display="inline">
                  {convertUTCDateToLocalDate(
                    selectedChangeOrders?.updatedDate?.toString() || ""
                  )?.format("yyyy-MM-DD hh:mm:ss a")}
                </Typography>
              </Typography>
              <Typography>
                <Grid item xs={12}>
                  <Typography display="inline">
                    <Typography variant="subtitle2" display="inline">
                      Created on:{" "}
                    </Typography>
                    <Typography display="inline">
                      {" "}
                      {moment(selectedChangeOrders?.createdDate).format(
                        "MM/DD/YYYY"
                      )}
                    </Typography>
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography display="inline">
                    <Typography variant="subtitle2" display="inline">
                      Created by:{" "}
                    </Typography>
                    <Typography display="inline">
                      {" "}
                      {selectedChangeOrders?.createdBy?.getFullName}
                    </Typography>
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography display="inline">
                    <Typography variant="subtitle2" display="inline">
                      Comments:{" "}
                    </Typography>
                    <Typography display="inline">
                      {" "}
                      {selectedChangeOrders?.rejectedComment}
                    </Typography>
                  </Typography>
                </Grid>
              </Typography>
              <Divider my={3}></Divider>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle2">
                Directed or Advise by:
              </Typography>
              <Typography display="inline">
                <Typography variant="subtitle2" display="inline">
                  Name:
                </Typography>
                <Typography display="inline">
                  {" "}
                  {selectedUser?.firstName} {selectedUser?.lastName},
                </Typography>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography display="inline">
                <Typography variant="subtitle2" display="inline">
                  Email:{" "}
                </Typography>
                <Typography display="inline"> {selectedUser?.email}</Typography>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography display="inline">
                <Typography variant="subtitle2" display="inline">
                  Cell Phone Number:
                </Typography>
                <Typography display="inline">
                  {" "}
                  {selectedUser?.cellPhoneNumber}
                </Typography>
              </Typography>

              <Divider my={3}></Divider>
            </Grid>
            {isMyOrder && selectedChangeOrders?.changeOrdersStatusId === 1 && (
              <Grid item xs={12}>
                <FormTextArea
                  name={"rejectedComment"}
                  label={"Comments"}
                  value={values.rejectedComment}
                  placeholder={"Comments"}
                  onChange={handleInputChange}
                  error={errors.rejectedComment}
                  disabled={projectCompleted}
                ></FormTextArea>
              </Grid>
            )}
            {isForward && (
              <>
                <Grid item xs={2}>
                  <Typography pt={2} variant="subtitle2">
                    Forward to:
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <RadioGroup
                    aria-label="Approver"
                    name="isTeamMember"
                    value={values.isTeamMember}
                    onChange={handleInputChange}
                    row
                  >
                    <FormControlLabel
                      value="false"
                      control={<Radio />}
                      label="Internal"
                      onChange={handleChangeRadio}
                    />
                    <FormControlLabel
                      value="true"
                      control={<Radio />}
                      label="External"
                      onChange={handleChangeRadio}
                    />
                  </RadioGroup>
                </Grid>
                <Grid item xs={5}>
                  <Grid item mt={1}>
                    {values.isTeamMember === "true" ? (
                      <FormSelect
                        name="teamMemberId"
                        label="External User"
                        value={values.teamMemberId}
                        onChange={handleTeamMember}
                        options={teamMembers}
                        error={errors.teamMemberId}
                      />
                    ) : (
                      <FormSelect
                        name="userId"
                        label="Internal User"
                        value={values.userId}
                        onChange={handleUser}
                        options={users}
                        error={errors.userId}
                      />
                    )}
                  </Grid>
                </Grid>
                <Grid item xs={1} sx={{ textAlign: "center" }}>
                  <GridActionButton
                    type="add"
                    onClick={handleAddUSerForward}
                    tooltip="Add Forward To"
                  />
                </Grid>
                <Grid item xs={12}>
                  <LocalEnhancedTable<IChangeOrdersForward>
                    refreshGrid={true}
                    columns={columnsForward(handleDelete)}
                    data={changeOrdersForwardList}
                    showSkeleton={isLoading}
                    noDataTitle="Please select an internal/external user from the list."
                  />
                </Grid>
              </>
            )}
          </Grid>
        </>
        <Stack direction="row" spacing={3}>
          <FormButton
            size="medium"
            variant="outlined"
            text="Close"
            onClick={() => {
              setShowForwardPopUp(false);
            }}
          />
          {isForward && (
            <FormButton
              size="medium"
              text="Send Change Work Orders"
              onClick={handleSendForward}
            />
          )}
          {isMyOrder &&
            selectedChangeOrders?.changeOrdersStatusId === 1 &&
            !projectCompleted && (
              <>
                <FormButton
                  size="medium"
                  color="error"
                  text="Reject"
                  onClick={() => {
                    handleAcceptReject(false);
                  }}
                />
                <FormButton
                  size="medium"
                  color="success"
                  text="Accept"
                  onClick={() => {
                    handleAcceptReject(true);
                  }}
                />
              </>
            )}
        </Stack>
      </Popup>
      <DialogMessagePopup
        title={"Warning"}
        text={`Are you certain you want to ${
          finalDesicion ? "Accept" : "Reject"
        } this Change/Work Order?`}
        showPopup={finalDesicionModal}
        setShowPopup={setFinalDesicionModal}
        onSave={handleSaveAnswer}
        onCancel={() => {
          setFinalDesicionModal(false);
        }}
        isSubmitting={false}
      />
    </>
  );
}
