import axios from "src/utils/axios";
import { IEnergyStudyAirHydronicsDTO } from "src/ts/interfaces";

const getTemplate = (
  target: string,
  reportTypeId: number,
  testMode: string
) => {
  return axios.get<IEnergyStudyAirHydronicsDTO>(
    `EnergyStudyAirHydronics/Template?target=${target}&energyReportTypeId=${reportTypeId}&testMode=${testMode}`
  );
};

const saveSubStudyGeneric = (route: string, object: any) => {
  return axios.post(route, object);
};

const energyStudyAirHydronicsService = {
  getTemplate,
  saveSubStudyGeneric,
};

export default energyStudyAirHydronicsService;
