import { ChillerStudyProvider } from "../context/ChillerStudyContext";
import ChillerStep42 from "./ChillerStep42";

const Index = () => {
  return (
    <ChillerStudyProvider>
      <ChillerStep42 />
    </ChillerStudyProvider>
  );
};

export default Index;
