import Popup from "src/components/Popup";
import { FormButton } from "src/components/formControls";
import { Grid, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useAsyncQuery } from "src/hooks";
import templateServices from "src/services/templateServices";
import { Default, IDefaultTemplate } from "src/ts/interfaces/template";
import LocalEnhancedTable from "src/components/localTable/LocalTable";
import { ColumnType } from "src/types/enhancedTable";
import { ProjectDTO } from "src/ts/interfaces/project/projectDto";
import UseMyPreferredTemplatePopUp from "./UseMyPreferredTemplatePopUp";
import { NavigateFunction, useNavigate } from "react-router-dom";
import styled from "@emotion/styled";
import { spacing } from "@mui/system";

interface UseMyPreferredTemplateProps {
  selectTemplateShowPopUp: boolean;
  setSelectTemplateShowPopUp: (val: boolean) => void;
  projectId: number;
  project: ProjectDTO;
  setProject: (project: ProjectDTO) => void;
}

const getColumns = (
  setTemplateId: (val: number) => void,
  setUseMyPreferredTemplateShowPopUp: (val: boolean) => void,
  setTemplateName: (val: string) => void,
  navigate: NavigateFunction,
  projectId: number
): ColumnType[] => [
  { id: "name", label: "Name", type: "string", sort: false, width: "180px" },
  {
    id: "description",
    label: "Description",
    type: "string",
    sort: false,
    width: "500px",
  },
  {
    id: "createdDate",
    label: "Studies Included",
    type: "custom",
    sort: false,
    width: "300px",
    callback: (row: Default) => {
      return (
        <>
          <Stack direction={"column"} spacing={1}>
            <Typography color="warning.main">
              {row.airStudies} Configurable Air Studies
            </Typography>
            <Typography color="primary">
              {row.airHydronicWater} Configurable All Hydronic/Water Studies
            </Typography>
            <Typography>
              {row.nonConfigurable} Non-Configurable Studies
            </Typography>
          </Stack>
        </>
      );
    },
  },
  {
    id: "actionButtons",
    label: "",
    type: "custom",
    sort: false,
    callback: (row: Default) => {
      return (
        <>
          <Grid container spacing={18}>
            <Grid item xs={3}>
              <FormButton
                onClick={() => {
                  setTemplateId(row.id);
                  setTemplateName(row.name);
                  setUseMyPreferredTemplateShowPopUp(true);
                }}
                text="Apply"
                size="small"
              />
            </Grid>
            <Grid item xs={3}>
              <FormButton
                onClick={() => {
                  navigate(
                    `/app/ViewTemplateFromTolerances/${projectId}/${row.id}`
                  );
                }}
                text="View"
                size="small"
                variant="outlined"
              />
            </Grid>
          </Grid>
        </>
      );
    },
  },
];

export default function SelectTemplatePopUp({
  selectTemplateShowPopUp,
  setSelectTemplateShowPopUp,
  projectId,
  project,
  setProject,
}: UseMyPreferredTemplateProps) {
  const [useMyPreferredTemplateShowPopUp, setUseMyPreferredTemplateShowPopUp] =
    useState(false);
  const [templateId, setTemplateId] = useState(0);
  const [templateName, setTemplateName] = useState("");
  const Spacer = styled.div(spacing);

  let navigate = useNavigate();
  const {
    execute: executeDefaultTemplate,
    data: dataDefaultTemplate,
    isLoading,
  } = useAsyncQuery<IDefaultTemplate>(templateServices.getDefaultTemplate);

  useEffect(() => {
    executeDefaultTemplate();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = getColumns(
    setTemplateId,
    setUseMyPreferredTemplateShowPopUp,
    setTemplateName,
    navigate,
    projectId
  );

  return (
    <>
      <Popup
        title={"Select a Template"}
        openPopup={selectTemplateShowPopUp}
        setOpenPopup={setSelectTemplateShowPopUp}
        onClose={() => {
          setSelectTemplateShowPopUp(false);
        }}
        size="lg"
      >
        <>
          <Grid container>
            <Grid item xs={12}>
              {" "}
              <Typography variant="h6">Pre-Defined Templates</Typography>
            </Grid>
            <Grid item xs={12}>
              <LocalEnhancedTable<Default>
                columns={columns}
                data={dataDefaultTemplate?.defaults ?? []}
                showSkeleton={isLoading}
                orderColumn="desc"
                hidePagination
              />
            </Grid>
          </Grid>
          <Spacer mb={9} />
          <Grid container>
            <Grid item xs={12}>
              {" "}
              <Typography variant="h6">Custom Templates</Typography>
            </Grid>
            <Grid item xs={12}>
              <LocalEnhancedTable<Default>
                columns={columns}
                data={dataDefaultTemplate?.templates ?? []}
                showSkeleton={isLoading}
                orderColumn="desc"
                noDataTitle="No custom templates have been added."
              />
            </Grid>
          </Grid>
        </>
        <Stack direction="row" spacing={3}>
          <FormButton
            size="small"
            text="Close"
            variant="outlined"
            onClick={() => {
              setSelectTemplateShowPopUp(false);
            }}
          />
        </Stack>
      </Popup>
      {useMyPreferredTemplateShowPopUp && (
        <UseMyPreferredTemplatePopUp
          setUseMyPreferredTemplateShowPopUp={
            setUseMyPreferredTemplateShowPopUp
          }
          useMyPreferredTemplateShowPopUp={useMyPreferredTemplateShowPopUp}
          projectId={project?.id}
          project={project}
          setProject={setProject}
          selectTemplate={templateId}
          setSelectTemplateShowPopUp={setSelectTemplateShowPopUp}
          templateName={templateName}
        />
      )}
    </>
  );
}
