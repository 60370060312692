import { useState } from "react";
import useLog from "src/hooks/useLog";
import {
  Grid,
  Typography,
  Stack,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Chip as MuiChip,
  styled,
} from "@mui/material";
import Popup from "src/components/Popup";
import { ICoupon, ISystemCostsCatalog } from "src/ts/interfaces";
import { currencyformatter } from "src/utils/format";
import { FormButton, FormCancelButton } from "src/components/formControls";
import { CompanyService, CouponService } from "src/services";
import { useForm } from "src/hooks";
import { Validator } from "src/ts/types";
import DialogMessagePopup from "src/components/DialogMessagePopup";
import { Save } from "@mui/icons-material";
import purchaseService from "src/services/purchaseService";
import { IPurchaseRequest } from "src/ts/interfaces/purchase";
import systemCostCatalogService from "src/services/systemCostCatalogService";
import { useNavigate } from "react-router-dom";
import { SpacingProps, spacing } from "@mui/system";
import FormTextHideText from "src/components/formControls/FormTextHideText";

interface ChipProps extends SpacingProps {
  component?: React.ElementType;
  href?: string;
  icon?: JSX.Element | null;
}

interface PayPopupProps {
  itemCostCatalog: ISystemCostsCatalog;
  showBuyModal: boolean;
  onChangeShowBuyModal: (e: boolean) => void;
  companyId: number;
  companyName: string;
}

const PayPopup = (props: PayPopupProps) => {
  const navigate = useNavigate();
  const {
    itemCostCatalog,
    showBuyModal,
    onChangeShowBuyModal,
    companyId,
    companyName,
  } = props;
  const { log } = useLog();

  const [coupon, setCoupon] = useState<ICoupon | null>(null);
  const Chip = styled(MuiChip)<ChipProps>(spacing);

  const validate = (fieldValues = values) => {
    let temp: Record<string, string> = { ...errors };

    temp.code = new Validator(fieldValues, "code")
      .isRequired("Please enter a value for the coupon code.")
      .toString();

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, errors, setErrors, handleInputChange, resetForm } = useForm(
    { code: "" },
    false,
    validate
  );

  const [showConfirm, setShowConfirm] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const applyCouponHandler = async () => {
    if (!validate()) return;
    try {
      const response = await CouponService.getByCode(values.code);
      if (response.data.typeId !== 6) {
        log.error("The coupon code is invalid or was expired");
        return;
      }
      setCoupon(response.data);
    } catch (error) {
      log.error("The coupon code is invalid or was expired");
    } finally {
      resetForm();
    }
  };

  const closeHandler = () => {
    values.code = "";
    setCoupon(null);
    onChangeShowBuyModal(false);
  };

  const confirmPayHandler = async () => {
    setIsSubmitting(true);
    const response = await systemCostCatalogService.getByClassification(
      "Satellite"
    );
    if (response.data.length > 0) {
      const pursacheValues: IPurchaseRequest = {
        systemCostCatalogId: response.data[0]?.id,
        cuponCode: coupon?.code ?? "",
        companyId: companyId,
      };

      try {
        const result = await purchaseService.purchase(pursacheValues);
        if (result.data.statusCode !== 0) {
          log.error(result.data.resultMessage);
        } else {
          const satellite = await CompanyService.getLastSatellite(companyId);
          log.success("New satellite company was succesfully added");
          onChangeShowBuyModal(false);
          navigate(`/app/company/${satellite.data.id}`);
        }

        setIsSubmitting(false);
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
        setIsSubmitting(false);
      }
    }
  };

  return (
    <>
      <DialogMessagePopup
        title={"Information"}
        text={`Are you certain you want to create a satellite company of: ${companyName}? This action will charge ${currencyformatter.format(
          (itemCostCatalog?.pricing ?? 0) -
            ((itemCostCatalog?.pricing ?? 0) / 100) * (coupon?.value ?? 0)
        )} to your AMP Wallet.`}
        showPopup={showConfirm}
        setShowPopup={setShowConfirm}
        onSave={confirmPayHandler}
        isSubmitting={isSubmitting}
      />
      <Popup
        openPopup={showBuyModal}
        setOpenPopup={() => {}}
        title="Confirmation"
        onClose={closeHandler}
      >
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Typography sx={{ fontWeight: "bold" }}>
              In order to add a new Satellite Company the following charge will
              be made to your wallet:
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>#</TableCell>
                    <TableCell>Description</TableCell>
                    <TableCell>Price</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  <TableRow>
                    <TableCell colSpan={1}>1</TableCell>
                    <TableCell>
                      Satellite Office Registration Fee per additional satellite
                      office. One time only fee per office.
                    </TableCell>
                    <TableCell style={{ textAlign: "right" }}>
                      {currencyformatter.format(itemCostCatalog?.pricing ?? 0)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={1}></TableCell>
                    <TableCell>Subtotal:</TableCell>
                    <TableCell style={{ textAlign: "right" }}>
                      {currencyformatter.format(itemCostCatalog?.pricing ?? 0)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={1}></TableCell>
                    <TableCell>Taxes:</TableCell>
                    <TableCell style={{ textAlign: "right" }}>$0.00</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={1}></TableCell>
                    <TableCell>Discount:</TableCell>
                    <TableCell style={{ textAlign: "right" }}>
                      <Typography color="red"></Typography>
                      {currencyformatter.format(
                        ((itemCostCatalog?.pricing ?? 0) / 100) *
                          (coupon?.value ?? 0)
                      )}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={1}></TableCell>
                    <TableCell>TOTAL:</TableCell>
                    <TableCell style={{ textAlign: "right" }}>
                      {currencyformatter.format(
                        (itemCostCatalog?.pricing ?? 0) -
                          ((itemCostCatalog?.pricing ?? 0) / 100) *
                            (coupon?.value ?? 0)
                      )}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid item xs={12}>
            <Stack direction="row" justifyContent="center">
              <Typography>Promotional code:</Typography>
            </Stack>
          </Grid>
          <Grid item direction="row" xs={12}>
            <Stack direction="row" justifyContent="center" spacing={3}>
              {coupon !== null && (
                <Chip
                  label={`${coupon?.value}% OFF`}
                  color="success"
                  m={1}
                  size="medium"
                />
              )}
              <FormTextHideText
                name="code"
                label={"Enter your code"}
                value={values.code}
                onChange={handleInputChange}
              />

              <FormButton
                text="Apply Coupon"
                size="small"
                onClick={applyCouponHandler}
              />
            </Stack>
          </Grid>
        </Grid>
        <>
          <FormCancelButton
            onClick={closeHandler}
            isSubmitting={isSubmitting}
          />
          <Grid pl={2}>
            <FormButton
              text={"Pay Satellite"}
              onClick={() => {
                setShowConfirm(true);
              }}
              startIcon={<Save />}
              size="small"
              color="primary"
              isSubmitting={isSubmitting}
            />
          </Grid>
        </>
      </Popup>
    </>
  );
};

export default PayPopup;
