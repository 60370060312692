import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import {
  Grid,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
  Typography,
  Card as MuiCard,
  CardContent,
  Stack,
  Button,
  Chip as MuiChip,
  Tabs,
  Tab,
} from "@mui/material";
import { Add as AddIcon } from "@mui/icons-material";
import { spacing, SpacingProps } from "@mui/system";
import { ColumnType } from "src/types/enhancedTable";
import LocalEnhancedTable from "src/components/localTable/LocalTable";
import useAsyncQuery from "src/hooks/useAsyncQuery";
import { GridActionButton } from "src/components/gridControls";
import buildingSubType from "src/services/catalogs/buildingSubType";
import { IBuildingSubType } from "src/ts/interfaces/catalogs";
import MatchWordConfirmModal from "src/components/modals/MatchWordConfirmModal";
import { useLog } from "src/hooks";
import BuildingSubtypeForm from "./BuildingSubtypeForm";
import { Permission as PermissionTypes } from "src/ts/enums";
import { usePermissions } from "src/hooks";
interface ChipProps extends SpacingProps {
  component?: React.ElementType;
  href?: string;
  icon?: JSX.Element | null;
}
const Chip = styled(MuiChip)<ChipProps>(spacing);
const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const initialValues: IBuildingSubType = {
  id: 0,
  name: "",
  buildingTypeId: "",
  orderPosition: 0,
  buildingTypeName: "",
  statusId: 1,
};

const BuildingSubtype = () => {
  const { log } = useLog();

  const { execute, data } = useAsyncQuery<IBuildingSubType[]>(
    buildingSubType.getAll
  );
  const [refreshPage, setRefreshPage] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [confirmDialogIsOpen, setConfirmDialogIsOpen] = useState(false);
  const [currentRow, setCurrentRow] = useState<IBuildingSubType>();
  const [selectdTab, setSelectedTab] = useState(0);
  const { fullAccess } = usePermissions(PermissionTypes.Catalogs);
  const [refreshGrid, setRefreshGrid] = useState(false);
  useEffect(() => {
    const getData = async () => {
      await execute();
    };

    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshPage]);

  const handleEdit = (row: IBuildingSubType) => {
    setCurrentRow(row);
    setIsDialogOpen(true);
  };

  const selectDeleteHandler = (row: IBuildingSubType) => {
    setCurrentRow(row);
    setConfirmDialogIsOpen(true);
  };

  const deleteHandler = async () => {
    if (currentRow !== undefined && currentRow?.id > 0) {
      try {
        await buildingSubType.remove(currentRow?.id);
        log.success("The item was deleted");
        refreshGridHandler();
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      }
    }
  };

  const newHandler = () => {
    setCurrentRow(initialValues);
    setIsDialogOpen(true);
  };

  const refreshGridHandler = () => {
    setRefreshPage(!refreshPage);
  };

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
    setRefreshGrid(!refreshGrid);
  };

  const columns: ColumnType[] = [
    {
      id: "id",
      label: "#",
      type: "string",
      sort: true,
    },
    {
      id: "buildingTypeName",
      label: "Building Type",
      type: "string",
      sort: true,
    },
    {
      id: "name",
      label: "Name",
      type: "string",
      sort: true,
    },
    {
      id: "statusId",
      label: "Status",
      type: "custom",
      sort: true,
      callback: (row: IBuildingSubType) => {
        return (
          <>
            {row.statusId === 1 ? (
              <Chip label="Enabled" color="primary" m={1} size="small" />
            ) : (
              <Chip
                label="Disabled"
                color="primary"
                variant="outlined"
                m={1}
                size="small"
              />
            )}
          </>
        );
      },
    },
    {
      id: "x",
      label: "Actions",
      type: "custom",
      sort: false,
      callback: (row: IBuildingSubType) => (
        <div>
          <Stack direction="row">
            <GridActionButton
              type={fullAccess ? "edit" : "view"}
              onClick={() => {
                handleEdit(row);
              }}
              tooltip={fullAccess ? "Edit" : "View"}
            />
            {fullAccess && (
              <GridActionButton
                type="delete"
                onClick={() => selectDeleteHandler(row)}
                tooltip="Delete"
              />
            )}
          </Stack>
        </div>
      ),
    },
  ];

  return (
    <>
      <Helmet title="Building Subtype" />
      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Building Subtype
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} to="/">
              Dashboard
            </Link>
            <Typography>Building Subtype</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item>
          <div>
            {fullAccess && (
              <Button onClick={newHandler} variant="contained" color="primary">
                <AddIcon />
                {`New Building Subtype`}
              </Button>
            )}
          </div>
        </Grid>
      </Grid>

      <Divider my={6} />
      <Grid
        container
        spacing={10}
        sx={{
          borderBottom: 1,
          borderColor: "divider",
          marginBottom: 5,
        }}
      >
        <Grid item xs={6}>
          <Tabs value={selectdTab} onChange={handleChangeTab} aria-label="">
            <Tab label="Active" id="1" />
            <Tab label="Inactive" id="2" />
          </Tabs>
        </Grid>
      </Grid>
      <Card mb={6}>
        <CardContent>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <LocalEnhancedTable<IBuildingSubType>
                refreshGrid={refreshGrid}
                columns={columns}
                data={data?.filter((x) => x.statusId === selectdTab + 1) ?? []}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <BuildingSubtypeForm
        selectedValue={currentRow}
        isDialogOpen={isDialogOpen}
        setIsDialogOpen={setIsDialogOpen}
        onUpdate={refreshGridHandler}
      />
      <MatchWordConfirmModal
        onConfirm={deleteHandler}
        text={`Are you certain you want to delete building subtype: ${currentRow?.name}?`}
        matchWord={"DELETE"}
        isDialogOpen={confirmDialogIsOpen}
        setIsDialogOpen={setConfirmDialogIsOpen}
      />
    </>
  );
};

export default BuildingSubtype;
