import axios from "src/utils/axios";
import { ISystemOutsideAirReadByDTO } from "src/ts/interfaces/catalogs";

const getSystemOAReadBy = () => {
  return axios.get<ISystemOutsideAirReadByDTO[]>("SystemOutsideAirReadBy");
};

const systemOAReadByService = {
  getSystemOAReadBy,
};

export default systemOAReadByService;
