import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";
import {
  Grid,
  Divider as MuiDivider,
  Card as MuiCard,
  CardContent,
  Stack,
} from "@mui/material";
import { spacing } from "@mui/system";
import { ColumnType } from "src/types/enhancedTable";
import LocalEnhancedTable from "src/components/localTable/LocalTable";
import useAsyncQuery from "src/hooks/useAsyncQuery";
import { GridActionButton } from "src/components/gridControls";
import { Permission as PermissionTypes } from "src/ts/enums";
import { usePermissions } from "src/hooks";
import HeaderPage from "src/components/page/HeaderPage";
import { ITermsAndConditionsList } from "src/ts/interfaces/catalogs/termsAndConditions";
import termsAndConditionsService from "src/services/termsAndConditionsService";
import { FormButton } from "src/components/formControls";
import { AddIcon } from "src/components/icons";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const TermsAndConditions = () => {
  const navigate = useNavigate();
  const { execute, data, isLoading } = useAsyncQuery<ITermsAndConditionsList[]>(
    termsAndConditionsService.getAll
  );
  const [refreshPage] = useState(false);

  useEffect(() => {
    const getData = async () => {
      await execute();
    };

    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshPage]);

  const handleEdit = (id: number) => {
    navigate(`/app/TermsAndConditions/${id}/View`);
  };

  const handleAddOld = (id: number) => {
    navigate(`/app/TermsAndConditions/${id}/New`);
  };
  const { fullAccess } = usePermissions(PermissionTypes.Catalogs);

  const columns: ColumnType[] = [
    {
      id: "title",
      label: "Title",
      type: "string",
      sort: true,
    },
    {
      id: "version",
      label: "Version",
      type: "string",
      sort: true,
    },
    {
      id: "createdDate",
      label: "Created Date",
      type: "utcDate",
      sort: true,
      format: "MM/DD/yyyy",
    },
    {
      id: "createdBy",
      label: "Created By",
      type: "string",
      sort: true,
    },
    {
      id: "x",
      label: "Actions",
      type: "custom",
      sort: false,
      callback: (row: ITermsAndConditionsList) => (
        <div>
          <Stack direction="row">
            <GridActionButton
              type={"add"}
              onClick={() => {
                handleAddOld(row.id);
              }}
              tooltip={"View"}
            />
            <GridActionButton
              type={"view"}
              onClick={() => {
                handleEdit(row.id);
              }}
              tooltip={"View"}
            />
          </Stack>
        </div>
      ),
    },
  ];

  return (
    <>
      <HeaderPage
        title={"Terms And Conditions"}
        parentText={"Dashboard"}
        parentLink={`/`}
        actionSection={
          <FormButton
            text={"Create New Version"}
            onClick={() => {
              navigate(`/app/TermsAndConditions/0/New`);
            }}
            startIcon={<AddIcon />}
            size="small"
            color="success"
            disabled={!fullAccess}
          />
        }
      ></HeaderPage>

      <Divider my={6} />
      <Card mb={6}>
        <CardContent>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <LocalEnhancedTable<ITermsAndConditionsList>
                refreshGrid={true}
                columns={columns}
                data={data ?? []}
                showSkeleton={isLoading}
                defaultRowPerPage={10}
                orderColumn={"desc"}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
};

export default TermsAndConditions;
