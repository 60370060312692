import {
  CardContent,
  Grid,
  Card as MuiCard,
  Divider as MuiDivider,
} from "@mui/material";
import { spacing } from "@mui/system";
import styled from "@emotion/styled";
import { useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import HeaderPage from "src/components/page/HeaderPage";
import { useAsyncQuery, useAuth, useEffectOnce, useLog } from "src/hooks";
import useFormTyped from "src/hooks/useFormTyped";
import chillerService from "src/services/study/chillerService";
import { IChiller, IReportType } from "src/ts/interfaces";
import { Mode, Validator } from "src/ts/types";
import StudyWizard from "../../components/StudyWizard";
import { FooterButtons } from "../../components/FooterButtons";
import { steps, studyTypeCode } from "../const";
import projectReportService from "src/services/study/projectReportService";
import {
  IProjectReport,
  ProjectDTO,
} from "src/ts/interfaces/project/projectDto";
import reportTypeService from "src/services/study/reportTypeService";
import studiesHistoryService from "src/services/study/studiesHistoryService";
import CompleteStudyPopup from "../../components/CompleteStudyPopup";
import getProjectSingleService from "src/services/getProjectSingleService";
import HeaderStudyPage from "src/components/page/HeaderStudyPage";

const Divider = styled(MuiDivider)(spacing);
const Card = styled(MuiCard)(spacing);
const activeStep = 7;
const initialValues: any = {};

const ChillerStep8 = () => {
  const { user } = useAuth();
  const params = useParams<{ id: string }>();
  const id = parseInt(params?.id === undefined ? "0" : params?.id);
  let navigate = useNavigate();
  const { log } = useLog();

  const [reportType, setReportType] = useState<IReportType | null>();
  const [parentStudy, setParentStudy] = useState<IProjectReport | null>();
  const [project, setProject] = useState<ProjectDTO | null>();
  const { execute: executeReport, data: dataReport } =
    useAsyncQuery<IProjectReport>(projectReportService.report);
  const [projectReport, setProjectReport] = useState<IProjectReport | null>();
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const validate: any = (fieldValues = values) => {
    let temp: Record<string, string> = { ...errors };

    temp.name = new Validator(fieldValues, "name")
      .isRequired("Project Name is required")
      .toString();

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange } =
    useFormTyped<IChiller>(initialValues, false, validate);

  const [isLoading, setIsLoading] = useState(false);

  useEffectOnce(() => {
    const getData = async () => {
      try {
        setIsLoading(true);
        const chillerRes = await chillerService.getById(id);
        setValues(chillerRes.data);

        const reportTypeRes = await reportTypeService.getReportTypeByCode(
          studyTypeCode
        );
        setReportType(reportTypeRes.data);

        await executeReport(id, reportTypeRes.data.code);

        await getParentReportIsComplete(chillerRes.data.id, studyTypeCode);
        await getProject(chillerRes.data.projectId);
        await getProjectReport(
          chillerRes.data.id,
          chillerRes.data.reportTypeId,
          chillerRes.data.projectId
        );
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      } finally {
        setIsLoading(false);
      }
    };
    if (id > 0) getData();
  });

  const getParentReportIsComplete = async (
    reportId: number,
    reportType: string
  ) => {
    try {
      const res = await projectReportService.report(reportId, reportType);
      setParentStudy(res.data);
    } catch (error) {}
  };

  const getProjectReport = async (
    reportId: number,
    reportTypeId: number,
    projectId: number
  ) => {
    const projectReportResposne = await projectReportService.projectReport(
      reportId,
      reportTypeId,
      projectId
    );
    setProjectReport(projectReportResposne.data);
  };

  const getProject = async (projectId: number) => {
    const projectResponse = await getProjectSingleService.single(projectId);
    setProject(projectResponse.data);
  };

  const disableStudy = useMemo(() => {
    return (
      values?.isComplete ||
      (dataReport?.isInUse && dataReport?.isInUseById !== user?.userId) ||
      dataReport?.isPartOfProject === false
    );
  }, [
    dataReport?.isInUse,
    dataReport?.isInUseById,
    dataReport?.isPartOfProject,
    user?.userId,
    values?.isComplete,
  ]);

  const save = async () => {
    if (!validate()) return;

    await studiesHistoryService.save(
      values.projectId,
      reportType?.id ?? 0,
      values.id,
      "saved"
    );
    log.info("Report Was saved");
  };

  const back = () => {
    const route = `/studies/Chiller/step7/${id}`;
    navigate(route);
  };

  return (
    <>
      <HeaderStudyPage
        headerStudiesPage={{
          code: studyTypeCode,
          system: values.system,
          id: id,
        }}
        parentText="Project"
        parentLink="/"
      />
      <Divider my={6} />

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <StudyWizard
            steps={steps}
            activeStep={activeStep}
            projectId={dataReport?.projectId}
            reportId={dataReport?.reportId}
            reportTypeId={dataReport?.reportTypeId}
          />
        </Grid>
        <Grid item xs={12}>
          <Card mb={1}>
            <CardContent>
              <Grid container spacing={5}>
                <Grid item xs={12}>
                  {project && projectReport && values && (
                    <CompleteStudyPopup
                      isDialogOpen={isDialogOpen}
                      setIsDialogOpen={setIsDialogOpen}
                      project={project}
                      projectReport={projectReport}
                      completed={values?.isComplete}
                      infectiousComplianceControl={null}
                      parentStudy={parentStudy ?? null}
                    />
                  )}
                </Grid>
                <Grid item xs={12}>
                  <FooterButtons
                    activeStep={activeStep}
                    stepsCount={steps.length}
                    projectId={dataReport?.projectId}
                    companyId={user?.companyId as number}
                    isSaving={false}
                    disabled={false}
                    reportName={reportType?.name ?? ""}
                    saveHandler={save}
                    previousStepHandler={() => back()}
                    hideSaveButton={disableStudy}
                    isComplete={values.isComplete}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default ChillerStep8;
