import React, { useState } from "react";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import {
  Grid,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
  Typography,
  Card as MuiCard,
  CardContent,
  Tabs,
  Tab,
} from "@mui/material";
import { FormText } from "src/components/formControls";
import { spacing } from "@mui/system";
import { useForm } from "src/hooks";
import YoutubeVideosPages from "./YoutubeVideosPages";
import YoutubeVideosReports from "./YoutubeVideosReports";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const initialValues = {
  pageName: "",
};

const YoutubeVideosBase = () => {
  const [selectdTab, setSelectedTab] = useState(0);

  const { values: filterValues, handleInputChange: handleFilterChange } =
    useForm(initialValues, true, []);

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  return (
    <>
      <Helmet title="Youtube Training Videos" />
      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Youtube Training Videos
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} to="/">
              Dashboard
            </Link>
            <Typography>Youtube Traning</Typography>
          </Breadcrumbs>
        </Grid>
      </Grid>

      <Divider my={6} />
      <Card mb={6}>
        <CardContent>
          <Grid container>
            <Grid item xs={12}>
              <FormText
                name="pageName"
                label="Page"
                value={filterValues.pageName}
                onChange={handleFilterChange}
              />
            </Grid>

            <Grid
              container
              spacing={10}
              sx={{ borderBottom: 1, borderColor: "divider", marginBottom: 5 }}
            >
              <Grid item xs={6}>
                <Tabs
                  value={selectdTab}
                  onChange={handleChangeTab}
                  aria-label="basic tabs example"
                >
                  <Tab label="Pages" id="0" />
                  <Tab label="Reports" id="1" />
                </Tabs>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              {selectdTab === 0 ? <YoutubeVideosPages /> : null}
              {selectdTab === 1 ? <YoutubeVideosReports /> : null}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
};

export default YoutubeVideosBase;
