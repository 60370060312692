import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Grid, Typography } from "@mui/material";
import {
  FormButton,
  FormDatePicker,
  FormNumeric2,
} from "src/components/formControls";
import { FooterButtons } from "../../components/FooterButtons";
import { IBoilerTest } from "src/ts/interfaces/study/boiler";
import { steps } from "./const";
import { NavigateFunction } from "react-router-dom";
import SteadyStateDialog from "../../components/SteadyState";
import { Stop } from "@mui/icons-material";
import DialogMessagePopup from "src/components/DialogMessagePopup";
import { useAsyncQuery } from "src/hooks";
import boilerTestService from "src/services/study/boilerTestService";
import moment from "moment";

interface Props {
  values: IBoilerTest;
  handleInputChange: (e: any) => void;
  errors: Record<string, string>;
  showskeleton: boolean;
  disableStudy: boolean;
  handleSave: () => Promise<void>;
  navigate: NavigateFunction;
  companyId: number;
  systemOfMeasurement: {
    get: (key: string) => any;
    setSystem: (system: string) => void;
    getByProject: (projectId: number) => Promise<string | null>;
    measurementSystem: string | null;
  };
  setValues: Dispatch<SetStateAction<IBoilerTest>>;
}

const Step2 = ({
  values,
  handleInputChange,
  errors,
  showskeleton,
  disableStudy,
  handleSave,
  navigate,
  companyId,
  systemOfMeasurement,
  setValues,
}: Props) => {
  const [modalEnd, setModalEnd] = useState(false);

  const { execute: calculation, isLoading: isloading } = useAsyncQuery(
    boilerTestService.calculation,
    {
      onSuccess: async (result) => {
        setValues({
          ...values,
          actualTubeBundlePsiTested: result.actualTubeBundlePsiTested,
          totalTubeBundleTTested: result.totalTubeBundleTTested,
          totalBtuHTested: result.totalBtuHTested,
          totalMbhasTested: result.totalMbhasTested,
          actualWaterHydronickw: result.actualWaterHydronickw,
        });
      },
    }
  );

  useEffect(() => {
    const getData = async () => {
      if (!disableStudy) await handleSave();
    };

    if (values.testFinished) getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.testFinished]);

  const nextStepHandler = async () => {
    if (!disableStudy) handleSave();
    navigate(`/studies/BoilerTest/step5/${values?.id}/`);
  };

  const backStepHandler = async () => {
    if (!disableStudy) handleSave();
    navigate(`/studies/BoilerTest/step3/${values?.id}/`);
  };

  const handleFinishModal = () => {
    setModalEnd(true);
  };

  const handleFinish = () => {
    setValues({ ...values, testFinished: true });
  };

  const handleChangeBlur = async (e: any) => {
    const newValues = { ...values, [e.target.name]: e.target.value };
    await calculation(newValues);
  };

  const handleChangeBlurActual = async (e: any) => {
    const newValues = { ...values, [e.target.name]: e.target.value };
    await calculation(newValues);
  };

  const handleChangeBlurActualOperating = async (e: any) => {
    const newValues = { ...values, [e.target.name]: e.target.value };
    await calculation(newValues);
  };

  useEffect(() => {
    if (
      values.actualDateTestPerformed === null ||
      values.actualDateTestPerformed === undefined
    ) {
      let newvalues = { ...values };
      newvalues.actualDateTestPerformed = moment().format("MM/DD/YYYY");
      setValues(newvalues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  return (
    <>
      <Grid container spacing={5}>
        <Grid item xs={5}>
          <FormDatePicker
            name="actualDateTestPerformed"
            label={`Actual Date Of When The Test Was Performed`}
            value={values.actualDateTestPerformed}
            onChange={handleInputChange}
            error={errors.actualDateTestPerformed}
            disable={disableStudy}
            showSkeleton={showskeleton}
            disableFuture={true}
            fullWidth
          />
        </Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={5}>
          <FormNumeric2
            name="actualGasInletPressure"
            label={`Actual Gas Inlet Pressure ${systemOfMeasurement.get(
              "inwc"
            )}`}
            value={values?.actualGasInletPressure}
            onChange={handleInputChange}
            error={errors.actualGasInletPressure}
            showSkeleton={showskeleton}
            disabled={disableStudy || values?.testFinished}
            decimalScale={3}
            thousandSeparator={true}
          />
        </Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={5}>
          <FormNumeric2
            name="actualGasManifoldPressure"
            label={`Actual Gas Manifold Pressure ${systemOfMeasurement.get(
              "inwc"
            )}`}
            value={values?.actualGasManifoldPressure}
            onChange={handleInputChange}
            error={errors.actualGasManifoldPressure}
            showSkeleton={showskeleton}
            disabled={disableStudy || values?.testFinished}
            decimalScale={3}
            thousandSeparator={true}
          />
        </Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={5}>
          <FormNumeric2
            name="actualGpmTotal"
            label={`Actual ${systemOfMeasurement.get("gpm")} Total`}
            value={values?.actualGpmTotal}
            onChange={handleInputChange}
            onBlur={handleChangeBlur}
            error={errors.actualGpmTotal}
            showSkeleton={showskeleton}
            disabled={disableStudy || values?.testFinished}
            decimalScale={3}
            thousandSeparator={true}
          />
        </Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={5}>
          <FormNumeric2
            name="actualReturnCoolEwt"
            label={`Actual (Return/Cool) EWT (${systemOfMeasurement.get(
              "temperature"
            )})`}
            value={values?.actualReturnCoolEwt}
            onChange={handleInputChange}
            onBlur={handleChangeBlur}
            error={errors.actualReturnCoolEwt}
            showSkeleton={showskeleton}
            disabled={disableStudy || values?.testFinished}
            decimalScale={3}
            thousandSeparator={true}
          />
        </Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={5}>
          <FormNumeric2
            name="actualSupplyHotLwt"
            label={`Actual (Supply/Hot) LWT (${systemOfMeasurement.get(
              "temperature"
            )})`}
            value={values?.actualSupplyHotLwt}
            onChange={handleInputChange}
            onBlur={handleChangeBlur}
            error={errors.actualSupplyHotLwt}
            showSkeleton={showskeleton}
            disabled={disableStudy || values?.testFinished}
            decimalScale={3}
            thousandSeparator={true}
          />
        </Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={5}>
          <FormNumeric2
            name="actualBoilerInletReturningColdPsi"
            label={`Actual Boiler Inlet/Returning (Cold) ${systemOfMeasurement.get(
              "psi"
            )}`}
            value={values?.actualBoilerInletReturningColdPsi}
            onChange={handleInputChange}
            onBlur={handleChangeBlurActual}
            error={errors.actualBoilerInletReturningColdPsi}
            showSkeleton={showskeleton}
            disabled={disableStudy || values?.testFinished}
            decimalScale={3}
            thousandSeparator={true}
          />
        </Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={5}>
          <FormNumeric2
            name="actualBoilerDischargeLeavingHotPsi"
            label={`Actual Boiler Discharge/Leaving (Hot) ${systemOfMeasurement.get(
              "psi"
            )}`}
            value={values?.actualBoilerDischargeLeavingHotPsi}
            onChange={handleInputChange}
            onBlur={handleChangeBlurActual}
            error={errors.actualBoilerDischargeLeavingHotPsi}
            showSkeleton={showskeleton}
            disabled={disableStudy || values?.testFinished}
            decimalScale={3}
            thousandSeparator={true}
          />
        </Grid>
        <Grid item xs={1}></Grid>
        {values.fuelSource === "Electric" && (
          <>
            <Grid container spacing={5} display={"contents"}>
              <Grid item xs={6}></Grid>
              <Grid item xs={5}>
                <Grid container spacing={5}>
                  <Grid item xs={3}>
                    <Typography>Actual Voltage Total</Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <FormNumeric2
                      name="actualVoltageTotal1"
                      label={``}
                      value={values?.actualVoltageTotal1}
                      onChange={handleInputChange}
                      onBlur={handleChangeBlurActualOperating}
                      error={errors.actualVoltageTotal1}
                      disabled={disableStudy || values?.testFinished}
                      showSkeleton={showskeleton}
                      decimalScale={3}
                      thousandSeparator={true}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <FormNumeric2
                      name="actualVoltageTotal2"
                      label={``}
                      value={values?.actualVoltageTotal2}
                      onChange={handleInputChange}
                      onBlur={handleChangeBlurActualOperating}
                      error={errors.actualVoltageTotal2}
                      disabled={disableStudy || values?.testFinished}
                      showSkeleton={showskeleton}
                      decimalScale={3}
                      thousandSeparator={true}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <FormNumeric2
                      name="actualVoltageTotal3"
                      label={``}
                      value={values?.actualVoltageTotal3}
                      onChange={handleInputChange}
                      onBlur={handleChangeBlurActualOperating}
                      error={errors.actualVoltageTotal3}
                      disabled={disableStudy || values?.testFinished}
                      showSkeleton={showskeleton}
                      decimalScale={3}
                      thousandSeparator={true}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={1}></Grid>
              <Grid item xs={6}></Grid>
              <Grid item xs={5}>
                <Grid container spacing={5}>
                  <Grid item xs={3}>
                    <Typography>Actual Amperage/Current Total</Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <FormNumeric2
                      name="actualAmperageCurrentTotal1"
                      label={``}
                      value={values?.actualAmperageCurrentTotal1}
                      onChange={handleInputChange}
                      onBlur={handleChangeBlurActualOperating}
                      error={errors.actualAmperageCurrentTotal1}
                      disabled={disableStudy || values?.testFinished}
                      showSkeleton={showskeleton}
                      decimalScale={3}
                      thousandSeparator={true}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <FormNumeric2
                      name="actualAmperageCurrentTotal2"
                      label={``}
                      value={values?.actualAmperageCurrentTotal2}
                      onChange={handleInputChange}
                      onBlur={handleChangeBlurActualOperating}
                      error={errors.actualAmperageCurrentTotal2}
                      disabled={disableStudy || values?.testFinished}
                      showSkeleton={showskeleton}
                      decimalScale={3}
                      thousandSeparator={true}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <FormNumeric2
                      name="actualAmperageCurrentTotal3"
                      label={``}
                      value={values?.actualAmperageCurrentTotal3}
                      onChange={handleInputChange}
                      onBlur={handleChangeBlurActualOperating}
                      error={errors.actualAmperageCurrentTotal3}
                      disabled={disableStudy || values?.testFinished}
                      showSkeleton={showskeleton}
                      decimalScale={3}
                      thousandSeparator={true}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={1}></Grid>
              <Grid item xs={6}></Grid>
              <Grid item xs={5}>
                <FormNumeric2
                  name="actualOperatingkW"
                  label={`Actual Operating kW`}
                  value={values?.actualOperatingkW}
                  onChange={handleInputChange}
                  error={errors.actualOperatingkW}
                  mode={"read"}
                  showSkeleton={showskeleton}
                  decimalScale={3}
                  thousandSeparator={true}
                />
              </Grid>
              <Grid item xs={1}></Grid>
            </Grid>
          </>
        )}
        <Grid item xs={5}>
          <FormNumeric2
            name="actualTubeBundlePsiTested"
            label={`Actual Tube Bundle Δ ${systemOfMeasurement.get(
              "psi"
            )} as Tested`}
            value={values?.actualTubeBundlePsiTested}
            onChange={handleInputChange}
            error={errors.actualTubeBundlePsiTested}
            showSkeleton={showskeleton}
            mode={"read"}
            decimalScale={3}
            thousandSeparator={true}
          />
        </Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={5}>
          <FormNumeric2
            name="totalTubeBundleTTested"
            label={`Total Tube Bundle ΔT as Tested`}
            value={values?.totalTubeBundleTTested}
            onChange={handleInputChange}
            onBlur={handleChangeBlur}
            error={errors.totalTubeBundleTTested}
            showSkeleton={showskeleton}
            mode={"read"}
            decimalScale={3}
            thousandSeparator={true}
          />
        </Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={5}>
          <FormNumeric2
            name="totalBtuHTested"
            label={`Total BTU/h as Tested`}
            value={values?.totalBtuHTested}
            onChange={handleInputChange}
            error={errors.totalBtuHTested}
            showSkeleton={showskeleton}
            mode={"read"}
            decimalScale={3}
            thousandSeparator={true}
          />
        </Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={5}>
          <FormNumeric2
            name="totalMbhasTested"
            label={`Total MBH as Tested`}
            value={values?.totalMbhasTested}
            onChange={handleInputChange}
            error={errors.totalMbhasTested}
            showSkeleton={showskeleton}
            mode={"read"}
            decimalScale={3}
            thousandSeparator={true}
          />
        </Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={5}>
          <FormNumeric2
            name="actualWaterHydronickw"
            label={`Actual Water/Hydronic kW`}
            value={values?.actualWaterHydronickw}
            onChange={handleInputChange}
            error={errors.actualWaterHydronickw}
            showSkeleton={showskeleton}
            mode={"read"}
            decimalScale={3}
            thousandSeparator={true}
          />
        </Grid>
        <Grid item xs={1}></Grid>
        {!values?.testFinished && !disableStudy && (
          <Grid item xs={12} textAlign={"center"}>
            <FormButton
              color="error"
              text={"End Test"}
              onClick={handleFinishModal}
              startIcon={<Stop />}
            ></FormButton>
          </Grid>
        )}
        <Grid item xs={12}>
          <FooterButtons
            activeStep={1}
            stepsCount={steps.length}
            projectId={values?.projectId ?? 0}
            companyId={companyId}
            isSaving={showskeleton}
            disabled={disableStudy}
            reportName="Static Report"
            saveHandler={handleSave}
            nextStepHandler={nextStepHandler}
            previousStepHandler={backStepHandler}
            disableNext={!values?.testFinished}
            isComplete={values?.isComplete ?? true}
          />
        </Grid>
      </Grid>
      {values && values?.projectId && (
        <SteadyStateDialog
          reportId={values?.id}
          reportTypeId={19}
          projectId={values?.projectId}
          reportCompleted={values?.isComplete}
        />
      )}
      {values && (
        <DialogMessagePopup
          title={"Important"}
          text={
            <Typography>
              <Typography display={"inline"}>
                This will complete your Boiler test report and
              </Typography>
              <Typography color={"error"} display={"inline"}>
                {" "}
                NO ADDITIONAL CHANGES WILL BE ALLOWED.{" "}
              </Typography>
              <Typography display={"inline"}>
                Are you certain you want to finalize this test?
              </Typography>
            </Typography>
          }
          showPopup={modalEnd}
          setShowPopup={setModalEnd}
          onSave={handleFinish}
          isSubmitting={showskeleton}
        ></DialogMessagePopup>
      )}
    </>
  );
};

export default Step2;
