import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface ICouponBar {
  refresh: boolean;
}

const initialState: ICouponBar = {
  refresh: false,
};

export const flotatingBarCouponBar = createSlice({
  name: "couponBar",
  initialState,
  reducers: {
    setRefresh(state: ICouponBar) {
      state.refresh = !state.refresh;
    },
  },
});

export default flotatingBarCouponBar;
