import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import {
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent,
  Divider as MuiDivider,
  Grid,
  Link,
  Typography,
  Avatar,
} from "@mui/material";
import { Helmet } from "react-helmet-async";
import { Box, spacing } from "@mui/system";
import styled from "@emotion/styled";
import LocalEnhancedTable from "src/components/localTable/LocalTable";
import { ISysAdminDocument } from "src/ts/interfaces";
import { ColumnType } from "src/types/enhancedTable";

import FileUtils from "src/utils/file";
import { useLog } from "src/hooks";
import { SysAdminDocumentService } from "src/services";
import { GridActionButton } from "src/components/gridControls";
import { alpha } from "@mui/material";
import { CircularProgress } from "@mui/material";

const AvatarWrapper = styled(Avatar)`
  background: ${(props) => alpha(props.theme.palette.primary.main, 0.2)};
  color: ${(props) => props.theme.palette.primary.main};
  width: ${(props) => props.theme.spacing(10)};
  height: ${(props) => props.theme.spacing(10)};
`;

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const getColumns = (
  downloadDocument: (row: ISysAdminDocument) => void,
  submiting: boolean
): ColumnType[] => [
  {
    id: "id",
    label: "Id",
    type: "string",
    sort: false,
    hide: true,
  },
  {
    id: "name",
    label: "Name",
    type: "string",
    sort: true,
  },
  {
    id: "status",
    label: "Action",
    type: "custom",
    sort: false,
    callback: (row: ISysAdminDocument) => {
      return (
        <div>
          <Box mr={2}>
            {!submiting && (
              <GridActionButton
                tooltip="Download"
                onClick={() => downloadDocument(row)}
                type={"download"}
              />
            )}
            {submiting && (
              <>
                <AvatarWrapper variant="rounded">
                  <CircularProgress size="1rem" />
                </AvatarWrapper>
              </>
            )}
          </Box>
        </div>
      );
    },
  },
];

const TrainingDocuments = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [refreshPage] = useState(false);
  const [trainingDocuments, setTrainingDocuments] = useState<
    ISysAdminDocument[]
  >([]);
  const { log } = useLog();

  useEffect(() => {
    const getDocuments = async () => {
      try {
        const request = await SysAdminDocumentService.getTrainingDocuments();
        setTrainingDocuments(request.data);
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      } finally {
      }
    };

    getDocuments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshPage]);

  const downloadDocuments = async (row: ISysAdminDocument) => {
    if (row && row.id) {
      try {
        setIsSubmitting(true);
        const response = await SysAdminDocumentService.downloadTrainingDocument(
          Number(row.id)
        );

        let fileName = row.name;
        if (
          row.name.indexOf(".pdf") === -1 &&
          row.name.indexOf(".xls") === -1 &&
          row.name.indexOf(".xslx") === -1
        ) {
          fileName = fileName + ".pdf";
        }

        FileUtils.downloadFile(response.data, fileName);
        log.success("The file has been downloaded successfully");
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      } finally {
        setIsSubmitting(false);
      }
    } else {
      log.error("currentRow is undefined");
    }
  };

  const columns = getColumns(downloadDocuments, isSubmitting);

  return (
    <React.Fragment>
      <Helmet title="Training Documents" />

      <Typography variant="h3" gutterBottom display="inline">
        Training Documents
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/">
          Dashboard
        </Link>
        <Typography>Training Documents</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Card mb={6}>
            <CardContent>
              <Grid container spacing={6}>
                <Grid item md={12}>
                  <LocalEnhancedTable<ISysAdminDocument>
                    refreshGrid={false}
                    columns={columns}
                    data={trainingDocuments}
                    hidePagination={true}
                  />
                </Grid>
                <Grid item md={4}></Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default TrainingDocuments;
