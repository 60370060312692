import React, { useState, useEffect } from "react";
import { COUNTRIES } from "src/constants";
import StateService from "src/services/stateService";
import { IKeyValue } from "src/ts/interfaces";
import FormSelect from "./FormSelect";
import FormText from "./FormText";
import { useLog } from "src/hooks";
import { Mode } from "src/ts/types/common";
import FormReadOnlyField from "./FormReadOnlyField";

interface FormStateProps {
  stateId: string;
  stateName: string;
  countryId: number | null;
  errorsStateId: any;
  errorsStateName: any;
  onChange: (e: any) => void;
  options?: any[] | null;
  size?: "small" | "medium" | null;
  fullWidth?: boolean | undefined;
  disable?: boolean;
  isLoading: (val: boolean) => void;
  showSkeleton?: boolean;
  mode?: Mode;
  otherNameStateId?: string;
  otherValuerStateId?: any;
  otherNameStateName?: string;
  otherValuerStateName?: any;
  readonly?: boolean;
}

const FormSelectState = (props: FormStateProps) => {
  const { log } = useLog();
  const {
    stateId,
    stateName,
    countryId,
    onChange,
    errorsStateId,
    errorsStateName,
    isLoading,
    disable,
    showSkeleton,
    mode,
    otherNameStateId,
    otherValuerStateId,
    otherNameStateName,
    otherValuerStateName,
    readonly,
  } = props;
  const [statesData, setStatesData] = useState<IKeyValue<number, string>[]>([]);

  useEffect(() => {
    const getState = async () => {
      try {
        isLoading(true);
        const request = await StateService.getKeyValues(countryId!);
        setStatesData(request);
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      } finally {
        isLoading(false);
      }
    };

    if (countryId === COUNTRIES.USA) getState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countryId]);

  if (mode && mode === "read") {
    // eslint-disable-next-line eqeqeq
    const selected = statesData?.find((opt) => opt.key === Number(stateId));
    return <FormReadOnlyField label="State" value={selected?.value ?? ""} />;
  }

  return countryId !== Number.MIN_SAFE_INTEGER &&
    countryId === COUNTRIES.USA ? (
    <FormSelect
      name={otherNameStateId ?? "stateId"}
      label="State"
      value={otherValuerStateId ?? stateId}
      onChange={onChange}
      options={statesData}
      error={errorsStateId}
      disable={disable}
      showSkeleton={showSkeleton}
      readOnly={readonly}
    />
  ) : (
    <FormText
      name={otherNameStateName ?? "stateName"}
      label="State"
      value={otherValuerStateName ?? stateName}
      onChange={onChange}
      error={errorsStateName}
      disabled={disable}
      showSkeleton={showSkeleton}
      readOnlyText={readonly}
    />
  );
};

export default FormSelectState;
