import { ChillerStudyProvider } from "../context/ChillerStudyContext";
import ChillerStep8 from "./ChillerStep8";
const Index = () => {
  return (
    <ChillerStudyProvider>
      <ChillerStep8 />
    </ChillerStudyProvider>
  );
};

export default Index;
