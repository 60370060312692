import React, { useEffect, useState } from "react";
import { ColumnType } from "src/types/enhancedTable";
import { IRecommendations } from "src/ts/interfaces/recommendations";
import {
  Chip as MuiChip,
  Card as MuiCard,
  CardContent,
  Grid,
  Tabs,
  Tab,
  Typography,
  Divider as MuiDivider,
} from "@mui/material";
import { SpacingProps, Stack, spacing, styled } from "@mui/system";
import { GridActionButton } from "src/components/gridControls";
import HeaderPage from "src/components/page/HeaderPage";
import LocalEnhancedTable from "src/components/localTable/LocalTable";
import { useAsyncQuery, useForm } from "src/hooks";
import recommendationsService from "src/services/recommendationsService";
import DialogMessagePopup from "src/components/DialogMessagePopup";
import { convertUTCDateToLocalDate } from "src/utils/utils";
import { useNavigate } from "react-router-dom";
import {
  FormActionButton,
  FormDatePicker,
  FormText,
} from "src/components/formControls";
import moment from "moment";
import { Validator } from "src/ts/types/Validator";

const Divider = styled(MuiDivider)(spacing);
const Card = styled(MuiCard)(spacing);
interface ChipProps extends SpacingProps {
  component?: React.ElementType;
  href?: string;
  icon?: JSX.Element | null;
}
const Chip = styled(MuiChip)<ChipProps>(spacing);
const initialValues: IRecommendations = {
  id: 0,
  title: "",
  description: "",
  createdDate: new Date(),
  updated: new Date(),
  recommendationsStatusId: 0,
  score: 0,
  recommendationsTypeId: 0,
  code: "",
  other: "",
};
const initialValuesFilter = {
  startDate: moment().startOf("month").format("MM/DD/YYYY"),
  endDate: moment().format("MM/DD/YYYY"),
  search: "",
};

const columns = (
  handleEdit: (id: number) => void,
  setViewRecommendations: React.Dispatch<
    React.SetStateAction<{
      recommendation: IRecommendations;
      showPopup: boolean;
    }>
  >,
  isAll: boolean
): ColumnType[] => [
  {
    id: "title",
    label: "Title",
    type: "custom",
    sort: false,
    callback: (row: IRecommendations) => {
      return (
        <>
          <Typography variant="subtitle2">
            {`(${row.code}) ${row.title}`}
          </Typography>
        </>
      );
    },
  },
  {
    id: "classification",
    label: "Classification",
    type: "custom",
    sort: false,
    callback: (row: IRecommendations) => {
      return <>{row.recommendationsType?.name}</>;
    },
  },
  {
    id: "description",
    label: "Description",
    type: "string",
    sort: true,
  },
  {
    id: "createdDate",
    label: "Created Date",
    type: "utcDate",
    sort: true,
    format: "MM/DD/yyyy",
  },
  {
    id: "status",
    label: "Status",
    type: "custom",
    sort: false,
    hide: isAll,
    callback: (row: IRecommendations) => {
      return (
        <>
          <Chip
            label={row.recommendationsStatus?.name}
            color={row.recommendationsStatus?.colorReact ?? "default"}
            m={1}
            size="small"
          />
        </>
      );
    },
  },
  {
    id: "actions",
    label: "Actions",
    type: "custom",
    sort: false,
    callback: (row: IRecommendations) => {
      return (
        <div>
          {!isAll && (
            <GridActionButton
              type="edit"
              onClick={() => {
                handleEdit(row.id);
              }}
              tooltip="Edit"
            />
          )}
          {isAll && (
            <GridActionButton
              type="view"
              onClick={() => {
                setViewRecommendations({
                  showPopup: true,
                  recommendation: row,
                });
              }}
              tooltip="View"
            />
          )}
        </div>
      );
    },
  },
];

function SuggestionBankList() {
  const navigate = useNavigate();

  const validate = (fieldValues = filterValues) => {
    let temp: Record<string, string> = { ...errors };

    temp.startDate = new Validator(fieldValues, "startDate")
      .isRequired("The date is required.")
      .toString();

    temp.endDate = new Validator(fieldValues, "endDate")
      .isRequired("The date is required.")
      .toString();

    const end = new Date(fieldValues.endDate);
    const start = new Date(fieldValues.startDate);
    if (fieldValues.endDate && fieldValues.startDate) {
      if (end < start) {
        temp.startDate = `Start date can not be greater than  ${moment(
          end
        ).format("MM/DD/YYYY")}`;
        temp.endDate = `End date can not be less than  ${moment(start).format(
          "MM/DD/YYYY"
        )}`;
      } else {
        temp.startDate = "";
        temp.endDate = "";
      }
    }

    setErrors({
      ...temp,
    });

    if (fieldValues === filterValues)
      return Object.values(temp).every((x) => x === "");
  };

  const {
    values: filterValues,
    errors,
    setErrors,
    handleInputChange: handleFilterChange,
    setValues: setFilterValues,
  } = useForm(initialValuesFilter, true, validate);

  const { execute, data, isLoading } = useAsyncQuery<IRecommendations[]>(
    recommendationsService.getByUser
  );
  const {
    execute: executeAll,
    data: dataAll,
    isLoading: isLoadingAll,
  } = useAsyncQuery<IRecommendations[]>(recommendationsService.getAll);
  const [tab, setTab] = useState(0);
  const [viewRecommendations, setViewRecommendations] = useState<{
    recommendation: IRecommendations;
    showPopup: boolean;
  }>({
    recommendation: initialValues,
    showPopup: false,
  });
  const [refreshTable, setRefreshTable] = useState(true);

  useEffect(() => {
    setFilterValues({
      ...filterValues,
      startDate: moment().startOf("month").format("MM-DD-YYYY"),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const getData = async () => {
      if (!validate()) return;
      const startDate = new Date(
        moment(filterValues.startDate)
          .set({
            hour: 0,
            minute: 0,
            second: 0,
            millisecond: 0,
          })
          .format("MM/DD/yyyy hh:mm:ss a")
      ).toUTCString();

      const endDate = new Date(
        moment(filterValues.endDate)
          .set({
            hour: 23,
            minute: 59,
            second: 59,
            millisecond: 999,
          })
          .format("MM/DD/yyyy hh:mm:ss a")
      ).toUTCString();

      if (tab === 0) {
        await execute(startDate, endDate, filterValues.search);
      } else {
        await executeAll(startDate, endDate, filterValues.search);
      }
    };

    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab, refreshTable]);

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setTab(newValue);
  };

  const handleEdit = (id: number) => {
    navigate(`/app/suggestionBankInfo/${id}/0`);
  };

  const refreshHandler = () => {
    setRefreshTable(!refreshTable);
  };

  const validateMinDate = (e: any) => {
    if (e.target.value !== "Invalid date") {
      let newValues = { ...filterValues };
      newValues.startDate = e.target.value;

      if (Date.parse(newValues.endDate) < Date.parse(newValues.startDate))
        setFilterValues({ ...newValues, endDate: newValues.startDate });
      else setFilterValues({ ...newValues });
    }
  };

  return (
    <>
      <HeaderPage
        title={"Suggestion Bank"}
        parentText={"Dashboard"}
        parentLink={`/`}
        pageYoutube="Suggestion Bank"
      ></HeaderPage>
      <Card mb={6}>
        <CardContent>
          <Grid container spacing={6}>
            <Grid item xs={2}>
              <FormDatePicker
                name="startDate"
                label="Date From"
                value={filterValues.startDate}
                onChange={validateMinDate}
                error={errors.startDate}
              />
            </Grid>
            <Grid item xs={2}>
              <FormDatePicker
                name="endDate"
                label="Date To"
                value={filterValues.endDate}
                onChange={handleFilterChange}
                minDate={filterValues.startDate}
                error={errors.endDate}
              />
            </Grid>
            <Grid item xs={6}>
              <FormText
                name="search"
                label="Search"
                value={filterValues.search}
                onChange={handleFilterChange}
                showSkeleton={false}
              />
            </Grid>
            <Grid item xs={2}>
              <FormActionButton
                mr={2}
                text="Search"
                size="medium"
                onClick={refreshHandler}
                isSubmitting={false}
                type="search"
              />
            </Grid>
          </Grid>
          <Divider my={3}></Divider>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Tabs
                value={tab}
                onChange={handleChangeTab}
                aria-label="Buildings"
              >
                <Tab label="My Suggestion Bank" id="0" value={0} />
                <Tab label="All Suggestions Bank" id="1" value={1} />
              </Tabs>
            </Grid>
            <Grid item xs={12}>
              {tab === 0 && (
                <LocalEnhancedTable<IRecommendations>
                  refreshGrid={true}
                  columns={columns(handleEdit, setViewRecommendations, false)}
                  data={Object.values(data ?? [])}
                  showSkeleton={isLoading}
                />
              )}
              {tab === 1 && (
                <LocalEnhancedTable<IRecommendations>
                  refreshGrid={true}
                  columns={columns(handleEdit, setViewRecommendations, true)}
                  data={Object.values(dataAll ?? [])}
                  showSkeleton={isLoadingAll}
                />
              )}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <DialogMessagePopup
        title={"Suggestion"}
        text={
          <Stack spacing={2}>
            <Typography variant="h6">
              {`(${viewRecommendations.recommendation.code}) ${viewRecommendations.recommendation.title}`}
            </Typography>
            <Typography>
              {convertUTCDateToLocalDate(
                viewRecommendations.recommendation.createdDate.toString() ?? ""
              )?.format("MM/DD/yyyy")}
            </Typography>
            <Typography>
              {viewRecommendations.recommendation.recommendationsType?.name}
            </Typography>
            <Typography>
              {viewRecommendations.recommendation.description}
            </Typography>
          </Stack>
        }
        showPopup={viewRecommendations.showPopup}
        setShowPopup={() => {
          setViewRecommendations({
            ...viewRecommendations,
            showPopup: !viewRecommendations.showPopup,
          });
        }}
        onSave={() => {
          setViewRecommendations({
            showPopup: false,
            recommendation: initialValues,
          });
        }}
        isSubmitting={false}
        hideCancel={true}
      ></DialogMessagePopup>
    </>
  );
}

export default SuggestionBankList;
