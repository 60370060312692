import { IFanMotorDTO, IMotorDTO } from "src/ts/interfaces";
import axios from "src/utils/axios";

const getPowerFactor = (motor: IFanMotorDTO | IMotorDTO) => {
  return axios.post<number>("MotorPower/powerFactor", motor);
};

const getEffByPowerFactor = (motor: IFanMotorDTO | IMotorDTO) => {
  return axios.post<number>("MotorPower/efficiency", motor);
};

const powerFactor = (fanMotor: any) => {
  return axios.post<number | null>("MotorPower/powerFactor", fanMotor);
};

const efficiency = (fanMotor: any) => {
  return axios.post<number | null>("MotorPower/efficiency", fanMotor);
};

const motorPowerService = {
  getPowerFactor,
  getEffByPowerFactor,
  powerFactor,
  efficiency,
};

export default motorPowerService;
