import {
  ILadderAFrame,
  ILadderExtension,
  ISafetyEquipment,
} from "src/ts/interfaces/project/porjectCatalog";
import axios from "src/utils/axios";

const safetyEquipments = () => {
  return axios.get<ISafetyEquipment[]>("SafetyEquipment");
};

const ladderAFrame = () => {
  return axios.get<ILadderAFrame[]>("LadderAFrame");
};

const ladderExtension = () => {
  return axios.get<ILadderExtension[]>("LadderExtension");
};

const projectCatalogs = {
  safetyEquipments,
  ladderAFrame,
  ladderExtension,
};

export default projectCatalogs;
