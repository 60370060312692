import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import {
  Grid,
  Card as MuiCard,
  CardContent,
  Chip as MuiChip,
  Link,
} from "@mui/material";
import { SpacingProps, spacing } from "@mui/system";
import { ColumnType } from "src/types/enhancedTable";
import { GridActionButton } from "src/components/gridControls";
import HeaderPage from "src/components/page/HeaderPage";
import {
  AccessTimeIcon,
  AddIcon,
  CheckCircleOutlineOutlinedIcon,
  SearchIcon,
} from "src/components/icons";
import { IContractor } from "src/ts/interfaces/contractor";
import contractorService from "src/services/contractorService";
import {
  Form,
  useAsyncQuery,
  useAuth,
  useCountry,
  useForm,
  useLog,
  useStateCatalog,
} from "src/hooks";
import LocalEnhancedTable from "src/components/localTable/LocalTable";
import RatingInformation from "../projectOutForBid/Components/RatingInformationPopUp";
import {
  FormButton,
  FormCheckBox,
  FormRaitingStarts,
  FormSelect,
  FormText,
} from "src/components/formControls";
import MatchWordConfirmModal from "src/components/modals/MatchWordConfirmModal";
import ContractorPopUp from "./ContractorPopUp";
import AddContractorPopUp from "./AddContractorPopUp";
import { COUNTRIES } from "src/constants";
interface ChipProps extends SpacingProps {
  component?: React.ElementType;
  href?: string;
  icon?: JSX.Element | null;
}
const Card = styled(MuiCard)(spacing);
const Chip = styled(MuiChip)<ChipProps>(spacing);
const columns = (
  setShowRatingContractorPopUp: React.Dispatch<
    React.SetStateAction<{
      show: boolean;
      id: number;
    }>
  >,
  setDeleteModalHandler: React.Dispatch<
    React.SetStateAction<{
      show: boolean;
      id: number;
    }>
  >,
  setShowContractorPopUp: React.Dispatch<
    React.SetStateAction<{
      show: boolean;
      id: number;
    }>
  >,
  handleResendInvitacion: (contractorId: number) => void
): ColumnType[] => [
  {
    id: "type",
    label: "Type",
    type: "custom",
    sort: true,
    callback: (row: IContractor) => {
      return (
        <>
          {!row.isKnownContractor && !row.isTermsAndConditionsAccepted && (
            <Chip label={"New Contractor"} color="warning" m={1} size="small" />
          )}
          {row.isKnownContractor && (
            <Chip
              label={"Certified Contractor"}
              color="success"
              m={1}
              size="small"
            />
          )}
          {!row.isKnownContractor && row.isTermsAndConditionsAccepted && (
            <Chip label={"Demo/Training"} color="primary" m={1} size="small" />
          )}
        </>
      );
    },
  },
  {
    id: "name",
    label: "Name",
    type: "string",
    sort: true,
  },
  {
    id: "countryState",
    label: "Country/State",
    type: "string",
    sort: true,
  },
  {
    id: "phoneNumber",
    label: "Phone",
    type: "phone",
    sort: true,
  },
  {
    id: "email",
    label: "Email",
    type: "string",
    sort: true,
  },
  {
    id: "registrationStatus ",
    label: "Registration Status ",
    type: "custom",
    sort: false,
    callback: (row: IContractor) => {
      return (
        <>
          {row.isTermsAndConditionsAccepted && (
            <Chip
              label="Confirmed"
              icon={<CheckCircleOutlineOutlinedIcon />}
              color="success"
              m={1}
              size="small"
            />
          )}
          {!row.isTermsAndConditionsAccepted && (
            <Chip
              label="Pending confirmation"
              m={1}
              icon={<AccessTimeIcon />}
              size="small"
            />
          )}
        </>
      );
    },
  },
  {
    id: "rating",
    label: "Rating",
    type: "custom",
    sort: false,
    width: "310px",
    callback: (row: IContractor) => {
      return (
        <>
          <Link
            onClick={() => {
              setShowRatingContractorPopUp({
                show: true,
                id: row.companyContractorId ?? 0,
              });
            }}
            component="button"
            variant="body2"
          >
            <FormRaitingStarts value={row.rating ?? 0} />
          </Link>
        </>
      );
    },
  },
  {
    id: "actions",
    label: "Actions",
    type: "custom",
    sort: false,
    width: "310px",
    callback: (row: IContractor) => {
      return (
        <>
          {row.isTermsAndConditionsAccepted && !row.isKnownContractor && (
            <GridActionButton
              type="view"
              onClick={() => {
                setShowContractorPopUp({
                  show: true,
                  id: row.id ?? 0,
                });
              }}
              tooltip="View Contractor"
            />
          )}
          {!row.isTermsAndConditionsAccepted && !row.isKnownContractor && (
            <GridActionButton
              type="email"
              onClick={() => {
                handleResendInvitacion(row.id);
              }}
              tooltip="Resend invitation"
            />
          )}
          {!row.isTermsAndConditionsAccepted && (
            <GridActionButton
              type="edit"
              onClick={() => {
                setShowContractorPopUp({
                  show: true,
                  id: row.id ?? 0,
                });
              }}
              tooltip="Update Contractor"
            />
          )}
          {!(row.isTermsAndConditionsAccepted && !row.isKnownContractor) && (
            <GridActionButton
              type="delete"
              onClick={() => {
                setDeleteModalHandler({
                  show: true,
                  id: row.id,
                });
              }}
              tooltip="Delete Contractor"
            />
          )}
        </>
      );
    },
  },
];

const initialValues = {
  id: 0,
  name: "",
  statusId: "",
  deactivationReason: "",
};

const initialValuesFilter = {
  search: null,
  stateName: null,
  countryId: COUNTRIES.USA,
  stateId: Number.MIN_SAFE_INTEGER,
  isUnion: false,
};

const ConstractorsList = () => {
  const { user } = useAuth();
  const { log } = useLog();
  const { execute, data, isLoading } = useAsyncQuery<IContractor[]>(
    contractorService.getContractors
  );
  const { execute: executeRemove } = useAsyncQuery(
    contractorService.removeContractor
  );
  const { execute: executeResend } = useAsyncQuery(
    contractorService.resendInvitation
  );
  const { values: filterValues } = useForm(initialValues, true, []);
  const [showRatingContractorPopUp, setShowRatingContractorPopUp] = useState({
    show: false,
    id: 0,
  });
  const [showContractorPopUp, setShowContractorPopUp] = useState({
    show: false,
    id: 0,
  });
  const [showAddContractorPopUp, setShowAddContractorPopUp] = useState(false);
  const [deleteModalHandler, setDeleteModalHandler] = useState({
    show: false,
    id: 0,
  });
  const [refreshTable, setRefreshTable] = useState(false);
  const {
    values: valuesFilter,
    handleInputChange: handleInputChangeFilter,
    setValues: setValuesFilter,
  } = useForm(initialValuesFilter, true, []);
  const { countriesKeyValue } = useCountry();
  const { states, setCountryId } = useStateCatalog();

  useEffect(() => {
    const getData = async () => {
      await handleGetAll();
    };
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshTable]);

  useEffect(() => {
    setCountryId(valuesFilter.countryId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valuesFilter.countryId]);

  const handleConfirmDelete = async () => {
    await executeRemove(deleteModalHandler.id);
    setDeleteModalHandler({
      show: false,
      id: 0,
    });
    setRefreshTable(!refreshTable);
  };

  const handleResendInvitacion = async (contractorId: number) => {
    await executeResend(contractorId);
    log.success("Invitation has been sent successfully");
  };

  const handleRefreshTable = () => {
    setRefreshTable(!refreshTable);
  };

  const handleGetAll = async () => {
    await execute(
      user?.companyId,
      valuesFilter.isUnion,
      valuesFilter.search,
      valuesFilter.stateName,
      valuesFilter.countryId,
      valuesFilter.stateId
    );
  };

  return (
    <>
      <HeaderPage
        title={"Preferred Contractors"}
        parentText={"Dashboard"}
        parentLink={`/`}
        pageYoutube="Preferred Contractors"
        actionSection={
          <Grid container spacing={2} sx={{ paddingRight: "16px" }}>
            <Grid item>
              <FormButton
                text={"Add AMP Licensee Contractor"}
                onClick={() => {
                  setShowAddContractorPopUp(true);
                }}
                startIcon={<AddIcon />}
                size="small"
                color="primary"
              />
            </Grid>
            <Grid item>
              <FormButton
                text={"Create & Invite New Contractor"}
                onClick={() => {
                  setShowContractorPopUp({
                    show: true,
                    id: 0,
                  });
                }}
                startIcon={<AddIcon />}
                size="small"
                color="primary"
              />
            </Grid>
          </Grid>
        }
      ></HeaderPage>
      <Card mb={6}>
        <CardContent>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Form>
                <Grid container spacing={5}>
                  <Grid item xs={8}>
                    <FormText
                      name="search"
                      label="Search"
                      value={valuesFilter.search}
                      onChange={handleInputChangeFilter}
                    />
                  </Grid>
                  <Grid item xs={2} textAlign={"right"}>
                    <FormCheckBox
                      value={valuesFilter.isUnion}
                      onChange={() => {
                        setValuesFilter({
                          ...valuesFilter,
                          isUnion: !valuesFilter.isUnion,
                        });
                      }}
                      name={"isUnion"}
                      label={"Union Affiliated"}
                    />
                  </Grid>
                  <Grid item xs={2} textAlign={"right"}>
                    <FormButton
                      text={"Search"}
                      startIcon={<SearchIcon />}
                      onClick={handleGetAll}
                      size="small"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <FormSelect
                      name="countryId"
                      label="Country"
                      value={valuesFilter.countryId}
                      onChange={handleInputChangeFilter}
                      options={countriesKeyValue}
                      // showSkeleton={isLoading}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    {valuesFilter.countryId !== Number.MIN_SAFE_INTEGER &&
                    valuesFilter.countryId === COUNTRIES.USA ? (
                      <FormSelect
                        name="stateId"
                        label="State"
                        value={valuesFilter.stateId}
                        onChange={handleInputChangeFilter}
                        options={states}
                        defaultValue={{
                          key: Number.MIN_SAFE_INTEGER,
                          value: "All",
                        }}
                        // showSkeleton={isLoading}
                      />
                    ) : (
                      <FormText
                        name="stateName"
                        label="State"
                        value={valuesFilter.stateName}
                        onChange={handleInputChangeFilter}
                      />
                    )}
                  </Grid>
                </Grid>
              </Form>
            </Grid>
            <Grid item xs={12}>
              <LocalEnhancedTable<IContractor>
                refreshGrid={true}
                columns={columns(
                  setShowRatingContractorPopUp,
                  setDeleteModalHandler,
                  setShowContractorPopUp,
                  handleResendInvitacion
                )}
                data={Object.values(data ?? [])}
                query={filterValues.name}
                showSkeleton={isLoading}
                subItems={"subBuilding"}
                orderColumn="desc"
                defaultSortColumn="createdDate"
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      {showRatingContractorPopUp.show && (
        <RatingInformation
          showRatingContractorPopUp={showRatingContractorPopUp.show}
          setShowRatingContractorPopUp={(showDialog: boolean) => {
            setShowRatingContractorPopUp({
              ...showRatingContractorPopUp,
              show: showDialog,
            });
          }}
          informationContractor={showRatingContractorPopUp.id}
        />
      )}
      <MatchWordConfirmModal
        onConfirm={handleConfirmDelete}
        text={`Are you certain you want to delete this item?`}
        matchWord={"DELETE"}
        isDialogOpen={deleteModalHandler.show}
        setIsDialogOpen={(showDialog: boolean) => {
          setDeleteModalHandler({ ...deleteModalHandler, show: showDialog });
        }}
      />
      {showContractorPopUp.show && (
        <ContractorPopUp
          showContractorPopUp={showContractorPopUp.show}
          setShowContractorPopUp={(showDialog: boolean) => {
            setShowContractorPopUp({
              ...showContractorPopUp,
              show: showDialog,
            });
          }}
          contractorId={showContractorPopUp.id}
          setRefresh={handleRefreshTable}
        ></ContractorPopUp>
      )}
      {showAddContractorPopUp && (
        <AddContractorPopUp
          showContractorPopUp={showAddContractorPopUp}
          setShowContractorPopUp={setShowAddContractorPopUp}
          setRefresh={handleRefreshTable}
        ></AddContractorPopUp>
      )}
    </>
  );
};

export default ConstractorsList;
