import React, { useState } from "react";
import { Grid } from "@mui/material";
import { Box } from "@mui/system";
import { Form } from "src/hooks";
import { FormButton, FormCheckBox } from "src/components/formControls";
import { Aceptance } from "../interfaces/types";

interface Props {
  handleAceptanceInputChange: (e: any) => void;
  AceptanceValues: Aceptance;
}

const CompleteAcceptance = ({
  AceptanceValues,
  handleAceptanceInputChange,
}: Props) => {
  const [showMore, setShowMore] = useState(false);

  const row = 12;
  return (
    <Form>
      <Grid container spacing={5}>
        <Grid item xs={row}>
          <FormCheckBox
            label="I have reviewed this projects report in its entirety prior to this submittal. This reports required data entry fields are complete and comprehensive with any system deficiencies or discrepancies clearly noted in AMP's comments and deficiencies tabs."
            name="acceptance1"
            value={AceptanceValues.acceptance1}
            onChange={handleAceptanceInputChange}
          />
        </Grid>
        <Grid item xs={row}>
          <FormCheckBox
            label="I hereby swear that all data provided in this report is factual and truthful."
            name="acceptance2"
            value={AceptanceValues.acceptance2}
            onChange={handleAceptanceInputChange}
          />
        </Grid>
        <Grid item xs={row}>
          <FormCheckBox
            label="I accept all consequences if report is found to be fraudulent, deemed not truthful"
            name="acceptance3"
            value={AceptanceValues.acceptance3}
            onChange={handleAceptanceInputChange}
          />
        </Grid>
        <Grid item xs={row}>
          <FormButton
            text={showMore ? "View less" : "View more"}
            onClick={() => setShowMore(!showMore)}
            variant="text"
          />
          {showMore && (
            <>
              <Box>
                Examples of Fraudulent Document in a sentence Topics include how
                to identify immigration documents, specifically Permanent
                Resident Cards and Employment Authorization Documents (EAD),
                common document security features, photocopy examination of
                altered genuine documents, as well as a review on impostor
                detection. The purpose of this Fraudulent Document Recognition
                Training course is to educate and enhance the FDNS employee’s
                ability to identify and differentiate between genuine,
                counterfeit, and altered documents. FDNS supports USCIS’s
                mission by enhancing USCIS’s effectiveness and efficiency in
                detecting and removing known and suspected fraud from the
                application process, thus promoting the efficient processing of
                legitimate applications and petitions. FDNS facilitates the
                Fraudulent Document Recognition Training to detect and deter
                fraud by recognizing fraudulent immigration documents as well as
                detecting impostors. In RPGs, common dice are d4’s, d6’s, d8’s,
                d10’s, d12’s, and d20’s. Access to the Fraudulent Document
                Recognition Training is restricted to employees with a valid
                need-to-know. The Loan File or Credit File contains any
                Fraudulent Document regardless of whether or not such Loan is
                delinquent. However, additional amounts were also requested for
                other CBP initiatives, including, among others, $12 million for
                WMD detection staffing; nearly $7 million for enhancements to
                the National Targeting Center (NTC); $9 million for the Arizona
                Border Control Initiative (ABCI); nearly$5 million for Border
                Patrol training at FLETC; nearly $5 million for the Immigration
                Advisory Program (IAP); and $1 million for the Fraudulent
                Document Analysis Unit. Statement of U.S. Attorney MacBride,
                United States Attorneys Office, “Twenty-Two Alleged Members of
                Sophisticated, Violent Fraudulent Document Ring Indicted,” press
                release, February 24, 2011,http://www.justice.gov/
                usao/vae/news/2011/02/20110224arellanonr.html. If the batteries
                are open or accessible, then a visual examination of the battery
                and connections should be made to ensure that they are in good
                condition. Officials within CBP’s Fraudulent Document Analysis
                Unit confirmed that there is a huge black market demand for
                legal documentation such as Green Cards, as over 4,600 cases of
                imposter Green Cards were recorded between 2013 and 2015. FIPP
                evolved to today’s Fraudulent Document Recognition Program,
                which is the most widely used training program AAMVA offers .
                More Definitions of Fraudulent Document Fraudulent Document
                means any Loan File document which, in the reasonable and sole
                judgment of AMPLIFY CREDIT UNION, is falsified, defective,
                misleading or inaccurate in any material respect; and is the
                result of fraud by the Broker. Sample 1 Based on 1 documents
                Save Copy Fraudulent Document. Any Loan File document which, in
                the reasonable judgment of NewRez, is falsified, defective,
                misleading, or inaccurate in any material respect. Freddie Mac
                or FHLMC: Federal Home Loan Mortgage Corporation, a
                congressionally chartered corporation that purchases mortgage
                loans on the secondary mortgage market. Sample 1 Based on 1
                documents Save Copy Save Email Related to Fraudulent Document
                Fraudulent Transfer Law means any applicable US Bankruptcy Law
                or any applicable US state fraudulent transfer or conveyance
                law. fraudulent practice means a misrepresentation of facts in
                order to influence a procurement process or the execution of a
                contract to the detriment of the Procuring Entity, and includes
                collusive practices among Bidders (prior to or after bid
                submission) designed to establish bid prices at artificial,
                non-competitive levels and to deprive the Procuring Entity of
                the benefits of free and open competition. Fraudulent Transfer
                Laws has the meaning set forth in Section 2.2. Fraudulent
                Practices means a misrepresentation of facts in order to
                influence a procurement process or the execution of a contract
                to the detriment of the Borrower, and includes collusive
                practice among Bidders (prior to or after bid submission)
                designed to establish bid prices at artificial non-competitive
                levels and to deprive the Borrower of the benefits of free and
                open competition Corrupt and Fraudulent Practices means either
                one or any combination of the practices given below; Dishonest
                or Fraudulent Act means any dishonest or fraudulent act,
                including “larceny and embezzlement” as defined in Section 37 of
                the Investment Company Act of 1940, committed with the conscious
                manifest intent (1) to cause the Insured to sustain a loss and
                (2) to obtain financial benefit for the perpetrator or any other
                person (other than salaries, commissions, fees, bonuses, awards,
                profit sharing, pensions or other employee benefits). A
                Dishonest or Fraudulent Act does not mean or include a reckless
                act, a negligent act, or a grossly negligent act. Fraudulent
                Conveyance means a fraudulent conveyance under Section 548 of
                Chapter 11 of Title II of the Bankruptcy Code or a fraudulent
                conveyance or fraudulent transfer under the applicable
                provisions of any fraudulent conveyance or fraudulent transfer
                law or similar law of any state, nation or other governmental
                unit, as in effect from time to time. Repudiation/Moratorium
                means the occurrence of both of the following events: (i) an
                authorized officer of a Reference Entity or a Governmental
                Authority (x) disaffirms, disclaims, repudiates or rejects, in
                whole or in part, or challenges the validity of, one or more
                Obligations in an aggregate amount of not less than the Default
                Requirement or (y) declares or imposes a moratorium, standstill,
                roll-over or deferral, whether de facto or de jure, with respect
                to one or more Obligations in an aggregate amount of not less
                than the Default Requirement and (ii) a Failure to Pay,
                determined without regard to the Payment Requirement, or a
                Restructuring, determined without regard to the Default
                Requirement, with respect to any such Obligation occurs on or
                prior to the Repudiation/Moratorium Evaluation Date.
                Repudiation/Moratorium means the occurrence of both of the
                following events: (i) an authorized officer of a Reference
                Entity or a Governmental Authority (x) disaffirms, disclaims,
                repudiates or rejects, in whole or in part, or challenges the
                validity of, one or more Obligations in an aggregate amount of
                not less than the Default Requirement or (y) declares or imposes
                a moratorium, standstill, roll-over or deferral, whether de
                facto or de jure, with respect to one or more Obligations in an
                aggregate amount of not less than the Default Requirement and
                (ii) a Failure to Pay, determined without regard to the Payment
                Requirement, or a Restructuring, determined without regard to
                the Default Requirement, with respect to any such Obligation
                occurs on or prior to the Repudiation/Moratorium Evaluation
                Date. former Act means the Stamp Act 1921 as in force from time
                to time before the commencement day. (2) The amended Act does
                not apply to a mortgage or other security on which duty was
                payable under Part IIIE of the former Act if the amount secured
                by the mortgage does not increase on or after the commencement
                day. (3) However, a mortgage that was liable to duty under Part
                IIIE of the former Act is liable to duty under Part IIIE of the
                amended Act as if the mortgage had first been executed after the
                commencement day if an advance as defined in Part IIIE of the
                amended Act is made under the mortgage after the commencement
                day.93. Life insurance The amendments effected by sections 80,
                81, 82, 83 and 87(9) do not apply in relation to a policy of
                life insurance where the period for which the insurance is
                effected commenced before this section came into operation.94.
                Hire of goods Bribery Act 2010 means the Bribery Act 2010 and
                any subordinate legislation made under that Act from time to
                time together with any guidance or codes of practice issued by
                the relevant government department concerning the legislation.
                Consumer debtor means a debtor in a consumer transaction.
                Licensed health care practitioner means a physician, as defined
                in Section 1861(r)(1) of the Social Security Act, a registered
                professional nurse, licensed social worker or other individual
                who meets requirements prescribed by the Secretary of the
                Treasury. Insolvency with respect to any Multiemployer Plan, the
                condition that such Plan is insolvent within the meaning of
                Section 4245 of ERISA. Securities Transfer Act means the
                Securities Transfer Act (British Columbia) from time to time in
                force and all amendments thereto and includes all regulations
                and amendments thereto made pursuant to that Act. Violent crime
                means a forcible felony, as defined in Iowa Code section 702.11,
                and includes any other felony or aggravated misdemeanor which
                involved the actual or threatened infliction of physical or
                emotional injury on one or more persons. Financial assistance
                means the transfer of money or property to an Applicant or
                Participant to accomplish a public purpose of support authorized
                by Federal statute through Grants or Cooperative Agreements and
                sub-awards. For DOE, it does not include direct loans, loan
                guarantees, price guarantees, purchase agreements, Cooperative
                Research and Development Agreements (CRADAs), or any other type
                of financial incentive instrument. Violent felony means any
                offense that, if committed by an adult, would constitute a
                felony and: Foreign Corrupt Practices Act means the Foreign
                Corrupt Practices Act of the United States, 15 U.S.C. Sections
                78a, 78m, 78dd-1, 78dd-2, 78dd-3, and 78ff, as amended, if
                applicable, or any similar law of the jurisdiction where the
                Property is located or where the Company or any of its
                Subsidiaries transacts business or any other jurisdiction, if
                applicable. Electronic Signatures means any electronic symbol or
                process attached to, or associated with, any contract or other
                record and adopted by a person with the intent to sign,
                authenticate or accept such contract or record. Violent student
                means a student under the age of 21 who: Violent act means
                behavior that resulted in homicide, attempted suicide, nonfatal
                injuries, or substantial damage to property. Kickback, as used
                in this clause, means any money, fee, commission, credit, gift,
                gratuity, thing of value, or compensation of any kind which is
                provided to any prime Contractor, prime Contractor employee,
                subcontractor, or subcontractor employee for the purpose of
                improperly obtaining or rewarding favorable treatment in
                connection with a prime contract or in connection with a
                subcontract relating to a prime contract. Consumer obligor means
                an obligor who is an individual and who incurred the obligation
                as part of a transaction entered into primarily for personal,
                family, or household purposes. Corrupt practices means the
                offering, giving, receiving or soliciting of anything of value
                to influence the action of a Government official in procurement
                process or in contract execution: and Health care practitioner
                means an individual licensed by the Board of Medical Practice or
                by a board attached to the Office of Professional Regulation to
                provide professional health care services in this State.
              </Box>
              <FormButton
                text={"View less"}
                onClick={() => setShowMore(false)}
                variant="text"
              />
            </>
          )}
        </Grid>
      </Grid>
    </Form>
  );
};

export default CompleteAcceptance;
