import React, { useEffect } from "react";
import { Typography, Stack } from "@mui/material";
import { ColumnType } from "src/types/enhancedTable";
import { useForm, useAuth, useAsyncQuery } from "src/hooks";
import LocalEnhancedTable from "src/components/localTable/LocalTable";
import { GridActionButton } from "src/components/gridControls";
import { buildingService } from "src/services";
import { IBuildingSelected, ProjectAuditorListDTO } from "src/ts/interfaces";
import getProjectSingleService from "src/services/project/getProjectSingleService";
import { useNavigate } from "react-router-dom";
import { FormButton } from "src/components/formControls";
import { ApartmentIcon } from "src/components/icons";

const initialValues = {
  id: 0,
  name: "",
  statusId: "",
};

const getColumns = (
  projects: ProjectAuditorListDTO[],
  selectedBuilding: number | null,
  setSelectedBuildingHandler: (row: IBuildingSelected) => void,
  addSubBuildingHandler: (row: IBuildingSelected) => void,
  editSpecificsHandler: (row: IBuildingSelected) => void,
  gotoProjectHandler: (row: IBuildingSelected) => void
): ColumnType[] => [
  { id: "serial", label: "Serial", type: "string", sort: true },
  {
    id: "name",
    label: "Name",
    type: "custom",
    sort: true,
    callback: (row: IBuildingSelected) => {
      return (
        <>
          {row.name}
          {row.activeCovidProjectId > 0 && (
            <Typography color="success.main">
              This building already has an ACTIVE Infectious Control Compliancy
              Project.
            </Typography>
          )}
        </>
      );
    },
  },
  {
    id: "address",
    label: "",
    type: "custom",
    sort: true,
    callback: (row: IBuildingSelected) => row.address,
  },
  {
    id: "createdDate",
    label: "Date Created",
    type: "utcDate",
    sort: true,
    format: "MM/DD/yyyy",
  },
  { id: "airHandlers", label: "# Air Handlers", type: "string", sort: true },
  {
    id: "specificsCompleted",
    label: "Completed?	",
    type: "custom",
    sort: true,
    callback: (row: IBuildingSelected) =>
      row.specificsCompleted ? (
        <Typography color="success.main">Yes</Typography>
      ) : (
        <Typography color="error.main">No</Typography>
      ),
  },
  {
    id: "action",
    label: "Actions",
    type: "custom",
    sort: false,
    align: "center",
    callback: (row: IBuildingSelected) => (
      <Stack direction="row">
        <GridActionButton
          type="add"
          tooltip="Add Sub-Building"
          onClick={() => addSubBuildingHandler(row)}
        />
        <GridActionButton
          type="edit"
          tooltip="Edit Building Specifics"
          onClick={() => editSpecificsHandler(row)}
        />
      </Stack>
    ),
  },
  {
    id: "action2",
    label: "",
    type: "custom",
    sort: false,
    callback: (row: IBuildingSelected) => {
      const showGotoProject = row.activeCovidProjectId > 0;
      return (
        <Stack direction="row">
          {row.activeCovidProjectId === 0 && (
            <GridActionButton
              type="enable"
              tooltip="Select Building"
              onClick={() => setSelectedBuildingHandler(row)}
              color={selectedBuilding === row.id ? "success" : "default"}
            />
          )}
          {showGotoProject && (
            <GridActionButton
              type="forward"
              tooltip="Go To Project"
              onClick={() => gotoProjectHandler(row)}
            />
          )}
        </Stack>
      );
    },
  },
];

interface Props {
  updateControlStep: (step: number, value: boolean) => void;
  value: boolean;
  setSelectedBuilding: (buildingId: number | null) => void;
  selectedBuilding: number | null;
}
const step = 2;
const SelectBuilding = ({
  updateControlStep,
  value,
  setSelectedBuilding,
  selectedBuilding,
}: Props) => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const { values: filterValues } = useForm(initialValues, true, []);

  const { execute: getProjects, data: projects } = useAsyncQuery<
    ProjectAuditorListDTO[]
  >(getProjectSingleService.getProjectsByAuditor);

  const { execute, data, isLoading } = useAsyncQuery<IBuildingSelected[]>(
    buildingService.getByCompany
  );

  useEffect(() => {
    const getData = async () => {
      if (
        user?.role === "EnergyAuditor" ||
        user?.role === "TemporalEnergyAuditor"
      ) {
        await getProjects();
      }
      await execute(user?.companyId);
    };

    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setSelectedBuildingHandler = (row: IBuildingSelected) => {
    setSelectedBuilding(row.id);
    updateControlStep(step, true);
  };

  const addSubBuildingHandler = (row: IBuildingSelected) => {
    navigate(`/app/Building/0/${user?.companyId}/${row.id}`);
  };

  const editSpecificsHandler = (row: IBuildingSelected) => {
    navigate(`/app/BuildingInfectProject/${row.id}/${user?.companyId}/true`);
  };

  const gotoProjectHandler = (row: IBuildingSelected) => {
    navigate(
      `/app/Project/Edit/${row.activeCovidProjectId}/${user?.companyId}`
    );
  };

  const gotoCreateNewBuilding = () => {
    navigate(`/app/Building/0/${user?.companyId}`);
  };

  const columns = getColumns(
    projects,
    selectedBuilding,
    setSelectedBuildingHandler,
    addSubBuildingHandler,
    editSpecificsHandler,
    gotoProjectHandler
  );

  return (
    <>
      <Stack
        direction="row"
        justifyContent={"center"}
        alignItems="center"
        spacing={5}
        paddingTop={10}
        paddingBottom={5}
      >
        <Typography variant="h4" align="center">
          Select a building below for your Infectious Control Compliancy Project
          or
        </Typography>

        <FormButton
          text="Create New Building"
          onClick={gotoCreateNewBuilding}
          startIcon={<ApartmentIcon />}
          variant="outlined"
        />
      </Stack>

      <LocalEnhancedTable<IBuildingSelected>
        columns={columns}
        data={data}
        query={filterValues.name}
        showSkeleton={isLoading}
      />
    </>
  );
};

export default SelectBuilding;
