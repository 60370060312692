import React, { useEffect, useState } from "react";
import { NavigateFunction, useNavigate } from "react-router-dom";
import styled from "@emotion/styled";
import { Chip as MuiChip, Stack } from "@mui/material";
import { spacing, SpacingProps } from "@mui/system";
import { ColumnType } from "src/types/enhancedTable";
import { ICompanyData } from "src/ts/interfaces";
import CompanyService from "src/services/companyService";
import { useTable } from "src/hooks/useTable";
import { FilterType } from "./types/FilterTypes";
import { getQueryString } from "./util/common";
import { GridActionButton } from "src/components/gridControls";
import useLoading from "src/hooks/useLoading";
import { useAsyncMutation, useAuth, useLog } from "src/hooks";
import { Permission as PermissionTypes } from "src/ts/enums";
import { usePermissions } from "src/hooks";
import MatchWordConfirmModal from "src/components/modals/MatchWordConfirmModal";
import { ROLES } from "src/constants";
import { userService } from "src/services";

interface ChipProps extends SpacingProps {
  component?: React.ElementType;
  href?: string;
  icon?: JSX.Element | null;
}

const Chip = styled(MuiChip)<ChipProps>(spacing);

const getColumns = (
  navigate: NavigateFunction,
  setShowDeleteModal: (val: boolean) => void,
  setIdCompany: (val: number) => void,
  fullAccess: boolean | undefined,
  userRole: string,
  sendEmail: (...params: any[]) => Promise<void>
): ColumnType[] => [
  {
    id: "code",
    label: "Code",
    type: "string",
    sort: true,
    callback: (row: ICompanyData) => {
      return row.code;
    },
  },
  {
    id: "name",
    label: "Company Name",
    type: "string",
    sort: true,
    callback: (row: ICompanyData) => <div>{row.name}</div>,
  },
  {
    id: "companyType",
    label: "Company Type",
    type: "custom",
    sort: true,
    callback: (row: ICompanyData) => {
      return (
        <>
          {!row.boma ? (
            <Chip label={row.companyType} color="primary" m={1} size="small" />
          ) : (
            <Chip
              label={row.companyType}
              color="primary"
              variant="outlined"
              m={1}
              size="small"
            />
          )}
          {row.satelliteCount > 0 ? (
            <Chip
              label={`Satellite Companies: ${row.satelliteCount}`}
              color="info"
              m={1}
              size="small"
            />
          ) : null}
        </>
      );
    },
  },
  {
    id: "createdDate",
    label: "Created Date",
    type: "date",
    format: "MM/DD/YYYY",
    sort: true,
    hide: userRole === ROLES.UnionAdministrator,
  },
  {
    id: "ownerName",
    label: "Owner",
    type: "string",
    sort: true,
  },
  {
    id: "email",
    label: "Email",
    type: "string",
    sort: true,
  },
  {
    id: "x",
    label: "Actions",
    type: "custom",
    sort: false,
    callback: (row: ICompanyData) => (
      <div>
        <Stack direction="row">
          <GridActionButton
            type={fullAccess ? "edit" : "view"}
            onClick={() => {
              navigate(`/app/company/${row.id}`);
            }}
            tooltip={fullAccess ? "Edit" : "View"}
          />
          {fullAccess && (
            <GridActionButton
              type={"delete"}
              onClick={() => {
                setIdCompany(row.id);
                setShowDeleteModal(true);
              }}
              tooltip="Delete"
            />
          )}
          {row.isEmailConfirmed === false && (
            <GridActionButton
              type="email"
              onClick={async () => {
                await sendEmail(row.administratorId, true);
              }}
              tooltip="Resend invitation"
            />
          )}
        </Stack>
      </div>
    ),
  },
];

const getColumnsAffilated = (
  navigate: NavigateFunction,
  setShowDeleteModal: (val: boolean) => void,
  setIdCompany: (val: number) => void
): ColumnType[] => [
  {
    id: "code",
    label: "Code",
    type: "string",
    sort: true,
    callback: (row: ICompanyData) => {
      return row.code;
    },
  },
  {
    id: "name",
    label: "Company Name",
    type: "string",
    sort: true,
    callback: (row: ICompanyData) => <div>{row.name}</div>,
  },
  {
    id: "companyType",
    label: "Company Type",
    type: "custom",
    sort: true,
    callback: (row: ICompanyData) => {
      return (
        <>
          {!row.boma ? (
            <Chip label={row.companyType} color="primary" m={1} size="small" />
          ) : (
            <Chip
              label={row.companyType}
              color="primary"
              variant="outlined"
              m={1}
              size="small"
            />
          )}
          {row.satelliteCount > 0 ? (
            <Chip
              label={`Satellite Companies: ${row.satelliteCount}`}
              color="info"
              m={1}
              size="small"
            />
          ) : null}
        </>
      );
    },
  },
  {
    id: "createdDate",
    label: "Created Date",
    type: "date",
    format: "MM/DD/YYYY",
    sort: true,
  },
  {
    id: "localUnion",
    label: "Local Union #",
    type: "date",
    format: "MM/DD/YYYY",
    sort: true,
  },
  {
    id: "ownerName",
    label: "Owner",
    type: "string",
    sort: true,
  },
  {
    id: "email",
    label: "Email",
    type: "string",
    sort: true,
  },
  {
    id: "x",
    label: "Actions",
    type: "custom",
    sort: false,
    callback: (row: ICompanyData) => (
      <div>
        <Stack direction="row">
          <GridActionButton
            type={"edit"}
            onClick={() => {
              navigate(`/app/company/${row.id}`);
            }}
            tooltip="Edit"
          />
          <GridActionButton
            type={"delete"}
            onClick={() => {
              setIdCompany(row.id);
              setShowDeleteModal(true);
            }}
            tooltip="Delete"
          />
        </Stack>
      </div>
    ),
  },
];
interface companyTableProps {
  filters: FilterType;
  refresh: boolean;
  parentCompanyId: number;
}

function IncompleteCompanies(props: companyTableProps) {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [idcompany, setIdCompany] = useState(0);
  const { parentCompanyId } = props;
  const { startRequest, endRequest } = useLoading();
  const [refresh, setRefresh] = useState(props.refresh);
  const { user } = useAuth();
  let navigate = useNavigate();
  const { execute: sendEmail } = useAsyncMutation(userService.sendEmail, {
    successfulMessage: "Account confirmation email was successfully sent",
  });
  const { log } = useLog();
  const { fullAccess } = usePermissions(
    PermissionTypes.Companies_Incomplete_Tab
  );

  let columns = getColumns(
    navigate,
    setShowDeleteModal,
    setIdCompany,
    fullAccess,
    user?.role,
    sendEmail
  );
  let columnsAffiliated = getColumnsAffilated(
    navigate,
    setShowDeleteModal,
    setIdCompany
  );

  const { Table, setRowsPerPage, page, setDataSet, setCount, orderBy, order } =
    useTable(props.filters.isUnionAffiliated ? columnsAffiliated : columns);

  useEffect(() => {
    const getData = async () => {
      try {
        startRequest("IncompleteCompanies");
        const request = await CompanyService.getAll(
          {
            page: page + 1,
            sortColumn: orderBy,
            sortDec: order === "desc" ? true : false,
            isActive: true,
            parentCompanyId: parentCompanyId,
            signupDone: false,
            countryId: props.filters.countryId as number,
            stateId: props.filters.stateId as number,
            stateName: props.filters.stateName,
            subscriptionId: props.filters.subscriptionId as number,
            filter: props.filters.filter,
            isUnionAffiliated: props.filters.isUnionAffiliated,
            canceledUnion: false,
            pendingUnion: false,
            laborUnionId: props.filters.laborUnionId,
            localUnionId: props.filters.localUnionId,
          },
          getQueryString
        );

        setCount(request.data.count);
        setDataSet(request.data.data);
        setRowsPerPage(10);
        endRequest("IncompleteCompanies");
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
        endRequest("IncompleteCompanies");
      }
    };

    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    page,
    orderBy,
    order,
    props.refresh,
    refresh,
    parentCompanyId,
    props.filters.isUnionAffiliated,
  ]);

  const deleteCompany = () => {
    const deleteComp = async () => {
      try {
        await CompanyService.IncompleteCompanyDelete(idcompany);
        log.success("Company was deleted");
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      } finally {
        setRefresh(!refresh);
      }
    };
    deleteComp();
  };

  return (
    <>
      <Table />
      <MatchWordConfirmModal
        onConfirm={deleteCompany}
        text={`Are you certain you want to delete this company?`}
        matchWord={"DELETE"}
        isDialogOpen={showDeleteModal}
        setIsDialogOpen={setShowDeleteModal}
      />
    </>
  );
}

export default IncompleteCompanies;
