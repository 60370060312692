import {
  ITermsAndConditions,
  ITermsAndConditionsList,
} from "src/ts/interfaces/catalogs/termsAndConditions";
import axios from "../utils/axios";

const getAll = async () => {
  return axios.get<ITermsAndConditionsList[]>(`TermsAndConditions/GetAll`);
};

const getCurrent = async () => {
  return axios.get<ITermsAndConditions>(`TermsAndConditions/Current`);
};

const getCurrentById = async (id: number) => {
  return axios.get<ITermsAndConditions>(`TermsAndConditions?id=${id}`);
};

const add = async (item: ITermsAndConditions) => {
  return axios.post(`TermsAndConditions`, item);
};

const termsAndConditionsService = {
  getAll,
  getCurrent,
  add,
  getCurrentById,
};

export default termsAndConditionsService;
