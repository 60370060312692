import {
  CardContent,
  Grid,
  Stack,
  Divider as MuiDivider,
  Card as MuiCard,
  Typography,
  Chip as MuiChip,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";

import { SpacingProps, spacing } from "@mui/system";
import styled from "@emotion/styled";
import { useEffect, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { FormCheckSwitch } from "src/components/formControls";
import useFormTyped from "src/hooks/useFormTyped";
import {
  useAsyncMutation,
  useAsyncQuery,
  useAuth,
  useSystemOfMeasurement,
} from "src/hooks";
import { IProjectReport } from "src/ts/interfaces/project/projectDto";
import projectReportService from "src/services/study/projectReportService";
import getProjectService from "src/services/project/getProjectService";
import { IElectricCoil } from "src/ts/interfaces/study/electricCoil";
import electricCoilService from "src/services/study/electricCoilService";
import { steps } from "./const/const";
import { FooterButtons } from "../components/FooterButtons";
import HeaderStudyPage from "src/components/page/HeaderStudyPage";
import StudyWizard from "../components/StudyWizard";
interface ChipProps extends SpacingProps {
  component?: React.ElementType;
  href?: string;
  icon?: JSX.Element | null;
}
const Chip = styled(MuiChip)<ChipProps>(spacing);
const initialValues: IElectricCoil = {
  id: 0,
  projectId: 0,
  projectName: "",
  reportTypeId: 0,
  systemField: "",
  roomAreaServed: "",
  description: "",
  totalBTUSystem: false,
  vavSystemTotal: false,
  vavOutletTotal: false,
  fillNext: false,
  deviceCoefficient: false,
  airflowFlowingCoilsId: 0,
  nameOfPerson: "",
  title: "",
  company: "",
  dateWhenTold: "",
  designAirflowDB: false,
  actualAirflowDB: false,
  designAirflow: false,
  actualAirflow: false,
  rh: false,
  wb: false,
  dp: false,
  designAirflowSP: false,
  actualAirflowSP: false,
  designBTUH: false,
  actualBTUH: false,
  designBTUHTotal: false,
  actualBTUHTotal: false,
  systemSteadyThisTest: false,
  systemSteadyWillBeMonitored: false,
  step: 0,
  isComplete: false,
  statusId: 0,
  createdDate: new Date(),
  createdBy: "",
  updatedDate: new Date(),
  updatedBy: "",
  userAuth: "",
  userName: "",
  mandatoryMinimum: false,
  isProjectDisabled: false,
};

const Divider = styled(MuiDivider)(spacing);
const Card = styled(MuiCard)(spacing);

const ElectricCoilStep2 = () => {
  const { user } = useAuth();
  const { systemOfMeasurement } = useSystemOfMeasurement(true);
  const params = useParams<{ id: string }>();
  const id = parseInt(params?.id === undefined ? "0" : params?.id);
  let navigate = useNavigate();
  const { execute: executeUpdate, isSubmitting: isSubmittingUpdate } =
    useAsyncMutation<number>(electricCoilService.update, {
      successfulMessage: "The report was updated successfully",
      onSuccess: async (result) => {
        await setValues({
          ...result,
        });
      },
    });

  const validate: any = (fieldValues = values) => {
    let temp: Record<string, string> = { ...errors };

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange } =
    useFormTyped<IElectricCoil>(initialValues, false, validate);

  const { execute, isLoading } = useAsyncQuery<IElectricCoil>(
    electricCoilService.getById,
    {
      onSuccess: (dataResult) => {
        const getData = async () => {
          const measurementSystemResponse =
            await getProjectService.getMeasurementSystem(dataResult?.projectId);
          systemOfMeasurement.setSystem(measurementSystemResponse.data);
        };
        getData();
        setValues(dataResult);
      },
    }
  );

  const { execute: executeReport, data: dataReport } =
    useAsyncQuery<IProjectReport>(projectReportService.report);

  const addAllAirflow = useMemo(() => {
    return (
      values?.designAirflowDB &&
      values?.actualAirflowDB &&
      values?.designAirflowSP &&
      values?.actualAirflowSP &&
      values?.designAirflow &&
      values?.actualAirflow &&
      values?.designBTUHTotal &&
      values?.actualBTUHTotal
    );
  }, [
    values?.actualAirflow,
    values?.actualAirflowDB,
    values?.actualAirflowSP,
    values?.actualBTUHTotal,
    values?.designAirflow,
    values?.designAirflowDB,
    values?.designAirflowSP,
    values?.designBTUHTotal,
  ]);

  const addAllElectric = useMemo(() => {
    return values?.designBTUH && values?.actualBTUH;
  }, [values?.actualBTUH, values?.designBTUH]);

  useEffect(() => {
    const getData = async () => {
      await execute(id);
    };

    if (id !== 0) getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    const getData = async () => {
      await executeReport(id, values?.reportTypeId === 28 ? "COEA" : "COET");
    };

    if (values && values?.id > 0) getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.id]);

  const nextStepHandler = async () => {
    if (!disableStudy) handleSave();
    navigate(`/studies/ElectricCoil/step3/${id}/`);
  };

  const handleBack = async () => {
    if (!disableStudy) handleSave();
    navigate(`/studies/ElectricCoil/step1/${id}/`);
  };

  const handleSave = async () => {
    if (!validate()) return;
    await executeUpdate(values.id, values);
  };

  const handleAddAllElectric = () => {
    setValues({
      ...values,
      designBTUH: !addAllElectric,
      actualBTUH: !addAllElectric,
    });
  };

  const handleAddAllAirflow = () => {
    setValues({
      ...values,
      designAirflowDB: !addAllAirflow,
      actualAirflowDB: !addAllAirflow,
      designAirflowSP: !addAllAirflow,
      actualAirflowSP: !addAllAirflow,
      designAirflow: !addAllAirflow,
      actualAirflow: !addAllAirflow,
      designBTUHTotal: !addAllAirflow,
      actualBTUHTotal: !addAllAirflow,
    });
  };

  const disableStudy = useMemo(() => {
    return (
      values?.isComplete ||
      (dataReport?.isInUse && dataReport?.isInUseById !== user?.userId) ||
      dataReport?.isPartOfProject === false
    );
  }, [
    user?.userId,
    values?.isComplete,
    dataReport?.isInUse,
    dataReport?.isInUseById,
    dataReport?.isPartOfProject,
  ]);

  return (
    <>
      <HeaderStudyPage
        headerStudiesPage={{
          code: values?.reportTypeId === 28 ? "COEA" : "COET",
          system: values?.systemField,
          id: id,
        }}
        parentText="Project"
        parentLink="/"
      />
      <Divider my={6} />

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <StudyWizard
            steps={steps}
            activeStep={1}
            projectId={values?.projectId}
            reportId={id}
            reportTypeId={values?.reportTypeId}
          ></StudyWizard>
        </Grid>
        <Grid item xs={12}>
          <Card mb={1}>
            <CardContent>
              <Grid container spacing={5}>
                <Grid item xs={12}>
                  <Typography variant="h5">
                    Electric Heat Transfer Performance Data
                    <Typography display={"inline"} pl={10}>
                      <FormCheckSwitch
                        name={"addAll"}
                        label={"Add All"}
                        value={addAllElectric}
                        disabled={disableStudy}
                        onChange={handleAddAllElectric}
                      ></FormCheckSwitch>
                    </Typography>
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <FormCheckSwitch
                    name={"designBTUH"}
                    label={
                      <Typography>
                        Add{" "}
                        <Chip
                          label="Design"
                          color="primary"
                          m={1}
                          size="small"
                        />{" "}
                        Coil {systemOfMeasurement.get("btuh")} Sensible
                      </Typography>
                    }
                    value={values?.designBTUH}
                    disabled={disableStudy}
                    onChange={handleInputChange}
                  ></FormCheckSwitch>
                </Grid>
                <Grid item xs={3}>
                  <FormCheckSwitch
                    name={"actualBTUH"}
                    label={
                      <Typography>
                        Add{" "}
                        <Chip label="Actual" color="error" m={1} size="small" />{" "}
                        Coil {systemOfMeasurement.get("btuh")} Sensible
                      </Typography>
                    }
                    value={values?.actualBTUH}
                    disabled={disableStudy}
                    onChange={handleInputChange}
                  ></FormCheckSwitch>
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h5">
                    Airflow Heat Transfer Performance Data
                    <Typography display={"inline"} pl={10}>
                      <FormCheckSwitch
                        name={"addAll"}
                        label={"Add All"}
                        value={addAllAirflow}
                        disabled={disableStudy}
                        onChange={handleAddAllAirflow}
                      ></FormCheckSwitch>
                    </Typography>
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <FormCheckSwitch
                    name={"designAirflowDB"}
                    label={
                      <Typography>
                        Add{" "}
                        <Chip
                          label="Design"
                          color="primary"
                          m={1}
                          size="small"
                        />{" "}
                        Airflow - ΔT {systemOfMeasurement.get("temperature")} DB
                        Column (Sensible)
                      </Typography>
                    }
                    value={values?.designAirflowDB}
                    disabled={disableStudy}
                    onChange={handleInputChange}
                  ></FormCheckSwitch>
                </Grid>
                <Grid item xs={3}>
                  <FormCheckSwitch
                    name={"actualAirflowDB"}
                    label={
                      <Typography>
                        Add{" "}
                        <Chip label="Actual" color="error" m={1} size="small" />{" "}
                        Airflow - ΔT {systemOfMeasurement.get("temperature")} DB
                        Column (Sensible)
                      </Typography>
                    }
                    value={values?.actualAirflowDB}
                    disabled={disableStudy}
                    onChange={handleInputChange}
                  ></FormCheckSwitch>
                </Grid>
                <Grid item xs={3}>
                  <FormCheckSwitch
                    name={"designAirflowSP"}
                    label={
                      <Typography>
                        Add{" "}
                        <Chip
                          label="Design"
                          color="primary"
                          m={1}
                          size="small"
                        />{" "}
                        Airflow - Coil Δ SP {systemOfMeasurement.get("inwc")}{" "}
                        Column
                      </Typography>
                    }
                    value={values?.designAirflowSP}
                    disabled={disableStudy}
                    onChange={handleInputChange}
                  ></FormCheckSwitch>
                </Grid>
                <Grid item xs={3}>
                  <FormCheckSwitch
                    name={"actualAirflowSP"}
                    label={
                      <Typography>
                        Add{" "}
                        <Chip label="Actual" color="error" m={1} size="small" />{" "}
                        Airflow - Coil Δ SP {systemOfMeasurement.get("inwc")}{" "}
                        Column
                      </Typography>
                    }
                    value={values?.actualAirflowSP}
                    disabled={disableStudy}
                    onChange={handleInputChange}
                  ></FormCheckSwitch>
                </Grid>
                <Grid item xs={3}>
                  <FormCheckSwitch
                    name={"designAirflow"}
                    label={
                      <Typography>
                        Add{" "}
                        <Chip
                          label="Design"
                          color="primary"
                          m={1}
                          size="small"
                        />{" "}
                        Airflow - % RH, WB, DP Column
                      </Typography>
                    }
                    value={values?.designAirflow}
                    disabled={disableStudy}
                    onChange={handleInputChange}
                  ></FormCheckSwitch>
                </Grid>
                <Grid item xs={3}>
                  <FormCheckSwitch
                    name={"actualAirflow"}
                    label={
                      <Typography>
                        Add{" "}
                        <Chip label="Actual" color="error" m={1} size="small" />{" "}
                        Airflow - % RH, WB, DP Column
                      </Typography>
                    }
                    value={values?.actualAirflow}
                    disabled={disableStudy}
                    onChange={handleInputChange}
                  ></FormCheckSwitch>
                </Grid>
                <Grid item xs={3}>
                  <FormCheckSwitch
                    name={"designBTUHTotal"}
                    label={
                      <Typography>
                        Add{" "}
                        <Chip
                          label="Design"
                          color="primary"
                          m={1}
                          size="small"
                        />{" "}
                        Coil Airflow
                      </Typography>
                    }
                    value={values?.designBTUHTotal}
                    disabled={disableStudy}
                    onChange={handleInputChange}
                  ></FormCheckSwitch>
                </Grid>
                <Grid item xs={3}>
                  <FormCheckSwitch
                    name={"actualBTUHTotal"}
                    label={
                      <Typography>
                        Add{" "}
                        <Chip label="Actual" color="error" m={1} size="small" />{" "}
                        Coil Airflow
                      </Typography>
                    }
                    value={values?.actualBTUHTotal}
                    disabled={disableStudy}
                    onChange={handleInputChange}
                  ></FormCheckSwitch>
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                {(values?.designAirflow || values?.actualAirflow) && (
                  <Grid item xs={6} textAlign={"right"}>
                    <Typography variant="subtitle2" pt={2}>
                      When collecting Airflow Transfer Data use:
                    </Typography>
                  </Grid>
                )}
                {(values?.designAirflow || values?.actualAirflow) && (
                  <Grid item xs={6}>
                    <RadioGroup aria-label="" name="" row>
                      <FormControlLabel
                        checked={values?.rh === true}
                        control={<Radio />}
                        label="Relative Humidity"
                        onChange={() => {
                          setValues({
                            ...values,
                            rh: true,
                            wb: false,
                            dp: false,
                          });
                        }}
                        name={"relativeHumidityBool"}
                        disabled={disableStudy}
                      />
                      <FormControlLabel
                        checked={values?.wb === true}
                        control={<Radio />}
                        label="Wet bulb F"
                        onChange={() => {
                          setValues({
                            ...values,
                            rh: false,
                            wb: true,
                            dp: false,
                          });
                        }}
                        name={"wetBulbFBool"}
                        disabled={disableStudy}
                      />
                      <FormControlLabel
                        checked={values?.dp === true}
                        control={<Radio />}
                        label="Dew Point"
                        onChange={() => {
                          setValues({
                            ...values,
                            rh: false,
                            wb: false,
                            dp: true,
                          });
                        }}
                        name={"dewPointBool"}
                        disabled={disableStudy}
                      />
                    </RadioGroup>
                  </Grid>
                )}

                <Grid item xs={12}>
                  <Stack
                    direction="row"
                    spacing={2}
                    justifyContent="center"
                    alignItems="center"
                  >
                    <FooterButtons
                      activeStep={1}
                      stepsCount={steps.length}
                      projectId={values?.projectId}
                      companyId={user?.companyId as number}
                      isSaving={isLoading || isSubmittingUpdate}
                      reportName="Electric Coil AHU Mixed Air Report"
                      saveHandler={handleSave}
                      nextStepHandler={nextStepHandler}
                      previousStepHandler={handleBack}
                      disabled={disableStudy}
                      isComplete={values?.isComplete ?? true}
                    />
                  </Stack>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default ElectricCoilStep2;
