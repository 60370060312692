import axios from "src/utils/axios";

const getDownloadApp = async () => {
  return await axios.get(`appDownload`);
};

const appDownloadService = {
  getDownloadApp,
};

export default appDownloadService;
