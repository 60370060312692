import React, { ChangeEvent } from "react";
import { Stack } from "@mui/material";
import { FormUploadButton } from "src/components/formControls";

interface Props {
  onFileUpload: (e: ChangeEvent<HTMLInputElement>) => Promise<void>;
  showUploadButton: boolean;
  disableUploadButton: boolean;
  isSubmittingFile: boolean;
}

function Actions({
  onFileUpload,
  showUploadButton,
  disableUploadButton,
  isSubmittingFile,
}: Props) {
  return (
    <React.Fragment>
      <Stack direction="row" spacing={2}>
        {showUploadButton && (
          <>
            <FormUploadButton
              accept=".png, .jpg, .jpeg, .bmp, .gif"
              onChange={(e) => onFileUpload(e)}
              text={"Upload File"}
              size="small"
              isSubmitting={isSubmittingFile}
              disabled={disableUploadButton}
            />
          </>
        )}
      </Stack>
    </React.Fragment>
  );
}

export default Actions;
