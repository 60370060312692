import {
  IHistoryDailyLogTech,
  ITechniciansHistory,
  ITechniciansHistoryByProject,
} from "src/ts/interfaces/techniciansHistory";
import axios from "../utils/axios";

const getAuditorList = async (projectId: number) => {
  return axios.get<ITechniciansHistory[]>(
    `TechniciansHistory/TechHistoryByAction/${projectId}/Added`
  );
};

const getByAuditor = async (projectId: number, userId: number) => {
  return axios.get<ITechniciansHistory[]>(
    `TechniciansHistory/TechHistoryById/${projectId}/${userId}`
  );
};

const getTechByProject = async (projectId: Number) => {
  return axios.get<ITechniciansHistoryByProject>(
    `TechniciansHistory/GetTechniciansByProjectAndStatus/${projectId}`
  );
};

const getTechnicianDailyLog = async (userId: Number, companyId: Number) => {
  return axios.get<IHistoryDailyLogTech[]>(
    `TechniciansHistory/ProjectsByTechnician/${userId}/${companyId}`
  );
};

const techniciansHistoryService = {
  getAuditorList,
  getByAuditor,
  getTechByProject,
  getTechnicianDailyLog,
};

export default techniciansHistoryService;
