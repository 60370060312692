import React, { useEffect, useState } from "react";
import {
  ContentState,
  EditorState,
  convertFromRaw,
  convertToRaw,
} from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Box } from "@mui/material";

interface FormNumericProps {
  value: string;
  placeholder?: string;
  title?: string;
  disable?: boolean;
  onChange: (e: any) => void;
  name: string;
  readonly?: boolean;
}

const createState = (text: any, isJson: boolean) => {
  if (isJson) {
    const object = JSON.parse(text);
    return EditorState.createWithContent(convertFromRaw(object));
  } else {
    return EditorState.createWithContent(ContentState.createFromText(text));
  }
};

function isJson(str: string) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

const FormTextEditor = (props: FormNumericProps) => {
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );

  const convertToDefEventPara = (name: any, value: any) => ({
    target: {
      name,
      value,
    },
  });

  useEffect(() => {
    if (!props.readonly) {
      const content = JSON.stringify(
        convertToRaw(editorState.getCurrentContent())
      );
      const rawcontent = convertToDefEventPara(props.name, content);
      props.onChange(rawcontent);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editorState]);

  useEffect(() => {
    setEditorState(createState(props.value, isJson(props.value)));
    if (props.readonly) {
      const fetchData = async () => {
        await setEditorState(createState(props.value, isJson(props.value)));
      };

      fetchData().catch(console.error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.value]);

  const boxes = Array.from(
    document.getElementsByClassName(
      "toolbar-class-readonly-draft"
    ) as HTMLCollectionOf<HTMLElement>
  );

  boxes.forEach((box) => {
    box.style.display = "none";
  });

  if (props.readonly) {
    return (
      <>
        <Editor
          editorState={editorState}
          onEditorStateChange={setEditorState}
          wrapperClassName="wrapper-class"
          editorClassName="editor-class"
          toolbarClassName="toolbar-class-readonly-draft"
          readOnly={props.readonly}
        />
      </>
    );
  } else {
    return (
      <>
        <Box
          component="fieldset"
          sx={{
            borderRadius: "5px",
          }}
        >
          <legend>{props.title}</legend>
          <Editor
            editorState={editorState}
            onEditorStateChange={setEditorState}
            wrapperClassName="wrapper-class"
            editorClassName="editor-class"
            toolbarClassName="toolbar-class"
            placeholder={props.placeholder}
            readOnly={props.disable}
          />
        </Box>
      </>
    );
  }
};

export default FormTextEditor;
