import axios from "src/utils/axios";
import { IReportCategoryType } from "src/ts/interfaces";

const getReportCategoryByProjectType = (projectId: number) => {
  return axios.get<IReportCategoryType[]>(
    `ReportCategoryType/GetReportCategoryByProjectType?projectId=${projectId}`
  );
};

//
const reportCategoryType = {
  getReportCategoryByProjectType,
};

export default reportCategoryType;
