import { ROLES } from "src/constants";
import { ICompany } from "src/ts/interfaces";
import WithRole from "src/components/guards/WithRole";
import { Box, Chip, Stack, useTheme } from "@mui/material";

interface Props {
  company: ICompany;
}

const CompanyInformation = ({ company }: Props) => {
  const theme = useTheme();
  return (
    <WithRole
      value={[ROLES.Architect, ROLES.SysAdmin, ROLES.ArchitectAux]}
      action="hide"
    >
      <Stack direction="row" spacing={2}>
        {company?.headquarters ? (
          <Chip label="Headquarters" color="primary" size="small" />
        ) : (
          <Chip
            label="Satellite"
            color="primary"
            variant="outlined"
            size="small"
          />
        )}

        <Box
          pr={3}
          sx={{
            pt: `${theme.spacing(1)}`,
            pb: { xs: 1, md: 0 },
          }}
          alignSelf="center"
        >
          <b>
            Address: {company?.address}, {company?.city},{" "}
            {company?.stateName != null
              ? company?.stateName
              : company?.state?.name}{" "}
            , {company?.country?.name}
          </b>
        </Box>
      </Stack>
    </WithRole>
  );
};

export default CompanyInformation;
