import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { Helmet } from "react-helmet-async";
import { Grid, Divider as MuiDivider, Typography } from "@mui/material";
import { spacing } from "@mui/system";
import HeaderPage from "src/components/page/HeaderPage";
import { FormButton } from "src/components/formControls";
import { useAppDispatch, useAuth, useLog } from "src/hooks";
import { useNavigate } from "react-router-dom";
import PublicIcon from "@mui/icons-material/Public";
import CreateNewFolderIcon from "@mui/icons-material/CreateNewFolder";
import DriveFileMoveIcon from "@mui/icons-material/DriveFileMove";
import DashboarItem from "./DashboarItem";
import FolderIcon from "@mui/icons-material/Folder";
import SourceIcon from "@mui/icons-material/Source";
import FolderSpecialIcon from "@mui/icons-material/FolderSpecial";
import DashboarItemCreate from "./DashboarItemCreate";
import projectService from "src/services/project/projectService";
import { IProjectSummary } from "src/ts/interfaces/project/projectDto";
import { floatingBarActions } from "src/redux/slices/floatingBarActions";
import { COMMON_MENU } from "src/constants";

const Divider = styled(MuiDivider)(spacing);

const ArchitectDashboard = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const { log } = useLog();
  const [architectSummary, setArchitectSummary] = useState<IProjectSummary>();

  const dispatch = useAppDispatch();

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await projectService.getArchitectSummary();
        setArchitectSummary(response.data);
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      }
    };

    const getToolBar = () => {
      dispatch(floatingBarActions.setOptionMenu(COMMON_MENU));
    };

    getData();
    getToolBar();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      <Helmet title="Analytics Dashboard" />
      <HeaderPage
        title={"Dashboard"}
        parentText={"Dashboard"}
        parentLink={`/`}
        actionSection={
          <Grid container>
            <Grid item p={1}>
              <FormButton
                text={"My Projects"}
                onClick={() => {
                  navigate(`/app/ProjectArchitect/${user?.companyId}/all`);
                }}
                size="small"
                color="primary"
              />
            </Grid>
            <Grid item p={1}>
              <FormButton
                text={"My Contractors"}
                onClick={() => {
                  navigate("/app/Contractors");
                }}
                size="small"
                color="primary"
              />
            </Grid>
            <Grid item p={1}>
              <FormButton
                text={"My Team Members"}
                onClick={() => {
                  navigate(`/app/teamMembers/${user?.companyId}`);
                }}
                size="small"
                color="primary"
              />
            </Grid>
            <Grid item p={1}>
              <FormButton
                text={"Infectious Control/Plaque Project"}
                onClick={() => {
                  navigate(`/app/Project/CreateInfectious`);
                }}
                size="small"
                color="primary"
              />
            </Grid>
            <Grid item p={1}>
              <FormButton
                color="success"
                text={"Global Energy Prices"}
                variant="outlined"
                onClick={() => {
                  window.open("https://www.globalpetrolprices.com/", "_blank");
                }}
                startIcon={<PublicIcon />}
                size="small"
              />
            </Grid>
          </Grid>
        }
      ></HeaderPage>
      <Divider my={6} />
      <Grid container spacing={5}>
        <Typography
          variant="h6"
          sx={{ marginLeft: "20px", marginTop: "10px", marginBottom: "10px" }}
        >
          Projects
        </Typography>
      </Grid>
      <Grid container spacing={5}>
        <Grid item xs={12} sm={12} md={3} lg={3}>
          <DashboarItem
            title={"New"}
            label2={
              <CreateNewFolderIcon style={{ float: "right", fontSize: 40 }} />
            }
            showSkeleton={false}
            action={() => {
              navigate(`/app/ProjectArchitect/${user?.companyId}/new`);
            }}
            label1={""}
            chip={architectSummary?.new.toString()}
            chipColor="primary"
          />
        </Grid>
        <Grid item xs={12} sm={12} md={3} lg={3}>
          <DashboarItem
            title={"Out For Bid"}
            label2={
              <DriveFileMoveIcon style={{ float: "right", fontSize: 40 }} />
            }
            showSkeleton={false}
            action={() => {
              navigate(`/app/ProjectArchitect/${user?.companyId}/outForBid`);
            }}
            label1={""}
            chip={architectSummary?.inProcess.toString()}
            chipColor="primary"
          />
        </Grid>
        <Grid item xs={12} sm={12} md={3} lg={3}>
          <DashboarItem
            title={"Awarded"}
            label2={<FolderIcon style={{ float: "right", fontSize: 40 }} />}
            showSkeleton={false}
            action={() => {
              navigate(`/app/ProjectArchitect/${user?.companyId}/awarded`);
            }}
            label1={""}
            chip={architectSummary?.assignedProjects.toString()}
            chipColor="primary"
          />
        </Grid>
        <Grid item xs={12} sm={12} md={3} lg={3}>
          <DashboarItem
            title={"Ready for review"}
            label2={<SourceIcon style={{ float: "right", fontSize: 40 }} />}
            showSkeleton={false}
            action={() => {
              navigate(
                `/app/ProjectArchitect/${user?.companyId}/readyForReview`
              );
            }}
            label1={""}
            chip={architectSummary?.readyForReview.toString()}
            chipColor="primary"
          />
        </Grid>
        <Grid item xs={12} sm={12} md={3} lg={3}>
          <DashboarItem
            title={"Approved"}
            label2={
              <FolderSpecialIcon style={{ float: "right", fontSize: 40 }} />
            }
            showSkeleton={false}
            action={() => {
              navigate(`/app/ProjectArchitect/${user?.companyId}/approved`);
            }}
            label1={""}
            chip={architectSummary?.approved.toString()}
            chipColor="success"
          />
        </Grid>
        <Grid item xs={12} sm={12} md={3} lg={3}>
          <DashboarItem
            title={"Rejected"}
            label2={
              <DriveFileMoveIcon style={{ float: "right", fontSize: 40 }} />
            }
            showSkeleton={false}
            action={() => {
              navigate(`/app/ProjectArchitect/${user?.companyId}/rejected`);
            }}
            label1={""}
            chip={architectSummary?.rejected.toString()}
            chipColor="success"
          />
        </Grid>
        <Grid item xs={12} sm={12} md={3} lg={3}>
          <DashboarItem
            title={"Closed"}
            label2={<FolderIcon style={{ float: "right", fontSize: 40 }} />}
            showSkeleton={false}
            action={() => {
              navigate(`/app/ProjectArchitect/${user?.companyId}/closed`);
            }}
            label1={""}
            chip={architectSummary?.closed.toString()}
            chipColor="success"
          />
        </Grid>
        <Grid item xs={12} sm={12} md={3} lg={3}>
          <DashboarItem
            title={"Archived"}
            label2={<SourceIcon style={{ float: "right", fontSize: 40 }} />}
            showSkeleton={false}
            action={() => {
              navigate(`/app/ProjectArchitect/${user?.companyId}/archived`);
            }}
            label1={""}
            chip={architectSummary?.archived.toString()}
            chipColor="success"
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <DashboarItemCreate
            title={"BUILDING"}
            label1={"Create new buildings"}
            label2={"Create Building"}
            showSkeleton={false}
            action={() => {
              navigate(`/app/Building/0/${user?.companyId}`);
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <DashboarItemCreate
            title={"PROJECT"}
            label1={"Create new projects"}
            label2={"Create Project"}
            showSkeleton={false}
            action={() => {
              navigate(`/app/ProjectWizard/Edit/0/${user?.companyId}`);
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <DashboarItemCreate
            title={"TEMPLATES"}
            label1={"Configure your templates"}
            label2={"Configure your templates"}
            showSkeleton={false}
            action={() => {
              navigate(`/app/templates`);
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <DashboarItemCreate
            title={"REPORTS"}
            label1={"View sample reports"}
            label2={"View sample reports"}
            showSkeleton={false}
            action={() => {
              navigate(`/app/sampleReports`);
            }}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default ArchitectDashboard;
