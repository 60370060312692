import axios from "../utils/axios";

const create = (score: number, id: number, comments: string) => {
  return axios.post(
    `RecommendationsScore?score=${score}&recommendationId=${id}&comments=${comments}`
  );
};

const recommendationsScoreService = {
  create,
};

export default recommendationsScoreService;
