import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import {
  CardContent,
  CssBaseline,
  Grid,
  Link,
  Card as MuiCard,
  Typography,
  Divider as MuiDivider,
  Stack,
} from "@mui/material";
import { useAsyncMutation, useAsyncQuery, useTheme } from "src/hooks";
import { useNavigate, useParams } from "react-router-dom";
import GlobalStyle from "src/components/GlobalStyle";
import { Box, spacing } from "@mui/system";
import { Helmet } from "react-helmet-async";
import {
  FormCancelButton,
  FormRadioGroup,
  FormSaveButton,
  FormTextArea,
} from "src/components/formControls";
import {
  THEMES,
  lastQuestionRating,
  ratingOptionReversed,
} from "src/constants";
import DialogMessagePopup from "src/components/DialogMessagePopup";
import { IRatingProject } from "src/ts/interfaces/projectOutToBid";
import contractorRatingService from "src/services/contractorRatingService";
const Card = styled(MuiCard)(spacing);
const Root = styled.div`
  max-width: 70%;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  display: flex;
  min-height: 100%;
  flex-direction: column;
`;

function RatingContractorNotification() {
  const { theme } = useTheme();
  const Divider = styled(MuiDivider)(spacing);
  const navigate = useNavigate();
  let { code } = useParams<{ code: string }>();
  const [confirmModal, setConfirmModal] = useState(false);
  const {
    execute: executeLoad,
    data,
    setData,
  } = useAsyncQuery<IRatingProject>(contractorRatingService.getByCode);

  const { execute, isSubmitting } = useAsyncMutation(
    contractorRatingService.updateByCode,
    {
      successfulMessage: `Contractor rating was created`,
      onSuccess: () => {
        setConfirmModal(false);
        navigate("/auth/sign-in");
      },
    }
  );

  useEffect(() => {
    const getData = async () => {
      await executeLoad(code);
    };

    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleConfirm = async () => {
    await execute(data);
  };

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setData({
      ...data,
      [name]: value,
    });
  };

  return (
    <Root>
      <CssBaseline />
      <GlobalStyle />
      <Grid container spacing={2} pt={10}>
        <Grid item xs={1}></Grid>
        <Grid item xs={10}>
          <Card mb={1}>
            <CardContent>
              <Helmet title="Confirm" />
              <Link href="https://www.auditmaster.pro/" target="_blank">
                <Box
                  component="img"
                  alt="Logo AMP"
                  src={
                    THEMES.DARK === theme
                      ? "/static/img/AMPWebLogoVectors.svg"
                      : "/static/img/AMPWebLogoVectorsLight.svg"
                  }
                  sx={{
                    marginLeft: "35%",
                    marginRight: "35%",
                    width: "30%",
                    marginBottom: "20px",
                  }}
                />
              </Link>
              <Divider mb={4} />
              <Typography variant="h3" textAlign={"center"}>
                Contractor's rating
              </Typography>
              {data?.saved && (
                <Grid container>
                  <Grid item xs={12}>
                    <Typography variant="h6" textAlign={"center"} pt={3}>
                      Contractor rating was already created
                    </Typography>
                  </Grid>
                  <Grid item xs={12} textAlign={"center"} p={5}>
                    <FormCancelButton
                      onClick={() => {
                        navigate("/auth/sign-in");
                      }}
                      isSubmitting={false}
                      text="Go to AMP"
                    ></FormCancelButton>
                  </Grid>
                </Grid>
              )}
              {!data?.saved && (
                <>
                  <Grid container>
                    <Grid item xs={3}>
                      <Stack textAlign={"right"} pr={1}>
                        <Typography ml={3}>
                          <b>Contractor's Name: </b>
                        </Typography>
                      </Stack>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography>{data?.companyName}</Typography>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={3}>
                      <Stack textAlign={"right"} pr={1}>
                        <Typography>
                          <b>Contractor's Address:</b>{" "}
                        </Typography>
                      </Stack>
                    </Grid>
                    <Grid item xs={9}>
                      <Typography> {data?.address}</Typography>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={3}>
                      <Stack textAlign={"right"} pr={1}>
                        <Typography ml={11}>
                          <b>Project Name:</b>{" "}
                        </Typography>
                      </Stack>
                    </Grid>
                    <Grid item xs={9}>
                      <Typography> {data?.projectName}</Typography>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={3}>
                      <Stack textAlign={"right"} pr={1}>
                        <Typography ml={20}>
                          <b>Building:</b>{" "}
                        </Typography>
                      </Stack>
                    </Grid>
                    <Grid item xs={9}>
                      <Typography>
                        {`${data?.buildingName} - ${data?.buildingAddress}`}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Divider mb={4} mt={4} />

                  <Stack direction="row" spacing={10}>
                    <Typography>
                      1. How satisfied are you with the knowledge of the
                      project's contractor and field technician(s)?
                    </Typography>
                  </Stack>
                  <Stack
                    direction="row"
                    justifyContent="start"
                    alignItems="center"
                  >
                    <FormRadioGroup
                      name="question1Rating"
                      label=""
                      value={data?.question1Rating}
                      onChange={handleInputChange}
                      items={ratingOptionReversed}
                    />
                  </Stack>
                  <Divider my={2} />
                  <Stack direction="row" spacing={10}>
                    <Typography>
                      2. How satisfied are you with the communication and
                      professionalism of the project's contractor and
                      technician(s)?
                    </Typography>
                  </Stack>
                  <Stack
                    direction="row"
                    justifyContent="start"
                    alignItems="center"
                  >
                    <FormRadioGroup
                      name="question2Rating"
                      label=""
                      value={data?.question2Rating}
                      onChange={handleInputChange}
                      items={ratingOptionReversed}
                    />
                  </Stack>
                  <Divider my={2} />
                  <Stack direction="row" spacing={10}>
                    <Typography>
                      3. How satisfied are you with the contractor's and
                      technician(s)' ability to address and resolve problems on
                      this project?
                    </Typography>
                  </Stack>
                  <Stack
                    direction="row"
                    justifyContent="start"
                    alignItems="center"
                  >
                    <FormRadioGroup
                      name="question3Rating"
                      label=""
                      value={data?.question3Rating}
                      onChange={handleInputChange}
                      items={ratingOptionReversed}
                    />
                  </Stack>
                  <Divider my={2} />
                  <Stack direction="row" spacing={10}>
                    <Typography>
                      4. How satisfied are you with the contractor’s
                      thoroughness in fulfilling and meeting the
                      project/contract requirements for measurement and
                      verification (M&V), along with testing and data
                      collection?
                    </Typography>
                  </Stack>
                  <Stack
                    direction="row"
                    justifyContent="start"
                    alignItems="center"
                  >
                    <FormRadioGroup
                      name="question4Rating"
                      label=""
                      value={data?.question4Rating}
                      onChange={handleInputChange}
                      items={ratingOptionReversed}
                    />
                  </Stack>
                  <Divider my={2} />
                  <Stack direction="row" spacing={10}>
                    <Typography>
                      5. Overall, would you use or recommend this contractor to
                      someone else?
                    </Typography>
                  </Stack>
                  <Stack
                    direction="row"
                    justifyContent="start"
                    alignItems="center"
                  >
                    <FormRadioGroup
                      name="question5Rating"
                      label=""
                      value={data?.question5Rating}
                      onChange={handleInputChange}
                      items={lastQuestionRating}
                    />
                  </Stack>
                  <Divider my={2} />
                  <Stack>
                    <FormTextArea
                      name="comments"
                      label="Comments"
                      value={data?.comments}
                      placeholder="Comments"
                      onChange={handleInputChange}
                    />
                  </Stack>
                  <Divider my={2} />
                  <Stack>
                    <Grid textAlign={"center"}>
                      <FormSaveButton
                        onClick={() => {
                          setConfirmModal(true);
                        }}
                        isSubmitting={false}
                        disabled={
                          !data?.question1Rating ||
                          !data?.question2Rating ||
                          !data?.question3Rating ||
                          !data?.question4Rating ||
                          !data?.question5Rating
                        }
                      ></FormSaveButton>
                    </Grid>
                  </Stack>
                </>
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <DialogMessagePopup
        title={"Please confirm"}
        text={
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Typography>
                <Typography pt={2}>
                  Are you certain you want to submit the results?
                </Typography>
              </Typography>
            </Grid>
          </Grid>
        }
        showPopup={confirmModal}
        setShowPopup={setConfirmModal}
        isSubmitting={isSubmitting}
        onSave={handleConfirm}
      ></DialogMessagePopup>
    </Root>
  );
}
export default RatingContractorNotification;
