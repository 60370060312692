import { Grid, Stack } from "@mui/material";
import Popup from "src/components/Popup";
import { FormButton, FormCancelButton } from "src/components/formControls";
import LocalEnhancedTable from "src/components/localTable/LocalTable";
import { IOutletReportComponent } from "src/ts/interfaces";
import { ColumnType } from "src/types/enhancedTable";

interface Props {
  isDialogOpen: boolean;
  setIsDialogOpen: (val: boolean) => void;
  onConfirm: () => void;
  components: IOutletReportComponent[];
}

const ActualVelocityAlertPopup = (props: Props) => {
  const { isDialogOpen, setIsDialogOpen, onConfirm, components } = props;
  const closeHandler = () => {
    setIsDialogOpen(false);
  };

  const columns: ColumnType[] = [
    {
      id: "boxAddress",
      label: "Box Identifier",
      type: "string",
      sort: true,
    },
    {
      id: "actualInletSqtFt",
      label: "Actual ASDF",
      type: "string",
      sort: true,
    },
    {
      id: "actualInletVelocity",
      label: "Actual Velocity",
      type: "string",
      sort: true,
    },
  ];
  const acceptHandler = () => {
    onConfirm();
  };

  return (
    <>
      <Popup
        openPopup={isDialogOpen}
        setOpenPopup={setIsDialogOpen}
        title={`Warning`}
        onClose={closeHandler}
      >
        <Grid container spacing={5}>
          <Grid item xs={12}>
            Medium Pressure/Duct Velocity is considered excessive for the
            following terminal devices, discuss with the design engineer if this
            is undersized for this installation.
          </Grid>
          <Grid item xs={12}>
            <LocalEnhancedTable<IOutletReportComponent>
              refreshGrid={true}
              columns={columns}
              data={components}
              showSkeleton={false}
              defaultRowPerPage={10}
              rowsPerPageOptions={[10]}
            />
          </Grid>
          <Grid item xs={12}>
            Are you certain you want to move forward?
          </Grid>
        </Grid>
        <>
          <Stack spacing={2} direction="row">
            <FormCancelButton onClick={closeHandler} isSubmitting={false} />
            <FormButton
              text="Continue"
              onClick={acceptHandler}
              isSubmitting={false}
              size="small"
            />
          </Stack>
        </>
      </Popup>
    </>
  );
};

export default ActualVelocityAlertPopup;
