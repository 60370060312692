import axios from "src/utils/axios";
import { ICommissioningDTO } from "src/ts/interfaces";

const getById = (id: number) => {
  return axios.get<ICommissioningDTO>(`Commissioning/report/${id}`);
};

const save = (id: number, commissioning: ICommissioningDTO) => {
  return axios.post<ICommissioningDTO>(
    `Commissioning?reportId=${id}`,
    commissioning
  );
};

const update = (id: number, commissioning: ICommissioningDTO) => {
  return axios.put<ICommissioningDTO>(`Commissioning/${id}`, commissioning);
};

const commissioningService = {
  getById,
  save,
  update,
};

export default commissioningService;
