import Rating from "@mui/material/Rating";

interface raitingStartsProps {
  value: number | undefined;
  readOnly?: boolean | true;
  onChange?: (val: number) => void;
  precision?: number;
}

export default function FormRaitingStarts(props: raitingStartsProps) {
  return (
    <>
      <Rating
        name="half-rating-read"
        defaultValue={props.value}
        precision={props.precision ? props.precision : 0.5}
        readOnly={props.readOnly ?? true}
        onChange={(event, newValue) => {
          if (props.onChange) props.onChange(newValue ?? 0);
        }}
      />
    </>
  );
}
