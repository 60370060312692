import { Grid } from "@mui/material";
import {
  FormCheckSwitch,
  FormDatePicker,
  FormNumeric2,
} from "src/components/formControls";
import { Mode } from "src/ts/types";
import useChillerStudy from "../hooks/useChillerStudy";
import DialogMessagePopup from "src/components/DialogMessagePopup";
import { useEffect, useState } from "react";
import { ISystemOfMeasurement } from "src/ts/interfaces";
import moment from "moment";

interface Props {
  mode: Mode;
  disableStudy: boolean;
  isLoading: boolean;
  systemOfMeasurement: ISystemOfMeasurement;
  parentReportId: number | null;
}

const EvaporatorForm = ({
  mode,
  disableStudy,
  isLoading,
  systemOfMeasurement,
  parentReportId,
}: Props) => {
  const {
    evaporatorValues: values,
    handleEvaporatorInputChange: handleInputChange,
    setEvaporatorValues,
  } = useChillerStudy();

  const [showRegrigerantPressuresModal, setShowRegrigerantPressuresModal] =
    useState(false);

  const handleNeedRegrigerantPressuresChange = (e: any) => {
    const value = e.target.value;
    if (value) {
      setShowRegrigerantPressuresModal(true);
    } else {
      handleInputChange(e);
    }
  };

  const onAccept = () => {
    setShowRegrigerantPressuresModal(false);
    setEvaporatorValues({ ...values, needRegrigerantPressures: true });
  };

  useEffect(() => {
    if (
      values.actualDateTestPerformed === null ||
      values.actualDateTestPerformed === undefined
    ) {
      let newvalues = { ...values };
      newvalues.actualDateTestPerformed = moment().format("MM/DD/YYYY");
      setEvaporatorValues(newvalues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  return (
    <>
      <Grid container spacing={5}>
        {parentReportId === null && (
          <>
            <Grid item xs={4}>
              <FormDatePicker
                name="actualDateTestPerformed"
                label={`Actual Date Of When The Test Was Performed`}
                value={values.actualDateTestPerformed}
                onChange={handleInputChange}
                disable={disableStudy}
                showSkeleton={isLoading}
                disableFuture={true}
                fullWidth
              />
            </Grid>
            <Grid item xs={8}></Grid>
          </>
        )}

        <Grid item xs={4}>
          <FormNumeric2
            name="enteringDeliveredTotalSystemGPMActual"
            label={`Chilled Water Delivery ${systemOfMeasurement.get("gpm")}`}
            value={values.enteringDeliveredTotalSystemGPMActual}
            onChange={handleInputChange}
            showSkeleton={isLoading}
            mode={mode}
            disabled={disableStudy}
            decimalScale={2}
            thousandSeparator={true}
            maxValue={1000000000}
            fixedDecimalScale={true}
          />
        </Grid>
        <Grid item xs={4}>
          <FormNumeric2
            name="actualChillerCoolerRefrigerantSumpTemp"
            label={`Chiller/Cooler Refrigerant Sump Temp ${systemOfMeasurement.get(
              "temperature"
            )}`}
            value={values.actualChillerCoolerRefrigerantSumpTemp}
            onChange={handleInputChange}
            showSkeleton={isLoading}
            mode={mode}
            disabled={disableStudy}
            decimalScale={2}
            thousandSeparator={true}
            maxValue={1000000000}
            fixedDecimalScale={true}
          />
        </Grid>
        <Grid item xs={4}>
          <FormCheckSwitch
            label="Need Chiller Refrigerant Pressures"
            name="needRegrigerantPressures"
            value={values.needRegrigerantPressures}
            onChange={handleNeedRegrigerantPressuresChange}
            mode={mode}
            showSkeleton={isLoading}
          />
        </Grid>
      </Grid>
      <DialogMessagePopup
        title={"Recording Pressures Agreement"}
        text={
          "I acknowledge I will follow all EPA, Federal, State, and Local codes when recording pressures internal to the DX System."
        }
        showPopup={showRegrigerantPressuresModal}
        setShowPopup={setShowRegrigerantPressuresModal}
        onSave={() => onAccept()}
        onCancel={() => {
          setShowRegrigerantPressuresModal(false);
        }}
        acceptTextButton="I acknowledge"
        isSubmitting={false}
        hideCancel={true}
        disableClickOutside={true}
      />
    </>
  );
};

export default EvaporatorForm;
