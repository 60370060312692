import React, { useEffect, useState } from "react";
import { Grid, Stack } from "@mui/material";
import { Form } from "src/hooks/useForm";
import {
  FormCancelButton,
  FormSaveButton,
  FormSelect,
  FormText,
  FormDatePicker,
  FormTextArea,
  FormCheckSwitch,
} from "src/components/formControls";
import { IKeyValue } from "src/ts/interfaces";
import { CouponService } from "src/services";
import Popup from "src/components/Popup";
import useAsyncMutation from "src/hooks/useAsyncMutation";
import { usePermissions } from "src/hooks";
import { Permission as PermissionTypes } from "src/ts/enums";

interface CouponFormProps {
  isDialogOpen: boolean;
  setIsDialogOpen: (value: boolean) => void;
  validate: (fieldValues?: any) => boolean | undefined;
  values: any;
  resetForm: () => void;
  onInputChange: (e: any) => void;
  errors: Record<string, string>;
  onRefreshGrid: () => void;
  setCouponType: (parms: IKeyValue<number, string>[]) => void;
  setValues: React.Dispatch<any>;
}

const CouponForm = (props: CouponFormProps) => {
  const {
    validate,
    values,
    resetForm,
    onInputChange,
    errors,
    onRefreshGrid,
    setCouponType,
    setValues,
  } = props;

  const { execute: addCoupon } = useAsyncMutation(CouponService.add, {
    successfulMessage: "The coupon was saved successful",
  });
  const { execute: updateCoupon, isSubmitting: updateSubmitting } =
    useAsyncMutation(CouponService.update, {
      successfulMessage: "The coupon was saved successful",
    });

  const { execute: getRadom } = useAsyncMutation(CouponService.getRandom, {
    hideSuccessfulMessage: true,
    onSuccess: (result) => {
      let newValues = { ...values };
      newValues.code = result;
      newValues.isOneTimeCoupon = !newValues.isOneTimeCoupon;
      const aYearFromNow = new Date();
      newValues.from =
        aYearFromNow.getMonth() +
        1 +
        "/" +
        aYearFromNow.getDate() +
        "/" +
        aYearFromNow.getFullYear();
      const aYearFromNowTo = new Date();
      aYearFromNowTo.setFullYear(aYearFromNowTo.getFullYear() + 1);
      newValues.to =
        aYearFromNowTo.getMonth() +
        1 +
        "/" +
        aYearFromNowTo.getDate() +
        "/" +
        aYearFromNowTo.getFullYear();
      newValues.displayInWebPage = false;
      setValues({
        ...newValues,
      });
    },
  });

  const [couponTypeOptions, setCouponTypeOptions] = useState<
    IKeyValue<number, string>[]
  >([]);
  const { fullAccess, readOnly } = usePermissions(PermissionTypes.Coupons);

  useEffect(() => {
    const fillCouponTypeOptions = async () => {
      const response = await CouponService.getCouponTypesKeyValues();
      setCouponTypeOptions(response);
      setCouponType(response);
    };
    fillCouponTypeOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const row = 12;

  const valueOptions: IKeyValue<number, string>[] = [
    { value: "5%", key: 5 },
    { value: "10%", key: 10 },
    { value: "15%", key: 15 },
    { value: "20%", key: 20 },
    { value: "25%", key: 25 },
    { value: "30%", key: 30 },
    { value: "35%", key: 35 },
    { value: "40%", key: 40 },
    { value: "45%", key: 45 },
    { value: "50%", key: 50 },
    { value: "99.99%", key: 99.99 },
  ];

  const handleSave = async () => {
    if (!validate()) return;

    if (values.id === 0) {
      await addCoupon(values);
      props.setIsDialogOpen(false);
      resetForm();
      onRefreshGrid();
    } else {
      await updateCoupon(values.id, values);
      props.setIsDialogOpen(false);
      resetForm();
      onRefreshGrid();
    }
  };

  const handleOneTimeButton = (e: any) => {
    let newValues = { ...values };
    if (!newValues.isOneTimeCoupon) getRadom();
    else {
      newValues.isOneTimeCoupon = !newValues.isOneTimeCoupon;
      newValues.code = !newValues.isOneTimeCoupon ? "" : newValues.code;
      newValues.from = null;
      newValues.to = null;
      setValues({
        ...newValues,
      });
    }
  };

  return (
    <>
      <Popup
        openPopup={props.isDialogOpen}
        setOpenPopup={props.setIsDialogOpen}
        title={fullAccess ? "New Coupon" : "Coupon"}
        onClose={() => props.setIsDialogOpen(false)}
      >
        <Form>
          <Grid container spacing={5}>
            <Grid item xs={row}>
              <FormText
                name="title"
                label="Title"
                value={values.title}
                onChange={onInputChange}
                error={errors.title}
                disabled={readOnly}
              />
            </Grid>
            <Grid item xs={row}>
              <FormSelect
                name="typeId"
                label="Type"
                value={values.typeId}
                onChange={onInputChange}
                options={couponTypeOptions}
                error={errors.typeId}
                defaultValue={{ key: -1, value: "Select one" }}
                disabled={readOnly}
              />
            </Grid>
            <Grid item xs={row}>
              <FormCheckSwitch
                name={"isOneTimeCoupon"}
                label={`One time coupon`}
                onChange={handleOneTimeButton}
                value={values?.isOneTimeCoupon}
                disabled={readOnly || values.id > 0}
              ></FormCheckSwitch>
            </Grid>
            <Grid item xs={row}>
              <FormText
                name="code"
                label="Code"
                value={values.code}
                onChange={onInputChange}
                error={errors.code}
                maxLength={20}
                disabled={readOnly}
              />
            </Grid>
            <Grid item xs={row}>
              <FormSelect
                name="value"
                label="Value"
                value={values.value}
                onChange={onInputChange}
                options={valueOptions}
                error={errors.value}
                defaultValue={{ key: -1, value: "Select a value" }}
                disabled={readOnly}
              />
            </Grid>

            <Grid item xs={6}>
              <FormDatePicker
                label="Valid From"
                name="from"
                disablePast={true}
                value={values.from}
                onChange={onInputChange}
                error={errors.from}
                disable={readOnly}
              />
            </Grid>
            <Grid item xs={6} textAlign={"right"}>
              <FormDatePicker
                label="Expires on"
                name="to"
                disablePast={true}
                value={values.to}
                onChange={onInputChange}
                error={errors.to}
                disable={readOnly}
              />
            </Grid>
            <Grid item xs={row}>
              <FormCheckSwitch
                name={"isActive"}
                label={`Activate coupon`}
                onChange={onInputChange}
                value={values?.isActive}
                disabled={readOnly}
              ></FormCheckSwitch>
            </Grid>

            <Grid item xs={row}>
              <FormCheckSwitch
                name={"displayInWebPage"}
                label={`Appear in web page`}
                onChange={onInputChange}
                value={
                  values.value === 99.999 ? false : values.displayInWebPage
                }
                disabled={
                  values.value === 99.999 || readOnly || values.isOneTimeCoupon
                    ? true
                    : false
                }
              ></FormCheckSwitch>
            </Grid>
            <Grid item xs={row}>
              <FormTextArea
                name="appliesTo"
                label="Applies To"
                value={values.appliesTo}
                onChange={onInputChange}
                error={errors.appliesTo}
                placeholder=""
                disabled={readOnly}
              />
            </Grid>
          </Grid>
        </Form>
        <>
          <Stack direction="row" spacing={2}>
            <FormCancelButton
              onClick={() => props.setIsDialogOpen(false)}
              isSubmitting={updateSubmitting}
            />
            {fullAccess && (
              <FormSaveButton
                onClick={handleSave}
                isSubmitting={updateSubmitting}
              />
            )}
          </Stack>
        </>
      </Popup>
    </>
  );
};

export default CouponForm;
