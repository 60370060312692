import { DuplicateDeleteStudy } from "src/ts/interfaces";
import axios from "../utils/axios";

const getMassiveProjectReportPDFs = (
  projectId: Number,
  selectedStudies: DuplicateDeleteStudy[],
  onProgressDownload: (progress: number) => void
) => {
  return axios.put(
    `PdfExport/MassiveProjectReportPDFs?projectId=${projectId}`,
    selectedStudies,
    {
      responseType: "blob",
      onDownloadProgress: (progressEvent) => {
        let percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        ); // you can use this to show user percentage of file downloaded
        onProgressDownload(percentCompleted);
      },
      timeout: 60000,
    }
  );
};

const getFileProject = (
  projectId: number,
  excecutiveSummary: boolean,
  onProgressDownload: (progress: number) => void
) => {
  return axios.get(
    `PdfExport/GetFileProject?projectId=${projectId}&excecutiveSummary=${excecutiveSummary}`,
    {
      // responseType: "blob",
      onDownloadProgress: (progressEvent) => {
        let percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        ); // you can use this to show user percentage of file downloaded
        onProgressDownload(percentCompleted);
      },
    }
  );
};

const downloadStudy = (
  reportId: number,
  onProgressDownload: (progress: number) => void
) => {
  return axios.get(`PdfExport/GetFileStudy?projectReportId=${reportId}`, {
    // responseType: "blob",
    onDownloadProgress: (progressEvent) => {
      let percentCompleted = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total
      ); // you can use this to show user percentage of file downloaded
      onProgressDownload(percentCompleted);
    },
  });
};

const generatePdfStudyAndSubstudyWithConfirmation = (
  projectId: number,
  reportId: number,
  reportTypeId: number
) => {
  return axios.post(
    `PdfExport/GeneratePdfStudyAndSubstudyWithConfirmation?projectId=${projectId}&reportId=${reportId}&reportTypeId=${reportTypeId}`
  );
};

const pdfExportService = {
  getMassiveProjectReportPDFs,
  getFileProject,
  downloadStudy,
  generatePdfStudyAndSubstudyWithConfirmation,
};
export default pdfExportService;
