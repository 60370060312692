import axios from "../utils/axios";
import {
  IHistoryDataPage,
  IHistoryFilter,
  ICouponsHistoryDataPage,
  ITechniciansHistoryDataPage,
  ITechniciansHistoryFilter,
  IProjectHistoryDataPage,
  IProjectHistoryFilter,
  IStudiesHistoryFilter,
  IStudiesHistoryDataPage,
  IUserAccessHistoryFilter,
  IUserAccessHistoryDataPage,
} from "../ts/interfaces/history";
import { getFilter } from "../utils/utils";

const getAll = async (
  filter: IHistoryFilter,
  getQuery?: (filters: Record<string, any>) => string
) => {
  const queryString = getQuery ? getQuery(filter) : getFilter(filter);

  return await axios.get<IHistoryDataPage>(
    `SystemCostsHistory/?${queryString}`
  );
};

const getAllCouponsHistory = async (
  filter: IHistoryFilter,
  getQuery?: (filters: Record<string, any>) => string
) => {
  const queryString = getQuery ? getQuery(filter) : getFilter(filter);

  return await axios.get<ICouponsHistoryDataPage>(
    `CouponsWalletMovement?${queryString}`
  );
};

const getTechniciansHistory = async (
  filter: ITechniciansHistoryFilter,
  getQuery?: (filters: Record<string, any>) => string
) => {
  const queryString = getQuery ? getQuery(filter) : getFilter(filter);

  return await axios.get<ITechniciansHistoryDataPage>(
    `TechniciansHistory?${queryString}`
  );
};

const getProjectHistory = async (
  filter: IProjectHistoryFilter,
  getQuery?: (filters: Record<string, any>) => string
) => {
  const queryString = getQuery ? getQuery(filter) : getFilter(filter);

  return await axios.get<IProjectHistoryDataPage>(
    `ProjectHistory?${queryString}`
  );
};

const getStudiesHistory = async (
  filter: IStudiesHistoryFilter,
  getQuery?: (filters: Record<string, any>) => string
) => {
  const queryString = getQuery ? getQuery(filter) : getFilter(filter);

  return await axios.get<IStudiesHistoryDataPage>(
    `StudiesHistory?${queryString}`
  );
};

const getUserAccessHistory = async (
  filter: IUserAccessHistoryFilter,
  getQuery?: (filters: Record<string, any>) => string
) => {
  const queryString = getQuery ? getQuery(filter) : getFilter(filter);

  return await axios.get<IUserAccessHistoryDataPage>(
    `UsersAccessHistory?${queryString}`
  );
};

const HistoryService = {
  getAll,
  getAllCouponsHistory,
  getTechniciansHistory,
  getProjectHistory,
  getStudiesHistory,
  getUserAccessHistory,
};

export default HistoryService;
