import { useForm } from "src/hooks";
import { Grid, Typography, Stack } from "@mui/material";
import { Form } from "src/hooks/useForm";
import { FormAcceptButton, FormCancelButton, FormText } from "../formControls";
import Popup from "../Popup";

interface MatchWordConfirmModalProps {
  matchWord: string;
  isDialogOpen: boolean;
  setIsDialogOpen: (val: boolean) => void;
  text: string;
  otherText?: string;
  onConfirm: () => void;
  isSubmitting?: boolean;
  disableClickOutside?: boolean;
  withoutOtherText?: boolean;
}

interface IWord {
  word: string;
}

const initialValues: IWord = {
  word: "",
};

const MatchWordConfirmModal = (props: MatchWordConfirmModalProps) => {
  const {
    isDialogOpen,
    matchWord,
    setIsDialogOpen,
    text,
    onConfirm,
    isSubmitting,
    otherText,
    withoutOtherText,
  } = props;

  const validate = (fieldValues = values) => {
    let temp: Record<string, string> = { ...errors };

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, errors, setErrors, handleInputChange, resetForm } = useForm(
    initialValues,
    false,
    validate
  );

  const confirmHandler = async () => {
    if (!validate()) return;

    onConfirm();
    resetForm();
    setIsDialogOpen(false);
  };

  const closeHandler = () => {
    setIsDialogOpen(false);
    resetForm();
  };

  const row = 12;
  return (
    <>
      <Popup
        openPopup={isDialogOpen}
        setOpenPopup={setIsDialogOpen}
        title={`Information`}
        onClose={closeHandler}
        disableClickOutside={props.disableClickOutside}
      >
        <Form>
          <Grid container spacing={5}>
            <Grid item xs={row}>
              <Typography variant="subtitle2" gutterBottom>
                {text}
              </Typography>
              {!withoutOtherText ? (
                otherText ? (
                  <Typography variant="body2" gutterBottom>
                    {otherText}
                  </Typography>
                ) : (
                  <Typography variant="body2" gutterBottom>
                    With this action, you will not be able to retrieve
                    previously entered information.
                  </Typography>
                )
              ) : null}
            </Grid>
            <Grid item xs={row}>
              <FormText
                name="word"
                label={`Type "${matchWord}" to confirm`}
                value={values.word}
                onChange={handleInputChange}
                error={errors.word}
              />
            </Grid>
          </Grid>
        </Form>
        <>
          <Stack spacing={2} direction="row">
            <FormCancelButton onClick={closeHandler} isSubmitting={false} />
            <FormAcceptButton
              text={"Yes, Delete"}
              disabled={values.word !== matchWord}
              onClick={confirmHandler}
              isSubmitting={isSubmitting ?? false}
              color="primary"
            />
          </Stack>
        </>
      </Popup>
    </>
  );
};

export default MatchWordConfirmModal;
