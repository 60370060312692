import { ISourceSoundAverage } from "src/ts/interfaces/catalogs";
import axios from "src/utils/axios";

const getByEnergySourceSubtractedId = (energySourceSubtractedId: number) => {
  return axios.get<ISourceSoundAverage[]>(
    `SourceSoundAverage/Report?energySourceSubtractedId=${energySourceSubtractedId}`
  );
};

const add = (sourceSoundCombine: ISourceSoundAverage) => {
  return axios.post<ISourceSoundAverage>(
    "SourceSoundAverage",
    sourceSoundCombine
  );
};

const update = (id: number, sourceSoundCombine: ISourceSoundAverage) => {
  return axios.put<ISourceSoundAverage>(
    `SourceSoundAverage/${id}`,
    sourceSoundCombine
  );
};

const updateAll = (sourceSoundCombine: ISourceSoundAverage[]) => {
  return axios.put<ISourceSoundAverage>(
    "SourceSoundAverage",
    sourceSoundCombine
  );
};

const remove = (energySourceSubtractedId: number) => {
  return axios.delete(`SourceSoundAverage/${energySourceSubtractedId}`);
};

const sourceSoundAverageService = {
  getByEnergySourceSubtractedId,
  add,
  update,
  updateAll,
  remove,
};

export default sourceSoundAverageService;
