import axios from "src/utils/axios";
import {
  IGeneralMatrixItems,
  IGeneralMatrixXY,
} from "src/ts/interfaces/study/generalMatrix";

const getByGeneralId = (id: number) => {
  return axios.get<IGeneralMatrixItems[]>(
    `GeneralMatrixItems?generalMatrixId=${id}`
  );
};

const saveItems = (items: IGeneralMatrixItems[]) => {
  return axios.put(`GeneralMatrixItems`, items);
};

const deleteMatrixItem = (id: number) => {
  return axios.delete(`GeneralMatrixItems/${id}`);
};

const postGeneralMatrixXY = (x: number, y: number, data: IGeneralMatrixXY) => {
  return axios.post(`GeneralMatrixItems?x=${x}&y=${y}`, data);
};

const generalMatrixItemsService = {
  getByGeneralId,
  saveItems,
  deleteMatrixItem,
  postGeneralMatrixXY,
};

export default generalMatrixItemsService;
