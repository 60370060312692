import { ROLES } from "src/constants";
import {
  DuplicateDeleteStudy,
  IProjectBuilding,
  IProjectCovid,
  IProjectInfectiousComplianceControl,
  IProjectReporDisplayt,
} from "src/ts/interfaces";
import {
  IProjectSummary,
  ProjectDTO,
  ProjectWithBogus,
} from "src/ts/interfaces/project/projectDto";
import { IArchitectProjects } from "src/ts/interfaces/projectArchitect";
import axios from "src/utils/axios";

const create = (companyId: number, project: ProjectDTO) => {
  return axios.post<ProjectDTO>(
    `Project?companyId=${companyId}&newRoute=true`,
    project
  );
};

const saveTolerances = (projectId: number, project: ProjectDTO) => {
  return axios.put(`project/tolerance?id=${projectId}`, project);
};

const changeStatus = (projectId: number, comment: string) => {
  return axios.put(`Project/ChangeStatus?id=${projectId}&comment=${comment}`);
};

const deleteStudies = (studies: DuplicateDeleteStudy[]) => {
  return axios.put("Project/deleteStudies", studies);
};

const getProjectInfectiousControlById = (projectId: number) => {
  return axios.get<IProjectInfectiousComplianceControl>(
    `Project/InfectiousControl/${projectId}`
  );
};

const getProjectBuildingDelete = async (id: Number) => {
  return axios.get<IProjectBuilding[]>(`Project/Building?buildingId=${id}`);
};

const getMyActiveProjects = async () => {
  return axios.get<number>(`Project/MyActiveProjects`);
};

const getZipCode = async (projectId: number) => {
  return axios.get<string>(`project/zip/${projectId}`);
};

const getArchitectSummary = async () => {
  return axios.get<IProjectSummary>(`project/Architect/Summary`);
};

const getContestProject = (projectId: number) => {
  return axios.get<ProjectDTO>(
    `Project/GetContestProject?projectId=${projectId}`
  );
};

const byReportType = (reportId: number, reportTypeCode: string) => {
  return axios.get<ProjectDTO>(
    `Project/ByReportType?reportId=${reportId}&reportType=${reportTypeCode}`
  );
};

const getDataTabs = async (id: number) => {
  return axios.get<IArchitectProjects[]>(`project/Architect/${id}`);
};

const putTemplate = (projectId: number, data: ProjectDTO) => {
  return axios.put(`project/${projectId}/template`, data);
};

const putOneYearSixMonth = async (value: number, projectId: number) => {
  return axios.put(
    `Project/${projectId}/OneYearSixMonthRequired?minimumDate=${value}`
  );
};

const putContestDueDate = async (id: number, data: ProjectWithBogus) => {
  return axios.put(`project/${id}/contestDueDate`, data);
};

const putChangeStatus = (projectId: number, id: number, comment: string) => {
  return axios.put(
    `Project/${projectId}/ChangeStatus/${id}/comments/${comment}`
  );
};

const putChangeStatusNoComments = (projectId: number, id: number) => {
  return axios.put(`Project/${projectId}/ChangeStatus/${id}`);
};

const getDeleteProject = (projectId: number) => {
  return axios.get(`Project/SendEmailDelete?projectId=${projectId}`);
};

const getControlProjects = () => {
  return axios.get<IProjectCovid>(`Project/GetControlProjects`);
};

const updateInfectiousControl = (
  data: IProjectInfectiousComplianceControl,
  id: number
) => {
  return axios.put(`Project/UpdateInfectiousControl/${id}`, data);
};

const getProjectTolerancesByReportId = async (
  id: number,
  reportTypeCode: string
) => {
  return axios.get<ProjectDTO>(
    `Project/ByReportType?reportId=${id}&reportType=${reportTypeCode}`
  );
};

const disableAfterSteadyStep = (role: string) => {
  return (
    role === ROLES.Administrator ||
    role === ROLES.ProjectManager ||
    role === ROLES.CompanyOwner ||
    role === ROLES.Architect ||
    role === ROLES.SysAdmin ||
    role === ROLES.TemporalAuditor ||
    role === ROLES.TemporalEnergyAuditor
  );
};

const updateTableViewPreference = async (id: number, data: ProjectDTO) => {
  return axios.put(`project/${id}/tableView`, data);
};

const deleteRecreateStudies = (
  id: number,
  studies: IProjectReporDisplayt[]
) => {
  return axios.put(`Project/DeleteDocument?projectId=${id}`, studies);
};

const getProjectSingle = async (id: number) => {
  return axios.get<ProjectDTO>(`Project/single/${id}`);
};

const projectService = {
  disableAfterSteadyStep,
  updateInfectiousControl,
  getMyActiveProjects,
  create,
  saveTolerances,
  deleteStudies,
  getProjectInfectiousControlById,
  getProjectBuildingDelete,
  changeStatus,
  getZipCode,
  getArchitectSummary,
  getContestProject,
  byReportType,
  getDataTabs,
  putTemplate,
  putOneYearSixMonth,
  putContestDueDate,
  putChangeStatus,
  getDeleteProject,
  putChangeStatusNoComments,
  getControlProjects,
  getProjectTolerancesByReportId,
  updateTableViewPreference,
  deleteRecreateStudies,
  getProjectSingle,
};

export default projectService;
