import { Grid, LinearProgress } from "@mui/material";
import { Form } from "src/hooks/useForm";
import Popup from "../Popup";
import { Stack } from "@mui/system";

interface Props {
  progress: number;
  isDialogOpen: boolean;
  setIsDialogOpen: (val: boolean) => void;
  text: string;
}

const ProgressBarModal = (props: Props) => {
  const { isDialogOpen, progress, setIsDialogOpen, text } = props;

  const closeHandler = () => {
    setIsDialogOpen(false);
  };

  const row = 12;
  return (
    <>
      <Popup
        openPopup={isDialogOpen}
        setOpenPopup={setIsDialogOpen}
        title={text}
        onClose={closeHandler}
      >
        <Form>
          <Grid container spacing={5}>
            <Grid item xs={row} justifyContent="center">
              <Stack direction="column" justifyContent="center">
                <LinearProgress variant="determinate" value={progress} />
              </Stack>
            </Grid>
          </Grid>
        </Form>
        <></>
      </Popup>
    </>
  );
};

export default ProgressBarModal;
