import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface IFloatingBar {
  projectId: number;
  options?: number[]; // Add options as an optional property
  report?: {
    reportId: number;
    projectId: number;
    reportTypeId: number;
  };
}

const initialState: IFloatingBar = {
  projectId: 0,
  options: [],
  report: {
    reportId: 0,
    projectId: 0,
    reportTypeId: 0,
  },
};

export const floatingBarSlice = createSlice({
  name: "floatingBar",
  initialState,
  reducers: {
    setChangeProjectId: (
      state: IFloatingBar,
      action: PayloadAction<number>
    ) => {
      state.projectId = action.payload;
    },
    projectIdValue: (state: IFloatingBar, action: PayloadAction<number>) => {
      state.projectId = action.payload;
      return state;
    },
    setReport: (
      state: IFloatingBar,
      action: PayloadAction<{
        reportId: number;
        projectId: number;
        reportTypeId: number;
      }>
    ) => {
      state.report = action.payload;
    },
    report: (
      state: IFloatingBar,
      action: PayloadAction<{
        reportId: number;
        projectId: number;
        reportTypeId: number;
      }>
    ) => {
      state.report = action.payload;
      return state;
    },
    setOptionMenu: (state: IFloatingBar, action: PayloadAction<number[]>) => {
      state.options = action.payload;
    },
    optionMenu: (state: IFloatingBar, action: PayloadAction<number[]>) => {
      state.options = action.payload;
      return state;
    },
  },
});

export default floatingBarSlice;
