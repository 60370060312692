import React from "react";
import { Typography } from "@mui/material";
import { ColumnType } from "src/types/enhancedTable";
import LocalEnhancedTable from "src/components/localTable/LocalTable";
import { GridActionButton } from "src/components/gridControls";
import { ProjectCompletedDTO } from "src/ts/interfaces";
import BuildIcon from "@mui/icons-material/Build";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import styled from "@emotion/styled";
import { Grid, Card as MuiCard, CardContent } from "@mui/material";
import { spacing } from "@mui/system";
import { useForm, Form, useAuth } from "src/hooks";
import FormText from "src/components/formControls/FormText";
import { useNavigate } from "react-router-dom";

const Card = styled(MuiCard)(spacing);

const getColumns = (
  projectNavigate: (projectId: number) => void
): ColumnType[] => {
  return [
    {
      id: "isInfectiousControlProject",
      label: "Type",
      type: "custom",
      sort: true,
      callback: (row: ProjectCompletedDTO) => {
        return (
          <div>
            {row.isInfectiousControlProject ? (
              <VerifiedUserIcon />
            ) : (
              <BuildIcon />
            )}
          </div>
        );
      },
    },
    { id: "id", label: "Project Id", type: "string", sort: true },
    {
      id: "code",
      label: "Code",
      type: "custom",
      sort: true,
      callback: (row: ProjectCompletedDTO) => {
        return (
          <>
            {row.code}
            {row.companyOwnerId != null && row.companyOwnerId !== 0 && (
              <Typography variant="subtitle2">
                Assigned and Awarded Building Professional Project
              </Typography>
            )}
          </>
        );
      },
    },
    { id: "name", label: "Name", type: "string", sort: true },
    {
      id: "buildingName",
      label: "Building",
      type: "custom",
      sort: true,
      callback: (row: ProjectCompletedDTO) => (
        <>
          <Typography>{row.buildingName}</Typography>
          <Typography>{row.buildingAddress}</Typography>
        </>
      ),
    },
    {
      id: "completedDate",
      label: "Completed Date",
      type: "utcDate",
      sort: true,
      format: "MM/DD/YYYY",
    },
    {
      id: "actions",
      label: "Actions",
      type: "custom",
      sort: false,
      callback: (row: ProjectCompletedDTO) => {
        return (
          <div>
            <GridActionButton
              type="forward"
              onClick={() => projectNavigate(row.id)}
              tooltip="Go to project"
            />
          </div>
        );
      },
    },
  ];
};

const initialValues = {
  id: 0,
  name: "",
  statusId: "",
};

interface Props {
  data: ProjectCompletedDTO[];
  isLoading: boolean;
  refreshGrid: boolean;
  setRefreshGrid: (value: boolean) => void;
}

const CompleteProjectsGrid = (props: Props) => {
  const { data, isLoading, refreshGrid } = props;
  const navigate = useNavigate();
  const { user } = useAuth();
  const { values: filterValues, handleInputChange: handleFilterChange } =
    useForm(initialValues, true, []);

  const projectNavigate = (projectId: number) => {
    navigate(`/app/Project/Edit/${projectId}/${user?.companyId}`);
  };

  const columns = getColumns(projectNavigate);

  return (
    <>
      <Card mb={6}>
        <CardContent>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Typography variant="h6">
                Completed project(s) which you have been part of before
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Form>
                <Grid container>
                  <Grid item xs={12}>
                    <FormText
                      name="name"
                      label="Search"
                      value={filterValues.name}
                      onChange={handleFilterChange}
                    />
                  </Grid>
                </Grid>
              </Form>
            </Grid>
            <Grid item xs={12}>
              <LocalEnhancedTable<ProjectCompletedDTO>
                refreshGrid={refreshGrid}
                columns={columns}
                data={data}
                query={filterValues.name}
                showSkeleton={isLoading}
                defaultRowPerPage={10}
                defaultSortColumn="createdDate"
                orderColumn="desc"
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
};

export default CompleteProjectsGrid;
