import { Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";

import { FormButton } from "src/components/formControls";
import LocalEnhancedTable from "src/components/localTable/LocalTable";
import Popup from "src/components/Popup";

import { useLog } from "src/hooks";
import historicalRecordService from "src/services/historicalRecordService";
import { IHistoricalRecord } from "src/ts/interfaces/historicalRecord";
import { ColumnType } from "src/types/enhancedTable";

interface IProps {
  showPopUp: boolean;
  setShowPopUp: (val: boolean) => void;
  userId: number;
}

const getColumns = (): ColumnType[] => {
  return [
    {
      id: "id",
      label: "id",
      type: "string",
      sort: false,
      hide: true,
    },
    {
      id: "createdDate",
      label: "Date",
      type: "utcDate",
      sort: true,
      format: "MM/DD/YYYY hh:mm A",
    },
    {
      id: "type",
      label: "Action",
      type: "custom",
      sort: true,
      callback: (row: IHistoricalRecord) => {
        return <>{row.type === 1 ? "Enabled" : "Disabled"}</>;
      },
    },
    {
      id: "createdByName",
      label: "Disable/Enabled By",
      type: "string",
      sort: true,
    },
    {
      id: "comments",
      label: "Comments",
      type: "string",
      sort: true,
    },
  ];
};

const UserStatusHistory = ({ showPopUp, setShowPopUp, userId }: IProps) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showHistory, setShowHistory] = useState(false);
  const [data, setData] = useState<IHistoricalRecord[]>(null as any);

  const { log } = useLog();
  const columns = getColumns();

  const handleOnClick = () => {
    setShowPopUp(false);
  };

  useEffect(() => {
    const getData = async () => {
      try {
        setIsSubmitting(true);

        const response = await historicalRecordService.getByUserId(userId);
        setData(response.data);
        setShowHistory(response.data.length > 0);
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      } finally {
        setIsSubmitting(false);
      }
    };

    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  return (
    <Popup
      title="Status History"
      openPopup={showPopUp}
      setOpenPopup={setShowPopUp}
      onClose={handleOnClick}
      size={!showHistory ? "sm" : "md"}
    >
      <>
        {!showHistory ? (
          <>
            <br />
            <Typography textAlign={"center"}>
              No records were found in the activation/deactivation user history.
            </Typography>

            <br />
          </>
        ) : (
          <>
            <Stack direction="row" pb={4}>
              <Typography sx={{ fontWeight: "bold" }} pr={2}>
                Name:
              </Typography>
              <Typography>{data[0].updatedName}</Typography>
            </Stack>

            <LocalEnhancedTable<IHistoricalRecord>
              columns={columns}
              data={data}
              showSkeleton={false}
              defaultRowPerPage={5}
              defaultSortColumn="id"
              orderColumn="desc"
            />
          </>
        )}
      </>
      <Stack
        direction="row"
        spacing={2}
        justifyContent="flex-end"
        alignItems="flex-start"
      >
        <FormButton
          text="Close"
          onClick={handleOnClick}
          size="medium"
          variant="outlined"
          isSubmitting={isSubmitting}
        />
      </Stack>
    </Popup>
  );
};

export default UserStatusHistory;
