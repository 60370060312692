import React, { useState } from "react";

const useFormCoupon = (
  initialFValues: any,
  validateOnChange = false,
  validate: any
) => {
  const [values, setValues] = useState(initialFValues);
  const [errors, setErrors] = useState<Record<string, string>>({});

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
    if (validateOnChange && validate.length > 0) validate({ [name]: value });
  };

  const resetForm = () => {
    setValues(initialFValues);
    setErrors({});
  };

  return {
    values,
    setValues,
    errors,
    setErrors,
    handleInputChange,
    resetForm,
  };
};

export default useFormCoupon;

export function Form(props: any) {
  const { children, ...other } = props;

  return (
    <form autoComplete="off" {...other}>
      {props.children}
    </form>
  );
}
