import axios from "../utils/axios";
import {
  IPermissionUsersType,
  IPermissionUsersUpdate,
  IUserCountryAccess,
} from "../ts/interfaces";

const getByUser = async (id: number, role: string) => {
  return axios.get<IPermissionUsersType[]>(
    "PermissionUsers/GetByUserRol?userId=" + id + "&role=" + role
  );
};

const saveItem = async (element: IPermissionUsersUpdate) => {
  return axios.post<number>(`PermissionUsers`, element);
};

const getPermissionUsersByRole = (role: string) => {
  return axios.get(`PermissionUsers?role=${role}`);
};

const saveItemAccess = async (element: IUserCountryAccess) => {
  return axios.post<number>(`PermissionUsers`, element);
};

const getAdministratorsSuggestionBank = () => {
  return axios.get(`PermissionUsers/AdministratorsSuggestionBank`);
};

const permissionUsersService = {
  getByUser,
  saveItem,
  getPermissionUsersByRole,
  saveItemAccess,
  getAdministratorsSuggestionBank,
};

export default permissionUsersService;
