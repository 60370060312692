import React, { ChangeEvent, useEffect, useState } from "react";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import {
  Grid,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
  Typography,
  Card as MuiCard,
  CardContent,
  Stack,
} from "@mui/material";
import { spacing } from "@mui/system";
import { ISystemCostsCatalog } from "src/ts/interfaces";
import { ColumnType } from "src/types/enhancedTable";
import { useForm, Form, useLog } from "src/hooks";
import { FormText } from "src/components/formControls";
import LocalEnhancedTable from "src/components/localTable/LocalTable";
import useAsyncQuery from "src/hooks/useAsyncQuery";
import systemCostCatalogService from "src/services/systemCostCatalogService";
import SubscriptionCostForm from "./SubscriptionCostForm";
import PasswordConfirmModal from "src/components/modals/PasswordConfirmModal";
import {
  GridActionButton,
  GridChipStatus,
  GridUploadButton,
} from "src/components/gridControls";
import FileUtils from "src/utils/file";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const initialValues = {
  id: 0,
  name: "",
  statusId: "",
};

const SubscriptionCost = () => {
  const { log } = useLog();

  const { execute, data, isLoading } = useAsyncQuery<ISystemCostsCatalog[]>(
    systemCostCatalogService.getAll
  );
  const [refreshPage, setRefreshPage] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isPasswordDialogOpen, setIsPasswordDialogOpen] = useState(false);
  const [currentRow, setCurrentRow] = useState<ISystemCostsCatalog>();
  const { values: filterValues, handleInputChange: handleFilterChange } =
    useForm(initialValues, true, []);

  useEffect(() => {
    const getData = async () => {
      await execute();
    };

    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshPage]);

  const handleEdit = (row: ISystemCostsCatalog) => {
    setCurrentRow(row);
    setIsDialogOpen(true);
  };

  const onContinueHandler = () => {
    setIsPasswordDialogOpen(true);
  };

  const downloadDocument = async (row: ISystemCostsCatalog) => {
    if (row && row.id) {
      try {
        const response = await systemCostCatalogService.downloadFile(
          Number(row.id)
        );

        let fileName = row.name + ".png";

        FileUtils.downloadFile(response.data, fileName);
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      }
    } else {
    }
  };

  const handleFileUpload = async (
    e: ChangeEvent<HTMLInputElement>,
    id: number
  ) => {
    if (!e.target.files) {
      return;
    }
    const file = e.target.files[0];
    //startRequest("uploadfile");

    try {
      await systemCostCatalogService.uploadFile(
        {
          documentType: "",
          content: file,
          name: "",
        },
        id
      );
    } catch (error: any) {
      log.error(error?.message?.exceptionMessage ?? "Something went wrong");
    }

    //endRequest("uploadfile");
    log.success("File was upload succesfully");
    setRefreshPage(!refreshPage);
  };

  const saveOnConfirmPasswordHandler = async (val: boolean) => {
    try {
      await systemCostCatalogService.update(currentRow!);
      log.success("The item was updated successfully");
      setRefreshPage(!refreshPage);
    } catch (error: any) {
      log.error(error?.message?.exceptionMessage ?? "Something went wrong");
    }
  };

  const columns: ColumnType[] = [
    {
      id: "id",
      label: "#",
      type: "string",
      sort: true,
    },
    {
      id: "code",
      label: "Code",
      type: "string",
      sort: true,
    },
    {
      id: "name",
      label: "Name",
      type: "string",
      sort: true,
    },
    {
      id: "pricing",
      label: "Pricing",
      type: "currency",
      sort: true,
    },
    {
      id: "comments",
      label: "Comments",
      type: "string",
      sort: true,
    },
    {
      id: "action1",
      label: "Status",
      type: "custom",
      sort: false,
      callback: (row: ISystemCostsCatalog) => (
        <GridChipStatus value={row.statusId} />
      ),
    },
    {
      id: "x",
      label: "Actions",
      type: "custom",
      sort: false,
      callback: (row: ISystemCostsCatalog) => (
        <div>
          <Stack direction="row">
            <GridActionButton
              type="edit"
              onClick={() => handleEdit(row)}
              tooltip="Edit"
            />
            {row.document ? (
              <GridActionButton
                tooltip="Download"
                onClick={() => downloadDocument(row)}
                type={"download"}
              />
            ) : (
              <>
                <GridUploadButton
                  accept=".png, .jpg, .jpeg, .bmp, .gif"
                  onChange={(e: any) => handleFileUpload(e, row.id)}
                />
              </>
            )}
          </Stack>
        </div>
      ),
    },
  ];

  return (
    <>
      <Helmet title="Subscription Costs" />
      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Subscription Costs
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} to="/">
              Dashboard
            </Link>
            <Typography>Subscription Costs</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item></Grid>
      </Grid>

      <Divider my={6} />
      <Card mb={6}>
        <CardContent>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Form>
                <Grid container>
                  <Grid item xs={12}>
                    <FormText
                      name="name"
                      label="Name"
                      value={filterValues.name}
                      onChange={handleFilterChange}
                    />
                  </Grid>
                </Grid>
              </Form>
            </Grid>
            <Grid item xs={12}>
              <LocalEnhancedTable<ISystemCostsCatalog>
                refreshGrid={true}
                columns={columns}
                data={data ?? []}
                query={filterValues.name}
                showSkeleton={isLoading}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      {currentRow !== undefined && currentRow !== null ? (
        <SubscriptionCostForm
          selectedValue={currentRow}
          setSelectedValue={setCurrentRow}
          isDialogOpen={isDialogOpen}
          setIsDialogOpen={setIsDialogOpen}
          onContinue={onContinueHandler}
        />
      ) : null}
      <PasswordConfirmModal
        isDialogOpen={isPasswordDialogOpen}
        setIsDialogOpen={setIsPasswordDialogOpen}
        setIsValidPassword={saveOnConfirmPasswordHandler}
      />
    </>
  );
};

export default SubscriptionCost;
