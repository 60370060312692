import Popup from "src/components/Popup";
import {
  FormButton,
  FormCheckBox,
  FormDatePicker,
  FormMaskText,
  FormNumeric2,
  FormRadioGroup,
  FormText,
  FormTextArea,
} from "src/components/formControls";
import { Stack, Typography } from "@mui/material";
import { IOutofServiceReason } from "src/ts/interfaces/companyAssets";
import useFormTyped from "src/hooks/useFormTyped";
import { useEffect, useState } from "react";
import { useLog } from "src/hooks";
import assetOutOfService from "src/services/assetOutOfService";
import { Validator } from "src/ts/types";
import { OUTOFSERVICEOPTIONS } from "src/constants";

interface OutofServiceProps {
  outofServiceShowPop: boolean;
  setOutofServiceShowPop: (val: boolean) => void;
  setRefreshGrid: (val: boolean) => void;
  refreshGrid: boolean;
  assetTitle: string;
  assetId: string;
  readonly?: boolean;
  dataOutOfService?: IOutofServiceReason | undefined;
}

const initialValues: IOutofServiceReason = {
  removedFromService: false,
  antiquated: false,
  antiquatedDisposed: false,
  doesntWork: false,
  doesntWorkDisposed: false,
  broken: false,
  brokenDisposed: false,
  sold: false,
  dateEquipment: "",
  soldTo: "",
  soldFor: "",
  other: false,
  explain: "",
  explainSisposed: false,
  lost: false,
  lostBy: "",
  dateLost: "",
  locationLost: "",
  stolen: false,
  stolenDate: "",
  locationStolen: "",
  policeReport: false,
  policeStation: "",
  datePolice: "",
  policeReportNumber: "",
  policePhone: "",
  policeName: "",
  comments: "",
};

const RemoveRadioButton = (props: any) => {
  const { handleInputChange, values, readonly, errors } = props;
  return (
    <>
      {values.removedFromService && (
        <Stack direction="column" mt={2} ml={5}>
          <Typography>Reason Equipment was removed from service:</Typography>
          <FormCheckBox
            name="antiquated"
            value={values.antiquated}
            label="Antiquated. No longer a viable item, no need of use"
            onChange={handleInputChange}
            disabled={readonly}
          />
          {values.antiquated && (
            <>
              <Stack ml={5}>
                <FormCheckBox
                  name="antiquatedDisposed"
                  value={values.antiquatedDisposed}
                  label="Disposed of item."
                  onChange={handleInputChange}
                  disabled={readonly}
                />
              </Stack>
            </>
          )}
          <FormCheckBox
            name="doesntWork"
            value={values.doesntWork}
            label="Doesn’t work properly"
            onChange={handleInputChange}
            disabled={readonly}
          />
          {values.doesntWork && (
            <>
              <Stack ml={5}>
                <FormCheckBox
                  name="doesntWorkDisposed"
                  value={values.doesntWorkDisposed}
                  label="Disposed of item."
                  onChange={handleInputChange}
                  disabled={readonly}
                />
              </Stack>
            </>
          )}
          <FormCheckBox
            name="broken"
            value={values.broken}
            label="Broken and not cost effective to repair and/or recalibrate"
            onChange={handleInputChange}
            disabled={readonly}
          />
          {values.broken && (
            <>
              <Stack ml={5}>
                <FormCheckBox
                  name="brokenDisposed"
                  value={values.brokenDisposed}
                  label="Disposed of item."
                  onChange={handleInputChange}
                  disabled={readonly}
                />
              </Stack>
            </>
          )}
          <FormCheckBox
            name="sold"
            value={values.sold}
            label="Sold"
            onChange={handleInputChange}
            disabled={readonly}
          />
          {values.sold && (
            <>
              <Stack direction="column" spacing={3} ml={5}>
                <FormDatePicker
                  name="dateEquipment"
                  label="Date Equipment was sold"
                  value={values.dateEquipment}
                  onChange={handleInputChange}
                  disable={readonly}
                />
                <FormText
                  name="soldTo"
                  label="Sold to"
                  value={values.soldTo}
                  onChange={handleInputChange}
                  disabled={readonly}
                />
                <FormNumeric2
                  name="soldFor"
                  label="Sold for"
                  value={values.soldFor}
                  thousandSeparator={true}
                  prefix="$"
                  onChange={handleInputChange}
                  disabled={readonly}
                />
              </Stack>
            </>
          )}
          <FormCheckBox
            name="other"
            value={values.other}
            label="Other"
            onChange={handleInputChange}
            disabled={readonly}
          />
          {values.other && (
            <>
              <Stack direction="column" spacing={3} ml={5}>
                <FormText
                  name="explain"
                  label="Explain"
                  value={values.explain}
                  onChange={handleInputChange}
                  disabled={readonly}
                  error={errors.explain}
                />
              </Stack>
              <Stack direction="column" spacing={3} ml={8}>
                <FormCheckBox
                  name="explainSisposed"
                  value={values.explainSisposed}
                  label="Disposed of item."
                  onChange={handleInputChange}
                  disabled={readonly}
                />
              </Stack>
            </>
          )}
        </Stack>
      )}
    </>
  );
};

const LostRadioButton = (props: any) => {
  const { handleInputChange, values, readonly } = props;
  return (
    <>
      {values.lost && (
        <Stack direction="column" mt={2} ml={5} spacing={3}>
          <FormText
            name="lostBy"
            label="Equipment was lost by"
            value={values.lostBy}
            onChange={handleInputChange}
            disabled={readonly}
          />
          <FormDatePicker
            name="dateLost"
            label="Date Equipment was identified as lost"
            value={values.dateLost}
            onChange={handleInputChange}
            disable={readonly}
          />
          <FormText
            name="locationLost"
            label="Location Equipment was last at or last seen"
            value={values.locationLost}
            onChange={handleInputChange}
            disabled={readonly}
          />
        </Stack>
      )}
    </>
  );
};

const StolenRadioButton = (props: any) => {
  const { handleInputChange, values, readonly } = props;
  return (
    <>
      {values.stolen && (
        <Stack direction="column" mt={2} ml={5} spacing={3}>
          <FormDatePicker
            name="stolenDate"
            label="Date Equipment was identified stolen"
            value={values.stolenDate}
            onChange={handleInputChange}
            disable={readonly}
          />
          <FormText
            name="locationStolen"
            label="Location or project Equipment was stolen at"
            value={values.locationStolen}
            onChange={handleInputChange}
            disabled={readonly}
          />
          <FormCheckBox
            name="policeReport"
            value={values.policeReport}
            label="Police report was filed"
            onChange={handleInputChange}
            disabled={readonly}
          />

          {values.policeReport && (
            <>
              <Stack direction="column" mt={3} ml={5} spacing={3}>
                <FormText
                  name="policeStation"
                  value={values.policeStation}
                  label="Police station where report was filed at."
                  onChange={handleInputChange}
                  disabled={readonly}
                />
                <FormDatePicker
                  name="datePolice"
                  label="Date of police report filing"
                  value={values.datePolice}
                  onChange={handleInputChange}
                  disable={readonly}
                />
                <FormText
                  name="policeReportNumber"
                  value={values.policeReportNumber}
                  label="Police report file number."
                  onChange={handleInputChange}
                  disabled={readonly}
                />
                <FormMaskText
                  name="policePhone"
                  mask="(999) 999 - 9999"
                  label="Police station phone number"
                  onChange={handleInputChange}
                  value={values.policePhone}
                  disabled={readonly}
                />
                <FormText
                  name="policeName"
                  label="Police detectives name handling case:"
                  value={values.policeName}
                  onChange={handleInputChange}
                  disabled={readonly}
                />
              </Stack>
            </>
          )}
        </Stack>
      )}
    </>
  );
};

export default function OutofServicePopUp({
  outofServiceShowPop,
  setOutofServiceShowPop,
  assetTitle,
  assetId,
  setRefreshGrid,
  refreshGrid,
  readonly,
  dataOutOfService,
}: OutofServiceProps) {
  const [radioSelected, setRadioSelected] = useState(0);

  const { log } = useLog();

  const validate: any = (fieldValues: IOutofServiceReason = values) => {
    let temp: Record<string, string> = { ...errors };

    temp.explain = new Validator(fieldValues, "explain")
      .validateIf(fieldValues.other)
      .isRequired("Please type an explain.")
      .maxLength(500, "Only 500 characters are allowed")
      .toString();

    temp.comments = new Validator(fieldValues, "comments")
      .isRequired("Please type a comment.")
      .maxLength(500, "Only 500 characters are allowed")
      .toString();

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, handleInputChange, setErrors, errors } =
    useFormTyped<IOutofServiceReason>(
      dataOutOfService ? dataOutOfService : initialValues,
      false,
      validate
    );

  let removedFromServices = [
    {
      key: 1,
      value: "Removed from service by office/shop administration.",
      name: "removedFromService",
      children: () => (
        <RemoveRadioButton
          handleInputChange={handleInputChange}
          values={values}
          readonly={readonly}
          errors={errors}
        />
      ),
    },
    {
      key: 2,
      value: "Lost.",
      name: "lost",
      children: () => (
        <LostRadioButton
          handleInputChange={handleInputChange}
          values={values}
          readonly={readonly}
        />
      ),
    },
    {
      key: 3,
      value: "Stolen.",
      name: "stolen",
      children: () => (
        <StolenRadioButton
          handleInputChange={handleInputChange}
          values={values}
          readonly={readonly}
        />
      ),
    },
  ];

  useEffect(() => {
    const dataLoad = () => {
      if (dataOutOfService) {
        dataOutOfService.removedFromService
          ? setRadioSelected(1)
          : dataOutOfService.lost
          ? setRadioSelected(2)
          : setRadioSelected(3);
      }
    };
    dataLoad();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const changeState = () => {
      const valueFilter = removedFromServices.filter((item) => {
        return item.key === Number(radioSelected);
      });

      if (valueFilter.length > 0) {
        const name = valueFilter[0].name;

        setValues({ ...values, [name]: true });
        if (name === OUTOFSERVICEOPTIONS.REMOVEDFROMSERVICE) {
          setValues({
            ...values,
            [name]: true,
            lostBy: "",
            dateLost: "",
            locationLost: "",
          });
        } else if (name === OUTOFSERVICEOPTIONS.LOST) {
          setValues({
            ...values,
            [name]: true,
            antiquated: false,
            antiquatedDisposed: false,
            doesntWork: false,
            doesntWorkDisposed: false,
            broken: false,
            brokenDisposed: false,
            sold: false,
            dateEquipment: "",
            soldTo: "",
            soldFor: "",
            other: false,
            explain: "",
            explainSisposed: false,
          });
        } else if (name === OUTOFSERVICEOPTIONS.STOLEN) {
          setValues({
            ...values,
            [name]: true,
            antiquated: false,
            antiquatedDisposed: false,
            doesntWork: false,
            doesntWorkDisposed: false,
            broken: false,
            brokenDisposed: false,
            sold: false,
            dateEquipment: "",
            soldTo: "",
            soldFor: "",
            other: false,
            explain: "",
            explainSisposed: false,
          });
        }
      }
    };
    changeState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [radioSelected]);

  const handlerOnChangeRadio = (e: any) => {
    const { value } = e.target;
    setRadioSelected(Number(value));
    setValues({
      ...values,
      lost: false,
      removedFromService: false,
      stolen: false,
    });
  };

  const handlerAccept = async () => {
    if (!validate()) return;
    try {
      if (radioSelected === 0) {
        log.warning("At least one item should be selected");
        return;
      }
      await assetOutOfService.postAssetOutOfService({
        ...values,
        companyAssetsId: Number(assetId),
      });
      log.success("Data was updated.");
      setRefreshGrid(!refreshGrid);
      setOutofServiceShowPop(false);
    } catch (error: any) {
      log.error(error?.message?.exceptionMessage ?? "Something went wrong");
    }
  };

  return (
    <>
      <Popup
        title={`Recommended ${assetTitle} Out of service`}
        openPopup={outofServiceShowPop}
        setOpenPopup={setOutofServiceShowPop}
        onClose={() => {
          setOutofServiceShowPop(false);
        }}
        size="sm"
      >
        <>
          <Stack direction="column">
            <FormRadioGroup
              name="removedFromService"
              onChange={handlerOnChangeRadio}
              label=""
              items={removedFromServices}
              value={radioSelected}
              disabled={readonly}
            />
          </Stack>
          <Stack mt={3}>
            <FormTextArea
              label="Comments"
              name="comments"
              value={values.comments}
              onChange={handleInputChange}
              disabled={readonly}
              error={errors.comments}
            />
          </Stack>
        </>
        <Stack direction="row" spacing={3}>
          <FormButton
            size="small"
            text="Close"
            variant="outlined"
            onClick={() => {
              setOutofServiceShowPop(false);
            }}
          />

          {!readonly ? (
            <FormButton text="Accept" size="small" onClick={handlerAccept} />
          ) : (
            <></>
          )}
        </Stack>
      </Popup>
    </>
  );
}
