import {
  IGeneralApprovalProcess,
  IStudyApprovalProcessStatus,
} from "src/ts/interfaces/study/studyApprovalProcess";
import axios from "src/utils/axios";

const get = (id: number) => {
  return axios.get<IGeneralApprovalProcess>(
    `StudyApprovalProcessStatus?projectReportId=${id}`
  );
};

const save = (item: IStudyApprovalProcessStatus) => {
  return axios.post<IStudyApprovalProcessStatus>(
    "StudyApprovalProcessStatus",
    item
  );
};

const studyApprovalProcessStatusService = {
  get,
  save,
};

export default studyApprovalProcessStatusService;
