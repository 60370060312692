import { IStudyStep } from "src/ts/interfaces";

export const steps: IStudyStep[] = [
  {
    label: "Step 1",
    key: 0,
    description: "System configuration",
    url: "/studies/BoilerTest/step1/:id",
  },
  {
    label: "Step 2",
    key: 1,
    description: "Boiler Design Data",
    url: "/studies/BoilerTest/step2/:id",
  },
  {
    label: "Step 3",
    key: 2,
    description: "Boiler Nameplate Data",
    url: "/studies/BoilerTest/step3/:id",
  },
  {
    label: "Step 4",
    key: 3,
    description: "Boiler Performance Test",
    url: "/studies/BoilerTest/step4/:id",
  },
  {
    label: "Step 5",
    key: 3,
    description: "Report",
    url: "/studies/BoilerTest/step5/:id",
  },
];
