import React, { useEffect, useRef, useState } from "react";
import styled from "@emotion/styled";

import {
  Box,
  IconButton,
  List,
  Popover as MuiPopover,
  Tooltip,
  Typography,
  Chip as MuiChip,
} from "@mui/material";
import { fetchNewNotifications } from "src/redux/slices/notificationActions";
import {
  useAppDispatch,
  useAppSelector,
  useAsyncQuery,
  useAuth,
} from "src/hooks";
import { Sell } from "@mui/icons-material";
import { spacing, SpacingProps } from "@mui/system";

import { ICountAvailableCoupon } from "src/ts/interfaces";
import { convertUTCDateToLocalDate } from "src/utils/utils";
import couponsWalletMovementService from "src/services/couponsWalletMovement";
const Popover = styled(MuiPopover)`
  .MuiPaper-root {
    width: 300px;
    ${(props) => props.theme.shadows[1]};
    border: 1px solid ${(props) => props.theme.palette.divider};
  }
`;

const NotificationHeader = styled(Box)`
  text-align: center;
  border-bottom: 1px solid ${(props) => props.theme.palette.divider};
`;
interface ChipProps extends SpacingProps {
  component?: React.ElementType;
  href?: string;
  icon?: JSX.Element | null;
}
const Chip = styled(MuiChip)<ChipProps>(spacing);
function NavbarCoupons() {
  const ref = useRef(null);
  const { user } = useAuth();
  const [isOpen, setOpen] = useState(false);

  const dispatch = useAppDispatch();

  const { execute, data } = useAsyncQuery<ICountAvailableCoupon>(
    couponsWalletMovementService.CountAvailableCoupons
  );

  const couponBar = useAppSelector((state) => state.couponBar);

  useEffect(() => {
    execute();
  }, []);

  useEffect(() => {
    execute();
  }, [couponBar]);

  useEffect(() => {
    dispatch(fetchNewNotifications());
  }, [dispatch]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      {user?.hasCoupons && data?.count < 3 && user?.role === "EnergyAuditor" && (
        <Tooltip title="Coupons">
          <IconButton
            color="inherit"
            ref={ref}
            onClick={handleOpen}
            size="large"
          >
            <Sell />
          </IconButton>
        </Tooltip>
      )}

      <Popover
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
      >
        <NotificationHeader p={2}>
          <Typography variant="subtitle1" color="textPrimary">
            Coupons
          </Typography>
        </NotificationHeader>
        <React.Fragment>
          <List disablePadding>
            <>
              <Typography p={4}>
                Code:
                <Chip
                  label={`Energy${user?.userId}`}
                  color="success"
                  m={1}
                  size="small"
                />
              </Typography>
              <Typography pl={4} pb={4}>
                Expiration Date:{" "}
                {convertUTCDateToLocalDate(
                  data?.date?.toString() || ""
                )?.format("MM/DD/yyyy")}
              </Typography>
              <Typography pl={4} pb={4}>
                Available coupons for energy study completion:{" "}
                {3 - (data?.count ?? 0)}
                /3
              </Typography>
            </>
          </List>
        </React.Fragment>
      </Popover>
    </React.Fragment>
  );
}

export default NavbarCoupons;
