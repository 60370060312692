import { IMemorandum, IMemorandumExternal } from "src/ts/interfaces/memorandum";
import axios from "../utils/axios";

const create = async (item: IMemorandum) => {
  return axios.post("Memorandum?newRoute=true", item);
};

const getAll = async (projectId: number, myMemorandum: boolean) => {
  return axios.get<IMemorandum[]>(
    `Memorandums/GetAll?projectId=${projectId}&myMemorandums=${myMemorandum}`
  );
};

const ChangeStatusCurrent = async (item: IMemorandum) => {
  return axios.put("Memorandums/ChangeStatusCurrent", item);
};

const getByCode = async (code: string) => {
  return axios.get<IMemorandumExternal>(`Memorandums/GetByCode?code=${code}`);
};

const ChangeStatus = async (item: IMemorandumExternal) => {
  return axios.put("Memorandums/ChangeStatus", item);
};

const getDownloadFile = async (projectId: number, memoradumId: number) => {
  return axios.get(
    `Memorandums/DownloadFile?projectId=${projectId}&changeOrderId=${memoradumId}`
  );
};

const getPending = async (userId: number) => {
  return axios.get<IMemorandum[]>(`Memorandums/GetPending?userId=${userId}`);
};

const memorandumService = {
  create,
  getAll,
  ChangeStatusCurrent,
  getByCode,
  ChangeStatus,
  getDownloadFile,
  getPending,
};

export default memorandumService;
