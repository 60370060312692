import axios from "src/utils/axios";
import { IMotorDTO } from "src/ts/interfaces";

const getById = (id: number) => {
  return axios.get<IMotorDTO>(`Motor/${id}`);
};

const getByPumpId = (id: number) => {
  return axios.get<IMotorDTO>(`Motor/Pump/${id}`);
};

const update = (id: number, motor: IMotorDTO) => {
  return axios.put<IMotorDTO>(`Motor/${id}`, motor);
};

const motorService = {
  getById,
  getByPumpId,
  update,
};

export default motorService;
