import { Grid, Typography, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { IPaymentSummary } from "src/ts/interfaces/technician";
import { GridActionButton } from "src/components/gridControls";

interface documentsProps {
  values: IPaymentSummary;
  userId: string;
  companyId: number;
  newUser: string;
}

const Document = (props: documentsProps) => {
  const { values, companyId, newUser } = props;
  const navigate = useNavigate();

  return (
    <>
      <Grid item xs={12}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            marginLeft: "10px",
            marginTop: "15px",

            alignItems: "center",
            justifyItems: "center",
          }}
        >
          <Typography>
            The technician data was added successfully. The new request was sent
            to <b>{`${values.paymentOption === 1 ? "Cart" : "AMP"}`}</b> and
            also a notification was sent to the technician applicant to add the
            documentation required to continue with the certification process.
          </Typography>
          <br />
          <Typography>
            Please add the scanned documents (photo and identification) in the
            Technicians Request - Pending tab by selecting
            <GridActionButton
              type="folder"
              onClick={() => {
                navigate(
                  `/app/UserDocuments/${newUser}/${companyId.toString()}/1`
                );
              }}
              tooltip="Document"
            />
            the icon.
          </Typography>
          <br />
          <Typography>
            Note: To assign the required test to the technician and complete the
            certification process, is necessary to make the payment.
          </Typography>
        </Box>
      </Grid>
    </>
  );
};

export default Document;
