import { OutletReportProvider } from "src/pages/studies/outlet/contexts/OutletReportContext";
import OutletStep2 from "./OutletStep2";

const Index = () => {
  return (
    <OutletReportProvider>
      <OutletStep2 />
    </OutletReportProvider>
  );
};

export default Index;
