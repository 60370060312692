import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IUserNotification } from "src/ts/interfaces";

interface INotificationState {
  notifications: IUserNotification[];
}

const initialState: INotificationState = {
  notifications: [],
};

export const notificationsSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    setNotifications(
      state: INotificationState,
      action: PayloadAction<IUserNotification[]>
    ) {
      state.notifications = action.payload;
    },
    archiveNotification(
      state: INotificationState,
      action: PayloadAction<number>
    ) {
      state.notifications = state.notifications.filter(
        (notification) => notification.id !== action.payload
      );
    },
    archiveAllNotification(state: INotificationState) {
      state.notifications = [];
    },
  },
});

export default notificationsSlice;
