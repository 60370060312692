import Popup from "src/components/Popup";
import { FormButton, FormTextArea } from "src/components/formControls";
import { Stack, Typography } from "@mui/material";
import { useForm, useLog } from "src/hooks";
import { Validator } from "src/ts/types";
import { useEffect, useState } from "react";
import RequestTransferTechnicianService from "src/services/RequestTransferTechnicianService";
import { IRequestTransfer } from "src/ts/interfaces/requestTransfer";
import moment from "moment";
import { DataAssignment } from "src/ts/interfaces/technician";

interface RequestTransferProps {
  requestTransferShowPopUp: boolean;
  setRequestTransferShowPopUp: (val: boolean) => void;
  refreshPage: boolean;
  setRefreshPage: (val: boolean) => void;
  currentRow: DataAssignment;
}

const initialValues = {
  comments: "",
};

export default function RequestTransfer({
  requestTransferShowPopUp,
  setRequestTransferShowPopUp,
  refreshPage,
  setRefreshPage,
  currentRow,
}: RequestTransferProps) {
  const [dataTransfer, setDataTransfer] = useState<IRequestTransfer>();
  const { log } = useLog();

  useEffect(() => {
    const getData = async () => {
      try {
        const response =
          await RequestTransferTechnicianService.getRequestTechnician(
            currentRow.id
          );
        setDataTransfer(response.data);
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      }
    };

    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const validate = (fieldValues = values) => {
    let temp: Record<string, string> = { ...errors };

    temp.comments = new Validator(fieldValues, "comments")
      .isRequired("Comment is required ")
      .toString();

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, errors, setErrors, handleInputChange } = useForm(
    initialValues,
    false,
    validate
  );

  const accepthandler = async () => {
    if (!validate()) return;
    try {
      await RequestTransferTechnicianService.putRequestTechnician(
        currentRow.id,
        values.comments
      );
      setRequestTransferShowPopUp(false);
      setRefreshPage(!refreshPage);
      log.success("The request has sent to administrator");
    } catch (error: any) {
      log.error(error?.message?.exceptionMessage ?? "Something went wrong");
    }
  };
  return (
    <>
      <Popup
        title={"Request Transfer"}
        openPopup={requestTransferShowPopUp}
        setOpenPopup={setRequestTransferShowPopUp}
        onClose={() => {
          setRequestTransferShowPopUp(false);
        }}
        size="sm"
      >
        <>
          {dataTransfer === null && (
            <>
              <Typography>
                {`Are you certain you want to initiate the authorization process
                to transfer auditor/technician ${currentRow.name} to your
                company/location?`}
              </Typography>
              <br />

              <FormTextArea
                name="comments"
                label="Comments"
                value={values.comments}
                onChange={handleInputChange}
                error={errors.comments}
                placeholder="Comment is required - Explain reason for transfer"
              />
            </>
          )}
          {dataTransfer !== null && (
            <>
              <Typography>
                {`The authorization process to transfer this technician to your company was already initiated by ${
                  dataTransfer?.createdBy?.name
                } ${dataTransfer?.createdBy?.lastName} on ${moment(
                  dataTransfer?.createdDate
                ).format("MM/DD/YYYY HH:mm A")}`}
              </Typography>
              <br />

              <Typography>Comments : {dataTransfer?.comments}</Typography>
            </>
          )}
        </>
        <Stack direction="row" spacing={3}>
          {dataTransfer == null ? (
            <>
              <FormButton
                size="small"
                text="Accept"
                color="primary"
                onClick={() => {
                  accepthandler();
                }}
              />
              <FormButton
                size="small"
                text="Close"
                variant="outlined"
                onClick={() => {
                  setRequestTransferShowPopUp(false);
                }}
              />
            </>
          ) : (
            <>
              <FormButton
                size="small"
                text="Accept"
                color="primary"
                onClick={() => {
                  setRequestTransferShowPopUp(false);
                }}
              />
            </>
          )}
        </Stack>
      </Popup>
    </>
  );
}
