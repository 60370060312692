import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import {
  Grid,
  Divider as MuiDivider,
  Card as MuiCard,
  CardContent,
  Box,
  Typography,
  Chip,
  Stack,
} from "@mui/material";
import { spacing } from "@mui/system";
import { ColumnType } from "src/types/enhancedTable";
import { useForm, Form, useAuth, useLog, usePermissions } from "src/hooks";
import { FormButton, FormText } from "src/components/formControls";
import LocalEnhancedTable from "src/components/localTable/LocalTable";
import useAsyncQuery from "src/hooks/useAsyncQuery";
import HeaderPage from "src/components/page/HeaderPage";
import {
  ICart,
  IpursacheCartProps,
  IpursacheCartViewProps,
} from "src/ts/interfaces/cart";
import { GridActionButton } from "src/components/gridControls";
import cartService from "src/services/cartService";
import PursacheCartPopup from "./PursacheCartPopup";
import { ShoppingCart } from "@mui/icons-material";
import { CompanyService, WalletService } from "src/services";
import { Permission as PermissionTypes } from "src/ts/enums";
import { ICompany } from "src/ts/interfaces";
import ViewCartPopup from "./ViewCartPopup";
import { currencyformatter } from "src/utils/format";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const initialValues = {
  id: 0,
  name: "",
  statusId: "",
  deactivationReason: "",
};

const initialValuesTotal: ICart = {
  id: 0,
  description: "",
  systemCostsCatalog: {
    name: "",
    code: "",
  },
  purchaseType: 0,
  project: {
    name: "",
    id: 0,
    isInfectiousControlProject: false,
  },
  technicians: {
    id: 0,
    email: "",
  },
  quantity: 0,
  totalCost: 0,
  requestDate: "",
  requestedByUserPerson: {
    name: "",
    lastName: "",
  },
  paymentStatus: "",
};

const columns = (
  handleOpenPursache: (id: number) => void,
  handleOpenView: (row: ICart) => void,
  selected: boolean,
  fullAccess: boolean
): ColumnType[] => [
  {
    id: "description",
    label: "Description",
    type: "string",
    sort: true,
  },
  {
    id: "quantity",
    label: "Qty",
    type: "custom",
    sort: true,
    callback: () => {
      return (
        <>
          <Typography>1</Typography>
        </>
      );
    },
  },
  {
    id: "totalCost",
    label: "Unit Price",
    type: "custom",
    align: "center",
    sort: true,
    totalLabel: "Total:",
    callback: (row: ICart) => {
      return (
        <Typography textAlign={"right"}>
          {currencyformatter.format(row.totalCost ?? 0)}
        </Typography>
      );
    },
  },
  {
    id: "totalCost",
    label: "Total Cost",
    type: "custom",
    sort: true,
    total: "totalCost",
    totalLabelAlign: "right",
    callback: (row: ICart) => {
      return (
        <>
          <Typography textAlign={"right"}>
            {currencyformatter.format(row.totalCost ?? 0)}
          </Typography>
        </>
      );
    },
  },
  {
    id: "requestDate",
    label: "Requested Date",
    type: "date",
    format: "MM/DD/YYYY",
    sort: true,
  },
  {
    id: "requestedByName",
    label: "Requested By",
    type: "string",
    sort: true,
  },
  {
    id: "paymentStatus",
    label: "Payment Status",
    type: "custom",
    sort: true,
    width: "165px",
    callback: (row: ICart) => {
      return (
        <>
          {row.paymentStatus === "Pending" && (
            <Chip
              label={row.paymentStatus}
              color="warning"
              variant="outlined"
            ></Chip>
          )}
          {row.paymentStatus !== "Pending" && (
            <Typography>{row.paymentStatus}</Typography>
          )}
        </>
      );
    },
  },
  {
    id: "actions",
    label: "Actions",
    type: "custom",
    sort: false,
    width: "133px;",
    callback: (row: ICart) => {
      return (
        <div>
          <Stack direction="row">
            {fullAccess && (
              <GridActionButton
                type="bill"
                onClick={() => handleOpenPursache(row.id)}
                tooltip="Purchase this item"
                disabled={selected}
              />
            )}
            <GridActionButton
              type="view"
              onClick={() => {
                handleOpenView(row);
              }}
              tooltip="View detail"
              disabled={selected}
            />
          </Stack>
        </div>
      );
    },
  },
];

const Cart = () => {
  const { log } = useLog();
  const { user } = useAuth();
  const { execute, data, isLoading } = useAsyncQuery<ICart[]>(
    cartService.getCart
  );
  const { execute: executeCompany, data: dataCompany } =
    useAsyncQuery<ICompany>(CompanyService.getById);
  const [pursacheCarProps, setPursacheCarProps] = useState<IpursacheCartProps>({
    carts: [],
    showPopup: false,
  });
  const [viewCarProps, setViewCarProps] = useState<IpursacheCartViewProps>({
    carts: [],
    showPopup: false,
  });
  const [cartItems, setCartItems] = useState<number[]>([]);
  const [totalCart, setTotalCart] = useState<ICart>(initialValuesTotal);
  const [refreshTable, setRefreshTable] = useState(false);
  const { values: filterValues, handleInputChange: handleFilterChange } =
    useForm(initialValues, true, []);
  const { execute: executeWallet, data: dataWallet } = useAsyncQuery<number>(
    WalletService.getWalletBalance
  );
  const { read: readWallet } = usePermissions(PermissionTypes.Wallet);
  const { fullAccess } = usePermissions(PermissionTypes.Cart);

  useEffect(() => {
    if (data && data.length > 0) {
      let sum: number = data
        .map((a) => a.totalCost)
        .reduce(function (a, b) {
          return a + b;
        });
      setTotalCart({ ...totalCart, totalCost: sum });
    } else setTotalCart({ ...totalCart, totalCost: 0 });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    const getData = async () => {
      execute(user?.companyId);
    };

    getData();

    const getWallet = async () => {
      try {
        if (readWallet) await executeWallet(user?.companyId);
        await executeCompany(user?.companyId);
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      }
    };
    if (user?.companyId) getWallet();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshTable]);

  const handleOpenPursashe = (id: number) => {
    let arr = [];
    arr.push(id);
    setPursacheCarProps({
      carts: arr,
      showPopup: true,
    });
  };

  const handleOpenView = (row: ICart) => {
    let arr = [];
    arr.push(row.id);
    setViewCarProps({
      carts: arr,
      showPopup: true,
      cart: row,
    });
  };

  const selectHandler = async (selected: number[]) => {
    if (selected.length !== cartItems.length) setCartItems(selected);
  };

  const handlePayAll = () => {
    setPursacheCarProps({ carts: cartItems, showPopup: true });
  };

  const handleRefreshTable = () => {
    setRefreshTable(!refreshTable);
  };

  return (
    <>
      <HeaderPage
        title={"Cart"}
        parentText={"Dashboard"}
        parentLink={`/`}
      ></HeaderPage>
      <Divider my={6} />
      <Card mb={6}>
        <CardContent>
          <Grid container spacing={6}>
            {readWallet && (
              <Grid item xs={4} textAlign={"center"}>
                <Typography variant="h4">Wallet Balance:</Typography>
                <Typography variant="h4">
                  {currencyformatter.format(dataWallet ?? 0)}
                </Typography>
              </Grid>
            )}
            <Grid item xs={4}></Grid>
            <Grid item xs={4} textAlign={"center"}>
              <Typography variant="h4">Wallet Serial Number: </Typography>
              <Typography variant="h6">{dataCompany?.walletSerial}</Typography>
              {dataCompany?.walletBlocked && (
                <Typography variant="h4">
                  Your AMP Wallet is blocked.
                </Typography>
              )}
              {dataCompany?.walletAdminBlocked && (
                <Typography variant="h4">
                  Your AMP Wallet has an Administrative Blockage.
                </Typography>
              )}
              {dataCompany?.walletDemoEndpointEnabled && (
                <Typography variant="h4">DEMO Mode.</Typography>
              )}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Card mb={6}>
        <CardContent>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Form>
                <Grid container>
                  <Grid
                    item
                    xs={12}
                    sx={{ textAlign: "center", marginBottom: "10px" }}
                  >
                    <Typography variant="h6">
                      <ShoppingCart />
                      <Box mt={2} sx={{ display: "inline" }}>
                        Cart Items
                      </Box>
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <FormText
                      name="name"
                      label="Search"
                      value={filterValues.name}
                      onChange={handleFilterChange}
                    />
                  </Grid>
                </Grid>
              </Form>
            </Grid>
            <Grid item xs={12}>
              <LocalEnhancedTable<ICart>
                cellCheckBox={true}
                onSelect={selectHandler}
                refreshGrid={true}
                columns={columns(
                  handleOpenPursashe,
                  handleOpenView,
                  cartItems.length > 0,
                  fullAccess
                )}
                data={Object.values(data ?? [])}
                query={filterValues.name}
                showSkeleton={isLoading}
                totalData={totalCart}
              />
            </Grid>
            {fullAccess && (
              <Grid item xs={12} sx={{ textAlign: "center" }}>
                <FormButton
                  text={"Pay Selected Items"}
                  onClick={handlePayAll}
                  disabled={cartItems.length === 0}
                />
              </Grid>
            )}
          </Grid>
        </CardContent>
      </Card>
      <PursacheCartPopup
        setPursacheCarProps={setPursacheCarProps}
        pursacheCarProps={pursacheCarProps}
        refreshTable={handleRefreshTable}
      ></PursacheCartPopup>
      <ViewCartPopup
        setPursacheCarProps={setViewCarProps}
        pursacheCarProps={viewCarProps}
      ></ViewCartPopup>
    </>
  );
};

export default Cart;
