import { useEffect, useState } from "react";
import { COUNTRIES } from "../constants";
import { StateService } from "../services";
import { IKeyValue } from "../ts/interfaces";
import useLog from "./useLog";

const useStateCatalog = () => {
  const [states, setStates] = useState<IKeyValue<number, string>[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [countryId, setCountryId] = useState(0);
  const { log } = useLog();

  useEffect(() => {
    const getState = async () => {
      try {
        setIsLoading(true);
        const request = await StateService.getKeyValues(countryId!);
        setStates(request);
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      } finally {
        setIsLoading(false);
      }
    };

    if (countryId === COUNTRIES.USA) getState();
    else setStates([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countryId]);

  return {
    states,
    setCountryId,
    isLoading,
  };
};

export default useStateCatalog;
