import axios from "src/utils/axios";
import { IOutletReportComponent } from "src/ts/interfaces";

const report = (id: number) => {
  return axios.get<IOutletReportComponent[]>(
    `OutletReportComponent/report/${id}`
  );
};

const getById = (id: number) => {
  return axios.get<IOutletReportComponent>(`OutletReportComponent/${id}`);
};

const update = (component: IOutletReportComponent) => {
  return axios.put<IOutletReportComponent>(
    `OutletReportComponent/${component.id}`,
    component
  );
};

const add = (outletReportId: number, component: IOutletReportComponent) => {
  return axios.post<IOutletReportComponent>(
    `OutletReportComponent/${outletReportId}`,
    component
  );
};

const remove = (componentId: number) => {
  return axios.delete<IOutletReportComponent>(
    `OutletReportComponent/${componentId}`
  );
};

const getLowesGriller = (componentId: number) => {
  return axios.get<IOutletReportComponent>(
    `OutletReportComponent/LowesGriller/${componentId}`
  );
};

const updateAll = (components: IOutletReportComponent[]) => {
  return axios.put(`OutletReportComponent`, components);
};

const outletReportComponentService = {
  report,
  getById,
  update,
  add,
  remove,
  getLowesGriller,
  updateAll,
};

export default outletReportComponentService;
