import React, { useState } from "react";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { FormButton, FormText } from "src/components/formControls";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import {
  CardContent,
  Grid,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  Typography,
  Stack,
  Tabs,
  Tab,
} from "@mui/material";
import { spacing } from "@mui/system";
import AdministratorsData from "./AdministratorsData";
import useForm from "src/hooks/useForm";
import { Permission as PermissionTypes } from "src/ts/enums";
import { usePermissions } from "src/hooks";
import AdministratorsSuggestionBankPopUp from "./AdministratorsSuggestionBankPopUp";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const filterInitialValues = {
  name: "",
};
function Administrators() {
  let navigate = useNavigate();
  const [selectdTab, setSelectedTab] = useState(0);

  const handleNewAdministrator = () => {
    navigate("/app/administrators/0");
  };

  const { values: filterValues, handleInputChange: handleFilterChange } =
    useForm(filterInitialValues, true, []);

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  const { fullAccess } = usePermissions(PermissionTypes.Administrators);
  const [openSuggestionBank, setOpenSuggestionBank] = useState(false);

  return (
    <React.Fragment>
      <Helmet title="Administrators" />
      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Audit Master Pro Administrators
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} to="/">
              Dashboard
            </Link>
            <Typography>Administrators</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item>
          <Stack direction="row" spacing={2}>
            {fullAccess && (
              <FormButton
                startIcon={<AddIcon />}
                text={"New Administrator"}
                onClick={handleNewAdministrator}
                size="medium"
              />
            )}
            <FormButton
              text={"Suggestion Bank Committee"}
              onClick={() => {
                setOpenSuggestionBank(true);
              }}
              size="medium"
              variant="outlined"
            />
          </Stack>
        </Grid>
      </Grid>

      <Divider my={6} />
      <Grid item xs={12}>
        <FormText
          name="name"
          label="Search"
          value={filterValues.name}
          onChange={handleFilterChange}
        />
      </Grid>
      <Divider my={6} />
      <Grid
        container
        spacing={10}
        sx={{ borderBottom: 1, borderColor: "divider", marginBottom: 5 }}
      >
        <Grid item xs={6}>
          <Tabs
            value={selectdTab}
            onChange={handleChangeTab}
            aria-label="basic tabs example"
          >
            <Tab label="Active" id="0" />
            <Tab label="Inactive" id="1" />
          </Tabs>
        </Grid>
      </Grid>

      <Card mb={12}>
        <CardContent>
          {selectdTab === 0 ? (
            <AdministratorsData
              refresh={false}
              statusCode="Active"
              filter={filterValues.name}
            />
          ) : null}
          {selectdTab === 1 ? (
            <AdministratorsData
              refresh={false}
              statusCode="Inactive"
              filter={filterValues.name}
            />
          ) : null}
        </CardContent>
      </Card>

      {openSuggestionBank && (
        <AdministratorsSuggestionBankPopUp
          showList={openSuggestionBank}
          setShowList={setOpenSuggestionBank}
        />
      )}
    </React.Fragment>
  );
}

export default Administrators;
