import axios from "src/utils/axios";

const deleteAuditor = (projectId: number, userId: number) => {
  return axios.delete(
    `ProjectAuditor/DeleteAuditor?auditorId=${userId}&projectId=${projectId}`
  );
};

const getIsPartOfProject = (projectId: number) => {
  return axios.get<boolean>(
    `ProjectAuditor/GetIsPartOfProject?projectId=${projectId}`
  );
};

const projectAuditorService = {
  deleteAuditor,
  getIsPartOfProject,
};

export default projectAuditorService;
