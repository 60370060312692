import { IBuildingPhoto, IBuildingPhotoDocument } from "../ts/interfaces";
import axios from "../utils/axios";

const uploadPhoto = async (file: IBuildingPhoto, fileName: string) => {
  let bodyFormData = new FormData();
  bodyFormData.append("fileForUpload", file.content);
  bodyFormData.append("key[buildingId]", file.buildingId.toString());
  bodyFormData.append("key[fileName]", fileName);

  return await axios({
    method: "post",
    url: "/BuildingPhoto/document",
    data: bodyFormData,
    headers: { "Content-Type": "multipart/form-data" },
  });
};

const getPhoto = async (buildingId: number) => {
  return axios.get<IBuildingPhotoDocument[]>(
    `BuildingPhoto/documents/${buildingId}`
  );
};

const deletePhoto = async (id: number) => {
  return axios.delete(`BuildingPhoto?id=${id}`);
};

const downloadPhoto = async (id: number) => {
  return axios.get(`BuildingPhoto/file?id=${id}`);
};

const hasBuildingProject = async (projectId: number) => {
  return axios.get<boolean>(
    `BuildingPhoto/HasBuildingPhoto?projectId=${projectId}`
  );
};

const buildingPhotoService = {
  uploadPhoto,
  getPhoto,
  deletePhoto,
  downloadPhoto,
  hasBuildingProject,
};

export default buildingPhotoService;
