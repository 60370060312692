import axios from "../utils/axios";
import { IComment } from "../ts/interfaces";

const getComment = async (projectId: number, type: number) => {
  return axios.get<IComment[]>(`Comment/type/${type}?projectId=${projectId}`);
};

const postComment = async (data: IComment) => {
  return axios.post("Comment", data);
};
const putComment = async (id: number, data: IComment) => {
  return axios.put(`Comment/${id}`, data);
};

const deleteCommentRow = async (id: number) => {
  return axios.delete(`Comment/${id}`);
};

const resolvedComment = async (id: number) => {
  return axios.put(`Comment/Resolve/${id}`);
};

const commentService = {
  getComment,
  postComment,
  putComment,
  deleteCommentRow,
  resolvedComment,
};

export default commentService;
