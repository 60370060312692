import { Grid, Stack } from "@mui/material";
import { Form } from "src/hooks/useForm";
import Popup from "src/components/Popup";
import { FormCancelButton, FormRadioGroup } from "src/components/formControls";
import { IOutletReportItem } from "src/ts/interfaces";
import { useEffect, useState } from "react";
import commentService from "src/services/commentService";

interface Props {
  value: string;
  isDialogOpen: boolean;
  setIsDialogOpen: (val: boolean) => void;
  onConfirm: (row: IOutletReportItem, index: number) => void;
  selectedRow: {
    row: IOutletReportItem;
    index: number;
  } | null;
  reportTypeId: number;
  reportId: number;
  projectId: number;
}

export const radioInformation = [
  {
    key: "Missing",
    value: "Missing",
  },
  {
    key: "Extra",
    value: "Extra",
  },
];

const TerminalDevicePopup = (props: Props) => {
  const {
    isDialogOpen,
    setIsDialogOpen,
    onConfirm,
    selectedRow,
    reportTypeId,
    reportId,
    projectId,
  } = props;

  const [selectedValue, setSelectedValue] = useState("");

  const closeHandler = () => {
    setIsDialogOpen(false);
  };

  const handleInputChange = async (e: any) => {
    if (selectedRow === null) return;

    setSelectedValue(e.target.value);

    if (e.target.value === "Missing") {
      const missingRow = missingSelected();
      onConfirm(missingRow, selectedRow.index);
    }
    if (e.target.value === "Extra") {
      const extraRow = extraSelected();
      onConfirm(extraRow, selectedRow.index);
    }

    setIsDialogOpen(false);
  };

  useEffect(() => {
    if (!isDialogOpen && selectedValue !== "") {
      setSelectedValue("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDialogOpen]);

  const missingSelected = () => {
    const row: IOutletReportItem = { ...selectedRow?.row! };

    row.missing = true;
    row.extra = false;

    row.width = 0;
    row.heigth = 0;
    row.akColumn = 0;
    row.device = "";
    row.requiredVelocity = 0;
    row.requiredCFM = 0;
    row.actualVelocity = 0;
    row.preliminary = 0;
    row.actualCFM = 0;
    row.percentaje = 0;

    row.btuh = 0;
    row.relHum = 0;
    row.sensibleHeat = 0;
    row.maxMin = "";
    row.max = 0;
    row.min = 0;

    const date = new Date();

    commentService.postComment({
      commentTypeId: 1,
      reportTypeId: reportTypeId,
      reportId: reportId,
      projectId: projectId,
      note:
        "Room or area served: " +
        row.roomAreaServed +
        ", outlet #: " +
        ((selectedRow?.index ?? 0) + 1) +
        ", Missing",
      createdDate: date,
    });

    return row;
  };

  const extraSelected = () => {
    const row: IOutletReportItem = { ...selectedRow?.row! };

    row.missing = false;
    row.extra = true;

    const date = new Date();

    commentService.postComment({
      commentTypeId: 1,
      reportTypeId: reportTypeId,
      reportId: reportId,
      projectId: projectId,
      note:
        "Room or area served: " +
        row.roomAreaServed +
        ", outlet #: " +
        ((selectedRow?.index ?? 0) + 1) +
        ", Extra",
      createdDate: date,
    });

    return row;
  };

  return (
    <>
      <Popup
        openPopup={isDialogOpen}
        setOpenPopup={setIsDialogOpen}
        title={`Terminal Device Missing/Extra`}
        onClose={closeHandler}
      >
        <Form>
          <Grid container spacing={5}>
            <Grid item xs={12}>
              <FormRadioGroup
                name="inletTypeOfDuct"
                value={selectedValue}
                items={radioInformation}
                onChange={handleInputChange}
                row={true}
              />
            </Grid>
          </Grid>
        </Form>
        <>
          <Stack spacing={2} direction="row">
            <FormCancelButton onClick={closeHandler} isSubmitting={false} />
          </Stack>
        </>
      </Popup>
    </>
  );
};

export default TerminalDevicePopup;
