import { IStudyStep } from "src/ts/interfaces";

export const steps: IStudyStep[] = [
  {
    label: "Step 1",
    key: 0,
    description: "Controls",
    url: "/studies/:code/step1/:id",
  },
  {
    label: "Step 2",
    key: 1,
    description: "Control configuration",
    url: "/studies/:code/step2/:id",
  },
  {
    label: "Step 3",
    key: 2,
    description: "Outlet readings & Parameters",
    url: "/studies/:code/step3/:id",
  },
  {
    label: "Step 4",
    key: 3,
    description: "Report",
    url: "/studies/:code/step4/:id",
  },
];
