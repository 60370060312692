import React, { useEffect, useState } from "react";
import Popup from "src/components/Popup";
import {
  FormCancelButton,
  FormText,
  FormSaveButton,
  FormSelect,
} from "src/components/formControls";
import { Stack, Typography, Grid, IconButton } from "@mui/material";
import { ClearOutIcon, AddIcon } from "src/components/icons";
import useForm from "src/hooks/useForm";
import { MetaData } from "src/ts/interfaces/subscription";
import SubscriptionService from "src/services/subscriptionService";
import useLog from "src/hooks/useLog";
import { Permission as PermissionTypes } from "src/ts/enums";
import { usePermissions } from "src/hooks";

interface EditCostProps {
  showEditCost: boolean;
  setShowEditCost: (val: boolean) => void;
  metaData: any;
  setRefresh: (val: boolean) => void;
  keyValueData: any;
  keyValue: any;
}

export default function EditCost({
  showEditCost,
  setShowEditCost,
  metaData,
  setRefresh,
  keyValueData,
  keyValue,
}: EditCostProps) {
  const [withCost, setWithCost] = useState<any>(metaData.withCostList);
  const [withoutCost, setWithoutCost] = useState<any>(metaData.withoutCostList);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleClosePopUp = () => {
    setShowEditCost(false);
  };

  const initialValues = {
    title: metaData.title,
    subTitle: metaData.subTitle,
    price: metaData.price,
    getPriceFromDb: metaData.getPriceFromDb,
    isActive: metaData.isActive,
    id: metaData.id,
    costCatalogId: metaData.costCatalogId,
    subscriptionId: metaData.subscriptionId,
    textPage: metaData.textPage,
  };

  const { log } = useLog();
  const { values, handleInputChange } = useForm(initialValues, false, []);
  const { fullAccess, readOnly } = usePermissions(
    PermissionTypes.Subscriptions
  );

  useEffect(() => {
    const getKeyValue = async () => {
      try {
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      }
    };
    getKeyValue();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAddNewWithoutCost = () => {
    setWithoutCost([
      ...withoutCost,
      {
        breakLine: false,
        cost: null,
        text: "",
        style: null,
        costCatalogId: null,
      },
    ]);
  };

  const handleAddNewWithCost = () => {
    setWithCost([
      ...withCost,
      {
        breakLine: false,
        cost: null,
        text: "",
        style: null,
        costCatalogId: null,
      },
    ]);
  };

  const handleDeleteWithoutCost = (textData: any) => {
    var newArrayTextLine = withoutCost.filter((withoutCostItem: any) => {
      return withoutCostItem.text !== textData.text;
    });

    setWithoutCost(newArrayTextLine);
  };

  const handleDeleteWithCost = (textData: any) => {
    var newArrayTextLine = withCost.filter((withoCostItem: any) => {
      return withoCostItem.text !== textData.text;
    });

    setWithCost(newArrayTextLine);
  };

  const handleOnChangeWithoutCost = (e: any, index: number, field: string) => {
    e.preventDefault();
    const { value } = e.target;

    const newWithoutCost = [...withoutCost];

    newWithoutCost[index] = { ...newWithoutCost[index], [field]: value };

    setWithoutCost(newWithoutCost);
  };

  const handleOnChangeWithCost = (e: any, index: number, field: string) => {
    e.preventDefault();
    const { value } = e.target;

    if (field === "costCatalogId") {
      const nameKeyValue = keyValueData.filter((item: any) => {
        return item.id === value;
      });
      const newWithCost = [...withCost];
      newWithCost[index] = {
        ...newWithCost[index],
        cost: nameKeyValue[0].price,
        costCatalogId: value,
      };
      setWithCost(newWithCost);
    } else {
      const newWithCost = [...withCost];
      newWithCost[index] = { ...newWithCost[index], [field]: value };
      setWithCost(newWithCost);
    }
  };

  const handleAcceptedButton = () => {
    var arrayMetaData: MetaData[] = [];

    arrayMetaData.push({
      ...values,
      textLines: metaData.textLines,
      withCostList: withCost,
      withoutCostList: withoutCost,
    });

    const postMetaDataSubs = async (data: MetaData[]) => {
      setIsSubmitting(true);
      try {
        await SubscriptionService.postMetaData(data);
        log.success(`Metadata was saved successfully`);
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      } finally {
        setIsSubmitting(false);
        setRefresh(true);
        setShowEditCost(false);
      }
    };
    postMetaDataSubs(arrayMetaData);
  };

  return (
    <>
      <Popup
        title="Detail page"
        openPopup={showEditCost}
        setOpenPopup={setShowEditCost}
        onClose={handleClosePopUp}
        size="lg"
      >
        <>
          <Grid container>
            <FormText
              label="Text Page"
              name="textPage"
              value={values.textPage}
              onChange={handleInputChange}
              disabled={readOnly}
            />
            <Grid xs={5} item>
              <Grid container xs={12} item>
                <Grid xs={5} item>
                  <Typography variant="h4" mt={5} mb={4}>
                    Options <b>without</b> cost
                  </Typography>
                </Grid>
                <Grid xs={7} justifyContent="center" mt={4} item>
                  {fullAccess && (
                    <IconButton
                      aria-label="add"
                      size="small"
                      onClick={handleAddNewWithoutCost}
                    >
                      <AddIcon />
                    </IconButton>
                  )}
                </Grid>
              </Grid>

              <Grid container xs={12} item>
                {withoutCost.length !== 0
                  ? withoutCost.map((list: any, index: number) => (
                      <Grid container xs={12} item key={index}>
                        <Grid xs={8} mb={2} item>
                          <FormText
                            name=""
                            label=""
                            value={list.text}
                            disabled={readOnly}
                            onChange={(e) => {
                              handleOnChangeWithoutCost(e, index, "text");
                            }}
                          />
                        </Grid>
                        <Grid xs={4} item>
                          {fullAccess && (
                            <IconButton
                              aria-label="delete"
                              size="small"
                              onClick={() => handleDeleteWithoutCost(list)}
                            >
                              <ClearOutIcon />
                            </IconButton>
                          )}
                        </Grid>
                      </Grid>
                    ))
                  : null}
              </Grid>
            </Grid>
            <Grid xs={7} item>
              <Grid container xs={12} item>
                <Grid xs={4} item>
                  <Typography variant="h4" mt={5}>
                    Options <b>with</b> cost
                  </Typography>
                </Grid>
                <Grid xs={8} justifyContent="center" mt={4} item>
                  {fullAccess && (
                    <IconButton
                      aria-label="add"
                      size="small"
                      onClick={handleAddNewWithCost}
                    >
                      <AddIcon />
                    </IconButton>
                  )}
                </Grid>
              </Grid>
              <Grid container xs={12} mt={2} item>
                {withCost.length !== 0
                  ? withCost.map((list: any, index: number) => (
                      <Grid container xs={12} item key={index}>
                        <Grid xs={4} mb={2} key={index} item>
                          <FormText
                            key={index}
                            name=""
                            label=""
                            value={list.text}
                            disabled={readOnly}
                            onChange={(e) => {
                              handleOnChangeWithCost(e, index, "text");
                            }}
                          />
                        </Grid>
                        <Grid xs={2} ml={2} item>
                          <FormText
                            key={index}
                            name=""
                            label=""
                            value={list.cost}
                            disabled={readOnly}
                            onChange={(e) => {
                              handleOnChangeWithCost(e, index, "cost");
                            }}
                          />
                        </Grid>
                        <Grid xs={4} ml={2} item>
                          <FormSelect
                            key={index}
                            name=""
                            label=""
                            value={list.costCatalogId}
                            options={keyValue}
                            disable={readOnly}
                            onChange={(e) => {
                              handleOnChangeWithCost(e, index, "costCatalogId");
                            }}
                          />
                        </Grid>
                        <Grid xs={1} item>
                          {fullAccess && (
                            <IconButton
                              aria-label="delete"
                              size="small"
                              onClick={(e) => handleDeleteWithCost(list)}
                            >
                              <ClearOutIcon />
                            </IconButton>
                          )}
                        </Grid>
                      </Grid>
                    ))
                  : null}
              </Grid>
            </Grid>
          </Grid>
        </>

        <Stack direction="row" spacing={5}>
          <FormCancelButton
            isSubmitting={isSubmitting}
            onClick={() => {
              setShowEditCost(false);
            }}
          />
          {fullAccess && (
            <FormSaveButton
              isSubmitting={isSubmitting}
              onClick={handleAcceptedButton}
            />
          )}
        </Stack>
      </Popup>
    </>
  );
}
