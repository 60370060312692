import React, { useState, useEffect } from "react";
import { Grid, Stack } from "@mui/material";
import { useForm, Form } from "src/hooks";
import { FormCancelButton, FormText } from "src/components/formControls";
import Popup from "src/components/Popup";
import LocalEnhancedTable from "src/components/localTable/LocalTable";
import { GridActionButton } from "src/components/gridControls";
import { buildingService } from "src/services";
import { ColumnType } from "src/types/enhancedTable";
import { IBuildingSelected } from "src/ts/interfaces";

interface Props {
  isDialogOpen: boolean;
  setIsDialogOpen: (val: boolean) => void;
  onSelect: (buildingId: IBuildingSelected) => void;
  companyId: number;
}

const initialValues = {
  id: 0,
  name: "",
  statusId: "",
};

const getColumns = (
  onSelect: (buildingId: IBuildingSelected) => void,
  setIsDialogOpen: (val: boolean) => void
): ColumnType[] => {
  return [
    { id: "serial", label: "Serial", type: "string", sort: true },
    { id: "name", label: "Name", type: "string", sort: true },
    { id: "address", label: "Address", type: "string", sort: true },
    {
      id: "createdDate",
      label: "Created",
      type: "utcDate",
      sort: true,
      format: "MM/DD/yyyy",
    },
    {
      id: "action",
      label: "Actions",
      type: "custom",
      sort: false,
      callback: (row: IBuildingSelected) => (
        <Stack direction="row">
          <GridActionButton
            type="forward"
            tooltip=""
            onClick={() => {
              onSelect(row);
              setIsDialogOpen(false);
            }}
          />
        </Stack>
      ),
    },
  ];
};

const SelectBuildingPopup = (props: Props) => {
  const { isDialogOpen, setIsDialogOpen, onSelect, companyId } = props;
  const [dataSet, setDataSet] = useState<IBuildingSelected[]>([]);

  const { values: filterValues, handleInputChange: handleFilterChange } =
    useForm(initialValues, true, []);

  useEffect(() => {
    const getData = async () => {
      const request = await buildingService.getByCompany(companyId.toString());
      setDataSet(request.data);
    };

    getData();
  }, [companyId]);

  const cancelHandler = () => {
    setIsDialogOpen(false);
  };

  const columns = getColumns(onSelect, setIsDialogOpen);

  return (
    <Popup
      openPopup={isDialogOpen}
      setOpenPopup={setIsDialogOpen}
      title={`Select Building`}
      onClose={cancelHandler}
      size="md"
    >
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Form>
            <Grid container>
              <Grid item xs={12}>
                <FormText
                  name="name"
                  label="Search"
                  value={filterValues.name}
                  onChange={handleFilterChange}
                />
              </Grid>
            </Grid>
          </Form>
        </Grid>
        <Grid item xs={12}>
          <LocalEnhancedTable<any>
            columns={columns}
            data={dataSet?.filter((building) => building.isActive)}
            query={filterValues.name}
            showSkeleton={false}
          />
        </Grid>
      </Grid>
      <>
        <Stack
          direction="row"
          spacing={2}
          justifyContent="center"
          alignItems="center"
        >
          <FormCancelButton onClick={cancelHandler} isSubmitting={false} />
        </Stack>
      </>
    </Popup>
  );
};

export default SelectBuildingPopup;
