import { Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import {
  FormButton,
  FormCheckSwitch,
  FormLabel,
  FormNumeric2,
  FormRadioGroup,
  FormSelect,
  FormText,
} from "src/components/formControls";
import { AddIcon } from "src/components/icons";
import { useAsyncQuery, useLog } from "src/hooks";
import outletReportCustomNameParametersService from "src/services/study/outletReportCustomNameParametersService";
import outletReportCustomParametersService from "src/services/study/outletReportCustomParametersService";
import {
  IKeyValue,
  IOutletReportCustomNameParametersAll,
  IOutletReportCustomParameters,
} from "src/ts/interfaces";
import useOutletReport from "../../hooks/useOutletReport";
import React from "react";
import { GridActionButton } from "src/components/gridControls";
import DialogMessagePopup from "src/components/DialogMessagePopup";
import { Stack } from "@mui/system";
import useFormTyped from "src/hooks/useFormTyped";
import motorManufacturerService from "src/services/catalogs/motorManufacturerService";
import defaultHorsePower from "src/services/catalogs/defaultHorsePower";
import { Mode } from "src/ts/types";

interface Props {
  reportTypeCode: string;
  systemOfMeasurement: string | null;
  custom: IOutletReportCustomParameters[];
  setCustom: (customParameters: IOutletReportCustomParameters[]) => void;
  addDefaultParameters: () => void;
  mode: Mode;
  studyIsDisabled: boolean;
}

interface INameValue {
  name: string;
  value: string;
}

const OutletReportParameters = ({
  reportTypeCode,
  systemOfMeasurement,
  custom,
  setCustom,
  addDefaultParameters,
  mode,
  studyIsDisabled,
}: Props) => {
  const { componentValues, outletReportComponentId } = useOutletReport();
  const { log } = useLog();

  const [customNameParamsKeyValues, setCustomNameParamsKeyValues] = useState<
    IKeyValue<number, string>[]
  >([]);

  // const [customNameParams, setCustomNameParams] = useState<
  //   IOutletReportCustomNameParametersAll[]
  // >([]);

  const [customNameParamsUnfiltered, setCustomNameParamsUnfiltered] = useState<
    IOutletReportCustomNameParametersAll[]
  >([]);

  const { data: motorManufacturerData } = useAsyncQuery<
    IKeyValue<number, string>[]
  >(motorManufacturerService.getKeyValues, { immediate: true });

  const { data: defaultHorsePowerData } = useAsyncQuery<
    IKeyValue<number, string>[]
  >(defaultHorsePower.getKeyValues, { immediate: true });

  useEffect(() => {
    const getData = async () => {
      const customNameParamsRes =
        await outletReportCustomNameParametersService.getAll();

      const unfilteredCustomNameParams = customNameParamsRes.data;
      const filteredParams = unfilteredCustomNameParams
        .filter((item) => item.reportType != null)
        .filter(
          (item) =>
            item.name !== "Make" &&
            item.name !== "Model" &&
            item.name !== "Serial Number" &&
            item.name !== "Box Size" &&
            item.name !== "Range" &&
            item.reportType.search(reportTypeCode) !== -1
        );

      const customerParamKeyValue = filteredParams.map((item) => {
        return {
          key: item.id,
          value:
            systemOfMeasurement === "metricSystem"
              ? `${item.nameMetric}`
              : `${item.name}`,
        };
      });
      setCustomNameParamsKeyValues(customerParamKeyValue);
      setCustomNameParamsUnfiltered(unfilteredCustomNameParams);
      //setCustomNameParams(filteredParams); //??

      const customParamRes =
        await outletReportCustomParametersService.getByComponent(
          outletReportComponentId
        );

      const customss = customParamRes.data;
      if (customss.length === 0) {
        if (reportTypeCode !== "VAVR" && reportTypeCode !== "CAVR") {
          const [parallelFlowFpbParam] = unfilteredCustomNameParams.filter(
            (item) => item.name === "Parallel Flow FPB / Series Flow FPB"
          );

          const outletReportCustomParameters: any = {
            outletReportComponentId: outletReportComponentId,
            OutletReportCustomNameParametersId: parallelFlowFpbParam.id,
          };

          const addCustomParamRes =
            await outletReportCustomParametersService.add(
              outletReportComponentId,
              outletReportCustomParameters
            );
          customss.push({
            ...addCustomParamRes.data,
            outletReportCustomNameParameters: {
              name: "Parallel Flow FPB / Series Flow FPB",
              type: "boolParallelSeries",
              order: 0,
              id: 0,
              reportType: "",
              nameMetric: "",
              statusId: null,
            },
          });
        }

        if (componentValues.coilTypeLinked === "Hydronic") {
          const [designGpmParam] = unfilteredCustomNameParams.filter(
            (item) => item.name === "Design GPM"
          );
          const outletReportCustomParametersGPM: any = {
            outletReportComponentId: outletReportComponentId,
            OutletReportCustomNameParametersId: designGpmParam.id,
          };

          const addCustomParamRes =
            await outletReportCustomParametersService.add(
              outletReportComponentId,
              outletReportCustomParametersGPM
            );

          customss.push({
            ...addCustomParamRes.data,
            outletReportCustomNameParameters: {
              name: "Design GPM",
              type: null,
              order: 0,
              id: 0,
              reportType: "",
              nameMetric: "",
              statusId: null,
            },
          });
        }

        if (componentValues.coilTypeLinked === "Electric") {
          const [designKwsParam] = unfilteredCustomNameParams.filter(
            (item) => item.name === "Design kW's"
          );

          const outletReportCustomParametersKws: any = {
            outletReportComponentId: outletReportComponentId,
            OutletReportCustomNameParametersId: designKwsParam.id,
          };

          const addCustomParamRes =
            await outletReportCustomParametersService.add(
              outletReportComponentId,
              outletReportCustomParametersKws
            );

          customss.push({
            ...addCustomParamRes.data,
            outletReportCustomNameParameters: {
              name: "Design kW's",
              type: null,
              order: 0,
              id: 0,
              reportType: "",
              nameMetric: "",
              statusId: null,
            },
          });
        }
      }
      setCustom(customss);
    };

    if (reportTypeCode !== "" && systemOfMeasurement !== null) getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportTypeCode, systemOfMeasurement, outletReportComponentId]);

  const parameterChangehandle = async (e: any) => {
    const selectedParameterId = parseInt(e.target.value);
    const existentParameter =
      custom.find(
        (param) =>
          param.outletReportCustomNameParameters.id === selectedParameterId
      ) ?? null;
    if (existentParameter) {
      log.error(
        existentParameter.outletReportCustomNameParameters.name +
          " parameter already exist"
      );
    } else {
      const outletReportCustomParameters: any = {
        outletReportComponentId: componentValues.id,
        OutletReportCustomNameParametersId: selectedParameterId,
      };

      const addParamRes = await outletReportCustomParametersService.add(
        componentValues.id,
        outletReportCustomParameters
      );

      const newParam = customNameParamsUnfiltered.find(
        (item) => item.id === selectedParameterId
      );

      const param: IOutletReportCustomParameters = {
        ...addParamRes.data,
        outletReportCustomNameParameters: {
          ...addParamRes.data.outletReportCustomNameParameters,
          name: newParam?.name!,
          nameMetric: newParam?.nameMetric!,
          type: newParam?.type!,
        },
      };

      setCustom([...custom, param]);
    }
  };

  const handleNumericChange = (e: any, item: IOutletReportCustomParameters) => {
    //TODO: Issue with fixedDecimalScale
    const index = custom.map((e) => e.id).indexOf(item.id);
    const paramValue = parseFloat(e.target.value);

    const modifiedItem: IOutletReportCustomParameters = {
      ...item,
      value1: paramValue.toString(),
    };

    updateState(index, modifiedItem);
  };

  const handleTextChange = (e: any, item: IOutletReportCustomParameters) => {
    const paramValue = e.target.value;

    const index = custom.map((e) => e.id).indexOf(item.id);

    const modifiedItem: IOutletReportCustomParameters = {
      ...item,
      value1: paramValue.toString(),
    };

    updateState(index, modifiedItem);
  };

  const handleVoltageChange = (e: any, item: IOutletReportCustomParameters) => {
    const paramValue = parseFloat(e.target.value);
    const index = custom.map((e) => e.id).indexOf(item.id);
    const modifiedItem: IOutletReportCustomParameters = {
      ...item,
      [e.target.name]: paramValue,
    };

    const average =
      (parseFloat(modifiedItem?.value1 ?? 0) +
        parseFloat(modifiedItem?.value2 ?? 0) +
        parseFloat(modifiedItem?.value3 ?? 0)) /
      3;

    const modifiedItemWithAverage: IOutletReportCustomParameters = {
      ...modifiedItem,
      average: average,
    };

    updateState(index, modifiedItemWithAverage);
  };

  const updateState = (
    index: number,
    modifiedItem: IOutletReportCustomParameters
  ) => {
    const newCustom = custom.map((param: IOutletReportCustomParameters, i) =>
      i === index ? modifiedItem : param
    );

    setCustom(newCustom);
  };

  const [selectedParameter, setSelectedParameter] = useState<{
    parameter: IOutletReportCustomParameters;
    index: number;
  } | null>(null);
  const removeParameterHandler = (
    e: any,

    item: IOutletReportCustomParameters
  ) => {
    const index = custom.map((e) => e.id).indexOf(item.id);
    setSelectedParameter({ parameter: item, index });
    setShowDeletePopup(true);
  };

  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const confirmDeleteParameterHandler = async () => {
    if (selectedParameter === null) return;
    await outletReportCustomParametersService.remove(
      selectedParameter.parameter.id
    );

    const updatedParams = custom.filter(
      (param) => param.id !== selectedParameter.parameter.id
    );

    setCustom([...updatedParams]);
  };

  const { values, handleInputChange, resetForm } = useFormTyped<INameValue>({
    name: "",
    value: "",
  });

  const addCustomParameter = async () => {
    const outletReportCustomParameters: any = {
      outletReportComponentId: componentValues.id,
      name: values.name,
      value1: values.value,
      outletReportCustomNameParametersId: 50,
    };

    const addCustomParamRes = await outletReportCustomParametersService.add(
      componentValues.id,
      outletReportCustomParameters
    );

    const param: IOutletReportCustomParameters = {
      ...addCustomParamRes.data,
      outletReportCustomNameParameters: {
        ...addCustomParamRes.data.outletReportCustomNameParameters,
        name: "Other",
      },
    };

    setCustom([...custom, param]);

    resetForm();
  };

  const valueOptions: IKeyValue<number, string>[] = [
    { value: "1", key: 1 },
    { value: "2", key: 2 },
    { value: "3", key: 3 },
    { value: "4", key: 4 },
  ];

  return (
    <>
      <Grid container spacing={5}>
        <Grid item xs={6}>
          <FormSelect
            name="teamMemberId"
            label="Parameter"
            value={"-1"}
            onChange={parameterChangehandle}
            options={customNameParamsKeyValues ?? []}
            defaultValue={{
              key: "-1",
              value: "Select Item",
            }}
            disabled={studyIsDisabled}
          />
        </Grid>
        <Grid item xs={6}>
          <Stack direction="row" spacing={3}>
            <Typography>
              Default selected parameters to all boxes associated to this fan
              system{" "}
            </Typography>

            <FormButton
              startIcon={<AddIcon />}
              text="Add"
              onClick={addDefaultParameters}
              size="small"
              disabled={studyIsDisabled}
              variant="outlined"
            />
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={5}>
            {custom
              ?.filter(
                (item) => item.outletReportCustomNameParameters.name !== "Other"
              )
              .map((item: IOutletReportCustomParameters, index) => (
                <React.Fragment key={item.id}>
                  <Grid item xs={6}>
                    {item?.outletReportCustomNameParameters?.type === null && (
                      <>
                        <FormNumeric2
                          name="designBoxInletSizeW"
                          label={
                            systemOfMeasurement === "metricSystem"
                              ? item.outletReportCustomNameParameters.nameMetric
                              : item.outletReportCustomNameParameters.name
                          }
                          value={item.value1}
                          onChange={(e) => handleNumericChange(e, item)}
                          fixedDecimalScale={true}
                          thousandSeparator={true}
                          maxValue={1000000000}
                          mode={mode}
                          decimalScale={3}
                          disabled={studyIsDisabled}
                        />
                      </>
                    )}
                    {item?.outletReportCustomNameParameters?.type ===
                      "alphanumeric" && (
                      <>
                        <FormText
                          name={`${item.name}-${item.id}`}
                          label={
                            systemOfMeasurement === "metricSystem"
                              ? item.outletReportCustomNameParameters.nameMetric
                              : item.outletReportCustomNameParameters.name
                          }
                          value={item.value1}
                          onChange={(e) => handleTextChange(e, item)}
                          mode={mode}
                        />
                      </>
                    )}
                    {item?.outletReportCustomNameParameters?.type ===
                      "boolECM/Induction" && (
                      <>
                        <FormRadioGroup
                          name={`${item.name}-${item.id}`}
                          label={
                            systemOfMeasurement === "metricSystem"
                              ? item.outletReportCustomNameParameters.nameMetric
                              : item.outletReportCustomNameParameters.name
                          }
                          mode={mode}
                          items={[
                            {
                              key: "true",
                              value: "ECM",
                            },
                            {
                              key: "false",
                              value: "Induction",
                            },
                          ]}
                          onChange={(e) => handleTextChange(e, item)}
                          value={item.value1}
                          row={true}
                          disabled={studyIsDisabled}
                        />
                      </>
                    )}
                    {item?.outletReportCustomNameParameters?.type ===
                      "boolParallelSeries" && (
                      <>
                        <FormRadioGroup
                          name={`${item.name}-${item.id}`}
                          label={
                            systemOfMeasurement === "metricSystem"
                              ? item.outletReportCustomNameParameters.nameMetric
                              : item.outletReportCustomNameParameters.name
                          }
                          items={[
                            {
                              key: "true",
                              value: "Parallel Flow FPB",
                            },
                            {
                              key: "false",
                              value: "Series Flow FPB",
                            },
                          ]}
                          mode={mode}
                          onChange={(e) => handleTextChange(e, item)}
                          value={item.value1}
                          row={true}
                          disabled={studyIsDisabled}
                        />
                      </>
                    )}
                    {item?.outletReportCustomNameParameters?.type ===
                      "boolDefaultFalse" && (
                      <>
                        <FormCheckSwitch
                          label={
                            systemOfMeasurement === "metricSystem"
                              ? item.outletReportCustomNameParameters.nameMetric
                              : item.outletReportCustomNameParameters.name
                          }
                          name={`${item.name}-${item.id}`}
                          value={item.value1 === "true"}
                          onChange={(e) => handleTextChange(e, item)}
                          mode={mode}
                          disabled={studyIsDisabled}
                        />
                      </>
                    )}
                    {item?.outletReportCustomNameParameters?.type === "3" && (
                      <>
                        <FormLabel
                          text={
                            systemOfMeasurement === "metricSystem"
                              ? item.outletReportCustomNameParameters.nameMetric
                              : item.outletReportCustomNameParameters.name
                          }
                        />
                        <Stack direction="row" spacing={2} pt="7px">
                          <FormNumeric2
                            name={`${item.name}-${item.id}-l1`}
                            label="L1"
                            value={item.value1}
                            onChange={(e) =>
                              handleVoltageChange(
                                {
                                  target: {
                                    name: "value1",
                                    value: e.target.value,
                                  },
                                },
                                item
                              )
                            }
                            fixedDecimalScale={true}
                            thousandSeparator={true}
                            maxValue={1000000000}
                            mode={mode}
                            decimalScale={3}
                            disabled={studyIsDisabled}
                          />
                          <FormNumeric2
                            name={`${item.name}-${item.id}-l2`}
                            label="L2"
                            value={item.value2}
                            onChange={(e) =>
                              handleVoltageChange(
                                {
                                  target: {
                                    name: "value2",
                                    value: e.target.value,
                                  },
                                },
                                item
                              )
                            }
                            fixedDecimalScale={true}
                            thousandSeparator={true}
                            maxValue={1000000000}
                            mode={mode}
                            decimalScale={3}
                            disabled={studyIsDisabled}
                          />

                          <FormNumeric2
                            name={`${item.name}-${item.id}-l3`}
                            label="L3"
                            value={item.value3}
                            onChange={(e) =>
                              handleVoltageChange(
                                {
                                  target: {
                                    name: "value3",
                                    value: e.target.value,
                                  },
                                },
                                item
                              )
                            }
                            fixedDecimalScale={true}
                            thousandSeparator={true}
                            maxValue={1000000000}
                            mode={mode}
                            decimalScale={3}
                            disabled={studyIsDisabled}
                          />

                          <FormNumeric2
                            name={`${item.name}-${item.id}-avg`}
                            label="Average"
                            value={item.average}
                            fixedDecimalScale={true}
                            thousandSeparator={true}
                            maxValue={1000000000}
                            disabled={true}
                            decimalScale={3}
                            mode={mode}
                          />
                        </Stack>
                      </>
                    )}
                    {item?.outletReportCustomNameParameters?.type ===
                      "Quantityofmotors" && (
                      <>
                        <FormSelect
                          name={`${item.name}-${item.id}`}
                          label={
                            systemOfMeasurement === "metricSystem"
                              ? item.outletReportCustomNameParameters.nameMetric
                              : item.outletReportCustomNameParameters.name
                          }
                          value={item.value1}
                          onChange={(e) => handleTextChange(e, item)}
                          options={valueOptions}
                          defaultValue={{ key: -1, value: "Select item" }}
                          mode={mode}
                          disabled={studyIsDisabled}
                        />
                      </>
                    )}
                    {item?.outletReportCustomNameParameters?.type ===
                      "defaultHorsePower" && (
                      <>
                        <FormSelect
                          name={`${item.name}-${item.id}`}
                          label={
                            systemOfMeasurement === "metricSystem"
                              ? item.outletReportCustomNameParameters.nameMetric
                              : item.outletReportCustomNameParameters.name
                          }
                          value={item.value1}
                          onChange={(e) => handleTextChange(e, item)}
                          options={defaultHorsePowerData}
                          defaultValue={{ key: -1, value: "Select item" }}
                          mode={mode}
                          disabled={studyIsDisabled}
                        />
                      </>
                    )}
                    {item?.outletReportCustomNameParameters?.type ===
                      "motorManufacturer" && (
                      <>
                        <FormSelect
                          name={`${item.name}-${item.id}`}
                          label={
                            systemOfMeasurement === "metricSystem"
                              ? item.outletReportCustomNameParameters.nameMetric
                              : item.outletReportCustomNameParameters.name
                          }
                          value={item.value1}
                          onChange={(e) => handleTextChange(e, item)}
                          options={motorManufacturerData}
                          defaultValue={{ key: -1, value: "Select item" }}
                          mode={mode}
                          disabled={studyIsDisabled}
                        />
                      </>
                    )}
                  </Grid>
                  <Grid item xs={4}>
                    <GridActionButton
                      type={"delete"}
                      onClick={(e) => removeParameterHandler(e, item)}
                      disabled={studyIsDisabled}
                    />
                  </Grid>
                </React.Fragment>
              ))}
            <Grid item xs={12}>
              <Typography variant="h6">Custom Parameters</Typography>
            </Grid>
            <Grid item xs={6}>
              <FormText
                name="name"
                label="Name"
                value={values.name}
                onChange={handleInputChange}
                disabled={studyIsDisabled}
              />
            </Grid>
            <Grid item xs={5}>
              <FormText
                name="value"
                label="Value"
                value={values.value}
                onChange={handleInputChange}
                disabled={studyIsDisabled}
              />
            </Grid>
            <Grid item>
              <GridActionButton
                type={"add"}
                onClick={addCustomParameter}
                disabled={studyIsDisabled}
              />
            </Grid>
            {custom
              ?.filter(
                (item) =>
                  item?.outletReportCustomNameParameters?.name === "Other"
              )
              .map((item: IOutletReportCustomParameters, index) => (
                <React.Fragment key={item.id}>
                  <Grid item xs={6}>
                    <FormText
                      name={`${item.name}-${item.id}`}
                      label={item.name}
                      value={item.value1}
                      onChange={(e) => handleTextChange(e, item)}
                      disabled={studyIsDisabled}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <GridActionButton
                      type={"delete"}
                      onClick={(e) => removeParameterHandler(e, item)}
                      disabled={studyIsDisabled}
                    />
                  </Grid>
                </React.Fragment>
              ))}
          </Grid>
        </Grid>
      </Grid>
      <DialogMessagePopup
        title="Information"
        text="Are you certain you want to delete this parameter?"
        showPopup={showDeletePopup}
        setShowPopup={setShowDeletePopup}
        onSave={confirmDeleteParameterHandler}
        isSubmitting={false}
      />
    </>
  );
};

export default OutletReportParameters;
