import axios from "../utils/axios";

const put = async <T>(url: string, data: any) => {
  return axios.put<T>(url, data);
};

const commonService = {
  put,
};

export default commonService;
