import React from "react";
import { Button as MuiButton, Tooltip, Zoom } from "@mui/material";
import { CircularProgress } from "@mui/material";

interface ButtonProps {
  text: string;
  size?: "small" | "large" | "medium" | undefined;
  color?:
    | "inherit"
    | "primary"
    | "secondary"
    | "success"
    | "error"
    | "info"
    | "warning"
    | undefined;
  variant?: "text" | "contained" | "outlined" | undefined;
  onClick: (e: React.MouseEvent<HTMLElement>) => void;
  disabled?: boolean;
  [key: string]: any;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  fullWidth?: boolean;
  tooltip?: string;
  isSubmitting?: boolean;
}

const FormButton = (props: ButtonProps) => {
  const {
    text,
    size,
    color,
    variant,
    onClick,
    disabled,
    startIcon,
    fullWidth,
    endIcon,
    tooltip,
    isSubmitting,
    ...other
  } = props;

  return (
    <Tooltip TransitionComponent={Zoom} title={disabled ? "" : tooltip ?? ""}>
      <MuiButton
        component="span"
        fullWidth={fullWidth}
        variant={variant || "contained"}
        size={size || "large"}
        color={color || "primary"}
        onClick={onClick}
        disabled={disabled || isSubmitting}
        {...other}
        startIcon={isSubmitting ? <CircularProgress size="1rem" /> : startIcon}
        endIcon={endIcon}
      >
        {text}
      </MuiButton>
    </Tooltip>
  );
};

export default FormButton;
