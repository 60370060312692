import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import {
  Grid,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
  Typography,
  Card as MuiCard,
  CardContent,
  Tab,
  Tabs,
  Button,
} from "@mui/material";
import { spacing } from "@mui/system";
import SystemWalletSummaryFilter from "./SystemWalletSummaryFilter";
import {
  IWalletMetrics,
  SystemWalletSummaryFilterType,
} from "src/ts/interfaces";
import { Stats } from "src/components/graphs";
import { green, red } from "@mui/material/colors";
import { WalletService } from "src/services";
import { currencyformatter } from "src/utils/format";
import BarChart from "./BarChart";
import LineChart from "./LineChart";
import LineChartBoma from "./LineChartBoma";
import PartnerBalance from "./PartnerBalance";
import { useLog } from "src/hooks";

const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Card = styled(MuiCard)(spacing);

const SystemWalletSummary = () => {
  const { log } = useLog();
  const [filters, setFilters] = useState<SystemWalletSummaryFilterType | null>(
    null
  );
  const [refresh, setRefresh] = useState(false);
  const [dataSet, setDataSet] = useState<IWalletMetrics | null>(null);
  const [selectdTab, setSelectedTab] = useState(0);
  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  useEffect(() => {
    const getData = async (filters: SystemWalletSummaryFilterType) => {
      try {
        const res = await WalletService.getMetrics(filters);
        setDataSet(res.data);
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      }
    };

    if (filters !== null) getData(filters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh]);

  const searchHandler = (filterSelected: SystemWalletSummaryFilterType) => {
    setFilters(filterSelected);
    setRefresh(!refresh);
  };

  const getBomaUnsettled = () => {
    if (dataSet?.bomaUnsettled ?? 0 > (dataSet?.bomaMinimumBalance ?? 0))
      return dataSet?.bomaUnsettled ?? 0;
    else return 0;
  };

  const getTeamUnsettled = () => {
    if (dataSet?.teamUnsettled ?? 0 > (dataSet?.teamMinimumBalance ?? 0))
      return dataSet?.teamUnsettled ?? 0;
    else return 0;
  };

  return (
    <>
      <Helmet title="System Wallet Summary" />
      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            System Wallet Summary
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} to="/">
              Dashboard
            </Link>
            <Typography>System Wallet Summary</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item></Grid>
      </Grid>

      <Divider my={6} />

      <Card mb={6}>
        <CardContent>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <SystemWalletSummaryFilter onSearch={searchHandler} />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Grid container spacing={6}>
        <Grid item xs={12} sm={12} md={6} lg={4} xl>
          <Stats
            title="Total Purchases"
            amount={currencyformatter.format(dataSet?.totalPurchases ?? 0)}
            percentagecolor={green[500]}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={4} xl>
          <Stats
            title="Boma Funds"
            amount={currencyformatter.format(dataSet?.totalBoma ?? 0)}
            chip={`Unsettle: ${currencyformatter.format(getBomaUnsettled())}`}
            percentagecolor={red[500]}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={4} xl>
          <Stats
            title="Team Funds"
            amount={currencyformatter.format(dataSet?.totalTeam ?? 0)}
            chip={`Unsettle: ${currencyformatter.format(getTeamUnsettled())}`}
            percentagetext="+18%"
            percentagecolor={green[500]}
          />
        </Grid>
      </Grid>

      {/*  ----------------------------------------------------------------------------------------------- */}
      <Grid container spacing={6}>
        <Grid item xs={12} sm={12} md={6} lg={3} xl>
          <Stats
            title="SignUp"
            amount={currencyformatter.format(dataSet?.totalSignup ?? 0)}
            percentagecolor={green[500]}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={3} xl>
          <Stats
            title="Projects"
            amount={currencyformatter.format(dataSet?.totalProjects ?? 0)}
            percentagecolor={green[500]}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={3} xl>
          <Stats
            title="T&B Cert"
            amount={currencyformatter.format(dataSet?.totalTestAndBalance ?? 0)}
            percentagecolor={green[500]}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={3} xl>
          <Stats
            title="Energy Cert"
            amount={currencyformatter.format(dataSet?.totalEnergy ?? 0)}
            percentagecolor={green[500]}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={3} xl>
          <Stats
            title="Storage"
            amount={currencyformatter.format(dataSet?.totalStorage ?? 0)}
            percentagecolor={green[500]}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={3} xl>
          <Stats
            title="Others"
            amount={currencyformatter.format(dataSet?.totalOthers ?? 0)}
            percentagecolor={green[500]}
          />
        </Grid>
      </Grid>

      <Grid item xs={6}>
        <Tabs
          value={selectdTab}
          onChange={handleChangeTab}
          aria-label="basic tabs example"
        >
          <Tab label="Chart" id="0" />
          <Tab label="Purchases" id="1" />
          <Tab label="BOMA" id="2" />
          <Tab label="Team" id="3" />
        </Tabs>
      </Grid>
      <Divider my={0} />
      <Grid container spacing={6}>
        {selectdTab === 0 && (
          <Grid item xs={12} lg={12}>
            <BarChart
              arrayData={[
                dataSet?.totalSignup ?? 0,
                dataSet?.totalProjects ?? 0,
                dataSet?.totalTestAndBalance ?? 0,
                dataSet?.totalEnergy ?? 0,
                dataSet?.totalStorage ?? 0,
                dataSet?.totalOthers ?? 0,
                dataSet?.totalBoma ?? 0,
                dataSet?.totalTeam ?? 0,
              ]}
            />
          </Grid>
        )}
        {selectdTab === 1 && (
          <Grid item xs={12} lg={12}>
            <LineChart
              walletPurchasesResume={dataSet?.walletPurchasesResumeDTOs ?? []}
            />
          </Grid>
        )}
        {selectdTab === 2 && (
          <Grid item xs={12} lg={12}>
            <Grid container spacing={12}>
              <Grid item xs={12} sm={12} md={6} lg={3} xl>
                <Stats
                  title="Emergency Funds"
                  amount={
                    "Goal: " +
                    currencyformatter.format(dataSet?.bomaMinimumBalance ?? 0)
                  }
                  percentagecolor={green[500]}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={3} xl>
                <Stats
                  title="Emergency Funds"
                  amount={
                    "Current: " +
                    currencyformatter.format(
                      dataSet?.bomaUnsettled ??
                        0 > (dataSet?.bomaMinimumBalance ?? 0)
                        ? dataSet?.bomaMinimumBalance ?? 0
                        : dataSet?.bomaUnsettled ??
                          0 < (dataSet?.bomaMinimumBalance ?? 0)
                        ? dataSet?.bomaUnsettled ?? 0
                        : 0
                    )
                  }
                  percentagecolor={green[500]}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={3} xl>
                <Stats
                  title="Unsettled Funds"
                  amount={currencyformatter.format(
                    dataSet?.bomaUnsettled ??
                      0 > (dataSet?.bomaMinimumBalance ?? 0)
                      ? dataSet?.bomaUnsettled ?? 0
                      : dataSet?.bomaUnsettled ??
                        0 < (dataSet?.bomaMinimumBalance ?? 0)
                      ? 0
                      : 0
                  )}
                  percentagecolor={green[500]}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={3} xl>
                <Stats
                  title="In The Period"
                  amount={currencyformatter.format(dataSet?.totalBoma ?? 0)}
                  percentagecolor={green[500]}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl>
                <Button
                  fullWidth
                  variant="outlined"
                  color="primary"
                  sx={{
                    color: "black",
                    borderColor: "black",
                  }}
                >
                  <span style={{ fontWeight: "bold" }}>H&nbsp;</span> Settle
                  History
                </Button>
                <Divider my={4} />
                <Button
                  fullWidth
                  variant="outlined"
                  color="primary"
                  sx={{
                    color: "black",
                    borderColor: "black",
                  }}
                >
                  <span style={{ fontWeight: "bold" }}>$&nbsp;</span> Settle
                  Pending Funds
                </Button>
              </Grid>
            </Grid>
            <Grid item xs={12} lg={12}>
              <LineChartBoma
                walletBomaResume={dataSet?.walletBomaResumeDTOs ?? []}
              />
            </Grid>
          </Grid>
        )}
        {selectdTab === 3 && (
          <Grid item xs={12}>
            <PartnerBalance></PartnerBalance>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default SystemWalletSummary;
