import { IGasFuelTypeEnergyCost } from "../ts/interfaces/gasFuelTypeEnergyCost";
import axios from "../utils/axios";

const deleteGasCost = async (id: number) => {
  return axios.delete<any>(`GasFuelTypeEnergyCost?id=${id}`);
};

const getGasCost = async (id: number) => {
  return axios.get<IGasFuelTypeEnergyCost[]>(
    "GasFuelTypeEnergyCost/building/" + id
  );
};

const createGasCost = async (data: IGasFuelTypeEnergyCost) => {
  return axios.post<any>("GasFuelTypeEnergyCost/", data);
};

const gasFuelTypeEnergyCostService = {
  deleteGasCost,
  createGasCost,
  getGasCost,
};

export default gasFuelTypeEnergyCostService;
