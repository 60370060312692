import axios from "../utils/axios";
import {
  DocumentLibraryDto,
  IProjectDocumentLibrary,
  ISelectedUsers,
  IUploadDocumentLibraryFile,
} from "../ts/interfaces";

const getByProjectIdAndParentId = (projectId: Number, parentId?: number) => {
  return axios.get<DocumentLibraryDto>(
    `libraryDocument?projectId=${projectId}&parentId=${parentId}`
  );
};

const getById = (id: number) => {
  return axios.get<DocumentLibraryDto>(`libraryDocument/${id}`);
};

const createFolder = (folder: {
  name: string;
  parentId: number | null;
  projectId: number;
}) => {
  return axios.post("libraryDocument/folder", folder);
};

const uploadFile = async (file: IUploadDocumentLibraryFile) => {
  let bodyFormData = new FormData();
  bodyFormData.append("fileForUpload[0]", file.content);
  bodyFormData.append("key[type]", file.type);
  bodyFormData.append("key[projectId]", file.projectId);
  bodyFormData.append("key[parentId]", file.parentId);

  return await axios({
    method: "post",
    url: "/libraryDocument/document",
    data: bodyFormData,
    headers: { "Content-Type": "multipart/form-data" },
  });
};

const downloadUserFile = (documentId: number) => {
  return axios.get(`libraryDocument/file/${documentId}`, {
    responseType: "arraybuffer",
  });
};

const deleteFile = (documentId: number) => {
  return axios.delete(`libraryDocument/file/${documentId}`);
};

const deleteFolder = (documentId: number) => {
  return axios.delete(`libraryDocument/folder/${documentId}`);
};

const getPath = (documentId: number) => {
  return axios.get(`libraryDocument/${documentId}/path`);
};

const getLibraryDocumentSummary = async () => {
  return axios.get<IProjectDocumentLibrary[]>(
    `LibraryDocument/storageMetrics/`
  );
};

const send = async (itemId: number, emails: ISelectedUsers[]) => {
  return axios.post(
    `LibraryDocument/shareDocument/${itemId}/forwardProjectEmailsTeam`,
    emails
  );
};

const documentLibraryService = {
  getByProjectIdAndParentId,
  createFolder,
  getById,
  uploadFile,
  downloadUserFile,
  deleteFile,
  deleteFolder,
  getPath,
  getLibraryDocumentSummary,
  send,
};
export default documentLibraryService;
