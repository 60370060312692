import { ITooltip } from "src/ts/interfaces/tooltip";
import axios from "../utils/axios";

const getByCode = async (name: string) => {
  return axios.get<ITooltip>(`Tooltip/name?name=${name}`);
};

const getById = async (id: number) => {
  return axios.get<ITooltip>(`Tooltip?Id=${id}`);
};

const tooltipService = {
  getByCode,
  getById,
};

export default tooltipService;
