import {
  createContext,
  ReactNode,
  useEffect,
  useReducer,
  useState,
} from "react";
import { ICredentials, ICredentialsWithCode } from "../ts/interfaces";

import { JWTContextType, ActionMap, AuthState, AuthUser } from "../types/auth";
import { AuthorizationData } from "../types/authorizationData";
import axios from "../utils/axios";
import { isValidToken, setSession } from "../utils/jwt";
import AuthenticationService from "../services/authenticationService";
import techDailyLogService from "src/services/techDailyLogService";
import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";
import { useLog } from "src/hooks";
import useTheme from "src/hooks/useTheme";
import DialogMessagePopup from "src/components/DialogMessagePopup";
import { Typography } from "@mui/material";
import createTheme from "src/theme";

const INITIALIZE = "INITIALIZE";
const SIGN_IN = "SIGN_IN";
const SIGN_OUT = "SIGN_OUT";
const SIGN_UP = "SIGN_UP";
const REQUIRE_VERIFICATION = "REQUIRE_VERIFICATION";
const REQUIRE_ROLE_SELECTION = "REQUIRE_ROLE_SELECTION";
const USER_WRONG_PASSWORD = "USER_WRONG_PASSWORD";
const USER_EMAIL_NOT_CONFIRMED = "USER_EMAIL_NOT_CONFIRMED";
const USER_LOCKOUT = "USER_LOCKOUT";
const DEACTIVATED_COMPANY = "DEACTIVATED_COMPANY";
const DEACTIVATED_TRAINING = "DEACTIVATED_TRAINING";
const EVALUATION_INPROGRESS = "EVALUATION_INPROGRESS";

type AuthActionTypes = {
  [INITIALIZE]: {
    isAuthenticated: boolean;
    user: AuthUser;
    userName: string | null;
    password: string | null;
    requireVerification: boolean;
    step: number;
    token: string | null;
  };
  [SIGN_IN]: {
    user: AuthUser;
    userName: string | null;
    password: string | null;
    requireVerification: boolean;
    step: number;
    token: string | null;
  };
  [SIGN_OUT]: undefined;
  [SIGN_UP]: {
    user: AuthUser;
    userName: string | null;
    password: string | null;
    requireVerification: boolean;
    step: number;
    token: string | null;
  };
  [REQUIRE_VERIFICATION]: {
    isAuthenticated: boolean;
    user: AuthUser;
    userName: string | null;
    password: string | null;
    requireVerification: boolean;
    step: number;
    token: string | null;
    message: string;
    confirmIdentityBySms: boolean;
  };
  [REQUIRE_ROLE_SELECTION]: {
    isAuthenticated: boolean;
    user: AuthUser;
    userName: string | null;
    password: string | null;
    token: string | null;
  };
  [USER_WRONG_PASSWORD]: {
    message: string;
  };
  [USER_EMAIL_NOT_CONFIRMED]: {
    message: string;
    emailNotConfirmed: boolean;
  };
  [USER_LOCKOUT]: {
    message: string;
  };
  [DEACTIVATED_COMPANY]: {
    message: string;
  };
  [DEACTIVATED_TRAINING]: {
    deactivatedTraining: boolean;
    userName: string | null;
  };
};

const initialState: AuthState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
  userName: null,
  password: null,
  requireVerification: false,
  step: 1,
  token: null,
  message: "",
  emailNotConfirmed: false,
  confirmIdentityBySms: false,
  deactivatedTraining: false,
};

const JWTReducer = (
  state: AuthState,
  action: ActionMap<AuthActionTypes>[keyof ActionMap<AuthActionTypes>]
) => {
  switch (action.type) {
    case INITIALIZE:
      return {
        isAuthenticated: action.payload.isAuthenticated,
        isInitialized: true,
        user: action.payload.user,
        userName: action.payload.userName,
        password: action.payload.password,
        requireVerification: false,
        step: 1,
        token: action.payload.token,
        message: "",
        emailNotConfirmed: false,
        confirmIdentityBySms: false,
        deactivatedTraining: false,
      };

    case SIGN_IN:
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
        userName: action.payload.userName,
        password: action.payload.password,
        requireVerification: false,
        step: 1,
        token: action.payload.token,
      };

    case SIGN_OUT:
      return {
        ...state,
        isAuthenticated: false,
        user: null,
        userName: null,
        password: null,
        requireVerification: false,
        step: 1,
        token: null,
        message: "",
        emailNotConfirmed: false,
      };

    case SIGN_UP:
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
        requireVerification: false,
        step: 1,
      };

    case REQUIRE_VERIFICATION:
      return {
        ...state,
        isAuthenticated: false,
        user: action.payload.user,
        requireVerification: true,
        userName: action.payload.userName,
        password: action.payload.password,
        step: action.payload.step,
        message: action.payload.message,
        confirmIdentityBySms: action.payload.confirmIdentityBySms,
      };

    case REQUIRE_ROLE_SELECTION:
      return {
        ...state,
        isAuthenticated: false,
        user: action.payload.user,
        userName: action.payload.userName,
        password: action.payload.password,
        step: 3,
      };

    case USER_WRONG_PASSWORD:
      return {
        ...state,
        message: action.payload.message,
      };

    case USER_EMAIL_NOT_CONFIRMED:
      return {
        ...state,
        message: action.payload.message,
        emailNotConfirmed: action.payload.emailNotConfirmed,
      };
    case USER_LOCKOUT:
      return {
        ...state,
        message: action.payload.message,
      };

    case DEACTIVATED_COMPANY:
      return {
        ...state,
        message: action.payload.message,
      };
    case DEACTIVATED_TRAINING:
      return {
        ...state,
        deactivatedTraining: true,
        userName: action.payload.userName,
      };
    default:
      return state;
  }
};

const AuthContext = createContext<JWTContextType | null>(null);

function AuthProvider({ children }: { children: ReactNode }) {
  let is2faActivate = false;
  const [state, dispatch] = useReducer(JWTReducer, initialState);
  useEffect(() => {
    initialize();
  }, []);
  const [popUpEmailNotConfirmed, setPopUpEmailNotConfirmed] = useState(false);
  const { theme } = useTheme();
  const signIn = async (userName: string, password: string) => {
    try {
      const response = await AuthenticationService.signIn({
        userName,
        password,
        role: "",
      });
      const authorizationData = response.data;
      const { access_token, ...user } = authorizationData;

      is2faActivate = authorizationData.is2FAEnable;
      setSession(authorizationData);
      if (is2faActivate) {
        dispatch({
          type: REQUIRE_VERIFICATION,
          payload: {
            user,
            userName,
            password,
            requireVerification: true,
            isAuthenticated: false,
            step: 2,
            token: "",
            message: "",
            confirmIdentityBySms: false,
          },
        });
      } else if (user.numberOfRoles === 1) {
        dispatch({
          type: SIGN_IN,
          payload: {
            user,
            userName: null,
            password: null,
            requireVerification: false,
            step: 1,
            token: access_token,
          },
        });
      } else if (user.numberOfRoles > 1) {
        dispatch({
          type: REQUIRE_ROLE_SELECTION,
          payload: {
            user,
            isAuthenticated: false,
            userName,
            password,
            token: "",
          },
        });
      }
    } catch (error: any) {
      if (
        error?.message &&
        error?.message.includes("Multiple active session")
      ) {
        dispatch({
          type: REQUIRE_VERIFICATION,
          payload: {
            user: null,
            userName,
            password,
            requireVerification: true,
            isAuthenticated: false,
            step: 2,
            token: "",
            message: "",
            confirmIdentityBySms: error?.message.includes(
              "ConfirmIdentityBySms"
            ),
          },
        });
      } else if (
        error?.message &&
        error?.message === "The user name or password is incorrect."
      ) {
        dispatch({
          type: USER_WRONG_PASSWORD,
          payload: {
            message: "The user name or password is incorrect.",
          },
        });
      } else if (error?.message && error?.message === "Email not confirmed.") {
        dispatch({
          type: USER_EMAIL_NOT_CONFIRMED,
          payload: {
            message: "Email not confirmed.",
            emailNotConfirmed: true,
          },
        });
      } else if (
        error?.message &&
        error?.message.includes("multiple failed login attempts")
      ) {
        dispatch({
          type: USER_LOCKOUT,
          payload: {
            message: error?.message,
          },
        });
      } else if (
        error?.message &&
        (error?.message.includes(
          "The company was deactivated by an AMP administrator"
        ) ||
          error?.message.includes("account has been disabled") ||
          error?.message.includes(
            "Evaluation by Union Administrators is still in progress"
          ))
      ) {
        dispatch({
          type: DEACTIVATED_COMPANY,
          payload: {
            message: error?.message,
          },
        });
      } else if (
        error?.message &&
        error?.message.includes("Deactivated by System")
      ) {
        dispatch({
          type: DEACTIVATED_TRAINING,
          payload: {
            deactivatedTraining: true,
            userName: userName,
          },
        });
      }
    }
  };

  const initialize = () => {
    try {
      const authorizationData: AuthorizationData = JSON.parse(
        window.localStorage.getItem("authorizationData") ?? ""
      );

      if (authorizationData && isValidToken(authorizationData.access_token)) {
        setSession(authorizationData);

        //const response = await axios.get("/api/auth/my-account");
        const { access_token, ...user } = authorizationData;

        dispatch({
          type: INITIALIZE,
          payload: {
            isAuthenticated: true,
            user,
            userName: null,
            password: null,
            requireVerification: false,
            step: 1,
            token: access_token,
          },
        });
      } else {
        dispatch({
          type: INITIALIZE,
          payload: {
            isAuthenticated: false,
            user: null,
            userName: null,
            password: null,
            requireVerification: false,
            step: 1,
            token: "",
          },
        });
      }
    } catch (err) {
      dispatch({
        type: INITIALIZE,
        payload: {
          isAuthenticated: false,
          user: null,
          userName: null,
          password: null,
          requireVerification: false,
          step: 1,
          token: "",
        },
      });
    }
  };

  const signOut = async () => {
    try {
      techDailyLogService.close();
    } finally {
      await AuthenticationService.signOut();
      setSession(null);
      localStorage.removeItem("authorizationData");
      dispatch({ type: SIGN_OUT });
    }
  };

  const signOutIdle = async (closeDailyLog: boolean) => {
    try {
      if (closeDailyLog) {
        techDailyLogService.close(true);
      }
      AuthenticationService.signOut();
    } finally {
      setSession(null);
      localStorage.removeItem("authorizationData");
      dispatch({ type: SIGN_OUT });
    }
  };

  const signOutMultisession = async () => {
    setSession(null);
    dispatch({ type: SIGN_OUT });
  };

  const signUp = async (
    email: string,
    password: string,
    firstName: string,
    lastName: string
  ) => {
    const response = await axios.post("/api/auth/sign-up", {
      email,
      password,
      firstName,
      lastName,
    });
    const { access_token, ...user } = response.data;

    window.localStorage.setItem("accessToken", access_token);
    dispatch({
      type: SIGN_UP,
      payload: {
        user,
        userName: null,
        password: null,
        requireVerification: false,
        step: 1,
        token: access_token,
      },
    });
  };

  const { log } = useLog();
  const verifyCode = async (credentialsWithCode: ICredentialsWithCode) => {
    try {
      const response = await AuthenticationService.verifyCode(
        credentialsWithCode
      );

      const authorizationData = response.data;
      const { access_token, ...user } = authorizationData;
      setSession(authorizationData);
      if (user.numberOfRoles === 1) {
        dispatch({
          type: SIGN_IN,
          payload: {
            user,
            userName: null,
            password: null,
            requireVerification: false,
            step: 0,
            token: access_token,
          },
        });
      } else {
        dispatch({
          type: REQUIRE_ROLE_SELECTION,
          payload: {
            user,
            isAuthenticated: false,
            userName: credentialsWithCode.userName,
            password: credentialsWithCode.password,
            token: "",
          },
        });
      }
      return true;
    } catch (error: any) {
      log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      return false;
    } finally {
    }
  };

  const signInWithRole = async (credentials: ICredentials) => {
    try {
      const response = await AuthenticationService.signInWithRole(credentials);
      const authorizationData = response.data;
      const { access_token, ...user } = authorizationData;
      setSession(authorizationData);
      dispatch({
        type: SIGN_IN,
        payload: {
          user,
          userName: null,
          password: null,
          requireVerification: false,
          step: 0,
          token: access_token,
        },
      });
    } catch (error: any) {
      log.error(error?.message?.exceptionMessage ?? "Something went wrong");
    }
  };

  const resetPassword = async (email: string) => {
    try {
      await axios.post(
        `Account/ResetPassword?userEmail=${email}&newRoute=true`
      );
      log.success(
        "An email has been sent. Please follow the link included and set a new password."
      );
    } catch (error: any) {
      if (error?.message === "Your email confirmation is not yet completed.") {
        setPopUpEmailNotConfirmed(true);
      } else log.error(error?.message ?? "Something went wrong");
    }
  };

  return (
    <>
      <AuthContext.Provider
        value={{
          ...state,
          method: "jwt",
          initialize,
          signIn,
          signOut,
          signOutMultisession,
          signOutIdle,
          signUp,
          resetPassword,
          verifyCode,
          signInWithRole,
        }}
      >
        {children}
      </AuthContext.Provider>
      <MuiThemeProvider theme={createTheme(theme)}>
        <DialogMessagePopup
          title={"Information"}
          text={
            <>
              <Typography>
                <b>Your email confirmation is not yet completed.</b>
              </Typography>
              <br />
              <Typography>
                <b>
                  Please complete the process by clicking the link included in
                  the email sent when your account was created or contact your
                  company administrator to receive a new confirmation email.
                </b>
              </Typography>
            </>
          }
          showPopup={popUpEmailNotConfirmed}
          setShowPopup={setPopUpEmailNotConfirmed}
          isSubmitting={false}
          hideAccept={true}
          cancelTextButton="Close"
        ></DialogMessagePopup>
      </MuiThemeProvider>
    </>
  );
}

export { AuthContext, AuthProvider };
