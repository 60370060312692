import axios from "../utils/axios";
import { IKeyValue, IState } from "../ts/interfaces";

const getById = (stateId: number) => {
  return axios.get<IState>(`State/${stateId}`);
};

const getAll = (countryId: number) => {
  return axios.get(`States/${countryId}`);
};

//try to do this generic
const getKeyValues = (countryId: number) => {
  return new Promise<IKeyValue<number, string>[]>((resolve, reject) => {
    axios
      .get<Array<IState>>(`States/${countryId}`)
      .then((response) => {
        const keyValuePair: IKeyValue<number, string>[] = response.data.map(
          (state) => {
            return {
              key: state.id!,
              value: state.name,
            };
          }
        );

        resolve(keyValuePair);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const StateService = {
  getById,
  getAll,
  getKeyValues,
};

export default StateService;
