import { Grid, Stack, Typography } from "@mui/material";
import { useState } from "react";
import {
  FormCheckBox,
  FormNumeric2,
  FormSelect,
  FormSelectState,
  FormText,
} from "src/components/formControls";
import { ADDUPDATEASSET } from "src/constants";
import { Form } from "src/hooks";
import { ICountry, IKeyValue } from "src/ts/interfaces";
import { IAssets } from "src/ts/interfaces/companyAssets";

interface IEdiiUpdateGeneralVehiclesProps {
  values: IAssets;
  setValues: (val: IAssets) => void;
  errors: Record<string, string>;
  handleInputChange: (e: any) => void;
  isLoading: boolean;
  countriesKeyValue: IKeyValue<number, string>[];
  assetType: string;
  tabValue: number;
  handleInputChangeCountry: (e: any) => void;
  selectedCountry: ICountry | null;
  readOnly: boolean;
}

const EditUpdateFormVehicles = ({
  values,
  setValues,
  errors,
  handleInputChange,
  isLoading,
  countriesKeyValue,
  assetType,
  tabValue,
  handleInputChangeCountry,
  selectedCountry,
  readOnly,
}: IEdiiUpdateGeneralVehiclesProps) => {
  const [isStateLoading, setIsStateLoading] = useState(false);
  const row = 6;

  return (
    <>
      <Form>
        {tabValue === ADDUPDATEASSET.GENERAL && assetType === "Vehicle" && (
          <Grid container spacing={5}>
            <Grid item xs={row}>
              <FormText
                name="make"
                label={`Make`}
                value={values?.vehicle?.make}
                onChange={handleInputChange}
                error={errors.make}
                showSkeleton={isLoading}
                readOnlyText={readOnly}
              />
            </Grid>
            <Grid item xs={row}>
              <FormText
                name="model"
                label={`Model`}
                value={values?.vehicle?.model}
                onChange={handleInputChange}
                error={errors.model}
                showSkeleton={isLoading}
                readOnlyText={readOnly}
              />
            </Grid>
            <Grid item xs={row}>
              <FormNumeric2
                name="year"
                label="Year"
                value={values?.vehicle?.year}
                onChange={handleInputChange}
                error={errors.year}
                showSkeleton={isLoading}
                disabled={readOnly}
              />
            </Grid>
            <Grid item xs={row}>
              <FormText
                name="vehicleIdNumber"
                label="Vehicle Identification Number"
                value={values?.vehicle?.vehicleIdNumber}
                onChange={handleInputChange}
                error={errors.vehicleIdNumber}
                placeholder=""
                showSkeleton={isLoading}
                readOnlyText={readOnly}
              />
            </Grid>
            <Grid item xs={row}>
              <FormText
                name="licensePlate"
                label="License Plate"
                value={values?.vehicle?.licensePlate}
                onChange={handleInputChange}
                error={errors.licensePlate}
                placeholder=""
                showSkeleton={isLoading}
                readOnlyText={readOnly}
              />
            </Grid>
            <Grid item xs={row}>
              <FormSelect
                name="countryOfRegistrationId"
                label="Country"
                value={values.vehicle?.countryOfRegistrationId ?? ""}
                onChange={handleInputChange}
                options={countriesKeyValue}
                error={errors.countryOfRegistrationId}
                showSkeleton={isLoading}
                defaultValue={{ key: -1, value: "Select a country" }}
                disabled={readOnly}
              />
            </Grid>
            <Grid item xs={row}>
              <FormSelectState
                stateId={values?.vehicle?.stateId?.toString() ?? ""}
                stateName={values?.vehicle?.stateName ?? ""}
                countryId={Number(values.vehicle?.countryOfRegistrationId)}
                errorsStateId={errors.stateId}
                errorsStateName={errors.stateName}
                onChange={handleInputChange}
                isLoading={(value: boolean) => setIsStateLoading(value)}
                showSkeleton={isLoading}
                disable={readOnly}
              />
            </Grid>
            <Grid item xs={row}>
              <FormNumeric2
                name="firstDataEntryMiles"
                label="First Data Entry Miles"
                value={values?.vehicle?.firstDataEntryMiles}
                onChange={handleInputChange}
                error={errors.firstDataEntryMiles}
                placeholder=""
                showSkeleton={isLoading}
                disabled={readOnly}
                thousandSeparator={true}
              />
            </Grid>
            <Grid item xs={row}>
              <FormText
                name="keylessCode"
                label="Keyless Code"
                value={values?.vehicle?.keylessCode}
                onChange={handleInputChange}
                error={errors.keylessCode}
                placeholder=""
                showSkeleton={isLoading}
                readOnlyText={readOnly}
              />
            </Grid>
            <Grid item xs={row}>
              <FormText
                name="optFuelBurnRate"
                label="Optimal Fuel Burn Rate"
                value={values?.vehicle?.optFuelBurnRate}
                onChange={handleInputChange}
                error={errors.optFuelBurnRate}
                placeholder=""
                showSkeleton={isLoading}
                readOnlyText={readOnly}
              />
            </Grid>
            <Grid item xs={row}>
              <FormText
                name="keysIssuedToTechnician"
                label="Keys Issued To Technician"
                value={values?.vehicle?.keysIssuedToTechnician}
                onChange={handleInputChange}
                error={errors.keysIssuedToTechnician}
                placeholder=""
                showSkeleton={isLoading}
                readOnlyText={readOnly}
              />
            </Grid>
            <Grid item xs={row}>
              <FormText
                name="insurancePolicyNumber"
                label="Insurance Policy Number"
                value={values?.vehicle?.insurancePolicyNumber}
                onChange={handleInputChange}
                error={errors.insurancePolicyNumber}
                placeholder=""
                showSkeleton={isLoading}
                readOnlyText={readOnly}
              />
            </Grid>
            <Grid item xs={row}>
              <FormText
                name="economicNumber"
                label="Economic Number"
                value={values?.vehicle?.economicNumber}
                onChange={handleInputChange}
                error={errors.economicNumber}
                placeholder=""
                showSkeleton={isLoading}
                readOnlyText={readOnly}
              />
            </Grid>
            <Grid item xs={row}>
              <Stack direction="row">
                <Typography m={2}>
                  <b> Assign to</b>
                </Typography>
                <FormCheckBox
                  label="Office/Shop"
                  name={""}
                  value={values.officeShop}
                  disabled={true}
                />
              </Stack>
            </Grid>
          </Grid>
        )}
      </Form>
    </>
  );
};

export default EditUpdateFormVehicles;
