import {
  CardContent,
  Grid,
  Card as MuiCard,
  Divider as MuiDivider,
} from "@mui/material";
import { spacing } from "@mui/system";
import styled from "@emotion/styled";
import { useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import HeaderPage from "src/components/page/HeaderPage";
import { useAsyncQuery, useAuth, useEffectOnce, useLog } from "src/hooks";
import chillerService from "src/services/study/chillerService";
import {
  IChillerWeigtedTest,
  IChillerWeigtedTestItem,
  IReportType,
} from "src/ts/interfaces";
import { Mode } from "src/ts/types";
import StudyWizard from "../../components/StudyWizard";
import { FormCheckSwitch } from "src/components/formControls";
import { FooterButtons } from "../../components/FooterButtons";
import { steps, studyTypeCode } from "../const";
import projectReportService from "src/services/study/projectReportService";
import { IProjectReport } from "src/ts/interfaces/project/projectDto";
import reportTypeService from "src/services/study/reportTypeService";
import studiesHistoryService from "src/services/study/studiesHistoryService";
import useChillerStudy from "../hooks/useChillerStudy";
import chillerWeigtedTestService from "src/services/study/chillerWeigtedTestService";
import ChillerWeigtedTestsList from "./components/ChillerWeigtedTestsList";
import ChillerWeigtedTestItemGrid from "./components/ChillerWeigtedTestItemGrid";
import HeaderStudyPage from "src/components/page/HeaderStudyPage";

const Divider = styled(MuiDivider)(spacing);
const Card = styled(MuiCard)(spacing);
const activeStep = 4;

const ChillerStep5 = () => {
  const { user } = useAuth();
  const params = useParams<{ id: string }>();
  const id = parseInt(params?.id === undefined ? "0" : params?.id);
  let navigate = useNavigate();
  const { log } = useLog();

  const [reportType, setReportType] = useState<IReportType | null>();

  const { execute: executeReport, data: dataReport } =
    useAsyncQuery<IProjectReport>(projectReportService.report);

  const { chillerValues, setChillerValues, handleChillerInputChange } =
    useChillerStudy();

  const [isLoading, setIsLoading] = useState(false);
  const [chillerWeigtedTests, setChillerWeigtedTests] = useState<
    IChillerWeigtedTest[]
  >([]);
  const [selectedChillerWeigtedTest, setSelectedChillerWeigtedTest] =
    useState<IChillerWeigtedTest | null>(null);

  const [items, setItems] = useState<IChillerWeigtedTestItem[]>([]);

  useEffectOnce(() => {
    const getData = async () => {
      try {
        setIsLoading(true);
        const chillerRes = await chillerService.getById(id);
        setChillerValues(chillerRes.data);

        const weigtedTests = await chillerWeigtedTestService.getByChillerId(id);

        setChillerWeigtedTests(weigtedTests.data);

        if (weigtedTests.data.length > 0) {
          setSelectedChillerWeigtedTest(weigtedTests.data[0]);
          const itemsRes =
            await chillerWeigtedTestService.getChillerWeigtedTestItem(
              weigtedTests.data[0].id
            );
          setItems(itemsRes.data);
        }

        const reportTypeRes = await reportTypeService.getReportTypeByCode(
          studyTypeCode
        );
        setReportType(reportTypeRes.data);

        await executeReport(id, reportTypeRes.data.code);
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      } finally {
        setIsLoading(false);
      }
    };
    if (id > 0) getData();
  });

  const mode: Mode = chillerValues.isComplete ? "read" : "read&Write";

  const disableStudy = useMemo(() => {
    return (
      chillerValues?.isComplete ||
      (dataReport?.isInUse && dataReport?.isInUseById !== user?.userId) ||
      dataReport?.isPartOfProject === false
    );
  }, [
    dataReport?.isInUse,
    dataReport?.isInUseById,
    dataReport?.isPartOfProject,
    user?.userId,
    chillerValues?.isComplete,
  ]);

  const saveHandler = async () => {
    //if (!validate()) return;

    if (!disableStudy) {
      await saveStudy();
    }
  };

  const previousStepHandler = async () => {
    if (!disableStudy) {
      await saveStudy();
    }
    const route = `/studies/Chiller/step6/${id}`;
    navigate(route);
  };

  const nextStepHandler = async () => {
    if (!disableStudy) {
      await saveStudy();
    }
    const route = `/studies/Chiller/step4/${id}`;
    navigate(route);
  };

  const saveStudy = async () => {
    try {
      await chillerService.update(chillerValues.id, chillerValues);
      await studiesHistoryService.save(
        dataReport.projectId,
        dataReport.reportTypeId,
        id,
        "saved"
      );
      await chillerWeigtedTestService.updateChillerWeigtedTests(
        chillerWeigtedTests
      );
      const updateItemPromises = items.map(
        async (items) =>
          await chillerWeigtedTestService.updateChillerWeigtedTestItem(items)
      );
      await Promise.all(updateItemPromises);
      log.info("Report was save");
    } catch (error: any) {
      log.error(error?.message?.exceptionMessage ?? "Something went wrong");
    } finally {
      //setIsSubmitting(false);
    }
  };

  const selectedItemHandler = async (
    chillerWeigtedTest: IChillerWeigtedTest
  ) => {
    if (!disableStudy) {
      await chillerWeigtedTestService.updateChillerWeigtedTest(
        chillerWeigtedTest
      );
      await Promise.all(
        items.map(
          async (item) =>
            await chillerWeigtedTestService.updateChillerWeigtedTestItem(item)
        )
      );
      chillerWeigtedTestService.updateChillerWeigtedTest(chillerWeigtedTest);
    }

    setSelectedChillerWeigtedTest(chillerWeigtedTest);

    const itemsRes = await chillerWeigtedTestService.getChillerWeigtedTestItem(
      chillerWeigtedTest.id
    );
    setItems(itemsRes.data);
  };

  return (
    <>
      <HeaderStudyPage
        headerStudiesPage={{
          code: studyTypeCode,
          system: chillerValues.system,
          id: id,
        }}
        parentText="Project"
        parentLink="/"
      />
      <Divider my={6} />

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <StudyWizard
            steps={steps}
            activeStep={activeStep}
            projectId={dataReport?.projectId}
            reportId={dataReport?.reportId}
            reportTypeId={dataReport?.reportTypeId}
          />
        </Grid>
        <Grid item xs={12}>
          <Card mb={1}>
            <CardContent>
              <Grid container spacing={5}>
                <Grid item xs={12}>
                  <FormCheckSwitch
                    label="Condenser/Tower Sound Pressure Testing"
                    name="chillerSoundPressureTesting"
                    value={chillerValues.chillerSoundPressureTesting}
                    onChange={handleChillerInputChange}
                    mode={mode}
                    disabled={disableStudy}
                    showSkeleton={isLoading}
                  />
                </Grid>
                {chillerValues.chillerSoundPressureTesting && (
                  <>
                    <Grid item xs={12}>
                      <ChillerWeigtedTestsList
                        chillerWeigtedTests={chillerWeigtedTests}
                        selectedChillerWeigtedTest={selectedChillerWeigtedTest}
                        selectedItemHandler={selectedItemHandler}
                        setChillerWeigtedTests={setChillerWeigtedTests}
                        disabledStudy={false}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <ChillerWeigtedTestItemGrid
                        items={items}
                        setItems={setItems}
                        dataReport={dataReport}
                        selectedChillerWeigtedTest={selectedChillerWeigtedTest}
                        chillerWeigtedTests={chillerWeigtedTests}
                        mode={mode}
                        disableStudy={disableStudy}
                        isLoading={isLoading}
                      />
                    </Grid>
                  </>
                )}
                <Grid item xs={12}>
                  <FooterButtons
                    activeStep={activeStep}
                    stepsCount={steps.length}
                    projectId={dataReport?.projectId}
                    companyId={user?.companyId as number}
                    isSaving={false}
                    disabled={false}
                    reportName={reportType?.name ?? ""}
                    saveHandler={saveHandler}
                    nextStepHandler={previousStepHandler}
                    previousStepHandler={nextStepHandler}
                    hideSaveButton={disableStudy}
                    isComplete={chillerValues.isComplete}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default ChillerStep5;
