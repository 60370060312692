import Popup from "src/components/Popup";
import { FormAcceptButton, FormButton } from "src/components/formControls";
import { Stack, Typography } from "@mui/material";

interface InformationProps {
  informationShowPopUp: boolean;
  setInformationShowPopUp: (val: boolean) => void;
  handlerAccept: (e: any) => void;
}

export default function InformationPopUp({
  informationShowPopUp,
  setInformationShowPopUp,
  handlerAccept,
}: InformationProps) {
  return (
    <>
      <Popup
        title={"Information"}
        openPopup={informationShowPopUp}
        setOpenPopup={setInformationShowPopUp}
        onClose={() => {
          setInformationShowPopUp(false);
        }}
        size="sm"
      >
        <>
          <Typography>
            There is an additional tab requesting important information about
            Equipment's purchase. Are you finished creating the test Equipment?
          </Typography>
        </>
        <Stack direction="row" spacing={3}>
          <FormButton
            size="small"
            text="Close"
            variant="outlined"
            onClick={() => {
              setInformationShowPopUp(false);
            }}
          />
          <FormAcceptButton
            size="small"
            text="Accept"
            onClick={handlerAccept}
            isSubmitting={false}
          />
        </Stack>
      </Popup>
    </>
  );
}
