import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import {
  CardContent,
  CssBaseline,
  Grid,
  Link,
  Card as MuiCard,
  Typography,
  Divider as MuiDivider,
} from "@mui/material";
import { useAsyncMutation, useAsyncQuery, useTheme } from "src/hooks";
import { useParams } from "react-router-dom";
import GlobalStyle from "src/components/GlobalStyle";
import { Box, spacing } from "@mui/system";
import { Helmet } from "react-helmet-async";
import teamMemberServices from "src/services/teamMemberServices";
import { FormCheckBox, FormSaveButton } from "src/components/formControls";
import { THEMES } from "src/constants";
import { ITeamMembersTechnician } from "src/ts/interfaces/teamMembers";
const Card = styled(MuiCard)(spacing);
const Root = styled.div`
  max-width: 70%;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  display: flex;
  min-height: 100%;
  flex-direction: column;
`;

function ConfirmTeamMember() {
  const { theme } = useTheme();
  const Divider = styled(MuiDivider)(spacing);
  let { code } = useParams<{ code: string }>();
  const [isTermsAndConditionsAccepted, setIsTermsAndConditionsAccepted] =
    useState(false);
  const { execute, data } = useAsyncQuery<ITeamMembersTechnician>(
    teamMemberServices.getByCode,
    {
      hideSuccessfulMessage: true,
    }
  );
  const { execute: execiteSubscribe } = useAsyncMutation(
    teamMemberServices.confirmByCode,
    {
      successfulMessage: "Subscription successfully",
      onSuccess: async () => {
        await execute(code);
      },
    }
  );
  useEffect(() => {
    const data = async () => {
      await execute(code);
    };

    data();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubscribe = async () => {
    await execiteSubscribe(code);
  };

  return (
    <Root>
      <CssBaseline />
      <GlobalStyle />
      <Grid container spacing={2} pt={10}>
        <Grid item xs={3}></Grid>
        <Grid item xs={6}>
          <Card mb={1}>
            <CardContent>
              <Helmet title="Confirm" />
              <Link href="https://www.auditmaster.pro/" target="_blank">
                <Box
                  component="img"
                  alt="Logo AMP"
                  src={
                    THEMES.DARK === theme
                      ? "/static/img/AMPWebLogoVectors.svg"
                      : "/static/img/AMPWebLogoVectorsLight.svg"
                  }
                  sx={{
                    marginLeft: "25%",
                    marginRight: "25%",
                    width: "50%",
                    marginBottom: "20px",
                  }}
                />
              </Link>
              <Divider mb={4} />
              {data?.isTermsAndConditionsAccepted && (
                <Typography variant="h4" textAlign={"center"}>
                  You are already subscribed.
                </Typography>
              )}
              {!data?.isTermsAndConditionsAccepted && (
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography>Your email address: {data?.email}</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography>
                      You're being asked to "subscribe" and be part of a
                      specific AMP project correspondence(s).
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography>
                      Do you want to subscribe to AMP's project notification?
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <FormCheckBox
                      name="isTermsAndConditionsAccepted"
                      label={
                        <Typography>
                          <Typography display={"inline"}>
                            I understand and agree to{" "}
                          </Typography>
                          <Link
                            href="https://auditmaster.pro/legal?id=privacy_statement"
                            target="_blank"
                          >
                            AMP's Privacy Policy and Terms of Service
                          </Link>
                        </Typography>
                      }
                      value={isTermsAndConditionsAccepted}
                      onChange={() => {
                        setIsTermsAndConditionsAccepted(
                          !isTermsAndConditionsAccepted
                        );
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} textAlign={"center"}>
                    <Divider mb={4} />
                    <FormSaveButton
                      onClick={handleSubscribe}
                      isSubmitting={false}
                      text="Yes, Subscribe"
                      disabled={!isTermsAndConditionsAccepted}
                    ></FormSaveButton>
                  </Grid>
                </Grid>
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Root>
  );
}

export default ConfirmTeamMember;
