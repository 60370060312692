import { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import SubscriptionService from "src/services/subscriptionService";
import { MetaData, keyValuePrice } from "src/ts/interfaces/subscription";
import { IKeyValue } from "src/ts/interfaces";
import {
  CardContent,
  Grid,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  Typography,
  Tabs,
  Tab,
} from "@mui/material";
import { spacing } from "@mui/system";
import BuildingProfessional from "./BuildingProfessional";
import HVACContractor from "./HVACContractor";
import OnlineSoftware from "./OnlieSoftware";
import systemCostCatalogService from "src/services/systemCostCatalogService";
import { useLog } from "src/hooks";

const Card = styled(MuiCard)(spacing);
const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const SubscriptionDetail = () => {
  const { log } = useLog();
  const [value, setValue] = useState(0);
  const [metaData, setMetaData] = useState<MetaData[]>([]);
  const [refresh, setRefresh] = useState(false);
  const [keyValueData, setKeyValueData] = useState<keyValuePrice>();
  const [keyValue, setKeyValue] = useState<IKeyValue<number, string>[]>([]);
  useEffect(() => {
    const getMetaData = async () => {
      try {
        const response = await SubscriptionService.getMetaData();
        const responseData = await systemCostCatalogService.getKeyValuesData();
        const responseValue = await systemCostCatalogService.getKeyValuePrice();

        setKeyValue(responseValue);
        setMetaData(response.data);
        setKeyValueData(responseData.data);
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      }
    };
    getMetaData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!refresh) return;
    const getMetaData = async () => {
      try {
        const response = await SubscriptionService.getMetaData();
        setMetaData(response.data);
        setRefresh(false);
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      }
    };
    getMetaData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <>
      <Helmet title="Pricing" />
      <Typography variant="h3" gutterBottom display="inline">
        Subscription Detail Preview
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/">
          Dashboard
        </Link>
        <Link component={NavLink} to="/">
          Pages
        </Link>
        <Typography>Pricing</Typography>
      </Breadcrumbs>

      <Divider my={6} />
      <Card mb={6}>
        <CardContent>
          <Grid
            container
            spacing={10}
            sx={{ borderBottom: 1, borderColor: "divider", marginBottom: 5 }}
          >
            <Grid item xs={12}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                <Tab label="BUILDING PROFESSIONALS" id="0" />
                <Tab label="HVAC CONTRACTORS" id="1" />
                <Tab
                  label="ONLINE SOFTWARE DEMONSTRATION & LIMITED TEST TRAINING"
                  id="2"
                />
              </Tabs>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            {value === 0 && metaData.length !== 0 ? (
              <BuildingProfessional
                metaData={metaData}
                setRefresh={setRefresh}
                keyValueData={keyValueData}
                keyValue={keyValue}
              />
            ) : null}
            {value === 1 && metaData.length !== 0 ? (
              <HVACContractor
                metaData={metaData}
                setRefresh={setRefresh}
                keyValueData={keyValueData}
                keyValue={keyValue}
              />
            ) : null}
            {value === 2 && metaData.length !== 0 ? (
              <OnlineSoftware
                metaData={metaData}
                setRefresh={setRefresh}
                keyValueData={keyValueData}
                keyValue={keyValue}
              />
            ) : null}
          </Grid>
        </CardContent>
      </Card>
      {}
    </>
  );
};

export default SubscriptionDetail;
