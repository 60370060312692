import axios from "src/utils/axios";
import { IKeyValue } from "src/ts/interfaces";

const getKeyValues = () => {
  return new Promise<IKeyValue<number, string>[]>((resolve, reject) => {
    axios
      .get<Array<any>>(`BuildingType`)
      .then((response) => {
        const keyValuePair: IKeyValue<number, string>[] = response.data.map(
          (item) => {
            return {
              key: item.id!,
              value: item.name,
            };
          }
        );

        resolve(keyValuePair);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const buildingType = { getKeyValues };
export default buildingType;
