import React from "react";
import styled from "@emotion/styled";
import { Theme, withTheme } from "@emotion/react";
import { Bar } from "react-chartjs-2";
import { MoreVertical } from "react-feather";
import { Card, CardContent, CardHeader, IconButton } from "@mui/material";

const ChartWrapper = styled.div`
  height: 320px;
  width: 100%;
`;

interface Props {
  theme: Theme & { palette: any };
  arrayData: number[];
}

const UserBarChart = ({ theme, arrayData }: Props) => {
  const firstDatasetColor = theme.palette.secondary.main;

  const data = {
    labels: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    datasets: [
      {
        label: "Users",
        backgroundColor: firstDatasetColor,
        borderColor: firstDatasetColor,
        hoverBackgroundColor: firstDatasetColor,
        hoverBorderColor: firstDatasetColor,
        data: arrayData,
        barPercentage: 0.4,
        categoryPercentage: 0.5,
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      y: {
        grid: {
          display: false,
        },
        stacked: true,
      },
      x: {
        stacked: true,
        grid: {
          color: "transparent",
        },
      },
    },
  };

  return (
    <Card>
      <CardHeader
        action={
          <IconButton aria-label="settings" size="large">
            <MoreVertical />
          </IconButton>
        }
        title="Users / Month"
      />

      <CardContent>
        <ChartWrapper>
          <Bar data={data} options={options} />
        </ChartWrapper>
      </CardContent>
    </Card>
  );
};

export default withTheme(UserBarChart);
