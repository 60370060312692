import { useState } from "react";
import { Grid, Divider as MuiDivider, Typography } from "@mui/material";
import styled from "@emotion/styled";
import { spacing } from "@mui/system";
import { TreeItem } from "@mui/x-tree-view/TreeItem";
import { FormText } from "src/components/formControls";
import { ICalculator } from "src/ts/interfaces/calculator";
const Divider = styled(MuiDivider)(spacing);

interface CalculatorTreeViewProps {
  formulas: ICalculator[];
}

export default function CalculatorTreeView({
  formulas,
}: CalculatorTreeViewProps) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [calc, setCalc] = useState<ICalculator>();

  const handleCalculateFormula = (e: any, item: ICalculator, index: number) => {
    item.input[index].value = e.target.value;
    item.output = item?.calculation?.(item.input ?? []) ?? "";
    setCalc({ ...item });
  };

  return (
    <>
      {formulas.map((item, key) => {
        return (
          <TreeItem
            nodeId={key.toString()}
            label={item.name}
            sx={{
              p: 2,
            }}
          >
            {item.subItems?.map((subItem, subKey) => {
              return (
                <>
                  <Divider pt={2} />
                  <TreeItem
                    nodeId={`${key.toString()}.${subKey.toString()}`}
                    label={subItem.name}
                    sx={{
                      p: 2,
                    }}
                  >
                    {subItem.subItems?.map((subSubItem, subSubKey) => {
                      return (
                        <>
                          <Divider pt={2} />
                          <TreeItem
                            nodeId={`${key.toString()}.${subKey.toString()}.${subSubKey.toString()}`}
                            label={subSubItem.name}
                            sx={{
                              p: 2,
                            }}
                          >
                            <Grid container p={1}>
                              {subSubItem.input?.map((inputs, keyInputs) => {
                                return (
                                  <Grid item md={12} p={1}>
                                    <FormText
                                      name={inputs.name}
                                      label={inputs.name}
                                      value={inputs.value}
                                      type="number"
                                      onChange={(e) => {
                                        handleCalculateFormula(
                                          e,
                                          subSubItem,
                                          keyInputs
                                        );
                                      }}
                                    />
                                  </Grid>
                                );
                              })}
                              <Grid item md={12}>
                                <Typography
                                  variant="subtitle2"
                                  sx={{ display: "inline" }}
                                >
                                  {subSubItem.answer ?? "Result ="}{" "}
                                </Typography>
                                <Typography sx={{ display: "inline" }}>
                                  {subSubItem.output === "NaN"
                                    ? ""
                                    : subSubItem.output}
                                </Typography>
                              </Grid>
                            </Grid>
                          </TreeItem>
                        </>
                      );
                    })}
                  </TreeItem>
                </>
              );
            })}
          </TreeItem>
        );
      })}
    </>
  );
}
