import axios from "src/utils/axios";
import { IHeadSheetGeneralDataDTO } from "src/ts/interfaces";

const getById = (id: number) => {
  return axios.get<IHeadSheetGeneralDataDTO>(
    `HeadSheetGeneralData/report/${id}`
  );
};

const update = (id: number, headSheet: IHeadSheetGeneralDataDTO) => {
  return axios.put<IHeadSheetGeneralDataDTO>(
    `HeadSheetGeneralData/${id}`,
    headSheet
  );
};

const headSheetGeneralDataService = {
  getById,
  update,
};

export default headSheetGeneralDataService;
