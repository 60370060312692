import axios from "src/utils/axios";
import { IKeyValue } from "src/ts/interfaces";
import { ICompressorManufacturer } from "src/ts/interfaces/catalogs";

const getKeyValues = () => {
  return new Promise<{ data: IKeyValue<number, string>[] }>(
    (resolve, reject) => {
      axios
        .get<Array<ICompressorManufacturer>>("/CompressorManufacturer")
        .then((response) => {
          const sorted = response.data.sort((a, b) =>
            a.orderPosition < b.orderPosition ? -1 : 1
          );

          const keyValuePair: IKeyValue<number, string>[] = sorted.map(
            (fanMotor) => {
              return {
                key: fanMotor.id || 0,
                value: fanMotor.name,
              };
            }
          );

          resolve({ data: keyValuePair });
        })
        .catch((error) => {
          reject(error);
        });
    }
  );
};

const compressorManufacturerService = {
  getKeyValues,
};

export default compressorManufacturerService;
