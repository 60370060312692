import {
  ITechnicianQuizAnswers,
  ITechnicianQuizBacklog,
  ITechnicianQuizBacklogElement,
} from "../ts/interfaces";
import axios from "../utils/axios";

const getAll = (quizId: number) => {
  return axios.get<Array<ITechnicianQuizBacklogElement>>(
    `/TechnicianQuizBacklog/backlogId/` + quizId
  );
};
const getAnswers = (questionId: number) => {
  return axios.get<Array<ITechnicianQuizAnswers>>(
    `/TechnicianQuizBacklog/asnwers/` + questionId
  );
};

const getById = (id: number) => {
  return axios.get<ITechnicianQuizBacklogElement>(
    `/TechnicianQuizBacklog/${id}`
  );
};

const deleteById = (id: number) => {
  return axios.delete(`/TechnicianQuizBacklog/${id}`);
};

const update = (id: number, data: ITechnicianQuizBacklogElement) => {
  return axios.put(`/TechnicianQuizBacklog/${id}`, data);
};

const changeStatus = (id: number) => {
  return axios.put(`/TechnicianQuizBacklog/EnableDisable/${id}`, null);
};

const add = async (element: ITechnicianQuizBacklog) => {
  return axios.post<ITechnicianQuizBacklog>(`TechnicianQuizBacklog`, element);
};

const technicianQuizBacklogService = {
  getAll,
  getById,
  update,
  add,
  deleteById,
  changeStatus,
  getAnswers,
};

export default technicianQuizBacklogService;
