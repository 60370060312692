import moment from "moment";

export const getFilter = (filters: Record<string, any>) => {
  if (!filters) return "";

  return Object.keys(filters)
    .filter(
      (key) =>
        filters[key] !== null &&
        filters[key] !== "" &&
        filters[key] !== Number.MIN_SAFE_INTEGER
    )
    .map((key) => {
      return `${key}=${filters[key]}`;
    })
    .join("&");
};

export const nameToInitials = (fullName: string) => {
  const namesArray = fullName.trim().split(" ");
  if (fullName === null) return "UU";

  if (namesArray.length === 0) return "UU";
  else if (namesArray.length === 1) return `${namesArray[0].charAt(0)}`;
  else return `${namesArray[0].charAt(0)}${namesArray[1].charAt(0)}`;
};

export const getFullName = (name: string, lastName: string) => {
  return `${name} ${lastName}`;
};

export const convertUTCDateToLocalDate = (stringDate: string) => {
  if (!stringDate) return null;
  var dateGiven = new Date(stringDate);
  var newDate = new Date(dateGiven.getTime());

  var offset = dateGiven.getTimezoneOffset() / 60;
  var hours = dateGiven.getHours();

  newDate.setHours(hours - offset);

  return moment(newDate);
};

export interface KeyValue {
  key: string;
  value: string;
}

export const keyValueFromEnum = <O extends object, K extends keyof O = keyof O>(
  obj: O
): KeyValue[] => {
  const keys = Object.keys(obj).filter((k) => !Number.isNaN(k)) as K[];
  return keys.map((tl) => ({
    key: tl.toString(),
    value: `${obj[tl]}`,
  }));
};
