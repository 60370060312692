import React, { useState, useEffect } from "react";
import { Grid, Stack } from "@mui/material";
import { useForm, Form, useLog } from "src/hooks";
import {
  FormCancelButton,
  FormSaveButton,
  FormText,
} from "src/components/formControls";
import { Validator } from "src/ts/types";
import Popup from "src/components/Popup";

const initialValues: any = {
  name: "",
};

interface ConfirmModalProps {
  isDialogOpen: boolean;
  setIsDialogOpen: (val: boolean) => void;
  onSave: (folderName: string) => Promise<void>;
}

const AddDirectoryPopup = (props: ConfirmModalProps) => {
  const { log } = useLog();
  const { isDialogOpen, setIsDialogOpen, onSave } = props;

  const [isSubmitting, setIsSubmitting] = useState(false);

  const validate = (fieldValues = values) => {
    let temp: Record<string, string> = { ...errors };

    temp.name = new Validator(fieldValues, "name").toString();

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, errors, setErrors, handleInputChange, resetForm } = useForm(
    initialValues,
    false,
    validate
  );

  useEffect(() => {
    resetForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDialogOpen]);

  const saveHandler = async () => {
    if (!validate()) return;

    try {
      setIsSubmitting(true);
      await onSave(values.name);
      setIsSubmitting(false);
      setIsDialogOpen(false);
    } catch (error: any) {
      log.error(error?.message?.exceptionMessage ?? "Something went wrong");
    } finally {
      setIsSubmitting(false);
    }
  };

  const cancelHandler = () => {
    setIsDialogOpen(false);
  };

  const row = 12;
  return (
    <Popup
      openPopup={isDialogOpen}
      setOpenPopup={setIsDialogOpen}
      title={`Add New Item`}
      onClose={cancelHandler}
    >
      <Form>
        <Grid container spacing={5}>
          <Grid item xs={row}>
            <FormText
              name="name"
              label="Folder name"
              value={values.name}
              onChange={handleInputChange}
              error={errors.name}
              maxLength={15}
            />
          </Grid>
        </Grid>
      </Form>
      <>
        <Stack
          direction="row"
          spacing={2}
          justifyContent="center"
          alignItems="center"
        >
          <FormCancelButton
            onClick={cancelHandler}
            isSubmitting={isSubmitting}
          />
          <FormSaveButton onClick={saveHandler} isSubmitting={isSubmitting} />
        </Stack>
      </>
    </Popup>
  );
};

export default AddDirectoryPopup;
