import { TowerStudyProvider } from "../context/TowerStudyContext";
import TowerStep2 from "./TowerStep2";

const Index = () => {
  return (
    <TowerStudyProvider>
      <TowerStep2 />
    </TowerStudyProvider>
  );
};

export default Index;
