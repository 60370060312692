import axios from "src/utils/axios";
import { IPitotMatrixItems } from "src/ts/interfaces/study/pitot";

const getByPitotId = (id: number) => {
  return axios.get<IPitotMatrixItems[]>(`PitotMatrixItems?pitotMatrixId=${id}`);
};

const saveItems = (items: IPitotMatrixItems[]) => {
  return axios.put(`PitotMatrixItems`, items);
};

const postMatrixXY = (x: number, y: number, id: number) => {
  return axios.post(`PitotMatrixItems?x=${x}&y=${y}`, {
    pitotTraverseId: id,
  });
};

const pitotMatrixItemsService = {
  getByPitotId,
  saveItems,
  postMatrixXY,
};

export default pitotMatrixItemsService;
