import Popup from "src/components/Popup";
import { FormButton } from "src/components/formControls";
import { Stack, Typography } from "@mui/material";

interface InsufficientFundsProps {
  insufficientFundsShowPopUp: boolean;
  setInsufficientFundsShowPopUp: (val: boolean) => void;
}

export default function InsufficientFundsPopUp({
  insufficientFundsShowPopUp,
  setInsufficientFundsShowPopUp,
}: InsufficientFundsProps) {
  return (
    <>
      <Popup
        title={"Insufficient funds in your AMP Wallet"}
        openPopup={insufficientFundsShowPopUp}
        setOpenPopup={setInsufficientFundsShowPopUp}
        onClose={() => {
          setInsufficientFundsShowPopUp(false);
        }}
        size="sm"
      >
        <>
          <Typography>
            The transaction could not be completed. Reason: Inssufficient funds.
          </Typography>
        </>
        <Stack direction="row" spacing={3}>
          <FormButton
            size="small"
            text="Close"
            color="primary"
            onClick={() => {
              setInsufficientFundsShowPopUp(false);
            }}
          />
        </Stack>
      </Popup>
    </>
  );
}
