import { Typography, Skeleton, Theme, SxProps } from "@mui/material";

interface LabelProps {
  text?: string;
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
  showSkeleton?: boolean;
  noWrap?: boolean;
  breakeLine?: boolean;
  sx?: SxProps<Theme> | undefined;
}

export default function FormLabel(props: LabelProps) {
  return (
    <>
      {props.showSkeleton ? (
        <Skeleton height={25} width={50} />
      ) : (
        <Typography
          noWrap={props?.noWrap}
          onClick={props.onClick}
          sx={{ ...props.sx }}
        >
          {props.text}
        </Typography>
      )}
    </>
  );
}
