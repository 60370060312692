import { Stack, Typography, Grid, Link } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  FormAcceptButton,
  FormCancelButton,
  FormCheckBox,
  FormSelect,
} from "src/components/formControls";
import Popup from "src/components/Popup";
import { useAsyncMutation, useAsyncQuery, useLog } from "src/hooks";
import { ICreditCard } from "src/ts/interfaces";
import { WalletService } from "src/services";
import FormNumeric from "src/components/formControls/FormNumeric";
import DialogMessagePopup from "src/components/DialogMessagePopup";
import CurrencyFormat from "src/components/format/CurrencyFormat";

interface IProps {
  showModal: boolean;
  setShowModal: (val: boolean) => void;
  cardId: number;
  companyId: number;
  getWalletBalance: () => void;
}

const AddFundsPopUp = ({
  showModal,
  setShowModal,
  cardId,
  companyId,
  getWalletBalance,
}: IProps) => {
  const { execute: executeBalance, data: dataBalance } = useAsyncQuery<number>(
    WalletService.getWalletBalance
  );
  const { execute: executeCard, data: dataCard } = useAsyncQuery<ICreditCard>(
    WalletService.getCardById
  );
  const { execute: executeAddFunds, isSubmitting } = useAsyncMutation<number>(
    WalletService.addFunds,
    {
      successfulMessage: "Payment successfully applied",
      onSuccess: () => {
        getWalletBalance();
        setShowConfirmPopup(false);
        setShowModal(false);
      },
    }
  );
  const [accept, setAccept] = useState(false);
  const [amount, setAmount] = useState<number | null>(50);
  const [showConfirmPopup, setShowConfirmPopup] = useState(false);

  useEffect(() => {
    const getData = async () => {
      await executeBalance(companyId ?? 0);
      await executeCard(cardId);
      setAmount(50);
    };
    if (showModal) getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cardId, showModal]);

  const { log } = useLog();
  const handleClose = () => {
    setShowModal(false);
  };

  const reloadAmount = [
    { key: 50, value: "50.00" },
    { key: 100, value: "100.00" },
    { key: 250, value: "250.00" },
    { key: 500, value: "500.00" },
    { key: 1000, value: "1000.00" },
    { key: 1500, value: "1500.00" },
    { key: 2000, value: "2000.00" },
    { key: 2500, value: "2500.00" },
    { key: 3000, value: "3000.00" },
    { key: 5000, value: "5000.00" },
    { key: 7500, value: "7500.00" },
    { key: 10000, value: "10000.00" },
    { key: 0, value: "Other amount" },
  ];

  const isNotDropdown = (amount: number) => {
    return !reloadAmount.find(
      (element) => element.key === amount && amount !== 0
    );
  };
  const handleInputChange = (e: any) => {
    setAmount(e.target.value);
  };

  const handleAccept = () => {
    setAccept(!accept);
  };

  const handleAddFundsModal = () => {
    setShowConfirmPopup(true);
  };

  const handleConfirmSave = async () => {
    dataCard.lastAmount = amount;
    await executeAddFunds(dataCard);
  };

  return (
    <>
      <Popup
        title="Add new funds to your AMP Wallet"
        openPopup={showModal}
        setOpenPopup={setShowModal}
        onClose={handleClose}
        size="sm"
      >
        <Grid container>
          <Grid item xs={12} textAlign={"center"} p={1}>
            <Typography variant="h4" display={"inline"}>
              Actual Balance:{" "}
            </Typography>
            <Typography variant="h6" display={"inline"}>
              <CurrencyFormat value={dataBalance}></CurrencyFormat>
            </Typography>
          </Grid>
          <Grid item xs={12} textAlign={"center"} p={1}>
            <Typography display={"inline"} variant="subtitle2">
              Card Number:
            </Typography>
            <Typography display={"inline"}>{dataCard?.cardNumber}</Typography>
          </Grid>
          <Grid item xs={12} p={1}>
            <FormSelect
              name="amount"
              label="Reload amount"
              value={
                isNotDropdown(amount ?? 0)
                  ? "0"
                  : Number(amount)?.toFixed(0)?.toString()
              }
              onChange={handleInputChange}
              options={reloadAmount}
            />
          </Grid>
          {isNotDropdown(amount ?? 0) && (
            <Grid item xs={12} p={1}>
              <FormNumeric
                name={"amount"}
                label={"Enter Reload amount"}
                value={amount?.toString() ?? ""}
                onChange={handleInputChange}
              />
            </Grid>
          )}
          <Grid item xs={12} p={1} textAlign={"center"}>
            <Typography>
              Unless otherwise required by law or permitted by this Agreement,
              the dollar value on your AMP Wallet is nonrefundable and may not
              be redeemed for cash.
            </Typography>
          </Grid>
          <Grid item xs={12} p={1} textAlign={"center"}>
            <FormCheckBox
              name={"accept"}
              label={"I accept."}
              value={accept}
              onChange={handleAccept}
            ></FormCheckBox>
          </Grid>
          <Grid item xs={12} p={1} textAlign={"center"}>
            <Link
              href="https://auditmaster.pro/legal?id=wallet_terms"
              target="_blank"
              underline="always"
            >
              {"Go to Wallet Terms of Use"}
            </Link>
          </Grid>
        </Grid>
        <Stack direction="row" spacing={3} justifyContent="flex-end">
          <FormCancelButton onClick={handleClose} isSubmitting={isSubmitting} />
          <FormAcceptButton
            onClick={handleAddFundsModal}
            isSubmitting={isSubmitting}
            disabled={!(accept && amount && amount > 0)}
          ></FormAcceptButton>
        </Stack>
      </Popup>
      <DialogMessagePopup
        title={"Information"}
        text={
          "Are you certain you want to reload your AMP Wallet with the selected funds?"
        }
        showPopup={showConfirmPopup}
        setShowPopup={setShowConfirmPopup}
        onSave={handleConfirmSave}
        isSubmitting={isSubmitting}
      />
    </>
  );
};

export default AddFundsPopUp;
