import axios from "axios";

const seckey = () => {
  return window.localStorage.getItem("seckey") ?? "";
};

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_AMP_API,
  timeout: 40000,
  headers: { seckey: seckey() },
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    return Promise.reject({
      message:
        (error.response && error.response.data) || "Something went wrong",
    });
  }
);

export default axiosInstance;
