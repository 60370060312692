import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { rgba } from "polished";
import {
  Box,
  Button,
  CardActions,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Chip as MuiChip,
  Typography as MuiTypography,
  Skeleton,
} from "@mui/material";

const illustrationCardStyle = (props: any) => css`
  ${props.illustration &&
  props.theme.palette.mode !== "dark" &&
  `
    background: ${rgba(props.theme.palette.primary.main, 0.125)};
    color: ${props.theme.palette.primary.main};
  `}
`;

const Card = styled(MuiCard)<{ illustration?: string }>`
  position: relative;

  ${illustrationCardStyle}
`;

const CardContent = styled(MuiCardContent)`
  position: relative;

  &:last-child {
    padding-bottom: ${(props) => props.theme.spacing(4)};
  }
`;

const Chip = styled(MuiChip)`
  position: absolute;
  top: 16px;
  right: 16px;
  height: 20px;
  padding: 4px 0;
  font-size: 85%;

  margin-bottom: ${(props) => props.theme.spacing(4)};

  span {
    padding-left: ${(props) => props.theme.spacing(2)};
    padding-right: ${(props) => props.theme.spacing(2)};
  }
`;

interface DashboarItemProps {
  title: string;
  label1: string | number;
  label2: React.ReactNode;
  chip?: string;
  chipColor?:
    | "default"
    | "primary"
    | "secondary"
    | "success"
    | "error"
    | "info"
    | "warning";
  action: () => void;
  showSkeleton?: boolean;
  hideViewDetails?: boolean;
}

const DashboarItem: React.FC<DashboarItemProps> = ({
  title,
  label1,
  label2,
  chip,
  chipColor,
  action,
  showSkeleton,
  hideViewDetails,
}) => {
  return (
    <>
      {showSkeleton ? (
        <Skeleton height={50} />
      ) : (
        <Card>
          <CardContent>
            <MuiTypography variant="h6" mb={6}>
              {title}
            </MuiTypography>
            <MuiTypography variant="h3" mb={6}>
              <Box fontWeight="fontWeightRegular">{label1}</Box>
            </MuiTypography>
            {label2}
            {chip && <Chip color={chipColor} label={chip} />}
          </CardContent>
          {!hideViewDetails && (
            <CardActions style={{ paddingTop: 0 }}>
              <Button size="small" onClick={action}>
                View details
              </Button>
            </CardActions>
          )}
        </Card>
      )}
    </>
  );
};

export default DashboarItem;
