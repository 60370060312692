import { ILocalUnions } from "src/ts/interfaces/catalogs/localUnions";
import axios from "src/utils/axios";
import { IKeyValue } from "src/ts/interfaces";

const getAll = () => {
  return axios.get<ILocalUnions[]>("LocalUnions");
};

const add = (unitLabel: ILocalUnions) => {
  return axios.post("LocalUnions", unitLabel);
};

const update = (unitLabel: ILocalUnions) => {
  return axios.put(`LocalUnions/${unitLabel.id}`, unitLabel);
};

const remove = (id: Number) => {
  return axios.delete(`LocalUnions/${id}`);
};

const getKeyValues = () => {
  return new Promise<IKeyValue<number, string>[]>((resolve, reject) => {
    axios
      .get<Array<ILocalUnions>>(`LocalUnions`)
      .then((response) => {
        const keyValuePair: IKeyValue<number, string>[] = response.data.map(
          (item) => {
            return {
              key: item.id!,
              value: item.name,
            };
          }
        );

        resolve(keyValuePair);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const getKeyValuesByLabor = (laborId: number) => {
  return new Promise<IKeyValue<number, string>[]>((resolve, reject) => {
    axios
      .get<Array<ILocalUnions>>(`LocalUnions/${laborId}`)
      .then((response) => {
        const keyValuePair: IKeyValue<number, string>[] = response.data
          .sort((a, b) => (a.orderPosition < b.orderPosition ? -1 : 1))
          .sort((a, b) => (a.name < b.name ? -1 : 1))
          .map((item) => {
            return {
              key: item.id!,
              value: item.name,
            };
          });

        resolve(keyValuePair);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const LocalUnionsService = {
  getAll,
  add,
  update,
  remove,
  getKeyValues,
  getKeyValuesByLabor,
};
export default LocalUnionsService;
