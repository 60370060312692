import axios from "src/utils/axios";
import { IGeneralMatrix } from "src/ts/interfaces/study/generalMatrix";

const getById = (id: number) => {
  return axios.get<IGeneralMatrix>(`GeneralMatrix/${id}`);
};

const update = (id: number, outletMaster: IGeneralMatrix) => {
  return axios.put<IGeneralMatrix>(
    `GeneralMatrix/${id}?recalculate=true`,
    outletMaster
  );
};

const putGeneralMatrix = (id: number, generalMatrixData: IGeneralMatrix) => {
  return axios.put(`GeneralMatrix/${id}`, generalMatrixData);
};

const generalMatrixService = {
  getById,
  update,
  putGeneralMatrix,
};

export default generalMatrixService;
