import { useEffect, useState } from "react";

const useStopwatch = (startDate: any, start: boolean, endDate: any) => {
  const stopWatchStartDate = new Date(startDate).getTime();
  const stopWatchEndDate = new Date(endDate).getTime();

  const [countDown, setCountDown] = useState(
    stopWatchEndDate - stopWatchStartDate
  );

  useEffect(() => {
    if (start) {
      const interval = setInterval(() => {
        setCountDown(new Date().getTime() - stopWatchStartDate);
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [stopWatchStartDate, start, endDate]);

  return getReturnValues(countDown);
};

const getReturnValues = (countDown: number) => {
  const days = Math.floor(countDown / (1000 * 60 * 60 * 24));
  const hours = Math.floor(
    (countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  );
  const minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((countDown % (1000 * 60)) / 1000);

  return [days, hours, minutes, seconds];
};

export { useStopwatch };
