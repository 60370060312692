import { IUserNotification } from "../ts/interfaces";
import axios from "../utils/axios";

const getByUser = () => {
  return axios.get("notification/user");
};

const getByType = (typeId: number) => {
  return axios.get<number>(
    `notification/countByType?notificationTypeId=${typeId}`
  );
};

const read = (notificationUserId: number) => {
  return axios.post(`Notification/read/${notificationUserId}`);
};

const getAllByUser = () => {
  return axios.get<IUserNotification[]>("notification/userAll");
};

const disable = (userNotificationId: number) => {
  return axios.post("notification/disable/" + userNotificationId);
};

const readAll = () => {
  return axios.post("notification/readAll");
};

const deleteAll = () => {
  return axios.post("notification/deleteAll");
};

const readByType = (typeId: number) => {
  return axios.put(`notification/readByType?notificationTypeId=${typeId}`);
};

const NotificationService = {
  getByUser,
  read,
  getAllByUser,
  disable,
  readAll,
  deleteAll,
  getByType,
  readByType,
};

export default NotificationService;
