import { ChillerStudyProvider } from "../context/ChillerStudyContext";
import ChillerStep41 from "./ChillerStep41";

const Index = () => {
  return (
    <ChillerStudyProvider>
      <ChillerStep41 />
    </ChillerStudyProvider>
  );
};

export default Index;
