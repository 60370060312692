import React, { useState } from "react";
import { useLog } from "src/hooks";
import useFormTyped from "src/hooks/useFormTyped";
import chillerService from "src/services/study/chillerService";
import {
  IChiller,
  IChillerCompressor,
  IChillerEvaporatorCondenser,
  IChillerTowerCondenser,
} from "src/ts/interfaces";
import { IChillerTowerFanMotor } from "src/ts/interfaces/study/tower";
import { Validator } from "src/ts/types";

interface IChillerContext {
  chillerValues: IChiller;
  setChillerValues: (val: IChiller) => void;
  handleChillerInputChange: (e: any) => void;
  chillerErros: Record<string, string>;
  validateChillerValues: any;
  //Evaporator
  evaporatorValues: IChillerEvaporatorCondenser;
  setEvaporatorValues: (val: IChillerEvaporatorCondenser) => void;
  handleEvaporatorInputChange: (e: any) => void;
  evaporatorErros: Record<string, string>;
  validateEvaporatorValues: any;
  //Compressor
  compressorValues: IChillerCompressor;
  setCompressorValues: (val: IChillerCompressor) => void;
  handleCompressorInputChange: (e: any) => void;
  compressorErros: Record<string, string>;
  setCompressorErrors: React.Dispatch<
    React.SetStateAction<Record<string, string>>
  >;
  validateCompressorValues: any;
  //TowerCondensers
  towerCondenserValues: IChillerTowerCondenser;
  setTowerCondenserValues: (val: IChillerTowerCondenser) => void;
  towerCondenserErros: Record<string, string>;
  setTowerCondenserErrors: React.Dispatch<
    React.SetStateAction<Record<string, string>>
  >;
  handleTowerCondenserInputChange: (e: any) => void;
  validateTowerCondenserValues: any;
  //FanMotor
  fanMotorValues: IChillerTowerFanMotor;
  setFanMotorValues: (val: IChillerTowerFanMotor) => void;
  fanMotorErrors: Record<string, string>;
  setFanMotorsErrors: React.Dispatch<
    React.SetStateAction<Record<string, string>>
  >;
  handleFanMotorInputChange: (e: any) => void;
  validateFanMotorValues: any;
  updateFanMotor: (fanMotor: IChillerTowerFanMotor) => Promise<void>;
  showEffPfLimitModal: boolean;
  setShowEffPfLimitModal: (val: boolean) => void;
  effPfMessage: string;
}

type ChillerStudyProviderProps = {
  children: React.ReactNode;
};

const ChillerStudyContext = React.createContext<IChillerContext | null>(null);

const initialValues: any = {};
const initialEvaportorValues: any = {};
const initialCompressorValues: any = {};
const initialTowerCondensersValues: any = {};
const initialFanMotorsValues: any = {};

function ChillerStudyProvider({ children }: ChillerStudyProviderProps) {
  const { log } = useLog();
  const validateChillerValues: any = (fieldValues = chillerValues) => {
    let temp: Record<string, string> = { ...chillerErros };

    temp.projectName = new Validator(fieldValues, "projectName")
      .isRequired("Project Name is required")
      .toString();

    temp.chillerNumber = new Validator(fieldValues, "chillerNumber")
      .greatThan(0, "Please enter minimum 1 Chiller")
      .toString();

    temp.numberOfTowers = new Validator(fieldValues, "numberOfTowers")
      .greatThan(0, "Please enter minimum 1 Tower")
      .toString();

    setChillerErrors({
      ...temp,
    });

    if (fieldValues === chillerValues)
      return Object.values(temp).every((x) => x === "");
  };

  const {
    values: chillerValues,
    setValues: setChillerValues,
    errors: chillerErros,
    setErrors: setChillerErrors,
    handleInputChange: handleChillerInputChange,
  } = useFormTyped<IChiller>(initialValues, false, validateChillerValues);

  const validateEvaporatorValues: any = (fieldValues = evaporatorValues) => {
    let temp: Record<string, string> = { ...evaporatorErros };

    setEvaporatorErrors({
      ...temp,
    });

    if (fieldValues === evaporatorValues)
      return Object.values(temp).every((x) => x === "");
  };

  const {
    values: evaporatorValues,
    setValues: setEvaporatorValues,
    errors: evaporatorErros,
    setErrors: setEvaporatorErrors,
    handleInputChange: handleEvaporatorInputChange,
  } = useFormTyped<IChillerEvaporatorCondenser>(
    initialEvaportorValues,
    false,
    validateEvaporatorValues
  );

  //Compressors
  const validateCompressorValues: any = (fieldValues = compressorValues) => {
    let temp: Record<string, string> = { ...compressorErros };

    temp.nameplateHorsePowerId = new Validator(
      fieldValues,
      "nameplateHorsePowerId"
    )
      .selectedOption(
        0,
        `To move forward, Nameplate HP is required. If you don't know the HP, select the "Nameplate HP unknown" checkbox.`
      )
      .toString();

    temp.otherCompressorManufacturer = new Validator(
      fieldValues,
      "otherCompressorManufacturer"
    )
      .maxLength(199, "More than 199")
      .toString();

    setCompressorErrors({
      ...temp,
    });

    if (fieldValues === compressorValues)
      return Object.values(temp).every((x) => x === "");
  };

  const {
    values: compressorValues,
    setValues: setCompressorValues,
    errors: compressorErros,
    setErrors: setCompressorErrors,
    handleInputChange: handleCompressorInputChange,
  } = useFormTyped<IChillerCompressor>(
    initialCompressorValues,
    false,
    validateCompressorValues
  );

  //TowerCondensers
  const validateTowerCondenserValues: any = (
    fieldValues = towerCondenserValues
  ) => {
    let temp: Record<string, string> = { ...towerCondenserErros };

    setTowerCondenserErrors({
      ...temp,
    });

    if (fieldValues === towerCondenserValues)
      return Object.values(temp).every((x) => x === "");
  };

  const {
    values: towerCondenserValues,
    setValues: setTowerCondenserValues,
    errors: towerCondenserErros,
    setErrors: setTowerCondenserErrors,
    handleInputChange: handleTowerCondenserInputChange,
  } = useFormTyped<IChillerTowerCondenser>(
    initialTowerCondensersValues,
    false,
    validateTowerCondenserValues
  );

  //FanMotors
  const validateFanMotorValues: any = (fieldValues = fanMotorValues) => {
    let temp: Record<string, string> = { ...towerCondenserErros };

    setFanMotorsErrors({
      ...temp,
    });

    if (fieldValues === fanMotorValues)
      return Object.values(temp).every((x) => x === "");
  };

  const {
    values: fanMotorValues,
    setValues: setFanMotorValues,
    errors: fanMotorErrors,
    setErrors: setFanMotorsErrors,
    handleInputChange: handleFanMotorInputChange,
  } = useFormTyped<IChillerTowerFanMotor>(
    initialFanMotorsValues,
    false,
    validateFanMotorValues
  );

  const [showEffPfLimitModal, setShowEffPfLimitModal] = useState(false);
  const [effPfMessage, setEffPfMessage] = useState("");

  const updateFanMotor = async (fanMotor: IChillerTowerFanMotor) => {
    try {
      await chillerService.updateFanMotor(fanMotor.id, fanMotor);

      const calculateTowerFormulaRes = await chillerService.updateTowerFormula(
        towerCondenserValues.id,
        towerCondenserValues
      );

      setTowerCondenserValues({
        ...towerCondenserValues,
        condenserTowerApproach:
          calculateTowerFormulaRes.data.condenserTowerApproach,
        coolingTowerEfficiency:
          calculateTowerFormulaRes.data.coolingTowerEfficiency,
        actualTowerBTUH: calculateTowerFormulaRes.data.actualTowerBTUH,
        actualKws: calculateTowerFormulaRes.data.actualKws,
      });

      const getFanMotorReq = await chillerService.getFanMotorById(
        fanMotorValues.id
      );

      const chillerFormula = getFanMotorReq.data.chillerFormula;
      const eff = chillerFormula.eff ? chillerFormula.eff / 100 : 0.0;

      setFanMotorValues({
        ...getFanMotorReq.data,
        chillerFormula: {
          ...chillerFormula,
          eff: eff,
        },
      });

      if (eff > 1.0) {
        setEffPfMessage("Improperly inputed data EFF can’t exceed 1.0");
        setShowEffPfLimitModal(true);
      } else if (chillerFormula.powerFactor > 1.0) {
        setEffPfMessage("Improperly inputed data PF can’t exceed 1.0");
        setShowEffPfLimitModal(true);
      }
    } catch (error: any) {
      log.error(error?.message?.exceptionMessage ?? "Something went wrong");
    }
  };

  return (
    <ChillerStudyContext.Provider
      value={{
        chillerValues,
        setChillerValues,
        handleChillerInputChange,
        chillerErros,
        validateChillerValues,
        //Evaporator
        evaporatorValues,
        setEvaporatorValues,
        handleEvaporatorInputChange,
        evaporatorErros,
        validateEvaporatorValues,
        //Compressors
        compressorValues,
        setCompressorValues,
        handleCompressorInputChange,
        compressorErros,
        setCompressorErrors,
        validateCompressorValues,
        //TowerCondensers
        towerCondenserValues,
        setTowerCondenserValues,
        towerCondenserErros,
        setTowerCondenserErrors,
        handleTowerCondenserInputChange,
        validateTowerCondenserValues,
        //
        fanMotorValues,
        setFanMotorValues,
        fanMotorErrors,
        setFanMotorsErrors,
        handleFanMotorInputChange,
        validateFanMotorValues,
        updateFanMotor,
        showEffPfLimitModal,
        setShowEffPfLimitModal,
        effPfMessage,
      }}
    >
      {children}
    </ChillerStudyContext.Provider>
  );
}

export { ChillerStudyContext, ChillerStudyProvider };
