import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Box, Grid, Typography } from "@mui/material";
import { useForm, Form, useLog } from "src/hooks";
import TicketService from "src/services/ticketService";
import { PriorityEnum } from "src/ts/enums";
import DropZone from "src/components/DropZone";
import { TICKETLOGIN } from "src/constants";
import { Validator } from "src/ts/types";
import Popup from "src/components/Popup";
import {
  FormCancelButton,
  FormDatePicker,
  FormSaveButton,
  FormSelect,
  FormText,
  FormTextArea,
} from "src/components/formControls";
import FormNumeric from "src/components/formControls/FormNumeric";
import { GridActionButton } from "src/components/gridControls";

const initialValues = {
  amount: 0,
  transactionDate: null,
  anonymousEmail: "",
  anonymousName: "",
  title: "",
  description: "",
  url: "",
  priority: PriorityEnum.SelectPriority,
  ClassificationId: TICKETLOGIN.CLASSIFICATIONID,
  Classification: {
    id: TICKETLOGIN.ID,
    Name: TICKETLOGIN.NAME,
  },
};

interface StateNewTicketModalProps {
  showEmergencyPopupForm: boolean;
  setShowEmergencyPopupForm: (parm1: boolean) => void;
  setOpenPopupMain: (parm: boolean) => void;
  setDisabledCreateButton: (parm: boolean) => void;
  setIsOpenSnackBar: (parm: boolean) => void;
  setDisableCheckeBox: (parm: boolean) => void;
  isAuthenticated: boolean;
  emailValue: string;
}

const priorities = [
  { key: PriorityEnum.SelectPriority, value: PriorityEnum.SelectPriority },
  { key: PriorityEnum.High, value: PriorityEnum.High },
  { key: PriorityEnum.Medium, value: PriorityEnum.Medium },
  { key: PriorityEnum.Low, value: PriorityEnum.Low },
];

export default function ModalNewTicketForm({
  showEmergencyPopupForm,
  setShowEmergencyPopupForm,
  setOpenPopupMain,
  setIsOpenSnackBar,
  setDisabledCreateButton,
  setDisableCheckeBox,
  isAuthenticated,
  emailValue,
}: StateNewTicketModalProps) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [clasifications, setClasifications] = useState<any[]>([]);
  const { log } = useLog();
  const location = useLocation();
  const [fileImage, setFileImage] = useState<File>();
  const [disableDropZone, setDisableDropZone] = useState(false);

  const validate = (fieldValues = values) => {
    let temp: Record<string, string> = { ...errors };

    temp.description = new Validator(fieldValues, "description")
      .maxLength(500, "Your description has more than 500 characters.")
      .toString();

    temp.description = new Validator(fieldValues, "description")
      .isRequired("The description is required.")
      .toString();

    temp.title = new Validator(fieldValues, "title")
      .isRequired("The title is required.")
      .toString();
    temp.priority = new Validator(fieldValues, "priority")
      .selectedOption("Select Priority", "The Classsification is required.")
      .toString();

    if (!isAuthenticated) {
      temp.anonymousEmail = new Validator(fieldValues, "anonymousEmail")
        .isRequired("The email is required.")
        .toString();

      temp.anonymousEmail = new Validator(fieldValues, "anonymousEmail")
        .emailFormat("Insert a correct email. demo@bootlab.io")
        ?.toString();

      temp.anonymousName = new Validator(fieldValues, "anonymousName")
        .isRequired("The name is required.")
        .toString();
    } else {
      temp.url = new Validator(fieldValues, "url")
        .isRequired("The url is required.")
        .toString();

      temp.ClassificationId = new Validator(fieldValues, "ClassificationId")
        .selectedOption(-1, "The Classsification is required.")
        .toString();

      if (
        fieldValues.ClassificationId === 8 ||
        fieldValues.ClassificationId === 9
      ) {
        temp.amount = new Validator(fieldValues, "amount")
          .maxLength(6, "Your amount has more than 6 digits")
          .toString();
        temp.transactionDate = new Validator(fieldValues, "transactionDate")
          .validateIf(fieldValues.transactionDate === null)
          .isRequired("Select a transaction date")
          .toString();
      }
    }
    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange } = useForm(
    initialValues,
    false,
    validate
  );

  useEffect(() => {
    if (!isAuthenticated) {
      setValues((current: any) => {
        return {
          ...current,
          Classification: {
            ...current.Classification,
            id: 7,
          },
        };
      });
      setValues((current: any) => {
        return {
          ...current,
          Classification: {
            ...current.Classification,
            Name: "Login",
          },
        };
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  useEffect(() => {
    setValues({ ...values, anonymousEmail: emailValue });
    setValues({
      ...values,
      url: `${window.location.protocol}/${window.location.host}${window.location.pathname}${window.location.search}`,
    });
    const getTicketClassificattion = async () => {
      try {
        const reponse = await TicketService.getAllTicketClassification();
        setClasifications(
          reponse.data.map((item: any) => {
            return { ...clasifications, key: item.id, value: item.name };
          })
        );
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      }
    };

    getTicketClassificattion();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFileAccepted = async (file: File) => {
    if (file) {
      setFileImage(file);
      setDisableDropZone(true);
    }
  };

  const handleDeleteFile = async () => {
    setFileImage(undefined);
    setDisableDropZone(false);
  };

  const handleClose = () => {
    setShowEmergencyPopupForm(false);
  };

  const handleSave = async () => {
    if (!validate()) return;
    try {
      setIsSubmitting(true);
      if (isAuthenticated) {
        if (fileImage === undefined) {
          await TicketService.postTicket({
            ...values,
            ClassificationId: values.ClassificationId,
            Classification: {
              ...values.Classification,
              id: values.ClassificationId,
            },
          });
        } else {
          await TicketService.uploadFile({
            key: {
              ...values,
              Classification: {
                ...values.Classification,
                id: values.ClassificationId,
              },
            },
            content: fileImage,
          });
        }
      } else {
        await TicketService.postTicket({
          ...values,
          ClassificationId: 7,
          Classification: {
            ...values.Classification,
            id: 7,
          },
        });
      }
    } catch (error: any) {
      log.error(error?.message?.exceptionMessage ?? "Something went wrong");
    } finally {
      setIsSubmitting(false);
      setShowEmergencyPopupForm(false);
      setDisabledCreateButton(true);
      setOpenPopupMain(false);
      setIsOpenSnackBar(true);
      setDisableCheckeBox(false);
    }
  };

  return (
    <>
      <Popup
        openPopup={showEmergencyPopupForm}
        setOpenPopup={setShowEmergencyPopupForm}
        title="Emergency Login Ticket"
        onClose={handleClose}
      >
        <>
          <p style={{ textAlign: "center" }}>
            <b>
              Please add all required information to send an alert of any
              software issue in need of immediate repair. Alerts are sent
              directly to our Software Design Engineers.
            </b>
          </p>

          <Box
            component="form"
            sx={{
              "& .MuiTextField-root": { m: 1, width: "25ch" },
            }}
            noValidate
            autoComplete="off"
          />
          <Form>
            <Grid container spacing={5}>
              {isAuthenticated ? null : (
                <>
                  <Grid item xs={12} spacing={5}>
                    <FormText
                      name="anonymousEmail"
                      label="Email"
                      value={values.anonymousEmail}
                      onChange={handleInputChange}
                      error={errors.anonymousEmail}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormText
                      name="anonymousName"
                      label="Name"
                      value={values.anonymousName}
                      onChange={handleInputChange}
                      error={errors.anonymousName}
                    />
                  </Grid>
                </>
              )}
              <Grid item xs={12}>
                <FormText
                  name="title"
                  label="Title"
                  value={values.title}
                  onChange={handleInputChange}
                  error={errors.title}
                />
              </Grid>
              <Grid item xs={12}>
                <FormTextArea
                  name="description"
                  label="Description"
                  value={values.description}
                  onChange={handleInputChange}
                  error={errors.description}
                  placeholder="Describe your problem. Max. 500 characters"
                />
              </Grid>
              {isAuthenticated ? (
                <>
                  <Grid item xs={12}>
                    <FormText
                      name="url"
                      label="URL"
                      value={values.url}
                      onChange={handleInputChange}
                      error={errors.url}
                    />
                  </Grid>
                </>
              ) : null}
              <Grid item xs={12}>
                <FormSelect
                  name="priority"
                  label="Select Priority"
                  value={values.priority}
                  onChange={handleInputChange}
                  options={priorities}
                  error={errors.priority}
                />
              </Grid>
              {isAuthenticated ? (
                <>
                  <Grid item xs={12}>
                    <FormSelect
                      name="ClassificationId"
                      label="Select Classification"
                      value={values.ClassificationId}
                      onChange={handleInputChange}
                      options={clasifications}
                      error={errors.ClassificationId}
                      defaultValue={{ key: -1, value: "Select Classification" }}
                    />
                  </Grid>
                  {values.ClassificationId === 8 ||
                  values.ClassificationId === 9 ? (
                    <>
                      <Grid item xs={6}>
                        <FormNumeric
                          maxLength={6}
                          name="amount"
                          label="Amount"
                          value={values.amount}
                          onChange={handleInputChange}
                          error={errors.amount}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <FormDatePicker
                          name="transactionDate"
                          label="Transaction Date"
                          value={values.transactionDate}
                          onChange={handleInputChange}
                          error={errors.transactionDate}
                        />
                      </Grid>
                      {values.ClassificationId === 8 ? (
                        <Grid item xs={5}>
                          <FormNumeric
                            name="last4DigitsCard"
                            label="Last 4 digts of the credit card"
                            value={values.last4DigitsCard}
                            onChange={handleInputChange}
                          />
                        </Grid>
                      ) : (
                        <></>
                      )}
                    </>
                  ) : (
                    <></>
                  )}
                  {fileImage && (
                    <>
                      <Grid item xs={9}>
                        <Typography variant="h6">File:</Typography>
                        <Typography paddingLeft={3}>
                          {fileImage?.name}
                        </Typography>
                      </Grid>
                      <Grid item xs={3} textAlign={"right"}>
                        <GridActionButton
                          type="delete"
                          onClick={() => {
                            handleDeleteFile();
                          }}
                          tooltip="Remove"
                          disabled={isSubmitting}
                        />
                      </Grid>
                    </>
                  )}
                  <Grid item xs={12}>
                    <DropZone
                      onFileAccepted={handleFileAccepted}
                      accept={{ "image/*": [".jpeg", ".jpg", ".png"] }}
                      disabled={disableDropZone}
                      disableMessage="Only one image is allowed"
                      submiting={isSubmitting}
                    />
                  </Grid>
                </>
              ) : null}
            </Grid>
          </Form>
        </>
        <>
          <FormSaveButton
            onClick={handleSave}
            isSubmitting={isSubmitting}
            sx={{ marginRight: "10px" }}
          />

          <FormCancelButton onClick={handleClose} isSubmitting={isSubmitting} />
        </>
      </Popup>
    </>
  );
}
