import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import {
  CardContent,
  Grid,
  Link,
  Button as MuiButton,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography as MuiTypography,
  Chip,
  Stepper,
  Step,
  StepLabel,
  Stack,
} from "@mui/material";
import { spacing, display } from "@mui/system";
import plaqueOrderService from "src/services/plaqueOrderService";
import { UserParams } from "src/ts/types";
import { IBuilding, IPlaqueOrderModify } from "src/ts/interfaces";
import { FormActionButton } from "src/components/formControls";
import { OrderStatusEnum } from "src/ts/enums";
import { orderStatus, ROLES } from "src/constants";
import ChangeStatusOrderPopup from "./ChangeStatusOrderPopup";
import { convertUTCDateToLocalDate } from "src/utils/utils";
import CurrencyFormat from "src/components/format/CurrencyFormat";
import { Permission as PermissionTypes } from "src/ts/enums";
import { useAsyncQuery, useAuth, usePermissions } from "src/hooks";
import { buildingService } from "src/services";

const Card = styled(MuiCard)`
  ${spacing};

  box-shadow: none;
`;

const Divider = styled(MuiDivider)(spacing);

const Shadow = styled.div`
  box-shadow: ${(props) => props.theme.shadows[1]};
`;

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Button = styled(MuiButton)(spacing);

const Typography = styled(MuiTypography)(display);

const steps = ["Order Received", "At Manufacturer", "Shipped", "Delivered"];

function PlaqueOrdersModify() {
  let navigate = useNavigate();
  const { user } = useAuth();
  let { id } = useParams<UserParams>();
  let { page } = useParams<{ page: string }>();
  const orderId = parseInt(id === undefined ? "0" : id);
  const [order, setOrder] = useState<IPlaqueOrderModify | null>(null);
  const [showChangeStatusOrderPopup, setShowChangeStatusOrderPopup] =
    useState(false);
  const { fullAccess } = usePermissions(PermissionTypes.PlaqueDecalsOrders);
  const { readOnly } = usePermissions(PermissionTypes.PlaqueDecalsOrders);
  useEffect(() => {
    const getUser = async (userId: number) => {
      const response = await plaqueOrderService.getById(userId);
      setOrder(response.data);
      await executeAddress(response.data.projectId);
    };

    if (orderId > 0) {
      getUser(orderId);
    }
  }, [orderId]);

  const { execute: executeAddress, data: dataAddress } =
    useAsyncQuery<IBuilding>(buildingService.getAddress);

  const cancelHandler = () => {
    if (fullAccess || readOnly) navigate("/app/plaqueOrders");
    else if (page === "plaqueCompanyOrder")
      navigate(`/app/plaqueCompanyOrders`);
    else {
      if (user?.role === ROLES.CompanyOwner)
        navigate(`/app/ProjectList/ApprovedByAMP/${user?.companyId}`);
      else navigate(`/app/infectiousProject/${user?.companyId}`);
    }
  };

  return (
    <React.Fragment>
      <Helmet title="Invoice Details" />

      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            {dataAddress?.name} - Order #{order?.id}
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} to="/">
              Dashboard
            </Link>
            <Typography>Companies</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item>
          <Stack direction="row" spacing={2}>
            {!(
              order?.orderStatus === OrderStatusEnum.Delivered ||
              order?.orderStatus === OrderStatusEnum.Other
            ) &&
              fullAccess && (
                <FormActionButton
                  mr={2}
                  text={`Change Status to ${
                    orderStatus[order?.orderStatus ?? 0]?.name ?? ""
                  }`}
                  size="medium"
                  onClick={() => {
                    setShowChangeStatusOrderPopup(true);
                  }}
                  type="add"
                />
              )}
          </Stack>
        </Grid>
      </Grid>

      <Divider my={6} />

      <Grid container justifyContent="center">
        <Grid item xs={12} lg={10}>
          <Shadow>
            <Card px={6} pt={6}>
              <CardContent>
                <Grid container spacing={6}>
                  <Grid item xs={12} md={12}>
                    <Stepper
                      activeStep={(order?.orderStatus ?? 0) - 1}
                      alternativeLabel
                    >
                      {steps.map((label) => (
                        <Step key={label}>
                          <StepLabel>{label}</StepLabel>
                        </Step>
                      ))}
                    </Stepper>
                  </Grid>

                  <Grid item xs={12}>
                    <Divider />
                  </Grid>

                  {order?.orderStatus !== undefined && order?.orderStatus > 1 && (
                    <>
                      <Grid item xs={12}>
                        <Grid container>
                          <Grid item xs={3} pl={3}>
                            <Typography>Status</Typography>
                          </Grid>
                          <Grid item xs={3} pl={3}>
                            <Typography>Updated By</Typography>
                          </Grid>
                          <Grid item xs={3} pl={3}>
                            <Typography>Comments</Typography>
                          </Grid>
                          <Grid item xs={3} pl={3}>
                            <Typography>Date</Typography>
                          </Grid>
                        </Grid>

                        {order?.orderStatus !== undefined &&
                          order?.orderStatus > 1 && <Divider my={4} />}

                        <Grid container>
                          {order?.orderStatus !== undefined &&
                            order?.orderStatus > 1 && (
                              <>
                                <Grid item xs={3} pl={3}>
                                  <Typography sx={{ fontWeight: "bold" }}>
                                    At Manufacturer
                                  </Typography>
                                </Grid>
                                <Grid item xs={3} pl={3}>
                                  <Typography>{order?.preparingBy}</Typography>
                                </Grid>
                                <Grid item xs={3} pl={3}>
                                  <Typography>
                                    {order?.preparingInfo}
                                  </Typography>
                                </Grid>
                                <Grid item xs={3} pl={3}>
                                  <Typography>
                                    {convertUTCDateToLocalDate(
                                      order?.preparingDate ?? ""
                                    )?.format("MM/DD/yyyy")}
                                  </Typography>
                                </Grid>
                              </>
                            )}
                        </Grid>
                        {order?.orderStatus !== undefined &&
                          order?.orderStatus > 1 && <Divider my={4} />}

                        <Grid container>
                          {order?.orderStatus !== undefined &&
                            order?.orderStatus > 2 && (
                              <>
                                <Grid item xs={3} pl={3}>
                                  <Typography sx={{ fontWeight: "bold" }}>
                                    Shipped
                                  </Typography>
                                </Grid>
                                <Grid item xs={3} pl={3}>
                                  <Typography>{order?.shippedBy}</Typography>
                                </Grid>
                                <Grid item xs={3} pl={3}>
                                  <Typography>{order?.shippedInfo}</Typography>
                                </Grid>
                                <Grid item xs={3} pl={3}>
                                  <Typography>
                                    {convertUTCDateToLocalDate(
                                      order?.shippedDate ?? ""
                                    )?.format("MM/DD/yyyy")}
                                  </Typography>
                                </Grid>
                              </>
                            )}
                        </Grid>
                        {order?.orderStatus !== undefined &&
                          order?.orderStatus > 2 && <Divider my={4} />}

                        <Grid container>
                          {order?.orderStatus !== undefined &&
                            order?.orderStatus > 3 && (
                              <>
                                <Grid item xs={3} pl={3}>
                                  <Typography sx={{ fontWeight: "bold" }}>
                                    Delivered
                                  </Typography>
                                </Grid>
                                <Grid item xs={3} pl={3}>
                                  <Typography>{order?.deliveredBy}</Typography>
                                </Grid>
                                <Grid item xs={3} pl={3}>
                                  <Typography>
                                    {order?.deliveredInfo}
                                  </Typography>
                                </Grid>
                                <Grid item xs={3} pl={3}>
                                  <Typography>
                                    {convertUTCDateToLocalDate(
                                      order?.deliveredDate ?? ""
                                    )?.format("MM/DD/yyyy")}
                                  </Typography>
                                </Grid>
                              </>
                            )}
                        </Grid>

                        {order?.orderStatus !== undefined &&
                          order?.orderStatus > 3 && <Divider my={4} />}
                      </Grid>
                    </>
                  )}
                  <Grid item xs={12} md={4}>
                    <Typography variant="caption">Building Address</Typography>
                    <Typography variant="body2">{dataAddress?.name}</Typography>
                    <Typography variant="body2">
                      {order?.deliverAddress?.buildingAddress},{" "}
                      {order?.deliverAddress?.buildingAddress2}
                      <br />
                      {order?.deliverAddress?.buildingState?.name},{" "}
                      {order?.deliverAddress?.buildingCity}{" "}
                      {order?.deliverAddress?.buildingCountry?.name}{" "}
                      {order?.deliverAddress?.buildingZip}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Typography variant="caption">Delivery Address</Typography>
                    <Typography variant="body2">
                      {order?.deliverAddress.address},{" "}
                      {order?.deliverAddress.address2}
                      <br />
                      {order?.deliverAddress.state.name},{" "}
                      {order?.deliverAddress.city}{" "}
                      {order?.deliverAddress.country.name}{" "}
                      {order?.deliverAddress.zip}
                      <br />+{order?.deliverAddress.country.countryCode}{" "}
                      {order?.deliverAddress.phone}
                    </Typography>
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <Typography variant="body2" align="right">
                      {convertUTCDateToLocalDate(
                        order?.createdDate ?? ""
                      )?.format("LL")}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography variant="caption">
                      Order Information:
                    </Typography>
                    <Typography variant="body2">
                      Requested Date:{" "}
                      {convertUTCDateToLocalDate(
                        order?.createdDate ?? ""
                      )?.format("LL")}{" "}
                      <br />
                      Purchase Date:{" "}
                      {convertUTCDateToLocalDate(
                        order?.updatedDate ?? ""
                      )?.format("LL")}
                      <br />
                      {order?.projectPurchase.paymentStatus === "Paid" && (
                        <>
                          Status:{" "}
                          <Chip
                            label={order?.projectPurchase.paymentStatus}
                            color={"success"}
                            size="small"
                          />
                          <br />
                        </>
                      )}
                      <br />
                    </Typography>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Typography variant="caption" align="right" display="block">
                      Additional Information
                    </Typography>
                    <Typography variant="body2" align="right">
                      {order?.deliverAddress.additional}
                      <br />
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
            <Card px={6}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Provider Name</TableCell>
                    <TableCell>Description</TableCell>
                    <TableCell>Size</TableCell>
                    <TableCell align="center">Quantity</TableCell>
                    <TableCell align="right">Unit Price</TableCell>
                    <TableCell align="right">Subtotal</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {order?.shippingDetailRates.map((item) => (
                    <TableRow>
                      <TableCell>
                        {item.plaqueOrderDetail.plaques.plaqueProviders?.name}
                      </TableCell>
                      <TableCell>
                        {item.plaqueOrderDetail.plaques.name}
                      </TableCell>
                      <TableCell>
                        {item.plaqueOrderDetail.plaques.size}
                      </TableCell>
                      <TableCell align="center">
                        {item.plaqueOrderDetail.quantity}
                      </TableCell>
                      <TableCell>
                        <Typography variant="body2" align="right">
                          <CurrencyFormat
                            value={
                              item.plaqueOrderDetail.price /
                              item.plaqueOrderDetail.quantity
                            }
                          />
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body2" align="right">
                          <CurrencyFormat
                            value={item.plaqueOrderDetail.price}
                          />
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ))}

                  <TableRow>
                    <TableCell colSpan={5}>
                      <Typography variant="body2" align="right">
                        Subtotal:
                      </Typography>
                    </TableCell>
                    <TableCell colSpan={1}>
                      <Typography variant="body2" align="right">
                        <CurrencyFormat
                          value={(order?.total ?? 0) - (order?.shipping ?? 0)}
                        />
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={5}>
                      <Typography variant="body2" align="right">
                        <Typography display={"inline"} pr={5}>
                          <Chip
                            label={
                              order?.shippingDetailRates[0]?.shippingRates?.name
                            }
                            color="primary"
                            variant="outlined"
                            size="small"
                          />
                        </Typography>
                        <Typography display={"inline"}>Shipping:</Typography>
                      </Typography>
                    </TableCell>
                    <TableCell colSpan={1}>
                      <Typography variant="body2" align="right">
                        <CurrencyFormat value={order?.shipping} />
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={5}>
                      <Typography variant="body2" align="right">
                        TOTAL:
                      </Typography>
                    </TableCell>
                    <TableCell colSpan={1}>
                      <Typography variant="body2" align="right">
                        <CurrencyFormat value={order?.total} />
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Card>
            <Card pb={6} px={6}>
              <CardContent style={{ textAlign: "center" }}>
                <Button
                  variant="outlined"
                  color="primary"
                  mt={2}
                  onClick={cancelHandler}
                >
                  Back
                </Button>
              </CardContent>
            </Card>
          </Shadow>
        </Grid>
      </Grid>

      <ChangeStatusOrderPopup
        showPopup={showChangeStatusOrderPopup}
        setShowPopup={setShowChangeStatusOrderPopup}
        onSave={cancelHandler}
        order={order}
      />
    </React.Fragment>
  );
}

export default PlaqueOrdersModify;
