import React, { useEffect, useLayoutEffect, useState } from "react";
import styled from "@emotion/styled";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Paper,
  Typography,
  Link,
  Alert,
  Stack,
  Button,
} from "@mui/material";
import SignInComponent from "src/components/auth/SignIn";
import RoleSelection from "./Components/RoleSelection";
import useAuth from "src/hooks/useAuth";
import CodeVerification from "./Components/CodeVerificationScreen";
import { useTheme } from "src/hooks";
import { ROLES, THEMES } from "src/constants";
import Popup from "src/components/Popup";
import DialogMessagePopup from "src/components/DialogMessagePopup";
import UpgradeAccountModal from "src/components/auth/components/UpgradeAccountModal";

const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)};

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)};
  }
`;

function SignIn() {
  const navigate = useNavigate();
  const {
    step,
    isAuthenticated,
    message,
    user,
    emailNotConfirmed,
    deactivatedTraining,
    userName,
    initialize,
  } = useAuth();
  const { theme } = useTheme();
  const [popUpEmailNotConfirmed, setPopUpEmailNotConfirmed] = useState(false);
  const [deactivatedTrainingModal, setDeactivatedTrainingModal] =
    useState(false);
  const [upgradeModal, setUpgradeModal] = useState(false);

  useEffect(() => {
    initialize();
    setDeactivatedTrainingModal(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      switch (user?.role) {
        case ROLES.Auditor:
          navigate("/app/auditorDashboard");
          break;
        case ROLES.SysAdmin:
          navigate("/app/sysAdminDashboard");
          break;
        case ROLES.EnergyAuditor:
          navigate("/app/energyAuditorDashboard");
          break;
        case ROLES.TemporalEnergyAuditor:
          navigate("/app/temporalEnergyAuditorDashboard");
          break;
        case ROLES.TemporalAuditor:
          navigate("/app/temporalAuditorDashboard");
          break;
        case ROLES.Architect:
          navigate("/app/architectDashboard");
          break;
        case ROLES.CompanyOwner:
          navigate("/app/companyAdministratorsDashboard");
          break;
        case ROLES.SuperAdministrator:
          navigate("/app/superAdministratorDashboard");
          break;
        case ROLES.UnionAdministrator:
          navigate("/app/unionAdministratorDashboard");
          break;
        case ROLES.Trainer:
          navigate("/app/trainerDashboard");
          break;
        case ROLES.Training:
          navigate("/app/trainingDashboard");
          break;
        case ROLES.Administrator:
          navigate("/app/companyAdministratorsDashboard");
          break;
        case ROLES.ProjectManager:
          navigate("/app/companyAdministratorsDashboard");
          break;
        case ROLES.ArchitectAux:
          navigate("/app/architectDashboard");
          break;
        default:
          navigate("/private");
          break;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, navigate]);

  useEffect(() => {
    if (emailNotConfirmed) {
      setPopUpEmailNotConfirmed(true);
    }
  }, [emailNotConfirmed]);

  useLayoutEffect(() => {
    if (deactivatedTraining) {
      setDeactivatedTrainingModal(true);
    }
  }, [deactivatedTraining]);

  const handleAccept = () => {
    setUpgradeModal(true);
  };

  return (
    <React.Fragment>
      <Typography component="h2" variant="h2" align="center"></Typography>
      <br />
      <br />
      <br />
      <Wrapper>
        <Helmet title="Sign In" />
        <Link href="https://www.auditmaster.pro/" target="_blank">
          <Box
            component="img"
            alt="Logo AMP"
            src={
              THEMES.DARK === theme
                ? "/static/img/AMPWebLogoVectors.svg"
                : "/static/img/AMPWebLogoVectorsLight.svg"
            }
            sx={{
              marginLeft: "27%",
              marginRight: "30%",
              width: "50%",
              marginBottom: "10px",
            }}
          />
        </Link>
        {message !== "" ? (
          <Stack mb={2}>
            {" "}
            <Alert severity="error">
              <div dangerouslySetInnerHTML={{ __html: message }}></div>
            </Alert>
          </Stack>
        ) : null}
        {step === 1 && <SignInComponent />}
        {step === 2 && <CodeVerification />}
        {step === 3 && <RoleSelection />}
      </Wrapper>

      {popUpEmailNotConfirmed && (
        <>
          <Popup
            title="Information"
            openPopup={popUpEmailNotConfirmed}
            setOpenPopup={setPopUpEmailNotConfirmed}
            onClose={() => {
              setPopUpEmailNotConfirmed(false);
            }}
            size="sm"
          >
            <>
              <Typography>
                <b>Your email confirmation is not yet completed.</b>
              </Typography>
              <br />
              <Typography>
                <b>
                  Please complete the process by clicking the link included in
                  the email sent when your account was created or contact your
                  company administrator to receive a new confirmation email.
                </b>
              </Typography>
            </>
            <Stack direction="row" spacing={3}>
              <Button
                type="button"
                variant="contained"
                color="primary"
                onClick={() => {
                  setPopUpEmailNotConfirmed(false);
                }}
              >
                Close
              </Button>
            </Stack>
          </Popup>
        </>
      )}
      <DialogMessagePopup
        title={"Information"}
        text={
          <Typography>
            <Typography>
              Your training/demo account has been expired. To continue using
              this email account in AMP Software, you need to upgrade.{" "}
            </Typography>
            <Typography>Do you want to proceed? </Typography>
          </Typography>
        }
        showPopup={deactivatedTrainingModal}
        setShowPopup={setDeactivatedTrainingModal}
        isSubmitting={false}
        acceptTextButton={"Accept"}
        onSave={handleAccept}
      ></DialogMessagePopup>
      {upgradeModal && (
        <UpgradeAccountModal
          showPopup={upgradeModal}
          setShowPopup={setUpgradeModal}
          email={userName ?? ""}
        ></UpgradeAccountModal>
      )}
    </React.Fragment>
  );
}

export default SignIn;
