import axios from "src/utils/axios";
import { IOutletMaster } from "src/ts/interfaces";

const getById = (id: number) => {
  return axios.get<IOutletMaster>(`OutletMaster/${id}`);
};

const update = (id: number, outletMaster: IOutletMaster) => {
  return axios.put<IOutletMaster>(`OutletMaster/${id}`, outletMaster);
};

const getLowesGriller = (id: number) => {
  return axios.get<IOutletMaster>(`OutletMaster/LowesGriller/${id}`);
};

const outletMasterService = {
  getById,
  update,
  getLowesGriller,
};

export default outletMasterService;
