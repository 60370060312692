import React from "react";
import { ColumnType } from "src/types/enhancedTable";
import LocalEnhancedTable from "src/components/localTable/LocalTable";
import { IEquipment } from "src/ts/interfaces";

const getColumns = (): ColumnType[] => [
  { id: "name", label: "Equipment Name", type: "string", sort: true },
  {
    id: "description",
    label: "Equipment Description",
    type: "string",
    sort: true,
  },
  { id: "serialNumber", label: "Serial Number", type: "string", sort: true },
  {
    id: "id",
    label: "Assigned To",
    type: "custom",
    sort: false,
    callback: (row: IEquipment) => {
      return (
        <>
          {!row.companyAssets.officeShop ? (
            `${row.companyAssets.assignedTo.name} ${row.companyAssets.assignedTo.lastName}`
          ) : (
            <>Office/Shop</>
          )}
        </>
      );
    },
  },
];

interface Props {
  allEquipments: IEquipment[];
  onSelect: (selectedIds: number[]) => void;
  selectedItemsIds: number[];
}

const CompleteEquipmentValidation = ({
  allEquipments,
  onSelect,
  selectedItemsIds,
}: Props) => {
  const columns = getColumns();

  const selectEquipmentHandler = (selected: number[]) => {
    onSelect(selected);
  };

  return (
    <LocalEnhancedTable<IEquipment>
      columns={columns}
      data={allEquipments}
      showSkeleton={false}
      cellCheckBox={true}
      onSelect={selectEquipmentHandler}
      selectedItemsId={selectedItemsIds ?? []}
    />
  );
};

export default CompleteEquipmentValidation;
