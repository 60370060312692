import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";
import {
  Grid,
  Divider as MuiDivider,
  Card as MuiCard,
  CardContent,
  Stack,
} from "@mui/material";
import { spacing } from "@mui/system";
import { ColumnType } from "src/types/enhancedTable";
import LocalEnhancedTable from "src/components/localTable/LocalTable";
import useAsyncQuery from "src/hooks/useAsyncQuery";
import { GridActionButton } from "src/components/gridControls";
import { Permission as PermissionTypes } from "src/ts/enums";
import { usePermissions } from "src/hooks";
import { IMandatoryMinimumList } from "src/ts/interfaces/catalogs/mandatoryMinimum";
import mandatoryMinimumService from "src/services/mandatoryMinimumService";
import HeaderPage from "src/components/page/HeaderPage";

const Card = styled(MuiCard)(spacing);
const Divider = styled(MuiDivider)(spacing);

const MandatoryMinimum = () => {
  const navigate = useNavigate();
  const { execute, data, isLoading } = useAsyncQuery<IMandatoryMinimumList[]>(
    mandatoryMinimumService.getAll
  );
  const [refreshPage] = useState(false);

  useEffect(() => {
    const getData = async () => {
      await execute();
    };

    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshPage]);

  const handleEdit = (id: number) => {
    navigate(`/app/MandatoryMinimum/${id}`);
  };

  const { fullAccess } = usePermissions(PermissionTypes.Catalogs);

  const columns: ColumnType[] = [
    {
      id: "code",
      label: "Code",
      type: "string",
      sort: true,
    },
    {
      id: "name",
      label: "Name",
      type: "string",
      sort: true,
    },
    {
      id: "updatedDate",
      label: "Updated Date",
      type: "utcDate",
      sort: true,
      format: "MM/DD/yyyy",
    },
    {
      id: "updatedBy",
      label: "Updated By",
      type: "string",
      sort: true,
    },
    {
      id: "x",
      label: "Actions",
      type: "custom",
      sort: false,
      callback: (row: IMandatoryMinimumList) => (
        <div>
          <Stack direction="row">
            <GridActionButton
              type={fullAccess ? "edit" : "view"}
              onClick={() => {
                handleEdit(row.reportTypeId);
              }}
              tooltip={fullAccess ? "Edit" : "View"}
            />
          </Stack>
        </div>
      ),
    },
  ];

  return (
    <>
      <HeaderPage
        title={"Mandatory Minimum"}
        parentText={"Dashboard"}
        parentLink={`/`}
      ></HeaderPage>

      <Divider my={6} />
      <Card mb={6}>
        <CardContent>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <LocalEnhancedTable<IMandatoryMinimumList>
                refreshGrid={true}
                columns={columns}
                data={data ?? []}
                showSkeleton={isLoading}
                defaultRowPerPage={10}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
};

export default MandatoryMinimum;
