/* eslint-disable react-hooks/exhaustive-deps */
import {
  CardContent,
  Grid,
  Divider as MuiDivider,
  Card as MuiCard,
  Alert,
  Stack,
  Button,
} from "@mui/material";

import { spacing } from "@mui/system";
import styled from "@emotion/styled";
import HeaderStudyPage from "src/components/page/HeaderStudyPage";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAsyncQuery, useLog, useAuth } from "src/hooks";
import {
  FormCheckSwitch,
  FormNumeric2,
  FormRadioGroup,
} from "src/components/formControls";
import useFormTyped from "src/hooks/useFormTyped";
import { IEnergyStudyAirHydronicsDTO, IReportType } from "src/ts/interfaces";
import {
  IProjectReport,
  ProjectDTO,
} from "src/ts/interfaces/project/projectDto";
import projectReportService from "src/services/study/projectReportService";
import { steps } from "../const/consts";
import { FooterButtons } from "../../components/FooterButtons";
import Popup from "src/components/Popup";
import reportTypeService from "src/services/study/reportTypeService";
import singleEnergyStudyAirHydronicsService from "src/services/study/singleEnergyStudyAirHydronicsService";
import energyStudyAirHydronicsPutService from "src/services/study/energyStudyAirHydronicsPutService";
import projectService from "src/services/project/projectService";
import { useSystemOfMeasurement } from "src/hooks";
import StudyWizard from "../../components/StudyWizard";

const initialValues: any = {
  projectName: "",
  system: "",
  equipmentLocation: "",
  areaServed: "",
};

const Divider = styled(MuiDivider)(spacing);
const Card = styled(MuiCard)(spacing);

const yesNoOptions = [
  { key: "Yes", value: "Yes" },
  { key: "No", value: "No" },
];

const EnergyHydronicsStep3 = () => {
  const { log } = useLog();
  const { user } = useAuth();
  const { systemOfMeasurement } = useSystemOfMeasurement(true);
  const params = useParams<{ id: string; step: string }>();
  const id = parseInt(params?.id === undefined ? "0" : params?.id);
  const [project, setProject] = useState<ProjectDTO>();
  const [isUpdating, setIsUpdating] = useState(false);
  const [title, setTitle] = useState<string>();
  const [code, setCode] = useState<string>();
  const [openWarningOne, setOpenWarningOne] = useState(false);
  const [openHamiltonWaring, setOpenHamiltonWarning] = useState(false);
  const [hamiltonErrorThreshold, setHamiltonErrorThreshold] = useState(0);
  const [openFiltersNotCleanWarning, setOpenFiltersNotCleanWarning] =
    useState(false);
  const [
    openFiltersNotCleanRejectionWarning,
    setOpenFiltersNotCleanRejectionWarning,
  ] = useState(false);
  const [
    openFiltersNotCleanRejectionYesWarning,
    setOpenFiltersNotCleanRejectionYesWarning,
  ] = useState(false);
  const [
    openFiltersNotCleanRejectionNoWarning,
    setOpenFiltersNotCleanRejectionNoWarning,
  ] = useState(false);

  let navigate = useNavigate();

  const validate: any = (fieldValues: IEnergyStudyAirHydronicsDTO = values) => {
    let temp: Record<string, string> = { ...errors };
    let valid = true;

    if (disableStudy()) return true;

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "" && valid);
  };

  const { values, setValues, errors, setErrors, handleInputChange } =
    useFormTyped<IEnergyStudyAirHydronicsDTO>(initialValues, false, validate);

  const activeStep = 2;

  const { execute: executeReport, data: dataReport } =
    useAsyncQuery<IProjectReport>(projectReportService.report);

  const { execute, isLoading } = useAsyncQuery<IEnergyStudyAirHydronicsDTO>(
    singleEnergyStudyAirHydronicsService.getById,
    {
      onSuccess: (dataResult) => {
        const getData = async () => {
          await systemOfMeasurement.getByProject(dataResult.projectId);
          setValues({ ...dataResult });
        };
        getData();
      },
    }
  );

  const { execute: executeReportType, isLoading: isLoadingReportType } =
    useAsyncQuery<IReportType>(reportTypeService.getReportTypeById, {
      onSuccess: (dataResult) => {
        const getData = async () => {
          setCode(dataResult.code);
          setTitle(dataResult.name);
        };
        getData();
      },
    });

  const { execute: executeProject, isLoading: isLoadingProject } =
    useAsyncQuery<ProjectDTO>(projectService.getProjectTolerancesByReportId, {
      onSuccess: (dataResult) => {
        const getData = async () => {
          setProject(dataResult);
        };
        getData();
      },
    });

  const disableStudy = () => {
    return (
      values?.isComplete ||
      (dataReport?.isInUse && dataReport?.isInUseById !== user?.userId) ||
      dataReport?.isPartOfProject === false
    );
  };

  useEffect(() => {
    if (values?.reportTypeId) executeReportType(values?.reportTypeId);
  }, [values?.reportTypeId]);

  useEffect(() => {
    const getData = async () => {
      try {
        await execute(id);
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      }
    };

    if (id !== 0) getData();
  }, [id]);

  useEffect(() => {
    const getData = async () => {
      try {
        await executeReport(id, code);
        await executeProject(id, code);
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      }
    };

    if (id !== 0 && code !== undefined) getData();
  }, [id, code]);

  useEffect(() => {
    if (values?.id && dataReport?.id)
      if (
        !values?.warningSevereDiscomfort &&
        !values?.warningDamageToAnyMechanical &&
        !disableStudy()
      )
        setOpenWarningOne(true);
  }, [values?.id, dataReport?.id]);

  useEffect(() => {
    if (systemOfMeasurement.measurementSystem === "metricSystem") {
      setHamiltonErrorThreshold(0.3472);
    }
    if (systemOfMeasurement.measurementSystem === "imperialSystem") {
      setHamiltonErrorThreshold(0.625);
    }
  }, [systemOfMeasurement.measurementSystem]);

  useEffect(() => {
    let lowest = 999999999;
    let highest = 0;

    lowest =
      (values?.hamiltonErrorTemp1 || 999999999) < lowest
        ? values?.hamiltonErrorTemp1 || 0
        : lowest;
    lowest =
      (values?.hamiltonErrorTemp2 || 999999999) < lowest
        ? values?.hamiltonErrorTemp2 || 0
        : lowest;
    lowest =
      (values?.hamiltonErrorTemp3 || 999999999) < lowest
        ? values?.hamiltonErrorTemp3 || 0
        : lowest;

    highest =
      (values?.hamiltonErrorTemp1 || 0) > highest
        ? values?.hamiltonErrorTemp1 || 0
        : highest;
    highest =
      (values?.hamiltonErrorTemp2 || 0) > highest
        ? values?.hamiltonErrorTemp2 || 0
        : highest;
    highest =
      (values?.hamiltonErrorTemp3 || 0) > highest
        ? values?.hamiltonErrorTemp3 || 0
        : highest;

    const deviation = Math.abs(highest - lowest);
    setValues({
      ...values,
      hamiltonErrorDeviation: deviation,
    });
  }, [
    values?.hamiltonErrorTemp1,
    values?.hamiltonErrorTemp2,
    values?.hamiltonErrorTemp3,
  ]);

  const saveHandler = async () => {
    if (!validate()) return;
    try {
      await saveEnergyHandler();
    } catch (error) {}
  };

  const saveEnergyHandler = async () => {
    try {
      if (!disableStudy()) {
        setIsUpdating(true);
        await energyStudyAirHydronicsPutService.update(id, values);
        log.success("Report was updated successfully");
        setIsUpdating(false);
      }
    } catch (error) {}
  };

  const nextStepHandler = async () => {
    if (!validate()) return;
    try {
      await saveEnergyHandler();
      navigate(`/studies/EnergyStudyAirHydronics/step4/${id}`);
    } catch (error) {}
  };

  const previousStepHandler = async () => {
    if (!validate()) return;
    try {
      await saveEnergyHandler();
      navigate(`/studies/EnergyStudyAirHydronics/step2/${id}`);
    } catch (error) {}
  };

  const showFiltersProcedure = () =>
    values?.identifyWhatSetpointsWillBeCommanded &&
    values?.performRealTimeDryRunPreTest &&
    values?.ensureSystemsWillOperate &&
    values?.ensureAllFanSystemsGo &&
    values?.ensureDevicesGo &&
    values?.locateIdealOutside &&
    values?.locateIdealReturn &&
    values?.locateIdealMixed &&
    values?.ensureAirFiltersClean;

  const showProcedure = () =>
    showFiltersProcedure() && !!values.airFiltersClean;

  const enableNextBtn = () =>
    showProcedure() &&
    values?.ensureEverybodyUnderstands &&
    values?.ensureAllTestPointsAreClearly &&
    values?.ensureAllBatteryPperated &&
    values?.ensureAllInstrumentsDesiredSettings &&
    values?.ensureAllInstrumentsInPlaceAndReady &&
    values?.identifyAllowTheTestProcedure &&
    values?.identifyHVAC &&
    values?.identifyMayCauseDeemed &&
    values?.pretestSystemLoadEnsured;

  const handleWarningOneOk = () => {
    if (!values?.warningSevereDiscomfort) {
      setValues({
        ...values,
        warningSevereDiscomfort: true,
      });
    } else {
      setValues({
        ...values,
        warningDamageToAnyMechanical: true,
      });
      setOpenWarningOne(false);
    }
  };

  const handleWarningOneClose = () => setOpenWarningOne(false);

  const handleHamiltonWarningClose = () => setOpenHamiltonWarning(false);

  const handleLocateIdealMixedChange = (e: any) => {
    setValues({
      ...values,
      [e.target.name]: e.target.value,
      hamiltonErrorTemp1: 0,
      hamiltonErrorTemp2: 0,
      hamiltonErrorTemp3: 0,
      hamiltonErrorDeviation: 0,
    });
    if (e.target.value) setOpenHamiltonWarning(true);
  };

  const handleAirFiltersCleanChange = (e: any) => {
    setValues({
      ...values,
      [e.target.name]: e.target.value,
      showFilterSchedule: false,
    });
    if (e.target.value === "No") setOpenFiltersNotCleanWarning(true);
  };

  const handleFiltersNotCleanClose = () => setOpenFiltersNotCleanWarning(false);

  const handleFiltersNotCleanNo = () => {
    setOpenFiltersNotCleanWarning(false);
    setOpenFiltersNotCleanRejectionWarning(true);
  };

  const handleFiltersNotCleanRejectionClose = () =>
    setOpenFiltersNotCleanRejectionWarning(false);

  const handleFiltersNotCleanRejectionYes = () => {
    setValues({
      ...values,
      airFiltersClean: "Yes",
      showFilterSchedule: true,
    });
    setOpenFiltersNotCleanRejectionWarning(false);
  };

  const handleFiltersNotCleanRejectionNo = () => {
    setOpenFiltersNotCleanRejectionWarning(false);
    setOpenFiltersNotCleanRejectionNoWarning(true);
  };

  const handleFiltersNotCleanRejectionYesClose = () =>
    setOpenFiltersNotCleanRejectionYesWarning(false);

  const handleFiltersNotCleanRejectionNoClose = () =>
    setOpenFiltersNotCleanRejectionNoWarning(false);

  return (
    <>
      <Popup
        title="Warning"
        openPopup={openWarningOne}
        setOpenPopup={setOpenWarningOne}
        onClose={handleWarningOneClose}
        disableClickOutside
        hideClose
      >
        <>
          {!values?.warningSevereDiscomfort && (
            <p>
              If outside ambient climate conditions can cause severe discomfort
              or damage due to high humidity or temperature, rescheduling of any
              "Pre Testing" will be required.
            </p>
          )}
          {values?.warningSevereDiscomfort && (
            <p>
              If outside ambient climate conditions can cause damage to any
              mechanical system coils due to extreme cold conditions,
              rescheduling of any "Pre Testing" will be required.
            </p>
          )}
        </>
        <Stack direction="row" spacing={3}>
          <Button
            variant="contained"
            color="success"
            size="small"
            onClick={handleWarningOneOk}
            autoFocus
          >
            Ok
          </Button>
        </Stack>
      </Popup>
      <Popup
        title="Comparing the Three M/A Temperature Points/Holes"
        openPopup={openHamiltonWaring}
        setOpenPopup={setOpenHamiltonWarning}
        onClose={handleHamiltonWarningClose}
        disableClickOutside
        hideClose
      >
        <>
          <p style={{ textAlign: "justify" }}>
            If more than one fan, or a Fan Array is delivering the same mixed
            O/A & R/A Air flow, proper mixing of the fluid flows{" "}
            <span style={{ color: "blue" }}>may become</span> degraded as
            associated with the O/A & R/A intake placement.
            <br />
            One single fan can also carry potential inherent error, single fan
            error must also be eliminated if present.
            <br />
            Temperature value should not exceed +-{" "}
            {hamiltonErrorThreshold.toFixed(3)}{" "}
            {systemOfMeasurement.get("temperature")} Δ/DB
            <br />
            If temperature deviation is out of tolerance, re-take the
            temperatures downstream the next{" "}
            <span style={{ color: "blue" }}>consecutively</span> available
            ducted 90º elbow.
            <br />
            Enter your 3 equally spaced duct temperature values you read below:
          </p>
          <Grid container spacing={5}>
            <Grid item xs={12} md={4}>
              <FormNumeric2
                label="Temp 1"
                name="hamiltonErrorTemp1"
                value={values?.hamiltonErrorTemp1}
                suffix={` ${systemOfMeasurement.get("temperature")}`}
                onChange={handleInputChange}
                decimalScale={2}
                maxValue={1000}
                fixedDecimalScale
                rightAlign
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <FormNumeric2
                label="Temp 2"
                name="hamiltonErrorTemp2"
                value={values?.hamiltonErrorTemp2}
                suffix={` ${systemOfMeasurement.get("temperature")}`}
                onChange={handleInputChange}
                decimalScale={2}
                maxValue={1000}
                fixedDecimalScale
                rightAlign
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <FormNumeric2
                label="Temp 3"
                name="hamiltonErrorTemp3"
                value={values?.hamiltonErrorTemp3}
                suffix={` ${systemOfMeasurement.get("temperature")}`}
                onChange={handleInputChange}
                decimalScale={2}
                maxValue={1000}
                fixedDecimalScale
                rightAlign
              />
            </Grid>
            {!!values?.hamiltonErrorDeviation && (
              <Grid item xs={12}>
                <p style={{ textAlign: "center" }}>
                  Δ DB Temperature Deviation:{" ["}
                  {values?.hamiltonErrorDeviation.toFixed(2)}{" "}
                  {systemOfMeasurement.get("temperature")}
                  {"] "}
                  <span
                    style={{
                      color:
                        values?.hamiltonErrorDeviation <= hamiltonErrorThreshold
                          ? "olive"
                          : "darkred",
                    }}
                  >
                    {values?.hamiltonErrorDeviation <= hamiltonErrorThreshold
                      ? "Acceptable"
                      : "Outside of Acceptable Tolerance"}
                  </span>
                </p>
              </Grid>
            )}
          </Grid>
        </>
        <Stack direction="row" spacing={3}>
          <Button
            variant="contained"
            color="success"
            size="small"
            onClick={handleHamiltonWarningClose}
            autoFocus
          >
            Ok
          </Button>
        </Stack>
      </Popup>
      <Popup
        title="Important"
        openPopup={openFiltersNotCleanWarning}
        setOpenPopup={setOpenFiltersNotCleanWarning}
        onClose={handleFiltersNotCleanClose}
        disableClickOutside
        hideClose
      >
        <>
          <Alert severity="warning">
            You have selected filters are not 100% clean/new for this systems
            filter test.
            <br />
            It is understood by you that no filter schedule will be produced in
            this systems report summary due to the test being performed with
            fiter media currently impacted/loaded/dirty
          </Alert>
        </>
        <Stack direction="row" spacing={3}>
          <Button
            variant="contained"
            color="error"
            onClick={handleFiltersNotCleanNo}
            size="small"
          >
            No
          </Button>
          <Button
            variant="contained"
            color="success"
            size="small"
            onClick={handleFiltersNotCleanClose}
            autoFocus
          >
            Yes
          </Button>
        </Stack>
      </Popup>
      <Popup
        title="Important"
        openPopup={openFiltersNotCleanRejectionWarning}
        setOpenPopup={setOpenFiltersNotCleanRejectionWarning}
        onClose={handleFiltersNotCleanRejectionClose}
        disableClickOutside
        hideClose
      >
        <>
          <Alert severity="info">
            Would you like to have the filters changed prior to testing?
          </Alert>
        </>
        <Stack direction="row" spacing={3}>
          <Button
            variant="contained"
            color="error"
            onClick={handleFiltersNotCleanRejectionNo}
            size="small"
          >
            No
          </Button>
          <Button
            variant="contained"
            color="success"
            size="small"
            onClick={handleFiltersNotCleanRejectionYes}
            autoFocus
          >
            Yes
          </Button>
        </Stack>
      </Popup>
      <Popup
        title="Important"
        openPopup={openFiltersNotCleanRejectionYesWarning}
        setOpenPopup={setOpenFiltersNotCleanRejectionYesWarning}
        onClose={handleFiltersNotCleanRejectionYesClose}
        disableClickOutside
        hideClose
      >
        <>
          <Alert severity="success">
            Change/Clean this systems filters and then continue moving forward
            from this point for further testing.
          </Alert>
        </>
        <Stack direction="row" spacing={3}>
          <Button
            variant="contained"
            color="success"
            size="small"
            onClick={handleFiltersNotCleanRejectionYesClose}
            autoFocus
          >
            Ok
          </Button>
        </Stack>
      </Popup>
      <Popup
        title="Important"
        openPopup={openFiltersNotCleanRejectionNoWarning}
        setOpenPopup={setOpenFiltersNotCleanRejectionNoWarning}
        onClose={handleFiltersNotCleanRejectionNoClose}
        disableClickOutside
        hideClose
      >
        <>
          <Alert severity="error">
            It is understood this systems filters are compromised and the test
            results will reflect this fact through lack of ideal heat transfer
            and poor energy performance results?
          </Alert>
        </>
        <Stack direction="row" spacing={3}>
          <Button
            variant="contained"
            color="success"
            size="small"
            onClick={handleFiltersNotCleanRejectionNoClose}
            autoFocus
          >
            Yes
          </Button>
        </Stack>
      </Popup>
      <HeaderStudyPage
        headerStudiesPage={{ code: code, system: values?.systemField, id: id }}
        parentText="Project"
        parentLink={`/app/ProjectStudies/${values?.projectId}/${user?.companyId}`}
      />
      <Divider my={6} />

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card mb={6}>
            <CardContent>
              <StudyWizard
                steps={steps}
                activeStep={activeStep}
                projectId={dataReport?.projectId}
                reportId={dataReport?.reportId}
                reportTypeId={dataReport?.reportTypeId}
              />
              <Divider />
              <h3>Pre Test & System Prep</h3>
              <Alert severity="info">
                If contracted Energy testing requires Building Engineers
                assistance with manipulating VAV's, VFD's, dampers, set points
                and a crew with Certified Technicians, it's highly recommended a
                prior system challenge dry run is performed.
              </Alert>
              <Grid container spacing={3} marginLeft={5}>
                <Grid item xs={12}>
                  <FormCheckSwitch
                    showSkeleton={
                      isLoading || isLoadingReportType || isLoadingProject
                    }
                    name="identifyWhatSetpointsWillBeCommanded"
                    label="(Consult building engineer or controls contractor) Identify what set points will be commanded to ensure constant steady state."
                    value={values?.identifyWhatSetpointsWillBeCommanded}
                    onChange={handleInputChange}
                    disabled={disableStudy() || isLoading}
                    size="medium"
                    switchStyle="Android"
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormCheckSwitch
                    showSkeleton={
                      isLoading || isLoadingReportType || isLoadingProject
                    }
                    name="performRealTimeDryRunPreTest"
                    label="Perform a real time dry run pre test. Validate all damper positions, speeds, modes, valves, etc."
                    value={values?.performRealTimeDryRunPreTest}
                    onChange={handleInputChange}
                    disabled={disableStudy() || isLoading}
                    switchStyle="Android"
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormCheckSwitch
                    showSkeleton={
                      isLoading || isLoadingReportType || isLoadingProject
                    }
                    name="ensureSystemsWillOperate"
                    label="Ensure systems will operate when commanded to the test conditions required. Perform a live dry run pre test."
                    value={values?.ensureSystemsWillOperate}
                    onChange={handleInputChange}
                    disabled={disableStudy() || isLoading}
                    switchStyle="Android"
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormCheckSwitch
                    showSkeleton={
                      isLoading || isLoadingReportType || isLoadingProject
                    }
                    name="ensureAllFanSystemsGo"
                    label={`Ensure all fan systems go to their commanded set points, ${systemOfMeasurement.get(
                      "rpm"
                    )}'s, etc.`}
                    value={values?.ensureAllFanSystemsGo}
                    onChange={handleInputChange}
                    disabled={disableStudy() || isLoading}
                    switchStyle="Android"
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormCheckSwitch
                    showSkeleton={
                      isLoading || isLoadingReportType || isLoadingProject
                    }
                    name="ensureDevicesGo"
                    label="Ensure devices go into proper test condition, devices such as all VAV's, FPB's, or Control Valves if applicable."
                    value={values?.ensureDevicesGo}
                    onChange={handleInputChange}
                    disabled={disableStudy() || isLoading}
                    switchStyle="Android"
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormCheckSwitch
                    showSkeleton={
                      isLoading || isLoadingReportType || isLoadingProject
                    }
                    name="locateIdealOutside"
                    label="(Ideal location @ O/A intake shaded) Locate Ideal Outside Air Temperature Point."
                    value={values?.locateIdealOutside}
                    onChange={handleInputChange}
                    disabled={disableStudy() || isLoading}
                    switchStyle="Android"
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormCheckSwitch
                    showSkeleton={
                      isLoading || isLoadingReportType || isLoadingProject
                    }
                    name="locateIdealReturn"
                    label="(Ideal location @ R/A intake before mixing chamber) Locate Ideal Return Air Temperature Point."
                    value={values?.locateIdealReturn}
                    onChange={handleInputChange}
                    disabled={disableStudy() || isLoading}
                    switchStyle="Android"
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormCheckSwitch
                    showSkeleton={
                      isLoading || isLoadingReportType || isLoadingProject
                    }
                    name="locateIdealMixed"
                    label="(Ideal location as learned in training) Locate Ideal Mixed Air Temperature Point."
                    value={values?.locateIdealMixed}
                    onChange={handleLocateIdealMixedChange}
                    disabled={disableStudy() || isLoading}
                    switchStyle="Android"
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormCheckSwitch
                    showSkeleton={
                      isLoading || isLoadingReportType || isLoadingProject
                    }
                    name="ensureAirFiltersClean"
                    label="Ensure Air Filters 100% clean for this pending test."
                    value={values?.ensureAirFiltersClean}
                    onChange={handleInputChange}
                    disabled={disableStudy() || isLoading}
                    switchStyle="Android"
                  />
                </Grid>
              </Grid>
              {showFiltersProcedure() && (
                <>
                  <Divider />
                  <h3>Pre Testing Filters Procedure</h3>
                  <Grid container spacing={3} marginLeft={5}>
                    <Grid item xs={12}>
                      <FormRadioGroup
                        showSkeleton={
                          isLoading || isLoadingReportType || isLoadingProject
                        }
                        name="airFiltersClean"
                        label="Air Filters are 100% clean for this test."
                        items={yesNoOptions}
                        value={values?.airFiltersClean}
                        onChange={handleAirFiltersCleanChange}
                        disabled={disableStudy() || isLoading}
                        row
                      />
                    </Grid>
                  </Grid>
                </>
              )}
              {showProcedure() && (
                <>
                  <Divider />
                  <h3>Pre Testing Procedure Checklist</h3>
                  <Alert severity="info">
                    Conduct readiness test meeting and system challenge
                  </Alert>
                  <Grid container spacing={3} marginLeft={5}>
                    <Grid item xs={12}>
                      <FormCheckSwitch
                        showSkeleton={
                          isLoading || isLoadingReportType || isLoadingProject
                        }
                        name="ensureEverybodyUnderstands"
                        label="Ensure everybody understands their jobs, scopes and goals."
                        value={values?.ensureEverybodyUnderstands}
                        onChange={handleInputChange}
                        disabled={disableStudy() || isLoading}
                        size="medium"
                        switchStyle="Android"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormCheckSwitch
                        showSkeleton={
                          isLoading || isLoadingReportType || isLoadingProject
                        }
                        name="ensureAllTestPointsAreClearly"
                        label="Ensure all test points previously prepped are clearly identified and understood what the purpose for each point is."
                        value={values?.ensureAllTestPointsAreClearly}
                        onChange={handleInputChange}
                        disabled={disableStudy() || isLoading}
                        switchStyle="Android"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormCheckSwitch
                        showSkeleton={
                          isLoading || isLoadingReportType || isLoadingProject
                        }
                        name="ensureAllBatteryPperated"
                        label="Ensure all battery operated test instruments to be used are fully charged."
                        value={values?.ensureAllBatteryPperated}
                        onChange={handleInputChange}
                        disabled={disableStudy() || isLoading}
                        switchStyle="Android"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormCheckSwitch
                        showSkeleton={
                          isLoading || isLoadingReportType || isLoadingProject
                        }
                        name="ensureAllInstrumentsDesiredSettings"
                        label="Ensure all test instruments to be used are set to their desired settings and in standby data log mode."
                        value={values?.ensureAllInstrumentsDesiredSettings}
                        onChange={handleInputChange}
                        disabled={disableStudy() || isLoading}
                        switchStyle="Android"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormCheckSwitch
                        showSkeleton={
                          isLoading || isLoadingReportType || isLoadingProject
                        }
                        name="ensureAllInstrumentsInPlaceAndReady"
                        label="Ensure all test instruments to be used are set in place and ready to log data."
                        value={values?.ensureAllInstrumentsInPlaceAndReady}
                        onChange={handleInputChange}
                        disabled={disableStudy() || isLoading}
                        switchStyle="Android"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormCheckSwitch
                        showSkeleton={
                          isLoading || isLoadingReportType || isLoadingProject
                        }
                        name="identifyAllowTheTestProcedure"
                        label="Identify if outside ambient climate conditions and local space conditions will allow the test procedure to maintain steady state. System Modulating Overrides Disabled."
                        value={values?.identifyAllowTheTestProcedure}
                        onChange={handleInputChange}
                        disabled={disableStudy() || isLoading}
                        switchStyle="Android"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormCheckSwitch
                        showSkeleton={
                          isLoading || isLoadingReportType || isLoadingProject
                        }
                        name="identifyHVAC"
                        label="Identify outside ambient climate conditions, recognize if ambient conditions can damage any HVAC mechanical coils or equipment due to temperatures and/or humidity."
                        value={values?.identifyHVAC}
                        onChange={handleInputChange}
                        disabled={disableStudy() || isLoading}
                        switchStyle="Android"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormCheckSwitch
                        showSkeleton={
                          isLoading || isLoadingReportType || isLoadingProject
                        }
                        name="identifyMayCauseDeemed"
                        label={
                          'Identify outside ambient climate conditions, recognize if ambient conditions may cause a deemed "unacceptable loss of building comfort or control due to temperatures and/or humidity".'
                        }
                        value={values?.identifyMayCauseDeemed}
                        onChange={handleInputChange}
                        disabled={disableStudy() || isLoading}
                        switchStyle="Android"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormCheckSwitch
                        showSkeleton={
                          isLoading || isLoadingReportType || isLoadingProject
                        }
                        name="pretestSystemLoadEnsured"
                        label={`When testing is performed, ensure you have a thermal load imposed on this system - If no load is present, ${
                          values?.testMode == "Cooling" ? "heat" : "cool"
                        } the space ${
                          values?.testMode == "Cooling" ? "up" : "down"
                        } while no ${
                          values?.testMode == "Cooling"
                            ? "chilled water"
                            : "hot water"
                        } is flowing.`}
                        value={values?.pretestSystemLoadEnsured}
                        onChange={handleInputChange}
                        disabled={disableStudy() || isLoading}
                        switchStyle="Android"
                      />
                    </Grid>
                  </Grid>
                </>
              )}
              <Divider />
              <FooterButtons
                activeStep={activeStep}
                stepsCount={steps.length}
                projectId={values?.projectId as number}
                companyId={user?.companyId as number}
                isSaving={isUpdating}
                disabled={
                  isUpdating ||
                  isLoading ||
                  isLoadingReportType ||
                  isLoadingProject ||
                  disableStudy()
                }
                reportName={title as string}
                saveHandler={saveHandler}
                nextStepHandler={nextStepHandler}
                disableNext={!enableNextBtn()}
                previousStepHandler={previousStepHandler}
                isComplete={values?.isComplete}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default EnergyHydronicsStep3;
