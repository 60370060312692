import React, { useEffect } from "react";
import {
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  Skeleton,
  Stack,
} from "@mui/material";

import { ColumnType } from "src/types/enhancedTable";
import EnhancedTableHead from "../enhancedTable/EnhancedTableHead";
import LocalEnhancedTableRow from "./LocalTableRow";
import EnhancedTableTotal from "../enhancedTable/EnhancedTableTotal";
import { useListSelection } from "src/hooks";

interface EnhancedTablePropsType<T> {
  refreshGrid?: boolean;
  columns: ColumnType[];
  data: T[] | null;
  query?: string;
  hidePagination?: boolean;
  defaultRowPerPage?: number;
  defaultSortColumn?: string;
  secondLevelSort?: string | "";
  cellCheckBox?: boolean;
  showSkeleton?: boolean;
  orderColumn?: "desc" | "asc";
  filterCustom?: string[];
  skeletonRows?: number;
  noDataTitle?: string;
  subItems?: string;
  rowsPerPageOptions?: number[];
  totalData?: any;
  onSelect?: (selected: number[]) => void;
  toggleAll?: boolean | null | undefined;
  selectedItemsId?: number[];
  onlyOneSelected?: boolean;
  orderBySecondLevel?: boolean;
}

const LocalEnhancedTable = <T extends {}>({
  columns,
  refreshGrid,
  data,
  query,
  hidePagination,
  defaultRowPerPage,
  defaultSortColumn,
  cellCheckBox,
  showSkeleton,
  orderColumn,
  secondLevelSort,
  filterCustom,
  skeletonRows,
  noDataTitle,
  subItems,
  rowsPerPageOptions,
  totalData,
  onSelect,
  toggleAll,
  selectedItemsId,
  onlyOneSelected,
  orderBySecondLevel,
}: EnhancedTablePropsType<T>) => {
  const [rowsPerPage, setRowsPerPage] = React.useState(defaultRowPerPage ?? 5);
  const [page, setPage] = React.useState(0);
  const [orderBy, setOrderBy] = React.useState(defaultSortColumn ?? "id");
  const [order, setOrder] = React.useState<"desc" | "asc">(
    orderColumn ?? "asc"
  );

  const handleRequestSort = (event: any, property: string) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  function descendingComparator(a: any, b: any, orderBy: string) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function orderComparator(a: any, b: any) {
    if (b < a) {
      return -1;
    }
    if (b > a) {
      return 1;
    }
    return 0;
  }

  function getComparator(order: "desc" | "asc", orderBy: string) {
    const value =
      order === "desc"
        ? (a: any, b: any) => descendingComparator(a, b, orderBy)
        : (a: any, b: any) => -descendingComparator(a, b, orderBy);
    return value;
  }

  const getProperty = (obj: any, path: string) => {
    const properties = path.split(".");
    return properties
      .reduce((acc, prop) => (acc && acc[prop] ? acc[prop] : ""), obj)
      .toUpperCase();
  };

  function stableSort(
    array: Array<any>,
    comparator: (a: any, b: any) => number
  ) {
    const stabilizedThis = array.map((el: any, index: number) => ({
      el,
      index,
    }));

    if (stabilizedThis.length > 0) {
      if (secondLevelSort !== undefined) {
        const sortedReports = [...stabilizedThis].sort((a, b) => {
          if (a.el[defaultSortColumn ?? ""] !== null) {
            let nameA = [];
            let nameB = [];

            if (orderBySecondLevel && defaultSortColumn !== orderBy) {
              nameA = getProperty(a.el, orderBy);
              nameB = getProperty(b.el, orderBy);
            } else {
              nameA =
                a.el[defaultSortColumn ?? ""][secondLevelSort ?? ""] ??
                "".toUpperCase();
              nameB =
                b.el[defaultSortColumn ?? ""][secondLevelSort ?? ""] ??
                "".toUpperCase();
            }

            return order === "desc"
              ? orderComparator(nameA, nameB)
              : -orderComparator(nameA, nameB);
          } else {
            return 0;
          }
        });
        const stabilizedThisReturn = sortedReports.map((element) => element.el);
        return stabilizedThisReturn;
      } else {
        if (orderBySecondLevel) {
          let nameA = [];
          let nameB = [];

          const sortedReports = [...stabilizedThis].sort((a, b) => {
            if (defaultSortColumn === orderBy) return 0;
            nameA =
              a.el[defaultSortColumn ?? ""][orderBy ?? ""] ?? "".toUpperCase();
            nameB =
              b.el[defaultSortColumn ?? ""][orderBy ?? ""] ?? "".toUpperCase();
            return order === "desc"
              ? orderComparator(nameA, nameB)
              : -orderComparator(nameA, nameB);
          });
          const stabilizedThisReturn = sortedReports.map(
            (element) => element.el
          );
          return stabilizedThisReturn;
        } else {
          stabilizedThis.sort((a, b) => {
            const order = comparator(a.el, b.el);
            if (order !== 0) return order;
            return a.index - b.index;
          });
        }
        const stabilizedThisReturn = stabilizedThis.map(
          (element) => element.el
        );
        return stabilizedThisReturn;
      }
    } else {
      stabilizedThis.sort((a, b) => {
        const order = comparator(a.el, b.el);
        if (order !== 0) return order;
        return a.index - b.index;
      });
      const stabilizedThisReturn = stabilizedThis.map((element) => element.el);
      return stabilizedThisReturn;
    }
  }

  const applyFilters = function <T>(
    dataSet: T[],
    query: any,
    filters: any,
    properties: any[]
  ): T[] {
    const value = dataSet.filter((row: any) => {
      let matches = true;

      if (query) {
        let containsQuery = false;

        properties.forEach((property) => {
          if (property.includes(".")) {
            var paths = property.split("."),
              current = row,
              i;

            for (i = 0; i < paths.length; ++i) {
              if (current[paths[i]] === undefined) {
                return undefined;
              } else {
                current = current[paths[i]];
                if (typeof current !== "object") {
                  if (
                    current
                      .toString()
                      .toLowerCase()
                      .includes(query.toLowerCase())
                  ) {
                    containsQuery = true;
                  }
                }
              }
            }
          } else {
            if (
              row[property] !== null &&
              row.hasOwnProperty(property) &&
              row[property]
                .toString()
                .toLowerCase()
                .includes(query.toLowerCase())
            ) {
              containsQuery = true;
            }
          }
        });

        if (filters.status && row.status !== filters.status) {
          matches = false;
        }

        if (!containsQuery) {
          matches = false;
        }
      }

      Object.keys(filters).forEach((key) => {
        const value = filters[key];

        if (value && row[key] !== value) {
          matches = false;
        }
      });

      return matches;
    });
    return value;
  };

  const applyPagination = function <T>(
    dataSet: T[],
    page: number,
    rowsPerPage: number
  ): T[] {
    return dataSet.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  };

  const properties: any = () => {
    if (filterCustom) {
      return filterCustom;
    }
    return columns.filter((col) => col.type === "string").map((col) => col.id);
  };

  const filtered = applyFilters<T>(data ?? [], query, {}, properties());
  const sortedData = stableSort(filtered, getComparator(order, orderBy));
  const paginatedProjects = applyPagination(sortedData, page, rowsPerPage);

  useEffect(() => {
    if (paginatedProjects.length === 0) {
      setPage(0);
    }
  }, [paginatedProjects]);

  useEffect(() => {
    setData(data ?? [], []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const {
    toggleSelection,
    isSelected,
    selectedIds,
    setData,
    isAllSelected,
    toggleSelectionAll,
  } = useListSelection<any>(data ?? [], selectedItemsId ?? [], onlyOneSelected); //note:Should be T but is possible no all tables has a id

  const cellCheckBoxTable = cellCheckBox === undefined ? false : cellCheckBox;

  const skeletonArray = Array(skeletonRows ?? 10).fill("");

  const toggleSelectionHandler = (id: number) => {
    toggleSelection(id);
  };

  useEffect(() => {
    if (toggleAll !== undefined && toggleAll !== null) toggleSelectionAll();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toggleAll]);

  useEffect(() => {
    onSelect && onSelect(selectedIds);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedIds]);

  return (
    <>
      <TableContainer>
        <Table size="small">
          <EnhancedTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            columns={columns.filter((x) => x.hide !== true)}
            tableCellCheckBox={cellCheckBoxTable}
            toggleSelectionAll={toggleSelectionAll}
            isAllSelected={isAllSelected}
            subItems={subItems}
            onlyOneSelected={onlyOneSelected}
          />
          <TableBody>
            {!showSkeleton && paginatedProjects.length !== 0 ? (
              paginatedProjects.map((row, index) => {
                return (
                  <>
                    <LocalEnhancedTableRow
                      index={index}
                      cellCheckBox={cellCheckBox}
                      onToggleSelection={toggleSelectionHandler}
                      isSelected={isSelected}
                      columns={columns}
                      row={row}
                      subItems={subItems}
                    ></LocalEnhancedTableRow>
                  </>
                );
              })
            ) : (
              <>
                {!showSkeleton && (
                  <TableRow>
                    <TableCell
                      colSpan={
                        subItems || cellCheckBox
                          ? columns.filter((x) => x.hide !== true).length + 1
                          : columns.filter((x) => x.hide !== true).length
                      }
                    >
                      <Stack sx={{ width: "100%" }} spacing={2}>
                        {noDataTitle === undefined
                          ? "No results were found"
                          : noDataTitle}
                      </Stack>
                    </TableCell>
                  </TableRow>
                )}
              </>
            )}

            {showSkeleton &&
              skeletonArray.map((row, indexRow) => {
                return (
                  <TableRow key={indexRow}>
                    <TableCell padding="checkbox">
                      <Skeleton />
                    </TableCell>

                    {columns.map((col, index) => {
                      return (
                        <TableCell key={col.id + "_" + indexRow + "_" + index}>
                          <Skeleton />
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
          {totalData && (
            <EnhancedTableTotal
              columns={columns.filter((x) => x.hide !== true)}
              tableCellCheckBox={cellCheckBoxTable}
              data={totalData}
            />
          )}
        </Table>
      </TableContainer>
      <TablePagination
        hidden={hidePagination}
        rowsPerPageOptions={rowsPerPageOptions ?? [5, 10, 25]}
        component="div"
        count={filtered.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
};

export default LocalEnhancedTable;
