import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { Helmet } from "react-helmet-async";
import { Grid, Divider as MuiDivider } from "@mui/material";
import { spacing } from "@mui/system";
import DialogMessagePopup from "src/components/DialogMessagePopup";
import useAppDispatch from "src/hooks/useAppDispatch";
import { floatingBarActions } from "src/redux/slices/floatingBarActions";
import { COMMON_MENU } from "src/constants";
import {
  BuildingDTO,
  IBuildingSelected,
  IProjectTypeCount,
  ProjectSelectedDashboardDTO,
} from "src/ts/interfaces";
import {
  buildingService,
  projectCurrentService,
  userService,
} from "src/services";
import { useAsyncQuery, useAuth } from "src/hooks";
import DailyLogPopUp from "src/pages/project/Components/DailyLogPopUp";
import techniciansHistoryService from "src/services/techniciansHistoryService";
import { ITechniciansHistoryByProject } from "src/ts/interfaces/techniciansHistory";
import projectService from "src/services/project/projectService";
import getProjectService from "src/services/project/getProjectService";
import getProjectSingleService from "src/services/project/getProjectSingleService";
import { useNavigate } from "react-router-dom";
import HeaderPage from "src/components/page/HeaderPage";
import { FormButton } from "src/components/formControls";
import TestingUnitCostModal from "src/components/modals/TestingUnitCostModal";
import StorageWidget from "src/pages/storage/StorageWidget";
import {
  AccountTreeOutlined,
  CorporateFareOutlined,
  HealthAndSafetyOutlined,
  HomeWorkOutlined,
  PermIdentityOutlined,
  PersonPinCircleOutlined,
} from "@mui/icons-material";
import DashboarItem from "./DashboarItem";
import DashboarItemCreate from "../Architect/DashboarItemCreate";

const Divider = styled(MuiDivider)(spacing);

const CompanyOwnerDashboard = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [showUnitCostPopUp, setShowUnitCostPopUp] = useState(false);
  const [showDailyLogPopUp, setShowDailyLogPopUp] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const {
    execute,
    data: projectCurrent,
    isLoading: isProjectCurrentLoading,
  } = useAsyncQuery<ProjectSelectedDashboardDTO>(
    projectCurrentService.getProjectCurrent
  );
  const {
    execute: getBuilding,
    data: building,
    isLoading: isBuildingLoading,
  } = useAsyncQuery<BuildingDTO>(buildingService.getBuildingByProject);

  const {
    execute: getHistoryProject,
    data: historyProject,
    isLoading: isHistoryProjectLoading,
  } = useAsyncQuery<ITechniciansHistoryByProject>(
    techniciansHistoryService.getTechByProject
  );

  const {
    execute: getCountByType,
    data: countByType,
    isLoading: isCountByType,
  } = useAsyncQuery<IProjectTypeCount[]>(getProjectService.countByType);

  const {
    execute: getMyActiveProjects,
    data: myActiveProjects,
    isLoading: isMyActiveProjects,
  } = useAsyncQuery<number>(projectService.getMyActiveProjects);

  const {
    execute: getMyCompletedProjects,
    data: myCompletedProjects,
    isLoading: isMyCompletedProjects,
  } = useAsyncQuery<IProjectTypeCount[]>(
    getProjectSingleService.getCountCompleteByType
  );

  const {
    execute: getAuditoCurrent,
    data: auditoCurrent,
    isLoading: isAuditoCurrent,
  } = useAsyncQuery<IProjectTypeCount[]>(userService.getCountUsers);

  const {
    execute: getBuildingByCompany,
    data: buildingByCompany,
    isLoading: isBuildingByCompany,
  } = useAsyncQuery<IBuildingSelected[]>(buildingService.getByCompany);

  const dispatch = useAppDispatch();
  useEffect(() => {
    const getData = async () => {
      execute();
      getMyActiveProjects();
      getMyCompletedProjects();
      getCountByType();
      getAuditoCurrent();
      getBuildingByCompany(user?.companyId);
    };
    const getProjectID = () => {
      dispatch(floatingBarActions.setOptionMenu(COMMON_MENU));
    };
    getData();
    getProjectID();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const getData = async () => {
      getBuilding(projectCurrent?.project?.id);
      getHistoryProject(projectCurrent?.project?.id);
    };
    if (projectCurrent?.project) getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectCurrent]);

  const showUnderConstruction = () => {
    setShowDeleteModal(true);
  };

  const handleOpenDailyLog = () => {
    setShowDailyLogPopUp(true);
  };

  const handleRedirectEquiment = () => {
    navigate(`/app/companyAssets/${user?.companyId}/EQIP`);
  };

  const handleRedirectBuilding = () => {
    navigate(`/app/Buildings/${user?.companyId}`);
  };

  const getBuildingAddress = () => {
    if (building == null) return "";

    let stateName = "";
    if (building?.state == null) {
      stateName = building?.stateName ?? "";
    } else {
      stateName = building?.state?.name ?? "";
    }

    return `${building?.address ?? ""}, ${building?.address2 ?? ""}, ${
      building?.city ?? ""
    }, ${stateName}, ${building?.zip ?? ""}`;
  };

  const isLoading = () => {
    return isProjectCurrentLoading && isBuildingLoading;
  };

  const handleOpenTabGlobalCost = () => {
    window.open("https://www.globalpetrolprices.com/", "_blank", "noreferrer");
  };

  const handleRedirectProjectList = () => {
    navigate(`/app/ProjectList/${user?.companyId}`);
  };

  const handleGotoCompleteProject = () => {
    navigate(`/app/ProjectList/Completed/${user?.companyId}`);
  };

  const handleGotoMyActiveProjects = () => {
    navigate(`/app/ProjectList/MyActiveProjects/${user?.companyId}`);
  };

  return (
    <React.Fragment>
      <Helmet title="Dashboard" />
      <HeaderPage
        title={"Dashboard"}
        parentText={"Dashboard"}
        parentLink={`/`}
        actionSection={
          <Grid container>
            <StorageWidget />
            <Grid item p={1}>
              <FormButton
                text={`$ Testing Unit Cost`}
                variant="outlined"
                onClick={() => {
                  setShowUnitCostPopUp(true);
                }}
                size="small"
                color="success"
              />
              <TestingUnitCostModal
                showPopup={showUnitCostPopUp}
                setShowPopup={setShowUnitCostPopUp}
              ></TestingUnitCostModal>
            </Grid>
            <Grid item p={1}>
              <FormButton
                text={`Global Energy Cost`}
                variant="outlined"
                onClick={handleOpenTabGlobalCost}
                size="small"
                color="success"
              />
            </Grid>
          </Grid>
        }
      ></HeaderPage>
      <Divider my={6} />
      <Grid container spacing={5}>
        <Grid item xs={6} sm={6} md={6} lg={6}>
          <DashboarItem
            title={"Current Project"}
            label2={
              <HomeWorkOutlined style={{ float: "right", fontSize: 40 }} />
            }
            showSkeleton={false}
            action={() => {
              navigate(
                `/app/Project/Edit/${projectCurrent?.project?.id}/${user?.companyId}`
              );
            }}
            label1={projectCurrent?.project?.name}
            chip={""}
            chipColor="primary"
            textDetails={
              (projectCurrent?.project?.name ? "View details" : null) ??
              "No current project has been defined."
            }
            hideViewDetails={!projectCurrent?.project?.name}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={6} lg={6}>
          <DashboarItem
            title={"Current Project Daily Log"}
            label2={
              <PersonPinCircleOutlined
                style={{ float: "right", fontSize: 40 }}
              />
            }
            showSkeleton={false}
            action={handleOpenDailyLog}
            label1={projectCurrent?.project?.name}
            chip={""}
            chipColor="primary"
            textDetails={
              (projectCurrent?.project?.name ? "View details" : null) ??
              "No current project has been defined."
            }
            hideViewDetails={!projectCurrent?.project?.name}
          />
          {projectCurrent !== null && projectCurrent?.project?.id !== 0 && (
            <DailyLogPopUp
              showDailyLogPopUp={showDailyLogPopUp}
              setShowDailyLogPopUp={setShowDailyLogPopUp}
              projectId={projectCurrent?.project?.id ?? 0}
            ></DailyLogPopUp>
          )}
        </Grid>
      </Grid>
      <Grid container spacing={5} pt={5}>
        <Grid item xs={6} sm={6} md={6} lg={4}>
          <DashboarItem
            title="Regular Projects"
            action={handleRedirectProjectList}
            showSkeleton={isCountByType}
            chip={`${
              countByType !== null ? countByType[0]?.count.toString() : "0"
            }`}
            chipColor="primary"
            label1={""}
            label2={
              <CorporateFareOutlined style={{ float: "right", fontSize: 40 }} />
            }
          />
        </Grid>
        <Grid item xs={6} sm={6} md={6} lg={4}>
          <DashboarItem
            title="Completed Regular Projects"
            chip={`${
              myCompletedProjects !== null ? myCompletedProjects[0]?.count : "0"
            }`}
            action={handleGotoCompleteProject}
            showSkeleton={isMyCompletedProjects}
            label2={
              <CorporateFareOutlined style={{ float: "right", fontSize: 40 }} />
            }
            chipColor="primary"
            label1={""}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={6} lg={4}>
          <DashboarItem
            title="My Active Projects"
            chip={myActiveProjects?.toString()}
            chipColor="primary"
            label2={
              <AccountTreeOutlined style={{ float: "right", fontSize: 40 }} />
            }
            action={handleGotoMyActiveProjects}
            showSkeleton={isMyActiveProjects}
            label1={""}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={6} lg={4}>
          <DashboarItem
            title="Infectious Control Compliancy Projects"
            action={handleRedirectProjectList}
            showSkeleton={isCountByType}
            chip={`${
              countByType !== null ? countByType[1]?.count.toString() : "0"
            }`}
            chipColor="primary"
            label1={""}
            label2={
              <HealthAndSafetyOutlined
                style={{ float: "right", fontSize: 40 }}
              />
            }
          />
        </Grid>
        <Grid item xs={6} sm={6} md={6} lg={4}>
          <DashboarItem
            title="Completed Infect Ctrl Compliancy Projects"
            chip={`${
              myCompletedProjects !== null ? myCompletedProjects[1]?.count : "0"
            }`}
            action={handleGotoCompleteProject}
            showSkeleton={isMyCompletedProjects}
            label2={
              <HealthAndSafetyOutlined
                style={{ float: "right", fontSize: 40 }}
              />
            }
            chipColor="primary"
            label1={""}
          />
        </Grid>

        <Grid item xs={6} sm={6} md={6} lg={4}>
          <DashboarItem
            title="Users"
            chip={auditoCurrent === null ? "0" : auditoCurrent.toString()}
            action={() => {
              navigate(`/app/users/${user?.companyId}`);
            }}
            showSkeleton={isAuditoCurrent}
            label2={
              <PermIdentityOutlined style={{ float: "right", fontSize: 40 }} />
            }
            chipColor="primary"
            label1={""}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={6} lg={4}>
          <DashboarItem
            title="Building Database"
            label2={
              <PermIdentityOutlined style={{ float: "right", fontSize: 40 }} />
            }
            chip={
              buildingByCompany === null
                ? "0"
                : buildingByCompany.length.toString()
            }
            chipColor="primary"
            action={handleRedirectBuilding}
            showSkeleton={isBuildingByCompany}
            label1={""}
          />
        </Grid>
      </Grid>
      <Grid container spacing={5} pt={5}>
        <Grid item xs={6} sm={6} md={4} lg={4}>
          <DashboarItemCreate
            title={"PROJECT"}
            label1={"Create new Projects"}
            label2={"Create Project"}
            showSkeleton={false}
            action={() => {
              navigate(`/app/Project/Edit/0/${user?.companyId}`);
            }}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={4} lg={4}>
          <DashboarItemCreate
            title={"BUILDING"}
            label1={"Create new buildings"}
            label2={"Create Building"}
            showSkeleton={false}
            action={() => {
              navigate(`/app/Building/0/${user?.companyId}`);
            }}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={4} lg={4}>
          <DashboarItemCreate
            title={"Field Test Equipment - List & Calibration"}
            label1={"View Test Equipment"}
            label2={"View Test Equipment"}
            showSkeleton={false}
            action={handleRedirectEquiment}
          />
        </Grid>
      </Grid>
      <DialogMessagePopup
        title={"Information"}
        text={"under construction"}
        showPopup={showDeleteModal}
        setShowPopup={setShowDeleteModal}
        onSave={() => setShowDeleteModal(false)}
        isSubmitting={false}
      />
    </React.Fragment>
  );
};

export default CompanyOwnerDashboard;
