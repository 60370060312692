import { IKeyValue, IOutletDuctType } from "src/ts/interfaces";
import axios from "src/utils/axios";

const getAll = () => {
  return axios.get<IOutletDuctType[]>("OutletDuctType");
};

const getKeyValue = () => {
  return new Promise<{ data: IKeyValue<number, string>[] }>(
    (resolve, reject) => {
      axios
        .get<IOutletDuctType[]>("OutletDuctType")
        .then((response) => {
          const keyValuePair: IKeyValue<number, string>[] = response.data.map(
            (item) => {
              return {
                key: item.id,
                value: item.name,
              };
            }
          );

          resolve({ data: keyValuePair });
        })
        .catch((error) => {
          reject(error);
        });
    }
  );
};

const outletDuctTypeService = {
  getAll,
  getKeyValue,
};

export default outletDuctTypeService;
