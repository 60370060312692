import axios from "../utils/axios";

const downloadFile = async (pictureId: number) => {
  return axios.get(`PlaquePicture/file?id=${pictureId}`);
};

const deletePicture = async (pictureId: number) => {
  return axios.delete(`PlaquePicture/${pictureId}`);
};

const uploadFile = async (file: File, pictureId: string) => {
  let bodyFormData = new FormData();
  bodyFormData.append("fileForUpload", file);
  bodyFormData.append("key", pictureId);

  return await axios({
    method: "post",
    url: "/PlaquePicture/upload",
    data: bodyFormData,
    headers: { "Content-Type": "multipart/form-data" },
  });
};

const plaquePictureService = { downloadFile, deletePicture, uploadFile };
export default plaquePictureService;
