import React, { useState } from "react";
import Popup from "src/components/Popup";
import { FormCancelButton, FormTextArea } from "src/components/formControls";
import { Button, Stack, Typography } from "@mui/material";
import { useForm } from "src/hooks";
import useLog from "src/hooks/useLog";
import { technicianQuizService } from "src/services";

interface InformationProps {
  showDisableModal: boolean;
  setShowDisableModal: (val: boolean) => void;
  id: number;
  status: boolean;
  onSave: () => void;
}

const initialValues = {
  deactivationReason: "",
};

export default function DisableQuizPopup({
  showDisableModal,
  setShowDisableModal,
  id,
  status,
  onSave,
}: InformationProps) {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { log } = useLog();

  const validate = (fieldValues = values) => {
    let temp: Record<string, string> = { ...errors };

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, errors, setErrors, handleInputChange, resetForm } = useForm(
    initialValues,
    false,
    validate
  );

  const handleSave = async () => {
    try {
      setIsSubmitting(true);
      await technicianQuizService.changeStatusQuiz(
        id,
        values.deactivationReason
      );
      onSave();
      resetForm();
      log.success(
        "The test was successfully " +
          (status === true ? "deactivated" : "activated")
      );
      setShowDisableModal(false);
    } catch (error: any) {
      log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      setIsSubmitting(false);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleClosePopUp = () => {
    resetForm();
    setShowDisableModal(false);
  };

  return (
    <>
      <Popup
        title="Deactivate Quiz"
        openPopup={showDisableModal}
        setOpenPopup={setShowDisableModal}
        onClose={handleClosePopUp}
        size="sm"
      >
        <>
          <Stack mb={4}>
            <Typography>
              Reason for {status === true ? "deactivation" : "activation"}:
            </Typography>
          </Stack>
          <FormTextArea
            name="deactivationReason"
            label={
              "Reason for " +
              (status === true ? "deactivation" : "activation") +
              ":"
            }
            value={values.deactivationReason}
            onChange={handleInputChange}
            error={errors.deactivationReason}
            placeholder={
              "Be descriptive when entering reason for " +
              (status === true ? "deactivation" : "activation")
            }
          />
        </>
        <Stack direction="row" spacing={3}>
          <FormCancelButton
            onClick={handleClosePopUp}
            isSubmitting={isSubmitting}
          />
          <Button
            type="submit"
            variant="contained"
            color="primary"
            onClick={handleSave}
          >
            Accept
          </Button>
        </Stack>
      </Popup>
    </>
  );
}
