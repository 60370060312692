import { useNavigate } from "react-router-dom";
import { useAuth } from "../hooks";

const parseJwt = (token: any) => {
  try {
    return JSON.parse(atob(token.split(".")[1]));
  } catch (e) {
    return null;
  }
};

const AuthVerify = async () => {
  const { signOut } = useAuth();
  const user = JSON.parse(localStorage.getItem("authorizationData") || "{}");
  const navigate = useNavigate();

  if (user) {
    const decodedJwt = parseJwt(user.access_token);
    if (decodedJwt.exp * 1000 < Date.now()) {
      await signOut();
      navigate("/auth/sign-in");
    }
  }

  return;
};

export default AuthVerify;
