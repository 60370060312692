import Popup from "src/components/Popup";
import { FormButton } from "src/components/formControls";
import { Stack, Typography } from "@mui/material";
import { useForm, useLog } from "src/hooks";
import FormTextHideText from "src/components/formControls/FormTextHideText";
import { AuthenticationService } from "src/services";
import assetOutOfService from "src/services/assetOutOfService";

interface ReactiveProps {
  passwordShowPopUp: boolean;
  setPasswordShowPopUp: (val: boolean) => void;
  setReactiveAssetShowPopUp: (val: boolean) => void;
  setRefreshGrid: (val: boolean) => void;
  assetId: string;
  comments: string;
  refreshGrid: boolean;
}

const initialValues = {
  password: "",
};

export default function PasswordPopUp({
  passwordShowPopUp,
  setPasswordShowPopUp,
  comments,
  setReactiveAssetShowPopUp,
  assetId,
  setRefreshGrid,
  refreshGrid,
}: ReactiveProps) {
  const { log } = useLog();

  const { values, handleInputChange, errors } = useForm(
    initialValues,
    false,
    {}
  );

  const handlerProceed = async () => {
    try {
      await AuthenticationService.checkSystemPassword(values.password);
      await assetOutOfService.putReactiveAssetOutOfService(assetId, comments);
      log.success("The asset was reactivated.");
      setRefreshGrid(!refreshGrid);
      setPasswordShowPopUp(false);
      setReactiveAssetShowPopUp(false);
    } catch (error: any) {
      if (error.message.title === "Not Found") {
        log.error("Invalid Password");
        setPasswordShowPopUp(false);
        setReactiveAssetShowPopUp(false);
      } else {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      }
    }
  };

  return (
    <>
      <Popup
        title={`Security Confirmation`}
        openPopup={passwordShowPopUp}
        setOpenPopup={setPasswordShowPopUp}
        onClose={() => {
          setPasswordShowPopUp(false);
        }}
        size="sm"
      >
        <>
          <Stack mt={1}>
            <Typography>Please enter your password to confirm</Typography>
          </Stack>
          <Stack textAlign="center" mt={2}>
            <FormTextHideText
              label="Password"
              name="password"
              value={values.password}
              onChange={handleInputChange}
            />
          </Stack>
        </>
        <Stack direction="row" spacing={3}>
          <FormButton
            size="small"
            text="Close"
            variant="outlined"
            onClick={() => {
              setPasswordShowPopUp(false);
            }}
            error={errors.comments}
          />
          <FormButton
            size="small"
            text="Proceed"
            color="primary"
            onClick={() => {
              handlerProceed();
            }}
          />
        </Stack>
      </Popup>
    </>
  );
}
