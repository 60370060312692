import {
  Drawer,
  Grid,
  IconButton,
  Divider as MuiDivider,
  Typography,
} from "@mui/material";
import styled from "@emotion/styled";
import { spacing, useTheme } from "@mui/system";
import { ChevronLeft, ChevronRight, ExpandMore } from "@mui/icons-material";
import { TreeItem } from "@mui/x-tree-view/TreeItem";
import { TreeView } from "@mui/x-tree-view/TreeView";
import { useEffect, useState } from "react";
import ConversionsService from "src/services/conversionsService";
import { IConversionsList } from "src/ts/interfaces/catalogs";
import { FormText } from "src/components/formControls";
import SyncAltIcon from "@mui/icons-material/SyncAlt";

const Divider = styled(MuiDivider)(spacing);

interface ConversionsPopUpProps {
  showConversions: boolean;
  setShowConversions: (val: boolean) => void;
}

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: "flex-start",
}));

const drawerWidth = 400;

export default function ConversionsPopUp({
  showConversions,
  setShowConversions,
}: ConversionsPopUpProps) {
  const theme = useTheme();
  const [allConversions, setAllConversions] = useState<IConversionsList[]>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [conv, setConv] = useState<IConversionsList>();
  const [conversionFilter, setConversionFilter] = useState("");

  useEffect(() => {
    const getData = async () => {
      const result = await ConversionsService.getAll();
      setAllConversions(result.data);
    };

    getData();
  }, []);

  const handleCloseConversions = () => {
    setShowConversions(false);
  };

  const handleCalculateFormula = (
    e: any,
    item: IConversionsList,
    isFrom: boolean
  ) => {
    if (isFrom) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const input = e.target.value;
      item.fromValue = e.target.value;
      // eslint-disable-next-line no-eval
      item.toValue = eval(item.formulaFrom).toFixed(3);
    } else {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const input = e.target.value;
      item.toValue = e.target.value;
      // eslint-disable-next-line no-eval
      item.fromValue = eval(item.formulaTo).toFixed(3);
    }
    setConv({ ...item });
  };

  return (
    <>
      <Drawer
        anchor="right"
        open={showConversions}
        onClose={handleCloseConversions}
        variant="persistent"
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
          },
        }}
      >
        <>
          <DrawerHeader>
            <IconButton onClick={handleCloseConversions}>
              {theme.direction === "rtl" ? <ChevronLeft /> : <ChevronRight />}
            </IconButton>
            <Typography variant="h4">Conversions</Typography>
          </DrawerHeader>
          <Divider />
          <Grid container sx={{ p: "0" }}>
            <Grid item md={12} p={3}>
              <FormText
                name={"search"}
                label={"Search"}
                value={conversionFilter}
                type="text"
                onChange={(e) => setConversionFilter(e.target.value.toString())}
              />
            </Grid>
            <Grid item md={12} sx={{ p: "0" }}>
              <TreeView
                aria-label="Conversions"
                defaultCollapseIcon={<ExpandMore />}
                defaultExpandIcon={<ChevronRight />}
                sx={{
                  flexGrow: 1,
                  maxwidth: 380,
                  width: 380,
                  overflowY: "auto",
                  p: "0",
                }}
              >
                {allConversions
                  .filter((conversions) => {
                    return (
                      conversions.fromName
                        .toLowerCase()
                        .includes(conversionFilter.toLowerCase()) ||
                      conversions.toName
                        .toLowerCase()
                        .includes(conversionFilter.toLowerCase())
                    );
                  })
                  .map((item, key) => {
                    return (
                      <TreeItem
                        nodeId={key.toString()}
                        label={
                          <>
                            {item.fromName}{" "}
                            <SyncAltIcon
                              fontSize="small"
                              sx={{ paddingTop: "5px" }}
                            />{" "}
                            {}
                            {item.toName}
                          </>
                        }
                        sx={{
                          p: 2,
                        }}
                      >
                        <Grid container p={1}>
                          <Grid item md={5.5} p={1}>
                            <FormText
                              name={item.fromName}
                              label={item.fromName}
                              value={item.fromValue || ""}
                              type="number"
                              onChange={(e) => {
                                handleCalculateFormula(e, item, true);
                              }}
                            />
                          </Grid>
                          <Grid
                            item
                            xs={1}
                            sx={{ paddingTop: "10px", paddingLeft: "6px" }}
                          >
                            <Typography variant="h4">=</Typography>
                          </Grid>
                          <Grid item md={5.5} p={1}>
                            <FormText
                              name={item.toName}
                              label={item.toName}
                              value={item.toValue || ""}
                              type="number"
                              onChange={(e) => {
                                handleCalculateFormula(e, item, false);
                              }}
                            />
                          </Grid>
                        </Grid>
                      </TreeItem>
                    );
                  })}
              </TreeView>
            </Grid>
          </Grid>
        </>
      </Drawer>
    </>
  );
}
