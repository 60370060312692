import React, { useEffect, useState } from "react";
import { NavigateFunction, useNavigate } from "react-router-dom";
import styled from "@emotion/styled";
import { spacing, SpacingProps } from "@mui/system";
import { Chip as MuiChip, IconButton, Stack, Typography } from "@mui/material";
import { PeopleAltIcon, ForwardIcon } from "src/components/icons";
import { ColumnType } from "src/types/enhancedTable";
import { ICompanyData } from "src/ts/interfaces";
import CompanyService from "src/services/companyService";
import { useTable } from "src/hooks/useTable";
import { FilterType } from "./types/FilterTypes";
import { getQueryString } from "./util/common";
import ChangeStatusPopup from "./components/ChangeStatusPopup";
import BlockingWalletPopup from "./components/BlockingWalletPopup";
import UnBlockWalletPopup from "./components/UnBlockWalletPopup";
import { GridActionButton } from "src/components/gridControls";
import useLoading from "src/hooks/useLoading";
import { ToolTip } from "src/components/others/ToolTip";
import { Permission as PermissionTypes } from "src/ts/enums";
import { useAsyncQuery, useAuth, useLog, usePermissions } from "src/hooks";
import { FormCheckBox } from "src/components/formControls";
import DialogMessagePopup from "src/components/DialogMessagePopup";
import { AuthenticationService } from "src/services";
import { setSession } from "src/utils/jwt";
import { ROLES } from "src/constants";

interface ChipProps extends SpacingProps {
  component?: React.ElementType;
  href?: string;
  icon?: JSX.Element | null;
}

const Chip = styled(MuiChip)<ChipProps>(spacing);

const getColumns = (
  navigate: NavigateFunction,
  openDisablePopup: (val: boolean) => void,
  setSelectedCompany: (val: ICompanyData) => void,
  setShowBlockWalletPopup: (val: boolean) => void,
  setShowUnBlockWalletPopup: (val: boolean) => void,
  fullAccess: boolean | undefined,
  disableCompany: boolean | undefined,
  accessDoc: boolean | undefined,
  userCompanyId: string,
  handleSelect: (selectedItem: ICompanyData) => void,
  userRole: string,
  readUsers: boolean
): ColumnType[] => [
  {
    id: "name",
    label: "Company Name",
    type: "string",
    sort: true,
    callback: (row: ICompanyData) => <div>{row.name}</div>,
  },
  {
    id: "companyType",
    label: "Company Type",
    type: "custom",
    sort: true,
    callback: (row: ICompanyData) => {
      return (
        <>
          {!row.boma ? (
            <Chip label={row.companyType} color="primary" m={1} size="small" />
          ) : (
            <Chip
              label={row.companyType}
              color="primary"
              variant="outlined"
              m={1}
              size="small"
            />
          )}
          {row.satelliteCount > 0 ? (
            <Chip
              label={`Satellite Companies: ${row.satelliteCount}`}
              color="info"
              m={1}
              size="small"
            />
          ) : null}
        </>
      );
    },
  },
  {
    id: "createdDate",
    label: "Created Date",
    type: "date",
    format: "MM/DD/YYYY",
    sort: true,
    hide: userRole === ROLES.UnionAdministrator,
  },
  {
    id: "localAndLabor",
    label: "Local and Labor Unions",
    type: "custom",
    sort: false,
    hide: userRole !== ROLES.UnionAdministrator,
    minWidth: "170px",
    callback: (row: ICompanyData) => (
      <>
        <Typography variant="subtitle2">{row.laborUnion}</Typography>
        <Typography>{row.localUnionName}</Typography>
      </>
    ),
  },
  {
    id: "RequestedDate",
    label: "Requested Date",
    type: "date",
    format: "MM/DD/YYYY",
    sort: true,
    hide: userRole !== ROLES.UnionAdministrator,
  },
  {
    id: "ownerName",
    label: "Owner",
    type: "string",
    sort: true,
  },
  {
    id: "email",
    label: "Email",
    type: "string",
    sort: true,
  },
  {
    id: "x",
    label: "Actions",
    type: "custom",
    sort: false,
    callback: (row: ICompanyData) => (
      <div>
        <Stack direction="row">
          <GridActionButton
            type={fullAccess ? "edit" : "forward"}
            onClick={() => {
              navigate(`/app/company/${row.id}`);
            }}
            tooltip={fullAccess ? "Edit" : "View"}
          />

          {disableCompany && fullAccess && (
            <GridActionButton
              type="disable"
              onClick={() => {
                openDisablePopup(true);
                setSelectedCompany(row);
              }}
              tooltip="Disable"
            />
          )}

          {fullAccess && (
            <GridActionButton
              type={row.walletBlocked ? "creditCardOff" : "creditCard"}
              onClick={() => {
                row.walletBlocked
                  ? setShowUnBlockWalletPopup(true)
                  : setShowBlockWalletPopup(true);

                setSelectedCompany(row);
              }}
              tooltip="Block Wallet"
            />
          )}

          {accessDoc && (
            <GridActionButton
              type="folder"
              onClick={() => {
                navigate(`/app/companyDocuments/${row.id}`);
              }}
              tooltip="Documents"
            />
          )}
        </Stack>
      </div>
    ),
  },
  {
    id: "y",
    label: "View Users",
    type: "custom",
    sort: false,
    hideColumn: !readUsers,
    callback: (row: ICompanyData) => (
      <ToolTip title={"View Users"}>
        <IconButton
          aria-label="delete"
          size="large"
          onClick={() => {
            navigate(`/app/users/${row.id}`);
          }}
        >
          <PeopleAltIcon />
        </IconButton>
      </ToolTip>
    ),
  },
  {
    id: "z",
    label: "Satellite Companies",
    type: "custom",
    sort: false,
    hide: userRole === ROLES.UnionAdministrator,
    callback: (row: ICompanyData) => (
      <ToolTip title={"View Satellite Companies"}>
        <IconButton
          disabled={row.satelliteCount <= 0}
          aria-label="delete"
          size="large"
          onClick={() => {
            navigate(`/app/companies/${row.id}`);
          }}
        >
          <ForwardIcon />
        </IconButton>
      </ToolTip>
    ),
  },
];

const getColumnsIsAffiliated = (
  navigate: NavigateFunction,
  openDisablePopup: (val: boolean) => void,
  setSelectedCompany: (val: ICompanyData) => void,
  setShowBlockWalletPopup: (val: boolean) => void,
  setShowUnBlockWalletPopup: (val: boolean) => void,
  fullAccess: boolean | undefined,
  disableCompany: boolean | undefined,
  accessDoc: boolean | undefined,
  userCompanyId: string,
  handleSelect: (selectedItem: ICompanyData) => void,
  userRole: string,
  readUsers: boolean
): ColumnType[] => [
  {
    id: "name",
    label: "Company Name",
    type: "string",
    sort: true,
    callback: (row: ICompanyData) => <div>{row.name}</div>,
  },
  {
    id: "companyType",
    label: "Company Type",
    type: "custom",
    sort: true,
    callback: (row: ICompanyData) => {
      return (
        <>
          {!row.boma ? (
            <Chip label={row.companyType} color="primary" m={1} size="small" />
          ) : (
            <Chip
              label={row.companyType}
              color="primary"
              variant="outlined"
              m={1}
              size="small"
            />
          )}
          {row.satelliteCount > 0 ? (
            <Chip
              label={`Satellite Companies: ${row.satelliteCount}`}
              color="info"
              m={1}
              size="small"
            />
          ) : null}
        </>
      );
    },
  },
  {
    id: "localUnion",
    label: "Local Union #",
    type: "string",
    sort: true,
  },
  {
    id: "createdDate",
    label: "Created Date",
    type: "date",
    format: "MM/DD/YYYY",
    sort: true,
  },
  {
    id: "ownerName",
    label: "Owner",
    type: "string",
    sort: true,
  },
  {
    id: "email",
    label: "Email",
    type: "string",
    sort: true,
  },
  {
    id: "x",
    label: "Actions",
    type: "custom",
    sort: false,
    callback: (row: ICompanyData) => (
      <div>
        <Stack direction="row">
          <GridActionButton
            type={fullAccess ? "edit" : "forward"}
            onClick={() => {
              navigate(`/app/company/${row.id}`);
            }}
            tooltip={fullAccess ? "Edit" : "View"}
          />

          {disableCompany && fullAccess && (
            <GridActionButton
              type="disable"
              onClick={() => {
                openDisablePopup(true);
                setSelectedCompany(row);
              }}
              tooltip="Disable"
            />
          )}

          {fullAccess && (
            <GridActionButton
              type={row.walletBlocked ? "creditCardOff" : "creditCard"}
              onClick={() => {
                row.walletBlocked
                  ? setShowUnBlockWalletPopup(true)
                  : setShowBlockWalletPopup(true);

                setSelectedCompany(row);
              }}
              tooltip="Block Wallet"
            />
          )}

          {accessDoc && (
            <GridActionButton
              type="folder"
              onClick={() => {
                navigate(`/app/companyDocuments/${row.id}`);
              }}
              tooltip="Documents"
            />
          )}
        </Stack>
      </div>
    ),
  },
  {
    id: "y",
    label: "View Users",
    type: "custom",
    sort: false,
    hideColumn: !readUsers,
    callback: (row: ICompanyData) => (
      <ToolTip title={"View Users"}>
        <IconButton
          aria-label="delete"
          size="large"
          onClick={() => {
            navigate(`/app/users/${row.id}`);
          }}
        >
          <PeopleAltIcon />
        </IconButton>
      </ToolTip>
    ),
  },
  {
    id: "z",
    label: "Satellite Companies",
    type: "custom",
    sort: false,
    callback: (row: ICompanyData) => (
      <ToolTip title={"View Satellite Companies"}>
        <IconButton
          disabled={row.satelliteCount <= 0}
          aria-label="delete"
          size="large"
          onClick={() => {
            navigate(`/app/companies/${row.id}`);
          }}
        >
          <ForwardIcon />
        </IconButton>
      </ToolTip>
    ),
  },
];

interface companyTableProps {
  filters: FilterType;
  refresh: boolean;
  parentCompanyId: number;
}

function PendingCompanies(props: companyTableProps) {
  const { log } = useLog();
  const { user } = useAuth();
  const [companyUpdatedModal, setCompanyUpdatedModal] = useState(false);
  const { parentCompanyId } = props;
  let navigate = useNavigate();
  const { startRequest, endRequest } = useLoading();
  const [selectedCompany, setSelectedCompany] = useState<ICompanyData>();
  const [disablePopup, setDisablePopup] = useState(false);
  const [showBlockWalletPopup, setShowBlockWalletPopup] = useState(false);
  const [showUnBlockWalletPopup, setShowUnBlockWalletPopup] = useState(false);

  const { fullAccess } = usePermissions(PermissionTypes.Companies);
  const { fullAccess: fullAccessStatus } = usePermissions(
    PermissionTypes.Companies_Active_Inactive
  );
  const { fullAccess: fullAccessDoc, readOnly: readOnlyDoc } = usePermissions(
    PermissionTypes.Company_Documents
  );

  const { read: readUsers } = usePermissions(PermissionTypes.Users);

  const [companyName, setCompanyName] = useState("");
  const [companyAddress, setCompanyAddress] = useState("");
  const handleSelect = async (selectedItem: ICompanyData) => {
    if (selectedItem && selectedItem?.id !== user?.CompanyId) {
      setCompanyName(selectedItem.name);
      setCompanyAddress(selectedItem.address);
      setCompanyUpdatedModal(true);
      await executeChange(selectedItem.id);
    }
  };

  const { execute: executeChange, isLoading } = useAsyncQuery(
    AuthenticationService.changeCompany,
    {
      successfulMessage: "Current Company Updated",
      onSuccess: (response) => {
        const authorizationData = response;
        const { access_token, ...user } = authorizationData;
        setSession(authorizationData);
      },
    }
  );

  let columns = getColumns(
    navigate,
    setDisablePopup,
    setSelectedCompany,
    setShowBlockWalletPopup,
    setShowUnBlockWalletPopup,
    fullAccess,
    fullAccessStatus,
    fullAccessDoc || readOnlyDoc,
    user?.companyId,
    handleSelect,
    user?.role,
    readUsers ?? false
  );

  let columnsIsAffiliated = getColumnsIsAffiliated(
    navigate,
    setDisablePopup,
    setSelectedCompany,
    setShowBlockWalletPopup,
    setShowUnBlockWalletPopup,
    fullAccess,
    fullAccessStatus,
    fullAccessDoc || readOnlyDoc,
    user?.companyId,
    handleSelect,
    user?.role,
    readUsers ?? false
  );

  const { Table, setRowsPerPage, page, setDataSet, setCount, orderBy, order } =
    useTable(props.filters.isUnionAffiliated ? columnsIsAffiliated : columns, {
      rowsPerPageOptions: [10],
    });

  const [refresh, setRefresh] = useState(props.refresh);

  useEffect(() => {
    const getData = async () => {
      try {
        startRequest("ActiveCompanies");
        const request = await CompanyService.getAll(
          {
            page: page + 1,
            sortColumn: orderBy,
            sortDec: order === "desc" ? true : false,
            isActive: false,
            parentCompanyId: parentCompanyId,
            signupDone: true,
            countryId: props.filters.countryId as number,
            stateId: props.filters.stateId as number,
            stateName: props.filters.stateName,
            subscriptionId: props.filters.subscriptionId as number,
            filter: props.filters.filter,
            isUnionAffiliated: true,
            canceledUnion: false,
            pendingUnion: true,
            laborUnionId: props.filters.laborUnionId,
            localUnionId: props.filters.localUnionId,
            isPendingToBeApproved: true,
          },
          getQueryString
        );

        setCount(request.data.count);
        setDataSet(request.data.data);
        setRowsPerPage(10);
        endRequest("ActiveCompanies");
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
        endRequest("ActiveCompanies");
      }
    };
    //&& props.filters.stateId !== ""
    if (props.filters.countryId !== "") getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    page,
    orderBy,
    order,
    refresh,
    props.refresh,
    props.filters.countryId,
    parentCompanyId,
    props.filters.isUnionAffiliated,
  ]);

  const handleSave = () => {
    setRefresh(!refresh);
  };

  return (
    <>
      <Table />
      <ChangeStatusPopup
        currentValues={selectedCompany}
        disablePopup={disablePopup}
        setDisablePopup={setDisablePopup}
        onSave={handleSave}
        onClick={() => null}
        status={true}
      />

      <BlockingWalletPopup
        currentValues={selectedCompany}
        disablePopup={showBlockWalletPopup}
        setDisablePopup={setShowBlockWalletPopup}
        onSave={handleSave}
      />

      <UnBlockWalletPopup
        currentValues={selectedCompany}
        disablePopup={showUnBlockWalletPopup}
        setDisablePopup={setShowUnBlockWalletPopup}
        onSave={handleSave}
      />

      <DialogMessagePopup
        title={"Current Company Updated"}
        text={
          <>
            <Typography paddingBottom={3}>
              Your current company has been updated to:
            </Typography>
            <Typography>{companyName}</Typography>
            <Typography>{companyAddress}</Typography>
          </>
        }
        showPopup={companyUpdatedModal}
        setShowPopup={setCompanyUpdatedModal}
        hideCancel={true}
        isSubmitting={isLoading}
        onSave={() => {
          window.location.reload();
        }}
        disableClickOutside={true}
      />
    </>
  );
}

export default PendingCompanies;
