import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import {
  Card as MuiCard,
  CardContent,
  Typography,
  Grid,
  Stack,
  IconButton,
} from "@mui/material";
import { spacing } from "@mui/system";
import { FormButton, FormDatePicker } from "src/components/formControls";
import { FilterType } from "./types/FilterTypes";
import moment from "moment/moment.js";
import { useTable } from "src/hooks/useTable";
import { ColumnType } from "src/types/enhancedTable";
import { getQueryString } from "./utils/common";
import { Form, useForm, useLog } from "src/hooks";
import { ISettlementBalance } from "src/ts/interfaces";
import { WalletService } from "src/services";
import FormNumeric from "src/components/formControls/FormNumeric";
import { GridActionButton } from "src/components/gridControls";
import SettleDetailForm from "./SettleDetailForm";
import { FileDownloadIcon } from "src/components/icons";
import FileUtils from "src/utils/file";

const Card = styled(MuiCard)(spacing);

const initialValues: FilterType = {
  amount: "",
  from: moment().startOf("month").format("MM/DD/yyyy"),
  to: moment().format("MM/DD/yyyy"),
};

const initalSettle: ISettlementBalance = {
  id: 0,
  date: "",
  amount: 0,
  settleStatus: 0,
  balance: "",
  dateSettled: "",
};

function SettlementBalance() {
  const { log } = useLog();
  const [refreshGrid, setRefreshGrid] = useState(false);

  const { values, errors, handleInputChange } = useForm(
    initialValues,
    false,
    []
  );

  const [currentRow, setCurrentRow] =
    useState<ISettlementBalance>(initalSettle);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const downloadDocument = async (row: ISettlementBalance) => {
    const pdf = await WalletService.getPdfSettle(row.id);
    FileUtils.downloadFile(pdf.data, "Settle" + row.id + ".pdf");
  };

  const handleShow = (row: ISettlementBalance) => {
    setCurrentRow(row);
    setIsDialogOpen(true);
  };

  const getColumns = (): ColumnType[] => [
    {
      id: "dateCreated",
      label: "Date",
      type: "date",
      format: "MM/DD/YYYY h:mma",
      sort: true,
    },
    {
      id: "amount",
      label: "Amount",
      type: "custom",
      sort: true,
      callback: (row: ISettlementBalance) => {
        const originalAmountValue = row.amount.toLocaleString("en-US");
        return (
          <>
            <Stack style={{ textAlign: "left" }}>
              <Typography>${originalAmountValue}</Typography>
            </Stack>
          </>
        );
      },
    },
    {
      id: "settleStatus",
      label: "Status",
      type: "string",
      sort: true,
    },
    {
      id: "dateSettled",
      label: "Date Settled",
      type: "date",
      format: "MM/DD/YYYY h:mma",
      sort: true,
    },
    {
      id: "x",
      label: "Actions",
      type: "custom",
      sort: false,
      callback: (row: ISettlementBalance) => (
        <div>
          <Stack direction="row">
            <GridActionButton
              type={"view"}
              onClick={() => {
                handleShow(row);
              }}
              tooltip={"Get Settle Details"}
            />
            <IconButton
              aria-label="download"
              size="large"
              onClick={() => downloadDocument(row)}
            >
              <FileDownloadIcon />
            </IconButton>
          </Stack>
        </div>
      ),
    },
  ];

  let columns = getColumns();

  const { Table, setRowsPerPage, page, setDataSet, setCount, orderBy, order } =
    useTable(columns, { cellCheckBox: false });

  const handleRefreshGrid = () => {
    setRefreshGrid(!refreshGrid);
  };

  useEffect(() => {
    const getData = async () => {
      try {
        const request = await WalletService.getPartnerSettleHistoryData(
          {
            page: page + 1,
            filter: values.filter,
            sortColumn: "dateCreated",
            sortDec: order === "desc" ? true : false,
            dateFrom: values.from,
            dateTo: values.to,
            amount: values.amount === undefined ? null : values.amount,
          },
          getQueryString
        );

        setCount(request.data.count);
        setDataSet(request.data.data);
        setRowsPerPage(10);
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      }
    };

    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, orderBy, order, setCount, setDataSet, setRowsPerPage, refreshGrid]);

  return (
    <>
      <Card mb={6}>
        <CardContent>
          <Grid container spacing={10}>
            <Grid item xs={12}>
              <Form>
                <Grid container spacing={5}>
                  <Grid item xs={4}>
                    <FormDatePicker
                      label={"Date From"}
                      name="from"
                      disablePast={false}
                      value={values.from}
                      onChange={handleInputChange}
                      error={errors.from}
                    />
                  </Grid>

                  <Grid item xs={4}>
                    <FormDatePicker
                      label={"Date To"}
                      name="to"
                      disablePast={false}
                      value={values.to}
                      onChange={handleInputChange}
                      error={errors.to}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <FormNumeric
                      name="amount"
                      label="Type ammount"
                      value={values.filter}
                      onChange={handleInputChange}
                      error={errors.filter}
                    />
                  </Grid>

                  <Grid item xs={1}>
                    <FormButton
                      color="primary"
                      text="Apply"
                      variant="contained"
                      size="medium"
                      onClick={handleRefreshGrid}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Table />
                  </Grid>
                </Grid>
              </Form>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <SettleDetailForm
        selectedValue={currentRow}
        isDialogOpen={isDialogOpen}
        setIsDialogOpen={setIsDialogOpen}
      />
    </>
  );
}

export default SettlementBalance;
