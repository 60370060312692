import Popup from "src/components/Popup";
import {
  FormButton,
  FormRadioGroup,
  FormSelect,
} from "src/components/formControls";
import { Stack } from "@mui/material";
import { useAsyncQuery, useForm, useLog } from "src/hooks";

import { useEffect, useState } from "react";
import { ITemMemberSend } from "src/ts/interfaces/teamMembers";
import teamMemberServices from "src/services/teamMemberServices";
import sendOutToBidProjectService from "src/services/sendOutToBidProjectService";
import userService from "src/services/userService";
import { IUserPersonAuditor } from "src/ts/interfaces/user";
import { OPTIONSHAREBYEMAIL } from "src/constants";
import { ISendOutToBidProject } from "src/ts/interfaces/projectOutToBid";

interface SendBidPopUpProps {
  sendBidShowPopUp: boolean;
  setSendBidShowPopUp: (val: boolean) => void;
  projectId: number;
  setRefreshSendOut: (val: boolean) => void;
  refreshSendOut: boolean;
  sendOutToBidProject: ISendOutToBidProject[];
}

interface IComboBoxTeamUser {
  key: number;
  value: string;
}

const initialValue = {
  selectOption: "-1",
  selectTypeUser: "0",
};

const userType = [
  {
    key: "0",
    value: "Licensed User",
  },
  {
    key: "1",
    value: "Team Member",
  },
];

export default function SendBidPopUp({
  sendBidShowPopUp,
  setSendBidShowPopUp,
  projectId,
  setRefreshSendOut,
  refreshSendOut,
  sendOutToBidProject,
}: SendBidPopUpProps) {
  const { log } = useLog();
  const [selectComboBox, setSelectComboBox] = useState<IComboBoxTeamUser[]>([]);
  const [selectComboLicensed, setSelectComboLicensed] = useState<
    IComboBoxTeamUser[]
  >([]);
  const [selectRadioButton] = useState(initialValue.selectOption);

  const { execute: executeTeamMember, data: dataTeamMember } = useAsyncQuery<
    ITemMemberSend[]
  >(teamMemberServices.teamMemberList);
  const { execute: executeCurrentCompany, data: dataCurrencyCompany } =
    useAsyncQuery<IUserPersonAuditor[]>(userService.currentCompanyList);

  useEffect(() => {
    executeTeamMember();
    executeCurrentCompany();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { values, handleInputChange, setValues } = useForm(
    initialValue,
    false,
    []
  );

  useEffect(() => {
    if (dataTeamMember) {
      setSelectComboBox(
        dataTeamMember.map((item) => {
          return {
            ...selectComboBox,
            key: item.id ?? 0,
            value: `${item.name}`,
          };
        })
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataTeamMember]);

  useEffect(() => {
    if (dataCurrencyCompany) {
      const uniqueCurrentUser = dataCurrencyCompany.filter(
        (obj: IUserPersonAuditor, index: number) => {
          if (obj.name?.includes("In Process")) {
            return (
              index ===
              dataCurrencyCompany.findIndex((o: IUserPersonAuditor) => {
                return obj.name1 === o.name1;
              })
            );
          } else {
            return (
              index ===
              dataCurrencyCompany.findIndex(
                (o: IUserPersonAuditor) => obj.name1 === o.name1
              )
            );
          }
        }
      );
      uniqueCurrentUser.sort(sortByName);
      setSelectComboLicensed(
        uniqueCurrentUser.map((item) => {
          return {
            ...selectComboBox,
            key: item.id ?? 0,
            value: `${item.name}`,
          };
        })
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataCurrencyCompany]);

  useEffect(() => {
    setValues({ ...values, selectOption: "-1" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.selectTypeUser]);

  const handlerAccept = async () => {
    let emailUserExistLicensed = false;
    let emailUserExistTeamMember = false;

    if (values.selectOption === "-1")
      return log.warning("Select at least one Team Member or Licensed User.");

    const userlLicensed = dataCurrencyCompany.filter(
      (item) => item.id === values.selectOption
    );
    if (userlLicensed[0]) {
      emailUserExistLicensed = sendOutToBidProject.some(
        (item) => item.email === userlLicensed[0].email
      );
    }

    const userTeamMember = dataTeamMember.filter(
      (item) => item.id === values.selectOption
    );

    if (userTeamMember[0]) {
      emailUserExistTeamMember = sendOutToBidProject.some(
        (item) =>
          item.teamMember && item.teamMember.email === userTeamMember[0].email
      );
    }

    if (emailUserExistLicensed || emailUserExistTeamMember)
      return log.warning("The user selected was already added.");
    try {
      if (values.selectTypeUser === OPTIONSHAREBYEMAIL.LICENSEED_USER) {
        await sendOutToBidProjectService.postSendOutToBidProject({
          projectId: projectId.toString(),
          name: userlLicensed[0].name,
          lastName: userlLicensed[0].lastName,
          email: userlLicensed[0].email,
          phone: userlLicensed[0].phone,
        });
      } else {
        await sendOutToBidProjectService.postSendOutToBidProject({
          projectId: projectId.toString(),
          teamMemberId: values.selectOption,
        });
      }

      setRefreshSendOut(!refreshSendOut);
      log.success("The user was added successfully.");
      setSendBidShowPopUp(false);
    } catch (error: any) {
      log.error(error?.message?.exceptionMessage ?? "Something went wrong");
    }
  };

  const sortByName = (a: IUserPersonAuditor, b: IUserPersonAuditor) => {
    if (a.name && b.name) {
      if (a.name.toUpperCase() < b.name.toUpperCase()) {
        return -1;
      }
      if (a.name.toUpperCase() > b.name.toUpperCase()) {
        return 1;
      }
      return 0;
    }
    return 0;
  };
  return (
    <>
      <Popup
        title={"Send bid information to:"}
        openPopup={sendBidShowPopUp}
        setOpenPopup={setSendBidShowPopUp}
        onClose={() => {
          setSendBidShowPopUp(false);
        }}
        size="sm"
      >
        <>
          <Stack direction={"column"} spacing={2}>
            <FormRadioGroup
              name="selectTypeUser"
              label="Send to:"
              value={values.selectTypeUser}
              items={userType}
              onChange={handleInputChange}
              defaultValue={selectRadioButton}
              row={true}
            />
            {values.selectTypeUser === OPTIONSHAREBYEMAIL.TEAM_MEMBER && (
              <FormSelect
                name="selectOption"
                value={values.selectOption}
                label="Team member"
                defaultValue={{
                  key: "-1",
                  value: "Select Team Member",
                }}
                onChange={handleInputChange}
                options={selectComboBox}
              />
            )}
            {values.selectTypeUser === OPTIONSHAREBYEMAIL.LICENSEED_USER && (
              <FormSelect
                name="selectOption"
                value={values.selectOption}
                label="Licensed User"
                defaultValue={{
                  key: "-1",
                  value: "Select Licensed User",
                }}
                onChange={handleInputChange}
                options={selectComboLicensed}
              />
            )}
          </Stack>
        </>
        <Stack direction="row" spacing={3}>
          <FormButton
            size="small"
            text="Close"
            variant="outlined"
            onClick={() => {
              setSendBidShowPopUp(false);
            }}
          />
          <FormButton
            size="small"
            text="Accept"
            color="primary"
            onClick={() => {
              handlerAccept();
            }}
          />
        </Stack>
      </Popup>
    </>
  );
}
