import axios from "../utils/axios";
import { IYoutubeTraining } from "../ts/interfaces/youtubeTraining";

const getByReportType = async (reportType: string) => {
  return axios.get<IYoutubeTraining[]>(
    `YoutubeTraining/reportType?code=` + reportType
  );
};

const getByPage = async (page: string) => {
  return axios.get<IYoutubeTraining[]>(`YoutubeTraining?page=` + page);
};

const youtubeTrainingService = {
  getByReportType,
  getByPage,
};

export default youtubeTrainingService;
