import { Stack, Switch, FormControl, FormControlLabel } from "@mui/material";
import React, { useState } from "react";
import {
  FormAcceptButton,
  FormCancelButton,
} from "src/components/formControls";
import Popup from "src/components/Popup";
import { useAsyncMutation } from "src/hooks";
import { userService } from "src/services";
import { IUserPersonRole } from "src/ts/interfaces";

interface ILockOutPopupProps {
  lockOutModal: boolean;
  setLockOutModal: (val: boolean) => void;
  dataUser: IUserPersonRole;
  setRefresh: (val: boolean) => void;
  refresh: boolean;
}

const LockOutPopUp = ({
  lockOutModal,
  setLockOutModal,
  dataUser,
  setRefresh,
  refresh,
}: ILockOutPopupProps) => {
  const [isSubmitting] = useState(false);
  const [lockoutEnabled, setLockoutEnabled] = useState(
    dataUser?.lockoutEnabled
  );
  const [isLockout, setIsLockout] = useState(dataUser?.isLockout);

  const { execute, isSubmitting: isSubmittingLockout } =
    useAsyncMutation<string>(userService.lockoutUser, {
      onSuccess: async () => {
        setLockOutModal(false);
        setRefresh(!refresh);
      },
      successfulMessage: "Lockout Options were updated",
    });

  const handleClose = () => {
    setLockOutModal(false);
  };

  const handleChangeLockout = async () => {
    await execute(dataUser?.userAuth, lockoutEnabled, isLockout);
  };

  return (
    <Popup
      title="Lockout Options"
      openPopup={lockOutModal}
      setOpenPopup={setLockOutModal}
      onClose={handleClose}
      size="sm"
    >
      <>
        <FormControl component="fieldset">
          <FormControlLabel
            control={<Switch color="primary" />}
            label="Enabled lockout option"
            labelPlacement="start"
            checked={lockoutEnabled}
            onChange={(e: any) => {
              setLockoutEnabled(!lockoutEnabled);
            }}
          />
          <FormControlLabel
            value="start"
            control={<Switch color="primary" />}
            label="User lockout"
            labelPlacement="start"
            checked={isLockout}
            onChange={(e: any) => {
              setIsLockout(false);
            }}
          />
        </FormControl>
      </>
      <Stack direction="row" spacing={3} justifyContent="flex-end">
        <FormCancelButton onClick={handleClose} isSubmitting={isSubmitting} />
        <FormAcceptButton
          onClick={() => {
            handleChangeLockout();
          }}
          isSubmitting={isSubmitting || isSubmittingLockout}
        />
      </Stack>
    </Popup>
  );
};

export default LockOutPopUp;
