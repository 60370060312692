import React from "react";
import styled from "@emotion/styled";
import { withTheme } from "@emotion/react";
import { Bar } from "react-chartjs-2";
import { MoreVertical } from "react-feather";

import {
  Card as MuiCard,
  CardContent,
  CardHeader,
  IconButton,
  Theme,
} from "@mui/material";
import { spacing } from "@mui/system";
import ChartDataLabels from "chartjs-plugin-datalabels";

const Card = styled(MuiCard)(spacing);

const ChartWrapper = styled.div`
  height: 340px;
  width: 100%;
`;

interface Props {
  theme: Theme & { palette: any };
  arrayData: number[];
}

const BarChart = ({ theme, arrayData }: Props) => {
  const data = {
    labels: [
      "SignUp",
      "Projects",
      "T&B Cert",
      "Energy Cert",
      "Storage",
      "Others",
      "BOMA",
      "Team",
    ],
    datasets: [
      {
        label: "Purchase",
        backgroundColor: [
          "rgba(172, 115, 57)",
          "rgba(230, 230, 0)",
          "rgba(77, 166, 255)",
          "rgba(153, 204, 0)",
          "rgba(255, 102, 204)",
          "rgba(179, 179, 204)",
          "rgba(133, 51, 255)",
          "rgba(255, 153, 0)",
        ],
        borderColor: [
          "rgba(172, 115, 57)",
          "rgba(230, 230, 0)",
          "rgba(77, 166, 255)",
          "rgba(153, 204, 0)",
          "rgba(255, 102, 204)",
          "rgba(179, 179, 204)",
          "rgba(133, 51, 255)",
          "rgba(255, 153, 0)",
        ],
        hoverBackgroundColor: [
          "rgba(172, 115, 57)",
          "rgba(230, 230, 0)",
          "rgba(77, 166, 255)",
          "rgba(153, 204, 0)",
          "rgba(255, 102, 204)",
          "rgba(179, 179, 204)",
          "rgba(133, 51, 255)",
          "rgba(255, 153, 0)",
        ],
        hoverBorderColor: [
          "rgba(172, 115, 57)",
          "rgba(230, 230, 0)",
          "rgba(77, 166, 255)",
          "rgba(153, 204, 0)",
          "rgba(255, 102, 204)",
          "rgba(179, 179, 204)",
          "rgba(133, 51, 255)",
          "rgba(255, 153, 0)",
        ],
        data: arrayData, //[54.22, 67, 41, 55, 62, 45, 55, 58],//
        barPercentage: 0.5,
        categoryPercentage: 0.5,
      },
    ],
  };

  const options: any = {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
      },

      tooltip: {
        callbacks: {
          label: function (context: any) {
            let label = context.dataset.label || "";

            if (label) {
              label += ": ";
            }
            if (context.parsed.y !== null) {
              label += new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
              }).format(context.parsed.y);
            }
            return label;
          },
        },
      },

      datalabels: {
        display: true,
        color: "black",
        formatter: function (value: any) {
          let label = "";
          if (value !== null) {
            label += new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
            }).format(value);
          }
          return label;
        },
        anchor: "end",
        offset: -20,
        align: "start",
      },
    },

    scales: {
      y: {
        grid: {
          display: false,
        },
        stacked: true,
        ticks: {
          stepSize: 20,
          fontColor: theme.palette.text.secondary,
        },
      },
      x: {
        stacked: true,
        grid: {
          color: "transparent",
        },
      },
    },
  };

  return (
    <Card mb={6}>
      <CardHeader
        action={
          <IconButton aria-label="settings" size="large">
            <MoreVertical />
          </IconButton>
        }
        title="Purchase"
      />

      <CardContent>
        <ChartWrapper>
          <Bar data={data} options={options} plugins={[ChartDataLabels]} />
        </ChartWrapper>
      </CardContent>
    </Card>
  );
};

export default withTheme(BarChart);
