import axios from "src/utils/axios";

const numberOfUsers = async () => {
  return axios.get<number>(`UsersStatistics/numberOfUsers`);
};
//
const onlineUsers = async () => {
  return axios.get<number>(`UsersStatistics/onlineUsers`);
};

const usersByMonth = async () => {
  return axios.get<number[]>(`UsersStatistics/usersByMonth`);
};

const usersStatistics = {
  numberOfUsers,
  onlineUsers,
  usersByMonth,
};

export default usersStatistics;
