import { useState } from "react";
import { Stack, Typography, Chip as MuiChip, Grid } from "@mui/material";
import styled from "@emotion/styled";
import { SpacingProps, spacing } from "@mui/system";
import { ColumnType } from "src/types/enhancedTable";
import { FilterType } from "../types/FilterTypes";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { GridActionButton } from "src/components/gridControls";
import { useAuth, usePermissions } from "src/hooks";

import { IArchitectProjects } from "src/ts/interfaces/projectArchitect";
import LocalEnhancedTable from "src/components/localTable/LocalTable";
import CloseProjectPopUp from "./components/CloseProjectPopUp";
import { makeStyles } from "@mui/styles";
import { Permission as PermissionTypes } from "src/ts/enums";
interface ChipProps extends SpacingProps {
  component?: React.ElementType;
  href?: string;
  icon?: JSX.Element | null;
}
const Chip = styled(MuiChip)<ChipProps>(spacing);

const useStyles = makeStyles({
  "@keyframes blinkAnimation": {
    "0%": {
      opacity: 1,
    },
    "50%": {
      opacity: 0,
    },
    "100%": {
      opacity: 1,
    },
  },
  blinking: {
    animation: "$blinkAnimation 2s infinite",
  },
});

const getColumns = (
  navigate: NavigateFunction,
  role: string,
  setCurrentRow: (val: IArchitectProjects) => void,
  companyId: number,
  setCloseProjectShowPopUp: (val: boolean) => void,
  classes: any,
  fullAccess: boolean
): ColumnType[] => [
  {
    id: "code",
    label: "Code",
    type: "string",
    sort: true,
  },
  {
    id: "name",
    label: "Project",
    type: "string",
    sort: true,
  },
  {
    id: "createdDate",
    label: "Created date",
    type: "date",
    format: "MM/DD/YYYY",
    sort: true,
  },
  {
    id: "buildingName",
    label: "Building",
    type: "custom",
    sort: true,
    callback: (row: IArchitectProjects) => {
      return (
        <Typography>
          {" "}
          <b>{row.buildingName}</b> <br />
          {row.buildingAddress}{" "}
        </Typography>
      );
    },
  },
  {
    id: "assignedTo",
    label: "Assigned To",
    type: "string",
    sort: true,
  },
  {
    id: "action",
    label: "",
    type: "custom",
    sort: true,
    width: "150p",
    callback: (row: IArchitectProjects) => (
      <>
        <Stack direction={"column"}>
          <Stack direction="row">
            <GridActionButton
              type="gauge"
              onClick={() => {
                navigate(`/app/projectDashboard/${row.id}`);
              }}
              tooltip="Dashboard Project"
            />
            <GridActionButton
              type="forward"
              onClick={() => {
                navigate(`/app/Project/Edit/${row.id}/${companyId}`);
              }}
              tooltip="Review Project"
            />
            {row.codeUsed && (
              <GridActionButton
                type="forum"
                onClick={() => {
                  navigate(`/app/dispute/${row.id}`);
                }}
                tooltip="Dispute"
                className={row.isOpenDispute ? classes.blinking : ""}
              />
            )}
            {fullAccess && (
              <GridActionButton
                type="close"
                onClick={() => {
                  setCurrentRow(row);
                  setCloseProjectShowPopUp(true);
                }}
                tooltip="Close Project"
              />
            )}

            <GridActionButton
              type="folder"
              onClick={() => {
                navigate(`/app/DocumentLibrary/${row.id}/${companyId}/Project`);
              }}
              tooltip="Project's Document Library"
            />
            <GridActionButton
              type="picture"
              onClick={() => {
                navigate(`/app/PhotoLibrary/${row.id}/${companyId}/Project`);
              }}
              tooltip="Project's Picture Library"
            />
          </Stack>
          <Stack>
            <Grid container>
              <Grid item xs={6}>
                {row.codeUsed && (
                  <Chip
                    label={"Accepted"}
                    color="secondary"
                    m={1}
                    size="small"
                  />
                )}
                {!row.codeUsed && (
                  <Chip
                    label={"Acceptance pending"}
                    color="warning"
                    m={1}
                    size="small"
                  />
                )}
                {row.declinedById !== null && (
                  <Chip label={"Declined"} color="error" m={1} size="small" />
                )}
              </Grid>
            </Grid>
          </Stack>
        </Stack>
      </>
    ),
  },
];

interface OutForBidProjectProps {
  filters: FilterType;
  setRefreshGrid: (val: boolean) => void;
  dataTable: IArchitectProjects[];
  isLoading: boolean;
  refreshGrid: boolean;
  getDataSummary: () => void;
  setDataTable: (val: IArchitectProjects[]) => void;
}

export default function AwarededProject({
  filters,
  setRefreshGrid,
  dataTable,
  isLoading,
  refreshGrid,
  getDataSummary,
  setDataTable,
}: OutForBidProjectProps) {
  const classes = useStyles();
  const [currentRow, setCurrentRow] = useState<IArchitectProjects>();
  const [closeProjectShowPopUp, setCloseProjectShowPopUp] = useState(false);
  const { fullAccess } = usePermissions(PermissionTypes.Project);
  let navigate = useNavigate();
  const { user } = useAuth();

  let columns = getColumns(
    navigate,
    user?.role,
    setCurrentRow,
    user?.companyId,
    setCloseProjectShowPopUp,
    classes,
    fullAccess
  );

  return (
    <>
      <LocalEnhancedTable<IArchitectProjects>
        refreshGrid={false}
        columns={columns}
        data={dataTable ?? []}
        showSkeleton={isLoading}
        skeletonRows={1}
        hidePagination={false}
        defaultRowPerPage={10}
        orderColumn="desc"
        defaultSortColumn="id"
      />
      {closeProjectShowPopUp && (
        <CloseProjectPopUp
          setCloseProjectShowPopUp={setCloseProjectShowPopUp}
          closeProjectShowPopUp={closeProjectShowPopUp}
          currentRow={currentRow}
          setRefreshGrid={setRefreshGrid}
          refreshGrid={refreshGrid}
        />
      )}
    </>
  );
}
