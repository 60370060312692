import Popup from "src/components/Popup";
import { FormButton } from "src/components/formControls";
import { Stack, Typography } from "@mui/material";
import { gpsPermissionService, userService } from "src/services";
import { useLog } from "src/hooks";
import { DataAssignment } from "src/ts/interfaces/technician";

interface RemoveGPSPermissionsProps {
  removeShowPopUp: boolean;
  setRemoveShowPopUp: (val: boolean) => void;
  refreshPage: boolean;
  setRefreshPage: (val: boolean) => void;
  currentRow: DataAssignment;
  typeRemove: string;
}

export default function RemoveGPSPermissions({
  removeShowPopUp,
  setRemoveShowPopUp,
  refreshPage,
  setRefreshPage,
  currentRow,
  typeRemove,
}: RemoveGPSPermissionsProps) {
  const { log } = useLog();
  const accepthandlerAll = async () => {
    try {
      if (typeRemove === "removePermanent") {
        await userService.putRemovePermanentPermission(currentRow.id);
      } else {
        await gpsPermissionService.removeTrainingPermission(currentRow.id);
      }
      setRemoveShowPopUp(false);
      setRefreshPage(!refreshPage);
      log.success("The permission has been removed");
    } catch (error: any) {
      log.error(error?.message?.exceptionMessage ?? "Something went wrong");
    }
  };
  return (
    <>
      <Popup
        title={"Information"}
        openPopup={removeShowPopUp}
        setOpenPopup={setRemoveShowPopUp}
        onClose={() => {
          setRemoveShowPopUp(false);
        }}
        size="sm"
      >
        <>
          <Typography>
            {typeRemove === "removePermanent"
              ? "Are you certain you want to remove this permanent permission for all projects?"
              : "Are you certain you want to remove this permission for this project?"}
          </Typography>
        </>
        <Stack direction="row" spacing={3}>
          <FormButton
            size="small"
            text="Close"
            variant="outlined"
            onClick={() => {
              setRemoveShowPopUp(false);
            }}
          />
          <FormButton
            size="small"
            text="Accept"
            color="primary"
            onClick={() => {
              accepthandlerAll();
            }}
          />
        </Stack>
      </Popup>
    </>
  );
}
