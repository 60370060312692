import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "@emotion/styled";
import useLog from "src/hooks/useLog";
import { COUNTRIES, MEASUREMENT, TrainingMessage } from "src/constants";
import {
  CardContent,
  Grid,
  Card as MuiCard,
  Divider as MuiDivider,
  Typography,
  Stack,
  Tabs,
  Tab,
} from "@mui/material";
import { spacing } from "@mui/system";
import { useForm, Form, useCountry, usePermissions } from "src/hooks";
import {
  FormCancelButton,
  FormSaveButton,
  FormText,
  FormSelectState,
  FormMaskText,
  FormSelect,
  FormCheckBox,
  FormTextArea,
  FormButton,
  FormCheckSwitch,
  FormNumeric2,
} from "src/components/formControls";
import { IBuilding, IBuildingPhoneNumber, IKeyValue } from "src/ts/interfaces";
import useZipCode from "src/hooks/useZipCode";
import useLoading from "src/hooks/useLoading";
import { UserParams } from "src/ts/types";
import { Validator } from "src/ts/types/Validator";
import { Permission as PermissionTypes } from "src/ts/enums";
import { buildingService, buildingSubType, buildingType } from "src/services";
import { GridActionButton } from "src/components/gridControls";
import BuildingPhoneNumber from "./BuildingPhoneNumber";
import HeaderPage from "src/components/page/HeaderPage";
import FormGoogleMap from "src/components/formControls/FormButtonGoogleMap";
import { ElectricBolt as ElectricBoltIcon } from "@mui/icons-material";
import DialogMessagePopup from "src/components/DialogMessagePopup";
import FormButtonTooltip from "src/components/formControls/FormButtonTooltip";

const Card = styled(MuiCard)(spacing);
const Divider = styled(MuiDivider)(spacing);
const initialValues: IBuilding = {
  id: 0,
  name: "",
  totalFloorArea: 0,
  floors: 0,
  auditArea: 0,
  parkingArea: 0,
  buildingTypeOther: "",
  elevation: 0,
  countryId: 235,
  countryCode: "1",
  address: "",
  address2: "",
  city: "",
  stateId: undefined,
  stateName: "",
  zip: "",
  yearBuilt: 0,
  isParkingAreaPartOfTotalBuildingFloorArea: true,
  isParkingAreaConditioned: true,
  isParkingAreaPartOfThisTest: true,
  energyConsumption: true,
  buildingContact: {
    id: 0,
    name: "",
    email: "",
    phone: "",
    extension: "",
    title: "",
    additional: "",
  },
  buildingTypeInformation: {
    id: 0,
    numberOfWorker: 0,
    weekyOperationgHours: 0,
    numberOfPCs: 0,
    percentHeated: 0,
    percentCooled: 0,
  },
  isActive: true,
  isDeleted: false,
  createdDate: new Date(),
  createdBy: "",
  updatedDate: new Date(),
  updatedBy: "",
  serial: "",
  buildingPhoneNumber: [
    {
      id: 0,
      phone: "",
      buildingId: 0,
      createdDate: new Date(),
      createdBy: "",
      modifiedDate: new Date(),
      modifiedBy: "",
    },
  ],
  subAddressIdentifier: "",
  numberOfAirHandlersProvidingOutsideAir: 0,
  isPrimaryStandalone: false,
  isPrimaryAnchorFacility: false,
  isSubBuildingStandalone: false,
  isSubBuildingAnchorFacility: false,
  latitude: 0,
  longitude: 0,
  aceptChangeLocation: true,
  totalSquareFeet: 0,
  measurementSystem: 1,
  jobSiteDistanceRadius: 0,
  getFullAddress: "",
  canEdit: true,
  parentBuildingId: 0,
  buildingTypeId: null,
  buildingSubTypeId: null,
};

const BuildingForm = () => {
  let navigate = useNavigate();
  const { log } = useLog();
  let { id } = useParams<UserParams>();
  let { parentBuildingId } = useParams<{ parentBuildingId: string }>();
  let { isInfect } = useParams<{ isInfect: string }>();
  const validate = (fieldValues = values) => {
    let temp: Record<string, string> = { ...errors };

    if (
      fieldValues.buildingContact.email &&
      fieldValues.buildingContact.email !== ""
    ) {
      temp.email = new Validator(fieldValues.buildingContact, "email")
        .emailFormat("Insert a correct email. Example:  demo@amp.com")
        .toString();
    } else temp.email = "";

    if (
      fieldValues.buildingContact.phone &&
      fieldValues.buildingContact.phone !== ""
    ) {
      temp.phone = new Validator(fieldValues.buildingContact, "phone")
        .phoneFormat("Incorrect phone format.")
        .toString();
    } else temp.phone = "";

    temp.administratorType = new Validator(fieldValues, "administratorType")
      .selectedOption(0, "The role is required.")
      .toString();

    if (fieldValues.hasOwnProperty("name")) {
      if (fieldValues.name === "") {
        temp.name = "This field is required";
      } else if (fieldValues.name.length > 50) {
        temp.name = "Only 50 character are allowed";
      } else {
        temp.name = "";
      }
    }

    if (fieldValues.hasOwnProperty("address")) {
      if (fieldValues.address.length > 200) {
        temp.address = "Only 200 character are allowed";
      } else {
        temp.address = "";
      }
    }

    if (fieldValues.hasOwnProperty("address2")) {
      if (fieldValues?.address2 && fieldValues.address2.length > 200) {
        temp.address2 = "Only 200 character are allowed";
      } else {
        temp.address2 = "";
      }
    }

    if (fieldValues.hasOwnProperty("zip")) {
      if (fieldValues.zip === "") {
        temp.zip = "The zipcode is required";
      } else {
        temp.zip = "";
      }
    }

    if (fieldValues.hasOwnProperty("city")) {
      if (fieldValues.city === "") {
        temp.city = "The city is required";
      } else {
        temp.city = "";
      }
    }

    if (fieldValues.hasOwnProperty("statusId"))
      temp.statusId =
        fieldValues.statusId !== 0 ? "" : "This field is required.";

    if (isInfect) {
      temp.numberOfAirHandlersProvidingOutsideAir = new Validator(
        fieldValues,
        "numberOfAirHandlersProvidingOutsideAir"
      )
        .greatThan(0, "Infectious Control Project requires this field")
        ?.toString();

      temp.buildingTypeId = new Validator(fieldValues, "buildingTypeId")
        .selectedOption(-1, "Infectious Control Project requires this field")
        .selectedOption(null, "Infectious Control Project requires this field")
        ?.toString();

      temp.buildingSubTypeId = new Validator(fieldValues, "buildingSubTypeId")
        .selectedOption(-1, "Infectious Control Project requires this field")
        .selectedOption(null, "Infectious Control Project requires this field")
        ?.toString();
    }

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [changeZipCodePopup, setChangeZipCodePopup] = useState(false);
  const [isStateLoading, setIsStateLoading] = useState(false);
  const [selectdTab, setSelectedTab] = useState(isInfect ? 1 : 0);
  const [addPhone, setAddPhone] = useState(0);
  const [saveItems, setSaveItems] = useState(0);
  const [buildingId, setBuildingId] = useState(
    parseInt(id === undefined ? "0" : id)
  );
  const [buildingPhones, setBuildingPhones] =
    useState<IBuildingPhoneNumber[]>();
  const { fullAccess, readOnly } = usePermissions(PermissionTypes.Building);
  const { read, demo } = usePermissions(PermissionTypes.Energy_Cost);
  const [canEdit, setCanEdit] = useState(false);
  const { values, setValues, errors, setErrors, handleInputChange } = useForm(
    initialValues,
    false,
    validate
  );
  const { isLoading, startRequest, endRequest } = useLoading();
  const { countries, countriesKeyValue } = useCountry();
  const { zipValue } = useZipCode(values.zip);
  let { companyId } = useParams<{ companyId: string }>();
  const [buildingTypeKeyValue, setBuildingTypeKeyValue] = useState<
    IKeyValue<number, string>[]
  >([]);
  const [buildingSubTypeKeyValue, setBuildingSubTypeKeyValue] = useState<
    IKeyValue<number, string>[]
  >([]);
  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    if (newValue === 0) setSaveItems(0);
    setSelectedTab(newValue);
  };

  useEffect(() => {
    if (isInfect && values && Number(id) > 0) validate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  useEffect(() => {
    const code = countries.filter((item: any) => {
      return item.id === values.countryId;
    });

    if (buildingId === 0 && code.length > 0) {
      setValues({
        ...values,
        countryCode: code[0].countryCode,
      });
    } else if (buildingId !== 0 && code.length > 0) {
      setValues({
        ...values,
        countryCode: code[0].countryCode,
      });
    } else {
      setValues({
        ...values,
        countryCode: values.countryCode ?? "",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.countryId, countries, values.country]);

  useEffect(() => {
    if (
      zipValue?.countryId !== undefined &&
      values.countryId === COUNTRIES.USA
    ) {
      setValues({ ...values, city: zipValue.city, stateId: zipValue.stateId });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [zipValue]);

  useEffect(() => {
    const getUser = async (buildingId: number) => {
      startRequest("building");
      const response = await buildingService.getById(buildingId);
      response.data.id = Number(id);
      if (Number(parentBuildingId) > 0)
        response.data.parentBuildingId = buildingId;

      setCanEdit(response.data.canEdit);
      setValues(response.data);
      endRequest("building");
    };

    if (Number(id) > 0) {
      getUser(Number(id));
    } else if (Number(parentBuildingId) > 0) {
      getUser(Number(parentBuildingId));
    } else {
      startRequest("building");
      values.companyId = companyId;
      setValues({ ...values });
      setCanEdit(true);
      endRequest("building");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    const getBuildingType = async () => {
      const response = await buildingType.getKeyValues();
      setBuildingTypeKeyValue(response);
    };

    getBuildingType();
  }, [values.buildingTypeId]);

  useEffect(() => {
    const getBuildingSubType = async () => {
      const response = await buildingSubType.getKeyValues(
        values.buildingTypeId
      );
      setBuildingSubTypeKeyValue(response);
    };
    if (values.buildingTypeId !== null) getBuildingSubType();
  }, [values.buildingTypeId]);

  const handleCancel = () => {
    navigate(`/app/Buildings/${companyId}`);
  };

  const handlerAddContactPhone = () => {
    setAddPhone(addPhone + 1);
  };

  const handleSave = async () => {
    if (!validate()) {
      if (isInfect) setSelectedTab(1);
      else setSelectedTab(0);
      log.error("Please fill all the required information");
      return;
    }
    try {
      setIsSubmitting(true);
      if (buildingId !== 0) {
        await buildingService.update(values.id, values);
        setSaveItems(saveItems + 1);
        if (selectdTab !== 0) log.success("Building's data was updated");
      } else {
        let radiousAlert = 0;
        if (values.measurementSystem === MEASUREMENT.IMPERIAL.id) {
          radiousAlert = 164;
        } else {
          radiousAlert = 164.04;
        }
        const newValue = { ...values, jobSiteDistanceRadius: radiousAlert };
        var result = await buildingService.create(newValue);
        buildingPhones?.forEach((item: IBuildingPhoneNumber) => {
          item.buildingId = result.data.id;
        });
        await buildingService.updatePhones(buildingPhones ?? []);
        setBuildingId(result.data.id);
        log.success("Building was created successfully");
        navigate("/app/Buildings/" + companyId);
      }
    } catch (error: any) {
      if (error?.message?.exceptionMessage === "Zip change") {
        setChangeZipCodePopup(true);
      } else
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
    } finally {
      setIsSubmitting(false);
    }
  };

  const metricSystem = [
    { key: 1, value: "Imperial System" },
    { key: 2, value: "Metric System" },
  ];

  const statuses = [
    { key: true, value: "Active" },
    { key: false, value: "Inactive" },
  ];

  const buildingPressurization = [
    { key: 1, value: "Positive +" },
    { key: 2, value: "Negative -" },
    { key: 3, value: "Combination of Both +/-" },
  ];

  const handleBuildingContact = (e: any) => {
    const { name, value } = e.target;
    values.buildingContact[name] = value;

    setValues({
      ...values,
    });
  };

  const handleSaveCheck = (e: any) => {
    const { name } = e.target;
    setValues({
      ...values,
      [name]: !values?.[name],
    });
  };

  const handleAcceptUpdate = () => {
    values.aceptChangeLocation = true;
    setValues({
      ...values,
    });
    handleSave();
  };

  const changeBuildingTypeInformation = (e: any) => {
    const { name, value } = e.target;
    values.buildingTypeInformation[name] = value;

    setValues({
      ...values,
    });
  };

  const onBlurHandler = () => {
    if (values.measurementSystem === MEASUREMENT.IMPERIAL.id) {
      if (values.jobSiteDistanceRadius < 164) {
        log.error(
          "The value for job site distance radius can't be less than 164 feet"
        );
        setValues({ ...values, jobSiteDistanceRadius: 164 });
      } else if (values.jobSiteDistanceRadius > 32810) {
        log.error(
          "The value for job site distance radius can't be greater than 32,810 feet"
        );
        setValues({ ...values, jobSiteDistanceRadius: 32810 });
      }
    } else {
      if (values.jobSiteDistanceRadius < 50) {
        log.error(
          "The value for job site distance radius can't be less than 50 meters"
        );
        setValues({ ...values, jobSiteDistanceRadius: 50 });
      } else if (values.jobSiteDistanceRadius > 10000) {
        log.error(
          "The value for job site distance radius can't be greater than 10,000 meters"
        );
        setValues({ ...values, jobSiteDistanceRadius: 10000 });
      }
    }
  };

  const row = 6;

  const milesConvert =
    values.jobSiteDistanceRadius === 0
      ? 0
      : values.measurementSystem === MEASUREMENT.IMPERIAL.id
      ? (values.jobSiteDistanceRadius / 5280).toFixed(3)
      : (values.jobSiteDistanceRadius / 1000).toFixed(3);

  const measurementValue =
    values.measurementSystem === MEASUREMENT.IMPERIAL.id
      ? MEASUREMENT.IMPERIAL.measurement
      : MEASUREMENT.METRIC.measurement;

  const measurementName =
    values.measurementSystem === MEASUREMENT.IMPERIAL.id
      ? MEASUREMENT.IMPERIAL.name
      : MEASUREMENT.METRIC.name;

  const isSubBuilding = values.parentBuildingId ? true : false;

  return (
    <>
      <HeaderPage
        title={"Building"}
        parentText={"Buildings"}
        parentLink={`/app/Buildings/${companyId}`}
        actionSection={undefined}
      ></HeaderPage>
      <Divider my={6} />
      <Card mb={6}>
        <CardContent>
          <Form>
            <Grid item xs={12}>
              <Tabs
                value={selectdTab}
                onChange={handleChangeTab}
                aria-label="basic tabs example"
              >
                <Tab label="Building Information" id="1" />
                {id !== "0" && <Tab label="Specifics" id="2" />}
                {id !== "0" && <Tab label="Information" id="3" />}
              </Tabs>
            </Grid>
            <Divider my={3}></Divider>
            <Grid container spacing={5}>
              {selectdTab === 0 && (
                <>
                  <Grid item xs={row}>
                    <FormText
                      name="name"
                      label="Building Name"
                      value={values.name}
                      onChange={handleInputChange}
                      error={errors.name}
                      disabled={isSubmitting || readOnly || !canEdit}
                      showSkeleton={isLoading}
                    />
                  </Grid>
                  <Grid item xs={row}>
                    <FormText
                      name="serial"
                      label="Serial Number"
                      value={values.serial}
                      onChange={handleInputChange}
                      error={errors.serial}
                      disabled={true}
                      showSkeleton={isLoading}
                    />
                  </Grid>
                  {isSubBuilding && (
                    <Grid item xs={12}>
                      <FormText
                        name="subAddressIdentifier"
                        label="Sub-Building Address Identifier"
                        value={values.subAddressIdentifier}
                        onChange={handleInputChange}
                        error={errors.subAddressIdentifier}
                        disabled={isSubmitting || readOnly || !canEdit}
                        showSkeleton={isLoading}
                        placeholder="Ex: Building A, 1, ll, Fuel Building"
                      />
                    </Grid>
                  )}
                  {!isSubBuilding && (
                    <>
                      <Grid item xs={row}>
                        <FormCheckBox
                          label="This building with this address is the primary administration, anchor facility"
                          name="isPrimaryAnchorFacility"
                          value={values.isPrimaryAnchorFacility}
                          onChange={handleSaveCheck}
                          disabled={!canEdit || readOnly}
                        />
                      </Grid>
                      <Grid item xs={row}>
                        <FormCheckBox
                          label="This building is a true stand-alone building that is void of being affected by or affecting any adjacent buildings that may prevent this building from meeting the spirit and definition of stand-alone"
                          name="isPrimaryStandalone"
                          value={values.isPrimaryStandalone}
                          onChange={handleSaveCheck}
                          disabled={!canEdit || readOnly}
                        />
                      </Grid>
                    </>
                  )}
                  {isSubBuilding && (
                    <>
                      <Grid item xs={row}>
                        <FormCheckBox
                          label="This building is a satellite/sub-building to this addresses primary administration, anchor facility"
                          name="isSubBuildingAnchorFacility"
                          value={values.isSubBuildingAnchorFacility}
                          onChange={handleSaveCheck}
                          disabled={!canEdit}
                        />
                      </Grid>
                      <Grid item xs={row}>
                        <FormCheckBox
                          label="This building is a true satellite/sub-building that is void of being affected by or affecting any adjacent buildings that may prevent this building from meeting the spirit and definition of stand-alone"
                          name="isSubBuildingStandalone"
                          value={values.isSubBuildingStandalone}
                          onChange={handleSaveCheck}
                          disabled={!canEdit}
                        />
                      </Grid>
                    </>
                  )}
                  <Grid item xs={row}>
                    <FormSelect
                      name="measurementSystem"
                      label="Measurement System"
                      value={values.measurementSystem}
                      onChange={handleInputChange}
                      options={metricSystem}
                      error={errors.measurementSystem}
                      disable={
                        isSubmitting || readOnly || buildingId > 0 || !canEdit
                      }
                      showSkeleton={isLoading}
                    />
                  </Grid>
                  <Grid item xs={row}>
                    <FormText
                      name="address"
                      label="Address"
                      value={values.address}
                      onChange={handleInputChange}
                      error={errors.address}
                      disabled={isSubmitting || readOnly || !canEdit}
                      showSkeleton={isLoading}
                    />
                  </Grid>
                  <Grid item xs={row}>
                    <FormText
                      name="address2"
                      label="Address 2"
                      value={values.address2}
                      onChange={handleInputChange}
                      error={errors.address2}
                      disabled={isSubmitting || readOnly || !canEdit}
                      showSkeleton={isLoading}
                    />
                  </Grid>
                  <Grid item xs={row}>
                    <FormSelect
                      name="countryId"
                      label="Country"
                      value={values.countryId}
                      onChange={handleInputChange}
                      options={countriesKeyValue}
                      error={errors.countryId}
                      showSkeleton={isLoading}
                      disable={!canEdit || readOnly}
                    />
                  </Grid>
                  <Grid item xs={row}>
                    <Grid container>
                      <Grid item xs={8}>
                        <FormText
                          name="zip"
                          label="Zip Code"
                          value={values.zip}
                          onChange={handleInputChange}
                          error={errors.zip}
                          disabled={isSubmitting || readOnly || !canEdit}
                          maxLength={6}
                          showSkeleton={isLoading}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <FormGoogleMap
                          canEdit={canEdit && fullAccess}
                          address={`${values.address} ${values.address2} ${
                            values.city
                          } ${values.stateName ? values.stateName : ""} ${
                            values.zip
                          } ${
                            countries.filter((item: any) => {
                              return item.id === values.countryId;
                            })[0]?.name ?? ""
                          }`}
                          lat={values.latitude}
                          lng={values.longitude}
                          setLat={(e) => {
                            values.latitude = e;
                            setValues({ ...values });
                          }}
                          setLng={(e) => {
                            values.longitude = e;
                            setValues({ ...values });
                          }}
                        ></FormGoogleMap>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={row}>
                    <FormText
                      name="city"
                      label="City"
                      value={values.city}
                      onChange={handleInputChange}
                      error={errors.city}
                      disabled={isSubmitting || readOnly || !canEdit}
                      showSkeleton={isLoading}
                    />
                  </Grid>
                  <Grid item xs={row}>
                    {values &&
                    values.stateId &&
                    values.countryId === COUNTRIES.USA ? (
                      <FormSelectState
                        stateId={values.stateId}
                        stateName={values.stateName}
                        countryId={values.countryId}
                        errorsStateId={errors.stateId}
                        errorsStateName={errors.stateName}
                        onChange={handleInputChange}
                        isLoading={(value: boolean) => setIsStateLoading(value)}
                        disable={isSubmitting || readOnly || !canEdit}
                      />
                    ) : (
                      <FormText
                        name="stateName"
                        label="State"
                        value={values.stateName}
                        onChange={handleInputChange}
                        error={errors.stateName}
                        disabled={isSubmitting || readOnly || !canEdit}
                        showSkeleton={isLoading}
                      />
                    )}
                  </Grid>
                  <Grid item xs={row}>
                    <FormText
                      name="name"
                      label="Building Contact Persons Name"
                      value={values.buildingContact.name}
                      onChange={handleBuildingContact}
                      disabled={isSubmitting || readOnly || !canEdit}
                      showSkeleton={isLoading}
                    />
                  </Grid>
                  <Grid item xs={row}>
                    <FormText
                      name="title"
                      label="Title"
                      value={values.buildingContact.title}
                      onChange={handleBuildingContact}
                      disabled={isSubmitting || readOnly || !canEdit}
                      showSkeleton={isLoading}
                    />
                  </Grid>
                  <Grid item xs={row}>
                    <FormText
                      name="email"
                      label="Contact's Email"
                      value={values.buildingContact.email}
                      onChange={handleBuildingContact}
                      disabled={isSubmitting || readOnly || !canEdit}
                      showSkeleton={isLoading}
                      error={errors.email}
                    />
                  </Grid>
                  <Grid item xs={row}>
                    <Stack direction="row">
                      <Grid container>
                        <Grid item xs={1.5}>
                          <FormText
                            name="countryCode"
                            label="Code"
                            value={"+ " + values.countryCode}
                            disabled={true}
                          />
                        </Grid>
                        <Grid item xs={9.5}>
                          <FormMaskText
                            name="phone"
                            mask="(999) 999 - 9999"
                            label="Contact's Phone Number"
                            onChange={handleBuildingContact}
                            value={values.buildingContact.phone}
                            error={errors.phone}
                            disabled={readOnly || !canEdit}
                          />
                        </Grid>
                        <Grid item xs={1}>
                          <GridActionButton
                            type="add"
                            onClick={handlerAddContactPhone}
                            tooltip="Add"
                            disabled={!canEdit}
                          />
                        </Grid>
                      </Grid>
                    </Stack>
                  </Grid>
                  <Grid item xs={row}>
                    <FormText
                      name="extension"
                      label="Extension Number"
                      value={values.buildingContact.extension}
                      onChange={handleBuildingContact}
                      disabled={isSubmitting || readOnly || !canEdit}
                      error={errors.buildingContactExtension}
                      showSkeleton={isLoading}
                    />
                  </Grid>
                  <BuildingPhoneNumber
                    readonly={(isSubmitting || readOnly || !canEdit) ?? true}
                    addPhone={addPhone}
                    buildingId={buildingId}
                    saveItems={saveItems}
                    countryCode={values.countryCode}
                    setBuildingPhones={setBuildingPhones}
                  ></BuildingPhoneNumber>

                  <Grid item xs={row}>
                    <FormTextArea
                      name="additional"
                      label="Additional Information"
                      value={values.buildingContact.additional}
                      onChange={handleBuildingContact}
                      placeholder="Enter additional information to better understand this building and project"
                      disabled={!canEdit || readOnly}
                    />
                  </Grid>
                  <Grid item xs={row}>
                    <FormSelect
                      name="isActive"
                      label="Status"
                      value={values.isActive}
                      onChange={handleInputChange}
                      options={statuses}
                      error={errors.isActive}
                      disable={true}
                      showSkeleton={isLoading}
                    />
                  </Grid>
                </>
              )}
              {selectdTab === 1 && (
                <>
                  <Grid item xs={row}>
                    {read && (
                      <Typography>
                        Specific Utilities Revenue Meter Data:
                        <FormButtonTooltip
                          text={"Energy Cost"}
                          onClick={() => {
                            navigate(`/app/BuildingEnergyCost/${id}`);
                          }}
                          startIcon={<ElectricBoltIcon />}
                          size="small"
                          color="primary"
                          sx={{ marginLeft: "10px" }}
                          variant="outlined"
                          disabled={demo}
                          tooltip={demo ? TrainingMessage : undefined}
                        />
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={row}>
                    <FormCheckBox
                      label="Energy Consumption meters specifically record this projects total usage"
                      name="energyConsumption"
                      value={values.energyConsumption}
                      onChange={handleInputChange}
                      disabled={!canEdit || readOnly}
                    />
                  </Grid>
                  <Grid item xs={row}>
                    <FormNumeric2
                      name="numberOfAirHandlersProvidingOutsideAir"
                      label="Number of Air Handlers Conditioning the Building that provide Outside Air"
                      value={values.numberOfAirHandlersProvidingOutsideAir}
                      onChange={handleInputChange}
                      error={errors.numberOfAirHandlersProvidingOutsideAir}
                      disabled={isSubmitting || readOnly || !canEdit}
                      showSkeleton={isLoading}
                    />
                  </Grid>
                  <Grid item xs={row}>
                    <FormNumeric2
                      name="totalSquareFeet"
                      label="Total building square feet (Sq/Ft)"
                      value={values.totalSquareFeet}
                      onChange={handleInputChange}
                      error={errors.totalSquareFeet}
                      disabled={isSubmitting || readOnly || !canEdit}
                      showSkeleton={isLoading}
                    />
                  </Grid>
                  <Grid item xs={row}>
                    <FormNumeric2
                      name="totalFloorArea"
                      label="Total building cubic feet (Cu/Ft)"
                      value={values.totalFloorArea}
                      onChange={handleInputChange}
                      error={errors.totalFloorArea}
                      disabled={isSubmitting || readOnly || !canEdit}
                      showSkeleton={isLoading}
                    />
                  </Grid>
                  <Grid item xs={row}>
                    <FormNumeric2
                      name="floors"
                      label="Number of total floors"
                      value={values.floors}
                      onChange={handleInputChange}
                      error={errors.floors}
                      disabled={isSubmitting || readOnly || !canEdit}
                      showSkeleton={isLoading}
                    />
                  </Grid>
                  <Grid item xs={row}>
                    <FormNumeric2
                      name="parkingArea"
                      label="Total square footage of parking area"
                      value={values.parkingArea}
                      onChange={handleInputChange}
                      error={errors.parkingArea}
                      disabled={isSubmitting || readOnly || !canEdit}
                      showSkeleton={isLoading}
                      suffix={` ft² `}
                    />
                  </Grid>
                  <Grid item xs={row}>
                    <FormNumeric2
                      name="jobSiteDistanceRadius"
                      label="Contractors Technician(s) GPS setting for project site distance radius alerts"
                      value={values.jobSiteDistanceRadius}
                      onChange={handleInputChange}
                      error={errors.jobSiteDistanceRadius}
                      disabled={isSubmitting || readOnly || !canEdit}
                      showSkeleton={isLoading}
                      onBlur={onBlurHandler}
                      suffix={` ${measurementValue} (${milesConvert} ${measurementName})`}
                    />
                  </Grid>
                  <Grid item xs={row}>
                    <Grid container>
                      <FormCheckSwitch
                        name={"isParkingAreaPartOfTotalBuildingFloorArea"}
                        label={`Parking area is part of "Total building" floor area`}
                        onChange={handleInputChange}
                        value={
                          values?.isParkingAreaPartOfTotalBuildingFloorArea
                        }
                        disabled={isSubmitting || readOnly || !canEdit}
                      ></FormCheckSwitch>
                    </Grid>
                  </Grid>
                  <Grid item xs={row}>
                    <FormCheckSwitch
                      name={"isParkingAreaConditioned"}
                      label={`Parking area is conditioned`}
                      onChange={handleInputChange}
                      value={values?.isParkingAreaConditioned}
                      disabled={isSubmitting || readOnly || !canEdit}
                    ></FormCheckSwitch>
                  </Grid>
                  <Grid item xs={row}>
                    <FormSelect
                      name="pressurizationId"
                      label="Building Pressurization"
                      value={values.pressurizationId}
                      onChange={handleInputChange}
                      options={buildingPressurization}
                      disable={isSubmitting || readOnly || !canEdit}
                      showSkeleton={isLoading}
                    />
                  </Grid>
                  <Grid item xs={row}>
                    <FormSelect
                      name="buildingTypeId"
                      label="Building Type"
                      value={values.buildingTypeId ?? "null"}
                      onChange={handleInputChange}
                      options={buildingTypeKeyValue}
                      error={errors.buildingTypeId}
                      disable={readOnly || !canEdit}
                      showSkeleton={false}
                      defaultValue={{ key: "null", value: "Select a value" }}
                    />
                  </Grid>
                  <Grid item xs={row}>
                    <FormSelect
                      name="buildingSubTypeId"
                      label="Building Sub Type"
                      value={values.buildingSubTypeId ?? "null"}
                      onChange={handleInputChange}
                      options={buildingSubTypeKeyValue}
                      error={errors.buildingSubTypeId}
                      disable={readOnly || !canEdit}
                      showSkeleton={false}
                      defaultValue={{ key: "null", value: "Select a value" }}
                    />
                  </Grid>
                  <Grid item xs={row}>
                    <FormNumeric2
                      name="yearBuilt"
                      label="Year Built"
                      value={values.yearBuilt}
                      onChange={handleInputChange}
                      disabled={isSubmitting || readOnly || !canEdit}
                      showSkeleton={isLoading}
                      maxValue={9999}
                    />
                  </Grid>
                </>
              )}
              {selectdTab === 2 && (
                <>
                  <Grid item xs={row}>
                    <FormNumeric2
                      name="weekyOperationgHours"
                      label="Weekly operating hours"
                      value={
                        values.buildingTypeInformation.weekyOperationgHours
                      }
                      onChange={changeBuildingTypeInformation}
                      disabled={isSubmitting || readOnly || !canEdit}
                      showSkeleton={isLoading}
                    />
                  </Grid>
                  <Grid item xs={row}>
                    <FormNumeric2
                      name="percentHeated"
                      label="Percent of building heated"
                      value={values.buildingTypeInformation.percentHeated}
                      onChange={changeBuildingTypeInformation}
                      disabled={isSubmitting || readOnly || !canEdit}
                      showSkeleton={isLoading}
                      maxValue={101}
                      suffix={"%"}
                    />
                  </Grid>
                  <Grid item xs={row}>
                    <FormNumeric2
                      name="percentCooled"
                      label="Percent of building cooled"
                      value={values.buildingTypeInformation.percentCooled}
                      onChange={changeBuildingTypeInformation}
                      disabled={isSubmitting || readOnly || !canEdit}
                      showSkeleton={isLoading}
                      maxValue={101}
                      suffix={"%"}
                    />
                  </Grid>
                  <Grid item xs={row}>
                    <FormNumeric2
                      name="numberOfWorker"
                      label="Number of occupants"
                      value={values.buildingTypeInformation.numberOfWorker}
                      onChange={changeBuildingTypeInformation}
                      disabled={isSubmitting || readOnly || !canEdit}
                      showSkeleton={isLoading}
                    />
                  </Grid>
                </>
              )}
              <Grid item xs={12}>
                <Stack
                  direction="row"
                  spacing={2}
                  justifyContent="center"
                  alignItems="center"
                >
                  <FormCancelButton
                    onClick={handleCancel}
                    isSubmitting={isSubmitting}
                    disabled={isStateLoading}
                  />
                  {fullAccess && (
                    <FormSaveButton
                      onClick={handleSave}
                      isSubmitting={isSubmitting}
                      disabled={isStateLoading || !canEdit}
                      showSkeleton={isLoading}
                    />
                  )}
                </Stack>
              </Grid>
            </Grid>
          </Form>
        </CardContent>
      </Card>
      <DialogMessagePopup
        title={"Information"}
        text={
          "Are you certain you want to change the location of the building?, Previously created projects will not be modified, the new location will be effective only for new projects "
        }
        showPopup={changeZipCodePopup}
        setShowPopup={setChangeZipCodePopup}
        onSave={handleAcceptUpdate}
        isSubmitting={false}
      ></DialogMessagePopup>
    </>
  );
};

export default BuildingForm;
