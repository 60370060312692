import {
  CardContent,
  Grid,
  Divider as MuiDivider,
  Card as MuiCard,
  Alert,
  useTheme,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";
import styled from "@emotion/styled";
import {
  useAsyncQuery,
  useAuth,
  useEffectOnce,
  useLog,
  useSystemOfMeasurement,
} from "src/hooks";
import { useParams } from "react-router";
import { useMemo, useState } from "react";
import { IReportType } from "src/ts/interfaces";
import {
  IProjectReport,
  ProjectDTO,
} from "src/ts/interfaces/project/projectDto";
import projectReportService from "src/services/study/projectReportService";
import reportTypeService from "src/services/study/reportTypeService";
import StudyWizard from "../../components/StudyWizard";
import { FooterButtons } from "../../components/FooterButtons";
import { steps, studyTypeCode } from "../const/const";
import { useNavigate } from "react-router-dom";
import DialogMessagePopup from "src/components/DialogMessagePopup";
import TowerList from "../towerStep2/components/TowerList";
import towerService from "src/services/study/towerService";
import useTowerStudy from "../hooks/useTowerStudy";
import TowerForm from "./components/TowerForm";
import TowerTable1 from "./components/TowerTable1";
import FanMotorList from "./components/FanMotorList";
import projectService from "src/services/project/projectService";
import FanMotorActuals from "./components/FanMotorActuals";
import { IChillerTowerFanMotor, ITower } from "src/ts/interfaces/study/tower";
import studiesHistoryService from "src/services/study/studiesHistoryService";
import { Mode } from "src/ts/types";
import HeaderStudyPage from "src/components/page/HeaderStudyPage";

const Divider = styled(MuiDivider)(spacing);
const Card = styled(MuiCard)(spacing);
const activeStep = 2;

const TowerStep31 = () => {
  const {
    setTowerCondenserValues,
    setTowerCondensers,
    towerCondensers,
    saveTowerCondenser,
    updateFanMotor,
    fanMotorValues,
    setFanMotorValues,
  } = useTowerStudy();

  const theme = useTheme();
  const { user } = useAuth();
  const params = useParams<{ id: string }>();
  const id = parseInt(params?.id === undefined ? "0" : params?.id);
  const { log } = useLog();
  let navigate = useNavigate();
  const { systemOfMeasurement } = useSystemOfMeasurement(true);

  const [reportType, setReportType] = useState<IReportType | null>();

  const { execute: executeReport, data: dataReport } =
    useAsyncQuery<IProjectReport>(projectReportService.report);

  const { execute: getProject, data: project } = useAsyncQuery<ProjectDTO>(
    projectService.byReportType
  );

  const { execute: getTower, data: tower } = useAsyncQuery<ITower>(
    towerService.getBydId,
    {
      onSuccess: async (result) => {
        await systemOfMeasurement.getByProject(result.projectId);
      },
    }
  );
  const [isLoading, setIsLoading] = useState(false);
  useEffectOnce(() => {
    const getData = async () => {
      try {
        setIsLoading(true);
        await getTower(id);

        const condensersReponse = await towerService.towerCondensers(id);
        setTowerCondensers(condensersReponse.data);

        if (condensersReponse.data.length > 0) {
          setTowerCondenserValues(condensersReponse.data[0]);
        }

        const reportTypeRes = await reportTypeService.getReportTypeByCode(
          studyTypeCode
        );
        setReportType(reportTypeRes.data);
        await executeReport(id, reportTypeRes.data.code);

        await getProject(id, studyTypeCode);
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      } finally {
        setIsLoading(false);
      }
    };

    if (id > 0) getData();
  });

  const mode: Mode = tower?.isComplete ? "read" : "read&Write";

  const disableStudy = useMemo(() => {
    return (
      tower?.isComplete ||
      (dataReport?.isInUse && dataReport?.isInUseById !== user?.userId) ||
      dataReport?.isPartOfProject === false
    );
  }, [
    dataReport?.isInUse,
    dataReport?.isInUseById,
    dataReport?.isPartOfProject,
    user?.userId,
    tower?.isComplete,
  ]);

  const nextStepHandler = async () => {
    const route = `/studies/Tower/step4/${id}`;
    if (disableStudy) {
      navigate(route);
      return;
    }

    await save();
    navigate(route);
  };

  const previousStepHandler = async () => {
    const route = `/studies/Tower/step2/${id}`;
    if (disableStudy) {
      navigate(route);
      return;
    }
    await save();
    navigate(route);
  };

  const saveHandler = async () => {
    await save();
  };

  const save = async () => {
    await towerService.update(tower.id, tower);
    await studiesHistoryService.save(
      tower.projectId,
      dataReport?.reportTypeId,
      id,
      "saved"
    );
    await saveTowerCondenser();
    await updateFanMotor(fanMotorValues);
  };

  const [showStartTestPopup, setShowStartTestPopup] = useState(false);
  const [showWarningPopup, setShowWarningPopup] = useState(false);

  const selectMotorHandler = async (
    selectedFanMotor: IChillerTowerFanMotor
  ) => {
    if (!disableStudy) {
      await saveFanMotor();
    }

    const fanMotorRes = await towerService.getFanMotorsById(
      selectedFanMotor.id
    );
    setFanMotorValues(fanMotorRes.data);
  };

  const saveFanMotor = async () => {
    await towerService.updateFanMotor(fanMotorValues.id, fanMotorValues);
  };

  return (
    <>
      <HeaderStudyPage
        headerStudiesPage={{
          code: "TOWE",
          system: tower?.system,
          id: id,
        }}
        parentText="Project"
        parentLink="/"
      />
      <Divider my={6} />

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <StudyWizard
            steps={steps}
            activeStep={activeStep}
            projectId={dataReport?.projectId}
            reportId={dataReport?.reportId}
            reportTypeId={dataReport?.reportTypeId}
          />
        </Grid>
        <Grid item xs={12}>
          <Card mb={1}>
            <CardContent>
              <Grid container spacing={5}>
                <Grid item xs={12}>
                  <Typography variant="h6">
                    <strong>Condenser/Tower Actuals</strong>
                  </Typography>
                  <Typography variant="body1">
                    Record Condenser water supply and return temperature
                    readings. Compare data to digital readout on system control
                    panel.
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <TowerForm
                    mode={mode}
                    disableStudy={disableStudy}
                    isLoading={isLoading}
                    systemOfMeasurement={systemOfMeasurement}
                    parentReportId={dataReport?.parentReportId}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TowerList
                    isStudyComplete={tower?.isComplete}
                    towerCondensers={towerCondensers}
                    saveFanMotor={saveFanMotor}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TowerTable1
                    mode={mode}
                    disableStudy={disableStudy}
                    isLoading={isLoading}
                    systemOfMeasurement={systemOfMeasurement}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FanMotorList
                    prefix="FnMt-"
                    onSelect={selectMotorHandler}
                    disableStudy={true}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FanMotorActuals
                    mode={mode}
                    disableStudy={disableStudy}
                    project={project}
                    isLoading={isLoading}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FooterButtons
                    activeStep={activeStep}
                    stepsCount={steps.length}
                    projectId={dataReport?.projectId}
                    companyId={user?.companyId as number}
                    isSaving={false}
                    disabled={false}
                    reportName={reportType?.name ?? ""}
                    previousStepHandler={previousStepHandler}
                    saveHandler={saveHandler}
                    nextStepHandler={nextStepHandler}
                    hideSaveButton={disableStudy}
                    isComplete={tower?.isComplete ?? true}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <DialogMessagePopup
        title={"WARNING"}
        text={
          "Ensure everybody is clear of any potential system moving parts, ie motors, fans, pulleys, belts, etc."
        }
        showPopup={showStartTestPopup}
        setShowPopup={setShowStartTestPopup}
        onSave={() => {
          setShowStartTestPopup(false);
          setShowWarningPopup(true);
        }}
        onCancel={() => {
          setShowStartTestPopup(false);
        }}
        isSubmitting={false}
        disableClickOutside={true}
      />
      <DialogMessagePopup
        title={"Danger: Arc Flash And Shock Hazard - Appropriate PPE Required."}
        text={
          <>
            <ul style={{ color: theme.palette.error.main }}>
              <li>
                Do not operate controls or open covers without appropriate
                Personal Protection Equipment (PPE). Failure to comply may
                result in Injury or Death.
              </li>
              <li>Refer to NFPA 70E for minimum PPE Requirements.</li>
              <li>
                Warning NFPA code requirements may change, always check for
                current or updated code requirements
              </li>
              <li>
                Request a qualified and licensed electrician to collect voltage
                and Current/Amperage data if not ARC Flash Trained
              </li>
            </ul>
            <Alert severity="error">
              {" "}
              <strong>Warning:</strong> Above is understood and will be adhered
              to.
            </Alert>
          </>
        }
        showPopup={showWarningPopup}
        setShowPopup={setShowWarningPopup}
        onSave={() => {
          setShowWarningPopup(false);
        }}
        onCancel={() => {
          setShowWarningPopup(false);
        }}
        isSubmitting={false}
        disableClickOutside={true}
      />
    </>
  );
};

export default TowerStep31;
