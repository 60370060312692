import { Grid, Typography, Chip as MuiChip, styled } from "@mui/material";
import { useAsyncQuery } from "src/hooks";
import { useEffect, useState } from "react";
import { CompanyService, WalletService } from "src/services";
import { SpacingProps, spacing } from "@mui/system";
import { currencyformatter } from "src/utils/format";
import { ICompany } from "src/ts/interfaces";
import { FormButton } from "src/components/formControls";
import { LocalAtm } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

interface ChipProps extends SpacingProps {
  component?: React.ElementType;
  href?: string;
  icon?: JSX.Element | null;
}
const Chip = styled(MuiChip)<ChipProps>(spacing);
interface Props {
  companyId: number;
  total: number;
}

export default function PurchaseSummary({ companyId, total }: Props) {
  let navigate = useNavigate();
  const { execute, data } = useAsyncQuery<number>(
    WalletService.getWalletBalance
  );
  const [companyInfo, setCompanyInfo] = useState<ICompany>();

  useEffect(() => {
    const getData = async () => {
      await execute(companyId);
      const response = await CompanyService.getById(companyId);
      setCompanyInfo(response.data);
    };
    if (companyId !== 0) getData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyId]);

  return (
    <Grid container sx={{ paddingTop: "0px", marginTop: "0px" }}>
      <Grid item xs={12} sx={{ textAlign: "center" }}>
        <Typography variant="h4">Purchase Summary</Typography>
      </Grid>
      <Grid item xs={6} pt={1} textAlign={"right"}>
        <Typography>Wallet Balance:</Typography>
      </Grid>
      <Grid item xs={6} textAlign={"left"}>
        <Typography>
          <Chip
            label={currencyformatter.format(data ?? 0)}
            variant="outlined"
            color={"primary"}
            m={1}
            size="small"
          ></Chip>
        </Typography>
      </Grid>
      <Grid item xs={6} pt={1} textAlign={"right"}>
        <Typography>Balance Remaining after Purchase:</Typography>
      </Grid>
      <Grid item xs={6} textAlign={"left"}>
        <Typography>
          <Chip
            label={currencyformatter.format((data ?? 0) - (total ?? 0))}
            color={(data ?? 0) - (total ?? 0) > 0 ? "primary" : "error"}
            m={1}
            size="small"
          />
        </Typography>
      </Grid>
      {companyInfo &&
        (data ?? 0) - (total ?? 0) < 0 &&
        companyInfo?.walletAutoWithdraw &&
        !companyInfo?.walletBlocked &&
        !companyInfo?.walletAdminBlocked && (
          <>
            <Grid item xs={12} p={3} textAlign={"center"}>
              <Typography variant="h6">
                The system will automatically withdraw funds from your Wallet's
                default card to complete this purchase.
              </Typography>
            </Grid>
          </>
        )}
      {companyInfo &&
        (data ?? 0) - (total ?? 0) < 0 &&
        !companyInfo?.walletAutoWithdraw &&
        !companyInfo?.walletBlocked &&
        !companyInfo?.walletAdminBlocked && (
          <>
            <Grid item xs={12} p={1} textAlign={"center"}>
              <Typography color={"error.main"} variant="h6">
                You have insufficient funds to complete this purchase, please go
                to your "Wallet" module (Button Below) to complete this
                purchase.
              </Typography>
            </Grid>
            <Grid item xs={12} p={1} textAlign={"center"}>
              <FormButton
                color="primary"
                text="Wallet"
                variant="contained"
                size="small"
                onClick={() => {
                  navigate(`/app/Wallet/${companyId}`);
                }}
                startIcon={<LocalAtm />}
              />
            </Grid>
          </>
        )}
    </Grid>
  );
}
