import axios from "src/utils/axios";
import { IBreakerMfgDTO } from "src/ts/interfaces/catalogs";

const getBreakerMfgs = () => {
  return axios.get<IBreakerMfgDTO[]>("BreakerMfg");
};

const breakerMfgService = {
  getBreakerMfgs,
};

export default breakerMfgService;
