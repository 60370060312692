import { useEffect, useState } from "react";
import { Grid, Stack, Typography } from "@mui/material";
import { useAuth, useLog } from "src/hooks";
import {
  FormButton,
  FormRadioGroup,
  FormTextArea,
} from "src/components/formControls";
import Popup from "src/components/Popup";
import useFormTyped from "src/hooks/useFormTyped";
import { Validator } from "src/ts/types/Validator";
import { ProjectDTO } from "src/ts/interfaces/project/projectDto";
import { IProjectEstimatedTime } from "src/ts/interfaces/projectEstimatedTime";
import projectEstimatedTimeService from "src/services/projectEstimatedTimeService";
import FormNumeric from "../formControls/FormNumeric";
import { estimatedTime } from "src/constants";
import LocalEnhancedTable from "../localTable/LocalTable";
import { ColumnType } from "src/types/enhancedTable";
import { convertUTCDateToLocalDate } from "src/utils/utils";

interface EstimatedTimePopupProps {
  project: ProjectDTO;
  isDialogOpen: boolean;
  setIsDialogOpen: (val: boolean) => void;
  isCurrentProject: boolean;
  validateEstimatedTime: number;
}

const initialValues: IProjectEstimatedTime = {
  id: 0,
  projectId: 0,
  userId: 0,
  estimatedTime: 0,
  isDays: true,
  createdDate: new Date(),
  comment: "",
  color: "",
};

const getColumns = (): ColumnType[] => {
  return [
    {
      id: "isDays",
      label: "Time",
      type: "custom",
      sort: false,
      callback: (row: IProjectEstimatedTime) => {
        return (
          <>
            {row.estimatedTime} {row.isDays ? "Days" : "Hours"}
          </>
        );
      },
    },
    {
      id: "createdDate",
      label: "Saved Date",
      type: "utcDate",
      sort: false,
      format: "MM/DD/yyyy",
    },
    { id: "comment", label: "Comment", type: "string", sort: false },
  ];
};

const EstimatedTimePopup = (props: EstimatedTimePopupProps) => {
  const {
    project,
    isDialogOpen,
    setIsDialogOpen,
    isCurrentProject,
    validateEstimatedTime,
  } = props;
  const { user } = useAuth();
  const { log } = useLog();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [refreshGrid, setRefreshGrid] = useState(false);
  const [todayTimeCard, setTodayTimeCard] = useState(false);
  const [commentsModal, setCommentsModal] = useState(false);
  const [items, setItems] = useState<IProjectEstimatedTime[]>([]);
  const validate: any = (fieldValues: IProjectEstimatedTime = values) => {
    let temp: Record<string, string> = { ...errors };

    temp.hours = new Validator(fieldValues, "hours")
      .isRequired("The time duration is required.")
      .greatThan(0, "Must be greater than 0")
      .lessThan(25, "Must be less than 24")
      .toString();

    temp.comments = new Validator(fieldValues, "comments")
      .maxLength(500, "Only 500 characters are allowed")
      .toString();

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };
  const isTechnichian = () => {
    return (
      user?.role === "TemporalAuditor" ||
      user?.role === "Auditor" ||
      user?.role === "EnergyAuditor" ||
      user?.role === "TemporalEnergyAuditor" ||
      user?.role === "Training"
    );
  };
  const { values, setValues, errors, setErrors, handleInputChange } =
    useFormTyped<IProjectEstimatedTime>(initialValues, false, validate);

  useEffect(() => {
    if (
      !todayTimeCard &&
      isTechnichian() &&
      isCurrentProject &&
      validateEstimatedTime > 0
    ) {
      setIsDialogOpen(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validateEstimatedTime]);

  useEffect(() => {
    if (project.projectEstimatedTime.length !== 0) {
      const today = convertUTCDateToLocalDate(new Date().toString())?.format(
        "MM/DD/YYYY"
      );
      const sortedEstimatedTime = project.projectEstimatedTime.sort(function (
        a,
        b
      ) {
        let dateA = new Date(a.createdDate).valueOf();
        let dateB = new Date(b.createdDate).valueOf();
        return dateA - dateB;
      });

      const lastMoveUtcDate = convertUTCDateToLocalDate(
        sortedEstimatedTime[
          sortedEstimatedTime.length - 1
        ].createdDate.toString()
      )?.format("MM/DD/YYYY");

      if (today?.toString() === lastMoveUtcDate?.toString())
        setTodayTimeCard(true);
    }

    setItems(project.projectEstimatedTime);
  }, [project]);

  const saveHandler = async () => {
    if (!validate()) return;

    let totalHours = project.computerEstimatedTimeRemaining;
    if (project.estimatedTimeDurationUnit === 2) totalHours = totalHours * 8;

    let newtotalHours = values.estimatedTime;
    if (values.isDays === true) newtotalHours = newtotalHours * 8;

    if (totalHours < newtotalHours && !values.comment) {
      setCommentsModal(true);
      return;
    }

    try {
      setIsSubmitting(true);
      if (!todayTimeCard) {
        values.projectId = project.id;
        var result = await projectEstimatedTimeService.add(values);
        setItems((prev) => [result.data, ...prev]);
      }
      setTodayTimeCard(true);
      setCommentsModal(false);
      setIsDialogOpen(false);
      log.success("Estimated Time Was Saved");
      setIsSubmitting(false);
      setRefreshGrid(!refreshGrid);
    } catch (error: any) {
      log.error(error?.message?.exceptionMessage ?? "Something went wrong");
    } finally {
      setIsSubmitting(false);
    }
  };

  const cancelHandler = () => {
    setIsDialogOpen(false);
  };

  return (
    <>
      <Popup
        openPopup={isDialogOpen}
        setOpenPopup={setIsDialogOpen}
        title={`Project Estimated Time Remaining`}
        onClose={cancelHandler}
        disableClickOutside={true}
        disableEscKeyDown={true}
        hideClose={
          !(
            todayTimeCard ||
            !isTechnichian() ||
            !isCurrentProject ||
            user?.canByPassEstimatedTime === "true"
          )
        }
      >
        <Grid container spacing={6}>
          {!todayTimeCard && isTechnichian() && isCurrentProject && (
            <>
              <Grid item xs={4}>
                <Typography>
                  Estimated Time Remaining from this day forward
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <FormNumeric
                  maxLength={6}
                  name="estimatedTime"
                  label="Time"
                  value={values.estimatedTime.toString()}
                  onChange={handleInputChange}
                  error={errors.estimatedTime}
                />
              </Grid>
              <Grid item xs={4}>
                <FormRadioGroup
                  name="isDays"
                  label=""
                  row={true}
                  value={values?.isDays ? 1 : 0}
                  onChange={(e) => {
                    values.isDays = e.target.value === "1" ? true : false;
                    setValues({ ...values });
                  }}
                  items={estimatedTime}
                  showSkeleton={false}
                ></FormRadioGroup>
              </Grid>
              <Grid item xs={2}>
                <FormButton
                  text={"Add"}
                  onClick={saveHandler}
                  size="large"
                ></FormButton>
              </Grid>
            </>
          )}
          <Grid item xs={12}>
            <LocalEnhancedTable<IProjectEstimatedTime>
              columns={getColumns()}
              data={items}
              showSkeleton={false}
              defaultRowPerPage={10}
              defaultSortColumn="id"
              orderColumn="desc"
            />
          </Grid>
        </Grid>
        <>
          <Stack
            direction="row"
            spacing={2}
            justifyContent="center"
            alignItems="center"
          >
            {(todayTimeCard ||
              !isTechnichian() ||
              !isCurrentProject ||
              user?.canByPassEstimatedTime === "true") && (
              <FormButton
                text="Close without saving"
                onClick={cancelHandler}
                isSubmitting={isSubmitting}
                color="primary"
                size="small"
              />
            )}
          </Stack>
        </>
      </Popup>
      <Popup
        openPopup={commentsModal}
        setOpenPopup={setCommentsModal}
        title={`Comments`}
        onClose={() => setCommentsModal(false)}
      >
        <Grid container>
          <Grid item xs={12} pb={5}>
            <Typography>
              Enter in comments below the reason(s) why your estimated time has
              increased from the precious estimated time entry
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <FormTextArea
              name="comment"
              label="Comments"
              value={values.comment}
              onChange={handleInputChange}
              error={errors.comment}
              placeholder="Enter in comments below the reason(s) why your estimated time has increased from the precious estimated time entry"
            />
          </Grid>
        </Grid>
        <>
          <Stack
            direction="row"
            spacing={2}
            justifyContent="center"
            alignItems="center"
          >
            <FormButton
              text="Save"
              onClick={saveHandler}
              isSubmitting={isSubmitting}
              size="small"
            />
          </Stack>
        </>
      </Popup>
    </>
  );
};

export default EstimatedTimePopup;
