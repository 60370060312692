import React from "react";
import {
  FormControl,
  FormControlLabel,
  FormControlProps,
  Checkbox as MuiCheckbox,
  Skeleton,
} from "@mui/material";
import FormReadOnlyField from "./FormReadOnlyField";
import { Mode } from "src/ts/types";

interface FormCheckBoxProps {
  name: string;
  label: string | React.ReactNode;
  value?: boolean;
  onChange?: (e: any) => void;
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
  disabled?: boolean;
  showSkeleton?: boolean;
  mode?: Mode;
  size?: "small" | "medium" | undefined;
  labelPlacement?: "top" | "start" | "bottom" | "end" | undefined;
  formControlProps?: FormControlProps;
  error?: any;
}

export default function FormCheckBox(props: FormCheckBoxProps) {
  const {
    name,
    label,
    value,
    onChange,
    onClick,
    disabled,
    showSkeleton,
    mode,
    size,
    labelPlacement,
    formControlProps,
    error,
  } = props;

  const convertToDefEventPara = (name: string, value: boolean) => ({
    target: {
      name,
      value,
    },
  });

  if (mode && mode === "read")
    return <FormReadOnlyField label={label} value={value ? "Yes" : "No"} />;

  return (
    <>
      {showSkeleton ? (
        <Skeleton height={50} />
      ) : (
        <FormControl
          {...formControlProps}
          {...(error && { error: true, helperText: error })}
        >
          <FormControlLabel
            control={
              <MuiCheckbox
                tabIndex={-1}
                name={name}
                color="primary"
                checked={value}
                onClick={onClick}
                onChange={(e) =>
                  onChange?.(convertToDefEventPara(name, e.target.checked))
                }
                disabled={disabled}
                size={size || "small"}
              />
            }
            labelPlacement={labelPlacement}
            label={label}
          />
        </FormControl>
      )}
    </>
  );
}
