import axios from "src/utils/axios";
import { IPumpSystemPipingProperlyFilledDTO } from "src/ts/interfaces";

const get = () => {
  return axios.get<IPumpSystemPipingProperlyFilledDTO[]>(
    "PumpSystemPipingProperlyFilled"
  );
};

const pumpSystemPipingProperlyFilledService = { get };
export default pumpSystemPipingProperlyFilledService;
