export enum RoleEnum {
  Administrator = 1,
  ProjectManager = 2,
  Auditor = 4,
  Architect = 8,
  SuperAdministrator = 16,
  SysAdmin = 32,
  EnergyAuditor = 64,
  CompanyOwner = 128,
  TemporalAuditor = 256,
  TemporalEnergyAuditor = 512,
  TemporalTraining = 1024,
  Training = 2048,
}

export enum PriorityEnum {
  High = "High",
  Medium = "Medium",
  Low = "Low",
  SelectPriority = "Select Priority",
}

export enum ClassificationEnum {
  SelectClassification = "Select Classification",
  SoftwareIssues = "Software Issues",
  Business = "Business",
  Wallet = "Wallet",
  ReportAbuse = "Report Abuse (legal documents)",
  General = "General",
  ReturnWalletReload = "Return wallet reload",
  ReturnWalletPurchase = "Return wallet purchase",
  Login = "Login",
  Other = "Other",
}

export enum AdministratorList {
  AmpAdmin = "Amp Administrator",
  AntonioRivera = "Antonio Rivera",
  AntonioGuerra = "Antonio Guerra",
  ArgeniSuperAdmin = "Argenis Super Administrator",
  ChristinaSuperAdmin = "Cristina Super Administrator",
  HamilstonSuperAdmin = "Hamilton Super Administrator",
  JasonSuperAdmin = "Jason Super Administrator",
  JesusSuperAdmin = "Jesus Super Administrator",
  LanceSuperAdminHawaii = "Lance Super Administrator Hawaii",
  LanceSuperAdminGlen = "Lance Super ADministrator Glen Ellyn",
  RoberClark = "Robert Clark",
  VeronicaSuperAdmin = "Veronica Super Administrator",
}

export enum PermissionTechnician {
  canAddBuildingsAndProjects = "canAddBuildingsAndProjects",
}

export enum Permission {
  Companies = "Companies",
  Administrators = "Administrators",
  System_Administrators = "System_Administrators",
  Coupons = "Coupons",
  AMP_Documents = "AMP_Documents",
  Catalogs = "Catalogs",
  Wallet_Admin_Center = "Wallet_Admin_Center",
  Quiz = "Quiz",
  Email_Black_list = "Email_Black_list",
  Subscriptions = "Subscriptions",
  Plaque_Decals = "Plaque_Decals",
  History = "History",
  Conversions = "Conversions",
  Projects_out_for_bid = "Projects_out_for_bid",
  Wallet = "Wallet",
  Cart = "Cart",
  Project_Bids = "Project_Bids",
  Project_Bids_Notifications = "Project_Bids_Notifications",
  Companys_Users = "Companys_Users",
  Project_Bid_History = "Project_Bid_History",
  Testimonials = "Testimonials",
  Commitee_Suggestion_Box = "Commitee_Suggestion_Box",
  Union_Dashboard_Route = "Union_Dashboard_Route",
  Companies_Active_Inactive = "Companies_Active_Inactive",
  Companies_Incomplete_Tab = "Companies_Incomplete_Tab",
  Company_Edit = "Company_Edit",
  Union_Section = "Union_Section",
  Users_SuperAdmin = "Users_SuperAdmin",
  Building = "Building",
  Project_Admin = "Project_Admin",
  Project = "Project",
  Studies = "Studies",
  Technicians = "Technicians",
  Company_Assets = "Company_Assets",
  Company_Assets_Purchase = "Company_Assets_Purchase",
  User_Union_Affiliation_Section = "User_Union_Affiliation_Section",
  Company_Documents = "Company_Documents",
  Users_Documents = "Users_Documents",
  Company_Union_Affiliation_Section = "Company_Union_Affiliation_Section",
  Manage_Lockout = "Manage_Lockout",
  Assign_Support_Tickets = "Assign_Support_Tickets",
  SuperAdminDashboard = "SuperAdminDashboard",
  BomaDashboard = "BomaDashboard",
  TrainingDashboard = "TrainingDashboard",
  PurchaseStorage = "PurchaseStorage",
  ProjecEvaluation = "ProjecEvaluation",
  Dispute = "Dispute",
  ProjectContest = "ProjectContest",
  TechniciansAdmin = "TechniciansAdmin",
  TrainingCoursesCatalog = "TrainingCoursesCatalog",
  PlaqueDecalsOrders = "PlaqueDecalsOrders",
  Templates = "Templates",
  Equipment = "Equipment",
  Vehicles = "Vehicles",
  HistoryCostCatalog = "HistoryCostCatalog",
  HistoryCoupon = "HistoryCoupon",
  HistoryAuditorsTechnicians = "HistoryAuditorsTechnicians",
  HistoryProject = "HistoryProject",
  HistoryReports = "HistoryReports",
  HistoryUsersAccess = "HistoryUsersAccess",
  HistoryUsersAcceptance = "HistoryUsersAcceptance",
  HistoryDocumentsNotifications = "HistoryDocumentsNotifications",
  TeamMembers = "TeamMembers",
  TrainingDocuments = "TrainingDocuments",
  PayProject = "PayProject",
  Users = "Users",
  Exam = "Exam",
  PlaqueAndDecalsParent = "PlaqueAndDecalsParent",
  Card = "Card",
  Ideas = "Ideas",
  //roles
  SysAdmin = "SysAdmin",
  SuperAdministrator = "SuperAdministrator",
  Auditor = "Auditor",
  EnergyAuditor = "EnergyAuditor",
  TemporalAuditor = "TemporalAuditor",
  TemporalEnergyAuditor = "TemporalEnergyAuditor",
  Architect = "Architect",
  CompanyOwner = "CompanyOwner",
  UnionAdministrator = "UnionAdministrator",
  Trainer = "Trainer",
  Training = "Training",
  Administrator = "Administrator",
  ProjectManager = "ProjectManager",
  ProjectTechnician = "ProjectTechnician",
  ArchitectAux = "ArchitectAux",
  Infectious_Control_Project = "Infectious_Control_Project",
  Union_Card = "Union_Card",
  Purchase_Storage_Button = "Purchase_Storage_Button",
  Energy_Cost = "Energy_Cost",
  Project_Auditors_Technician = "Project_Auditors_Technician",
  My_Pending_Requests = "My_Pending_Requests",
  ProjectOutForBidParent = "ProjectOutForBidParent",
  Union_Administrator = "Union_Administrator",
}
