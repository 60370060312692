import Popup from "src/components/Popup";
import { FormButton, FormTextArea } from "src/components/formControls";
import { Stack, Typography } from "@mui/material";
import { IAssets } from "src/ts/interfaces/companyAssets";
import { ASSET_TILE, AssetsConstant } from "src/constants";
import { useForm } from "src/hooks";
import { Validator } from "src/ts/types/Validator";
import PasswordPopUp from "./PasswordPopUp";
import { useState } from "react";

interface ReactiveProps {
  reactiveAssetShowPopUp: boolean;
  setReactiveAssetShowPopUp: (val: boolean) => void;
  setRefreshGrid: (val: boolean) => void;
  refreshGrid: boolean;
  assetType: string;
  currentRow: IAssets | undefined;
}

const initialValues = {
  comments: "",
};

export default function ReactiveAsset({
  reactiveAssetShowPopUp,
  setReactiveAssetShowPopUp,
  assetType,
  currentRow,
  setRefreshGrid,
  refreshGrid,
}: ReactiveProps) {
  const [passwordShowPopUp, setPasswordShowPopUp] = useState(false);
  let titleName =
    assetType === AssetsConstant.VEHICLE
      ? ASSET_TILE.VEHICLE
      : `Test ${ASSET_TILE.EQUIPMENT}`;

  const validate: any = (fieldValues = values) => {
    let temp: Record<string, string> = { ...errors };

    temp.comments = new Validator(fieldValues, "comments")
      .isRequired("The comments are required.")
      .maxLength(500, "Only 500 characters are allowed")
      .toString();

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, handleInputChange, errors, setErrors } = useForm(
    initialValues,
    false,
    {}
  );
  const VehicleInfo = () => (
    <>
      <Stack direction="row" spacing={5} mb={3}>
        <Typography>
          <b>Make</b>
        </Typography>
        <Typography>{currentRow?.vehicle?.make}</Typography>
      </Stack>
      <Stack direction="row" spacing={3.5} mb={3}>
        <Typography>
          <b>Model</b>
        </Typography>
        <Typography>{currentRow?.vehicle?.model}</Typography>
      </Stack>
      <Stack direction="row" spacing={6.5} mb={1}>
        <Typography>
          <b>Year</b>
        </Typography>
        <Typography>{currentRow?.vehicle?.year}</Typography>
      </Stack>
    </>
  );

  const EquipmentInfo = () => (
    <>
      <Stack direction="row" spacing={3.7} mb={3} ml={13}>
        <Typography>
          <b>Name</b>
        </Typography>
        <Typography>{currentRow?.equipment?.name}</Typography>
      </Stack>
      <Stack direction="row" spacing={4} mb={3}>
        <Typography>
          <b>Serial Number</b>
        </Typography>
        <Typography>{currentRow?.equipment?.serialNumber}</Typography>
      </Stack>
      <Stack direction="row" spacing={3} mb={5}>
        <Typography>
          <b>Model Number</b>
        </Typography>
        <Typography>{currentRow?.equipment?.model}</Typography>
      </Stack>
    </>
  );

  const handlerAccept = () => {
    if (!validate()) return;

    setPasswordShowPopUp(true);
  };
  return (
    <>
      <Popup
        title={`Reactivate ${titleName}`}
        openPopup={reactiveAssetShowPopUp}
        setOpenPopup={setReactiveAssetShowPopUp}
        onClose={() => {
          setReactiveAssetShowPopUp(false);
        }}
        size="sm"
      >
        <>
          {titleName === ASSET_TILE.EQUIPMENT ? (
            <EquipmentInfo />
          ) : (
            <VehicleInfo />
          )}
          <Stack ml={2}>
            <FormTextArea
              name="comments"
              value={values.comments}
              onChange={handleInputChange}
              label="Comments"
              error={errors.comments}
            />
          </Stack>
        </>
        <Stack direction="row" spacing={3}>
          <FormButton
            size="small"
            text="Close"
            variant="outlined"
            onClick={() => {
              setReactiveAssetShowPopUp(false);
            }}
          />
          <FormButton
            size="small"
            text="Accept"
            color="primary"
            onClick={() => {
              handlerAccept();
            }}
          />
        </Stack>
      </Popup>
      <PasswordPopUp
        passwordShowPopUp={passwordShowPopUp}
        setPasswordShowPopUp={setPasswordShowPopUp}
        comments={values.comments}
        setReactiveAssetShowPopUp={setReactiveAssetShowPopUp}
        assetId={currentRow?.id?.toString() ?? "0"}
        setRefreshGrid={setRefreshGrid}
        refreshGrid={refreshGrid}
      />
    </>
  );
}
