import { ITechDailyLogItem } from "../ts/interfaces";
import axios from "../utils/axios";

const getAuditorList = async (projectId: number, userId: number) => {
  return axios.get<ITechDailyLogItem[]>(
    `TechDailyLog/AuditorDailyLogByUserId?projectId=${projectId}&userId=${userId}`
  );
};

const getAuditorLast = async (projectId: number, userId: number) => {
  return axios.get<ITechDailyLogItem>(
    `TechDailyLog/AuditorLastLogByUserId?projectId=${projectId}&userId=${userId}`
  );
};

const add = async (element: ITechDailyLogItem) => {
  return axios.post(`TechDailyLog`, element);
};

const close = async (idle: boolean = false) => {
  return axios.post(`TechDailyLog/CloseDailyLogByLogout?idle=${idle}`);
};

const techDailyLogService = {
  getAuditorList,
  getAuditorLast,
  add,
  close,
};

export default techDailyLogService;
