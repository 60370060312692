import React, { useEffect, useState } from "react";
import {
  Grid,
  CardContent,
  Typography,
  styled,
  Card as MuiCard,
  Stack,
} from "@mui/material";
import { ColumnType } from "src/types/enhancedTable";
import {
  useAppDispatch,
  useAppSelector,
  useAsyncMutation,
  useAsyncQuery,
  useAuth,
  useLog,
} from "src/hooks";
import LocalEnhancedTable from "src/components/localTable/LocalTable";
import { GridActionButton } from "src/components/gridControls";
import { ProjectAuditorListDTO, ProjectSelectedDTO } from "src/ts/interfaces";
import BuildIcon from "@mui/icons-material/Build";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import { gpsPermissionService } from "src/services";
import GpsPermissionModal from "./components/GpsPermissionModal";
import DialogMessagePopup from "src/components/DialogMessagePopup";
import { spacing } from "@mui/system";
import { useNavigate } from "react-router-dom";
import { signalrActions } from "src/redux/slices/signalrActions";

const Card = styled(MuiCard)(spacing);

const getColumns = (
  user: any,
  permissionStatus: string,
  setCurrentRow: (value: ProjectAuditorListDTO) => void,
  handleTechApp: () => void,
  setShowCreatePermissionDlg: (value: boolean) => void,
  setRemoveCreatePermissionDlg: (value: boolean) => void,
  documentLibraryNavigate: (projectId: number) => void,
  pictureLogNavigate: (projectId: number) => void,
  projectNavigate: (projectId: number) => void
): ColumnType[] => {
  const showGpsPermissionButton1 =
    user?.canRequestGpsPermission === "true" &&
    user.permanentPermissionTechniciansApp === "false" &&
    permissionStatus !== "Permanent" &&
    permissionStatus !== "Active";

  const hideGpsPermissionButton1 =
    (user?.role === "Auditor" || user?.role === "TemporalAuditor") &&
    user?.isInfectiousControlProject;

  const showGpsPermissionButton2 =
    user?.role === "Training" && permissionStatus !== "Permanent";

  const showGpsPermissionButton3 =
    user?.role === "Training" && permissionStatus === "Permanent";

  const showGpsPermissionButton4 =
    user?.role !== "Training" &&
    user?.canRequestGpsPermission === "true" &&
    user?.permanentPermissionTechniciansApp === "false" &&
    (permissionStatus === "Permanent" || permissionStatus === "Active");

  return [
    {
      id: "isInfectiousControlProject",
      label: "Type",
      type: "custom",
      sort: false,
      callback: (row: ProjectSelectedDTO) => {
        return (
          <div>
            {row.isInfectiousControlProject ? (
              <VerifiedUserIcon />
            ) : (
              <BuildIcon />
            )}
          </div>
        );
      },
    },
    { id: "id", label: "Project Id", type: "string", sort: false },
    {
      id: "code",
      label: "Code",
      type: "custom",
      sort: false,
      callback: (row: ProjectSelectedDTO) => {
        return (
          <>
            {row.code}
            {row.companyOwnerId != null && row.companyOwnerId !== 0 && (
              <Typography variant="subtitle2">
                Assigned and Awarded Building Professional Project
              </Typography>
            )}
          </>
        );
      },
    },
    { id: "name", label: "Name", type: "string", sort: false },
    { id: "buildingName", label: "Building", type: "string", sort: false },
    {
      id: "createdDate",
      label: "Created Date",
      type: "utcDate",
      sort: false,
      format: "MM/DD/YYYY",
    },
    {
      id: "actions",
      label: "Actions",
      type: "custom",
      sort: false,
      callback: (row: ProjectAuditorListDTO) => {
        return (
          <div>
            <Stack direction="row">
              <GridActionButton
                type="forward"
                onClick={() => projectNavigate(row.id)}
                tooltip="Go to project"
              />
              <GridActionButton
                type="folder"
                onClick={() => documentLibraryNavigate(row.id)}
                tooltip="Project's Document Library"
              />
              <GridActionButton
                type="picture"
                onClick={() => pictureLogNavigate(row.id)}
                tooltip="Project's picture library"
              />
              <GridActionButton
                type="download"
                onClick={() => {
                  // setCurrentRow(row);
                  // setDuplicateModal(true);
                }}
                tooltip="Offline mode. Download project. Coming soon"
              />
              <GridActionButton
                type="upload"
                onClick={() => {
                  // setCurrentRow(row);
                  // setDuplicateModal(true);
                }}
                tooltip="Offline mode. Download project. Coming soon"
              />

              {/* amp permissions */}
              {showGpsPermissionButton1 && !hideGpsPermissionButton1 && (
                <GridActionButton
                  type="key"
                  onClick={() => {
                    setCurrentRow(row);
                    handleTechApp();
                  }}
                  tooltip="AMP App Permission"
                />
              )}
              {showGpsPermissionButton2 && (
                <GridActionButton
                  type="key"
                  onClick={() => {
                    setCurrentRow(row);
                    setShowCreatePermissionDlg(true);
                  }}
                  tooltip="AMP App Permission"
                />
              )}

              {showGpsPermissionButton3 && (
                <GridActionButton
                  type="keyOff"
                  onClick={() => {
                    setCurrentRow(row);
                    setRemoveCreatePermissionDlg(true);
                  }}
                  tooltip="AMP App Permission"
                />
              )}

              {showGpsPermissionButton4 && (
                <GridActionButton
                  type="keyOff"
                  onClick={() => {
                    setCurrentRow(row);
                    setRemoveCreatePermissionDlg(true);
                  }}
                  tooltip="AMP App Permission"
                />
              )}
            </Stack>
          </div>
        );
      },
    },
  ];
};

interface Props {
  data: ProjectAuditorListDTO[];
  isLoading: boolean;
}

const CurrentProjectGrid = (props: Props) => {
  const { data, isLoading } = props;
  const navigate = useNavigate();
  const { log } = useLog();
  const [isPermissionDialogOpen, setIsPermissionDialogOpen] = useState(false);
  const [showCreatePermissionDlg, setShowCreatePermissionDlg] = useState(false);
  const [showRemovePermissionDlg, setRemoveCreatePermissionDlg] =
    useState(false);
  const [columns, setColumns] = useState<ColumnType[]>([]);
  const [currentRow, setCurrentRow] = useState<ProjectAuditorListDTO>();
  const { user } = useAuth();
  const signalRStatus = useAppSelector((state) => state.signalR);
  const dispatch = useAppDispatch();
  const {
    execute: getPermissionStatus,
    data: permissionStatus,
    isLoading: getPermissionStatusLoading,
  } = useAsyncQuery<string>(gpsPermissionService.getPermissionStatus);
  const getData = async () => {
    await getPermissionStatus();
  };
  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (signalRStatus.messageCode === "OnGpsPermissionActivated") {
      getData();
      dispatch(signalrActions.setMessageCode(""));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signalRStatus.messageCode]);

  const documentLibraryNavigate = (projectId: number) => {
    navigate(`/app/DocumentLibrary/${projectId}/${user?.companyId}/Working`);
  };

  const pictureLogNavigate = (projectId: number) => {
    navigate(`/app/PhotoLibrary/${projectId}/${user?.companyId}/Working`);
  };

  const projectNavigate = (projectId: number) => {
    navigate(`/app/Project/Edit/${projectId}/${user?.companyId}`);
  };

  useEffect(() => {
    if (data && permissionStatus) {
      setColumns([
        ...getColumns(
          user,
          permissionStatus,
          setCurrentRow,
          handleTechApp,
          setShowCreatePermissionDlg,
          setRemoveCreatePermissionDlg,
          documentLibraryNavigate,
          pictureLogNavigate,
          projectNavigate
        ),
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getPermissionStatusLoading, isLoading]);

  const { execute: updateGpsPermission } = useAsyncMutation(
    gpsPermissionService.save,
    {
      successfulMessage:
        "The request has been sent to the company's administrator(s) and project manager(s)",
      onSuccess: async () => {
        getData();
      },
    }
  );

  const saveGpsPermission = async (comment: string) => {
    await updateGpsPermission({
      technicianComments: comment,
      technicianId: user?.userId,
      projectId: currentRow?.id,
    });
  };

  const {
    execute: createGpsPermission,
    isSubmitting: createGpsPermissionIsSubmtting,
  } = useAsyncMutation(gpsPermissionService.createTrainingPermission, {
    successfulMessage: "Permission was created successfully",
    errorMessage: "An error was ocurred",
  });

  const handleCreatePermission = async () => {
    await createGpsPermission(currentRow?.id);
    setShowCreatePermissionDlg(false);
  };

  const {
    execute: removeGpsPermission,
    isSubmitting: removeGpsPermissionIsSubmtting,
  } = useAsyncMutation(gpsPermissionService.removeTrainingPermission, {
    successfulMessage: "Permission was disabled successfully",
    errorMessage: "An error was ocurred",
  });

  const handleRemoveGpsPermission = async () => {
    await removeGpsPermission(user?.userId);
    getData();
    setRemoveCreatePermissionDlg(false);
  };

  const handleTechApp = () => {
    if (permissionStatus === "New") setIsPermissionDialogOpen(true);
    else
      log.info(
        `A temporary disable AMP Technicians App permission is: ${permissionStatus}`
      );
  };

  return (
    <>
      <Card mb={6}>
        <CardContent>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Typography variant="h6">Current Project</Typography>
            </Grid>
            <Grid item xs={12}>
              <LocalEnhancedTable<ProjectAuditorListDTO>
                refreshGrid={true}
                columns={columns}
                data={Object.values(data ?? [])}
                showSkeleton={isLoading}
                skeletonRows={1}
                hidePagination={true}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      <GpsPermissionModal
        isDialogOpen={isPermissionDialogOpen}
        setIsDialogOpen={setIsPermissionDialogOpen}
        onSave={saveGpsPermission}
      />
      <DialogMessagePopup
        title={"Warning"}
        text={`Are you sure you want to temporarily disable the AMP Technicians App for your current project?`}
        showPopup={showCreatePermissionDlg}
        setShowPopup={setShowCreatePermissionDlg}
        onSave={handleCreatePermission}
        isSubmitting={createGpsPermissionIsSubmtting}
      />

      <DialogMessagePopup
        title={"Warning"}
        text={`Are you sure you want to enable the AMP Technicians App for your current project?`}
        showPopup={showRemovePermissionDlg}
        setShowPopup={setRemoveCreatePermissionDlg}
        onSave={handleRemoveGpsPermission}
        isSubmitting={removeGpsPermissionIsSubmtting}
      />
    </>
  );
};

export default CurrentProjectGrid;
