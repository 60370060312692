import Popup from "src/components/Popup";
import { FormButton } from "src/components/formControls";
import { Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

interface WithoutPreferredTemplateProps {
  withoutPreferredTemplate: boolean;
  setWithoutPreferredTemplate: (val: boolean) => void;
}

export default function WithoutPreferredTemplatePopUp({
  withoutPreferredTemplate,
  setWithoutPreferredTemplate,
}: WithoutPreferredTemplateProps) {
  const navigate = useNavigate();
  return (
    <>
      <Popup
        title={"Information"}
        openPopup={withoutPreferredTemplate}
        setOpenPopup={setWithoutPreferredTemplate}
        onClose={() => {
          setWithoutPreferredTemplate(false);
        }}
        size="sm"
      >
        <>
          <Typography>
            Oops, it looks like you don't have a "Preferred Template" currently
            selected. Please go to your dashboard and select "Create/Configure
            Your Templates" or{" "}
            <span
              style={{ cursor: "pointer", textDecoration: "underline" }}
              onClick={() => {
                navigate("/app/templates");
              }}
            >
              click here
            </span>{" "}
            to select your preferred template.
          </Typography>
        </>
        <Stack direction="row" spacing={3}>
          <FormButton
            size="small"
            text="Cancel"
            variant="outlined"
            onClick={() => {
              setWithoutPreferredTemplate(false);
            }}
          />
        </Stack>
      </Popup>
    </>
  );
}
