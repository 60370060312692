import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import {
  Button,
  CardContent,
  Card as MuiCard,
  Divider as MuiDivider,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";
import { technicianQuizExamService } from "src/services";
import { ITechnicianQuizQuestions } from "src/ts/interfaces/technicianQuizQuestions";
import FormTextEditor from "src/components/formControls/FormTextEditor";
import ExamAnswers from "./ExamAnswers";
import DialogMessagePopup from "src/components/DialogMessagePopup";
import { useLog } from "src/hooks";

const Card = styled(MuiCard)(spacing);
const Divider = styled(MuiDivider)(spacing);

interface IExamProps {
  title: string;
  description: string;
  examId: number;
  onFinish: (e: number) => void;
}

function ExamStart(props: IExamProps) {
  const [exam, setExam] = useState<ITechnicianQuizQuestions[]>();
  const [completeModal, setCompleteModal] = useState(false);
  const [isSubmiting, setIsSubmiting] = useState(false);
  const { log } = useLog();
  useEffect(() => {
    const getData = async () => {
      try {
        const request = await technicianQuizExamService.getQuiz();
        setExam(request.data);
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      }
    };
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  const handleFinish = async () => {
    try {
      setIsSubmiting(true);
      let result = await technicianQuizExamService.finishExam();
      setCompleteModal(false);
      setIsSubmiting(false);
      if (result.data.statusExam === 0) props.onFinish(3);
      else if (result.data.statusExam === 1) props.onFinish(2);
    } catch (error: any) {
      log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      setCompleteModal(false);
      setIsSubmiting(false);
    }
  };

  return (
    <React.Fragment>
      <Card mb={12}>
        <CardContent>
          <Typography variant="h3" align="center">
            {props.title}
          </Typography>
          <Typography variant="subtitle1">{props.description}</Typography>
          <Divider></Divider>
          {exam &&
            exam.map((item, index) => (
              <>
                <FormTextEditor
                  value={item.technicianQuizBacklog.description}
                  onChange={function (e: any): void {
                    throw new Error("Function not implemented.");
                  }}
                  name={"description" + index}
                  readonly={true}
                ></FormTextEditor>
                <ExamAnswers
                  answers={item.technicianQuizBacklog.technicianQuizAnswers}
                  questionId={item.technicianQuizBacklog.id}
                  examId={props.examId}
                ></ExamAnswers>
                <Divider></Divider>
              </>
            ))}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            onClick={() => {
              setCompleteModal(true);
            }}
          >
            Complete Test
          </Button>
        </CardContent>
      </Card>
      <DialogMessagePopup
        title={"Complete Test"}
        text={`Are you certain you want to complete this test?`}
        showPopup={completeModal}
        setShowPopup={setCompleteModal}
        onSave={handleFinish}
        isSubmitting={isSubmiting}
      />
    </React.Fragment>
  );
}

export default ExamStart;
