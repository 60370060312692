import {
  IAssetTransfer,
  ITransferHistory,
} from "src/ts/interfaces/companyAssets";
import axios from "src/utils/axios";

const getAssetTransferGetAll = (userId: number, assetCode: string) => {
  return axios.get<ITransferHistory[]>(
    `AssetTransfer/GetAll?userId=${userId}&code=${assetCode}`
  );
};

const getByCode = (code: string) => {
  return axios.get<IAssetTransfer>(`AssetTransfer?code=${code}`);
};

const updateByCode = (code: string, status: string, comments: string) => {
  return axios.put(
    `AssetTransfer?code=${code}&status=${status}&comments=${comments}`
  );
};

const assetTransferService = {
  getAssetTransferGetAll,
  getByCode,
  updateByCode,
};

export default assetTransferService;
