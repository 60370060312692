import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { useNavigate, useParams } from "react-router-dom";
import {
  Grid,
  Divider as MuiDivider,
  Card as MuiCard,
  CardContent,
  Stack,
  Tabs,
  Tab,
  Typography,
  Chip as MuiChip,
} from "@mui/material";
import { spacing } from "@mui/system";
import { ColumnType } from "src/types/enhancedTable";
import {
  useForm,
  Form,
  useAuth,
  useLog,
  usePermissions,
  useAsyncQuery,
  useAppSelector,
} from "src/hooks";
import FormText from "src/components/formControls/FormText";
import { GridActionButton } from "src/components/gridControls";
import getProjectService from "src/services/project/getProjectService";
import {
  ICompany,
  IProjectActiveResult,
  IProjectCovid,
  ISelectedUsers,
  ProjectSelectedDTO,
  TableData,
} from "src/ts/interfaces";
import { ShieldIcon, ArrowBackIcon, AddIcon } from "src/components/icons";
import { FormButton } from "src/components/formControls";
import DailyLogPopUp from "../Components/DailyLogPopUp";
import DuplicateProjectPopupPopUp from "../Components/DuplicateProjectPopup";
import ChangeProjectStatusPopup from "../Components/ChangeProjectStatusPopup";
import HeaderPage from "src/components/page/HeaderPage";
import AddProjectByCode from "./components/AddProjectByCode";
import {
  CompanyService,
  projectActiveService,
  projectCurrentService,
  projectPutService,
} from "src/services";
import projectHistoryService from "src/services/project/projectHistoryService";
import projectReportService from "src/services/study/projectReportService";
import DialogMessagePopup from "src/components/DialogMessagePopup";
import MatchWordConfirmModal from "src/components/modals/MatchWordConfirmModal";
import getProjectSingleService from "src/services/project/getProjectSingleService";
import { ProjectDTO } from "src/ts/interfaces/project/projectDto";
import ShareByEmailPopUpComponent from "src/components/others/ShareByEmailPopUpComponent";
import projectEmailsService from "src/services/projectEmailsService";
import { Permission } from "src/ts/enums";
import SatellitePopup from "src/pages/company/components/SatellitePopup";
import { ViewList } from "@mui/icons-material";
import { ROLES } from "src/constants";
import CompanyInformation from "../createEditProject/components/CompanyInformation";
import { makeStyles } from "@mui/styles";
import { useTable } from "src/hooks/useTable";
import { SpacingProps } from "@mui/system";
import { convertUTCDateToLocalDate } from "src/utils/utils";

interface ChipProps extends SpacingProps {
  component?: React.ElementType;
  href?: string;
  icon?: JSX.Element | null;
}

const Chip = styled(MuiChip)<ChipProps>(spacing);
const Divider = styled(MuiDivider)(spacing);
const Card = styled(MuiCard)(spacing);

const useStyles = makeStyles({
  "@keyframes blinkAnimation": {
    "0%": {
      opacity: 1,
    },
    "50%": {
      opacity: 0,
    },
    "100%": {
      opacity: 1,
    },
  },
  blinking: {
    animation: "$blinkAnimation 2s infinite",
  },
});

const initialValues = {
  id: 0,
  name: "",
  statusId: "",
};

const getActiveColumns = (
  gotoProject: (project: ProjectSelectedDTO) => void,
  openDailyLog: (projectId: number) => void,
  gotoProjectDashboard: (projectId: number) => void,
  openDisableProject: (projectId: number, disabed: boolean) => void,
  setCurrentProject: (project: ProjectSelectedDTO) => void,
  setActiveInactiveProject: (project: ProjectSelectedDTO, mode: string) => void,
  gotoDocumentLibrary: (project: ProjectSelectedDTO) => void,
  gotoPhotoLibrary: (project: ProjectSelectedDTO) => void,
  setCanDelete: (project: ProjectSelectedDTO) => void,
  projectAdminAccess: boolean,
  setShowSatellite: (showSatellite: boolean) => void,
  setCurrentProjectId: (projectId: number) => void,
  headquarters: boolean | undefined,
  gotoDispute: (projectId: number) => void,
  classes: any
): ColumnType[] => {
  return [
    { id: "id", label: "ID", type: "string", sort: true },
    { id: "name", label: "Project", type: "string", sort: true },
    {
      id: "buildingName",
      label: "Building",
      type: "custom",
      sort: true,
      callback: (row: any) => (
        <>
          <b>{row.buildingName} </b>
          <br />
          {row.buildingAddress}
        </>
      ),
    },
    {
      id: "projectDescription",
      label: "Description",
      type: "string",
      sort: true,
    },
    {
      id: "currentProject",
      label: "My Current Project",
      type: "custom",
      width: "15px",
      sort: true,
      callback: (row: ProjectSelectedDTO) => {
        return (
          <>
            {row.currentProject ? (
              <GridActionButton
                type="enable"
                color="success"
                tooltip=""
                onClick={() => setCurrentProject(row)}
              />
            ) : (
              <GridActionButton
                type="emptyCircle"
                tooltip=""
                onClick={() => setCurrentProject(row)}
              />
            )}
          </>
        );
      },
    },
    {
      id: "activeProject",
      label: "My Active Project(s)",
      type: "custom",
      width: "15px",
      sort: true,
      callback: (row: ProjectSelectedDTO) => {
        return (
          <>
            {row.activeProject ? (
              <GridActionButton
                type="enable"
                color="success"
                tooltip=""
                onClick={() => setActiveInactiveProject(row, "Remove")}
              />
            ) : (
              <GridActionButton
                type="emptyCircle"
                tooltip=""
                onClick={() => setActiveInactiveProject(row, "Add")}
              />
            )}
          </>
        );
      },
    },
    {
      id: "createdDate",
      label: "Date Created",
      type: "utcDate",
      format: "MM/DD/yyyy",
      sort: true,
    },
    {
      id: "action",
      label: "Actions",
      type: "custom",
      sort: false,
      callback: (row: ProjectSelectedDTO) => {
        return (
          <div>
            <Stack direction="row">
              <GridActionButton
                type="forward"
                onClick={() => gotoProject(row)}
                tooltip="Go to project"
              />
              <GridActionButton
                type="gauge"
                onClick={() => {
                  gotoProjectDashboard(row.id);
                }}
                tooltip="Dashboard Project"
              />
              <GridActionButton
                type="location"
                onClick={() => {
                  openDailyLog(row.id);
                }}
                tooltip="Project Daily Log"
              />

              <GridActionButton
                type="folder"
                onClick={() => gotoDocumentLibrary(row)}
                tooltip="Project's Document Library"
              />
              <GridActionButton
                type="picture"
                onClick={() => gotoPhotoLibrary(row)}
                tooltip="Project's picture library"
              />
              <GridActionButton
                type="disable"
                onClick={() => {
                  openDisableProject(row.id, row.disabled);
                }}
                tooltip="Disable"
              />
              {(row?.companyOwnerId ?? 0) === 0 && (
                <GridActionButton
                  type="delete"
                  onClick={() => {
                    setCanDelete(row);
                  }}
                  tooltip="Delete Project"
                />
              )}
              {(row?.companyOwnerId ?? 0) !== 0 && (
                <GridActionButton
                  type="chat"
                  onClick={() => {
                    gotoDispute(row?.id);
                  }}
                  tooltip="Dispute"
                  className={row.isOpenDispute ? classes.blinking : ""}
                />
              )}

              {(row?.companyOwnerId ?? 0) !== 0 &&
                projectAdminAccess &&
                headquarters && (
                  <GridActionButton
                    type="swap"
                    onClick={() => {
                      setCurrentProjectId(row.id);
                      setShowSatellite(true);
                    }}
                    tooltip="Transfer Project"
                  />
                )}
            </Stack>
            {(row?.companyOwnerId ?? 0) !== 0 ? (
              <Typography textAlign={"center"}>
                Assigned and Awarded Building Professional Project
              </Typography>
            ) : null}
          </div>
        );
      },
    },
  ];
};

const getInctiveColumns = (
  gotoProject: (project: ProjectSelectedDTO) => void,
  gotoProjectDashboard: (projectId: number) => void,
  openDisableProject: (projectId: number, disabed: boolean) => void,
  gotoDocumentLibrary: (project: ProjectSelectedDTO) => void,
  gotoPhotoLibrary: (project: ProjectSelectedDTO) => void,
  setCanDelete: (project: ProjectSelectedDTO) => void
): ColumnType[] => {
  return [
    { id: "id", label: "ID", type: "string", sort: true },
    { id: "name", label: "Project", type: "string", sort: true },
    {
      id: "buildingName",
      label: "Building",
      type: "custom",
      sort: true,
      callback: (row: any) => (
        <>
          <b>{row.buildingName} </b>
          <br />
          {row.buildingAddress}
        </>
      ),
    },
    {
      id: "projectComments",
      label: "Comments",
      type: "string",
      sort: true,
    },
    {
      id: "createdDate",
      label: "Date Created",
      type: "utcDate",
      format: "MM/DD/yyyy",
      sort: true,
    },
    {
      id: "deactivationDate",
      label: "Deactivation Date",
      type: "utcDate",
      format: "MM/DD/yyyy",
      sort: true,
    },
    {
      id: "action",
      label: "Actions",
      type: "custom",
      sort: false,
      callback: (row: ProjectSelectedDTO) => {
        return (
          <div>
            <Stack direction="row">
              <GridActionButton
                type="forward"
                onClick={() => gotoProject(row)}
                tooltip="Go to project"
              />
              <GridActionButton
                type="gauge"
                onClick={() => {
                  gotoProjectDashboard(row.id);
                }}
                tooltip="Dashboard Project"
              />
              <GridActionButton
                type="folder"
                onClick={() => gotoDocumentLibrary(row)}
                tooltip="Project's Document Library"
              />
              <GridActionButton
                type="picture"
                onClick={() => gotoPhotoLibrary(row)}
                tooltip="Project's picture library"
              />
              <GridActionButton
                type="enable"
                onClick={() => {
                  openDisableProject(row.id, row.disabled);
                }}
                tooltip="Enable"
              />
              {(row?.companyOwnerId ?? 0) === 0 && (
                <GridActionButton
                  type="delete"
                  onClick={() => {
                    setCanDelete(row);
                  }}
                  tooltip="Delete Project"
                />
              )}
            </Stack>
            {(row?.companyOwnerId ?? 0) !== 0 ? (
              <b>Assigned and Awarded Building Professional Project</b>
            ) : null}
          </div>
        );
      },
    },
  ];
};

const getCompleteGridColumns = (
  gotoProject: (project: ProjectSelectedDTO) => void,
  openDailyLog: (projectId: number) => void,
  duplicateNoActuals: (projectId: number, copyActuals: boolean) => void,
  gotoProjectDashboard: (projectId: number) => void,
  gotoDocumentLibrary: (project: ProjectSelectedDTO) => void,
  gotoPhotoLibrary: (project: ProjectSelectedDTO) => void,
  setForwardModal: (forwardModal: boolean) => void,
  setCurrentProjectId: (projectId: number) => void
): ColumnType[] => [
  { id: "id", label: "ID", type: "string", sort: true },
  { id: "name", label: "Project", type: "string", sort: true },
  {
    id: "buildingName",
    label: "Building",
    type: "custom",
    sort: true,
    callback: (row: any) => (
      <>
        <b>{row.buildingName} </b>
        <br />
        {row.buildingAddress}
      </>
    ),
  },
  {
    id: "createdDate",
    label: "Date Created",
    type: "utcDate",
    format: "MM/DD/yyyy",
    sort: true,
  },
  {
    id: "completedDate",
    label: "Date Completed",
    type: "utcDate",
    format: "MM/DD/yyyy",
    sort: true,
  },
  {
    id: "completionFee",
    label: "Completion Fee",
    type: "custom",
    format: "MM/DD/yyyy",
    sort: true,
    callback: (row: ProjectSelectedDTO) => {
      return (
        <Stack direction="column">
          <Stack textAlign={"center"}>
            {convertUTCDateToLocalDate(
              row.completionFee?.toString() || ""
            )?.format("MM/DD/YYYY")}
          </Stack>
          {row?.isPaid && (
            <Chip
              label={"Payment Received"}
              color="success"
              m={1}
              size="small"
            />
          )}
          {!row?.isPaid && (
            <Chip label={"Pending"} color="warning" m={1} size="small" />
          )}
        </Stack>
      );
    },
  },

  {
    id: "completedBy",
    label: "Completed By",
    type: "string",
    sort: true,
  },
  {
    id: "action",
    label: "Actions",
    type: "custom",
    sort: false,
    callback: (row: ProjectSelectedDTO) => {
      return (
        <div>
          <Stack direction="row">
            <GridActionButton
              type="forward"
              onClick={() => gotoProject(row)}
              tooltip="Go to project"
            />
            <GridActionButton
              type="gauge"
              onClick={() => {
                gotoProjectDashboard(row.id);
              }}
              tooltip="Dashboard Project"
            />
            <GridActionButton
              type="location"
              onClick={() => {
                openDailyLog(row.id);
              }}
              tooltip="Project Daily Log"
            />

            <GridActionButton
              type="folder"
              onClick={() => gotoDocumentLibrary(row)}
              tooltip="Project's Document Library"
            />
            <GridActionButton
              type="picture"
              onClick={() => gotoPhotoLibrary(row)}
              tooltip="Project's picture library"
            />
            <GridActionButton
              type="copy2"
              onClick={() => {
                duplicateNoActuals(row.id, false);
              }}
              tooltip="Duplicate Project - No Actuals"
            />
            <GridActionButton
              type="copy"
              onClick={() => {
                duplicateNoActuals(row.id, true);
              }}
              tooltip="Duplicate Project - Full"
            />
            <GridActionButton
              type="email"
              onClick={() => {
                setCurrentProjectId(row.id);
                setForwardModal(true);
              }}
              tooltip="Forward Project"
            />
          </Stack>
          {(row?.companyOwnerId ?? 0) !== 0 ? (
            <b>Assigned and Awarded Building Professional Project</b>
          ) : null}
        </div>
      );
    },
  },
];

const getUnderEvaluationGridColumns = (
  gotoControlInfectProject: (project: IProjectCovid) => void,
  openDailyLog: (projectId: number) => void,
  gotoProjectDashboard: (projectId: number) => void,
  openDisableProject: (projectId: number, disabed: boolean) => void
): ColumnType[] => [
  { id: "id", label: "ID", type: "string", sort: true },
  { id: "name", label: "Project", type: "string", sort: true },
  {
    id: "buildingAddress",
    label: "Building Address",
    type: "string",
    sort: true,
  },
  {
    id: "projectDescription",
    label: "Description",
    type: "string",
    sort: true,
  },
  {
    id: "createdDate",
    label: "Date Created",
    type: "utcDate",
    format: "MM/DD/yyyy",
    sort: true,
  },
  {
    id: "airHandlers",
    label: "# of Air Handlers (Building)",
    type: "string",
    sort: true,
  },
  {
    id: "airHandlersCompleted",
    label: "# of Air Handlers Under Evaluation",
    type: "string",
    sort: true,
  },
  {
    id: "airHandlersApproved",
    label: "# of Air Handlers Approved",
    type: "string",
    sort: true,
  },
  {
    id: "airHandlersRejected",
    label: "# of Air Handlers Rejected",
    type: "string",
    sort: true,
  },
  {
    id: "action",
    label: "Actions",
    type: "custom",
    sort: false,
    callback: (row: IProjectCovid) => {
      return (
        <div>
          <Stack direction="row">
            <GridActionButton
              type="forward"
              onClick={() => gotoControlInfectProject(row)}
              tooltip="Go to project"
            />
            <GridActionButton
              type="gauge"
              onClick={() => {
                gotoProjectDashboard(row.id);
              }}
              tooltip="Dashboard Project"
            />
            <GridActionButton
              type="location"
              onClick={() => {
                openDailyLog(row.id);
              }}
              tooltip="Project Daily Log"
            />
            <GridActionButton
              type="disable"
              onClick={() => {
                openDisableProject(row.id, false);
              }}
              tooltip="Disable"
            />

            <GridActionButton
              type="folder"
              onClick={() => {} /*documentLibraryNavigate(row.id)*/}
              tooltip="Project's Document Library"
            />
            <GridActionButton
              type="picture"
              onClick={() => {} /*pictureLogNavigate(row.id)*/}
              tooltip="Project's picture library"
            />

            <GridActionButton
              type="chat"
              onClick={() => {}}
              tooltip="Dispute"
            />
            <GridActionButton
              type="email"
              onClick={() => {}}
              tooltip="Duplicate Project - Full"
            />
          </Stack>
        </div>
      );
    },
  },
];

const getCompleteControlGridColumns = (
  gotoControlInfectProject: (project: IProjectCovid) => void,
  duplicateNoActuals: (projectId: number, copyActuals: boolean) => void,
  gotoProjectDashboard: (projectId: number) => void,
  setForwardModal: (forwardModal: boolean) => void,
  setCurrentProjectId: (projectId: number) => void
): ColumnType[] => [
  { id: "id", label: "ID", type: "string", sort: true },
  { id: "name", label: "Project", type: "string", sort: true },
  {
    id: "buildingName",
    label: "Building",
    type: "custom",
    sort: true,
    callback: (row: IProjectCovid) => (
      <>
        <b>{row.buildingName} </b>
        <br />
        {row.buildingAddress}
      </>
    ),
  },
  {
    id: "createdDate",
    label: "Date Created",
    type: "utcDate",
    format: "MM/DD/yyyy",
    sort: true,
  },
  {
    id: "completedDate",
    label: "Date Completed",
    type: "utcDate",
    format: "MM/DD/yyyy",
    sort: true,
  },
  {
    id: "completionFee",
    label: "Completion Fee",
    type: "utcDate",
    format: "MM/DD/yyyy",
    sort: true,
  },
  {
    id: "completedBy",
    label: "Completed By",
    type: "string",
    sort: true,
  },
  {
    id: "action",
    label: "Actions",
    type: "custom",
    sort: false,
    callback: (row: IProjectCovid) => {
      return (
        <div>
          <Stack direction="row">
            <GridActionButton
              type="forward"
              onClick={() => {} /*projectNavigate(row.id)*/}
              tooltip="Go to project"
            />
            <GridActionButton
              type="gauge"
              onClick={() => {
                gotoProjectDashboard(row.id);
              }}
              tooltip="Dashboard Project"
            />
            <GridActionButton
              type="folder"
              onClick={() => {} /*documentLibraryNavigate(row.id)*/}
              tooltip="Project's Document Library"
            />
            <GridActionButton
              type="picture"
              onClick={() => {} /*pictureLogNavigate(row.id)*/}
              tooltip="Project's picture library"
            />
            <GridActionButton
              type="copy2"
              onClick={() => {
                duplicateNoActuals(row.id, false);
              }}
              tooltip="Duplicate Project - No Actuals"
            />
            <GridActionButton
              type="copy"
              onClick={() => {
                duplicateNoActuals(row.id, true);
              }}
              tooltip="Duplicate Project - Full"
            />
            <GridActionButton
              type="email"
              onClick={() => {
                setCurrentProjectId(row.id);
                setForwardModal(true);
              }}
              tooltip="Forward Project"
            />
          </Stack>
          {(row?.companyOwnerId ?? 0) !== 0 ? (
            <b>Assigned and Awarded Building Professional Project</b>
          ) : null}
        </div>
      );
    },
  },
];

const getApprovedControlGridColumns = (
  gotoControlInfectProject: (project: IProjectCovid) => void,
  openDailyLog: (projectId: number) => void,
  gotoProjectDashboard: (projectId: number) => void,
  gotoOrderPlaque: (projectId: number) => void,
  setForwardModal: (forwardModal: boolean) => void,
  setCurrentProjectId: (projectId: number) => void
): ColumnType[] => [
  { id: "id", label: "ID", type: "string", sort: true },
  { id: "name", label: "Project", type: "string", sort: true },
  {
    id: "buildingAddress",
    label: "Building Address",
    type: "string",
    sort: true,
  },
  {
    id: "projectDescription",
    label: "Description",
    type: "string",
    sort: true,
  },
  {
    id: "createdDate",
    label: "Date Created",
    type: "utcDate",
    format: "MM/DD/yyyy",
    sort: true,
  },
  {
    id: "completedDate",
    label: "Date Completed",
    type: "utcDate",
    format: "MM/DD/yyyy",
    sort: true,
  },
  {
    id: "approvedDate",
    label: "Date Approved",
    type: "utcDate",
    format: "MM/DD/yyyy",
    sort: true,
  },
  {
    id: "approvedByFullName",
    label: "Approved By",
    type: "string",
    sort: true,
  },
  {
    id: "action",
    label: "Actions",
    type: "custom",
    sort: false,
    callback: (row: IProjectCovid) => {
      return (
        <div>
          <Stack direction="row">
            <GridActionButton
              type="forward"
              onClick={() => gotoControlInfectProject(row)}
              tooltip="Go to project"
            />
            <GridActionButton
              type="gauge"
              onClick={() => {
                gotoProjectDashboard(row.id);
              }}
              tooltip="Dashboard Project"
            />
            <GridActionButton
              type="location"
              onClick={() => {
                openDailyLog(row.id);
              }}
              tooltip="Project Daily Log"
            />
            <GridActionButton
              type="folder"
              onClick={() => {} /*documentLibraryNavigate(row.id)*/}
              tooltip="Project's Document Library"
            />
            <GridActionButton
              type="picture"
              onClick={() => {} /*pictureLogNavigate(row.id)*/}
              tooltip="Project's picture library"
            />
            <GridActionButton
              type="email"
              onClick={() => {
                setCurrentProjectId(row.id);
                setForwardModal(true);
              }}
              tooltip="Forward Project"
            />
            <GridActionButton
              type="shield"
              onClick={() => {
                gotoOrderPlaque(row.id);
              }}
              tooltip="Order Plaque"
            />
            <GridActionButton
              type="download"
              onClick={() => {}}
              tooltip="Certificate"
            />
            <GridActionButton
              type="delete"
              onClick={() => {}}
              tooltip="Delete Certificate"
            />
          </Stack>
          {(row?.companyOwnerId ?? 0) !== 0 ? (
            <b>Assigned and Awarded Building Professional Project</b>
          ) : null}
        </div>
      );
    },
  },
];

const getMyActiveProjectsColumns = (
  gotoProject: (project: ProjectSelectedDTO) => void,
  openDailyLog: (projectId: number) => void,
  gotoProjectDashboard: (projectId: number) => void,
  gotoDocumentLibrary: (project: ProjectSelectedDTO) => void,
  gotoPhotoLibrary: (project: ProjectSelectedDTO) => void
): ColumnType[] => {
  return [
    { id: "id", label: "ID", type: "string", sort: true },
    { id: "name", label: "Project", type: "string", sort: true },
    {
      id: "buildingName",
      label: "Building",
      type: "custom",
      sort: true,
      callback: (row: any) => (
        <>
          <b>{row.buildingName} </b>
          <br />
          {row.buildingAddress}
        </>
      ),
    },
    {
      id: "description",
      label: "Description",
      type: "string",
      sort: true,
    },
    {
      id: "createdDate",
      label: "Date Created",
      type: "utcDate",
      format: "MM/DD/yyyy",
      sort: true,
    },
    {
      id: "action",
      label: "Actions",
      type: "custom",
      sort: false,
      callback: (row: ProjectSelectedDTO) => {
        return (
          <div>
            <Stack direction="row">
              <GridActionButton
                type="forward"
                onClick={() => gotoProject(row)}
                tooltip="Go to project"
              />
              <GridActionButton
                type="gauge"
                onClick={() => {
                  gotoProjectDashboard(row.id);
                }}
                tooltip="Dashboard Project"
              />
              <GridActionButton
                type="location"
                onClick={() => {
                  openDailyLog(row.id);
                }}
                tooltip="Project Daily Log"
              />
              <GridActionButton
                type="folder"
                onClick={() => gotoDocumentLibrary(row)}
                tooltip="Project's Document Library"
              />
              <GridActionButton
                type="picture"
                onClick={() => gotoPhotoLibrary(row)}
                tooltip="Project's picture library"
              />
            </Stack>
            {(row?.companyOwnerId ?? 0) !== 0 ? (
              <b>Assigned and Awarded Building Professional Project</b>
            ) : null}
          </div>
        );
      },
    },
  ];
};

const ProjectList = () => {
  const { user } = useAuth();
  const classes = useStyles();
  let { tabName } = useParams<{ tabName: string }>();
  let { companyId } = useParams<{ companyId: string }>();
  const paramCompanyId = parseInt(companyId === undefined ? "0" : companyId);
  const { log } = useLog();
  const navigate = useNavigate();
  const { fullAccess: projectAdminAccess } = usePermissions(
    Permission.Project_Admin
  );
  const [companyData, setCompanyData] = useState<ICompany>();
  const [selectedSatelliteId, setSelectedSatelliteId] = useState(0);

  const [showTransferModal, setShowTransferModal] = useState(false);
  const [showSatellite, setShowSatellite] = useState(false);
  const [myActiveProjectsDataSet, setMyActiveProjectsDataSet] = useState<
    IProjectActiveResult[]
  >([]);

  const [forwardModal, setForwardModal] = useState(false);
  const [currentProjectId, setCurrentProjectId] = useState(0);

  const [dialogModal, setDialogModal] = useState(false);
  const [projectName, setProjectName] = useState("");
  const [deleteProjectId, setDeleteProjectId] = useState(0);
  const [deleteModal, setDeleteModal] = useState(false);

  const [showChangeProjectStatusPopup, setShowChangeProjectStatusPopup] =
    useState({ status: false, disabled: false, projectId: 0 });
  const [isControlProject, setIsControlProject] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [activeProjects, setActiveProjects] = useState(false);
  const [showDailyLogPopUp, setShowDailyLogPopUp] = useState(false);
  const [copyActuals, setCopyActuals] = useState(false);
  const [showDuplicatePopUp, setShowDuplicatePopUp] = useState(false);
  const [selectProjectId, setSelectProjectId] = useState(0);
  const [tab, setTab] = React.useState(
    tabName === "Completed" ? 2 : tabName === "MyActiveProjects" ? 6 : 0
  );
  const [firstLoad, setFirstLoad] = useState(true);
  const [selectedProject, setSelectedProject] = useState(0);
  const [refreshTable, setRefreshTable] = useState(0);
  const [showAddProjectByCode, setShowAddProjectByCode] = useState(false);
  const [showSatelliteModal, setShowSatelliteModal] = useState(false);
  const signalRStatus = useAppSelector((state) => state.signalR);
  const { values: filterValues, handleInputChange: handleFilterChange } =
    useForm(initialValues, true, []);
  const { execute: executeCompany, data: dataCompany } =
    useAsyncQuery<ICompany>(CompanyService.getById);
  const [projectTypeChange, setProjectTypeChange] = useState(0);

  useEffect(() => {
    if (tabName === "ApprovedByAMP" && firstLoad) {
      setIsControlProject(true);
      setTab(5);
      setFirstLoad(false);
    }
    executeCompany(paramCompanyId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paramCompanyId]);

  const gotoProject = (project: ProjectSelectedDTO) => {
    if (
      !project.activeProject &&
      project.projectStatusId !== 1 &&
      !project.disabled &&
      !project.isInfectiousControlProject
    ) {
      navigate(`/app/ProjectStudies/${project.id}/${paramCompanyId}`);
    } else {
      navigate(`/app/Project/Edit/${project.id}/${paramCompanyId}`);
    }
  };

  const gotToPlaqueCompanyOrders = () => {
    navigate(`/app/plaqueCompanyOrders`);
  };

  const gotoControlInfectProject = (project: IProjectCovid) => {
    if (
      (project.isInfectiousControlProject &&
        project.controlStatusId !== undefined &&
        project.controlStatusId !== 11 &&
        project.controlStatusId !== 12) ||
      (project.isInfectiousControlProject &&
        project.controlStatusId !== undefined &&
        project.controlStatusId === 12) ||
      (project.isInfectiousControlProject &&
        project.controlStatusId !== undefined &&
        project.controlStatusId === 11)
    ) {
      navigate(`/app/ProjectStudies/${project.id}/${paramCompanyId}`);
    } else {
      navigate(`/app/Project/Edit/${project.id}/${paramCompanyId}`);
    }
  };
  const handleDuplicateNoActuals = (projectId: number, actuals: boolean) => {
    setCopyActuals(actuals);
    setSelectProjectId(projectId);
    setShowDuplicatePopUp(true);
  };
  const handleOpenDailyLog = (projectId: number) => {
    setSelectedProject(projectId);
    setShowDailyLogPopUp(true);
  };
  const handleOpenDisablePopUp = (projectId: number, disabled: boolean) => {
    setShowChangeProjectStatusPopup({
      status: true,
      disabled: disabled,
      projectId: projectId,
    });
  };
  const handleGotoDashboard = (projectId: number) => {
    navigate(`/app/projectDashboard/${projectId}`);
  };

  const setCurrentProject = async (project: ProjectSelectedDTO) => {
    if (project.currentProject) {
      log.error("There must be a current project selected");
    } else {
      try {
        await projectCurrentService.setCurrentProject(project.id);
        await projectHistoryService.log(
          project.id,
          "Project was set as current"
        );
        log.info("The current project was updated");
        setRefreshTable(refreshTable + 1);
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      }
    }
  };

  const setActiveInactiveProject = async (
    project: ProjectSelectedDTO,
    mode: string
  ) => {
    try {
      await projectActiveService.modifyActiveProject(project.id, mode);
      setRefreshTable(refreshTable + 1);
      log.info("The active projects list was updated");
    } catch (error: any) {
      log.error(error?.message?.exceptionMessage ?? "Something went wrong");
    }
  };

  const setCanDelete = async (project: ProjectSelectedDTO) => {
    try {
      const canDeleteRespose = await projectReportService.canDeleteProject(
        project.id
      );
      setProjectName(project.name);
      if (!canDeleteRespose.data) {
        setDialogModal(true);
      } else if (project.currentProject)
        log.warning("The project can't be deleted: is the current project");
      else if (project.activeProject)
        log.warning(
          "The project can't be deleted: is part of the active projects"
        );
      else {
        setDeleteProjectId(project.id);
        setDeleteModal(true);
      }
    } catch (error: any) {
      log.error(error?.message?.exceptionMessage ?? "Something went wrong");
    }
  };

  const setProjectSingle = async () => {
    try {
      const projectSingleData = await getProjectSingleService.getById(
        deleteProjectId
      );
      if (projectSingleData) await deleteHandler(projectSingleData.data);
    } catch (error: any) {
      log.error(error?.message?.exceptionMessage ?? "Something went wrong");
    }
  };

  const deleteHandler = async (projectDto: ProjectDTO) => {
    try {
      projectDto.projectStatusId = 4;
      await projectPutService.update(deleteProjectId, projectDto);
      setDeleteProjectId(0);
      setRefreshTable(refreshTable + 1);
      log.success("Project was deleted succesfully");
    } catch (error: any) {
      log.error(error?.message?.exceptionMessage ?? "Something went wrong");
    }
  };

  const gotoDocumentLibrary = (project: ProjectSelectedDTO) => {
    navigate(`/app/DocumentLibrary/${project.id}/${paramCompanyId}/Project`);
  };

  const gotoPhotoLibrary = (project: ProjectSelectedDTO) => {
    navigate(`/app/PhotoLibrary/${project.id}/${paramCompanyId}/Project`);
  };

  const gotoOrderPlaque = (projectId: number) => {
    navigate(`/app/selectPlaque/${projectId}/1/projectList`);
  };

  const gotoDispute = (projectId: number) => {
    navigate(`/app/dispute/${projectId}`);
  };

  const columns = getActiveColumns(
    gotoProject,
    handleOpenDailyLog,
    handleGotoDashboard,
    handleOpenDisablePopUp,
    setCurrentProject,
    setActiveInactiveProject,
    gotoDocumentLibrary,
    gotoPhotoLibrary,
    setCanDelete,
    projectAdminAccess,
    setShowSatellite,
    setCurrentProjectId,
    dataCompany?.headquarters,
    gotoDispute,
    classes
  );
  const inactiveGridColumns = getInctiveColumns(
    gotoProject,
    handleGotoDashboard,
    handleOpenDisablePopUp,
    gotoDocumentLibrary,
    gotoPhotoLibrary,
    setCanDelete
  );
  const completeGridColumns = getCompleteGridColumns(
    gotoProject,
    handleOpenDailyLog,
    handleDuplicateNoActuals,
    handleGotoDashboard,
    gotoDocumentLibrary,
    gotoPhotoLibrary,
    setForwardModal,
    setCurrentProjectId
  );
  const underEvaluationGridColumns = getUnderEvaluationGridColumns(
    gotoControlInfectProject,
    handleOpenDailyLog,
    handleGotoDashboard,
    handleOpenDisablePopUp
  );
  const completeControlGridColumns = getCompleteControlGridColumns(
    gotoControlInfectProject,
    handleDuplicateNoActuals,
    handleGotoDashboard,
    setForwardModal,
    setCurrentProjectId
  );
  const approvedControlGridColumns = getApprovedControlGridColumns(
    gotoControlInfectProject,
    handleOpenDailyLog,
    handleGotoDashboard,
    gotoOrderPlaque,
    setForwardModal,
    setCurrentProjectId
  );

  const myActiveProjectsGridColumns = getMyActiveProjectsColumns(
    gotoProject,
    handleOpenDailyLog,
    handleGotoDashboard,
    gotoDocumentLibrary,
    gotoPhotoLibrary
  );

  const {
    Table,
    page,
    setDataSet,
    setCount,
    orderBy,
    order,
    setPage,
    startRequest,
    endRequest,
  } = useTable(
    tab === 0
      ? columns
      : tab === 1
      ? inactiveGridColumns
      : tab === 2
      ? completeGridColumns
      : tab === 3
      ? underEvaluationGridColumns
      : tab === 4
      ? completeControlGridColumns
      : tab === 5
      ? approvedControlGridColumns
      : tab === 6
      ? myActiveProjectsGridColumns
      : [],
    {
      rowsPerPageOptions: [10],
      defaultOrder: "desc",
    }
  );

  useEffect(() => {
    const getData = async () => {
      try {
        setIsSubmitting(true);
        const response = await CompanyService.getById(user?.companyId ?? 0);
        setCompanyData(response.data);
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      } finally {
        setIsSubmitting(false);
      }
    };
    getData();
    if (signalRStatus.projectList > 0) {
      getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (refreshTable < 1) return;

    getData();
    if (signalRStatus.projectList > 0) {
      getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshTable, page, orderBy, order, paramCompanyId]);

  useEffect(() => {
    const timer = setTimeout(() => {
      getData();
    }, 1000);

    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterValues.name]);

  const getData = async () => {
    try {
      setIsSubmitting(true);
      startRequest("project");
      if (tabName === "MyActiveProjects") {
        setActiveProjects(true);
        const myActiveProjectsResponse =
          await projectActiveService.GetMyActiveProjectsList();
        setMyActiveProjectsDataSet(myActiveProjectsResponse.data);
      } else {
        if (tab === 0 || tab === 1) {
          const activeProjectRespose =
            await getProjectService.getProjectSelectedItem(
              paramCompanyId,
              isControlProject,
              page + 1,
              filterValues.name,
              orderBy,
              order,
              10,
              tab === 1 ? true : false
            );
          setDataSet(activeProjectRespose.data.data);
          setCount(activeProjectRespose.data.count);
        } else if (isControlProject) {
          let controlStatusId = -1;
          if (tab === 4) controlStatusId = 12;
          if (tab === 5) controlStatusId = 11;
          const projectControldRespose =
            await getProjectService.getControlEvaluatedItems(
              paramCompanyId,
              isControlProject,
              page + 1,
              filterValues.name,
              orderBy,
              order,
              10,
              controlStatusId
            );
          setDataSet(projectControldRespose.data.data);
          setCount(projectControldRespose.data.count);
        } else if (tab === 2) {
          const projectCompletedRespose =
            await getProjectService.getCompleteSelectedItems(
              paramCompanyId,
              isControlProject,
              page + 1,
              filterValues.name,
              orderBy,
              order,
              10
            );
          setDataSet(projectCompletedRespose.data.data);
          setCount(projectCompletedRespose.data.count);
        }
      }
    } catch (error: any) {
      endRequest("project");
      log.error(error?.message?.exceptionMessage ?? "Something went wrong");
    } finally {
      setIsSubmitting(false);
      endRequest("project");
    }
  };

  useEffect(() => {
    setPage(0);
    setRefreshTable(refreshTable + 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab, projectTypeChange]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTab(newValue);
  };

  const onAddProjectByCode = () => {
    setShowAddProjectByCode(false);
    setRefreshTable(refreshTable + 1);
  };

  const handleRefreshTable = () => {
    setRefreshTable(refreshTable + 1);
  };

  const handleProjectEmailsSendEmail = async (dataTable: TableData[]) => {
    try {
      const selectedUsers = dataTable.map((item) => {
        return {
          email: item.email,
          id: item.id,
          isTeamMemeber: item.userType === "TeamMember",
          name: item.name,
        } as ISelectedUsers;
      }) as ISelectedUsers[];

      const response = await projectEmailsService.sendEmailProject(
        selectedUsers,
        currentProjectId
      );

      response.data.forEach((item: any) => {
        if (item.wasSuccessful) log.success(`${item.email} - ${item.message}`);
        else log.error(`${item.email} - ${item.message}`);
      });
    } catch (error: any) {
      log.error(error?.message?.exceptionMessage ?? "Something went wrong");
    }
  };

  const handleOnSelectSatellite = (satelliteId: number) => {
    setSelectedSatelliteId(satelliteId);
    setShowTransferModal(true);
  };

  const transferProject = async () => {
    try {
      await projectPutService.transferProject(
        currentProjectId,
        selectedSatelliteId
      );
      log.success("The project was sent to the satellite company");
      setRefreshTable(refreshTable + 1);
    } catch (error: any) {
      log.error(error?.message?.exceptionMessage ?? "Something went wrong");
    }
  };

  const showSatelliteHandler = async () => {
    setShowSatelliteModal(true);
  };

  return (
    <>
      <HeaderPage
        title="Project"
        parentText="Dashboard"
        parentLink="/"
        actionSection={
          <Stack direction="row" spacing={2}>
            {!isControlProject && (
              <>
                <FormButton
                  text={"Create New Project"}
                  onClick={() => {
                    navigate(`/app/Project/Edit/0/${paramCompanyId}`);
                  }}
                  startIcon={<AddIcon />}
                  size="small"
                  color="primary"
                />
                <FormButton
                  text={"Add Project by Code"}
                  onClick={() => {
                    setShowAddProjectByCode(true);
                  }}
                  startIcon={<AddIcon />}
                  size="small"
                  color="primary"
                />
                {!isControlProject && !activeProjects && (
                  <FormButton
                    onClick={() => {
                      setIsControlProject(true);
                      setTab(0);
                      setProjectTypeChange(1);
                    }}
                    variant="outlined"
                    color="primary"
                    size="small"
                    text={"View Infectious Control Compliance Projects"}
                    startIcon={<ShieldIcon />}
                  />
                )}
                {dataCompany?.headquarters &&
                  dataCompany?.satelliteCount !== undefined &&
                  (user?.role === ROLES.CompanyOwner ||
                    user?.role === ROLES.SuperAdministrator ||
                    user?.role === ROLES.Administrator ||
                    user?.role === ROLES.Training) &&
                  dataCompany?.satelliteCount > 0 && (
                    <FormButton
                      text={"View Satellites"}
                      onClick={showSatelliteHandler}
                      startIcon={<ViewList />}
                      size="small"
                      variant="outlined"
                      color="primary"
                    />
                  )}
              </>
            )}
            {isControlProject && (
              <>
                <FormButton
                  text={"Plaque & Decals Order(s)"}
                  onClick={gotToPlaqueCompanyOrders}
                  startIcon={<ShieldIcon />}
                  size="small"
                  color="primary"
                />
                <FormButton
                  onClick={() => {
                    setIsControlProject(false);
                    setTab(0);
                    setProjectTypeChange(0);
                  }}
                  variant="outlined"
                  color="primary"
                  text={"Return to Regular Projects"}
                  startIcon={<ArrowBackIcon />}
                  size="small"
                />
              </>
            )}
          </Stack>
        }
        infoSection={
          !dataCompany?.headquarters && (
            <CompanyInformation company={dataCompany} />
          )
        }
      />
      <Divider my={6} />

      <Card mb={6}>
        <CardContent>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Form>
                <Grid container>
                  <Grid item xs={12}>
                    <FormText
                      name="name"
                      label="Search"
                      value={filterValues.name}
                      onChange={handleFilterChange}
                    />
                  </Grid>
                </Grid>
              </Form>
            </Grid>
            <Grid item xs={12}>
              {!isControlProject && !activeProjects && (
                <Tabs
                  value={tab}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                >
                  <Tab label="Active" id="0" value={0} />
                  <Tab label="Inactive" id="1" value={1} />
                  <Tab label="Completed" id="2" value={2} />
                </Tabs>
              )}
              {!isControlProject && activeProjects && (
                <Tabs value={tab} aria-label="basic tabs example">
                  <Tab label="My Active Projects" id="6" value={6} />
                </Tabs>
              )}
              {isControlProject && (
                <Tabs
                  value={tab}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                >
                  <Tab label="Active" id="0" value={0} />
                  <Tab label="Inactive" id="1" value={1} />
                  <Tab label="Under AMP evaluation" id="3" value={3} />
                  <Tab label="Completed" id="4" value={4} />
                  <Tab label="Approved by AMP" id="5" value={5} />
                </Tabs>
              )}
            </Grid>
            <Grid item xs={12}>
              <Table />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      {showDailyLogPopUp && selectedProject !== 0 && (
        <DailyLogPopUp
          showDailyLogPopUp={showDailyLogPopUp}
          setShowDailyLogPopUp={setShowDailyLogPopUp}
          projectId={selectedProject}
        ></DailyLogPopUp>
      )}
      <DuplicateProjectPopupPopUp
        projectId={selectProjectId}
        showDuplicatePopUp={showDuplicatePopUp}
        setShowDuplicatePopUp={setShowDuplicatePopUp}
        copyActuals={copyActuals}
        refreshTable={handleRefreshTable}
      ></DuplicateProjectPopupPopUp>
      {showChangeProjectStatusPopup && (
        <ChangeProjectStatusPopup
          changeProjectStatusProps={showChangeProjectStatusPopup}
          setChangeProjectStatusProps={setShowChangeProjectStatusPopup}
          refreshTable={handleRefreshTable}
        ></ChangeProjectStatusPopup>
      )}

      <AddProjectByCode
        isDialogOpen={showAddProjectByCode}
        setIsDialogOpen={setShowAddProjectByCode}
        onSave={onAddProjectByCode}
      />
      <DialogMessagePopup
        title={"Information"}
        text={`${"The project"} ${projectName} ${"can't be deleted, it is under use by other user."}`}
        showPopup={dialogModal}
        setShowPopup={setDialogModal}
        onSave={() => {
          setDialogModal(false);
          setProjectName("");
        }}
        isSubmitting={isSubmitting}
        hideCancel={true}
      />
      <MatchWordConfirmModal
        onConfirm={setProjectSingle}
        text={`Are you certain you want to delete project: ${projectName}?`}
        matchWord={"DELETE"}
        isDialogOpen={deleteModal}
        setIsDialogOpen={setDeleteModal}
      />

      <ShareByEmailPopUpComponent
        sendbyEmailShow={forwardModal}
        setSendbyEmailShow={setForwardModal}
        sendEmailFunction={handleProjectEmailsSendEmail}
        hideLogSuccess={true}
      />
      <SatellitePopup
        showSatellitePopup={showSatelliteModal}
        setShowSatellitePopup={setShowSatelliteModal}
        companyId={user?.companyId}
        companyName={dataCompany?.name}
        historyView="Projects"
      />
      <SatellitePopup
        showSatellitePopup={showSatellite}
        setShowSatellitePopup={setShowSatellite}
        companyId={user?.companyId}
        companyName={companyData?.name}
        historyView="Select Satellite"
        onClick={handleOnSelectSatellite}
      />

      <DialogMessagePopup
        title={"Warning"}
        text={
          <>
            <Typography>
              Are you certain you want to transfer this project?
            </Typography>
          </>
        }
        showPopup={showTransferModal}
        setShowPopup={setShowTransferModal}
        isSubmitting={false}
        onSave={() => {
          transferProject();
        }}
      />
    </>
  );
};

export default ProjectList;
