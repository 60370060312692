import { IconButton } from "@mui/material";
import { YouTube } from "@mui/icons-material";
import { useEffect, useState } from "react";
import youtubeTrainingService from "src/services/youtubeTrainingService";
import { IYoutubeTraining } from "src/ts/interfaces/youtubeTraining";

interface IYoutubeLink {
  reportType?: string;
  page?: string;
}

const YoutubeLink = ({ reportType, page }: IYoutubeLink) => {
  const [values, setValues] = useState<IYoutubeTraining>();
  useEffect(() => {
    const getReportType = async () => {
      const response = await youtubeTrainingService.getByReportType(
        reportType ?? ""
      );
      setValues(response.data[0]);
    };

    if (reportType) {
      getReportType();
    }
  }, [reportType]);

  useEffect(() => {
    const getPage = async () => {
      const response = await youtubeTrainingService.getByPage(page ?? "");
      setValues(response.data[0]);
    };

    if (page) {
      getPage();
    }
  }, [page]);

  return (
    <>
      {values && values.publish && (
        <span>
          <IconButton
            aria-label="delete"
            size="large"
            href={values.url}
            target="_blank"
            title={values.title}
          >
            <YouTube color="error" />
          </IconButton>
        </span>
      )}
    </>
  );
};

export default YoutubeLink;
