import Popup from "src/components/Popup";
import { FormButton, FormTextArea } from "src/components/formControls";
import { Stack, Grid, Stepper, Step, StepLabel } from "@mui/material";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { CheckCircleOutline, HighlightOffRounded } from "@mui/icons-material";
import { useAsyncMutation, useLog } from "src/hooks";
import projectHistoryStatusCommentsService from "src/services/project/projectHistoryStatusCommentsService";
import { IPostHistoryStatusComments } from "src/ts/interfaces";
import projectService from "src/services/project/projectService";
import { IRatingContractors } from "src/ts/interfaces/projectOutToBid";

interface props {
  modalPopup: boolean;
  setModalPopup: (val: boolean) => void;
  projectId: number;
  setRatingInformationModal: Dispatch<SetStateAction<boolean>>;
  refresh: () => void;
  ratingProject: IRatingContractors | undefined;
  projectStatusId: number;
}

export default function EvaluatePopup({
  modalPopup,
  setModalPopup,
  projectId,
  setRatingInformationModal,
  refresh,
  ratingProject,
  projectStatusId,
}: props) {
  const steps = ["Evaluate Project", "Rating"];
  const [comments, setComments] = useState("");
  const { log } = useLog();
  const { execute, isSubmitting } = useAsyncMutation(
    projectHistoryStatusCommentsService.postProjectHistoryStatusComments,
    {
      hideSuccessfulMessage: true,
      onSuccess: async (result, params: any) => {
        await executeStatus(projectId, params[0].statusId);
        log.success(
          `The project was ${
            params[0].statusId === 11 ? "approved" : "rejected"
          }`
        );
      },
    }
  );

  const { execute: executeStatus, isSubmitting: isSubmittingComments } =
    useAsyncMutation(projectService.putChangeStatusNoComments, {
      hideSuccessfulMessage: true,
      onSuccess: () => {
        setModalPopup(false);
        if (!ratingProject?.saved) setRatingInformationModal(true);
        refresh();
      },
    });

  useEffect(() => {
    if (modalPopup) setComments("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalPopup]);

  const handleApprove = async (status: number) => {
    const values: IPostHistoryStatusComments = {
      comments: comments,
      projectId: projectId,
      statusId: status,
    };

    await execute(values);
  };

  return (
    <>
      <Popup
        title={`Evaluate Project`}
        openPopup={modalPopup}
        setOpenPopup={setModalPopup}
        onClose={() => {
          setModalPopup(false);
        }}
        size="sm"
      >
        <>
          <Grid container spacing={4}>
            {!ratingProject?.saved && (
              <Grid item xs={12}>
                <Stepper alternativeLabel activeStep={0}>
                  {steps.map((label) => (
                    <Step key={label}>
                      <StepLabel>{label}</StepLabel>
                    </Step>
                  ))}
                </Stepper>
              </Grid>
            )}
            <Grid item xs={12}>
              <FormTextArea
                name={""}
                label={""}
                value={comments}
                onChange={(e: any) => {
                  setComments(e.target.value);
                }}
                placeholder="Please be descriptive on your comments"
              ></FormTextArea>
            </Grid>
          </Grid>
        </>
        <Stack direction="row" spacing={3}>
          <FormButton
            size="small"
            text="Close"
            color="primary"
            variant="outlined"
            onClick={() => {
              setModalPopup(false);
            }}
            isSubmitting={isSubmitting || isSubmittingComments}
          />
          {projectStatusId !== 10 && (
            <FormButton
              size="small"
              text="Reject"
              color="primary"
              variant="outlined"
              onClick={() => {
                handleApprove(10);
              }}
              startIcon={<HighlightOffRounded />}
              isSubmitting={isSubmitting || isSubmittingComments}
              disabled={!comments}
            />
          )}
          <FormButton
            size="small"
            text="Approve"
            color="primary"
            onClick={() => {
              handleApprove(11);
            }}
            startIcon={<CheckCircleOutline />}
            isSubmitting={isSubmitting || isSubmittingComments}
            disabled={!comments}
          />
        </Stack>
      </Popup>
    </>
  );
}
