import { Box } from "@mui/material";

const JCBIcon = () => {
  return (
    <Box
      component="img"
      alt="Logo AMP"
      src={"/static/img/creditCard/jcb.svg"}
      sx={{
        width: "60px",
      }}
    />
  );
};

export default JCBIcon;
