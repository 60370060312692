import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { Helmet } from "react-helmet-async";
import {
  Grid,
  Divider as MuiDivider,
  Typography as MuiTypography,
} from "@mui/material";
import { spacing } from "@mui/system";
import { usersStatistics } from "src/services";
import { useAsyncQuery } from "src/hooks";
import DialogMessagePopup from "src/components/DialogMessagePopup";
import Stats from "../components/Stats";
import { green } from "@mui/material/colors";
import UserBarChart from "./UserBarChart";
import { Users as UsersIcon } from "react-feather";

const Divider = styled(MuiDivider)(spacing);

const Typography = styled(MuiTypography)(spacing);

const SysAdministratorDashboard = () => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const { execute: getNumberOfUsers, data: numberOfUsers } =
    useAsyncQuery<number>(usersStatistics.numberOfUsers);

  const { execute: getOnlineUsers, data: onlineUsers } = useAsyncQuery<number>(
    usersStatistics.onlineUsers
  );

  const { execute: getUsersByMonth, data: usersByMonth } = useAsyncQuery<
    number[]
  >(usersStatistics.usersByMonth);

  useEffect(() => {
    const getNumberOfUsersData = async () => {
      await getNumberOfUsers();
      await getOnlineUsers();
      await getUsersByMonth();
    };

    getNumberOfUsersData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      <Helmet title="Analytics Dashboard" />
      <Grid justifyContent="space-between" container spacing={6}>
        <Grid item>
          <Typography variant="h3" gutterBottom>
            Dashboard
          </Typography>
          <Typography variant="subtitle1">AMP Dashboard</Typography>
        </Grid>
        <Grid item></Grid>
      </Grid>
      <Divider my={6} />
      <Grid container spacing={5}>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Stats
            title="Total Users"
            Icon={UsersIcon}
            amount={numberOfUsers?.toString() ?? ""}
            percentagecolor={green[500]}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Stats
            title="Online Users"
            Icon={UsersIcon}
            amount={onlineUsers?.toString() ?? ""}
            percentagecolor={green[500]}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <UserBarChart arrayData={usersByMonth} />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}></Grid>
      </Grid>
      <DialogMessagePopup
        title={"Information"}
        text={"under construction"}
        showPopup={showDeleteModal}
        setShowPopup={setShowDeleteModal}
        onSave={() => setShowDeleteModal(false)}
        isSubmitting={false}
      />
    </React.Fragment>
  );
};

export default SysAdministratorDashboard;
