import React, { useState } from "react";
import styled from "@emotion/styled";
import {
  Grid,
  Divider as MuiDivider,
  Card as MuiCard,
  CardContent,
} from "@mui/material";
import { spacing } from "@mui/system";
import PurchaseHistoryLogFilter from "./purchaseHistory/PurchaseHistoryLogFilter";
import PurchaseHistoryLogGrid from "./purchaseHistory/PurchaseHistoryLogGrid";
import { useNavigate, useParams } from "react-router-dom";
import HeaderPage from "src/components/page/HeaderPage";
import { ElavonPaymentFilter } from "./types";
import { FormCancelButton } from "src/components/formControls";

const Divider = styled(MuiDivider)(spacing);
const Card = styled(MuiCard)(spacing);

const WalletHistory = () => {
  let { companyId } = useParams<{ companyId: string }>();
  const [filters, setFilters] = useState<ElavonPaymentFilter | null>(null);
  const [refresh, setRefresh] = useState(false);
  const navigate = useNavigate();
  const searchHandler = (filters: ElavonPaymentFilter) => {
    setFilters(filters);
    setRefresh(!refresh);
  };

  return (
    <>
      <HeaderPage
        title={"Wallet History"}
        parentText={"Wallet"}
        parentLink={`/Wallet/${companyId}`}
      ></HeaderPage>
      <Divider my={6} />

      <Card mb={6}>
        <CardContent>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={12}>
                  <PurchaseHistoryLogFilter onSearch={searchHandler} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <PurchaseHistoryLogGrid filter={filters} refresh={refresh} />
            </Grid>
            <Grid item xs={12} textAlign={"center"}>
              <FormCancelButton
                onClick={() => {
                  navigate(`/app/Wallet/${companyId}`);
                }}
                isSubmitting={false}
              ></FormCancelButton>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
};

export default WalletHistory;
