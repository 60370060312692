import axios from "src/utils/axios";
import { IPitotMatrixTitle } from "src/ts/interfaces/study/pitot";

const getByPitotId = (id: number, orientation: number) => {
  return axios.get<IPitotMatrixTitle[]>(
    `PitotMatrixTitle?pitotMatrixId=${id}&orientation=${orientation}`
  );
};

const deletePitotTitle = (id: number, orientation: number) => {
  return axios.delete(`PitotMatrixTitle/${id}`);
};

const postMatrixX = (InternalInsulation: number, x: number, id: number) => {
  return axios.post<IPitotMatrixTitle[]>(
    `PitotMatrixTitle?InternalInsulation=${InternalInsulation}&x=${x}`,
    {
      pitotTraverseId: id,
    }
  );
};

const postMatrixY = (InternalInsulation: number, y: number, id: number) => {
  return axios.post<IPitotMatrixTitle[]>(
    `PitotMatrixTitle?InternalInsulation=${InternalInsulation}&y=${y}`,
    {
      pitotTraverseId: id,
    }
  );
};

const pitotMatrixTitleService = {
  getByPitotId,
  deletePitotTitle,
  postMatrixX,
  postMatrixY,
};

export default pitotMatrixTitleService;
