import { Stack } from "@mui/material";
import Popup from "src/components/Popup";
import { FormCheckBox } from "src/components/formControls";
import AutorenewIcon from "@mui/icons-material/Autorenew";

interface IFanWarningProps {
  openPopup: boolean;
  setOpenPopup: (val: boolean) => void;
}

export const FanWarning = (props: IFanWarningProps) => {
  const handleClose = () => {
    props.setOpenPopup(false);
  };
  const handleCloseFanWarningDialog = () => {
    props.setOpenPopup(false);
  };

  return (
    <>
      {/** Fans Alert */}
      <Popup
        openPopup={props.openPopup}
        onClose={handleClose}
        setOpenPopup={props.setOpenPopup}
        disableClickOutside
        title="Actual Fan CFM"
        size="sm"
        hideClose
        disableEscKeyDown
      >
        <>
          <p style={{ textAlign: "justify" }}>
            <strong style={{ color: "red" }}>Warning</strong>:{" "}
            <b>
              You must enter Actual Fan CFM when using any of these 4 choices
              highlighted with (**) in order for the software to properly
              calculate Total Pressure (TP), Fan Total Pressure (FTP) and Fan
              Static Pressure (FSP)
              <br />
              <br />
              It is best to enter the CFM airflow first to allow immediate
              calculation. If the CFM is entered after any pressure data is
              entered, the refresh button
              <AutorenewIcon />
              must be toggled.
              <br />
              <br />
              Also, Inlet/Fan discharge dimensions need to be entered along with
              CFM
            </b>
          </p>
        </>
        <Stack direction="column" spacing={5} sx={{ marginRight: 5 }}>
          <FormCheckBox
            name="cfmWarningUnderstood"
            label="I understand and would like to move forward"
            labelPlacement="start"
            onClick={handleCloseFanWarningDialog}
          />
        </Stack>
      </Popup>
    </>
  );
};
