import React from "react";
import { TableHead, TableRow, TableCell } from "@mui/material";
import { ColumnType } from "src/types/enhancedTable";
import { currencyformatter } from "src/utils/format";

type EnhancedTableTotalProps = {
  columns: ColumnType[];
  tableCellCheckBox?: boolean;
  data: any;
};

const EnhancedTableTotal: React.FC<EnhancedTableTotalProps> = (props) => {
  const { columns, tableCellCheckBox, data } = props;

  return (
    <TableHead>
      <TableRow>
        <>
          {tableCellCheckBox ? <TableCell></TableCell> : null}
          {columns.map((headCell, index) => (
            <TableCell
              key={headCell.id}
              sx={[
                headCell.width !== null && { width: headCell.width },
                headCell.minWidth !== null && { minWidth: headCell.minWidth },
                headCell.totalLabelAlign !== null && {
                  textAlign: headCell.totalLabelAlign,
                },
              ]}
            >
              {headCell.prefixTotal}
              {headCell.total &&
                currencyformatter.format(data[headCell.total] ?? 0)}
              {headCell.totalLabel && headCell.totalLabel}
            </TableCell>
          ))}
        </>
      </TableRow>
    </TableHead>
  );
};

export default EnhancedTableTotal;
