import styled from "@emotion/styled";
import { Box, spacing } from "@mui/system";
import { Grid, Typography, Card as MuiCard, CardContent } from "@mui/material";
import Popup from "src/components/Popup";
import { IReportCategoryType, IReportType } from "src/ts/interfaces";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faShield,
  faBolt,
  faCertificate,
  faAdjust,
  faReorder,
  faTable,
  faAtom,
  faArchive,
  faBookmark,
  faGears,
  faShekel,
  faQrcode,
} from "@fortawesome/free-solid-svg-icons";

import { faEmpire, faYelp, faTrello } from "@fortawesome/free-brands-svg-icons";
import { ProjectDTO } from "src/ts/interfaces/project/projectDto";

const style = {
  bgcolor: "background.default",
  paddingRight: "10px",
};
const message = `You are currently logged in to AMP software with Testing & Balancing Certification. To have access to this project's studies please select the role "Energy Auditor / Infectious Building Control" when login to the software.`;

interface Props {
  studyCategories: IReportCategoryType[];
  studyTypes: IReportType[];
  show: boolean;
  setShow: (val: boolean) => void;
  onSelectStudy: (studyTypes: IReportType) => void;
  project: ProjectDTO;
}
const Card = styled(MuiCard)(spacing);
const SelectStudyPopup = ({
  studyCategories,
  studyTypes,
  show,
  setShow,
  onSelectStudy,
  project,
}: Props) => {
  const icons: Record<string, any> = {
    "icon-shield": <FontAwesomeIcon icon={faShield} size="2x" color="orange" />,
    "icon-bolt": <FontAwesomeIcon icon={faBolt} size="2x" />,
    "icon-certificate": <FontAwesomeIcon icon={faCertificate} size="2x" />,
    "icon-adjust": <FontAwesomeIcon icon={faAdjust} size="2x" />,
    "icon-empire": <FontAwesomeIcon icon={faEmpire} size="2x" />,
    "icon-ge": <FontAwesomeIcon icon={faAtom} size="2x" />,
    "icon-yelp": <FontAwesomeIcon icon={faYelp} size="2x" />,
    "icon-reorder": <FontAwesomeIcon icon={faReorder} size="2x" />,
    "icon-table": <FontAwesomeIcon icon={faTable} size="2x" />,
    "icon-archive": <FontAwesomeIcon icon={faArchive} size="2x" />,
    "icon-bookmark": <FontAwesomeIcon icon={faBookmark} size="2x" />,
    "icon-trello": <FontAwesomeIcon icon={faTrello} size="2x" />,
    "icon-gears": <FontAwesomeIcon icon={faGears} size="2x" />,
    "icon-shekel": <FontAwesomeIcon icon={faShekel} size="2x" />,
    "icon-qrcode": <FontAwesomeIcon icon={faQrcode} size="2x" />,
  };

  const handleSelectStudy = (studyTypes: IReportType) => {
    onSelectStudy(studyTypes);
  };

  return (
    <Popup
      title="Select the type of report"
      openPopup={show}
      setOpenPopup={setShow}
      onClose={() => setShow(false)}
      size="sm"
    >
      <>
        <Grid container sx={style} spacing={2}>
          {studyTypes &&
            studyCategories.map((category) => {
              return (
                <Grid item xs={12} key={category.id}>
                  <Card mb={1}>
                    <CardContent>
                      <Typography variant="h6">{category.name}</Typography>
                    </CardContent>
                  </Card>
                  <Grid
                    container
                    spacing={2}
                    sx={{
                      paddingTop: "5px",
                    }}
                  >
                    {studyTypes
                      .filter(
                        (studyType) =>
                          studyType.reportCategoryTypeId === category.id
                      )
                      .map((studyTypes) => (
                        <Grid item xs={6} key={studyTypes.id}>
                          <Card mb={1}>
                            <CardContent
                              onClick={() => {
                                studyTypes.color !== "bg-grayDark" &&
                                  handleSelectStudy(studyTypes);
                              }}
                              sx={
                                studyTypes.color === "bg-grayDark"
                                  ? {
                                      backgroundColor: "text.disabled",

                                      "&:hover": {
                                        cursor: "not-allowed",
                                      },
                                    }
                                  : {
                                      "&:hover": {
                                        backgroundColor: "primary.main",
                                        color: "white",
                                      },
                                    }
                              }
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <Box sx={{ paddingRight: "5px" }}>
                                  {/* <SecurityIcon fontSize="large" /> */}
                                  {icons[studyTypes.icon]}
                                  {/* {studyTypes.color} */}
                                </Box>
                                <Box>
                                  <Typography>
                                    <b>{studyTypes.reportLabel}</b>
                                  </Typography>
                                  <Typography>{studyTypes.name}</Typography>
                                </Box>
                              </Box>
                            </CardContent>
                          </Card>
                        </Grid>
                      ))}
                  </Grid>
                </Grid>
              );
            })}
          {studyCategories?.length === 3 && project.isInfectiousControlProject && (
            <Grid item xs={12}>
              <Card mb={1}>
                <CardContent>
                  <Typography>{message}</Typography>
                </CardContent>
              </Card>
            </Grid>
          )}
        </Grid>
      </>
      <></>
    </Popup>
  );
};

export default SelectStudyPopup;
