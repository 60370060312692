import React from "react";
import { usePermissions } from "../../hooks";

interface Props {
  to: string;
  children: React.ReactNode;
  fallback?: React.ReactNode;
}

const Permission = ({ to, children, fallback }: Props) => {
  const { fullAccess } = usePermissions(to);

  if (fullAccess) return <React.Fragment>{children}</React.Fragment>;
  if (fallback) return <React.Fragment>{fallback}</React.Fragment>;

  return null;
};

export default Permission;
