import { Stack, Typography, Grid, Divider as MuiDivider } from "@mui/material";
import {
  AdditionalTestingReqEnum,
  BeltsAndPulleysEnum,
  CostForElectricMotorsEnum,
  TestingEquipmentMinimumDateEnum,
} from "src/ts/enums/project";
import {
  IProjectBidOtherConfiguration,
  ProjectDTO,
} from "src/ts/interfaces/project/projectDto";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import projectBidOtherService from "src/services/project/projectBidOtherService";
import { useAsyncQuery } from "src/hooks";
import { useEffect } from "react";
import { spacing, styled } from "@mui/system";
import {
  EquipmentCalibrationOthers,
  EquipmentCalibrationShow,
  EquipmentCalibrationTitles,
} from "src/constants";
interface OptionTitleProps {
  text: string;
  readonly?: boolean;
}

const OptionTitle = ({ text }: OptionTitleProps) => {
  return (
    <Typography variant="h5" pb="5px" pt="10px">
      {text}
    </Typography>
  );
};

interface OptionItemProps {
  text?: string;
  description: string;
}

const OptionItem = ({ text, description }: OptionItemProps) => {
  return (
    <Stack
      pt={2}
      pb={2}
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
    >
      <CheckCircleOutlineIcon color="success" />
      {text !== "" && (
        <Typography
          pr="4px"
          pl="4px"
          style={{ fontWeight: "bold" }}
          textAlign={"center"}
        >
          {text}
        </Typography>
      )}
      {description && (
        <Typography style={{ display: "inline-block" }}>
          {description}
        </Typography>
      )}
    </Stack>
  );
};

interface Props {
  project: ProjectDTO;
  readOnly?: boolean;
}

const Divider = styled(MuiDivider)(spacing);

const EquipmentCalibration = ({ project, readOnly }: Props) => {
  const showEquipmentCalibration =
    project?.projectBomaTypeId !== null || project?.isInfectiousControlProject;

  const { execute, data: otherBids } = useAsyncQuery<
    IProjectBidOtherConfiguration[]
  >(projectBidOtherService.get);

  useEffect(() => {
    const getData = async () => {
      await execute(project.id);
    };

    if (project.id > 0) getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project]);

  const ProjectStudies = () => {
    return (
      <>
        {showEquipmentCalibration && (
          <>
            <OptionTitle text={EquipmentCalibrationTitles.Equipment} />
            {project.minimumDate ===
              TestingEquipmentMinimumDateEnum.OneYearRequired && (
              <OptionItem
                text={EquipmentCalibrationShow.OneYear.Text}
                description={EquipmentCalibrationShow.OneYear.Description}
              />
            )}
            {project.minimumDate ===
              TestingEquipmentMinimumDateEnum.SixMonthRequired && (
              <OptionItem
                text={EquipmentCalibrationShow.SixMonths.Text}
                description={EquipmentCalibrationShow.SixMonths.Description}
              />
            )}
          </>
        )}

        {project.projectBomaTypeId !== null && (
          <>
            <OptionTitle text={EquipmentCalibrationTitles.HVACBelt} />
            {project.beltsAndPulleys === BeltsAndPulleysEnum.HVACInstalling && (
              <OptionItem
                description={EquipmentCalibrationOthers.HVACMechanical}
              />
            )}
            {project.beltsAndPulleys === BeltsAndPulleysEnum.AMPEnergy && (
              <OptionItem description={EquipmentCalibrationOthers.AMPEnergy} />
            )}
            {project.beltsAndPulleys === BeltsAndPulleysEnum.NotSelected && (
              <OptionItem
                description={EquipmentCalibrationOthers.AbovePurposely}
              />
            )}
          </>
        )}
        {project.projectBomaTypeId !== null && (
          <>
            <OptionTitle text={EquipmentCalibrationTitles.HVACElectric} />
            {project.costForElectricMotors ===
              CostForElectricMotorsEnum.HVACInstalling && (
              <OptionItem
                description={EquipmentCalibrationOthers.HVACElectric}
              />
            )}
            {project.costForElectricMotors ===
              CostForElectricMotorsEnum.AMPEnergy && (
              <OptionItem description={EquipmentCalibrationOthers.AMPEnergy} />
            )}
            {project.costForElectricMotors ===
              CostForElectricMotorsEnum.NotSelected && (
              <OptionItem
                description={EquipmentCalibrationOthers.AbovePurposely}
              />
            )}
          </>
        )}
        {(project.projectBomaTypeId != null || readOnly) &&
          !project.isInfectiousControlProject && (
            <>
              {project.airTestingBalancing && (
                <OptionItem
                  description={EquipmentCalibrationOthers.AirTesting}
                />
              )}
              {project.ductLeakageTesting && (
                <OptionItem
                  description={EquipmentCalibrationOthers.DuctLeakage}
                />
              )}
              {project.hydronicWaterBalancing && (
                <OptionItem
                  description={EquipmentCalibrationOthers.HydronicWater}
                />
              )}
              {project.hydronicPipeleakage && (
                <OptionItem
                  description={EquipmentCalibrationOthers.HydromocPipe}
                />
              )}
              {project.hvacEnergyPerformance && (
                <OptionItem
                  description={EquipmentCalibrationOthers.HvacEnergy}
                />
              )}
              {project.commissioning && (
                <OptionItem
                  description={EquipmentCalibrationOthers.Commissioning}
                />
              )}
              {project.soundTestingLocations && (
                <OptionItem
                  description={EquipmentCalibrationOthers.SoundTesting}
                />
              )}
              {project.vibrationTestingForMotors && (
                <OptionItem
                  description={EquipmentCalibrationOthers.VibrationTesting}
                />
              )}
              {project.ufadPlenumLeakage && (
                <OptionItem
                  description={EquipmentCalibrationOthers.UFADPLenum}
                />
              )}
              {project.otherTesting && project.otherTextTesting && (
                <OptionItem description={project.otherTextTesting} />
              )}
              {project.otherTesting &&
                otherBids &&
                otherBids.map((item) => (
                  <OptionItem description={item.description} />
                ))}
              {project.additionalTestingReq ===
                AdditionalTestingReqEnum.NotSelected && (
                <OptionItem
                  description={EquipmentCalibrationOthers.AbovePurposely}
                />
              )}
            </>
          )}
      </>
    );
  };

  const ProjectOutForBid = () => {
    return (
      <>
        {showEquipmentCalibration && (
          <>
            <Grid container spacing={5} alignItems={"center"}>
              <Grid item xs={4}>
                <Stack textAlign={"right"}>
                  <Typography>
                    <b>{EquipmentCalibrationTitles.Equipment}</b>
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={8}>
                <Stack mb={2}>
                  {project.minimumDate ===
                    TestingEquipmentMinimumDateEnum.OneYearRequired && (
                    <OptionItem
                      text={EquipmentCalibrationShow.OneYear.Text}
                      description={EquipmentCalibrationShow.OneYear.Description}
                    />
                  )}
                  {project.minimumDate ===
                    TestingEquipmentMinimumDateEnum.SixMonthRequired && (
                    <OptionItem
                      text={EquipmentCalibrationShow.SixMonths.Text}
                      description={
                        EquipmentCalibrationShow.SixMonths.Description
                      }
                    />
                  )}
                </Stack>
              </Grid>
            </Grid>
          </>
        )}

        {project.projectBomaTypeId !== null && (
          <>
            <Grid container spacing={5} alignItems={"center"}>
              <Grid item xs={4}>
                <Stack textAlign={"right"}>
                  <Typography>
                    <b>To meet design +- tolerance fluid flows</b>
                  </Typography>
                  <Typography>
                    <b>through replacing any HVAC Belts and</b>
                  </Typography>
                  <Typography>
                    <b>Pulleys that may be required</b>
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={8}>
                <Stack mb={2}>
                  {project.beltsAndPulleys ===
                    BeltsAndPulleysEnum.HVACInstalling && (
                    <OptionItem
                      description={EquipmentCalibrationOthers.HVACMechanical}
                    />
                  )}
                  {project.beltsAndPulleys ===
                    BeltsAndPulleysEnum.AMPEnergy && (
                    <OptionItem
                      description={EquipmentCalibrationOthers.AMPEnergy}
                    />
                  )}
                  {project.beltsAndPulleys ===
                    BeltsAndPulleysEnum.NotSelected && (
                    <OptionItem
                      description={EquipmentCalibrationOthers.NotSelected}
                    />
                  )}
                </Stack>
              </Grid>
            </Grid>
          </>
        )}
        {project.projectBomaTypeId !== null && (
          <>
            <Grid container spacing={5} alignItems={"center"}>
              <Grid item xs={4}>
                <Stack direction="column" textAlign={"right"} mt={2}>
                  <Typography>
                    <b>To meet design +- tolerance fluid flows</b>
                  </Typography>
                  <Typography>
                    <b>through replacing any HVAC Electric Motors </b>
                  </Typography>
                  <Typography>
                    <b>that may be required</b>
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={8}>
                <Stack>
                  {project.costForElectricMotors ===
                    CostForElectricMotorsEnum.HVACInstalling && (
                    <OptionItem
                      description={EquipmentCalibrationOthers.HVACElectric}
                    />
                  )}
                  {project.costForElectricMotors ===
                    CostForElectricMotorsEnum.AMPEnergy && (
                    <OptionItem
                      description={EquipmentCalibrationOthers.AMPEnergy}
                    />
                  )}
                  {project.costForElectricMotors ===
                    CostForElectricMotorsEnum.NotSelected && (
                    <OptionItem
                      description={EquipmentCalibrationOthers.NotSelected}
                    />
                  )}
                </Stack>
              </Grid>
            </Grid>
          </>
        )}
        {project.projectBomaTypeId !== null && (
          <>
            <Grid container spacing={5} alignItems={"center"}>
              <Grid item xs={4}></Grid>
              <Grid item xs={8}>
                <Stack>
                  {(project.projectBomaTypeId != null || readOnly) &&
                    !project.isInfectiousControlProject && (
                      <>
                        <Divider my={4} />
                        <Stack direction={"column"} spacing={1}>
                          {project.airTestingBalancing && (
                            <OptionItem
                              description={
                                EquipmentCalibrationOthers.AirTesting
                              }
                            />
                          )}
                          {project.ductLeakageTesting && (
                            <OptionItem
                              description={
                                EquipmentCalibrationOthers.DuctLeakage
                              }
                            />
                          )}
                          {project.hydronicWaterBalancing && (
                            <OptionItem
                              description={
                                EquipmentCalibrationOthers.HydronicWater
                              }
                            />
                          )}
                          {project.hydronicPipeleakage && (
                            <OptionItem
                              description={
                                EquipmentCalibrationOthers.HydromocPipe
                              }
                            />
                          )}
                          {project.hvacEnergyPerformance && (
                            <OptionItem
                              description={
                                EquipmentCalibrationOthers.HvacEnergy
                              }
                            />
                          )}
                          {project.commissioning && (
                            <OptionItem
                              description={
                                EquipmentCalibrationOthers.Commissioning
                              }
                            />
                          )}
                          {project.soundTestingLocations && (
                            <OptionItem
                              description={
                                EquipmentCalibrationOthers.SoundTesting
                              }
                            />
                          )}
                          {project.vibrationTestingForMotors && (
                            <OptionItem
                              description={
                                EquipmentCalibrationOthers.VibrationTesting
                              }
                            />
                          )}
                          {project.ufadPlenumLeakage && (
                            <OptionItem
                              description={
                                EquipmentCalibrationOthers.UFADPLenum
                              }
                            />
                          )}
                          {project.otherTesting && project.otherTextTesting && (
                            <OptionItem
                              description={project.otherTextTesting}
                            />
                          )}
                          {project.otherTesting &&
                            otherBids &&
                            otherBids.map((item) => (
                              <OptionItem description={item.description} />
                            ))}
                        </Stack>
                      </>
                    )}
                </Stack>
              </Grid>
            </Grid>
          </>
        )}
      </>
    );
  };

  return (
    <>
      {!readOnly && <ProjectStudies />}
      {readOnly && <ProjectOutForBid />}
    </>
  );
};

export default EquipmentCalibration;
