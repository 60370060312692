import {
  IChillerTowerCondenser,
  IChillerTowerFanMotor,
  ITower,
} from "src/ts/interfaces/study/tower";
import axios from "src/utils/axios";

const getBydId = (id: number) => {
  return axios.get<ITower>(`Tower/${id}`);
};

const update = (id: number, towerStudy: ITower) => {
  return axios.put<ITower>(`Tower/${id}`, towerStudy);
};

const towerCondensers = (id: number) => {
  return axios.get<IChillerTowerCondenser[]>(
    `Tower/towerCondensers?reportId=${id}`
  );
};

const updateTowerCondenser = (
  id: number,
  towerCondenser: IChillerTowerCondenser
) => {
  return axios.put<IChillerTowerCondenser>(
    `Tower/towerCondenser/${id}`,
    towerCondenser
  );
};

const getFanMotorsByTowerCondenserId = (id: number) => {
  return axios.get<IChillerTowerFanMotor[]>(`Tower/fanMotors?reportId=${id}`);
};

const updateFanMotor = (id: number, fanMotor: IChillerTowerFanMotor) => {
  return axios.put(`Tower/fanMotor/${id}`, fanMotor);
};

const addFanMotor = (fanMotor: IChillerTowerFanMotor) => {
  return axios.post<IChillerTowerFanMotor>(`Tower/fanMotor`, fanMotor);
};

const calculateTowerFormula = (
  id: number,
  towerCondenser: IChillerTowerCondenser
) => {
  return axios.put<IChillerTowerCondenser>(
    `Tower/towerFormula/${id}`,
    towerCondenser
  );
};

const getFanMotorsById = (id: number) => {
  return axios.get<IChillerTowerFanMotor>(`Tower/fanMotor?id=${id}`);
};

const getTowerCondenserById = (id: number) => {
  return axios.get<IChillerTowerCondenser>(`Tower/towerCondenser/${id}`);
};

const removeFanMotor = (id: number) => {
  return axios.delete(`Tower/fanMotor/${id}`);
};

const towerService = {
  getBydId,
  update,
  towerCondensers,
  updateTowerCondenser,
  getFanMotorsByTowerCondenserId,
  updateFanMotor,
  calculateTowerFormula,
  getFanMotorsById,
  getTowerCondenserById,
  removeFanMotor,
  addFanMotor,
};

export default towerService;
