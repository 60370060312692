import axios from "src/utils/axios";
import { ICfmDTO } from "src/ts/interfaces";

const getCfm = (id: number, reportTypeCode: string) => {
  return axios.get<ICfmDTO>(`cfm?headSheetId=${id}&code=${reportTypeCode}`);
};

const cfmService = {
  getCfm,
};

export default cfmService;
