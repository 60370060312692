import axios from "../utils/axios";
import {
  IAuditTechnDataPage,
  IAuditTechnFilter,
  IPostTechnician,
  ITechnician,
  ITechniciansTemp,
  IAuditTechAssignments,
  IAuditTechnAssignmentFilter,
  IPostDocumentIssue,
} from "src/ts/interfaces/technician";
import { getFilter } from "src/utils/utils";

const getAll = async (
  filter: IAuditTechnFilter,
  getQuery?: (filters: Record<string, any>) => string
) => {
  const queryString = getQuery ? getQuery(filter) : getFilter(filter);

  return await axios.get<IAuditTechnDataPage>(
    `Technicians/GetAll?${queryString}`
  );
};

const getTechnicians = async (technicianId: string) => {
  return axios.get<ITechnician>(`Technicians/${technicianId}`);
};
const updatedTechnicians = async (technicianId: string, data: ITechnician) => {
  return axios.put<ITechnician>(`Technicians/${technicianId}`, data);
};

const getCertificationsByUser = async (userName: string) => {
  return axios.get<ITechniciansTemp[]>(
    `Technicians/Certifications?userName=${userName}`
  );
};

const getAllAssignments = async (
  filter: IAuditTechnAssignmentFilter,
  getQuery?: (filters: Record<string, any>) => string
) => {
  const queryString = getQuery ? getQuery(filter) : getFilter(filter);

  return await axios.get<IAuditTechAssignments>(
    `Technicians/GetAllAssignements?${queryString}`
  );
};

const postNewTechinician = async (
  sentTo: boolean,
  systemCostCatalogId: number,
  cuponCode: string,
  data: IPostTechnician
) => {
  return axios.post(
    `Technicians/purchase?sendTo=${sentTo}&systemCostCatalogId=${systemCostCatalogId}&cuponCode=${cuponCode}&newRoute=true`,
    data
  );
};
const updateTrainingDate = async (
  technicianId: number,
  startDate: Date,
  quizId: number
) => {
  return axios.post(
    `Technicians/UpdateTrainingDate?id=${technicianId}&trainingDate=${startDate}&quizId=${quizId}`
  );
};

const getCertificationTechnician = async (technicianId: number) => {
  return axios.get(
    `Technicians/downloadCertification?technicianId=${technicianId}`
  );
};

const getAtLeastOneCertification = async (technicianId: number) => {
  return axios.get(
    `Technicians/atLeastOneCertification?userId=${technicianId}`
  );
};

const postGenerateCertification = async (
  technicianId: number,
  comments: string
) => {
  return axios.post(
    `Technicians/certification?technicianId=${technicianId}&comments=${comments}`
  );
};

const postDocumentIssue = async (data: IPostDocumentIssue) => {
  return axios.post(`Technicians/documentNotification`, data);
};

const deleteDocument = async (technicianId: number) => {
  return axios.put(`Technicians/DeleteDocument?technicianId=${technicianId}`);
};

const technicianService = {
  getCertificationsByUser,
  getAll,
  getTechnicians,
  updatedTechnicians,
  postNewTechinician,
  updateTrainingDate,
  getCertificationTechnician,
  getAllAssignments,
  getAtLeastOneCertification,
  postGenerateCertification,
  postDocumentIssue,
  deleteDocument,
};

export default technicianService;
