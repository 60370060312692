import "react-app-polyfill/stable";

import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { SnackbarProvider } from "notistack";
import reportWebVitals from "./utils/reportWebVitals";
import App from "./App";
import { AuthProvider } from "./contexts/JWTContext";
// import "./mocks";

import { ThemeProvider } from "./contexts/ThemeContext";

const container = document.getElementById("root");
// @ts-ignore
const root = ReactDOM.createRoot(container); // createRoot(container!) if you use TypeScript

root.render(
  <BrowserRouter>
    <ThemeProvider>
      <SnackbarProvider
        maxSnack={6}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        <AuthProvider>
          <App />
        </AuthProvider>
      </SnackbarProvider>
    </ThemeProvider>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
