import {
  FormButton,
  FormNumeric2,
  FormSelect,
  FormText,
} from "src/components/formControls";
import { AddIcon } from "src/components/icons";
import { GridActionButton } from "src/components/gridControls";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import {
  IFormulaSystemService,
  IHeadSheetActualSpacePressurizationDTO,
  ISystemOfMeasurement,
} from "src/ts/interfaces";
import Popup from "src/components/Popup";
import { Stack, useTheme } from "@mui/system";
import { Dispatch, SetStateAction, useState } from "react";
import { useAsyncQuery } from "src/hooks";
import headSheetActualSpacePressurizationService from "src/services/study/headSheetActualSpacePressurizationService";

interface ISpacePressurizationProps {
  headSheetActualId: number;
  disableStudy: boolean;
  isLoading: boolean;
  isLoadingSpacePressurization: boolean;
  systemOfMeasurement: ISystemOfMeasurement;
  spacePressurizationValues:
    | IHeadSheetActualSpacePressurizationDTO[]
    | undefined;
  setSpacePressurizationValues:
    | Dispatch<SetStateAction<IHeadSheetActualSpacePressurizationDTO[]>>
    | Dispatch<
        SetStateAction<IHeadSheetActualSpacePressurizationDTO[] | undefined>
      >;
  readOnly?: boolean;
}

const spacePressurizationToOptions = [
  { key: "Outside Ambient", value: "Outside Ambient" },
  { key: "Adjacent Space", value: "Adjacent Space" },
];

export const SpacePressurization = (props: ISpacePressurizationProps) => {
  const [currentSpacePressurizationId, setCurrentSpacePressurizationId] =
    useState(0);
  const [currentSpacePressurizationIdx, setCurrentSpacePressurizationIdx] =
    useState(0);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const isDarkTheme = useTheme().palette.mode === "dark";

  const {
    execute: addSpacePressurization,
    isLoading: isAddingSpacePressurization,
  } = useAsyncQuery<IHeadSheetActualSpacePressurizationDTO[]>(
    headSheetActualSpacePressurizationService.save,
    {
      onSuccess: (dataResult) => {
        const getData = async () => {
          props.setSpacePressurizationValues([
            ...(props.spacePressurizationValues as IHeadSheetActualSpacePressurizationDTO[]),
            dataResult,
          ]);
        };
        getData();
      },
    }
  );

  const handleSpacePressurizationValuesChange = (
    e: any,
    row: IHeadSheetActualSpacePressurizationDTO,
    index: number
  ) => {
    let newSpacePressurization = { ...row, [e.target.name]: e.target.value };
    let crud = [
      ...(props.spacePressurizationValues as IHeadSheetActualSpacePressurizationDTO[]),
    ];
    crud.splice(index, 1, newSpacePressurization);
    props.setSpacePressurizationValues(crud);
  };

  const removeSpacePressurizationHandler = async (
    id: number,
    index: number
  ) => {
    await headSheetActualSpacePressurizationService.remove(id);
    let newSpacePressurization = props.spacePressurizationValues;
    newSpacePressurization?.splice(index, 1);
    props.setSpacePressurizationValues(
      newSpacePressurization as IHeadSheetActualSpacePressurizationDTO[]
    );
    setOpenDeleteDialog(false);
  };

  const handleAddSpacePressurization = async () => {
    const spacePressurization = {
      id: 0,
      spacePressurization: "",
      spacePressurizationIsMinus: false,
      spacePressurizationTo: null,
      comment: null,
      headSheetActualId: props.headSheetActualId,
    };
    addSpacePressurization(spacePressurization);
  };

  const handleCloseDeleteDialog = () => setOpenDeleteDialog(false);

  const handleSpacePressurizationSignChange = (
    sign: "-" | "+",
    row: IHeadSheetActualSpacePressurizationDTO,
    index: number
  ) => {
    if (!props.disableStudy)
      handleSpacePressurizationValuesChange(
        {
          target: {
            name: "spacePressurizationIsMinus",
            value: sign === "-",
          },
        },
        row,
        index
      );
  };
  return (
    <>
      <Popup
        openPopup={openDeleteDialog}
        setOpenPopup={setOpenDeleteDialog}
        onClose={handleCloseDeleteDialog}
        title="Confirm delete Component"
      >
        <p>
          Are you certain you want to delete the selected space pressurization
          item?
        </p>
        <Stack direction="row" spacing={3}>
          <FormButton
            text="Yes"
            variant="contained"
            color="primary"
            onClick={() =>
              removeSpacePressurizationHandler(
                currentSpacePressurizationId,
                currentSpacePressurizationIdx
              )
            }
            autoFocus
          />
          <FormButton
            text="No"
            variant="outlined"
            color="secondary"
            onClick={handleCloseDeleteDialog}
          />
        </Stack>
      </Popup>
      {!props.readOnly && (
        <FormButton
          variant="contained"
          color="success"
          text="Add Space Pressurization"
          endIcon={<AddIcon />}
          onClick={handleAddSpacePressurization}
          size="small"
          disabled={props.disableStudy}
        />
      )}
      <TableContainer sx={{ maxHeight: 480 }} component={Paper}>
        <Table stickyHeader aria-label="static components">
          <TableHead>
            <TableRow>
              <TableCell style={{ textAlign: "center" }}>
                {`Space pressurization (${props.systemOfMeasurement.get(
                  "inwc"
                )})`}
              </TableCell>
              <TableCell style={{ textAlign: "center" }}>
                Space pressurization to
              </TableCell>
              <TableCell style={{ textAlign: "center", minWidth: 600 }}>
                Space pressurization comment
              </TableCell>
              {!props.readOnly && (
                <TableCell style={{ textAlign: "center" }}>Actions</TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {props.spacePressurizationValues &&
              props.spacePressurizationValues.map(
                (
                  row: IHeadSheetActualSpacePressurizationDTO,
                  index: number
                ) => (
                  <>
                    <TableRow
                      key={`space-pressurization-${row.id}-ge-${index}`}
                      style={{
                        backgroundColor:
                          index % 2 > 0
                            ? isDarkTheme
                              ? "#243549"
                              : "#f3f3f3"
                            : "inherit",
                      }}
                    >
                      <TableCell
                        style={{
                          textAlign: "center",
                        }}
                      >
                        {!props.disableStudy && (
                          <>
                            <AddCircleOutlineIcon
                              style={{
                                marginTop: 7,
                                cursor: "pointer",
                              }}
                              onClick={(e: any) =>
                                handleSpacePressurizationSignChange(
                                  "+",
                                  row,
                                  index
                                )
                              }
                            />
                            <RemoveCircleOutlineIcon
                              style={{
                                marginTop: 7,
                                cursor: "pointer",
                              }}
                              onClick={(e: any) =>
                                handleSpacePressurizationSignChange(
                                  "-",
                                  row,
                                  index
                                )
                              }
                            />
                          </>
                        )}
                        <FormNumeric2
                          label=""
                          placeholder="0.00"
                          allowNegative={false}
                          prefix={row.spacePressurizationIsMinus ? "-" : "+"}
                          fixedDecimalScale
                          decimalScale={2}
                          fullWidth={false}
                          style={{ width: 120 }}
                          name="spacePressurization"
                          value={row.spacePressurization}
                          onChange={(e: any) =>
                            handleSpacePressurizationValuesChange(e, row, index)
                          }
                          showSkeleton={
                            props.isLoading ||
                            props.isLoadingSpacePressurization ||
                            isAddingSpacePressurization
                          }
                          disabled={props.disableStudy}
                          mode={props.readOnly ? "read" : "read&Write"}
                        />
                      </TableCell>
                      <TableCell
                        style={{
                          textAlign: "center",
                        }}
                      >
                        <FormSelect
                          label=""
                          name="spacePressurizationTo"
                          value={row.spacePressurizationTo}
                          options={spacePressurizationToOptions}
                          onChange={(e: any) =>
                            handleSpacePressurizationValuesChange(e, row, index)
                          }
                          showSkeleton={
                            props.isLoading ||
                            props.isLoadingSpacePressurization ||
                            isAddingSpacePressurization
                          }
                          disabled={props.disableStudy}
                          mode={props.readOnly ? "read" : "read&Write"}
                        />
                      </TableCell>
                      <TableCell
                        style={{
                          textAlign: "center",
                        }}
                      >
                        <FormText
                          label=""
                          name="comment"
                          placeholder="Type your comments here..."
                          value={row.comment || null}
                          onChange={(e: any) =>
                            handleSpacePressurizationValuesChange(e, row, index)
                          }
                          showSkeleton={
                            props.isLoading ||
                            props.isLoadingSpacePressurization ||
                            isAddingSpacePressurization
                          }
                          disabled={props.disableStudy}
                          mode={props.readOnly ? "read" : "read&Write"}
                        />
                      </TableCell>
                      {!props.readOnly && (
                        <TableCell
                          style={{
                            textAlign: "center",
                          }}
                        >
                          <GridActionButton
                            type="delete"
                            disabled={props.disableStudy}
                            onClick={() => {
                              setCurrentSpacePressurizationId(row.id);
                              setCurrentSpacePressurizationIdx(index);
                              setOpenDeleteDialog(true);
                            }}
                            tooltip="Delete row"
                          />
                        </TableCell>
                      )}
                    </TableRow>
                  </>
                )
              )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
