import Popup from "src/components/Popup";
import {
  FormButton,
  FormSaveButton,
  FormTextArea,
} from "src/components/formControls";
import { Stack, Grid } from "@mui/material";
import { useState } from "react";
import { SettingsBackupRestore } from "@mui/icons-material";
import { ROLES, projectStatus } from "src/constants";
import {
  useAsyncMutation,
  useAsyncQuery,
  useAuth,
  usePermissions,
} from "src/hooks";
import projectHistoryStatusCommentsService from "src/services/project/projectHistoryStatusCommentsService";
import recycleBinService from "src/services/recycleBinService";
import { Permission } from "src/ts/enums";

interface props {
  projectId: number;
  projectStatusId: number;
  refreshProject: () => void;
}

export default function ReopenProject({
  projectId,
  projectStatusId,
  refreshProject,
}: props) {
  const { user } = useAuth();
  const [modal, setModal] = useState(false);
  const [comments, setComments] = useState("");
  const { fullAccess: projectAccess } = usePermissions(Permission.Project);
  const { execute } = useAsyncQuery(
    projectHistoryStatusCommentsService.postProjectHistoryStatusComments
  );

  const { execute: executeReopen, isSubmitting } = useAsyncMutation(
    recycleBinService.recoverProject,
    {
      successfulMessage: "The project was successfully reopened",
      onSuccess: () => {
        refreshProject();
        setModal(false);
      },
    }
  );

  const handleReopen = async () => {
    await execute({
      comments: comments,
      projectId: projectId,
      statusId: 1,
    });
    await executeReopen(projectId);
  };

  return (
    <>
      {projectStatusId === projectStatus.Rejected.id &&
        (user?.role === ROLES.Architect ||
          user?.role === ROLES.ArchitectAux ||
          user?.role === ROLES.SysAdmin) &&
        projectAccess && (
          <FormButton
            text={"Reopen"}
            onClick={() => {
              setModal(true);
            }}
            startIcon={<SettingsBackupRestore />}
            size="small"
            variant="outlined"
          ></FormButton>
        )}

      <Popup
        title={`Reopen Comments`}
        openPopup={modal}
        setOpenPopup={setModal}
        onClose={() => {
          setModal(false);
        }}
        size="sm"
      >
        <>
          <Grid container spacing={4}>
            <Grid item xs={12} textAlign={"center"}>
              <FormTextArea
                name={"comments"}
                label={"Comments"}
                value={comments}
                onChange={(e: any) => {
                  setComments(e.target.value);
                }}
              ></FormTextArea>
            </Grid>
          </Grid>
        </>
        <Stack direction="row" spacing={3}>
          <FormButton
            size="small"
            text="Close"
            color="primary"
            variant="outlined"
            onClick={() => {
              setModal(false);
            }}
            isSubmitting={isSubmitting}
          />
          <FormSaveButton
            onClick={handleReopen}
            isSubmitting={isSubmitting}
          ></FormSaveButton>
        </Stack>
      </Popup>
    </>
  );
}
