import {
  CardContent,
  Grid,
  Stack,
  Divider as MuiDivider,
  Card as MuiCard,
  Typography,
} from "@mui/material";

import { spacing } from "@mui/system";
import styled from "@emotion/styled";
import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useFormTyped from "src/hooks/useFormTyped";
import { IFormulaSystemService } from "src/ts/interfaces";
import {
  useAsyncMutation,
  useAsyncQuery,
  useAuth,
  useSystemOfMeasurement,
} from "src/hooks";
import { IProjectReport } from "src/ts/interfaces/project/projectDto";
import projectReportService from "src/services/study/projectReportService";
import useFormulas from "src/hooks/useFormulas";
import getProjectService from "src/services/project/getProjectService";
import { IElectricCoil } from "src/ts/interfaces/study/electricCoil";
import electricCoilService from "src/services/study/electricCoilService";
import { steps } from "./const/const";
import ElectricCoilGrid from "./components/ElectricCoilGrid";
import SteadyStateDialog from "../components/SteadyState";
import { FooterButtons } from "../components/FooterButtons";
import projectService from "src/services/project/projectService";
import HeaderStudyPage from "src/components/page/HeaderStudyPage";
import StudyWizard from "../components/StudyWizard";
const initialValues: IElectricCoil = {
  id: 0,
  projectId: 0,
  projectName: "",
  reportTypeId: 0,
  systemField: "",
  roomAreaServed: "",
  description: "",
  totalBTUSystem: false,
  vavSystemTotal: false,
  vavOutletTotal: false,
  fillNext: false,
  deviceCoefficient: false,
  airflowFlowingCoilsId: 0,
  nameOfPerson: "",
  title: "",
  company: "",
  dateWhenTold: "",
  designAirflowDB: false,
  actualAirflowDB: false,
  designAirflow: false,
  actualAirflow: false,
  rh: false,
  wb: false,
  dp: false,
  designAirflowSP: false,
  actualAirflowSP: false,
  designBTUH: false,
  actualBTUH: false,
  designBTUHTotal: false,
  actualBTUHTotal: false,
  systemSteadyThisTest: false,
  systemSteadyWillBeMonitored: false,
  step: 0,
  isComplete: false,
  statusId: 0,
  createdDate: new Date(),
  createdBy: "",
  updatedDate: new Date(),
  updatedBy: "",
  userAuth: "",
  userName: "",
  mandatoryMinimum: false,
  isProjectDisabled: false,
};

const Divider = styled(MuiDivider)(spacing);
const Card = styled(MuiCard)(spacing);

const ElectricCoilStep3 = () => {
  const { user } = useAuth();
  const { systemOfMeasurement } = useSystemOfMeasurement(true);
  const params = useParams<{ id: string }>();
  const id = parseInt(params?.id === undefined ? "0" : params?.id);
  const { formulaSystem } = useFormulas();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [formulas, setFormulas] = useState<IFormulaSystemService>();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [addAllElectric, setAddAllElectric] = useState<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [addAllAirflow, setAddAllAirflow] = useState<boolean>(false);
  const [saveItems, setSaveItems] = useState<boolean>(false);
  const [saveItemsNext, setSaveItemsNext] = useState<boolean>(false);
  const [saveItemsBack, setSaveItemsBack] = useState<boolean>(false);
  const [disableAfterSteadyStep, setDisableAfterSteadyStep] =
    useState<boolean>(false);
  let navigate = useNavigate();
  const { execute: executeUpdate, isSubmitting: isSubmittingUpdate } =
    useAsyncMutation<number>(electricCoilService.update, {
      successfulMessage: "The report was updated successfully",
      onSuccess: async (result) => {
        await setValues({
          ...result,
        });
      },
    });

  const validate: any = (fieldValues = values) => {
    let temp: Record<string, string> = { ...errors };

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors } = useFormTyped<IElectricCoil>(
    initialValues,
    false,
    validate
  );

  const { execute, isLoading } = useAsyncQuery<IElectricCoil>(
    electricCoilService.getById,
    {
      onSuccess: (dataResult) => {
        const getData = async () => {
          let result = await formulaSystem.setSystem(dataResult?.projectId);
          setFormulas(result);
          const measurementSystemResponse =
            await getProjectService.getMeasurementSystem(dataResult?.projectId);
          systemOfMeasurement.setSystem(measurementSystemResponse.data);
        };
        getData();
        setValues(dataResult);
      },
    }
  );

  const { execute: executeReport, data: dataReport } =
    useAsyncQuery<IProjectReport>(projectReportService.report);

  useEffect(() => {
    if (values?.designBTUH && values?.actualBTUH) setAddAllElectric(true);
    else setAddAllElectric(false);

    if (
      values?.designAirflowDB &&
      values?.actualAirflowDB &&
      values?.designAirflowSP &&
      values?.actualAirflowSP &&
      values?.designAirflow &&
      values?.actualAirflow &&
      values?.designBTUHTotal &&
      values?.actualBTUHTotal
    )
      setAddAllAirflow(true);
    else setAddAllAirflow(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  useEffect(() => {
    const getData = async () => {
      await execute(id);
    };

    if (id !== 0) getData();
    setDisableAfterSteadyStep(
      projectService.disableAfterSteadyStep(user?.role)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    const getData = async () => {
      await executeReport(id, values?.reportTypeId === 28 ? "COEA" : "COET");
    };

    if (values && values?.id > 0) getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.id]);

  useEffect(() => {
    const getData = async () => {
      await executeUpdate(values.id, values);
    };
    if (values && values?.id > 0) getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveItems]);

  useEffect(() => {
    const getData = async () => {
      await executeUpdate(values.id, values);
      navigate(`/studies/ElectricCoil/step2/${id}/`);
    };
    if (values && values?.id > 0) getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveItemsBack]);

  useEffect(() => {
    const getData = async () => {
      await executeUpdate(values.id, values);
      navigate(`/studies/ElectricCoil/step4/${id}/`);
    };
    if (values && values?.id > 0) getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveItemsNext]);

  const nextStepHandler = async () => {
    if (!disableStudy) {
      if (!validate()) return;
      setSaveItemsNext(!saveItemsNext);
      handleSave();
    } else navigate(`/studies/ElectricCoil/step4/${id}/`);
  };

  const handleBack = async () => {
    if (!disableStudy) {
      if (!validate()) return;
      setSaveItemsBack(!saveItemsBack);
      handleSave();
    } else navigate(`/studies/ElectricCoil/step2/${id}/`);
  };

  const handleSave = async () => {
    if (!validate()) return;
    setSaveItems(!saveItems);
  };

  const disableStudy = useMemo(() => {
    return (
      values?.isComplete ||
      (dataReport?.isInUse && dataReport?.isInUseById !== user?.userId) ||
      dataReport?.isPartOfProject === false ||
      disableAfterSteadyStep
    );
  }, [user?.userId, values?.isComplete, dataReport, disableAfterSteadyStep]);

  return (
    <>
      <HeaderStudyPage
        headerStudiesPage={{
          code: values?.reportTypeId === 28 ? "COEA" : "COET",
          system: values?.systemField,
          id: id,
        }}
        parentText="Project"
        parentLink="/"
      />
      <Divider my={6} />

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <StudyWizard
            steps={steps}
            activeStep={2}
            projectId={values?.projectId}
            reportId={id}
            reportTypeId={values?.reportTypeId}
          ></StudyWizard>
        </Grid>
        <Grid item xs={12}></Grid>
        <Grid item xs={12}>
          <Card mb={1}>
            <CardContent>
              <Grid container spacing={5}>
                <Grid item xs={12}>
                  <Typography variant="h5">
                    Stacked Coil Configuration
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <ElectricCoilGrid
                    id={values?.id}
                    isLoading={false}
                    mode={"read&Write"}
                    readOnly={false}
                    saveItems={saveItems}
                    systemOfMeasurement={systemOfMeasurement}
                    disableStudy={disableStudy}
                    electricCoil={values}
                    setElectricCoil={setValues}
                  ></ElectricCoilGrid>
                </Grid>
                <Grid item xs={12}>
                  <Stack
                    direction="row"
                    spacing={2}
                    justifyContent="center"
                    alignItems="center"
                  >
                    <FooterButtons
                      activeStep={2}
                      stepsCount={steps.length}
                      projectId={values?.projectId}
                      companyId={user?.companyId as number}
                      isSaving={isLoading || isSubmittingUpdate}
                      reportName="Electric Coil AHU Mixed Air Report"
                      saveHandler={handleSave}
                      nextStepHandler={nextStepHandler}
                      previousStepHandler={handleBack}
                      disabled={disableStudy}
                      isComplete={values?.isComplete ?? true}
                    />
                  </Stack>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      {values && values?.projectId && (
        <SteadyStateDialog
          reportId={values?.id}
          reportTypeId={values?.reportTypeId}
          projectId={values?.projectId}
          reportCompleted={values?.isComplete}
        />
      )}
    </>
  );
};

export default ElectricCoilStep3;
