import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import {
  Grid,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
  Typography,
  Card as MuiCard,
  CardContent,
  Tabs,
  Tab,
} from "@mui/material";
import { spacing } from "@mui/system";
import { Form, useAuth, useForm, useLog } from "src/hooks";
import {
  FormActionButton,
  FormDatePicker,
  FormText,
} from "src/components/formControls";
import {
  FilterType,
  IRecycleBinBuilding,
  IRecycleBinProject,
  IRecycleBinStudy,
} from "src/ts/interfaces/recycleBin";
import moment from "moment";
import { ColumnType } from "src/types/enhancedTable";
import { useTable } from "src/hooks/useTable";
import recycleBinService from "src/services/recycleBinService";
import { getQueryString } from "./util/common";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import BuildIcon from "@mui/icons-material/Build";
import { GridActionButton } from "src/components/gridControls";
import MatchWordConfirmModal from "src/components/modals/MatchWordConfirmModal";
import DialogMessagePopup from "src/components/DialogMessagePopup";
import { Validator } from "src/ts/types/Validator";

const Card = styled(MuiCard)(spacing);
const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const getProjectColumns = (
  setCurrentRowProject: (val: IRecycleBinProject) => void,
  setDeleteProjectModal: (val: boolean) => void,
  setRecoverProjectModal: (val: boolean) => void
): ColumnType[] => {
  return [
    {
      id: "id",
      label: "Id",
      type: "string",
      sort: true,
    },
    {
      id: "isInfectiousControlProject",
      label: "Type",
      type: "custom",
      sort: true,
      callback: (row: IRecycleBinProject) => {
        return (
          <div>
            {row.isInfectiousControlProject ? (
              <VerifiedUserIcon />
            ) : (
              <BuildIcon />
            )}
          </div>
        );
      },
    },
    {
      id: "code",
      label: "Code",
      type: "string",
      sort: true,
    },
    {
      id: "name",
      label: "Name",
      type: "string",
      sort: true,
    },
    {
      id: "buildingName",
      label: "Building Name",
      type: "string",
      sort: true,
    },
    {
      id: "buildingAddress",
      label: "Building Address",
      type: "string",
      sort: true,
    },
    {
      id: "deletedDate",
      label: "Date Deleted",
      type: "utcDate",
      sort: true,
      format: "MM/DD/yyyy h:mma",
    },
    {
      id: "deletedBy",
      label: "Deleted By",
      type: "string",
      sort: true,
    },
    {
      id: "actions",
      label: "Actions",
      type: "custom",
      sort: false,
      width: "150px",
      callback: (row: IRecycleBinProject) => {
        return (
          <>
            <GridActionButton
              type="upload"
              onClick={() => {
                setCurrentRowProject(row);
                setRecoverProjectModal(true);
              }}
              tooltip="Recover"
            />
            <GridActionButton
              type="delete"
              onClick={() => {
                setCurrentRowProject(row);
                setDeleteProjectModal(true);
              }}
              tooltip="Delete Permanently"
            />
          </>
        );
      },
    },
  ];
};

const getStudyColumns = (
  setCurrentRowStudy: (val: IRecycleBinStudy) => void,
  setDeleteStudyModal: (val: boolean) => void,
  setRecoverStudyModal: (val: boolean) => void
): ColumnType[] => {
  return [
    {
      id: "id",
      label: "Id",
      type: "string",
      sort: true,
    },
    {
      id: "code",
      label: "Code",
      type: "string",
      sort: true,
    },
    {
      id: "name",
      label: "Name",
      type: "string",
      sort: true,
    },
    {
      id: "projectName",
      label: "Project Name",
      type: "string",
      sort: true,
    },
    {
      id: "system",
      label: "System",
      type: "string",
      sort: true,
    },
    {
      id: "dateDeleted",
      label: "Date Deleted",
      type: "utcDate",
      sort: true,
      format: "MM/DD/yyyy h:mma",
    },
    {
      id: "deletedBy",
      label: "Deleted By",
      type: "string",
      sort: true,
    },
    {
      id: "actions",
      label: "Actions",
      type: "custom",
      sort: false,
      width: "150px",
      callback: (row: IRecycleBinStudy) => {
        return (
          <>
            <GridActionButton
              type="upload"
              onClick={() => {
                setCurrentRowStudy(row);
                setRecoverStudyModal(true);
              }}
              tooltip="Recover"
            />
            <GridActionButton
              type="delete"
              onClick={() => {
                setCurrentRowStudy(row);
                setDeleteStudyModal(true);
              }}
              tooltip="Delete Permanently"
            />
          </>
        );
      },
    },
  ];
};

const getBuildingColumns = (
  setCurrentRowBuilding: (val: IRecycleBinBuilding) => void,
  setDeleteBuildingModal: (val: boolean) => void,
  setRecoverBuildingModal: (val: boolean) => void
): ColumnType[] => {
  return [
    {
      id: "id",
      label: "Id",
      type: "string",
      sort: true,
    },
    {
      id: "name",
      label: "Name",
      type: "string",
      sort: true,
    },
    {
      id: "address",
      label: "Address",
      type: "string",
      sort: true,
    },
    {
      id: "dateDeleted",
      label: "Date Deleted",
      type: "utcDate",
      sort: true,
      format: "MM/DD/yyyy h:mma",
    },
    {
      id: "deletedBy",
      label: "Deleted By",
      type: "string",
      sort: true,
    },
    {
      id: "actions",
      label: "Actions",
      type: "custom",
      sort: false,
      width: "150px",
      callback: (row: IRecycleBinBuilding) => {
        return (
          <>
            <GridActionButton
              type="upload"
              onClick={() => {
                setCurrentRowBuilding(row);
                setRecoverBuildingModal(true);
              }}
              tooltip="Recover"
            />
            <GridActionButton
              type="delete"
              onClick={() => {
                setCurrentRowBuilding(row);
                setDeleteBuildingModal(true);
              }}
              tooltip="Delete Permanently"
            />
          </>
        );
      },
    },
  ];
};

const initialValues: FilterType = {
  filter: "",
  from: moment().startOf("month").format("MM/DD/YYYY"),
  to: moment().format("MM/DD/YYYY"),
};

const NavBarRecycleBin = () => {
  const { user } = useAuth();
  const [currentRowProject, setCurrentRowProject] =
    useState<IRecycleBinProject>();
  const [deleteProjectModal, setDeleteProjectModal] = useState(false);
  const [recoverProjectModal, setRecoverProjectModal] = useState(false);

  const projectColumns = getProjectColumns(
    setCurrentRowProject,
    setDeleteProjectModal,
    setRecoverProjectModal
  );

  const [currentRowStudy, setCurrentRowStudy] = useState<IRecycleBinStudy>();
  const [deleteStudyModal, setDeleteStudyModal] = useState(false);
  const [recoverStudyModal, setRecoverStudyModal] = useState(false);

  const studyColumns = getStudyColumns(
    setCurrentRowStudy,
    setDeleteStudyModal,
    setRecoverStudyModal
  );

  const [currentRowBuilding, setCurrentRowBuilding] =
    useState<IRecycleBinBuilding>();
  const [deleteBuildingModal, setDeleteBuildingModal] = useState(false);
  const [recoverBuildingModal, setRecoverBuildingModal] = useState(false);

  const buildingColumns = getBuildingColumns(
    setCurrentRowBuilding,
    setDeleteBuildingModal,
    setRecoverBuildingModal
  );

  const { log } = useLog();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [callFromApply, setCallFromApply] = useState(false);

  const validate = (fieldValues = values) => {
    let temp: Record<string, string> = { ...errors };

    temp.from = new Validator(fieldValues, "from")
      .isRequired("The date is required.")
      .toString();

    temp.to = new Validator(fieldValues, "to")
      .isRequired("The date is required.")
      .toString();

    const end = new Date(fieldValues.to);
    const start = new Date(fieldValues.from);
    if (fieldValues.to && fieldValues.from) {
      if (end < start) {
        temp.from = `Start date can not be greater than  ${moment(end).format(
          "MM/DD/YYYY"
        )}`;
        temp.to = `End date can not be less than  ${moment(start).format(
          "MM/DD/YYYY"
        )}`;
      } else {
        temp.from = "";
        temp.to = "";
      }
    }

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, errors, setErrors, handleInputChange, setValues } = useForm(
    initialValues,
    false,
    validate
  );

  const {
    Table: ProjectTable,
    setRowsPerPage,
    page,
    setDataSet,
    setCount,
    orderBy,
    order,
    setPage,
    setRefreshGrid,
    refreshGrid,
  } = useTable(projectColumns, { rowsPerPageOptions: [10] });

  const {
    Table: StudyTable,
    setRowsPerPage: setStudyRowsPerPage,
    page: studyPage,
    setDataSet: setStudyDataSet,
    setCount: setStudyCount,
    orderBy: studyOrderBy,
    order: studyOrder,
    setPage: setStudyPage,
    setRefreshGrid: setStudyRefreshGrid,
    refreshGrid: studyRefreshGrid,
  } = useTable(studyColumns, { rowsPerPageOptions: [10] });

  const {
    Table: BuildingTable,
    setRowsPerPage: setBuildingRowsPerPage,
    page: buildingPage,
    setDataSet: setBuildingDataSet,
    setCount: setBuildingCount,
    orderBy: buildingOrderBy,
    order: buildingOrder,
    setPage: setBuildingPage,
    setRefreshGrid: setBuildingRefreshGrid,
    refreshGrid: buildingRefreshGrid,
  } = useTable(buildingColumns, { rowsPerPageOptions: [10] });

  const [selectdTab, setSelectedTab] = useState(0);
  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  const handleRefreshGrid = () => {
    if (!validate()) return;

    setValues({ ...values });
    setPage(0);
    setStudyPage(0);
    setBuildingPage(0);
    setCallFromApply(!callFromApply);
  };

  useEffect(() => {
    const getProjectsData = async () => {
      try {
        if (!validate()) return;

        const tempStartDate = new Date(
          moment(values.from)
            .set({
              hour: 0,
              minute: 0,
              second: 0,
              millisecond: 0,
            })
            .format("MM-DD-yyyy hh:mm:ss a")
        ).toUTCString();

        const tempEndDate = new Date(
          moment(values.to)
            .set({
              hour: 0,
              minute: 0,
              second: 0,
              millisecond: 1,
            })
            .format("MM-DD-yyyy hh:mm:ss a")
        ).toUTCString();

        const request = await recycleBinService.getProjects(
          {
            companyId: user?.companyId,
            page: page + 1,
            filter: values.filter,
            sortColumn: orderBy,
            sortDec: order === "desc" ? true : false,
            dateFrom: tempStartDate,
            dateTo: tempEndDate,
          },
          getQueryString
        );

        setCount(request.data.count);
        setDataSet(request.data.data);
        setRowsPerPage(10);
        setIsSubmitting(false);
        setRefreshGrid(!refreshGrid);
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      } finally {
        setIsSubmitting(false);
      }
    };

    if (selectdTab === 0) getProjectsData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, orderBy, order, callFromApply, selectdTab]);

  useEffect(() => {
    const getStudyData = async () => {
      try {
        if (!validate()) return;

        const tempStartDate = new Date(
          moment(values.from)
            .set({
              hour: 0,
              minute: 0,
              second: 0,
              millisecond: 0,
            })
            .format("MM-DD-yyyy hh:mm:ss a")
        ).toUTCString();

        const tempEndDate = new Date(
          moment(values.to)
            .set({
              hour: 0,
              minute: 0,
              second: 0,
              millisecond: 1,
            })
            .format("MM-DD-yyyy hh:mm:ss a")
        ).toUTCString();

        const request = await recycleBinService.getStudies(
          {
            companyId: user?.companyId,
            page: studyPage + 1,
            filter: values.filter,
            sortColumn: studyOrderBy,
            sortDec: studyOrder === "desc" ? true : false,
            dateFrom: tempStartDate,
            dateTo: tempEndDate,
          },
          getQueryString
        );

        setStudyCount(request.data.count);
        setStudyDataSet(request.data.data);
        setStudyRowsPerPage(10);
        setIsSubmitting(false);
        setStudyRefreshGrid(!studyRefreshGrid);
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      } finally {
        setIsSubmitting(false);
      }
    };

    if (selectdTab === 1) getStudyData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [studyPage, studyOrderBy, studyOrder, callFromApply, selectdTab]);

  useEffect(() => {
    const getBuildingData = async () => {
      try {
        if (!validate()) return;

        const tempStartDate = new Date(
          moment(values.from)
            .set({
              hour: 0,
              minute: 0,
              second: 0,
              millisecond: 0,
            })
            .format("MM-DD-yyyy hh:mm:ss a")
        ).toUTCString();

        const tempEndDate = new Date(
          moment(values.to)
            .set({
              hour: 0,
              minute: 0,
              second: 0,
              millisecond: 1,
            })
            .format("MM-DD-yyyy hh:mm:ss a")
        ).toUTCString();

        const request = await recycleBinService.getBuildings(
          {
            companyId: user?.companyId,
            page: buildingPage + 1,
            filter: values.filter,
            sortColumn: buildingOrderBy,
            sortDec: buildingOrder === "desc" ? true : false,
            dateFrom: tempStartDate,
            dateTo: tempEndDate,
          },
          getQueryString
        );

        setBuildingCount(request.data.count);
        setBuildingDataSet(request.data.data);
        setBuildingRowsPerPage(10);
        setIsSubmitting(false);
        setBuildingRefreshGrid(!buildingRefreshGrid);
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      } finally {
        setIsSubmitting(false);
      }
    };

    if (selectdTab === 2) getBuildingData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buildingPage, buildingOrderBy, buildingOrder, callFromApply, selectdTab]);

  const deleteProjectModalHandler = async () => {
    try {
      await recycleBinService.deleteProject(currentRowProject?.id ?? 0);
      log.success("The project was successfully deleted permanently");
      setCallFromApply(!callFromApply);
      setDeleteProjectModal(false);
    } catch (error: any) {
      log.error(error?.message?.exceptionMessage ?? "Something went wrong");
    }
  };

  const handleRecoverProject = async () => {
    try {
      await recycleBinService.recoverProject(currentRowProject?.id ?? 0);
      log.success("The project was successfully recovered");
      setRecoverProjectModal(false);
      setCallFromApply(!callFromApply);
    } catch (error: any) {
      log.error(error?.message?.exceptionMessage ?? "Something went wrong");
    }
  };

  const deleteStudyModalHandler = async () => {
    try {
      await recycleBinService.deleteStudy(currentRowStudy?.id ?? 0);
      log.success("The study was successfully deleted permanently");
      setCallFromApply(!callFromApply);
      setDeleteProjectModal(false);
    } catch (error: any) {
      log.error(error?.message?.exceptionMessage ?? "Something went wrong");
    }
  };

  const handleRecoverStudy = async () => {
    try {
      await recycleBinService.recoverStudy(currentRowStudy?.id ?? 0);
      log.success("The study was successfully recovered");
      setRecoverProjectModal(false);
      setCallFromApply(!callFromApply);
    } catch (error: any) {
      log.error(error?.message?.exceptionMessage ?? "Something went wrong");
    }
  };

  const deleteBuildingModalHandler = async () => {
    try {
      await recycleBinService.deleteBuilding(currentRowBuilding?.id ?? 0);
      log.success("The building was successfully deleted permanently");
      setCallFromApply(!callFromApply);
      setDeleteBuildingModal(false);
    } catch (error: any) {
      log.error(error?.message?.exceptionMessage ?? "Something went wrong");
    }
  };

  const handleRecoverBuilding = async () => {
    try {
      await recycleBinService.recoverBuilding(currentRowBuilding?.id ?? 0);
      log.success("The building was successfully recovered");
      setRecoverBuildingModal(false);
      setCallFromApply(!callFromApply);
    } catch (error: any) {
      log.error(error?.message?.exceptionMessage ?? "Something went wrong");
    }
  };

  const validateMinDate = (e: any) => {
    if (e.target.value !== "Invalid date") {
      let newValues = { ...values };
      newValues.from = e.target.value;
      if (Date.parse(newValues.to) < Date.parse(newValues.from))
        setValues({ ...newValues, to: newValues.from });
      else setValues({ ...newValues });
    }
  };

  return (
    <>
      <Helmet title="Recycle Bin" />
      <Grid container spacing={10}>
        <Grid item xs={6}>
          <Typography variant="h3" gutterBottom display="inline">
            Recycle Bin
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} to="/">
              Dashboard
            </Link>
          </Breadcrumbs>
        </Grid>
      </Grid>

      <Divider my={6} />
      <Card mb={6}>
        <CardContent>
          <Grid container spacing={10}>
            <Grid item xs={12}>
              <Form>
                <Grid container spacing={5}>
                  <Grid item xs={4}>
                    <FormDatePicker
                      label={"Date From"}
                      name="from"
                      disablePast={false}
                      value={values.from}
                      onChange={validateMinDate}
                      error={errors.from}
                    />
                  </Grid>
                  <Grid item xs={4} sx={{ textAlign: "right" }}>
                    <FormDatePicker
                      label={"Date To"}
                      name="to"
                      disablePast={false}
                      value={values.to}
                      onChange={handleInputChange}
                      error={errors.to}
                      fullWidth={true}
                      minDate={values.from}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <FormActionButton
                      mr={2}
                      text="Search"
                      size="medium"
                      onClick={handleRefreshGrid}
                      isSubmitting={isSubmitting}
                      type="search"
                    />
                  </Grid>
                  <Grid item xs={8}>
                    <FormText
                      name="filter"
                      label="Type text to search"
                      value={values.filter}
                      onChange={handleInputChange}
                      error={errors.filter}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Tabs
                      value={selectdTab}
                      onChange={handleChangeTab}
                      aria-label="Recycle Bin"
                    >
                      <Tab label="Project" id="0" value={0} />
                      <Tab label="Study" id="1" value={1} />
                      <Tab label="Building" id="2" value={2} />
                    </Tabs>
                  </Grid>
                  <Divider my={3}></Divider>
                  {selectdTab === 0 && (
                    <Grid item xs={12}>
                      <ProjectTable />
                    </Grid>
                  )}
                  {selectdTab === 1 && (
                    <Grid item xs={12}>
                      <StudyTable />
                    </Grid>
                  )}
                  {selectdTab === 2 && (
                    <Grid item xs={12}>
                      <BuildingTable />
                    </Grid>
                  )}
                </Grid>
              </Form>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <MatchWordConfirmModal
        onConfirm={deleteProjectModalHandler}
        text={`Are you certain you want to delete permanently this project?`}
        matchWord={"DELETE"}
        isDialogOpen={deleteProjectModal}
        setIsDialogOpen={setDeleteProjectModal}
      />
      <DialogMessagePopup
        title={"Information"}
        text={`Are you certain you want to recover this project?`}
        showPopup={recoverProjectModal}
        setShowPopup={setRecoverProjectModal}
        onSave={handleRecoverProject}
        onCancel={() => {
          setRecoverProjectModal(false);
        }}
        isSubmitting={false}
      />
      <MatchWordConfirmModal
        onConfirm={deleteStudyModalHandler}
        text={`Are you certain you want to delete permanently this study?`}
        matchWord={"DELETE"}
        isDialogOpen={deleteStudyModal}
        setIsDialogOpen={setDeleteStudyModal}
      />
      <DialogMessagePopup
        title={"Information"}
        text={`Are you certain you want to recover this study?`}
        showPopup={recoverStudyModal}
        setShowPopup={setRecoverStudyModal}
        onSave={handleRecoverStudy}
        onCancel={() => {
          setRecoverStudyModal(false);
        }}
        isSubmitting={false}
      />
      <MatchWordConfirmModal
        onConfirm={deleteBuildingModalHandler}
        text={`Are you certain you want to delete permanently this building?`}
        matchWord={"DELETE"}
        isDialogOpen={deleteBuildingModal}
        setIsDialogOpen={setDeleteBuildingModal}
      />
      <DialogMessagePopup
        title={"Information"}
        text={`Are you certain you want to recover this building?`}
        showPopup={recoverBuildingModal}
        setShowPopup={setRecoverBuildingModal}
        onSave={handleRecoverBuilding}
        onCancel={() => {
          setRecoverBuildingModal(false);
        }}
        isSubmitting={false}
      />
    </>
  );
};

export default NavBarRecycleBin;
