import {
  BuildingDTO,
  IBuildingSelected,
  IBuildingPhoneNumber,
  IBuilding as IBuildingDTO,
} from "../ts/interfaces";
import axios from "../utils/axios";

const getById = (id: number) => {
  return axios.get<IBuildingDTO>(`Building/${id}`);
};

const getAddress = (id: number) => {
  return axios.get<IBuildingDTO>(`Building/getAddress?projectId=${id}`);
};

const getBuilding = async (id: number) => {
  return axios.get(`Building/${id}`);
};

const getBuildingByProject = (id: number) => {
  return axios.get<BuildingDTO>(`Building/GetBuildingByProject?id=${id}`);
};

const GetPhoneNumbers = (id: number) => {
  return axios.get<IBuildingPhoneNumber[]>(`Building/GetPhoneNumbers?id=${id}`);
};

const getByCompany = async (companyId: string) => {
  return axios.get<IBuildingSelected[]>(
    `Building/CompanySelected/${companyId}`
  );
};

const changeStatus = async (
  id: number,
  comments: string,
  isActive: boolean
) => {
  return await axios.put(
    `Building/changeStatus?id=${id}&isActive=${isActive}&comment=${comments}`,
    null
  );
};

const duplicate = async (item: IBuildingSelected) => {
  return await axios.post(`Building/Duplicate`, item);
};

const remove = (id: Number) => {
  return axios.delete(`Building/${id}`);
};

const update = async (id: number, data: IBuildingDTO) => {
  return axios.put<any>("Building/" + id, data);
};

const updatePhones = async (data: IBuildingPhoneNumber[]) => {
  return axios.post<any>("Building/UpdateBuildingPhones/", data);
};

const create = async (data: IBuildingDTO) => {
  return axios.post<any>("Building", data);
};

const getBuildingByProjectBid = async (id: number) => {
  return axios.get(`Building/GetBuildingByProject?id=${id}`);
};

const checkCovidAvailability = async (id: number) => {
  return axios.get<number>(`Building/CheckCovidAvailability?id=${id}`);
};

const deletePhone = async (id: number) => {
  return axios.delete(`Building/DeletePhone?id=${id}`);
};

const buildingService = {
  getBuildingByProjectBid,
  getBuilding,
  getBuildingByProject,
  getByCompany,
  changeStatus,
  duplicate,
  remove,
  getById,
  update,
  create,
  updatePhones,
  GetPhoneNumbers,
  checkCovidAvailability,
  getAddress,
  deletePhone,
};

export default buildingService;
