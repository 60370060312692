const downloadFile = (content: any, fileName: string) => {
  const typeimage = fileName.substr(fileName.indexOf(".") + 1);

  const a = document.createElement("a");
  a.href = "data:data/" + typeimage + ";base64," + content;
  a.target = "_blank";
  a.download = fileName;
  document.body.appendChild(a);

  a.click();
  document.body.removeChild(a);
};

const downloadFile2 = (
  content: any,
  fileName: string,
  type: string,
  extension?: string
) => {
  let file = new Blob([content], {
    type: type,
  });
  let fileURL = URL.createObjectURL(file);
  let a = document.createElement("a");
  a.href = fileURL;
  a.target = "_blank";

  a.download = `${fileName}.${
    extension === undefined || extension === null ? "pdf" : extension
  }`;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

const downloadFile3 = (content: string, name: string) => {
  let type = name.substr(name.indexOf(".") + 1);
  var a = document.createElement("a");
  a.href = "data:image/" + type + ";base64," + content;
  a.target = "_blank";
  a.download = name;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

const file2Base64 = (file: File): Promise<string> => {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result?.toString() || "");
    reader.onerror = (error) => reject(error);
  });
};

const FileUtils = {
  downloadFile,
  file2Base64,
  downloadFile2,
  downloadFile3,
};

export default FileUtils;
