import React, { useState, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import styled from "@emotion/styled";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import useLog from "src/hooks/useLog";
import {
  CardContent,
  Grid,
  Card as MuiCard,
  Divider as MuiDivider,
  Typography,
  Chip as MuiChip,
  Stack,
  Breadcrumbs as MuiBreadcrumbs,
  Link,
  Box,
} from "@mui/material";
import { SpacingProps, spacing } from "@mui/system";
import { COUNTRIES, EXAMENRESULT, ROLES } from "src/constants";
import { useForm, Form, useCountry, useAuth, usePermissions } from "src/hooks";
import moment from "moment";
import {
  FormCancelButton,
  FormSelect,
  FormText,
  FormSelectState,
  FormMaskText,
  FormButton,
  FormRadioGroup,
  FormTextArea,
} from "src/components/formControls";

import CompanyService from "src/services/companyService";

import { ICompany, IKeyValue } from "src/ts/interfaces";

import { Validator } from "src/ts/types";
import systemCostCatalogService from "src/services/systemCostCatalogService";

import useLoading from "src/hooks/useLoading";
import useZipCode from "src/hooks/useZipCode";

import UnionPopUp from "src/components/others/UnionPopUp";
import AccountBalanceWalletOutlinedIcon from "@mui/icons-material/AccountBalanceWalletOutlined";

import IdTypeService from "src/services/idTypeService";
import TechnicianService from "src/services/technicianService";
import {
  IPurchaseSummary,
  ITechnician,
  ITechnicianCosts,
} from "src/ts/interfaces/technician";
import { StateService, WalletService, userService } from "src/services";
import ConfirmationPayment from "./components/ConfirmationPayment";
import UnionSection from "../profile/UnionSection";
import { currencyformatter } from "src/utils/format";
import GenerateCertificationPopUp from "./components/popUp/GenerateCertificationPopUp";
import ViewTestPopUp from "./components/popUp/ViewTestPopUp";
import { Permission as PermissionTypes } from "src/ts/enums";

interface ChipProps extends SpacingProps {
  component?: React.ElementType;
  href?: string;
  icon?: JSX.Element | null;
}

const Card = styled(MuiCard)(spacing);
const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Chip = styled(MuiChip)<ChipProps>(spacing);

export type AudiTechniParams = {
  companyId: string;
  userId: string;
};

const initialValues: ITechnician = {
  id: 0,
  companyId: 0,
  companyName: "",
  photoId: 0,
  name: "",
  lastName: "",
  address: "",
  address2: "",
  city: "",
  stateId: 0,
  stateName: "",
  countryId: -1,
  idType: 0,
  certificationId: 0,
  zip: "",
  phone: "",
  cellPhone: "",
  email: "",
  idOther: "",
  idNumber: "",
  testComments: "",
  identification: { id: -1 },
  unionAffiliated: {
    isAffiliated: false,
    unionAffiliatedAnswer: false,
    unionAffiliationNumbersOther: "",
    code: "",
    smwiaMember: "",
    localUnionsOther: "",
    laborUnionsId: 0,
    localUnionsId: 0,
  },
};

const AuditorTechnicianEditAdd = () => {
  let navigate = useNavigate();
  const { log } = useLog();
  const { user } = useAuth();

  const { setSelectedCountryById, selectedCountry, countriesKeyValue } =
    useCountry();

  let { userId, companyId } = useParams<AudiTechniParams>();
  const companyNumber = Number(companyId);
  const userID = Number(userId);

  const { isLoading, startRequest, endRequest } = useLoading();

  const [companyInfo, setCompanyInfo] = useState<ICompany>();
  const [purchaseSummary, setPurchaseSummary] = useState<IPurchaseSummary>();
  const [selectCertificate, setSelectCertificate] = useState("");
  const [walletBalance, setWalletBalance] = useState(0);
  const [technicianCost, setTechnicianCost] = useState<ITechnicianCosts[]>([]);
  const [techInfo, setTechInfo] = useState<ITechnician>();
  const [idType, setIdType] = useState<IKeyValue<number, string>[]>();

  const [validSearch, setValidSearch] = useState(true);
  const [disableButtonSearch, setDisableButtonSearch] = useState(false);
  const [atLeastOneCertification, setAtLeastOneCertification] = useState(false);
  const [showConfirmationPayment, setShowConfirmationPayment] = useState(false);
  const [isSubmitting] = useState(false);
  const [showPopUp, setShowPopUp] = useState(false);
  const [viewTestShowPopUp, setViewTestShowPopUp] = useState(false);
  const [generateCerificateShowPopUp, setGenerateCerificateShowPopUp] =
    useState(false);

  const [isStateLoading, setIsStateLoading] = useState(false);
  const { fullAccess } = usePermissions(PermissionTypes.TechniciansAdmin);

  const validate = (fieldValues = values) => {
    let temp: Record<string, string> = { ...errors };

    if (
      values.email !== "" &&
      selectCertificate !== "" &&
      disableButtonSearch === false
    ) {
      temp.email = new Validator(fieldValues, "email")
        .isRequired("The email is required.")
        .emailFormat("Insert a correct email. demo@bootlab.io")
        .toString();

      setErrors({
        ...temp,
      });
    } else {
      temp.name = new Validator(fieldValues, "name")
        .isRequired("The Technician's first name is required.")
        .maxLength(200, "Only 200 character are allowed")
        .toString();

      temp.lastName = new Validator(fieldValues, "lastName")
        .isRequired("The Technician's last name is required.")
        .maxLength(200, "Only 200 character are allowed")
        .toString();

      temp.address = new Validator(fieldValues, "address")
        .isRequired("The Technician's address is required.")
        .maxLength(200, "Only 200 character are allowed.")
        .toString();

      temp.address2 = new Validator(fieldValues, "address2")
        .maxLength(200, "Only 200 character are allowed.")
        .toString();

      temp.zip = new Validator(fieldValues, "zip")
        .isRequired("The Technician's zipcode is required.")
        .toString();

      temp.countryId = new Validator(fieldValues, "countryId")
        .selectedOption(-1, "The Technician's country is required.")
        .toString();

      temp.city = new Validator(fieldValues, "city")
        .isRequired("The Technician's city is required.")
        .toString();
      temp.idNumber = new Validator(fieldValues, "idNumber")
        .isRequired("The Technician's ID number is required.")
        .toString();

      temp.stateId = new Validator(fieldValues, "stateId")
        .validateIf(fieldValues.countryId === COUNTRIES.USA)
        .selectedOption(0, "The Technician's state is required.")
        .toString();

      temp.stateName = new Validator(fieldValues, "stateName")
        .validateIf(fieldValues.countryId !== COUNTRIES.USA)
        .isRequired("The Technician's state is required.")
        .maxLength(50, "Only 50 character are allowed")
        .toString();

      temp.phone = new Validator(fieldValues, "phone")
        .isRequired("The User's cell phone is required.")
        .phoneFormat("Incorrect phone format.")
        .toString();

      if (fieldValues.identification === null) {
        temp.identificationId = "The Technician's Identification is required.";
      } else {
        temp.identificationId = new Validator(fieldValues.identification, "id")
          .selectedOption(-1, "The Technician's Identification is required.")
          .toString();
      }

      temp.idNumber = new Validator(fieldValues, "idNumber")
        .isRequired("The Technician's ID # is required.")
        .toString();

      if (
        fieldValues?.identification !== null &&
        fieldValues?.identification.id === 5
      ) {
        temp.idOther = new Validator(fieldValues, "idOther")
          .isRequired("ID's description is required.")
          .toString();
      }

      if (fieldValues?.unionAffiliated?.unionAffiliatedAnswer) {
        temp.laborUnionsId = new Validator(
          fieldValues.unionAffiliated,
          "laborUnionsId"
        )
          .selectedOption(0, "The Labor Union is required.")
          .toString();
        temp.smwiaMember = new Validator(
          fieldValues.unionAffiliated,
          "smwiaMember"
        )
          .isRequired("The Member # is required.")
          .toString();

        temp.localUnionsId = new Validator(
          fieldValues.unionAffiliated,
          "localUnionsId"
        )
          .selectedOption(0, "The Local Union is required.")
          .toString();
      }

      if (
        fieldValues?.unionAffiliated?.laborUnionsId === 2 ||
        fieldValues?.unionAffiliated?.laborUnionsId === 3
      ) {
        temp.localUnionsOther = new Validator(
          fieldValues.unionAffiliated,
          "localUnionsOther"
        )
          .isRequired("The Local Union is required.")
          .toString();
      }

      setErrors({
        ...temp,
      });
    }

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues, true, validate);

  const { zipValue } = useZipCode(values.zip);

  useEffect(() => {
    if (
      zipValue?.countryId !== undefined &&
      values.countryId === COUNTRIES.USA
    ) {
      setValues({ ...values, city: zipValue.city, stateId: zipValue.stateId });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [zipValue]);

  useEffect(() => {
    const getInformation = async () => {
      try {
        startRequest("auditTech");
        if (userId !== "0") {
          const responses = await Promise.all([
            CompanyService.getById(companyNumber),
            TechnicianService.getTechnicians(userId ?? "1"),
            IdTypeService.getIdTypes(),
          ]);
          setValidSearch(false);
          setCompanyInfo(responses[0].data);
          setValues(responses[1].data);
          setTechInfo(responses[1].data);
          setIdType(responses[2]);
        } else {
          const responses = await Promise.all([
            CompanyService.getById(companyNumber),
            IdTypeService.getIdTypes(),
            systemCostCatalogService.GetTechniciansCosts(),
            WalletService.getWalletBalance(companyId ?? "0"),
          ]);

          setValidSearch(true);
          setCompanyInfo(responses[0].data);
          setIdType(responses[1]);
          setTechnicianCost(responses[2].data);
          setWalletBalance(responses[3].data);
          if (process.env.REACT_APP_ENVIRONMENT === "TRAINING") {
            setValues({ ...values, email: user?.userName });
          }
        }
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
        endRequest("auditTech");
      } finally {
        endRequest("auditTech");
      }
    };

    getInformation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const getAtLeastCertification = async () => {
      try {
        const response = await TechnicianService.getAtLeastOneCertification(
          techInfo?.userId ?? 0
        );
        setAtLeastOneCertification(response.data);
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      }
    };

    if (techInfo !== undefined) getAtLeastCertification();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [techInfo]);

  useEffect(() => {
    const getPurchaseSummaryData = () => {
      const certificationInfo = technicianCost.filter(
        (item: ITechnicianCosts) => {
          return selectCertificate === item.code;
        }
      );
      let maintanceFeeValue;
      if (selectCertificate === "TBTC") {
        maintanceFeeValue = technicianCost.filter((item: ITechnicianCosts) => {
          return "TBAM" === item.code;
        });
      } else {
        maintanceFeeValue = technicianCost.filter((item: ITechnicianCosts) => {
          return "EAAM" === item.code;
        });
      }

      setPurchaseSummary({
        pricing: certificationInfo[0].pricing,
        maintananceFee: maintanceFeeValue[0].pricing,
        selectionTotal:
          certificationInfo[0].pricing + maintanceFeeValue[0].pricing,
        walletBalance: walletBalance,
        balanceRemaining:
          walletBalance -
          certificationInfo[0].pricing -
          maintanceFeeValue[0].pricing,
        nameCertication: certificationInfo[0].name,
      });
    };
    if (selectCertificate !== "") getPurchaseSummaryData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectCertificate]);

  const handleCancel = () => {
    navigate(`/app/Technicians/${companyId}`);
  };

  useEffect(() => {
    setSelectedCountryById(values.countryId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.countryId]);

  useEffect(() => {
    const emailChange = () => {
      setDisableButtonSearch(false);
      setValidSearch(true);
      resetForm();
    };

    if (disableButtonSearch) emailChange();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.email]);

  const handleSave = async () => {
    if (userId === "0") {
      if (!validate()) return;

      const filterType = idType?.filter(
        (item) => item.key === values.identification.id
      );
      const certificationID = technicianCost.filter(
        (item) => item.code === selectCertificate
      );

      const request = await StateService.getKeyValues(values.countryId!);
      const filterState = request.filter((item) => item.key === values.stateId);
      setValues({
        ...values,
        certificationId: certificationID[0].id,
        country: {
          id: selectedCountry?.id,
          name: selectedCountry?.name,
          countryCode: selectedCountry?.countryCode,
        },
        identification: {
          id: filterType?.[0]?.key ?? 0,
          name: filterType?.[0]?.value ?? "",
        },
        state: {
          id: values.stateId,
          name: filterState?.[0]?.value,
        },
      });
      setShowConfirmationPayment(true);
    } else {
      if (!validate()) return;

      try {
        await TechnicianService.updatedTechnicians(userId ?? "1", values);
        log.success("The technician was updated");
        const response = await TechnicianService.getTechnicians(userId ?? "1");
        setValues(response.data);
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      }
    }
  };

  const handleOnChangeRadio = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectCertificate(e.target.value);

    const certificationID = technicianCost.filter(
      (item) => item.code === e.target.value
    );

    setValues({
      ...values,
      certificationId: certificationID[0].id,
    });
  };

  const handleOnChangeSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValues({
      ...values,
      identification: { ...values.identification, id: Number(e.target.value) },
    });
  };

  const radioInformation = [
    {
      key: technicianCost[0]?.code,
      value: technicianCost[0]?.name,
      color: "blue",
    },
    {
      key: technicianCost[2]?.code,
      value: technicianCost[2]?.name,
      color: "green",
    },
  ];

  const goWalletPage = () => {
    navigate(`/app/Wallet/${companyId}`);
  };

  const handleSearchButton = async () => {
    if (selectCertificate === "") {
      log.warning("Select certification type");
    } else if (values.email === "") {
      log.warning("Please enter user's email");
    } else {
      if (!validate()) return;

      const roleTechVal =
        values.certificationId === 6 ? "EnergyAuditor" : "Auditor";

      try {
        const response = await userService.getByEmail(
          values.email,
          roleTechVal
        );

        setValues(response.data);

        log.info(
          "The email is already registered in Audit Master Pro. Please fill all the required information to complete the technician request."
        );
        setDisableButtonSearch(true);
        setValidSearch(false);
      } catch (error: any) {
        if (error.message === "Something went wrong") {
          log.info(
            "The email you entered is not registered in Audit Master Pro. Please fill in all the required fields to complete your technician request."
          );
          setValidSearch(false);
          setDisableButtonSearch(true);
        } else {
          log.warning(error?.message ?? "Something went wrong");
        }
      }
    }
  };

  const closeBuyModalHandler = (e: boolean) => {
    setShowConfirmationPayment(e);
  };

  const row = 6;

  const companyType =
    Number(user?.companyId) === companyInfo?.id ? "Headquarters" : "Satellite";

  const showInsufficientFundsMessage =
    purchaseSummary !== undefined &&
    !companyInfo?.walletBlocked &&
    !companyInfo?.walletAdminBlocked &&
    purchaseSummary?.balanceRemaining < 0 &&
    !companyInfo?.walletAutoWithdraw;

  const showAutomaticallyWithdrawFound =
    purchaseSummary !== undefined &&
    !companyInfo?.walletBlocked &&
    !companyInfo?.walletAdminBlocked &&
    purchaseSummary?.balanceRemaining < 0 &&
    companyInfo?.walletAutoWithdraw;

  const tempDate =
    values?.trainingDate !== null &&
    new Date(values?.trainingDate) <= new Date();

  const testData =
    values?.totalQuestions > 0 &&
    (user?.role === ROLES.SuperAdministrator ||
      user?.role === ROLES.Administrator ||
      user?.role === ROLES.CompanyOwner);

  return (
    <>
      <Helmet title="Requests" />

      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            {userID !== 0
              ? "Edit Technician / Auditor of:"
              : "Add Technician / Auditor for:"}
            {companyType === "Headquarters" && (
              <Chip label={companyType} color="warning" m={1} size="medium" />
            )}
            {companyType === "Satellite" && (
              <Chip label={companyType} color="success" m={1} size="medium" />
            )}
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} to="/">
              Dashboard
            </Link>
            <Link
              component={NavLink}
              to={`/app/TechnicianModify/${userId}/${companyId}`}
            >
              Requests
            </Link>
            <Typography>{userID !== 0 ? "Edit" : "New"}</Typography>
          </Breadcrumbs>
        </Grid>
      </Grid>
      <Divider my={6} />
      <Card mb={6}>
        <CardContent>
          <Form>
            <Grid container spacing={2}>
              {user?.role === ROLES.SuperAdministrator && (
                <>
                  <Grid item xs={1.1}></Grid>
                  <Grid item xs={8} mb={3}>
                    <Stack>
                      <FormText
                        label="Certification"
                        name="certification"
                        value={values.certification}
                        onChange={handleInputChange}
                        readOnlyText={true}
                      />
                    </Stack>
                  </Grid>
                  <Grid item xs={1}>
                    <Stack mt={1}>
                      {!values.isCertified && fullAccess && (
                        <FormButton
                          onClick={() => {
                            setGenerateCerificateShowPopUp(true);
                          }}
                          text="Generate"
                          color="primary"
                          size="small"
                          disabled={!values.passed}
                        />
                      )}
                    </Stack>
                  </Grid>
                </>
              )}
            </Grid>
            <Grid container spacing={5}>
              <Grid container xs={12} spacing={5} item>
                {userId !== "0" && (
                  <>
                    <Grid item xs={1}>
                      <Stack
                        direction="row"
                        justifyContent="end"
                        alignItems="center"
                      >
                        <Typography mt={1}>
                          <b>Certification Type </b>
                        </Typography>
                      </Stack>
                    </Grid>
                    <Grid item xs={11}>
                      <Stack
                        direction="row"
                        justifyContent="start"
                        alignItems="center"
                      >
                        {values?.costCatalog?.code === "EACR" && (
                          <Chip
                            label={values?.costCatalog?.name}
                            color="success"
                            m={1}
                            p={1}
                            size="small"
                            sx={{
                              textAlign: "center",
                              height: "auto",
                              "& .MuiChip-label": {
                                display: "block",
                                whiteSpace: "normal",
                              },
                            }}
                          />
                        )}
                        {values?.costCatalog?.code === "TBTC" && (
                          <Chip
                            label={values?.costCatalog?.name}
                            color="primary"
                            m={1}
                            p={1}
                            size="small"
                            sx={{
                              textAlign: "center",
                              height: "auto",
                              "& .MuiChip-label": {
                                display: "block",
                                whiteSpace: "normal",
                              },
                            }}
                          />
                        )}
                      </Stack>
                    </Grid>
                  </>
                )}
              </Grid>
              <Grid container xs={12} spacing={5} item>
                {userId !== "0" && (
                  <>
                    {tempDate && (
                      <>
                        <Grid item xs={2}>
                          <Stack
                            direction="row"
                            justifyContent="end"
                            alignItems="center"
                          >
                            <Typography>
                              <b>Test date</b>
                            </Typography>
                          </Stack>
                        </Grid>

                        <Grid item xs={2}>
                          <Stack
                            direction="row"
                            justifyContent="start"
                            alignItems="center"
                          >
                            {values?.trainingDate !== null &&
                              moment(values?.trainingDate).format("MM/DD/YYYY")}
                          </Stack>
                        </Grid>
                      </>
                    )}
                    {testData && (
                      <>
                        <Grid item xs={2}>
                          <Stack
                            direction="row"
                            justifyContent="end"
                            alignItems="center"
                          >
                            <Typography>
                              <b>Total questions: </b>
                            </Typography>
                          </Stack>
                        </Grid>
                        <Grid item xs={2}>
                          <Stack
                            direction="row"
                            justifyContent="start"
                            alignItems="center"
                          >
                            {values?.totalQuestions}
                          </Stack>
                        </Grid>{" "}
                      </>
                    )}
                    {testData && (
                      <>
                        {" "}
                        <Grid item xs={2}>
                          <Stack
                            direction="row"
                            justifyContent="end"
                            alignItems="center"
                          >
                            <Typography>
                              <b>Correct answers</b>
                            </Typography>
                          </Stack>
                        </Grid>
                        <Grid item xs={2}>
                          <Stack
                            direction="row"
                            justifyContent="start"
                            alignItems="center"
                          >
                            {values?.correctAnswers}
                          </Stack>
                        </Grid>
                      </>
                    )}
                  </>
                )}
                <Grid container mt={3}>
                  {values?.totalQuestions > 0 &&
                    user?.role === ROLES.SuperAdministrator && (
                      <>
                        <Grid item xs={1.3}></Grid>

                        <Grid item xs={8}>
                          <FormTextArea
                            label="Test Notes"
                            name="testComments"
                            onChange={handleInputChange}
                            value={values.testComments}
                            disabled={!fullAccess}
                          />
                        </Grid>
                        <Grid item xs={2}>
                          <Stack direction={"row"} spacing={2} mt={4} ml={2}>
                            <Chip
                              label={
                                values.passed
                                  ? EXAMENRESULT.PASS
                                  : EXAMENRESULT.FAIL
                              }
                              color={values.passed ? "success" : "error"}
                              size="medium"
                            />
                            <FormButton
                              onClick={() => {
                                setViewTestShowPopUp(true);
                              }}
                              text="View Test"
                              size="small"
                            />
                          </Stack>
                        </Grid>
                      </>
                    )}
                </Grid>
                {userId === "0" && (
                  <>
                    <Grid item xs={2}>
                      <Stack
                        direction="row"
                        justifyContent="end"
                        alignItems="center"
                      >
                        <Typography>
                          <b>Select Certification Type.</b>
                        </Typography>
                      </Stack>
                    </Grid>
                    <Grid item xs={10}>
                      <Stack
                        direction="row"
                        justifyContent="start"
                        alignItems="center"
                      >
                        <FormRadioGroup
                          name="radioButtonSelect"
                          label=""
                          value={values.radioButtonSelect}
                          items={radioInformation}
                          onChange={handleOnChangeRadio}
                          disabled={disableButtonSearch || !fullAccess}
                        />
                      </Stack>
                    </Grid>
                  </>
                )}
              </Grid>
              <Grid container xs={12} spacing={5} mb={5} item>
                {selectCertificate !== "" && (
                  <>
                    {!companyInfo?.walletAdminBlocked &&
                      !companyInfo?.walletBlocked && (
                        <>
                          <Grid item xs={12}>
                            {" "}
                            <Typography textAlign="center" variant="h6">
                              Purchase Summary
                            </Typography>
                          </Grid>

                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              border: "2px solid black",
                              padding: "5px",
                              marginLeft: "10px",
                              marginTop: "10px",
                              textAlign: "center",
                              alignItems: "center",
                              justifyItems: "center",
                            }}
                          >
                            <Grid item xs={4}>
                              <Stack
                                direction="row"
                                justifyContent="end"
                                alignItems="center"
                              >
                                <Typography>
                                  <b>
                                    New Test & Balance Technician Certification
                                    Request:
                                  </b>
                                </Typography>
                              </Stack>
                            </Grid>
                            <Grid item xs={2}>
                              <Stack
                                direction="row"
                                justifyContent="start"
                                alignItems="center"
                                textAlign="center"
                              >
                                <Chip
                                  label={`${currencyformatter.format(
                                    purchaseSummary?.pricing ?? 0
                                  )} USD`}
                                  color="primary"
                                  m={1}
                                  size="medium"
                                />
                              </Stack>
                            </Grid>
                            <Grid item xs={2}>
                              <Stack
                                direction="row"
                                justifyContent="end"
                                alignItems="center"
                              >
                                <Typography>
                                  <b>Prepaid Annual Maintenance Fee: </b>
                                </Typography>
                              </Stack>
                            </Grid>
                            <Grid item xs={2}>
                              <Stack
                                direction="row"
                                justifyContent="start"
                                alignItems="center"
                              >
                                <Chip
                                  label={`${currencyformatter.format(
                                    purchaseSummary?.maintananceFee ?? 0
                                  )} USD`}
                                  color="primary"
                                  m={1}
                                  size="medium"
                                />
                              </Stack>
                            </Grid>
                            <Grid item xs={2}>
                              <Stack
                                direction="row"
                                justifyContent="end"
                                alignItems="center"
                              >
                                <Typography>
                                  <b>Selection Total:</b>
                                </Typography>
                              </Stack>
                            </Grid>
                            <Grid item xs={2}>
                              <Stack
                                direction="row"
                                justifyContent="start"
                                alignItems="center"
                              >
                                <Chip
                                  label={`${currencyformatter.format(
                                    purchaseSummary?.selectionTotal ?? 0
                                  )} USD`}
                                  color="warning"
                                  m={1}
                                  size="medium"
                                />
                                {values?.correctAnswers}
                              </Stack>
                            </Grid>

                            <Grid item xs={2}>
                              <Stack
                                direction="row"
                                justifyContent="end"
                                alignItems="center"
                              >
                                <Typography>
                                  <b>Wallet Balance:</b>
                                </Typography>
                              </Stack>
                            </Grid>
                            <Grid item xs={2}>
                              <Stack
                                direction="row"
                                justifyContent="start"
                                alignItems="center"
                              >
                                <Chip
                                  label={`${currencyformatter.format(
                                    purchaseSummary?.walletBalance ?? 0
                                  )} USD`}
                                  color="success"
                                  m={1}
                                  size="medium"
                                />
                              </Stack>
                            </Grid>
                            <Grid item xs={2}>
                              <Stack
                                direction="row"
                                justifyContent="end"
                                alignItems="center"
                              >
                                <Typography>
                                  <b>Balance Remaining after Purchase: </b>
                                </Typography>
                              </Stack>
                            </Grid>
                            <Grid item xs={2}>
                              <Stack
                                direction="row"
                                justifyContent="start"
                                alignItems="center"
                              >
                                {purchaseSummary !== undefined &&
                                purchaseSummary?.balanceRemaining > 0 ? (
                                  <Chip
                                    label={`${currencyformatter.format(
                                      purchaseSummary?.balanceRemaining ?? 0
                                    )} USD`}
                                    color="primary"
                                    m={1}
                                    size="medium"
                                  />
                                ) : (
                                  <Chip
                                    label={`${currencyformatter.format(
                                      purchaseSummary?.balanceRemaining ?? 0
                                    )} USD`}
                                    color="error"
                                    m={1}
                                    size="medium"
                                  />
                                )}
                              </Stack>
                            </Grid>
                          </Box>
                        </>
                      )}
                    <Grid item xs={12} direction="column" textAlign="center">
                      {showInsufficientFundsMessage && (
                        <>
                          <Typography variant="h6" sx={{ color: "red" }} mb={2}>
                            You have insufficient funds to complete this
                            purchase, please go to your "Wallet" module (Button
                            Below) to complete this purchase.
                          </Typography>
                          <FormButton
                            text="Wallet"
                            onClick={() => {
                              goWalletPage();
                            }}
                            color="success"
                            size="small"
                            startIcon={<AccountBalanceWalletOutlinedIcon />}
                          />
                        </>
                      )}

                      {showAutomaticallyWithdrawFound && (
                        <>
                          <Typography
                            variant="h6"
                            sx={{ color: "blue" }}
                            mb={2}
                          >
                            The system will automatically withdraw funds from
                            your Wallet's default card to complete this
                            purchase.
                          </Typography>
                        </>
                      )}
                      {companyInfo?.walletAdminBlocked && (
                        <>
                          <Typography variant="h6" sx={{ color: "red" }} mb={2}>
                            Your AMP Wallet has an Administrative Blockage
                          </Typography>
                        </>
                      )}
                      {companyInfo?.walletBlocked && (
                        <>
                          <Typography variant="h6" sx={{ color: "red" }} mb={2}>
                            Your AMP Wallet has an Administrative Blockage
                          </Typography>
                        </>
                      )}
                    </Grid>
                  </>
                )}
              </Grid>
              <Grid item xs={userId !== "0" ? row : 8}>
                <FormText
                  name="email"
                  label="Email"
                  value={values.email}
                  onChange={handleInputChange}
                  error={errors.email}
                  disabled={
                    Number(userId) !== 0 ||
                    process.env.REACT_APP_ENVIRONMENT === "TRAINING" ||
                    !fullAccess
                  }
                  showSkeleton={isLoading}
                  placeholder="Pleace enter user's email and click"
                />
              </Grid>
              {userId === "0" && (
                <>
                  <Grid item xs={2}>
                    <FormButton
                      text="Search"
                      onClick={() => {
                        handleSearchButton();
                      }}
                      size="small"
                      disabled={disableButtonSearch}
                    />
                  </Grid>
                </>
              )}

              <Grid item xs={row}>
                <FormText
                  name="name"
                  label="Technician First Name"
                  value={values.name}
                  onChange={handleInputChange}
                  error={errors.name}
                  disabled={
                    (userId === "0" ? validSearch : atLeastOneCertification) ||
                    !fullAccess
                  }
                  showSkeleton={isLoading}
                />
              </Grid>
              <Grid item xs={row}>
                <FormText
                  name="lastName"
                  label="Technician Last Name"
                  value={values.lastName}
                  onChange={handleInputChange}
                  error={errors.lastName}
                  disabled={
                    (userId === "0" ? validSearch : atLeastOneCertification) ||
                    !fullAccess
                  }
                  showSkeleton={isLoading}
                />
              </Grid>
              <Grid item xs={row}>
                <FormText
                  name="address"
                  label="Address"
                  value={values.address}
                  onChange={handleInputChange}
                  error={errors.address}
                  disabled={validSearch || !fullAccess}
                  showSkeleton={isLoading}
                />
              </Grid>
              <Grid item xs={row}>
                <FormText
                  name="address2"
                  label="Address 2"
                  value={values.address2}
                  onChange={handleInputChange}
                  error={errors.address2}
                  disabled={validSearch || !fullAccess}
                  placeholder="Suite, Floor, etc."
                  showSkeleton={isLoading}
                />
              </Grid>
              <Grid item xs={row}>
                <FormSelect
                  name="countryId"
                  label="Country"
                  value={values.countryId}
                  onChange={handleInputChange}
                  options={countriesKeyValue}
                  error={errors.countryId}
                  showSkeleton={isLoading}
                  disable={validSearch || !fullAccess}
                  defaultValue={{ key: -1, value: "Select a country" }}
                />
              </Grid>
              <Grid item xs={row}>
                <FormText
                  name="zip"
                  label="Zip code"
                  value={values.zip}
                  onChange={handleInputChange}
                  error={errors.zip}
                  disabled={validSearch || !fullAccess}
                  showSkeleton={isLoading}
                />
              </Grid>
              <Grid item xs={row}>
                <FormText
                  name="city"
                  label="City"
                  value={values.city}
                  onChange={handleInputChange}
                  error={errors.city}
                  disabled={validSearch || !fullAccess}
                  showSkeleton={isLoading}
                />
              </Grid>
              <Grid item xs={row}>
                <FormSelectState
                  stateId={values.stateId}
                  stateName={values.stateName}
                  countryId={values.countryId}
                  errorsStateId={errors.stateId}
                  errorsStateName={errors.stateName}
                  onChange={handleInputChange}
                  isLoading={(value: boolean) => setIsStateLoading(value)}
                  showSkeleton={isLoading}
                  disable={validSearch || !fullAccess}
                />
              </Grid>
              <Grid item xs={row}>
                <Stack direction="row" spacing={2}>
                  <FormText
                    name="countryCode"
                    label="Country Code"
                    value={selectedCountry?.countryCode?.toString() ?? ""}
                    error={errors.countryCode}
                    disabled={true}
                    fullWidth={false}
                    showSkeleton={isLoading}
                  />
                  <FormMaskText
                    name="phone"
                    mask="(999) 999 - 9999"
                    label="Cell Phone"
                    onChange={handleInputChange}
                    value={values.phone}
                    error={errors.phone}
                    showSkeleton={isLoading}
                    disabled={validSearch || !fullAccess}
                  />
                </Stack>
              </Grid>
              <Grid item xs={row}>
                <Stack direction="row" spacing={2}>
                  <FormText
                    name="countryCode"
                    label="Country Code"
                    value={selectedCountry?.countryCode?.toString() ?? ""}
                    error={errors.countryCode}
                    disabled={true}
                    fullWidth={false}
                    showSkeleton={isLoading}
                  />
                  <FormMaskText
                    name="fixedPhone"
                    mask="(999) 999 - 9999"
                    label="Phone"
                    onChange={handleInputChange}
                    value={values.fixedPhone}
                    error={errors.fixedPhone}
                    showSkeleton={isLoading}
                    disabled={validSearch || !fullAccess}
                  />
                </Stack>
              </Grid>
              <Grid item xs={row}>
                <FormSelect
                  name="id"
                  label="Identification"
                  value={values?.identification?.id ?? -1}
                  onChange={handleOnChangeSelect}
                  options={idType}
                  error={errors.identificationId}
                  disabled={
                    (userId === "0" ? validSearch : atLeastOneCertification) ||
                    !fullAccess
                  }
                  defaultValue={{
                    key: -1,
                    value: " Please Select",
                  }}
                />
              </Grid>
              <Grid item xs={row}>
                <FormText
                  name="idNumber"
                  label={
                    values?.identification?.name === 5 ? "Other ID" : "ID#"
                  }
                  value={values.idNumber}
                  onChange={handleInputChange}
                  error={errors.idNumber}
                  disabled={
                    (userId === "0" ? validSearch : atLeastOneCertification) ||
                    !fullAccess
                  }
                  showSkeleton={isLoading}
                />
              </Grid>
              <Grid item xs={row}>
                {values?.identification && values?.identification?.id === 5 && (
                  <FormText
                    name="idOther"
                    label="Other"
                    value={values.idOther}
                    onChange={handleInputChange}
                    error={errors.idOther}
                    disabled={
                      (userId === "0"
                        ? validSearch
                        : atLeastOneCertification) || !fullAccess
                    }
                    showSkeleton={isLoading}
                  />
                )}
              </Grid>
              <Grid item xs={12}></Grid>

              {process.env.REACT_APP_ENVIRONMENT !== "SCHOOL" && (
                <Grid item xs={12}>
                  <UnionSection
                    values={values}
                    setValues={setValues}
                    errors={errors}
                  />
                </Grid>
              )}

              <Grid item xs={12}>
                <Stack
                  direction="row"
                  spacing={2}
                  justifyContent="center"
                  alignItems="center"
                >
                  <FormCancelButton
                    onClick={handleCancel}
                    isSubmitting={isSubmitting}
                    disabled={isStateLoading}
                  />
                  {!validSearch && fullAccess && (
                    <FormButton
                      text={userId === "0" ? "Add" : "Update"}
                      onClick={handleSave}
                      isSubmitting={isSubmitting}
                      disabled={isStateLoading}
                      size="small"
                    />
                  )}
                </Stack>
              </Grid>
            </Grid>
          </Form>
        </CardContent>
      </Card>
      <UnionPopUp setShowPopUp={setShowPopUp} showPopUp={showPopUp} />

      {showConfirmationPayment && (
        <ConfirmationPayment
          showBuyModal={showConfirmationPayment}
          setShowBuyModal={setShowConfirmationPayment}
          onChangeShowBuyModal={closeBuyModalHandler}
          companyId={Number(companyId)}
          companyName={values.name}
          purchaseSummary={
            purchaseSummary ?? {
              pricing: 0,
              maintananceFee: 0,
              selectionTotal: 0,
              walletBalance: 0,
              balanceRemaining: 0,
              nameCertication: "",
            }
          }
          completeName={`${values.name} ${values.lastName}`}
          userId={userId ?? "0"}
          roleTechVal={
            values.certificationId === 6 ? "EnergyAuditor" : "Auditor"
          }
          data={values}
          companyInfo={companyInfo}
        />
      )}
      {generateCerificateShowPopUp && (
        <GenerateCertificationPopUp
          generateCerificateShowPopUp={generateCerificateShowPopUp}
          setGenerateCerificateShowPopUp={setGenerateCerificateShowPopUp}
          comments={values.testComments}
          technicianId={userID}
          companyId={Number(companyId)}
          values={values}
          certification={values?.costCatalog?.code}
        />
      )}
      {viewTestShowPopUp && (
        <ViewTestPopUp
          setViewTestShowPopUp={setViewTestShowPopUp}
          technicianId={userID}
          viewTestShowPopUp={viewTestShowPopUp}
        />
      )}
    </>
  );
};

export default AuditorTechnicianEditAdd;
