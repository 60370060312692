import Popup from "src/components/Popup";
import { FormCancelButton } from "src/components/formControls";
import {
  Grid,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import {
  ICartPursasheResult,
  IPreviewPursacheProject,
  IpursacheCartViewProps,
} from "src/ts/interfaces/cart";
import { ColumnType } from "src/types/enhancedTable";
import LocalEnhancedTable from "src/components/localTable/LocalTable";
import { useAsyncQuery, useAuth, useLog } from "src/hooks";
import cartService from "src/services/cartService";
import { useEffect, useState } from "react";
import { IPlaqueOrder, IProjectReporDisplayPursache } from "src/ts/interfaces";
import projectReportService from "src/services/study/projectReportService";
import { blue, green } from "@mui/material/colors";
import plaqueOrderService from "src/services/plaqueOrderService";
import { convertUTCDateToLocalDate } from "src/utils/utils";
import { currencyformatter } from "src/utils/format";
import technicianService from "src/services/technicianService";
import { ITechnician } from "src/ts/interfaces/technician";

interface Props {
  pursacheCarProps: IpursacheCartViewProps;
  setPursacheCarProps: React.Dispatch<
    React.SetStateAction<IpursacheCartViewProps>
  >;
}

const columns = (): ColumnType[] => [
  {
    id: "code",
    label: "Code",
    type: "string",
    sort: true,
  },
  {
    id: "description",
    label: "Description",
    type: "string",
    sort: true,
  },
  {
    id: "quantity",
    label: "Qty",
    type: "string",
    sort: false,
  },
  {
    id: "price",
    label: "Price",
    type: "custom",
    sort: true,
    align: "right",
    callback: (row: IPreviewPursacheProject) => {
      return (
        <>
          <Typography textAlign={"right"}>
            {currencyformatter.format(row.price ?? 0)}
          </Typography>
        </>
      );
    },
  },
  {
    id: "discounts",
    label: "Discount",
    type: "custom",
    sort: true,
    align: "right",
    totalLabel: "Total:",
    callback: (row: IPreviewPursacheProject) => {
      return (
        <>
          <Typography textAlign={"right"}>
            {currencyformatter.format(row.discounts ?? 0)}
          </Typography>
        </>
      );
    },
  },
  {
    id: "total",
    label: "Total",
    type: "custom",
    sort: true,
    align: "right",
    total: "total",
    totalLabelAlign: "right",
    callback: (row: IPreviewPursacheProject) => {
      return (
        <>
          <Typography textAlign={"right"}>
            {currencyformatter.format(row.total ?? 0)}
          </Typography>
        </>
      );
    },
  },
];

export default function ViewCartPopup({
  pursacheCarProps,
  setPursacheCarProps,
}: Props) {
  const { user } = useAuth();
  const { log } = useLog();
  const { execute, data, isLoading } = useAsyncQuery<ICartPursasheResult>(
    cartService.getPreviewPursache
  );
  const { execute: executeSystem, data: dataSystem } = useAsyncQuery<string>(
    cartService.getSystem
  );
  const { execute: executeReports, data: dataReports } = useAsyncQuery<
    IProjectReporDisplayPursache[]
  >(projectReportService.getStudiesByProjectCart);
  const { execute: executePlaque, data: dataPlaque } =
    useAsyncQuery<IPlaqueOrder>(plaqueOrderService.getProjectPurchase);
  const [coupons, setCoupons] = useState<string[]>([]);
  const [technicianInfo, setTechnicianInfo] = useState<ITechnician>();

  useEffect(() => {
    const getData = async () => {
      if (pursacheCarProps.cart?.technicians) {
        const technician = await technicianService.getTechnicians(
          pursacheCarProps.cart?.technicians.id.toString()
        );
        setTechnicianInfo(technician.data);
      }
      if (pursacheCarProps.cart?.systemCostsCatalog.code !== "PLOR") {
        const listcoupons = Object.keys(coupons).map((key, item) => {
          return coupons[item];
        });
        const payload = {
          selected: pursacheCarProps.carts,
          coupons: listcoupons,
        };
        try {
          await execute(user?.companyId, payload);
        } catch (error: any) {
          log.error(error?.message?.exceptionMessage ?? "Something went wrong");
        }

        await executeReports(pursacheCarProps.cart?.project.id);
      } else await executePlaque(pursacheCarProps.carts[0]);

      if (
        pursacheCarProps.cart?.project &&
        pursacheCarProps.cart?.project.isInfectiousControlProject
      ) {
        executeSystem(pursacheCarProps.carts[0]);
      }
    };

    if (pursacheCarProps.carts.length > 0 && pursacheCarProps.showPopup)
      getData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pursacheCarProps.showPopup, coupons]);

  useEffect(() => {
    if (data?.result?.message) {
      log.error(data?.result?.message);
      setCoupons(data?.result?.coupons);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const handleClosePopUp = () => {
    setPursacheCarProps({
      carts: [],
      showPopup: false,
    });
  };

  const handleOpenPopUp = () => {
    setPursacheCarProps({
      ...pursacheCarProps,
      showPopup: true,
    });
  };

  return (
    <>
      <Popup
        title="Payment View"
        openPopup={pursacheCarProps.showPopup}
        setOpenPopup={handleOpenPopUp}
        onClose={handleClosePopUp}
        size="md"
      >
        <>
          <Stack direction="column">
            <Grid item xs={12}>
              <Grid container>
                {pursacheCarProps.cart?.technicians && (
                  <>
                    <Grid item xs={2} pt={1} textAlign={"right"}>
                      <Typography sx={{ fontWeight: "bold" }}>
                        Technician / Auditor:
                      </Typography>
                    </Grid>
                    <Grid item xs={9} pt={1} sx={{ marginLeft: "5px" }}>
                      <Typography>
                        {technicianInfo?.name} {technicianInfo?.lastName}
                      </Typography>
                    </Grid>
                  </>
                )}
                {!pursacheCarProps.cart?.technicians && (
                  <>
                    <Grid item xs={2} pt={1} textAlign={"right"}>
                      <Typography sx={{ fontWeight: "bold" }}>
                        Project Name:
                      </Typography>
                    </Grid>
                    <Grid item xs={9} pt={1} sx={{ marginLeft: "5px" }}>
                      <Typography>
                        {dataPlaque
                          ? dataPlaque.project.name
                          : pursacheCarProps.cart?.project?.name}
                      </Typography>
                    </Grid>
                  </>
                )}
                {pursacheCarProps.cart?.systemCostsCatalog.code !== "PLOR" &&
                  !pursacheCarProps.cart?.technicians && (
                    <>
                      <Grid item xs={2} pt={1} textAlign={"right"}>
                        <Typography sx={{ fontWeight: "bold" }}>
                          {pursacheCarProps.cart?.project
                            ?.isInfectiousControlProject
                            ? "System:"
                            : "Studies Included:"}
                        </Typography>
                      </Grid>

                      <Grid item xs={9} pt={1} sx={{ marginLeft: "5px" }}>
                        {dataReports &&
                          !pursacheCarProps.cart?.project
                            ?.isInfectiousControlProject &&
                          dataReports.map((row) => {
                            return (
                              <Typography
                                color={
                                  row.reportCategoryTypeId === 3
                                    ? green[800]
                                    : blue[800]
                                }
                              >
                                [{row.number}] {row.name}
                              </Typography>
                            );
                          })}
                        {pursacheCarProps.cart?.project
                          ?.isInfectiousControlProject && (
                          <Typography>{dataSystem}</Typography>
                        )}
                      </Grid>
                    </>
                  )}
                <Grid item xs={2} pt={1} textAlign={"right"}>
                  <Typography sx={{ fontWeight: "bold" }}>
                    Requested By:
                  </Typography>
                </Grid>
                <Grid item xs={9} pt={1} sx={{ marginLeft: "5px" }}>
                  <Typography>
                    {`${pursacheCarProps.cart?.requestedByUserPerson.name} ${pursacheCarProps.cart?.requestedByUserPerson.lastName}`}
                  </Typography>
                </Grid>
                <Grid item xs={2} pt={1} textAlign={"right"}>
                  <Typography sx={{ fontWeight: "bold" }}>
                    Requested Date:
                  </Typography>
                </Grid>
                <Grid item xs={9} pt={1} sx={{ marginLeft: "5px" }}>
                  <Typography>
                    {convertUTCDateToLocalDate(
                      pursacheCarProps.cart?.requestDate ?? ""
                    )?.format("LL")}
                  </Typography>
                </Grid>
                {pursacheCarProps.cart?.systemCostsCatalog.code === "PLOR" && (
                  <>
                    <Grid item xs={2} pt={1} textAlign={"right"}>
                      <Typography sx={{ fontWeight: "bold" }}>
                        Order #:
                      </Typography>
                    </Grid>
                    <Grid item xs={9} pt={1} sx={{ marginLeft: "5px" }}>
                      <Typography>{dataPlaque?.id}</Typography>
                    </Grid>
                  </>
                )}
              </Grid>
            </Grid>
            <Grid item xs={12}>
              {pursacheCarProps.cart?.systemCostsCatalog.code !== "PLOR" && (
                <Grid item xs={12}>
                  <LocalEnhancedTable<IPreviewPursacheProject>
                    refreshGrid={true}
                    columns={columns()}
                    data={Object.values(data?.result?.items ?? [])}
                    showSkeleton={isLoading}
                    defaultSortColumn={"code"}
                    totalData={data?.result ?? []}
                  />
                </Grid>
              )}
              {pursacheCarProps.cart?.systemCostsCatalog.code === "PLOR" && (
                <Grid>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Plaque Name</TableCell>
                        <TableCell>Size</TableCell>
                        <TableCell>Quantity</TableCell>
                        <TableCell align="right">Unit Price</TableCell>
                        <TableCell align="right">Total</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {dataPlaque?.plaqueOrderDetail.map((item) => (
                        <TableRow>
                          <TableCell>{item.plaques.name}</TableCell>
                          <TableCell>{item.plaques.size}</TableCell>
                          <TableCell>{item.quantity}</TableCell>
                          <TableCell>
                            <Typography variant="body2" align="right">
                              {currencyformatter.format(item.price)}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="body2" align="right">
                              {currencyformatter.format(item.price)}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      ))}

                      <TableRow>
                        <TableCell colSpan={4}>
                          <Typography variant="body2" align="right">
                            Subtotal:
                          </Typography>
                        </TableCell>
                        <TableCell colSpan={1}>
                          <Typography variant="body2" align="right">
                            {currencyformatter.format(
                              (dataPlaque?.total ?? 0) -
                                (dataPlaque?.shipping ?? 0)
                            )}
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell colSpan={4}>
                          <Typography variant="body2" align="right">
                            Shipping:
                          </Typography>
                        </TableCell>
                        <TableCell colSpan={1}>
                          <Typography variant="body2" align="right">
                            {currencyformatter.format(dataPlaque?.shipping)}
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell colSpan={4}>
                          <Typography variant="body2" align="right">
                            TOTAL:
                          </Typography>
                        </TableCell>
                        <TableCell colSpan={1}>
                          <Typography variant="body2" align="right">
                            {currencyformatter.format(dataPlaque?.total)}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Grid>
              )}
            </Grid>
          </Stack>
        </>

        <Stack direction="row" spacing={2}>
          <FormCancelButton
            onClick={() => {
              handleClosePopUp();
            }}
            isSubmitting={false}
          />
        </Stack>
      </Popup>
    </>
  );
}
