import React, { useEffect, useState } from "react";
import { Stack, Typography } from "@mui/material";
import Popup from "src/components/Popup";
import { FormButton } from "src/components/formControls";
import { IAssets } from "src/ts/interfaces/companyAssets";

interface Props {
  showSatelliteModal: boolean;
  setShowSatelliteModal: (val: boolean) => void;
  currentRow: IAssets;
  transactionType: string;
}

const SatellitePopUp = ({
  showSatelliteModal,
  setShowSatelliteModal,
  currentRow,
  transactionType,
}: Props) => {
  const [title, setTitle] = useState("");
  const [satelliteAddress, setSatelliteAddress] = useState("");

  useEffect(() => {
    const getInfo = () => {
      if (transactionType === "transferTo") {
        if (currentRow?.requestTransfer?.company?.headquarters) {
          setTitle("Headquarters");
          setSatelliteAddress(currentRow?.company?.companyAddress ?? "");
        } else {
          setTitle("Satellite");
          setSatelliteAddress(
            currentRow?.requestTransfer?.company?.companyAddress ?? ""
          );
        }
      }
      if (transactionType === "assignedTo") {
        if (currentRow?.company?.headquarters) {
          setTitle("Headquarters");
          setSatelliteAddress(currentRow?.company?.companyAddress ?? "");
        } else {
          setTitle("Satellite");
          setSatelliteAddress(
            currentRow?.requestTransfer?.company?.companyAddress ?? ""
          );
        }
      }
    };
    getInfo();
  }, [transactionType, currentRow]);

  return (
    <Popup
      title={`${title} Address`}
      openPopup={showSatelliteModal}
      setOpenPopup={setShowSatelliteModal}
      onClose={() => {
        setShowSatelliteModal(false);
      }}
    >
      <Stack>
        <div>
          <Typography>{satelliteAddress}</Typography>
        </div>
      </Stack>

      <Stack
        direction="row"
        spacing={2}
        justifyContent="flex-end"
        alignItems="flex-start"
      >
        <FormButton
          text="Close"
          size="medium"
          onClick={() => {
            setShowSatelliteModal(false);
          }}
        />
      </Stack>
    </Popup>
  );
};

export default SatellitePopUp;
