import axios from "../utils/axios";

const saveRate = async (rateId: number) => {
  return axios.put(`ShippingRates?shippingRateId=${rateId}`);
};

const shippingRatesService = {
  saveRate,
};

export default shippingRatesService;
