import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { NavLink, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import {
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
  Typography,
  Tab,
  Tabs,
  Grid,
  Card as MuiCard,
  CardContent,
  Stack,
} from "@mui/material";
import { spacing } from "@mui/system";

import useLoading from "src/hooks/useLoading";
import TechniciansExams from "./TechniciansExams";
import QuestionsExam from "./QuestionsExam";
import {
  FormCancelButton,
  FormSaveButton,
  FormSelect,
  FormText,
} from "src/components/formControls";
import { ITechnicianQuizAdd } from "src/ts/interfaces";
import { technicianQuizService } from "src/services";
import { useForm, useLog, usePermissions } from "src/hooks";
import TestChart from "./component/TestChart";
import { Permission as PermissionTypes } from "src/ts/enums";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const initialValues: ITechnicianQuizAdd = {
  id: 0,
  isActive: 1,
  title: "",
  description: "",
  totalQuestions: 0,
  numberQuestionsToPass: 0,
  technicianQuizCategory: [],
  type: 0,
  filter: "",
};

type QuizParams = {
  id: string;
};

function QuizView() {
  let navigate = useNavigate();
  let { id } = useParams<QuizParams>();
  const { log } = useLog();
  const quizId = parseInt(id === undefined ? "0" : id);
  const { isLoading, startRequest, endRequest } = useLoading();
  const [value, setValue] = React.useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const validate = (fieldValues = values) => {
    let temp: Record<string, string> = { ...errors };

    if (fieldValues.hasOwnProperty("title")) {
      if (fieldValues.title === "") {
        temp.title = "This field is required";
      } else if (fieldValues.title.length > 50) {
        temp.title = "Only 50 character are allowed";
      } else {
        temp.title = "";
      }
    }

    if (fieldValues.hasOwnProperty("description")) {
      if (fieldValues.description === "") {
        temp.description = "This field is required";
      } else if (fieldValues.description.length > 50) {
        temp.description = "Only 50 character are allowed";
      } else {
        temp.description = "";
      }
    }

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange } = useForm(
    initialValues,
    false,
    validate
  );

  const filterTnitialValues = {
    name: "",
  };
  const { values: filterValues, handleInputChange: handleFilterChange } =
    useForm(filterTnitialValues, true, []);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { fullAccess, readOnly } = usePermissions(PermissionTypes.Quiz);
  //Handlers

  const handleSave = async () => {
    if (!validate()) return;

    try {
      setIsSubmitting(true);
      await technicianQuizService.updateQuiz(values.id, values);
      log.success("The test was succesfully updated");
    } catch (error: any) {
      log.error(error?.message?.exceptionMessage ?? "Something went wrong");
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    const getData = async () => {
      try {
        startRequest("ActiveCompanies");
        const request = await technicianQuizService.getByIdNoExam(quizId);
        setValues(request.data);
        endRequest("ActiveCompanies");
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
        endRequest("ActiveCompanies");
      }
    };

    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const statuses = [
    { key: true, value: "Enable" },
    { key: false, value: "Disable" },
  ];

  const quizType = [
    { key: 1, value: "Energy/Auditor" },
    { key: 2, value: "Test & Balance" },
  ];

  const handleCancel = () => {
    navigate("/app/quiz");
  };

  const row = 6;

  return (
    <React.Fragment>
      <Helmet />
      <Typography variant="h3" gutterBottom display="inline">
        View Exam
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/">
          Dashboard
        </Link>
        <Link component={NavLink} to="/app/quiz">
          Quizs
        </Link>
        <Typography>View Exam</Typography>
      </Breadcrumbs>
      <Divider my={6} />

      <Grid>
        <TestChart quizId={quizId}></TestChart>
      </Grid>

      <Card mb={6}>
        <CardContent>
          {value === 0 && (
            <>
              <Divider my={6} />
              <Grid item xs={12}>
                <FormText
                  name="name"
                  label="Search: Name"
                  value={filterValues.name}
                  onChange={handleFilterChange}
                />
              </Grid>
              <Divider my={6} />
            </>
          )}
          <Grid
            container
            spacing={10}
            sx={{ borderBottom: 1, borderColor: "divider", marginBottom: 5 }}
          >
            <Grid item xs={6}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                <Tab label="Information" id="1" disabled={isLoading} />
                <Tab label="Questions" id="2" disabled={isLoading} />
                <Tab label="Technicians Exams" id="3" disabled={isLoading} />
              </Tabs>
            </Grid>
          </Grid>
          <Grid container spacing={10}>
            <Grid item xs={12}>
              {value === 2 && (
                <TechniciansExams quizId={quizId} filter={filterValues.name} />
              )}
              {value === 1 && <QuestionsExam quizId={quizId} />}
              {value === 0 && (
                <Grid container spacing={5}>
                  <Grid item xs={row}>
                    <FormSelect
                      name="type"
                      label="Certification Type:"
                      value={values.type}
                      onChange={handleInputChange}
                      options={quizType}
                      error={errors.type}
                      disable={isSubmitting || readOnly}
                      showSkeleton={isLoading}
                    />
                  </Grid>
                  <Grid item xs={row}>
                    <FormSelect
                      name="isActive"
                      label="Status"
                      value={values.isActive}
                      onChange={handleInputChange}
                      options={statuses}
                      error={errors.isActive}
                      disable={isSubmitting || readOnly}
                      showSkeleton={isLoading}
                    />
                  </Grid>
                  <Grid item xs={row}>
                    <FormText
                      name="title"
                      label="Title"
                      value={values.title}
                      onChange={handleInputChange}
                      error={errors.title}
                      disabled={isSubmitting || readOnly}
                      showSkeleton={isLoading}
                    />
                  </Grid>
                  <Grid item xs={row}>
                    <FormText
                      name="description"
                      label="Description"
                      value={values.description}
                      onChange={handleInputChange}
                      error={errors.description}
                      disabled={isSubmitting || readOnly}
                      showSkeleton={isLoading}
                    />
                  </Grid>
                  <Grid item xs={row}>
                    <FormText
                      name="totalQuestions"
                      label="Number of questions"
                      value={values.totalQuestions}
                      onChange={handleInputChange}
                      error={errors.totalQuestions}
                      disabled={isSubmitting || readOnly}
                      showSkeleton={isLoading}
                    />
                  </Grid>
                  <Grid item xs={row}>
                    <FormText
                      name="numberQuestionsToPass"
                      label="Percentage to pass"
                      value={values.numberQuestionsToPass}
                      onChange={handleInputChange}
                      error={errors.numberQuestionsToPass}
                      disabled={isSubmitting || readOnly}
                      showSkeleton={isLoading}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Stack
                      direction="row"
                      spacing={2}
                      justifyContent="center"
                      alignItems="center"
                    >
                      <FormCancelButton
                        onClick={handleCancel}
                        isSubmitting={isSubmitting}
                        disabled={isSubmitting}
                      />
                      {fullAccess && (
                        <FormSaveButton
                          onClick={handleSave}
                          isSubmitting={isSubmitting}
                          disabled={isSubmitting}
                          showSkeleton={isLoading}
                        />
                      )}
                    </Stack>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </React.Fragment>
  );
}

export default QuizView;
