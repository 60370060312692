import { useEffect, useState } from "react";
import Popup from "src/components/Popup";
import {
  FormAcceptButton,
  FormCancelButton,
  FormTextArea,
} from "src/components/formControls";
import { Box, Grid, Stack, Typography } from "@mui/material";
import projectService from "src/services/project/projectService";

interface Props {
  changeProjectStatusProps: {
    status: boolean;
    disabled: boolean;
    projectId: number;
  };
  setChangeProjectStatusProps: (
    value: React.SetStateAction<{
      status: boolean;
      disabled: boolean;
      projectId: number;
    }>
  ) => void;
  refreshTable: () => void;
}

export default function ChangeProjectStatusPopup({
  changeProjectStatusProps,
  setChangeProjectStatusProps,
  refreshTable,
}: Props) {
  const [comment, setComment] = useState("");
  const [isSubmmiting, setIsSubmmiting] = useState(false);
  useEffect(() => {
    setComment("");
  }, [changeProjectStatusProps]);
  useEffect(() => {
    setIsSubmmiting(false);
  }, [changeProjectStatusProps.projectId]);
  const handleChangeStatus = async () => {
    setIsSubmmiting(true);
    await projectService.changeStatus(
      changeProjectStatusProps.projectId,
      comment
    );
    setIsSubmmiting(false);
    setChangeProjectStatusProps({
      status: false,
      disabled: false,
      projectId: 0,
    });
    refreshTable();
  };
  const handleOpenPoup = (status: boolean) => {
    setChangeProjectStatusProps({
      status: status,
      disabled: changeProjectStatusProps.disabled,
      projectId: changeProjectStatusProps.projectId,
    });
  };
  return (
    <>
      <Popup
        title="Information"
        openPopup={changeProjectStatusProps.status}
        setOpenPopup={handleOpenPoup}
        onClose={() => {
          handleOpenPoup(false);
        }}
        size="sm"
      >
        <>
          <Stack direction="row" spacing={0}>
            <Grid container spacing={6}>
              <Grid item xs={12}>
                <Typography>
                  Reason(s) for changing this project status to{" "}
                  <Box fontWeight={"fontWeightBold"} display="inline">
                    {changeProjectStatusProps.disabled
                      ? "Active:"
                      : "Inactive:"}
                  </Box>
                </Typography>
                <Grid item xs={12} mb={5}></Grid>
                <FormTextArea
                  name={"rejectedComment"}
                  label="Comments"
                  value={comment || ""}
                  placeholder={
                    "Be descriptive when entering reason(s) for changing this project status"
                  }
                  onChange={(e) => {
                    setComment(e.target.value);
                  }}
                ></FormTextArea>
              </Grid>
            </Grid>
          </Stack>
        </>

        <Stack
          direction="row"
          spacing={2}
          justifyContent="flex-end"
          alignItems="flex-start"
        >
          <FormCancelButton
            onClick={() => {
              handleOpenPoup(false);
            }}
            isSubmitting={isSubmmiting}
          />
          <FormAcceptButton
            onClick={handleChangeStatus}
            isSubmitting={isSubmmiting}
            disabled={comment.length === 0}
          />
        </Stack>
      </Popup>
    </>
  );
}
