import { Grid } from "@mui/material";
import React, { useState, useEffect } from "react";
import { IUnionCompany, IKeyValue } from "src/ts/interfaces";
import { CatalogService, localUnionsService } from "src/services";
import { FormSelect, FormText } from "src/components/formControls";

interface UnionAffilatedData {
  disableUnionNumber?: boolean;
  values: IUnionCompany;
  setValues: (val: IUnionCompany) => void;
  errors: any;
  setShowPopUp: (val: boolean) => void;
}
const UnionCatalogCompany = (props: UnionAffilatedData) => {
  const { values, setValues, disableUnionNumber, errors } = props;
  const [dataLaborUnions, setDataLaborUnions] = useState<
    IKeyValue<number, string>[]
  >([]);
  const [isLoadingLaborUnions, setIsLoadingLaborUnions] = useState(false);
  const [dataLocalUnions, setDataLocalUnions] = useState<
    IKeyValue<number, string>[]
  >([]);
  const [isLoadingLocalUnions, setIsLoadingLaocalUnions] = useState(false);
  useEffect(() => {
    const getData = async () => {
      setIsLoadingLaborUnions(true);
      var result = await CatalogService.getAllDropdown("LaborUnions");
      setDataLaborUnions(result);
      setIsLoadingLaborUnions(false);
    };
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const getData = async () => {
      setIsLoadingLaocalUnions(true);
      var result = await localUnionsService.getKeyValuesByLabor(
        values?.laborUnionsId ?? 0
      );
      setDataLocalUnions(result);
      setIsLoadingLaocalUnions(false);
      if (result.length === 1) {
        setValues({
          ...values,
          localUnionsId: result[0].key,
        });
      }
    };

    if (values?.laborUnionsId && values?.laborUnionsId > 0) getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values?.laborUnionsId]);

  const isOtherLaborUnion = () => {
    if (!dataLaborUnions) return false;
    const found = dataLaborUnions.find((element) => element.value === "Other");
    if (!found) return false;
    return values?.laborUnionsId === found?.key;
  };

  const isOtherLocalUnion = () => {
    if (!dataLocalUnions) return false;
    const found = dataLocalUnions.find((element) => element.value === "Other");
    if (!found) return false;
    return values.localUnionsId === found?.key;
  };

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });

    if (name === "laborUnionsId") {
      const found = dataLaborUnions.find(
        (element) => element.value === "SMWIA/SMART"
      );
      if (found?.key === value) props.setShowPopUp(true);
    }
  };

  return (
    <>
      <Grid container spacing={5}>
        <Grid item xs={6}>
          <FormSelect
            name="laborUnionsId"
            label="Labor Unions"
            value={values.laborUnionsId?.toString()}
            onChange={handleInputChange}
            options={dataLaborUnions}
            error={errors?.laborUnionsId}
            showSkeleton={isLoadingLaborUnions}
            disable={disableUnionNumber}
          />
        </Grid>
        {isOtherLaborUnion() && (
          <Grid item xs={6}>
            <FormText
              name={"laborUnionsOther"}
              label={"Labor Unions Other"}
              value={values.laborUnionsOther}
              onChange={handleInputChange}
              disabled={disableUnionNumber}
            ></FormText>
          </Grid>
        )}
        <Grid item xs={6}>
          <FormSelect
            name="localUnionsId"
            label="Local Unions"
            value={values.localUnionsId?.toString()}
            onChange={handleInputChange}
            options={dataLocalUnions}
            error={errors?.localUnionsId}
            showSkeleton={isLoadingLocalUnions}
            disable={disableUnionNumber}
          />
        </Grid>
        <Grid item xs={6}>
          {isOtherLocalUnion() && (
            <FormText
              name={"localUnionsOther"}
              label={"Local Unions Other"}
              value={values.localUnionsOther}
              onChange={handleInputChange}
              disabled={disableUnionNumber}
            ></FormText>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default UnionCatalogCompany;
