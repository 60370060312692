import { IContractor, IContractorCompany } from "src/ts/interfaces/contractor";
import axios from "../utils/axios";

const getContractors = async (
  companyId: number,
  isUnion: boolean,
  search?: string,
  stateName?: string,
  countryId?: number,
  stateId?: number
) => {
  return axios.get(
    `Contractor/owner/${companyId}?isUnion=${isUnion}${
      !search ? "" : `&search=${search}`
    }${!stateName ? "" : `&stateName=${stateName}`}${
      !countryId ? "" : `&countryId=${countryId}`
    }${
      !stateId || stateId === Number.MIN_SAFE_INTEGER
        ? ""
        : `&stateId=${stateId}`
    }`
  );
};

const getContractor = async (companyId: number) => {
  return axios.get(`Contractor/${companyId}`);
};

const removeContractor = async (id: number) => {
  return axios.delete(`contractor/${id}`);
};

const updateContractor = async (companyId: number, contractor: IContractor) => {
  return axios.put(`Contractor/${companyId}`, contractor);
};

const createContractor = async (contractor: IContractor) => {
  return axios.post(`Contractor/create`, contractor);
};

const resendInvitation = async (companyId: number) => {
  return axios.put(`Contractor/ResendEmail/${companyId}?newRoute=true`);
};

const createContractorCompany = async (contractor: IContractorCompany) => {
  return axios.post(`Contractor/create`, contractor);
};

const contractorService = {
  getContractors,
  removeContractor,
  getContractor,
  updateContractor,
  resendInvitation,
  createContractor,
  createContractorCompany,
};

export default contractorService;
