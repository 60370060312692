import axios from "src/utils/axios";
import { ICoilDimensionDTO } from "src/ts/interfaces";
import { ICoilType } from "src/ts/interfaces/catalogs";

const getByReportId = (id: number) => {
  return axios.get<ICoilDimensionDTO[]>(`CoilDimension?reportId=${id}`);
};

const getCoilTypes = () => {
  return axios.get<ICoilType[]>("CoilType");
};

const save = (id: number, coil: ICoilDimensionDTO) => {
  return axios.post<ICoilDimensionDTO>(`CoilDimension?reportId=${id}`, coil);
};

const update = (id: number, coil: ICoilDimensionDTO) => {
  return axios.put<ICoilDimensionDTO>(`CoilDimension/${id}`, coil);
};

const remove = (id: number) => {
  return axios.delete<ICoilDimensionDTO>(`CoilDimension/${id}`);
};

const coilDimensionService = {
  getByReportId,
  getCoilTypes,
  save,
  remove,
  update,
};

export default coilDimensionService;
