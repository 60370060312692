import { IPostSendOutBid } from "src/ts/interfaces/sendOutForBid";
import axios from "../utils/axios";

const getSendOutToBidProject = async (id: number) => {
  return axios.get(`SendOutToBidProject/${id}`);
};

const deleteSendOutToBidProject = async (id: number) => {
  return axios.delete(`SendOutToBidProject/${id}`);
};
const postSendOutToBidProject = async (data: IPostSendOutBid) => {
  return axios.post(`SendOutToBidProject`, data);
};

const sendOutToBidProjectService = {
  getSendOutToBidProject,
  deleteSendOutToBidProject,
  postSendOutToBidProject,
};

export default sendOutToBidProjectService;
