import { IElevationCorrected } from "src/ts/interfaces";
import axios from "../utils/axios";

const getById = async (elevation: number | null) => {
  return axios.get<IElevationCorrected>(`elevationCorrected/${elevation}`);
};

const elevationCorrectedService = {
  getById,
};

export default elevationCorrectedService;
