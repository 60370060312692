import { Stack, Chip as MuiChip, styled, Divider } from "@mui/material";
import { spacing, SpacingProps } from "@mui/system";
import React, { useEffect, useState } from "react";
import { FormCancelButton } from "src/components/formControls";
import Popup from "src/components/Popup";
import { technicianQuizExamService } from "src/services";
import { ITechnicianQuizExamAnswers } from "src/ts/interfaces";
import FormTextEditor from "src/components/formControls/FormTextEditor";
import { useLog } from "src/hooks";

interface IChangeStatusPopupProps {
  quizExamId: number | undefined;
  showPopup: boolean;
  setShowPopup: (val: boolean) => void;
}

interface ChipProps extends SpacingProps {
  component?: React.ElementType;
  href?: string;
  icon?: JSX.Element | null;
}

const Chip = styled(MuiChip)<ChipProps>(spacing);

const ViewExamPopup = ({
  quizExamId,
  showPopup,
  setShowPopup,
}: IChangeStatusPopupProps) => {
  const handleClose = () => {
    setShowPopup(false);
  };
  const { log } = useLog();
  const [dataSet, setDataSet] = useState<ITechnicianQuizExamAnswers[]>([]);

  useEffect(() => {
    const getData = async () => {
      try {
        if (quizExamId !== undefined && showPopup && quizExamId !== 0) {
          const request = await technicianQuizExamService.getViewQuizExamId(
            quizExamId
          );
          setDataSet(request.data);
        }
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      }
    };

    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quizExamId, showPopup]);

  const exam = dataSet.map(function (item, index) {
    return (
      <div style={{ padding: 10, paddingLeft: 0, paddingRight: 0 }}>
        <label>
          {index + 1}.{" Question"}
          <FormTextEditor
            value={item.technicianQuizBacklog.description}
            onChange={function (e: any): void {
              throw new Error("Function not implemented.");
            }}
            name={"description" + item.id}
            readonly={true}
          ></FormTextEditor>
          {" Answer: "}
        </label>
        {item.technicianQuizAnswers.isCorrect ? (
          <Chip
            label={item.technicianQuizAnswers.description}
            color="success"
            variant="outlined"
            m={1}
            size="small"
          />
        ) : (
          <Chip
            label={item.technicianQuizAnswers.description}
            color="error"
            variant="outlined"
            m={1}
            size="small"
          />
        )}
        <Divider style={{ padding: 5 }} />
      </div>
    );
  });

  return (
    <Popup
      title="Information"
      openPopup={showPopup}
      setOpenPopup={setShowPopup}
      onClose={handleClose}
    >
      <>{exam}</>
      <Stack
        direction="row"
        spacing={2}
        justifyContent="flex-end"
        alignItems="flex-start"
      >
        <FormCancelButton onClick={handleClose} isSubmitting={false} />
      </Stack>
    </Popup>
  );
};

export default ViewExamPopup;
