import React, { useEffect, useState } from "react";
import { Grid, Stack } from "@mui/material";
import { useForm, Form, useCountry, useStateCatalog, useLog } from "src/hooks";
import useZipCode from "src/hooks/useZipCode";
import {
  FormCancelButton,
  FormSelect,
  FormText,
  FormMaskText,
  FormSaveButton,
} from "src/components/formControls";
import { Validator } from "src/ts/types";
import { IMemberTeam } from "src/ts/interfaces/teamMembers";
import Popup from "src/components/Popup";
import { COUNTRIES } from "src/constants";
import teamMemberServices from "src/services/teamMemberServices";

interface CouponFormProps {
  isDialogOpen: boolean;
  setIsDialogOpen: (value: boolean) => void;
  memberId: number;
  companyId: number;
  setRefresh: (value: boolean) => void;
  refresh: boolean;
}

const initialValues: IMemberTeam = {
  zipCode: "",
  city: "",
  companyTitle: "",
  name: "",
  lastName: "",
  title: "",
  email: "",
  address: "",
  stateName: "",
  workPhoneNumber: "",
  cellPhoneNumber: "",
  countryId: COUNTRIES.USA,
  stateId: 0,
  companyId: 0,
  countryCode: "+1",
};

const MembersForm = (props: CouponFormProps) => {
  const {
    isDialogOpen,
    setIsDialogOpen,
    companyId,
    memberId,
    setRefresh,
    refresh,
  } = props;

  const validate = (fieldValues = values) => {
    let temp: Record<string, string> = { ...errors };

    temp.companyTitle = new Validator(fieldValues, "companyTitle")
      .isRequired("Company is required.")
      .maxLength(200, "Only 200 character are allowed.")
      .toString();

    temp.name = new Validator(fieldValues, "name")
      .isRequired("First Name is required.")
      .maxLength(200, "Only 200 character are allowed.")
      .toString();
    temp.lastName = new Validator(fieldValues, "lastName")
      .isRequired("Last name is required.")
      .maxLength(200, "Only 200 character are allowed.")
      .toString();

    temp.email = new Validator(fieldValues, "email")
      .isRequired("The User's email is required.")
      .maxLength(50, "Only 50 character are allowed")
      .emailFormat("Insert a correct email. Example:  demo@bootlab.io")
      .toString();

    temp.address = new Validator(fieldValues, "address")
      .maxLength(500, "Only 500 character are allowed")
      .toString();

    temp.address2 = new Validator(fieldValues, "address2")
      .maxLength(500, "Only 500 character are allowed")
      .toString();

    temp.zipCode = new Validator(fieldValues, "zipCode")
      .maxLength(5, "Only 5 character are allowed")
      .toString();

    temp.city = new Validator(fieldValues, "city")
      .maxLength(50, "Only 50 character are allowed")
      .toString();

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange } = useForm(
    initialValues,
    false,
    validate
  );
  const { log } = useLog();

  const { countriesKeyValue, countries } = useCountry();
  const { states, setCountryId, isLoading } = useStateCatalog();
  const { zipValue } = useZipCode(values?.zipCode ?? "");
  const [isSubmmiting, setIsSubmmiting] = useState(false);
  useEffect(() => {
    const getData = async () => {
      setIsSubmmiting(true);
      try {
        const response = await teamMemberServices.dataTeamMember(memberId);
        setValues(response.data);
        if (countries.length > 0) {
          const countryCodevalue = countries.filter((item: any) => {
            return item.id === values.countryId;
          });

          setValues({
            ...values,
            countryCode: `+${countryCodevalue[0].countryCode}`,
          });
        }
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      } finally {
        setIsSubmmiting(false);
      }
    };
    if (memberId !== 0) getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      zipValue?.countryId !== undefined &&
      values.countryId === COUNTRIES.USA
    ) {
      setValues({ ...values, city: zipValue.city, stateId: zipValue.stateId });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [zipValue]);

  useEffect(() => {
    if (countries.length > 0) {
      const countryCodevalue = countries.filter((item: any) => {
        return item.id === values.countryId;
      });

      setValues({
        ...values,
        countryCode: `+${countryCodevalue[0]?.countryCode}`,
      });
    }
    setCountryId(values.countryId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setCountryId, values.countryId]);

  const handleOnChangeMask = (e: any) => {
    const { name, value } = e.target;

    setValues({ ...values, [name]: value });
  };

  const handleSave = async () => {
    if (!validate()) return;
    if (memberId === 0) {
      try {
        if (values.stateId === 0) {
          delete values.stateId;
        }
        const existEmail = await teamMemberServices.checkEmailTeamMember(
          values.email
        );
        if (!existEmail.data) {
          const response = await teamMemberServices.addNewTeamMember({
            ...values,
            companyId: companyId,
            countryId:
              values.countryId === -1 ? COUNTRIES.USA : values.countryId,
          });

          await teamMemberServices.notificationTeamMember(response.data.id);

          log.success("The email was sent");

          setIsDialogOpen(false);
          setRefresh(!refresh);
        } else {
          log.warning(
            "The Email is already registered like company user or external team member"
          );
        }
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      }
    } else {
      try {
        await teamMemberServices.updateTeamMemberData(values);
        log.success("Team member was updated");
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      }
    }
  };

  return (
    <>
      <Popup
        openPopup={isDialogOpen}
        setOpenPopup={setIsDialogOpen}
        title={
          "Add Team Member (This is for adding people to specific projects and have them receive all project correspondences.)"
        }
        onClose={() => setIsDialogOpen(false)}
        size="lg"
      >
        <Form>
          <Grid container spacing={5}>
            <Grid item xs={12}>
              <FormText
                name="companyTitle"
                label="Company"
                value={values.companyTitle}
                onChange={handleInputChange}
                error={errors.companyTitle}
                showSkeleton={isLoading}
              />
            </Grid>
            <Grid item xs={6}>
              <FormText
                name="name"
                label="Name"
                value={values.name}
                onChange={handleInputChange}
                error={errors.name}
                showSkeleton={isLoading}
              />
            </Grid>
            <Grid item xs={6}>
              <FormText
                name="lastName"
                label="Last Name"
                value={values.lastName}
                onChange={handleInputChange}
                error={errors.lastName}
                showSkeleton={isLoading}
              />
            </Grid>
            <Grid item xs={6}>
              <FormText
                name="title"
                label="Title"
                value={values.title}
                onChange={handleInputChange}
                error={errors.title}
                showSkeleton={isLoading}
              />
            </Grid>
            <Grid item xs={6}>
              <FormText
                name="email"
                label="Email"
                value={values.email}
                onChange={handleInputChange}
                error={errors.email}
                showSkeleton={isLoading}
                disabled={memberId !== 0 ? true : false}
              />
            </Grid>
            <Grid item xs={6}>
              <FormText
                name="address"
                label="Address"
                value={values.address}
                onChange={handleInputChange}
                error={errors.address}
                showSkeleton={isLoading}
              />
            </Grid>
            <Grid item xs={6}>
              <FormText
                name="address2"
                label="Address 2"
                value={values.address2}
                onChange={handleInputChange}
                error={errors.address2}
                showSkeleton={isLoading}
              />
            </Grid>
            <Grid item xs={6}>
              <FormSelect
                name="countryId"
                label="Country"
                value={values.countryId}
                onChange={handleInputChange}
                options={countriesKeyValue}
                error={errors.countryId}
                disable={false}
                showSkeleton={isLoading}
                defaultValue={{ key: -1, value: "Select a country" }}
              />
            </Grid>
            <Grid item xs={6}>
              <FormText
                name="zipCode"
                label="Zip Code"
                value={values.zipCode}
                onChange={handleInputChange}
                error={errors.zipCode}
                showSkeleton={isLoading}
                maxLength={5}
              />
            </Grid>
            <Grid item xs={6}>
              <FormText
                name="city"
                label="City"
                value={values.city}
                onChange={handleInputChange}
                error={errors.city}
                showSkeleton={isLoading}
              />
            </Grid>

            {values.countryId !== Number.MIN_SAFE_INTEGER &&
            values.countryId === COUNTRIES.USA ? (
              <Grid item xs={6}>
                <FormSelect
                  name="stateId"
                  label="State"
                  value={values.stateId}
                  onChange={handleInputChange}
                  options={states}
                  error={errors.stateId}
                  disable={false}
                  showSkeleton={isLoading}
                />
              </Grid>
            ) : (
              <Grid item xs={6}>
                <FormText
                  name="stateName"
                  label="State"
                  value={values.stateName}
                  onChange={handleInputChange}
                  error={errors.stateName}
                  showSkeleton={isLoading}
                />
              </Grid>
            )}

            <Grid item xs={6}>
              <Stack direction="row">
                <Grid container>
                  <Grid item xs={1.5}>
                    <FormText
                      name="countryCode"
                      label=""
                      value={values.countryCode}
                      disabled={true}
                    />
                  </Grid>
                  <Grid item xs={10.5}>
                    <FormMaskText
                      name="workPhoneNumber"
                      mask="(999) 999 - 9999"
                      label="Work Phone Number"
                      onChange={handleOnChangeMask}
                      value={values.workPhoneNumber}
                      error={errors.workPhoneNumber}
                    />
                  </Grid>
                </Grid>
              </Stack>
            </Grid>
            <Grid item xs={6}>
              <FormMaskText
                name="extension"
                mask=" 999999 "
                label="Extension"
                onChange={handleOnChangeMask}
                value={values.extension}
                error={errors.extension}
              />
            </Grid>
            <Grid item xs={6}>
              <Stack direction="row">
                <Grid container>
                  <Grid item xs={1.5}>
                    <FormText
                      name="countryCode"
                      label=""
                      value={values.countryCode}
                      disabled={true}
                    />
                  </Grid>
                  <Grid item xs={10.5}>
                    <FormMaskText
                      name="cellPhoneNumber"
                      mask="(999) 999 - 9999"
                      label="Cell Phone"
                      onChange={handleOnChangeMask}
                      value={values.cellPhoneNumber}
                      error={errors.cellPhoneNumber}
                    />
                  </Grid>
                </Grid>
              </Stack>
            </Grid>
          </Grid>
        </Form>
        <>
          <Stack direction="row" spacing={2}>
            <FormCancelButton
              onClick={() => {
                setIsDialogOpen(false);
                setRefresh(!refresh);
              }}
              isSubmitting={isSubmmiting}
            />
            <FormSaveButton
              onClick={handleSave}
              text={memberId !== 0 ? "Update" : "Add"}
              isSubmitting={isSubmmiting}
            />
          </Stack>
        </>
      </Popup>
    </>
  );
};

export default MembersForm;
