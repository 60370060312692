import { IStudyStep } from "src/ts/interfaces";

export const steps: IStudyStep[] = [
  {
    label: "Step 1",
    key: 0,
    description: "System Design",
    url: "/studies/Tower/step1/:id",
  },
  {
    label: "Step 2",
    key: 1,
    description: "Nameplates",
    url: "/studies/Tower/step2/:id",
  },
  {
    label: "Step 3",
    key: 2,
    description: "Actuals",
    url: "/studies/Tower/step3/:id",
  },
  {
    label: "Step 4",
    key: 3,
    description: "Sound Pressure Test",
    url: "/studies/Tower/step4/:id",
  },
  {
    label: "Step 5",
    key: 4,
    description: "Energy Source Test",
    url: "/studies/Tower/step5/:id",
  },
  {
    label: "Step 6",
    key: 5,
    description: "Energy Combined/Averaged",
    url: "/studies/Tower/step6/:id",
  },
  {
    label: "Step 7",
    key: 6,
    description: "Report",
    url: "/studies/Tower/step7/:id",
  },
];

export const studyTypeCode = "TOWE";

export const reportTypeId = 22;
