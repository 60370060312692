import axios from "src/utils/axios";
import { IFilterDTO } from "src/ts/interfaces";

const getByReportId = (id: number) => {
  return axios.get<IFilterDTO[]>(`Filter?reportId=${id}`);
};

const save = (id: number, filter: IFilterDTO) => {
  return axios.post<IFilterDTO>(`Filter?reportId=${id}`, filter);
};

const update = (id: number, filter: IFilterDTO) => {
  return axios.put<IFilterDTO>(`Filter/${id}`, filter);
};

const remove = (id: number) => {
  return axios.delete<IFilterDTO>(`Filter/${id}`);
};

const filterService = {
  getByReportId,
  save,
  remove,
  update,
};

export default filterService;
