import React, { useEffect, useState } from "react";
import {
  Skeleton,
  Button,
  Grid,
  Typography,
  Stack,
  Container,
} from "@mui/material";
import Popup from "../Popup";
import FormCancelButton from "./FormCancelButton";
import FormAcceptButton from "./FormAcceptButton";
import GoogleMapReact from "google-map-react";
import { LocationOn, Room } from "@mui/icons-material";
import FormButton from "./FormButton";
interface FormButtonGoogleMapProps {
  canEdit: boolean;
  showSkeleton?: boolean;
  lat: number;
  lng: number;
  address: string;
  setLat: (e: number) => void;
  setLng: (e: number) => void;
}

export default function FormButtonGoogleMap(props: FormButtonGoogleMapProps) {
  const { canEdit } = props;
  const { showSkeleton } = props;
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [elevation, setElevation] = useState(0);
  const [zoom, setZoom] = useState(4);

  useEffect(() => {
    if (props.lat || props.lng) {
      setZoom(15);
    }
  }, [props.lat, props.lng]);

  const closeHandler = () => {
    setIsDialogOpen(false);
  };

  const onMapChildClick = (e: any) => {
    props.setLat(e.lat);
    props.setLng(e.lng);
    setZoom(15);
    handleElevation(new google.maps.LatLng(e.lat, e.lng));
  };

  const handleElevation = (LatLng: google.maps.LatLng) => {
    const elevationService = new window.google.maps.ElevationService();
    elevationService
      .getElevationForLocations({
        locations: [LatLng],
      })
      .then((results: any) => {
        if (results.results[0]) {
          setElevation(results.results[0].elevation);
        } else {
        }
      })
      .catch();
  };

  const handleUseBuildingAddress = () => {
    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode(
      { address: props.address },
      (results: any, status: any) => {
        if (status === "OK") {
          props.setLat(results[0].geometry.location.lat());
          props.setLng(results[0].geometry.location.lng());
          setZoom(15);
        }
      }
    );
  };

  const LocationPin = (lat: any, lng: any) => (
    <div className="pin">
      <LocationOn
        style={{ position: "relative", top: "-50px", left: "-25px" }}
        color="error"
        sx={{ fontSize: 40 }}
      />
    </div>
  );

  return (
    <>
      {showSkeleton ? (
        <Skeleton height={50} />
      ) : (
        <>
          <Button
            type="button"
            onClick={() => {
              setIsDialogOpen(true);
            }}
            fullWidth
            variant="outlined"
            color="primary"
            startIcon={<Room />}
          >
            Elevation Map
          </Button>
        </>
      )}
      <Popup
        openPopup={isDialogOpen}
        setOpenPopup={setIsDialogOpen}
        title={`Map Zip Code`}
        onClose={closeHandler}
        size="lg"
      >
        <Grid container spacing={5}>
          <Grid item xs={12}>
            <Typography variant="subtitle2" gutterBottom>
              Please choose a location on the map
            </Typography>
            <Typography display="inline" variant="subtitle2">
              Latitude:{" "}
            </Typography>
            <Typography display="inline" variant="body1">
              {props.lat}{" "}
            </Typography>
            <Typography display="inline" variant="subtitle2">
              Longitude:{" "}
            </Typography>
            <Typography display="inline" variant="body1">
              {props.lng}{" "}
            </Typography>
            <Typography display="inline" variant="subtitle2">
              Elevation:{" "}
            </Typography>
            <Typography display="inline" variant="body1">
              {elevation}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Container sx={{ height: "400px", width: "100%" }}>
              <GoogleMapReact
                bootstrapURLKeys={{
                  key: "AIzaSyBpQki4GU4PnVf72VdtY6xXZz6C74gg3-Q",
                }}
                defaultCenter={{ lat: props.lat, lng: props.lng }}
                defaultZoom={zoom}
                onClick={onMapChildClick}
                yesIWantToUseGoogleMapApiInternals
                center={{ lat: props.lat, lng: props.lng }}
              >
                <LocationPin lat={props.lat} lng={props.lng} />
              </GoogleMapReact>
            </Container>
          </Grid>
        </Grid>
        <>
          <Stack spacing={2} direction="row">
            <FormCancelButton
              onClick={closeHandler}
              isSubmitting={false}
              size="medium"
            />
            {canEdit && (
              <>
                <FormButton
                  text={"Use Address"}
                  onClick={handleUseBuildingAddress}
                  color="primary"
                  variant="outlined"
                  startIcon={<Room />}
                  size="medium"
                />
                <FormAcceptButton
                  text="Save & Close"
                  onClick={closeHandler}
                  isSubmitting={false}
                  size="medium"
                />
              </>
            )}
          </Stack>
        </>
      </Popup>
    </>
  );
}
