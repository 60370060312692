import React, { useState, useEffect } from "react";
import { Grid, Stack, Typography } from "@mui/material";
import { useForm, Form, useLog } from "src/hooks";
import {
  FormCancelButton,
  FormSaveButton,
  FormTextArea,
} from "src/components/formControls";
import Popup from "src/components/Popup";
import { Validator } from "src/ts/types";

const initialValues: any = {
  comment: "",
};

interface Props {
  isDialogOpen: boolean;
  setIsDialogOpen: (val: boolean) => void;
  onSave: (comment: string) => void;
}

const GpsPermissionModal = (props: Props) => {
  const { log } = useLog();
  const { isDialogOpen, setIsDialogOpen, onSave } = props;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const validate = (fieldValues = values) => {
    let temp: Record<string, string> = { ...errors };

    temp.comment = new Validator(fieldValues, "comment").toString();

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, errors, setErrors, handleInputChange, resetForm } = useForm(
    initialValues,
    false,
    validate
  );

  useEffect(() => {
    resetForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDialogOpen]);

  const saveHandler = async () => {
    if (!validate()) return;

    try {
      setIsSubmitting(true);
      await onSave(values?.comment);
      setIsSubmitting(false);
      setIsDialogOpen(false);
    } catch (error: any) {
      log.error(error?.message?.exceptionMessage ?? "Something went wrong");
    } finally {
      setIsSubmitting(false);
    }
  };

  const cancelHandler = () => {
    setIsDialogOpen(false);
  };

  const row = 12;
  return (
    <Popup
      openPopup={isDialogOpen}
      setOpenPopup={setIsDialogOpen}
      title={`AMP Technicians App`}
      onClose={cancelHandler}
      disableClickOutside={true}
      isSubmitting={isSubmitting}
    >
      <Form>
        <Grid container spacing={5}>
          <Grid item xs={12}>
            <Typography variant="subtitle2">
              Are you certain you want to send an authorization request to
              temporarily disable this AMP Technicians App?
            </Typography>
          </Grid>
          <Grid item xs={row}>
            <FormTextArea
              name="comment"
              label="Comments"
              value={values.comment}
              onChange={handleInputChange}
              error={errors.comment}
              placeholder=""
            />{" "}
          </Grid>
        </Grid>
      </Form>
      <>
        <Stack
          direction="row"
          spacing={2}
          justifyContent="center"
          alignItems="center"
        >
          <FormCancelButton
            onClick={cancelHandler}
            isSubmitting={isSubmitting}
          />
          <FormSaveButton onClick={saveHandler} isSubmitting={isSubmitting} />
        </Stack>
      </>
    </Popup>
  );
};

export default GpsPermissionModal;
