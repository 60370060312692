import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { Outlet, useNavigate } from "react-router-dom";
import { Box, CssBaseline, Paper as MuiPaper } from "@mui/material";
import { spacing } from "@mui/system";
import GlobalStyle from "../components/GlobalStyle";
import Navbar from "../components/navbar/Navbar";
import sysAdminItems from "../components/sidebar/sysAdminItems";
import Sidebar from "../components/sidebar/Sidebar";
import Footer from "../components/Footer";
import Settings from "../components/Settings";
import useAuth from "../hooks/useAuth";
import { SidebarItemsType } from "../types/sidebar";
import { ROLES } from "../constants";

const drawerWidth = 258;

const Root = styled.div`
  display: flex;
  min-height: 100vh;
`;

const Drawer = styled.div`
  ${(props) => props.theme.breakpoints.up("md")} {
    width: ${drawerWidth}px;
    flex-shrink: 0;
  }
`;

const AppContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  max-width: calc(100% - 258px);
`;

const Paper = styled(MuiPaper)(spacing);

const MainContent = styled(Paper)`
  flex: 1;
  background: ${(props) => props.theme.palette.background.default};

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    flex: none;
  }

  .MuiPaper-root .MuiPaper-root {
    box-shadow: none;
  }
`;
interface DashboardProps {
  children?: React.ReactNode;
}

const Dashboard: React.FC<DashboardProps> = ({ children }) => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [mobileOpen, setMobileOpen] = useState(false);
  const [menuItems, setMenuItems] = useState<
    {
      title: string;
      pages: SidebarItemsType[];
    }[]
  >([]);

  useEffect(() => {
    const getData = async () => {
      if (
        user?.role === ROLES.SysAdmin ||
        user?.role === ROLES.SuperAdministrator ||
        user?.role === ROLES.Auditor ||
        user?.role === ROLES.EnergyAuditor ||
        user?.role === ROLES.TemporalEnergyAuditor ||
        user?.role === ROLES.TemporalAuditor ||
        user?.role === ROLES.Architect ||
        user?.role === ROLES.CompanyOwner ||
        user?.role === ROLES.UnionAdministrator ||
        user?.role === ROLES.Trainer ||
        user?.role === ROLES.Training ||
        user?.role === ROLES.Administrator ||
        user?.role === ROLES.ProjectManager ||
        user?.role === ROLES.ArchitectAux
      )
        setMenuItems(await sysAdminItems(user?.role, user?.userName, user));
      else if (
        user?.role === ROLES.TemporalArchitect ||
        user?.role === ROLES.TemporalCompanyOwner ||
        user?.role === ROLES.TemporalTraining ||
        user?.role === ROLES.TemporalSchool
      )
        navigate("/contactInformation");
      else setMenuItems([]);
    };
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.role]);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  return (
    <Root>
      <CssBaseline />
      <GlobalStyle />
      <Drawer>
        <Box sx={{ display: { xs: "block", lg: "none" } }}>
          <Sidebar
            PaperProps={{ style: { width: drawerWidth } }}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            items={menuItems}
          />
        </Box>
        <Box sx={{ display: { xs: "none", md: "block" } }}>
          <Sidebar
            PaperProps={{ style: { width: drawerWidth } }}
            items={menuItems}
          />
        </Box>
      </Drawer>
      <AppContent>
        <Navbar onDrawerToggle={handleDrawerToggle} />
        {/* <MainContent p={isLgUp ? 12 : 5}> */}
        <MainContent p={5}>
          {children}
          <Outlet />
        </MainContent>
        <Footer />
      </AppContent>
      <Settings />
    </Root>
  );
};

export default Dashboard;
