import React, { useEffect, useState } from "react";
import { FormControlLabel, Grid, IconButton, Radio } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { ITechnicianQuizAnswers } from "src/ts/interfaces";
import { ColumnType } from "src/types/enhancedTable";
import { useForm, useLoading, useLog, usePermissions } from "src/hooks";
import FormText from "src/components/formControls/FormText";
import LocalEnhancedTable from "src/components/localTable/LocalTable";
import { technicianQuizBacklogService } from "src/services";
import { AddIcon } from "src/components/icons";
import { Permission as PermissionTypes } from "src/ts/enums";

interface QuestionsProps {
  questionId: number;
  onChange: (e: ITechnicianQuizAnswers[]) => void;
}

const QuestionsCatalogAnswers = (prop: QuestionsProps) => {
  const { log } = useLog();
  const initialValues = {
    id: 0,
    description: "",
    isCorrect: false,
    technicianQuizBacklogId: prop.questionId,
  };

  const [refreshGrid] = useState(false);
  const [answers, setAnswers] = useState<ITechnicianQuizAnswers[]>([]);
  const { fullAccess, readOnly } = usePermissions(PermissionTypes.Quiz);
  const handleDeleteAnswer = async (row: ITechnicianQuizAnswers) => {
    var deletesAnswers = answers.filter((x) => {
      return x !== row;
    });
    setAnswers(deletesAnswers);
    prop.onChange(deletesAnswers);
  };

  const handleAddAnswer = (row: ITechnicianQuizAnswers) => {
    if (!validate()) return;

    const newList = answers.concat(row);
    setAnswers(newList);
    prop.onChange(newList);
    resetForm();
  };

  const handleSelectCorrect = (row: ITechnicianQuizAnswers) => {
    var result = answers.map(function (answer) {
      if (row === answer) answer.isCorrect = true;
      else answer.isCorrect = false;
      return answer;
    });
    setAnswers(result);
    prop.onChange(result);
  };

  const { startRequest, endRequest } = useLoading();

  const validate = (fieldValues = values) => {
    let temp: Record<string, string> = { ...errors };
    if (fieldValues.hasOwnProperty("description")) {
      temp.description = fieldValues.description
        ? ""
        : "This field is required.";
    }

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, errors, setErrors, handleInputChange, resetForm } = useForm(
    initialValues,
    false,
    validate
  );

  useEffect(() => {
    const getData = async () => {
      try {
        startRequest("QuestionsCatalogAnswers");
        const request = await technicianQuizBacklogService.getAnswers(
          prop.questionId
        );
        setAnswers(request.data);
        endRequest("QuestionsCatalogAnswers");
        prop.onChange(request.data);
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
        endRequest("QuestionsCatalogAnswers");
      }
    };

    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshGrid]);

  const columns = (readOnly: boolean, fullAccess: boolean): ColumnType[] => [
    {
      id: "answer",
      label: "Answer",
      type: "custom",
      sort: false,
      callback: (row: ITechnicianQuizAnswers) => {
        return (
          <FormControlLabel
            control={
              <Radio
                checked={row.isCorrect}
                value={row.isCorrect}
                onClick={() => handleSelectCorrect(row)}
                disabled={readOnly}
              />
            }
            label={row.description}
            title="Delete"
          />
        );
      },
    },
    {
      id: "action",
      label: "Actions",
      type: "custom",
      sort: false,
      callback: (row: ITechnicianQuizAnswers) => {
        return (
          <Grid>
            {fullAccess && (
              <IconButton
                aria-label="delete"
                size="small"
                onClick={() => handleDeleteAnswer(row)}
              >
                <DeleteIcon />
              </IconButton>
            )}
          </Grid>
        );
      },
    },
  ];

  return (
    <React.Fragment>
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={9}>
            <FormText
              name="description"
              label={`Answer`}
              value={values.description}
              onChange={handleInputChange}
              error={errors.description}
              disabled={readOnly}
            />
          </Grid>
          <Grid item xs={3}>
            {fullAccess && (
              <IconButton
                onClick={() => handleAddAnswer(values)}
                aria-label="details"
                size="small"
              >
                <AddIcon />
              </IconButton>
            )}
          </Grid>
        </Grid>
      </Grid>
      <LocalEnhancedTable<ITechnicianQuizAnswers>
        refreshGrid={refreshGrid}
        columns={columns(readOnly || true, fullAccess || false)}
        data={answers}
      />
    </React.Fragment>
  );
};

export default QuestionsCatalogAnswers;
