import { IResultsPreviewPursacheProject } from "src/ts/interfaces/project/projectDto";
import { HistoryProjectCostDTO } from "src/ts/interfaces";
import axios from "src/utils/axios";
import { IPurchaseResultMessage } from "src/ts/types";

const getCheckPricing = (projectId: number) => {
  return axios.get<HistoryProjectCostDTO>(
    `ProjectIsComplete/CheckPricing?projectId=${projectId}`
  );
};

const validateDiscounts = (projectId: number, coupons: string[]) => {
  return axios.put<IResultsPreviewPursacheProject>(
    `ProjectIsComplete/previewPursache?projectId=${projectId}`,
    coupons
  );
};

const completeInfect = (projectId: number, comments: string) => {
  return axios.post<IResultsPreviewPursacheProject>(
    `ProjectIsComplete/IsCompleteInfectous?id=${projectId}&superAdminCompletedComments=${comments}`
  );
};

const completePayment = (
  projectId: number,
  endDate: string,
  cart: boolean,
  superAdminComments: string,
  isBomaProject: boolean,
  coupons: string[]
) => {
  return axios.post<IPurchaseResultMessage>(
    `ProjectIsComplete/IsComplete?id=${projectId}&endDate=${endDate}&cart=${cart}&superAdminCompletedComments=${superAdminComments}&isBomaProject=${isBomaProject}`,
    coupons
  );
};

const projectIsCompleteService = {
  getCheckPricing,
  validateDiscounts,
  completePayment,
  completeInfect,
};

export default projectIsCompleteService;
