import {
  CardContent,
  Grid,
  Divider as MuiDivider,
  Card as MuiCard,
} from "@mui/material";
import { spacing } from "@mui/system";
import styled from "@emotion/styled";
import { useAsyncQuery, useAuth, useEffectOnce, useLog } from "src/hooks";
import { useParams } from "react-router";
import { useMemo, useState } from "react";
import { IReportType } from "src/ts/interfaces";
import { IProjectReport } from "src/ts/interfaces/project/projectDto";
import projectReportService from "src/services/study/projectReportService";
import reportTypeService from "src/services/study/reportTypeService";
import StudyWizard from "../../components/StudyWizard";
import { FooterButtons } from "../../components/FooterButtons";
import { steps, studyTypeCode } from "../const/const";
import { useNavigate } from "react-router-dom";
import { FormCheckSwitch } from "src/components/formControls";
import useTowerStudy from "../hooks/useTowerStudy";
import { Mode } from "src/ts/types";
import towerWeigtedTestService from "src/services/study/towerWeigtedTestService";
import {
  ITowerWeigtedTest,
  ITowerWeigtedTestItem,
} from "src/ts/interfaces/study/tower";
import TowerWeigtedTestsList from "./components/TowerWeigtedTestsList";
import TowerWeigtedTestItemGrid from "./components/TowerWeigtedTestItemGrid";
import towerService from "src/services/study/towerService";
import studiesHistoryService from "src/services/study/studiesHistoryService";
import HeaderStudyPage from "src/components/page/HeaderStudyPage";

const Divider = styled(MuiDivider)(spacing);
const Card = styled(MuiCard)(spacing);
const activeStep = 3;

const TowerStep4 = () => {
  const { getTowerStudy, towerValues, handleInputTowerChange } =
    useTowerStudy();

  const { user } = useAuth();
  const { log } = useLog();
  const params = useParams<{ id: string }>();
  const id = parseInt(params?.id === undefined ? "0" : params?.id);
  let navigate = useNavigate();

  const [reportType, setReportType] = useState<IReportType | null>();
  const { execute: executeReport, data: dataReport } =
    useAsyncQuery<IProjectReport>(projectReportService.report);

  const [items, setItems] = useState<ITowerWeigtedTestItem[]>([]);
  const [towerWeigtedTests, setTowerWeigtedTests] = useState<
    ITowerWeigtedTest[]
  >([]);

  const [selectedTowerWeigtedTest, setSelectedTowerWeigtedTest] =
    useState<ITowerWeigtedTest | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  useEffectOnce(() => {
    const getData = async () => {
      try {
        setIsLoading(true);
        await getTowerStudy(id);
        const towerWeigtedTests = await towerWeigtedTestService.getByTowerId(
          id
        );
        setTowerWeigtedTests(towerWeigtedTests.data);

        if (towerWeigtedTests.data.length > 0) {
          setSelectedTowerWeigtedTest(towerWeigtedTests.data[0]);
          const itemsRes =
            await towerWeigtedTestService.getTowerWeigtedTestItem(
              towerWeigtedTests.data[0].id
            );
          setItems(itemsRes.data);
        }

        const reportTypeRes = await reportTypeService.getReportTypeByCode(
          studyTypeCode
        );
        setReportType(reportTypeRes.data);
        await executeReport(id, reportTypeRes.data.code);
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      } finally {
        setIsLoading(false);
      }
    };

    if (id > 0) getData();
  });

  const selectedItemHandler = async (towerWeigtedTest: ITowerWeigtedTest) => {
    if (!towerValues?.isComplete) {
      await towerWeigtedTestService.updateTowerWeigtedTest(towerWeigtedTest);
      await Promise.all(
        items.map(
          async (item) =>
            await towerWeigtedTestService.updateTowerWeigtedTestItem(item)
        )
      );
      towerWeigtedTestService.updateTowerWeigtedTest(towerWeigtedTest);
    }

    setSelectedTowerWeigtedTest(towerWeigtedTest);

    const itemsRes = await towerWeigtedTestService.getTowerWeigtedTestItem(
      towerWeigtedTest.id
    );
    setItems(itemsRes.data);
  };

  const nextStepHandler = async () => {
    const route = `/studies/Tower/step5/${id}`;
    if (disableStudy) {
      navigate(route);
      return;
    }

    await saveStudy();
    navigate(route);
  };

  const previousStepHandler = async () => {
    const route = `/studies/Tower/step3/${id}`;
    if (disableStudy) {
      navigate(route);
      return;
    }
    await saveStudy();
    navigate(route);
  };

  const saveHandler = async () => {
    await saveStudy();
  };

  const saveStudy = async () => {
    try {
      await towerService.update(towerValues.id, towerValues);
      await studiesHistoryService.save(
        dataReport.projectId,
        dataReport.reportTypeId,
        id,
        "saved"
      );
      await towerWeigtedTestService.updateTowerWeigtedTests(towerWeigtedTests);
      const updateItemPromises = items.map(
        async (items) =>
          await towerWeigtedTestService.updateTowerWeigtedTestItem(items)
      );
      await Promise.all(updateItemPromises);
      log.info("Report was save");
    } catch (error: any) {
      log.error(error?.message?.exceptionMessage ?? "Something went wrong");
    } finally {
      //setIsSubmitting(false);
    }
  };

  const mode: Mode = towerValues?.isComplete ? "read" : "read&Write";

  const disableStudy = useMemo(() => {
    return (
      towerValues?.isComplete ||
      (dataReport?.isInUse && dataReport?.isInUseById !== user?.userId) ||
      dataReport?.isPartOfProject === false
    );
  }, [
    dataReport?.isInUse,
    dataReport?.isInUseById,
    dataReport?.isPartOfProject,
    user?.userId,
    towerValues?.isComplete,
  ]);

  return (
    <>
      <HeaderStudyPage
        headerStudiesPage={{
          code: "TOWE",
          system: towerValues?.system,
          id: id,
        }}
        parentText="Project"
        parentLink="/"
      />
      <Divider my={6} />

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <StudyWizard
            steps={steps}
            activeStep={activeStep}
            projectId={dataReport?.projectId}
            reportId={dataReport?.reportId}
            reportTypeId={dataReport?.reportTypeId}
          />
        </Grid>
        <Grid item xs={12}>
          <Card mb={1}>
            <CardContent>
              <Grid container spacing={5}>
                <Grid item xs={12}></Grid>
                <Grid item xs={12}>
                  <FormCheckSwitch
                    label="Cooling Tower Sound Pressure Testing"
                    name="coolingTowerSoundPressureTesting"
                    value={towerValues.coolingTowerSoundPressureTesting}
                    onChange={handleInputTowerChange}
                    mode={mode}
                    disabled={disableStudy}
                    showSkeleton={isLoading}
                  />
                </Grid>
                {towerValues.coolingTowerSoundPressureTesting && (
                  <>
                    <Grid item xs={12}>
                      <TowerWeigtedTestsList
                        towerWeigtedTests={towerWeigtedTests}
                        selectedTowerWeigtedTest={selectedTowerWeigtedTest}
                        selectedItemHandler={selectedItemHandler}
                        setTowerWeigtedTests={setTowerWeigtedTests}
                        disabledStudy={disableStudy}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TowerWeigtedTestItemGrid
                        items={items}
                        setItems={setItems}
                        towerValues={towerValues}
                        dataReport={dataReport}
                        selectedTowerWeigtedTest={selectedTowerWeigtedTest}
                        towerWeigtedTests={towerWeigtedTests}
                        mode={mode}
                        disableStudy={disableStudy}
                        isLoading={isLoading}
                      />
                    </Grid>
                  </>
                )}

                <Grid item xs={12}>
                  <FooterButtons
                    activeStep={activeStep}
                    stepsCount={steps.length}
                    projectId={dataReport?.projectId}
                    companyId={user?.companyId as number}
                    isSaving={false}
                    disabled={false}
                    reportName={reportType?.name ?? ""}
                    previousStepHandler={previousStepHandler}
                    saveHandler={saveHandler}
                    nextStepHandler={nextStepHandler}
                    hideSaveButton={disableStudy}
                    isComplete={towerValues?.isComplete ?? true}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default TowerStep4;
