import Popup from "src/components/Popup";
import { FormButton } from "src/components/formControls";
import { Stack, Typography } from "@mui/material";
import { useEffect } from "react";
import { useAsyncQuery, useLoading, useLog } from "src/hooks";
import templateServices from "src/services/templateServices";
import { IPreferredTemplate } from "src/ts/interfaces/template";
import mandatoryMinimumStudyService from "src/services/mandatoryMinimumStudyService";
import projectDuplicateService from "src/services/project/projectDuplicateService";
import projectService from "src/services/project/projectService";
import { ProjectDTO } from "src/ts/interfaces/project/projectDto";

interface UseMyPreferredTemplateProps {
  useMyPreferredTemplateShowPopUp: boolean;
  setUseMyPreferredTemplateShowPopUp: (val: boolean) => void;
  projectId: number;
  project: ProjectDTO;
  setProject: (project: ProjectDTO) => void;
  selectTemplate?: number;
  setSelectTemplateShowPopUp: (val: boolean) => void;
  templateName?: string;
}

export default function UseMyPreferredTemplatePopUp({
  useMyPreferredTemplateShowPopUp,
  setUseMyPreferredTemplateShowPopUp,
  projectId,
  project,
  setProject,
  selectTemplate,
  setSelectTemplateShowPopUp,
  templateName,
}: UseMyPreferredTemplateProps) {
  const { log } = useLog();
  const { execute, data } = useAsyncQuery<IPreferredTemplate>(
    templateServices.getPreferred
  );

  const { startRequest, endRequest, isLoading } = useLoading();

  useEffect(() => {
    execute();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const acceptHandler = async () => {
    startRequest("AcceptLoading");

    try {
      const responseTemplateProject = await templateServices.getProject(
        selectTemplate === undefined ? data.id : selectTemplate
      );

      const dataProject = responseTemplateProject.data;

      const tolerances = () => {
        return {
          diffuserRegisterGrilleToleranceTop:
            dataProject.diffuserRegisterGrilleToleranceTop,
          diffuserRegisterGrilleToleranceBottom:
            dataProject.diffuserRegisterGrilleToleranceBottom,
          requiredCFMSystemToleranceTop:
            dataProject.requiredCFMSystemToleranceTop,
          requiredCFMSystemToleranceBottom:
            dataProject.requiredCFMSystemToleranceBottom,
          outsideAirVentilationRateTop:
            dataProject.outsideAirVentilationRateTop,
          outsideAirVentilationRateBottom:
            dataProject.outsideAirVentilationRateBottom,
          hydronicTerminalDevicesToleranceTop:
            dataProject.hydronicTerminalDevicesToleranceTop,
          hydronicTerminalDevicesToleranceBottom:
            dataProject.hydronicTerminalDevicesToleranceBottom,
          requiredGPMSystemToleranceTop:
            dataProject.requiredGPMSystemToleranceTop,
          requiredGPMSystemToleranceBottom:
            dataProject.requiredGPMSystemToleranceBottom,
          equipmentCalibrationReportRequired:
            dataProject.equipmentCalibrationReportRequired,
        };
      };

      await mandatoryMinimumStudyService.updateTemplate(
        responseTemplateProject.data.id,
        projectId
      );

      const responseStudies = await templateServices.getStudies(
        selectTemplate === undefined ? data.id : selectTemplate
      );

      if (responseStudies.data !== null && responseStudies.data.length > 0) {
        await projectDuplicateService.duplicateStudiesNewProject(
          projectId,
          responseStudies.data
        );
      }

      await projectService.putTemplate(projectId, {
        ...project,
        templateName: templateName === undefined ? data.name : templateName,
        tolerancesWereSaved: false,
        templateWasUsed: true,
        ...tolerances(),
      });

      await projectService.saveTolerances(projectId, {
        ...project,
        templateName: templateName === undefined ? data.name : templateName,
        tolerancesWereSaved: true,
        templateWasUsed: true,
        visibleToAllContractors: true,
        ...tolerances(),
      });

      setProject({
        ...project,
        templateName: templateName === undefined ? data.name : templateName,
        tolerancesWereSaved: true,
        templateWasUsed: true,
        visibleToAllContractors: true,
        ...tolerances(),
      });
      endRequest("AcceptLoading");
      log.success("Template was applied successfully");
      setUseMyPreferredTemplateShowPopUp(false);
      setSelectTemplateShowPopUp(false);
    } catch (error: any) {
      endRequest("AcceptLoading");
      log.error(error?.message?.exceptionMessage ?? "Something went wrong");
    }
  };

  return (
    <>
      <Popup
        title={"Information"}
        openPopup={useMyPreferredTemplateShowPopUp}
        setOpenPopup={setUseMyPreferredTemplateShowPopUp}
        onClose={() => {
          setUseMyPreferredTemplateShowPopUp(false);
        }}
        size="sm"
      >
        <>
          <Typography>
            After applying the template, your current tolerance and study
            settings will be removed, and you will acquire the default values
            defined in the selected template.
          </Typography>
        </>
        <Stack direction="row" spacing={3}>
          <FormButton
            size="small"
            text="Cancel"
            variant="outlined"
            onClick={() => {
              setUseMyPreferredTemplateShowPopUp(false);
            }}
            disabled={isLoading}
          />
          <FormButton
            size="small"
            text="Accept"
            color="primary"
            onClick={acceptHandler}
            isSubmitting={isLoading}
          />
        </Stack>
      </Popup>
    </>
  );
}
