import React, { useEffect, useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import {
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent,
  Divider as MuiDivider,
  Grid,
  IconButton,
  Link,
  Typography,
} from "@mui/material";
import DropZone from "src/components/DropZone";
import { Helmet } from "react-helmet-async";
import { Box, spacing } from "@mui/system";
import styled from "@emotion/styled";
import LocalEnhancedTable from "src/components/localTable/LocalTable";
import { IBuildingPhotoDocument } from "src/ts/interfaces";
import { ColumnType } from "src/types/enhancedTable";
import { FileDownloadIcon, DeleteIcon } from "src/components/icons";
import FileUtils from "src/utils/file";
import { useLog } from "src/hooks";
import buildingPhotoService from "src/services/buildingPhotoService";
import MatchWordConfirmModal from "src/components/modals/MatchWordConfirmModal";
import "react-image-crop/dist/ReactCrop.css";
import DialogMessagePopup from "src/components/DialogMessagePopup";
import FormCropImage from "src/components/formControls/FormCropImage";
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const getColumns = (
  handleDeletePhoto: (row: IBuildingPhotoDocument) => void,
  downloadPhoto: (row: IBuildingPhotoDocument) => void
): ColumnType[] => [
  {
    id: "id",
    label: "Id",
    type: "string",
    sort: false,
    hide: true,
  },
  {
    id: "name",
    label: "Name",
    type: "string",
    sort: true,
  },
  {
    id: "status",
    label: "Actions",
    type: "custom",
    sort: false,
    callback: (row: IBuildingPhotoDocument) => {
      return (
        <div>
          <Box mr={2}>
            <IconButton
              aria-label="Download"
              size="large"
              onClick={() => downloadPhoto(row)}
            >
              <FileDownloadIcon />
            </IconButton>

            <IconButton
              aria-label="Delete"
              size="large"
              onClick={() => handleDeletePhoto(row)}
            >
              <DeleteIcon />
            </IconButton>
          </Box>
        </div>
      );
    },
  },
];

type BuldingPhotoParams = {
  id: string;
  companyId: string;
};

const BuildingPhoto = () => {
  let { id } = useParams<BuldingPhotoParams>();
  const buildingId = parseInt(id === undefined ? "0" : id);
  let { companyId } = useParams<BuldingPhotoParams>();

  const [refreshPage, setRefreshPage] = useState(false);
  const [buildingPhoto, setBuildingPhoto] = useState<IBuildingPhotoDocument[]>(
    []
  );
  const [disablePhotoUploader, setDisablePhotoUploader] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submittingText, setSubmittingText] = useState("");
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [currentRow, setCurrentRow] = useState<IBuildingPhotoDocument>();
  const { log } = useLog();
  const [imgSrc, setImgSrc] = useState("");
  const [fileName, setfileName] = useState("");
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    const getBuildingPhotoDocument = async (buildingId: number) => {
      try {
        setIsSubmitting(true);
        const request = await buildingPhotoService.getPhoto(buildingId);
        setDisablePhotoUploader(request.data.length >= 1);
        setBuildingPhoto(request.data);
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      } finally {
        setIsSubmitting(false);
      }
    };
    getBuildingPhotoDocument(Number(buildingId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buildingId, refreshPage]);

  const saveFile = async (file: string) => {
    try {
      setIsSubmitting(true);
      window.scrollTo(0, 0);
      await buildingPhotoService.uploadPhoto(
        {
          buildingId: buildingId,
          content: file,
        },
        fileName
      );
      setfileName("");
      setImgSrc("");
      setRefreshPage(!refreshPage);
      setShowPopup(true);
      setIsSubmitting(false);
    } catch (error: any) {
      log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      setIsSubmitting(false);
    }
  };

  const deleteDocument = async () => {
    if (currentRow && currentRow.id) {
      try {
        setIsSubmitting(true);
        await buildingPhotoService.deletePhoto(currentRow.id);
        setRefreshPage(!refreshPage);
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      } finally {
        setIsSubmitting(false);
      }
    } else {
      log.error("currentRow is undefined");
    }
  };

  const downloadDocument = async (row: IBuildingPhotoDocument) => {
    if (row && row.id) {
      try {
        setIsSubmitting(true);
        setSubmittingText("Downloading file ...");
        const response = await buildingPhotoService.downloadPhoto(row.id);
        FileUtils.downloadFile(response.data, row.name);
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      } finally {
        setIsSubmitting(false);
        setSubmittingText("");
      }
    } else {
      log.error("currentRow is undefined");
    }
  };

  const handleDeleteDocument = (row: IBuildingPhotoDocument) => {
    setCurrentRow(row);
    setShowDeleteModal(true);
  };

  const handleLogoFileAccepted = async (file: File) => {
    if (file) {
      setfileName(file.name);
      const reader = new FileReader();
      reader.addEventListener("load", () =>
        setImgSrc(reader.result?.toString() || "")
      );
      reader.readAsDataURL(file);
    }
  };

  const columns = getColumns(handleDeleteDocument, downloadDocument);

  return (
    <React.Fragment>
      <Helmet title="Building Picture" />

      <Typography variant="h3" gutterBottom display="inline">
        Building Picture
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/">
          Dashboard
        </Link>
        <Link component={NavLink} to={`/app/Buildings/${companyId}`}>
          Buildings
        </Link>
        <Typography>Building Picture</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Card mb={6}>
            <CardContent>
              <Grid container spacing={6}>
                <Grid item md={12}>
                  <LocalEnhancedTable<IBuildingPhotoDocument>
                    refreshGrid={false}
                    columns={columns}
                    data={buildingPhoto}
                    hidePagination={true}
                  />
                  <DropZone
                    onFileAccepted={handleLogoFileAccepted}
                    accept={{ "image/*": [".jpeg", ".jpg", ".png"] }}
                    disabled={disablePhotoUploader}
                    disableMessage="Only one picture is allowed"
                    submiting={isSubmitting}
                    submittingMessage={submittingText}
                    hide={false}
                  />
                  <DialogMessagePopup
                    title={"Information"}
                    text={
                      "The building picture will be updated in the completed projects and reports after a company\nadministrator deletes the PDF file. This action will result in the recreation of the PDF\ndocument(s) when they are downloaded next time."
                    }
                    showPopup={showPopup}
                    setShowPopup={setShowPopup}
                    onSave={() => {
                      setShowPopup(false);
                    }}
                    isSubmitting={false}
                    hideCancel={true}
                  />
                </Grid>
                <FormCropImage
                  save={saveFile}
                  imgSrc={imgSrc}
                  isSubmitting={isSubmitting}
                  lockedResize={false}
                ></FormCropImage>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <MatchWordConfirmModal
        onConfirm={deleteDocument}
        text={`Are you certain you want to delete this item?`}
        matchWord={"delete"}
        isDialogOpen={showDeleteModal}
        setIsDialogOpen={setShowDeleteModal}
      />
    </React.Fragment>
  );
};

export default BuildingPhoto;
