import axios from "src/utils/axios";
import { IDefaultHorsePower } from "src/ts/interfaces/catalogs";

const getAll = () => {
  return axios.get<IDefaultHorsePower>("DefaultHorsePower");
};

const getAllForTraining = (id: number, motorType: "fanMotor" | "motor") => {
  return axios.get<IDefaultHorsePower>(
    `DefaultHorsePower?${motorType}ManufacturerId=${id}`
  );
};

const defaultHorsePowerService = {
  getAll,
  getAllForTraining,
};

export default defaultHorsePowerService;
