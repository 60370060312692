import axios from "src/utils/axios";
import {
  ICompanyDocument,
  IUploadCompanyFile,
  SendDocument,
} from "src/ts/interfaces";

const uploadFile = async (file: IUploadCompanyFile) => {
  let bodyFormData = new FormData();
  bodyFormData.append("fileForUpload[0]", file.content);
  bodyFormData.append("key[type]", file.documentType);
  bodyFormData.append("key[companyId]", file.companyId.toString());

  return await axios({
    method: "post",
    url: "/CompanyDocument/document",
    data: bodyFormData,
    headers: { "Content-Type": "multipart/form-data" },
  });
};

const uploadFileLogo = async (file: IUploadCompanyFile) => {
  let bodyFormData = new FormData();
  bodyFormData.append("fileForUpload", file.content);
  bodyFormData.append("key[type]", file.documentType);
  bodyFormData.append("key[companyId]", file.companyId.toString());

  return await axios({
    method: "post",
    url: "/CompanyDocument/logo",
    data: bodyFormData,
    headers: { "Content-Type": "multipart/form-data" },
  });
};

const companyDocuments = async (companyId: number) => {
  return axios.get<ICompanyDocument[]>(
    `CompanyDocument/documents?id=${companyId}`
  );
};

const deleteDocument = async (documentId: number) => {
  return axios.delete(`CompanyDocument?id=${documentId}`);
};

const downloadFile = async (documentId: number) => {
  return axios.get(`CompanyDocument/file?id=${documentId}`);
};

const sendEmailDocuments = (data: SendDocument) => {
  return axios.post(`CompanyDocument/SendDocuments`, data);
};

const companyDocumentService = {
  sendEmailDocuments,
  uploadFile,
  companyDocuments,
  deleteDocument,
  downloadFile,
  uploadFileLogo,
};

export default companyDocumentService;
