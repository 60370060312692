import axios from "../utils/axios";
import {
  IPictureLogDto,
  IProjectPictureLog,
  IUploadDocumentLibraryFile,
} from "../ts/interfaces";

const uploadFile = async (file: IUploadDocumentLibraryFile) => {
  let bodyFormData = new FormData();

  bodyFormData.append("fileForUpload[0]", file.content);
  bodyFormData.append("key[type]", file.type);
  bodyFormData.append("key[projectId]", file.projectId);

  return await axios({
    method: "post",
    url: "/PictureLog/document",
    data: bodyFormData,
    headers: { "Content-Type": "multipart/form-data" },
  });
};

const getByProject = (projectId: Number) => {
  return axios.get<IPictureLogDto>(`pictureLog?projectId=${projectId}`);
};

const downloadFile = (documentId: number) => {
  return axios.get<IPictureLogDto>(`PictureLog/${documentId}`);
};

const update = (pictureLog: IPictureLogDto) => {
  return axios.put(`pictureLog/${pictureLog.id}`, pictureLog);
};

const removePicture = (pictureLogId: number) => {
  return axios.delete(`pictureLog/file/${pictureLogId}`);
};

const getPictureLogSummary = async () => {
  return axios.get<IProjectPictureLog[]>(`pictureLog/storageMetrics/`);
};

const pictureLogService = {
  uploadFile,
  getByProject,
  downloadFile,
  update,
  removePicture,
  getPictureLogSummary,
};
export default pictureLogService;
