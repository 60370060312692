export const getQueryString = (filters: Record<string, any>) => {
  if (!filters) return "";

  if (filters["stateId"] === Number.MIN_SAFE_INTEGER) filters["stateId"] = 0;
  if (filters["search"] === "") filters["search"] = undefined;
  if (filters["zipCode"] === "") filters["zipCode"] = undefined;
  if (filters["buildingName"] === "") filters["buildingName"] = undefined;
  if (filters["projectCode"] === "") filters["projectCode"] = undefined;
  if (filters["stateId"] === -1) filters["stateId"] = 0;

  return Object.keys(filters)
    .filter((key) => {
      return filters[key] !== null && filters[key] !== Number.MIN_SAFE_INTEGER;
    })
    .map((key) => {
      return `${key}=${filters[key]}`;
    })
    .join("&");
};
