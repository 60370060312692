import React from "react";
import { ColumnType } from "src/types/enhancedTable";
import LocalEnhancedTable from "../localTable/LocalTable";
import { ITimeCard } from "src/ts/interfaces";

const getColumns = (totalRowNumber: number): ColumnType[] => {
  return [
    {
      id: "id",
      label: "#",
      type: "custom",
      sort: false,
      callback: (row: ITimeCard, col: any, index: number) =>
        totalRowNumber - index,
    },
    { id: "hours", label: "Hours", type: "string", sort: false },
    {
      id: "userName",
      label: "Created By",
      type: "string",
      sort: false,
    },
    {
      id: "createdDate",
      label: "Saved Date",
      type: "utcDate",
      sort: false,
      format: "MM/DD/yyyy",
    },
    { id: "comments", label: "Comment", type: "string", sort: false },
  ];
};

interface TimeCardGridProps {
  data: ITimeCard[] | undefined;
}

const TimeCardGrid = ({ data }: TimeCardGridProps) => {
  const columns = getColumns(data?.length ?? 0);
  return (
    <>
      {data && (
        <LocalEnhancedTable<ITimeCard>
          columns={columns}
          data={data}
          showSkeleton={false}
          defaultRowPerPage={10}
          defaultSortColumn="id"
          orderColumn="desc"
        />
      )}
    </>
  );
};

export default TimeCardGrid;
