import React, { useEffect } from "react";
import { Grid } from "@mui/material";
import { useForm, Form } from "src/hooks";
import { FormActionButton } from "src/components/formControls";
import { FormDatePicker } from "src/components/formControls";
import { Validator } from "src/ts/types/Validator";
import moment from "moment";
import { SystemWalletSummaryFilterType } from "src/ts/interfaces";

const initialValues: SystemWalletSummaryFilterType = {
  startDate: moment().startOf("month").format("MM/DD/YYYY"),
  endDate: moment().format("MM/DD/YYYY"),
};

interface Props {
  onSearch: (val: SystemWalletSummaryFilterType) => void;
}

const SystemWalletSummaryFilter = (props: Props) => {
  const { onSearch } = props;

  const validate = (fieldValues = values) => {
    let temp: Record<string, string> = { ...errors };

    temp.startDate = new Validator(fieldValues, "startDate")
      .isRequired("The date is required.")
      .toString();

    temp.endDate = new Validator(fieldValues, "endDate")
      .isRequired("The date is required.")
      .toString();

    const end = new Date(fieldValues.endDate);
    const start = new Date(fieldValues.startDate);
    if (fieldValues.endDate && fieldValues.startDate) {
      if (end < start) {
        temp.startDate = `Start date can not be greater than  ${moment(
          end
        ).format("MM/DD/YYYY")}`;
        temp.endDate = `End date can not be less than  ${moment(start).format(
          "MM/DD/YYYY"
        )}`;
      } else {
        temp.startDate = "";
        temp.endDate = "";
      }
    }

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, errors, setErrors, handleInputChange, setValues } = useForm(
    initialValues,
    false,
    validate
  );

  useEffect(() => {
    onSearch(values);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const refreshHandler = () => {
    if (!validate()) return;
    onSearch(values);
  };

  const validateMinDate = (e: any) => {
    if (e.target.value !== "Invalid date") {
      let newValues = { ...values };
      newValues.startDate = e.target.value;

      if (Date.parse(newValues.endDate) < Date.parse(newValues.startDate))
        setValues({ ...newValues, endDate: newValues.startDate });
      else setValues({ ...newValues });
    }
  };

  return (
    <Form>
      <Grid container spacing={5}>
        <Grid item xs={5}>
          <FormDatePicker
            name="startDate"
            label="Date From"
            value={values.startDate}
            onChange={validateMinDate}
            error={errors.startDate}
          />
        </Grid>
        <Grid item xs={5}>
          <FormDatePicker
            name="endDate"
            label="Date To"
            value={values.endDate}
            onChange={handleInputChange}
            error={errors.endDate}
            minDate={values.from}
          />
        </Grid>
        <Grid item xs={2}>
          <FormActionButton
            mr={2}
            text="Search"
            size="medium"
            onClick={refreshHandler}
            isSubmitting={false}
            type="search"
          />
        </Grid>
      </Grid>
    </Form>
  );
};

export default SystemWalletSummaryFilter;
