import { Alert, Grid } from "@mui/material";
import {
  FormCheckBox,
  FormNumeric2,
  FormSelect,
} from "src/components/formControls";
import { IChillerCompressor } from "src/ts/interfaces";
import { useState } from "react";
import { Stack } from "@mui/system";
import { Mode } from "src/ts/types";

import useChillerStudy from "../hooks/useChillerStudy";

interface IActualAmpsValid {
  line1ActualAmpsValid: boolean;
  line2ActualAmpsValid: boolean;
  line3ActualAmpsValid: boolean;
}

export const motorPhaseOptions = [
  { key: "Three phase", value: "Three phase" },
  { key: "2 wire single phase", value: "2 wire single phase" },
];

export const hertzOptions = [
  { key: 50, value: "50 HZ" },
  { key: 60, value: "60 HZ" },
];

const compressorTypeOptions = [
  { key: "Centrifugal", value: "Centrifugal" },
  { key: "Screw", value: "Screw" },
  { key: "Rotating", value: "Rotating" },
  { key: "Reciprocating", value: "Reciprocating" },
];

interface Props {
  mode: Mode;
  disableStudy: boolean;
  isLoading: boolean;
  updateCompressor: () => Promise<void>;
}

const CompressorActualForm = ({
  mode,
  disableStudy,
  isLoading,
  updateCompressor,
}: Props) => {
  const {
    compressorValues,
    handleCompressorInputChange,
    compressorErros,
    setCompressorValues,
    evaporatorValues,
  } = useChillerStudy();

  const showSelect = compressorValues.motorPhase !== "Single phase";
  const showL2 =
    compressorValues.motorPhase === "Three phase" ||
    (compressorValues.motorPhase === "2 wire single phase" &&
      compressorValues.additionalVolAmpValue >= 1);
  const showL3 =
    compressorValues.motorPhase === "Three phase" ||
    (compressorValues.motorPhase === "2 wire single phase" &&
      compressorValues.additionalVolAmpValue === 2);
  const showL4 =
    compressorValues.motorPhase === "Three phase" &&
    compressorValues.additionalVolAmpValue >= 1;
  const showL5 =
    compressorValues.motorPhase === "Three phase" &&
    compressorValues.additionalVolAmpValue >= 2;
  const showL6 =
    compressorValues.motorPhase === "Three phase" &&
    compressorValues.additionalVolAmpValue === 3;

  const row = 8;

  const voltAmpsReferenceClick = (reference: number) => {
    setCompressorValues({
      ...compressorValues,
      selectedVoltAmpsReference: reference,
      voltageNotListed: false,
    });
  };

  const voltageNotListedClick = (e: any) => {
    setCompressorValues({
      ...compressorValues,
      selectedVoltAmpsReference: null,
      [e.target.name]: e.target.value,
    });
  };

  const voltsAmpsInputDataValidation = (e: any) => {
    if (
      (compressorValues.selectedVoltAmpsReference == null ||
        compressorValues.selectedVoltAmpsReference === 0) &&
      compressorValues.voltageNotListed === false
    ) {
      //User has not selected a Volts/Amps set reference or Voltage Not Listed checkbox and tries to enter any actual value
      const newFanMotor = {
        ...compressorValues,
        actualVoltsPhase1: null,
        actualVoltsPhase2: null,
        actualVoltsPhase3: null,
        actualAmpsPhase1: null,
        actualAmpsPhase2: null,
        actualAmpsPhase3: null,
      };
      setCompressorValues(newFanMotor);
    } else if (
      compressorValues?.selectedVoltAmpsReference &&
      compressorValues.selectedVoltAmpsReference > 0
    ) {
      const newFanMotor: IChillerCompressor = {
        ...compressorValues,
        [e.target.name]: parseFloat(e.target.value),
      };
      setCompressorValues(newFanMotor);
      validateActualAmps(newFanMotor);
    }
  };

  const [actualAmpsValid, setActualAmpsValid] = useState<IActualAmpsValid>({
    line1ActualAmpsValid: true,
    line2ActualAmpsValid: true,
    line3ActualAmpsValid: true,
  });

  const validateActualAmps = (fanMotor: IChillerCompressor) => {
    // User has selected a Volts/Amps set reference and is entering data in actual values,
    // so we have to know if the actual amps are valid per the reference value

    const actualAmpsPhase1 = fanMotor?.actualAmpsPhase1 ?? 0;
    const actualAmpsPhase2 = fanMotor?.actualAmpsPhase2 ?? 0;
    const actualAmpsPhase3 = fanMotor?.actualAmpsPhase3 ?? 0;

    let line1ActualAmpsValid = false;
    let line2ActualAmpsValid = false;
    let line3ActualAmpsValid = false;

    switch (compressorValues.selectedVoltAmpsReference) {
      case 1:
        //Comparing to the first reference set
        line1ActualAmpsValid =
          actualAmpsPhase1 <= (fanMotor?.nameplateAmpsPhase1 ?? 0);
        line2ActualAmpsValid =
          actualAmpsPhase2 <= (fanMotor?.nameplateAmpsPhase1 ?? 0);
        line3ActualAmpsValid =
          actualAmpsPhase3 <= (fanMotor?.nameplateAmpsPhase1 ?? 0);
        break;
      case 2:
        //Comparing to the first reference set
        line1ActualAmpsValid =
          actualAmpsPhase1 <= (fanMotor?.nameplateAmpsPhase2 ?? 0) &&
          (fanMotor?.nameplateAmpsPhase2 ?? 0) > 0;
        line2ActualAmpsValid =
          actualAmpsPhase2 <= (fanMotor?.nameplateAmpsPhase2 ?? 0) &&
          (compressorValues.nameplateAmpsPhase2 ?? 0) > 0;
        line3ActualAmpsValid =
          actualAmpsPhase3 <= (fanMotor?.nameplateAmpsPhase2 ?? 0) &&
          (fanMotor?.nameplateAmpsPhase2 ?? 0) > 0;
        break;
      case 3:
        //Comparing to the first reference set
        line1ActualAmpsValid =
          actualAmpsPhase1 <= (fanMotor?.nameplateAmpsPhase3 ?? 0) &&
          (fanMotor?.nameplateAmpsPhase3 ?? 0) > 0;
        line2ActualAmpsValid =
          actualAmpsPhase2 <= (compressorValues.nameplateAmpsPhase3 ?? 0) &&
          (fanMotor?.nameplateAmpsPhase3 ?? 0) > 0;
        line3ActualAmpsValid =
          actualAmpsPhase3 <= (fanMotor?.nameplateAmpsPhase3 ?? 0) &&
          (fanMotor?.nameplateAmpsPhase3 ?? 0) > 0;
        break;
      case 4:
        //Comparing to the first reference set
        line1ActualAmpsValid =
          actualAmpsPhase1 <= (fanMotor?.nameplateAmpsPhase4 ?? 0) &&
          (fanMotor?.nameplateAmpsPhase4 ?? 0) > 0;
        line2ActualAmpsValid =
          actualAmpsPhase2 <= (fanMotor?.nameplateAmpsPhase4 ?? 0) &&
          (fanMotor?.nameplateAmpsPhase4 ?? 0) > 0;
        line3ActualAmpsValid =
          actualAmpsPhase3 <= (fanMotor?.nameplateAmpsPhase4 ?? 0) &&
          (fanMotor?.nameplateAmpsPhase4 ?? 0) > 0;
        break;
      case 5:
        //Comparing to the first reference set
        line1ActualAmpsValid =
          actualAmpsPhase1 <= (fanMotor?.nameplateAmpsPhase5 ?? 0) &&
          (fanMotor?.nameplateAmpsPhase5 ?? 0) > 0;
        line2ActualAmpsValid =
          actualAmpsPhase2 <= (fanMotor?.nameplateAmpsPhase5 ?? 0) &&
          (fanMotor?.nameplateAmpsPhase5 ?? 0) > 0;
        line3ActualAmpsValid =
          actualAmpsPhase3 <= (fanMotor?.nameplateAmpsPhase5 ?? 0) &&
          (fanMotor?.nameplateAmpsPhase5 ?? 0) > 0;
        break;
      case 6:
        //Comparing to the first reference set
        line1ActualAmpsValid =
          actualAmpsPhase1 <= (fanMotor?.nameplateAmpsPhase6 ?? 0) &&
          (fanMotor?.nameplateAmpsPhase6 ?? 0) > 0;
        line2ActualAmpsValid =
          actualAmpsPhase2 <= (fanMotor?.nameplateAmpsPhase6 ?? 0) &&
          (fanMotor?.nameplateAmpsPhase6 ?? 0) > 0;
        line3ActualAmpsValid =
          actualAmpsPhase3 <= (fanMotor?.nameplateAmpsPhase6 ?? 0) &&
          (fanMotor?.nameplateAmpsPhase6 ?? 0) > 0;
        break;
    }

    setActualAmpsValid({
      line1ActualAmpsValid,
      line2ActualAmpsValid,
      line3ActualAmpsValid,
    });
  };

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={8}>
          <FormNumeric2
            name="compressorNumber"
            value={compressorValues.compressorNumber ?? 0}
            error={compressorErros.compressorNumber}
            label="Compressor Number"
            onChange={handleCompressorInputChange}
            showSkeleton={isLoading}
            mode={mode}
            disabled={disableStudy}
            decimalScale={0}
            maxValue={1000000000}
          />
        </Grid>
        <Grid item xs={row}>
          <FormSelect
            name={"compressorType"}
            value={compressorValues?.compressorType ?? 0}
            label="Compressor Type"
            onChange={handleCompressorInputChange}
            options={compressorTypeOptions}
            showSkeleton={isLoading}
            defaultValue={{ key: 0, value: "Select a value" }}
            error={compressorErros.compressorType}
            mode={mode}
            disable={disableStudy}
          />
        </Grid>
        {evaporatorValues.needRegrigerantPressures && (
          <Grid item xs={8}>
            <FormNumeric2
              name="lowSideSuctionPressure"
              value={compressorValues.lowSideSuctionPressure ?? 0}
              error={compressorErros.lowSideSuctionPressure}
              label="Low Side Suction Pressure PSI"
              onChange={handleCompressorInputChange}
              showSkeleton={isLoading}
              mode={mode}
              disabled={disableStudy}
              decimalScale={3}
              maxValue={1000000000}
              fixedDecimalScale={true}
            />
          </Grid>
        )}
        {evaporatorValues.needRegrigerantPressures && (
          <Grid item xs={8}>
            <FormNumeric2
              name="highSideDischargePressure"
              value={compressorValues.highSideDischargePressure ?? 0}
              error={compressorErros.highSideDischargePressure}
              label="High Side Discharge Pressure PSI"
              onChange={handleCompressorInputChange}
              showSkeleton={isLoading}
              mode={mode}
              disabled={disableStudy}
              decimalScale={3}
              maxValue={1000000000}
              fixedDecimalScale={true}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          {showSelect && (
            <Grid container spacing={5}>
              <Grid item xs={3}>
                <Stack direction="row" justifyContent="flex-end" pt={2}>
                  Select Voltage that best represents Actual Value
                </Stack>
              </Grid>
              <Grid item xs={1}>
                <FormCheckBox
                  name="reference1"
                  value={compressorValues.selectedVoltAmpsReference === 1}
                  onChange={() => voltAmpsReferenceClick(1)}
                  label={undefined}
                  mode={mode}
                  disabled={disableStudy}
                  showSkeleton={isLoading}
                />
              </Grid>
              {showL2 && (
                <Grid item xs={1}>
                  <FormCheckBox
                    name="reference2"
                    value={compressorValues.selectedVoltAmpsReference === 2}
                    onChange={() => voltAmpsReferenceClick(2)}
                    label={undefined}
                    mode={mode}
                    disabled={disableStudy}
                    showSkeleton={isLoading}
                  />
                </Grid>
              )}
              {showL3 && (
                <Grid item xs={1}>
                  <FormCheckBox
                    name="reference3"
                    value={compressorValues.selectedVoltAmpsReference === 3}
                    onChange={() => voltAmpsReferenceClick(3)}
                    label={undefined}
                    mode={mode}
                    disabled={disableStudy}
                    showSkeleton={isLoading}
                  />
                </Grid>
              )}
              {showL4 && (
                <Grid item xs={1}>
                  <FormCheckBox
                    name="reference4"
                    value={compressorValues.selectedVoltAmpsReference === 4}
                    onChange={() => voltAmpsReferenceClick(4)}
                    label={undefined}
                    mode={mode}
                    disabled={disableStudy}
                    showSkeleton={isLoading}
                  />
                </Grid>
              )}
              {showL5 && (
                <Grid item xs={1}>
                  <FormCheckBox
                    name="reference5"
                    value={compressorValues.selectedVoltAmpsReference === 5}
                    onChange={() => voltAmpsReferenceClick(5)}
                    label={undefined}
                    mode={mode}
                    disabled={disableStudy}
                    showSkeleton={isLoading}
                  />
                </Grid>
              )}
              {showL6 && (
                <Grid item xs={1}>
                  <FormCheckBox
                    name="reference6"
                    value={compressorValues.selectedVoltAmpsReference === 6}
                    onChange={() => voltAmpsReferenceClick(6)}
                    label={undefined}
                    mode={mode}
                    disabled={disableStudy}
                    showSkeleton={isLoading}
                  />
                </Grid>
              )}

              <Grid item xs={2}>
                <FormCheckBox
                  name="voltageNotListed"
                  value={compressorValues.voltageNotListed}
                  onChange={(e) => voltageNotListedClick(e)}
                  label="Voltage Not Listed"
                  mode={mode}
                  disabled={disableStudy}
                  showSkeleton={isLoading}
                />
              </Grid>
            </Grid>
          )}
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={5}>
            <Grid item xs={3}>
              <Stack direction="row" justifyContent="flex-end">
                Nameplate Volts
              </Stack>
            </Grid>
            <Grid item xs={1}>
              {compressorValues.nameplateVoltsPhase1}V
            </Grid>
            {showL2 && (
              <Grid item xs={1}>
                {compressorValues.nameplateVoltsPhase2}V
              </Grid>
            )}
            {showL3 && (
              <Grid item xs={1}>
                {compressorValues.nameplateVoltsPhase3}V
              </Grid>
            )}
            {showL4 && (
              <Grid item xs={1}>
                {compressorValues.nameplateVoltsPhase4}V
              </Grid>
            )}
            {showL5 && (
              <Grid item xs={1}>
                {compressorValues.nameplateVoltsPhase5}V
              </Grid>
            )}
            {showL6 && (
              <Grid item xs={1}>
                {compressorValues.nameplateVoltsPhase6}V
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={5}>
            <Grid item xs={3}>
              <Stack direction="row" justifyContent="flex-end">
                Nameplate Amps
              </Stack>
            </Grid>
            <Grid item xs={1}>
              {compressorValues.nameplateAmpsPhase1}A
            </Grid>
            {showL2 && (
              <Grid item xs={1}>
                {compressorValues.nameplateAmpsPhase2}A
              </Grid>
            )}
            {showL3 && (
              <Grid item xs={1}>
                {compressorValues.nameplateAmpsPhase3}A
              </Grid>
            )}
            {showL4 && (
              <Grid item xs={1}>
                {compressorValues.nameplateAmpsPhase4}A
              </Grid>
            )}
            {showL5 && (
              <Grid item xs={1}>
                {compressorValues.nameplateAmpsPhase5}A
              </Grid>
            )}
            {showL6 && (
              <Grid item xs={1}>
                {compressorValues.nameplateAmpsPhase6}A
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={5}>
            <Grid item xs={3}></Grid>
            <Grid item xs={1}>
              L1 -L2
            </Grid>
            <Grid item xs={1}>
              L1 -L3
            </Grid>
            {compressorValues.motorPhase === "Three phase" && (
              <Grid item xs={1}>
                L2 -L3
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={5}>
            <Grid item xs={3}>
              <Stack direction="row" justifyContent="flex-end">
                Compressor Actual Volts
              </Stack>
            </Grid>
            <Grid item xs={1}>
              <FormNumeric2
                name="actualVoltsPhase1"
                label=""
                value={compressorValues.actualVoltsPhase1 ?? ""}
                onChange={voltsAmpsInputDataValidation}
                onBlur={(e) => updateCompressor()}
                showSkeleton={isLoading}
                mode={mode}
                disabled={disableStudy}
                decimalScale={2}
                thousandSeparator={true}
                maxValue={100000000}
                fixedDecimalScale={true}
              />
            </Grid>
            {compressorValues.motorPhase === "Three phase" && (
              <Grid item xs={1}>
                <FormNumeric2
                  name="actualVoltsPhase2"
                  label=""
                  value={compressorValues.actualVoltsPhase2 ?? ""}
                  onChange={voltsAmpsInputDataValidation}
                  onBlur={(e) => updateCompressor()}
                  showSkeleton={isLoading}
                  mode={mode}
                  decimalScale={2}
                  thousandSeparator={true}
                  maxValue={100000000}
                  fixedDecimalScale={true}
                />
              </Grid>
            )}
            {compressorValues.motorPhase === "Three phase" && (
              <Grid item xs={1}>
                <FormNumeric2
                  name="actualVoltsPhase3"
                  label=""
                  value={compressorValues.actualVoltsPhase3 ?? ""}
                  onChange={voltsAmpsInputDataValidation}
                  onBlur={(e) => updateCompressor()}
                  showSkeleton={isLoading}
                  mode={mode}
                  disabled={disableStudy}
                  decimalScale={2}
                  thousandSeparator={true}
                  maxValue={100000000}
                  fixedDecimalScale={true}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={5}>
            <Grid item xs={3}>
              <Stack direction="row" justifyContent="flex-end">
                Compressor Actual Amps
              </Stack>
            </Grid>
            <Grid item xs={1}>
              <FormNumeric2
                name="actualAmpsPhase1"
                label=""
                value={compressorValues.actualAmpsPhase1 ?? ""}
                onChange={voltsAmpsInputDataValidation}
                onBlur={(e) => updateCompressor()}
                showSkeleton={isLoading}
                mode={mode}
                disabled={disableStudy}
                decimalScale={2}
                thousandSeparator={true}
                maxValue={100000000}
                fixedDecimalScale={true}
              />
            </Grid>
            <Grid item xs={1}>
              <FormNumeric2
                name="actualAmpsPhase2"
                label=""
                value={compressorValues.actualAmpsPhase2 ?? ""}
                onChange={voltsAmpsInputDataValidation}
                onBlur={(e) => updateCompressor()}
                showSkeleton={isLoading}
                mode={mode}
                disabled={disableStudy}
                decimalScale={2}
                thousandSeparator={true}
                maxValue={100000000}
                fixedDecimalScale={true}
              />
            </Grid>
            {compressorValues.motorPhase === "Three phase" && (
              <Grid item xs={1}>
                <FormNumeric2
                  name="actualAmpsPhase3"
                  label=""
                  value={compressorValues.actualAmpsPhase3 ?? ""}
                  onChange={voltsAmpsInputDataValidation}
                  onBlur={(e) => updateCompressor()}
                  showSkeleton={isLoading}
                  mode={mode}
                  disabled={disableStudy}
                  decimalScale={2}
                  thousandSeparator={true}
                  maxValue={100000000}
                  fixedDecimalScale={true}
                />
              </Grid>
            )}
          </Grid>
          <Grid item xs={12} pt={5}>
            {!actualAmpsValid.line1ActualAmpsValid &&
              actualAmpsValid.line2ActualAmpsValid &&
              actualAmpsValid.line3ActualAmpsValid && (
                <Alert severity="error">
                  WARNING: Actual Amps exceed referenced nameplate Amp rating in
                  line 1
                </Alert>
              )}
            {actualAmpsValid.line1ActualAmpsValid &&
              !actualAmpsValid.line2ActualAmpsValid &&
              actualAmpsValid.line3ActualAmpsValid && (
                <Alert severity="error">
                  WARNING: Actual Amps exceed referenced nameplate Amp rating in
                  line 2
                </Alert>
              )}
            {actualAmpsValid.line1ActualAmpsValid &&
              actualAmpsValid.line2ActualAmpsValid &&
              !actualAmpsValid.line3ActualAmpsValid && (
                <Alert severity="error">
                  WARNING: Actual Amps exceed referenced nameplate Amp rating in
                  line 3
                </Alert>
              )}
            {!actualAmpsValid.line1ActualAmpsValid &&
              !actualAmpsValid.line2ActualAmpsValid &&
              actualAmpsValid.line3ActualAmpsValid && (
                <Alert severity="error">
                  WARNING: Actual Amps exceed referenced nameplate Amp rating in
                  lines 1 & 2
                </Alert>
              )}
            {!actualAmpsValid.line1ActualAmpsValid &&
              actualAmpsValid.line2ActualAmpsValid &&
              !actualAmpsValid.line3ActualAmpsValid && (
                <Alert severity="error">
                  WARNING: Actual Amps exceed referenced nameplate Amp rating in
                  lines 1 & 3
                </Alert>
              )}
            {actualAmpsValid.line1ActualAmpsValid &&
              !actualAmpsValid.line2ActualAmpsValid &&
              !actualAmpsValid.line3ActualAmpsValid && (
                <Alert severity="error">
                  WARNING: Actual Amps exceed referenced nameplate Amp rating in
                  lines 2 & 3
                </Alert>
              )}
            {!actualAmpsValid.line1ActualAmpsValid &&
              !actualAmpsValid.line2ActualAmpsValid &&
              !actualAmpsValid.line3ActualAmpsValid && (
                <Alert severity="error">
                  WARNING: Actual Amps exceed referenced nameplate Amp rating in
                  lines 1, 2 & 3
                </Alert>
              )}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default CompressorActualForm;
