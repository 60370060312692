import React, { useEffect } from "react";
import { Grid, Typography } from "@mui/material";
import { useForm, Form, useAsyncQuery } from "src/hooks";
import { FormText, FormActionButton } from "src/components/formControls";
import FormNumeric from "src/components/formControls/FormNumeric";
import { FormDatePicker } from "src/components/formControls";
import { Validator } from "src/ts/types/Validator";
import { ElavonPaymentFilter } from "../types";
import moment from "moment";
import { useParams } from "react-router-dom";
import CurrencyFormat from "src/components/format/CurrencyFormat";
import { WalletService } from "src/services";

const initialValues: ElavonPaymentFilter = {
  startDate: moment().startOf("month").format("MM/DD/YYYY"),
  endDate: moment().format("MM/DD/YYYY"),
  search: "",
  amountSearch: null,
};

interface Props {
  onSearch: (val: ElavonPaymentFilter) => void;
}

const PurchaseHistoryLogFilters = (props: Props) => {
  const { onSearch } = props;
  let { companyId } = useParams<{ companyId: string }>();
  const validate = (fieldValues = values) => {
    let temp: Record<string, string> = { ...errors };

    temp.startDate = new Validator(fieldValues, "startDate")
      .isRequired("The date is required.")
      .toString();

    temp.endDate = new Validator(fieldValues, "endDate")
      .isRequired("The date is required.")
      .toString();

    const end = new Date(fieldValues.endDate);
    const start = new Date(fieldValues.startDate);
    if (fieldValues.endDate && fieldValues.startDate) {
      if (end < start) {
        temp.startDate = `Start date can not be greater than  ${moment(
          end
        ).format("MM/DD/YYYY")}`;
        temp.endDate = `End date can not be less than  ${moment(start).format(
          "MM/DD/YYYY"
        )}`;
      }
    }

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, errors, setErrors, handleInputChange, setValues } = useForm(
    initialValues,
    false,
    validate
  );

  const { execute: executeBalance, data: dataBalance } = useAsyncQuery<number>(
    WalletService.getWalletBalance
  );

  useEffect(() => {
    const getData = async () => {
      if (!validate()) return;
      await executeBalance(companyId);
    };

    if (companyId) getData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyId]);

  const refreshHandler = () => {
    if (!validate()) return;
    onSearch(values);
  };

  const validateMinDate = (e: any) => {
    if (e.target.value !== "Invalid date") {
      let newValues = { ...values };
      newValues.startDate = e.target.value;

      if (Date.parse(newValues.endDate) < Date.parse(newValues.startDate))
        setValues({ ...newValues, endDate: newValues.startDate });
      else setValues({ ...newValues });
    }
  };

  return (
    <Form>
      <Grid container>
        {companyId && (
          <Grid item xs={4} textAlign={"center"}>
            <Typography variant="h4">Actual Balance: </Typography>
            <Typography variant="h6">
              <CurrencyFormat value={dataBalance}></CurrencyFormat>
            </Typography>
          </Grid>
        )}
        <Grid item xs={companyId ? 8 : 12}>
          <Grid container spacing={5}>
            <Grid item xs={5}>
              <FormDatePicker
                name="startDate"
                label="Date From"
                value={values.startDate}
                onChange={validateMinDate}
                error={errors.startDate}
              />
            </Grid>
            <Grid item xs={5}>
              <FormDatePicker
                name="endDate"
                label="Date To"
                value={values.endDate}
                onChange={handleInputChange}
                error={errors.endDate}
                minDate={values.startDate}
              />
            </Grid>
            <Grid item xs={2}>
              <FormActionButton
                mr={2}
                text="Search"
                size="medium"
                onClick={refreshHandler}
                isSubmitting={false}
                type="search"
              />
            </Grid>
            <Grid item xs={5}>
              <FormText
                name="search"
                label="Text"
                value={values.search}
                onChange={handleInputChange}
                error={errors.search}
                showSkeleton={false}
              />
            </Grid>
            <Grid item xs={5}>
              <FormNumeric
                name="amountSearch"
                label="Amount"
                value={values.amountSearch}
                onChange={handleInputChange}
                error={errors.amountSearch}
                placeholder=""
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Form>
  );
};

export default PurchaseHistoryLogFilters;
