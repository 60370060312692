import { IFormulaSystemService } from "src/ts/interfaces";

const rectangularArea = (height: number, width: number) => {
  return height * width;
};

const circularArea = (diameter: number) => {
  return Math.PI * Math.pow(diameter / 2, 2);
};

const averageVelocity = (systemCFM: number, Sqft: number) => {
  return systemCFM / Sqft;
};

const vp = (AvgVel: number) => {
  return Math.pow(AvgVel / 4005, 2);
};

const sqft = (area: number) => {
  return area / 144;
};

const totalSPDrop = (upPressureInwg: number, downPressureInwg: number) => {
  var totalSPDrop = upPressureInwg - downPressureInwg;
  return Math.abs(totalSPDrop);
};

const calculateBtuH = (cfm: number, h: number, d: number) => {
  return cfm * 4.45 * h; // * (d / 0.075);
};

const calculateSensibleHeat = (cfm: number, dryBulbF: number) => {
  return cfm * dryBulbF * 1.0824;
};

const temperature = (fahrenheit: number) => {
  return fahrenheit;
};

const getSheaveDimensionBetween = () => 0.25;

const getSheaveDimensionBetweenFirst = () => 1.25;

const getAirflowTemperature = () => 70;

const InHgToKPa = (InHg: number) => {
  return InHg;
};

const getPneumaticPressureBottom = () => 18.0;

const getPneumaticPressureTop = () => 20.0;

const sqftToCuft = (sqft: number) => sqft * 10;

const cuftToSqft = (cuft: number) => cuft / 10;

const formulasImperialService: IFormulaSystemService = {
  rectangularArea: rectangularArea,
  circularArea: circularArea,
  averageVelocity: averageVelocity,
  vp: vp,
  sqft: sqft,
  totalSPDrop: totalSPDrop,
  calculateBtuH: calculateBtuH,
  calculateSensibleHeat: calculateSensibleHeat,
  temperature: temperature,
  getSheaveDimensionBetween: getSheaveDimensionBetween,
  getSheaveDimensionBetweenFirst: getSheaveDimensionBetweenFirst,
  getAirflowTemperature: getAirflowTemperature,
  InHgToKPa: InHgToKPa,
  getPneumaticPressureBottom: getPneumaticPressureBottom,
  getPneumaticPressureTop: getPneumaticPressureTop,
  sqftToCuft: sqftToCuft,
  cuftToSqft: cuftToSqft,
};

export default formulasImperialService;
