import axios from "src/utils/axios";

const getProjectContestProposalBid = async (id: number) => {
  return axios.get(`ProjectContestProposalBid?id=${id}`);
};

const projectContestProposalBidService = {
  getProjectContestProposalBid,
};

export default projectContestProposalBidService;
