/* eslint-disable react-hooks/exhaustive-deps */
import {
  CardContent,
  Grid,
  Divider as MuiDivider,
  Card as MuiCard,
  Alert,
  Stack,
  Button,
} from "@mui/material";

import { spacing } from "@mui/system";
import styled from "@emotion/styled";
import { SyntheticEvent, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  useAsyncQuery,
  useLog,
  useAuth,
  useSystemOfMeasurement,
} from "src/hooks";
import {
  FormCheckBox,
  FormNumeric2,
  FormRadioGroup,
} from "src/components/formControls";
import useFormTyped from "src/hooks/useFormTyped";
import {
  ICfmDTO,
  ICommissioningDTO,
  IEnergyStudyAirHydronicsDTO,
  IHeadSheetGeneralDataDTO,
  IQuizAnswerDTO,
  IQuizQuestionDTO,
  IQuizResultDTO,
} from "src/ts/interfaces";
import { IProjectReport } from "src/ts/interfaces/project/projectDto";
import projectReportService from "src/services/study/projectReportService";
import headSheetGeneralDataService from "src/services/study/headSheetGeneralDataService";
import commissioningService from "src/services/study/commissioningService";
import quizQuestionService from "src/services/study/quizQuestionService";
import { headSheetType, steps, initialValuesComm } from "../const/consts";
import { FooterButtons } from "../../components/FooterButtons";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import DisplaySettingsIcon from "@mui/icons-material/DisplaySettings";
import EngineeringIcon from "@mui/icons-material/Engineering";
import AirIcon from "@mui/icons-material/Air";
import _ from "lodash";
import Popup from "src/components/Popup";
import { Validator } from "src/ts/types/Validator";
import cfmService from "src/services/study/cfmService";
import HeaderStudyPage from "src/components/page/HeaderStudyPage";
import singleEnergyStudyAirHydronicsService from "src/services/study/singleEnergyStudyAirHydronicsService";
import StudyWizard from "../../components/StudyWizard";

const initialValues: any = {
  projectName: "",
  system: "",
  equipmentLocation: "",
  areaServed: "",
};

const supplyOptions = [
  { key: 0, value: "Supply" },
  { key: 1, value: "Return" },
  { key: 2, value: "Exhaust" },
];

const _TAB_GE = 0;
const _TAB_C1 = 1;
const _TAB_C2 = 2;

const Divider = styled(MuiDivider)(spacing);
const Card = styled(MuiCard)(spacing);

const HeadSheetStep2 = () => {
  const { log } = useLog();
  const { user } = useAuth();
  const { systemOfMeasurement } = useSystemOfMeasurement(true);
  const params = useParams<{ id: string; step: string }>();
  const id = parseInt(params?.id === undefined ? "0" : params?.id);
  const [isUpdating, setIsUpdating] = useState(false);
  const [title, setTitle] = useState<string>();
  const [code, setCode] = useState<string>();
  const [route, setRoute] = useState<string>();
  const [tabSelected, setTabSelected] = useState(0);
  const [commValues, setCommValues] =
    useState<ICommissioningDTO>(initialValuesComm);
  const [questions, setQuestions] = useState<IQuizQuestionDTO[]>();
  const [basAlert, setBasAlert] = useState("");
  const [showBasAlert, setShowBasAlert] = useState(false);
  const [notArrayAlertOpen, setNotArrayAlertOpen] = useState(false);
  const [visualAlert, setVisualAlert] = useState("");
  const [showVisualAlert, setShowVisualAlert] = useState(false);
  const [openOaRaAlert, setOpenOaRaAlert] = useState(false);
  const [nextTab, setNextTab] = useState(0);
  const [oldFansX, setOldFansX] = useState(0);
  const [oldFansY, setOldFansY] = useState(0);
  const [oldFans, setOldFans] = useState(0);
  const [fansDecreasedOpen, setFanDecreasedOpen] = useState(false);
  const [isEnergyProcess, setIsEnergyProcess] = useState(false);
  const [energyValues, setEnergyValues] =
    useState<IEnergyStudyAirHydronicsDTO>();

  const handleTabChange = (event: SyntheticEvent, newValue: number) => {
    setTabSelected(newValue);
  };

  let navigate = useNavigate();

  const validate: any = (fieldValues: IHeadSheetGeneralDataDTO = values) => {
    let temp: Record<string, string> = { ...errors };
    let valid = true;

    if (disableStudy()) return true;

    if (tabSelected === _TAB_GE) {
      temp.diffusersCFMDesign = new Validator(fieldValues, "diffusersCFMDesign")
        .validateIf(!fieldValues.isNotAvailableDiffusersCFMDesign)
        .isRequired("This field is required.")
        .toString();
      temp.systemCFMDesign = new Validator(fieldValues, "systemCFMDesign")
        .validateIf(!fieldValues.isNotAvailableSystemCFMDesign)
        .isRequired("This field is required.")
        .toString();
      temp.systemCFMDesign = new Validator(fieldValues, "systemCFMDesign")
        .validateIf(!fieldValues.isNotAvailableSystemCFMDesign)
        .isRequired("This field is required.")
        .toString();
      temp.outsideAirVentilationRateCfmDesign = new Validator(
        fieldValues,
        "outsideAirVentilationRateCfmDesign"
      )
        .validateIf(
          !fieldValues.isNotAvailableOutsideAirVentilationRateCfmDesign
        )
        .isRequired("This field is required.")
        .toString();
      temp.returnAirflow = new Validator(fieldValues, "returnAirflow")
        .validateIf(!fieldValues.returnAirflowNotListed)
        .isRequired("This field is required.")
        .toString();
      temp.systemExhaustAirflow = new Validator(
        fieldValues,
        "systemExhaustAirflow"
      )
        .validateIf(!fieldValues.systemExhaustAirflowNotListed)
        .isRequired("This field is required.")
        .toString();
    }

    if (tabSelected === _TAB_C1) {
      temp.basOADamperposition = new Validator(
        commValues,
        "basOADamperposition"
      )
        .validateIf(commValues.basOADamperPositionPercentage || false)
        .isRequired("This field is required.")
        .toString();
      temp.basRADamperposition = new Validator(
        commValues,
        "basRADamperposition"
      )
        .validateIf(commValues.basRADamperPositionPercentage || false)
        .isRequired("This field is required.")
        .toString();
      temp.basMADamperposition = new Validator(
        commValues,
        "basMADamperposition"
      )
        .validateIf(commValues.basMADamperPositionPercentage || false)
        .isRequired("This field is required.")
        .toString();
      temp.basAHUExhaustDamper = new Validator(
        commValues,
        "basAHUExhaustDamper"
      )
        .validateIf(commValues.basAHUExhaustDamperPercentage || false)
        .isRequired("This field is required.")
        .toString();
      temp.visualOADamperposition = new Validator(
        commValues,
        "visualOADamperposition"
      )
        .validateIf(commValues.visualOADamperPositionPercentage || false)
        .isRequired("This field is required.")
        .toString();
      temp.visualRADamperposition = new Validator(
        commValues,
        "visualRADamperposition"
      )
        .validateIf(commValues.visualRADamperPositionPercentage || false)
        .isRequired("This field is required.")
        .toString();
      temp.visualMADamperposition = new Validator(
        commValues,
        "visualMADamperposition"
      )
        .validateIf(commValues.visualMADamperPositionPercentage || false)
        .isRequired("This field is required.")
        .toString();
      temp.visualAHUExhaustDamper = new Validator(
        commValues,
        "visualAHUExhaustDamper"
      )
        .validateIf(commValues.visualAHUExhaustDamperPercentage || false)
        .isRequired("This field is required.")
        .toString();
    }

    if (tabSelected === _TAB_C2) {
      let numOfMotors =
        typeof values.numberOfFans === "string"
          ? parseInt(values.numberOfFans)
          : values.numberOfFans;
      let oldNumOfMotors = oldFans;
      const acrossHorizontal =
        typeof values.numberOfFansX === "string"
          ? parseInt(values.numberOfFansX)
          : values.numberOfFansX;
      const downVertical =
        typeof values.numberOfFansY === "string"
          ? parseInt(values.numberOfFansY)
          : values.numberOfFansY;
      const maxNumberOfMotors = user?.role === "Training" ? 4 : 55;
      let errorMotorMessage =
        user?.role === "Training"
          ? `Only ${maxNumberOfMotors} fans are allowed on training mode`
          : `Total number of fans must be less than ${maxNumberOfMotors}`;

      if (values.fanSystemType === "FanArray") {
        oldNumOfMotors = oldFansX * oldFansY;
        if (acrossHorizontal === 1 && downVertical === 1) {
          setNotArrayAlertOpen(true);
          valid = false;
        }
        numOfMotors = acrossHorizontal * downVertical;
        if (numOfMotors > maxNumberOfMotors) {
          log.warning(errorMotorMessage);
          valid = false;
        }
        temp.numberOfFansX = new Validator(fieldValues, "numberOfFansX")
          .isRequired("The number of fans is required.")
          .greatThan(0, "Must be great than 0")
          .toString();
        temp.numberOfFansY = new Validator(fieldValues, "numberOfFansY")
          .isRequired("The number of fans is required.")
          .greatThan(0, "Must be great than 0")
          .toString();
      } else if (values.fanSystemType === "FanSystem") {
        temp.numberOfFans = new Validator(fieldValues, "numberOfFans")
          .isRequired("The number of fans is required.")
          .greatThan(0, "Must be great than 0")
          .lessThan(maxNumberOfMotors, errorMotorMessage)
          .toString();
      } else {
        log.warning("Fan Array or Multiple Fan System must be select");
        valid = false;
      }

      if (oldNumOfMotors > numOfMotors) {
        setFanDecreasedOpen(true);
        valid = false;
      }
    }

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "" && valid);
  };

  const { values, setValues, errors, setErrors, handleInputChange } =
    useFormTyped<IHeadSheetGeneralDataDTO>(initialValues, false, validate);

  const activeStep = 1;

  const { execute: executeReport, data: dataReport } =
    useAsyncQuery<IProjectReport>(projectReportService.report);

  const { execute, isLoading } = useAsyncQuery<IHeadSheetGeneralDataDTO>(
    headSheetGeneralDataService.getById,
    {
      onSuccess: (dataResult) => {
        const getData = async () => {
          setValues({ ...dataResult });
          setOldFans(dataResult.numberOfFans);
          setOldFansX(dataResult.numberOfFansX);
          setOldFansY(dataResult.numberOfFansY);
          await systemOfMeasurement.getByProject(
            dataResult.headSheet.projectId ?? values.headSheet?.projectId
          );
          window.scrollTo(0, 0);
        };
        getData();
      },
    }
  );

  const { execute: executeComm, isLoading: isLoadingComm } =
    useAsyncQuery<ICommissioningDTO>(commissioningService.getById, {
      onSuccess: (dataResult) => {
        const getData = async () => {
          if (_.isEmpty(dataResult)) {
            setCommValues({ ...initialValuesComm, headSheetId: id });
          } else {
            setCommValues({ ...dataResult });
          }
        };
        getData();
      },
    });

  const { execute: executeQuestions, isLoading: isLoadingQuestions } =
    useAsyncQuery<IQuizQuestionDTO[]>(quizQuestionService.getQuizQuestions, {
      onSuccess: (dataResult) => {
        const getData = async () => {
          setQuestions(dataResult);
        };
        getData();
      },
    });

  const { execute: executeCfm } = useAsyncQuery<ICfmDTO>(cfmService.getCfm, {
    onSuccess: (dataResult) => {
      const getData = async () => {
        if (!values?.manualEntryActualDiffusersCFM) {
          setValues({
            ...values,
            actualDiffusersCfmSupply: dataResult.actualDiffusersCfmSupply,
            actualDiffusersCfmReturn: dataResult.actualDiffusersCfmReturn,
            actualDiffusersCfmExhaust: dataResult.actualDiffusersCfmExhaust,
          });
          if (!values?.manualEntryActualSystemCFM) {
            setValues({
              ...values,
              actualSystemCfmSupply: dataResult.actualSystemCfmSupply,
              actualSystemCfmReturn: dataResult.actualSystemCfmReturn,
              actualSystemCfmExhaust: dataResult.actualSystemCfmExhaust,
            });
          }

          if (!values?.manualEntryActualOutsideAirVentilationRateCfm) {
            setValues({
              ...values,
              actualOutsideAirVentilationRateCfm:
                dataResult.outsideAirVentilationRateCfmDesign,
            });
          }
        }
      };
      getData();
    },
  });

  const { execute: executeParentEnergy } = useAsyncQuery<
    IEnergyStudyAirHydronicsDTO[]
  >(singleEnergyStudyAirHydronicsService.getById, {
    onSuccess: (dataResult) => {
      const getData = async () => {
        setEnergyValues(dataResult);
      };
      getData();
    },
  });

  const disableStudy = () => {
    return (
      values.headSheet?.isComplete ||
      (dataReport?.isInUse && dataReport?.isInUseById !== user?.userId) ||
      dataReport?.isPartOfProject === false
    );
  };

  useEffect(() => {
    const energyReportTypes = [18, 24, 25];
    if (dataReport?.parentReportTypeId) {
      if (energyReportTypes.includes(dataReport?.parentReportTypeId)) {
        setIsEnergyProcess(true);
        executeParentEnergy(dataReport?.parentReportId);
      }
    }
  }, [dataReport?.parentReportTypeId]);

  useEffect(() => {
    if (values.headSheet?.reportTypeId) {
      const [{ title, code, route }] = headSheetType.filter(
        (x) => x.key === values.headSheet?.reportTypeId
      );
      setTitle(title);
      setCode(code);
      setRoute(route);
    }
  }, [values.headSheet?.reportTypeId]);

  useEffect(() => {
    const getData = async () => {
      try {
        if (tabSelected === 0) {
          await execute(id);
        }
        if (tabSelected === 1) {
          await executeComm(id);
        }
        if (tabSelected === 2) {
          await executeQuestions(id, "System", 0);
        }
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      }
    };

    if (id !== 0) getData();
  }, [id, tabSelected]);

  useEffect(() => {
    const getData = async () => {
      try {
        await executeReport(id, code);
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      }
    };

    if (id !== 0 && code !== undefined) getData();
  }, [id, code]);

  useEffect(() => {
    if (!disableStudy() && id && code) executeCfm(id, code);
  }, [
    values?.isCFMknown,
    values?.manualEntryActualDiffusersCFM,
    values?.manualEntryActualSystemCFM,
    values?.manualEntryActualOutsideAirVentilationRateCfm,
    id,
    code,
  ]);

  const saveHandler = async () => {
    if (!validate()) return;
    try {
      if (tabSelected === _TAB_GE) {
        await saveGeneralDataHandler();
      }
      if (tabSelected === _TAB_C1) {
        if (showBasAlert || showVisualAlert) {
          setNextTab(_TAB_C1);
          setOpenOaRaAlert(true);
        } else {
          await saveCommissioningHandler();
        }
      }
      if (tabSelected === _TAB_C2) {
        await saveQuizQuestionsHandler();
      }
    } catch (error) {}
  };

  const saveGeneralDataHandler = async () => {
    try {
      if (!validate()) return false;
      if (!disableStudy()) {
        setIsUpdating(true);
        await headSheetGeneralDataService.update(id, values);
        setOldFans(
          typeof values.numberOfFans === "string"
            ? parseInt(values.numberOfFans)
            : values.numberOfFans
        );
        setOldFansX(
          typeof values.numberOfFansX === "string"
            ? parseInt(values.numberOfFansX)
            : values.numberOfFansX
        );
        setOldFansY(
          typeof values.numberOfFansY === "string"
            ? parseInt(values.numberOfFansY)
            : values.numberOfFansY
        );
        log.success("Report was updated successfully");
        setIsUpdating(false);
      }
    } catch (error) {}
  };

  const saveCommissioningHandler = async () => {
    try {
      if (!disableStudy()) {
        setIsUpdating(true);
        if (commValues.id === 0) {
          await commissioningService.save(id, commValues);
        } else {
          await commissioningService.update(commValues.id, commValues);
        }
        log.success("Commissioning was updated successfully");
        setIsUpdating(false);
      }
    } catch (error) {}
  };

  const saveQuizQuestionsHandler = async () => {
    try {
      if (!disableStudy()) {
        setFanDecreasedOpen(false);
        setIsUpdating(true);
        let results = [] as IQuizResultDTO[];
        questions?.forEach((question) => {
          const subResults = question.answers?.map(
            (answer) =>
              ({
                quizQuestionId: question.id,
                quizAnswerId: answer.id,
                quizId: 1,
                checked: answer.checked,
                reportId: id,
              } as IQuizResultDTO)
          ) as IQuizResultDTO[];
          results = [...results, ...subResults];
        });
        await quizQuestionService.save(id, results);
        await saveGeneralDataHandler();
        log.success("Questions were updated successfully");
        setIsUpdating(false);
      }
    } catch (error) {}
  };

  const nextStepHandler = async () => {
    if (!validate()) return;
    try {
      if (tabSelected === _TAB_GE) {
        await saveGeneralDataHandler();
        setTabSelected(_TAB_C1);
        window.scrollTo(0, 0);
      } else if (tabSelected === _TAB_C1) {
        if ((showBasAlert || showVisualAlert) && !disableStudy()) {
          setNextTab(_TAB_C2);
          setOpenOaRaAlert(true);
        } else {
          await saveCommissioningHandler();
          setTabSelected(_TAB_C2);
          window.scrollTo(0, 0);
        }
      } else if (tabSelected === _TAB_C2) {
        await saveQuizQuestionsHandler();
        navigate(`/studies/${route}/step3/${id}`);
      }
    } catch (error) {}
  };

  const previousStepHandler = async () => {
    if (!validate()) return;
    try {
      if (tabSelected === _TAB_C2) {
        await saveQuizQuestionsHandler();
        setTabSelected(_TAB_C1);
        window.scrollTo(0, 0);
      } else if (tabSelected === _TAB_C1) {
        if ((showBasAlert || showVisualAlert) && !disableStudy()) {
          setNextTab(_TAB_GE);
          setOpenOaRaAlert(true);
        } else {
          await saveCommissioningHandler();
          setTabSelected(_TAB_GE);
          window.scrollTo(0, 0);
        }
      } else if (tabSelected === _TAB_GE) {
        await saveGeneralDataHandler();
        navigate(`/studies/${route}/step1/${id}`);
      }
    } catch (error) {}
  };

  const handleTypeOfHeadSheetChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    value: string
  ) => {
    const [supply] = supplyOptions.filter((x) => x.key === parseInt(value));
    setValues({
      ...values,
      supply: supply.value === "Supply",
      return: supply.value === "Return",
      exhaust: supply.value === "Exhaust",
    });
  };

  //Reset values when boxes are disabled
  useEffect(() => {
    setValues({
      ...values,
      actualDiffusersCfmSupply: values.manualEntryActualDiffusersCFM
        ? values.actualDiffusersCfmSupply
        : 0,
      actualDiffusersCfmReturn: values.manualEntryActualDiffusersCFM
        ? values.actualDiffusersCfmReturn
        : 0,
      actualDiffusersCfmExhaust: values.manualEntryActualDiffusersCFM
        ? values.actualDiffusersCfmExhaust
        : 0,
      actualSystemCfmSupply: values.manualEntryActualSystemCFM
        ? values.actualSystemCfmSupply
        : 0,
      actualSystemCfmReturn: values.manualEntryActualSystemCFM
        ? values.actualSystemCfmReturn
        : 0,
      actualSystemCfmExhaust: values.manualEntryActualSystemCFM
        ? values.actualSystemCfmExhaust
        : 0,
      actualOutsideAirVentilationRateCfm:
        values.manualEntryActualOutsideAirVentilationRateCfm
          ? values.actualOutsideAirVentilationRateCfm
          : 0,
      diffusersCFMDesign: !values.isNotAvailableDiffusersCFMDesign
        ? values.diffusersCFMDesign
        : 0,
      systemCFMDesign: !values.isNotAvailableSystemCFMDesign
        ? values.systemCFMDesign
        : 0,
      outsideAirVentilationRateCfmDesign:
        !values.isNotAvailableOutsideAirVentilationRateCfmDesign
          ? values.outsideAirVentilationRateCfmDesign
          : 0,
      returnAirflow: !values.returnAirflowNotListed ? values.returnAirflow : 0,
      systemExhaustAirflow: !values.systemExhaustAirflowNotListed
        ? values.systemExhaustAirflow
        : 0,
    });
  }, [
    values.manualEntryActualDiffusersCFM,
    values.manualEntryActualSystemCFM,
    values.manualEntryActualOutsideAirVentilationRateCfm,
    values.isNotAvailableDiffusersCFMDesign,
    values.isNotAvailableSystemCFMDesign,
    values.isNotAvailableOutsideAirVentilationRateCfmDesign,
  ]);

  useEffect(() => {
    setBasAlert("");
    setShowBasAlert(false);
    setVisualAlert("");
    setShowVisualAlert(false);

    if (
      commValues.basOADamperPositionPercentage &&
      commValues.basRADamperPositionPercentage
    ) {
      const basTotal =
        parseFloat(commValues.basOADamperposition as unknown as string) +
        parseFloat(commValues.basRADamperposition as unknown as string);
      if (basTotal !== 100) {
        setBasAlert(
          basTotal > 100
            ? "% O/A & R/A exceeds 100%"
            : basTotal < 100
            ? "% O/A & R/A doesn't equal 100%"
            : ""
        );
        setShowBasAlert(true);
      }
    }

    if (
      commValues.visualOADamperPositionPercentage &&
      commValues.visualRADamperPositionPercentage
    ) {
      const visualTotal =
        parseFloat(commValues.visualOADamperposition as unknown as string) +
        parseFloat(commValues.visualRADamperposition as unknown as string);

      if (visualTotal !== 100) {
        setVisualAlert(
          visualTotal > 100
            ? "% O/A & R/A exceeds 100%"
            : visualTotal < 100
            ? "% O/A & R/A doesn't equal 100%"
            : ""
        );
        setShowVisualAlert(true);
      }
    }
  }, [
    commValues.basOADamperposition,
    commValues.basRADamperposition,
    commValues.visualOADamperposition,
    commValues.visualRADamperposition,
    commValues.basOADamperPositionPercentage,
    commValues.basRADamperPositionPercentage,
    commValues.visualOADamperPositionPercentage,
    commValues.visualRADamperPositionPercentage,
  ]);

  useEffect(() => {
    setCommValues({
      ...commValues,
      basOADamperposition: commValues.basOADamperPositionPercentage
        ? commValues.basOADamperposition
        : 0,
      basRADamperposition: commValues.basRADamperPositionPercentage
        ? commValues.basRADamperposition
        : 0,
      basMADamperposition: commValues.basMADamperPositionPercentage
        ? commValues.basMADamperposition
        : 0,
      basAHUExhaustDamper: commValues.basAHUExhaustDamperPercentage
        ? commValues.basAHUExhaustDamper
        : 0,
      visualOADamperposition: commValues.visualOADamperPositionPercentage
        ? commValues.visualOADamperposition
        : 0,
      visualRADamperposition: commValues.visualRADamperPositionPercentage
        ? commValues.visualRADamperposition
        : 0,
      visualMADamperposition: commValues.visualMADamperPositionPercentage
        ? commValues.visualMADamperposition
        : 0,
      visualAHUExhaustDamper: commValues.visualAHUExhaustDamperPercentage
        ? commValues.visualAHUExhaustDamper
        : 0,
    });
  }, [
    commValues.basOADamperPositionPercentage,
    commValues.basRADamperPositionPercentage,
    commValues.basMADamperPositionPercentage,
    commValues.basAHUExhaustDamperPercentage,
    commValues.visualOADamperPositionPercentage,
    commValues.visualRADamperPositionPercentage,
    commValues.visualMADamperPositionPercentage,
    commValues.visualAHUExhaustDamperPercentage,
  ]);

  const supplyValue = () => {
    if (values.supply) {
      const [{ key }] = supplyOptions.filter((x) => x.value === "Supply");
      return key;
    } else if (values.return) {
      const [{ key }] = supplyOptions.filter((x) => x.value === "Return");
      return key;
    } else if (values.exhaust) {
      const [{ key }] = supplyOptions.filter((x) => x.value === "Exhaust");
      return key;
    }
  };

  const allCfmNA = () =>
    values.isNotAvailableDiffusersCFMDesign &&
    values.isNotAvailableSystemCFMDesign &&
    values.isNotAvailableOutsideAirVentilationRateCfmDesign &&
    values.returnAirflowNotListed &&
    values.systemExhaustAirflowNotListed;

  const allCfmNAHandleChange = (e: any) => {
    const value = e.target.value || false;
    setValues({
      ...values,
      isNotAvailableDiffusersCFMDesign: value,
      isNotAvailableSystemCFMDesign: value,
      isNotAvailableOutsideAirVentilationRateCfmDesign: value,
      returnAirflowNotListed: value,
      systemExhaustAirflowNotListed: value,
    });
  };

  const handleCommInputChange = (e: any) => {
    const { name, value } = e.target;
    let newValue = value;
    if (
      [
        "basOADamperpositionNotApplicable",
        "basOADamperpositionNotAccessible",
        "basOADamperPositionPercentage",
        "basRADamperpositionNotAccessible",
        "basRADamperpositionNotApplicable",
        "basRADamperPositionPercentage",
        "basMADamperpositionNotAccessible",
        "basMADamperpositionNotApplicable",
        "basMADamperPositionPercentage",
        "basAHUExhaustDamperNotAccessible",
        "basAHUExhaustDamperNotApplicable",
        "basAHUExhaustDamperPercentage",
        "visualOADamperpositionNotApplicable",
        "visualOADamperpositionNotAccessible",
        "visualOADamperPositionPercentage",
        "visualRADamperpositionNotAccessible",
        "visualRADamperpositionNotApplicable",
        "visualRADamperPositionPercentage",
        "visualMADamperpositionNotAccessible",
        "visualMADamperpositionNotApplicable",
        "visualMADamperPositionPercentage",
        "visualAHUExhaustDamperNotAccessible",
        "visualAHUExhaustDamperNotApplicable",
        "visualAHUExhaustDamperPercentage",
      ].includes(name) &&
      !value
    ) {
      newValue = true;
    }
    setCommValues({
      ...commValues,
      [name]: newValue,
    });
  };

  const allBasNA = () =>
    commValues.basOADamperpositionNotApplicable &&
    commValues.basRADamperpositionNotApplicable &&
    commValues.basMADamperpositionNotApplicable &&
    commValues.basAHUExhaustDamperNotApplicable;

  const allBasNAHandleChange = (e: any) => {
    const value = e.target.value || false;
    setCommValues({
      ...commValues,
      basOADamperpositionNotApplicable: value,
      basRADamperpositionNotApplicable: value,
      basMADamperpositionNotApplicable: value,
      basAHUExhaustDamperNotApplicable: value,
      basOADamperpositionNotAccessible: false,
      basRADamperpositionNotAccessible: false,
      basMADamperpositionNotAccessible: false,
      basAHUExhaustDamperNotAccessible: false,
      basOADamperPositionPercentage: !value,
      basRADamperPositionPercentage: !value,
      basMADamperPositionPercentage: !value,
      basAHUExhaustDamperPercentage: !value,
    });
  };

  const allBasNAc = () =>
    commValues.basOADamperpositionNotAccessible &&
    commValues.basRADamperpositionNotAccessible &&
    commValues.basMADamperpositionNotAccessible &&
    commValues.basAHUExhaustDamperNotAccessible;

  const allBasNAcHandleChange = (e: any) => {
    const value = e.target.value || false;
    setCommValues({
      ...commValues,
      basOADamperpositionNotAccessible: value,
      basRADamperpositionNotAccessible: value,
      basMADamperpositionNotAccessible: value,
      basAHUExhaustDamperNotAccessible: value,
      basOADamperpositionNotApplicable: false,
      basRADamperpositionNotApplicable: false,
      basMADamperpositionNotApplicable: false,
      basAHUExhaustDamperNotApplicable: false,
      basOADamperPositionPercentage: !value,
      basRADamperPositionPercentage: !value,
      basMADamperPositionPercentage: !value,
      basAHUExhaustDamperPercentage: !value,
    });
  };

  const allVisualNA = () =>
    commValues.visualOADamperpositionNotApplicable &&
    commValues.visualRADamperpositionNotApplicable &&
    commValues.visualMADamperpositionNotApplicable &&
    commValues.visualAHUExhaustDamperNotApplicable;

  const allVisualNAHandleChange = (e: any) => {
    const value = e.target.value || false;
    setCommValues({
      ...commValues,
      visualOADamperpositionNotApplicable: value,
      visualRADamperpositionNotApplicable: value,
      visualMADamperpositionNotApplicable: value,
      visualAHUExhaustDamperNotApplicable: value,
      visualOADamperpositionNotAccessible: false,
      visualRADamperpositionNotAccessible: false,
      visualMADamperpositionNotAccessible: false,
      visualAHUExhaustDamperNotAccessible: false,
      visualOADamperPositionPercentage: !value,
      visualRADamperPositionPercentage: !value,
      visualMADamperPositionPercentage: !value,
      visualAHUExhaustDamperPercentage: !value,
    });
  };

  const allVisualNAc = () =>
    commValues.visualOADamperpositionNotAccessible &&
    commValues.visualRADamperpositionNotAccessible &&
    commValues.visualMADamperpositionNotAccessible &&
    commValues.visualAHUExhaustDamperNotAccessible;

  const allVisualNAcHandleChange = (e: any) => {
    const value = e.target.value || false;
    setCommValues({
      ...commValues,
      visualOADamperpositionNotAccessible: value,
      visualRADamperpositionNotAccessible: value,
      visualMADamperpositionNotAccessible: value,
      visualAHUExhaustDamperNotAccessible: value,
      visualOADamperpositionNotApplicable: false,
      visualRADamperpositionNotApplicable: false,
      visualMADamperpositionNotApplicable: false,
      visualAHUExhaustDamperNotApplicable: false,
      visualOADamperPositionPercentage: !value,
      visualRADamperPositionPercentage: !value,
      visualMADamperPositionPercentage: !value,
      visualAHUExhaustDamperPercentage: !value,
    });
  };

  const oaDamperChkHandleChange = (
    name: string,
    opposite1: string,
    opposite2: string
  ) => {
    setCommValues({
      ...commValues,
      [name]: true,
      [opposite1]: false,
      [opposite2]: false,
    });
  };

  const handleOaRaAlertNo = () => {
    setOpenOaRaAlert(false);
  };

  const handleOaRaAlertYes = () => {
    setOpenOaRaAlert(false);
    saveCommissioningHandler();
    setTabSelected(nextTab);
    window.scrollTo(0, 0);
  };

  const handleQuestionsRadioChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    value: string
  ) => {
    let answers = [] as IQuizAnswerDTO[];
    const questionId = parseInt(event.target.name.split("-")[1]);
    const [question] = (questions as IQuizQuestionDTO[]).filter(
      (x) => x.id === questionId
    );
    const index = (questions as IQuizQuestionDTO[]).indexOf(question);
    (question?.answers as IQuizAnswerDTO[]).forEach((answer) =>
      answers.push({ ...answer, checked: answer.id === parseInt(value) })
    );
    let crud = [...(questions as IQuizQuestionDTO[])];
    crud.splice(index, 1, { ...question, answers: [...answers] });
    setQuestions([...crud]);
  };

  const quizQuestionValueHandle = (question: IQuizQuestionDTO) => {
    const [answer] = (question?.answers as IQuizAnswerDTO[]).filter(
      (x) => x.checked
    );
    return answer?.id ?? null;
  };

  return (
    <>
      {/**Warnings section*/}
      <Popup
        title="Warning"
        openPopup={openOaRaAlert}
        setOpenPopup={setOpenOaRaAlert}
        onClose={handleOaRaAlertNo}
      >
        <>
          <Alert severity="warning">
            It is understood your free area doesn't equal 100%.
          </Alert>
        </>
        <Stack direction="row" spacing={3}>
          <Button
            variant="outlined"
            color="secondary"
            onClick={handleOaRaAlertNo}
            size="small"
          >
            No
          </Button>
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={handleOaRaAlertYes}
            autoFocus
          >
            Yes
          </Button>
        </Stack>
      </Popup>
      <Popup
        title="Warning"
        openPopup={notArrayAlertOpen}
        setOpenPopup={setNotArrayAlertOpen}
        onClose={() => setNotArrayAlertOpen(false)}
      >
        <>
          <p style={{ color: "darkblue", fontWeight: "bold" }}>
            Your actual configuration of Fans, in X (Across/Horizontal) and Y
            (Down/Vertical), results in an array of a one single Fan/Motor
            system, which is an Impossibility. Fan Arrays are always an
            arrangement of more than one Fan/Motor system.
            <br />
            <br />
            You have to set up more than 1 Fan in X (Across/Horizontal) or Y
            (Down/Vertical).
            <br />
            <br />
            Even if you physically have one single Fan/Motor, you may try with
            the "Engineered Fan Report" study instead of the "Engineered Fan
            Array Report".
          </p>
        </>
        <Stack direction="row" spacing={3}>
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={() => setNotArrayAlertOpen(false)}
            autoFocus
          >
            Accept
          </Button>
        </Stack>
      </Popup>
      <Popup
        title="Warning"
        openPopup={fansDecreasedOpen}
        setOpenPopup={setFanDecreasedOpen}
        onClose={() => setFanDecreasedOpen(false)}
      >
        <>
          <Alert severity="warning">
            <p>
              You are about to decrease the number of Fans on the Fan Array and
              you will lose Fans permanently. Are you certain you want to
              continue?
            </p>
          </Alert>
        </>
        <Stack direction="row" spacing={3}>
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => setFanDecreasedOpen(false)}
            size="small"
          >
            No
          </Button>
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={saveQuizQuestionsHandler}
            autoFocus
          >
            Yes
          </Button>
        </Stack>
      </Popup>
      {/**Warnings section end */}
      <HeaderStudyPage
        headerStudiesPage={{
          code: code,
          system: values?.headSheet?.system,
          id: id,
        }}
        parentText="Project"
        parentLink={`/app/ProjectStudies/${values.headSheet?.projectId}/${user?.companyId}`}
      />
      <Divider my={6} />

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card mb={6}>
            <CardContent>
              <StudyWizard
                steps={steps}
                activeStep={activeStep}
                projectId={dataReport?.projectId}
                reportId={dataReport?.reportId}
                reportTypeId={dataReport?.reportTypeId}
              />
              <Grid container spacing={3} marginLeft={5}>
                <Grid item xs={12}>
                  <Tabs
                    value={tabSelected}
                    onChange={handleTabChange}
                    aria-label="headsheet step 2 tabs"
                  >
                    <Tab
                      icon={<AirIcon />}
                      iconPosition="start"
                      label="General Data"
                    />
                    <Tab
                      icon={<DisplaySettingsIcon />}
                      iconPosition="start"
                      label="Commissioning - 1"
                    />
                    <Tab
                      icon={<EngineeringIcon />}
                      iconPosition="start"
                      label="Commissioning - 2"
                    />
                  </Tabs>
                </Grid>
                {tabSelected === _TAB_GE && (
                  <>
                    <Grid item xs={11}>
                      <h3>System Design</h3>
                    </Grid>
                    <Grid item xs={11}>
                      <FormCheckBox
                        label={`Actual ${systemOfMeasurement.get(
                          "cfm"
                        )}'s are known at this point`}
                        name="isCFMknown"
                        value={values.isCFMknown}
                        onChange={handleInputChange}
                        showSkeleton={isLoading}
                        disabled={
                          disableStudy() || energyValues?.target === "Air"
                        }
                      />
                      {energyValues?.target === "Air" && (
                        <Alert severity="info">
                          This task is disabled due to the Target of the current
                          Energy Process
                        </Alert>
                      )}
                    </Grid>
                    {values.isCFMknown && (
                      <>
                        <Grid item xs={11}>
                          <FormRadioGroup
                            label="Select the type of HeadSheet"
                            name="supply"
                            items={supplyOptions}
                            row
                            onChange={handleTypeOfHeadSheetChange}
                            value={`${supplyValue()}`}
                            showSkeleton={isLoading}
                            disabled={disableStudy()}
                          />
                        </Grid>
                        <Grid item xs={11} md={2}>
                          <FormCheckBox
                            label="Manual entry"
                            name="manualEntryActualDiffusersCFM"
                            value={values.manualEntryActualDiffusersCFM}
                            onChange={handleInputChange}
                            showSkeleton={isLoading}
                            disabled={disableStudy()}
                          />
                        </Grid>
                        <Grid item xs={11} md={3}>
                          <FormNumeric2
                            disabled={
                              !values.manualEntryActualDiffusersCFM ||
                              disableStudy()
                            }
                            label={`Actual Grill/Reg/Diff ${systemOfMeasurement.get(
                              "cfm"
                            )} - Supply`}
                            name="actualDiffusersCfmSupply"
                            onBlur={handleInputChange}
                            value={values.actualDiffusersCfmSupply}
                            thousandSeparator
                            decimalScale={3}
                            fixedDecimalScale
                            showSkeleton={isLoading}
                          />
                        </Grid>
                        <Grid item xs={11} md={3}>
                          <FormNumeric2
                            disabled={
                              !values.manualEntryActualDiffusersCFM ||
                              disableStudy()
                            }
                            label={`Actual Grill/Reg/Diff ${systemOfMeasurement.get(
                              "cfm"
                            )} - Return`}
                            name="actualDiffusersCfmReturn"
                            onBlur={handleInputChange}
                            value={values.actualDiffusersCfmReturn}
                            thousandSeparator
                            decimalScale={3}
                            fixedDecimalScale
                            showSkeleton={isLoading}
                          />
                        </Grid>
                        <Grid item xs={11} md={3}>
                          <FormNumeric2
                            disabled={
                              !values.manualEntryActualDiffusersCFM ||
                              disableStudy()
                            }
                            label={`Actual Grill/Reg/Diff ${systemOfMeasurement.get(
                              "cfm"
                            )} - Exhaust`}
                            name="actualDiffusersCfmExhaust"
                            onBlur={handleInputChange}
                            value={values.actualDiffusersCfmExhaust}
                            thousandSeparator
                            decimalScale={3}
                            fixedDecimalScale
                            showSkeleton={isLoading}
                          />
                        </Grid>
                        <Grid item xs={11} md={2}>
                          <FormCheckBox
                            label="Manual entry"
                            name="manualEntryActualSystemCFM"
                            value={values.manualEntryActualSystemCFM}
                            onChange={handleInputChange}
                            showSkeleton={isLoading}
                            disabled={disableStudy()}
                          />
                        </Grid>
                        <Grid item xs={11} md={3}>
                          <FormNumeric2
                            disabled={
                              !values.manualEntryActualSystemCFM ||
                              disableStudy()
                            }
                            label={`Actual System ${systemOfMeasurement.get(
                              "cfm"
                            )} - Supply`}
                            name="actualSystemCfmSupply"
                            onBlur={handleInputChange}
                            value={values.actualSystemCfmSupply}
                            thousandSeparator
                            decimalScale={3}
                            fixedDecimalScale
                            showSkeleton={isLoading}
                          />
                        </Grid>
                        <Grid item xs={11} md={3}>
                          <FormNumeric2
                            disabled={
                              !values.manualEntryActualSystemCFM ||
                              disableStudy()
                            }
                            label={`Actual System ${systemOfMeasurement.get(
                              "cfm"
                            )} - Return`}
                            name="actualSystemCfmReturn"
                            onBlur={handleInputChange}
                            value={values.actualSystemCfmReturn}
                            thousandSeparator
                            decimalScale={3}
                            fixedDecimalScale
                            showSkeleton={isLoading}
                          />
                        </Grid>
                        <Grid item xs={11} md={3}>
                          <FormNumeric2
                            disabled={
                              !values.manualEntryActualSystemCFM ||
                              disableStudy()
                            }
                            label={`Actual System ${systemOfMeasurement.get(
                              "cfm"
                            )} - Exhaust`}
                            name="actualSystemCfmExhaust"
                            onBlur={handleInputChange}
                            value={values.actualSystemCfmExhaust}
                            thousandSeparator
                            decimalScale={3}
                            fixedDecimalScale
                            showSkeleton={isLoading}
                          />
                        </Grid>
                        <Grid item xs={11} md={2}>
                          <FormCheckBox
                            label="Manual entry"
                            value={
                              values.manualEntryActualOutsideAirVentilationRateCfm
                            }
                            name="manualEntryActualOutsideAirVentilationRateCfm"
                            onChange={handleInputChange}
                            showSkeleton={isLoading}
                            disabled={disableStudy()}
                          />
                        </Grid>
                        <Grid item xs={11} md={9}>
                          <FormNumeric2
                            disabled={
                              !values.manualEntryActualOutsideAirVentilationRateCfm ||
                              disableStudy()
                            }
                            label={`Actual O/A Ventilation Rate ${systemOfMeasurement.get(
                              "cfm"
                            )}`}
                            name="actualOutsideAirVentilationRateCfm"
                            onBlur={handleInputChange}
                            value={values.actualOutsideAirVentilationRateCfm}
                            thousandSeparator
                            decimalScale={3}
                            fixedDecimalScale
                            showSkeleton={isLoading}
                          />
                        </Grid>
                      </>
                    )}
                    <Grid item xs={11}>
                      <Divider my={2} />
                    </Grid>
                    <Grid item xs={11}>
                      <FormCheckBox
                        label={`All ${systemOfMeasurement.get(
                          "cfm"
                        )} Not Available`}
                        name="allCFMNotAvailbale"
                        value={allCfmNA()}
                        onChange={allCfmNAHandleChange}
                        showSkeleton={isLoading}
                        disabled={disableStudy()}
                      />
                    </Grid>
                    <Grid item xs={11} md={2}>
                      <FormCheckBox
                        label="Not Available"
                        name="isNotAvailableDiffusersCFMDesign"
                        value={values.isNotAvailableDiffusersCFMDesign}
                        onChange={handleInputChange}
                        showSkeleton={isLoading}
                        disabled={disableStudy()}
                      />
                    </Grid>
                    <Grid item xs={11} md={9}>
                      <FormNumeric2
                        disabled={
                          values.isNotAvailableDiffusersCFMDesign ||
                          disableStudy()
                        }
                        label={`Grilles, Registers & Diffusers ${systemOfMeasurement.get(
                          "cfm"
                        )} Design`}
                        name="diffusersCFMDesign"
                        onBlur={handleInputChange}
                        value={values.diffusersCFMDesign}
                        thousandSeparator
                        decimalScale={3}
                        fixedDecimalScale
                        showSkeleton={isLoading}
                        error={errors.diffusersCFMDesign}
                      />
                    </Grid>
                    <Grid item xs={11} md={2}>
                      <FormCheckBox
                        label="Not Available"
                        name="isNotAvailableSystemCFMDesign"
                        value={values.isNotAvailableSystemCFMDesign}
                        onChange={handleInputChange}
                        showSkeleton={isLoading}
                        disabled={disableStudy()}
                      />
                    </Grid>
                    <Grid item xs={11} md={9}>
                      <FormNumeric2
                        disabled={
                          values.isNotAvailableSystemCFMDesign || disableStudy()
                        }
                        label={`System ${systemOfMeasurement.get(
                          "cfm"
                        )} Design`}
                        name="systemCFMDesign"
                        onBlur={handleInputChange}
                        value={values.systemCFMDesign}
                        thousandSeparator
                        decimalScale={3}
                        fixedDecimalScale
                        showSkeleton={isLoading}
                        error={errors.systemCFMDesign}
                      />
                    </Grid>
                    <Grid item xs={11} md={2}>
                      <FormCheckBox
                        label="Not Available"
                        name="isNotAvailableOutsideAirVentilationRateCfmDesign"
                        value={
                          values.isNotAvailableOutsideAirVentilationRateCfmDesign
                        }
                        onChange={handleInputChange}
                        showSkeleton={isLoading}
                        disabled={disableStudy()}
                      />
                    </Grid>
                    <Grid item xs={11} md={9}>
                      <FormNumeric2
                        disabled={
                          values.isNotAvailableOutsideAirVentilationRateCfmDesign ||
                          disableStudy()
                        }
                        label={`Outside Air Ventilation Rate ${systemOfMeasurement.get(
                          "cfm"
                        )} Design`}
                        name="outsideAirVentilationRateCfmDesign"
                        onBlur={handleInputChange}
                        value={values.outsideAirVentilationRateCfmDesign}
                        thousandSeparator
                        decimalScale={3}
                        fixedDecimalScale
                        showSkeleton={isLoading}
                        error={errors.outsideAirVentilationRateCfmDesign}
                      />
                    </Grid>
                    <Grid item xs={11} md={2}>
                      <FormCheckBox
                        label="Not Available"
                        name="returnAirflowNotListed"
                        value={values.returnAirflowNotListed}
                        onChange={handleInputChange}
                        showSkeleton={isLoading}
                        disabled={disableStudy()}
                      />
                    </Grid>
                    <Grid item xs={11} md={9}>
                      <FormNumeric2
                        disabled={
                          values.returnAirflowNotListed || disableStudy()
                        }
                        label={`Return Airflow ${systemOfMeasurement.get(
                          "cfm"
                        )} Design`}
                        name="returnAirflow"
                        onBlur={handleInputChange}
                        value={values.returnAirflow}
                        thousandSeparator
                        decimalScale={3}
                        fixedDecimalScale
                        showSkeleton={isLoading}
                        error={errors.returnAirflow}
                      />
                    </Grid>
                    <Grid item xs={11} md={2}>
                      <FormCheckBox
                        label="Not Available"
                        name="systemExhaustAirflowNotListed"
                        value={values.systemExhaustAirflowNotListed}
                        onChange={handleInputChange}
                        showSkeleton={isLoading}
                        disabled={disableStudy()}
                      />
                    </Grid>
                    <Grid item xs={11} md={9}>
                      <FormNumeric2
                        disabled={
                          values.systemExhaustAirflowNotListed || disableStudy()
                        }
                        label={`System Exhaust Airflow ${systemOfMeasurement.get(
                          "cfm"
                        )} Design`}
                        name="systemExhaustAirflow"
                        onBlur={handleInputChange}
                        value={values.systemExhaustAirflow}
                        thousandSeparator
                        decimalScale={3}
                        fixedDecimalScale
                        showSkeleton={isLoading}
                        error={errors.systemExhaustAirflow}
                      />
                    </Grid>
                    <Grid item xs={11}>
                      <FormCheckBox
                        label="Fan system is constant volume"
                        name="systemInConstantVolume"
                        value={values.systemInConstantVolume}
                        onChange={handleInputChange}
                        showSkeleton={isLoading}
                        disabled={disableStudy()}
                      />
                    </Grid>
                  </>
                )}
                {tabSelected === _TAB_C1 && (
                  <>
                    <Grid container spacing={3}>
                      <Grid
                        item
                        xs={11}
                        md={3}
                        marginTop={16}
                        borderRight={1}
                        borderColor="lightgrey"
                      >
                        <Grid item>
                          <FormCheckBox
                            label="Water Coil Carryover"
                            name="waterCoilCarryover"
                            value={commValues.waterCoilCarryover}
                            onChange={handleCommInputChange}
                            showSkeleton={isLoadingComm}
                            disabled={disableStudy()}
                          />
                        </Grid>
                        <Grid item>
                          <FormCheckBox
                            label="Drain Pan Clogged"
                            name="drainPanClogged"
                            value={commValues.drainPanClogged}
                            onChange={handleCommInputChange}
                            showSkeleton={isLoadingComm}
                            disabled={disableStudy()}
                          />
                        </Grid>
                        <Grid item>
                          <FormCheckBox
                            label="Microbial Growth Suspected"
                            name="microbialGrowthSuspected"
                            value={commValues.microbialGrowthSuspected}
                            onChange={handleCommInputChange}
                            showSkeleton={isLoadingComm}
                            disabled={disableStudy()}
                          />
                        </Grid>
                        <Grid item>
                          <FormCheckBox
                            label="Dirty Coils"
                            name="dirtyCoils"
                            value={commValues.dirtyCoils}
                            onChange={handleCommInputChange}
                            showSkeleton={isLoadingComm}
                            disabled={disableStudy()}
                          />
                        </Grid>
                        <Grid item>
                          <FormCheckBox
                            label="End of coil missing a blank-off to prevent airflow bypass"
                            name="endCoilBlankOff"
                            value={commValues.endCoilBlankOff}
                            onChange={handleCommInputChange}
                            showSkeleton={isLoadingComm}
                            disabled={disableStudy()}
                          />
                        </Grid>
                        <Grid item>
                          <FormCheckBox
                            label="Coil Fin damage found, comb fins or repair recommended"
                            name="coilFinDamage"
                            value={commValues.coilFinDamage}
                            onChange={handleCommInputChange}
                            showSkeleton={isLoadingComm}
                            disabled={disableStudy()}
                          />
                        </Grid>
                        <Grid item>
                          <FormCheckBox
                            label="Wet Insulation"
                            name="wetInsulation"
                            value={commValues.wetInsulation}
                            onChange={handleCommInputChange}
                            showSkeleton={isLoadingComm}
                            disabled={disableStudy()}
                          />
                        </Grid>
                        <Grid item>
                          <FormCheckBox
                            label="Missing/Damaged Insulation"
                            name="damagedInsulation"
                            value={commValues.damagedInsulation}
                            onChange={handleCommInputChange}
                            showSkeleton={isLoadingComm}
                            disabled={disableStudy()}
                          />
                        </Grid>
                        <Grid item>
                          <FormCheckBox
                            label="Obvious Duct Leakage"
                            name="obviousDuctLeakage"
                            value={commValues.obviousDuctLeakage}
                            onChange={handleCommInputChange}
                            showSkeleton={isLoadingComm}
                            disabled={disableStudy()}
                          />
                        </Grid>
                        <Grid item>
                          <FormCheckBox
                            label="Canvas connection Needs Repair"
                            name="canvasConnectionNeedsRepair"
                            value={commValues.canvasConnectionNeedsRepair}
                            onChange={handleCommInputChange}
                            showSkeleton={isLoadingComm}
                            disabled={disableStudy()}
                          />
                        </Grid>
                        <Grid item>
                          <FormCheckBox
                            label="Door Seals Leak"
                            name="doorSealsLeak"
                            value={commValues.doorSealsLeak}
                            onChange={handleCommInputChange}
                            showSkeleton={isLoadingComm}
                            disabled={disableStudy()}
                          />
                        </Grid>
                        <Grid item>
                          <FormCheckBox
                            label="Restricted Airflow"
                            name="restrictedAirflow"
                            value={commValues.restrictedAirflow}
                            onChange={handleCommInputChange}
                            showSkeleton={isLoadingComm}
                            disabled={disableStudy()}
                          />
                        </Grid>
                      </Grid>
                      <Grid item xs={11} md={8} marginLeft={5}>
                        <Grid container spacing={3}>
                          <Grid
                            item
                            xs={12}
                            textAlign="center"
                            color="GrayText"
                          >
                            <h3>BAS Damper Position</h3>
                          </Grid>
                          <Grid item xs={12} md={3}>
                            <FormCheckBox
                              label="All not applicable"
                              name="basAllNotApplicable"
                              value={allBasNA()}
                              onChange={allBasNAHandleChange}
                              showSkeleton={isLoadingComm}
                              disabled={disableStudy()}
                            />
                          </Grid>
                          <Grid item xs={12} md={9}>
                            <FormCheckBox
                              label="All not accessible"
                              name="basAllNotAccessible"
                              value={allBasNAc()}
                              onChange={allBasNAcHandleChange}
                              showSkeleton={isLoadingComm}
                              disabled={disableStudy()}
                            />
                          </Grid>
                          {/*OA Damper Position*/}
                          <Grid item xs={12} md={3}>
                            <FormCheckBox
                              label="Not applicable"
                              name="basOADamperpositionNotApplicable"
                              value={
                                commValues.basOADamperpositionNotApplicable
                              }
                              onChange={(e: any) =>
                                oaDamperChkHandleChange(
                                  e.target.name,
                                  "basOADamperpositionNotAccessible",
                                  "basOADamperPositionPercentage"
                                )
                              }
                              showSkeleton={isLoadingComm}
                              disabled={disableStudy()}
                            />
                          </Grid>
                          <Grid item xs={12} md={3}>
                            <FormCheckBox
                              label="Not accessible"
                              name="basOADamperpositionNotAccessible"
                              value={
                                commValues.basOADamperpositionNotAccessible
                              }
                              onChange={(e: any) =>
                                oaDamperChkHandleChange(
                                  e.target.name,
                                  "basOADamperPositionPercentage",
                                  "basOADamperpositionNotApplicable"
                                )
                              }
                              showSkeleton={isLoadingComm}
                              disabled={disableStudy()}
                            />
                          </Grid>
                          <Grid item xs={12} md={3}>
                            <FormCheckBox
                              label="BAS OA Damper Position"
                              name="basOADamperPositionPercentage"
                              value={
                                commValues.basOADamperPositionPercentage ||
                                false
                              }
                              onChange={(e: any) =>
                                oaDamperChkHandleChange(
                                  e.target.name,
                                  "basOADamperpositionNotAccessible",
                                  "basOADamperpositionNotApplicable"
                                )
                              }
                              showSkeleton={isLoadingComm}
                              disabled={disableStudy()}
                            />
                          </Grid>
                          <Grid item xs={12} md={3}>
                            <FormNumeric2
                              disabled={
                                !commValues.basOADamperPositionPercentage ||
                                disableStudy()
                              }
                              label="OA Damper Position"
                              name="basOADamperposition"
                              onBlur={handleCommInputChange}
                              value={commValues.basOADamperposition}
                              thousandSeparator
                              decimalScale={3}
                              fixedDecimalScale
                              maxValue={1000}
                              showSkeleton={isLoadingComm}
                              error={errors.basOADamperposition}
                            />
                          </Grid>
                          {/*RA Damper Position*/}
                          <Grid item xs={12} md={3}>
                            <FormCheckBox
                              label="Not applicable"
                              name="basRADamperpositionNotApplicable"
                              value={
                                commValues.basRADamperpositionNotApplicable
                              }
                              onChange={(e: any) =>
                                oaDamperChkHandleChange(
                                  e.target.name,
                                  "basRADamperpositionNotAccessible",
                                  "basRADamperPositionPercentage"
                                )
                              }
                              showSkeleton={isLoadingComm}
                              disabled={disableStudy()}
                            />
                          </Grid>
                          <Grid item xs={12} md={3}>
                            <FormCheckBox
                              label="Not accessible"
                              name="basRADamperpositionNotAccessible"
                              value={
                                commValues.basRADamperpositionNotAccessible
                              }
                              onChange={(e: any) =>
                                oaDamperChkHandleChange(
                                  e.target.name,
                                  "basRADamperPositionPercentage",
                                  "basRADamperpositionNotApplicable"
                                )
                              }
                              showSkeleton={isLoadingComm}
                              disabled={disableStudy()}
                            />
                          </Grid>
                          <Grid item xs={12} md={3}>
                            <FormCheckBox
                              label="BAS RA Damper Position"
                              name="basRADamperPositionPercentage"
                              value={
                                commValues.basRADamperPositionPercentage ||
                                false
                              }
                              onChange={(e: any) =>
                                oaDamperChkHandleChange(
                                  e.target.name,
                                  "basRADamperpositionNotAccessible",
                                  "basRADamperpositionNotApplicable"
                                )
                              }
                              showSkeleton={isLoadingComm}
                              disabled={disableStudy()}
                            />
                          </Grid>
                          <Grid item xs={12} md={3}>
                            <FormNumeric2
                              disabled={
                                !commValues.basRADamperPositionPercentage ||
                                disableStudy()
                              }
                              label="RA Damper Position"
                              name="basRADamperposition"
                              onBlur={handleCommInputChange}
                              value={commValues.basRADamperposition}
                              thousandSeparator
                              decimalScale={3}
                              fixedDecimalScale
                              maxValue={1000}
                              showSkeleton={isLoadingComm}
                              error={errors.basRADamperposition}
                            />
                          </Grid>
                          {/*MA Damper Position*/}
                          <Grid item xs={12} md={3}>
                            <FormCheckBox
                              label="Not applicable"
                              name="basMADamperpositionNotApplicable"
                              value={
                                commValues.basMADamperpositionNotApplicable
                              }
                              onChange={(e: any) =>
                                oaDamperChkHandleChange(
                                  e.target.name,
                                  "basMADamperPositionPercentage",
                                  "basMADamperpositionNotAccessible"
                                )
                              }
                              showSkeleton={isLoadingComm}
                              disabled={disableStudy()}
                            />
                          </Grid>
                          <Grid item xs={12} md={3}>
                            <FormCheckBox
                              label="Not accessible"
                              name="basMADamperpositionNotAccessible"
                              value={
                                commValues.basMADamperpositionNotAccessible
                              }
                              onChange={(e: any) =>
                                oaDamperChkHandleChange(
                                  e.target.name,
                                  "basMADamperPositionPercentage",
                                  "basMADamperpositionNotApplicable"
                                )
                              }
                              showSkeleton={isLoadingComm}
                              disabled={disableStudy()}
                            />
                          </Grid>
                          <Grid item xs={12} md={3}>
                            <FormCheckBox
                              label="BAS MA Damper Position"
                              name="basMADamperPositionPercentage"
                              value={
                                commValues.basMADamperPositionPercentage ||
                                false
                              }
                              onChange={(e: any) =>
                                oaDamperChkHandleChange(
                                  e.target.name,
                                  "basMADamperpositionNotAccessible",
                                  "basMADamperpositionNotApplicable"
                                )
                              }
                              showSkeleton={isLoadingComm}
                              disabled={disableStudy()}
                            />
                          </Grid>
                          <Grid item xs={12} md={3}>
                            <FormNumeric2
                              disabled={
                                !commValues.basMADamperPositionPercentage ||
                                disableStudy()
                              }
                              label="MA Damper Position"
                              name="basMADamperposition"
                              onBlur={handleCommInputChange}
                              value={commValues.basMADamperposition}
                              thousandSeparator
                              decimalScale={3}
                              fixedDecimalScale
                              maxValue={1000}
                              showSkeleton={isLoadingComm}
                              error={errors.basMADamperposition}
                            />
                          </Grid>
                          {/*EA Damper Position*/}
                          <Grid item xs={12} md={3}>
                            <FormCheckBox
                              label="Not applicable"
                              name="basAHUExhaustDamperNotApplicable"
                              value={
                                commValues.basAHUExhaustDamperNotApplicable
                              }
                              onChange={(e: any) =>
                                oaDamperChkHandleChange(
                                  e.target.name,
                                  "basAHUExhaustDamperPercentage",
                                  "basAHUExhaustDamperNotAccessible"
                                )
                              }
                              showSkeleton={isLoadingComm}
                              disabled={disableStudy()}
                            />
                          </Grid>
                          <Grid item xs={12} md={3}>
                            <FormCheckBox
                              label="Not accessible"
                              name="basAHUExhaustDamperNotAccessible"
                              value={
                                commValues.basAHUExhaustDamperNotAccessible
                              }
                              onChange={(e: any) =>
                                oaDamperChkHandleChange(
                                  e.target.name,
                                  "basAHUExhaustDamperPercentage",
                                  "basAHUExhaustDamperNotApplicable"
                                )
                              }
                              showSkeleton={isLoadingComm}
                              disabled={disableStudy()}
                            />
                          </Grid>
                          <Grid item xs={12} md={3}>
                            <FormCheckBox
                              label="BAS EA Damper Position"
                              name="basAHUExhaustDamperPercentage"
                              value={
                                commValues.basAHUExhaustDamperPercentage ||
                                false
                              }
                              onChange={(e: any) =>
                                oaDamperChkHandleChange(
                                  e.target.name,
                                  "basAHUExhaustDamperNotApplicable",
                                  "basAHUExhaustDamperNotAccessible"
                                )
                              }
                              showSkeleton={isLoadingComm}
                              disabled={disableStudy()}
                            />
                          </Grid>
                          <Grid item xs={12} md={3}>
                            <FormNumeric2
                              disabled={
                                !commValues.basAHUExhaustDamperPercentage ||
                                disableStudy()
                              }
                              label="EA Damper Position"
                              name="basAHUExhaustDamper"
                              onBlur={handleCommInputChange}
                              value={commValues.basAHUExhaustDamper}
                              thousandSeparator
                              decimalScale={3}
                              fixedDecimalScale
                              maxValue={1000}
                              showSkeleton={isLoadingComm}
                              error={errors.basAHUExhaustDamper}
                            />
                          </Grid>
                          {showBasAlert && !isLoadingComm && (
                            <Grid item xs={12}>
                              <Alert severity="error">{basAlert}</Alert>
                            </Grid>
                          )}
                          {/** Visual Damper Position */}
                          <Grid
                            item
                            xs={12}
                            textAlign="center"
                            color="GrayText"
                          >
                            <h3>Visual Damper Position</h3>
                          </Grid>
                          <Grid item xs={12} md={3}>
                            <FormCheckBox
                              label="All not applicable"
                              name="visualAllNotApplicable"
                              value={allVisualNA()}
                              onChange={allVisualNAHandleChange}
                              showSkeleton={isLoadingComm}
                              disabled={disableStudy()}
                            />
                          </Grid>
                          <Grid item xs={12} md={9}>
                            <FormCheckBox
                              label="All not accessible"
                              name="visualAllNotAccessible"
                              value={allVisualNAc()}
                              onChange={allVisualNAcHandleChange}
                              showSkeleton={isLoadingComm}
                              disabled={disableStudy()}
                            />
                          </Grid>
                          {/*OA Damper Position*/}
                          <Grid item xs={12} md={3}>
                            <FormCheckBox
                              label="Not applicable"
                              name="visualOADamperpositionNotApplicable"
                              value={
                                commValues.visualOADamperpositionNotApplicable
                              }
                              onChange={(e: any) =>
                                oaDamperChkHandleChange(
                                  e.target.name,
                                  "visualOADamperPositionPercentage",
                                  "visualOADamperpositionNotAccessible"
                                )
                              }
                              showSkeleton={isLoadingComm}
                              disabled={disableStudy()}
                            />
                          </Grid>
                          <Grid item xs={12} md={3}>
                            <FormCheckBox
                              label="Not accessible"
                              name="visualOADamperpositionNotAccessible"
                              value={
                                commValues.visualOADamperpositionNotAccessible
                              }
                              onChange={(e: any) =>
                                oaDamperChkHandleChange(
                                  e.target.name,
                                  "visualOADamperPositionPercentage",
                                  "visualOADamperpositionNotApplicable"
                                )
                              }
                              showSkeleton={isLoadingComm}
                              disabled={disableStudy()}
                            />
                          </Grid>
                          <Grid item xs={12} md={3}>
                            <FormCheckBox
                              label="Visual OA Damper Position"
                              name="visualOADamperPositionPercentage"
                              value={
                                commValues.visualOADamperPositionPercentage ||
                                false
                              }
                              onChange={(e: any) =>
                                oaDamperChkHandleChange(
                                  e.target.name,
                                  "visualOADamperpositionNotApplicable",
                                  "visualOADamperpositionNotAccessible"
                                )
                              }
                              showSkeleton={isLoadingComm}
                              disabled={disableStudy()}
                            />
                          </Grid>
                          <Grid item xs={12} md={3}>
                            <FormNumeric2
                              disabled={
                                !commValues.visualOADamperPositionPercentage ||
                                disableStudy()
                              }
                              label="OA Damper Position"
                              name="visualOADamperposition"
                              onBlur={handleCommInputChange}
                              value={commValues.visualOADamperposition}
                              thousandSeparator
                              decimalScale={3}
                              fixedDecimalScale
                              maxValue={1000}
                              showSkeleton={isLoadingComm}
                              error={errors.visualOADamperposition}
                            />
                          </Grid>
                          {/*RA Damper Position*/}
                          <Grid item xs={12} md={3}>
                            <FormCheckBox
                              label="Not applicable"
                              name="visualRADamperpositionNotApplicable"
                              value={
                                commValues.visualRADamperpositionNotApplicable
                              }
                              onChange={(e: any) =>
                                oaDamperChkHandleChange(
                                  e.target.name,
                                  "visualRADamperPositionPercentage",
                                  "visualRADamperpositionNotAccessible"
                                )
                              }
                              showSkeleton={isLoadingComm}
                              disabled={disableStudy()}
                            />
                          </Grid>
                          <Grid item xs={12} md={3}>
                            <FormCheckBox
                              label="Not accessible"
                              name="visualRADamperpositionNotAccessible"
                              value={
                                commValues.visualRADamperpositionNotAccessible
                              }
                              onChange={(e: any) =>
                                oaDamperChkHandleChange(
                                  e.target.name,
                                  "visualRADamperPositionPercentage",
                                  "visualRADamperpositionNotApplicable"
                                )
                              }
                              showSkeleton={isLoadingComm}
                              disabled={disableStudy()}
                            />
                          </Grid>
                          <Grid item xs={12} md={3}>
                            <FormCheckBox
                              label="Visual RA Damper Position"
                              name="visualRADamperPositionPercentage"
                              value={
                                commValues.visualRADamperPositionPercentage ||
                                false
                              }
                              onChange={(e: any) =>
                                oaDamperChkHandleChange(
                                  e.target.name,
                                  "visualRADamperpositionNotAccessible",
                                  "visualRADamperpositionNotApplicable"
                                )
                              }
                              showSkeleton={isLoadingComm}
                              disabled={disableStudy()}
                            />
                          </Grid>
                          <Grid item xs={12} md={3}>
                            <FormNumeric2
                              disabled={
                                !commValues.visualRADamperPositionPercentage ||
                                disableStudy()
                              }
                              label="RA Damper Position"
                              name="visualRADamperposition"
                              onBlur={handleCommInputChange}
                              value={commValues.visualRADamperposition}
                              thousandSeparator
                              decimalScale={3}
                              fixedDecimalScale
                              maxValue={1000}
                              showSkeleton={isLoadingComm}
                              error={errors.visualRADamperposition}
                            />
                          </Grid>
                          {/*MA Damper Position*/}
                          <Grid item xs={12} md={3}>
                            <FormCheckBox
                              label="Not applicable"
                              name="visualMADamperpositionNotApplicable"
                              value={
                                commValues.visualMADamperpositionNotApplicable
                              }
                              onChange={(e: any) =>
                                oaDamperChkHandleChange(
                                  e.target.name,
                                  "visualMADamperPositionPercentage",
                                  "visualMADamperpositionNotAccessible"
                                )
                              }
                              showSkeleton={isLoadingComm}
                              disabled={disableStudy()}
                            />
                          </Grid>
                          <Grid item xs={12} md={3}>
                            <FormCheckBox
                              label="Not accessible"
                              name="visualMADamperpositionNotAccessible"
                              value={
                                commValues.visualMADamperpositionNotAccessible
                              }
                              onChange={(e: any) =>
                                oaDamperChkHandleChange(
                                  e.target.name,
                                  "visualMADamperPositionPercentage",
                                  "visualMADamperpositionNotApplicable"
                                )
                              }
                              showSkeleton={isLoadingComm}
                              disabled={disableStudy()}
                            />
                          </Grid>
                          <Grid item xs={12} md={3}>
                            <FormCheckBox
                              label="Visual MA Damper Position"
                              name="visualMADamperPositionPercentage"
                              value={
                                commValues.visualMADamperPositionPercentage ||
                                false
                              }
                              onChange={(e: any) =>
                                oaDamperChkHandleChange(
                                  e.target.name,
                                  "visualMADamperpositionNotAccessible",
                                  "visualMADamperpositionNotApplicable"
                                )
                              }
                              showSkeleton={isLoadingComm}
                              disabled={disableStudy()}
                            />
                          </Grid>
                          <Grid item xs={12} md={3}>
                            <FormNumeric2
                              disabled={
                                !commValues.visualMADamperPositionPercentage ||
                                disableStudy()
                              }
                              label="MA Damper Position"
                              name="visualMADamperposition"
                              onBlur={handleCommInputChange}
                              value={commValues.visualMADamperposition}
                              thousandSeparator
                              decimalScale={3}
                              fixedDecimalScale
                              maxValue={1000}
                              showSkeleton={isLoadingComm}
                              error={errors.visualMADamperposition}
                            />
                          </Grid>
                          {/*EA Damper Position*/}
                          <Grid item xs={12} md={3}>
                            <FormCheckBox
                              label="Not applicable"
                              name="visualAHUExhaustDamperNotApplicable"
                              value={
                                commValues.visualAHUExhaustDamperNotApplicable
                              }
                              onChange={(e: any) =>
                                oaDamperChkHandleChange(
                                  e.target.name,
                                  "visualAHUExhaustDamperPercentage",
                                  "visualAHUExhaustDamperNotAccessible"
                                )
                              }
                              showSkeleton={isLoadingComm}
                              disabled={disableStudy()}
                            />
                          </Grid>
                          <Grid item xs={12} md={3}>
                            <FormCheckBox
                              label="Not accessible"
                              name="visualAHUExhaustDamperNotAccessible"
                              value={
                                commValues.visualAHUExhaustDamperNotAccessible
                              }
                              onChange={(e: any) =>
                                oaDamperChkHandleChange(
                                  e.target.name,
                                  "visualAHUExhaustDamperPercentage",
                                  "visualAHUExhaustDamperNotApplicable"
                                )
                              }
                              showSkeleton={isLoadingComm}
                              disabled={disableStudy()}
                            />
                          </Grid>
                          <Grid item xs={12} md={3}>
                            <FormCheckBox
                              label="Visual EA Damper Position"
                              name="visualAHUExhaustDamperPercentage"
                              value={
                                commValues.visualAHUExhaustDamperPercentage ||
                                false
                              }
                              onChange={(e: any) =>
                                oaDamperChkHandleChange(
                                  e.target.name,
                                  "visualAHUExhaustDamperNotAccessible",
                                  "visualAHUExhaustDamperNotApplicable"
                                )
                              }
                              showSkeleton={isLoadingComm}
                              disabled={disableStudy()}
                            />
                          </Grid>
                          <Grid item xs={12} md={3}>
                            <FormNumeric2
                              disabled={
                                !commValues.visualAHUExhaustDamperPercentage ||
                                disableStudy()
                              }
                              label="EA Damper Position"
                              name="visualAHUExhaustDamper"
                              onBlur={handleCommInputChange}
                              value={commValues.visualAHUExhaustDamper}
                              thousandSeparator
                              decimalScale={3}
                              fixedDecimalScale
                              maxValue={1000}
                              showSkeleton={isLoadingComm}
                              error={errors.visualAHUExhaustDamper}
                            />
                          </Grid>
                          {showVisualAlert && !isLoadingComm && (
                            <Grid item xs={12}>
                              <Alert severity="error">{visualAlert}</Alert>
                            </Grid>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  </>
                )}
                {tabSelected === _TAB_C2 && (
                  <>
                    {questions?.map(
                      (question: IQuizQuestionDTO, key: number) => (
                        <Grid item key={key} xs={12}>
                          <FormRadioGroup
                            name={`question-${question.id}-answers`}
                            label={question.question as string}
                            items={question.answers?.map((answer) => ({
                              key: answer.id,
                              value: answer.answer,
                            }))}
                            onChange={handleQuestionsRadioChange}
                            value={quizQuestionValueHandle(question)}
                            showSkeleton={isLoadingQuestions}
                            disabled={disableStudy()}
                          />
                        </Grid>
                      )
                    )}
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                    {values?.fanSystemType === "FanArray" && (
                      <>
                        <Grid item xs={12}>
                          <h3>Fan Array</h3>
                        </Grid>
                        <Grid item xs={11}>
                          <FormNumeric2
                            label="Enter Number Of Fans (X)"
                            name="numberOfFansX"
                            onBlur={handleInputChange}
                            value={values.numberOfFansX}
                            thousandSeparator
                            decimalScale={0}
                            maxValue={1000}
                            showSkeleton={isLoadingQuestions}
                            disabled={disableStudy()}
                          />
                        </Grid>
                        <Grid item xs={11}>
                          <FormNumeric2
                            label="Enter Number Of Fans (Y)"
                            name="numberOfFansY"
                            onBlur={handleInputChange}
                            value={values.numberOfFansY}
                            thousandSeparator
                            decimalScale={0}
                            maxValue={1000}
                            showSkeleton={isLoadingQuestions}
                            disabled={disableStudy()}
                          />
                        </Grid>
                        <Grid item xs={11}>
                          <FormCheckBox
                            label="Fed/Controlled by a Single VFD"
                            name="fedBySingleVFD"
                            value={values.fedBySingleVFD}
                            onChange={handleInputChange}
                            showSkeleton={isLoadingQuestions}
                            disabled={disableStudy()}
                          />
                        </Grid>
                      </>
                    )}
                    {values?.fanSystemType === "FanSystem" && (
                      <>
                        <Grid item xs={12}>
                          <h3>Multiple fan systems</h3>
                        </Grid>
                        <Grid item xs={11}>
                          <FormNumeric2
                            label="Enter Number Of Fans (X)"
                            name="numberOfFans"
                            onBlur={handleInputChange}
                            value={values.numberOfFans}
                            thousandSeparator
                            decimalScale={0}
                            maxValue={1000}
                            error={errors.numberOfFans}
                            showSkeleton={isLoadingQuestions}
                            disabled={disableStudy()}
                          />
                        </Grid>
                      </>
                    )}
                  </>
                )}
              </Grid>
              <FooterButtons
                activeStep={activeStep}
                stepsCount={steps.length}
                projectId={values?.headSheet?.projectId as number}
                companyId={user?.companyId as number}
                isSaving={isUpdating}
                disabled={
                  isUpdating ||
                  isLoading ||
                  isLoadingComm ||
                  isLoadingQuestions ||
                  disableStudy()
                }
                reportName={title as string}
                saveHandler={saveHandler}
                hideSaveButton={disableStudy()}
                nextStepHandler={nextStepHandler}
                previousStepHandler={previousStepHandler}
                isComplete={values?.headSheet?.isComplete ?? true}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default HeadSheetStep2;
