import {
  CardContent,
  Grid,
  Divider as MuiDivider,
  Card as MuiCard,
} from "@mui/material";

import { spacing } from "@mui/system";
import styled from "@emotion/styled";
import { useEffect, useLayoutEffect, useMemo, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import useFormTyped from "src/hooks/useFormTyped";
import {
  useAsyncMutation,
  useAsyncQuery,
  useAuth,
  useSystemOfMeasurement,
} from "src/hooks";
import { IProjectReport } from "src/ts/interfaces/project/projectDto";
import projectReportService from "src/services/study/projectReportService";
import { IBoilerTest } from "src/ts/interfaces/study/boiler";
import { steps } from "./components/const";
import boilerTestService from "src/services/study/boilerTestService";
import StudyWizard from "../components/StudyWizard";
import Step1 from "./components/Step1";
import Step2 from "./components/Step2";
import getProjectService from "src/services/project/getProjectService";
import Step3 from "./components/Step3";
import Step4 from "./components/Step4";
import HeaderStudyPage from "src/components/page/HeaderStudyPage";
const initialValues: IBoilerTest = {
  id: 0,
  conditionOfTest: "",
  equipmentLocation: "",
  system: "",
  systemField: "",
  numberCirculatingPumpsCreated: false,
  numberPrimaryPumpsCreated: false,
  manufacturer: "",
  modelNumber: "",
  serialNumber: "",
  series: "",
  mfgDate: "",
  fuelSource: "",
  forSteamWhenPicked: false,
  forElectricWhenPicked: false,
  boilerEfficiencyTest: false,
  projectName: "",
  isComplete: false,
  testStarted: false,
  testFinished: false,
  createdDate: new Date(),
  createdBy: "",
  updatedDate: new Date(),
  updatedBy: "",
  step: 0,
  designManufacturer: "",
  designModelNumber: "",
  fuelCaloricEnergyValueUnit: "",
};

const Divider = styled(MuiDivider)(spacing);
const Card = styled(MuiCard)(spacing);

const BoilerTest = () => {
  const location = useLocation();
  let navigate = useNavigate();
  const { systemOfMeasurement } = useSystemOfMeasurement(true);
  const [measurement, setMeasurement] = useState("");
  const { user } = useAuth();
  const params = useParams<{ id: string; step: string }>();
  const id = parseInt(params?.id === undefined ? "0" : params?.id);
  const [activeStep, setActiveStep] = useState<number>(
    parseInt(params?.step === undefined ? "0" : params?.step.slice(-1))
  );
  const validate: any = (fieldValues = values) => {
    let temp: Record<string, string> = { ...errors };

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, errors, setValues, setErrors, handleInputChange } =
    useFormTyped<IBoilerTest>(initialValues, false, validate);

  const { execute, isLoading } = useAsyncQuery<IBoilerTest>(
    boilerTestService.getById,
    {
      onSuccess: (result) => {
        const getData = async () => {
          const measurementSystemResponse =
            await getProjectService.getMeasurementSystem(result?.projectId);
          systemOfMeasurement.setSystem(measurementSystemResponse.data);
          setMeasurement(measurementSystemResponse.data);
        };
        getData();
        setValues(result);
      },
    }
  );

  const { execute: executeUpdate, isSubmitting: isSubmittingUpdate } =
    useAsyncMutation<number>(boilerTestService.update, {
      successfulMessage: "The report was updated successfully",
      onSuccess: async () => {
        await execute(id);
      },
    });

  const {
    execute: executeReport,
    data: dataReport,
    isLoading: isLoadingReport,
  } = useAsyncQuery<IProjectReport>(projectReportService.report);

  useEffect(() => {
    const getData = async () => {
      await execute(id);
      await executeReport(id, "BOTS");
    };

    if (id !== 0) getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useLayoutEffect(() => {
    setActiveStep(
      parseInt(params?.step === undefined ? "0" : params?.step.slice(-1))
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const handleSave = async () => {
    if (!validate()) return;
    await executeUpdate(values.id, values);
  };

  const disableStudy = useMemo(() => {
    return (
      values?.isComplete ||
      (dataReport?.isInUse && dataReport?.isInUseById !== user?.userId) ||
      dataReport?.isPartOfProject === false
    );
  }, [user?.userId, values?.isComplete, dataReport]);

  const showskeleton = useMemo(
    () => isLoading || isSubmittingUpdate || isLoadingReport,
    [isLoading, isSubmittingUpdate, isLoadingReport]
  );

  return (
    <>
      <HeaderStudyPage
        parentText="Project"
        parentLink="/"
        headerStudiesPage={{ code: "BOTS", system: values?.system, id: id }}
      />
      <Divider my={6} />

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <StudyWizard
            steps={steps}
            activeStep={activeStep - 1}
            projectId={values?.projectId}
            reportId={id}
            reportTypeId={values?.reportTypeId}
          ></StudyWizard>
        </Grid>
        <Grid item xs={12}>
          <Card mb={1}>
            <CardContent>
              {values && activeStep === 1 && (
                <Step1
                  values={values}
                  handleInputChange={handleInputChange}
                  errors={errors}
                  showskeleton={showskeleton}
                  disableStudy={disableStudy}
                  handleSave={handleSave}
                  navigate={navigate}
                  companyId={user?.companyId ?? 0}
                ></Step1>
              )}
              {values && activeStep === 2 && (
                <Step2
                  values={values}
                  handleInputChange={handleInputChange}
                  errors={errors}
                  showskeleton={showskeleton}
                  disableStudy={disableStudy}
                  handleSave={handleSave}
                  navigate={navigate}
                  companyId={user?.companyId ?? 0}
                  systemOfMeasurement={systemOfMeasurement}
                  setValues={setValues}
                ></Step2>
              )}
              {values && activeStep === 3 && (
                <Step3
                  values={values}
                  handleInputChange={handleInputChange}
                  errors={errors}
                  showskeleton={showskeleton}
                  disableStudy={disableStudy}
                  handleSave={handleSave}
                  navigate={navigate}
                  companyId={user?.companyId ?? 0}
                  systemOfMeasurement={systemOfMeasurement}
                  measurement={measurement}
                  setValues={setValues}
                ></Step3>
              )}
              {values && dataReport && activeStep === 4 && (
                <Step4
                  values={values}
                  handleInputChange={handleInputChange}
                  errors={errors}
                  showskeleton={showskeleton}
                  disableStudy={disableStudy}
                  handleSave={handleSave}
                  navigate={navigate}
                  companyId={user?.companyId ?? 0}
                  systemOfMeasurement={systemOfMeasurement}
                  setValues={setValues}
                ></Step4>
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default BoilerTest;
