import React, { useState, useEffect } from "react";
import { Grid, Stack } from "@mui/material";
import { useForm, Form, useLog } from "src/hooks";
import {
  FormCancelButton,
  FormSaveButton,
  FormSelect,
  FormText,
} from "src/components/formControls";
import Popup from "src/components/Popup";
import convertionService from "src/services/catalogs/convertionService";
import { IConversions } from "src/ts/interfaces/catalogs";
import { STATUSES } from "src/constants";
import { Validator } from "src/ts/types/Validator";
import { Permission as PermissionTypes } from "src/ts/enums";
import { usePermissions } from "src/hooks";

const initialValues: IConversions = {
  statusId: -1,
  fromName: "",
  formulaFrom: "",
  toName: "",
  formulaTo: "",
  id: 0,
};

interface ConfirmModalProps {
  isDialogOpen: boolean;
  setIsDialogOpen: (val: boolean) => void;
  onSave: () => void;
  currentValue: IConversions | null;
}

const ConversionForm = (props: ConfirmModalProps) => {
  const { isDialogOpen, setIsDialogOpen, onSave, currentValue } = props;
  const { fullAccess, readOnly } = usePermissions(PermissionTypes.Conversions);

  const { log } = useLog();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const validate = (fieldValues = values) => {
    let temp: Record<string, string> = { ...errors };

    temp.statusId = new Validator(fieldValues, "statusId")
      .selectedOption(-1, "The status is required.")
      .toString();

    temp.fromName = new Validator(fieldValues, "fromName")
      .isRequired("The From is required")
      .toString();

    temp.formulaFrom = new Validator(fieldValues, "formulaFrom")
      .isRequired("The Formula is required")
      .toString();

    temp.toName = new Validator(fieldValues, "toName")
      .isRequired("The To is required")
      .toString();

    temp.formulaTo = new Validator(fieldValues, "formulaTo")
      .isRequired("The To is required")
      .toString();

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues, false, validate);

  useEffect(() => {
    if (isDialogOpen) setValues(currentValue);
    else resetForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDialogOpen]);

  const saveHandler = async () => {
    if (!validate()) return;

    try {
      setIsSubmitting(true);
      if (values.id > 0) {
        await convertionService.update(values);
        log.success("The item was successfully updated");
      } else {
        await convertionService.add(values);
        log.success("The item was successfully created");
      }

      setIsSubmitting(false);
      setIsDialogOpen(false);
      onSave();
    } catch (error: any) {
      log.error(error?.message?.exceptionMessage ?? "Something went wrong");
    } finally {
      setIsSubmitting(false);
    }
  };

  const cancelHandler = () => {
    setIsDialogOpen(false);
  };

  const row = 12;
  return (
    <Popup
      openPopup={isDialogOpen}
      setOpenPopup={setIsDialogOpen}
      title={`Add New Item`}
      onClose={cancelHandler}
    >
      <Form>
        <Grid container spacing={5}>
          <Grid item xs={row}>
            <FormSelect
              name="statusId"
              label="Status"
              value={values.statusId}
              onChange={handleInputChange}
              options={STATUSES}
              error={errors.statusId}
              disable={isSubmitting || readOnly}
              defaultValue={{ key: -1, value: "Select a value" }}
            />
          </Grid>
          <Grid item xs={row}>
            <FormText
              name="fromName"
              label="From"
              value={values.fromName}
              onChange={handleInputChange}
              error={errors.fromName}
              maxLength={50}
              disabled={readOnly}
            />
          </Grid>
          <Grid item xs={row}>
            <FormText
              name="formulaFrom"
              label="Formula"
              value={values.formulaFrom}
              onChange={handleInputChange}
              error={errors.formulaFrom}
              maxLength={50}
              disabled={readOnly}
            />
          </Grid>
          <Grid item xs={row}>
            <FormText
              name="toName"
              label="To"
              value={values.toName}
              onChange={handleInputChange}
              error={errors.toName}
              maxLength={50}
              disabled={readOnly}
            />
          </Grid>
          <Grid item xs={row}>
            <FormText
              name="formulaTo"
              label="Formula"
              value={values.formulaTo}
              onChange={handleInputChange}
              error={errors.formulaTo}
              maxLength={50}
              disabled={readOnly}
            />
          </Grid>
        </Grid>
      </Form>
      <>
        <Stack
          direction="row"
          spacing={2}
          justifyContent="center"
          alignItems="center"
        >
          <FormCancelButton
            onClick={cancelHandler}
            isSubmitting={isSubmitting}
          />
          {fullAccess && (
            <FormSaveButton onClick={saveHandler} isSubmitting={isSubmitting} />
          )}
        </Stack>
      </>
    </Popup>
  );
};

export default ConversionForm;
