import React from "react";
import { Button } from "@mui/material";

interface ButtonProps {
  onClick: (e: React.MouseEvent<HTMLElement>) => void;
  isSubmitting: boolean;
  fullWidth?: boolean | undefined;
  disabled?: boolean;
  text?: string;
  size?: "small" | "medium" | "large";
}

const FormCancelButton = (props: ButtonProps) => {
  const { onClick, isSubmitting, fullWidth, disabled, text, size } = props;

  return (
    <Button
      onClick={onClick}
      variant="outlined"
      color="primary"
      disabled={isSubmitting || disabled}
      fullWidth={fullWidth}
      size={size ?? "small"}
    >
      {text ?? "Cancel"}
    </Button>
  );
};

export default FormCancelButton;
