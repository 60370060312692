import axios from "../utils/axios";
import {
  ITeamMembersTechnician,
  IMemberTeam,
  ITemMemberSend,
} from "../ts/interfaces/teamMembers";
import { IUserContactInfo } from "src/ts/interfaces";

const getTeamMemberKeyValue = async () => {
  return axios.get(`TeamMember/keyValueForwardBidNotification`);
};

const getTeamMember = async (disabled: boolean, companyId: number) => {
  return axios.get(`teamMember?disabled=${disabled}&companyId=${companyId}`);
};

const notificationTeamMember = async (id: number) => {
  return axios.get(`TeamMember/notify/${id}?newRoute=true`);
};

const updateTeamMemberData = async (data: ITeamMembersTechnician) => {
  return axios.put(`teamMember`, data);
};

const addNewTeamMember = async (data: IMemberTeam) => {
  return axios.post(`teamMember`, data);
};

const dataTeamMember = async (memberId: number) => {
  return axios.get(`teamMember/${memberId}`);
};

const deleteTeamMember = async (memberId: number) => {
  return axios.delete(`teamMember/${memberId}`);
};

const checkEmailTeamMember = async (email: string) => {
  return axios.get(`teamMember/checkEmail?email=${email}`);
};

const keyValue = async () => {
  return axios.get(`teamMember/keyValue`);
};

const getContactInfo = async (teamMemberId: number) => {
  return axios.get<IUserContactInfo>(
    `teamMember/memorandum?id=${teamMemberId}`
  );
};

const confirmed = async () => {
  return axios.get<IMemberTeam[]>(`teamMember/confirmed`);
};

const teamMemberList = () => {
  return axios.get<ITemMemberSend[]>(`teamMember/keyValueForward`);
};

const getByCode = (code: string) => {
  return axios.get<IMemberTeam>(`teamMember/code/${code}`);
};

const confirmByCode = (code: string) => {
  return axios.get(`teamMember/confirm?code=${code}`);
};

//
const teamMemberServices = {
  getTeamMemberKeyValue,
  getTeamMember,
  notificationTeamMember,
  updateTeamMemberData,
  addNewTeamMember,
  dataTeamMember,
  deleteTeamMember,
  checkEmailTeamMember,
  keyValue,
  getContactInfo,
  confirmed,
  teamMemberList,
  getByCode,
  confirmByCode,
};

export default teamMemberServices;
