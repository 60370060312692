import React from "react";
import { Grid } from "@mui/material";
import { FormText } from "src/components/formControls";
import { FooterButtons } from "../../components/FooterButtons";
import { IBoilerTest } from "src/ts/interfaces/study/boiler";
import { steps } from "./const";
import { NavigateFunction } from "react-router-dom";

interface Props {
  values: IBoilerTest;
  handleInputChange: (e: any) => void;
  errors: Record<string, string>;
  showskeleton: boolean;
  disableStudy: boolean;
  handleSave: () => Promise<void>;
  navigate: NavigateFunction;
  companyId: number;
}

const Step1 = ({
  values,
  handleInputChange,
  errors,
  showskeleton,
  disableStudy,
  handleSave,
  navigate,
  companyId,
}: Props) => {
  const nextStepHandler = async () => {
    if (!disableStudy) handleSave();
    navigate(`/studies/BoilerTest/step2/${values?.id}/`);
  };
  return (
    <Grid container spacing={5}>
      <Grid item xs={6}>
        <FormText
          name="projectName"
          label="Project Name"
          value={values?.projectName}
          onChange={handleInputChange}
          error={errors.projectName}
          showSkeleton={showskeleton}
          disabled={disableStudy}
        />
      </Grid>
      <Grid item xs={6}>
        <FormText
          name="system"
          label="System"
          value={values?.system}
          onChange={handleInputChange}
          error={errors.system}
          showSkeleton={showskeleton}
          disabled={disableStudy}
          maxLength={50}
        />
      </Grid>
      <Grid item xs={6}>
        <FormText
          name="equipmentLocation"
          label="Equipment location"
          value={values?.equipmentLocation}
          onChange={handleInputChange}
          error={errors.equipmentLocation}
          showSkeleton={showskeleton}
          disabled={disableStudy}
        />
      </Grid>
      <Grid item xs={6}>
        <FormText
          name="conditionOfTest"
          label="Condition of test"
          value={values?.conditionOfTest}
          onChange={handleInputChange}
          error={errors.conditionOfTest}
          showSkeleton={showskeleton}
          disabled={disableStudy}
        />
      </Grid>
      <Grid item xs={12}>
        <FooterButtons
          activeStep={0}
          stepsCount={steps.length}
          projectId={values?.projectId ?? 0}
          companyId={companyId}
          isSaving={showskeleton}
          disabled={disableStudy}
          reportName="Static Report"
          saveHandler={handleSave}
          nextStepHandler={nextStepHandler}
          isComplete={values?.isComplete ?? true}
        />
      </Grid>
    </Grid>
  );
};

export default Step1;
