import axios from "src/utils/axios";
import { IOutletReportItem, IOutletReportItems } from "src/ts/interfaces";

const component = (id: number) => {
  return axios.get<IOutletReportItem[]>(`OutletReportItem/component/${id}`);
};

const remove = (outletReportItemId: number) => {
  return axios.delete(`OutletReportItem/${outletReportItemId}`);
};

const addItem = (item: IOutletReportItem) => {
  return axios.post("OutletReportItem", item);
};

const updateItem = (item: IOutletReportItem) => {
  return axios.put<IOutletReportItem>("OutletReportItem/Item", item);
};

const updateAllItems = (items: IOutletReportItem[]) => {
  return axios.put<IOutletReportItem>("OutletReportItem/AllItems", items);
};

const removeByComponent = (componentId: number) => {
  return axios.delete(`OutletReportItem/component/${componentId}`);
};

const getByReportId = (outletReportId: number) => {
  return axios.get<IOutletReportItems[]>(
    `OutletReportItem/report/${outletReportId}`
  );
};

//

const outletReportItemService = {
  component,
  remove,
  addItem,
  updateItem,
  updateAllItems,
  removeByComponent,
  getByReportId,
};

export default outletReportItemService;
