import axios from "src/utils/axios";
import { IEnergyStudyAirHydronicsDTO } from "src/ts/interfaces";

const update = (id: number, energyReport: IEnergyStudyAirHydronicsDTO) => {
  return axios.put<IEnergyStudyAirHydronicsDTO>(
    `EnergyStudyAirHydronicsPut/${id}`,
    energyReport
  );
};

const beginTime = (id: number, energyReport: IEnergyStudyAirHydronicsDTO) => {
  return axios.put<IEnergyStudyAirHydronicsDTO>(
    `EnergyStudyAirHydronicsPut/BeginTime/${id}`,
    energyReport
  );
};

const endTime = (id: number, energyReport: IEnergyStudyAirHydronicsDTO) => {
  return axios.put<IEnergyStudyAirHydronicsDTO>(
    `EnergyStudyAirHydronicsPut/EndTimeReact/${id}`,
    energyReport
  );
};

const beginTimeSteadyOff = (
  id: number,
  energyReport: IEnergyStudyAirHydronicsDTO
) => {
  return axios.put<IEnergyStudyAirHydronicsDTO>(
    `EnergyStudyAirHydronicsPut/BeginTimeSteadyOff/${id}`,
    energyReport
  );
};

const endTimeSteadyOff = (
  id: number,
  energyReport: IEnergyStudyAirHydronicsDTO
) => {
  return axios.put<IEnergyStudyAirHydronicsDTO>(
    `EnergyStudyAirHydronicsPut/EndTimeSteadyOffReact/${id}`,
    energyReport
  );
};

const beginTimeEnergyOn = (
  id: number,
  energyReport: IEnergyStudyAirHydronicsDTO
) => {
  return axios.put<IEnergyStudyAirHydronicsDTO>(
    `EnergyStudyAirHydronicsPut/BeginTimeSteadyOn/${id}`,
    energyReport
  );
};

const endTimeEnergyOn = (
  id: number,
  energyReport: IEnergyStudyAirHydronicsDTO
) => {
  return axios.put<IEnergyStudyAirHydronicsDTO>(
    `EnergyStudyAirHydronicsPut/EndTimeSteadyOnReact/${id}`,
    energyReport
  );
};

const beginTimeSteadyOn = (
  id: number,
  energyReport: IEnergyStudyAirHydronicsDTO
) => {
  return axios.put<IEnergyStudyAirHydronicsDTO>(
    `EnergyStudyAirHydronicsPut/BeginTimeSteadyStateOn/${id}`,
    energyReport
  );
};

const endTimeSteadyOn = (
  id: number,
  energyReport: IEnergyStudyAirHydronicsDTO
) => {
  return axios.put<IEnergyStudyAirHydronicsDTO>(
    `EnergyStudyAirHydronicsPut/EndTimeSteadyStateOnReact/${id}`,
    energyReport
  );
};

const endFinalTest = (
  id: number,
  energyReport: IEnergyStudyAirHydronicsDTO
) => {
  return axios.put<IEnergyStudyAirHydronicsDTO>(
    `EnergyStudyAirHydronicsPut/EndFinalTest/${id}`,
    energyReport
  );
};

const energyStudyAirHydronicsPutService = {
  update,
  beginTime,
  endTime,
  beginTimeSteadyOff,
  endTimeSteadyOff,
  beginTimeEnergyOn,
  endTimeEnergyOn,
  beginTimeSteadyOn,
  endTimeSteadyOn,
  endFinalTest,
};

export default energyStudyAirHydronicsPutService;
