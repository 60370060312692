import React from "react";
import styled from "@emotion/styled";
import { withTheme } from "@emotion/react";
import Chart from "react-chartjs-2";
import { MoreVertical } from "react-feather";
import "chartjs-adapter-moment";
import {
  Card as MuiCard,
  CardContent,
  CardHeader,
  IconButton,
  Theme,
} from "@mui/material";
import { spacing } from "@mui/system";
import { alpha } from "@mui/material/styles";

import { IWalletPurchasesResume } from "src/ts/interfaces";
import {
  Chart as ChartJS,
  TimeScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ChartOptions,
} from "chart.js";
ChartJS.register(
  TimeScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const Card = styled(MuiCard)(spacing);

const ChartWrapper = styled.div`
  height: 378px;
`;

interface Props {
  theme: Theme & { palette: any };
  walletPurchasesResume: IWalletPurchasesResume[];
}

function LineChart({ theme, walletPurchasesResume }: Props) {
  const data = (canvas: any) => {
    const ctx = canvas.getContext("2d");

    const gradient = ctx.createLinearGradient(0, 0, 0, 300);
    gradient.addColorStop(0, alpha(theme.palette.secondary.main, 0.0875));
    gradient.addColorStop(1, "rgba(0, 0, 0, 0)");

    // let resArr: string[] = [];
    // walletPurchasesResume.filter(function (item) {
    //   var i = resArr.findIndex(
    //     (x) => x === moment(item.date).format("yyyy-MM-DD")
    //   );

    //   if (i <= -1) {
    //     resArr.push(moment(item.date).format("yyyy-MM-DD"));
    //   }
    //   return null;
    // });

    // console.log(resArr);

    let signUpAmount = 0;
    let projectAmount = 0;
    let testAndBalanceAmount = 0;
    let energyAmount = 0;
    let storageAmount = 0;
    let othersAmount = 0;

    let signUp: {
      x: Date;
      y: number;
    }[] = [];
    let project: {
      x: Date;
      y: number;
    }[] = [];
    let testAndBalance: {
      x: Date;
      y: number;
    }[] = [];
    let energy: {
      x: Date;
      y: number;
    }[] = [];

    let storage: {
      x: Date;
      y: number;
    }[] = [];

    let others: {
      x: Date;
      y: number;
    }[] = [];

    for (let i = 0; i < walletPurchasesResume.length; i++) {
      const item = walletPurchasesResume[i];

      const purchaseDate = new Date(item.date);
      switch (item.classification) {
        case "SignUp":
          signUpAmount += item.amount * -1;
          signUp.push({ x: purchaseDate, y: signUpAmount });
          break;
        case "Project":
          projectAmount += item.amount * -1;
          project.push({ x: purchaseDate, y: projectAmount });
          break;
        case "TestAndBalanceCertification":
          testAndBalanceAmount += item.amount * -1;
          testAndBalance.push({ x: purchaseDate, y: testAndBalanceAmount });
          break;
        case "EnergyCertification":
          energyAmount += item.amount * -1;
          energy.push({ x: purchaseDate, y: energyAmount });
          break;
        case "Space":
          storageAmount += item.amount * -1;
          storage.push({ x: purchaseDate, y: storageAmount });
          break;
        default:
          othersAmount += item.amount * -1;
          others.push({ x: purchaseDate, y: othersAmount });
          break;
      }
    }

    return {
      datasets: [
        {
          label: "signUp",
          fill: true,
          backgroundColor: gradient,
          borderColor: theme.palette.secondary.main,
          tension: 0.4,
          data: signUp,
        },
        {
          label: "Project",
          fill: true,
          backgroundColor: "transparent",
          borderColor: "#8bc34a",
          tension: 0.4,
          data: project,
        },
        //////////////
        {
          label: "T&B Cert",
          fill: true,
          backgroundColor: "transparent",
          borderColor: "#009688",
          tension: 0.4,
          data: testAndBalance,
        },
        {
          label: "Energy Cert",
          fill: true,
          backgroundColor: "transparent",
          borderColor: "#ffc107",
          tension: 0.4,
          data: energy,
        },
        {
          label: "Storage",
          fill: true,
          backgroundColor: "transparent",
          borderColor: "#2196f3",
          tension: 0.4,
          data: storage,
        },
        {
          label: "Other",
          fill: true,
          backgroundColor: "transparent",
          borderColor: "#ff5722",
          tension: 0.4,
          data: others,
        },
      ],
    };
  };

  const options: ChartOptions = {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
      },
    },
    scales: {
      x: {
        type: "time",
        time: {
          unit: "day",
        },
        grid: {
          display: false,
        },
      },
      y: {
        grid: {
          color: "rgba(0,0,0,0.0375)",
          //fontColor: "#fff",
        },
        title: {
          display: true,
          text: "Amount ($USD)",
        },
      },
    },
  };

  return (
    <Card mb={6}>
      <CardHeader
        action={
          <IconButton aria-label="settings" size="large">
            <MoreVertical />
          </IconButton>
        }
        title="Total revenue"
      />
      <CardContent>
        <ChartWrapper>
          <Chart type="line" data={data} options={options} />
        </ChartWrapper>
      </CardContent>
    </Card>
  );
}
export default withTheme(LineChart);
