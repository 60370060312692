import Popup from "src/components/Popup";
import {
  FormAcceptButton,
  FormCancelButton,
} from "src/components/formControls";
import { Stack, Typography, Grid } from "@mui/material";
import { IStorage } from "src/ts/interfaces/storage";
import { useAuth, useLog } from "src/hooks";
import purchaseService from "src/services/purchaseService";
import PurchaseSummary from "src/pages/Cart/PurchaseSummary";
import { useState } from "react";

interface ReviewORderPops {
  showPopUp: boolean;
  setShowPopUp: (val: boolean) => void;
  getInformationStorage: IStorage;
  dataWalletBalance: number;
  companyId: string;
  setInsufficientFundsShowPopUp: (val: boolean) => void;
  setNotDefaultCardShowPopUp: (val: boolean) => void;
  setSubmit: (val: boolean) => void;
}

export default function ReviewOrderPopUp({
  showPopUp,
  setShowPopUp,
  getInformationStorage,
  dataWalletBalance,
  companyId,
  setInsufficientFundsShowPopUp,
  setNotDefaultCardShowPopUp,
  setSubmit,
}: ReviewORderPops) {
  const { log } = useLog();
  const { user } = useAuth();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const conditionModal = (value: number, dataMessage: string) => {
    switch (value) {
      case 0:
        log.success(dataMessage);
        setSubmit(true);
        break;
      case 1:
        setInsufficientFundsShowPopUp(true);
        break;
      case 3:
        setNotDefaultCardShowPopUp(true);
        break;
      default:
        log.error(dataMessage);
        break;
    }
  };

  const confirmHandler = async () => {
    try {
      setIsSubmitting(true);
      const response = await purchaseService.purchase({
        companyId: Number(companyId),
        cuponCode: "",
        systemCostCatalogId: getInformationStorage?.id ?? 0,
      });
      conditionModal(response.data.statusCode, response.data.resultMessage);
      setShowPopUp(false);
    } catch (error: any) {
      log.error(error?.message?.exceptionMessage ?? "Something went wrong");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <Popup
        title={"Review your order"}
        openPopup={showPopUp}
        setOpenPopup={setShowPopUp}
        onClose={() => {
          setShowPopUp(false);
        }}
        size="sm"
        disableClickOutside
        isSubmitting={isSubmitting}
      >
        <>
          <Grid container>
            <Grid item xs={4} spacing={2}>
              <Typography sx={{ textAlign: "right" }} mr={3}>
                <b>Item Description</b>
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography mb={5}>{getInformationStorage?.name}</Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography sx={{ textAlign: "right" }} mr={3}>
                <b>Price</b>
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography mb={5}>
                ${Number(getInformationStorage?.pricing).toFixed(2)} USD
              </Typography>
            </Grid>
            <PurchaseSummary
              companyId={user?.companyId}
              total={getInformationStorage?.pricing ?? 0}
            ></PurchaseSummary>
          </Grid>
        </>
        <Stack direction="row" spacing={3}>
          <FormCancelButton
            onClick={() => {
              setShowPopUp(false);
            }}
            isSubmitting={isSubmitting}
            text={"Cancel"}
          />
          <FormAcceptButton
            text="Confirm"
            onClick={() => {
              confirmHandler();
            }}
            isSubmitting={isSubmitting}
          />
        </Stack>
      </Popup>
    </>
  );
}
