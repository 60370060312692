import { useEffect, useState } from "react";
import Popup from "src/components/Popup";
import {
  FormButton,
  FormSelect,
  FormTextArea,
} from "src/components/formControls";
import {
  Divider as MuiDivider,
  Grid,
  Stack,
  Tab,
  Tabs,
  FormControlLabel,
  Typography,
  Radio,
  RadioGroup,
  Chip as MuiChip,
} from "@mui/material";
import { useAuth, useForm, useLoading, useLog } from "src/hooks";
import styled from "@emotion/styled";
import { SpacingProps, spacing } from "@mui/system";
import { Plus } from "react-feather";
import teamMemberServices from "src/services/teamMemberServices";
import { IKeyValue, IUserContactInfo } from "src/ts/interfaces";
import { userService } from "src/services";
import DialogMessagePopup from "src/components/DialogMessagePopup";
import { useNavigate } from "react-router-dom";
import { ColumnType } from "src/types/enhancedTable";
import { changeOrdersStatus, memorandumStatus, TABVALUES } from "src/constants";
import { GridActionButton } from "src/components/gridControls";
import LocalEnhancedTable from "src/components/localTable/LocalTable";
import { IChangeOrders } from "src/ts/interfaces/changeWorkOrder";
import changeOrdersService from "src/services/changeOrdersService";
import ChangeOrdersForwardPopUp from "./ChangeOrdersForwardPopUp";
import moment from "moment";
import FileUtils from "src/utils/file";
import { convertUTCDateToLocalDate } from "src/utils/utils";
const Divider = styled(MuiDivider)(spacing);
interface ChipProps extends SpacingProps {
  component?: React.ElementType;
  href?: string;
  icon?: JSX.Element | null;
}
interface ChangeWorkOrdersPopUpProps {
  showChangeOrdersPopUp: boolean;
  setShowChangeOrdersPopUp: (val: boolean) => void;
  projectId: number;
  defaultTab?: "new" | "all";
  projectCompleted: boolean;
}
const Chip = styled(MuiChip)<ChipProps>(spacing);
const initialValues: IChangeOrders = {
  id: 0,
  projectId: 0,
  notes: "",
  createdDate: new Date(),
  updatedDate: new Date(),
  codeVerification: "",
  rejectedComment: "",
  email: "",
  firstName: "",
  lastName: "",
  title: "",
  address: "",
  city: "",
  state: "",
  zipCode: "",
  workPhoneNumber: "",
  extension: "",
  cellPhoneNumber: "",
  isTeamMember: false,
};

const columns = (
  handleSendEmail: (row: IChangeOrders) => void,
  handleView: (row: IChangeOrders) => void,
  tab: number,
  projectCompleted: boolean,
  downloadFile: (changeOrderId: number) => void,
  loadingDownload: boolean
): ColumnType[] => [
  {
    id: "name",
    label: "Created By",
    type: "custom",
    sort: false,
    hide: tab !== TABVALUES.PENDING,
    callback: (row: IChangeOrders) => {
      return <>{row.createdBy?.getFullName}</>;
    },
  },
  {
    id: "notes",
    label: "Description",
    type: "string",
    sort: false,
  },

  {
    id: "name",
    label: "Approver",
    type: "custom",
    sort: false,
    callback: (row: IChangeOrders) => {
      return (
        <>
          {row.firstName} {row.lastName}
        </>
      );
    },
  },
  {
    id: "changeOrdersStatus.name",
    label: "Status",
    type: "custom",
    sort: false,
    callback: (row: IChangeOrders) => {
      return (
        <>
          {row.changeOrdersStatusId ===
            changeOrdersStatus.PendingApproval.id && (
            <Chip
              label={row?.changeOrdersStatus?.name}
              color="default"
              m={1}
              size="small"
            />
          )}
          {row.changeOrdersStatusId === changeOrdersStatus.Approved.id && (
            <Chip
              label={row?.changeOrdersStatus?.name}
              color="success"
              m={1}
              size="small"
            />
          )}
          {row.changeOrdersStatusId === changeOrdersStatus.Rejected.id && (
            <Chip
              label={row?.changeOrdersStatus?.name}
              color="error"
              m={1}
              size="small"
            />
          )}
        </>
      );
    },
  },
  {
    id: "createdDate",
    label: "Created Date",
    type: "utcDate",
    sort: false,
    format: "MM/DD/YYYY hh:mm A",
  },
  {
    id: "updatedDate",
    label: "Updated Date",
    type: "custom",
    sort: false,
    hide: tab === TABVALUES.PENDING,
    callback: (row: IChangeOrders) => {
      return (
        <>
          {row.changeOrdersStatus?.id !== memorandumStatus.PendingApproval.id &&
            convertUTCDateToLocalDate(row.updatedDate.toString() || "")?.format(
              "MM/DD/YYYY hh:mm A"
            )}
        </>
      );
    },
  },
  {
    id: "rejectedComment",
    label: "Comments",
    type: "string",
    sort: false,
    hide: tab === TABVALUES.PENDING,
  },
  {
    id: "actions",
    label: "Actions",
    type: "custom",
    sort: false,
    minWidth: "125px;",
    callback: (row: IChangeOrders) => {
      return (
        <>
          <Stack direction={"row"} spacing={2}>
            <GridActionButton
              type="view"
              onClick={() => {
                handleView(row);
              }}
              tooltip="View Change Work Orders"
            />
            {row.changeOrdersStatusId !==
              changeOrdersStatus.PendingApproval.id && (
              <GridActionButton
                type="email"
                onClick={() => {
                  handleSendEmail(row);
                }}
                tooltip="Forward Change Work Orders"
              />
            )}
            <GridActionButton
              type="download"
              onClick={() => {
                downloadFile(row.id);
              }}
              tooltip="Download"
              isLoading={loadingDownload}
            />
          </Stack>
        </>
      );
    },
  },
];
const columnsMyOrderChange = (
  handleSendEmail: (row: IChangeOrders) => void,
  handleView: (row: IChangeOrders) => void,
  projectCompleted: boolean,
  downloadFile: (changeOrderId: number) => void,
  loadingDownload: boolean
): ColumnType[] => [
  {
    id: "name",
    label: "Created By",
    type: "custom",
    sort: false,
    callback: (row: IChangeOrders) => {
      return <>{row.createdBy?.getFullName}</>;
    },
  },
  {
    id: "notes",
    label: "Description",
    type: "string",
    sort: false,
  },
  {
    id: "changeOrdersStatus.name",
    label: "Status",
    type: "custom",
    sort: false,
    callback: (row: IChangeOrders) => {
      return (
        <>
          {row.changeOrdersStatusId ===
            changeOrdersStatus.PendingApproval.id && (
            <Chip
              label={row?.changeOrdersStatus?.name}
              color="default"
              m={1}
              size="small"
            />
          )}
          {row.changeOrdersStatusId === changeOrdersStatus.Approved.id && (
            <Chip
              label={row?.changeOrdersStatus?.name}
              color="success"
              m={1}
              size="small"
            />
          )}
          {row.changeOrdersStatusId === changeOrdersStatus.Rejected.id && (
            <Chip
              label={row?.changeOrdersStatus?.name}
              color="error"
              m={1}
              size="small"
            />
          )}
        </>
      );
    },
  },
  {
    id: "createdDate",
    label: "Created Date",
    type: "utcDate",
    sort: false,
    format: "MM/DD/YYYY hh:mm A",
  },
  {
    id: "updatedDate",
    label: "Updated Date",
    type: "custom",
    sort: false,
    callback: (row: IChangeOrders) => {
      return (
        <>
          {row.changeOrdersStatus?.id !== memorandumStatus.PendingApproval.id &&
            convertUTCDateToLocalDate(row.updatedDate.toString() || "")?.format(
              "MM/DD/YYYY hh:mm A"
            )}
        </>
      );
    },
  },
  {
    id: "rejectedComment",
    label: "Comments",
    type: "string",
    sort: false,
  },
  {
    id: "actions",
    label: "Actions",
    type: "custom",
    sort: false,
    minWidth: "125px;",
    callback: (row: IChangeOrders) => {
      return (
        <>
          <Stack direction={"row"} spacing={2}>
            <GridActionButton
              type="view"
              onClick={() => {
                handleView(row);
              }}
              tooltip="View Change Work Orders"
            />
            {row.changeOrdersStatusId !==
              changeOrdersStatus.PendingApproval.id && (
              <GridActionButton
                type="email"
                onClick={() => {
                  handleSendEmail(row);
                }}
                tooltip="Forward Change Work Orders"
              />
            )}
            <GridActionButton
              type="download"
              onClick={() => {
                downloadFile(row.id);
              }}
              tooltip="Download"
              isLoading={loadingDownload}
            />
          </Stack>
        </>
      );
    },
  },
];

export default function ChangeWorkOrdersPopUp({
  showChangeOrdersPopUp,
  setShowChangeOrdersPopUp,
  projectId,
  defaultTab,
  projectCompleted,
}: ChangeWorkOrdersPopUpProps) {
  const validate = (fieldValues = values) => {
    let temp: Record<string, string> = { ...errors };

    temp.notes = fieldValues.notes === "" ? "This field is required" : "";

    if (fieldValues.isTeamMember) {
      if (!fieldValues.teamMemberId) {
        temp.teamMemberId = "This field is required";
      } else {
        temp.teamMemberId = "";
      }
    }

    if (!fieldValues.isTeamMember) {
      if (!fieldValues.userId) {
        temp.userId = "This field is required";
      } else {
        temp.userId = "";
      }
    }

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const [tab, setTab] = useState(
    defaultTab === "all" || projectCompleted ? 1 : 0
  );
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [teamMembers, setTeamMembers] = useState<IKeyValue<number, string>[]>(
    []
  );
  const navigate = useNavigate();
  const { log } = useLog();
  const [isLoading, setIsLoading] = useState(false);
  const [isForward, setisForward] = useState(false);
  const [refreshTable, setRefreshTable] = useState(false);
  const [redirectModal, setRedirectModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState<IUserContactInfo>();
  const [selectedChangeOrders, setSelectedChangeOrders] =
    useState<IChangeOrders>();
  const [changeOrdersList, setChangeOrdersList] = useState<IChangeOrders[]>([]);
  const [myChangeOrdersList, setMyChangeOrdersList] = useState<IChangeOrders[]>(
    []
  );
  const [users, setUsers] = useState<IKeyValue<number, string>[]>([]);
  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setTab(newValue);
  };
  const handleClosePopUp = () => {
    setShowChangeOrdersPopUp(false);
  };
  const { values, setValues, errors, setErrors, handleInputChange } = useForm(
    initialValues,
    false,
    validate
  );
  const { user } = useAuth();
  const isTechnician =
    user?.role === "TemporalAuditor" ||
    user?.role === "Auditor" ||
    user?.role === "TemporalEnergyAuditor" ||
    user?.role === "EnergyAuditor";

  const { startRequest, endRequest, isLoading: loadingDownload } = useLoading();

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await teamMemberServices.keyValue();
        setTeamMembers(response.data);

        const responseInternals = await userService.getDistinctTechnicianList(
          projectId
        );

        const keyValues = responseInternals.data.map(
          (item: { id: any; name: any; lastName: string }) => {
            return {
              key: item.id,
              value: `${item.name} ${item.lastName}`,
            };
          }
        );
        setUsers(keyValues);
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      }
    };
    if (user?.companyId) getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.companyId]);

  useEffect(() => {
    setIsLoading(true);
    const getData = async () => {
      const response = await changeOrdersService.getAll(projectId, false);
      setChangeOrdersList(response.data);
      const responseMy = await changeOrdersService.getAll(projectId, true);
      setMyChangeOrdersList(responseMy.data);
      setIsLoading(false);
    };
    if (projectId > 0) getData();
  }, [projectId, refreshTable, tab]);

  useEffect(() => {
    values.projectId = projectId;
    setValues({ ...values });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId]);

  const handleTeamMember = async (e: any) => {
    values.userId = null;
    values.teamMemberId = e.target.value;
    setValues({ ...values });
    if (!isForward) {
      var result = await teamMemberServices.getContactInfo(values.teamMemberId);
      setSelectedUser(result.data);
    }
  };

  const handleUser = async (e: any) => {
    values.teamMemberId = null;
    values.userId = e.target.value;
    setValues({ ...values });
    if (!isForward) {
      var result = await userService.getUserInfo(values.userId);
      setSelectedUser(result.data);
    }
  };

  const handleChangeRadio = () => {
    setSelectedUser(undefined);
  };

  const handleAddChangeOrders = async () => {
    if (!validate()) return;
    setIsLoading(true);
    await changeOrdersService.create(values);
    setRefreshTable(!refreshTable);
    setValues(initialValues);
    setTab(1);
    setIsLoading(false);
    setSelectedUser(undefined);
  };

  const handleSendEmail = async (row: IChangeOrders) => {
    setSelectedChangeOrders(row);
    setisForward(true);
    setShowInfoModal(true);
  };

  const handleView = async (changeOrders: IChangeOrders) => {
    setSelectedChangeOrders(changeOrders);
    setisForward(false);
    setShowInfoModal(true);
  };

  const handleRefreshMainTable = async () => {
    setRefreshTable(!refreshTable);
  };

  const downloadFile = async (changeOrderId: number) => {
    try {
      startRequest("Downloading");
      const response = await changeOrdersService.getDownloadFile(
        projectId,
        changeOrderId
      );
      let fileName = `Change Order_${changeOrderId}.pdf`;
      FileUtils.downloadFile(response.data, fileName);
      endRequest("Downloading");
    } catch (error: any) {
      log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      endRequest("Downloading");
    }
  };

  return (
    <>
      <Popup
        title="Change Work Orders"
        openPopup={showChangeOrdersPopUp}
        setOpenPopup={setShowChangeOrdersPopUp}
        onClose={handleClosePopUp}
        size="md"
        paperProps={{
          style: {
            height: "600px",
          },
        }}
      >
        <>
          <Grid container>
            <Grid item xs={12}>
              <Tabs
                value={tab}
                onChange={handleChangeTab}
                aria-label="Buildings"
              >
                {!projectCompleted && <Tab label="Add New" id="0" value={0} />}
                <Tab label="Pending" id="1" value={1} />
                <Tab
                  label="Approved"
                  id="2"
                  value={2}
                  style={{ color: "green" }}
                />
                <Tab
                  label="Rejected"
                  id="3"
                  value={3}
                  style={{ color: "red" }}
                />
                <Tab label="My Change Work Orders" id="4" value={4} />
              </Tabs>
              <Divider my={3}></Divider>
            </Grid>
            {tab === 0 && (
              <>
                <Grid item xs={12} pb={4}>
                  <FormTextArea
                    name="notes"
                    label="Change Work Orders"
                    value={values.notes}
                    onChange={handleInputChange}
                    error={errors.notes}
                    placeholder="Please be detailed with your Change/Work Order request. Include such things that you wish to have approved, for example: Detailed scope, intent, parts, labor, hours, hourly cost, total cost, etc. Increased detail expedites order approval."
                  />
                </Grid>
                <Grid item xs={1}>
                  <Typography pt={2} variant="subtitle2">
                    Approver:
                  </Typography>
                </Grid>
                <Grid item xs={5}>
                  <RadioGroup
                    aria-label="Approver"
                    name="isTeamMember"
                    value={values.isTeamMember}
                    onChange={handleInputChange}
                    row
                  >
                    <FormControlLabel
                      value="false"
                      control={<Radio />}
                      label="Internal / AMP Users"
                      onChange={handleChangeRadio}
                    />
                    <FormControlLabel
                      value="true"
                      control={<Radio />}
                      label="External / Team Members"
                      onChange={handleChangeRadio}
                    />
                  </RadioGroup>
                </Grid>
                <Grid item xs={3}>
                  {values.isTeamMember === "true" ? (
                    <FormSelect
                      name="teamMemberId"
                      label="Team Member"
                      value={values.teamMemberId}
                      onChange={handleTeamMember}
                      options={teamMembers}
                      error={errors.teamMemberId}
                    />
                  ) : (
                    <FormSelect
                      name="userId"
                      label="Registered User"
                      value={values.userId}
                      onChange={handleUser}
                      options={users}
                      error={errors.userId}
                    />
                  )}
                </Grid>
                <Grid item xs={3} sx={{ textAlign: "center" }}>
                  {!isTechnician && (
                    <FormButton
                      text={"Add New Approver"}
                      onClick={() => setShowChangeOrdersPopUp(true)}
                      startIcon={<Plus />}
                      size="small"
                      variant="outlined"
                    />
                  )}
                </Grid>
                <Grid item xs={12} sx={{ textAlign: "center" }}>
                  <Typography variant="subtitle2">
                    If outside external message is required, create your target
                    person as a team member{" "}
                  </Typography>
                  <Divider my={3}></Divider>
                </Grid>
                {selectedUser && (
                  <>
                    <Grid item xs={12}>
                      <Typography variant="subtitle2">
                        Directed or Advise by:
                      </Typography>
                      <Typography display="inline">
                        <Typography variant="subtitle2" display="inline">
                          Name:
                        </Typography>
                        <Typography display="inline">
                          {" "}
                          {selectedUser?.firstName} {selectedUser?.lastName}
                        </Typography>
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography display="inline">
                        <Typography variant="subtitle2" display="inline">
                          Email:{" "}
                        </Typography>
                        <Typography display="inline">
                          {" "}
                          {selectedUser?.email}
                        </Typography>
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography display="inline">
                        <Typography variant="subtitle2" display="inline">
                          Cell Phone Number:
                        </Typography>
                        <Typography display="inline">
                          {" "}
                          {selectedUser?.cellPhoneNumber}
                        </Typography>
                      </Typography>
                      {"    "}
                    </Grid>
                  </>
                )}
              </>
            )}
            {tab === 1 && (
              <Grid item xs={12}>
                <LocalEnhancedTable<IChangeOrders>
                  refreshGrid={true}
                  columns={columns(
                    handleSendEmail,
                    handleView,
                    tab,
                    projectCompleted,
                    downloadFile,
                    loadingDownload
                  )}
                  data={changeOrdersList.filter(
                    (item: IChangeOrders) =>
                      item.changeOrdersStatus?.name ===
                      changeOrdersStatus.PendingApproval.name
                  )}
                  showSkeleton={isLoading}
                  orderColumn="desc"
                  defaultSortColumn="createdDate"
                />
              </Grid>
            )}
            {tab === 2 && (
              <Grid item xs={12}>
                <LocalEnhancedTable<IChangeOrders>
                  refreshGrid={true}
                  columns={columns(
                    handleSendEmail,
                    handleView,
                    tab,
                    projectCompleted,
                    downloadFile,
                    loadingDownload
                  )}
                  data={changeOrdersList.filter(
                    (item: IChangeOrders) =>
                      item.changeOrdersStatus?.name ===
                      changeOrdersStatus.Approved.name
                  )}
                  showSkeleton={isLoading}
                  orderColumn="desc"
                  defaultSortColumn="createdDate"
                />
              </Grid>
            )}
            {tab === 3 && (
              <Grid item xs={12}>
                <LocalEnhancedTable<IChangeOrders>
                  refreshGrid={true}
                  columns={columns(
                    handleSendEmail,
                    handleView,
                    tab,
                    projectCompleted,
                    downloadFile,
                    loadingDownload
                  )}
                  data={changeOrdersList.filter(
                    (item: IChangeOrders) =>
                      item.changeOrdersStatus?.name ===
                      changeOrdersStatus.Rejected.name
                  )}
                  showSkeleton={isLoading}
                  orderColumn="desc"
                  defaultSortColumn="createdDate"
                />
              </Grid>
            )}
            {tab === 4 && (
              <Grid item xs={12}>
                <LocalEnhancedTable<IChangeOrders>
                  refreshGrid={true}
                  columns={columnsMyOrderChange(
                    handleSendEmail,
                    handleView,
                    projectCompleted,
                    downloadFile,
                    loadingDownload
                  )}
                  data={myChangeOrdersList}
                  showSkeleton={isLoading}
                  orderColumn="desc"
                  defaultSortColumn="createdDate"
                />
              </Grid>
            )}
          </Grid>
        </>
        <Stack
          justifyContent="flex-end"
          alignItems="flex-start"
          direction="row"
        >
          <FormButton
            size="medium"
            variant="outlined"
            text="Close"
            onClick={handleClosePopUp}
          />
          {tab === 0 && (
            <Grid pl={2}>
              <FormButton
                size="medium"
                text="Add Change Work Orders"
                onClick={handleAddChangeOrders}
              />
            </Grid>
          )}
        </Stack>
      </Popup>
      {selectedChangeOrders && (
        <ChangeOrdersForwardPopUp
          showForwardPopUp={showInfoModal}
          setShowForwardPopUp={setShowInfoModal}
          changeOrders={selectedChangeOrders}
          isForward={isForward}
          isMyOrder={tab === 4}
          refreshMainTable={handleRefreshMainTable}
          projectId={projectId}
          projectCompleted={projectCompleted}
        ></ChangeOrdersForwardPopUp>
      )}

      <DialogMessagePopup
        title={"Warning"}
        text={
          "In order to add a new user, you will be redirected to another screen which will cancel the current Change Work Orders."
        }
        showPopup={redirectModal}
        setShowPopup={setRedirectModal}
        onSave={() => {
          navigate("/auth/sign-in");
        }}
        onCancel={() => {
          setRedirectModal(false);
        }}
        isSubmitting={false}
      />
    </>
  );
}
