import axios from "src/utils/axios";
import { IFanSubType } from "src/ts/interfaces/catalogs";

const getFanSubTypes = () => {
  return axios.get<IFanSubType[]>("FanSubType/group");
};

const fanSubTypeService = {
  getFanSubTypes,
};

export default fanSubTypeService;
