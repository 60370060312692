/* eslint-disable react-hooks/exhaustive-deps */
import {
  CardContent,
  Grid,
  Divider as MuiDivider,
  Card as MuiCard,
} from "@mui/material";

import { spacing } from "@mui/system";
import styled from "@emotion/styled";
import HeaderStudyPage from "src/components/page/HeaderStudyPage";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAsyncQuery, useLog, useAuth } from "src/hooks";
import { FormText } from "src/components/formControls";
import useFormTyped from "src/hooks/useFormTyped";
import { IPumpReportDTO, IReportType } from "src/ts/interfaces";
import { IProjectReport } from "src/ts/interfaces/project/projectDto";
import projectReportService from "src/services/study/projectReportService";
import reportTypeService from "src/services/study/reportTypeService";
import { steps } from "../const/consts";
import { FooterButtons } from "../../components/FooterButtons";
import pumpReportService from "src/services/study/pumpReportService";
import StudyWizard from "../../components/StudyWizard";

const initialValues: any = {
  projectName: "",
  system: "",
  equipmentLocation: "",
  conditionOfTest: "",
};

const Divider = styled(MuiDivider)(spacing);
const Card = styled(MuiCard)(spacing);

const PumpStep1 = () => {
  const { log } = useLog();
  const { user } = useAuth();
  const params = useParams<{ id: string; step: string }>();
  const id = parseInt(params?.id === undefined ? "0" : params?.id);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [title, setTitle] = useState<string>();
  const [code, setCode] = useState<string>();

  let navigate = useNavigate();

  const validate: any = (fieldValues = values) => {
    let temp: Record<string, string> = { ...errors };

    if (disableStudy()) return true;

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange } =
    useFormTyped<IPumpReportDTO>(initialValues, false, validate);

  const activeStep = 0;

  const { execute: executeReport, data: dataReport } =
    useAsyncQuery<IProjectReport>(projectReportService.report);

  const { execute, isLoading } = useAsyncQuery<IPumpReportDTO>(
    pumpReportService.getBaseById,
    {
      onSuccess: (dataResult) => {
        const getData = async () => {
          setValues({ ...dataResult });
        };
        getData();
      },
    }
  );

  const { execute: executeReportType, isLoading: isLoadingReportType } =
    useAsyncQuery<IReportType>(reportTypeService.getReportTypeById, {
      onSuccess: (dataResult) => {
        const getData = async () => {
          setCode(dataResult.code);
          setTitle(dataResult.name);
        };
        getData();
      },
    });

  const disableStudy = () => {
    return (
      values?.isComplete ||
      (dataReport?.isInUse && dataReport?.isInUseById !== user?.userId) ||
      dataReport?.isPartOfProject === false
    );
  };

  useEffect(() => {
    if (values?.reportTypeId) executeReportType(values?.reportTypeId);
  }, [values?.reportTypeId]);

  useEffect(() => {
    const getData = async () => {
      try {
        await execute(id);
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      }
    };

    if (id !== 0) getData();
  }, [id]);

  useEffect(() => {
    const getData = async () => {
      try {
        await executeReport(id, code);
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      }
    };

    if (id !== 0 && code !== undefined) getData();
  }, [id, code]);

  const saveHandler = async () => {
    if (!validate()) return;
    try {
      setIsUpdating(true);
      await pumpReportService.update(id, values);
      log.success("Report was updated successfully");
      setIsUpdating(false);
    } catch (error) {}
  };

  const nexStepHandler = async () => {
    if (!validate()) return;
    try {
      if (!disableStudy()) {
        setIsSubmitting(true);
        await pumpReportService.update(id, values);
        log.success("Report was updated successfully");
        setIsSubmitting(false);
      }
      navigate(`/studies/pumpReport/step2/${id}`);
    } catch (error) {}
  };

  const row = 12;

  return (
    <>
      <HeaderStudyPage
        headerStudiesPage={{ code: code, system: values?.system, id: id }}
        parentText="Project"
        parentLink={`/app/ProjectStudies/${values?.projectId}/${user?.companyId}`}
      />
      <Divider my={6} />

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card mb={6}>
            <CardContent>
              <StudyWizard
                steps={steps}
                activeStep={activeStep}
                projectId={dataReport?.projectId}
                reportId={dataReport?.reportId}
                reportTypeId={dataReport?.reportTypeId}
              />
              <Grid container spacing={5}>
                <Grid item xs={row}>
                  <FormText
                    name="projectName"
                    label="Project Name"
                    value={`${values.projectName}`}
                    onChange={handleInputChange}
                    error={errors.projectName}
                    showSkeleton={isLoading || isLoadingReportType}
                    readOnlyText={disableStudy()}
                    disabled
                  />
                </Grid>
                <Grid item xs={row}>
                  <FormText
                    name="system"
                    label="System"
                    value={`${values.system ?? ""}`}
                    onChange={handleInputChange}
                    error={errors.system}
                    showSkeleton={isLoading || isLoadingReportType}
                    disabled={disableStudy()}
                    maxLength={50}
                  />
                </Grid>
                <Grid item xs={row}>
                  <FormText
                    name="equipmentLocation"
                    label="Equipment Location"
                    value={`${values.equipmentLocation ?? ""}`}
                    onChange={handleInputChange}
                    error={errors.equipmentLocation}
                    showSkeleton={isLoading || isLoadingReportType}
                    disabled={disableStudy()}
                  />
                </Grid>
                <Grid item xs={row}>
                  <FormText
                    name="areaServed"
                    label="Area Served"
                    value={`${values.areaServed ?? ""}`}
                    onChange={handleInputChange}
                    error={errors.conditionOfTest}
                    showSkeleton={isLoading || isLoadingReportType}
                    disabled={disableStudy()}
                  />
                </Grid>
              </Grid>
              <FooterButtons
                activeStep={activeStep}
                stepsCount={steps.length}
                projectId={values?.projectId as number}
                companyId={values.user?.companyId as number}
                isSaving={isUpdating}
                disabled={
                  isUpdating || isLoading || isSubmitting || disableStudy()
                }
                reportName={title as string}
                saveHandler={saveHandler}
                nextStepHandler={nexStepHandler}
                isComplete={values?.isComplete ?? true}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default PumpStep1;
