import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Grid,
  Card as MuiCard,
  Divider as MuiDivider,
  CardContent,
  Stack,
  Tabs,
  Tab,
} from "@mui/material";
import styled from "@emotion/styled";
import { Helmet } from "react-helmet-async";
import { spacing } from "@mui/system";
import { useAsyncQuery, useAuth, useLog, usePermissions } from "src/hooks";
import companyAssetsService from "src/services/companyAssetsService";
import { IAssets, ITypeAsset } from "src/ts/interfaces/companyAssets";
import { FormButton, FormText } from "src/components/formControls";
import AssetAssignedToYou from "./components/AssetAssignedToYou";
import CompanyAssets from "./components/CompanyAssets";
import RecommendedPopUp from "./components/RecommendedPopUp";
import { useForm } from "src/hooks";
import HeaderPage from "src/components/page/HeaderPage";
import companyAssetsTypeService from "src/services/catalogs/companyAssetTypeService";
import AvailableGrid from "./components/AvailableGrid";
import OutOfServiceGrid from "./components/OutOfServiceGrid";
import { AddOutlined } from "@mui/icons-material";
import { AssetsConstant, ROLES } from "src/constants";
import HistoryTechnicianPopUp from "./components/HistoryTechnicianPopUp";
import { Permission as PermissionTypes } from "src/ts/enums";
const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const initialValues = {
  filter: "",
};

const Assets = () => {
  const params = useParams<{
    companyId: string;
    asset: string;
  }>();
  const companyId = parseInt(
    params?.companyId === undefined ? "0" : params?.companyId
  );
  const { asset } = params;
  const [refreshGrid, setRefreshGrid] = useState(false);
  const [information, setInformation] = useState<ITypeAsset>();
  const [showRecommendedModal, setShowRecommendedModal] = useState(false);
  const [historytechnicanShowPopUp, setHistorytechnicanShowPopUp] =
    useState(false);
  const [value, setValue] = useState(0);
  const { execute, data, isLoading } = useAsyncQuery<IAssets[]>(
    companyAssetsService.getAssets
  );
  const { log } = useLog();
  const { user } = useAuth();
  const navigate = useNavigate();
  const { fullAccess: fullAccessEQIP } = usePermissions(
    PermissionTypes.Equipment
  );
  const { fullAccess: fullAccessVEHI } = usePermissions(
    PermissionTypes.Vehicles
  );

  const permissionRole =
    user?.role === ROLES.ProjectManager ||
    user?.role === ROLES.CompanyOwner ||
    user?.role === ROLES.SuperAdministrator ||
    user?.role === ROLES.Administrator ||
    user?.role === ROLES.Training;

  useEffect(() => {
    const getData = async () => {
      if (permissionRole) {
        const valueGeneralPurchase = value === 0 ? true : false;
        execute(companyId, valueGeneralPurchase, asset);
      } else {
        execute(companyId, true, asset);
      }

      try {
        if (asset !== "VEHI" && asset !== "EQIP") {
          log.error("Incorrect asset");
          return;
        }
        const response = await companyAssetsTypeService.informationAsset(asset);
        setInformation(response.data);
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      }
    };

    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshGrid, asset, value]);

  const { values: filterValues, handleInputChange } = useForm(
    initialValues,
    true,
    []
  );

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <React.Fragment>
      <Helmet title="Company Assets" />

      <HeaderPage
        title={information?.title ?? ""}
        parentText={"Dashboard"}
        parentLink={`/`}
        actionSection={
          <Grid container>
            <Grid item xs={12}>
              <Stack
                direction="row"
                justifyContent="flex-end"
                mb={2}
                spacing={2}
              >
                {permissionRole && eval(`fullAccess${asset}`) && (
                  <FormButton
                    onClick={() => {
                      if (asset === AssetsConstant.EQUIPMENT) {
                        navigate(
                          `/app/companyAssets/Edit/0/${companyId}/EQIP/0`
                        );
                      } else {
                        navigate(
                          `/app/companyAssets/Edit/0/${companyId}/VEHI/0`
                        );
                      }
                    }}
                    text={`Add New ${information?.name}`}
                    color="primary"
                    size="small"
                    startIcon={<AddOutlined />}
                  />
                )}
                <FormButton
                  onClick={() => {
                    setShowRecommendedModal(true);
                  }}
                  text={`Recommended Testing ${information?.name}`}
                  color="primary"
                  size="small"
                  variant="outlined"
                />
                {permissionRole && (
                  <FormButton
                    onClick={() => {
                      setHistorytechnicanShowPopUp(true);
                    }}
                    text={`History by Technician`}
                    color="primary"
                    size="small"
                    variant="outlined"
                  />
                )}
              </Stack>
            </Grid>
          </Grid>
        }
      ></HeaderPage>
      <Grid item></Grid>

      <Divider my={6} />
      <Card>
        <CardContent>
          <Grid container>
            <Grid item xs={12} mb={5}>
              <FormText
                onChange={handleInputChange}
                label="Search"
                name="filter"
                value={filterValues.filter}
              />
            </Grid>
          </Grid>
          {!permissionRole && (
            <>
              <Grid container>
                <Grid item xs={12}>
                  <AssetAssignedToYou
                    data={data}
                    isLoading={isLoading}
                    asset={asset ?? ""}
                    companyId={companyId.toString()}
                    setRefreshGrid={setRefreshGrid}
                    refreshGrid={refreshGrid}
                    filterValues={filterValues.filter}
                    information={information ?? {}}
                    permissionRole={permissionRole}
                  />
                </Grid>
              </Grid>

              <Grid container>
                <Grid item xs={12}></Grid>
              </Grid>

              <Grid container spacing={6}>
                <Grid item xs={12}>
                  <CompanyAssets
                    data={data}
                    isLoading={isLoading}
                    asset={asset ?? ""}
                    companyId={companyId.toString()}
                    setRefreshGrid={setRefreshGrid}
                    refreshGrid={refreshGrid}
                    filterValues={filterValues.filter}
                    information={information ?? {}}
                  />
                </Grid>
              </Grid>
            </>
          )}
          {permissionRole && (
            <>
              <Card mb={6}>
                <CardContent>
                  <Grid
                    container
                    spacing={10}
                    sx={{
                      borderBottom: 1,
                      borderColor: "divider",
                      marginBottom: 5,
                    }}
                  >
                    <Grid item xs={6}>
                      <Tabs
                        value={value}
                        onChange={handleChange}
                        aria-label="basic tabs example"
                      >
                        <Tab label="Active" id="1" />
                        <Tab label="Out of Service" id="2" />
                      </Tabs>
                    </Grid>
                  </Grid>

                  <Grid container spacing={10}>
                    <Grid item xs={12}>
                      {value === 0 && (
                        <AvailableGrid
                          data={data}
                          isLoading={isLoading}
                          asset={asset ?? ""}
                          companyId={companyId.toString()}
                          setRefreshGrid={setRefreshGrid}
                          refreshGrid={refreshGrid}
                          filterValues={filterValues.filter}
                          information={information ?? {}}
                          permissionRole={permissionRole}
                        />
                      )}
                      {value === 1 && (
                        <OutOfServiceGrid
                          data={data}
                          isLoading={isLoading}
                          asset={asset ?? ""}
                          companyId={companyId.toString()}
                          setRefreshGrid={setRefreshGrid}
                          refreshGrid={refreshGrid}
                          filterValues={filterValues.filter}
                          information={information ?? {}}
                          permissionRole={permissionRole}
                        />
                      )}
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </>
          )}
        </CardContent>
      </Card>
      {showRecommendedModal && (
        <RecommendedPopUp
          showRecommendedModal={showRecommendedModal}
          setShowRecommendedModal={setShowRecommendedModal}
          information={information ?? {}}
        />
      )}
      {historytechnicanShowPopUp && (
        <HistoryTechnicianPopUp
          historytechnicanShowPopUp={historytechnicanShowPopUp}
          setHistorytechnicanShowPopUp={setHistorytechnicanShowPopUp}
          assetCode={asset}
          companyId={companyId}
        />
      )}
    </React.Fragment>
  );
};

export default Assets;
