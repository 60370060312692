import axios from "src/utils/axios";
import { ISchools } from "src/ts/interfaces/catalogs/schools";

const getAll = () => {
  return axios.get<ISchools[]>("schools");
};

const add = (school: ISchools) => {
  return axios.post("schools", school);
};

const update = (school: ISchools) => {
  return axios.put(`schools/${school.id}`, school);
};

const remove = (id: Number) => {
  return axios.delete(`schools/${id}`);
};

const schoolsService = { getAll, add, update, remove };
export default schoolsService;
