import { Alert, Snackbar } from "@mui/material";

interface AlertSnackBarProps {
  isOpenSnackBar: boolean;
  setIsOpenSnackBar: (parm1: boolean) => void;
}

export default function AlertSnackBar({
  isOpenSnackBar,
  setIsOpenSnackBar,
}: AlertSnackBarProps) {
  return (
    <>
      <Snackbar
        open={isOpenSnackBar}
        autoHideDuration={6000}
        onClose={() => setIsOpenSnackBar(false)}
      >
        <Alert
          onClose={() => setIsOpenSnackBar(false)}
          severity="success"
          sx={{ width: "100%" }}
        >
          Thank you for your feedback. We work hard to build the best solution
          for your company. The email with your support request has been sent to
          our software engineers and we will response to the email address you
          provided.
        </Alert>
      </Snackbar>
    </>
  );
}
