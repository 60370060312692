import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { Helmet } from "react-helmet-async";
import { Grid, Divider as MuiDivider } from "@mui/material";
import { spacing } from "@mui/system";
import DialogMessagePopup from "src/components/DialogMessagePopup";
import HeaderPage from "src/components/page/HeaderPage";
import { useAppDispatch, useAsyncQuery, useAuth } from "src/hooks";
import { useNavigate } from "react-router-dom";
import { buildingService, projectCurrentService } from "src/services";
import {
  BuildingDTO,
  IBuildingSelected,
  ProjectSelectedDashboardDTO,
} from "src/ts/interfaces";
import getProjectSingleService from "src/services/project/getProjectSingleService";
import { floatingBarActions } from "src/redux/slices/floatingBarActions";
import { COMMON_MENU } from "src/constants";
import DashboarItem from "../CompanyOwner/DashboarItem";
import {
  ApartmentOutlined,
  AppsOutlined,
  AutoGraphOutlined,
  BeenhereOutlined,
  HomeRepairServiceOutlined,
  UpgradeOutlined,
} from "@mui/icons-material";
import DashboarItemCreate from "../Architect/DashboarItemCreate";

const Divider = styled(MuiDivider)(spacing);

const TrainingDashboard = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const {
    execute,
    data: projectCurrent,
    isLoading: isProjectCurrentLoading,
  } = useAsyncQuery<ProjectSelectedDashboardDTO>(
    projectCurrentService.getProjectCurrent
  );

  const {
    execute: getBuilding,
    data: building,
    isLoading: isBuildingLoading,
  } = useAsyncQuery<BuildingDTO>(buildingService.getBuildingByProject);

  const {
    execute: getCountCompleteAuditor,
    data: countCompleteAuditor,
    isLoading: isCountCompleteAuditorLoading,
  } = useAsyncQuery<number>(getProjectSingleService.getCountCompleteAuditor);

  const {
    execute: getCountOutToBid,
    data: countOutToBid,
    isLoading: isCountOutToBidLoading,
  } = useAsyncQuery<number>(getProjectSingleService.getCountOutToBid);

  const {
    execute: executeBuildings,
    data: buildingsData,
    isLoading: isBuildingsLoading,
  } = useAsyncQuery<IBuildingSelected[]>(buildingService.getByCompany);

  useEffect(() => {
    const getData = async () => {
      execute();
      getCountCompleteAuditor();
      getCountOutToBid();
      executeBuildings(user?.companyId);
    };

    const getProjectID = () => {
      dispatch(floatingBarActions.setOptionMenu(COMMON_MENU));
    };
    getProjectID();

    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const getData = async () => {
      getBuilding(projectCurrent?.project?.id);
    };
    if (projectCurrent?.project) getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectCurrent]);

  const isLoading = () => {
    return (
      isProjectCurrentLoading &&
      isBuildingLoading &&
      isCountCompleteAuditorLoading &&
      isCountOutToBidLoading &&
      isBuildingsLoading
    );
  };

  const getBuildingAddress = () => {
    if (building == null) return "";

    let stateName = "";
    if (building?.state == null) {
      stateName = building?.stateName ?? "";
    } else {
      stateName = building?.state?.name ?? "";
    }

    return `${building?.address ?? ""}, ${building?.address2 ?? ""}, ${
      building?.city ?? ""
    }, ${stateName}, ${building?.zip ?? ""}`;
  };

  return (
    <React.Fragment>
      <Helmet title="Analytics Dashboard" />
      <HeaderPage
        title={"Dashboard"}
        parentText={"Dashboard"}
        parentLink={`/`}
      ></HeaderPage>
      <Divider my={6} />
      <Grid container spacing={5}>
        <Grid item xs={6} sm={6} md={6} lg={4}>
          <DashboarItem
            title="Current Project"
            label1={
              projectCurrent?.project?.name ??
              "No current project has been defined."
            }
            label2={
              <AutoGraphOutlined style={{ float: "right", fontSize: 40 }} />
            }
            showSkeleton={isLoading()}
            action={() => {
              if (projectCurrent?.project?.name) {
                navigate(
                  `/app/Project/Edit/${projectCurrent?.project?.id}/${user?.companyId}`
                );
              }
            }}
            hideViewDetails={false}
            textDetails={projectCurrent?.project?.name ? "View details" : ""}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={6} lg={4}>
          <DashboarItem
            title="License Upgrade"
            label1={"HVAC Contractor Subscription"}
            label2={
              <UpgradeOutlined style={{ float: "right", fontSize: 40 }} />
            }
            showSkeleton={isLoading()}
            action={() => {
              navigate(`/app/upgradeSubscription`);
            }}
            hideViewDetails={false}
            textDetails="Upgrade License"
          />
        </Grid>
        <Grid item xs={6} sm={6} md={6} lg={4}>
          <DashboarItem
            title="Field Test Equipment"
            label1={"List & Calibration"}
            label2={
              <HomeRepairServiceOutlined
                style={{ float: "right", fontSize: 40 }}
              />
            }
            showSkeleton={isLoading()}
            action={() => {
              navigate(`/app/companyAssets/${user?.companyId}/EQIP`);
            }}
            hideViewDetails={false}
            textDetails={"View details"}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={6} lg={4}>
          <DashboarItem
            title="Completed Projects"
            chip={
              countCompleteAuditor !== null
                ? countCompleteAuditor.toString()
                : "0"
            }
            chipColor="primary"
            label2={
              <BeenhereOutlined style={{ float: "right", fontSize: 40 }} />
            }
            showSkeleton={isLoading()}
            action={() => {
              navigate(`/app/Working/Complete`);
            }}
            hideViewDetails={false}
            textDetails={"View details"}
            label1={""}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={6} lg={4}>
          <DashboarItem
            title="Out For Bid Projects"
            chip={countOutToBid !== null ? countOutToBid.toString() : "0"}
            chipColor="primary"
            label1={""}
            label2={<AppsOutlined style={{ float: "right", fontSize: 40 }} />}
            showSkeleton={isLoading()}
            action={() => {
              navigate(`/app/projectsOutForBid`);
            }}
            hideViewDetails={false}
            textDetails={"View details"}
          />
        </Grid>

        <Grid item xs={6} sm={6} md={6} lg={4}>
          <DashboarItem
            title="Building Database"
            chip={buildingsData ? buildingsData.length.toString() : "0"}
            chipColor="primary"
            label1={""}
            label2={
              <ApartmentOutlined style={{ float: "right", fontSize: 40 }} />
            }
            showSkeleton={isLoading()}
            action={() => {
              navigate(`/app/Buildings/${user?.companyId}`);
            }}
            hideViewDetails={false}
            textDetails={"View details"}
          />
        </Grid>
      </Grid>
      <Grid container spacing={5} pt={5}>
        <Grid item xs={6} sm={6} md={6} lg={6}>
          <DashboarItemCreate
            title={"PROJECT"}
            label1={"Create new Projects"}
            label2={"Create Project"}
            showSkeleton={false}
            action={() => {
              navigate(`/app/Project/Edit/0/${user?.companyId}`);
            }}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={6} lg={6}>
          <DashboarItemCreate
            title={"BUILDING"}
            label1={"Create new buildings"}
            label2={"Create Building"}
            showSkeleton={false}
            action={() => {
              navigate(`/app/Building/0/${user?.companyId}`);
            }}
          />
        </Grid>
      </Grid>
      <DialogMessagePopup
        title={"Information"}
        text={"Under construction"}
        showPopup={showDeleteModal}
        setShowPopup={setShowDeleteModal}
        onSave={() => setShowDeleteModal(false)}
        isSubmitting={false}
        hideCancel={true}
      />
    </React.Fragment>
  );
};

export default TrainingDashboard;
