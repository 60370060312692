import { Grid, Stack } from "@mui/material";
import { useLog } from "src/hooks";
import { FormCancelButton } from "src/components/formControls";
import Popup from "src/components/Popup";
import { ColumnType } from "src/types/enhancedTable";
import LocalEnhancedTable from "src/components/localTable/LocalTable";
import { IProjectReporDisplayt } from "src/ts/interfaces";
import { GridActionButton } from "src/components/gridControls";

interface ConfirmModalProps {
  isDialogOpen: boolean;
  setIsDialogOpen: (val: boolean) => void;
  studies: IProjectReporDisplayt[] | null;
}

const IncompleteStudies = (props: ConfirmModalProps) => {
  const { isDialogOpen, setIsDialogOpen, studies } = props;
  const { log } = useLog();
  const cancelHandler = () => {
    setIsDialogOpen(false);
  };

  const showUnderConstruction = () => {
    log.message("Under construction");
  };
  const getColumns = (): ColumnType[] => [
    {
      id: "study",
      label: "Study",
      type: "custom",
      sort: false,
      callback: (row: IProjectReporDisplayt) => {
        return (
          <>
            {`${row.system == null ? "(System Null)" : row.system} ${
              row.reportTypeName
            }(${row.studyStatus})`}
          </>
        );
      },
    },
    {
      id: "actions",
      label: "Action",
      type: "custom",
      sort: false,
      callback: (row: IProjectReporDisplayt) => {
        return (
          <div>
            <Stack direction="row">
              <GridActionButton
                type="forward"
                onClick={showUnderConstruction}
                tooltip="Go to Study"
              />
            </Stack>
          </div>
        );
      },
    },
  ];
  const columns = getColumns();

  return (
    <Popup
      openPopup={isDialogOpen}
      setOpenPopup={setIsDialogOpen}
      title={`Incomplete Studies`}
      onClose={cancelHandler}
    >
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <LocalEnhancedTable<IProjectReporDisplayt>
            refreshGrid={false}
            columns={columns}
            data={
              studies?.filter((study) => study.studyStatus !== "Complete") ?? []
            }
          />
        </Grid>
      </Grid>
      <>
        <Stack
          direction="row"
          spacing={2}
          justifyContent="center"
          alignItems="center"
        >
          <FormCancelButton onClick={cancelHandler} isSubmitting={false} />
        </Stack>
      </>
    </Popup>
  );
};

export default IncompleteStudies;
