import { Grid } from "@mui/material";
import {
  FormDatePicker,
  FormNumeric2,
  FormText,
} from "src/components/formControls";
import { Mode } from "src/ts/types";
import useTowerStudy from "../../hooks/useTowerStudy";
import { ISystemOfMeasurement } from "src/ts/interfaces";

interface Props {
  mode: Mode;
  disableStudy: boolean;
  isLoading: boolean;
  systemOfMeasurement: ISystemOfMeasurement;
}

const TowerForm = ({
  mode,
  disableStudy,
  isLoading,
  systemOfMeasurement,
}: Props) => {
  const {
    towerCondenserValues: values,
    handleInputTowerCondenserChange: handleInputChange,
    errorTowerCondenser: errors,
  } = useTowerStudy();
  const row = 12;

  return (
    <Grid container spacing={5}>
      <Grid item xs={row}>
        <FormText
          name="namePlateManufacturer"
          label="Tower Manufacturer"
          value={values.namePlateManufacturer || ""}
          onChange={handleInputChange}
          error={errors.namePlateManufacturer}
          showSkeleton={isLoading}
          mode={mode}
          disabled={disableStudy}
        />
      </Grid>
      <Grid item xs={row}>
        <FormText
          name="namePlateModel"
          label="Model #"
          value={values?.namePlateModel ?? ""}
          onChange={handleInputChange}
          error={errors.namePlateModel}
          showSkeleton={isLoading}
          mode={mode}
          disabled={disableStudy}
        />
      </Grid>
      <Grid item xs={row}>
        <FormText
          name="namePlateSerial"
          label="Serial #"
          value={values.namePlateSerial ?? ""}
          onChange={handleInputChange}
          error={errors.namePlateSerial}
          showSkeleton={isLoading}
          mode={mode}
          disabled={disableStudy}
        />
      </Grid>
      <Grid item xs={row}>
        <FormDatePicker
          name="manufacturedDate"
          label="Date Manufactured"
          value={values.manufacturedDate}
          onChange={handleInputChange}
          error={errors.manufacturedDate}
          mode={mode}
          disable={disableStudy}
          showSkeleton={isLoading}
        />
      </Grid>
      <Grid item xs={row}>
        <FormNumeric2
          name="nameplateTonnage"
          label="Nameplate Tonnage"
          value={values.nameplateTonnage}
          onChange={handleInputChange}
          showSkeleton={isLoading}
          mode={mode}
          disabled={disableStudy}
          decimalScale={2}
          thousandSeparator={true}
          maxValue={1000000000}
          fixedDecimalScale={true}
        />
      </Grid>
      <Grid item xs={row}>
        <FormNumeric2
          name="nameplateCapacityBTUH"
          label={`Nameplate Rated Capacity ${systemOfMeasurement.get("btuh")}`}
          value={values.nameplateCapacityBTUH}
          onChange={handleInputChange}
          showSkeleton={isLoading}
          mode={mode}
          disabled={disableStudy}
          decimalScale={2}
          thousandSeparator={true}
          maxValue={1000000000}
          fixedDecimalScale={true}
        />
      </Grid>
      <Grid item xs={row}>
        <FormNumeric2
          name="nameplateFanCFM"
          label={`Tower Total ${systemOfMeasurement.get("cfm")}`}
          value={values.nameplateFanCFM}
          onChange={handleInputChange}
          showSkeleton={isLoading}
          mode={mode}
          disabled={disableStudy}
          decimalScale={2}
          thousandSeparator={true}
          maxValue={1000000000}
          fixedDecimalScale={true}
        />
      </Grid>
      <Grid item xs={row}>
        <FormNumeric2
          name="designEnteringAirWB"
          label="Design Entering Air WB"
          value={values.designEnteringAirWB}
          onChange={handleInputChange}
          showSkeleton={isLoading}
          mode={mode}
          disabled={disableStudy}
          decimalScale={2}
          thousandSeparator={true}
          maxValue={1000000000}
          fixedDecimalScale={true}
        />
      </Grid>
      <Grid item xs={row}>
        <FormNumeric2
          name="designAmbientAirDB"
          label="Design Ambient Air DB"
          value={values.designAmbientAirDB}
          onChange={handleInputChange}
          showSkeleton={isLoading}
          mode={mode}
          disabled={disableStudy}
          decimalScale={2}
          thousandSeparator={true}
          maxValue={1000000000}
          fixedDecimalScale={true}
        />
      </Grid>
    </Grid>
  );
};

export default TowerForm;
