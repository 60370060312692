import { Alert, Grid, Stack, Typography } from "@mui/material";
import { FormCheckBox, FormNumeric2 } from "src/components/formControls";
import { Mode } from "src/ts/types";
import { ProjectDTO } from "src/ts/interfaces/project/projectDto";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import DialogMessagePopup from "src/components/DialogMessagePopup";
import { IChillerTowerFanMotor } from "src/ts/interfaces/study/tower";
import useChillerStudy from "../hooks/useChillerStudy";

interface IActualAmpsValid {
  line1ActualAmpsValid: boolean;
  line2ActualAmpsValid: boolean;
  line3ActualAmpsValid: boolean;
}

interface Props {
  project: ProjectDTO;
  mode: Mode;
  disableStudy: boolean;
  isLoading: boolean;
}

const round = (num: number) => {
  if (num != null || !isNaN(num)) {
    return parseFloat((Math.round(num * 100) / 100).toFixed(2));
  } else {
    return 0;
  }
};

const FanMotorActualForm = ({
  project,
  mode,
  disableStudy,
  isLoading,
}: Props) => {
  const {
    fanMotorValues: values,
    setFanMotorValues: setValues,
    handleFanMotorInputChange: handleInputChange,
    updateFanMotor,
    showEffPfLimitModal,
    setShowEffPfLimitModal,
    effPfMessage,
  } = useChillerStudy();

  const [actualAmpsValid, setActualAmpsValid] = useState<IActualAmpsValid>({
    line1ActualAmpsValid: true,
    line2ActualAmpsValid: true,
    line3ActualAmpsValid: true,
  });

  const calcPercentageSpeed = () => {
    if (
      values?.vfdOperatingHertz &&
      values.vfdOperatingHertz > 0 &&
      values.vfdOperatingHertz !== 0
    ) {
      const hz = values.vfdOperatingHertz;
      const speed = (hz / (project?.suppliedPowerGridFrequency ?? 0)) * 100;
      const vfdOperatingVelocity = round(speed);
      setValues({ ...values, vfdOperatingVelocity });
    }
  };

  const calcHertz = () => {
    if (
      values?.vfdOperatingVelocity &&
      values.vfdOperatingVelocity > 0 &&
      values.vfdOperatingVelocity !== 0
    ) {
      const speed = values.vfdOperatingVelocity;
      const hz = (speed / 100) * (project?.suppliedPowerGridFrequency ?? 0);
      const vfdOperatingHertz = round(hz);
      setValues({ ...values, vfdOperatingHertz });
    }
  };

  const voltAmpsReferenceClick = (reference: number) => {
    setValues({
      ...values,
      selectedVoltAmpsReference: reference,
      voltageNotListed: false,
    });
  };

  const voltageNotListedClick = (e: any) => {
    setValues({
      ...values,
      selectedVoltAmpsReference: null,
      [e.target.name]: e.target.value,
    });
  };

  const showSelect = values.motorPhase !== "Single phase";
  const showL2 =
    values.motorPhase === "Three phase" ||
    (values.motorPhase === "2 wire single phase" &&
      values.additionalVolAmpValue >= 1);
  const showL3 =
    values.motorPhase === "Three phase" ||
    (values.motorPhase === "2 wire single phase" &&
      values.additionalVolAmpValue === 2);
  const showL4 =
    values.motorPhase === "Three phase" && values.additionalVolAmpValue >= 1;
  const showL5 =
    values.motorPhase === "Three phase" && values.additionalVolAmpValue >= 2;
  const showL6 =
    values.motorPhase === "Three phase" && values.additionalVolAmpValue === 3;

  const voltReadInputChange = (e: any) => {
    setValues({
      ...values,
      voltReadFromVFDScreen: e.target.value,
      voltMeterUsed: !e.target.value,
    });
  };

  const voltMeterInputChange = (e: any) => {
    setValues({
      ...values,
      voltReadFromVFDScreen: !e.target.value,
      voltMeterUsed: e.target.value,
    });
  };

  const vfdOnlyDisplaysSingleVolAmpClick = (e: any, update: boolean) => {
    let newValues = { ...values, [e.target.name]: parseFloat(e.target.value) };

    if (values.vfdOnlyDisplaysSingleVolAmp) {
      newValues.actualVoltsPhase3 = newValues.actualVoltsPhase2 =
        newValues.actualVoltsPhase1;
      newValues.actualAmpsPhase3 = newValues.actualAmpsPhase2 =
        newValues.actualAmpsPhase1;
    }

    setValues(newValues);
    if (update) setTriggerFanMotor(!triggerSaveFanMotor);
  };

  const vfdOnlyDisplaysSingleClick = (e: any) => {
    const vfdOnlyDisplaysSingleVolAmp = e.target.value;

    if (vfdOnlyDisplaysSingleVolAmp) {
      setValues({
        ...values,
        actualVoltsPhase1: values.actualVoltsPhase1,
        actualVoltsPhase2: values.actualVoltsPhase1,
        actualVoltsPhase3: values.actualVoltsPhase1,
        actualAmpsPhase1: values.actualAmpsPhase1,
        actualAmpsPhase2: values.actualAmpsPhase1,
        actualAmpsPhase3: values.actualAmpsPhase1,
        vfdOnlyDisplaysSingleVolAmp: vfdOnlyDisplaysSingleVolAmp,
      });
    } else {
      handleInputChange(e);
    }
  };

  /* Allow to run  updateFanMotor no in the first render*/
  const firstUpdate = useRef(true);
  useLayoutEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
  });

  const [triggerSaveFanMotor, setTriggerFanMotor] = useState(false);
  useEffect(() => {
    if (firstUpdate.current === false && values?.id > 0) {
      updateFanMotor(values);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerSaveFanMotor]);
  /*********************************************************/

  const [showNoVoltsAmps, setShowNoVoltsAmps] = useState(false);
  const voltsAmpsInputDataValidation = (e: any) => {
    if (
      (values.selectedVoltAmpsReference == null ||
        values.selectedVoltAmpsReference === 0) &&
      values.voltageNotListed === false
    ) {
      //User has not selected a Volts/Amps set reference or Voltage Not Listed checkbox and tries to enter any actual value
      //setResetVoltsAmps(!resetVoltAmps);
      setShowNoVoltsAmps(true);
      const newFanMotor: IChillerTowerFanMotor = {
        ...values,
        actualVoltsPhase1: null,
        actualVoltsPhase2: null,
        actualVoltsPhase3: null,
        actualAmpsPhase1: null,
        actualAmpsPhase2: null,
        actualAmpsPhase3: null,
      };
      setValues(newFanMotor);
    } else if (
      values?.selectedVoltAmpsReference &&
      values.selectedVoltAmpsReference > 0
    ) {
      const newFanMotor: IChillerTowerFanMotor = {
        ...values,
        [e.target.name]: parseFloat(e.target.value),
      };
      setValues(newFanMotor);
      validateActualAmps(newFanMotor);
    }
  };

  const validateActualAmps = (fanMotor: IChillerTowerFanMotor) => {
    // User has selected a Volts/Amps set reference and is entering data in actual values,
    // so we have to know if the actual amps are valid per the reference value

    const actualAmpsPhase1 = fanMotor?.actualAmpsPhase1 ?? 0;
    const actualAmpsPhase2 = fanMotor?.actualAmpsPhase2 ?? 0;
    const actualAmpsPhase3 = fanMotor?.actualAmpsPhase3 ?? 0;

    let line1ActualAmpsValid = false;
    let line2ActualAmpsValid = false;
    let line3ActualAmpsValid = false;

    switch (values.selectedVoltAmpsReference) {
      case 1:
        //Comparing to the first reference set
        line1ActualAmpsValid =
          actualAmpsPhase1 <= (fanMotor?.nameplateAmpsPhase1 ?? 0);
        line2ActualAmpsValid =
          actualAmpsPhase2 <= (fanMotor?.nameplateAmpsPhase1 ?? 0);
        line3ActualAmpsValid =
          actualAmpsPhase3 <= (fanMotor?.nameplateAmpsPhase1 ?? 0);
        break;
      case 2:
        //Comparing to the first reference set
        line1ActualAmpsValid =
          actualAmpsPhase1 <= (fanMotor?.nameplateAmpsPhase2 ?? 0) &&
          (fanMotor?.nameplateAmpsPhase2 ?? 0) > 0;
        line2ActualAmpsValid =
          actualAmpsPhase2 <= (fanMotor?.nameplateAmpsPhase2 ?? 0) &&
          (values.nameplateAmpsPhase2 ?? 0) > 0;
        line3ActualAmpsValid =
          actualAmpsPhase3 <= (fanMotor?.nameplateAmpsPhase2 ?? 0) &&
          (fanMotor?.nameplateAmpsPhase2 ?? 0) > 0;
        break;
      case 3:
        //Comparing to the first reference set
        line1ActualAmpsValid =
          actualAmpsPhase1 <= (fanMotor?.nameplateAmpsPhase3 ?? 0) &&
          (fanMotor?.nameplateAmpsPhase3 ?? 0) > 0;
        line2ActualAmpsValid =
          actualAmpsPhase2 <= (values.nameplateAmpsPhase3 ?? 0) &&
          (fanMotor?.nameplateAmpsPhase3 ?? 0) > 0;
        line3ActualAmpsValid =
          actualAmpsPhase3 <= (fanMotor?.nameplateAmpsPhase3 ?? 0) &&
          (fanMotor?.nameplateAmpsPhase3 ?? 0) > 0;
        break;
      case 4:
        //Comparing to the first reference set
        line1ActualAmpsValid =
          actualAmpsPhase1 <= (fanMotor?.nameplateAmpsPhase4 ?? 0) &&
          (fanMotor?.nameplateAmpsPhase4 ?? 0) > 0;
        line2ActualAmpsValid =
          actualAmpsPhase2 <= (fanMotor?.nameplateAmpsPhase4 ?? 0) &&
          (fanMotor?.nameplateAmpsPhase4 ?? 0) > 0;
        line3ActualAmpsValid =
          actualAmpsPhase3 <= (fanMotor?.nameplateAmpsPhase4 ?? 0) &&
          (fanMotor?.nameplateAmpsPhase4 ?? 0) > 0;
        break;
      case 5:
        //Comparing to the first reference set
        line1ActualAmpsValid =
          actualAmpsPhase1 <= (fanMotor?.nameplateAmpsPhase5 ?? 0) &&
          (fanMotor?.nameplateAmpsPhase5 ?? 0) > 0;
        line2ActualAmpsValid =
          actualAmpsPhase2 <= (fanMotor?.nameplateAmpsPhase5 ?? 0) &&
          (fanMotor?.nameplateAmpsPhase5 ?? 0) > 0;
        line3ActualAmpsValid =
          actualAmpsPhase3 <= (fanMotor?.nameplateAmpsPhase5 ?? 0) &&
          (fanMotor?.nameplateAmpsPhase5 ?? 0) > 0;
        break;
      case 6:
        //Comparing to the first reference set
        line1ActualAmpsValid =
          actualAmpsPhase1 <= (fanMotor?.nameplateAmpsPhase6 ?? 0) &&
          (fanMotor?.nameplateAmpsPhase6 ?? 0) > 0;
        line2ActualAmpsValid =
          actualAmpsPhase2 <= (fanMotor?.nameplateAmpsPhase6 ?? 0) &&
          (fanMotor?.nameplateAmpsPhase6 ?? 0) > 0;
        line3ActualAmpsValid =
          actualAmpsPhase3 <= (fanMotor?.nameplateAmpsPhase6 ?? 0) &&
          (fanMotor?.nameplateAmpsPhase6 ?? 0) > 0;
        break;
    }

    setActualAmpsValid({
      line1ActualAmpsValid,
      line2ActualAmpsValid,
      line3ActualAmpsValid,
    });
  };

  return (
    <Grid container spacing={5}>
      <Grid item xs={4}>
        <FormNumeric2
          name="fanMotorNumber"
          label="Fan Motor Number"
          value={values.fanMotorNumber}
          onChange={handleInputChange}
          showSkeleton={isLoading}
          mode={mode}
          disabled={disableStudy}
          thousandSeparator={true}
          maxValue={1000000000}
        />
      </Grid>
      {values.systemMotorOperatesFromAVfDPwmDevice && (
        <>
          <Grid item xs={4}>
            <FormNumeric2
              name="vfdOperatingHertz"
              label="VFD Operating Hertz"
              value={values.vfdOperatingHertz}
              onChange={handleInputChange}
              onBlur={calcPercentageSpeed}
              showSkeleton={isLoading}
              mode={mode}
              disabled={disableStudy}
              decimalScale={2}
              thousandSeparator={true}
              maxValue={100000000}
              fixedDecimalScale={true}
            />
          </Grid>
          <Grid item xs={4}>
            <FormNumeric2
              name="vfdOperatingVelocity"
              label="VFD Operating % Speed"
              value={values.vfdOperatingVelocity}
              onChange={handleInputChange}
              onBlur={calcHertz}
              showSkeleton={isLoading}
              mode={mode}
              disabled={disableStudy}
              decimalScale={2}
              thousandSeparator={true}
              maxValue={100000000}
              fixedDecimalScale={true}
            />
          </Grid>
          <Grid item xs={12}>
            <Alert severity="info">
              If VFD has a display screen - Collect Operating Voltage and
              Current/Amperage directly from this display if available.
              <br />
              If the screen only displays a single averaged Voltage and
              Current/Amperage, enter its value in each data input box.
              <br />
              If using a standard Volt/Amp meter not rated to read VFD or PWM
              voltage output, the meter won't work when recording VFD / PWM
              "Output Voltage". This meter will work when collecting
              Amperage(s). Collecting actual VFD /PWM "Output Voltage" can be
              done with the meters designed and available to do this task.
            </Alert>
          </Grid>
        </>
      )}
      <Grid item xs={12}>
        <Grid container spacing={5}>
          <Grid item xs={3}>
            <Stack direction="row" justifyContent="flex-end">
              <Typography align="right" pt={2}>
                Aegis Bearing or Cool Blue Ring(s) installed
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={1}>
            <FormCheckBox
              name="aegisRing"
              value={values.aegisRing}
              onChange={handleInputChange}
              mode={mode}
              disabled={disableStudy}
              showSkeleton={isLoading}
              label={undefined}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        {showSelect && (
          <Grid container spacing={5}>
            <Grid item xs={3}>
              <Stack direction="row" justifyContent="flex-end">
                <Typography align="right" pt={2}>
                  Select Voltage that best represents Actual Value
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={1}>
              <FormCheckBox
                name="reference1"
                value={values.selectedVoltAmpsReference === 1}
                onChange={() => voltAmpsReferenceClick(1)}
                label={undefined}
                mode={mode}
                disabled={disableStudy}
                showSkeleton={isLoading}
              />
            </Grid>
            {showL2 && (
              <Grid item xs={1}>
                <FormCheckBox
                  name="reference2"
                  value={values.selectedVoltAmpsReference === 2}
                  onChange={() => voltAmpsReferenceClick(2)}
                  label={undefined}
                  mode={mode}
                  disabled={disableStudy}
                  showSkeleton={isLoading}
                />
              </Grid>
            )}
            {showL3 && (
              <Grid item xs={1}>
                <FormCheckBox
                  name="reference3"
                  value={values.selectedVoltAmpsReference === 3}
                  onChange={() => voltAmpsReferenceClick(3)}
                  label={undefined}
                  mode={mode}
                  disabled={disableStudy}
                  showSkeleton={isLoading}
                />
              </Grid>
            )}
            {showL4 && (
              <Grid item xs={1}>
                <FormCheckBox
                  name="reference4"
                  value={values.selectedVoltAmpsReference === 4}
                  onChange={() => voltAmpsReferenceClick(4)}
                  label={undefined}
                  mode={mode}
                  disabled={disableStudy}
                  showSkeleton={isLoading}
                />
              </Grid>
            )}
            {showL5 && (
              <Grid item xs={1}>
                <FormCheckBox
                  name="reference5"
                  value={values.selectedVoltAmpsReference === 5}
                  onChange={() => voltAmpsReferenceClick(5)}
                  label={undefined}
                  mode={mode}
                  disabled={disableStudy}
                  showSkeleton={isLoading}
                />
              </Grid>
            )}
            {showL6 && (
              <Grid item xs={1}>
                <FormCheckBox
                  name="reference6"
                  value={values.selectedVoltAmpsReference === 6}
                  onChange={() => voltAmpsReferenceClick(6)}
                  label={undefined}
                  mode={mode}
                  disabled={disableStudy}
                  showSkeleton={isLoading}
                />
              </Grid>
            )}

            <Grid item xs={2}>
              <FormCheckBox
                name="voltageNotListed"
                value={values.voltageNotListed}
                onChange={(e) => voltageNotListedClick(e)}
                label="Voltage Not Listed"
                mode={mode}
                disabled={disableStudy}
                showSkeleton={isLoading}
              />
            </Grid>
          </Grid>
        )}
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={5}>
          <Grid item xs={3}>
            <Stack direction="row" justifyContent="flex-end">
              Nameplate Volts
            </Stack>
          </Grid>
          <Grid item xs={1}>
            {values.nameplateVoltsPhase1}V
          </Grid>
          {showL2 && (
            <Grid item xs={1}>
              {values.nameplateVoltsPhase2}V
            </Grid>
          )}
          {showL3 && (
            <Grid item xs={1}>
              {values.nameplateVoltsPhase3}V
            </Grid>
          )}
          {showL4 && (
            <Grid item xs={1}>
              {values.nameplateVoltsPhase4}V
            </Grid>
          )}
          {showL5 && (
            <Grid item xs={1}>
              {values.nameplateVoltsPhase5}V
            </Grid>
          )}
          {showL6 && (
            <Grid item xs={1}>
              {values.nameplateVoltsPhase6}V
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={5}>
          <Grid item xs={3}>
            <Stack direction="row" justifyContent="flex-end">
              Nameplate Amps
            </Stack>
          </Grid>
          <Grid item xs={1}>
            {values.nameplateAmpsPhase1}A
          </Grid>
          {showL2 && (
            <Grid item xs={1}>
              {values.nameplateAmpsPhase2}A
            </Grid>
          )}
          {showL3 && (
            <Grid item xs={1}>
              {values.nameplateAmpsPhase3}A
            </Grid>
          )}
          {showL4 && (
            <Grid item xs={1}>
              {values.nameplateAmpsPhase4}A
            </Grid>
          )}
          {showL5 && (
            <Grid item xs={1}>
              {values.nameplateAmpsPhase5}A
            </Grid>
          )}
          {showL6 && (
            <Grid item xs={1}>
              {values.nameplateAmpsPhase6}A
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Alert severity="error">
          Warning
          <p>
            1. Single Phase and 3 Phase Voltages are recorded from Phase to
            Phase, not Phase to Ground.
          </p>
          {values.systemMotorOperatesFromAVfDPwmDevice && (
            <p>
              2. Reading Actual Volts requires a handheld voltmeter rated for
              VFDs. A typical RMS Voltmeter will not provide accurate readings.
            </p>
          )}
          {values.systemMotorOperatesFromAVfDPwmDevice && (
            <p>
              3. Actual Voltage and Amperage associated with the VFD must be
              recorded on the leaving side of the VFD to the Motor.
            </p>
          )}
        </Alert>
      </Grid>
      {values.systemMotorOperatesFromAVfDPwmDevice && (
        <>
          <Grid item xs={6}>
            <Grid container spacing={5}>
              <Grid item xs={6}>
                <Stack direction="row" justifyContent="flex-end">
                  <Typography align="right" pt={2}>
                    Volts & Amperages read from VFD Screen
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={1}>
                <FormCheckBox
                  name="voltReadFromVFDScreen"
                  value={values.voltReadFromVFDScreen}
                  onChange={voltReadInputChange}
                  label={undefined}
                  showSkeleton={isLoading}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Grid container spacing={5}>
              <Grid item xs={6}>
                <Stack direction="row" justifyContent="flex-end">
                  <Typography align="right" pt={2}>
                    Volts & Amperages read with a handheld voltmeter
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={1}>
                <FormCheckBox
                  name="voltMeterUsed"
                  value={values.voltMeterUsed}
                  onChange={voltMeterInputChange}
                  label={undefined}
                  showSkeleton={isLoading}
                />
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
      {values.voltMeterUsed && (
        <Grid item xs={12}>
          <Alert severity="info">
            Declaration
            <p>I hereby state that I'm using a voltmeter rated for VFD.</p>
          </Alert>
        </Grid>
      )}
      {values.voltReadFromVFDScreen &&
        values.systemMotorOperatesFromAVfDPwmDevice && (
          <Grid item xs={12}>
            <Grid container spacing={5}>
              <Grid item xs={3}>
                <Stack direction="row" justifyContent="flex-end">
                  <Typography align="right" pt={2}>
                    VFD/PWM Only displays 1 Voltage and 1 Amperage
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={1}>
                <FormCheckBox
                  name="vfdOnlyDisplaysSingleVolAmp"
                  value={values.vfdOnlyDisplaysSingleVolAmp}
                  onChange={vfdOnlyDisplaysSingleClick}
                  label={undefined}
                  showSkeleton={isLoading}
                />
              </Grid>
            </Grid>
          </Grid>
        )}
      <Grid item xs={12}>
        <Grid container spacing={5}>
          <Grid item xs={3}></Grid>
          <Grid item xs={1}>
            L1 -L2
          </Grid>
          <Grid item xs={1}>
            L1 -L3
          </Grid>
          {values.motorPhase === "Three phase" && (
            <Grid item xs={1}>
              L2 -L3
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={5}>
          <Grid item xs={3}>
            <Stack direction="row" justifyContent="flex-end">
              Fan Motor Actual Volts
            </Stack>
          </Grid>
          <Grid item xs={1}>
            <FormNumeric2
              name="actualVoltsPhase1"
              label=""
              value={values.actualVoltsPhase1 ?? ""}
              onChange={voltsAmpsInputDataValidation}
              onBlur={(e) => vfdOnlyDisplaysSingleVolAmpClick(e, true)}
              showSkeleton={isLoading}
              mode={mode}
              disabled={disableStudy}
              decimalScale={2}
              thousandSeparator={true}
              maxValue={100000000}
              fixedDecimalScale={true}
              placeholder="0.00"
            />
          </Grid>
          {values.motorPhase === "Three phase" && (
            <Grid item xs={1}>
              <FormNumeric2
                name="actualVoltsPhase2"
                label=""
                value={values.actualVoltsPhase2 ?? ""}
                onChange={voltsAmpsInputDataValidation}
                showSkeleton={isLoading}
                mode={mode}
                disabled={values.vfdOnlyDisplaysSingleVolAmp || disableStudy}
                decimalScale={2}
                thousandSeparator={true}
                maxValue={100000000}
                fixedDecimalScale={true}
                placeholder="0.00"
              />
            </Grid>
          )}
          {values.motorPhase === "Three phase" && (
            <Grid item xs={1}>
              <FormNumeric2
                name="actualVoltsPhase3"
                label=""
                value={values.actualVoltsPhase3 ?? ""}
                onChange={voltsAmpsInputDataValidation}
                showSkeleton={isLoading}
                mode={mode}
                disabled={values.vfdOnlyDisplaysSingleVolAmp || disableStudy}
                decimalScale={2}
                thousandSeparator={true}
                maxValue={100000000}
                fixedDecimalScale={true}
                placeholder="0.00"
              />
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={5}>
          <Grid item xs={3}>
            <Stack direction="row" justifyContent="flex-end">
              Fan Motor Actual Amps
            </Stack>
          </Grid>
          {(values.motorPhase === "Three phase" ||
            values.motorPhase === "2 wire single phase") && (
            <Grid item xs={1}>
              <FormNumeric2
                name="actualAmpsPhase1"
                label=""
                value={values.actualAmpsPhase1 ?? ""}
                onChange={voltsAmpsInputDataValidation}
                onBlur={(e) => vfdOnlyDisplaysSingleVolAmpClick(e, true)}
                showSkeleton={isLoading}
                mode={mode}
                disabled={disableStudy}
                decimalScale={2}
                thousandSeparator={true}
                maxValue={100000000}
                fixedDecimalScale={true}
                placeholder="0.00"
              />
            </Grid>
          )}
          {(values.motorPhase === "Three phase" ||
            values.motorPhase === "2 wire single phase") && (
            <Grid item xs={1}>
              <FormNumeric2
                name="actualAmpsPhase2"
                label=""
                value={values.actualAmpsPhase2 ?? ""}
                onChange={voltsAmpsInputDataValidation}
                showSkeleton={isLoading}
                mode={mode}
                disabled={values.vfdOnlyDisplaysSingleVolAmp || disableStudy}
                decimalScale={2}
                thousandSeparator={true}
                maxValue={100000000}
                fixedDecimalScale={true}
                placeholder="0.00"
              />
            </Grid>
          )}
          {values.motorPhase === "Three phase" && (
            <Grid item xs={1}>
              <FormNumeric2
                name="actualAmpsPhase3"
                label=""
                value={values.actualAmpsPhase3 ?? ""}
                onChange={voltsAmpsInputDataValidation}
                showSkeleton={isLoading}
                mode={mode}
                disabled={values.vfdOnlyDisplaysSingleVolAmp || disableStudy}
                decimalScale={2}
                thousandSeparator={true}
                maxValue={100000000}
                fixedDecimalScale={true}
                placeholder="0.00"
              />
            </Grid>
          )}
        </Grid>
        <Grid item xs={12} pt={5}>
          {!actualAmpsValid.line1ActualAmpsValid &&
            actualAmpsValid.line2ActualAmpsValid &&
            actualAmpsValid.line3ActualAmpsValid && (
              <Alert severity="error">
                WARNING: Actual Amps exceed referenced nameplate Amp rating in
                line 1
              </Alert>
            )}
          {actualAmpsValid.line1ActualAmpsValid &&
            !actualAmpsValid.line2ActualAmpsValid &&
            actualAmpsValid.line3ActualAmpsValid && (
              <Alert severity="error">
                WARNING: Actual Amps exceed referenced nameplate Amp rating in
                line 2
              </Alert>
            )}
          {actualAmpsValid.line1ActualAmpsValid &&
            actualAmpsValid.line2ActualAmpsValid &&
            !actualAmpsValid.line3ActualAmpsValid && (
              <Alert severity="error">
                WARNING: Actual Amps exceed referenced nameplate Amp rating in
                line 3
              </Alert>
            )}
          {!actualAmpsValid.line1ActualAmpsValid &&
            !actualAmpsValid.line2ActualAmpsValid &&
            actualAmpsValid.line3ActualAmpsValid && (
              <Alert severity="error">
                WARNING: Actual Amps exceed referenced nameplate Amp rating in
                lines 1 & 2
              </Alert>
            )}
          {!actualAmpsValid.line1ActualAmpsValid &&
            actualAmpsValid.line2ActualAmpsValid &&
            !actualAmpsValid.line3ActualAmpsValid && (
              <Alert severity="error">
                WARNING: Actual Amps exceed referenced nameplate Amp rating in
                lines 1 & 3
              </Alert>
            )}
          {actualAmpsValid.line1ActualAmpsValid &&
            !actualAmpsValid.line2ActualAmpsValid &&
            !actualAmpsValid.line3ActualAmpsValid && (
              <Alert severity="error">
                WARNING: Actual Amps exceed referenced nameplate Amp rating in
                lines 2 & 3
              </Alert>
            )}
          {!actualAmpsValid.line1ActualAmpsValid &&
            !actualAmpsValid.line2ActualAmpsValid &&
            !actualAmpsValid.line3ActualAmpsValid && (
              <Alert severity="error">
                WARNING: Actual Amps exceed referenced nameplate Amp rating in
                lines 1, 2 & 3
              </Alert>
            )}
        </Grid>
      </Grid>
      <DialogMessagePopup
        title={"Limit reached."}
        text={effPfMessage}
        showPopup={showEffPfLimitModal}
        setShowPopup={setShowEffPfLimitModal}
        onSave={() => {
          setShowEffPfLimitModal(false);
        }}
        isSubmitting={false}
        hideCancel={true}
      />
      <DialogMessagePopup
        title={"No Volts/Amps reference set selected."}
        text="You must select a Voltage that best represents Actual Voltage being read."
        showPopup={showNoVoltsAmps}
        setShowPopup={setShowNoVoltsAmps}
        onSave={() => {
          setShowNoVoltsAmps(false);
        }}
        isSubmitting={false}
        hideCancel={true}
      />
    </Grid>
  );
};

export default FanMotorActualForm;
