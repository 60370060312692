import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import {
  Grid,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
  Typography,
  Card as MuiCard,
  CardContent,
  Stack,
  useTheme,
} from "@mui/material";
import { spacing } from "@mui/system";
import { useAuth, useLog } from "src/hooks";
import { useEffect, useState } from "react";
import { ISystemCostsCatalog } from "src/ts/interfaces";
import systemCostCatalogService from "src/services/systemCostCatalogService";
import { currencyformatter } from "src/utils/format";
import { FormButton } from "src/components/formControls";
import UpgradeAccountModal from "src/components/auth/components/UpgradeAccountModal";

const Card = styled(MuiCard)(spacing);
const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const UpgradeSubscription = () => {
  const { log } = useLog();
  const { user } = useAuth();
  const theme = useTheme();
  const labelColor = theme.palette.primary.main;
  const Spacer = styled.div(spacing);
  const [costs, setCosts] = useState<ISystemCostsCatalog[]>([]);
  const [upgradeModal, setUpgradeModal] = useState(false);

  useEffect(() => {
    const getInformation = async () => {
      try {
        const response = await systemCostCatalogService.GetCostToUpgrade();

        setCosts(response.data);
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      } finally {
      }
    };

    getInformation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Helmet title="Upgrade Subscription" />
      <Grid container spacing={10}>
        <Grid item xs={6}>
          <Typography variant="h3" gutterBottom display="inline">
            Upgrade Subscription
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} to="/">
              Dashboard
            </Link>
          </Breadcrumbs>
        </Grid>
        <Grid item xs={6}>
          <Stack direction="row" justifyContent="end"></Stack>
        </Grid>
      </Grid>

      <Divider my={6} />
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={3} lg={3}></Grid>
        <Grid item xs={12} sm={12} md={3} lg={5}>
          <Card>
            <CardContent style={{ textAlign: "center" }}>
              <Typography
                variant="h5"
                sx={{
                  color: labelColor,
                }}
              >
                HVAC CONTRACTORS
              </Typography>

              <Divider my={3} />

              <Typography>
                One Time Only <br />
                Fee/Subscription
              </Typography>

              <Spacer mb={4}></Spacer>

              <Typography variant="h2">
                {currencyformatter.format(costs[5]?.pricing)}
              </Typography>

              <Spacer mb={4}></Spacer>

              <FormButton
                text={"Upgrade Now"}
                size="medium"
                onClick={() => {
                  setUpgradeModal(true);
                }}
                disabled={false}
                color={"primary"}
                isSubmitting={false}
                variant="outlined"
              />

              <Divider my={3} />

              <Typography sx={{ fontWeight: "bold" }}>
                OPTIONS WITHOUT COST:
              </Typography>

              <Spacer mb={2}></Spacer>

              <Grid container>
                <Grid item xs={12}>
                  <Typography sx={{ textAlign: "center" }}>
                    Dashboard
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Typography sx={{ textAlign: "center" }}>
                    Company Data / Profile / User preferences
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Typography sx={{ textAlign: "center" }}>
                    Satellite company(ies) administration
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Typography sx={{ textAlign: "center" }}>
                    Company users administration
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Typography sx={{ textAlign: "center" }}>
                    Technicians administration
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Typography sx={{ textAlign: "center" }}>
                    Buildings
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Typography sx={{ textAlign: "center" }}>
                    Regular Projects
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Typography sx={{ textAlign: "center" }}>
                    Test Equipment
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Typography sx={{ textAlign: "center" }}>
                    History (Auditors/Technicians, Projects, Studies, Users
                    access)
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Typography sx={{ textAlign: "center" }}>
                    Wallet (credit cards) administration and wallet history
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Typography sx={{ textAlign: "center" }}>Cart</Typography>
                </Grid>

                <Grid item xs={12}>
                  <Typography sx={{ textAlign: "center" }}>
                    Storage administration
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Typography sx={{ textAlign: "center" }}>
                    Team members
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Typography sx={{ textAlign: "center" }}>
                    PDF Downloads
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Typography sx={{ textAlign: "center" }}>Glossary</Typography>
                </Grid>

                <Grid item xs={12}>
                  <Typography sx={{ textAlign: "center" }}>
                    Support Tickets
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Typography sx={{ textAlign: "center" }}>
                    Suggestions Bank
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Typography sx={{ textAlign: "center" }}>
                    Daily Log, Time Card, Project Estimated Time
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Typography sx={{ textAlign: "center" }}>
                    Change order
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Typography sx={{ textAlign: "center" }}>
                    Memorandum of understanding
                  </Typography>
                </Grid>
              </Grid>

              <Divider my={3} />

              <FormButton
                text={"Upgrade Now"}
                size="medium"
                onClick={() => {
                  setUpgradeModal(true);
                }}
                disabled={false}
                color={"primary"}
                isSubmitting={false}
              />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={12} md={3} lg={3}></Grid>
      </Grid>

      <UpgradeAccountModal
        showPopup={upgradeModal}
        setShowPopup={setUpgradeModal}
        email={user?.userName}
      ></UpgradeAccountModal>
    </>
  );
};

export default UpgradeSubscription;
