import { ICatalogType, IKeyValue } from "../ts/interfaces";
import { ICountry } from "../ts/interfaces/country";
import axios from "../utils/axios";

const getCompanyTypeKeyValues = () => {
  return new Promise<
    IKeyValue<number | null | undefined, string | undefined>[]
  >((resolve, reject) => {
    axios
      .get<Array<ICountry>>("/CompanyType")
      .then((response) => {
        const keyValuePair: IKeyValue<
          number | null | undefined,
          string | undefined
        >[] = response.data.map((country) => {
          return {
            key: country.id,
            value: country.name,
          };
        });

        resolve(keyValuePair);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const getKeyValues = (catalogType: string) => {
  return new Promise<
    IKeyValue<number | null | undefined, string | undefined>[]
  >((resolve, reject) => {
    axios
      .get("/" + catalogType)
      .then((response) => {
        const keyValuePair: IKeyValue<
          number | null | undefined,
          string | undefined
        >[] = response.data.map((x: any) => {
          return {
            key: x.id,
            value: x.name,
          };
        });

        resolve(keyValuePair);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const getAll = (catalog: string) => {
  return axios.get<Array<ICatalogType>>(`/${catalog}`);
};

const getAllDropdown = async (catalog: string) => {
  var response = await axios.get<Array<ICatalogType>>(`/${catalog}`);
  const keyValuePair: IKeyValue<number, string>[] = response.data.map(
    (item) => {
      return {
        key: item.id!,
        value: item.name,
      };
    }
  );
  return keyValuePair;
};

const getById = (catalog: string, id: number) => {
  return axios.get<ICatalogType>(`/${catalog}/${id}`);
};

const deleteById = (catalog: string, id: number) => {
  return axios.delete(`/${catalog}/${id}`);
};

const update = (catalog: string, id: number, data: ICatalogType) => {
  return axios.put(`/${catalog}/${id}`, data);
};

const add = (catalog: string, data: ICatalogType) => {
  return axios.post(`/${catalog}`, data);
};

const CatalogService = {
  getCompanyTypeKeyValues,
  getAll,
  getAllDropdown,
  getById,
  update,
  add,
  deleteById,
  getKeyValues,
};

export default CatalogService;
