import { OutletReportProvider } from "../contexts/OutletReportContext";
import OutletStep3 from "./OutletStep3";

const index = () => {
  return (
    <OutletReportProvider>
      <OutletStep3 />
    </OutletReportProvider>
  );
};

export default index;
