import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { Grid, Typography, Drawer, Box, Fab as MuiFab } from "@mui/material";

import {
  AttachMoneyOutlinedIcon,
  CompareArrowsOutlinedIcon,
  CalculateOutlinedIcon,
  SettingsOutlinedIcon,
  AutoAwesomeMotionOutlinedIcon,
  BuildOutlinedIcon,
  ChatBubbleOutlineOutlinedIcon,
  DescriptionOutlinedIcon,
  RoomOutlinedIcon,
  LibraryAddOutlinedIcon,
  SettingsCellOutlinedIcon,
  InsertDriveFileOutlinedIcon,
} from "../icons";
import CommentsModal from "src/components/floatingModalBar/CommentsModal";
import ChangeWorkOrdersPopUp from "src/components/floatingModalBar/ChangeWorkOrderPopUp";
import MemorandumPopUp from "src/components/floatingModalBar/MemorandumPopUp";
import { COMMENTSTYPE, projectStatus } from "src/constants";
import { useAppSelector, useAsyncQuery, useAuth } from "src/hooks";
import DailyLogPopUp from "src/pages/project/Components/DailyLogPopUp";
import CalculatorPopUp from "src/pages/project/Components/CalculatorPopUp";
import ConversionsPopUp from "src/pages/project/Components/ConversionsPopUp";
import TestingUnitCostModal from "../modals/TestingUnitCostModal";
import GPSAppPopUp from "../floatingModalBar/GPSAppPopUp";
import PunchListPopUp from "../floatingModalBar/PunchListPopUp";
import { ToolTip } from "../others/ToolTip";
import studiesHistoryService from "src/services/study/studiesHistoryService";
import { useLocation } from "react-router-dom";
import getProjectSingleService from "src/services/getProjectSingleService";
import { ProjectDTO } from "src/ts/interfaces/project/projectDto";

type OptionButtonType = {
  active?: boolean;
};

const Fab = styled(MuiFab)`
  position: fixed;
  right: ${(props) => props.theme.spacing(2)};
  bottom: ${(props) => props.theme.spacing(38)};
  z-index: 1;
`;
const Wrapper = styled.div`
  width: 258px;
  overflow-x: hidden;
`;

type Anchor = "right";

const OptionButton = styled.div<OptionButtonType>`
  cursor: pointer;
  background: ${(props) => props.theme.palette.background.paper};
  height: 80px;
  border-radius: 0.3rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.825rem;
  position: relative;
  border: 1px solid
    ${(props) =>
      !props.active
        ? props.theme.palette.action.selected
        : props.theme.palette.action.active};
`;
const TitleButton = styled(Typography)`
  text-align: center;
`;

const FloatingBar = () => {
  const [state, setState] = React.useState({
    right: false,
  });

  const { user } = useAuth();

  const projectIdValueRedux = useAppSelector(
    (state) => state.floatingBar.projectId
  );

  const report = useAppSelector((state) => state.floatingBar.report);
  const location = useLocation();
  const [typeComment, setTypeComment] = useState(0);
  const [showComments, setShowComments] = useState(false);
  const [showChangeOrdersPopUp, setShowChangeOrdersPopUp] = useState(false);
  const [showMemorandumPopUp, setShowMemorandumPopUp] = useState(false);
  const [showDailyLogPopUp, setShowDailyLogPopUp] = useState(false);
  const [showCalculatorPopUp, setShowCalculatorPopUp] = useState(false);
  const [showConversionsPopUp, setShowConversionsPopUp] = useState(false);
  const [testingUnitCostModalShow, setTestingUnitCostModalShow] =
    useState(false);
  const [showGPSAppPopUp, setShowGPSAppPopUp] = useState(false);
  const [showPunchList, setShowPunchList] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const arrayMenu = useAppSelector((state) => state.floatingBar.options);

  const options = [
    {
      id: 1,
      icon: <RoomOutlinedIcon fontSize="large" />,
      name: "Daily Log",
      event: () => {
        setShowDailyLogPopUp(true);
      },
    },
    {
      id: 2,
      icon: <DescriptionOutlinedIcon fontSize="large" />,
      name: "Private Notes",
      event: () => {
        openModalComments(COMMENTSTYPE.PRIVATE_NOTES);
      },
    },
    {
      id: 3,
      icon: <ChatBubbleOutlineOutlinedIcon fontSize="large" />,
      name: "Comments",
      event: () => {
        openModalComments(COMMENTSTYPE.COMMENTS);
      },
    },
    {
      id: 4,
      icon: <BuildOutlinedIcon fontSize="large" />,
      name: "Deficiencies",
      event: () => {
        openModalComments(COMMENTSTYPE.DEFICIENCIES);
      },
    },
    {
      id: 5,
      icon: <AutoAwesomeMotionOutlinedIcon fontSize="large" />,
      name: "Mem of Und",
      event: () => {
        openMemorandum();
      },
    },
    {
      id: 6,
      icon: <SettingsOutlinedIcon fontSize="large" />,
      name: "C/W Orders",
      event: () => {
        openChangeOrders();
      },
    },
    {
      id: 7,
      icon: <CalculateOutlinedIcon fontSize="large" />,
      name: "E=mc²",
      event: () => {
        openCalculator();
      },
    },
    {
      id: 8,
      icon: <CompareArrowsOutlinedIcon fontSize="large" />,
      name: "Conversions",
      event: () => {
        openConversions();
      },
    },
    {
      id: 9,
      icon: <AttachMoneyOutlinedIcon fontSize="large" />,
      name: "Testing Unit Cost",
      event: () => {
        setTestingUnitCostModalShow(true);
      },
    },
    {
      id: 10,
      icon: <SettingsCellOutlinedIcon fontSize="large" />,
      name: "GPS App",
      event: () => {
        openGPSApp();
      },
    },
    {
      id: 11,
      icon: <InsertDriveFileOutlinedIcon fontSize="large" />,
      name: "Punchlist",
      event: () => {
        openPunchList();
      },
    },
  ];

  const { execute, data } = useAsyncQuery<ProjectDTO>(
    getProjectSingleService.getProjectSingle
  );

  useEffect(() => {
    const getData = async () => {
      await studiesHistoryService.save(
        projectIdValueRedux,
        report?.reportTypeId ?? 0,
        report?.reportId ?? 0,
        "opened",
        location.pathname
      );
    };
    const getProjectData = async () => {
      await execute(projectIdValueRedux);
    };

    if (report?.reportId !== 0 && report?.reportTypeId !== 0) getData();
    if (projectIdValueRedux > 0 && user) getProjectData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [report]);

  const projectCompleted =
    data?.projectStatus?.name === projectStatus.Complete.name ||
    data?.projectStatus?.name === projectStatus.Canceled.name ||
    data?.projectStatus?.name === projectStatus.Incomplete.name ||
    data?.projectStatus?.name === projectStatus.Approved.name ||
    data?.projectStatus?.name === projectStatus.Rejected.name;

  const openModalComments = (types: number) => {
    setTypeComment(types);
    setShowComments(true);
  };

  const openChangeOrders = () => {
    setShowChangeOrdersPopUp(true);
  };

  const openMemorandum = () => {
    setShowMemorandumPopUp(true);
  };

  const openCalculator = () => {
    setState({ ...state, right: false });
    setShowCalculatorPopUp(true);
  };

  const openConversions = () => {
    setState({ ...state, right: false });
    setShowConversionsPopUp(true);
  };

  const openPunchList = () => {
    setShowPunchList(true);
  };

  const openGPSApp = () => {
    setShowGPSAppPopUp(true);
  };

  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setState({ ...state, [anchor]: open });
    };

  type OptionsTypes = {
    title: string;
    icon: React.ReactNode;
    event: () => void;
  };

  function Options({ title, icon, event }: OptionsTypes) {
    const isSelected = title === selectedOption;
    return (
      <Grid item xs={6}>
        <OptionButton
          onClick={() => {
            setSelectedOption(title);
            event();
          }}
          active={isSelected}
        >
          {icon}
        </OptionButton>
        <TitleButton variant="subtitle2" gutterBottom>
          {title}
        </TitleButton>
      </Grid>
    );
  }

  return (
    <>
      <ToolTip title="Toolbar">
        <Fab
          size="small"
          color="success"
          aria-label="Edit"
          onClick={toggleDrawer("right", true)}
        >
          <LibraryAddOutlinedIcon />
        </Fab>
      </ToolTip>
      <Drawer
        anchor="right"
        open={state.right}
        onClose={toggleDrawer("right", false)}
      >
        <Wrapper>
          <Box px={4} my={3}>
            <Grid container spacing={3}>
              {options
                .filter((option) => arrayMenu?.includes(option.id))
                .map((item: any, index: number) => (
                  <Options
                    key={index}
                    title={item.name}
                    icon={item.icon}
                    event={item.event}
                  />
                ))}
            </Grid>
          </Box>
        </Wrapper>
      </Drawer>
      {showComments && (
        <CommentsModal
          isDialogOpen={showComments}
          setIsDialogOpen={setShowComments}
          type={typeComment}
          projectId={projectIdValueRedux}
          projectCompleted={projectCompleted}
        />
      )}
      {showChangeOrdersPopUp && (
        <ChangeWorkOrdersPopUp
          setShowChangeOrdersPopUp={setShowChangeOrdersPopUp}
          showChangeOrdersPopUp={showChangeOrdersPopUp}
          projectId={projectIdValueRedux}
          projectCompleted={projectCompleted}
        />
      )}
      {showMemorandumPopUp && (
        <MemorandumPopUp
          showMemorandumPopUp={showMemorandumPopUp}
          setShowMemorandumPopUp={setShowMemorandumPopUp}
          projectId={projectIdValueRedux}
          projectCompleted={projectCompleted}
        />
      )}
      {showCalculatorPopUp && (
        <CalculatorPopUp
          showCalculator={showCalculatorPopUp}
          setShowCalculator={setShowCalculatorPopUp}
        ></CalculatorPopUp>
      )}
      {showConversionsPopUp && (
        <ConversionsPopUp
          showConversions={showConversionsPopUp}
          setShowConversions={setShowConversionsPopUp}
        ></ConversionsPopUp>
      )}

      {projectIdValueRedux !== 0 && (
        <DailyLogPopUp
          showDailyLogPopUp={showDailyLogPopUp}
          setShowDailyLogPopUp={setShowDailyLogPopUp}
          projectId={projectIdValueRedux}
        ></DailyLogPopUp>
      )}
      {testingUnitCostModalShow && (
        <TestingUnitCostModal
          showPopup={testingUnitCostModalShow}
          setShowPopup={setTestingUnitCostModalShow}
        />
      )}
      <GPSAppPopUp
        showGPSApp={showGPSAppPopUp}
        setShowGPSApp={setShowGPSAppPopUp}
      />
      {showPunchList && (
        <PunchListPopUp
          showPunchList={showPunchList}
          setShowPunchList={setShowPunchList}
        />
      )}
    </>
  );
};

export default FloatingBar;
