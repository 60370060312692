import { useForm } from "src/hooks";
import { Grid, Typography, Stack } from "@mui/material";
import { Form } from "src/hooks/useForm";
import { FormCancelButton, FormSaveButton, FormText } from "../formControls";
import Popup from "../Popup";
import { AuthenticationService } from "src/services";
import useLog from "src/hooks/useLog";
import { useState } from "react";

interface PasswordConfirmModalProps {
  isDialogOpen: boolean;
  setIsDialogOpen: (val: boolean) => void;
  setIsValidPassword: (val: boolean) => void;
  text?: React.ReactNode;
  disabled?: boolean;
  title?: string;
}

interface IPassword {
  password: string;
}

const initialValues: IPassword = {
  password: "",
};

const PasswordConfirmModal = (props: PasswordConfirmModalProps) => {
  const {
    isDialogOpen,
    setIsDialogOpen,
    setIsValidPassword,
    text,
    disabled,
    title,
  } = props;
  const { log } = useLog();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const validate = (fieldValues = values) => {
    let temp: Record<string, string> = { ...errors };

    // temp.name = new Validator(fieldValues, "name")
    //   .isRequired("The SystemCostsCatalog's name is required")
    //   .maxLength(200, "Only 200 character are allowed")
    //   .toString();

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, errors, setErrors, handleInputChange, resetForm } = useForm(
    initialValues,
    false,
    validate
  );

  const handleClose = () => {
    resetForm();
    setIsDialogOpen(false);
  };

  const handleSave = async () => {
    if (!validate()) return;

    try {
      setIsSubmitting(true);
      const res = await AuthenticationService.checkSystemPassword(
        values.password
      );

      setIsValidPassword(res.status === 204);
    } catch (error) {
      log.error("Incorrect password");
    } finally {
      setIsDialogOpen(false);
      setIsSubmitting(false);
    }
  };

  const row = 12;
  return (
    <>
      <Popup
        openPopup={isDialogOpen}
        setOpenPopup={setIsDialogOpen}
        title={title ? title : `Edit`}
        onClose={() => setIsDialogOpen(false)}
        disableClickOutside={true}
        disableEscKeyDown={true}
      >
        <Form>
          <Grid container spacing={5}>
            <Grid item xs={row}>
              {text ? (
                text
              ) : (
                <>
                  <Typography variant="subtitle2" gutterBottom>
                    To proceed with updating this System Cost Item, AMP needs to
                    be certain that you are a AMP authorized System
                    Administrator.
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    Please enter the authorized System Administrator Password:
                  </Typography>
                </>
              )}
            </Grid>
            <Grid item xs={row}>
              <FormText
                type="password"
                name="password"
                label="Password"
                value={values.password}
                onChange={handleInputChange}
                error={errors.password}
                disabled={disabled}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                    handleSave();
                  }
                }}
              />
            </Grid>
          </Grid>
        </Form>
        <>
          <Stack direction="row" spacing={2}>
            <FormCancelButton
              onClick={handleClose}
              isSubmitting={isSubmitting}
            />
            <FormSaveButton
              onClick={handleSave}
              disabled={disabled || !values.password}
              isSubmitting={isSubmitting}
            />
          </Stack>
        </>
      </Popup>
    </>
  );
};

export default PasswordConfirmModal;
