import { ChillerStudyProvider } from "../context/ChillerStudyContext";
import ChillerStep1 from "./ChillerStep1";
const Index = () => {
  return (
    <ChillerStudyProvider>
      <ChillerStep1 />
    </ChillerStudyProvider>
  );
};

export default Index;
