import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import { useParams } from "react-router-dom";
import {
  Card as MuiCard,
  Divider as MuiDivider,
  CardContent,
  Typography,
  Grid,
  Stack,
} from "@mui/material";
import { spacing } from "@mui/system";
import useForm, { Form } from "src/hooks/useForm";
import FormText from "src/components/formControls/FormText";
import {
  FormActionButton,
  FormButton,
  FormDatePicker,
} from "src/components/formControls";
import { FilterType } from "./types/FilterTypes";
import HistoryService from "src/services/historyService";
import moment from "moment/moment.js";
import { useTable } from "src/hooks/useTable";
import { ColumnType } from "src/types/enhancedTable";
import { getQueryString } from "./utils/common";
import { IUserAccessHistoryData } from "src/ts/interfaces/history";
import { useAsyncQuery, useAuth, useLog } from "src/hooks";
import HeaderPage from "src/components/page/HeaderPage";
import { ICompany } from "src/ts/interfaces/company";
import CompanyService from "src/services/companyService";
import { ViewList } from "@mui/icons-material";
import SatellitePopup from "../company/components/SatellitePopup";
import { Validator } from "src/ts/types/Validator";
import { ROLES } from "src/constants";
import CompanyInformation from "../project/createEditProject/components/CompanyInformation";
import { SearchIcon } from "src/components/icons";

const Divider = styled(MuiDivider)(spacing);
const Card = styled(MuiCard)(spacing);

const initialValues: FilterType = {
  filter: "",
  from: moment().startOf("month").format("MM/DD/YYYY"),
  to: moment().format("MM/DD/YYYY"),
};

function UserAccessHistroy() {
  const { user } = useAuth();
  let { companyId } = useParams<{ companyId: string }>();
  let index = 0;
  const { log } = useLog();
  const [callFromApply, setCallFromApply] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const validate = (fieldValues = values) => {
    let temp: Record<string, string> = { ...errors };

    temp.from = new Validator(fieldValues, "from")
      .isRequired("The date is required.")
      .toString();

    temp.to = new Validator(fieldValues, "to")
      .isRequired("The date is required.")
      .toString();

    const end = new Date(fieldValues.to);
    const start = new Date(fieldValues.from);
    if (fieldValues.to && fieldValues.from) {
      if (end < start) {
        temp.from = `Start date can not be greater than  ${moment(end).format(
          "MM/DD/YYYY"
        )}`;
        temp.to = `End date can not be less than  ${moment(start).format(
          "MM/DD/YYYY"
        )}`;
      } else {
        temp.from = "";
        temp.to = "";
      }
    }

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };
  const { values, errors, setErrors, handleInputChange, setValues } = useForm(
    initialValues,
    false,
    validate
  );

  const getColumns = (): ColumnType[] => [
    {
      id: "id",
      label: "#",
      type: "custom",
      sort: false,
      align: "center",
      hide: true,
      callback: (row: IUserAccessHistoryData) => {
        index = index + 1;
        return (
          <>
            <Stack ml={7}>
              <Typography> {index + page * 10}</Typography>
            </Stack>
          </>
        );
      },
    },
    {
      id: "userid",
      label: "User Name",
      type: "custom",
      sort: true,
      callback: (row: IUserAccessHistoryData) => {
        return (
          <>
            {row.user.name} {row.user.lastName}
          </>
        );
      },
    },
    {
      id: "email",
      label: "Email",
      type: "custom",
      sort: true,
      callback: (row: IUserAccessHistoryData) => {
        return <>{row.user.email}</>;
      },
    },
    {
      id: "date",
      label: "Date",
      type: "utcDate",
      format: "MM/DD/YYYY h:mma",
      sort: true,
    },
    {
      id: "action",
      label: "Action",
      type: "string",
      sort: true,
    },
  ];

  let columns = getColumns();

  const {
    Table,
    setRowsPerPage,
    page,
    setDataSet,
    setCount,
    orderBy,
    order,
    setPage,
    setRefreshGrid,
    refreshGrid,
  } = useTable(columns, { rowsPerPageOptions: [10] });

  const handleRefreshGrid = () => {
    setPage(0);
    setCallFromApply(!callFromApply);
  };

  const { execute: getCompanyById, data: company } = useAsyncQuery<ICompany>(
    CompanyService.getById
  );

  useEffect(() => {
    const getCompany = async () => {
      try {
        if (!validate()) return;
        await getCompanyById(companyId);
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      }
    };

    if (Number(companyId) > 0) getCompany();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyId]);

  useEffect(() => {
    const getData = async () => {
      try {
        if (!validate()) return;
        setIsSubmitting(true);
        const request = await HistoryService.getUserAccessHistory(
          {
            page: page + 1,
            filter: values.filter,
            sortColumn: orderBy,
            sortDec: order === "desc" ? true : false,
            dateFrom: values.from,
            dateTo: values.to,
            companyId: companyId?.toString() ?? "",
          },
          getQueryString
        );

        setCount(request.data.count);
        setDataSet(request.data.data);
        setRowsPerPage(10);
        setIsSubmitting(false);
        setRefreshGrid(!refreshGrid);
      } catch (error: any) {
        setIsSubmitting(false);
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      }
    };

    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, orderBy, order, callFromApply, companyId]);

  const [showSatelliteModal, setShowSatelliteModal] = useState(false);

  const showSatelliteHandler = async () => {
    setShowSatelliteModal(true);
  };

  const validateMinDate = (e: any) => {
    if (e.target.value !== "Invalid date") {
      let newValues = { ...values };
      newValues.from = e.target.value;

      if (Date.parse(newValues.to) < Date.parse(newValues.from))
        setValues({ ...newValues, to: newValues.from });
      else setValues({ ...newValues });
    }
  };

  return (
    <>
      <HeaderPage
        title={"Users Access History"}
        parentText={"Dashboard"}
        parentLink={`/`}
        actionSection={
          company?.headquarters &&
          company?.satelliteCount !== undefined &&
          (user?.role === ROLES.CompanyOwner ||
            user?.role === ROLES.SuperAdministrator ||
            user?.role === ROLES.Administrator ||
            user?.role === ROLES.Training) &&
          company?.satelliteCount > 0 && (
            <FormButton
              text={"View Satellites"}
              onClick={showSatelliteHandler}
              startIcon={<ViewList />}
              size="small"
              variant="outlined"
              color="primary"
            />
          )
        }
        infoSection={
          !company?.headquarters && <CompanyInformation company={company} />
        }
      ></HeaderPage>

      <Divider my={6} />
      <Card mb={6}>
        <CardContent>
          <Grid container spacing={10}>
            <Grid item xs={12}>
              <Form>
                <Grid container spacing={5}>
                  <Grid item xs={4}>
                    <FormDatePicker
                      label={"Date From"}
                      name="from"
                      disablePast={false}
                      value={values.from}
                      onChange={validateMinDate}
                      error={errors.from}
                    />
                  </Grid>

                  <Grid item xs={4} sx={{ textAlign: "right" }}>
                    <FormDatePicker
                      label={"Date To"}
                      name="to"
                      disablePast={false}
                      value={values.to}
                      onChange={handleInputChange}
                      error={errors.to}
                      fullWidth={true}
                      minDate={values.from}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <FormActionButton
                      mr={2}
                      text="Search"
                      size="medium"
                      onClick={handleRefreshGrid}
                      isSubmitting={isSubmitting}
                      type="search"
                      startIcon={<SearchIcon />}
                    />
                  </Grid>
                  <Grid item xs={8}>
                    <FormText
                      name="filter"
                      label="Type text to search"
                      value={values.filter}
                      onChange={handleInputChange}
                      error={errors.filter}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Table />
                  </Grid>
                </Grid>
              </Form>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      <SatellitePopup
        showSatellitePopup={showSatelliteModal}
        setShowSatellitePopup={setShowSatelliteModal}
        companyId={Number(companyId) ?? 0}
        historyView={"Users Access History"}
        companyName={company?.name}
      />
    </>
  );
}

export default UserAccessHistroy;
