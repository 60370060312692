import { IPsychrometric, IPsychrometricArgs } from "src/ts/interfaces";
import useFormulas from "./useFormulas";
import psychrometricService from "src/services/psychrometricService";

interface IPsychrometricByProject {
  psychrometric: IPsychrometric;
  btuh: number | null;
  sensibleHeat: number | null;
}

function usePsychrometric() {
  const { formulaSystem } = useFormulas();

  const getByProject = async (psychrometricArgs: IPsychrometricArgs) => {
    const emptyResponse: IPsychrometric = {
      atmosphericPress: 0,
      satVaporPress: 0,
      humidityRatio: 0,
      partialVaporPress: 0,
      enthalpy: 0,
      specificVolume: 0,
      density: 0,
      message: "Data is not enough to get Psychrometrics Data",
    };

    const empty: IPsychrometricByProject = {
      psychrometric: emptyResponse,
      btuh: null,
      sensibleHeat: null,
    };

    if (
      psychrometricArgs.humidityMeasuredAs === null ||
      psychrometricArgs.humidityMeasuredAs === "" ||
      psychrometricArgs.humidityMeasuredAs === undefined
    ) {
      return Promise.resolve(empty);
    }

    if (psychrometricArgs?.humidityValue === null)
      return Promise.resolve(empty);
    if (psychrometricArgs?.dryBulbF === null) return Promise.resolve(empty);

    const formulas = await formulaSystem.setSystem(psychrometricArgs.projectId);
    try {
      const psychrometricRes = await psychrometricService.getByProject(
        psychrometricArgs
      );

      var enalphy = psychrometricRes.data.enthalpy;
      var density = psychrometricRes.data.density;
      var btuh = formulas.calculateBtuH(
        psychrometricArgs.totalCFM,
        enalphy,
        density
      );
      var sensibleHeat = formulas.calculateSensibleHeat(
        psychrometricArgs.totalCFM,
        psychrometricArgs.dryBulbF
      );
      const res: IPsychrometricByProject = {
        psychrometric: psychrometricRes.data,
        btuh: btuh,
        sensibleHeat: sensibleHeat,
      };
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  };

  const psychrometric = { getByProject };
  return { psychrometric };
}

export default usePsychrometric;
