import React from "react";
import styled from "@emotion/styled";
import {
  Grid,
  List,
  ListItemText as MuiListItemText,
  ListItemButtonProps as MuiListItemButtonProps,
  ListItemButton as MuiListItemButton,
  ListItemIcon,
  Link,
  Stack,
  Box,
} from "@mui/material";
import Public from "@mui/icons-material/Public";
import { ToolTip } from "./others/ToolTip";
import { Typography } from "@mui/material";
import FormTooltip from "./formControls/FormTooltip";

//@ts-ignore
interface ListItemButtonProps extends MuiListItemButtonProps {
  component?: string;
  href?: string;
}

const Wrapper = styled.div`
  padding: ${(props) => props.theme.spacing(0.25)}
    ${(props) => props.theme.spacing(4)};
  background: ${(props) => props.theme.footer.background};
  position: relative;
`;

const ListItemButton = styled(MuiListItemButton)<ListItemButtonProps>`
  display: inline-block;
  width: auto;
  padding-left: ${(props) => props.theme.spacing(2)};
  padding-right: ${(props) => props.theme.spacing(2)};

  &,
  &:hover,
  &:active {
    color: #ff0000;
  }
`;

const ListItemText = styled(MuiListItemText)`
  span {
    color: ${(props) => props.theme.footer.color};
  }
`;

const version = process.env.REACT_APP_VERSION ?? "0.0.0";

function Footer() {
  return (
    <Wrapper>
      <Grid container spacing={0}>
        <Grid
          container
          item
          xs={12}
          md={
            process.env.REACT_APP_ENVIRONMENT === "SCHOOL" ||
            process.env.REACT_APP_ENVIRONMENT === "TRAINING"
              ? 3
              : 6
          }
        >
          <List>
            <Stack direction="row" spacing={2}>
              <ListItemButton>
                <ListItemIcon>
                  <Public />
                  <Link
                    underline="none"
                    color="inherit"
                    href="https://auditmaster.pro"
                    target="_blank"
                  >
                    <Typography mt={0.5}>
                      {`Visit our WebSite${
                        process.env.REACT_APP_ENVIRONMENT !== "SCHOOL" &&
                        process.env.REACT_APP_ENVIRONMENT !== "TRAINING"
                          ? `, version:${version}`
                          : " "
                      }`}
                    </Typography>
                  </Link>
                </ListItemIcon>
              </ListItemButton>
            </Stack>
          </List>
        </Grid>
        {(process.env.REACT_APP_ENVIRONMENT === "SCHOOL" ||
          process.env.REACT_APP_ENVIRONMENT === "TRAINING") && (
          <>
            <Grid
              container
              item
              md={3}
              alignItems={"center"}
              justifyContent="flex-end"
            >
              <List>
                <Stack direction="row">
                  <ToolTip title="Yopmail.com">
                    <Link target="_blank" href={`https://yopmail.com/`}>
                      <Box
                        component="img"
                        alt="InstantSSL"
                        src={"/static/img/Yopmail.png"}
                        sx={{ width: "35px" }}
                      />
                    </Link>
                  </ToolTip>
                </Stack>
              </List>
              <Typography pl={1}>
                <ToolTip title="Yopmail.com">
                  <Link
                    underline="none"
                    color="inherit"
                    target="_blank"
                    href={`https://yopmail.com/`}
                  >
                    Temporary Emails
                  </Link>
                </ToolTip>
              </Typography>
            </Grid>
          </>
        )}
        <Grid container item xs={12} md={6} justifyContent="flex-end">
          <List>
            <Link
              href="https://auditmaster.pro/legal?id=privacy_statement"
              target="_blank"
            >
              <ListItemButton>
                <ListItemText primary="Privacy" />
              </ListItemButton>
            </Link>
            <Link
              href="https://auditmaster.pro/legal?id=amp_temps"
              target="_blank"
            >
              <ListItemButton>
                <ListItemText primary="Terms of Service" />
              </ListItemButton>
            </Link>
            <ListItemButton>
              <ListItemText primary={`© ${new Date().getFullYear()} - AMP`} />
            </ListItemButton>
          </List>
        </Grid>
      </Grid>
    </Wrapper>
  );
}

export default Footer;
