import React, { Dispatch, SetStateAction } from "react";
import { Grid, Typography } from "@mui/material";
import {
  FormNumeric2,
  FormSelect,
  FormText,
} from "src/components/formControls";
import { FooterButtons } from "../../components/FooterButtons";
import { IBoilerTest } from "src/ts/interfaces/study/boiler";
import { steps } from "./const";
import { NavigateFunction } from "react-router-dom";
import FormTooltip from "src/components/formControls/FormTooltip";
import FormTitleBox from "src/components/formControls/FormTitleBox";

interface Props {
  values: IBoilerTest;
  handleInputChange: (e: any) => void;
  errors: Record<string, string>;
  showskeleton: boolean;
  disableStudy: boolean;
  handleSave: () => Promise<void>;
  navigate: NavigateFunction;
  companyId: number;
  systemOfMeasurement: {
    get: (key: string) => any;
    setSystem: (system: string) => void;
    getByProject: (projectId: number) => Promise<string | null>;
    measurementSystem: string | null;
  };
  setValues: Dispatch<SetStateAction<IBoilerTest>>;
}

const Step2 = ({
  values,
  handleInputChange,
  errors,
  showskeleton,
  disableStudy,
  handleSave,
  navigate,
  companyId,
  systemOfMeasurement,
  setValues,
}: Props) => {
  const numberCirculatingPumps = [
    { key: 1, value: "1" },
    { key: 2, value: "2" },
    { key: 3, value: "3" },
    { key: 4, value: "4" },
    { key: 5, value: "5" },
    { key: 6, value: "6" },
    { key: 7, value: "7" },
    { key: 8, value: "8" },
    { key: 9, value: "9" },
    { key: 10, value: "10" },
  ];
  const numberPrimaryPumps = [
    { key: 1, value: "1" },
    { key: 2, value: "2" },
    { key: 3, value: "3" },
    { key: 4, value: "4" },
    { key: 5, value: "5" },
    { key: 6, value: "6" },
    { key: 7, value: "7" },
    { key: 8, value: "8" },
    { key: 9, value: "9" },
    { key: 10, value: "10" },
    { key: 11, value: "11" },
    { key: 12, value: "12" },
    { key: 13, value: "13" },
    { key: 14, value: "14" },
    { key: 15, value: "15" },
    { key: 16, value: "16" },
    { key: 17, value: "17" },
    { key: 18, value: "18" },
    { key: 19, value: "19" },
    { key: 20, value: "20" },
  ];

  const nextStepHandler = async () => {
    if (!disableStudy) handleSave();
    navigate(`/studies/BoilerTest/step3/${values?.id}/`);
  };

  const backStepHandler = async () => {
    if (!disableStudy) handleSave();
    navigate(`/studies/BoilerTest/step1/${values?.id}/`);
  };

  const getBTUh = () => {
    if (values.designRatedMBH != null)
      setValues({ ...values, designRatedBTUh: values.designRatedMBH * 1000 });
  };

  const getMBH = () => {
    if (values.designRatedBTUh != null)
      setValues({ ...values, designRatedMBH: values.designRatedBTUh / 1000 });
  };

  const getWaterDeltaT = () => {
    if (
      values.designRatedReturnCoolEWT != null &&
      values.designRatedSupplyHot != null
    )
      setValues({
        ...values,
        designRatedBoilerWaterDeltaT: Math.abs(
          values.designRatedReturnCoolEWT - values.designRatedSupplyHot
        ),
      });
  };

  return (
    <>
      <FormTitleBox title={"Boiler Design Data"}>
        <Grid container spacing={5} p={2}>
          <Grid item xs={5}>
            <FormNumeric2
              name="designRatedGPMTotal"
              label={`Design/Rated ${systemOfMeasurement.get("gpm")} Total`}
              value={values?.designRatedGPMTotal}
              onChange={handleInputChange}
              error={errors.designRatedGPMTotal}
              showSkeleton={showskeleton}
              disabled={disableStudy}
              decimalScale={3}
              thousandSeparator={true}
            />
          </Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={5}>
            <FormText
              name="designManufacturer"
              label="Design Manufacturer"
              value={values?.designManufacturer}
              onChange={handleInputChange}
              error={errors.designManufacturer}
              showSkeleton={showskeleton}
              disabled={disableStudy}
            />
          </Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={5}>
            <FormText
              name="designModelNumber"
              label="Design Model #"
              value={values?.designModelNumber}
              onChange={handleInputChange}
              error={errors.designModelNumber}
              showSkeleton={showskeleton}
              disabled={disableStudy}
            />
          </Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={5}>
            <FormNumeric2
              name="designRatedReturnCoolEWT"
              label={`Design/Rated (Return/Cool) EWT (${systemOfMeasurement.get(
                "temperature"
              )})`}
              value={values?.designRatedReturnCoolEWT}
              onChange={handleInputChange}
              error={errors.designRatedReturnCoolEWT}
              showSkeleton={showskeleton}
              disabled={disableStudy}
              decimalScale={3}
              onBlur={getWaterDeltaT}
              thousandSeparator={true}
            />
          </Grid>
          <Grid item xs={1}>
            <Typography pt={2}>EWT</Typography>
          </Grid>
          <Grid item xs={5}>
            <FormNumeric2
              name="designRatedSupplyHot"
              label={`Design/Rated (Supply/Hot) LWT (${systemOfMeasurement.get(
                "temperature"
              )})`}
              value={values?.designRatedSupplyHot}
              onChange={handleInputChange}
              error={errors.designRatedSupplyHot}
              showSkeleton={showskeleton}
              disabled={disableStudy}
              decimalScale={3}
              onBlur={getWaterDeltaT}
              thousandSeparator={true}
            />
          </Grid>
          <Grid item xs={1}>
            <Typography pt={2}>LWT</Typography>
          </Grid>
          <Grid item xs={5}>
            <FormNumeric2
              name="designRatedBoilerWaterDeltaT"
              label={`Design/Rated Boiler Water Delta T (${systemOfMeasurement.get(
                "temperature"
              )})`}
              value={values?.designRatedBoilerWaterDeltaT}
              onChange={handleInputChange}
              error={errors.designRatedBoilerWaterDeltaT}
              showSkeleton={showskeleton}
              disabled={disableStudy}
              decimalScale={3}
              thousandSeparator={true}
            />
          </Grid>
          <Grid item xs={1}>
            <Typography pt={2}>ΔT</Typography>
          </Grid>
          <Grid item xs={5}>
            <FormNumeric2
              name="designRatedBTUh"
              label={`Design Rated (${systemOfMeasurement.get("btuh")})`}
              value={values?.designRatedBTUh}
              onChange={handleInputChange}
              onBlur={getMBH}
              error={errors.designRatedBTUh}
              showSkeleton={showskeleton}
              disabled={disableStudy}
              decimalScale={3}
              thousandSeparator={true}
            />
          </Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={5}>
            <FormNumeric2
              name="designRatedMBH"
              label={`Design/Rated (${systemOfMeasurement.get("mbh")})`}
              value={values?.designRatedMBH}
              onChange={handleInputChange}
              error={errors.designRatedMBH}
              showSkeleton={showskeleton}
              disabled={disableStudy}
              decimalScale={3}
              onBlur={getBTUh}
              thousandSeparator={true}
            />
          </Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={5}>
            <FormNumeric2
              name="designRatedBoilerEfficiency"
              label={`Design/Rated Boiler Efficiency`}
              value={values?.designRatedBoilerEfficiency}
              onChange={handleInputChange}
              error={errors.designRatedBoilerEfficiency}
              showSkeleton={showskeleton}
              disabled={disableStudy}
              decimalScale={3}
              thousandSeparator={true}
            />
          </Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={5}>
            <FormNumeric2
              name="designRatedTubeBundlePSI"
              label={`Design/Rated Tube Bundle Δ ${systemOfMeasurement.get(
                "psi"
              )}`}
              value={values?.designRatedTubeBundlePSI}
              onChange={handleInputChange}
              error={errors.designRatedTubeBundlePSI}
              showSkeleton={showskeleton}
              disabled={disableStudy}
              decimalScale={3}
              thousandSeparator={true}
            />
          </Grid>
          <Grid item xs={1}>
            <Typography pt={2}>Δ PSI</Typography>
          </Grid>
          <Grid item xs={5}>
            <FormSelect
              name={"numberCirculatingPumps"}
              label={"Number of Circulating Pumps Serving this Boiler"}
              onChange={handleInputChange}
              showSkeleton={showskeleton}
              disabled={disableStudy || values?.numberCirculatingPumpsCreated}
              error={errors.numberCirculatingPumps}
              value={values?.numberCirculatingPumps}
              options={numberCirculatingPumps}
              defaultValue={{
                key: null,
                value: "Select Number of Circulating Pumps",
              }}
            ></FormSelect>
          </Grid>
          <Grid item xs={1}>
            <FormTooltip
              text={
                "Circulating pumps are pumps specifically installed to maintain consistent boiler water temperature"
              }
            ></FormTooltip>
          </Grid>
          <Grid item xs={5}>
            <FormSelect
              name={"numberPrimaryPumps"}
              label={"Number of Primary Pumps Serving this Boiler"}
              onChange={handleInputChange}
              showSkeleton={showskeleton}
              disabled={disableStudy || values?.numberPrimaryPumpsCreated}
              error={errors.numberPrimaryPumps}
              value={values?.numberPrimaryPumps}
              options={numberPrimaryPumps}
              defaultValue={{
                key: null,
                value: "Select Number of Primary Pumps",
              }}
            ></FormSelect>
          </Grid>
          <Grid item xs={1}>
            <FormTooltip
              text={
                "Primary pumps are pumps specifically installed to either maintain consistent loop boiler water temperature while secondary field pumps feed water to coils such as: Air handler coils, VAV coils, radiators, convectors, etc. Boiler primary pumps may also pump water through the boiler and provide water to: Air handler coils, VAV coils, radiators, convectors, etc. Meaning secondary pumps are not installed on the system"
              }
            ></FormTooltip>
          </Grid>
          <Grid item xs={12}>
            <FooterButtons
              activeStep={1}
              stepsCount={steps.length}
              projectId={values?.projectId ?? 0}
              companyId={companyId}
              isSaving={showskeleton}
              disabled={disableStudy}
              reportName="Static Report"
              saveHandler={handleSave}
              nextStepHandler={nextStepHandler}
              previousStepHandler={backStepHandler}
              isComplete={values?.isComplete ?? true}
            />
          </Grid>
        </Grid>
      </FormTitleBox>
    </>
  );
};

export default Step2;
