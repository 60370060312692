import { IProjectReporDisplayt } from "src/ts/interfaces";
import axios from "src/utils/axios";

const getChildReports = (
  projectId: number,
  reportId: number,
  reportTypeId: number
) => {
  return axios.get<IProjectReporDisplayt[]>(
    `ProjectReportChild/ChildReports/${projectId}/${reportId}/${reportTypeId}`
  );
};

const getChildReportsParents = (projectId: number, reportId: number) => {
  return axios.get<IProjectReporDisplayt[]>(
    `ProjectReportChild/ChildReports/${projectId}/${reportId}`
  );
};

const projectReportChild = {
  getChildReports,
  getChildReportsParents,
};

export default projectReportChild;
