import axios from "src/utils/axios";
import { IFanMotorDTO } from "src/ts/interfaces";

const getById = (id: number) => {
  return axios.get<IFanMotorDTO>(`FanMotor/${id}`);
};

const fanMotorService = {
  getById,
};

export default fanMotorService;
