import {
  CardContent,
  Grid,
  Card as MuiCard,
  Divider as MuiDivider,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";
import styled from "@emotion/styled";
import { useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  useAsyncQuery,
  useAuth,
  useEffectOnce,
  useLog,
  useSystemOfMeasurement,
} from "src/hooks";
import chillerService from "src/services/study/chillerService";
import { IChillerTowerCondenser, IReportType } from "src/ts/interfaces";
import { Mode } from "src/ts/types";
import StudyWizard from "../../components/StudyWizard";
import { FooterButtons } from "../../components/FooterButtons";
import { steps, studyTypeCode } from "../const";
import projectReportService from "src/services/study/projectReportService";
import {
  IProjectReport,
  ProjectDTO,
} from "src/ts/interfaces/project/projectDto";
import reportTypeService from "src/services/study/reportTypeService";
import studiesHistoryService from "src/services/study/studiesHistoryService";
import useChillerStudy from "../hooks/useChillerStudy";
import TowerCondenserList from "../components/TowerCondenserList";
import { IChillerTowerFanMotor } from "src/ts/interfaces/study/tower";
import TowerCondenserActualForm from "../components/TowerCondenserActualForm";
import TowerCondenserTable from "../components/TowerCondenserTable";
import FanMotorList from "../components/FanMotorList";
import FanMotorActualForm from "../components/FanMotorActualForm";
import projectService from "src/services/project/projectService";
import HeaderStudyPage from "src/components/page/HeaderStudyPage";

const Divider = styled(MuiDivider)(spacing);
const Card = styled(MuiCard)(spacing);
const activeStep = 3;

const ChillerStep42 = () => {
  const { user } = useAuth();
  const params = useParams<{ id: string }>();
  const id = parseInt(params?.id === undefined ? "0" : params?.id);
  let navigate = useNavigate();
  const { log } = useLog();
  const { systemOfMeasurement } = useSystemOfMeasurement(true);
  const [reportType, setReportType] = useState<IReportType | null>();

  const { execute: executeReport, data: dataReport } =
    useAsyncQuery<IProjectReport>(projectReportService.report);

  const { execute: getProject, data: project } = useAsyncQuery<ProjectDTO>(
    projectService.byReportType
  );

  const {
    chillerValues,
    setChillerValues,
    setTowerCondenserValues,
    setFanMotorValues,
    towerCondenserValues,
    validateFanMotorValues,
    fanMotorValues,
    updateFanMotor,
  } = useChillerStudy();

  const [towerCondensers, setTowerCondensers] = useState<
    IChillerTowerCondenser[]
  >([]);
  const [fanMotors, setFanMotors] = useState<IChillerTowerFanMotor[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffectOnce(() => {
    const getData = async () => {
      try {
        setIsLoading(true);
        const chillerRes = await chillerService.getById(id);
        setChillerValues(chillerRes.data);

        const towerCondensersRes = await chillerService.towerCondensers(id);
        setTowerCondensers(towerCondensersRes.data);
        if (towerCondensersRes.data.length > 0) {
          setTowerCondenserValues(towerCondensersRes.data[0]);

          await getFanMotor(towerCondensersRes.data[0]);
        }

        const reportTypeRes = await reportTypeService.getReportTypeByCode(
          studyTypeCode
        );
        setReportType(reportTypeRes.data);

        await executeReport(id, reportTypeRes.data.code);

        await getProject(id, studyTypeCode);

        await systemOfMeasurement.getByProject(chillerRes.data.projectId);
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      } finally {
        setIsLoading(false);
      }
    };
    if (id > 0) getData();
  });

  const getFanMotor = async (towerCondenser: IChillerTowerCondenser) => {
    const fanMotorsRes = await chillerService.getFanMotorByTowerCondenserId(
      towerCondenser.id
    );

    if (fanMotorsRes.data.length > 0) {
      setFanMotors(fanMotorsRes.data);
      setFanMotorValues(fanMotorsRes.data[0]);
      //Training Motor
    } else {
      const fanMotorNew: any = {
        chillerTowerCondenserId: towerCondenser.id,
      };

      const fanMotorRes = await chillerService.addFanMotor(fanMotorNew);
      setFanMotors([fanMotorRes.data]);
      setFanMotorValues(fanMotorRes.data);
    }
  };

  const mode: Mode = chillerValues.isComplete ? "read" : "read&Write";

  const disableStudy = useMemo(() => {
    return (
      chillerValues?.isComplete ||
      (dataReport?.isInUse && dataReport?.isInUseById !== user?.userId) ||
      dataReport?.isPartOfProject === false
    );
  }, [
    dataReport?.isInUse,
    dataReport?.isInUseById,
    dataReport?.isPartOfProject,
    user?.userId,
    chillerValues?.isComplete,
  ]);

  const save = async () => {
    //if (!validate()) return;

    if (!disableStudy) {
      await chillerService.update(chillerValues.id, chillerValues);
      const towerCondenserRes = await chillerService.updateTowerCondenser(
        towerCondenserValues.id,
        towerCondenserValues
      );

      setTowerCondenserValues({
        ...towerCondenserValues,
        condenseraterSupplyTempComparation:
          towerCondenserRes.data.condenseraterSupplyTempComparation,
        condenseraterReturnTempComparation:
          towerCondenserRes.data.condenseraterReturnTempComparation,
        condenserDesgin: towerCondenserRes.data.condenserDesgin,
        condenserActual: towerCondenserRes.data.condenserActual,
        condenserPercentage: towerCondenserRes.data.condenserPercentage,
        condenserTowerApproach: towerCondenserRes.data.condenserTowerApproach,
        coolingTowerEfficiency: towerCondenserRes.data.coolingTowerEfficiency,
        actualTowerBTUH: towerCondenserRes.data.actualTowerBTUH,
      });

      await updateFanMotor(fanMotorValues);
      await studiesHistoryService.save(
        chillerValues.projectId,
        reportType?.id ?? 0,
        chillerValues.id,
        "saved"
      );
      log.info("Report Was saved");
    }
  };

  const next = async () => {
    if (!disableStudy) {
      await save();
    }
    const route = `/studies/Chiller/step5/${id}`;
    navigate(route);
  };

  const back = async () => {
    if (!disableStudy) {
      await save();
    }
    const route = `/studies/Chiller/step4/${id}`;
    navigate(route);
  };

  const onTowerCondenserChangeHandler = async (
    towerCondenser: IChillerTowerCondenser
  ) => {
    if (towerCondenser.id === towerCondenserValues.id) return;
    //if (!validateFanMotorValues()) return;
    if (!disableStudy) {
      await chillerService.updateTowerCondenser(towerCondenserValues.id, {
        ...towerCondenserValues,
      });
      await saveFanMotor();
    }

    const evaporatorRes = await chillerService.getTowerCondenserById(
      towerCondenser.id
    );

    setTowerCondenserValues(evaporatorRes.data);
    await getFanMotor(towerCondenser);
  };

  const saveFanMotor = async () => {
    if (!validateFanMotorValues()) return;
    await chillerService.updateFanMotor(fanMotorValues.id, fanMotorValues);
  };

  const selectFanMotorHandler = async (
    selectedFanMotor: IChillerTowerFanMotor
  ) => {
    if (!disableStudy) {
      await saveFanMotor();
    }

    const fanMotorRes = await chillerService.getFanMotorById(
      selectedFanMotor.id
    );
    setFanMotorValues(fanMotorRes.data);
  };

  return (
    <>
      <HeaderStudyPage
        headerStudiesPage={{
          code: studyTypeCode,
          system: chillerValues.system,
          id: id,
        }}
        parentText="Project"
        parentLink="/"
      />
      <Divider my={6} />

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <StudyWizard
            steps={steps}
            activeStep={activeStep}
            projectId={dataReport?.projectId}
            reportId={dataReport?.reportId}
            reportTypeId={dataReport?.reportTypeId}
          />
        </Grid>
        <Grid item xs={12}>
          <Card mb={1}>
            <CardContent>
              <Grid container spacing={5}>
                <Grid item xs={12}>
                  <Typography variant="h6">Condenser/Tower Actuals</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body2">
                    Record Condenser water supply and return temperature
                    readings. Compare data to digital readout on chiller control
                    panel.
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <TowerCondenserActualForm
                    mode={mode}
                    disableStudy={disableStudy}
                    isLoading={false}
                    systemOfMeasurement={systemOfMeasurement}
                  />
                </Grid>

                <Grid item xs={12}>
                  <TowerCondenserList
                    towerCondensers={towerCondensers}
                    isStudyComplete={disableStudy}
                    onChange={onTowerCondenserChangeHandler}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TowerCondenserTable
                    mode={mode}
                    disableStudy={disableStudy}
                    isLoading={false}
                    systemOfMeasurement={systemOfMeasurement}
                  />
                </Grid>
                <Grid item xs={12} pt={6}>
                  <Typography variant="h6">Fan Motors</Typography>
                </Grid>
                <Grid item xs={12}>
                  <FanMotorList
                    fanMotors={fanMotors}
                    disableStudy={disableStudy}
                    onSelect={selectFanMotorHandler}
                    showDelete={false}
                    showDuplicate={false}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FanMotorActualForm
                    project={project}
                    mode={mode}
                    disableStudy={disableStudy}
                    isLoading={false}
                  />
                </Grid>

                <Grid item xs={12}>
                  <FooterButtons
                    activeStep={activeStep}
                    stepsCount={steps.length}
                    projectId={dataReport?.projectId}
                    companyId={user?.companyId as number}
                    isSaving={false}
                    disabled={false}
                    reportName={reportType?.name ?? ""}
                    saveHandler={save}
                    nextStepHandler={() => next()}
                    previousStepHandler={() => back()}
                    hideSaveButton={disableStudy}
                    isComplete={chillerValues.isComplete}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default ChillerStep42;
