import axios from "src/utils/axios";
import { IUnitLabel } from "src/ts/interfaces/catalogs";

const getAll = () => {
  return axios.get<IUnitLabel[]>("unitLabel");
};

const add = (unitLabel: IUnitLabel) => {
  return axios.post("unitLabel", unitLabel);
};

const update = (unitLabel: IUnitLabel) => {
  return axios.put(`unitLabel/${unitLabel.id}`, unitLabel);
};

const remove = (id: Number) => {
  return axios.delete(`unitLabel/${id}`);
};

const dictionary = () => {
  return axios.get("unitLabel/Dictionary");
};

const unitLabelService = { getAll, add, update, remove, dictionary };
export default unitLabelService;
