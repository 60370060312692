import { Tooltip as MUIToolTip } from "@mui/material";

export function ToolTip(props: any) {
  const { children, ...other } = props;

  return (
    <MUIToolTip autoComplete="off" {...other}>
      {props.children}
    </MUIToolTip>
  );
}
