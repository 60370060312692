import axios from "src/utils/axios";
import { ICustomData, IKeyValue } from "src/ts/interfaces";
import { IMeterDevice } from "src/ts/interfaces/catalogs";

const getKeyValues = () => {
  return new Promise<ICustomData>((resolve, reject) => {
    axios
      .get<Array<IMeterDevice>>(`MeterDevice`)
      .then((response) => {
        const keyValuePair: IKeyValue<number, string>[] = response.data.map(
          (item) => {
            return {
              key: item.id!,
              value: item.name,
            };
          }
        );

        resolve({ data: keyValuePair });
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const meterDeviceService = { getKeyValues };
export default meterDeviceService;
