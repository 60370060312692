import React, { useEffect, useState, ChangeEvent } from "react";
import styled from "@emotion/styled";
import { Stack, IconButton, Chip as MuiChip, Typography } from "@mui/material";
import { spacing, SpacingProps } from "@mui/system";
import LocalEnhancedTable from "src/components/localTable/LocalTable";
import { ICoupon } from "src/ts/interfaces";
import { ColumnType } from "src/types/enhancedTable";
import { DeleteIcon, EditIcon, ForwardIcon } from "src/components/icons";
import { CouponService } from "src/services";
import DialogMessagePopup from "src/components/DialogMessagePopup";
import { FormText } from "src/components/formControls";
import { ToolTip } from "src/components/others/ToolTip";
import { GridActionButton } from "src/components/gridControls";
import { IKeyValue } from "src/ts/interfaces";
import { Permission as PermissionTypes } from "src/ts/enums";
import { useLog, usePermissions } from "src/hooks";

interface ChipProps extends SpacingProps {
  component?: React.ElementType;
  href?: string;
  icon?: JSX.Element | null;
}

const Chip = styled(MuiChip)<ChipProps>(spacing);

interface ValidCouponsProps {
  onEditCoupon: (row: ICoupon) => void;
  refreshGrid: boolean;
  onRefreshGrid: () => void;
  couponType: IKeyValue<number, string>[];
  query?: any;
}

const initialValues = {
  code: "",
};
const ValidCoupons = (props: ValidCouponsProps) => {
  const { onEditCoupon, refreshGrid, onRefreshGrid, couponType } = props;
  const [validCoupons, setValidCoupons] = useState<ICoupon[]>([]);
  const [selectedRow, setSelectedRow] = useState<ICoupon>();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showSkeleton, setShowSkeleton] = useState(false);
  const [disabledButton, setDisabledButton] = useState(false);
  const [values, setValues] = useState(initialValues);
  const { log } = useLog();
  const { fullAccess } = usePermissions(PermissionTypes.Coupons);

  const columns: ColumnType[] = [
    {
      id: "id",
      label: "#",
      type: "string",
      sort: true,
    },
    {
      id: "title",
      label: "Title",
      type: "string",
      sort: true,
    },
    {
      id: "code",
      label: "Code",
      type: "string",
      sort: true,
    },
    {
      id: "typeId",
      label: "Type",
      type: "custom",
      sort: true,
      callback: (row: ICoupon) => {
        var value = couponType.filter((item: any) => {
          return row.typeId === item.key;
        });

        return <Typography>{value[0].value}</Typography>;
      },
    },
    {
      id: "value",
      label: "Value",
      type: "custom",
      sort: true,
      callback: (row: ICoupon) => {
        return (
          <Chip
            label={`${row.value}% OFF`}
            color="success"
            m={1}
            size="small"
          />
        );
      },
    },
    {
      id: "from",
      label: "Valid From",
      type: "date",
      format: "MM/DD/YYYY h:mma",
      sort: true,
    },
    {
      id: "to",
      label: "Expires on",
      type: "date",
      format: "MM/DD/YYYY h:mma",
      sort: true,
    },
    {
      id: "status",
      label: "Status",
      type: "string",
      sort: true,
    },
    {
      id: "na",
      label: "Actions",
      type: "custom",
      sort: false,
      callback: (row: ICoupon) => {
        return (
          <div>
            <Stack direction="row">
              <ToolTip title={fullAccess ? "Edit" : "View"}>
                <IconButton
                  aria-label="delete"
                  size="small"
                  onClick={() => onEditCoupon(row)}
                >
                  {fullAccess ? <EditIcon /> : <ForwardIcon />}
                </IconButton>
              </ToolTip>
              {fullAccess && (
                <GridActionButton
                  type="enable"
                  tooltip={row.isExpired === true ? "" : "Enable"}
                  disabled={row.isExpired === true ? true : false}
                  onClick={() => {
                    handleEnableCoupon(row.id);
                  }}
                />
              )}

              {fullAccess && (
                <ToolTip title="Delete">
                  <IconButton
                    aria-label="details"
                    size="small"
                    onClick={() => handleDeleteDocument(row)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </ToolTip>
              )}
            </Stack>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    const getInvalidCoupons = async () => {
      try {
        setShowSkeleton(true);
        const response = await CouponService.getInvalidCoupons();
        setValidCoupons(response.data);
        setShowSkeleton(false);
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      }
    };

    getInvalidCoupons();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshGrid]);

  useEffect(() => {
    setValues({
      code: "",
    });
  }, [showDeleteModal]);

  const handleDeleteDocument = (row: ICoupon) => {
    setSelectedRow(row);
    setShowDeleteModal(true);
  };

  const handleDeleteCoupon = async () => {
    try {
      await CouponService.remove(selectedRow!.id);
      onRefreshGrid();
      setValues({ code: "" });
    } catch (error: any) {
      log.error(error?.message?.exceptionMessage ?? "Something went wrong");
    }
  };

  const handleEnableCoupon = async (idCoupon: number) => {
    try {
      await CouponService.statusCoupon(idCoupon, "enable");
      onRefreshGrid();
    } catch (error: any) {
      log.error(error?.message?.exceptionMessage ?? "Something went wrong");
    }
  };

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setValues({
      ...values,
      [name]: value,
    });
    value === "delete" ? setDisabledButton(true) : setDisabledButton(false);
  };

  return (
    <>
      <LocalEnhancedTable<ICoupon>
        refreshGrid={false}
        columns={columns}
        data={validCoupons}
        showSkeleton={showSkeleton}
        query={props.query}
      />
      <DialogMessagePopup
        title={"Information"}
        text={"Are you certain you want to delete this item?"}
        showPopup={showDeleteModal}
        setShowPopup={setShowDeleteModal}
        onSave={handleDeleteCoupon}
        isSubmitting={false}
        disabled={!disabledButton}
      >
        <Stack>
          <Typography mb={2} mt={5}>
            Type "delete" to confirm
          </Typography>
          <FormText
            label=""
            name="code"
            value={values.code}
            onChange={handleOnChange}
          />
        </Stack>
      </DialogMessagePopup>
    </>
  );
};

export default ValidCoupons;
