import { ICountry } from "../ts/interfaces/country";
import axios from "../utils/axios";

const getById = (countryId: number) => {
  return axios.get<ICountry>(`/Country/${countryId}`);
};

const getCountries = () => {
  return axios.get<ICountry[]>("/Country");
};

const getCountryList = async () => {
  return axios.get("Country");
};

const CountryService = {
  getById,
  getCountries,
  getCountryList,
};

export default CountryService;
