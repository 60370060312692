import {
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useState } from "react";
import DialogMessagePopup from "src/components/DialogMessagePopup";
import {
  FormButton,
  FormNumeric2,
  FormSelect,
  FormText,
} from "src/components/formControls";
import SpreadSheetActionButton from "src/components/spreadsheet/SpreadSheetActionButton";
import sourceSoundAverageService from "src/services/study/sourceSoundAverageService";
import { IKeyValue } from "src/ts/interfaces";
import {
  IEnergyCombinedAverage,
  ISourceSoundAverage,
  ISourceSoundTest,
} from "src/ts/interfaces/catalogs";
import { Mode } from "src/ts/types";

interface Props {
  sourceSoundAverageItems: ISourceSoundAverage[];
  setSourceSoundAverageItems: (val: ISourceSoundAverage[]) => void;
  saveSourceSoundAverage: (item: ISourceSoundAverage) => Promise<void>;
  energyCombinedAverageSelected: IEnergyCombinedAverage | null;
  mode: Mode;
  disableStudy: boolean;
  sourceSoundTests: IKeyValue<number, string>[];
  sourceSoundTestsAll: ISourceSoundTest[];
}

const SourceSoundAverageGrid = ({
  sourceSoundAverageItems,
  setSourceSoundAverageItems,
  saveSourceSoundAverage,
  energyCombinedAverageSelected,
  mode,
  disableStudy,
  sourceSoundTests,
  sourceSoundTestsAll,
}: Props) => {
  const [sourceSoundAverageSelected, setSourceSoundAverageSelected] = useState<{
    item: ISourceSoundAverage;
    index: number;
  } | null>(null);
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] =
    useState(false);

  const onBlurCombinedDbHandler = async (
    row: ISourceSoundAverage,
    index: number,
    e: any
  ) => {
    if (parseFloat(e.target.value) === row.averageDb) return;

    const modifiedItem = { ...row, averageDb: parseFloat(e.target.value) };
    updateItems(modifiedItem, index);
    await saveSourceSoundAverage(modifiedItem);
  };

  const onBlurDescription = (
    row: ISourceSoundAverage,
    index: number,
    e: any
  ) => {
    const modifiedItem = { ...row, description: e.target.value };
    updateItems(modifiedItem, index);
  };

  const onClickSelectButton = (
    row: ISourceSoundAverage,
    index: number,
    e: any
  ) => {
    const modifiedItem = {
      ...row,
      combinedDb: 0,
      description: null,
      sourceSoundTestsId: -1,
    };
    updateItems(modifiedItem, index);
  };

  const onSelectChange = async (
    row: ISourceSoundAverage,
    index: number,
    e: any
  ) => {
    const sourceSoundTestsId = parseInt(e.target.value);
    const energySourceSubtracted = sourceSoundTestsAll.find(
      (x) => x.id === sourceSoundTestsId
    );

    const description = !energySourceSubtracted
      ? null
      : energySourceSubtracted.description;

    const averageDb = !energySourceSubtracted
      ? null
      : energySourceSubtracted.db;

    const modifiedItem: ISourceSoundAverage = {
      ...row,
      sourceSoundTestsId: sourceSoundTestsId,
      description: description,
      averageDb: averageDb ?? 0,
    };
    updateItems(modifiedItem, index);

    await saveSourceSoundAverage(modifiedItem);
  };

  const updateItems = (row: ISourceSoundAverage, index: number) => {
    const newItems = sourceSoundAverageItems.map((item, i) =>
      index === i ? row : item
    );
    setSourceSoundAverageItems(newItems);
  };

  const deleteSoundAverageTestItemConfirmation = (
    row: ISourceSoundAverage,
    index: number
  ) => {
    setSourceSoundAverageSelected({ item: row, index });
    setShowDeleteConfirmationModal(true);
  };

  const deleteSoundSource = async () => {
    if (
      sourceSoundAverageSelected?.item == null ||
      sourceSoundAverageSelected?.index < 0
    )
      return;

    await sourceSoundAverageService.remove(sourceSoundAverageSelected?.item.id);
    const newItems = [...sourceSoundAverageItems];
    newItems.splice(sourceSoundAverageSelected?.index, 1);
    setSourceSoundAverageItems(newItems);
    setShowDeleteConfirmationModal(false);
  };

  const addSourceSoundTest = async () => {
    const tempSourceSoundTest: any = {
      energySourceSubtractedId: energyCombinedAverageSelected?.id,
    };
    const newItemRes = await sourceSoundAverageService.add(tempSourceSoundTest);
    const newItems = [...sourceSoundAverageItems];
    newItems.push({ ...newItemRes.data, sourceSoundTestsId: -1 });
    setSourceSoundAverageItems(newItems);
    //TODO: energyCombinedSources ???
  };
  return (
    <>
      <TableContainer>
        <Table style={{ marginBottom: "0px" }}>
          <TableHead>
            <TableRow>
              <TableCell>Energy Average</TableCell>
              {sourceSoundAverageItems.map((item, index) => (
                <TableCell style={{ padding: 0 }}>
                  Source #{index + 1}
                  {mode === "read&Write" && !disableStudy && (
                    <SpreadSheetActionButton
                      type="delete"
                      tooltip="Delete this item"
                      onMouseUp={() =>
                        deleteSoundAverageTestItemConfirmation(item, index)
                      }
                    />
                  )}
                </TableCell>
              ))}

              <TableCell>
                {mode === "read&Write" && !disableStudy && (
                  <SpreadSheetActionButton
                    type="add"
                    tooltip="Add new item"
                    onMouseUp={(e: any) => addSourceSoundTest()}
                  />
                )}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>Description</TableCell>

              {sourceSoundAverageItems.map(
                (item: ISourceSoundAverage, index) => (
                  <TableCell style={{ padding: 2 }} key={item.id}>
                    {item.sourceSoundTestsId !== 0 && (
                      <FormSelect
                        name={"sourceSoundTestsId"}
                        label={""}
                        //value={item.energySourceSubtractedId}
                        value={item.sourceSoundTestsId}
                        options={sourceSoundTests}
                        onChange={(e) => onSelectChange(item, index, e)}
                        defaultValue={{ key: -1, value: "Select" }}
                        mode={mode}
                      />
                    )}

                    {item.sourceSoundTestsId === 0 && (
                      <Stack direction="row">
                        <FormText
                          name={`description_${item.id}`}
                          value={item.description ?? ""}
                          label={""}
                          onChange={(e: any) =>
                            onBlurDescription(item, index, e)
                          }
                          mode={mode}
                          disabled={disableStudy}
                        />
                        {!disableStudy && (
                          <FormButton
                            variant="outlined"
                            size="small"
                            text={"Select"}
                            onClick={(e) => onClickSelectButton(item, index, e)}
                          />
                        )}
                      </Stack>
                    )}
                  </TableCell>
                )
              )}

              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Average DB</TableCell>

              {sourceSoundAverageItems.map((item, index) => (
                <TableCell style={{ padding: 2 }} key={item.id}>
                  <FormNumeric2
                    name={`combinedDb_${index}`}
                    value={item.averageDb}
                    onChange={() => {}}
                    decimalScale={3}
                    maxValue={1000000}
                    onBlur={(e: any) => onBlurCombinedDbHandler(item, index, e)}
                    label={""}
                    mode={mode}
                    disabled={disableStudy}
                  />
                </TableCell>
              ))}
              <TableCell></TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <DialogMessagePopup
        title={"Delete Confirmation"}
        text="Are you certain you want to delete the selected Source Item?"
        showPopup={showDeleteConfirmationModal}
        setShowPopup={setShowDeleteConfirmationModal}
        onSave={() => deleteSoundSource()}
        onCancel={() => setShowDeleteConfirmationModal(false)}
        isSubmitting={false}
        disableClickOutside={true}
      />
    </>
  );
};

export default SourceSoundAverageGrid;
