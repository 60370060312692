import axios from "src/utils/axios";
import { IAssetsHistory } from "src/ts/interfaces/companyAssets";

const getAssignmentsHistory = (assetId: number) => {
  return axios.get<IAssetsHistory[]>(
    `AssetHistory/GetHistory?assetId=${assetId}`
  );
};

const assetHistoryService = {
  getAssignmentsHistory,
};

export default assetHistoryService;
