import draftToHtml from "draftjs-to-html";
import { ContentState, convertToRaw } from "draft-js";

function isJson(str: string) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

function rawContent(question: string) {
  if (isJson(question)) {
    const object = JSON.parse(question);
    return object;
  } else {
    return convertToRaw(ContentState.createFromText(question));
  }
}

const convertToHTML = (question: string) => {
  const result = rawContent(question);
  return draftToHtml(result);
};

const draftToHtmlService = {
  convertToHTML,
};

export default draftToHtmlService;
