import { ICompanyAssetsType } from "src/ts/interfaces/catalogs/companyAssetsType";
import axios from "src/utils/axios";

const getAll = () => {
  return axios.get<ICompanyAssetsType[]>("CompanyAssetType/GetAll");
};

const update = (companyAssets: ICompanyAssetsType) => {
  return axios.put(`CompanyAssetType/${companyAssets.id}`, companyAssets);
};

const informationAsset = (asset: string) => {
  return axios.get(`CompanyAssetType?code=${asset}`);
};

const companyAssetsTypeService = { getAll, update, informationAsset };
export default companyAssetsTypeService;
