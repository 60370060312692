import {
  IStorageMetrics,
  IProjectStorageSummary,
  ICompanyDocumentSummary,
  IUserDocumentSummary,
} from "src/ts/interfaces/storage";
import axios from "../utils/axios";

const storageMetrics = async (id: number) => {
  return axios.get<IStorageMetrics>(`Storage/StorageMetrics/${id}`);
};

const summaryByCompanies = async () => {
  return axios.get<IStorageMetrics[]>(`Storage/summaryByCompanies/`);
};

const getCompletedProjects = async () => {
  return axios.get<IProjectStorageSummary[]>(`Storage/summary/`);
};

const getCompanyStorageSummary = async () => {
  return axios.get<ICompanyDocumentSummary[]>(`Storage/companySummary/`);
};

const getUserStorageSummary = async () => {
  return axios.get<IUserDocumentSummary[]>(`Storage/userSummary/`);
};

const storageService = {
  storageMetrics,
  summaryByCompanies,
  getCompletedProjects,
  getCompanyStorageSummary,
  getUserStorageSummary,
};

export default storageService;
