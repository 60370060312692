import { Grid, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { FormButton, FormSelect } from "src/components/formControls";
import { useForm, Form, useLog } from "src/hooks";
import { IKeyValue } from "src/ts/interfaces";
import useAuth from "src/hooks/useAuth";
import accountService from "src/services/accountService";

const initialValues = {
  id: 0,
  role: "",
};

const RoleSelection = () => {
  const { signInWithRole, signOut, userName, password } = useAuth();
  const { log } = useLog();

  const validate = (fieldValues = values) => {
    let temp: Record<string, string> = { ...errors };
    if (fieldValues.hasOwnProperty("name")) {
      temp.name = fieldValues.name ? "" : "This field is required.";
      temp.name =
        fieldValues.name.length < 9 ? "put more than 9 charac..." : "";
    }

    if (fieldValues.hasOwnProperty("statusId"))
      temp.statusId =
        fieldValues.statusId !== 0 ? "" : "This field is required.";

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, errors, setErrors, handleInputChange, setValues } = useForm(
    initialValues,
    true,
    validate
  );

  const [roles, setRoles] = useState<IKeyValue<number, string>[]>([]);

  useEffect(() => {
    const getData = async () => {
      if (userName === "" || userName === null) return;

      try {
        const rolesKeyValue = await accountService.getRoleKeyValues(userName);
        setRoles(rolesKeyValue);
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      }
    };

    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userName]);
  useEffect(() => {
    if (roles.length > 0)
      if (values.role === "") {
        let newValues = { ...values };
        newValues.role = roles[0].key;
        setValues({ ...newValues });
      }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roles]);

  const handleLoginWithRole = () => {
    signInWithRole({
      userName: userName!,
      password: password!,
      role: roles[values.role].value,
    });
  };

  const handleCancelSignIn = async () => {
    await signOut();
  };

  return (
    <div>
      <Form>
        <Grid container spacing={5}>
          <Grid item xs={12}>
            <Typography component="h2" variant="body1" align="center">
              Select Role
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <FormSelect
              name="role"
              label="Role"
              size="medium"
              value={values.role}
              onChange={handleInputChange}
              options={roles}
              error={errors.role}
              fullWidth={true}
            />
          </Grid>
          <Grid item xs={12}>
            <Stack
              direction="row"
              spacing={2}
              justifyContent="space-around"
              alignItems="flex-start"
            >
              <FormButton
                text="Accept"
                fullWidth={true}
                onClick={handleLoginWithRole}
              />
              <FormButton
                text="Cancel"
                color="primary"
                fullWidth={true}
                onClick={handleCancelSignIn}
                variant="outlined"
              />
            </Stack>
          </Grid>
        </Grid>
      </Form>
    </div>
  );
};

export default RoleSelection;
