import React, { useState } from "react";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import {
  Grid,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
  Typography,
  Card as MuiCard,
  CardContent,
  Stack,
  CircularProgress,
} from "@mui/material";
import { spacing } from "@mui/system";
import { ColumnType } from "src/types/enhancedTable";
import { glossaryTable } from "src/ts/interfaces/glossary";
import { useForm, useLog } from "src/hooks";
import { Glosary } from "src/constants";
import SysAdminDocumentService from "src/services/sysAdminDocumentService";
import FileUtils from "src/utils/file";
import { FormButton, FormText } from "src/components/formControls";
import LocalEnhancedTable from "src/components/localTable/LocalTable";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const initialValues: glossaryTable = {
  name: "",
  content: "",
};

const NavBarGlossary = () => {
  const [data] = useState<glossaryTable[]>(Glosary);
  const [isLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const columns: ColumnType[] = [
    {
      id: "name",
      label: "",
      type: "custom",
      sort: true,
      callback: (row: glossaryTable) => {
        return (
          <>
            <Typography sx={{ fontWeight: "bold" }}>{row.name}</Typography>
          </>
        );
      },
    },
    {
      id: "content",
      label: "",
      type: "string",
      sort: true,
    },
  ];

  const { values: filterValues, handleInputChange: handleFilterChange } =
    useForm(initialValues, true, []);

  const { log } = useLog();

  const downloadHandle = async () => {
    try {
      setIsSubmitting(true);
      const response = await SysAdminDocumentService.ashraeFile();
      let fileName = "TermsAndDefinitions.pdf";
      FileUtils.downloadFile(response.data, fileName);
    } catch (error: any) {
      log.error(error?.message?.exceptionMessage ?? "Something went wrong");
    } finally {
      setIsSubmitting(false);
      log.success("The file has been downloaded successfully");
    }
  };

  return (
    <>
      <Helmet title="Glossary" />
      <Grid container spacing={10}>
        <Grid item xs={6}>
          <Typography variant="h3" gutterBottom display="inline">
            Glossary
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} to="/">
              Dashboard
            </Link>
          </Breadcrumbs>
        </Grid>
        <Grid item xs={6}>
          <Stack direction="row" justifyContent="end">
            <FormButton
              text="ASHRAE Terms and Definitions"
              onClick={downloadHandle}
              size="medium"
              startIcon={isSubmitting ? <CircularProgress size="1rem" /> : null}
              disabled={isSubmitting}
              color={"primary"}
            />
          </Stack>
        </Grid>
      </Grid>

      <Divider my={6} />
      <Card mb={6}>
        <CardContent>
          <Grid container>
            <Grid item xs={12}>
              <FormText
                name="name"
                label="Search"
                value={filterValues.name}
                onChange={handleFilterChange}
              />
            </Grid>

            <Grid item xs={12}>
              <LocalEnhancedTable<glossaryTable>
                refreshGrid={true}
                columns={columns}
                data={data ?? []}
                showSkeleton={isLoading}
                defaultRowPerPage={10}
                query={filterValues.name}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
};

export default NavBarGlossary;
