import {
  Chip,
  ListItemAvatar,
  ListItemText,
  Typography,
  List,
  ListItem,
  Grid,
} from "@mui/material";
import { ProjectWithBogus } from "src/ts/interfaces/project/projectDto";
import { useAsyncQuery } from "src/hooks";
import { IProjectHistoryStatusComments } from "src/ts/interfaces";
import projectHistoryStatusCommentsService from "src/services/project/projectHistoryStatusCommentsService";
import { useEffect } from "react";
import { convertUTCDateToLocalDate } from "src/utils/utils";

interface Props {
  projectId: number;
  project: ProjectWithBogus;
}

const ProjectHistoryStatusComments = ({ projectId, project }: Props) => {
  const { execute: getCommentStatus, data: commentStatus } = useAsyncQuery<
    IProjectHistoryStatusComments[]
  >(projectHistoryStatusCommentsService.getProjectHistoryStatusComments);

  useEffect(() => {
    const commentStatusData = async () => {
      await getCommentStatus(projectId);
    };

    if (projectId > 0) {
      commentStatusData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId]);

  type StatusCommentConfig = {
    color:
      | "default"
      | "error"
      | "primary"
      | "secondary"
      | "info"
      | "success"
      | "warning";
    text: string;
  };
  const statusColor: Record<string, StatusCommentConfig> = {
    Rejected: { color: "error", text: "Rejected Comments" },
    Canceled: { color: "warning", text: "Closed Comments" },
    Incomplete: { color: "warning", text: "Archived Comments" },
    Approved: { color: "success", text: "Approved Comments" },
    New: { color: "info", text: "Reopen Comments" },
    Reopen: { color: "info", text: "Reopen Comments" },
  };

  return (
    <>
      <Grid container spacing={3}>
        {/* <Grid item xs={12}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
          >
            <Typography gutterBottom variant="h5" component="div">
              Project Status
            </Typography>
            {projectId === 0 && <Chip label="New" color="primary" />}
            {projectId > 0 && (
              <Chip
                label={project.projectStatus?.name}
                color="default"
                size="small"
              />
            )}
          </Stack>
        </Grid> */}
        {commentStatus && commentStatus.length > 0 && (
          <Grid item xs={12}>
            <Typography gutterBottom variant="h5" component="div">
              Building Owner Evaluation
            </Typography>
            {commentStatus?.map((commentStatus) => (
              <List key={commentStatus.id}>
                <ListItem disablePadding>
                  <ListItemAvatar sx={{ mr: "7px" }}>
                    <Chip
                      label={statusColor[commentStatus.projectStatus.name].text}
                      color={
                        statusColor[commentStatus.projectStatus.name].color
                      }
                      size="small"
                    />
                  </ListItemAvatar>
                  <ListItemText
                    primary={convertUTCDateToLocalDate(
                      commentStatus.createdDate
                    )?.format("MM/DD/yyyy h:mm A")}
                    secondary={commentStatus.comments}
                  ></ListItemText>
                </ListItem>
              </List>
            ))}
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default ProjectHistoryStatusComments;
