import { Box, Chip, Grid, IconButton, Stack, Typography } from "@mui/material";
import { ColumnType } from "src/types/enhancedTable";
import LocalEnhancedTable from "src/components/localTable/LocalTable";
import { GridActionButton } from "src/components/gridControls";
import { IProjectReporDisplayt } from "src/ts/interfaces/study/study";
import projectReportService from "src/services/study/projectReportService";
import { useAuth, useLog } from "src/hooks";
import { AuthUser } from "src/types/auth";
import { ProjectDTO } from "src/ts/interfaces/project/projectDto";
import { colorsStudies } from "src/constants";
import StudyStatusComponent from "./StudyStatusComponent";
import { pdfExportService } from "src/services";
import { useMemo, useState } from "react";
import FileUtils from "src/utils/file";
import { convertUTCDateToLocalDate } from "src/utils/utils";
import { Download, Forward } from "@mui/icons-material";

const getColumns = (
  moveUp: (index: number) => Promise<void>,
  moveDown: (index: number) => Promise<void>,
  user: AuthUser,
  project: ProjectDTO,
  downloadStudy: (study: IProjectReporDisplayt) => Promise<void>,
  gotoToStudyOrAddSubStudy: (study: IProjectReporDisplayt) => void,
  isEnergy: boolean
): ColumnType[] => {
  const hideSelectColumn = !(
    user?.role !== "Architect" &&
    user?.role !== "ArchitectAux" &&
    user?.role !== "SysAdmin"
  );
  const hideLastModifiedDate = !(project?.projectStatus?.name !== "Complete");

  const hideCompletedBy = !(
    project?.projectStatus?.name === "Complete" ||
    project?.projectStatus?.name === "In Review" ||
    project?.projectStatus?.name === "Approved" ||
    project?.projectStatus?.name === "Rejected"
  );

  const hideDownloadButton =
    project?.projectStatus?.name === "Contest" ||
    !(
      project?.projectStatus?.name === "In Review" ||
      project?.projectStatus?.name === "Complete" ||
      project?.projectStatus?.name === "Approved" ||
      project?.projectStatus?.name === "Rejected"
    );

  const energyRequiredColumn = {
    id: "required",
    label: "Required",
    type: "custom",
    sort: true,
    callback: (row: IProjectReporDisplayt) => (
      <>
        {row.required ? (
          <Chip label="Required" size="small" color="error" />
        ) : (
          <Chip label="Not Required" size="small" color="default" />
        )}
      </>
    ),
  } as ColumnType;

  const result = [
    {
      id: "orderAction",
      label: "Order",
      type: "custom",
      sort: false,
      hide: hideSelectColumn,
      callback: (row: IProjectReporDisplayt, col: any, index: number) => {
        return (
          <Stack direction="row">
            <GridActionButton
              type="up"
              tooltip=""
              onClick={async () => await moveUp(index)}
            />
            <GridActionButton
              type="down"
              tooltip=""
              onClick={async () => await moveDown(index)}
            />
          </Stack>
        );
      },
    },
    {
      id: "reportTypeName",
      label: "Report Name",
      type: "custom",
      sort: true,
      callback: (row: IProjectReporDisplayt) => (
        <>
          <Typography
            component="div"
            color={colorsStudies[row.reportTypeColor] ?? ""}
          >
            <Box
              sx={{ fontWeight: "bold", m: 1 }}
            >{`${row.reportTypeName} (${row.reportLabel})`}</Box>
          </Typography>

          <StudyStatusComponent row={row} />
        </>
      ),
    },
    { id: "system", label: "System", type: "string", sort: true },
    {
      id: "dateModified",
      label: "Last Modified Date",
      type: "custom",
      sort: true,
      callback: (row: IProjectReporDisplayt) => {
        return (
          <>
            {row.isInUse ? (
              "Study is under use"
            ) : (
              <>
                {row.studyStatus === "Complete"
                  ? convertUTCDateToLocalDate(row.completedDate ?? "")?.format(
                      "MM/DD/yyyy h:mm A"
                    )
                  : (row.dateModified &&
                      convertUTCDateToLocalDate(row.dateModified)?.format(
                        "MM/DD/yyyy h:mm A"
                      )) ??
                    ""}
              </>
            )}
          </>
        );
      },
    },
    {
      id: "updatedBy",
      label: "Completed By",
      type: "string",
      sort: true,
      hide: hideCompletedBy,
    },
    {
      id: "completedDate",
      label: "Completed Date",
      type: "utcDate",
      sort: true,
      format: "MM/DD/yyyy h:mm A",
      hide: hideCompletedBy,
    },
    {
      id: "studyStatus",
      label: "Status",
      type: "custom",
      sort: true,
      callback: (row: IProjectReporDisplayt) => row.studyStatus,
      hide: hideCompletedBy,
    },
    {
      id: "isInUse",
      label: "isInUse",
      type: "custom",
      sort: false,
      callback: (row: IProjectReporDisplayt) => row.isInUse,
      hide: true,
    },
    {
      id: "countSubreport",
      label: "# of Sub-Studies",
      type: "string",
      sort: false,
    },
    {
      id: "action",
      label: "Actions",
      type: "custom",
      sort: false,
      callback: (row: IProjectReporDisplayt) => (
        <Stack direction="row">
          <IconButton
            onClick={() => {
              gotoToStudyOrAddSubStudy(row);
            }}
          >
            <Forward />
          </IconButton>
          {!hideDownloadButton &&
            row.studyStatusId !== 2 &&
            row.studyStatusId !== 1 &&
            row.reportTypeRouteComplete !== "" &&
            row.reportTypeRoute !== "" && (
              <IconButton onClick={() => downloadStudy(row)}>
                <Download />
              </IconButton>
            )}
        </Stack>
      ),
    },
  ] as ColumnType[];

  if (isEnergy) result.splice(1, 0, energyRequiredColumn);

  return result;
};

interface Props {
  data: IProjectReporDisplayt[];
  search: string;
  toggleAll: boolean | null;
  onSelectHandler: (selected: number[]) => void;
  onGridChange: (studies: IProjectReporDisplayt[]) => void;
  project: ProjectDTO;
  setProgressBarModal: (value: boolean) => void;
  setShowProjectCompletionFee: (value: boolean) => void;
  onSelectStudy?: (study: IProjectReporDisplayt) => void;
  isEnergy?: boolean;
}

const StudiesGrid = ({
  data,
  search,
  toggleAll,
  onSelectHandler,
  onGridChange,
  project,
  setProgressBarModal,
  setShowProjectCompletionFee,
  onSelectStudy,
  isEnergy,
}: Props) => {
  const { user } = useAuth();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [progress, setProgress] = useState(0);
  const { log } = useLog();
  const swapElements = (arr: any, x: number, y: number) => {
    [arr[x], arr[y]] = [arr[y], arr[x]];

    return arr;
  };

  const gotoToStudyOrAddSubStudy = (study: IProjectReporDisplayt) => {
    onSelectStudy && onSelectStudy(study);
  };

  const moveUp = async (index: number) => {
    if (index === 0) return;

    const swapedStues: IProjectReporDisplayt[] = swapElements(
      dataResult,
      index,
      index - 1
    );

    const temp = swapedStues[index].subStudyOrder;
    swapedStues[index].subStudyOrder = swapedStues[index - 1].subStudyOrder;
    swapedStues[index - 1].subStudyOrder = temp;
    onGridChange(swapedStues);
    await projectReportService.updateOrder(swapedStues);
  };

  const moveDown = async (index: number) => {
    if (index === dataResult.length - 1) return;

    const swapedStues: IProjectReporDisplayt[] = swapElements(
      dataResult,
      index,
      index + 1
    );

    const temp = swapedStues[index].subStudyOrder;
    swapedStues[index].subStudyOrder = swapedStues[index + 1].subStudyOrder;
    swapedStues[index + 1].subStudyOrder = temp;
    onGridChange(swapedStues);
    await projectReportService.updateOrder(swapedStues);
  };

  const onProgressDownload = (progress: number) => {
    setProgress(progress);
  };

  const downloadStudy = async (study: IProjectReporDisplayt) => {
    if (
      (project.isPaid == null || project.isPaid <= 0) &&
      project.isInfectiousControlProject === false
    ) {
      setShowProjectCompletionFee(true);
      return;
    }

    try {
      setProgressBarModal(true);
      setProgress(0);

      const response = await pdfExportService.downloadStudy(
        study.id,
        onProgressDownload
      );

      FileUtils.downloadFile(response.data.content, response.data.filename);
    } catch (error: any) {
      log.error(error?.message?.exceptionMessage ?? "Something went wrong");
    } finally {
      setProgressBarModal(false);
    }
  };

  const columns = getColumns(
    moveUp,
    moveDown,
    user,
    project,
    downloadStudy,
    gotoToStudyOrAddSubStudy,
    isEnergy || false
  );

  const dataResult = useMemo(() => {
    let resut = data?.filter(
      (study) =>
        study.system.toLowerCase().includes(search.toLowerCase()) ||
        study.projectCode.toLowerCase().includes(search.toLowerCase()) ||
        study.reportTypeName.toLowerCase().includes(search.toLowerCase())
    );
    return resut;
  }, [data, search]);

  return (
    <>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <LocalEnhancedTable<IProjectReporDisplayt>
            columns={columns}
            data={dataResult}
            showSkeleton={false}
            cellCheckBox={true}
            onSelect={onSelectHandler}
            toggleAll={toggleAll}
            defaultSortColumn="subStudyOrder"
            orderColumn="asc"
          />
        </Grid>
      </Grid>
    </>
  );
};

export default StudiesGrid;
