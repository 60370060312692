import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  DialogActions,
  IconButton,
  DialogProps,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { CSSProperties } from "react";
import { PaperProps as MuiPaperProps } from "@mui/material/Paper";

interface IPopupProps {
  title: string;
  children: JSX.Element[];
  openPopup: boolean;
  size?: "xs" | "sm" | "md" | "lg" | "xl";
  setOpenPopup: (val: boolean) => void;
  onClose: (() => void) | undefined;
  hideClose?: boolean;
  disableEscKeyDown?: boolean;
  titleStyle?: CSSProperties;
  disableClickOutside?: boolean | false;
  isSubmitting?: boolean | false;
  paperProps?: MuiPaperProps;
}

export default function Popup(props: IPopupProps) {
  const { title, children, openPopup, size, onClose, paperProps } = props;
  const [Form, Stack] = children;

  const handleClose: DialogProps["onClose"] = (event, reason) => {
    if (props.disableClickOutside) {
      if (reason && reason === "backdropClick") return;
    }
    props.setOpenPopup(false);
  };

  return (
    <Dialog
      open={openPopup}
      maxWidth={size ?? "sm"}
      fullWidth={true}
      disableEscapeKeyDown={props.disableEscKeyDown}
      onClose={handleClose}
      PaperProps={paperProps}
    >
      <DialogTitle>
        <div style={{ display: "flex" }}>
          <Typography
            variant="h6"
            component="div"
            style={{ ...props.titleStyle, flexGrow: 1 }}
          >
            {title}
          </Typography>
        </div>
        {!props.hideClose && (
          <IconButton
            aria-label="close"
            onClick={onClose as () => void}
            disabled={props.isSubmitting}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        )}
      </DialogTitle>
      <DialogContent dividers>{Form}</DialogContent>
      <DialogActions disableSpacing>{Stack}</DialogActions>
    </Dialog>
  );
}
