import axios from "../utils/axios";
import { ITermsAndConditionsOptions } from "src/ts/interfaces/catalogs/termsAndConditionsOptions";

const getAll = async (tabId: number) => {
  return axios.get<ITermsAndConditionsOptions[]>(
    `TermsAndConditionsOptions/getAll?id=${tabId}`
  );
};

const getCurrent = async () => {
  return axios.get<ITermsAndConditionsOptions>(
    `TermsAndConditionsOptions/Current`
  );
};

const add = async (item: ITermsAndConditionsOptions) => {
  return axios.post(`TermsAndConditionsOptions`, item);
};

const termsAndConditionsOptionsService = {
  getAll,
  getCurrent,
  add,
};

export default termsAndConditionsOptionsService;
