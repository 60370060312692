import { Typography, Box, Button, Stack } from "@mui/material";
import Popup from "src/components/Popup";
import FormCancelButton from "src/components/formControls/FormCancelButton";
import FormButton from "src/components/formControls/FormButton";
import FormCheckBox from "src/components/formControls/FormCheckBox";
import { useState } from "react";
import useAuth from "src/hooks/useAuth";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import ModalNewTicketForm from "../Modals/ModalNewTicketForm";
import { ToolTip } from "src/components/others/ToolTip";
import AlertSnackBar from "./AlertSnackBar";
export interface EmergencyButtonProps {
  emailValue: string;
  modalType?: boolean;
}

export default function EmergencyButton({
  emailValue,
  modalType,
}: EmergencyButtonProps) {
  const [openPopupMain, setOpenPopupMain] = useState(false);
  const [disabledCreateButton, setDisabledCreateButton] = useState(true);
  const [disableCheckeBox, setDisableCheckeBox] = useState(false);
  const [showEmergencyPopupForm, setShowEmergencyPopupForm] = useState(false);
  const [isSubmitting] = useState(false);
  const [isOpenSnackBar, setIsOpenSnackBar] = useState(false);

  const { isAuthenticated } = useAuth();
  const handleOpen = () => setOpenPopupMain(true);

  const handleClose = () => {
    setOpenPopupMain(false);
    setDisabledCreateButton(true);
    setDisableCheckeBox(false);
  };

  const handleChecked = () => {
    setDisabledCreateButton(!disabledCreateButton);
    setDisableCheckeBox(!disableCheckeBox);
  };

  const handleCreateTicket = () => {
    setShowEmergencyPopupForm(true);
    setDisableCheckeBox(true);
  };

  const FormControls = () => (
    <>
      <Box>
        {Information}
        <Box
          component="img"
          src="/static/img/hard-refresh-browser.jpg"
          sx={{
            width: "550px",
          }}
        />

        <FormCheckBox
          label="I have performed the instructions above and the issue is still present, I want to go forward creating my Emergency Ticket."
          name="accepted"
          value={disableCheckeBox}
          onClick={handleChecked}
        />
      </Box>

      {showEmergencyPopupForm ? (
        <ModalNewTicketForm
          isAuthenticated={isAuthenticated}
          showEmergencyPopupForm={showEmergencyPopupForm}
          setShowEmergencyPopupForm={setShowEmergencyPopupForm}
          setOpenPopupMain={setOpenPopupMain}
          setIsOpenSnackBar={setIsOpenSnackBar}
          setDisabledCreateButton={setDisabledCreateButton}
          setDisableCheckeBox={setDisableCheckeBox}
          emailValue={emailValue}
        />
      ) : null}
    </>
  );

  const Actions = () => (
    <>
      <Stack direction="row" spacing={2}>
        {" "}
        <FormButton
          size="medium"
          onClick={handleCreateTicket}
          color="primary"
          text="Continue to create My Emergency Ticket"
          disabled={disabledCreateButton}
        />
        <FormCancelButton onClick={handleClose} isSubmitting={isSubmitting} />
      </Stack>
    </>
  );

  const Information = (
    <>
      <Typography sx={{ mt: 2, textAlign: "justify" }}>
        Typically, issues in the software are related to the user's computer and
        can be resolved at the user's end.
      </Typography>
      <Typography sx={{ mt: 2, textAlign: "justify" }}>
        We would like to offer some guidance before reporting an issue in the
        software.
      </Typography>
      <Typography sx={{ mt: 2, textAlign: "justify" }}>
        Please perform the following instructions to execute a hard reset of
        your internet browser's page.
      </Typography>
      <Typography sx={{ mt: 2, textAlign: "justify" }}>
        It's <b style={{ color: "orangered" }}>IMPORTANT</b> that you perform
        the following instructions while you are into the browser's page where
        AMP software is running.
      </Typography>
    </>
  );

  return (
    <>
      {modalType ? (
        <>
          <ToolTip title="Emergency Button">
            <SupportAgentIcon
              onClick={() => {
                handleOpen();
              }}
            />
          </ToolTip>
        </>
      ) : (
        <>
          <Box
            onClick={() => {
              handleOpen();
            }}
            sx={{
              cursor: "pointer",
              marginBottom: "10px",
            }}
          ></Box>
          <Button
            variant="contained"
            color="error"
            startIcon={<SupportAgentIcon />}
            onClick={() => {
              handleOpen();
            }}
          >
            Emergency Support
          </Button>
        </>
      )}

      {openPopupMain === true ? (
        <Popup
          openPopup={openPopupMain}
          setOpenPopup={setOpenPopupMain}
          title="Emergency Button"
          onClose={handleClose}
        >
          <FormControls />
          <Actions />
        </Popup>
      ) : null}

      {isOpenSnackBar ? (
        <AlertSnackBar
          isOpenSnackBar={isOpenSnackBar}
          setIsOpenSnackBar={setIsOpenSnackBar}
        />
      ) : null}
    </>
  );
}
