import { IKeyValue } from "src/ts/interfaces";
import { ISourceSoundTest } from "src/ts/interfaces/catalogs";
import axios from "src/utils/axios";

const getByEnergySourceSubtractedId = (energySourceSubtractedId: number) => {
  return axios.get<ISourceSoundTest[]>(
    `SourceSoundTest/Report?energySourceSubtractedId=${energySourceSubtractedId}`
  );
};

const getAll = (reportTypeId: number, reportId: number) => {
  return axios.get<ISourceSoundTest[]>(
    `SourceSoundTest/All?reportTypeId=${reportTypeId}&reportId=${reportId}`
  );
};

const getKeyValues = async (reportTypeId: number, reportId: number) => {
  const res = await getAll(reportTypeId, reportId);

  const keyValuePair: IKeyValue<number, string>[] = res.data.map(
    (subscription) => {
      return {
        key: subscription.id,
        value: subscription.description,
      };
    }
  );

  return keyValuePair;
};

const add = (sourceSoundTest: ISourceSoundTest) => {
  return axios.post<ISourceSoundTest>("SourceSoundTest", sourceSoundTest);
};

const updateAll = (sourceSoundTests: ISourceSoundTest[]) => {
  return axios.put<ISourceSoundTest>("SourceSoundTest", sourceSoundTests);
};

const remove = (energySourceSubtractedId: number) => {
  return axios.delete(`SourceSoundTest/${energySourceSubtractedId}`);
};

const sourceSoundTestService = {
  getByEnergySourceSubtractedId,
  add,
  updateAll,
  remove,
  getAll,
  getKeyValues,
};

export default sourceSoundTestService;
