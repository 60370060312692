import React, { useState } from "react";

function useForm<T>(
  initialFValues: T,
  validateOnChange = false,
  validate?: any
) {
  const [values, setValues] = useState<T>(initialFValues);
  const [errors, setErrors] = useState<Record<string, string>>({});

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    //removing comma separator for numeric values
    let newValue = value;
    if (typeof newValue === "string") {
      const numberPattern = /^[-+]?\d{1,3}(?:,\d{3})*(?:\.\d+)?$/;
      newValue = numberPattern.test(newValue)
        ? newValue.replace(/,/g, "")
        : newValue;
    }
    setValues({
      ...values,
      [name]: newValue,
    });
    if (validateOnChange && validate && validate.length > 0)
      validate({ [name]: value });
  };

  const resetForm = () => {
    setValues(initialFValues);
    setErrors({});
  };

  return {
    values,
    setValues,
    errors,
    setErrors,
    handleInputChange,
    resetForm,
  };
}

export default useForm;

export function Form(props: any) {
  const { children, ...other } = props;

  return (
    <form autoComplete="off" {...other}>
      {props.children}
    </form>
  );
}
