import axios from "../utils/axios";
import { IZipCode } from "../ts/interfaces";

const getByZipCode = async (zipcode: string) => {
  return axios.get<IZipCode>(`zipCode?zipcode=` + zipcode);
};

const getElevation = (zipcode: string) => {
  return axios.get<number | null>(`ZipCode/elevation?zipCode=` + zipcode);
};

const zipCodeService = {
  getByZipCode,
  getElevation,
};

export default zipCodeService;
