import React, { useEffect } from "react";
import { IAssetsHistory } from "src/ts/interfaces/companyAssets";
import { ColumnType } from "src/types/enhancedTable";
import LocalEnhancedTable from "src/components/localTable/LocalTable";
import { useAsyncQuery } from "src/hooks";

import assetHistoryService from "src/services/assetHistoryService";
import { Typography } from "@mui/material";

interface Props {
  assetId: number;
  refresh: boolean;
}

const AssignmentsGrid = ({ assetId, refresh }: Props) => {
  const { execute, data, isLoading } = useAsyncQuery<IAssetsHistory[]>(
    assetHistoryService.getAssignmentsHistory
  );

  useEffect(() => {
    const getData = async () => {
      await execute(assetId);
    };

    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getColumns = (): ColumnType[] => {
    return [
      {
        id: "assignedTo",
        label: "Assigned To",
        type: "custom",
        sort: true,
        callback: (row: IAssetsHistory) => {
          return (
            <>
              <Typography>{`${row.assignedTo}`}</Typography>
            </>
          );
        },
      },
      {
        id: "assignedDate",
        label: "Date",
        type: "utcDate",
        sort: true,
        format: "MM/DD/YYYY h:mma",
      },
      {
        id: "assignedBy",
        label: "Assigned By",
        type: "string",
        sort: true,
      },
      {
        id: "comments",
        label: "Comments",
        type: "string",
        sort: true,
      },
    ];
  };

  const columns = getColumns();

  return (
    <>
      <LocalEnhancedTable<IAssetsHistory>
        refreshGrid={true}
        columns={columns}
        data={data ?? []}
        showSkeleton={isLoading}
        defaultRowPerPage={5}
        orderColumn="desc"
        defaultSortColumn="assignedDate"
      />
    </>
  );
};

export default AssignmentsGrid;
