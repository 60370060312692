import React, { useEffect } from "react";
import { Grid, Stack } from "@mui/material";
import { Form } from "src/hooks";
import {
  FormAcceptButton,
  FormCancelButton,
  FormCheckBox,
} from "src/components/formControls";
import FormNumeric from "src/components/formControls/FormNumeric";
import Popup from "src/components/Popup";
import { Validator } from "src/ts/types";
import useFormTyped from "src/hooks/useFormTyped";

interface QuantityForm {
  quantity: number;
  includeActuals: boolean;
}

const initialValues: QuantityForm = {
  quantity: 1,
  includeActuals: true,
};

interface Props {
  isDialogOpen: boolean;
  setIsDialogOpen: (val: boolean) => void;
  onSave: (quantity: number, includeActuals: boolean) => void;
  isSubmitting: boolean;
}

const SelectDuplicateQuantityPopup = ({
  isDialogOpen,
  setIsDialogOpen,
  onSave,
  isSubmitting,
}: Props) => {
  const validate: any = (fieldValues = values) => {
    let temp: Record<string, string> = { ...errors };

    temp.quantity = new Validator(fieldValues, "quantity")
      .isRequired("The quantity is required.")
      .greatThan(0, "The quantity must be great than 0")
      .toString();

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, errors, setErrors, handleInputChange, resetForm } =
    useFormTyped<QuantityForm>(initialValues, false, validate);

  useEffect(() => {
    resetForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDialogOpen]);

  const saveHandler = async () => {
    if (!validate()) return;

    onSave(values.quantity, values.includeActuals);
  };

  const cancelHandler = () => {
    setIsDialogOpen(false);
  };

  const row = 12;
  return (
    <Popup
      openPopup={isDialogOpen}
      setOpenPopup={setIsDialogOpen}
      title="Duplicate study"
      onClose={cancelHandler}
    >
      <Form>
        <Grid container spacing={5}>
          <Grid item xs={row}>
            <FormNumeric
              name="quantity"
              label="Enter Quantity"
              value={values.quantity.toString()}
              onChange={handleInputChange}
              maxLength={2}
              error={errors.quantity}
              placeholder=""
            />
          </Grid>
          <Grid item xs={row}>
            <FormCheckBox
              label="Include/Copy actuals for this Report"
              value={values.includeActuals}
              name="includeActuals"
              onChange={handleInputChange}
            />
          </Grid>
        </Grid>
      </Form>
      <>
        <Stack
          direction="row"
          spacing={2}
          justifyContent="center"
          alignItems="center"
        >
          <FormCancelButton
            onClick={cancelHandler}
            isSubmitting={isSubmitting}
          />
          <FormAcceptButton onClick={saveHandler} isSubmitting={isSubmitting} />
        </Stack>
      </>
    </Popup>
  );
};

export default SelectDuplicateQuantityPopup;
