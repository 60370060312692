import { useAuth } from "src/hooks";

interface WithRoleProps {
  children: JSX.Element;
  value?: string | string[];
  action: "hide" | "show";
}
const WithRole = ({ children, value, action }: WithRoleProps) => {
  const { user } = useAuth();

  if (
    (action === "show" &&
      value &&
      typeof value === "string" &&
      user?.role === value) ||
    (typeof value === "object" &&
      (value as string[]).some((role: string) => user?.role === role))
  ) {
    return <>{children}</>;
  }

  if (
    action === "hide" &&
    value &&
    ((typeof value === "string" && user?.role !== value) ||
      (typeof value === "object" &&
        !(value as string[]).some((role: string) => user?.role === role)))
  ) {
    return <>{children}</>;
  }

  return <></>;
};

export default WithRole;
