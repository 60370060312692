import {
  IRatingContractors,
  IRatingProject,
} from "src/ts/interfaces/projectOutToBid";
import axios from "../utils/axios";

const getByCode = async (code: string) => {
  return axios.get<IRatingProject>(`ContractorRating/Code?code=${code}`);
};

const getContractorRating = async (companyId: number) => {
  return axios.get(`ContractorRating?companyId=${companyId}`);
};
const getContractorRatingProject = async (projectId: number) => {
  return axios.get<IRatingContractors>(
    `ContractorRating?projectId=${projectId}`
  );
};

const postContractorRating = (data: IRatingContractors) => {
  return axios.post(`ContractorRating`, data);
};

const updateByCode = (data: IRatingProject) => {
  return axios.put(`ContractorRating/UpdateByCode`, data);
};

const contractorRatingService = {
  getContractorRating,
  getContractorRatingProject,
  postContractorRating,
  getByCode,
  updateByCode,
};

export default contractorRatingService;
