import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment";
import FormReadOnlyField from "./FormReadOnlyField";
import dayjs from "dayjs";
import { Skeleton } from "@mui/material";

interface FormSelectProps {
  name: string;
  label: string;
  value: string | null | undefined;
  onChange: (e: any) => void;
  size?: "small" | "medium" | null;
  fullWidth?: boolean | undefined;
  disablePast?: boolean;
  error?: any;
  disable?: boolean;
  mode?: "read" | "read&Write";
  minDate?: string | null | undefined;
  readOnlyText?: boolean;
  showSkeleton?: boolean;
  outputFormat?: string;
  disableFuture?: boolean;
}

const FormDatePicker = (props: FormSelectProps) => {
  const {
    name,
    label,
    value,
    disablePast,
    error,
    disable,
    onChange,
    mode,
    minDate,
    readOnlyText,
    showSkeleton,
    outputFormat = "MM/DD/YYYY",
    fullWidth,
    disableFuture,
  } = props;

  const convertToDefEventPara = (name: string, value: any | null) => {
    return {
      target: {
        name,
        value: moment(value?.$d).format(outputFormat),
      },
    };
  };

  if (mode && mode === "read")
    return (
      <FormReadOnlyField
        label={label}
        value={value ? moment(value as any).format(outputFormat) : "-"}
      />
    );

  return (
    <>
      {showSkeleton ? (
        <Skeleton height={40}></Skeleton>
      ) : (
        <DatePicker
          label={label}
          value={dayjs(value)}
          disablePast={disablePast ?? false}
          disableFuture={disableFuture ?? false}
          onChange={(date) => onChange(convertToDefEventPara(name, date))}
          disabled={disable ?? false}
          minDate={minDate ? dayjs(minDate) : dayjs("")}
          readOnly={readOnlyText}
          slotProps={{
            textField: {
              size: "small",
              helperText: error ?? "",
              error: error !== undefined && error !== null && error !== "",
              fullWidth: fullWidth,
            },
          }}

          //https://mui.com/x/react-date-pickers/validation/
          //TODO: issue with packages
          // renderInput={(params: any) => (
          //   <TextField
          //     size={size ?? "small"}
          //     fullWidth={fullWidth ?? true}
          //     {...params}
          //     //   InputLabelProps={{ shrink: value }}
          //     {...(error && { error: true, helperText: error })}
          //   />
          // )}
        />
      )}
    </>
  );
};

export default FormDatePicker;
