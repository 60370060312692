import { TowerStudyProvider } from "../context/TowerStudyContext";
import TowerStep6 from "src/pages/studies/tower/towerStep6/TowerStep6";

const Index = () => {
  return (
    <TowerStudyProvider>
      <TowerStep6 />
    </TowerStudyProvider>
  );
};

export default Index;
