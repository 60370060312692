import React, { useEffect, useState } from "react";
import { Grid, Typography } from "@mui/material";
import { FormCheckBox } from "src/components/formControls";
import { IEnergyCostProcess } from "src/ts/interfaces/energyCost";
import { useParams } from "react-router-dom";
import { UserParams } from "src/ts/types";
import buildingService from "src/services/buildingService";

interface IBuildingEnergyCostProps {
  items: IEnergyCostProcess;
  onChange: (e: IEnergyCostProcess) => void;
}

const initialValues: IEnergyCostProcess = {
  id: 0,
  energyCostId: 0,
  processTypeId: 0,
  processType: {
    id: 0,
    name: "",
    statusId: 0,
    status: {
      id: 0,
      name: "",
    },
  },
  limitedDataId: 0,
  limitedData: false,
  annualDetailedId: 0,
  annualDetailedY1: false,
  annualDetailedY2: false,
  annualDetailedY3: false,
  annualCombinedId: 0,
  annualCombinedY1: false,
  annualCombinedY2: false,
  annualCombinedY3: false,
};

const BuildingEnergyCost = (props: IBuildingEnergyCostProps) => {
  const { items, onChange } = props;
  const [values, setValues] = useState(initialValues);
  let { id } = useParams<UserParams>();
  const [canEdit, setCanEdit] = useState(false);

  useEffect(() => {
    const getBuilding = async (buildingId: number) => {
      const response = await buildingService.getById(buildingId);
      setCanEdit(response.data.canEdit);
    };

    if (Number(id) > 0) {
      getBuilding(Number(id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (items) setValues(items);
  }, [items]);

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;

    if (name === "limitedData") {
      values.limitedData = value;
      if (value) {
        values.annualDetailedY1 = false;
        values.annualDetailedY2 = false;
        values.annualDetailedY3 = false;
        values.annualCombinedY1 = false;
        values.annualCombinedY2 = false;
        values.annualCombinedY3 = false;
      }
    }

    if (name === "annualDetailedY1") {
      values.annualDetailedY1 = value;
      if (value) {
        values.limitedData = false;
        values.annualCombinedY1 = false;
        values.annualCombinedY2 = false;
        values.annualCombinedY3 = false;
      }
    }

    if (name === "annualDetailedY2") {
      values.annualDetailedY2 = value;
      if (value) {
        values.limitedData = false;
        values.annualCombinedY1 = false;
        values.annualCombinedY2 = false;
        values.annualCombinedY3 = false;
      }
    }

    if (name === "annualDetailedY3") {
      values.annualDetailedY3 = value;
      if (value) {
        values.limitedData = false;
        values.annualCombinedY1 = false;
        values.annualCombinedY2 = false;
        values.annualCombinedY3 = false;
      }
    }

    if (name === "annualCombinedY1") {
      values.annualCombinedY1 = value;
      if (value) {
        values.limitedData = false;
        values.annualDetailedY1 = false;
        values.annualDetailedY2 = false;
        values.annualDetailedY3 = false;
      }
    }

    if (name === "annualCombinedY2") {
      values.annualCombinedY2 = value;
      if (value) {
        values.limitedData = false;
        values.annualDetailedY1 = false;
        values.annualDetailedY2 = false;
        values.annualDetailedY3 = false;
      }
    }

    if (name === "annualCombinedY3") {
      values.annualCombinedY3 = value;
      if (value) {
        values.limitedData = false;
        values.annualDetailedY1 = false;
        values.annualDetailedY2 = false;
        values.annualDetailedY3 = false;
      }
    }

    setValues({
      ...values,
    });
    onChange({
      ...values,
    });
  };
  return (
    <>
      <Grid container spacing={6}>
        <Grid item xs={3}>
          <Typography variant="subtitle2" sx={{ textAlign: "right" }}>
            Primary energy source:
          </Typography>
        </Grid>
        <Grid item xs={9} sx={{ textAlign: "center" }}>
          <Typography variant="subtitle2">Natural Gas - Electric</Typography>
        </Grid>
        <Grid item xs={3} sx={{ textAlign: "center" }}></Grid>
        <Grid item xs={9} sx={{ textAlign: "center" }}>
          <Typography variant="subtitle2">
            If utility billing data available is limited and misc months are
            missing in any 1 fiscal year, choose a 12 Entry field and allow the
            system to calculate and report sums logically
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="subtitle2" sx={{ textAlign: "right" }}>
            Option 1:
          </Typography>
        </Grid>
        <Grid item xs={9}>
          <Typography variant="subtitle2">Limited Data</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography
            variant="subtitle2"
            sx={{ textAlign: "right" }}
          ></Typography>
        </Grid>
        <Grid item xs={9}>
          <Typography variant="subtitle2">
            <FormCheckBox
              label="One month only"
              name="limitedData"
              value={values.limitedData}
              onChange={handleInputChange}
              disabled={!canEdit}
            />
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="subtitle2" sx={{ textAlign: "right" }}>
            Option 2:
          </Typography>
        </Grid>
        <Grid item xs={9}>
          <Typography variant="subtitle2">
            Annual Detailed = 12 Entries per year
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography
            variant="subtitle2"
            sx={{ textAlign: "right" }}
          ></Typography>
        </Grid>
        <Grid item xs={9}>
          <Typography variant="subtitle2">
            <FormCheckBox
              label="Total/Annual Year 1"
              name="annualDetailedY1"
              value={values.annualDetailedY1}
              onChange={handleInputChange}
              disabled={!canEdit}
            />
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography
            variant="subtitle2"
            sx={{ textAlign: "right" }}
          ></Typography>
        </Grid>
        <Grid item xs={9}>
          <Typography variant="subtitle2">
            <FormCheckBox
              label="Total/Annual Year 2"
              name="annualDetailedY2"
              value={values.annualDetailedY2}
              onChange={handleInputChange}
              disabled={!canEdit}
            />
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography
            variant="subtitle2"
            sx={{ textAlign: "right" }}
          ></Typography>
        </Grid>
        <Grid item xs={9}>
          <Typography variant="subtitle2">
            <FormCheckBox
              label="Total/Annual Year 3"
              name="annualDetailedY3"
              value={values.annualDetailedY3}
              onChange={handleInputChange}
              disabled={!canEdit}
            />
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="subtitle2" sx={{ textAlign: "right" }}>
            Option 3:
          </Typography>
        </Grid>
        <Grid item xs={9}>
          <Typography variant="subtitle2">
            Annual Combined = 1 Entry per year only
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography
            variant="subtitle2"
            sx={{ textAlign: "right" }}
          ></Typography>
        </Grid>
        <Grid item xs={9}>
          <Typography variant="subtitle2">
            <FormCheckBox
              label="Total/Annual Year 1"
              name="annualCombinedY1"
              value={values.annualCombinedY1}
              onChange={handleInputChange}
              disabled={!canEdit}
            />
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography
            variant="subtitle2"
            sx={{ textAlign: "right" }}
          ></Typography>
        </Grid>
        <Grid item xs={9}>
          <Typography variant="subtitle2">
            <FormCheckBox
              label="Total/Annual Year 2"
              name="annualCombinedY2"
              value={values.annualCombinedY2}
              onChange={handleInputChange}
              disabled={!canEdit}
            />
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography
            variant="subtitle2"
            sx={{ textAlign: "right" }}
          ></Typography>
        </Grid>
        <Grid item xs={9}>
          <Typography variant="subtitle2">
            <FormCheckBox
              label="Total/Annual Year 3"
              name="annualCombinedY3"
              value={values.annualCombinedY3}
              onChange={handleInputChange}
              disabled={!canEdit}
            />
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

export default BuildingEnergyCost;
