import axios from "src/utils/axios";
import { IEnergyStudyAirHydronicsDTO } from "src/ts/interfaces";

const updateSystemTotalBTUH = (
  id: number,
  energyReport: IEnergyStudyAirHydronicsDTO
) => {
  return axios.put<IEnergyStudyAirHydronicsDTO>(
    `EnergyStudyAirHydronicsTimes/UpdateSystemTotalBTUH/${id}`,
    energyReport
  );
};

const updateHeatingSystemTotalBTUH = (
  id: number,
  energyReport: IEnergyStudyAirHydronicsDTO
) => {
  return axios.put<IEnergyStudyAirHydronicsDTO>(
    `EnergyStudyAirHydronicsTimes/UpdateHeatingSystemTotalBTUH/${id}`,
    energyReport
  );
};

const updateCoolingCoilBTUH = (
  id: number,
  energyReport: IEnergyStudyAirHydronicsDTO
) => {
  return axios.put<IEnergyStudyAirHydronicsDTO>(
    `EnergyStudyAirHydronicsTimes/UpdateCoolingCoilTotalBTUH/${id}`,
    energyReport
  );
};

const updateCoolingCoilGPM = (
  id: number,
  energyReport: IEnergyStudyAirHydronicsDTO
) => {
  return axios.put<IEnergyStudyAirHydronicsDTO>(
    `EnergyStudyAirHydronicsTimes/UpdateCoolingCoilGpm/${id}`,
    energyReport
  );
};

const updateCoolingCoilEWT = (
  id: number,
  energyReport: IEnergyStudyAirHydronicsDTO
) => {
  return axios.put<IEnergyStudyAirHydronicsDTO>(
    `EnergyStudyAirHydronicsTimes/UpdateCoolingCoilEnteringWaterTemp/${id}`,
    energyReport
  );
};

const updateCoolingCoilLWT = (
  id: number,
  energyReport: IEnergyStudyAirHydronicsDTO
) => {
  return axios.put<IEnergyStudyAirHydronicsDTO>(
    `EnergyStudyAirHydronicsTimes/UpdateCoolingCoilLeavingWaterTemp/${id}`,
    energyReport
  );
};

const updateCoolingCoilAWT = (
  id: number,
  energyReport: IEnergyStudyAirHydronicsDTO
) => {
  return axios.put<IEnergyStudyAirHydronicsDTO>(
    `EnergyStudyAirHydronicsTimes/UpdateCoolingCoilAtf/${id}`,
    energyReport
  );
};

const updateHeatingCoilBTUH = (
  id: number,
  energyReport: IEnergyStudyAirHydronicsDTO
) => {
  return axios.put<IEnergyStudyAirHydronicsDTO>(
    `EnergyStudyAirHydronicsTimes/UpdateHeatingCoilTotalBTUH/${id}`,
    energyReport
  );
};

const updateHeatingCoilGPM = (
  id: number,
  energyReport: IEnergyStudyAirHydronicsDTO
) => {
  return axios.put<IEnergyStudyAirHydronicsDTO>(
    `EnergyStudyAirHydronicsTimes/UpdateHeatingCoilGpm/${id}`,
    energyReport
  );
};

const updateHeatingCoilEWT = (
  id: number,
  energyReport: IEnergyStudyAirHydronicsDTO
) => {
  return axios.put<IEnergyStudyAirHydronicsDTO>(
    `EnergyStudyAirHydronicsTimes/UpdateHeatingCoilEnteringWaterTemp/${id}`,
    energyReport
  );
};

const updateHeatingCoilLWT = (
  id: number,
  energyReport: IEnergyStudyAirHydronicsDTO
) => {
  return axios.put<IEnergyStudyAirHydronicsDTO>(
    `EnergyStudyAirHydronicsTimes/UpdateHeatingCoilLeavingWaterTemp/${id}`,
    energyReport
  );
};

const updateHeatingCoilAWT = (
  id: number,
  energyReport: IEnergyStudyAirHydronicsDTO
) => {
  return axios.put<IEnergyStudyAirHydronicsDTO>(
    `EnergyStudyAirHydronicsTimes/UpdateHeatingCoilAtf/${id}`,
    energyReport
  );
};

const energyStudyAirHydronicsTimesService = {
  updateSystemTotalBTUH,
  updateHeatingSystemTotalBTUH,
  updateCoolingCoilBTUH,
  updateCoolingCoilGPM,
  updateCoolingCoilEWT,
  updateCoolingCoilLWT,
  updateCoolingCoilAWT,
  updateHeatingCoilBTUH,
  updateHeatingCoilGPM,
  updateHeatingCoilEWT,
  updateHeatingCoilLWT,
  updateHeatingCoilAWT,
};

export default energyStudyAirHydronicsTimesService;
