import React from "react";
import styled from "@emotion/styled";

import { Typography } from "@mui/material";

import { SidebarItemsType } from "src/types/sidebar";
import SidebarNavList from "./SidebarNavList";
import AuthVerify from "src/utils/authVerify";

const Title = styled(Typography)`
  color: white;
  font-size: 14px;
  padding: ${(props) => props.theme.spacing(4)}
    ${(props) => props.theme.spacing(7)} ${(props) => props.theme.spacing(1)};
  opacity: 0.4;
  text-transform: uppercase;
  display: block;
`;

type SidebarNavSectionProps = {
  className?: Element;
  component?: React.ElementType;
  pages: SidebarItemsType[];
  title?: string;
};

const SidebarNavSection: React.FC<SidebarNavSectionProps> = (props) => {
  const {
    title,
    pages,
    className,
    component: Component = "nav",
    ...rest
  } = props;

  AuthVerify();

  return (
    <Component {...rest}>
      {title && (
        <Title variant="h1" sx={{ color: "white" }}>
          {title}
        </Title>
      )}
      <SidebarNavList pages={pages} depth={0} />
    </Component>
  );
};

export default SidebarNavSection;
