import { useState, useEffect, useRef } from "react";
import { useAsyncQuery, useAuth, useTheme } from "src/hooks";
import DialogMessagePopup from "../DialogMessagePopup";
import { Grid, Typography } from "@mui/material";
import FormCountdown from "../formControls/FormCountdown";
import { useLocation, useNavigate } from "react-router-dom";
import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";
import createTheme from "src/theme";
import { gpsPermissionService } from "src/services";

const FormIdle = () => {
  const delay = 3600000;
  const idleSeconds = 61000;
  const location = useLocation();
  const { user } = useAuth();
  const [isIdle, setIsIdle] = useState(false);
  const { isInitialized, signOutIdle, isAuthenticated } = useAuth();
  const [idleModal, setIdleModal] = useState(false);
  const [idleDate, setIdleDate] = useState(new Date());
  const [count, setCount] = useState(0);
  const [isActive, setIsActive] = useState(false);
  const navigate = useNavigate();
  const { theme } = useTheme();
  // create a new reference to track timer
  const timeoutId = useRef<ReturnType<typeof setInterval> | null>(null);
  const { execute: getPermissionStatus, data: permissionStatus } =
    useAsyncQuery<string>(gpsPermissionService.getPermissionStatus);
  // assign and remove the listeners
  useEffect(() => {
    setup();

    return () => {
      cleanUp();
    };
  });

  useEffect(() => {
    const getData = async () => {
      await getPermissionStatus();
    };
    if (
      user &&
      user?.userId > 0 &&
      location.pathname.indexOf("auth/sign-in") === -1 &&
      !permissionStatus
    )
      getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, location]);

  useEffect(() => {
    if (isAuthenticated && isInitialized) {
      resetTimer();
    } else {
      goInactive();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, isInitialized, location]);

  useEffect(() => {
    let id: NodeJS.Timeout;
    let seconds = (idleSeconds - 2000) / 1000;
    if (isActive) {
      id = setInterval(() => {
        setCount(count + 1);
      }, 1000);
      if (count >= seconds) {
        setCount(0);
        handleLogout();
      }
    }
    return () => clearInterval(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActive, count]);

  const handleIdle = () => {
    if (isAuthenticated && isInitialized) {
      let t = new Date();
      t.setSeconds(t.getSeconds() + idleSeconds / 1000);
      setIdleDate(t);
      setIdleModal(true);
      setIsActive(true);
      setCount(0);
    }
    goInactive();
  };

  const startTimer = () => {
    // wait till delay time before calling goInactive
    timeoutId.current = setTimeout(handleIdle, delay);
  };

  const resetTimer = () => {
    //reset the timer and make user active
    clearTimeout(timeoutId.current ?? undefined);
    goActive();
  };

  const goInactive = () => {
    setIsIdle(true);
  };

  const goActive = () => {
    setIsIdle(false);

    // start the timer to track Inactiveness
    startTimer();
  };

  const setup = () => {
    document.addEventListener("mousemove", resetTimer, false);
    document.addEventListener("mousedown", resetTimer, false);
    document.addEventListener("keypress", resetTimer, false);
    document.addEventListener("DOMMouseScroll", resetTimer, false);
    document.addEventListener("mousewheel", resetTimer, false);
    document.addEventListener("touchmove", resetTimer, false);
    document.addEventListener("MSPointerMove", resetTimer, false);

    //edge case
    //if tab is changed or is out of focus
    window.addEventListener("blur", resetTimer, false);
    window.addEventListener("focus", resetTimer, false);
  };

  const cleanUp = () => {
    document.removeEventListener("mousemove", resetTimer);
    document.removeEventListener("mousedown", resetTimer);
    document.removeEventListener("keypress", resetTimer);
    document.removeEventListener("DOMMouseScroll", resetTimer);
    document.removeEventListener("mousewheel", resetTimer);
    document.removeEventListener("touchmove", resetTimer);
    document.removeEventListener("MSPointerMove", resetTimer);

    //edge case
    //if tab is changed or is out of focus
    window.removeEventListener("blur", resetTimer);
    window.removeEventListener("focus", resetTimer);

    // memory leak
    clearTimeout(timeoutId.current ?? undefined);
  };

  const handleLogout = async () => {
    setIsActive(false);
    setCount(0);
    setIdleModal(false);
    try {
      if (
        user?.permanentPermissionTechniciansApp === "false" &&
        permissionStatus !== "Permanent" &&
        permissionStatus !== "Active"
      ) {
        signOutIdle(false);
      } else signOutIdle(true);
    } finally {
      navigate("/auth/sign-in");
    }
  };

  const handleRestart = async () => {
    resetTimer();
    setIdleModal(false);
    setIsActive(false);
    setCount(0);
  };

  return (
    <MuiThemeProvider theme={createTheme(theme)}>
      <DialogMessagePopup
        title={"Idle timer expired"}
        text={
          <>
            <Grid container>
              <Grid item xs={6}>
                <Typography typography={"h6"} pt={2}>
                  Are you still there?
                </Typography>
                <Typography typography={"subtitle2"}>
                  If not, we'll close this session in:
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <FormCountdown
                  size="medium"
                  hideDays={true}
                  hideHours={true}
                  date={idleDate}
                ></FormCountdown>
              </Grid>
            </Grid>
          </>
        }
        showPopup={idleModal}
        setShowPopup={setIdleModal}
        isSubmitting={false}
        cancelTextButton={"Logout"}
        acceptTextButton={"Stay Connected"}
        onSave={handleRestart}
        onCancel={handleLogout}
        disableClickOutside={true}
      ></DialogMessagePopup>
    </MuiThemeProvider>
  );
};

export default FormIdle;
