import { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import {
  Grid,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
  Typography,
  Button,
  Card as MuiCard,
  CardContent,
  Stack,
} from "@mui/material";
import { spacing } from "@mui/system";
import { Add as AddIcon } from "@mui/icons-material";
import { ColumnType } from "src/types/enhancedTable";
import { useForm, Form, useAsyncQuery, useLog } from "src/hooks";
import FormText from "src/components/formControls/FormText";
import LocalEnhancedTable from "src/components/localTable/LocalTable";
import { GridActionButton } from "src/components/gridControls";
import { emailBlackListService } from "src/services";
import { IEmailBlackList } from "src/ts/interfaces/emailBlackList";
import DialogMessagePopup from "src/components/DialogMessagePopup";
import EmailBlackListPopup from "./EmailBlackListPopup";
import { Permission as PermissionTypes } from "src/ts/enums";
import { usePermissions } from "src/hooks";

const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Card = styled(MuiCard)(spacing);

const initialValues = {
  email: "",
};

const getColumns = (
  deleteRowHandler: (row: IEmailBlackList) => void,
  fullAccess: boolean | undefined
): ColumnType[] => [
  { id: "email", label: "Email", type: "string", sort: true },
  {
    id: "createdDate",
    label: "Created Date",
    type: "utcDate",
    sort: true,
    format: "MM/DD/yyyy h:mma",
  },
  { id: "createdBy", label: "Created By", type: "string", sort: true },
  {
    id: "updatedDate",
    label: "Updated Date",
    type: "utcDate",
    sort: true,
    format: "MM/DD/yyyy h:mma",
  },
  { id: "updatedBy", label: "Updated By", type: "string", sort: true },
  {
    id: "action",
    label: "Actions",
    type: "custom",
    sort: false,
    callback: (row: IEmailBlackList) => (
      <Stack direction="row">
        {fullAccess && (
          <GridActionButton
            type="delete"
            onClick={() => deleteRowHandler(row)}
            tooltip="Delete"
          />
        )}
      </Stack>
    ),
  },
];

const EmailBlackList = () => {
  const [refreshGrid, setRefreshGrid] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [currentRow, setCurrentRow] = useState<IEmailBlackList>();
  const { log } = useLog();
  const { execute, data, isLoading } = useAsyncQuery<IEmailBlackList[]>(
    emailBlackListService.getAll
  );

  const { fullAccess, readOnly } = usePermissions(
    PermissionTypes.Email_Black_list
  );

  const { values: filterValues, handleInputChange: handleFilterChange } =
    useForm(initialValues, true, []);

  useEffect(() => {
    const getData = async () => {
      execute();
    };

    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshGrid]);

  const handleOpen = () => {
    setIsDialogOpen(true);
  };

  const refreshGridHandler = () => {
    setRefreshGrid(!refreshGrid);
  };
  // const handleClose = () => {
  //   setIsDialogOpen(false);
  // };

  // const handleEdit = (row: any) => {
  //   setIsDialogOpen(true);
  // };

  const deleteRowHandler = (row: IEmailBlackList) => {
    setCurrentRow(row);
    setShowDeleteModal(true);
  };

  const deleteHandler = async () => {
    if (currentRow?.id !== undefined) {
      try {
        await emailBlackListService.remove(currentRow?.id);
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      }
      refreshGridHandler();
    }
  };

  const columns = getColumns(deleteRowHandler, fullAccess);

  return (
    <>
      <Helmet title={"AMP"} />
      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            {"AMP"}
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} to="/">
              Dashboard
            </Link>
            <Typography>{"AMP"}</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item>
          <div>
            {fullAccess && (
              <Button onClick={handleOpen} variant="contained" color="primary">
                <AddIcon />
                New Email
              </Button>
            )}
          </div>
        </Grid>
      </Grid>

      <Divider my={6} />

      <Card mb={6}>
        <CardContent>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Form>
                <Grid container>
                  <Grid item xs={12}>
                    <FormText
                      name="email"
                      label="email"
                      value={filterValues.email}
                      onChange={handleFilterChange}
                      disabled={readOnly}
                    />
                  </Grid>
                </Grid>
              </Form>
            </Grid>
            <Grid item xs={12}>
              <LocalEnhancedTable<IEmailBlackList>
                refreshGrid={refreshGrid}
                columns={columns}
                data={data}
                query={filterValues.email}
                showSkeleton={isLoading}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <DialogMessagePopup
        title={"Information"}
        text={"Are you certain you want to delete this item?"}
        showPopup={showDeleteModal}
        setShowPopup={setShowDeleteModal}
        onSave={deleteHandler}
        isSubmitting={false}
      />
      <EmailBlackListPopup
        isDialogOpen={isDialogOpen}
        setIsDialogOpen={setIsDialogOpen}
        onSave={refreshGridHandler}
      />
    </>
  );
};

export default EmailBlackList;
