import Popup from "src/components/Popup";
import {
  Stack,
  Typography,
  styled,
  LinearProgress as MuiLinearProgress,
  Grid,
} from "@mui/material";
import { spacing } from "@mui/system";
import { FormButton } from "src/components/formControls";
import { useNavigate } from "react-router-dom";

interface WaitingMobileAppPopupProps {
  showWaitingMobileAppPopup: boolean;
  setShowWaitingMobileAppPopup: (val: boolean) => void;
}

const LinearProgress = styled(MuiLinearProgress)(spacing);

export default function WaitingMobileAppPopup({
  showWaitingMobileAppPopup,
  setShowWaitingMobileAppPopup,
}: WaitingMobileAppPopupProps) {
  const navigate = useNavigate();
  const handleGotoPoject = () => {
    navigate("/app/Working");
  };
  return (
    <>
      <Popup
        title="Information"
        openPopup={showWaitingMobileAppPopup}
        setOpenPopup={setShowWaitingMobileAppPopup}
        onClose={() => {
          setShowWaitingMobileAppPopup(false);
        }}
        size="xs"
        hideClose={true}
        disableClickOutside={true}
      >
        <>
          <Stack direction="row" spacing={2}>
            <Grid container>
              <Grid item md={12} sx={{ textAlign: "center" }} pb={3}>
                <LinearProgress my={2} />
              </Grid>
              <Grid item md={12}>
                <Typography>
                  <b>
                    Waiting for a response from your AMP's Technician Mobile App
                  </b>
                </Typography>
              </Grid>
              <Grid item md={12} textAlign={"center"} p={3}>
                <FormButton
                  text={"Go back to project's list"}
                  onClick={handleGotoPoject}
                  variant="outlined"
                ></FormButton>
              </Grid>
            </Grid>
          </Stack>
        </>
        <Stack></Stack>
      </Popup>
    </>
  );
}
