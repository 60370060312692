import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import {
  Grid,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
  Typography,
  Card as MuiCard,
  CardContent,
  Stack,
} from "@mui/material";
import { spacing } from "@mui/system";
import { ColumnType } from "src/types/enhancedTable";
import LocalEnhancedTable from "src/components/localTable/LocalTable";
import useAsyncQuery from "src/hooks/useAsyncQuery";
import { GridActionButton } from "src/components/gridControls";
import { Permission as PermissionTypes } from "src/ts/enums";
import { usePermissions } from "src/hooks";
import { ICompanyAssetsType } from "src/ts/interfaces/catalogs/companyAssetsType";
import CompanyAssetsForm from "./CompanyAssetsTypeForm";
import companyAssetsTypeService from "src/services/catalogs/companyAssetTypeService";

const Card = styled(MuiCard)(spacing);
const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const initialValues: ICompanyAssetsType = {
  id: 0,
  name: "",
  code: "",
  title: "",
  recommended: "",
  recommendedNvo: "",
  recommendedHTML: "",
  orderPosition: 0,
  statusId: 0,
};

const CompanyAssetsType = () => {
  const { execute, data, isLoading } = useAsyncQuery<ICompanyAssetsType[]>(
    companyAssetsTypeService.getAll
  );
  const [refreshPage, setRefreshPage] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [currentRow, setCurrentRow] =
    useState<ICompanyAssetsType>(initialValues);

  useEffect(() => {
    const getData = async () => {
      await execute();
    };

    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshPage]);

  const handleEdit = (row: ICompanyAssetsType) => {
    setCurrentRow(row);
    setIsDialogOpen(true);
  };

  const refreshGridHandler = () => {
    setRefreshPage(!refreshPage);
  };

  const { fullAccess } = usePermissions(PermissionTypes.Catalogs);

  const columns: ColumnType[] = [
    {
      id: "id",
      label: "#",
      type: "string",
      sort: true,
    },
    {
      id: "name",
      label: "Name",
      type: "string",
      sort: true,
    },
    {
      id: "code",
      label: "Code",
      type: "string",
      sort: true,
    },
    {
      id: "x",
      label: "Actions",
      type: "custom",
      sort: false,
      callback: (row: ICompanyAssetsType) => (
        <div>
          <Stack direction="row">
            <GridActionButton
              type={fullAccess ? "edit" : "view"}
              onClick={() => {
                handleEdit(row);
              }}
              tooltip={fullAccess ? "Edit" : "View"}
            />
          </Stack>
        </div>
      ),
    },
  ];

  return (
    <>
      <Helmet title="Subscription Costs" />
      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Company Assets
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} to="/">
              Dashboard
            </Link>
            <Typography>Company Assets</Typography>
          </Breadcrumbs>
        </Grid>
      </Grid>

      <Divider my={6} />
      <Card mb={6}>
        <CardContent>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <LocalEnhancedTable<ICompanyAssetsType>
                refreshGrid={true}
                columns={columns}
                data={data ?? []}
                showSkeleton={isLoading}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <CompanyAssetsForm
        selectedValue={currentRow}
        isDialogOpen={isDialogOpen}
        setIsDialogOpen={setIsDialogOpen}
        onUpdate={refreshGridHandler}
      />
    </>
  );
};

export default CompanyAssetsType;
