import { useEffect } from "react";
import Tolerances from "../../projectStudies/components/Tolerances";
import { ProjectDTO } from "src/ts/interfaces/project/projectDto";
import { useAsyncQuery } from "src/hooks";

import getProjectSingleService from "src/services/project/getProjectSingleService";
import { Skeleton, Stack, Typography } from "@mui/material";

interface GeneralProjectProps {
  companyId: number;
  projectResponse: number;
  setActiveStep: (val: number) => void;
}

export default function ToleranceProject({
  companyId,
  projectResponse,
  setActiveStep,
}: GeneralProjectProps) {
  const { execute, data, setData, isLoading } = useAsyncQuery<ProjectDTO>(
    getProjectSingleService.getById
  );

  useEffect(() => {
    execute(projectResponse);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const skeletonCount = 10;
  const skeletons = Array.from({ length: skeletonCount }, (_, index) => (
    <Skeleton
      key={index}
      width={1000}
      height={25}
      style={{ marginTop: "5px" }}
    />
  ));
  return (
    <>
      {data && data.templateName !== null && (
        <Stack textAlign={"center"} mb={5}>
          <Typography variant="h3">
            Template applied: {`${data.templateName}`}
          </Typography>
        </Stack>
      )}
      {data ? (
        <Tolerances
          project={data}
          setProject={setData}
          disable={true}
          useTemplate={true}
          isPartOfProject={true}
          isLoading={isLoading}
        />
      ) : (
        skeletons
      )}
    </>
  );
}
