import {
  CardContent,
  Grid,
  Divider as MuiDivider,
  Card as MuiCard,
  Stepper,
  Step,
  StepLabel,
  Stack,
} from "@mui/material";
import HeaderPage from "src/components/page/HeaderPage";
import { spacing } from "@mui/system";
import styled from "@emotion/styled";
import { useAuth } from "src/hooks";
import { useEffect, useState } from "react";
import { FormCancelButton } from "src/components/formControls";
import Agreement from "./components/Agreement";
import Agreement2 from "./components/Agreement2";
import SelectBuilding from "./components/SelectBuilding";
import Validations from "./components/Validations";
import ProjectInformation from "./components/ProjectInformation";
import Payment from "./components/Payment";
import { ICompany } from "src/ts/interfaces";
import { ProjectWithBogus } from "src/ts/interfaces/project/projectDto";
import { CompanyService } from "src/services";
import DialogMessagePopup from "src/components/DialogMessagePopup";
import FormNextButton from "src/components/formControls/FormNextButton";
import FormBackButton from "src/components/formControls/FormBackButton";

const Divider = styled(MuiDivider)(spacing);
const Card = styled(MuiCard)(spacing);

enum WizardStepsEnum {
  Agreement,
  Agreement2,
  SelectBuilding,
  Validate,
  ProjectInformation,
  Payment,
}

interface CreateInfectiousControlProjectStep {
  label: string;
  key: number;
  type: WizardStepsEnum;
}

interface DialogArgs {
  show: boolean;
  source: string;
}

const CreateInfectiousControlProject = () => {
  const { user } = useAuth();
  const [steps, setSteps] = useState<CreateInfectiousControlProjectStep[]>([]);
  const [activeStep, setActiveStep] = useState<number>(0);
  const [stepControl, setStepControl] = useState<boolean[]>([
    false,
    false,
    false,
    false,
    false,
  ]);
  const [selectedBuilding, setSelectedBuilding] = useState<number | null>(null);
  const [company, setCompany] = useState<ICompany | null>(null);
  const [projectInformation, setProjectInformation] =
    useState<ProjectWithBogus | null>(null);
  const [showAvoidLosingModal, setShowAvoidLosingModal] = useState<DialogArgs>({
    source: "",
    show: false,
  });

  useEffect(() => {
    setSteps([
      {
        key: 0,
        label: "Agreement - Part 1",
        type: WizardStepsEnum.Agreement,
      },
      {
        key: 1,
        label: "Agreement - Part 2",
        type: WizardStepsEnum.Agreement2,
      },
      {
        key: 2,
        label: "Select Building",
        type: WizardStepsEnum.SelectBuilding,
      },
      {
        key: 3,
        label: "Check validations",
        type: WizardStepsEnum.Validate,
      },
      {
        key: 4,
        label: "Project Information",
        type: WizardStepsEnum.ProjectInformation,
      },
      {
        key: 5,
        label: "Payment",
        type: WizardStepsEnum.Payment,
      },
    ]);

    const getCompany = async () => {
      const companyResponse = await CompanyService.getById(user?.companyId);
      setCompany(companyResponse.data);
    };

    getCompany();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const previousStepHandler = () => {
    if (activeStep === WizardStepsEnum.ProjectInformation) {
      setShowAvoidLosingModal({ show: true, source: "previousStepButton" });
      return;
    }
    setActiveStep(activeStep - 1);
  };

  const showAvoidLosingHandler = () => {
    if (showAvoidLosingModal.source === "cancelButton") {
      setSelectedBuilding(null);
      setProjectInformation(null);
      setStepControl([false, false, false, false, false]);
      setActiveStep(0);
    }

    if (showAvoidLosingModal.source === "previousStepButton") {
      setProjectInformation(null);
      setActiveStep(activeStep - 1);
      updateControlStep(4, false);
    }
  };

  const setShowAvoidLosingModalHandler = (val: boolean) => {
    setShowAvoidLosingModal({ show: val, source: "dialog" });
  };

  const nexStepHandler = async () => {
    setActiveStep(activeStep + 1);
  };

  const cancelHandler = () => {
    setShowAvoidLosingModal({ show: true, source: "cancelButton" });
  };

  const updateControlStep = (step: number, value: boolean) => {
    const updatedSteps = [...stepControl];
    updatedSteps[step] = value;
    setStepControl([...updatedSteps]);
  };

  const setProjectInformationHandler = (project: ProjectWithBogus | null) => {
    setProjectInformation(project);
  };

  return (
    <>
      <HeaderPage
        title="Create an Infectious Control Compliancy Project"
        parentText="Dashboard"
        parentLink="/"
        infoSection={<></>}
      />
      <Divider my={6} />

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card mb={6}>
            <CardContent>
              <Stepper alternativeLabel activeStep={activeStep}>
                {steps.map((step) => (
                  <Step key={step.key}>
                    <StepLabel style={{ textTransform: "capitalize" }}>
                      {step.label}
                    </StepLabel>
                  </Step>
                ))}
              </Stepper>
              {steps[activeStep]?.type === WizardStepsEnum.Agreement && (
                <Agreement
                  updateControlStep={updateControlStep}
                  value={stepControl[0]}
                />
              )}
              {steps[activeStep]?.type === WizardStepsEnum.Agreement2 && (
                <Agreement2
                  updateControlStep={updateControlStep}
                  value={stepControl[1]}
                />
              )}
              {steps[activeStep]?.type === WizardStepsEnum.SelectBuilding && (
                <SelectBuilding
                  updateControlStep={updateControlStep}
                  value={stepControl[2]}
                  setSelectedBuilding={setSelectedBuilding}
                  selectedBuilding={selectedBuilding}
                />
              )}
              {steps[activeStep]?.type === WizardStepsEnum.Validate &&
                selectedBuilding !== null && (
                  <Validations
                    updateControlStep={updateControlStep}
                    value={stepControl[3]}
                    selectedBuilding={selectedBuilding}
                  />
                )}
              {steps[activeStep]?.type === WizardStepsEnum.ProjectInformation &&
                selectedBuilding !== null &&
                company !== null && (
                  <ProjectInformation
                    updateControlStep={updateControlStep}
                    value={stepControl[4]}
                    selectedBuildingId={selectedBuilding}
                    setProjectInformationHandler={setProjectInformationHandler}
                    company={company}
                    projectInformation={projectInformation}
                  />
                )}
              {steps[activeStep]?.type === WizardStepsEnum.Payment &&
                company !== null &&
                projectInformation !== null && (
                  <Payment company={company} project={projectInformation} />
                )}

              <Stack
                direction="row"
                spacing={2}
                justifyContent="center"
                alignItems="center"
                pt={5}
              >
                {activeStep > 0 && (
                  <FormBackButton
                    onClick={previousStepHandler}
                    size="small"
                    isSubmitting={false}
                    color="primary"
                    variant="outlined"
                  />
                )}
                <FormCancelButton
                  onClick={cancelHandler}
                  text="Cancel"
                  isSubmitting={false}
                  size="small"
                />
                {activeStep < steps.length - 1 && stepControl[activeStep] && (
                  <FormNextButton
                    onClick={nexStepHandler}
                    size="small"
                    isSubmitting={false}
                    color="primary"
                    variant="outlined"
                  />
                )}
              </Stack>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <DialogMessagePopup
        title={"Information"}
        text="If you continue to the previous step, you will lose your current project information. Are you certain you want to go to the previous step?"
        showPopup={showAvoidLosingModal.show}
        setShowPopup={setShowAvoidLosingModalHandler}
        onSave={showAvoidLosingHandler}
        onCancel={() => {
          setShowAvoidLosingModal({
            ...showAvoidLosingModal,
            show: false,
          });
        }}
        isSubmitting={false}
      />
    </>
  );
};

export default CreateInfectiousControlProject;
