import axios from "../utils/axios";
import { IChangeOrders } from "src/ts/interfaces/changeWorkOrder";

const create = async (item: IChangeOrders) => {
  return axios.post("ChangeOrders?newRoute=true", item);
};

const ChangeStatusCurrent = async (item: IChangeOrders) => {
  return axios.put("ChangeOrders/UpdateByCurrent", item);
};

const getAll = async (projectId: number, myChangeOrders: boolean) => {
  return axios.get<IChangeOrders[]>(
    `ChangeOrders/GetAll?projectId=${projectId}&myChangeOrders=${myChangeOrders}`
  );
};

const getByCode = async (code: string) => {
  return axios.get<IChangeOrders>(`ChangeOrders/GetByCode?code=${code}`);
};

const ChangeStatus = async (item: IChangeOrders) => {
  return axios.put("ChangeOrders/UpdateByCode", item);
};

const getDownloadFile = async (projectId: number, changeOrderId: number) => {
  return axios.get(
    `ChangeOrders/DownloadFile?projectId=${projectId}&changeOrderId=${changeOrderId}`
  );
};

const getPending = async (userId: number) => {
  return axios.get<IChangeOrders[]>(`ChangeOrders/GetPending?userId=${userId}`);
};

const changeOrdersService = {
  create,
  getAll,
  ChangeStatusCurrent,
  getByCode,
  ChangeStatus,
  getDownloadFile,
  getPending,
};

export default changeOrdersService;
