import { useEffect, useState } from "react";
import zipCodeService from "../services/zipCodeService";
import { IZipCode } from "../ts/interfaces";

function useZipCode(zipCode: any) {
  const [zipValue, setZipValue] = useState<IZipCode>();
  useEffect(() => {
    const getZip = async () => {
      let reponse = await zipCodeService.getByZipCode(zipCode);
      setZipValue(reponse.data);
    };
    if (zipCode && zipCode.length > 2 && zipCode.length < 6) getZip();
  }, [zipCode]);

  return { zipValue };
}

export default useZipCode;
