import {
  IProjectCurrent,
  ProjectSelectedDashboardDTO,
} from "src/ts/interfaces";
import axios from "src/utils/axios";

const getProjectCurrent = () => {
  return axios.get<ProjectSelectedDashboardDTO>(
    "ProjectCurrent/ProjectCurrent"
  );
};

const updateProjectCurrentTechnician = (projectId: number) => {
  return axios.get(
    `ProjectCurrent/UpdateProjectCurrentTechnician?id=${projectId}`
  );
};

const getByUser = () => {
  return axios.get<any>("ProjectCurrent/GetCurrentProjectByUser");
};

const projectCurrent = () => {
  return axios.get<ProjectSelectedDashboardDTO>(
    "ProjectCurrent/ProjectCurrent"
  );
};

const GetCurrentProjectByUser = () => {
  return axios.get<IProjectCurrent>("ProjectCurrent/GetCurrentProjectByUser");
};

const setCurrentProject = (projectId: number) => {
  return axios.put(`ProjectCurrent/${projectId}`);
};

const projectCurrentService = {
  projectCurrent,
  GetCurrentProjectByUser,
  setCurrentProject,
  getProjectCurrent,
  updateProjectCurrentTechnician,
  getByUser,
};

export default projectCurrentService;
