import React, { useEffect, useState } from "react";
import { NavigateFunction, useNavigate } from "react-router-dom";
import styled from "@emotion/styled";
import { spacing, SpacingProps } from "@mui/system";
import { Chip as MuiChip, Stack } from "@mui/material";
import { ColumnType } from "src/types/enhancedTable";
import { ITechnicianQuizExamList } from "src/ts/interfaces";
import { GridActionButton } from "src/components/gridControls";
import useLoading from "src/hooks/useLoading";
import { technicianQuizExamService } from "src/services";
import ViewExamPopup from "./component/ViewExamPopup";
import LocalEnhancedTable from "src/components/localTable/LocalTable";
import { useLog } from "src/hooks";

interface ChipProps extends SpacingProps {
  component?: React.ElementType;
  href?: string;
  icon?: JSX.Element | null;
}

const Chip = styled(MuiChip)<ChipProps>(spacing);

const getColumns = (
  navigate: NavigateFunction,
  setOpenView: (val: boolean) => void,
  setSelectedExam: (val: ITechnicianQuizExamList) => void
): ColumnType[] => [
  {
    id: "id",
    label: "Id",
    type: "string",
    sort: true,
    callback: (row: ITechnicianQuizExamList) => {
      return row.id;
    },
  },
  {
    id: "name",
    label: "Name",
    type: "custom",
    sort: true,
    callback: (row: ITechnicianQuizExamList) => <div>{row.userFullName} </div>,
  },
  {
    id: "",
    label: "Pass/Fail",
    type: "custom",
    sort: true,
    callback: (row: ITechnicianQuizExamList) => {
      return (
        <>
          {row.statusExam === 1 ? (
            <Chip label="Pass" color="success" m={1} size="small" />
          ) : row.statusExam === 0 ? (
            <Chip label="Fail" color="error" m={1} size="small" />
          ) : (
            ""
          )}
        </>
      );
    },
  },
  {
    id: "score",
    label: "Score",
    type: "string",
    sort: true,
  },
  {
    id: "startDate",
    label: "Date Scheduler",
    type: "date",
    format: "MM/DD/YYYY",
    sort: true,
  },
  {
    id: "endDate",
    label: "Finish Date",
    type: "utcDate",
    format: "MM/DD/YYYY",
    sort: true,
  },
  {
    id: "x",
    label: "Actions",
    type: "custom",
    sort: false,
    callback: (row: ITechnicianQuizExamList) => (
      <div>
        <Stack direction="row">
          <GridActionButton
            type="view"
            onClick={() => {
              setOpenView(true);
              setSelectedExam(row);
            }}
          />
        </Stack>
      </div>
    ),
  },
];

interface techniciansExamProps {
  quizId: number;
  filter?: any;
}

function TechniciansExams(props: techniciansExamProps) {
  const { log } = useLog();
  let navigate = useNavigate();
  const { isLoading, startRequest, endRequest } = useLoading();
  const [openView, setOpenView] = useState(false);
  const [selectedExam, setSelectedExam] = useState<ITechnicianQuizExamList>();
  const [dataSet, setDataSet] = useState<ITechnicianQuizExamList[]>();
  let columns = getColumns(navigate, setOpenView, setSelectedExam);

  useEffect(() => {
    const getData = async () => {
      try {
        startRequest("GetQuiz");
        const request = await technicianQuizExamService.getByQuizExamQuizId(
          props.quizId
        );
        setDataSet(request.data);
        endRequest("GetQuiz");
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
        endRequest("GetQuiz");
      }
    };

    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <LocalEnhancedTable<ITechnicianQuizExamList>
        columns={columns}
        data={dataSet ?? []}
        defaultSortColumn="id"
        orderColumn="desc"
        defaultRowPerPage={10}
        showSkeleton={isLoading}
        query={props.filter}
        filterCustom={["userFullName"]}
      />
      <ViewExamPopup
        showPopup={openView}
        setShowPopup={setOpenView}
        quizExamId={selectedExam?.id}
      />
    </>
  );
}

export default TechniciansExams;
