import axios from "src/utils/axios";
import { IFanDTO } from "src/ts/interfaces";

const getByMotor = (id: number) => {
  return axios.get<IFanDTO>(`Fan/ByMotor/${id}`);
};

const fanService = {
  getByMotor,
};

export default fanService;
