import React, { useState } from "react";
import { Box, Stack } from "@mui/material";
import { FormButton, FormCancelButton } from "./formControls";
import Popup from "./Popup";
import { ICompanyData } from "../ts/interfaces";
import { Check as CheckIcon } from "@mui/icons-material/";

interface IDialogMessagePopupProps {
  title: string;
  text: React.ReactNode;
  showPopup: boolean;
  setShowPopup: (val: boolean) => void;
  currentValues?: ICompanyData;
  onSave?: () => void;
  isSubmitting: boolean;
  size?: "xs" | "sm" | "md" | "lg" | "xl";
  children?: React.ReactNode;
  disabled?: boolean;
  onCancel?: () => void;
  whiteSpace?: boolean;
  hideCancel?: boolean;
  acceptTextButton?: string;
  hideAccept?: boolean;
  cancelTextButton?: string;
  disableClickOutside?: boolean;
}

const DialogMessagePopup = ({
  title,
  text,
  showPopup,
  setShowPopup,
  currentValues,
  onSave,
  isSubmitting,
  size,
  children,
  disabled,
  onCancel,
  whiteSpace,
  hideCancel,
  acceptTextButton,
  hideAccept,
  cancelTextButton,
  disableClickOutside,
}: IDialogMessagePopupProps) => {
  const [isSubmittingButton, setIsSubmittingButton] = useState(false);
  const handleSave = async () => {
    setIsSubmittingButton(true);
    if (onSave) await onSave();
    setIsSubmittingButton(false);
    setShowPopup(false);
  };

  const handleClose = async () => {
    setShowPopup(false);
    if (onCancel) await onCancel();
  };

  return (
    <Popup
      title={title}
      openPopup={showPopup}
      setOpenPopup={setShowPopup}
      onClose={handleClose}
      hideClose={hideCancel}
      size={size ?? "sm"}
      disableClickOutside={disableClickOutside}
    >
      <Stack>
        <Box sx={{ whiteSpace: whiteSpace ? "pre" : "normal" }}>{text}</Box>
        {children}
      </Stack>

      <Stack
        direction="row"
        spacing={2}
        justifyContent="flex-end"
        alignItems="flex-start"
      >
        {hideCancel ? null : (
          <FormCancelButton
            onClick={handleClose}
            isSubmitting={isSubmittingButton || isSubmitting}
            text={cancelTextButton}
          />
        )}
        {!hideAccept && (
          <FormButton
            text={acceptTextButton ?? "Accept"}
            startIcon={<CheckIcon />}
            size="small"
            onClick={handleSave}
            disabled={disabled}
            color={"primary"}
            isSubmitting={isSubmittingButton || isSubmitting}
          />
        )}
      </Stack>
    </Popup>
  );
};

export default DialogMessagePopup;
