export class Validator {
  private message: string = "";
  private fieldValues: any;
  private fieldUnderValidation: string;
  private fieldExist: boolean = false;
  private isValid: boolean = true;
  private shouldValidate = true;

  constructor(fieldValues: any, fieldUnderValidation: string) {
    this.fieldValues = fieldValues;
    this.fieldUnderValidation = fieldUnderValidation;
    this.fieldExist = fieldValues.hasOwnProperty(fieldUnderValidation);
  }

  public isRequired(message: string) {
    if (this === undefined) {
      throw new Error("Something went wrong");
    }

    //The field doesn't exist
    if (!this.fieldExist || !this.shouldValidate) {
      this.message = "";
      return this;
    }

    //The field was already validated and we won't continue the validation workflow
    if (!this.isValid) {
      return this;
    }

    this.message = "";
    if (
      this.fieldValues[this.fieldUnderValidation] === "" ||
      this.fieldValues[this.fieldUnderValidation] === null
    ) {
      this.isValid = false;
      this.message = message;
    }
    return this;
  }

  public maxLength(maxlength: number, message: string) {
    //The field doesn't exist
    if (!this.fieldExist || !this.shouldValidate) {
      this.message = "";
      return this;
    }

    //The field was already validated and we won't continue the validation workflow
    if (!this.isValid) {
      return this;
    }

    if (
      this.fieldValues[this.fieldUnderValidation] !== null &&
      this.fieldValues[this.fieldUnderValidation].length > maxlength
    ) {
      this.isValid = false;
      this.message = message;
    }
    return this;
  }

  public minLength(minLength: number, message: string) {
    //The field doesn't exist
    if (!this.fieldExist || !this.shouldValidate) {
      this.message = "";
      return this;
    }

    //The field was already validated and we won't continue the validation workflow
    if (!this.isValid) {
      return this;
    }

    if (
      this.fieldValues[this.fieldUnderValidation] !== null &&
      this.fieldValues[this.fieldUnderValidation].length < minLength
    ) {
      this.isValid = false;
      this.message = message;
    }
    return this;
  }

  public selectedOption(
    invalidOption: string | number | null,
    message: string
  ) {
    //The field doesn't exist
    if (!this.fieldExist || !this.shouldValidate) {
      this.message = "";
      return this;
    }

    //The field was already validated and we won't continue the validation workflow
    if (!this.isValid) {
      return this;
    }

    if (typeof invalidOption === "number") {
      if (
        this.fieldValues[this.fieldUnderValidation] ===
        (invalidOption as number)
      ) {
        this.isValid = false;
        this.message = message;
      }
    } else if (typeof invalidOption === "string") {
      if (
        this.fieldValues[this.fieldUnderValidation] ===
        (invalidOption as string)
      ) {
        this.isValid = false;
        this.message = message;
      }
    } else if (invalidOption == null) {
      if (this.fieldValues[this.fieldUnderValidation] === null) {
        this.isValid = false;
        this.message = message;
      }
    }

    return this;
  }

  public validateIf(expression: boolean) {
    this.shouldValidate = expression;
    return this;
  }

  public emailFormat(message: string) {
    if (!this.fieldExist || !this.shouldValidate) {
      this.message = "";
      return this;
    }

    //The field was already validated and we won't continue the validation workflow
    if (!this.isValid) {
      return this;
    }

    const validateEmail = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
      this.fieldValues[this.fieldUnderValidation]
    );

    if (!validateEmail) {
      this.message = message;
    }
    return this;
  }

  public phoneFormat(message: string) {
    if (!this.fieldExist || !this.shouldValidate) {
      this.message = "";
      return this;
    }

    //The field was already validated and we won't continue the validation workflow
    if (!this.isValid) {
      return this;
    }

    const validatePhone = /(\d)(?=(\d{3})+(?!\d))/g.test(
      this.fieldValues[this.fieldUnderValidation]
    );

    if (!validatePhone) {
      this.message = message;
    }
    return this;
  }

  public greatThan(value: number, message: string) {
    //The field doesn't exist
    if (!this.fieldExist || !this.shouldValidate) {
      this.message = "";
      return this;
    }

    //The field was already validated and we won't continue the validation workflow
    if (!this.isValid) {
      return this;
    }

    if (this.fieldValues[this.fieldUnderValidation] <= value) {
      this.isValid = false;
      this.message = message;
    }
    return this;
  }

  public greatThanOrEquals(value: number, message: string) {
    //The field doesn't exist
    if (!this.fieldExist || !this.shouldValidate) {
      this.message = "";
      return this;
    }

    //The field was already validated and we won't continue the validation workflow
    if (!this.isValid) {
      return this;
    }

    if (this.fieldValues[this.fieldUnderValidation] < value) {
      this.isValid = false;
      this.message = message;
    }
    return this;
  }

  public lessThan(value: number, message: string) {
    //The field doesn't exist
    if (!this.fieldExist || !this.shouldValidate) {
      this.message = "";
      return this;
    }

    //The field was already validated and we won't continue the validation workflow
    if (!this.isValid) {
      return this;
    }

    if (this.fieldValues[this.fieldUnderValidation] >= value) {
      this.isValid = false;
      this.message = message;
    }
    return this;
  }

  public lessThanOrEquals(value: number, message: string) {
    //The field doesn't exist
    if (!this.fieldExist || !this.shouldValidate) {
      this.message = "";
      return this;
    }

    //The field was already validated and we won't continue the validation workflow
    if (!this.isValid) {
      return this;
    }

    if (this.fieldValues[this.fieldUnderValidation] > value) {
      this.isValid = false;
      this.message = message;
    }
    return this;
  }

  public toString() {
    return this.message;
  }
}
