import React from "react";
import styled from "@emotion/styled";
import { Helmet } from "react-helmet-async";

import { Typography } from "@mui/material";

const Wrapper = styled.div`
  padding: ${(props) => props.theme.spacing(6)};
  text-align: center;
  background: transparent;

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)};
  }
`;

function PageInvalidPermissions() {
  return (
    <Wrapper>
      <Helmet title="Invalid Permission" />
      <Typography component="h1" variant="h1" align="center" gutterBottom>
        -
      </Typography>
      <Typography component="h2" variant="h5" align="center" gutterBottom>
        Invalid Permissions.
      </Typography>
      <Typography component="h2" variant="body1" align="center" gutterBottom>
        Invalid Permissions.
      </Typography>
    </Wrapper>
  );
}

export default PageInvalidPermissions;
