import axios from "src/utils/axios";
import { IReportType } from "src/ts/interfaces";

const getReportTypeByProjectType = (projectId: number) => {
  return axios.get<IReportType[]>(
    `ReportType/reportsByProjectType?projectId=${projectId}`
  );
};

const getReportTypeById = (id: number) => {
  return axios.get<IReportType>(`ReportType/${id}`);
};

const getReportTypeByCode = (code: string) => {
  return axios.get<IReportType>(`ReportType?code=${code}`);
};

const getReportTypeGrouped = () => {
  return axios.get<IReportType[]>(`ReportType/grouped`);
};

const reportTypeService = {
  getReportTypeByProjectType,
  getReportTypeById,
  getReportTypeByCode,
  getReportTypeGrouped,
};

export default reportTypeService;
