import { Box, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { FormButton } from "src/components/formControls";
import Popup from "src/components/Popup";
import appDownloadService from "src/services/appDownloadService";
import { IAppDowload } from "src/ts/interfaces/appDownload";

interface GPSAppModalProps {
  showGPSApp: boolean;
  setShowGPSApp: (val: boolean) => void;
}

const GPSAppPopUp = (props: GPSAppModalProps) => {
  const { showGPSApp, setShowGPSApp } = props;
  const [getDownladInfo, setGetDownladInfo] = useState<IAppDowload[]>([]);
  const [svgInfoIOS, setSvgInfoIOS] = useState("");
  const [svgInfoAndroid, setSvgInfoAndroid] = useState("");

  useEffect(() => {
    const getAppInfo = async () => {
      const response = await appDownloadService.getDownloadApp();
      setGetDownladInfo(response.data);
      setSvgInfoIOS(atob(response.data[0]?.qrCodeBase64));
      setSvgInfoAndroid(atob(response.data[1]?.qrCodeBase64));
    };

    getAppInfo();
  }, []);

  const cancelHandler = () => {
    setShowGPSApp(false);
  };

  return (
    <Popup
      openPopup={showGPSApp}
      setOpenPopup={setShowGPSApp}
      title={"Information"}
      onClose={cancelHandler}
      size="md"
    >
      <Stack>
        <Typography align="center" ml={3} mr={3}>
          <b>
            AMP Auditors app is continuously synchronized with AMP's software,
            registering time and location of the technicians and auditors
            related to the specific building and their assigned project.
          </b>
        </Typography>
        {getDownladInfo[0]?.requiresExpoGo && (
          <Typography align="center" mt={5} ml={15} mr={15}>
            <b>
              {" "}
              To run the AMP Auditors App on your device, you'll first need to
              install the Expo Go App. Use the following links to download Expo
              Go depending on your device's operating system.
            </b>
          </Typography>
        )}

        {getDownladInfo[0]?.requiresExpoGo && (
          <Box display="flex" justifyContent="center">
            <Stack direction="row">
              <a
                href="https://itunes.apple.com/app/apple-store/id982107779"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  alt=""
                  style={{ height: "40px", marginTop: "12px" }}
                  src="/static/img/app-store.png"
                />
              </a>
              <img
                alt=""
                style={{ height: "64px" }}
                src="/static/img/expogo.png"
              />
              <a
                href="https://play.google.com/store/apps/details?id=host.exp.exponent&referrer=www"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  alt=""
                  style={{ height: "60px", marginBottom: "4px" }}
                  src="/static/img/google-play-badge.png"
                />
              </a>
            </Stack>
          </Box>
        )}
        {getDownladInfo[0]?.requiresExpoGo && (
          <Typography align="center" ml={15} mr={15}>
            <b>
              After installing Expo Go, please scan the QR code that corresponds
              to your device's operating system.
            </b>
          </Typography>
        )}
        <Box display="flex" justifyContent="center">
          <Stack direction="row">
            <div
              dangerouslySetInnerHTML={{ __html: svgInfoIOS }}
              style={{ marginRight: "25px" }}
            />
            <img
              alt=""
              style={{ height: "128px", marginTop: "30px" }}
              src="/static/img/scanqr.jpeg"
            />
            <div
              dangerouslySetInnerHTML={{ __html: svgInfoAndroid }}
              style={{ marginLeft: "25px" }}
            />
          </Stack>
        </Box>
        <Box display="flex" justifyContent="center">
          <img
            alt=""
            style={{ height: "48px", marginRight: "130px" }}
            src="/static/img/appleIcon.png"
          />
          <img
            alt=""
            style={{ height: "48px", marginLeft: "130px" }}
            src="/static/img/androidIcon.jpeg"
          />
        </Box>
      </Stack>
      <>
        <Stack
          direction="row"
          spacing={2}
          justifyContent="center"
          alignItems="center"
        >
          <FormButton
            onClick={cancelHandler}
            text="Close"
            color="error"
            size="medium"
          />
        </Stack>
      </>
    </Popup>
  );
};

export default GPSAppPopUp;
