import Popup from "src/components/Popup";
import { FormButton, FormTextArea } from "src/components/formControls";
import { Stack, Typography } from "@mui/material";
import { useForm, useLog } from "src/hooks";
import { useEffect, useState } from "react";
import {
  DataRequestTransfer,
  IRequestTransfer,
} from "src/ts/interfaces/requestTransfer";
import RequestTransferTechnicianService from "src/services/RequestTransferTechnicianService";

interface TransferDecisionProps {
  transferDecisionShow: boolean;
  setTransferDecisionShow: (val: boolean) => void;
  refreshPage: boolean;
  setRefreshPage: (val: boolean) => void;
  currentTransferRow: DataRequestTransfer;
  decision: string;
}

const initialValues = {
  comments: "",
};

export default function TransferDecision({
  transferDecisionShow,
  setTransferDecisionShow,
  refreshPage,
  setRefreshPage,
  currentTransferRow,
  decision,
}: TransferDecisionProps) {
  const [data, setData] = useState<IRequestTransfer>();
  const { log } = useLog();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const dataInfo = async () => {
      const response = await RequestTransferTechnicianService.getTransferInfo(
        currentTransferRow?.guid
      );
      setData(response.data);
    };
    dataInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { values, handleInputChange } = useForm(initialValues, true, []);

  const accepthandlerAll = async () => {
    try {
      setIsLoading(true);
      await RequestTransferTechnicianService.putDecisionTransfer(
        currentTransferRow.guid,
        decision,
        values.comments
      );
      log.success(`The transfer was ${decision}ed`);
      setTransferDecisionShow(false);
      setRefreshPage(!refreshPage);
    } catch (error: any) {
      log.error(error?.message?.exceptionMessage ?? "Something went wrong");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Popup
        title={"Technician transfer request"}
        openPopup={transferDecisionShow}
        setOpenPopup={setTransferDecisionShow}
        onClose={() => {
          setTransferDecisionShow(false);
        }}
        size="sm"
        disableClickOutside={true}
        isSubmitting={isLoading}
      >
        <>
          <Typography>
            {decision === "accept"
              ? "Are you certain you want to accept this request?"
              : "Are you certain you want to reject this request?"}
          </Typography>
          <br />
          {data !== null && data?.project !== null && decision === "accept" && (
            <>
              <Typography>
                {`This technician is currently assigned to project ${data?.project?.name} and will not be able to enter any additional information.`}
              </Typography>
              <br />
            </>
          )}
          {decision !== "accept" && (
            <FormTextArea
              name="comments"
              value={values.comments}
              onChange={handleInputChange}
              label="Comments"
              placeholder=""
            />
          )}
        </>
        <Stack direction="row" spacing={3}>
          <FormButton
            size="small"
            text="Close"
            variant="outlined"
            onClick={() => {
              setTransferDecisionShow(false);
            }}
            isSubmitting={isLoading}
          />
          <FormButton
            size="small"
            text="Accept"
            color="primary"
            onClick={() => {
              accepthandlerAll();
            }}
            isSubmitting={isLoading}
            disabled={decision !== "accept" && !values.comments ? true : false}
          />
        </Stack>
      </Popup>
    </>
  );
}
