export const getDescription = (
  zeroToleranceSpacingBetween: boolean,
  concernsOfComponentDamage: boolean
) => {
  if (zeroToleranceSpacingBetween)
    return "Component Drop can't be performed due to Zero Tolerance Spacing Between.";
  if (concernsOfComponentDamage)
    return "Component Drop can't be performed due to Concerns of Drilling and Potential Component Damage.";
  return "";
};
