import { Input, TextField } from "@mui/material";

interface SpreadSheetInputProps {
  name: string;
  value?: string;
  handleFocus?: (e: any) => void;
  handleBlur?: (e: any) => void;
  onChange?: (e: any) => void;
  inputRef?: React.RefObject<HTMLInputElement>;
  disabled?: boolean;
  borderColor?: string;
  placeholder?: string;
}

const SpreadSheetInput = ({
  handleFocus,
  handleBlur,
  inputRef,
  name,
  value,
  onChange,
  disabled,
  borderColor,
  placeholder,
}: SpreadSheetInputProps) => {
  return (
    <TextField
      name={name}
      inputRef={inputRef}
      fullWidth={true}
      placeholder={placeholder}
      size="small"
      //disableUnderline={true}
      onFocus={handleFocus}
      onBlur={handleBlur}
      // onChange={onChange}
      value={value}
      disabled={disabled}
      autoComplete="off"
      // inputProps={{
      //   disableUnderline: true, // <== added this
      // }}
      style={{
        // outline: 0,
        //border: "none",
        // background: "none",
        // borderRadius: "-1px",
        width: "100%",
        // height: "30px",
        float: "right",
        display: "inline-block",
        paddingLeft: "8px",
        paddingRight: "8px",
        // borderColor: borderColor,
        // borderStyle: "solid",
        // borderWidth: "2px",
      }}
    />
  );
};

export default SpreadSheetInput;
