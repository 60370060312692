/* eslint-disable react-hooks/exhaustive-deps */
import {
  CardContent,
  Grid,
  Divider as MuiDivider,
  Card as MuiCard,
  ButtonGroup,
  Button,
  Tabs,
  Tab,
  Alert,
} from "@mui/material";
import { Stack, spacing } from "@mui/system";

import styled from "@emotion/styled";
import HeaderStudyPage from "src/components/page/HeaderStudyPage";
import { SyntheticEvent, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  useAsyncQuery,
  useLog,
  useAuth,
  useSystemOfMeasurement,
} from "src/hooks";
import {
  FormCheckBox,
  FormNumeric2,
  FormSelect,
  FormText,
} from "src/components/formControls";
import useFormTyped from "src/hooks/useFormTyped";
import {
  IPumpReportViewModelDTO,
  IReportType,
  IItemKeyValueDTO,
  IPumpDTO,
  IPumpSystemStrainerRequiredDTO,
  IPumpSystemStrainerInstalledDTO,
  IPumpSystemStrainerCheckeCleanDTO,
  IPumpSystemPipingProperlyFilledDTO,
  IPumpSystemPipingProperlyVentedDTO,
} from "src/ts/interfaces";
import {
  IProjectReport,
  ProjectDTO,
} from "src/ts/interfaces/project/projectDto";
import projectReportService from "src/services/study/projectReportService";
import { steps } from "../const/consts";
import { FooterButtons } from "../../components/FooterButtons";
import ElectricMeterIcon from "@mui/icons-material/ElectricMeter";
import CycloneIcon from "@mui/icons-material/Cyclone";
import {
  IFlowDesignDTO,
  IPumpManufacturerDTO,
  IPumpTypeDTO,
} from "src/ts/interfaces/catalogs";
import { IDefaultComboBoxCatalog } from "src/ts/interfaces";
import Motor from "../../components/Motor";
import Popup from "src/components/Popup";
import { Validator } from "src/ts/types/Validator";
import IncompleteDataDialog from "../../components/IncompleteDataDialog";
import pumpReportService from "src/services/study/pumpReportService";
import reportTypeService from "src/services/study/reportTypeService";
import pumpService from "src/services/study/pumpService";
import pumpTypeService from "src/services/catalogs/pumpTypeService";
import pumpManufacturerService from "src/services/catalogs/pumpManufacturerService";
import flowDesignService from "src/services/catalogs/flowDesignService";
import pumpSystemStrainerRequiredService from "src/services/catalogs/pumpSystemStrainerRequiredService";
import pumpSystemStrainerInstalledService from "src/services/catalogs/pumpSystemStrainerInstalledService";
import pumpSystemStrainerCheckeCleanService from "src/services/catalogs/pumpSystemStrainerCheckeCleanService";
import pumpSystemPipingProperlyFilledService from "src/services/catalogs/pumpSystemPipingProperlyFilledService";
import pumpSystemPipingProperlyVentedService from "src/services/catalogs/pumpSystemPipingProperlyVentedService";
import projectService from "src/services/project/projectService";
import StudyWizard from "../../components/StudyWizard";

const initialValues: any = {};

const _TAB_PMP = 0;
const _TAB_MT = 1;

const Divider = styled(MuiDivider)(spacing);
const Card = styled(MuiCard)(spacing);

const PumpStep3 = () => {
  const { log } = useLog();
  const { user } = useAuth();
  const { systemOfMeasurement } = useSystemOfMeasurement(true);
  const params = useParams<{ id: string; step: string }>();
  const id = parseInt(params?.id === undefined ? "0" : params?.id);
  const [isUpdating, setIsUpdating] = useState(false);
  const [title, setTitle] = useState<string>();
  const [code, setCode] = useState<string>();
  const [selectedPump, setSelectedPump] = useState(0);
  const [selectedPumpIndex, setSelectedPumpIndex] = useState(0);
  const [tabSelected, setTabSelected] = useState(0);
  const [pumpValues, setPumpValues] = useState<IPumpDTO>();
  const [triggerMotorSave, setTriggerMotorSave] = useState(false);
  const [triggerMoveNext, setTriggerMoveNext] = useState(false);
  const [triggerMoveBack, setTriggerMoveBack] = useState(false);
  const [pumpManufacturers, setPumpManufacturers] =
    useState<IDefaultComboBoxCatalog[]>();
  const [pumpTypes, setPumpTypes] = useState<IDefaultComboBoxCatalog[]>();
  const [flowDesigns, setFlowDesigns] = useState<IDefaultComboBoxCatalog[]>();
  const [pumpSystemStrainerRequireds, setPumpSystemStrainerRequireds] =
    useState<IDefaultComboBoxCatalog[]>();
  const [pumpSystemStrainerInstalleds, setPumpSystemStrainerInstalleds] =
    useState<IDefaultComboBoxCatalog[]>();
  const [pumpSystemStrainerCheckeCleans, setPumpSystemStrainerCheckeCleans] =
    useState<IDefaultComboBoxCatalog[]>();
  const [pumpSystemPipingProperlyFilleds, setPumpSystemPipingProperlyFilleds] =
    useState<IDefaultComboBoxCatalog[]>();
  const [pumpSystemPipingProperlyVenteds, setPumpSystemPipingProperlyVenteds] =
    useState<IDefaultComboBoxCatalog[]>();
  const [openIncompleteDataDialog, setOpenIncompleteDataDialog] =
    useState(false);
  const [gpmTolerance, setGpmTolerance] = useState<number>();
  const [gpmDiversityDialogOpen, setGpmDiversityDialogOpen] = useState(false);
  const [newTotalDeisgnGpm, setNewTotalDesignGpm] = useState(0);
  const [gpmPOD, setGpmPOD] = useState(0);
  const [activePumps, setActivePumps] = useState<IItemKeyValueDTO[]>();

  let navigate = useNavigate();

  const validate: any = (
    pumpFieldValues: IPumpDTO = pumpValues as IPumpDTO
  ) => {
    let temp: Record<string, string> = { ...errors };

    if (disableStudy()) return true;

    if (tabSelected === _TAB_PMP) {
      temp.manufacturerId = new Validator(pumpFieldValues, "manufacturerId")
        .isRequired("This field is required.")
        .greatThan(0, "This field is required.")
        .toString();
      temp.otherManufacturer = new Validator(
        pumpFieldValues,
        "otherManufacturer"
      )
        .validateIf(
          catalogValueOtherEval(
            pumpFieldValues.manufacturerId as number,
            pumpManufacturers as IDefaultComboBoxCatalog[]
          )
        )
        .isRequired("This field is required.")
        .toString();
      temp.shortDescription = new Validator(pumpFieldValues, "shortDescription")
        .isRequired("This field is required.")
        .toString();
      temp.pumpTypeId = new Validator(pumpFieldValues, "pumpTypeId")
        .isRequired("This field is required.")
        .greatThan(0, "This field is required.")
        .toString();
      temp.flowDesignId = new Validator(pumpFieldValues, "flowDesignId")
        .isRequired("This field is required.")
        .greatThan(0, "This field is required.")
        .toString();
      temp.otherFlowDesign = new Validator(pumpFieldValues, "otherFlowDesign")
        .validateIf(
          catalogValueOtherEval(
            pumpFieldValues.flowDesignId as number,
            flowDesigns as IDefaultComboBoxCatalog[]
          )
        )
        .isRequired("This field is required.")
        .toString();
      temp.pumpSystemStrainerRequiredId = new Validator(
        pumpFieldValues,
        "pumpSystemStrainerRequiredId"
      )
        .isRequired("This field is required.")
        .greatThan(0, "This field is required.")
        .toString();
      temp.pumpSystemStrainerInstalledId = new Validator(
        pumpFieldValues,
        "pumpSystemStrainerInstalledId"
      )
        .isRequired("This field is required.")
        .greatThan(0, "This field is required.")
        .toString();
      temp.pumpSystemStrainerCheckeCleanId = new Validator(
        pumpFieldValues,
        "pumpSystemStrainerCheckeCleanId"
      )
        .isRequired("This field is required.")
        .greatThan(0, "This field is required.")
        .toString();
      temp.pumpSystemPipingProperlyFilledId = new Validator(
        pumpFieldValues,
        "pumpSystemPipingProperlyFilledId"
      )
        .isRequired("This field is required.")
        .greatThan(0, "This field is required.")
        .toString();
      temp.pumpSystemPipingProperlyVentedId = new Validator(
        pumpFieldValues,
        "pumpSystemPipingProperlyVentedId"
      )
        .isRequired("This field is required.")
        .greatThan(0, "This field is required.")
        .toString();
    }

    setErrors({
      ...temp,
    });

    if (pumpFieldValues === pumpValues) {
      return Object.values(temp).every((x) => x === "");
    }
  };

  const handleTabChange = (event: SyntheticEvent, newValue: number) => {
    setTabSelected(newValue);
  };

  const { values, setValues, errors, setErrors } =
    useFormTyped<IPumpReportViewModelDTO>(initialValues, false, validate);

  const activeStep = 2;

  const { execute: executeReport, data: dataReport } =
    useAsyncQuery<IProjectReport>(projectReportService.report);

  const { execute: executePumpMfg, isLoading: isLoadingPumpMfgs } =
    useAsyncQuery<IPumpManufacturerDTO[]>(pumpManufacturerService.get, {
      onSuccess: (dataResult) => {
        const getData = async () => {
          const mfgs = (dataResult as IPumpManufacturerDTO[]).map((mfg) => ({
            key: mfg.id as number,
            value: mfg.name as any,
          }));
          setPumpManufacturers(mfgs);
        };
        getData();
      },
    });

  const { execute: executePumpType, isLoading: isLoadingPumpTypes } =
    useAsyncQuery<IPumpTypeDTO[]>(pumpTypeService.get, {
      onSuccess: (dataResult) => {
        const getData = async () => {
          const mfgs = (dataResult as IPumpTypeDTO[]).map((mfg) => ({
            key: mfg.id as number,
            value: mfg.name as any,
          }));
          setPumpTypes(mfgs);
        };
        getData();
      },
    });

  const { execute: executeFlowDesign, isLoading: isLoadingFlowDesigns } =
    useAsyncQuery<IFlowDesignDTO[]>(flowDesignService.get, {
      onSuccess: (dataResult) => {
        const getData = async () => {
          const mfgs = (dataResult as IFlowDesignDTO[]).map((mfg) => ({
            key: mfg.id as number,
            value: mfg.name as any,
          }));
          setFlowDesigns([{ key: 0, value: "Select an option" }, ...mfgs]);
        };
        getData();
      },
    });

  const {
    execute: executePumpSystemStrainerRequired,
    isLoading: isLoadingPumpSystemStrainerRequireds,
  } = useAsyncQuery<IPumpSystemStrainerRequiredDTO[]>(
    pumpSystemStrainerRequiredService.get,
    {
      onSuccess: (dataResult) => {
        const getData = async () => {
          const mfgs = (dataResult as IPumpSystemStrainerRequiredDTO[]).map(
            (mfg) => ({
              key: mfg.id as number,
              value: mfg.name as any,
            })
          );
          setPumpSystemStrainerRequireds([
            { key: 0, value: "Select an option" },
            ...mfgs,
          ]);
        };
        getData();
      },
    }
  );

  const {
    execute: executePumpSystemStrainerInstalled,
    isLoading: isLoadingPumpSystemStrainerInstalleds,
  } = useAsyncQuery<IPumpSystemStrainerInstalledDTO[]>(
    pumpSystemStrainerInstalledService.get,
    {
      onSuccess: (dataResult) => {
        const getData = async () => {
          const mfgs = (dataResult as IPumpSystemStrainerInstalledDTO[]).map(
            (mfg) => ({
              key: mfg.id as number,
              value: mfg.name as any,
            })
          );
          setPumpSystemStrainerInstalleds([
            { key: 0, value: "Select an option" },
            ...mfgs,
          ]);
        };
        getData();
      },
    }
  );

  const {
    execute: executePumpSystemStrainerCheckeClean,
    isLoading: isLoadingPumpSystemStrainerCheckeCleans,
  } = useAsyncQuery<IPumpSystemStrainerCheckeCleanDTO[]>(
    pumpSystemStrainerCheckeCleanService.get,
    {
      onSuccess: (dataResult) => {
        const getData = async () => {
          const mfgs = (dataResult as IPumpSystemStrainerCheckeCleanDTO[]).map(
            (mfg) => ({
              key: mfg.id as number,
              value: mfg.name as any,
            })
          );
          setPumpSystemStrainerCheckeCleans([
            { key: 0, value: "Select an option" },
            ...mfgs,
          ]);
        };
        getData();
      },
    }
  );

  const {
    execute: executePumpSystemPipingProperlyFilled,
    isLoading: isLoadingPumpSystemPipingProperlyFilleds,
  } = useAsyncQuery<IPumpSystemPipingProperlyFilledDTO[]>(
    pumpSystemPipingProperlyFilledService.get,
    {
      onSuccess: (dataResult) => {
        const getData = async () => {
          const mfgs = (dataResult as IPumpSystemPipingProperlyFilledDTO[]).map(
            (mfg) => ({
              key: mfg.id as number,
              value: mfg.name as any,
            })
          );
          setPumpSystemPipingProperlyFilleds([
            { key: 0, value: "Select an option" },
            ...mfgs,
          ]);
        };
        getData();
      },
    }
  );

  const {
    execute: executePumpSystemPipingProperlyVented,
    isLoading: isLoadingPumpSystemPipingProperlyVenteds,
  } = useAsyncQuery<IPumpSystemPipingProperlyVentedDTO[]>(
    pumpSystemPipingProperlyVentedService.get,
    {
      onSuccess: (dataResult) => {
        const getData = async () => {
          const mfgs = (dataResult as IPumpSystemPipingProperlyVentedDTO[]).map(
            (mfg) => ({
              key: mfg.id as number,
              value: mfg.name as any,
            })
          );
          setPumpSystemPipingProperlyVenteds([
            { key: 0, value: "Select an option" },
            ...mfgs,
          ]);
        };
        getData();
      },
    }
  );

  const { execute: executePump, isLoading: isLoadingPump } =
    useAsyncQuery<IPumpDTO>(pumpService.getById, {
      onSuccess: (dataResult) => {
        const getData = async () => {
          setPumpValues({
            ...dataResult,
            designGpm:
              !dataResult.designGpm &&
              activePumps?.length === 1 &&
              (values.pumps as IItemKeyValueDTO[])[selectedPumpIndex].key ===
                activePumps[0].key
                ? values?.systemGpmDesign
                : dataResult.designGpm,
          });
        };
        getData();
      },
    });

  const { execute, isLoading } = useAsyncQuery<IPumpReportViewModelDTO>(
    pumpReportService.getById,
    {
      onSuccess: (dataResult) => {
        const getData = async () => {
          setValues({ ...dataResult });
          setActivePumps(
            dataResult.pumps.filter((x: IItemKeyValueDTO) => !x.standByLag)
          );
          await systemOfMeasurement.getByProject(
            dataResult.projectId ?? values.projectId
          );
          window.scrollTo(0, 0);
        };
        getData();
      },
    }
  );

  const { execute: executeReportType } = useAsyncQuery<IReportType>(
    reportTypeService.getReportTypeById,
    {
      onSuccess: (dataResult) => {
        const getData = async () => {
          setCode(dataResult.code);
          setTitle(dataResult.name);
        };
        getData();
      },
    }
  );

  const { execute: executeTolerances } = useAsyncQuery<ProjectDTO>(
    projectService.getProjectTolerancesByReportId,
    {
      onSuccess: (dataResult) => {
        const getData = async () => {
          setGpmTolerance(dataResult.requiredGPMSystemToleranceTop);
        };
        getData();
      },
    }
  );

  const disableStudy = () => {
    return (
      values?.isComplete ||
      (dataReport?.isInUse && dataReport?.isInUseById !== user?.userId) ||
      dataReport?.isPartOfProject === false
    );
  };

  useEffect(() => {
    if (values?.reportTypeId) executeReportType(values?.reportTypeId);
  }, [values?.reportTypeId]);

  useEffect(() => {
    const getData = async () => {
      try {
        await executePumpMfg();
        await executePumpType();
        await executeFlowDesign();
        await executePumpSystemStrainerRequired();
        await executePumpSystemStrainerInstalled();
        await executePumpSystemStrainerCheckeClean();
        await executePumpSystemPipingProperlyFilled();
        await executePumpSystemPipingProperlyVented();
        await execute(id);
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      }
    };

    if (id !== 0) getData();
  }, [id]);

  useEffect(() => {
    if (values.id) {
      setErrors({});
      setSelectedPump(
        (values.pumps as IItemKeyValueDTO[])[selectedPumpIndex].key
      );
      executePump((values.pumps as IItemKeyValueDTO[])[selectedPumpIndex].key);
    }
  }, [values, selectedPumpIndex]);

  useEffect(() => {
    const getData = async () => {
      try {
        await executeReport(id, code);
        await executeTolerances(id, code);
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      }
    };

    if (id !== 0 && code !== undefined) getData();
  }, [id, code]);

  const getPumpsGpm = async () => {
    try {
      if (disableStudy()) return false;
      const dataResult = (
        await pumpReportService.getDesignGpm(id, pumpValues?.id || 0)
      ).data;
      const currentPumpDesignGpm =
        typeof pumpValues?.designGpm === "string"
          ? parseFloat(pumpValues?.designGpm)
          : pumpValues?.designGpm;
      const newTotalDeisgnGpm = !pumpValues?.standByLag
        ? dataResult + (currentPumpDesignGpm || 0)
        : dataResult;
      setNewTotalDesignGpm(newTotalDeisgnGpm);
      const gpmDesignDiversity = values?.systemGpmDesign
        ? Math.abs(1 - (newTotalDeisgnGpm || 0) / values?.systemGpmDesign) * 100
        : 0;
      const gpmPOD = values?.systemGpmDesign
        ? (newTotalDeisgnGpm / values?.systemGpmDesign) * 100
        : 0;
      setGpmPOD(gpmPOD);
      const pumpReport = (await pumpReportService.getBaseById(id)).data;
      await pumpReportService.update(id, {
        ...pumpReport,
        systemTotalCombinedGPMDesign: newTotalDeisgnGpm,
        systemDiversityDesign: gpmDesignDiversity,
        systemDesignCombinedGpmPOD: gpmPOD,
      });
      if (
        gpmDesignDiversity > (gpmTolerance || 0) &&
        !values?.isNotAvailableSystemGpmDesign &&
        !values?.isComplete &&
        selectedPumpIndex === (values?.pumps as IItemKeyValueDTO[])?.length - 1
      ) {
        setGpmDiversityDialogOpen(true);
        return true;
      }
      return false;
    } catch (error: any) {
      log.error(
        error?.message?.exceptionMessage ??
          "Error updateing System Diversity Design"
      );
    }
  };

  const saveHandler = async () => {
    if (tabSelected === _TAB_PMP) {
      await savePumpHandler();
    }
    if (tabSelected === _TAB_MT) {
      setTriggerMotorSave(true);
    }
  };

  const savePumpHandler = async () => {
    try {
      if (!disableStudy()) {
        setIsUpdating(true);
        await pumpService.update(pumpValues?.id || 0, {
          ...pumpValues,
          flowDesignId:
            pumpValues?.flowDesignId === 0 ? null : pumpValues?.flowDesignId,
          pumpSystemStrainerRequiredId:
            pumpValues?.pumpSystemStrainerRequiredId === 0
              ? null
              : pumpValues?.pumpSystemStrainerRequiredId,
          pumpSystemStrainerInstalledId:
            pumpValues?.pumpSystemStrainerInstalledId === 0
              ? null
              : pumpValues?.pumpSystemStrainerInstalledId,
          pumpSystemStrainerCheckeCleanId:
            pumpValues?.pumpSystemStrainerCheckeCleanId === 0
              ? null
              : pumpValues?.pumpSystemStrainerCheckeCleanId,
          pumpSystemPipingProperlyFilledId:
            pumpValues?.pumpSystemPipingProperlyFilledId === 0
              ? null
              : pumpValues?.pumpSystemPipingProperlyFilledId,
          pumpSystemPipingProperlyVentedId:
            pumpValues?.pumpSystemPipingProperlyVentedId === 0
              ? null
              : pumpValues?.pumpSystemPipingProperlyVentedId,
        } as IPumpDTO);
        log.success("Pump was updated successfully");
        setIsUpdating(false);
      }
    } catch (error) {}
  };

  const nextStepHandler = async () => {
    if (tabSelected === _TAB_PMP) {
      if (!validate()) setOpenIncompleteDataDialog(true);
      else if (await getPumpsGpm()) return false;
      else await movingForwardHandler();
    }
    if (tabSelected === _TAB_MT) {
      setTriggerMoveNext(true);
    }
  };

  const clickYesIncompleteDialogHandle = async () => {
    setOpenIncompleteDataDialog(false);
    if (await getPumpsGpm()) return false;
    else await movingForwardHandler();
  };

  const movingForwardHandler = async () => {
    setOpenIncompleteDataDialog(false);
    setGpmDiversityDialogOpen(false);
    await savePumpHandler();
    setTabSelected(_TAB_MT);
    window.scrollTo(0, 0);
  };

  const motorMoveNextHandler = () => {
    if (selectedPumpIndex < (values.pumps as IItemKeyValueDTO[])?.length - 1) {
      setSelectedPumpIndex(selectedPumpIndex + 1);
      setTabSelected(_TAB_PMP);
      window.scrollTo(0, 0);
    } else {
      navigate(`/studies/pumpReport/step4/${id}`);
    }
  };

  const previousStepHandler = async () => {
    if (tabSelected === _TAB_MT) {
      setTriggerMoveBack(true);
    }
    if (tabSelected === _TAB_PMP) {
      await savePumpHandler();
      if (selectedPumpIndex > 0) {
        setSelectedPumpIndex(selectedPumpIndex - 1);
        setTabSelected(_TAB_MT);
        window.scrollTo(0, 0);
      } else {
        navigate(`/studies/pumpReport/step2/${id}`);
      }
    }
  };

  const motorMoveBackHandler = () => {
    setTabSelected(_TAB_PMP);
    window.scrollTo(0, 0);
  };

  const handlePumpValuesChange = (e: any) => {
    setPumpValues({
      ...(pumpValues as IPumpDTO),
      [e.target.name]: e.target.value,
    });
  };

  const catalogValueOtherEval = (
    id: number,
    catalog: IDefaultComboBoxCatalog[]
  ) => {
    if (catalog && id) {
      const [{ value }] = catalog.filter((x) => x.key === id);
      return value === "Other";
    }
    return false;
  };

  const loadPumpMotor = (index: number) => {
    setSelectedPumpIndex(index);
    setTabSelected(_TAB_PMP);
  };

  const filterNonLagPumps = () =>
    values?.pumps?.filter((x) => !x.standByLag).length;

  return (
    <>
      {/**Warnings section*/}
      <IncompleteDataDialog
        setOpenIncompleteDataDialog={setOpenIncompleteDataDialog}
        openIncompleteDataDialog={openIncompleteDataDialog}
        yesButtonClickHandler={clickYesIncompleteDialogHandle}
      />
      <Popup
        title="Warning"
        openPopup={gpmDiversityDialogOpen}
        setOpenPopup={setGpmDiversityDialogOpen}
        onClose={() => setGpmDiversityDialogOpen(false)}
      >
        <>
          <Alert severity="warning">
            <strong>
              The Total Combined Pump(s) Design {systemOfMeasurement.get("gpm")}{" "}
              exceeds the System Design {systemOfMeasurement.get("gpm")}
              /Diversity Tolerance.
            </strong>
            <br />
            System Design {systemOfMeasurement.get("gpm")}:{" "}
            <span style={{ color: "blue" }}>
              {values?.systemGpmDesign?.toLocaleString("en-US")}{" "}
              {systemOfMeasurement.get("gpm")}
            </span>
            <br />
            Pump(s) Total Combined Design {systemOfMeasurement.get("gpm")}:{" "}
            <span style={{ color: "red" }}>
              {newTotalDeisgnGpm.toLocaleString("en-US")}{" "}
              {systemOfMeasurement.get("gpm")}
            </span>
            <br />
            Pump(s) Total Combined Design {systemOfMeasurement.get("gpm")}{" "}
            Percentage:{" "}
            <span style={{ color: "red" }}>
              {gpmPOD.toLocaleString("en-US")}%
            </span>
          </Alert>
        </>
        <Stack direction="row" spacing={3}>
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => setGpmDiversityDialogOpen(false)}
            size="small"
          >
            No
          </Button>
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={movingForwardHandler}
            autoFocus
          >
            Yes
          </Button>
        </Stack>
      </Popup>
      {/**Warnings section end */}
      <HeaderStudyPage
        headerStudiesPage={{ code: code, system: values?.system, id: id }}
        parentText="Project"
        parentLink={`/app/ProjectStudies/${values?.projectId}/${user?.companyId}`}
      />
      <Divider my={6} />

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card mb={6}>
            <CardContent>
              <StudyWizard
                steps={steps}
                activeStep={activeStep}
                projectId={dataReport?.projectId}
                reportId={dataReport?.reportId}
                reportTypeId={dataReport?.reportTypeId}
              />
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <ButtonGroup
                    variant="outlined"
                    aria-label="Loading button group"
                  >
                    {values?.pumps?.map((pump, index) => (
                      <Button
                        key={pump.key}
                        disabled={selectedPump === pump.key}
                        onClick={() => loadPumpMotor(index)}
                      >
                        <CycloneIcon />
                        {`${pump.value}`}
                      </Button>
                    ))}
                  </ButtonGroup>
                </Grid>
                <Grid item xs={12}>
                  <Tabs
                    value={tabSelected}
                    onChange={handleTabChange}
                    aria-label="headsheet step 2 tabs"
                  >
                    <Tab
                      icon={<CycloneIcon />}
                      iconPosition="start"
                      label="Pump"
                    />
                    <Tab
                      icon={<ElectricMeterIcon />}
                      iconPosition="start"
                      label="Motor"
                    />
                  </Tabs>
                </Grid>
              </Grid>
              {tabSelected === _TAB_PMP && (
                <>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <h2>Pump Description</h2>
                    </Grid>
                    <Grid item xs={12}>
                      <FormCheckBox
                        size="medium"
                        name="standByLag"
                        label="Stand by/Lag"
                        onChange={handlePumpValuesChange}
                        value={pumpValues?.standByLag || false}
                        showSkeleton={isLoadingPump || isLoading}
                        disabled={
                          disableStudy() ||
                          (!pumpValues?.standByLag &&
                            (filterNonLagPumps() || 0) < 2)
                        }
                      />
                      <Alert severity="info">
                        {`(Only Lead Pump(s) are used for calculations${
                          values?.pumps?.length === 1
                            ? " - 2 Pumps or more required to activate)"
                            : ")"
                        }`}
                      </Alert>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <FormText
                        name="shortDescription"
                        label="Short description"
                        onChange={handlePumpValuesChange}
                        placeholder="IE: North, South, Left, Right, etc."
                        value={pumpValues?.shortDescription || ""}
                        showSkeleton={isLoadingPump || isLoading}
                        disabled={disableStudy()}
                        maxLength={8}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <FormText
                        name="equipmentLocation"
                        label="Equipment location"
                        onChange={handlePumpValuesChange}
                        placeholder="IE: North, South, Left, Right, etc."
                        value={pumpValues?.equipmentLocation || ""}
                        showSkeleton={isLoadingPump || isLoading}
                        disabled={disableStudy()}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <FormText
                        name="areaServed"
                        label="Area served"
                        onChange={handlePumpValuesChange}
                        placeholder="IE: North, South, Left, Right, etc."
                        value={pumpValues?.areaServed || ""}
                        showSkeleton={isLoadingPump || isLoading}
                        disabled={disableStudy()}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormSelect
                        name="pumpTypeId"
                        label="Pump type"
                        error={errors.pumpTypeId}
                        onChange={handlePumpValuesChange}
                        options={pumpTypes}
                        value={pumpValues?.pumpTypeId || 0}
                        showSkeleton={
                          isLoadingPump || isLoading || isLoadingPumpTypes
                        }
                        disabled={disableStudy()}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <h2>Pump Nameplate Data</h2>
                    </Grid>
                    <Grid item xs={12}>
                      <FormSelect
                        name="manufacturerId"
                        label="Pump manufacturer"
                        error={errors.manufacturerId}
                        onChange={handlePumpValuesChange}
                        options={pumpManufacturers}
                        value={pumpValues?.manufacturerId || 0}
                        showSkeleton={
                          isLoadingPump || isLoading || isLoadingPumpMfgs
                        }
                        disabled={disableStudy()}
                      />
                    </Grid>
                    {catalogValueOtherEval(
                      pumpValues?.manufacturerId || 0,
                      pumpManufacturers as IDefaultComboBoxCatalog[]
                    ) && (
                      <Grid item xs={12}>
                        <FormText
                          name="otherManufacturer"
                          label="Other pump manufacturer"
                          onChange={handlePumpValuesChange}
                          placeholder="Type the pump manufacturer here"
                          value={pumpValues?.otherManufacturer || ""}
                          showSkeleton={isLoadingPump || isLoading}
                          error={errors.otherManufacturer}
                          disabled={disableStudy()}
                        />
                      </Grid>
                    )}
                    <Grid item xs={12} md={4}>
                      <FormText
                        name="modelNumber"
                        label="Model number"
                        onChange={handlePumpValuesChange}
                        placeholder="Model number"
                        value={pumpValues?.modelNumber || ""}
                        showSkeleton={isLoadingPump || isLoading}
                        disabled={disableStudy()}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <FormText
                        name="serialNumber"
                        label="Serial number"
                        onChange={handlePumpValuesChange}
                        placeholder="Serial number"
                        value={pumpValues?.serialNumber || ""}
                        showSkeleton={isLoadingPump || isLoading}
                        disabled={disableStudy()}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <FormNumeric2
                        name="nameplateRpm"
                        value={pumpValues?.nameplateRpm}
                        label={`Nameplate rated ${systemOfMeasurement.get(
                          "rpm"
                        )}`}
                        onChange={handlePumpValuesChange}
                        fixedDecimalScale
                        decimalScale={2}
                        thousandSeparator
                        showSkeleton={isLoadingPump || isLoading}
                        disabled={disableStudy()}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <FormNumeric2
                        name="nameplateHead"
                        value={pumpValues?.nameplateHead}
                        label={`Nameplate rated ${systemOfMeasurement.get(
                          "headft"
                        )}`}
                        onChange={handlePumpValuesChange}
                        fixedDecimalScale
                        decimalScale={2}
                        thousandSeparator
                        showSkeleton={isLoadingPump || isLoading}
                        disabled={disableStudy()}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <FormNumeric2
                        name="nameplateGpm"
                        value={pumpValues?.nameplateGpm}
                        label={`Nameplate rated ${systemOfMeasurement.get(
                          "gpm"
                        )}`}
                        onChange={handlePumpValuesChange}
                        fixedDecimalScale
                        decimalScale={2}
                        thousandSeparator
                        showSkeleton={isLoadingPump || isLoading}
                        disabled={disableStudy()}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <FormNumeric2
                        name="impellerSize"
                        value={pumpValues?.impellerSize}
                        label={`Nameplate rated pump impeller size (${systemOfMeasurement.get(
                          "in"
                        )})`}
                        onChange={handlePumpValuesChange}
                        fixedDecimalScale
                        decimalScale={3}
                        thousandSeparator
                        showSkeleton={isLoadingPump || isLoading}
                        disabled={disableStudy()}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <h2>Pump Design Data</h2>
                    </Grid>
                    <Grid item xs={12}>
                      <FormNumeric2
                        name="designGpm"
                        value={pumpValues?.designGpm}
                        label={`This Pump's Design Intent ${systemOfMeasurement.get(
                          "gpm"
                        )}`}
                        onChange={handlePumpValuesChange}
                        fixedDecimalScale
                        decimalScale={2}
                        thousandSeparator
                        showSkeleton={isLoadingPump || isLoading}
                        disabled={disableStudy()}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormSelect
                        name="flowDesignId"
                        label="Delivery of flow design"
                        error={errors.flowDesignId}
                        onChange={handlePumpValuesChange}
                        options={flowDesigns}
                        value={pumpValues?.flowDesignId || 0}
                        showSkeleton={
                          isLoadingPump || isLoading || isLoadingFlowDesigns
                        }
                        disabled={disableStudy()}
                      />
                    </Grid>
                    {catalogValueOtherEval(
                      pumpValues?.flowDesignId || 0,
                      flowDesigns as IDefaultComboBoxCatalog[]
                    ) && (
                      <Grid item xs={12}>
                        <FormText
                          name="otherManufacturer"
                          label="Other delivery of flow design"
                          onChange={handlePumpValuesChange}
                          placeholder="Type the delivery of flow design here"
                          value={pumpValues?.otherManufacturer || ""}
                          showSkeleton={isLoadingPump || isLoading}
                          error={errors.otherManufacturer}
                          disabled={disableStudy()}
                        />
                      </Grid>
                    )}
                    <Grid item xs={12}>
                      <FormSelect
                        name="pumpSystemStrainerRequiredId"
                        label="Pump System Strainer Required/Shown on Plans and Specifications"
                        error={errors.pumpSystemStrainerRequiredId}
                        onChange={handlePumpValuesChange}
                        options={pumpSystemStrainerRequireds}
                        value={pumpValues?.pumpSystemStrainerRequiredId || 0}
                        showSkeleton={
                          isLoadingPump ||
                          isLoading ||
                          isLoadingPumpSystemStrainerRequireds
                        }
                        disabled={disableStudy()}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormSelect
                        name="pumpSystemStrainerInstalledId"
                        label="Pump System Strainer Installed"
                        error={errors.pumpSystemStrainerInstalledId}
                        onChange={handlePumpValuesChange}
                        options={pumpSystemStrainerInstalleds}
                        value={pumpValues?.pumpSystemStrainerInstalledId || 0}
                        showSkeleton={
                          isLoadingPump ||
                          isLoading ||
                          isLoadingPumpSystemStrainerInstalleds
                        }
                        disabled={disableStudy()}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormSelect
                        name="pumpSystemStrainerCheckeCleanId"
                        label="Pump System Strainer Checked and Clean"
                        error={errors.pumpSystemStrainerCheckeCleanId}
                        onChange={handlePumpValuesChange}
                        options={pumpSystemStrainerCheckeCleans}
                        value={pumpValues?.pumpSystemStrainerCheckeCleanId || 0}
                        showSkeleton={
                          isLoadingPump ||
                          isLoading ||
                          isLoadingPumpSystemStrainerCheckeCleans
                        }
                        disabled={disableStudy()}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormSelect
                        name="pumpSystemPipingProperlyFilledId"
                        label="Pump System Piping Properly Filled Based on System PRV, Total System Piping Height x .4335 and adding 2 to 3 PSI for fill pressure"
                        error={errors.pumpSystemPipingProperlyFilledId}
                        onChange={handlePumpValuesChange}
                        options={pumpSystemPipingProperlyFilleds}
                        value={
                          pumpValues?.pumpSystemPipingProperlyFilledId || 0
                        }
                        showSkeleton={
                          isLoadingPump ||
                          isLoading ||
                          isLoadingPumpSystemPipingProperlyFilleds
                        }
                        disabled={disableStudy()}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormSelect
                        name="pumpSystemPipingProperlyVentedId"
                        label="Pump System Piping Properly Vented"
                        error={errors.pumpSystemPipingProperlyVentedId}
                        onChange={handlePumpValuesChange}
                        options={pumpSystemPipingProperlyVenteds}
                        value={
                          pumpValues?.pumpSystemPipingProperlyVentedId || 0
                        }
                        showSkeleton={
                          isLoadingPump ||
                          isLoading ||
                          isLoadingPumpSystemPipingProperlyVenteds
                        }
                        disabled={disableStudy()}
                      />
                    </Grid>
                  </Grid>
                </>
              )}
              {tabSelected === _TAB_MT && (
                <Motor
                  id={selectedPump}
                  triggerSave={triggerMotorSave}
                  triggerMoveBack={triggerMoveBack}
                  triggerMoveNext={triggerMoveNext}
                  setTriggerSave={setTriggerMotorSave}
                  setTriggerMoveBack={setTriggerMoveBack}
                  setTriggerMoveNext={setTriggerMoveNext}
                  setIsUpdating={setIsUpdating}
                  moveBackHandler={motorMoveBackHandler}
                  moveNextHandler={motorMoveNextHandler}
                  isFanMotor={false}
                  reportId={id}
                  projectId={values?.projectId || 0}
                  index={selectedPumpIndex}
                  showSkeleton={isLoading}
                  isDisabled={disableStudy()}
                />
              )}
              <FooterButtons
                activeStep={activeStep}
                stepsCount={steps.length}
                projectId={values?.projectId as number}
                companyId={user?.companyId as number}
                isSaving={isUpdating}
                disabled={isUpdating || isLoading || disableStudy()}
                reportName={title as string}
                saveHandler={saveHandler}
                nextStepHandler={nextStepHandler}
                previousStepHandler={previousStepHandler}
                isComplete={values?.isComplete ?? true}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default PumpStep3;
