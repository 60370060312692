import {
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemSecondaryAction,
  ListItemText,
  Stack,
  useTheme,
} from "@mui/material";
import { useState } from "react";
import { AddIcon, DeleteIcon } from "src/components/icons";

import { useLog } from "src/hooks";
import DialogMessagePopup from "src/components/DialogMessagePopup";
import useChillerStudy from "../../hooks/useChillerStudy";
import { IChillerWeigtedTest } from "src/ts/interfaces";
import chillerWeigtedTestService from "src/services/study/chillerWeigtedTestService";

interface Props {
  chillerWeigtedTests: IChillerWeigtedTest[];
  selectedChillerWeigtedTest: IChillerWeigtedTest | null;
  selectedItemHandler: (towerWeigtedTest: IChillerWeigtedTest) => void;
  setChillerWeigtedTests: (val: IChillerWeigtedTest[]) => void;
  disabledStudy: boolean;
}

const ChillerWeigtedTestsList = ({
  chillerWeigtedTests,
  selectedChillerWeigtedTest,
  selectedItemHandler,
  setChillerWeigtedTests,
  disabledStudy,
}: Props) => {
  const theme = useTheme();
  const { chillerValues } = useChillerStudy();
  const { log } = useLog();
  const [showConfirmDeletePopup, setShowConfirmDeletePopup] = useState(false);
  const [selectedItemForDelete, setSelectedItemForDelete] = useState<{
    item: IChillerWeigtedTest | null;
    index: number | null;
  }>({
    item: null,
    index: null,
  });
  const addWeigtedTest = async () => {
    const chillerWeigtedTestDto: any = {
      chillerId: chillerValues.id,
    };
    const res = await chillerWeigtedTestService.addChillerWeigtedTest(
      chillerWeigtedTestDto
    );
    log.info("Weigted Test was added succesfully");

    const newChillerWeigtedTests = [...chillerWeigtedTests];
    newChillerWeigtedTests.push(res.data);
    setChillerWeigtedTests(newChillerWeigtedTests);
  };

  const deleteSoundTestConfirmation = (
    towerWeigtedTest: IChillerWeigtedTest,
    index: number
  ) => {
    setSelectedItemForDelete({ item: towerWeigtedTest, index: index });
    setShowConfirmDeletePopup(true);
  };

  const deleteWeigtedTest = async () => {
    if (selectedItemForDelete?.item === null) return;
    const itemsRes = await chillerWeigtedTestService.getChillerWeigtedTestItem(
      selectedItemForDelete.item?.id
    );
    const deleteItemPromises = itemsRes.data.map(
      async (item) =>
        await chillerWeigtedTestService.deleteChillerWeigtedTestItem(item.id)
    );
    await Promise.all(deleteItemPromises);
    await chillerWeigtedTestService.deleteChillerWeigtedTest(
      selectedItemForDelete.item?.id
    );

    if (selectedItemForDelete.index === null) return;

    const newTowerWeigtedTests = [...chillerWeigtedTests];
    newTowerWeigtedTests.splice(selectedItemForDelete.index, 1);
    setChillerWeigtedTests(newTowerWeigtedTests);

    if (newTowerWeigtedTests.length > 0) {
      selectedItemHandler(newTowerWeigtedTests[0]);
    }
  };

  return (
    <>
      <List component={Stack} direction="row" flexWrap={"wrap"}>
        {chillerWeigtedTests.map((towerWeigtedTest, index) => {
          return (
            <ListItem
              key={towerWeigtedTest.id}
              disablePadding
              sx={{
                borderColor: theme.palette.primary.main,
                borderWidth: "1px",
                borderStyle: "solid",
              }}
            >
              <ListItemButton
                selected={
                  selectedChillerWeigtedTest?.id === towerWeigtedTest.id
                }
                onClick={() => selectedItemHandler(towerWeigtedTest)}
              >
                <ListItemText primary={`${index + 1}      `} />
              </ListItemButton>

              <ListItemSecondaryAction>
                {!chillerValues.isComplete && !disabledStudy && (
                  <>
                    <IconButton onClick={addWeigtedTest}>
                      <AddIcon />
                    </IconButton>
                    {index > 0 && (
                      <IconButton
                        edge="end"
                        onClick={() =>
                          deleteSoundTestConfirmation(towerWeigtedTest, index)
                        }
                      >
                        <DeleteIcon />
                      </IconButton>
                    )}
                  </>
                )}
              </ListItemSecondaryAction>
            </ListItem>
          );
        })}
      </List>
      <DialogMessagePopup
        title={"Delete Confirmation"}
        text="Are you certain you want to delete the selected Sound Test?"
        showPopup={showConfirmDeletePopup}
        setShowPopup={setShowConfirmDeletePopup}
        onSave={deleteWeigtedTest}
        onCancel={() => {
          setShowConfirmDeletePopup(false);
        }}
        isSubmitting={false}
        disableClickOutside={true}
      />
    </>
  );
};

export default ChillerWeigtedTestsList;
