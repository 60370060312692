import axios from "src/utils/axios";
import {
  IActualFormulaPumpMotorDTO,
  IPumpReportActualDTO,
  IPumpReportActualFormulaDTO,
  IPumpReportDTO,
  IPumpReportViewModelDTO,
} from "src/ts/interfaces/study/pump";

const getBaseById = (id: number) => {
  return axios.get<IPumpReportDTO>(`PumpReport/base/${id}`);
};

const getById = (id: number) => {
  return axios.get<IPumpReportViewModelDTO>(`PumpReport/${id}`);
};

const save = (pumpReport: IPumpReportDTO) => {
  return axios.post<IPumpReportDTO>("PumpReport", pumpReport);
};

const update = (id: number, pumpReport: IPumpReportDTO) => {
  return axios.put<IPumpReportDTO>(`PumpReport/base/${id}`, pumpReport);
};

const getDesignGpm = (id: number, pumpId: number) => {
  return axios.get<number>(`PumpReport/pumpsDesignGpm/${id}?pumpId=${pumpId}`);
};

const getActualGpm = (id: number, actualId: number) => {
  return axios.get<number>(
    `PumpReport/pumpsActualGpm/${id}?actualId=${actualId}`
  );
};

const getActualFormulaById = (id: number) => {
  return axios.get<IPumpReportActualFormulaDTO[]>(
    `PumpReport/actualFormula/${id}`
  );
};

const getActualFormulaMotorById = (id: number) => {
  return axios.get<IActualFormulaPumpMotorDTO[]>(
    `PumpReport/actualFormulaPumpMotor/${id}`
  );
};

const getActualFormulaByPumpId = (id: number, pumpId: number) => {
  return axios.get<IPumpReportActualFormulaDTO>(
    `PumpReport/actuals/${id}?pumpId=${pumpId}`
  );
};

const updateActual = (
  id: number,
  pumpId: number,
  actuals: IPumpReportActualDTO
) => {
  return axios.put<IPumpReportActualDTO>(
    `PumpReport/actuals/${id}?pumpId=${pumpId}`,
    actuals
  );
};

const pumpReportService = {
  getBaseById,
  getById,
  save,
  update,
  getDesignGpm,
  getActualGpm,
  getActualFormulaById,
  getActualFormulaMotorById,
  getActualFormulaByPumpId,
  updateActual,
};

export default pumpReportService;
