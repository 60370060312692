export enum ProviderTypeEnum {
  Plaque = 1,
  Decals = 2,
}

export enum OrderStatusEnum {
  Received = 1,
  Preparing = 2,
  Shipped = 3,
  Delivered = 4,
  Canceled = -1,
  Other = 0,
}

export enum PlaquesTypeEnum {
  Decal = 0,
  Bronze = 1,
  Crystal = 2,
  Aluminium = 3,
}

export const PlaquesTypeEnumMap: Record<PlaquesTypeEnum, string> = {
  0: "Decal",
  1: "Bronze",
  2: "Crystal",
  3: "Aluminium",
};
