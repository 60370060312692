import {
  Grid,
  Stack,
  Switch,
  Typography,
  Paper,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
} from "@mui/material";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import { useEffect, useState } from "react";
import CompanyService from "src/services/companyService";
import {
  IProjectSingle,
  IAuditorByCERT,
} from "src/ts/interfaces/projectOutToBid";
import { IBuildingByProject, ICompanyData } from "src/ts/interfaces";
import { FormLabel, FormButton } from "src/components/formControls";
import CheckIcon from "@mui/icons-material/Check";
import getProjectSingleService from "src/services/getProjectSingleService";
import buildingService from "src/services/buildingService";
import userService from "src/services/userService";
import { useLog } from "src/hooks";
type ProjectOutForBidParams = {
  id: string;
};

export default function ProjectReport() {
  const { log } = useLog();
  const [projectSingle, setProjectSingle] = useState<IProjectSingle>();
  const [buildingInformation, setBuildingInformation] =
    useState<IBuildingByProject>();
  const [auditorByCert, setAuditorByCert] = useState<IAuditorByCERT[]>([]);
  const [companyData, setCompanyData] = useState<ICompanyData>();

  let { id } = useParams<ProjectOutForBidParams>();
  const navigate = useNavigate();
  const projectId = Number(id);
  const { pathname } = useLocation();

  useEffect(() => {
    const getProjectInfo = async () => {
      try {
        const response = await getProjectSingleService.getProjectSingle(
          projectId
        );
        setProjectSingle(response.data);
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      }
    };
    const getBuildingProject = async () => {
      try {
        const response = await buildingService.getBuildingByProjectBid(
          projectId
        );
        setBuildingInformation(response.data);
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      }
    };

    getProjectInfo();
    getBuildingProject();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    const getAuditCert = async () => {
      try {
        const companyID = projectSingle?.companyId ?? 1;
        const response = await userService.getAuditorByCer(
          companyID,
          projectId
        );
        setAuditorByCert(response.data);
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      }
    };
    const getCompanyData = async () => {
      try {
        const companyID = projectSingle?.companyId ?? 1;
        const response = await CompanyService.getByIdData(companyID);
        setCompanyData(response.data);
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      }
    };

    getAuditCert();
    getCompanyData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectSingle]);

  return (
    <>
      <Grid container>
        <Grid container xs={12} spacing={5} mb={5} item>
          <Grid item xs={4}>
            <Stack direction="row" justifyContent="end" alignItems="center">
              <Typography>
                <b>Project Name</b>
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={7}>
            <Stack direction="row" justifyContent="start" alignItems="center">
              <FormLabel text={projectSingle?.name} />
            </Stack>
          </Grid>
        </Grid>
        <Grid container xs={12} spacing={5} mb={5} item>
          <Grid item xs={4}>
            <Stack direction="row" justifyContent="end" alignItems="center">
              <Typography>
                <b>Created Date</b>
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={7}>
            <Stack direction="row" justifyContent="start" alignItems="center">
              <FormLabel
                text={moment(projectSingle?.createdDate).format("MM-DD-YYYY")}
              />
            </Stack>
          </Grid>
        </Grid>
        <Grid container xs={12} spacing={5} mb={5} item>
          <Grid item xs={4}>
            <Stack direction="row" justifyContent="end" alignItems="center">
              <Typography>
                <b>Proposed Start Date</b>
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={2}>
            <Stack direction="row" justifyContent="start" alignItems="center">
              <FormLabel
                text={moment(projectSingle?.startDate).format("MM-DD-YYYY")}
              />
            </Stack>
          </Grid>
          <Grid item xs={4}>
            <Stack
              direction="row"
              justifyContent="start"
              alignItems="center"
              spacing={5}
            >
              <Typography>
                <b>Proposed Start Time</b>
              </Typography>
              <FormLabel
                text={moment(projectSingle?.startDate).format("HH:mm:ss A")}
              />
            </Stack>
          </Grid>
        </Grid>
        <Grid container xs={12} spacing={5} mb={5} item>
          <Grid item xs={4}>
            <Stack direction="row" justifyContent="end" alignItems="center">
              <Typography>
                <b>Proposal Project Time Duration/ Bid</b>
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={7}>
            <Stack direction="row" justifyContent="start" alignItems="center">
              <FormLabel
                text={`${projectSingle?.estimatedTimeDuration} Days`}
              />
            </Stack>
          </Grid>
        </Grid>
        <Grid container xs={12} spacing={5} mb={5} item>
          <Grid item xs={4}>
            <Stack direction="row" justifyContent="end" alignItems="center">
              <Typography>
                <b>Computer Estimated Time Remaining</b>
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={7}>
            <Stack direction="row" justifyContent="start" alignItems="center">
              <FormLabel
                text={`${projectSingle?.computerEstimatedTimeRemaining} Days`}
              />
            </Stack>
          </Grid>
        </Grid>
        <Grid container xs={12} spacing={5} mb={5} item>
          <Grid item xs={4}>
            <Stack direction="row" justifyContent="end">
              <Typography>
                <b>Technicians Estimated Time Remaining</b>
              </Typography>
              <FormLabel text="" />
            </Stack>
          </Grid>
          <Grid item xs={7}>
            <Stack direction="row" justifyContent="start" alignItems="center">
              <FormLabel
                text={
                  projectSingle?.techniciansEstimatedTimeRemaining === "Empty"
                    ? "Not yet defined "
                    : `${projectSingle?.computerEstimatedTimeRemaining} Days`
                }
              />
            </Stack>
          </Grid>
        </Grid>
        <Grid container xs={12} spacing={5} mb={5} item>
          <Grid item xs={4}>
            <Stack direction="row" justifyContent="end">
              <Typography>
                <b>Select Location</b>
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={7}>
            <Stack direction="row" justifyContent="start" alignItems="center">
              <FormLabel text={buildingInformation?.name} />
            </Stack>
            <Stack
              direction="row"
              justifyContent="start"
              alignItems="center"
              mt={1}
            >
              <Typography>
                <b>Address: </b>{" "}
                {` ${buildingInformation?.address}, ${buildingInformation?.country.name}, ${buildingInformation?.city}, Zip: ${buildingInformation?.zip}`}
              </Typography>
            </Stack>
            <Stack
              direction="row"
              justifyContent="start"
              alignItems="center"
            ></Stack>
            <Stack
              direction="row"
              justifyContent="start"
              alignItems="center"
              mt={1}
            >
              <Typography>
                <b>Building Serial #: </b>{" "}
                {buildingInformation?.serial === "null"
                  ? ""
                  : buildingInformation?.serial}
              </Typography>
            </Stack>
          </Grid>
          <Grid container xs={12} spacing={5} item>
            <Grid item xs={4}>
              <Stack direction="row" justifyContent="end">
                <Typography>
                  <b>Measurement System</b>
                </Typography>
                <FormLabel text="" />
              </Stack>
            </Grid>
            <Grid item xs={7}>
              <Stack direction="row" justifyContent="start" alignItems="center">
                <FormLabel
                  text={
                    projectSingle?.measurementSystem === 1
                      ? "Imperial System"
                      : ``
                  }
                />
              </Stack>
            </Grid>
          </Grid>
          <Grid container xs={12} spacing={5} item>
            <Grid item xs={4}>
              <Stack direction="row" justifyContent="end">
                <Typography>
                  <b>Supplied Power Grid Frequency</b>
                </Typography>
                <FormLabel text="" />
              </Stack>
            </Grid>
            <Grid item xs={7}>
              <Stack direction="row" justifyContent="start" alignItems="center">
                <FormLabel
                  text={`${projectSingle?.suppliedPowerGridFrequency} Hz`}
                />
              </Stack>
            </Grid>
          </Grid>
          <Grid container xs={12} spacing={5} item>
            <Grid item xs={4}>
              <Stack direction="row" justifyContent="end" alignItems="center">
                <Typography>
                  <b>Total square feet (Sq/Ft) of audit area being tested</b>
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={7}>
              <Stack direction="row" justifyContent="start" alignItems="center">
                <Typography>
                  {" "}
                  {`${
                    projectSingle?.auditAreaSqFt !== "null"
                      ? ""
                      : projectSingle?.auditAreaSqFt
                  } ft²`}
                </Typography>
              </Stack>
            </Grid>
          </Grid>
          <Grid container xs={12} spacing={5} item>
            <Grid item xs={4}>
              <Stack direction="row" justifyContent="end" alignItems="center">
                <Typography>
                  <b>Total cubic feet (Cu/Ft) of audit area being tested</b>
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={7}>
              <Stack direction="row" justifyContent="start" alignItems="center">
                <Typography>
                  {" "}
                  {`${
                    projectSingle?.auditAreaCuFt !== "null"
                      ? ""
                      : projectSingle?.auditAreaCuFt
                  } ft³`}
                </Typography>
              </Stack>
            </Grid>
          </Grid>
          <Grid container xs={12} spacing={5} item>
            <Grid item xs={4}>
              <Stack direction="row" justifyContent="end" alignItems="center">
                <Typography>
                  <b>Parking area is part of this test</b>
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={7}>
              <Stack direction="row" justifyContent="start" alignItems="center">
                <Switch
                  checked={projectSingle?.isParkingAreaPartOfThisTest}
                  onChange={() => {}}
                  disabled={true}
                  inputProps={{ "aria-label": "controlled" }}
                />
              </Stack>
            </Grid>
          </Grid>
          <Grid container xs={12} spacing={5} item>
            <Grid item xs={4}>
              <Stack direction="row" justifyContent="end" alignItems="center">
                <Typography>
                  <b>Project Type</b>
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={7}>
              <Stack direction="row" justifyContent="start" alignItems="center">
                <FormLabel text={projectSingle?.projectBomaType.name} />
              </Stack>
            </Grid>
          </Grid>
          <Grid container xs={12} spacing={5} item>
            <Grid item xs={4}>
              <Stack direction="row" justifyContent="end" alignItems="center">
                <Typography>
                  <b>Project Description</b>
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={7}>
              <Stack direction="row" justifyContent="start" alignItems="center">
                <FormLabel text={projectSingle?.projectDescription} />
              </Stack>
            </Grid>
          </Grid>
          <Grid container xs={12} spacing={5} item>
            <Grid item xs={4}>
              <Stack direction="row" justifyContent="end" alignItems="center">
                <Typography sx={{ color: "green" }}>
                  <b>Required Comprehensive Energy Test</b>
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={7}>
              <Stack direction="row" justifyContent="start" alignItems="center">
                <FormLabel
                  text={
                    projectSingle?.requiredComprehensiveEnergyTest === false
                      ? "No"
                      : "Yes"
                  }
                />
              </Stack>
            </Grid>
          </Grid>
          <Grid container xs={12} spacing={5} item>
            <Grid item xs={4}>
              <Stack direction="row" justifyContent="end" alignItems="center">
                <Typography>
                  <b>Contracted By</b>
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={7}>
              <Stack direction="row" justifyContent="start" alignItems="center">
                <FormLabel text={projectSingle?.contractedBy} />
              </Stack>
            </Grid>
          </Grid>
          <Grid container xs={12} spacing={5} item>
            <Grid item xs={4}>
              <Stack direction="row" justifyContent="end" alignItems="center">
                <Typography>
                  <b>Purchase Order #</b>
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={7}>
              <Stack direction="row" justifyContent="start" alignItems="center">
                <FormLabel text={projectSingle?.purchaseOrder} />
              </Stack>
            </Grid>
          </Grid>
          <Grid container xs={12} spacing={5} item>
            <Grid item xs={4}>
              <Stack direction="row" justifyContent="end" alignItems="center">
                <Typography>
                  <b>Contact Person</b>
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={7}>
              <Stack direction="row" justifyContent="start" alignItems="center">
                <FormLabel text={projectSingle?.contactPerson} />
              </Stack>
            </Grid>
          </Grid>
          <Grid container xs={12} spacing={5} item>
            <Grid item xs={4}>
              <Stack direction="row" justifyContent="end" alignItems="center">
                <Typography>
                  <b>Contact Phone Number</b>
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={7}>
              <Stack direction="row" justifyContent="start" alignItems="center">
                <FormLabel text={projectSingle?.contactPhoneNumber} />
              </Stack>
            </Grid>
          </Grid>
          <Grid container xs={12} spacing={5} item>
            <Grid item xs={4}>
              <Stack direction="row" justifyContent="end" alignItems="center">
                <Typography>
                  <b>Print Drawing(s) Available On-Site</b>
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={7}>
              <Stack direction="row" justifyContent="start" alignItems="center">
                <FormLabel
                  text={
                    projectSingle?.printDrawingsAvailableOnSite === false
                      ? "No"
                      : "Yes"
                  }
                />
              </Stack>
            </Grid>
          </Grid>
          <Grid container xs={12} spacing={5} item>
            <Grid item xs={4}>
              <Stack direction="row" justifyContent="end" alignItems="center">
                <Typography>
                  <b>Print Drawing(s) Bid for this Project</b>
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={7}>
              <Stack direction="row" justifyContent="start" alignItems="center">
                <FormLabel text={projectSingle?.printDrawings} />
              </Stack>
            </Grid>
          </Grid>
          <Grid container xs={12} spacing={5} item>
            <Grid item xs={4}>
              <Stack direction="row" justifyContent="end" alignItems="center">
                <Typography>
                  <b>Print Addendum Date Used for Proposal</b>
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={7}>
              <Stack direction="row" justifyContent="start" alignItems="center">
                <FormLabel
                  text={projectSingle?.printAddendumDateUsedForProposal}
                />
              </Stack>
            </Grid>
          </Grid>
          <Grid container xs={12} spacing={5} item>
            <Grid item xs={4}>
              <Stack direction="row" justifyContent="end" alignItems="center">
                <Typography>
                  <b>Specifications Provided</b>
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={7}>
              <Stack direction="row" justifyContent="start" alignItems="center">
                <FormLabel
                  text={
                    projectSingle?.specificationsProvided === false
                      ? "No"
                      : "Yes"
                  }
                />
              </Stack>
            </Grid>
          </Grid>
          <Grid container xs={12} spacing={5} item>
            <Grid item xs={4}>
              <Stack direction="row" justifyContent="end" alignItems="center">
                <Typography>
                  <b>Addendums Bid for this Project</b>
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={7}>
              <Stack direction="row" justifyContent="start" alignItems="center">
                <FormLabel
                  text={
                    projectSingle?.addendumBidForThisProject === 0
                      ? "None"
                      : projectSingle?.addendumBidForThisProject.toString()
                  }
                />
              </Stack>
            </Grid>
          </Grid>
          <Grid container xs={12} spacing={5} item>
            <Grid item xs={4}>
              <Stack direction="row" justifyContent="end" alignItems="center">
                <Typography>
                  <b>Equipment Description and Quantity</b>
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={7}>
              <Stack direction="row" justifyContent="start" alignItems="center">
                <FormLabel
                  text={projectSingle?.equipmentDescriptionAndQuantity}
                />
              </Stack>
            </Grid>
          </Grid>
          <Grid container xs={12} spacing={5} item>
            <Grid item xs={4}>
              <Stack direction="row" justifyContent="end" alignItems="center">
                <Typography>
                  <b>Lift Work Required</b>
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={7}>
              <Stack direction="row" justifyContent="start" alignItems="center">
                <FormLabel
                  text={
                    projectSingle?.liftWorkRequired === false ? "No" : "Yes"
                  }
                />
              </Stack>
            </Grid>
          </Grid>
          <Grid container xs={12} spacing={5} item>
            <Grid item xs={4}>
              <Stack direction="row" justifyContent="end" alignItems="center">
                <Typography>
                  <b>Lift Provided By Others</b>
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={7}>
              <Stack direction="row" justifyContent="start" alignItems="center">
                <FormLabel
                  text={
                    projectSingle?.liftProvidedByOthers === false ? "No" : "Yes"
                  }
                />
              </Stack>
            </Grid>
          </Grid>
          <Grid container xs={12} spacing={5} item>
            <Grid item xs={4}>
              <Stack direction="row" justifyContent="end" alignItems="center">
                <Typography>
                  <b>Additional Lift Instructions</b>
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={7}>
              <Stack direction="row" justifyContent="start" alignItems="center">
                <FormLabel text={projectSingle?.additionalLiftInstructions} />
              </Stack>
            </Grid>
          </Grid>
          <Grid container xs={12} spacing={5} item>
            <Grid item xs={4}>
              <Stack direction="row" justifyContent="end" alignItems="center">
                <Typography>
                  <b>Roof Access / Hatch Available</b>
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={7}>
              <Stack direction="row" justifyContent="start" alignItems="center">
                <FormLabel
                  text={
                    projectSingle?.roofAccessHatchAvailable === 0
                      ? "Unknown"
                      : projectSingle?.roofAccessHatchAvailable.toString()
                  }
                />
              </Stack>
            </Grid>
          </Grid>
          <Grid container xs={12} spacing={5} item>
            <Grid item xs={4}>
              <Stack direction="row" justifyContent="end" alignItems="center">
                <Typography>
                  <b>Ladders Required</b>
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={7}>
              <Stack direction="row" justifyContent="start" alignItems="center">
                <FormLabel
                  text={projectSingle?.laddersRequired === false ? "No" : "Yes"}
                />
              </Stack>
            </Grid>
          </Grid>
          <Grid container xs={12} spacing={5} item>
            <Grid item xs={4}>
              <Stack direction="row" justifyContent="end" alignItems="center">
                <Typography>
                  <b>Control Contractor Name</b>
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={7}>
              <Stack direction="row" justifyContent="start" alignItems="center">
                <FormLabel text={projectSingle?.controlContractorName} />
              </Stack>
            </Grid>
          </Grid>
          <Grid container xs={12} spacing={5} item>
            <Grid item xs={4}>
              <Stack direction="row" justifyContent="end" alignItems="center">
                <Typography>
                  <b>Contact Person/Technician</b>
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={7}>
              <Stack direction="row" justifyContent="start" alignItems="center">
                <FormLabel text={projectSingle?.contactPersonTechnician} />
              </Stack>
            </Grid>
          </Grid>
          <Grid container xs={12} spacing={5} item>
            <Grid item xs={4}>
              <Stack direction="row" justifyContent="end" alignItems="center">
                <Typography>
                  <b>Control Contractors Contact Phone Number</b>
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={7}>
              <Stack direction="row" justifyContent="start" alignItems="center">
                <FormLabel text={projectSingle?.controlContractorPhoneNumber} />
              </Stack>
            </Grid>
          </Grid>
          <Grid container xs={12} spacing={5} item>
            <Grid item xs={4}>
              <Stack direction="row" justifyContent="end" alignItems="center">
                <Typography>
                  <b>Installed Controls Manufacturer</b>
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={7}>
              <Stack direction="row" justifyContent="start" alignItems="center">
                <FormLabel text={projectSingle?.controlManufacturer} />
              </Stack>
            </Grid>
          </Grid>
          <Grid container xs={12} spacing={5} item>
            <Grid item xs={4}>
              <Stack direction="row" justifyContent="end" alignItems="center">
                <Typography>
                  <b>Installed Controls Type</b>
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={7}>
              <Stack direction="row" justifyContent="start" alignItems="center">
                <FormLabel text={projectSingle?.controlType} />
              </Stack>
            </Grid>
          </Grid>
          <Grid container xs={12} spacing={5} item>
            <Grid item xs={4}>
              <Stack direction="row" justifyContent="end" alignItems="center">
                <Typography>
                  <b>Installed Controls Version</b>
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={7}>
              <Stack direction="row" justifyContent="start" alignItems="center">
                <FormLabel text={projectSingle?.controlVersion} />
              </Stack>
            </Grid>
          </Grid>
          <Grid container xs={12} spacing={5} item>
            <Grid item xs={4}>
              <Stack direction="row" justifyContent="end" alignItems="center">
                <Typography>
                  <b>Auditors/Technician</b>
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={6}>
              <Stack direction="row" justifyContent="start">
                <Paper
                  sx={{
                    width: 200,
                    height: 150,
                    overflow: "auto",
                    borderStyle: "solid",
                    borderColor: "lightgray",
                    borderWidth: "thin",
                  }}
                  elevation={3}
                >
                  <List dense component="div" role="list">
                    {auditorByCert.map((value: any) => {
                      return (
                        <ListItem key={value.id} role="listitem">
                          <ListItemText primary={value.name} />
                        </ListItem>
                      );
                    })}
                  </List>
                </Paper>
                {/* <FormTransferList /> */}
              </Stack>
            </Grid>
          </Grid>
          <Grid container xs={12} spacing={5} item>
            <Grid item xs={4}>
              <Stack direction="row" justifyContent="end" alignItems="center">
                <Typography>
                  <b>Safety Equipment</b>
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={7}>
              <Stack direction="row" justifyContent="start" alignItems="center">
                <Paper
                  sx={{
                    width: 200,
                    height: 150,
                    overflow: "auto",
                    borderStyle: "solid",
                    borderColor: "lightgray",
                    borderWidth: "thin",
                  }}
                  elevation={3}
                >
                  <List dense component="div" role="list">
                    {projectSingle?.safetyEquipments.map((value: any) => {
                      return (
                        <ListItem key={value.id} role="listitem">
                          <ListItemIcon>
                            <CheckIcon />
                          </ListItemIcon>
                          <ListItemText primary={value.name} />
                        </ListItem>
                      );
                    })}
                  </List>
                </Paper>
              </Stack>
            </Grid>
          </Grid>
          <Grid container xs={12} spacing={5} item>
            <Grid item xs={4}>
              <Stack direction="row" justifyContent="end" alignItems="center">
                <Typography>
                  <b>Building Pressurization</b>
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={7}>
              <Stack direction="row" justifyContent="start" alignItems="center">
                <FormLabel
                  text={
                    projectSingle?.buildingPressurizationId === null
                      ? "Positive +"
                      : projectSingle?.buildingPressurizationId
                  }
                />
              </Stack>
            </Grid>
          </Grid>
          <Grid container xs={12} spacing={5} item>
            <Grid item xs={4}>
              <Stack direction="row" justifyContent="end" alignItems="center">
                <Typography>
                  <b>Additional Project Instructions/Directions</b>
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={7}>
              <Stack direction="row" justifyContent="start" alignItems="center">
                <FormLabel
                  text={projectSingle?.additionalProjectInstructions}
                />
              </Stack>
            </Grid>
          </Grid>
          <Grid container xs={12} spacing={5} item>
            <Grid item xs={4}>
              <Stack direction="row" justifyContent="end" alignItems="center">
                <Typography>
                  <b>Contracts</b>
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={7}>
              <Stack
                direction="row"
                justifyContent="start"
                alignItems="center"
                spacing={2}
              >
                <Typography>
                  <b>Name: </b>
                </Typography>
                <FormLabel text={companyData?.name} />
              </Stack>
              <Stack
                direction="row"
                justifyContent="start"
                alignItems="center"
                spacing={2}
              >
                <Typography>
                  <b>Address: </b>
                </Typography>
                <FormLabel
                  text={`${companyData?.address}, ${companyData?.city}, ${
                    companyData?.state === null ? "" : companyData?.state.name
                  }, ${
                    companyData?.country === null
                      ? ""
                      : companyData?.country.name
                  }  `}
                />
              </Stack>
              <Stack
                direction="row"
                justifyContent="start"
                alignItems="center"
                spacing={2}
              >
                <Typography>
                  <b>Email: </b>
                </Typography>
                <FormLabel text={companyData?.email} />
              </Stack>
              <Stack
                direction="row"
                justifyContent="start"
                alignItems="center"
                spacing={2}
              >
                <Typography>
                  <b>Phone: </b>
                </Typography>
                <FormLabel text={companyData?.phone} />
              </Stack>
            </Grid>
          </Grid>
          <Grid xs={12} sx={{ textAlign: "center" }} item>
            <FormButton
              text="Close"
              size="medium"
              color="error"
              onClick={() => {
                navigate("/app/projectsOutForBid");
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
