import { useEffect, useState } from "react";
import { CountryService } from "../services";
import { ICountry, IKeyValue } from "../ts/interfaces";

const useCountry = (countryId?: number) => {
  const [countries, setCountries] = useState<ICountry[]>([]);
  const [countriesKeyValue, setCountriesKeyValue] = useState<
    IKeyValue<number | null | undefined, string | undefined>[]
  >([]);
  const [selectedCountry, setSelectedCountry] = useState<ICountry | null>(null);

  useEffect(() => {
    const getCountries = async () => {
      const response = await CountryService.getCountries();
      setCountries(response.data);
      const keyValues = response.data.map((country) => {
        return {
          key: country.id,
          value: country.name,
        };
      });

      setCountriesKeyValue(keyValues);

      if (countryId) {
        const result = response.data.find(
          (country) => country.id === countryId
        );
        if (result !== null) setSelectedCountry(result!);
      }
    };
    getCountries();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setSelectedCountryById = (id: number) => {
    const result = countries.find((country) => country.id === id);
    if (result !== null) setSelectedCountry(result!);
  };

  const getCountryById = (id: number) => {
    return countries.find((country) => country.id === id) ?? null;
  };

  return {
    countries,
    setSelectedCountryById,
    selectedCountry,
    countriesKeyValue,
    getCountryById,
  };
};

export default useCountry;
