import {
  IAditionalProposalDocuments,
  IUploadAdditionalProposalFile,
} from "src/ts/interfaces";
import axios from "../utils/axios";

const uploadFileAdditionalProposal = async (
  file: IUploadAdditionalProposalFile
) => {
  let bodyFormData = new FormData();
  bodyFormData.append("fileForUpload[0]", file.content);
  bodyFormData.append("key[type]", file.type);
  bodyFormData.append("key[projectId]", file.projectId);

  return await axios({
    method: "post",
    url: "/libraryDocument/additionalProposalDocumentationFolder",
    data: bodyFormData,
    headers: { "Content-Type": "multipart/form-data" },
  });
};

const getAdditionalPropsoal = async (projectId: string, companyId: string) => {
  return axios.get<IAditionalProposalDocuments[]>(
    `LibraryDocument/AditionalProposal?projectId=${projectId}&companyId=${companyId}`
  );
};

const getDocumentFile = async (projectId: number) => {
  return axios.get(`libraryDocument/file/${projectId}`);
};
const libraryDocumentService = {
  uploadFileAdditionalProposal,
  getAdditionalPropsoal,
  getDocumentFile,
};

export default libraryDocumentService;
