import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface IPdfNotification {
  id: number;
  name: string;
  percentage: number;
  isProject: boolean;
  isExcecutive: boolean;
}

interface IPdfNotificationState {
  pdfNotifications: IPdfNotification[];
  completeProject: boolean;
  openModal: boolean;
}

const initialState: IPdfNotificationState = {
  pdfNotifications: [],
  completeProject: false,
  openModal: false,
};

export const floatingBarPdfGeneration = createSlice({
  name: "pdfNotifications",
  initialState,
  reducers: {
    setPdfNotifications(
      state: IPdfNotificationState,
      action: PayloadAction<IPdfNotification[]>
    ) {
      state.pdfNotifications = action.payload;
    },
    archivePdfNotification(
      state: IPdfNotificationState,
      action: PayloadAction<number>
    ) {
      state.pdfNotifications = state.pdfNotifications.filter(
        (notification) => notification.id !== action.payload
      );
    },
    setComplete(state: IPdfNotificationState, action: PayloadAction<boolean>) {
      state.completeProject = action.payload;
    },

    setOpen(state: IPdfNotificationState, action: PayloadAction<boolean>) {
      state.openModal = action.payload;
    },
  },
});

export default floatingBarPdfGeneration;
