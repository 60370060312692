import axios from "../utils/axios";
import { IPlaqueOrderDetail } from "../ts/interfaces";

const create = (detail: IPlaqueOrderDetail) => {
  return axios.post("PlaqueOrderDetail", detail);
};

const remove = (projectId: number, orderDetailId: number) => {
  return axios.delete(
    `PlaqueOrderDetail/${orderDetailId}?projectId=${projectId}`
  );
};

const plaqueOrderDetailService = {
  create,
  remove,
};
export default plaqueOrderDetailService;
