import { Grid, Typography } from "@mui/material";
import { IProjectStorageSummary } from "src/ts/interfaces/storage";
import LocalEnhancedTable from "src/components/localTable/LocalTable";
import { ColumnType } from "src/types/enhancedTable";
import { useForm, useLog } from "src/hooks";
import storageService from "src/services/storageService";
import { useEffect, useState } from "react";
import { FormText } from "src/components/formControls";

const getColumns = (): ColumnType[] => {
  return [
    {
      id: "name",
      label: "Project",
      type: "string",
      sort: true,
    },
    {
      id: "buildingName",
      label: "Building Name",
      type: "string",
      sort: true,
    },
    {
      id: "buildingAddress",
      label: "Building Address",
      type: "string",
      sort: true,
    },
    {
      id: "createdDate",
      label: "Date Created",
      type: "utcDate",
      sort: true,
      format: "MM/DD/yyyy",
    },
    {
      id: "size",
      label: "Size",
      type: "custom",
      sort: true,
      callback: (row: IProjectStorageSummary) => {
        return (
          <>
            <Typography>
              {Intl.NumberFormat("en-US", {
                maximumFractionDigits: 2,
              }).format(row.size)}{" "}
              KB
            </Typography>
          </>
        );
      },
    },
  ];
};

const initialValues = {
  id: 0,
  name: "",
  buildingName: "",
};

const TableCompletedProjects = () => {
  const columns = getColumns();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [completedProjects, setCompletedProjects] = useState<
    IProjectStorageSummary[]
  >([]);
  const { log } = useLog();

  const { values: filterValues, handleInputChange: handleFilterChange } =
    useForm(initialValues, true, []);

  useEffect(() => {
    const getData = async () => {
      try {
        setIsSubmitting(true);
        const request = await storageService.getCompletedProjects();
        setCompletedProjects(request.data);
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      } finally {
        setIsSubmitting(false);
      }
    };

    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Grid item xs={12}>
        <Grid container padding={"20px"}>
          <Grid item xs={12}>
            <FormText
              name="name"
              label="Search"
              value={filterValues.name}
              onChange={handleFilterChange}
            />
          </Grid>
        </Grid>

        <LocalEnhancedTable<IProjectStorageSummary>
          refreshGrid={true}
          columns={columns}
          data={completedProjects}
          showSkeleton={isSubmitting}
          query={filterValues.name}
          defaultRowPerPage={10}
        />
      </Grid>
    </>
  );
};

export default TableCompletedProjects;
