import { IDeliverAddress } from "src/ts/interfaces";
import axios from "src/utils/axios";

const calculate = async (address: IDeliverAddress) => {
  return axios.post(`DeliverAddress`, address);
};

const deliverAddressService = {
  calculate,
};

export default deliverAddressService;
