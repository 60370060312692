import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import { useParams } from "react-router-dom";
import {
  Card as MuiCard,
  Divider as MuiDivider,
  CardContent,
  Typography,
  Grid,
  Stack,
} from "@mui/material";
import { spacing } from "@mui/system";
import useForm, { Form } from "src/hooks/useForm";
import FormText from "src/components/formControls/FormText";
import { FormActionButton, FormDatePicker } from "src/components/formControls";
import { FilterType } from "./types/FilterTypes";
import moment from "moment/moment.js";
import { useTable } from "src/hooks/useTable";
import { ColumnType } from "src/types/enhancedTable";
import { getQueryString } from "./utils/common";
import { IForwardDocumentLibraryData } from "src/ts/interfaces/history";

import HeaderPage from "src/components/page/HeaderPage";
import { Validator } from "src/ts/types/Validator";
import { useLog } from "src/hooks";
import forwardDocumentHistoryService from "src/services/forwardDocumentHistoryService";
import { SearchIcon } from "src/components/icons";

const Divider = styled(MuiDivider)(spacing);
const Card = styled(MuiCard)(spacing);

const initialValues: FilterType = {
  filter: "",
  from: moment().startOf("month").format("MM/DD/YYYY"),
  to: moment().format("MM/DD/YYYY"),
};

function ForwardDocumentLibrary() {
  let { companyId } = useParams<{ companyId: string }>();
  let index = 0;
  const { log } = useLog();
  const [callFromApply, setCallFromApply] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const validate = (fieldValues = values) => {
    let temp: Record<string, string> = { ...errors };

    temp.from = new Validator(fieldValues, "from")
      .isRequired("The date is required.")
      .toString();

    temp.to = new Validator(fieldValues, "to")
      .isRequired("The date is required.")
      .toString();

    const end = new Date(fieldValues.to);
    const start = new Date(fieldValues.from);
    if (fieldValues.to && fieldValues.from) {
      if (end < start) {
        temp.from = `Start date can not be greater than  ${moment(end).format(
          "MM/DD/YYYY"
        )}`;
        temp.to = `End date can not be less than  ${moment(start).format(
          "MM/DD/YYYY"
        )}`;
      } else {
        temp.from = "";
        temp.to = "";
      }
    }

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };
  const { values, errors, setErrors, handleInputChange, setValues } = useForm(
    initialValues,
    false,
    validate
  );

  const getColumns = (): ColumnType[] => [
    {
      id: "id",
      label: "#",
      type: "custom",
      sort: false,
      align: "center",
      hide: true,
      callback: (row: IForwardDocumentLibraryData) => {
        index = index + 1;
        return (
          <>
            <Stack ml={7}>
              <Typography> {index + page * 10}</Typography>
            </Stack>
          </>
        );
      },
    },
    {
      id: "fromUser",
      label: "From User",
      type: "string",
      sort: true,
    },
    {
      id: "type",
      label: "Type",
      type: "string",
      sort: true,
    },
    {
      id: "toUser",
      label: "To User",
      type: "string",
      sort: true,
    },
    {
      id: "createdDate",
      label: "Date Sent",
      type: "utcDate",
      format: "MM/DD/YYYY h:mma",
      sort: true,
    },
    {
      id: "projectName",
      label: "Project Name",
      type: "string",
      sort: true,
    },
    {
      id: "folder",
      label: "Folder",
      type: "string",
      sort: true,
    },
    {
      id: "documentName",
      label: "Document Name",
      type: "string",
      sort: true,
    },
  ];

  let columns = getColumns();

  const {
    Table,
    setRowsPerPage,
    page,
    setDataSet,
    setCount,
    orderBy,
    order,
    setPage,
    setRefreshGrid,
    refreshGrid,
  } = useTable(columns, { rowsPerPageOptions: [10] });

  const handleRefreshGrid = () => {
    setPage(0);
    setCallFromApply(!callFromApply);
  };

  useEffect(() => {
    const getData = async () => {
      try {
        if (!validate()) return;
        setIsSubmitting(true);
        const request =
          await forwardDocumentHistoryService.getForwardDocumentHistory(
            {
              page: page + 1,
              filter: values.filter,
              sortColumn: orderBy,
              sortDec: order === "desc" ? true : false,
              dateFrom: values.from,
              dateTo: values.to,
            },
            getQueryString
          );

        setCount(request.data.count);
        setDataSet(request.data.getAll);
        setRowsPerPage(10);
        setIsSubmitting(false);
        setRefreshGrid(!refreshGrid);
      } catch (error: any) {
        setIsSubmitting(false);
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      }
    };

    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, orderBy, order, callFromApply, companyId]);

  const validateMinDate = (e: any) => {
    if (e.target.value !== "Invalid date") {
      let newValues = { ...values };
      newValues.from = e.target.value;

      if (Date.parse(newValues.to) < Date.parse(newValues.from))
        setValues({ ...newValues, to: newValues.from });
      else setValues({ ...newValues });
    }
  };

  return (
    <>
      <HeaderPage
        title={"Documents Notifications"}
        parentText={"Dashboard"}
        parentLink={`/`}
        actionSection={<Grid container></Grid>}
      ></HeaderPage>

      <Divider my={6} />
      <Card mb={6}>
        <CardContent>
          <Grid container spacing={10}>
            <Grid item xs={12}>
              <Form>
                <Grid container spacing={5}>
                  <Grid item xs={4}>
                    <FormDatePicker
                      label={"Date From"}
                      name="from"
                      disablePast={false}
                      value={values.from}
                      onChange={validateMinDate}
                      error={errors.from}
                    />
                  </Grid>
                  <Grid item xs={4} sx={{ textAlign: "right" }}>
                    <FormDatePicker
                      label={"Date To"}
                      name="to"
                      disablePast={false}
                      value={values.to}
                      onChange={handleInputChange}
                      error={errors.to}
                      fullWidth={true}
                      minDate={values.from}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <FormActionButton
                      mr={2}
                      text="Search"
                      size="medium"
                      onClick={handleRefreshGrid}
                      isSubmitting={isSubmitting}
                      type="search"
                      startIcon={<SearchIcon />}
                    />
                  </Grid>
                  <Grid item xs={8}>
                    <FormText
                      name="filter"
                      label="Type text to search"
                      value={values.filter}
                      onChange={handleInputChange}
                      error={errors.filter}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Table />
                  </Grid>
                </Grid>
              </Form>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
}

export default ForwardDocumentLibrary;
