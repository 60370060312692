import { useState } from "react";
import Popup from "src/components/Popup";
import {
  FormAcceptButton,
  FormCancelButton,
} from "src/components/formControls";
import { Stack, Typography } from "@mui/material";
import useForm from "src/hooks/useForm";
import FormTextArea from "src/components/formControls/FormTextArea";
import { TicketService } from "src/services";
import useLog from "src/hooks/useLog";

interface CommentsTicketsProps {
  showCommentsTicketPopup: boolean;
  setShowCommentsTicketPopup: (val: boolean) => void;
  informationId: any;
  onRefreshGrid: () => void;
}

const initialValue = {
  comments: "",
  id: 0,
};

export default function CommentsTickets({
  showCommentsTicketPopup,
  setShowCommentsTicketPopup,
  informationId,
  onRefreshGrid,
}: CommentsTicketsProps) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const handleClosePopUp = () => {
    setShowCommentsTicketPopup(false);
  };

  const validate = (fieldValues = values) => {
    let temp: Record<string, string> = { ...errors };

    if (fieldValues.hasOwnProperty("comments")) {
      temp.comments =
        fieldValues.comments !== ""
          ? ""
          : "Please enter comments for closing the ticket.";
    }
    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, errors, setErrors, handleInputChange } = useForm(
    initialValue,
    false,
    validate
  );

  const { log } = useLog();

  const handleSave = async () => {
    if (!validate()) {
      log.error("Please enter comments for closing the ticket.");
      return;
    }
    setIsSubmitting(true);
    await TicketService.postCommentTicket({
      ...values,
      id: informationId.id,
    });
    onRefreshGrid();
    log.info(
      "The ticket has been closed and a notification has been sent to the author."
    );
    setIsSubmitting(false);
    setShowCommentsTicketPopup(false);
  };

  return (
    <>
      <Popup
        title="Tickets"
        openPopup={showCommentsTicketPopup}
        setOpenPopup={setShowCommentsTicketPopup}
        onClose={handleClosePopUp}
      >
        <>
          <Stack direction="row" spacing={15}>
            <Typography> Comments: </Typography>
          </Stack>
          <Stack direction="row" spacing={10}>
            <FormTextArea
              placeholder="Please be descriptive when entering comment for closing the ticket"
              label=""
              name="comments"
              value={values.comments}
              onChange={handleInputChange}
            />
          </Stack>
        </>
        <Stack
          direction="row"
          spacing={2}
          justifyContent="flex-end"
          alignItems="flex-start"
        >
          <FormCancelButton
            onClick={() => {
              setShowCommentsTicketPopup(false);
            }}
            isSubmitting={isSubmitting}
          />
          <FormAcceptButton onClick={handleSave} isSubmitting={isSubmitting} />
        </Stack>
      </Popup>
    </>
  );
}
