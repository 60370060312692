import React, { useEffect } from "react";
import { Alert, Grid, Stack } from "@mui/material";
import {
  useAsyncMutation,
  useAsyncQuery,
  useCountry,
  useForm,
  useStateCatalog,
  useTimeZone,
} from "src/hooks";
import FormTitleBox from "src/components/formControls/FormTitleBox";
import { Validator } from "src/ts/types";
import { IUserContactInfoSignup } from "src/ts/interfaces";
import {
  FormCancelButton,
  FormCheckBox,
  FormSaveButton,
  FormSelect,
  FormText,
} from "src/components/formControls";
import { COUNTRIES } from "src/constants";
import { CompanyService, userService } from "src/services";
import useZipCode from "src/hooks/useZipCode";
import timeZonesService from "src/services/catalogs/timeZonesService";

const initialValues: IUserContactInfoSignup = {
  stateId: 0,
  countryId: COUNTRIES.USA,
  companyId: 0,
  name: "",
  lastName: "",
  address: "",
  address2: "",
  city: "",
  stateName: "",
  zip: "",
  phone: "",
  email: "",
  copyDataToCompany: true,
};
interface Props {
  setModalCancel: React.Dispatch<React.SetStateAction<boolean>>;
  handleNext: (cardConfirm: boolean) => Promise<void>;
}
function ContactInformation({ setModalCancel, handleNext }: Props) {
  const { timeZoneKeyValue } = useTimeZone();
  const { countriesKeyValue } = useCountry();
  const { states, setCountryId } = useStateCatalog();
  const validate = (fieldValues = values) => {
    let temp: Record<string, string> = { ...errors };

    temp.name = new Validator(fieldValues, "name")
      .isRequired("The User's first name is required")
      .maxLength(200, "Only 200 character are allowed")
      .toString();

    temp.lastName = new Validator(fieldValues, "lastName")
      .isRequired("The User's last name is required.")
      .maxLength(200, "Only 200 character are allowed")
      .toString();

    temp.address = new Validator(fieldValues, "address")
      .isRequired("The User's address is required.")
      .maxLength(500, "Only 500 character are allowed")
      .toString();

    temp.address2 = new Validator(fieldValues, "address2")
      .maxLength(500, "Only 500 character are allowed")
      .toString();

    temp.countryId = new Validator(fieldValues, "countryId")
      .selectedOption("", "The User's country is required.")
      .toString();

    temp.zip = new Validator(fieldValues, "zip")
      .isRequired("The User's zipcode is required.")
      .maxLength(5, "Only 5 character are allowed")
      .toString();

    temp.city = new Validator(fieldValues, "city")
      .isRequired("The User's city is required.")
      .maxLength(50, "Only 50 character are allowed")
      .toString();

    temp.stateId = new Validator(fieldValues, "stateId")
      .selectedOption(0, "The User's state is required.")
      .toString();

    temp.stateName = new Validator(fieldValues, "stateName")
      .validateIf(fieldValues.countryId !== COUNTRIES.USA)
      .isRequired("The User's state is required.")
      .maxLength(50, "Only 50 character are allowed")
      .toString();

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };
  const { values, setValues, errors, setErrors, handleInputChange } = useForm(
    initialValues,
    true,
    validate
  );
  const { zipValue } = useZipCode(values.zip);
  const { execute, isLoading: isLoadingInfo } =
    useAsyncQuery<IUserContactInfoSignup>(userService.getForContactInfo, {
      hideSuccessfulMessage: true,
      onSuccess: (result) => {
        if (!result.copyDataToCompany) result.copyDataToCompany = true;
        if (!result.countryId) result.countryId = COUNTRIES.USA;
        setValues({ ...result });
      },
    });

  const { execute: executeProfie, isSubmitting } = useAsyncMutation(
    userService.updateProfile,
    {
      hideSuccessfulMessage: true,
    }
  );

  const { execute: executeAddress, isSubmitting: isSubmittingAddress } =
    useAsyncMutation(CompanyService.updateAddress, {
      hideSuccessfulMessage: true,
    });

  useEffect(() => {
    const getData = async () => {
      await execute();
    };
    const getTimeZone = async () => {
      const currentTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const response = await timeZonesService.getcurrentZone(currentTimeZone);
      setValues({ ...values, timeZoneId: response.data.id });
    };
    if (values.companyId === 0) getData();
    else {
      getTimeZone();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.companyId]);

  useEffect(() => {
    if (
      zipValue?.countryId !== undefined &&
      values.countryId === COUNTRIES.USA
    ) {
      setValues({ ...values, city: zipValue.city, stateId: zipValue.stateId });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [zipValue]);

  useEffect(() => {
    setCountryId(values.countryId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.countryId]);

  const row = 6;

  const cancelHandler = () => {
    setModalCancel(true);
  };

  const saveHandler = async () => {
    if (!validate()) return;
    await executeProfie(values);
    if (values.copyDataToCompany) {
      let copyValues = { ...values };
      copyValues.unionAffiliated = false;
      await executeAddress(copyValues);
    }
    await handleNext(false);
  };

  return (
    <>
      <FormTitleBox title={"Contact Information "}>
        <Grid container spacing={5}>
          <Grid item xs={row}>
            <FormText
              name="name"
              label="First Name"
              value={values.name}
              onChange={handleInputChange}
              error={errors.name}
              showSkeleton={isLoadingInfo}
            />
          </Grid>
          <Grid item xs={row}>
            <FormText
              name="lastName"
              label="Last Name"
              value={values.lastName}
              onChange={handleInputChange}
              error={errors.lastName}
              showSkeleton={isLoadingInfo}
            />
          </Grid>
          <Grid item xs={row}>
            <FormText
              name="address"
              label="Address"
              value={values.address}
              onChange={handleInputChange}
              error={errors.address}
              showSkeleton={isLoadingInfo}
            />
          </Grid>
          <Grid item xs={row}>
            <FormText
              name="address2"
              label="Address 2"
              value={values.address2}
              onChange={handleInputChange}
              error={errors.address2}
              showSkeleton={isLoadingInfo}
            />
          </Grid>
          <Grid item xs={row}>
            <FormSelect
              name="countryId"
              label="Country"
              value={values.countryId}
              onChange={handleInputChange}
              options={countriesKeyValue}
              error={errors.countryId}
              showSkeleton={isLoadingInfo}
            />
          </Grid>
          <Grid item xs={row}>
            <FormText
              name="zip"
              label="Zip Code"
              value={values.zip}
              onChange={handleInputChange}
              error={errors.zip}
              showSkeleton={isLoadingInfo}
            />
          </Grid>
          <Grid item xs={row}>
            <FormText
              name="city"
              label="City"
              value={values.city}
              onChange={handleInputChange}
              error={errors.city}
              showSkeleton={isLoadingInfo}
            />
          </Grid>

          {values.countryId !== Number.MIN_SAFE_INTEGER &&
          values.countryId === COUNTRIES.USA ? (
            <Grid item xs={row}>
              <FormSelect
                name="stateId"
                label="State"
                value={values.stateId}
                onChange={handleInputChange}
                options={states}
                error={errors.stateId}
                showSkeleton={isLoadingInfo}
              />
            </Grid>
          ) : (
            <Grid item xs={row}>
              <FormText
                name="stateName"
                label="State"
                value={values.stateName}
                onChange={handleInputChange}
                error={errors.stateName}
                showSkeleton={isLoadingInfo}
              />
            </Grid>
          )}
          <Grid item xs={row}>
            <FormSelect
              name="timeZoneId"
              label="Time zone"
              value={values.timeZoneId?.toString() ?? ""}
              onChange={handleInputChange}
              options={timeZoneKeyValue}
              error={errors.timeZoneId}
              disable={false}
              showSkeleton={isLoadingInfo}
            />
          </Grid>
          <Grid item xs={row}>
            <FormCheckBox
              name={"copyDataToCompany"}
              value={values.copyDataToCompany}
              onChange={handleInputChange}
              label={
                "Use my contact information as my Company's Contact Information "
              }
            ></FormCheckBox>
            {!values.copyDataToCompany && (
              <Alert severity="warning">
                You will need to complete your Company's Contact Information at
                the first login{" "}
              </Alert>
            )}
          </Grid>
          <Grid item xs={12}>
            <Stack
              direction="row"
              spacing={2}
              justifyContent="center"
              alignItems="center"
            >
              <FormCancelButton
                onClick={cancelHandler}
                isSubmitting={isSubmitting || isSubmittingAddress}
              />
              <FormSaveButton
                text="Save & Continue"
                onClick={saveHandler}
                isSubmitting={isSubmitting || isSubmittingAddress}
              />
            </Stack>
          </Grid>
        </Grid>
      </FormTitleBox>
    </>
  );
}

export default ContactInformation;
