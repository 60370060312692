import React, { useState } from "react";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import {
  Grid,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
  Typography,
  Card as MuiCard,
  CardContent,
} from "@mui/material";
import { spacing } from "@mui/system";
import ElavonPaymentLogFilter from "./ElavonPaymentLogFilter";
import { ElavonPaymentFilter } from "../types";
import ElavonPaymentLogGrid from "./ElavonPaymentLogGrid";

const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Card = styled(MuiCard)(spacing);

const ElavonPaymentLog = () => {
  const [filters, setFilters] = useState<ElavonPaymentFilter | null>(null);
  const [refresh, setRefresh] = useState(false);

  const searchHandler = (filters: ElavonPaymentFilter) => {
    setFilters(filters);
    setRefresh(!refresh);
  };

  return (
    <>
      <Helmet title={"AMP"} />
      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            {"AMP"}
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} to="/">
              Dashboard
            </Link>
            <Typography>{"AMP"}</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item></Grid>
      </Grid>

      <Divider my={6} />

      <Card mb={6}>
        <CardContent>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={12}>
                  <ElavonPaymentLogFilter onSearch={searchHandler} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <ElavonPaymentLogGrid filter={filters} refresh={refresh} />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
};

export default ElavonPaymentLog;
