import { ChillerStudyProvider } from "../context/ChillerStudyContext";
import ChillerStep6 from "./ChillerStep6";
const Index = () => {
  return (
    <ChillerStudyProvider>
      <ChillerStep6 />
    </ChillerStudyProvider>
  );
};

export default Index;
