import {
  InfoOutlined,
  KeyOutlined,
  ManageSearch,
  ContentCopy,
  Photo,
  Add,
  ElectricBolt,
  SubdirectoryArrowRight,
  KeyboardArrowDown,
  KeyboardArrowUp,
  SpeedOutlined,
  ChatBubbleOutlineOutlined,
} from "@mui/icons-material";
import {
  IconButton as IconButtonMui,
  Tooltip,
  Zoom,
  styled,
} from "@mui/material";
import {
  EditIcon,
  DoNotDisturbIcon,
  CreditCardOffIcon,
  CreditCardIcon,
  FolderIcon,
  PeopleAltIcon,
  CheckCircleOutlineIcon,
  ForwardIcon,
  DeleteIcon,
  FileDownloadIcon,
  ArrowBackIcon,
  AppRegistrationIcon,
  FileUploadIcon,
  EmailOutlinedIcon,
  CompareArrowsIcon,
  AssessmentIcon,
  HomeRepairServiceIcon,
  CloseIcon,
  CircleOutlinedIcon,
  LocationOnOutlinedIcon,
  SwapHorizOutlinedIcon,
  CopyAllOutlinedIcon,
  ShieldIcon,
  LocalAtmIcon,
  StarIcon,
} from "../icons";

interface SpreadSheetActionButtonProps {
  type:
    | "edit"
    | "disable"
    | "enable"
    | "creditCard"
    | "creditCardOff"
    | "folder"
    | "users"
    | "forward"
    | "delete"
    | "download"
    | "view"
    | "backward"
    | "info"
    | "key"
    | "items"
    | "copy"
    | "picture"
    | "upload"
    | "add"
    | "email"
    | "electric"
    | "compare"
    | "report"
    | "tools"
    | "subItem"
    | "down"
    | "up"
    | "emptyCircle"
    | "gauge"
    | "location"
    | "chat"
    | "close"
    | "copy2"
    | "shield"
    | "swap"
    | "money"
    | "star";

  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
  onMouseUp?: (e: React.MouseEvent<HTMLElement>) => void;
  tooltip?: string;
  disabled?: boolean;
  [key: string]: any;
  hidden?: boolean;
  color?:
    | "info"
    | "inherit"
    | "default"
    | "success"
    | "primary"
    | "secondary"
    | "error"
    | "warning";
}

const IconButton = styled(IconButtonMui)`
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
`;

const SpreadSheetActionButton = (props: SpreadSheetActionButtonProps) => {
  const { onClick, onMouseUp, type, tooltip, disabled, hidden, color } = props;

  const IconType = () => {
    switch (type) {
      case "edit":
        return <EditIcon />;
      case "disable":
        return <DoNotDisturbIcon />;
      case "enable":
        return <CheckCircleOutlineIcon />;
      case "creditCard":
        return <CreditCardIcon />;
      case "creditCardOff":
        return <CreditCardOffIcon />;
      case "folder":
        return <FolderIcon />;
      case "users":
        return <PeopleAltIcon />;
      case "forward":
        return <ForwardIcon />;
      case "delete":
        return <DeleteIcon />;
      case "download":
        return <FileDownloadIcon />;
      case "view":
        return <ManageSearch />;
      case "backward":
        return <ArrowBackIcon />;
      case "info":
        return <InfoOutlined />;
      case "key":
        return <KeyOutlined />;
      case "items":
        return <AppRegistrationIcon />;
      case "copy":
        return <ContentCopy />;
      case "picture":
        return <Photo />;
      case "upload":
        return <FileUploadIcon />;
      case "add":
        return <Add />;
      case "email":
        return <EmailOutlinedIcon />;
      case "electric":
        return <ElectricBolt />;
      case "compare":
        return <CompareArrowsIcon />;
      case "report":
        return <AssessmentIcon />;
      case "tools":
        return <HomeRepairServiceIcon />;
      case "subItem":
        return <SubdirectoryArrowRight />;
      case "down":
        return <KeyboardArrowDown />;
      case "up":
        return <KeyboardArrowUp />;
      case "emptyCircle":
        return <CircleOutlinedIcon />;
      case "gauge":
        return <SpeedOutlined />;
      case "close":
        return <CloseIcon />;
      case "location":
        return <LocationOnOutlinedIcon />;
      case "chat":
        return <ChatBubbleOutlineOutlined />;
      case "swap":
        return <SwapHorizOutlinedIcon />;
      case "copy2":
        return <CopyAllOutlinedIcon />;
      case "shield":
        return <ShieldIcon />;
      case "money":
        return <LocalAtmIcon />;
      case "star":
        return <StarIcon />;
    }
  };

  return (
    <Tooltip TransitionComponent={Zoom} title={tooltip ?? ""}>
      <span>
        <IconButton
          aria-label="delete"
          size="large"
          onClick={onClick}
          onMouseUp={onMouseUp}
          disabled={disabled}
          hidden={hidden}
          color={color}
        >
          <IconType />
        </IconButton>
      </span>
    </Tooltip>
  );
};

export default SpreadSheetActionButton;
