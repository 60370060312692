import React, { useEffect, useState } from "react";
import { useLog } from "src/hooks";
import useFormTyped from "src/hooks/useFormTyped";
import towerService from "src/services/study/towerService";
import {
  IChillerTowerCondenser,
  IChillerTowerFanMotor,
  ITower,
} from "src/ts/interfaces/study/tower";
import { Validator } from "src/ts/types";

interface ITowerContext {
  towerCondenserValues: IChillerTowerCondenser;
  setTowerCondenserValues: (val: IChillerTowerCondenser) => void;
  handleInputTowerCondenserChange: (e: any) => void;
  errorTowerCondenser: Record<string, string>;
  saveTowerCondenser: (
    updateArra?: boolean,
    showSaveMessage?: boolean
  ) => Promise<void>;
  isLoading: boolean;
  towerCondensers: IChillerTowerCondenser[];
  setTowerCondensers: (val: IChillerTowerCondenser[]) => void;
  fanMotors: IChillerTowerFanMotor[] | null;
  setFanMotors: (val: IChillerTowerFanMotor[]) => void;
  fanMotorValues: IChillerTowerFanMotor;
  setFanMotorValues: (val: IChillerTowerFanMotor) => void;
  handleInputfanMotorChange: (e: any) => void;
  errorfanMotor: Record<string, string>;
  validatefanMotor: any;

  updateFanMotor: (fanMotor: IChillerTowerFanMotor) => Promise<void>;
  showEffPfLimitModal: boolean;
  setShowEffPfLimitModal: (val: boolean) => void;
  effPfMessage: string;
  getTowerStudy: (id: number) => Promise<ITower>;
  towerValues: ITower;
  handleInputTowerChange: (e: any) => void;
  updateTowerStudy: () => Promise<void>;
}

type TowerStudyProviderProps = {
  children: React.ReactNode;
};

const TowerStudyContext = React.createContext<ITowerContext | null>(null);

const initialComponent: any = {};
const initialFanMotor: any = {};
const initialTowerStudy: any = {};

function TowerStudyProvider({ children }: TowerStudyProviderProps) {
  const { log } = useLog();
  const [isLoading, setIsLoading] = useState(false);
  const [towerCondensers, setTowerCondensers] = useState<
    IChillerTowerCondenser[]
  >([]);

  const [fanMotors, setFanMotors] = useState<IChillerTowerFanMotor[] | null>(
    null
  );

  const validateTowerCondenser: any = (fieldValues = towerCondenserValues) => {
    let temp: Record<string, string> = { ...errorTowerCondenser };

    setComponentErrors({
      ...temp,
    });

    if (fieldValues === towerCondenserValues)
      return Object.values(temp).every((x) => x === "");
  };

  const {
    values: towerCondenserValues,
    setValues: setTowerCondenserValues,
    errors: errorTowerCondenser,
    setErrors: setComponentErrors,
    handleInputChange: handleInputTowerCondenserChange,
  } = useFormTyped<IChillerTowerCondenser>(
    initialComponent,
    false,
    validateTowerCondenser
  );

  const validatefanMotor: any = (fieldValues = fanMotorValues) => {
    let temp: Record<string, string> = { ...errorfanMotor };

    temp.fanMotorManufacturerId = new Validator(
      fieldValues,
      "fanMotorManufacturerId"
    )
      .selectedOption(null, "Select an option.")
      ?.toString();

    temp.otherFanMotorManufacturer = new Validator(
      fieldValues,
      "otherFanMotorManufacturer"
    )
      .maxLength(199, "More than 199")
      .toString();

    temp.model = new Validator(fieldValues, "model")
      .maxLength(50, "Model is too long")
      .toString();

    temp.serialNumber = new Validator(fieldValues, "serialNumber")
      .maxLength(50, "Serial number is too long")
      .toString();

    if (!fieldValues.efficiencyUnknown) {
      temp.nameplateRatedEff = new Validator(fieldValues, "nameplateRatedEff")
        .isRequired("Eff is invalid")
        .toString();

      temp.nameplateRatedPF = new Validator(fieldValues, "nameplateRatedPF")
        .isRequired("PF is invalid")
        .toString();
    } else {
      temp.nameplateRatedEff = "";
      temp.nameplateRatedPF = "";
    }

    setFanMotorErros({
      ...temp,
    });

    if (fieldValues === fanMotorValues)
      return Object.values(temp).every((x) => x === "");
  };

  const {
    values: fanMotorValues,
    setValues: setFanMotorValues,
    errors: errorfanMotor,
    handleInputChange: handleInputfanMotorChange,
    setErrors: setFanMotorErros,
  } = useFormTyped<IChillerTowerFanMotor>(
    initialFanMotor,
    false,
    validatefanMotor
  );

  useEffect(() => {
    const getData = async () => {
      const res = await towerService.getFanMotorsByTowerCondenserId(
        towerCondenserValues.id
      );

      if (res.data.length > 0) {
        setFanMotors(res.data);

        if (res.data.length > 0) {
          setFanMotorValues(res.data[0]);
        }
      } else {
        const chillerFanMotor: any = {
          chillerTowerCondenserId: towerCondenserValues.id,
        };

        const fanMotorRes = await towerService.addFanMotor(chillerFanMotor);
        if (fanMotorRes.data !== null) {
          setFanMotors([fanMotorRes.data]);
          setFanMotorValues(fanMotorRes.data);
        }
      }
    };

    if (towerCondenserValues?.id !== null && towerCondenserValues?.id > 0) {
      getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [towerCondenserValues.id]);

  const saveTowerCondenser = async (
    updateArra: boolean = true,
    showSaveMessage: boolean = true
  ) => {
    try {
      setIsLoading(true);

      const res = await towerService.updateTowerCondenser(
        towerCondenserValues.id,
        towerCondenserValues
      );

      if (showSaveMessage) log.info("Updated successfully");

      const newTowerCondense: IChillerTowerCondenser = {
        ...towerCondenserValues,
        condenseraterSupplyTempComparation:
          res.data.condenseraterSupplyTempComparation,
        condenseraterReturnTempComparation:
          res.data.condenseraterReturnTempComparation,
        condenserDesgin: res.data.condenserDesgin,
        condenserActual: res.data.condenserActual,
        condenserPercentage: res.data.condenserPercentage,
        condenserTowerApproach: res.data.condenserTowerApproach,
        coolingTowerEfficiency: res.data.coolingTowerEfficiency,
        actualTowerBTUH: res.data.actualTowerBTUH,
      };

      setTowerCondenserValues(newTowerCondense);

      if (updateArra) updateArray(newTowerCondense);
    } catch (error: any) {
      log.error(error?.message?.exceptionMessage ?? "Something went wrong");
    } finally {
      setIsLoading(false);
    }
  };

  const updateArray = (newTowerCondenser: IChillerTowerCondenser) => {
    const index = towerCondensers.findIndex(
      (x) => x.id === newTowerCondenser.id
    );

    if (index > -1) {
      const newItems = towerCondensers.map((item, i) => {
        return i === index ? newTowerCondenser : item;
      });

      setTowerCondensers(newItems);
    }
  };

  const [showEffPfLimitModal, setShowEffPfLimitModal] = useState(false);
  const [effPfMessage, setEffPfMessage] = useState("");

  const updateFanMotor = async (fanMotor: IChillerTowerFanMotor) => {
    try {
      await towerService.updateFanMotor(fanMotor.id, fanMotor);

      const calculateTowerFormulaRes = await towerService.calculateTowerFormula(
        towerCondenserValues.id,
        towerCondenserValues
      );

      setTowerCondenserValues({
        ...towerCondenserValues,
        condenserTowerApproach:
          calculateTowerFormulaRes.data.condenserTowerApproach,
        coolingTowerEfficiency:
          calculateTowerFormulaRes.data.coolingTowerEfficiency,
        actualTowerBTUH: calculateTowerFormulaRes.data.actualTowerBTUH,
        actualKws: calculateTowerFormulaRes.data.actualKws,
      });

      const getFanMotorReq = await towerService.getFanMotorsById(
        fanMotorValues.id
      );

      const chillerFormula = getFanMotorReq.data.chillerFormula;
      const eff = chillerFormula.eff ? chillerFormula.eff / 100 : 0.0;

      setFanMotorValues({
        ...getFanMotorReq.data,
        chillerFormula: {
          ...chillerFormula,
          eff: eff,
        },
      });

      if (eff > 1.0) {
        setEffPfMessage("Improperly inputed data EFF can’t exceed 1.0");
        setShowEffPfLimitModal(true);
      } else if (chillerFormula.powerFactor > 1.0) {
        setEffPfMessage("Improperly inputed data PF can’t exceed 1.0");
        setShowEffPfLimitModal(true);
      }
    } catch (error: any) {
      log.error(error?.message?.exceptionMessage ?? "Something went wrong");
    }
  };

  const validateTower: any = (fieldValues = towerValues) => {
    let temp: Record<string, string> = { ...errorfanMotor };

    setComponentErrors({
      ...temp,
    });

    if (fieldValues === towerValues)
      return Object.values(temp).every((x) => x === "");
  };

  const {
    values: towerValues,
    setValues: setTowerValues,
    handleInputChange: handleInputTowerChange,
  } = useFormTyped<ITower>(initialTowerStudy, false, validateTower);

  const getTowerStudy = async (id: number) => {
    const towerRes = await towerService.getBydId(id);
    setTowerValues(towerRes.data);
    return towerRes.data;
  };

  const updateTowerStudy = async () => {
    await towerService.update(towerValues.id, towerValues);
  };

  return (
    <TowerStudyContext.Provider
      value={{
        towerCondenserValues,
        setTowerCondenserValues,
        handleInputTowerCondenserChange,
        errorTowerCondenser,
        saveTowerCondenser,
        isLoading,
        towerCondensers,
        setTowerCondensers,
        fanMotors,
        setFanMotors,
        fanMotorValues,
        setFanMotorValues,
        handleInputfanMotorChange,
        errorfanMotor,
        validatefanMotor,
        updateFanMotor,
        showEffPfLimitModal,
        setShowEffPfLimitModal,
        effPfMessage,
        towerValues,
        handleInputTowerChange,
        getTowerStudy,
        updateTowerStudy,
      }}
    >
      {children}
    </TowerStudyContext.Provider>
  );
}

export { TowerStudyContext, TowerStudyProvider };
