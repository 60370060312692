import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import {
  Card as MuiCard,
  CardContent,
  Divider as MuiDivider,
  Grid,
} from "@mui/material";
import { spacing } from "@mui/system";
import { useAsyncMutation, useAsyncQuery } from "src/hooks";
import HeaderPage from "src/components/page/HeaderPage";
import templateServices from "src/services/templateServices";
import { useNavigate, useParams } from "react-router-dom";
import Tolerances from "../projectStudies/components/Tolerances";
import { ProjectDTO } from "src/ts/interfaces/project/projectDto";
import { FormButton, FormCheckBox } from "src/components/formControls";
import { ITemplate } from "src/ts/interfaces/template";
import FormNextButton from "src/components/formControls/FormNextButton";
import { East as EastIcon } from "@mui/icons-material";
const Divider = styled(MuiDivider)(spacing);
const Card = styled(MuiCard)(spacing);

const ProjectTolerances = () => {
  const navigate = useNavigate();
  let { template } = useParams<{ template: string }>();
  const [saveContinue, setSaveContinue] = useState(false);
  const { execute, data, setData, isLoading } = useAsyncQuery<ProjectDTO>(
    templateServices.getProject
  );
  const {
    execute: executeTemplate,
    data: dataTemplate,
    setData: setDataTemplate,
  } = useAsyncQuery<ITemplate>(templateServices.getById);

  const { execute: executeStatus } = useAsyncMutation(
    templateServices.changeStatus,
    { successfulMessage: "The status was changed" }
  );

  useEffect(() => {
    const getData = async () => {
      await execute(template);
      await executeTemplate(template);
    };

    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeStatus = async (e: any) => {
    setDataTemplate({ ...dataTemplate, enabled: e.target.value });
    await executeStatus(dataTemplate.projectId, e.target.value);
  };

  const handleNextStep = () => {
    navigate(`/app/templateStudies/${template}`);
  };

  return (
    <>
      <HeaderPage
        title={`Template: ${dataTemplate ? dataTemplate.name : ""}`}
        parentText="Templates"
        parentLink="/app/templates"
      />
      <Divider my={6} />
      <Card mb={6}>
        <CardContent>
          <Grid container xs={12}>
            {dataTemplate && !dataTemplate.isDefault && (
              <Grid item xs={12} textAlign={"center"}>
                <FormCheckBox
                  name={"enabled"}
                  label={`${
                    dataTemplate?.enabled ? "Deactivate" : "Activate"
                  } This Template`}
                  value={dataTemplate?.enabled}
                  onChange={handleChangeStatus}
                ></FormCheckBox>
              </Grid>
            )}
            <Grid item>
              {data && dataTemplate && (
                <Tolerances
                  project={data}
                  setProject={setData}
                  disable={dataTemplate?.isDefault}
                  isTemplate={true}
                  isLoading={isLoading}
                  readOnly={dataTemplate?.isDefault}
                  save={saveContinue}
                  onSave={handleNextStep}
                />
              )}
            </Grid>
            <Grid item xs={12} textAlign={"center"} p={3}>
              {!dataTemplate?.isDefault && (
                <FormButton
                  text={"Save & Continue"}
                  onClick={() => {
                    setSaveContinue(true);
                  }}
                  endIcon={<EastIcon />}
                  size="small"
                ></FormButton>
              )}
              {dataTemplate?.isDefault && (
                <FormNextButton
                  onClick={handleNextStep}
                  isSubmitting={false}
                ></FormNextButton>
              )}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
};

export default ProjectTolerances;
