import axios from "src/utils/axios";
import { ICustomData, IKeyValue, ISimpleCatalogType } from "src/ts/interfaces";
const getKeyValues = () => {
  return new Promise<ICustomData>((resolve, reject) => {
    axios
      .get<Array<ISimpleCatalogType>>(`MotorFrameCategory`)
      .then((response) => {
        const keyValuePair: IKeyValue<number, string>[] = response.data.map(
          (item) => {
            return {
              key: item.id!,
              value: item.name,
            };
          }
        );

        resolve({ data: keyValuePair });
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const motorFrameCategorySevice = {
  getKeyValues,
};
export default motorFrameCategorySevice;
