import { useState } from "react";
import Popup from "src/components/Popup";
import {
  FormAcceptButton,
  FormCancelButton,
  FormSelect,
} from "src/components/formControls";
import { Grid, Stack, Typography } from "@mui/material";
import useForm, { Form } from "src/hooks/useForm";
import { TicketService } from "src/services";
import useLog from "src/hooks/useLog";

interface AssignTicketProps {
  showAssignTicketPopup: boolean;
  setShowAssignTicketPopup: (val: boolean) => void;
  informationId: any;
  administratorList: any;
  onRefreshGrid: () => void;
}
const initialValue = {
  assignTo: -1,
};

export default function AssignTicketPopUp({
  showAssignTicketPopup,
  setShowAssignTicketPopup,
  informationId,
  administratorList,
  onRefreshGrid,
}: AssignTicketProps) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const handleClosePopUp = () => {
    setShowAssignTicketPopup(false);
  };
  const { log } = useLog();

  const validate = (fieldValues = values) => {
    let temp: Record<string, string> = { ...errors };

    if (fieldValues.hasOwnProperty("assignTo")) {
      temp.assignTo =
        fieldValues.assignTo !== -1 ? "" : "This field is required.";
    }
    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, errors, setErrors, handleInputChange } = useForm(
    initialValue,
    false,
    validate
  );

  const handleSave = async () => {
    if (!validate()) {
      log.error(
        "Please select an administrator who will take care of the ticket."
      );
      return;
    }
    setIsSubmitting(true);
    await TicketService.postAssign({
      userId: values.assignTo,
      ticketId: informationId.id,
    });
    onRefreshGrid();
    log.info("The ticket was succesfully assigned.");
    setShowAssignTicketPopup(false);
    setIsSubmitting(false);
  };

  return (
    <>
      <Popup
        title="Assign Ticket"
        openPopup={showAssignTicketPopup}
        setOpenPopup={setShowAssignTicketPopup}
        onClose={handleClosePopUp}
      >
        <Form>
          <>
            <Grid container p={2} paddingLeft={0}>
              <Grid item xs={2} textAlign={"right"}>
                <Typography fontWeight={"bold"}>Title:</Typography>
              </Grid>
              <Grid item xs={10} paddingLeft={2} paddingBottom={3}>
                <Typography>{informationId.title}</Typography>
              </Grid>
              <Grid item xs={2} textAlign={"right"}>
                <Typography fontWeight={"bold"}>Assign To:</Typography>
              </Grid>
              <Grid item xs={10} paddingLeft={2} paddingBottom={1}>
                <FormSelect
                  name="assignTo"
                  label=""
                  value={values.assignTo}
                  options={administratorList}
                  defaultValue={{ key: -1, value: "Please Select.." }}
                  onChange={handleInputChange}
                />
              </Grid>
            </Grid>
          </>
        </Form>
        <Stack
          direction="row"
          spacing={2}
          justifyContent="flex-end"
          alignItems="flex-start"
        >
          <FormCancelButton
            onClick={() => {
              setShowAssignTicketPopup(false);
            }}
            isSubmitting={isSubmitting}
          />
          <FormAcceptButton onClick={handleSave} isSubmitting={isSubmitting} />
        </Stack>
      </Popup>
    </>
  );
}
