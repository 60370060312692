import React, { ChangeEvent, useEffect, useState } from "react";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import {
  CardContent,
  Grid,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  SelectChangeEvent,
  Typography,
  Stack,
} from "@mui/material";
import { Box, spacing } from "@mui/system";
import DropZone from "src/components/DropZone";
import LocalEnhancedTable from "src/components/localTable/LocalTable";
import { ISysAdminDocument } from "src/ts/interfaces";
import { ColumnType } from "src/types/enhancedTable";
import { SysAdminDocumentService } from "src/services";
import DialogMessagePopup from "src/components/DialogMessagePopup";
import FileUtils from "src/utils/file";
import useLog from "src/hooks/useLog";
import useLoading from "src/hooks/useLoading";
import {
  GridActionButton,
  GridUploadButton,
} from "src/components/gridControls";
import { FormSelect, FormText } from "src/components/formControls";
import { Permission as PermissionTypes } from "src/ts/enums";
import { usePermissions } from "src/hooks";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const typeDocument = [
  { key: 1, value: "Addendums" },
  { key: 2, value: "General/Miscellaneous" },
  { key: 3, value: "Submittals" },
  { key: 4, value: "Specifications" },
  { key: 5, value: "Prints/Plans" },
  { key: 6, value: "Legal" },
];

interface EditValueProps {
  informationRow: ISysAdminDocument;
  initialValue: number;
}

const UpdateTypeDocument = (props: EditValueProps) => {
  const { initialValue, informationRow } = props;
  const [value, setValue] = useState(initialValue);

  const { log } = useLog();
  const handleSetValue = async (event: SelectChangeEvent<any>) => {
    if (event.target.value !== -1) {
      try {
        setValue(event.target.value);
        await SysAdminDocumentService.updateTypeDocument(
          informationRow.id.toString(),
          {
            ...informationRow,
            folderType: event.target.value,
          }
        );
        log.info("The type was saved.");
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      }
    }
  };

  return (
    <FormSelect
      name="value"
      label=""
      value={value.toString()}
      onChange={handleSetValue}
      options={typeDocument}
      defaultValue={{ key: -1, value: "Select a type" }}
    />
  );
};

const getColumns = (
  handleDeleteDocument: (row: ISysAdminDocument) => void,
  downloadDocument: (row: ISysAdminDocument) => void,
  fullAccess: boolean | undefined
): ColumnType[] => [
  {
    id: "name",
    label: "Name",
    type: "string",
    sort: true,
    width: "76%",
  },
  {
    id: "status",
    label: "Actions",
    type: "custom",
    sort: false,
    callback: (row: ISysAdminDocument) => {
      return (
        <div>
          <Box mr={2}>
            <GridActionButton
              type="download"
              onClick={() => downloadDocument(row)}
              tooltip="Download"
            />
            {fullAccess && (
              <GridActionButton
                type="delete"
                onClick={() => handleDeleteDocument(row)}
                tooltip="Delete"
              />
            )}
          </Box>
        </div>
      );
    },
  },
];

const getColumnsBOMA = (
  handleDeleteDocument: (row: ISysAdminDocument) => void,
  downloadDocument: (row: ISysAdminDocument) => void,
  fullAccess: boolean | undefined
): ColumnType[] => [
  {
    id: "name",
    label: "Name",
    type: "string",
    sort: true,
  },
  {
    id: "folderType",
    label: "Type",
    type: "custom",
    sort: true,
    callback: (row: ISysAdminDocument) => {
      return (
        <>
          <UpdateTypeDocument
            informationRow={row}
            initialValue={row.folderType || -1}
          />
        </>
      );
    },
  },
  {
    id: "status",
    label: "Actions",
    type: "custom",
    sort: false,
    callback: (row: ISysAdminDocument) => {
      return (
        <div>
          <Box mr={2}>
            <GridActionButton
              type="download"
              onClick={() => downloadDocument(row)}
              tooltip="Download"
            />
            {fullAccess && (
              <GridActionButton
                type="delete"
                onClick={() => handleDeleteDocument(row)}
                tooltip="Delete"
              />
            )}
          </Box>
        </div>
      );
    },
  },
];

const getColumnsForSample = (
  handleDeleteDocument: (row: ISysAdminDocument) => void,
  downloadDocument: (row: ISysAdminDocument) => void,
  handleFileUpload: (e: ChangeEvent<HTMLInputElement>, report: string) => void,
  fullAccess: boolean | undefined
): ColumnType[] => [
  {
    id: "name",
    label: "Name",
    type: "string",
    sort: true,
    width: "76%",
  },
  {
    id: "status",
    label: "Actions",
    type: "custom",
    sort: false,
    callback: (row: ISysAdminDocument) => {
      return (
        <div>
          <Box mr={2}>
            {row.path === null ? (
              fullAccess && (
                <GridUploadButton
                  accept=".pdf"
                  onChange={(e: any) => handleFileUpload(e, row.name)}
                />
              )
            ) : (
              <>
                <GridActionButton
                  tooltip="Download"
                  onClick={() => downloadDocument(row)}
                  type={"download"}
                />
                {fullAccess && (
                  <GridActionButton
                    tooltip="Delete"
                    onClick={() => handleDeleteDocument(row)}
                    type={"delete"}
                  />
                )}
              </>
            )}
          </Box>
        </div>
      );
    },
  },
];

enum SysAdminDocumentTypeEnum {
  ExcelTemplates = "excelTemplates",
  Documents = "documents",
  BomaProjDoc = "bomaProjDoc",
  SampleReports = "sampleReports",
  TrainingDoc = "trainingDoc",
  AshraeDoc = "ashraeDoc",
  PretestingAHU = "pretestingAHU",
}

const SysAdminDocuments = () => {
  const { log } = useLog();
  const { startRequest, endRequest } = useLoading();

  const [currentRow, setCurrentRow] = useState<ISysAdminDocument>();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [refreshPage, setRefreshPage] = useState(false);

  const [documents, setDocuments] = useState<ISysAdminDocument[]>([]);
  const [bomaDocuments, setBomaDocuments] = useState<ISysAdminDocument[]>([]);
  const [ashraeDocuments, setAshraeDocuments] = useState<ISysAdminDocument[]>(
    []
  );
  const [trainingDocuments, setTrainingDocuments] = useState<
    ISysAdminDocument[]
  >([]);
  const [sampleDocuments, setSampleDocuments] = useState<ISysAdminDocument[]>(
    []
  );
  const [pretestingAHU, setPretestingAHU] = useState<ISysAdminDocument[]>([]);

  const [
    disableTemplateUploaderAshraeDoc,
    setDisableTemplateUploaderAshraeDoc,
  ] = useState(false);
  const [disabledButton, setDisabledButton] = useState(false);
  const [valuesDeleteCode, setValuesDeleteCode] = useState({ code: "" });
  const { fullAccess } = usePermissions(PermissionTypes.AMP_Documents);

  useEffect(() => {
    const loadDocuments = async () => {
      try {
        const response = await SysAdminDocumentService.getAll();
        setTrainingDocuments(
          response.data.filter(
            (item: any) => item.type === SysAdminDocumentTypeEnum.TrainingDoc
          )
        );
        const totalAshraeDocuments = response.data.filter(
          (item: any) => item.type === SysAdminDocumentTypeEnum.AshraeDoc
        );
        setAshraeDocuments(totalAshraeDocuments);
        totalAshraeDocuments.length > 0
          ? setDisableTemplateUploaderAshraeDoc(true)
          : setDisableTemplateUploaderAshraeDoc(false);

        setDocuments(
          response.data.filter(
            (item: any) => item.type === SysAdminDocumentTypeEnum.Documents
          )
        );

        setBomaDocuments(
          response.data.filter(
            (item: any) => item.type === SysAdminDocumentTypeEnum.BomaProjDoc
          )
        );

        setSampleDocuments(
          response.data.filter(
            (item: any) => item.type === SysAdminDocumentTypeEnum.SampleReports
          )
        );

        setPretestingAHU(
          response.data.filter(
            (item: any) => item.type === SysAdminDocumentTypeEnum.PretestingAHU
          )
        );
        //
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      }
    };

    loadDocuments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshPage]);

  useEffect(() => {
    setValuesDeleteCode({
      code: "",
    });
  }, [showDeleteModal]);

  const handleDeleteDocument = (row: ISysAdminDocument) => {
    setCurrentRow(row);
    setShowDeleteModal(true);
  };

  const downloadDocument = async (row: ISysAdminDocument) => {
    if (row && row.id) {
      try {
        setIsSubmitting(true);
        const response = await SysAdminDocumentService.downloadFile(
          Number(row.id)
        );

        let fileName = row.name;
        if (
          row.name.indexOf(".pdf") === -1 &&
          row.name.indexOf(".xls") === -1 &&
          row.name.indexOf(".xslx") === -1
        ) {
          fileName = fileName + ".pdf";
        }

        FileUtils.downloadFile(response.data, fileName);
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      } finally {
        setIsSubmitting(false);
      }
    } else {
    }
  };

  const handleTrainingDocumentAccepted = async (file: File) => {
    await saveFile(file, SysAdminDocumentTypeEnum.TrainingDoc);
  };

  const handleAshraeDocumentAccepted = async (file: File) => {
    await saveFile(file, SysAdminDocumentTypeEnum.AshraeDoc);
  };

  const handleDocumentFileAccepted = async (file: File) => {
    await saveFile(file, SysAdminDocumentTypeEnum.Documents);
  };

  const handleBomaDocumentFileAccepted = async (file: File) => {
    await saveFile(file, SysAdminDocumentTypeEnum.BomaProjDoc);
  };

  const handlePretestingAHUFileAccepted = async (file: File) => {
    await saveFile(file, SysAdminDocumentTypeEnum.PretestingAHU);
  };

  const saveFile = async (file: File, fileType: string) => {
    try {
      setIsSubmitting(true);
      await SysAdminDocumentService.uploadFile({
        documentType: fileType,
        content: file,
      });
      setRefreshPage(!refreshPage);
    } catch (error: any) {
      log.error(error?.message?.exceptionMessage ?? "Something went wrong");
    } finally {
      setIsSubmitting(false);
    }
  };

  const deleteDocument = async () => {
    if (currentRow && currentRow.id) {
      try {
        setIsSubmitting(true);
        await SysAdminDocumentService.remove(currentRow.id.toString());
        setRefreshPage(!refreshPage);
        log.success("The document was deleted.");
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      } finally {
        setIsSubmitting(false);
        setValuesDeleteCode({ code: "" });
      }
    } else {
      setValuesDeleteCode({ code: "" });
    }
  };

  const handleFileUpload = async (
    e: ChangeEvent<HTMLInputElement>,
    report: string
  ) => {
    if (!e.target.files) {
      return;
    }
    const file = e.target.files[0];
    startRequest("uploadfile");
    await SysAdminDocumentService.uploadFile({
      documentType: SysAdminDocumentTypeEnum.SampleReports,
      content: file,
      name: report,
    });
    endRequest("uploadfile");
    log.success("File was upload succesfully");
    setRefreshPage(!refreshPage);
  };

  const columns = getColumns(
    handleDeleteDocument,
    downloadDocument,
    fullAccess
  );
  const columnsForSample = getColumnsForSample(
    handleDeleteDocument,
    downloadDocument,
    handleFileUpload,
    fullAccess
  );

  const columnsBOMA = getColumnsBOMA(
    handleDeleteDocument,
    downloadDocument,
    fullAccess
  );

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setValuesDeleteCode({
      ...valuesDeleteCode,
      [name]: value,
    });
    value === "delete" ? setDisabledButton(true) : setDisabledButton(false);
  };

  return (
    <React.Fragment>
      <Helmet title="Amp Documents" />
      <Typography variant="h3" gutterBottom display="inline">
        AMP Documents
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/">
          Dashboard
        </Link>
        <Typography>AMP Documents</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Card mb={6}>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Training documents
              </Typography>

              <Grid container spacing={6}>
                <Grid item md={12}>
                  <LocalEnhancedTable<ISysAdminDocument>
                    refreshGrid={false}
                    columns={columns}
                    data={trainingDocuments}
                    hidePagination={true}
                  />
                  {fullAccess && (
                    <DropZone
                      onFileAccepted={handleTrainingDocumentAccepted}
                      accept={{ "application/pdf": [".pdf"] }}
                      disabled={false}
                      disableMessage="Only one profile is allowed"
                      submiting={isSubmitting}
                    />
                  )}
                </Grid>
                <Grid item md={6}></Grid>
              </Grid>
            </CardContent>
          </Card>
          <Card mb={6}>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Documents
              </Typography>

              <Grid container spacing={6}>
                <Grid item md={12}>
                  <LocalEnhancedTable<ISysAdminDocument>
                    refreshGrid={false}
                    columns={columns}
                    data={documents}
                    hidePagination={true}
                  />
                  {fullAccess && (
                    <DropZone
                      onFileAccepted={handleDocumentFileAccepted}
                      accept={{ "application/pdf": [".pdf"] }}
                      disabled={false}
                      disableMessage="Only one profile is allowed"
                      submiting={isSubmitting}
                    />
                  )}
                </Grid>
                <Grid item md={6}></Grid>
              </Grid>
            </CardContent>
          </Card>
          <Card mb={6}>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                BOMA project requirements document
              </Typography>

              <Grid container spacing={6}>
                <Grid item md={12}>
                  <LocalEnhancedTable<ISysAdminDocument>
                    refreshGrid={false}
                    columns={columnsBOMA}
                    data={bomaDocuments}
                  />
                  {fullAccess && (
                    <DropZone
                      onFileAccepted={handleBomaDocumentFileAccepted}
                      accept={{ "application/pdf": [".pdf"] }}
                      disabled={false}
                      disableMessage="Only five certifications are allowed"
                      submiting={isSubmitting}
                    />
                  )}
                </Grid>
                <Grid item md={6}></Grid>
              </Grid>
            </CardContent>
          </Card>
          <Card mb={6}>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Sample Reports
              </Typography>

              <Grid container spacing={6}>
                <Grid item md={12}>
                  <LocalEnhancedTable<ISysAdminDocument>
                    refreshGrid={false}
                    columns={columnsForSample}
                    data={sampleDocuments}
                    defaultRowPerPage={25}
                    hidePagination={true}
                    defaultSortColumn="name"
                  />
                </Grid>
                <Grid item md={6}></Grid>
              </Grid>
            </CardContent>
          </Card>
          <Card mb={6}>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                ASHRAE terms and definitions
              </Typography>

              <Grid container spacing={6}>
                <Grid item md={12}>
                  <LocalEnhancedTable<ISysAdminDocument>
                    refreshGrid={false}
                    columns={columns}
                    data={ashraeDocuments}
                    hidePagination={true}
                  />
                  {fullAccess && (
                    <DropZone
                      onFileAccepted={handleAshraeDocumentAccepted}
                      accept={{ "application/pdf": [".pdf"] }}
                      disabled={disableTemplateUploaderAshraeDoc}
                      disableMessage="Only one profile is allowed"
                      submiting={isSubmitting}
                    />
                  )}
                </Grid>
                <Grid item md={6}></Grid>
              </Grid>
            </CardContent>
          </Card>
          <Card mb={6}>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Walkdown Punchlist
              </Typography>
              <Grid container spacing={6}>
                <Grid item md={12}>
                  <LocalEnhancedTable<ISysAdminDocument>
                    refreshGrid={false}
                    columns={columns}
                    data={pretestingAHU}
                    hidePagination={true}
                  />
                  <DropZone
                    onFileAccepted={handlePretestingAHUFileAccepted}
                    accept={{ "application/pdf": [".pdf"] }}
                    disabled={pretestingAHU.length > 0 ? true : false}
                    disableMessage="Only one file is allowed"
                    submiting={isSubmitting}
                  />
                </Grid>
                <Grid item md={6}></Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <DialogMessagePopup
        title={"Information"}
        text={"Are you certain you want to delete this item?"}
        showPopup={showDeleteModal}
        setShowPopup={setShowDeleteModal}
        onSave={deleteDocument}
        isSubmitting={false}
        disabled={!disabledButton}
      >
        <Stack>
          <Typography mb={2} mt={5}>
            Type "delete" to confirm
          </Typography>
          <FormText
            label=""
            name="code"
            value={valuesDeleteCode.code}
            onChange={handleOnChange}
          />
        </Stack>
      </DialogMessagePopup>
    </React.Fragment>
  );
};

export default SysAdminDocuments;
