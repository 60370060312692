import { useState } from "react";
import {
  Card,
  Stepper,
  Step,
  StepLabel,
  CardContent,
  Divider as MuiDivider,
} from "@mui/material";
import { stepsCreateProject } from "src/constants";
import HeaderPage from "src/components/page/HeaderPage";
import { spacing, styled } from "@mui/system";
import { useParams } from "react-router-dom";
import ConfigurationProject from "./ConfigurationProject";

const Divider = styled(MuiDivider)(spacing);

export type ProjectsParams = {
  companyId: string;
  projectId: string;
};

export default function ConfigurationWizard() {
  let params = useParams<ProjectsParams>();
  const projectId = parseInt(
    params?.projectId === undefined ? "0" : params?.projectId
  );
  const [activeStep] = useState(3);

  return (
    <>
      <HeaderPage
        title={"Project"}
        parentText={"Dashboard"}
        parentLink={`/`}
        actionSection={undefined}
        pageYoutube=""
      ></HeaderPage>
      <Divider my={6} />
      <Card>
        <CardContent>
          <Stepper alternativeLabel activeStep={activeStep}>
            {stepsCreateProject.map((step) => (
              <Step key={step.key}>
                <StepLabel>
                  {step.label}
                  <br /> {step.description}
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        </CardContent>
      </Card>

      <ConfigurationProject projectResponse={projectId} />
    </>
  );
}
