import { IMandatoryMinimumField } from "../ts/interfaces/catalogs/mandatoryMinimumField";
import axios from "../utils/axios";

const getByType = async (typeId: number) => {
  return axios.get<IMandatoryMinimumField[]>(
    `MandatoryMinimumField?mandatoryMinimumId=${typeId}`
  );
};

const update = async (item: IMandatoryMinimumField) => {
  return axios.put(`MandatoryMinimumField`, item);
};

const add = async (item: IMandatoryMinimumField) => {
  return axios.post(`MandatoryMinimumField`, item);
};

const remove = async (id: number) => {
  return axios.delete(`MandatoryMinimumField/${id}`);
};

const mandatoryMinimumFieldService = {
  getByType,
  update,
  add,
  remove,
};

export default mandatoryMinimumFieldService;
