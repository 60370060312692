import { IConversions } from "src/ts/interfaces/catalogs";
import axios from "src/utils/axios";

const getAll = () => {
  return axios.get<IConversions[]>("Conversions");
};

const add = (unitLabel: IConversions) => {
  return axios.post("Conversions", unitLabel);
};

const update = (unitLabel: IConversions) => {
  return axios.put(`Conversions/${unitLabel.id}`, unitLabel);
};

const remove = (id: Number) => {
  return axios.delete(`Conversions/${id}`);
};

const convertionService = { getAll, add, update, remove };
export default convertionService;
