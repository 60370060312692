import React from "react";
import { TableRow, TableCell, Checkbox } from "@mui/material";
import Moment from "moment";
import { ColumnType } from "src/types/enhancedTable";
import { GridActionButton } from "../gridControls";
import { convertUTCDateToLocalDate } from "src/utils/utils";
import { currencyformatter } from "src/utils/format";
import { FormCheckBox } from "../formControls";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
interface EnhancedTablePropsType<T> {
  index: number;
  cellCheckBox?: boolean;
  onToggleSelection: (id: number) => void;
  isSelected?: (id: number) => boolean;
  columns: ColumnType[];
  subItems?: string;
  row: any;
}

const LocalEnhancedTableRow = <T extends {}>({
  index,
  cellCheckBox,
  onToggleSelection,
  isSelected,
  columns,
  subItems,
  row,
}: EnhancedTablePropsType<T>) => {
  const [showSubItems, setShowSubItems] = React.useState(false);

  const cellcontent: any = (row: any, col: any, index: number) => {
    if (col.type === "string") {
      return row[col.id];
    } else if (col.type === "custom") {
      return col.callback(row, col, index);
    } else if (col.type === "date") {
      return Moment(row[col.id]).format(col.format);
    } else if (col.type === "utcDate") {
      return convertUTCDateToLocalDate(row[col.id])?.format(col.format) ?? "";
    } else if (col.type === "currency") {
      return currencyformatter.format(row[col.id]);
    } else if (col.type === "phone") {
      let cleaned = ("" + row[col.id]).replace(/\D/g, "");
      let match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
      if (match) {
        let intlCode = match[1] ? "+1 " : "";
        return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join(
          ""
        );
      }
      return null;
    } else {
      return row[col.id];
    }
  };

  const onSelectHandler = (id: number) => {
    onToggleSelection && onToggleSelection(id);
  };

  return (
    <>
      <TableRow key={index}>
        {cellCheckBox ? (
          <TableCell padding="checkbox">
            <FormCheckBox
              value={isSelected && isSelected(row.id)}
              onChange={() => {
                onSelectHandler(row.id);
              }}
              name={""}
              label={""}
            />
          </TableCell>
        ) : null}

        {columns
          .filter((x) => x.hide !== true)
          .map((col, indexColumns) => {
            return (
              <>
                {subItems && indexColumns === 0 && (
                  <TableCell
                    key={"table_" + col.id + "_" + row.id + "_subitem"}
                  >
                    {row?.[subItems] !== undefined &&
                      row?.[subItems].length > 0 && (
                        <GridActionButton
                          type={showSubItems ? "up" : "down"}
                          onClick={() => {
                            setShowSubItems(!showSubItems);
                          }}
                        />
                      )}
                  </TableCell>
                )}
                <TableCell
                  align={col.contentAlign}
                  key={"table_" + col.id + "_" + row.id}
                >
                  {cellcontent(row, col, index)}
                </TableCell>
              </>
            );
          })}
      </TableRow>
      {subItems &&
        showSubItems &&
        row?.[subItems].length > 0 &&
        row?.[subItems].map((rowSub: any, indexSub: any) => {
          return (
            <>
              <TableRow key={indexSub}>
                {cellCheckBox ? (
                  <TableCell padding="checkbox">
                    <Checkbox />
                  </TableCell>
                ) : null}

                {columns
                  .filter((x) => x.hide !== true)
                  .map((col, indexColumns) => {
                    return (
                      <>
                        {indexColumns === 0 && (
                          <TableCell
                            key={
                              "table_" + col.id + "_" + rowSub.id + "_subitem"
                            }
                          >
                            <GridActionButton
                              type="subItem"
                              onClick={() => {}}
                              disabled={true}
                            />
                          </TableCell>
                        )}
                        <TableCell key={"table_" + col.id + "_" + rowSub.id}>
                          {cellcontent(rowSub, col)}
                        </TableCell>
                      </>
                    );
                  })}
              </TableRow>
            </>
          );
        })}
    </>
  );
};

export default LocalEnhancedTableRow;
