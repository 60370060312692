import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import {
  Grid,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
  Typography,
  Card as MuiCard,
  CardContent,
  Stack,
} from "@mui/material";
import { spacing } from "@mui/system";
import { ColumnType } from "src/types/enhancedTable";
import { useAsyncQuery, useForm, useLog, usePermissions } from "src/hooks";

import LocalEnhancedTable from "src/components/localTable/LocalTable";
import {
  FormButton,
  FormCheckBox,
  FormText,
} from "src/components/formControls";
import {
  TECHNICIANPERMISSIONS,
  AMPTECHNICIANPERMISSIONS,
  TechinicianType,
} from "src/constants";
import { ITechnicianPermission } from "src/ts/interfaces/permissionTechnician";
import { userService } from "src/services";
import { IAdministratorsData } from "src/ts/interfaces";
import { Permission as PermissionTypes } from "src/ts/enums";
const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

type UserParams = {
  userId: string;
  companyId: string;
  techType: string;
};

interface IDataTable {
  applicationPermissions: string;
  enabled: boolean;
  caption: string;
  valueData: string;
  object: string;
  valueText: number;
}

const initialValues = {
  canAddBuildingsAndProjects: false,
  canAddTechniciansToProject: false,
  canTechnicianEstimatedTime: false,
  canByPassEstimatedTime: false,
  canByPassDailyLog: false,
  canAssignTestEquipment: false,
  permanentPermissionTechniciansApp: false,
  canByPassTimeCard: false,
  canRequestGpsPermission: false,
  paidTravelPermittedStopTime: 0,
  stopReasonRequired: false,
  workingTime: 0,
  maxWorkingTime: 0,
};

const TechniciansPermissions = () => {
  let { userId, companyId, techType } = useParams<UserParams>();
  const { log } = useLog();
  const navigate = useNavigate();
  const [dataTableValues, setDataTableValues] = useState<IDataTable[]>(
    TECHNICIANPERMISSIONS
  );
  const [dataTableValuesAMP, setDataTableValuesAMP] = useState<IDataTable[]>(
    AMPTECHNICIANPERMISSIONS
  );
  const { fullAccess: fullAccessTechniciansAdmin } = usePermissions(
    PermissionTypes.TechniciansAdmin
  );
  const { execute, data } = useAsyncQuery<ITechnicianPermission>(
    userService.getTechnicianPermissions
  );
  const { execute: executeUserData, data: userData } =
    useAsyncQuery<IAdministratorsData>(userService.getById);

  const { values, setValues } = useForm(initialValues, false, []);

  useEffect(() => {
    const getData = () => {
      execute(Number(userId));
      executeUserData(Number(userId));
    };

    getData();
  }, []);

  useEffect(() => {
    const getValues = () => {
      setValues(data);
      const updatedPermissions = dataTableValues.map((permission) => ({
        ...permission,
        enabled: data[permission.valueData as keyof ITechnicianPermission],
      }));

      setDataTableValues(updatedPermissions as IDataTable[]);
    };

    const getValuesAMP = () => {
      setValues({
        ...data,
        workingTime: data.workingTime / 3600,
        maxWorkingTime: data.maxWorkingTime / 3600,
      });
      const updatedPermissions = dataTableValuesAMP.map((permission) => ({
        ...permission,
        valueText: data[permission.valueData as keyof ITechnicianPermission],
      }));

      setDataTableValuesAMP(updatedPermissions as IDataTable[]);
    };
    if (data !== null) getValues();
    if (data !== null) getValuesAMP();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const handleInputChange = async (e: any) => {
    const { name, value } = e.target;

    setValues({
      ...values,
      [name]: value,
    });

    try {
      await userService.putTechnicianPermissions(Number(userId), {
        ...values,
        [name]: value,

        workingTime:
          Number(values.workingTime) > 24
            ? 24 * 3600
            : Number(values.workingTime) * 3600,
        maxWorkingTime:
          Number(values.maxWorkingTime) > 24
            ? 24 * 3600
            : Number(values.maxWorkingTime) * 3600,
      });
      log.success("Data was updated succesfully");
    } catch (error: any) {
      log.error(error?.message?.exceptionMessage ?? "Something went wrong");
    }
  };

  const handleInputChangeText = async (e: any) => {
    const { name, value } = e.target;

    if (name === "paidTravelPermittedStopTime") {
      setValues({
        ...values,
        [name]: Math.floor(Number(value)),
      });
    } else {
      setValues({
        ...values,
        [name]: Number(value),
      });
    }
  };

  const handleBurText = async (e: any) => {
    try {
      await userService.putTechnicianPermissions(Number(userId), {
        canAddBuildingsAndProjects: values.canAddBuildingsAndProjects,
        canAddTechniciansToProject: values.canAddTechniciansToProject,
        canTechnicianEstimatedTime: values.canTechnicianEstimatedTime,
        canByPassEstimatedTime: values.canByPassEstimatedTime,
        canByPassDailyLog: values.canByPassDailyLog,
        canAssignTestEquipment: values.canAssignTestEquipment,
        permanentPermissionTechniciansApp:
          values.permanentPermissionTechniciansApp,
        canByPassTimeCard: values.canByPassTimeCard,
        canRequestGpsPermission: values.canRequestGpsPermission,
        paidTravelPermittedStopTime: values.paidTravelPermittedStopTime,
        stopReasonRequired: values.stopReasonRequired,
        workingTime:
          Number(values.workingTime) > 24
            ? 24 * 3600
            : Number(values.workingTime) * 3600,
        maxWorkingTime:
          Number(values.maxWorkingTime) > 24
            ? 24 * 3600
            : Number(values.maxWorkingTime) * 3600,
      });
      log.success("Data was updated succesfully");
    } catch (error: any) {
      log.error(error?.message?.exceptionMessage ?? "Something went wrong");
    }
  };

  const getColumns = (
    handleInputChange: (e: any) => void,
    values: ITechnicianPermission,
    fullAccessTechniciansAdmin: boolean
  ): ColumnType[] => {
    return [
      {
        id: "applicationPermissions",
        label: "Application Permissions",
        type: "custom",
        sort: true,
        align: "left",
        width: "70%",
        callback: (row: IDataTable) => {
          return (
            <>
              <Stack>{row.applicationPermissions}</Stack>
              {row.caption !== "" && (
                <Typography variant="caption">{row.caption}</Typography>
              )}
            </>
          );
        },
      },
      {
        id: "enabled",
        label: "Enabled",
        type: "custom",
        sort: true,
        width: "30%",
        callback: (row: IDataTable) => {
          return (
            <>
              <Stack direction="row">
                <FormCheckBox
                  label=""
                  name={row.valueData}
                  value={!!values[row.valueData as keyof ITechnicianPermission]}
                  onChange={handleInputChange}
                  disabled={!fullAccessTechniciansAdmin}
                />
              </Stack>
            </>
          );
        },
      },
    ];
  };

  const getColumnsAMPTech = (
    handleInputChange: (e: any) => void,
    handleInputChangeText: (e: any) => void,
    handleBurText: (e: any) => void,
    values: ITechnicianPermission,
    fullAccessTechniciansAdmin: boolean
  ): ColumnType[] => {
    return [
      {
        id: "applicationPermissions",
        label: "AMP Technicians App Permissions",
        type: "custom",
        sort: true,
        align: "left",
        width: "70%",
        callback: (row: IDataTable) => {
          return (
            <>
              <Stack>{row.applicationPermissions}</Stack>
              {row.caption !== "" && (
                <Typography variant="caption">{row.caption}</Typography>
              )}
            </>
          );
        },
      },
      {
        id: "enabled",
        label: "Value",
        type: "custom",
        sort: true,
        width: "30%",
        callback: (row: IDataTable) => {
          return (
            <>
              <Stack direction="row">
                {row.object === "checkBox" && (
                  <FormCheckBox
                    label=""
                    name={row.valueData}
                    value={
                      !!values[row.valueData as keyof ITechnicianPermission]
                    }
                    onChange={handleInputChange}
                    disabled={!fullAccessTechniciansAdmin}
                  />
                )}
                {row.object === "textField" && (
                  <FormText
                    name={row.valueData}
                    label=""
                    fullWidth={false}
                    type="number"
                    value={String(
                      values[row.valueData as keyof ITechnicianPermission]
                    )}
                    onChange={handleInputChangeText}
                    onBlur={handleBurText}
                    disabled={!fullAccessTechniciansAdmin}
                  />
                )}
              </Stack>
            </>
          );
        },
      },
    ];
  };

  const columns = getColumns(
    handleInputChange,
    values,
    fullAccessTechniciansAdmin
  );
  const columnsAMP = getColumnsAMPTech(
    handleInputChange,
    handleInputChangeText,
    handleBurText,
    values,
    fullAccessTechniciansAdmin
  );

  const hanlderClose = () => {
    if (techType === TechinicianType.PermissionsRequest) {
      navigate(`/app/Technicians/${companyId}`);
    } else if (techType === TechinicianType.Users) {
      navigate(`/app/users/${companyId}`);
    } else {
      navigate(`/app/TechniciansAssignments/${companyId}`);
    }
  };

  return (
    <>
      <Helmet title="Technician Permissions" />
      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            {`Technician Permissions: ${userData?.name} ${userData?.lastName}`}
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} to="/">
              Dashboard
            </Link>
            <Typography>Technician Permissions</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item></Grid>
      </Grid>

      <Divider my={6} />
      <Card mb={6}>
        <CardContent>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <LocalEnhancedTable<IDataTable>
                refreshGrid={true}
                columns={columns}
                data={dataTableValues}
                showSkeleton={false}
                defaultRowPerPage={10}
                hidePagination={true}
              />
            </Grid>
          </Grid>

          <Grid container spacing={6} mt={10}>
            <Grid item xs={12}>
              <LocalEnhancedTable<IDataTable>
                refreshGrid={true}
                columns={columnsAMP}
                data={dataTableValuesAMP}
                showSkeleton={false}
                defaultRowPerPage={10}
                hidePagination={true}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Stack direction="row" justifyContent="center">
        <FormButton
          text="Close"
          onClick={hanlderClose}
          size="small"
          color="primary"
        />
      </Stack>
    </>
  );
};

export default TechniciansPermissions;
