import React from "react";
import { Navigate } from "react-router-dom";
import useAuth from "src/hooks/useAuth";
import { Permissions } from "src/types/authorizationData";

const isAllowed = (permissionRole: number, userRole: number) => {
  return (permissionRole & userRole) !== 0;
};

const isAllowedMatrix = (type: string, permissions: Permissions[]) => {
  if (type === "") return true;
  if (permissions === undefined) return false;
  var result = permissions.find((x) => x.code === type);
  if (result && (result.readOnly || result.fullAccess)) return true;
  else return false;
};

interface AuthGuardProps {
  children: React.ReactNode;
  permission: number;
  permissionMatrix: string[];
}

// For routes that can only be accessed by authenticated users
function AuthGuard({ permission, children, permissionMatrix }: AuthGuardProps) {
  const { isAuthenticated, user } = useAuth();

  if (!isAllowed(permission, user?.roleId)) {
    return <Navigate to="/app/invalidPermissions" />;
  }
  permissionMatrix.forEach(function (item) {
    if (!isAllowedMatrix(item, user?.permissions)) {
      return <Navigate to="/app/invalidPermissions" />;
    }
  });

  if (!isAuthenticated) {
    return <Navigate to="/auth/sign-in" />;
  }

  return <React.Fragment>{children}</React.Fragment>;
}

export default AuthGuard;
