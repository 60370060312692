import {
  Stack,
  List,
  ListItem,
  useTheme,
  ListItemButton,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
} from "@mui/material";
import useChillerStudy from "../hooks/useChillerStudy";
import { IChillerTowerCondenser } from "src/ts/interfaces";
import { CopyAllOutlinedIcon, DeleteIcon } from "src/components/icons";

interface Props {
  towerCondensers: IChillerTowerCondenser[];
  isStudyComplete?: boolean;
  onChange: (towerCondenser: IChillerTowerCondenser) => Promise<void>;
}

const TowerCondenserList = ({
  towerCondensers,
  isStudyComplete,
  onChange,
}: Props) => {
  const theme = useTheme();
  const { towerCondenserValues } = useChillerStudy();

  const selectedItemHandler = async (
    towerCondenser: IChillerTowerCondenser
  ) => {
    onChange && (await onChange(towerCondenser));
  };

  return (
    <List component={Stack} direction="row" flexWrap={"wrap"}>
      {towerCondensers.map((towerCondenser, index) => {
        return (
          <ListItem
            key={towerCondenser.id}
            disablePadding
            sx={{
              borderColor: theme.palette.primary.main,
              borderWidth: "1px",
              borderStyle: "solid",
            }}
          >
            <ListItemButton
              selected={towerCondenserValues?.id === towerCondenser.id}
              onClick={() => selectedItemHandler(towerCondenser)}
            >
              <ListItemText primary={`Tower ${index + 1}`} />
            </ListItemButton>

            <ListItemSecondaryAction>
              <>
                {false && (
                  <>
                    <IconButton aria-label="comments" onClick={() => {}}>
                      <CopyAllOutlinedIcon />
                    </IconButton>
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      onClick={() => {}}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </>
                )}
              </>
            </ListItemSecondaryAction>
          </ListItem>
        );
      })}
    </List>
  );
};

export default TowerCondenserList;
