import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { NumericFormat } from "react-number-format";

import { FormNumeric2 } from "src/components/formControls";
import { Mode } from "src/ts/types";
import useChillerStudy from "../hooks/useChillerStudy";
import chillerService from "src/services/study/chillerService";
import { ISystemOfMeasurement } from "src/ts/interfaces";

interface Props {
  mode: Mode;
  disableStudy: boolean;
  isLoading: boolean;
  systemOfMeasurement: ISystemOfMeasurement;
}

const EvaporatorTable = ({
  mode,
  disableStudy,
  isLoading,
  systemOfMeasurement,
}: Props) => {
  const {
    evaporatorValues: values,
    handleEvaporatorInputChange: handleInputChange,
    setEvaporatorValues,
  } = useChillerStudy();

  const saveTower = async () => {
    const updateEvaporatorRes = await chillerService.updateEvaporator(
      values.id,
      values
    );
    setEvaporatorValues(updateEvaporatorRes.data);
  };

  return (
    <>
      <TableContainer>
        <Table style={{ marginBottom: "0px" }}>
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell style={{ textAlign: "center" }}>
                Actual ({systemOfMeasurement.get("temperature")})
              </TableCell>
              <TableCell style={{ textAlign: "center" }}>
                Display ({systemOfMeasurement.get("temperature")})
              </TableCell>
              <TableCell style={{ textAlign: "center" }}>
                Δ Comparison
              </TableCell>
              <TableCell style={{ textAlign: "center" }}>
                Design Δ {systemOfMeasurement.get("temperature")}
              </TableCell>
              <TableCell style={{ textAlign: "center" }}>Range / Δ T</TableCell>
              <TableCell style={{ textAlign: "center" }}>
                Δ {systemOfMeasurement.get("temperature")} % of Design
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell style={{ padding: 0 }}>
                <label style={{ paddingLeft: 0 }}>
                  <span style={{ color: "#538DD5" }}>Chilled</span> Water Supply
                  Temp ({systemOfMeasurement.get("temperature")})
                </label>
              </TableCell>
              <TableCell style={{ padding: "0px", width: "100px" }}>
                <FormNumeric2
                  name="chilledWaterSupplyTempActual"
                  value={values.chilledWaterSupplyTempActual}
                  onChange={handleInputChange}
                  decimalScale={2}
                  thousandSeparator={true}
                  maxValue={1000000000}
                  fixedDecimalScale={true}
                  onBlur={saveTower}
                  label={""}
                  mode={mode}
                  disabled={disableStudy}
                  showSkeleton={isLoading}
                />
              </TableCell>
              <TableCell style={{ padding: "0px", width: "100px" }}>
                <FormNumeric2
                  name="chilledWaterSupplyTempDisplay"
                  value={values.chilledWaterSupplyTempDisplay}
                  onChange={handleInputChange}
                  decimalScale={2}
                  thousandSeparator={true}
                  maxValue={1000000000}
                  fixedDecimalScale={true}
                  label={""}
                  onBlur={saveTower}
                  mode={mode}
                  disabled={disableStudy}
                  showSkeleton={isLoading}
                />
              </TableCell>
              <TableCell
                style={{
                  padding: "0px",
                  width: "150px",
                  verticalAlign: "middle",
                  textAlign: "center",
                }}
              >
                <NumericFormat
                  displayType="text"
                  value={values.chilledWaterSupplyTempComparation}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  thousandSeparator={true}
                />
              </TableCell>
              <TableCell
                style={{
                  padding: "0px",
                  verticalAlign: "middle",
                  textAlign: "center",
                }}
                rowSpan={2}
              >
                <NumericFormat
                  displayType="text"
                  value={values.chillerDesign}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  thousandSeparator={true}
                />
              </TableCell>
              <TableCell
                style={{
                  padding: "0px",
                  verticalAlign: "middle",
                  textAlign: "center",
                }}
                rowSpan={2}
              >
                <NumericFormat
                  displayType="text"
                  value={values.chillerActual}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  thousandSeparator={true}
                />
              </TableCell>
              <TableCell
                style={{
                  padding: "0px",
                  verticalAlign: "middle",
                  textAlign: "center",
                }}
                rowSpan={2}
              >
                <NumericFormat
                  displayType="text"
                  value={values.chillerPercentage}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  thousandSeparator={true}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ padding: 0 }}>
                <label style={{ paddingLeft: 0 }}>
                  <span style={{ color: "#538DD5" }}>Chilled</span> Water Return
                  Temp ({systemOfMeasurement.get("temperature")})
                </label>
              </TableCell>
              <TableCell style={{ padding: "0px", width: "100px" }}>
                <FormNumeric2
                  name="chilledWaterReturnTempActual"
                  value={values.chilledWaterReturnTempActual}
                  onChange={handleInputChange}
                  decimalScale={2}
                  thousandSeparator={true}
                  maxValue={1000000000}
                  fixedDecimalScale={true}
                  label={""}
                  onBlur={saveTower}
                  mode={mode}
                  disabled={disableStudy}
                  showSkeleton={isLoading}
                />
              </TableCell>
              <TableCell style={{ padding: "0px", width: "100px" }}>
                <FormNumeric2
                  name="chilledWaterReturnTempDisplay"
                  value={values.chilledWaterReturnTempDisplay}
                  onChange={handleInputChange}
                  decimalScale={2}
                  thousandSeparator={true}
                  maxValue={1000000000}
                  fixedDecimalScale={true}
                  label={""}
                  onBlur={saveTower}
                  mode={mode}
                  disabled={disableStudy}
                  showSkeleton={isLoading}
                />
              </TableCell>
              <TableCell
                style={{
                  padding: "0px",
                  width: "100px",
                  verticalAlign: "middle",
                  textAlign: "center",
                }}
              >
                <NumericFormat
                  displayType="text"
                  value={values.chilledWaterReturnTempComparation}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  thousandSeparator={true}
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      {/* <TableContainer>
        <Table style={{ marginBottom: "0px" }}>
          <TableHead>
            <TableRow>
              <TableCell style={{ textAlign: "center" }}>
                Ambient WB Temperature at{" "}
                <span style={{ color: "#FF0000" }}>Condenser/Tower</span>
              </TableCell>
              <TableCell style={{ textAlign: "center" }}>
                <span style={{ color: "#FF0000" }}>Condenser/Tower</span>{" "}
                Approach
              </TableCell>
              <TableCell style={{ textAlign: "center" }}>
                % <span style={{ color: "#538DD5" }}>Cooling</span> Tower
                Efficiency
              </TableCell>
              <TableCell style={{ textAlign: "center" }}>
                <span style={{ color: "#538DD5" }}>Cooling</span> Tower (btuh)
              </TableCell>
              <TableCell style={{ textAlign: "center" }}>
                Tower Total kW
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell style={{ padding: 0, width: "100px" }}>
                <FormNumeric2
                  name="ambientWBTempAtCondenserTower"
                  value={values.ambientWBTempAtCondenserTower}
                  onChange={handleInputChange}
                  decimalScale={2}
                  thousandSeparator={true}
                  maxValue={1000000000}
                  fixedDecimalScale={true}
                  label={""}
                  onBlur={saveTower}
                  mode={mode}
                  disabled={disableStudy}
                />
              </TableCell>
              <TableCell
                style={{
                  padding: "0px",
                  width: "100px",
                  verticalAlign: "middle",
                  textAlign: "center",
                }}
              >
                <FormNumeric2
                  value={values.condenserTowerApproach}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  thousandSeparator={true}
                  mode={mode}
                  disabled={disableStudy}
                  name={""}
                  label={""}
                />
              </TableCell>
              <TableCell
                style={{
                  padding: "0px",
                  width: "100px",
                  verticalAlign: "middle",
                  textAlign: "center",
                }}
              >
                <FormNumeric2
                  value={values.coolingTowerEfficiency}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  thousandSeparator={true}
                  mode={mode}
                  disabled={disableStudy}
                  name={""}
                  label={""}
                />
              </TableCell>
              <TableCell
                style={{
                  padding: "0px",
                  width: "100px",
                  verticalAlign: "middle",
                  textAlign: "center",
                }}
              >
                <FormNumeric2
                  value={values.actualTowerBTUH}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  thousandSeparator={true}
                  mode={mode}
                  disabled={disableStudy}
                  name={""}
                  label={""}
                />
              </TableCell>
              <TableCell
                style={{
                  padding: "0px",
                  width: "100px",
                  verticalAlign: "middle",
                  textAlign: "center",
                }}
              >
                <FormNumeric2
                  value={values.actualKws}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  thousandSeparator={true}
                  mode={mode}
                  disabled={disableStudy}
                  name={""}
                  label={""}
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer> */}
    </>
  );
};

export default EvaporatorTable;
