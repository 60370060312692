import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import {
  Grid,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
  Typography,
  Card as MuiCard,
  CardContent,
  Stack,
  Button,
  Chip,
} from "@mui/material";
import { Add as AddIcon } from "@mui/icons-material";
import { spacing } from "@mui/system";
import { ColumnType } from "src/types/enhancedTable";
import LocalEnhancedTable from "src/components/localTable/LocalTable";
import useAsyncQuery from "src/hooks/useAsyncQuery";
import { GridActionButton } from "src/components/gridControls";
import MatchWordConfirmModal from "src/components/modals/MatchWordConfirmModal";
import { useLog } from "src/hooks";
import { ISchools } from "src/ts/interfaces/catalogs/schools";
import schoolsService from "src/services/catalogs/schoolsService";
import SchoolForm from "./SchoolForm";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const initialValues: ISchools = {
  id: 0,
  name: "",
  address: "",
  address2: "",
  countryId: 235,
  countryCode: "",
  city: "",
  stateId: 0,
  stateName: "",
  zip: "",
  getFullAddress: "",
  contactName: "",
  contactEmail: "",
  contactPhone: "",
  contactExtension: "",
  statusId: 0,
};

const Schools = () => {
  const { log } = useLog();

  const { execute, data, isLoading } = useAsyncQuery<ISchools[]>(
    schoolsService.getAll
  );
  const [refreshPage, setRefreshPage] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [confirmDialogIsOpen, setConfirmDialogIsOpen] = useState(false);
  const [currentRow, setCurrentRow] = useState<ISchools>();

  useEffect(() => {
    const getData = async () => {
      await execute();
    };

    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshPage]);

  const handleEdit = (row: ISchools) => {
    setCurrentRow(row);
    setIsDialogOpen(true);
  };

  const selectDeleteHandler = (row: ISchools) => {
    setCurrentRow(row);
    setConfirmDialogIsOpen(true);
  };

  const deleteHandler = async () => {
    if (currentRow !== undefined && currentRow?.id > 0) {
      try {
        await schoolsService.remove(currentRow?.id);
        log.success("The item was deleted");
        refreshGridHandler();
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      }
    }
  };

  const newHandler = () => {
    setCurrentRow(initialValues);
    setIsDialogOpen(true);
  };

  const refreshGridHandler = () => {
    setRefreshPage(!refreshPage);
  };

  const columns: ColumnType[] = [
    {
      id: "id",
      label: "#",
      type: "string",
      sort: true,
      hide: true,
    },
    {
      id: "name",
      label: "Name",
      type: "string",
      sort: true,
    },
    {
      id: "getFullAddress",
      label: "Address",
      type: "string",
      sort: true,
    },
    {
      id: "statusId",
      label: "Status",
      type: "custom",
      sort: true,
      callback: (row: ISchools) => {
        return (
          <>
            {row.statusId === 1 ? (
              <Chip label="Enabled" color="primary" size="small" />
            ) : (
              <Chip
                label="Disabled"
                color="primary"
                variant="outlined"
                size="small"
              />
            )}
          </>
        );
      },
    },
    {
      id: "x",
      label: "Actions",
      type: "custom",
      sort: false,
      callback: (row: ISchools) => (
        <div>
          <Stack direction="row">
            <GridActionButton
              type={"edit"}
              onClick={() => {
                handleEdit(row);
                setIsDialogOpen(true);
              }}
              tooltip={"Edit"}
            />

            <GridActionButton
              type="delete"
              onClick={() => selectDeleteHandler(row)}
              tooltip="Delete"
            />
          </Stack>
        </div>
      ),
    },
  ];

  return (
    <>
      <Helmet title="Schools" />
      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Schools
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} to="/">
              Dashboard
            </Link>
            <Typography>Schools</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item>
          <div>
            <Button onClick={newHandler} variant="contained" color="primary">
              <AddIcon />
              {`New School`}
            </Button>
          </div>
        </Grid>
      </Grid>

      <Divider my={6} />
      <Card mb={6}>
        <CardContent>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <LocalEnhancedTable<ISchools>
                refreshGrid={true}
                columns={columns}
                data={data ?? []}
                showSkeleton={isLoading}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      {isDialogOpen && (
        <SchoolForm
          selectedValue={currentRow}
          isDialogOpen={isDialogOpen}
          setIsDialogOpen={setIsDialogOpen}
          onUpdate={refreshGridHandler}
        />
      )}

      <MatchWordConfirmModal
        onConfirm={deleteHandler}
        text={`Are you certain you want to delete the school: ?`}
        matchWord={"DELETE"}
        isDialogOpen={confirmDialogIsOpen}
        setIsDialogOpen={setConfirmDialogIsOpen}
      />
    </>
  );
};

export default Schools;
