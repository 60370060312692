import { Box, FormLabel } from "@mui/material";
import { NumericFormat } from "react-number-format";

interface ReadOnlyFieldProps {
  label: string | React.ReactNode;
  labelColor?: string;
  value?: number | string | null;
  valueColor?: string;
  color?: "error" | "info" | "primary" | "secondary" | "success" | "warning";
  thousandSeparator?: string | boolean;
  prefix?: string;
  decimalScale?: number;
  fixedDecimalScale?: boolean;
  suffix?: string;
}

const FormNumericReadOnlyField = ({
  label,
  labelColor,
  value,
  valueColor,
  color,
  prefix,
  thousandSeparator,
  decimalScale,
  fixedDecimalScale,
  suffix,
}: ReadOnlyFieldProps) => {
  return (
    <Box
      pr={3}
      sx={{
        pb: { xs: 2, md: 2 },
        width: "100%",
        marginTop: label === "" ? 2 : 0,
      }}
      alignSelf="center"
    >
      <FormLabel sx={{ color: labelColor }}>{label}</FormLabel>
      <Box>
        <NumericFormat
          displayType="text"
          value={value}
          thousandSeparator={thousandSeparator}
          prefix={prefix}
          suffix={suffix}
          decimalScale={decimalScale}
          fixedDecimalScale={fixedDecimalScale}
          style={{ color: valueColor }}
        />
      </Box>
    </Box>
  );
};

export default FormNumericReadOnlyField;
