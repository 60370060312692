import React from "react";
import { Stack } from "@mui/material";
import { FormButton } from "src/components/formControls";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";

interface Props {
  timeCardButtonClick: () => void;
  estimatedTimeButtonClick: () => void;
  showTimeCardButton: boolean;
}

function ProjectActions({
  timeCardButtonClick,
  showTimeCardButton,
  estimatedTimeButtonClick,
}: Props) {
  return (
    <React.Fragment>
      <Stack direction="row" spacing={2}>
        {showTimeCardButton && (
          <>
            <FormButton
              variant="outlined"
              text={"Time Card"}
              onClick={timeCardButtonClick}
              startIcon={<AccessTimeIcon />}
              size="small"
            />
            <FormButton
              variant="outlined"
              text={"Estimated Time"}
              onClick={estimatedTimeButtonClick}
              startIcon={<CalendarMonthIcon />}
              size="small"
            />
          </>
        )}
      </Stack>
    </React.Fragment>
  );
}

export default ProjectActions;
