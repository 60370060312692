import axios from "src/utils/axios";
import {
  ICustomData,
  IKeyValue,
  IOutletReportCustomNameParametersAll,
} from "src/ts/interfaces";

const getAll = () => {
  return axios.get<IOutletReportCustomNameParametersAll[]>(
    "OutletReportCustomNameParameters"
  );
};

const getAllKeyValues = () => {
  return new Promise<ICustomData>((resolve, reject) => {
    axios
      .get<Array<IOutletReportCustomNameParametersAll>>(
        "/OutletReportCustomNameParameters"
      )
      .then((response) => {
        const keyValuePair: IKeyValue<number, string>[] = response.data.map(
          (item) => {
            return {
              key: item.id,
              value: item.name,
            };
          }
        );
        resolve({ data: keyValuePair });
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const outletReportCustomNameParametersService = {
  getAll,
  getAllKeyValues,
};

export default outletReportCustomNameParametersService;
