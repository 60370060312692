import {
  Grid,
  IconButton,
  Tooltip,
  TooltipProps,
  Zoom,
  styled,
  tooltipClasses,
} from "@mui/material";
import {
  FormButton,
  FormCheckBox,
  FormMaskText,
  FormNumeric2,
  FormRadioGroup,
  FormSelect,
  FormText,
} from "src/components/formControls";
import { useAsyncQuery, useEffectOnce } from "src/hooks";
import { IChillerCompressor, IKeyValue } from "src/ts/interfaces";
import { IDefaultHorsePower } from "src/ts/interfaces/catalogs";
import defaultHorsePower from "src/services/catalogs/defaultHorsePower";
import { useEffect, useState } from "react";
import { Stack } from "@mui/system";
import { Mode } from "src/ts/types";
import DialogMessagePopup from "src/components/DialogMessagePopup";
import motorPowerService from "src/services/study/motorPowerService";
import { InfoIcon } from "src/components/icons";
import tooltipService from "src/services/tooltipService";
import React from "react";
import useChillerStudy from "../hooks/useChillerStudy";
import compressorManufacturerService from "src/services/study/compressorManufacturerService";

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
  },
}));

export const motorPhaseOptions = [
  { key: "Three phase", value: "Three phase" },
  { key: "2 wire single phase", value: "2 wire single phase" },
];

export const hertzOptions = [
  { key: 50, value: "50 HZ" },
  { key: 60, value: "60 HZ" },
];

interface Props {
  mode: Mode;
  disableStudy: boolean;
  isLoading: boolean;
  compressorErros: Record<string, string>;
}

const CompressorForm = ({
  mode,
  disableStudy,
  isLoading,
  compressorErros,
}: Props) => {
  const { compressorValues, handleCompressorInputChange, setCompressorValues } =
    useChillerStudy();

  const { execute: getCompressorManufacturers, data: compressorManufacturers } =
    useAsyncQuery<IKeyValue<number, string>[]>(
      compressorManufacturerService.getKeyValues
    );

  const { execute: getDefaultHorsePower, data: defaultHorsePowersOptions } =
    useAsyncQuery<IKeyValue<number, string>[]>(
      defaultHorsePower.getKeyValuesMetricName
    );

  const {
    execute: getAllDefaultHorsePower,
    data: defaultAllHorsePowersOptions,
  } = useAsyncQuery<IDefaultHorsePower[]>(defaultHorsePower.getAll);

  const [showLimitModal, setShowLimitModal] = useState(false);
  const [showServiceFactorModal, setShowServiceFactorModal] = useState(false);
  const [serviceFactorToolTip, setServiceFactorToolTip] = useState("");
  const [effToolTip, setEffToolTip] = useState("");

  useEffectOnce(() => {
    const getData = async () => {
      await getCompressorManufacturers();
      await getDefaultHorsePower();
      await getAllDefaultHorsePower();
      const res = await tooltipService.getById(2);
      setServiceFactorToolTip(res.data.content);

      const resEff = await tooltipService.getById(1);
      setEffToolTip(resEff.data.content);
    };
    getData();
  });

  const unknownHorsepower = function (e: any) {
    const isUnknownHorsepower = e.target.value;
    if (!isUnknownHorsepower) {
      setCompressorValues({
        ...compressorValues,
        efficiencyUnknown: false,
        actualPowerFactorUnknown: false,
        horsepowerUnknown: isUnknownHorsepower,
      });
    } else {
      handleCompressorInputChange(e);
    }
  };

  const showL2 =
    compressorValues.motorPhase === "Three phase" ||
    (compressorValues.motorPhase === "2 wire single phase" &&
      compressorValues.additionalVolAmpValue >= 1);
  const showL3 =
    compressorValues.motorPhase === "Three phase" ||
    (compressorValues.motorPhase === "2 wire single phase" &&
      compressorValues.additionalVolAmpValue === 2);
  const showL4 =
    compressorValues.motorPhase === "Three phase" &&
    compressorValues.additionalVolAmpValue >= 1;
  const showL5 =
    compressorValues.motorPhase === "Three phase" &&
    compressorValues.additionalVolAmpValue >= 2;
  const showL6 =
    compressorValues.motorPhase === "Three phase" &&
    compressorValues.additionalVolAmpValue === 3;

  const addAdditionalVolAmp = () => {
    switch (compressorValues.motorPhase) {
      case "Three phase":
        if (compressorValues.additionalVolAmpValue < 3) {
          setCompressorValues({
            ...compressorValues,
            additionalVolAmpValue: compressorValues.additionalVolAmpValue + 1,
          });
        } else {
          setShowLimitModal(true);
        }
        break;
      case "2 wire single phase":
        if (compressorValues.additionalVolAmpValue < 2) {
          setCompressorValues({
            ...compressorValues,
            additionalVolAmpValue: compressorValues.additionalVolAmpValue + 1,
          });
        } else {
          setShowLimitModal(true);
        }
        break;
      default:
        break;
    }
  };

  const removeVolAmpValue = () => {
    if (compressorValues.additionalVolAmpValue > 0) {
      const additionalVolAmpValue = compressorValues.additionalVolAmpValue - 1;

      switch (compressorValues.additionalVolAmpValue) {
        case 3:
          const nameplateVoltsPhase6 = null;
          const nameplateAmpsPhase6 = null;
          let selectedVoltAmpsReference =
            compressorValues.selectedVoltAmpsReference;
          if (compressorValues.selectedVoltAmpsReference === 6) {
            selectedVoltAmpsReference = null;
          }
          setCompressorValues({
            ...compressorValues,
            nameplateVoltsPhase6,
            nameplateAmpsPhase6,
            selectedVoltAmpsReference,
            additionalVolAmpValue,
          });
          break;
        case 2:
          if (compressorValues.motorPhase === "Three phase") {
            const nameplateVoltsPhase5 = null;
            const nameplateAmpsPhase5 = null;
            let selectedVoltAmpsReference =
              compressorValues.selectedVoltAmpsReference;
            if (compressorValues.selectedVoltAmpsReference === 5) {
              selectedVoltAmpsReference = null;
            }
            setCompressorValues({
              ...compressorValues,
              nameplateVoltsPhase5,
              nameplateAmpsPhase5,
              selectedVoltAmpsReference,
              additionalVolAmpValue,
            });
          }
          if (compressorValues.motorPhase === "2 wire single phase") {
            const nameplateVoltsPhase3 = null;
            const nameplateAmpsPhase3 = null;
            let selectedVoltAmpsReference =
              compressorValues.selectedVoltAmpsReference;
            if (compressorValues.selectedVoltAmpsReference === 3) {
              selectedVoltAmpsReference = null;
            }
            setCompressorValues({
              ...compressorValues,
              nameplateVoltsPhase3,
              nameplateAmpsPhase3,
              selectedVoltAmpsReference,
              additionalVolAmpValue,
            });
          }
          break;
        case 1:
          if (compressorValues.motorPhase === "Three phase") {
            const nameplateVoltsPhase4 = null;
            const nameplateAmpsPhase4 = null;
            let selectedVoltAmpsReference =
              compressorValues.selectedVoltAmpsReference;
            if (compressorValues.selectedVoltAmpsReference === 4) {
              selectedVoltAmpsReference = null;
            }
            setCompressorValues({
              ...compressorValues,
              nameplateVoltsPhase4,
              nameplateAmpsPhase4,
              selectedVoltAmpsReference,
              additionalVolAmpValue,
            });
          }
          if (compressorValues.motorPhase === "2 wire single phase") {
            const nameplateVoltsPhase2 = null;
            const nameplateAmpsPhase2 = null;
            let selectedVoltAmpsReference =
              compressorValues.selectedVoltAmpsReference;
            if (compressorValues.selectedVoltAmpsReference === 2) {
              selectedVoltAmpsReference = null;
            }
            setCompressorValues({
              ...compressorValues,
              nameplateVoltsPhase2,
              nameplateAmpsPhase2,
              selectedVoltAmpsReference,
              additionalVolAmpValue,
            });
          }
          break;
        default:
          break;
      }
    } else {
      setShowLimitModal(true);
    }
  };

  const validateNameplateVolts = (fanMotor: IChillerCompressor) => {
    if (fanMotor.nameplateVoltsPhase1 != null) {
      return true;
    }
    if (fanMotor.nameplateVoltsPhase2 != null) {
      return true;
    }
    if (fanMotor.nameplateVoltsPhase3 != null) {
      return true;
    }
    return false;
  };

  function validateNameplateAmps(fanMotor: IChillerCompressor) {
    if (fanMotor.nameplateAmpsPhase1 != null) {
      return true;
    }
    if (fanMotor.nameplateAmpsPhase2 != null) {
      return true;
    }
    if (fanMotor.nameplateAmpsPhase3 != null) {
      return true;
    }
    return false;
  }

  const [effPfMessage, setEffPfMessage] = useState("");
  const [showEffPfLimitModal, setShowEffPfLimitModal] = useState(false);

  const getPowerFactor = async () => {
    if (compressorValues.nameplateHorsePowerId === null) return;
    if (!validateNameplateVolts(compressorValues)) {
      return;
    }
    if (!validateNameplateAmps(compressorValues)) {
      return;
    }

    if (
      compressorValues?.nameplateRatedEff &&
      compressorValues?.nameplateRatedEff > 1.0
    ) {
      setEffPfMessage("Improperly inputed data EFF can’t exceed 1.0");
      setShowEffPfLimitModal(true);
      return;
    }

    const motorModel: any = {
      motorPhase: compressorValues.motorPhase,
      nameplateVoltsPhase1: compressorValues.nameplateVoltsPhase1,
      nameplateVoltsPhase2: compressorValues.nameplateVoltsPhase2,
      nameplateVoltsPhase3: compressorValues.nameplateVoltsPhase3,
      nameplateAmpsPhase1: compressorValues.nameplateAmpsPhase1,
      nameplateAmpsPhase2: compressorValues.nameplateAmpsPhase2,
      nameplateAmpsPhase3: compressorValues.nameplateAmpsPhase3,
      nameplateEfficiency: compressorValues.nameplateRatedEff,
      nameplateHorsePowerId: compressorValues.nameplateHorsePowerId,
    };

    const powerFactorRes = await motorPowerService.powerFactor(motorModel);
    let nameplateRatedPF =
      powerFactorRes.data === 0 ? null : powerFactorRes.data;
    if (nameplateRatedPF && nameplateRatedPF > 1.0) {
      setEffPfMessage("Improperly inputed data PF can’t exceed 1.0");
      nameplateRatedPF = null;
      setShowEffPfLimitModal(true);
    }
    setCompressorValues({
      ...compressorValues,
      nameplateRatedPF,
    });
  };

  const handleNameplateRatedPFChange = async (e: any) => {
    const value = parseFloat(e.target.value);
    const newCompressorValues: IChillerCompressor = {
      ...compressorValues,
      nameplateRatedPF: value,
    };
    await getEfficiencyByPowerFactor(newCompressorValues);
  };

  const getEfficiencyByPowerFactor = async (compressor: IChillerCompressor) => {
    if (compressor.nameplateHorsePowerId == null) {
      return;
    }
    if (!validateNameplateVolts(compressor)) {
      return;
    }
    if (!validateNameplateAmps(compressor)) {
      return;
    }

    if (compressor?.nameplateRatedPF && compressor?.nameplateRatedPF > 1.0) {
      setEffPfMessage("Improperly inputed data PF can’t exceed 1.0");
      setShowEffPfLimitModal(true);
      return;
    }

    var motorModel: any = {
      motorPhase: compressor.motorPhase,
      nameplateVoltsPhase1: compressor.nameplateVoltsPhase1,
      nameplateVoltsPhase2: compressor.nameplateVoltsPhase2,
      nameplateVoltsPhase3: compressor.nameplateVoltsPhase3,
      nameplateAmpsPhase1: compressor.nameplateAmpsPhase1,
      nameplateAmpsPhase2: compressor.nameplateAmpsPhase2,
      nameplateAmpsPhase3: compressor.nameplateAmpsPhase3,
      nameplatePowerFactor: compressor.nameplateRatedPF,
      nameplateHorsePowerId: compressor.nameplateHorsePowerId,
    };

    const response = await motorPowerService.efficiency(motorModel);

    if (response != null) {
      let nameplateRatedEff = response.data === 0 ? null : response.data;
      if (nameplateRatedEff && nameplateRatedEff > 1.0) {
        setEffPfMessage("Improperly inputed data EFF can’t exceed 1.0");
        setCompressorValues({
          ...compressor,
          nameplateRatedEff: null,
        });
        setShowEffPfLimitModal(true);
      }
      setCompressorValues({
        ...compressor,
        nameplateRatedEff,
      });
    }
  };
  const row = 8;

  const [triggerGetPowerFactor, setTriggerGetPowerFactor] = useState(false);
  const changeHorsePower = (e: any) => {
    const nameplateHorsePowerId: number | null = e.target.value;
    //loadMotorInformationForTraining()
    if (compressorValues.efficiencyUnknown && nameplateHorsePowerId) {
      const efficiency = getEfficiencyByHorsePowerId(nameplateHorsePowerId);
      setCompressorValues({
        ...compressorValues,
        nameplateRatedEff: efficiency,
        nameplateHorsePowerId,
      });
    } else {
      handleCompressorInputChange(e);
    }

    setTriggerGetPowerFactor(!triggerGetPowerFactor);
  };

  useEffect(() => {
    const getData = async () => {
      await getPowerFactor();
    };
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerGetPowerFactor]);

  const unknownEfficiency = (e: any) => {
    const efficiencyUnknown: boolean = e.target.value;

    if (efficiencyUnknown) {
      const horsepowerUnknown = false;
      const actualPowerFactorUnknown = false;

      if (compressorValues.nameplateHorsePowerId != null) {
        const efficiency = getEfficiencyByHorsePowerId(
          compressorValues?.nameplateHorsePowerId
        );
        const nameplateRatedEff = Math.round(efficiency * 1000) / 1000;
        setCompressorValues({
          ...compressorValues,
          nameplateRatedEff,
          horsepowerUnknown,
          actualPowerFactorUnknown,
          efficiencyUnknown,
        });
        setTriggerGetPowerFactor(!triggerGetPowerFactor);
      } else {
        setCompressorValues({
          ...compressorValues,
          horsepowerUnknown,
          actualPowerFactorUnknown,
          efficiencyUnknown,
        });
      }
    } else {
      handleCompressorInputChange(e);
    }
  };

  function getEfficiencyByHorsePowerId(nameplateHorsePowerId: number | null) {
    let efficiency = 0;

    efficiency =
      defaultAllHorsePowersOptions.find((x) => x.id === nameplateHorsePowerId)
        ?.efficiency ?? 0;
    return efficiency;
  }

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={row}>
          <FormSelect
            name={"compressorManufacturerId"}
            value={compressorValues?.compressorManufacturerId ?? 0}
            label="Nameplate Motor Manufacturer"
            onChange={handleCompressorInputChange}
            options={compressorManufacturers}
            showSkeleton={isLoading}
            defaultValue={{ key: 0, value: "Select a value" }}
            error={compressorErros.compressorManufacturerId}
            mode={mode}
            disable={disableStudy}
          />
        </Grid>

        {compressorValues.compressorManufacturerId === 16 && (
          <Grid item xs={row}>
            <FormText
              name="otherCompressorManufacturer"
              label="Other compressor manufacturer"
              value={compressorValues?.otherCompressorManufacturer ?? ""}
              onChange={handleCompressorInputChange}
              error={compressorErros.otherCompressorManufacturer}
              showSkeleton={isLoading}
              mode={mode}
              disabled={disableStudy}
            />
          </Grid>
        )}
        <Grid item xs={6}>
          <FormSelect
            name="nameplateHorsePowerId"
            label="Nameplate HP"
            onChange={changeHorsePower}
            value={compressorValues.nameplateHorsePowerId ?? 0}
            error={compressorErros.serialNumber}
            showSkeleton={isLoading}
            options={defaultHorsePowersOptions}
            mode={mode}
            disabled={disableStudy || compressorValues?.horsepowerUnknown}
          />
        </Grid>
        <Grid item xs={2}>
          <FormCheckBox
            name="horsepowerUnknown"
            label="Nameplate HP unknown"
            onChange={unknownHorsepower}
            value={compressorValues?.horsepowerUnknown}
            showSkeleton={isLoading}
            mode={mode}
            disabled={disableStudy}
          />
        </Grid>

        <Grid item xs={12}>
          <FormRadioGroup
            label="Motor phase"
            name="motorPhase"
            onChange={handleCompressorInputChange}
            items={motorPhaseOptions}
            row
            value={compressorValues.motorPhase}
            defaultValue={"Three phase"}
            mode={mode}
            disabled={disableStudy}
            showSkeleton={isLoading}
          />
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={5}>
            <Grid item xs={2}>
              <Stack direction="row" justifyContent="flex-end">
                Nameplate Rated Volts
              </Stack>
            </Grid>
            <Grid item xs={1}>
              <FormNumeric2
                name="nameplateVoltsPhase1"
                value={compressorValues.nameplateVoltsPhase1 ?? ""}
                error={compressorErros.nameplateVoltsPhase1}
                label=""
                onChange={handleCompressorInputChange}
                onBlur={() => getPowerFactor()}
                showSkeleton={isLoading}
                mode={mode}
                disabled={disableStudy}
                decimalScale={2}
                maxValue={1000}
                fixedDecimalScale={true}
                placeholder="000.00 V"
              />
            </Grid>
            {showL2 && (
              <Grid item xs={1}>
                <FormNumeric2
                  name="nameplateVoltsPhase2"
                  value={compressorValues?.nameplateVoltsPhase2 ?? ""}
                  error={compressorErros.nameplateVoltsPhase2}
                  label=""
                  onChange={handleCompressorInputChange}
                  onBlur={() => getPowerFactor()}
                  showSkeleton={isLoading}
                  mode={mode}
                  disabled={disableStudy}
                  decimalScale={2}
                  maxValue={1000}
                  fixedDecimalScale={true}
                  placeholder="000.00 V"
                />
              </Grid>
            )}
            {showL3 && (
              <Grid item xs={1}>
                <FormNumeric2
                  name="nameplateVoltsPhase3"
                  value={compressorValues?.nameplateVoltsPhase3 ?? ""}
                  error={compressorErros.nameplateVoltsPhase3}
                  label=""
                  onChange={handleCompressorInputChange}
                  onBlur={() => getPowerFactor()}
                  showSkeleton={isLoading}
                  mode={mode}
                  disabled={disableStudy}
                  decimalScale={2}
                  maxValue={1000}
                  fixedDecimalScale={true}
                  placeholder="000.00 V"
                />
              </Grid>
            )}
            {showL4 && (
              <Grid item xs={1}>
                <FormNumeric2
                  name="nameplateVoltsPhase4"
                  value={compressorValues?.nameplateVoltsPhase4 ?? ""}
                  error={compressorErros.nameplateVoltsPhase4}
                  label=""
                  onChange={handleCompressorInputChange}
                  showSkeleton={isLoading}
                  mode={mode}
                  disabled={disableStudy}
                  decimalScale={2}
                  maxValue={1000}
                  fixedDecimalScale={true}
                  placeholder="000.00 V"
                />
              </Grid>
            )}
            {showL5 && (
              <Grid item xs={1}>
                <FormNumeric2
                  name="nameplateVoltsPhase5"
                  value={compressorValues?.nameplateVoltsPhase5 ?? ""}
                  error={compressorErros.nameplateVoltsPhase5}
                  label=""
                  onChange={handleCompressorInputChange}
                  showSkeleton={isLoading}
                  mode={mode}
                  disabled={disableStudy}
                  decimalScale={2}
                  maxValue={1000}
                  fixedDecimalScale={true}
                  placeholder="000.00 V"
                />
              </Grid>
            )}
            {showL6 && (
              <Grid item xs={1}>
                <FormNumeric2
                  name="nameplateVoltsPhase6"
                  value={compressorValues?.nameplateVoltsPhase6 ?? ""}
                  error={compressorErros.nameplateVoltsPhase6}
                  label=""
                  onChange={handleCompressorInputChange}
                  showSkeleton={isLoading}
                  mode={mode}
                  disabled={disableStudy}
                  decimalScale={2}
                  maxValue={1000}
                  fixedDecimalScale={true}
                  placeholder="000.00 V"
                />
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={5}>
            <Grid item xs={2}>
              <Stack direction="row" justifyContent="flex-end">
                Nameplate Rated Amps
              </Stack>
            </Grid>
            <Grid item xs={1}>
              <FormNumeric2
                name="nameplateAmpsPhase1"
                value={compressorValues?.nameplateAmpsPhase1 ?? ""}
                error={compressorErros.nameplateAmpsPhase1}
                label=""
                onChange={handleCompressorInputChange}
                onBlur={() => getPowerFactor()}
                showSkeleton={isLoading}
                mode={mode}
                disabled={disableStudy}
                decimalScale={2}
                maxValue={1000}
                fixedDecimalScale={true}
                placeholder="000.00 A"
              />
            </Grid>
            {showL2 && (
              <Grid item xs={1}>
                <FormNumeric2
                  name="nameplateAmpsPhase2"
                  value={compressorValues.nameplateAmpsPhase2 ?? ""}
                  error={compressorErros.nameplateAmpsPhase2}
                  label=""
                  onChange={handleCompressorInputChange}
                  onBlur={() => getPowerFactor()}
                  showSkeleton={isLoading}
                  mode={mode}
                  disabled={disableStudy}
                  decimalScale={2}
                  maxValue={1000}
                  fixedDecimalScale={true}
                  placeholder="000.00 A"
                />
              </Grid>
            )}
            {showL3 && (
              <Grid item xs={1}>
                <FormNumeric2
                  name="nameplateAmpsPhase3"
                  value={compressorValues?.nameplateAmpsPhase3 ?? ""}
                  error={compressorErros.nameplateAmpsPhase3}
                  label=""
                  onChange={handleCompressorInputChange}
                  onBlur={() => getPowerFactor()}
                  showSkeleton={isLoading}
                  mode={mode}
                  disabled={disableStudy}
                  decimalScale={2}
                  maxValue={1000}
                  fixedDecimalScale={true}
                  placeholder="000.00 A"
                />
              </Grid>
            )}
            {showL4 && (
              <Grid item xs={1}>
                <FormNumeric2
                  name="nameplateAmpsPhase4"
                  value={compressorValues?.nameplateAmpsPhase4 ?? ""}
                  error={compressorErros.nameplateAmpsPhase4}
                  label=""
                  onChange={handleCompressorInputChange}
                  showSkeleton={isLoading}
                  mode={mode}
                  disabled={disableStudy}
                  decimalScale={2}
                  maxValue={1000}
                  fixedDecimalScale={true}
                  placeholder="000.00 A"
                />
              </Grid>
            )}
            {showL5 && (
              <Grid item xs={1}>
                <FormNumeric2
                  name="nameplateAmpsPhase5"
                  value={compressorValues?.nameplateAmpsPhase5 ?? ""}
                  error={compressorErros.nameplateAmpsPhase5}
                  label=""
                  onChange={handleCompressorInputChange}
                  showSkeleton={isLoading}
                  mode={mode}
                  disabled={disableStudy}
                  decimalScale={2}
                  maxValue={1000}
                  fixedDecimalScale={true}
                  placeholder="000.00 A"
                />
              </Grid>
            )}
            {showL6 && (
              <Grid item xs={1}>
                <FormNumeric2
                  name="nameplateAmpsPhase6"
                  value={compressorValues?.nameplateAmpsPhase6 ?? ""}
                  error={compressorErros.nameplateAmpsPhase6}
                  label=""
                  onChange={handleCompressorInputChange}
                  showSkeleton={isLoading}
                  mode={mode}
                  disabled={disableStudy}
                  decimalScale={2}
                  maxValue={1000}
                  fixedDecimalScale={true}
                  placeholder="000.00 A"
                />
              </Grid>
            )}
          </Grid>
          {!disableStudy && (
            <Grid item xs={row}>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="center"
                spacing={2}
                pt={3}
              >
                <FormButton
                  text="Add"
                  onClick={addAdditionalVolAmp}
                  size="small"
                  variant="outlined"
                />
                <FormButton
                  text="Remove"
                  onClick={removeVolAmpValue}
                  size="small"
                  variant="outlined"
                />
              </Stack>
            </Grid>
          )}
        </Grid>
        <Grid item xs={4}>
          <FormMaskText
            name="nameplateRatedEff"
            mask="0.999"
            label="Nameplate efficiency (Eff)"
            value={compressorValues?.nameplateRatedEff?.toString() ?? ""}
            error={compressorErros.nameplateRatedEff}
            onChange={handleCompressorInputChange}
            onBlur={() => getPowerFactor()}
            showSkeleton={isLoading}
            mode={mode}
            disabled={disableStudy || compressorValues?.efficiencyUnknown}
          />
        </Grid>
        <Grid item xs={4}>
          <FormCheckBox
            name="efficiencyUnknown"
            label="Power Factor and Efficiency Unknown"
            onChange={unknownEfficiency}
            value={compressorValues?.efficiencyUnknown}
            showSkeleton={isLoading}
            mode={mode}
            disabled={disableStudy}
          />
        </Grid>
        <Grid item xs={1}>
          <HtmlTooltip
            TransitionComponent={Zoom}
            title={
              <React.Fragment>
                <div
                  dangerouslySetInnerHTML={{ __html: effToolTip }}
                  style={{ marginRight: "25px" }}
                />
              </React.Fragment>
            }
          >
            <span>
              <IconButton aria-label="delete" size="large">
                <InfoIcon />
              </IconButton>
            </span>
          </HtmlTooltip>
        </Grid>
        <Grid item xs={8}>
          <FormMaskText
            mask="0.999"
            name="nameplateRatedPF"
            value={compressorValues?.nameplateRatedPF?.toString() ?? ""}
            error={compressorErros.nameplateRatedPF}
            label="Nameplate power factor (PF)"
            onChange={handleCompressorInputChange}
            onBlur={(e: any) => handleNameplateRatedPFChange(e)}
            showSkeleton={isLoading}
            mode={mode}
            disabled={disableStudy || compressorValues?.efficiencyUnknown}
          />
        </Grid>
        <Grid item xs={1}>
          <HtmlTooltip
            TransitionComponent={Zoom}
            title={
              <React.Fragment>
                <div
                  dangerouslySetInnerHTML={{ __html: serviceFactorToolTip }}
                  style={{ marginRight: "25px" }}
                />
              </React.Fragment>
            }
          >
            <span>
              <IconButton aria-label="delete" size="large">
                <InfoIcon />
              </IconButton>
            </span>
          </HtmlTooltip>
        </Grid>
        <Grid item xs={12}>
          <FormRadioGroup
            label="Nameplate Rated Hz"
            name="nameplateRatedHz"
            onChange={handleCompressorInputChange}
            items={hertzOptions}
            row
            value={compressorValues.nameplateRatedHz}
            mode={mode}
            disabled={disableStudy}
            showSkeleton={isLoading}
            defaultValue={60}
          />
        </Grid>
      </Grid>
      <DialogMessagePopup
        title={"Limit reached."}
        text={
          <>
            {compressorValues.additionalVolAmpValue > 0 && (
              <p>You have reached the limit of additional "Volts/Amps" sets.</p>
            )}
            {compressorValues.additionalVolAmpValue === 0 && (
              <p>You can't remove anymore "Volts/Amps" sets.</p>
            )}
          </>
        }
        showPopup={showLimitModal}
        setShowPopup={setShowLimitModal}
        onSave={() => {
          setShowLimitModal(false);
        }}
        isSubmitting={false}
        hideCancel={true}
      />

      <DialogMessagePopup
        title={"Limit reached."}
        text={effPfMessage}
        showPopup={showEffPfLimitModal}
        setShowPopup={setShowEffPfLimitModal}
        onSave={() => {
          setShowEffPfLimitModal(false);
        }}
        isSubmitting={false}
        hideCancel={true}
      />

      <DialogMessagePopup
        title={"Important."}
        text={
          <div
            dangerouslySetInnerHTML={{ __html: serviceFactorToolTip }}
            style={{ marginRight: "25px" }}
          />
        }
        showPopup={showServiceFactorModal}
        setShowPopup={setShowServiceFactorModal}
        onSave={() => {
          setShowServiceFactorModal(false);
        }}
        isSubmitting={false}
        hideCancel={true}
      />
    </>
  );
};

export default CompressorForm;
