import { ISourceSoundCombine } from "src/ts/interfaces/catalogs";
import axios from "src/utils/axios";

const getByEnergySourceSubtractedId = (energySourceSubtractedId: number) => {
  return axios.get<ISourceSoundCombine[]>(
    `SourceSoundCombine/Report?energySourceSubtractedId=${energySourceSubtractedId}`
  );
};

const add = (sourceSoundCombine: ISourceSoundCombine) => {
  return axios.post<ISourceSoundCombine>(
    "SourceSoundCombine",
    sourceSoundCombine
  );
};

const update = (id: number, sourceSoundCombine: ISourceSoundCombine) => {
  return axios.put<ISourceSoundCombine>(
    `SourceSoundCombine/${id}`,
    sourceSoundCombine
  );
};

const updateAll = (sourceSoundCombine: ISourceSoundCombine[]) => {
  return axios.put<ISourceSoundCombine>(
    "SourceSoundCombine",
    sourceSoundCombine
  );
};

const remove = (energySourceSubtractedId: number) => {
  return axios.delete(`SourceSoundCombine/${energySourceSubtractedId}`);
};

const sourceSoundCombineService = {
  getByEnergySourceSubtractedId,
  add,
  update,
  updateAll,
  remove,
};

export default sourceSoundCombineService;
