import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import {
  Grid,
  Card as MuiCard,
  Divider as MuiDivider,
  CardContent,
  Tabs,
  Tab,
  Stack,
} from "@mui/material";
import { spacing } from "@mui/system";
import useForm, { Form } from "src/hooks/useForm";
import { FormText, FormButton } from "src/components/formControls";
import { useLoading, useLog, usePermissions } from "src/hooks";
import HeaderPage from "src/components/page/HeaderPage";
import { AddIcon, ShieldIcon } from "src/components/icons";
import NewProject from "./componentsArchitectProject/NewProject";
import projectService from "src/services/project/projectService";
import { ARCHITECTDATA, ARCHITECTTABS } from "src/constants";
import { IArchitectProjects } from "src/ts/interfaces/projectArchitect";
import OutForBidProject from "./componentsArchitectProject/OutForBidProject";
import { IProjectSummary } from "src/ts/interfaces/project/projectDto";
import AwarededProject from "./componentsArchitectProject/AwarededProject";
import ReadyForReviewProject from "./componentsArchitectProject/ReadyForReviewProject";
import ApprovedProject from "./componentsArchitectProject/ApprovedProject";
import RejectedProject from "./componentsArchitectProject/RejectedProject";
import ClosedProject from "./componentsArchitectProject/ClosedProject";
import ArchivedProject from "./componentsArchitectProject/ArchivedProject";
import { useNavigate, useParams } from "react-router-dom";
import { Permission as PermissionTypes } from "src/ts/enums";
const Divider = styled(MuiDivider)(spacing);
const Card = styled(MuiCard)(spacing);

const initialValues = {
  search: "",
};

const initialValuesSummary = {
  new: 0,
  readyForReview: 0,
  approved: 0,
  rejected: 0,
  assignedProjects: 0,
  inProcess: 0,
  closed: 0,
  archived: 0,
};

export type ProjectsParams = {
  companyId: string;
  tabPage: string;
};

const ProjectsArchitect = () => {
  let params = useParams<ProjectsParams>();
  const companyId = parseInt(
    params?.companyId === undefined ? "0" : params?.companyId
  );
  const tabPage = params.tabPage;
  const { log } = useLog();
  const { startRequest, endRequest, isLoading } = useLoading();
  const [dataTable, setDataTable] = useState<IArchitectProjects[]>([]);
  const [summary, setSummary] = useState<IProjectSummary>(initialValuesSummary);
  const [value, setValue] = useState(-1);
  const [refreshGrid, setRefreshGrid] = useState(false);
  const [dataFilter, setDataFilter] = useState<IArchitectProjects[]>([]);
  const { fullAccess } = usePermissions(PermissionTypes.Project);
  let navigate = useNavigate();
  const { values, handleInputChange } = useForm(initialValues, false, []);

  useEffect(() => {
    startRequest("summary");
    getDataSummary();
    endRequest("summary");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const getData = async () => {
      try {
        startRequest("Get Data");
        const filterByTab = (tab: string) => {
          for (const key in ARCHITECTDATA) {
            if (ARCHITECTDATA[key].tab === tab) {
              return ARCHITECTDATA[key].queryData;
            }
          }
          return 0;
        };

        const response = await projectService.getDataTabs(
          filterByTab(value.toString())
        );

        if (values.search !== "") {
          setDataTable(tableFilter(response.data));
          setDataFilter(response.data);
        } else {
          setDataTable(response.data);
          setDataFilter(response.data);
        }

        endRequest("Get Data");
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      } finally {
        endRequest("Get Data");
      }
    };

    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, refreshGrid]);

  useEffect(() => {
    setDataTable(tableFilter(dataFilter));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.search]);

  const getDataSummary = async () => {
    try {
      const responseSummary = await projectService.getArchitectSummary();
      setSummary(responseSummary.data);
      switch (tabPage) {
        case "new":
          setValue(ARCHITECTTABS.NEW);
          break;
        case "outForBid":
          setValue(ARCHITECTTABS.OUTFORBID);
          break;
        case "awarded":
          setValue(ARCHITECTTABS.AWARDED);
          break;
        case "readyForReview":
          setValue(ARCHITECTTABS.READYFORREVIEW);
          break;
        case "approved":
          setValue(ARCHITECTTABS.APPROVED);
          break;
        case "rejected":
          setValue(ARCHITECTTABS.REJECTED);
          break;
        case "closed":
          setValue(ARCHITECTTABS.CLOSED);
          break;
        case "archived":
          setValue(ARCHITECTTABS.ARCHIVED);
          break;
        default:
          setValue(
            value > -1
              ? value
              : responseSummary.data.new === 0
              ? ARCHITECTTABS.OUTFORBID
              : ARCHITECTTABS.NEW
          );

          break;
      }
    } catch (error: any) {
      log.error(error?.message?.exceptionMessage ?? "Something went wrong ");
    }
  };

  const tableFilter = (data: IArchitectProjects[]) => {
    if (values.search === "") {
      return dataFilter;
    }
    const searchLower = values.search.toLowerCase();

    const filteredArray = data.filter((item: IArchitectProjects) => {
      return (
        item.code.toLowerCase().includes(searchLower) ||
        item.name.toLowerCase().includes(searchLower) ||
        item.buildingAddress.toLowerCase().includes(searchLower) ||
        item.buildingName.toLowerCase().includes(searchLower) ||
        item?.assignedTo?.toLowerCase().includes(searchLower)
      );
    });

    return filteredArray;
  };

  useEffect(() => {
    getDataSummary();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshGrid]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <>
      <HeaderPage
        title={`Projects`}
        parentText={"Dashboard"}
        parentLink={`/`}
        actionSection={
          <>
            <Stack direction={"row"} spacing={2}>
              {fullAccess && (
                <FormButton
                  text="Create New Project"
                  color="secondary"
                  startIcon={<AddIcon />}
                  onClick={() => {
                    navigate(`/app/ProjectWizard/Edit/0/${companyId}`);
                  }}
                  size="medium"
                />
              )}

              <FormButton
                onClick={() => {
                  navigate(`/app/infectiousProject/${companyId}`);
                }}
                variant="outlined"
                color="primary"
                text={"View Infectious Control Compliance Projects"}
                startIcon={<ShieldIcon />}
                size="medium"
              />
            </Stack>
          </>
        }
        pageYoutube=""
      ></HeaderPage>
      <Divider my={6} />
      <Card mb={6}>
        <CardContent>
          <Grid
            container
            spacing={10}
            sx={{ borderBottom: 1, borderColor: "divider", marginBottom: 5 }}
          >
            <Grid item xs={12}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                <Tab
                  label={`New (${summary?.new})`}
                  id={"0"}
                  value={ARCHITECTTABS.NEW}
                />
                <Tab
                  label={`Out For Bid (${summary?.inProcess})`}
                  id={"1"}
                  value={ARCHITECTTABS.OUTFORBID}
                />
                <Tab
                  label={`Awarded (${summary?.assignedProjects})`}
                  id={"2"}
                  value={ARCHITECTTABS.AWARDED}
                />
                <Tab
                  label={`Ready for review (${summary?.readyForReview})`}
                  id={"3"}
                  value={ARCHITECTTABS.READYFORREVIEW}
                />
                <Tab
                  label={`Approved (${summary?.approved})`}
                  id={"4"}
                  value={ARCHITECTTABS.APPROVED}
                />
                <Tab
                  label={`Rejected (${summary?.rejected})`}
                  id={"5"}
                  value={ARCHITECTTABS.REJECTED}
                />
                <Tab
                  label={`Closed (${summary?.closed})`}
                  id={"6"}
                  value={ARCHITECTTABS.CLOSED}
                />
                <Tab
                  label={`Archived (${summary?.archived})`}
                  id={"7"}
                  value={ARCHITECTTABS.ARCHIVED}
                />
              </Tabs>
            </Grid>
          </Grid>

          <Grid container spacing={10}>
            <Grid item xs={12}>
              <Form>
                <Grid container spacing={5}>
                  <Grid item xs={12}>
                    <FormText
                      name="search"
                      value={values.search}
                      label="Search"
                      onChange={handleInputChange}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    {value === ARCHITECTTABS.NEW && (
                      <NewProject
                        filters={values}
                        setRefreshGrid={setRefreshGrid}
                        refreshGrid={refreshGrid}
                        dataTable={dataTable}
                        isLoading={isLoading}
                        getDataSummary={getDataSummary}
                        setDataTable={setDataTable}
                      />
                    )}
                    {value === ARCHITECTTABS.OUTFORBID && (
                      <OutForBidProject
                        filters={values}
                        setRefreshGrid={setRefreshGrid}
                        refreshGrid={refreshGrid}
                        dataTable={dataTable}
                        isLoading={isLoading}
                        getDataSummary={getDataSummary}
                        setDataTable={setDataTable}
                      />
                    )}
                    {value === ARCHITECTTABS.AWARDED && (
                      <AwarededProject
                        filters={values}
                        setRefreshGrid={setRefreshGrid}
                        refreshGrid={refreshGrid}
                        dataTable={dataTable}
                        isLoading={isLoading}
                        getDataSummary={getDataSummary}
                        setDataTable={setDataTable}
                      />
                    )}
                    {value === ARCHITECTTABS.READYFORREVIEW && (
                      <ReadyForReviewProject
                        filters={values}
                        refresh={refreshGrid}
                        setRefreshGrid={setRefreshGrid}
                        dataTable={dataTable}
                        isLoading={isLoading}
                      />
                    )}
                    {value === ARCHITECTTABS.APPROVED && (
                      <ApprovedProject
                        filters={values}
                        refresh={refreshGrid}
                        setRefreshGrid={setRefreshGrid}
                        dataTable={dataTable}
                        isLoading={isLoading}
                      />
                    )}
                    {value === ARCHITECTTABS.REJECTED && (
                      <RejectedProject
                        filters={values}
                        refresh={refreshGrid}
                        setRefreshGrid={setRefreshGrid}
                        dataTable={dataTable}
                        isLoading={isLoading}
                      />
                    )}
                    {value === ARCHITECTTABS.CLOSED && (
                      <ClosedProject
                        filters={values}
                        refreshGrid={refreshGrid}
                        dataTable={dataTable}
                        isLoading={isLoading}
                        getDataSummary={getDataSummary}
                        setDataTable={setDataTable}
                        setRefreshGrid={setRefreshGrid}
                      />
                    )}
                    {value === ARCHITECTTABS.ARCHIVED && (
                      <ArchivedProject
                        filters={values}
                        refresh={refreshGrid}
                        dataTable={dataTable}
                        isLoading={isLoading}
                        getDataSummary={getDataSummary}
                        setDataTable={setDataTable}
                      />
                    )}
                  </Grid>
                </Grid>
              </Form>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
};

export default ProjectsArchitect;
