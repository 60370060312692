import { IOutletReport } from "src/ts/interfaces";
import axios from "src/utils/axios";

const getById = (id: number) => {
  return axios.get<IOutletReport>(`OutletReport/${id}`);
};

const update = (id: number, report: IOutletReport) => {
  return axios.put(`OutletReport/${id}`, report);
};

const outletReportService = {
  getById,
  update,
};

export default outletReportService;
