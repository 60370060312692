import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Grid } from "@mui/material";
import { FormButton } from "../../components/formControls";
import { useAsyncQuery, useAuth } from "src/hooks";
import { IStorageMetrics } from "src/ts/interfaces/storage";
import storageService from "src/services/storageService";

const StorageWidget = () => {
  const { user } = useAuth();
  const navigate = useNavigate();

  const { execute: getStorageMetric, data: storageMetric } =
    useAsyncQuery<IStorageMetrics>(storageService.storageMetrics);

  useEffect(() => {
    const getData = async () => {
      getStorageMetric(user?.companyId);
    };

    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Grid item p={1}>
        <FormButton
          text={`Storage Available ${
            storageMetric !== null
              ? storageMetric.storagePercentageAvailable.toFixed(2)
              : "0"
          }%`}
          variant="outlined"
          onClick={() => {
            navigate(`/app/storageSummary`);
          }}
          size="small"
          color="primary"
        />
      </Grid>
    </>
  );
};

export default StorageWidget;
