import { CircularProgress } from "@mui/material";
import { CloudUploadIcon } from "../icons";
import { ToolTip } from "../others/ToolTip";
import { Button as MuiButton } from "@mui/material";

interface GridUploadButtonProps {
  onChange?: (e: any) => void;
  accept: string;
  text: string;
  variant?: "text" | "contained" | "outlined" | undefined;
  size?: "small" | "large" | "medium" | undefined;
  isSubmitting: boolean;
  disabled?: boolean;
}

const FormUploadButton = (props: GridUploadButtonProps) => {
  const { onChange, accept, text, variant, size, isSubmitting, disabled } =
    props;
  const handleClick = (event: any) => {
    const { target = {} } = event || {};
    target.value = "";
  };
  return (
    <>
      <input
        accept={accept}
        id="icon-button-file"
        type="file"
        hidden
        onChange={onChange}
        disabled={disabled}
        onClick={handleClick}
      />
      <label htmlFor="icon-button-file">
        <ToolTip title={"Upload"}>
          <MuiButton
            component="span"
            disabled={disabled}
            variant={variant || "contained"}
            startIcon={
              isSubmitting ? (
                <CircularProgress size="1rem" />
              ) : (
                <CloudUploadIcon />
              )
            }
            size={size || "large"}
          >
            {text}
          </MuiButton>
        </ToolTip>
      </label>
    </>
  );
};

export default FormUploadButton;
