import {
  CardContent,
  Grid,
  Stack,
  Card as MuiCard,
  Divider as MuiDivider,
  Typography,
  Alert,
  AlertTitle,
} from "@mui/material";
import { spacing } from "@mui/system";
import styled from "@emotion/styled";
import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  useAsyncQuery,
  useAuth,
  useEffectOnce,
  useLog,
  useSystemOfMeasurement,
} from "src/hooks";
import chillerService from "src/services/study/chillerService";
import { IChiller, IKeyValue, IReportType } from "src/ts/interfaces";
import { Mode } from "src/ts/types";
import StudyWizard from "../../components/StudyWizard";
import {
  FormCheckSwitch,
  FormDatePicker,
  FormNumeric2,
  FormSelect,
} from "src/components/formControls";
import { FooterButtons } from "../../components/FooterButtons";
import DialogMessagePopup from "src/components/DialogMessagePopup";
import { steps, studyTypeCode } from "../const";
import projectReportService from "src/services/study/projectReportService";
import { IProjectReport } from "src/ts/interfaces/project/projectDto";
import reportTypeService from "src/services/study/reportTypeService";
import studiesHistoryService from "src/services/study/studiesHistoryService";
import useChillerStudy from "../hooks/useChillerStudy";
import moment from "moment";
import HeaderStudyPage from "src/components/page/HeaderStudyPage";

const Divider = styled(MuiDivider)(spacing);
const Card = styled(MuiCard)(spacing);
const activeStep = 1;

interface IChillerCongiguration {
  evaporatorLastPumpsToTest: number;
  condenserLastPumpsToTest: number;
}

const numberOfEvaporatorPumps = [
  { key: 1, value: 1 },
  { key: 2, value: 2 },
  { key: 3, value: 3 },
  { key: 4, value: 4 },
  { key: 5, value: 5 },
  { key: 6, value: 6 },
  { key: 7, value: 7 },
  { key: 8, value: 8 },
  { key: 9, value: 9 },
  { key: 10, value: 10 },
  { key: 11, value: 11 },
  { key: 12, value: 12 },
  { key: 13, value: 13 },
  { key: 14, value: 14 },
  { key: 15, value: 15 },
  { key: 16, value: 16 },
  { key: 17, value: 17 },
  { key: 18, value: 18 },
  { key: 19, value: 19 },
  { key: 20, value: 20 },
];

const ChillerStep2 = () => {
  const { user } = useAuth();
  const params = useParams<{ id: string }>();
  const id = parseInt(params?.id === undefined ? "0" : params?.id);
  let navigate = useNavigate();
  const { log } = useLog();
  const { systemOfMeasurement } = useSystemOfMeasurement(true);

  const [reportType, setReportType] = useState<IReportType | null>();

  const { execute: executeReport, data: dataReport } =
    useAsyncQuery<IProjectReport>(projectReportService.report);

  const {
    chillerValues,
    setChillerValues,
    chillerErros,
    handleChillerInputChange,
    validateChillerValues,
  } = useChillerStudy();

  const [days, setDays] = useState({
    weekDays: 0,
    saturdays: 0,
    sundays: 0,
  });

  const [isLoading, setIsLoading] = useState(false);
  const [
    numberOfEvaporatorPumpsConnected,
    setNumberOfEvaporatorPumpsConnected,
  ] = useState<IKeyValue<number, string>[]>([]);

  const [numberOfCondenserPumpsConnected, setNumberOfCondenserPumpsConnected] =
    useState<IKeyValue<number, string>[]>([]);

  const [chillerConfig, setChillerConfig] = useState<IChillerCongiguration>({
    evaporatorLastPumpsToTest: 0,
    condenserLastPumpsToTest: 0,
  });

  const [showDateErrorPopup, setShowErrorPopup] = useState(false);
  const [showDecreaseTowerModal, setShowDecreaseTowerModal] = useState(false);

  useEffectOnce(() => {
    const getData = async () => {
      try {
        setIsLoading(true);
        const chillerRes = await chillerService.getById(id);
        setChillerValues(chillerRes.data);

        setChillerConfig({
          evaporatorLastPumpsToTest:
            chillerRes.data.numberOfEvaporatorPumpsToBeTested,
          condenserLastPumpsToTest:
            chillerRes.data.numberOfCondenserPumpsToBeTested,
        });

        const reportTypeRes = await reportTypeService.getReportTypeByCode(
          studyTypeCode
        );
        setReportType(reportTypeRes.data);

        await executeReport(id, reportTypeRes.data.code);
        if (
          chillerRes.data.operatedFromApproximately !== null &&
          chillerRes.data.operatedToApproximately !== null
        ) {
          getWorkingDaysDetail(
            chillerRes.data.operatedFromApproximately,
            chillerRes.data.operatedToApproximately
          );
        }

        let numberOfPumps = user?.role === "Training" ? 3 : 21;
        const numberOfItems: IKeyValue<number, string>[] =
          getPumps(numberOfPumps);

        setNumberOfEvaporatorPumpsConnected(numberOfItems);
        setNumberOfCondenserPumpsConnected(numberOfItems);
        await systemOfMeasurement.getByProject(chillerRes.data.projectId);
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      } finally {
        setIsLoading(false);
      }
    };
    if (id > 0) getData();
  });

  const getPumps = (count: number) => {
    let arrayX = [];
    for (let i = 0; i < count; i++) {
      let obj: IKeyValue<number, string> = {
        key: i,
        value: i.toString(),
      };
      arrayX.push(obj);
    }

    return arrayX;
  };

  const getWorkingDaysDetail = (date1: string, date2: string) => {
    const d1 = moment(date1, "YYYY-MM-DD").toDate();
    const d2 = moment(date2, "YYYY-MM-DD").toDate();

    let weekDays = 0;
    let saturdays = 0;
    let sundays = 0;

    while (d1 <= d2) {
      var day = d1.getDay();
      switch (day) {
        case 0:
          sundays++;
          break;
        case 6:
          saturdays++;
          break;
        default:
          weekDays++;
          break;
      }
      d1.setDate(d1.getDate() + 1);
    }

    setDays({
      saturdays,
      sundays,
      weekDays,
    });

    return false;
  };

  const mode: Mode = chillerValues.isComplete ? "read" : "read&Write";

  const disableStudy = useMemo(() => {
    return (
      chillerValues?.isComplete ||
      (dataReport?.isInUse && dataReport?.isInUseById !== user?.userId) ||
      dataReport?.isPartOfProject === false
    );
  }, [
    dataReport?.isInUse,
    dataReport?.isInUseById,
    dataReport?.isPartOfProject,
    user?.userId,
    chillerValues?.isComplete,
  ]);

  const saveHandler = async () => {
    if (!validateChillerValues()) return;
    await validatePumpsDecrement("save");
  };

  const nextHandle = async () => {
    await validatePumpsDecrement("next");
  };

  const backHandler = async () => {
    await validatePumpsDecrement("back");
  };

  const [temporalAction, setTemporalAction] = useState("");

  const validatePumpsDecrement = async (action: string) => {
    setTemporalAction(action);

    const lastTotalPumps =
      chillerConfig.evaporatorLastPumpsToTest +
      chillerConfig.condenserLastPumpsToTest;
    const actualTotalPumps =
      chillerValues.numberOfEvaporatorPumpsToBeTested +
      chillerValues.numberOfCondenserPumpsToBeTested;

    if (lastTotalPumps > actualTotalPumps) {
      //vm.pumpsDecreased = true;
      setShowDecreaseTowerModal(true);
      return;
    }

    if (action === "save") {
      await continueAction();
    }
    if (action === "next") {
      await nextAction(chillerValues);
    }
    if (action === "back") {
      await back(chillerValues);
    }
  };

  const [informationModal, setInformationModal] = useState(false);

  const nextAction = (chillerValues: IChiller) => {
    if (disableStudy) {
      const route = `/studies/Chiller/step3/${id}`;
      navigate(route);
    } else {
      if (
        (chillerValues.numberOfEvaporatorPumpsToBeTested > 0 &&
          chillerValues.numberOfEvaporatorPumpsToBeTestedCreated === false &&
          chillerValues.numberOfEvaporatorPumpsToBeTestedToggle === true) ||
        (chillerValues.numberOfCondenserPumpsToBeTested > 0 &&
          chillerValues.numberOfCondenserPumpsToBeTestedCreated === false &&
          chillerValues.numberOfCondenserPumpsToBeTestedToogle === true)
      ) {
        setTemporalAction("next");
        setInformationModal(true);
      } else {
        continueAction();
      }
    }
  };

  const continueAction = async () => {
    const lastTotalPumps =
      chillerConfig.evaporatorLastPumpsToTest +
      chillerConfig.condenserLastPumpsToTest;
    const actualTotalPumps =
      chillerValues.numberOfEvaporatorPumpsToBeTested +
      chillerValues.numberOfCondenserPumpsToBeTested;

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const totalpump = Math.abs(actualTotalPumps - lastTotalPumps);
    let newChillerValues = { ...chillerValues };
    if (
      chillerValues.numberOfEvaporatorPumpsToBeTested > 0 &&
      chillerValues.numberOfEvaporatorPumpsToBeTestedCreated === false &&
      chillerValues.numberOfEvaporatorPumpsToBeTestedToggle === true
    ) {
      newChillerValues.numberOfEvaporatorPumpsToBeTestedCreated = true; //move
    }
    if (
      chillerValues.numberOfCondenserPumpsToBeTested > 0 &&
      chillerValues.numberOfCondenserPumpsToBeTestedCreated === false &&
      chillerValues.numberOfCondenserPumpsToBeTestedToogle === true
    ) {
      newChillerValues.numberOfCondenserPumpsToBeTestedCreated = true; //move
    }

    setChillerConfig({
      evaporatorLastPumpsToTest:
        chillerValues.numberOfEvaporatorPumpsToBeTested,
      condenserLastPumpsToTest: chillerValues.numberOfCondenserPumpsToBeTested,
    });

    switch (temporalAction) {
      case "save":
        await save(newChillerValues);
        break;
      case "next":
        await next(newChillerValues);
        break;
      case "back":
        await back(newChillerValues);
        break;
      default:
        await savePartial(newChillerValues);
        break;
    }
  };

  const save = async (chiller: IChiller) => {
    if (disableStudy) return;

    const chillerRes = await chillerService.update(chiller.id, chiller);
    await studiesHistoryService.save(
      chillerValues.projectId,
      reportType?.id ?? 0,
      chillerValues.id,
      "saved"
    );
    setChillerValues(chillerRes.data);
    log.info("Report Was saved");
  };

  const next = async (chiller: IChiller) => {
    await save(chiller);
    const route = `/studies/Chiller/step3/${id}`;
    navigate(route);
  };

  const back = async (chiller: IChiller) => {
    await save(chiller);
    const route = `/studies/Chiller/step1/${id}`;
    navigate(route);
  };

  const [btuhRatio, setBtuhRatio] = useState(0);

  const savePartial = async (chiller: IChiller) => {
    const chillerRes = await chillerService.update(chiller.id, chiller);
    await studiesHistoryService.save(
      chillerValues.projectId,
      reportType?.id ?? 0,
      chillerValues.id,
      "saved"
    );
    setChillerValues(chillerRes.data);
  };

  const savePartialHandler = () => {
    savePartial(chillerValues);
  };

  useEffect(() => {
    if (
      chillerValues.condenserDesignBTUH != null &&
      chillerValues?.evaporatorDesignBTUH != null &&
      chillerValues?.evaporatorDesignBTUH > 0
    ) {
      setBtuhRatio(
        Math.round(
          (chillerValues.condenserDesignBTUH /
            chillerValues.evaporatorDesignBTUH) *
            10000
        ) / 100
      );
    }
  }, [chillerValues.condenserDesignBTUH, chillerValues.evaporatorDesignBTUH]);

  const calculateEstimatedAnualHoursOperated = () => {
    const hoursMonFri =
      chillerValues.estimatedAnnualHoursMonFri * days.weekDays;
    const hoursSat = chillerValues.estimatedAnualHoursSaturday * days.saturdays;
    const hoursSun = chillerValues.estimatedAnnualHoursSunday * days.sundays;

    const estimatedAnnualHoursOperated = hoursMonFri + hoursSat + hoursSun;

    const estimatedAnnualOperatingDays =
      (chillerValues.estimatedAnnualHoursMonFri > 0 ? days.weekDays : 0) +
      (chillerValues.estimatedAnualHoursSaturday > 0 ? days.saturdays : 0) +
      (chillerValues.estimatedAnnualHoursSunday > 0 ? days.sundays : 0);

    setChillerValues({
      ...chillerValues,
      estimatedAnnualHoursOperated,
      estimatedAnnualOperatingDays,
    });
  };

  const compareDateFromAndTo = () => {
    if (
      chillerValues.operatedToApproximately != null &&
      chillerValues.operatedFromApproximately != null
    ) {
      if (
        Date.parse(chillerValues.operatedToApproximately) <
        Date.parse(chillerValues.operatedFromApproximately)
      ) {
        setShowErrorPopup(true);
        setChillerValues({ ...chillerValues, operatedToApproximately: null });
        return;
      }

      if (
        chillerValues.operatedFromApproximately !== null &&
        chillerValues.operatedToApproximately !== null
      )
        getWorkingDaysDetail(
          chillerValues.operatedFromApproximately,
          chillerValues.operatedToApproximately
        );
    }
  };

  useEffect(() => {
    compareDateFromAndTo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    chillerValues.operatedFromApproximately,
    chillerValues.operatedToApproximately,
  ]);

  useEffect(() => {
    if (!chillerValues?.numberOfEvaporatorPumpsConnectedToThisChillerToggle) {
      setChillerValues({
        ...chillerValues,
        numberOfEvaporatorPumpsConnectedToThisChiller: 0,
        numberOfEvaporatorPumpsToBeTested: 0,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chillerValues?.numberOfEvaporatorPumpsConnectedToThisChillerToggle]);

  useEffect(() => {
    if (!chillerValues?.numberOfEvaporatorPumpsToBeTestedToggle) {
      setChillerValues({
        ...chillerValues,
        numberOfEvaporatorPumpsToBeTested: 0,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chillerValues?.numberOfEvaporatorPumpsToBeTestedToggle]);

  useEffect(() => {
    if (!chillerValues?.numberOfCondenserPumpsConnectedToThisChillerToggle) {
      setChillerValues({
        ...chillerValues,
        numberOfCondenserPumpsConnectedToThisChiller: 0,
        numberOfCondenserPumpsToBeTested: 0,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chillerValues?.numberOfCondenserPumpsConnectedToThisChillerToggle]);

  useEffect(() => {
    if (!chillerValues?.numberOfCondenserPumpsToBeTestedToogle) {
      setChillerValues({
        ...chillerValues,
        //numberOfCondenserPumpsConnectedToThisChiller: 0,
        numberOfCondenserPumpsToBeTested: 0,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chillerValues?.numberOfCondenserPumpsToBeTestedToogle]);

  useEffect(() => {
    calculateEstimatedAnualHoursOperated();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [days]);

  const showWarning = () => {
    if (
      chillerValues?.condenserDesignBTUH === null ||
      chillerValues?.evaporatorDesignBTUH === null
    )
      return false;
    return (
      (chillerValues?.condenserDesignBTUH <
        chillerValues?.evaporatorDesignBTUH * 1.2 ||
        chillerValues?.condenserDesignBTUH >
          chillerValues?.evaporatorDesignBTUH * 1.3) &&
      validateEvaporatorConenserData()
    );
  };

  const validateEvaporatorConenserData = () => {
    if (
      chillerValues.designRatedGPMTotal == null ||
      chillerValues.designRatedGPMTotal === 0
    ) {
      return false;
    }
    if (
      chillerValues.designRatedGPMTotalCondenser == null ||
      chillerValues.designRatedGPMTotalCondenser === 0
    ) {
      return false;
    }
    if (
      chillerValues.designRatedWarmEWT == null ||
      chillerValues.designRatedWarmEWT === 0
    ) {
      return false;
    }
    if (
      chillerValues.designRatedWarmEWTCondenser == null ||
      chillerValues.designRatedWarmEWTCondenser === 0
    ) {
      return false;
    }
    if (
      chillerValues.designRatedColdLWT == null ||
      chillerValues.designRatedColdLWT === 0
    ) {
      return false;
    }
    if (
      chillerValues.designRatedColdLWTCondenser == null ||
      chillerValues.designRatedColdLWTCondenser === 0
    ) {
      return false;
    }

    return true;
  };
  return (
    <>
      <HeaderStudyPage
        headerStudiesPage={{
          code: studyTypeCode,
          system: chillerValues.system,
          id: id,
        }}
        parentText="Project"
        parentLink="/"
      />
      <Divider my={6} />

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <StudyWizard
            steps={steps}
            activeStep={activeStep}
            projectId={dataReport?.projectId}
            reportId={dataReport?.reportId}
            reportTypeId={dataReport?.reportTypeId}
          />
        </Grid>
        <Grid item xs={12}>
          <Card mb={1}>
            <CardContent>
              <Grid container spacing={5}>
                <Grid item xs={12}>
                  <Stack direction="row" spacing={5}>
                    <FormDatePicker
                      name="operatedFromApproximately"
                      label="Operated from approximately (Date)"
                      value={chillerValues.operatedFromApproximately}
                      onChange={handleChillerInputChange}
                      error={chillerErros.operatedFromApproximately}
                      mode={mode}
                      disable={disableStudy}
                      showSkeleton={isLoading}
                      outputFormat="YYYY-MM-DD"
                    />
                    <FormDatePicker
                      name="operatedToApproximately"
                      label="Operated to (Date)"
                      value={chillerValues.operatedToApproximately}
                      onChange={handleChillerInputChange}
                      error={chillerErros.operatedToApproximately}
                      minDate={chillerValues.operatedFromApproximately}
                      mode={mode}
                      disable={disableStudy}
                      showSkeleton={isLoading}
                      outputFormat="YYYY-MM-DD"
                    />
                  </Stack>
                </Grid>
                <Grid item xs={12}>
                  <FormNumeric2
                    name="estimatedAnnualHoursMonFri"
                    label='"Estimated" Daily Operating Hours Mon - Fri (Hrs / Per day)'
                    value={chillerValues?.estimatedAnnualHoursMonFri ?? 0}
                    onChange={handleChillerInputChange}
                    onBlur={calculateEstimatedAnualHoursOperated}
                    error={chillerErros.estimatedAnnualHoursMonFri}
                    showSkeleton={isLoading}
                    mode={mode}
                    disabled={disableStudy}
                    decimalScale={0}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormNumeric2
                    name="estimatedAnualHoursSaturday"
                    label='"Estimated" Daily Operating Hours Saturday (Hrs / Per day)'
                    value={chillerValues?.estimatedAnualHoursSaturday ?? 0}
                    onChange={handleChillerInputChange}
                    onBlur={calculateEstimatedAnualHoursOperated}
                    error={chillerErros.estimatedAnualHoursSaturday}
                    showSkeleton={isLoading}
                    mode={mode}
                    disabled={disableStudy}
                    decimalScale={0}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormNumeric2
                    name="estimatedAnnualHoursSunday"
                    label='"Estimated" Daily Operating Hours Sunday (Hrs/Per day)'
                    value={chillerValues?.estimatedAnnualHoursSunday ?? 0}
                    onChange={handleChillerInputChange}
                    onBlur={calculateEstimatedAnualHoursOperated}
                    error={chillerErros.estimatedAnnualHoursSunday}
                    showSkeleton={isLoading}
                    mode={mode}
                    disabled={disableStudy}
                    decimalScale={0}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormNumeric2
                    name="estimatedAnnualHoursOperated"
                    label='"Estimated" Annual Operating Hours (Hrs/Per day)'
                    value={chillerValues?.estimatedAnnualHoursOperated ?? 0}
                    onChange={handleChillerInputChange}
                    error={chillerErros.estimatedAnnualHoursOperated}
                    showSkeleton={isLoading}
                    mode={mode}
                    disabled={disableStudy}
                    decimalScale={0}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormNumeric2
                    name="estimatedAnnualOperatingDays"
                    label='"Estimated" Annual Operating Days (Days/Per year)'
                    value={chillerValues?.estimatedAnnualOperatingDays ?? 0}
                    onChange={handleChillerInputChange}
                    error={chillerErros.estimatedAnnualOperatingDays}
                    showSkeleton={isLoading}
                    mode={mode}
                    disabled={disableStudy}
                    decimalScale={0}
                  />
                </Grid>
                <Grid item xs={8}>
                  <FormSelect
                    name="numberOfEvaporatorPumpsConnectedToThisChiller"
                    label="Number of Evaporator pumps in this System"
                    size="small"
                    value={
                      chillerValues.numberOfEvaporatorPumpsConnectedToThisChiller
                    }
                    onChange={handleChillerInputChange}
                    options={numberOfEvaporatorPumpsConnected}
                    error={
                      chillerErros.numberOfEvaporatorPumpsConnectedToThisChiller
                    }
                    fullWidth={true}
                    mode={mode}
                    disabled={
                      disableStudy ||
                      !chillerValues?.numberOfEvaporatorPumpsConnectedToThisChillerToggle
                    }
                    showSkeleton={isLoading}
                  />
                </Grid>
                <Grid item xs={4}>
                  <FormCheckSwitch
                    name="numberOfEvaporatorPumpsConnectedToThisChillerToggle"
                    label=""
                    onChange={handleChillerInputChange}
                    value={
                      chillerValues?.numberOfEvaporatorPumpsConnectedToThisChillerToggle
                    }
                    showSkeleton={isLoading}
                    mode={mode}
                    disabled={disableStudy}
                  />
                </Grid>

                <Grid item xs={8}>
                  <FormSelect
                    name="numberOfEvaporatorPumpsToBeTested"
                    label="Number of Evaporator pumps to be tested"
                    size="small"
                    value={chillerValues.numberOfEvaporatorPumpsToBeTested ?? 0}
                    onChange={handleChillerInputChange}
                    options={numberOfEvaporatorPumps.filter(
                      (item) =>
                        item.key <=
                        chillerValues.numberOfEvaporatorPumpsConnectedToThisChiller
                    )}
                    defaultValue={{ key: 0, value: 0 }}
                    error={chillerErros.numberOfEvaporatorPumpsToBeTested}
                    fullWidth={true}
                    mode={mode}
                    disabled={
                      disableStudy ||
                      !chillerValues?.numberOfEvaporatorPumpsToBeTestedToggle
                    }
                    showSkeleton={isLoading}
                  />
                </Grid>
                <Grid item xs={4}>
                  <FormCheckSwitch
                    name="numberOfEvaporatorPumpsToBeTestedToggle"
                    label=""
                    onChange={handleChillerInputChange}
                    value={
                      chillerValues?.numberOfEvaporatorPumpsToBeTestedToggle
                    }
                    showSkeleton={isLoading}
                    mode={mode}
                    disabled={disableStudy}
                  />
                </Grid>
                <Grid item xs={8}>
                  <FormSelect
                    name="numberOfCondenserPumpsConnectedToThisChiller"
                    label="Number of Tower/Condenser pumps in this System"
                    size="small"
                    value={
                      chillerValues.numberOfCondenserPumpsConnectedToThisChiller
                    }
                    onChange={handleChillerInputChange}
                    options={numberOfCondenserPumpsConnected}
                    error={
                      chillerErros.numberOfCondenserPumpsConnectedToThisChiller
                    }
                    fullWidth={true}
                    mode={mode}
                    disabled={
                      disableStudy ||
                      !chillerValues?.numberOfCondenserPumpsConnectedToThisChillerToggle
                    }
                    showSkeleton={isLoading}
                  />
                </Grid>
                <Grid item xs={4}>
                  <FormCheckSwitch
                    name="numberOfCondenserPumpsConnectedToThisChillerToggle"
                    label=""
                    onChange={handleChillerInputChange}
                    value={
                      chillerValues?.numberOfCondenserPumpsConnectedToThisChillerToggle
                    }
                    showSkeleton={isLoading}
                    mode={mode}
                    disabled={disableStudy}
                  />
                </Grid>
                <Grid item xs={8}>
                  <FormSelect
                    name="numberOfCondenserPumpsToBeTested"
                    label="Number of Tower/Condenser pumps to be tested"
                    size="small"
                    value={chillerValues.numberOfCondenserPumpsToBeTested ?? 0}
                    onChange={handleChillerInputChange}
                    options={numberOfEvaporatorPumps.filter(
                      (item) =>
                        item.key <=
                        chillerValues.numberOfCondenserPumpsConnectedToThisChiller
                    )}
                    defaultValue={{ key: 0, value: 0 }}
                    error={chillerErros.numberOfCondenserPumpsToBeTested}
                    fullWidth={true}
                    mode={mode}
                    disabled={
                      disableStudy ||
                      !chillerValues?.numberOfCondenserPumpsToBeTestedToogle
                    }
                    showSkeleton={isLoading}
                  />
                </Grid>
                <Grid item xs={4}>
                  <FormCheckSwitch
                    name="numberOfCondenserPumpsToBeTestedToogle"
                    label=""
                    onChange={handleChillerInputChange}
                    value={
                      chillerValues?.numberOfCondenserPumpsToBeTestedToogle
                    }
                    showSkeleton={isLoading}
                    mode={mode}
                    disabled={disableStudy}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Grid container spacing={6}>
                    <Grid item xs={12}>
                      <Typography variant="h6">Evaporator Section</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <FormNumeric2
                        name="designRatedGPMTotal"
                        label={`Design/Rated ${systemOfMeasurement.get(
                          "cfm"
                        )} Total`}
                        value={chillerValues?.designRatedGPMTotal ?? 0}
                        onChange={handleChillerInputChange}
                        showSkeleton={isLoading}
                        mode={mode}
                        disabled={disableStudy}
                        decimalScale={3}
                        thousandSeparator={true}
                        maxValue={1000000000}
                        fixedDecimalScale={true}
                        onBlur={savePartialHandler}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormNumeric2
                        name="designRatedWarmEWT"
                        label="Design/Rated (Warm) EWT"
                        value={chillerValues?.designRatedWarmEWT ?? 0}
                        onChange={handleChillerInputChange}
                        showSkeleton={isLoading}
                        mode={mode}
                        disabled={disableStudy}
                        decimalScale={3}
                        thousandSeparator={true}
                        maxValue={1000000000}
                        fixedDecimalScale={true}
                        onBlur={savePartialHandler}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormNumeric2
                        name="designRatedColdLWT"
                        label="Design/Rated (Cold) LWT"
                        value={chillerValues?.designRatedColdLWT ?? 0}
                        onChange={handleChillerInputChange}
                        showSkeleton={isLoading}
                        mode={mode}
                        disabled={disableStudy}
                        decimalScale={3}
                        thousandSeparator={true}
                        maxValue={1000000000}
                        fixedDecimalScale={true}
                        onBlur={savePartialHandler}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormNumeric2
                        name="evaporatorDesignATF"
                        label={`Evaporator Design ΔT (${systemOfMeasurement.get(
                          "temperature"
                        )})`}
                        value={chillerValues?.evaporatorDesignATF ?? 0}
                        onChange={handleChillerInputChange}
                        showSkeleton={isLoading}
                        mode={"read"}
                        decimalScale={3}
                        thousandSeparator={true}
                        maxValue={1000000000}
                        fixedDecimalScale={true}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormNumeric2
                        name="evaporatorDesignBTUH"
                        label={`Evaporator Design ${systemOfMeasurement.get(
                          "btuh"
                        )}`}
                        value={chillerValues?.evaporatorDesignBTUH ?? 0}
                        onChange={handleChillerInputChange}
                        showSkeleton={isLoading}
                        mode={"read"}
                        decimalScale={3}
                        thousandSeparator={true}
                        maxValue={1000000000}
                        fixedDecimalScale={true}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  <Grid container spacing={6}>
                    <Grid item xs={12}>
                      <Typography variant="h6">Condenser Section</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <FormNumeric2
                        name="designRatedGPMTotalCondenser"
                        label={`Design/Rated ${systemOfMeasurement.get(
                          "gpm"
                        )} Total`}
                        value={chillerValues?.designRatedGPMTotalCondenser ?? 0}
                        onChange={handleChillerInputChange}
                        showSkeleton={isLoading}
                        mode={mode}
                        disabled={disableStudy}
                        decimalScale={3}
                        thousandSeparator={true}
                        maxValue={1000000000}
                        fixedDecimalScale={true}
                        onBlur={savePartialHandler}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormNumeric2
                        name="designRatedWarmEWTCondenser"
                        label="Design/Rated (Warm) EWT"
                        value={chillerValues?.designRatedWarmEWTCondenser ?? 0}
                        onChange={handleChillerInputChange}
                        showSkeleton={isLoading}
                        mode={mode}
                        disabled={disableStudy}
                        decimalScale={3}
                        thousandSeparator={true}
                        maxValue={1000000000}
                        fixedDecimalScale={true}
                        onBlur={savePartialHandler}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormNumeric2
                        name="designRatedColdLWTCondenser"
                        label="Design/Rated (Cold) LWT"
                        value={chillerValues?.designRatedColdLWTCondenser ?? 0}
                        onChange={handleChillerInputChange}
                        showSkeleton={isLoading}
                        mode={mode}
                        disabled={disableStudy}
                        decimalScale={3}
                        thousandSeparator={true}
                        maxValue={1000000000}
                        fixedDecimalScale={true}
                        onBlur={savePartialHandler}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormNumeric2
                        name="evaporatorDesignATFCondenser"
                        label={`Condenser Design ΔT (${systemOfMeasurement.get(
                          "temperature"
                        )})`}
                        value={chillerValues?.evaporatorDesignATFCondenser ?? 0}
                        onChange={handleChillerInputChange}
                        showSkeleton={isLoading}
                        mode={"read"}
                        decimalScale={3}
                        thousandSeparator={true}
                        maxValue={1000000000}
                        fixedDecimalScale={true}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormNumeric2
                        name="condenserDesignBTUH"
                        label={`Condenser Design ${systemOfMeasurement.get(
                          "btuh"
                        )}`}
                        value={chillerValues?.condenserDesignBTUH ?? 0}
                        onChange={handleChillerInputChange}
                        showSkeleton={isLoading}
                        mode={"read"}
                        decimalScale={3}
                        thousandSeparator={true}
                        maxValue={1000000000}
                        fixedDecimalScale={true}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                {showWarning() && (
                  <Grid item xs={12}>
                    <Alert severity="error">
                      <AlertTitle>Warning</AlertTitle>
                      Your values entered are not within industry standard
                      range. Your ratio of Heat of Absorption to the calculated
                      Heat of Rejection is {btuhRatio} %. Ideal is 125.00 %.{" "}
                      <br />
                      Review the design data you've entered for the Evaporator
                      and Condenser regarding flows and temperatures.
                    </Alert>
                  </Grid>
                )}
                <Grid item xs={12}>
                  <FooterButtons
                    activeStep={activeStep}
                    stepsCount={steps.length}
                    projectId={dataReport?.projectId}
                    companyId={user?.companyId as number}
                    isSaving={false}
                    disabled={false}
                    reportName={reportType?.name ?? ""}
                    saveHandler={saveHandler}
                    nextStepHandler={() => nextHandle()}
                    previousStepHandler={() => backHandler()}
                    hideSaveButton={disableStudy}
                    isComplete={chillerValues.isComplete}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <DialogMessagePopup
        title={"Error"}
        text={
          <div>
            "Operation Start Date must not be greater or later then its End
            Date. Please re-enter an earlier Start Date or later End Date.
          </div>
        }
        isSubmitting={false}
        showPopup={showDateErrorPopup}
        setShowPopup={setShowErrorPopup}
        onSave={() => {
          setShowErrorPopup(false);
        }}
        hideAccept={true}
        cancelTextButton="Close"
      />
      <DialogMessagePopup
        title={"Warning"}
        text={
          <div>
            You are about to decrease the number of Pumps in this Report and you
            will lose Pumps Studies permanently. Are you certain you want to
            continue?
          </div>
        }
        isSubmitting={false}
        showPopup={showDecreaseTowerModal}
        setShowPopup={setShowDecreaseTowerModal}
        onSave={() => {
          continueAction();
        }}
      />
      <DialogMessagePopup
        title={"Warning"}
        text={
          <p>
            Prior to performance testing this cell: Confirm the system is fully
            loaded/challenged and has been operating for a minimum of 30 minutes
            and is at steady state. Testing idealy to be performed when the
            prevailing wind speed is{" "}
            {systemOfMeasurement.measurementSystem === "metricSystem"
              ? "24.0 KMH"
              : "15.0 MPH"}{" "}
            or less when testing Cooling Towers.
          </p>
        }
        isSubmitting={false}
        showPopup={informationModal}
        setShowPopup={setInformationModal}
        onSave={() => {
          continueAction();
        }}
      />
    </>
  );
};

export default ChillerStep2;
