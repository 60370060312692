import React, { useEffect, useState } from "react";
import Popup from "src/components/Popup";
import {
  FormAcceptButton,
  FormCancelButton,
  FormCheckBox,
  FormSelect,
  FormText,
} from "src/components/formControls";
import { Grid, Link, Stack, Typography } from "@mui/material";
import { useAsyncMutation, useAuth, useForm, useLog } from "src/hooks";
import { CatalogService } from "src/services";
import { IKeyValue } from "src/ts/interfaces";
import accountService from "src/services/accountService";
import { useNavigate } from "react-router-dom";
import tokenService from "src/services/tokenService";

interface Props {
  showPopup: boolean;
  setShowPopup: (val: boolean) => void;
  email: string;
}

export default function UpgradeAccountModal({
  showPopup,
  setShowPopup,
  email,
}: Props) {
  const initialValues = {
    company: "",
    companyTypeId: null,
    user: {
      password: "",
      userName: email,
      confirmPassword: "",
    },
    subscriptionId: 2,
  };

  const { signIn } = useAuth();
  const navigate = useNavigate();
  const [companyType, setCompanyType] = useState<IKeyValue<number, string>[]>(
    []
  );

  const validate = (fieldValues = values) => {
    let temp: Record<string, string> = { ...errors };

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };
  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues, false, validate);

  const { execute, isSubmitting } = useAsyncMutation(accountService.register, {
    hideSuccessfulMessage: true,
    hideErrorMessage: true,
    onSuccess: async () => {
      await signIn(values.user.userName, values.user.password);
      navigate("/contactInformation");
    },
  });

  const { execute: executeUpgrade, isSubmitting: isSubmittingUpgrade } =
    useAsyncMutation(tokenService.upgrade, {
      hideSuccessfulMessage: true,
      hideErrorMessage: true,
      onSuccess: async () => {
        await execute(values);
      },
      onError: (result) => {
        let temp: Record<string, string> = { ...errors };
        temp.password = "Password is incorrect";
        setErrors({
          ...temp,
        });
      },
    });

  useEffect(() => {
    const getSubscriptions = async () => {
      const companyTypeResult = await CatalogService.getAllDropdown(
        "companyType"
      );
      setCompanyType(companyTypeResult);
    };
    getSubscriptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSave = async () => {
    if (!validate()) return;
    await executeUpgrade(values.user.password, email);
  };

  const handleClosePopUp = () => {
    resetForm();
    setShowPopup(false);
  };

  const handleInputChangeUser = (e: any) => {
    let temp: Record<string, string> = { ...errors };
    temp.password = "";
    setErrors({
      ...temp,
    });

    const { name, value } = e.target;
    let newValues = { ...values };
    newValues.user[name] = value;
    if (name === "password") newValues.user.confirmPassword = value;

    setValues({
      ...newValues,
    });
  };

  return (
    <>
      <Popup
        title="Information"
        openPopup={showPopup}
        setOpenPopup={setShowPopup}
        onClose={handleClosePopUp}
        size="sm"
        isSubmitting={isSubmitting || isSubmittingUpgrade}
        disableClickOutside={true}
      >
        <>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <FormText
                type="text"
                name="company"
                label="Company"
                value={values.company}
                error={errors.company}
                fullWidth
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12}>
              <FormSelect
                name={"companyTypeId"}
                label={"Company Type"}
                value={values.companyTypeId}
                onChange={handleInputChange}
                options={companyType}
                error={errors.companyTypeId}
                defaultValue={{ key: null, value: "Select Company Type" }}
              ></FormSelect>
            </Grid>
            <Grid item xs={12}>
              <Typography>Enter your password to confirm</Typography>
            </Grid>
            <Grid item xs={12}>
              <FormText
                type="password"
                name="password"
                label="Password"
                autoComplete="new-password"
                placeholder="Password - Your password must have at least one non letter or digit character"
                value={values?.user?.password}
                error={errors.password}
                fullWidth
                onChange={handleInputChangeUser}
              />
            </Grid>
            <Grid item xs={12}>
              <FormCheckBox
                name="isTermsAndConditionsAccepted"
                label={
                  <Typography>
                    <Typography display={"inline"}>
                      I understand and agree to{" "}
                    </Typography>
                    <Link
                      href="https://auditmaster.pro/legal?id=privacy_statement"
                      target="_blank"
                    >
                      AMP's Privacy Policy and Terms of Service
                    </Link>
                  </Typography>
                }
                value={values.isTermsAndConditionsAccepted}
                onChange={handleInputChange}
              />
            </Grid>
          </Grid>
        </>
        <Stack direction="row" spacing={3}>
          <FormCancelButton
            onClick={handleClosePopUp}
            isSubmitting={isSubmitting || isSubmittingUpgrade}
          />
          <FormAcceptButton
            onClick={handleSave}
            isSubmitting={isSubmitting || isSubmittingUpgrade}
            disabled={
              !values.isTermsAndConditionsAccepted ||
              !values?.user?.password ||
              !values.companyTypeId ||
              !values.company
            }
            text="Accept"
          ></FormAcceptButton>
        </Stack>
      </Popup>
    </>
  );
}
