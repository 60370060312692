import { useContext } from "react";
import { TowerStudyContext } from "src/pages/studies/tower/context/TowerStudyContext";

const useTowerStudy = () => {
  const context = useContext(TowerStudyContext);

  if (!context)
    throw new Error(
      "TowerStudyContext must be placed within TowerStudyProvider"
    );

  return context;
};

export default useTowerStudy;
