import axios from "src/utils/axios";
import { IGpmDTO } from "src/ts/interfaces";

const getGpm = (id: number, reportTypeCode: string) => {
  return axios.get<IGpmDTO>(`gpm?pumpId=${id}&code=${reportTypeCode}`);
};

const gpmService = {
  getGpm,
};

export default gpmService;
