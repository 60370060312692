import axios from "src/utils/axios";
import { IFanSheave } from "src/ts/interfaces/catalogs";

const getFanSheaveManufacturers = () => {
  return axios.get<IFanSheave[]>("FanSheave");
};

const fanSheaveService = {
  getFanSheaveManufacturers,
};

export default fanSheaveService;
