import { useEffect, useState } from "react";
import useLog from "src/hooks/useLog";
import {
  Grid,
  Typography,
  Stack,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Chip as MuiChip,
  styled,
} from "@mui/material";
import { ICompany, ICoupon } from "src/ts/interfaces";
import { currencyformatter } from "src/utils/format";
import { FormButton, FormRadioGroup } from "src/components/formControls";
import { CouponService } from "src/services";

import {
  IPaymentSummary,
  IPurchaseSummary,
} from "src/ts/interfaces/technician";
import FormTextHideText from "src/components/formControls/FormTextHideText";
import { SpacingProps, spacing } from "@mui/system";
import { ROLES } from "src/constants";

interface ChipProps extends SpacingProps {
  component?: React.ElementType;
  href?: string;
  icon?: JSX.Element | null;
}

interface PayPopupProps {
  purchaseSummary: IPurchaseSummary;
  completeName: string;
  handleInputChange: (e: any) => void;
  values: IPaymentSummary;
  setValues: (val: IPaymentSummary) => void;
  setDiscountCod: (val: number) => void;
  resetForm: () => void;
  companyInfo: ICompany | undefined;
  requestedRole?: string;
  isSubmitting: boolean;
}

const Chip = styled(MuiChip)<ChipProps>(spacing);

const radioInformation = [
  {
    key: 0,
    value: "Pay Now",
  },
  {
    key: 1,
    value: "Sent to Cart",
  },
];

const PaymentSummary = (props: PayPopupProps) => {
  const {
    purchaseSummary,
    completeName,
    handleInputChange,
    values,
    setValues,
    setDiscountCod,
    resetForm,
    companyInfo,
    requestedRole,
    isSubmitting,
  } = props;
  const { log } = useLog();

  const [coupon, setCoupon] = useState<ICoupon | null>(null);
  const [isApplyingCoupon, setIsApplyingCoupon] = useState(false);
  const remnant =
    purchaseSummary.walletBalance - purchaseSummary.selectionTotal;

  useEffect(() => {
    if (companyInfo?.walletAutoWithdraw || remnant > 0) {
      setValues({ ...values, paymentOption: 0 });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyInfo]);

  const applyCouponHandler = async () => {
    try {
      setIsApplyingCoupon(true);
      const response = await CouponService.getByCode(values.code);
      if (requestedRole) {
        if (
          response.data.typeId === 3 &&
          requestedRole !== ROLES.EnergyAuditor
        ) {
          log.error(
            "The coupon is valid only for Energy Auditor certification type"
          );
          return;
        }
        if (response.data.typeId === 2 && requestedRole !== ROLES.Auditor) {
          log.error(
            "The coupon is valid only for Test & Balance certification type"
          );
          return;
        } else if (response.data.typeId !== 2 && response.data.typeId !== 3) {
          log.error("The coupon does not apply to technician's request");
          return;
        }
      }
      setCoupon(response.data);
      const discountApply =
        (purchaseSummary?.pricing ?? 0) -
        ((purchaseSummary?.pricing ?? 0) / 100) * (response?.data.value ?? 0);

      setDiscountCod(discountApply);

      const total =
        (purchaseSummary?.selectionTotal ?? 0) -
        ((purchaseSummary?.selectionTotal ?? 0) * (response?.data.value ?? 0)) /
          100;
      if (
        companyInfo?.walletAutoWithdraw ||
        purchaseSummary?.walletBalance > total
      ) {
        setValues({ ...values, paymentOption: 0 });
      } else {
        setValues({ ...values, paymentOption: 1 });
      }
      setIsApplyingCoupon(false);
    } catch (error) {
      setIsApplyingCoupon(false);
      log.error("The coupon code is invalid or was expired");
      setCoupon(null);
      resetForm();
    }
  };

  const totalWithCode =
    (purchaseSummary?.selectionTotal ?? 0) -
    ((purchaseSummary?.selectionTotal ?? 0) * (coupon?.value ?? 0)) / 100;

  const funds = (() => {
    return (
      (remnant < 0 &&
        !companyInfo?.walletAutoWithdraw &&
        !companyInfo?.walletBlocked &&
        !companyInfo?.walletAdminBlocked &&
        purchaseSummary?.walletBalance < totalWithCode) ||
      companyInfo?.walletAdminBlocked
    );
  })();

  const showInsufficientFundsMessage =
    purchaseSummary !== undefined &&
    !companyInfo?.walletBlocked &&
    !companyInfo?.walletAdminBlocked &&
    purchaseSummary?.balanceRemaining < 0 &&
    !companyInfo?.walletAutoWithdraw &&
    purchaseSummary?.walletBalance < totalWithCode;

  const showAutomaticallyWithdrawFound =
    purchaseSummary !== undefined &&
    !companyInfo?.walletBlocked &&
    !companyInfo?.walletAdminBlocked &&
    purchaseSummary?.balanceRemaining < 0 &&
    companyInfo?.walletAutoWithdraw;

  const handleSelectChange = (e: any) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: Number(value),
    });
  };

  return (
    <>
      <Grid item xs={12}>
        <Typography sx={{ fontWeight: "bold" }} textAlign="center">
          {` In order to add the request for techician ${completeName} the following carge
              will be made to your wallet`}
        </Typography>

        <Grid item xs={12}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>#</TableCell>
                  <TableCell>Description</TableCell>
                  <TableCell>Price</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                <TableRow>
                  <TableCell colSpan={1}>1</TableCell>
                  <TableCell>{`${purchaseSummary.nameCertication}`}</TableCell>
                  <TableCell style={{ textAlign: "right" }}>
                    {currencyformatter.format(
                      purchaseSummary?.selectionTotal ?? 0
                    )}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={1}></TableCell>
                  <TableCell style={{ textAlign: "right" }}>
                    <b>Subtotal:</b>
                  </TableCell>
                  <TableCell style={{ textAlign: "right" }}>
                    {currencyformatter.format(
                      purchaseSummary?.selectionTotal ?? 0
                    )}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={1}></TableCell>
                  <TableCell style={{ textAlign: "right" }}>
                    <b>Taxes:</b>
                  </TableCell>
                  <TableCell style={{ textAlign: "right" }}>$0.00</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={1}></TableCell>
                  <TableCell style={{ textAlign: "right" }}>
                    <b>Discount:</b>
                  </TableCell>
                  <TableCell style={{ textAlign: "right" }}>
                    <Typography color="red"></Typography>
                    {currencyformatter.format(
                      ((purchaseSummary?.selectionTotal ?? 0) *
                        (coupon?.value ?? 0)) /
                        100
                    )}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={1}></TableCell>
                  <TableCell style={{ textAlign: "right" }}>
                    <b>Total:</b>
                  </TableCell>
                  <TableCell style={{ textAlign: "right" }}>
                    {currencyformatter.format(
                      (purchaseSummary?.selectionTotal ?? 0) -
                        ((purchaseSummary?.selectionTotal ?? 0) *
                          (coupon?.value ?? 0)) /
                          100
                    )}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item xs={12}>
          {showInsufficientFundsMessage && (
            <Typography sx={{ color: "red" }} mb={2} mt={2} textAlign="center">
              You have insufficient funds to complete this purchase. The request
              will be sent to the cart.
            </Typography>
          )}
          {companyInfo?.walletAdminBlocked && (
            <Typography sx={{ color: "red" }} mb={2} mt={2} textAlign="center">
              Your AMP Wallet has an Administrative Blockage.
            </Typography>
          )}
          {companyInfo?.walletBlocked && (
            <Typography sx={{ color: "red" }} mb={2} mt={2} textAlign="center">
              Your AMP Wallet is blocked.
            </Typography>
          )}
          {showAutomaticallyWithdrawFound && (
            <>
              <Typography
                variant="h6"
                sx={{ color: "blue" }}
                mb={2}
                mt={2}
                textAlign="center"
              >
                The system will automatically withdraw funds from your Wallet's
                default card to complete this purchase.
              </Typography>
            </>
          )}
        </Grid>
        <Grid item xs={12}>
          <Stack direction="row" justifyContent="center">
            <Typography mt={3} mr={2}>
              Select and option
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Stack direction="row" justifyContent="center">
            <FormRadioGroup
              name="paymentOption"
              label=""
              value={values.paymentOption}
              items={radioInformation}
              onChange={handleSelectChange}
              disabled={funds}
              row={true}
            />
          </Stack>
        </Grid>
        <Grid item xs={12}></Grid>
        <Grid item xs={12}>
          <Stack direction="row" justifyContent="center">
            <Typography mb={1}>Promotional code:</Typography>
          </Stack>
        </Grid>
        <Grid item direction="row" xs={12}>
          <Stack direction="row" justifyContent="center" spacing={3}>
            {coupon !== null && (
              <Chip
                label={`${coupon?.value}% OFF`}
                color="success"
                m={1}
                size="medium"
              />
            )}
            <FormTextHideText
              name="code"
              label={"Enter your code"}
              value={values.code}
              onChange={handleInputChange}
              autocomplete={false}
            />

            <FormButton
              text="Apply Coupon"
              size="small"
              onClick={applyCouponHandler}
              isSubmitting={isSubmitting || isApplyingCoupon}
            />
          </Stack>
        </Grid>
      </Grid>
    </>
  );
};

export default PaymentSummary;
