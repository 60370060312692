import axios from "src/utils/axios";
import { IPumpSvcDTO } from "src/ts/interfaces/catalogs";

const getPumpSvc = () => {
  return axios.get<IPumpSvcDTO[]>("PumpSvc");
};

const pumpSvcService = {
  getPumpSvc,
};

export default pumpSvcService;
