import { IMandatoryMinimumStudy } from "src/ts/interfaces/mandatoryMinimumStudy";
import { IMandatoryMinimumList } from "../ts/interfaces/catalogs/mandatoryMinimum";
import axios from "../utils/axios";

const getByStudy = async (projectId: number, reportId: number) => {
  return axios.get<IMandatoryMinimumList>(
    `MandatoryMinimumStudy?projectId=${projectId}&projectReportId=${reportId}`
  );
};

const update = async (study: IMandatoryMinimumStudy) => {
  return axios.put(`MandatoryMinimumStudy`, study);
};

const updateTemplate = async (templateId: number, projectId: number) => {
  return axios.put(
    `MandatoryMinimumStudy/ApplyTemplate/${templateId}/${projectId}`,
    null
  );
};

const mandatoryMinimumStudyService = {
  getByStudy,
  update,
  updateTemplate,
};

export default mandatoryMinimumStudyService;
