import React, { useEffect, useState } from "react";
import {
  Grid,
  List,
  ListItem,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import FormTitleBox from "src/components/formControls/FormTitleBox";
import {
  FormButton,
  FormCancelButton,
  FormCheckBox,
  FormSaveButton,
} from "src/components/formControls";
import { useAsyncQuery, useAuth } from "src/hooks";
import { ICompany, IUser } from "src/ts/interfaces";
import { CompanyService, userService } from "src/services";
interface Props {
  setModalCancel: React.Dispatch<React.SetStateAction<boolean>>;
  handleNext: (cardConfirm: boolean) => Promise<void>;
  isSubmitting: boolean;
}
function AgreementAcceptance({
  setModalCancel,
  handleNext,
  isSubmitting,
}: Props) {
  const [showMore, setShowMore] = useState(false);
  const [acceptance1, setAcceptance1] = useState(false);
  const [acceptance2, setAcceptance2] = useState(false);
  const { user } = useAuth();
  const { execute, data } = useAsyncQuery<ICompany>(
    CompanyService.getByIdWithSchool,
    {
      hideSuccessfulMessage: true,
    }
  );

  const { execute: executeUser, data: dataUser } = useAsyncQuery<IUser>(
    userService.getCurrent,
    {
      hideSuccessfulMessage: true,
    }
  );

  useEffect(() => {
    const getData = async () => {
      await execute(user?.companyId);
      await executeUser();
    };
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const cancelHandler = () => {
    setModalCancel(true);
  };

  const saveHandler = async () => {
    await handleNext(false);
  };
  return (
    <>
      <FormTitleBox
        title={"Software and Process Subscription and Use Agreement"}
      >
        <Grid container pt={2}>
          <Grid item xs={12} textAlign={"center"}>
            <Typography variant="h3" pb={3}>
              Software and Process Subscription and Use Agreement
            </Typography>

            <FormButton
              text={showMore ? "View less" : "View more..."}
              onClick={() => setShowMore(!showMore)}
              variant="text"
            />
          </Grid>
          {showMore && (
            <Grid item xs={12}>
              <Typography textAlign={"left"} pb={4} pt={2}>
                <Typography variant="subtitle2" display={"inline"}>
                  This Agreement{" "}
                </Typography>
                <Typography display={"inline"}>
                  is made and entered into by and between {data?.schools?.name}{" "}
                  and {dataUser?.name} {dataUser?.lastName}, collectively
                  referred to as the "Parties".
                </Typography>
              </Typography>
              <Typography textAlign={"left"} pb={4}>
                <Typography variant="subtitle2" display={"inline"}>
                  Whereas{" "}
                </Typography>
                <Typography display={"inline"}>
                  the Institution agrees to provide Recipient with a
                  subscription access to certain proprietary software and
                  patented processes ("Resources") strictly for educational and
                  training purposes as part of building energy auditing, and
                  energy optimization; and
                </Typography>
              </Typography>
              <Typography textAlign={"left"} pb={4}>
                <Typography variant="subtitle2" display={"inline"}>
                  Whereas{" "}
                </Typography>
                <Typography display={"inline"}>
                  the Recipient agrees to use the Resources solely for classroom
                  exercises associated with this course and acknowledges that
                  any other use is strictly prohibited.
                </Typography>
              </Typography>
              <Typography textAlign={"left"} pb={4}>
                <Typography variant="subtitle2" display={"inline"}>
                  Now, Therefore,{" "}
                </Typography>
                <Typography display={"inline"}>
                  the Parties agree as follows:
                </Typography>
              </Typography>
              <List sx={{ listStyle: "decimal", pl: 4 }}>
                <ListItem sx={{ display: "list-item" }}>
                  <ListItemText primary="Grant of Subscription and License:" />
                  <List sx={{ listStyle: "disc", pl: 4 }}>
                    <ListItem sx={{ display: "list-item" }}>
                      <ListItemText primary="The Institution hereby grants the Recipient a non-exclusive, non-transferable subscription and license to use the Resources solely for educational purposes within the classroom setting of the specified training program." />
                    </ListItem>
                  </List>
                </ListItem>
                <ListItem sx={{ display: "list-item" }}>
                  <ListItemText primary="Restrictions:" />
                  <List sx={{ listStyle: "disc", pl: 4 }}>
                    <ListItem sx={{ display: "list-item" }}>
                      <ListItemText primary="The Recipient shall not use or permit the use of the Resources for any commercial, professional, or outside academic purposes. This includes, but is not limited to, using the Resources for commercial gain, profit, consulting, or outside research." />
                    </ListItem>
                    <ListItem sx={{ display: "list-item" }}>
                      <ListItemText primary="The Recipient is prohibited from copying, modifying, reverse engineering, or creating derivative works based on the Resources." />
                    </ListItem>
                  </List>
                </ListItem>
                <ListItem sx={{ display: "list-item" }}>
                  <ListItemText primary="Confidentiality:" />
                  <List sx={{ listStyle: "disc", pl: 4 }}>
                    <ListItem sx={{ display: "list-item" }}>
                      <ListItemText primary="The Recipient agrees to maintain the confidentiality of the Resources and not disclose any related materials, knowledge, or data to third parties." />
                    </ListItem>
                  </List>
                </ListItem>
                <ListItem sx={{ display: "list-item" }}>
                  <ListItemText primary="Financial Liability:" />
                  <List sx={{ listStyle: "disc", pl: 4 }}>
                    <ListItem sx={{ display: "list-item" }}>
                      <ListItemText primary="If the Recipient breaches this Agreement by using the Resources outside of the designated classroom exercises, especially for profit, the Recipient will be held financially liable for any resulting damages or unjust enrichment. This financial liability will include, but not be limited to, any profits made from such unauthorized use and potential damages to the Institution’s intellectual property rights and business interests." />
                    </ListItem>
                    <ListItem sx={{ display: "list-item" }}>
                      <ListItemText primary="The Institution reserves the right to seek further legal action for breach of this Agreement including injunctions and recovery of attorney’s fees." />
                    </ListItem>
                  </List>
                </ListItem>
                <ListItem sx={{ display: "list-item" }}>
                  <ListItemText primary="Subscription Fees:" />
                  <List sx={{ listStyle: "disc", pl: 4 }}>
                    <ListItem sx={{ display: "list-item" }}>
                      <ListItemText primary="The Recipient agrees to pay a subscription fee, as detailed in [Fee Schedule Appendix or Section], which will be non-refundable and required for maintaining access to the Resources for the duration of the course." />
                    </ListItem>
                  </List>
                </ListItem>
                <ListItem sx={{ display: "list-item" }}>
                  <ListItemText primary="Termination:" />
                  <List sx={{ listStyle: "disc", pl: 4 }}>
                    <ListItem sx={{ display: "list-item" }}>
                      <ListItemText primary="This Agreement will terminate upon the conclusion of the Recipient’s enrollment in the specified course unless earlier terminated by the Institution for breach of terms." />
                      <ListItemText primary="Upon termination, the Recipient must cease all use of the Resources and return or destroy all materials received." />
                    </ListItem>
                  </List>
                </ListItem>
                <ListItem sx={{ display: "list-item" }}>
                  <ListItemText primary="General Provisions:" />
                  <List sx={{ listStyle: "disc", pl: 4 }}>
                    <ListItem sx={{ display: "list-item" }}>
                      <ListItemText primary="This Agreement constitutes the entire agreement between the Parties regarding the subject matter hereof." />
                    </ListItem>
                    <ListItem sx={{ display: "list-item" }}>
                      <ListItemText primary="Any amendments to this Agreement must be in writing and signed by both Parties." />
                    </ListItem>
                    <ListItem sx={{ display: "list-item" }}>
                      <ListItemText
                        primary={`This Agreement is bound by the laws of ${
                          data?.schools?.country?.name
                        }, ${
                          data?.schools?.state !== null
                            ? data?.schools?.state?.name
                            : data?.schools?.stateName
                        }, without regard to its conflict of law provisions.`}
                      />
                    </ListItem>
                  </List>
                </ListItem>
              </List>
              <Typography textAlign={"left"} pb={4}>
                <Typography variant="subtitle2" display={"inline"}>
                  In Witness Whereof,{" "}
                </Typography>
                <Typography display={"inline"}>
                  the Parties have executed this Agreement as of the{" "}
                  {new Date().getDate()} of{" "}
                  {new Date().toLocaleString("en-GB", { month: "long" })},{" "}
                  {new Date().getFullYear()}.
                </Typography>
              </Typography>
            </Grid>
          )}
          <Grid item xs={12} pl={2} m={0} p={0}>
            <FormCheckBox
              label="I have comprehensively read the terms provided above and fully understand them."
              name="acceptance1"
              value={acceptance1}
              onChange={() => {
                setAcceptance1(!acceptance1);
              }}
              disabled={isSubmitting}
            />
          </Grid>
          <Grid item xs={12} pl={2} m={0} p={0}>
            {" "}
            <FormCheckBox
              label="I agree to the terms provided above. "
              name="acceptance2"
              value={acceptance2}
              onChange={() => {
                setAcceptance2(!acceptance2);
              }}
              disabled={isSubmitting}
            />
          </Grid>
          <Grid item xs={12}>
            <Stack
              direction="row"
              spacing={2}
              justifyContent="center"
              alignItems="center"
            >
              <FormCancelButton
                onClick={cancelHandler}
                isSubmitting={isSubmitting}
              />
              <FormSaveButton
                text="Save & Continue"
                onClick={saveHandler}
                isSubmitting={isSubmitting}
                disabled={!acceptance1 || !acceptance2}
              />
            </Stack>
          </Grid>
        </Grid>
      </FormTitleBox>
    </>
  );
}

export default AgreementAcceptance;
