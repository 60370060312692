import { IStudyStep } from "src/ts/interfaces";

export const steps: IStudyStep[] = [
  {
    label: "Step 1",
    key: 0,
    description: "System Information",
    url: "/studies/Chiller/step1/:id",
  },
  {
    label: "Step 2",
    key: 1,
    description: "General Information",
    url: "/studies/Chiller/step2/:id",
  },
  {
    label: "Step 3",
    key: 2,
    description: "Nameplates",
    url: "/studies/Chiller/step3/:id",
  },
  {
    label: "Step 4",
    key: 3,
    description: "Actuals",
    url: "/studies/Chiller/step4/:id",
  },
  {
    label: "Step 5",
    key: 4,
    description: "Sound Pressure Test",
    url: "/studies/Chiller/step5/:id",
  },
  {
    label: "Step 6",
    key: 5,
    description: "Energy Source Test",
    url: "/studies/Chiller/step6/:id",
  },
  {
    label: "Step 7",
    key: 6,
    description: "Energy Combined/Averaged",
    url: "/studies/Chiller/step7/:id",
  },
  {
    label: "Step 8",
    key: 7,
    description: "Report",
    url: "/studies/Chiller/step8/:id",
  },
];

export const studyTypeCode = "CHIL";

export const reportTypeId = 21;
