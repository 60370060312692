import axios from "src/utils/axios";
import { IPumpSystemStrainerCheckeCleanDTO } from "src/ts/interfaces";

const get = () => {
  return axios.get<IPumpSystemStrainerCheckeCleanDTO[]>(
    "PumpSystemStrainerCheckeClean"
  );
};

const pumpSystemStrainerCheckeCleanService = { get };
export default pumpSystemStrainerCheckeCleanService;
