import {
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemSecondaryAction,
  ListItemText,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { AddIcon, DeleteIcon } from "src/components/icons";

import { IChillerTowerFanMotor } from "src/ts/interfaces/study/tower";
import { withStyles } from "@mui/styles";
import useChillerStudy from "../hooks/useChillerStudy";

const ListItemWithWiderSecondaryAction = withStyles({
  secondaryAction: {
    paddingRight: 130,
  },
})(ListItem);

interface Props {
  fanMotors: IChillerTowerFanMotor[];
  prefix?: string;
  showDelete?: boolean;
  showDuplicate?: boolean;
  onSelect?: (fanMotor: IChillerTowerFanMotor) => Promise<void>;
  onDelete?: (fanMotor: IChillerTowerFanMotor) => void;
  onAdd?: (fanMotor: IChillerTowerFanMotor, index: number) => Promise<void>;
  disableStudy: boolean;
}

const FanMotorList = ({
  fanMotors,
  prefix,
  showDelete,
  showDuplicate,
  onSelect,
  onDelete,
  onAdd,
  disableStudy,
}: Props) => {
  const { fanMotorValues } = useChillerStudy();
  const theme = useTheme();

  const selectedItemHandler = (fanMotor: IChillerTowerFanMotor) => {
    onSelect && onSelect(fanMotor);
  };

  const deleteItemHandler = (fanMotor: IChillerTowerFanMotor) => {
    onDelete && onDelete(fanMotor);
  };

  const addItemHandler = (fanMotor: IChillerTowerFanMotor, index: number) => {
    onAdd && onAdd(fanMotor, index);
  };

  return (
    <List
      component={Stack}
      direction="row"
      flexWrap={"wrap"}
      sx={{ "--List-item-endActionWidth": "100px" }}
    >
      {fanMotors &&
        fanMotors.map((fanMotor, index) => {
          return (
            <ListItemWithWiderSecondaryAction
              key={fanMotor.id}
              disablePadding
              sx={{
                borderColor: theme.palette.primary.main,
                borderWidth: "1px",
                borderStyle: "solid",
              }}
            >
              <ListItemButton
                selected={fanMotorValues?.id === fanMotor.id}
                onClick={() => selectedItemHandler(fanMotor)}
              >
                <ListItemText primary={`${prefix ? prefix : ""}${index + 1}`} />
              </ListItemButton>

              <ListItemSecondaryAction sx={{ width: "115px", right: "10px" }}>
                <Grid container>
                  <Grid item xs={5}>
                    <Typography>FnMt-{index + 1}</Typography>
                  </Grid>
                  <Grid item xs={7} textAlign={"right"}>
                    {showDuplicate &&
                      !disableStudy &&
                      index === fanMotors.length - 1 && (
                        <IconButton
                          aria-label="comments"
                          onClick={() => addItemHandler(fanMotor, index)}
                        >
                          <AddIcon />
                        </IconButton>
                      )}
                    {showDelete && !disableStudy && (
                      <IconButton
                        edge="end"
                        aria-label="delete"
                        onClick={() => deleteItemHandler(fanMotor)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    )}
                  </Grid>
                </Grid>
              </ListItemSecondaryAction>
            </ListItemWithWiderSecondaryAction>
          );
        })}
    </List>
  );
};

export default FanMotorList;
