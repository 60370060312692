import React, { useEffect, useState } from "react";
import { FormControlLabel, Grid, Radio, RadioGroup } from "@mui/material";
import { technicianQuizExamService } from "src/services";
import {
  ITechnicianQuizAnswers,
  ITechnicianQuizAnswersTech,
} from "src/ts/interfaces";
import { useLog } from "src/hooks";

interface IExamProps {
  answers: ITechnicianQuizAnswers[];
  questionId: number;
  examId: number;
}

function ExamAnswers(props: IExamProps) {
  const [quizAnswer, setQuizAnswer] = useState("0");
  const [isSubmiting, setIsSubmiting] = useState(false);
  const { log } = useLog();

  useEffect(() => {
    props.answers.forEach((item) => {
      if (item.isSelected) {
        setQuizAnswer(item.id.toString());
      }
    });
  }, [props.answers]);

  const handleSelectAnswer = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setIsSubmiting(true);
    setQuizAnswer((event.target as HTMLInputElement).value);

    let answer: ITechnicianQuizAnswersTech = {
      id: (event.target as HTMLInputElement).value,
      technicianQuizExamId: props.examId,
      technicianQuizBacklogId: props.questionId,
    };

    try {
      await technicianQuizExamService.updateAnswer(answer);
      setIsSubmiting(false);
    } catch (error: any) {
      log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      setIsSubmiting(false);
    }
  };

  return (
    <RadioGroup
      aria-label=""
      name={props.questionId.toString()}
      value={quizAnswer}
      onChange={handleSelectAnswer}
    >
      {props.answers &&
        props.answers.map((item, index) => (
          <Grid container>
            <Grid item xs={12}>
              <FormControlLabel
                control={<Radio value={item.id} disabled={isSubmiting} />}
                label={item.description}
              />
            </Grid>
          </Grid>
        ))}
    </RadioGroup>
  );
}

export default ExamAnswers;
