import axios from "src/utils/axios";
import {
  IActualFormulaFanMotorDTO,
  IEETReportServiceModelDTO,
  IFanDTO,
  IFanMotorDTO,
  IHeadSheetActualDTO,
  IHeadSheetActualFormulaDTO,
  IHeadSheetDTO,
  IHeadSheetStarterDTO,
  IHeadsheetActualRpmPdAlertsDTO,
} from "src/ts/interfaces";

const _PI = 3.14159;

const getById = (id: number) => {
  return axios.get<IHeadSheetDTO>(`HeadSheet/stepOne/${id}`);
};

const update = (id: number, headSheet: IHeadSheetDTO) => {
  return axios.put<IHeadSheetDTO>(`HeadSheet/stepOne/${id}`, headSheet);
};

const updateFanMotor = (
  id: number,
  motor: IFanMotorDTO,
  isArray: boolean,
  index: number
) => {
  if (isArray && id > 0 && index === 0)
    return axios.put<IFanMotorDTO>(
      `HeadSheet/FanMotorArray/${id}?isFanSystem=${isArray}`,
      motor
    );
  else return axios.put<IFanMotorDTO>(`FanMotor/${id}`, motor);
};

const updateFan = (
  id: number,
  fan: IFanDTO,
  isArray: boolean,
  index: number
) => {
  if (isArray && id > 0 && index === 0)
    return axios.put<IFanDTO>(
      `HeadSheet/FanArray/${id}?isFanSystem=${isArray}`,
      fan
    );
  else return axios.put<IFanDTO>(`Fan/${fan.id}`, fan);
};

const getStepThree = (id: number) => {
  return axios.get<IEETReportServiceModelDTO>(`HeadSheet/stepThree/${id}`);
};

const getStepFour = (id: number) => {
  return axios.get<IEETReportServiceModelDTO>(`HeadSheet/stepFour/${id}`);
};

const getActualFormulaById = (id: number) => {
  return axios.get<IActualFormulaFanMotorDTO[]>(
    `HeadSheet/actualFormula/${id}`
  );
};

const getActualRpmPdAlerts = (id: number) => {
  return axios.get<IHeadsheetActualRpmPdAlertsDTO[]>(
    `HeadSheet/actualsRpmPdAlert/${id}`
  );
};

const updateActuals = (
  id: number,
  actuals: IHeadSheetActualDTO,
  fanMotorId: number,
  isArray: boolean,
  index: number
) => {
  if (isArray && id > 0 && index === 0)
    return axios.put<IHeadSheetActualDTO>(
      `HeadSheet/ActualsArray/${id}?isFanSystem=${isArray}&fanMotorId=${fanMotorId}`,
      actuals
    );
  else return axios.put<IHeadSheetActualDTO>(`HeadSheet/Actual/${id}`, actuals);
};

const getActuals = (id: number, fanMotorId: number) => {
  return axios.get<IHeadSheetActualFormulaDTO>(
    `HeadSheet/actuals/${id}?motorId=${fanMotorId}`
  );
};

const updateStarter = (
  id: number,
  starterBreaker: IHeadSheetStarterDTO,
  isArray: boolean,
  index: number
) => {
  if (isArray && id > 0 && index === 0)
    return axios.put<IHeadSheetStarterDTO>(
      `HeadSheet/StarterArray/${id}?isFanSystem=${isArray}&index=${index}`,
      starterBreaker
    );
  else
    return axios.put<IHeadSheetStarterDTO>(
      `HeadSheetStarter/${id}`,
      starterBreaker
    );
};

const calculateFanPD = (fanRPM: number, motorRPM: number, motorPD: number) => {
  if (fanRPM > 0 && motorPD > 0) {
    return Math.round((motorRPM / fanRPM) * motorPD * 100) / 100;
  } else {
    return null;
  }
};

const calculateMotorPD = (fanRPM: number, motorRPM: number, fanPD: number) => {
  if (motorRPM > 0 && fanPD > 0) {
    return Math.round((fanRPM / motorRPM) * fanPD * 100) / 100;
  } else {
    return null;
  }
};

const calculateFanRPM = (motorRPM: number, motorPD: number, fanPD: number) => {
  if (motorRPM > 0 && motorPD > 0) {
    return Math.round((motorRPM / (fanPD / motorPD)) * 10) / 10;
  } else {
    return null;
  }
};

const calculateMotorRPM = (fanRPM: number, motorPD: number, fanPD: number) => {
  if (fanRPM > 0 && motorPD > 0) {
    return Math.round(fanRPM * (fanPD / motorPD) * 10) / 10;
  } else {
    return null;
  }
};

const calculateFanTip = (
  fanRpm: number,
  b: number,
  c: number,
  systemOfMeasurement: string
) => {
  let localConst = 12;
  if (systemOfMeasurement === "metricSystem") {
    localConst = 1000;
  }
  return Math.round(((fanRpm * _PI * (b * 2 + c)) / localConst) * 100) / 100;
};

const headSheetService = {
  getById,
  update,
  updateFanMotor,
  updateFan,
  getStepThree,
  getStepFour,
  getActualFormulaById,
  getActualRpmPdAlerts,
  updateActuals,
  updateStarter,
  getActuals,
  calculateFanPD,
  calculateMotorPD,
  calculateFanRPM,
  calculateMotorRPM,
  calculateFanTip,
};

export default headSheetService;
