import React, { useEffect } from "react";
import styled from "@emotion/styled";
import { Stack, Chip as MuiChip, Typography } from "@mui/material";
import { spacing, SpacingProps } from "@mui/system";
import LocalEnhancedTable from "src/components/localTable/LocalTable";
import { ICoupon } from "src/ts/interfaces";
import { ColumnType } from "src/types/enhancedTable";
import { CouponService } from "src/services";
import useAsyncQuery from "src/hooks/useAsyncQuery";
import { GridActionButton } from "src/components/gridControls";
import { IKeyValue } from "src/ts/interfaces";
import { Permission as PermissionTypes } from "src/ts/enums";
import { useLog, usePermissions } from "src/hooks";

interface ChipProps extends SpacingProps {
  component?: React.ElementType;
  href?: string;
  icon?: JSX.Element | null;
}

const Chip = styled(MuiChip)<ChipProps>(spacing);

interface ValidCouponsProps {
  onEditCoupon: (row: ICoupon) => void;
  refreshGrid: boolean;
  onRefreshGrid: () => void;
  couponType: IKeyValue<number, string>[];
  query?: any;
}

const ValidCoupons = (props: ValidCouponsProps) => {
  const { execute, data, isLoading } = useAsyncQuery<ICoupon[]>(
    CouponService.getValidCoupons
  );

  const { fullAccess } = usePermissions(PermissionTypes.Coupons);
  const { log } = useLog();

  const { onEditCoupon, refreshGrid, onRefreshGrid, couponType } = props;
  const columns: ColumnType[] = [
    {
      id: "id",
      label: "#",
      type: "string",
      sort: true,
    },
    {
      id: "code",
      label: "Code",
      type: "string",
      sort: true,
    },
    {
      id: "typeId",
      label: "Type",
      type: "custom",
      sort: true,
      callback: (row: ICoupon) => {
        var value = couponType.filter((item: any) => {
          return row.typeId === item.key;
        });

        return (
          <Typography>{(value.length > 0 && value[0].value) ?? []}</Typography>
        );
      },
    },
    {
      id: "value",
      label: "Value",
      type: "custom",
      sort: true,
      callback: (row: ICoupon) => {
        return (
          <Chip
            label={`${row.value}% OFF`}
            color="success"
            m={1}
            size="small"
          />
        );
      },
    },
    {
      id: "from",
      label: "Valid From",
      type: "date",
      format: "MM/DD/YYYY h:mma",
      sort: true,
    },
    {
      id: "to",
      label: "Expires on",
      type: "date",
      format: "MM/DD/YYYY h:mma",
      sort: true,
    },
    {
      id: "createdBy",
      label: "Created By",
      type: "custom",
      sort: true,
      callback: (row: ICoupon) => {
        return `${row.createBy?.name ?? ""} ${row.createBy?.lastName ?? ""}`;
      },
    },
    {
      id: "createdDate",
      label: "Created Date",
      type: "date",
      format: "MM/DD/YYYY h:mma",
      sort: true,
    },
    {
      id: "displayInWebPage",
      label: "Published in Webpage",
      type: "custom",
      sort: true,
      callback: (row: ICoupon) => {
        return (
          <Typography
            sx={{ color: row.displayInWebPage === false ? "red" : "black" }}
          >
            {row.displayInWebPage === false ? "No" : "Yes"}
          </Typography>
        );
      },
    },
    {
      id: "isOneTimeCoupon",
      label: "One time coupon",
      type: "custom",
      sort: true,
      callback: (row: ICoupon) => {
        return (
          <Typography
            sx={{ color: row.isOneTimeCoupon === false ? "red" : "green" }}
          >
            {row.isOneTimeCoupon === false ? "No" : "Yes"}
          </Typography>
        );
      },
    },
    {
      id: "na",
      label: "Actions",
      type: "custom",
      sort: false,
      callback: (row: ICoupon) => {
        return (
          <div>
            <Stack direction="row">
              <GridActionButton
                type={fullAccess ? "edit" : "view"}
                onClick={() => {
                  onEditCoupon(row);
                }}
                tooltip={fullAccess ? "Edit" : "View"}
              />
              {fullAccess && (
                <GridActionButton
                  type="disable"
                  onClick={() => {
                    handleDisable(row.id);
                  }}
                  tooltip="Disable"
                />
              )}
            </Stack>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    try {
      execute();
    } catch (error: any) {
      log.error(error?.message?.exceptionMessage ?? "Something went wrong");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshGrid]);

  const handleDisable = async (idCoupon: number) => {
    try {
      await CouponService.statusCoupon(idCoupon, "disable");
      onRefreshGrid();
    } catch (error: any) {
      log.error(error?.message?.exceptionMessage ?? "Something went wrong");
    }
  };

  return (
    <>
      <LocalEnhancedTable<ICoupon>
        refreshGrid={false}
        columns={columns}
        data={data ?? []}
        showSkeleton={isLoading}
        query={props.query}
      />
    </>
  );
};

export default ValidCoupons;
