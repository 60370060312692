import { IStudyStep } from "src/ts/interfaces";

export const steps: IStudyStep[] = [
  {
    label: "Step 1",
    key: 0,
    description: "System configuration",
    url: "/studies/ElectricCoil/Step1/:id",
  },
  {
    label: "Step 2",
    key: 1,
    description: "Coil data configuration",
    url: "/studies/ElectricCoil/Step2/:id",
  },
  {
    label: "Step 3",
    key: 2,
    description: "Data capture and report",
    url: "/studies/ElectricCoil/Step3/:id",
  },
  { label: "Step 4", key: 3, description: "Report" },
];
