import { useEffect, useState } from "react";
import timeZonesService from "src/services/catalogs/timeZonesService";
import { IAmpTimeZone, IKeyValue } from "src/ts/interfaces";

const useTimeZone = () => {
  const [timeZones, setTimeZones] = useState<IAmpTimeZone[]>([]);
  const [timeZoneKeyValue, setTimeZoneKeyValue] = useState<
    IKeyValue<number, string>[]
  >([]);
  useEffect(() => {
    const getTypeZones = async () => {
      const response = await timeZonesService.getAll();
      setTimeZones(response.data);

      const keyValues = response.data.map((item) => {
        return {
          key: item.id,
          value: item.name,
        };
      });

      setTimeZoneKeyValue(keyValues);
    };

    getTypeZones();
  }, []);

  const getTimeZoneById = (id: number) => {
    return timeZones.find((timeZone) => timeZone.id === id) ?? null;
  };

  return { timeZones, timeZoneKeyValue, getTimeZoneById };
};

export default useTimeZone;
