import {
  Grid,
  IconButton,
  Tooltip,
  TooltipProps,
  Typography,
  Zoom,
  styled,
  tooltipClasses,
} from "@mui/material";
import {
  FormButton,
  FormCheckBox,
  FormMaskText,
  FormNumeric2,
  FormRadioGroup,
  FormSelect,
  FormText,
} from "src/components/formControls";
import fanMotorManufacturerService from "src/services/study/fanMotorManufacturerService";
import { useAsyncQuery, useEffectOnce } from "src/hooks";
import { IKeyValue, ISystemOfMeasurement } from "src/ts/interfaces";
import { IDefaultHorsePower } from "src/ts/interfaces/catalogs";
import defaultHorsePower from "src/services/catalogs/defaultHorsePower";
import { useEffect, useState } from "react";
import { Stack } from "@mui/system";
import { Mode } from "src/ts/types";
import DialogMessagePopup from "src/components/DialogMessagePopup";
import { IChillerTowerFanMotor } from "src/ts/interfaces/study/tower";
import motorPowerService from "src/services/study/motorPowerService";
import motorFrameCategorySevice from "src/services/catalogs/motorFrameCategorySevice";
import { InfoIcon } from "src/components/icons";
import tooltipService from "src/services/tooltipService";
import React from "react";
import useChillerStudy from "../hooks/useChillerStudy";

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
  },
}));

export const motorPhaseOptions = [
  { key: "Three phase", value: "Three phase" },
  { key: "2 wire single phase", value: "2 wire single phase" },
];

export const hertzOptions = [
  { key: 50, value: "50 HZ" },
  { key: 60, value: "60 HZ" },
];

interface Props {
  mode: Mode;
  disableStudy: boolean;
  isLoading: boolean;
  systemOfMeasurement: ISystemOfMeasurement;
}

const FanMotorForm = ({
  mode,
  disableStudy,
  isLoading,
  systemOfMeasurement,
}: Props) => {
  const {
    fanMotorValues,
    handleFanMotorInputChange,
    fanMotorErrors,
    setFanMotorValues,
  } = useChillerStudy();

  const { execute: getFanMotorManufacturers, data: fanMotorManufacturers } =
    useAsyncQuery<IKeyValue<number, string>[]>(
      fanMotorManufacturerService.getKeyValues
    );

  const { execute: getDefaultHorsePower, data: defaultHorsePowersOptions } =
    useAsyncQuery<IKeyValue<number, string>[]>(
      defaultHorsePower.getKeyValuesMetricName
    );

  const {
    execute: getAllDefaultHorsePower,
    data: defaultAllHorsePowersOptions,
  } = useAsyncQuery<IDefaultHorsePower[]>(defaultHorsePower.getAll);

  const { execute: getMotorFrameCategory, data: motorFrameCategory } =
    useAsyncQuery<IKeyValue<number, string>[]>(
      motorFrameCategorySevice.getKeyValues
    );

  const [showLimitModal, setShowLimitModal] = useState(false);
  const [showServiceFactorModal, setShowServiceFactorModal] = useState(false);
  const [serviceFactorToolTip, setServiceFactorToolTip] = useState("");
  const [effToolTip, setEffToolTip] = useState("");

  useEffectOnce(() => {
    const getData = async () => {
      await getFanMotorManufacturers();
      await getDefaultHorsePower();
      await getMotorFrameCategory();
      await getAllDefaultHorsePower();
      const res = await tooltipService.getByCode("Service Factor Warning");
      setServiceFactorToolTip(res.data.content);

      const resEff = await tooltipService.getById(1);
      setEffToolTip(resEff.data.content);
    };
    getData();
  });

  const unknownHorsepower = function (e: any) {
    const isUnknownHorsepower = e.target.value;
    if (!isUnknownHorsepower) {
      setFanMotorValues({
        ...fanMotorValues,
        efficiencyUnknown: false,
        actualPowerFactorUnknown: false,
        horsepowerUnknown: isUnknownHorsepower,
      });
    } else {
      handleFanMotorInputChange(e);
    }
  };

  const showL2 =
    fanMotorValues.motorPhase === "Three phase" ||
    (fanMotorValues.motorPhase === "2 wire single phase" &&
      fanMotorValues.additionalVolAmpValue >= 1);
  const showL3 =
    fanMotorValues.motorPhase === "Three phase" ||
    (fanMotorValues.motorPhase === "2 wire single phase" &&
      fanMotorValues.additionalVolAmpValue === 2);
  const showL4 =
    fanMotorValues.motorPhase === "Three phase" &&
    fanMotorValues.additionalVolAmpValue >= 1;
  const showL5 =
    fanMotorValues.motorPhase === "Three phase" &&
    fanMotorValues.additionalVolAmpValue >= 2;
  const showL6 =
    fanMotorValues.motorPhase === "Three phase" &&
    fanMotorValues.additionalVolAmpValue === 3;

  const addAdditionalVolAmp = () => {
    switch (fanMotorValues.motorPhase) {
      case "Three phase":
        if (fanMotorValues.additionalVolAmpValue < 3) {
          setFanMotorValues({
            ...fanMotorValues,
            additionalVolAmpValue: fanMotorValues.additionalVolAmpValue + 1,
          });
        } else {
          setShowLimitModal(true);
        }
        break;
      case "2 wire single phase":
        if (fanMotorValues.additionalVolAmpValue < 2) {
          setFanMotorValues({
            ...fanMotorValues,
            additionalVolAmpValue: fanMotorValues.additionalVolAmpValue + 1,
          });
        } else {
          setShowLimitModal(true);
        }
        break;
      default:
        break;
    }
  };

  const removeVolAmpValue = () => {
    if (fanMotorValues.additionalVolAmpValue > 0) {
      const additionalVolAmpValue = fanMotorValues.additionalVolAmpValue - 1;

      switch (fanMotorValues.additionalVolAmpValue) {
        case 3:
          const nameplateVoltsPhase6 = null;
          const nameplateAmpsPhase6 = null;
          let selectedVoltAmpsReference =
            fanMotorValues.selectedVoltAmpsReference;
          if (fanMotorValues.selectedVoltAmpsReference === 6) {
            selectedVoltAmpsReference = null;
          }
          setFanMotorValues({
            ...fanMotorValues,
            nameplateVoltsPhase6,
            nameplateAmpsPhase6,
            selectedVoltAmpsReference,
            additionalVolAmpValue,
          });
          break;
        case 2:
          if (fanMotorValues.motorPhase === "Three phase") {
            const nameplateVoltsPhase5 = null;
            const nameplateAmpsPhase5 = null;
            let selectedVoltAmpsReference =
              fanMotorValues.selectedVoltAmpsReference;
            if (fanMotorValues.selectedVoltAmpsReference === 5) {
              selectedVoltAmpsReference = null;
            }
            setFanMotorValues({
              ...fanMotorValues,
              nameplateVoltsPhase5,
              nameplateAmpsPhase5,
              selectedVoltAmpsReference,
              additionalVolAmpValue,
            });
          }
          if (fanMotorValues.motorPhase === "2 wire single phase") {
            const nameplateVoltsPhase3 = null;
            const nameplateAmpsPhase3 = null;
            let selectedVoltAmpsReference =
              fanMotorValues.selectedVoltAmpsReference;
            if (fanMotorValues.selectedVoltAmpsReference === 3) {
              selectedVoltAmpsReference = null;
            }
            setFanMotorValues({
              ...fanMotorValues,
              nameplateVoltsPhase3,
              nameplateAmpsPhase3,
              selectedVoltAmpsReference,
              additionalVolAmpValue,
            });
          }
          break;
        case 1:
          if (fanMotorValues.motorPhase === "Three phase") {
            const nameplateVoltsPhase4 = null;
            const nameplateAmpsPhase4 = null;
            let selectedVoltAmpsReference =
              fanMotorValues.selectedVoltAmpsReference;
            if (fanMotorValues.selectedVoltAmpsReference === 4) {
              selectedVoltAmpsReference = null;
            }
            setFanMotorValues({
              ...fanMotorValues,
              nameplateVoltsPhase4,
              nameplateAmpsPhase4,
              selectedVoltAmpsReference,
              additionalVolAmpValue,
            });
          }
          if (fanMotorValues.motorPhase === "2 wire single phase") {
            const nameplateVoltsPhase2 = null;
            const nameplateAmpsPhase2 = null;
            let selectedVoltAmpsReference =
              fanMotorValues.selectedVoltAmpsReference;
            if (fanMotorValues.selectedVoltAmpsReference === 2) {
              selectedVoltAmpsReference = null;
            }
            setFanMotorValues({
              ...fanMotorValues,
              nameplateVoltsPhase2,
              nameplateAmpsPhase2,
              selectedVoltAmpsReference,
              additionalVolAmpValue,
            });
          }
          break;
        default:
          break;
      }
    } else {
      setShowLimitModal(true);
    }
  };

  const validateNameplateVolts = (fanMotor: IChillerTowerFanMotor) => {
    if (fanMotor.nameplateVoltsPhase1 != null) {
      return true;
    }
    if (fanMotor.nameplateVoltsPhase2 != null) {
      return true;
    }
    if (fanMotor.nameplateVoltsPhase3 != null) {
      return true;
    }
    return false;
  };

  function validateNameplateAmps(fanMotor: IChillerTowerFanMotor) {
    if (fanMotor.nameplateAmpsPhase1 != null) {
      return true;
    }
    if (fanMotor.nameplateAmpsPhase2 != null) {
      return true;
    }
    if (fanMotor.nameplateAmpsPhase3 != null) {
      return true;
    }
    return false;
  }

  const [effPfMessage, setEffPfMessage] = useState("");
  const [showEffPfLimitModal, setShowEffPfLimitModal] = useState(false);

  const getPowerFactor = async () => {
    if (fanMotorValues.nameplateHorsePowerId === null) return;
    if (!validateNameplateVolts(fanMotorValues)) {
      return;
    }
    if (!validateNameplateAmps(fanMotorValues)) {
      return;
    }

    if (
      fanMotorValues?.nameplateRatedEff &&
      fanMotorValues?.nameplateRatedEff > 1.0
    ) {
      setEffPfMessage("Improperly inputed data EFF can’t exceed 1.0");
      setShowEffPfLimitModal(true);
      return;
    }

    const motorModel: any = {
      motorPhase: fanMotorValues.motorPhase,
      nameplateVoltsPhase1: fanMotorValues.nameplateVoltsPhase1,
      nameplateVoltsPhase2: fanMotorValues.nameplateVoltsPhase2,
      nameplateVoltsPhase3: fanMotorValues.nameplateVoltsPhase3,
      nameplateAmpsPhase1: fanMotorValues.nameplateAmpsPhase1,
      nameplateAmpsPhase2: fanMotorValues.nameplateAmpsPhase2,
      nameplateAmpsPhase3: fanMotorValues.nameplateAmpsPhase3,
      nameplateEfficiency: fanMotorValues.nameplateRatedEff,
      nameplateHorsePowerId: fanMotorValues.nameplateHorsePowerId,
    };

    const powerFactorRes = await motorPowerService.powerFactor(motorModel);
    let nameplateRatedPF =
      powerFactorRes.data === 0 ? null : powerFactorRes.data;
    if (nameplateRatedPF && nameplateRatedPF > 1.0) {
      setEffPfMessage("Improperly inputed data PF can’t exceed 1.0");
      nameplateRatedPF = null;
      setShowEffPfLimitModal(true);
    }
    setFanMotorValues({
      ...fanMotorValues,
      nameplateRatedPF,
    });
  };

  const getEfficiencyByPowerFactor = async () => {
    if (fanMotorValues.nameplateHorsePowerId == null) {
      return;
    }
    if (!validateNameplateVolts(fanMotorValues)) {
      return;
    }
    if (!validateNameplateAmps(fanMotorValues)) {
      return;
    }

    if (
      fanMotorValues?.nameplateRatedPF &&
      fanMotorValues?.nameplateRatedPF > 1.0
    ) {
      setEffPfMessage("Improperly inputed data PF can’t exceed 1.0");
      setShowEffPfLimitModal(true);
      return;
    }

    var motorModel: any = {
      motorPhase: fanMotorValues.motorPhase,
      nameplateVoltsPhase1: fanMotorValues.nameplateVoltsPhase1,
      nameplateVoltsPhase2: fanMotorValues.nameplateVoltsPhase2,
      nameplateVoltsPhase3: fanMotorValues.nameplateVoltsPhase3,
      nameplateAmpsPhase1: fanMotorValues.nameplateAmpsPhase1,
      nameplateAmpsPhase2: fanMotorValues.nameplateAmpsPhase2,
      nameplateAmpsPhase3: fanMotorValues.nameplateAmpsPhase3,
      nameplatePowerFactor: fanMotorValues.nameplateRatedPF,
      nameplateHorsePowerId: fanMotorValues.nameplateHorsePowerId,
    };

    const response = await motorPowerService.efficiency(motorModel);

    if (response != null) {
      let nameplateRatedEff = response.data === 0 ? null : response.data;
      if (nameplateRatedEff && nameplateRatedEff > 1.0) {
        setEffPfMessage("Improperly inputed data EFF can’t exceed 1.0");
        setFanMotorValues({
          ...fanMotorValues,
          nameplateRatedEff: null,
        });
        setShowEffPfLimitModal(true);
        return;
      }
      setFanMotorValues({
        ...fanMotorValues,
        nameplateRatedEff: nameplateRatedEff,
      });
    }
  };
  const row = 8;

  const [triggerGetPowerFactor, setTriggerGetPowerFactor] = useState(false);
  const changeHorsePower = (e: any) => {
    const nameplateHorsePowerId: number | null = e.target.value;
    //loadMotorInformationForTraining()

    if (fanMotorValues.efficiencyUnknown && nameplateHorsePowerId) {
      const efficiency = getEfficiencyByHorsePowerId(nameplateHorsePowerId);
      setFanMotorValues({
        ...fanMotorValues,
        nameplateRatedEff: efficiency,
        nameplateHorsePowerId,
      });
    } else {
      handleFanMotorInputChange(e);
    }

    setTriggerGetPowerFactor(!triggerGetPowerFactor);
  };

  useEffect(() => {
    const getData = async () => {
      await getPowerFactor();
    };
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerGetPowerFactor]);

  const unknownEfficiency = (e: any) => {
    const efficiencyUnknown: boolean = e.target.value;

    if (efficiencyUnknown) {
      const horsepowerUnknown = false;
      const actualPowerFactorUnknown = false;

      if (fanMotorValues.nameplateHorsePowerId != null) {
        const efficiency = getEfficiencyByHorsePowerId(
          fanMotorValues?.nameplateHorsePowerId
        );
        const nameplateRatedEff = Math.round(efficiency * 1000) / 1000;
        setFanMotorValues({
          ...fanMotorValues,
          nameplateRatedEff,
          horsepowerUnknown,
          actualPowerFactorUnknown,
          efficiencyUnknown,
        });
        setTriggerGetPowerFactor(!triggerGetPowerFactor);
      } else {
        setFanMotorValues({
          ...fanMotorValues,
          horsepowerUnknown,
          actualPowerFactorUnknown,
          efficiencyUnknown,
        });
      }
    } else {
      handleFanMotorInputChange(e);
    }
  };

  function getEfficiencyByHorsePowerId(nameplateHorsePowerId: number | null) {
    let efficiency = 0;

    efficiency =
      defaultAllHorsePowersOptions.find((x) => x.id === nameplateHorsePowerId)
        ?.efficiency ?? 0;
    return efficiency;
  }

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={row}>
          <Typography variant="h6">Motor Information</Typography>
        </Grid>
        <Grid item xs={row}>
          <FormSelect
            name={"fanMotorManufacturerId"}
            value={fanMotorValues?.fanMotorManufacturerId ?? 0}
            label="Nameplate Motor Manufacturer"
            onChange={handleFanMotorInputChange}
            options={fanMotorManufacturers}
            showSkeleton={isLoading}
            defaultValue={{ key: 0, value: "Select a value" }}
            error={fanMotorErrors.fanMotorManufacturerId}
            mode={mode}
            disable={disableStudy}
          />
        </Grid>
        {fanMotorValues.fanMotorManufacturerId === 28 && (
          <Grid item xs={row}>
            <FormText
              name="otherFanMotorManufacturer"
              label="Other Motor manufacturer"
              value={fanMotorValues?.otherFanMotorManufacturer ?? ""}
              onChange={handleFanMotorInputChange}
              error={fanMotorErrors.otherFanMotorManufacturer}
              showSkeleton={isLoading}
              mode={mode}
              disabled={disableStudy}
            />
          </Grid>
        )}
        <Grid item xs={row}>
          <FormText
            name="model"
            label="Model"
            onChange={handleFanMotorInputChange}
            placeholder="Type the nameplate model here"
            value={fanMotorValues?.model ?? ""}
            error={fanMotorErrors.model}
            showSkeleton={isLoading}
            mode={mode}
            disabled={disableStudy}
          />
        </Grid>
        <Grid item xs={row}>
          <FormText
            name="serialNumber"
            label="Serial number"
            onChange={handleFanMotorInputChange}
            placeholder="Type the nameplate serial number here"
            value={fanMotorValues.serialNumber ?? ""}
            error={fanMotorErrors.serialNumber}
            showSkeleton={isLoading}
            mode={mode}
            disabled={disableStudy}
          />
        </Grid>
        <Grid item xs={6}>
          <FormSelect
            name="nameplateHorsePowerId"
            label="Nameplate HP"
            onChange={changeHorsePower}
            value={fanMotorValues.nameplateHorsePowerId ?? 0}
            error={fanMotorErrors.serialNumber}
            showSkeleton={isLoading}
            options={defaultHorsePowersOptions}
            disable={fanMotorValues?.horsepowerUnknown}
            mode={mode}
            disabled={disableStudy || fanMotorValues?.horsepowerUnknown}
          />
        </Grid>
        <Grid item xs={2}>
          <FormCheckBox
            name="horsepowerUnknown"
            label="Nameplate HP unknown"
            onChange={unknownHorsepower}
            value={fanMotorValues?.horsepowerUnknown}
            showSkeleton={isLoading}
            mode={mode}
            disabled={disableStudy}
          />
        </Grid>
        <Grid item xs={row}>
          <FormNumeric2
            name="nameplateRpm"
            value={fanMotorValues.nameplateRpm ?? ""}
            error={fanMotorErrors.nameplateRpm}
            label={`Nameplate ${systemOfMeasurement.get("rpm")}`}
            onChange={handleFanMotorInputChange}
            mode={mode}
            disabled={disableStudy}
            thousandSeparator={true}
            decimalScale={2}
            maxValue={100000000}
            fixedDecimalScale={true}
            placeholder="0.00"
            showSkeleton={isLoading}
          />
        </Grid>
        <Grid item xs={row}>
          <FormSelect
            name="motorFrameCategoryId"
            label="Nameplate frame category"
            onChange={handleFanMotorInputChange}
            value={fanMotorValues.motorFrameCategoryId ?? 0}
            error={fanMotorErrors.motorFrameCategoryId}
            showSkeleton={isLoading}
            options={motorFrameCategory}
            defaultValue={{ key: 0, value: "Select nameplate frame" }}
            mode={mode}
            disable={disableStudy}
          />
        </Grid>
        {fanMotorValues.motorFrameCategoryId && (
          <Grid item xs={row}>
            <FormText
              name="otherNameplateFrame"
              label="Nameplate frame"
              onChange={handleFanMotorInputChange}
              placeholder="Type the nameplate serial number here"
              value={fanMotorValues.otherNameplateFrame ?? ""}
              error={fanMotorErrors.otherNameplateFrame}
              showSkeleton={isLoading}
              mode={mode}
              disabled={disableStudy}
            />
          </Grid>
        )}
        <Grid item xs={6}>
          <FormMaskText
            name="serviceFactor"
            mask="1.999"
            label="Nameplate service factor"
            value={fanMotorValues?.serviceFactor?.toString() ?? ""}
            error={fanMotorErrors.serviceFactor}
            onChange={handleFanMotorInputChange}
            showSkeleton={isLoading}
            mode={mode}
            disabled={disableStudy || fanMotorValues?.serviceFactorNotListed}
          />
        </Grid>
        <Grid item xs={2}>
          <Stack direction={"row"}>
            <FormCheckBox
              name="serviceFactorNotListed"
              label="Not listed"
              onChange={handleFanMotorInputChange}
              value={fanMotorValues?.serviceFactorNotListed}
              showSkeleton={isLoading}
              mode={mode}
              disabled={disableStudy}
            />
            <Tooltip
              TransitionComponent={Zoom}
              title={"Click to open information."}
            >
              <span>
                <IconButton
                  aria-label="delete"
                  size="large"
                  onClick={() => setShowServiceFactorModal(true)}
                >
                  <InfoIcon />
                </IconButton>
              </span>
            </Tooltip>
          </Stack>
        </Grid>
        <Grid item xs={row}>
          <Stack direction={"row"}>
            <FormCheckBox
              name="ecmMotor"
              label="ECM (Motor)"
              onChange={handleFanMotorInputChange}
              value={fanMotorValues?.ecmMotor}
              showSkeleton={isLoading}
              mode={mode}
              disabled={disableStudy}
            />
            <HtmlTooltip
              TransitionComponent={Zoom}
              title={
                <React.Fragment>
                  <p>
                    ECM (Electronically Commutated Motor) Replacement requires
                    reprogramming of this specific motor. Motor swap can't be
                    performed without reprogramming.
                  </p>
                  <p>See Manufacturer Literature.</p>
                  <p>
                    Limited Horsepower - [1/60 HP - 12.42 Watts] to [10.0 HP -
                    7,456.9 Watts] is the typical range.
                  </p>
                  <p>[1.0 HP - 745.69 Watts] is by far the most common size.</p>
                </React.Fragment>
              }
            >
              <span>
                <IconButton aria-label="delete" size="large">
                  <InfoIcon />
                </IconButton>
              </span>
            </HtmlTooltip>
          </Stack>
        </Grid>
        <Grid item xs={row}>
          <FormCheckBox
            name="systemMotorOperatesFromAVfDPwmDevice"
            label="System motor operates from a VFD/PWM device?"
            onChange={handleFanMotorInputChange}
            value={fanMotorValues?.systemMotorOperatesFromAVfDPwmDevice}
            showSkeleton={isLoading}
            mode={mode}
            disabled={disableStudy}
          />
        </Grid>
        <Grid item xs={12}>
          <FormRadioGroup
            label="Motor phase"
            name="motorPhase"
            onChange={handleFanMotorInputChange}
            items={motorPhaseOptions}
            row
            value={fanMotorValues.motorPhase}
            defaultValue={"Three phase"}
            mode={mode}
            disabled={disableStudy}
            showSkeleton={isLoading}
          />
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={5}>
            <Grid item xs={2}>
              <Stack direction="row" justifyContent="flex-end">
                Nameplate Rated Volts
              </Stack>
            </Grid>
            <Grid item xs={1}>
              <FormNumeric2
                name="nameplateVoltsPhase1"
                value={fanMotorValues.nameplateVoltsPhase1 ?? ""}
                error={fanMotorErrors.nameplateVoltsPhase1}
                label=""
                onChange={handleFanMotorInputChange}
                onBlur={() => getPowerFactor()}
                showSkeleton={isLoading}
                mode={mode}
                disabled={disableStudy}
                decimalScale={2}
                maxValue={1000}
                fixedDecimalScale={true}
                placeholder="000.00 V"
              />
            </Grid>
            {showL2 && (
              <Grid item xs={1}>
                <FormNumeric2
                  name="nameplateVoltsPhase2"
                  value={fanMotorValues?.nameplateVoltsPhase2 ?? ""}
                  error={fanMotorErrors.nameplateVoltsPhase2}
                  label=""
                  onChange={handleFanMotorInputChange}
                  onBlur={() => getPowerFactor()}
                  showSkeleton={isLoading}
                  mode={mode}
                  disabled={disableStudy}
                  decimalScale={2}
                  maxValue={1000}
                  fixedDecimalScale={true}
                  placeholder="000.00 V"
                />
              </Grid>
            )}
            {showL3 && (
              <Grid item xs={1}>
                <FormNumeric2
                  name="nameplateVoltsPhase3"
                  value={fanMotorValues?.nameplateVoltsPhase3 ?? ""}
                  error={fanMotorErrors.nameplateVoltsPhase3}
                  label=""
                  onChange={handleFanMotorInputChange}
                  onBlur={() => getPowerFactor()}
                  showSkeleton={isLoading}
                  mode={mode}
                  disabled={disableStudy}
                  decimalScale={2}
                  maxValue={1000}
                  fixedDecimalScale={true}
                  placeholder="000.00 V"
                />
              </Grid>
            )}
            {showL4 && (
              <Grid item xs={1}>
                <FormNumeric2
                  name="nameplateVoltsPhase4"
                  value={fanMotorValues?.nameplateVoltsPhase4 ?? ""}
                  error={fanMotorErrors.nameplateVoltsPhase4}
                  label=""
                  onChange={handleFanMotorInputChange}
                  showSkeleton={isLoading}
                  mode={mode}
                  disabled={disableStudy}
                  decimalScale={2}
                  maxValue={1000}
                  fixedDecimalScale={true}
                  placeholder="000.00 V"
                />
              </Grid>
            )}
            {showL5 && (
              <Grid item xs={1}>
                <FormNumeric2
                  name="nameplateVoltsPhase5"
                  value={fanMotorValues?.nameplateVoltsPhase5 ?? ""}
                  error={fanMotorErrors.nameplateVoltsPhase5}
                  label=""
                  onChange={handleFanMotorInputChange}
                  showSkeleton={isLoading}
                  mode={mode}
                  disabled={disableStudy}
                  decimalScale={2}
                  maxValue={1000}
                  fixedDecimalScale={true}
                  placeholder="000.00 V"
                />
              </Grid>
            )}
            {showL6 && (
              <Grid item xs={1}>
                <FormNumeric2
                  name="nameplateVoltsPhase6"
                  value={fanMotorValues?.nameplateVoltsPhase6 ?? ""}
                  error={fanMotorErrors.nameplateVoltsPhase6}
                  label=""
                  onChange={handleFanMotorInputChange}
                  showSkeleton={isLoading}
                  mode={mode}
                  disabled={disableStudy}
                  decimalScale={2}
                  maxValue={1000}
                  fixedDecimalScale={true}
                  placeholder="000.00 V"
                />
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={5}>
            <Grid item xs={2}>
              <Stack direction="row" justifyContent="flex-end">
                Nameplate Rated Amps
              </Stack>
            </Grid>
            <Grid item xs={1}>
              <FormNumeric2
                name="nameplateAmpsPhase1"
                value={fanMotorValues?.nameplateAmpsPhase1 ?? ""}
                error={fanMotorErrors.nameplateAmpsPhase1}
                label=""
                onChange={handleFanMotorInputChange}
                onBlur={() => getPowerFactor()}
                showSkeleton={isLoading}
                mode={mode}
                disabled={disableStudy}
                decimalScale={2}
                maxValue={1000}
                fixedDecimalScale={true}
                placeholder="000.00 A"
              />
            </Grid>
            {showL2 && (
              <Grid item xs={1}>
                <FormNumeric2
                  name="nameplateAmpsPhase2"
                  value={fanMotorValues.nameplateAmpsPhase2 ?? ""}
                  error={fanMotorErrors.nameplateAmpsPhase2}
                  label=""
                  onChange={handleFanMotorInputChange}
                  onBlur={() => getPowerFactor()}
                  showSkeleton={isLoading}
                  mode={mode}
                  disabled={disableStudy}
                  decimalScale={2}
                  maxValue={1000}
                  fixedDecimalScale={true}
                  placeholder="000.00 A"
                />
              </Grid>
            )}
            {showL3 && (
              <Grid item xs={1}>
                <FormNumeric2
                  name="nameplateAmpsPhase3"
                  value={fanMotorValues?.nameplateAmpsPhase3 ?? ""}
                  error={fanMotorErrors.nameplateAmpsPhase3}
                  label=""
                  onChange={handleFanMotorInputChange}
                  onBlur={() => getPowerFactor()}
                  showSkeleton={isLoading}
                  mode={mode}
                  disabled={disableStudy}
                  decimalScale={2}
                  maxValue={1000}
                  fixedDecimalScale={true}
                  placeholder="000.00 A"
                />
              </Grid>
            )}
            {showL4 && (
              <Grid item xs={1}>
                <FormNumeric2
                  name="nameplateAmpsPhase4"
                  value={fanMotorValues?.nameplateAmpsPhase4 ?? ""}
                  error={fanMotorErrors.nameplateAmpsPhase4}
                  label=""
                  onChange={handleFanMotorInputChange}
                  showSkeleton={isLoading}
                  mode={mode}
                  disabled={disableStudy}
                  decimalScale={2}
                  maxValue={1000}
                  fixedDecimalScale={true}
                  placeholder="000.00 A"
                />
              </Grid>
            )}
            {showL5 && (
              <Grid item xs={1}>
                <FormNumeric2
                  name="nameplateAmpsPhase5"
                  value={fanMotorValues?.nameplateAmpsPhase5 ?? ""}
                  error={fanMotorErrors.nameplateAmpsPhase5}
                  label=""
                  onChange={handleFanMotorInputChange}
                  showSkeleton={isLoading}
                  mode={mode}
                  disabled={disableStudy}
                  decimalScale={2}
                  maxValue={1000}
                  fixedDecimalScale={true}
                  placeholder="000.00 A"
                />
              </Grid>
            )}
            {showL6 && (
              <Grid item xs={1}>
                <FormNumeric2
                  name="nameplateAmpsPhase6"
                  value={fanMotorValues?.nameplateAmpsPhase6 ?? ""}
                  error={fanMotorErrors.nameplateAmpsPhase6}
                  label=""
                  onChange={handleFanMotorInputChange}
                  showSkeleton={isLoading}
                  mode={mode}
                  disabled={disableStudy}
                  decimalScale={2}
                  maxValue={1000}
                  fixedDecimalScale={true}
                  placeholder="000.00 A"
                />
              </Grid>
            )}
          </Grid>
          {!disableStudy && (
            <Grid item xs={row}>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="center"
                spacing={2}
                pt={3}
              >
                <FormButton
                  text="Add"
                  onClick={addAdditionalVolAmp}
                  size="small"
                  variant="outlined"
                />
                <FormButton
                  text="Remove"
                  onClick={removeVolAmpValue}
                  size="small"
                  variant="outlined"
                />
              </Stack>
            </Grid>
          )}
        </Grid>
        <Grid item xs={4}>
          <FormMaskText
            mask="0.999"
            name="nameplateRatedEff"
            value={fanMotorValues?.nameplateRatedEff?.toString() ?? ""}
            error={fanMotorErrors.nameplateRatedEff}
            label="Nameplate efficiency (Eff)"
            onChange={handleFanMotorInputChange}
            onBlur={() => getPowerFactor()}
            showSkeleton={isLoading}
            mode={mode}
            disabled={disableStudy || fanMotorValues?.efficiencyUnknown}
          />
        </Grid>
        <Grid item xs={4}>
          <FormCheckBox
            name="efficiencyUnknown"
            label="Power Factor and Efficiency Unknown"
            onChange={unknownEfficiency}
            value={fanMotorValues?.efficiencyUnknown}
            showSkeleton={isLoading}
            mode={mode}
            disabled={disableStudy}
          />
        </Grid>
        <Grid item xs={1}>
          <HtmlTooltip
            TransitionComponent={Zoom}
            title={
              <React.Fragment>
                <div
                  dangerouslySetInnerHTML={{ __html: effToolTip }}
                  style={{ marginRight: "25px" }}
                />
              </React.Fragment>
            }
          >
            <span>
              <IconButton aria-label="delete" size="large">
                <InfoIcon />
              </IconButton>
            </span>
          </HtmlTooltip>
        </Grid>
        <Grid item xs={8}>
          <FormMaskText
            mask="0.999"
            name="nameplateRatedPF"
            value={fanMotorValues?.nameplateRatedPF?.toString() ?? ""}
            error={fanMotorErrors.nameplateRatedPF}
            label="Nameplate power factor (PF)"
            onChange={handleFanMotorInputChange}
            onBlur={() => getEfficiencyByPowerFactor()}
            showSkeleton={isLoading}
            mode={mode}
            disabled={disableStudy || fanMotorValues?.efficiencyUnknown}
          />
        </Grid>
        <Grid item xs={1}>
          <HtmlTooltip
            TransitionComponent={Zoom}
            title={
              <React.Fragment>
                <div
                  dangerouslySetInnerHTML={{ __html: effToolTip }}
                  style={{ marginRight: "25px" }}
                />
              </React.Fragment>
            }
          >
            <span>
              <IconButton aria-label="delete" size="large">
                <InfoIcon />
              </IconButton>
            </span>
          </HtmlTooltip>
        </Grid>
        <Grid item xs={12}>
          <FormRadioGroup
            label="Nameplate Rated Hz"
            name="nameplateRatedHz"
            onChange={handleFanMotorInputChange}
            items={hertzOptions}
            row
            value={fanMotorValues.nameplateRatedHz}
            mode={mode}
            disabled={disableStudy}
            showSkeleton={isLoading}
            defaultValue={60}
          />
        </Grid>
      </Grid>
      <DialogMessagePopup
        title={"Limit reached."}
        text={
          <>
            {fanMotorValues.additionalVolAmpValue > 0 && (
              <p>You have reached the limit of additional "Volts/Amps" sets.</p>
            )}
            {fanMotorValues.additionalVolAmpValue === 0 && (
              <p>You can't remove anymore "Volts/Amps" sets.</p>
            )}
          </>
        }
        showPopup={showLimitModal}
        setShowPopup={setShowLimitModal}
        onSave={() => {
          setShowLimitModal(false);
        }}
        isSubmitting={false}
        hideCancel={true}
      />

      <DialogMessagePopup
        title={"Limit reached."}
        text={effPfMessage}
        showPopup={showEffPfLimitModal}
        setShowPopup={setShowEffPfLimitModal}
        onSave={() => {
          setShowEffPfLimitModal(false);
        }}
        isSubmitting={false}
        hideCancel={true}
      />

      <DialogMessagePopup
        title={"Important."}
        text={
          <div
            dangerouslySetInnerHTML={{ __html: serviceFactorToolTip }}
            style={{ marginRight: "25px" }}
          />
        }
        showPopup={showServiceFactorModal}
        setShowPopup={setShowServiceFactorModal}
        onSave={() => {
          setShowServiceFactorModal(false);
        }}
        isSubmitting={false}
        hideCancel={true}
      />
    </>
  );
};

export default FanMotorForm;
