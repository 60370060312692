import React, { useEffect, useState } from "react";
import {
  Card as MuiCard,
  Divider as MuiDivider,
  Grid,
  Tab,
  Tabs,
  Button,
  Switch,
  Typography,
} from "@mui/material";
import { Stack, spacing } from "@mui/system";
import { useAsyncQuery, useForm, useLog } from "src/hooks";
import { Validator } from "src/ts/types/Validator";
import { Permission as PermissionTypes } from "src/ts/enums";
import { usePermissions } from "src/hooks";
import HeaderPage from "src/components/page/HeaderPage";
import styled from "@emotion/styled";
import { AddIcon } from "src/components/icons";
import { ColumnType } from "src/types/enhancedTable";
import { IMandatoryMinimumField } from "src/ts/interfaces/catalogs/mandatoryMinimumField";
import { GridActionButton } from "src/components/gridControls";
import LocalEnhancedTable from "src/components/localTable/LocalTable";
import mandatoryMinimumFieldService from "src/services/mandatoryMinimumFieldService";
import { useParams } from "react-router-dom";
import mandatoryMinimumService from "src/services/mandatoryMinimumService";
import { IMandatoryMinimumList } from "src/ts/interfaces/catalogs/mandatoryMinimum";
import Popup from "src/components/Popup";
import {
  FormAcceptButton,
  FormCancelButton,
  FormSelect,
  FormText,
  FormTextArea,
} from "src/components/formControls";
import MatchWordConfirmModal from "src/components/modals/MatchWordConfirmModal";
import { MandatoryMinimumFieldType } from "src/constants";

const Card = styled(MuiCard)(spacing);
const Divider = styled(MuiDivider)(spacing);
const initialValues: IMandatoryMinimumField = {
  id: 0,
  mandatoryMinimumId: 0,
  name: "",
  description: "",
  fieldId: "",
  order: 0,
  switchOn: false,
  updatedDate: new Date(),
  createdDate: new Date(),
  type: 0,
  subTitle: "",
  subItems: [],
};

const getColumns = (
  handleSwicthChange: (e: any) => void,
  fullAccess: boolean,
  handleEdit: (e: any) => void,
  type: number,
  callDeleteModalHandler: (e: IMandatoryMinimumField) => void,
  addSubItem: (e: number) => void
): ColumnType[] => [
  {
    id: "description",
    label: "Name",
    type: "string",
    sort: true,
  },
  {
    id: "switchOn",
    label: "Switch On",
    type: "custom",
    sort: false,
    hide: type === MandatoryMinimumFieldType.Required,
    callback: (row: IMandatoryMinimumField) => {
      return (
        <>
          {row.parentId !== null && (
            <Switch
              checked={row.switchOn}
              onChange={handleSwicthChange}
              value={true}
            />
          )}
        </>
      );
    },
  },
  {
    id: "x",
    label: "Actions",
    type: "custom",
    sort: false,
    callback: (row: IMandatoryMinimumField) => (
      <>
        {row.parentId === null && type === MandatoryMinimumFieldType.Title && (
          <GridActionButton
            type="add"
            tooltip="Add Sub Item"
            onClick={() => {
              addSubItem(row.id);
            }}
          />
        )}
        <GridActionButton
          type={fullAccess ? "edit" : "view"}
          onClick={() => {
            handleEdit(row);
          }}
          tooltip={fullAccess ? "Edit" : "View"}
        />
        <GridActionButton
          type="delete"
          onClick={() => {
            callDeleteModalHandler(row);
          }}
          tooltip="Delete"
        />
      </>
    ),
  },
];

const MandatoryMinimumForm = () => {
  const { log } = useLog();
  let { id } = useParams<{ id: string }>();
  const validate = (fieldValues = values) => {
    let temp: Record<string, string> = { ...errors };

    temp.key = new Validator(fieldValues, "key")
      .isRequired("The key is required")
      .maxLength(50, "Only 50 character are allowed")
      .toString();

    temp.imperialSystem = new Validator(fieldValues, "imperialSystem")
      .isRequired("The label is required")
      .maxLength(50, "Only 50 character are allowed")
      .toString();

    temp.metricSystem = new Validator(fieldValues, "metricSystem")
      .isRequired("The label is required")
      .maxLength(50, "Only 50 character are allowed")
      .toString();

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const types = [
    { key: MandatoryMinimumFieldType.Title, value: "Title" },
    { key: MandatoryMinimumFieldType.Switch, value: "Switch" },
  ];

  const { execute, data, isLoading } = useAsyncQuery<IMandatoryMinimumList>(
    mandatoryMinimumService.getByTypeId
  );

  const { execute: executeList, data: dataList } = useAsyncQuery<
    IMandatoryMinimumField[]
  >(mandatoryMinimumFieldService.getByType);
  const { values, setValues, errors, setErrors, handleInputChange } = useForm(
    initialValues,
    false,
    validate
  );

  const { fullAccess, readOnly } = usePermissions(PermissionTypes.Catalogs);
  const [tab, setTab] = useState(MandatoryMinimumFieldType.Title);
  const [showPopup, setShowPopup] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [refreshPage, setRefreshPage] = useState(false);
  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setTab(newValue);
  };

  useEffect(() => {
    const getData = async () => {
      await execute(Number(id));
    };

    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    const getData = async () => {
      await executeList(Number(id));
    };

    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshPage]);

  const handleSave = async () => {
    if (!validate()) return;

    try {
      if (values.id === 0) {
        await mandatoryMinimumFieldService.add(values);
        log.success("Item was added succesfully");
      } else {
        await mandatoryMinimumFieldService.update(values);
        log.success("Item was updated succesfully");
      }
      setRefreshPage(!refreshPage);
      setShowPopup(false);
    } catch (error: any) {
      log.error(error?.message?.exceptionMessage ?? "Something went wrong");
    }
  };

  const handleClose = () => {
    setShowPopup(false);
  };

  const handleSwicthChange = (e: any) => {
    values.switchOn = !values.switchOn;
    setValues({ ...values });
  };

  const handleEdit = (row: IMandatoryMinimumField) => {
    setValues(row);
    setShowPopup(true);
  };

  const newHandler = async () => {
    if (tab === MandatoryMinimumFieldType.Required) initialValues.type = tab;
    else initialValues.type = 0;
    initialValues.mandatoryMinimumId = Number(id);
    setValues(initialValues);
    setShowPopup(true);
  };

  const callDeleteModalHandler = (item: IMandatoryMinimumField) => {
    setValues(item);
    setDeleteModal(true);
  };

  const addSubItemHandler = (parentId: number) => {
    initialValues.parentId = parentId;
    initialValues.mandatoryMinimumId = Number(id);
    setValues(initialValues);
    setShowPopup(true);
  };

  const deleteHandler = async () => {
    try {
      await mandatoryMinimumFieldService.remove(values?.id);
      log.success("The item was deleted");
      setRefreshPage(!refreshPage);
      setDeleteModal(false);
    } catch (error: any) {
      log.error(error?.message?.exceptionMessage ?? "Something went wrong");
    }
  };

  return (
    <>
      <HeaderPage
        title={`(${data?.reportType?.code}) ${data?.reportType?.name}`}
        parentText={"Mandatory Minimum"}
        parentLink={`/app/MandatoryMinimum`}
      ></HeaderPage>
      <Divider my={6}></Divider>
      <Card mb={6}>
        <Grid container>
          <Grid item xs={12}>
            <Tabs value={tab} onChange={handleChangeTab} aria-label="Buildings">
              <Tab
                label="Optional"
                id="0"
                value={MandatoryMinimumFieldType.Title}
              />
              <Tab
                label="Mandatory Minimum"
                id="1"
                value={MandatoryMinimumFieldType.Required}
              />
            </Tabs>
          </Grid>
          <Grid item xs={12} m={5} sx={{ textAlign: "right" }}>
            {fullAccess && (
              <Button onClick={newHandler} variant="contained" color="primary">
                <AddIcon />
                {`Add Item`}
              </Button>
            )}
          </Grid>
          <Grid item xs={12}>
            <LocalEnhancedTable<IMandatoryMinimumField>
              refreshGrid={true}
              columns={getColumns(
                handleSwicthChange,
                fullAccess ?? false,
                handleEdit,
                tab,
                callDeleteModalHandler,
                addSubItemHandler
              )}
              data={Object.values(dataList ?? []).filter((x) => {
                return x.type === tab;
              })}
              showSkeleton={isLoading}
              subItems={"subItems"}
              defaultRowPerPage={10}
            />
          </Grid>
        </Grid>
      </Card>
      <Popup
        title={"Item"}
        openPopup={showPopup}
        setOpenPopup={setShowPopup}
        onClose={handleClose}
        size={"sm"}
      >
        <Stack>
          <Grid container>
            <Grid item xs={12} pb={5}>
              <FormText
                name="description"
                label="Name"
                value={values?.description}
                onChange={handleInputChange}
                disabled={readOnly}
                showSkeleton={isLoading}
              />
            </Grid>
            {values.type !== MandatoryMinimumFieldType.Required && (
              <Grid item xs={12}>
                <FormSelect
                  name="type"
                  label="Types"
                  value={values.type}
                  onChange={handleInputChange}
                  options={types}
                  error={errors.type}
                  showSkeleton={isLoading}
                />
              </Grid>
            )}
            {values.type === MandatoryMinimumFieldType.Switch && (
              <>
                <Grid item pt={5} xs={2}>
                  <Typography>Switch On</Typography>
                </Grid>
                <Grid item pt={3} xs={10}>
                  <Switch
                    name="switchOn"
                    checked={values.switchOn}
                    onChange={handleSwicthChange}
                    value={true}
                  />
                </Grid>
              </>
            )}
            {values.type === MandatoryMinimumFieldType.Required && (
              <Grid item xs={12}>
                <FormTextArea
                  name="subTitle"
                  label="Description"
                  value={values.subTitle}
                  onChange={handleInputChange}
                  error={errors.subTitle}
                  placeholder=""
                />
              </Grid>
            )}
          </Grid>
        </Stack>

        <Stack
          direction="row"
          spacing={2}
          justifyContent="flex-end"
          alignItems="flex-start"
        >
          <FormCancelButton onClick={handleClose} isSubmitting={false} />
          <FormAcceptButton onClick={handleSave} isSubmitting={false} />
        </Stack>
      </Popup>
      <MatchWordConfirmModal
        onConfirm={deleteHandler}
        text={`Are you certain you want to delete this item?`}
        matchWord={"DELETE"}
        isDialogOpen={deleteModal}
        setIsDialogOpen={setDeleteModal}
      />
    </>
  );
};

export default MandatoryMinimumForm;
