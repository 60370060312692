import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { withTheme } from "@emotion/react";
import { NavigateFunction, useNavigate } from "react-router-dom";
import {
  Grid,
  AppBar as MuiAppBar,
  IconButton as MuiIconButton,
  Toolbar,
  Tooltip,
  Typography,
  Stack,
} from "@mui/material";
import {
  Apartment,
  PinDropOutlined,
  Menu as MenuIcon,
  LightbulbOutlined,
} from "@mui/icons-material";
import NavbarNotificationsDropdown from "./NavbarNotificationsDropdown";
import NavbarLanguagesDropdown from "./NavbarLanguagesDropdown";
import NavbarUserDropdown from "./NavbarUserDropdown";
import NavBarVersion from "./NavBarVersion";
import { Book } from "react-feather";
import useAuth from "src/hooks/useAuth";
import CompanyService from "src/services/companyService";
import { CompanyUser } from "src/ts/interfaces/company";
import useTheme from "src/hooks/useTheme";
import { useLog, usePermissions } from "src/hooks";
import { COUNTRIES, ROLES } from "src/constants";
import NavbarSuggestionBank from "./NavbarSuggestionBank";
import RecyclingIcon from "@mui/icons-material/Recycling";
import { Permission as PermissionTypes } from "src/ts/enums";
import NavbarCoupons from "./NavbarCoupons";

const AppBar = styled(MuiAppBar)`
  background: ${(props) => props.theme.header.background};
  color: ${(props) => props.theme.header.color};
`;

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;
type NavbarProps = {
  onDrawerToggle: React.MouseEventHandler<HTMLElement>;
};

interface glossaryProps {
  navigate: NavigateFunction;
}

const NavBarRecycleBin = (props: glossaryProps) => (
  <>
    <Tooltip title="Recycle Bin">
      <IconButton
        color="inherit"
        size="large"
        onClick={() => {
          props.navigate(`/app/recycleBin`);
        }}
      >
        <RecyclingIcon />
      </IconButton>
    </Tooltip>
  </>
);

const NavBarGlossaryComponent = (props: glossaryProps) => (
  <>
    <Tooltip title="Glossary">
      <IconButton
        color="inherit"
        size="large"
        onClick={() => {
          props.navigate(`/app/glossary`);
        }}
      >
        <Book />
      </IconButton>
    </Tooltip>
  </>
);

const NavbarSuggestionBankAdmin = (props: glossaryProps) => (
  <>
    <Tooltip title="Suggestion Bank">
      <IconButton
        color="inherit"
        onClick={() => {
          props.navigate(`/app/suggestionBankAdmin`);
        }}
        size="large"
      >
        <LightbulbOutlined />
      </IconButton>
    </Tooltip>
  </>
);

const Navbar: React.FC<NavbarProps> = ({ onDrawerToggle }) => {
  const { log } = useLog();
  const [companyData, setCompanyData] = useState<CompanyUser>();
  const { theme } = useTheme();
  const { user } = useAuth();
  let navigate = useNavigate();
  const { fullAccess, read } = usePermissions(
    PermissionTypes.Commitee_Suggestion_Box
  );
  const { read: readIdeas } = usePermissions(PermissionTypes.Ideas);
  const { fullAccess: fullAccessToProjects } = usePermissions(
    PermissionTypes.Project
  );
  useEffect(() => {
    const getComapanyData = async () => {
      try {
        const responesCompany = await CompanyService.companyUser(
          user!.companyId
        );
        setCompanyData(responesCompany.data);
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      }
    };
    getComapanyData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      <AppBar position="sticky" elevation={0}>
        <Toolbar>
          <Grid container alignItems="center">
            <Grid item sx={{ display: { xs: "block", md: "none" } }}>
              <IconButton
                color="inherit"
                aria-label="Open drawer"
                onClick={onDrawerToggle}
                size="large"
              >
                <MenuIcon />
              </IconButton>
            </Grid>
            <Grid item>
              <Stack direction="row" spacing={10}>
                <Stack direction="row" spacing={2} alignItems="center">
                  {" "}
                  <Apartment
                    sx={{ color: theme === "DARK" ? "white" : "black" }}
                  />
                  <Typography
                    variant="subtitle2"
                    sx={{ color: theme === "DARK" ? "white" : "black" }}
                  >
                    {`${companyData?.name} `}
                  </Typography>
                </Stack>
                <Stack direction="row" spacing={2} alignItems="center">
                  <PinDropOutlined
                    sx={{ color: theme === "DARK" ? "white" : "black" }}
                  />
                  <Typography
                    variant="subtitle2"
                    sx={{ color: theme === "DARK" ? "white" : "black" }}
                  >
                    {`${companyData?.city ?? ""}, ${
                      companyData?.countryId === COUNTRIES.USA
                        ? companyData?.state?.name ?? ""
                        : companyData?.stateName ?? ""
                    }, ${companyData?.country?.name ?? ""} `}
                  </Typography>
                </Stack>
              </Stack>
            </Grid>
            <Grid item xs />
            <Grid item>
              <NavbarCoupons />
              <NavbarNotificationsDropdown />
              {readIdeas &&
                (!fullAccess || fullAccess === undefined) &&
                (!read || read === undefined) && <NavbarSuggestionBank />}
              {(fullAccess || read) &&
                (user?.role === ROLES.SuperAdministrator ||
                  user?.role === ROLES.Trainer) && (
                  <NavbarSuggestionBankAdmin navigate={navigate} />
                )}
              {/* <NavBarDownload /> */}
              {fullAccessToProjects && <NavBarRecycleBin navigate={navigate} />}
              <NavBarGlossaryComponent navigate={navigate} />
              <NavBarVersion />
              <NavbarLanguagesDropdown />
              <NavbarUserDropdown />
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
};

export default withTheme(Navbar);
