import {
  Typography,
  Divider as MuiDivider,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Card as MuiCard,
  CardContent,
  Box,
} from "@mui/material";
import styled from "@emotion/styled";
import { spacing } from "@mui/system";
import { useAsyncMutation, useAsyncQuery, useAuth } from "src/hooks";
import { IPlaqueOrder } from "src/ts/interfaces";
import plaqueOrderService from "src/services/plaqueOrderService";
import { useEffect } from "react";
import { GridActionButton } from "src/components/gridControls";
import plaqueOrderDetailService from "src/services/plaqueOrderDetailService";
import React from "react";
import PurchaseSummary from "src/pages/Cart/PurchaseSummary";
import { currencyformatter } from "src/utils/format";
const Divider = styled(MuiDivider)(spacing);
const Card = styled(MuiCard)(spacing);
interface Props {
  projectId: string;
  setRefreshData: (e: boolean) => void;
  refreshData: boolean;
}

const CartItems = ({ projectId, setRefreshData, refreshData }: Props) => {
  const { user } = useAuth();
  const { execute: executeOrder, data: dataOrder } =
    useAsyncQuery<IPlaqueOrder>(plaqueOrderService.getByProjectId);

  const { execute: executeDelete, isSubmitting: isSubmittingDelete } =
    useAsyncMutation(plaqueOrderDetailService.remove, {
      successfulMessage: "Item was removed",
      onSuccess: () => {
        setRefreshData(!refreshData);
      },
    });

  useEffect(() => {
    const getData = async () => {
      await executeOrder(projectId);
    };
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId, refreshData]);

  const handleRemove = async (itemId: number) => {
    await executeDelete(projectId, itemId);
  };

  return (
    <>
      <Card mb={6}>
        <CardContent>
          <PurchaseSummary
            companyId={user?.companyId}
            total={dataOrder?.total ?? 0}
          ></PurchaseSummary>
        </CardContent>
      </Card>
      <Card mb={6}>
        <CardContent>
          <>
            {dataOrder &&
              dataOrder?.plaqueOrderDetail.map((item) => (
                <>
                  <List
                    sx={{
                      width: "100%",
                      maxWidth: 360,
                      bgcolor: "background.paper",
                    }}
                  >
                    <ListItem alignItems="flex-start">
                      <ListItemAvatar>
                        <Box
                          component="img"
                          sx={{
                            height: 40,
                            display: "block",
                            maxWidth: 40,
                            overflow: "hidden",
                            width: "100%",
                          }}
                          src={item.thumbByte ?? "/static/img/COVID1.png"}
                          alt={item.plaques.name}
                        />
                        <GridActionButton
                          type="delete"
                          onClick={() => {
                            handleRemove(item.plaquesId);
                          }}
                          tooltip="Remove"
                          disabled={isSubmittingDelete}
                        />
                      </ListItemAvatar>
                      <ListItemText
                        primary={item.plaques.name}
                        secondary={
                          <React.Fragment>
                            <Typography
                              sx={{ display: "inline" }}
                              component="span"
                              variant="body2"
                              color="text.primary"
                            >
                              Price: {currencyformatter.format(item.price)} -
                              Qty: {item.quantity}
                            </Typography>
                            <Typography
                              sx={{
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                display: "-webkit-box",
                                WebkitLineClamp: "3",
                                WebkitBoxOrient: "vertical",
                              }}
                            >
                              {item.plaques.description}
                            </Typography>
                          </React.Fragment>
                        }
                      />
                    </ListItem>
                    <Divider variant="inset" />
                  </List>
                </>
              ))}
            <Typography textAlign={"center"}>
              <Typography display={"inline-flex"} variant="subtitle2">
                Total:&nbsp;
              </Typography>
              <Typography display={"inline-flex"}>
                {currencyformatter.format(dataOrder?.total ?? 0)}
              </Typography>
            </Typography>
          </>
        </CardContent>
      </Card>
    </>
  );
};

export default CartItems;
