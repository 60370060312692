import { Box, FormLabel, Skeleton, Typography } from "@mui/material";

interface ReadOnlyFieldProps {
  label: string | React.ReactNode;
  value?: string;
  valueColor?: string;
  color?: string;
  showSkeleton?: boolean;
}

const FormReadOnlyField = ({
  label,
  value,
  valueColor,
  color,
  showSkeleton,
}: ReadOnlyFieldProps) => {
  return (
    <>
      {showSkeleton ? (
        <Skeleton height={50} />
      ) : (
        <Box
          pr={3}
          sx={{
            pb: { xs: 2, md: 2 },
            width: "100%",
          }}
          alignSelf="center"
        >
          <FormLabel sx={{ color: color }}>{label}</FormLabel>
          <Typography
            mt={label === "" ? 2 : 0}
            variant="body1"
            color={valueColor}
          >
            {value}
          </Typography>
        </Box>
      )}
    </>
  );
};

export default FormReadOnlyField;
