import { Grid, Typography } from "@mui/material";
import LocalEnhancedTable from "src/components/localTable/LocalTable";
import { ColumnType } from "src/types/enhancedTable";
import { useForm, useLog } from "src/hooks";
import { useEffect, useState } from "react";
import { FormText } from "src/components/formControls";
import { IProjectPictureLog } from "src/ts/interfaces";
import { pictureLogService } from "src/services";

const getColumns = (): ColumnType[] => {
  return [
    {
      id: "projectName",
      label: "Project Name",
      type: "string",
      sort: true,
    },
    {
      id: "buildingName",
      label: "Building Name",
      type: "string",
      sort: true,
    },
    {
      id: "projectStatus",
      label: "Project Status",
      type: "string",
      sort: true,
    },
    {
      id: "totalFileSize",
      label: "Size",
      type: "custom",
      sort: true,
      callback: (row: IProjectPictureLog) => {
        return (
          <>
            <Typography>
              {Intl.NumberFormat("en-US", {
                maximumFractionDigits: 2,
              }).format(row.totalFileSize)}{" "}
              KB
            </Typography>
          </>
        );
      },
    },
  ];
};

const initialValues = {
  id: 0,
  projectName: "",
  buildingName: "",
  projectStatus: "",
};

const TablePictureLogSummary = () => {
  const columns = getColumns();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [pictureLog, setPictureLog] = useState<IProjectPictureLog[]>([]);
  const { log } = useLog();

  const { values: filterValues, handleInputChange: handleFilterChange } =
    useForm(initialValues, true, []);

  useEffect(() => {
    const getData = async () => {
      try {
        setIsSubmitting(true);
        const request = await pictureLogService.getPictureLogSummary();
        setPictureLog(request.data);
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      } finally {
        setIsSubmitting(false);
      }
    };

    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Grid item xs={12}>
        <Grid container padding={"20px"}>
          <Grid item xs={12}>
            <FormText
              name="name"
              label="Search"
              value={filterValues.name}
              onChange={handleFilterChange}
            />
          </Grid>
        </Grid>

        <LocalEnhancedTable<IProjectPictureLog>
          refreshGrid={true}
          columns={columns}
          data={pictureLog}
          showSkeleton={isSubmitting}
          query={filterValues.name}
          defaultRowPerPage={10}
        />
      </Grid>
    </>
  );
};

export default TablePictureLogSummary;
