import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import {
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  CardContent,
  Typography,
  Tab,
  Tabs,
  Grid,
} from "@mui/material";
import { spacing } from "@mui/system";
import useForm, { Form } from "src/hooks/useForm";
import FormText from "src/components/formControls/FormText";
import { FormButton, FormDatePicker } from "src/components/formControls";
import { FilterType } from "./types/FilterTypes";
import NewTicket from "./NewTicket";
import InProgressTicket from "./InProgressTicket";
import ClosedTicket from "./ClosedTicket";
import moment from "moment";
import { useAppDispatch, useAppSelector } from "src/hooks";
import { NotificationService } from "src/services";
import { signalrActions } from "src/redux/slices/signalrActions";

const Divider = styled(MuiDivider)(spacing);
const Card = styled(MuiCard)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const initialValues: FilterType = {
  filter: "",
  from: moment().startOf("month").format("MM/DD/yyyy"),
  to: moment().format("MM/DD/yyyy"),
};

function TicketManagment() {
  const [value, setValue] = React.useState(0);
  const dispatch = useAppDispatch();
  const [refreshGrid, setRefreshGrid] = useState(false);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const { values, errors, handleInputChange } = useForm(
    initialValues,
    false,
    []
  );

  const signalRStatus = useAppSelector((state) => state.signalR);

  useEffect(() => {
    NotificationService.readByType(2);
    dispatch(signalrActions.coutNewTicket(0));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (signalRStatus.ticketList > 0) {
      setRefreshGrid(!refreshGrid);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signalRStatus.ticketList]);

  const handleRefreshGrid = () => {
    setRefreshGrid(!refreshGrid);
  };

  return (
    <React.Fragment>
      <Helmet title="Ticket Support Management" />
      <Typography variant="h3" gutterBottom display="inline">
        Ticket Support Management
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/">
          Dashboard
        </Link>
        <Typography>Tickets</Typography>
      </Breadcrumbs>

      <Divider my={6} />
      <Card mb={6}>
        <CardContent>
          <Grid
            container
            spacing={10}
            sx={{ borderBottom: 1, borderColor: "divider", marginBottom: 5 }}
          >
            <Grid item xs={6}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                <Tab label="New" id="1" />
                <Tab label="In Progress" id="2" />
                <Tab label="Closed " id="3" />
              </Tabs>
            </Grid>
          </Grid>

          <Grid container spacing={10}>
            <Grid item xs={12}>
              <Form>
                <Grid container spacing={5}>
                  <Grid item xs={3}>
                    <FormDatePicker
                      label={
                        value === 0
                          ? "Created Date From"
                          : value === 1
                          ? "Assigned Date From"
                          : "Closed Date From "
                      }
                      name="from"
                      disablePast={false}
                      value={values.from}
                      onChange={handleInputChange}
                      error={errors.from}
                      fullWidth={true}
                    />
                  </Grid>
                  <Grid item xs={3}></Grid>
                  <Grid item xs={3}>
                    <FormDatePicker
                      label={
                        value === 0
                          ? "Created Date To"
                          : value === 1
                          ? "Assigned Date To"
                          : "Closed Date To "
                      }
                      name="to"
                      disablePast={false}
                      value={values.to}
                      onChange={handleInputChange}
                      error={errors.to}
                      fullWidth={true}
                    />
                  </Grid>
                  <Grid item xs={9}>
                    <FormText
                      name="filter"
                      label="Type text to search"
                      value={values.filter}
                      onChange={handleInputChange}
                      error={errors.filter}
                    />
                  </Grid>

                  <Grid item xs={2}>
                    <FormButton
                      color="primary"
                      text="Search"
                      variant="contained"
                      size="medium"
                      onClick={handleRefreshGrid}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    {value === 0 && (
                      <NewTicket
                        filters={values}
                        refresh={refreshGrid}
                        onRefreshGrid={handleRefreshGrid}
                      />
                    )}
                    {value === 1 && (
                      <InProgressTicket
                        filters={values}
                        refresh={refreshGrid}
                        onRefreshGrid={handleRefreshGrid}
                      />
                    )}

                    {value === 2 && (
                      <ClosedTicket filters={values} refresh={refreshGrid} />
                    )}
                  </Grid>
                </Grid>
              </Form>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </React.Fragment>
  );
}

export default TicketManagment;
