import { useEffect, useState } from "react";
import {
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from "@mui/material";
import Popup from "src/components/Popup";
import {
  FormAcceptButton,
  FormCancelButton,
  FormDatePicker,
  FormTimePicker,
} from "src/components/formControls";
import { AuditorOptionRemove } from "src/constants";
import { buildingService } from "src/services";
import { BuildingDTO } from "src/ts/interfaces";
import projectAuditorService from "src/services/project/projectAuditorService";
import { useLog } from "src/hooks";
import TechnicianAssigmentProjectService from "src/services/project/TechnicianAssigmentProjectService";
import { ITechnicianAssigmentProject } from "src/ts/interfaces/technicianAssigmentProject";

interface ValidateAuditorsPopUpProps {
  showValidateAuditorsPopUp: boolean;
  setShowValidateAuditorsPopUp: (val: boolean) => void;
  projectId: number;
  name: string;
  userId: number;
  toProjectId: number;
  save: () => void;
  scheduled: (userId: number) => void;
}

export default function ValidateAuditorsPopUp({
  showValidateAuditorsPopUp,
  setShowValidateAuditorsPopUp,
  projectId,
  name,
  userId,
  toProjectId,
  save,
  scheduled,
}: ValidateAuditorsPopUpProps) {
  const value = {
    AuditorOptionToRemove: AuditorOptionRemove.Inmediately,
    removeDate: null,
    removeTime: null,
  };

  const [values, setValues] = useState(value);
  const [building, setBuilding] = useState<BuildingDTO>();
  const { log } = useLog();
  useEffect(() => {
    const getBuildingData = async () => {
      var buildingResult = await buildingService.getBuildingByProject(
        projectId
      );
      setBuilding(buildingResult.data);
    };
    if (projectId > 0) {
      getBuildingData();
    }
  }, [projectId]);

  const handleClosePopUp = () => {
    setShowValidateAuditorsPopUp(false);
  };

  const handleSave = async () => {
    if (values.AuditorOptionToRemove === AuditorOptionRemove.Inmediately) {
      await projectAuditorService.deleteAuditor(projectId, userId);
      log.success(
        `Auditor/Technician ${name} has been succesfully remove from the project assigned.`
      );
      save();
    } else {
      const assigmentProject: ITechnicianAssigmentProject = {
        id: 0,
        fromProjectId: projectId,
        toProjectId: toProjectId,
        assigmentOptionsId: values.AuditorOptionToRemove,
        technicianId: userId,
        removeDate: new Date(`${values.removeDate} ${values.removeTime}`),
      };

      await TechnicianAssigmentProjectService.createAssigment(assigmentProject);
      scheduled(userId);
      log.success(`The option has been succesfully saved.`);
    }
    setShowValidateAuditorsPopUp(false);
  };

  const handleClose = () => {
    setShowValidateAuditorsPopUp(false);
  };

  return (
    <>
      <Popup
        title="Validate Auditors"
        openPopup={showValidateAuditorsPopUp}
        setOpenPopup={setShowValidateAuditorsPopUp}
        onClose={handleClosePopUp}
        size="sm"
      >
        <>
          <Typography variant="body1">
            {name} is currently assigned to another project.
          </Typography>
          <Typography variant="body1">
            Project Name: {building?.name}
          </Typography>
          <Typography variant="body1">Please select an option:</Typography>
          <Grid pt={4}>
            <RadioGroup
              name={"name"}
              value={values.AuditorOptionToRemove}
              onChange={function (e: any): void {
                values.AuditorOptionToRemove = e.target.value;
                setValues({ ...values });
              }}
              defaultValue={AuditorOptionRemove.Inmediately}
            >
              <FormControlLabel
                sx={{ pb: 2 }}
                key={AuditorOptionRemove.Inmediately}
                value={AuditorOptionRemove.Inmediately}
                control={<Radio />}
                label={
                  <>
                    <Typography sx={{ display: "inline" }}>
                      Remove this Technician/Auditor from their assigned project
                    </Typography>{" "}
                    <Typography
                      color="primary.main"
                      sx={{ display: "inline" }}
                      style={{ fontWeight: "bold" }}
                    >
                      inmediately
                    </Typography>{" "}
                    <Typography sx={{ display: "inline" }}>
                      and assign them to this project. If technician/Auditor is
                      currently on an active project and attemps to move forward
                      with entering data, this Technician/Auditor will not be
                      able to enter any additional information.
                    </Typography>
                  </>
                }
              />
              <FormControlLabel
                key={AuditorOptionRemove.AtSomeDateTime}
                value={AuditorOptionRemove.AtSomeDateTime}
                control={<Radio />}
                label={
                  <>
                    <Typography sx={{ display: "inline" }}>
                      Remove this Technician/Auditor from their assigned project
                      and assign them to this project on the following
                    </Typography>{" "}
                    <Typography
                      color="primary.main"
                      sx={{ display: "inline" }}
                      style={{ fontWeight: "bold" }}
                    >
                      date and time:
                    </Typography>
                  </>
                }
              />
              <Grid container p={4}>
                <Grid item xs={6}>
                  <FormDatePicker
                    name="date"
                    label="Date"
                    value={values.removeDate}
                    disablePast={true}
                    onChange={function (e: any): void {
                      values.removeDate = e.target.value;
                      setValues({ ...values });
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormTimePicker
                    label="Time"
                    value={values.removeTime}
                    onChange={function (e: any): void {
                      values.removeTime = e.target.value;
                      setValues({ ...values });
                    }}
                    name={"time"}
                  />
                </Grid>
              </Grid>

              <FormControlLabel
                sx={{ pb: 2 }}
                key={AuditorOptionRemove.PrevProjectCompleted}
                value={AuditorOptionRemove.PrevProjectCompleted}
                control={<Radio />}
                label={
                  <>
                    <Typography sx={{ display: "inline" }}>
                      Remove this Technician/Auditor from their assigned project
                      immediately and assign them to this project as soon as the
                    </Typography>{" "}
                    <Typography
                      color="primary.main"
                      sx={{ display: "inline" }}
                      style={{ fontWeight: "bold" }}
                    >
                      previous project gets completed.
                    </Typography>
                  </>
                }
              />
              <FormControlLabel
                key={AuditorOptionRemove.EndWorkDay}
                value={AuditorOptionRemove.EndWorkDay}
                control={<Radio />}
                label={
                  <>
                    <Typography sx={{ display: "inline" }}>
                      Remove this Technician/Auditor from their assigned project
                      immediately and assign them to this project as soon as
                      they select
                    </Typography>{" "}
                    <Typography
                      color="primary.main"
                      sx={{ display: "inline" }}
                      style={{ fontWeight: "bold" }}
                    >
                      'End Workday'.
                    </Typography>
                  </>
                }
              />
            </RadioGroup>
          </Grid>
        </>

        <Stack
          direction="row"
          spacing={2}
          justifyContent="flex-end"
          alignItems="flex-start"
        >
          <Typography>Do you want to proceed with the change?</Typography>
          <FormAcceptButton onClick={handleSave} isSubmitting={false} />
          <FormCancelButton onClick={handleClose} isSubmitting={false} />
        </Stack>
      </Popup>
    </>
  );
}
