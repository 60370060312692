const getVersion = async () => {
  const response = await fetch(
    `${process.env.REACT_APP_AMP_HOST}/version.json`,
    {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  );

  const data: { version: string } = await response.json();
  return data.version;
};

const versionService = {
  getVersion,
};

export default versionService;
