import { IKeyValue, UserAuth } from "../ts/interfaces";
import {
  IChangePasswordBindingModel,
  IResetPassword,
  INewUserAccount,
  ISignUp,
} from "../ts/interfaces/account";
import axios from "../utils/axios";

const getAccountUserByGuid = (userGuid: string) => {
  return axios.get<UserAuth>(`Account/user/${userGuid}`);
};

const confirm = (userGuid: string) => {
  return axios.get(`Account/ConfirmEmailGuid?guid=${userGuid}`);
};

const chagePassword = (credentials: IChangePasswordBindingModel) => {
  return axios.post<any>("account/ChangePassword", credentials);
};

const getRolesByUser = async (userName: string) => {
  return await axios.get(`Account/CheckRoles?userName=${userName}`);
};

const register = async (data: ISignUp) => {
  return await axios.post(`Account/RegisterArchitec?newRoute=true`, data);
};

const resetPassword = async (data: IResetPassword) => {
  return await axios.post(`Account/RecoverPassword`, data);
};

const newAccountPassword = async (data: INewUserAccount) => {
  return await axios.post(`Account/RecoverPassword`, data);
};

const getRoleKeyValues = (userName: string) => {
  return new Promise<IKeyValue<number, string>[]>((resolve, reject) => {
    axios
      .get<Array<string>>(`Account/CheckRoles?userName=${userName}`)
      .then((response) => {
        const keyValuePair: IKeyValue<number, string>[] = response.data.map(
          (role, index) => {
            return {
              key: index,
              value: role,
            };
          }
        );

        resolve(keyValuePair);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const accountService = {
  getAccountUserByGuid,
  chagePassword,
  getRolesByUser,
  getRoleKeyValues,
  register,
  confirm,
  resetPassword,
  newAccountPassword,
};

export default accountService;
