import { IProjectBidOtherConfiguration } from "src/ts/interfaces/project/projectDto";
import axios from "src/utils/axios";

const get = (projectId: number) => {
  return axios.get<IProjectBidOtherConfiguration[]>(
    `ProjectBidOther?projectId=${projectId}`
  );
};

const putOtherBid = (id: number, description: string) => {
  return axios.put(`ProjectBidOther?id=${id}&description=${description}`);
};

const deleteOtherBid = (id: number) => {
  return axios.delete(`ProjectBidOther?id=${id}`);
};

const postOtherBid = (projectId: number, description: string) => {
  return axios.post(
    `ProjectBidOther?projectId=${projectId}&description=${description}`
  );
};

const projectBidOtherService = {
  get,
  putOtherBid,
  deleteOtherBid,
  postOtherBid,
};

export default projectBidOtherService;
