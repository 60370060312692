import React, { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Grid, Typography, Stack } from "@mui/material";
import { ProjectDTO } from "src/ts/interfaces/project/projectDto";
import {
  useAsyncMutation,
  useAsyncQuery,
  useAuth,
  usePermissions,
} from "src/hooks";
import ToleranceInput from "./ToleranceInput";
import useFormTyped from "src/hooks/useFormTyped";
import { Validator } from "src/ts/types";
import { FormButton } from "src/components/formControls";
import DialogMessagePopup from "src/components/DialogMessagePopup";
import projectService from "src/services/project/projectService";
import { ProjectParams } from "../../createEditProject/components/ProjectForm";
import { ARCHITECTDATA, ROLES } from "src/constants";
import UseMyPreferredTemplatePopUp from "./UseMyPreferredTemplatePopUp";
import templateServices from "src/services/templateServices";
import WithoutPreferredTemplatePopUp from "./WithoutPreferredTemplatePopUp";
import SelectTemplatePopUp from "./SelectTemplatePopUp";
import projectReportGroupService from "src/services/ProjectReportGroupService";
import { IReportGroup } from "src/ts/interfaces";
import FormNextButton from "src/components/formControls/FormNextButton";
import FormBackButton from "src/components/formControls/FormBackButton";
import { Permission as PermissionTypes } from "src/ts/enums";
interface Toleraces {
  diffuserRegisterGrilleToleranceTop?: number | null;
  diffuserRegisterGrilleToleranceBottom?: number | null;
  requiredCFMSystemToleranceTop?: number | null;
  requiredCFMSystemToleranceBottom?: number | null;
  hydronicTerminalDevicesToleranceTop?: number | null;
  hydronicTerminalDevicesToleranceBottom?: number | null;
  requiredGPMSystemToleranceTop?: number | null;
  requiredGPMSystemToleranceBottom?: number | null;
  outsideAirVentilationRateTop?: number | null;
  outsideAirVentilationRateBottom?: number | null;

  energyDiffuserRegisterGrilleToleranceTop?: number | null;
  energyDiffuserRegisterGrilleToleranceBottom?: number | null;
  energyRequiredCFMSystemToleranceTop?: number | null;
  energyRequiredCFMSystemToleranceBottom?: number | null;
  energyHydronicTerminalDevicesToleranceTop?: number | null;
  energyHydronicTerminalDevicesToleranceBottom?: number | null;
  energyRequiredGPMSystemToleranceTop?: number | null;
  energyRequiredGPMSystemToleranceBottom?: number | null;
  energyOutsideAirVentilationRateTop?: number | null;
  energyOutsideAirVentilationRateBottom?: number | null;
}

interface Props {
  project: ProjectDTO;
  setProject: (project: ProjectDTO) => void;
  readOnly?: boolean;
  disable?: boolean;
  isTemplate?: boolean;
  useTemplate?: boolean;
  isPartOfProject?: boolean;
  isLoading?: boolean;
  save?: boolean;
  onSave?: () => void;
}

const Tolerances = ({
  project,
  setProject,
  disable,
  isTemplate,
  readOnly,
  useTemplate,
  isPartOfProject,
  isLoading,
  save,
  onSave,
}: Props) => {
  let params = useParams<ProjectParams>();

  const projectId = parseInt(
    params?.projectId === undefined ? "0" : params?.projectId
  );

  const { user } = useAuth();
  const navigate = useNavigate();

  const [showSaveTolerancesModal, setShowSaveTolerancesModal] = useState(false);
  const [useMyPreferredTemplateShowPopUp, setUseMyPreferredTemplateShowPopUp] =
    useState(false);
  const [withoutPreferredTemplate, setWithoutPreferredTemplate] =
    useState(false);
  const [selectTemplateShowPopUp, setSelectTemplateShowPopUp] = useState(false);
  const { fullAccess } = usePermissions(PermissionTypes.Project);
  const validate: any = (fieldValues = values) => {
    let temp: Record<string, string> = { ...errors };

    const valueRequiredMessage = "Value is required";
    const valueGreatThan0Message = "The value can't be less than 0";
    const props = [
      "diffuserRegisterGrilleToleranceTop",
      "diffuserRegisterGrilleToleranceBottom",
      "requiredCFMSystemToleranceTop",
      "requiredCFMSystemToleranceBottom",
      "hydronicTerminalDevicesToleranceTop",
      "hydronicTerminalDevicesToleranceBottom",
      "requiredGPMSystemToleranceTop",
      "requiredGPMSystemToleranceBottom",
      "outsideAirVentilationRateTop",
      "outsideAirVentilationRateBottom",
      "energyDiffuserRegisterGrilleToleranceTop",
      "energyDiffuserRegisterGrilleToleranceBottom",
      "energyRequiredCFMSystemToleranceTop",
      "energyRequiredCFMSystemToleranceBottom",
      "energyHydronicTerminalDevicesToleranceTop",
      "energyHydronicTerminalDevicesToleranceBottom",
      "energyRequiredGPMSystemToleranceTop",
      "energyRequiredGPMSystemToleranceBottom",
      "energyOutsideAirVentilationRateTop",
      "energyOutsideAirVentilationRateBottom",
    ];

    props.map((prop) => {
      temp[prop] = new Validator(fieldValues, prop)
        .isRequired(valueRequiredMessage)
        .greatThanOrEquals(0, valueGreatThan0Message)
        .toString();
      return null;
    });

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  //query
  const { values, setValues, errors, setErrors, handleInputChange } =
    useFormTyped<Toleraces>({}, false, validate);

  const { execute: preferredName, data: preferredNameData } =
    useAsyncQuery<string>(templateServices.getPreferredName);

  const { execute: executeGroups } = useAsyncQuery<IReportGroup>(
    projectReportGroupService.getProjectReportGrupo
  );

  useEffect(() => {
    if (save) saveTolerancesHandler();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [save]);

  useEffect(() => {
    const getPreferredName = () => {
      if (user?.role === ROLES.Architect || user?.role === ROLES.ArchitectAux) {
        preferredName();

        executeGroups(projectId);
      }
    };
    getPreferredName();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setValues({
      ...project,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project]);

  const minimumDate: number | null = null;

  const saveTolerancesHandler = () => {
    if (!validate()) return;

    if (minimumDate || !isTemplate) {
      setShowSaveTolerancesModal(true);
    } else if (isTemplate) {
      confirmSaveTolerancesHandler();
    }
  };

  const { execute: saveTolerances, isSubmitting: savingTolerances } =
    useAsyncMutation(projectService.saveTolerances, {
      successfulMessage: "Tolerances were saved",
      errorMessage: "An error was ocurred",
      onSuccess: () => {
        if (onSave) onSave();
      },
    });

  const confirmSaveTolerancesHandler = async () => {
    const projectWithTolerances = {
      ...project,
      ...values,
      tolerancesWereSaved: true,
    };

    setProject({
      ...projectWithTolerances,
    });

    await saveTolerances(projectId, projectWithTolerances);
  };

  const modalPreferredTemplateHandler = () => {
    if (preferredNameData !== "") {
      setUseMyPreferredTemplateShowPopUp(true);
    } else {
      setWithoutPreferredTemplate(true);
    }
  };

  const updateHandler = async () => {
    const projectWithTolerances = {
      ...project,
      ...values,
    };

    await saveTolerances(projectId, projectWithTolerances);
  };

  const disableTolerances = useMemo(() => {
    return (
      !fullAccess ||
      project.templateWasUsed ||
      (project.tolerancesWereSaved && projectId > 0) ||
      (project?.projectStatus?.name !== "New contest" &&
        (user?.role === "Architect" ||
          user?.role === "ArchitectAux" ||
          user?.role === "SysAdmin")) ||
      readOnly ||
      (!isPartOfProject &&
        user?.role !== ROLES.Architect &&
        user?.role !== ROLES.SysAdmin)
    );
  }, [
    isPartOfProject,
    project?.projectStatus?.name,
    project.templateWasUsed,
    project.tolerancesWereSaved,
    projectId,
    readOnly,
    user?.role,
    fullAccess,
  ]);

  const hideSaveButton = useMemo(() => {
    return (
      (project.tolerancesWereSaved && !isTemplate) ||
      user?.role === ROLES.Architect ||
      user?.role === ROLES.ArchitectAux ||
      user?.role === ROLES.SysAdmin ||
      disable ||
      (!isPartOfProject &&
        user?.role !== ROLES.Architect &&
        user?.role !== ROLES.ArchitectAux &&
        user?.role !== ROLES.SysAdmin)
    );
  }, [
    disable,
    isPartOfProject,
    isTemplate,
    project.tolerancesWereSaved,
    user?.role,
  ]);
  const toleranceWidth = useMemo(() => {
    return project?.isInfectiousControlProject ? 10 : 6;
  }, [project?.isInfectiousControlProject]);
  const isCovidProject = project.isInfectiousControlProject;

  const hideUsePreferredTemplate = useMemo(() => {
    return (
      projectId !== 0 &&
      user?.role === ROLES.Architect &&
      project?.projectStatus?.name === "New contest" &&
      !isCovidProject &&
      useTemplate
    );
  }, [
    isCovidProject,
    project?.projectStatus?.name,
    projectId,
    useTemplate,
    user?.role,
  ]);

  const filterByTab = useMemo(() => {
    for (const key in ARCHITECTDATA) {
      if (ARCHITECTDATA[key].queryData === project.projectStatusId) {
        return ARCHITECTDATA[key].name;
      }
    }
    return "all";
  }, [project.projectStatusId]);

  return (
    <>
      <Typography
        gutterBottom
        variant="h4"
        component="div"
        textAlign={"center"}
      >
        {!readOnly ? (
          " Tolerances"
        ) : (
          <>
            <b>Required Tolerances for this project</b>
          </>
        )}
      </Typography>
      {/* <> */}
      <Grid container>
        <Grid item xs={12} sm={12} lg={toleranceWidth}>
          <Grid container spacing={3}>
            <Grid item xs={8}>
              <Typography
                gutterBottom
                variant="h6"
                component="div"
                align="center"
                color="success.main"
                textAlign={"right"}
              >
                Energy Project Tolerances
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <ToleranceInput
                label="Diffuser, Register & Grille CFM Tolerance"
                topName="energyDiffuserRegisterGrilleToleranceTop"
                bottonName="energyDiffuserRegisterGrilleToleranceBottom"
                topValue={values?.energyDiffuserRegisterGrilleToleranceTop}
                bottomValue={
                  values?.energyDiffuserRegisterGrilleToleranceBottom
                }
                onChange={handleInputChange}
                error={errors}
                disable={true}
                showskeleton={isLoading}
              />
            </Grid>
            <Grid item xs={12}>
              <ToleranceInput
                label="Total System CFM Tolerance"
                topName="energyRequiredCFMSystemToleranceTop"
                bottonName="energyRequiredCFMSystemToleranceBottom"
                topValue={values?.energyRequiredCFMSystemToleranceTop}
                bottomValue={values?.energyRequiredCFMSystemToleranceBottom}
                onChange={handleInputChange}
                error={errors}
                disable={true}
                showskeleton={isLoading}
              />
            </Grid>
            <Grid item xs={12}>
              <ToleranceInput
                label="Outside Air Ventilation Rate Tolerance"
                topName="energyOutsideAirVentilationRateTop"
                bottonName="energyOutsideAirVentilationRateBottom"
                topValue={values?.energyOutsideAirVentilationRateTop}
                bottomValue={values?.energyOutsideAirVentilationRateBottom}
                onChange={handleInputChange}
                error={errors}
                disable={true}
                showskeleton={isLoading}
              />
            </Grid>
            <Grid item xs={12}>
              <ToleranceInput
                label="Hydronic Terminal Devices GPM Tolerance"
                topName="energyHydronicTerminalDevicesToleranceTop"
                bottonName="ienergyHydronicTerminalDevicesToleranceBottom"
                topValue={values?.energyHydronicTerminalDevicesToleranceTop}
                bottomValue={
                  values?.energyHydronicTerminalDevicesToleranceBottom
                }
                onChange={handleInputChange}
                error={errors}
                disable={true}
                showskeleton={isLoading}
              />
            </Grid>
            <Grid item xs={12}>
              <ToleranceInput
                label="Total System GPM Tolerance"
                topName="energyRequiredGPMSystemToleranceTop"
                bottonName="energyRequiredGPMSystemToleranceBottom"
                topValue={values?.energyRequiredGPMSystemToleranceTop}
                bottomValue={values?.energyRequiredGPMSystemToleranceBottom}
                onChange={handleInputChange}
                error={errors}
                disable={true}
                showskeleton={isLoading}
              />
            </Grid>
          </Grid>
        </Grid>

        {!project?.isInfectiousControlProject && (
          <Grid item xs={12} sm={12} lg={6}>
            <Grid container spacing={3}>
              <Grid item xs={8}>
                <Typography
                  gutterBottom
                  variant="h6"
                  component="div"
                  align="center"
                  color="info.main"
                  textAlign={"right"}
                >
                  T&B Project Tolerances
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <ToleranceInput
                  label="Diffuser, Register & Grille CFM Tolerance"
                  topName="diffuserRegisterGrilleToleranceTop"
                  bottonName="diffuserRegisterGrilleToleranceBottom"
                  topValue={values?.diffuserRegisterGrilleToleranceTop}
                  bottomValue={values?.diffuserRegisterGrilleToleranceBottom}
                  onChange={handleInputChange}
                  error={errors}
                  disable={disableTolerances}
                  showskeleton={isLoading}
                />
              </Grid>
              <Grid item xs={12}>
                <ToleranceInput
                  label="Total System CFM Tolerance"
                  topName="requiredCFMSystemToleranceTop"
                  bottonName="requiredCFMSystemToleranceBottom"
                  topValue={values?.requiredCFMSystemToleranceTop}
                  bottomValue={values?.requiredCFMSystemToleranceBottom}
                  onChange={handleInputChange}
                  error={errors}
                  disable={disableTolerances}
                  showskeleton={isLoading}
                />
              </Grid>
              <Grid item xs={12}>
                <ToleranceInput
                  label="Outside Air Ventilation Rate Tolerance"
                  topName="outsideAirVentilationRateTop"
                  bottonName="outsideAirVentilationRateBottom"
                  topValue={values?.outsideAirVentilationRateTop}
                  bottomValue={values?.outsideAirVentilationRateBottom}
                  onChange={handleInputChange}
                  error={errors}
                  disable={disableTolerances}
                  showskeleton={isLoading}
                />
              </Grid>
              <Grid item xs={12}>
                <ToleranceInput
                  label="Hydronic Terminal Devices GPM Tolerance"
                  topName="hydronicTerminalDevicesToleranceTop"
                  bottonName="hydronicTerminalDevicesToleranceBottom"
                  topValue={values?.hydronicTerminalDevicesToleranceTop}
                  bottomValue={values?.hydronicTerminalDevicesToleranceBottom}
                  onChange={handleInputChange}
                  error={errors}
                  disable={disableTolerances}
                  showskeleton={isLoading}
                />
              </Grid>
              <Grid item xs={12}>
                <ToleranceInput
                  label="Total System GPM Tolerance"
                  topName="requiredGPMSystemToleranceTop"
                  bottonName="requiredGPMSystemToleranceBottom"
                  topValue={values?.requiredGPMSystemToleranceTop}
                  bottomValue={values?.requiredGPMSystemToleranceBottom}
                  onChange={handleInputChange}
                  error={errors}
                  disable={disableTolerances}
                  showskeleton={isLoading}
                />
              </Grid>
            </Grid>
          </Grid>
        )}

        <Grid
          container
          direction="row"
          alignItems={"center"}
          justifyContent={"center"}
        >
          {!hideSaveButton && (
            <FormButton
              text="Save Tolerances"
              size="small"
              onClick={saveTolerancesHandler}
            />
          )}
          {hideUsePreferredTemplate && (
            <Stack direction={"row"} spacing={2} mt={2}>
              <FormButton
                text="Use My Preferred Template"
                size="small"
                onClick={modalPreferredTemplateHandler}
                disabled={project.templateWasUsed}
              />
              <FormButton
                text="Use Template"
                size="small"
                onClick={() => {
                  setSelectTemplateShowPopUp(true);
                }}
                disabled={project.templateWasUsed}
              />
            </Stack>
          )}
        </Grid>
        <Grid container justifyContent={"center"}>
          {ROLES.Architect && !readOnly && useTemplate && (
            <Stack
              direction={"row"}
              spacing={4}
              mt={5}
              justifyContent={"center"}
            >
              <FormBackButton
                size="small"
                text="Previous Step"
                color="primary"
                variant="outlined"
                onClick={() => {
                  navigate(
                    `/app/ProjectWizard/Edit/${project?.id}/${user?.companyId}`
                  );
                }}
                isSubmitting={false}
              />
              <FormButton
                size="small"
                text="Close"
                variant="outlined"
                onClick={() => {
                  navigate(
                    `/app/ProjectArchitect/${user?.companyId}/${filterByTab}`
                  );
                }}
              />
              <FormNextButton
                size="small"
                text="Next Step"
                color="primary"
                variant="outlined"
                onClick={() => {
                  updateHandler();
                  navigate(
                    `/app/StudiesReport/Edit/${project?.id}/${user?.companyId}`
                  );
                }}
                isSubmitting={false}
              />
            </Stack>
          )}
        </Grid>
      </Grid>
      <DialogMessagePopup
        title="Are you sure?"
        text="Tolerances can't be changed once you select the accept button"
        showPopup={showSaveTolerancesModal}
        setShowPopup={setShowSaveTolerancesModal}
        onSave={confirmSaveTolerancesHandler}
        isSubmitting={savingTolerances}
      />
      {useMyPreferredTemplateShowPopUp && (
        <UseMyPreferredTemplatePopUp
          setUseMyPreferredTemplateShowPopUp={
            setUseMyPreferredTemplateShowPopUp
          }
          useMyPreferredTemplateShowPopUp={useMyPreferredTemplateShowPopUp}
          projectId={project?.id}
          setSelectTemplateShowPopUp={setSelectTemplateShowPopUp}
          project={project}
          setProject={setProject}
        />
      )}
      {withoutPreferredTemplate && (
        <WithoutPreferredTemplatePopUp
          setWithoutPreferredTemplate={setWithoutPreferredTemplate}
          withoutPreferredTemplate={withoutPreferredTemplate}
        />
      )}
      {selectTemplateShowPopUp && (
        <SelectTemplatePopUp
          setSelectTemplateShowPopUp={setSelectTemplateShowPopUp}
          selectTemplateShowPopUp={selectTemplateShowPopUp}
          projectId={project?.id}
          project={project}
          setProject={setProject}
        />
      )}
    </>
  );
};

export default Tolerances;
