export const getQueryString = (filters: Record<string, any>) => {
  if (!filters) return "";

  return Object.keys(filters)
    .filter((key) => {
      return filters[key] !== null && filters[key] !== Number.MIN_SAFE_INTEGER;
    })
    .map((key) => {
      return `${key}=${filters[key]}`;
    })
    .join("&");
};
