import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import {
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  CardContent,
  Typography,
  Stack,
  Chip,
} from "@mui/material";
import { spacing } from "@mui/system";
import { FormButton } from "../../components/formControls";
import { ColumnType } from "../../types/enhancedTable";
import { IStorage } from "src/ts/interfaces/storage";
import LocalEnhancedTable from "src/components/localTable/LocalTable";
import systemCostCatalogService from "src/services/systemCostCatalogService";
import WalletService from "src/services/walletService";
import { useAsyncQuery, useAuth, useLog, usePermissions } from "src/hooks";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import ReviewOrderPopUp from "./components/ReviewOrderPopUp";
import InsufficientFundsPopUp from "./components/InsufficientFundsPopUp";
import NotDefaultCardPopUp from "./components/NotDefaultCardPopUp";
import { Permission as PermissionTypes } from "src/ts/enums";
import { TrainingMessage } from "src/constants";
import FormButtonTooltip from "src/components/formControls/FormButtonTooltip";
const Divider = styled(MuiDivider)(spacing);
const Card = styled(MuiCard)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const PurchaseStorage = () => {
  const [showPopUp, setShowPopUp] = useState(false);
  const [getInformationStorage, setGetInformationStorage] =
    useState<IStorage>();
  const [insufficientFundsShowPopUp, setInsufficientFundsShowPopUp] =
    useState(false);
  const [notDefaultCardShowPopUp, setNotDefaultCardShowPopUp] = useState(false);
  const [submit, setSubmit] = useState(false);

  const { user } = useAuth();
  const { log } = useLog();

  const { execute, data, isLoading } = useAsyncQuery<IStorage[]>(
    systemCostCatalogService.getStorageValues
  );
  const { demo, readOnly } = usePermissions(
    PermissionTypes.Purchase_Storage_Button
  );
  const { execute: walletService, data: dataWalletBalance } =
    useAsyncQuery<number>(WalletService.getWalletBalance);

  useEffect(() => {
    const getInformation = async () => {
      execute("Space");
      walletService(user?.companyId);
    };
    getInformation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const getBalance = async () => {
      walletService(user?.companyId);
      log.success("Wallet updated");
      log.success("Wallet log was added (Purchase successfully applied)");
      setSubmit(false);
    };
    if (submit === true) getBalance();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submit]);

  const getColumns = (
    buyHandler: () => void,
    setGetInformationStorage: (val: IStorage) => void
  ): ColumnType[] => [
    {
      id: "name",
      label: "Name",
      type: "string",
      sort: true,
    },
    {
      id: "pricing",
      label: "Price",
      type: "custom",
      sort: true,
      minWidth: "140px",
      callback: (row: IStorage) => (
        <Typography>
          <b>{`USD $${row.pricing?.toFixed(2)}`}</b>
        </Typography>
      ),
    },
    {
      id: "actions",
      label: "Actions",
      type: "custom",
      sort: true,
      hide: readOnly,
      callback: (row: IStorage) => {
        return (
          <Stack>
            <FormButtonTooltip
              text="Buy"
              onClick={() => {
                setGetInformationStorage(row ?? {});
                buyHandler();
              }}
              startIcon={<ShoppingCartOutlinedIcon />}
              size="small"
              color="primary"
              disabled={demo}
              tooltip={demo ? TrainingMessage : undefined}
            />
          </Stack>
        );
      },
    },
  ];

  const buyHandler = () => {
    setShowPopUp(true);
  };

  const columns = getColumns(buyHandler, setGetInformationStorage);

  return (
    <>
      <Helmet title="System Costs History" />
      <Typography variant="h3" gutterBottom display="inline">
        Purchase Storage
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/">
          Dashboard
        </Link>
        <Typography>Purchase Storage</Typography>
      </Breadcrumbs>

      <Divider my={6} />
      <Stack direction="row" spacing={2} mb={5}>
        <Typography variant="h3">Wallet Balance: </Typography>
        <Typography variant="h3" mt={1}>
          <Chip
            label={`$${
              dataWalletBalance !== null ? dataWalletBalance.toFixed(2) : 0.0
            } USD`}
            color="primary"
          />
        </Typography>
      </Stack>

      <Card mb={6}>
        <CardContent>
          <LocalEnhancedTable<IStorage>
            refreshGrid={true}
            columns={columns}
            data={data}
            showSkeleton={isLoading}
          />
        </CardContent>
      </Card>
      {showPopUp && (
        <ReviewOrderPopUp
          setShowPopUp={setShowPopUp}
          showPopUp={showPopUp}
          getInformationStorage={getInformationStorage ?? {}}
          dataWalletBalance={dataWalletBalance}
          companyId={user?.companyId ?? 0}
          setInsufficientFundsShowPopUp={setInsufficientFundsShowPopUp}
          setNotDefaultCardShowPopUp={setNotDefaultCardShowPopUp}
          setSubmit={setSubmit}
        />
      )}
      {insufficientFundsShowPopUp && (
        <InsufficientFundsPopUp
          insufficientFundsShowPopUp={insufficientFundsShowPopUp}
          setInsufficientFundsShowPopUp={setInsufficientFundsShowPopUp}
        />
      )}
      {notDefaultCardShowPopUp && (
        <NotDefaultCardPopUp
          notDefaultCardShowPopUp={notDefaultCardShowPopUp}
          setNotDefaultCardShowPopUp={setNotDefaultCardShowPopUp}
        />
      )}
    </>
  );
};

export default PurchaseStorage;
