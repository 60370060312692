import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";
import {
  Grid,
  Divider as MuiDivider,
  Card as MuiCard,
  CardContent,
  Stack,
  Tabs,
  Tab,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";
import { ColumnType } from "src/types/enhancedTable";
import { useForm, Form, useAuth, useLog, useAsyncQuery } from "src/hooks";
import FormText from "src/components/formControls/FormText";
import LocalEnhancedTable from "src/components/localTable/LocalTable";
import { GridActionButton } from "src/components/gridControls";
import { IProjectCovid, ISelectedUsers, TableData } from "src/ts/interfaces";
import HeaderPage from "src/components/page/HeaderPage";
import ShareByEmailPopUpComponent from "src/components/others/ShareByEmailPopUpComponent";
import projectEmailsService from "src/services/projectEmailsService";
import projectService from "src/services/project/projectService";

const Divider = styled(MuiDivider)(spacing);
const Card = styled(MuiCard)(spacing);

const initialValues = {
  id: 0,
  name: "",
  statusId: "",
};

const getUnderEvaluationGridColumns = (
  gotoControlInfectProject: (project: IProjectCovid) => void,
  gotoProjectDashboard: (projectId: number) => void,
  documentLibraryNavigate: (projectId: number) => void,
  pictureLogNavigate: (projectId: number) => void
): ColumnType[] => [
  { id: "companyName", label: "Company Name", type: "string", sort: true },
  { id: "name", label: "Project", type: "string", sort: true },
  {
    id: "buildingAddress",
    label: "Building Address",
    type: "string",
    sort: true,
  },
  {
    id: "projectDescription",
    label: "Description",
    type: "string",
    sort: true,
  },
  {
    id: "createdDate",
    label: "Date Created",
    type: "utcDate",
    format: "MM/DD/yyyy",
    sort: true,
  },
  {
    id: "airHandlers",
    label: "# of Air Handlers (Building)",
    type: "string",
    sort: true,
    width: "90px",
    maxWidth: "90px",
  },
  {
    id: "airHandlersCompleted",
    label: "# of Air Handlers Under Evaluation",
    type: "string",
    sort: true,
    width: "90px",
    maxWidth: "90px",
  },
  {
    id: "airHandlersApproved",
    label: "# of Air Handlers Approved",
    type: "string",
    sort: true,
    width: "90px",
    maxWidth: "90px",
  },
  {
    id: "airHandlersRejected",
    label: "# of Air Handlers Rejected",
    type: "string",
    sort: true,
    width: "90px",
    maxWidth: "90px",
  },
  {
    id: "action",
    label: "Actions",
    type: "custom",
    sort: false,
    callback: (row: IProjectCovid) => {
      return (
        <div>
          <Stack direction="row">
            <GridActionButton
              type="forward"
              onClick={() => gotoControlInfectProject(row)}
              tooltip="Go to project"
            />
            <GridActionButton
              type="folder"
              onClick={() => {
                documentLibraryNavigate(row.id);
              }}
              tooltip="Project's Document Library"
            />
            <GridActionButton
              type="picture"
              onClick={() => {
                pictureLogNavigate(row.id);
              }}
              tooltip="Project's picture library"
            />
            <GridActionButton
              type="chat"
              onClick={() => {}}
              tooltip="Dispute"
            />
          </Stack>
          {(row?.companyOwnerId ?? 0) !== 0 ? (
            <Typography>
              Assigned and Awarded Building Professional Project
            </Typography>
          ) : null}
        </div>
      );
    },
  },
];

const getCompleteControlGridColumns = (
  gotoControlInfectProject: (project: IProjectCovid) => void,
  setForwardModal: (forwardModal: boolean) => void,
  setCurrentProjectId: (projectId: number) => void,
  documentLibraryNavigate: (projectId: number) => void,
  pictureLogNavigate: (projectId: number) => void
): ColumnType[] => [
  { id: "companyName", label: "Company Name", type: "string", sort: true },
  { id: "name", label: "Project", type: "string", sort: true },
  {
    id: "buildingName",
    label: "Building Address",
    type: "custom",
    sort: true,
    callback: (row: IProjectCovid) => (
      <>
        <b>{row.buildingName} </b>
        <br />
        {row.buildingAddress}
      </>
    ),
  },
  {
    id: "createdDate",
    label: "Date Created",
    type: "utcDate",
    format: "MM/DD/yyyy",
    sort: true,
  },
  {
    id: "completedDate",
    label: "Date Completed",
    type: "utcDate",
    format: "MM/DD/yyyy",
    sort: true,
  },
  {
    id: "completedBy",
    label: "Completed By",
    type: "string",
    sort: true,
  },
  {
    id: "action",
    label: "Actions",
    type: "custom",
    sort: false,
    callback: (row: IProjectCovid) => {
      return (
        <div>
          <Stack direction="row">
            <GridActionButton
              type="forward"
              onClick={() => gotoControlInfectProject(row)}
              tooltip="Go to project"
            />
            <GridActionButton
              type="folder"
              onClick={() => {
                documentLibraryNavigate(row.id);
              }}
              tooltip="Project's Document Library"
            />
            <GridActionButton
              type="picture"
              onClick={() => {
                pictureLogNavigate(row.id);
              }}
              tooltip="Project's picture library"
            />
            <GridActionButton
              type="email"
              onClick={() => {
                setCurrentProjectId(row.id);
                setForwardModal(true);
              }}
              tooltip="Forward Project"
            />
          </Stack>
          {(row?.companyOwnerId ?? 0) !== 0 ? (
            <Typography>
              Assigned and Awarded Building Professional Project
            </Typography>
          ) : null}
        </div>
      );
    },
  },
];

const getApprovedControlGridColumns = (
  gotoControlInfectProject: (project: IProjectCovid) => void,
  gotoProjectDashboard: (projectId: number) => void,
  gotoOrderPlaque: (projectId: number) => void,
  setForwardModal: (forwardModal: boolean) => void,
  setCurrentProjectId: (projectId: number) => void,
  documentLibraryNavigate: (projectId: number) => void,
  pictureLogNavigate: (projectId: number) => void
): ColumnType[] => [
  { id: "companyName", label: "Company Name", type: "string", sort: true },
  { id: "name", label: "Project", type: "string", sort: true },
  {
    id: "buildingAddress",
    label: "Building Address",
    type: "string",
    sort: true,
  },
  {
    id: "projectDescription",
    label: "Description",
    type: "string",
    sort: true,
  },
  {
    id: "createdDate",
    label: "Date Created",
    type: "utcDate",
    format: "MM/DD/yyyy",
    sort: true,
  },
  {
    id: "completedDate",
    label: "Date Completed",
    type: "utcDate",
    format: "MM/DD/yyyy",
    sort: true,
  },
  {
    id: "approvedDate",
    label: "Date Approved",
    type: "date",
    format: "MM/DD/yyyy",
    sort: true,
  },
  {
    id: "approvedByFullName",
    label: "Approved By",
    type: "string",
    sort: true,
  },
  {
    id: "action",
    label: "Actions",
    type: "custom",
    sort: false,
    callback: (row: IProjectCovid) => {
      return (
        <div>
          <Stack direction="row">
            <GridActionButton
              type="forward"
              onClick={() => gotoControlInfectProject(row)}
              tooltip="Go to project"
            />
            <GridActionButton
              type="folder"
              onClick={() => {
                documentLibraryNavigate(row.id);
              }}
              tooltip="Project's Document Library"
            />
            <GridActionButton
              type="picture"
              onClick={() => {
                pictureLogNavigate(row.id);
              }}
              tooltip="Project's picture library"
            />
            <GridActionButton
              type="email"
              onClick={() => {
                setCurrentProjectId(row.id);
                setForwardModal(true);
              }}
              tooltip="Forward Project"
            />
            <GridActionButton
              type="chat"
              onClick={() => {}}
              tooltip="Dispute"
            />
          </Stack>
          {(row?.companyOwnerId ?? 0) !== 0 ? (
            <Typography>
              Assigned and Awarded Building Professional Project
            </Typography>
          ) : null}
        </div>
      );
    },
  },
];

const ProjectEvaluation = () => {
  const { user } = useAuth();
  const { log } = useLog();
  const navigate = useNavigate();
  const [forwardModal, setForwardModal] = useState(false);
  const [currentProjectId, setCurrentProjectId] = useState(0);
  const [tab, setTab] = React.useState(3);
  const { values: filterValues, handleInputChange: handleFilterChange } =
    useForm(initialValues, true, []);

  const { execute, data, isLoading } = useAsyncQuery<IProjectCovid>(
    projectService.getControlProjects
  );

  useEffect(() => {
    const getData = async () => {
      await execute();
    };
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const gotoControlInfectProject = (project: IProjectCovid) => {
    if (
      (project.isInfectiousControlProject &&
        project.controlStatusId !== undefined &&
        project.controlStatusId !== 11 &&
        project.controlStatusId !== 12) ||
      (project.isInfectiousControlProject &&
        project.controlStatusId !== undefined &&
        project.controlStatusId === 12) ||
      (project.isInfectiousControlProject &&
        project.controlStatusId !== undefined &&
        project.controlStatusId === 11)
    ) {
      navigate(
        `/app/ProjectStudies/${project.id}/${user?.companyId}/ProjectEvaluation`
      );
    } else {
      navigate(
        `/app/Project/Edit/${project.id}/${user?.companyId}/ProjectEvaluation`
      );
    }
  };
  const handleGotoDashboard = (projectId: number) => {
    navigate(`/app/projectDashboard/${projectId}`);
  };

  const gotoOrderPlaque = (projectId: number) => {
    navigate(
      `/app/selectPlaque/${projectId}/${user?.companyId}/ProjectEvaluation`
    );
  };

  const documentLibraryNavigate = (projectId: number) => {
    navigate(
      `/app/DocumentLibrary/${projectId}/${user?.companyId}/ProjectEvaluation`
    );
  };

  const pictureLogNavigate = (projectId: number) => {
    navigate(
      `/app/PhotoLibrary/${projectId}/${user?.companyId}/ProjectEvaluation`
    );
  };

  const underEvaluationGridColumns = getUnderEvaluationGridColumns(
    gotoControlInfectProject,
    handleGotoDashboard,
    documentLibraryNavigate,
    pictureLogNavigate
  );
  const completeControlGridColumns = getCompleteControlGridColumns(
    gotoControlInfectProject,
    setForwardModal,
    setCurrentProjectId,
    documentLibraryNavigate,
    pictureLogNavigate
  );
  const approvedControlGridColumns = getApprovedControlGridColumns(
    gotoControlInfectProject,
    handleGotoDashboard,
    gotoOrderPlaque,
    setForwardModal,
    setCurrentProjectId,
    documentLibraryNavigate,
    pictureLogNavigate
  );

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTab(newValue);
  };

  const handleProjectEmailsSendEmail = async (dataTable: TableData[]) => {
    try {
      const selectedUsers = dataTable.map((item) => {
        return {
          email: item.email,
          id: item.id,
          isTeamMemeber: item.userType === "TeamMember",
          name: item.name,
        } as ISelectedUsers;
      }) as ISelectedUsers[];

      const response = await projectEmailsService.sendEmailProject(
        selectedUsers,
        currentProjectId
      );

      response.data.forEach((item: any) => {
        if (item.wasSuccessful) log.success(`${item.email} - ${item.message}`);
        else log.error(`${item.email} - ${item.message}`);
      });
    } catch (error: any) {
      log.error(error?.message?.exceptionMessage ?? "Something went wrong");
    }
  };

  return (
    <>
      <HeaderPage
        title="Projects Evaluation "
        parentText="Dashboard"
        parentLink="/"
      />
      <Divider my={6} />

      <Card mb={6}>
        <CardContent>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Form>
                <Grid container>
                  <Grid item xs={12}>
                    <FormText
                      name="name"
                      label="Name"
                      value={filterValues.name}
                      onChange={handleFilterChange}
                    />
                  </Grid>
                </Grid>
              </Form>
            </Grid>
            <Grid item xs={12}>
              <Tabs
                value={tab}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                <Tab label="Under AMP evaluation" id="3" value={3} />
                <Tab label="Completed" id="4" value={4} />
                <Tab label="Approved by AMP" id="5" value={5} />
              </Tabs>
            </Grid>
            <Grid item xs={12}>
              {tab === 3 && (
                <LocalEnhancedTable<IProjectCovid>
                  columns={underEvaluationGridColumns}
                  data={Object.values(data ?? []).filter((x) => {
                    return x.controlStatusId !== 11 && x.controlStatusId !== 12;
                  })}
                  query={filterValues.name}
                  showSkeleton={isLoading}
                  defaultRowPerPage={10}
                  defaultSortColumn="createdDate"
                  orderColumn="desc"
                />
              )}
              {tab === 4 && (
                <LocalEnhancedTable<IProjectCovid>
                  columns={completeControlGridColumns}
                  data={Object.values(data ?? []).filter((x) => {
                    return x.controlStatusId === 12;
                  })}
                  query={filterValues.name}
                  showSkeleton={isLoading}
                  defaultRowPerPage={10}
                  defaultSortColumn="createdDate"
                  orderColumn="desc"
                />
              )}
              {tab === 5 && (
                <>
                  <LocalEnhancedTable<IProjectCovid>
                    columns={approvedControlGridColumns}
                    data={Object.values(data ?? []).filter((x) => {
                      return x.controlStatusId === 11;
                    })}
                    query={filterValues.name}
                    showSkeleton={isLoading}
                    defaultRowPerPage={10}
                    defaultSortColumn="createdDate"
                    orderColumn="desc"
                  />
                </>
              )}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <ShareByEmailPopUpComponent
        sendbyEmailShow={forwardModal}
        setSendbyEmailShow={setForwardModal}
        sendEmailFunction={handleProjectEmailsSendEmail}
        hideLogSuccess={true}
      />
    </>
  );
};

export default ProjectEvaluation;
