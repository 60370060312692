import { IEquipment } from "src/ts/interfaces";
import axios from "../utils/axios";
import { IAssetProject } from "src/ts/interfaces/companyAssets";

const equipmentAssigned = async () => {
  return axios.post<IEquipment[]>("Equipment/EquipmentAssigned");
};

const getProjectsAssets = async (assedId: string) => {
  return axios.get<IAssetProject[]>(`Equipment/${assedId}/projects`);
};

const equipmentService = {
  equipmentAssigned,
  getProjectsAssets,
};

export default equipmentService;
