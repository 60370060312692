import { useEffect, useState } from "react";
import { Grid, Tabs, Tab } from "@mui/material";
import Popup from "src/components/Popup";
import { ICompany } from "src/ts/interfaces";

import { FormCancelButton } from "src/components/formControls";
import { CompanyService } from "src/services";
import DialogMessagePopup from "src/components/DialogMessagePopup";
import SatelliteActiveGrid from "./SatelliteActiveGrid";
import SatelliteInactiveGrid from "./SatelliteInactiveGrid";

interface SatellitePopupProps {
  showSatellitePopup: boolean;
  setShowSatellitePopup: (val: boolean) => void;
  companyId: number;
  historyView?: string;
  companyName?: string;
  onClick?: (companyId: number) => void;
}
const SatellitePopup = (props: SatellitePopupProps) => {
  const {
    showSatellitePopup,
    setShowSatellitePopup,
    companyId,
    historyView,
    companyName,
    onClick,
  } = props;
  const [selectedTab, setSelectedTab] = useState(0);
  const [refresh, setRefresh] = useState(false);
  const [satelliteCompanies, setSatelliteCompanies] = useState<
    ICompany[] | null
  >(null);
  const changeTabHandler = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  useEffect(() => {
    const getSatelliteCompanies = async () => {
      const response = await CompanyService.getSatellites(companyId);
      if (response.data) {
        setSatelliteCompanies(response.data);
      }
    };

    getSatelliteCompanies();
  }, [companyId, refresh, selectedTab]);

  const refreshGrid = () => {
    setRefresh(!refresh);
  };

  return (
    <>
      <DialogMessagePopup
        title={"Information"}
        text={
          "Are you certain you want to create a satellite company of: Arch/Engineer Jason? This action will charge $850.00 to your AMP Wallet.v"
        }
        showPopup={false}
        setShowPopup={() => {}}
        onSave={() => {}}
        isSubmitting={false}
      />
      <Popup
        openPopup={showSatellitePopup}
        setOpenPopup={() => {}}
        title={`Satellite companies of ${companyName}`}
        onClose={() => setShowSatellitePopup(false)}
        size="md"
      >
        <Grid container>
          <Grid item xs={12}>
            <Tabs value={selectedTab} onChange={changeTabHandler}>
              <Tab label="Active" id="1" disabled={false} />
              {!historyView && <Tab label="Inactive" id="2" disabled={false} />}
            </Tabs>
          </Grid>
          <Grid item xs={12}>
            {selectedTab === 0 && (
              <SatelliteActiveGrid
                refreshGrid={refreshGrid}
                setShowSatellitePopup={setShowSatellitePopup}
                companies={
                  satelliteCompanies?.filter((company) => company.isActive) ??
                  []
                }
                historyView={historyView}
                onClick={onClick}
              />
            )}
            {selectedTab === 1 && (
              <SatelliteInactiveGrid
                refreshGrid={refreshGrid}
                companies={
                  satelliteCompanies?.filter((company) => !company.isActive) ??
                  []
                }
              />
            )}
          </Grid>
        </Grid>
        <>
          <FormCancelButton
            onClick={() => setShowSatellitePopup(false)}
            isSubmitting={false}
          />
        </>
      </Popup>
    </>
  );
};

export default SatellitePopup;
