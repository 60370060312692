import { Mode } from "src/ts/types";
import { FormRadioGroup } from "../formControls";

interface Props {
  supply: boolean;
  return_: boolean;
  exhaust: boolean;
  outsideAir: boolean;
  onChange: (
    supply: boolean,
    return_: boolean,
    exhaust: boolean,
    outsideAir: boolean
  ) => void;
  mode?: Mode;
  showSkeleton: boolean;
  disabled: boolean;
}

const types = [
  {
    key: 0,
    value: "Supply",
  },
  {
    key: 1,
    value: "Return",
  },
  {
    key: 2,
    value: "Exhaust",
  },
  {
    key: 3,
    value: "Outside Air",
  },
];

const OuletTypeSelect = ({
  supply,
  return_,
  exhaust,
  outsideAir,
  onChange,
  mode,
  showSkeleton,
  disabled,
}: Props) => {
  const getValue = () => {
    if (supply) return 0;
    else if (return_) return 1;
    else if (exhaust) return 2;
    else if (outsideAir) return 3;

    return null;
  };

  const onChangeHandler = (e: any) => {
    const { value } = e.target;
    switch (value) {
      case "0":
        onChange(true, false, false, false);
        break;
      case "1":
        onChange(false, true, false, false);
        break;
      case "2":
        onChange(false, false, true, false);
        break;
      case "3":
        onChange(false, false, false, true);
        break;
      default:
        onChange(false, false, false, false);
    }
  };

  return (
    <FormRadioGroup
      name="na"
      label=""
      row={true}
      value={getValue()}
      onChange={onChangeHandler}
      items={types}
      showSkeleton={showSkeleton}
      mode={mode}
      disabled={disabled}
    ></FormRadioGroup>
  );
};

export default OuletTypeSelect;
