import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import { NavLink, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import {
  Grid,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
  Typography,
  Stack,
  Chip,
  IconButton,
  Skeleton,
} from "@mui/material";
import { Box, spacing } from "@mui/system";
import { useLog } from "src/hooks";
import { IPlaques, IPlaquesPicture } from "src/ts/interfaces";
import { UserParams } from "src/ts/types";
import { plaqueProviderService } from "src/services";
import { PlaquesTypeEnumMap } from "src/ts/enums/plaquesAndDecals";
import { GridActionButton } from "src/components/gridControls";
import PlaqueDecalForm from "./PlaqueDecalForm";
import { Permission as PermissionTypes } from "src/ts/enums";
import { usePermissions } from "src/hooks";
import DropZone from "src/components/DropZone";
import LocalEnhancedTable from "src/components/localTable/LocalTable";
import { AddIcon, DeleteIcon, FileDownloadIcon } from "src/components/icons";
import { ColumnType } from "src/types/enhancedTable";
import FileUtils from "src/utils/file";
import plaquePictureService from "src/services/plaquePictureService";
import FormAccordion from "src/components/formControls/FormAccordion";
import MatchWordConfirmModal from "src/components/modals/MatchWordConfirmModal";
import { currencyformatter } from "../../../utils/format";
import { FormButton } from "src/components/formControls";
import plaqueService from "src/services/plaqueService";

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const initialValuesCode = {
  code: "",
};

const getColumns = (
  handleDeleteDocument: (row: IPlaquesPicture) => void,
  downloadDocument: (row: IPlaquesPicture) => void,
  fullAccess: boolean | undefined
): ColumnType[] => [
  {
    id: "id",
    label: "Id",
    type: "string",
    sort: true,
  },
  {
    id: "name",
    label: "Name",
    type: "string",
    sort: true,
  },
  {
    id: "status",
    label: "Actions",
    type: "custom",
    sort: false,
    callback: (row: IPlaquesPicture) => {
      return (
        <div>
          <Box mr={2}>
            <IconButton
              aria-label="download"
              size="large"
              onClick={() => downloadDocument(row)}
            >
              <FileDownloadIcon />
            </IconButton>

            {fullAccess && (
              <IconButton
                aria-label="delete"
                size="large"
                onClick={() => handleDeleteDocument(row)}
              >
                <DeleteIcon />
              </IconButton>
            )}
          </Box>
        </div>
      );
    },
  },
];

const Plaques = () => {
  let { id } = useParams<UserParams>();
  const providerId = parseInt(id === undefined ? "0" : id);

  const { log } = useLog();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [plaques, setPlaques] = useState<IPlaques[]>();
  const [currentProvider, setCurrentProvider] = useState("");
  const [providerType, setProviderType] = useState(1);

  const [plaqueId, setPlaqueId] = useState(0);
  const [refreshData, setrefreshData] = useState(false);
  const [popupPlaqueEdit, setPopupPlaqueEdit] = useState(false);
  const { fullAccess, readOnly } = usePermissions(
    PermissionTypes.Plaque_Decals
  );
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showDeletePlaqueModal, setShowDeletePlaqueModal] = useState(false);

  const [currentRow, setCurrentRow] = useState<IPlaquesPicture>();
  const [values, setValues] = useState(initialValuesCode);
  const [expanded, setExpanded] = React.useState<string | false>(false);
  const handleChange =
    (panel: string, itemId: number) =>
    (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
      setPlaqueId(itemId);
    };

  const [isMaping, setIsMaping] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsMaping(false);
    }, 1000);

    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const getUser = async (providerId: number) => {
      const response = await plaqueProviderService.getById(providerId);
      setPlaques(response.data.plaques);
      setCurrentProvider(response.data.name);
      setProviderType(response.data.providerType);
    };

    if (providerId > 0 || !popupPlaqueEdit) {
      getUser(providerId);
    }
  }, [providerId, refreshData, popupPlaqueEdit]);

  const handleEditPlaque = (id: number) => {
    setPlaqueId(id);
    setPopupPlaqueEdit(true);
  };

  const handleDeletePlaque = (id: number) => {
    setPlaqueId(id);
    setShowDeletePlaqueModal(true);
  };

  const handleFileAccepted = async (file: File) => {
    try {
      setIsSubmitting(true);
      await plaquePictureService.uploadFile(file, plaqueId.toString());
    } catch (error: any) {
      log.error(error?.message?.exceptionMessage ?? "Something went wrong");
    } finally {
      setIsSubmitting(false);
      setrefreshData(!refreshData);
    }
  };

  const downloadPicture = async (row: IPlaquesPicture) => {
    if (row && row.id) {
      try {
        setIsSubmitting(true);
        const response = await plaquePictureService.downloadFile(row.id);
        FileUtils.downloadFile(response.data, row.name);
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      } finally {
        setIsSubmitting(false);
      }
    } else {
    }
  };

  const handleDeletePicture = (row: IPlaquesPicture) => {
    setCurrentRow(row);
    setShowDeleteModal(true);
  };

  const deletePicture = async () => {
    if (currentRow && currentRow.id) {
      try {
        setIsSubmitting(true);
        await plaquePictureService.deletePicture(currentRow.id);
        setrefreshData(!refreshData);
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      } finally {
        setIsSubmitting(false);
      }
      setValues({ code: "" });
    } else {
      setValues({ code: "" });
    }
  };

  const deletePlaque = async () => {
    if (plaqueId !== 0) {
      try {
        setIsSubmitting(true);
        await plaqueService.deleteById(plaqueId);
        log.info("Plaque was succesfully deleted");
        setrefreshData(!refreshData);
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      } finally {
        setIsSubmitting(false);
        setShowDeletePlaqueModal(false);
      }
    }
  };

  const columns = getColumns(handleDeletePicture, downloadPicture, fullAccess);

  return (
    <>
      <Helmet title="Company" />
      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            {`Provider ${currentProvider}`}
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} to="/">
              Dashboard
            </Link>
            <Link component={NavLink} to="/app/plaqueProviders">
              Providers
            </Link>
            <Typography>Provider</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item>
          <Stack direction="row" spacing={2}>
            <FormButton
              startIcon={<AddIcon />}
              text={`Add New ${providerType === 1 ? "Plaques" : "Decals"}`}
              onClick={() => {
                handleEditPlaque(0);
              }}
              size="medium"
            />
          </Stack>
        </Grid>
      </Grid>
      <Divider my={6} />

      <Typography variant="h6" pb={4}>
        {providerType === 1 ? "Plaques" : "Decals"}
      </Typography>

      {isMaping ? (
        <>
          <Skeleton height={50} />
          <Skeleton height={50} />
          <Skeleton height={50} />
          <Skeleton height={50} />
        </>
      ) : (
        <>
          {plaques?.map((plaque, index) => (
            <FormAccordion
              title={plaque.name}
              expanded={expanded === `selected${plaque.id}`}
              onChange={handleChange(`selected${plaque.id}`, plaque.id)}
            >
              <Grid container justifyContent="space-between">
                <Grid item>
                  <Stack direction="row">
                    <Typography pr={1}>{plaque.name}</Typography>
                    {providerType === 1 && (
                      <Chip
                        variant="outlined"
                        color="primary"
                        size="small"
                        label={PlaquesTypeEnumMap[plaque.type]}
                      />
                    )}
                  </Stack>
                </Grid>
                <Grid item>
                  <Stack direction="row" alignContent="end" alignItems="start">
                    <GridActionButton
                      type={fullAccess ? "edit" : "view"}
                      onClick={() => {
                        handleEditPlaque(plaque.id);
                      }}
                      tooltip={fullAccess ? "Edit" : "View"}
                    />
                    {fullAccess && (
                      <GridActionButton
                        type="delete"
                        onClick={() => {
                          handleDeletePlaque(plaque.id);
                        }}
                        tooltip="Delete"
                      />
                    )}
                  </Stack>
                </Grid>
              </Grid>

              <br />
              <Typography>{plaque.description}</Typography>
              <Divider my={6} />

              <Typography>
                <Stack direction="row" alignContent={"space-between"}>
                  <Box sx={{ fontWeight: "bold", paddingRight: "4px" }}>
                    Price:
                  </Box>
                  <Typography pr={2}>
                    {currencyformatter.format(plaque.price)}
                  </Typography>
                  <Box sx={{ fontWeight: "bold" }}>Size:&nbsp;</Box>{" "}
                  {plaque.size}
                </Stack>
              </Typography>
              {fullAccess && (
                <Grid item xs={12}>
                  <DropZone
                    onFileAccepted={handleFileAccepted}
                    accept={{ "image/*": [".jpeg", ".jpg", ".png"] }}
                    disabled={isSubmitting || plaque.plaquesPicture.length > 0}
                    disableMessage="Only one image is allowed"
                    submiting={isSubmitting}
                  />
                  <LocalEnhancedTable<IPlaquesPicture>
                    refreshGrid={false}
                    columns={columns}
                    data={plaque.plaquesPicture}
                    hidePagination={true}
                  />
                </Grid>
              )}
            </FormAccordion>
          ))}
        </>
      )}

      <PlaqueDecalForm
        plaqueId={plaqueId}
        providerType={providerType}
        providerId={providerId}
        showPopup={popupPlaqueEdit}
        setShowPopup={setPopupPlaqueEdit}
      />

      <MatchWordConfirmModal
        matchWord={"DELETE"}
        isDialogOpen={showDeleteModal}
        setIsDialogOpen={setShowDeleteModal}
        text={"Are you certain you want to delete this item?"}
        onConfirm={deletePicture}
      ></MatchWordConfirmModal>

      <MatchWordConfirmModal
        matchWord={"DELETE"}
        isDialogOpen={showDeletePlaqueModal}
        setIsDialogOpen={setShowDeletePlaqueModal}
        text={"Are you certain you want to delete this item?"}
        onConfirm={deletePlaque}
      ></MatchWordConfirmModal>
    </>
  );
};

export default Plaques;
