import { IAmpTimeZone, IKeyValue } from "src/ts/interfaces";
import axios from "src/utils/axios";

const getcurrentZone = (zone: string) => {
  return axios.get<any>(`/TimeZones/getTimeZone?timeZone=${zone}`);
};

const getAll = () => {
  return axios.get<Array<IAmpTimeZone>>("/TimeZones");
};

const getKeyValues = () => {
  return new Promise<IKeyValue<number, string>[]>((resolve, reject) => {
    axios
      .get<Array<IAmpTimeZone>>("/TimeZones")
      .then((response) => {
        const keyValuePair: IKeyValue<number, string>[] = response.data.map(
          (item) => {
            return {
              key: item.id,
              value: item.name,
            };
          }
        );

        resolve(keyValuePair);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const timeZonesService = {
  getcurrentZone,
  getAll,
  getKeyValues,
};

export default timeZonesService;
