import Popup from "src/components/Popup";
import { FormButton } from "src/components/formControls";
import { Grid, Stack, Typography } from "@mui/material";
import { useLog } from "src/hooks";
import { IProjectOutForBidData } from "src/ts/interfaces/projectOutToBid";
import projectContestService from "src/services/projectContestService";

interface ReverseDeclinationPopUpProps {
  declineProjectShow: boolean;
  setDeclineProject: (val: boolean) => void;
  refreshPage: boolean;
  setRefreshPage: (val: boolean) => void;
  dataRow: IProjectOutForBidData | undefined;
}

export default function ReverseDeclinationPopUp({
  declineProjectShow,
  setDeclineProject,
  refreshPage,
  setRefreshPage,
  dataRow,
}: ReverseDeclinationPopUpProps) {
  const { log } = useLog();

  const accepthandlerAll = async () => {
    try {
      await projectContestService.putReverseDeclineProject(dataRow?.id ?? 0);
      setDeclineProject(false);
      setRefreshPage(!refreshPage);
      log.success("Reverse Declined Completed");
    } catch (error: any) {
      log.error(error?.message?.exceptionMessage ?? "Something went wrong");
    }
  };

  return (
    <>
      <Popup
        title={"Reverse Declination Project"}
        openPopup={declineProjectShow}
        setOpenPopup={setDeclineProject}
        onClose={() => {
          setDeclineProject(false);
        }}
        size="sm"
      >
        <>
          <Grid container>
            <Grid item xs={2.5}>
              <Typography> Project Code: </Typography>
            </Grid>
            <Grid item xs={9}>
              <Typography>{dataRow?.code} </Typography>
            </Grid>
          </Grid>

          <Grid container mt={2}>
            <Grid item xs={2.5}>
              <Typography> Project Name: </Typography>
            </Grid>
            <Grid item xs={9}>
              <Typography>
                {" "}
                {dataRow?.name === undefined
                  ? dataRow?.projectContractor?.name
                  : dataRow.name}
              </Typography>
            </Grid>
          </Grid>

          <Grid container mt={2}>
            <Grid item xs={2.5}>
              <Typography ml={8.5}> Building: </Typography>
            </Grid>
            <Grid item xs={9}>
              <Typography>
                <b>{dataRow?.buildingName} </b> {dataRow?.buildingAddress}
              </Typography>
            </Grid>
          </Grid>

          <Typography mb={1} mt={3}>
            <b>
              Are you certain you want to reverse the declination for this
              project?
            </b>
          </Typography>
        </>
        <Stack direction="row" spacing={3}>
          <FormButton
            variant="outlined"
            size="small"
            text="Close"
            onClick={() => {
              setDeclineProject(false);
            }}
          />
          <FormButton
            size="small"
            text="Accept"
            color="primary"
            onClick={() => {
              accepthandlerAll();
            }}
          />
        </Stack>
      </Popup>
    </>
  );
}
