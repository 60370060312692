import React, { useState, useEffect } from "react";
import { IKeyValue } from "src/ts/interfaces";
import FormSelect from "./FormSelect";
import { userCountryAccessService } from "src/services";
import { useLog } from "src/hooks";

interface FormSelectCountryProps {
  value: string;
  error?: any;
  onChange: (e: any) => void;
  size?: "small" | "medium" | null;
  fullWidth?: boolean | undefined;
  disable?: boolean | undefined;
  isLoading: (val: boolean) => void;
  extraItem?: IKeyValue<number, string> | null;
  showSkeleton?: boolean;
}

const FormSelectCountry = (props: FormSelectCountryProps) => {
  const { isLoading, ...other } = props;
  const [countries, setCountries] = useState<
    IKeyValue<number | null | undefined, string | undefined>[]
  >([]);
  const { log } = useLog();

  useEffect(() => {
    const getCountries = async () => {
      try {
        isLoading(true);
        const request = await userCountryAccessService.getKeyValues();
        if (props?.extraItem !== undefined && props?.extraItem) {
          request.splice(0, 0, {
            key: props?.extraItem?.key,
            value: props?.extraItem?.value,
          });
        }
        setCountries(request);
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      } finally {
        isLoading(false);
      }
    };

    getCountries();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FormSelect
      name="countryId"
      label="Country"
      options={countries}
      {...other}
    />
  );
};

export default FormSelectCountry;
