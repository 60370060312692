import React, { useState } from "react";
import Popup from "src/components/Popup";
import { FormCancelButton } from "src/components/formControls";
import { Grid, Stack, Typography } from "@mui/material";

import { convertUTCDateToLocalDate } from "src/utils/utils";

interface InformationProps {
  showInformationTicketPopup: boolean;
  setShowInformationTicketPopup: (val: boolean) => void;
  informationId: any;
}

export default function InformationTicketPopUp({
  showInformationTicketPopup,
  setShowInformationTicketPopup,
  informationId,
}: InformationProps) {
  const [isSubmitting] = useState(false);
  const handleClosePopUp = () => {
    setShowInformationTicketPopup(false);
  };

  return (
    <>
      <Popup
        title="Ticket information"
        openPopup={showInformationTicketPopup}
        setOpenPopup={setShowInformationTicketPopup}
        onClose={handleClosePopUp}
      >
        <>
          <Grid container p={2}>
            <Grid item xs={2} textAlign={"right"}>
              <Typography fontWeight={"bold"}>Ticket ID:</Typography>
            </Grid>
            <Grid item xs={10} paddingLeft={2} paddingBottom={1}>
              <Typography>{informationId.id}</Typography>
            </Grid>
            <Grid item xs={2} textAlign={"right"}>
              <Typography fontWeight={"bold"}>Classification:</Typography>
            </Grid>
            <Grid item xs={10} paddingLeft={2} paddingBottom={1}>
              <Typography>{informationId.classification.name}</Typography>
            </Grid>
            <Grid item xs={2} textAlign={"right"}>
              <Typography fontWeight={"bold"}>Priority:</Typography>
            </Grid>
            <Grid item xs={10} paddingLeft={2} paddingBottom={1}>
              <Typography>{informationId.priority}</Typography>
            </Grid>
            <Grid item xs={2} textAlign={"right"}>
              <Typography fontWeight={"bold"}>Title:</Typography>
            </Grid>
            <Grid item xs={10} paddingLeft={2} paddingBottom={1}>
              <Typography>{informationId.title}</Typography>
            </Grid>
            <Grid item xs={2} textAlign={"right"}>
              <Typography fontWeight={"bold"}>Description:</Typography>
            </Grid>
            <Grid item xs={10} paddingLeft={2} paddingBottom={1}>
              <Typography>{informationId.description}</Typography>
            </Grid>
            <Grid item xs={2} textAlign={"right"}>
              <Typography fontWeight={"bold"}>Create By:</Typography>
            </Grid>
            <Grid item xs={10} paddingLeft={2} paddingBottom={1}>
              <Typography>
                {informationId.createdBy === null
                  ? `(0) - ${informationId.anonymousName}`
                  : `(${informationId.createdBy}) - ${informationId.created.name} ${informationId.created.lastName}`}
              </Typography>
            </Grid>
            <Grid item xs={2} textAlign={"right"}>
              <Typography fontWeight={"bold"}>Created Date:</Typography>
            </Grid>
            <Grid item xs={10} paddingLeft={2} paddingBottom={1}>
              <Typography>
                {convertUTCDateToLocalDate(informationId.createdDate)?.format(
                  "MM/DD/YYYY hh:mm A"
                )}
              </Typography>
            </Grid>
            <Grid item xs={2} textAlign={"right"}>
              <Typography fontWeight={"bold"}>Email:</Typography>
            </Grid>
            <Grid item xs={10} paddingLeft={2} paddingBottom={1}>
              <Typography>
                {informationId.createdBy === null
                  ? `${informationId.anonymousEmail}`
                  : `${informationId.created.email}`}
              </Typography>
            </Grid>
            <Grid item xs={2} textAlign={"right"}>
              <Typography fontWeight={"bold"}>Url:</Typography>
            </Grid>
            <Grid item xs={10} paddingLeft={2} paddingBottom={1}>
              <Typography>{informationId.url}</Typography>
            </Grid>

            {informationId.comments !== null ? (
              <>
                <Grid item xs={2} textAlign={"right"}>
                  <Typography fontWeight={"bold"}>Comments:</Typography>
                </Grid>
                <Grid item xs={10} paddingLeft={2} paddingBottom={1}>
                  <Typography>{informationId.comments}</Typography>
                </Grid>
              </>
            ) : (
              ""
            )}
          </Grid>
        </>
        <Stack
          direction="row"
          spacing={2}
          justifyContent="flex-end"
          alignItems="flex-start"
        >
          <FormCancelButton
            onClick={() => {
              setShowInformationTicketPopup(false);
            }}
            isSubmitting={isSubmitting}
          />
        </Stack>
      </Popup>
    </>
  );
}
