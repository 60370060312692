import { IPostCellPhoneNumberVerificationRequest } from "src/ts/interfaces/signup";
import axios from "../utils/axios";

const start = async () => {
  return axios.get(`SignUp/start`);
};

const done = async (wizardId: number) => {
  return axios.get(`SignUp/done/${wizardId}`);
};

const sendcode = async (data: IPostCellPhoneNumberVerificationRequest) => {
  return axios.post(`SignUp/SendCode/`, data);
};

const verifyCode = async (data: IPostCellPhoneNumberVerificationRequest) => {
  return axios.post(`SignUp/VerifyCode/`, data);
};

const nextStep = async (cardConfirm: boolean, wizardId: number) => {
  return axios.get(`SignUp/NextStep/${wizardId}?cardConfirmed=${cardConfirm}`);
};

const restart = async (wizardId: number) => {
  return axios.get(`SignUp/Restart/${wizardId}`);
};

const confirm = async (
  wizardId: number,
  creditCardId: number,
  coupon: string
) => {
  return axios.get(
    `SignUp/confirm/${wizardId}?coupon=${coupon}&creditCardId=${creditCardId}`
  );
};

const signUpService = {
  start,
  done,
  sendcode,
  nextStep,
  verifyCode,
  confirm,
  restart,
};

export default signUpService;
