import { useEffect, useState } from "react";
import { IQueryOptions } from "../ts/interfaces";
import useLog from "./useLog";

const useAsyncQuery = <T>(func: any, options?: IQueryOptions) => {
  const { log } = useLog();
  const [isLoading, setIsLoading] = useState(false);
  const [status, setStatus] = useState("idle");
  const [data, setData] = useState<T>(null as any);

  const execute = async (...params: any[]) => {
    setIsLoading(true);
    setStatus("pending");

    await func(...params)
      .then((response: any) => {
        setData(response.data);
        setIsLoading(false);
        setStatus("success");
        if (options?.onSuccess) options?.onSuccess(response.data, params);
      })
      .catch((error: any) => {
        if (
          !(options?.hideErrorMessage && options?.hideErrorMessage === true)
        ) {
          log.error(
            error?.message?.message ?? error?.message?.exceptionMessage
          );
        }

        setIsLoading(false);
        setStatus("error");
      });
  };
  useEffect(() => {
    if (options?.immediate) execute();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options?.immediate]);

  const resetData = () => {
    setData(null as any);
  };

  const query = { execute, data, isLoading, status, setData, resetData };

  return query;
};

export default useAsyncQuery;
