import React, { useState, useEffect } from "react";
import {
  FormControl,
  FormLabel,
  RadioGroup as MuiRadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";

interface WeekEndingPayPeriodProps {
  startDay: number;
  endDay: number;
  name: string;
  label: string;
  onChange: (e: any) => void;
  disabled?: boolean;
}

const WeekEndingPayPeriods = (props: WeekEndingPayPeriodProps) => {
  const { startDay, onChange, disabled } = props;
  const [selectedValue, setSelectedValue] = useState(1);

  useEffect(() => {
    switch (startDay) {
      case 1: //Sunday to Saturday
        setSelectedValue(2);
        break;
      case 2: //Monday to Sunday
        setSelectedValue(1);
        break;
      case 7: //Saturday to Friday
        setSelectedValue(3);
        break;
    }
  }, [startDay]);

  const weekDaysOptions = [
    { key: 1, value: "Monday - Sunday" },
    { key: 2, value: "Sunday - Saturday" },
    { key: 3, value: "Saturday - Friday" },
  ];

  const handleOnChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    value: string
  ) => {
    const selectedOption = Number(value);

    setSelectedValue(selectedOption);
    let weekDays = { weekEndDay: 1, weekStartDay: 2 };
    switch (selectedOption) {
      case 1: //Monday to Sunday
        weekDays = { weekEndDay: 1, weekStartDay: 2 };
        break;
      case 2: //Sunday to Saturday
        weekDays = { weekEndDay: 7, weekStartDay: 1 };
        break;
      case 3: //Saturday to Friday
        weekDays = { weekEndDay: 6, weekStartDay: 7 };
        break;
    }
    onChange(weekDays);
  };

  return (
    <FormControl disabled={disabled}>
      <FormLabel>{"Week ending pay periods"}</FormLabel>
      <MuiRadioGroup value={selectedValue} onChange={handleOnChange}>
        {weekDaysOptions &&
          weekDaysOptions.map((item) => (
            <FormControlLabel
              key={item.key}
              value={item.key}
              control={<Radio />}
              label={item.value}
            />
          ))}
      </MuiRadioGroup>
    </FormControl>
  );
};

export default WeekEndingPayPeriods;
