import { ITechnicianQuizCategory } from "../ts/interfaces";
import axios from "../utils/axios";

const getAll = async () => {
  return axios.get<ITechnicianQuizCategory[]>(
    `TechnicianQuizCategory/GetAllNotRequired`
  );
};

const technicianQuizCategoryService = {
  getAll,
};

export default technicianQuizCategoryService;
