import React, { useState } from "react";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import {
  Grid,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  Typography,
  Tabs,
  Tab,
  Stack,
  CardContent,
} from "@mui/material";
import { spacing } from "@mui/system";
import { IKeyValue } from "src/ts/interfaces";
import AddIcon from "@mui/icons-material/Add";
import { FormButton, FormText } from "src/components/formControls";
import { ICoupon } from "src/ts/interfaces";
import ExpiredDisableCoupons from "./ExpiredDisableCoupons";
import CouponForm from "./CouponForm";
import ValidCoupons from "./ValidCoupons";
import { useForm } from "src/hooks";
import { Validator } from "src/ts/types";
import useFormCoupon from "./hooks/useFormCoupon";
import { Permission as PermissionTypes } from "src/ts/enums";
import { usePermissions } from "src/hooks";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const filterInitialValues = {
  id: 0,
  title: "",
  code: "",
};

const initialValues: ICoupon = {
  id: 0,
  appliesTo: "",
  code: "",
  displayInWebPage: false,
  from: null,
  isActive: true,
  title: "",
  to: null,
  typeId: -1,
  value: 0,
};

const Coupons = () => {
  const validate = (fieldValues = values) => {
    let temp: Record<string, string> = { ...errors };

    temp.title = new Validator(fieldValues, "title")
      .isRequired("The coupon title is required.")
      .toString();

    temp.code = new Validator(fieldValues, "code")
      .isRequired("The coupon code is required.")
      .maxLength(8, "Only 8 character are allowed.")
      .toString();

    temp.from = new Validator(fieldValues, "from")
      .isRequired("The valid from date is required.")
      .toString();

    temp.to = new Validator(fieldValues, "to")
      .isRequired("The expires on date is required.")
      .toString();

    temp.typeId = new Validator(fieldValues, "typeId")
      .selectedOption(-1, "The coupon type is required.")
      .toString();

    temp.value = new Validator(fieldValues, "value")
      .selectedOption(0, "The coupon value is required.")
      .toString();

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues, false, validate);
  const [selectdTab, setSelectedTab] = useState(0);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [refreshGrid, setRefreshGrid] = useState(false);
  const [couponType, setCouponType] = useState<IKeyValue<number, string>[]>([]);

  const { values: filterValues, handleInputChange: handleFilterChange } =
    useFormCoupon(filterInitialValues, true, []);
  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  const handleSetDialogIsOpen = (value: boolean) => {
    setIsDialogOpen(value);
  };

  const handleEditCoupon = (row: ICoupon) => {
    setValues(row);
    setIsDialogOpen(true);
  };

  const handleRefreshGrid = () => {
    setRefreshGrid(!refreshGrid);
  };

  const { fullAccess } = usePermissions(PermissionTypes.Coupons);

  return (
    <React.Fragment>
      <Helmet title="Coupons" />
      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Coupons
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} to="/">
              Dashboard
            </Link>
            <Typography>Coupons</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item>
          <Stack direction="row" spacing={2}>
            {fullAccess && (
              <FormButton
                startIcon={<AddIcon />}
                text={"New Coupon"}
                onClick={() => {
                  setIsDialogOpen(true);
                  resetForm();
                }}
              />
            )}
          </Stack>
        </Grid>
      </Grid>
      <Divider my={6} />
      <Card>
        <CardContent>
          <Grid container>
            <Grid item xs={12}>
              <FormText
                name="name"
                label="Search"
                value={filterValues.name}
                onChange={handleFilterChange}
              />
            </Grid>
          </Grid>
          <Divider my={6} />
          <Grid
            container
            spacing={10}
            sx={{ borderBottom: 1, borderColor: "divider", marginBottom: 5 }}
          >
            <Grid item xs={6}>
              <Tabs
                value={selectdTab}
                onChange={handleChangeTab}
                aria-label="basic tabs example"
              >
                <Tab label="Active" id="1" />
                <Tab label="Expired/Disabled" id="2" />
              </Tabs>
            </Grid>
          </Grid>

          <Grid container spacing={6}>
            <Grid item xs={12}>
              {selectdTab === 1 && (
                <ExpiredDisableCoupons
                  refreshGrid={refreshGrid}
                  onEditCoupon={handleEditCoupon}
                  onRefreshGrid={handleRefreshGrid}
                  couponType={couponType}
                  query={filterValues.name}
                />
              )}
              {selectdTab === 0 && (
                <ValidCoupons
                  refreshGrid={refreshGrid}
                  onEditCoupon={handleEditCoupon}
                  onRefreshGrid={handleRefreshGrid}
                  couponType={couponType}
                  query={filterValues.name}
                />
              )}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <CouponForm
        isDialogOpen={isDialogOpen}
        setIsDialogOpen={handleSetDialogIsOpen}
        validate={validate}
        values={values}
        resetForm={resetForm}
        onInputChange={handleInputChange}
        onRefreshGrid={handleRefreshGrid}
        errors={errors}
        setCouponType={setCouponType}
        setValues={setValues}
      />
    </React.Fragment>
  );
};

export default Coupons;
