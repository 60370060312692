import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import {
  CardContent,
  Grid,
  Card as MuiCard,
  Divider as MuiDivider,
  Stack,
  Stepper,
  Step,
  StepLabel,
  List,
  Typography,
  Box,
  useTheme,
  Skeleton,
} from "@mui/material";
import {
  IPlaqueOrder,
  IPlaqueOrderDetailAdd,
  IPlaques,
} from "src/ts/interfaces";
import { useNavigate, useParams } from "react-router-dom";
import { useAsyncMutation, useAsyncQuery, useAuth } from "src/hooks";
import HeaderPage from "src/components/page/HeaderPage";
import { spacing } from "@mui/system";
import plaqueService from "src/services/plaqueService";
import { FormCancelButton, FormNumeric2 } from "src/components/formControls";
import { GridActionButton } from "src/components/gridControls";
import { ProviderTypeEnum } from "src/ts/enums";
import FormNextButton from "src/components/formControls/FormNextButton";
import FormBackButton from "src/components/formControls/FormBackButton";
import plaqueOrderService from "src/services/plaqueOrderService";
import plaqueOrderDetailService from "src/services/plaqueOrderDetailService";
import CartItems from "./components/CartItems";
import { steps } from "./const/const";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import { currencyformatter } from "src/utils/format";
import { ROLES } from "src/constants";
const AutoPlaySwipeableViews = autoPlay(SwipeableViews);
const Divider = styled(MuiDivider)(spacing);
const Card = styled(MuiCard)(spacing);
const Items = styled.div`
  padding-top: ${(props) => props.theme.spacing(2.5)};
  padding-bottom: ${(props) => props.theme.spacing(2.5)};
`;
const images = [
  {
    name: "Infectious Building Compliancy",
    thumbByte: "/static/img/COVID1.png",
  },
];
const SelectPlaques = () => {
  const theme = useTheme();
  const { user } = useAuth();
  const navigate = useNavigate();
  let { projectId, type, page } = useParams<{
    projectId: string;
    type: string;
    page: string;
  }>();
  const [providerType, setProviderType] = useState<ProviderTypeEnum>(
    type === "1" ? ProviderTypeEnum.Plaque : ProviderTypeEnum.Decals
  );
  const [refreshData, setRefreshData] = useState(false);
  const [activeStep, setActiveStep] = React.useState(0);
  const [adding, setAdding] = useState(false);
  const { execute, data, isLoading } = useAsyncQuery<IPlaques[]>(
    plaqueService.getallActive
  );

  const { execute: executeOrder, data: dataOrder } =
    useAsyncQuery<IPlaqueOrder>(plaqueOrderService.getByProjectId, {
      onSuccess: (result) => {
        if (result.projectPurchaseId !== null)
          navigate(`/app/completeOrder/${projectId}/selectPlaques`);
      },
    });

  const { execute: executeAdd } = useAsyncMutation(
    plaqueOrderDetailService.create,
    {
      successfulMessage: "Item was added",
      onSuccess: () => {
        setRefreshData(!refreshData);
      },
    }
  );

  useEffect(() => {
    const getData = async () => {
      await execute(projectId);
      await executeOrder(projectId);
      setAdding(false);
    };
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId, refreshData]);

  const handleCancel = () => {
    if (page === "plaqueCompanyOrder") navigate(`/app/plaqueCompanyOrders`);
    else {
      if (user?.role === ROLES.CompanyOwner)
        navigate(`/app/ProjectList/ApprovedByAMP/${user?.companyId}`);
      else navigate(`/app/infectiousProject/${user?.companyId}`);
    }
  };

  const handleStep = (step: ProviderTypeEnum) => {
    if (page === "plaqueCompanyOrder")
      navigate(`/app/selectPlaque/${projectId}/${step}/plaqueCompanyOrder`);
    else navigate(`/app/selectPlaque/${projectId}/${step}/selectPlaques`);
    setProviderType(step);
    window.scrollTo(0, 0);
  };

  const handleGotoDeliveryAddress = () => {
    if (page === "plaqueCompanyOrder")
      navigate(`/app/deliveryAddress/${projectId}/plaqueCompanyOrder`);
    else navigate(`/app/deliveryAddress/${projectId}/selectPlaques`);
  };

  const handleAdd = async (itemId: number, quantity: number) => {
    setAdding(true);
    const newItem: IPlaqueOrderDetailAdd = {
      plaqueOrderId: dataOrder.id,
      plaquesId: itemId,
      quantity: quantity < 1 ? 1 : quantity,
    };

    await executeAdd(newItem);
  };

  const handleStepChange = (step: number) => {};

  const skeletonArray = Array(5).fill("");
  return (
    <>
      <HeaderPage
        title={"Order Plaque"}
        parentText={"Project"}
        parentLink={`/app/ProjectList/${user?.companyId}`}
        actionSection={undefined}
      ></HeaderPage>
      <Divider my={6} />
      <Card mb={6}>
        <CardContent>
          <Stepper
            alternativeLabel
            activeStep={providerType === ProviderTypeEnum.Plaque ? 0 : 1}
          >
            {steps.map((step) => (
              <Step key={step.key}>
                <StepLabel>
                  {step.label}
                  <br /> {step.description}
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        </CardContent>
      </Card>
      <Grid container spacing={6}>
        <Grid item xs={8}>
          <Card mb={6}>
            <CardContent>
              {isLoading &&
                skeletonArray.map((row, indexRow) => {
                  return (
                    <Grid container paddingTop={0}>
                      <Grid item xs={4} paddingTop={10} paddingLeft={20}>
                        <Skeleton height={100} width={100}></Skeleton>
                      </Grid>
                      <Grid item xs={8} p={0}>
                        <Skeleton height={30} width={300}></Skeleton>
                        <Skeleton height={30} width={150}></Skeleton>
                        <Skeleton height={30} width={150}></Skeleton>
                        <Skeleton height={100}></Skeleton>
                      </Grid>
                    </Grid>
                  );
                })}

              {!isLoading && (
                <List>
                  <Items>
                    {data &&
                      data
                        .filter(
                          (col) =>
                            col?.plaqueProviders?.providerType ===
                              providerType && !col?.selected
                        )
                        .map((item, index) => (
                          <>
                            <Grid container p={2}>
                              <Grid item xs={4} paddingRight={4}>
                                <Stack direction="row" justifyContent="center">
                                  <AutoPlaySwipeableViews
                                    axis={
                                      theme.direction === "rtl"
                                        ? "x-reverse"
                                        : "x"
                                    }
                                    index={activeStep}
                                    onChangeIndex={handleStepChange}
                                    enableMouseEvents
                                  >
                                    {(item.plaquesPicture.length > 0
                                      ? item.plaquesPicture
                                      : images
                                    ).map((step, index) => (
                                      <div key={step.name}>
                                        {Math.abs(activeStep - index) <= 2 ? (
                                          <Box
                                            component="img"
                                            sx={{
                                              height: 200,
                                              display: "block",
                                              maxWidth: 400,
                                              overflow: "hidden",
                                              width: "100%",
                                            }}
                                            src={step.thumbByte}
                                            alt={step.name}
                                          />
                                        ) : null}
                                      </div>
                                    ))}
                                  </AutoPlaySwipeableViews>
                                </Stack>
                              </Grid>
                              <Grid item xs={8}>
                                <Stack spacing={2}>
                                  <Typography variant="h5">
                                    {item.name}
                                  </Typography>
                                  <Typography>
                                    <Typography
                                      display={"inline"}
                                      variant="subtitle2"
                                    >
                                      Price:{" "}
                                    </Typography>
                                    <Typography display={"inline"}>
                                      {currencyformatter.format(item.price)}
                                    </Typography>
                                  </Typography>
                                  <Typography>
                                    <Typography
                                      display={"inline"}
                                      variant="subtitle2"
                                    >
                                      Size:{" "}
                                    </Typography>
                                    <Typography display={"inline"}>
                                      {item.size}
                                    </Typography>
                                  </Typography>
                                  <Typography>{item.description}</Typography>
                                  <Grid container>
                                    <Grid item xs={3} pt={3}>
                                      <FormNumeric2
                                        size={"small"}
                                        name={"numQuantity"}
                                        label={"Quantity"}
                                        defaultValue={1}
                                        decimalScale={0}
                                        onChange={(e) => {
                                          item.numQuantity = e.target.value;
                                        }}
                                        value={item.numQuantity ?? 1}
                                      ></FormNumeric2>
                                    </Grid>
                                    <Grid item xs={3} mt={1}>
                                      <GridActionButton
                                        type="add"
                                        onClick={() => {
                                          handleAdd(
                                            item.id,
                                            item.numQuantity ?? 1
                                          );
                                        }}
                                        tooltip="Add"
                                        disabled={adding}
                                      />
                                    </Grid>
                                  </Grid>
                                </Stack>
                              </Grid>
                            </Grid>
                            <Divider my={6} />
                          </>
                        ))}
                  </Items>
                </List>
              )}
              <Stack
                direction="row"
                spacing={2}
                justifyContent="center"
                alignItems="center"
              >
                {providerType === ProviderTypeEnum.Decals && (
                  <FormBackButton
                    onClick={() => {
                      handleStep(ProviderTypeEnum.Plaque);
                    }}
                    isSubmitting={false}
                  ></FormBackButton>
                )}
                <FormCancelButton
                  onClick={handleCancel}
                  isSubmitting={false}
                ></FormCancelButton>
                {providerType === ProviderTypeEnum.Plaque && (
                  <FormNextButton
                    onClick={() => {
                      handleStep(ProviderTypeEnum.Decals);
                    }}
                    isSubmitting={false}
                  ></FormNextButton>
                )}
                {providerType === ProviderTypeEnum.Decals && (
                  <FormNextButton
                    onClick={handleGotoDeliveryAddress}
                    isSubmitting={false}
                  ></FormNextButton>
                )}
              </Stack>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={4}>
          <CartItems
            projectId={projectId ?? ""}
            setRefreshData={setRefreshData}
            refreshData={refreshData}
          ></CartItems>
        </Grid>
      </Grid>
    </>
  );
};

export default SelectPlaques;
