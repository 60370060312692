import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { rgba } from "polished";
import {
  Box,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Typography as MuiTypography,
  Skeleton,
} from "@mui/material";
import { FormButton } from "src/components/formControls";

const illustrationCardStyle = (props: any) => css`
  ${props.illustration &&
  props.theme.palette.mode !== "dark" &&
  `
    background: ${rgba(props.theme.palette.primary.main, 0.125)};
    color: ${props.theme.palette.primary.main};
  `}
`;

const Card = styled(MuiCard)<{ illustration?: string }>`
  position: relative;

  ${illustrationCardStyle}
`;

const CardContent = styled(MuiCardContent)`
  position: relative;

  &:last-child {
    padding-bottom: ${(props) => props.theme.spacing(4)};
  }
`;

interface DashboarItemProps {
  title: string;
  label1: string | number;
  label2: string;
  chip?: string;
  action: () => void;
  showSkeleton?: boolean;
  hideViewDetails?: boolean;
}

const DashboarItemCreate: React.FC<DashboarItemProps> = ({
  title,
  label1,
  label2,
  chip,
  action,
  showSkeleton,
  hideViewDetails,
}) => {
  return (
    <>
      {showSkeleton ? (
        <Skeleton height={50} />
      ) : (
        <Card>
          <CardContent>
            <MuiTypography variant="h6">{title}</MuiTypography>
            <MuiTypography>
              <Box fontWeight="fontWeightRegular">{label1}</Box>
            </MuiTypography>
          </CardContent>
          <CardContent sx={{ paddingTop: "3px" }}>
            <FormButton
              text={label2}
              onClick={action}
              size="large"
              color="primary"
              fullWidth={true}
            />
          </CardContent>
        </Card>
      )}
    </>
  );
};

export default DashboarItemCreate;
