import React, { useEffect, useState } from "react";
import Popup from "src/components/Popup";
import {
  FormButton,
  FormCheckBox,
  FormRaitingStarts,
  FormSelect,
  FormText,
} from "src/components/formControls";
import { Stack, Grid, Link } from "@mui/material";
import { IContractor } from "src/ts/interfaces/contractor";
import {
  Form,
  useAsyncQuery,
  useAuth,
  useCountry,
  useForm,
  useLog,
  useStateCatalog,
} from "src/hooks";
import contractorService from "src/services/contractorService";
import { ColumnType } from "src/types/enhancedTable";
import LocalEnhancedTable from "src/components/localTable/LocalTable";
import { COUNTRIES } from "src/constants";
import RatingInformation from "../projectOutForBid/Components/RatingInformationPopUp";
import projectContestService from "src/services/projectContestService";
import NewContractorAddedPopUp from "../projectOutForBid/Components/NewContractorAddedPopUp";
import { SearchIcon } from "src/components/icons";
import { IProjectContestOwner } from "src/ts/interfaces/projectOutToBid";

interface Props {
  showAddPreferredPopUp: boolean;
  setShowAddPreferredPopUp: (val: boolean) => void;
  setRefresh: () => void;
  projectId?: string;
  projectContest?: IProjectContestOwner[] | undefined;
}

const initialValues = {
  search: null,
  stateName: null,
  countryId: COUNTRIES.USA,
  stateId: Number.MIN_SAFE_INTEGER,
  isUnion: false,
};

const columns = (
  setShowRatingContractorPopUp: React.Dispatch<
    React.SetStateAction<{
      show: boolean;
      id: number;
    }>
  >
): ColumnType[] => [
  {
    id: "name",
    label: "Name",
    type: "string",
    sort: true,
  },
  {
    id: "phoneNumber",
    label: "Phone",
    type: "string",
    sort: true,
  },
  {
    id: "email",
    label: "Email",
    type: "string",
    sort: true,
  },
  {
    id: "rating",
    label: "Rating",
    type: "custom",
    sort: false,
    callback: (row: IContractor) => {
      return (
        <>
          <Link
            onClick={() => {
              setShowRatingContractorPopUp({
                show: true,
                id: row.companyContractorId ?? 0,
              });
            }}
            component="button"
            variant="body2"
          >
            <FormRaitingStarts value={Math.round(row.rating ?? 0)} />
          </Link>
        </>
      );
    },
  },
  {
    id: "totalCurrentProjects",
    label: "Current Projects",
    type: "string",
    sort: true,
  },
  {
    id: "totalCompletedProjects",
    label: "Comppleted",
    type: "string",
    sort: true,
  },
];

export default function AddPreferredPopUp({
  showAddPreferredPopUp,
  setShowAddPreferredPopUp,
  setRefresh,
  projectId,
  projectContest,
}: Props) {
  const { user } = useAuth();
  const { log } = useLog();
  const { execute, data, isLoading } = useAsyncQuery<IContractor[]>(
    contractorService.getContractors
  );
  const { values, handleInputChange, setValues } = useForm(
    initialValues,
    true,
    []
  );
  const [showRatingContractorPopUp, setShowRatingContractorPopUp] = useState({
    show: false,
    id: 0,
  });
  const [newContractorAddedShowPopUp, setNewContractorAddedShowPopUp] =
    useState(false);

  const [selectedContractorIds, setSelectedContractorIds] = useState<number[]>(
    []
  );

  const { execute: executeCreate, data: dataCreate } = useAsyncQuery(
    projectContestService.postProjectContestList
  );

  const { countriesKeyValue } = useCountry();
  const { states, setCountryId } = useStateCatalog();
  const handleClosePopUp = () => {
    setShowAddPreferredPopUp(false);
  };

  useEffect(() => {
    const getData = async () => {
      await handleGetAll();
    };
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataCreate]);

  useEffect(() => {
    setCountryId(values.countryId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.countryId]);

  const handleSave = async () => {
    if (selectedContractorIds.length === 0)
      return log.warning("Select a contractor.");

    if (projectContest) {
      let userExist = false;
      const contractorIds = data
        .filter((item) => selectedContractorIds.includes(item.id))
        .map((item) => item.companyContractorId);

      userExist = projectContest.some((item) =>
        contractorIds.includes(item.companyContractorId)
      );
      if (userExist) {
        return log.warning(
          "Selected contractor(s) was already added to the project out for bid."
        );
      }
    }

    const dataValue = selectedContractorIds.map((item) => {
      return {
        architectContractor: { companyContractorId: null },
        architectContractorId: item,
        enable: true,
        projectId: projectId,
      };
    });

    await executeCreate(dataValue);
    setRefresh();
    log.success("The contractor was added");
    setNewContractorAddedShowPopUp(true);
  };

  const handleGetAll = async () => {
    await execute(
      user?.companyId,
      values.isUnion,
      values.search,
      values.stateName,
      values.countryId,
      values.stateId
    );
  };

  const selectedRow = (selectedIds: number[]) => {
    setSelectedContractorIds([...selectedIds]);
  };

  return (
    <>
      <Popup
        title="Add Contractor"
        openPopup={showAddPreferredPopUp}
        setOpenPopup={setShowAddPreferredPopUp}
        onClose={handleClosePopUp}
        size="md"
      >
        <Stack direction="row">
          <Grid container spacing={5}>
            <Grid item xs={12}>
              <Form>
                <Grid container spacing={4}>
                  <Grid item xs={8}>
                    <FormText
                      name="search"
                      label="Search"
                      value={values.search}
                      onChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <FormCheckBox
                      value={values.isUnion}
                      onChange={() => {
                        setValues({ ...values, isUnion: !values.isUnion });
                      }}
                      name={"isUnion"}
                      label={"Union Affiliated"}
                    />
                  </Grid>
                  <Grid item xs={2} sx={{ textAlign: "right" }}>
                    <FormButton
                      startIcon={<SearchIcon />}
                      text={"Search"}
                      onClick={handleGetAll}
                      size="small"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <FormSelect
                      name="countryId"
                      label="Country"
                      value={values.countryId}
                      onChange={handleInputChange}
                      options={countriesKeyValue}
                      // showSkeleton={isLoading}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    {values.countryId !== Number.MIN_SAFE_INTEGER &&
                    values.countryId === COUNTRIES.USA ? (
                      <FormSelect
                        name="stateId"
                        label="State"
                        value={values.stateId}
                        onChange={handleInputChange}
                        options={states}
                        defaultValue={{
                          key: Number.MIN_SAFE_INTEGER,
                          value: "All",
                        }}
                        // showSkeleton={isLoading}
                      />
                    ) : (
                      <FormText
                        name="stateName"
                        label="State"
                        value={values.stateName}
                        onChange={handleInputChange}
                      />
                    )}
                  </Grid>
                </Grid>
              </Form>
            </Grid>
            <Grid item xs={12}>
              <LocalEnhancedTable<IContractor>
                refreshGrid={true}
                columns={columns(setShowRatingContractorPopUp)}
                data={Object.values(data ?? [])}
                query={values.name}
                showSkeleton={isLoading}
                subItems={"subBuilding"}
                orderColumn="desc"
                defaultSortColumn="createdDate"
                cellCheckBox={true}
                onSelect={selectedRow}
                selectedItemsId={selectedContractorIds ?? []}
              />
            </Grid>
          </Grid>
        </Stack>
        <Stack direction="row" spacing={2}>
          <FormButton
            text="Close"
            size="small"
            variant="outlined"
            onClick={() => {
              setShowAddPreferredPopUp(false);
            }}
          />
          <FormButton
            text="Add Selected Contractors"
            size="small"
            color="primary"
            onClick={() => {
              handleSave();
            }}
          />
        </Stack>
      </Popup>
      {showRatingContractorPopUp.show && (
        <RatingInformation
          showRatingContractorPopUp={showRatingContractorPopUp.show}
          setShowRatingContractorPopUp={(showDialog: boolean) => {
            setShowRatingContractorPopUp({
              ...showRatingContractorPopUp,
              show: showDialog,
            });
          }}
          informationContractor={showRatingContractorPopUp.id}
        />
      )}
      {newContractorAddedShowPopUp && (
        <NewContractorAddedPopUp
          newContractorAddedShowPopUp={newContractorAddedShowPopUp}
          setNewContractorAddedShowPopUp={setNewContractorAddedShowPopUp}
          setShowContractorPopUp={setShowAddPreferredPopUp}
          preferred={true}
        />
      )}
    </>
  );
}
