export enum CompanyTypeEnum {
  MechanicalContractor = 1,
  ProfessionalEngineering,
  TestAndBalance,
  EnergyContractor,
  Other,
}

export enum subscriptionEnum {
  Boma = 1,
  CertifiedSoftwareSubscription,
  TrainingAccount,
}

export enum AuthorityData {
  Other = 6,
}
