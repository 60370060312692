import React, { useState, useEffect } from "react";
import { Grid, Stack } from "@mui/material";
import { useLog, useAsyncQuery, useAsyncMutation, useAuth } from "src/hooks";
import { FormButton } from "src/components/formControls";
import Popup from "src/components/Popup";
import { ColumnType } from "src/types/enhancedTable";
import LocalEnhancedTable from "src/components/localTable/LocalTable";
import { projectReportChild } from "src/services";
import { IProjectReporDisplayt } from "src/ts/interfaces";
import Study from "./Study";
import {
  ArrowUpwardIcon,
  BuildIcon,
  CheckIcon,
  RemoveIcon,
  TonalityIcon,
} from "src/components/icons";
import { GridActionButton } from "src/components/gridControls";
import DialogMessagePopup from "src/components/DialogMessagePopup";
import projectReportService from "src/services/study/projectReportService";

const icons = [
  <ArrowUpwardIcon />,
  <BuildIcon />,
  <CheckIcon />,
  <RemoveIcon />,
  <TonalityIcon />,
];

const getColumns = (
  gotoStudy: (study: IProjectReporDisplayt) => void,
  showDeleteSubStudyHandler: (selectedSubStudy: IProjectReporDisplayt) => void,
  isPartOfProject: boolean,
  isCompleteParent: boolean
): ColumnType[] => [
  {
    id: "id",
    label: "#",
    type: "custom",
    sort: false,
    callback: (row: IProjectReporDisplayt, col: any, index: number) =>
      index + 1,
  },
  {
    id: "reportTypeName",
    label: "Report",
    type: "custom",
    sort: false,
    callback: (row: IProjectReporDisplayt) => row.reportTypeName,
  },
  { id: "system", label: "System", type: "string", sort: false },
  {
    id: "studyStatus",
    label: "Status",
    type: "custom",
    sort: false,
    callback: (row: IProjectReporDisplayt) => {
      return (
        <>
          {row?.studyStatusId && icons[row?.studyStatusId - 1]}
          {row?.studyStatus}
        </>
      );
    },
  },
  {
    id: "action",
    label: "",
    type: "custom",
    sort: false,
    callback: (row: IProjectReporDisplayt) => (
      <>
        <Stack direction="row">
          <GridActionButton
            type="forward"
            tooltip="Go to Sub-Study"
            onClick={() => {
              gotoStudy(row);
            }}
          />
          {isPartOfProject && !isCompleteParent && (
            <GridActionButton
              type="delete"
              tooltip="Delete Study"
              onClick={() => showDeleteSubStudyHandler(row)}
            />
          )}
        </Stack>
      </>
    ),
  },
];

interface Props {
  isDialogOpen: boolean;
  setIsDialogOpen: (val: boolean) => void;
  onSave: () => void;
  parentStudy: IProjectReporDisplayt | null;
  projectId: number;
  refreshStudies: boolean;
  onSelectStudy: (study: IProjectReporDisplayt) => void;
  isPartOfProject: boolean;
}

const AddSubStudies = (props: Props) => {
  const {
    isDialogOpen,
    setIsDialogOpen,
    onSave,
    parentStudy,
    projectId,
    refreshStudies,
    isPartOfProject,
  } = props;
  const { log } = useLog();
  const { user } = useAuth();
  const [refreshGrid, setRefreshGrid] = useState(false);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [selectedSubStudy, setSelectedSubStudy] =
    useState<IProjectReporDisplayt | null>(null);

  const {
    execute: getChildStudies,
    data: childStudies,
    isLoading,
  } = useAsyncQuery<IProjectReporDisplayt[]>(
    projectReportChild.getChildReports
  );

  useEffect(() => {
    const getData = async () => {
      await getChildStudies(
        projectId,
        parentStudy?.reportId,
        parentStudy?.reportTypeId
      );
    };
    if (parentStudy !== null && isDialogOpen) getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parentStudy, isDialogOpen, refreshGrid, refreshStudies]);

  const cancelHandler = () => {
    setIsDialogOpen(false);
  };

  const gotoToStudyOrAddSubStudy = (study: IProjectReporDisplayt) => {
    props.onSelectStudy && props.onSelectStudy(study);
  };

  const showDeleteSubStudyHandler = (
    selectedSubStudy: IProjectReporDisplayt
  ) => {
    setSelectedSubStudy(selectedSubStudy);
    setShowDeletePopup(true);
  };

  const { execute: removeSubStudy, isSubmitting: removingSubStudy } =
    useAsyncMutation<number>(projectReportService.removeSubStudy, {
      successfulMessage: "The sub-study was removed",
    });

  const deleteSubStudy = async () => {
    if (selectedSubStudy == null) log.warning("Select a Sub study");
    await removeSubStudy(
      projectId,
      selectedSubStudy?.reportId,
      selectedSubStudy?.reportTypeId
    );
    setShowDeletePopup(false);
    setRefreshGrid(!refreshGrid);
  };

  const columns = getColumns(
    gotoToStudyOrAddSubStudy,
    showDeleteSubStudyHandler,
    isPartOfProject,
    parentStudy?.isComplete ?? true
  );

  const getDeleteMessage = () => {
    if (
      user?.role !== "Architect" &&
      user?.role !== "ArchitectAux" &&
      user?.role !== "SysAdmin" &&
      selectedSubStudy !== null
    ) {
      return `Are you certain you want delete ${selectedSubStudy?.reportTypeName}: ${selectedSubStudy?.system}?`;
    }

    if (
      user?.role === "Architect" &&
      user?.role === "ArchitectAux" &&
      user?.role !== "SysAdmin"
    ) {
      return "Are you certain you want delete the report?";
    }

    return "";
  };

  const addSubStudyHandler = () => {
    onSave();
  };

  return (
    <>
      <Popup
        openPopup={isDialogOpen}
        setOpenPopup={setIsDialogOpen}
        title={"Primary Study/Report"}
        onClose={cancelHandler}
      >
        <Grid container spacing={6}>
          <Grid item xs={12}>
            {parentStudy && (
              <Study
                study={parentStudy}
                onSelectStudy={() => {
                  gotoToStudyOrAddSubStudy(parentStudy);
                }}
                isSelectable={false}
              />
            )}
          </Grid>
          <Grid item xs={6}>
            Sub studies list
          </Grid>
          <Grid item xs={6}>
            {/* {isPartOfProject && (
              <>
                <Stack direction="row" justifyContent="flex-end" spacing={1}>
                  <FormButton
                    text=""
                    onClick={underConstructionHandler}
                    isSubmitting={removingSubStudy}
                    size="small"
                    variant="outlined"
                    startIcon={<ArrowUpwardIcon />}
                  />
                  <FormButton
                    text=""
                    onClick={underConstructionHandler}
                    isSubmitting={removingSubStudy}
                    size="small"
                    variant="outlined"
                    startIcon={<ArrowDownwardIcon />}
                  />
                  <FormButton
                    text="Save"
                    onClick={underConstructionHandler}
                    isSubmitting={removingSubStudy}
                    size="small"
                  />
                </Stack>
              </>
            )} */}
          </Grid>
          <Grid item xs={12}>
            <LocalEnhancedTable<IProjectReporDisplayt>
              refreshGrid={refreshGrid}
              columns={columns}
              data={childStudies}
              showSkeleton={isLoading}
              rowsPerPageOptions={[5]}
              skeletonRows={5}
            />
          </Grid>
        </Grid>
        <>
          <Stack
            direction="row"
            spacing={2}
            justifyContent="center"
            alignItems="center"
          >
            <FormButton
              text="Close"
              onClick={cancelHandler}
              isSubmitting={removingSubStudy}
              size="small"
              variant="outlined"
            />
            {isPartOfProject && !parentStudy?.isComplete && (
              <FormButton
                text="New Sub Study"
                onClick={addSubStudyHandler}
                isSubmitting={removingSubStudy}
                size="small"
              />
            )}
          </Stack>
        </>
      </Popup>
      <DialogMessagePopup
        title="Warning"
        text={getDeleteMessage()}
        showPopup={showDeletePopup}
        setShowPopup={setShowDeletePopup}
        onSave={deleteSubStudy}
        isSubmitting={false}
      />
    </>
  );
};

export default AddSubStudies;
