import { IPostShareDocumentRequest } from "src/ts/interfaces";
import axios from "../utils/axios";

const getCheckCode = async (code: string) => {
  return axios.get(`sharedDocument/checkCode/${code}`);
};

const downloadFile = async (code: string) => {
  return axios.put(`SharedDocument/GetFile/${code}`, null, {
    responseType: "blob",
    timeout: 60000,
  });
};

const share = async (transaction: IPostShareDocumentRequest) => {
  return axios.post(`sharedDocument/share`, transaction);
};

const sharedDocumentServices = {
  getCheckCode,
  downloadFile,
  share,
};

export default sharedDocumentServices;
