import { ProjectWithBogus } from "src/ts/interfaces/project/projectDto";
import { useEffect, useState } from "react";
import getProjectSingleService from "src/services/getProjectSingleService";
import ProjectFormArchitect from "../../createEditProject/components/ProjectFormArchitect";

interface GeneralProjectProps {
  companyId: number;
  setProjectResponse: (val: number) => void;
  setActiveStep: (val: number) => void;
  projectResponse: number;
  projectId: number;
}

const initialValues: ProjectWithBogus = {
  bogusStartDate: null,
  bogusStartDateTime: "8:00 AM",
  bogusActualStartDate: null,
  bogusActualStartDateTime: "8:00 AM",
  name: "",
  estimatedTimeDurationUnit: 0,
  startDate: null,
  actualStartDate: null,
  estimatedTimeDuration: 0,
  buildingId: -1,
  measurementSystem: 1,
  suppliedPowerGridFrequency: null,
  auditAreaSqFt: null,
  auditAreaCuFt: null,
  isParkingAreaPartOfThisTest: false,
  requiredComprehensiveEnergyTest: false,
  printDrawingsAvailableOnSite: false,
  printAddendumDateUsedForProposal: null,
  specificationsProvided: false,
  addendumBidForThisProject: 0,
  liftWorkRequired: false,
  liftWorkOnSite: false,
  liftProvidedByOthers: false,
  roofAccessHatchAvailable: 0,
  laddersRequired: false,
  id: 0,
  equipmentCalibrationReportRequired: false,
  visibleToAllContractors: false,
  publishInAuditMasterPro: false,
  tolerancesWereSaved: false,
  sendProposalThroughAMP: false,
  projectStatusId: 0,
  isTemplate: false,
  templateWasUsed: false,
  currentProject: false,
  activeProject: false,
  disabled: false,
  size: 0,
  completedBySuperAdmin: false,
  wasOpenedByContractor: false,
  projectAgreementsAccepted: false,
  isInfectiousControlProject: false,
  computerEstimatedTimeRemaining: 0,
  techniciansEstimatedTimeRemainingIsDays: false,
  completionPaymentByOwner: false,
  elevation: 0,
  gridView: false,
  isOpenDispute: false,
  ductLeakageTesting: false,
  vibrationTestingForMotors: false,
  soundTestingLocations: false,
  hydronicPipeleakage: false,
  ufadPlenumLeakage: false,
  otherTesting: false,
  buildingEnergyEfficiency: false,
  airTestingBalancing: false,
  hydronicWaterBalancing: false,
  hvacEnergyPerformance: false,
  commissioning: false,
  proposedStartDateTimeUnknown: false,
  auditAreaUnknown: false,
  auditAreaUnknownCuFt: false,
  projectBomaTypeId: null,
  projectBomaType: null,
  projectDescription: "",
  projectEstimatedTime: [],
};

export default function GeneralProject({
  companyId,
  setProjectResponse,
  setActiveStep,
  projectId,
}: GeneralProjectProps) {
  const [values, setValues] = useState<ProjectWithBogus>(initialValues);

  useEffect(() => {
    const getData = async () => {
      if (projectId !== 0) {
        const response = await getProjectSingleService.getProjectSingle(
          projectId
        );
        setValues(response.data);
      }
    };
    getData();
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
  }, []);

  return (
    <>
      <ProjectFormArchitect
        project={values}
        setProjectResponse={setProjectResponse}
        setActiveStep={setActiveStep}
      />
    </>
  );
}
