import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import {
  Card as MuiCard,
  CardContent,
  Divider as MuiDivider,
  Grid,
  Typography,
  Box,
  Skeleton,
  CircularProgress,
} from "@mui/material";
import { spacing } from "@mui/system";
import { useAsyncQuery, useLog } from "src/hooks";
import HeaderPage from "src/components/page/HeaderPage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAdjust,
  faArchive,
  faAtom,
  faBolt,
  faBookmark,
  faCertificate,
  faGears,
  faQrcode,
  faReorder,
  faShekel,
  faShield,
  faTable,
} from "@fortawesome/free-solid-svg-icons";
import { faEmpire, faTrello, faYelp } from "@fortawesome/free-brands-svg-icons";
import reportTypeService from "src/services/study/reportTypeService";
import { IReportType } from "src/ts/interfaces";
import { SysAdminDocumentService } from "src/services";
import FileUtils from "src/utils/file";
const style = {
  bgcolor: "background.default",
  paddingRight: "10px",
};
const Divider = styled(MuiDivider)(spacing);
const Card = styled(MuiCard)(spacing);

const SampleReports = () => {
  const { log } = useLog();
  const [isDownloading, setIsDownloading] = useState(false);
  const { execute, data, isLoading } = useAsyncQuery<any>(
    reportTypeService.getReportTypeGrouped
  );

  useEffect(() => {
    const getData = async () => {
      await execute();
    };

    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const icons: Record<string, any> = {
    "icon-shield": <FontAwesomeIcon icon={faShield} size="2x" color="orange" />,
    "icon-bolt": <FontAwesomeIcon icon={faBolt} size="2x" />,
    "icon-certificate": <FontAwesomeIcon icon={faCertificate} size="2x" />,
    "icon-adjust": <FontAwesomeIcon icon={faAdjust} size="2x" />,
    "icon-empire": <FontAwesomeIcon icon={faEmpire} size="2x" />,
    "icon-ge": <FontAwesomeIcon icon={faAtom} size="2x" />,
    "icon-yelp": <FontAwesomeIcon icon={faYelp} size="2x" />,
    "icon-reorder": <FontAwesomeIcon icon={faReorder} size="2x" />,
    "icon-table": <FontAwesomeIcon icon={faTable} size="2x" />,
    "icon-archive": <FontAwesomeIcon icon={faArchive} size="2x" />,
    "icon-bookmark": <FontAwesomeIcon icon={faBookmark} size="2x" />,
    "icon-trello": <FontAwesomeIcon icon={faTrello} size="2x" />,
    "icon-gears": <FontAwesomeIcon icon={faGears} size="2x" />,
    "icon-shekel": <FontAwesomeIcon icon={faShekel} size="2x" />,
    "icon-qrcode": <FontAwesomeIcon icon={faQrcode} size="2x" />,
  };

  const [downloadingName, setDownloadingName] = useState("");
  const downloadDocument = async (documentName: string) => {
    if (documentName) {
      try {
        setIsDownloading(true);
        setDownloadingName(documentName);
        const response = await SysAdminDocumentService.downloadSampleReportFile(
          documentName
        );

        if (response.data) {
          if (
            documentName.indexOf(".pdf") === -1 &&
            documentName.indexOf(".xls") === -1 &&
            documentName.indexOf(".xslx") === -1
          ) {
            documentName = documentName + ".pdf";
          }

          FileUtils.downloadFile(response.data, documentName);
        }
      } catch (error: any) {
        if (error?.message?.status !== 404)
          log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      } finally {
        setDownloadingName("");
        setIsDownloading(false);
      }
    } else {
    }
  };

  const renderStudies = (
    items: IReportType[],
    isLoading: boolean,
    isDownloading: boolean
  ) => {
    return (
      <>
        {isLoading && (
          <Grid container sx={style} spacing={2}>
            {Array.from({ length: 6 }, (_, i) => (
              <Grid item xs={4}>
                <Skeleton height={130}></Skeleton>
              </Grid>
            ))}
          </Grid>
        )}
        {!isLoading && (
          <Grid container sx={style} spacing={2}>
            {items?.map((studyTypes) => {
              return (
                <Grid item xs={4} key={studyTypes.id}>
                  <Card mb={1}>
                    <CardContent
                      onClick={() => {
                        if (!isDownloading) {
                          if (studyTypes.color !== "bg-grayDark")
                            downloadDocument(
                              `${studyTypes.name} (${studyTypes.reportLabel})`
                            );
                        }
                      }}
                      sx={
                        studyTypes.color === "bg-grayDark"
                          ? {
                              backgroundColor: "text.disabled",

                              "&:hover": {
                                cursor: "not-allowed",
                              },
                            }
                          : {
                              "&:hover": {
                                backgroundColor: "primary.main",
                                color: "white",
                                cursor: isDownloading ? "default" : "pointer",
                              },
                            }
                      }
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Box sx={{ paddingRight: "5px" }}>
                          {isDownloading &&
                          `${studyTypes.name} (${studyTypes.reportLabel})` ===
                            downloadingName ? (
                            <CircularProgress size="1rem" color="warning" />
                          ) : (
                            icons[studyTypes.icon]
                          )}
                        </Box>
                        <Box>
                          <Typography>
                            <b>{studyTypes.reportLabel}</b>
                          </Typography>
                          <Typography>{studyTypes.name}</Typography>
                        </Box>
                      </Box>
                    </CardContent>
                  </Card>
                </Grid>
              );
            })}
          </Grid>
        )}
      </>
    );
  };

  return (
    <>
      <HeaderPage
        title={`Sample Reports`}
        parentText="Dashboard"
        parentLink={`/`}
      />
      <Divider my={6} />
      <Grid container spacing={2}>
        <Grid item xs={12} pt={30} sx={{ marginLeft: "8px" }}>
          <Grid container>
            <Grid item xs={12}>
              <Grid container sx={style} spacing={2}>
                {renderStudies(data?.groupType1, isLoading, isDownloading)}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} pt={30} pl={16} sx={{ marginLeft: "8px" }}>
          <Grid container>
            <Grid item xs={12}>
              <Grid container sx={style} spacing={2}>
                {renderStudies(data?.groupType3, isLoading, isDownloading)}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default SampleReports;
