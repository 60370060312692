import {
  IAssetDescription,
  IAssetDescriptionUpdate,
} from "src/ts/interfaces/companyAssets";
import axios from "src/utils/axios";

const getAssesmentHistory = (assetId: number) => {
  return axios.get(`AssetAssessment/GetByAsset?assetId=${assetId}`);
};

const postDescription = (data: IAssetDescription) => {
  return axios.post(`AssetAssessment`, data);
};

const updateDescription = (data: IAssetDescriptionUpdate) => {
  return axios.put(`AssetAssessment`, data);
};

const deleteDescription = (id: string) => {
  return axios.delete(`AssetAssessment?id=${id}`);
};

const assetAssessmentService = {
  getAssesmentHistory,
  postDescription,
  updateDescription,
  deleteDescription,
};

export default assetAssessmentService;
