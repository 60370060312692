import axios from "src/utils/axios";
import { IBuildingSubType } from "src/ts/interfaces/catalogs";
import { IKeyValue } from "src/ts/interfaces";

const getAll = () => {
  return axios.get<IBuildingSubType[]>("BuildingSubType");
};

const add = (unitLabel: IBuildingSubType) => {
  return axios.post("BuildingSubType", unitLabel);
};

const update = (unitLabel: IBuildingSubType) => {
  return axios.put(`BuildingSubType/${unitLabel.id}`, unitLabel);
};

const remove = (id: Number) => {
  return axios.delete(`BuildingSubType/${id}`);
};

const getKeyValues = (typeId: number) => {
  return new Promise<IKeyValue<number, string>[]>((resolve, reject) => {
    axios
      .get<Array<IBuildingSubType>>(`BuildingSubType/${typeId}`)
      .then((response) => {
        const keyValuePair: IKeyValue<number, string>[] = response.data.map(
          (item) => {
            return {
              key: item.id!,
              value: item.name,
            };
          }
        );

        resolve(keyValuePair);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const buildingSubType = { getAll, add, update, remove, getKeyValues };
export default buildingSubType;
