import { IEmailBlackList } from "../ts/interfaces/emailBlackList";
import axios from "../utils/axios";

const getAll = () => {
  return axios.get<IEmailBlackList[]>("emailBlackList");
};

const add = (email: string) => {
  return axios.post("emailBlackList", { email });
};

const remove = (id: number) => {
  return axios.delete(`emailBlackList/${id}`);
};

const emailBlackListService = {
  getAll,
  add,
  remove,
};

export default emailBlackListService;
