import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import {
  Grid,
  Drawer,
  Box,
  Fab as MuiFab,
  LinearProgress,
  Typography,
  Divider as MuiDivider,
} from "@mui/material";
import { ToolTip } from "../others/ToolTip";
import {
  CheckOutlined,
  CloudDownload,
  PictureAsPdfOutlined,
} from "@mui/icons-material";
import FormTitleBox from "../formControls/FormTitleBox";
import { useAppDispatch, useAppSelector } from "src/hooks";
import { GridActionButton } from "../gridControls";
import floatingBarPdfGeneration from "src/redux/slices/flotatingBarPdfGeneration";
import { pdfExportService } from "src/services";
import FileUtils from "src/utils/file";
import ProgressBarModal from "../modals/ProgressBarModal";
import { spacing } from "@mui/system";

const Fab = styled(MuiFab)`
  position: fixed;
  right: ${(props) => props.theme.spacing(2)};
  bottom: ${(props) => props.theme.spacing(5)};
  z-index: 1;
`;
const Wrapper = styled.div`
  width: 400px;
  overflow-x: hidden;
`;

type Anchor = "right";
const Divider = styled(MuiDivider)(spacing);
const DocumentsCache = () => {
  const [state, setState] = React.useState({
    right: false,
  });
  const [showProgressBarModal, setProgressBarModal] = useState(false);
  const [progress, setProgress] = useState(0);
  const dispatch = useAppDispatch();
  const pdfGeneration = useAppSelector(
    (state) => state.pdfGeneration.pdfNotifications
  );
  const openModal = useAppSelector((state) => state.pdfGeneration.openModal);

  useEffect(() => {
    if (openModal) {
      setState({ ...state, right: true });
      dispatch(floatingBarPdfGeneration.actions.setOpen(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openModal]);

  const onProgressDownload = (progress: number) => {
    setProgress(progress);
  };

  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setState({ ...state, [anchor]: open });
    };

  const handleDelete = (id: number) => {
    dispatch(floatingBarPdfGeneration.actions.archivePdfNotification(id));
  };

  const handleDownload = async (id: number) => {
    setProgressBarModal(true);
    setProgress(10);
    const response = await pdfExportService.getFileProject(
      id,
      true,
      onProgressDownload
    );
    FileUtils.downloadFile(response.data, "ProjectSummary.pdf");
    setProgressBarModal(false);
  };

  return (
    <>
      {pdfGeneration.length > 0 && (
        <ToolTip title="Toolbar">
          <Fab
            size="small"
            color="info"
            aria-label="Edit"
            onClick={toggleDrawer("right", true)}
          >
            <CloudDownload />
          </Fab>
        </ToolTip>
      )}
      <Drawer
        anchor="right"
        open={state.right}
        onClose={toggleDrawer("right", false)}
      >
        <Wrapper>
          <Box>
            <Grid container>
              <Grid item xs={12}>
                <FormTitleBox title={"Latest Study Generations Cache"}>
                  <Grid container>
                    <Grid item xs={12}>
                      {pdfGeneration?.map((row) => {
                        return (
                          <>
                            {" "}
                            <Grid container>
                              <Grid item xs={2} pl={2} pt={2}>
                                <PictureAsPdfOutlined
                                  color="error"
                                  fontSize="large"
                                />
                              </Grid>
                              <Grid item xs={7}>
                                <Grid container spacing={2}>
                                  <Grid item xs={12}>
                                    {row.name}
                                  </Grid>
                                  <Grid item xs={12}>
                                    {row.percentage !== 100 && (
                                      <LinearProgress
                                        variant="determinate"
                                        value={row.percentage}
                                      />
                                    )}
                                    {row.percentage === 100 && (
                                      <Typography variant="caption">
                                        <Typography
                                          display={"inline"}
                                          paddingRight={2}
                                        >
                                          <CheckOutlined
                                            color="success"
                                            fontSize="inherit"
                                          />
                                        </Typography>
                                        Generation Complete
                                      </Typography>
                                    )}
                                  </Grid>
                                </Grid>
                              </Grid>
                              <Grid item xs={3}>
                                {row.percentage === 100 ? (
                                  <>
                                    <GridActionButton
                                      type="download"
                                      onClick={() => {
                                        handleDownload(row.id);
                                      }}
                                      tooltip="Download"
                                    />
                                    <GridActionButton
                                      type="delete"
                                      onClick={() => {
                                        handleDelete(row.id);
                                      }}
                                      tooltip="Remove"
                                    />
                                  </>
                                ) : (
                                  <Typography>{row.percentage}%</Typography>
                                )}
                              </Grid>
                            </Grid>
                            <Divider />
                          </>
                        );
                      })}
                    </Grid>
                  </Grid>
                </FormTitleBox>
              </Grid>
            </Grid>
          </Box>
        </Wrapper>
      </Drawer>
      <ProgressBarModal
        progress={progress}
        isDialogOpen={showProgressBarModal}
        setIsDialogOpen={setProgressBarModal}
        text={"Download zip file"}
      />
    </>
  );
};

export default DocumentsCache;
