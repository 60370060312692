import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import {
  CardContent,
  CssBaseline,
  Grid,
  Link,
  Card as MuiCard,
  Typography,
  Divider as MuiDivider,
  Stack,
} from "@mui/material";
import { useAsyncMutation, useAsyncQuery, useTheme } from "src/hooks";
import { useNavigate, useParams } from "react-router-dom";
import GlobalStyle from "src/components/GlobalStyle";
import { Box, spacing } from "@mui/system";
import { Helmet } from "react-helmet-async";
import { FormButton, FormTextArea } from "src/components/formControls";
import { THEMES, changeOrdersStatus } from "src/constants";
import { Check, Close } from "@mui/icons-material";
import memorandumService from "src/services/memorandumService";
import { IMemorandumExternal } from "src/ts/interfaces/memorandum";
import { convertUTCDateToLocalDate } from "src/utils/utils";
import DialogMessagePopup from "src/components/DialogMessagePopup";
const Card = styled(MuiCard)(spacing);
const Root = styled.div`
  max-width: 70%;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  display: flex;
  min-height: 100%;
  flex-direction: column;
`;

function RequestMemorandum() {
  const { theme } = useTheme();
  const Divider = styled(MuiDivider)(spacing);
  const navigate = useNavigate();
  let { code } = useParams<{ code: string }>();
  const [confirmModal, setConfirmModal] = useState(false);
  const [rejectModal, setRejectModal] = useState(false);
  const [comments, setComments] = useState("");

  const { execute: executeLoad, data } = useAsyncQuery<IMemorandumExternal>(
    memorandumService.getByCode
  );

  const { execute, isSubmitting } = useAsyncMutation(
    memorandumService.ChangeStatus,
    {
      successfulMessage: `Memorandum of Understanding status was updated`,
      onSuccess: () => {
        setConfirmModal(false);
        setRejectModal(false);
        navigate("/auth/sign-in");
      },
    }
  );

  useEffect(() => {
    const getData = async () => {
      await executeLoad(code);
    };

    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleConfirm = async (status: number) => {
    let newData = {
      ...data,
      memorandumStatusId: status,
      rejectedComment: comments,
      updatedDate: new Date(),
    };
    await execute(newData);
  };

  const acceptedOrRejected =
    data?.memorandumStatus?.name === changeOrdersStatus.Approved.name ||
    data?.memorandumStatus?.name === changeOrdersStatus.Rejected.name;

  const acceptedOrRejectedText =
    data?.memorandumStatus?.name === changeOrdersStatus.Approved.name
      ? changeOrdersStatus.Approved.name
      : changeOrdersStatus.Rejected.name;

  return (
    <Root>
      <CssBaseline />
      <GlobalStyle />
      <Grid container spacing={2} pt={10}>
        <Grid item xs={3}></Grid>
        <Grid item xs={6}>
          <Card mb={1}>
            <CardContent>
              <Helmet title="Confirm" />
              <Link href="https://www.auditmaster.pro/" target="_blank">
                <Box
                  component="img"
                  alt="Logo AMP"
                  src={
                    THEMES.DARK === theme
                      ? "/static/img/AMPWebLogoVectors.svg"
                      : "/static/img/AMPWebLogoVectorsLight.svg"
                  }
                  sx={{
                    marginLeft: "25%",
                    marginRight: "25%",
                    width: "50%",
                    marginBottom: "20px",
                  }}
                />
              </Link>
              <Divider mb={4} />

              {acceptedOrRejected && (
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Typography variant="h4" pb={2} textAlign={"center"}>
                      {` This Memorandum of Understanding was already ${acceptedOrRejectedText}.`}
                    </Typography>
                  </Grid>
                </Grid>
              )}
              {!acceptedOrRejected && (
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Typography variant="h3" pb={2} textAlign={"center"}>
                      Memorandum of Understanding
                    </Typography>
                    <Typography textAlign={"center"}>
                      {convertUTCDateToLocalDate(
                        data?.createdDate.toString()
                      )?.format("MM/DD/YYYY h:mma")}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="h6" pb={2} textAlign={"center"}>
                      {data?.notes}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} textAlign={"center"} spacing={2}>
                    <Divider mb={4} mt={3} />
                    <Stack
                      direction="row"
                      spacing={2}
                      justifyContent="center"
                      alignItems="center"
                    >
                      <FormButton
                        onClick={() => {
                          setComments("");
                          setRejectModal(true);
                        }}
                        isSubmitting={isSubmitting}
                        variant="outlined"
                        text="Reject"
                        size="small"
                        startIcon={<Close />}
                      ></FormButton>
                      <FormButton
                        onClick={() => {
                          setComments("");
                          setConfirmModal(true);
                        }}
                        isSubmitting={isSubmitting}
                        text="Accept"
                        size="small"
                        startIcon={<Check />}
                      ></FormButton>
                    </Stack>
                  </Grid>
                </Grid>
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <DialogMessagePopup
        title={"Memorandum of Understanding"}
        text={
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Typography>
                <Typography>
                  You're acknowledging you have the authority to accept this
                  Memorandum of Understanding.
                </Typography>
                <Typography pt={2}>
                  Are you certain you want to accept this proposed Memorandum of
                  Understanding?
                </Typography>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <FormTextArea
                name={"comments"}
                label={"Comments"}
                value={comments}
                onChange={(e: any) => {
                  setComments(e.target.value);
                }}
              ></FormTextArea>
            </Grid>
          </Grid>
        }
        showPopup={confirmModal}
        setShowPopup={setConfirmModal}
        isSubmitting={isSubmitting}
        onSave={() => {
          handleConfirm(2);
        }}
      ></DialogMessagePopup>

      <DialogMessagePopup
        title={"Memorandum of Understanding"}
        text={
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Typography>
                Are you certain you want to reject this Memorandum of
                Understanding?
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <FormTextArea
                name={"comments"}
                label={"Comments"}
                value={comments}
                onChange={(e: any) => {
                  setComments(e.target.value);
                }}
              ></FormTextArea>
            </Grid>
          </Grid>
        }
        showPopup={rejectModal}
        setShowPopup={setRejectModal}
        isSubmitting={isSubmitting}
        onSave={() => {
          handleConfirm(3);
        }}
        disabled={!comments}
      ></DialogMessagePopup>
    </Root>
  );
}
export default RequestMemorandum;
