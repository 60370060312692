import axios from "src/utils/axios";

const retestEnergyReport = (
  reportId: number,
  projectId: number,
  reportTypeId: number
) => {
  return axios.put(
    `ProjectReportToRetest/SetEnergyToRetest?id=${reportId}&projectId=${projectId}&typeId=${reportTypeId}`,
    ""
  );
};

const projectReportToRetestService = {
  retestEnergyReport,
};

export default projectReportToRetestService;
