import styled from "@emotion/styled";
import { Typography } from "@mui/material";
import { Doughnut } from "react-chartjs-2";
import {
  IDataDoughnutChart,
  IOptionsDoughnutChart,
} from "src/ts/interfaces/dataDoughnutChart";

interface ButtonProps {
  data: IDataDoughnutChart;
  title: string;
  subtitle: string;
}

const FormChartDoughnut = (props: ButtonProps) => {
  const { data, title, subtitle } = props;
  const ChartWrapper = styled.div`
    height: 168px;
    position: relative;
  `;
  const DoughnutInner = styled.div`
    width: 100%;
    position: absolute;
    top: 50%;
    left: 0;
    margin-top: -22px;
    text-align: center;
    z-index: 0;
  `;

  const options: IOptionsDoughnutChart = {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
    },
    cutout: "80%",
  };

  return (
    <ChartWrapper>
      <DoughnutInner>
        {title && title !== "" && <Typography variant="h4">{title}</Typography>}
        {subtitle && subtitle !== "" && (
          <Typography variant="caption">{subtitle}</Typography>
        )}
      </DoughnutInner>
      <Doughnut data={data} options={options} />
    </ChartWrapper>
  );
};

export default FormChartDoughnut;
