import axios from "src/utils/axios";
import { IFanManufacturer } from "src/ts/interfaces/catalogs";

const getManufacturers = () => {
  return axios.get<IFanManufacturer[]>("FanManufacturer");
};

const fanManufacturerService = {
  getManufacturers,
};

export default fanManufacturerService;
