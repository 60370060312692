import React from "react";
import { Grid, Stack } from "@mui/material";
import { ITechnicianQuizBacklog } from "src/ts/interfaces";
import { ColumnType } from "src/types/enhancedTable";
import { useForm, Form, usePermissions } from "src/hooks";
import FormText from "src/components/formControls/FormText";
import LocalEnhancedTable from "src/components/localTable/LocalTable";
import { GridActionButton } from "src/components/gridControls";
import FormTextEditor from "src/components/formControls/FormTextEditor";
import { Permission as PermissionTypes } from "src/ts/enums";
const initialValues = {
  id: 0,
  name: "",
  statusId: "",
};

interface QuestionsProps {
  data: ITechnicianQuizBacklog[];
  disableFunction: Function;
  editFunction: Function;
  deleteFunction: Function;
  refreshGrid: boolean;
}

const QuestionsCatalogGrid = (prop: QuestionsProps) => {
  const { values: filterValues, handleInputChange: handleFilterChange } =
    useForm(initialValues, true, []);
  const { fullAccess, readOnly } = usePermissions(PermissionTypes.Quiz);
  const columns = (fullAccess: boolean): ColumnType[] => [
    {
      id: "id",
      label: "Id",
      type: "string",
      sort: true,
    },
    {
      id: "description",
      label: "Description",
      type: "custom",
      sort: false,
      callback: (row: ITechnicianQuizBacklog) => {
        return (
          <>
            <FormTextEditor
              value={row.description}
              onChange={function (e: any): void {
                throw new Error("Function not implemented.");
              }}
              name={"description" + row.id}
              readonly={true}
            ></FormTextEditor>
          </>
        );
      },
    },
    {
      id: "status",
      label: "Actions",
      type: "custom",
      sort: false,
      callback: (row: ITechnicianQuizBacklog) => {
        return (
          <Grid>
            <Stack direction="row">
              <GridActionButton
                type={readOnly ? "view" : "edit"}
                onClick={() => prop.editFunction(row)}
                tooltip={readOnly ? "View" : "Edit"}
              />
              {fullAccess &&
                (row.isDisabled ? (
                  <GridActionButton
                    type="enable"
                    onClick={() => {
                      prop.disableFunction(row);
                    }}
                    tooltip="Enable"
                  />
                ) : (
                  <GridActionButton
                    type="disable"
                    onClick={() => {
                      prop.disableFunction(row);
                    }}
                    tooltip="Disable"
                  />
                ))}

              {fullAccess && (
                <GridActionButton
                  type="delete"
                  onClick={() => prop.deleteFunction(row)}
                  tooltip="Delete"
                />
              )}
            </Stack>
          </Grid>
        );
      },
    },
  ];

  return (
    <React.Fragment>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Form>
            <Grid container>
              <Grid item xs={12}>
                <FormText
                  name="name"
                  label="Name"
                  value={filterValues.name}
                  onChange={handleFilterChange}
                />
              </Grid>
            </Grid>
          </Form>
        </Grid>
        <Grid item xs={12}>
          <LocalEnhancedTable<ITechnicianQuizBacklog>
            refreshGrid={prop.refreshGrid}
            columns={columns(fullAccess || false)}
            data={prop.data}
            query={filterValues.name}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default QuestionsCatalogGrid;
