import { Card, CardContent, Grid, Typography } from "@mui/material";
import FolderCopyIcon from "@mui/icons-material/FolderCopy";
import FolderSharedIcon from "@mui/icons-material/FolderShared";
import SourceIcon from "@mui/icons-material/Source";
import SnippetFolderIcon from "@mui/icons-material/SnippetFolder";
import StorageIcon from "@mui/icons-material/Storage";
import { IStorageMetrics } from "src/ts/interfaces/storage";

interface MemoryDashboardProp {
  unitType: string;
  storageMetric: IStorageMetrics;
}

const MemoryDashboardItems = (props: MemoryDashboardProp) => {
  const { unitType, storageMetric } = props;

  return (
    <>
      <Grid container p={2}>
        <Grid item md={4} p={1} pl={2}>
          <Card>
            <CardContent>
              <Typography variant="h4" mb={4} align="center">
                Total Storage Used
              </Typography>
              <Grid container>
                <Grid item md={1}>
                  <StorageIcon />
                </Grid>
                <Grid item md={11}>
                  {storageMetric && (
                    <Typography variant="h2" mb={4} align="right">
                      {unitType === "1" && (
                        <>
                          {Intl.NumberFormat("en-US", {
                            maximumFractionDigits: 2,
                          }).format(storageMetric?.totalUsedStorageInGb)}{" "}
                          {`${"GB"}`}
                        </>
                      )}
                      {unitType === "2" && (
                        <>
                          {Intl.NumberFormat("en-US", {
                            maximumFractionDigits: 2,
                          }).format(storageMetric?.totalUsedStorageInMb)}{" "}
                          {`${"MB"}`}
                        </>
                      )}
                    </Typography>
                  )}
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item md={4} p={1} pl={2}>
          <Card>
            <CardContent>
              <Typography variant="h4" mb={4} align="center">
                Storage Size
              </Typography>
              <Grid container>
                <Grid item md={1}>
                  <StorageIcon />
                </Grid>
                <Grid item md={11}>
                  {storageMetric && (
                    <Typography variant="h2" mb={4} align="right">
                      {unitType === "1" && (
                        <>
                          {Intl.NumberFormat("en-US", {
                            maximumFractionDigits: 2,
                          }).format(storageMetric?.storageSizeInGb)}{" "}
                          {`${"GB"}`}
                        </>
                      )}
                      {unitType === "2" && (
                        <>
                          {Intl.NumberFormat("en-US", {
                            maximumFractionDigits: 2,
                          }).format(storageMetric?.storageSizeInMb)}{" "}
                          {`${"MB"}`}
                        </>
                      )}
                    </Typography>
                  )}
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item md={4} p={1} pl={2}>
          <Card>
            <CardContent>
              <Typography variant="h4" mb={4} align="center">
                Storage Available
              </Typography>
              <Grid container>
                <Grid item md={1}>
                  <StorageIcon />
                </Grid>
                <Grid item md={11}>
                  {storageMetric && (
                    <Typography variant="h2" mb={4} align="right">
                      <>
                        {Intl.NumberFormat("en-US", {
                          maximumFractionDigits: 2,
                        }).format(
                          storageMetric?.storagePercentageAvailable
                        )}{" "}
                        {`${"%"}`}
                      </>
                    </Typography>
                  )}
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item md={3} p={1} pl={2}>
          <Card>
            <CardContent>
              <Typography variant="h4" mb={4} align="center">
                Company Documents
              </Typography>
              <Grid container>
                <Grid item md={1}>
                  <FolderCopyIcon />
                </Grid>
                <Grid item md={11}>
                  {storageMetric && (
                    <Typography variant="h2" mb={4} align="right">
                      {unitType === "1" && (
                        <>
                          {Intl.NumberFormat("en-US", {
                            maximumFractionDigits: 2,
                          }).format(storageMetric?.companyDocumentSizeGb)}{" "}
                          {`${"GB"}`}
                        </>
                      )}
                      {unitType === "2" && (
                        <>
                          {Intl.NumberFormat("en-US", {
                            maximumFractionDigits: 2,
                          }).format(storageMetric?.companyDocumentSize)}{" "}
                          {`${"MB"}`}
                        </>
                      )}
                    </Typography>
                  )}
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item md={3} p={1} pl={2}>
          <Card>
            <CardContent>
              <Typography variant="h4" mb={4} align="center">
                Project documents
              </Typography>
              <Grid container>
                <Grid item md={1}>
                  <SourceIcon />
                </Grid>
                <Grid item md={11}>
                  {storageMetric && (
                    <Typography variant="h2" mb={4} align="right">
                      {unitType === "1" && (
                        <>
                          {Intl.NumberFormat("en-US", {
                            maximumFractionDigits: 2,
                          }).format(storageMetric?.projectDocumentSizeGb)}{" "}
                          {`${"GB"}`}
                        </>
                      )}
                      {unitType === "2" && (
                        <>
                          {Intl.NumberFormat("en-US", {
                            maximumFractionDigits: 2,
                          }).format(storageMetric?.projectDocumentSize)}{" "}
                          {`${"MB"}`}
                        </>
                      )}
                    </Typography>
                  )}
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item md={3} p={1} pl={2}>
          <Card>
            <CardContent>
              <Typography variant="h4" mb={4} align="center">
                User documents
              </Typography>
              <Grid container>
                <Grid item md={1}>
                  <FolderSharedIcon />
                </Grid>
                <Grid item md={11}>
                  {storageMetric && (
                    <Typography variant="h2" mb={4} align="right">
                      {unitType === "1" && (
                        <>
                          {Intl.NumberFormat("en-US", {
                            maximumFractionDigits: 2,
                          }).format(storageMetric?.userDocumentSizeGb)}{" "}
                          {`${"GB"}`}
                        </>
                      )}
                      {unitType === "2" && (
                        <>
                          {Intl.NumberFormat("en-US", {
                            maximumFractionDigits: 2,
                          }).format(storageMetric?.userDocumentSize)}{" "}
                          {`${"MB"}`}
                        </>
                      )}
                    </Typography>
                  )}
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item md={3} p={1} pl={2}>
          <Card>
            <CardContent>
              <Typography variant="h4" mb={4} align="center">
                Project's documents library
              </Typography>
              <Grid container>
                <Grid item md={1}>
                  <FolderCopyIcon />
                </Grid>
                <Grid item md={11}>
                  {storageMetric && (
                    <Typography variant="h2" mb={4} align="right">
                      {unitType === "1" && (
                        <>
                          {Intl.NumberFormat("en-US", {
                            maximumFractionDigits: 2,
                          }).format(storageMetric?.libraryDocumentSizeGb)}{" "}
                          {`${"GB"}`}
                        </>
                      )}
                      {unitType === "2" && (
                        <>
                          {Intl.NumberFormat("en-US", {
                            maximumFractionDigits: 2,
                          }).format(storageMetric?.libraryDocumentSize)}{" "}
                          {`${"MB"}`}
                        </>
                      )}
                    </Typography>
                  )}
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item md={3} p={1} pl={2}>
          <Card>
            <CardContent>
              <Typography variant="h4" mb={4} align="center">
                Project's Picture log
              </Typography>
              <Grid container>
                <Grid item md={1}>
                  <FolderCopyIcon />
                </Grid>
                <Grid item md={11}>
                  {storageMetric && (
                    <Typography variant="h2" mb={4} align="right">
                      {unitType === "1" && (
                        <>
                          {Intl.NumberFormat("en-US", {
                            maximumFractionDigits: 2,
                          }).format(storageMetric?.pictureLogSizeGb)}{" "}
                          {`${"GB"}`}
                        </>
                      )}
                      {unitType === "2" && (
                        <>
                          {Intl.NumberFormat("en-US", {
                            maximumFractionDigits: 2,
                          }).format(storageMetric?.pictureLogSize)}{" "}
                          {`${"MB"}`}
                        </>
                      )}
                    </Typography>
                  )}
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item md={3} p={1} pl={2}>
          <Card>
            <CardContent>
              <Typography variant="h4" mb={4} align="center">
                Equipment Documents
              </Typography>
              <Grid container>
                <Grid item md={1}>
                  <SnippetFolderIcon />
                </Grid>
                <Grid item md={11}>
                  {storageMetric && (
                    <Typography variant="h2" mb={4} align="right">
                      {unitType === "1" && (
                        <>
                          {Intl.NumberFormat("en-US", {
                            maximumFractionDigits: 2,
                          }).format(0)}{" "}
                          {`${"GB"}`}
                        </>
                      )}
                      {unitType === "2" && (
                        <>
                          {Intl.NumberFormat("en-US", {
                            maximumFractionDigits: 2,
                          }).format(0)}{" "}
                          {`${"MB"}`}
                        </>
                      )}
                    </Typography>
                  )}
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default MemoryDashboardItems;
