import { IProjectReporDisplayt } from "src/ts/interfaces";
import styled from "@emotion/styled";
import {
  Card as MuiCard,
  CardContent as MuiCardContent,
  Typography as MuiTypography,
  Box,
  IconButton,
  Chip,
} from "@mui/material";
import { spacing } from "@mui/system";
import { convertUTCDateToLocalDate } from "src/utils/utils";
import {
  faShield,
  faBolt,
  faCertificate,
  faAdjust,
  faReorder,
  faTable,
  faAtom,
  faArchive,
  faBookmark,
  faGears,
  faShekel,
  faQrcode,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEmpire, faYelp, faTrello } from "@fortawesome/free-brands-svg-icons";
import { FormCheckBox } from "src/components/formControls";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { colorsStudies } from "src/constants";
import StudyStatusComponent from "./StudyStatusComponent";
import { useState } from "react";
import InformationStudiesPopUp from "./InformationStudiesPopUp";
import ConfigureStudyPopup from "../../Template/ConfigureStudyPopup";

const Card = styled(MuiCard)(spacing);

const CardContent = styled(MuiCardContent)<{ pb?: number }>`
  &:last-child {
    padding-bottom: ${(props) => props.theme.spacing(4)};
  }
`;

const TaskWrapper = styled(Card)`
  border: 1px solid ${(props) => props.theme.palette.grey[300]};
  margin: ${(props) => props.theme.spacing(2)};
  cursor: grab;

  &:hover {
    background: ${(props) => props.theme.palette.background.default};
  }
`;

const TaskWrapperContent = styled(CardContent)`
  position: relative;

  &:last-child {
    padding-bottom: ${(props) => props.theme.spacing(2)};
  }
`;

interface Props {
  study: IProjectReporDisplayt;
  onToggleSelection?: (id: number) => void;
  isSelected?: (id: number) => boolean;
  onSelectStudy?: (study: IProjectReporDisplayt) => void;
  isSelectable: boolean;
  readOnly?: boolean;
  groupId?: number;
  projectId?: number;
  disable?: boolean;
  isEnergy?: boolean;
}

const Study = ({
  study,
  onToggleSelection,
  isSelected,
  onSelectStudy,
  isSelectable,
  readOnly,
  groupId,
  projectId,
  disable,
  isEnergy,
}: Props) => {
  const [informationStuidesShowPopUp, setInformationStuidesShowPopUp] =
    useState(false);

  const [configureStudyPopup, setConfigureStudyPopup] = useState({
    show: false,
    projectId: 0,
    projectReportId: 0,
    reportName: "",
    groupType: 0,
  });

  const icons: Record<string, any> = {
    "icon-shield": <FontAwesomeIcon icon={faShield} size="2x" color="orange" />,
    "icon-bolt": <FontAwesomeIcon icon={faBolt} size="2x" />,
    "icon-certificate": <FontAwesomeIcon icon={faCertificate} size="2x" />,
    "icon-adjust": <FontAwesomeIcon icon={faAdjust} size="2x" />,
    "icon-empire": <FontAwesomeIcon icon={faEmpire} size="2x" />,
    "icon-ge": <FontAwesomeIcon icon={faAtom} size="2x" />,
    "icon-yelp": <FontAwesomeIcon icon={faYelp} size="2x" />,
    "icon-reorder": <FontAwesomeIcon icon={faReorder} size="2x" />,
    "icon-table": <FontAwesomeIcon icon={faTable} size="2x" />,
    "icon-archive": <FontAwesomeIcon icon={faArchive} size="2x" />,
    "icon-bookmark": <FontAwesomeIcon icon={faBookmark} size="2x" />,
    "icon-trello": <FontAwesomeIcon icon={faTrello} size="2x" />,
    "icon-gears": <FontAwesomeIcon icon={faGears} size="2x" />,
    "icon-shekel": <FontAwesomeIcon icon={faShekel} size="2x" />,
    "icon-qrcode": <FontAwesomeIcon icon={faQrcode} size="2x" />,
  };

  const onSelectHandler = (id: number) => {
    onToggleSelection && onToggleSelection(id);
  };

  const gotoToStudyOrAddSubStudy = (study: IProjectReporDisplayt) => {
    onSelectStudy && onSelectStudy(study);
  };

  const ReadOnlyStudies = () => (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Box sx={{ paddingRight: "4px", flex: 1 }}>
          <Box> {icons[study?.reportTypeIcon]}</Box>
        </Box>
        <Box sx={{ paddingLeft: "4px", flex: 2 }}>
          <MuiTypography
            component="div"
            color={colorsStudies[study?.reportTypeColor] ?? ""}
          >
            <Box
              sx={{ fontWeight: "bold", m: 1 }}
            >{`${study?.reportTypeName} (${study?.reportLabel})`}</Box>
          </MuiTypography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "end",
            alignItems: "center",
          }}
        >
          <IconButton
            aria-label="toggle password visibility"
            onClick={() => {
              if (onSelectStudy) {
                gotoToStudyOrAddSubStudy(study);
              } else {
                if (groupId === undefined) {
                  setInformationStuidesShowPopUp(true);
                } else {
                  handleOpenConfigurationPopup(study, groupId ?? 0);
                }
              }
            }}
            edge="end"
          >
            <ArrowForwardIcon />
          </IconButton>
        </Box>
      </Box>
    </>
  );

  const handleOpenConfigurationPopup = (
    item: IProjectReporDisplayt,
    groupType: number
  ) => {
    setConfigureStudyPopup({
      show: true,
      projectId: projectId ?? 0,
      projectReportId: item.reportTypeId,
      reportName: item.reportTypeName,
      groupType: groupType,
    });
  };

  return (
    <TaskWrapper mb={4}>
      <TaskWrapperContent>
        {!readOnly ? (
          <>
            {isEnergy && (
              <>
                {study.required ? (
                  <Chip label="Required" size="small" color="error" />
                ) : (
                  <Chip label="Not Required" size="small" color="default" />
                )}
              </>
            )}
            <MuiTypography
              component="div"
              color={colorsStudies[study?.reportTypeColor] ?? ""}
            >
              <Box
                sx={{ fontWeight: "bold", m: 1 }}
              >{`${study?.reportTypeName} (${study?.reportLabel}) - ${study?.id}`}</Box>
            </MuiTypography>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                flexDirection: "row",
              }}
            >
              <Box sx={{ paddingRight: "4px" }}>
                <Box> {icons[study?.reportTypeIcon]}</Box>
              </Box>
              <Box sx={{ paddingLeft: "4px" }}>
                <Box>{`${study?.system}`}</Box>
                {study?.isInUse ? (
                  <Box>Study is under use</Box>
                ) : study.studyStatus === "Complete" ? (
                  <Box>{`Last modified date: ${convertUTCDateToLocalDate(
                    study?.completedDate ?? ""
                  )?.format("MM/DD/yyyy h:mm A")}`}</Box>
                ) : (
                  <Box>{`Last modified date: ${convertUTCDateToLocalDate(
                    study?.dateModified ?? ""
                  )?.format("MM/DD/yyyy h:mm A")}`}</Box>
                )}
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
              }}
            >
              {/* {`Status:`}
          <Chip label={study?.studyStatus} size="small" /> */}
              <StudyStatusComponent row={study} />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              {isSelectable ? (
                <FormCheckBox
                  label="Select"
                  value={isSelected && isSelected(study?.id)}
                  name={""}
                  onChange={() => {
                    onSelectHandler(study?.id);
                  }}
                />
              ) : (
                <div></div>
              )}
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => {
                  gotoToStudyOrAddSubStudy(study);
                }}
                edge="end"
              >
                <ArrowForwardIcon />
              </IconButton>
            </Box>
          </>
        ) : (
          <ReadOnlyStudies />
        )}
      </TaskWrapperContent>
      {informationStuidesShowPopUp && (
        <InformationStudiesPopUp
          informationStuidesShowPopUp={informationStuidesShowPopUp}
          setInformationStuidesShowPopUp={setInformationStuidesShowPopUp}
          study={study}
        />
      )}
      {configureStudyPopup.show && (
        <ConfigureStudyPopup
          showTemplatePopUp={configureStudyPopup.show}
          setShowTemplatePopUp={() => {
            setConfigureStudyPopup({
              ...configureStudyPopup,
              show: !configureStudyPopup.show,
            });
          }}
          projectId={configureStudyPopup.projectId}
          projectReportId={configureStudyPopup.projectReportId}
          reportName={configureStudyPopup.reportName}
          groupType={configureStudyPopup.groupType}
          disable={disable}
        />
      )}
    </TaskWrapper>
  );
};

export default Study;
