import axios from "src/utils/axios";
import { ICustomData, IKeyValue, ISimpleCatalogType } from "src/ts/interfaces";
import { IDefaultHorsePower } from "src/ts/interfaces/catalogs";
const getKeyValues = () => {
  return new Promise<ICustomData>((resolve, reject) => {
    axios
      .get<Array<ISimpleCatalogType>>(`DefaultHorsePower`)
      .then((response) => {
        const keyValuePair: IKeyValue<number, string>[] = response.data.map(
          (item) => {
            return {
              key: item.id!,
              value: item.name,
            };
          }
        );

        resolve({ data: keyValuePair });
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const getAll = () => {
  return axios.get<Array<IDefaultHorsePower>>("DefaultHorsePower");
};

const getKeyValueByFanMotorManufacturerId = (id: number) => {
  return new Promise<ICustomData>((resolve, reject) => {
    axios
      .get<Array<ISimpleCatalogType>>(
        `DefaultHorsePower?fanMotorManufacturerId=${id}`
      )
      .then((response) => {
        const keyValuePair: IKeyValue<number, string>[] = response.data.map(
          (item) => {
            return {
              key: item.id!,
              value: item.name,
            };
          }
        );

        resolve({ data: keyValuePair });
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const getKeyValuesMetricName = () => {
  return new Promise<ICustomData>((resolve, reject) => {
    axios
      .get<Array<IDefaultHorsePower>>(`DefaultHorsePower`)
      .then((response) => {
        const keyValuePair: IKeyValue<number, string>[] = response.data.map(
          (item) => {
            return {
              key: item.id!,
              value: item.metricName,
            };
          }
        );

        resolve({ data: keyValuePair });
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const defaultHorsePower = {
  getKeyValues,
  getKeyValueByFanMotorManufacturerId,
  getKeyValuesMetricName,
  getAll,
};
export default defaultHorsePower;
