import {
  IAuditTechnAssignmenRequesttFilter,
  IRequestTransfer,
  IRequestTransferData,
} from "src/ts/interfaces/requestTransfer";
import axios from "../utils/axios";
import { getFilter } from "src/utils/utils";

const getRequestTechnician = (userId: number) => {
  return axios.get<IRequestTransfer>(
    `RequestTransferTechnician/GetByUser?userId=${userId}`
  );
};

const putRequestTechnician = (userId: number, comments: string) => {
  return axios.put(
    `RequestTransferTechnician/RequestTransfer?userId=${userId}&comments=${comments}&newRoute=true`
  );
};

const getTransferRequestTechnicians = async (
  filter: IAuditTechnAssignmenRequesttFilter,
  getQuery?: (filters: Record<string, any>) => string
) => {
  const queryString = getQuery ? getQuery(filter) : getFilter(filter);

  return await axios.get<IRequestTransferData>(
    `RequestTransferTechnician/GetTransferRequestTechnicians?${queryString}`
  );
};

const getTransferInfo = async (code: string) => {
  return await axios.get<IRequestTransfer>(
    `RequestTransferTechnician?code=${code}`
  );
};

const putDecisionTransfer = async (
  code: string,
  status: string,
  comments: string
) => {
  return await axios.put(
    `RequestTransferTechnician?code=${code}&status=${status}&comments=${comments}`
  );
};

const RequestTransferTechnicianService = {
  getRequestTechnician,
  putRequestTechnician,
  getTransferRequestTechnicians,
  getTransferInfo,
  putDecisionTransfer,
};

export default RequestTransferTechnicianService;
