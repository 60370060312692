import {
  CardContent,
  Grid,
  Card as MuiCard,
  Divider as MuiDivider,
  Alert,
  Typography,
  Box,
  useTheme,
} from "@mui/material";
import { spacing } from "@mui/system";
import styled from "@emotion/styled";
import { useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import HeaderPage from "src/components/page/HeaderPage";
import { useAsyncQuery, useAuth, useEffectOnce, useLog } from "src/hooks";
import chillerService from "src/services/study/chillerService";
import { IChiller, IReportType } from "src/ts/interfaces";
import { Mode } from "src/ts/types";
import StudyWizard from "../../components/StudyWizard";
import { FormButton } from "src/components/formControls";
import { FooterButtons } from "../../components/FooterButtons";
import DialogMessagePopup from "src/components/DialogMessagePopup";
import { steps, studyTypeCode } from "../const";
import projectReportService from "src/services/study/projectReportService";
import { IProjectReport } from "src/ts/interfaces/project/projectDto";
import reportTypeService from "src/services/study/reportTypeService";
import SteadyStateDialog from "../../components/SteadyState";
import HeaderStudyPage from "src/components/page/HeaderStudyPage";

const Divider = styled(MuiDivider)(spacing);
const Card = styled(MuiCard)(spacing);
const activeStep = 3;

const ChillerStep4 = () => {
  const theme = useTheme();
  const { user } = useAuth();
  const params = useParams<{ id: string }>();
  const id = parseInt(params?.id === undefined ? "0" : params?.id);
  let navigate = useNavigate();
  const { log } = useLog();
  const [disableEditData, setDisableEditData] = useState(false);

  const [reportType, setReportType] = useState<IReportType | null>();
  const { execute: executeReport, data: dataReport } =
    useAsyncQuery<IProjectReport>(projectReportService.report);

  const { execute: getChiller, data: chiller } = useAsyncQuery<IChiller>(
    chillerService.getById
  );

  const [isLoading, setIsLoading] = useState(false);

  useEffectOnce(() => {
    const getData = async () => {
      try {
        setIsLoading(true);

        await getChiller(id);

        const reportTypeRes = await reportTypeService.getReportTypeByCode(
          studyTypeCode
        );
        setReportType(reportTypeRes.data);

        await executeReport(id, reportTypeRes.data.code);
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      } finally {
        setIsLoading(false);
      }
    };
    if (id > 0) getData();
  });

  const disableStudy = useMemo(() => {
    return (
      chiller?.isComplete ||
      (dataReport?.isInUse && dataReport?.isInUseById !== user?.userId) ||
      dataReport?.isPartOfProject === false
    );
  }, [
    dataReport?.isInUse,
    dataReport?.isInUseById,
    dataReport?.isPartOfProject,
    user?.userId,
    chiller?.isComplete,
  ]);

  const nextStepHandler = () => {
    const route = `/studies/Chiller/step41/${id}`;
    navigate(route);
  };

  const previousStepHandler = () => {
    const route = `/studies/Chiller/step3/${id}`;
    navigate(route);
  };

  const [showStartTestPopup, setShowStartTestPopup] = useState(false);
  const [showWarningPopup, setShowWarningPopup] = useState(false);

  return (
    <>
      <HeaderStudyPage
        headerStudiesPage={{
          code: studyTypeCode,
          system: chiller?.system,
          id: id,
        }}
        parentText="Project"
        parentLink="/"
      />
      <Divider my={6} />

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <StudyWizard
            steps={steps}
            activeStep={activeStep}
            projectId={dataReport?.projectId}
            reportId={dataReport?.reportId}
            reportTypeId={dataReport?.reportTypeId}
          />
        </Grid>
        <Grid item xs={12}>
          <Card mb={1}>
            <CardContent>
              <Grid container spacing={5}>
                <Grid item xs={12}>
                  <Typography variant="h6">Test</Typography>
                </Grid>
                <Grid item xs={12}>
                  {!disableStudy && (
                    <Box sx={{ textAlign: "center" }}>
                      <FormButton
                        text={"Start Test"}
                        onClick={() => setShowStartTestPopup(!disableEditData)}
                        size="small"
                        disabled={disableEditData}
                      />
                    </Box>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <FooterButtons
                    activeStep={activeStep}
                    stepsCount={steps.length}
                    projectId={dataReport?.projectId}
                    companyId={user?.companyId as number}
                    isSaving={isLoading}
                    disabled={false}
                    reportName={reportType?.name ?? ""}
                    previousStepHandler={previousStepHandler}
                    nextStepHandler={nextStepHandler}
                    disableNext={!disableStudy}
                    hideSaveButton={true}
                    isComplete={chiller?.isComplete}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <DialogMessagePopup
        title={"WARNING"}
        text={
          "Ensure everybody is clear of any potential system moving parts, ie motors, fans, pulleys, belts, etc."
        }
        showPopup={showStartTestPopup}
        setShowPopup={setShowStartTestPopup}
        onSave={() => {
          setShowStartTestPopup(false);
          setShowWarningPopup(true);
        }}
        onCancel={() => {
          setShowStartTestPopup(false);
        }}
        isSubmitting={false}
        disableClickOutside={true}
      />
      <DialogMessagePopup
        title={"Danger: Arc Flash And Shock Hazard - Appropriate PPE Required."}
        text={
          <>
            <ul style={{ color: theme.palette.error.main }}>
              <li>
                Do not operate controls or open covers without appropriate
                Personal Protection Equipment (PPE). Failure to comply may
                result in Injury or Death.
              </li>
              <li>Refer to NFPA 70E for minimum PPE Requirements.</li>
              <li>
                Warning NFPA code requirements may change, always check for
                current or updated code requirements
              </li>
              <li>
                Request a qualified and licensed electrician to collect voltage
                and Current/Amperage data if not ARC Flash Trained
              </li>
            </ul>
            <Alert severity="error">
              {" "}
              <strong>Warning:</strong> Above is understood and will be adhered
              to.
            </Alert>
          </>
        }
        showPopup={showWarningPopup}
        setShowPopup={setShowWarningPopup}
        onSave={() => {
          nextStepHandler();
        }}
        onCancel={() => {
          setShowWarningPopup(false);
        }}
        isSubmitting={false}
        disableClickOutside={true}
      />
      <SteadyStateDialog
        reportId={chiller?.id}
        reportTypeId={chiller?.reportTypeId as number}
        projectId={chiller?.projectId}
        reportCompleted={chiller?.isComplete}
        setDisableEditData={setDisableEditData}
      />
    </>
  );
};

export default ChillerStep4;
