import Popup from "src/components/Popup";
import { FormCancelButton } from "src/components/formControls";
import { Grid, Stack } from "@mui/material";
import { ColumnType } from "src/types/enhancedTable";
import LocalEnhancedTable from "src/components/localTable/LocalTable";
import { ITechniciansHistoryDashboard } from "src/ts/interfaces/project/projectDashboard";

interface Props {
  showTechHistory: boolean;
  setShowTechHistory: (val: boolean) => void;
  data: ITechniciansHistoryDashboard[];
}

const columns = (): ColumnType[] => [
  {
    id: "name",
    label: "Name",
    type: "string",
    sort: true,
  },
  {
    id: "certificationType",
    label: "Certification",
    type: "custom",
    sort: true,
    callback: (row: ITechniciansHistoryDashboard) => {
      return (
        <div dangerouslySetInnerHTML={{ __html: row.certificationType }} />
      );
    },
  },
  {
    id: "dateTime",
    label: "Date Added",
    type: "utcDate",
    sort: true,
    format: "MM/DD/yyyy h:mma",
  },
  {
    id: "dateTimeDeleted",
    label: "Date Deleted",
    type: "utcDate",
    sort: true,
    format: "MM/DD/yyyy h:mma",
  },
];

export default function TechnicianHistoryPopup({
  showTechHistory,
  setShowTechHistory,
  data,
}: Props) {
  return (
    <>
      <Popup
        title="Technician's assignment history"
        openPopup={showTechHistory}
        setOpenPopup={setShowTechHistory}
        onClose={() => {
          setShowTechHistory(false);
        }}
        size="md"
      >
        <>
          <Stack direction="row" spacing={3.2}>
            <Grid container>
              <Grid item xs={12}>
                <LocalEnhancedTable<ITechniciansHistoryDashboard>
                  refreshGrid={true}
                  columns={columns()}
                  data={Object.values(data ?? [])}
                />
              </Grid>
            </Grid>
          </Stack>
        </>

        <Stack>
          <Grid container>
            <Grid item>
              <FormCancelButton
                onClick={() => {
                  setShowTechHistory(false);
                }}
                isSubmitting={false}
              />
            </Grid>
          </Grid>
        </Stack>
      </Popup>
    </>
  );
}
