import { Stack, Typography, Grid } from "@mui/material";
import { ColumnType } from "src/types/enhancedTable";
import { FilterType } from "../types/FilterTypes";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { GridActionButton } from "src/components/gridControls";
import { useAuth } from "src/hooks";
import { IArchitectProjects } from "src/ts/interfaces/projectArchitect";
import LocalEnhancedTable from "src/components/localTable/LocalTable";
import { FormRaitingStarts } from "src/components/formControls";
import { makeStyles } from "@mui/styles";
import { useState } from "react";
import RatingInformation from "src/pages/projectOutForBid/Components/RatingInformationPopUp";

const useStyles = makeStyles({
  "@keyframes blinkAnimation": {
    "0%": {
      opacity: 1,
    },
    "50%": {
      opacity: 0,
    },
    "100%": {
      opacity: 1,
    },
  },
  blinking: {
    animation: "$blinkAnimation 3s infinite",
  },
});

const getColumns = (
  navigate: NavigateFunction,
  role: string,
  companyId: number,
  classes: any,
  setCurrentRow: (val: IArchitectProjects) => void,
  setShowRatingContractorPopUp: (val: boolean) => void
): ColumnType[] => [
  {
    id: "code",
    label: "Code",
    type: "string",
    sort: true,
  },
  {
    id: "name",
    label: "Project",
    type: "string",
    sort: true,
  },
  {
    id: "createdDate",
    label: "Created date",
    type: "date",
    format: "MM/DD/YYYY",
    sort: true,
  },
  {
    id: "buildingName",
    label: "Building",
    type: "custom",
    sort: true,
    callback: (row: IArchitectProjects) => {
      return (
        <Typography>
          {" "}
          <b>{row.buildingName}</b> <br />
          {row.buildingAddress}{" "}
        </Typography>
      );
    },
  },
  {
    id: "completedDate",
    label: "Completed date",
    type: "date",
    format: "MM/DD/YYYY",
    sort: true,
  },
  {
    id: "updatedDate",
    label: "Approved date",
    type: "date",
    format: "MM/DD/YYYY",
    sort: true,
  },
  {
    id: "action",
    label: "Click on Stars to see Service Providers Ratings",
    type: "custom",
    sort: true,
    width: "150p",
    callback: (row: IArchitectProjects) => (
      <>
        <Stack direction={"column"}>
          <Stack direction="row">
            <GridActionButton
              type="gauge"
              onClick={() => {
                navigate(`/app/projectDashboard/${row.id}`);
              }}
              tooltip="Dashboard Project"
            />
            <GridActionButton
              type="forward"
              onClick={() => {
                navigate(`/app/Project/Edit/${row.id}/${companyId}`);
              }}
              tooltip="Review Project"
            />
            {row.codeUsed && (
              <GridActionButton
                type="forum"
                onClick={() => {
                  navigate(`/app/dispute/${row.id}`);
                }}
                tooltip="Dispute"
                className={row.isOpenDispute ? classes.blinking : ""}
              />
            )}
            <GridActionButton
              type="folder"
              onClick={() => {
                navigate(`/app/DocumentLibrary/${row.id}/${companyId}/Project`);
              }}
              tooltip="Project's Document Library"
            />
            <GridActionButton
              type="picture"
              onClick={() => {
                navigate(`/app/PhotoLibrary/${row.id}/${companyId}/Project`);
              }}
              tooltip="Project's Picture Library"
            />
          </Stack>
          <Stack>
            <Grid container>
              <Grid item xs={6}>
                <Stack
                  direction="row"
                  justifyContent="center"
                  onClick={() => {
                    setCurrentRow(row);
                    setShowRatingContractorPopUp(true);
                  }}
                  sx={{ cursor: "pointer" }}
                >
                  <FormRaitingStarts value={row.rating} />
                </Stack>
              </Grid>
            </Grid>
          </Stack>
        </Stack>
      </>
    ),
  },
];

interface ApprovedProjectProps {
  filters: FilterType;
  refresh: boolean;
  dataTable: IArchitectProjects[];
  isLoading: boolean;
  setRefreshGrid: (val: boolean) => void;
}

export default function ApprovedProject({
  filters,
  refresh,
  dataTable,
  isLoading,
  setRefreshGrid,
}: ApprovedProjectProps) {
  const classes = useStyles();
  let navigate = useNavigate();
  const { user } = useAuth();
  const [currentRow, setCurrentRow] = useState<IArchitectProjects>();
  const [showRatingContractorPopUp, setShowRatingContractorPopUp] =
    useState(false);

  let columns = getColumns(
    navigate,
    user?.role,
    user?.companyId,
    classes,
    setCurrentRow,
    setShowRatingContractorPopUp
  );

  return (
    <>
      <LocalEnhancedTable<IArchitectProjects>
        refreshGrid={false}
        columns={columns}
        data={dataTable ?? []}
        showSkeleton={isLoading}
        skeletonRows={1}
        hidePagination={false}
        defaultRowPerPage={10}
        orderColumn="desc"
        defaultSortColumn="id"
      />
      {showRatingContractorPopUp && (
        <RatingInformation
          showRatingContractorPopUp={showRatingContractorPopUp}
          setShowRatingContractorPopUp={setShowRatingContractorPopUp}
          informationContractor={currentRow?.id ?? 0}
          isProject={true}
          setRefresh={setRefreshGrid}
          hideComments
        />
      )}
    </>
  );
}
