/* eslint-disable react-hooks/exhaustive-deps */
import {
  CardContent,
  Grid,
  Divider as MuiDivider,
  Card as MuiCard,
  ButtonGroup,
  Button,
  Tabs,
  Tab,
  FormLabel as MuiFormLabel,
  Alert,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import { Stack, spacing, useTheme } from "@mui/system";

import styled from "@emotion/styled";
import { SyntheticEvent, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  useAsyncQuery,
  useLog,
  useAuth,
  useSystemOfMeasurement,
  useFormulas,
} from "src/hooks";
import {
  FormButton,
  FormCheckBox,
  FormLabel,
  FormNumeric2,
  FormRadioGroup,
  FormSelect,
  FormText,
} from "src/components/formControls";
import { GridActionButton } from "src/components/gridControls";
import useFormTyped from "src/hooks/useFormTyped";
import {
  IEETReportServiceModelDTO,
  IFanDTO,
  IQuizAnswerDTO,
  IQuizQuestionDTO,
  IQuizResultDTO,
  IBearingDTO,
  IFanMotorActualDTO,
  IFormulaSystemService,
  IHeadSheetGeneralDataDTO,
} from "src/ts/interfaces";
import { IProjectReport } from "src/ts/interfaces/project/projectDto";
import projectReportService from "src/services/study/projectReportService";
import fanService from "src/services/study/fanService";
import quizQuestionService from "src/services/study/quizQuestionService";
import bearingService from "src/services/study/bearingService";
import { headSheetType, steps } from "../const/consts";
import { FooterButtons } from "../../components/FooterButtons";
import ElectricMeterIcon from "@mui/icons-material/ElectricMeter";
import AcUnitIcon from "@mui/icons-material/AcUnit";
import AirIcon from "@mui/icons-material/Air";
import {
  IFanManufacturer,
  IFanSheave,
  IFanSubType,
} from "src/ts/interfaces/catalogs";
import { IDefaultComboBoxCatalog } from "src/ts/interfaces";
import FormTooltip from "src/components/formControls/FormTooltip";
import { AddIcon } from "src/components/icons";
import Motor from "../../components/Motor";
import Popup from "src/components/Popup";
import { Validator } from "src/ts/types/Validator";
import IncompleteDataDialog from "../../components/IncompleteDataDialog";
import fanManufacturerService from "src/services/study/fanManufacturerService";
import fanSheaveService from "src/services/study/fanSheaveService";
import fanSubTypeService from "src/services/study/fanSubTypeService";
import headSheetService from "src/services/study/headSheetService";
import HeaderStudyPage from "src/components/page/HeaderStudyPage";
import headSheetGeneralDataService from "src/services/study/headSheetGeneralDataService";
import StudyWizard from "../../components/StudyWizard";

const initialValues: any = {};

const _TAB_FN = 0;
const _TAB_MT = 1;

const bearingGreasedOptions = [
  { key: "Properly", value: "Properly" },
  { key: "Overgreased", value: "Overgreased" },
  { key: "Undergreased", value: "Undergreased" },
  { key: "Unknown", value: "Unknown" },
];

const Divider = styled(MuiDivider)(spacing);
const Card = styled(MuiCard)(spacing);

const HeadSheetStep3 = () => {
  const { log } = useLog();
  const { user } = useAuth();
  const { systemOfMeasurement } = useSystemOfMeasurement(true);
  const { formulaSystem } = useFormulas();
  const [formulas, setFormulas] = useState<IFormulaSystemService>();
  const params = useParams<{ id: string; step: string }>();
  const id = parseInt(params?.id === undefined ? "0" : params?.id);
  const [isUpdating, setIsUpdating] = useState(false);
  const [title, setTitle] = useState<string>();
  const [code, setCode] = useState<string>();
  const [route, setRoute] = useState<string>();
  const [selectedFanMotor, setSelectedFanMotor] = useState(0);
  const [selectedFanMotorIndex, setSelectedFanMotorIndex] = useState(0);
  const [tabSelected, setTabSelected] = useState(0);
  const [fanValues, setFanValues] = useState<IFanDTO>();
  const [triggerMotorSave, setTriggerMotorSave] = useState(false);
  const [triggerMoveNext, setTriggerMoveNext] = useState(false);
  const [triggerMoveBack, setTriggerMoveBack] = useState(false);
  const [questions, setQuestions] = useState<IQuizQuestionDTO[]>();
  const [bearingValues, setBearingValues] = useState<IBearingDTO[]>();
  const [fanManufacturers, setFanManufacturers] =
    useState<IDefaultComboBoxCatalog[]>();
  const [fanSheaveManufacturers, setFanSheaveManufacturers] =
    useState<IDefaultComboBoxCatalog[]>();
  const [fanSubTypes, setFanSubTypes] = useState<IDefaultComboBoxCatalog[]>();
  const [generalDataValues, setGeneralDataValues] =
    useState<IHeadSheetGeneralDataDTO>();
  const [sheaveDimensionBetween, setSheaveDimensionBetween] = useState(0);
  const [showFanSheaveAlert, setShowFanSheaveAlert] = useState(false);
  const [fanSheaveDimensionLow, setFanSheaveDimensionLow] = useState(0);
  const [fanSheaveDimensionHigh, setFanSheaveDimensionHigh] = useState(0);
  const [currentBearingId, setCurrentBearingId] = useState(0);
  const [currentBearingIdx, setCurrentBearingIdx] = useState(0);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openIncompleteDataDialog, setOpenIncompleteDataDialog] =
    useState(false);
  const isDarkTheme = useTheme().palette.mode === "dark";

  let navigate = useNavigate();

  const validate: any = (fanFieldValues: IFanDTO = fanValues as IFanDTO) => {
    let temp: Record<string, string> = { ...errors };

    if (disableStudy()) return true;

    if (tabSelected === _TAB_FN) {
      temp.fanManufacturerId = new Validator(
        fanFieldValues,
        "fanManufacturerId"
      )
        .isRequired("Fan Manufacturer is required.")
        .greatThan(0, "Fan Manufacturer is required.")
        .toString();
      temp.otherFanManufacturer = new Validator(
        fanFieldValues,
        "otherFanManufacturer"
      )
        .validateIf(
          catalogValueOtherEval(
            fanFieldValues.fanManufacturerId as number,
            fanManufacturers as IDefaultComboBoxCatalog[]
          )
        )
        .isRequired("Other fan manufacturer is required.")
        .toString();
      temp.fanSheaveId = new Validator(fanFieldValues, "fanSheaveId")
        .isRequired("Fan Sheave Manufacturer is required.")
        .greatThan(0, "Fan Sheave Manufacturer is required.")
        .toString();
      temp.fanSheaveStampingDimensionOnly = new Validator(
        fanFieldValues,
        "fanSheaveStampingDimensionOnly"
      )
        .isRequired("Fan sheave stamping dimension is required.")
        .greatThan(0, "Fan sheave stamping dimension must be great than 0")
        .toString();
      temp.halfFanSheaveDimension = new Validator(
        fanFieldValues,
        "halfFanSheaveDimension"
      )
        .isRequired("Half of the fan sheave dimension is required.")
        .greatThan(0, "Half of the fan sheave dimension must be great than 0")
        .toString();
      temp.fanShaftDiameter = new Validator(fanFieldValues, "fanShaftDiameter")
        .isRequired("Fan Shaft Diameter is required.")
        .greatThan(0, "Fan Shaft Diameter must be great than 0")
        .toString();
      temp.fanOuterEdgeToShaftRadius = new Validator(
        fanFieldValues,
        "fanOuterEdgeToShaftRadius"
      )
        .isRequired("Fan Blade Outer edges Diameter is required.")
        .greatThan(0, "Fan Blade Outer edges Diameter must be great than 0")
        .toString();
    }

    setErrors({
      ...temp,
    });

    if (fanFieldValues === fanValues) {
      return Object.values(temp).every((x) => x === "");
    }
  };

  const handleTabChange = (event: SyntheticEvent, newValue: number) => {
    setTabSelected(newValue);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
  };

  const { values, setValues, errors, setErrors } =
    useFormTyped<IEETReportServiceModelDTO>(initialValues, false, validate);

  const activeStep = 2;

  const { execute: executeReport, data: dataReport } =
    useAsyncQuery<IProjectReport>(projectReportService.report);

  const { execute: executeFanMfg, isLoading: isLoadingFanMfgs } = useAsyncQuery<
    IFanManufacturer[]
  >(fanManufacturerService.getManufacturers, {
    onSuccess: (dataResult) => {
      const getData = async () => {
        const mfgs = (dataResult as IFanManufacturer[]).map((mfg) => ({
          key: mfg.id as number,
          value: mfg.name as any,
        }));
        setFanManufacturers([
          { key: 0, value: "Select a fan manufacturer" },
          ...mfgs,
        ]);
      };
      getData();
    },
  });

  const { execute: executeFanSheaveMfg, isLoading: isLoadingFanSheaveMfgs } =
    useAsyncQuery<IFanSheave[]>(fanSheaveService.getFanSheaveManufacturers, {
      onSuccess: (dataResult) => {
        const getData = async () => {
          const mfgs = (dataResult as IFanSheave[]).map((mfg) => ({
            key: mfg.id as number,
            value: mfg.name as any,
          }));
          setFanSheaveManufacturers([
            { key: 0, value: "Select a fan sheave manufacturer" },
            ...mfgs,
          ]);
        };
        getData();
      },
    });

  const { execute: executeFanSubType, isLoading: isLoadingFanSubTypes } =
    useAsyncQuery<IFanSubType[]>(fanSubTypeService.getFanSubTypes, {
      onSuccess: (dataResult) => {
        const getData = async () => {
          const mfgs = (dataResult as IFanSheave[]).map((mfg) => ({
            key: mfg.id as number,
            value: mfg.name as any,
          }));
          setFanSubTypes([{ key: 0, value: "Select a fan type" }, ...mfgs]);
        };
        getData();
      },
    });

  const { execute: executeFan, isLoading: isLoadingFan } =
    useAsyncQuery<IFanDTO>(fanService.getByMotor, {
      onSuccess: (dataResult) => {
        const getData = async () => {
          await executeFanMfg();
          await executeFanSheaveMfg();
          await executeFanSubType();
          await executeBearing(id, dataResult.id);
          setFanValues({
            ...dataResult,
          });
          window.scrollTo(0, 0);
        };
        getData();
      },
    });

  const { execute: executeQuestions, isLoading: isLoadingQuestions } =
    useAsyncQuery<IQuizQuestionDTO[]>(quizQuestionService.getQuizQuestions, {
      onSuccess: (dataResult) => {
        const getData = async () => {
          setQuestions(dataResult);
        };
        getData();
      },
    });

  const { execute: executeBearing, isLoading: isLoadingBearing } =
    useAsyncQuery<IBearingDTO[]>(bearingService.getByReportAndFan, {
      onSuccess: (dataResult) => {
        const getData = async () => {
          setBearingValues(dataResult);
        };
        getData();
      },
    });

  const { execute: addBearing, isLoading: isAddingBearing } = useAsyncQuery<
    IBearingDTO[]
  >(bearingService.save, {
    onSuccess: (dataResult) => {
      const getData = async () => {
        setBearingValues([...(bearingValues as IBearingDTO[]), dataResult]);
      };
      getData();
    },
  });

  const { execute, isLoading } = useAsyncQuery<IEETReportServiceModelDTO>(
    headSheetService.getStepThree,
    {
      onSuccess: (dataResult) => {
        const getData = async () => {
          setValues({ ...dataResult });
          await systemOfMeasurement.getByProject(dataResult.projectId);
          const formulas = await formulaSystem.setSystem(dataResult.projectId);
          setFormulas(formulas);
        };
        getData();
      },
    }
  );

  const { execute: executeGeneralData } =
    useAsyncQuery<IHeadSheetGeneralDataDTO>(
      headSheetGeneralDataService.getById,
      {
        onSuccess: (dataResult) => {
          const getData = async () => {
            setGeneralDataValues(dataResult);
          };
          getData();
        },
      }
    );

  const disableStudy = () => {
    return (
      generalDataValues?.headSheet?.isComplete ||
      (dataReport?.isInUse && dataReport?.isInUseById !== user?.userId) ||
      dataReport?.isPartOfProject === false
    );
  };

  useEffect(() => {
    if (values.reportTypeId) {
      const [{ title, code, route }] = headSheetType.filter(
        (x) => x.key === values?.reportTypeId
      );
      setTitle(title);
      setCode(code);
      setRoute(route);
    }
  }, [values.reportTypeId]);

  useEffect(() => {
    const getData = async () => {
      try {
        await execute(id);
        await executeGeneralData(id);
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      }
    };

    if (id !== 0) getData();
  }, [id]);

  useEffect(() => {
    if (values.id) {
      setErrors({});
      setSelectedFanMotor(
        (values.fans as IFanMotorActualDTO[])[selectedFanMotorIndex].key
      );
      executeFan(
        (values.fans as IFanMotorActualDTO[])[selectedFanMotorIndex].key
      );
      executeQuestions(
        id,
        "Element",
        (values.fans as IFanMotorActualDTO[])[selectedFanMotorIndex].fanId
      );
    }
  }, [values, selectedFanMotorIndex]);

  useEffect(() => {
    const getData = async () => {
      try {
        await executeReport(id, code);
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      }
    };

    if (id !== 0 && code !== undefined) getData();
  }, [id, code]);

  useEffect(() => {
    if (formulas)
      setSheaveDimensionBetween(formulas?.getSheaveDimensionBetween());
  }, [formulas]);

  useEffect(() => {
    if (fanValues) {
      const pitchDiameter =
        typeof fanValues?.fanSheaveDimensionMeasured === "string"
          ? parseFloat(fanValues?.fanSheaveDimensionMeasured)
          : (fanValues?.fanSheaveDimensionMeasured as number);
      const sheaveDimension =
        typeof fanValues?.fanSheaveStampingDimensionOnly === "string"
          ? parseFloat(fanValues?.fanSheaveStampingDimensionOnly)
          : (fanValues?.fanSheaveStampingDimensionOnly as number);
      setFanSheaveDimensionLow(sheaveDimension - sheaveDimensionBetween);
      setFanSheaveDimensionHigh(sheaveDimension + sheaveDimensionBetween);

      const halfFanSheaveDimension =
        typeof fanValues?.halfFanSheaveDimension === "string"
          ? parseFloat(fanValues?.halfFanSheaveDimension)
          : (fanValues?.halfFanSheaveDimension as number);
      const fanShaftDiameter =
        typeof fanValues?.fanShaftDiameter === "string"
          ? parseFloat(fanValues?.fanShaftDiameter)
          : (fanValues?.fanShaftDiameter as number);
      const result = halfFanSheaveDimension * 2 + fanShaftDiameter * 1;
      setFanValues({
        ...(fanValues as IFanDTO),
        fanSheaveDimensionMeasured: result,
      });

      if (
        (pitchDiameter > sheaveDimension + sheaveDimensionBetween ||
          pitchDiameter < sheaveDimension - sheaveDimensionBetween) &&
        fanValues?.fanShaftDiameter != null &&
        fanValues?.halfFanSheaveDimension != null
      ) {
        setShowFanSheaveAlert(true);
      } else {
        setShowFanSheaveAlert(false);
      }
    }
  }, [
    fanValues?.fanSheaveStampingDimensionOnly,
    fanValues?.fanSheaveDimensionMeasured,
    fanValues?.fanShaftDiameter,
    fanValues?.halfFanSheaveDimension,
    sheaveDimensionBetween,
  ]);

  useEffect(() => {
    let results = [] as IQuizResultDTO[];
    questions?.forEach((question) => {
      const subResults = question.answers?.map(
        (answer) =>
          ({
            quizQuestionId: question.id,
            quizAnswerId: answer.id,
            quizId: 1,
            checked: answer.checked,
            reportId: id,
          } as IQuizResultDTO)
      ) as IQuizResultDTO[];
      results = [...results, ...subResults];
    });
    setFanValues({
      ...(fanValues as IFanDTO),
      quizResults: results,
    });
  }, [questions]);

  const saveHandler = async () => {
    if (tabSelected === _TAB_FN) {
      await saveFanHandler();
      await saveBearingHandle();
    }
    if (tabSelected === _TAB_MT) {
      setTriggerMotorSave(true);
    }
  };

  const saveFanHandler = async () => {
    try {
      if (!disableStudy()) {
        setIsUpdating(true);
        await headSheetService.updateFan(
          values?.id,
          fanValues as IFanDTO,
          values?.fanSystemType === "FanArray",
          selectedFanMotorIndex
        );
        log.success("Fan was updated successfully");
        setIsUpdating(false);
      }
    } catch (error) {}
  };

  const saveBearingHandle = async () => {
    try {
      if (!disableStudy()) {
        setIsUpdating(true);
        bearingValues?.forEach(async (bearing) => {
          await bearingService.update(bearing.id, bearing);
        });
        log.success("Bearing data was updated successfully");
        setIsUpdating(false);
      }
    } catch (error) {}
  };

  const nextStepHandler = async () => {
    if (tabSelected === _TAB_FN) {
      if (!validate()) setOpenIncompleteDataDialog(true);
      else await movingForwardHandler();
    }
    if (tabSelected === _TAB_MT) {
      setTriggerMoveNext(true);
    }
  };

  const movingForwardHandler = async () => {
    setOpenIncompleteDataDialog(false);
    await saveFanHandler();
    await saveBearingHandle();
    setTabSelected(_TAB_MT);
  };

  const motorMoveNextHandler = () => {
    if (
      selectedFanMotorIndex <
      (values.fans as IFanMotorActualDTO[])?.length - 1
    ) {
      setSelectedFanMotorIndex(selectedFanMotorIndex + 1);
      setTabSelected(_TAB_FN);
      window.scrollTo(0, 0);
    } else {
      navigate(`/studies/${route}/step4/${id}`);
    }
  };

  const previousStepHandler = async () => {
    if (tabSelected === _TAB_MT) {
      setTriggerMoveBack(true);
    }
    if (tabSelected === _TAB_FN) {
      await saveFanHandler();
      await saveBearingHandle();
      if (selectedFanMotorIndex > 0) {
        setSelectedFanMotorIndex(selectedFanMotorIndex - 1);
        setTabSelected(_TAB_MT);
      } else {
        navigate(`/studies/${route}/step2/${id}`);
      }
    }
  };

  const motorMoveBackHandler = () => {
    setTabSelected(_TAB_FN);
    window.scrollTo(0, 0);
  };

  const handleFanValuesChange = (e: any) => {
    setFanValues({
      ...(fanValues as IFanDTO),
      [e.target.name]: e.target.value,
    });
  };

  const handleBearingValuesChange = (
    e: any,
    row: IBearingDTO,
    index: number
  ) => {
    let newBearing = { ...row, [e.target.name]: e.target.value };
    if (e.target.name === "float") {
      newBearing = {
        ...newBearing,
        fixed: e.target.value ? !e.target.value : newBearing.fixed,
      };
    }
    if (e.target.name === "fixed") {
      newBearing = {
        ...newBearing,
        float: e.target.value ? !e.target.value : newBearing.float,
      };
    }
    let crud = [...(bearingValues as IBearingDTO[])];
    crud.splice(index, 1, newBearing);
    setBearingValues(crud);
  };

  const handleQuestionsRadioChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    value: string
  ) => {
    let answers = [] as IQuizAnswerDTO[];
    const questionId = parseInt(event.target.name.split("-")[1]);
    const [question] = (questions as IQuizQuestionDTO[]).filter(
      (x) => x.id === questionId
    );
    const index = (questions as IQuizQuestionDTO[]).indexOf(question);
    (question?.answers as IQuizAnswerDTO[]).forEach((answer) =>
      answers.push({ ...answer, checked: answer.id === parseInt(value) })
    );
    let crud = [...(questions as IQuizQuestionDTO[])];
    crud.splice(index, 1, { ...question, answers: [...answers] });
    setQuestions([...crud]);
  };

  const quizQuestionRadValueHandle = (question: IQuizQuestionDTO) => {
    const [answer] = (question?.answers as IQuizAnswerDTO[]).filter(
      (x) => x.checked
    );
    return answer?.id ?? null;
  };

  const handleQuestionsChkChange = (e: any) => {
    let answers = [] as IQuizAnswerDTO[];
    const questionId = parseInt(e.target.name.split("-")[1]);
    const answerId = parseInt(e.target.name.split("-")[3]);
    const [question] = (questions as IQuizQuestionDTO[]).filter(
      (x) => x.id === questionId
    );
    const index = (questions as IQuizQuestionDTO[]).indexOf(question);
    const [targetAnswer] = (question?.answers as IQuizAnswerDTO[]).filter(
      (x) => x.id === answerId
    );
    const isNoIssuesApparent = targetAnswer?.answer === "No Issues Apparent";
    (question?.answers as IQuizAnswerDTO[]).forEach((answer) =>
      answers.push({
        ...answer,
        checked:
          answer.id === answerId
            ? e.target.value
            : isNoIssuesApparent
            ? false
            : answer.answer === "No Issues Apparent" && e.target.value
            ? false
            : answer.checked,
      })
    );
    let crud = [...(questions as IQuizQuestionDTO[])];
    crud.splice(index, 1, { ...question, answers: [...answers] });
    setQuestions([...crud]);
  };

  const quizQuestionChkValueHandle = (questionId: number, answerId: number) => {
    const [question] = (questions as IQuizQuestionDTO[]).filter(
      (x) => x.id === questionId
    );
    const [answer] = (question?.answers as IQuizAnswerDTO[]).filter(
      (x) => x.id === answerId
    );
    return answer?.checked;
  };

  const removeBearingHandler = async (id: number, index: number) => {
    await bearingService.remove(id);
    let newBearing = bearingValues;
    newBearing?.splice(index, 1);
    setBearingValues(newBearing);
    setOpenDeleteDialog(false);
  };

  const handleAddBearing = async () => {
    const bearing = {
      id: 0,
      idNumber: "",
      location: "",
      type: "",
      manufacturer: "",
      shaftDimension: "",
      float: false,
      greased: "",
      recommendReplacement: false,
      reportId: id,
      fanId: fanValues?.id,
      comments: "",
    };
    addBearing(bearing);
  };

  const catalogValueOtherEval = (
    id: number,
    catalog: IDefaultComboBoxCatalog[]
  ) => {
    if (catalog && id) {
      const [{ value }] = catalog.filter((x) => x.key === id);
      return value === "Other";
    }
    return false;
  };

  const loadFanMotor = (index: number) => {
    setSelectedFanMotorIndex(index);
    setTabSelected(_TAB_FN);
  };

  return (
    <>
      <Popup
        openPopup={openDeleteDialog}
        setOpenPopup={setOpenDeleteDialog}
        onClose={handleCloseDeleteDialog}
        title="Confirm delete Component"
      >
        <p>Are you certain you want to delete the selected bearing item?</p>
        <Stack direction="row" spacing={3}>
          <Button
            variant="outlined"
            color="secondary"
            onClick={handleCloseDeleteDialog}
          >
            No
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() =>
              removeBearingHandler(currentBearingId, currentBearingIdx)
            }
            autoFocus
          >
            Yes
          </Button>
        </Stack>
      </Popup>
      <IncompleteDataDialog
        setOpenIncompleteDataDialog={setOpenIncompleteDataDialog}
        openIncompleteDataDialog={openIncompleteDataDialog}
        yesButtonClickHandler={movingForwardHandler}
      />
      <HeaderStudyPage
        headerStudiesPage={{ code: code, system: values?.system, id: id }}
        parentText="Project"
        parentLink={`/app/ProjectStudies/${values?.projectId}/${user?.companyId}`}
      />
      <Divider my={6} />

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card mb={6}>
            <CardContent>
              <StudyWizard
                steps={steps}
                activeStep={activeStep}
                projectId={dataReport?.projectId}
                reportId={dataReport?.reportId}
                reportTypeId={dataReport?.reportTypeId}
              />
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <ButtonGroup
                    variant="outlined"
                    aria-label="Loading button group"
                  >
                    {values?.fans?.map((fanMotor, index) => (
                      <Button
                        key={fanMotor.key}
                        disabled={selectedFanMotor === fanMotor.key}
                        onClick={() => loadFanMotor(index)}
                      >
                        <AcUnitIcon />
                        {values.fanSystemType === "FanArray"
                          ? fanMotor.serial
                            ? `R:${fanMotor.row} > #${fanMotor.index}`
                            : `S#${(fanMotor.serial || "").padStart(6, "...")}`
                          : `FnMt > #${fanMotor.index}`}
                      </Button>
                    ))}
                  </ButtonGroup>
                </Grid>
                <Grid item xs={12}>
                  <Tabs
                    value={tabSelected}
                    onChange={handleTabChange}
                    aria-label="headsheet step 2 tabs"
                  >
                    <Tab icon={<AirIcon />} iconPosition="start" label="Fan" />
                    <Tab
                      icon={<ElectricMeterIcon />}
                      iconPosition="start"
                      label="Motor"
                    />
                  </Tabs>
                </Grid>
              </Grid>
              {tabSelected === _TAB_FN && (
                <>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <h2>Fan Description</h2>
                    </Grid>
                    <Grid item xs={12}>
                      <FormSelect
                        name="fanManufacturerId"
                        label="Fan manufacturer"
                        error={errors.fanManufacturerId}
                        onChange={handleFanValuesChange}
                        options={fanManufacturers}
                        value={fanValues?.fanManufacturerId || 0}
                        showSkeleton={
                          isLoadingFan || isLoading || isLoadingFanMfgs
                        }
                        disabled={disableStudy()}
                      />
                    </Grid>
                    {catalogValueOtherEval(
                      fanValues?.fanManufacturerId || 0,
                      fanManufacturers as IDefaultComboBoxCatalog[]
                    ) && (
                      <Grid item xs={12}>
                        <FormText
                          name="otherFanManufacturer"
                          label="Other fan manufacturer"
                          onChange={handleFanValuesChange}
                          placeholder="Type the fan manufacturer here"
                          value={fanValues?.otherFanManufacturer || ""}
                          showSkeleton={isLoadingFan || isLoading}
                          error={errors.otherFanManufacturer}
                          disabled={disableStudy()}
                        />
                      </Grid>
                    )}
                    <Grid item xs={12}>
                      <FormText
                        name="description"
                        label={`Individual fan ${
                          values.fanSystemType === "FanArray" ? "array" : ""
                        } tag identifier`}
                        onChange={handleFanValuesChange}
                        placeholder="IE: North, South, Left, Right, etc."
                        value={fanValues?.description || ""}
                        showSkeleton={isLoadingFan || isLoading}
                        disabled={disableStudy()}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <FormText
                        name="modelNumber"
                        label="Model number"
                        onChange={handleFanValuesChange}
                        placeholder="Model number"
                        value={fanValues?.modelNumber || ""}
                        showSkeleton={isLoadingFan || isLoading}
                        disabled={disableStudy()}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <FormText
                        name="serialNumber"
                        label="Serial number"
                        onChange={handleFanValuesChange}
                        placeholder="Serial number"
                        value={fanValues?.serialNumber || ""}
                        showSkeleton={isLoadingFan || isLoading}
                        disabled={disableStudy()}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <FormSelect
                        name="fanSubTypeId"
                        label="Fan type"
                        onChange={handleFanValuesChange}
                        showSkeleton={
                          isLoadingFan || isLoading || isLoadingFanSubTypes
                        }
                        value={fanValues?.fanSubTypeId || 0}
                        options={fanSubTypes}
                        disabled={disableStudy()}
                      />
                    </Grid>
                    {catalogValueOtherEval(
                      fanValues?.fanSubTypeId || 0,
                      fanSubTypes as IDefaultComboBoxCatalog[]
                    ) && (
                      <Grid item xs={12}>
                        <FormText
                          name="otherFanType"
                          label="Other fan type"
                          onChange={handleFanValuesChange}
                          placeholder="Type the fan type here"
                          value={fanValues?.otherFanType || ""}
                          showSkeleton={isLoadingFan || isLoading}
                          disabled={disableStudy()}
                        />
                      </Grid>
                    )}
                    <Grid item xs={12}>
                      <h2>Fan/Motor Drive Type</h2>
                    </Grid>
                    <Grid item xs={12}>
                      <FormCheckBox
                        size="medium"
                        name="isDirectDrive"
                        label="Direct drive fan system"
                        onChange={handleFanValuesChange}
                        value={fanValues?.isDirectDrive || false}
                        showSkeleton={isLoadingFan || isLoading}
                        disabled={disableStudy()}
                      />
                    </Grid>
                    {fanValues?.isDirectDrive && (
                      <Grid item xs={12}>
                        <FormNumeric2
                          name="fanMotorShaftSize"
                          value={fanValues?.fanMotorShaftSize || 0}
                          label={`Fan/Motor shaft size ${systemOfMeasurement.get(
                            "in"
                          )}`}
                          onChange={handleFanValuesChange}
                          fixedDecimalScale
                          decimalScale={4}
                          thousandSeparator
                          showSkeleton={isLoadingFan || isLoading}
                          disabled={disableStudy()}
                          fullWidth={false}
                          style={{ width: "95%" }}
                        />
                        <FormTooltip
                          titleName="Fan Shaft Diameter"
                          showSkeleton={isLoadingFan || isLoading}
                        />
                        <Alert severity="info" style={{ marginTop: 5 }}>
                          NOTE: When a Fan is directly driven by the motor, the
                          outer dimension (OD) of the motor's shaft is the same
                          as the inner dimension (ID) of the fan's collect/hub.
                        </Alert>
                      </Grid>
                    )}
                    {!fanValues?.isDirectDrive && (
                      <>
                        <Grid item xs={12}>
                          <FormSelect
                            name="fanSheaveId"
                            label="Fan sheave manufacturer"
                            error={errors.fanSheaveId}
                            onChange={handleFanValuesChange}
                            showSkeleton={
                              isLoadingFan ||
                              isLoading ||
                              isLoadingFanSheaveMfgs
                            }
                            options={fanSheaveManufacturers}
                            value={fanValues?.fanSheaveId || 0}
                            disabled={disableStudy()}
                          />
                        </Grid>
                        {catalogValueOtherEval(
                          fanValues?.fanSheaveId || 0,
                          fanSheaveManufacturers as IDefaultComboBoxCatalog[]
                        ) && (
                          <Grid item xs={12}>
                            <FormText
                              name="otherFanSheave"
                              label="Other fan sheave manufacturer"
                              onChange={handleFanValuesChange}
                              placeholder="Type the fan type here"
                              value={fanValues?.otherFanSheave || ""}
                              showSkeleton={isLoadingFan || isLoading}
                              disabled={disableStudy()}
                            />
                          </Grid>
                        )}
                        <Grid item xs={12} md={6}>
                          <FormText
                            name="fanSheaveStamping"
                            label="Fan sheave stamping (Full stamping)"
                            onChange={handleFanValuesChange}
                            placeholder="Fan sheave stamping"
                            value={fanValues?.fanSheaveStamping || ""}
                            showSkeleton={isLoadingFan || isLoading}
                            disabled={disableStudy()}
                          />
                        </Grid>
                        <Grid item xs={12} md={6} flexDirection="column">
                          <Grid container spacing={0}>
                            <Grid item xs={11}>
                              <FormNumeric2
                                name="fanSheaveStampingDimensionOnly"
                                value={
                                  fanValues?.fanSheaveStampingDimensionOnly || 0
                                }
                                label={`Fan sheave stamping dimension ${systemOfMeasurement.get(
                                  "in"
                                )}`}
                                error={errors.fanSheaveStampingDimensionOnly}
                                onChange={handleFanValuesChange}
                                fixedDecimalScale
                                decimalScale={4}
                                thousandSeparator
                                showSkeleton={isLoadingFan || isLoading}
                                disabled={disableStudy()}
                              />
                            </Grid>
                            <Grid item xs={1}>
                              <FormTooltip
                                titleName="Fan Sheave Stamping Dimension"
                                showSkeleton={isLoadingFan || isLoading}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <FormNumeric2
                            name="halfFanSheaveDimension"
                            value={fanValues?.halfFanSheaveDimension || 0}
                            label={`Half of the fan sheave dimension from outer edge of pulley to the face of the fan shaft ${systemOfMeasurement.get(
                              "in"
                            )}`}
                            error={errors.halfFanSheaveDimension}
                            onChange={handleFanValuesChange}
                            fixedDecimalScale
                            decimalScale={4}
                            thousandSeparator
                            showSkeleton={isLoadingFan || isLoading}
                            disabled={disableStudy()}
                          />
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <Grid container spacing={0}>
                            <Grid item xs={11}>
                              <FormNumeric2
                                name="fanShaftDiameter"
                                value={fanValues?.fanShaftDiameter || 0}
                                label={`Fan Shaft Diameter ${systemOfMeasurement.get(
                                  "in"
                                )}`}
                                error={errors.fanShaftDiameter}
                                onChange={handleFanValuesChange}
                                fixedDecimalScale
                                decimalScale={4}
                                thousandSeparator
                                showSkeleton={isLoadingFan || isLoading}
                                disabled={disableStudy()}
                              />
                            </Grid>
                            <Grid item xs={1}>
                              <FormTooltip
                                titleName="Fan Shaft Diameter"
                                showSkeleton={isLoadingFan || isLoading}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <Grid container spacing={0}>
                            <Grid item xs={11}>
                              <FormNumeric2
                                name="fanOuterEdgeToShaftRadius"
                                value={
                                  fanValues?.fanOuterEdgeToShaftRadius || 0
                                }
                                label={`Tip Speed -  Fan Blade Outer edges Diameter ${systemOfMeasurement.get(
                                  "in"
                                )}`}
                                error={errors.fanOuterEdgeToShaftRadius}
                                onChange={handleFanValuesChange}
                                fixedDecimalScale
                                decimalScale={4}
                                thousandSeparator
                                showSkeleton={isLoadingFan || isLoading}
                                disabled={disableStudy()}
                              />
                            </Grid>
                            <Grid item xs={1}>
                              <FormTooltip
                                titleName="Fan Shaft Diameter"
                                showSkeleton={isLoadingFan || isLoading}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12}>
                          <FormLabel
                            text={`Fan sheave dimension measured ${systemOfMeasurement.get(
                              "in"
                            )}: ${
                              fanValues?.fanSheaveDimensionMeasured?.toFixed(
                                2
                              ) || ""
                            }`}
                            showSkeleton={isLoadingFan || isLoading}
                          ></FormLabel>
                        </Grid>
                        {showFanSheaveAlert && (
                          <Grid item xs={12}>
                            <Alert severity="error">
                              <p>
                                Please review dimension entered.
                                <br />
                                Difference in dimensions exceeds built-in
                                tolerance.
                                <br />
                                Fan Sheave Dimension Measured value should be
                                between {`${fanSheaveDimensionLow.toFixed(
                                  2
                                )}`}{" "}
                                and{" "}
                                {`${fanSheaveDimensionHigh.toFixed(
                                  2
                                )} ${systemOfMeasurement.get("in")}`}{" "}
                                regarding the actual Fan Sheave Stamping
                                Dimension value.
                              </p>
                            </Alert>
                          </Grid>
                        )}
                        <Grid item xs={12}>
                          <h2>Fan Sheave Type and Bore</h2>
                        </Grid>
                        <Grid item xs={12}>
                          <FormCheckBox
                            size="medium"
                            name="fanSheaveFixedBore"
                            label="Fixed bore fan sheave"
                            onChange={handleFanValuesChange}
                            value={fanValues?.fanSheaveFixedBore || false}
                            showSkeleton={isLoadingFan || isLoading}
                            disabled={disableStudy()}
                          />
                        </Grid>
                        {!fanValues?.fanSheaveFixedBore && (
                          <Grid item xs={12}>
                            <FormText
                              name="fanBushingStamping"
                              label="Fan bushing stamping"
                              onChange={handleFanValuesChange}
                              placeholder="Fan bushing stamping"
                              value={fanValues?.fanBushingStamping || ""}
                              showSkeleton={isLoadingFan || isLoading}
                              disabled={disableStudy()}
                            />
                          </Grid>
                        )}
                      </>
                    )}
                    <Grid item xs={12}>
                      <h2>Commissioning Continued</h2>
                    </Grid>
                    <Grid item xs={4} textAlign="center">
                      <FormCheckBox
                        size="medium"
                        name="dirtyFanBlades"
                        label="Dirty Fan Blades"
                        onChange={handleFanValuesChange}
                        showSkeleton={isLoadingFan || isLoading}
                        value={fanValues?.dirtyFanBlades || false}
                        disabled={disableStudy()}
                      />
                    </Grid>
                    <Grid item xs={4} textAlign="center">
                      <FormCheckBox
                        size="medium"
                        name="fanBladesLoose"
                        label="Fan Blades loose"
                        onChange={handleFanValuesChange}
                        showSkeleton={isLoadingFan || isLoading}
                        value={fanValues?.fanBladesLoose || false}
                        disabled={disableStudy()}
                      />
                    </Grid>
                    <Grid item xs={4} textAlign="center">
                      <FormCheckBox
                        size="medium"
                        name="fanBladesMissing"
                        label="Fan Blades Missing"
                        onChange={handleFanValuesChange}
                        showSkeleton={isLoadingFan || isLoading}
                        value={fanValues?.fanBladesMissing || false}
                        disabled={disableStudy()}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                    {questions?.map(
                      (question: IQuizQuestionDTO, key: number) => (
                        <Grid item key={key} xs={12}>
                          {question.questionType === "Single" && (
                            <FormRadioGroup
                              key={`radio-group-${question.id}`}
                              name={`question-${question.id}-answers`}
                              label={question.question as string}
                              items={question.answers?.map((answer) => ({
                                key: answer.id,
                                value: answer.answer,
                              }))}
                              onChange={handleQuestionsRadioChange}
                              value={quizQuestionRadValueHandle(question)}
                              disabled={disableStudy()}
                            />
                          )}
                          {question.questionType === "Multiple" && (
                            <>
                              <MuiFormLabel>{question.question}</MuiFormLabel>
                              <br />
                              {question.answers?.map(
                                (answer: IQuizAnswerDTO, key: number) => (
                                  <>
                                    <FormCheckBox
                                      size="medium"
                                      key={answer.id}
                                      name={`question-${question.id}-answer-${answer.id}`}
                                      label={answer.answer}
                                      onChange={handleQuestionsChkChange}
                                      value={quizQuestionChkValueHandle(
                                        question.id,
                                        answer.id
                                      )}
                                      showSkeleton={
                                        isLoadingFan ||
                                        isLoading ||
                                        isLoadingQuestions
                                      }
                                      disabled={disableStudy()}
                                    />
                                    <br />
                                  </>
                                )
                              )}
                            </>
                          )}
                        </Grid>
                      )
                    )}
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                    <Grid item xs={12}>
                      <FormCheckBox
                        size="medium"
                        name="includeBearingReport"
                        label="Create a bearing report"
                        onChange={handleFanValuesChange}
                        value={fanValues?.includeBearingReport}
                        showSkeleton={
                          isLoadingFan || isLoading || isLoadingBearing
                        }
                        disabled={disableStudy()}
                      />
                    </Grid>
                    {fanValues?.includeBearingReport && (
                      <>
                        <Grid item xs={12}>
                          <h2>Bearing Data</h2>
                          <FormButton
                            variant="contained"
                            color="success"
                            text="Add Bearing"
                            endIcon={<AddIcon />}
                            onClick={handleAddBearing}
                            size="small"
                            disabled={disableStudy()}
                          />
                          <TableContainer
                            sx={{ maxHeight: 480 }}
                            component={Paper}
                          >
                            <Table stickyHeader aria-label="static components">
                              <TableHead>
                                <TableRow>
                                  <TableCell style={{ textAlign: "center" }}>
                                    Bearing ID Number
                                  </TableCell>
                                  <TableCell style={{ textAlign: "center" }}>
                                    Location
                                  </TableCell>
                                  <TableCell style={{ textAlign: "center" }}>
                                    Type
                                  </TableCell>
                                  <TableCell style={{ textAlign: "center" }}>
                                    Manufacturer
                                  </TableCell>
                                  <TableCell style={{ textAlign: "center" }}>
                                    {`Shaft Dimension (${systemOfMeasurement.get(
                                      "in"
                                    )})`}
                                    <FormTooltip
                                      titleName="Fan Shaft Diameter"
                                      showSkeleton={isLoadingFan || isLoading}
                                    />
                                  </TableCell>
                                  <TableCell style={{ textAlign: "center" }}>
                                    Floating
                                  </TableCell>
                                  <TableCell style={{ textAlign: "center" }}>
                                    Fixed
                                  </TableCell>
                                  <TableCell style={{ textAlign: "center" }}>
                                    Greased
                                  </TableCell>
                                  <TableCell style={{ textAlign: "center" }}>
                                    Recommend Replacement
                                  </TableCell>
                                  <TableCell style={{ textAlign: "center" }}>
                                    Actions
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {bearingValues &&
                                  bearingValues.map(
                                    (row: IBearingDTO, index: number) => (
                                      <>
                                        <TableRow
                                          key={`bearing-${row.id}-ge-${index}`}
                                          style={{
                                            backgroundColor:
                                              index % 2 > 0
                                                ? isDarkTheme
                                                  ? "#243549"
                                                  : "#f3f3f3"
                                                : "inherit",
                                          }}
                                        >
                                          <TableCell
                                            style={{
                                              textAlign: "center",
                                            }}
                                          >
                                            <FormText
                                              label=""
                                              name="idNumber"
                                              value={row.idNumber || ""}
                                              onChange={(e: any) =>
                                                handleBearingValuesChange(
                                                  e,
                                                  row,
                                                  index
                                                )
                                              }
                                              showSkeleton={
                                                isLoading ||
                                                isLoadingBearing ||
                                                isAddingBearing
                                              }
                                              disabled={disableStudy()}
                                            />
                                          </TableCell>
                                          <TableCell
                                            style={{
                                              textAlign: "center",
                                            }}
                                          >
                                            <FormText
                                              label=""
                                              name="location"
                                              value={row.location || ""}
                                              onChange={(e: any) =>
                                                handleBearingValuesChange(
                                                  e,
                                                  row,
                                                  index
                                                )
                                              }
                                              showSkeleton={
                                                isLoading ||
                                                isLoadingBearing ||
                                                isAddingBearing
                                              }
                                              disabled={disableStudy()}
                                            />
                                          </TableCell>
                                          <TableCell
                                            style={{
                                              textAlign: "center",
                                            }}
                                          >
                                            <FormText
                                              label=""
                                              name="type"
                                              value={row.type || ""}
                                              onChange={(e: any) =>
                                                handleBearingValuesChange(
                                                  e,
                                                  row,
                                                  index
                                                )
                                              }
                                              showSkeleton={
                                                isLoading ||
                                                isLoadingBearing ||
                                                isAddingBearing
                                              }
                                              disabled={disableStudy()}
                                            />
                                          </TableCell>
                                          <TableCell
                                            style={{
                                              textAlign: "center",
                                            }}
                                          >
                                            <FormText
                                              label=""
                                              name="manufacturer"
                                              value={row.manufacturer || ""}
                                              onChange={(e: any) =>
                                                handleBearingValuesChange(
                                                  e,
                                                  row,
                                                  index
                                                )
                                              }
                                              showSkeleton={
                                                isLoading ||
                                                isLoadingBearing ||
                                                isAddingBearing
                                              }
                                              disabled={disableStudy()}
                                            />
                                          </TableCell>
                                          <TableCell
                                            style={{
                                              textAlign: "center",
                                            }}
                                          >
                                            <FormNumeric2
                                              label=""
                                              name="shaftDimension"
                                              value={row.shaftDimension || 0}
                                              onChange={(e: any) =>
                                                handleBearingValuesChange(
                                                  e,
                                                  row,
                                                  index
                                                )
                                              }
                                              showSkeleton={
                                                isLoading ||
                                                isLoadingBearing ||
                                                isAddingBearing
                                              }
                                              disabled={disableStudy()}
                                            />
                                          </TableCell>
                                          <TableCell
                                            style={{
                                              textAlign: "center",
                                            }}
                                          >
                                            <FormCheckBox
                                              label=""
                                              name="float"
                                              size="medium"
                                              value={row.float}
                                              onChange={(e: any) =>
                                                handleBearingValuesChange(
                                                  e,
                                                  row,
                                                  index
                                                )
                                              }
                                              showSkeleton={
                                                isLoading ||
                                                isLoadingBearing ||
                                                isAddingBearing
                                              }
                                              disabled={disableStudy()}
                                            />
                                          </TableCell>
                                          <TableCell
                                            style={{
                                              textAlign: "center",
                                            }}
                                          >
                                            <FormCheckBox
                                              label=""
                                              name="fixed"
                                              size="medium"
                                              value={row.fixed}
                                              onChange={(e: any) =>
                                                handleBearingValuesChange(
                                                  e,
                                                  row,
                                                  index
                                                )
                                              }
                                              showSkeleton={
                                                isLoading ||
                                                isLoadingBearing ||
                                                isAddingBearing
                                              }
                                              disabled={disableStudy()}
                                            />
                                          </TableCell>
                                          <TableCell
                                            style={{
                                              textAlign: "center",
                                              minWidth: 150,
                                            }}
                                          >
                                            <FormSelect
                                              label=""
                                              name="greased"
                                              value={row.greased || ""}
                                              options={bearingGreasedOptions}
                                              onChange={(e: any) =>
                                                handleBearingValuesChange(
                                                  e,
                                                  row,
                                                  index
                                                )
                                              }
                                              showSkeleton={
                                                isLoading ||
                                                isLoadingBearing ||
                                                isAddingBearing
                                              }
                                              disabled={disableStudy()}
                                            />
                                          </TableCell>
                                          <TableCell
                                            style={{
                                              textAlign: "center",
                                            }}
                                          >
                                            <FormCheckBox
                                              label=""
                                              name="recommendReplacement"
                                              size="medium"
                                              value={row.recommendReplacement}
                                              onChange={(e: any) =>
                                                handleBearingValuesChange(
                                                  e,
                                                  row,
                                                  index
                                                )
                                              }
                                              showSkeleton={
                                                isLoading ||
                                                isLoadingBearing ||
                                                isAddingBearing
                                              }
                                              disabled={disableStudy()}
                                            />
                                          </TableCell>
                                          <TableCell
                                            rowSpan={2}
                                            style={{
                                              textAlign: "center",
                                            }}
                                          >
                                            <GridActionButton
                                              type="delete"
                                              disabled={disableStudy()}
                                              onClick={() => {
                                                setCurrentBearingId(row.id);
                                                setCurrentBearingIdx(index);
                                                setOpenDeleteDialog(true);
                                              }}
                                              tooltip="Delete component"
                                            />
                                          </TableCell>
                                        </TableRow>
                                        <TableRow
                                          key={`bearing-${row.id}-co-${index}`}
                                          style={{
                                            backgroundColor:
                                              index % 2 > 0
                                                ? isDarkTheme
                                                  ? "#243549"
                                                  : "#f3f3f3"
                                                : "inherit",
                                          }}
                                        >
                                          <TableCell
                                            colSpan={9}
                                            style={{
                                              textAlign: "center",
                                            }}
                                          >
                                            <FormText
                                              label="Comments"
                                              name="comments"
                                              placeholder="Type your comments here..."
                                              value={row.comments || ""}
                                              onChange={(e: any) =>
                                                handleBearingValuesChange(
                                                  e,
                                                  row,
                                                  index
                                                )
                                              }
                                              showSkeleton={
                                                isLoading ||
                                                isLoadingBearing ||
                                                isAddingBearing
                                              }
                                              disabled={disableStudy()}
                                            />
                                          </TableCell>
                                        </TableRow>
                                      </>
                                    )
                                  )}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </Grid>
                      </>
                    )}
                  </Grid>
                </>
              )}
              {tabSelected === _TAB_MT && (
                <Motor
                  id={selectedFanMotor}
                  triggerSave={triggerMotorSave}
                  triggerMoveBack={triggerMoveBack}
                  triggerMoveNext={triggerMoveNext}
                  setTriggerSave={setTriggerMotorSave}
                  setTriggerMoveBack={setTriggerMoveBack}
                  setTriggerMoveNext={setTriggerMoveNext}
                  setIsUpdating={setIsUpdating}
                  moveBackHandler={motorMoveBackHandler}
                  moveNextHandler={motorMoveNextHandler}
                  isFanMotor
                  reportId={id}
                  projectId={values?.projectId}
                  isArray={values?.fanSystemType === "FanArray"}
                  index={selectedFanMotorIndex}
                  showSkeleton={isLoading}
                  isDisabled={disableStudy()}
                />
              )}
              <FooterButtons
                activeStep={activeStep}
                stepsCount={steps.length}
                projectId={values?.projectId as number}
                companyId={user?.companyId as number}
                isSaving={isUpdating}
                disabled={isUpdating || isLoading || disableStudy()}
                reportName={title as string}
                saveHandler={saveHandler}
                hideSaveButton={disableStudy()}
                nextStepHandler={nextStepHandler}
                previousStepHandler={previousStepHandler}
                isComplete={values?.isComplete ?? true}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default HeadSheetStep3;
