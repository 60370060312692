import axios from "../utils/axios";
import {
  IChangeOrdersForward,
  IChangeOrdersForwardRequest,
} from "src/ts/interfaces/changeOrdersForward";

const create = async (item: IChangeOrdersForwardRequest) => {
  return axios.post("ChangeOrdersForward", item);
};

const getAll = async (id: number) => {
  return axios.get<IChangeOrdersForward[]>(`ChangeOrdersForward/${id}`);
};

const remove = async (id: number) => {
  return axios.delete(`ChangeOrdersForward?id=${id}`);
};

const send = async (id: number) => {
  return axios.get(`ChangeOrdersForward/sendEmails/${id}`);
};

const changeOrdersForwardService = {
  create,
  getAll,
  remove,
  send,
};

export default changeOrdersForwardService;
