import React, { ChangeEvent, useEffect, useState } from "react";
import styled from "@emotion/styled";
import { useParams } from "react-router-dom";
import { Grid, Divider as MuiDivider, Alert } from "@mui/material";
import { spacing } from "@mui/system";
import { useAsyncMutation, useAsyncQuery, useAuth } from "src/hooks";
import { IPictureLogDto } from "src/ts/interfaces";
import Actions from "./components/Actions";
import { pictureLogService } from "src/services";
import PictureLogItem from "./components/PictureLogItem";
import getProjectSingleService from "src/services/project/getProjectSingleService";
import { ProjectDTO } from "src/ts/interfaces/project/projectDto";
import { ROLES, projectStatus } from "src/constants";
import HeaderPage from "src/components/page/HeaderPage";

const Divider = styled(MuiDivider)(spacing);

export type DocumentLibraryParams = {
  projectId: string;
  companyId: string;
  returnPage: string;
};

const PictureLog = () => {
  const { projectId } = useParams<DocumentLibraryParams>();
  const projectIdNumber = Number(projectId);
  const [refreshGrid, setRefreshGrid] = useState(false);
  const { user } = useAuth();

  const { execute: getPictures, data: pictures } = useAsyncQuery<
    IPictureLogDto[]
  >(pictureLogService.getByProject);

  const { execute: getProject, data: project } = useAsyncQuery<ProjectDTO>(
    getProjectSingleService.getById
  );

  useEffect(() => {
    const getData = async (projectIdNumber: number) => {
      await getPictures(projectIdNumber);
      await getProject(projectIdNumber);
    };

    if (projectIdNumber !== null && projectIdNumber !== undefined) {
      getData(projectIdNumber);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectIdNumber, refreshGrid]);

  const { execute: uploadFile, isSubmitting: isSubmittingFile } =
    useAsyncMutation(pictureLogService.uploadFile);

  const handleFileUpload = async (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      return;
    }
    const file = e.target.files[0];

    await uploadFile({
      type: "",
      content: file,
      projectId: projectIdNumber.toString(),
    });
    setRefreshGrid(!refreshGrid);
  };

  const refreshGridHandler = () => {
    setRefreshGrid(!refreshGrid);
  };

  const isProjectCompleted =
    project?.projectStatus?.name === projectStatus.Complete.name ||
    project?.projectStatus?.name === projectStatus.Paid.name ||
    project?.projectStatus?.name === projectStatus.Approved.name ||
    project?.projectStatus?.name === projectStatus.InReview.name ||
    project?.projectStatus?.name === projectStatus.Rejected.name;

  const showUploadButton =
    !(
      project?.projectStatus?.name === projectStatus.Contest.name &&
      user?.role === ROLES.CompanyOwner
    ) && !isProjectCompleted;

  const disableUploadButton =
    project?.projectStatusId === projectStatus.Incomplete.id ||
    project?.projectStatusId === projectStatus.Canceled.id;

  return (
    <>
      <HeaderPage
        title="Picture Log"
        parentText="Dashboard"
        parentLink="/"
        actionSection={
          <Actions
            onFileUpload={handleFileUpload}
            showUploadButton={showUploadButton}
            disableUploadButton={disableUploadButton}
            isSubmittingFile={isSubmittingFile}
          />
        }
      />
      <Divider my={6} />
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Alert severity="warning">
            File must be in PNG, JPG, JPEG, GIF or BMP format
          </Alert>
        </Grid>
        {pictures?.map((pictureLog: IPictureLogDto) => {
          return (
            <Grid item xs={12} lg={6} xl={3}>
              <PictureLogItem
                chip={<div></div>}
                pictureLog={pictureLog}
                onRefreshGrid={refreshGridHandler}
                key={pictureLog.id}
              />
            </Grid>
          );
        })}
      </Grid>
    </>
  );
};

export default PictureLog;
