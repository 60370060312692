import React, { useEffect } from "react";
import {
  Alert,
  Avatar,
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography,
  Box,
  Divider,
  styled,
} from "@mui/material";
import { useDropzone } from "react-dropzone";
import CloseTwoToneIcon from "@mui/icons-material/CloseTwoTone";
import CheckTwoToneIcon from "@mui/icons-material/CheckTwoTone";
import CloudUploadTwoToneIcon from "@mui/icons-material/CloudUploadTwoTone";
import DoNotDisturbIcon from "@mui/icons-material/DoNotDisturb";
import { alpha } from "@mui/material";
import { CircularProgress } from "@mui/material";

const AvatarWrapper = styled(Avatar)`
  background: ${(props) => alpha(props.theme.palette.primary.main, 0.2)};
  color: ${(props) => props.theme.palette.primary.main};
  width: ${(props) => props.theme.spacing(10)};
  height: ${(props) => props.theme.spacing(10)};
`;

const AvatarSuccess = styled(Avatar)`
  background: ${(props) => props.theme.palette.success.light};
  width: ${(props) => props.theme.spacing(10)};
  height: ${(props) => props.theme.spacing(10)};
`;

const AvatarDanger = styled(Avatar)`
  background: ${(props) => props.theme.palette.error.light};
  width: ${(props) => props.theme.spacing(10)};
  height: ${(props) => props.theme.spacing(10)};
`;

const BoxUploadWrapper = styled(Box)(
  ({ theme }) => `
      border-radius: 10px;
      padding: ${theme.spacing(3)};
      background:${alpha("#000", 0.03)};
      border: 1px dashed ${alpha(theme.palette.primary.main, 0.3)};
      outline: none;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      transition: ${theme.transitions.create(["border", "background"])};

      &:hover {
        background:  ${alpha(theme.palette.primary.main, 0.03)};
        border-color: ${theme.palette.primary.main};
      }
  `
);

export interface Accept {
  [key: string]: string[];
}

interface DropZoneProps {
  onFileAccepted: (file: File) => void;
  accept: Accept;
  disabled: boolean;
  disableMessage?: string;
  submiting: boolean;
  hide?: boolean;
  submittingMessage?: string | null;
}

const DropZone = (props: DropZoneProps) => {
  const {
    onFileAccepted,
    accept,
    disabled,
    disableMessage,
    submiting,
    hide,
    submittingMessage,
  } = props;
  const {
    acceptedFiles,
    isDragActive,
    isDragAccept,
    isDragReject,
    getRootProps,
    getInputProps,
  } = useDropzone({
    disabled: disabled || submiting,
    accept: {
      ...accept,
    },
  });

  useEffect(() => {
    if (acceptedFiles.length > 0) {
      onFileAccepted(acceptedFiles[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [acceptedFiles.length]);

  const files = acceptedFiles.map((file: File, index) => {
    return (
      <ListItem disableGutters component="div" key={index}>
        <ListItemText primary={file.name} />
        <b>{file.size} bytes</b>
        <Divider />
      </ListItem>
    );
  });

  return (
    <Grid container spacing={0} visibility={hide ? "hidden" : "visible"}>
      <Grid item xs={12}>
        <BoxUploadWrapper {...getRootProps()}>
          <input {...getInputProps()} />
          {isDragAccept && !submiting && !disabled && (
            <>
              <AvatarSuccess variant="rounded">
                <CheckTwoToneIcon />
              </AvatarSuccess>
              <Typography
                sx={{
                  mt: 2,
                }}
              >
                {"Drop the files to start uploading"}
              </Typography>
            </>
          )}
          {isDragReject && !submiting && !disabled && (
            <>
              <AvatarDanger variant="rounded">
                <CloseTwoToneIcon />
              </AvatarDanger>
              <Typography
                sx={{
                  mt: 2,
                }}
              >
                {"You cannot upload these file types"}
              </Typography>
            </>
          )}
          {!isDragActive && !submiting && !disabled && (
            <>
              <AvatarWrapper variant="rounded">
                <CloudUploadTwoToneIcon />
              </AvatarWrapper>
              <Typography
                sx={{
                  mt: 2,
                }}
              >
                {"Drag & drop files here"}
              </Typography>
            </>
          )}
          {disabled && !submiting && (
            <>
              <AvatarWrapper variant="rounded">
                <DoNotDisturbIcon />
              </AvatarWrapper>
              <Typography
                sx={{
                  mt: 2,
                }}
              >
                {disableMessage ?? "No more files are allowed"}
              </Typography>
            </>
          )}
          {submiting && (
            <>
              <AvatarWrapper variant="rounded">
                <CircularProgress size="1rem" />
              </AvatarWrapper>
              <Typography
                sx={{
                  mt: 2,
                }}
              >
                {!submittingMessage ? "Uploading file ..." : submittingMessage}
              </Typography>
            </>
          )}
        </BoxUploadWrapper>
        {files.length > 0 ||
          (hide && (
            <>
              <Alert
                sx={{
                  py: 0,
                  mt: 2,
                }}
                severity="success"
              >
                {"You have uploaded"} <b>{files.length}</b> {"files"}!
              </Alert>
              <Divider
                sx={{
                  mt: 2,
                }}
              />
              <List disablePadding component="div">
                {files}
              </List>
            </>
          ))}
      </Grid>
    </Grid>
  );
};

export default DropZone;
