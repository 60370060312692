import { IEnergyApprovalComments } from "src/ts/interfaces/catalogs";
import axios from "src/utils/axios";

const get = (id: number) => {
  return axios.get<IEnergyApprovalComments[]>("EnergyApprovalComments");
};

const energyApprovalCommentsService = {
  get,
};

export default energyApprovalCommentsService;
