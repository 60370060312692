import {
  Alert,
  IconButton,
  Stack,
  TableCell,
  TableRow,
  Tooltip,
  Zoom,
} from "@mui/material";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import Spreadsheet, {
  ISpreadsheetColumn,
} from "src/components/spreadsheet/Spreadsheet";
import { IKeyValue } from "src/ts/interfaces";
import {
  ITower,
  ITowerWeigtedTest,
  ITowerWeigtedTestItem,
} from "src/ts/interfaces/study/tower";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import criteriaCurvesService from "src/services/catalogs/criteriaCurvesService";
import { ICriteriaCurves } from "src/ts/interfaces/catalogs";
import { useLog } from "src/hooks";
import useTowerStudy from "../../hooks/useTowerStudy";
import towerWeigtedTestService from "src/services/study/towerWeigtedTestService";
import studiesHistoryService from "src/services/study/studiesHistoryService";
import { IProjectReport } from "src/ts/interfaces/project/projectDto";
import SpreadSheetCell from "src/components/spreadsheet/SpreadSheetCell";
import SpreadSheetActionButton from "src/components/spreadsheet/SpreadSheetActionButton";
import DialogMessagePopup from "src/components/DialogMessagePopup";
import { InfoIcon } from "src/components/icons";
import tooltipService from "src/services/tooltipService";
import { ITooltip } from "src/ts/interfaces/tooltip";
import { Mode } from "src/ts/types";
// Load Highcharts modules
require("highcharts/modules/exporting")(Highcharts);

interface Props {
  items: ITowerWeigtedTestItem[];
  setItems: Dispatch<SetStateAction<ITowerWeigtedTestItem[]>>;
  towerValues: ITower;
  dataReport: IProjectReport;
  selectedTowerWeigtedTest: ITowerWeigtedTest | null;
  towerWeigtedTests: ITowerWeigtedTest[];
  mode: Mode;
  disableStudy: boolean;
  isLoading: boolean;
}

const takenAtOptions: IKeyValue<string, string>[] = [
  { key: "N", value: "N" },
  { key: "NE", value: "NE" },
  { key: "E", value: "E" },
  { key: "SE", value: "SE" },
  { key: "S", value: "S" },
  { key: "SW", value: "SW" },
  { key: "W", value: "W" },
  { key: "NW", value: "NW" },
];

const weigtedTestOptions: IKeyValue<string, string>[] = [
  { key: "dB(A)", value: "dB(A) - Recommended" },
  { key: "dB(B)", value: "dB(B)" },
  { key: "dB(C)", value: "dB(C)" },
];

const noiseCriteriaCurvesOptions: IKeyValue<string, string>[] = [
  { key: "NC", value: "NC - Recommended" },
  { key: "RC", value: "RC" },
  { key: "Non-Rated", value: "Non-Rated" },
];

const TowerWeigtedTestItemGrid = ({
  items,
  setItems,
  towerValues,
  dataReport,
  selectedTowerWeigtedTest,
  towerWeigtedTests,
  mode,
  disableStudy,
  isLoading,
}: Props) => {
  const { updateTowerStudy } = useTowerStudy();
  const { log } = useLog();

  const customHeaders = (weigtedTestItem: ITowerWeigtedTestItem) => {
    return (
      <>
        <TableRow>
          <TableCell rowSpan={2}>Location</TableCell>
          <TableCell rowSpan={2}>Distance from Tower ( feet )</TableCell>
          <TableCell rowSpan={2}>Taken at (N, S, E, W)</TableCell>
          {(weigtedTestItem?.noiseCriteriaCurve === "RC" ||
            weigtedTestItem?.noiseCriteriaCurve === "NC") && (
            <TableCell rowSpan={2}>
              {weigtedTestItem?.noiseCriteriaCurve === "RC"
                ? "Design RC"
                : "Design NC"}
            </TableCell>
          )}

          <TableCell
            colSpan={weigtedTestItem?.noiseCriteriaCurve === "RC" ? 9 : 8}
          >
            Octave Frequency Bands
          </TableCell>
          <TableCell rowSpan={2}>Noise Criteria Curves</TableCell>
          <TableCell rowSpan={2}>
            <Tooltip
              TransitionComponent={Zoom}
              title={
                <div
                  dangerouslySetInnerHTML={{
                    __html: toolTip ? toolTip?.content : "",
                  }}
                />
              }
            >
              <span>
                <IconButton aria-label="delete" size="large">
                  <InfoIcon />
                </IconButton>
              </span>
            </Tooltip>
            Weighted Test
          </TableCell>
          <TableCell rowSpan={2}>Actions</TableCell>
        </TableRow>
        <TableRow>
          {weigtedTestItem?.noiseCriteriaCurve === "RC" && (
            <TableCell>16 Hz</TableCell>
          )}

          <TableCell>
            {weigtedTestItem?.noiseCriteriaCurve === "RC" ? "32Hz" : "63 Hz"}
          </TableCell>
          <TableCell>
            {weigtedTestItem?.noiseCriteriaCurve === "RC" ? "63Hz" : "125 Hz"}
          </TableCell>
          <TableCell>
            {weigtedTestItem?.noiseCriteriaCurve === "RC" ? "125Hz" : "250 Hz"}
          </TableCell>
          <TableCell>
            {weigtedTestItem?.noiseCriteriaCurve === "RC" ? "250Hz" : "500 Hz"}
          </TableCell>
          <TableCell>
            {weigtedTestItem?.noiseCriteriaCurve === "RC" ? "500Hz" : "1 KHz"}
          </TableCell>
          <TableCell>
            {weigtedTestItem?.noiseCriteriaCurve === "RC" ? "1KHz" : "2 KHz"}
          </TableCell>
          <TableCell>
            {weigtedTestItem?.noiseCriteriaCurve === "RC" ? "2KHz" : "4 KHz"}
          </TableCell>
          <TableCell>
            {weigtedTestItem?.noiseCriteriaCurve === "RC" ? "4KHz" : "8KHz"}
          </TableCell>
        </TableRow>
      </>
    );
  };

  const [criteriaCurves, setCriteriaCurves] = useState<ICriteriaCurves[]>();
  const [options, setOptions] = useState<any>();
  const [toolTip, setToolTip] = useState<ITooltip | null>(null);
  useEffect(() => {
    const getCriteriaCurves = async () => {
      const creiterialRes = await criteriaCurvesService.getAll();
      setCriteriaCurves(creiterialRes.data);

      const tooltipRes = await tooltipService.getById(27);
      setToolTip(tooltipRes.data);
      setRefreshGraph(!refreshGraph);
    };

    getCriteriaCurves();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [refreshGraph, setRefreshGraph] = useState(false);
  useEffect(() => {
    plotChart();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshGraph]);

  useEffect(() => {
    plotChart();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTowerWeigtedTest]);

  const updateItem = (
    updatedTowerWeigtedTestItem: ITowerWeigtedTestItem,
    index: number
  ) => {
    const updatedItems = items.map((item, i) =>
      i === index ? updatedTowerWeigtedTestItem : item
    );

    setItems(updatedItems);
  };

  const setItemValue = (
    field: string,
    value: number,
    crud: ITowerWeigtedTestItem
  ) => {
    const updatedTowerWeigtedTestItem: ITowerWeigtedTestItem = {
      ...crud,
      [field]: value,
    };
    return updatedTowerWeigtedTestItem;
  };

  const nearestCriteriaCurvePrevious = async (
    band: string,
    crud: ITowerWeigtedTestItem,
    field: string,
    e: any,
    index: number,
    save: boolean = true
  ) => {
    let value = parseFloat(e.target.value);
    let updatedCrud = setItemValue(field, value, crud);

    const crd = await nearestCriteriaCurve(band, updatedCrud, field, items);
    if (crd) {
      updateItem(crd, index);
    }
    setRefreshGraph(!refreshGraph);
  };

  const nearestCriteriaCurve = async (
    band: string,
    crud: ITowerWeigtedTestItem,
    field: string,
    items: ITowerWeigtedTestItem[],
    save: boolean = true
  ) => {
    let updatedCrud: ITowerWeigtedTestItem = { ...crud };

    const db = crud[field as keyof ITowerWeigtedTestItem] as number;
    if (db == null) {
      return null;
    }
    if (items.length === 0) {
      return null;
    }

    const criteria = items[0].noiseCriteriaCurve;

    if (criteria == null) {
      log.error("First select Noise Criteria Curve");
      //updateItem(updatedCrud, index);
      return updatedCrud;
    }

    if (criteria === "Non-Rated") {
      //updateItem(updatedCrud, index);
      return updatedCrud;
    }

    let result: ICriteriaCurves = {
      id: 0,
      band: "",
      criteria: "",
      curve: "",
      value: 0,
      actualCriteria: 0,
    };
    const curves = criteriaCurves?.filter(
      (item) => item.band === band && item.criteria === criteria
    );
    if (curves?.length === 0) {
      log.error("Error determining Actual Criteria");
      //updateItem(updatedCrud, index);
      return updatedCrud;
    }

    if (parseFloat(db.toString()) > 90) {
      log.error("dB can not exceed 90");
      const modfiedRow = setItemValue(field, 0, crud);
      return modfiedRow;
    }
    if (parseFloat(db.toString()) < 0) {
      log.error("dB can not be negative");
      const modfiedRow = setItemValue(field, 0, crud);
      return modfiedRow;
    }

    let curveFound = false;
    curves?.forEach((item, index) => {
      //First element
      if (parseFloat(db.toString()) <= item.value && index === 0) {
        const valueHigh = item.value;
        const percent = db / valueHigh;
        item.actualCriteria = Math.round(parseFloat(item.curve) * percent);
        item.actualCriteria =
          isNaN(item.actualCriteria) || item.actualCriteria == null
            ? 0
            : item.actualCriteria;
        result = item;
        curveFound = true;
      }
      if (index < curves.length - 1) {
        //Elements in the middle
        const nextItem = curves[index + 1];
        if (
          parseFloat(db.toString()) > item.value &&
          parseFloat(db.toString()) <= nextItem.value &&
          !curveFound
        ) {
          const { value } = item;
          const valueHigh = nextItem.value;
          const gap = Math.abs(valueHigh - value);
          const diff = Math.abs(parseFloat(db.toString()) - value);
          const percent = diff / gap;
          item.actualCriteria = Math.round(
            parseFloat(item.curve) + gap * percent
          );
          item.actualCriteria =
            isNaN(item.actualCriteria) || item.actualCriteria == null
              ? 0
              : item.actualCriteria;
          result = item;
          curveFound = true;
        }
      } else {
        //List element
        if (parseFloat(db.toString()) > item.value && !curveFound) {
          const { value } = item;
          const valueHigh = 90;
          const gap = Math.abs(valueHigh - value);
          const diff = Math.abs(parseFloat(db.toString()) - value);
          const percent = diff / gap;
          item.actualCriteria = Math.round(
            parseFloat(item.curve) + gap * percent
          );
          item.actualCriteria =
            isNaN(item.actualCriteria) || item.actualCriteria == null
              ? 0
              : item.actualCriteria;
          result = item;
          curveFound = true;
        }
      }
    });

    const criterialField = field + "CriteriaValue";
    updatedCrud = setItemValue(
      criterialField,
      result.actualCriteria,
      updatedCrud
    );

    let actualCriteriaValue = Math.max(
      updatedCrud.hz16CriteriaValue,
      updatedCrud.hz63CriteriaValue,
      updatedCrud.hz125CriteriaValue,
      updatedCrud.hz250CriteriaValue,
      updatedCrud.hz500CriteriaValue,
      updatedCrud.hz1000CriteriaValue,
      updatedCrud.hz2000CriteriaValue,
      updatedCrud.hz4000CriteriaValue,
      updatedCrud.hz8000CriteriaValue
    );
    actualCriteriaValue =
      isNaN(actualCriteriaValue) ||
      actualCriteriaValue == null ||
      actualCriteriaValue === undefined
        ? 0
        : actualCriteriaValue;

    updatedCrud = setItemValue(
      "actualCriteriaValue",
      actualCriteriaValue,
      updatedCrud
    );
    if (save) await saveWeigthedTest(crud);

    return updatedCrud;
  };

  const saveWeigthedTest = async (item: ITowerWeigtedTestItem) => {
    await updateTowerStudy();
    await towerWeigtedTestService.updateTowerWeigtedTestItem(item);
    plotChart();
  };

  const nearestCriteriaCurveAll = async (
    e: any,
    row: ITowerWeigtedTestItem,
    rowIndex: number
  ) => {
    const value = e.target.value;
    let updatedRow: ITowerWeigtedTestItem | null = {
      ...row,
      noiseCriteriaCurve: value,
    };

    let itms = items.map((item, i) => (i === rowIndex ? updatedRow! : item!));
    itms = itms.map((item) => ({ ...item, noiseCriteriaCurve: value }));
    if (itms.length === 0) {
      return null;
    }

    if (value === "Non-Rated") {
      setItems(itms);
      return null;
    }

    if (updatedRow === null) return;

    itms.forEach(async (item, index) => {
      let itm = await nearestCriteriaCurve("16Hz", item, "hz16", itms, false);
      itm = await nearestCriteriaCurve("63Hz", itm!, "hz63", itms, false);
      itm = await nearestCriteriaCurve("125Hz", itm!, "hz125", itms, false);
      itm = await nearestCriteriaCurve("250Hz", itm!, "hz250", itms, false);
      itm = await nearestCriteriaCurve("500Hz", itm!, "hz500", itms, false);
      itm = await nearestCriteriaCurve("1000Hz", itm!, "hz1000", itms, false);
      itm = await nearestCriteriaCurve("2000Hz", itm!, "hz2000", itms, false);
      itm = await nearestCriteriaCurve("4000Hz", itm!, "hz4000", itms, false);
      itm = await nearestCriteriaCurve("8000Hz", itm!, "hz8000", itms, false);
      itms = itms.map((item, i) => (i === index ? itm! : item!));
    });

    setItems(itms);

    await updateTowerStudy();
    studiesHistoryService.save(
      towerValues.projectId,
      dataReport.reportTypeId,
      towerValues.id,
      "saved"
    );
    await towerWeigtedTestService.updateTowerWeigtedTests(towerWeigtedTests);
    await Promise.all(
      itms.map(
        async (item) =>
          await towerWeigtedTestService.updateTowerWeigtedTestItem(item)
      )
    );
    log.info("Report was saved");
    setRefreshGraph(!refreshGraph);
  };

  const readOnly = false;

  const columns: ISpreadsheetColumn[] = [
    {
      name: "type",
      type: "text",
      label: "Location",
      mode: mode,
      disabled: disableStudy,
    },
    {
      name: "soundLevelTestDistanceFromTower",
      type: "numericInput",
      label: "Distance from Tower ({feet})",
      align: "left",
      maxValue: 10000,
      decimalScale: 3,
      mode: mode,
      disabled: disableStudy,
    },
    {
      name: "soundTestWasTakenAt",
      label: "Taken at (N, S, E, W)",
      type: "select",
      items: takenAtOptions,
      mode: mode,
      disabled: disableStudy,
    },
    {
      name: "designCriteriaValue",
      type: "numericInput",
      label:
        items?.length > 0 && items[0].noiseCriteriaCurve === "RC"
          ? "Design RC"
          : "Design NC",
      align: "left",
      maxValue: 10000,
      decimalScale: 3,
      hide: !(
        (items?.length > 0 && items[0].noiseCriteriaCurve === "RC") ||
        (items?.length > 0 && items[0].noiseCriteriaCurve === "NC")
      ),
      mode: mode,
      disabled: disableStudy,
    },
    {
      name: "hz16",
      type: "numericInput",
      label: "hz16",
      align: "left",
      maxValue: 10000,
      decimalScale: 3,
      mode: mode,
      disabled: disableStudy,
      hide: !(items?.length > 0 && items[0].noiseCriteriaCurve === "RC"),
      onChange: async (row: ITowerWeigtedTestItem, e: any, index: number) =>
        await nearestCriteriaCurvePrevious("16Hz", row, "hz16", e, index),
    },
    {
      name: "hz63",
      type: "numericInput",
      label: "hz63",
      align: "left",
      maxValue: 10000,
      decimalScale: 3,
      mode: mode,
      disabled: disableStudy,
      onChange: async (row: ITowerWeigtedTestItem, e: any, index: number) =>
        await nearestCriteriaCurvePrevious("63Hz", row, "hz63", e, index),
    },
    {
      name: "hz125",
      type: "numericInput",
      label: "hz125",
      align: "left",
      maxValue: 10000,
      mode: mode,
      disabled: disableStudy,
      decimalScale: 3,
      onChange: async (row: ITowerWeigtedTestItem, e: any, index: number) =>
        await nearestCriteriaCurvePrevious("125Hz", row, "hz125", e, index),
    },
    {
      name: "hz250",
      type: "numericInput",
      label: "hz250",
      align: "left",
      maxValue: 10000,
      decimalScale: 3,
      mode: mode,
      disabled: disableStudy,
      onChange: async (row: ITowerWeigtedTestItem, e: any, index: number) =>
        await nearestCriteriaCurvePrevious("250Hz", row, "hz250", e, index),
    },
    {
      name: "hz500",
      type: "numericInput",
      label: "hz500",
      align: "left",
      maxValue: 10000,
      decimalScale: 3,
      mode: mode,
      disabled: disableStudy,
      onChange: async (row: ITowerWeigtedTestItem, e: any, index: number) =>
        await nearestCriteriaCurvePrevious("500Hz", row, "hz500", e, index),
    },
    {
      name: "hz1000",
      type: "numericInput",
      label: "hz1000",
      align: "left",
      maxValue: 10000,
      decimalScale: 3,
      mode: mode,
      disabled: disableStudy,
      onChange: async (row: ITowerWeigtedTestItem, e: any, index: number) =>
        await nearestCriteriaCurvePrevious("1000Hz", row, "hz1000", e, index),
    },
    {
      name: "hz2000",
      type: "numericInput",
      label: "hz2000",
      align: "left",
      maxValue: 10000,
      decimalScale: 3,
      mode: mode,
      disabled: disableStudy,
      onChange: async (row: ITowerWeigtedTestItem, e: any, index: number) =>
        await nearestCriteriaCurvePrevious("2000Hz", row, "hz2000", e, index),
    },
    {
      name: "hz4000",
      type: "numericInput",
      label: "hz4000",
      align: "left",
      maxValue: 10000,
      decimalScale: 3,
      mode: mode,
      disabled: disableStudy,
      onChange: async (row: ITowerWeigtedTestItem, e: any, index: number) =>
        await nearestCriteriaCurvePrevious("4000Hz", row, "hz4000", e, index),
    },
    {
      name: "hz8000",
      type: "numericInput",
      label: "hz8000",
      align: "left",
      maxValue: 10000,
      decimalScale: 3,
      mode: mode,
      disabled: disableStudy,
      onChange: async (row: ITowerWeigtedTestItem, e: any, index: number) =>
        await nearestCriteriaCurvePrevious("8000Hz", row, "hz8000", e, index),
    },
    {
      name: "noiseCriteriaCurve",
      label: "noiseCriteriaCurve",
      type: "select",
      items: noiseCriteriaCurvesOptions,
      disabled: disableStudy,
      //TODO: fix use mode
      mode: "read&Write",
      onChange: async (
        row: ITowerWeigtedTestItem,
        e: any,
        index: number
      ): Promise<void> => {
        await nearestCriteriaCurveAll(e, row, index);
      },
    },
    {
      name: "weigtedTest",
      label: "weigtedTest",
      type: "select",
      items: weigtedTestOptions,
      disabled: disableStudy,
      mode: mode,
    },
    {
      name: "actio1",
      type: "custom",
      render: (
        row: ITowerWeigtedTestItem,
        col: ISpreadsheetColumn,
        index: number
      ) => {
        return (
          <SpreadSheetCell>
            {mode === "read&Write" && !disableStudy ? (
              <Stack direction="row" justifyContent="center">
                {index + 1 === items.length && (
                  <SpreadSheetActionButton
                    type="add"
                    tooltip="Add new item"
                    onMouseUp={(e: any) => addWeigtedTestItem(row, index)}
                    disabled={readOnly}
                  />
                )}

                <SpreadSheetActionButton
                  type="delete"
                  tooltip="Delete this item"
                  onClick={() => deleteSoundTestItem(row, index)}
                  disabled={readOnly}
                />
              </Stack>
            ) : (
              <></>
            )}
          </SpreadSheetCell>
        );
      },
    },
  ];

  const plotChart = () => {
    if (items.length > 0) {
      if (items[0].noiseCriteriaCurve === "RC") {
        createRoomCriteriaChart();
      } else if (items[0].noiseCriteriaCurve === "NC") {
        createNoiseCriteriaChart();
      }
    }
  };

  const createNoiseCriteriaChart = () => {
    if (towerValues?.coolingTowerSoundPressureTesting) {
      let nc15data = [];
      const nc15 = criteriaCurves?.filter(
        (item) =>
          item.criteria === "NC" && item.curve === "15" && item.band !== "16Hz"
      );

      nc15?.forEach((element) => {
        nc15data.push(element.value);
      });
      let dataSeries: any[] = [
        {
          name: "NC 15",
          data: [47, 36, 29, 22, 17.5, 14, 12, 11],
          color: "#000000",
          lineWidth: 1,

          marker: {
            enabled: false,
          },
          showInLegend: false,
        },
        {
          name: "NC 20",
          data: [51, 40, 32.5, 26, 22.5, 19, 17, 16.5],
          color: "#000000",
          lineWidth: 1,

          marker: {
            enabled: false,
          },
          showInLegend: false,
        },
        {
          name: "NC 25",
          data: [53.5, 44, 37.5, 31, 27.5, 24, 22, 21],
          color: "#000000",
          lineWidth: 1,

          marker: {
            enabled: false,
          },
          showInLegend: false,
        },
        {
          name: "NC 30",
          data: [57, 48, 41, 35, 31, 29, 28, 27],
          color: "#000000",
          lineWidth: 1,

          marker: {
            enabled: false,
          },
          showInLegend: false,
        },
        {
          name: "NC 35",
          data: [59.5, 52, 45, 40, 36, 34, 33, 32],
          color: "#000000",
          lineWidth: 1,

          marker: {
            enabled: false,
          },
          showInLegend: false,
        },
        {
          name: "NC 40",
          data: [63, 56.5, 50, 45, 41, 39, 38.5, 37],
          color: "#000000",
          lineWidth: 1,

          marker: {
            enabled: false,
          },
          showInLegend: false,
        },
        {
          name: "NC 45",
          data: [67, 60, 54, 49, 46, 44, 43, 42],
          color: "#000000",
          lineWidth: 1,

          marker: {
            enabled: false,
          },
          showInLegend: false,
        },
        {
          name: "NC 50",
          data: [71, 64, 58, 54, 51, 49, 48, 47],
          color: "#000000",
          lineWidth: 1,

          marker: {
            enabled: false,
          },
          showInLegend: false,
        },
        {
          name: "NC 55",
          data: [73, 67, 62, 58, 56, 54, 52.5, 52],
          color: "#000000",
          lineWidth: 1,

          marker: {
            enabled: false,
          },
          showInLegend: false,
        },
        {
          name: "NC 60",
          data: [77, 71, 66, 63, 61, 59, 58, 57],
          color: "#000000",
          lineWidth: 1,

          marker: {
            enabled: false,
          },
          showInLegend: false,
        },
        {
          name: "NC 65",
          data: [80, 75, 71, 68, 66, 64, 62.5, 62],
          color: "#000000",
          lineWidth: 1,

          marker: {
            enabled: false,
          },
          showInLegend: false,
        },
        {
          name: "NC 70",
          data: [82.5, 78.5, 74.5, 71.5, 71, 70, 69, 68],
          color: "#000000",
          lineWidth: 1,

          marker: {
            enabled: false,
          },
          showInLegend: false,
        },
      ];

      let allZeros = true; //This variable is to prevent an error in the view when all the values are 0
      for (let i = 0; i < items.length; i++) {
        if (items[i].hz63 !== 0) {
          allZeros = false;
          break;
        }
        if (items[i].hz125 !== 0) {
          allZeros = false;
          break;
        }
        if (items[i].hz250 !== 0) {
          allZeros = false;
          break;
        }
        if (items[i].hz500 !== 0) {
          allZeros = false;
          break;
        }
        if (items[i].hz1000 !== 0) {
          allZeros = false;
          break;
        }
        if (items[i].hz2000 !== 0) {
          allZeros = false;
          break;
        }
        if (items[i].hz4000 !== 0) {
          allZeros = false;
          break;
        }
        if (items[i].hz8000 !== 0) {
          allZeros = false;
          break;
        }
      }

      for (let i = 0; i < items.length; i++) {
        let seriesItem = {
          name: items[i].type,
          data: [
            items[i].hz63 === 0 && !allZeros ? null : items[i].hz63,
            items[i].hz125 === 0 && !allZeros ? null : items[i].hz125,
            items[i].hz250 === 0 && !allZeros ? null : items[i].hz250,
            items[i].hz500 === 0 && !allZeros ? null : items[i].hz500,
            items[i].hz1000 === 0 && !allZeros ? null : items[i].hz1000,
            items[i].hz2000 === 0 && !allZeros ? null : items[i].hz2000,
            items[i].hz4000 === 0 && !allZeros ? null : items[i].hz4000,
            items[i].hz8000 === 0 && !allZeros ? null : items[i].hz8000,
          ],
        };

        dataSeries.push(seriesItem);
      }

      setOptions({
        chart: {
          type: "spline",
        },
        title: {
          text: "Noise Criteria Curves",
        },
        subtitle: {
          text: "Noise Criteria Raiting for each location",
        },
        yAxis: {
          title: {
            text: "Sound Pressure Level (dB re 20 mPa)",
          },
          tickPositions: [0, 10, 20, 30, 40, 50, 60, 70, 80, 90],
        },
        xAxis: {
          title: {
            text: "Octave Band Center Frequency (Hz)",
          },
          accessibility: {
            rangeDescription: "Range: 63 to 8000 Hz.",
          },
          categories: [
            "63Hz",
            "125Hz",
            "250Hz",
            "500Hz",
            "1000Hz",
            "2000Hz",
            "4000Hz",
            "8000Hz",
          ],
        },
        plotOptions: {
          series: {
            label: {
              connectorAllowed: false,
            },
          },
        },
        tooltip: {
          headerFormat: "<b>{series.name}</b><br/>",
          pointFormat: "{point.y} dB",
        },

        credits: {
          enabled: false,
        },
        responsive: {
          rules: [
            {
              condition: {
                maxWidth: 500,
              },
              chartOptions: {
                legend: {
                  layout: "horizontal",
                  align: "center",
                  verticalAlign: "bottom",
                },
              },
            },
          ],
        },
        series: dataSeries,
      });
    }
  };

  const createRoomCriteriaChart = () => {
    if (towerValues?.coolingTowerSoundPressureTesting) {
      let dataSeries: any[] = [
        {
          name: "RC 10",
          data: [35, 35, 30, 25, 20, 15, 10, 5, 0],
          color: "#000000",
          lineWidth: 1,
          marker: {
            enabled: false,
          },
          showInLegend: false,
        },
        {
          name: "RC 15",
          data: [40, 40, 35, 30, 25, 20, 15, 10, 5],
          color: "#000000",
          lineWidth: 1,
          marker: {
            enabled: false,
          },
          showInLegend: false,
        },
        {
          name: "RC 20",
          data: [45, 45, 40, 35, 30, 25, 20, 15, 10],
          color: "#000000",
          lineWidth: 1,
          marker: {
            enabled: false,
          },
          showInLegend: false,
        },
        {
          name: "RC 25",
          data: [50, 50, 45, 40, 35, 30, 25, 20, 15],
          color: "#000000",
          lineWidth: 1,
          marker: {
            enabled: false,
          },
          showInLegend: false,
        },
        {
          name: "RC 30",
          data: [55, 55, 50, 45, 40, 35, 30, 25, 20],
          color: "#000000",
          lineWidth: 1,
          marker: {
            enabled: false,
          },
          showInLegend: false,
        },
        {
          name: "RC 35",
          data: [60, 60, 55, 50, 45, 40, 35, 30, 25],
          color: "#000000",
          lineWidth: 1,
          marker: {
            enabled: false,
          },
          showInLegend: false,
        },
        {
          name: "RC 40",
          data: [65, 65, 60, 55, 50, 45, 40, 35, 30],
          color: "#000000",
          lineWidth: 1,
          marker: {
            enabled: false,
          },
          showInLegend: false,
        },
        {
          name: "RC 45",
          data: [70, 70, 65, 60, 55, 50, 45, 40, 35],
          color: "#000000",
          lineWidth: 1,
          marker: {
            enabled: false,
          },
          showInLegend: false,
        },
        {
          name: "RC 50",
          data: [75, 75, 70, 65, 60, 55, 50, 45, 40],
          color: "#000000",
          lineWidth: 1,
          marker: {
            enabled: false,
          },
          showInLegend: false,
        },
      ];

      let allZeros = true; //This variable is to prevent an error in the view when all the values are 0
      for (let i = 0; i < items.length; i++) {
        if (items[i].hz16 !== 0) {
          allZeros = false;
          break;
        }
        if (items[i].hz63 !== 0) {
          allZeros = false;
          break;
        }
        if (items[i].hz125 !== 0) {
          allZeros = false;
          break;
        }
        if (items[i].hz250 !== 0) {
          allZeros = false;
          break;
        }
        if (items[i].hz500 !== 0) {
          allZeros = false;
          break;
        }
        if (items[i].hz1000 !== 0) {
          allZeros = false;
          break;
        }
        if (items[i].hz2000 !== 0) {
          allZeros = false;
          break;
        }
        if (items[i].hz4000 !== 0) {
          allZeros = false;
          break;
        }
        if (items[i].hz8000 !== 0) {
          allZeros = false;
          break;
        }
      }

      for (let i = 0; i < items.length; i++) {
        let seriesItem = {
          name: items[i].type,
          data: [
            items[i].hz16 === 0 && !allZeros ? null : items[i].hz16,
            items[i].hz63 === 0 && !allZeros ? null : items[i].hz63,
            items[i].hz125 === 0 && !allZeros ? null : items[i].hz125,
            items[i].hz250 === 0 && !allZeros ? null : items[i].hz250,
            items[i].hz500 === 0 && !allZeros ? null : items[i].hz500,
            items[i].hz1000 === 0 && !allZeros ? null : items[i].hz1000,
            items[i].hz2000 === 0 && !allZeros ? null : items[i].hz2000,
            items[i].hz4000 === 0 && !allZeros ? null : items[i].hz4000,
            items[i].hz8000 === 0 && !allZeros ? null : items[i].hz8000,
          ],
        };

        dataSeries.push(seriesItem);
      }

      setOptions({
        chart: {
          type: "spline",
        },
        title: {
          text: "Room Criteria Curves",
        },

        subtitle: {
          text: "Room Criteria Raiting for each location",
        },

        yAxis: {
          title: {
            text: "Sound Pressure Level (dB re 20 mPa)",
          },
          tickPositions: [0, 10, 20, 30, 40, 50, 60, 70, 80, 90],
        },

        xAxis: {
          title: {
            text: "Octave Band Center Frequency (Hz)",
          },
          accessibility: {
            rangeDescription: "Range: 63 to 8000 Hz.",
          },
          categories: [
            "16Hz",
            "32Hz",
            "63Hz",
            "125Hz",
            "250Hz",
            "500Hz",
            "1000Hz",
            "2000Hz",
            "4000Hz",
          ],
        },

        plotOptions: {
          series: {
            label: {
              connectorAllowed: false,
            },
          },
        },

        tooltip: {
          headerFormat: "<b>{series.name}</b><br/>",
          pointFormat: "{point.y} dB",
        },

        credits: {
          enabled: false,
        },

        responsive: {
          rules: [
            {
              condition: {
                maxWidth: 500,
              },
              chartOptions: {
                legend: {
                  layout: "horizontal",
                  align: "center",
                  verticalAlign: "bottom",
                },
              },
            },
          ],
        },
        series: dataSeries,
      });
    }
  };

  const addWeigtedTestItem = async (
    row: ITowerWeigtedTestItem,
    index: number
  ) => {
    const weigtedTestItemDTO: any = {
      towerWeigtedTestId: row.towerWeigtedTestId,
    };

    const res = await towerWeigtedTestService.addTowerWeigtedTestItem(
      weigtedTestItemDTO
    );

    const newItem = [...items];
    newItem.push(res.data);
    setItems(newItem);
    plotChart();
  };

  const [soundTestItemSelected, setSoundTestItemSelected] = useState<{
    item: ITowerWeigtedTestItem | null;
    index: number | null;
  }>({ item: null, index: null });

  const [showDeleteSoundTestItemPopup, setShowDeleteSoundTestItemPopup] =
    useState(false);

  const deleteSoundTestItem = (row: ITowerWeigtedTestItem, index: number) => {
    setSoundTestItemSelected({ item: row, index });
    setShowDeleteSoundTestItemPopup(true);
  };

  const deleteWeigtedTestItem = async () => {
    if (soundTestItemSelected?.item === null) return;

    await towerWeigtedTestService.deleteTowerWeigtedTestItem(
      soundTestItemSelected?.item.id
    );

    if (soundTestItemSelected?.index === null) return;

    const newItems = [...items];
    newItems.splice(soundTestItemSelected?.index, 1);
    setItems(newItems);

    setShowDeleteSoundTestItemPopup(false);
  };

  return (
    <>
      <Alert severity="info">
        Sound Level: Maximum sound pressure levels (dB) measured 50 ft from the
        cooling tower operating at full fan speed.
      </Alert>
      <Spreadsheet
        items={items}
        setItems={setItems}
        cols={columns}
        defaultRowPerPage={25}
        showSkeleton={isLoading}
        customHeaders={customHeaders(items[0])}
      />

      <Alert severity="info">
        The locations above are recommended by the Software. You can update,
        delete, or add new locations at any time.
      </Alert>

      {towerValues?.coolingTowerSoundPressureTesting &&
        items.length > 0 &&
        items[0]?.noiseCriteriaCurve !== "Non-Rated" && (
          <HighchartsReact highcharts={Highcharts} options={options} />
        )}

      <DialogMessagePopup
        title={"Delete Confirmation"}
        text="Are you certain you want to delete the selected Sound Test Item?"
        showPopup={showDeleteSoundTestItemPopup}
        setShowPopup={setShowDeleteSoundTestItemPopup}
        onSave={deleteWeigtedTestItem}
        onCancel={() => {
          setShowDeleteSoundTestItemPopup(false);
        }}
        isSubmitting={false}
        disableClickOutside={true}
      />
    </>
  );
};

export default TowerWeigtedTestItemGrid;
