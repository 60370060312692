import axios from "../utils/axios";
import { ITermsAndConditions } from "src/ts/interfaces/catalogs/termsAndConditions";

const postAcceptedTerms = async (
  projectId: number,
  dataPayload: ITermsAndConditions
) => {
  return axios.post(
    `TermsAndConditionsContractor?projectId=${projectId}`,
    dataPayload
  );
};

const termsAndConditionsContractorService = {
  postAcceptedTerms,
};

export default termsAndConditionsContractorService;
