import React from "react";
import styled from "@emotion/styled";

import { Chip as MuiChip } from "@mui/material";
import { spacing, SpacingProps } from "@mui/system";

interface ChipProps extends SpacingProps {
  component?: React.ElementType;
  href?: string;
  icon?: JSX.Element | null;
}

const Chip = styled(MuiChip)<ChipProps>(spacing);

interface GridChipStatusProps {
  value?: number | null;
}

const GridChipStatus = (props: GridChipStatusProps) => {
  const { value } = props;
  return (
    <>
      {value === 1 ? (
        <Chip label="Enabled" color="primary" m={1} size="small" />
      ) : (
        <Chip
          label="Disabled"
          color="primary"
          variant="outlined"
          m={1}
          size="small"
        />
      )}
    </>
  );
};

export default GridChipStatus;
