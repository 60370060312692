import {
  CardContent,
  Grid,
  Divider as MuiDivider,
  Card as MuiCard,
  Stack,
} from "@mui/material";
import { spacing } from "@mui/system";
import styled from "@emotion/styled";
import { useState } from "react";
import { useAuth, useEffectOnce } from "src/hooks";
import { IProjectReportInfectiousComplianceControl } from "src/ts/interfaces";
import { useNavigate, useParams } from "react-router-dom";
import projectReportService from "src/services/study/projectReportService";
import {
  IProjectReport,
  ProjectDTO,
} from "src/ts/interfaces/project/projectDto";
import FormBackButton from "src/components/formControls/FormBackButton";
import { FormCancelButton } from "src/components/formControls";
import getProjectSingleService from "src/services/getProjectSingleService";
import CompleteStudyPopup from "../components/CompleteStudyPopup";
import { IPitotTraverseTest } from "src/ts/interfaces/study/pitot";
import pitotTraverseTestService from "src/services/study/pitotTraverseTestService";
import { STEPS } from "./components/constants";
import HeaderStudyPage from "src/components/page/HeaderStudyPage";
import StudyWizard from "../components/StudyWizard";

const Divider = styled(MuiDivider)(spacing);
const Card = styled(MuiCard)(spacing);

const PitotFanTraverseStep3 = () => {
  const params = useParams<{ id: string; code: string }>();
  const id = parseInt(params?.id === undefined ? "0" : params?.id);
  const navigate = useNavigate();
  const { user } = useAuth();
  const activeStep = 2;

  const [pitot, setPitot] = useState<IPitotTraverseTest | null>();
  const [parentStudy, setParentStudy] = useState<IProjectReport | null>();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [project, setProject] = useState<ProjectDTO | null>();
  const [projectReport, setProjectReport] = useState<IProjectReport | null>();
  const [infectiousComplianceControl, setInfectiousComplianceControl] =
    useState<IProjectReportInfectiousComplianceControl | null>(null);

  const getParentReportIsComplete = async (
    reportId: number,
    reportType: string
  ) => {
    try {
      const res = await projectReportService.report(reportId, reportType);
      setParentStudy(res.data);
    } catch (error) {}
  };

  useEffectOnce(() => {
    const getData = async () => {
      const pitotResponse = await pitotTraverseTestService.getById(id);
      setPitot(pitotResponse.data);
      await getParentReportIsComplete(
        pitotResponse.data.id,
        pitotResponse.data.reportTypeId === 5 ? "PITT" : "PTFR"
      );
      await getProject(pitotResponse.data.projectId);
      await getProjectReport(
        pitotResponse.data.id,
        pitotResponse.data.reportTypeId,
        pitotResponse.data.projectId
      );
      await getInfectiousControlData(pitotResponse.data.id);
    };
    if (id > 0) getData();
  });

  const getInfectiousControlData = async (reportId: number) => {
    try {
      const res = await projectReportService.getInfectiousControl(reportId);
      setInfectiousComplianceControl(res.data);
    } catch (error: any) {
      //log.error(error?.message?.exceptionMessage ?? "Something went wrong");
    }
  };

  const getProjectReport = async (
    reportId: number,
    reportTypeId: number,
    projectId: number
  ) => {
    const projectReportResposne = await projectReportService.projectReport(
      reportId,
      reportTypeId,
      projectId
    );
    setProjectReport(projectReportResposne.data);
  };

  const getProject = async (projectId: number) => {
    const projectResponse = await getProjectSingleService.single(projectId);
    setProject(projectResponse.data);
  };

  const previousStepHandler = async () => {
    navigate(
      `/studies/PitotTraverseStart${
        pitot?.reportTypeId === 5 ? "" : "TT"
      }/Step2/${id}`
    );
  };
  //ProjectStudies/713/58
  const cancelHandler = async () => {
    navigate(`/app/ProjectStudies/${project?.id}/${user?.companyId}`);
  };

  return (
    <>
      <HeaderStudyPage
        headerStudiesPage={{
          code: pitot?.reportTypeId === 5 ? "PITT" : "PTFR",
          system: pitot?.systemField,
          id: id,
        }}
        parentText="Project"
        parentLink="/"
      />
      <Divider my={6} />

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <StudyWizard
            steps={STEPS}
            activeStep={activeStep}
            projectId={pitot?.projectId ?? 0}
            reportId={id}
            reportTypeId={pitot?.reportTypeId ?? 0}
          ></StudyWizard>
        </Grid>
        <Grid item xs={12}>
          <Card mb={1}>
            <CardContent>
              <Grid container spacing={5}>
                <Grid item xs={12}>
                  {project && projectReport && pitot && (
                    <CompleteStudyPopup
                      isDialogOpen={isDialogOpen}
                      setIsDialogOpen={setIsDialogOpen}
                      project={project}
                      projectReport={projectReport}
                      completed={pitot?.isComplete}
                      infectiousComplianceControl={infectiousComplianceControl}
                      parentStudy={parentStudy ?? null}
                    />
                  )}
                </Grid>
                <Grid item xs={12}>
                  <Stack
                    direction="row"
                    spacing={2}
                    justifyContent="center"
                    alignItems="center"
                  >
                    <FormBackButton
                      onClick={previousStepHandler}
                      isSubmitting={false}
                    />
                    <FormCancelButton
                      onClick={cancelHandler}
                      isSubmitting={false}
                    />
                  </Stack>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default PitotFanTraverseStep3;
