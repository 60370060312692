import { Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import {
  FormButton,
  FormCancelButton,
  FormTextArea,
} from "src/components/formControls";
import Popup from "src/components/Popup";
import useForm from "src/hooks/useForm";
import { userService } from "src/services";
import { useLog } from "src/hooks";
import { useNavigate, useParams } from "react-router-dom";
import { UserParams } from "src/ts/types";

interface IEnableDisableUpdatePopUpProps {
  showPopUp: boolean;
  setShowPopUp: (val: boolean) => void;
  name: string;
  enableUser: boolean;
  userID: number;
  valuesOther: any;
  redirect?: boolean;
}
const initialValue = {
  deactivationReason: "",
};

const EnableDisableUpdatePopUp = ({
  showPopUp,
  setShowPopUp,
  name,
  enableUser,
  userID,
  valuesOther,
  redirect,
}: IEnableDisableUpdatePopUpProps) => {
  let navigate = useNavigate();
  let { companyId: companyParameter } = useParams<UserParams>();
  const companyId = parseInt(
    companyParameter === undefined ? "0" : companyParameter
  );
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { values, handleInputChange, resetForm } = useForm(
    initialValue,
    false,
    []
  );
  const { log } = useLog();
  const handleClose = () => {
    resetForm();
    setShowPopUp(false);
  };

  const handleOnClick = () => {
    const disable = async () => {
      try {
        setIsSubmitting(true);
        await userService.update(userID, false, {
          ...valuesOther,
          unibesComments: values.deactivationReason,
        });
        log.success(
          !enableUser ? "The user was enabled." : "The user was disabled."
        );
        if (redirect) {
          navigate(`/app/users/${companyId}`);
        }
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      } finally {
        setShowPopUp(false);
        setIsSubmitting(false);
      }
    };

    values.deactivationReason === "" ? log.error("Type a Reason ") : disable();
  };

  return (
    <Popup
      title="Warning"
      openPopup={showPopUp}
      setOpenPopup={setShowPopUp}
      onClose={handleClose}
    >
      <>
        {!enableUser ? (
          <>
            <Typography>
              You are about to enable user: <b>{name}</b>{" "}
            </Typography>

            <br />
            <Typography mb={5}>
              Please indicate the reason for enabling (this information will
              appear in the user's page for the organization's administrators).
            </Typography>
            <FormTextArea
              name="deactivationReason"
              value={values.deactivationReason}
              placeholder="Enter reason for enabling the user"
              label=""
              onChange={handleInputChange}
            />
            <br />
          </>
        ) : (
          <>
            <Typography>
              You are about to disable user: <b>{name}</b>{" "}
            </Typography>
            <Typography>
              Please indicate the reason for disabling (this information will
              appear in the user's page for the organization's administrators).
            </Typography>
            <br />
            <FormTextArea
              name="deactivationReason"
              value={values.deactivationReason}
              placeholder="Enter reason for disabling the user"
              label=""
              onChange={handleInputChange}
            />
            <br />
          </>
        )}
      </>
      <Stack
        direction="row"
        spacing={2}
        justifyContent="flex-end"
        alignItems="flex-start"
      >
        <FormButton
          text="Accept"
          onClick={handleOnClick}
          size="medium"
          isSubmitting={isSubmitting}
          disabled={!values.deactivationReason}
        />
        <FormCancelButton onClick={handleClose} isSubmitting={isSubmitting} />
      </Stack>
    </Popup>
  );
};

export default EnableDisableUpdatePopUp;
