import { useContext } from "react";
import { ChillerStudyContext } from "../context/ChillerStudyContext";

const useChillerStudy = () => {
  const context = useContext(ChillerStudyContext);

  if (!context)
    throw new Error(
      "ChillerStudyContext must be placed within ChillerStudyProvider"
    );

  return context;
};

export default useChillerStudy;
