import React, { ChangeEvent, useEffect, useState } from "react";
import { TextField, Skeleton } from "@mui/material";
import InputMask from "react-input-mask";
import { Mode } from "src/ts/types";
import FormReadOnlyField from "./FormReadOnlyField";

interface FormMaskTextProps {
  name: string;
  mask: string;
  label: string;
  onChange: (e: any) => void;
  value: string | null | undefined;
  error?: any;
  replaceCharacters?: string[];
  disabled?: boolean;
  showSkeleton?: boolean;
  mode?: Mode;
  onBlur?: (e: any) => void;
}

const FormMaskText = (props: FormMaskTextProps) => {
  const {
    name,
    mask,
    label,
    onChange,
    value,
    error,
    disabled,
    showSkeleton,
    mode,
    onBlur,
  } = props;
  const [textValue, setTextValue] = useState<string | null | undefined>(value);

  const handleOnChangePhone = (event: ChangeEvent<HTMLInputElement>) => {
    setTextValue(event.target.value);

    onChange(convertToDefEventPara(name, event.target.value));
  };

  useEffect(() => {
    setTextValue(value);
  }, [value]);

  const convertToDefEventPara = (name: string, value: string) => {
    return {
      target: {
        name,
        value: value.replaceAll("_", "").replaceAll(" ", ""),
      },
    };
  };

  if (mode && mode === "read") {
    let cleaned = ("" + value).replace(/\D/g, "");
    let match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      let intlCode = match[1] ? "+1 " : "";
      return (
        <FormReadOnlyField
          label={label}
          value={
            [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("") ??
            "-"
          }
        />
      );
    }
    return <FormReadOnlyField label={label} value={value ?? "-"} />;
  }

  return (
    <>
      {showSkeleton ? (
        <Skeleton height={50} />
      ) : (
        <InputMask
          name={name}
          mask={mask}
          value={textValue ?? ""}
          onChange={handleOnChangePhone}
          disabled={disabled}
          onBlur={onBlur}
        >
          {/* 
          // @ts-ignore */}
          <TextField
            id="outlined-basic"
            label={label}
            variant="outlined"
            size="small"
            InputLabelProps={{ shrink: true }}
            fullWidth
            {...(error && { error: true, helperText: error })}
            onBlur={onBlur}
          />
        </InputMask>
      )}
    </>
  );
};

export default FormMaskText;
