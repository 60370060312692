import Popup from "src/components/Popup";
import { FormButton } from "src/components/formControls";
import { Stack, Typography } from "@mui/material";
import { useLoading, useLog } from "src/hooks";
import technicianService from "src/services/technicianService";
import { useNavigate } from "react-router-dom";
import { ITechnician } from "src/ts/interfaces/technician";
import { CERTIFICATION_CODE, CertificationName } from "src/constants";

interface GenerateCertificationPopUpProps {
  generateCerificateShowPopUp: boolean;
  setGenerateCerificateShowPopUp: (val: boolean) => void;
  comments: string;
  technicianId: number;
  companyId: number;
  values: ITechnician | undefined;
  certification: string;
}

export default function GenerateCertificationPopUp({
  generateCerificateShowPopUp,
  setGenerateCerificateShowPopUp,
  comments,
  technicianId,
  companyId,
  values,
  certification,
}: GenerateCertificationPopUpProps) {
  const { log } = useLog();
  const navigate = useNavigate();

  const { startRequest, endRequest, isLoading } = useLoading();
  const handlerAccept = async () => {
    startRequest("Generate Certification");
    try {
      const response = await technicianService.postGenerateCertification(
        technicianId,
        comments
      );
      log.success(
        `Certification has been succefully generated: ${response.data}`
      );
      endRequest("Generate Certification");
      setGenerateCerificateShowPopUp(false);
      navigate(`/app/Technicians/${companyId}`);
    } catch (error: any) {
      log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      endRequest("Generate Certification");
    }
  };

  const certificatioName =
    certification === CERTIFICATION_CODE.TESTINGBALANCE
      ? CertificationName.TestingBalancingTechnician
      : CertificationName.EnergyAuditorInfectiousBuildingControl;
  return (
    <>
      <Popup
        title={"Warning"}
        openPopup={generateCerificateShowPopUp}
        setOpenPopup={setGenerateCerificateShowPopUp}
        onClose={() => {
          setGenerateCerificateShowPopUp(false);
        }}
        size="sm"
        disableClickOutside={true}
        hideClose={isLoading}
        disableEscKeyDown={true}
      >
        <>
          <Typography>
            {` A new ${certificatioName} Certification will be generated
            for ${values?.name} ${values?.lastName}, this will allow the new technician user to
            enter actual values in reports as well as complete AMP projects.`}
          </Typography>
        </>
        <Stack direction="row" spacing={3}>
          <Typography>Do you want to proceed?</Typography>
          <FormButton
            size="small"
            text="Close"
            variant="outlined"
            onClick={() => {
              setGenerateCerificateShowPopUp(false);
            }}
            isSubmitting={isLoading}
          />
          <FormButton
            size="small"
            text="Accept"
            color="primary"
            onClick={() => {
              handlerAccept();
            }}
            isSubmitting={isLoading}
          />
        </Stack>
      </Popup>
    </>
  );
}
