import { IGetNotificationData } from "src/ts/interfaces/documentNotification";
import axios from "../utils/axios";

const getNotifications = (id: number) => {
  return axios.get<IGetNotificationData[]>(`DocumentNotification`);
};

const documentNotificationService = {
  getNotifications,
};
export default documentNotificationService;
