import React, { useState } from "react";

const useFormUnion = (
  initialFValues: any,
  validateOnChange = false,
  validate: any,
  setShowPopUp: (val: boolean) => void
) => {
  const [valuesUnion, setValuesUnion] = useState(initialFValues);
  const [errorsUnion, setErrorsUnion] = useState<Record<string, string>>({});

  const handleInputChangeUnion = (e: any) => {
    const { name, value } = e.target;

    if (name === "unionAffiliatedAnswer") {
      setValuesUnion({
        ...valuesUnion,
        [name]: value === "true" ? false : true,
      });
    }

    const changeRadio = value === "true" ? false : true;
    if (name === "unionAffiliatedAnswer" && changeRadio === false) {
      resetFormUnion();
    }

    if (validateOnChange && validate.length > 0) validate({ [name]: value });
  };

  const resetFormUnion = () => {
    setValuesUnion(initialFValues);
    setErrorsUnion({});
  };

  return {
    valuesUnion,
    setValuesUnion,
    errorsUnion,
    setErrorsUnion,
    handleInputChangeUnion,
    resetFormUnion,
  };
};

export default useFormUnion;

export function FormUnion(props: any) {
  const { children, ...other } = props;

  return (
    <form autoComplete="off" {...other}>
      {props.children}
    </form>
  );
}
