/* eslint-disable react-hooks/exhaustive-deps */
import {
  CardContent,
  Grid,
  Divider as MuiDivider,
  Card as MuiCard,
  Alert,
  Stack,
  Button,
} from "@mui/material";

import { spacing } from "@mui/system";
import styled from "@emotion/styled";
import HeaderStudyPage from "src/components/page/HeaderStudyPage";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAsyncQuery, useLog, useAuth } from "src/hooks";
import {
  FormCheckBox,
  FormNumeric2,
  FormSelect,
  FormText,
} from "src/components/formControls";
import useFormTyped from "src/hooks/useFormTyped";
import {
  IDefaultComboBoxCatalog,
  IGpmDTO,
  IPumpReportDTO,
  IReportType,
} from "src/ts/interfaces";
import {
  IProjectReport,
  ProjectDTO,
} from "src/ts/interfaces/project/projectDto";
import projectReportService from "src/services/study/projectReportService";
import { steps } from "../const/consts";
import { FooterButtons } from "../../components/FooterButtons";
import Popup from "src/components/Popup";
import { Validator } from "src/ts/types/Validator";
import reportTypeService from "src/services/study/reportTypeService";
import pumpReportService from "src/services/study/pumpReportService";
import gpmService from "src/services/study/gpmService";
import { IPumpSvcDTO } from "src/ts/interfaces/catalogs";
import pumpSvcService from "src/services/catalogs/pumpSvcService";
import projectService from "src/services/project/projectService";
import { useSystemOfMeasurement } from "src/hooks";
import StudyWizard from "../../components/StudyWizard";

const initialValues: any = {
  projectName: "",
  system: "",
  equipmentLocation: "",
  areaServed: "",
};

const Divider = styled(MuiDivider)(spacing);
const Card = styled(MuiCard)(spacing);

const PumpStep2 = () => {
  const { log } = useLog();
  const { user } = useAuth();
  const { systemOfMeasurement } = useSystemOfMeasurement(true);
  const params = useParams<{ id: string; step: string }>();
  const id = parseInt(params?.id === undefined ? "0" : params?.id);
  const [isUpdating, setIsUpdating] = useState(false);
  const [title, setTitle] = useState<string>();
  const [code, setCode] = useState<string>();
  const [oldPumps, setOldPumps] = useState(0);
  const [pumpsDecreasedOpen, setPunmpsDecreasedOpen] = useState(false);
  const [pumpSvc, setPumpSvc] = useState<IDefaultComboBoxCatalog[]>();
  const [gpmTolerance, setGpmTolerance] = useState<number>();
  const [designDiversityAlertShow, setDesignDiversityAlertShow] =
    useState(false);
  const [actualDiversityAlertShow, setActualDiversityAlertShow] =
    useState(false);
  const [moveNextStep, setMoveNextStep] = useState(false);
  const [movePrevStep, setMovePrevStep] = useState(false);
  const [isEnergyProcess, setIsEnergyProcess] = useState(false);

  let navigate = useNavigate();

  const validate: any = (fieldValues: IPumpReportDTO = values) => {
    let temp: Record<string, string> = { ...errors };
    let valid = true;

    if (disableStudy()) return true;

    temp.serviceId = new Validator(fieldValues, "serviceId")
      .isRequired("Pump Manufacturer is required.")
      .greatThan(0, "Pump Manufacturer is required.")
      .toString();
    temp.otherService = new Validator(fieldValues, "otherService")
      .validateIf(
        catalogValueOtherEval(
          fieldValues.serviceId as number,
          pumpSvc as IDefaultComboBoxCatalog[]
        )
      )
      .isRequired("Other Pump manufacturer is required.")
      .toString();
    temp.coilsGpmDesign = new Validator(fieldValues, "coilsGpmDesign")
      .validateIf(!fieldValues.isNotAvailableCoilsGpmDesign)
      .isRequired("This field is required.")
      .toString();
    temp.systemGpmDesign = new Validator(fieldValues, "systemGpmDesign")
      .validateIf(!fieldValues.isNotAvailableSystemGpmDesign)
      .isRequired("This field is required.")
      .toString();
    temp.numberOfPumps = new Validator(fieldValues, "numberOfPumps")
      .isRequired("This field is required.")
      .greatThan(0, "This field must be greater than 0")
      .toString();

    let numOfMotors =
      typeof fieldValues.numberOfPumps === "string"
        ? parseInt(fieldValues.numberOfPumps)
        : fieldValues.numberOfPumps;
    let oldNumOfMotors = oldPumps;

    const maxNumberOfMotors = user?.role === "Training" ? 4 : 55;
    let errorMotorMessage =
      user?.role === "Training"
        ? `Only ${maxNumberOfMotors} pumps are allowed on training mode`
        : `Total number of pumps must be less than ${maxNumberOfMotors}`;

    temp.numberOfPumps = new Validator(fieldValues, "numberOfPumps")
      .isRequired("The number of pumps is required.")
      .greatThan(0, "Must be great than 0")
      .lessThan(maxNumberOfMotors, errorMotorMessage)
      .toString();

    if (oldNumOfMotors > numOfMotors) {
      setPunmpsDecreasedOpen(true);
      valid = false;
    }

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "" && valid);
  };

  const { values, setValues, errors, setErrors, handleInputChange } =
    useFormTyped<IPumpReportDTO>(initialValues, false, validate);

  const activeStep = 1;

  const { execute: executeReport, data: dataReport } =
    useAsyncQuery<IProjectReport>(projectReportService.report);

  const { execute, isLoading } = useAsyncQuery<IPumpReportDTO>(
    pumpReportService.getBaseById,
    {
      onSuccess: (dataResult) => {
        const getData = async () => {
          setValues({ ...dataResult });
          setOldPumps(dataResult.numberOfPumps);
          await systemOfMeasurement.getByProject(
            dataResult.projectId ?? values.projectId
          );
          window.scrollTo(0, 0);
        };
        getData();
      },
    }
  );

  const { execute: executeGpm } = useAsyncQuery<IGpmDTO>(gpmService.getGpm, {
    onSuccess: (dataResult) => {
      const getData = async () => {
        if (
          !values?.manualEntryActualCoilsGpm ||
          !values?.manualEntryActualSystemGpm
        ) {
          setValues({
            ...values,
            actualCoilsGpm: !values?.manualEntryActualCoilsGpm
              ? dataResult.actualCoilsGpm
              : values?.actualCoilsGpm,
            actualSystemGpm: !values?.manualEntryActualSystemGpm
              ? dataResult.actualSystemGpm
              : values?.actualSystemGpm,
          });
        }
      };
      getData();
    },
  });

  const { execute: executeReportType, isLoading: isLoadingReportType } =
    useAsyncQuery<IReportType>(reportTypeService.getReportTypeById, {
      onSuccess: (dataResult) => {
        const getData = async () => {
          setCode(dataResult.code);
          setTitle(dataResult.name);
        };
        getData();
      },
    });

  const { execute: executePumpSvc, isLoading: isLoadingPumpSvc } =
    useAsyncQuery<IPumpSvcDTO[]>(pumpSvcService.getPumpSvc, {
      onSuccess: (dataResult) => {
        const getData = async () => {
          const mfgs = (dataResult as IPumpSvcDTO[]).map((mfg) => ({
            key: mfg.id as number,
            value: mfg.name as any,
          }));
          setPumpSvc([{ key: 0, value: "Select a type of service" }, ...mfgs]);
        };
        getData();
      },
    });

  const { execute: executeTolerances } = useAsyncQuery<ProjectDTO>(
    projectService.getProjectTolerancesByReportId,
    {
      onSuccess: (dataResult) => {
        const getData = async () => {
          setGpmTolerance(dataResult.requiredGPMSystemToleranceTop);
        };
        getData();
      },
    }
  );

  const disableStudy = () => {
    return (
      values?.isComplete ||
      (dataReport?.isInUse && dataReport?.isInUseById !== user?.userId) ||
      dataReport?.isPartOfProject === false
    );
  };

  useEffect(() => {
    if (values?.reportTypeId) executeReportType(values?.reportTypeId);
  }, [values?.reportTypeId]);

  useEffect(() => {
    const getData = async () => {
      try {
        await executePumpSvc();
        await execute(id);
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      }
    };

    if (id !== 0) getData();
  }, [id]);

  useEffect(() => {
    const getData = async () => {
      try {
        await executeReport(id, code);
        await executeTolerances(id, code);
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      }
    };

    if (id !== 0 && code !== undefined) getData();
  }, [id, code]);

  useEffect(() => {
    if (!disableStudy() && id && code && dataReport?.id) {
      if (values?.isGpmKnown && !dataReport?.parentReportId)
        executeGpm(id, code);
    }
  }, [dataReport?.id, id, code]);

  useEffect(() => {
    if (gpmTolerance) {
      const systemGpmDesign =
        typeof values?.systemGpmDesign === "string"
          ? parseFloat(values?.systemGpmDesign)
          : values?.systemGpmDesign || 0;
      const coilsGpmDesign =
        typeof values?.coilsGpmDesign === "string"
          ? parseFloat(values?.coilsGpmDesign)
          : values?.coilsGpmDesign || 0;
      const coilsDiversityDesign =
        systemGpmDesign > 0
          ? Math.abs(1 - coilsGpmDesign / systemGpmDesign) * 100
          : 0;
      setValues({ ...values, coilsDiversityDesign });
      setDesignDiversityAlertShow(
        coilsDiversityDesign > gpmTolerance &&
          !values?.isNotAvailableCoilsGpmDesign &&
          !values?.isNotAvailableSystemGpmDesign &&
          !disableStudy()
      );
    }
  }, [
    gpmTolerance,
    values?.systemGpmDesign,
    values?.coilsGpmDesign,
    values?.isNotAvailableCoilsGpmDesign,
    values?.isNotAvailableSystemGpmDesign,
  ]);

  useEffect(() => {
    if (gpmTolerance) {
      const actualSystemGpm =
        typeof values?.actualSystemGpm === "string"
          ? parseFloat(values?.actualSystemGpm)
          : values?.actualSystemGpm || 0;
      const actualCoilsGpm =
        typeof values?.actualCoilsGpm === "string"
          ? parseFloat(values?.actualCoilsGpm)
          : values?.actualCoilsGpm || 0;
      const coilsDiversityActual =
        actualSystemGpm > 0
          ? Math.abs(1 - actualCoilsGpm / actualSystemGpm) * 100
          : 0;
      setValues({ ...values, coilsDiversityActual });
      setActualDiversityAlertShow(
        coilsDiversityActual > gpmTolerance &&
          values?.isGpmKnown &&
          !disableStudy()
      );
    }
  }, [
    gpmTolerance,
    values?.actualSystemGpm,
    values?.actualCoilsGpm,
    values?.isGpmKnown,
  ]);

  useEffect(() => {
    if (dataReport?.id)
      setIsEnergyProcess(dataReport?.parentReportTypeId === 18);
  }, [dataReport?.id]);

  const saveHandler = async () => {
    setMoveNextStep(false);
    setMovePrevStep(false);
    if (!validate()) return;
    try {
      await savePumpDataHandler();
    } catch (error) {}
  };

  const savePumpDataHandler = async () => {
    try {
      if (!disableStudy()) {
        setIsUpdating(true);
        await pumpReportService.update(id, values);
        setOldPumps(
          typeof values.numberOfPumps === "string"
            ? parseInt(values.numberOfPumps)
            : values.numberOfPumps
        );
        log.success("Report was updated successfully");
        setIsUpdating(false);
      }
    } catch (error) {}
  };

  const nextStepHandler = async () => {
    setMoveNextStep(true);
    if (!validate()) return;
    try {
      await savePumpDataHandler();
      navigate(`/studies/pumpReport/step3/${id}`);
    } catch (error) {}
  };

  const previousStepHandler = async () => {
    setMovePrevStep(true);
    if (!validate()) return;
    try {
      await savePumpDataHandler();
      navigate(`/studies/pumpReport/step1/${id}`);
    } catch (error) {}
  };

  const handleDecreasePumpsYesButtonClick = async () => {
    setPunmpsDecreasedOpen(false);
    await savePumpDataHandler();
    if (moveNextStep) navigate(`/studies/pumpReport/step3/${id}`);
    if (movePrevStep) navigate(`/studies/pumpReport/step1/${id}`);
  };

  const allGpmNA = () =>
    values?.isNotAvailableCoilsGpmDesign &&
    values?.isNotAvailableSystemGpmDesign;

  const allGpmNAHandleChange = (e: any) => {
    const value = e.target.value || false;
    setValues({
      ...values,
      isNotAvailableCoilsGpmDesign: value,
      isNotAvailableSystemGpmDesign: value,
    });
  };

  const catalogValueOtherEval = (
    id: number,
    catalog: IDefaultComboBoxCatalog[]
  ) => {
    if (catalog && id) {
      const [{ value }] = catalog.filter((x) => x.key === id);
      return value === "Other";
    }
    return false;
  };

  const handleIsGpmKnownChnage = async (e: any) => {
    if (e.target.value) {
      const dataResult = (await gpmService.getGpm(id, code || "")).data;
      setValues({
        ...values,
        [e.target.name]: e.target.value,
        actualCoilsGpm: !values?.manualEntryActualCoilsGpm
          ? dataResult.actualCoilsGpm
          : values?.actualCoilsGpm,
        actualSystemGpm: !values?.manualEntryActualSystemGpm
          ? dataResult.actualSystemGpm
          : values?.actualSystemGpm,
      });
      return;
    }
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    });
  };

  const handleManualEntryGPMKnownChnage = async (e: any) => {
    if (!e.target.value) {
      const dataResult = (await gpmService.getGpm(id, code || "")).data;
      setValues({
        ...values,
        [e.target.name]: e.target.value,
        actualCoilsGpm: !values?.manualEntryActualCoilsGpm
          ? dataResult.actualCoilsGpm
          : values?.actualCoilsGpm,
        actualSystemGpm: !values?.manualEntryActualSystemGpm
          ? dataResult.actualSystemGpm
          : values?.actualSystemGpm,
      });
      return;
    }
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <>
      {/**Warnings section*/}
      <Popup
        title="Warning"
        openPopup={pumpsDecreasedOpen}
        setOpenPopup={setPunmpsDecreasedOpen}
        onClose={() => setPunmpsDecreasedOpen(false)}
      >
        <>
          <Alert severity="warning">
            You are about to decrease the number of Pumps and you will lose data
            permanently. Are you certain you want to continue?
          </Alert>
        </>
        <Stack direction="row" spacing={3}>
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => setPunmpsDecreasedOpen(false)}
            size="small"
          >
            No
          </Button>
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={handleDecreasePumpsYesButtonClick}
            autoFocus
          >
            Yes
          </Button>
        </Stack>
      </Popup>
      {/**Warnings section end */}
      <HeaderStudyPage
        headerStudiesPage={{ code: code, system: values?.system, id: id }}
        parentText="Project"
        parentLink={`/app/ProjectStudies/${values?.projectId}/${user?.companyId}`}
      />
      <Divider my={6} />

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card mb={6}>
            <CardContent>
              <StudyWizard
                steps={steps}
                activeStep={activeStep}
                projectId={dataReport?.projectId}
                reportId={dataReport?.reportId}
                reportTypeId={dataReport?.reportTypeId}
              />
              <Grid container spacing={3} marginLeft={5}>
                <Grid item xs={11}>
                  <h3>System Design</h3>
                </Grid>
                <Grid item xs={11}>
                  <FormCheckBox
                    label={`Actual ${systemOfMeasurement.get(
                      "gpm"
                    )}'s are known at this point`}
                    name="isGpmKnown"
                    value={values.isGpmKnown}
                    onChange={handleIsGpmKnownChnage}
                    showSkeleton={isLoading || isLoadingReportType}
                    disabled={disableStudy() || isEnergyProcess}
                  />
                </Grid>
                {values.isGpmKnown && (
                  <>
                    <Grid item xs={11} md={2}>
                      <FormCheckBox
                        label="Manual entry"
                        name="manualEntryActualCoilsGpm"
                        value={values.manualEntryActualCoilsGpm}
                        onChange={handleManualEntryGPMKnownChnage}
                        showSkeleton={isLoading || isLoadingReportType}
                        disabled={disableStudy() || isEnergyProcess}
                      />
                    </Grid>
                    <Grid item xs={11} md={9}>
                      <FormNumeric2
                        disabled={
                          !values.manualEntryActualCoilsGpm ||
                          disableStudy() ||
                          isEnergyProcess
                        }
                        label={`Actual Coil(s) ${systemOfMeasurement.get(
                          "gpm"
                        )}`}
                        name="actualCoilsGpm"
                        onBlur={handleInputChange}
                        value={values.actualCoilsGpm}
                        thousandSeparator
                        decimalScale={3}
                        fixedDecimalScale
                        showSkeleton={isLoading || isLoadingReportType}
                      />
                    </Grid>
                    <Grid item xs={11} md={2}>
                      <FormCheckBox
                        label="Manual entry"
                        name="manualEntryActualSystemGpm"
                        value={values.manualEntryActualSystemGpm}
                        onChange={handleManualEntryGPMKnownChnage}
                        showSkeleton={isLoading || isLoadingReportType}
                        disabled={disableStudy() || isEnergyProcess}
                      />
                    </Grid>
                    <Grid item xs={11} md={9}>
                      <FormNumeric2
                        disabled={
                          !values.manualEntryActualSystemGpm ||
                          disableStudy() ||
                          isEnergyProcess
                        }
                        label={`Actual System ${systemOfMeasurement.get(
                          "gpm"
                        )}`}
                        name="actualSystemGpm"
                        onBlur={handleInputChange}
                        value={values.actualSystemGpm}
                        thousandSeparator
                        decimalScale={3}
                        fixedDecimalScale
                        showSkeleton={isLoading || isLoadingReportType}
                      />
                    </Grid>
                    {isEnergyProcess && (
                      <Grid item xs={11}>
                        <Alert severity="info">
                          This section is disabled during an Energy Process
                        </Alert>
                      </Grid>
                    )}
                    {actualDiversityAlertShow && (
                      <>
                        <Grid item xs={2}></Grid>
                        <Grid item xs={9}>
                          <Alert severity="warning">
                            The Coil(s) Actual {systemOfMeasurement.get("gpm")}{" "}
                            exceeds the System Actual{" "}
                            {systemOfMeasurement.get("gpm")} Diversity
                            Tolerance.
                          </Alert>
                        </Grid>
                      </>
                    )}
                  </>
                )}
                <Grid item xs={11}>
                  <Divider my={2} />
                </Grid>
                <Grid item xs={11}>
                  <FormCheckBox
                    label={`All ${systemOfMeasurement.get(
                      "gpm"
                    )} Not Available`}
                    name="allGPMNotAvailbale"
                    value={allGpmNA()}
                    onChange={allGpmNAHandleChange}
                    showSkeleton={isLoading || isLoadingReportType}
                    disabled={disableStudy()}
                  />
                </Grid>
                <Grid item xs={11} md={2}>
                  <FormCheckBox
                    label="Not Available"
                    name="isNotAvailableCoilsGpmDesign"
                    value={values.isNotAvailableCoilsGpmDesign}
                    onChange={handleInputChange}
                    showSkeleton={isLoading || isLoadingReportType}
                    disabled={disableStudy()}
                  />
                </Grid>
                <Grid item xs={11} md={9}>
                  <FormNumeric2
                    disabled={
                      values.isNotAvailableCoilsGpmDesign || disableStudy()
                    }
                    label={`Total Coil(s) Design ${systemOfMeasurement.get(
                      "gpm"
                    )}`}
                    name="coilsGpmDesign"
                    onBlur={handleInputChange}
                    value={values.coilsGpmDesign}
                    thousandSeparator
                    decimalScale={3}
                    fixedDecimalScale
                    showSkeleton={isLoading || isLoadingReportType}
                    error={errors.coilsGpmDesign}
                  />
                </Grid>
                <Grid item xs={11} md={2}>
                  <FormCheckBox
                    label="Not Available"
                    name="isNotAvailableSystemGpmDesign"
                    value={values.isNotAvailableSystemGpmDesign}
                    onChange={handleInputChange}
                    showSkeleton={isLoading || isLoadingReportType}
                    disabled={disableStudy()}
                  />
                </Grid>
                <Grid item xs={11} md={9}>
                  <FormNumeric2
                    disabled={
                      values.isNotAvailableSystemGpmDesign || disableStudy()
                    }
                    label={`Total System Design ${systemOfMeasurement.get(
                      "gpm"
                    )}`}
                    name="systemGpmDesign"
                    onBlur={handleInputChange}
                    value={values.systemGpmDesign}
                    thousandSeparator
                    decimalScale={3}
                    fixedDecimalScale
                    showSkeleton={isLoading || isLoadingReportType}
                    error={errors.systemGpmDesign}
                  />
                </Grid>
                <Grid item xs={2}></Grid>
                <Grid item xs={9}>
                  <FormSelect
                    name="serviceId"
                    label="Select Type Of System"
                    error={errors.serviceId}
                    onChange={handleInputChange}
                    options={pumpSvc}
                    value={values?.serviceId || 0}
                    showSkeleton={isLoading || isLoadingPumpSvc}
                    disabled={disableStudy()}
                  />
                </Grid>
                {catalogValueOtherEval(
                  values?.serviceId || 0,
                  pumpSvc as IDefaultComboBoxCatalog[]
                ) && (
                  <>
                    <Grid item xs={2}></Grid>
                    <Grid item xs={9}>
                      <FormText
                        name="otherService"
                        label="Other type of system"
                        onChange={handleInputChange}
                        placeholder="Type the type of system here"
                        value={values?.otherService || ""}
                        showSkeleton={isLoading}
                        error={errors.otherService}
                        disabled={disableStudy()}
                      />
                    </Grid>
                  </>
                )}
                <Grid item xs={2}></Grid>
                <Grid item xs={9}>
                  <FormNumeric2
                    label="Number of pumps"
                    name="numberOfPumps"
                    onBlur={handleInputChange}
                    value={values.numberOfPumps}
                    thousandSeparator
                    decimalScale={0}
                    maxValue={1000}
                    error={errors.numberOfPumps}
                    showSkeleton={isLoading || isLoadingReportType}
                    disabled={disableStudy()}
                  />
                </Grid>
                {designDiversityAlertShow && (
                  <>
                    <Grid item xs={2}></Grid>
                    <Grid item xs={9}>
                      <Alert severity="warning">
                        The Coil(s) Design {systemOfMeasurement.get("gpm")}{" "}
                        exceeds the System Design{" "}
                        {systemOfMeasurement.get("gpm")} Diversity Tolerance.
                      </Alert>
                    </Grid>
                  </>
                )}
              </Grid>
              <FooterButtons
                activeStep={activeStep}
                stepsCount={steps.length}
                projectId={values?.projectId as number}
                companyId={user?.companyId as number}
                isSaving={isUpdating}
                disabled={
                  isUpdating ||
                  isLoading ||
                  isLoadingReportType ||
                  disableStudy()
                }
                reportName={title as string}
                saveHandler={saveHandler}
                nextStepHandler={nextStepHandler}
                previousStepHandler={previousStepHandler}
                isComplete={values?.isComplete ?? true}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default PumpStep2;
