import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styled from "@emotion/styled";
import { NavLink, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import useLog from "src/hooks/useLog";
import {
  CardContent,
  Grid,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  Typography,
  Stack,
  Stepper,
  Step,
  StepLabel,
  Button,
} from "@mui/material";
import { spacing } from "@mui/system";
import { useForm, Form } from "src/hooks";
import {
  FormAcceptButton,
  FormCancelButton,
  FormSelect,
  FormText,
} from "src/components/formControls";
import useLoading from "src/hooks/useLoading";
import { Validator } from "src/ts/types/Validator";
import { CatalogService } from "src/services";
import { IKeyValue } from "src/ts/interfaces";
import {
  IEnergyCostSheduledAndBilling,
  IGasTotalCost,
} from "src/ts/interfaces/energyCostSheduledAndBilling";
import FormBackButton from "src/components/formControls/FormBackButton";
import { GridActionButton } from "src/components/gridControls";
import { ColumnType } from "src/types/enhancedTable";
import LocalEnhancedTable from "src/components/localTable/LocalTable";
import Popup from "src/components/Popup";
import energyCostSheduledAndBillingService from "src/services/energyCostSheduledAndBillingService";
import { IGasFuelTypeEnergyCost } from "src/ts/interfaces/gasFuelTypeEnergyCost";
import buildingService from "src/services/buildingService";
import FormNumeric from "src/components/formControls/FormNumeric";
import MatchWordConfirmModal from "src/components/modals/MatchWordConfirmModal";

const Card = styled(MuiCard)(spacing);
const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const initialValues: IEnergyCostSheduledAndBilling = {
  id: 0,
  electricityId: -1,
  electricityUnitsId: 0,
  totalCost: 0,
  actualUtilityReportedPowerFactor: 0,
  powerFactorPenalties: 0,
  allOtherPenaltiesCombined: 0,
  energyCostProcessId: 0,
  month: "",
  year: "",
  gasTotalCost: [],
  generalGasTotalCost: 0,
};

const initialValuesFuelType: IGasFuelTypeEnergyCost = {
  id: 0,
  energyCostProcessId: 0,
  fuelTypeId: "0",
  gasUnitsId: "0",
};

const initialValuesGas: IGasTotalCost = {
  id: 0,
  EnergyCostSheduledAndBillingId: 0,
  gasFuelTypeEnergyCostId: 0,
  totalCost: "0",
  gasFuelTypeEnergyCost: initialValuesFuelType,
};

const columns = (
  canEdit: boolean,
  setShowDeleteModal: (val: boolean) => void,
  setCurrentRowSelected: (val: IGasTotalCost) => void
): ColumnType[] => [
  {
    id: "fuelType.name",
    label: "Fuel Type",
    type: "custom",
    sort: false,
    callback: (row: IGasTotalCost) => {
      return <>{row?.gasFuelTypeEnergyCost?.fuelType?.name}</>;
    },
  },
  {
    id: "gasUnits.name",
    label: "Unit",
    type: "custom",
    sort: false,
    totalLabel: "Total",
    callback: (row: IGasTotalCost) => {
      return <>{row?.gasFuelTypeEnergyCost?.gasUnits?.name}</>;
    },
  },
  {
    id: "totalCost",
    label: "Total Cost $ (USD)",
    type: "currency",
    sort: true,
    total: "totalCost",
  },
  {
    id: "actions",
    label: "Actions",
    type: "custom",
    sort: false,
    callback: (row: IGasTotalCost) => {
      return (
        <GridActionButton
          type="delete"
          onClick={() => {
            setCurrentRowSelected(row);
            setShowDeleteModal(true);
          }}
          tooltip="Delete"
          disabled={!canEdit}
        />
      );
    },
  },
];

const BuildingLimitedData = () => {
  let navigate = useNavigate();
  const { log } = useLog();
  let { energyCostProcessId } = useParams<{ energyCostProcessId: string }>();
  let { id } = useParams<{ id: string }>();
  let { type } = useParams<{ type: string }>();
  const [canEdit, setCanEdit] = useState(false);

  const validate = (fieldValues = values) => {
    let temp: Record<string, string> = { ...errors };

    temp.administratorType = new Validator(fieldValues, "administratorType")
      .selectedOption(0, "The role is required.")
      .toString();

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const validateModal = (fieldValues = valuesModal) => {
    let temp: Record<string, string> = { ...errorsModal };

    temp.totalCost =
      Number(fieldValues.totalCost) === 0 ? "Total Cost is required" : "";

    temp.gasUnitsId =
      Number(fieldValues.gasFuelTypeEnergyCost.gasUnitsId) === 0
        ? "This field is required."
        : "";

    temp.fuelTypeId =
      Number(fieldValues.gasFuelTypeEnergyCost.fuelTypeId) === 0
        ? "This field is required."
        : "";

    setErrorsModal({
      ...temp,
    });

    if (fieldValues === valuesModal)
      return Object.values(temp).every((x) => x === "");
  };

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [refreshTable, setRefreshTable] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [gasTotalCost, setGasTotalCost] = useState<IGasTotalCost[]>([]);
  const [currentRowSelected, setCurrentRowSelected] = useState<IGasTotalCost>();
  const [gasTotalCostTotal, setGasTotalCostTotal] =
    useState<IGasTotalCost>(initialValuesGas);
  const [electricityUnitsKeyValue, setElectricityUnitsKeyValue] = useState<
    IKeyValue<number, string>[]
  >([]);
  const [unitsKeyValue, setUnitsKeyValue] = useState<
    IKeyValue<number, string>[]
  >([]);
  const [fuelTypeKeyValue, setFuelTypeKeyValue] = useState<
    IKeyValue<number, string>[]
  >([]);
  const { values, setValues, errors, setErrors, handleInputChange } = useForm(
    initialValues,
    false,
    validate
  );
  const {
    values: valuesModal,
    setValues: setValuesModal,
    errors: errorsModal,
    setErrors: setErrorsModal,
  } = useForm(initialValuesGas, false, validateModal);
  const { isLoading, startRequest, endRequest } = useLoading();

  useEffect(() => {
    const getUser = async () => {
      startRequest("user");
      const response = await energyCostSheduledAndBillingService.getById(
        Number(energyCostProcessId)
      );
      setValues(response.data);
      endRequest("user");
    };

    if (Number(energyCostProcessId) > 0) {
      getUser();
    }

    const getFuelType = async () => {
      const response = await CatalogService.getAllDropdown("FuelType");
      setFuelTypeKeyValue(response);
    };

    getFuelType();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [energyCostProcessId]);

  useEffect(() => {
    const getBuilding = async (buildingId: number) => {
      const response = await buildingService.getById(buildingId);
      setCanEdit(response.data.canEdit);
    };

    if (Number(id) > 0) {
      getBuilding(Number(id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (gasTotalCostTotal !== null) {
      if (gasTotalCost.length > 0) {
        gasTotalCostTotal.totalCost = gasTotalCost
          .map((datum) => Number(datum.totalCost))
          .reduce((a, b) => Number(a) + Number(b))
          .toString();
      } else {
        gasTotalCostTotal.totalCost = "0.00";
      }
      gasTotalCostTotal.totalCost = Number(gasTotalCostTotal.totalCost)
        .toFixed(2)
        .toString();

      setGasTotalCostTotal({ ...gasTotalCostTotal });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gasTotalCost]);

  useEffect(() => {
    const getUnit = async () => {
      const response = await CatalogService.getAllDropdown(
        "Units/" + valuesModal.gasFuelTypeEnergyCost.fuelTypeId
      );
      setElectricityUnitsKeyValue(response);
    };
    if (valuesModal.gasFuelTypeEnergyCost.fuelTypeId !== "0") getUnit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valuesModal.gasFuelTypeEnergyCost.fuelTypeId]);

  useEffect(() => {
    const getUnit = async () => {
      const response = await CatalogService.getAllDropdown("Units/1");
      setUnitsKeyValue(response);
    };
    getUnit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    const getGas = async () => {
      startRequest("user");
      const response = await energyCostSheduledAndBillingService.getGasCost(
        Number(values.id)
      );
      setGasTotalCost(response.data);
      endRequest("user");
    };
    if (values.id !== 0) getGas();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.id, refreshTable]);

  const handleCancel = () => {
    navigate("/app/BuildingEnergyCost/" + id);
  };

  const handleBack = async () => {
    if (canEdit) await handleSave();
    navigate("/app/BuildingEnergyCost/" + id);
  };

  const handleSave = async () => {
    if (!validate()) return;
    try {
      setIsSubmitting(true);
      await energyCostSheduledAndBillingService.update(values.id, values);
      log.success("Data was updated");
    } catch (error: any) {
      log.error(error?.message?.exceptionMessage ?? "Something went wrong");
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleAddGasCost = () => {
    initialValuesGas.EnergyCostSheduledAndBillingId = values.id;
    setValuesModal(initialValuesGas);
    setIsDialogOpen(true);
  };

  const closeHandler = () => {
    setIsDialogOpen(false);
  };

  const confirmHandler = async () => {
    if (!validateModal()) return;
    try {
      setIsSubmitting(true);
      await energyCostSheduledAndBillingService.createGasCost(
        values.energyCostProcessId,
        valuesModal
      );
      log.success("Item was added");
      setRefreshTable(!refreshTable);
    } catch (error: any) {
      log.error(error?.message?.exceptionMessage ?? "Something went wrong");
    } finally {
      setIsSubmitting(false);
    }
    setIsDialogOpen(false);
  };

  const handleDelete = async () => {
    await energyCostSheduledAndBillingService.deleteGasCost(
      currentRowSelected?.id ?? 0
    );
    log.success("Item was deleted");
    setRefreshTable(!refreshTable);
  };

  const steps = ["Energy configuration", "Limited Data"];

  const row = 6;

  return (
    <>
      <Helmet title="Building" />
      <Typography variant="h3" gutterBottom display="inline">
        Building
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/">
          Dashboard
        </Link>
        <Link component={NavLink} to="/app/Buildings">
          Buildings
        </Link>
        <Typography>Building</Typography>
      </Breadcrumbs>

      <Divider my={6} />
      <Card mb={6}>
        <CardContent>
          <Form>
            <Grid item xs={12} md={12}>
              <Stepper alternativeLabel activeStep={1}>
                {steps.map((label) => (
                  <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
            </Grid>
            <Divider my={3}></Divider>
            <Typography variant="h2">{type}</Typography>
            <Divider my={6} />
            <Typography variant="h5">Electricity Type</Typography>
            <Divider my={3}></Divider>
            <Grid container spacing={6}>
              <Grid item xs={row}>
                <FormSelect
                  name="electricityUnitsId"
                  label="Electricity Unit"
                  value={values.electricityUnitsId}
                  onChange={handleInputChange}
                  options={unitsKeyValue}
                  error={errors.buildingTypeId}
                  disable={isSubmitting || !canEdit}
                  showSkeleton={isLoading}
                  defaultValue={{ key: 0, value: "Select a value" }}
                />
              </Grid>
              <Grid item xs={row}>
                <FormNumeric
                  name={"totalCost"}
                  label={"Total Cost"}
                  value={values.totalCost}
                  onChange={handleInputChange}
                  error={errors.totalCost}
                  disabled={isSubmitting || !canEdit}
                  prefix="$"
                ></FormNumeric>
              </Grid>
              <Grid item xs={row}>
                <FormText
                  name="actualUtilityReportedPowerFactor"
                  label="Actual Utility Reported Power Factor"
                  value={values.actualUtilityReportedPowerFactor}
                  onChange={handleInputChange}
                  error={errors.actualUtilityReportedPowerFactor}
                  disabled={isSubmitting || !canEdit}
                  showSkeleton={isLoading}
                  type="number"
                />
              </Grid>
              <Grid item xs={row}>
                <FormText
                  name="powerFactorPenalties"
                  label="Power Factor Penalties $ (USD)"
                  value={values.powerFactorPenalties}
                  onChange={handleInputChange}
                  error={errors.powerFactorPenalties}
                  disabled={isSubmitting || !canEdit}
                  showSkeleton={isLoading}
                  type="number"
                />
              </Grid>
              <Grid item xs={row}>
                <FormText
                  name="allOtherPenaltiesCombined"
                  label="All Other Penalties Combined $ (USD)"
                  value={values.allOtherPenaltiesCombined}
                  onChange={handleInputChange}
                  error={errors.allOtherPenaltiesCombined}
                  disabled={isSubmitting || !canEdit}
                  showSkeleton={isLoading}
                  type="number"
                />
              </Grid>
              <Grid item xs={12} sx={{ textAlign: "center" }}>
                <Button
                  onClick={handleSave}
                  variant="contained"
                  color="primary"
                  disabled={!canEdit}
                >
                  Update
                </Button>
              </Grid>
            </Grid>
            <Divider my={3}></Divider>
            <Typography variant="h5">Fuel Type</Typography>
            <Divider my={3}></Divider>
            <Grid container>
              <Grid item xs={12} sx={{ textAlign: "center" }}>
                <Button
                  onClick={handleAddGasCost}
                  variant="contained"
                  color="primary"
                  disabled={!canEdit}
                >
                  Add Gas Cost
                </Button>
              </Grid>
              <Grid item xs={2}></Grid>
              <Grid item xs={8}>
                <LocalEnhancedTable<IGasTotalCost>
                  refreshGrid={true}
                  columns={columns(
                    canEdit,
                    setShowDeleteModal,
                    setCurrentRowSelected
                  )}
                  data={gasTotalCost}
                  showSkeleton={isLoading}
                  totalData={gasTotalCostTotal}
                />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Stack
                direction="row"
                spacing={2}
                justifyContent="center"
                alignItems="center"
              >
                <FormBackButton
                  onClick={handleBack}
                  isSubmitting={isSubmitting}
                  disabled={isLoading}
                  showSkeleton={isLoading}
                />
                <FormCancelButton
                  onClick={handleCancel}
                  isSubmitting={isSubmitting}
                  disabled={isLoading}
                />
              </Stack>
            </Grid>
          </Form>
        </CardContent>
      </Card>
      <Popup
        openPopup={isDialogOpen}
        setOpenPopup={setIsDialogOpen}
        title={` Add Gas Cost`}
        onClose={closeHandler}
      >
        <Form>
          <Grid container spacing={5}>
            <Grid item xs={12}>
              <FormSelect
                name="fuelTypeId"
                label="Fuel Type"
                value={valuesModal.gasFuelTypeEnergyCost.fuelTypeId}
                onChange={function (e: any): void {
                  valuesModal.gasFuelTypeEnergyCost.fuelTypeId = e.target.value;
                  setValuesModal({ ...valuesModal });
                }}
                options={fuelTypeKeyValue}
                error={errorsModal.fuelTypeId}
                disable={isSubmitting}
                showSkeleton={isLoading}
                defaultValue={{ key: 0, value: "Select a value" }}
              />
            </Grid>
            <Grid item xs={12}>
              <FormSelect
                name="gasUnitsId"
                label="Unit"
                value={valuesModal.gasFuelTypeEnergyCost.gasUnitsId}
                onChange={function (e: any): void {
                  valuesModal.gasFuelTypeEnergyCost.gasUnitsId = e.target.value;
                  setValuesModal({ ...valuesModal });
                }}
                options={electricityUnitsKeyValue}
                error={errorsModal.gasUnitsId}
                disable={isSubmitting}
                showSkeleton={isLoading}
                defaultValue={{ key: 0, value: "Select a value" }}
              />
            </Grid>
            <Grid item xs={12}>
              <FormText
                name="totalCost"
                label="Total Cost $ (USD)"
                value={valuesModal.totalCost}
                onChange={function (e: any): void {
                  valuesModal.totalCost = e.target.value;
                  setValuesModal({ ...valuesModal });
                }}
                error={errorsModal.totalCost}
                disabled={isSubmitting}
                showSkeleton={isLoading}
                type="number"
              />
            </Grid>
          </Grid>
        </Form>
        <Grid item>
          <FormCancelButton onClick={closeHandler} isSubmitting={false} />
          <FormAcceptButton onClick={confirmHandler} isSubmitting={false} />
        </Grid>
      </Popup>

      <MatchWordConfirmModal
        onConfirm={handleDelete}
        text={`Are you certain you want to delete this item?`}
        matchWord={"delete"}
        isDialogOpen={showDeleteModal}
        setIsDialogOpen={setShowDeleteModal}
      />
    </>
  );
};

export default BuildingLimitedData;
