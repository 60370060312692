import { Button, Grid, Typography } from "@mui/material";
import { useStopwatch } from "../../hooks/useStopwatch";

interface FormStopwatchPros {
  date: Date;
  start: boolean;
  endDate?: Date;
}

const FormStopwatch = (props: FormStopwatchPros) => {
  const { date, start, endDate } = props;
  const stopWatchStartDate = new Date(date).getTime();
  const stopWatchEndDate = !!endDate
    ? new Date(endDate).getTime()
    : new Date().getTime();
  const [days, hours, minutes, seconds] = useStopwatch(
    stopWatchStartDate,
    start,
    stopWatchEndDate
  );

  return (
    <>
      <Grid container spacing={12}>
        <Grid item xs={3}>
          <Button
            type="submit"
            fullWidth
            variant="outlined"
            color="primary"
            size="large"
          >
            <Grid>
              <Grid item>
                <Typography variant="h1" gutterBottom>
                  {days}
                </Typography>
              </Grid>
              <Grid>
                <Typography variant="h5" gutterBottom>
                  Days
                </Typography>
              </Grid>
            </Grid>
          </Button>
        </Grid>
        <Grid item xs={3}>
          <Button
            type="submit"
            fullWidth
            variant="outlined"
            color="primary"
            size="large"
          >
            <Grid>
              <Grid item>
                <Typography variant="h1" gutterBottom>
                  {hours}
                </Typography>
              </Grid>
              <Grid>
                <Typography variant="h5" gutterBottom>
                  Hours
                </Typography>
              </Grid>
            </Grid>
          </Button>
        </Grid>
        <Grid item xs={3}>
          <Button
            type="submit"
            fullWidth
            variant="outlined"
            color="primary"
            size="large"
          >
            <Grid>
              <Grid item>
                <Typography variant="h1" gutterBottom>
                  {minutes}
                </Typography>
              </Grid>
              <Grid>
                <Typography variant="h5" gutterBottom>
                  Minutes
                </Typography>
              </Grid>
            </Grid>
          </Button>
        </Grid>
        <Grid item xs={3}>
          <Button
            type="submit"
            fullWidth
            variant="outlined"
            color="primary"
            size="large"
          >
            <Grid>
              <Grid item>
                <Typography variant="h1" gutterBottom>
                  {seconds}
                </Typography>
              </Grid>
              <Grid>
                <Typography variant="h5" gutterBottom>
                  Seconds
                </Typography>
              </Grid>
            </Grid>
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default FormStopwatch;
