import { Alert, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { FormButton } from "src/components/formControls";

interface Props {
  buildingId: number;
  companyId: number;
}

const CompleteValidateBuildingPicture = ({ buildingId, companyId }: Props) => {
  const navigate = useNavigate();

  const gotoBuildingPhotoHandler = () => {
    navigate(`/app/BuildingPhoto/${buildingId}/${companyId}`);
  };

  return (
    <>
      <Alert severity="warning">
        <b>Attention:</b> Make your <b>project</b> look more professional by
        uploading a picture of the <b>Building or Space</b> you’re testing which
        will be presented as part of the final report cover.
      </Alert>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <FormButton
          text="Upload a Building/Project Report Cover Picture"
          onClick={gotoBuildingPhotoHandler}
          variant="text"
        />
      </Box>
    </>
  );
};

export default CompleteValidateBuildingPicture;
