import React from "react";
import { Alert, Grid, Stack } from "@mui/material";
import { Form } from "src/hooks";
import { FormButton } from "src/components/formControls";

interface Props {
  onComplete: () => Promise<void>;
  isSubmitting: boolean;
}

const CompleteAcceptance = ({ onComplete, isSubmitting }: Props) => {
  const onAcceptHandler = async () => {
    await onComplete();
  };

  const row = 12;
  return (
    <Form>
      <Grid container spacing={5}>
        <Grid item xs={row}>
          <>
            <Alert severity="error">
              This will complete this Study and
              <b>
                {" "}
                NO ADDITIONAL CHANGES WILL BE ALLOWED UNLESS YOU REOPEN REPORT
                STUDY.{" "}
              </b>
              Are you certain you want to finalize your test?
            </Alert>
          </>
        </Grid>
        <Grid item xs={row}>
          <Stack
            direction="row"
            spacing={2}
            justifyContent="center"
            alignItems="center"
          >
            <FormButton
              text={"Accept"}
              onClick={onAcceptHandler}
              size="small"
              isSubmitting={isSubmitting}
            />
          </Stack>
        </Grid>
      </Grid>
    </Form>
  );
};

export default CompleteAcceptance;
