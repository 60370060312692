import React from "react";
import styled from "@emotion/styled";
import { Helmet } from "react-helmet-async";
import { Box, Link, Paper, Typography } from "@mui/material";
import { useTheme } from "src/hooks";
import SignUpComponent from "src/components/auth/SignUp";
import { THEMES } from "src/constants";
import { useParams } from "react-router-dom";

const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)};

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)};
  }
`;

function SignUp() {
  const { theme } = useTheme();
  let { id } = useParams<{ id: string }>();
  let teamMemberId = Number(id ?? 0);

  return (
    <React.Fragment>
      <Typography component="h1" variant="h4" align="center" gutterBottom>
        Create a new account
      </Typography>
      <Wrapper>
        <Helmet title="Sign Up" />
        <Link href="https://www.auditmaster.pro/" target="_blank">
          <Box
            component="img"
            alt="Logo AMP"
            src={
              THEMES.DARK === theme
                ? "/static/img/AMPWebLogoVectors.svg"
                : "/static/img/AMPWebLogoVectorsLight.svg"
            }
            sx={{
              marginLeft: "27%",
              marginRight: "30%",
              width: "50%",
              marginBottom: "10px",
            }}
          />
        </Link>
        <SignUpComponent teamMemberId={teamMemberId} />
      </Wrapper>
    </React.Fragment>
  );
}

export default SignUp;
