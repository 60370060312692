import axios from "../utils/axios";
import { getFilter } from "../utils/utils";
import {
  ITestimonialFilter,
  ITestimonialSurvey,
  TestimonialSurveyList,
} from "src/ts/interfaces/testimonial";

const getAll = async (
  filter: ITestimonialFilter,
  getQuery?: (filters: Record<string, any>) => string
) => {
  const queryString = getQuery ? getQuery(filter) : getFilter(filter);

  return await axios.get<TestimonialSurveyList>(
    `TestimonialSurvey/GetAll?${queryString}`
  );
};

const getCurrent = async (id: number) => {
  return axios.get(`TestimonialSurvey/${id}`);
};

const getByCode = async (code: string) => {
  return axios.get(`TestimonialSurvey/Code?code=${code}`);
};

const updatestatus = async (id: number, statusId: number) => {
  return axios.put<any>(
    `TestimonialSurvey/UpdateStatus?id=${id}&status=${statusId}`
  );
};

const update = async (data: ITestimonialSurvey) => {
  return axios.put<any>(`TestimonialSurvey`, data);
};

const testimonialSurveyService = {
  getAll,
  getCurrent,
  updatestatus,
  getByCode,
  update,
};

export default testimonialSurveyService;
