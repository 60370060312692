import { IProjectActiveResult } from "src/ts/interfaces";
import axios from "src/utils/axios";

const modifyActiveProject = (projectId: number, mode: string = "Add") => {
  return axios.put(`ProjectActive/Modify?id=${projectId}&mode=${mode}`);
};

const GetMyActiveProjectsList = () => {
  return axios.get<IProjectActiveResult[]>(
    "ProjectActive/GetMyActiveProjectsList"
  );
};

const projectActiveService = {
  modifyActiveProject,
  GetMyActiveProjectsList,
};

export default projectActiveService;
