import { Grid, Link, Typography } from "@mui/material";
import LocalEnhancedTable from "src/components/localTable/LocalTable";
import { ColumnType } from "src/types/enhancedTable";
import { useAuth, useForm, useLog } from "src/hooks";
import { useEffect, useState } from "react";
import { FormText } from "src/components/formControls";
import { IProjectDocumentLibrary } from "src/ts/interfaces/documents";
import documentLibraryService from "src/services/documentLibraryService";
import { useNavigate } from "react-router-dom";

const getColumns = (
  navigate: (val: string) => void,
  companyId: string
): ColumnType[] => {
  return [
    {
      id: "projectName",
      label: "Project Name",
      type: "custom",
      sort: true,
      callback: (row: IProjectDocumentLibrary) => {
        return (
          <>
            <Link
              component="button"
              onClick={() => {
                navigate(
                  `${"/app/DocumentLibrary/"}${
                    row.projectId
                  }/${companyId}${"/StorageSummary"}`
                );
              }}
            >
              {row.projectName}
            </Link>
          </>
        );
      },
    },
    {
      id: "buildingName",
      label: "Building Name",
      type: "string",
      sort: true,
    },
    {
      id: "projectStatus",
      label: "Project Status",
      type: "string",
      sort: true,
    },
    {
      id: "totalFileSize",
      label: "Size",
      type: "custom",
      sort: true,
      callback: (row: IProjectDocumentLibrary) => {
        return (
          <>
            <Typography>
              {Intl.NumberFormat("en-US", {
                maximumFractionDigits: 2,
              }).format(row.totalFileSize)}{" "}
              KB
            </Typography>
          </>
        );
      },
    },
  ];
};

const initialValues = {
  id: 0,
  projectName: "",
  buildingName: "",
  projectStatus: "",
};

const TableLibraryDocumentSummary = () => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const columns = getColumns(navigate, user?.companyId);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [libraryDocument, setLibraryDocument] = useState<
    IProjectDocumentLibrary[]
  >([]);
  const { log } = useLog();

  const { values: filterValues, handleInputChange: handleFilterChange } =
    useForm(initialValues, true, []);

  useEffect(() => {
    const getData = async () => {
      try {
        setIsSubmitting(true);
        const request =
          await documentLibraryService.getLibraryDocumentSummary();
        setLibraryDocument(request.data);
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      } finally {
        setIsSubmitting(false);
      }
    };

    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Grid item xs={12}>
        <Grid container padding={"20px"}>
          <Grid item xs={12}>
            <FormText
              name="name"
              label="Search"
              value={filterValues.name}
              onChange={handleFilterChange}
            />
          </Grid>
        </Grid>

        <LocalEnhancedTable<IProjectDocumentLibrary>
          refreshGrid={true}
          columns={columns}
          data={libraryDocument}
          showSkeleton={isSubmitting}
          query={filterValues.name}
          defaultRowPerPage={10}
        />
      </Grid>
    </>
  );
};

export default TableLibraryDocumentSummary;
