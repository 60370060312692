import React, { useEffect, useRef, useState } from "react";
import Moment from "moment";
import { Link } from "react-router-dom";
import styled from "@emotion/styled";

import {
  Avatar as MuiAvatar,
  Badge,
  Box,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Popover as MuiPopover,
  SvgIcon,
  Tooltip,
  Typography,
} from "@mui/material";
import { Bell } from "react-feather";
import {
  WorkIcon,
  BugReportIcon,
  ShuffleIcon,
  ChildCareIcon,
  CreditCardIcon,
  CalendarMonthIcon,
  BookIcon,
  CommentIcon,
  ShieldIcon,
  SsidChartIcon,
} from "../icons";
import {
  fetchNewNotifications,
  archiveNotificationById,
  archiveAllNotification,
} from "src/redux/slices/notificationActions";
import { useAppDispatch, useAppSelector } from "src/hooks";
import { convertUTCDateToLocalDate } from "src/utils/utils";
const Popover = styled(MuiPopover)`
  .MuiPaper-root {
    width: 300px;
    ${(props) => props.theme.shadows[1]};
    border: 1px solid ${(props) => props.theme.palette.divider};
  }
`;

const Indicator = styled(Badge)`
  .MuiBadge-badge {
    background: ${(props) => props.theme.header.indicator.background};
    color: ${(props) => props.theme.palette.common.white};
  }
`;

const Avatar = styled(MuiAvatar)`
  background: ${(props) => props.theme.palette.primary.main};
`;

const NotificationHeader = styled(Box)`
  text-align: center;
  border-bottom: 1px solid ${(props) => props.theme.palette.divider};
`;

function Notification({
  title,
  description,
  Icon,
  onClick,
  notificationUserId,
}: {
  title: string;
  description: string;
  Icon: React.ElementType;
  onClick: (notificationUserId: number) => void;
  notificationUserId: number;
}) {
  return (
    <ListItem button onClick={() => onClick(notificationUserId)}>
      <ListItemAvatar>
        <Avatar>
          <SvgIcon fontSize="small">{Icon ? <Icon /> : ""}</SvgIcon>
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={title}
        primaryTypographyProps={{
          variant: "subtitle2",
          color: "textPrimary",
        }}
        secondary={description}
      />
    </ListItem>
  );
}

function NavbarNotificationsDropdown() {
  const ref = useRef(null);
  const [isOpen, setOpen] = useState(false);

  const dispatch = useAppDispatch();
  const notifications = useAppSelector(
    (state) => state.notification.notifications
  );

  useEffect(() => {
    dispatch(fetchNewNotifications());
  }, [dispatch]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const getIcon = (icon: string): any => {
    switch (icon) {
      case "icon-briefcase bg-blue":
        return WorkIcon;

      case "icon-bug bg-danger":
        return BugReportIcon;

      case "icon-random btn-info":
        return ShuffleIcon;

      case "icon-child btn-azure":
        return ChildCareIcon;

      case "icon-credit-card btn-success":
        return CreditCardIcon;

      case "icon-calendar btn-yellow":
        return CalendarMonthIcon;

      case "icon-book btn-black-opacity":
        return BookIcon;

      case "glyph-icon icon-comments":
        return CommentIcon;

      case "icon-shield btn-warning":
        return ShieldIcon;

      case "icon-line-chart btn-success":
        return SsidChartIcon;
    }
  };

  const handleReadNotification = (notificationUserId: number) => {
    dispatch(archiveNotificationById(notificationUserId));
  };

  const handleReadAllNotification = () => {
    dispatch(archiveAllNotification());
  };

  return (
    <React.Fragment>
      <Tooltip title="Notifications">
        <IconButton color="inherit" ref={ref} onClick={handleOpen} size="large">
          <Indicator badgeContent={`${notifications.length ?? 0}`}>
            <Bell />
          </Indicator>
        </IconButton>
      </Tooltip>
      <Popover
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
      >
        <NotificationHeader p={2}>
          <Typography variant="subtitle1" color="textPrimary">
            {notifications.length ?? 0} New Notifications
          </Typography>
        </NotificationHeader>
        <React.Fragment>
          <List disablePadding>
            <>
              {notifications.map((notification) => (
                <Notification
                  key={notification.id}
                  notificationUserId={notification.id}
                  onClick={handleReadNotification}
                  title={notification.notification.title}
                  description={Moment(
                    convertUTCDateToLocalDate(
                      notification?.notification?.createdDate?.toString() || ""
                    )?.format("yyyy-MM-DD hh:mm:ss a")
                  ).fromNow()}
                  Icon={getIcon(
                    notification.notification.notificationType.icon
                  )}
                />
              ))}
            </>
          </List>
          <Box p={1} display="flex" justifyContent="center">
            <Button size="small" component={Link} to="/app/Notifications">
              Show all notifications
            </Button>
            <Button size="small" onClick={handleReadAllNotification}>
              Mark all as read
            </Button>
          </Box>
        </React.Fragment>
      </Popover>
    </React.Fragment>
  );
}

export default NavbarNotificationsDropdown;
