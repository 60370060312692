import { IOutofServiceReason } from "src/ts/interfaces/companyAssets";
import axios from "src/utils/axios";

const postAssetOutOfService = (data: IOutofServiceReason) => {
  return axios.post(`AssetOutOfService`, data);
};

const putReactiveAssetOutOfService = (assetId: string, comments: string) => {
  return axios.put(`AssetOutOfService?assetId=${assetId}&comments=${comments}`);
};

const assetOutOfService = {
  postAssetOutOfService,
  putReactiveAssetOutOfService,
};

export default assetOutOfService;
