import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { Stack } from "@mui/system";
import { useEffect, useState } from "react";
import DialogMessagePopup from "src/components/DialogMessagePopup";
import {
  FormButton,
  FormNumeric2,
  FormSelect,
  FormText,
} from "src/components/formControls";
import SpreadSheetActionButton from "src/components/spreadsheet/SpreadSheetActionButton";
import sourceSoundCombineService from "src/services/study/sourceSoundCombineService";
import { IKeyValue } from "src/ts/interfaces";
import {
  IEnergyCombinedAverage,
  ISourceSoundCombine,
  ISourceSoundTest,
} from "src/ts/interfaces/catalogs";
import { Mode } from "src/ts/types";

interface Props {
  sourceSoundCombineItems: ISourceSoundCombine[];
  setSourceSoundCombineItems: (val: ISourceSoundCombine[]) => void;
  saveSourceSoundCombined: (item: ISourceSoundCombine) => Promise<void>;
  energyCombinedAverageSelected: IEnergyCombinedAverage | null;
  mode: Mode;
  disableStudy: boolean;
  isLoading: boolean;
  sourceSoundTests: IKeyValue<number, string>[];
  sourceSoundTestsAll: ISourceSoundTest[];
}

const SourceSoundGrid = ({
  sourceSoundCombineItems,
  setSourceSoundCombineItems,
  saveSourceSoundCombined,
  energyCombinedAverageSelected,
  mode,
  disableStudy,
  isLoading,
  sourceSoundTests,
  sourceSoundTestsAll,
}: Props) => {
  const [sourceSoundCombineSelected, setSourceSoundCombineSelected] = useState<{
    item: ISourceSoundCombine;
    index: number;
  } | null>(null);
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] =
    useState(false);

  const onBlurCombinedDbHandler = async (
    row: ISourceSoundCombine,
    index: number,
    e: any
  ) => {
    if (parseFloat(e.target.value) === row.combinedDb) return;

    const modifiedItem = { ...row, combinedDb: parseFloat(e.target.value) };
    updateItems(modifiedItem, index);
    await saveSourceSoundCombined(modifiedItem);
  };

  const onBlurDescription = async (
    row: ISourceSoundCombine,
    index: number,
    e: any,
    saveItem: boolean
  ) => {
    const modifiedItem = { ...row, description: e.target.value };
    updateItems(modifiedItem, index);
    if (saveItem) await saveSourceSoundCombined(modifiedItem);
  };

  const onClickSelectButton = (
    row: ISourceSoundCombine,
    index: number,
    e: any
  ) => {
    const modifiedItem = {
      ...row,
      combinedDb: 0,
      description: null,
      sourceSoundTestsId: -1,
    };
    updateItems(modifiedItem, index);
  };

  const onSelectChange = async (
    row: ISourceSoundCombine,
    index: number,
    e: any
  ) => {
    const sourceSoundTestsId = parseInt(e.target.value);
    const energySourceSubtracted = sourceSoundTestsAll.find(
      (x) => x.id === sourceSoundTestsId
    );

    const description = !energySourceSubtracted
      ? null
      : energySourceSubtracted.description;

    const combinedDb = !energySourceSubtracted
      ? null
      : energySourceSubtracted.db;

    const modifiedItem = {
      ...row,
      sourceSoundTestsId: sourceSoundTestsId,
      description: description,
      combinedDb: combinedDb ?? 0,
    };

    updateItems(modifiedItem, index);
    await saveSourceSoundCombined(modifiedItem);
  };

  const updateItems = (row: ISourceSoundCombine, index: number) => {
    const newItems = sourceSoundCombineItems.map((item, i) =>
      index === i ? row : item
    );
    setSourceSoundCombineItems(newItems);
  };

  const deleteSoundCombinedTestItemConfirmation = (
    row: ISourceSoundCombine,
    index: number
  ) => {
    setSourceSoundCombineSelected({ item: row, index });
    setShowDeleteConfirmationModal(true);
  };

  const deleteSoundSource = async () => {
    if (
      sourceSoundCombineSelected?.item == null ||
      sourceSoundCombineSelected?.index < 0
    )
      return;

    await sourceSoundCombineService.remove(sourceSoundCombineSelected?.item.id);
    const newItems = [...sourceSoundCombineItems];
    newItems.splice(sourceSoundCombineSelected?.index, 1);
    setSourceSoundCombineItems(newItems);
    setShowDeleteConfirmationModal(false);
  };

  const addSourceSoundTest = async () => {
    const tempSourceSoundTest: any = {
      energySourceSubtractedId: energyCombinedAverageSelected?.id,
    };
    const newItemRes = await sourceSoundCombineService.add(tempSourceSoundTest);
    const newItems = [...sourceSoundCombineItems];
    newItems.push({ ...newItemRes.data, sourceSoundTestsId: -1 });
    setSourceSoundCombineItems(newItems);
  };
  return (
    <>
      <TableContainer>
        <Table style={{ marginBottom: "0px" }}>
          <TableHead>
            <TableRow>
              <TableCell>Energy Combined</TableCell>
              {sourceSoundCombineItems.map((item, index) => (
                <TableCell style={{ padding: 0 }}>
                  Source #{index + 1}
                  {mode === "read&Write" && !disableStudy && (
                    <SpreadSheetActionButton
                      type="delete"
                      tooltip="Delete this item"
                      onMouseUp={() =>
                        deleteSoundCombinedTestItemConfirmation(item, index)
                      }
                    />
                  )}
                </TableCell>
              ))}

              <TableCell>
                {mode === "read&Write" && !disableStudy && (
                  <SpreadSheetActionButton
                    type="add"
                    tooltip="Add new item"
                    onMouseUp={(e: any) => addSourceSoundTest()}
                  />
                )}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>Description</TableCell>

              {sourceSoundCombineItems.map(
                (item: ISourceSoundCombine, index) => (
                  <TableCell style={{ padding: 2 }} key={item.id}>
                    {item.sourceSoundTestsId !== 0 && (
                      <FormSelect
                        name={"sourceSoundTestsId"}
                        label={""}
                        value={item.sourceSoundTestsId}
                        options={sourceSoundTests}
                        onChange={(e) => onSelectChange(item, index, e)}
                        defaultValue={{ key: -1, value: "Select" }}
                        disabled={disableStudy}
                        mode={mode}
                      />
                    )}
                    {/* sourceSoundTests */}
                    {item.sourceSoundTestsId === 0 && (
                      <Stack direction="row">
                        <FormText
                          name={`description_${item.id}`}
                          value={item.description ?? ""}
                          label={""}
                          onChange={(e: any) =>
                            onBlurDescription(item, index, e, false)
                          }
                          onBlur={(e: any) =>
                            onBlurDescription(item, index, e, true)
                          }
                          mode={mode}
                          disabled={disableStudy}
                          showSkeleton={isLoading}
                        />
                        {!disableStudy && (
                          <FormButton
                            variant="outlined"
                            size="small"
                            text={"Select"}
                            onClick={(e) => onClickSelectButton(item, index, e)}
                          />
                        )}
                      </Stack>
                    )}
                  </TableCell>
                )
              )}

              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Combined DB</TableCell>

              {sourceSoundCombineItems.map((item, index) => (
                <TableCell style={{ padding: 2 }} key={item.id}>
                  <FormNumeric2
                    name={`combinedDb_${index}`}
                    value={item.combinedDb}
                    onChange={() => {}}
                    decimalScale={3}
                    maxValue={1000000}
                    onBlur={(e: any) => onBlurCombinedDbHandler(item, index, e)}
                    label={""}
                    mode={mode}
                    disabled={disableStudy}
                    showSkeleton={isLoading}
                  />
                </TableCell>
              ))}
              <TableCell></TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <DialogMessagePopup
        title={"Delete Confirmation"}
        text="Are you certain you want to delete the selected Source Item?"
        showPopup={showDeleteConfirmationModal}
        setShowPopup={setShowDeleteConfirmationModal}
        onSave={() => deleteSoundSource()}
        onCancel={() => setShowDeleteConfirmationModal(false)}
        isSubmitting={false}
        disableClickOutside={true}
      />
    </>
  );
};

export default SourceSoundGrid;
