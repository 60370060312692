import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Grid, Typography } from "@mui/material";
import {
  FormButton,
  FormCheckBox,
  FormDatePicker,
  FormNumeric2,
  FormSelect,
  FormText,
} from "src/components/formControls";
import { FooterButtons } from "../../components/FooterButtons";
import { IBoilerTest } from "src/ts/interfaces/study/boiler";
import { steps } from "./const";
import { NavigateFunction } from "react-router-dom";
import FormTooltip from "src/components/formControls/FormTooltip";
import { PlayArrow } from "@mui/icons-material";
import DialogMessagePopup from "src/components/DialogMessagePopup";
import FormTitleBox from "src/components/formControls/FormTitleBox";

interface Props {
  values: IBoilerTest;
  handleInputChange: (e: any) => void;
  errors: Record<string, string>;
  showskeleton: boolean;
  disableStudy: boolean;
  handleSave: () => Promise<void>;
  navigate: NavigateFunction;
  companyId: number;
  systemOfMeasurement: {
    get: (key: string) => any;
    setSystem: (system: string) => void;
    getByProject: (projectId: number) => Promise<string | null>;
    measurementSystem: string | null;
  };
  measurement: string;
  setValues: Dispatch<SetStateAction<IBoilerTest>>;
}

const Step3 = ({
  values,
  handleInputChange,
  errors,
  showskeleton,
  disableStudy,
  handleSave,
  navigate,
  companyId,
  systemOfMeasurement,
  measurement,
  setValues,
}: Props) => {
  const fuelUtilitySource = [
    { key: "Natural Gas", value: "Natural Gas" },
    { key: "Electric", value: "Electric" },
    { key: "Propane", value: "Propane" },
    { key: "Fuel Oil", value: "Fuel Oil" },
    {
      key: "Landfill/digester or Methane",
      value: "Landfill/digester or Methane",
    },
    { key: "Coal", value: "Coal" },
    { key: "Diesel Oil", value: "Diesel Oil" },
    { key: "Gasoline", value: "Gasoline" },
    { key: "Coke Oven Gas", value: "Coke Oven Gas" },
    { key: "Wood", value: "Wood" },
  ];

  const [modalStart, setModalStart] = useState(false);
  const [finishLoad, setFinishLoad] = useState(false);

  useEffect(() => {
    const getData = async () => {
      if (!disableStudy) await handleSave();
    };

    if (values.testStarted && finishLoad) getData();
    setFinishLoad(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.testStarted]);

  useEffect(() => {
    if (!values.fuelSource) values.fuelSource = "Natural Gas";
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.fuelSource]);

  const nextStepHandler = async () => {
    if (!disableStudy) handleSave();
    navigate(`/studies/BoilerTest/step4/${values.id}/`);
  };

  const backStepHandler = async () => {
    if (!disableStudy) handleSave();
    navigate(`/studies/BoilerTest/step2/${values.id}/`);
  };

  const handleStartStudy = () => {
    setValues({ ...values, testStarted: true });
  };

  const handleStartModal = () => {
    setModalStart(true);
  };

  const handleInputChangewaterMbh = (e: any) => {
    if (e.target.value != null) {
      setValues({
        ...values,
        waterBtuHr: e.target.value * 1000,
        waterMbh: e.target.value,
      });
    } else {
      setValues({ ...values, waterMbh: e.target.value });
    }
  };

  const handleInputChangewaterBtuHr = (e: any) => {
    if (e.target.value != null) {
      setValues({
        ...values,
        waterMbh: e.target.value / 1000,
        waterBtuHr: e.target.value,
      });
    } else {
      setValues({ ...values, waterBtuHr: e.target.value });
    }
  };

  return (
    <>
      <FormTitleBox title={"Boiler Nameplate Data"}>
        <Grid container spacing={5} p={2}>
          <Grid item xs={5}>
            <FormText
              name="manufacturer"
              label="Manufacturer"
              value={values?.manufacturer}
              onChange={handleInputChange}
              error={errors.manufacturer}
              showSkeleton={showskeleton}
              disabled={disableStudy}
            />
          </Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={5}>
            <FormText
              name="modelNumber"
              label="Model #"
              value={values?.modelNumber}
              onChange={handleInputChange}
              error={errors.modelNumber}
              showSkeleton={showskeleton}
              disabled={disableStudy}
            />
          </Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={5}>
            <FormText
              name="serialNumber"
              label="Serial #"
              value={values?.serialNumber}
              onChange={handleInputChange}
              error={errors.serialNumber}
              showSkeleton={showskeleton}
              disabled={disableStudy}
            />
          </Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={5}>
            <FormText
              name="series"
              label="Series #"
              value={values?.series}
              onChange={handleInputChange}
              error={errors.series}
              showSkeleton={showskeleton}
              disabled={disableStudy}
            />
          </Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={5}>
            <FormNumeric2
              name="minimumReliefValveCapacity"
              label={`Minimum Relief Valve Capacity (${systemOfMeasurement.get(
                "psi"
              )})`}
              value={values?.minimumReliefValveCapacity}
              onChange={handleInputChange}
              error={errors.minimumReliefValveCapacity}
              showSkeleton={showskeleton}
              disabled={disableStudy}
              decimalScale={3}
              thousandSeparator={true}
            />
          </Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={5}>
            <FormDatePicker
              name="mfgDate"
              label={`Mfg Date`}
              value={values?.mfgDate}
              onChange={handleInputChange}
              error={errors.mfgDate}
              disable={disableStudy}
            />
          </Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={5}>
            <FormSelect
              name={"fuelSource"}
              label={"Fuel/Utility Source"}
              onChange={handleInputChange}
              showSkeleton={showskeleton}
              disabled={disableStudy}
              error={errors.fuelSource}
              value={values?.fuelSource}
              options={fuelUtilitySource}
              defaultValue={{
                key: "",
                value: "Select Fuel/Utility Source",
              }}
            ></FormSelect>
          </Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={3}>
            <FormNumeric2
              name="fuelCaloricEnergyValue"
              label={`Fuel Utility Supplier Caloric Energy Value`}
              value={values?.fuelCaloricEnergyValue}
              onChange={handleInputChange}
              error={errors.fuelCaloricEnergyValue}
              showSkeleton={showskeleton}
              disabled={disableStudy}
              decimalScale={3}
              thousandSeparator={true}
            />
          </Grid>
          <Grid item xs={2}>
            <FormText
              name="fuelCaloricEnergyValueUnit"
              label={``}
              value={values?.fuelCaloricEnergyValueUnit}
              onChange={handleInputChange}
              error={errors.fuelCaloricEnergyValueUnit}
              showSkeleton={showskeleton}
              disabled={disableStudy}
              decimalScale={3}
              placeholder="Unit (Cu/Ft, BTU/h, Watts, Joules,  etc.)"
            />
          </Grid>
          <Grid item xs={1}>
            <FormTooltip text="Call Fuel Utility Supplier to get the Caloric value and then enter whether it is by the Cu/Ft, Gallon, Liter, Pound, Kilo, BTU/h, Watts, Joules, etc."></FormTooltip>
          </Grid>
          <Grid item xs={5}>
            <FormNumeric2
              name="waterBtuHr"
              label={`${systemOfMeasurement.get("btuh")}`}
              value={values?.waterBtuHr}
              onChange={handleInputChangewaterBtuHr}
              error={errors.waterBtuHr}
              disabled={disableStudy}
              showSkeleton={showskeleton}
              decimalScale={3}
              thousandSeparator={true}
            />
          </Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={5}>
            <FormNumeric2
              name="waterMbh"
              label={`${systemOfMeasurement.get("mbh")}`}
              value={values?.waterMbh}
              onChange={handleInputChangewaterMbh}
              error={errors.waterMbh}
              disabled={disableStudy}
              showSkeleton={showskeleton}
              decimalScale={3}
              thousandSeparator={true}
            />
          </Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={5}>
            <FormNumeric2
              name="efficiency"
              label={`Efficiency`}
              value={values?.efficiency}
              onChange={handleInputChange}
              error={errors.efficiency}
              disabled={disableStudy}
              showSkeleton={showskeleton}
              decimalScale={3}
              thousandSeparator={true}
            />
          </Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={5}>
            <FormNumeric2
              name="minimumBtuHrFuelInput"
              label={`Minimum ${systemOfMeasurement.get("btuh")} Fuel Input`}
              value={values?.minimumBtuHrFuelInput}
              onChange={handleInputChange}
              error={errors.minimumBtuHrFuelInput}
              disabled={disableStudy}
              showSkeleton={showskeleton}
              decimalScale={3}
              thousandSeparator={true}
            />
          </Grid>
          <Grid item xs={1}>
            <FormTooltip text="Minimum BTU/HR ratings apply to high-Low and modulating type boilers only"></FormTooltip>
          </Grid>
          <Grid item xs={5}>
            <FormNumeric2
              name="maximumBtuHrFuelInpu"
              label={`Maximum ${systemOfMeasurement.get("btuh")} Fuel Input`}
              value={values?.maximumBtuHrFuelInpu}
              onChange={handleInputChange}
              error={errors.maximumBtuHrFuelInpu}
              disabled={disableStudy}
              showSkeleton={showskeleton}
              decimalScale={3}
              thousandSeparator={true}
            />
          </Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={5}>
            <FormNumeric2
              name="minimumBtuHrOutput"
              label={`Minimum ${systemOfMeasurement.get("btuh")} Output`}
              value={values?.minimumBtuHrOutput}
              onChange={handleInputChange}
              error={errors.minimumBtuHrOutput}
              disabled={disableStudy}
              showSkeleton={showskeleton}
              decimalScale={3}
              thousandSeparator={true}
            />
          </Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={5}>
            <FormNumeric2
              name="maximumBtuHrOutput"
              label={`Maximum ${systemOfMeasurement.get("btuh")} Output`}
              value={values?.maximumBtuHrOutput}
              onChange={handleInputChange}
              error={errors.maximumBtuHrOutput}
              disabled={disableStudy}
              showSkeleton={showskeleton}
              decimalScale={3}
              thousandSeparator={true}
            />
          </Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={5}>
            <FormNumeric2
              name="boilerHorsepower"
              label={`Boiler ${systemOfMeasurement.get("horsepower")}`}
              value={values?.boilerHorsepower}
              onChange={handleInputChange}
              error={errors.boilerHorsepower}
              disabled={disableStudy}
              showSkeleton={showskeleton}
              decimalScale={3}
              thousandSeparator={true}
            />
          </Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={5}>
            <FormNumeric2
              name="squareFeetHeatingSurface"
              label={`${systemOfMeasurement.get(
                "squarefeet"
              )} of Heating Surface`}
              value={values?.squareFeetHeatingSurface}
              onChange={handleInputChange}
              error={errors.squareFeetHeatingSurface}
              disabled={disableStudy}
              showSkeleton={showskeleton}
              decimalScale={3}
              thousandSeparator={true}
            />
          </Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={5}>
            <FormNumeric2
              name="grossEdr"
              label={`Gross E.D.R.`}
              value={values?.grossEdr}
              onChange={handleInputChange}
              error={errors.grossEdr}
              disabled={disableStudy}
              showSkeleton={showskeleton}
              decimalScale={3}
              thousandSeparator={true}
            />
          </Grid>
          <Grid item xs={1}>
            <FormTooltip text="Equivalent Direct Radiation (EDR)"></FormTooltip>
          </Grid>
          <Grid item xs={5}>
            <FormNumeric2
              name="maximumWaterTemperature"
              label={`Maximum Water Temperature (${systemOfMeasurement.get(
                "temperature"
              )})`}
              value={values?.maximumWaterTemperature}
              onChange={handleInputChange}
              error={errors.maximumWaterTemperature}
              disabled={disableStudy}
              showSkeleton={showskeleton}
              decimalScale={3}
              thousandSeparator={true}
            />
          </Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={5}>
            <FormNumeric2
              name="maximumWorkingPressure"
              label={`Maximum Working Pressure (${systemOfMeasurement.get(
                "psi"
              )})`}
              value={values?.maximumWorkingPressure}
              onChange={handleInputChange}
              error={errors.maximumWorkingPressure}
              disabled={disableStudy}
              showSkeleton={showskeleton}
              decimalScale={3}
              thousandSeparator={true}
            />
          </Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={5}>
            <FormNumeric2
              name="maximumInputUsgph"
              label={`Minimum Input ${systemOfMeasurement.get("usgph")}`}
              value={values?.maximumInputUsgph}
              onChange={handleInputChange}
              error={errors.maximumInputUsgph}
              disabled={disableStudy}
              showSkeleton={showskeleton}
              decimalScale={3}
              thousandSeparator={true}
            />
          </Grid>
          <Grid item xs={1}>
            <FormTooltip
              text={
                measurement === "metricSystem"
                  ? "Liters Per Minute (LPM)"
                  : "US Gallons Per Hour (USGPH)"
              }
            ></FormTooltip>
          </Grid>
          <Grid item xs={12} pl={1}>
            <FormCheckBox
              name="forSteamWhenPicked"
              label={`Steam`}
              value={values?.forSteamWhenPicked}
              onChange={handleInputChange}
              disabled={disableStudy}
              showSkeleton={showskeleton}
            />
          </Grid>
          {values?.forSteamWhenPicked && (
            <>
              <Grid item xs={5}>
                <FormNumeric2
                  name="steamSqFt"
                  label={`(NET) Steam ${systemOfMeasurement.get("sqft")}`}
                  value={values?.steamSqFt}
                  onChange={handleInputChange}
                  error={errors.steamSqFt}
                  disabled={disableStudy}
                  showSkeleton={showskeleton}
                  decimalScale={3}
                  thousandSeparator={true}
                />
              </Grid>
              <Grid item xs={1}></Grid>
              <Grid item xs={5}>
                <FormNumeric2
                  name="steamBtuhr"
                  label={`(IBR) Steam ${systemOfMeasurement.get("btuh")}`}
                  value={values?.steamBtuhr}
                  onChange={handleInputChange}
                  error={errors.steamBtuhr}
                  disabled={disableStudy}
                  showSkeleton={showskeleton}
                  decimalScale={3}
                  thousandSeparator={true}
                />
              </Grid>
              <Grid item xs={1}>
                <FormTooltip text="Indian Boiler Regulations (IBR)"></FormTooltip>
              </Grid>
              <Grid item xs={5}>
                <FormNumeric2
                  name="steamMbh"
                  label={`Steam ${systemOfMeasurement.get("mbh")}`}
                  value={values?.steamMbh}
                  onChange={handleInputChange}
                  error={errors.steamMbh}
                  disabled={disableStudy}
                  showSkeleton={showskeleton}
                  decimalScale={3}
                  thousandSeparator={true}
                />
              </Grid>
              <Grid item xs={1}></Grid>
              <Grid item xs={5}>
                <FormNumeric2
                  name="maximumDesignSteamCapacity"
                  label={`Maximum Design Steam Capacity (${systemOfMeasurement.get(
                    "lbh"
                  )})`}
                  value={values?.maximumDesignSteamCapacity}
                  onChange={handleInputChange}
                  error={errors.maximumDesignSteamCapacity}
                  disabled={disableStudy}
                  showSkeleton={showskeleton}
                  decimalScale={3}
                  thousandSeparator={true}
                />
              </Grid>
              <Grid item xs={1}></Grid>
            </>
          )}
        </Grid>
      </FormTitleBox>

      {values.fuelSource && (
        <FormTitleBox title={`Boiler Type: ${values.fuelSource}`}>
          <Grid container spacing={5} p={2}>
            <Grid item xs={5}>
              <FormNumeric2
                name="maximumHydronicPressurePSI"
                label={`Maximum Hydronic Pressure ${systemOfMeasurement.get(
                  "psi"
                )}`}
                value={values?.maximumHydronicPressurePSI}
                onChange={handleInputChange}
                error={errors.maximumHydronicPressurePSI}
                disabled={disableStudy}
                showSkeleton={showskeleton}
                decimalScale={3}
                thousandSeparator={true}
              />
            </Grid>
            <Grid item xs={1}></Grid>
            <Grid item xs={5}>
              <FormNumeric2
                name="mbhRating"
                label={`${systemOfMeasurement.get("mbh")} Rating`}
                value={values?.mbhRating}
                onChange={handleInputChange}
                error={errors.mbhRating}
                disabled={disableStudy}
                showSkeleton={showskeleton}
                decimalScale={3}
                thousandSeparator={true}
              />
            </Grid>
            <Grid item xs={1}></Grid>
            {(values.fuelSource === "Natural Gas" ||
              values.fuelSource === "Propane" ||
              values.fuelSource === "Landfill/digester or Methane") && (
              <>
                <Grid item xs={5}>
                  <FormNumeric2
                    name="gasMinimumInletPressure"
                    label={`Gas Minimum Inlet Pressure ${systemOfMeasurement.get(
                      "inwc"
                    )}`}
                    value={values?.gasMinimumInletPressure}
                    onChange={handleInputChange}
                    error={errors.gasMinimumInletPressure}
                    disabled={disableStudy}
                    showSkeleton={showskeleton}
                    decimalScale={3}
                    thousandSeparator={true}
                  />
                </Grid>
                <Grid item xs={1}>
                  <FormTooltip text="Required to regulate gas pressure at the inlet to the gas train."></FormTooltip>
                </Grid>
              </>
            )}
            {(values.fuelSource === "Natural Gas" ||
              values.fuelSource === "Propane" ||
              values.fuelSource === "Landfill/digester or Methane") && (
              <>
                {" "}
                <Grid item xs={5}>
                  <FormNumeric2
                    name="gasMaximumInletPressure"
                    label={`Gas Maximum Inlet Pressure ${systemOfMeasurement.get(
                      "inwc"
                    )}`}
                    value={values?.gasMaximumInletPressure}
                    onChange={handleInputChange}
                    error={errors.gasMaximumInletPressure}
                    disabled={disableStudy}
                    showSkeleton={showskeleton}
                    decimalScale={3}
                    thousandSeparator={true}
                  />
                </Grid>
                <Grid item xs={1}></Grid>
              </>
            )}
            {(values.fuelSource === "Natural Gas" ||
              values.fuelSource === "Propane" ||
              values.fuelSource === "Landfill/digester or Methane") && (
              <>
                <Grid item xs={5}>
                  <FormNumeric2
                    name="gasManifoldPressure"
                    label={`Gas Manifold Pressure ${systemOfMeasurement.get(
                      "inwc"
                    )}`}
                    value={values?.gasManifoldPressure}
                    onChange={handleInputChange}
                    error={errors.gasManifoldPressure}
                    disabled={disableStudy}
                    showSkeleton={showskeleton}
                    decimalScale={3}
                    thousandSeparator={true}
                  />
                </Grid>
                <Grid item xs={1}>
                  <FormTooltip text="Minimum required gas pressure at the burner. Manifold Gas Pressures are measured after the Gas Train."></FormTooltip>
                </Grid>
              </>
            )}
            {(values.fuelSource === "Natural Gas" ||
              values.fuelSource === "Propane" ||
              values.fuelSource === "Landfill/digester or Methane") && (
              <>
                <Grid item xs={5}>
                  <FormNumeric2
                    name="noxEmissionLeve"
                    label={`NOx Emission Level PPM`}
                    value={values?.noxEmissionLeve}
                    onChange={handleInputChange}
                    error={errors.noxEmissionLeve}
                    disabled={disableStudy}
                    showSkeleton={showskeleton}
                    decimalScale={3}
                    thousandSeparator={true}
                  />
                </Grid>
                <Grid item xs={1}>
                  <FormTooltip text="Note: UC stands for uncontrolled emissions."></FormTooltip>
                </Grid>
              </>
            )}
            {values.fuelSource === "Electric" && (
              <>
                <Grid item xs={5}>
                  <FormNumeric2
                    name="ratedKw"
                    label={`Rated kW`}
                    value={values?.ratedKw}
                    onChange={handleInputChange}
                    error={errors.ratedKw}
                    disabled={disableStudy}
                    showSkeleton={showskeleton}
                    decimalScale={3}
                    thousandSeparator={true}
                  />
                </Grid>
                <Grid item xs={1}></Grid>
              </>
            )}
            {(values.fuelSource === "Fuel Oil" ||
              values.fuelSource === "Diesel Oil" ||
              values.fuelSource === "Gasoline") && (
              <>
                <Grid item xs={5}>
                  <FormNumeric2
                    name="inputGalPerHour"
                    label={`Input ${systemOfMeasurement.get("galhr")}`}
                    value={values?.inputGalPerHour}
                    onChange={handleInputChange}
                    error={errors.inputGalPerHour}
                    disabled={disableStudy}
                    showSkeleton={showskeleton}
                    decimalScale={3}
                    thousandSeparator={true}
                  />
                </Grid>
                <Grid item xs={1}>
                  <FormTooltip text="Note: UC stands for uncontrolled emissions."></FormTooltip>
                </Grid>
              </>
            )}
            {values.fuelSource === "Fuel Oil" && (
              <>
                <Grid item xs={5}>
                  <FormNumeric2
                    name="nozzlePumpPsi"
                    label={`Nozzle @ pump, ${systemOfMeasurement.get("psi")}`}
                    value={values?.nozzlePumpPsi}
                    onChange={handleInputChange}
                    error={errors.nozzlePumpPsi}
                    disabled={disableStudy}
                    showSkeleton={showskeleton}
                    decimalScale={3}
                    thousandSeparator={true}
                  />
                </Grid>
                <Grid item xs={1}></Grid>
              </>
            )}
            {values.fuelSource === "Electric" && (
              <>
                <Grid item xs={5}>
                  <FormNumeric2
                    name="mbtuhr"
                    label={`${systemOfMeasurement.get("mbh")}`}
                    value={values?.mbtuhr}
                    onChange={handleInputChange}
                    error={errors.mbtuhr}
                    disabled={disableStudy}
                    showSkeleton={showskeleton}
                    decimalScale={3}
                    thousandSeparator={true}
                  />
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={5}>
                  <FormNumeric2
                    name="quantityofElements"
                    label={`Quantity of Elements #`}
                    value={values?.quantityofElements}
                    onChange={handleInputChange}
                    error={errors.quantityofElements}
                    disabled={disableStudy}
                    showSkeleton={showskeleton}
                    decimalScale={3}
                    thousandSeparator={true}
                  />
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={5}>
                  <FormNumeric2
                    name="kwperElement"
                    label={`kW per Element`}
                    value={values?.kwperElement}
                    onChange={handleInputChange}
                    error={errors.kwperElement}
                    disabled={disableStudy}
                    showSkeleton={showskeleton}
                    decimalScale={3}
                    thousandSeparator={true}
                  />
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={5}>
                  <FormNumeric2
                    name="numberofContactors"
                    label={`Number of Contactors`}
                    value={values?.numberofContactors}
                    onChange={handleInputChange}
                    error={errors.numberofContactors}
                    disabled={disableStudy}
                    showSkeleton={showskeleton}
                    decimalScale={3}
                    thousandSeparator={true}
                  />
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={5}>
                  <FormNumeric2
                    name="numberofSteps"
                    label={`Number of Steps`}
                    value={values?.numberofSteps}
                    onChange={handleInputChange}
                    error={errors.numberofSteps}
                    disabled={disableStudy}
                    showSkeleton={showskeleton}
                    decimalScale={3}
                    thousandSeparator={true}
                  />
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={5}>
                  <FormNumeric2
                    name="kwofeachStep"
                    label={`kW of each Step`}
                    value={values?.kwofeachStep}
                    onChange={handleInputChange}
                    error={errors.kwofeachStep}
                    disabled={disableStudy}
                    showSkeleton={showskeleton}
                    decimalScale={3}
                    thousandSeparator={true}
                  />
                </Grid>
                <Grid item xs={1}>
                  <FormTooltip text="kW per element multiplied by the quantity of elements above should equal the kW of each step here"></FormTooltip>
                </Grid>
                <Grid container spacing={5} display={"contents"}>
                  <Grid item xs={5}>
                    <Grid container spacing={5}>
                      <Grid item xs={3}>
                        <Typography>Rated Volts</Typography>
                      </Grid>
                      <Grid item xs={3}>
                        <FormNumeric2
                          name="rated3PhaseVolts1"
                          label={``}
                          value={values?.rated3PhaseVolts1}
                          onChange={handleInputChange}
                          error={errors.rated3PhaseVolts1}
                          disabled={disableStudy}
                          showSkeleton={showskeleton}
                          decimalScale={3}
                          thousandSeparator={true}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <FormNumeric2
                          name="rated3PhaseVolts2"
                          label={``}
                          value={values?.rated3PhaseVolts2}
                          onChange={handleInputChange}
                          error={errors.rated3PhaseVolts2}
                          disabled={disableStudy}
                          showSkeleton={showskeleton}
                          decimalScale={3}
                          thousandSeparator={true}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <FormNumeric2
                          name="rated3PhaseVolts3"
                          label={``}
                          value={values?.rated3PhaseVolts3}
                          onChange={handleInputChange}
                          error={errors.rated3PhaseVolts3}
                          disabled={disableStudy}
                          showSkeleton={showskeleton}
                          decimalScale={3}
                          thousandSeparator={true}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={1}></Grid>
                  <Grid item xs={5}>
                    <FormNumeric2
                      name="vesselCapacitygallons"
                      label={`Vessel Capacity (${systemOfMeasurement.get(
                        "mbh"
                      )})`}
                      value={values?.vesselCapacitygallons}
                      onChange={handleInputChange}
                      error={errors.vesselCapacitygallons}
                      disabled={disableStudy}
                      showSkeleton={showskeleton}
                      decimalScale={3}
                      thousandSeparator={true}
                    />
                  </Grid>
                  <Grid item xs={1}></Grid>
                  <Grid item xs={5}>
                    <Grid container spacing={5}>
                      <Grid item xs={3}>
                        <Typography>Rated Amps</Typography>
                      </Grid>
                      <Grid item xs={3}>
                        <FormNumeric2
                          name="rated3PhaseAmps1"
                          label={``}
                          value={values?.rated3PhaseAmps1}
                          onChange={handleInputChange}
                          error={errors.rated3PhaseAmps1}
                          disabled={disableStudy}
                          showSkeleton={showskeleton}
                          decimalScale={3}
                          thousandSeparator={true}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <FormNumeric2
                          name="rated3PhaseAmps2"
                          label={``}
                          value={values?.rated3PhaseAmps2}
                          onChange={handleInputChange}
                          error={errors.rated3PhaseAmps2}
                          disabled={disableStudy}
                          showSkeleton={showskeleton}
                          decimalScale={3}
                          thousandSeparator={true}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <FormNumeric2
                          name="rated3PhaseAmps3"
                          label={``}
                          value={values?.rated3PhaseAmps3}
                          onChange={handleInputChange}
                          error={errors.rated3PhaseAmps3}
                          disabled={disableStudy}
                          showSkeleton={showskeleton}
                          decimalScale={3}
                          thousandSeparator={true}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={1}></Grid>
                </Grid>
              </>
            )}
          </Grid>
        </FormTitleBox>
      )}
      {!values?.testStarted && !disableStudy && (
        <Grid container pt={5}>
          <Grid item xs={12} textAlign={"center"}>
            <FormButton
              color="success"
              text={"Start Test"}
              onClick={handleStartModal}
              startIcon={<PlayArrow />}
            ></FormButton>
          </Grid>
        </Grid>
      )}
      <FooterButtons
        activeStep={1}
        stepsCount={steps.length}
        projectId={values?.projectId ?? 0}
        companyId={companyId}
        isSaving={showskeleton}
        disabled={disableStudy}
        reportName="Static Report"
        saveHandler={handleSave}
        nextStepHandler={nextStepHandler}
        previousStepHandler={backStepHandler}
        disableNext={!values?.testStarted}
        isComplete={values?.isComplete ?? true}
      />
      <DialogMessagePopup
        title={"Important"}
        text={
          <Typography>
            <Typography color={"error"} display={"inline"}>
              Before performing Test.{" "}
            </Typography>
            <Typography display={"inline"}>
              Ensure system has been running for 30 minutes and is under full
              load and Steady State.{" "}
            </Typography>
          </Typography>
        }
        showPopup={modalStart}
        setShowPopup={setModalStart}
        onSave={handleStartStudy}
        isSubmitting={showskeleton}
      ></DialogMessagePopup>
    </>
  );
};

export default Step3;
