import * as React from "react";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import { KeyValueList, KeyValueListColor } from "src/ts/interfaces";
import { useEffect } from "react";
import {
  Box,
  FormControl,
  FormLabel,
  Skeleton,
  Typography,
} from "@mui/material";
import { Mode } from "src/ts/types";

function not(arr1: KeyValueList[], arr2: KeyValueList[]): KeyValueList[] {
  return arr1.filter((val1) => {
    const index = arr2.map((v) => v.key).indexOf(val1.key);

    if (index === -1) return val1;

    return null;
  });
}

function intersection(
  arr1: KeyValueList[],
  arr2: KeyValueList[]
): KeyValueList[] {
  return arr1.filter((val1) => {
    return arr2.find((val2) => val1.key === val2.key);
  });
}

interface TransferListProps {
  label: string;
  options: KeyValueListColor[];
  rightInitialKeys: number[];
  onChange: (e: any) => void;
  mode?: Mode;
  disabled?: boolean;
  showSkeleton?: boolean;
  sortById?: boolean;
}

export default function TransferList({
  label,
  options,
  rightInitialKeys,
  onChange,
  mode,
  disabled,
  showSkeleton,
  sortById,
}: TransferListProps) {
  const sortByName = (a: KeyValueListColor, b: KeyValueListColor) => {
    if (a.value && b.value) {
      if (a.value.toUpperCase() < b.value.toUpperCase()) {
        return -1;
      }
      if (a.value.toUpperCase() > b.value.toUpperCase()) {
        return 1;
      }
      return 0;
    }
    return 0;
  };
  const sortsById = (a: KeyValueListColor, b: KeyValueListColor) => {
    if (a.key && b.key) {
      if (a.key < b.key) {
        return -1;
      }
      if (a.key > b.key) {
        return 1;
      }
      return 0;
    }
    return 0;
  };
  const leftInitial = options
    ?.filter(
      (option: KeyValueListColor) => rightInitialKeys.indexOf(option.key) === -1
    )
    .sort(sortById ? sortsById : sortByName);

  const rightInitial = options
    ?.filter(
      (option: KeyValueListColor) => rightInitialKeys.indexOf(option.key) !== -1
    )
    .sort(sortByName);

  const [checked, setChecked] = React.useState<KeyValueListColor[]>([]);
  const [left, setLeft] = React.useState<KeyValueListColor[]>(
    leftInitial ?? []
  );
  const [right, setRight] = React.useState<KeyValueListColor[]>(
    rightInitial ?? []
  );

  useEffect(() => {
    onChange(right);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [right]);

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  const handleToggle = (value: KeyValueListColor) => () => {
    const currentIndex = checked
      .map((item: KeyValueListColor) => item.key)
      .indexOf(value.key);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleAllRight = () => {
    setRight(right.concat(left).sort(sortByName));
    setLeft([]);
    setChecked([]);
  };

  const handleCheckedRight = () => {
    const bb = right.concat(leftChecked);
    setRight(bb.sort(sortByName));
    const rr = not(left, leftChecked);
    setLeft(rr.sort(sortByName));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked).sort(sortByName));
    setRight(not(right, rightChecked).sort(sortByName));
    setChecked(not(checked, rightChecked));
  };

  const handleAllLeft = () => {
    setLeft(left.concat(right).sort(sortByName));
    setRight([]);
    setChecked([]);
  };

  const customList = (items: KeyValueListColor[]) => (
    <Paper
      sx={{ width: 300, height: 230, overflow: "auto" }}
      variant="outlined"
    >
      <List dense={true} component="div" role="list">
        {items?.map((value: KeyValueListColor) => {
          const labelId = `transfer-list-item-${value.key}-label`;

          return (
            <ListItem
              divider={true}
              key={value.key}
              role="listitem"
              button
              onClick={handleToggle(value)}
              disabled={disabled}
            >
              <ListItemIcon>
                <Checkbox
                  checked={checked.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{
                    "aria-labelledby": labelId,
                  }}
                />
              </ListItemIcon>
              <ListItemText
                primaryTypographyProps={{
                  color: value.color,
                }}
                id={value.value}
                primary={value.value}
              />
            </ListItem>
          );
        })}
      </List>
    </Paper>
  );

  if (mode && mode === "read")
    return (
      <Box
        pr={3}
        sx={{
          pb: { xs: 1, md: 0 },
          width: "100%",
        }}
        alignSelf="center"
      >
        <FormLabel>{label}</FormLabel>
        {right.map((item) => (
          <Typography variant="body1">{item.value}</Typography>
        ))}
      </Box>
    );

  return (
    <>
      {showSkeleton ? (
        <Grid container>
          <Grid xs={4}>
            <Skeleton height={400}></Skeleton>
          </Grid>
          <Grid xs={1}></Grid>
          <Grid xs={4}>
            <Skeleton height={400}></Skeleton>
          </Grid>
        </Grid>
      ) : (
        <FormControl>
          <FormLabel>{label}</FormLabel>
          <Grid
            container
            spacing={2}
            justifyContent="center"
            alignItems="center"
          >
            <Grid item>{customList(left)}</Grid>
            <Grid item>
              <Grid container direction="column" alignItems="center">
                <Button
                  sx={{ my: 0.5 }}
                  variant="outlined"
                  size="small"
                  onClick={handleAllRight}
                  disabled={left?.length === 0}
                  aria-label="move all right"
                >
                  ≫
                </Button>
                <Button
                  sx={{ my: 0.5 }}
                  variant="outlined"
                  size="small"
                  onClick={handleCheckedRight}
                  disabled={leftChecked.length === 0}
                  aria-label="move selected right"
                >
                  &gt;
                </Button>
                <Button
                  sx={{ my: 0.5 }}
                  variant="outlined"
                  size="small"
                  onClick={handleCheckedLeft}
                  disabled={rightChecked.length === 0}
                  aria-label="move selected left"
                >
                  &lt;
                </Button>
                <Button
                  sx={{ my: 0.5 }}
                  variant="outlined"
                  size="small"
                  onClick={handleAllLeft}
                  disabled={right?.length === 0}
                  aria-label="move all left"
                >
                  ≪
                </Button>
              </Grid>
            </Grid>
            <Grid item>{customList(right)}</Grid>
          </Grid>
        </FormControl>
      )}
    </>
  );
}
