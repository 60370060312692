import Popup from "src/components/Popup";
import { FormButton } from "src/components/formControls";
import { Stack, Typography } from "@mui/material";
import { IAssets } from "src/ts/interfaces/companyAssets";

interface AssigmentProcessDecisionProps {
  assigmentProcessDecisionShowPopUp: boolean;
  setAssigmentProcessDecisionShowPopUp: (val: boolean) => void;
  setShowAssignTransfer: (val: boolean) => void;
  currentRow: IAssets | undefined;
  titleName: string;
}

export default function AssigmentProcessDecisionPopUp({
  assigmentProcessDecisionShowPopUp,
  setAssigmentProcessDecisionShowPopUp,
  currentRow,
  titleName,
  setShowAssignTransfer,
}: AssigmentProcessDecisionProps) {
  const titleText = `  The ${titleName} has an open assignment process and is awaiting a
  decision from ${
    currentRow?.requestTransfer?.transferTo?.name === undefined
      ? ""
      : currentRow?.requestTransfer?.transferTo?.name +
        " " +
        currentRow?.requestTransfer?.transferTo?.lastName
  } ${
    currentRow?.requestTransfer !== null &&
    currentRow?.requestTransfer?.transferTo === null
      ? "Office Shop"
      : ""
  }. If you confirm this transfer request, the current one will be
  canceled.`;

  return (
    <>
      <Popup
        title={"Information"}
        openPopup={assigmentProcessDecisionShowPopUp}
        setOpenPopup={setAssigmentProcessDecisionShowPopUp}
        onClose={() => {
          setAssigmentProcessDecisionShowPopUp(false);
        }}
      >
        <Stack>
          <Typography>{titleText}</Typography>
        </Stack>

        <Stack
          direction="row"
          spacing={2}
          justifyContent="flex-end"
          alignItems="flex-start"
        >
          <FormButton
            text="No, cancel request "
            size="medium"
            variant="outlined"
            onClick={() => {
              setAssigmentProcessDecisionShowPopUp(false);
            }}
          />
          <FormButton
            text="Yes, proceed "
            size="medium"
            color="primary"
            onClick={() => {
              setShowAssignTransfer(true);
              setAssigmentProcessDecisionShowPopUp(false);
            }}
          />
        </Stack>
      </Popup>
    </>
  );
}
