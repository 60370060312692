import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { useParams } from "react-router-dom";
import {
  Grid,
  Divider as MuiDivider,
  Typography,
  Card as MuiCard,
  CardContent,
  Stack,
  Button,
  Tabs,
  Tab,
  Chip,
} from "@mui/material";
import { spacing } from "@mui/system";
import { ColumnType } from "src/types/enhancedTable";
import { useForm, useLog } from "src/hooks";
import {
  FormButton,
  FormCancelButton,
  FormTextArea,
} from "src/components/formControls";
import LocalEnhancedTable from "src/components/localTable/LocalTable";
import useAsyncQuery from "src/hooks/useAsyncQuery";
import { GridActionButton } from "src/components/gridControls";
import { ITeamMembersTechnician } from "src/ts/interfaces/teamMembers";
import Popup from "src/components/Popup";
import MatchWordConfirmModal from "src/components/modals/MatchWordConfirmModal";
import teamMemberServices from "src/services/teamMemberServices";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import RemoveCircleOutlineOutlinedIcon from "@mui/icons-material/RemoveCircleOutlineOutlined";
import AddIcon from "@mui/icons-material/Add";
import MembersForm from "./components/MembersForm";
import HeaderPage from "src/components/page/HeaderPage";
import { Validator } from "src/ts/types";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const initialValues = {
  comment: "",
};

const TeamMembers = () => {
  let { companyId } = useParams<{ companyId: string }>();
  const { execute, data, isLoading, setData } = useAsyncQuery<
    ITeamMembersTechnician[]
  >(teamMemberServices.getTeamMember);

  const [showDisableModal, setShowDisableModal] = useState(false);
  const [userId, setUserId] = useState(0);
  const [isActive, setIsActive] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [selectdTab, setSelectedTab] = useState(0);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [currentRow, setCurrentRow] = useState<ITeamMembersTechnician>();
  const [deleteModal, setDeleteModal] = useState(false);
  const { log } = useLog();

  const validate = (fieldValues = filterValues) => {
    let temp: Record<string, string> = { ...errors };

    temp.comment = new Validator(fieldValues, "comment")
      .isRequired("Comments are required")
      ?.toString();

    setErrors({
      ...temp,
    });

    if (fieldValues === filterValues)
      return Object.values(temp).every((x) => x === "");
  };

  const {
    values: filterValues,
    handleInputChange: handleFilterChange,
    setValues,
    errors,
    setErrors,
  } = useForm(initialValues, true, validate);

  useEffect(() => {
    const getData = async () => {
      setData([]);
      execute(isActive, companyId);
    };

    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActive, refresh]);

  const handleSaveDeactivate = async () => {
    try {
      if (!validate()) return;

      await teamMemberServices.updateTeamMemberData({
        ...currentRow!,
        comment: filterValues.coment,
        isDisabled: true,
      });
      setShowDisableModal(false);
      setRefresh(!refresh);
      setValues(initialValues);
    } catch (error: any) {
      log.error(error?.message?.exceptionMessage ?? "Something went wrong");
    }
  };

  const handleActivated = async (row: ITeamMembersTechnician) => {
    try {
      await teamMemberServices.updateTeamMemberData({
        ...row,
        comment: "",
        isDisabled: false,
      });
      setRefresh(!refresh);
    } catch (error: any) {
      log.error(error?.message?.exceptionMessage ?? "Something went wrong");
    }
  };

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
    setIsActive(newValue === 0 ? false : true);
  };

  const deleteHandler = async () => {
    if (currentRow !== undefined && currentRow?.id > 0) {
      try {
        await teamMemberServices.deleteTeamMember(currentRow?.id);
        log.success("The team member was deleted");
        setRefresh(!refresh);
        setDeleteModal(false);
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      }
    }
  };

  const sendNotification = async (row: ITeamMembersTechnician) => {
    try {
      await teamMemberServices.notificationTeamMember(row.id);
      log.info("The email was sent");
    } catch (error: any) {
      log.error(error?.message?.exceptionMessage ?? "Something went wrong");
    }
  };

  const columns: ColumnType[] = [
    {
      id: "id",
      label: "ID",
      type: "string",
      sort: true,
      hide: true,
    },
    {
      id: "companyTitle",
      label: "Company",
      type: "string",
      sort: true,
    },
    {
      id: "name",
      label: "First Name",
      type: "string",
      sort: true,
    },
    {
      id: "lastName",
      label: "Last Name",
      type: "string",
      sort: true,
    },
    {
      id: "email",
      label: "Email",
      type: "string",
      sort: true,
    },
    {
      id: "createdDate",
      label: "Created Date",
      type: "utcDate",
      sort: true,
      format: "MM/DD/yyyy h:mma",
    },
    {
      id: "request",
      label: "Request Status",
      type: "custom",
      sort: false,
      align: "center",
      callback: (row: ITeamMembersTechnician) => {
        return (
          <div style={{ textAlign: "center" }}>
            {!row.isTermsAndConditionsAccepted && !row.isUnsubscribed && (
              <Chip
                icon={<AccessTimeOutlinedIcon />}
                label="Pending confirmation"
                size="small"
              />
            )}
            {row.isTermsAndConditionsAccepted && (
              <Chip
                color="success"
                icon={<CheckCircleOutlineOutlinedIcon />}
                label="Confirmed"
                size="small"
              />
            )}
            {row.isUnsubscribed && (
              <Chip
                color="error"
                icon={<RemoveCircleOutlineOutlinedIcon />}
                label="Unsubscribed"
                size="small"
              />
            )}
          </div>
        );
      },
    },
    {
      id: "actions",
      label: "Actions",
      type: "custom",
      sort: false,
      align: "center",
      width: "130px",
      callback: (row: ITeamMembersTechnician) => {
        return (
          <div style={{ textAlign: "center" }}>
            <Stack direction="row">
              <GridActionButton
                type="edit"
                onClick={() => {
                  setUserId(row.id);
                  setIsDialogOpen(true);
                }}
                tooltip="Edit"
              />
              {!row.isUnsubscribed &&
                !isActive &&
                !row.isTermsAndConditionsAccepted && (
                  <>
                    <GridActionButton
                      type="email"
                      onClick={() => {
                        sendNotification(row);
                      }}
                      tooltip="Send notification"
                    />
                  </>
                )}

              <GridActionButton
                type={!isActive ? "disable" : "enable"}
                onClick={() => {
                  setCurrentRow(row);
                  if (!isActive) {
                    setShowDisableModal(true);
                  } else {
                    setCurrentRow(row);
                    handleActivated(row);
                  }
                }}
                tooltip="Disable"
              />
              {!row.isUnsubscribed && (
                <>
                  <GridActionButton
                    type="delete"
                    onClick={() => {
                      setCurrentRow(row);
                      setDeleteModal(true);
                    }}
                    tooltip="Delete"
                  />
                </>
              )}
            </Stack>
          </div>
        );
      },
    },
  ];

  return (
    <>
      <HeaderPage
        title={"Team Members"}
        parentText={"Dashboard"}
        parentLink={`/`}
        actionSection={
          <FormButton
            startIcon={<AddIcon />}
            text={"Add Team Member"}
            onClick={() => {
              setUserId(0);
              setIsDialogOpen(true);
            }}
            size="medium"
            color="primary"
          />
        }
      ></HeaderPage>
      <Divider my={6} />
      <Card mb={6}>
        <CardContent>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Stack direction="row">
                <Grid item xs={6}>
                  <Typography>
                    <b>
                      Team Members are specifically chosen from this data-bank
                      and invited at a projects level
                    </b>
                  </Typography>
                </Grid>
              </Stack>
            </Grid>
            <Grid
              container
              spacing={10}
              sx={{ borderBottom: 1, borderColor: "divider", marginBottom: 5 }}
            >
              <Grid item xs={6} ml={4}>
                <Tabs
                  value={selectdTab}
                  onChange={handleChangeTab}
                  aria-label="basic tabs example"
                >
                  <Tab label="Active" id="0" />
                  <Tab label="Inactive" id="1" />
                </Tabs>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <LocalEnhancedTable<ITeamMembersTechnician>
                refreshGrid={true}
                columns={columns}
                data={data}
                showSkeleton={isLoading}
                defaultRowPerPage={5}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Popup
        title="Information"
        openPopup={showDisableModal}
        setOpenPopup={setShowDisableModal}
        onClose={() => {
          setShowDisableModal(false);
        }}
        size="sm"
      >
        <>
          <Stack mb={4}>
            <Typography>Reason(s) for disabling this team member:</Typography>
          </Stack>
          <FormTextArea
            name="comment"
            label={""}
            value={filterValues.comment}
            onChange={handleFilterChange}
            error={errors.comment}
            placeholder={
              "Be descriptive when entering reason(s) for disabling this team member "
            }
          />
        </>
        <Stack direction="row" spacing={3}>
          <FormCancelButton
            onClick={() => {
              setValues(initialValues);
              setShowDisableModal(false);
            }}
            isSubmitting={false}
          />
          <Button
            type="submit"
            variant="contained"
            color="primary"
            onClick={handleSaveDeactivate}
          >
            Accept
          </Button>
        </Stack>
      </Popup>

      <MatchWordConfirmModal
        onConfirm={deleteHandler}
        text={`Are you certain you want to delete team member: ${currentRow?.name} ${currentRow?.lastName}?`}
        matchWord={"delete"}
        isDialogOpen={deleteModal}
        setIsDialogOpen={setDeleteModal}
      />
      {isDialogOpen && (
        <MembersForm
          isDialogOpen={isDialogOpen}
          setIsDialogOpen={setIsDialogOpen}
          memberId={userId}
          companyId={Number(companyId)}
          setRefresh={setRefresh}
          refresh={refresh}
        />
      )}
    </>
  );
};

export default TeamMembers;
