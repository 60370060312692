import axios from "../utils/axios";

const sendStartWorkingNotification = async (projectId: number) => {
  return axios.post(`TechniciansApp/SendStartWorkingNotification/${projectId}`);
};

const techniciansAppService = {
  sendStartWorkingNotification,
};

export default techniciansAppService;
