import {
  CardContent,
  Grid,
  Divider as MuiDivider,
  Card as MuiCard,
  Typography,
} from "@mui/material";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import {
  useAsyncQuery,
  useAuth,
  useEffectOnce,
  useFormulas,
  useLog,
  usePsychrometric,
  useSystemOfMeasurement,
} from "src/hooks";
import { spacing } from "@mui/system";
import styled from "@emotion/styled";
import { steps } from "../const/const";
import {
  FormCheckSwitch,
  FormNumeric2,
  FormText,
} from "src/components/formControls";
import useOutletReport from "../hooks/useOutletReport";
import { IProjectReport } from "src/ts/interfaces/project/projectDto";
import {
  IOutletReport,
  IOutletReportComponent,
  IOutletReportCustomParameters,
  IPsychrometricArgs,
  IReportType,
} from "src/ts/interfaces";
import projectReportService from "src/services/study/projectReportService";
import useFormTyped from "src/hooks/useFormTyped";
import { useMemo, useState } from "react";
import { Mode } from "src/ts/types";
import { getCode, getStudyCode, initialValues } from "../util/util";
import outletReportService from "src/services/study/outletReportService";
import reportTypeService from "src/services/study/reportTypeService";
import outletReportComponentService from "src/services/study/outletReportComponentService";
import ComponentList from "../componets/ComponentList";
import OutletReportTransposeGrid from "./components/OutletReportTransposeGrid";
import ActualVelocityAlertPopup from "../../components/ActualVelocityAlertPopup";
import ActualItemsVelocityAlertPopup from "../../components/ActualItemsVelocityAlertPopup";
import studiesHistoryService from "src/services/study/studiesHistoryService";
import outletReportCustomParametersService from "src/services/study/outletReportCustomParametersService";
import outletReportItemService from "src/services/study/outletReportItemService";
import ComponentForm from "./components/ComponentForm";
import OutletReportParameters from "./components/OutletReportParameters";
import StudyWizard from "../../components/StudyWizard";
import projectService from "src/services/project/projectService";
import elevationCorrectedService from "src/services/elevationCorrectedService";
import { zipCodeService } from "src/services";
import SteadyStateDialog from "../../components/SteadyState";
import { FooterButtons } from "../../components/FooterButtons";
import HeaderStudyPage from "src/components/page/HeaderStudyPage";

const Divider = styled(MuiDivider)(spacing);
const Card = styled(MuiCard)(spacing);
const row = 12;

const OutletStep3 = () => {
  const params = useParams<{ id: string }>();
  const id = parseInt(params?.id === undefined ? "0" : params?.id);
  let navigate = useNavigate();
  const location = useLocation();
  const { user } = useAuth();
  const { log } = useLog();
  const { formulaSystem } = useFormulas();
  const { systemOfMeasurement } = useSystemOfMeasurement(true);
  const {
    setOutletReportComponentId,
    setComponentValues,
    items,
    getAllOutletReportItems,
    allOutletReportItems,
    componentValues,
  } = useOutletReport();
  const [disableEditData, setDisableEditData] = useState(false);

  const { psychrometric } = usePsychrometric();

  const { execute: executeReport, data: dataReport } =
    useAsyncQuery<IProjectReport>(projectReportService.report);

  const [reportType, setReportType] = useState<IReportType | null>();
  const [components, setComponents] = useState<IOutletReportComponent[]>([]);

  const validate: any = (fieldValues = values) => {
    let temp: Record<string, string> = { ...errors };
    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange } =
    useFormTyped<IOutletReport>(initialValues, false, validate);

  useEffectOnce(() => {
    const getData = async () => {
      const outletReportResponse = await outletReportService.getById(id);

      let localBarometricPressure =
        outletReportResponse.data.localBarometricPressure;

      if (outletReportResponse.data.localBarometricPressure == null) {
        localBarometricPressure = await getLocalBarometricPressure(
          outletReportResponse.data.projectId
        );
      }

      setValues({
        ...outletReportResponse.data,
        localBarometricPressure: localBarometricPressure,
      });

      const studyType = getStudyCode(location.pathname);

      const reportTypeRes = await reportTypeService.getReportTypeByCode(
        studyType
      );

      setReportType(reportTypeRes.data);
      await executeReport(id, reportTypeRes.data.code);

      const componentsRes = await outletReportComponentService.report(id);
      setComponents(componentsRes.data);
      if (componentsRes.data.length > 0) {
        setOutletReportComponentId(componentsRes.data[0].id);
      }

      await systemOfMeasurement.getByProject(
        outletReportResponse.data.projectId
      );

      await getAllOutletReportItems(id);
    };

    if (id > 0) getData();
  });

  const setOutletReport = (outletReport: IOutletReport) => {
    setValues(outletReport);
  };

  const getLocalBarometricPressure = async (projectId: number) => {
    try {
      const zipCodeResponse = await projectService.getZipCode(projectId);

      const elevationResponse = await zipCodeService.getElevation(
        zipCodeResponse.data
      );

      const elevationCorrectedResponse =
        await elevationCorrectedService.getById(elevationResponse.data);

      const formulas = await formulaSystem.setSystem(projectId);
      const localBarometricPressure = formulas.InHgToKPa(
        elevationCorrectedResponse.data.atmosphericPressure
      );
      return localBarometricPressure;
    } catch (error: any) {
      log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      return null;
    }
  };

  const disableStudy = useMemo(() => {
    return (
      values?.isComplete ||
      (dataReport?.isInUse && dataReport?.isInUseById !== user?.userId) ||
      dataReport?.isPartOfProject === false ||
      disableEditData
    );
  }, [
    dataReport?.isInUse,
    dataReport?.isInUseById,
    dataReport?.isPartOfProject,
    user?.userId,
    values?.isComplete,
    disableEditData,
  ]);

  const mode: Mode = values.isComplete ? "read" : "read&Write";

  const velocityLimit =
    systemOfMeasurement.measurementSystem === "metricSystem" ? 12.7 : 2500;

  const nextStepHandler = async () => {
    if (mode === "read&Write" && !disableStudy) {
      const showVelocityAlert = components.some(
        (comp) =>
          comp?.actualInletVelocity != null &&
          comp?.actualInletVelocity > velocityLimit
      );

      if (showVelocityAlert) {
        setShowActualVelocityAlert(true);
      } else {
        const showItemVelocityAlert = allOutletReportItems.some(
          (item) =>
            item?.neckActualVelocity !== null && item?.neckActualVelocity > 1000
        );
        if (showItemVelocityAlert) {
          setShowActualItemsVelocityAlert(true);
        } else {
          continueGotoStep();
        }
      }
    } else {
      navigateToNextPage();
    }
  };

  const previousStepHandler = async () => {
    if (mode === "read&Write" && !disableStudy) {
      await saveHandler();
    }

    const route = `/studies/${getCode(location.pathname)}/step2/${id}`;
    navigate(route);
  };

  const useSpecificDefaultHandler = async (e: any) => {
    if (values.isComplete) return;

    const useSiteSpecificDefaultBool = e.target.value;

    if (useSiteSpecificDefaultBool) {
      const psychometricsArgs: IPsychrometricArgs = {
        projectId: values.projectId,
        humidityMeasuredAs: "RH",
        humidityValue: 50,
        dryBulbF:
          systemOfMeasurement.measurementSystem === "imperialSystem"
            ? 70
            : 21.11,
        totalCFM: 0,
      };

      const psychrometricRes = await psychrometric.getByProject(
        psychometricsArgs
      );

      if (
        psychrometricRes.psychrometric.message != null &&
        psychrometricRes.psychrometric.enthalpy === 0
      ) {
        log.warning(psychrometricRes.psychrometric.message);
      } else if (psychrometricRes.psychrometric.message != null) {
        log.info(psychrometricRes.psychrometric.message);
      }
      const useSiteSpecificDefault =
        psychrometricRes.psychrometric.atmosphericPress;
      setValues({
        ...values,
        useSiteSpecificDefault,
        useSiteSpecificDefaultBool,
      });
    } else {
      setValues({
        ...values,
        useSiteSpecificDefaultBool,
      });
    }
  };

  const UpdateBarometricPresureHandler = async () => {
    // await outletMasterService.update(values.id, values);
    // const res = await outletMasterItemsService.updateAll(items);
    // const orderedItems: IOutletMasterItem[] = res.data.map((item, index) => ({
    //   ...item,
    //   order: index,
    // }));
    // temporalItems = orderedItems;
    // let index = 0;
    // calcBTUHExtraRepeat(temporalItems[index], index);
    // const resLowestGriller = await outletMasterService.getLowesGriller(id);
    // setValues({
    //   ...resLowestGriller.data,
    //   useSiteSpecificDefault: lastAtmosphericPress,
    // });
    // setItems(temporalItems);
  };

  const setComponentHandler = (components: IOutletReportComponent[]) => {
    setComponents(components);
  };

  const [showActualVelocityAlert, setShowActualVelocityAlert] = useState(false);
  const [showActualItemsVelocityAlert, setShowActualItemsVelocityAlert] =
    useState(false);
  const confirmContinueHandler = () => {
    const showItemVelocityAlert = allOutletReportItems.some(
      (item) =>
        item?.neckActualVelocity !== null && item?.neckActualVelocity > 1000
    );

    setShowActualVelocityAlert(false);
    if (showItemVelocityAlert) setShowActualItemsVelocityAlert(true);
    else continueGotoStep();
  };

  const [custom, setCustom] = useState<IOutletReportCustomParameters[]>([]);
  const setCustomParameters = (
    customParameters: IOutletReportCustomParameters[]
  ) => {
    setCustom(customParameters);
  };

  const continueGotoStep = async () => {
    await saveHandler();
    navigateToNextPage();
  };

  const navigateToNextPage = () => {
    const route = `/studies/${getCode(location.pathname)}/step5/${id}`;
    navigate(route);
  };

  const saveHandler = async () => {
    try {
      await Promise.all([
        outletReportService.update(values.id, values),
        outletReportComponentService.updateAll(components),
        outletReportCustomParametersService.updateAllCustomParameterss(custom),
        outletReportItemService.updateAllItems(items),
        studiesHistoryService.save(
          values.projectId,
          dataReport.reportTypeId,
          values.id,
          "saved"
        ),
      ]);

      log.info("Report was updated successfully");
      const res = await outletReportComponentService.getById(
        componentValues.id
      );
      setComponentValues(res.data);
    } catch (error: any) {
      log.error(error?.message?.exceptionMessage ?? "Something went wrong");
    }
  };

  const addDefaultParametersHandler = () => {
    components.forEach((component) => {
      if (component.id !== componentValues.id) {
        custom.forEach(async (customParameter) => {
          try {
            const outletReportCustomParameters: any = {
              outletReportComponentId: component.id,
              outletReportCustomNameParametersId:
                customParameter.outletReportCustomNameParametersId,
              order: customParameter.order,
            };

            if (customParameter.outletReportCustomNameParametersId === 50) {
              outletReportCustomParameters.name = customParameter.name;
            }

            await outletReportCustomParametersService.add(
              component.id,
              outletReportCustomParameters
            );
          } catch (error: any) {
            log.error(
              error?.message?.exceptionMessage ?? "Something went wrong"
            );
          }
        });
      }
    });
    log.info("All selected parameters are added to associated fan VAV systems");
  };

  const isLoading = false;
  const activeStep = 2;
  return (
    <>
      <HeaderStudyPage
        headerStudiesPage={{
          code: reportType?.code,
          system: values?.systemField,
          id: id,
        }}
        parentText="Project"
        parentLink="/"
      />
      <Divider my={6} />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <StudyWizard
            steps={steps}
            activeStep={activeStep}
            projectId={values?.projectId}
            reportId={dataReport?.reportId}
            reportTypeId={dataReport?.reportTypeId}
            code={getCode(location.pathname)}
          />
        </Grid>
        <Grid item xs={12}>
          <Card mb={1}>
            <CardContent>
              <Grid container spacing={5}>
                <Grid item xs={6}>
                  <FormText
                    name="projectName"
                    label="Project Name"
                    value={values.projectName}
                    onChange={handleInputChange}
                    error={errors.projectName}
                    showSkeleton={isLoading}
                    mode={"read"}
                    disabled={disableStudy}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormText
                    name="systemField"
                    label="System"
                    value={values.systemField}
                    onChange={handleInputChange}
                    error={errors.systemField}
                    showSkeleton={isLoading}
                    mode={"read"}
                    disabled={disableStudy}
                    maxLength={50}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormText
                    name="description"
                    label="Description"
                    value={values.description}
                    onChange={handleInputChange}
                    error={errors.description}
                    showSkeleton={isLoading}
                    mode={"read"}
                    disabled={disableStudy}
                  />
                </Grid>
                <Grid item xs={row}></Grid>
                <Grid item xs={row}>
                  <Typography variant="h6">Barometric Pressure</Typography>
                </Grid>
                <Grid item xs={4}>
                  <FormCheckSwitch
                    label="Use Site Specific Default"
                    name="useSiteSpecificDefaultBool"
                    value={values.useSiteSpecificDefaultBool}
                    onChange={useSpecificDefaultHandler}
                    disabled={disableStudy}
                    mode={mode}
                    showSkeleton={isLoading}
                  />
                </Grid>
                <Grid item xs={6}>
                  {values.useSiteSpecificDefaultBool ? (
                    <FormNumeric2
                      name="useSiteSpecificDefault"
                      label="Local Barometric Pressure Default"
                      value={values?.useSiteSpecificDefault?.toString() || ""}
                      onChange={handleInputChange}
                      error={errors.useSiteSpecificDefault}
                      showSkeleton={isLoading}
                      disabled={true}
                      mode={mode}
                      maxValue={1000000000}
                      decimalScale={3}
                      thousandSeparator={true}
                    />
                  ) : (
                    <FormNumeric2
                      name="localBarometricPressure"
                      label="Actual Barometric Pressure"
                      value={values?.localBarometricPressure?.toString() || ""}
                      onChange={handleInputChange}
                      error={errors.localBarometricPressure}
                      showSkeleton={isLoading}
                      disabled={values.barometricalyCompensated || disableStudy}
                      onBlur={UpdateBarometricPresureHandler}
                      mode={mode}
                      maxValue={1000000000}
                      decimalScale={3}
                      thousandSeparator={true}
                    />
                  )}
                </Grid>
                <Grid item xs={12}>
                  <ComponentList
                    components={components}
                    setComponentHandler={setComponentHandler}
                    outletReport={values}
                    showActions={false}
                    updateStudy={saveHandler}
                  />
                </Grid>
                <Grid item xs={12}>
                  <OutletReportTransposeGrid
                    outletReport={values}
                    dataReport={dataReport}
                    setOutletReport={setOutletReport}
                    setComponents={setComponentHandler}
                    components={components}
                    disableStudy={disableStudy}
                  />
                </Grid>
                <Grid item xs={12}>
                  <ComponentForm
                    updateStudyHandler={saveHandler}
                    mode={mode}
                    setComponentHandler={setComponentHandler}
                    components={components}
                    disableStudy={disableStudy}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h6">Parameters</Typography>
                </Grid>
                <Grid item xs={12}>
                  <OutletReportParameters
                    reportTypeCode={reportType?.code ?? ""}
                    systemOfMeasurement={systemOfMeasurement?.measurementSystem}
                    custom={custom}
                    setCustom={setCustomParameters}
                    addDefaultParameters={addDefaultParametersHandler}
                    mode={mode}
                    studyIsDisabled={disableStudy}
                  />
                </Grid>

                <Grid item xs={12}>
                  <FooterButtons
                    activeStep={activeStep}
                    stepsCount={steps.length}
                    projectId={values?.projectId}
                    companyId={user?.companyId as number}
                    isSaving={false}
                    disabled={false}
                    reportName={reportType?.name ?? ""}
                    previousStepHandler={previousStepHandler}
                    saveHandler={saveHandler}
                    hideSaveButton={disableStudy}
                    nextStepHandler={nextStepHandler}
                    isComplete={values?.isComplete ?? true}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <ActualVelocityAlertPopup
        isDialogOpen={showActualVelocityAlert}
        setIsDialogOpen={setShowActualVelocityAlert}
        onConfirm={confirmContinueHandler}
        components={components.filter(
          (comp) =>
            comp?.actualInletVelocity !== null &&
            comp?.actualInletVelocity > velocityLimit
        )}
      />
      <ActualItemsVelocityAlertPopup
        isDialogOpen={showActualItemsVelocityAlert}
        setIsDialogOpen={setShowActualItemsVelocityAlert}
        onConfirm={continueGotoStep}
        allOutletReportItems={allOutletReportItems.filter(
          (item) =>
            item?.neckActualVelocity !== null && item?.neckActualVelocity > 1000
        )}
      />
      {values && values?.projectId && (
        <SteadyStateDialog
          reportId={values?.id}
          reportTypeId={values?.reportTypeId as number}
          projectId={values?.projectId}
          reportCompleted={values?.isComplete}
          setDisableEditData={setDisableEditData}
        />
      )}
    </>
  );
};

export default OutletStep3;
