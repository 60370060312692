import { SysDocuments, IUploadUserFile, SendDocument } from "../ts/interfaces";
import axios from "../utils/axios";

const getAllUserDocuments = (userId: number) => {
  return axios.get<Array<SysDocuments>>(
    `/UserDocument/documents?userId=${userId}`
  );
};

const uploadUserFile = async (file: IUploadUserFile) => {
  let bodyFormData = new FormData();
  bodyFormData.append("fileForUpload[0]", file.content);
  bodyFormData.append("key[type]", file.documentType);
  bodyFormData.append("key[userId]", file.userId ?? "null");
  bodyFormData.append("key[technicianId]", file.technicianId);

  return await axios({
    method: "post",
    url: "/UserDocument/document",
    data: bodyFormData,
    headers: { "Content-Type": "multipart/form-data" },
  });
};

const deleteUserFile = (userId: string) => {
  return axios.delete(`UserDocument?id=${userId}`);
};

const downloadUserFile = (documentId: number) => {
  return axios.get(`UserDocument/file?id=${documentId}`);
};

const sendEmailDocuments = (data: SendDocument) => {
  return axios.post(`UserDocument/SendDocuments`, data);
};
const postVerifyDocument = (userId: number, technicianId: number) => {
  return axios.post(
    `UserDocument/verifyDocuments?userId=${userId}&technicianId=${technicianId}`
  );
};

const userDocumentService = {
  getAllUserDocuments,
  uploadUserFile,
  deleteUserFile,
  downloadUserFile,
  sendEmailDocuments,
  postVerifyDocument,
};

export default userDocumentService;
