import { OptionsObject, useSnackbar } from "notistack";
import { Zoom } from "@mui/material";

function useLog() {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const success = (message: string, options?: OptionsObject) => {
    const key = enqueueSnackbar(message, {
      ...options,
      persist: false,
      variant: "success",
      disableWindowBlurListener: true,
      TransitionComponent: Zoom,
      onClick: () => {
        closeSnackbar(key);
      },
    });
  };

  const error = (message: string, options?: OptionsObject) => {
    enqueueSnackbar(message, {
      ...options,
      persist: false,
      variant: "error",
      disableWindowBlurListener: true,
      TransitionComponent: Zoom,
    });
  };

  const info = (message: string, options?: OptionsObject) => {
    enqueueSnackbar(message, {
      ...options,
      variant: "info",
      persist: false,
      disableWindowBlurListener: true,
      TransitionComponent: Zoom,
    });
  };

  const warning = (message: string, options?: OptionsObject) => {
    enqueueSnackbar(message, {
      ...options,
      variant: "warning",
      persist: false,
      disableWindowBlurListener: true,
      TransitionComponent: Zoom,
    });
  };

  const message = (message: string, options?: OptionsObject) => {
    enqueueSnackbar(message, {
      ...options,
      variant: "default",
      persist: false,
      disableWindowBlurListener: true,
      TransitionComponent: Zoom,
    });
  };

  const log = {
    success,
    error,
    info,
    warning,
    message,
  };

  return { log };
}

export default useLog;
