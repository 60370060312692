import axios from "src/utils/axios";
import { IPumpStarterDTO } from "src/ts/interfaces";

const getById = (id: number) => {
  return axios.get<IPumpStarterDTO[]>(`PumpStarter/${id}`);
};

const update = (id: number, pumpStarter: IPumpStarterDTO) => {
  return axios.put<IPumpStarterDTO[]>(`PumpStarter/${id}`, pumpStarter);
};

const pumpStarterService = {
  getById,
  update,
};

export default pumpStarterService;
