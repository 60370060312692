import Popup from "src/components/Popup";
import {
  FormButton,
  FormDatePicker,
  FormSelect,
} from "src/components/formControls";
import { Grid, Stack, Typography } from "@mui/material";
import { IScheduleModal } from "src/ts/interfaces/technician";
import { useForm, useLog } from "src/hooks";
import { useEffect, useState } from "react";
import moment from "moment";
import technicianService from "src/services/technicianService";
import technicianQuizService from "src/services/technicianQuizService";
import { IKeyValue } from "src/ts/interfaces";
import { Validator } from "src/ts/types/Validator";

interface TrainingDateProps {
  showTraningModal: boolean;
  setShowTraningModal: (val: boolean) => void;
  IScheduleModal: IScheduleModal;
  setRefreshPage: (val: boolean) => void;
  refreshPage: boolean;
}

const initialValues = {
  dateSelected: null,
  selectTypeTest: -1,
};

export default function TrainingDate({
  showTraningModal,
  setShowTraningModal,
  IScheduleModal,
  setRefreshPage,
  refreshPage,
}: TrainingDateProps) {
  const { log } = useLog();

  const [dataType, setDataType] = useState<IKeyValue<number, string>[]>([]);

  const validate = (fieldValues = values) => {
    let temp: Record<string, string> = { ...errors };

    temp.selectTypeTest = new Validator(fieldValues, "selectTypeTest")
      .selectedOption(-1, "The test is required.")
      .toString();

    temp.dateSelected =
      fieldValues.dateSelected === "Invalid date"
        ? "The date is required."
        : "";

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };
  const { values, setValues, handleInputChange, errors, setErrors } = useForm(
    initialValues,
    true,
    validate
  );
  useEffect(() => {
    const getData = async () => {
      try {
        const response = await technicianQuizService.getAvailable();

        const filterResponse = response.data
          .filter((item) => {
            return IScheduleModal.selectType === item.type;
          })
          .map((item) => ({ key: item.id, value: item.title }));
        setDataType(filterResponse);

        setValues({
          ...values,
          selectTypeTest:
            IScheduleModal.quizId === 0 ? -1 : IScheduleModal.quizId,
          dateSelected:
            moment(IScheduleModal.startDate).format("MM/DD/YYYY") ?? "",
        });
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      }
    };

    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlerAccept = async () => {
    if (!validate()) return;
    try {
      await technicianService.updateTrainingDate(
        IScheduleModal.technicianId ?? 0,
        values.dateSelected,
        values.selectTypeTest ?? 0
      );
      setShowTraningModal(false);
      setRefreshPage(!refreshPage);
    } catch (error: any) {
      log.error(error?.message?.exceptionMessage ?? "Something went wrong");
    }
  };

  return (
    <>
      <Popup
        title={IScheduleModal.dateModalTitle ?? ""}
        openPopup={showTraningModal}
        setOpenPopup={setShowTraningModal}
        onClose={() => {
          setShowTraningModal(false);
        }}
        size="sm"
      >
        <>
          <Grid container>
            <Grid item xs={12}>
              <Stack direction="row" spacing={2}>
                <Grid item xs={6}>
                  {" "}
                  <Typography>{IScheduleModal.dateModalText ?? ""}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <FormDatePicker
                    label=""
                    name="dateSelected"
                    onChange={handleInputChange}
                    value={values.dateSelected}
                    disablePast={true}
                    readOnlyText={IScheduleModal.passed}
                    error={errors.dateSelected}
                  />
                </Grid>
              </Stack>
            </Grid>
            <Grid item xs={12} mt={5}>
              <Stack direction="row" spacing={2}>
                <Grid item xs={6}>
                  {" "}
                  <Typography>Select Test</Typography>
                </Grid>
                <Grid item xs={6}>
                  <FormSelect
                    name="selectTypeTest"
                    value={values.selectTypeTest}
                    onChange={handleInputChange}
                    options={dataType}
                    label=""
                    readOnly={IScheduleModal.passed}
                    error={errors.selectTypeTest}
                    defaultValue={{
                      key: -1,
                      value: "Select a test",
                    }}
                  />
                </Grid>
              </Stack>
            </Grid>
            <Grid item xs={12} mt={5}>
              <Typography>{IScheduleModal.dateModalNotes}</Typography>
            </Grid>
          </Grid>
        </>
        <Stack direction="row" spacing={3}>
          <FormButton
            size="small"
            text="Close"
            variant="outlined"
            onClick={() => {
              setShowTraningModal(false);
            }}
          />
          <FormButton
            size="small"
            text="Accept"
            color="primary"
            onClick={() => {
              handlerAccept();
            }}
            disabled={IScheduleModal.passed}
          />
        </Stack>
      </Popup>
    </>
  );
}
