import axios from "../utils/axios";
import { ISelectedUsers } from "src/ts/interfaces";

const sendEmailProject = async (users: ISelectedUsers[], projectId: number) => {
  return axios.post(
    `ProjectEmails/project/${projectId}/forwardProjectEmailsTeam`,
    users
  );
};

const projectEmailsService = {
  sendEmailProject,
};

export default projectEmailsService;
