import axios from "../utils/axios";
import { ICountry, IKeyValue, IUserCountryAccess } from "../ts/interfaces";

const getByUser = async (id: number) => {
  return axios.get<IUserCountryAccess[]>(`/UserCountryAccess/ByUserId/` + id);
};

const deleteItem = async (id: number) => {
  return axios.delete<number>(`UserCountryAccess/Delete/` + id);
};

const addItem = async (userId: number, countryId: string | null) => {
  if (countryId === "0")
    return axios.post<IUserCountryAccess>(
      `UserCountryAccess/selectAll/` + userId
    );
  else
    return axios.post<IUserCountryAccess>(
      `UserCountryAccess/Create/` + userId + "/" + countryId
    );
};

const selectAllCountries = async (userId: number) => {
  return await axios.post(`UserCountryAccess/selectAll/${userId}`);
};

const getAll = () => {
  return axios.get<ICountry[]>("UserCountryAccess/CountryByCurrent");
};

const getKeyValues = () => {
  return new Promise<
    IKeyValue<number | null | undefined, string | undefined>[]
  >((resolve, reject) => {
    axios
      .get<Array<ICountry>>("/UserCountryAccess/CountryByCurrent")
      .then((response) => {
        const keyValuePair: IKeyValue<
          number | null | undefined,
          string | undefined
        >[] = response.data.map((country) => {
          return {
            key: country.id,
            value: country.name,
          };
        });

        resolve(keyValuePair);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const userCountryAccessService = {
  getAll,
  getByUser,
  deleteItem,
  addItem,
  selectAllCountries,
  getKeyValues,
};

export default userCountryAccessService;
