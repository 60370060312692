import axios from "src/utils/axios";
import { IPumpSystemPipingProperlyVentedDTO } from "src/ts/interfaces";

const get = () => {
  return axios.get<IPumpSystemPipingProperlyVentedDTO[]>(
    "PumpSystemPipingProperlyVented"
  );
};

const pumpSystemPipingProperlyVentedService = { get };
export default pumpSystemPipingProperlyVentedService;
