import {
  FormControl,
  InputLabel,
  Select as MuiSelect,
  MenuItem,
  FormHelperText,
  Skeleton,
  Typography,
} from "@mui/material";
import { Mode } from "src/ts/types/common";
import FormReadOnlyField from "./FormReadOnlyField";

interface FormSelectProps {
  name: string;
  label: string;
  value?: string | number | null;
  error?: any;
  onChange: (e: any) => void;
  options?: any[] | null;
  size?: "small" | "medium" | null;
  fullWidth?: boolean | undefined;
  disable?: boolean | undefined;
  defaultValue?: { key: any; value: any };
  showSkeleton?: boolean;
  sourceKey?: string;
  sourceValue?: string;
  disabled?: boolean;
  mode?: Mode;
  width?: string;
  colorLabel?: string;
  readOnly?: boolean;
  requiredField?: boolean;
}

export default function FormSelect(props: FormSelectProps) {
  const {
    name,
    label,
    value,
    error = null,
    onChange,
    options,
    size,
    fullWidth,
    disable,
    defaultValue,
    showSkeleton,
    sourceKey,
    sourceValue,
    disabled,
    mode,
    width,
    colorLabel,
    readOnly,
    requiredField,
  } = props;

  const internalSourceKey = sourceKey ?? "key";
  const internalSourceValue = sourceValue ?? "value";

  if (mode && mode === "read") {
    // eslint-disable-next-line eqeqeq
    const selected = options?.find((opt) => opt.key == value);
    return <FormReadOnlyField label={label} value={selected?.value ?? ""} />;
  }

  return (
    <>
      {showSkeleton ? (
        <Skeleton height={50} />
      ) : (
        <FormControl
          size={size ?? "small"}
          variant="outlined"
          fullWidth={fullWidth ?? true}
          disabled={disable}
          style={{ width: width }}
          sx={{
            ...(requiredField && {
              "& .MuiInputBase-root": {
                border: "1.5px solid green",
              },
              "& .MuiInputLabel-shrink": {
                transform: "translate(14px, -6px) scale(0.75)",
                backgroundColor: "white",
                padding: "0 4px",
              },
            }),
          }}
          {...(error && { error: true })}
        >
          <InputLabel shrink={true}>{label}</InputLabel>
          <MuiSelect
            label={label}
            name={name}
            value={value === "" ? defaultValue?.key ?? "" : value}
            onChange={onChange}
            size={size ?? "small"}
            fullWidth
            disabled={disabled}
            notched={true}
            readOnly={readOnly}
          >
            {defaultValue && (
              <MenuItem disabled={true} value={defaultValue.key}>
                <Typography color={colorLabel}>{defaultValue.value}</Typography>
              </MenuItem>
            )}
            {options?.map((item: any) => (
              <MenuItem
                key={item[internalSourceKey]}
                value={item[internalSourceKey]}
              >
                {item[internalSourceValue]}
              </MenuItem>
            ))}
          </MuiSelect>
          {error && <FormHelperText>{error}</FormHelperText>}
        </FormControl>
      )}
    </>
  );
}
