import {
  CardContent,
  Grid,
  Divider as MuiDivider,
  Card as MuiCard,
  useTheme,
} from "@mui/material";
import { spacing } from "@mui/system";
import styled from "@emotion/styled";
import { useAsyncQuery, useAuth, useEffectOnce, useLog } from "src/hooks";
import { useParams } from "react-router";
import { useMemo, useState } from "react";
import { IKeyValue, IReportType } from "src/ts/interfaces";
import { IProjectReport } from "src/ts/interfaces/project/projectDto";
import projectReportService from "src/services/study/projectReportService";
import reportTypeService from "src/services/study/reportTypeService";
import StudyWizard from "../../components/StudyWizard";
import { FooterButtons } from "../../components/FooterButtons";
import { reportTypeId, steps, studyTypeCode } from "../const/const";
import { useNavigate } from "react-router-dom";
import { FormCheckSwitch } from "src/components/formControls";
import useTowerStudy from "../hooks/useTowerStudy";
import DialogMessagePopup from "src/components/DialogMessagePopup";
import { Mode } from "src/ts/types";
import energyCombinedAverageService from "src/services/study/energyCombinedAverageService";
import {
  IEnergyCombinedAverage,
  ISourceSoundAverage,
  ISourceSoundCombine,
  ISourceSoundTest,
} from "src/ts/interfaces/catalogs";
import sourceSoundCombineService from "src/services/study/sourceSoundCombineService";
import EnergyCombinedAverageTabs from "./components/EnergyCombinedAverageTabs";
import SourceSoundGrid from "./components/SourceSoundGrid";
import FormNumericReadOnlyField from "src/components/formControls/FormNumericReadOnlyField";
import sourceSoundAverageService from "src/services/study/sourceSoundAverageService";
import SourceSoundAverageGrid from "./components/SourceSoundAverageGrid";
import studiesHistoryService from "src/services/study/studiesHistoryService";
import sourceSoundTestService from "src/services/study/sourceSoundTestService";
import HeaderStudyPage from "src/components/page/HeaderStudyPage";

const Divider = styled(MuiDivider)(spacing);
const Card = styled(MuiCard)(spacing);
const activeStep = 5;

const TowerStep6 = () => {
  const {
    getTowerStudy,
    towerValues,
    handleInputTowerChange,
    updateTowerStudy,
  } = useTowerStudy();

  const { user } = useAuth();
  const { log } = useLog();
  const params = useParams<{ id: string }>();
  const id = parseInt(params?.id === undefined ? "0" : params?.id);
  let navigate = useNavigate();
  const theme = useTheme();
  const [reportType, setReportType] = useState<IReportType | null>();
  const { execute: executeReport, data: dataReport } =
    useAsyncQuery<IProjectReport>(projectReportService.report);

  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] =
    useState(false);
  //Energy
  const [energyCombinedAverageItems, setEnergyCombinedAverageItems] = useState<
    IEnergyCombinedAverage[]
  >([]);

  const [energyCombinedAverageSelected, setEnergyCombinedAverageSelected] =
    useState<IEnergyCombinedAverage | null>(null);

  //Combined
  const [sourceSoundCombineItems, setSourceSoundCombineItems] = useState<
    ISourceSoundCombine[]
  >([]);

  //Average
  const [sourceSoundAverageItems, setSourceSoundAverageItems] = useState<
    ISourceSoundAverage[]
  >([]);

  const getEnergyCombinedAverage = async (
    sourceSoundTest: IKeyValue<number, string>[]
  ) => {
    const energyCombinedAverageItemsRes =
      await energyCombinedAverageService.getByReportIdAndType(id, reportTypeId);
    if (energyCombinedAverageItemsRes.data.length === 0) {
      const tempEnergySourceSubtracted: any = {
        reportId: id,
        reportTypeId: reportTypeId,
      };

      await energyCombinedAverageService.add(tempEnergySourceSubtracted);
      await getEnergyCombinedAverage(sourceSoundTest);
    } else {
      await getSourceSoundCombinedItems(
        energyCombinedAverageItemsRes.data[0].id,
        sourceSoundTest
      );
      await getSourceSoundAverageItems(
        energyCombinedAverageItemsRes.data[0].id,
        sourceSoundTest
      );
      setEnergyCombinedAverageItems(energyCombinedAverageItemsRes.data);
      setEnergyCombinedAverageSelected(energyCombinedAverageItemsRes.data[0]);
    }
  };

  const getEnergySourceSubtractedId = (
    description: string | null,
    sourceSoundTest: IKeyValue<number, string>[]
  ) => {
    return description
      ? sourceSoundTest.find((x) => x.value === description)?.key ?? 0
      : -1;
  };

  const getSourceSoundCombinedItems = async (
    energyId: number,
    soursourceSoundTest: IKeyValue<number, string>[]
  ) => {
    const sourceSoundItemsRes =
      await sourceSoundCombineService.getByEnergySourceSubtractedId(energyId);
    if (sourceSoundItemsRes.data.length === 0) {
      const tempSourceSoundTest: any = {
        energySourceSubtractedId: energyId,
      };

      const addedSourceSoundRes = await sourceSoundCombineService.add(
        tempSourceSoundTest
      );
      setSourceSoundCombineItems([
        { ...addedSourceSoundRes.data, energySourceSubtractedId: -1 },
      ]);
    } else {
      const sources = sourceSoundItemsRes.data.map((s) => ({
        ...s,
        sourceSoundTestsId: getEnergySourceSubtractedId(
          s.description,
          soursourceSoundTest
        ),
      }));
      setSourceSoundCombineItems(sources);
    }
  };

  const getSourceSoundAverageItems = async (
    energyId: number,
    sourceSoundTest: IKeyValue<number, string>[]
  ) => {
    const sourceSoundItemsRes =
      await sourceSoundAverageService.getByEnergySourceSubtractedId(energyId);
    if (sourceSoundItemsRes.data.length === 0) {
      const tempSourceSoundTest: any = {
        energySourceSubtractedId: energyId,
      };

      const addedSourceSoundRes = await sourceSoundAverageService.add(
        tempSourceSoundTest
      );
      setSourceSoundAverageItems([
        { ...addedSourceSoundRes.data, energySourceSubtractedId: energyId },
      ]);
    } else {
      const sources = sourceSoundItemsRes.data.map((s) => ({
        ...s,
        sourceSoundTestsId: getEnergySourceSubtractedId(
          s.description,
          sourceSoundTest
        ),
      }));
      setSourceSoundAverageItems(sources);
    }
  };

  const [sourceSoundTests, setSourceSoundTests] = useState<
    IKeyValue<number, string>[]
  >([]);

  const [sourceSoundTestsAll, setSourceSoundTestsAll] = useState<
    ISourceSoundTest[]
  >([]);

  const [isLoading, setIsLoading] = useState(false);
  useEffectOnce(() => {
    const getData = async () => {
      try {
        setIsLoading(true);
        await getTowerStudy(id);

        const sourceSoundTestRes = await sourceSoundTestService.getKeyValues(
          reportTypeId,
          id
        );

        setSourceSoundTests([
          ...sourceSoundTestRes,
          { key: 0, value: "Other" },
        ]);

        const sourceSoundTestResAll = await sourceSoundTestService.getAll(
          reportTypeId,
          id
        );

        setSourceSoundTestsAll([...sourceSoundTestResAll.data]);

        await getEnergyCombinedAverage(sourceSoundTestRes);
        const reportTypeRes = await reportTypeService.getReportTypeByCode(
          studyTypeCode
        );
        setReportType(reportTypeRes.data);
        await executeReport(id, reportTypeRes.data.code);
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      } finally {
        setIsLoading(false);
      }
    };

    if (id > 0) getData();
  });

  const mode: Mode = towerValues?.isComplete ? "read" : "read&Write";

  const disableStudy = useMemo(() => {
    return (
      towerValues?.isComplete ||
      (dataReport?.isInUse && dataReport?.isInUseById !== user?.userId) ||
      dataReport?.isPartOfProject === false
    );
  }, [
    dataReport?.isInUse,
    dataReport?.isInUseById,
    dataReport?.isPartOfProject,
    user?.userId,
    towerValues?.isComplete,
  ]);

  const selectedEnergy = async (
    energySourceSelected: IEnergyCombinedAverage
  ) => {
    setEnergyCombinedAverageSelected(energySourceSelected);

    if (!towerValues.isComplete) {
      await sourceSoundCombineService.updateAll(sourceSoundCombineItems);
      await sourceSoundAverageService.updateAll(sourceSoundAverageItems);
    }

    await getSourceSoundCombinedItems(
      energySourceSelected.id,
      sourceSoundTests
    );
    await getSourceSoundAverageItems(energySourceSelected.id, sourceSoundTests);
  };

  const addEnergySourceHandler = async () => {
    const tempEnergySourceSubtracted: any = {
      reportId: id,
      reportTypeId: reportTypeId,
    };

    const addedItem = await energyCombinedAverageService.add(
      tempEnergySourceSubtracted
    );
    const newItems = [...energyCombinedAverageItems];
    newItems.push(addedItem.data);
    setEnergyCombinedAverageItems(newItems);
  };
  const deleteEnergyConfirmationHandler = () => {
    setShowDeleteConfirmationModal(true);
  };

  const deleteEnergyCombinedAverageHandler = () => {
    const index = energyCombinedAverageItems.findIndex(
      (item) => item.id === energyCombinedAverageSelected?.id
    );

    if (
      index < 0 ||
      energyCombinedAverageSelected == null ||
      energyCombinedAverageItems.length <= 1
    )
      return;

    energyCombinedAverageService.remove(energyCombinedAverageSelected?.id);

    const newItems = [...energyCombinedAverageItems];
    newItems.splice(index, 1);
    setEnergyCombinedAverageItems(newItems);

    setShowDeleteConfirmationModal(false);
  };

  const saveSourceSoundCombined = async (item: ISourceSoundCombine) => {
    await sourceSoundCombineService.update(item.id, item);
    const energyCombinedRes =
      await energyCombinedAverageService.getByEnergyCombinedAverageById(
        energyCombinedAverageSelected?.id!
      );

    const index = getEnergyCombinedAverageIndex();

    const newItems = energyCombinedAverageItems.map((item, i) =>
      index === i ? energyCombinedRes.data : item
    );

    setEnergyCombinedAverageItems(newItems);
  };

  const saveSourceSoundAverage = async (item: ISourceSoundAverage) => {
    await sourceSoundAverageService.update(item.id, item);
    const energyCombinedRes =
      await energyCombinedAverageService.getByEnergyCombinedAverageId(
        energyCombinedAverageSelected?.id!
      );

    const index = getEnergyCombinedAverageIndex();

    const newItems = energyCombinedAverageItems.map((item, i) =>
      index === i ? energyCombinedRes.data : item
    );

    setEnergyCombinedAverageItems(newItems);
  };

  const getEnergyCombinedAverageIndex = () => {
    const index = energyCombinedAverageItems.findIndex(
      (item) => item.id === energyCombinedAverageSelected?.id
    );
    return index;
  };

  const getCombinedDb = () => {
    const index = getEnergyCombinedAverageIndex();
    if (index >= 0) {
      return energyCombinedAverageItems[index].dbCombined;
    }
    return 0;
  };

  const getAverageDb = () => {
    const index = getEnergyCombinedAverageIndex();
    if (index >= 0) {
      return energyCombinedAverageItems[index].dbAverage;
    }
    return 0;
  };

  const nextStepHandler = async () => {
    const route = `/studies/Tower/step7/${id}`;
    if (disableStudy) {
      navigate(route);
      return;
    }
    await save();
    navigate(route);
  };

  const previousStepHandler = async () => {
    const route = `/studies/Tower/step5/${id}`;
    if (disableStudy) {
      navigate(route);
      return;
    }
    await save();
    navigate(route);
  };

  const saveHandler = async () => {
    await save();
  };

  const save = async () => {
    await updateTowerStudy();
    await studiesHistoryService.save(
      towerValues.projectId,
      dataReport.reportTypeId,
      towerValues.id,
      "saved"
    );
    await sourceSoundCombineService.updateAll(sourceSoundCombineItems);
    await sourceSoundAverageService.updateAll(sourceSoundAverageItems);
    await energyCombinedAverageService.updateAll(energyCombinedAverageItems);
    log.info("Report was saved");
  };

  return (
    <>
      <HeaderStudyPage
        headerStudiesPage={{
          code: "TOWE",
          system: towerValues?.system,
          id: id,
        }}
        parentText="Project"
        parentLink="/"
      />
      <Divider my={6} />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <StudyWizard
            steps={steps}
            activeStep={activeStep}
            projectId={dataReport?.projectId}
            reportId={dataReport?.reportId}
            reportTypeId={dataReport?.reportTypeId}
          />
        </Grid>
        <Grid item xs={12}>
          <Card mb={1}>
            <CardContent>
              <Grid container spacing={5}>
                <Grid item xs={6}>
                  <FormCheckSwitch
                    label="Energy Combined/Averaged"
                    name="energyCombinedAverage"
                    value={towerValues.energyCombinedAverage}
                    onChange={handleInputTowerChange}
                    mode={mode}
                    disabled={disableStudy}
                    showSkeleton={isLoading}
                  />
                  {towerValues.energyCombinedAverage && (
                    <>← Adding Energy Sources Together.</>
                  )}
                </Grid>
                <Grid item xs={6}></Grid>
                {towerValues.energyCombinedAverage && (
                  <>
                    <Grid item xs={12}>
                      <EnergyCombinedAverageTabs
                        energyCombinedAverageItems={energyCombinedAverageItems}
                        energyCombinedAverageSelected={
                          energyCombinedAverageSelected
                        }
                        setEnergyCombinedAverageSelected={
                          setEnergyCombinedAverageSelected
                        }
                        selectedEnergy={selectedEnergy}
                        onAddEnergySource={addEnergySourceHandler}
                        onDeleteEnergyConfirmation={
                          deleteEnergyConfirmationHandler
                        }
                        disabledStudy={disableStudy}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <SourceSoundGrid
                        sourceSoundCombineItems={sourceSoundCombineItems}
                        setSourceSoundCombineItems={setSourceSoundCombineItems}
                        saveSourceSoundCombined={saveSourceSoundCombined}
                        energyCombinedAverageSelected={
                          energyCombinedAverageSelected
                        }
                        mode={mode}
                        disableStudy={disableStudy}
                        isLoading={isLoading}
                        sourceSoundTests={sourceSoundTests}
                        sourceSoundTestsAll={sourceSoundTestsAll}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormNumericReadOnlyField
                        label="Combined DB"
                        labelColor={theme.palette.success.main}
                        decimalScale={3}
                        value={getCombinedDb()}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <SourceSoundAverageGrid
                        sourceSoundAverageItems={sourceSoundAverageItems}
                        setSourceSoundAverageItems={setSourceSoundAverageItems}
                        saveSourceSoundAverage={saveSourceSoundAverage}
                        energyCombinedAverageSelected={
                          energyCombinedAverageSelected
                        }
                        mode={mode}
                        disableStudy={disableStudy}
                        sourceSoundTests={sourceSoundTests}
                        sourceSoundTestsAll={sourceSoundTestsAll}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormNumericReadOnlyField
                        label="Averaged DB"
                        labelColor={theme.palette.info.main}
                        decimalScale={3}
                        value={getAverageDb()}
                      />
                    </Grid>
                  </>
                )}

                <Grid item xs={12}>
                  <FooterButtons
                    activeStep={activeStep}
                    stepsCount={steps.length}
                    projectId={dataReport?.projectId}
                    companyId={user?.companyId as number}
                    isSaving={false}
                    disabled={false}
                    reportName={reportType?.name ?? ""}
                    previousStepHandler={previousStepHandler}
                    saveHandler={saveHandler}
                    nextStepHandler={nextStepHandler}
                    hideSaveButton={disableStudy}
                    isComplete={towerValues?.isComplete ?? true}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <DialogMessagePopup
        title={"Delete Confirmation"}
        text="Are you certain you want to delete the selected Energy Source Sound Test?"
        showPopup={showDeleteConfirmationModal}
        setShowPopup={setShowDeleteConfirmationModal}
        onSave={() => deleteEnergyCombinedAverageHandler()}
        onCancel={() => setShowDeleteConfirmationModal(false)}
        isSubmitting={false}
        disableClickOutside={true}
      />
    </>
  );
};

export default TowerStep6;
