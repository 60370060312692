import { Typography } from "@mui/material";
import { FormCheckBox } from "src/components/formControls";

const pb = 4;

interface Props {
  updateControlStep: (step: number, value: boolean) => void;
  value: boolean;
}

const step = 1;

const Agreement2 = ({ updateControlStep, value }: Props) => {
  const toggleAgreement = (e: any) => {
    updateControlStep(step, e.target.value);
  };

  return (
    <>
      <Typography variant="h4" gutterBottom pt={10} pb={pb} align="center">
        Agreement - Part 2
      </Typography>

      <Typography variant="body1" gutterBottom pb={pb}>
        Notice:
      </Typography>

      <Typography variant="body1" gutterBottom pb={pb}>
        When you select "Complete Project" you will have the opportunity to
        Re-Enter Data based on the initial report recommendations at no extra
        charge. <br /> This opportunity ends after the "Complete Project" button
        is selected for a second time.
      </Typography>

      <FormCheckBox
        value={value}
        name={""}
        label="I understand and wish to continue moving forward."
        onChange={toggleAgreement}
      />
    </>
  );
};

export default Agreement2;
