import { useEffect, useState } from "react";
import Popup from "src/components/Popup";
import {
  FormAcceptButton,
  FormCancelButton,
  FormDatePicker,
  FormRadioGroup,
  FormText,
  FormTextArea,
  FormTimePicker,
} from "src/components/formControls";
import { Grid, Stack } from "@mui/material";
import DialogMessagePopup from "src/components/DialogMessagePopup";
import getProjectSingleService from "src/services/getProjectSingleService";
import BuildingSelect from "../createEditProject/components/BuildingSelect";
import useFormTyped from "src/hooks/useFormTyped";
import { ProjectWithBogus } from "src/ts/interfaces/project/projectDto";
import { Validator } from "src/ts/types";
import {
  MeasurementSystemEnum,
  ProjectAddendum,
  ProjectTimeDurationUnit,
} from "src/ts/enums/project";
import FormNumeric from "src/components/formControls/FormNumeric";
import projectDuplicateService from "src/services/project/projectDuplicateService";
import { useLog } from "src/hooks";
import { IBuildingSelected } from "src/ts/interfaces";

interface DuplicateProjectPopupProps {
  projectId: number;
  showDuplicatePopUp: boolean;
  setShowDuplicatePopUp: (val: boolean) => void;
  copyActuals: boolean;
  refreshTable: () => void;
}

const initialValues: ProjectWithBogus = {
  id: 0,
  equipmentCalibrationReportRequired: false,
  visibleToAllContractors: false,
  publishInAuditMasterPro: false,
  tolerancesWereSaved: false,
  sendProposalThroughAMP: false,
  buildingId: 0,
  projectStatusId: 0,
  isTemplate: false,
  templateWasUsed: false,
  estimatedTimeDurationUnit: null,
  liftWorkRequired: false,
  liftWorkOnSite: false,
  liftProvidedByOthers: false,
  laddersRequired: false,
  specificationsProvided: false,
  printDrawingsAvailableOnSite: false,
  currentProject: false,
  activeProject: false,
  addendumBidForThisProject: ProjectAddendum.None,
  disabled: false,
  size: 0,
  completedBySuperAdmin: false,
  wasOpenedByContractor: false,
  projectAgreementsAccepted: false,
  isInfectiousControlProject: false,
  projectEstimatedTime: [],
  computerEstimatedTimeRemaining: 0,
  techniciansEstimatedTimeRemainingIsDays: false,
  completionPaymentByOwner: false,
  measurementSystem: MeasurementSystemEnum.Imperial,
  elevation: 0,
  gridView: false,
  isOpenDispute: false,
  projectBomaTypeId: null,
  projectBomaType: null,
  ductLeakageTesting: false,
  vibrationTestingForMotors: false,
  soundTestingLocations: false,
  hydronicPipeleakage: false,
  ufadPlenumLeakage: false,
  otherTesting: false,
  buildingEnergyEfficiency: false,
  airTestingBalancing: false,
  hydronicWaterBalancing: false,
  hvacEnergyPerformance: false,
  commissioning: false,
  proposedStartDateTimeUnknown: false,
  auditAreaUnknown: false,
  auditAreaUnknownCuFt: false,
  isParkingAreaPartOfThisTest: false,
  bogusAuditors: [],
  auditAreaSqFt: null,
  auditAreaCuFt: null,
  bogusStartDateTime: null,
};

export default function DuplicateProjectPopupPopUp({
  projectId,
  showDuplicatePopUp,
  setShowDuplicatePopUp,
  copyActuals,
  refreshTable,
}: DuplicateProjectPopupProps) {
  const { log } = useLog();
  const validate: any = (fieldValues = values) => {
    let temp: Record<string, string> = { ...errors };

    temp.name = new Validator(fieldValues, "name")
      .isRequired("Project Name is required")
      .toString();

    temp.estimatedTimeDuration = new Validator(
      fieldValues,
      "estimatedTimeDuration"
    )
      .isRequired("The time duration is required.")
      .greatThan(0, "The time duration must be great than 0")
      .toString();

    temp.bogusStartDate = new Validator(fieldValues, "bogusStartDate")
      .isRequired("This field is required.")
      .toString();

    temp.bogusStartDateTime = new Validator(fieldValues, "bogusStartDateTime")
      .isRequired("This field is required.")
      .toString();

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };
  const { values, setValues, errors, setErrors, handleInputChange } =
    useFormTyped<ProjectWithBogus>(initialValues, false, validate);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    const getProject = async () => {
      const response = await getProjectSingleService.getProjectSingle(
        projectId
      );

      setValues({
        ...response.data,
        bogusStartDateTime: "8:00 AM",
        bogusStartDate: null,
      });
    };
    if (projectId !== 0) getProject();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId]);
  const [showDuplicateProjectPopup, setShowDuplicateProjectPopup] =
    useState(false);

  const handleShowDuplicateProjectModal = () => {
    setShowDuplicatePopUp(false);
    setShowDuplicateProjectPopup(true);
  };

  const handleDuplicate = async () => {
    if (!validate()) {
      log.warning("The form is invalid, please correct.");
      return;
    }
    values.startDate = `${values.bogusStartDate} ${values.bogusStartDateTime}`;

    setIsLoading(true);
    await projectDuplicateService.duplicateProject(
      projectId,
      values,
      copyActuals
    );
    setShowDuplicateProjectPopup(false);
    setIsLoading(false);
    refreshTable();
    log.success("Project was copied succesfully");
  };

  return (
    <>
      <DialogMessagePopup
        title={"Information"}
        text={"Are you certain you want to duplicate this project?"}
        showPopup={showDuplicatePopUp}
        setShowPopup={setShowDuplicatePopUp}
        onSave={handleShowDuplicateProjectModal}
        isSubmitting={false}
      ></DialogMessagePopup>
      <Popup
        title="Project Duplicate"
        openPopup={showDuplicateProjectPopup}
        setOpenPopup={setShowDuplicateProjectPopup}
        onClose={() => {
          setShowDuplicateProjectPopup(false);
        }}
        size="sm"
      >
        <>
          <Stack direction="row" spacing={3.2}>
            <Grid container>
              <Grid item md={12} p={2}>
                <FormText
                  name="name"
                  label="Project Name"
                  value={values.name}
                  onChange={handleInputChange}
                  error={errors.name}
                  showSkeleton={false}
                  mode={"read&Write"}
                />
              </Grid>
              <Grid item xs={12} p={2}>
                <Stack direction="row" spacing={2}>
                  <FormDatePicker
                    name="bogusStartDate"
                    label="Proposed Start Date"
                    value={values.bogusStartDate}
                    onChange={handleInputChange}
                    error={errors.bogusStartDate}
                    disablePast={true}
                    mode={"read&Write"}
                  />
                  <FormTimePicker
                    label="Proposed Start Time"
                    value={values.bogusStartDateTime}
                    onChange={handleInputChange}
                    name={"bogusStartDateTime"}
                    error={errors.bogusStartDateTime}
                    mode={"read&Write"}
                  />
                </Stack>
              </Grid>
              <Grid item xs={12} p={2}>
                <Grid container>
                  <Grid item xs={6}>
                    <FormNumeric
                      name="estimatedTimeDuration"
                      label="Proposal Project Time Duration / Bid"
                      value={values.estimatedTimeDuration?.toString() ?? ""}
                      onChange={handleInputChange}
                      maxLength={6}
                      error={errors.estimatedTimeDuration}
                    />
                  </Grid>
                  <Grid item xs={6} pl={4}>
                    <FormRadioGroup
                      name="estimatedTimeDurationUnit"
                      label={""}
                      items={[
                        { key: ProjectTimeDurationUnit.Hours, value: "Hours" },
                        { key: ProjectTimeDurationUnit.Days, value: "Days" },
                      ]}
                      onChange={handleInputChange}
                      value={values.estimatedTimeDurationUnit}
                      row={true}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} p={2}>
                <FormTextArea
                  name="projectDescription"
                  label="Project Description"
                  value={values.projectDescription ?? ""}
                  onChange={handleInputChange}
                  error={errors.projectDescription}
                  placeholder=""
                />
              </Grid>
              <Grid item md={12} p={2}>
                <BuildingSelect
                  isRead={isLoading}
                  companyId={values?.companyId ?? 0}
                  values={values}
                  setValues={setValues}
                  errors={errors}
                  showGotoBuildingButton={false}
                  buildingId={values?.buildingId}
                  onSelect={function (buildingId: IBuildingSelected): void {
                    throw new Error("Function not implemented.");
                  }}
                ></BuildingSelect>
              </Grid>
            </Grid>
          </Stack>
        </>

        <Stack>
          <Grid container>
            <Grid item>
              <FormCancelButton
                onClick={() => {
                  setShowDuplicateProjectPopup(false);
                }}
                isSubmitting={isLoading}
              />
            </Grid>
            <Grid item pl={2}>
              <FormAcceptButton
                onClick={handleDuplicate}
                isSubmitting={isLoading}
              ></FormAcceptButton>
            </Grid>
          </Grid>
        </Stack>
      </Popup>
    </>
  );
}
